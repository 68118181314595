import {
  OCol,
  OConfirmationModal,
  OField,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DataGrid } from "devextreme-react";
import { PostAdminCancelableReceivablesResponse } from "services/quickfin/models";
import { receivablesCancelingModalId } from "../canceling.utils";
import { cancelingModalGrid } from "./canceling-modal.grid";
import { useCancelingModal } from "./canceling-modal.hook";

interface CancelingModalGridProps {
  gridRef: React.RefObject<DataGrid>;
  receivables: PostAdminCancelableReceivablesResponse["cancelableReceivables"];
  setSelectedReceivables: React.Dispatch<
    React.SetStateAction<
      | PostAdminCancelableReceivablesResponse["cancelableReceivables"]
      | undefined
    >
  >;
  refetchReceivables: () => Promise<void>;
}

export const CancelingModal = ({
  gridRef,
  receivables,
  setSelectedReceivables,
  refetchReceivables,
}: CancelingModalGridProps) => {
  const { cancelReceivables, loading, reducedReceivables, totalSum } =
    useCancelingModal(
      gridRef,
      receivables,
      setSelectedReceivables,
      refetchReceivables,
    );

  return (
    <OConfirmationModal
      modalId={receivablesCancelingModalId}
      title="Confirma o cancelamento dos seguintes títulos?"
      loading={loading}
      onConfirm={() => cancelReceivables()}
    >
      <div className="d-flex flex-column gap-3">
        <ODataGridGenerator
          grid={cancelingModalGrid}
          dataSource={reducedReceivables}
        />
        <ORow>
          <OCol xs={12} md={6}>
            <OField htmlFor="total-soma" label="Soma">
              <OTypography id="total-soma">
                {masks.currency(totalSum)}
              </OTypography>
            </OField>
          </OCol>
          <OCol xs={12} md={6}>
            <OField htmlFor="total-notas" label="Total de notas">
              <OTypography id="total-notas">{receivables.length}</OTypography>
            </OField>
          </OCol>
        </ORow>
      </div>
    </OConfirmationModal>
  );
};
