import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCancelableReceivablesResponse } from "services/quickfin/models";

export const cancelingGrid = (
  setSelectedReceivables: React.Dispatch<
    React.SetStateAction<
      | PostAdminCancelableReceivablesResponse["cancelableReceivables"]
      | undefined
    >
  >,
): ODataGridGeneratorConfig<
  PostAdminCancelableReceivablesResponse["cancelableReceivables"][number]
> => ({
  datagrid: {
    noDataText: "Nenhum recebível com esses filtros.",
    onSelectionChanged: (evt) => setSelectedReceivables(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "receivableIdentification",
      caption: "Identificação do recebível",
    },
    {
      dataField: "receivableStatus",
      caption: "Status do recebível",
    },
    {
      dataField: "sacado",
      caption: "Sacado",
    },
    {
      dataField: "cedente",
      caption: "Cedente",
    },
    {
      dataField: "valorVencimento",
      caption: "Valor do vencimento",
      cellRender: ({ data }) => masks.currency(data.valorVencimento),
    },
    {
      dataField: "currency",
      caption: "Moeda",
    },
    {
      dataField: "dataVencimento",
      caption: "Data de vencimento",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "dataCriacao",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "dataAtualizacao",
      caption: "Atualizado em",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "borderoId",
      caption: "Identificador de importação",
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
