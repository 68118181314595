import { env } from "utils/environments";

export const fidcEndpoints = {
  ambev: {
    generate: {
      allContracts: `${env.API_FIDC}/api/ambev/generate/all-contracts`,
    },
    notifications: `${env.API_FIDC}/api/ambev/notifications/`,
  },
  batch: {
    admin: `${env.API_FIDC}/api/batch/admin`,
    byBatchId: {
      terms: (batchId: string) => `${env.API_FIDC}/api/batch/${batchId}/terms`,
      guaranteeXLSXV2: (batchId: string) =>
        `${env.API_FIDC}/api/batch/${batchId}/guaranteeXLSXV2`,
      submit: {
        admin: (batchId: string) =>
          `${env.API_FIDC}/api/batch/${batchId}/submit/admin`,
      },
    },
    dueDate: `${env.API_FIDC}/api/batch/duedate`,
    getByCnpj: `${env.API_FIDC}/api/batch/getByCnpj`,
  },
  contractGenerator: {
    byProductId: (productId: string) =>
      `${env.API_FIDC}/api/ContractGenerator/${productId}`,
    endpoint: `${env.API_FIDC}/api/ContractGenerator/`,
  },
  dashboard: {
    bankSlip: {
      byBankSlip: (bankSlipId: string) =>
        `${env.API_FIDC}/api/dashboard/bankSlip/${bankSlipId}`,
    },
    batches: `${env.API_FIDC}/api/dashboard/batches`,
    charges: `${env.API_FIDC}/api/dashboard/charges`,
    overallStatus: `${env.API_FIDC}/api/dashboard/overallStatus`,
    products: `${env.API_FIDC}/api/dashboard/products`,
    signers: {
      ambevNfe: `${env.API_FIDC}/api/dashboard/signers/ambev-nfe`,
    },
    brokers: {
      byProductId: (productId: string) =>
        `${env.API_FIDC}/api/dashboard/brokers/${productId}`,
    },
  },
  fund: {
    admin: `${env.API_FIDC}/api/fund/admin`,
  },
  // fundCompany: {
  //   admin: `${env.API_FIDC}/api/company/funds`,
  // },
  guarantee: {
    byTaxId: (taxId: string) => `${env.API_FIDC}/api/guarantee/${taxId}`,
  },
  product: {
    admin: `${env.API_FIDC}/api/product/admin`,
  },
  send: {
    notifications: {
      fund: `${env.API_FIDC}/api/send/notification/fund`,
    },
  },
  snake: {
    company: {
      byId: (id: string) => `${env.API_FIDC}/api/snake/company/${id}`,
      byFund: (fund: string) =>
        `${env.API_FIDC}/api/snake/company/${fund}/list`,
    },
    financial: {
      byFinancialId: (financialId: string) =>
        `${env.API_FIDC}/api/snake/financial/${financialId}`,
      endpoint: `${env.API_FIDC}/api/snake/financial`,
      byUuid: (uuid: string) => `${env.API_FIDC}/api/snake/financial/${uuid}`,
    },
    fundCompany: {
      admin: `${env.API_FIDC}/api/snake/company/funds`,
    },
    odata: {
      InteractionsOdata: `${env.API_FIDC}/api/snake/odata/InteractionsOData`,
    },
    interaction: {
      tracking: `${env.API_FIDC}/api/snake/interaction/tracking`,
      interactionsList: `${env.API_FIDC}/api/snake/interaction/InteractionList`,
    },
  },
  v1: {
    bankslip: {
      bySlipId: (slipId: string) =>
        `${env.API_FIDC_BANKSLIP_MANAGER}v1/bankslip/${slipId}`,
      endpoint: `${env.API_FIDC_BANKSLIP_MANAGER}v1/bankslip/`,
    },
    NextBusDay: `${env.API_FIDC_BANKSLIP_MANAGER}v1/NextBusDay`,
  },
  credit: {
    conciliation: `${env.API_FIDC}/api/CreditConciliation`,
    details: {
      byId: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/details`,
    },
    file: {
      byId: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/files`,
      file: `${env.API_FIDC}/api/CreditConciliation/files`,
    },
    confirmation: {
      byId: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/release`,
    },
    cancel: {
      byId: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/cancel`,
    },
    download: {
      generate: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/generate-download-url`,
    },
    applyChannelValidation: {
      channelValidation: (orderId: string) =>
        `${env.API_FIDC}/api/CreditConciliation/${orderId}/apply-channel-validation`,
    },
  },
  fidcBack: {
    disverbation: {
      approvals: `${env.API_FIDC}/api/Disverbation`,
      status: `${env.API_FIDC}/api/Disverbation/status`,
      funds: `${env.API_FIDC}/api/DisverbationConfig/activeDisverbationFunds`,
      resends: `${env.API_FIDC}/api/Disverbation/send`,
    },
  },
};
