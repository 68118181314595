import { roles } from "roles/roles";
import { MenuTabs } from "../tab-menu.types";

export const menuTabs = {
  customer: [
    {
      name: "Cadastro",
      path: "/cadastro/cliente",
      role: roles.cadastro.customer.tab.role,
    },
    {
      name: "Usuários",
      path: "/usuarios/cliente",
      role: roles.usuarios.customer.tab.role,
    },
    {
      name: "ACC",
      path: "/acc/cliente",
      role: roles.acc.customer.tab.role,
    },
    {
      name: "Antecipação de Cartões",
      path: "/antecipacao-de-cartoes/cliente",
      role: roles.antecipacaoDeCartoes.customer.tab.role,
    },
    {
      name: "Antecipação a Fornecedor",
      path: "/antecipacao-a-fornecedor/cliente",
      role: roles.antecipacaoAFornecedor.customer.tab.role,
    },
    {
      name: "Parceiros",
      path: "/finders/cliente",
      role: roles.b2b.customer.tab.role,
    },
    {
      name: "Parceiros Novo",
      path: "/parceiros/cliente",
      role: roles.parceiros.customer.partnersTab.role,
    },
    {
      name: "Câmbio",
      path: "/cambio/cliente",
      role: roles.cambio.customer.tab.role,
    },
    {
      // legacy
      name: "Cartões",
      path: "/cartoes/cliente",
      role: roles.cartoes.customer.tab.role,
    },
    {
      name: "Conta Corrente",
      path: "/banking/cliente",
      role: roles.banking.customer.tab.role,
    },
    {
      name: "Developers",
      path: "/developers/cliente",
      role: roles.developers.product.tab.role,
    },
    {
      name: "Duplicatas",
      path: "/duplicatas/cliente",
      role: roles.duplicatas.customer.tab.role,
    },
    {
      name: "Empréstimos",
      path: "/emprestimos/cliente",
      role: roles.emprestimos.customer.tab.role,
    },
    {
      name: "Energia",
      path: "/energia/cliente",
      role: roles.energia.customer.tab.role,
    },
    {
      name: "Folha de Pagamento",
      path: "/folha-de-pagamento/cliente",
      role: roles.fopa.customer.tab.role,
    },
    {
      name: "Franquias",
      path: "/franquias/cliente",
      role: roles.franquias.customer.tab.role,
    },
    {
      name: "Recebíveis",
      path: "/recebiveis/cliente",
      role: roles.recebiveis.customer.tab.role,
    },
    {
      name: "Renegociação/Quitação",
      path: "/renegociacoes/cliente",
      role: roles.renegociacao.customer.tab.role,
    },
    {
      name: "Catalogo de Produtos",
      path: "/workflow-rmadmin/cliente",
      role: roles.workflowRmAdmin.customer.tab.role,
    },
  ],
  product: [
    {
      name: "Empresas",
      path: "/empresas/produto",
      role: roles.empresas.product.tab.role,
    },
    {
      name: "Usuários",
      path: "/usuarios/produto",
      role: roles.usuarios.product.tab.role,
    },
    {
      name: "ACC",
      path: "/acc/produto",
      role: roles.acc.product.tab.role,
    },
    {
      name: "Antecipação de Cartões",
      path: "/antecipacao-de-cartoes/produto",
      role: roles.antecipacaoDeCartoes.product.tab.role,
    },
    {
      name: "Parceiros",
      path: "/finders/produto",
      role: roles.b2b.product.tab.role,
    },
    {
      name: "Parceiros Novo",
      path: "/parceiros/produto",
      role: roles.parceiros.product.partnersTab.role,
    },
    {
      name: "Banking",
      path: "/banking/produto",
      role: roles.banking.product.tab.role,
    },
    {
      name: "Billing",
      path: "/billing/produto",
      role: roles.billing.product.tab.role,
    },
    {
      name: "Câmbio",
      path: "/cambio/produto",
      role: roles.cambio.product.tab.role,
    },
    {
      name: "Cartões",
      path: "/cartoes/produto",
      role: roles.cartoes.product.tab.role,
    },
    {
      name: "Cobranças",
      path: "/cobrancas/produto",
      role: roles.cobrancas.product.tab.role,
    },
    {
      name: "Duplicatas",
      path: "/duplicatas/produto",
      role: roles.duplicatas.product.tab.role,
    },
    {
      name: "Developers",
      path: "/developers/produto",
      role: roles.developers.product.tab.role,
    },
    {
      name: "Empréstimos",
      path: "/emprestimos/produto",
      role: roles.emprestimos.product.tab.role,
    },
    {
      name: "Energia",
      path: "/energia/produto",
      role: roles.energia.product.tab.role,
    },
    {
      name: "Ferramentas",
      path: "/ferramentas/produto",
      role: roles.ferramentas.product.tab.role,
    },
    {
      name: "FIDC",
      path: "/fidc/produto",
      role: roles.fidc.product.tab.role,
    },
    {
      name: "Franquias",
      path: "/franquias/produto",
      role: roles.franquias.product.tab.role,
    },
    {
      name: "Recebíveis",
      path: "/recebiveis/produto",
      role: roles.recebiveis.product.tab.role,
    },
    {
      name: "Workflow de Leads",
      path: "/workflow/produto",
      role: roles.workflow.product.tab.role,
    },
    {
      name: "Catalogo de Produtos",
      path: "/workflow-rmadmin/produto",
      role: roles.workflowRmAdmin.product.tab.role,
    },
  ],
} satisfies MenuTabs;
