import { OToastManager } from "@maestro/core";
import { ORFieldCheckboxGroup } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { renegGrid } from "./renegociacao-clientes.grid";
import { Contract } from "./renegociacao-clientes.type";
import { ConditionsLink, HistoryLink } from "./_compose";

const dataSource = dataSourceCustomStoreGenerator<Contract>(() =>
  service.renegotiation
    .getContracts()
    .then(({ data }) => data)
    .catch(() => {
      const errorMessage = "Erro ao buscar os contratos";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const RenegociacoesCustomer = () => {
  const form = useForm<{ contracts: string[] }>();

  const gridRef = useGridRef();

  const { watch } = form;

  const contracts = watch("contracts");

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Renegociação/Quitação de contratos" />}
      actions={
        <>
          <ConditionsLink chosenContracts={contracts ?? []} />
          <HistoryLink />
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <FormProvider {...form}>
        <ORFieldCheckboxGroup
          dataAction="renegociacao:checkbox:contratos"
          dataLabel="contratos"
          id="contracts"
          name="contracts"
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={renegGrid}
            dataSource={dataSource}
          />
        </ORFieldCheckboxGroup>
      </FormProvider>
    </GridTemplate>
  );
};
