import { OCol, OLabel, ORFieldInput, ORow, OSwitch } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { CollateralFormValues } from "../add-collateral-form.schema";

export const PromissoryNoteFormComponent = () => {
  const form = useFormContext<CollateralFormValues>();

  const { setValue, watch } = form;

  const formalizedWatcher = watch("formalized");

  return (
    <div>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="amount"
            name="amount"
            label="Valor"
            tag="currency"
            symbol="R$"
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="guaranteedPercentage"
            name="guaranteedPercentage"
            label="Porcentagem garantida"
            tag="percentage"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={6} align="center">
          <div className="d-flex align-items-center gap-2">
            <OSwitch
              id="formalized"
              size="xs"
              className="mr-2"
              value={!!formalizedWatcher}
              onInput={() => setValue("formalized", !formalizedWatcher)}
            />
            <OLabel htmlFor="formalized" size="md">
              Já assinado
            </OLabel>
          </div>
        </OCol>
        {formalizedWatcher && (
          <OCol sm={12} md={6}>
            <ORFieldInput
              id="formalizedContractNumber"
              name="formalizedContractNumber"
              label="Número da Nota Promissória"
              tag="text"
            />
          </OCol>
        )}
      </ORow>
      <div />
    </div>
  );
};
