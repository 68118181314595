import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  addSacadoTierConfigDefaulValues,
  addSacadoTierConfigValidationSchema,
} from "./add-sacado-tier-config.form";
import { AddSacadoTierConfigForm } from "./add-sacado-tier-config.types";

export const useAddSacadoTierConfig = () => {
  const navigate = useNavigate();

  const {
    callService: listSacados,
    hasError: sacadosHasError,
    loading: sacadosLoading,
    value: sacadosFull,
  } = useServiceCall(service.quickfin.listSacados);

  const {
    callService: createSacadoTierConfig,
    hasError: submitHasError,
    loading: submitLoading,
    value: submitResponse,
  } = useServiceCall(service.quickfin.createSacadoTierConfig);

  const form = useForm<AddSacadoTierConfigForm>({
    defaultValues: addSacadoTierConfigDefaulValues,
    resolver: yupResolver(addSacadoTierConfigValidationSchema),
  });

  const { handleSubmit, setValue, trigger } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) =>
        createSacadoTierConfig({
          ...values,
          minimumTier: String(values.minimumTier),
        }),
      ),
    [createSacadoTierConfig, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    listSacados();
  }, [listSacados]);

  /** Error warning */
  useEffect(() => {
    if (submitHasError)
      OToastManager.danger(
        "Erro ao criar a configuração. Verifique se o sacado já possui configuração de limite.",
      );
  }, [submitHasError]);

  /** Error warning */
  useEffect(() => {
    if (sacadosHasError) OToastManager.danger("Erro ao buscar os sacados");
  }, [sacadosHasError]);

  /** Success */
  useEffect(() => {
    if (submitResponse) {
      OToastManager.success("Configuração criada com sucesso");
      navigate(
        corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento
          .sacadoTier.path,
      );
    }
  }, [navigate, submitResponse]);

  const sacadosOptions = useMemo<SelectSearchOption<number>[]>(
    () =>
      sacadosFull?.value.map((sacado, index) => ({
        label: sacado.OfficialName ?? "",
        value: sacado.Id,
        onSelect: () => {
          setValue("sacadoId", sacado.Id);
          trigger("sacadoId");
        },
        customKey: index,
      })) ?? [],
    [sacadosFull?.value, setValue, trigger],
  );

  return {
    sacadosHasError,
    sacadosLoading,
    sacadosOptions,
    submitLoading,
    form,
    submit,
  };
};
