import { OSwitch, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo, useState } from "react";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, powersGrid } from "./company-acts.grid";

export const CompanyActs = () => {
  const [useCache, setUseCache] = useState(false);

  const gridRef = useGridRef();

  const { hasRole } = useRoles();

  const dataSource = useMemo(() => buildDataSource(useCache), [useCache]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Firmas e poderes" />}
      gridRef={gridRef}
      actions={
        hasRole(roles.cadastro.customer.companyActsCacheSwitch.role) && (
          <div className="d-flex gap-2 align-self-end me-auto">
            <OSwitch
              size="xs"
              value={useCache}
              onClick={() => setUseCache((x) => !x)}
            />
            <OTypography type="dark">Usar cache</OTypography>
          </div>
        )
      }
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={powersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
