import { OTooltip } from "@maestro/react";
import { StatusBadge } from "components/status-badge";
import {
  ProfileManagementChangeStatus,
  ProfileManagementRequestStatus,
} from "services/gatekeeper/models/types/profile-management.types";

export const profileManagementRequestsStatusMap: Record<
  ProfileManagementRequestStatus,
  JSX.Element
> = {
  EM_CONSTRUCAO: <StatusBadge type="info">Em construção</StatusBadge>,
  CANCELADO: <StatusBadge type="danger">Cancelado</StatusBadge>,
  ERRO_IMPLEMENTACAO: (
    <StatusBadge type="danger">Erro de implementação</StatusBadge>
  ),
  EM_ANALISE: <StatusBadge type="warning">Aguardando análise</StatusBadge>,
  APROVADA: <StatusBadge type="success">Aprovada</StatusBadge>,
  CONCLUIDA: <StatusBadge type="success">Concluida</StatusBadge>,
  PENDENTE_ALTERACAO: (
    <StatusBadge type="danger">Aguardando alterações</StatusBadge>
  ),
};

export const profileManagementChangesStatusMap: Record<
  ProfileManagementChangeStatus,
  (rejectReason?: string) => JSX.Element
> = {
  EM_CONSTRUCAO: () => <StatusBadge type="info">Em construção</StatusBadge>,
  EM_ANALISE: () => (
    <StatusBadge type="warning">Aguardando análise</StatusBadge>
  ),
  APROVADA: () => <StatusBadge type="success">Aprovada</StatusBadge>,
  CONCLUIDA: () => <StatusBadge type="success">Concluida</StatusBadge>,
  PENDENTE_ALTERACAO: (rejectReason?: string) => (
    <OTooltip floating>
      <span slot="tooltip-content">{rejectReason}</span>
      <StatusBadge type="danger">Reprovado</StatusBadge>
    </OTooltip>
  ),
};
