import { OButton, OIcon, OTypography } from "@maestro/react";

export interface InfoCardContentProps {
  footerFirstLabel: string;
  footerFirstValue: string;
  footerSecondLabel: string;
  footerSecondValue: string;
  refetch: () => void;
  title: string;
  value: string;
}

export const InfoCardContent = ({
  footerFirstLabel,
  footerFirstValue,
  footerSecondLabel,
  footerSecondValue,
  refetch,
  title,
  value,
}: InfoCardContentProps) => {
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        {title}
        <OButton
          dataAction={`operacoes_dashboard_${title}:botao:refetch`}
          dataLabel="refetch"
          type="dark"
          size="sm"
          outline
          onClick={() => refetch()}
        >
          <OIcon category="fal" icon="fa-sync" size="sm" />
        </OButton>
      </div>
      <OTypography size="xxl" weight="700">
        {value}
      </OTypography>
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <span>{footerFirstLabel}</span>
          <span>{footerFirstValue}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>{footerSecondLabel}</span>
          <span>{footerSecondValue}</span>
        </div>
      </div>
    </>
  );
};
