import { OToastManager, modalManager } from "@maestro/react";
import { useState } from "react";
import { service } from "services";
import { CancelFloatingModalId } from "./cancel-floating-modal.types";

export const useCancelFloating = () => {
  const [isLoading, setIsLoading] = useState(false);

  const cancelFloating = async (handleSuccess: () => void) => {
    try {
      setIsLoading(true);
      await service.adminBankinghub.collection.cancelFloating();
      OToastManager.success("Floating removido com sucesso");
      modalManager.hide(CancelFloatingModalId);
      handleSuccess();
    } catch {
      OToastManager.danger(
        "Ocorreu um erro para remover o Floating do beneficiário.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { cancelFloating, isLoading };
};
