import { useEffect, useState } from "react";
import { GetCnabCnabTracingResponse } from "services/admin-bankinghub/models";
import { service } from "services/service";

export const useStatementsMonitor = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [statements, setStatements] = useState<GetCnabCnabTracingResponse>();
  const [chosenDate, setChosenDate] = useState<string>(
    new Date().toISOString(),
  );

  const fetchData = async (date: string) => {
    if (!date) {
      return;
    }

    setLoading(true);
    setHasError(false);
    try {
      const { data } = await service.adminBankinghub.getCnabStatements({
        executeDate: date,
      });
      setStatements(data);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(chosenDate);
  }, [chosenDate]);

  return {
    chosenDate,
    fetchData,
    hasError,
    loading,
    setChosenDate,
    statements,
  };
};
