import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { customersODataGrid } from "./client-customers-relationship.grid";

export const ClientCustomersRelationshipsPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => customersODataGrid, []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Vínculos de Clientes" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
