import { DetailsCard } from "components/details-card";
import { ErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { DetailsTemplate } from "templates/details-template";
import { Offer } from "../hired-offers.types";
import { offerSearchParam } from "../hired-offers.utils";
import { buildOfferDetails } from "./offer-details.details";

export const OfferDetails = () => {
  const { value: offer, hasError } =
    useParseFromSearchParams<Offer>(offerSearchParam);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={`Detalhes da oferta ${offer?.company.name}`}
          key={offer?.company.name}
        />
      }
    >
      <DetailsCard
        loading={!offer}
        hasError={hasError}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível encontrar a oferta"
            messageParagraph="Ocorreu um erro ao buscar a oferta. Retorne à página anterior e tente novamente."
          />
        }
        fields={buildOfferDetails}
        value={offer}
      />
    </DetailsTemplate>
  );
};
