import { BankData } from "./bank-data";
import { Confirmation } from "./confirmation";
import { QuoteSuggestStep } from "./quote-suggest";
import { Result } from "./result";
import { SummaryStep } from "./summary";
import { ValidateStep } from "./validate";

export const suggestSteps = [
  {
    name: "Cotação",
    component: QuoteSuggestStep,
  },
  {
    name: "Resumo",
    component: SummaryStep,
  },
  {
    name: "Validação",
    component: ValidateStep,
  },
  {
    name: "Dados bancários",
    component: BankData,
  },
  {
    name: "Criar operação",
    component: Confirmation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
