import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { ContractsStatusModal } from "./operations-contracts-status-modal.component";
import { OperationsContractsProvider } from "./operations-contracts.context";
import { operationsContractsGrid } from "./operations-contracts.grid";

export const OperationsContractsPage = () => {
  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubLoan.getContracts(id).catch(() => {
          throw new Error("Erro ao buscar contratos");
        }),
      ),
    [id],
  );

  return (
    <OperationsContractsProvider>
      <GridTemplate
        pageTitle={<PageTitle title="Contratos" />}
        actions={
          <RefreshGridButton
            onClick={() => {
              !dataSource?.isLoading() && dataSource?.reload();
            }}
          />
        }
      >
        <ODataGridGenerator
          grid={operationsContractsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <ContractsStatusModal dataSource={dataSource} />
    </OperationsContractsProvider>
  );
};
