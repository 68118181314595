import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./add.form";
import { FormValuesType } from "./add.types";

export const useAddRestrictionsList = () => {
  const restrictionOptions = [
    "ExperienciaAntigaPortal",
    "ExtratoLiquidacaoPosicao",
    "IgnoraHorarioLimiteAntecipacao",
    "PermiteOperarContaBloqueadaParcial",
    "PodeDesembolsarFora",
  ];

  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, watch } = form;

  const watchTipoProduto = watch("tipoProduto");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
      };

      await service.quickfin.setAdminCompanyFlags(payload);

      OToastManager.success("Empresa criada com sucesso");

      navigate(routes.recebiveis.product.restrictionsList.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    restrictionOptions,
    form,
    loading,
    watchTipoProduto,
    submit,
  };
};
