import { OCard, OCardBody, OLoader } from "@maestro/react";
import { InfoCard } from "../../_compose/info-card";
import { InfoCardContainer } from "../../_compose/info-card/card.styles";

export type CreditConciliationDashboardProps = {
  paymentTypeDetails: Fidc.CreditConciliationsDetailItem[] | undefined;
  loading: boolean;
};

export const CreditConciliationDashboard = ({
  paymentTypeDetails,
  loading,
}: CreditConciliationDashboardProps) => {
  return (
    <OCard>
      <OCardBody>
        {paymentTypeDetails && !loading ? (
          <InfoCardContainer>
            <div className="d-flex flex-row">
              {paymentTypeDetails?.map((item, index) => (
                <InfoCard
                  key={item.status || index}
                  title={item.paymentType}
                  value={item.amount}
                  quantity={item.quantity}
                  status={item.status}
                />
              ))}
            </div>
          </InfoCardContainer>
        ) : (
          <div className="d-flex flex-column align-items-center gap-3">
            <OLoader />
          </div>
        )}
      </OCardBody>
    </OCard>
  );
};
