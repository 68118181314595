import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanContractsConfigForm,
  LoanContractsConfigFormValues,
} from "../../../../../components/loan-contracts-config/loan-contracts-config-form.component";
import { loanContractsConfigValidationFormSchema } from "../../../../../components/loan-contracts-config/loan-contracts-config-form.schemas";
import {
  LoanContractsTemplatesForm,
  LoanContractsTemplatesFormValues,
} from "../../../../../components/loan-contracts-templates/loan-contracts-templates-form.component";
import { loanContractsTemplatesValidationFormSchema } from "../../../../../components/loan-contracts-templates/loan-contracts-templates-form.schemas";

type LoanAddContractsConfigFormValues = LoanContractsConfigFormValues &
  LoanContractsTemplatesFormValues;

export const LoanAddContractsConfigPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<LoanAddContractsConfigFormValues>({
    resolver: yupResolver(
      loanContractsConfigValidationFormSchema.concat(
        loanContractsTemplatesValidationFormSchema,
      ),
    ),
  });

  const { handleSubmit } = form;

  const addContractTemplateConfig = useCallback(
    async (values: LoanAddContractsConfigFormValues) => {
      try {
        setLoading(true);

        await service.hubLoan.addLoanContractsConfig(values);

        OToastManager.success(
          "Nova configuração de contratos de empréstimo cadastrada com sucesso.",
        );
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao tentar cadastrar a nova configuração de contratos de empréstimo.",
        );
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Cadastrar configuração de contratos de empréstimo" />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_template_contratto:botao:salvar"
          dataLabel="salvar"
          onClick={handleSubmit(addContractTemplateConfig)}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <LoanContractsConfigForm />

          <OTypography>Templates de contrato</OTypography>
          <LoanContractsTemplatesForm />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
