import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { PutAdminInvoiceBody } from "services/hubcreditmanager/models/requests/put-admin-invoice.request";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../../routes/emprestimos.route-params";
import {
  InvoiceProductsFormValues,
  invoiceProductsGrid,
} from "../invoice-products.grid";
import { useLimitInvoices } from "../limit-invoices.hook";
import { InvoiceForm } from "./invoice-form.component";
import {
  InvoiceFormValues,
  invoiceFormValidationFormSchema,
} from "./invoice-form.schemas";

export const EditLimitInvoicePage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  const invoiceServicesForm = useForm<InvoiceFormValues>({
    resolver: yupResolver(invoiceFormValidationFormSchema),
  });
  const invoiceProductsForm = useForm<InvoiceProductsFormValues>({
    resolver: yupResolver(invoiceFormValidationFormSchema),
  });

  const { handleSubmit: handleSubmitServices, reset: resetServices } =
    invoiceServicesForm;
  const {
    handleSubmit: handleSubmitProducts,
    reset: resetProducts,
    setValue: setValueProducts,
  } = invoiceProductsForm;

  if (!id) throw new Error("No id");

  const [loading, setLoading] = useState({ service: false, product: false });

  const {
    productsInvoice,
    serviceInvoice,
    hasError,
    loading: loadingLimitRequest,
    callService,
  } = useLimitInvoices(id);

  const updateInvoice = useCallback(
    async (invoice: PutAdminInvoiceBody, symbol: "service" | "product") => {
      try {
        setLoading((previousLoading) => ({
          ...previousLoading,
          [symbol]: true,
        }));

        await service.hubCreditManager.updateInvoice(invoice);

        OToastManager.success("Dados atualizados com sucesso.");
      } catch {
        OToastManager.danger("Não foi possível atualizar os dados.");
      } finally {
        setLoading((previousLoading) => ({
          ...previousLoading,
          [symbol]: false,
        }));
      }
    },
    [],
  );

  const productsGrid = useMemo(
    () =>
      invoiceProductsGrid(false, (invoiceProducts) => {
        setValueProducts("invoiceProducts", invoiceProducts);
        const sumProductsValues = invoiceProducts?.reduce(
          (sum, product) => sum + product.amount,
          0,
        );
        setValueProducts("amount", sumProductsValues);
      }),
    [setValueProducts],
  );

  useEffect(() => {
    serviceInvoice && resetServices(serviceInvoice);
  }, [resetServices, serviceInvoice]);

  useEffect(() => {
    productsInvoice && resetProducts(productsInvoice);
  }, [productsInvoice, resetProducts]);

  return (
    <FormTemplate pageTitle={<PageTitle title="Editar Notas fiscais" />}>
      <ContentTemplate
        loading={loadingLimitRequest}
        hasError={hasError}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => callService(id)} />
          </ErrorComponent>
        }
        render={() => (
          <>
            {!!serviceInvoice && (
              <FormTemplate
                pageTitle={
                  <OTypography className="my-4" tag="h3" size="lg" type="dark">
                    Nota fiscal de serviço
                  </OTypography>
                }
                actions={
                  <LoadingButton
                    loading={loading.service}
                    dataAction="emprestimos_notas_fiscais:botao:salvar_alteracoes"
                    dataLabel="salvar_alteracoes"
                    onClick={handleSubmitServices((values) =>
                      updateInvoice(
                        { ...serviceInvoice, ...values },
                        "service",
                      ),
                    )}
                  >
                    Salvar alterações
                  </LoadingButton>
                }
              >
                <CardTemplate>
                  <FormProvider {...invoiceServicesForm}>
                    <InvoiceForm />
                  </FormProvider>
                </CardTemplate>
              </FormTemplate>
            )}
            {!!productsInvoice && (
              <FormTemplate
                pageTitle={
                  <OTypography className="my-4" tag="h3" size="lg" type="dark">
                    Nota fiscal de produto
                  </OTypography>
                }
                actions={
                  <LoadingButton
                    loading={loading.product}
                    dataAction="emprestimos_notas_fiscais:botao:salvar_alteracoes"
                    dataLabel="salvar_alteracoes"
                    onClick={handleSubmitProducts((values) =>
                      updateInvoice(
                        { ...productsInvoice, ...values },
                        "product",
                      ),
                    )}
                  >
                    Salvar alterações
                  </LoadingButton>
                }
              >
                <CardTemplate>
                  <FormProvider {...invoiceProductsForm}>
                    <InvoiceForm disableAmount />
                    <ODataGridGenerator
                      grid={productsGrid}
                      dataSource={productsInvoice?.invoiceProducts ?? []}
                    />
                  </FormProvider>
                </CardTemplate>
              </FormTemplate>
            )}
          </>
        )}
      />
    </FormTemplate>
  );
};
