import { OField, OLabel, ORadio, ORadioGroup } from "@maestro/react";
import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useMemo } from "react";
import { GenericBankData } from "../../../../../components";
import { useIntubateOperations } from "../../intubate-operations.context";

export const BankData = () => {
  const {
    bankAccount,
    cardReceivables,
    setBankAccount,
    shouldGroupOperations,
    setShouldGroupOperations,
  } = useIntubateOperations();

  const cedenteTaxIds = useMemo(
    () => [
      ...new Set(
        cardReceivables
          .map((item) => item.CedenteIdentification)
          .filter(Boolean),
      ),
    ],
    [cardReceivables],
  );

  return (
    <div>
      <GenericBankData
        cedenteTaxIds={cedenteTaxIds}
        setBankAccount={setBankAccount}
      />
      <OField
        label="Deseja agrupar operações no mesmo grupo de desembolso?"
        htmlFor="should-group-operations"
      >
        <ORadioGroup
          dataAction="entuba_step_dados_bancarios:radio:agrupar_operacoes"
          dataLabel="agrupar_operacoes"
          value={shouldGroupOperations ? "yes" : "no"}
          onInput={(evt) =>
            setShouldGroupOperations(
              (evt.currentTarget as HTMLORadioGroupElement).value === "yes",
            )
          }
        >
          <div className="d-flex gap-2">
            <ORadio id="yes" value="yes" />
            <OLabel htmlFor="yes">Sim</OLabel>
          </div>
          <div className="d-flex gap-2">
            <ORadio id="no" value="no" />
            <OLabel htmlFor="no">Não</OLabel>
          </div>
        </ORadioGroup>
      </OField>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <NextStepButton
          disabled={!bankAccount}
          title={bankAccount ? "" : "Escolha uma conta"}
        />
      </div>
    </div>
  );
};
