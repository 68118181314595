import { env } from "utils/environments";

export const quickfinEndpoints = {
  emailCotacao: `${env.API_QUICKFIN}partners/receivables/email`,
  borderos: {
    byId: (id: string) => `${env.API_QUICKFIN}borderos/${id}`,
  },
  contracts: {
    complement: `${env.API_QUICKFIN}contracts/complement-contract`,
  },
  contractTemplates: {
    endpoint: `${env.API_QUICKFIN}contract-templates`,
    byTemplateId: {
      endpoint: (templateId: string | number) =>
        `${env.API_QUICKFIN}contract-templates/${templateId}`,
      file: (templateId: string | number) =>
        `${env.API_QUICKFIN}contract-templates/${templateId}/file`,
    },
  },
  superAdmin: {
    aditamento: `${env.API_QUICKFIN}super-admin/aditamento`,
    agendamento: `${env.API_QUICKFIN}super-admin/agendamento`,
    events: `${env.API_QUICKFIN}super-admin/events`,
    groups: {
      forceCompletion: `${env.API_QUICKFIN}super-admin/groups/force-completion`,
    },
    receivables: {
      status: `${env.API_QUICKFIN}super-admin/receivables/status`,
    },
    receivable: {
      byId: {
        group: (id: string) =>
          `${env.API_QUICKFIN}super-admin/receivable/${id}/group`,
      },
    },
    receivableStateMachine: {
      status: `${env.API_QUICKFIN}super-admin/receivable-state-machines/status`,
    },
  },
  trades: {
    byId: {
      comprovante: (id: number) =>
        `${env.API_QUICKFIN}trades/${id}/comprovante`,
    },
  },
  companyEstrategiaComercial: {
    estrategiasByCompaniesProductType: `${env.API_QUICKFIN}company-estrategia-comercial/estrategiasByCompaniesProductType`,
  },
  admin: {
    receivablesReport: `${env.API_QUICKFIN}admin/receivables/report`,
    aditamentos: {
      verify: `${env.API_QUICKFIN}admin/aditamentos/verify`,
      create: `${env.API_QUICKFIN}admin/aditamentos/create`,
    },
    balances: `${env.API_QUICKFIN}admin/balances`,
    borderos: {
      multiCreate: {
        process: `${env.API_QUICKFIN}admin/borderos/multi-create/process`,
        verify: `${env.API_QUICKFIN}admin/borderos/multi-create/verify`,
      },
    },
    companyFlags: {
      endpoint: `${env.API_QUICKFIN}admin/company-flags`,
      byId: (id: string | number) =>
        `${env.API_QUICKFIN}admin/company-flags/${id}`,
    },
    cancelableReceivables: `${env.API_QUICKFIN}admin/cancelable-receivables`,
    companies: {
      byCompanyId: {
        operations: {
          byOperationId: (
            companyId: string | number,
            operationId: string | number,
          ) =>
            `${env.API_QUICKFIN}admin/companies/${companyId}/operations/${operationId}`,
        },
        assignments: {
          byOperationId: (
            companyId: string | number,
            operationId: string | number,
          ) =>
            `${env.API_QUICKFIN}admin/companies/${companyId}/assignments/${operationId}`,
        },
      },
    },
    dashboardOnboardings: {
      infos: `${env.API_QUICKFIN}admin/dashboard-onboardings/infos`,
    },
    distinctValues: {
      byEntityName: {
        byPropertyName: (entityName: string, propertyName: string) =>
          `${env.API_QUICKFIN}admin/distinct-values/${entityName}/${propertyName}`,
      },
    },
    documentos: {
      create: `${env.API_QUICKFIN}admin/documentos/create`,
      byId: {
        zip: (id: number) => `${env.API_QUICKFIN}admin/documentos/${id}/zip`,
      },
    },
    limit: {
      cedentes: {
        endpoint: `${env.API_QUICKFIN}admin/limit/cedentes`,
        byId: (id: string | number) =>
          `${env.API_QUICKFIN}admin/limit/cedentes/${id}`,
      },
    },
    monitor: {
      resumoDiario: `${env.API_QUICKFIN}admin/monitor/resumo-diario`,
      tradesMaturing: `${env.API_QUICKFIN}admin/monitor/trades-maturing`,
    },
    onboardingStrategy: {
      endpoint: `${env.API_QUICKFIN}admin/onboardingStrategy`,
      byId: {
        endpoint: (id: string | number) =>
          `${env.API_QUICKFIN}admin/onboardingStrategy/${id}`,
        updateOnboardingType: (id: string | number) =>
          `${env.API_QUICKFIN}admin/onboardingStrategy/${id}/updateOnboardingType`,
      },
    },
    tcoStrategy: {
      endpoint: `${env.API_QUICKFIN}admin/tcoStrategy`,
      byId: {
        endpoint: (id: string | number) =>
          `${env.API_QUICKFIN}admin/tcoStrategy/${id}`,
      },
    },
    operations: {
      endpoint: `${env.API_QUICKFIN}admin/operations`,
      validate: `${env.API_QUICKFIN}admin/operations/validate`,
      antecipacao: `${env.API_QUICKFIN}admin/operations/antecipacao`,
      cancelamento: `${env.API_QUICKFIN}admin/operations/cancelamento`,
      reprocessamento: `${env.API_QUICKFIN}admin/operations/redisponibilizar`,
    },
    operationsWithoutRegistry: {
      simulate: `${env.API_QUICKFIN}admin/operations-without-registry/simulate`,
      validate: `${env.API_QUICKFIN}admin/operations-without-registry/validate`,
      endpoint: `${env.API_QUICKFIN}admin/operations-without-registry`,
    },
    receivables: {
      endpoint: `${env.API_QUICKFIN}admin/receivables`,
      export: `${env.API_QUICKFIN}admin/receivables/export`,
      approve: `${env.API_QUICKFIN}admin/receivables/approve`,
      cotacao: `${env.API_QUICKFIN}admin/receivables/cotacao`,
      fidc: {
        dailySummary: `${env.API_QUICKFIN}admin/receivables/fidc/dailySummary`,
      },
      search: `${env.API_QUICKFIN}admin/receivables/search`,
      suggest: `${env.API_QUICKFIN}admin/receivables/suggest`,
      summary: `${env.API_QUICKFIN}admin/receivables/summary`,
      searchQuotationFile: `${env.API_QUICKFIN}admin/receivables/search-quotation-file`,
      suggestQuotationFile: `${env.API_QUICKFIN}admin/receivables/suggest-quotation-file`,
      reprocess: `${env.API_QUICKFIN}admin/receivables/reprocess`,
      calculateAvgs:`${env.API_QUICKFIN}admin/receivables/calculate-avgs`,
    },
    receivableStateMachine: {
      byId: (id: string) =>
        `${env.API_QUICKFIN}admin/receivable-state-machine/${id}`,
    },
    sacadocedentetiers: {
      tiers: {
        byId: {
          endpoint: (id: string | number = "") =>
            `${env.API_QUICKFIN}admin/sacadocedentetiers/tiers/${id}`,
          sacados: {
            byId: {
              cedentes: {
                byId: (
                  tierId: string | number,
                  sacadoId: string | number,
                  cedenteId: string | number,
                ) =>
                  `${env.API_QUICKFIN}admin/sacadocedentetiers/tiers/${tierId}/sacados/${sacadoId}/cedentes/${cedenteId}`,
              },
            },
          },
        },
      },
    },
    settlements: {
      bySettlementId: (settlementId: string) =>
        `${env.API_QUICKFIN}odata/SettlementTradesOData?settlementId=${settlementId}`,
    },
    settlementStrategies: {
      bySettlementId: (settlementId: string) =>
        `${env.API_QUICKFIN}admin/settlement-strategies/${settlementId}`,
      endpoint: `${env.API_QUICKFIN}admin/settlement-strategies`,
    },
    tiers: {
      endpoint: `${env.API_QUICKFIN}admin/tiers`,
      byId: (id: string | number) => `${env.API_QUICKFIN}admin/tiers/${id}`,
      sacados: {
        endpoint: `${env.API_QUICKFIN}admin/tiers/sacados`,
        byId: (id: string | number) =>
          `${env.API_QUICKFIN}admin/tiers/sacados/${id}`,
      },
      upload: {
        endpoint: `${env.API_QUICKFIN}admin/tiers/upload`,
        preview: `${env.API_QUICKFIN}admin/tiers/upload/preview`,
      },
    },
    userFlag: {
      allAssinantesContratosCartoes: `${env.API_QUICKFIN}admin/user-flag/all-assinantes-contratos-cartoes`,
      assinantesContratosCartoes: {
        endpoint: `${env.API_QUICKFIN}admin/user-flag/assinantes-contratos-cartoes`,
        byTaxId: (taxId: string) =>
          `${env.API_QUICKFIN}admin/user-flag/assinantes-contratos-cartoes/${taxId}`,
      },
    },
    portalEstrategiaComercial: {
      createOrUpdate: `${env.API_QUICKFIN}admin/portais-estrategias-comerciais`,
    },
  },
  companies: {
    endpoint: `${env.API_QUICKFIN}companies`,
    admin: {
      getAllCompanies: `${env.API_QUICKFIN}companies/admin/get-all-companies`,
    },
    byCompanyId: {
      borderos: {
        upload: (id: string | number) =>
          `${env.API_QUICKFIN}companies/${id}/borderos/upload`,
        verify: (id: string | number) =>
          `${env.API_QUICKFIN}companies/${id}/borderos/verify`,
      },
      cedentes: {
        endpoint: (id: string | number) =>
          `${env.API_QUICKFIN}companies/${id}/cedentes`,
        contractComplement: {
          get: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/cedentes/get-complement`,
          add: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/cedentes/add-complement`,
        },
        upload: {
          endpoint: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/cedentes/upload`,
          verify: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/cedentes/upload/verify`,
        },
      },
      contractTemplates: {
        endpoint: (id: string | number) =>
          `${env.API_QUICKFIN}companies/${id}/contract-templates`,
        byTemplateId: {
          endpoint: (id: string | number, templateId: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/contract-templates/${templateId}`,
          file: (id: string | number, templateId: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/contract-templates/${templateId}/file`,
          setDefault: (id: string | number, templateId: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/contract-templates/${templateId}/set-default`,
        },
      },
      estrategias: {
        byEstrategiaId: {
          taxas: {
            endpoint: (
              companyId: string | number,
              estrategiaId: string | number,
            ) =>
              `${env.API_QUICKFIN}companies/${companyId}/estrategias/${estrategiaId}/taxas`,
            byCurrency: (
              companyId: string | number,
              estrategiaId: string | number,
              currency: string,
            ) =>
              `${env.API_QUICKFIN}companies/${companyId}/estrategias/${estrategiaId}/taxas/${currency}`,
            verify: (
              companyId: string | number,
              estrategiaId: string | number,
            ) =>
              `${env.API_QUICKFIN}companies/${companyId}/estrategias/${estrategiaId}/taxas/verify`,
          },
        },
      },
      sacados: {
        endpoint: (id: string | number) =>
          `${env.API_QUICKFIN}companies/${id}/sacados`,
        upload: {
          endpoint: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/sacados/upload`,
          verify: (id: string | number) =>
            `${env.API_QUICKFIN}companies/${id}/sacados/upload/verify`,
        },
      },
    },
    cedente: {
      sacados: {
        salesforce: `${env.API_QUICKFIN}companies/cedente/sacados/salesforce`,
      },
    },
    details: `${env.API_QUICKFIN}companies/details`,
    products: `${env.API_QUICKFIN}companies/products`,
    searchByCnpj: {
      byTaxId: (taxId: string | number) =>
        `${env.API_QUICKFIN}companies/searchByCnpj/${taxId}`,
    },
    search: {
      byUUID: (UUID: string) =>
        `${env.API_QUICKFIN}companies/searchByUUID/${UUID}`,
    },
    update: {
      byId: (id: string | number) =>
        `${env.API_QUICKFIN}companies/update/${id}`,
    },
    withOnboarding: {
      byId: (id: string | number) =>
        `${env.API_QUICKFIN}companies/with-onboarding/${id}`,
    },
  },
  estrategiaComercial: {
    getByProductType: {
      byType: (type: string) =>
        `${env.API_QUICKFIN}estrategia-comercial/getByProductType/${type}`,
    },
    getInstrumentCodeByProductType: {
      byProductType: (productType: string) =>
        `${env.API_QUICKFIN}estrategia-comercial/getInstrumentCodeByProductType/${productType}`,
    },
    getAllSimplified: {
      endpoint: `${env.API_QUICKFIN}estrategia-comercial/get-all-simplified`,
    },
    byCompanyId: {
      endpoint: (companyId: string | number) =>
        `${env.API_QUICKFIN}estrategia-comercial/${companyId}`,
      byEstrategiaId: {
        endpoint: (companyId: string | number, estrategiaId: string | number) =>
          `${env.API_QUICKFIN}estrategia-comercial/${companyId}/${estrategiaId}`,
        byAtualizaCedentes: {
          relacionamento: (
            companyId: string | number,
            estrategiaId: string | number,
            atualizaCedentes: string | number,
          ) =>
            `${env.API_QUICKFIN}estrategia-comercial/${companyId}/${estrategiaId}/${atualizaCedentes}/relacionamento`,
        },
        padrao: (companyId: string | number, estrategiaId: string | number) =>
          `${env.API_QUICKFIN}estrategia-comercial/${companyId}/${estrategiaId}/padrao`,
      },
    },
  },
  marketplaces: `${env.API_QUICKFIN}marketplaces`,
  odata: {
    aditamentoOData: `${env.API_QUICKFIN}odata/AditamentoOData`,
    adminPayeesOData: `${env.API_QUICKFIN}odata/AdminPayeesOData`,
    agendamentoView: `${env.API_QUICKFIN}odata/AgendamentoView`,
    aprovacaoODataView: `${env.API_QUICKFIN}odata/AprovacaoODataView`,
    assignmentsOData: `${env.API_QUICKFIN}odata/AssignmentsOData`,
    borderosOData: `${env.API_QUICKFIN}odata/BorderosOData`,
    cedentesCompaniesView: `${env.API_QUICKFIN}odata/CedentesCompaniesView`,
    companiesRelationshipsOData: `${env.API_QUICKFIN}odata/CompaniesRelationshipsOData`,
    contractsPendingComplement: `${env.API_QUICKFIN}odata/ContractsPendingComplementOData`,
    estrategiasComerciaisOdata: `${env.API_QUICKFIN}odata/EstrategiasComerciaisOData`,
    fidcBorderosOData: `${env.API_QUICKFIN}odata/FIDCBorderosOData`,
    fidcOperationsOData: `${env.API_QUICKFIN}odata/FIDCOperationsOData`,
    fidcReceivableStateMachineODataView: `${env.API_QUICKFIN}odata/FIDCReceivableStateMachineODataView`,
    monitorDiario: `${env.API_QUICKFIN}odata/MonitorDiario`,
    onboardingStrategiesOData: {
      endpoint: `${env.API_QUICKFIN}odata/OnboardingStrategiesOData`,
      byId: (id: string | number) =>
        `${env.API_QUICKFIN}odata/OnboardingStrategiesOData(${id})`,
    },
    tcoStrategiesOData: `${env.API_QUICKFIN}odata/TCOStrategyOData`,
    onboardingsFornecedoresOData: `${env.API_QUICKFIN}odata/OnboardingsFornecedoresOData`,
    operationsAttendanceOData: `${env.API_QUICKFIN}odata/OperationsAttendanceOData`,
    operationsOData: `${env.API_QUICKFIN}odata/OperationsOData`,
    operationStateOData: `${env.API_QUICKFIN}odata/OperationStateOData`,
    receivableStateMachineODataView: `${env.API_QUICKFIN}odata/ReceivableStateMachineODataView`,
    receivablesSalesforceOData: `${env.API_QUICKFIN}odata/ReceivablesSalesforceOData`,
    relatoriosEmLoteAdminOData: `${env.API_QUICKFIN}odata/RelatoriosEmLoteAdminOData`,
    sacadosCompaniesView: `${env.API_QUICKFIN}odata/SacadosCompaniesView`,
    settlementStrategiesOData: `${env.API_QUICKFIN}odata/SettlementStrategiesOData`,
    settlementsOData: `${env.API_QUICKFIN}odata/SettlementsOData`,
    settlementStrategiesODataById: (id: string) =>
      `${env.API_QUICKFIN}odata/SettlementStrategiesOData(${id})`,
    receivableReprocessODataView: `${env.API_QUICKFIN}odata/ReceivableReprocessODataView`,
  },
  operations: {
    attendanceByOperationId: (operationId: string) =>
      `${env.API_QUICKFIN}operations/attendance/${operationId}`,
  },
  payments: (maturityDate: string) =>
    `${env.API_QUICKFIN}trades/payments?maturityDate=${maturityDate}`,
  portal: `${env.API_QUICKFIN}portal`,
  portfolios: `${env.API_QUICKFIN}portfolios`,
  creditors: `${env.API_QUICKFIN}creditors`,
  receivables: {
    cancel: `${env.API_QUICKFIN}receivables/cancel`,
  },
  scheduling: {
    byId: {
      comprovante: (id: number) =>
        `${env.API_QUICKFIN}scheduling/${id}/comprovante/true`,
    },
  },
  templateEstrategiaComercial: {
    endpoint: `${env.API_QUICKFIN}template-estrategia-comercial`,
    byEstrategiaId: {
      byAtualizaEstrategias: {
        byAtualizaCedentes: {
          relacionamento: (
            estrategiaId: string | number,
            atualizaEstrategias: string | boolean,
            atualizaCedentes: string | boolean,
          ) =>
            `${env.API_QUICKFIN}template-estrategia-comercial/${estrategiaId}/${atualizaEstrategias}/${atualizaCedentes}/relacionamento`,
        },
      },
      configTaxa: (estrategiaId: string | number) =>
        `${env.API_QUICKFIN}template-estrategia-comercial/${estrategiaId}/configTaxa`,
    },
  },
  v2: {
    summaries: (identification: string, groupBy: string) =>
      `${env.API_QUICKFIN}api/v2/summaries/${identification}/${groupBy}`,
  },
};
