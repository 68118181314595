import {
  OCheckbox,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
} from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ClosingReasonForm } from "./closing-reason.types";

interface ClosingReasonFieldsProps {
  form: UseFormReturn<ClosingReasonForm>;
  reasons: string[];
}

export const ClosingReasonFields = ({
  form,
  reasons,
}: ClosingReasonFieldsProps) => {
  const { watch } = form;

  const reasonsWatcher = watch("reasons");

  const showOtherReasons = reasonsWatcher.includes("Outros motivos");

  return (
    <FormProvider {...form}>
      <ORFieldCheckboxGroup
        dataAction="form:checkbox:motivos"
        dataLabel="motivos"
        id="reasons"
        name="reasons"
        label="Selecione o(s) motivo(s) do encerramento da(s) conta(s)"
        required
      >
        <div className="d-flex flex-column gap-3">
          {reasons.map((reason) => (
            <div className="d-flex align-items-center gap-2" key={reason}>
              <OCheckbox id={reason} name={reason} value={reason} size="sm" />
              <OLabel htmlFor={reason}>{reason}</OLabel>
            </div>
          ))}
        </div>
      </ORFieldCheckboxGroup>
      {showOtherReasons && (
        <ORFieldInput
          dataAction="form:texto:outros_motivos"
          dataLabel="outros_motivos"
          id="otherReasons"
          name="otherReasons"
          tag="text"
          label="Informe mais detalhes do motivo do encerramento da conta"
          placeholder="Preencher"
          required
        />
      )}
    </FormProvider>
  );
};
