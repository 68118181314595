import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { FormTemplate } from "templates/form-template";
import { RecebiveisClienteAssinaturasByRuleIdRouteParams } from "../../../../../routes/recebiveis.route-params";
import { SignatureGroup } from "../../_compose";

export const AddSignatureGroup = () => {
  const { ruleId } =
    useParams<RecebiveisClienteAssinaturasByRuleIdRouteParams>();
  if (!ruleId) throw new Error("No ruleId");

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar grupo de assinaturas" />}
    >
      <SignatureGroup disabled={false} ruleId={Number(ruleId)} />
    </FormTemplate>
  );
};
