import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, generalHealthGrid } from "./general-health.grid";

export const GeneralHealth = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Visão geral"
          description="Esse painel mostra indicadores de saúde geral do sistema RecebiveisCartao. Caso algo esteja diferente de 'ok', contate a equipe técnica."
        />
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={generalHealthGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
