import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { useGridRef } from "hooks/grid-ref";
import { ODataGridGenerator } from "components/data-grid";
import { CardTemplate } from "templates/card-template";
import { FormProvider } from "react-hook-form";
import { OButton, modalManager } from "@maestro/react";
import { DetailsTemplate } from "templates/details-template";
import { useState } from "react";
import { consultaOptinsGrid } from "./consulta-optins.grid";
import { CompanySearch } from "../../entuba-operacoes/_steps/_compose/company-search/company-search.component";
import { useOptinView } from "./consulta-optins.hooks";
import { ConfirmationModal } from "./confirmation-modal/confirmation-modal.component";
import { useOptinContext } from "./consulta-optin.context";
import {
  OptOutModal,
  optOutModalId,
} from "./confirmation-modal/opt-out-confirmation-modal.component";

const FormComponent = () => {
  const [input, setInput] = useState<string | undefined>("");
  const [resetInput, setResetInput] = useState(false);

  const { setCnpjs } = useOptinContext();

  const { form, clear, submit, watchEmpresas } = useOptinView(input, setCnpjs);

  const disabledOptOut = !input && watchEmpresas.length === 0;

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-4 mb-5">
        <CompanySearch
          mode="Cedente"
          label="Nome ou identificação das empresas"
          name="empresas"
          placeholder="Selecione a(s) empresa(s)"
          multiple
          setInput={setInput}
          resetInput={resetInput}
        />
      </div>

      <div className="d-flex justify-content-between mt-5">
        <OButton
          type="dark"
          outline
          onClick={() => {
            clear();
            setResetInput((prevReset) => !prevReset);
            submit();
          }}
        >
          Limpar
        </OButton>
        <div className="d-flex flex-row gap-4">
          <OButton
            onClick={() => {
              modalManager.show(optOutModalId);
              submit();
            }}
            disabled={disabledOptOut}
            type="danger"
          >
            Fazer Opt-out
          </OButton>

          <OButton
            onClick={() => {
              submit();
            }}
          >
            Buscar
          </OButton>
        </div>
      </div>
    </FormProvider>
  );
};

export const ConsultaOptinsContent = () => {
  const gridRef = useGridRef();

  const { cnpjs, optOutFilter, setOptOutFilter } = useOptinContext();

  const grid = consultaOptinsGrid(cnpjs, optOutFilter);

  modalManager.on(optOutModalId, "modalOpen", () =>
    setOptOutFilter(["status", "=", "aceito"]),
  );
  modalManager.on(optOutModalId, "modalClose", () => setOptOutFilter([]));

  return (
    <>
      <DetailsTemplate
        pageTitle={
          <PageTitle
            title="Consulta de Optins"
            description="Consulte todos os Optins registrados de acordo com os CNPJs buscados. A opção de realizar Opt-out é disponível para todos os Optins com Status 'Aceito'.
                        Logo ao enviar o Optin, seu Status é 'Iniciado' e, após processado, 'Solicitado'. O Optin pode receber o status 'Aceito' ou 'Rejeitado' com a justificativa
                        em sua descrição. Ao realizar o Opt-out pelo CNPJ ou pelo Optin (coluna no final da tabela), seu status é alterado para 'Opt-out solicitado' e, posteriormente, 'Opt-out'."
          />
        }
      >
        <div className="mb-5">
          <CardTemplate>
            <FormComponent />
          </CardTemplate>
        </div>
        <GridTemplate
          showClearFiltersButton
          showRefreshButton
          showExportButton
          gridRef={gridRef}
        >
          <ODataGridGenerator gridRef={gridRef} grid={grid} />
        </GridTemplate>
      </DetailsTemplate>
      <ConfirmationModal />
      <OptOutModal />
    </>
  );
};
