import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { ContentTemplate } from "templates/content-template";
import {
  AccountCloseIdsParams,
  accountCloseIdsParam,
} from "./render-only-if-params.types";

interface RenderOnlyIfParamsProps {
  render: (ids: number[]) => JSX.Element;
}

export const RenderOnlyIfParams = ({ render }: RenderOnlyIfParamsProps) => {
  const { value: ids, hasError } =
    useParseFromSearchParams<AccountCloseIdsParams>(accountCloseIdsParam);

  return (
    <ContentTemplate
      hasError={hasError || !ids?.length}
      value={ids}
      render={render}
    />
  );
};
