import {
  EligibilityCriteria,
  EligibilityGroup,
  CriteriaParams,
} from "../../eligibility-tree.types";
import { CriteriaFields } from "./edit-eligibility-group-modal.types";

const assembleCriteriaParams = (fields: CriteriaFields) => {
  return {
    id: fields.criteriaParamsId,
    active: fields.active,
    operation: fields.operation,
    value: fields.value,
    eligibilityCriteriaId: fields.eligibilityCriteriaId,
    isNewlyCreated: fields.isNewlyCreated,
  } as CriteriaParams;
};

const assembleEligibilityCriteria = (
  fields: CriteriaFields,
  group: EligibilityGroup,
  isNewlyCreated = false,
  criteriaParams: CriteriaParams[] = [],
) => {
  return {
    id: fields.eligibilityCriteriaId,
    type: fields.criterium,
    eligibilityGroupId: group.id ?? 0,
    active: true,
    isNewlyCreated,
    criteriaParams,
  } as EligibilityCriteria;
};

const inactivateEligibilityCriteriaWithEmptyParams = (
  eligibilityCriteriaList: EligibilityCriteria[],
) => {
  return eligibilityCriteriaList.map((eligibilityCriteria) => {
    if (eligibilityCriteria.criteriaParams.some((param) => param.active))
      return eligibilityCriteria;
    return { ...eligibilityCriteria, active: false };
  });
};

export function editGroup(
  currentGroup: EligibilityGroup,
  groupName: string,
  criteriaList: CriteriaFields[],
) {
  const eligibilityCriteria = criteriaList
    .filter((value) => !value.isNewlyCreated)
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.criterium === value.criterium),
    )
    .map((criteriaField) => {
      return assembleEligibilityCriteria(criteriaField, currentGroup);
    });

  criteriaList.forEach((criteriaField) => {
    const criteriaExists = eligibilityCriteria.find(
      (y) => y.type === criteriaField.criterium,
    );

    const params = assembleCriteriaParams(criteriaField);

    if (criteriaExists) {
      criteriaExists.criteriaParams.push(params);
    } else {
      const newEligibilityCriteria = assembleEligibilityCriteria(
        criteriaField,
        currentGroup,
        true,
        [params],
      );
      eligibilityCriteria.push(newEligibilityCriteria);
    }
  });

  const updatedGroup: EligibilityGroup = {
    ...currentGroup,
    groupName,
    eligibilityCriteria:
      inactivateEligibilityCriteriaWithEmptyParams(eligibilityCriteria),
  };

  return updatedGroup;
}
