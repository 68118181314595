import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Desk,
  DeskMigrationOptionsForm,
} from "../../desk-migration-options.types";

interface DeskSelectorProps {
  desks: Desk[] | undefined;
  loading: boolean;
  hasError: boolean;
  refetch: () => void;
}

const name = "deskCode";

export const DeskSelector = ({
  desks,
  hasError,
  loading,
  refetch,
}: DeskSelectorProps) => {
  const form = useFormContext<DeskMigrationOptionsForm>();

  const { clearErrors, setError } = form;

  useEffect(() => {
    if (hasError) {
      setError(name, {
        message:
          "Erro ao buscar as mesas. Clique no botão ao lado para tentar novamente.",
      });
    } else {
      clearErrors(name);
    }
  }, [clearErrors, hasError, setError]);

  return (
    <div className="d-flex align-items-center flex-fill">
      <ORFieldSelect
        dataAction="formulario:select:mesa"
        dataLabel="mesa"
        id={name}
        name={name}
        label="Mesa"
        placeholder="Selecionar"
        disabled={loading}
        required
        key={desks?.length}
      >
        {desks?.map((desk) => (
          <OOption key={desk.deskCode} value={desk.deskCode}>
            {desk.deskCode} - {desk.deskDescription}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasError && !loading && (
        <RefreshGridButton
          dataAction="formulario:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => refetch()}
        />
      )}
    </div>
  );
};
