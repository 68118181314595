import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { modalManager } from "@maestro/core";

import { ConfirmationDeleteMinimumCriteriaModal } from "./_compose/delete-criteria-modal/delete-criteria-modal.component";
import { deleteMinimumCriteriaModalId } from "./_compose/delete-criteria-modal/delete-criteria-modal.utils";

type MinimumCriteriaColumns = Awaited<
  ReturnType<typeof service.hubCreditManager.getMinimumCriteria>
>["data"][number];

export const MinimumCriteriaGrid = {
  datagrid: {
    noDataText: "Nenhuma critério mínimo encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getMinimumCriteria().catch(() => {
        throw new Error("Não foi possível buscar os critérios mínimos.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
    paging: { pageSize: 10 },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "title",
      caption: "Título",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "isVisible",
      dataType: "boolean",
      caption: "Visível?",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                role: roles.workflow.product
                  .configurationsEditMinimumCriteriaPage.role,
                route: `${corporateRouter.routes.workflow.product.configurations.minimumCriteria.edit.path(
                  {
                    id: data.id,
                  },
                )}?data=${encodeURIComponent(JSON.stringify(data))}`,
              },
              {
                icon: { category: "orq", icon: "orq-edit-trash" },
                label: "Remover",
                onClick: () =>
                  modalManager.show(deleteMinimumCriteriaModalId(data.id)),
              },
            ]}
          />
          <ConfirmationDeleteMinimumCriteriaModal
            id={data.id}
            reload={() => component.getDataSource().reload()}
          />
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<MinimumCriteriaColumns>;