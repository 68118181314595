import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useBooleanPropertyField } from "./boolean-property-field.hook";

export const BooleanPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, processorConfigId, name, description } =
    processorConfigProperty;

  useBooleanPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const genericPropertyField = useMemo(
    () => (
      <ORFieldCheckboxGroup
        key={`properties.${name}_${processorPropertyId}`}
        id={`properties.${name}_${processorPropertyId}`}
        name={`properties.${name}_${processorPropertyId}`}
      >
        <div className="d-flex align-items-center gap-2 mb-2">
          <OCheckbox
            size="xs"
            id={`single-parent-checkbox-${name}`}
            value="true"
          />
          <OLabel htmlFor={`single-parent-checkbox-${name}`}>
            {description}
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
    ),
    [processorPropertyId, processorConfigId],
  );

  return <>{genericPropertyField}</>;
};
