import styled from "styled-components";

export const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--theme-dark-20);
  padding: var(--font-xs) var(--font-md);
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--theme-light);
`;
