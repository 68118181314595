import { OToastManager, OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { service } from "services/service";

interface ResendWelcomeEmailButtonProps {
  portal: string;
  email: string | null;
  taxId: string | null;
}

export const ResendWelcomeEmailButton = ({
  portal,
  email,
  taxId,
}: ResendWelcomeEmailButtonProps) => {
  const handle = async () => {
    try {
      await service.onboardingRelationships.postAdminToolsUserWelcomeEmail({
        portal,
        usersCompanies: [{ relatedTaxId: null, taxId }],
      });
      OToastManager.success("E-mail reenviado com sucesso.");
    } catch {
      OToastManager.danger("Falha ao reenviar e-mail.");
    }
  };

  return (
    <ButtonWithConfirmation
      type="quaternary"
      confirmationModal={{ title: "Reenviar e-mail de boas-vindas" }}
      modalBody={
        <OTypography>
          Tem certeza que deseja reenviar e-mail de boas-vindas para{" "}
          {email ?? "o usuário selecionado"}
        </OTypography>
      }
      dataAction="usuario_produto:botao:reenviar_enviar_boas_vindas"
      dataLabel="reenviar_enviar_boas_vindas"
      onConfirm={() => handle()}
      outline
      disabled={!taxId}
    >
      Reenviar boas-vindas
    </ButtonWithConfirmation>
  );
};
