import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { companyMonitorGrid } from "../company-monitor.grid";

const grid = companyMonitorGrid("DATA_UPDATE");

export const DataUpdateMonitor = () => {
    const gridRef = useGridRef();
    return (
        <GridTemplate
            pageTitle={<PageTitle title="Atualização Cadastral" />}
            gridRef={gridRef}
            showClearFiltersButton
            showRefreshButton
            showExportButton
        >
            <ODataGridGenerator gridRef={gridRef} grid={grid} />
        </GridTemplate>
    );
};
