import { OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useConditionPropertyField } from "./condition-property-field.hook";

export const ConditionPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, processorConfigId, name, description } =
    processorConfigProperty;
  const { allConditions } = useConditionPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const formPropertyField = useMemo(
    () => (
      <ORFieldSelect
        key={allConditions?.map((cond) => cond.conditionName).join("")}
        id={`properties.${name}_${processorPropertyId}`}
        name={`properties.${name}_${processorPropertyId}`}
        placeholder="Selecione"
        labelSize="md"
        label={description}
      >
        <div>
          {allConditions?.map((cond) => (
            <OOption key={cond.conditionName} value={cond.conditionName}>
              {cond.description}
            </OOption>
          ))}
          <OOption
            value=""
            key={`${name.toLowerCase()}-condition-property-null`}
          >
            Nenhum
          </OOption>
        </div>
      </ORFieldSelect>
    ),
    [allConditions, processorPropertyId, processorConfigId],
  );

  return <>{formPropertyField}</>;
};
