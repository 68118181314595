import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useProcessorConfigField } from "./processor-config-field.hook";

export const ProcessorConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, processorConfigId, name, description } =
    processorConfigProperty;
  const { chidProcessorConfigs, loading } = useProcessorConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const processorConfigField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={chidProcessorConfigs?.map((config) => config.id).join("")}
          id={`properties.${name}_${processorPropertyId}`}
          name={`properties.${name}_${processorPropertyId}`}
          placeholder="Selecione"
          label={description}
        >
          {chidProcessorConfigs?.map((config) => (
            <OOption key={config.id} value={config.id?.toString()}>
              {config.name}
            </OOption>
          ))}
        </ORFieldSelect>
        {loading && <OLoader />}
      </div>
    ),
    [
      name,
      description,
      processorPropertyId,
      chidProcessorConfigs,
      processorConfigId,
      loading,
    ],
  );

  return <>{processorConfigField}</>;
};
