import {
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
} from "@maestro/react";

export interface LoanPartnersFormValues {
  name: string | null;
  taxId?: string | null;
  creditEngineName?: string | null;
  webHook?: string | null;
  webHookAuthParams?: string | null;
  webHookAuthType: string | null;
  address?: string | null;
  agreementIdentification?: string | null;
  agreementSignatureDate?: string | null;
  email?: string | null;
  developersAdditionalParams?: string | null;
  webHookDefaultHeaders?: string | null;
  isFromDevelopersSender?: string[];
}

export const LoanPartnerForm = () => {
  return (
    <>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="text"
            id="name"
            name="name"
            dataAction="emprestimos_parceiros_form:text:nome_parceiro"
            dataLabel="nome_parceiro"
            label="Nome do parceiro"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="cpfcnpj"
            id="taxId"
            name="taxId"
            dataAction="emprestimos_parceiros_form:text:cpf_cnpj"
            dataLabel="cpf_cnpj"
            label="CNPJ / CPF"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="text"
            id="creditEngineName"
            name="creditEngineName"
            dataAction="emprestimos_parceiros_form:text:motor_credito"
            dataLabel="motor_credito"
            label="Motor de crédito"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="text"
            id="webHook"
            name="webHook"
            dataAction="emprestimos_parceiros_form:text:webHook"
            dataLabel="webHook"
            label="Webhook"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="text"
            id="webHookAuthParams"
            name="webHookAuthParams"
            dataAction="emprestimos_parceiros_form:text:parametros_autenticacao_webhook"
            dataLabel="parametros_autenticacao_webhook"
            label="Parâmetro de autenticação do webhook"
          />
        </OCol>
        <OCol>
          <ORFieldSelect
            id="webHookAuthType"
            name="webHookAuthType"
            dataAction="emprestimos_parceiros_form:select:tipo_autenticacao_webhook"
            dataLabel="tipo_autenticacao_webhook"
            label="Tipo de autenticação do webhook"
          >
            <OOption value="Default">Default</OOption>
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="text"
            id="address"
            name="address"
            dataAction="emprestimos_parceiros_form:text:endereco"
            dataLabel="endereco"
            label="Endereço"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="text"
            id="agreementIdentification"
            name="agreementIdentification"
            dataAction="emprestimos_parceiros_form:text:identificacao_convenio"
            dataLabel="identificacao_convenio"
            label="Identificação do convênio"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <ORFieldInputDate
            size="md"
            id="agreementSignatureDate"
            name="agreementSignatureDate"
            label="Data de assinatura do convênio"
          />
        </OCol>
        <OCol>
          <ORFieldInput
            tag="text"
            id="email"
            name="email"
            dataAction="emprestimos_parceiros_form:text:email"
            dataLabel="email"
            label="E-mail"
          />
        </OCol>
      </ORow>
      <div className="d-flex gap-4">
        <OCol>
          <ORFieldCheckboxGroup
            id="isFromDevelopersSender"
            name="isFromDevelopersSender"
          >
            <div className="d-flex align-items-center gap-2">
              <OCheckbox size="xs" id="showOnTabs-checkbox" value="1" />
              <OLabel htmlFor="showOnTabs-checkbox">
                Parceiro via Developers?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol>
          <ORFieldInput
            tag="text"
            id="developersAdditionalParams"
            name="developersAdditionalParams"
            label="Parâmetros adicionais do Developers"
          />
        </OCol>
      </div>
      <OCol>
        <ORFieldInput
          tag="text"
          id="webHookDefaultHeaders"
          name="webHookDefaultHeaders"
          label="Headers padrão"
        />
      </OCol>
    </>
  );
};
