import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { OOption, ORFieldInput, ORFieldSelect } from "@maestro/react";
import { validators } from "@maestro/utils";
import { AxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowErrorResponse } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { brazilStates } from "services/public/models/types/brazil-states.utils";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  addResaleFormDefaultValues,
  addResaleFormValidationSchema,
} from "./add-resale-form.schemas";

export const AddResalePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(addResaleFormValidationSchema),
    defaultValues: addResaleFormDefaultValues,
  });
  const { handleSubmit, reset, watch, setValue, getValues } = form;

  const taxIdWatch = watch("identification");

  const submitResale = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.createCustomerOriginator({
            ...values,
            type: "REVENDA",
          });

          reset();

          OToastManager.success("Empresa cadastrada com sucesso.");

          navigate(corporateRouter.routes.workflow.product.resales.path);
        } catch (e) {
          const error = e as AxiosError<WorkflowErrorResponse>;

          if (
            error?.response?.data?.failures?.some(
              (f) => f.errorCode === "OriginatorAlreadyExists",
            )
          ) {
            OToastManager.danger("Empresa já cadastrada na base.");
          } else {
            OToastManager.danger("Não foi possível cadastrar a empresa.");
          }
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, reset],
  );

  const getLegalEntity = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await service.onboarding.searchLegalEntity({
        taxId: getValues("identification"),
      });

      setValue("name", data.name ?? data.officialName);
    } catch {
      OToastManager.danger(
        "Não foi possível buscar dados da contraparte. Por favor, tente novamente.",
      );
    } finally {
      setLoading(false);
    }
  }, [getValues, setValue]);

  useEffect(() => {
    if (!validators.cnpj(taxIdWatch)) {
      setValue("name", "");
      return;
    }

    getLegalEntity();
  }, [setValue, taxIdWatch, getLegalEntity]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Parceiro/Revenda" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_revenda:botao:enviar"
          dataLabel="enviar"
          onClick={() => submitResale()}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex gap-2">
              <ORFieldInput
                id="identification"
                name="identification"
                tag="cnpj"
                label="CNPJ"
                required
                labelSize="md"
                dataAction="adicionar_revenda:texto:cnpj"
                dataLabel="cnpj"
              />
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Nome"
                required
                labelSize="md"
                dataAction="adicionar_revenda:texto:nome"
                dataLabel="nome"
                disabled
              />
            </div>
            <div className="d-flex gap-2">
              <ORFieldSelect
                id="state"
                name="state"
                label="Selecione o Estado"
                placeholder="Informar"
                labelSize="md"
                dataAction="adicionar_revenda:texto:state"
                dataLabel="state"
                size="md"
              >
                <div>
                  {brazilStates.map((state) => (
                    <OOption
                      value={state.uf.toString()}
                      key={state.uf.toString()}
                    >
                      {state.uf}
                    </OOption>
                  ))}
                </div>
              </ORFieldSelect>
              <ORFieldInput
                id="city"
                name="city"
                tag="text"
                label="Cidade"
                required
                labelSize="md"
                dataAction="adicionar_revenda:texto:cidade"
                dataLabel="cidade"
              />
            </div>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
