import { OToastManager, modalManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useBankslipContext } from "../../bankslip.context";
import { CancelBankslipModalId } from "./cancel-bankslip-modal.types";

export const useCancelBankslip = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { bankslipToCancel, refreshGrid } = useBankslipContext();

  const cancelCollection = useCallback(async () => {
    try {
      if (!bankslipToCancel?.collectionId) return;

      setIsLoading(true);
      modalManager.hide(CancelBankslipModalId);
      await service.adminBankinghub.cancelCollectionById(
        bankslipToCancel.collectionId,
      );
      OToastManager.success("O cancelamento do boleto está sendo processado.");
      refreshGrid();
    } catch {
      OToastManager.danger("Ocorreu um erro ao cancelar o boleto");
    } finally {
      setIsLoading(false);
    }
  }, [bankslipToCancel?.collectionId, refreshGrid]);

  return { isLoading, cancelCollection };
};
