import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, inviteTypesGrid } from "./invite-type.grid";

export const InviteTypePage = () => {
  const gridRef = useGridRef();
  const { routes } = corporateRouter;

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Tipos de convite" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
      actions={
        <RouterButton
          href={
            routes.ferramentas.product.onboarding.inviteConfigs.inviteTypes
              .register.path
          }
          role={
            roles.ferramentas.product.onboardingInviteConfigsInviteTypesAddPage
              .role
          }
        >
          Adicionar
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={inviteTypesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
