import { ExpiringPowersUserNotification } from "./_compose";
import { InactiveUserNotification } from "./_compose";

export const NotificationArea = () => {
  return (
    <>
      <ExpiringPowersUserNotification />
      <InactiveUserNotification />
    </>
  )
};
