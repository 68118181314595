import { OButton, ODivider, OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { MleByDiligenceEventRuleIdRouteParams } from "../../../../../routes/energia.route-params";
import { RuleComponent } from "../_compose";
import { ActionComponent } from "../_compose/action-component";
import { dummyAction } from "./event-rule-details.utils";

export const EventRuleDetailsPage = () => {
  const { diligenceEventRuleId } =
    useParams<MleByDiligenceEventRuleIdRouteParams>();
  if (!diligenceEventRuleId) throw new Error("No diligenceEventRuleId");

  const { callService, error, hasError, loading, value } = useServiceCall(
    service.hubEnergy.getDiligenceEventRule,
  );

  const [rule, setRule] = useState<HubEnergy.DiligenceEventRuleResponse>();

  const [actions, setActions] = useState<
    HubEnergy.DiligenceEventActionSimpleResponse[]
  >([]);

  const getRule = useCallback(
    () => callService(diligenceEventRuleId),
    [callService, diligenceEventRuleId],
  );

  useEffect(() => {
    getRule();
  }, [getRule]);

  useEffect(() => {
    setRule(value?.response);
    setActions(value?.response.actions ?? []);
  }, [value]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes da regra de evento" />}
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <ValidationErrorComponent
            title="Erro ao buscar regra de evento"
            error={error}
            callback={getRule}
          />
        }
        value={rule}
        render={(_rule) => (
          <div className="d-flex flex-column gap-4">
            <DetailsTemplate
              pageTitle={
                <OTypography type="dark" size="lg" className="mb-2">
                  Regra
                </OTypography>
              }
            >
              <RuleComponent
                initialMode="display"
                rule={_rule}
                setRule={setRule}
              />
            </DetailsTemplate>
            <FormTemplate
              pageTitle={
                <OTypography type="dark" size="lg" className="mb-2">
                  Ações
                </OTypography>
              }
              actions={
                <OButton
                  onClick={() =>
                    setActions((old) => [
                      ...old,
                      { ...dummyAction, id: new Date().getTime() },
                    ])
                  }
                >
                  Nova ação
                </OButton>
              }
            >
              <div className="d-flex flex-column gap-4">
                {actions.map((a, idx) => (
                  <Fragment key={a.id}>
                    <ActionComponent
                      rule={_rule}
                      initialMode={a.ruleId === 0 ? "editing" : "display"}
                      action={a}
                      setActions={setActions}
                    />
                    {idx !== actions.length - 1 && <ODivider size="xxs" />}
                  </Fragment>
                ))}
              </div>
            </FormTemplate>
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
