import {
  OButton,
  OCol,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldRadioGroup,
  ORadio,
  ORow,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";

interface FormValuesType {
  force: "true" | "false";
}

interface ReopenDataReviewProps {
  workflowUuid: string;
  modalId: string;
}

export const ReopenDataReview = ({
  modalId,
  workflowUuid,
}: ReopenDataReviewProps) => {
  const { customer } = useCustomer();

  const form = useForm<FormValuesType>({ defaultValues: { force: "false" } });
  const { handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          if (values.force === "true") {
            await service.onboarding.reopenDataReviewForce({
              taxId: customer.identification,
              workflowUuid,
            });
          } else {
            await service.onboarding.reopenDataReview({
              taxId: customer.identification,
              workflowUuid,
            });
          }

          OToastManager.info("Revisão reaberta com sucesso.");

          modalManager.hide(modalId);
        } catch (error) {
          OToastManager.warning("Erro ao reabrir revisão.");
        } finally {
          setLoading(false);
        }
      }),
    [customer.identification, handleSubmit, modalId, workflowUuid],
  );

  return (
    <OModal position="center" id={modalId}>
      <OModalHeader closeButton>
        <PageTitle title="Reabertura da revisão cadastral" />
        <OTypography>{`Id: ${workflowUuid}`}</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-3">
              <ORFieldRadioGroup id="force" name="force">
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isRemoveTrue"
                      name="isRemoveTrue"
                      value="false"
                      size="xs"
                    />
                    <OLabel htmlFor="isRemoveTrue" size="md">
                      Reabertura de revisão para o cliente realizar edição
                    </OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isRemoveFalse"
                      name="isRemoveFalse"
                      value="true"
                      size="xs"
                    />
                    <OLabel htmlFor="isRemoveFalse" size="md">
                      Reabertura do fluxo de revisão após cliente ter solicitado
                      encerramento das contas
                    </OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton outline onClick={() => modalManager.hide(modalId)}>
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={() => submit()}>
            Confirmar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
