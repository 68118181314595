export enum BtgIconAvatarType {
  Investment = "investment",
  Business = "business",
  Banking = "banking",
}

export interface BtgIconAvatarProps {
  type: BtgIconAvatarType;
  size?: HTMLOAvatarElement["size"];
  isLoading?: boolean;
}

export const MapperBtgIconAvatarTypeAlt: Record<BtgIconAvatarType, string> = {
  [BtgIconAvatarType.Investment]: "BTG Investimentos",
  [BtgIconAvatarType.Business]: "BTG Empresas",
  [BtgIconAvatarType.Banking]: "BTG Banking",
};
