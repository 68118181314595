import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useEditStepConfigContext } from "../../edit-step-config.context";
import { propertyConfigsGrid } from "./property-configs-grid.grid";
import { usePropertyConfigsGrid } from "./property-configs-grid.hook";

interface PropertyConfigsGridProps {
  diligenceStepConfigId: number;
}

export const PropertyConfigsGrid = ({
  diligenceStepConfigId,
}: PropertyConfigsGridProps) => {
  const gridRef = useGridRef();

  const {
    documentTypesLoading,
    documentTypesValue,
    propertyTypesLoading,
    propertyTypesValue,
  } = useEditStepConfigContext();

  const { dataSource } = usePropertyConfigsGrid(diligenceStepConfigId);

  const grid = useMemo(
    () =>
      propertyConfigsGrid(
        propertyTypesValue?.response ?? [],
        documentTypesValue?.response ?? [],
      ),
    [documentTypesValue?.response, propertyTypesValue?.response],
  );

  return (
    <OCard>
      <OCardHeader>
        <OTypography weight="500">Propriedades</OTypography>
      </OCardHeader>
      <OCardBody>
        <GridTemplate
          actions={
            <OButton onClick={() => gridRef.current?.instance.addRow()}>
              <OIcon category="orq" icon="orq-add-plus" />
            </OButton>
          }
          gridRef={gridRef}
          showExportButton
          showRefreshButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            dataSource={dataSource}
            loading={documentTypesLoading || propertyTypesLoading}
          />
        </GridTemplate>
      </OCardBody>
    </OCard>
  );
};
