import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCheckbox,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { ComponentProperty } from "../../../component-info.types";
import {
  propertyModalFormDefaultValues,
  propertyModalFormValidationSchema,
} from "./component-properties-modal-form.schemas";

interface ComponentDetailsProps {
  id: string;
  component: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponentsById>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getComponentsById>
      >["data"]
    >
  >;
  selectedProperty: ComponentProperty | undefined;
}

const componentPropertyType = [
  "FORM",
  "TEXT",
  "NUMBER",
  "INTEGER",
  "FILE",
  "CONDITION",
  "BOOLEAN",
  "BATCH_TEMPLATE",
];

export const COMPONENT_PROPERTY_MODAL_ID = "component-property-modal";

export const ComponentPropertyModal = ({
  id,
  component,
  selectedProperty,
}: ComponentDetailsProps) => {
  const form = useForm({
    resolver: yupResolver(propertyModalFormValidationSchema),
    defaultValues: propertyModalFormDefaultValues,
  });

  const { reset, handleSubmit, getValues } = form;

  const { hide } = modalManager;

  const { callService: getComponentsDetails } = component;

  const {
    callService: editComponentProperty,
    loading: editComponentPropertyLoading,
  } = useServiceCall(service.hubCreditManager.editComponentProperty);

  const {
    callService: addComponentProperty,
    loading: addComponentPropertyLoading,
  } = useServiceCall(service.hubCreditManager.addComponentProperty);

  useEffect(() => {
    if (selectedProperty) {
      reset({
        type: selectedProperty.type,
        description: selectedProperty.description,
        name: selectedProperty.name,
        isRequired: selectedProperty.isRequired ? ["1"] : [],
        allowedValues: selectedProperty.allowedValues.join(","),
      });
    } else {
      reset(propertyModalFormDefaultValues);
    }
  }, [reset, selectedProperty, getValues]);

  const addProperty = useMemo(
    () =>
      handleSubmit(async (values) => {
        const allowedValues = values.allowedValues
          ? values.allowedValues.split(",")
          : null;

        const isRequired = !!values.isRequired.length;

        const { success } = await addComponentProperty({
          ...values,
          componentId: Number(id),
          type: values.type,
          description: values.description,
          name: values.name,
          allowedValues,
          isRequired,
        });

        if (success) {
          OToastManager.success("Propriedade salva com sucesso.");
          hide(COMPONENT_PROPERTY_MODAL_ID);

          getComponentsDetails(id);

          reset();
        } else {
          OToastManager.danger(
            "Não foi possível salvar a propriedade. Por favor, tente novamente mais tarde.",
          );
        }
      }),
    [handleSubmit, id, hide, getComponentsDetails, reset, addComponentProperty],
  );

  const editProperty = useCallback(
    async (propertyId: number) => {
      const { allowedValues } = getValues();

      const updatedAllowedValues = allowedValues
        ? allowedValues.split(",")
        : null;

      const { type, description, name, isRequired } = getValues();

      const { success } = await editComponentProperty({
        name,
        componentPropertyId: propertyId,
        description,
        type,
        allowedValues: updatedAllowedValues,
        isRequired: !!isRequired.length,
      });

      if (success) {
        OToastManager.success("Propriedade editada com sucesso.");
        hide(COMPONENT_PROPERTY_MODAL_ID);

        getComponentsDetails(id);
      } else OToastManager.danger("Não foi possível editar a propriedade.");
    },
    [getValues, hide, getComponentsDetails, id, editComponentProperty],
  );

  return (
    <OModal id={COMPONENT_PROPERTY_MODAL_ID} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography
          tag="h1"
          size="xxl"
          key={`property-modal-title-${selectedProperty}`}
        >
          {`${selectedProperty ? "Editar" : "Adicionar"} propriedade`}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORFieldSelect
            id="type"
            name="type"
            label="Tipo"
            labelSize="lg"
            dataAction="adicionar_propriedade_componente:select:tipo"
            dataLabel="tipo"
          >
            <div>
              {componentPropertyType.map((value) => (
                <OOption key={value} value={value}>
                  {value}
                </OOption>
              ))}
            </div>
          </ORFieldSelect>
          <ORFieldInput
            id="description"
            name="description"
            tag="text"
            label="Descrição"
            labelSize="lg"
            dataAction="adicionar_propriedade_componente:texto:descricao"
            dataLabel="descricao"
          />
          <ORFieldInput
            id="name"
            name="name"
            tag="text"
            label="Nome"
            tooltip="Ex.=NOME_PROPRIEDADE"
            tooltipPosition="top-right"
            labelSize="lg"
            dataAction="adicionar_propriedade_componente:texto:nome"
            dataLabel="nome"
          />
          <ORFieldInput
            id="allowedValues"
            name="allowedValues"
            tag="text"
            tooltip="Valores separados por vírgula"
            tooltipPosition="top-right"
            label="Valores permitidos"
            labelSize="lg"
            dataAction="adicionar_propriedade_componente:texto:valores_permitidos"
            dataLabel="valores_permitidos"
          />
          <ORFieldCheckboxGroup
            id="isRequired"
            name="isRequired"
            dataAction="adicionar_propriedade_componente:checkbox:e_obrigatorio"
            dataLabel="e_obrigatorio"
          >
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="xs" id="is-required-checkbox" value="1" />
              <OLabel htmlFor="is-required-checkbox">É obrigatório?</OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="adicionar_propriedade_componente:botao:fechar"
            dataLabel="fechar"
            outline
            onClick={() => {
              modalManager.hide(COMPONENT_PROPERTY_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <LoadingButton
            loading={
              selectedProperty
                ? editComponentPropertyLoading
                : addComponentPropertyLoading
            }
            dataAction="adicionar_propriedade_componente:botao:adicionar"
            dataLabel="adicionar"
            onClick={async () => {
              if (selectedProperty) editProperty(selectedProperty.id);
              else addProperty();
            }}
          >
            {selectedProperty ? "Editar" : "Adicionar"}
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
