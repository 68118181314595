import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const FundRelatedNameField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:nome"
        dataLabel="nome"
        id="fundRelatedName"
        name="fundRelatedName"
        tag="text"
        label={EstrategiaFormLabels.fundRelatedName}
        placeholder="Preencher"
      />
    </OCol>
  );
};
