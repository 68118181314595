import {
  OButton,
  OIcon,
  OModal,
  OModalBody,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { Faq } from "../../../../../components/faq/faq.component";
import type { ProductItem } from "../../offers.utils";
import { faqItems } from "./faq-modal.utils";

export type FaqModalProps = ProductItem;

export const FAQ_MODAL = "faq-modal";

export const FaqModal = (props: FaqModalProps) => {
  const items = faqItems[props?.identification];

  if (!items?.length) return null;

  return (
    <>
      <OButton
        className="d-flex justify-self-end"
        onClick={() => modalManager.show(FAQ_MODAL)}
        outline
        bordered={false}
      >
        <div className="d-flex align-items-center gap-1">
          <OIcon category="fal" icon="fa-question-circle" />
          <OTypography type="info">Perguntas frequentes</OTypography>
        </div>
      </OButton>
      <OModal id={FAQ_MODAL} position="right" className="position-absolute">
        <OModalHeader closeButton>
          <PageTitle title="Perguntas Frequentes" />
        </OModalHeader>
        <OModalBody>
          <Faq items={faqItems[props?.identification]} />
        </OModalBody>
      </OModal>
    </>
  );
};
