/* eslint-disable no-param-reassign */
import type { ODataGridGeneratorConfig } from "components/data-grid";
import CustomStore from "devextreme/data/custom_store";
import type DataSource from "devextreme/data/data_source";
import type dxDataGrid from "devextreme/ui/data_grid";
import { MapToInternalValue, NestedKeyOf } from "./header-filter.types";
import { access, generateFilter } from "./header-filter.utils";

type DataSourceFactory<T> = (
  adjustParams: (params: Record<string, string | undefined>) => void,
) => DataSource<T>;

type CalculateText<T, U extends readonly unknown[]> = (
  ...args: MapToInternalValue<T, U>
) => string;

type CalculateValue<T, U extends readonly unknown[]> = (
  ...args: MapToInternalValue<T, U>
) => any;

const defaultCalculateText = <T extends readonly unknown[]>(...values: T) =>
  values.join(", ");

const defaultCalculateFilter =
  <T extends readonly string[], U extends readonly unknown[]>(fields: T) =>
  (...values: U) =>
    generateFilter(fields, values);

/**
 * Configures header filter to make an OData request using `apply` to group by
 * `fields`, resulting in a faster query
 * 
 * @example
 * 
 * {
 *    // rest of the column
 *    headerFilter:
        configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
          ["status"] as const,
          (adjustParams) => buildDataSource(adjustParams),
          (status) => getValueFromMap(energyTradeStatusMap, status)?.text ?? status,
        ),
 * }
 */
export const configureHeaderFilterODataGroupBy =
  <T>() =>
  <U extends readonly NestedKeyOf<T>[]>(
    fields: U,
    dataSourceFactory: DataSourceFactory<T>,
    calculateText: CalculateText<T, U> = defaultCalculateText,
    calculateValue: CalculateValue<T, U> = defaultCalculateFilter(fields),
  ): ODataGridGeneratorConfig<T>["columns"][number]["headerFilter"] => {
    const adjustParams = (params: Record<string, string | undefined>) => {
      params.$apply = `groupby((${fields.join(",")}))`;
      if (params.$filter) {
        params.$apply = `filter(${params.$filter})/` + params.$apply;
        params.$filter = undefined;
      }
    };

    const dataSource = dataSourceFactory(adjustParams);

    return {
      dataSource: (options) => {
        options.dataSource = {
          store: new CustomStore({
            load: async (loadOptions) => {
              loadOptions.filter = (
                options.component as dxDataGrid
              ).getCombinedFilter();

              const result = (await dataSource
                .store()
                .load(loadOptions)) as U[];

              return result.map((v) => ({
                text: calculateText(
                  ...(fields.map((key: any) =>
                    access(v, key),
                  ) as MapToInternalValue<T, U>),
                ),
                value: calculateValue(
                  ...(fields.map((key: any) =>
                    access(v, key),
                  ) as MapToInternalValue<T, U>),
                ),
              }));
            },
          }),
        };
      },
    };
  };
