import { OCard, OCardBody } from "@maestro/react";
import { CustomerServiceButton } from "components/customer-service-button";
import { ErrorComponent } from "components/empty-state";
import { ImpersonateDropdown } from "components/impersonate-dropdown";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { NotificationArea } from "./_compose";
import { ActivateAccessButton } from "./_compose/activate-access-button";
import { DeactivateAccessButton } from "./_compose/deactivate-access-button";
import { ResendWelcomeEmailButton } from "./_compose/resend-welcome-email-button";
import { UpdateUserForm } from "./_compose/update-user-form";
import { useUserDetails } from "./user-details.hook";
import { useTimePenalty } from "./_compose/time-penalty";

type UserDetailsType = {
  portal: "b2c" | "b2b";
};

export const UserDetailsPage = ({ portal }: UserDetailsType) => {
  const { form, hasError, hasChanged, loadingUpdate, updateUser, user } =
    useUserDetails();
  const { loading, timePenalty, removeTimePenalty } = useTimePenalty();
  const { hasRole } = useRoles();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do usuário" />}
      actions={
        <>
          {hasRole(roles.usuarios.customer.timePenalty.role) && (
            <LoadingButton
              outline
              disabled={!timePenalty}
              loading={loading}
              dataAction=""
              dataLabel=""
              onClick={removeTimePenalty}
            >
              Remover penalidade
            </LoadingButton>
          )}
          <LoadingButton
            loading={loadingUpdate}
            disabled={!hasChanged}
            dataAction=""
            dataLabel=""
            onClick={updateUser}
          >
            Atualizar
          </LoadingButton>
        </>
      }
    >
      <div className="d-flex flex-column gap-4">
        <NotificationArea />
        <DetailsTemplate
          actions={
            !!user &&
            user.email && (
              <>
                {user.isActive ? (
                  <>
                    <DeactivateAccessButton email={user?.email} />
                    <ImpersonateDropdown email={user?.email} />
                  </>
                ) : (
                  <ActivateAccessButton email={user?.email} />
                )}
                <CustomerServiceButton cpf={user?.cpf ?? ""} />
                <ResendWelcomeEmailButton
                  portal={portal}
                  taxId={user?.cpf}
                  email={user?.email}
                />
              </>
            )
          }
        >
          <OCard>
            <OCardBody>
              <ContentTemplate
                hasError={hasError}
                noValue
                errorComponent={
                  <ErrorComponent
                    messageTitle="Não foi possível encontrar o usuário"
                    messageParagraph="Ocorreu um erro ao buscar o usuário. Retorne à página anterior e tente novamente."
                  />
                }
                render={() => (
                  <FormProvider {...form}>
                    <UpdateUserForm />
                  </FormProvider>
                )}
              />
            </OCardBody>
          </OCard>
        </DetailsTemplate>
      </div>
    </FormTemplate>
  );
};
