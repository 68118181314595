import { modalManager, OToastManager } from "@maestro/core";
import {
  OConfirmationModal,
  OIcon,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { TooltipContent } from "components/tooltip-icon";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";

interface WaitForDistributorButtonProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

const allowedPendencyStatuses: HubEnergy.EDiligenceStepPendencyStatus[] = [
  "PENDENTE_BPO",
  "PENDENTE_OPS",
  "AGUARDANDO_DISTRIBUIDORA",
];

export const WaitForDistributorButton = ({
  pendency,
}: WaitForDistributorButtonProps) => {
  const modalId = `wait-for-distributor-confirmation-modal-${pendency.id}`;

  const { stepAnalysis, setStepAnalysis } = useStepAnalysisContext();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.stepAnalysisWaitForDistributor,
  );

  const waitForDistributor = useCallback(async () => {
    const { success, response, error } = await callService({
      diligenceStepAnalysisId: stepAnalysis.id,
      diligenceStepPendencyId: pendency.id,
    });

    if (success) {
      OToastManager.success(
        "Pendência ficará aguardando resposta da distribuidora",
      );
      setStepAnalysis(response.data.response);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao marcar pendência como aguardando distribuidora",
      );
    }
  }, [callService, pendency.id, setStepAnalysis, stepAnalysis.id]);

  if (
    !allowedPendencyStatuses.includes(pendency.status) ||
    (stepAnalysis.level === "BPO" && pendency.status === "PENDENTE_OPS") // escalated
  )
    return null;

  return (
    <>
      <OTooltip floating position="top-left">
        <TooltipContent>
          {pendency.status !== "AGUARDANDO_DISTRIBUIDORA"
            ? "Aguardar retorno da distribuidora"
            : "Aguardando retorno da distribuidora"}
        </TooltipContent>
        <LoadingButton
          loading={loading}
          type={
            pendency.status === "AGUARDANDO_DISTRIBUIDORA" ? "info" : "dark"
          }
          outline={pendency.status !== "AGUARDANDO_DISTRIBUIDORA"}
          style={{
            pointerEvents:
              pendency.status === "AGUARDANDO_DISTRIBUIDORA" ? "none" : "unset",
          }}
          onClick={() => modalManager.show(modalId)}
        >
          <OIcon category="fal" icon="fa-clock" />
        </LoadingButton>
      </OTooltip>
      <OConfirmationModal
        modalId={modalId}
        title="Aguardar retorno da distribuidora?"
        divider={false}
        onConfirm={() => {
          waitForDistributor();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja aguardar retorno da distribuidora?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
