import { SelectSearchFieldV2Option } from "./select-search-field.types";

/** Find the `options` corresponding to the given `values` */
export const getOptionsFromValues = <T>(
  options: SelectSearchFieldV2Option<T>[],
  values: T[],
) =>
  values
    .map((val) => options.find((opt) => opt.value === val))
    .filter((opt): opt is SelectSearchFieldV2Option<T> => !!opt);

/** Ensure selectedValues is always an array. If fieldWatch is not an array, returns an array containing fieldWatch */
export const getSelectedValuesFromWatch = <T>(fieldWatch: T | T[]) =>
  Array.isArray(fieldWatch) ? fieldWatch : fieldWatch ? [fieldWatch] : [];
