import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const HabilitaPagForField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.habilitaPagFor}
      name="habilitaPagFor"
      description="Configuração que habilita o agendamento do pagamento do recebível ao fornecedor no CROS na data de vencimento. O agendamento acontece após a aprovação do recebível pelo sacado. O recebível fica disponível para antecipação, somente após confirmação do agendamento"
    />
  );
};
