import { OIcon } from "@maestro/react";
import { OptionContainer } from "./option.styles";
import { OptionProps } from "./option.types";

export const Option = ({
  children,
  selected,
  onClick,
  disabled,
}: OptionProps) => {
  const disabledClassName = disabled ? "disabled" : "";

  return (
    <OptionContainer
      className={`align-items-center ${disabledClassName}`}
      selected={selected}
      {...(!disabled && { onClick })}
    >
      {children}
      {selected && (
        <OIcon
          className="py-2"
          category="orq"
          icon="orq-check"
          size="lg"
          type="light"
        />
      )}
    </OptionContainer>
  );
};
