export interface AttachUserForm {
  taxId: string;
  name: string;
  email: string;
  phone: string;
  sendWelcomeEmail: string[];
  profiles: string[];
}

export type AttachUserSearchParams = Omit<
  AttachUserForm,
  "taxId" | "sendWelcomeEmail"
> & {
  profiles: string;
};

export const userDataSearchParam = "user-data";
