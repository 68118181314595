import { ODataGridGeneratorConfig } from "components/data-grid";
import { ReactElement } from "react";

export interface LimitManagerContingency {
  description: string;
  component: ReactElement;
}

export const limitManagerContingenciesGrid = {
  datagrid: {
    noDataText: "Nenhuma contingencia disponível.",
  },
  columns: [
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "component",
      caption: "Ação",
      cellRender: ({ data }) => data.component,
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitManagerContingency>;
