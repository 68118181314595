import { client } from "../client";
import { bankinghubEndpoints } from "./bankinghub.endpoints";
import {
  GetApiV1CorporateTaxParams,
  GetApiV2StatementByAccountParams,
  GetBankslipPaymentsParams,
  GetCardsContestationsParams,
  GetCardsDollarPriceParams,
  GetCardsInternalAccountsTimelinesParams,
  GetCardsOwnersParams,
  GetCardsWalletProvisionsParams,
  GetOverdraftsAnalysisParams,
  GetPaymentParams,
  GetStatementFileParams,
  GetStatementFullYieldParams,
  GetStatementMovementParams,
  PatchCardsOwnerUpdateAddressBody,
  PatchCardUpdateWalletProvisionParams,
  PostCardsConfirmOfferBody,
  PostCardsCreateOwnerParams,
  PostCardsOwnerRegisterAddressBody,
  PostCardsRequestCardBody,
} from "./models/requests";
import {
  GetApiV1CorporateTaxResponse,
  GetApiV2StatementByAccountResponse,
  GetBalanceYieldStatusResponse,
  GetCardsAccountProposalsResponse,
  GetCardsAccountResponse,
  GetCardsAccountsInvoicesResponse,
  GetCardsCompaniesUsersResponse,
  GetCardsContestationsResponse,
  GetCardsDebitCardOwnersResponse,
  GetCardsDollarPriceResponse,
  GetCardsInternalAccountsTimelinesResponse,
  GetCardsOfferResponse,
  GetCardsOfferGuarantorsResponse,
  GetCardsOwnersResponse,
  GetCardsOwnerAddressesResponse,
  GetCardsTrackingsResponse,
  GetCardsWalletProvisionsResponse,
  GetOverdraftsAnalysisResponse,
  GetOverdraftsLimitsOwnerInstallmentPlansResponse,
  GetOverdraftsLimitsResponse,
  GetStatementAccountsResponse,
  GetStatementBalanceResponse,
  GetStatementFullYieldResponse,
  GetStatementMovementResponse,
  PostCardsConfirmOfferResponse,
  PostCardsCreateOwnerResponse,
  PostCardsOwnerRegisterAddressResponse,
  PostCardsRequestCardResponse,
  GetBankslipPaymentsResponse,
  GetPaymentResponse,
} from "./models/responses";

export const bankinghubService = {
  getStatementAccounts() {
    return client.get<GetStatementAccountsResponse>(
      bankinghubEndpoints.statement.accounts,
    );
  },

  addBankAccount(body: any) {
    return client.post(bankinghubEndpoints.banking.addBankAccount, body);
  },

  getAccountBalance(account: string | number, agency: string | number) {
    return client.get<GetStatementBalanceResponse>(
      bankinghubEndpoints.statement.balance(account, agency),
    );
  },

  getAccountMovement(
    account: string | number,
    agency: string | number,
    params?: GetStatementMovementParams,
  ) {
    return client.get<GetStatementMovementResponse>(
      bankinghubEndpoints.statement.movement(account, agency),
      {
        params,
      },
    );
  },

  getStatementFile(
    accountNumber: string,
    agency: string,
    extension: "pdf" | "excel" | "ofx" | "legacy-ofx",
    params: GetStatementFileParams,
  ) {
    return client.get(
      bankinghubEndpoints.statement.file(accountNumber, agency, extension),
      {
        params,
      },
    );
  },

  getBalanceYieldStatus(accountNumber: string, agency: number) {
    const account = Number(accountNumber);
    return client.get<GetBalanceYieldStatusResponse>(
      bankinghubEndpoints.balanceYield.balanceYield(String(account), agency),
    );
  },

  getStatementFullYield(
    balanceYieldId: string,
    params: GetStatementFullYieldParams,
  ) {
    return client.get<GetStatementFullYieldResponse>(
      bankinghubEndpoints.balanceYield.fullYield(balanceYieldId),
      {
        params,
      },
    );
  },

  getBankslipPayments(params?: GetBankslipPaymentsParams) {
    return client.get<GetBankslipPaymentsResponse>(
      bankinghubEndpoints.bankslip.payments,
      {
        params,
      },
    );
  },

  getOverdraftAnalysis(params?: GetOverdraftsAnalysisParams) {
    return client.get<GetOverdraftsAnalysisResponse>(
      bankinghubEndpoints.overdrafts.analysis.document(),
      {
        params,
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
      },
    );
  },

  getOverdraftLimits() {
    return client.get<GetOverdraftsLimitsResponse>(
      bankinghubEndpoints.overdrafts.limits,
      {
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
      },
    );
  },

  getOverdraftInstallmentPlans(ownerId: string) {
    return client.get<GetOverdraftsLimitsOwnerInstallmentPlansResponse>(
      bankinghubEndpoints.overdrafts.owner.installmentPlans(ownerId),
    );
  },

  getStatementByAccount(
    account: string,
    agency: string,
    file: string,
    params?: GetApiV2StatementByAccountParams,
  ) {
    return client.get<GetApiV2StatementByAccountResponse>(
      bankinghubEndpoints.statement.file(account, agency, file),
      {
        params,
      },
    );
  },

  getTransfers(params?: GetPaymentParams) {
    return client.get<GetPaymentResponse>(bankinghubEndpoints.payment, {
      params,
    });
  },

  getCardsAccount() {
    return client.get<GetCardsAccountResponse>(
      bankinghubEndpoints.cards.accounts.endpoint,
    );
  },

  getCardsAccountProposals(accountId: string) {
    return client.get<GetCardsAccountProposalsResponse>(
      bankinghubEndpoints.cards.accounts.proposals(accountId),
    );
  },

  getCardsOffer() {
    return client.get<GetCardsOfferResponse>(
      bankinghubEndpoints.cards.offers.endpoint,
    );
  },

  getCardsOfferGuarantors(offerId: string) {
    return client.get<GetCardsOfferGuarantorsResponse>(
      bankinghubEndpoints.cards.offers.byId.guarantors(offerId),
    );
  },

  getCardsOwners(params?: GetCardsOwnersParams) {
    return client.get<GetCardsOwnersResponse>(
      bankinghubEndpoints.cards.owners.endpoint,
      {
        params,
      },
    );
  },

  postCardsCreateOwner(params?: PostCardsCreateOwnerParams) {
    return client.post<PostCardsCreateOwnerResponse>(
      bankinghubEndpoints.cards.owners.endpoint,
      {
        params,
      },
    );
  },

  getCardsOwnerAddresses(ownerId: string) {
    return client.get<GetCardsOwnerAddressesResponse>(
      bankinghubEndpoints.cards.owners.byId.addresses(ownerId),
    );
  },

  postCardsOwnerRegisterAddress(
    ownerId: string,
    body: PostCardsOwnerRegisterAddressBody,
  ) {
    return client.post<PostCardsOwnerRegisterAddressResponse>(
      bankinghubEndpoints.cards.owners.byId.addresses(ownerId),
      body,
    );
  },

  patchCardsOwnerUpdateAddress(
    ownerId: string,
    addressId: string,
    body: PatchCardsOwnerUpdateAddressBody,
  ) {
    return client.patch(
      bankinghubEndpoints.cards.owners.byId.address.byId(ownerId, addressId),
      body,
    );
  },

  getCardsContestations(params?: GetCardsContestationsParams) {
    return client.get<GetCardsContestationsResponse>(
      bankinghubEndpoints.cards.contestations,
      {
        params,
      },
    );
  },

  getCardsDollarPrice(params?: GetCardsDollarPriceParams) {
    return client.get<GetCardsDollarPriceResponse>(
      bankinghubEndpoints.cards.dollarPrice,
      {
        params,
      },
    );
  },

  getCardsInvoices(accountId: string) {
    return client.get<GetCardsAccountsInvoicesResponse>(
      bankinghubEndpoints.cards.accounts.invoices(accountId),
    );
  },

  getCardsTimelines(
    accountId: string,
    params?: GetCardsInternalAccountsTimelinesParams,
  ) {
    return client.get<GetCardsInternalAccountsTimelinesResponse>(
      bankinghubEndpoints.cards.internal.accounts.timelines(accountId),
      {
        params,
      },
    );
  },

  getCardsTracking(cardId: string, trackingId: string) {
    return client.get<GetCardsTrackingsResponse>(
      bankinghubEndpoints.cards.trackings(cardId, trackingId),
    );
  },

  getCardsCompaniesUsers() {
    return client.get<GetCardsCompaniesUsersResponse>(
      bankinghubEndpoints.cards.companies.users,
    );
  },

  getCardsDebitCardOwners() {
    return client.get<GetCardsDebitCardOwnersResponse>(
      bankinghubEndpoints.cards.debitCardOwners,
    );
  },

  postConfirmOffer(body: PostCardsConfirmOfferBody) {
    return client.post<PostCardsConfirmOfferResponse>(
      bankinghubEndpoints.cards.offers.endpoint,
      body,
    );
  },

  postRequestCard(body: PostCardsRequestCardBody) {
    return client.post<PostCardsRequestCardResponse>(
      bankinghubEndpoints.cards.endpoint,
      body,
    );
  },

  getCardsWalletProvisions(params?: GetCardsWalletProvisionsParams) {
    return client.get<GetCardsWalletProvisionsResponse>(
      bankinghubEndpoints.cards.wallets.requests,
      { params },
    );
  },

  updateCardWalletProvision(
    cardId: string,
    walletProvisionId: string,
    params?: PatchCardUpdateWalletProvisionParams,
  ) {
    return client.patch(
      bankinghubEndpoints.cards.internal.walletProvisions.byCardId(cardId),
      { params },
      {
        headers: {
          "wallet-provision-id": walletProvisionId,
        },
      },
    );
  },

  getCorporateTax(params?: GetApiV1CorporateTaxParams) {
    return client.get<GetApiV1CorporateTaxResponse>(
      bankinghubEndpoints.corporateTax.corporateTax,
      { params },
    );
  },
};
