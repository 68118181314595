import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams } from "../../../../../routes/ferramentas.route-params";
import { ContractFileConfigurationForm } from "../_compose/contract-file-configuration-form.component";
import {
  ContractFileConfigurationFormValues,
  contractFileConfigurationFormValidationSchema,
} from "../_compose/contract-file-configuration.form";

export const UpdateContractFileConfigurationPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<ContractFileConfigurationFormValues>({
    resolver: yupResolver(contractFileConfigurationFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getContractFileConfigurationDetails,
    loading,
    hasError,
    value: contractFileConfiguration,
  } = useServiceCall(service.onboarding.getContractFileConfigurationDetails);
  const {
    callService: updateContractFileConfiguration,
    loading: submitLoading,
  } = useServiceCall(service.onboarding.updateContractFileConfiguration);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateContractFileConfiguration(uuid, values);

        if (!success)
          return OToastManager.danger("Erro ao atualizar configuração");

        return OToastManager.success("Configuração atualizada com sucesso");
      }),
    [handleSubmit, updateContractFileConfiguration, uuid],
  );

  useEffect(() => {
    getContractFileConfigurationDetails(uuid);
  }, [getContractFileConfigurationDetails, uuid]);

  useEffect(() => {
    contractFileConfiguration && reset(contractFileConfiguration);
  }, [contractFileConfiguration, reset]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar configuração de arquivo do contrato" />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar configuração"
            callback={() => getContractFileConfigurationDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <ContractFileConfigurationForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
