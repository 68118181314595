import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { SacadosCompaniesViewValueItem } from "services/quickfin/models";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const grid: ODataGridGeneratorConfig<SacadosCompaniesViewValueItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      cellRender: ({ data: { Cnpj, Country } }) => {
        if (!Cnpj) return;
        const country = Country && Country != "BR" ? Country : "";
        const identification = country + Cnpj;
        return (
          <DetailsLink
            href={buildCustomerLink(
              identification,
              corporateRouter.routes.recebiveis.customer.path,
            )}
          />
        );
      },
    },
    {
      dataField: "OfficialName",
      caption: "Sacado",
    },
    {
      dataField: "Cnpj",
      caption: "Identificação",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "CompanyEntityType",
      caption: "Tipo da entidade",
      allowFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "Country",
      caption: "País",
      allowFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "date",
      cellRender: ({ data }) =>
        dayjs(data.CreatedAt)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
    {
      dataField: "UpdatedAt",
      caption: "Atualizado em",
      dataType: "date",
      cellRender: ({ data }) =>
        dayjs(data.UpdatedAt)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
  ],
};
