import { OFilterItem, ORadioGroup, OTypography } from "@maestro/react";

import { RadioFilterItem } from "../items/radio-filter-item.component";
import { activityTypes } from "../filters";

export const ActivityTypeFilter = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Tipo de atividades</OTypography>
      <ORadioGroup
        data-filterparam="activityFilter"
        dataAction="statement-activity-filter"
        dataLabel="statement-activity-filter"
      >
        {activityTypes.map((item) => (
          <RadioFilterItem {...item} key={item.value} />
        ))}
      </ORadioGroup>
    </OFilterItem>
  );
};
