import { modalManager, OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import {
  consumerUnitAssingmentGrid,
  dataSource,
} from "./consumer-unit-assignment.grid";
import { BatchAssignModal, batchAssignModalId } from "./_compose";

export const ConsumerUnitAssignmentPage = () => {
  const gridRef = useGridRef();
  const [selectedCuts, setSelectedCuts] = useState<
    HubEnergy.ConsumerUnitWithAgentsResponse[]
  >([]);

  const { callService, value } = useServiceCall(service.hubEnergy.getAgents);

  useEffect(() => {
    callService();
  }, [callService]);

  const grid = useMemo(() => {
    const _grid = consumerUnitAssingmentGrid(value?.response ?? []);

    _grid.datagrid.onSelectionChanged = (evt) =>
      setSelectedCuts(evt.selectedRowsData ?? []);

    return _grid;
  }, [value?.response]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Atribuição de unidades consumidoras" />}
      gridRef={gridRef}
      actions={
        value && (
          <>
            <OButton
              disabled={!selectedCuts.length}
              onClick={() => modalManager.show(batchAssignModalId)}
            >
              Atribuir unidades selecionadas
            </OButton>
            <BatchAssignModal
              agents={value.response}
              selectedCuts={selectedCuts}
              reload={() => gridRef.current?.instance.getDataSource().reload()}
              gridRef={gridRef}
            />
          </>
        )
      }
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
