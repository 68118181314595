import { modalManager, OButton, OIcon } from "@maestro/react";
import { confirmationModalId } from "./confirmation-modal.component";
import { ParamsProps } from "./optins-rejeitados.types";
import { useRejectedOptinContext } from "./optins-rejeitados.context";

export const RetryButton = ({ newParams }: { newParams: ParamsProps }) => {
  const { setParams } = useRejectedOptinContext();

  return (
    <OButton
      type="info"
      size="sm"
      outline
      onClick={() => {
        modalManager.show(confirmationModalId);
        setParams(newParams);
      }}
    >
      <OIcon category="fal" icon="fa-redo" size="sm" />
    </OButton>
  );
};
