import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";
import { PostAdminLoaAllResponseItemResponse } from "services/onboarding-relationships/models/responses";

export const useWorkflowDetails = () => {
  const [workflow, setWorkflow] =
    useState<PostAdminLoaAllResponseItemResponse>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const getRepresentativeWorkflow = useCallback(async (uuid: string) => {
    try {
      setLoading(true);
      const { data } = await service.onboardingRelationships.getWorkflowDetails(
        {
          uuid,
        },
      );
      setWorkflow(data);
      if (
        data.occurrences.length === 0 &&
        (data.status === "PENDENTE_ENVIO" ||
          data.status === "PENDENTE_VALIDACAO_SEGURANCA")
      ) {
        setCanSend(true);
      } else {
        setCanSend(false);
      }

      if (
        data.status === "PENDENTE_AJUSTE" ||
        data.status === "PENDENTE" ||
        data.status === "PENDENTE_ENVIO" ||
        data.status === "PENDENTE_VALIDACAO_SEGURANCA"
      ) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }

      setHasError(false);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o workflow");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    workflow,
    loading,
    hasError,
    getRepresentativeWorkflow,
    canSend,
    canEdit,
  };
};
