import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import {
  DataGridCellContent,
  DataGridStatusCellContent,
  PaymentUtils,
} from "./_compose";
import { DataSourceItem, MetaData } from "./payments-and-transfers.types";

const isVisibleDownloadReceipt = (rowData: MetaData) => {
  return (
    PaymentUtils.isAPaymentStatus(rowData.status) &&
    PaymentUtils.canDownloadPaymentReceipt(rowData.status)
  );
};

export const paymentsAndTransfersGrid: (
  seeDetails: (clickedRow: MetaData) => void,
  downloadReceipt: (id: string) => void,
) => ODataGridGeneratorConfig<DataSourceItem> = (
  seeDetails,
  downloadReceipt,
) => ({
  datagrid: { noDataText: "Nenhum pagamento encontrado" },
  paging: { enabled: false },
  columns: [
    {
      dataField: "paymentDate.primary",
      dataType: "date",
      format: "shortDate",
      caption: "Data do pagamento",
      width: "10%",
    },
    {
      dataField: "sender.primary",
      caption: "Conta de origem",
      cellRender: ({ data: { sender } }) => {
        return <DataGridCellContent {...sender} />;
      },
      width: "15%",
    },
    {
      dataField: "receiver.primary",
      caption: "Favorecido",
      cellRender: ({ data: { receiver } }) => {
        return <DataGridCellContent {...receiver} />;
      },
      width: "30%",
    },
    {
      dataField: "product.primary",
      caption: "Produto",
      width: "10%",
    },
    {
      dataField: "status.status",
      caption: "Status",
      cellRender: ({ data }) => {
        const { badgeType, icon, tooltip, status } = data.status;
        return (
          <DataGridStatusCellContent
            badgeType={badgeType}
            icon={icon}
            tooltip={tooltip}
            status={status}
          />
        );
      },
      width: "20%",
    },
    {
      dataField: "amount.primary",
      caption: "Valor",
      width: "15%",
    },
    {
      caption: "Ações",
      alignment: "right",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                label: "Ver detalhes",
                icon: { category: "orq", icon: "orq-stack-details" },
                onClick: () => seeDetails(data.metaData),
              },
              {
                label: "Baixar comprovante",
                icon: { category: "orq", icon: "orq-download" },
                onClick: () => downloadReceipt(data.id),
                visible: isVisibleDownloadReceipt(data.metaData),
              },
            ]}
          />
        );
      },
      width: "85px",
    },
  ],
});
