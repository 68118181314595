import { OTypography } from "@maestro/react";
import { useSimulation } from "./simulation.context";
import { ProductItemInterface } from "./simulation.types";

interface SimulationPageHeaderProps {
  simulationProduct: ProductItemInterface | undefined;
}

export const SimulationPageHeader = ({
  simulationProduct,
}: SimulationPageHeaderProps) => {
  const { summary } = useSimulation();

  return (
    <div className="d-flex justify-content-between">
      {simulationProduct && (
        <div>
          <OTypography size="xl" className="mb-2">
            {simulationProduct?.simulation.title(
              summary?.creditLineVisibleName,
            )}
          </OTypography>
          <OTypography type="default" className="mb-5">
            {simulationProduct?.simulation.description}
          </OTypography>
        </div>
      )}

      {simulationProduct?.faqModal(simulationProduct)}
    </div>
  );
};
