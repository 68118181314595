export interface FilterItem {
  label: string;
  value: string;
  default?: boolean;
}

export const periods: FilterItem[] = [
  { label: "Hoje", value: "today" },
  { label: "Último dia", value: "yesterday" },
  { label: "Três últimos dias", value: "last-three-days" },
  { label: "Última semana", value: "last-week", default: true },
  { label: "Período mensal", value: "monthly" },
  { label: "Período específico", value: "custom" },
];

export const activityTypes = [
  { label: "Transferências", value: "transfers" },
  { label: "Pix", value: "pix" },
  { label: "Boletos", value: "bankslips" },
  { label: "Folha de Pagamento", value: "fopa" },
  { label: "Taxas", value: "tax" },
];

export const movementTypes = [
  { label: "Entradas", value: "incomes" },
  { label: "Saídas", value: "outcomes" },
];

export const exportationTypes = [
  { label: "Excel", value: "excel", default: true },
  { label: "PDF", value: "pdf" },
  { label: "CSV", value: "csv" },
  { label: "OFX", value: "ofx" },
  { label: "OFX (Antigo)", value: "legacy-ofx" },
];

export const order = [
  { label: "Mais recente primeiro", value: "desc", default: true },
  { label: "Mais antigo primeiro", value: "asc" },
];

export const statementType = [
  { label: "Sem rendimentos", value: "simple", default: true },
  { label: "Rendimentos pagos pelo Remunera+", value: "yields" },
  {
    label: "Rendimentos, Aplicações e Resgates (Remunera+)",
    value: "withdraw-applications",
  },
];

export const balanceYield = [
  {
    label: "IOF + IR (informações de tributação do Remunera+)",
    value: "balance-yield",
  },
];
