import { ODropdownItem } from "@maestro/react";
import styled, { css } from "styled-components";

interface SelectableDropdownItemProps {
  $selected?: boolean;
}

export const SelectableDropdownItem = styled(
  ODropdownItem,
)<SelectableDropdownItemProps>`
  display: flex;
  justify-content: start;
  color: var(--theme-dark);

  ${({ $selected }) =>
    $selected
      ? css`
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          background-color: var(--theme-tertiary) !important;
          color: var(--theme-tertiary-inverse) !important;

          &:hover {
            background-color: var(--theme-tertiary);
          }

          &::after {
            content: "\f00c";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            display: block;
          }
        `
      : ""};
`;
