import { Nullable } from "utils/types";

export interface ChangeTradeStatusForm {
  shouldTriggerEvent: string[];
  status: string;
}

export const changeTradeStatusFormDefaultValues = {
  shouldTriggerEvent: [],
  status: null,
} satisfies Partial<
  Nullable<ChangeTradeStatusForm>
> as unknown as ChangeTradeStatusForm;
