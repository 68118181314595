import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { Validations } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";
import { grid } from "./validation-workflow-card.grid";
import { JsonResponseModal } from "../json-response-modal.component";

interface ValidationsProps {
  validations: Validations[];
}

export const ValidationCard = ({ validations }: ValidationsProps) => {
  const [uuidNotification, setuuidNotification] = useState<string>("");
  const [jsonResponse, setJsonResponse] = useState<string>("");

  const gridComponent = useMemo(() => {
    return grid({ setJsonResponse, setJsonUuid: setuuidNotification });
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <OTypography size="lg" type="default" className="mb-3">
          Validações
        </OTypography>
      }
    >
      <ODataGridGenerator grid={gridComponent} dataSource={validations} />

      <JsonResponseModal
        jsonResponseModalId={uuidNotification}
        content={jsonResponse}
      />
    </GridTemplate>
  );
};
