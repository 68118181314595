import {
  CCCForm,
  CartaoForm,
  FidcForm,
  FundingProprioForm,
  OffshoreForm,
  RiscoCedenteForm,
  RiscoSacadoForm,
} from "./forms";

export const productFormMap = {
  RiscoSacado: RiscoSacadoForm,
  Cartao: CartaoForm,
  FIDC: FidcForm,
  Offshore: OffshoreForm,
  RiscoCedente: RiscoCedenteForm,
  FundingProprio: FundingProprioForm,
  CCC: CCCForm,
};
