/* eslint-disable no-param-reassign */
import { modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { TooltipIcon } from "components/tooltip-icon";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { format } from "utils/date";
import { getValueFromMap } from "utils/get-value-from-map";
import { impersonateRedirect } from "utils/impersonate";
import {
  biometryStatusMap,
  biometryTooltipMap,
  userStatusMap,
  userTooltipMap,
} from "../../../../../utils/status-map";
import {
  SalesforceCompanyUser,
  invalidateUserModalId,
  sendWelcomeModalId,
} from "./company-users.types";

export const buildCompanyUsersGrid = (
  salesforceCompanyUserRef: React.MutableRefObject<
    SalesforceCompanyUser | undefined
  >,
): ODataGridGeneratorConfig<SalesforceCompanyUser> => ({
  datagrid: {
    noDataText: "Nenhum usuário",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "name", caption: "Nome" },
    {
      dataField: "cpf",
      caption: "CPF",
      format: (value) => masks.cpf(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "birthDate",
      caption: "Data de nascimento",
      format: (value) => format.date(value, { timeZone: "UTC" }),
    },
    { dataField: "email", caption: "E-mail" },
    {
      dataField: "phone",
      caption: "Telefone",
      format: (value) => masks.phone(value),
    },
    {
      dataField: "biometryStatus",
      caption: "Biometria",
      cellRender: ({ data }) => {
        const status =
          getValueFromMap(biometryStatusMap, data.biometryStatus) ??
          data.biometryStatus ??
          "-";

        const tooltipContent = getValueFromMap(
          biometryTooltipMap,
          data.biometryStatus,
        );

        return (
          <span className="d-flex gap-2">
            {status}
            {tooltipContent && <TooltipIcon content={tooltipContent} />}
          </span>
        );
      },
    },
    {
      dataField: "accountStatus",
      caption: "Status de login",
      cellRender: ({ data }) => {
        const status =
          getValueFromMap(userStatusMap, data.accountStatus) ??
          data.accountStatus;

        const tooltipContent = getValueFromMap(
          userTooltipMap,
          data.accountStatus,
        );

        return (
          <span className="d-flex gap-2">
            {status}
            {tooltipContent && <TooltipIcon content={tooltipContent} />}
          </span>
        );
      },
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: {
                category: "fal",
                icon: "fa-envelope",
              },
              label: "Enviar e-mail de boas vindas",
              onClick: () => {
                salesforceCompanyUserRef.current = data;
                modalManager.show(sendWelcomeModalId);
              },
            },
            {
              icon: {
                category: "fal",
                icon: "fa-lock-alt",
              },
              label: "Bloquear",
              onClick: () => {
                salesforceCompanyUserRef.current = data;
                modalManager.show(invalidateUserModalId);
              },
            },
            {
              icon: {
                category: "fal",
                icon: "fa-portrait",
              },
              label: "Impersonar",
              onClick: () => {
                impersonateRedirect(data.email, "BTG_PACTUAL_EMPRESAS");
              },
            },
          ]}
        />
      ),
    },
  ],
});
