import * as yup from "yup";
import { ClosingReasonForm } from "./closing-reason.types";

export const closingReasonDefaultValues = {
  reasons: [],
  otherReasons: null,
};

export const closingReasonValidationSchema = yup.object<
  ClosingReasonForm,
  yup.ObjectSchema<ClosingReasonForm>["fields"]
>({
  reasons: yup
    .array()
    .required("Selecione ao menos um motivo")
    .min(1, "Selecione ao menos um motivo"),
  otherReasons: yup.string().when("reasons", {
    is: (reasons: string[]) => reasons.includes("Outros motivos"),
    then: (schema) =>
      schema.required(
        'Quando "Outros motivos" está marcado, este campo deve ser preenchido',
      ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});
