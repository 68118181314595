import * as yup from "yup";
import { CompanyInteractionFinancialAddForm } from "./interaction-financial-add.type";

export const companyInteractionFinancialAddValidationSchema: yup.ObjectSchema<
  Omit<CompanyInteractionFinancialAddForm, "id">
> = yup.object({
  cellPhoneNumber: yup.array().required("Este campo é obrigatório"),
  email: yup
    .array()
    .of(
      yup.string().email("Email inválido").required("Este campo é obrigatório"),
    )
    .required("Este campo é obrigatório"),
  name: yup.string().required("Este campo é obrigatório"),
});

export const companyInteractionFinancialAddDefaultValues: CompanyInteractionFinancialAddForm =
  {
    cellPhoneNumber: [""],
    email: [""],
    id: "",
    name: "",
  };
