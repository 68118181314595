import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { DefaultContractFileConfigurationForm } from "../_compose/default-contract-file-configuration-form.component";
import {
  DefaultContractFileConfigurationFormValues,
  defaultContractFileConfigurationFormValidationSchema,
} from "../_compose/default-contract-file-configuration.form";

export const RegisterDefaultContractFileConfigurationPage = () => {
  const form = useForm<DefaultContractFileConfigurationFormValues>({
    resolver: yupResolver(defaultContractFileConfigurationFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerDefaultContractFileConfiguration, loading } =
    useServiceCall(service.onboarding.registerDefaultContractFileConfiguration);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDefaultContractFileConfiguration([
          values,
        ]);

        if (!success) return OToastManager.danger("Erro ao criar configuração");

        return OToastManager.success("Configuração criada com sucesso");
      }),
    [handleSubmit, registerDefaultContractFileConfiguration],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Adicionar configuração padrão de arquivo do contrato" />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DefaultContractFileConfigurationForm />
      </FormProvider>
    </FormTemplate>
  );
};
