import { masks } from "@maestro/utils";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { UseFormRegisterReturn } from "react-hook-form";
import { banksList } from "utils/bank-list";
import { BankAccount, Contact } from "./generic-bank-data.types";

export const buildCedenteTaxIdsOptions = (
  cedenteTaxIds: string[],
  onSelect: (taxId: string) => void,
): SelectSearchOption<string>[] =>
  cedenteTaxIds.map((taxId) => ({
    value: taxId,
    label: masks.cnpj(taxId),
    onSelect,
  }));

export const buildAccountsOptions = (
  contact: Contact | undefined,
  onSelect: (bankAccount: BankAccount) => void,
): SelectSearchOption<BankAccount>[] =>
  contact?.product?.bankAccounts?.map((account) => {
    const bankTitle = banksList.find((bank) => bank.id === account.bank)?.title;

    const bankName = `${account.bank}${bankTitle ? ` - ${bankTitle}` : ""}`;
    const agency = `Agência: ${account.branch}`;
    const accountNumber = `Conta: ${account.account}-${account.account_digit}`;
    const accountType = `Tipo: ${account.account_type}`;

    return {
      value: account,
      label: `${bankName} | ${agency} | ${accountNumber} | ${accountType}`,
      onSelect,
    };
  }) ?? [];

export const validateTaxIdRoot = (cedenteTaxIds: string[], taxId: string) =>
  cedenteTaxIds
    .map((char) => char.slice(0, 8))
    .some((root) => root === taxId.slice(0, 8));

export const remapHookFormRegister = <T extends string>(
  registerReturn: UseFormRegisterReturn<T>,
) => ({
  ...registerReturn,
  onInput: registerReturn.onChange,
});
