import * as yup from "yup";

export interface DeskDocumentConfigFormValues {
  type: string;
  subType?: string | null;
  isBiometry: boolean;
  isRequired: boolean;
  lessCode?: number | null;
  label?: string | null;
}

export const deskDocumentConfigFormValidationSchema: yup.ObjectSchema<DeskDocumentConfigFormValues> =
  yup.object({
    lessCode: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    type: yup.string().required("Este campo é obrigatório"),
    subType: yup
      .string()
      .transform((value) => value || null)
      .nullable(),
    label: yup
      .string()
      .transform((value) => value || null)
      .nullable(),
    isBiometry: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    isRequired: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
  });
