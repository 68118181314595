import * as yup from "yup";

export interface UploadOnboardingFilesFormValues {
  file: File[];
}

export const uploadOnboardingFilesFormValidationSchema: yup.ObjectSchema<UploadOnboardingFilesFormValues> =
  yup.object({
    file: yup
      .array()
      .typeError("Este campo é obrigatorio")
      .required("Este campo é obrigatorio")
      .min(1, "Este campo é obrigatorio"),
  });
