import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useNavigate } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./settlements.grid";

export const Settlements = () => {
  const gridRef = useGridRef();
  const navigate = useNavigate();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Liquidações"
          description="Acompanhe todas as liquidações de nota realizadas em nossa plataforma"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid(navigate)} />
    </GridTemplate>
  );
};
