export const downloadCsv = (csv: string, filename: string) => {
  const link = document.createElement("a");

  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`,
  );

  link.setAttribute("download", filename);
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
