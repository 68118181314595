import { modalManager } from "@maestro/core";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { Section, SideFilterBar } from "components/side-filter-bar";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useCallback, useMemo } from "react";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import {
  ArchiveBankslipModal,
  ArchiveBankslipModalId,
} from "./_compose/archive-bankslip-modal";
import {
  BankslipDetailsDrawer,
  BankslipDetailsDrawerId,
} from "./_compose/bankslip-details-drawer";
import {
  BankslipEventsModal,
  BankslipEventsModalId,
} from "./_compose/bankslip-events-modal";
import { BeneficiaryStatusCard } from "./_compose/beneficiary-status-card";
import {
  CancelAllBankslipsModal,
  CancelAllBankslipsModalId,
} from "./_compose/cancel-all-bankslips-modal";
import {
  CancelBankslipModal,
  CancelBankslipModalId,
} from "./_compose/cancel-bankslip-modal";
import {
  ManageBankslipGridDrawer,
  manageBankslipGridDrawerId,
} from "./_compose/manage-bankslip-grid-drawer";
import { PaginationGrid } from "./_compose/pagination-grid";
import { BankslipProvider, useBankslipContext } from "./bankslip.context";
import { grid } from "./bankslip.grid";
import { assembleSections } from "./bankslip.utils";

const BankslipComponent = () => {
  const gridRef = useGridRef();
  const {
    collectionsList,
    refreshGrid,
    filters,
    handleSubmitFilter,
    isLoading,
    pageSize,
    setBankslipToArchive,
    setBankslipToCancel,
    setDetailedBankslip,
    visibleColumns,
    setBankslipToSeeEvents,
  } = useBankslipContext();
  const { hasRole } = useRoles();

  const showCancelAllBankslips = useMemo(
    () => hasRole(roles.banking.customer.cancelAllBankslipsAction.role),
    [hasRole],
  );

  const archiveCollection = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToArchive(collection);
      modalManager.show(ArchiveBankslipModalId);
    },
    [setBankslipToArchive],
  );

  const cancelCollection = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToCancel(collection);
      modalManager.show(CancelBankslipModalId);
    },
    [setBankslipToCancel],
  );

  const seeDetails = useCallback(
    (collection: BankingHub.Collection) => {
      setDetailedBankslip(collection);
      modalManager.show(BankslipDetailsDrawerId);
    },
    [setDetailedBankslip],
  );

  const seeEvents = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToSeeEvents(collection);
      modalManager.show(BankslipEventsModalId);
    },
    [setBankslipToSeeEvents],
  );

  const memoGrid = useMemo(
    () =>
      grid(
        pageSize,
        archiveCollection,
        cancelCollection,
        seeDetails,
        seeEvents,
        visibleColumns,
      ),
    [
      archiveCollection,
      cancelCollection,
      pageSize,
      seeDetails,
      seeEvents,
      visibleColumns,
    ],
  );

  const sections = useMemo(
    (): Section[] => assembleSections(filters),
    [filters],
  );

  return (
    <div>
      <ManageBankslipGridDrawer />
      <ArchiveBankslipModal />
      <CancelBankslipModal />
      <BankslipEventsModal />
      <BankslipDetailsDrawer />
      <CancelAllBankslipsModal />

      <div className="d-flex flex-column gap-3">
        <PageTitle title="Boletos" />
        <BeneficiaryStatusCard />

        <GridTemplate
          gridRef={gridRef}
          actions={
            <div className="d-flex align-items-center gap-2">
              {showCancelAllBankslips && (
                <IconButton
                  icon={{ category: "orq", icon: "orq-cancel" }}
                  onClick={() => modalManager.show(CancelAllBankslipsModalId)}
                  outline
                >
                  Cancelar todas as cobranças
                </IconButton>
              )}
              <IconButton
                icon={{ category: "fa", icon: "fa-line-columns" }}
                onClick={() => modalManager.show(manageBankslipGridDrawerId)}
                outline
              >
                Editar colunas
              </IconButton>
              <SideFilterBar
                sections={sections}
                submitFilters={handleSubmitFilter}
                defaultValuesSubmitted
              />

              <RefreshGridButton onClick={refreshGrid} />
            </div>
          }
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={memoGrid}
            dataSource={collectionsList}
            loading={isLoading}
          />
          <PaginationGrid />
        </GridTemplate>
      </div>
    </div>
  );
};

export const Bankslip = () => {
  return (
    <BankslipProvider>
      <BankslipComponent />
    </BankslipProvider>
  );
};
