export enum PixKeyType {
  EVP = "EVP",
  CPF = "CPF",
  CNPJ = "CNPJ",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export enum PixKeyStatus {
  Registered = "REGISTERED",
  Deleting = "DELETING",
  ClaimedPortability = "CLAIMED_PORTABILITY",
  ClaimedPortabilityRequest = "CLAIMED_PORTABILITY_REQUEST",
  ClaimedOwnership = "CLAIMED_OWNERSHIP",
  ClaimedOwnershipRequest = "CLAIMED_OWNERSHIP_REQUEST",
  CompleteOwnershipRequest = "COMPLETE_OWNERSHIP_REQUEST",
}

export enum PixKeysGridColumn {
  KeyType = "KeyType",
  KeyValue = "KeyValue",
  AgencyId = "AgencyId",
  AccountNumber = "AccountNumber",
  Status = "Status",
}
