import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import { GetSnakeCompanyByIdResponse } from "services/fidc/models/responses";

export const buildCompanyFundDetailsFields = (
  _company: GetSnakeCompanyByIdResponse,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Razão Social",
        value: _company.name,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF/CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(_company.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={_company.taxId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CGE",
        value: _company.cge,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  contact: {
    title: "Contato",
    items: [
      {
        label: "E-mail",
        value: _company.email,
        grid: { md: 4, xl: 4 },
      },
      {
        label: "Telefone",
        value: masks.phone(_company.phoneNumber),
        grid: { md: 4, xl: 6 },
      },
    ],
  },
});
