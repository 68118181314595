import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useRelocate } from "../../relocate.context";
import { NextStepButton } from "../../_compose";
import { buildRelocateCompaniesGrid, dataSource } from "./simulation.grid";

export const Simulation = () => {
  const gridRef = useGridRef();
  const { selectedCompanies, setSelectedCompanies, simulate } = useRelocate();

  const relocateCompaniesGrid = useMemo(
    () => buildRelocateCompaniesGrid(setSelectedCompanies),
    [setSelectedCompanies],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate
        pageTitle={
          <PageTitle description="Selecione as empresas que deseja simular realocação pelo nosso motor" />
        }
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={relocateCompaniesGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <NextStepButton
          disabled={selectedCompanies.length < 1}
          onClick={() => simulate()}
        >
          Simular
        </NextStepButton>
      </div>
    </div>
  );
};
