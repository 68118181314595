import { ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { BaseStepFields } from "./base-step-fields.component";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";

interface QuickfinSyncStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const QuickfinSyncStep = ({
  fieldName,
  index,
}: QuickfinSyncStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(`${fieldName}.${index}._t`, "QuickFinSyncConfigurationStep");
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(
        `${fieldName}.${index}.stepId`,
        `quickfinsync-${generateUuidV4()}`,
      );
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="QUICKFINSYNC"
      />
    </ORow>
  );
};
