import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { domainExceptionMap } from "utils/domain-exception";

type BatchRequestRows = Awaited<
  ReturnType<
    typeof service.hubCreditManager.odata.getWorkflowLeadsBatchRequestRows
  >
>["data"]["value"][number];

export const batchRowsGrid = (batchRequestId: number) =>
  ({
    datagrid: {
      noDataText: "Nenhum erro encontrado",
      dataSource: dataSourceODataStoreGenerator(
        `${endpoints.hubCreditManager.odata.workflowLeadsBatchRequestRowAdmin(
          batchRequestId,
        )}?$filter=(contains(Status,'INVALID'))`,
      ),
      headerFilter: { visible: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "WorkflowLeadsBatchRequestId",
        caption: "BatchId",
        dataType: "number",
        filterValue: batchRequestId,
        visible: false,
      },
      {
        dataField: "LineNumber",
        caption: "Linha da Tabela",
        dataType: "number",
      },
      {
        dataField: "ValidationErrors",
        caption: "Descrição do erro",
        dataType: "string",
        cellRender: ({ data }) => {
          const joinedErrors = data.ValidationErrors.map(
            (vError) => domainExceptionMap[vError]?.message ?? vError,
          )
            .filter((error) => !!error)
            .join(", ");

          return <div>{joinedErrors}</div>;
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<BatchRequestRows>);
