import { DeepNullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";
import { StepConfigForm } from "./step-config-form.types";

export const stepConfigFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
    autocomplete: "off",
  },
  label: {
    id: "label",
    name: "label",
    tag: "text",
    label: "Label",
    placeholder: "Preencher",
    required: false,
    autocomplete: "off",
  },
  isPublic: {
    id: "isPublic",
    name: "isPublic",
    checkboxLabel: "Mostra para o cliente",
  },
  needsPreApproval: {
    id: "needsPreApproval",
    name: "needsPreApproval",
    checkboxLabel: "Precisa de pré-aprovação",
  },
  maximumDuration: {
    id: "maximumDuration",
    name: "maximumDuration",
    tag: "number",
    label: "Prazo",
    placeholder: "Preencher",
    required: true,
    autocomplete: "off",
  },
  maximumDurationType: {
    id: "maximumDurationType",
    name: "maximumDurationType",
    label: "Tipo do prazo",
    placeholder: "Selecionar",
    required: true,
  },
} as const;

const stringRequired = yup.string().required("Campo obrigatório");
const numberRequired = yup.number().required("Campo obrigatório");

export const stepConfigFormValidationSchema = yup.object<
  StepConfigForm,
  YupObjectSchema<StepConfigForm>
>({
  name: stringRequired,
  maximumDuration: numberRequired,
  typeId: numberRequired,
});

export const checkboxValue = "true";

export const stepConfigFormDefaultValues = {
  name: null,
  isPublic: false,
  needsPreApproval: false,
  maximumDuration: null,
  maximumDurationType: null,
  dependencyTypeIds: [],
  typeId: null,
} satisfies Partial<DeepNullable<StepConfigForm>> as unknown as StepConfigForm;
