import { yupResolver } from "@hookform/resolvers/yup";
import { ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { PostApiAdminAddConfigurationVariableBody } from "services/hubloan/models/requests/post-api-admin-add-configuration-variable.request";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  configurationVariableFormDefaultValues,
  configurationVariableFormValidationSchema,
} from "../loan-configuration-variable-form.schemas";

export const LoanAddConfigurationVariablePage = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(configurationVariableFormValidationSchema),
    defaultValues: configurationVariableFormDefaultValues,
  });

  const { handleSubmit } = form;

  const addConfigurationVariable = useCallback(
    async (values: PostApiAdminAddConfigurationVariableBody) => {
      try {
        setLoading(true);

        await service.hubLoan.addConfigurationVariable(values);

        OToastManager.success(
          "Nova Variável de Configuração adicionada com sucesso.",
        );
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao tentar adicionar a nova Variável de Configuração.",
        );
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Variável de Configuração" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={handleSubmit(addConfigurationVariable)}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex flex-direction-row gap-3">
              <div className="w-80">
                <ORFieldInput
                  adaptHeight
                  id="variableName"
                  name="variableName"
                  tag="textarea"
                  label="Nome da Variável"
                  labelSize="md"
                />
              </div>
              <div className="w-100">
                <ORFieldInput
                  adaptHeight
                  id="variableValue"
                  name="variableValue"
                  tag="textarea"
                  label="Valor da Variável"
                  labelSize="md"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
