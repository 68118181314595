import { OButton } from "@maestro/react";
import { ErrorComponent } from "components/empty-state";
import { useNavigate } from "react-router-dom";

export const ForbiddenPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
      <ErrorComponent messageTitle="Você não tem permissão para acessar essa rota">
        <OButton
          dataAction="forbidden:botao:voltar_pagina_inicial"
          dataLabel="voltar_pagina_inicial"
          outline
          type="default"
          className="mt-3"
          onClick={() => {
            navigate("/", { replace: true });
          }}
        >
          Voltar para a página inicial
        </OButton>
      </ErrorComponent>
    </div>
  );
};
