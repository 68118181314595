import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByDistributorIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import { EnergyDistributorDiligenceFormValues } from "./_compose/field-form/field-form.model";
import { energyDistributorDiligenceValidationFormSchema } from "./_compose/field-form/field-form.schemas";

export const useEnergyDistributorDiligencePage = () => {
  const { distributorId } = useParams<MleByDistributorIdRouteParams>();
  if (!distributorId) throw new Error("No distributorId");

  const form = useForm<EnergyDistributorDiligenceFormValues>({
    resolver: yupResolver(energyDistributorDiligenceValidationFormSchema),
  });
  const { handleSubmit, reset } = form;

  const {
    callService: getDistributorDiligenceConfig,
    loading: loadingDetails,
    hasError,
    value: genericFieldDetails,
  } = useServiceCall(service.hubEnergy.getDistributorDiligenceConfig);

  const { callService: updateDistributorDiligenceConfig, loading } =
    useServiceCall(service.hubEnergy.updateDistributorDiligenceConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateDistributorDiligenceConfig({
          ...values,
          distributorId: Number(distributorId),
          needsPreApproval: !!values.needsPreApproval.length,
          needsPostApproval: !!values.needsPostApproval.length,
          diligenceStepConfigIds: values.diligenceStepConfigIds.map(
            ({ id }) => id,
          ),
        });

        if (success)
          OToastManager.success("Diligência configurada com sucesso");
        else
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Erro ao configurar a diligência",
          );
      }),
    [handleSubmit, updateDistributorDiligenceConfig, distributorId],
  );

  const handleErrorCallback = useCallback(
    () => getDistributorDiligenceConfig(distributorId),
    [getDistributorDiligenceConfig, distributorId],
  );

  useEffect(() => {
    getDistributorDiligenceConfig(distributorId);
  }, [getDistributorDiligenceConfig, distributorId]);

  useEffect(() => {
    const response = genericFieldDetails?.response;

    if (!response) return;

    const loadData = {
      needsPreApproval: response.needsPreApproval ? ["true"] : [],
      needsPostApproval: response.needsPostApproval ? ["true"] : [],
      diligenceStepConfigIds: response.stepsConfigs
        .sort((a, b) => a.type.order - b.type.order)
        .map(({ id }) => ({ id })),
    };

    reset(loadData);
  }, [genericFieldDetails, reset]);

  return {
    form,
    loading,
    loadingDetails,
    hasError,
    genericFieldDetails,
    handleErrorCallback,
    submit,
  };
};
