import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { AddressFields } from "../../../../components/address-form/address.component";
import {
  DistributorBasicDataFields,
  DistributorConfigFields,
  DistributorContactsGrid,
} from "../_compose";
import { useAddDistributor } from "./add-distributor.hook";

export const AddDistributorPage = () => {
  const { form, loading, submit } = useAddDistributor();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Cadastrar distribuidora" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-3">
        <FormProvider {...form}>
          <OCard>
            <OCardHeader>
              <OTypography>Dados cadastrais</OTypography>
            </OCardHeader>
            <OCardBody>
              <DistributorBasicDataFields />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardHeader>
              <OTypography>Endereço</OTypography>
            </OCardHeader>
            <OCardBody>
              <AddressFields />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardHeader>
              <OTypography>Configurações</OTypography>
            </OCardHeader>
            <OCardBody>
              <DistributorConfigFields />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardHeader>
              <OTypography>Contatos</OTypography>
            </OCardHeader>
            <OCardBody>
              <DistributorContactsGrid />
            </OCardBody>
          </OCard>
        </FormProvider>
      </div>
    </FormTemplate>
  );
};
