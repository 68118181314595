import { OIcon, OTypography } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { gridStorage } from "utils/storage";
import { energiaRoles } from "../../../../../../../../roles/energia.roles";
import { getPropertyLabel } from "../../../../../../../../utils/get-property-label";
import { GridActions, ValueCell } from "./_compose";
import { DownloadFileButton } from "./_compose/value-cell/_compose/document-cell/download-file-button.component";

export const stepPropertiesGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceStepPropertyResponse> =
  {
    datagrid: {
      noDataText: "Nenhuma propriedade",
      stateStoring: gridStorage("mle-stepPropertiesGrid"),
      headerFilter: { visible: true },
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "type.name",
        dataType: "string",
        cssClass: "dx-word-wrap",
        caption: "Nome",
        calculateCellValue: (data) => getPropertyLabel(data),
      },
      {
        dataField: "value",
        dataType: "string",
        caption: "Valor",
        cellRender: ({ data }) => <ValueCell property={data} />,
        allowFiltering: false,
      },
      {
        dataField: "documentTemplate",
        dataType: "string",
        caption: "Template do documento",
        cellRender: ({ data }) => {
          const firstFile = data.documentTemplate?.files?.[0];
          return (
            firstFile && (
              <>
                <DownloadFileButton
                  key={firstFile.key}
                  fileName={firstFile.name}
                  fileKey={firstFile.key}
                />
                {data.documentTemplate.files.length > 1 && (
                  <OTypography
                    className="mt-2"
                    style={{ whiteSpace: "normal" }}
                  >
                    <OIcon
                      category="orq"
                      icon="orq-status-warning"
                      type="danger"
                      className="d-inline-block"
                    />{" "}
                    Template de documento possui mais de um arquivo! Apenas o
                    primeiro arquivo será exibido para o cliente.
                  </OTypography>
                )}
              </>
            )
          );
        },
        allowFiltering: false,
      },
      {
        dataField: "isPublic",
        dataType: "boolean",
        caption: "Mostra Cliente",
        width: "auto",
      },
      {
        dataField: "isAutomation",
        dataType: "boolean",
        caption: "Automação",
        width: "auto",
      },
      {
        dataField: "updatedDate",
        dataType: "date",
        format: "shortDateShortTime",
        caption: "Data de Atualização",
        width: "auto",
      },
      {
        role: energiaRoles.customer.migrationStepPropertyActions.role,
        cellRender: ({ data, component }) => (
          <GridActions
            property={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
        width: "auto",
      },
    ],
  };
