import { OToastManager } from "@maestro/core";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { parseOnboardingErrors } from "services/onboarding/utils/onboarding-errors-messages";
import { GridTemplate } from "templates/grid-template";
import { buildGrid } from "./onboardings.grid";

export const Onboardings = () => {
  const { customer } = useCustomer();
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          service.clerk.admin
            .getCompanyDetails({
              taxId: customer.identification,
            })
            .then(({ data }) => data.onboardings ?? []),
        {
          customStoreOptions: {
            remove: async ({ externalReference }) => {
              try {
                if (!externalReference)
                  throw new Error("Missing externalReference");
                await service.onboarding.disableOnboarding(externalReference);
                OToastManager.success("Onboarding excluído com sucesso");
              } catch (err) {
                const errorMessage = "Erro ao excluir onboarding";
                OToastManager.danger(
                  parseOnboardingErrors(err, {
                    onlyMappedErrorMessages: false,
                  }) || errorMessage,
                );
                throw new Error(errorMessage);
              }
            },
          },
        },
      ),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Cadastros" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
