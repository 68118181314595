import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { dataSource, operatorsODataGrid } from "./product-operators.grid";

export const ProductOperatorsPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => operatorsODataGrid(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Perfis de Operador" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        dataSource={dataSource}
        gridRef={gridRef}
        grid={grid}
      />
    </GridTemplate>
  );
};
