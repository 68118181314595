import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AddressType, IAddress } from "../../../../cards.types";
import { masks } from "@maestro/utils";

dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("America/Sao_Paulo");

interface MonthDetails {
  fullName: string;
  name: string;
}

const MonthToDetails: MonthDetails[] = [
  { fullName: "Janeiro", name: "Jan" },
  { fullName: "Fevereiro", name: "Fev" },
  { fullName: "Março", name: "Mar" },
  { fullName: "Abril", name: "Abr" },
  { fullName: "Maio", name: "Mai" },
  { fullName: "Junho", name: "Jun" },
  { fullName: "Julho", name: "Jul" },
  { fullName: "Agosto", name: "Ago" },
  { fullName: "Setembro", name: "Set" },
  { fullName: "Outubro", name: "Out" },
  { fullName: "Novembro", name: "Nov" },
  { fullName: "Dezembro", name: "Dez" },
];

const getMonthFullName = (monthIndex: number): string =>
  MonthToDetails[monthIndex].fullName;

const formatToDayAndMonth = (date: string, onlyNumbers?: boolean): string => {
  if (onlyNumbers) return `${dayjs(date).format("DD/MM")}`;
  const month = dayjs(date).month();
  return `${dayjs(date).format("DD")} de ${getMonthFullName(
    month,
  ).toLowerCase()}`;
};

export const dateRangeFormatToDayMonthAndYear = (
  initialDate: string,
  endDate: string,
): string => {
  const initialDateObj = dayjs(initialDate);
  const endDateObj = dayjs(endDate);

  const initialMonth = initialDateObj.month();
  const initialYear = initialDateObj.year();

  const endMonth = endDateObj.month();
  const endYear = endDateObj.year();

  if (initialYear !== endYear) {
    return `${formatToDayAndMonth(
      initialDate,
    )} de ${initialYear} a ${formatToDayAndMonth(endDate)} de ${endYear}`;
  }

  if (initialMonth !== endMonth) {
    return `${formatToDayAndMonth(initialDate)} a ${formatToDayAndMonth(
      endDate,
    )} de ${initialYear}`;
  }

  return `${dayjs(initialDate).format("DD")} a ${dayjs(endDate).format(
    "DD",
  )} de ${getMonthFullName(initialMonth).toLowerCase()} de ${initialYear}`;
};

const MapperAddressType: Record<AddressType, string> = {
  [AddressType.RESIDENTIAL]: "Residencial",
  [AddressType.COMMERCIAL]: "Comercial",
  [AddressType.OTHERS]: "Outros",
};

export const addressToStreetAndNeighborhoodText = (address: IAddress) => {
  const addressNumberText = address.number ? `, ${address.number}` : "";
  const addressNumberComplement = address.complement
    ? ` (${address.complement})`
    : "";

  const streetText = `${MapperAddressType[address.type]}: ${
    address.street
  }${addressNumberText}${addressNumberComplement}`;

  const neighborhoodText = `${address.neighborhood}, ${address.city} - ${
    address.state
  } ${masks.cep(address.zipCode)}`;

  return {
    streetText,
    neighborhoodText,
  };
};
