import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { SignatureGroupsGrid, SignatureRuleConfigs } from "./_compose";
import { useCustomerSignatures } from "./customer-signatures.hook";

export const CustomerSignatures = () => {
  const { getSignatureRules, hasError, loading, signatureRules } =
    useCustomerSignatures();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Assinaturas" />}>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={signatureRules}
        errorComponent={
          <DefaultErrorComponent
            title="Não foi possível buscar as regras de assinatura"
            callback={getSignatureRules}
          />
        }
        emptyStateComponent={
          <DetailsTemplate
            actions={
              <StandardLink
                href={
                  corporateRouter.routes.recebiveis.customer.signatures.newRule
                    .path
                }
                role={roles.recebiveis.customer.signaturesNewRulePage.role}
              >
                Criar regra de assinatura
              </StandardLink>
            }
          >
            <CardTemplate>
              <EmptyState messageTitle="Não há regras ou grupos de assinatura cadastrados para esta empresa" />
            </CardTemplate>
          </DetailsTemplate>
        }
        render={(_signatureRules) => (
          <div className="d-flex flex-column gap-4">
            <SignatureGroupsGrid signatureRule={_signatureRules[0]} />
            <SignatureRuleConfigs signatureRule={_signatureRules[0]} />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
