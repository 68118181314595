import { OCol, ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { scfDevelopersOnboardingStatusMap } from "../../../domain-configuration-scf.utils";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";
import { MultiSelectAll } from "../../multi-select-all";
import { BaseStepFields } from "./base-step-fields.component";

interface WebhookStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const WebhookStep = ({ fieldName, index }: WebhookStepProps) => {
  const { getValues, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue(`${fieldName}.${index}._t`, "WebhookPartnerConfigurationStep");
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(`${fieldName}.${index}.stepId`, `webhook-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  const watchAllowedWebhookStatusTypes = watch(
    `${fieldName}.${index}.allowedWebhookStatusTypes`,
  );

  const changeAllowedWebhookStatusTypes = (values: string[]) =>
    setValue(`${fieldName}.${index}.allowedWebhookStatusTypes`, values);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="WEBHOOK"
        gridSize={4}
      />
      <OCol xs={4}>
        <MultiSelectAll
          id={`${fieldName}.${index}.allowedWebhookStatusTypes`}
          items={Object.keys(scfDevelopersOnboardingStatusMap)}
          label="Status permitidos"
          mapping={scfDevelopersOnboardingStatusMap}
          selected={watchAllowedWebhookStatusTypes}
          setSelected={changeAllowedWebhookStatusTypes}
        />
      </OCol>
    </ORow>
  );
};
