import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { DocumentTypeFormComponent } from "../_compose";
import { useEditDocumentType } from "./edit-document-type.hook";

export const EditDocumentTypePage = () => {
  const {
    form,
    submitLoading,
    submit,
    getDocumentType,
    hasError,
    loading,
    error,
  } = useEditDocumentType();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar tipo de documento" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ValidationErrorComponent
              callback={getDocumentType}
              error={error}
              title="Erro ao buscar o tipo de documento"
            />
          }
          noValue
          render={() => (
            <FormProvider {...form}>
              <DocumentTypeFormComponent />
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
