import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";
import { CardVariantsBarChart } from "./_compose";

export const CardVariantsBarChartCard = () => {
  return (
    <InfoCard
      summaryType="cardsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Variantes de cartão"
          body={<CardVariantsBarChart variants={_value.variants} />}
        />
      )}
    />
  );
};
