import { modalManager, OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";
import { logger } from "utils/logger";
import { useCollectionsAgreementContext } from "../../collections-agreement.context";
import { DeleteAgreementModalId } from "./delete-agreement-modal.types";

export const useDeleteCollectionAgreement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getAgreementsList } = useCollectionsAgreementContext();

  const deleteAgreement = useCallback(
    async (agreementId: string) => {
      try {
        setIsLoading(true);
        if (!agreementId) throw new Error("Empty agreementId");
        await service.adminBankinghub.collection.deleteAgreement(agreementId);
        modalManager.hide(DeleteAgreementModalId);
        OToastManager.success("Convênio excluido com sucesso.");
        getAgreementsList();
      } catch (err) {
        logger.warn(err);
        OToastManager.danger("Ocorreu um erro para excluir o convênio.");
      } finally {
        setIsLoading(false);
      }
    },
    [getAgreementsList],
  );

  return { deleteAgreement, isLoading };
};
