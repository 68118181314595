import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { RowSpacedContainer } from "../cards.styles";
import { AccountModality } from "../cards.types";
import { InfoPad } from "../compose/info-pad/info-pad.component";
import { IAccountListProps } from "./account-list.types";

export const AccountList = ({
  cardAccountData,
  activeDebitAccount,
  setActiveDebitAccount,
  renderDollarPrice,
}: IAccountListProps) => {
  const modality = cardAccountData?.program.modality;

  return (
    <div>
      {cardAccountData?.program && (
        <div className="d-flex flex-column gap-3">
          <RowSpacedContainer>
            <OTypography size="lg">Informações gerais</OTypography>
            {renderDollarPrice()}
          </RowSpacedContainer>
          <div className="d-flex flex-row flex-wrap gap-2">
            {cardAccountData?.debitAccounts?.map((debitAccount) => {
              const active = debitAccount.id === activeDebitAccount?.id;
              return (
                <InfoPad
                  label="Conta Débito"
                  infos={[
                    `CC. ${masks.generic(debitAccount.number, {
                      mask: "00000000-0",
                    })}`,
                    `Ag. ${debitAccount.branch.padStart(4, "0")}`,
                  ]}
                  icon={{
                    category: "orq",
                    icon: "orq-money",
                  }}
                  button={{
                    onClick: () => setActiveDebitAccount(debitAccount),
                    title: "Selecionar conta débito",
                  }}
                  active={active}
                />
              );
            })}
            {cardAccountData.program.modality !== AccountModality.Multiple && (
              <>
                <InfoPad
                  label="Novas Ofertas"
                  infos={[
                    ...((cardAccountData.program.modality ===
                      AccountModality.Credit && [
                      `Débito: ${
                        cardAccountData?.hasNewDebitCardProposal
                          ? "Disponível"
                          : "Indisponivel"
                      }`,
                    ]) ||
                      []),
                    ...((cardAccountData.program.modality ===
                      AccountModality.Debit && [
                      `Crédito: ${
                        cardAccountData?.hasNewCreditCardProposal
                          ? "Disponível"
                          : "Indisponivel"
                      }`,
                    ]) ||
                      []),
                  ]}
                  icon={{
                    category: "orq",
                    icon: "orq-proposal",
                  }}
                />
                {cardAccountData.offer.isOnHiringProcess && (
                  <InfoPad
                    label={"Status contratação crédito"}
                    infos={[
                      `Iniciou o processo de contratação: ${
                        cardAccountData.offer.isOnHiringProcess ? "Sim" : "Não"
                      }`,
                    ]}
                    icon={{
                      category: "orq",
                      icon: "orq-proposal",
                    }}
                  />
                )}
              </>
            )}
            <InfoPad
              label="Conta Crédito"
              infos={[modality !== AccountModality.Debit ? "Ativo" : "Inativo"]}
              icon={{
                category: "orq",
                icon: "orq-tariff",
              }}
            />
            {modality !== AccountModality.Debit && (
              <>
                <InfoPad
                  label="Oferta de Crédito"
                  infos={[
                    "Contratado",
                    masks.currency(
                      cardAccountData?.offer?.limitAnalysis?.amount,
                      "R$",
                    ),
                  ]}
                  icon={{
                    category: "orq",
                    icon: "orq-proposal",
                  }}
                />
                <InfoPad
                  label="Plano Ofertado"
                  infos={[cardAccountData?.program?.name]}
                  icon={{
                    category: "orq",
                    icon: "orq-policy",
                  }}
                />
                <InfoPad
                  label="Débito automático de fatura"
                  infos={[
                    cardAccountData?.isInvoiceDirectDebitEnabled
                      ? "Habilitado"
                      : "Desabilitado",
                  ]}
                  icon={{
                    category: "orq",
                    icon: "orq-invoice",
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
