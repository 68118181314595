import { ODivider } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useEffect } from "react";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useEditProcessor } from "../../edit-processor.context";
import { GeneralInfo } from "../general-info/general-info.component";
import { OutputsGrid } from "../outputs-grid/outputs-grid.component";
import { PropertiesGrid } from "../properties-grid/properties-grid.component";

export const EditProcessor = () => {
  const {
    processor: { callService: getProcessorDetails, loading, hasError },
    id,
  } = useEditProcessor();

  useEffect(() => {
    getProcessorDetails(id);
  }, [getProcessorDetails, id]);

  return (
    <FormTemplate pageTitle={<PageTitle title="Editar processador" />}>
      <CardTemplate>
        <ContentTemplate
          hasError={hasError}
          loading={loading}
          noValue
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível carregar detalhes do processador"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={() => getProcessorDetails(id)} />
            </ErrorComponent>
          }
          render={() => (
            <div className="d-flex flex-column gap-5">
              <GeneralInfo />
              <ODivider size="xxs" className="mb-3" />
              <PropertiesGrid />
              <OutputsGrid />
            </div>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
