import * as yup from "yup";

export interface OperationsQuotesActionFormValues {
  email: string;
}

export const operationsQuotesActionValidationSchema: yup.ObjectSchema<OperationsQuotesActionFormValues> =
  yup.object({
    email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  });
