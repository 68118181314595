import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const VisualizaRebateSacadoField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.visualizaRebateSacado}
      name="visualizaRebateSacado"
      description="Configuração que define se o sacado pode ver informações de rebate dos recebíveis. Caso, possua uma única estratégia com essa configuração ativada (Sim), então todas as páginas do portal irão informar dados de Rebate, caso houver"
    />
  );
};
