import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { service } from "services";
import { B2bAgroProdutoByProposalIdRouteParams } from "../../../../../../routes/b2b.route-params";
import { getCurrentPathParams } from "../../../../[...routes]/b2b-embedded.utils";

const TEMPLATE_PATH = "/finders/produto/monitoramento/:proposalId/resumo";

export const useProposalSummary = () => {
  const location = useLocation();
  const { proposalId } = getCurrentPathParams(
    TEMPLATE_PATH,
    location.pathname,
  ) as B2bAgroProdutoByProposalIdRouteParams;

  if (!proposalId) throw new Error("No proposalId");

  const form = useForm<{ status: string }>();

  const { reset, watch, getValues } = form;

  const statusWatcher = watch("status");

  const {
    callService: getProposalDetails,
    value: proposal,
    hasError,
    loading,
  } = useServiceCall(service.b2bAgro.getProposalDetails);

  const { callService: updateStatus, loading: updateStatusLoading } =
    useServiceCall(service.b2bAgro.updateProposalStatus);

  const {
    callService: updateCanEditGuarantee,
    loading: updateCanEditGuaranteeLoading,
  } = useServiceCall(service.b2bAgro.updateCanEditGuarantee);

  useEffect(() => {
    getProposalDetails(proposalId);
  }, [getProposalDetails, proposalId]);

  useEffect(() => {
    proposal &&
      reset({
        status: proposal.data.status,
      });
  }, [proposal, reset]);

  const handleUpdateStatus = useCallback(async () => {
    if (!proposalId) return;

    const { status } = getValues();

    const payload = {
      status,
    };

    const { success } = await updateStatus(proposalId, payload);
    if (success) {
      getProposalDetails(proposalId);
      OToastManager.success("Status da proposta atualizado com sucesso.");
    } else {
      OToastManager.danger("Houve um problema ao atualizar o status.");
    }
  }, [getProposalDetails, getValues, proposalId, updateStatus]);

  const handleUpdateCanEditGuarantee = useCallback(async () => {
    if (!proposal) return;

    const payload = {
      canEditGuarantee: !proposal.data.canEditGuarantee,
    };

    const { success } = await updateCanEditGuarantee(
      proposal.data.uuid,
      payload,
    );
    if (success) {
      getProposalDetails(proposalId);
      OToastManager.success(
        "Alteração no formulário de garantias realizada com sucesso.",
      );
    } else {
      OToastManager.danger(
        "Houve um problema ao atualizar o formulário de garantias.",
      );
    }
  }, [getProposalDetails, proposal, proposalId, updateCanEditGuarantee]);

  return {
    handleUpdateStatus,
    updateStatusLoading,
    updateCanEditGuaranteeLoading,
    loading,
    hasError,
    proposalStatus: statusWatcher,
    proposal,
    form,
    proposalId,
    getProposalDetails,
    handleUpdateCanEditGuarantee,
  };
};
