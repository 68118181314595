import {
  OBadge,
  OCol,
  OField,
  OIcon,
  OLoader,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { remapHookFormRegister } from "../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useGatekeeperSystemsAdd } from "./add.hook";

export const GatekeeperSystemsAdd = () => {
  const {
    allProfiles,
    form,
    loading,
    localForm,
    watchInput,
    watchProfiles,
    setValue,
    submit,
  } = useGatekeeperSystemsAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Sistemas Gatekeeper"
          description="Adicione um sistema e suas configurações ao Gatekeeper"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_sistema:botao:criar_sistema"
          dataLabel="criar_sistema"
          onClick={submit}
        >
          Criar Sistema
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader size="sm" />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="name"
                  name="name"
                  dataAction="sistema:input:nome"
                  dataLabel="nome"
                  label="Nome"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="webhook"
                  name="webhook"
                  dataAction="sistema:input:webhook"
                  dataLabel="webhook"
                  label="Webhook"
                />
              </OCol>
            </ORow>

            <ORow align="start">
              <OCol xs={12} sm={6} lg={4}>
                <OField label="Profiles" htmlFor="input">
                  <SelectSearch
                    id="input"
                    name="input"
                    placeholder="Selecionar"
                    options={allProfiles}
                    value={watchInput}
                    className="w-100"
                    maxOptions={50}
                    innerProps={{
                      input: {
                        ...remapHookFormRegister(localForm.register("input")),
                      },
                    }}
                  />
                </OField>
              </OCol>

              {!!watchProfiles?.length && (
                <OCol
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-column gap-4"
                >
                  <OTypography tag="h3">Selecionados:</OTypography>

                  <div className="d-flex flex-wrap gap-2">
                    {watchProfiles.map((profile) => (
                      <OBadge
                        key={profile}
                        type="dark"
                        size="sm"
                        rounded
                        outline
                      >
                        <div className="d-flex align-items-center gap-2">
                          {profile}
                          <UnstyledButton
                            onClick={() => {
                              const filteredProfiles = watchProfiles.filter(
                                (p) => p !== profile,
                              );

                              setValue("profiles", filteredProfiles);
                            }}
                          >
                            <OIcon category="fal" icon="fa-times" />
                          </UnstyledButton>
                        </div>
                      </OBadge>
                    ))}
                  </div>
                </OCol>
              )}
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
