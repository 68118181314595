import { DefaultErrorComponent } from "components/empty-state";
import { EmbeddedContainer, EmbeddedPortal } from "components/portal-embeded";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";

export const WorkflowsRmAdminCustomerPage = () => {
  const { customer } = useCustomer();

  const {
    value: partnerIdentification,
    callService: getPartnerByAuthOperator,
    loading,
    hasError,
  } = useServiceCall(service.b2bCore.getPartnerByAuthOperator);

  useEffect(() => {
    getPartnerByAuthOperator();
  }, [getPartnerByAuthOperator]);

  return (
    <ContentTemplate
      loading={loading || partnerIdentification === undefined}
      errorComponent={
        <DefaultErrorComponent
          title="Não foi possível buscar o originador"
          callback={() => getPartnerByAuthOperator()}
        />
      }
      hasError={hasError}
      value={partnerIdentification}
      render={(value) => (
        <EmbeddedContainer>
          <EmbeddedPortal
            headers={{ "x-identification": value }}
            route={`/emprestimos/workflows/rmadmin?scoped=${customer.identification}`}
          />
        </EmbeddedContainer>
      )}
    />
  );
};
