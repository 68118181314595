import {
  modalManager,
  OConfirmationModal,
  OToastManager,
} from "@maestro/react";
import { useCompanyTraitsContext } from "./company-traits.context";
import { useCompanyTraits } from "./company-traits.hook";

export const confirmationModalId = "company-traits-modal";

export const ConfirmationModal = ({
  setUpdate,
}: {
  setUpdate: (arg0: (arg0: boolean) => boolean) => void;
}) => {
  const { params } = useCompanyTraitsContext();
  const { deleteCompanyTraits, deleteCompanyTraitsHasError } =
    useCompanyTraits();

  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={confirmationModalId}
      title="Remover Propriedade da empresa"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={() => {
        deleteCompanyTraits({ id: params.id }).then(() => {
          if (!deleteCompanyTraitsHasError) {
            OToastManager.success("A propriedade foi removida com sucesso");
            setUpdate((prevUpdate) => !prevUpdate);
          } else {
            const errorMessage = "Erro ao excluir a propriedade";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
          modalManager.hide(confirmationModalId);
        });
      }}
    >
      <div>
        Tem certeza que deseja remover a propriedade {params?.trait} da empresa{" "}
        {params?.name} - {params?.taxId}?
      </div>
    </OConfirmationModal>
  );
};
