import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { BaseCalendar } from "services/legacys/models";

interface CalendarFieldsProps {
  baseCalendars: BaseCalendar[];
  nameDisabled?: boolean;
}

export const CalendarFields: React.FC<CalendarFieldsProps> = ({
  baseCalendars,
  nameDisabled,
}) => {
  return (
    <ORow>
      <OCol sm={12} xl={6}>
        <ORFieldInput
          name="name"
          id="name"
          dataAction="formulario_calendario:select:nome"
          dataLabel="nome"
          tag="text"
          placeholder="Preencher"
          label="Nome do calendário"
          disabled={nameDisabled}
        />
      </OCol>
      <OCol sm={12} xl={6}>
        <ORFieldSelect
          aspect="outline"
          label="Calendário base"
          name="baseCalendarId"
          id="baseCalendarId"
          dataAction="formulario_calendario:select:calendario_base"
          dataLabel="calendario_base"
        >
          {baseCalendars.map((calendar) => (
            <OOption key={calendar.id} value={calendar.id}>
              {calendar.name}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
    </ORow>
  );
};
