import { MoanaOwner } from "services/moana/models";
import { CardWalletComponent } from "./_compose/card-wallet/card-wallet.component";
import { BankslipPortfolio } from "./_compose/portfolio-management/bankslip-portfolio.component";
import { DetailWalletMenuComponent } from "./_compose/detail-wallet/detail-wallet-menu.component";

export const WalletBaseForm = ({
  name,
  documentNumber,
  personType,
  totalEligibleValue,
  totalAnticipatedValue,
  totalReceivableValue,
  totalLimiteValue,
  activePortfolio,
  state,
  totalValue,
  portfolioManagement,
  totalPendingPaymentValue,
  limitStatus,
  expirationDate,
  totalLateSettlement,
}: MoanaOwner) => {
  return (
    <>
      <DetailWalletMenuComponent
        documentNumber={documentNumber}
        activePortfolio={activePortfolio}
        name={name}
        personType={personType === 1 ? "Pessoa física" : "Pessoa jurídica"}
        state={state}
        totalValue={totalValue}
        limitStatus={limitStatus}
        expirationDate={expirationDate}
      />
      <CardWalletComponent
        totalAnticipatedValue={totalAnticipatedValue}
        totalEligibleValue={totalEligibleValue}
        totalLimiteValue={totalLimiteValue}
        totalReceivableValue={totalReceivableValue}
        totalPendingPaymentValue={totalPendingPaymentValue}
        totalLateSettlement={totalLateSettlement}
      />
      <BankslipPortfolio
        portfolioManagement={portfolioManagement}
        documentNumber={documentNumber}
      />
    </>
  );
};
