import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  creditLineConfigurationsDataSource,
  creditLineConfigurationsGrid,
} from "./credit-line-configurations.grid";

export const CreditLineConfigurationsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configurações de linhas de crédito" />}
      actions={
        <RouterButton
          type="tertiary"
          dataAction="configuracoes_de_linha_de_credito:botao:adicionar"
          dataLabel="adicionar"
          href={
            corporateRouter.routes.emprestimos.product.contingency
              .creditLinesConfigurations.add.path
          }
          role={
            roles.emprestimos.product.contingencyCreditLineConfigurationsAddPage
              .role
          }
          outline
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={creditLineConfigurationsGrid}
        dataSource={creditLineConfigurationsDataSource}
      />
    </GridTemplate>
  );
};
