import * as yup from "yup";
import { UserProfilesForm } from "./user-profiles.types";

export const userProfilesFields = {
  profiles: {
    id: "profiles",
    name: "profiles",
    label: "Profiles",
    placeholder: "Buscar um profile",
  },
};

export const userProfilesDefaultValues = {
  profiles: [],
};

export const userProfilesValidationSchema = yup.object<
  UserProfilesForm,
  yup.ObjectSchema<UserProfilesForm>["fields"]
>({
  profiles: yup.array().required("Campo obrigatório"),
});
