import type { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import type { RowPreparedEvent } from "devextreme/ui/data_grid";

export const highlightNearExpirationDate = ({
  data,
  rowElement,
  rowType,
}: RowPreparedEvent<HubEnergy.DiligenceTaskODataResponse>) => {
  if (rowType === "data" && data?.stepExpirationDate) {
    const today = dayjs();
    const expirationDate = dayjs(data.stepExpirationDate);

    if (expirationDate.isBefore(today)) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-danger-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-danger-light-inverse)",
      );
    } else if (expirationDate.diff(today, "days") < 7) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-warning-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-warning-light-inverse)",
      );
    }
  }
};

// grid export
const consumerUnitNameColumn = "consumerUnitNameColumn";
const consumerUnitOfficialNameColumn = "consumerUnitOfficialNameColumn";
const consumerUnitTaxIdColumn = "consumerUnitTaxIdColumn";
const consumerUnitInstallationNumberColumn = "installationNumberColumn";

export const exportOnlyColumnNames = [
  consumerUnitNameColumn,
  consumerUnitOfficialNameColumn,
  consumerUnitTaxIdColumn,
  consumerUnitInstallationNumberColumn,
];

export const exportOnlyColumns = [
  {
    visible: false,
    dataField: "consumerUnitName",
    dataType: "string",
    name: consumerUnitNameColumn,
    caption: "Unidade consumidora - Nome fantasia",
  },
  {
    visible: false,
    dataField: "consumerUnitOfficialName",
    dataType: "string",
    name: consumerUnitOfficialNameColumn,
    caption: "Unidade consumidora - Razão social",
  },
  {
    visible: false,
    dataField: "consumerUnitTaxId",
    dataType: "string",
    name: consumerUnitTaxIdColumn,
    caption: "Unidade consumidora - CNPJ",
  },
  {
    visible: false,
    dataField: "consumerUnitInstallationNumber",
    dataType: "string",
    name: consumerUnitInstallationNumberColumn,
    caption: "Unidade consumidora - Número de instalação",
  },
] satisfies ODataGridGeneratorConfig<HubEnergy.DiligenceTaskODataResponse>["columns"];
