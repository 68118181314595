import {
  OCard,
  OCardBody,
  OCol,
  OLoader,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { useAddOfficer } from "./add-officer.hook";

export const AddOfficer = () => {
  const { form, loading, submit } = useAddOfficer();

  return (
    <>
      <PageTitle title="Adicionar Officer" />

      <FormTemplate
        actions={
          <LoadingButton
            dataAction="scf_officer:botao:enviar"
            dataLabel="enviar"
            onClick={submit}
            loading={loading}
          >
            Enviar
          </LoadingButton>
        }
      >
        <OCard>
          <OCardBody>
            <FormProvider {...form}>
              {loading && <OLoader absolute backdrop />}
              <ORow>
                <OCol xs={6}>
                  <ORFieldInput tag="text" id="name" name="name" label="Nome" />
                </OCol>
                <OCol xs={6}>
                  <ORFieldInput
                    tag="cpfcnpj"
                    id="taxId"
                    name="taxId"
                    label="CNPJ/CPF"
                  />
                </OCol>
                <OCol xs={6}>
                  <ORFieldInput tag="text" id="cge" name="cge" label="CGE" />
                </OCol>
              </ORow>
            </FormProvider>
          </OCardBody>
        </OCard>
      </FormTemplate>
    </>
  );
};
