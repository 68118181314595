import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { FormValuesType } from "../formulario/tco-strategies-form.types";

export const useSelectStrategies = () => {
  const [allStrategies, setAllStrategies] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const form = useFormContext<FormValuesType>();
  const { watch } = form;

  const watchProductType = watch("tipoProduto");
  const watchCompanies = watch("companiesIds");
  const watchPortfolio = watch("portfolioId");

  const { callService: getStrategies, value: dataStrategies } = useServiceCall(
    service.quickfin.getAllEstrategiasSimplified,
  );

  useEffect(() => {
    getStrategies({
      companiesIds: watchCompanies,
      tipoProduto: watchProductType,
      portfolioId: watchPortfolio,
    });
  }, [getStrategies, watchProductType, watchCompanies, watchPortfolio]);

  useEffect(() => {
    if (!dataStrategies) return;

    const newData = dataStrategies.estrategias.map((d) => {
      return {
        value: String(d.id),
        label: d.description,
      };
    });

    setAllStrategies(newData);
  }, [dataStrategies]);

  return {
    allStrategies,
  };
};
