import { useCallback, useEffect, useState } from "react";
import { useHiringStatus } from "./hiring-status.hook";
import { PostAdminLimitsGetLimitResponse } from "services/hubcreditmanager/models/responses";
import { LimitGuarantors } from "./limit-guarantors/limit-guarantors.component";
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { BankslipContracts } from "./bankslip-contracts/bankslip-contracts.component";
import { GetPmeAccountsResponse } from "services/clerk";
import { BankslipAccounts } from "./bankslip-accounts/bankslip-accounts.component";

export const HiringStatusDetailsComponent = (documentNumber: string) => {
  const [limit, setLimit] = useState<PostAdminLimitsGetLimitResponse>();
  const [pmeAccounts, setPmeAccounts] = useState<GetPmeAccountsResponse>();

  const { getLimit, getPmeAccount } = useHiringStatus(documentNumber);

  const fetchPmeAccounts = useCallback(async () => {
    const response = await getPmeAccount();
    setPmeAccounts(response);
  }, [getPmeAccount]);

  const fetchWalletLimit = useCallback(async () => {
    const response = await getLimit();
    setLimit(response);
  }, [getLimit]);

  useEffect(() => {
    fetchPmeAccounts();
  }, []);

  useEffect(() => {
    fetchWalletLimit();
  }, []);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Status da contratação"
          description="Confira detalhes dos avalistas, contratos e contas"
        />
      }
    >
      <div className="d-flex flex-column gap-4" style={{ marginTop: "20px" }}>
        <LimitGuarantors
          limitGuarantors={limit?.currentLimitRequest?.limitGuarantors ?? null}
          fetchWalletLimit={fetchWalletLimit}
        />
      </div>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "20px" }}>
        <BankslipContracts documentNumber={documentNumber} />
      </div>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "20px" }}>
        <BankslipAccounts
          pmeAccounts={pmeAccounts ?? null}
          fetchPmeAccounts={fetchPmeAccounts}
        />
      </div>
    </DetailsTemplate>
  );
};
