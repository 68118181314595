import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { mapCurrencySymbol } from "utils/currency";
import { ParsedTooltip } from "../../parsed-tooltip/parsed-tooltip.component";
import { StyledValue } from "../roadwalker-master-detail.style";
import { TradeMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerTradeDetails = (data: TradeMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    trade: {
      items: [
        {
          label: "TradeId",
          value: <StyledValue>{data.TradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "OrderIdentification",
          value: <StyledValue>{data.OrderIdentification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProcessingDateTime",
          value: (
            <StyledValue>
              {dayjs(data.ProcessingDateTime).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.ExternalId}</span>
                {data.ExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProductCode",
          value: <StyledValue>{data.ProductCode}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradeStatus",
          value: <StyledValue>{data.TradeStatus}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TransactionType",
          value: <StyledValue>{data.TransactionType}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PayerCodCGE",
          value: <StyledValue>{data.PayerCodCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PayeeCodCGE",
          value: <StyledValue>{data.PayeeCodCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Book",
          value: <StyledValue>{data.Book}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Strategy",
          value: <StyledValue>{data.Strategy}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "AssetType",
          value: <StyledValue>{data.AssetType}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Side",
          value: <StyledValue>{data.Side}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BookToBook",
          value: <StyledValue>{data.BookToBook}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradeDateTime",
          value: (
            <StyledValue>
              {dayjs(data.TradeDateTime).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExpiryDate",
          value: (
            <StyledValue>
              {dayjs(data.ExpiryDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MaturityDate",
          value: (
            <StyledValue>
              {dayjs(data.MaturityDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Currency",
          value: <StyledValue>{mapCurrencySymbol(data.Currency)}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Amount",
          value: (
            <StyledValue>
              {masks.currency(
                data.Amount,
                mapCurrencySymbol(data.Currency),
                ".",
              )}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "DisbursementDate",
          value: (
            <StyledValue>
              {dayjs(data.DisbursementDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "DisbursementAmount",
          value: (
            <StyledValue>
              {masks.currency(
                data.DisbursementAmount,
                mapCurrencySymbol(data.Currency),
                ".",
              )}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "RootTradeId",
          value: <StyledValue>{data.RootTradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ParentTradeId",
          value: <StyledValue>{data.ParentTradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BundleId",
          value: <StyledValue>{data.BundleId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradeSystem",
          value: <StyledValue>{data.TradeSystem}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradeVersion",
          value: <StyledValue>{data.TradeVersion}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },

        {
          label: "Streams",
          value: (
            <StyledValue>
              <ParsedTooltip value={data.Streams} />
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Errors",
          value: <StyledValue>{data.Errors}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Fcc",
          value: <StyledValue>{data.Fcc}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ContractIdentification",
          value: <StyledValue>{data.ContractIdentification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
