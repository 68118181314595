import { client } from "../client";
import { gateholderEndpoints } from "./gateholder.endpoints";
import {
  GetApiAllowListNoBiometryParams,
  PatchApiFraudSysManualAnalysisByIdBody,
  PostApiAllowListCreateBody,
  PostApiFraudSysGridBody,
} from "./models/requests";
import {
  DeleteApiAllowListNoBiometryByTaxidResponse,
  GetApiAllowListNoBiometryResponse,
  GetApiFilesDownloadByUuidResponse,
  GetApiFraudSysAnalysisDetailsByIdResponse,
  PatchApiFraudSysManualAnalysisByIdResponse,
  PostApiAllowListCreateResponse,
  PostApiFraudSysGridResponse,
} from "./models/responses";

export const gateholderService = {
  getFraudSysGrid(body: PostApiFraudSysGridBody) {
    return client.post<PostApiFraudSysGridResponse>(
      gateholderEndpoints.fraudSys.grid,
      body,
    );
  },
  getFraudSysAnalysisDetailsById(id: string) {
    return client.get<GetApiFraudSysAnalysisDetailsByIdResponse>(
      gateholderEndpoints.fraudSys.analysisDetails.byId(id),
    );
  },
  setFraudSysManualAnalysisById(
    id: string,
    body: PatchApiFraudSysManualAnalysisByIdBody,
  ) {
    return client.patch<PatchApiFraudSysManualAnalysisByIdResponse>(
      gateholderEndpoints.fraudSys.manualAnalysis.byId(id),
      body,
    );
  },
  deleteNoBiometryByTaxid(taxid: string) {
    return client.delete<DeleteApiAllowListNoBiometryByTaxidResponse>(
      gateholderEndpoints.allowList.noBiometry.byTaxid(taxid),
    );
  },
  getNoBiometryAllowList(params?: GetApiAllowListNoBiometryParams) {
    return client.get<GetApiAllowListNoBiometryResponse>(
      gateholderEndpoints.allowList.noBiometry.endpoint,
      { params },
    );
  },
  createAllowList(body: PostApiAllowListCreateBody) {
    return client.post<PostApiAllowListCreateResponse>(
      gateholderEndpoints.allowList.create,
      body,
    );
  },
  getFileByUuid(uuid: string) {
    return client.get<GetApiFilesDownloadByUuidResponse>(
      gateholderEndpoints.files.download.byUuid(uuid),
      {
        responseType: "blob",
      },
    );
  },
};
