import * as yup from "yup";

export interface DefaultContractFileConfigurationFormValues {
  isPJConfig: boolean;
  isPFConfig: boolean;
  workflowRelationship: string;
  contractFileConfigurationId: number;
}

export const defaultContractFileConfigurationFormValidationSchema: yup.ObjectSchema<DefaultContractFileConfigurationFormValues> =
  yup.object({
    isPJConfig: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    isPFConfig: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    contractFileConfigurationId: yup
      .number()
      .required("Este campo é obrigatório"),
    workflowRelationship: yup.string().required("Este campo é obrigatório"),
  });
