import React, { createContext, useContext, useState } from "react";

const conciliacaoDetailsContext = createContext(
  {} as ConciliacaoDetailsContext,
);

interface ConciliacaoProps {
  children: React.ReactNode;
}

interface ConciliacaoDetailsContext {
  maturityFilterDate: string;
  setMaturityFilterDate: React.Dispatch<React.SetStateAction<string>>;
}

export const ConciliacaoDetailsProvider = ({ children }: ConciliacaoProps) => {
  const [maturityFilterDate, setMaturityFilterDate] = useState<string>("");

  const value = {
    maturityFilterDate,
    setMaturityFilterDate,
  };

  return (
    <conciliacaoDetailsContext.Provider value={value}>
      {children}
    </conciliacaoDetailsContext.Provider>
  );
};

export const useConciliacaoDetailsContext = () =>
  useContext(conciliacaoDetailsContext);
