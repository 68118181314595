import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { service } from "services";

const modalId = "remove-batch-imports-confirmation-modal";

interface RemoveBatchButtonProps {
  gridRef: React.RefObject<
    DataGrid<HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem>
  >;
  reload: () => void;
}

export const RemoveBatchButton = ({
  gridRef,
  reload,
}: RemoveBatchButtonProps) => {
  const [loading, setLoading] = useState(false);

  const { callService } = useServiceCall(
    service.hubEnergy.deleteConsumerUnitsImportData,
  );

  const removeImportData = useCallback(async () => {
    const selected =
      (await gridRef.current?.instance.getSelectedRowsData()) ?? [];

    if (!selected.length)
      return OToastManager.warning("Nenhuma importação selecionada");

    if (
      selected.some(
        ({ status }) => status === "IMPORTADO" || status === "PROCESSANDO",
      )
    )
      return OToastManager.warning(
        "Não é possível remover importações com status IMPORTADO ou PROCESSANDO",
      );

    const promises = selected.map(({ id }) => callService(id));
    setLoading(true);
    try {
      await Promise.all(promises);
      OToastManager.success("As importações foram removidas com sucesso");
      reload();
    } catch (err) {
      OToastManager.warning("Erro ao remover as importações");
    } finally {
      setLoading(false);
    }
  }, [callService, gridRef, reload]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-edit-trash" }}
        loading={loading}
        onClick={() => modalManager.show(modalId)}
        type="danger"
      >
        Remover
      </IconButton>
      <OConfirmationModal
        modalId={modalId}
        title="Remover importações"
        divider={false}
        onConfirm={() => {
          removeImportData();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja remover as importações selecionadas?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
