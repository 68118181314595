import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const bannerTemplatesGrid: ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.getBannerTemplates>
    >["data"]
  >[number]
> = {
  datagrid: {
    noDataText: "Nenhum template encontrado.",
    dataSource: dataSourceCustomStoreGenerator(() => {
      return service.hubCreditManager.getBannerTemplates().catch(() => {
        throw new Error("Erro ao buscar templates.");
      });
    }),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      role: roles.workflow.product.editCampaignTemplatePage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.workflow.product.bannerManagement.bannerTemplates.edit.path(
            {
              id: data.id,
            },
          )}
        />
      ),
    },
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "actionText",
      caption: "Botão de ação",
    },
    {
      dataField: "actionRef",
      caption: "Redirecionamento",
    },
    {
      dataField: "openInNewTab",
      caption: "Abre em nova aba?",
      cellRender: ({ data }) => (data.openInNewTab ? "Sim" : "Não"),
    },
    {
      dataField: "imageAlt",
      caption: "Texto alternativo",
    },
    {
      dataField: "imageFile.name",
      caption: "Nome do arquivo",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.getBannerTemplates>
    >["data"]
  >[number]
>;
