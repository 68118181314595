import { OCard, OCardBody, OTypography } from "@maestro/react";
import { StatusBadge } from "components/status-badge";
import { useCallback, useMemo, useState } from "react";
import { InformationSection } from "./_compose/information-section";
import { SectionId } from "./bankslip-details.types";
import {
  MapperCollectionStatus,
  assembleDetailLines,
} from "./bankslip-details.utils";

interface BankslipDetailsProps {
  bankslip: BankingHub.Collection;
}

export const BankslipDetails = ({ bankslip }: BankslipDetailsProps) => {
  const [sectionsExpanded, setSectionsExpanded] = useState<SectionId[]>([
    SectionId.Resume,
  ]);

  const { label, type } = useMemo(
    () =>
      MapperCollectionStatus[bankslip.status] ?? {
        label: bankslip.status,
        type: "warning",
      },
    [bankslip],
  );

  const {
    resumeDetails,
    payeeDetails,
    payerDetails,
    feesDetails,
    paymentDetails,
    generalDetails,
  } = useMemo(() => assembleDetailLines(bankslip), [bankslip]);

  const updateSectionsExpanded = useCallback(
    (sectionId: SectionId) => {
      const updatedSections = sectionsExpanded.includes(sectionId)
        ? sectionsExpanded.filter((section) => section !== sectionId)
        : [...sectionsExpanded, sectionId];

      setSectionsExpanded(updatedSections);
    },
    [sectionsExpanded],
  );

  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-1">
        <div className="d-flex align-items-center justify-content-between">
          <OTypography type="dark" size="lg">
            Dados do boleto
          </OTypography>
          <StatusBadge type={type}>{label}</StatusBadge>
        </div>

        <InformationSection
          sectionId={SectionId.Resume}
          sectionsExpanded={sectionsExpanded}
          updateSectionsExpanded={updateSectionsExpanded}
          detailItems={resumeDetails}
        />

        <InformationSection
          sectionId={SectionId.Payee}
          sectionsExpanded={sectionsExpanded}
          updateSectionsExpanded={updateSectionsExpanded}
          detailItems={payeeDetails}
        />

        <InformationSection
          sectionId={SectionId.Payer}
          sectionsExpanded={sectionsExpanded}
          updateSectionsExpanded={updateSectionsExpanded}
          detailItems={payerDetails}
        />

        <InformationSection
          sectionId={SectionId.Fees}
          sectionsExpanded={sectionsExpanded}
          updateSectionsExpanded={updateSectionsExpanded}
          detailItems={feesDetails}
        />

        {!!paymentDetails.length && (
          <InformationSection
            sectionId={SectionId.Payment}
            sectionsExpanded={sectionsExpanded}
            updateSectionsExpanded={updateSectionsExpanded}
            detailItems={paymentDetails}
          />
        )}

        <InformationSection
          sectionId={SectionId.Details}
          sectionsExpanded={sectionsExpanded}
          updateSectionsExpanded={updateSectionsExpanded}
          detailItems={generalDetails}
        />
      </OCardBody>
    </OCard>
  );
};
