import { BankData } from "./bank-data";
import { FileUpload } from "./file-upload";
import { QuoteStep } from "./quote-step";
import { RequestAnticipation } from "./request-anticipation";
import { Result } from "./result";

export const steps = [
  {
    name: "Envio de arquivo",
    component: FileUpload,
  },
  {
    name: "Cotação",
    component: QuoteStep,
  },
  {
    name: "Dados Bancários",
    component: BankData,
  },
  {
    name: "Antecipação",
    component: RequestAnticipation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
