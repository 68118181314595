import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { GetCompaniesByIdContractTemplatesResponse } from "services/quickfin/models";
import { useContractTemplateSelector } from "./contract-template-selector.hook";

interface ContractTemplateSelectorProps {
  companyId: string | number | null;
  disabled?: boolean;
  label: string;
  name: string;
  required?: boolean;
  onChange?: (
    template: GetCompaniesByIdContractTemplatesResponse[number],
  ) => void;
}

export const ContractTemplateSelector = ({
  companyId,
  disabled,
  label,
  name,
  required,
  onChange,
}: ContractTemplateSelectorProps) => {
  const form = useFormContext();
  const { watch } = form;
  const contractTemplateWatch = watch(name);

  const { contractTemplates, refetch, hasFetchError, loading } =
    useContractTemplateSelector(companyId, form, name);

  useEffect(() => {
    if (onChange && contractTemplates?.length && contractTemplateWatch) {
      const selectedTemplate = contractTemplates.find(
        ({ id }) => contractTemplateWatch === id,
      );

      selectedTemplate && onChange(selectedTemplate);
    }
  }, [contractTemplates, onChange, contractTemplateWatch]);

  return (
    <div className="d-flex align-items-center flex-fill">
      <ORFieldSelect
        id={name}
        name={name}
        placeholder="Selecionar"
        disabled={hasFetchError || loading || disabled}
        label={label}
        required={required}
        key={contractTemplates?.length}
      >
        {contractTemplates?.map((template) => (
          <OOption
            key={template.id}
            value={template.id}
            onClick={() => onChange && onChange(template)}
          >
            {template?.file?.fileKey?.split("-")[0]} - {template.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && <RefreshGridButton onClick={refetch} />}
    </div>
  );
};
