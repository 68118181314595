import { OTypography } from "@maestro/react";
import { SummaryTable } from "../operation-summary/operation-summary.styles";

interface OperationLogsProps {
  result: HubEnergy.UpdateDiligenceStepWithConfigResponse;
}

export const OperationLogs = ({ result }: OperationLogsProps) => {
  return (
    <SummaryTable style={{ flex: 1 }}>
      <thead>
        <tr>
          <th>Logs</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ position: "relative", verticalAlign: "baseline" }}>
            <div
              style={{
                overflowY: "auto",
                position: "absolute",
                height: "calc(100% - 10px)",
              }}
            >
              {result.logs.map((l, i) => (
                <OTypography key={i}>{l}</OTypography>
              ))}
            </div>
          </td>
        </tr>
      </tbody>
    </SummaryTable>
  );
};
