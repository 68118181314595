import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { currencySymbol } from "utils/currency";

type LimitRequestsInactives = NonNullable<
  Awaited<
    ReturnType<
      typeof service.hubCreditManager.odata.getLimitRequestsInactivesAdmin
    >
  >["data"]["value"]
>[number];

export const limitRequestsInactivesGrid = {
  datagrid: {
    noDataText: "Nenhum limite encontrado.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubCreditManager.odata.limitRequestsInactivesAdmin,
    ),
    headerFilter: { visible: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product
        .limitManagerLimitRequestsInactivesDetailsSummaryPage.role,
      cellRender: ({ data }) =>
        !!data.Id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.product.limitManager.limitRequestsInactives.details.summary.path(
              {
                id: data.Id,
              },
            )}
          />
        ),
    },
    {
      dataField: "Id",
      caption: "Id da solicitação",
    },
    {
      dataField: "CustomerName",
      caption: "Nome",
    },
    {
      dataField: "CustomerTaxId",
      caption: "CNPJ",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "Amount",
      caption: "Limite",
      format: (value) => masks.currency(value, currencySymbol.BRL, "."),
    },
    {
      dataField: "CreditLineIdentification",
      caption: "Identicação",
    },
    {
      dataField: "CreatedDate",
      caption: "Data de criação",
      dataType: "datetime",
      sortOrder: "desc",
    },
    {
      dataField: "ExpirationDate",
      caption: "Data de vencimento",
    },
    {
      dataField: "Status",
      caption: "Status",
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitRequestsInactives>;
