import { ODataGridGeneratorConfig } from "components/data-grid";
import { Collaterals } from "../../collaterals.types";

export const CollateralsRegistriesGrid = {
  datagrid: {
    noDataText: "Nenhum registro associado foi encontrado.",
  },
  columns: [
    {
      dataField: "registryFirmName",
      caption: "Cartório",
    },
    {
      dataField: "registryFirmTaxId",
      caption: "CNPJ",
    },
    {
      dataField: "registryDate",
      caption: "Data de registro",
    },
    {
      dataField: "protocol",
      caption: "Protocolo",
    },
    {
      dataField: "city",
      caption: "Cidade",
    },
    {
      dataField: "neighborhood",
      caption: "Bairro",
    },
    {
      dataField: "state",
      caption: "Estado",
    },
    {
      dataField: "county",
      caption: "Comarca",
    },
    {
      dataField: "address",
      caption: "Endereço",
    },
    {
      dataField: "country",
      caption: "País",
    },
    {
      dataField: "zipCode",
      caption: "CEP",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Collaterals[number]["properties"][number]["registries"][number]
>;
