import { modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

export const deletePropertyConfirmationModalId = (id: number | string) =>
  `delete-property-confirmation-modal-${id}`;

interface DeletePropertyConfirmationModalProps {
  property: HubEnergy.DiligenceStepPropertySimpleResponse;
  onConfirm: () => void;
}

export const DeletePropertyConfirmationModal = ({
  property,
  onConfirm,
}: DeletePropertyConfirmationModalProps) => {
  const modalId = deletePropertyConfirmationModalId(property.id);

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Excluir propriedade"
      onConfirm={() => {
        modalManager.hide(modalId);
        onConfirm();
      }}
      divider={false}
      {...datagridModalFix}
    >
      <OTypography style={{ textAlign: "start", whiteSpace: "normal" }}>
        Tem certeza que deseja excluir a propriedade{" "}
        <strong>{property.type.label}</strong>?
      </OTypography>
    </OConfirmationModal>
  );
};
