import { SummaryTable } from "./operation-summary.styles";

interface OperationSummaryProps {
  notDryRun: boolean;
  overwrite: boolean;
  result: HubEnergy.UpdateDiligenceStepWithConfigResponse;
}

export const OperationSummary = ({
  notDryRun,
  overwrite,
  result,
}: OperationSummaryProps) => {
  return (
    <SummaryTable>
      <thead>
        <tr>
          <th colSpan={2}>Resumo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Salvar mudanças</td>
          <td>{notDryRun ? "Sim" : "Não"}</td>
        </tr>
        <tr>
          <td>Recriar steps</td>
          <td>{overwrite ? "Sim" : "Não"}</td>
        </tr>
        <tr>
          <td>Unidades Consumidoras</td>
          <td>{result.consumerUnits}</td>
        </tr>
        <tr>
          <td>Steps</td>
          <td>{result.steps}</td>
        </tr>
        <tr>
          <td>Propriedades adicionadas</td>
          <td>{result.addedProperties}</td>
        </tr>
        <tr>
          <td>Propriedades modificadas</td>
          <td>{result.changedProperties}</td>
        </tr>
        <tr>
          <td>Propriedades duplicadas</td>
          <td>{result.unmatchedProperties}</td>
        </tr>
        <tr>
          <td>Pendências adicionadas</td>
          <td>{result.addedPendencies}</td>
        </tr>
        <tr>
          <td>Pendências modificadas</td>
          <td>{result.changedPendencies}</td>
        </tr>
        <tr>
          <td>Pendências duplicadas</td>
          <td>{result.unmatchedPendencies}</td>
        </tr>
      </tbody>
    </SummaryTable>
  );
};
