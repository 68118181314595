import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./limit-manager.grid";

const dataSource = dataSourceCustomStoreGenerator<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.getCompanyCreditLines>
    >["data"]["value"]
  >[number]
>(() =>
  service.hubCreditManager
    .getCompanyCreditLines()
    .then(({ data }) => data.value ?? [])
    .catch(() => {
      const errorMessage = "Erro ao buscar os limites";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const LimitManager = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Gestor de limites" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
