import { OButton, OCol, OIcon, OLoader, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { ORFieldSelectSearch } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { ProfilesFormValues } from "./profiles-role-form.schemas";

interface ProfileSubProfilesFormProps {
  index?: number;
  profiles: string[];
  requestId: number;
  changeType: "ADD_SUB_PROFILES" | "REMOVE_SUB_PROFILES";
}

export const ProfileSubProfilesForm = ({
  index,
  profiles,
  requestId,
  changeType,
}: ProfileSubProfilesFormProps) => {
  const {
    value: availableSubProfiles,
    callService: getAvailableSubProfiles,
    loading: loadingSubProfiles,
    hasError: hasErrorSubProfiles,
  } = useServiceCall(service.gatekeeper.getAvailableSubProfilesForChange);

  const { setValue, watch, getValues } = useFormContext<
    ProfilesFormValues | ProfilesFormValues["changes"][number]
  >();

  const subProfilesResetValue = useRef(
    getValues(
      typeof index === "number"
        ? `changes.${index}.subProfileNames`
        : "subProfileNames",
    ),
  );

  const watchProfileName = watch(
    typeof index === "number" ? `changes.${index}.profileName` : "profileName",
  );

  const getSubProfiles = useCallback(() => {
    if (!watchProfileName) return;
    return getAvailableSubProfiles({
      changeType,
      profileName: watchProfileName,
      requestId,
    });
  }, [changeType, getAvailableSubProfiles, requestId, watchProfileName]);

  const profileOptions = useMemo(
    () =>
      profiles?.map((_profile) => ({
        value: _profile,
        label: _profile,
        customKey: _profile,
        onSelect: (value: string) => {
          setValue(
            typeof index === "number"
              ? `changes.${index}.profileName`
              : "profileName",
            value,
          );

          setValue(
            typeof index === "number"
              ? `changes.${index}.subProfileNames`
              : "subProfileNames",
            subProfilesResetValue.current,
          );

          subProfilesResetValue.current = [];
        },
      })) ?? [],
    [profiles, index, setValue],
  );

  const subProfilesOptions = useMemo(
    () =>
      availableSubProfiles?.subProfiles?.map((_subProfile) => ({
        value: _subProfile,
        label: _subProfile,
      })) ?? [],
    [availableSubProfiles],
  );

  useEffect(() => {
    getSubProfiles();
  }, [getSubProfiles]);

  return (
    <ORow className="flex-fill">
      <OCol xs={12} sm={4}>
        <ORFieldSelectSearch
          id={`changes.${index}.profileName`}
          name={
            typeof index === "number"
              ? `changes.${index}.profileName`
              : "profileName"
          }
          placeholder="Selecionar"
          options={profileOptions}
          className="w-100"
          maxOptions={50}
          label="Perfil"
          value={watchProfileName}
        />
      </OCol>
      <OCol xs={12} sm={8} className="flex-column">
        <SelectSearchMultiple
          id={`changes.${index}.subProfileNames`}
          name={
            typeof index === "number"
              ? `changes.${index}.subProfileNames`
              : "subProfileNames"
          }
          placeholder="Selecionar"
          options={subProfilesOptions}
          maxOptions={50}
          label="Sub-perfis"
          disabled={loadingSubProfiles}
        />
        {loadingSubProfiles && <OLoader />}
        {hasErrorSubProfiles && (
          <OButton
            type="default"
            outline
            dataLabel="adicionar_sub_profiles_form"
            dataAction="adicionar_sub_profiles_form:botao:sub_profiles"
            onClick={() => getSubProfiles()}
          >
            <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
          </OButton>
        )}
      </OCol>
    </ORow>
  );
};
