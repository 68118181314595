import { OIcon, OTypography } from "@maestro/react";
import { Badge } from "./badges.style";

interface IsPublicBadgeProps {
  isPublic: boolean | null | undefined;
}

export const IsPublicBadge = ({ isPublic }: IsPublicBadgeProps) => {
  if (!isPublic) return null;

  return (
    <Badge
      backgroundColor="var(--theme-info-light)"
      className="px-2 d-flex align-items-center gap-2"
    >
      <OIcon category="far" icon="fa-eye" type="current-color" size="sm" />
      <OTypography size="sm">Visível para o cliente</OTypography>
    </Badge>
  );
};
