import { modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import {
  AssociatedCondition,
  TemplateColumn,
} from "services/hubcreditmanager/models/types/workflow/workflow.types";

export const columnsGrid = (
  id: string,
  removeColumn: (BatchFileTemplateColumnId: number) => Promise<void>,
  setSelectedConditions: React.Dispatch<
    React.SetStateAction<AssociatedCondition[]>
  >,
  setSelectedTemplateColumn: React.Dispatch<
    React.SetStateAction<TemplateColumn | undefined>
  >,
) => {
  const { show } = modalManager;

  return {
    datagrid: {
      noDataText: "Nenhuma coluna configurada",
      dataSource: dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager.getBatchFileTemplateColumns(id).catch(() => {
          throw new Error("Não foi possível buscar a lista de Colunas.");
        }),
      ),
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "configurableColumn.name",
        caption: "Nome",
      },
      {
        dataField: "configurableColumn.description",
        caption: "Descrição",
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "fal", icon: "fa-edit" },
                label: "Condições",
                onClick: () => {
                  setSelectedTemplateColumn(data);
                  setSelectedConditions(data.conditions);
                  show("update-column-modal");
                },
              },
              {
                icon: {
                  category: "orq",
                  icon: "orq-edit-trash",
                },
                label: "Remover",
                onClick: async () => {
                  removeColumn(data.id);
                  component.getDataSource().reload();
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<TemplateColumn>;
};
