export enum AddressType {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
  OTHERS = "OTHERS",
}

export interface IAddress {
  id: string;
  street: string;
  city: string;
  state: string;
  neighborhood: string;
  zipCode: string;
  number?: string;
  complement?: string;
  type: AddressType;
}

interface IIssueDate {
  id: number;
  day: number;
}

interface IOfferProgram {
  bank: number;
  cardFlagType: string;
  creditVariant: string;
  externalChannel: number;
  externalProgram: number;
  id: number;
  issueDates: Array<IIssueDate>;
  modality: AccountModality;
  partner: string;
}

interface IOfferMetadata {
  offerId: string;
  addressId: string;
  programId: number;
  issueDate: {
    id: number;
    day: number;
  };
  printedName: string;
  phone: string;
  hiredAmount: number;
  modality: string;
  ownerDocument: string;
  ownerId: string;
  accountNumber: string;
  branch: string;
}

export interface IGuarantor {
  document: string;
  guaranteeRate: number;
  status: string;
}

export interface IOffers {
  id: string;
  ownerDocument: string;
  programs: Array<IOfferProgram>;
  grantedLimit: number;
  expireDate: string;
  taxes: Array<Record<string, number>>;
  addresses: Array<IAddress>;
  guarantors: IGuarantor[];
  isGuarantorsOnboarded: boolean;
  isContractSigned: boolean;
  isOnDebitHiringProcess: boolean;
  isOnHiringProcess: boolean;
  hasOnlyDebitCard: boolean;
  hasOnlyCreditCard: boolean;
  hasMultipleCards: boolean;
  metadata?: IOfferMetadata;
}

export enum CardStatus {
  Normal = "NORMAL",
  Blocked = "BLOCKED",
  Canceled = "CANCELED",
  Created = "CREATED",
  Damaged = "DAMAGED",
  Expired = "EXPIRED",
  Fraud = "FRAUD",
  Lost = "LOST",
  Warning = "WARNING",
  Robbed = "ROBBED",
  Requested = "REQUESTED",
  Sleeping = "SLEEPING",
  BlockedInternal = "BLOCKED_INTERNAL",
  BlockedByPaymentDefault = "BLOCKED_BY_PAYMENT_DEFAULT",
  Destroyed = "DESTROYED",
}

export enum CardModality {
  Debit = "DEBIT",
  Credit = "CREDIT",
  Multiple = "MULTIPLE",
}

export enum CardType {
  Physical = "PHYSICAL",
  Virtual = "VIRTUAL",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface IOwner {
  birthDate: string;
  document: string;
  gender: Gender;
  id: string;
  name: string;
  phone: string;
}

export enum CardVariant {
  Black = "BLACK",
  Platinum = "PLATINUM",
  Business = "BUSINESS",
  Gold = "GOLD",
  BusinessDebit = "BUSINESS_DEBIT",
}

export interface IVirtualCard {
  id: string;
  last4Digits: string;
  modality: CardModality;
  printedName: string;
  status: CardStatus;
  type: CardType;
  variant: CardVariant;
  createdAt: string;
}

export interface ICard {
  activatedAt: string;
  cvv: string;
  deliveryAddress: IAddress;
  deliveryDate: string;
  expirationDate: string;
  id: string;
  isContactlessEnabled: boolean;
  isTravelNoticeEnabled: boolean;
  isOnlineShoppingEnabled: boolean;
  isWithdrawalEnabled: boolean;
  last4Digits: string;
  modality: CardModality;
  number: string;
  owner: IOwner;
  printedName: string;
  status: CardStatus;
  trackingId: string;
  travelEndDate: string;
  travelStartDate: string;
  type: CardType;
  variant: CardVariant;
  virtualCards: Array<IVirtualCard>;
  debitAccount?: {
    branch?: string;
    number?: string;
  };
}

interface ICompany {
  document: string;
  name: string;
}

export interface ICreditLimit {
  availableAmount: number;
  availableWithdrawalAmount: number;
  maxAmount: number;
  totalAmount: number;
  usedAmount: number;
  withdrawalAmount: number;
}

enum LimitAnalysisStatus {
  Created = "CREATED",
  Approved = "APPROVED",
  Expired = "EXPIRED",
  Canceled = "CANCELED",
}

export interface ILimitAnalysis {
  amount: number;
  expirationDate: string;
  externalId: string;
  id: string;
  status: LimitAnalysisStatus;
}

enum OfferStatus {
  Created = "CREATED",
  Hired = "HIRED",
  Expired = "EXPIRED",
  Canceled = "CANCELED",
}

export interface IOfferAccount {
  creditHiredAt: null;
  id: string;
  limitAnalysis: ILimitAnalysis;
  productHiredAt: null;
  status: OfferStatus;
}

export enum AccountModality {
  Debit = "DEBIT",
  Credit = "CREDIT",
  Multiple = "MULTIPLE",
}

export const MapperAccountModality: Record<AccountModality, string> = {
  [AccountModality.Credit]: "Crédito",
  [AccountModality.Debit]: "Débito",
  [AccountModality.Multiple]: "Múltiplo (Crédito e Débito)",
};

export interface IAccountProgram {
  id: string;
  name: string;
  modality: AccountModality;
}

enum AccountStatus {
  Normal = "NORMAL",
  Blocked = "BLOCKED",
  Canceled = "CANCELED",
  Created = "CREATED",
}

export interface IDebitAccount {
  id: string;
  externalId: string;
  branch: string;
  number: string;
}

export interface IAccount {
  id: string;
  isInvoiceDirectDebitEnabled: boolean;
  externalId: string;
  cards: Array<ICard>;
  company: ICompany;
  creditLimit: ICreditLimit;
  program: IAccountProgram;
  offer: {
    id: string;
    limitAnalysis: {
      id: string;
      amount: number;
      expirationDate: string;
      externalId: string;
      status: string;
    };
    status: string;
    productHiredAt: string;
    creditHiredAt: string;
    isOnHiringProcess: boolean;
  };
  debitAccounts: IDebitAccount[];
  status: AccountStatus;
  hasNewCreditCardProposal: boolean;
  hasNewDebitCardProposal: boolean;
  isCanceledByPaymentDefault: boolean;
}

export enum CycleStatus {
  Closed = "CLOSED",
  PartiallyPaid = "PARTIALLY_PAID",
  Paid = "PAID",
  Open = "OPEN",
  Future = "FUTURE",
  Overdue = "OVERDUE",
  Installment = "INSTALLMENT",
}

export interface IInvoice {
  bestTransactionDay: string;
  canPay: boolean;
  creditAccountId: number;
  credits: number;
  cycleClosingDate: string;
  cycleOpeningDate: string;
  cycleStatus: CycleStatus;
  debits: number;
  dueDate: string;
  invoiceId: number;
  isCurrent: boolean;
  minimumPayment: number;
  month: number;
  paidBalance: number;
  partialPayment: null;
  previousBalance: number;
  realDueDate: string;
  remainingBalance: number;
  totalBalance: number;
  year: number;
}

export enum ContestationStatus {
  Requested = "REQUESTED",
  Sent = "SENT",
  Failed = "FAILED",
}

export interface IContestation {
  id: string;
  creditAccountId: string;
  externalAuthorizationId: string;
  description: string;
  ticketId: string;
  status: ContestationStatus;
  ticketDate: string;
  createdAt: string;
}

export const MapperCardType: Record<string, string> = {
  PHYSICAL: "Físico",
  PLASTIC: "Físico",
  VIRTUAL: "Virtual",
};

export enum ItemType {
  Transaction = "TRANSACTION",
  Confirmation = "CONFIRMATION",
  Card = "CARD",
  Statement = "STATEMENT",
  Mdes = "MDES",
  Credit = "CREDIT",
}

export enum ItemCategory {
  Authorize = "AUTHORIZE",
  Cancellation = "CANCELLATION",
  CancellationRefusal = "CANCELLATION_REFUSAL",
  Replacement = "REPLACEMENT",
  Refusal = "REFUSAL",
  PartialCancellation = "PARTIAL_CANCELLATION",
  Overdue = "OVERDUE",
  Open = "OPEN",
  Close = "CLOSE",
  CardBlock = "CARD_BLOCK",
  Payment = "PAYMENT",
  PartialPayment = "PARTIAL_PAYMENT",
  Creation = "CREATION",
  Production = "PRODUCTION",
  Delivery = "DELIVERY",
  Activation = "ACTIVATION",
  Block = "BLOCK",
  Unblock = "UNBLOCK",
  Accept = "ACCEPTED",
  LimitNear = "LIMIT_NEAR",
  LimitReached = "LIMIT_REACHED",
}

export const MapperItemCategoryAndType: Record<string, string> = {
  AUTHORIZE: "Compra autorizada",
  CANCELLATION: "Compra cancelada",
  CANCELLATION_REFUSAL: "Cancelamento não autorizado",
  REFUSAL: "Compra não autorizada",
  REPLACEMENT: "Compra atualizada",
  PARTIAL_CANCELLATION: "Cancelamento parcial",
  OVERDUE: "Atraso",
  OPEN: "Abertura",
  CLOSE: "Fechamento",
  CARD_BLOCK: "Bloqueio de cartão",
  PAYMENT: "Pagamento",
  PARTIAL_PAYMENT: "Pagamento parcial",
  CREATION: "Criação",
  PRODUCTION: "Produção",
  DELIVERY: "Entrega",
  ACTIVATION: "Ativação",
  BLOCK: "Bloqueio",
  UNBLOCK: "Desbloqueio",
  ACCEPTED: "Aceite",
  LIMIT_NEAR: "Limite próximo",
  LIMIT_REACHED: "Limite atingido",
  TRANSACTION: "Transação",
  CONFIRMATION: "Compra confirmada",
  CARD: "Cartão",
  STATEMENT: "Declaração",
  MDES: "MDES",
  CREDIT: "Crédito",
};

export enum Currency {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
}
export interface ITimelineItem {
  type: ItemType;
  creditAccountId: number;
  category: ItemCategory;
  tenantAccountTimestamp: string;
  data: {
    item: {
      contractAmount: number;
      responseCode: string;
      merchantCity: string;
      dcc: boolean;
      orgOperation: {
        processingCode: string;
      };
      programId: number;
      entryMode: string;
      numberOfInstallments: number;
      principalAmount: number;
      settlementCurrencyLiteral?: Currency;
      currencyCode: string;
      cardholderbillingAmount: number;
      ledgerUpdateId: string;
      settlementCurrencyCode?: string;
      network: string;
      preAuthorization: boolean;
      merchantCategoryGroup: string;
      softDescriptor: string;
      installments: any[];
      currencyLiteral: Currency;
      authorizationCode: string;
      cashBackAmount: number;
      airportTax: number;
      interestRate: number;
      id: number;
      mti: string;
      installmentAmount: number;
      toName: string;
      merchantCategoryCode: string;
      settlementCurrencyRate?: number;
      merchantStateOrCountry: string;
      cardType: string;
      cardId: number;
      cardholderCurrencyRate: number;
      localAmount: number;
      cardName: string;
      entryModeLiteral: string;
      customerId: number;
      settlementCurrencyAmount?: number;
      merchantName: string;
      merchantImageURL: string;
      categoryName: string;
      cardLast4Digits: string;
      isCredit: boolean;
      timelineImageURL: string;
      categoryImageURL: string;
      title: string;
      subTitle: string;
      description: string;
      authorizationType: string;
      authorizationDescription: string;
      negativeReason: string;
      refusalReason: string;
      attendantRefusalReason: string;
      isInternational: boolean;
      tax: { type: string; value: number }[];
    };
  };
  correlationId: string;
  timestamp: string;
  relatedItems?: ITimelineItem[];
}

export interface IVirtualCardItem extends IVirtualCard {
  ownerName: string;
}

export interface OffersPagination {
  page: number;
  itemsPerPage: number;
}
