import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useCallback, useState } from "react";
import { service } from "services";

interface ResendDataReviewProps {
  workflowUuid: string;
  modalId: string;
}

export const ResendDataReview = ({
  workflowUuid,
  modalId,
}: ResendDataReviewProps) => {
  const { customer } = useCustomer();
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      await service.onboarding.resendDataReview({
        taxId: customer.identification,
        workflowUuid,
      });

      OToastManager.info("Revisão reenviada para análise.");

      modalManager.hide(modalId);
    } catch (error) {
      OToastManager.warning("Erro ao enviar revisão para análise.");
    } finally {
      setLoading(false);
    }
  }, [customer.identification, modalId, workflowUuid]);

  return (
    <OConfirmationModal
      modalId={modalId}
      size="sm"
      title="Reenviar revisão para análise ?"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      onConfirm={submit}
      loading={loading}
    >
      <div> {`Id: ${workflowUuid}`}</div>
    </OConfirmationModal>
  );
};
