import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { useMemo } from "react";
import { useInteractionsCompany } from "./interactions.hook";
import { interactionsCompanyGrid } from "./interactions.grid";
import { CustomPagination } from "./_compose/custom-pagination";

export const InteractionsCompany = () => {
  const {
    dataSource,
    form,
    funds,
    loading,
    resend,
    taxId,
    totalItems,
    page,
    setPage,
    rows,
    setRows,
  } = useInteractionsCompany();

  const gridRef = useGridRef();

  const grid = useMemo(() => {
    return interactionsCompanyGrid(resend);
  }, [resend]);

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        {taxId && (
          <GridTemplate
            pageTitle={
              <PageTitle
                title={`Interações da Empresa ${
                  funds?.find((fund) => fund.taxId === taxId)?.name ?? ""
                }`}
              />
            }
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              loading={loading}
              grid={grid}
              dataSource={dataSource}
            />
            <CustomPagination
              totalCount={totalItems}
              currentPage={page}
              setCurrentPage={setPage}
              pagination={[10, 20, 30]}
              selectedPaginationSize={rows}
              setSelectedPaginationSize={setRows}
            />
          </GridTemplate>
        )}
      </div>
    </FormProvider>
  );
};
