import {
  OAccordion,
  OButton,
  OCollapse,
  OCollapseContent,
  OCollapseHeader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { CancelingDrawnAndTransferor } from "../../../../cancelamento/form-compose";
import {
  DateInputs,
  MultiSelects,
  TagInputs,
  EntitiesSelects,
} from "./_compose";
import { NotesFilterForm } from "./note-filter.types";
import { noteFilterModalId } from "./note-filter.utils";

interface NoteFilterProps {
  form: UseFormReturn<NotesFilterForm>;
  onSubmit: () => void;
  resetFilter: () => void;
}

export const NoteFilter = ({
  form,
  onSubmit,
  resetFilter,
}: NoteFilterProps) => {
  return (
    <OModal id={noteFilterModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <CancelingDrawnAndTransferor />
            <MultiSelects />
            <DateInputs />
            <OAccordion className="px-1">
              <OCollapse id="more-filters">
                <OCollapseHeader>
                  <OTypography>Mais filtros...</OTypography>
                </OCollapseHeader>
                <OCollapseContent>
                  <ORow>
                    <TagInputs />
                    <EntitiesSelects />
                  </ORow>
                </OCollapseContent>
              </OCollapse>
            </OAccordion>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton
            dataAction="cancelamento_recebiveis:botao:limpar_filtro"
            dataLabel="limpar_filtro"
            onClick={() => resetFilter()}
            outline
          >
            Limpar
          </OButton>
          <OButton
            dataAction="cancelamento_recebiveis:botao:pesquisar"
            dataLabel="pesquisar"
            onClick={() => onSubmit()}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
