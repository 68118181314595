import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes } from "../../../../../../../../routes/antecipacao-de-cartoes.route-params";

export const useOperationDetails = () => {
  const { companyId, operationId } =
    useParams<AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes>();
  if (!companyId || !operationId) throw new Error("No url parameters");

  const {
    callService,
    hasError,
    loading,
    value: operationDetails,
  } = useServiceCall(service.quickfin.getCompanyOperation);

  const getOperation = useCallback(
    () => callService(companyId, operationId),
    [callService, companyId, operationId],
  );

  useEffect(() => {
    getOperation();
  }, [getOperation]);

  return {
    getOperation,
    hasError,
    loading,
    operationDetails,
  };
};
