import {
  modalManager,
  OButton,
  OCheckbox,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useState } from "react";

interface ConfirmPrepaymentModalProps {
  beginAnticipation: () => void;
}

export const CONFIRM_PREPAYMENT_MODAL = "confirm-prepayment-modal";

export const CONFIRM_PREPAYMENT_STORAGE_HASH =
  "confirm-prepayment-do-not-show-again";

export const ConfirmPrepaymentModal = ({
  beginAnticipation,
}: ConfirmPrepaymentModalProps) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

  return (
    <OModal
      className="modal-show text-center"
      id={CONFIRM_PREPAYMENT_MODAL}
      position="center"
      backdrop={false}
      size="xs"
    >
      <OModalHeader>
        <OTypography size="lg">
          Confirmar solicitação de pré-pagamento?
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <OTypography size="md">
            Caso confirmada, enviaremos uma notificação ao cliente.
          </OTypography>
          <div className="d-flex gap-2 align-items-center">
            <OCheckbox
              size="sm"
              id="do-not-show-again"
              value=""
              checked={doNotShowAgain}
              onClick={() => {
                if (doNotShowAgain) {
                  sessionStorage.removeItem(CONFIRM_PREPAYMENT_STORAGE_HASH);
                } else {
                  sessionStorage.setItem(
                    CONFIRM_PREPAYMENT_STORAGE_HASH,
                    "true",
                  );
                }

                setDoNotShowAgain(!doNotShowAgain);
              }}
            />
            <OLabel htmlFor="do-not-show-again">
              <i>Não mostrar este aviso novamente.</i>
            </OLabel>
          </div>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-2">
          <OButton
            type="light"
            onClick={() => {
              modalManager.hide(CONFIRM_PREPAYMENT_MODAL);
            }}
          >
            Voltar
          </OButton>
          <OButton
            onClick={() => {
              modalManager.hide(CONFIRM_PREPAYMENT_MODAL);
              beginAnticipation();
            }}
          >
            Confirmar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
