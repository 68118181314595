import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  ORFieldInput,
  OToastManager,
} from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { CreditManagerAddressForm } from "../../../../../../components/address/credit-manager-address-form.component";
import { WorkflowProdutoLeadByIdConsumerUnitsByUnitId } from "../../../../../../routes/workflow.route-params";
import {
  WorkflowConsumerUnitFormValues,
  workflowConsumerUnitValidationFormSchema,
} from "./workflow-consumer-unit-form.schemas";
import { workflowConsumerUnitDetailsGenerator } from "./workflow-consumer-unit.details";

export const WorkflowConsumerUnitPage = () => {
  const { id, unitId } =
    useParams<WorkflowProdutoLeadByIdConsumerUnitsByUnitId>();

  if (!id || !unitId) throw new Error("No id or No unitId");

  const form = useForm<WorkflowConsumerUnitFormValues>({
    resolver: yupResolver(workflowConsumerUnitValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getConsumerUnitDetails,
    value,
    hasError,
    loading,
  } = useServiceCall(service.hubCreditManager.getConsumerUnitDetails);

  const { callService: saveConsumerUnit, loading: loadingSubmit } =
    useServiceCall(service.hubCreditManager.saveConsumerUnit);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!value?.id) throw new Error("No unitId");

        const { success } = await saveConsumerUnit({
          unitId: value.id,
          energyConsumerUnitInfos: {
            installationNumber: values.installationNumber,
            invoiceAmount: value.amount,
            fareGroup: value.fareGroup,
            modality: value.modality,
            description: value.description,
            energyDistributionCompanyId: value.energyDistributionCompany.id,
          },
          address: values?.address,
        });
        if (!success) return OToastManager.danger("Erro ao salvar dados");

        OToastManager.success("Dados salvos com sucesso");
      }),
    [handleSubmit, saveConsumerUnit, value],
  );

  useEffect(() => {
    getConsumerUnitDetails(unitId);
  }, [getConsumerUnitDetails, unitId]);

  useEffect(() => {
    value && reset(value as WorkflowConsumerUnitFormValues);
  }, [reset, value]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes da Unidade Consumidora" />}
    >
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loading}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível carregar os detalhes da unidade consumidora."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton onClick={() => getConsumerUnitDetails(unitId)} />
            </ErrorComponent>
          }
          hasError={hasError}
          value={value}
          fields={workflowConsumerUnitDetailsGenerator}
        />
        <FormTemplate
          actions={
            <LoadingButton
              loading={loadingSubmit}
              onClick={submit}
              dataAction="salvar"
              dataLabel="salvar_consumidora:botao:salvar"
            >
              Salvar
            </LoadingButton>
          }
        >
          <OCard>
            <OCardHeader>Dados da fatura</OCardHeader>
            <OCardBody>
              <FormProvider {...form}>
                <OCol md={6}>
                  <ORFieldInput
                    required
                    tag="text"
                    id="installationNumber"
                    name="installationNumber"
                    dataAction="energia_formulario:texto:numero_instalacao"
                    dataLabel="numero_instalacao"
                    label="Número de instalação"
                  />
                </OCol>
                <CreditManagerAddressForm />
              </FormProvider>
            </OCardBody>
          </OCard>
        </FormTemplate>
      </div>
    </DetailsTemplate>
  );
};
