import { OToastManager, modalManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import {
  CreateDocumentsConfirmationModal,
  createDocumentsConfirmationModalId,
} from "./_compose";
import { getValidationMessages } from "../../../../../../../utils";

interface CreateTradeDocumentsButtonProps {
  tradeId: string;
}

export const CreateTradeDocumentsButton = ({
  tradeId,
}: CreateTradeDocumentsButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createTradeDocuments,
  );

  const create = useCallback(async () => {
    const { success, response, error } = await callService({
      tradeId: Number(tradeId),
    });

    if (success) {
      if (response.data.success) {
        OToastManager.success("Documentos gerados");
      } else {
        OToastManager.danger(
          response.data.errorMessage ?? "Documentos foram gerados com erro",
        );
      }
    } else {
      const errorMessage =
        getValidationMessages(error)?.[0].errorMessage ??
        "Não foi possível gerar os documentos";

      OToastManager.danger(errorMessage);
    }
  }, [callService, tradeId]);

  return (
    <>
      <LoadingButton
        loading={loading}
        onClick={() => modalManager.show(createDocumentsConfirmationModalId)}
      >
        Gerar documentos
      </LoadingButton>
      <CreateDocumentsConfirmationModal create={create} />
    </>
  );
};
