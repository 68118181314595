import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";
import { CardReceivableWithOverrideMaturityAmount } from "../../intubate-operations.types";

export const summaryGrid: ODataGridGeneratorConfig<CardReceivableWithOverrideMaturityAmount> =
  {
    datagrid: {
      noDataText: "Nenhum recebível.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "Sacado",
        dataType: "string",
        caption: "Sacado",
        allowHeaderFiltering: false,
      },
      {
        dataField: "Cedente",
        dataType: "string",
        caption: "Cedente",
        allowHeaderFiltering: false,
      },
      {
        dataField: "Bandeira",
        dataType: "string",
        caption: "Bandeira",
        alignment: "center",
        cellRender: ({ data }) =>
          getValueFromMap(cardIconMap, data.Bandeira) || data.Bandeira,
      },
      {
        dataField: "DataVencimento",
        caption: "Data de Vencimento",
        dataType: "date",
        format: "shortDate",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorVencimento",
        caption: "Valor Vencimento",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorSolicitado",
        caption: "Valor Solicitado",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        caption: "Cotação",
        allowHeaderFiltering: false,
        cellRender: ({ data }) =>
          masks.currency(
            data.ValorDesembolsoSolicitado || data.ValorDesembolso,
            "R$",
          ),
      },
    ],
  };
