import styled, { css } from "styled-components";
import { env } from "utils/environments";

export const EnvironmentLine = styled.div`
  ${env.PROJECT_ENV === "uat"
    ? css`
        background: var(--theme-warning);
      `
    : css`
        background: #1ec8c9;
      `}
  height: 4px;
`;
