import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { CompanyDetailsCardTemplate } from "../template";
import { EloaCardContents } from "./eloa-card-contents.component";
import { useEloaCard } from "./eloa-card.hook";

export const EloaCard = () => {
  const { eloaInfo, getEloaInfo, hasError, isNotFoundError, loading } =
    useEloaCard();

  return (
    <CompanyDetailsCardTemplate title="Procuração Eletrônica">
      <ContentTemplate
        loading={loading}
        value={eloaInfo}
        hasError={hasError}
        errorComponent={
          isNotFoundError ? (
            <EmptyState messageParagraph="Nenhuma informação" />
          ) : (
            <DefaultErrorComponent
              title="Não foi possível buscar as informações"
              callback={getEloaInfo}
            />
          )
        }
        render={(_eloaInfo) => (
          <EloaCardContents
            description={_eloaInfo.description}
            status={_eloaInfo.status}
            updatedAt={_eloaInfo.updatedAt}
          />
        )}
      />
    </CompanyDetailsCardTemplate>
  );
};
