import { getValueFromMap } from "utils/get-value-from-map";
import { MappedStatus } from "./status-maps.types";

export const getStatusFromMap = (
  map: Record<string, MappedStatus>,
  status: string | null | undefined,
) =>
  getValueFromMap(map, status) ?? {
    text: status ?? "---",
    type: "warning",
  };
