import { PropertyStageComponent } from "./property-stage";
import { StepAnalysisStageType } from "./step-analysis-stages.types";
import { StepPendenciesStageComponent } from "./step-pendencies-stage";
import { SummaryStage } from "./summary-stage";

export const stageMap = {
  PENDENCIAS_DA_ETAPA: StepPendenciesStageComponent,
  PROPRIEDADE: PropertyStageComponent,
  RESUMO: SummaryStage,
} satisfies Record<StepAnalysisStageType, () => JSX.Element>;
