import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { User, usersGrid } from "../_compose";

const dataSource = dataSourceCustomStoreGenerator<User>((loadOptions) =>
  service.clerk
    .getAllUsersOdata(loadOptions as any) // sort type
    .then(({ data }) => ({
      data: data.data ?? [],
      totalCount: data.total_count,
    }))
    .catch(() => {
      const errorMessage = "Não foi possível buscar os usuários";
      throw new Error(errorMessage);
    }),
);

export const B2CUsersPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Todos os usuários" />}
      actions={
        <StandardLink
          href={corporateRouter.routes.usuarios.product.b2c.createAdmin.path}
          role={roles.usuarios.product.b2cCreateAdminPage.role}
        >
          Adicionar administrador
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={usersGrid("b2c")}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
