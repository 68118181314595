import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OField,
  ORFieldInput,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PostSnakeFinancialBody } from "services/fidc/models/requests";
import { service } from "services/service";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { EditList } from "../../../edit-list";
import { FinancialActionSearchParams } from "../interaction-financials.type";
import {
  companyInteractionFinancialAddDefaultValues,
  companyInteractionFinancialAddValidationSchema,
} from "./interaction-financial-add.form";
import { CompanyInteractionFinancialAddForm } from "./interaction-financial-add.type";

export const CompanyInteractionFinancialAdd = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState<string>();
  const [taxId, setTaxId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const form = useForm<CompanyInteractionFinancialAddForm>({
    resolver: yupResolver(companyInteractionFinancialAddValidationSchema),
    defaultValues: companyInteractionFinancialAddDefaultValues,
  });
  const { handleSubmit } = form;

  const fetchFinancialContact = useCallback(async () => {
    const { contact } = getSearchParams<FinancialActionSearchParams>();

    if (!contact) {
      return;
    }

    const parsedContact = JSON.parse(contact);

    setCompanyId(parsedContact.id);
    setTaxId(parsedContact.taxId);
  }, []);

  useEffect(() => {
    fetchFinancialContact();
  }, [fetchFinancialContact]);

  const onSubmit = handleSubmit(async (values) => {
    const validateField = (field: string | string[]) => {
      if (Array.isArray(field)) {
        return field.filter((value) => value && value.trim() !== "");
      }
      return field && field.trim() !== "" ? [field] : [];
    };

    const validEmails = validateField(values.email);
    const validPhones = validateField(values.cellPhoneNumber);

    if (validEmails.length === 0 || validPhones.length === 0) {
      OToastManager.danger(
        "É necessário fornecer ao menos um email e um número de telefone válido.",
      );
      return;
    }

    const body: PostSnakeFinancialBody = {
      __KEY__: "10000000-1000-4000-8000-100000000000".replace(/[01]/g, () =>
        Math.floor(Math.random() * 16).toString(16),
      ),
      cellPhoneNumber: validPhones.join(";"),
      companyId: companyId ?? "",
      companyTaxId: taxId ?? "",
      email: validEmails.join(";"),
      name: values.name ?? "",
    };

    setLoading(true);
    try {
      await service.fidc.postFinancial(body);
      OToastManager.success("Contato financeiro adicionado com sucesso!");
      navigate(-1);
    } catch {
      OToastManager.danger("Erro ao tentar adicionar contato financeiro.");
    } finally {
      setLoading(false);
    }
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar contato" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_contato_financeiro:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="adicionar_contato_financeiro:texto:id"
                dataLabel="id"
                id="id"
                name="id"
                tag="text"
                label="Id"
                disabled
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="adicionar_contato_financeiro:texto:nome"
                dataLabel="nome"
                id="name"
                name="name"
                tag="text"
                label="Nome"
              />
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="email" label="Email">
                <EditList
                  field="email"
                  sectionName="adicionar_contato_financeiro"
                />
              </OField>
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="cellPhoneNumber" label="Telefone">
                <EditList
                  field="cellPhoneNumber"
                  sectionName="adicionar_contato_financeiro"
                />
              </OField>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
