export const errorMessageMap = {
  AtLeastOneCedente: "Informar pelo menos um cedente",
  AtLeastOneReceivable: "Informar pelo menos um recebível",
  AtLeastOneSacado: "Informar pelo menos um sacado",
  CantHaveDisbursementeAmount: "Não pode ter valor de desembolso",
  CantHavePixTypeAndBankInfo: "Não pode ter tipo pix e informações bancárias",
  CantHavePixInfoAndPagfor: "Não pode ter pix e ser pagfor",
  CantHavePixKeyAndBankInfo: "Não pode ter chave pix e informações bancárias",
  CantHaveRebateWithRebateAlongaPrazo:
    "Estratégia com rebate alonga prazo não permite inserir um valor de rebate",
  CantHaveTaxaParticularIfRebateFixo:
    "Não é permitido taxa fornecedor com rebate fixo configurado para as taxas BTG",
  CantHaveVisibilidadeIfDoesntHavePrazo:
    "'Prazo que o cedente visualiza' não pode ser diferente de 'Prazo Original' se não tem rebate alonga prazo",
  CedenteCadastrado: "Cedente já associado ao sacado",
  CedenteDesativado: "Cedente está inativado",
  CedenteInvalido: "Cedente inválido",
  CedenteMustHaveCgeWhenOffShore:
    "Cedente precisa de CGE quando o bordero é offshore",
  CedenteNaoCadastrado: "Cedente não cadastrado",
  CedenteRiskNotImplemented: "Risco cedente não implementado",
  CodigoFornecedorDoestnNeedBankAccount:
    "Conta bancária não é necessária quando o bordero é offshore",
  CodigoFornecedorMustHaveEstrategiaOffshore: "Código do fornecedor inválido",
  CompanyNotFound: "Empresa não encontrada",
  ConfigurationNotFound: "Configuração não encontrada",
  ContractTemplateGUIDDoesNotExists: "O identificador do contrato não existe",
  ContratoInvalido: "Contrato inválido",
  ConvenioCannotBeRemovedDueContainingSacadoCedenteObjsCompanyConvenios:
    "Convênio tem vínculo com cedente!",
  ConvenioCanOnlyHaveRebateOrSpreadDoRebateNotBoth:
    "Só é possível registrar um tipo de rebate",
  ConvenioGUIDDoesNotExists: "Código do convênio não encontrado",
  default: "Erro desconhecido",
  DiasDeVencimentoInvalidos: "Dias de vencimento inválidos!",
  DiasDeVencimentoRepetidos: "Não pode conter dias repetidos",
  RepasseInvalido: "Data de repasse inválida",
  DisbursmentValueInvalid: "Valor de desembolso superior a taxa",
  DisbursmentValueNotAllowed: "Valor de desembolso não permitido",
  DuplicatedInstallments: "Parcelas duplicadas",
  EmailAlreadyInUse:
    "Não foi possível criar a sua conta com os dados fornecidos",
  EstrategiaComercialNotFound: "Estratégia comercial não encontrada",
  IdentificationRequired: "Identificação obrigatória",
  CountryCodeRequired: "Código do país obrigatório",
  CountryCodeInvalidForOffshore: "Código do país inválido",
  IofMustBeGreaterThanZero: "Iof deve ser maior que zero",
  InvalidAccountDigit: "Dígito da conta inválido",
  InvalidAgency: "Agencia inválida",
  InvalidBankAccount: "Conta bancária inválida",
  InvalidBankCode: "Código do banco inválido",
  InvalidCNPJ: "CNPJ inválido",
  InvalidCodigoFornecedor: "Código de fornecedor inválido ou inexistente",
  InvalidCompanyEntityType: "Tipo de documento inválido",
  InvalidCompanyType: "Tipo da empresa inválido",
  InvalidContractTemplate: "Template de contrato inválido",
  InvalidContractType: "Modelo de termo inválido",
  InvalidConvenio: "Convênio inválido",
  InvalidCPF: "CPF inválido",
  InvalidHmac: "Hmac inválido",
  InvalidInstallmentNumber: "Nº de uma das parcelas inválido",
  InvalidInstallmentAmortization: "Valor de amortização de uma das parcelas inválido",
  InvalidInstallmentAmount: "Valor de uma das parcelas inválido",
  InvalidInstallmentMaturityDate:
    "Data de vencimento de uma das parcelas inválida",
  InvalidLoanAgreementId: "Id do convênio de empréstimo inválido",
  InvalidLoanBaseDayCount: "Base de dias do empréstimo inválida",
  InvalidLoanRate: "Taxa do empréstimo inválida",
  InvalidMaturityDate: "Data de vencimento inválida",
  InvalidMonetaryCorrectionIndexCode:
    "Índice de correção de uma das parcelas inválido",
  InvalidMonetaryCorrectionIpcaCorrection:
    "IPCA de correção de uma das parcelas inválido",
  InvalidMonetaryCorrectionVariationRule:
    "Regra de variação de correção de uma das parcelas inválida",
  InvalidMonetaryCorrectionBaseValue:
    "Valor base de correção de uma das parcelas inválido",
  InvalidMonetaryCorrectionFrequency:
    "Frequência de correção de uma das parcelas inválida",
  InvalidMonetaryCorrectionLag:
    "Defasagem de correção de uma das parcelas inválida",
  InvalidDisbursementDate: "Data de desembolso inválida",
  InvalidOperation: "Operação inválida",
  InvalidContaPix: "Informações faltantes de conta bancária",
  InvalidPixType: "Tipo pix inválido",
  InvalidPixKey: "Chave pix inválida",
  InvalidRoles: "Permissão inválida",
  InvalidRebate: "Rebate inválido",
  InvalidSacado: "Sacado inválido",
  InvalidTemplate: "Template inválido",
  InvalidToken: "Token inválido",
  InvalidTradeId: "ID do trade inválido",
  InvalidValue: "Valor inválido",
  MissingEmail: "Informe o email",
  MissingestrategiaId: "Informe o identificador do convênio",
  MissingIdentificadorExterno: "Informe o identificador externo",
  MissingRegisterNumber: "Informe o número de registro",
  MissingRepository: "Informe a registradora",
  MissingTaxId: "Informe o CNPJ/CPF",
  MustBeCedente: "A empresa deve ser do tipo cedente",
  MustBeCodigoFornecedorForOffshore:
    "Documento deve ser código fornecedor para operações offshore",
  MustBeGreaterThanPreviousValue: "Valor precisa ser maior que o anterior",
  MustBeSacado: "A empresa deve ser do tipo sacado",
  MustHaveCedenteIdentification:
    "Identificador inválido/coluna com nome inválido",
  MustHaveCgeWhenOffShore: "Informe o CGE",
  MustHaveContaLiquidacao: "Informe uma conta de liquidação",
  MustHaveContractTemplate: "Template de contrato não pode ser vazio",
  MustHaveContractType: "Tipo de contrato não pode ser vazio",
  MustHaveDiasParaVencimentoSacado:
    "Dias para vencimento sacado não pode ser vazio",
  MustHaveDiasParaVencimentoDataRepasseRiscoCedente:
    "Dias para vencimento data repasse risco cedente não pode ser vazio",
  MustHaveEstrategiaOffshore: "Estratégia configurada não é offshore",
  MustHaveFCCId: "Fcc não pode ser vazio",
  MustHaveInstallments: "Necessário informar parcelas",
  MustHaveNoIdentificationWhenOffshore:
    "Identificação deve estar vazia quando Offshore",
  MustHaveOfficialName: "Informe o nome da empresa",
  MustHavePositiveRebateTolerance:
    "Tolerância de rebate deve ser um valor positivo",
  MustHaveTipoOnboarding: "Tipo de onboarding não pode ser vazio",
  MustHaveValorVencimento: "Precisa ter valor de vencimento",
  MustHaveTipoTaxaParticular:
    'Ao marcar "Permitir definição de taxa Sacado/Cedente" deve ser informada a "Configuração taxa Sacado/Cedente"',
  MustHaveVisbilidadeIfRebateAlongaPrazo:
    "'Prazo que o cedente visualiza' deve ser preenchido se tem rebate alonga prazo",
  MustNotHaveInstallments: "Não pode haver parcelas nessa operação",
  NotaEmOperacao: "Nota já em operação",
  OffshoreDoestnNeedBankAccount:
    "Bordero offshore não necessite de conta bancária",
  OffshoreMustHaveEstrategiaOffshore: "Estratégia configurada não é offshore",
  OneCurrencyPerBordero: "Apenas uma moeda permitida por bordero",
  OneTemplatePerProductPortfolioMarketplace:
    "Apenas um template permitido por produto, carteira e marketplace",
  ReceivableAlreadyExists: "Este recebível já existe em nossa plataforma",
  ReceivableAlreadyExistsInThisSheet: "O recebível está repetido na planilha",
  RepeatedCompanyForThisCedente: "Sacado já associado a este cedente ",
  RepeatedCompanyForThisSacado: "Cedente já associado a este sacado ",
  SacadoMustHaveCGEWhenOffShore: "Sacado precisa de CGE",
  TaxaBaseNaoCadastrada: "Taxa base não cadastrada para este fornecedor",
  TaxaNaoCadastrada: "Taxa não cadastrada para o período da nota",
  TaxaParticularInvalida: "Taxa fornecedor inválida ou inferior à taxa base",
  TaxIdAlreadyInUse: "CNPJ/CPF já cadastrado",
  TaxIdNotAuthorized: "CNPJ/CPF não autorizado",
  UserCPFAlreadyExists:
    "Não foi possível criar a sua conta com os dados fornecidos",
  UserCpfIsNotValid: "CPF inválido",
  UserNameCanNotBeEmpty: "O campo nome não pode ser vazio",
  UsernameExistsException:
    "Não foi possível criar a sua conta com os dados fornecidos",
  UserNotAuthorized: "Usuário não autorizado",
  UserNotFound: "Usuário não encontrado",
  VencimentoInvalido: "Vencimento inválido",
  ConvenioCannotBeRemovedDueContainingSacadoCedenteObjs:
    "Convênio está sendo usado!",
  InvalidRiscoOperacao: "Risco de operação inválido",
  MustHaveRiscoOperacao: "Risco de operação não encontrado",
  CnpjNotMatriz: "O CNPJ informado não corresponde a uma matriz",
  InvalidTaxId: "CNPJ inválido",
  CompanyNotHaveRequiredRole: "A empresa não possui uma role necessária",
  CnpjNotSubsidiary: "A empresa não é subsidiária",
  InvalidGroupType: "Tipo de grupo inválido",
  OnboardCantBeEdited: "Este onboarding não pode ser editado",
  InvalidPhoneNumber: "Telefone inválido",
  InvalidEmail: "Email inválido",
  EmailAndTaxIdNotAllowed: "Email ou CPF já em uso",
  CnpjIsNotActiveInRfb: "CNPJ inativo na RFB",
  CpfIsNotRegularInRfb: "CPF não regular na RFB",
  UnderAge: "P.F. menor de idade",
  InvalidCountryCode: "Código do país inválido",
  NotEmptyValidator: "Campo obrigatório não informado",
  NotNullValidator: "Campo obrigatório não informado",
  CannotHaveMoreThan100Onboardings:
    "Permitido cadastrar até 100 onboardings por arquivo",
  CannotHaveRepeatedOnboardings: "Há onboardings duplicados",
  BirthDateIsRequired: "Data de Nascimento obrigatória",
  InvalidMaritalStatus: "Estado Civil inválido",
  InvalidGender: "Sexo inválido",
  TradeAlreadySentCorrection:
    "Já existe uma correção ou aditamento em andamento para esse trade",
  InvalidCompany: "InvalidCompany",
  InvalidCorrectionOrAdditionTrade:
    "Arquivo importado diferente do tipo de correção selecionado",
  InvalidSettlementStrategy:
    "Estratégias de Liquidação inválidas, preencha todos os campos",
  InvalidSettlementIsPrimary:
    "Campo de Liquidação primária inválido, preencha com sim ou não",
  InvalidSettlementIsConsolidated:
    "Campo de Liquidação consolidado inválido, preencha com sim ou não",
  InvalidSettlementMethodCode:
    "Campo método de Liquidação inválido. Use 'INTN', 'EXTN', 'INVC', 'SWFT'",
  InvalidSettlementLockInfo:
    "Campo lock info liquidação inválido. Use 'DRCT', 'RFCD', 'LOCK'",
  InvalidSettlementParty:
    "Campo party liquidação inválido. Use 'Cedente' ou 'Sacado'",
  InvalidDateTimeFormat: "Contém um campo com uma data inválida",
  ValueMustBeGreaterThanZero: "O valor precisa ser maior que zero",
  DuplicatedTradeId: "TradeId está duplicado na planilha",
  SettlementAccountCantBeNull:
    "Deve ser preenchido os campos de conta da estratégia de liquidação",
  MissingJustificativeDeactivation:
    "Informe a justificativa de ativação/desativação antes de importar",
  "QF0015-CPF inválido": "CPF da Contraparte inválido",
  "QF0016-CNPJ inválido": "CNPJ da Contraparte inválido",
  "QF0022-Valor Inválido": "Valor do ativo inválido",
};

export const documentTypeMap = {
  PessoaFisica: {
    label: "CPF",
    value: "PessoaFisica",
    inputTag: "cpf" as const,
  },
  PessoaJuridica: {
    label: "CNPJ",
    value: "PessoaJuridica",
    inputTag: "cnpj" as const,
  },
  Offshore: {
    label: "Offshore",
    value: "Offshore",
    inputTag: "text" as const,
  },
};

// Values must be in ISO 3166 (alpha-2) format
export const countryCodeMap = {
  BR: {
    label: "Brasil",
    value: "BR",
    inputTag: "brasil" as const,
  },
  DE: {
    label: "Alemanha",
    value: "DE",
    inputTag: "text" as const,
  },
  AR: {
    label: "Argentina",
    value: "AR",
    inputTag: "text" as const,
  },
  CL: {
    label: "Chile",
    value: "CL",
    inputTag: "text" as const,
  },
  CN: {
    label: "China",
    value: "CN",
    inputTag: "text" as const,
  },
  CO: {
    label: "Colômbia",
    value: "CO",
    inputTag: "text" as const,
  },
  ES: {
    label: "Espanha",
    value: "ES",
    inputTag: "text" as const,
  },
  US: {
    label: "Estados Unidos",
    value: "US",
    inputTag: "text" as const,
  },
  FR: {
    label: "França",
    value: "FR",
    inputTag: "text" as const,
  },
  NL: {
    label: "Holanda",
    value: "NL",
    inputTag: "text" as const,
  },
  VG: {
    label: "Ilhas Virgens Britânicas",
    value: "VG",
    inputTag: "text" as const,
  },
  IN: {
    label: "Índia",
    value: "IN",
    inputTag: "text" as const,
  },
  IT: {
    label: "Itália",
    value: "IT",
    inputTag: "text" as const,
  },
  PY: {
    label: "Paraguai",
    value: "PY",
    inputTag: "text" as const,
  },
  PE: {
    label: "Peru",
    value: "PE",
    inputTag: "text" as const,
  },
  PT: {
    label: "Portugal",
    value: "PT",
    inputTag: "text" as const,
  },
  GB: {
    label: "Reino Unido",
    value: "GB",
    inputTag: "text" as const,
  },
  CH: {
    label: "Suíça",
    value: "CH",
    inputTag: "text" as const,
  },
  ZW: {
    label: "Zimbábue",
    value: "ZW",
    inputTag: "text" as const,
  },
};

export const documentTypeReverseMap = {
  CPF: "PessoaFisica",
  CNPJ: "PessoaJuridica",
  Offshore: "Offshore",
};

export const countryCodeReverseMap = {
  BR: "BR",
  DE: "DE",
  AR: "AR",
  CH: "CH",
  CL: "CL",
  CN: "CN",
  CO: "CO",
  ES: "ES",
  US: "US",
  FR: "FR",
  IN: "IN",
  IT: "IT",
  NL: "NL",
  PE: "PE",
  PT: "PT",
  PY: "PY",
  GB: "GB",
  VG: "VG",
  ZW: "ZW",
};

export const contractTypeMap = {
  PorOperacao: "Por operação",
  CessaoFutura: "Cessão futura",
  SemContrato: "Sem contrato",
};
