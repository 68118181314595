import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OCol,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  ORFieldRadioGroup,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services/service";
import * as yup from "yup";

interface FormValuesType {
  isRemoveUsersFromCompany: string;
  isInactivateUsersAccess: string;
  isReproveOnboarding: string;
  justification: string;
}

const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  isRemoveUsersFromCompany: yup.string().required("Este campo é obrigatório"),
  isInactivateUsersAccess: yup.string().required("Este campo é obrigatório"),
  isReproveOnboarding: yup.string().required("Este campo é obrigatório"),
  justification: yup.string().required("Este campo é obrigatório"),
});

interface TerminateOnboardingRelationshipProps {
  workflowId: string;
  relationship: string;
  modalId: string;
}

export const TerminateOnboardingRelationship = ({
  workflowId,
  modalId,
  relationship,
}: TerminateOnboardingRelationshipProps) => {
  const { callService, loading } = useServiceCall(
    service.onboarding.terminateRelationship,
  );

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const isRemoveUsers = values.isRemoveUsersFromCompany === "S";
        const isInactivate = values.isInactivateUsersAccess === "S";
        const isReprove = values.isReproveOnboarding === "S";

        if (!isRemoveUsers && !isInactivate && !isReprove) {
          OToastManager.info("Selecione pelo menos uma ação");
          return;
        }

        const { success } = await callService({
          workflowId,
          isRemoveUsersFromCompany: isRemoveUsers,
          isInactivateUsersAccess: isInactivate,
          isReproveOnboarding: isReprove,
          justification: values.justification,
        });

        if (!success)
          return OToastManager.danger("Erro ao aprovar conclusão de cadastro.");

        OToastManager.success("Conclusão de cadastro aprovada.");
        modalManager.hide(modalId);
      }),
    [callService, handleSubmit, modalId, workflowId],
  );

  return (
    <OModal id={modalId}>
      <OModalHeader closeButton>
        <PageTitle
          title="Encerrar relacionamento"
          description="Este procedimento não encerrará os relacionamentos e contas que o cliente possa ter no LESS"
        />
        <OTypography>{`${relationship} - Id: ${workflowId}`}</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-3">
              <OTypography tag="h3">Remover usuários da empresa?</OTypography>
              <ORFieldRadioGroup
                id="isRemoveUsersFromCompany"
                name="isRemoveUsersFromCompany"
              >
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isRemoveTrue"
                      name="isRemoveTrue"
                      value="S"
                      size="xs"
                    />
                    <OLabel htmlFor="isRemoveTrue" size="md">
                      Sim
                    </OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isRemoveFalse"
                      name="isRemoveFalse"
                      value="N"
                      size="xs"
                    />
                    <OLabel htmlFor="isRemoveFalse" size="md">
                      Não
                    </OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-3">
              <OTypography tag="h3">
                Bloquear acessos dos usuários à plataforma?
              </OTypography>
              <ORFieldRadioGroup
                id="isInactivateUsersAccess"
                name="isInactivateUsersAccess"
              >
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isInactivateTrue"
                      name="isInactivateTrue"
                      value="S"
                      size="xs"
                    />
                    <OLabel htmlFor="isInactivateTrue" size="md">
                      Sim
                    </OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isInactivateFalse"
                      name="isInactivateFalse"
                      value="N"
                      size="xs"
                    />
                    <OLabel htmlFor="isInactivateFalse" size="md">
                      Não
                    </OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-3">
              <OTypography tag="h3">Reprovar cadastro da empresa?</OTypography>
              <ORFieldRadioGroup
                id="isReproveOnboarding"
                name="isReproveOnboarding"
              >
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isReproveTrue"
                      name="isReproveTrue"
                      value="S"
                      size="xs"
                    />
                    <OLabel htmlFor="isReproveTrue" size="md">
                      Sim
                    </OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <ORadio
                      id="isReproveFalse"
                      name="isReproveFalse"
                      value="N"
                      size="xs"
                    />
                    <OLabel htmlFor="isReproveFalse" size="md">
                      Não
                    </OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <ORFieldInput
              tag="text"
              id="justification"
              name="justification"
              label="Motivo"
            />
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton outline onClick={() => modalManager.hide(modalId)}>
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={() => submit()}>
            Encerrar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
