import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { ODataGridGenerator } from "components/data-grid";
import { consultaOptinsGrid } from "../consulta-optins.grid";
import { useOptinContext } from "../consulta-optin.context";
import { useOptOut } from "../consulta-optins.hooks";

export const optOutModalId = "opt-out-modal";

export const OptOutModal = () => {
  const gridRef = useGridRef();

  const { cnpjs, optOutFilter } = useOptinContext();

  const { postOptOut, postOptOutHasError } = useOptOut();

  const grid = consultaOptinsGrid(cnpjs, optOutFilter);

  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={optOutModalId}
      title="Realizar Opt-out"
      confirmLabel="Confirmar Opt-out"
      closeLabel="Cancelar"
      onConfirm={() => {
        cnpjs.forEach((cnpj) => {
          postOptOut({ cnpj }).then(() => {
            if (!postOptOutHasError)
              OToastManager.success("O Opt-out foi realizado com sucesso");
            else {
              const errorMessage = "Erro ao realizar o Opt-out";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }
          });
        });
        modalManager.hide(optOutModalId);
      }}
    >
      <GridTemplate
        showClearFiltersButton
        showRefreshButton
        showExportButton
        gridRef={gridRef}
      >
        <ODataGridGenerator gridRef={gridRef} grid={grid} />
      </GridTemplate>
    </OConfirmationModal>
  );
};
