import * as yup from "yup";

export interface DeskAccountConfigRelationshipFormValues {
  lessRelationshipRole: string;
  hasEntityDefault: boolean;
  cgeMaster?: number | null;
}

export const deskAccountConfigRelationshipFormValidationSchema: yup.ObjectSchema<DeskAccountConfigRelationshipFormValues> =
  yup.object({
    lessRelationshipRole: yup.string().required("Este campo é obrigatório"),
    hasEntityDefault: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    cgeMaster: yup
      .number()
      .transform((value) => value || null)
      .optional()
      .nullable(),
  });
