import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BuildBalanceDetailsFields } from "./balance.details";
import { useAccountBalance } from "./balance.hook";
import { BankingClienteContasByAccountByAgencyRouteParams } from "../../../../../../routes/banking.route-params";
import { usePlanCard } from "../../../../../../../cadastro/pages/cliente/detalhes/_compose/cards/plan/plan-card.hook";

export const AccountBalance = () => {
  const { account, agency } =
    useParams<BankingClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  const { balance, loading, hasError, getBalance } = useAccountBalance();
  const { planInfo } = usePlanCard();

  useEffect(() => {
    getBalance(account, agency);
  }, [account, agency, getBalance]);

  return (
    <DetailsCard
      loading={loading}
      hasError={hasError}
      value={{ ...balance, plan: planInfo?.bundleName }}
      errorComponent={
        <ErrorComponent
          messageTitle="Erro ao buscar o saldo"
          messageParagraph="Clique no botão para tentar novamente."
        >
          <TryAgainButton onClick={() => getBalance(account, agency)} />
        </ErrorComponent>
      }
      fields={BuildBalanceDetailsFields}
    />
  );
};
