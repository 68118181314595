import { Chart } from "chart.js";

const lightModeColors = {
  text: "#000000",
  grid: "#00000055",
};

const darkModeColors = {
  text: "#ffffff",
  grid: "#ffffff55",
};

const defaultColors = {
  light: lightModeColors,
  dark: darkModeColors,
};

/** Set chart.js default colors based on theme */
export const applyDefaultColors = (theme: "light" | "dark") => {
  Chart.defaults.color = defaultColors[theme].text;
  Chart.defaults.borderColor = defaultColors[theme].grid;
};
