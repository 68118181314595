import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FranquiasProdutoByIdRouteParams } from "../../../../routes/franquias.route-params";
import { FranquiasForm } from "../_compose";
import { useUpdateFranchise } from "./update.hook";

export const UpdateFranchisePage = () => {
  const { id } = useParams<FranquiasProdutoByIdRouteParams>();
  if (!id) throw new Error("No id");

  const { form, submit, submitLoading } = useUpdateFranchise(id);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes da franquia" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FranquiasForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
