import {
  WorkflowContext
} from "pages/workflow/components/hooks/use-workflow.context";
import { useContext, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useBatchTemplatePropertyField = ({
  processorConfigProperty: {
    processorPropertyId,
    processorConfigId,
    name,
    value,
  },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef<boolean>(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    batchTemplates: { value: allBatchTemplates },
  } = useContext(WorkflowContext);

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    name,
    processorPropertyId,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [value, setValue, reset, processorPropertyId]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (!fieldWatch || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, processorPropertyId, processorConfigId);
  }, [
    fieldWatch,
    handleSubmitProperty,
    processorPropertyId,
    processorConfigId,
    value,
  ]);

  return { allBatchTemplates };
};
