import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { GetAdminCompanyFlagsResponseItem } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const grid = (
  onDelete: (calendar: GetAdminCompanyFlagsResponseItem) => void,
): ODataGridGeneratorConfig<GetAdminCompanyFlagsResponseItem> => (
  {
    datagrid: {
      noDataText: "Nenhuma restrição encontrada",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      { dataField: "id", caption: "Id" },
      { dataField: "companyName", caption: "Empresa" },
      {
        dataField: "companyCnpj",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      { dataField: "name", caption: "Opção" },
      { dataField: "tipoProduto", caption: "Tipo produto" },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-trash" },
                label: "Remover",
                onClick: () => onDelete(data),
              },
            ]}
          />
        ),
      },
    ],
  }
);
