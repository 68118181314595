import { IType, OBadge } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { SortOrder } from "devextreme/common";
import {
  Bankslip,
  SharkAmortizationType,
  SharkSettlementData,
} from "services/shark/models/responses";
import { format } from "utils/date";
import { AnticipationCheckbox } from "./_compose/anticipation-checkbox.component";
import { AnticipationHeaderCheckbox } from "./_compose/anticipation-header-checkbox.component";
import { OperationsQuotesGridActions } from "./operations-installments-action.component";
import { SharkInstallmentItem } from "./operations-installments.types";

const renderSharkStatus = (installment: SharkInstallmentItem) => {
  const badgeProps = { type: "", label: "" };

  if (installment.has_processing_payments) {
    badgeProps.type = "info" as const;
    badgeProps.label = "Em Processamento";
  } else if (
    dayjs(installment.maturity_business_date).isBefore(dayjs(), "days")
  ) {
    badgeProps.type = "danger" as const;
    badgeProps.label = "Vencida";
  } else {
    badgeProps.type = "info" as const;
    badgeProps.label = "Em Aberto";
  }

  return badgeProps;
};

const sharkAmortizationFrequencyMap: Record<SharkAmortizationType, string> = {
  BNTH: "Bimestral",
  MIAN: "Semestral",
  MNTH: "Mensal",
  MTRY: "Bullet",
  QUDR: "Quadrimestral",
  QURT: "Trimestral",
  YEAR: "Anual",
  NONE: "-",
  ANY: "-",
};

interface OperationsInstallmentsGridProps {
  tradeId?: string;
  email?: string | null;
  availableBankSlips: Bankslip[];
  showActionColumn: boolean;
  canAnticipate: boolean;
  showInstallmentsType: boolean;
  sortOrder?: SortOrder;
  sharkSettlement?: SharkSettlementData | undefined;
}

export const OperationsInstallmentsGrid = ({
  tradeId,
  email,
  availableBankSlips,
  showActionColumn,
  canAnticipate,
  showInstallmentsType,
  sortOrder,
  sharkSettlement,
}: OperationsInstallmentsGridProps): ODataGridGeneratorConfig<SharkInstallmentItem> => {
  return {
    datagrid: {
      noDataText: "Nenhuma parcela encontrada.",
      pager: {
        showInfo: true,
        infoText: "Página {0} de {1} ({2} parcelas)",
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        alignment: "center",
        width: "70px",
        headerCellRender: () => <AnticipationHeaderCheckbox />,
        visible: canAnticipate,
        cellRender: ({ data }) => (
          <div className="d-flex justify-content-center">
            <AnticipationCheckbox installment={data} />
          </div>
        ),
      },
      {
        dataField: "installment_number",
        caption: "Parcela",
        alignment: "center",
        cellRender: ({ data }) =>
          sharkSettlement?.installments_count
            ? `${data.installment_number}/${
                sharkSettlement?.installments_count_by_frequency[
                  data.amortization_frequency
                ] || sharkSettlement?.installments_count
              }`
            : data.installment_number,
      },
      {
        dataField: "amortization_frequency",
        caption: "Tipo",
        visible: showInstallmentsType,
        allowSorting: true,
        alignment: "center",
        cellRender: ({ data }) =>
          sharkAmortizationFrequencyMap[data.amortization_frequency],
      },
      {
        dataField: "maturity_date",
        sortOrder,
        caption: "Data de vencimento",
        alignment: "center",
        cellRender: ({ data }) => format.date(data.maturity_business_date),
      },
      {
        dataField: "amount_at_maturity",
        caption: "Valor no vencimento",
        alignment: "left",
        cellRender: ({ data }) =>
          masks.currency(data.amount_at_maturity, "R$", "."),
      },
      {
        dataField: "curve_value",
        caption: "Valor atual",
        alignment: "left",
        cellRender: ({ data }) => masks.currency(data.curve_value, "R$", "."),
      },
      {
        dataField: "status_description",
        caption: "Status",
        alignment: "left",
        cellRender: ({ data }) => {
          const { label, type } = renderSharkStatus(data);
          return (
            <OBadge type={type as IType} size="sm">
              {label}
            </OBadge>
          );
        },
      },
      {
        caption: "Ações",
        visible: showActionColumn,
        cellRender: ({ data }) => {
          const hasBankslip = availableBankSlips?.some(
            (bs) =>
              bs.installment === data.installment_number &&
              bs.installment_frequency === data.amortization_frequency,
          );

          return (
            <OperationsQuotesGridActions
              tradeId={tradeId ?? ""}
              installment={data.installment_number ?? 0}
              installmentFrequency={data.amortization_frequency || "NONE"}
              hasBankslip={hasBankslip ?? false}
              email={email}
              bankslips={availableBankSlips}
            />
          );
        },
        alignment: "center",
      },
    ],
  } satisfies ODataGridGeneratorConfig<SharkInstallmentItem>;
};
