import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./relationship.grid";

export const Relationship = () => {
  const { customer } = useCustomer();

  return (
    <GridTemplate pageTitle={<PageTitle title="Relacionamento" />}>
      <ODataGridGenerator grid={grid} dataSource={customer.contacts} />
    </GridTemplate>
  );
};
