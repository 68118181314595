import {
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OToastManager,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { service } from "services";
import { GatekeeperProfileDetailsAddFormProps } from "./details-add-form.types";

export const GatekeeperProfileDetailsAddForm = ({
  form,
}: GatekeeperProfileDetailsAddFormProps) => {
  const [groups, setGroup] =
    useState<
      Awaited<
        ReturnType<typeof service.gatekeeper.listGroupsProfileDetails>
      >["data"]
    >();

  const getGroup = useCallback(async () => {
    try {
      const { data } = await service.gatekeeper.listGroupsProfileDetails();

      setGroup(data);
    } catch {
      OToastManager.danger("Erro ao buscar grupos");
    }
  }, []);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  return (
    <FormProvider {...form}>
      <form>
        <ORow>
          <OCol xs={4} md={4} xl={4}>
            <ORFieldSelect
              id="groupId"
              name="groupId"
              dataAction="adicionar_detalhe:input:nomeGrupo"
              dataLabel="Grupo"
              placeholder="Grupo"
              label="Grupo"
              key={groups?.length}
            >
              {groups?.map((group) => (
                <OOption key={group.id} value={group.id}>
                  {group.name}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol xs={8} md={8} xl={8}>
            <ORFieldInput
              tag="text"
              id="description"
              name="description"
              dataAction="adicionar_detalhe:input:descricao"
              dataLabel="descricao"
              label="Descrição"
            />
          </OCol>
        </ORow>

        <ORow>
          <OCol xs={12} md={6} xl={4}>
            <ORFieldRadioGroup
              dataAction="adicionar_detalhe:radio:e-um-detalhe-disponivel"
              dataLabel="e-um-detalhe-disponivel"
              id="isAvailable"
              name="isAvailable"
              label="É um detalhe disponível?"
            >
              <div className="d-flex gap-5">
                <div className="d-flex gap-2">
                  <ORadio id="isAvailableTrue" value="true" />
                  <OLabel htmlFor="isAvailableTrue">Sim</OLabel>
                </div>
                <div className="d-flex gap-2">
                  <ORadio id="isAvailableFalse" value="false" />
                  <OLabel htmlFor="isAvailableFalse">Não</OLabel>
                </div>
              </div>
            </ORFieldRadioGroup>
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
