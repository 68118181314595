import { SelectSearchFieldV2 } from "../../../../../../../../components/select-search-v2";
import { StepConfigForm } from "../../step-config-form.types";

interface StepTypeSelectorProps {
  name: keyof StepConfigForm;
  label: string;
  multiple: boolean;
  stepTypes: HubEnergy.DiligenceStepTypeResponse[];
}

export const StepTypeSelector = ({
  name,
  label,
  multiple,
  stepTypes,
}: StepTypeSelectorProps) => {
  return (
    <SelectSearchFieldV2
      options={stepTypes.map(({ name: typeName, id }) => ({
        content: typeName,
        value: id,
      }))}
      id={name}
      name={name}
      label={label}
      multiple={multiple}
      placeholder="Selecionar"
      // dropdownPosition={dropdownPosition}
    />
  );
};
