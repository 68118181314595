import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { WorkflowProdutoScoreModuleById } from "../../../../../../routes/workflow.route-params";
import { ScoreModuleForm } from "../../_compose/score-module-form.component";
import {
  scoreModuleFormDefaultValues,
  scoreModuleFormValidationSchema,
} from "../../_compose/score-module-form.schema";

export const EditScoreModulePage = () => {
  const { scoreModuleId } = useParams<WorkflowProdutoScoreModuleById>();

  if (!scoreModuleId) throw new Error("No id");

  const form = useForm({
    resolver: yupResolver(scoreModuleFormValidationSchema),
    defaultValues: scoreModuleFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Editar módulo" />}>
      <FormProvider {...form}>
        <ScoreModuleForm mode="edit" id={scoreModuleId} />
      </FormProvider>
    </FormTemplate>
  );
};
