import { StepBoard } from "./step-board.component";

interface StepsBoardProps {
  summary: HubEnergy.DiligenceStepAnalysisSummaryResponse;
}

export const StepsBoard = ({ summary }: StepsBoardProps) => {
  return (
    <div style={{ overflowX: "auto" }} className="d-flex gap-4 pb-4">
      {summary.steps.map((s) => (
        <StepBoard key={s.stepType.name} step={s} />
      ))}
    </div>
  );
};
