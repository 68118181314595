import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { AddRelationship } from "./add-relationships.component";
import { grid } from "./company-group-associations.grid";
import { CompanyGroupAssociation } from "../../../../../services/onboarding/models/responses/post-admin-company-group-get-companies-from-group.response"
import {
  OButton,
  modalManager,
} from "@maestro/react";

export const CompanyGroupAssociationsPage = () => {
  const gridRef = useGridRef();
  const {
    customer: { identification },
  } = useCustomer();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<CompanyGroupAssociation>(() =>
        service.onboarding
          .getCompaniesFromGroup({
            taxId: identification,
            groupType: "SELF",
          })
          .then(({ data }) => data.companyGroupAssociations ?? [])
          .catch((err) => {
            if ((err.response.data.failures[0].errorCode == "CnpjNotMatriz")) return [];
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar relacionamentos";
            if (!(err.response.data.failures[0].errorCode == "CnpjNotMatriz")) OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [identification],
  );

  return (<>
    <GridTemplate
      pageTitle={<PageTitle title="Solicitação de Relacionamento" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      actions={
        <OButton
          dataAction="grid:botao:recarregar_grid"
          dataLabel="tentar_novamente"
          onClick={() => modalManager.show("group_association_modal")}
        >
          Adicionar relacionamentos
        </OButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
    <AddRelationship />
  </>
  );
};
