const loadPageCursors = (
  data: [string, string],
  target = "",
  origin = "",
): [string, string] => {
  return data.indexOf(target) ? [origin, ""] : ["", origin];
};

export const DataGridUtils = {
  loadPageCursors,
};
