import {
  OConfirmationModal,
  OLoader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ContractTemplate } from "../../company-contract-templates.types";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  template: ContractTemplate;
  reload: () => void;
}

export const GridActions = ({ template, reload }: GridActionsProps) => {
  const {
    deleteCompanyContractTemplate,
    loading,
    setDefaultCompanyContractTemplate,
    onDownloadFile,
  } = useGridActions(template.id);

  const modalId = `remove-template-${template.id}-confirmation-modal`;

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-edit" },
              label: "Editar",
              role: roles.recebiveis.customer.contractTemplatesByTemplateIdPage
                .role,
              route:
                corporateRouter.routes.recebiveis.customer.contractTemplates.byTemplateId.path(
                  {
                    templateId: template.id,
                  },
                ),
            },
            {
              icon: { category: "far", icon: "fa-download" },
              label: "Download",
              onClick: () =>
                onDownloadFile({ name: template.file.originalName }),
            },
            {
              icon: { category: "far", icon: "fa-bookmark" },
              label: "Marcar como padrão",
              onClick: async () => {
                await setDefaultCompanyContractTemplate();
                reload();
              },
              visible: !template.isDefault,
            },
            {
              icon: { category: "far", icon: "fa-trash" },
              label: "Excluir template",
              onClick: () => modalManager.show(modalId),
            },
          ]}
        />
      )}
      <OConfirmationModal
        modalId={modalId}
        title="Excluir template?"
        closeLabel="Cancelar"
        confirmLabel="Sim"
        onConfirm={async () => {
          modalManager.hide(modalId);
          await deleteCompanyContractTemplate();
          reload();
        }}
      >
        <OTypography>
          Certeza que deseja excluir o template &ldquo;{template.name}
          &rdquo;?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
