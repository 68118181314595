import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { currencySymbol } from "utils/currency";
import { Account, MapperAccountStatus } from "./accounts-admin.type";

export const grid: ODataGridGeneratorConfig<Account> = {
  datagrid: {
    noDataText: "Nenhuma conta encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "number", caption: "Conta", allowEditing: false },
    { dataField: "cge", caption: "Cge", allowEditing: false },
    {
      dataField: "service",
      caption: "Serviço",
      allowEditing: false,
      customizeText: ({ value }) => value ?? "Outras",
    },
    {
      allowHeaderFiltering: false,
      allowEditing: false,
      dataField: "balance",
      caption: "Saldo",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      allowHeaderFiltering: false,
      allowEditing: false,
      dataField: "blockedBalance",
      caption: "Saldo bloqueado",
      format: (value) => masks.currency(value || 0, currencySymbol.BRL),
    },
    {
      dataField: "status",
      allowEditing: false,
      caption: "Status",
      customizeText: ({ value }) => {
        return MapperAccountStatus[value];
      },
    },
    { dataField: "agency", caption: "Agência", allowEditing: false },
    { dataField: "subscriptionCode", caption: "Tipo", allowEditing: false },
    { dataField: "segment", caption: "Segmento", allowEditing: false },
    { dataField: "coSegment", caption: "Cosegmento", allowEditing: false },
    { dataField: "businessAccount", caption: "Conta Business" },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              label: "Histórico de Permissões",
              icon: { category: "orq", icon: "orq-scheduled" },
              role: roles.cadastro.customer
                .btgAccountsAccountAdminPermissionHistoryPage.role,
              route:
                corporateRouter.routes.cadastro.customer.btgAccounts.accountAdmin.permissionHistory.path(
                  {
                    agency: data.agency,
                    account: data.number,
                  },
                ),
            },
            {
              label: "Extrato",
              icon: { category: "orq", icon: "orq-download" },
              role: roles.cadastro.customer.btgAccountsAccountAdminStatementPage
                .role,
              route:
                corporateRouter.routes.cadastro.customer.btgAccounts.accountAdmin.statement.path(
                  {
                    agency: data.agency,
                    account: data.number,
                  },
                ),
            },
            {
              label: "Informe de rendimentos",
              icon: { category: "orq", icon: "orq-file" },
              role: roles.banking.customer
                .btgAccountsAccountAdminCorporateTaxPage.role,
              route:
                corporateRouter.routes.banking.customer.accounts.account.agency.corporateTax.path(
                  {
                    agency: data.agency,
                    account: data.number,
                  },
                ),
            },
            {
              label: "Editar",
              icon: { category: "orq", icon: "orq-edit-pencil" },
              onClick: () => {
                component.editRow(rowIndex);
              },
            },
            {
              label: "Usuários restritos",
              icon: { category: "orq", icon: "orq-block" },
              role: roles.cadastro.customer
                .btgAccountsAccountAdminUsersBlockedPage.role,
              route:
                corporateRouter.routes.cadastro.customer.btgAccounts.accountAdmin.usersBlocked.path(
                  {
                    id: data.id,
                  },
                ),
            },
          ]}
        />
      ),
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
};
