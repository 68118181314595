import { OUploadCustomEvent } from "@maestro/core";
import {
  OButton,
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OField,
  OOption,
  ORFieldSelect,
  ORFieldUploadInput,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { importFidcGrid } from "./import-fidc.grid";
import {
  formToRenderMap,
  getFidcSheetModel,
  importProductSelectMap,
} from "./import-fidc.utils";
import { useImportFidc } from "./import-fidc.hook";

export const ImportFidc = () => {
  const {
    dataSource,
    form,
    onFileUpload,
    onSubmit,
    products,
    resetAll,
    watchSelectedProduct,
    setVehicleList,
    vehicleList,
  } = useImportFidc();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Importar títulos" />}
      actions={
        <OButton
          dataAction="importacao_fidc:botao:baixar_modelo_importacao"
          dataLabel="baixar_modelo_importacao"
          disabled={!watchSelectedProduct}
          onClick={() =>
            getFidcSheetModel(
              products?.find(
                (_product) => _product.name === watchSelectedProduct,
              ).assetSchema,
            )
          }
        >
          Baixar modelo de importação
        </OButton>
      }
    >
      <FormProvider {...form}>
        <OCard>
          <OCardHeader divider>
            <OField
              htmlFor="product-selection"
              label="Selecione o tipo de importação"
            >
              {products && (
                <ORFieldSelect
                  name="selectedProduct"
                  id="selectedProduct"
                  dataAction="importacao_fidc:select:produto_importacao"
                  dataLabel="produto_importacao"
                  placeholder="Selecione"
                >
                  {products?.map((product) => (
                    <OOption key={product.id} value={product.name ?? ""}>
                      {importProductSelectMap[product?.name]}
                    </OOption>
                  ))}
                </ORFieldSelect>
              )}
            </OField>
          </OCardHeader>
          <OCardBody>
            <FormTemplate>
              {watchSelectedProduct &&
                formToRenderMap[watchSelectedProduct]?.formFields}
            </FormTemplate>
          </OCardBody>
          <OCardFooter>
            <FormTemplate
              actions={
                <>
                  {vehicleList.length > 0 && (
                    <OButton
                      dataAction="importacao_fidc:botao:limpar_importacoes"
                      dataLabel="limpar_importacoes"
                      onClick={() => setVehicleList([])}
                    >
                      Limpar importação
                    </OButton>
                  )}
                  <OButton
                    dataAction="importacao_fidc:botao:limpar_tudo"
                    dataLabel="limpar_tudo"
                    onClick={() => resetAll()}
                  >
                    Limpar tudo
                  </OButton>
                  <OButton
                    dataAction="importacao_fidc:botao:validar_titulos"
                    dataLabel="validar_titulos"
                    onClick={() => onSubmit()}
                  >
                    Validar títulos
                  </OButton>
                </>
              }
            >
              {vehicleList.length === 0 ? (
                <ORFieldUploadInput
                  id="importsFile"
                  name="importsFile"
                  dataAction="importacao_fidc:upload:upload_veiculos"
                  dataLabel="upload_veiculos"
                  inputLabel="Selecione ou arraste o excel com os dados dos veículos"
                  accept=".xls, .xlsx, .csv"
                  handleAddFile={(e: OUploadCustomEvent<File>) =>
                    onFileUpload(e.detail)
                  }
                />
              ) : (
                <ODataGridGenerator
                  grid={importFidcGrid}
                  dataSource={dataSource}
                />
              )}
            </FormTemplate>
          </OCardFooter>
        </OCard>
      </FormProvider>
    </DetailsTemplate>
  );
};
