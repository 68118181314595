import { modalManager } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { Validations } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";

interface UserAgentModalProps {
  setJsonResponse: React.Dispatch<React.SetStateAction<string>>;
  setJsonUuid: React.Dispatch<React.SetStateAction<string>>;
}

export const grid = ({ setJsonResponse, setJsonUuid }: UserAgentModalProps) => {
  return {
    datagrid: {
      noDataText: "Nenhum Notificação",
      pager: {
        infoText: "Página {0} de {1} ({2} notificação)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        dataField: "status",
        caption: "Sucesso na Validação",
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-unified-invoice" },
                  label: "Exibir Resposta",
                  onClick: async () => {
                    setJsonUuid(data.uuid);
                    setJsonResponse(data.jsonResponse);
                    setTimeout(() => modalManager.show(data.uuid), 0);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  } as ODataGridGeneratorConfig<Validations>;
};
