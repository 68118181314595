import {
  OButton,
  OCol,
  OField,
  OIcon,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { Control, useFieldArray } from "react-hook-form";
import { RoadwalkerSearch } from "../roadwalker-search-modal.types";

interface RoadWalkerExtraFieldsProps {
  control: Control<RoadwalkerSearch>;
}

export const RoadWalkerExtraFields = ({
  control,
}: RoadWalkerExtraFieldsProps) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: "filters",
  });

  return (
    <OField label="Inclua filtros na busca de mensagens" htmlFor="extra-fields">
      <div className="d-flex flex-column" id="extra-fields">
        {fields.map((field, index) => (
          <ORow key={field.id} className="d-flex flex-row align-items-center">
            <OCol xs={5}>
              <ORFieldInput
                tag="text"
                id={`filters.${index}.filter`}
                name={`filters.${index}.filter`}
                dataAction="roadwalker_monitor:input:filtro"
                dataLabel="filtro"
                label="Filtro"
              />
            </OCol>
            <OCol xs={5}>
              <ORFieldInput
                tag="text"
                id={`filters.${index}.value`}
                name={`filters.${index}.value`}
                dataAction="roadwalker_monitor:input:valor"
                dataLabel="valor"
                label="Valor"
              />
            </OCol>
            <OCol xs={2}>
              <OButton
                dataAction="roadwalker_monitor:botao:remover"
                dataLabel="remover"
                onClick={() => remove(index)}
              >
                <OIcon category="fa" icon="fa-trash" />
              </OButton>
            </OCol>
          </ORow>
        ))}
        <div className="d-flex flex-row justify-content-end">
          <OButton
            dataAction="roadwalker_monitor:botao:adicionar"
            dataLabel="adicionar"
            onClick={() => append({} as RoadwalkerSearch["filters"][number])}
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      </div>
    </OField>
  );
};
