import { OTypography, OUploadLabel } from "@maestro/react";
import { NodeProps } from "reactflow";

export type NewTreeNodeData = {
  handleNewTree: () => void;
};

export const NewTreeNode = ({ data, id }: NodeProps<NewTreeNodeData>) => {
  return (
    <OUploadLabel
      htmlFor={`${id}`}
      size="xl"
      disabled
      onClick={(e) => {
        e.preventDefault();
        data.handleNewTree();
      }}
      style={{ cursor: "pointer" }}
    >
      <OTypography weight="bold" size="sm" key="new-tree">
        Nova árvore
      </OTypography>
    </OUploadLabel>
  );
};
