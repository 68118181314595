import { read as xlsxRead, utils as xlsxUtils } from "xlsx";

export const readBufferAsSpreadsheet = <T = Record<string, unknown>>(
  buffer: string | ArrayBuffer | null,
) => {
  const workbook = xlsxRead(buffer, { type: "binary", bookVBA: true });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const firstSheetJson = xlsxUtils.sheet_to_json<T>(worksheet);

  return {
    workbook,
    firstSheetJson,
  };
};
