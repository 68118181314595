export const ORFieldInputs = [
  {
    label: "Valor alocado",
    name: "accAllocatedAmount",
    id: "accAllocatedAmount",
    mask: true,
  },
  {
    label: "Valor liquidado",
    name: "accSettledAmount",
    id: "accSettledAmount",
    mask: true,
  },
  {
    label: "Valor em trânsito",
    name: "inTransitAmount",
    id: "inTransitAmount",
    mask: true,
  },
  {
    label: "Pagador",
    name: "payer",
    id: "payer",
  },
];
