import { OCol, ORFieldInput } from "@maestro/react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";

export const IdInput = () => {
  const { watch } = useFormContext<UpsertCollectionAgreementFormFields>();
  const { pathname } = useLocation();

  const isDefaultAgreementWatched = watch("isDefaultAgreement");

  const disabled = useMemo(
    () => isDefaultAgreementWatched || pathname.includes("editar"),
    [pathname, isDefaultAgreementWatched],
  );

  return (
    <OCol>
      <ORFieldInput
        id="id"
        name="id"
        tag="text"
        label="Id do convênio (opcional)"
        placeholder="Insira o Id"
        maxlength={13}
        disabled={disabled}
      />
    </OCol>
  );
};
