import {
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldInputDateRange,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { SelectSacados, SelectCedentes } from "./_compose";
import { periodOptions } from "./reports-config.utils";

export const ReportsConfig = () => {
  const form = useFormContext();
  const { watch } = form;
  const watchReportPeriod = watch("reportPeriod");

  const customPeriodLabel = "Selecione o período (data da operação)";

  return (
    <>
      <OTypography type="dark" className="mb-4">
        Selecione as configurações para gerar o relatório
      </OTypography>

      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldInput
            tag="text"
            id="reportName"
            name="reportName"
            dataAction="relatorios_em_lote:texto:nome_relatorio"
            dataLabel="nome_relatorio"
            label="Crie um nome para o relatório"
            placeholder="Insira um nome para facilitar o controle"
            maxlength={150}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldSelect
            dataAction="relatorios_em_lote:select:periodo_de_relatorio"
            dataLabel="periodo_de_relatorio"
            id="reportPeriod"
            name="reportPeriod"
            placeholder="Selecione o período"
            label={customPeriodLabel}
          >
            {periodOptions.map((option) => (
              <OOption key={option.value} value={option.value}>
                {option.label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>

      {watchReportPeriod === "CustomPeriod" && (
        <ORow>
          <OCol xs={12} md={6}>
            <ORFieldInputDateRange
              id="periodDate"
              name="periodDate"
              aspect="flushed"
              placeholder={["Data de inicio", "Data de fim"]}
            />
          </OCol>
        </ORow>
      )}

      <ORow>
        <OCol xs={12} md={6}>
          <SelectSacados />
        </OCol>
        <OCol xs={12} md={6}>
          <SelectCedentes />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldRadioGroup
            id="isCnpjRaiz"
            name="isCnpjRaiz"
            dataLabel="tipo_de_relatorio"
            dataAction="relatorios_em_lote:select:tipo_de_relatorio"
            label="É CNPJ raiz? "
          >
            <OCol xs={12} className="d-flex gap-3">
              <div className="d-flex aligm-items-center gap-2">
                <OLabel htmlFor="isCnpjRaiz">Sim</OLabel>
                <ORadio id="isCnpjRaiz" value="true" />
                <OLabel htmlFor="isCnpjRaiz">Não</OLabel>
                <ORadio id="isCnpjRaiz" value="false" />
              </div>
            </OCol>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
    </>
  );
};
