import { ConfigurationFormField } from "../domain-configuration-scf.form";
import { scfDevelopersOnboardingStatusMap } from "../domain-configuration-scf.utils";

export const generateUuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const buildConfigurationsIntoForm = (
  configurations: ScfDevelopers.Configuration[],
) => {
  return configurations.map((config) => {
    if (config.stepType === "GENERAL") {
      return {
        stepId: config.stepId,
        stepType: "GENERAL" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        isFicd: config.isFidc ? ["true"] : [],
      };
    }
    if (config.stepType === "VALIDATION") {
      return {
        stepId: config.stepId,
        stepType: "VALIDATION" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        shouldValidateAddress: config.shouldValidateAddress ? ["true"] : [],
        requiredDocuments: config.requiredDocuments ?? [],
      };
    }
    if (config.stepType === "COMPLEMENT") {
      return {
        stepId: config.stepId,
        stepType: "COMPLEMENT" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "RISKANALYSIS") {
      return {
        stepId: config.stepId,
        stepType: "RISKANALYSIS" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        segment: config.segment,
        coSegment: config.coSegment,
        riskAnalysisType: config.riskAnalysisType,
      };
    }
    if (config.stepType === "LESS") {
      return {
        stepId: config.stepId,
        stepType: "LESS" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType,
        mainOfficerName: config.mainOfficerName,
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship
            ? ["true"]
            : [],
      };
    }
    if (config.stepType === "WEBHOOK") {
      return {
        stepId: config.stepId,
        stepType: "WEBHOOK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        allowedWebhookStatusTypes:
          config.allowedWebhookStatusTypes?.length === 0
            ? Object.keys(scfDevelopersOnboardingStatusMap)
            : config.allowedWebhookStatusTypes,
      };
    }
    if (config.stepType === "WEBHOOK_INTERNAL") {
      return {
        stepId: config.stepId,
        stepType: "WEBHOOK_INTERNAL" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        allowedWebhookStatusTypes:
          config.allowedWebhookStatusTypes?.length === 0
            ? Object.keys(scfDevelopersOnboardingStatusMap)
            : config.allowedWebhookStatusTypes,
      };
    }
    if (config.stepType === "DEVELOPERS_WEBHOOK") {
      return {
        stepId: config.stepId,
        stepType: "DEVELOPERS_WEBHOOK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        additionalPartnerAuthClientIds:
          config.additionalPartnerAuthClientIds ?? [],
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "CLERK") {
      return {
        stepId: config.stepId,
        stepType: "CLERK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "QUICKFINSYNC") {
      return {
        stepId: config.stepId,
        stepType: "QUICKFINSYNC" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "VALIDATE_CREATE_USER_RELATIONSHIP") {
      return {
        stepId: config.stepId,
        stepType: "VALIDATE_CREATE_USER_RELATIONSHIP" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "CREATE_USER_RELATIONSHIP") {
      return {
        stepId: config.stepId,
        stepType: "CREATE_USER_RELATIONSHIP" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        shouldSendEmail: config.shouldSendEmail ? ["true"] : [],
      };
    }
    return {};
  });
};

export const buildConfigurationsFromForm = (
  configurations: ConfigurationFormField[],
) => {
  const formattedConfigurations = configurations.map((config) => {
    if (config.stepType === "GENERAL") {
      return {
        stepId: config.stepId,
        stepType: "GENERAL" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        isFicd: config.isFidc.includes("true"),
      };
    }
    if (config.stepType === "VALIDATION") {
      return {
        stepId: config.stepId,
        stepType: "VALIDATION" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        shouldValidateAddress: config.shouldValidateAddress.includes("true"),
        requiredDocuments: config.requiredDocuments ?? [],
      };
    }
    if (config.stepType === "COMPLEMENT") {
      return {
        stepId: config.stepId,
        stepType: "COMPLEMENT" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "RISKANALYSIS") {
      return {
        stepId: config.stepId,
        stepType: "RISKANALYSIS" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        segment: config.segment,
        coSegment: config.coSegment,
        riskAnalysisType: config.riskAnalysisType,
      };
    }
    if (config.stepType === "LESS") {
      return {
        stepId: config.stepId,
        stepType: "LESS" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType ?? "",
        mainOfficerName: config.mainOfficerName ?? "",
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship.includes(
            "true",
          ),
      };
    }
    if (config.stepType === "WEBHOOK") {
      return {
        stepId: config.stepId,
        stepType: "WEBHOOK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        allowedWebhookStatusTypes:
          config.allowedWebhookStatusTypes.length ===
          Object.keys(scfDevelopersOnboardingStatusMap).length
            ? []
            : config.allowedWebhookStatusTypes ?? [],
      };
    }
    if (config.stepType === "WEBHOOK_INTERNAL") {
      return {
        stepId: config.stepId,
        stepType: "WEBHOOK_INTERNAL" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        allowedWebhookStatusTypes:
          config.allowedWebhookStatusTypes.length ===
          Object.keys(scfDevelopersOnboardingStatusMap).length
            ? []
            : config.allowedWebhookStatusTypes ?? [],
      };
    }
    if (config.stepType === "DEVELOPERS_WEBHOOK") {
      return {
        stepId: config.stepId,
        stepType: "DEVELOPERS_WEBHOOK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        additionalPartnerAuthClientIds:
          config.additionalPartnerAuthClientIds ?? [],
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "CLERK") {
      return {
        stepId: config.stepId,
        stepType: "CLERK" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "QUICKFINSYNC") {
      return {
        stepId: config.stepId,
        stepType: "QUICKFINSYNC" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "VALIDATE_CREATE_USER_RELATIONSHIP") {
      return {
        stepId: config.stepId,
        stepType: "VALIDATE_CREATE_USER_RELATIONSHIP" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "CREATE_USER_RELATIONSHIP") {
      return {
        stepId: config.stepId,
        stepType: "CREATE_USER_RELATIONSHIP" as ScfDevelopers.StepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        shouldSendEmail: config.shouldSendEmail.includes("true"),
      };
    }
    return {};
  });

  const getNextStepId = (index: number, configs: any[]) => {
    if (index === configs.length) {
      return null;
    }

    return configs[index].stepId;
  };

  return formattedConfigurations.map((config, index, configs) => {
    return {
      ...config,
      nextStepId: getNextStepId(index + 1, configs),
    };
  });
};
