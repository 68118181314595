import {
  OCol,
  OField,
  OLabel,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldRadioGroup,
  ORadio,
  ORow,
} from "@maestro/react";
import { ORFieldInputDdiPhone } from "components/or-input-ddi-phone";
import { useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  statusAccountMap,
  statusAccountTooltipMap,
  statusBiometryMap,
  statusBiometryTooltipMap,
} from "../../../../../cliente";
import { useTimePenalty } from "../time-penalty";
import { updateUserFields } from "./update-user-form.form";

export const UpdateUserForm = () => {
  const form = useFormContext();
  const { getValues } = form;
  const { timePenalty } = useTimePenalty();

  return (
    <ORow>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateUserFields.name} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <OField htmlFor="birthDate" label="Data de nascimento" className="mt-2">
          <ORFieldInputDate {...updateUserFields.birthDate} />
        </OField>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDdiPhone {...updateUserFields.phone} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldRadioGroup {...updateUserFields.isActive}>
          <div className="d-flex gap-4">
            <div className="d-flex gap-2">
              <ORadio id="yes" value="yes" disabled />
              <OLabel htmlFor="yes">Sim</OLabel>
            </div>
            <div className="d-flex gap-2">
              <ORadio id="no" value="no" disabled />
              <OLabel htmlFor="no">Não</OLabel>
            </div>
          </div>
        </ORFieldRadioGroup>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateUserFields.cpf} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateUserFields.email} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          {...updateUserFields.accountStatus}
          value={
            getValueFromMap(statusAccountMap, getValues("accountStatus"))?.label
          }
          key={statusAccountTooltipMap[getValues("accountStatus")]}
          tooltip={statusAccountTooltipMap[getValues("accountStatus")]}
          tooltipMaxWidth="400px"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          {...updateUserFields.biometryStatus}
          value={
            getValueFromMap(statusBiometryMap, getValues("biometryStatus"))
              ?.label
          }
          key={statusBiometryTooltipMap[getValues("biometryStatus")]}
          tooltip={statusBiometryTooltipMap[getValues("biometryStatus")]}
          tooltipMaxWidth="400px"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldRadioGroup
          {...updateUserFields.timePenalty}
          value={timePenalty ? "yes" : "no"}
          tooltip="Cliente excedeu o número de tentativas"
          tooltipMaxWidth="400px"
        >
          <div className="d-flex gap-4">
            <div className="d-flex gap-2">
              <ORadio id="yes" value="yes" disabled />
              <OLabel htmlFor="yes">Sim</OLabel>
            </div>
            <div className="d-flex gap-2">
              <ORadio id="no" value="no" disabled />
              <OLabel htmlFor="no">Não</OLabel>
            </div>
          </div>
        </ORFieldRadioGroup>
      </OCol>
    </ORow>
  );
};
