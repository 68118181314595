import { DetailsCard } from "components/details-card";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ReceivableDetailsGenerator } from "./receivable.details";
import { useReceivableDetails } from "./receivable.hook";

export const ReceivableDetails = () => {
  const { detail, form, loading, submit } = useReceivableDetails();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Recebível" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="recebivel:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DetailsCard fields={ReceivableDetailsGenerator(detail?.receivable)} />
      </FormProvider>
    </FormTemplate>
  );
};
