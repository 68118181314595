import { StandardLink } from "./standard-link.component";

interface DetailsLinkProps {
  href: string;
}

export const DetailsLink = ({ href }: DetailsLinkProps) => {
  return (
    <span style={{ width: "88px", display: "inline-block" }}>
      <StandardLink href={href}>Detalhes</StandardLink>
    </span>
  );
};
