import * as yup from "yup";

export interface ContractFileConfigurationFormValues {
  fileName: string;
  lessDocumentCode?: string | null;
  documentLessId?: number | null;
  filePriorityOrder: number;
  description?: string | null;
}

export const contractFileConfigurationFormValidationSchema: yup.ObjectSchema<ContractFileConfigurationFormValues> =
  yup.object({
    fileName: yup.string().required("Este campo é obrigatório"),
    lessDocumentCode: yup
      .string()
      .transform((value) => value || null)
      .nullable()
      .optional(),
    documentLessId: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    filePriorityOrder: yup.number().required("Este campo é obrigatório"),
    description: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
  });
