import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import { AutoRelocationForm } from "../../auto-relocation.types";

interface DisableRelocationButtonProps {
  loading: boolean;
  submit: () => void;
}

export const DisableRelocationButton = ({
  loading,
  submit,
}: DisableRelocationButtonProps) => {
  const { watch } = useFormContext<AutoRelocationForm>();

  const companyIds = watch("companyIds");

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-close" }}
      dataAction="auto_realocacao:botao:desligar"
      dataLabel="desligar"
      type="danger"
      loading={loading}
      disabled={!companyIds?.length}
      onClick={submit}
    >
      Desligar realocação automática
    </IconButton>
  );
};
