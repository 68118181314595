/**
 * Transform Microsoft Excel date to JS date
 *
 * Source: https://stackoverflow.com/a/67130235
 *
 * @returns Date in format "YYYY-MM-DD"
 */
export const parseExcelDate = (excelDate: number) =>
  new Date(Date.UTC(0, 0, excelDate - 1)).toISOString().split("T")[0];

// export const parseExcelDate = (excelDate: number) =>
// console.log("excelDate", excelDate);
