import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ClerkSystemsForm } from "../_compose";
import { useClerkSystemsAdd } from "./add.hook";

export const ClerkSystemsAdd = () => {
  const { submit, form, loading } = useClerkSystemsAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Sistema clerk" description="Criar novo sistema" />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="sistemas_clerk:botao:adicionar"
          dataLabel="adicionar"
          onClick={submit}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ClerkSystemsForm mode="add" />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
