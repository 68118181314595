import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./new-accounts-optin.grid";
import { Workflow } from "./new-accounts-optin.type";

export const NewAccountsOptinPage = () => {
  const {
    customer: { identification, officialName },
  } = useCustomer();
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<Workflow>(() =>
        service.onboardingCoreAccount
          .getWorkflows({ taxId: identification })
          .then(({ data }) => {
            if (!data) return [];
            return Promise.all(
              data.map(async (workflow) => {
                return {
                  ...workflow,
                  cnpj: identification.toString(),
                  razaoSocial: officialName,
                };
              }),
            );
          })
          .catch(() => {
            const errorMessage =
              "Não foi possível carregar as solicitações de cração de contas Optin feitas";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [identification, officialName],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contas" />}
      actions={
        <RouterButton
          dataLabel="optin"
          href={routes.cadastro.customer.btgAccounts.optin.createAccounts.path}
          role={roles.cadastro.customer.btgAccountsOptinCreateAccountsPage.role}
          dataAction="optin:botao:redirecionar"
        >
          Abrir Contas Opt-in
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
