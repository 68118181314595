import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { productOriginatorsODataGrid } from "./product-originators.grid";

export const ProductOriginatorsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Originadores" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
      actions={
        <RouterButton
          href={corporateRouter.routes.parceiros.product.originators.add.path}
          role={roles.parceiros.product.originatorsAdd.role}
        >
          Adicionar originador
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={productOriginatorsODataGrid}
      />
    </GridTemplate>
  );
};
