import moment from "moment";
import dayjs from "dayjs";
import { PostAdminReceivablesBody } from "services/quickfin/models";
import { parsePeriodDate } from "utils/parse-period-date";
import { newFilterDefaultValues } from "./note-filter.form";
import { NotesFilterForm } from "./note-filter.types";

export const productTypeMap = {
  Cartao: "Cartão",
  RiscoCedente: "Cessão de crédito",
  CCC: "Cessão com coobrigação",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  Offshore: "Offshore",
  RiscoSacado: "Risco Sacado",
};

export const statesOptions = [
  "Agendado",
  "AguardandoRegistro",
  "AprovadoBanco",
  "AprovadoCedente",
  "AprovadoLimiteSacado",
  "AprovadoOnboarding",
  "AprovadoSacado",
  "DisponivelParaCedente",
  "EmCancelamento",
  "EmCancelamentoPagfor",
  "ErrorValidacao",
  "IndisponivelParaCedente",
  "Inicial",
  "PagforCancelado",
  "Pago",
  "PendenteAgendamento",
  "PendenteAprovacaoBanco",
  "PendenteAprovacaoCedente",
  "PendenteAprovacaoSacado",
  "PendenteAprovacaoSacadoPosSolicitacaoCedente",
  "PendenteComplementoContrato",
  "PendenteContratacao",
  "PendenteEligibilidade",
  "PendenteLimiteSacados",
  "PendenteOnboarding",
  "PendenteSimulacaoParcelas",
  "PendenteSolicitacaoTrade",
  "PendenteReleaseTrade",
  "PendenteValidacaoTaxas",
  "Recusado",
  "Removido",
  "TradeDesembolsado",
  "TradeEmAtraso",
  "TradeLiquidado",
  "TradeSolicitado",
];

export const noteFilterModalId = "note-filter-modal";

export const buildPayload = (
  values: NotesFilterForm,
): Omit<PostAdminReceivablesBody, "page" | "rows"> => {
  const createdAt = parsePeriodDate(values.createdAt);
  const disbursementDate = parsePeriodDate(values.disbursementDate);
  const maturityDate = parsePeriodDate(values.maturityDate);
  const tradeDate = parsePeriodDate(values.tradeDate);
  const updatedAt = parsePeriodDate(values.updatedAt);

  return {
    borderoId: values.borderoId,
    cedenteIdList: values.cedenteIdList,
    currentStateList: values.currentStateList,
    fromCreatedAt: createdAt[0] ?? "",
    fromDisbursementDate: disbursementDate[0] ?? "",
    fromMaturityDate: maturityDate[0] ?? "",
    fromTradeDate: tradeDate[0] ?? "",
    fromUpdatedAt: updatedAt[0] ?? "",
    operationId: values.operationId,
    receivableId: values.receivableId,
    receivableIdentification: values.receivableIdentification,
    externalIds: values.externalIds,
    contractNumbers: values.contractNumbers,
    strategyGuids: values.strategyGuids,
    sacadoIdList: values.sacadoIdList,
    tipoProdutoList: values.tipoProdutoList,
    toCreatedAt: createdAt[1] ?? "",
    toDisbursementDate: disbursementDate[1] ?? "",
    toMaturityDate: maturityDate[1] ?? "",
    toTradeDate: tradeDate[1] ?? "",
    toUpdatedAt: updatedAt[1] ?? "",
    tradeId: values.tradeId,
    creditorIds: values.creditorIds,
    marketplaceIds: values.marketplaceIds,
    portfolioIds: values.portfolioIds,
  };
};

export const isEmpty: (value?: any) => boolean = (value) =>
  Array.isArray(value)
    ? value.every(isEmpty)
    : [undefined, "", null].includes(value);

export const filtersToSearchParams = (values: NotesFilterForm) => {
  const searchParams = new URLSearchParams();
  Object.entries(values).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      if (
        value.every(
          (innerValue: any) =>
            moment.isMoment(innerValue) || dayjs.isDayjs(innerValue),
        )
      )
        value.forEach((v: dayjs.Dayjs) =>
          searchParams.append(key, v.format("YYYY-MM-DD")),
        );
      else value.forEach((v: string) => searchParams.append(key, v));
    }
  });
  return searchParams;
};

const newFilterDefaultValuesEntries = Object.entries(newFilterDefaultValues);

export const getSearchParamsNoteFilter = (
  searchParams: URLSearchParams,
  allowEmpty = true,
) =>
  newFilterDefaultValuesEntries.reduce((acc, [key, value]) => {
    const isArrayValue = Array.isArray(value);
    const searchParamValue = isArrayValue
      ? searchParams.getAll(key)
      : searchParams.get(key);
    const emptyValue = isArrayValue ? [] : "";
    return {
      ...acc,
      [key]: isEmpty(searchParamValue)
        ? allowEmpty
          ? emptyValue
          : value
        : searchParamValue,
    };
  }, newFilterDefaultValues);
