import { OCol, OOption, ORFieldSelect } from "@maestro/react";

export const SacadoForm = () => {
  return (
    <OCol xs={12} md={6} xl={4}>
      <ORFieldSelect
        dataAction="formulario_sacado:select:planilha_importacao"
        dataLabel="planilha_importacao"
        id="customSheetParser"
        name="customSheetParser"
        label="Planilha de importação"
        placeholder="Selecionar"
      >
        <OOption value="Padrao">Padrão</OOption>
        <OOption value="BRF">BRF</OOption>
        <OOption value="CNAB">CNAB</OOption>
        <OOption value="CSV">CSV</OOption>
        <OOption value="ImportBlocked">Importação bloqueada</OOption>
      </ORFieldSelect>
    </OCol>
  );
};
