export const loanProductDashboardDescriptionMap = {
  Renegociado: {
    label: "Renegociado",
    color: "#ffb190",
  },
  Expirado: {
    label: "Expirado",
    color: "#a70900",
  },
  Rejeitado: {
    label: "Rejeitado",
    color: "#740600",
  },
  Invalido: {
    label: "Inválido",
    color: "#a51b0b",
  },
  Cancelado: {
    label: "Cancelado",
    color: "#c30a00",
  },
  Simulado: {
    label: "Simulado",
    color: "#6a6269",
  },
  PendenteOnboardingTerceiros: {
    label: "Pendente Onboarding",
    color: "#4682B4",
  },
  GeracaoDeContrato: {
    label: "Geração de Contrato",
    color: "#b6d3a8",
  },
  PendenteAssinatura: {
    label: "Pendente Assinatura",
    color: "#e9c268",
  },
  PendenteValidacao: {
    label: "Pendente Validação",
    color: "#c5c5c5",
  },
  AguardandoRegistro: {
    label: "Aguardando Registro",
    color: "#4682b4",
  },
  AguardandoNotaFiscal: {
    label: "Aguardando NotaFiscal",
    color: "#3868b5",
  },
  PendenteAtivacaoConta: {
    label: "Pendente Ativação da Conta",
    color: "#695383",
  },
  AprovacaoEnvioTrade: {
    label: "Aprovação Envio Trade",
    color: "#6AC46A",
  },
  RecalculandoOperacao: {
    label: "Recalculando Operação",
    color: "#FFC2B4",
  },
  EnvioTrade: {
    label: "Envio Trade",
    color: "#287491",
  },
  TradeEnviado: {
    label: "Trade Enviado",
    color: "#7ba05b",
  },
  AguardandoAprovacao: {
    label: "Aguardando Aprovação",
    color: "#ffc36e",
  },
  AguardandoDesembolso: {
    label: "Aguardando Desembolso",
    color: "#9acfe3",
  },
  Desembolsado: {
    label: "Desembolsado",
    color: "#7ea4b3",
  },
  Liquidado: {
    label: "Liquidado",
    color: "#335E33",
  },
  Atrasado: {
    label: "Atrasado",
    color: "#60000E",
  },
  Cedido: {
    label: "Cedido",
    color: "#C5D6D8",
  },
};
