import { PaymentType } from "services/api/models/types/payments/enums";

export enum PaymentTypeGroup {
  Pix = "PIX",
  Ted = "TED",
  Tef = "TEF",
  Bankslip = "BANKSLIP",
  Darf = "DARF",
  Utilities = "UTILITIES",
  CreditCardInvoice = "CREDIT_CARD_INVOICE",
}

export enum DataSourceType {
  All = "ALL",
  Pending = "PENDING",
  Scheduled = "SCHEDULED",
  Paid = "PAID",
  Unprocessed = "UNPROCESSED",
}

export const MapperPaymentTypeGroup: Record<PaymentTypeGroup, string> = {
  [PaymentTypeGroup.Bankslip]: "Boleto",
  [PaymentTypeGroup.Pix]: "PIX",
  [PaymentTypeGroup.Ted]: "TED",
  [PaymentTypeGroup.Tef]: "TEF",
  [PaymentTypeGroup.Darf]: "DARF",
  [PaymentTypeGroup.Utilities]: "Conta / Tributo",
  [PaymentTypeGroup.CreditCardInvoice]: "Fatura de cartão de crédito",
};

export const MapperDataSourceType: Record<DataSourceType, string> = {
  [DataSourceType.All]: "Todos",
  [DataSourceType.Pending]: "Pendentes",
  [DataSourceType.Scheduled]: "Agendados",
  [DataSourceType.Paid]: "Realizados",
  [DataSourceType.Unprocessed]: "Não processados",
};

export const MapperPaymentTypeToPaymentTypeGroup: Record<
  ApiPayments.PaymentType,
  PaymentTypeGroup
> = {
  [PaymentType.Bankslip]: PaymentTypeGroup.Bankslip,
  [PaymentType.PixManual]: PaymentTypeGroup.Pix,
  [PaymentType.PixKey]: PaymentTypeGroup.Pix,
  [PaymentType.PixQrCode]: PaymentTypeGroup.Pix,
  [PaymentType.PixReversal]: PaymentTypeGroup.Pix,
  [PaymentType.Ted]: PaymentTypeGroup.Ted,
  [PaymentType.Tef]: PaymentTypeGroup.Tef,
  [PaymentType.Darf]: PaymentTypeGroup.Darf,
  [PaymentType.Utilities]: PaymentTypeGroup.Utilities,
  [PaymentType.CreditCardInvoice]: PaymentTypeGroup.CreditCardInvoice,
};
