const nodeTypesId: Record<string, string> = {
  GROUP: "groupConfig-",
  PROCESSOR: "",
};

export const getNodeIdNumber = (id: string, type?: string) => {
  if (!type) return;

  return Number(id.replace(nodeTypesId[type], ""));
};
