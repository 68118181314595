import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { SelectConditionsComponent } from "../../../../../../../../../components/conditions/select-conditions-component/select-conditions.component";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";
import { useWorkflow } from "../../../../../../../../../components/hooks/use-workflow.context";
import {
  updateStepModalFormDefaultValues,
  updateStepModalFormValidationSchema,
} from "./update-step-modal.schemas";
import { contentTypeOptions } from "../../../../form-details.utils";

export const UpdateStepModal = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(updateStepModalFormValidationSchema),
    defaultValues: updateStepModalFormDefaultValues,
  });
  const { setValue, reset, handleSubmit } = form;
  const { hide } = modalManager;
  const { setSelectedConditions } = useWorkflow();
  const {
    form: { callService: getForm, value: workflowFormTemplate },
    selectedStep,
  } = useWorkflowFormTemplate();

  const submitStep = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          if (
            !workflowFormTemplate ||
            (selectedStep?.description === values.description &&
              selectedStep?.name === values.name &&
              selectedStep?.content === values.content)
          )
            return;

          setLoading(true);

          await service.hubCreditManager.updateFormStep({
            ...values,
            formStepId: selectedStep?.id,
          });

          reset();
          getForm(workflowFormTemplate.id);
          hide("update-step-modal");
          OToastManager.success("O passo atualizado com sucesso.");
        } catch {
          OToastManager.danger("Não foi possível atualizar o passo.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, workflowFormTemplate, selectedStep, reset, getForm, hide],
  );

  useEffect(() => {
    if (!selectedStep) return;

    setSelectedConditions(selectedStep.conditions);

    // preencher campos do formulário
    reset({
      name: selectedStep.name,
      description: selectedStep.description,
      content: selectedStep.content,
    });
  }, [setValue, selectedStep, setSelectedConditions, reset]);

  return (
    <OModal
      id="update-step-modal"
      position="center"
      size="md"
      className="position-absolute"
      onModalClose={async () => {
        await submitStep();
      }}
    >
      <OModalHeader closeButton>
        <div className="d-flex gap-3 align-items-center">
          <OTypography tag="h1" size="xxl">
            Editar passo
          </OTypography>
          {loading && <OLoader size="xs" />}
        </div>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-5">
          <FormProvider {...form}>
            <form spellCheck="false">
              <ORFieldInput
                id="update-step-name"
                name="name"
                tag="text"
                label="Nome"
              />
              <ORFieldInput
                id="update-step-description"
                name="description"
                tag="text"
                label="Descrição"
              />
              <ORFieldSelect
                id="update-step-content"
                name="content"
                label="Tipo do conteúdo"
              >
                {contentTypeOptions.map((option) => (
                  <OOption
                    value={option}
                    key={`content-type-component-${option}`}
                  >
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </form>
          </FormProvider>
          <SelectConditionsComponent />
        </div>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex justify-content-end">
          <OButton
            dataAction="editar_passo:botao:fechar"
            dataLabel="fechar"
            type="dark"
            outline
            onClick={async () => {
              await submitStep();
              hide("update-step-modal");
            }}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
