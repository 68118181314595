import { OCheckbox, OCol, ORFieldCheckboxGroup, ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { BaseStepFields } from "./base-step-fields.component";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";
import { JustifiedLabel } from "../configuration-form.style";

interface CreateUserRelationshipStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const CreateUserRelationshipStep = ({
  fieldName,
  index,
}: CreateUserRelationshipStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      `${fieldName}.${index}._t`,
      "CreateUserRelationshipPartnerConfigurationStep",
    );
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(
        `${fieldName}.${index}.stepId`,
        `CREATE_USER_RELATIONSHIP-${generateUuidV4()}`,
      );
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="CREATE_USER_RELATIONSHIP"
        gridSize={4}
      />
      <OCol xs={4} align="center">
        <ORFieldCheckboxGroup
          dataAction={`partner_configuration_scf:checkbox:${fieldName}_${index}_skip_validation`}
          dataLabel={`${fieldName}_${index}_skip_validation`}
          id={`${fieldName}.${index}.shouldSendEmail`}
          name={`${fieldName}.${index}.shouldSendEmail`}
        >
          <div className="d-flex align-items-center gap-3 mb-2">
            <OCheckbox
              size="xs"
              id={`${fieldName}.${index}.shouldSendEmailCheckbox`}
              value="true"
            />
            <JustifiedLabel
              htmlFor={`${fieldName}.${index}.shouldSendEmailCheckbox`}
              id={`${fieldName}.${index}.shouldSendEmailLabel`}
            >
              Envia e-mail de boas vindas
            </JustifiedLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
