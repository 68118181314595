import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import {
  filterValues,
  formatExcelDate,
  parseNumber,
  toBoolean,
} from "../../../../../utils";
import {
  Receivable,
  ReceivableInstallment,
} from "../../batch-upload-receivables.types";
import { utils as xlsxUtils } from "xlsx";
import { getValueFromMap } from "utils/get-value-from-map";

export const ipcaCorrectionMap = {
  "Acumulado Simples": "ACSM",
  "Customizada BTG": "CBTG",
};

export const variationRuleMap = {
  "Variação positiva e negativa": "ALL",
  "Apenas positiva": "PSTV",
};

export const baseValueMap = {
  Parcela: "VPMT",
  Principal: "PCPL",
};

export const frequencyMap = {
  Mensal: "MNTHFULL",
  Anual: "YEARFULL",
};

export const dayCountCodeMap = {
  "Base 1": "B010",
  "Base 30": "B024",
  "Base 252": "B003",
  "Base 360": "A004",
  "Base 365": "B001",
  "Base 366": "B020",
  "Base 30/360": "B013",
  "Base 30E360": "B011",
};

export const getMapValueOrKey = (map: Record<string, string>, key: any) => {
  return getValueFromMap(map, key) ?? key;
};

export const parseReceivablesSpreadsheet = (
  buffer: FileReader["result"],
): Receivable[] => {
  let { workbook, firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  let installments: ReceivableInstallment[] | null = null;
  if (workbook.SheetNames.length > 1) {
    sheetData = xlsxUtils.sheet_to_json<Record<string, unknown>>(
      workbook.Sheets["Recebiveis"] ??
        workbook.Sheets["Dados"] ??
        workbook.Sheets["Sheet1"],
    );

    installments = xlsxUtils
      .sheet_to_json<Record<string, unknown>>(workbook.Sheets["Parcelas"])
      .map((row) => {
        const cleanRow = normalizeObjectKeys(row);

        return filterValues({
          receivableIdentification: cleanRow["numero da nota"],
          number: parseNumber(cleanRow["numero da parcela"]),
          amount: parseNumber(cleanRow["valor da parcela"]),
          amortization: parseNumber(cleanRow["valor de amortização"]),
          maturityDate: formatExcelDate(cleanRow["data vencimento da parcela"]),
          monetaryCorrectionIndexCode: cleanRow["correção monetária - index"],
          monetaryCorrectionIpcaCorrection: getMapValueOrKey(
            ipcaCorrectionMap,
            cleanRow["correção monetária - correção ipca"],
          ),
          monetaryCorrectionVariationRule: getMapValueOrKey(
            variationRuleMap,
            cleanRow["correção monetária - regra variação"],
          ),
          monetaryCorrectionBaseValue: getMapValueOrKey(
            baseValueMap,
            cleanRow["correção monetária - valor base"],
          ),
          monetaryCorrectionFrequency: getMapValueOrKey(
            frequencyMap,
            cleanRow["correção monetária - frequência"],
          ),
          monetaryCorrectionLag: parseNumber(
            cleanRow["correção monetária - defasagem"],
          ),
        });
      }) as ReceivableInstallment[];
  }

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return filterValues({
      identification: cleanRow["numero da nota"],
      legalName: cleanRow.fornecedor,
      taxId: cleanRow["identificação (cnpj/cpf/offshore)"],
      currency: cleanRow.moeda,
      disbursementDate: formatExcelDate(cleanRow["data desembolso"]),
      maturityDate: formatExcelDate(cleanRow["data vencimento"]),
      dataRepasse: formatExcelDate(cleanRow["data repasse"]),
      rebate: parseNumber(cleanRow.rebate),
      bankCode: cleanRow["código do banco"],
      agency: cleanRow.agência,
      accountNumber: cleanRow.conta,
      accountDigit: cleanRow["dígito"],
      pixKey: cleanRow["chave pix"],
      pixType: cleanRow["tipo pix"],
      documentType: cleanRow["tipo do documento"],
      disbursementAmount: parseNumber(cleanRow["valor desembolso"]),
      maturityAmount: parseNumber(cleanRow["valor da nota"]),
      hasHedge: toBoolean(cleanRow["tem hedge"]),
      iof: parseNumber(cleanRow.iof),
      exchangeRate: parseNumber(cleanRow["taxa de câmbio"]),
      country: cleanRow["país"],
      repository: cleanRow["registradora"],
      registerNumber: cleanRow["número de registro"],
      propertyName: cleanRow["nome do empreendimento"],
      propertyUnit: cleanRow["unidade do empreendimento"],
      propertyBlock: cleanRow["bloco do empreendimento"],
      loanRate: parseNumber(cleanRow["taxa do empréstimo"]),
      clientLoanPrincipal: parseNumber(cleanRow["valor principal do cliente"]),
      loanBaseDayCountCode: getMapValueOrKey(
        dayCountCodeMap,
        cleanRow["base de dias do empréstimo"],
      ),
      installments: installments?.filter(
        (item) => item.receivableIdentification === cleanRow["numero da nota"],
      ),
      externalId: cleanRow["origin id"],
    });
  });
};
