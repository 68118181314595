import * as yup from "yup";
import { OInputDate } from "@maestro/react";

type Moment = Extract<React.ComponentProps<typeof OInputDate>["value"], object>;

export interface GroupedValuesForm {
  cedentes: string[];
  sacados: string[];
  bandeiras: string[];
  dataVencimentoFinal: Moment | null;
  dataVencimentoInicial: Moment | null;
}

export const searchModalDefaulValues = {
  cedentes: [],
  sacados: [],
  bandeiras: [],
  dataVencimentoFinal: null,
  dataVencimentoInicial: null,
} satisfies GroupedValuesForm;

export const searchModalValidationSchema = yup.object({});
