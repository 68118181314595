import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetOdataOperationsAttendanceODataResponse } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { operationStatusMap } from "./operations.utils";

export const grid: ODataGridGeneratorConfig<
  GetOdataOperationsAttendanceODataResponse["value"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma Operação encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} operações)",
    },
    remoteOperations: true,
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) =>
        data.Id ? (
          <DetailsLink
            href={corporateRouter.routes.antecipacaoAFornecedor.customer.operations.details.path(
              {
                id: data.Id,
              },
            )}
          />
        ) : undefined,
      role: roles.antecipacaoAFornecedor.customer.operationsDetailsPage.role,
    },
    {
      dataField: "CreatedAt",
      caption: "Data",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      dataField: "SacadoName",
      caption: "Sacado",
    },
    {
      dataField: "Status",
      caption: "Status",
      format: (value) => getValueFromMap(operationStatusMap, value) ?? value,
    },
    {
      dataField: "ReceivableQuantity",
      caption: "Total de títulos",
    },
    {
      dataField: "ReceivableDeniedQuantity",
      caption: "Notas recusadas",
    },
    {
      dataField: "MaturityAmount",
      caption: "Valor de face (R$)",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "DisbursementAmount",
      caption: "Valor antecipado (R$)",
      format: (value) => masks.currency(value, "R$", "."),
    },
  ],
};
