import { SelectSearchMultipleOption } from "components/form";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";

export const useWalletInvoicesFilter = () => {
  const [selected, setSelected] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const { customer } = useCustomer();
  const document = customer.identification;

  const { callService: getPayersFromOwner } = useServiceCall(
    service.moana.getPayersFromOwner,
  );

  const getOwners = useCallback(async () => {
    const data = await getPayersFromOwner({
      documentNumber: document,
    });
    return data.response;
  }, [document]);

  useEffect(() => {
    const fetchOwners = async () => {
      const response = await getOwners();
      if (!response?.data) return;
      const newData = response.data.map((v) => {
        return {
          value: v.uuid,
          label: `${v.name} (${fastCpfCnpjMask(v.documentNu)})`,
        };
      });
      setSelected(newData);
    };

    fetchOwners();
  }, []);

  return { selected };
};
