import { OToastManager, TMessage } from "@maestro/core";
import { Navigate } from "react-router-dom";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";

interface BehaviorRouteProps {
  allowedBehaviors: Behaviors[];
  toastMessage?: TMessage | null;
  redirectPath?: string;
  children: React.ReactNode;
}

export const BehaviorRoute = ({
  allowedBehaviors,
  children,
  redirectPath,
  toastMessage = {
    title: "Você foi redirecionado",
    message:
      "A página que você tentou acessar não está disponível para o comportamento atual",
  },
}: BehaviorRouteProps) => {
  const { behavior } = useBehavior();

  if (
    !allowedBehaviors.some((allowedBehavior) => allowedBehavior === behavior.id)
  ) {
    toastMessage && OToastManager.warning(toastMessage);
    return <Navigate to={redirectPath || behavior.homepage} replace />;
  }

  return children as JSX.Element;
};
