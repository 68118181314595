import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataBorderosODataItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./imported-batches.grid";

const dataSource = dataSourceODataStoreGenerator<GetOdataBorderosODataItem>(
  endpoints.quickfin.odata.borderosOData,
);

export const TitleImportedBatches = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Lotes importados"
          description="Visualize todos os títulos recebíveis e suas informações principais"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
