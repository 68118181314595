import { PageTitle } from "components/page-title";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RelationshipContingencies } from "../../../../../components/contingencias-relacionamento";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../routes/cadastro.route-params";

export const EloaContingencies = () => {
  const { uuid } = useParams<CadastroClienteProcuracoesByUuidRouteParams>();
  if (!uuid)
    throw new Error("No representative workflow uuid in route parameters");

  const entity = useMemo(
    () => ({
      workflowUuid: uuid,
    }),
    [uuid],
  );

  return (
    <div>
      <PageTitle title="Contingências Procuração eletrônica" />
      <RelationshipContingencies
        entity={entity}
        relationship="REPRESENTANTE_PROCURACAO_ELETRONICA"
      />
    </div>
  );
};
