import {
  OCol,
  OField,
  OLabel,
  OLoader,
  OOption,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { emailTypeMap } from "../auto-emails.utils";
import { useNewAutoEmail } from "./new-auto-email.hook";

export const NewAutoEmail = () => {
  const { companiesLoading, form, loading, options, submit } =
    useNewAutoEmail();

  const {
    formState: { errors },
    setValue,
  } = form;

  const clear = useCallback(
    () => setValue("companyId", null as any),
    [setValue],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar e-mail automático" />}
      actions={
        <LoadingButton
          dataAction="criar_email_automatico:botao:salvar"
          dataLabel="salvar"
          onClick={submit}
          loading={loading}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow gap={3}>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <OField
                label="Empresa"
                htmlFor="companyId"
                error={!!errors.companyId}
                message={errors?.companyId?.message}
              >
                <SelectSearch
                  id="companyId"
                  name="companyId"
                  placeholder="Busque uma empresa ou CNPJ"
                  options={options}
                  disabled={companiesLoading}
                  onClear={clear}
                  className="w-100"
                  maxOptions={50}
                />
              </OField>
              {companiesLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                dataAction="criar_email_automatico:select:tipo_email"
                dataLabel="tipo_email"
                id="emailType"
                name="emailType"
                label="Tipo de e-mail"
                placeholder="Selecione"
              >
                {Object.entries(emailTypeMap).map(([raw, pretty]) => (
                  <OOption key={raw} value={raw}>
                    {pretty}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
          <ORow className="mt-3" gap={3}>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                dataAction="criar_email_automatico:radio:enviar_para_ol"
                dataLabel="enviar_para_ol"
                id="sendToOL"
                name="sendToOL"
                label="Enviar para OL"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="sendToOL-yes" value="yes" />
                    <OLabel htmlFor="sendToOL-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="sendToOL-no" value="no" />
                    <OLabel htmlFor="sendToOL-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                dataAction="criar_email_automatico:radio:enviar_para_representante_legal"
                dataLabel="enviar_para_representante_legal"
                id="sendToLegalRepresentative"
                name="sendToLegalRepresentative"
                label="Enviar para representante legal"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="sendToLegalRepresentative-yes" value="yes" />
                    <OLabel htmlFor="sendToLegalRepresentative-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="sendToLegalRepresentative-no" value="no" />
                    <OLabel htmlFor="sendToLegalRepresentative-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                dataAction="criar_email_automatico:radio:enviar_para_operadores"
                dataLabel="enviar_para_operadores"
                id="sendToOperators"
                name="sendToOperators"
                label="Enviar para operadores"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="sendToOperators-yes" value="yes" />
                    <OLabel htmlFor="sendToOperators-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="sendToOperators-no" value="no" />
                    <OLabel htmlFor="sendToOperators-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
