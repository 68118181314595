import {
  OButton,
  OCol,
  ODivider,
  OIcon,
  OInputMask,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CustomLabelSectionFilter } from "../../../../side-filter-bar.types";

type CustomLabelProps = CustomLabelSectionFilter;

export const CustomLabel = ({
  formId,
  maskOption,
  unmaskedValidator = () => true,
  placeholder,
}: CustomLabelProps) => {
  const [inputValue, setInputValue] = useState<string>();

  const { watch, setValue } = useFormContext();

  const fieldValueWatched: string[] = watch(formId) || [];

  const handleIncludeTag = (value: string) => {
    const hasToInclude = !fieldValueWatched.includes(value);
    if (hasToInclude) setValue(formId, fieldValueWatched.concat(value));
    setInputValue(undefined);
  };

  const handleExcludeTag = (value: string) => {
    setValue(
      formId,
      fieldValueWatched.filter((tag) => tag !== value),
    );
  };

  return (
    <div className="d-flex flex-column gap-3">
      <ORow justify="between">
        <OCol sm={10}>
          <OInputMask
            className="bg-light w-100"
            id="tag"
            name="tag"
            maskOption={maskOption}
            placeholder={placeholder}
            autocomplete="off"
            aspect="outline"
            value={inputValue}
            onChangeMasked={(e) => {
              const { unmaskedValue } = e.detail;
              setInputValue(unmaskedValue);
            }}
          />
        </OCol>
        <OCol sm="auto">
          <OButton
            type="tertiary"
            outline
            bordered={false}
            circle
            disabled={!!inputValue && !unmaskedValidator(inputValue)}
            onClick={() => {
              if (!inputValue) return;
              handleIncludeTag(inputValue);
            }}
          >
            <OIcon category="orq" icon="orq-add-circle" size="lg" />
          </OButton>
        </OCol>
      </ORow>
      {!!fieldValueWatched.length && (
        <div>
          {fieldValueWatched.map((tag, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <ORow justify="between" align="center">
                <OCol sm="auto">
                  <OCol sm="auto" align="center">
                    <OTypography type="dark">
                      {maskOption ? masks.generic(tag, maskOption) : tag}
                    </OTypography>
                  </OCol>
                </OCol>
                <OCol sm="auto" align="center">
                  <OButton
                    type="tertiary"
                    outline
                    bordered={false}
                    circle
                    onClick={() => {
                      handleExcludeTag(tag);
                    }}
                  >
                    <OIcon category="orq" icon="orq-remove-circle" size="lg" />
                  </OButton>
                </OCol>
              </ORow>
              <ODivider size="xxs" className="my-2" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
