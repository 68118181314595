import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, expiringPowersGrid } from "../../../../components";

export const ExpiringPowers = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(["CompanyTaxId", "=", identification]),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Expiração de poderes" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={expiringPowersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
