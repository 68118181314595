import {
  OCollapseContent,
  OCollapseHeader,
  OIcon,
  OOptionMultiple,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { useDropdown } from "./collapse-item.hook";
import {
  StyledCollapse,
  StyledContent,
  StyledInput,
  StyledMultipleSelector,
} from "./collapse-item.styles";
import { CollapseItemProps } from "./collapse-item.types";
import { handleClickSelector } from "./collapse-item.utils";

export const CollapseItem = ({
  name,
  label,
  children,
  values,
  statusMap,
  clearSearchs,
}: CollapseItemProps) => {
  const { watch } = useFormContext();
  const watchValue = name && watch(name);

  const { dropdownRef, valuesMapping, setSearchValue } = useDropdown(
    values,
    statusMap,
    clearSearchs,
  );

  return (
    <StyledCollapse
      key={`field-${values?.map((a) => a.slice(0))}-${clearSearchs}`}
    >
      <OCollapseHeader onClick={() => handleClickSelector(dropdownRef)}>
        {label}
      </OCollapseHeader>
      <OCollapseContent>
        {children ?? (
          <StyledContent ref={dropdownRef}>
            {!statusMap && (
              <StyledInput
                aspect="outline"
                id="selector-search"
                name="selector-search"
                placeholder="Buscar"
                onInput={(evt) => {
                  setSearchValue(evt.currentTarget.value ?? "");
                }}
              >
                <OIcon
                  category="orq"
                  icon="orq-tools-search"
                  slot="before"
                  size="lg"
                />
              </StyledInput>
            )}

            {name && (
              <StyledMultipleSelector
                value={watchValue}
                name={name}
                id="name-field"
              >
                <div key={`options-${values?.length}`}>
                  {valuesMapping?.map((s) => {
                    return (
                      <OOptionMultiple
                        selected={watchValue?.includes(s.value)}
                        value={s.value}
                        key={s.label}
                      >
                        {s.label}
                      </OOptionMultiple>
                    );
                  })}
                </div>
              </StyledMultipleSelector>
            )}
          </StyledContent>
        )}
      </OCollapseContent>
    </StyledCollapse>
  );
};
