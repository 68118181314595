import { OToastManager } from "@maestro/core";
import {
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OLoader,
  ORow,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { service } from "services/service";
import { VariableDetails } from "./_compose";

interface ContingencyScriptProps {
  contingency: HubOnboardingRelationships.Admin.Contingency;
  entity: any;
}

export const ContingencyScript = ({
  contingency,
  entity,
}: ContingencyScriptProps) => {
  const [filledData, setFilledData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<Record<string, string>>({});
  const [requiresInput, setRequiresInput] = useState(false);

  const submit = useCallback(async () => {
    if (Object.values(payload).length < contingency.variableDetails.length) {
      OToastManager.danger("Preencha todos os campos");
      return;
    }

    setLoading(true);
    try {
      await service.onboardingRelationships.runContingency({
        identifier: contingency.identifier ?? "",
        data: payload,
      });
      OToastManager.success("Contingencia resolvida com sucesso!");
    } catch (err) {
      const message = isAxiosError(err)
        ? err.response?.data?.failures.length > 0
          ? err.response?.data?.failures[0].errorMessage
          : "Dados inválidos"
        : "Ocorreu um erro. Tente novamente mais tarde";
      OToastManager.danger(message);
    } finally {
      setLoading(false);
    }
  }, [contingency, payload]);

  const fillData = useCallback(async () => {
    if (filledData) {
      return;
    }
    const newPayload = { ...payload };
    contingency.variableDetails.forEach((variableDetail) => {
      newPayload[variableDetail.propertyIdentifier] =
        entity[variableDetail.propertyIdentifier];
    });
    setPayload(newPayload);
    const payloadValues = Object.values(newPayload).filter((value) => !!value);
    setFilledData(true);
    setRequiresInput(
      payloadValues.length !== contingency.variableDetails.length,
    );
  }, [contingency.variableDetails, entity, filledData, payload]);

  useEffect(() => {
    fillData();
  }, [fillData]);

  return requiresInput ? (
    <OCard className="my-2">
      {loading && <OLoader absolute backdrop />}
      <OCardHeader>
        <PageTitle
          title={contingency.identifier ?? ""}
          description={contingency.description}
        />
      </OCardHeader>
      <OCardBody>
        <ORow>
          {contingency.variableDetails.length > 0 &&
            contingency.variableDetails.map((variableDetail) => (
              <VariableDetails
                key={variableDetail.propertyIdentifier}
                variableDetail={variableDetail}
                payload={payload}
                setPayload={setPayload}
              />
            ))}
        </ORow>
      </OCardBody>
      <OCardFooter className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      </OCardFooter>
    </OCard>
  ) : (
    <OCard className="my-2">
      {loading && <OLoader absolute backdrop />}
      <OCardBody className="d-flex flex-row align-items-center justify-content-between">
        <PageTitle
          title={contingency.identifier ?? ""}
          description={contingency.description}
        />
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      </OCardBody>
    </OCard>
  );
};
