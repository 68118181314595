import { OBadge } from "@maestro/react";

const getStatus = (limit: BankingHub.FullLimit | undefined) => {
  if (limit?.Message === "Error retrieving overdraft information") {
    return { color: "danger-light" as const, text: "Sem limite disponível" };
  }

  if (limit?.isSplitted) {
    return { color: "warning-light" as const, text: "Em Parcelamento" };
  }

  if (
    limit?.usedAmount &&
    limit?.maxAmount &&
    limit?.usedAmount > limit?.maxAmount
  ) {
    return { color: "danger-light" as const, text: "Overlimit" };
  }

  if (limit?.analysis?.status === "Approved") {
    return { color: "success-light" as const, text: "Contratado" };
  }

  return { color: "info-light" as const, text: "Ofertado" };
};

interface OverdraftStatusBadgeProps {
  limit: BankingHub.FullLimit | undefined;
}

export const OverdraftStatusBadge = ({ limit }: OverdraftStatusBadgeProps) => {
  const { color, text } = getStatus(limit);

  return <OBadge type={color}>{text}</OBadge>;
};
