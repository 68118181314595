import {
  ODivider,
  OExpander,
  OExpanderHeader,
  OIcon,
  OInputSearch,
  OTypography,
} from "@maestro/react";
import debounce from "lodash/debounce";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClearSelectionButton } from "./_compose/clear-selection-button";
import { useOutsideClick } from "./_compose/hooks/outside-click";
import { Option } from "./_compose/option";
import {
  AbsoluteExpanderContent,
  OptionsContainer,
} from "./custom-select.styles";
import { CustomSelectProps } from "./custom-select.types";

/* eslint-disable react/function-component-definition */
function Component<Option>({
  options,
  mainOptionsByUniqueKeyProp,
  uniqueKeyProp,
  renderOption,
  resumeOption,
  renderEmpty,
  handleSelect,
  id,
  size = "md",
  label,
  allowClearSelection,
  disabled,
  blockSelection,
  blockSingleOptionAutoSelection,
  selectedOption,
  setSelectedOption,
  hideSearch,
  handleOptionsVisibility,
  searchPlaceholder = "Digite aqui o que você procura",
  hideOptionsDescription = "Para acessar outras opções, digite o que você procura no campo de busca.",
  maxOptionsAllowed = 20,
  optionsMaxHeight = "180px",
}: CustomSelectProps<Option>) {
  const [expanded, setExpanded] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [internalSelectedOption, setInternalSelectedOption] =
    useState<Option>();

  const handleUpdateSelectedOption = useCallback(
    (option?: Option) => {
      if (setSelectedOption) setSelectedOption(option);
      else setInternalSelectedOption(option);
    },
    [setSelectedOption],
  );

  const selectedOptionRef = useMemo(
    () => selectedOption ?? internalSelectedOption,
    [selectedOption, internalSelectedOption],
  );

  const expanderRef = useRef<HTMLOExpanderElement>(null);

  useOutsideClick<HTMLOExpanderElement>(expanderRef, () => {
    if (expanded) setExpanded(false);
  });

  useEffect(() => {
    if (!options || options.length !== 1 || disabled) return;

    const [defaultAccountOption] = options;

    if (blockSingleOptionAutoSelection) return;

    if (setSelectedOption) {
      setSelectedOption((prev) => prev ?? defaultAccountOption);
    } else {
      setInternalSelectedOption((prev) => prev ?? defaultAccountOption);
    }

    handleSelect(defaultAccountOption);
  }, [
    options,
    blockSingleOptionAutoSelection,
    disabled,
    handleSelect,
    setSelectedOption,
  ]);

  const mainOptions = useMemo(() => {
    if (!mainOptionsByUniqueKeyProp) return;
    return mainOptionsByUniqueKeyProp.reduce<Option[]>(
      (list, targetUniqueKeyPropValue) => {
        try {
          const targetOption = options.find(
            (option) => option?.[uniqueKeyProp] === targetUniqueKeyPropValue,
          );
          if (targetOption) return list.concat(targetOption);
          return list;
        } catch {
          return list;
        }
      },
      [],
    );
  }, [mainOptionsByUniqueKeyProp, options, uniqueKeyProp]);

  const optionsList = useMemo(() => {
    if (!searchString && !!mainOptions) return mainOptions;
    return options?.filter((option) => {
      return resumeOption(option)
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  }, [searchString, mainOptions, options, resumeOption]);

  const classNameDisabled = useMemo(() => {
    return disabled ? "disabled" : "";
  }, [disabled]);

  const clearSelectionButtonHandleOnClick = useCallback(
    ({ stopPropagation }: React.MouseEvent) => {
      handleUpdateSelectedOption(undefined);
      handleSelect(undefined);
      stopPropagation();
    },
    [handleSelect, handleUpdateSelectedOption],
  );

  useEffect(() => {
    if (!disabled && handleOptionsVisibility) handleOptionsVisibility(expanded);
  }, [disabled, expanded, handleOptionsVisibility]);

  const showHideOptionsDescription = useMemo(
    () => !!searchString || optionsList.length > maxOptionsAllowed,
    [searchString, optionsList.length, maxOptionsAllowed],
  );

  const debouncedSearch = useMemo(() => {
    return debounce((value: string) => setSearchString(value), 500);
  }, []);

  return (
    <div className={`d-flex flex-column gap-1 ${classNameDisabled}`} id={id}>
      <OExpander
        className={classNameDisabled}
        ref={expanderRef}
        expanded={expanded}
      >
        <OExpanderHeader onClick={() => setExpanded((prev) => !prev)}>
          <div
            className={`o-select__header--size-${size} d-flex flex-column justify-content-end gap-1`}
          >
            <div className="d-flex justify-content-between">
              <div className="w-95">
                {selectedOptionRef ? (
                  <div className="d-flex flex-row w-100 justify-content-between">
                    {renderOption(selectedOptionRef, true)}
                    {allowClearSelection && options.length > 1 && (
                      <ClearSelectionButton
                        handleClick={clearSelectionButtonHandleOnClick}
                      />
                    )}
                  </div>
                ) : (
                  renderEmpty()
                )}
              </div>
              <OIcon
                className={!disabled ? "rotate" : ""}
                category="orq"
                icon="orq-chevron-down"
                size="xl"
              />
            </div>
            <ODivider size="xxs" />
          </div>
        </OExpanderHeader>
        {!disabled && (
          <AbsoluteExpanderContent
            width={`${expanderRef.current?.offsetWidth}px`}
          >
            {!hideSearch && (
              <div className="border-bottom px-2">
                <OInputSearch
                  aspect="unstyled"
                  id="search-input"
                  key="search-input"
                  name="search-input"
                  handleClear={() => {
                    event?.preventDefault();
                    setSearchString("");
                  }}
                  onInput={({ currentTarget: { value } }) =>
                    debouncedSearch(value ?? "")
                  }
                  placeholder={searchPlaceholder}
                />
              </div>
            )}
            {optionsList && (
              <OptionsContainer maxHeight={optionsMaxHeight}>
                {optionsList
                  .slice(0, maxOptionsAllowed)
                  .map((option: Option) => {
                    const selected =
                      option[uniqueKeyProp] ===
                      selectedOptionRef?.[uniqueKeyProp];
                    return (
                      <Option
                        key={String(option[uniqueKeyProp])}
                        selected={selected}
                        disabled={blockSelection}
                        onClick={() => {
                          handleUpdateSelectedOption(option);
                          handleSelect(option);
                          setExpanded((prev) => !prev);
                        }}
                      >
                        {renderOption(option)}
                      </Option>
                    );
                  })}
                {showHideOptionsDescription && (
                  <div className="d-flex align-items-center p-2">
                    <OTypography>{hideOptionsDescription}</OTypography>
                  </div>
                )}
              </OptionsContainer>
            )}
          </AbsoluteExpanderContent>
        )}
      </OExpander>
      {label && (
        <OTypography type="dark-80" size="sm">
          {label}
        </OTypography>
      )}
    </div>
  );
}

const genericMemoComponent: <Props>(component: Props) => Props = memo;

export const CustomSelect = genericMemoComponent(Component);
