import { OInput } from "@maestro/react";
import styled from "styled-components";

export const EmptyButton = styled.button`
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;

  :active {
    box-shadow: inset 0px 0px 7px 0px var(--theme-dark-10);
  }
`;

export const InputOutline = styled(OInput)`
  &.o-input-active {
    input {
      border-color: var(--theme-success);
      box-shadow: 0 0 2px 2px var(--theme-success-20);
    }
  }

  [slot="after"] {
    width: unset !important;
    padding: 0 6px;

    & ~ .o-input {
      padding-right: 54px !important; // 48 + 6
    }
  }

  /* center icons */
  o-icon i.o-icon {
    line-height: unset !important;
  }
`;
