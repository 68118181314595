import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { exchangeLimitsGrid } from "../../../components/limits/limits.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.hubFx.adminCustomer.getLimits().catch(() => {
    throw new Error("Erro ao buscar limites.");
  }),
);

export const ExchangeLimitsPage = () => {
  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Limites" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeLimitsGrid(mapSymbol)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
