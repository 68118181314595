import * as yup from "yup";

export interface WorkflowContextFormValues {
  processorConfig: string;
}

export const workflowContextValidationSchema: yup.ObjectSchema<WorkflowContextFormValues> =
  yup.object({
    processorConfig: yup.string().required("Campo obrigatório"),
  });
