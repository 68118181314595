import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingReviewByUUIDRouteParams } from "../../../../../routes/cadastro.route-params";
import { useOnboardingReviewEdit } from "./onboarding-review-edit.hook";

export const OnboardingReviewEdit = () => {
  const { uuid } = useParams<OnboardingReviewByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid in route parameters");

  const { submit, form, loading, dataReviewDetails, dataReviewEnums } =
    useOnboardingReviewEdit({
      uuid,
    });

  const {
    callService: getDetails,
    loading: loadingDetails,
    hasError: hasErrorDetails,
  } = dataReviewDetails;
  const {
    callService: getEnums,
    loading: loadingEnums,
    hasError: hasErrorEnums,
    value: enums,
  } = dataReviewEnums;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Editar Revisão Cadastral"
          description={`Id: ${uuid}`}
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Editar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasErrorDetails}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar revisão"
            callback={() => getDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <form>
                <ORow>
                  <OCol className="gap-2">
                    <ORFieldSelect
                      disabled={loadingEnums}
                      id="status"
                      name="status"
                      placeholder="Status"
                      label="Status"
                      key={enums?.portalStatusTypes?.length}
                    >
                      {enums?.portalStatusTypes?.map((portalStatus) => (
                        <OOption key={portalStatus} value={portalStatus}>
                          {portalStatus}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                    {loadingEnums && <OLoader className="d-block" size="sm" />}
                    {hasErrorEnums && (
                      <OButton
                        type="default"
                        outline
                        onClick={() => getEnums()}
                      >
                        <OIcon
                          category="fa"
                          icon="fa-repeat"
                          size="sm"
                          type="default"
                        />
                      </OButton>
                    )}
                  </OCol>
                  <OCol className="gap-2">
                    <ORFieldSelect
                      disabled={loadingEnums}
                      id="notificationStatus"
                      name="notificationStatus"
                      placeholder="Status da Notificação"
                      label="Status da Notificação"
                      key={enums?.portalNotificationStatusTypes?.length}
                    >
                      {enums?.portalNotificationStatusTypes?.map(
                        (notificationStatus) => (
                          <OOption
                            key={notificationStatus}
                            value={notificationStatus}
                          >
                            {notificationStatus}
                          </OOption>
                        ),
                      )}
                    </ORFieldSelect>
                    {loadingEnums && <OLoader className="d-block" size="sm" />}
                    {hasErrorEnums && (
                      <OButton
                        type="default"
                        outline
                        onClick={() => getEnums()}
                      >
                        <OIcon
                          category="fa"
                          icon="fa-repeat"
                          size="sm"
                          type="default"
                        />
                      </OButton>
                    )}
                  </OCol>
                  <OCol>
                    <ORFieldInput
                      tag="text"
                      id="type"
                      name="type"
                      dataAction="editar_onboarding_review:input:tipo"
                      dataLabel="tipo"
                      label="Tipo"
                      disabled
                    />
                  </OCol>
                  <OCol>
                    <ORFieldInput
                      tag="text"
                      id="insertedAt"
                      name="insertedAt"
                      dataAction="editar_onboarding_review:input:data-da-criacao"
                      dataLabel="data-da-criacao"
                      label="Data da Criação"
                      disabled
                    />
                  </OCol>
                  <OCol>
                    <ORFieldInput
                      tag="text"
                      id="updatedAt"
                      name="updatedAt"
                      dataAction="editar_onboarding_review:input:data-de-atualizacao"
                      dataLabel="data-de-atualizacao"
                      label="Data de Atualização"
                      disabled
                    />
                  </OCol>
                </ORow>
              </form>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
