import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostAdminCompanyGetByTaxIdResponse } from "services/onboarding-relationships/models";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./clientes.grid";

export const UsuariosCustomer = () => {
  const { customer } = useCustomer();
  const { hasRole } = useRoles();
  const gridRef = useGridRef();

  const canUpdate = hasRole(roles.usuarios.customer.updateUser.role);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        PostAdminCompanyGetByTaxIdResponse[number]
      >(
        () =>
          service.onboardingRelationships
            .getUsersInfo()
            .then(({ data }) => {
              return {
                data,
                totalCount: data.length,
              };
            })
            .catch(() => {
              const errorMessage = "Erro ao buscar usuários";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }),
        {
          customStoreOptions: {
            update: async (original, updated) => {
              const payload = {
                data: {
                  legalRepresentative: updated.legalRepresentative,
                  responsibilities: [],
                },
              };
              try {
                await service.clerk.updateUser(
                  original.cpf,
                  customer.identification,
                  payload,
                );
              } catch {
                OToastManager.danger("Falha ao editar usuário");
              }
            },
          },
        },
      ),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Usuários" />}
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.usuarios.customer.all.addInternacional.path
            }
            role={roles.usuarios.customer.addInterncionalPage.role}
          >
            Novo usuário internacional
          </StandardLink>
          <StandardLink
            href={corporateRouter.routes.usuarios.customer.all.add.path}
            role={roles.usuarios.customer.addPage.role}
          >
            Novo usuário
          </StandardLink>
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showExportButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid(canUpdate)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
