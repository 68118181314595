import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { EnableCurrentAccount } from "../../cadastro/pages/cliente/detalhes/abertura-conta-corrente";
import { B2BCustomersPage } from "../pages/cliente/clientes";
import { CompanyUsersPage } from "../pages/cliente/clientes/[customerId]/detalhes";
import { OperatorsPage } from "../pages/cliente/operadores";
import { OriginatorPage } from "../pages/cliente/originator";
import { B2BEmbedded } from "../pages/produto/[...routes]";
import { b2bRoles } from "../roles/b2b.roles";
import { b2bRouteParams } from "./b2b.route-params";

export const b2bRoutes = {
  customer: {
    name: "",
    path: "/finders/cliente",
    element: <RedirectWithRoles />,
    children: {
      originator: {
        name: "Originador",
        path: "/finders/cliente/originador",
        role: b2bRoles.customer.originatorPage.role,
        element: <OriginatorPage />,
      },
      operators: {
        name: "Operadores",
        path: "/finders/cliente/operadores",
        role: b2bRoles.customer.operatorsPage.role,
        element: <OperatorsPage />,
      },
      b2bCustomers: {
        name: "Clientes",
        path: "/finders/cliente/clientes",
        role: b2bRoles.customer.b2BCustomersPage.role,
        element: <B2BCustomersPage />,
        children: {
          companyUsers: {
            name: "Usuários da empresa",
            path: `/finders/cliente/clientes/:${b2bRouteParams.customerId}/detalhes`,
            role: b2bRoles.customer.b2BCustomersCompanyUsersPage.role,
            element: <CompanyUsersPage />,
          },
        },
      },
      enableCurrentAccount: {
        name: "Abertura de Conta Corrente",
        path: "/finders/cliente/abertura-conta-corrente",
        role: b2bRoles.customer.enableCurrentAccountPage.role,
        element: <EnableCurrentAccount />,
      },
    },
  },
  product: {
    name: "Portal de Parceiros",
    path: "/finders/produto/*",
    isFullScreen: true,
    role: b2bRoles.product.tab.role,
    element: <B2BEmbedded />,
  },
} as const satisfies Routes;
