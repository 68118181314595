import { BankIconSVG } from "../financial-institution-icon.types";

export const BankCaixaSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#2577AC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.812 57.676H65.05l13.867 22.966H58.676L44.812 57.676z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.914 54.148h20.918L86 31.278H65.082l-18.168 22.87zM26 80.78h20.915l18.167-23.148H44.164L26 80.78z"
        fill="#F6822A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.542 31h19.521L66.44 54.148H46.914L33.542 31z"
        fill="#fff"
      />
    </svg>
  );
};
