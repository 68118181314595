import { modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { corporateRouter } from "routes/corporate-router.context";
import { Collaterals } from "./collaterals.types";
import { RemoveCollateralConfirmationModal } from "./remove-collateral-confirmation-modal.component";

export const equipamentGrid = (id: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantia encontrada.",
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        alignment: "left",
      },
      {
        dataField: "manufacturer",
        caption: "Marca",
      },
      {
        dataField: "identification",
        caption: "Chassi/série",
      },
      {
        dataField: "manufacturingYear",
        caption: "Ano de fabricação",
        alignment: "left",
      },
      {
        dataField: "finame",
        caption: "FINAME",
        customizeText: ({ value }) => value || "Não possui",
      },
      {
        dataField: "unitPrice",
        caption: "Valor unitário",
        alignment: "left",
        customizeText: ({ value }) => masks.currency(value, "R$", "."),
      },
      {
        dataField: "storageLocation",
        caption: "Local de armazenamento",
        alignment: "center",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <>
            <DataGridAction
              actions={[
                {
                  label: "Editar",
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  route:
                    corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.update.path(
                      {
                        collateralId: data.id,
                        id,
                      },
                    ),
                },
                {
                  label: "Remover",
                  icon: { category: "orq", icon: "orq-cancel" },
                  onClick: () =>
                    modalManager.show(`remove-collateral-modal-${data.id}`),
                },
              ]}
            />
            <RemoveCollateralConfirmationModal collateralId={data.id} />
          </>
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Collaterals[number]>);
