import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { EmprestimosProdutoGestorLimitesByIdAndByCollateralId } from "../../../../../../../../routes/emprestimos.route-params";
import { AddCollateralPage } from "../../adicionar/add-collateral.page";

export const UpdateCollateralPage = () => {
  const { collateralId } =
    useParams<EmprestimosProdutoGestorLimitesByIdAndByCollateralId>();

  if (!collateralId) throw new Error("No collateralId");

  const {
    callService: getCollateral,
    value: collateral,
    loading: loadingCollateral,
  } = useServiceCall(service.hubCreditManager.getCollateralDetails);

  useEffect(() => {
    getCollateral(collateralId);
  }, [getCollateral, collateralId]);

  return (
    <AddCollateralPage
      collateral={collateral}
      loadingCollateral={loadingCollateral}
      isUpdating={!!collateralId}
    />
  );
};
