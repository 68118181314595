import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { RepublishWorkflowStatusButton } from "./republish-workflow-status-button.component";
import { workflowContingenciesGrid } from "./workflow-contingencies.grid";
import { DeleteLeadButton } from "./delete-lead-button.component";

export const WorkflowContingenciesPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const {
    callService: getWorkflowLeadsSummary,
    value: leadDetails,
    hasError,
    loading,
  } = useServiceCall(service.hubCreditManager.getWorkflowLeadsSummary);

  useEffect(() => {
    getWorkflowLeadsSummary(id);
  }, [getWorkflowLeadsSummary, id]);

  const dataSource = useMemo(
    () => [
      {
        description: "Republicar evento de atualização de status",
        component: <RepublishWorkflowStatusButton workflowId={+id} />,
      },
      {
        description: "Deletar lead",
        component: <DeleteLeadButton workflowId={+id} />,
      },
    ],
    [id],
  );

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Contingências" />}>
      <ContentTemplate
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os dados do lead."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getWorkflowLeadsSummary(id)} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={leadDetails}
        render={() => (
          <ODataGridGenerator
            grid={workflowContingenciesGrid}
            dataSource={dataSource}
          />
        )}
      />
    </DetailsTemplate>
  );
};
