import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useDiligenceStep } from "../../../../../../diligence-step.context";
import { EditStepPropertyForm } from "../../edit-step-property-modal.form";

export const useDocumentTypeSelector = () => {
  const {
    documentTypesCallService,
    documentTypesHasError,
    documentTypesLoading,
    documentTypesValue,
  } = useDiligenceStep();

  const { clearErrors, setError } = useFormContext<EditStepPropertyForm>();

  const getDocumentTypes = useCallback(async () => {
    const { success } = await documentTypesCallService();
    if (success) clearErrors("documentTypeId");
    else
      setError("documentTypeId", {
        message:
          "Erro ao buscar os tipos de documento. Clique no botão ao lado para tentar novamente.",
      });
  }, [documentTypesCallService, clearErrors, setError]);

  const documentTypes = useMemo(
    () =>
      documentTypesValue?.response.map<SelectSearchFieldOption<number>>(
        ({ label, id }) => ({
          label,
          value: id,
          customKey: id,
        }),
      ) ?? [],
    [documentTypesValue?.response],
  );

  return {
    documentTypes,
    getDocumentTypes,
    loading: documentTypesLoading,
    hasFetchError: documentTypesHasError,
  };
};
