import { OButton, OLoader } from "@maestro/react";
import { useState } from "react";
import { RowGap } from "../../../cards.styles";
import { IMiddleButtonIteration } from "../chargeback.types";
import { HtmlStringContent, QuestionContainer } from "./compose.styles";
import { IQuestionHtmlProps } from "./compose.types";

export const QuestionHtml = ({
  questionIndex,
  data,
  executeAction,
  isEditable,
}: IQuestionHtmlProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <QuestionContainer key={`${questionIndex}-question-container`}>
      <HtmlStringContent
        dangerouslySetInnerHTML={{ __html: data.title }}
      ></HtmlStringContent>
      {"actions" in data && isEditable && (
        <RowGap columnGap={80}>
          {(data.actions as Array<IMiddleButtonIteration>).map(
            (action, index) => {
              return (
                <>
                  {isLoading ? (
                    <OLoader />
                  ) : (
                    <OButton
                      key={`${questionIndex}-action-option-${index}`}
                      onClick={async () => {
                        setIsLoading(true);
                        await executeAction(action.id, undefined);
                        setIsLoading(false);
                      }}
                      dataAction={`chargeback:botao:${action.label}`}
                      dataLabel={action.label}
                    >
                      {action.label}
                    </OButton>
                  )}
                </>
              );
            },
          )}
        </RowGap>
      )}
    </QuestionContainer>
  );
};
