import {
  OConfirmationModal,
  OLoader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { useCustomer } from "contexts/customer";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { EstrategiaComercial } from "../../estrategia-comercial.types";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  estrategia: EstrategiaComercial;
  reload: () => void;
}

export const GridActions = ({ estrategia, reload }: GridActionsProps) => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const { removeCompanyEstrategia, setUnsetDefaultCompanyEstrategia, loading } =
    useGridActions(quickfinId, estrategia.id);

  const modalId = `remove-estrategia-${estrategia.id}-confirmation-modal`;

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-percent" },
              label: "Taxas",
              role: roles.recebiveis.customer
                .estrategiaComercialByEstrategiaIdTaxasViewPage.role,
              route:
                corporateRouter.routes.recebiveis.customer.estrategiaComercial.byEstrategiaId.taxas.view.path(
                  {
                    estrategiaId: estrategia.id,
                  },
                ),
            },
            {
              icon: { category: "far", icon: "fa-address-card" },
              label: "Cadastro de relacionamento",
              role: roles.recebiveis.customer
                .estrategiaComercialByEstrategiaIdOnboardingRelationshipSettingsPage
                .role,
              route:
                corporateRouter.routes.recebiveis.customer.estrategiaComercial.byEstrategiaId.onboardingRelationshipSettings.path(
                  {
                    estrategiaId: estrategia.id,
                  },
                ),
            },
            {
              icon: { category: "far", icon: "fa-sliders-h" },
              label: "Visualizar estratégia",
              role: roles.recebiveis.customer
                .estrategiaComercialByEstrategiaIdEstrategiaDetailsPage.role,
              route:
                corporateRouter.routes.recebiveis.customer.estrategiaComercial.byEstrategiaId.estrategiaDetails.path(
                  {
                    estrategiaId: estrategia.id,
                  },
                ),
            },
            {
              icon: { category: "far", icon: "fa-bookmark" },
              label: "Marcar como padrão",
              onClick: async () => {
                await setUnsetDefaultCompanyEstrategia();
                reload();
              },
              visible: !estrategia.padrao,
            },
            {
              icon: { category: "far", icon: "fa-bookmark" },
              label: "Desmarcar como padrão",
              onClick: async () => {
                await setUnsetDefaultCompanyEstrategia();
                reload();
              },
              visible: estrategia.padrao,
            },
            {
              icon: { category: "far", icon: "fa-trash" },
              label: "Excluir estratégia",
              onClick: () => modalManager.show(modalId),
            },
          ]}
        />
      )}
      <OConfirmationModal
        modalId={modalId}
        title="Excluir estratégia?"
        closeLabel="Cancelar"
        confirmLabel="Sim"
        onConfirm={async () => {
          modalManager.hide(modalId);
          await removeCompanyEstrategia();
          reload();
        }}
      >
        <OTypography>
          Certeza que deseja excluir a estratégia &ldquo;{estrategia.nome}
          &rdquo;?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
