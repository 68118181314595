import { OCol, ORFieldInput, ORFieldInputDate } from "@maestro/react";

export const SignatureRuleConfigsFields = () => {
  return (
    <>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInput
          dataAction="regra_assinatura:texto:valor_minimo"
          dataLabel="valor_minimo"
          id="minimumValue"
          name="minimumValue"
          tag="mask"
          maskOption={{ mask: Number }}
          label="Valor mínimo"
          placeholder="Preencher"
        />
      </OCol>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInput
          dataAction="regra_assinatura:texto:valor_maximo"
          dataLabel="valor_maximo"
          id="maximumValue"
          name="maximumValue"
          tag="mask"
          maskOption={{ mask: Number }}
          label="Valor máximo"
          placeholder="Preencher"
        />
      </OCol>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInputDate
          id="startingDateOfPermission"
          name="startingDateOfPermission"
          label="Data início"
          placeholder="Preencher"
          size="md"
        />
      </OCol>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInputDate
          id="endingDateOfPermission"
          name="endingDateOfPermission"
          label="Data fim"
          placeholder="Preencher"
          size="md"
        />
      </OCol>
    </>
  );
};
