import * as yup from "yup";
import { FormValuesType } from "./raven-form.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  type: yup.string().required("Este campo é obrigatório"),
  fileKey: yup.string().required("Este campo é obrigatório"),
  defaultTitle: yup.string(),
  variables: yup.string(),
});
