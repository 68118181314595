import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { getValueFromMap } from "utils/get-value-from-map";
import { ClerkProducts, QuickfinProducts } from "./_compose";
import { UnstyledUl } from "./products-list.styles";
import { productNameOverridesMap } from "./products-list.utils";

interface ProductsListProps {
  products: PostAdminCompaniesGetByTaxIdResponse["products"];
}

export const ProductsList = ({ products }: ProductsListProps) => {
  const clerkProducts = products.map(
    (product) =>
      getValueFromMap(productNameOverridesMap, product.name) ?? product.name,
  );

  return (
    <UnstyledUl>
      <ClerkProducts products={clerkProducts} />
      <QuickfinProducts clerkProducts={clerkProducts} />
    </UnstyledUl>
  );
};
