import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetDashboardSignersAmbevNfeResponse } from "services/fidc/models/responses";

export const signersTableGrid: ODataGridGeneratorConfig<
  GetDashboardSignersAmbevNfeResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhuma notificação encontrada",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} assinantes)",
    },
  },
  columns: [
    {
      caption: "Nome",
      dataField: "name",
    },
    {
      caption: "E-mail",
      dataField: "mail",
    },
  ],
};
