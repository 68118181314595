import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { Wallet } from "./wallets.types";
import { walletStatusMap, walletTypeMap } from "./wallets.utils";
import { WalletProvisionRequestActionButtons } from "./_compose";
import {
  walletProvisionRequestStatusFilters,
  walletTypeFilters,
} from "./wallets.filter-utils";
import { masks } from "@maestro/utils";

export const walletGrid: ODataGridGeneratorConfig<Wallet> = {
  datagrid: {
    noDataText: "Nenhuma requisição de wallet encontrada",
    remoteOperations: {
      paging: true,
      filtering: true,
    },
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "card.owner.name",
      caption: "Portador",
      allowSorting: false,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "card.owner.document",
      caption: "Documento do portador",
      allowSorting: false,
      allowHeaderFiltering: true,
      format: (value) => masks.cpf(value),
    },
    {
      dataField: "type",
      caption: "Wallet",
      headerFilter: {
        dataSource: walletTypeFilters,
      },
      allowSorting: false,
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellRender: ({ data }) => {
        const { label, type } = getValueFromMap(walletTypeMap, data.type) ?? {
          label: data.type,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      dataField: "card.printedName",
      caption: "Nome do cartão",
      allowSorting: false,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "card.last4Digits",
      caption: "4 dígitos",
      allowSorting: false,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "status",
      caption: "Status",
      headerFilter: {
        dataSource: walletProvisionRequestStatusFilters,
      },
      allowSorting: false,
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellRender: ({ data }) => {
        const { label, type } = getValueFromMap(
          walletStatusMap,
          data.status,
        ) ?? {
          label: data.status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        if (data.status !== "CREATED") return <></>;
        return (
          <WalletProvisionRequestActionButtons
            requestId={data.id}
            cardId={data.card.externalId}
          />
        );
      },
    },
  ],
  paging: {
    enabled: true,
    defaultPageSize: 15,
  },
};
