import { modalManager, OToastManager } from "@maestro/core";
import { cases } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";
import debounce from "lodash/debounce";
import {
  PatchCardsOwnerUpdateAddressBody,
  PostCardsOwnerRegisterAddressBody,
} from "services/bankinghub/models";
import {
  ManageDeliveryAddressForm,
  ManageDeliveryAddressFormModalId,
} from "./manage-delivery-address-form-modal.types";

interface UseManageDeliveryAddressFormModalProps {
  form: UseFormReturn<ManageDeliveryAddressForm>;
  ownerId?: string;
  address?: BankingHubCards.Address;
  handleUpdateAddressId: (addressId?: string) => void;
  resetAddressId: () => void;
}

export const useManageDeliveryAddressFormModal = ({
  form,
  ownerId,
  address,
  handleUpdateAddressId,
  resetAddressId,
}: UseManageDeliveryAddressFormModalProps) => {
  const [submitting, setSubmitting] = useState(false);

  const { callService: getAddress, loading: getAddressLoading } =
    useServiceCall(service.onboarding.getAddress);

  const { setValue, clearErrors, setError } = form;

  const loadAddress = useMemo(
    () =>
      debounce(async (zipCode: string | undefined) => {
        if (!zipCode) return;

        const { success, response } = await getAddress({ zipCode });

        if (!success)
          return setError("zipCode", {
            message: "Não foi possível carregar o endereço a partir do CEP",
          });

        if (!response.data) return;

        clearErrors("zipCode");

        const { cityName, neighborhood, stateCode, streetName } = response.data;

        setValue("street", cases.title(streetName));
        setValue("stateId", stateCode.toUpperCase());
        setValue("neighborhood", cases.title(neighborhood));
        setValue("city", cases.title(cityName));
      }, 500),
    [clearErrors, getAddress, setError, setValue],
  );

  const { callService: postCardsOwnerRegisterAddress } = useServiceCall(
    service.bankinghub.postCardsOwnerRegisterAddress,
  );

  const { callService: patchCardsOwnerUpdateAddress } = useServiceCall(
    service.bankinghub.patchCardsOwnerUpdateAddress,
  );

  const handleRegister = useCallback(
    async (body: PostCardsOwnerRegisterAddressBody) => {
      if (!ownerId) throw Error("Empty ownerId param value");
      return postCardsOwnerRegisterAddress(ownerId, body);
    },
    [ownerId, postCardsOwnerRegisterAddress],
  );

  const handleUpdate = useCallback(
    async (addressId: string, body: PatchCardsOwnerUpdateAddressBody) => {
      if (!ownerId) throw Error("Empty ownerId param value");
      return patchCardsOwnerUpdateAddress(ownerId, addressId, body);
    },
    [ownerId, patchCardsOwnerUpdateAddress],
  );

  const onFormSubmit = useCallback(
    async (values: ManageDeliveryAddressForm) => {
      try {
        if (!ownerId) {
          throw Error("Empty ownerId param value");
        }

        setSubmitting(true);

        resetAddressId();

        const body = {
          zipCode: values.zipCode.replace(/\.|-|\//g, ""),
          street: values.street,
          number: values.number,
          complement: values.complement,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.stateId,
          type: values.type,
        };

        const { success, response, error } = address
          ? await handleUpdate(address.id, body)
          : await handleRegister(body);

        if (!success) throw error;

        const newAddressId = response?.data?.id;
        handleUpdateAddressId(newAddressId);

        modalManager.hide(ManageDeliveryAddressFormModalId);
      } catch (error) {
        logger.warn(error);
        OToastManager.danger(
          address
            ? "Erro ao alterar dados do endereço."
            : "Erro ao registrar dados do endereço.",
        );
      } finally {
        setSubmitting(false);
      }
    },
    [
      ownerId,
      address,
      handleUpdate,
      handleRegister,
      resetAddressId,
      handleUpdateAddressId,
    ],
  );

  return {
    onFormSubmit,
    submitting,
    loadAddress,
    loadingAddress: getAddressLoading,
  };
};
