import { OCard, OCardBody, ODivider } from "@maestro/react";
import { DetailsWithoutCard } from "components/details-card";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { useStepAnalysisContext } from "../../step-analysis.context";
import { consumerUnitHeaderFields } from "./consumer-unit-header.details";
import { StepRelatedInfo, TinyLoader } from "./_compose";

export const ConsumerUnitHeader = () => {
  const { stepAnalysis } = useStepAnalysisContext();

  const { callService, loading, value } = useServiceCall(
    service.hubEnergy.getStepRelatedInfo,
  );

  useEffect(() => {
    callService({
      consumerUnitId: stepAnalysis.consumerUnit.relatedEntityId,
      stepType: stepAnalysis.stepType.name,
    });
  }, [
    callService,
    stepAnalysis.consumerUnit.relatedEntityId,
    stepAnalysis.stepType.name,
  ]);

  const hasDetails = !!value && !!Object.keys(value.response).length;

  return (
    <OCard>
      <OCardBody className="position-relative">
        {loading && <TinyLoader />}
        <DetailsWithoutCard
          value={stepAnalysis}
          fields={consumerUnitHeaderFields}
        />
        {hasDetails && (
          <>
            <ODivider size="xxs" className="my-4" />
            <StepRelatedInfo details={value.response} />
          </>
        )}
      </OCardBody>
    </OCard>
  );
};
