import { RouteErrorBoundary } from "components/error-boundary";
import { Outlet, useLocation } from "react-router-dom";
import { Route } from "routes/routes.types";
import { GlobalMenu } from "../global-menu";
import { MenuTabs, findActiveTab, useMenuTabs } from "../tab-menu";
import { ContentContainer, MenuContainer } from "./content.styles";
import { wrapElement } from "./content.utils";

interface MenusLayoutProps {
  route: Route;
  element: React.ReactNode;
  tabType: keyof MenuTabs;
}

export const MenusLayout = ({ route, tabType, element }: MenusLayoutProps) => {
  const location = useLocation();
  const menuTabs = useMenuTabs();

  const activeTab = findActiveTab(menuTabs[tabType], route.path, false);

  const hasSomeChildrenWithSamePath =
    !!route.children &&
    Object.values(route.children).some((child) => route.path === child.path);

  return (
    <div className="d-flex flex-grow-1">
      {!!route.children &&
        !hasSomeChildrenWithSamePath &&
        !(route.isFullScreen && activeTab?.path === location.pathname) && (
          <MenuContainer>
            <GlobalMenu />
          </MenuContainer>
        )}
      <div className="w-100 position-relative" style={{ minWidth: 0 }}>
        {hasSomeChildrenWithSamePath ? (
          <Outlet />
        ) : (
          <ContentContainer>
            <RouteErrorBoundary>
              {wrapElement(element)[tabType]}
            </RouteErrorBoundary>
          </ContentContainer>
        )}
      </div>
    </div>
  );
};
