import styled from "styled-components";

export const BigButton = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? "var(--theme-light-hover)" : "var(--theme-tertiary)"};
  border-radius: var(--border-radius-xs);
  color: ${({ disabled }) =>
    disabled ? "var(--theme-light-inverse)" : "var(--theme-light)"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 154px !important;
  padding: 0px 16px;
  &:hover {
    ${({ disabled }) =>
      !disabled && "background-color: var(--theme-tertiary-hover);"};
  }
  &:active {
    ${({ disabled }) =>
      !disabled && "background-color: var(--theme-tertiary-active);"};
  }
`;
