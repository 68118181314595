import { OToastManager } from "@maestro/core";
import { OIcon } from "@maestro/react";
import { CopyButtonStyle } from "./copy-button.style";

export interface CopyButtonProps {
  target: string;
  toasterMessage: string;
  action?: string;
  label?: string;
  size?: HTMLOIconElement["size"];
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  target,
  toasterMessage,
  action = "",
  label = "",
  size = "sm",
}) => {
  return (
    <CopyButtonStyle
      type="button"
      data-action={action}
      data-label={label}
      onClick={() => {
        navigator.clipboard.writeText(target);
        OToastManager.info(toasterMessage);
      }}
    >
      <OIcon category="orq" type="dark" icon="orq-edit-copy" size={size} />
    </CopyButtonStyle>
  );
};
