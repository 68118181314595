import { modalManager } from "@maestro/core";
import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { dataSource } from "./incorrect-orders-grid.component";
import { useExchangeProductIncorrectOrdersPage } from "./incorrect-orders.hook";
import { IncorrectOrdersModal } from "./modal-ordens-incorretas";
import { incorrectordersModalIds } from "./modal-ordens-incorretas/incorrect-orders-modal.utils";

export const ExchangeProductIncorrectOrdersPage = () => {
  const { grid, gridRef, selectedOrders } =
    useExchangeProductIncorrectOrdersPage();

  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Ordens incorretas" />}
        actions={
          <>
            {selectedOrders.length > 0 && (
              <>
                <OButton
                  dataAction="ordens_incorretas:botao:alterar_para_corrigido"
                  dataLabel="alterar_para_corrigido"
                  onClick={() =>
                    modalManager.show(incorrectordersModalIds.close)
                  }
                >
                  Alterar para corrigido
                </OButton>
                <OButton
                  dataAction="ordens_incorretas:botao:reativar_ordens_incorretas"
                  dataLabel="reativar_ordens_incorretas"
                  onClick={() =>
                    modalManager.show(incorrectordersModalIds.reopen)
                  }
                >
                  Reativar ordens incorretas
                </OButton>
              </>
            )}
          </>
        }
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
      >
        <ODataGridGenerator
          grid={grid}
          gridRef={gridRef}
          dataSource={dataSource}
        />
      </GridTemplate>
      <IncorrectOrdersModal
        orders={selectedOrders ?? []}
        gridRef={gridRef}
        closes
      />
      <IncorrectOrdersModal orders={selectedOrders ?? []} gridRef={gridRef} />
    </>
  );
};
