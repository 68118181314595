export interface BannerFormFields {
  title?: string;
  internalName?: string;
  badge?: string;
  bannerTemplateId?: number;
  isActive: string[];
  startingFrom?: string;
  endingAt?: string;
  accessRules: string[] | null;
}

export const bannerFormDefaultValues = {
  title: undefined,
  internalName: undefined,
  badge: undefined,
  isActive: [],
  bannerTemplateId: undefined,
  startingFrom: undefined,
  endingAt: undefined,
  accessRules: null,
} satisfies BannerFormFields;
