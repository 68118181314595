import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { service } from "services";
import { OToastManager, modalManager } from "@maestro/core";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { IconButton } from "components/icon-button";
import dayjs from "dayjs";
import { AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes } from "../../../../../routes/antecipacao-de-cartoes.route-params";
import { conciliacaoDetailsGrid } from "./extrato-liquidacao-posicao-details.grid";
import {
  ConciliacaoDetailsProvider,
  useConciliacaoDetailsContext,
} from "./extrato-liquidacao-posicao-details.context";
import { ConciliationDetail } from "./extrato-liquidacao-posicao-details.types";
import { ReportCardDay } from "../../_compose/report-card-day.component";
import { useExtratoLiquidacaoPosicaoDetails } from "./extrato-liquidacao-posicao-details.hook";
import { ConciliacaoDetailsModal, conciliacaoDetailsModalId } from "./_compose";

const ConciliacaoDetailsComponent = () => {
  const gridRef = useGridRef();

  const { maturityDate } =
    useParams<AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes>();

  const { maturityFilterDate } = useConciliacaoDetailsContext();

  const { extratoInfo } = useExtratoLiquidacaoPosicaoDetails();

  const formattedDate = `${dayjs(
    maturityFilterDate || maturityDate,
  ).date()} de ${dayjs(maturityFilterDate || maturityDate)
    .locale("PT-BR")
    .format("MMMM")} de ${dayjs(maturityFilterDate || maturityDate).format(
    "YYYY",
  )}`;

  const dataSource = useMemo(() => {
    if (maturityFilterDate || maturityDate) {
      return dataSourceCustomStoreGenerator<
        NonNullable<ConciliationDetail>[number]
      >(() =>
        service.recebiveisCartao
          .getConciliacaoDetail(maturityFilterDate || maturityDate || "")
          .then(({ data }) => {
            return data;
          })
          .catch(() => {
            const errorMessage = "Erro ao buscar dados de conciliação";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      );
    }
  }, [maturityDate, maturityFilterDate]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Detalhes da antecipação"
          description="
      Veja os detalhes das suas antecipações por dia, podendo selecionar uma data diferente para verificação."
        />
      }
      actions={
        <IconButton
          icon={{ category: "far", icon: "fa-search" }}
          onClick={() => modalManager.show(conciliacaoDetailsModalId)}
        >
          Abrir busca
        </IconButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ReportCardDay
        anticipatedAmount={extratoInfo.maturityAmountSum}
        disbursementAmount={extratoInfo.disbursementAmountSum}
        liquidatedAmount={extratoInfo.liquidatedAmountSum}
        vnpAmount={extratoInfo.balanceAmountSum}
        fee={extratoInfo.feeAmountSum}
        formattedDate={formattedDate}
        status={extratoInfo.status}
      />
      <ODataGridGenerator
        gridRef={gridRef}
        grid={conciliacaoDetailsGrid}
        dataSource={dataSource}
      />
      <ConciliacaoDetailsModal />
    </GridTemplate>
  );
};

export const ConciliacaoDetailsPage = () => {
  return (
    <ConciliacaoDetailsProvider>
      <ConciliacaoDetailsComponent />
    </ConciliacaoDetailsProvider>
  );
};
