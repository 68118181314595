const replacer = (_key: string, value: unknown) => value ?? "";

export const makeCsvFromObjectArray = (array: Record<string, unknown>[]) => {
  const header = Object.keys(array[0]);

  const lines = array.map((obj) =>
    header.map((field) => JSON.stringify(obj[field], replacer)).join(","),
  );

  const csv = [header.join(","), ...lines].join("\r\n");

  return csv;
};
