import { OCol, ORFieldInput, ORow, OTypography } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { GatekeeperRoleFormProps } from "./role-form.types";

export const GatekeeperRoleForm = ({ form }: GatekeeperRoleFormProps) => {
  return (
    <>
      <div className="d-flex flex-column gap-1 mb-4">
        <OTypography>
          <strong>Atenção:</strong> O nome de uma role deve seguir a seguinte
          formatação:
        </OTypography>
        <OTypography weight="500">[serviço]:[recurso].[ação]</OTypography>
        <OTypography>Ex: partner:OnboardingAgent.GetCustomer</OTypography>
      </div>

      <FormProvider {...form}>
        <form>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="type"
                name="type"
                dataAction="role:input:servico"
                dataLabel="servico"
                label="Serviço"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="resource"
                name="resource"
                dataAction="role:input:recurso"
                dataLabel="recurso"
                label="Recurso"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="action"
                name="action"
                dataAction="role:input:acao"
                dataLabel="acao"
                label="Ação"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="description"
                name="description"
                dataAction="role:input:descricao"
                dataLabel="descricao"
                label="Descrição"
              />
            </OCol>
          </ORow>
        </form>
      </FormProvider>
    </>
  );
};
