import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import { OToastManager, modalManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { service } from "services/service";
import { newFilterDefaultValues } from "./note-filter.form";
import {
  buildPayload,
  filtersToSearchParams,
  getSearchParamsNoteFilter,
  noteFilterModalId,
} from "./note-filter.utils";

export const useNoteFilter = () => {
  const initializedParams = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, rows] = useMemo(() => {
    const initiadedPage = searchParams.get("page");
    const initiadedRows = searchParams.get("rows");
    return [
      initiadedPage ? +initiadedPage : 1,
      initiadedRows ? +initiadedRows : 10,
    ];
  }, [searchParams]);
  const [searchParamsNoteFilter, setSearchParamsNoteFilter] = useState(
    getSearchParamsNoteFilter(searchParams, initializedParams.current),
  );

  const form = useForm({
    defaultValues: searchParamsNoteFilter,
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getSummary,
    value: summary,
    loading: loadingSummary,
  } = useServiceCall(service.quickfin.getReceivablesSummary);

  const {
    callService: getDataSource,
    value: dataSource,
    loading: loadingDataSource,
  } = useServiceCall(service.quickfin.getReceivablesTitles);

  useEffect(() => {
    const newSearchParamsNoteFilter = getSearchParamsNoteFilter(
      searchParams,
      initializedParams.current,
    );
    if (!isEqual(newSearchParamsNoteFilter, searchParamsNoteFilter)) {
      setSearchParamsNoteFilter(newSearchParamsNoteFilter);
      initializedParams.current = true;
    }
  }, [searchParams, searchParamsNoteFilter]);

  const setPage = (newPage: number) => {
    setSearchParams((prev) => {
      if (prev.has("page")) prev.delete("page");
      prev.append("page", newPage.toString());
      return prev;
    });
  };
  const setRows = (newRows: number) => {
    setSearchParams((prev) => {
      if (prev.has("rows")) prev.delete("rows");
      prev.append("rows", newRows.toString());
      return prev;
    });
  };

  const parsedPayload = useMemo(
    () => buildPayload(searchParamsNoteFilter),
    [searchParamsNoteFilter],
  );

  const resetFilter = () => {
    setSearchParams([
      ...Array.from(filtersToSearchParams(newFilterDefaultValues).entries()),
      ...Object.entries({ page: "1", rows: "10" }),
    ]);
    const parsedInitialBody = buildPayload(newFilterDefaultValues);
    reset(newFilterDefaultValues);
    getSummary(parsedInitialBody);
    getDataSource({ ...parsedInitialBody, page: 1, rows: 10 });
    modalManager.hide(noteFilterModalId);
  };

  const onSubmit = handleSubmit(async (values) => {
    setSearchParams([
      ...Array.from(filtersToSearchParams(values).entries()),
      ...Object.entries({
        page: "1",
        rows: rows.toString(),
      }),
    ]);
    modalManager.hide(noteFilterModalId);
  });

  const refetchData = () => {
    getSummary(parsedPayload);
    getDataSource({ ...parsedPayload, page, rows });
  };

  const exportAllData = useCallback(async () => {
    try {
      const { data: blob } = await service.quickfin.exportAllNotes(
        parsedPayload,
      );

      helpers.downloadBlobFile("recebiveis_nota_a_nota.xlsx", blob);
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [parsedPayload]);

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams([
        ...Array.from(filtersToSearchParams(newFilterDefaultValues).entries()),
        ...Object.entries({ page: "1", rows: "10" }),
      ]);
    } else {
      getSummary(parsedPayload);
    }
  }, [parsedPayload]);

  useEffect(() => {
    if (!searchParams.size) return;
    getDataSource({ ...parsedPayload, page, rows });
  }, [parsedPayload, page, rows]);

  return {
    dataSource: dataSource ? dataSource.receivables : [],
    loadingDataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  };
};
