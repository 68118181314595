import { masks } from "@maestro/utils";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useServiceCall } from "hooks/service-call";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  CommercialStrategy,
  GetAdminSettlementStrategiesBySettlementIdResponse,
  GetOdataCedentesCompaniesViewResponse,
  GetOdataEstrategiasComerciaisOdataResponse,
  ValueItem,
} from "services/quickfin/models";
import {
  PredicateFields,
  SelectedEntity,
} from "./settlement-strategy-search.types";
import { serviceMap } from "./settlement-strategy-search.utils";

export const useSettlementStrategySearch = (
  initialValue:
    | GetAdminSettlementStrategiesBySettlementIdResponse["estrategiaComercial"]
    | GetAdminSettlementStrategiesBySettlementIdResponse["cedente"],
  name: PredicateFields,
  filter: (input: string) => string,
) => {
  const [selected, setSelected] = useState<SelectedEntity>();

  const { callService, hasError, loading, value } = useServiceCall(
    getValueFromMap(serviceMap, name),
  );

  const outerForm = useFormContext();

  const {
    setValue: outerSetValue,
    formState: {
      errors: { [name]: outerError },
    },
  } = outerForm;

  const localForm = useForm<{ input: string | undefined }>();

  const {
    watch: localWatch,
    setError: localSetError,
    setValue: localSetValue,
    clearErrors: localClearErrors,
  } = localForm;

  const debouncedGetCompanies = useMemo(
    () => debounce(callService, 500),
    [callService],
  );

  const inputWatcher = localWatch("input");

  useEffect(() => {
    if (initialValue) {
      localSetValue(
        "input",
        "uniqueId" in initialValue
          ? `${initialValue.nome} - ${initialValue.uniqueId}`
          : `${initialValue.officialName} - ${initialValue.id}`,
      );
    }
  }, [initialValue, localSetValue]);

  const selectCompany = useCallback((id: string, label: string) => {
    setSelected({
      id,
      label,
    });
  }, []);

  useEffect(() => {
    if (inputWatcher && inputWatcher.length > 0) {
      debouncedGetCompanies({
        $filter: filter(inputWatcher),
        $top: 50,
      });
    }
  }, [debouncedGetCompanies, inputWatcher, filter]);

  useEffect(() => {
    if (hasError)
      localSetError("input", {
        message: "Erro ao buscar as empresas",
      });
  }, [hasError, localSetError]);

  useEffect(() => {
    outerSetValue(name, selected?.id);
  }, [selected, localSetValue, outerSetValue, name]);

  useEffect(() => {
    if (outerError?.message) {
      localSetError("input", {
        message: outerError.message as string,
      });
    } else {
      localClearErrors("input");
    }
  }, [outerError, localSetError, localClearErrors]);

  const options = useMemo(() => {
    if (!value) {
      return [];
    }
    return name === "estrategiaComercialId"
      ? (value as GetOdataEstrategiasComerciaisOdataResponse).value.map(
          (entity: CommercialStrategy) => {
            const label = `${entity.Nome} - ${entity.UniqueId}`;

            return {
              value: entity.Id,
              label,
              onSelect: () => selectCompany(entity.Id.toString(), label),
            };
          },
        ) ?? []
      : (value as GetOdataCedentesCompaniesViewResponse).value.map(
          (entity: ValueItem) => {
            const label = `${entity.OfficialName ?? ""} - ${masks.cnpj(
              entity.Cnpj,
            )}`;

            return {
              value: entity.Id,
              label,
              onSelect: () => selectCompany(entity.Id.toString(), label),
            };
          },
        ) ?? [];
  }, [name, selectCompany, value]);

  return {
    loading,
    localForm,
    options,
    selected,
  };
};
