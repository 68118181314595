import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ReporteDiarioRiscoCedenteField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.reporteDiarioRiscoCedente}
      name="reporteDiarioRiscoCedente"
      description="E-mail de notificação ao cedente das próximas datas de repasse"
    />
  );
};
