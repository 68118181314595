import { OToastManager } from "@maestro/core";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useCallback, useEffect, useState } from "react";
import { isAxiosError } from "axios";
import { service } from "services";
import { NotificationConfigurationOdataResponse } from "services/raven/models/responses/notification-configuration-odata-response";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { NotificationTypeOdataResponse } from "services/raven/models/responses/notification-types-odata.response";

export const notificationConfigurationMasterDetailGrid: ODataGridGeneratorConfig<NotificationConfigurationOdataResponse> =
  {
    datagrid: {
      filterRow: {
        visible: false,
      },
      editing: {
        allowDeleting: true,
        mode: "row",
        texts: {
          confirmDeleteMessage:
            "Tem certeza que deseja remover esta configuração?",
        },
      },
      onRowRemoved: async ({ data: { id } }) => {
        try {
          await service.raven.deleteNotificationConfiguration(id);
          OToastManager.success("Configuração removida com sucesso");
        } catch {
          OToastManager.danger("Erro ao remover a configuração");
        }
      },
    },
    columns: [
      {
        dataField: "channel",
        dataType: "string",
        caption: "Canal",
      },
      {
        dataField: "sendOnDefault?",
        dataType: "boolean",
        caption: "Enviado por padrão",
      },
      {
        dataField: "userOverridable?",
        dataType: "boolean",
        caption: "Usuário pode alterar a preferência",
      },
      {
        caption: "Ações",
        alignment: "center",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Detalhes",
                role: roles.ferramentas.product
                  .ravenNotificationConfigDetailsPage.role,
                route:
                  corporateRouter.routes.ferramentas.product.raven.notificacoes.gestao.configDetails.path(
                    { configurationId: data.id },
                  ),
              },
            ]}
          />
        ),
      },
    ],
  };

type NotificationConfigurationsMasterDetailProps =
  MasterDetailComponentProps<NotificationTypeOdataResponse>;

export const NotificationConfigurationMasterDetail = ({
  data: { data },
}: NotificationConfigurationsMasterDetailProps) => {
  const [responseData, setResponseData] = useState<
    NotificationConfigurationOdataResponse[]
  >([]);

  const { id } = data;

  const [loading, setLoading] = useState(false);

  const transformChannel = (channel: string) => {
    switch (channel) {
      case "email":
        return "E-mail";
      case "sms":
        return "SMS";
      case "push":
        return "Notificação Push";
      case "bell":
        return "Sininho";
      default:
        return channel;
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await service.raven.getNotificationConfigurationsByType(
        id,
      );

      const transformedData = response.data.data.map(
        (config: NotificationConfigurationOdataResponse) => ({
          ...config,
          channel: transformChannel(config.channel),
        }),
      );

      setResponseData(transformedData);
    } catch (err) {
      if (isAxiosError(err)) {
        const status = err.response?.status;
        if (status !== 404) {
          OToastManager.danger(
            "Não foi possível obter as configurações de notificação",
          );
        }
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ODataGridGenerator
      grid={notificationConfigurationMasterDetailGrid}
      dataSource={responseData}
      loading={loading}
    />
  );
};
