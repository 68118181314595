import * as yup from "yup";

export interface ExchangeGenericFieldsFormValues {
  name: string;
  description: string;
  mask: string;
  fxPendencyCodes?: string[];
}

export const exchangeGenericFieldsFormValidationFormSchema: yup.ObjectSchema<ExchangeGenericFieldsFormValues> =
  yup.object({
    name: yup.string().required("Campo obrigatório"),
    description: yup.string().required("Campo obrigatório"),
    mask: yup.string().required("Campo obrigatório"),
    fxPendencyCodes: yup.array().default([]),
  });
