import dayjs from "dayjs";
import * as yup from "yup";

export const optinDefaulValues = {
  taxId: "",
  brandNames: [],
  acquirerTaxIds: [],
  isRootTaxId: "yes",
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: dayjs("9999-12-31").format("YYYY-MM-DD"),
};

export const optinValidationSchema = yup.object({
  taxId: yup.string().trim().required("É necessário informar o CNPJ"),
  brandNames: yup
    .array()
    .required("É necessário selecionar ao menos uma bandeira")
    .min(1, "É necessário selecionar ao menos uma bandeira"),
  acquirerTaxIds: yup
    .array()
    .required("É necessário selecionar ao menos uma credenciadora")
    .min(1, "É necessário selecionar ao menos uma credenciadora"),
  isRootTaxId: yup.string().trim().required("É necessário marcar uma opção"),
  startDate: yup
    .date()
    .required("É necessário selecionar a data de início do contrato de optin"),
  endDate: yup
    .date()
    .required("É necessário selecionar a data de início do contrato de optin"),
});
