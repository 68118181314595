import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { CreditLineConfigurationForm } from "../_compose/credit-line-configuration-form.component";
import { useAddCreditLineConfiguration } from "./add-credit-line-configuration.hook";

export const AddCreditLineConfigurationPage = () => {
  const {
    createCreditLineConfiguration,
    form,
    loadingAddCreditLineConfiguration,
    loadingBookStrategies,
    loadingCreditLines,
    hasErrorBookStrategies,
    hasErrorCreditLines,
    getAllData,
    creditLines,
    bookStrategies,
  } = useAddCreditLineConfiguration();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Adicionar configuração de linha de crédito" />
      }
      actions={
        <LoadingButton
          loading={loadingAddCreditLineConfiguration}
          onClick={handleSubmit(createCreditLineConfiguration)}
          disabled={loadingBookStrategies || loadingCreditLines}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasErrorBookStrategies || hasErrorCreditLines}
        loading={loadingBookStrategies || loadingCreditLines}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os dados referentes às configurações das linhas de crédito."
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getAllData()} />
          </ErrorComponent>
        }
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <CreditLineConfigurationForm
                bookStrategies={bookStrategies}
                creditLines={creditLines}
                hasErrorBookStrategies={hasErrorBookStrategies}
                hasErrorCreditLines={hasErrorCreditLines}
                loadingBookStrategies={loadingBookStrategies}
                loadingCreditLines={loadingCreditLines}
              />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
