import { OCard, OCardBody, ODivider, OTypography } from "@maestro/react";
import { MasterDetailComponentProps } from "components/data-grid";
import { StyledPre } from "./jobs.styles";
import { Job } from "./jobs.types";

type JobsMasterDetailProps = MasterDetailComponentProps<Job>;

export const JobsMasterDetail = ({
  data: {
    data: { args, errors },
  },
}: JobsMasterDetailProps) => {
  return (
    <OCard>
      <OCardBody>
        <div>
          <OTypography size="lg" weight="700">
            Argumentos
          </OTypography>
          <StyledPre>{JSON.stringify(args, null, 2)}</StyledPre>
        </div>
        <ODivider size="xxs" type="dark" className="my-3" />
        <div>
          <OTypography size="lg" weight="700">
            Erros
          </OTypography>
          {errors.map((error) => (
            <div key={error.at} className="mt-4">
              <OTypography weight="700">
                Tentativa {error.attempt} ({error.at})
              </OTypography>
              <StyledPre>{error.error}</StyledPre>
            </div>
          ))}
        </div>
      </OCardBody>
    </OCard>
  );
};
