import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetContactByContactHolderResponseItem } from "services/admin-bankinghub/models";
import { gridStorage } from "utils/storage";
import { ContactsMasterDetail } from "./contacts.master-detail";

export const contactsGrid = () =>
  ({
    datagrid: {
      noDataText: "Nenhuma conta encontrada",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      stateStoring: gridStorage("contactsGrid"),
    },
    columns: [
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "document_number",
        caption: "Documento",
      },
      { dataField: "person_type", caption: "Tipo" },
      {
        dataField: "same_ownership",
        caption: "Mesma Titularidade",
      },
    ],
    masterDetail: {
      enabled: true,
      component: ContactsMasterDetail,
    },
  } satisfies ODataGridGeneratorConfig<GetContactByContactHolderResponseItem>);
