import { OButton, OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoBatchRequestById } from "../../../../../../routes/workflow.route-params";
import { batchRowsGrid } from "./_compose/batch-rows/batch-rows.grid";
import { workflowLeadBatchRequestDetailsGenerator } from "./batch-request-details.details";

export const BatchRequestDetailsPage = () => {
  const { id } = useParams<WorkflowProdutoBatchRequestById>();

  if (!id) throw new Error("No id");

  const gridRef = useGridRef();

  const grid = useMemo(() => batchRowsGrid(Number(id)), [id]);

  const {
    callService: getWorkflowLeadsBatchRequestsDetails,
    value: batchRequestDetails,
    hasError,
    loading: loadingLeadDetails,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowLeadsBatchRequestsDetails,
  );

  const downloadBatchFile = useCallback(async () => {
    try {
      if (!batchRequestDetails)
        return OToastManager.danger("Nenhum arquivo encontrado.");

      const { data: blob } = await service.hubCreditManager.download({
        key: batchRequestDetails.file,
      });
      helpers.downloadBlobFile(batchRequestDetails.name, blob);

      OToastManager.success("Download realizado com sucesso!");
    } catch {
      OToastManager.danger(
        "Não foi possível baixar o arquivo. Tente novamente",
      );
    }
  }, [batchRequestDetails]);

  useEffect(() => {
    getWorkflowLeadsBatchRequestsDetails(id);
  }, [getWorkflowLeadsBatchRequestsDetails, id]);

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate
        pageTitle={<PageTitle title="Detalhes do Processamento" />}
      >
        <div className="d-flex justify-content-end pt-3 gap-3">
          <OButton
            className="pb-3"
            onClick={downloadBatchFile}
            dataAction="envio-lote-detalhes:botao:download-arquivo"
            dataLabel="download-template"
          >
            Download do arquivo enviado
          </OButton>
        </div>
        <DetailsCard
          loading={loadingLeadDetails}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível carregar os detalhes do processamento."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton
                onClick={() => getWorkflowLeadsBatchRequestsDetails(id)}
              />
            </ErrorComponent>
          }
          hasError={hasError}
          value={batchRequestDetails}
          fields={workflowLeadBatchRequestDetailsGenerator}
        />
      </DetailsTemplate>
      <GridTemplate
        pageTitle={<PageTitle title="Erros encontrados na planilha" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator gridRef={gridRef} grid={grid} />
      </GridTemplate>
    </div>
  );
};
