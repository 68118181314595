import { StepRenderProps } from "../../banner-form.types";
import { Campaign } from "../campaign";
import { Creation } from "../creation";
import { Summary } from "../summary";

export interface StepTimelineProps {
  step?: string;
}

export interface StepsMapType {
  id: string;
  title: string;
  subtitle: string;
  content: React.FC<StepRenderProps>;
}

export const stepsMap: Record<string, StepsMapType> = {
  CRIACAO: {
    id: "CRIACAO",
    title: "Criação do banner",
    subtitle:
      "Escolha o tipo da campanha antes de começar a personalizar o banner. Em seguida, confira no exemplo do banner para saber como ficará o texto.",
    content: Creation,
  },
  CAMPANHA: {
    id: "CAMPANHA",
    title: "Configuração da campanha",
    subtitle:
      "Informe o período em que a campanha ficará ativa. Se necessário, você vai poder editar e excluir a campanha a qualquer momento.",
    content: Campaign,
  },
  RESUMO: {
    id: "RESUMO",
    title: "Resumo da solicitação",
    subtitle:
      "Confira resumo da campanha que será criada e, se estiver de acordo, continue.",
    content: Summary,
  },
};
