/* eslint-disable react/no-array-index-key */
import { ONotification, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { PaymentUtils, StatusBadge } from "../../../../_compose";
import {
  PaymentStatusProps,
  StatusDescriptionProps,
} from "./payment-status.types";
import { assembleStatusDescription } from "./payment-status.utils";

export const PaymentStatus = ({ paymentDetail }: PaymentStatusProps) => {
  const { badgeType, status, icon } = useMemo(() => {
    return PaymentUtils.assemblePaymentStatus(paymentDetail);
  }, [paymentDetail]);

  const { description, type, customBackgroundColor }: StatusDescriptionProps =
    useMemo(() => {
      return assembleStatusDescription(paymentDetail);
    }, [paymentDetail]);

  const descriptionsWithoutEmptyText = useMemo(() => {
    return description.filter((item) => item.trim() !== "");
  }, [description]);

  if (!descriptionsWithoutEmptyText) return null;

  return (
    <>
      <StatusBadge
        badgeType={badgeType}
        icon={icon && { ...icon }}
        status={status}
      />

      {!!description.length && (
        <ONotification
          key={descriptionsWithoutEmptyText.length}
          type={type}
          className="d-flex align-items-center"
          style={{ backgroundColor: customBackgroundColor }}
        >
          <>
            {descriptionsWithoutEmptyText?.map((text, index) => {
              const orderList = index + 1;
              return (
                <>
                  {descriptionsWithoutEmptyText.length > 1 ? (
                    <OTypography key={`${index}-${text}`} type="dark">
                      {`${orderList}. ${text}`}
                    </OTypography>
                  ) : (
                    <OTypography key={`${index}-${text}`} type="dark">
                      {text}
                    </OTypography>
                  )}
                </>
              );
            })}
          </>
        </ONotification>
      )}
    </>
  );
};
