import { OLoader } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { PageTitle } from "components/page-title";
import { ContractsGrid } from "../../../../components";
import { useToolsGenerateTerm } from "./generate-term.hook";

export const ToolsGenerateTerm = () => {
  const { createAccountOpeningContracts, gridFilter, loading } =
    useToolsGenerateTerm();

  return (
    <ContractsGrid
      filter={gridFilter}
      isFullGrid={false}
      pageTitle={
        <PageTitle
          title="Geração de termos para abertura de conta"
          description="Iremos gerar um contrato com os termos de abertura de conta. Após a solicitação, verifique na aba de termos."
        />
      }
      actions={
        <ButtonWithConfirmation
          confirmationModal={{
            title: "Confirma a geração de termos para abertura de conta?",
            confirmLabel: "Sim",
            closeLabel: "Não",
            divider: false,
            size: "xs",
            loading,
          }}
          dataAction="formulario-ferramentas-geracao-de-termos:botao:solicitar"
          dataLabel="solicitar"
          className="align-self-end"
          onConfirm={createAccountOpeningContracts}
        >
          <div className="d-flex flex-row gap-2">
            {loading && <OLoader size="sm" />}
            Solicitar
          </div>
        </ButtonWithConfirmation>
      }
    />
  );
};
