import { client } from "../client";
import { endpoints } from "../endpoints";
import {
  DeleteAgreementResponse,
  GetAgreementDetailsResponse,
  GetAgreementsResponse,
  GetContractsResponse,
  PostAgreementFirstPaymentBody,
  PostAgreementsBody,
  PostAgreementsResponse,
  PostContractAvailabilityDatesBody,
  PostContractAvailabilityDatesReponse,
  PostSimulateBody,
  PostSimulateResponse,
} from "./models";

export const renegotiationService = {
  getContracts() {
    return client.get<GetContractsResponse>(
      endpoints.renegotiation.salesforce.contracts.endpoint,
    );
  },

  postContractAvailabilityDates(body: PostContractAvailabilityDatesBody) {
    return client.post<PostContractAvailabilityDatesReponse>(
      endpoints.renegotiation.salesforce.contracts.availableDates,
      body,
    );
  },
  postSimulate(body: PostSimulateBody) {
    return client.post<PostSimulateResponse>(
      endpoints.renegotiation.salesforce.simulate,
      body,
    );
  },
  postAgreement(body: PostAgreementsBody) {
    return client.post<PostAgreementsResponse>(
      endpoints.renegotiation.salesforce.accept,
      body,
    );
  },
  getAgreements() {
    return client.get<GetAgreementsResponse>(
      endpoints.renegotiation.salesforce.agreements.endpoint,
    );
  },
  getAgreementDetails(agreementId: string) {
    return client.get<GetAgreementDetailsResponse>(
      endpoints.renegotiation.salesforce.agreements.byId.endpoint(agreementId),
    );
  },
  postAgreementFirstPayment(
    agreementId: string,
    body: PostAgreementFirstPaymentBody,
  ) {
    return client.post(
      endpoints.renegotiation.salesforce.agreements.byId.firstPayment(
        agreementId,
      ),
      body,
    );
  },
  deleteAgreement(agreementId: string) {
    return client.delete<DeleteAgreementResponse>(
      endpoints.renegotiation.salesforce.agreements.byId.endpoint(agreementId),
    );
  },
};
