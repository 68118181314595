import { FileDataItem } from "services/bankinghub/models";
import {
  CorporateTax,
  GroupedYears,
  InitialMonthKeyQuarters,
  Quarter,
  QuartersOfYearType,
} from "./corporate-tax.types";

export const identifyQuarters = (
  quarters: FileDataItem[],
): (FileDataItem | undefined)[] => {
  const quarterMonthsKey = Object.values(InitialMonthKeyQuarters);
  return quarterMonthsKey.map((monthKey) => {
    const fileData = quarters.find((quarter) => {
      const initialDateMonthKey = new Date(quarter.dtInicio).getMonth();

      return initialDateMonthKey === monthKey;
    });
    return fileData;
  });
};

export const groupByYearsQuarters = (
  groupedYears: GroupedYears,
): CorporateTax => {
  const entries = Object.entries(groupedYears);
  return Object.fromEntries(
    entries.map(([year, quarters]) => {
      const [first, second, third, fourth] = identifyQuarters(quarters);

      const data: QuartersOfYearType = {
        [Quarter.First]: first,
        [Quarter.Second]: second,
        [Quarter.Third]: third,
        [Quarter.Fourth]: fourth,
      };
      return [year, data];
    }),
  );
};

export const groupByYear = (
  quarters: FileDataItem[],
): Record<string, FileDataItem[]> => {
  return quarters.reduce<Record<string, FileDataItem[]>>((groups, quarter) => {
    const newGroups = groups;

    const year = new Date(quarter.dtInicio).getFullYear().toString();
    const isFirstYearQuarterRecord = !Object.keys(newGroups).includes(year);
    const value = isFirstYearQuarterRecord
      ? [quarter]
      : newGroups[year].concat(quarter);

    newGroups[year] = value;

    return newGroups;
  }, {});
};
