import { OCard, OCardBody, OTypography } from "@maestro/react";

interface OccurrencesCardProps {
  occurrences: HubOnboardingRelationships.Guarantor.Error[];
}

export const OccurrencesCard = ({ occurrences }: OccurrencesCardProps) => {
  return (
    <OCard>
      <OCardBody>
        {occurrences.length ? (
          <ul>
            {occurrences.map((occurrence) => (
              <li
                key={`${occurrence.entityId}${occurrence.propertyName}${occurrence.errorMessage}`}
              >
                {occurrence.errorMessage}
              </li>
            ))}
          </ul>
        ) : (
          <OTypography type="default">Nenhuma ocorrência</OTypography>
        )}
      </OCardBody>
    </OCard>
  );
};
