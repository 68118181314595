import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { adminACCContingencySettlementsODataGrid } from "./admin-contingency-acc-settlements-odata.grid";

export const AdminContingencyACCSettlementsPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => adminACCContingencySettlementsODataGrid, []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Liquidações" />}
      gridRef={gridRef}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.acc.product.contingency.settlements.create
              .path
          }
          role={roles.acc.product.settlementsContingencyFormPage.role}
        >
          Criar rascunho
        </RouterButton>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
