import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useWorkflowConfigField } from "./workflow-config-field.hook";

export const WorkflowConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorConfigId, processorPropertyId, name, description } =
    processorConfigProperty;
  const { chidWorkflowConfigs, loading } = useWorkflowConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const workflowConfigField = useMemo(() => {
    const fieldId = `properties.${name}_${processorPropertyId}`;

    return (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={chidWorkflowConfigs
            ?.map((config) => config.identification)
            .join("")}
          id={fieldId}
          name={fieldId}
          placeholder="Selecione"
          label={description}
        >
          {chidWorkflowConfigs?.map((config) => (
            <OOption key={config.identification} value={config.identification}>
              {config.name}
            </OOption>
          ))}
        </ORFieldSelect>
        {loading && <OLoader />}
      </div>
    );
  }, [processorPropertyId, processorConfigId, chidWorkflowConfigs, loading]);

  return <>{workflowConfigField}</>;
};
