import { OButton, OCol, OIcon, ORFieldInput, ORow } from "@maestro/react";
import { UseFieldArrayReturn } from "react-hook-form";
import { CedenteDetailsForm } from "../../cedente-details.types";

interface ContractComplementProps {
  fieldArray: UseFieldArrayReturn<
    CedenteDetailsForm,
    "contractComplement",
    "id"
  >;
  index: number;
}

export const ContractComplementField = ({
  fieldArray,
  index,
}: ContractComplementProps) => {
  const { remove } = fieldArray;

  return (
    <div className="d-flex align-items-center gap-4 flex-fill">
      <ORow className="flex-fill">
        <OCol xs={6}>
          <ORFieldInput
            id={`variable.${index}`}
            name={`contractComplement.${index}.key`}
            tag="text"
            placeholder="Nome da variável no template"
            label="Variável"
          />
        </OCol>
        <OCol xs={6}>
          <ORFieldInput
            id={`value.${index}`}
            name={`contractComplement.${index}.value`}
            tag="text"
            placeholder="Valor de substituição"
            label="Valor"
          />
        </OCol>
      </ORow>
      <OButton type="danger" onClick={async () => remove(index)}>
        <OIcon category="fa" icon="fa-trash" />
      </OButton>
    </div>
  );
};
