import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import dayjs from "dayjs";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { GetProductAdminResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { FidcImportPossibleFormTypes } from "./import-fidc.types";
import { formToRenderMap } from "./import-fidc.utils";
import { fidcImportPossibleFormValidationSchema } from "./import-fidc.form";

export const useImportFidc = () => {
  const [dataSource, setDataSource] = useState(
    new DataSource<Fidc.Vehicle>({
      store: new ArrayStore({
        data: [],
      }),
    }),
  );
  const [products, setProducts] = useState<GetProductAdminResponse>();
  const [vehicleList, setVehicleList] = useState<Fidc.Vehicle[]>([]);
  const form = useForm<FidcImportPossibleFormTypes>({
    resolver: yupResolver(fidcImportPossibleFormValidationSchema),
  });
  const { control, handleSubmit, reset, setValue } = form;
  const watchSelectedProduct = useWatch({ control, name: "selectedProduct" });
  const buyerTaxIdWatcher = useWatch({ control, name: "buyerTaxId" });
  const dueDateParamsWatcher = useWatch({
    control,
    name: ["gracePeriod", "installments", "operationDate"],
  });

  function getVehicleId() {
    return "10000000-1000-4000-8000-100000000000".replace(/[01]/g, () =>
      Math.floor(Math.random() * 16).toString(16),
    );
  }

  const getImportProducts = useCallback(async () => {
    try {
      const { data } = await service.fidc.getImportProducts();

      setProducts(data);
    } catch {
      OToastManager.danger("Erro ao buscar os fundos de FIDC");
    }
  }, []);

  useEffect(() => {
    getImportProducts();
  }, [getImportProducts]);

  const getGuarantor = useCallback(async () => {
    if (!buyerTaxIdWatcher) {
      return;
    }

    const cleanValue = buyerTaxIdWatcher.replace(/\D/g, "");

    if (cleanValue.length !== 14) {
      return;
    }

    try {
      const { data } = await service.fidc.getGuaranteeByTaxId(cleanValue);
      setValue("guarantorTaxId", data.guarantorTaxId);
    } catch {
      OToastManager.warning("Fiador não encontrado ou não existe");
    }
  }, [buyerTaxIdWatcher, setValue]);

  const getDueDate = useCallback(async () => {
    if (
      !dueDateParamsWatcher[0] ||
      !dueDateParamsWatcher[1] ||
      !dueDateParamsWatcher[2]
    ) {
      return;
    }

    const params = {
      operationDate: dueDateParamsWatcher[2].format("MM/DD/YYYY"),
      gracePeriod: dueDateParamsWatcher[0].toString(),
      installments: dueDateParamsWatcher[1].toString(),
    };

    try {
      const { data } = await service.fidc.getDueDate(params);
      setValue("dueDate", data.dueDate);
    } catch {
      OToastManager.warning("Erro ao calcular tempo de carência");
    }
  }, [dueDateParamsWatcher, setValue]);

  useEffect(() => {
    getGuarantor();
    getDueDate();
  }, [getDueDate, getGuarantor]);

  const onFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = (e) => {
      const { firstSheetJson } = readBufferAsSpreadsheet<Fidc.Vehicle>(
        e?.target?.result ?? "",
      );
      setVehicleList(firstSheetJson);
    };
  };

  useEffect(() => {
    if (vehicleList.length > 0) {
      setDataSource(
        new DataSource<Fidc.Vehicle>({
          store: new ArrayStore({
            data: vehicleList,
          }),
        }),
      );
    }
  }, [vehicleList]);

  const resetAll = () => {
    setVehicleList([]);
    reset(formToRenderMap[watchSelectedProduct].defaultValues);
    setDataSource(
      new DataSource<Fidc.Vehicle>({
        store: new ArrayStore({
          data: [],
        }),
      }),
    );
  };

  const onSubmit = handleSubmit(async (values) => {
    const assets = vehicleList.map((vehicle) => {
      return {
        id: getVehicleId(),
        licensePlate: vehicle.Placa,
        manufacturingYear: vehicle["Ano Fabricação"],
        modelYear: vehicle["Ano Modelo"],
        ownerTaxId: vehicle["CNPJ Proprietário"],
        value: vehicle.Valor,
      };
    });
    const covenant =
      values.selectedProduct === "simpar-compra-e-venda"
        ? {
            brandNew: values.brandNew ?? false,
            buyerTaxId: values.buyerTaxId?.replace(/\D/g, "") ?? "",
            contractDate: dayjs(values.contractDate, "MM/DD/YYYY").toString(),
            dueDate: dayjs(values.dueDate, "MM/DD/YYYY").toString(),
            gracePeriod: values.gracePeriod ?? 0,
            guarantorTaxId: values.guarantorTaxId?.replace(/\D/g, "") ?? "",
            installments: values.installments ?? 1,
            isBuyerGuarantee: values.isBuyerGuarantee ?? false,
            operationDate: dayjs(
              values?.operationDate?.toString(),
              "MM/DD/YYYY",
            ).toString(),
            originalDate: dayjs(
              values?.originalDate?.toString(),
              "MM/DD/YYYY",
            ).toString(),
            sellerTaxId: values.sellerTaxId?.replace(/\D/g, "") ?? "",
            type: values.type ?? "",
          }
        : {
            buyerTaxId: values.buyerTaxId?.replace(/\D/g, "") ?? "",
            dueDate: dayjs(values.dueDate).format("MM/DD/YYYY"),
            faceValue: values?.faceValue ?? 0,
            gracePeriod: values.gracePeriod ?? 0,
            guarantorTaxId: values.guarantorTaxId?.replace(/\D/g, "") ?? "",
            installments: values.installments ?? 1,
            operationDate: dayjs(values?.operationDate?.toString()).format(
              "MM/DD/YYYY",
            ),
            sellerTaxId: values.sellerTaxId?.replace(/\D/g, "") ?? "",
            type: values.type ?? "",
          };

    await service.fidc.postImportBatch({
      assets,
      covenant,
      productName: watchSelectedProduct,
    });
  });

  return {
    dataSource,
    form,
    onFileUpload,
    onSubmit,
    products,
    resetAll,
    watchSelectedProduct,
    setVehicleList,
    vehicleList,
  };
};
