import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ScoreModuleForm } from "../_compose/score-module-form.component";
import {
  scoreModuleFormDefaultValues,
  scoreModuleFormValidationSchema,
} from "../_compose/score-module-form.schema";

export const AddScoreModulePage = () => {
  const form = useForm({
    resolver: yupResolver(scoreModuleFormValidationSchema),
    defaultValues: scoreModuleFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Adicionar módulo" />}>
      <FormProvider {...form}>
        <ScoreModuleForm mode="add" />
      </FormProvider>
    </FormTemplate>
  );
};
