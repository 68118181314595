import { OTypography } from "@maestro/react";
import styled from "styled-components";

export const StyledText = styled.span`
  text-decoration: underline;
`;

export const StyledCount = styled(OTypography)`
  .o-typography {
    padding: 0 0.05rem 0.15rem 0;
  }
`;
