import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { companyGroupsGrid, dataSource } from "./company-groups.grid";

export const CompanyGroups = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Agrupamento de CNPJs"
          description="Configure os grupos de CNPJs cujas agendas devem ser registradas e operadas sob uma mesma matriz."
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={companyGroupsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
