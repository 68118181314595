import { IDescriptionCardInfo } from "./description.types";

export const DescriptionCardInfo: Array<IDescriptionCardInfo> = [
  { label: "Total Gastos Líquidos", key: "paidBalance" },
  {
    label: "Valor do Fechamento",
    key: "totalBalance",
  },
  {
    label: "Saldo em Aberto",
    key: "remainingBalance",
  },
  {
    label: "Pagamento Mínimo",
    key: "minimumPayment",
  },
];
