import { OButton } from "@maestro/react";

interface ClearErrorButtonProps {
  clearError: () => void;
}

export const ClearErrorButton = ({ clearError }: ClearErrorButtonProps) => {
  return (
    <OButton
      dataAction="error_boundary:botao:tentar_novamente"
      dataLabel="tentar_novamente"
      outline
      type="default"
      className="mt-3"
      onClick={() => {
        clearError();
      }}
    >
      Tentar novamente
    </OButton>
  );
};
