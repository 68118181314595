import {
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { CollateralFormValues } from "../add-collateral-form.schema";

export const CDBFormComponent = () => {
  const form = useFormContext<CollateralFormValues>();

  const { setValue, watch } = form;

  const optionalWatcher = watch("hasOptional");

  return (
    <>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="identification"
            name="identification"
            label="Identificador"
            tag="text"
          />
        </OCol>

        <OCol sm={12} md={6}>
          <ORFieldSelect
            id="lockDisbursement"
            name="lockDisbursement"
            label="Bloqueia desembolso"
          >
            <OOption value="PRE">PRE</OOption>
            <OOption value="POS">POS</OOption>
          </ORFieldSelect>
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12}>
          <ORFieldInput
            id="description"
            name="description"
            label="Descrição"
            tag="text"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12}>
          <div className="d-flex align-items-center gap-2">
            <OCheckbox
              id="hasOptional"
              name="hasOptional"
              size="xs"
              value="true"
              onClick={() => setValue("hasOptional", !optionalWatcher)}
            />
            <OLabel htmlFor="hasOptional">Opcional</OLabel>
          </div>
        </OCol>
      </ORow>
    </>
  );
};
