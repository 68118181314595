import { CycleStatus, IInvoice } from "../cards.types";

export interface IInvoicesProps {
  invoicesData: Array<IInvoice>;
  accountId: string;
  selectedCompany: string;
  openContestations: string[];
  setOpenContestations: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

export interface IInvoiceInfo {
  key: string;
  label: string;
}

export interface ICycleStatusData {
  title: string;
  color: string;
}

export const MapperCycleStatus: Record<CycleStatus, ICycleStatusData> = {
  CLOSED: { title: "Fechada", color: "#646464" },
  PARTIALLY_PAID: { title: "Parcialmente paga", color: "#de8e1b" },
  PAID: { title: "Paga", color: "#057738" },
  OPEN: { title: "Aberta", color: "#646464" },
  FUTURE: { title: "Futura", color: "#646464" },
  OVERDUE: { title: "Atrasada", color: "#a31437" },
  INSTALLMENT: { title: "Parcelada", color: "#de8e1b" },
};

enum ItemCode {
  ITEM_ON_CASH_AMOUNT = "ITEM_ON_CASH_AMOUNT",
  ITEM_INSTALLMENT_AMOUNT = "ITEM_INSTALLMENT_AMOUNT",
  ITEM_PREVIOUS_INVOICE = "ITEM_PREVIOUS_INVOICE",
  ITEM_PAYMENTS = "ITEM_PAYMENTS",
  ITEM_CREDITS_SPECIAL_IOF = "ITEM_CREDITS_SPECIAL_IOF",
  ITEM_CREDITS_LOUNGE_KEY = "ITEM_CREDITS_LOUNGE_KEY",
  ITEM_REVERSAL = "ITEM_REVERSAL",
}

export interface IItem {
  amount: string;
  code: ItemCode;
  title: string;
}

enum CategoriesCode {
  CATEGORY_NATIONAL_TRANSACTIONS = "CATEGORY_NATIONAL_TRANSACTIONS",
  CATEGORY_PREVIOUS_BALANCE_AND_CREDITS = "CATEGORY_PREVIOUS_BALANCE_AND_CREDITS",
}

export interface ICategories {
  amount: number;
  code: CategoriesCode;
  items: Array<IItem>;
  title: string;
}

export interface ISummary {
  amount: number;
  categories: Array<ICategories>;
  title: string;
}

export interface IInvoiceDetails {
  bestTransactionDay: string;
  canDownloadInvoice: boolean;
  canPay: boolean;
  chargebackBalance: number;
  creditAccountId: number;
  cycleStatus: CycleStatus;
  dueDate: string;
  invoiceId: number;
  paidBalance: number;
  paymentDate: string;
  remainingBalance: number;
  summary: Array<ISummary>;
  totalBalance: number;
}
