import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ContractTemplateDetailsForm } from "../../../../components/contract-templates/contract-template-details-form.component";
import {
  ContractTemplateDetailsFormValues,
  contractTemplateDetailsFormValidationSchema,
} from "../../../../components/contract-templates/contract-template-details-form.schemas";

export const AddContractTemplate = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm<ContractTemplateDetailsFormValues>({
    resolver: yupResolver(contractTemplateDetailsFormValidationSchema),
  });

  const { handleSubmit } = form;

  const save = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.quickfin.createPublicContractTemplate({
            name: values.name,
            signatureOrigin: values.signatureOrigin,
            file: {
              originalName: values.file[0].name,
              fileContent: await helpers.convertFileToBase64(values.file[0]),
            },
            needsSignature: !!values.needsSignature?.length,
            geraNovosTermos: !!values.geraNovosTermos?.length,
            hasComplement: !!values.hasComplement?.length,
            signingParties: [
              {
                partyType: "Sacado",
                ...values.signingParties?.sacado,
                companyId: 0,
                parties: values.signingParties?.sacado?.parties ?? [],
                validFor: values.signingParties?.sacado?.validFor ?? [],
              },
              {
                partyType: "Cedente",
                ...values.signingParties?.cedente,
                companyId: 0,
                parties: values.signingParties?.cedente?.parties ?? [],
                validFor: values.signingParties?.cedente?.validFor ?? [],
              },
            ],
          });

          OToastManager.success("Template criado com sucesso.");
        } catch {
          OToastManager.danger("Não foi possível salvar o template.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar template" />}
      actions={
        <LoadingButton
          dataAction="recebiveis_adicionar_template_contrato:botao:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={save}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ContractTemplateDetailsForm />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
