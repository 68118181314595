import { OProgressBar } from "@maestro/react";
import styled from "styled-components";

export const OProgressBarStyled = styled(OProgressBar)`
  & > div.o-progress-bar {
    height: var(--font-xxs);
  }
`;

export const ProgressContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15rem;
`;

export const StyledText = styled.div`
  text-wrap: nowrap;
`;
