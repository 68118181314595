import { DetailsFields } from "components/details-card";
import { FxBeneficiaryResponse } from "services/hubfx/models/types/beneficiary/beneficiary.model";

export const exchangeBeneficiaryDetailsGenerator = (
  beneficiary: FxBeneficiaryResponse | null,
) => {
  if (!beneficiary) return {} as DetailsFields;

  return {
    beneficiary: {
      title: "Beneficiário",
      items: [
        {
          label: "Nome",
          value: beneficiary?.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: beneficiary?.businessType?.description,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    benefitiaryAccount: {
      title: "Conta beneficiário",
      items: [
        {
          label: "Nº Conta",
          value: beneficiary?.account?.details?.accountNumber,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: beneficiary?.account?.currency?.description,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: beneficiary?.account?.country?.description,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    beneficiaryBank: {
      title: "Banco Beneficiário",
      items: [
        {
          label: "Banco Beneficiário",
          value: beneficiary?.beneficiaryBank?.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.beneficiaryBank?.swift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.beneficiaryBank?.fedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    intermediateBank: {
      visible: !!beneficiary?.intermediateBank?.name,
      title: "Banco Intermedário",
      items: [
        {
          label: "Banco Intermedário",
          value: beneficiary?.intermediateBank?.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.intermediateBank?.swift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.intermediateBank?.fedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
