import * as yup from "yup";

export interface AddCreditLineConfigurationFormValues {
  creditLineId: number;
  partner: string | null;
  shouldSyncGuarantors: number;
  automaticallyApproveOnFts: number;
  associateGuarantorMinimumShare: number | null;
  maximumLimitForSimpleGuarantor: number | null;
  bookStrategyId: number | null;
  collateralTypes: string[];
  agreementId: string;
  fundingType: string;
  creditLineVisibleName: string;
  creditLineDescription: string;
  creditLineIcon: string;
}

export interface UpdateCreditLineLineConfigurationFormValues
  extends AddCreditLineConfigurationFormValues {
  id: number;
}

export const creditLineConfigurationFormValidationSchema = yup.object().shape({
  creditLineId: yup.number().required("Campo obrigatório"),
  partner: yup
    .string()
    .transform((value) => (value === "" ? null : value))
    .length(14, "Este campo deve ser vazio ou ")
    .nullable(),
  shouldSyncGuarantors: yup.number(),
  automaticallyApproveOnFts: yup.number(),
  associateGuarantorMinimumShare: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when("shouldSyncGuarantors", {
      is: (value: number) => !!value,
      then: (associateGuarantorMinimumShare: yup.NumberSchema) =>
        associateGuarantorMinimumShare
          .required("Campo obrigatório")
          .moreThan(0, "Este campo deve ser maior do que 0"),
      otherwise: (associateGuarantorMinimumShare: yup.NumberSchema) =>
        associateGuarantorMinimumShare.nullable(),
    }),
  maximumLimitForSimpleGuarantor: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when("shouldSyncGuarantors", {
      is: (value: number) => !!value,
      then: (maximumLimitForSimpleGuarantor: yup.NumberSchema) =>
        maximumLimitForSimpleGuarantor
          .required("Campo obrigatório")
          .moreThan(0, "Este campo deve ser maior do que 0"),
      otherwise: (maximumLimitForSimpleGuarantor: yup.NumberSchema) =>
        maximumLimitForSimpleGuarantor.nullable(),
    }),
  bookStrategyId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  collateralTypes: yup.array().of(yup.string()),
});

export const creditLineConfigurationFormDefaultValues = {
  creditLineId: NaN,
  partner: "",
  shouldSyncGuarantors: 0,
  automaticallyApproveOnFts: 0,
  associateGuarantorMinimumShare: NaN,
  maximumLimitForSimpleGuarantor: NaN,
  bookStrategyId: NaN,
  collateralTypes: [] as string[],
  creditLineVisibleName: "",
  creditLineDescription: "",
  creditLineIcon: "",
};
