import { MaestroFields } from "utils/types";
import { VigencyForm } from "./vigency-select-modal.types";

export const vigencyFields = {
  vigencyDate: {
    dataAction: "formulario:texto:vigencia",
    dataLabel: "vigencia",
    id: "vigencyDate",
    name: "vigencyDate",
    label: "Vigência",
    placeholder: "Selecionar",
    required: true,
  },
} as const satisfies MaestroFields<VigencyForm>;
