import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridActionV2 } from "components/datagrid-action";
import "devextreme/ui/text_area";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { getHubEnergyErrorMessage } from "../../../components/deprecated/error/error-code.utils";
import { energiaRoles } from "../../../roles/energia.roles";

export const energyCommonDomainGrid = {
  datagrid: {
    noDataText: "Nenhuma variável de configuração encontrada.",
    dataSource: dataSourceCustomStoreGenerator(
      () =>
        service.hubEnergy
          .getCommonDomain()
          .then(({ data }) => data.response ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar variáveis de configuração");
          }),
      {
        customStoreOptions: {
          insert: async (values) => {
            try {
              await service.hubEnergy.postCommonDomain(values);

              OToastManager.success("Dado adicionado com sucesso.");

              return values;
            } catch (error) {
              OToastManager.danger(getHubEnergyErrorMessage(error));
              throw new Error("Um erro ocorreu ao tentar adicionar.");
            }
          },
          update: async (originalData, updatedData) => {
            const data = { ...originalData, ...updatedData };
            try {
              await service.hubEnergy.postCommonDomain(data);

              OToastManager.success("Dados atualizados com sucesso.");
            } catch (error) {
              OToastManager.danger(getHubEnergyErrorMessage(error));
            }
          },
          remove: async (values) => {
            try {
              await service.hubEnergy.deleteCommonDomain(
                values.group,
                values.key,
              );

              OToastManager.success("Dado removido com sucesso.");
            } catch (error) {
              OToastManager.danger(getHubEnergyErrorMessage(error));
            }
          },
        },
      },
    ),
    editing: {
      mode: "form",
    },
    headerFilter: { visible: true },
    filterRow: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("energyCommonDomainGrid"),
    columnAutoWidth: false,
  },
  columns: [
    {
      sortIndex: 0,
      sortOrder: "asc",
      dataField: "group",
      caption: "Grupo",
      validationRules: [{ type: "required" }],
      width: "auto",
    },
    {
      sortIndex: 1,
      sortOrder: "asc",
      dataField: "key",
      caption: "Chave",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "value",
      caption: "Valor",
      formItem: {
        editorType: "dxTextArea",
      },
      editorOptions: {
        autoResizeEnabled: true,
      },
      allowHeaderFiltering: false,
      cssClass: "dx-word-wrap",
    },
    {
      dataField: "isPublic",
      caption: "Público",
      width: "auto",
    },
    {
      dataField: "createdDate",
      caption: "Data de criação",
      dataType: "date",
      format: "shortDateShortTime",
      allowEditing: false,
      formItem: {
        visible: false,
      },
      width: "auto",
    },
    {
      dataField: "updatedDate",
      caption: "Última atualização",
      dataType: "date",
      format: "shortDateShortTime",
      sortOrder: "desc",
      allowEditing: false,
      formItem: {
        visible: false,
      },
      width: "auto",
    },
    {
      formItem: {
        visible: false,
      },
      caption: "Ações",
      role: energiaRoles.product.editGridCommonDomain.role,
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar",
              onClick: () => {
                component.editRow(rowIndex);
              },
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              onClick: () => {
                component.option(
                  "editing.texts.confirmDeleteMessage",
                  `Você confirma que a variável '${data?.group} - ${data?.key}' será removida?`,
                );
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
      width: "auto",
    },
  ],
} satisfies ODataGridGeneratorConfig<HubEnergy.AdminCommonDomainResponse>;
