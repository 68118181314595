import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { MleByBillingReportIdRouteParams } from "../../routes/energia.route-params";
import { invoicesDataSource, invoicesGrid } from "./invoices.grid";

export const Invoices = () => {
  const { billingReportId } = useParams<MleByBillingReportIdRouteParams>();
  if (!billingReportId) throw new Error("No billingReportId");

  const gridRef = useGridRef();
  const dataSource = invoicesDataSource(billingReportId);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Faturamentos" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={invoicesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
