import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface DistributorConfigForm {
  communicationType: string;
  portalUrl: string;
  contacts: ContactForm[];
}

export interface ContactForm {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  isMain: boolean;
}

export const distributorConfigFormFields = {
  communicationType: {
    id: "communicationType",
    name: "communicationType",
    label: "Método de envio da carta denúncia",
    placeholder: "Selecionar",
    required: true,
  },
  portalUrl: {
    id: "portalUrl",
    name: "portalUrl",
    tag: "text",
    label: "Portal da distribuidora",
    placeholder: "Preencher",
  },
} as const satisfies MaestroFields<DistributorConfigForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const distributorConfigFormValidationSchema = yup.object<
  DistributorConfigForm,
  YupObjectSchema<DistributorConfigForm>
>({
  communicationType: stringRequired,
});

export const distributorConfigFormDefaultValues = {
  communicationType: null,
  portalUrl: null,
  contacts: [],
} satisfies Partial<
  Nullable<DistributorConfigForm>
> as unknown as DistributorConfigForm;
