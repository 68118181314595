import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCancelableReceivablesResponse } from "services/quickfin/models";

export const cancelingModalGrid: ODataGridGeneratorConfig<
  PostAdminCancelableReceivablesResponse["cancelableReceivables"][number]
> = {
  datagrid: {
    noDataText: "Nenhum título disponível.",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showInfo: true,
      infoText: "Página {0} de {1} ({2} títulos)",
    },
  },
  columns: [
    {
      dataField: "sacado",
      caption: "Sacado",
    },
    {
      dataField: "cedente",
      caption: "Cedente",
    },
    {
      dataField: "valorVencimento",
      caption: "Valor vencimento",
      cellRender: ({ data }) => masks.currency(data.valorVencimento),
    },
  ],
};
