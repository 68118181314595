import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import dayjs from "dayjs";
import { endpoints } from "services/endpoints";
import { gridStorage } from "utils/storage";
import { OBadge, OIcon, ORow, OCol } from "@maestro/react";
import { corporateRouter } from "routes/corporate-router.context";
import { StandardLink } from "components/standard-link";
import { getValueFromMap } from "utils/get-value-from-map";
import { Operation } from "./operations.types";
import { operationStatusMap, operationTintMap } from "./operations.utils";

export const dataSource = dataSourceODataStoreGenerator<Operation>(
  endpoints.quickfin.odata.operationsOData,
  {
    odataStoreOptions: {
      beforeSend: ({ params }) => {
        // eslint-disable-next-line no-param-reassign
        params.tipoProduto = "Cartao";
      },
    },
  },
);

const buildDetailsLink = (operation: Operation) =>
  corporateRouter.routes.antecipacaoDeCartoes.product.operations.details.path({
    companyId: operation.CompanyId,
    operationId: operation.Id,
  });

export const operationsGrid: ODataGridGeneratorConfig<Operation> = {
  datagrid: {
    noDataText: "Nenhuma operação.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    grouping: {
      expandMode: "rowClick",
      contextMenuEnabled: true,
    },
    height: 900,
    stateStoring: gridStorage("creditCardReceivablesOperationsGrid"),
    exportGrid: {
      onExporting: ({ component }) => {
        component?.beginUpdate();
        component?.columnOption("ValorDesembolsoAntecipado", "visible", true);
        component?.columnOption("ValorVencimentoAntecipado", "visible", true);
        component?.columnOption("ValorSolicitado", "visible", true);
        component?.columnOption("ValorDesembolsoRecusado", "visible", true);
        component?.columnOption("ValorVencimentoRecusado", "visible", true);
        component?.columnOption("ValorDesembolsoPendente", "visible", true);
        component?.columnOption("ValorVencimentoPendente", "visible", true);
        component?.columnOption("TradesAntecipadosQtd", "visible", true);
        component?.columnOption("TradesRecusadosQtd", "visible", true);
        component?.columnOption("TradesPendentesQtd", "visible", true);
      },
      onExported: ({ component }) => {
        component?.columnOption("ValorDesembolsoAntecipado", "visible", false);
        component?.columnOption("ValorVencimentoAntecipado", "visible", false);
        component?.columnOption("ValorSolicitado", "visible", false);
        component?.columnOption("ValorDesembolsoRecusado", "visible", false);
        component?.columnOption("ValorVencimentoRecusado", "visible", false);
        component?.columnOption("ValorDesembolsoPendente", "visible", false);
        component?.columnOption("ValorVencimentoPendente", "visible", false);
        component?.columnOption("TradesAntecipadosQtd", "visible", false);
        component?.columnOption("TradesRecusadosQtd", "visible", false);
        component?.columnOption("TradesPendentesQtd", "visible", false);
        component?.endUpdate();
      },
    },
  },
  columns: [
    {
      caption: "#",
      dataField: "Id",
      allowHeaderFiltering: true,
      cellRender: ({ data }) => (
        <StandardLink href={buildDetailsLink(data)}>{data.Id}</StandardLink>
      ),
    },
    {
      dataField: "SacadoName",
      dataType: "string",
      caption: "Sacado",
      allowHeaderFiltering: false,
      width: 200,
    },
    {
      dataField: "CedenteName",
      dataType: "string",
      caption: "Cedente",
      allowHeaderFiltering: false,
      width: 200,
    },
    {
      dataField: "PrazoMedio",
      dataType: "number",
      caption: "Prazo Médio",
      format: "#,##0.00",
      allowHeaderFiltering: false,
    },
    {
      dataField: "TaxaMensal",
      dataType: "number",
      caption: "Taxa Mensal",
      format: { type: "percent", precision: 4 },
      allowHeaderFiltering: false,
    },
    {
      dataField: "Status",
      dataType: "string",
      caption: "Situação",
      cellRender: ({ data }) => (
        <OBadge
          size="sm"
          type={
            getValueFromMap(operationTintMap, data.Status) || "warning-light"
          }
        >
          {getValueFromMap(operationStatusMap, data.Status) || data.Status}
        </OBadge>
      ),
      calculateCellValue: (data) =>
        getValueFromMap(operationStatusMap, data.Status) || data.Status,
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      dataField: "ValorDesembolsoAntecipado",
      dataType: "number",
      caption: "Valor desembolso sucesso",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "ValorVencimentoAntecipado",
      dataType: "number",
      caption: "Valor vencimento sucesso",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "ValorSolicitado",
      dataType: "number",
      caption: "Valor solicitado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "CreatedAt",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Criado em",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
      cellRender: ({ data }) =>
        dayjs(data.CreatedAt)
          .utc(true)
          .tz(dayjs.tz.guess())
          .format("DD/MM/YYYY, HH:mm"),
    },
    {
      dataField: "ValorDesembolsoRecusado",
      dataType: "number",
      caption: "Valor desembolso recusado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "ValorVencimentoRecusado",
      dataType: "number",
      caption: "Valor vencimento recusado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "ValorDesembolsoPendente",
      dataType: "number",
      caption: "Valor desembolso pendente",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "ValorVencimentoPendente",
      dataType: "number",
      caption: "Valor vencimento pendente",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "TradesAntecipadosQtd",
      dataType: "number",
      caption: "Trades sucesso",
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "TradesRecusadosQtd",
      dataType: "number",
      caption: "Trades recusados",
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      dataField: "TradesPendentesQtd",
      dataType: "number",
      caption: "Trades pendentes",
      allowHeaderFiltering: false,
      visible: false,
    },
    {
      caption: "Status dos Valores",
      minWidth: 300,
      cellRender: ({ data }) => (
        <table className="table table-sm">
          <tbody>
            <tr>
              <th>Valor solicitado</th>
              <td>{masks.currency(data.ValorSolicitado, "R$")}</td>
            </tr>
            <tr>
              <th>Valor registrado</th>
              <td className="d-flex">
                {masks.currency(data.ValorVencimentoAntecipado, "R$")}
                {data.ValorSolicitado !== data.ValorVencimentoAntecipado && (
                  <OIcon
                    category="orq"
                    icon="orq-status-warning"
                    type="warning"
                    className="ms-1"
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>Valor desembolsado</th>
              <td>{masks.currency(data.ValorDesembolsoAntecipado, "R$")}</td>
            </tr>
            <tr>
              <th>Desembolso pendente</th>
              <td>{masks.currency(data.ValorDesembolsoPendente, "R$")}</td>
            </tr>
          </tbody>
        </table>
      ),
      allowExporting: false,
    },
    {
      caption: "Divergência",
      calculateCellValue: (data) =>
        data.ValorSolicitado - (data.ValorVencimentoAntecipado ?? 0),
      format: (value: number) => masks.currency(value, "R$"),
      allowSorting: true,
    },
    {
      caption: "Trades",
      allowExporting: false,
      cellRender: ({ data }) => (
        <>
          <ORow className="mb-1">
            <OCol>
              <OBadge outline size="sm" type="success">
                {data.TradesAntecipadosQtd} desembolsados
              </OBadge>
            </OCol>
          </ORow>
          <ORow className="mb-1">
            <OCol>
              <OBadge outline size="sm" type="warning">
                {data.TradesPendentesQtd} pendentes
              </OBadge>
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <OBadge outline size="sm" type="danger">
                {data.TradesRecusadosQtd} recusados
              </OBadge>
            </OCol>
          </ORow>
        </>
      ),
    },
  ],
  filterPanel: {
    visible: true,
  },
};
