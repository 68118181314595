import dayjs from "dayjs";
import { PeriodDate } from "./request-for-reports.types";

export const calculateStartEndDate = (
  reportPeriod: string,
  reportStartDate?: string | null,
  reportEndDate?: string | null,
) => {
  const now = dayjs(new Date());
  const dateFormat = "YYYY-MM-DD";
  const endOfDay = now.endOf("day").format(dateFormat);

  if (reportPeriod === "CustomPeriod") {
    return {
      periodoDe: reportStartDate || "",
      periodoAte: reportEndDate || "",
    };
  }

  const periodMap: Record<string, PeriodDate> = {
    Today: {
      periodoDe: now.startOf("day").format(dateFormat),
      periodoAte: endOfDay,
    },
    LastSevenDays: {
      periodoDe: now.subtract(7, "days").format(dateFormat),
      periodoAte: endOfDay,
    },
    LastThirtyDays: {
      periodoDe: now.subtract(30, "days").format(dateFormat),
      periodoAte: endOfDay,
    },
    LastSixtyDays: {
      periodoDe: now.subtract(60, "days").format(dateFormat),
      periodoAte: endOfDay,
    },
    AllPeriod: {
      periodoDe: now.subtract(20, "years").format(dateFormat),
      periodoAte: endOfDay,
    },
  };

  return periodMap[reportPeriod];
};
