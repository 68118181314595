import { OCol, OLoader, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useLayoutEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useEligibilityTree } from "../../eligibility-tree.context";

export const VariantSelector = () => {
  const {
    form,
    getVariants,
    variants,
    variantsLoading: loading,
  } = useEligibilityTree();

  const {
    formState: {
      errors: { variant: variantError },
    },
    setValue,
  } = form;

  useLayoutEffect(() => {
    if (variants?.[0].id) {
      setValue("variant", String(variants[0].id));
    }
  }, [setValue, variants]);

  return (
    <ORow>
      <OCol xs={12} sm={6} align="center" className="gap-2">
        <FormProvider {...form}>
          <ORFieldSelect
            dataAction="selecionar_variante:select:selecionar_variante"
            dataLabel="selecionar_variante"
            id="variant"
            name="variant"
            label="Variante"
            disabled={!!variantError || loading}
            key={variants?.length}
          >
            {variants?.map((variant) => (
              <OOption key={variant.id} value={String(variant.id)}>
                {variant.variantName}
              </OOption>
            ))}
          </ORFieldSelect>
        </FormProvider>
        {loading && <OLoader />}
        {!!variantError && !loading && (
          <RefreshGridButton
            dataAction="selecionar_variante:botao:tentar_novamente"
            dataLabel="tentar_novamente"
            onClick={() => getVariants()}
          />
        )}
      </OCol>
    </ORow>
  );
};
