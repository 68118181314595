import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { InternacionalUserForm } from "./add-internacional-user.type";
import { addInternacionalUserValidationSchema } from "./add-internacional-user.schema";

export const useAddInternacionalUser = () => {
  const form = useForm<InternacionalUserForm>({
    resolver: yupResolver(addInternacionalUserValidationSchema),
  });

  const { getValues } = form;
  const navigate = useNavigate();

  const { callService: createInternacionalUser, loading: submitLoading } =
    useServiceCall(service.onboardingRelationships.createInternacionalUser);

  const submit = useCallback(async () => {
    const { ...values } = getValues();

    const { success } = await createInternacionalUser({
      ...values,
    });

    if (success) {
      OToastManager.success("Usuário internacional adicionado com sucesso");
      navigate(corporateRouter.routes.usuarios.customer.path);
    } else {
      OToastManager.danger("Erro ao adicionar o usuário internacional");
    }
  }, [createInternacionalUser, getValues, navigate]);

  return {
    form,
    loading: submitLoading,
    submit,
  };
};
