import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import type { Options } from "devextreme/data/data_source";
import { useMemo } from "react";
import { ContractsGrid } from "../../../../../components";

export const AdminTerms = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const gridFilter: Options = useMemo(
    () => ["CompanyCNPJ", "=", identification],
    [identification],
  );
  return (
    <ContractsGrid
      filter={gridFilter}
      isFullGrid
      pageTitle={<PageTitle title="Termos" />}
    />
  );
};
