import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import {
  registerFeesSacadoDefaultValues,
  registerFeesSacadoValidationSchema,
} from "./register-fees.form";
import { RegisterFeesForm } from "./register-fees.types";
import { buildPayload } from "./register-fees.utils";

export const useRegisterFeesSacado = () => {
  const [profitShare, setProfitShare] = useState(true);
  const { customer } = useCustomer();
  const navigate = useNavigate();
  const form = useForm<RegisterFeesForm>({
    defaultValues: registerFeesSacadoDefaultValues,
    resolver: yupResolver(registerFeesSacadoValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: getCompany, value: company } = useServiceCall(
    service.quickfin.getCompanyWithOnboarding,
  );

  const { callService: createFee, loading: loadingSubmit } = useServiceCall(
    service.turtlefee.createFee,
  );

  useEffect(() => {
    if (customer.quickfinId) {
      getCompany(customer.quickfinId);
    }
  }, [customer.quickfinId, getCompany]);

  const onSubmit = handleSubmit(async (values) => {
    if (!company || !company.cge) {
      OToastManager.danger(
        "Empresa deve estar com CGE cadastrado para obter e cadastrar Fees.",
      );
      return;
    }

    const payload = buildPayload(company.cge, profitShare, values);

    const { success } = await createFee(payload);

    if (success) {
      OToastManager.info("Taxa de rebate do sacado configurada com sucesso.");
      navigate(corporateRouter.routes.recebiveis.customer.feeSacado.path);
    } else {
      OToastManager.danger(
        "Houve um problema ao atualizar, tente novamente mais tarde.",
      );
    }
  });

  return {
    form,
    loadingSubmit,
    onSubmit,
    profitShare,
    setProfitShare,
  };
};
