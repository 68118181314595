import { OTypography } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { ACCPreticketByIdRouteParams } from "pages/acc/routes/acc.route-params";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { adminCustomerAccSettlementDetailsGenerator } from "./admin-customer-acc-settlements-simulate.details";
import {
  OProgressBarStyled,
  ProgressContent,
} from "./admin-customer-acc-settlements-simulate.styles";
import { formatTime } from "./admin-customer-acc-settlements-simulate.utils";

export const AdminCustomerACCSettlementSimulationPage = () => {
  const { id } = useParams<ACCPreticketByIdRouteParams>();

  if (!id) throw new Error("No id");

  const [timer, setTimer] = useState(60);

  const {
    value: simulateDetails,
    callService: postAccSimulateSettlement,
    hasError: hasErrorSimulateDetails,
    loading: loadingSimulateDetails,
  } = useServiceCall(service.acc.adminCustomer.postAccSimulateSettlement);

  useEffect(() => {
    postAccSimulateSettlement(id);
  }, [postAccSimulateSettlement, id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) setTimer(60);
  }, [timer]);

  useEffect(() => {
    if (!id) return;

    if (!timer) postAccSimulateSettlement(id);
  }, [timer, postAccSimulateSettlement, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Simulação da liquidação" />}>
      <ContentTemplate
        loading={loadingSimulateDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao simular liquidação."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => postAccSimulateSettlement(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorSimulateDetails}
        value={simulateDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <ProgressContent>
              <div className="d-flex justify-content-start align-items-center gap-2 w-100">
                <OTypography type="dark">
                  Prazo da cotação: {formatTime(timer)}
                </OTypography>
                <OProgressBarStyled
                  value={timer}
                  max={60}
                  className="w-20"
                />
              </div>
            </ProgressContent>
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccSettlementDetailsGenerator}
            />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
