import { customerQueryParam } from "components/access-control";
import { corporateRouter } from "routes/corporate-router.context";

export const buildCustomerLink = (
  identification: string,
  route: string = corporateRouter.routes.cadastro.customer.details.path,
) => {
  const params = new URLSearchParams();
  params.set(customerQueryParam, identification);
  return route + "?" + params.toString();
};
