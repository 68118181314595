import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { ApproveButton, DenyButton } from "./_compose";
import { buildDeskMigrationFields } from "./desk-migration-details.details";
import { migrationMovementsGrid } from "./desk-migration-details.grid";
import { useDeskMigrationDetails } from "./desk-migration-details.hook";

export const DeskMigrationDetails = () => {
  const gridRef = useGridRef();

  const { dataSource, deskMigration, hasError, loading, migrationId } =
    useDeskMigrationDetails();

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate
        pageTitle={<PageTitle title="Detalhes da migração" />}
        actions={
          <>
            <ApproveButton
              workflowMigrationId={migrationId}
              successCallback={dataSource.reload}
              disabled={
                deskMigration?.workflowMigration.actualMigrationStatus !==
                "PENDENTE_VALIDACAO"
              }
            />
            <DenyButton
              workflowMigrationId={migrationId}
              successCallback={dataSource.reload}
              disabled={
                deskMigration?.workflowMigration.actualMigrationStatus !==
                "PENDENTE_VALIDACAO"
              }
            />
          </>
        }
      >
        <DetailsCard
          loading={loading}
          hasError={hasError}
          value={deskMigration}
          fields={buildDeskMigrationFields}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível buscar os detalhes da migração"
              callback={() => dataSource.reload()}
            />
          }
        />
      </DetailsTemplate>
      <OTypography size="lg" className="mt-4">
        Movimentações
      </OTypography>
      <GridTemplate gridRef={gridRef} showRefreshButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={migrationMovementsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </div>
  );
};
