import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
    WorkflowBigNumbersById, WorkflowProdutoBigNumbersRouteQueryParms,
} from "pages/workflow/routes/workflow.route-params";
import { bigNumberFormDefaultValues, bigNumberFormValidationSchema } from "../../big-number-form.schema";
import { BigNumbersForm } from "../../big-number-form.component";

export const UpdateBigNumbersPage = () => {
  const { id } = useParams<WorkflowBigNumbersById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoBigNumbersRouteQueryParms>(
      "data",
    );
  const form = useForm({
    resolver: yupResolver(bigNumberFormValidationSchema),
    defaultValues: bigNumberFormDefaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    value && reset(value);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate pageTitle={<PageTitle title="Editar Big Number" />}>
          <FormProvider {...form}>
            <BigNumbersForm mode="edit" id={id} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};