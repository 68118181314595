import { OCard, OCardBody, ODivider, OTypography } from "@maestro/react";
import { getPropertyLabel, sortById } from "../../../../../../utils";
import { useStepAnalysisContext } from "../../step-analysis.context";
import { PendencySummaryBadge } from "../../_compose";

export const SummaryStage = () => {
  const { stepAnalysis, stages, setSelectedStageIndex } =
    useStepAnalysisContext();

  return (
    <OCard>
      <OCardBody>
        <div className="d-flex flex-column gap-4">
          {!!stepAnalysis.stepPendencies.length && (
            <div>
              <OTypography weight="500" size="lg" className="mb-4">
                Pendências da etapa
              </OTypography>
              <div className="d-flex flex-column gap-2">
                {sortById(stepAnalysis.stepPendencies).map((p) => (
                  <div
                    key={p.id}
                    className="d-flex align-items-center justify-content-between gap-2"
                  >
                    <OTypography>{p.type.label}</OTypography>
                    <ODivider size="xxs" className="flex-fill" />
                    <OTypography>
                      <PendencySummaryBadge
                        pendency={p}
                        level={stepAnalysis.level}
                        goToStage={() => setSelectedStageIndex(0)}
                      />
                    </OTypography>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!stepAnalysis.stepPendencies.length &&
            !!stepAnalysis.propertiesWithPendencies.length && (
              <ODivider size="xxs" />
            )}
          {!!stepAnalysis.propertiesWithPendencies.length && (
            <div>
              <OTypography weight="500" size="lg" className="mb-4">
                Propriedades
              </OTypography>
              <div className="d-flex flex-column gap-4">
                {sortById(stepAnalysis.propertiesWithPendencies).map(
                  (property) => {
                    const stageIndex = stages.findIndex(
                      (s) => s.key === property.id,
                    );

                    return (
                      <div key={property.id}>
                        <OTypography>{getPropertyLabel(property)}</OTypography>
                        {!!property.pendencies.length && (
                          <div className="ps-4 mt-2 d-flex flex-column gap-2">
                            {sortById(property.pendencies).map((p) => (
                              <div
                                key={p.id}
                                className="d-flex align-items-center justify-content-between gap-2"
                              >
                                <OTypography>{p.type.label}</OTypography>
                                <ODivider size="xxs" className="flex-fill" />
                                <OTypography>
                                  <PendencySummaryBadge
                                    pendency={p}
                                    level={stepAnalysis.level}
                                    goToStage={() =>
                                      setSelectedStageIndex(stageIndex)
                                    }
                                  />
                                </OTypography>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          )}
        </div>
      </OCardBody>
    </OCard>
  );
};
