import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasComParceirosByUuidRouteParams } from "../../../../../routes/ferramentas.route-params";
import { DeskPartnerForm } from "../_compose/desk-partner-form.component";
import {
  DeskPartnerFormValues,
  deskPartnerFormValidationSchema,
} from "../_compose/desk-partner.form";

export const UpdateDeskPartnerPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasComParceirosByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DeskPartnerFormValues>({
    resolver: yupResolver(deskPartnerFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskPartnerDetails,
    loading,
    hasError,
    value: deskPartner,
  } = useServiceCall(service.onboarding.getDeskOnboardingPartnerConfigDetails);
  const { callService: updateDeskPartner, loading: submitLoading } =
    useServiceCall(service.onboarding.updateDeskOnboardingPartnerConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskPartner({
          deskOnboardingPartnerConfigUuid: uuid,
          ...values,
        });

        if (!success)
          return OToastManager.danger(
            "Erro ao atualizar configuração com parceiro",
          );

        return OToastManager.success(
          "Configuração com parceiro atualizada com sucesso",
        );
      }),
    [handleSubmit, updateDeskPartner, uuid],
  );

  useEffect(() => {
    getDeskPartnerDetails(uuid);
  }, [getDeskPartnerDetails, uuid]);

  useEffect(() => {
    deskPartner &&
      reset({
        deskOnboardingConfigIdentifier:
          deskPartner.deskOnboardingConfigConfigIdentifier,
        ...deskPartner,
      });
  }, [deskPartner, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar configuração com pareciro" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar configuração"
            callback={() => getDeskPartnerDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskPartnerForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
