import * as yup from "yup";
import { hasJsonStructure } from "./test-condition.utils";

export const testConditionValidationFormSchema = yup.object().shape({
  workflow: yup
    .string()
    .required("Campo obrigatório")
    .test("valid-json", "O campo deve ter formato JSON", (value?: string) =>
      hasJsonStructure(value),
    ),
});
