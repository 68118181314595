import styled from "styled-components";

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
`;

export const GridRow = styled.div<{ templateColumns: string }>`
  display: grid;
  gap: 10px;
  grid-template-columns: ${(props) => props.templateColumns};
  grid-auto-rows: auto;
  grid-auto-flow: dense;

  @media screen and (max-width: 590px) {
    grid-template-columns: 100%;
  }
`;

export const GridColumn = styled.div<{ templateRows: string }>`
  display: grid;
  gap: 10px;
  grid-template-rows: ${(props) => props.templateRows};
  grid-auto-rows: auto;
  grid-auto-flow: dense;
`;
