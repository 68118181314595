import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { DetailsTemplate } from "templates/details-template";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse } from "services/quickfin/models";
import { service } from "services/service";
import { ContentTemplate } from "templates/content-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { FidcOperationsDetailsFields } from "./fidc-operations-details.details";
import { calculateMeanTerm } from "./fidc-operations-details.utils";
import { FidcOperationsDetailsGrid } from "./fidc-operations-details.grid";

interface OperationDetailsSearchParams {
  operationId: string;
  companyId: string;
}

export const FidcOperationsDetails = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [operation, setOperation] =
    useState<GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse>();

  const getOperation = useCallback(async () => {
    const { operationId, companyId } =
      getSearchParams<OperationDetailsSearchParams>();

    if (!operationId || !companyId) {
      return;
    }

    const params = { operationId, companyId };

    setLoading(true);
    setHasError(false);
    try {
      const { data } = await service.quickfin.getOperationDetails(params);
      setOperation(data);
    } catch {
      setHasError(true);
      OToastManager.danger("Erro ao buscar detalhes da operação");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getOperation();
  }, [getOperation]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da operação" />}>
      <DetailsCard
        loading={loading && !operation}
        hasError={hasError}
        value={operation}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da operação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getOperation()} />
          </ErrorComponent>
        }
        fields={FidcOperationsDetailsFields(
          operation,
          calculateMeanTerm(operation ? operation.trades : []),
        )}
      />
      <ContentTemplate
        loading={loading && !operation}
        hasError={hasError}
        value={operation?.trades}
        render={(_trades) => (
          <ODataGridGenerator
            grid={FidcOperationsDetailsGrid}
            dataSource={_trades}
          />
        )}
      />
    </DetailsTemplate>
  );
};
