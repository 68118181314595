import { modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { actionTextBuilder } from "../../../_utils";

export const removeActionConfirmationModalId = (actionId: number) =>
  `remove-action-confirmation-modal-${actionId}`;

interface RemoveActionConfirmationModalProps {
  action: HubEnergy.DiligenceEventActionSimpleResponse;
  remove: () => void;
}

export const RemoveActionConfirmationModal = ({
  action,
  remove,
}: RemoveActionConfirmationModalProps) => {
  const modalId = removeActionConfirmationModalId(action.id);

  return (
    <OConfirmationModal
      {...datagridModalFix}
      style={{ position: "absolute" }}
      modalId={modalId}
      title="Excluir ação"
      confirmLabel="Excluir"
      closeLabel="Cancelar"
      divider={false}
      onConfirm={() => {
        remove();
        modalManager.hide(modalId);
      }}
    >
      <OTypography>Tem certeza que deseja excluir a ação abaixo?</OTypography>
      <OTypography style={{ whiteSpace: "normal" }}>
        {actionTextBuilder(action)}
      </OTypography>
    </OConfirmationModal>
  );
};
