import * as PaymentsEnums from "services/api/models/types/payments/enums";
import { StatusDescriptionProps } from "./payment-status.types";

const getDetailErrors = (errors?: ApiPayments.PaymentError[]): string[] => {
  if (!errors?.length) return [];

  return errors.map((error) => error.detail);
};

const MapperPaymentStatusToStatusDescriptionProps: Record<
  ApiPayments.PaymentStatus,
  (item?: ApiPayments.PaymentError[]) => StatusDescriptionProps
> = {
  [PaymentsEnums.PaymentStatus.AwaitingApproval]: (item) => {
    return {
      type: "warning",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Started]: (item) => {
    return {
      type: "warning",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Created]: (item) => {
    return {
      type: "info",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Scheduled]: (item) => {
    return {
      type: "info",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Canceled]: (item) => {
    return {
      type: "danger",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Adjourned]: (item) => {
    return {
      type: "warning",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Dispatched]: (item) => {
    return {
      type: "info",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Processed]: (item) => {
    return {
      type: "info",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Failed]: (item) => {
    return {
      type: "default",
      description: getDetailErrors(item),
      customBackgroundColor: "#e5e5e5",
    };
  },
  [PaymentsEnums.PaymentStatus.Confirmed]: (item) => {
    return {
      type: "success",
      description: getDetailErrors(item),
    };
  },
  [PaymentsEnums.PaymentStatus.Reverted]: (item) => {
    return {
      type: "default",
      description: getDetailErrors(item),
      customBackgroundColor: "#e5e5e5",
    };
  },
  [PaymentsEnums.PaymentStatus.Temporized]: () => {
    return {
      type: "info",
      description: [
        "Por questões de segurança, essa operação está sendo analisada pelo BTG.",
      ],
    };
  },
};

export const assembleStatusDescription = (
  item: ApiPayments.PaymentData,
): StatusDescriptionProps => {
  return MapperPaymentStatusToStatusDescriptionProps[item.status](item.errors);
};
