import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { analysisLogsGrid, dataSource } from "./analysis-logs.grid";

export const AnalysisLogsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Logs de análise" />}
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={analysisLogsGrid}
      />
    </GridTemplate>
  );
};
