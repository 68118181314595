import { ODataGridGeneratorConfig } from "components/data-grid";
import { PreticketGenericField } from "services/hubfx/models/responses";

export const exchangePreTicketGenericFieldsGrid = {
  datagrid: {
    noDataText: "Nenhuma informação adicional",
  },
  columns: [
    {
      dataField: "typeName",
      caption: "Campo",
      allowHeaderFiltering: false,
    },
    {
      dataField: "value",
      caption: "Valor",
      allowHeaderFiltering: false,
    },
  ],
} satisfies ODataGridGeneratorConfig<PreticketGenericField>;
