import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useXMLDownloadButton = (s3Key: string) => {
  const { callService, hasError, loading, value } = useServiceCall(
    service.recebiveisCartao.downloadAgenda,
  );

  const download = useCallback(
    () => callService({ s3_key: s3Key }),
    [callService, s3Key],
  );

  useEffect(() => {
    if (value) helpers.downloadBlobFile(s3Key.replace(/\//g, "_"), value);
  }, [s3Key, value]);

  useEffect(() => {
    if (hasError)
      OToastManager.danger("Não foi possível fazer o download do arquivo");
  }, [hasError]);

  return { download, loading };
};
