import * as yup from "yup";
import { AddCedenteLimitForm } from "./add-cedente-limit.types";

export const addCedenteLimitDefaulValues = {
  cedenteId: null,
  limit: null,
  tipoProduto: null,
} satisfies Record<keyof AddCedenteLimitForm, unknown> as any;

export const addCedenteLimitValidationSchema: yup.ObjectSchema<AddCedenteLimitForm> =
  yup.object({
    cedenteId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("É necessário selecionar um cedente"),
    limit: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("É necessário digitar um limite"),
    tipoProduto: yup
      .string()
      .required("É necessário escolher um tipo de produto"),
  });
