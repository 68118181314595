import { OCol, OInputMask, OOption, ORow, OSelect } from "@maestro/react";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OTruncateTypography } from "../../../../../o-truncate-typography";
import {
  SelectableSearchSectionFilter,
  SelectableSearchValue,
} from "../../../../side-filter-bar.types";

type SelectableSearchProps = SelectableSearchSectionFilter;

export const SelectableSearch = ({ data, formId }: SelectableSearchProps) => {
  const [selectFilterParam, setSelectFilterParam] = useState<unknown>();
  const [inputValue, setInputValue] = useState<string>();
  const { formState, watch, setValue, setError, clearErrors, getFieldState } =
    useFormContext();

  const fieldValueWatched: SelectableSearchValue = watch(formId);

  const fieldState = getFieldState(formId, formState);

  useEffect(() => {
    if (!fieldValueWatched) return;
    const { value, filterParam } = fieldValueWatched;
    setInputValue(value);
    setSelectFilterParam(filterParam);
  }, [fieldValueWatched, setValue]);

  const activeOption = useMemo(() => {
    if (!selectFilterParam) return;
    return data.find((item) => item.filterParam === selectFilterParam);
  }, [data, selectFilterParam]);

  const activeOptionTitle = useMemo(() => {
    if (activeOption) return `${activeOption.label}`;
  }, [activeOption]);

  const [unmaskedValidator, inputValueFormatter] = useMemo(() => {
    const emptyValidator = () => true;
    const emptyFormatter = (value: string) => value;
    return [
      activeOption?.unmaskedValidator ?? emptyValidator,
      activeOption?.inputValueFormatter ?? emptyFormatter,
    ];
  }, [activeOption]);

  const onBlur = () => {
    try {
      if (!inputValue) {
        setValue(formId, undefined);
        return;
      }

      if (!unmaskedValidator(inputValue)) throw new Error("Invalid inputValue");

      setValue(formId, {
        filterParam: selectFilterParam,
        value: inputValue,
      });
    } catch {
      setError(formId, {
        message: "O valor inserido para busca não é válido.",
      });
    }
  };

  return (
    <div className="d-flex flex-column gap-3">
      <ORow justify="between">
        <OCol sm={4} title={activeOptionTitle}>
          <OSelect
            placeholder={
              activeOptionTitle ?? "Selecione a opção a ser filtrada"
            }
            style={{ backgroundColor: "transparent" }}
            aspect="flushed"
            readonly={data.length === 1}
            onInput={(e) => {
              const value = (e.target as HTMLOSelectElement)?.value;
              const isAValidNewValue = !!value && value !== selectFilterParam;
              if (!isAValidNewValue) return;
              setValue(formId, undefined);
              setSelectFilterParam(value);
              setInputValue("");
            }}
            value={`${selectFilterParam}`}
          >
            {data?.map((item) => (
              <OOption
                style={{ backgroundColor: "var(--theme-light)" }}
                title={item.label}
                key={`${item.filterParam}`}
                value={`${item.filterParam}`}
              >
                <OTruncateTypography>{item.label}</OTruncateTypography>
              </OOption>
            ))}
          </OSelect>
        </OCol>
        <OCol sm={8}>
          <OInputMask
            className="w-100"
            id="selectable_search"
            name="selectable_search"
            placeholder="Digite aqui para buscar"
            aspect="flushed"
            maskOption={activeOption?.maskOption}
            value={inputValue}
            onChangeMasked={({ detail }) => {
              const { unmaskedValue: value } = detail;
              setInputValue(inputValueFormatter(value));
              clearErrors(formId);
            }}
            onBlur={onBlur}
            error={fieldState.invalid}
          />
        </OCol>
      </ORow>
    </div>
  );
};
