import * as yup from "yup";

export const sacadoEditDetailsValidationSchema = yup.object({
  companyEntityType: yup.string().required("Campo obrigatório").nullable(),
  estrategiaId: yup.number().required("Campo obrigatório").nullable(),
  officialName: yup.string().required("Campo obrigatório").nullable(),
  permiteOverrideEstrategia: yup
    .string()
    .required("Campo obrigatório")
    .nullable(),
});

export const sacadoDetailsValidationSchema =
  sacadoEditDetailsValidationSchema.shape({
    country: yup
      .string()
      .required("Este campo é obrigatório")
      .test(
        "country",
        "País não pode ser Brasil quando Tipo do documento for Offshore",
        (value, context) => {
          return context.parent.companyEntityType === "Offshore" &&
            value === "BR"
            ? false
            : true;
        },
      )
      .test(
        "country",
        "Para Tipo do documento CPF ou CNPJ, País deve ser Brasil",
        (value, context) => {
          return context.parent.companyEntityType !== "Offshore" &&
            value !== "BR"
            ? false
            : true;
        },
      )
      .nullable(),
  });

export const sacadoDetailsDefaultValue = {
  tipoContrato: "SemContrato",
};
