import { OTypography } from "@maestro/react";

export interface EmptyStateProps {
  messageTitle?: string;
  messageParagraph?: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  messageTitle,
  messageParagraph,
  children,
  icon,
}) => {
  return (
    <div className="d-flex flex-column align-items-center gap-2">
      {icon}
      <div>
        {messageTitle && (
          <OTypography size="lg">
            {messageTitle}
          </OTypography>
        )}
        {messageParagraph && (
          <OTypography>
            {messageParagraph}
          </OTypography>
        )}
        {children}
      </div>
    </div>
  );
};
