import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const dataSource = dataSourceCustomStoreGenerator(() =>
  service.onboarding
    .getDesks()
    .then(({ data }) => data ?? [])
    .catch(() => {
      const validator = "Erro ao buscar as mesas";

      OToastManager.danger(validator);
      throw new Error(validator);
    }),
);

export const desksGrid: ODataGridGeneratorConfig<
  Awaited<ReturnType<typeof service.onboarding.getDesks>>["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma mesa",
    headerFilter: { visible: true },
    filterRow: { visible: true },
  },
  columns: [
    {
      role: roles.ferramentas.product.onboardingWorkflowsConfigsDesksDetailsPage
        .role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.desks.details.path(
            {
              deskCode: data.deskCode,
            },
          )}
        />
      ),
    },
    {
      dataField: "deskCode",
      caption: "Código",
    },
    {
      dataField: "deskName",
      caption: "Nome",
    },
    {
      dataField: "deskDescription",
      caption: "Descrição",
    },
  ],
};
