import * as yup from "yup";
import { FormValuesType } from "./step-config.types";

const requiredFieldMessage = "Este campo é obrigatório";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  name: yup.string().required(requiredFieldMessage),
  description: yup.string().required(requiredFieldMessage),
  pipelineUuid: yup.string().required(requiredFieldMessage),
  responseMessage: yup.string().required(requiredFieldMessage),
  responseMessageTypeCode: yup.string().required(requiredFieldMessage),
  shouldStopOnGreen: yup.bool().default(false).required(requiredFieldMessage),
  shouldStopOnYellow: yup.bool().default(false).required(requiredFieldMessage),
  shouldStopOnRed: yup.bool().default(false).required(requiredFieldMessage),
});
