import { ODataGridGeneratorConfig } from "components/data-grid";
import { PermissionHistory } from "./permission-history.type";

export const grid: ODataGridGeneratorConfig<PermissionHistory> = {
  datagrid: {
    noDataText: "Nenhuma permissão encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "action",
      caption: "Ação",
    },
    {
      dataField: "sourceUser.name",
      caption: "Usuário Ator",
    },
    {
        dataField: "sourceUser.email",
        caption: "Email Ator",
      },
    {
      dataField: "sourceUser.cpf",
      caption: "CPF Ator",
    },
    {
      dataField: "sourceUser.role",
      caption: "Role Ator",
    },
    {
      dataField: "user.name",
      caption: "Usuário Alvo",
    },
    {
      dataField: "user.cpf",
      caption: "CPF Alvo",
    },
    {
      dataField: "user.email",
      caption: "Email Alvo",
    },
    {
      dataField: "operationDateTime",
      caption: "Data da Operação",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
      sortOrder: "desc",
    },
    {
      dataField: "plataform",
      caption: "Plataforma",
    },
  ],
};
