export const contractSignatoriesFields = {
  taxId: {
    tag: "cpfcnpj",
    dataAction: "formulario:texto:identificacao_empresa",
    dataLabel: "identificacao_empresa",
    id: "taxId",
    name: "taxId",
    label: "CNPJ/CPF da empresa",
    placeholder: "Preencher",
    required: true,
  },
  identifications: {
    dataAction: "formulario:select:representantes",
    dataLabel: "representantes",
    id: "identifications",
    name: "identifications",
    label: "Representantes",
    required: true,
  },
} as const;
