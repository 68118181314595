import { OBadge, OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetMassifyEmployeesListResponse } from "services/fopa";
import { massifyStatusMap } from "./lotes.utils";

export const massifyListGrid = {
  datagrid: {
    noDataText: "Nenhum funcionário encontrado.",
    remoteOperations: {
      paging: true,
      filtering: true,
    },
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "name", caption: "Nome", allowHeaderFiltering: false },
    {
      dataField: "email",
      caption: "E-mail",
      allowHeaderFiltering: false,
    },
    {
      dataField: "loteId",
      caption: "Lote Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "documentNumber",
      caption: "Documento",
      cellRender: ({ data }) => masks.cpfCnpj(data.documentNumber),
      allowHeaderFiltering: false,
    },
    {
      dataField: "phone",
      caption: "Telefone",
      allowHeaderFiltering: false,
    },
    {
      dataField: "accountNumber",
      caption: "Agência / Conta",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => {
        if (!data.accountNumber || !data.agencyNumber) return "";
        if (
          data.accountNumber === "Não Autorizado" ||
          data.agencyNumber === "Não Autorizado"
        ) {
          return (
            <div>
              <OTooltip floating>
                <span slot="tooltip-content">
                  Entre em contato com o seu colaborador e solicite as
                  informações da conta.
                </span>
                ++ / +++++++++
              </OTooltip>
            </div>
          );
        }
        return `${data.agencyNumber} / ${data.accountNumber}`;
      },
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) =>
        data.status && massifyStatusMap[data.status] ? (
          <OBadge type={massifyStatusMap[data.status].type} size="sm">
            {massifyStatusMap[data.status].label}
          </OBadge>
        ) : (
          <OBadge type="info" size="sm">
            {data.status}
          </OBadge>
        ),
      headerFilter: {
        dataSource: [
          {
            value: "APROVADO,CONTA_ATIVA",
            text: "Concluído",
          },
          {
            value:
              "VALIDANDO_CANDIDATO,ENRIQUECENDO_DADOS,ANALISE_INICIADA,EM_ANALISE_ONBOARDING,EM_ANALISE_AML,EM_ANALISE_BIOMETRICA,ACAO_REQUERIDA,PENDENTE_ATIVACAO_CONTA",
            text: "Em Andamento",
          },
          {
            value: "CADASTRO_NAO_FINALIZADO",
            text: "Cadastro não finalizado",
          },
          {
            value: "FALHA_VALIDACAO,REPROVADO,ERRO",
            text: "Erro",
          },
        ],
      },
    },
    {
      dataField: "errors",
      caption: "Erro",
      allowHeaderFiltering: false,
      cellRender: ({ data }) =>
        data.errors.length > 0 ? data.errors[0].error : "",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  GetMassifyEmployeesListResponse["content"][number]
>;
