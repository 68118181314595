import { OToastManager } from "@maestro/react";
import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getModificationsToArray } from "../../../../utils";

export const useGatekeeperProfilesRoles = (uuid?: string, name?: string) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const {
    callService: getAllRoles,
    value: allRoles,
    loading: allRolesLoading,
    hasError: allRolesHasError,
  } = useServiceCall(service.gatekeeper.getRoles);

  const {
    callService: getInitialProfile,
    value: initialProfile,
    loading: initialProfileLoading,
    hasError: initialProfileHasError,
  } = useServiceCall(service.gatekeeper.getProfileByName);

  const { callService: addRoleToProfile, loading: addLoading } = useServiceCall(
    service.gatekeeper.profiles.addProfileRoles,
  );

  const { callService: removeRoleFromProfile, loading: removeLoading } =
    useServiceCall(service.gatekeeper.profiles.removeProfileRoles);

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const loading =
    allRolesLoading || initialProfileLoading || addLoading || removeLoading;

  const allRolesOptions: SelectSearchMultipleOption<string>[] = useMemo(
    () =>
      allRoles?.map((d) => {
        return {
          value: d.name,
          label: d.name,
          customKey: d.id,
        };
      }) ?? [],
    [allRoles],
  );

  const submit = handleSubmit(async (values) => {
    if (!name || !initialProfile) return;

    const { added, removed } = getModificationsToArray(
      initialProfile.roles,
      values.roles,
    );

    if (added.length === 0 && removed.length === 0) {
      return OToastManager.info("Nenhuma modificação");
    }

    const promises: Promise<{ success: boolean }>[] = [];

    if (added.length > 0) {
      promises.push(
        addRoleToProfile({
          profile: name,
          roles: values.roles,
        }),
      );
    }

    if (removed.length > 0) {
      promises.push(
        removeRoleFromProfile({
          profile: name,
          roles: removed,
        }),
      );
    }

    const successes = await Promise.all(promises);

    if (successes.every(({ success }) => success)) {
      OToastManager.success("Roles salvas com sucesso!");

      if (!uuid) throw new Error("No uuid");

      navigate(
        routes.ferramentas.product.gatekeeper.profiles.profile.roles.path({
          uuid: uuid,
        }),
      );
    } else {
      OToastManager.danger("Erro para salvar as roles");
    }
  });

  useEffect(() => {
    if (name) {
      getAllRoles();
      getInitialProfile(name);
    }
  }, [getAllRoles, getInitialProfile, name]);

  useEffect(() => {
    if (initialProfile) {
      setValue("roles", initialProfile.roles);
    }
  }, [initialProfile, setValue]);

  return {
    allRolesHasError,
    allRolesOptions,
    form,
    getAllRoles,
    getInitialProfile,
    initialProfileHasError,
    loading,
    submit,
  };
};
