import { StateStoring } from "devextreme/common/grids";
import { logger } from "../logger";
import {
  behaviorLocalStorage,
  behaviorSessionStorage,
} from "./behavior-storage";

export const customSaveStore = (store = behaviorLocalStorage) =>
  function customSave(this: StateStoring, gridState: unknown) {
    if (!this.storageKey) {
      logger.error("[customSave]No grid storageKey");
      return;
    }
    try {
      const stringifiedGridState = JSON.stringify(gridState);
      store.setItem(this.storageKey, stringifiedGridState);
    } catch (err) {
      logger.error(err, "[customSave]Unable to stringify gridState");
    }
  };

export const customLoadStore = (store = behaviorLocalStorage) =>
  async function customLoad(this: StateStoring) {
    if (!this.storageKey) {
      logger.error("[customLoad]No grid storageKey");
      return null;
    }
    try {
      const stringifiedGridState = store.getItem(this.storageKey);
      const gridState: unknown | null = JSON.parse(
        stringifiedGridState ?? "null",
      );
      return gridState;
    } catch (err) {
      logger.error(err, "[customLoad]Unable to parse gridState");
      return null;
    }
  };

export function gridStorage(
  key: string,
  savingTimeout = 250,
  storage: "session" | "local" = "session",
) {
  const store =
    storage === "session" ? behaviorSessionStorage : behaviorLocalStorage;

  return {
    savingTimeout,
    enabled: true,
    type: "custom" as const,
    customSave: customSaveStore(store),
    customLoad: customLoadStore(store),
    storageKey: `dxGridStorage-${key}`,
  };
}
