import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { fastCpfCnpjMask } from "../../../emails-automaticos/novo/new-auto-email.utils";
import {
  addCedenteLimitDefaulValues,
  addCedenteLimitValidationSchema,
} from "./add-cedente-limit.form";
import { AddCedenteLimitForm } from "./add-cedente-limit.types";

export const useAddCedenteLimit = () => {
  const navigate = useNavigate();

  const {
    callService: listCedentes,
    hasError: cedentesHasError,
    loading: cedentesLoading,
    value: cedentes,
  } = useServiceCall(service.quickfin.getAllCompanies);

  const {
    callService: addCedenteLimit,
    hasError: submitHasError,
    loading: submitLoading,
    value: submitResponse,
  } = useServiceCall(service.quickfin.addCedenteLimit);

  const form = useForm<AddCedenteLimitForm>({
    defaultValues: addCedenteLimitDefaulValues,
    resolver: yupResolver(addCedenteLimitValidationSchema),
  });

  const { handleSubmit, setValue, trigger } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) =>
        addCedenteLimit({
          ...values,
          limit: String(values.limit),
        }),
      ),
    [addCedenteLimit, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    listCedentes({ CompanyType: "Cedente" });
  }, [listCedentes]);

  /** Error warning */
  useEffect(() => {
    if (submitHasError)
      OToastManager.danger(
        "Erro ao criar o limite. Verifique se o cedente já possui configuração de limite.",
      );
  }, [submitHasError]);

  /** Error warning */
  useEffect(() => {
    if (cedentesHasError) OToastManager.danger("Erro ao buscar os cedentes");
  }, [cedentesHasError]);

  /** Success */
  useEffect(() => {
    if (submitResponse) {
      OToastManager.success("Limite criado com sucesso");
      navigate(
        corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento
          .cedenteLimits.path,
      );
    }
  }, [navigate, submitResponse]);

  const cedentesOptions = useMemo<SelectSearchOption<number>[]>(
    () =>
      cedentes?.companies.map((cedente) => ({
        label: `${cedente.name} - ${fastCpfCnpjMask(cedente.identification)}`,
        value: cedente.id,
        onSelect: () => {
          setValue("cedenteId", cedente.id);
          trigger("cedenteId");
        },
        customKey: cedente.id,
      })) ?? [],
    [cedentes, setValue, trigger],
  );

  return {
    cedentesHasError,
    cedentesLoading,
    cedentesOptions,
    submitLoading,
    form,
    submit,
  };
};
