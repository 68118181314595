/* eslint-disable no-param-reassign */
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { OBadge, OIcon, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { StandardLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { Conciliation } from "./extrato-liquidacao-posicao.types";

const buildDetailsLink = (conciliation: Conciliation["data"][number]) =>
  corporateRouter.routes.antecipacaoDeCartoes.customer.liquidationExtract.details.path(
    {
      maturityDate: conciliation.maturity_date,
    },
  );

export const conciliacaoGrid: ODataGridGeneratorConfig<
  NonNullable<Conciliation["data"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhuma informação de conciliação.",
  },
  columns: [
    {
      caption: "#",
      allowHeaderFiltering: true,
      cellRender: ({ data }) => (
        <StandardLink href={buildDetailsLink(data)}>Ver detalhes</StandardLink>
      ),
      name: "details",
    },
    {
      name: "maturityDate",
      dataField: "maturity_date",
      dataType: "date",
      format: "dd/MM/yyyy",
      caption: "Data vencimento",
      cellRender: ({ row: { data } }) => {
        const venceHoje =
          dayjs(data.maturity_date).format("DD-MM-YYYY") ===
          dayjs().format("DD-MM-YYYY");
        return (
          <div className="d-flex flex-row gap-1">
            {venceHoje && data.status === "em_aberto" && (
              <OIcon
                category="fa"
                icon="fa-exclamation-triangle"
                type="warning"
              />
            )}
            <div className="gap-1">
              <OTypography>
                {dayjs(data.maturity_date).format("DD/MM/YYYY")}
              </OTypography>
              {venceHoje && data.status === "em_aberto" && (
                <OTypography type="dark-60">Vence hoje</OTypography>
              )}
            </div>
          </div>
        );
      },
      sortOrder: "desc",
    },
    {
      dataField: "soma_valor_trades",
      caption: "Vencimento",
      alignment: "left",
      dataType: "number",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "soma_valor_pagamento",
      caption: "Recebido da credenciadora",
      alignment: "left",
      dataType: "number",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "diferenca",
      caption: "Diferença",
      alignment: "left",
      dataType: "number",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ row: { data } }) =>
        data.status === "nao_pago" ? (
          <OBadge type="danger">Não pago</OBadge>
        ) : data.status === "em_aberto" ? (
          <OBadge type="warning">Em aberto</OBadge>
        ) : (
          <OBadge type="success">Pago</OBadge>
        ),
    },
  ],
};
