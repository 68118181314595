import { OLabel, ORadio, ORadioGroup } from "@maestro/react";
import { useState } from "react";
import { statementType } from "../../filter/filters";

export const StatementTypeOptions = () => {
  const [typeState, setTypeState] = useState(
    statementType.find((t) => t.default)?.value,
  );
  return (
    <ORadioGroup
      className="d-flex flex-column justify-content-start gap-1"
      data-filterparam="type"
      dataAction="statement-export-filter"
      dataLabel="statement-export-filter"
      value={typeState}
    >
      {statementType.map((item) => (
        <div
          className="d-flex flex-row justify-content-start gap-3"
          key={item.value}
        >
          <ORadio
            name="statement-type"
            id={item.value}
            value={item.value}
            onClick={() => setTypeState(item.value)}
            key={item.value}
          />
          <OLabel htmlFor={item.value} size="md">
            {item.label}
          </OLabel>
        </div>
      ))}
    </ORadioGroup>
  );
};
