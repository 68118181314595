import { FormProvider, type UseFormReturn } from "react-hook-form";
import { service } from "services";
import { ApiSelector } from "../../../../../components/api-selector";

interface AgentImpersonateSelectorProps {
  form: UseFormReturn<{ agent: string | null }>;
}

export const AgentImpersonateSelector = ({
  form,
}: AgentImpersonateSelectorProps) => {
  return (
    <div style={{ width: "400px" }}>
      <FormProvider {...form}>
        <ApiSelector
          label="Impersonar agente"
          name="agent"
          placeholder="Nenhum agente impersonado"
          optionMapper={(value) => {
            const options =
              value?.response
                ?.filter(({ level }) => level !== "ADMIN")
                .map(({ name, level, taxId }) => ({
                  content: `${level}: ${name}`,
                  value: taxId,
                })) ?? [];
            options.sort((a, b) => a.content.localeCompare(b.content));
            return options;
          }}
          serviceMethod={service.hubEnergy.getAgents}
        />
      </FormProvider>
    </div>
  );
};
