import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiFraudSysAnalysisDetailsByIdResponse } from "services/gateholder/models/responses";
import { validationSchema } from "./details.form";
import { FormValuesType } from "./details.types";

export const useSecurityAntifraudAnalysisDetails = (id?: string) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] =
    useState<GetApiFraudSysAnalysisDetailsByIdResponse>();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (!id) return;

      await service.gateholder.setFraudSysManualAnalysisById(id, values);

      OToastManager.success("Detalhe alterado com sucesso");

      navigate(routes.ferramentas.product.security.antifraudAnalysis.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    try {
      setLoading(true);

      if (!id) return;

      const { data } = await service.gateholder.getFraudSysAnalysisDetailsById(
        id,
      );

      const newReset = {
        justification: data.justification || "",
        analysedBy: data.analysedBy || "",
        status: data.status,
        analysisResult: data.analysisResult,
        analysisType: data.analysisType,
      };

      reset(newReset);
      setDetail(data);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [id, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    loading,
    detail,
  };
};
