import { masks } from "@maestro/utils";
import { CardWallet } from "./multiple-card-wallet.component";

interface CardWalletInterface {
  totalEligibleValue: number;
  totalAnticipatedValue: number;
  totalReceivableValue: number;
  totalLimiteValue: number;
  totalPendingPaymentValue: number;
  totalLateSettlement: number;
}

export const CardWalletComponent = ({
  totalEligibleValue,
  totalAnticipatedValue,
  totalReceivableValue,
  totalLimiteValue,
  totalPendingPaymentValue,
  totalLateSettlement,
}: CardWalletInterface) => (
  <div
    className="d-flex flex-row w-100 justify-content-between"
    style={{ marginTop: "10px" }}
  >
    <CardWallet
      infos={[
        {
          title: "Limite disponível",
          value: masks.currency(totalLimiteValue, "R$"),
        },
      ]}
      icon="fa-sort-size-up"
      widht="w-12"
    />
    <CardWallet
      infos={[
        {
          title: "Carteira total",
          value: masks.currency(totalReceivableValue, "R$"),
        },
        {
          title: "Vencidos total",
          value: masks.currency(totalPendingPaymentValue, "R$"),
        },
      ]}
      icon="fa-wallet"
      widht="w-24"
    />
    <CardWallet
      infos={[
        {
          title: "Carteira disponível",
          value: masks.currency(totalEligibleValue, "R$"),
        },
        {
          title: "Carteira descontada",
          value: masks.currency(totalAnticipatedValue, "R$"),
        },
        {
          title: "Carteira vencida",
          value: masks.currency(totalLateSettlement, "R$"),
        },
      ]}
      icon="fa-check-circle"
      widht="w-36"
    />
  </div>
);
