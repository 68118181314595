import {
  OCard,
  OCardBody,
  OCardHeader,
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import DataSource from "devextreme/data/data_source";
import { EnergyAddressForm } from "../address/energy-address-form.component";
import { EnergyContactsForm } from "../contacts/energy-contacts-form.component";

interface EnergyAdequatorFieldsFormProps {
  disabled?: boolean;
  disableEditTaxId?: boolean;
  contactsDataSource?: DataSource<HubEnergy.ContactResponse>;
}

/** @deprecated */
export const EnergyAdequatorFieldsForm = ({
  disabled,
  contactsDataSource,
  disableEditTaxId,
}: EnergyAdequatorFieldsFormProps) => {
  return (
    <div className="d-flex flex-column gap-2">
      <OCard>
        <OCardHeader>Dados gerais</OCardHeader>
        <OCardBody>
          <ORow>
            <OCol sm={12} md={6} xl={4}>
              <ORFieldInput
                disabled={disabled}
                tag="text"
                id="name"
                name="name"
                label="Nome"
              />
            </OCol>
            <OCol sm={12} md={6} xl={4}>
              <ORFieldInput
                tag="cnpj"
                id="taxId"
                name="taxId"
                label="CNPJ"
                disabled={disabled || disableEditTaxId}
              />
            </OCol>
            <OCol sm={12} md={6} xl={4}>
              <ORFieldCheckboxGroup
                disabled={disabled}
                id="createdByCustomer"
                name="createdByCustomer"
                label=""
              >
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id="createdByCustomerTrue"
                    value="true"
                    size="sm"
                  />
                  <OLabel htmlFor="createdByCustomerTrue">
                    Criado pelo cliente ?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>Endereço</OCardHeader>
        <OCardBody>
          <EnergyAddressForm disabled={disabled} />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>Contatos</OCardHeader>
        <OCardBody>
          <EnergyContactsForm
            readonly={disabled}
            contactsDataSource={contactsDataSource}
          />
        </OCardBody>
      </OCard>
    </div>
  );
};
