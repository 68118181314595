import { OToastManager } from "@maestro/core";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useUser } from "contexts/user";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { limitRequestsGrid } from "./limit-requests.grid";

export const LimitRequestsPage = () => {
  const { user } = useUser();
  const gridRef = useGridRef();

  const handleApproval = useCallback(
    async (approval: "REJECTED" | "APPROVED", limitRequestId: number) => {
      try {
        await service.hubCreditManager.approvalLimits({
          approver: {
            name: user.name ?? "",
            taxId: user.cpf ?? "",
          },
          limitRequestId,
          approval,
        });

        const message = approval === "APPROVED" ? "aprovado" : "reprovado";

        OToastManager.success(`Limite ${message} com sucesso`);
      } catch {
        const message = approval === "APPROVED" ? "aprovar" : "reprovar";
        OToastManager.danger(
          `Houve um erro ao ${message} o limite. Tente novamente.`,
        );
      }
    },
    [user.cpf, user.name],
  );

  const grid = useMemo(
    () => limitRequestsGrid(handleApproval),
    [handleApproval],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Solicitações Pendentes" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
