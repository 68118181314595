import { env } from "utils/environments";

export interface Portal {
  url?: string;
  text: string;
  client_id?: string;
}

export const portals: {
  [key: string]: Portal;
} = {
  BTG_PACTUAL_EMPRESAS: {
    url: env.PORTAL_CORE_FRONT_URL,
    text: "BTG Pactual Empresas",
  },
  PARCEIROS: {
    url: env.PORTAL_B2B,
    text: "Parceiros",
  },
  RMADMINE: {
    url: env.PORTAL_RMADMINE,
    text: "Rm Admin E",
    client_id: env.OIDC_CLIENT_ID,
  },
};
