import {
  OCol,
  OLabel,
  ORFieldRadioGroup,
  ORadio,
  ORow,
  OToastManager,
} from "@maestro/react";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { FileUpload } from "../file-card";

interface DocumentCardProps {
  documentRepresentativeUuid: string;
  refetch: () => void;
  canEdit: boolean;
}

export const DocumentCard = ({
  documentRepresentativeUuid,
  refetch,
  canEdit,
}: DocumentCardProps) => {
  const { watch } = useFormContext();
  const watchSubType = watch("subType");

  const updateSubType = useCallback(
    async (subType: string) => {
      try {
        await service.onboardingRelationships.updateDocumentType({
          documentRepresentativeUuid,
          subType,
        });
      } catch {
        OToastManager.danger("Erro ao atualizar tipo de documento");
      }
    },
    [documentRepresentativeUuid],
  );

  useEffect(() => {
    watchSubType && updateSubType(watchSubType);
  }, [updateSubType, watchSubType]);

  return (
    <ORow>
      <OCol sm={4}>
        <div className="w-100">
          <ORFieldRadioGroup disabled={!canEdit} name="subType" id="subType">
            <div className="d-flex w-100 gap-4">
              <div className="d-flex align-items-center gap-2">
                <ORadio id="CNH" value="CNH" />
                <OLabel htmlFor="CNH">CNH</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2">
                <ORadio id="RG" value="RG" />
                <OLabel htmlFor="RG">RG</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2">
                <ORadio id="PASSAPORTE" value="PASSAPORTE" />
                <OLabel htmlFor="PASSAPORTE">Passaporte</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2">
                <ORadio id="RNE" value="RNE" />
                <OLabel htmlFor="RNE">RNE</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </div>
      </OCol>
      <OCol sm={8}>
        <FileUpload
          uuid={documentRepresentativeUuid}
          refetch={refetch}
          canEdit={canEdit}
        />
      </OCol>
    </ORow>
  );
};
