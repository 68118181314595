import { CancelingReceivablesForm } from "./canceling.types";

export const cancelingReceivablesDefaultValues: CancelingReceivablesForm = {
  receivableIdentification: "",
  receivableId: "",
  borderoId: "",
  type: "0",
  receivableStatusList: [],
  maturityDate: null,
  createdAt: null,
  updatedAt: null,
  sacadoIdList: [],
  cedenteIdList: [],
};
