import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { getValueFromMap } from "utils/get-value-from-map";
import { financedAssetIdentificationMap } from "../financed-assets.utils";
import { financedAssetPropertiesGrid } from "./financed-asset-properties.grid";
import { FinancedAsset } from "./financed-asset.types";

export const financedAssetDetailsGenerator = (financedAsset: FinancedAsset) => {
  return {
    asset: {
      title: "Detalhes do ativo",
      items: [
        {
          label: "Id",
          value: financedAsset.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: financedAsset.type,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Identificação",
          value:
            getValueFromMap(
              financedAssetIdentificationMap,
              financedAsset.identification?.toUpperCase(),
            ) ?? financedAsset.identification,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Quantidade",
          value: masks.currency(financedAsset.quantity, "", ".", ",", 0),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor unitário",
          value: masks.currency(
            financedAsset.unitaryAmount,
            currencySymbol.BRL,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Peso unitário",
          value: `${financedAsset.unitaryWeight ?? "-"} ${
            financedAsset.unitaryWeightUnit ?? "-"
          }`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Safra",
          value: financedAsset.harvest,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de apuração do preço",
          value: format.date(financedAsset.priceDate),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    properties: {
      title: "Propriedades",
      items: [
        {
          value: (
            <ODataGridGenerator
              grid={financedAssetPropertiesGrid}
              dataSource={financedAsset.properties ?? []}
            />
          ),
        },
      ],
    },
  } satisfies DetailsFields;
};
