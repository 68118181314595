import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { service } from "services";
import { logger } from "utils/logger";
import { CadastroClienteToolsMigrateDesksByWorkflowIdRouteParams } from "../../../../../routes/cadastro.route-params";
import { deskMigrationOptionsValidationSchema } from "./desk-migration-options.form";
import {
  Account,
  DeskMigrationOptionsForm,
} from "./desk-migration-options.types";

export const useDeskMigrationOptions = () => {
  const { workflowId } =
    useParams<CadastroClienteToolsMigrateDesksByWorkflowIdRouteParams>();
  if (!workflowId) throw new Error("No workflowId");

  const {
    callService: getDesks,
    hasError,
    loading,
    value: desks,
  } = useServiceCall(service.onboarding.getDesksOnboarding);

  const { callService: createDeskMigration, loading: submitLoading } =
    useServiceCall(service.onboarding.createDeskMigration);

  const navigate = useNavigate();

  const form = useForm<DeskMigrationOptionsForm>({
    resolver: yupResolver(deskMigrationOptionsValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          const { agency, type } = JSON.parse(
            values.accountStringified,
          ) as Account;

          const { success } = await createDeskMigration({
            workflowId,
            deskCode: values.deskCode,
            officerCge: values.officerCge,
            accounts: [
              {
                agency,
                type,
              },
            ],
          });

          if (success) {
            OToastManager.success("Migração de segmento criada com sucesso");
            navigate(-1);
          } else {
            OToastManager.danger("Erro ao criar migração de segmento");
          }
        } catch (err) {
          // should never happen
          logger.error("[DeskMigrationOptions]Unable to JSON.parse", err);
          OToastManager.danger("[DeskMigrationOptions]Unable to JSON.parse");
        }
      }),
    [createDeskMigration, handleSubmit, navigate, workflowId],
  );

  useEffect(() => {
    getDesks();
  }, [getDesks]);

  return {
    desks,
    form,
    getDesks,
    hasError,
    loading,
    submit,
    submitLoading,
    workflowId,
  };
};
