import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  BiometryRequiredField,
  CancelaNaoAntecipadasD0Field,
  DiasCampanhaNotasNaoAntecipadasCedenteField,
  DiasParaVencimentoSacadoField,
  EmailsSacadoNotificationField,
  ExigeAprovacaoBancoField,
  ExigeAprovacaoSacadoField,
  FccIdSacadoField,
  HabilitaPagForField,
  HabilitaPagForBankingField,
  HorarioLimiteOperacaoField,
  IdContaDeRetiradaField,
  InstrumentCodeIdField,
  MarketplaceIdField,
  ModeloDeTaxaField,
  NomeField,
  NotaDisponivelCedenteField,
  NotificacaoRecebiveisAntecipadosField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  ReporteDiarioSacadoField,
  ReporteNotasNaoAntecipadasCedenteField,
  SacadoDefineContaDesembolsoField,
  TipoOnboardingField,
  GracePeriodField,
} from "../fields";
import { ToCreditField } from "../fields/to-credit-field.component";
import { radioToBoolean } from "../../../utils";

interface FundingProprioFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const FundingProprioForm = ({
  form,
  shouldReset,
}: FundingProprioFormProps) => {
  const { getValues, reset, watch, setValue } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        exigeAprovacaoBanco: "no",
        prazoLimiteOperacao: 0,
        toCredit: "yes",
        modeloDeTaxa: "configuracao",
        tipoOnboarding: "CEDENTE_SIMPLES",
      });
  }, [getValues, reset, shouldReset]);

  const reporteDiarioSacadoWatch = watch("reporteDiarioSacado");
  const reporteNotasNaoAntecipadasCedenteWatch = watch(
    "reporteNotasNaoAntecipadasCedente",
  );
  const habilitaPagForWatch = watch("habilitaPagFor");
  const habilitaPagForBankingWatch = watch("habilitaPagForBanking");

  const togglePagFor = (
    setValueTarget: "habilitaPagFor" | "habilitaPagForBanking",
    watchOrigin?: any,
  ) => {
    if (radioToBoolean(watchOrigin)) {
      setValue(setValueTarget as keyof EstrategiaForm, "no");
    }
  };

  useEffect(() => {
    togglePagFor("habilitaPagForBanking", habilitaPagForWatch);
  }, [habilitaPagForWatch]);
  useEffect(() => {
    togglePagFor("habilitaPagFor", habilitaPagForBankingWatch);
  }, [habilitaPagForBankingWatch]);

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <HorarioLimiteOperacaoField />
        <ExigeAprovacaoBancoField />
        <TipoOnboardingField type="DEFAULT" />
        <PrazoLimiteOperacaoField />
        <GracePeriodField />
        <FccIdSacadoField required />
        <PnlBookField />
        <PnlStrategyField />
        <InstrumentCodeIdField />
        <MarketplaceIdField />
        <PortfolioIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Funding Próprio
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <HabilitaPagForField />
        <HabilitaPagForBankingField />
        <ExigeAprovacaoSacadoField />
        <SacadoDefineContaDesembolsoField />
        <CancelaNaoAntecipadasD0Field />
        <IdContaDeRetiradaField required />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (sacado)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ReporteDiarioSacadoField />
        {reporteDiarioSacadoWatch === "yes" && (
          <DiasParaVencimentoSacadoField />
        )}
        <EmailsSacadoNotificationField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (cedente)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NotaDisponivelCedenteField />
        <ReporteNotasNaoAntecipadasCedenteField />
        {reporteNotasNaoAntecipadasCedenteWatch === "yes" && (
          <DiasCampanhaNotasNaoAntecipadasCedenteField />
        )}
        <NotificacaoRecebiveisAntecipadosField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
      </ORow>
    </>
  );
};
