export const currencyOptions = [
  { value: "BRL", label: "BRL" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
];

export const configTypeOptions = [
  {
    value: "InputTaxa",
    label: "Taxa",
  },
  {
    value: "InputSpread",
    label: "Spread",
  },
  {
    value: "InputDiMais",
    label: "DI+",
  },
];

export const capitalizationBaseOptions = [
  {
    value: "Base21",
    label: "Base 21",
  },
  {
    value: "Base30",
    label: "Base 30",
  },
  {
    value: "Base252",
    label: "Base 252",
  },
  {
    value: "Base360",
    label: "Base 360",
  },
];
export const capitalizationTypeOptions = [
  {
    value: "Linear",
    label: "Linear",
  },
  {
    value: "Exponential",
    label: "Exponential",
  },
  {
    value: "TaxaDesconto",
    label: "Taxa Desconto",
  },
];

export const calendarOptions = [
  {
    value: "",
    label: "Padrão",
  },
];

export const rebateBaseOptions = [
  {
    value: "Mensal",
    label: "Mensal",
  },
  {
    value: "Anual",
    label: "Anual",
  },
];

export const rebateTypeOptions = [
  {
    value: "DiferencaTaxas",
    label: "Diferença taxas",
  },
  {
    value: "Fixo",
    label: "Fixo",
  },
  {
    value: "SemRebate",
    label: "Sem rebate",
  },
  {
    value: "SemRebateComTrava",
    label: "Sem rebate com trava",
  },
];

export const taxasColunasOptions = [
  { value: "Prazo", label: "Prazo" },
  { value: "DataBase", label: "DataBase" },
  { value: "DataFutura", label: "DataFutura" },
  { value: "Spread", label: "Spread" },
  { value: "FatorDesconto", label: "FatorDesconto" },
  { value: "PreAaLin", label: "PreAaLin" },
  { value: "PreExpDuAa", label: "PreExpDuAa" },
  { value: "PreExpDuAm", label: "PreExpDuAm" },
  { value: "PreExpDcAa", label: "PreExpDcAa" },
  { value: "PreExpDcAm", label: "PreExpDcAm" },
  { value: "PreAmEfetivaLin", label: "PreAmEfetivaLin" },
  { value: "PreAmSimplesLin", label: "PreAmSimplesLin" },
  { value: "Rebate", label: "Rebate" },
  { value: "RebateOverTxDi", label: "RebateOverTxDi" },
  { value: "TaxaMensal", label: "TaxaMensal" },
  { value: "TaxaTotal", label: "TaxaTotal" },
];

export const scfCalendarMap = {
  RiscoSacado: "SCF",
  RiscoCedente: "SCF",
  FundingProprio: "SCF",
};
