// TODO: figure out correct colors and labels
export const userStatusMap = {
  UNCONFIRMED: {
    type: "warning",
    label: "Não confirmado",
  },
  RESET_REQUIRED: {
    type: "danger",
    label: "Aguardando mudar senha",
  },
  FORCE_CHANGE_PASSWORD: {
    type: "danger",
    label: "Aguardando mudar senha",
  },
  NOT_CREATED: {
    type: "info",
    label: "Conta não criada",
  },
  CONFIRMED: {
    type: "success",
    label: "Confirmado",
  },
} as const;
