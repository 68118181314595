import qs from "query-string";
import { client } from "../client";
import {
  GetAdminCompaniesByCompanyIdOperationsByOperationIdParams,
  GetAdminDashboardOnboardingsInfosParams,
  GetAdminTiersParams,
  GetCompaniesAdminGetAllCompaniesParams,
  GetCompaniesCedenteSacadosSalesforceParams,
  GetCompaniesDetailsParams,
  GetOdataAditamentoODataParams,
  GetOdataAdminPayeesODataParams,
  GetOdataAgendamentoViewParams,
  GetOdataAprovacoesOdataViewParams,
  GetOdataBorderosODataParams,
  GetOdataCedentesCompaniesViewParams,
  GetOdataCompaniesRelationshipsODataParams,
  GetOdataEstrategiasComerciaisOdataParams,
  GetOdataFidcBorderosOdataParams,
  GetOdataFidcOperationsOdataParams,
  GetOdataFidcReceivableStateMachineOdataViewParams,
  GetOdataMonitorDiarioParams,
  GetOdataOnboardingStrategiesODataParams,
  GetOdataOperationsAttendanceODataParams,
  GetOdataOperationsODataParams,
  GetOdataReceivableStateMachineODataViewParams,
  GetOdataReceivablesSalesforceODataParams,
  GetOdataRelatoriosEmLoteAdminODataParams,
  GetOdataSacadosCompaniesViewParams,
  GetOdataSettlementStrategiesOdataParams,
  PatchAdminOnboardingStrategyBody,
  PatchAdminUserFlagAssinantesContratosCartoesBody,
  PatchCompaniesByIdCedentesBody,
  PatchCompaniesByIdSacadosBody,
  PatchEstrategiaComercialByCompanyIdBody,
  PatchSuperAdminAditamentoBody,
  PatchSuperAdminAgendamentoBody,
  PatchSuperAdminReceivableStateMachinesStatusBody,
  PatchSuperAdminReceivablesStatusBody,
  PatchTemplateEstrategiaComercialBody,
  PatchTemplateEstrategiaComercialByEstrategiaIdByAtualizaEstrategiasByAtualizaCedentesRelacionamentoBody,
  PatchTemplateEstrategiaComercialByEstrategiaIdConfigTaxaBody,
  PostAdminAditamentosCreateBody,
  PostAdminAditamentosVerifyBody,
  PostAdminCancelableReceivablesBody,
  PostAdminCompanyFlagsBody,
  PostAdminDocumentosCreateBody,
  PostAdminLimitCedentesBody,
  PostAdminOnboardingStrategyBody,
  PostAdminOperationsAntecipacaoBody,
  PostAdminOperationsBody,
  PostAdminReceivablesApproveBody,
  PostAdminReceivablesBody,
  PostAdminReceivablesCancelBody,
  PostAdminReceivablesCotacaoBody,
  PostAdminReceivablesExportBody,
  PostAdminReceivablesSearchBody,
  PostAdminReceivablesSuggestBody,
  PostAdminReceivablesSummaryBody,
  PostAdminSacadocedentetiersTiersByIdBody,
  PostAdminTiersBody,
  PostAdminTiersSacadosBody,
  PostAdminTiersUploadBody,
  PostAdminTiersUploadPreviewBody,
  PostAdminUserFlagAssinantesContratosCartoesBody,
  PostCompaniesBody,
  PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasBody,
  PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyBody,
  PostCompaniesByIdBorderosUploadBody,
  PostCompaniesByIdBorderosVerifyBody,
  PostCompaniesByIdCedentesBody,
  PostCompaniesByIdCedentesUploadBody,
  PostCompaniesByIdCedentesUploadVerifyBody,
  PostCompaniesByIdContractTemplatesBody,
  PostCompaniesByIdSacadosBody,
  PostCompaniesByIdSacadosUploadBody,
  PostCompaniesByIdSacadosUploadVerifyBody,
  PostCompaniesProductsBody,
  PostCompanyEstrategiaComercialEstrategiasByCompaniesProductTypeBody,
  PostContractTemplatesBody,
  PostEmailCotacaoBody,
  PostEstrategiaComercialByCompanyIdBody,
  PostEstrategiaComercialByCompanyIdByEstrategiaIdByAtualizaCedentesRelacionamentoBody,
  PostSuperAdminEventsBody,
  PostSuperAdminGroupsForceCompletionBody,
  PostTemplateEstrategiaComercialBody,
  PostsettlementStrategiesBody,
  PutAdminLimitCedentesByIdBody,
  PutAdminSettlementStrategiesBySettlementIdBody,
  PutAdminTiersByIdBody,
  PutAdminTiersSacadosByIdBody,
  PutCompaniesByCompanyIdContractTemplatesByTemplateIdBody,
  PutCompaniesUpdateByIdBody,
  PutContractTemplatesByTemplateIdBody,
  PostPortalEstrategiaComercialBody,
  PostCompaniesByIdCedentesContractComplementBody,
  PostContractComplementBody,
  PostAdminReceivableData,
  GetOdataReceivableReprocessOdataViewParams,
  PostAdminBorderosMultiCreateBody,
  PostAdminOperationsCancelamentoBody,
  PostAdminReceivablesCalculateAvgsBody,
} from "./models/requests";
import { PatchAdminTCOStrategyBody } from "./models/requests/patch-admin-tco-strategy.request";
import { PostAdminReceivablesReportBody } from "./models/requests/post-admin-receivables-report.request";
import { PostAdminTCOStrategyBody } from "./models/requests/post-admin-tco-strategy.request";
import { PostGetAllEstrategiasComerciaisSimplifiedBody } from "./models/requests/post-get-all-estrategias-comerciais-simplified.request";
import {
  DeleteAdminTiersByIdResponse,
  DeleteAdminTiersByIdSacadosByIdCedentesByIdResponse,
  DeleteAdminTiersSacadosByIdResponse,
  DeleteCompaniesByCompanyIdContractTemplatesByTemplateIdResponse,
  DeleteEstrategiaComercialByCompanyIdByEstrategiaIdResponse,
  DeleteOdataOnboardingStrategiesODataByIdResponse,
  GeOdataOnboardingsFornecedoresODataResponse,
  GetAdminBalancesResponse,
  GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse,
  GetAdminCompanyFlagsResponse,
  GetAdminDashboardOnboardingsInfosResponse,
  GetAdminDistinctValuesByEntityNameByPropertyNameResponse,
  GetAdminDocumentosByIdZipResponse,
  GetAdminLimitCedentesResponse,
  GetAdminMonitorResumoDiarioResponse,
  GetAdminMonitorTradesMaturingResponse,
  GetAdminOnboardingStrategyByIdResponse,
  GetAdminOnboardingStrategyByIdUpdateOnboardingTypeResponse,
  GetAdminReceivableFidcDailySummaryResponse,
  GetAdminReceivableStateMachineByIdResponse,
  GetAdminSacadocedentetiersTiersByIdResponse,
  GetAdminSettlementStrategiesBySettlementIdResponse,
  GetAdminTiersByIdResponse,
  GetAdminTiersResponse,
  GetAdminTiersSacadosByIdResponse,
  GetAdminTiersSacadosResponse,
  GetAdminUserFlagAllAssinantesContratosCartoesResponse,
  GetAdminUserFlagAssinantesContratosCartoesByTaxIdResponse,
  GetBorderosByIdResponse,
  GetCompaniesAdminGetAllCompaniesResponse,
  GetCompaniesByCompanyIdContractTemplatesByTemplateIdFileResponse,
  GetCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasByCurrencyResponse,
  GetCompaniesByIdCedentesResponse,
  GetCompaniesByIdContractTemplatesByTemplateIdResponse,
  GetCompaniesByIdContractTemplatesResponse,
  GetCompaniesByIdSacadosResponse,
  GetCompaniesCedenteSacadosSalesforceResponse,
  GetCompaniesDetailsResponse,
  GetCompaniesSearchByCnpjByTaxIdResponse,
  GetCompaniesWithOnboardingByIdResponse,
  GetContractTemplatesByIdFileResponse,
  GetContractTemplatesResponse,
  GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse,
  GetEstrategiaComercialByCompanyIdResponse,
  GetEstrategiaComercialGetByProductTypeByTypeResponse,
  GetEstrategiaComercialGetInstrumentCodeByProductTypeByProductTypeResponse,
  GetMarketplacesResponse,
  GetOdataAditamentoODataResponse,
  GetOdataAdminPayeesODataResponse,
  GetOdataAgendamentoViewResponse,
  GetOdataAprovacaoOdataViewResponse,
  GetOdataBorderosODataResponse,
  GetOdataCedentesCompaniesViewResponse,
  GetOdataCompaniesRelationshipsODataResponse,
  GetOdataEstrategiasComerciaisOdataResponse,
  GetOdataFidcBorderosOdataResponse,
  GetOdataFidcOperationsOdataResponse,
  GetOdataFidcReceivableStateMachineOdataViewResponse,
  GetOdataMonitorDiarioResponse,
  GetOdataOnboardingStrategiesODataResponse,
  GetOdataOperationsAttendanceODataResponse,
  GetOdataOperationsODataResponse,
  GetOdataReceivableStateMachineODataViewResponse,
  GetOdataReceivablesSalesforceODataResponse,
  GetOdataRelatoriosEmLoteAdminODataResponse,
  GetOdataSacadosCompaniesViewResponse,
  GetOdataSettlementStrategiesOdataResponse,
  GetOperationsAttendanceByOperationIdResponse,
  GetPortalResponse,
  GetPortfoliosResponse,
  GetSchedulingByIdComprovanteTrueResponse,
  GetSuperAdminReceivableByIdGroupResponse,
  GetTemplateEstrategiaComercialResponse,
  GetTradesByIdComprovanteResponse,
  PatchAdminOnboardingStrategyResponse,
  PatchAdminUserFlagAssinantesContratosCartoesResponse,
  PatchEstrategiaComercialByCompanyIdByEstrategiaIdPadraoResponse,
  PatchEstrategiaComercialByCompanyIdResponse,
  PatchSuperAdminReceivableStateMachinesStatusResponse,
  PatchSuperAdminReceivablesStatusResponse,
  PatchTemplateEstrategiaComercialByEstrategiaIdByAtualizaEstrategiasByAtualizaCedentesRelacionamentoResponse,
  PatchTemplateEstrategiaComercialResponse,
  PostAdminAditamentosVerifyResponse,
  PostAdminCancelableReceivablesResponse,
  PostAdminCompanyFlagsResponse,
  PostAdminLimitCedentesResponse,
  PostAdminOnboardingStrategyResponse,
  PostAdminOperationsAntecipacaoResponse,
  PostAdminOperationsResponse,
  PostAdminReceivablesCancelResponse,
  PostAdminReceivablesCotacaoResponse,
  PostAdminReceivablesExportResponse,
  PostAdminReceivablesResponse,
  PostAdminReceivablesSearchResponse,
  PostAdminReceivablesSuggestResponse,
  PostAdminReceivablesSummaryResponse,
  PostAdminSacadocedentetiersTiersByIdResponse,
  PostAdminSettlementStrategies,
  PostAdminTiersResponse,
  PostAdminTiersSacadosResponse,
  PostAdminTiersUploadPreviewResponse,
  PostAdminTiersUploadResponse,
  PostAdminUserFlagAssinantesContratosCartoesResponse,
  PostCompaniesByCompanyIdContractTemplatesByTemplateIdSetDefaultResponse,
  PostCompaniesByIdBorderosVerifyErrorResponse,
  PostCompaniesByIdCedentesResponse,
  PostCompaniesByIdCedentesUploadVerifyErrorResponse,
  PostCompaniesByIdContractTemplatesResponse,
  PostCompaniesByIdSacadosResponse,
  PostCompaniesByIdSacadosUploadVerifyErrorResponse,
  PostCompaniesProductsResponse,
  PostCompaniesResponse,
  PostCompanyEstrategiaComercialEstrategiasByCompaniesProductTypeResponse,
  PostEstrategiaComercialByCompanyIdByEstrategiaIdByAtualizaCedentesRelacionamentoResponse,
  PostEstrategiaComercialByCompanyIdResponse,
  PostSuperAdminGroupsForceCompletionResponse,
  PostTemplateEstrategiaComercialResponse,
  PutAdminLimitCedentesByIdResponse,
  PutAdminSettlementStrategiesBySettlementId,
  PutAdminTiersByIdResponse,
  PutAdminTiersSacadosByIdResponse,
  PutCompaniesByCompanyIdContractTemplatesByTemplateIdResponse,
  PutCompaniesUpdateByIdResponse,
  PostAdminReceivablesQuotationFileResponse,
  PostPortalEstrategiaComercialResponse,
  GetCompaniesByIdCedentesContractComplementResponse,
  GetOdataReceivableReprocessOdataViewResponse,
  GetTradesPaymentsResponseItem,
  GetCompaniesSearchByUUIDResponse,
  GetAdminCompaniesByCompanyIdAssignmentsByOperationIdResponse,
  PostAdminReceivablesCalculateAvgsResponse,
  CreatedOperationsWithoutRegistryResponse,
  ValidatedOperationsWithoutRegistryResponse,
  SimulatedOperationsWithoutRegistryResponseWithAvgInfos,
  GetOdataSettlementTradesOdataResponse,
} from "./models/responses";
import { GetAdminTCOStrategyByIdResponse } from "./models/responses/get-admin-tco-strategy-by-id.response";
import { GetSacadosSummariesResponse } from "./models/responses/get-sacados-summaries-response";
import { GetCreditorsResponse } from "./models/responses/get-creditors.response";
import { quickfinEndpoints } from "./quickfin.endpoints";
import { PostAdminValidateOperationsResponse } from "./models/responses/post-admin-validate-operations.response";
import {
  ICreateLimitBagParams,
  IGetLimitBagsByProductParams,
} from "../limit-control/models/request";
import { limitControlService } from "../limit-control/limit-control.service";
import { PostAdminReceivablesReprocessBody } from "./models/requests/post-admin-receivables-reprocess.request";


export const quickfinService = {
  getAttendance(operationId: string) {
    return client.get<GetOperationsAttendanceByOperationIdResponse>(
      quickfinEndpoints.operations.attendanceByOperationId(operationId),
    );
  },
  getFidcBorderos(params: GetOdataFidcBorderosOdataParams) {
    return client.get<GetOdataFidcBorderosOdataResponse>(
      quickfinEndpoints.odata.fidcBorderosOData,
      {
        params,
      },
    );
  },
  getFidcOperations(params: GetOdataFidcOperationsOdataParams) {
    return client.get<GetOdataFidcOperationsOdataResponse>(
      quickfinEndpoints.odata.fidcOperationsOData,
      {
        params,
      },
    );
  },
  getFidcReceivables(
    params: GetOdataFidcReceivableStateMachineOdataViewParams,
  ) {
    return client.get<GetOdataFidcReceivableStateMachineOdataViewResponse>(
      quickfinEndpoints.odata.fidcReceivableStateMachineODataView,
      {
        params,
      },
    );
  },
  getFidcReceivablesSummary() {
    return client.get<GetAdminReceivableFidcDailySummaryResponse>(
      quickfinEndpoints.admin.receivables.fidc.dailySummary,
    );
  },
  getOperationDetails(
    params: GetAdminCompaniesByCompanyIdOperationsByOperationIdParams,
  ) {
    return client.get<GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse>(
      quickfinEndpoints.admin.companies.byCompanyId.operations.byOperationId(
        params.companyId,
        params.operationId,
      ),
    );
  },
  getOperationsAttendance(params: GetOdataOperationsAttendanceODataParams) {
    return client.get<GetOdataOperationsAttendanceODataResponse>(
      quickfinEndpoints.odata.operationsAttendanceOData,
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      },
    );
  },
  getReceivables(params: GetOdataReceivablesSalesforceODataParams) {
    return client.get<GetOdataReceivablesSalesforceODataResponse>(
      quickfinEndpoints.odata.receivablesSalesforceOData,
      {
        params,
      },
    );
  },
  getCompanyByTaxId(taxId: string | number) {
    return client.get<GetCompaniesSearchByCnpjByTaxIdResponse>(
      quickfinEndpoints.companies.searchByCnpj.byTaxId(taxId),
    );
  },
  getCompanyByUUID(UUID: string) {
    return client.get<GetCompaniesSearchByUUIDResponse>(
      quickfinEndpoints.companies.search.byUUID(UUID),
    );
  },
  getSacados(params: GetCompaniesCedenteSacadosSalesforceParams) {
    return client.get<GetCompaniesCedenteSacadosSalesforceResponse>(
      quickfinEndpoints.companies.cedente.sacados.salesforce,
      {
        params,
      },
    );
  },
  getQuote(body: PostAdminReceivablesCotacaoBody) {
    return client.post<PostAdminReceivablesCotacaoResponse>(
      quickfinEndpoints.admin.receivables.cotacao,
      body,
    );
  },
  anticipate(body: PostAdminOperationsAntecipacaoBody) {
    return client.post<PostAdminOperationsAntecipacaoResponse>(
      quickfinEndpoints.admin.operations.antecipacao,
      body,
    );
  },
  searchReceivables(body: PostAdminReceivablesSearchBody) {
    return client.post<PostAdminReceivablesSearchResponse>(
      quickfinEndpoints.admin.receivables.search,
      body,
    );
  },
  validateOperations(body: PostAdminOperationsBody) {
    return client.post<PostAdminValidateOperationsResponse>(
      quickfinEndpoints.admin.operations.validate,
      body,
    );
  },
  createOperations(body: PostAdminOperationsBody) {
    return client.post<PostAdminOperationsResponse>(
      quickfinEndpoints.admin.operations.endpoint,
      body,
    );
  },
  simulateOperationsWithoutRegistry(body: PostAdminReceivableData) {
    return client.post<SimulatedOperationsWithoutRegistryResponseWithAvgInfos>(
      quickfinEndpoints.admin.operationsWithoutRegistry.simulate,
      body,
    );
  },
  validateOperationsWithoutRegistry(body: PostAdminReceivableData) {
    return client.post<ValidatedOperationsWithoutRegistryResponse[]>(
      quickfinEndpoints.admin.operationsWithoutRegistry.validate,
      body,
    );
  },
  createOperationsWithoutRegistry(body: PostAdminReceivableData) {
    return client.post<CreatedOperationsWithoutRegistryResponse[]>(
      quickfinEndpoints.admin.operationsWithoutRegistry.endpoint,
      body,
    );
  },
  cancelOperations(body: PostAdminOperationsCancelamentoBody) {
    return client.post(
      quickfinEndpoints.admin.operations.cancelamento,
      body,
    );
  },
  reprocessOperations(body: PostAdminOperationsCancelamentoBody) {
    return client.post(
      quickfinEndpoints.admin.operations.reprocessamento,
      body,
    );
  },
  receivableStateMachineODataView(
    params?: GetOdataReceivableStateMachineODataViewParams,
  ) {
    return client.get<GetOdataReceivableStateMachineODataViewResponse>(
      quickfinEndpoints.odata.receivableStateMachineODataView,
      { params },
    );
  },
  getAllCompanies(params?: GetCompaniesAdminGetAllCompaniesParams) {
    return client.get<GetCompaniesAdminGetAllCompaniesResponse>(
      quickfinEndpoints.companies.admin.getAllCompanies,
      {
        params,
        paramsSerializer: (rawParams) =>
          qs.stringify(rawParams, { arrayFormat: "index" }),
      },
    );
  },
  suggestReceivables(body: PostAdminReceivablesSuggestBody) {
    return client.post<PostAdminReceivablesSuggestResponse>(
      quickfinEndpoints.admin.receivables.suggest,
      body,
    );
  },
  dailyMonitor(params?: GetOdataMonitorDiarioParams) {
    return client.get<GetOdataMonitorDiarioResponse>(
      quickfinEndpoints.odata.monitorDiario,
      { params },
    );
  },
  getTiers(params?: GetAdminTiersParams) {
    return client.get<GetAdminTiersResponse>(
      quickfinEndpoints.admin.tiers.endpoint,
      { params },
    );
  },
  createTier(body: PostAdminTiersBody) {
    return client.post<PostAdminTiersResponse>(
      quickfinEndpoints.admin.tiers.endpoint,
      body,
    );
  },
  getTierRelationships(id?: string | number) {
    return client.get<GetAdminSacadocedentetiersTiersByIdResponse>(
      quickfinEndpoints.admin.sacadocedentetiers.tiers.byId.endpoint(id),
    );
  },
  createTierRelationship(
    id: string | number,
    body: PostAdminSacadocedentetiersTiersByIdBody,
  ) {
    return client.post<PostAdminSacadocedentetiersTiersByIdResponse>(
      quickfinEndpoints.admin.sacadocedentetiers.tiers.byId.endpoint(id),
      body,
    );
  },
  removeTierRelationship(
    tierId: string | number,
    sacadoId: string | number,
    cedenteId: string | number,
  ) {
    return client.delete<DeleteAdminTiersByIdSacadosByIdCedentesByIdResponse>(
      quickfinEndpoints.admin.sacadocedentetiers.tiers.byId.sacados.byId.cedentes.byId(
        tierId,
        sacadoId,
        cedenteId,
      ),
    );
  },
  getTier(id: string | number) {
    return client.get<GetAdminTiersByIdResponse>(
      quickfinEndpoints.admin.tiers.byId(id),
    );
  },
  editTier(id: string | number, body: PutAdminTiersByIdBody) {
    return client.put<PutAdminTiersByIdResponse>(
      quickfinEndpoints.admin.tiers.byId(id),
      body,
    );
  },
  removeTier(id: string | number) {
    return client.delete<DeleteAdminTiersByIdResponse>(
      quickfinEndpoints.admin.tiers.byId(id),
    );
  },
  listSacados(params?: GetOdataSacadosCompaniesViewParams) {
    return client.get<GetOdataSacadosCompaniesViewResponse>(
      quickfinEndpoints.odata.sacadosCompaniesView,
      { params },
    );
  },
  listCedentes(params?: GetOdataCedentesCompaniesViewParams) {
    return client.get<GetOdataCedentesCompaniesViewResponse>(
      quickfinEndpoints.odata.cedentesCompaniesView,
      { params },
    );
  },
  getSacadoTierConfigs() {
    return client.get<GetAdminTiersSacadosResponse>(
      quickfinEndpoints.admin.tiers.sacados.endpoint,
    );
  },
  createSacadoTierConfig(body: PostAdminTiersSacadosBody) {
    return client.post<PostAdminTiersSacadosResponse>(
      quickfinEndpoints.admin.tiers.sacados.endpoint,
      body,
    );
  },
  getSacadoTierConfig(id: string | number) {
    return client.get<GetAdminTiersSacadosByIdResponse>(
      quickfinEndpoints.admin.tiers.sacados.byId(id),
    );
  },
  editSacadoTierConfig(
    id: string | number,
    body: PutAdminTiersSacadosByIdBody,
  ) {
    return client.put<PutAdminTiersSacadosByIdResponse>(
      quickfinEndpoints.admin.tiers.sacados.byId(id),
      body,
    );
  },
  removeSacadoTierConfig(id: string | number) {
    return client.delete<DeleteAdminTiersSacadosByIdResponse>(
      quickfinEndpoints.admin.tiers.sacados.byId(id),
    );
  },
  batchSendTiers(body: PostAdminTiersUploadBody) {
    return client.post<PostAdminTiersUploadResponse>(
      quickfinEndpoints.admin.tiers.upload.endpoint,
      body,
    );
  },
  batchPreviewTiers(body: PostAdminTiersUploadPreviewBody) {
    return client.post<PostAdminTiersUploadPreviewResponse>(
      quickfinEndpoints.admin.tiers.upload.preview,
      body,
    );
  },
  getOperations(params?: GetOdataOperationsODataParams) {
    return client.get<GetOdataOperationsODataResponse>(
      quickfinEndpoints.odata.operationsOData,
      { params },
    );
  },
  getDailySummary() {
    return client.get<GetAdminMonitorResumoDiarioResponse>(
      quickfinEndpoints.admin.monitor.resumoDiario,
    );
  },
  getCompanyOperation(
    companyId: string | number,
    operationId: string | number,
  ) {
    return client.get<GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse>(
      quickfinEndpoints.admin.companies.byCompanyId.operations.byOperationId(
        companyId,
        operationId,
      ),
    );
  },
  getCompanyAssignment(
    companyId: string | number,
    operationId: string | number,
  ) {
    return client.get<GetAdminCompaniesByCompanyIdAssignmentsByOperationIdResponse>(
      quickfinEndpoints.admin.companies.byCompanyId.assignments.byOperationId(
        companyId,
        operationId,
      ),
    );
  },
  getTradesMaturing() {
    return client.get<GetAdminMonitorTradesMaturingResponse>(
      quickfinEndpoints.admin.monitor.tradesMaturing,
    );
  },
  getCededenteLimits() {
    return client.get<GetAdminLimitCedentesResponse>(
      quickfinEndpoints.admin.limit.cedentes.endpoint,
    );
  },
  editCededenteLimit(id: string | number, body: PutAdminLimitCedentesByIdBody) {
    return client.put<PutAdminLimitCedentesByIdResponse>(
      quickfinEndpoints.admin.limit.cedentes.byId(id),
      body,
    );
  },
  removeCededenteLimit(id: string | number) {
    return client.delete(quickfinEndpoints.admin.limit.cedentes.byId(id));
  },
  addCedenteLimit(body: PostAdminLimitCedentesBody) {
    return client.post<PostAdminLimitCedentesResponse>(
      quickfinEndpoints.admin.limit.cedentes.endpoint,
      body,
    );
  },
  getCompanyProducts(body: PostCompaniesProductsBody) {
    return client.post<PostCompaniesProductsResponse>(
      quickfinEndpoints.companies.products,
      body,
    );
  },
  getCompanyWithOnboarding(id: string | number) {
    return client.get<GetCompaniesWithOnboardingByIdResponse>(
      quickfinEndpoints.companies.withOnboarding.byId(id),
    );
  },
  portal() {
    return client.get<GetPortalResponse>(quickfinEndpoints.portal);
  },
  updateCompany(id: string | number, body: PutCompaniesUpdateByIdBody) {
    return client.put<PutCompaniesUpdateByIdResponse>(
      quickfinEndpoints.companies.update.byId(id),
      body,
    );
  },
  getCompanyEstrategias(companyId: string | number) {
    return client.get<GetEstrategiaComercialByCompanyIdResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.endpoint(companyId),
    );
  },
  addCompanyEstrategia(
    companyId: string | number,
    body: PostEstrategiaComercialByCompanyIdBody,
  ) {
    return client.post<PostEstrategiaComercialByCompanyIdResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.endpoint(companyId),
      body,
    );
  },
  editCompanyEstrategia(
    companyId: string | number,
    body: PatchEstrategiaComercialByCompanyIdBody,
  ) {
    return client.patch<PatchEstrategiaComercialByCompanyIdResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.endpoint(companyId),
      body,
    );
  },
  getCompanyEstrategia(
    companyId: string | number,
    estrategiaId: string | number,
  ) {
    return client.get<GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.byEstrategiaId.endpoint(
        companyId,
        estrategiaId,
      ),
    );
  },
  removeCompanyEstrategia(
    companyId: string | number,
    estrategiaId: string | number,
  ) {
    return client.delete<DeleteEstrategiaComercialByCompanyIdByEstrategiaIdResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.byEstrategiaId.endpoint(
        companyId,
        estrategiaId,
      ),
    );
  },
  setUnsetDefaultCompanyEstrategia(
    companyId: string | number,
    estrategiaId: string | number,
  ) {
    return client.patch<PatchEstrategiaComercialByCompanyIdByEstrategiaIdPadraoResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.byEstrategiaId.padrao(
        companyId,
        estrategiaId,
      ),
    );
  },
  createorUpdatePortalEstrategiaComercial(
    body: PostPortalEstrategiaComercialBody,
  ) {
    return client.post<PostPortalEstrategiaComercialResponse>(
      quickfinEndpoints.admin.portalEstrategiaComercial.createOrUpdate,
      body,
    );
  },
  verifyReceivables(
    id: string | number,
    body: PostCompaniesByIdBorderosVerifyBody,
  ) {
    return client.post<PostCompaniesByIdBorderosVerifyErrorResponse | null>(
      quickfinEndpoints.companies.byCompanyId.borderos.verify(id),
      body,
    );
  },
  uploadReceivables(
    id: string | number,
    body: PostCompaniesByIdBorderosUploadBody,
  ) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.borderos.upload(id),
      body,
    );
  },
  verifyMultiBorderos(body: PostAdminBorderosMultiCreateBody) {
    return client.post<PostCompaniesByIdBorderosVerifyErrorResponse | null>(
      quickfinEndpoints.admin.borderos.multiCreate.verify,
      body,
    );
  },
  processMultiBorderos(body: PostAdminBorderosMultiCreateBody) {
    return client.post(
      quickfinEndpoints.admin.borderos.multiCreate.process,
      body,
    );
  },
  getCompanyCedentes(id: string | number) {
    return client.get<GetCompaniesByIdCedentesResponse>(
      quickfinEndpoints.companies.byCompanyId.cedentes.endpoint(id),
    );
  },
  editCompanyCedente(
    id: string | number,
    body: PatchCompaniesByIdCedentesBody,
  ) {
    return client.patch(
      quickfinEndpoints.companies.byCompanyId.cedentes.endpoint(id),
      body,
    );
  },
  addCompanyCedente(id: string | number, body: PostCompaniesByIdCedentesBody) {
    return client.post<PostCompaniesByIdCedentesResponse>(
      quickfinEndpoints.companies.byCompanyId.cedentes.endpoint(id),
      body,
    );
  },
  getCompanyCedenteContractComplement(
    sacadoId: string | number,
    cedenteId: string | number,
    tipoProduto?: string | null,
  ) {
    return client.post<GetCompaniesByIdCedentesContractComplementResponse>(
      quickfinEndpoints.companies.byCompanyId.cedentes.contractComplement.get(
        sacadoId,
      ),
      { cedenteId, sacadoId, tipoProduto: tipoProduto ?? "RiscoSacado" },
    );
  },
  addCompanyCedenteContractComplement(
    id: string | number,
    body: PostCompaniesByIdCedentesContractComplementBody,
  ) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.cedentes.contractComplement.add(
        id,
      ),
      body,
    );
  },
  updateCompanyCedenteContractComplement(body: PostContractComplementBody) {
    return client.post(quickfinEndpoints.contracts.complement, body);
  },
  getCompanyContractTemplates(id: string | number) {
    return client.get<GetCompaniesByIdContractTemplatesResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.endpoint(id),
    );
  },
  addContractTemplate(
    id: string | number,
    body: PostCompaniesByIdContractTemplatesBody,
  ) {
    return client.post<PostCompaniesByIdContractTemplatesResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.endpoint(id),
      body,
    );
  },
  getCompanyContractTemplate(id: string | number, templateId: string | number) {
    return client.get<GetCompaniesByIdContractTemplatesByTemplateIdResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.byTemplateId.endpoint(
        id,
        templateId,
      ),
    );
  },
  updateCompanyContractTemplate(
    id: string | number,
    templateId: string | number,
    body: PutCompaniesByCompanyIdContractTemplatesByTemplateIdBody,
  ) {
    return client.put<PutCompaniesByCompanyIdContractTemplatesByTemplateIdResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.byTemplateId.endpoint(
        id,
        templateId,
      ),
      body,
    );
  },
  deleteCompanyContractTemplate(
    id: string | number,
    templateId: string | number,
  ) {
    return client.delete<DeleteCompaniesByCompanyIdContractTemplatesByTemplateIdResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.byTemplateId.endpoint(
        id,
        templateId,
      ),
    );
  },
  downloadCompanyContractTemplateFile(
    id: string | number,
    templateId: string | number,
  ) {
    return client.get<GetCompaniesByCompanyIdContractTemplatesByTemplateIdFileResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.byTemplateId.file(
        id,
        templateId,
      ),
    );
  },
  setDefaultCompanyContractTemplate(
    id: string | number,
    templateId: string | number,
  ) {
    return client.post<PostCompaniesByCompanyIdContractTemplatesByTemplateIdSetDefaultResponse>(
      quickfinEndpoints.companies.byCompanyId.contractTemplates.byTemplateId.setDefault(
        id,
        templateId,
      ),
    );
  },
  verifyCedentes(
    id: string | number,
    body: PostCompaniesByIdCedentesUploadVerifyBody,
  ) {
    return client.post<PostCompaniesByIdCedentesUploadVerifyErrorResponse>(
      quickfinEndpoints.companies.byCompanyId.cedentes.upload.verify(id),
      body,
    );
  },
  uploadCedentes(
    id: string | number,
    body: PostCompaniesByIdCedentesUploadBody,
  ) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.cedentes.upload.endpoint(id),
      body,
    );
  },
  getCompanySacados(id: string | number) {
    return client.get<GetCompaniesByIdSacadosResponse>(
      quickfinEndpoints.companies.byCompanyId.sacados.endpoint(id),
    );
  },
  editCompanySacado(id: string | number, body: PatchCompaniesByIdSacadosBody) {
    return client.patch(
      quickfinEndpoints.companies.byCompanyId.sacados.endpoint(id),
      body,
    );
  },
  addCompanySacado(id: string | number, body: PostCompaniesByIdSacadosBody) {
    return client.post<PostCompaniesByIdSacadosResponse>(
      quickfinEndpoints.companies.byCompanyId.sacados.endpoint(id),
      body,
    );
  },
  verifySacados(
    id: string | number,
    body: PostCompaniesByIdSacadosUploadVerifyBody,
  ) {
    return client.post<PostCompaniesByIdSacadosUploadVerifyErrorResponse>(
      quickfinEndpoints.companies.byCompanyId.sacados.upload.verify(id),
      body,
    );
  },
  uploadSacados(id: string | number, body: PostCompaniesByIdSacadosUploadBody) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.sacados.upload.endpoint(id),
      body,
    );
  },
  getCompanyEstrategiaTaxas(
    companyId: string | number,
    estrategiaId: string | number,
    currency: string,
  ) {
    return client.get<GetCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasByCurrencyResponse>(
      quickfinEndpoints.companies.byCompanyId.estrategias.byEstrategiaId.taxas.byCurrency(
        companyId,
        estrategiaId,
        currency,
      ),
    );
  },
  verifyTaxasFile(
    companyId: string | number,
    estrategiaId: string | number,
    body: PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyBody,
  ) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.estrategias.byEstrategiaId.taxas.verify(
        companyId,
        estrategiaId,
      ),
      body,
    );
  },
  uploadTaxasFile(
    companyId: string | number,
    estrategiaId: string | number,
    body: PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasBody,
  ) {
    return client.post(
      quickfinEndpoints.companies.byCompanyId.estrategias.byEstrategiaId.taxas.endpoint(
        companyId,
        estrategiaId,
      ),
      body,
    );
  },
  editCompanyEstrategiaRelationship(
    companyId: string | number,
    estrategiaId: string | number,
    atualizaCedentes: string,
    body: PostEstrategiaComercialByCompanyIdByEstrategiaIdByAtualizaCedentesRelacionamentoBody,
  ) {
    return client.post<PostEstrategiaComercialByCompanyIdByEstrategiaIdByAtualizaCedentesRelacionamentoResponse>(
      quickfinEndpoints.estrategiaComercial.byCompanyId.byEstrategiaId.byAtualizaCedentes.relacionamento(
        companyId,
        estrategiaId,
        atualizaCedentes,
      ),
      body,
    );
  },
  getMarketplaces() {
    return client.get<GetMarketplacesResponse>(quickfinEndpoints.marketplaces);
  },
  getPortfolios() {
    return client.get<GetPortfoliosResponse>(quickfinEndpoints.portfolios);
  },
  getCreditors() {
    return client.get<GetCreditorsResponse>(quickfinEndpoints.creditors);
  },
  getPublicContractTemplates() {
    return client.get<GetContractTemplatesResponse>(
      quickfinEndpoints.contractTemplates.endpoint,
    );
  },
  createPublicContractTemplate(body: PostContractTemplatesBody) {
    return client.post(quickfinEndpoints.contractTemplates.endpoint, body);
  },
  updatePublicContractTemplate(
    templateId: string | number,
    body: PutContractTemplatesByTemplateIdBody,
  ) {
    return client.put(
      quickfinEndpoints.contractTemplates.byTemplateId.endpoint(templateId),
      body,
    );
  },
  deletePublicContractTemplate(templateId: string | number) {
    return client.delete(
      quickfinEndpoints.contractTemplates.byTemplateId.endpoint(templateId),
    );
  },
  downloadPublicContractTemplateFile(templateId: string | number) {
    return client.get<GetContractTemplatesByIdFileResponse>(
      quickfinEndpoints.contractTemplates.byTemplateId.file(templateId),
    );
  },
  createAditamentos(body: PostAdminAditamentosCreateBody) {
    return client.post(quickfinEndpoints.admin.aditamentos.create, body);
  },
  createDocumentos(body: PostAdminDocumentosCreateBody) {
    return client.post(quickfinEndpoints.admin.documentos.create, body);
  },
  getReceivablesReport(body: PostAdminReceivablesReportBody) {
    return client.post(quickfinEndpoints.admin.receivablesReport, body);
  },
  getOnboardingsFornecedores() {
    return client.get<GeOdataOnboardingsFornecedoresODataResponse>(
      quickfinEndpoints.odata.onboardingsFornecedoresOData,
    );
  },
  getDashboardOnboardingsInfos(
    params: GetAdminDashboardOnboardingsInfosParams,
  ) {
    return client.get<GetAdminDashboardOnboardingsInfosResponse>(
      quickfinEndpoints.admin.dashboardOnboardings.infos,
      {
        params,
        paramsSerializer: {
          indexes: true,
        },
      },
    );
  },
  getCompanyDetails(params: GetCompaniesDetailsParams) {
    return client.get<GetCompaniesDetailsResponse>(
      quickfinEndpoints.companies.details,
      { params },
    );
  },
  deleteSettlementStrategyById(id: string) {
    return client.delete(
      quickfinEndpoints.odata.settlementStrategiesODataById(id),
    );
  },
  downloadComprovanteById(id: number) {
    return client.get<GetSchedulingByIdComprovanteTrueResponse>(
      quickfinEndpoints.scheduling.byId.comprovante(id),
      {
        responseType: "blob",
      },
    );
  },
  getAditamentoOData(params?: GetOdataAditamentoODataParams) {
    return client.get<GetOdataAditamentoODataResponse>(
      quickfinEndpoints.odata.aditamentoOData,
      { params },
    );
  },
  getAditamentosVerify(body: PostAdminAditamentosVerifyBody) {
    return client.post<PostAdminAditamentosVerifyResponse | null>(
      quickfinEndpoints.admin.aditamentos.verify,
      body,
    );
  },
  getAgendamentoView(params?: GetOdataAgendamentoViewParams) {
    return client.get<GetOdataAgendamentoViewResponse>(
      quickfinEndpoints.odata.agendamentoView,
      { params },
    );
  },
  getBorderosById(id: string) {
    return client.get<GetBorderosByIdResponse>(
      quickfinEndpoints.borderos.byId(id),
    );
  },
  getBorderosOData(params?: GetOdataBorderosODataParams) {
    return client.get<GetOdataBorderosODataResponse>(
      quickfinEndpoints.odata.borderosOData,
      { params },
    );
  },
  getCommercialStrategies(params?: GetOdataEstrategiasComerciaisOdataParams) {
    return client.get<GetOdataEstrategiasComerciaisOdataResponse>(
      quickfinEndpoints.odata.estrategiasComerciaisOdata,
      {
        params,
      },
    );
  },
  getDocumentosById(id: number) {
    return client.get<GetAdminDocumentosByIdZipResponse>(
      quickfinEndpoints.admin.documentos.byId.zip(id),
      {
        responseType: "blob",
      },
    );
  },
  getReceivableGroupById(id: string) {
    return client.get<GetSuperAdminReceivableByIdGroupResponse>(
      quickfinEndpoints.superAdmin.receivable.byId.group(id),
    );
  },
  getReceivablesApproval(params: GetOdataAprovacoesOdataViewParams) {
    return client.get<GetOdataAprovacaoOdataViewResponse>(
      quickfinEndpoints.odata.aprovacaoODataView,
      {
        params,
      },
    );
  },
  getReceivablesSummary(body: PostAdminReceivablesSummaryBody) {
    return client.post<PostAdminReceivablesSummaryResponse>(
      quickfinEndpoints.admin.receivables.summary,
      body,
    );
  },
  getReceivableStateMachineById(id: string) {
    return client.get<GetAdminReceivableStateMachineByIdResponse>(
      quickfinEndpoints.admin.receivableStateMachine.byId(id),
    );
  },
  getReceivablesTitles(body: PostAdminReceivablesBody) {
    return client.post<PostAdminReceivablesResponse>(
      quickfinEndpoints.admin.receivables.endpoint,
      body,
    );
  },
  getRelatoriosEmLoteAdminOData(
    params?: GetOdataRelatoriosEmLoteAdminODataParams,
  ) {
    return client.get<GetOdataRelatoriosEmLoteAdminODataResponse>(
      quickfinEndpoints.odata.relatoriosEmLoteAdminOData,
      { params },
    );
  },
  getSettlementBySettlementId(settlementId: string) {
    return client.get<GetOdataSettlementTradesOdataResponse>(
      quickfinEndpoints.admin.settlements.bySettlementId(settlementId),
    );
  },
  getSettlementStrategies(params: GetOdataSettlementStrategiesOdataParams) {
    return client.get<GetOdataSettlementStrategiesOdataResponse>(
      quickfinEndpoints.odata.settlementStrategiesOData,
      {
        params,
      },
    );
  },
  getSettlementStrategiesBySettlementId(settlementId: string) {
    return client.get<GetAdminSettlementStrategiesBySettlementIdResponse>(
      quickfinEndpoints.admin.settlementStrategies.bySettlementId(settlementId),
    );
  },
  getTradeComprovanteById(id: number) {
    return client.get<GetTradesByIdComprovanteResponse>(
      quickfinEndpoints.trades.byId.comprovante(id),
      {
        responseType: "blob",
      },
    );
  },
  groupForceCompletion(body: PostSuperAdminGroupsForceCompletionBody) {
    return client.post<PostSuperAdminGroupsForceCompletionResponse>(
      quickfinEndpoints.superAdmin.groups.forceCompletion,
      body,
    );
  },
  postCancelableReceivables(body: PostAdminCancelableReceivablesBody) {
    return client.post<PostAdminCancelableReceivablesResponse>(
      quickfinEndpoints.admin.cancelableReceivables,
      body,
    );
  },
  postCancelReceivables(body: PostAdminReceivablesCancelBody) {
    return client.post<PostAdminReceivablesCancelResponse>(
      quickfinEndpoints.receivables.cancel,
      body,
    );
  },
  postNewSettlementStrategy(body: PostsettlementStrategiesBody) {
    return client.post<PostAdminSettlementStrategies>(
      quickfinEndpoints.admin.settlementStrategies.endpoint,
      body,
    );
  },
  postReceivableApprovals(body: PostAdminReceivablesApproveBody) {
    return client.post(quickfinEndpoints.admin.receivables.approve, body);
  },
  putSettlementStrategy(body: PutAdminSettlementStrategiesBySettlementIdBody) {
    return client.put<PutAdminSettlementStrategiesBySettlementId>(
      quickfinEndpoints.admin.settlementStrategies.bySettlementId(
        body.id.toString(),
      ),
      body,
    );
  },
  republishEvent(body: PostSuperAdminEventsBody) {
    return client.post(quickfinEndpoints.superAdmin.events, body);
  },
  setAditamento(body: PatchSuperAdminAditamentoBody) {
    return client.patch(quickfinEndpoints.superAdmin.aditamento, body);
  },
  updateReceivableStateMachineStatus(
    body: PatchSuperAdminReceivableStateMachinesStatusBody,
  ) {
    return client.patch<PatchSuperAdminReceivableStateMachinesStatusResponse>(
      quickfinEndpoints.superAdmin.receivableStateMachine.status,
      body,
    );
  },
  updateReceivableStatus(body: PatchSuperAdminReceivablesStatusBody) {
    return client.patch<PatchSuperAdminReceivablesStatusResponse>(
      quickfinEndpoints.superAdmin.receivables.status,
      body,
    );
  },
  getAdminPayeesOData(params?: GetOdataAdminPayeesODataParams) {
    return client.get<GetOdataAdminPayeesODataResponse>(
      quickfinEndpoints.odata.adminPayeesOData,
      { params },
    );
  },
  getCompaniesDetails(params?: GetCompaniesDetailsParams) {
    return client.get<GetCompaniesDetailsResponse>(
      quickfinEndpoints.companies.details,
      { params },
    );
  },
  setCompanies(body: PostCompaniesBody) {
    return client.post<PostCompaniesResponse>(
      quickfinEndpoints.companies.endpoint,
      body,
    );
  },
  getAdminBalances() {
    return client.get<GetAdminBalancesResponse>(
      quickfinEndpoints.admin.balances,
    );
  },
  getAdminCompanyFlags() {
    return client.get<GetAdminCompanyFlagsResponse>(
      quickfinEndpoints.admin.companyFlags.endpoint,
    );
  },
  setAdminCompanyFlags(body: PostAdminCompanyFlagsBody) {
    return client.post<PostAdminCompanyFlagsResponse>(
      quickfinEndpoints.admin.companyFlags.endpoint,
      body,
    );
  },
  removeAdminCompanyFlags(id: number) {
    return client.delete<GetAdminCompanyFlagsResponse>(
      quickfinEndpoints.admin.companyFlags.byId(id),
    );
  },
  getOnboardingStrategiesOData(
    params?: GetOdataOnboardingStrategiesODataParams,
  ) {
    return client.get<GetOdataOnboardingStrategiesODataResponse>(
      quickfinEndpoints.odata.onboardingStrategiesOData.endpoint,
      { params },
    );
  },
  getCommercialStrategyByProductType(type: string) {
    return client.get<GetEstrategiaComercialGetByProductTypeByTypeResponse>(
      quickfinEndpoints.estrategiaComercial.getByProductType.byType(type),
    );
  },
  getEstrategiasByCompaniesProductType(
    body: PostCompanyEstrategiaComercialEstrategiasByCompaniesProductTypeBody,
  ) {
    return client.post<PostCompanyEstrategiaComercialEstrategiasByCompaniesProductTypeResponse>(
      quickfinEndpoints.companyEstrategiaComercial
        .estrategiasByCompaniesProductType,
      body,
    );
  },
  getAllEstrategiasSimplified(
    body: PostGetAllEstrategiasComerciaisSimplifiedBody,
  ) {
    return client.post<PostCompanyEstrategiaComercialEstrategiasByCompaniesProductTypeResponse>(
      quickfinEndpoints.estrategiaComercial.getAllSimplified.endpoint,
      body,
    );
  },
  setAdminOnboardingStrategy(body: PostAdminOnboardingStrategyBody) {
    return client.post<PostAdminOnboardingStrategyResponse>(
      quickfinEndpoints.admin.onboardingStrategy.endpoint,
      body,
    );
  },
  updateAdminOnboardingStrategy(body: PatchAdminOnboardingStrategyBody) {
    return client.patch<PatchAdminOnboardingStrategyResponse>(
      quickfinEndpoints.admin.onboardingStrategy.endpoint,
      body,
    );
  },
  getUpdateOnboardingTypeById(id: string | number) {
    return client.get<GetAdminOnboardingStrategyByIdUpdateOnboardingTypeResponse>(
      quickfinEndpoints.admin.onboardingStrategy.byId.updateOnboardingType(id),
    );
  },
  getAdminOnboardingStrategyById(id: string | number) {
    return client.get<GetAdminOnboardingStrategyByIdResponse>(
      quickfinEndpoints.admin.onboardingStrategy.byId.endpoint(id),
    );
  },
  deleteOnboardingStrategiesODataById(id: string | number) {
    return client.delete<DeleteOdataOnboardingStrategiesODataByIdResponse>(
      quickfinEndpoints.odata.onboardingStrategiesOData.byId(id),
    );
  },
  createAdminTCOStrategy(body: PostAdminTCOStrategyBody) {
    return client.post<number>(
      quickfinEndpoints.admin.tcoStrategy.endpoint,
      body,
    );
  },
  updateAdminTCOStrategy(body: PatchAdminTCOStrategyBody) {
    return client.patch<number>(
      quickfinEndpoints.admin.tcoStrategy.endpoint,
      body,
    );
  },
  getAdminTCOStrategyById(id: string | number) {
    return client.get<GetAdminTCOStrategyByIdResponse>(
      quickfinEndpoints.admin.tcoStrategy.byId.endpoint(id),
    );
  },
  deleteTCOStrategyODataById(id: string | number) {
    return client.delete<boolean>(
      quickfinEndpoints.admin.tcoStrategy.byId.endpoint(id),
    );
  },
  getCompaniesRelationships(
    params?: GetOdataCompaniesRelationshipsODataParams,
  ) {
    return client.get<GetOdataCompaniesRelationshipsODataResponse>(
      quickfinEndpoints.odata.companiesRelationshipsOData,
      {
        params,
      },
    );
  },
  getEstrategias() {
    return client.get<GetTemplateEstrategiaComercialResponse>(
      quickfinEndpoints.templateEstrategiaComercial.endpoint,
    );
  },
  addEstrategia(body: PostTemplateEstrategiaComercialBody) {
    return client.post<PostTemplateEstrategiaComercialResponse>(
      quickfinEndpoints.templateEstrategiaComercial.endpoint,
      body,
    );
  },
  editEstrategia(body: PatchTemplateEstrategiaComercialBody) {
    return client.patch<PatchTemplateEstrategiaComercialResponse>(
      quickfinEndpoints.templateEstrategiaComercial.endpoint,
      body,
    );
  },
  updateEstrategiasConfigTaxa(
    estrategiaId: string | number,
    body: PatchTemplateEstrategiaComercialByEstrategiaIdConfigTaxaBody,
  ) {
    return client.patch(
      quickfinEndpoints.templateEstrategiaComercial.byEstrategiaId.configTaxa(
        estrategiaId,
      ),
      body,
    );
  },
  editEstrategiaRelationship(
    estrategiaId: string | number,
    atualizaEstrategias: string | boolean,
    atualizaCedentes: string | boolean,
    body: PatchTemplateEstrategiaComercialByEstrategiaIdByAtualizaEstrategiasByAtualizaCedentesRelacionamentoBody,
  ) {
    return client.patch<PatchTemplateEstrategiaComercialByEstrategiaIdByAtualizaEstrategiasByAtualizaCedentesRelacionamentoResponse>(
      quickfinEndpoints.templateEstrategiaComercial.byEstrategiaId.byAtualizaEstrategias.byAtualizaCedentes.relacionamento(
        estrategiaId,
        atualizaEstrategias,
        atualizaCedentes,
      ),
      body,
    );
  },
  getDistinctValues(entityName: string, propertyName: string) {
    return client.get<GetAdminDistinctValuesByEntityNameByPropertyNameResponse>(
      quickfinEndpoints.admin.distinctValues.byEntityName.byPropertyName(
        entityName,
        propertyName,
      ),
    );
  },
  getContractSignatories() {
    return client.get<GetAdminUserFlagAllAssinantesContratosCartoesResponse>(
      quickfinEndpoints.admin.userFlag.allAssinantesContratosCartoes,
    );
  },
  getCustomerContractSignatories(taxId: string) {
    return client.get<GetAdminUserFlagAssinantesContratosCartoesByTaxIdResponse>(
      quickfinEndpoints.admin.userFlag.assinantesContratosCartoes.byTaxId(
        taxId,
      ),
    );
  },
  createContractSignatories(
    body: PostAdminUserFlagAssinantesContratosCartoesBody,
  ) {
    return client.post<PostAdminUserFlagAssinantesContratosCartoesResponse>(
      quickfinEndpoints.admin.userFlag.assinantesContratosCartoes.endpoint,
      body,
    );
  },
  updateContractSignatories(
    body: PatchAdminUserFlagAssinantesContratosCartoesBody,
  ) {
    return client.patch<PatchAdminUserFlagAssinantesContratosCartoesResponse>(
      quickfinEndpoints.admin.userFlag.assinantesContratosCartoes.endpoint,
      body,
    );
  },
  exportAllNotes(body: PostAdminReceivablesExportBody) {
    return client.post<PostAdminReceivablesExportResponse>(
      quickfinEndpoints.admin.receivables.export,
      body,
      {
        responseType: "blob",
      },
    );
  },
  editScheduling(body: PatchSuperAdminAgendamentoBody) {
    return client.patch(quickfinEndpoints.superAdmin.agendamento, body);
  },
  getInstrumentCode(productType: string) {
    return client.get<GetEstrategiaComercialGetInstrumentCodeByProductTypeByProductTypeResponse>(
      quickfinEndpoints.estrategiaComercial.getInstrumentCodeByProductType.byProductType(
        productType,
      ),
    );
  },
  sendEmailCotacao(body: PostEmailCotacaoBody[]) {
    return client.post<string>(quickfinEndpoints.emailCotacao, body);
  },
  downloadSearchQuotationFile(body: PostAdminReceivablesSearchBody) {
    return client.post<PostAdminReceivablesQuotationFileResponse>(
      quickfinEndpoints.admin.receivables.searchQuotationFile,
      {
        receivableParams: body,
      },
    );
  },
  downloadSuggestQuotationFile(body: PostAdminReceivablesSuggestBody) {
    return client.post<PostAdminReceivablesQuotationFileResponse>(
      quickfinEndpoints.admin.receivables.suggestQuotationFile,
      body,
    );
  },
  getSacadosSummaries(identification: string, groupBy: string) {
    return client.get<GetSacadosSummariesResponse>(
      quickfinEndpoints.v2.summaries(identification, groupBy),
    );
  },
  getCartaoLimits(params: IGetLimitBagsByProductParams) {
    return limitControlService.getLimitsBagsByProduct(params);
  },
  getDependentDetails(id: string) {
    return limitControlService.getLimitBagWithDependents("QuickFin", id);
  },
  createDependentLimit(id: string, params: ICreateLimitBagParams) {
    return limitControlService.createDependentLimitBag(
      `QuickFin:${id}`,
      params,
    );
  },
  getReceivablesReprocess(params: GetOdataReceivableReprocessOdataViewParams) {
    return client.get<GetOdataReceivableReprocessOdataViewResponse>(
      quickfinEndpoints.odata.receivableReprocessODataView,
      {
        params,
      },
    );
  },
  postReceivableReprocess(body: PostAdminReceivablesReprocessBody) {
    return client.post(quickfinEndpoints.admin.receivables.reprocess, body);
  },
  getTradesPayments(maturityDate: string) {
    return client.get<GetTradesPaymentsResponseItem[]>(
      quickfinEndpoints.payments(maturityDate),
    );
  },
  postAdminReceivablesCalculateAvgs(
    body: PostAdminReceivablesCalculateAvgsBody,
  ) {
    return client.post<PostAdminReceivablesCalculateAvgsResponse>(
      quickfinEndpoints.admin.receivables.calculateAvgs,
      body,
    );
  },
};
