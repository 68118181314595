import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { FormValuesType } from "../formulario/onboarding-strategies-form.types";

export const useSelectStrategies = () => {
  const [allStrategies, setAllStrategies] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const form = useFormContext<FormValuesType>();
  const { watch } = form;

  const watchProductType = watch("productType");
  const watchSacados = watch("sacadosIds");

  const { callService: getStrategies, value: dataStrategies } = useServiceCall(
    service.quickfin.getEstrategiasByCompaniesProductType,
  );

  const {
    callService: getCommercialStrategies,
    value: dataCommercialStrategies,
  } = useServiceCall(service.quickfin.getCommercialStrategyByProductType);

  useEffect(() => {
    if (!watchProductType || !watchSacados?.length) return;

    getStrategies({ sacadosIds: watchSacados, productType: watchProductType });
  }, [getStrategies, watchProductType, watchSacados]);

  useEffect(() => {
    if (!watchProductType || watchSacados?.length) return;

    getCommercialStrategies(watchProductType);
  }, [getCommercialStrategies, watchProductType, watchSacados]);

  useEffect(() => {
    if (!dataStrategies) return;

    const newData = dataStrategies.estrategias.map((d) => {
      return {
        value: String(d.id),
        label: d.description,
      };
    });

    setAllStrategies(newData);
  }, [dataStrategies]);

  useEffect(() => {
    if (!dataCommercialStrategies) return;

    const newData = dataCommercialStrategies.estrategias.map((d) => {
      return {
        value: String(d.id),
        label: d.description,
      };
    });

    setAllStrategies(newData);
  }, [dataCommercialStrategies]);

  return {
    allStrategies,
  };
};
