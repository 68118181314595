import { OToastManager } from "@maestro/core";
import { OInputPhone } from "@maestro/react";
import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../../../utils";

export const buildDataSource = (customerId: number) =>
  dataSourceCustomStoreGenerator(
    () =>
      service.hubEnergy
        .getCustomerContacts(customerId)
        .then(({ data: { response } }) => response)
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0]?.errorMessage ??
            "Erro ao buscar os contatos";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        update: async (original, updated) => {
          const data = {
            ...original,
            ...updated,
          };

          try {
            await service.hubEnergy.updateCustomerContact({
              entityId: customerId,
              contact: data,
            });
            OToastManager.success("Contato atualizado com sucesso");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Erro ao atualizar o contato";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        insert: async (data) => {
          try {
            await service.hubEnergy.addCustomerContact({
              entityId: customerId,
              contact: {
                id: null,
                isMain: data.isMain,
                name: data.name,
                email: data.email,
                phoneNumber: data.phoneNumber,
              },
            });
            OToastManager.success("Contato adicionado com sucesso");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Erro ao adicionar o contato";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
          return data;
        },
        remove: async (data) => {
          try {
            await service.hubEnergy.removeCustomerContact(data.id, customerId);
            OToastManager.success("Contato removido com sucesso");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Erro ao remover o contato";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const contactsGrid = (
  allowUpdating: boolean,
): ODataGridGeneratorConfig<HubEnergy.ContactResponse> => ({
  datagrid: {
    noDataText: "Nenhum contato",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("mle-contactsGrid"),
    editing: {
      confirmDelete: allowUpdating,
      allowDeleting: allowUpdating,
      allowUpdating,
    },
    onRowValidating: (e) => {
      const { email } = {
        ...e.oldData,
        ...e.newData,
      };
      const noEditingValues = e.component
        .getVisibleRows()
        .flatMap((row) => (row.isEditing ? [] : [row.data]));

      const hasContactWithSameEmail = noEditingValues.some(
        (item) => item.email === email,
      );

      if (hasContactWithSameEmail) {
        e.errorText = "Email já cadastrado";
        e.isValid = false;
      }
    },
  },
  columns: [
    {
      caption: "Nome",
      dataField: "name",
      dataType: "string",
    },
    {
      caption: "Contato principal?",
      dataField: "isMain",
      dataType: "boolean",
      validationRules: [
        {
          type: "custom",
          validationCallback: ({ value }) => typeof value === "boolean",
        },
      ],
    },
    {
      caption: "Telefone",
      dataField: "phoneNumber",
      dataType: "string",
      format: (value) => masks.phone(value),
      editCellRender: ({ setValue, rowIndex, value }) => (
        <OInputPhone
          id={`phone-number-${rowIndex}`}
          name={`phone-number-${rowIndex}`}
          value={value}
          onInput={(evt) => setValue(evt.currentTarget.value)}
          aspect="unstyled"
          className="p-2"
        />
      ),
      validationRules: [
        {
          type: "custom",
          message: "Telefone inválido",
          validationCallback: (option) => {
            return !option.value || validators.phone(option.value);
          },
        },
      ],
    },
    {
      caption: "Email",
      dataField: "email",
      dataType: "string",
      validationRules: [
        {
          type: "custom",
          message: "Email inválido",
          validationCallback: (option) => {
            return validators.email(option.value);
          },
        },
      ],
    },
  ],
});
