import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GetAdminBalancesResponseItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./overdue-positions.grid";

const dataSource = dataSourceCustomStoreGenerator<GetAdminBalancesResponseItem>(
  () =>
    service.quickfin
      .getAdminBalances()
      .then(({ data }) => data)
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar posições em atraso";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const OverduePositions = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Posições em Atraso"
          description="Lista de empresas com posições em aberto após a data de vencimento."
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
