import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { syncCpfConfirmationModalId } from "../../operator-details.utils";

export const SyncCpfConfirmationModal = () => {
  const form = useFormContext();
  const { setValue, watch } = form;
  const watchIdentification = watch("identification");

  const fillByCpfCnpj = useCallback(async () => {
    const body = {
      taxId: watchIdentification,
    };
    try {
      const { data } = await service.onboarding.searchLegalEntity(body);
      setValue("name", data.officialName);
      modalManager.hide(syncCpfConfirmationModalId);
    } catch {
      OToastManager.danger("Falha ao obter dados da PF");
    }
  }, [setValue, watchIdentification]);

  return (
    <OConfirmationModal
      modalId={syncCpfConfirmationModalId}
      title="Deseja sincronizar as informações dessa PF?"
      onConfirm={fillByCpfCnpj}
    />
  );
};
