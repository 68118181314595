import {
  OConfirmationModal,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useEffect, useState } from "react";
import { service } from "services";

interface ContactsFavoredDeleteModalProps {
  uuid: string | null;
}

export const ContactsFavoredDeleteModal = ({
  uuid,
}: ContactsFavoredDeleteModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (!uuid) return;

      const { data } = await service.adminBankinghub.getContactByHolder();

      const contact = data.find((c) =>
        c.product.bankAccounts?.some((a) => a.id === uuid),
      );

      if (!contact) return;

      const bankAccounts =
        contact.product.bankAccounts?.filter((bank) => bank.id !== uuid) || [];

      const payload = {
        ...contact,
        product: { ...contact.product, bankAccounts },
      };

      await service.adminBankinghub.deleteContact(payload);

      OToastManager.success("Conta excluida com sucesso");

      modalManager.hide("contacts-favored-delete-modal");
    } catch (error) {
      OToastManager.danger("Erro para deletar conta");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!uuid) modalManager.hide("contacts-favored-delete-modal");
  }, [uuid]);

  return (
    <OConfirmationModal
      modalId="contacts-favored-delete-modal"
      size="sm"
      title="Excluir Conta"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      onConfirm={handleConfirm}
      loading={loading}
    >
      <OTypography tag="p" size="md" type="dark-80" className="mb-3">
        Este processo irá excluir esta conta. Você deseja continuar ?
      </OTypography>
    </OConfirmationModal>
  );
};
