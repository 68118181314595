import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  estrategiasComerciaisGrid,
} from "./estrategia-comercial.grid";

export const EstrategiaComercialPage = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(quickfinId), [quickfinId]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Estratégia comercial" />}
      actions={
        <StandardLink
          href={
            corporateRouter.routes.recebiveis.customer.estrategiaComercial
              .addEstrategia.path
          }
          role={
            roles.recebiveis.customer.estrategiaComercialAddEstrategiaPage.role
          }
        >
          Adicionar
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={estrategiasComerciaisGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
