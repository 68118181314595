import { ODataGridGeneratorConfig } from "components/data-grid";
import {
  GetGatekeeperGetActsResponse,
  PostGatekeeperProfilesGetActsResponseItem,
} from "services/gatekeeper/models";

export const buildGrid = (
  acts?: GetGatekeeperGetActsResponse,
): ODataGridGeneratorConfig<PostGatekeeperProfilesGetActsResponseItem> => ({
  datagrid: {
    noDataText: "Nenhum ato encontrado.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      allowDeleting: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      allowHeaderFiltering: false,
    },
    {
      dataField: "name",
      caption: "Nome",
      lookup: {
        dataSource: acts?.map((act) => ({
          title: act.name,
        })),
        displayExpr: "title",
        valueExpr: "title",
      },
    },
  ],
});
