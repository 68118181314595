import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import {
  buildDetailsDataSource,
  buildDetailsGrid,
} from "./onboarding-details.grid";
import { UserAgentModal } from "./user-agent-modal.grid";
import { OnboardingDetailsByUUIDRouteParams } from "../../../../routes/cadastro.route-params";

export const UserAgentModalId = (externalReference?: string) =>
  "modal-" + externalReference;

export const OnboardingDetailsLogs = () => {
  const { externalReference } = useParams<OnboardingDetailsByUUIDRouteParams>();
  const [userAgentText, setUserAgentText] = useState<string>("");
  const dataSource = useMemo(
    () => buildDetailsDataSource(externalReference),
    [externalReference],
  );
  const grid = useMemo(
    () =>
      buildDetailsGrid(setUserAgentText, UserAgentModalId(externalReference)),
    [setUserAgentText, externalReference],
  );
  const gridRef = useGridRef();
  if (!externalReference)
    throw new Error("No external reference in route parameters");

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Cadastros" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
    >
      <ODataGridGenerator
        grid={grid}
        gridRef={gridRef}
        dataSource={dataSource}
      />
      <UserAgentModal
        userAgentModalId={UserAgentModalId(externalReference)}
        content={userAgentText}
      />
    </GridTemplate>
  );
};
