import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { downloadJson } from "./canvas-context-menu.utils";

export const useCanvasContextMenu = () => {
  const { callService: _exportCanvas, loading: exportLoading } = useServiceCall(
    service.hubCreditManager.exportCanvas,
  );

  const { selectedNodes } = useCanvas();

  const exportCanvas = useCallback(async () => {
    const { success, response } = await _exportCanvas({
      processorConfigIds: selectedNodes
        .map((node) => node.data.id)
        .filter((id) => !!id),
    });

    if (success) {
      OToastManager.success("Exportado com sucesso");
      downloadJson(response.data);
    } else {
      OToastManager.danger("Erro ao exportar");
    }
  }, [_exportCanvas, selectedNodes]);

  return { exportCanvas, exportLoading };
};
