export const recebiveisRouteParams = {
  aditamentoId: "aditamentoId",
  batcheId: "batcheId",
  calendarId: "calendarId",
  cedenteId: "cedenteId",
  cedenteType: "cedenteType",
  companyId: "companyId",
  estrategiaId: "estrategiaId",
  groupId: "groupId",
  operationId: "operationId",
  receivableId: "receivableId",
  ruleId: "ruleId",
  sacadoId: "sacadoId",
  settlementId: "settlementId",
  strategyId: "strategyId",
  templateId: "templateId",
  contractId: "contractId",
} as const;

type RecebiveisParams = Record<keyof typeof recebiveisRouteParams, string>;

export type RecebiveisClienteCedentesByCedenteIdRouteParams = Pick<
  RecebiveisParams,
  "cedenteId" | "strategyId"
>;

export type RecebiveisClienteSacadosBySacadoIdRouteParams = Pick<
  RecebiveisParams,
  "sacadoId" | "strategyId"
>;

export type RecebiveisClienteAssinaturasByRuleIdRouteParams = Pick<
  RecebiveisParams,
  "ruleId"
>;

export type RecebiveisClienteAssinaturasByRuleIdByGroupIdRouteParams = Pick<
  RecebiveisParams,
  "ruleId" | "groupId"
>;

export type RecebiveisClienteEstrategiaComercialByEstrategiaIdRouteParams =
  Pick<RecebiveisParams, "estrategiaId">;

export type RecebiveisClienteTemplatesContratoByTemplateIdRouteParams = Pick<
  RecebiveisParams,
  "templateId"
>;

export type ReceivablesCustomerByCompanyIdRouteParams = Pick<
  RecebiveisParams,
  "companyId"
>;

export type ReceivablesCustomerByOperationIdRouteParams = Pick<
  RecebiveisParams,
  "operationId"
>;

export type ReceivablesCustomerByBatcheIdRouteParams = Pick<
  RecebiveisParams,
  "batcheId"
>;

export type ReceivablesCustomerByReceivableIdRouteParams = Pick<
  RecebiveisParams,
  "receivableId"
>;

export type ReceivablesCustomerByReceivableIdAndAditamentoIdRouteParams = Pick<
  RecebiveisParams,
  "receivableId" | "aditamentoId"
>;

export type ReceivablesCustomerBySettlementIdRouteParams = Pick<
  RecebiveisParams,
  "settlementId"
>;

export type ReceivablesCustomerByStrategyIdRouteParams = Pick<
  RecebiveisParams,
  "strategyId"
>;

export type ReceivablesProductByCedenteIdByCedenteTypeRouteParams = Pick<
  RecebiveisParams,
  "cedenteId" | "cedenteType"
>;

export type ReceivablesProductContractTemplatesByTemplateIdRouteParams = Pick<
  RecebiveisParams,
  "templateId"
>;

export type RecebiveisProductEstrategiaTemplatesByEstrategiaIdRouteParams =
  Pick<RecebiveisParams, "estrategiaId">;

export type RecebiveisProductFillContractPendingComplementsRouteParams = Pick<
  RecebiveisParams,
  "sacadoId" | "cedenteId" | "contractId"
>;

export type RecebiveisProductCalendarsCalendarByIdRouteParams = Pick<
  RecebiveisParams,
  "calendarId"
>;
