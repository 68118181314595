import { TrackingStatus } from "../../tracking.types";

export const MapperTrackingStatusToAlertMessages: Record<string, Function> = {
  [TrackingStatus.UpdatedAddressWrongAddress]: () => [
    "Tentamos fazer a entrega, mas o endereço não foi localizado. É preciso revisar o endereço para fazermos uma nova entrega. O prazo para revisar o endereço antes que o cartão seja cancelado é de 30 dias.",
  ],
  [TrackingStatus.UpdatedAddressCustomerAway]: () => [
    "Tentamos entregar o cartão, mas não havia ninguém para recebê-lo. É preciso confirmar o endereço e se certificar de que haverá alguém maior de 18 anos no local para receber o cartão.",
    "O prazo para revisar o endereço antes que o cartão seja cancelado é de 30 dias.",
  ],
  [TrackingStatus.UpdatedAddress]: () => [
    "Tentamos entregar o cartão, mas não foi possível porque o endereço informado está incorreto. É preciso revisar o endereço para fazermos uma nova entrega.",
    "O prazo para revisar o endereço antes que o cartão seja cancelado é de 30 dias.",
  ],
  [TrackingStatus.Destroyed]: (cardNumber: number) => [
    `A entrega do cartão final ${cardNumber} foi cancelada. É preciso solicitar um novo cartão.`,
  ],
};
