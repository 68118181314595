import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddWithdraw } from "./add.hook";
import { documentTypeMap, countryCodeMap } from "../../../../utils";

export const AddWithdraw = () => {
  const { form, loading, submit } = useAddWithdraw();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Cadastrar Sacado"
          description="Adicione uma empresa"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="cadastrar_sacado:botao:criar_empresa"
          dataLabel="criar_empresa"
          onClick={submit}
        >
          Criar empresa
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldSelect
                  id="companyEntityType"
                  name="companyEntityType"
                  dataAction="cadastrar_sacado:input:tipo_de_documento"
                  dataLabel="tipo_de_documento"
                  label="Tipo de documento"
                  placeholder="Selecione"
                  required
                >
                  {Object.values(documentTypeMap).map(({ label, value }) => (
                    <OOption key={value} value={value}>
                      {label}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldSelect
                  dataAction="detalhes:select:country"
                  dataLabel="country"
                  id="country"
                  name="country"
                  label="País"
                  placeholder="Selecione"
                  required
                >
                  {Object.values(countryCodeMap).map(({ label, value }) => (
                    <OOption key={value} value={value}>
                      {label}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="identificacao"
                  name="identificacao"
                  dataAction="cadastrar_sacado:input:identificacao"
                  dataLabel="identificacao"
                  label="Identificação"
                  required
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="officialName"
                  name="officialName"
                  dataAction="cadastrar_sacado:input:razao_social"
                  dataLabel="razao_social"
                  label="Razão Social"
                  required
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
