import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { service } from "services";
import {
  CardModality,
  ProposalStatus,
} from "services/bankinghub/models/types/cards";
import { useCardsCustomerContext } from "../../../../../../../contexts";
import { useCardHiringContext } from "../../../card-hiring.context";
import { OfferedCard } from "../../../card-hiring.types";
import {
  assembleDefaultPrintedName,
  assembleOfferedCardFromAccount,
  assembleOfferedCardFromOffer,
  unmaskDocument,
  unmaskPhone,
} from "../../../card-hiring.utils";
import { CardHiringForm } from "./card-hiring-form.types";
import { validatePhone } from "./card-hiring-form.utils";

export const useCardHiringForm = (form: UseFormReturn<CardHiringForm>) => {
  const { account, hasAccountRegistered, offer, hasOfferActive } =
    useCardsCustomerContext();

  const { accountProposals } = useCardHiringContext();

  const {
    callService: getCardsOwnerAddresses,
    loading: getCardsOwnerAddressesLoading,
    value: getCardsOwnerAddressesResponse,
  } = useServiceCall(service.bankinghub.getCardsOwnerAddresses);

  const { callService: getCardsOwners, loading: getCardsOwnersLoading } =
    useServiceCall(service.bankinghub.getCardsOwners);

  const {
    callService: postCardsCreateOwner,
    loading: postCardsCreateOwnerLoading,
  } = useServiceCall(service.bankinghub.postCardsCreateOwner);

  const { setValue, clearErrors, setError, watch, resetField } = form;

  const ownerDocumentWatcher = watch("ownerDocument");

  const phoneWatcher = watch("phone");

  const handleOwnerDocument = useCallback(
    async (ownerDocument: string | undefined) => {
      if (!ownerDocument) return;

      const {
        success: getCardsOwnersSuccess,
        response: getCardsOwnersResponse,
      } = await getCardsOwners({
        document: unmaskDocument(ownerDocument),
      });

      if (!getCardsOwnersSuccess)
        return setError("ownerDocument", {
          message: "Não foi possível localizar os dados do portador",
        });

      const [owner] = getCardsOwnersResponse.data?.owners ?? [];

      if (owner) {
        clearErrors("ownerDocument");
        setValue("ownerId", owner.id);
        setTimeout(() => {
          setValue("phone", masks.phone(owner.phone));
          setValue("printedName", assembleDefaultPrintedName(owner.name));
        }, 500);
        return;
      }

      const fieldsToClear: (keyof CardHiringForm)[] = [
        "ownerId",
        "phone",
        "printedName",
      ];

      fieldsToClear.forEach((field) => setValue(field, ""));

      OToastManager.warning({
        title: "Portador do cartão",
        message:
          "Por favor, preencha o número do celular para seleção do endereço de entrega",
      });
    },
    [clearErrors, getCardsOwners, setError, setValue],
  );

  useEffect(() => {
    handleOwnerDocument(ownerDocumentWatcher);
  }, [ownerDocumentWatcher, handleOwnerDocument]);

  const ownerIdWatcher = watch("ownerId");

  const handlePhone = useCallback(
    async (phone: string | undefined) => {
      if (!ownerDocumentWatcher) return;

      if (!validatePhone(phone)) return;

      const {
        success: postCardsCreateOwnerSuccess,
        response: postCardsCreateOwnerResponse,
      } = await postCardsCreateOwner({
        document: unmaskDocument(ownerDocumentWatcher),
        phone: unmaskPhone(phone),
      });

      if (!postCardsCreateOwnerSuccess)
        return setError("ownerDocument", {
          message: "Não foi possível registrar o portador",
        });

      clearErrors("ownerDocument");
      setValue("ownerId", postCardsCreateOwnerResponse.data.id);
    },
    [
      ownerDocumentWatcher,
      clearErrors,
      postCardsCreateOwner,
      setError,
      setValue,
    ],
  );

  useEffect(() => {
    if (!ownerIdWatcher) handlePhone(phoneWatcher);
  }, [phoneWatcher, ownerIdWatcher, handlePhone]);

  const offeredCardOptions = useMemo(() => {
    if (hasAccountRegistered === true && !!account) {
      const { isCreditFullyHired } = account.offer;
      const { isOnDebitHiringProcess } = account.offer;
      return accountProposals?.reduce<OfferedCard[]>((options, proposal) => {
        const option = assembleOfferedCardFromAccount({
          proposal,
          offerId: account.offer.id,
          garantedLimit: account.offer.limitAnalysis.amount,
        });

        const isCredit = proposal.modality === CardModality.Credit;
        const isDebit = proposal.modality === CardModality.Debit;

        const isAvailable = proposal.status === ProposalStatus.Available;

        if (isCredit && isAvailable && !isCreditFullyHired) return options;
        if (isDebit && isAvailable && isOnDebitHiringProcess) return options;
        return options.concat(option);
      }, []);
    }

    if (hasOfferActive === true && !!offer) {
      const { isOnHiringProcess } = offer;
      const { isOnDebitHiringProcess } = offer;
      return offer.programs?.reduce<OfferedCard[]>((options, program) => {
        const option = assembleOfferedCardFromOffer({
          program,
          offerId: offer.id,
          grantedLimit: offer.grantedLimit,
        });

        const isCredit = program.modality === CardModality.Credit;
        const isDebit = program.modality === CardModality.Debit;

        if (isCredit && isOnHiringProcess) return options;
        if (isDebit && isOnDebitHiringProcess) return options;
        return options.concat(option);
      }, []);
    }
  }, [hasAccountRegistered, account, accountProposals, offer, hasOfferActive]);

  const {
    value: getStatementAccountsResponse,
    callService: getStatementAccounts,
  } = useServiceCall(service.bankinghub.getStatementAccounts);

  const statementAccounts = useMemo(() => {
    if (!offeredCardOptions) return;

    const requireStatementAccounts = !!offeredCardOptions.find(({ modality }) =>
      [CardModality.Debit, CardModality.Multiple].includes(modality),
    );

    if (!requireStatementAccounts) return;

    if (!getStatementAccountsResponse) {
      getStatementAccounts();
      return;
    }

    const data = getStatementAccountsResponse?.data?.filter(
      ({ agency }) => agency === 50,
    );

    const hasASingleAccount = data?.length === 1;

    if (hasASingleAccount) {
      const [{ number, agency }] = data;
      setValue("accountNumber", number);
      setValue("branch", String(agency));
    }

    return data;
  }, [
    offeredCardOptions,
    getStatementAccountsResponse,
    getStatementAccounts,
    setValue,
  ]);

  const loadOwnerAddresses = useCallback(
    async (ownerId: string | undefined) => {
      if (!ownerId) return;

      const { success, response } = await getCardsOwnerAddresses(ownerId);

      if (!success)
        return setError("addressId", {
          message: "Não foi possível carregar os endereços cadastrados",
        });

      if (!response.data.addresses?.length)
        return setError("addressId", {
          message: "Nenhum endereço cadastrado",
        });

      clearErrors("addressId");
    },
    [clearErrors, getCardsOwnerAddresses, setError],
  );

  const ownerAddresses = useMemo(() => {
    resetField("addressId");

    if (!getCardsOwnerAddressesResponse) return;

    const { owner, addresses } = getCardsOwnerAddressesResponse;
    if (owner.id !== ownerIdWatcher) return;

    if (addresses.length === 1) {
      const [{ id }] = addresses;
      setValue("addressId", id);
    }

    return addresses;
  }, [ownerIdWatcher, getCardsOwnerAddressesResponse, resetField, setValue]);

  useEffect(() => {
    loadOwnerAddresses(ownerIdWatcher);
  }, [loadOwnerAddresses, ownerIdWatcher]);

  const programIdWatcher = watch("programId");

  const issueDates = useMemo(() => {
    if (!offeredCardOptions) return;
    return offeredCardOptions.find(
      ({ programId }) => programId === programIdWatcher,
    )?.issueDates;
  }, [offeredCardOptions, programIdWatcher]);

  return {
    offeredCardOptions,
    issueDates,
    ownerAddresses,
    statementAccounts,
    getCardsOwnersLoading,
    postCardsCreateOwnerLoading,
    loadingOwnerAddresses: getCardsOwnerAddressesLoading,
    loadOwnerAddresses,
  };
};
