import { useCallback } from "react";
import { service } from "services";

export const useHiringStatus = (documentNumber: string) => {
  const getLimit = useCallback(async () => {
    const { data } = await service.hubCreditManager.getLimit({
      taxId: documentNumber,
      identification: "ANTECIPACAO_DE_RECEBIVEIS_BOLETOS",
      companyTaxId: "",
      userEmail: "",
      originSystem: "",
      originIP: "",
    });
    return data;
  }, [documentNumber]);

  const getPmeAccount = useCallback(async () => {
    const { data } = await service.clerk.getPmeAccount({
      identification: documentNumber,
      branch: "1",
      type: "VINCULADA",
    });
    return data;
  }, [documentNumber]);

  return { getLimit, getPmeAccount };
};
