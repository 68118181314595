import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  PatchEstrategiaComercialByCompanyIdBody,
  PostPortalEstrategiaComercialBody,
} from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  EstrategiaForm,
  addEstrategiaFormDefaultValues,
  addEstrategiaFormValidationSchema,
  productFormMap,
} from "../../../../../../components/estrategia-form";
import { useEstrategiaComercial } from "../../_compose";
import {
  buildPayload,
  toastErrors,
} from "../adicionar/add-company-estrategia.utils";
import { buildInitialValues } from "./company-estrategia-details.utils";
import {
  GetTaxasConfigTaxaByEstrategiaUuidResponse,
  PostTaxasConfigTaxaBody,
} from "services/senna/models";

export const useCompanyEstrategiaDetails = () => {
  const { estrategia, loading, quickfinId } = useEstrategiaComercial();

  const { callService: editCompanyEstrategia, loading: submitLoading } =
    useServiceCall(service.quickfin.editCompanyEstrategia);

  const { callService: editPortalEstrategia } = useServiceCall(
    service.quickfin.createorUpdatePortalEstrategiaComercial,
  );

  const { callService: getTaxas } = useServiceCall(
    service.senna.getConfigTaxasByEstrategia,
  );

  const { callService: setConfigTaxas } = useServiceCall(
    service.senna.setConfigTaxas,
  );

  const createUpdatePortalEstrategiaComercial = useCallback(
    async (estrategiaId: number, portais: string[]) => {
      const payload = {
        estrategiaComercialId: estrategiaId,
        portalCodes: portais,
      };
      const { error } = await editPortalEstrategia(
        payload as PostPortalEstrategiaComercialBody,
      );
      if (error) {
        OToastManager.danger(
          "Não foi possível criar as relações de links taggeados",
        );
      }
    },
    [editPortalEstrategia],
  );

  const navigate = useNavigate();

  const form = useForm<EstrategiaForm>({
    defaultValues: addEstrategiaFormDefaultValues,
    resolver: yupResolver(addEstrategiaFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const tipoProdutoWatch = watch("tipoProduto");

  const Form = getValueFromMap(productFormMap, tipoProdutoWatch);

  const transformTaxaObject = (
    uniqueId: string,
    configs: GetTaxasConfigTaxaByEstrategiaUuidResponse,
  ): PostTaxasConfigTaxaBody => {
    const config = configs.configTaxas[0];
    const configTaxa = {
      ...config,
      convenioExternalId: uniqueId,
      taxasColunas: config.emailsTaxas?.colunas,
      taxasEmails: config.emailsTaxas?.emails,
    } as PostTaxasConfigTaxaBody;

    return configTaxa;
  };

  const updateTaxas = async (uniqueId: string | undefined) => {
    if (!uniqueId) {
      return OToastManager.danger(
        "Não foi possivel atualizar as taxas dessa estratégia comercial",
      );
    }

    const { success, response } = await getTaxas(uniqueId);

    if (success) {
      const { data } = response;

      if (data.configTaxas.length === 1) {
        const configTaxa = transformTaxaObject(uniqueId, data);

        const { success: setConfigTaxaSuccess } = await setConfigTaxas(
          configTaxa,
        );

        if (setConfigTaxaSuccess)
          OToastManager.success("Taxas atualizadas com sucesso");
        else
          OToastManager.warning(
            "Não foi possivel atualizar as taxas dessa estratégia comercial",
          );
      } else if (data.configTaxas.length > 1) {
        OToastManager.warning(
          "Existem mais de uma configuração de taxa para essa estratégia comercial, favor configurar a desejada",
        );
      }
    }
  };

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await editCompanyEstrategia(
          quickfinId,
          buildPayload(values, true) as PatchEstrategiaComercialByCompanyIdBody,
        );

        if (values.linkTaggeado && values.id) {
          createUpdatePortalEstrategiaComercial(values.id, values.linkTaggeado);
        }

        if (success) {
          OToastManager.success("Estratégia editada com sucesso");

          if (values.modeloDeTaxa === "configuracao")
            updateTaxas(estrategia?.uniqueId);

          navigate(
            corporateRouter.routes.recebiveis.customer.estrategiaComercial.path,
          );
        } else {
          toastErrors(error);
          OToastManager.danger("Não foi possível editar a estratégia.");
        }
      }),
    [
      createUpdatePortalEstrategiaComercial,
      editCompanyEstrategia,
      handleSubmit,
      navigate,
      quickfinId,
      estrategia,
    ],
  );

  useEffect(() => {
    if (estrategia) {
      reset(buildInitialValues(estrategia) as any);
    }
  }, [estrategia, reset]);

  return {
    Form,
    form,
    loading,
    submit,
    submitLoading,
  };
};
