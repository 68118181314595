import {
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";

interface EmailFormModalProps {
  id: string;
  loading: boolean;
  onConfirm: () => Promise<void>;
}

export const EmailFormModal = ({
  id,
  loading,
  onConfirm,
}: EmailFormModalProps) => {
  return (
    <OModal id={id} position="center">
      <OModalHeader closeButton>
        <div className="d-flex flex-column gap-3 align-items-start">
          <OTypography size="xl" weight="bold">
            E-mail para notificação
          </OTypography>
          <OTypography>
            Será enviado um e-mail contendo o boleto da parcela selecionada
          </OTypography>
        </div>
      </OModalHeader>

      <OModalBody>
        <ORFieldInput
          id="email"
          name="email"
          required
          label="E-mail"
          tag="text"
        />
      </OModalBody>

      <OModalFooter>
        <div className="d-flex justify-content-end">
          <LoadingButton loading={loading} onClick={onConfirm}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
