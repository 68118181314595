import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const SacadoDefineContaDesembolsoField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.sacadoDefineContaDesembolso}
      name="sacadoDefineContaDesembolso"
      description="Trava na escolha de conta de desembolso da antecipação"
    />
  );
};
