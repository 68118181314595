import { modalManager, OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services";
import { logger } from "utils/logger";
import { B2bClienteByCustomerIdRouteParams } from "../../../../../routes/b2b.route-params";
import {
  invalidateUserModalId,
  SalesforceCompanyUser,
  sendWelcomeModalId,
} from "./company-users.types";

export const useCompanyUsers = () => {
  const [customerBasicData, setCustomerBasicData] =
    useState<
      Awaited<ReturnType<typeof service.clerk.admin.getCompanyUsers>>["data"]
    >();
  const [sendWelcomeLoading, setSendWelcomeLoading] = useState(false);
  const [invalidateUserLoading, setInvalidateUserLoading] = useState(false);

  const salesforceCompanyUserRef = useRef<SalesforceCompanyUser>();

  const { customerId } = useParams<B2bClienteByCustomerIdRouteParams>();
  if (!customerId) throw new Error("No customerId");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<SalesforceCompanyUser>(() =>
        service.clerk.admin
          .getCompanyUsers({ identification: customerId })
          .then(({ data }) => {
            setCustomerBasicData(data);
            return data.users;
          })
          .catch(() => {
            const errorMessage = "Erro ao buscar os usuários";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [customerId],
  );

  const sendWelcomeEmail = useCallback(async () => {
    try {
      if (!salesforceCompanyUserRef.current) throw new Error("No companyUser");

      setSendWelcomeLoading(true);

      await service.onboarding.sendWelcomeEmail({
        data: [
          {
            taxId: salesforceCompanyUserRef.current.cpf,
            relatedTaxId: customerId,
          },
        ],
      });

      OToastManager.success("E-mail enviado com sucesso.");
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);

      OToastManager.danger("Não foi possível enviar o e-mail de boas vindas.");
    } finally {
      setSendWelcomeLoading(false);
      modalManager.hide(sendWelcomeModalId);
    }
  }, [customerId]);

  const invalidateUser = useCallback(async () => {
    try {
      if (!salesforceCompanyUserRef.current) throw new Error("No companyUser");

      setInvalidateUserLoading(true);

      await service.gatekeeper.invalidateByCpf({
        cpf: salesforceCompanyUserRef.current.cpf,
      });

      OToastManager.success(
        "Os tokens de todos os dispositivos foram revogados com sucesso.",
      );
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);

      OToastManager.danger("Não foi possivel revogar os tokens.");
    } finally {
      setInvalidateUserLoading(false);
      modalManager.hide(invalidateUserModalId);
    }
  }, []);

  return {
    customerBasicData,
    dataSource,
    salesforceCompanyUserRef,
    sendWelcomeLoading,
    invalidateUserLoading,
    sendWelcomeEmail,
    invalidateUser,
  };
};
