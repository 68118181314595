import { OInputPhone } from "@maestro/react";
import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { gridStorage } from "utils/storage";
import { ContactForm } from "../../distributor-config.form";

export const distributorContactsGrid = (
  onChange: (contacts: ContactForm[]) => void,
  canEditContract: boolean,
): ODataGridGeneratorConfig<ContactForm> => {
  return {
    datagrid: {
      noDataText: "Nenhuma contato",
      stateStoring: gridStorage("distributorContactsGrid"),
      editing: {
        allowDeleting: canEditContract,
        allowUpdating: canEditContract,
      },
      onRowUpdated: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowInserted: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "name",
        dataType: "string",
        caption: "Nome",
      },
      {
        dataField: "isMain",
        dataType: "boolean",
        caption: "Contato principal",
        validationRules: [
          {
            type: "custom",
            validationCallback: ({ value }) => typeof value === "boolean",
          },
        ],
      },
      {
        dataField: "phoneNumber",
        dataType: "string",
        caption: "Telefone",
        format: (value) => masks.phone(value),
        editCellRender: ({ setValue, rowIndex, value }) => (
          <OInputPhone
            id={`phone-number-${rowIndex}`}
            name={`phone-number-${rowIndex}`}
            value={value}
            onInput={(evt) => setValue(evt.currentTarget.value)}
            aspect="unstyled"
            className="p-2"
          />
        ),
        validationRules: [
          {
            type: "custom",
            message: "Telefone inválido",
            validationCallback: (option) => {
              return !option.value || validators.phone(option.value);
            },
          },
        ],
      },
      {
        dataField: "email",
        dataType: "string",
        caption: "Email",
        validationRules: [
          {
            type: "custom",
            message: "Email inválido",
            validationCallback: (option) => {
              return validators.email(option.value);
            },
          },
        ],
      },
    ],
  };
};
