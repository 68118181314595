import { masks } from "@maestro/utils";
import { currencySymbol } from "utils/currency";

export const approvedAmountTypeMap = {
  GUARANTEED_DISCOUNT: "Desconto garantido",
  FIXED_VALUE: "Preço fixo",
} satisfies Record<HubEnergy.ETradeApprovedAmountType, string>;

export const approvedAmountMaskMap = {
  GUARANTEED_DISCOUNT: masks.percentage,
  FIXED_VALUE: (value) => masks.currency(value, currencySymbol.BRL),
  DEFAULT: (value) => `${value}`,
} satisfies Record<
  HubEnergy.ETradeApprovedAmountType | "DEFAULT",
  (value: number) => string
>;
