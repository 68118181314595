import {
  diligenceStatusMap,
  diligenceStepStatusMap,
  getStatusFromMap,
} from "../../../../utils";
import { AugmentedStatusBadge } from "../../../augmented-status-badge";
import { MappedStatusBadge } from "../../../mapped-status-badge";

interface DiligenceStatusesCellProps {
  diligenceStatus: HubEnergy.EDiligenceStatus | null;
  diligenceStepsStatuses: string[] | null;
}

export const DiligenceStatusesCell = ({
  diligenceStatus,
  diligenceStepsStatuses,
}: DiligenceStatusesCellProps) => {
  if (!diligenceStatus) return <>-</>;

  if (!diligenceStepsStatuses?.length)
    return (
      <MappedStatusBadge map={diligenceStatusMap} status={diligenceStatus} />
    );

  return (
    <div className="d-flex flex-column gap-1">
      {diligenceStepsStatuses?.map((stepStatus) => {
        const [name, status] = stepStatus.split(" ");
        const mappedStatus = getStatusFromMap(diligenceStepStatusMap, status);

        return (
          <AugmentedStatusBadge key={stepStatus} type={mappedStatus.type}>
            {name} - {mappedStatus.text}
          </AugmentedStatusBadge>
        );
      })}
    </div>
  );
};
