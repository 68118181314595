import { OButton, OIcon, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { getValueFromMap } from "utils/get-value-from-map";
import { IconContainer } from "../cards.styles";
import { CompanyDetailsCardTemplate } from "../template";
import {
    dataUpdateStatusMap,
} from "./data-update-card.utils";

export const DataUpdateCard = () => {
    const { customer } = useCustomer();

    const navigate = useNavigate();

    const lastDataUpdate = useMemo(() => {
        const sortedUpdates = customer.dataUpdates;
        sortedUpdates?.sort(
            (a, b) =>
                new Date(a.updatedAt ?? "").getTime() -
                new Date(b.updatedAt ?? "").getTime(),
        );

        return sortedUpdates?.[sortedUpdates.length - 1];
    }, [customer.dataUpdates]);

    if (!lastDataUpdate) return null;

    const statusProps =
        getValueFromMap(dataUpdateStatusMap, lastDataUpdate.status) ??
        dataUpdateStatusMap.DEFAULT;

    return (
        <CompanyDetailsCardTemplate title="Atualização cadastral">
            <div className="d-flex flex-column gap-3 w-100">
                <div className="d-flex justify-content-between">
                    <div className="d-flex gap-1">
                        <IconContainer>
                            <OIcon
                                category="fa"
                                icon={statusProps.icon}
                                type={statusProps.type}
                            />
                        </IconContainer>
                        <OTypography>Status:</OTypography>
                    </div>
                    <OTypography>{statusProps.text ?? lastDataUpdate.status}</OTypography>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex gap-1">
                        <IconContainer>
                            <OIcon category="fa" icon="fa-history" />
                        </IconContainer>
                        <OTypography>Atualizado em:</OTypography>
                    </div>
                    <OTypography>
                        {dayjs(lastDataUpdate.updatedAt).format("DD/MM/YYYY")}
                    </OTypography>
                </div>
                <OButton
                    dataAction="atualizacao_cadastral:botao:abrir_modal_historico_atualizacao_cadastral"
                    dataLabel="abrir_modal_historico_atualizacao_cadastral"
                    onClick={() =>
                        navigate(
                            corporateRouter.routes.cadastro.customer.details.dataUpdates
                                .path,
                        )
                    }
                    className="align-self-center"
                >
                    <OTypography type="light">Abrir histórico</OTypography>
                </OButton>
            </div>
        </CompanyDetailsCardTemplate>
    );
};
