import { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface OptinContextProps {
  identop: string;
  setIdentop: (newIdentop: string) => void;
  cnpjs: string[];
  setCnpjs: (newCnpjs: string[]) => void;
  optOutFilter: [] | ["status", "=", "aceito"];
  setOptOutFilter: (newFilter: [] | ["status", "=", "aceito"]) => void;
}

export const OptinContext = createContext<OptinContextProps | undefined>(
  undefined,
);

export const useOptinContext = () => {
  const context = useContext(OptinContext);
  if (!context)
    throw new Error(
      "useOptinContext must be used within an OptinContextProvider",
    );
  return context;
};

export const OptinContextProvider = ({ children }: { children: ReactNode }) => {
  const [identop, setIdentop] = useState("");

  const [cnpjs, setCnpjs] = useState<string[]>([]);

  const [optOutFilter, setOptOutFilter] = useState<
    [] | ["status", "=", "aceito"]
  >([]);

  const contextValue = useMemo(
    () => ({
      identop,
      setIdentop,
      cnpjs,
      setCnpjs,
      optOutFilter,
      setOptOutFilter,
    }),
    [cnpjs, identop, optOutFilter],
  );

  return (
    <OptinContext.Provider value={contextValue}>
      {children}
    </OptinContext.Provider>
  );
};
