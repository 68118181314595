import { modalManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import {
  RemoveSignatureGroupConfirmationModal,
  removeSignatureGroupModalId,
} from "../remove-signature-group-confirmation-modal";
import { useRemoveSignatureGroup } from "./remove-signature-group.hook";

interface RemoveSignatureGroupButtonProps {
  ruleId: string | number;
  groupId: string | number;
}

export const RemoveSignatureGroupButton = ({
  ruleId,
  groupId,
}: RemoveSignatureGroupButtonProps) => {
  const { loading, removeGroupOfSignatures } = useRemoveSignatureGroup(
    ruleId,
    groupId,
  );
  return (
    <>
      <LoadingButton
        dataAction="acoes:botao:enviar"
        dataLabel="enviar"
        loading={loading}
        onClick={() => modalManager.show(removeSignatureGroupModalId)}
        type="danger"
      >
        Remover
      </LoadingButton>
      <RemoveSignatureGroupConfirmationModal
        callback={removeGroupOfSignatures}
        loading={loading}
      />
    </>
  );
};
