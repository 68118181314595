import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { mapCurrencySymbol } from "utils/currency";
import { paymentMethodMap } from "../../settlement-type/settlement-type.utils";
import { useSimulation } from "../../simulation/simulation.context";

export const LoadSimulationItems = (column?: "left" | "right") => {
  const { selectedSimulationResult, selectedSettlementType } = useSimulation();

  if (!selectedSimulationResult) return [];

  const isNotBRL = selectedSimulationResult?.currency?.toUpperCase() !== "BRL";

  const hasIOF = !!selectedSimulationResult?.settlement.iofAmount;

  const hasRegistrationFee = !!selectedSimulationResult?.additionalFees.some(
    (a) => a.amount > 0,
  );

  let items = [
    {
      label: "Valor do crédito",
      value: masks.currency(
        selectedSimulationResult?.amount,
        mapCurrencySymbol(selectedSimulationResult?.currency),
      ),
      visible: true,
    },
    {
      label: "Cotação",
      value: `${masks.currency(
        1,
        selectedSimulationResult?.currency,
      )} = ${masks.currency(
        selectedSimulationResult?.currencyPrice,
        undefined,
      )}`,
      visible: isNotBRL,
    },
    {
      label: "Valor de referência",
      value: selectedSimulationResult
        ? masks.currency(
            selectedSimulationResult.amount *
              selectedSimulationResult.currencyPrice,
          )
        : 0,
      visible: isNotBRL,
    },
    {
      label: "Despesas da operação",
      value: masks.currency(
        selectedSimulationResult?.additionalFees.reduce(
          (feesTotalAmount, current) => current.amount + feesTotalAmount,
          0,
        ),
        selectedSimulationResult?.currency,
      ),
      visible: hasRegistrationFee,
    },
    {
      label: "Taxa de juros (a.m)",
      value: `${
        selectedSimulationResult.interest.indexCode === "CDIE" ? "CDI + " : ""
      }${masks.percentage(selectedSimulationResult?.rate)}`,
      visible: true,
    },

    {
      label: "IOF",
      value: masks.currency(
        selectedSimulationResult?.settlement.iofAmount,
        mapCurrencySymbol(selectedSimulationResult?.currency),
      ),
      visible: hasIOF,
    },

    {
      label: "CET (a.a)",
      value: masks.percentage(selectedSimulationResult?.cet),
      visible: true,
    },

    {
      label:
        selectedSimulationResult &&
        selectedSimulationResult.settlement.installments.length > 1
          ? "Primeiro Vencimento"
          : "Vencimento",
      value: dayjs(
        selectedSimulationResult?.settlement.installments?.[0].maturityDate,
      ).format("DD/MM/YYYY"),
      visible: true,
    },

    {
      label: "Método de pagamento",
      value: paymentMethodMap[selectedSettlementType || ""].label || "-",
      visible: true,
    },
  ];

  items = items.filter((item) => item.visible);

  if (column === "right") {
    const itemsAux = items.slice(4, items.length);
    while (itemsAux.length < 4) {
      itemsAux.push({ value: "", label: "", visible: true });
    }
    return itemsAux;
  }

  return items.slice(0, 4);
};
