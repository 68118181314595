import * as yup from "yup";

export const configurationVariableFormValidationSchema = yup.object().shape({
  variableName: yup.string().required("Campo obrigatório"),
  variableValue: yup.string().required("Campo obrigatório"),
});

export const configurationVariableFormDefaultValues = {
  variableName: "",
  variableValue: "",
};
