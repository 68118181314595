import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import useSWR from "swr";

export const useCurrencies = () => {
  const {
    callService: getCurrencies,
  } = useServiceCall(service.hubFx.getApprovedCurrencies);

  const callCurrencies = useCallback(async () => {
    const { response, success } = await getCurrencies();

    if (!success) return;
    const { data } = response;

    return data;
  }, [getCurrencies]);

  const { data: currencies, isLoading: isLoadingCurrencies } = useSWR(
    "api/currency",
    callCurrencies,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  const mapSymbol = (currency?: string | null) => {
    return currencies?.find((curr) => curr.code === currency)?.symbol ?? "";
  };

  return {
    currencies,
    isLoadingCurrencies,
    mapSymbol
  };
};