import { yupResolver } from "@hookform/resolvers/yup";
import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { FormProvider, useForm } from "react-hook-form";
import { useWorkflow } from "../../hooks/use-workflow.context";
import {
  TestConditionForm,
  TestConditionFormValues,
} from "../test-condition-form/test-condition-form.component";
import { testConditionValidationFormSchema } from "../test-condition-form/test-condition-form.schemas";

export const TestConditionModal = () => {
  const { hide } = modalManager;
  const form = useForm<TestConditionFormValues>({
    resolver: yupResolver(testConditionValidationFormSchema),
  });
  const { reset } = form;
  const { gridCondition } = useWorkflow();

  return (
    <OModal
      id="test-condition-modal"
      position="center"
      className="position-absolute"
    >
      <OModalHeader
        closeButton
        onModalClose={() => {
          reset();
        }}
      >
        <OTypography tag="h1" size="xxl">
          Testar condição
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          {gridCondition && <TestConditionForm condition={gridCondition} />}
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="testar_condicao:botao:fechar"
            dataLabel="fechar"
            type="dark"
            outline
            onClick={() => {
              reset();
              hide("test-condition-modal");
            }}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
