import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { AxiosResponse } from "axios";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PutAdminLegalEntityDenyBody } from "services/hubcreditmanager/models/requests";
import { PostAdminLegalEntityBody } from "services/hubcreditmanager/models/requests/post-admin-legal-entity.request";
import { PostAdminLegalEntityResponse } from "services/hubcreditmanager/models/responses/post-admin-legal-entity.response";
import {
  formDefaultValues,
  formValidationSchema,
} from "./add-deny-list-form.schema";

export const useAddDenyHook = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(formValidationSchema),
    defaultValues: formDefaultValues,
  });

  const { getValues, watch, setValue, trigger } = form;

  const taxIdWatch = watch("taxId");

  const addDeny = useCallback(async () => {
    try {
      setLoading(true);

      const isValid = await trigger();

      if (!isValid) throw new Error("Error on form validation");

      const { taxId, reasonDescription } = getValues();

      const body: PutAdminLegalEntityDenyBody = {
        reasonDescription,
        taxId,
      };

      await service.hubCreditManager.addLegalEntityToDenyList(body);

      navigate(corporateRouter.routes.workflow.product.denyList.path);
      OToastManager.success("Cliente adicionado a lista com sucesso.");
    } catch {
      OToastManager.danger("Houve um erro ao adicionar o cliente.");
    } finally {
      setLoading(false);
    }
  }, [getValues, navigate, trigger]);

  const getLegalEntity = useCallback(
    async (taxId: string) => {
      try {
        setLoading(true);

        const body: PostAdminLegalEntityBody = {
          taxId,
        };

        const response: AxiosResponse<PostAdminLegalEntityResponse> =
          await service.hubCreditManager.getLegalEntityByTaxId(body);

        const { data } = response;

        setValue("name", data?.name);
      } catch (e) {
        OToastManager.danger(
          "Não foi possível buscar dados do cliente. Por favor, tente novamente.",
        );
      } finally {
        setLoading(false);
      }
    },
    [setValue],
  );

  const debouncedTaxIdSearch = useMemo(() => {
    return debounce(getLegalEntity, 800);
  }, [getLegalEntity]);

  useEffect(() => {
    const taxId = taxIdWatch?.replace(/\D/g, "");
    if (!taxId || (taxId.length !== 14 && taxId.length !== 11)) {
      setValue("name", "");
      return;
    }

    debouncedTaxIdSearch(taxIdWatch);

    return () => {
      debouncedTaxIdSearch.cancel();
    };
  }, [setValue, taxIdWatch, debouncedTaxIdSearch]);

  return { form, loading, addDeny };
};
