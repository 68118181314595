import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { CompanyGroup, CompanyInGroup } from "./company-groups.types";

const buildDataSource = (taxId: string) =>
  dataSourceCustomStoreGenerator<CompanyInGroup>(() =>
    service.recebiveisCartao
      .getCompaniesGroup(taxId)
      .then(({ data }) => data.companies)
      .catch(() => {
        const errorMessage = "Erro ao buscar os CNPJs do grupo";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const companyGroupsMasterDetailGrid: ODataGridGeneratorConfig<CompanyInGroup> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "tax_id",
        dataType: "string",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "target_tax_id",
        dataType: "string",
        caption: "CNPJ Matriz",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "name",
        dataType: "string",
        caption: "Nome",
        allowHeaderFiltering: false,
      },
      {
        dataField: "agendas_count",
        dataType: "boolean",
        calculateCellValue: ({ agendas_count }) => !!agendas_count,
        caption: "Possui agendas hoje",
      },
    ],
  };

type CompanyGroupsMasterDetailProps = MasterDetailComponentProps<CompanyGroup>;

export const CompanyGroupsMasterDetail = ({
  data: {
    data: { tax_id },
  },
}: CompanyGroupsMasterDetailProps) => {
  const dataSource = useMemo(() => buildDataSource(tax_id), [tax_id]);
  return (
    <ODataGridGenerator
      grid={companyGroupsMasterDetailGrid}
      dataSource={dataSource}
    />
  );
};
