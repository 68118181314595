import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { MonitoringTemplate } from "../../../../../components/monitoramento";
import { eloaMonitoringGrid } from "./eloa-monitoring.grid";

export const EloaMonitoring = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();

  return (
    <>
      <PageTitle title="Monitoramento Procuração eletrônica" />
      <MonitoringTemplate
        filterFields={[
          {
            label: "Nome do Representante",
            property: "UserName",
          },
          {
            label: "TaxId do Representante",
            property: "UserIdentification",
          },
        ]}
        initialFilter={{
          CompanyName: customer.officialName,
          CompanyIdentification: customer.identification,
        }}
        relationship="ROLEASSIGNMENT"
        gridBuilder={eloaMonitoringGrid}
        gridRef={gridRef}
      />
    </>
  );
};
