import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  ConfiguraWorkTimeFormValues,
  configureWorkTimeSchema,
  configureWorkTimeDefaultValues,
} from "./configuracao-horario-operacao-form.form";
import { panelTitleMap } from "./configuracao-horario-operacao-form.utls";

export const useConfigureWorkTimeForm = (
  operation: BankingHub.admin.Operation,
) => {
  const [loading, setLoading] = useState(false);
  const [onlyBusinessDay, setOnlyBusinessDay] = useState(
    operation.onlyBusinessDay,
  );
  const form = useForm<ConfiguraWorkTimeFormValues>({
    resolver: yupResolver(configureWorkTimeSchema),
    defaultValues: configureWorkTimeDefaultValues(operation),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    const payload = {
      ...operation,
      start: values.start,
      end: values.end,
      onlyBusinessDay,
    };

    setLoading(true);
    try {
      await service.adminBankinghub.putNewOperationTime(operation.id, payload);
      OToastManager.success(
        `Horário da operação de ${getValueFromMap(
          panelTitleMap,
          operation.operationType,
        )} editado com sucesso`,
      );
    } catch {
      OToastManager.danger(
        `Falha ao editar o horário de ${getValueFromMap(
          panelTitleMap,
          operation.operationType,
        )}. Tente novamente.`,
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    onlyBusinessDay,
    onSubmit,
    setOnlyBusinessDay,
  };
};
