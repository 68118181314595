import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { antecipacaoAutomaticaGrid } from "./antecipacao-automatica.grid";

export const AntecipacaoAutomatica = () => {
  const gridRef = useGridRef();
  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Antecipações automáticas configuradas"
          description="Podemos visualizar configurações de antecipação automática - empresa ligada, sacados, bandeiras e range de vencimento"
        />
      }
    >
      <GridTemplate
        showClearFiltersButton
        showRefreshButton
        showExportButton
        gridRef={gridRef}
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={antecipacaoAutomaticaGrid()}
        />
      </GridTemplate>
    </DetailsTemplate>
  );
};
