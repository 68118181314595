import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { withRole } from "components/with-role";
import { useRemoveRelationshipButton } from "./remove-relationship-button.hook";

interface RemoveRelationshipButtonProps {
  companyTaxId: string;
  userTaxId: string;
  callback?: () => void;
}

export const RemoveRelationshipButton = withRole(
  ({ callback, companyTaxId, userTaxId }: RemoveRelationshipButtonProps) => {
    const { loading, removeRelationship } = useRemoveRelationshipButton(
      companyTaxId,
      userTaxId,
    );

    const modalId = `remove-relationship-confirmation-modal-${companyTaxId}`;

    return (
      <>
        <IconButton
          icon={{ category: "fal", icon: "fa-times" }}
          dataAction="coluna_acoes:botao:remover"
          dataLabel="remover"
          loading={loading}
          onClick={() => modalManager.show(modalId)}
          type="dark"
          outline
        >
          Remover
        </IconButton>
        <OConfirmationModal
          style={{ position: "absolute" }}
          modalId={modalId}
          title="Remover relacionamento"
          confirmLabel="Sim"
          closeLabel="Cancelar"
          size="sm"
          onConfirm={async () => {
            modalManager.hide(modalId);
            await removeRelationship();
            callback?.();
          }}
        >
          <OTypography style={{ whiteSpace: "normal" }}>
            Tem certeza que deseja remover o relacionamento entre o usuário{" "}
            {masks.cpf(userTaxId)} e a empresa {masks.cpfCnpj(companyTaxId)}?
          </OTypography>
        </OConfirmationModal>
      </>
    );
  },
);

RemoveRelationshipButton.displayName = "RemoveRelationshipButton";
