import { modalManager } from "@maestro/core";
import {
  OButton,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { getPropertyLabel } from "../../../../../../../../../../utils";
import { useEditPropertyValueModal } from "./edit-property-value-modal.hook";
import { LowerZIndexModal } from "./edit-property-value-modal.styles";
import { maskFieldsMap } from "./_compose/mask-fields";

interface EditPropertyValueModalProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
  reload: () => void;
}

export const EditPropertyValueModal = ({
  property,
  reload,
}: EditPropertyValueModalProps) => {
  const { form, loading, submit, modalId } = useEditPropertyValueModal(
    property,
    reload,
  );

  const Field = useMemo(
    () => property && maskFieldsMap[property.type.mask],
    [property],
  );

  return (
    <LowerZIndexModal
      id={modalId}
      position="center"
      backdrop
      className="position-absolute"
    >
      <OModalHeader>
        <OTypography size="lg">Editar valor da propriedade</OTypography>
        {property && (
          <OTypography type="default">{getPropertyLabel(property)}</OTypography>
        )}
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          {Field && property && <Field property={property} />}
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </LowerZIndexModal>
  );
};
