import { OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo, useState } from "react";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { buildOperationDetails } from "./operation-details.details";
import { tradesGrid } from "./operation-details.grid";
import { useOperationDetails } from "./operation-details.hook";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";

export const OperationDetails = () => {
  const { getOperation, hasError, loading, operationDetails } =
    useOperationDetails();
  const gridRef = useGridRef();
  const [cancelOperationButtonDisabled, setCancelOperationButtonDisabled] = useState(false);

  const gridSummary = useMemo(
    () => (
      <Summary texts={{ sum: "{0}" }}>
        <TotalItem
          column="price"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
              operationDetails?.trades?.[0].receivable.currency || "BRL",
            precision: 3,
          }}
        />
        <TotalItem
          column="maturityAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
              operationDetails?.trades?.[0].receivable.currency || "BRL",
            precision: 3,
          }}
        />
      </Summary>
    ),
    [operationDetails?.trades],
  );

  const {
    callService: cancelOperations,
  } = useServiceCall(service.quickfin.cancelOperations);

  const {
    callService: reprocessOperations,
  } = useServiceCall(service.quickfin.reprocessOperations);

  const showOperationOptionsButton = useCallback(() => {
    let cancelButton = (
      <ButtonWithConfirmation
        confirmationModal={{
          title: "Confirma o cancelamento de operação?",
          confirmLabel: "Sim",
          closeLabel: "Não",
          divider: false,
          size: "xs",
        }}
        modalBody={
          <div className="d-flex flex-column gap-4">
            <OTypography type="dark">
              Essa ação cancelará o contrato e consequentemente todas as
              operações associadas a esse contrato
            </OTypography>
          </div>
        }
        className="align-self-end"
        type="danger"
        disabled={cancelOperationButtonDisabled}
        onConfirm={async () => {
          if (operationDetails)
          {
            await cancelOperations({operationsId: [Number(operationDetails.id)]});
            setCancelOperationButtonDisabled(true);
            getOperation();
          }
        }}
      >
        Cancelar operação
      </ButtonWithConfirmation>
    );

    let reprocessButton = (
      <ButtonWithConfirmation
        confirmationModal={{
          title: "Confirma o reprocessamento?",
          confirmLabel: "Sim",
          closeLabel: "Não",
          divider: false,
          size: "xs",
        }}
        modalBody={
          <div className="d-flex flex-column gap-4">
            <OTypography type="dark">
              Essa ação reprocessará os recebíveis dessa operação
            </OTypography>
          </div>
        }
        className="align-self-end"
        disabled={cancelOperationButtonDisabled}
        onConfirm={async () => {
          if (operationDetails)
          {
            await reprocessOperations({operationsId: [Number(operationDetails.id)]});
            setCancelOperationButtonDisabled(true);
            getOperation();
          }
        }}
      >
        Reprocessar recebíveis
      </ButtonWithConfirmation>
    );

    switch (operationDetails?.status) {
      case "WaitingContractSignature":
        return cancelButton;
      case "Canceled":
        return reprocessButton;
      default:
        return <></>;
    }
  }, [operationDetails, cancelOperationButtonDisabled, setCancelOperationButtonDisabled]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da operação" />}>
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar os detalhes da operação"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={getOperation} />
            </ErrorComponent>
          }
          value={operationDetails}
          fields={buildOperationDetails}
        />

        <GridTemplate
          actions={
            <>
              {showOperationOptionsButton()}
              <RefreshGridButton onClick={getOperation} />
            </>
          }
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={tradesGrid}
            dataSource={operationDetails?.trades}
            loading={loading}
          >
            {gridSummary}
          </ODataGridGenerator>
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
