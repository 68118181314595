import { Roles } from "roles/roles.types";

export const usuariosRoles = {
  customer: {
    tab: {
      role: "admin:UserTabCustomer.View",
      description: "Visualização da tab 'Usuários' na visão cliente",
    },
    updateUser: {
      role: "admin:UserUpdateCustomer.View",
      description:
        "Visualização da ação de edição de usuário em 'Usuários' na visão cliente",
    },
    addPage: {
      role: "admin:UserAddCustomer.View",
      description:
        "Visualização da página 'Usuários - Novo usuário' na visão cliente",
    },
    addInterncionalPage: {
      role: "admin:UserAddInternacionalCustomer.View",
      description:
        "Visualização da página 'Usuários - Novo usuário internacional' na visão cliente",
    },
    attachUser: {
      role: "admin:EnableLegalRepresentativeAccess.View",
      description:
        "Visualização da página 'Usuários - Vincular usuário' na visão cliente",
    },
    timePenalty: {
      role: "admin:TimePenalty.View",
      description:
        "Visualização da ação de remover penalidade de tempo do usuário",
    },
    detailsPage: {
      role: "admin:UserDetailsPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Detalhes' na visão cliente",
    },
    detailsExpiringPowersCompanyUserPage: {
      role: "admin:UserDetailsExpiringPowersCompanyUserProduct.View",
      description:
        "Visualização da página 'Usuários - Detalhes - Expiração de poderes' na visão cliente",
    },
    roleAssignmentsPage: {
      role: "admin:UserRoleAssignmentsPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Perfis de Acesso' na visão cliente",
    },
    lettersOfAttorneyPage: {
      role: "admin:UserLettersOfAttorneyPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Procurações físicas' na visão cliente",
    },
    notificationsEmailPage: {
      role: "admin:UserNotificationsEmailPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Notificações - E-mail' na visão cliente",
    },
    notificationsPushPage: {
      role: "admin:UserNotificationsPushPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Notificações - Push' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:UserTabProduct.View",
      description: "Visualização da tab 'Usuários' na visão produto",
    },
    b2cPage: {
      role: "admin:UserB2CPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C' na visão produto",
    },
    b2cDetailsPage: {
      role: "admin:UserB2CDetailsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Detalhes' na visão produto",
    },
    b2cAccessProfilesPage: {
      role: "admin:UserB2CAccessProfilesPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Empresas relacionadas' na visão produto",
    },
    b2cActivationsPage: {
      role: "admin:UserB2CActivationsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Ativações' na visão produto",
    },
    b2cDeviceTokensPage: {
      role: "admin:UserB2CDeviceTokensPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Tokens' na visão produto",
    },
    b2cNotificationsPage: {
      role: "admin:UserB2CNotificationsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Notificações' na visão produto",
    },
    b2cEmailMaintenancePage: {
      role: "admin:UserB2CEmailMaintenancePageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Alteração de e-mail' na visão produto",
    },
    b2cCreateAdminPage: {
      role: "admin:UserB2CCreateAdminPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Criar administrador' na visão produto",
    },
    b2bPage: {
      role: "admin:UserB2BPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B' na visão produto",
    },
    b2bDetailsPage: {
      role: "admin:UserB2BDetailsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Detalhes' na visão produto",
    },
    b2bAccessProfilesPage: {
      role: "admin:UserB2BAccessProfilesPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Empresas relacionadas' na visão produto",
    },
    b2bActivationsPage: {
      role: "admin:UserB2BActivationsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Ativações' na visão produto",
    },
    b2bDeviceTokensPage: {
      role: "admin:UserB2BDeviceTokensPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Tokens' na visão produto",
    },
    b2bNotificationsPage: {
      role: "admin:UserB2BNotificationsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Notificações' na visão produto",
    },
    b2bEmailMaintenancePage: {
      role: "admin:UserB2BEmailMaintenancePageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Alteração de e-mail' na visão produto",
    },
    detailsExpiringPowersUserPage: {
      role: "admin:UserDetailsExpiringPowersUserProduct.View",
      description:
        "Visualização da página 'Usuários - Detalhes - Expiração de poderes' na visão produto",
    },
    removeUserCompanyRelationshipButton: {
      role: "admin:RemoveUserCompanyRelationshipButton.View",
      description:
        "Visualização da botão de remover relacionamento entre um usuário e uma empresa",
    },
    b2cBlockedLogsPage: {
      role: "admin:UserB2CBlockedLogsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Logs de Bloqueios' na visão produto",
    },
    b2bBlockedLogsPage: {
      role: "admin:UserB2BBlockedLogsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Logs de Bloqueios' na visão produto",
    },
    b2cLoginActivitiesPage: {
      role: "admin:UserB2CLoginActivitiesPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Atividades de Login' na visão cliente",
    },
    b2bLoginActivitiesPage: {
      role: "admin:UserB2BLoginActivitiesPageCustomer.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Atividades de Login' na visão produto",
    },
    b2cToolsPage: {
      role: "admin:UserB2CToolsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2C - Ferramentas' na visão produto",
    },
    b2bToolsPage: {
      role: "admin:UserB2BToolsPageProduct.View",
      description:
        "Visualização da página 'Usuários - Usuários B2B - Ferramentas' na visão produto",
    },
    excludeUserToolsPage: {
      role: "admin:ExcludeUserToolsPageProduct.View",
      description:
        "Visualização do botão de remover um usuário na página de Ferramentas",
    },
  },
} satisfies Roles;
