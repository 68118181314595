import { SnapshotNode } from "./eligibility-offer-details.types";

export const operationMap = {
  GREATER_THAN: "Maior que",
  GREATER_THAN_OR_EQUAL: "Maior ou igual a",
  EQUAL: "Igual",
  LESS_THAN: "Menor que",
  LESS_THAN_OR_EQUAL: "Menor ou igual a",
};

export const criteriaMap = {
  PRE_APPROVED_LIMIT: "Limite pré-aprovado",
  NOT_ELIGIBLE: "Não elegível",
  ELIGIBLE: "Elegível",
  CORPORATE: "Corporate",
  ALLOWED_USERS_TO_HIRE_DEBIT_CARD: "Usuários com poder de movimentação",
  VALID_CPF: "CPF Válido",
  VALID_CNPJ: "CNPJ Válido",
  AGRO_GUARANTEE: "Garantias formalizadas",
};

const nodeHasChildren = (node: SnapshotNode, nodes: SnapshotNode[]) => {
  return nodes.some((x) => x.parentId === node.id);
};

export const isEligibleTree = (node: SnapshotNode, nodes: SnapshotNode[]) => {
  if (!node.hasPassed) return false;

  if (!nodeHasChildren(node, nodes)) return true;

  const nodeChildren = nodes.filter((x) => x.parentId === node.id);
  for (const child of nodeChildren) {
    const isEligible = isEligibleTree(child, nodes);
    if (isEligible) return true;
  }

  return false;
};
