import * as yup from "yup";

export const autoRelocationDefaulValues = {
  companyIds: [],
};

export const autoRelocationValidationSchema = yup.object({
  companyIds: yup
    .array()
    .required("É necessário selecionar ao menos uma empresa")
    .min(1, "É necessário selecionar ao menos uma empresa"),
  time: yup
    .string()
    .trim()
    .required("É necessário informar o horário")
    .test("time", "Horário inválido", (value) => {
      const [hh, mm] = value.split(":").map((part) => parseInt(part));
      return hh >= 0 && hh < 24 && mm >= 0 && mm < 60 && value.length === 5;
    }),
});
