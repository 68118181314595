import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductNaturesGrid } from "./exchange-product-natures.grid";

export const ExchangeProductNaturesPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Naturezas" />}
      actions={
        <RouterButton
          dataAction="cambio_naturezas:botao:adicionar_natureza"
          dataLabel="adicionar_natureza"
          href={
            corporateRouter.routes.cambio.product.contingency.natures.all.create
              .path
          }
          role={roles.cambio.product.contingencyNaturesAllCreatePage.role}
        >
          Adicionar natureza
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={exchangeProductNaturesGrid}
      />
    </GridTemplate>
  );
};
