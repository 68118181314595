import { ONotification, OTypography } from "@maestro/react";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const ExpiringPowersCompanyNotification = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const { hasRole } = useRoles();

  const { callService, value } = useServiceCall(
    service.onboardingRelationships.getPowersStatus,
  );

  useEffect(() => {
    if (hasRole(roles.cadastro.customer.detailsExpiringPowersPage.role))
      callService({
        companyTaxId: identification,
      });
  }, [callService, hasRole, identification]);

  const hasExpiredPowers = value?.status && value.status !== "NOT_EXPIRE";

  return hasExpiredPowers ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">
          Os representantes legais da empresa possuem expiração de poderes
        </OTypography>
        <StandardLink
          href={
            corporateRouter.routes.cadastro.customer.details.expiringPowers.path
          }
          role={roles.cadastro.customer.detailsExpiringPowersPage.role}
        >
          Detalhes
        </StandardLink>
      </div>
    </ONotification>
  ) : null;
};
