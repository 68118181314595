import * as yup from "yup";

export interface BannerTemplateFormFields {
  description: string;
  identification: string;
  imageFile: File[];
  imageAlt: string;
  openInNewTab: string[];
  actionText: string;
  actionRef: string;
}

export const bannerFormValidationSchema = yup.object().shape({
  description: yup.string().required("Campo obrigatório"),
  identification: yup.string().required("Campo obrigatório"),
  imageAlt: yup.string().required("Campo obrigatório"),
  actionText: yup.string().required("Campo obrigatório"),
  actionRef: yup.string().required("Campo obrigatório"),
  imageFile: yup.array().of(
    yup
      .mixed()
      .test(
        "is-valid-file-or-object",
        "O formato do arquivo inserido é inválido",
        (value) => {
          if (value instanceof File) {
            return true;
          }
          if (value && typeof value === "object" && !(value instanceof File)) {
            return true;
          }
          return false;
        },
      ),
  ),
  openInNewTab: yup.array().of(yup.string()),
});

export const bannerFormDefaultValues = {
  description: "",
  identification: "",
  imageAlt: "",
  actionRef: "",
  actionText: "",
  openInNewTab: [],
  imageFile: [],
} satisfies BannerTemplateFormFields;
