import { ICategory } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { LimitedWidthIcon } from "./big-numbers.styles";
import { DataGridAction } from "components/datagrid-action";
import { corporateRouter } from "routes/corporate-router.context";
import { roles } from "roles/roles";

type BigNumbers = Awaited<
  ReturnType<typeof service.hubCreditManager.getBigNumbers>
>["data"][number];

export const bigNumbersGrid = {
  datagrid: {
    noDataText: "Nenhum big number encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getBigNumbers().catch(() => {
        throw new Error("Não foi possível buscar a lista de Big Numbers.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
    paging: { pageSize: 10 },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "nameId",
      caption: "Identificador",
    },
    {
      dataField: "iconName",
      caption: "Ícone",
      cellRender: ({ data }) => (
        <LimitedWidthIcon
          category={(data.iconName.split("-")[0] ?? "orq") as ICategory}
          icon={data.iconName}
          size="lg"
          type="info"
        />
      ),
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                role: roles.workflow.product
                  .configurationsUpdateBigNumberPage.role,
                route: `${corporateRouter.routes.workflow.product.configurations.bigNumbers.update.path(
                  {
                    id: data.id,
                  },
                )}?data=${encodeURIComponent(JSON.stringify(data))}`,
              },
            ]}
          />
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<BigNumbers>;