import { roles } from "roles/roles";
import {
  ApproveContingency,
  DocumentDevolutive,
  EnableAccount,
  ResetLegalEntityRegistration,
  SincronizeAccessWithPowers,
  SyncLessAccounts,
  UpdateCompanyRegistersFromLess,
  UpdateSalesforceData,
} from "./_compose";
import { DataReviewWorkflows } from "./_compose/data-review-workflows";
import { DataUpdateWorkflows } from "./_compose/data-update-workflows";
import { OnboardingWorkflows } from "./_compose/onboarding-workflows";
import { Tool } from "./tools.types";

export const tools: Tool[] = [
  {
    description: "Conclusão de cadastro legados",
    type: "Cadastro",
    action: <ApproveContingency />,
  },
  {
    description: "Documentos do cliente",
    type: "Cadastro",
    action: <DocumentDevolutive />,
  },
  {
    description: "Atualização de dados do portal empresas com o LESS",
    type: "Cadastro",
    action: <UpdateCompanyRegistersFromLess />,
  },
  {
    description: "Resetar jornada de onboarding",
    type: "Cadastro",
    action: <ResetLegalEntityRegistration />,
  },
  {
    description: "Edição de onboarding",
    type: "Cadastro",
    action: <OnboardingWorkflows />,
    role: roles.cadastro.customer.toolsOnboardingWorkflowsPage.role,
  },
  {
    description: "Habilitação de Conta Corrente para fornecedores e parceiros",
    type: "Abertura de conta corrente PJ",
    action: <EnableAccount />,
  },
  {
    description: "Edição de revisão cadastral",
    type: "Revisão cadastral",
    action: <DataReviewWorkflows />,
  },
  {
    description: "Edição de atualização cadastral",
    type: "Atualização cadastral",
    action: <DataUpdateWorkflows />,
  },
  {
    description: "Sincronzação de Acessos de acordo com os poderes IRIS",
    type: "Gestão de Acessos",
    action: <SincronizeAccessWithPowers />,
  },
  {
    description:
      "Sincronzação de contas do cliente com as contas registradas no LESS",
    type: "Gestão de Contas",
    action: <SyncLessAccounts />,
  },
  {
    description: "Atualizar dados no Salesforce",
    type: "Salesforce",
    action: <UpdateSalesforceData />,
  },
];
