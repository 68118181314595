import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ComponentProperty } from "../component-info.types";

export const useEditComponent = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("No id");

  const [selectedProperty, setSelectedProperty] = useState<ComponentProperty>();

  const component = useServiceCall(service.hubCreditManager.getComponentsById);

  const { callService: getComponentsDetails } = component;

  useEffect(() => {
    if (!id) return;
    getComponentsDetails(id);
  }, [getComponentsDetails, id]);

  return {
    id,
    selectedProperty,
    setSelectedProperty,
    component,
  };
};
