import {
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useCallback } from "react";
import { useCancelFloating } from "./cancel-floating-modal.hook";
import {
  CancelFloatingModalId,
  CancelFloatingModalProps,
} from "./cancel-floating-modal.types";

export { CancelFloatingModalId } from "./cancel-floating-modal.types";

export const CancelFloatingModal = ({
  handleSuccess,
}: CancelFloatingModalProps) => {
  const { cancelFloating, isLoading } = useCancelFloating();

  const handleRemove = useCallback(
    () => cancelFloating(handleSuccess),
    [cancelFloating, handleSuccess],
  );

  return (
    <OModal
      id={CancelFloatingModalId}
      backdrop={!isLoading}
      position="center"
      size="sm"
    >
      <OModalHeader closeButton={!isLoading}>
        <OTypography type="dark" size="lg" className="text-nowrap">
          Remover Floating?
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <OTypography type="dark-80">
          Ao remover o floating deste beneficiário, o floating de todas as
          cobranças existentes será removido. Deseja continuar?
        </OTypography>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            onClick={() => modalManager.hide(CancelFloatingModalId)}
            disabled={isLoading}
            outline
          >
            Voltar
          </OButton>
          <OButton onClick={handleRemove} disabled={isLoading}>
            <div className="d-flex align-items-center gap-2">
              Remover Floating
              {isLoading && <OLoader size="xs" type="light" />}
            </div>
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
