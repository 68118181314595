import { RouterLink } from "components/router-link";
import { useProductsHomeRoute } from "hooks/products-home-route";
import { HeaderNavigation } from "./navigation-menu.styles";

export const NavigationMenu = () => {
  const productsRoute = useProductsHomeRoute();

  return (
    <HeaderNavigation>
      {productsRoute && (
        <RouterLink href={productsRoute}>Produtos e Serviços</RouterLink>
      )}
    </HeaderNavigation>
  );
};
