import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import { productsMap } from "../../../../antecipacao-de-cartoes/pages/produto/enquadramento-carteira/cedentes/cedente-limits.utils";
import { GridActions } from "./_compose";
import { EstrategiaComercial } from "./estrategia-comercial.types";

export const buildDataSource = (id: string | number) =>
  dataSourceCustomStoreGenerator<EstrategiaComercial>(() =>
    service.quickfin.getCompanyEstrategias(id).then(({ data }) => data),
  );

export const estrategiasComerciaisGrid: ODataGridGeneratorConfig<EstrategiaComercial> =
  {
    datagrid: {
      noDataText: "Nenhuma estratégia comercial",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      stateStoring: gridStorage("estrategiaComercialGrid"),
    },
    columns: [
      {
        dataField: "nome",
        caption: "Nome",
        allowHeaderFiltering: false,
      },
      {
        dataField: "uniqueId",
        caption: "Identificador",
        allowHeaderFiltering: false,
      },
      {
        dataField: "padrao",
        caption: "Padrão",
      },
      {
        dataField: "tipoProduto",
        caption: "Tipo do produto",
        customizeText: ({ value }) =>
          getValueFromMap(productsMap, value) ?? value,
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <GridActions
            estrategia={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
      },
    ],
  };
