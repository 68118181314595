import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import UAParser from "ua-parser-js";

interface UserAgentModalProps {
  userAgentModalId: string;
  content: string;
}

export const UserAgentModal = ({
  userAgentModalId,
  content,
}: UserAgentModalProps) => {
  const parser = new UAParser();
  parser.setUA(content);

  return (
    <OModal id={userAgentModalId} position="center" size="sm">
      <OModalHeader>
        <OTypography size="lg">Agente do Usuario</OTypography>
      </OModalHeader>
      <OModalBody>
        <div style={{ overflow: "auto" }}>
          <pre>{JSON.stringify(parser.getResult(), null, 2)}</pre>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            dataAction="entuba_sugestao:botao:limpar"
            dataLabel="limpar"
            type="dark"
            outline
            onClick={() => modalManager.hide(userAgentModalId)}
          >
            Ok
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
