import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";

interface SyncDataReviewWorkflowProps {
  uuid: string;
  modalId: string;
}

export const SyncDataReviewWorkflow = ({
  uuid,
  modalId,
}: SyncDataReviewWorkflowProps) => {
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      await service.onboarding.syncDataReview({
        workflowDataReviewUuid: uuid,
      });

      OToastManager.info("Revisão sincronizada com sucesso.");

      modalManager.hide(modalId);
    } catch (error) {
      OToastManager.warning("Erro ao sincronizar revisão.");
    } finally {
      setLoading(false);
    }
  }, [modalId, uuid]);

  return (
    <OConfirmationModal
      modalId={modalId}
      size="sm"
      title="Sincronizar a revisão?"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      onConfirm={submit}
      loading={loading}
    >
      <div> {`Id: ${uuid}`}</div>
    </OConfirmationModal>
  );
};
