import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { maskIdentificationByCountry } from "utils/mask";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { TooltipIcon } from "components/tooltip-icon";
import moment from "moment";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ReceivablesItem } from "services/quickfin/models";

export const grid: ODataGridGeneratorConfig<ReceivablesItem> = {
  datagrid: {
    noDataText: "Nenhuma nota encontrada",
    paging: {
      enabled: false,
    },
  },
  columns: [
    {
      allowExporting: false,
      caption: "Detalhes",
      role: roles.recebiveis.product.titlesNoteNoteDetailsReceivablePage.role,
      cellRender: ({ data: { receivableId } }) => (
        <DetailsLink
          href={corporateRouter.routes.recebiveis.product.titles.noteNote.details.receivable.path(
            {
              receivableId,
            },
          )}
        />
      ),
    },
    {
      caption: "Receivable Id",
      dataField: "receivableId",
    },
    { caption: "Trade Id", dataField: "tradeId" },
    {
      dataField: "receivableIdentification",
      caption: "Identificação recebível",
    },
    {
      dataField: "state",
      caption: "State",
      cellRender: ({ data }) =>
        ["ErrorValidacao", "Recusado"].includes(data.state) ? (
          <span>
            {data.state}{" "}
            {!!data.errorValidation && data.errorValidation.length > 0 && (
              <TooltipIcon
                maxWidth="600px"
                content={
                  <ul>
                    {data.errorValidation.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                }
              />
            )}
          </span>
        ) : (
          data.state
        ),
    },
    {
      caption: "Sacado",
      dataField: "sacado",
      calculateCellValue: (data) =>
        `${data.sacado} ${data.sacadoIdentification}`,
      cellRender: ({ data }) => (
        <div>
          <OTypography>{data.sacado}</OTypography>
          <OTypography size="sm" type="dark-80">
            {maskIdentificationByCountry(
              data.sacadoIdentification,
              data.sacadoCountry,
            )}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: (filterValue) => [
        ["sacado", "contains", filterValue],
        "or",
        ["sacadoIdentification", "contains", filterValue.replace(/\D/g, "")],
      ],
      allowSorting: false,
    },
    {
      caption: "Cedente",
      dataField: "cedente",
      calculateCellValue: (data) =>
        `${data.cedente} ${data.cedenteIdentification}`,
      cellRender: ({ data }) => (
        <div>
          <OTypography>{data.cedente}</OTypography>
          <OTypography size="sm" type="dark-80">
            {maskIdentificationByCountry(
              data.cedenteIdentification,
              data.cedenteCountry,
            )}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: (filterValue) => [
        ["cedente", "contains", filterValue],
        "or",
        ["cedenteIdentification", "contains", filterValue.replace(/\D/g, "")],
      ],
      allowSorting: false,
    },
    {
      caption: "Valor vencimento",
      format: "#,##0.00",
      dataType: "number",
      cellRender: ({ data }) => {
        const value = data.valorVencimentoTrade
          ? data.valorVencimentoTrade
          : data.valorVencimento;

        return <OTypography tag="span">{masks.currency(value)}</OTypography>;
      },
    },
    {
      dataField: "valorDesembolso",
      caption: "Valor desembolso",
      format: (value) => masks.currency(value),
    },
    {
      caption: "Rebate",
      dataField: "rebate",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "iof",
      caption: "IOF",
      format: (value) => masks.currency(value),
    },
    { caption: "Moeda", dataField: "currency" },
    {
      caption: "Data trade",
      dataField: "tradeDate",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Data desembolso",
      dataField: "dataDesembolso",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Data vencimento",
      dataField: "dataVencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Vencimento alongado",
      dataField: "dataVencimentoTrade",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Criado em",
      dataField: "createdAt",
      dataType: "datetime",
      format: "dd/MM/yyyy HH:mm:ss",
      cssClass: "dx-word-wrap",
      calculateCellValue: (data: { createdAt: string }) => {
        const date = moment(data.createdAt).subtract(3, 'hours');
        return date.format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      caption: "Atualizado em",
      dataField: "updatedAt",
      dataType: "datetime",
      format: "dd/MM/yyyy HH:mm:ss",
      cssClass: "dx-word-wrap",
      sortOrder: "desc",
      calculateCellValue: (data: { updatedAt: string }) => {
        const date = moment(data.updatedAt).subtract(3, 'hours');
        return date.format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      caption: "Estratégia",
      dataField: "estrategiaComercialNome",
      calculateCellValue: (data) =>
        `${data.estrategiaComercialNome} ${data.estrategiaComercialGuid}`,
      cellRender: ({ data }) => (
        <div>
          <OTypography>{data.estrategiaComercialNome}</OTypography>
          <OTypography size="sm" type="dark-80">
            {data.estrategiaComercialGuid}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: (filterValue) => [
        ["estrategiaComercialNome", "contains", filterValue],
        "or",
        ["estrategiaComercialGuid", "contains", filterValue],
      ],
      allowSorting: false,
    },
    {
      caption: "Marketplace",
      cellRender: ({ data }) => (
        <OTypography tag="span">
          {data.marketplaceSubgroup
            ? `${data.marketplaceName} - ${data.marketplaceSubgroup}`
            : data.marketplaceName}
        </OTypography>
      ),
    },
    {
      caption: "Tipo produto",
      dataField: "tipoProduto",
    },
    {
      caption: "Data repasse",
      dataField: "dataRepasse",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "borderoId",
      caption: "Id lote",
    },
    {
      dataField: "operationId",
      caption: "Id operação",
    },
    {
      dataField: "externalId",
      caption: "Identificador externo",
    },
    {
      dataField: "contractNumber",
      caption: "Nº contrato",
    },
    {
      dataField: "exchangeRate",
      caption: "Taxa de câmbio",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "charge",
      caption: "TCO",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "saldoDevedor",
      caption: "Saldo devedor",
      format: (value) => masks.currency(value),
    },
  ],
};
