import { yupResolver } from "@hookform/resolvers/yup";
import { IIcon, IType, OToastManager } from "@maestro/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowContext } from "../../../../../../../../components/hooks/use-workflow.context";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../routes/workflow.route-params";
import {
  buttonTypes,
  orqIcons,
} from "../../../../../../configuracoes/workflow-configuration.utils";
import {
  userActionsFormDefaultValues,
  userActionsFormValidationSchema,
} from "./processor-config-user-actions.schema";

export const useProcessorConfigUserActionsPageContent = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const {
    bigNumbers: { value: bigNumbers },
    workflowConfigViews: { value: workflowConfigViews },
    processorConfig: {
      value: selectedProcessorConfig,
      callService: getProcessorConfig,
    },
  } = useContext(WorkflowContext);

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(userActionsFormValidationSchema),
    defaultValues: userActionsFormDefaultValues,
  });
  const { handleSubmit, setValue, reset } = form;

  const iconOptions = orqIcons.map((icon: IIcon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("actionIcon", value),
  }));

  const buttonTypeOptions = buttonTypes.map((type: IType) => ({
    value: type,
    label: type,
    onSelect: (value: string) => setValue("actionButtonType", value),
  }));

  const getUserActions = useCallback(async () => {
    try {
      if (!processorId) return;
      setLoading(true);

      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsUserActions(
          processorId,
        );

      setTimeout(() => {
        reset({
          actionButtonName: data.actionButtonName,
          actionButtonType: data.actionButtonType,
          actionIcon: data.actionIcon,
          hasActionWithoutContext: data.hasActionWithoutContext ? ["1"] : [],
          isActionButtonOutlined: data.isActionButtonOutlined ? ["1"] : [],
          viewUserActions: data.viewUserActions.map((v) => v.type),
          bigNumberRelated: data.bigNumber ? data.bigNumber.id : 0,
        });
      }, 500);
    } catch (err) {
      OToastManager.danger("Erro para retornar as ações de usuários.");
    } finally {
      setLoading(false);
    }
  }, [processorId, reset]);

  const updateUserActions = handleSubmit(async (values) => {
    try {
      if (!processorId) return;

      setLoading(true);
      await service.hubCreditManager.updateProcessorConfigDetailsUserActions({
        ...values,
        processorConfigId: Number(processorId),
        actionButtonName: values.actionButtonName,
        actionIcon: values.actionIcon,
        bigNumberRelated: values.bigNumberRelated,
        hasActionWithoutContext: !!values.hasActionWithoutContext?.length,
        isActionButtonOutlined: !!values.isActionButtonOutlined?.length,
        viewUserActions: values.viewUserActions,
      });

      OToastManager.success("Ações de Usuários alteradas com sucesso.");
    } catch (err) {
      OToastManager.danger("Erro para salvar as ações de usuários.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getUserActions();
  }, [getUserActions]);

  useEffect(() => {
    if (!processorId) return;

    getProcessorConfig(processorId);
  }, [getProcessorConfig, processorId]);

  return {
    bigNumbers,
    buttonTypeOptions,
    form,
    iconOptions,
    loading,
    selectedProcessorConfig,
    workflowConfigViews,
    updateUserActions,
  };
};
