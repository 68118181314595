import { OToastManager, modalManager } from "@maestro/react";
import { dataSourceODataStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useEffect, useMemo, useState } from "react";
import { endpoints } from "services/endpoints";
import { TCOStrategiesODataValueItem } from "services/quickfin/models/responses/get-odata-tco-strategies-odata.response";
import { service } from "services/service";
import { tcoStrategiesGrid } from "./tco-strategies.grid";

export const useTCOStrategies = () => {
  const [strategyId, setStrategyId] = useState<number>();
  const [companiesFilter, setCompaniesFilter] = useState("");
  const [estrategiasFilter, setEstrategiasFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceODataStoreGenerator<TCOStrategiesODataValueItem>(
        `${endpoints.quickfin.odata.tcoStrategiesOData}?${companiesFilter}&${estrategiasFilter}`,
      ),
    [estrategiasFilter, companiesFilter],
  );

  const filterEstrategias = (filterValue: string) => {
    setEstrategiasFilter(`estrategia=${filterValue}`);
    return ["Description", "contains", ""];
  };

  const filterCompanies = (filterValue: string) => {
    setCompaniesFilter(`company=${filterValue}`);
    return ["Description", "contains", ""];
  };

  const grid = useMemo(() => {
    return tcoStrategiesGrid(filterEstrategias, filterCompanies, setStrategyId);
  }, []);

  useEffect(() => {
    if (!strategyId) return;

    modalManager.show("delete-tco-modal");
  }, [strategyId]);

  const deleteTCO = useCallback(async () => {
    setLoading(true);
    try {
      if (!strategyId) return;

      await service.quickfin.deleteTCOStrategyODataById(strategyId);
      OToastManager.success("Estratégia de TCO deletada com sucesso!");
    } catch {
      OToastManager.danger("Falha ao deletar estratégia de TCO");
    } finally {
      setStrategyId(undefined);
      dataSource.reload();
      modalManager.hide("delete-tco-modal");
      setLoading(false);
    }
  }, [dataSource, strategyId]);

  const clearFilter = () => {
    gridRef.current?.instance.clearFilter();
    setEstrategiasFilter("");
    setCompaniesFilter("");
  };

  return {
    clearFilter,
    dataSource,
    deleteTCO,
    grid,
    gridRef,
    loading,
  };
};
