import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { MleByPropertyTypeIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import {
  PropertyTypeForm,
  propertyTypeFormDefaultValues,
  propertyTypeFormValidationSchema,
} from "../_compose";

export const useEditPropertyType = () => {
  const { propertyTypeId } = useParams<MleByPropertyTypeIdRouteParams>();
  if (!propertyTypeId) throw new Error("No propertyTypeId");

  const {
    callService: _fetch,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPropertyTypeById);

  const { callService: _submit, loading: submitLoading } = useServiceCall(
    service.hubEnergy.updateDiligenceStepPropertyType,
  );

  const navigate = useNavigate();

  const form = useForm<PropertyTypeForm>({
    defaultValues: propertyTypeFormDefaultValues,
    resolver: yupResolver(propertyTypeFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getPropertyType = useCallback(
    () => _fetch(propertyTypeId),
    [_fetch, propertyTypeId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _submit({
          id: Number(propertyTypeId),
          label: values.label,
          mask: values.mask,
          name: values.name,
        });

        if (success) {
          OToastManager.success("Tipo de propriedade salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.propertyTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Erro ao salvar tipo de propriedade",
          );
        }
      }),
    [_submit, handleSubmit, navigate, propertyTypeId],
  );

  useEffect(() => {
    getPropertyType();
  }, [getPropertyType]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        label: fetchValue.response.label,
        mask: fetchValue.response.mask,
        name: fetchValue.response.name,
      });
  }, [reset, fetchValue]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getPropertyType,
    submit,
    submitLoading,
  };
};
