import { PostApiEmailConfigsTypeCreateBody } from "services/clerk";
import * as yup from "yup";

export type InviteTypeFormValues = PostApiEmailConfigsTypeCreateBody;

export const nameFieldTypeMap = {
  cpf: "CPF",
  email: "EMAIL",
  name: "NOME",
  phone_number: "TELEFONE",
  originator: "ORIGINADOR",
  operator: "OPERADOR",
  cnpj: "CNPJ",
  official_name: "RAZAO_SOCIAL",
  desk: "MESA",
  limit_date: "DATA_LIMITE",
};

export const nameStepTypeMap = {
  company: "DADOS_EMPRESA",
  user: "DADOS_USUARIO",
};

const fieldsValidationSchema = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  required: yup
    .mixed((input): input is boolean => typeof input === "boolean")
    .transform(
      (value) => ({ true: true, false: false }[value as string] ?? null),
    )
    .required("Este campo é obrigatório"),
  defaultValue: yup
    .mixed((input): input is boolean => typeof input === "boolean")
    .transform(
      (value) => ({ true: true, false: false }[value as string] ?? null),
    )
    .required("Este campo é obrigatório"),
  allowChange: yup
    .mixed((input): input is boolean => typeof input === "boolean")
    .transform(
      (value) => ({ true: true, false: false }[value as string] ?? null),
    )
    .required("Este campo é obrigatório"),
  visible: yup
    .mixed((input): input is boolean => typeof input === "boolean")
    .transform(
      (value) => ({ true: true, false: false }[value as string] ?? null),
    )
    .required("Este campo é obrigatório"),
});

export const stepsValidationSchema = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  priority: yup.number().required("Este campo é obrigatório"),
  visible: yup
    .mixed((input): input is boolean => typeof input === "boolean")
    .transform(
      (value) => ({ true: true, false: false }[value as string] ?? null),
    )
    .required("Este campo é obrigatório"),
  fields: yup.array().of(fieldsValidationSchema).optional(),
});

export const inviteTypeFormValidationSchema: yup.ObjectSchema<InviteTypeFormValues> =
  yup.object({
    emailConfigId: yup.number().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    description: yup.string().required("Este campo é obrigatório"),
    expirationTime: yup.number().required("Este campo é obrigatório"),
    totalUses: yup.number().required("Este campo é obrigatório"),
    hasValidationToken: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    hasValidationCnpj: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    shouldNotifyUpdatesOnTeams: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    teamsNotificationGroup: yup.string().required("Este campo é obrigatório"),
    steps: yup.array().of(stepsValidationSchema).optional(),
  });
