import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { useDownloadContractTemplateFile } from "../download-contract-template-file";

export const useGridActions = (templateId: string | number) => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const { callService: _delete, loading: deleteLoading } = useServiceCall(
    service.quickfin.deleteCompanyContractTemplate,
  );

  const { callService: _setDefault, loading: setDefaultLoading } =
    useServiceCall(service.quickfin.setDefaultCompanyContractTemplate);

  const { onDownloadFile, loading: downloadLoading } =
    useDownloadContractTemplateFile(templateId);

  const deleteCompanyContractTemplate = useCallback(async () => {
    const { success } = await _delete(quickfinId, templateId);
    if (success) {
      OToastManager.success("Template excluído com sucesso");
    } else {
      OToastManager.danger("Não foi possível excluir o template");
    }
  }, [_delete, quickfinId, templateId]);

  const setDefaultCompanyContractTemplate = useCallback(async () => {
    const { success } = await _setDefault(quickfinId, templateId);
    if (success) {
      OToastManager.success("Template marcado como padrão");
    } else {
      OToastManager.danger("Não foi possível marcar o template como padrão");
    }
  }, [_setDefault, quickfinId, templateId]);

  return {
    deleteCompanyContractTemplate,
    loading: downloadLoading || deleteLoading || setDefaultLoading,
    onDownloadFile,
    setDefaultCompanyContractTemplate,
  };
};
