import { safeLocalStorage } from "../storage/storage";
import { InternalLoggerImpl, LogLevels, Logger } from "./logger.types";
import { _console } from "./logger.utils";

// Configuration
const defaultLevel = LogLevels.warn;
const defaultStackTraceEnabled = false;

const levelStorageKey = "corporate-portal:logger:level";
const stackTraceStorageKey = "corporate-portal:logger:stack-trace";

class InternalLogger implements InternalLoggerImpl {
  private _level = safeLocalStorage.getItem(levelStorageKey) || defaultLevel;

  private _stackTraceEnabled: boolean =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    JSON.parse(safeLocalStorage.getItem(stackTraceStorageKey)!) ?? // JSON.parse(null) === null
    defaultStackTraceEnabled;

  log(level: Exclude<LogLevels, LogLevels.off>, ...args: unknown[]) {
    if (level <= this.level) {
      const parsed = this.stackTraceEnabled
        ? args
        : args.map((arg) => (arg instanceof Error ? arg.message : arg));

      _console(level, ...parsed);
    }
  }

  get level() {
    return this._level as LogLevels;
  }

  set level(newLevel: LogLevels) {
    this._level = newLevel;
    safeLocalStorage.setItem(levelStorageKey, String(newLevel));
  }

  get stackTraceEnabled() {
    return this._stackTraceEnabled;
  }

  set stackTraceEnabled(newStackTraceEnabled: boolean) {
    this._stackTraceEnabled = newStackTraceEnabled;
    safeLocalStorage.setItem(
      stackTraceStorageKey,
      String(newStackTraceEnabled),
    );
  }
}

const _logger = new InternalLogger();

// public interface

export const logger: Logger = {
  fatal: (...args) => _logger.log(LogLevels.fatal, ...args),
  error: (...args) => _logger.log(LogLevels.error, ...args),
  warn: (...args) => _logger.log(LogLevels.warn, ...args),
  info: (...args) => _logger.log(LogLevels.info, ...args),
  debug: (...args) => _logger.log(LogLevels.debug, ...args),
};

// configure via window

declare global {
  interface Window {
    logger: {
      getLevel: () => void;
      setLevel: (level: string) => void;
      enableStackTrace: () => void;
      disableStackTrace: () => void;
    };
  }
}

window.logger = {
  getLevel: () =>
    _console(
      LogLevels.debug,
      "Current level:",
      LogLevels[_logger.level],
      ". Stack trace:",
      _logger.stackTraceEnabled ? "Enabled" : "Disabled",
    ),
  setLevel: (level: string) => {
    const newLevel = LogLevels[level as keyof typeof LogLevels];
    if (newLevel === undefined) {
      _console(
        LogLevels.debug,
        "Invalid level, available levels are:",
        Object.values(LogLevels)
          .filter((value) => typeof value === "string")
          .filter((value) => value !== LogLevels[LogLevels.off])
          .join(", "),
      );
      return;
    }
    _logger.level = newLevel;
  },
  enableStackTrace: () => {
    _logger.stackTraceEnabled = true;
  },
  disableStackTrace: () => {
    _logger.stackTraceEnabled = false;
  },
};
