import * as yup from "yup";

export const addCanvasItemValidationSchema = yup.object({
  value: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
});

export const addCanvasItemFormDefaultValues = {
  value: "",
  type: "GROUP",
};
