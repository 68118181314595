import {
  GetCompaniesByIdCedentesContractComplementResponse,
  PostContractComplementBody,
} from "services/quickfin/models";
import { parseContractComplement } from "../../../cliente/cedentes/detalhes/_compose";
import { FillPendingComplementsForm } from "./fill-peding-complements.types";

export const parseFillContractComplementPayload = (
  contractId: number,
  values: FillPendingComplementsForm,
  contractComplement?: GetCompaniesByIdCedentesContractComplementResponse,
) => {
  const parsedContractComplement = parseContractComplement(
    values.contractComplement,
  );
  return {
    contractId,
    contractTemplateComplement: {
      variables: parsedContractComplement,
      contractSpecificSignatories:
        contractComplement?.contractSpecificSignatories,
      inclusionList: values.contractComplementInclusionList,
    },
  } as PostContractComplementBody;
};
