import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { AntecipacaoDeCartoesEnquadramentoCarteiraTiersByTierId } from "../../../../../../routes/antecipacao-de-cartoes.route-params";
import {
  addRelationshipDefaulValues,
  addRelationshipValidationSchema,
} from "./add-relationship.form";
import { AddRelationshipForm } from "./add-relationship.types";

export const useAddRelatioship = () => {
  const { tierId } =
    useParams<AntecipacaoDeCartoesEnquadramentoCarteiraTiersByTierId>();
  if (!tierId) throw new Error("No tierId");

  const navigate = useNavigate();

  const {
    callService: listSacados,
    hasError: sacadosHasError,
    loading: sacadosLoading,
    value: sacadosFull,
  } = useServiceCall(service.quickfin.listSacados);

  const {
    callService: listCedentes,
    hasError: cedentesHasError,
    loading: cedentesLoading,
    value: cedentesFull,
  } = useServiceCall(service.quickfin.listCedentes);

  const {
    callService: createTierRelationship,
    hasError: submitHasError,
    loading: submitLoading,
    value: submitResponse,
  } = useServiceCall(service.quickfin.createTierRelationship);

  const form = useForm<AddRelationshipForm>({
    defaultValues: addRelationshipDefaulValues,
    resolver: yupResolver(addRelationshipValidationSchema),
  });

  const { handleSubmit, setValue, trigger } = form;

  const submit = useMemo(
    () => handleSubmit((values) => createTierRelationship(tierId, values)),
    [createTierRelationship, handleSubmit, tierId],
  );

  /** Initial request */
  useEffect(() => {
    listSacados();
  }, [listSacados]);

  /** Initial request */
  useEffect(() => {
    listCedentes();
  }, [listCedentes]);

  /** Error warning */
  useEffect(() => {
    if (submitHasError) OToastManager.danger("Erro ao criar o relacionamento");
  }, [submitHasError]);

  /** Error warning */
  useEffect(() => {
    if (sacadosHasError) OToastManager.danger("Erro ao buscar os sacados");
  }, [sacadosHasError]);

  /** Error warning */
  useEffect(() => {
    if (cedentesHasError) OToastManager.danger("Erro ao buscar os cedentes");
  }, [cedentesHasError]);

  /** Success */
  useEffect(() => {
    if (submitResponse) {
      OToastManager.success("Relacionamento criado com sucesso");
      navigate(
        corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento.tiers
          .path,
      );
    }
  }, [navigate, submitResponse]);

  const sacadosOptions = useMemo<SelectSearchOption<number>[]>(
    () =>
      sacadosFull?.value.map((sacado, index) => ({
        label: sacado.OfficialName ?? "",
        value: sacado.Id,
        onSelect: () => {
          setValue("sacadoId", sacado.Id);
          trigger("sacadoId");
        },
        customKey: index,
      })) ?? [],
    [sacadosFull?.value, setValue, trigger],
  );

  const cedentesOptions = useMemo<SelectSearchOption<number>[]>(
    () =>
      cedentesFull?.value.map((cedente, index) => ({
        label: cedente.OfficialName ?? "EMPRESA SEM NOME",
        value: cedente.Id,
        onSelect: () => {
          setValue("cedenteId", cedente.Id);
          trigger("cedenteId");
        },
        customKey: index,
      })) ?? [],
    [cedentesFull?.value, setValue, trigger],
  );

  return {
    cedentesHasError,
    cedentesLoading,
    cedentesOptions,
    sacadosHasError,
    sacadosLoading,
    sacadosOptions,
    submitLoading,
    form,
    tierId,
    submit,
  };
};
