import { OToastManager } from "@maestro/core";
import { OCol, OField, OInputNumber, ORow } from "@maestro/react";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { useState } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { TaxasPreCurvaItem } from "services/senna/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./rate-curve.grid";

export const RateCurve = () => {
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  const [daysToDataBase, setDaysToDataBase] = useState(0);

  const dataSource = dataSourceCustomStoreGenerator<TaxasPreCurvaItem>(() =>
    service.senna
      .getTaxasPre({ indexBase: "fptp", daysToDataBase })
      .then(({ data }) => data.curva)
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar curvas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Curva de taxas"
          description="Curva de taxas para o período"
        />
      }
      actions={
        <StandardLink
          href={routes.recebiveis.product.rateCurve.add.path}
          role={roles.recebiveis.product.rateCurveAddPage.role}
        >
          Adicionar
        </StandardLink>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ORow className="mb-3">
        <OCol xs={12} md={6} xl={4}>
          <OField htmlFor="daysToDataBase" label="Digite a data base (D+)">
            <OInputNumber
              id="daysToDataBase"
              name="daysToDataBase"
              dataAction="curva_taxas:texto:digite_a_data_base_d+"
              dataLabel="digite_a_data_base_d+"
              placeholder="Preencher"
              value={daysToDataBase}
              onInput={(e: any) => setDaysToDataBase(e.target.value)}
            />
          </OField>
        </OCol>
      </ORow>

      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
