import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { ReconResult } from "./reconciliations.types";
import { reconResultStatusMap } from "./reconciliations.utils";

const buildDetailsLink = (reconResultExternalId: string) =>
  corporateRouter.routes.banking.product.reconciliations.details.path({
    reconResultExternalId,
  });

export const buildDataSource = (startDate: string, endDate: string) =>
  dataSourceCustomStoreGenerator<ReconResult>((loadOptions) => {
    return service.reconciliero
      .getReconciliationsResults({
        skip: loadOptions.skip ?? 0,
        take: loadOptions.take ?? 10,
        startDate,
        endDate,
      })
      .then(({ data }) => ({ data: data.results, totalCount: data.count }))
      .catch(() => {
        const errorMessage = "Erro ao buscar resultados das reconciliações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const reconciliationsGrid: ODataGridGeneratorConfig<ReconResult> = {
  datagrid: {
    noDataText: "Nenhum item encontrado.",
    pager: {
      infoText: "Página {0} de {1} ({2} resultados)",
      visible: true,
      showPageSizeSelector: true,
    },
    remoteOperations: {
      paging: true,
      filtering: false,
    },
    sorting: {
      mode: "none",
    },
  },
  columns: [
    {
      role: roles.banking.product.reconciliationsDetailsPage.role,
      cellRender: ({ data: { externalId } }) => (
        <DetailsLink href={buildDetailsLink(externalId)} />
      ),
    },
    {
      dataField: "creationDate",
      dataType: "date",
      caption: "Data",
      format: "shortDate",
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => {
        const { label, type } = getValueFromMap(
          reconResultStatusMap,
          data.status,
        ) ?? {
          label: data.status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      dataField: "area",
      dataType: "string",
      caption: "Área",
    },
    {
      dataField: "description",
      dataType: "string",
      caption: "Descrição",
    },
    {
      dataField: "profile",
      dataType: "string",
      caption: "Perfil",
    },
    {
      dataField: "product",
      dataType: "string",
      caption: "Produto",
    },
    {
      dataField: "category",
      dataType: "string",
      caption: "Categoria",
    },
    {
      dataField: "account",
      dataType: "string",
      caption: "Conta",
    },
    {
      dataField: "operationQuantity",
      dataType: "number",
      caption: "Qtd. Operações",
    },
    {
      dataField: "breakQuantity",
      dataType: "number",
      caption: "Qtd. Breaks",
    },
    {
      dataField: "expectedValue",
      dataType: "number",
      caption: "Total esperado",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "diff",
      dataType: "number",
      caption: "Total Break",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "value",
      dataType: "number",
      caption: "Total Reconciliado",
      format: (value) => masks.currency(value, "R$"),
    },
  ],
};
