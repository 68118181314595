import { OToastManager } from "@maestro/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services/service";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
import { GetCompanyByFundResponse } from "services/fidc/models/responses/get-company-by-fund.response";
// eslint-disable-next-line no-restricted-imports
import { isArray } from "lodash";
import { useFidcCompanyFunds } from "../../../hooks/fidc-company-funds";
import { companyGrid } from "./company.grid";
import {
  cleanTaxId,
  companyFormDefaulValues,
  CompanyInteractionFormValues,
} from "./company.utils";

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("&");

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");

export const useCompanyNotification = () => {
  const [loading, setLoading] = useState(false);
  const { companyFunds } = useFidcCompanyFunds();

  const formSerch = useForm<CompanyInteractionFormValues>({
    defaultValues: companyFormDefaulValues,
  });

  const form = useForm();

  const { watch } = form;

  const [totalItems, setTotalItems] = useState(999);
  const [data, setData] = useState<Fidc.Company[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const hasFetched = useRef(false);
  const selectedFundWatch = watch("selectedFund");

  const [findTaxId, setFindTaxId] = useState<string>("");

  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    if (!selectedFundWatch) {
      OToastManager.danger("Erro ao selecionar um fundo");
      return;
    }

    if (hasFetched.current) {
      return;
    }

    setLoading(true);
    try {
      const { data: apiData } = await service.fidc.getCompanyByFund(
        selectedFundWatch,
        buildQuery({
          limit: rows,
          page,
          taxId: cleanTaxId(findTaxId),
        }),
      );
      setData(apiData.companies);
      setTotalCount(apiData.totalCount || 0);
      setTotalItems(apiData.totalCount);
      hasFetched.current = true;
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      const errorMessage = "Erro na busca das empresas.";
      OToastManager.danger(errorMessage);
      // eslint-disable-next-line no-restricted-syntax
      console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const grid = useMemo(() => {
    return companyGrid(rows);
  }, [rows]);

  useEffect(() => {
    if (!selectedFundWatch) {
      return;
    }

    hasFetched.current = false;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFundWatch, rows, page, findTaxId]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        GetCompanyByFundResponse["companies"][number]
      >(() => {
        if (!selectedFundWatch) {
          return Promise.resolve({ data: [], totalCount: 0 });
        }

        if (hasFetched.current) {
          return Promise.resolve({ data, totalCount });
        }

        return Promise.resolve({ data: [], totalCount: 0 });
      }),
    [data, selectedFundWatch, totalCount],
  );

  return {
    dataSource,
    form,
    formSerch,
    companyFunds,
    selectedFundWatch,
    rows,
    setRows,
    page,
    setPage,
    totalItems,
    loading,
    grid,
    setFindTaxId,
  };
};
