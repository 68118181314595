import {
  BehaviorRoute,
  CustomerRoute,
  ProductRoute,
} from "components/access-control";
import { UserGuard } from "components/render-guard";
import { Behaviors } from "contexts/behavior/types";
import { MenuTabs } from "../tab-menu";

export const wrapElement = (
  el: React.ReactNode,
): Record<keyof MenuTabs, JSX.Element> => ({
  customer: (
    <UserGuard>
      <CustomerRoute>{el}</CustomerRoute>
    </UserGuard>
  ),
  product: (
    <BehaviorRoute
      allowedBehaviors={[Behaviors.Admin, Behaviors.RTB, Behaviors.Corban]}
    >
      <UserGuard>
        <ProductRoute>{el}</ProductRoute>
      </UserGuard>
    </BehaviorRoute>
  ),
});
