import { client } from "../../client";
import { b2bCoreEndpoints } from "./b2bcore.endpoints";
import {
  GetB2BCoreCustomersParams,
  GetB2BCoreCustomersResponse,
  GetB2BCoreOperatorParams,
  GetB2BCoreOperatorResponse,
  GetB2BCoreOriginatorResponse,
} from "./models";

export const b2bCoreService = {
  customers(params: GetB2BCoreCustomersParams) {
    return client.get<GetB2BCoreCustomersResponse>(
      b2bCoreEndpoints.admin.customers(params.originatorIdentification),
      {
        headers: {
          origem: "core",
        },
      },
    );
  },
  operators(params: GetB2BCoreOperatorParams) {
    return client.get<GetB2BCoreOperatorResponse>(
      b2bCoreEndpoints.admin.operators(params.originatorIdentification),
      {
        headers: {
          origem: "core",
        },
      },
    );
  },
  originator(originatorIdentification: string) {
    return client.get<GetB2BCoreOriginatorResponse>(
      b2bCoreEndpoints.admin.originator(originatorIdentification),
      {
        headers: {
          origem: "core",
        },
      },
    );
  },
  getPartnerByAuthOperator() {
    return client.get<string>(
      b2bCoreEndpoints.assessors.getPartnerByAuthOperator,
      {
        headers: {
          origem: "core",
        },
      },
    );
  },
};
