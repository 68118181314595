import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { service } from "services/service";
import {
  addPartnerDefaultValues,
  addPartnerValidationschema,
} from "./add-partner.form";
import { AddPartnerFormFields } from "./add-partner.types";

export const useAddPartner = () => {
  const { callService: upsertPartner, loading } = useServiceCall(
    service.scfDevelopers.upsertPartner,
  );

  const navigate = useNavigate();

  const form = useForm<AddPartnerFormFields>({
    defaultValues: addPartnerDefaultValues,
    resolver: yupResolver(addPartnerValidationschema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    const payload = {
      partner: {
        ...values,
        isInternal: values.isInternal.includes("true"),
      },
    };
    const { success } = await upsertPartner(payload);

    if (success) {
      OToastManager.success("Parceiro adicionado com sucesso!");
      navigate(-1);
    } else {
      OToastManager.danger("Falha ao adicionar parceiro");
    }
  });

  return {
    form,
    loading,
    submit,
  };
};
