import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useEditStepConfigContext } from "../../edit-step-config.context";
import {
  buildDataSource,
  pendencyConfigsGrid,
} from "./pendency-configs-grid.grid";

interface PendencyConfigsGridProps {
  diligenceStepConfigId: number;
}

export const PendencyConfigsGrid = ({
  diligenceStepConfigId,
}: PendencyConfigsGridProps) => {
  const gridRef = useGridRef();
  const {
    pendencyConfigsFetch,
    pendencyConfigsValue,
    pendencyTypesLoading,
    pendencyTypesValue,
    propertyConfigsValue,
    propertyTypesValue,
    documentTypesValue,
  } = useEditStepConfigContext();

  const dataSource = useMemo(
    () => buildDataSource(diligenceStepConfigId, pendencyConfigsFetch),
    [diligenceStepConfigId, pendencyConfigsFetch],
  );

  const propertyConfigLookup = useMemo(
    () =>
      propertyConfigsValue?.response.map(({ id, typeId, documentTypeId }) => {
        const propertyType = propertyTypesValue?.response.find(
          (pt) => pt.id === typeId,
        );
        const propertyTypeLabel = propertyType?.name ?? "";

        const documentType = documentTypesValue?.response.find(
          (dt) => dt.id === documentTypeId,
        );
        const documentTypeLabel = documentTypeId
          ? ` (${documentType?.name ?? ""})`
          : "";

        return {
          value: id,
          label: propertyTypeLabel + documentTypeLabel,
        };
      }) ?? [],
    [
      documentTypesValue?.response,
      propertyConfigsValue?.response,
      propertyTypesValue?.response,
    ],
  );

  const grid = useMemo(
    () =>
      pendencyConfigsGrid(
        pendencyTypesValue?.response ?? [],
        pendencyConfigsValue?.response ?? [],
        propertyConfigLookup,
      ),
    [
      pendencyConfigsValue?.response,
      pendencyTypesValue?.response,
      propertyConfigLookup,
    ],
  );

  return (
    <OCard>
      <OCardHeader>
        <OTypography weight="500">Pendências iniciais</OTypography>
      </OCardHeader>
      <OCardBody>
        <GridTemplate
          actions={
            <OButton onClick={() => gridRef.current?.instance.addRow()}>
              <OIcon category="orq" icon="orq-add-plus" />
            </OButton>
          }
          gridRef={gridRef}
          showExportButton
          showRefreshButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            dataSource={dataSource}
            loading={pendencyTypesLoading}
          />
        </GridTemplate>
      </OCardBody>
    </OCard>
  );
};
