import { ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { SignatureRule } from "../../customer-signatures.types";
import { SignatureRuleConfigsFields } from "./signature-rule-configs-fields.component";
import { useSignatureRuleConfigs } from "./signature-rule-configs.hook";

interface SignatureRuleProps {
  signatureRule: SignatureRule;
}

export const SignatureRuleConfigs = ({ signatureRule }: SignatureRuleProps) => {
  const { form, loading, submit } = useSignatureRuleConfigs(signatureRule);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          dataAction="regra_assinatura:botao:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={() => submit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <SignatureRuleConfigsFields />
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
