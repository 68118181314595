import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { RecommendedAdequacyOptionsForm } from "../../recommended-adequacy-options.form";

export const useAdequacyOptionSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getAdequacyOptions,
  );

  const { clearErrors, setError } =
    useFormContext<RecommendedAdequacyOptionsForm>();

  const getAdequacyOptions = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("adequacyOptionIds");
    else
      setError("adequacyOptionIds", {
        message:
          "Erro ao buscar as adequadoras. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getAdequacyOptions();
  }, [getAdequacyOptions]);

  const adequacyOptions = useMemo(
    () =>
      value?.response.map<SelectSearchMultipleOption<number>>(
        (adequacyOption) => ({
          customKey: adequacyOption.id,
          label: `${adequacyOption.name} - ${fastCpfCnpjMask(
            adequacyOption.entity.taxId,
          )}${adequacyOption.createdByCustomer ? "*" : ""}`,
          value: adequacyOption.id,
        }),
      ) ?? [],
    [value],
  );

  return {
    adequacyOptions,
    getAdequacyOptions,
    loading,
    hasFetchError: hasError,
  };
};
