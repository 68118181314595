import { OCard, OCardBody, OToastManager, OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  AccountResume,
  DetailsLine,
  DoubleDetailsCollection,
  ObjectUtils,
  PaymentUtils,
} from "../_compose";
import { PaymentDataValidator } from "../_compose/validators";
import { usePaymentsAndTransfersContext } from "../payments-and-transfers.context";
import { ActionButton, OperationAmount, PaymentStatus } from "./_compose";
import { usePaymentsAndTransfersDetails } from "./payments-and-transfers-details.hook";
import { MapperAccountType } from "./payments-and-transfers-details.utils";

export const PaymentsAndTransfersDetails = () => {
  const { clickedRow } = usePaymentsAndTransfersContext();

  const { assembleDetailLines } = usePaymentsAndTransfersDetails();

  const {
    customer: { identification },
  } = useCustomer();
  const navigate = useNavigate();

  const [paymentData, setPaymentData] = useState<ApiPayments.PaymentData>();

  const isValid = ObjectUtils.validateInterface<ApiPayments.PaymentData>(
    clickedRow,
    PaymentDataValidator,
  );

  const detailLines = useMemo<DetailsLine[]>(() => {
    if (!paymentData) return [];

    return assembleDetailLines(paymentData);
  }, [assembleDetailLines, paymentData]);

  useEffect(() => {
    if (isValid) {
      setPaymentData(clickedRow);
    } else {
      OToastManager.danger("Erro ao carregar informações da operação.");
      navigate(
        corporateRouter.routes.banking.customer.paymentsAndTransfers.path,
      );
    }
  }, [isValid, clickedRow, navigate]);

  return (
    <div className="d-flex flex-column gap-3">
      <PageTitle
        title="Informações detalhadas da transação"
        description="Confira as informações da operação selecionada."
      />

      <AccountResume
        accountNumber={paymentData?.debitParty.account.number ?? ""}
        agency={paymentData?.debitParty.account.branch ?? ""}
        bankName="BANCO BTG PACTUAL S/A"
        title="Dados da conta de origem"
        accountType={
          paymentData && MapperAccountType[paymentData?.debitParty.account.type]
        }
      />

      <OCard>
        <OCardBody className="d-flex flex-column gap-4">
          <OTypography type="dark" size="lg">
            Dados da operação
          </OTypography>

          {paymentData && <PaymentStatus paymentDetail={paymentData} />}

          <DoubleDetailsCollection detailLines={detailLines} />

          {paymentData?.amount && (
            <OperationAmount amount={paymentData?.amount} />
          )}

          {paymentData?.status &&
            PaymentUtils.canDownloadPaymentReceipt(paymentData?.status) && (
              <ActionButton
                text="Download do comprovante"
                icon="orq-download"
                onclick={() =>
                  PaymentUtils.handleDownloadPaymentsReceipt(identification, [
                    paymentData?.id,
                  ])
                }
              />
            )}
        </OCardBody>
      </OCard>
    </div>
  );
};
