import { logger } from "utils/logger";
import {
  isAIntervalAmountSectionFilter,
  isAIntervalCountSectionFilter,
  isAMultFinancialInstitutionSelectionSectionFilter,
  isAMultSelectionSectionFilter,
  isASelectableSearchSectionFilter,
} from "./_compose/side-form-modal/side-form-modal.utils";
import {
  FiltersToSubmit,
  FormFieldsToFilter,
  IntervalValue,
  Section,
  SelectableSearchValue,
  SideFilterBarForm,
} from "./side-filter-bar.types";

const validateMultSelectionSectionFilterValue = (data: string[]): boolean => {
  try {
    return !!data.length && data.every((item) => !!item);
  } catch {
    return false;
  }
};

const validateSelectableSearchSectionFilterValue = (
  data: SelectableSearchValue,
): boolean => {
  try {
    return !!data.value;
  } catch {
    return false;
  }
};

const validateIntervalSectionFilterValue = (data: IntervalValue): boolean => {
  try {
    return !data.every((item) => !item);
  } catch {
    return false;
  }
};

const assembleSelectableSearchSectionFilterValue = (
  data: SelectableSearchValue,
): [string, string] => {
  const entries: [string, string] = [data.filterParam, data.value];

  return entries;
};

// TO DO: revisar interfaces deste cara pra não precisar do generic
export const assembleSelectableSearchDefaultValues = <Filter>(
  currentFilter: Filter,
  keys: Array<keyof Filter>,
): SelectableSearchValue => {
  for (const key of keys) {
    const value = currentFilter[key];
    if (!!value && typeof value === "string") {
      return { filterParam: key.toString(), value };
    }
  }

  return { filterParam: keys[0].toString(), value: "" };
};

export const assembleDefaultValuesFromSections = (
  sections: Section[],
): SideFilterBarForm => {
  try {
    const entries = sections.reduce<[string, unknown][]>((data, section) => {
      data.push([section.formId, section.defaultValue]);
      return data;
    }, []);

    const defaultValues = Object.fromEntries(entries);

    return defaultValues;
  } catch (error) {
    logger.error(error);
    return {};
  }
};

export const removeNullableFormFields = (
  data: SideFilterBarForm,
  sections: Section[],
): FormFieldsToFilter => {
  const form = Object.fromEntries<NonNullable<any>>(
    Object.entries(data).filter(([key, value]) => {
      try {
        const section = sections.find(({ formId }) => formId === key);

        if (!section) throw new Error(`Error in find section from ${key}`);

        if (isAMultSelectionSectionFilter(section)) {
          return validateMultSelectionSectionFilterValue(value);
        }

        if (isASelectableSearchSectionFilter(section)) {
          return validateSelectableSearchSectionFilterValue(value);
        }

        const isAIntervalSectionSectionFilter =
          isAIntervalAmountSectionFilter(section) ||
          isAIntervalCountSectionFilter(section);

        if (isAIntervalSectionSectionFilter) {
          return validateIntervalSectionFilterValue(value);
        }

        if (isAMultFinancialInstitutionSelectionSectionFilter(section)) {
          return validateMultSelectionSectionFilterValue(value);
        }

        return !!value;
      } catch (error) {
        logger.error(error);
        return false;
      }
    }),
  );
  return form;
};

export const assembleFiltersToSubmit = (
  data: FormFieldsToFilter,
  sections: Section[],
): FiltersToSubmit => {
  const filters = Object.fromEntries<string | string[]>(
    Object.entries(data).reduce<[string, string | string[]][]>(
      (entries, [key, value]) => {
        try {
          const section = sections.find(({ formId }) => formId === key);

          if (!section) throw new Error(`Error in find section from ${key}`);

          if (isASelectableSearchSectionFilter(section)) {
            const entry = assembleSelectableSearchSectionFilterValue(value);
            if (entry) return entries.concat([entry]);
          }

          return entries.concat([[key, value]]);
        } catch (error) {
          logger.error(error);
          return entries;
        }
      },
      [],
    ),
  );

  return filters;
};

export const loadFieldValueReset = (
  fieldValue: string | SelectableSearchValue,
): undefined | SelectableSearchValue => {
  if (typeof fieldValue === "string") return undefined;

  if (validateSelectableSearchSectionFilterValue(fieldValue)) {
    return { ...fieldValue, value: "" };
  }
};
