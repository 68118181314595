import { ODivider, ORow, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { TipoProdutoField } from "../../../../../../components/estrategia-form/fields";
import { useAddCompanyEstrategia } from "./add-company-estrategia.hook";

export const AddCompanyEstrategia = () => {
  const { form, Form, loading, submit } = useAddCompanyEstrategia();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar estratégia" />}
      actions={
        <LoadingButton
          dataAction="formulario:botao:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={() => submit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OTypography size="lg">Tipo de produto</OTypography>
          <ODivider type="dark" size="xxs" className="mb-2" />
          <ORow columnGap={4} rowGap={5} className="ms-2">
            <TipoProdutoField type="company" />
          </ORow>

          {Form && <Form form={form} shouldReset />}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
