import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

interface DownloadSignedDocumentButtonProps {
  possibleDocument: HubEnergy.PossibleDocumentResponse;
}

export const DownloadSignedDocumentButton = ({
  possibleDocument,
}: DownloadSignedDocumentButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.paperclipv2.getContractSignedFileBinary,
  );

  const download = useCallback(async () => {
    const activeFile = possibleDocument.actualDocument.files.find(
      (f) => f.isActive && f.isValid,
    );
    if (!activeFile) return OToastManager.danger("No activeFile");
    const { externalId } = activeFile;
    const { success, response } = await callService(externalId);
    if (success) {
      const filename = possibleDocument.consumerUnit
        ? `${possibleDocument.consumerUnit.officialName}-${possibleDocument.consumerUnit.installationNumber}-${possibleDocument.actualDocument.type.name}.pdf`
        : `${possibleDocument.customer.officialName}-${possibleDocument.actualDocument.type.name}.pdf`;
      helpers.downloadBlobFile(filename, response.data);
      OToastManager.success("Download feito com sucesso");
    } else {
      OToastManager.danger("Não foi possível baixar o documento");
    }
  }, [callService, possibleDocument]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-download" }}
      loading={loading}
      onClick={download}
    >
      Download
    </IconButton>
  );
};
