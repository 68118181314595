import { OConfirmationModal } from "@maestro/react";

export type ConfirmationModalProps = {
  onConfirm: () => void;
  id: string;
  isConfirmationModalProcessing: boolean;
};

export const ConfirmationApprovalModal = ({
  onConfirm,
  isConfirmationModalProcessing,
}: ConfirmationModalProps) => {
  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId="modal-confirmation-approval"
      loading={isConfirmationModalProcessing}
      title="Você confirma a aprovação de liquidação por repasse?"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={onConfirm}
    />
  );
};
