import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useDiligenceStep } from "../../../../../../diligence-step.context";
import { EditStepPropertyForm } from "../../edit-step-property-modal.form";

export const usePropertyTypeSelector = () => {
  const {
    propertyTypesCallService,
    propertyTypesHasError,
    propertyTypesLoading,
    propertyTypesValue,
  } = useDiligenceStep();

  const { clearErrors, setError } = useFormContext<EditStepPropertyForm>();

  const getPropertyTypes = useCallback(async () => {
    const { success } = await propertyTypesCallService();
    if (success) clearErrors("typeId");
    else
      setError("typeId", {
        message:
          "Erro ao buscar os tipos de propriedade. Clique no botão ao lado para tentar novamente.",
      });
  }, [propertyTypesCallService, clearErrors, setError]);

  const propertyTypes = useMemo(
    () =>
      propertyTypesValue?.response.map<SelectSearchFieldOption<number>>(
        ({ label, id }) => ({
          label,
          value: id,
        }),
      ) ?? [],
    [propertyTypesValue?.response],
  );

  return {
    propertyTypes,
    getPropertyTypes,
    loading: propertyTypesLoading,
    hasFetchError: propertyTypesHasError,
  };
};
