import { OCard, OCardBody, OIcon, OTypography } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { useCards } from "./cards.hook";
import { StyledCard } from "./cards.styles";

export const Cards = () => {
  const navigate = useNavigate();
  const { handleCreateCampaign, canEdit, canAccessTemplates } = useCards();

  return (
    <div className="d-flex flex-row gap-4">
      {canEdit && (
        <StyledCard>
          <OCard className="h-100">
            <OCardBody
              onClick={() => handleCreateCampaign()}
              className="d-flex flex-column justify-content-between h-100 p-2 btn"
            >
              <div className="d-flex justify-content-end">
                <OIcon
                  className="align-self-end"
                  size="xl"
                  type="info"
                  category="orq"
                  icon="orq-add-plus"
                />
              </div>

              <OTypography className="d-flex text-left" type="primary">
                Criar campanha
              </OTypography>
            </OCardBody>
          </OCard>
        </StyledCard>
      )}

      {canAccessTemplates && (
        <StyledCard>
          <OCard className="h-100">
            <OCardBody
              onClick={() =>
                navigate(
                  corporateRouter.routes.workflow.product.bannerManagement
                    .bannerTemplates.path,
                )
              }
              className="d-flex flex-column justify-content-between h-100 p-2 btn"
            >
              <div className="d-flex justify-content-end">
                <OIcon
                  className="align-self-end"
                  size="xl"
                  type="info"
                  category="orq"
                  icon="orq-edit-copy"
                />
              </div>

              <OTypography className="d-flex text-left" type="primary">
                Templates de campanha
              </OTypography>
            </OCardBody>
          </OCard>
        </StyledCard>
      )}
    </div>
  );
};
