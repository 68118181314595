import * as yup from "yup";

export const formValidationSchema = yup.object().shape({
  taxId: yup.string().required("Campo obrigatório"),
  reasonDescription: yup.string().required("Motivo é obrigatório"),
});

export const formDefaultValues = {
  taxId: "",
  name: "",
  reasonDescription: "",
};
