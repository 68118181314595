import { behavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import { dummyStorage, safeLocalStorage, safeSessionStorage } from "./storage";

const isSessionStorageEnabled = () =>
  behavior.value?.roles.includes(BehaviorRoles.SessionStorage_Enable);

const behaviorStorage = (storage: Storage): Storage => ({
  clear: () =>
    isSessionStorageEnabled() ? storage.clear() : dummyStorage.clear(),
  getItem: (key) =>
    isSessionStorageEnabled()
      ? storage.getItem(key)
      : dummyStorage.getItem(key),
  key: (index) =>
    isSessionStorageEnabled() ? storage.key(index) : dummyStorage.key(index),
  removeItem: (key) =>
    isSessionStorageEnabled()
      ? storage.removeItem(key)
      : dummyStorage.removeItem(key),
  setItem: (key, value) =>
    isSessionStorageEnabled()
      ? storage.setItem(key, value)
      : dummyStorage.setItem(key, value),
  get length() {
    return isSessionStorageEnabled() ? storage.length : dummyStorage.length;
  },
});

export const behaviorSessionStorage = behaviorStorage(safeSessionStorage);
export const behaviorLocalStorage = behaviorStorage(safeLocalStorage);
