import { OIcon } from "@maestro/react";
import { Tooltip } from "devextreme-react/tooltip";

interface GridHeaderTooltipProps {
  caption: string | undefined;
  tooltipContent?: string;
}

export const GridHeaderTooltip = ({
  caption,
  tooltipContent,
}: GridHeaderTooltipProps) => {
  const id = `grid-header-tooltip-${caption?.match(/[a-z]/g)?.join("")}`;
  return (
    <span style={{ whiteSpace: "normal" }} id={id}>
      {tooltipContent && (
        <>
          <Tooltip
            target={`#${id}`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position="top"
          >
            {tooltipContent}
          </Tooltip>
          <OIcon category="fal" icon="fa-info-circle" className="d-inline" />
        </>
      )}{" "}
      {caption}
    </span>
  );
};
