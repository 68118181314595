import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { GetApiAdminViewResponse } from "services/hubcreditmanager/models/responses";

interface WorkflowConfigViewFormProps {
  viewOptions?: GetApiAdminViewResponse;
}

export const WorkflowConfigViewForm = ({
  viewOptions,
}: WorkflowConfigViewFormProps) => (
  <form spellCheck="false">
    <ORow>
      <OCol>
        <ORFieldSelect
          id="viewId"
          name="viewId"
          label="View"
          labelSize="md"
          dataAction="form-oferta:texto:id-visualizacao"
          dataLabel="id-visualizacao"
          placeholder="Selecionar"
        >
          {viewOptions?.map((option) => (
            <OOption
              value={option.id}
              key={`view-option-${option.id}`}
            >{`${option.name} (${option.id})`}</OOption>
          ))}
        </ORFieldSelect>
      </OCol>
    </ORow>
  </form>
);
