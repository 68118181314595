import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowBannerById } from "../../../../../routes/workflow.route-params";
import { useBanner } from "../../banner-management.hook";
import {
  bannerFormDefaultValues,
  bannerFormValidationSchema,
  BannerTemplateFormFields,
} from "../banner-templates.schema";
import { convertBase64ToIntArray } from "./banner-templates-form.utils";

export const useBannerTemplateForm = () => {
  const navigate = useNavigate();
  const form = useForm<BannerTemplateFormFields>({
    resolver: yupResolver(bannerFormValidationSchema),
    defaultValues: bannerFormDefaultValues,
  });

  const { id } = useParams<WorkflowBannerById>();

  const { setValue, reset } = form;

  const { callService: getBannerTemplateById, value: data } = useServiceCall(
    service.hubCreditManager.getBannerTemplateById,
  );

  const { callService: callUpsertBannerTemplate, loading: loadingSubmit } =
    useServiceCall(service.hubCreditManager.upsertBannerTemplate);

  const { imageUrl, setImageUrl } = useBanner({
    fileKey: data?.imageFile?.key,
    fileName: data?.imageFile?.name,
  });

  const onAddFile = useCallback(
    async (e: OUploadCustomEvent<File>) => {
      const file = e.detail;

      setValue("imageFile", [file]);

      const base64 = await helpers.convertFileToBase64(file);

      const intArray = convertBase64ToIntArray(base64);
      const url = URL.createObjectURL(
        new Blob([intArray], {
          type: "application/pdf;base64",
        }),
      );

      setImageUrl(url);
    },
    [setImageUrl, setValue],
  );

  const upsertTemplate = useCallback(
    async (values: BannerTemplateFormFields) => {
      const formData = new FormData();

      formData.append("identification", values.identification);
      formData.append("description", values.description);
      formData.append("imageAlt", values.imageAlt);
      formData.append("actionText", values.actionText);
      formData.append("actionRef", values.actionRef);
      formData.append("openInNewTab", String(!!values.openInNewTab.length));

      if (values.imageFile.length) {
        formData.append("imageFile", values.imageFile[0]);
      }

      if (id) {
        formData.append("id", id);
      }

      const { success } = await callUpsertBannerTemplate(formData);

      if (success) {
        OToastManager.success(
          `Template ${id ? "editado" : "adicionado"} com sucesso.`,
        );

        navigate(
          corporateRouter.routes.workflow.product.bannerManagement
            .bannerTemplates.path,
        );
      } else {
        OToastManager.danger(
          `Um erro ocorreu ao tentar ${
            id ? "editar" : "adicionar"
          } o template.`,
        );
      }
    },
    [callUpsertBannerTemplate, id, navigate],
  );

  useEffect(() => {
    if (!data) return;

    const { imageFile, openInNewTab, ...values } = data;

    reset(values);
    setValue("openInNewTab", openInNewTab ? ["true"] : []);

    if (imageFile) {
      setValue("imageFile", [imageFile as unknown as File]);
    }
  }, [data, reset, setValue]);

  useEffect(() => {
    if (!id) return;

    getBannerTemplateById(id);
  }, [getBannerTemplateById, id]);

  return {
    upsertTemplate,
    onAddFile,
    form,
    loadingSubmit,
    imageUrl,
  };
};
