import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { EmprestimosProdutoGestorLimitesById } from "pages/emprestimos/routes/emprestimos.route-params";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { financedAssetDetailsGenerator } from "./financed-asset-details.details";

export const FinancedAssetDetailsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const {
    callService: getFinancedAsset,
    value: financedAsset,
    hasError,
    loading,
  } = useServiceCall(service.hubCreditManager.getFinancedAsset);

  useEffect(() => {
    getFinancedAsset(id);
  }, [getFinancedAsset, id]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do ativo financiado" />}
    >
      <DetailsCard
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os detalhes do ativo financiado."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getFinancedAsset(id)} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={financedAsset}
        fields={financedAssetDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
