import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../routes/workflow.route-params";
import { workflowConfigOffersGrid } from "./offers.grid";

export const WorkflowConfigOffersPage = () => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const workflowConfigOffers = workflowConfigOffersGrid(id);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ofertas" />}
      actions={
        <>
          <RouterButton
            dataAction="workflows_configurações:botao:criar"
            dataLabel="criar"
            role={
              roles.workflow.product.workflowsConfigDetailsOffersAddPage.role
            }
            href={corporateRouter.routes.workflow.product.workflowsConfig.details.edit.offers.add.path(
              { id },
            )}
          >
            Criar
          </RouterButton>
          <RefreshGridButton
            onClick={() => {
              !workflowConfigOffers.datagrid.dataSource?.isLoading() &&
                workflowConfigOffers.datagrid.dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator grid={workflowConfigOffers} />
    </GridTemplate>
  );
};
