import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { WorkflowLeadBatchRequest } from "./batch-request-details.types";

export const workflowLeadBatchRequestDetailsGenerator = (
  workflowLeadBatchRequest: WorkflowLeadBatchRequest,
) => {
  return {
    details: {
      title: "Informações do Processamento",
      items: [
        {
          label: "Data solicitação",
          value:
            dayjs(workflowLeadBatchRequest?.createdDate).format(
              "DD/MM/YYYY HH:mm:ss",
            ) ?? "-",
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Originador",
          value: `${
            workflowLeadBatchRequest?.originator?.name ?? ""
          } - ${masks.cpfCnpj(
            workflowLeadBatchRequest?.originator?.identification,
          )}`,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Nome do arquivo",
          value: `${workflowLeadBatchRequest?.name} `,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Status atual",
          value: (
            <div className="d-flex flex-row gap-2">
              {workflowLeadBatchRequest?.status}
              {workflowLeadBatchRequest?.status === "ERROR" &&
              workflowLeadBatchRequest.exceptionError ? (
                <OTypography type="warning">
                  - {workflowLeadBatchRequest.exceptionError}
                </OTypography>
              ) : (
                ""
              )}
            </div>
          ),
          grid: { xs: 12, md: 12 },
        },
        {
          label: "Solicitações Processadas",
          value: workflowLeadBatchRequest?.processedLeadCount ?? "-",
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Canal",
          value: workflowLeadBatchRequest?.channel.name ?? "-",
          grid: { xs: 12, md: 4 },
        },
      ],
    },
  } satisfies DetailsFields;
};
