import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { customerWorkflowsTrackerGrid } from "./customer-workflows-tracker.grid";
import { CustomerWorkflowTrackerResponse } from "./customer-workflows-tracker.types";

const dataSourceGenerator = (taxId: string) =>
  dataSourceCustomStoreGenerator<CustomerWorkflowTrackerResponse>(
    () => {
      return service.clerk
        .getWorflowByTaxId({ identification: taxId })
        .then(({ data }) => ({
          data: data ?? [],
        }))
        .catch(() => {
          const errorMessage = "Erro ao buscar workflows";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    {
      dataSourceOptions: {
        sort: [
          {
            desc: true,
            selector: "insertedAt",
          },
        ],
      },
    },
  );

export const CustomerWorkflowTracker = () => {
  const { customer } = useCustomer();
  const gridRef = useGridRef();
  const dataSource = useMemo(
    () => dataSourceGenerator(customer.identification),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Convites" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={customerWorkflowsTrackerGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
