import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";

export const importDataInfo = (
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse,
): DetailsFields => ({
  root: {
    title: "Importação",
    items: [
      {
        label: "Id",
        value: importData.id,
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Id agrupamento",
        value: importData.groupingId,
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Id boleta NES",
        value: importData.nesOperationId,
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Status",
        value: importData.status,
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Status da proposta",
        value: importData.tradeStatus,
        grid: { xs: 12, md: 6, xl: 4 },
      },
    ],
  },
  ...(importData.lead && {
    mockedLead: {
      title: "Lead",
      items: [
        {
          label: "Id correlação",
          value: importData.lead.correlationId,
          grid: { xs: 12, md: 6, xl: 4 },
        },
        {
          label: "Nome cliente",
          value: importData.lead.name,
          grid: { xs: 12, md: 6, xl: 4 },
        },
        {
          label: "CNPJ cliente",
          value: masks.cpfCnpj(importData.lead.taxId),
          grid: { xs: 12, md: 6, xl: 4 },
        },
      ],
    },
    proposal: {
      title: "Proposta",
      items: [
        {
          label: "Tipo",
          value:
            importData.lead.acceptedProposal.proposals?.[0]?.approvedAmountType,
          grid: { xs: 12, md: 6, xl: 4 },
        },
        {
          label: "Tipo",
          value: masks.percentage(
            importData.lead.acceptedProposal.proposals?.[0]?.approvedAmount,
          ),
          grid: { xs: 12, md: 6, xl: 4 },
        },
      ],
    },
  }),
});

export const consumerUnitInfo = (
  u: HubEnergy.LegacyImport.ConsumerUnit,
): DetailsFields => ({
  basicData: {
    title: u.description,
    items: [
      {
        label: "Nome",
        value: u.name,
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "CNPJ",
        value: masks.cpfCnpj(u.taxId),
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Unidade principal",
        value: u.isMainUnit ? "SIM" : "NÃO",
        grid: { xs: 12, md: 6, xl: 4 },
      },
      {
        label: "Endereço",
        value:
          u.address &&
          `${[
            u.address.street,
            u.address.number,
            u.address.complement,
            u.address.neighborhood,
          ]
            .filter((value) => !!value)
            .join(", ")} | ${[
            u.address.city,
            u.address.state,
            masks.cep(u.address.zipCode),
          ]
            .filter((value) => !!value)
            .join(" - ")}`,
        grid: { xs: 12, md: 6, xl: 4 },
      },
    ],
  },
  consumptionData: {
    items: [
      {
        label: "Distribuidora Vinculada",
        value: `${u.distributor.name} - ${masks.cpfCnpj(u.distributor.taxId)}`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Número de instalação",
        value: u.installationNumber,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Modalidade tarifária",
        value: `${u.fareGroup ?? ""} ${u.modality ?? ""}`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Consumo medio da unidade (MWh)",
        value: u.volume,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Volume (MWm)",
        value: u.volumePerHour,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Demanda contratada ponta (kW)",
        value: u.peakDemand,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Demanda contratada fora-ponta (kW)",
        value: u.offPeakDemand,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Percentual de alocação do volume mínimo na ponta",
        value: masks.percentage(u.minimumPeakAllocation),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data da migração",
        value: u.migrationDate && dayjs(u.migrationDate).format("DD/MM/YYYY"),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data final de suprimento",
        value: u.supplyEndDate && dayjs(u.supplyEndDate).format("DD/MM/YYYY"),
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
