import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import {
  GroupedValuesForm,
  searchModalDefaulValues,
  searchModalValidationSchema,
} from "./search-modal.form";

export const useSearchModal = (
  setParams: (newParams: GroupedValuesForm) => void,
) => {
  const form = useForm<GroupedValuesForm>({
    defaultValues: searchModalDefaulValues,
    resolver: yupResolver(searchModalValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(() => reset(searchModalDefaulValues), [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((loadOptions) => {
        setParams(loadOptions);
      }),
    [handleSubmit, setParams],
  );

  return {
    form,
    submit,
    clear,
  };
};
