import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useCreditLineField } from "./credit-line-field.hook";

export const CreditLineField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, processorConfigId, name, description } =
    processorConfigProperty;
  const { creditLines, loadingCreditLines } = useCreditLineField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const creditLineField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <ORFieldSelect
          key={creditLines
            ?.map(({ identification }) => identification)
            .join("")}
          id={`properties.${name}_${processorPropertyId}`}
          name={`properties.${name}_${processorPropertyId}`}
          placeholder="Selecione"
          label={description}
        >
          <div>
            {creditLines?.map(({ identification }) => (
              <OOption
                value={identification}
                key={`credit-lines-option-${identification}`}
              >
                {identification}
              </OOption>
            ))}
          </div>
        </ORFieldSelect>
        {loadingCreditLines && <OLoader />}
      </div>
    ),
    [
      processorPropertyId,
      processorConfigId,
      creditLines,
      loadingCreditLines,
      name,
      description,
    ],
  );

  return <>{creditLineField}</>;
};
