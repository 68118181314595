import { OCard, OCardBody, ORFieldInput, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BankslipDetails } from "./_compose/bankslip-details";
import { useBankslipSearchCard } from "./bankslip-search-card.hook";
import { BankslipSearchFormFields } from "./bankslip-search.types";

const BARCODE_LENGTH = 44;
const DIGITABLE_LINE_LENGTH = 47;

export const BankslipSearch = () => {
  const { bankslip, isLoading, searchBankslip } = useBankslipSearchCard();
  const form = useForm<BankslipSearchFormFields>();
  const { watch } = form;
  const value = watch("value");

  useEffect(() => {
    const debounce = setTimeout(() => {
      const isPotentialBarcodeOrDigitableLine = [
        BARCODE_LENGTH,
        DIGITABLE_LINE_LENGTH,
      ].includes(value.length);

      if (!isPotentialBarcodeOrDigitableLine) return;
      searchBankslip(value);
    }, 1500);

    return () => clearTimeout(debounce);
  }, [searchBankslip, value]);

  return (
    <div className="d-flex flex-column gap-4">
      <FormProvider {...form}>
        <OCard>
          <OCardBody className="d-flex flex-column gap-3">
            <OTypography type="dark">Consulta de boletos</OTypography>

            <ORFieldInput
              id="value"
              name="value"
              tag="mask"
              label="Código de Barras / Linha digitável"
              description="Para consultar informações do boleto, basta inserir a linha digitável ou o código de barras."
              maskOption={{
                mask: [
                  {
                    mask: "000 0 000000000000000 00000 000000 00000000000000",
                  },
                  {
                    mask: "00000.00000 00000.000000 00000.000000 0 00000000000000",
                  },
                ],
              }}
              disabled={isLoading}
            />
          </OCardBody>
        </OCard>
      </FormProvider>

      {!!bankslip && <BankslipDetails bankslip={bankslip} />}
    </div>
  );
};
