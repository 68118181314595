export interface IChargebackTransactionItem {
  creditAccountId: string;
  authorizationId: string;
  description: string;
  amount: number;
}

export interface ISalesforceTokenInfo {
  numberTicket?: string;
  dhOpenTicket?: string;
  isChargeback?: boolean;
}

interface IUserInfo {
  cpf: string;
  email: string;
  name: string;
}

export interface IUsersInfo extends IUserInfo {
  attendant: IUserInfo;
  phone: string;
}

export interface ITransactionChargeBackProps {
  chargebackTransactionItemData?: IChargebackTransactionItem;
  finishRequestContestation: Function;
  selectedCompany: string;
  salesforceTokenInfo: ISalesforceTokenInfo;
}

export const ApiCodeErrorText: Record<string, string> = {
  CONTESTATION_ALREADY_CREATED:
    "Já existe uma contestação em aberto para essa transação",
};

export type TreeName = "initialTree" | "chargeBackTree" | "fraudTree";

export type TreeType = "INITIAL" | "CHARGEBACK" | "FRAUD";

export interface ILinkHeaded {
  treeName: TreeName;
  nodeAlias: string;
  treeType: TreeType;
  treeChannel: "PORTAL";
}

export interface IHeadedButtonInitialIteration {
  type: "button";
  label: string;
  link: ILinkHeaded;
}

export interface IHeadedButtonIteration {
  id: "btnBack" | "btnAdvance" | "btnFinish";
  type: "button";
  label: string;
  link: ILinkHeaded;
}

export interface IInitialNode {
  nodeName: string;
  nodeAlias: string;
  title: string;
  responseType: "button";
  questions: Array<IHeadedButtonInitialIteration>;
  response: Array<unknown>;
}

export interface IMiddleButtonIteration {
  id: "btnBack" | "btnAdvance" | "btnFinish";
  type: "button";
  label: string;
  link: string;
}

export interface IMiddleSimpleTextIteration {
  type: "text";
  label: string;
  name: string;
}

export interface IMiddleComposedTextIteration {
  type: "email" | "datetime-local" | "tel" | "text" | "number";
  label: string;
  placeholder: string;
  name: string;
}

export type LinkChannels =
  | "selectChannelEmail"
  | "selectChannelWhatsApp"
  | "selectChannelPhone"
  | "selectChannelPresent";

export interface IMiddleFormTextIteration {
  type: "email" | "datetime-local" | "tel" | "text";
  label: string;
  placeholder: string;
  name: string;
  link: LinkChannels;
}

export interface IMidleCheckboxIteration {
  id: string;
  type: "checkbox";
  label: string;
  name: string;
  link: LinkChannels;
}

export interface ITextMiddleNodeResponse {
  label: string;
  value: string;
}

export interface ITextMiddleNode {
  nodeName: string;
  nodeAlias: string;
  title: string;
  responseType: "text";
  questions: Array<
    | IMiddleSimpleTextIteration
    | IMiddleComposedTextIteration
    | IMiddleFormTextIteration
  >;
  actions: Array<IHeadedButtonIteration | IMiddleButtonIteration>;
  response: Array<unknown>;
}

export interface IButtonMiddleNode {
  nodeName: string;
  nodeAlias: string;
  title: string;
  responseType: "button";
  questions?: Array<IMiddleButtonIteration>;
  actions: Array<IHeadedButtonIteration | IMiddleButtonIteration>;
  response: Array<unknown>;
}

export interface IHTMLMiddleNode {
  nodeName: string;
  nodeAlias: string;
  title: string;
  responseType: "html";
  actions: Array<IHeadedButtonIteration | IMiddleButtonIteration>;
}

export interface ICheckboxMiddleNode {
  nodeName: string;
  nodeAlias: string;
  title: string;
  responseType: "checkbox";
  questions: Array<IMidleCheckboxIteration>;
  actions: Array<IMiddleButtonIteration>;
  response: Array<unknown>;
}

export interface ITreeFile {
  treeName: "initialTree" | "fraudTree" | "chargeBackTree";
  treeVersion: string;
  nodes: Array<
    | IInitialNode
    | IButtonMiddleNode
    | ITextMiddleNode
    | IHTMLMiddleNode
    | ICheckboxMiddleNode
  >;
}

export interface IActiveQuestionData {
  index: number;
  data:
    | IInitialNode
    | IButtonMiddleNode
    | ITextMiddleNode
    | ICheckboxMiddleNode
    | IHTMLMiddleNode;
}
