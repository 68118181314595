import { LoadingButton } from "components/loading-button";
import { useGridActions } from "../../_compose/grid-actions/grid-actions.hook";

interface AccountCloseDownloadFileButtonProps {
  filekey: string;
}

export const AccountCloseDownloadFileButton = ({
  filekey,
}: AccountCloseDownloadFileButtonProps) => {
  const { downloadFile, loadingFile } = useGridActions();
  return (
    <LoadingButton
      title="Download"
      ariaLabel="Download"
      loading={loadingFile}
      onClick={async () => {
        await downloadFile(filekey);
      }}
    >
      Download
    </LoadingButton>
  );
};
