import { OIcon } from "@maestro/react";

const SuccessIcon = () => (
  <OIcon
    category="fas"
    icon="fa-check-circle"
    size="lg"
    type="success"
    className="d-block"
  />
);

const DangerIcon = () => (
  <OIcon
    category="fas"
    icon="fa-times-circle"
    size="lg"
    type="danger"
    className="d-block"
  />
);

interface StatusIconProps {
  status: "success" | "danger";
}

export const StatusIcon = ({ status }: StatusIconProps) =>
  status === "success" ? <SuccessIcon /> : <DangerIcon />;
