import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useDistributorSelector } from "./distributor-selector.hook";

interface DistributorSelectorProps {
  disabled?: boolean;
}

export const DistributorSelector = ({ disabled }: DistributorSelectorProps) => {
  const { getDistributors, hasFetchError, loading, options } =
    useDistributorSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={options}
        id="currentDistributorId"
        name="currentDistributorId"
        placeholder="Selecionar"
        label="Distribuidora"
        disabled={disabled}
        required
      />
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={() => getDistributors()} />
      )}
    </div>
  );
};
