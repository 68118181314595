import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../components/mapped-status-badge";
import { energyDistributorStatusMap } from "../../../utils/status-maps/distributor.status";

type Distributor = NonNullable<
  Awaited<
    ReturnType<typeof service.hubEnergy.getDistributors>
  >["data"]["response"]
>[number];

export const energyDistributorsGrid = {
  datagrid: {
    noDataText: "Nenhuma distribuidora encontrada.",
    headerFilter: { visible: true },
    filterRow: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("energyDistributorsGrid"),
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubEnergy
        .getDistributors()
        .then(({ data }) => data.response ?? []),
    ),
  },
  columns: [
    {
      role: roles.energia.product.distributorDetails.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.energia.product.distributor.byId.basicData.path(
            {
              distributorId: data.id,
            },
          )}
        />
      ),
      allowExporting: false,
      calculateCellValue: ({ id }) => id,
      allowFiltering: true,
      allowHeaderFiltering: false,
      allowSorting: true,
      width: "auto",
    },
    {
      dataField: "entity.name",
      caption: "Nome",
      sortOrder: "asc",
      sortIndex: 1,
    },
    {
      dataField: "entity.officialName",
      caption: "Razão social",
    },
    {
      dataField: "entity.taxId",
      caption: "CNPJ",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "address.city",
      caption: "Cidade",
    },
    {
      dataField: "status",
      caption: "Status",
      alignment: "center",
      cellRender: ({ data }) => (
        <MappedStatusBadge
          map={energyDistributorStatusMap}
          status={data.status}
        />
      ),
      sortOrder: "desc",
      sortIndex: 0,
    },
  ],
} satisfies ODataGridGeneratorConfig<Distributor>;
