import { OCol, ORow, OSkeletonLine } from "@maestro/react";

const skeletonLines = [0, 1, 2];

export const ContractComplementsLoading = () => {
  return (
    <>
      {skeletonLines.map((id) => (
        <ORow key={id} className="mb-2">
          <OCol xs={6}>
            <OSkeletonLine className="w-100" />
          </OCol>
          <OCol xs={6}>
            <OSkeletonLine className="w-100" />
          </OCol>
        </ORow>
      ))}
    </>
  );
};
