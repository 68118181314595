import {
  AddPendencyButton,
  DocumentTemplateButton,
  EditPropertyButton,
  MoveFilesButton,
} from "./_compose";

interface ActionsAreaProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const ActionsArea = ({ property }: ActionsAreaProps) => {
  return (
    <div className="d-flex gap-2">
      {property.type.mask === "DOCUMENT" && (
        <>
          <DocumentTemplateButton property={property} />
          <MoveFilesButton property={property} />
        </>
      )}
      <EditPropertyButton property={property} />
      <AddPendencyButton property={property} />
    </div>
  );
};
