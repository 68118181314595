import styled from "styled-components";

export const TableStyle = styled.table`
  width: 100%;
  tr:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  td {
    padding-left: 0.5rem;
  }
`;
