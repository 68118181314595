import { Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface AddDiligenceStepForm {
  diligenceStepConfigId: number;
}

export const addDiligenceStepFormValidationSchema = yup.object<
  AddDiligenceStepForm,
  YupObjectSchema<AddDiligenceStepForm>
>({
  diligenceStepConfigId: yup.number().required("Campo obrigatório"),
});

export const addDiligenceStepFormDefaultValues = {
  diligenceStepConfigId: null,
} satisfies Partial<
  Nullable<AddDiligenceStepForm>
> as unknown as AddDiligenceStepForm;
