import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useRemoveDenyHook } from "./remove-deny-list.hook";

export const RemoveDenyListPage = () => {
  const { form, loading, removeDeny } = useRemoveDenyHook();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Remover cliente da lista de bloqueio" />}
    >
      <FormProvider {...form}>
        <FormTemplate
          actions={
            <LoadingButton loading={loading} type="info" onClick={removeDeny}>
              Remover
            </LoadingButton>
          }
        >
          <CardTemplate>
            {loading && <OLoader backdrop absolute />}
            <ORow gap={4}>
              <OCol sm={6}>
                <ORFieldInput
                  id="taxId"
                  name="taxId"
                  tag="text"
                  label="CNPJ/CPF"
                  labelSize="md"
                  disabled
                />
              </OCol>
              <OCol sm={6}>
                <ORFieldInput
                  id="name"
                  name="name"
                  tag="text"
                  label="Nome/Razão social"
                  labelSize="md"
                  disabled
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol sm={12}>
                <ORFieldInput
                  id="unblockReason"
                  name="unblockReason"
                  tag="text"
                  label="Motivo"
                  labelSize="lg"
                />
              </OCol>
            </ORow>
          </CardTemplate>
        </FormTemplate>
      </FormProvider>
    </FormTemplate>
  );
};
