import {
  OBadge,
  OCard,
  OCol,
  ODivider,
  OExpander,
  OExpanderContent,
  OExpanderHeader,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { Fragment } from "react";
import {
  minimumSigners,
  resolveRuleDescription,
} from "./contract-rule-signatory.utils";
import {
  ContractDetails,
  RuleStatusColor,
  RuleStatusTitle,
} from "./contract-rule-signatoy.types";

interface RulesSignatoryProps {
  contract: ContractDetails;
}

export const RulesSignatory = ({ contract }: RulesSignatoryProps) => {
  return (
    <OCard className="p-4">
      {contract.rules.map((rule, index) => (
        <Fragment
          key={`contract_rule_${rule.groups[0].signatories.toString()}_${
            rule.groups[0].name
          }`}
        >
          <OExpander>
            <OExpanderHeader>
              <ORow>
                <OCol justify="start">
                  <ORow>
                    <OTypography>Regra de aprovação {index + 1}</OTypography>
                    <OTypography type="default" className="mt-2">
                      {resolveRuleDescription(rule)}
                    </OTypography>
                  </ORow>
                </OCol>
                <OCol justify="end">
                  <OBadge type={RuleStatusColor[rule.status]}>
                    <OTypography>{RuleStatusTitle[rule.status]}</OTypography>
                  </OBadge>
                </OCol>
              </ORow>
            </OExpanderHeader>
            <OExpanderContent>
              {rule.groups.map((group, idx) => (
                <Fragment
                  key={`rule_group_${
                    group.name
                  }_${group.signatories.toString()}`}
                >
                  <ORow className="mt-3">
                    <OCol>
                      <OTypography>
                        {group.name
                          ? `Grupo ${idx + 1} -  ${group.name}`
                          : `Grupo ${idx + 1}`}
                      </OTypography>
                    </OCol>
                    <OCol justify="end" align="center">
                      <OTypography>{minimumSigners(group)}</OTypography>
                    </OCol>
                  </ORow>

                  {group.signatories.map((sig) => (
                    <ORow
                      className="mt-3"
                      key={`signatories_${
                        contract.signatories[sig]?.name
                      }_${sig.toString()}`}
                    >
                      <OCol>
                        <OTypography type="default">
                          {cases.title(contract.signatories[sig]?.name ?? "") ||
                            masks.cpf(contract.signatories[sig]?.cpf)}
                        </OTypography>
                      </OCol>
                      <OCol justify="end">
                        {contract.signatories[sig].signed ? (
                          <OIcon
                            category="orq"
                            icon="orq-status-success"
                            type="success"
                          />
                        ) : (
                          <OIcon
                            category="orq"
                            icon="orq-status-warning"
                            type="warning"
                          />
                        )}
                      </OCol>
                    </ORow>
                  ))}
                </Fragment>
              ))}
            </OExpanderContent>
          </OExpander>
          {index !== contract.rules.length - 1 && <ODivider className="my-4" />}
        </Fragment>
      ))}
    </OCard>
  );
};
