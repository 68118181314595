import {
  modalManager,
  OButton,
  OCard,
  OCardHeader,
  OCol,
  OTypography,
} from "@maestro/react";
import { PortfolioManagementCard } from "./portfolio-management-card.component";
import { PortfolioManagamentResponse } from "services/moana/models/responses/portfolio-management.response";
import { useState } from "react";
import { duplicatasRoles } from "pages/duplicatas/roles/duplicatas.roles";
import { OwnWalletModal } from "./own-wallet-modal.component";
import { ownWalletModal } from "./portfolio-mangament.utils";

interface OwnWalletManagementProps {
  portfolioManagement?: PortfolioManagamentResponse;
  documentNumber: string;
}

export const OwnWallet = ({
  portfolioManagement,
  documentNumber,
}: OwnWalletManagementProps) => {
  const [selectedNotAnticipatedRanges, setSelectedNotAnticipatedRanges] =
    useState<number[]>([]);

  const [selectedOwnRanges, setSelectedOwnRanges] = useState<number[]>([]);

  const [totalItems, setTotalItems] = useState<number>(0);

  return (
    <>
      <OwnWalletModal
        documentNumber={documentNumber}
        ranges={selectedOwnRanges}
        totalItems={totalItems}
      />
      <OCard>
        <OCardHeader divider>
          <OCol className="justify-content-between">
            <OTypography type="default">Carteira própria</OTypography>
            <OButton
              role={duplicatasRoles.product.walletDetailsPage.role}
              type="tertiary"
              outline
              disabled={selectedNotAnticipatedRanges.length <= 0}
              onClick={() => {
                setSelectedOwnRanges(selectedNotAnticipatedRanges);
                modalManager.show(ownWalletModal);
              }}
            >
              Detalhar
            </OButton>
          </OCol>
        </OCardHeader>
        <PortfolioManagementCard
          receivables={portfolioManagement?.notAnticipatedReceivables}
          selectedRanges={selectedNotAnticipatedRanges}
          setSelectedRanges={setSelectedNotAnticipatedRanges}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
        />
      </OCard>
    </>
  );
};
