import { OIcon, OLoader, OOption, OSelect } from "@maestro/react";
import { UnstyledButton } from "components/unstyled-button";
import { useTiers } from "../../tiers.context";

interface CedenteSelectorProps {
  selectedCedente: number | undefined;
  setSelectedCedente: (selected: number | undefined) => void;
}

export const CedenteSelector = ({
  selectedCedente,
  setSelectedCedente,
}: CedenteSelectorProps) => {
  const { getTierRelationships, cedenteOptions, hasError, loading } =
    useTiers();

  return (
    <div className="position-relative d-flex gap-1 align-items-center me-auto">
      <OSelect
        dataAction="tiers:select:filtro-cedentes"
        dataLabel="filtro-cedentes"
        disabled={loading || hasError || cedenteOptions.length === 0}
        value={selectedCedente}
        style={{
          width: "200px",
        }}
        onInput={(evt) =>
          setSelectedCedente(
            (evt.currentTarget as HTMLOSelectElement).value as number,
          )
        }
      >
        {cedenteOptions.map((option) => (
          <OOption key={option.id} value={option.id} size="sm">
            {option.name}
          </OOption>
        ))}
      </OSelect>
      {loading && <OLoader style={{ display: "inline" }} size="sm" />}
      {hasError && (
        <UnstyledButton
          title="Houve um erro na requisição. Clique para tentar refazê-la."
          onClick={() => getTierRelationships()}
        >
          <OIcon category="orq" icon="orq-status-error" type="danger" />
        </UnstyledButton>
      )}
      {selectedCedente !== undefined && (
        <UnstyledButton
          title="Limpar seleção"
          onClick={() => setSelectedCedente(undefined)}
        >
          <OIcon category="fal" icon="fa-times-circle" size="lg" type="dark" />
        </UnstyledButton>
      )}
    </div>
  );
};
