import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { OfferForm } from "../_compose/offer-form.component";
import { useEditOffer } from "./edit-offer.hook";

export const EditOfferPage = () => {
  const { id: workflowConfigId, offerId } = useParams<{
    id: string;
    offerId: string;
  }>();
  if (!workflowConfigId || !offerId) throw new Error("No id");

  const {
    editOffer,
    form,
    getOfferById,
    hasError,
    loadingEditOffer,
    loadingGetOfferById,
    onAddFile,
  } = useEditOffer();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar oferta" />}
      actions={
        <LoadingButton
          loading={loadingEditOffer}
          onClick={handleSubmit(editOffer)}
          dataAction="editar-oferta:botao:salvar"
          dataLabel="salvar"
          disabled={loadingGetOfferById}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasError}
        loading={loadingGetOfferById}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os dados referentes às configurações das linhas de crédito."
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton
              onClick={() => {
                getOfferById(offerId);
              }}
            />
          </ErrorComponent>
        }
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <OfferForm onAddFile={onAddFile} />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
