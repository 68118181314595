import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { SharkSettlementData } from "services/shark/models/responses";
import { format } from "utils/date";

type SharkPaymentItem = SharkSettlementData["payments"][number];

export const operationsPaymentsGrid = {
  datagrid: {
    noDataText: "Nenhum pagamento encontrado.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} parcelas)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "installment_number",
      caption: "Parcela",
      sortOrder: "desc",
      alignment: "center",
    },
    {
      dataField: "installment_maturity_business_date",
      caption: "Data de vencimento",
      alignment: "center",
      cellRender: ({ data }) =>
        format.date(data.installment_maturity_business_date),
    },
    {
      dataField: "payment_date",
      caption: "Data do pagamento",
      alignment: "center",
      cellRender: ({ data }) => format.date(data.payment_date),
    },
    {
      dataField: "paid_value",
      caption: "Valor total pago",
      alignment: "left",
      cellRender: ({ data }) => masks.currency(data.paid_value, "R$", "."),
    },
    {
      dataField: "principal_value",
      caption: "Valor amortizado",
      alignment: "left",
      cellRender: ({ data }) => masks.currency(data.principal_value, "R$", "."),
    },
    {
      caption: "Juros",
      alignment: "left",
      cellRender: ({ data }) =>
        masks.currency(
          data.fixed_interest_value + data.float_interest_value,
          "R$",
          ".",
        ),
    },
    {
      dataField: "payment_status_description",
      caption: "Status",
      alignment: "left",
    },
  ],
} satisfies ODataGridGeneratorConfig<SharkPaymentItem>;
