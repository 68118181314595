export const statusMap = {
  PENDENTE: {
    label: "Pendente",
    type: "warning",
  },
  PENDENTE_BIOMETRIA: {
    label: "Pendente biometria",
    type: "warning",
  },
  PENDENTE_ENVIO: {
    label: "Pendente envio",
    type: "warning",
  },
  PENDENTE_VALIDACAO_SEGURANCA: {
    label: "Pendente validação segurança",
    type: "info",
  },
  EM_ANALISE: {
    label: "Em análise",
    type: "info",
  },
  PENDENTE_ASSINATURA_DOCUMENTOS: {
    label: "Pendente assinatura documentos",
    type: "warning",
  },
  PENDENTE_AJUSTE: {
    label: "Pendente ajuste",
    type: "warning",
  },
  REPROVADO: {
    label: "Reprovado",
    type: "danger",
  },
  ATIVO: {
    label: "Ativo",
    type: "success",
  },
  INATIVO: {
    label: "Inativo",
    type: "danger",
  },
} as const;

export const biometryStatusMap = {
  PENDENTE: {
    label: "Pendente",
    type: "warning",
  },
  APROVADO: {
    label: "Aprovado",
    type: "success",
  },
} as const;

export const workflowTypeMap = {
  REVOGACAO: {
    label: "Revogação",
    type: "danger",
  },
  DELEGACAO: {
    label: "Delegação",
    type: "success",
  },
} as const;
