import {
  OCol,
  OField,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsCard } from "components/details-card";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { currencySymbol } from "utils/currency";
import { ViewAssetsDetailsFields } from "./view-assets-details.details";
import { useViewAssetsDetails } from "./view-assets-details.hook";

export const ViewAssetsDetails = () => {
  const {
    bankslip,
    form,
    installment,
    loading,
    nextBusDay,
    nominalAmountWatch,
    onSubmit,
  } = useViewAssetsDetails();

  return (
    <div className="d-flex flex-column gap-5">
      <DetailsTemplate
        pageTitle={<PageTitle title="Detalhes do boleto" />}
        actions={
          <LoadingButton dataAction="" dataLabel="" loading={loading}>
            Baixar boleto
          </LoadingButton>
        }
      >
        <DetailsCard
          loading={loading}
          value={bankslip}
          fields={ViewAssetsDetailsFields(
            bankslip,
            installment ?? "",
            nextBusDay ?? "",
          )}
        />
      </DetailsTemplate>
      <FormTemplate
        pageTitle={<PageTitle title="Edição do boleto" />}
        actions={
          <LoadingButton
            loading={loading}
            dataAction="editar_boleto_fidc:botao:editar"
            dataLabel="editar"
            onClick={() => onSubmit()}
          >
            Editar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  dataAction="editar_boleto_fidc:texto:valor_boleto"
                  dataLabel="valor_boleto"
                  id="nominalAmount"
                  name="nominalAmount"
                  tag="currency"
                  label="Valor do boleto (R$)"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="Data de vencimento" htmlFor="maturityDate">
                  <ORFieldInputDate
                    id="maturityDate"
                    name="maturityDate"
                    className="mt-2"
                  />
                </OField>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="Desconto aplicado" htmlFor="netValue">
                  <OTypography id="netValue" className="mt-2">
                    {masks.currency(
                      nominalAmountWatch - (bankslip?.nominalAmount ?? 0),
                      currencySymbol.BRL,
                      ".",
                    )}
                  </OTypography>
                </OField>
              </OCol>
            </ORow>
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
    </div>
  );
};
