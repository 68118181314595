export const collateralTypesOptions = [
  {
    value: "CAC",
    description: "CAC",
  },
  {
    value: "CDB",
    description: "CDB",
  },
  {
    value: "IMOVEL",
    description: "Imóvel",
  },
  {
    value: "PAINEIS_SOLARES",
    description: "Paineis solares",
  },
  {
    value: "PEAC",
    description: "PEAC",
  },
  {
    value: "RECEBIVEIS_CARTOES",
    description: "Recebíveis de cartões",
  },
  {
    value: "SACAS_GRAO",
    description: "Sacas de grãos",
  },
  {
    value: "SEGURO",
    description: "Seguro",
  },
];

export const fundingTypes = [
  {
    value: "BTG",
    description: "BTG",
  },
  {
    value: "BNDES",
    description: "BNDES",
  },
];
