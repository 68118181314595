import * as yup from "yup";
import { FormValuesType } from "./import-corrections.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  type: yup.string().required("Este campo é obrigatorio"),
});

export const formDefaultValues = {
  type: "Aditamento",
};
