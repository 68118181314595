export const statusMap: Record<string, string> = {
  APPROVED: "Aprovado",
  REJECTED: "Reprovado",
};

export const getValueFromMap = (value?: string) => {
  if (!value) return "";

  return statusMap[value] ?? value;
};
