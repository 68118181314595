import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";

export const useUpdateStepWithConfigModal = (
  selectedSteps: HubEnergy.DiligenceStepMonitor[],
) => {
  const { callService, loading, value, clear } = useServiceCall(
    service.hubEnergy.updateStepWithConfig,
  );

  const [overwrite, setOverwrite] = useState(false);
  const [notDryRun, setNotDryRun] = useState(false);

  const updateStepWithConfig = useCallback(async () => {
    const { success, error } = await callService({
      dryRun: !notDryRun,
      overwrite,
      diligenceStepIds: selectedSteps.map((s) => s.diligenceStepId),
    });

    if (success) {
      OToastManager.success("Steps atualizados com sucesso");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao atualizar os steps",
      );
    }
  }, [callService, notDryRun, overwrite, selectedSteps]);

  return {
    clear,
    loading,
    notDryRun,
    overwrite,
    setNotDryRun,
    setOverwrite,
    updateStepWithConfig,
    result: value?.response,
  };
};
