import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { PostAdminAccountopeningGetWorkflowsItem } from "services/onboarding-core-account/models";

export const useGridActions = (
  contract: PostAdminAccountopeningGetWorkflowsItem,
) => {
  const { callService: requesterResponse, loading: requesterResponseLoading } =
    useServiceCall(service.onboardingCoreAccount.requesterResponse);

  const reprocessWorkflow = useCallback(async () => {
    if (!contract.uuid) return OToastManager.danger("O workflow não possui ID");
    const { success } = await requesterResponse({
      retry: true,
      workflowUuid: contract.uuid,
    });
    if (success)
      OToastManager.success(
        "O processo solicitado foi enviado para retentativa, aguarde enquanto processamento dos dados é feito",
      );
    else OToastManager.danger("Não foi possível executar o reprocessamento");
  }, [contract.uuid, requesterResponse]);

  const completeWorkflow = useCallback(async () => {
    if (!contract.uuid) return OToastManager.danger("O workflow não possui ID");
    const { success } = await requesterResponse({
      retry: false,
      workflowUuid: contract.uuid,
    });
    if (success)
      OToastManager.success(
        "O processo solicitado foi enviado para geração do termo de aceite, aguarde enquanto processamento dos dados é feito",
      );
    else OToastManager.danger("Não foi possível executar a geração do termo");
  }, [contract.uuid, requesterResponse]);

  return {
    reprocessWorkflow,
    completeWorkflow,
    loading: requesterResponseLoading,
  };
};
