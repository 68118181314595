import { OTextarea, OTypography } from "@maestro/react";
import styled from "styled-components";

export const OTextareaStyled = styled(OTextarea)`
  > textarea {
    margin-top: 12px;
    border: 1px solid #d1d1d1;
    font-size: 0.9rem;
    font-weight: 100;
    padding-top: 10px;
    padding-left: 10px;
  }
`;

export const OTypographyStyled = styled(OTypography)`
  > p {
    padding-left: 10px;
    padding: 8px;
    border: 1px solid #d1d1d1;
    font-size: 0.9rem;
  }
`;
