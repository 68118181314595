import { OCol, ORFieldInputProgress } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";

export const DescriptionInput = () => {
  const { watch } = useFormContext<UpsertCollectionAgreementFormFields>();
  const descriptionWatched = watch("settings.description");

  return (
    <OCol>
      <ORFieldInputProgress
        id="settings.description"
        name="settings.description"
        valueLength={(descriptionWatched ?? "").length}
        placeholder="Adicione o texto"
        label="Instruções da cobrança (opcional)"
        description="Esta mensagem irá aparecer no boleto gerado"
        maxlength={300}
        tag="text"
      />
    </OCol>
  );
};
