import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { GetAdminReceivableStateMachineByIdResponse } from "services/quickfin/models";
import { percent4DigitsMask } from "utils/mask";

export const tradeDetailsGenerator = (
  trade: GetAdminReceivableStateMachineByIdResponse["receivable"]["trade"],
) => {
  if (!trade) return {} as DetailsFields;

  return {
    trade: {
      items: [
        {
          label: "Id",
          value: trade?.id ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Preço",
          value: trade?.price ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa mensal",
          value: percent4DigitsMask(trade?.taxaMensal) ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa total",
          value: percent4DigitsMask(trade?.taxaTotal) ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Criado em",
          value: dayjs(trade?.createdAt).format("DD/MM/YYYY HH:mm") ?? "",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TCO",
          value: trade?.charge ?? 0,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
