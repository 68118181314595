import { useMemo } from "react";
import { modalManager, OButton, OIcon, OTooltip } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { IconButton } from "components/icon-button";
import { ODataGridGenerator } from "components/data-grid";
import { grid } from "./wallets.grid";
import { useWallets } from "./wallets.hook";
import { CustomPagination } from "../../../../recebiveis/pages/produto/titulos/nota-a-nota/_compose/custom-pagination";
import { WalletsFilter } from "./_compose/wallets-filter/wallets-filter.component";
import { walletsFilterModalId } from "./_compose/wallets-filter/wallets-filter.utils";
import { OwnerInState } from "./_compose/wallet-state/owner-in-state/owner-in-state.component";
import { OwnerTotals } from "./_compose/wallet-state/owners-total/owner-totals.component";
import { TooltipContent } from "components/tooltip-icon";

export const WalletsPage = () => {
  const gridRef = useGridRef();
  const {
    dataSource,
    totalItems,
    page,
    setPage,
    rows,
    setRows,
    form,
    onSubmit,
    resetFilter,
    setSortQuery,
    exportData,
    // exportDataEmail,
    ownersInState,
    ownersTotals,
  } = useWallets();

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        grid={grid(setSortQuery, rows)}
        gridRef={gridRef}
        dataSource={dataSource}
      />
    ),
    [gridRef, dataSource, setSortQuery, rows],
  );

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Carteiras"
          description="Visualize todas as carteiras disponíveis e suas informações principais"
        />
      }
    >
      <OwnerInState ownersInState={ownersInState} totalOwners={totalItems} />
      <OwnerTotals ownersTotals={ownersTotals} />
      <GridTemplate
        gridRef={gridRef}
        actions={
          <>
            <IconButton
              icon={{ category: "orq", icon: "orq-tools-search" }}
              dataAction="duplicatas:botao:mostrar_form"
              dataLabel="mostrar_form"
              onClick={() => modalManager.show(walletsFilterModalId)}
            >
              Abrir busca
            </IconButton>

            <OTooltip>
              <TooltipContent>Exportar planilha</TooltipContent>
              <OButton
                onClick={() => exportData()}
                type="default"
                outline
              >
                <OIcon category="fa" icon="fa-file-spreadsheet" size="lg"/>
              </OButton>
            </OTooltip>

            {/* <OTooltip>
              <TooltipContent>Enviar carteira completa por e-mail</TooltipContent>
              <OButton
                onClick={() => exportDataEmail()}
                type="default"
                outline
              >
                <OIcon category="orq" icon="orq-email" size="lg"/>
              </OButton>
            </OTooltip> */}
          </>
        }
        showRefreshButton
      >
        {memoizedGrid}
        <CustomPagination
          totalCount={totalItems}
          currentPage={page}
          setCurrentPage={setPage}
          pagination={[10, 20, 30, 50, 100]}
          selectedPaginationSize={rows}
          setSelectedPaginationSize={setRows}
        />
      </GridTemplate>
      <WalletsFilter
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
      />
    </DetailsTemplate>
  );
};
