import { OIcon, OLink, OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { UploadFile } from "../_compose";
import { useAddRateCurve } from "./add.hook";

export const AddRateCurve = () => {
  const { curvaPre, loading, setCurvaPre, submit } = useAddRateCurve();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Importar Curva"
          description="Curva de taxas para o período"
        />
      }
      actions={
        <OLink href="/files/modelo_importacao_curva_taxas_admin.xlsx">
          <OIcon category="fas" icon="fa-external-link" className="me-2" />
          Baixar modelo
        </OLink>
      }
    >
      <FormTemplate
        actions={
          <LoadingButton
            loading={loading}
            dataAction="curva_taxas:botao:importar"
            dataLabel="importar"
            onClick={submit}
          >
            Importar
          </LoadingButton>
        }
      >
        <CardTemplate>
          {loading && <OLoader absolute />}
          <UploadFile curvaPre={curvaPre} setCurvaPre={setCurvaPre} />
        </CardTemplate>
      </FormTemplate>
    </DetailsTemplate>
  );
};
