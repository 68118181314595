import { MenuTabs } from "../../tab-menu.types";

/** Empirically determined estimation for tab width
 * based on text content number of characters */
export const estimateTabWidth = (text: string) => text.length * 9 + 32;

export const dropdownTabWidth = 55;

export const calculateVisibleTabs = (
  screenWidth: number,
  tabWidths: number[],
  showTabDropdown: boolean,
) => {
  let totalWidth = showTabDropdown ? dropdownTabWidth : 0;

  for (let i = 0; i < tabWidths.length; i += 1) {
    totalWidth += tabWidths[i];

    if (totalWidth >= screenWidth) {
      return i;
    }
  }

  return tabWidths.length;
};

export const generateTabsKey = (tabs: MenuTabs[keyof MenuTabs]) =>
  tabs.map((tab) => tab.path).join();
