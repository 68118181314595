import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { ContractFileConfigurationForm } from "../_compose/contract-file-configuration-form.component";
import {
  ContractFileConfigurationFormValues,
  contractFileConfigurationFormValidationSchema,
} from "../_compose/contract-file-configuration.form";

export const RegisterContractFileConfigurationPage = () => {
  const form = useForm<ContractFileConfigurationFormValues>({
    resolver: yupResolver(contractFileConfigurationFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerContractFileConfiguration, loading } =
    useServiceCall(service.onboarding.registerContractFileConfiguration);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerContractFileConfiguration([values]);

        if (!success) return OToastManager.danger("Erro ao criar configuração");

        return OToastManager.success("Configuração criada com sucesso");
      }),
    [handleSubmit, registerContractFileConfiguration],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Adicionar configuração de arquivo do contrato" />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ContractFileConfigurationForm />
      </FormProvider>
    </FormTemplate>
  );
};
