import { OButton, OCol, ODivider, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { ActionTypeSelector } from "../../../selects";
import { actionEditorFormFields } from "./action-editor.form";
import { useActionEditor } from "./action-editor.hook";
import {
  actionEditorFields,
  actionEditorPayloadFields,
} from "./action-editor.utils";

interface ActionEditorProps {
  rule: HubEnergy.DiligenceEventRuleResponse;
  action: HubEnergy.DiligenceEventActionSimpleResponse;
  setMode: React.Dispatch<React.SetStateAction<"display" | "editing">>;
  setActions: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventActionSimpleResponse[]>
  >;
}

export const ActionEditor = ({
  rule,
  action,
  setMode,
  setActions,
}: ActionEditorProps) => {
  const { actionTypeWatcher, form, submit, submitLoading } = useActionEditor(
    rule.id,
    action,
    setActions,
    setMode,
  );

  return (
    <DetailsTemplate
      actions={
        <>
          {action.ruleId !== 0 && (
            <OButton onClick={() => setMode("display")} type="dark" outline>
              Cancelar
            </OButton>
          )}
          {action.ruleId === 0 && (
            <OButton
              onClick={() =>
                setActions((old) => old.filter(({ id }) => id !== action.id))
              }
              type="dark"
              outline
            >
              Cancelar
            </OButton>
          )}
          <LoadingButton loading={submitLoading} onClick={submit}>
            Salvar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ActionTypeSelector
                {...actionEditorFormFields.actionType}
                dropdownPosition="top"
              />
            </OCol>
          </ORow>
          {actionTypeWatcher && (
            <>
              <ORow>
                {actionEditorFields.map((field) => {
                  const shouldDisplay =
                    field.availableActionTypes.includes(actionTypeWatcher) &&
                    (!field.availableEventTypes ||
                      field.availableEventTypes.includes(rule.eventType));
                  return (
                    <OCol
                      key={field.key}
                      xs={12}
                      md={6}
                      style={{
                        display: shouldDisplay ? undefined : "none",
                      }}
                    >
                      <field.Component />
                    </OCol>
                  );
                })}
              </ORow>
              <ODivider className="mt-2 mb-4" type="dark" />
              <ORow>
                {actionEditorPayloadFields.map((field) => {
                  const shouldDisplay =
                    field.availableActionTypes.includes(actionTypeWatcher);
                  return (
                    <OCol
                      key={field.key}
                      xs={12}
                      md={6}
                      style={{
                        display: shouldDisplay ? undefined : "none",
                      }}
                    >
                      <field.Component />
                    </OCol>
                  );
                })}
              </ORow>
            </>
          )}
        </FormProvider>
      </CardTemplate>
    </DetailsTemplate>
  );
};
