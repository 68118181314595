import { OToastManager } from "@maestro/core";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  modalManager,
  OCard,
  OCardBody,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  OButton,
  OTextarea,
  ORFieldUploadInput,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "contexts/user";
import { service } from "services";
import { LoadingButton } from "components/loading-button";
import { ConfirmationChannelValidationModal } from "../confirmation-modal/confirmation-channel-validation-modal.component";

interface UploadFileProcessProps {
  orderId: string;
}
interface UploadConciliationFilesFormValues {
  file: File[] | null;
  descriptionUpload: string | null;
}

export const UploadFileProcess = ({ orderId }: UploadFileProcessProps) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [uploadFilesData, setUploadFilesData] = useState<Array<File>>([]);

  const form = useForm();
  const { handleSubmit, reset, register, setValue } = form;

  const handleCloseModal = useCallback(() => {
    modalManager.hide("upload-file");
    modalManager.hide("channel-validation-modal");
    setUploadFilesData([]);
    reset({
      descriptionUpload: null,
      file: null,
    });
    setLoading(false);
  }, [reset]);

  const addFile = useCallback(
    async (data: FieldValues) => {
      setLoading(true);

      const { file, descriptionUpload } =
        data as UploadConciliationFilesFormValues;

      if (!file || file.length === 0) {
        setLoading(false);
        return OToastManager.danger(
          "Favor selecionar um arquivo '.csv' para upload.",
        );
      }

      const singleFile = file[0];

      if (descriptionUpload === null || descriptionUpload === "") {
        setLoading(false);
        return OToastManager.danger("Favor informar uma descrição.");
      }

      try {
        const fileBlob = new Blob([singleFile], {
          type: "text/csv;charset=utf-8;",
        });

        const dataForm = new FormData();
        dataForm.append("author", user.name ?? "");
        dataForm.append("description", descriptionUpload ?? "");
        dataForm.append("file", fileBlob, singleFile?.name);

        await service.fidc.postConciliationFileById(dataForm, orderId);
        OToastManager.success("Upload feito com sucesso!");
      } catch (err: any) {
        if (err.response) {
          const statusCode = err.response.status;
          const errorMessage = err.response.data || "Erro ao enviar arquivo.";

          if (Math.floor(statusCode / 100) === 5) {
            OToastManager.danger("Erro no servidor ao enviar o arquivo.");
          } else if (Math.floor(statusCode / 100) === 4) {
            OToastManager.danger(errorMessage);
          } else {
            OToastManager.danger("Erro ao enviar arquivo.");
          }
        } else if (err.request) {
          OToastManager.danger(
            "Nenhuma resposta do servidor. Verifique sua conexão.",
          );
        } else {
          OToastManager.danger("Erro ao configurar o envio do arquivo.");
        }
      } finally {
        setLoading(false);
        handleCloseModal();
      }
    },
    [handleCloseModal, orderId, user.name],
  );

  const onAddFile = useCallback(async (file: File) => {
    setUploadFilesData((files) => [...files, file]);
  }, []);

  const onRemoveFile = useCallback(async (file: File) => {
    setUploadFilesData((files) =>
      files.filter((uploadFile) => uploadFile.name !== file.name),
    );
  }, []);

  useEffect(
    () => setValue("file", uploadFilesData),
    [uploadFilesData, setValue],
  );

  const approveChannelValidation = useCallback(async () => {
    try {
      setLoading(true);
      await service.fidc.putConciliationChannelValidation(orderId);

      OToastManager.success("Aprovação enviada ao CROS com sucesso.");
    } catch (err: any) {
      if (err.response) {
        const statusCode = err.response.status;
        const errorMessage =
          err.response.data || "Erro ao enviar aprovação ao CROS!";

        if (statusCode === 409) {
          OToastManager.danger(errorMessage);
        } else OToastManager.danger("Erro ao enviar aprovação ao CROS!");
      }
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  }, [handleCloseModal, orderId]);

  return (
    <>
      <OModal
        id="upload-file"
        position="center"
        backdrop
        onModalClose={handleCloseModal}
      >
        <OModalHeader closeButton divider>
          <OTypography tag="h1">Validação de liquidação de crédito</OTypography>
        </OModalHeader>
        <OModalBody>
          <FormProvider {...form}>
            <form>
              <div className="d-flex gap-3">
                <OCard>
                  <OCardBody>
                    <div className="d-flex flex-column align-items-center gap-4">
                      <OTypography type="default">
                        Considerar todas as aprovações realizadas pelo canal ao
                        CROS
                      </OTypography>
                      <LoadingButton
                        dataAction="apply_channel_validation:botao:apply"
                        dataLabel="apply"
                        type="info"
                        loading={loading}
                        onClick={() =>
                          modalManager.show("channel-validation-modal")
                        }
                      >
                        Enviar ao CROS
                      </LoadingButton>
                    </div>
                  </OCardBody>
                </OCard>
                <OCard>
                  <OCardBody>
                    <div>
                      <OTextarea
                        id="descriptionUpload"
                        placeholder="Informe aqui uma descrição: *"
                        {...register("descriptionUpload")}
                      />
                      <OTypography size="xxl" weight="100" />
                      <ORFieldUploadInput
                        dataAction="file:file_conciliation"
                        dataLabel="file_conciliation"
                        inputLabel="Clique ou arraste o arquivo para esta área."
                        tip="Adicionar um arquivo no formato .csv*"
                        description="Obrigatorio adicionar 1 arquivo."
                        accept=".csv"
                        id="file"
                        name="file"
                        value={uploadFilesData}
                        handleRemove={(file) => onRemoveFile(file)}
                        handleAddFile={(event) => {
                          if (uploadFilesData.length === 1) {
                            OToastManager.warning(
                              "Seu arquivo já foi adicionado!",
                            );
                            return;
                          }

                          onAddFile(event.detail);
                        }}
                      />
                      <div className="d-flex justify-content-end gap-4">
                        <LoadingButton
                          dataAction="enviar_agrupar:botao:enviar"
                          dataLabel="enviar"
                          loading={loading}
                          onClick={handleSubmit(addFile)}
                        >
                          Enviar para validação
                        </LoadingButton>
                      </div>
                    </div>
                  </OCardBody>
                </OCard>
              </div>
            </form>
          </FormProvider>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-3">
            <OButton
              dataAction="cancelar_agrupar:botao:cancelar"
              dataLabel="cancelar"
              type="dark"
              outline
              onClick={handleCloseModal}
            >
              Cancelar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <ConfirmationChannelValidationModal
        id="channel-validation-modal"
        isConfirmationModalProcessing={loading}
        onConfirm={async () => {
          approveChannelValidation().then(() => modalManager.hide(orderId));
        }}
      />
    </>
  );
};
