import * as yup from "yup";
import { GlobalDeflatorForm } from "./global-deflator.types";

export const globalDeflatorValidationSchema: yup.ObjectSchema<GlobalDeflatorForm> =
  yup.object({
    deflator: yup
      .number()
      .typeError("O deflator deve ser um número")
      .required("É necessário preencher o deflator")
      .min(0, "O deflator deve ser maior que 0")
      .max(1, "O deflator deve ser menor que 1"),
  });
