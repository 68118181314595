import { modalManager, OToastManager } from "@maestro/core";
import dayjs from "dayjs";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";
import {
  PropertyValueForm,
  propertyValueFormDefaultValues,
} from "./edit-property-value-modal.form";
import { editPropertyValueModalId } from "./edit-property-value-modal.utils";

export const useEditPropertyValueModal = (
  property: HubEnergy.DiligenceStepPropertySimpleResponse,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.patchDiligencePropertyValue,
  );

  const modalId = editPropertyValueModalId(property.id);

  const form = useForm<PropertyValueForm>({
    defaultValues: propertyValueFormDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!property) return OToastManager.danger("Nenhuma propriedade");

        const { success, error } = await callService({
          diligenceStepPropertyId: property.id,
          value:
            property.type.mask === "DATE"
              ? dayjs(values.value).toISOString()
              : values.value,
          adequacyOptionId: values.adequacyOptionId,
        });

        if (success) {
          OToastManager.success("Propriedade atualizada com sucesso");
          reload();
          modalManager.hide(modalId);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Erro ao atualizar a propriedade",
          );
        }
      }),
    [callService, handleSubmit, modalId, property, reload],
  );

  useEffect(() => {
    if (property)
      reset({
        adequacyOptionId: property.adequacyOption?.id ?? null,
        value: property.value,
        // helper
        files: property.document?.files ?? null,
      });
  }, [property, reset]);

  return { form, submit, loading, modalId };
};
