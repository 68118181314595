import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { exchangeOrderEntriesGrid } from "../../../../../components/orders/order-entries/order-entries.grid";
import { exchangeOrderDetailsGenerator } from "../../../../../components/orders/order.details";
import { CambioOrdensByIdRouteParams } from "../../../../../routes/cambio.route-params";

export const ExchangeProductOrderDetailsPage = () => {
  const { id } = useParams<CambioOrdensByIdRouteParams>();

  if (!id) throw new Error("No id");

  const { mapSymbol } = useCurrencies();

  const {
    value: orderDetails,
    callService: getOrderDetails,
    hasError: hasErrorOrderDetails,
    loading: loadingOrderDetails,
  } = useServiceCall(service.hubFx.getOrderDetails);

  useEffect(() => {
    getOrderDetails(id);
  }, [getOrderDetails, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da Ordem" />}>
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loadingOrderDetails}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar detalhes da ordem."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton onClick={() => getOrderDetails(id)} />
            </ErrorComponent>
          }
          hasError={hasErrorOrderDetails}
          value={orderDetails}
          fields={exchangeOrderDetailsGenerator}
        />
        {!!orderDetails?.orderEntries?.length && (
          <DetailsCard
            value={orderDetails.orderEntries}
            fields={(entries) => ({
              orderEntries: {
                title: "Entradas da Ordem",
                items: [
                  {
                    value: (
                      <ODataGridGenerator
                        grid={exchangeOrderEntriesGrid(mapSymbol)}
                        dataSource={entries}
                      />
                    ),
                  },
                ],
              },
            })}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
