import { BankIconSVG } from "../financial-institution-icon.types";

export const BankOtherSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#D6D9DF" />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M35.26 71.555V48.222m41.48 0v23.333M45.63 48.222v23.333M56 48.222v23.333m10.37-23.333v23.333M56 41.158a.324.324 0 10.327.324.328.328 0 00-.327-.324"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M80.63 48.222H31.37V41.38L56 32.666l24.63 8.714v6.842zm-1.296 23.333H32.666l-2.593 2.592v5.185h51.852v-5.185l-2.593-2.592z"
        clipRule="evenodd"
      />
    </svg>
  );
};
