import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";

interface OverdraftErrorComponentProps {
  tryAgainCallback: () => void;
}

export const OverdraftErrorComponent = ({
  tryAgainCallback,
}: OverdraftErrorComponentProps) => (
  <OCard>
    <OCardBody>
      <ErrorComponent
        messageTitle="Erro ao buscar o limite"
        messageParagraph="Clique no botão para tentar novamente."
      >
        <TryAgainButton onClick={() => tryAgainCallback()} />
      </ErrorComponent>
    </OCardBody>
  </OCard>
);
