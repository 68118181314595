/* eslint-disable no-param-reassign */
import type { LoadOptions } from "devextreme/data";
import { Offer, OfferStatus } from "./hired-offers.types";

/** @deprecated */
type FilterItem = string | Array<FilterItem>;

/** @deprecated */
const offerStatusList = [
  OfferStatus.Created,
  OfferStatus.Expired,
  OfferStatus.Hired,
  OfferStatus.Canceled,
  OfferStatus.PendingGuarantorsOnboard,
  OfferStatus.PendingSignature,
];

/** @deprecated */
interface ParsedFilter {
  status?: string;
  name?: string;
  document?: string;
}

/** @deprecated */
const parseDXFilter = (filter: FilterItem, parsed: ParsedFilter = {}) => {
  if (typeof filter === "string" || !filter) return parsed;

  if (filter[0] === "!") {
    const { status: invertedStatus } = parseDXFilter(filter[1]);
    parsed.status = offerStatusList
      .filter((offerStatus) => !invertedStatus?.match(offerStatus))
      .join(",");
    return parsed;
  }

  if (filter[0] === "company.name") {
    parsed.name = filter[2] as string;
    return parsed;
  }

  if (filter[0] === "company.document") {
    parsed.document = filter[2] as string;
    return parsed;
  }

  if (filter[0] === "status") {
    parsed.status = [parsed.status, filter[1] as string]
      .filter(Boolean)
      .join(",");
    return parsed;
  }

  filter.forEach((f) => {
    parsed = parseDXFilter(f, parsed);
  });

  return parsed;
};

/** @deprecated */
export const offerStatusFilters = [
  { text: "Criado", value: ["status", OfferStatus.Created] },
  { text: "Contratado", value: ["status", OfferStatus.Hired] },
  { text: "Expirado", value: ["status", OfferStatus.Expired] },
  { text: "Cancelado", value: ["status", OfferStatus.Canceled] },
  {
    text: "Assinatura pendente",
    value: ["status", OfferStatus.PendingSignature],
  },
  {
    text: "Pendente devedores solidários",
    value: ["status", OfferStatus.PendingGuarantorsOnboard],
  },
];

/** @deprecated */
export const remapFilter = (loadOptions: LoadOptions<Offer>) => {
  const itemsPerPage = loadOptions.take || 10;
  const page = (loadOptions.skip || 0) / (loadOptions.take || 1) + 1;

  const parsedFilter = Object.fromEntries(
    Object.entries(parseDXFilter(loadOptions.filter)).filter(
      ([_key, value]) => !!value,
    ),
  );

  const filter = {
    page,
    itemsPerPage,
    ...parsedFilter,
  };

  return filter;
};
