import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { DocumentUpload } from "../../../../../../../../../../../../../components/document-upload";
import { MaskFieldsMapProps } from "../mask-fields-map.types";
import { buildDataSource, energyBillsGrid } from "./bills-fiels.grid";

export const BillsField = ({ property }: MaskFieldsMapProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(() => buildDataSource(property), [property]);

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate
        actions={
          <OButton onClick={() => gridRef.current?.instance.addRow()}>
            Adicionar
          </OButton>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={energyBillsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <DocumentUpload
        id={`bills-document-${property.id}`}
        name="files"
        documentId={property.document.id}
      />
    </div>
  );
};
