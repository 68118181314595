import { ODataGridGenerator } from "components/data-grid";
import { DataGrid } from "devextreme-react";
import { useMemo, useRef } from "react";
import { grid } from "./document-card.grid";

interface DocumentCardProps {
  documents: HubOnboardingRelationships.Admin.DocumentsItem[];
  refetch: () => Promise<void>;
  canEdit: boolean;
}

export const DocumentCard = ({
  documents,
  refetch,
  canEdit,
}: DocumentCardProps) => {
  const gridComponent = useMemo(() => {
    return grid({ refetch, canEdit });
  }, [refetch, canEdit]);

  const templatesGridRef = useRef<DataGrid>(null);

  return (
    <ODataGridGenerator
      gridRef={templatesGridRef}
      grid={gridComponent}
      dataSource={documents}
    />
  );
};
