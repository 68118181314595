import { OCard } from "@maestro/react";
import styled from "styled-components";
import { ColorBoxProps } from "./configurable-status-form.types";

export const ColorBox = styled(OCard)<ColorBoxProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: ${(props) => props.isActive && "2px solid var(--theme-dark)"};
  cursor: pointer;
`;
