import { ORow } from "@maestro/react";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  CardModality,
  ProposalStatus,
} from "services/bankinghub/models/types/cards";
import { OfferedCard } from "../../../../card-hiring.types";
import { CardHiringForm } from "../card-hiring-form.types";
import { AccountNumberField } from "./account-number-field/account-number-field.component";
import { AddressIdField } from "./address-id-field/address-id-field.component";
import { HiredAmountField } from "./hired-amount-field/hired-amount-field.component";
import { IssueDateIdField } from "./issue-date-id-field/issue-date-id-field.component";
import { ManageAddressButtons } from "./manage-address-buttons/manage-address-buttons.component";
import { OwnerDocumentField } from "./owner-document-field/owner-document-field.component";
import { PhoneField } from "./phone-field/phone-field.component";
import { PrintedNameField } from "./printed-name-field/printed-name-field.component";
import { ProgramIdField } from "./program-id-field/program-id-field.component";
import { useCardHiringContext } from "../../../../card-hiring.context";

interface FormFieldsProps {
  offeredCards: OfferedCard[];
  ownerAddresses?: BankingHubCards.Address[];
  loadingOwnerAddresses: boolean;
  statementAccounts?: BankingHub.Account[];
  issueDates?: BankingHubCards.IssueDate[];
}

export const FormFields = ({
  offeredCards,
  ownerAddresses,
  loadingOwnerAddresses,
  statementAccounts,
  issueDates,
}: FormFieldsProps) => {
  const { creditCardOwners, debitCardOwners } = useCardHiringContext();

  const { setValue, watch, resetField } = useFormContext();

  const proposalStatusWatcher = watch("proposalStatus");

  const modalityWatcher = watch("modality");

  const [isCredit, isDebit, isMultiple] = useMemo(() => {
    return [
      modalityWatcher === CardModality.Credit,
      modalityWatcher === CardModality.Debit,
      modalityWatcher === CardModality.Multiple,
    ];
  }, [modalityWatcher]);

  const cardOwners = useMemo(() => {
    if (isCredit) return creditCardOwners;
    if (isDebit || isMultiple) return debitCardOwners;
  }, [isCredit, isDebit, isMultiple, creditCardOwners, debitCardOwners]);

  useEffect(() => {
    const fieldToReset: (keyof CardHiringForm)[] = [
      "ownerDocument",
      "printedName",
      "addressId",
    ];

    fieldToReset.forEach((field) => resetField(field));

    setValue("ownerId", "");
  }, [modalityWatcher, resetField, setValue]);

  const renderDebitFields = useMemo(() => {
    const isDebitOrMultiple = isDebit || isMultiple;
    return isDebitOrMultiple;
  }, [isDebit, isMultiple]);

  const renderCreditAvailableProposalFields = useMemo(() => {
    const isAvailable = proposalStatusWatcher === ProposalStatus.Available;

    const isCreditOrMultiple = isCredit || isMultiple;

    return isAvailable && isCreditOrMultiple;
  }, [proposalStatusWatcher, isCredit, isMultiple]);

  return (
    <>
      <ORow className="mb-3">
        <ProgramIdField data={offeredCards} />
        <OwnerDocumentField data={cardOwners} />
      </ORow>

      <ORow className="mb-3">
        <PhoneField />
        <PrintedNameField />
      </ORow>

      <ORow className="mb-3">
        <AddressIdField data={ownerAddresses} loading={loadingOwnerAddresses} />
        <ManageAddressButtons />
      </ORow>

      {renderDebitFields && (
        <ORow className="mb-3">
          <AccountNumberField data={statementAccounts} />
        </ORow>
      )}

      {renderCreditAvailableProposalFields && (
        <ORow className="mb-3">
          <IssueDateIdField data={issueDates} />
          <HiredAmountField />
        </ORow>
      )}
    </>
  );
};
