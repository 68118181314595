import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  DenialReasonForm,
  denialReasonFields,
} from "./denial-reason-modal.form";

export const denialReasonModalId = "denial-reason-modal";

interface DenialReasonModalProps {
  form: UseFormReturn<DenialReasonForm>;
  submit: () => void;
}

export const DenialReasonModal = ({ form, submit }: DenialReasonModalProps) => {
  return (
    <OModal
      id={denialReasonModalId}
      position="center"
      className="position-absolute"
    >
      <OModalHeader closeButton>
        <OTypography size="lg">Justificativa da reprovação</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORFieldInput {...denialReasonFields.reproveStatus} />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_agrupar:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(denialReasonModalId)}
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_agrupar:botao:confirmar"
            dataLabel="confirmar"
            onClick={() => {
              submit();
              modalManager.hide(denialReasonModalId);
            }}
          >
            Confirmar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
