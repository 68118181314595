import { env } from "utils/environments";

export const gateholderEndpoints = {
  fraudSys: {
    grid: `${env.API_GATEHOLDER}/api/fraud_sys/grid`,
    analysisDetails: {
      byId: (id: string) =>
        `${env.API_GATEHOLDER}/api/fraud_sys/analysis_details/${id}`,
    },
    manualAnalysis: {
      byId: (id: string) =>
        `${env.API_GATEHOLDER}/api/fraud_sys/manual_analysis/${id}`,
    },
  },
  allowList: {
    noBiometry: {
      endpoint: `${env.API_GATEHOLDER}/api/allow_list/no_biometry`,
      byTaxid: (taxId: string) =>
        `${env.API_GATEHOLDER}/api/allow_list/no_biometry/${taxId}`,
    },
    create: `${env.API_GATEHOLDER}/api/allow_list/create`,
  },
  files: {
    download: {
      byUuid: (uuid: string) =>
        `${env.API_GATEHOLDER}/api/files/download/${uuid}`,
    },
  },
};
