import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OConfirmationModal } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

const modalId = "sync-less-accounts-modal";

export const SyncLessAccounts = () => {
  const { customer } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.onboarding.putSyncLessAccounts,
  );

  const submit = useCallback(async () => {
    const { success } = await callService({
      requestTaxId: customer.identification,
    });

    if (success) {
      OToastManager.info("Contas sincronizadas com sucesso.");
    } else {
      OToastManager.warning("Erro ao sincronizar contas.");
    }
  }, [callService, customer.identification]);

  return (
    <>
      <OButton
        dataAction="ferramentas:botao:reabrir"
        dataLabel="reabrir"
        onClick={() => modalManager.show(modalId)}
      >
        Sincronizar
      </OButton>

      <OConfirmationModal
        modalId={modalId}
        size="sm"
        title="Sincronizar contas do cliente com as conta registradas no LESS?"
        closeLabel="Cancelar"
        confirmLabel="Confirmar"
        onConfirm={submit}
        loading={loading}
      />
    </>
  );
};
