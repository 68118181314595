import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  RoleAssignmentsForm,
  useRoleAssignmentsSubmission,
} from "../../../components/role-assignments";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";
import { userRoleAssignmentsValidationSchema } from "./user-role-assignments.schema";

export const useUserRoleAssignments = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");
  const [editingPhoneValue, setEditingPhoneValue] = useState("");

  const {
    customer: { identification: customerIdentification },
  } = useCustomer();

  const {
    callService: getRoleAssignments,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: initialValues,
  } = useServiceCall(
    service.onboardingRelationships.getRoleAssignmentsWorkflow,
  );

  const form = useForm<RoleAssignmentsForm>({
    resolver: yupResolver(userRoleAssignmentsValidationSchema),
  });

  const { reset } = form;

  const {
    ceilingVigencyDate,
    checkProfilesLoading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
    submitLoading,
  } = useRoleAssignmentsSubmission(form, true);

  const getInitialValues = useCallback(async () => {
    const { success, response } = await getRoleAssignments({
      taxId: identification,
      relatedTaxId: customerIdentification,
    });

    if (success) {
      setEditingPhoneValue(response.data.user.phoneNumber);
      reset({
        taxId: response.data.user.taxId,
        name: response.data.user.name,
        email: response.data.user.email,
        phoneNumber: response.data.user.phoneNumber,
        roles: response.data.profiles?.map(({ uuid }) => uuid),
      });
    }
  }, [customerIdentification, getRoleAssignments, identification, reset]);

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  return {
    ceilingVigencyDate,
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getInitialValues,
    initialValues,
    loading: checkProfilesLoading || submitLoading || fetchLoading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
    editingPhoneValue,
  };
};
