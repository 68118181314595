import styled from "styled-components";

export const RefusalTransacionModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  font-size: 14px;
  text-align: justify;
  white-space: pre-wrap;

  label:first-child {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;
