import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { env } from "../environments/env";
import { logger } from "../logger";
import { isDatadogEnabled } from "./enabled";
import { isProd } from "utils/environments";

const reAxiosHeaders = /"headers":\s?(\{[^}]*?\},?\n?)/gm;
const ignoredLogsMessages: string[] = ["Request failed with status code"];
const ignoredCommonMessages: string[] = [
  "[webpack-dev-server]",
  "ads.linkedin",
];
const ignoredRUMMessages: string[] = [];

export const initDatadog = () => {
  if (isDatadogEnabled) {
    const version = isProd()
      ? `prod:${env.LATEST_TAG}`
      : `uat:${env.LATEST_TAG}`;

    const commonConfig = {
      applicationId: "999adb19-9924-4db6-86c3-3896c2fd6138",
      clientToken: "pub76119d0a7ba871ab1896f79c8f841d2f",
      service: "portal-corporativo",
      env: env.PROJECT_ENV,
      site: "datadoghq.com",
      trackSessionAcrossSubdomains: true,
      version,
    };

    datadogRum.init({
      ...commonConfig,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      beforeSend: (event) => {
        if (event.type === "error") {
          /** redact axios headers */
          if (event.error.message.match(reAxiosHeaders)) {
            event.error.message = event.error.message.replace(
              Array.from(event.error.message.matchAll(reAxiosHeaders))[0][1],
              '"<REDACTED>",\n',
            );
            return true;
          }
        }

        const eventString = JSON.stringify(event);
        const containsFilteredMessage = [
          ...ignoredCommonMessages,
          ...ignoredRUMMessages,
        ].some((msg) => eventString.includes(msg));
        return !containsFilteredMessage;
      },
      allowedTracingUrls: [
        /https:\/\/.*\.btgpactual\.com/,
        /https:\/\/.*\.empresas\.btgpactual\.com/,
        /https:\/\/.*\.btgpactualbusiness\.com/,
        /https:\/\/.*\.quickfin\.com\.br/,
      ],
    });

    datadogLogs.init({
      ...commonConfig,
      forwardErrorsToLogs: true,
      beforeSend: (event) => {
        // Prevent HTTP errors that are not intercepted from xhr from uploading to datadog
        if (event.http?.method && !event.intercepted) {
          return false;
        }

        const eventString = JSON.stringify(event);
        const containsFilteredMessage = [
          ...ignoredCommonMessages,
          ...ignoredLogsMessages,
        ].some((msg) => eventString.includes(msg));

        return !containsFilteredMessage;
      },
    });

    logger.debug(
      `[Datadog RUM ${datadogRum.version}] loaded with deploy version ${version}`,
    );
    logger.debug(
      `[Datadog Logs ${datadogLogs.version}] loaded with deploy version ${version}`,
    );
  }
};
