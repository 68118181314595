import { OButton, OIcon } from "@maestro/react";
import { RouterButton } from "components/router-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

interface LimitInvoicesEditButtonProps {
  limitRequestId: string;
}

export const LimitInvoicesEditButton = ({
  limitRequestId,
}: LimitInvoicesEditButtonProps) => {
  const {
    callService: getLimitRequest,
    value: limitRequest,
    hasError,
  } = useServiceCall(service.hubCreditManager.getLimitRequestMinified);

  useEffect(() => {
    getLimitRequest(limitRequestId);
  }, [getLimitRequest, limitRequestId]);

  return (
    <>
      {limitRequest?.status === "PENDING_APPROVAL" && (
        <RouterButton
          type="tertiary"
          dataAction="emprestimos_notas_fiscais:botao:editar"
          dataLabel="editar"
          href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.invoices.edit.path(
            { id: limitRequestId },
          )}
          role={
            roles.emprestimos.product.limitManagerLimitsDetailsInvoicesEditPage
              .role
          }
          outline
        >
          Editar
        </RouterButton>
      )}
      {hasError && (
        <OButton
          title="Erro para consultar status do limite. Clique para tentar novamente"
          type="default"
          bordered={false}
          outline
          dataLabel="tentar_novamente"
          dataAction="editar_notas_fiscais:botao:tentar_novamente"
          onClick={() => getLimitRequest(limitRequestId)}
        >
          <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
        </OButton>
      )}
    </>
  );
};
