import CustomStore, {
  type Options as CustomStoreOptions,
  type ResolvedData,
} from "devextreme/data/custom_store";
import DataSource, {
  type Options as DataSourceOptions,
} from "devextreme/data/data_source";

export const dataSourceCustomStoreGenerator = <T>(
  request: (
    ...args: Parameters<CustomStore<T>["load"]>
  ) => Promise<Extract<ResolvedData<T>, unknown[] | { data: unknown[] }>>,
  options?: {
    customStoreOptions?: Partial<CustomStoreOptions<T, T>>;
    dataSourceOptions?: DataSourceOptions<T>;
  },
) =>
  new DataSource<T>({
    store: new CustomStore<T>({
      load: request,
      ...options?.customStoreOptions,
    }),
    ...options?.dataSourceOptions,
  });

const paramsToObject = (params: string) =>
  [...new URLSearchParams(params).entries()].reduce((acc, tuple) => {
    const [key, val] = tuple;
    if (key in acc) {
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        acc[key] = [acc[key], val];
      }
    } else {
      acc[key] = val;
    }
    return acc;
  }, {} as any);

const operatorsBinaryFilter = [
  "=",
  "<>",
  ">",
  ">=",
  "<",
  "<=",
  "startswith",
  "endswith",
  "contains",
  "notcontains",
];
const operatorsUnaryFilter = ["!"];
const operatorsComplexFilter = ["and", "or"];

export const loadOptionsFilterToObject = (loadOptionsFilter: any) => {
  return (
    loadOptionsFilter &&
    paramsToObject(
      loadOptionsFilter
        .join("")
        .replace(new RegExp(operatorsBinaryFilter.join("|"), "g"), "=")
        .replace(new RegExp(operatorsUnaryFilter.join("|"), "g"), "")
        .replace(new RegExp(operatorsComplexFilter.join("|"), "g"), "&")
        .replace(
          new RegExp(
            ",(" +
              [
                ...operatorsBinaryFilter,
                ...operatorsUnaryFilter,
                ...operatorsComplexFilter,
              ].join("|") +
              "|&),",
            "g",
          ),
          "",
        ),
    )
  );
};
