import { CardSection } from "../already-approved/already-approved.styles";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { Wallet } from "./wallets.types";
import { useGridRef } from "hooks/grid-ref";
import { walletGrid } from "./wallets.grid";
import { GridTemplate } from "templates/grid-template";
import { service } from "services";
import { remapFilter } from "./wallets.filter-utils";

export interface WalletsProps {
  selectedCompany: string;
}

export const Wallets = ({ selectedCompany }: WalletsProps) => {
  const gridRef = useGridRef();

  const dataSource = dataSourceCustomStoreGenerator<Wallet>((loadOptions) =>
    service.bankinghub
      .getCardsWalletProvisions({
        companyDocument: selectedCompany,
        ...remapFilter(loadOptions),
      })
      .then(({ data }) => ({
        data: data.items as Wallet[],
        totalCount: data.numberOfRecords,
      }))
      .catch((error) => {
        console.error(error);
        const gridErrorMessage = "Falha ao buscar requisições de wallets";
        throw new Error(gridErrorMessage);
      }),
  );

  return (
    <CardSection>
      <GridTemplate gridRef={gridRef} showRefreshButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={walletGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </CardSection>
  );
};
