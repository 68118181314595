import { OBadge, OIcon, OTooltip, OTypography } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { NavigateFunction } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  Banner,
  GetApiAdminBannersResponse,
} from "services/hubcreditmanager/models/responses";
import { getStatus } from "./banner-management.utils";

export const bannersGrid = (
  navigate: NavigateFunction,
  handleToggleIsActive: (values: Banner) => Promise<void>,
  handleDeleteCampaign: (values: Banner) => Promise<void>,
  setData: React.Dispatch<
    React.SetStateAction<GetApiAdminBannersResponse | undefined>
  >,
  canEdit: boolean,
) => {
  return {
    datagrid: {
      noDataText: "Nenhuma campanha encontrada.",
      dataSource: dataSourceCustomStoreGenerator(() => {
        return service.hubCreditManager
          .getBanners()
          .then((data) => {
            setData(data.data);
            return data.data;
          })
          .catch(() => {
            throw new Error("Erro ao buscar campanhas.");
          });
      }),
    },
    columns: [
      {
        dataField: "id",
        alignment: "left",
        sortOrder: "desc",
      },
      {
        alignment: "left",
        caption: "Data de início / Data final",
        cellRender: ({ data }) => {
          const isBefore =
            data.isActive && dayjs().isBefore(dayjs(data.startingFrom));
          const isAfter =
            data.isActive && dayjs().isAfter(dayjs(data.endingAt));

          return (
            data.startingFrom &&
            data.endingAt && (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row gap-2">
                  <OTypography>
                    {dayjs(data.startingFrom).format("DD/MM/YYYY")}
                  </OTypography>

                  {isBefore && (
                    <OTooltip maxWidth="250px" floating>
                      <span slot="tooltip-content">
                        A campanha está ativa mas a data de início ainda não
                        chegou.
                      </span>
                      <OIcon category="orq" icon="orq-info" type="info" />
                    </OTooltip>
                  )}
                </div>

                <div className="d-flex flex-row gap-2">
                  <OTypography type="default">
                    Válido até {dayjs(data.endingAt).format("DD/MM/YYYY")}
                  </OTypography>

                  {isAfter && (
                    <OTooltip maxWidth="250px" floating>
                      <span slot="tooltip-content">
                        A campanha está ativa mas a data de validade já foi
                        atingida.
                      </span>
                      <OIcon
                        category="orq"
                        icon="orq-attention"
                        type="warning"
                      />
                    </OTooltip>
                  )}
                </div>
              </div>
            )
          );
        },
      },
      {
        dataField: "badge",
        alignment: "left",
        caption: "Produto",
      },
      {
        dataField: "isActive",
        caption: "Ativo",
        alignment: "left",
        cellRender: ({ data }) => {
          const { backgroundColor, label, icon } = getStatus(data);

          return (
            <OBadge type={backgroundColor} size="sm" rounded>
              {icon}
              <OTypography size="sm" key={label}>
                {label}
              </OTypography>
            </OBadge>
          );
        },
      },
      {
        caption: "Nome da campanha / Responsável",
        alignment: "left",
        cellRender: ({ data }) => {
          return (
            <div className="d-flex flex-column gap-2">
              <OTypography>{data.bannerTemplate?.identification}</OTypography>
              <OTypography type="default">{data.createdBy?.name}</OTypography>
            </div>
          );
        },
      },
      {
        caption: "Nome interno da campanha",
        alignment: "left",
        dataField: "internalName",
      },
      {
        caption: "Público alvo",
        alignment: "left",
        width: "12rem",
        cellRender: ({ data }) => {
          return (
            <div className="d-flex flex-column gap-2">
              <OTypography>
                {data.accessRules?.map((rule) => rule.name).join(", ")}
              </OTypography>
            </div>
          );
        },
      },
      {
        caption: "Última atualização",
        alignment: "left",
        allowResizing: true,
        cellRender: ({ data }) => {
          return (
            <OTypography>
              {!!data.updatedDate &&
                dayjs(data.updatedDate).format("DD/MM/YYYY")}
            </OTypography>
          );
        },
      },
      {
        caption: "Data de criação",
        alignment: "left",
        cellRender: ({ data }) => {
          return (
            <OTypography>
              {dayjs(data.createdDate).format("DD/MM/YYYY")}
            </OTypography>
          );
        },
      },
      {
        caption: "Ações",
        alignment: "center",
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  label: "Editar",
                  onClick: () =>
                    navigate(
                      corporateRouter.routes.workflow.product.bannerManagement.edit.path(
                        {
                          id: data.id,
                        },
                      ),
                    ),
                  visible: canEdit,
                },
                {
                  icon: {
                    category: "orq",
                    icon: data.isActive ? "orq-cancel" : "orq-play",
                  },
                  label: data.isActive ? "Desativar" : "Ativar",
                  onClick: () => handleToggleIsActive(data),
                  visible: canEdit,
                },
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Excluir",
                  onClick: () => handleDeleteCampaign(data),
                  visible: canEdit && !data.isActive,
                },
                {
                  icon: { category: "orq", icon: "orq-stack-details" },
                  label: "Ver detalhes",
                  onClick: () =>
                    navigate(
                      corporateRouter.routes.workflow.product.bannerManagement.details.path(
                        {
                          id: data.id,
                        },
                      ),
                    ),
                },
              ]}
            />
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<ReturnType<typeof service.hubCreditManager.getBanners>>["data"]
    >[number]
  >;
};
