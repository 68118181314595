import { OButton } from "@maestro/react";
import { useRelocate } from "../../relocate.context";

type GoAgainButtonProps = Omit<
  React.ComponentProps<typeof OButton>,
  "dataAction" | "dataLabel"
>;

export const GoAgainButton = (props: GoAgainButtonProps) => {
  const { onClick } = props;

  const { reset } = useRelocate();

  return (
    <OButton
      dataAction="realocacao_step_resultado:botao:fazer_outra_realocacao"
      dataLabel="fazer_outra_realocacao"
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        reset();
      }}
    >
      Fazer outra realocação
    </OButton>
  );
};
