import { OToastManager } from "@maestro/core";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { service } from "services";
import {
  Banner,
  GetApiAdminBannersResponse,
} from "services/hubcreditmanager/models/responses";
import { workflowRoles } from "../../../roles/workflow.roles";
import { bannersGrid } from "./banner-management.grid";
import { UseBannerProps } from "./banner-management.types";

export const useBanner = ({ fileKey, fileName }: UseBannerProps) => {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const canEdit = hasRole(workflowRoles.product.editCampaignPage.role);

  const [data, setData] = useState<GetApiAdminBannersResponse>();

  const [imageUrl, setImageUrl] = useState("");

  const { callService: download } = useServiceCall(
    service.hubCreditManager.download,
  );

  const { callService, loading } = useServiceCall(
    service.hubCreditManager.updateBanner,
  );

  const { callService: callDeleteCampaign } = useServiceCall(
    service.hubCreditManager.deleteBanner,
  );

  const downloadBannerImage = useCallback(async () => {
    if (!fileKey || !fileName) return;

    try {
      const { response } = await download({
        key: fileKey,
      });

      if (response) {
        const splitFileName = fileName.split(".");

        const blob = new Blob([response.data], {
          type: `image/${splitFileName[splitFileName.length - 1]}`,
        });

        const url = URL.createObjectURL(blob);

        setImageUrl(url);
      }
    } catch (e) {
      OToastManager.warning("Não foi possível baixar o arquivo da imagem");
    }
  }, [download, fileKey, fileName]);

  const handleToggleIsActive = useCallback(
    async (values: Banner) => {
      const { accessRules, bannerTemplate, isActive } = values;

      const accessRuleIds = accessRules?.map((rule) => rule.id);
      const bannerTemplateId = bannerTemplate?.id;

      const payload = {
        ...values,
        accessRuleIds,
        bannerTemplateId,
        isActive: !isActive,
      };

      const { success: createSuccess } = await callService({
        ...payload,
      });

      if (createSuccess) {
        OToastManager.success(
          `Campanha ${isActive ? "desativada" : "ativada"} com sucesso.`,
        );

        !gridRef.current?.instance.getDataSource().isLoading() &&
          gridRef.current?.instance.getDataSource().reload();
      } else {
        OToastManager.danger(
          `Um erro ocorreu ao tentar ${
            isActive ? "desativar" : "ativar"
          } a campanha.`,
        );
      }
    },
    [callService, gridRef],
  );

  const handleDeleteCampaign = useCallback(
    async (values: Banner) => {
      const { id } = values;

      const { success: deactivateSuccess } = await callDeleteCampaign(id);

      if (deactivateSuccess) {
        OToastManager.success(`Campanha excluída com sucesso.`);

        !gridRef.current?.instance.getDataSource().isLoading() &&
          gridRef.current?.instance.getDataSource().reload();
      } else {
        OToastManager.danger(`Um erro ocorreu ao tentar excluir a campanha.`);
      }
    },
    [callDeleteCampaign, gridRef],
  );

  const grid = useMemo(() => {
    return bannersGrid(
      navigate,
      handleToggleIsActive,
      handleDeleteCampaign,
      setData,
      canEdit,
    );
  }, [navigate, handleToggleIsActive, handleDeleteCampaign, canEdit]);

  useEffect(() => {
    if (!fileKey) return;

    downloadBannerImage();
  }, [downloadBannerImage, fileKey]);

  return {
    imageUrl,
    grid,
    gridRef,
    loading,
    data,
    setImageUrl,
    handleToggleIsActive,
  };
};
