import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLimiteCedenteContext } from "../../controlador-limite-cedente.context";
import { CedenteSearchForm } from "./search-modal.types";

export const useSearchModal = () => {
  const { setBuscaCedente } = useLimiteCedenteContext();

  const form = useForm<CedenteSearchForm>({
    defaultValues: {cedentes: []},
    resolver: yupResolver(
      yup.object({
        cedentes: yup
          .array()
          .required("É necessário selecionar ao menos um cedente")
          .min(1, "É necessário selecionar ao menos um cedente"),
      })
    )
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(() => {
    reset({ cedentes: []});
    setBuscaCedente(undefined);
  }, [reset]);

  const submit = useMemo(
    () => handleSubmit((values) => {
      
      setBuscaCedente({
        cedentes: values.cedentes
      })
    }), [handleSubmit, setBuscaCedente],
  )

  return { form, submit, clear };
};