import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { propertyTypeFormFields } from "./property-type-form.form";
import { MaskSelector } from "./_compose";

export const PropertyTypeFormComponent = () => {
  return (
    <ORow gap={3}>
      <OCol xs={12} lg={6}>
        <ORFieldInput {...propertyTypeFormFields.name} />
      </OCol>
      <OCol xs={12} lg={6}>
        <ORFieldInput {...propertyTypeFormFields.label} />
      </OCol>
      <OCol xs={12} lg={6}>
        <MaskSelector />
      </OCol>
    </ORow>
  );
};
