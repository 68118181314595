import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { percent4DigitsMask } from "utils/mask/percent-4-digits-mask";
import { SimulatedOperationsWithoutRegistryResponse } from "services/quickfin/models";
import { cardIconMap } from "../../../../../utils";

export const summaryGrid: ODataGridGeneratorConfig<SimulatedOperationsWithoutRegistryResponse> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "payerName",
        dataType: "string",
        caption: "Sacado",
        allowHeaderFiltering: false,
        alignment: "center",
      },
      {
        dataField: "payerTaxIdentification",
        dataType: "string",
        caption: "SacadoCNPJ",
        allowHeaderFiltering: false,
        alignment: "center",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "payeeName",
        dataType: "string",
        caption: "Cedente",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "payeeTaxIdentification",
        dataType: "string",
        caption: "CedenteCNPJ",
        alignment: "center",
        allowHeaderFiltering: false,
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "maturityDate",
        caption: "Data de Vencimento",
        dataType: "date",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "maturityAmount",
        caption: "Valor Vencimento",
        alignment: "center",
        allowHeaderFiltering: false,
        format: (value) => masks.currency(value, "R$", "."),
      },
      {
        dataField: "disbursementAmount",
        caption: "Valor Desembolso",
        alignment: "center",
        allowHeaderFiltering: false,
        format: (value) => masks.currency(value, "R$", "."),
      },
      {
        dataField: "brand",
        dataType: "string",
        alignment: "center",
        caption: "Bandeira",
        cellRender: ({ data }) =>
          getValueFromMap(cardIconMap, data.brand) || data.brand,
      },
      {
        dataField: "bank",
        dataType: "number",
        alignment: "center",
        caption: "Banco",
        allowHeaderFiltering: false,
      },
      {
        dataField: "agency",
        dataType: "number",
        alignment: "center",
        caption: "Agência",
        allowHeaderFiltering: false,
      },
      {
        dataField: "account",
        dataType: "number",
        alignment: "center",
        caption: "Conta",
        allowHeaderFiltering: false,
      },
      {
        dataField: "accountDigit",
        dataType: "number",
        alignment: "center",
        caption: "Dígito da Conta",
        allowHeaderFiltering: false,
      },
      {
        dataField: "taxaEfetiva",
        dataType: "number",
        alignment: "center",
        caption: "Taxa Efetiva",
        cellRender: ({ data }) => percent4DigitsMask(data.taxaEfetiva),
        allowHeaderFiltering: false,
      },
      {
        dataField: "taxaMensal",
        dataType: "number",
        alignment: "center",
        caption: "Taxa Mensal",
        cellRender: ({ data }) => percent4DigitsMask(data.taxaMensal),
        allowHeaderFiltering: false,
      },
    ],
  };
