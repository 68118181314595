import { modalManager } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DataGrid } from "devextreme-react";
import { useMemo, useRef, useState } from "react";
import {
  LogNotification,
  Notifications,
} from "services/onboarding-core-account/models/responses";
import { GridTemplate } from "templates/grid-template";
import { JsonResponseModal } from "../json-response-modal.component";

interface UserAgentModalProps {
  setJsonResponse: React.Dispatch<React.SetStateAction<string>>;
  setJsonUuidResponse: React.Dispatch<React.SetStateAction<string>>;
  setJsonRequest: React.Dispatch<React.SetStateAction<string>>;
  setJsonUuidRequest: React.Dispatch<React.SetStateAction<string>>;
}

const grid = ({
  setJsonResponse,
  setJsonUuidResponse,
  setJsonRequest,
  setJsonUuidRequest,
}: UserAgentModalProps) => {
  return {
    datagrid: {
      noDataText: "Nenhum arquivo",
      pager: {
        infoText: "Página {0} de {1} ({2} arquivos)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      {
        dataField: "id",
        caption: "ID",
        allowHeaderFiltering: false,
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "system",
        caption: "Sistema",
        allowHeaderFiltering: false,
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "description",
        caption: "Descrição",
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-unified-invoice" },
                  label: "Exibir Request",
                  onClick: () => {
                    setJsonUuidRequest(data.uuid + "request");
                    setJsonRequest(data.jsonRequest);
                    setTimeout(
                      () => modalManager.show(data.uuid + "request"),
                      0,
                    );
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-unified-invoice" },
                  label: "Exibir Resposta",
                  onClick: () => {
                    setJsonUuidResponse(data.uuid + "response");
                    setJsonResponse(data.jsonResponse);
                    setTimeout(
                      () => modalManager.show(data.uuid + "response"),
                      0,
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  } as ODataGridGeneratorConfig<LogNotification>;
};

interface LogCardProps {
  items: MasterDetailComponentProps<Notifications>;
}

export const LogsMasterDetail = ({
  items: {
    data: {
      data: { logs },
    },
  },
}: LogCardProps) => {
  const [uuidNotificationResponse, setJsonUuidResponse] = useState<string>("");
  const [jsonResponse, setJsonResponse] = useState<string>("");
  const [uuidNotificationRequest, setJsonUuidRequest] = useState<string>("");
  const [jsonRequest, setJsonRequest] = useState<string>("");

  const gridComponent = useMemo(() => {
    return grid({
      setJsonUuidResponse,
      setJsonResponse,
      setJsonRequest,
      setJsonUuidRequest,
    });
  }, []);

  const templatesGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate>
      <ODataGridGenerator
        gridRef={templatesGridRef}
        grid={gridComponent}
        dataSource={logs}
      />
      <JsonResponseModal
        jsonResponseModalId={uuidNotificationResponse}
        content={jsonResponse}
      />
      <JsonResponseModal
        jsonResponseModalId={uuidNotificationRequest}
        content={jsonRequest}
      />
    </GridTemplate>
  );
};
