import { masks } from "@maestro/utils";
import { OTypography } from "@maestro/react";
import { formatStatus } from "../../detalhes/credit-conciliation-details.utils";

export interface InfoCardContentProps {
  title: string;
  value: number;
  quantity: number;
  status: string;
}

export const InfoCardContent = ({
  title,
  value,
  quantity,
}: InfoCardContentProps) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <OTypography tag="h1" size="md" type="dark">
          {formatStatus(title)}
        </OTypography>
      </div>
      <div className="mt-2">
        <div className="d-flex justify-content-between">
          <OTypography tag="h1" size="md" type="dark">
            {masks.currency(value, "R$")}
          </OTypography>
        </div>
        <div className="d-flex justify-content-between">
          <OTypography tag="h1" size="md" type="dark">
            {quantity} Qtd.
          </OTypography>
        </div>
      </div>
    </>
  );
};
