import { cleanTaxId, normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { BatchTierInfo } from "../../upload-tiers.types";

export const parseBatchTiersSpreadsheet = (
  buffer: FileReader["result"],
): BatchTierInfo[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);
    console.log('cleanRow', cleanRow);

    return {
      payeeTaxIdentification: cleanTaxId(cleanRow["cnpj allowlist"]),
      payerTaxIdentification: cleanTaxId(cleanRow["cnpj blocklist"]),
      tierClassification: 1,
    };
  });
};
