import { FormProvider } from "react-hook-form";
import { useAddPayer } from "./add-payers.hook";
import { stepManager } from "./_compose";

export const AddPayer = () => {
  const {
    configurations,
    currentStep,
    form,
    loadingDesks,
    possibleDesks,
    requiredDocuments,
    requiredFields,
    setCurrentStep,
  } = useAddPayer();

  return (
    <FormProvider {...form}>
      {stepManager(
        configurations,
        currentStep,
        loadingDesks,
        possibleDesks,
        requiredDocuments,
        requiredFields,
        setCurrentStep,
      )}
    </FormProvider>
  );
};
