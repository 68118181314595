import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { RenderOnlyIfParams, useValidateAccount } from "../_compose";
import { useClose } from "./close.hook";
import { ClosingReasonFields } from "./closing-reason-fields.component";

interface ClosingReasonProps {
  ids: number[];
}

const ClosingReasonContent = ({ ids }: ClosingReasonProps) => {
  const { form, loading: submitLoading, submit } = useClose(ids);

  const {
    canClose,
    hasError: validationError,
    loading: validationLoading,
    validateAccounts,
  } = useValidateAccount(ids);

  const {
    callService: getAccontCloseReasons,
    hasError,
    loading,
    value: reasons,
  } = useServiceCall(service.onboardingCoreAccount.getAccontCloseReasons);

  useEffect(() => {
    getAccontCloseReasons();
  }, [getAccontCloseReasons]);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          dataAction="acoes:botao:enviar"
          dataLabel="enviar"
          loading={submitLoading}
          onClick={submit}
          disabled={!canClose}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loading || validationLoading}
          hasError={hasError || validationError}
          value={reasons}
          errorComponent={
            validationError ? (
              <DefaultErrorComponent
                title="Não foi possível validar as contas"
                callback={validateAccounts}
              />
            ) : (
              <DefaultErrorComponent
                title="Não foi possível buscar as razões de encerramento"
                callback={getAccontCloseReasons}
              />
            )
          }
          render={(_reasons) => (
            <ClosingReasonFields form={form} reasons={_reasons} />
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};

export const ClosingReason = () => (
  <DetailsTemplate pageTitle={<PageTitle title="Motivo do encerramento" />}>
    <RenderOnlyIfParams render={(ids) => <ClosingReasonContent ids={ids} />} />
  </DetailsTemplate>
);
