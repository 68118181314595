export const taxaPrazoMap = {
  InputTaxa: {
    value: "taxa",
    label: "Taxa",
  },
  InputSpread: {
    value: "spread",
    label: "Spread",
  },
  InputDiMais: {
    value: "diMais",
    label: "DI+",
  },
} as const;
