import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { ContractGeneratorFormValues } from "../contract-generator.types";

type ContractVariable =
  ContractGeneratorFormValues["contractVariables"][number];

export const contractVariablesGrid = (
  onChange: (contractTemplates: ContractVariable[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma variável para tagueamento configurada",
      dataSource: [],
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false,
      },
      onRowInserted: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowUpdated: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
    },
    columns: [
      {
        dataField: "key",
        caption: "Chave",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "value",
        caption: "Valor",
        validationRules: [{ type: "required" }],
      },
    ],
  } satisfies ODataGridGeneratorConfig<ContractVariable>);
