import { masks } from "@maestro/utils";
import {
  FeesType,
  InterestType,
} from "services/admin-bankinghub/collection/models/types";

export const assembleDaysToCancelResume = ({
  settings: { daysToCancel },
}: BankingHubAdminCollection.Agreement) => {
  if (daysToCancel === undefined) return "-";
  if (daysToCancel === 0) return "No dia do vencimento";
  if (daysToCancel === 1) return "1 dia após o vencimento";
  return `${daysToCancel} dias após o vencimento`;
};

export const formatPercentOrAmount = (
  noValueMessage: string,
  value?: number,
  type?: FeesType | InterestType,
) => {
  if (value === undefined || type === undefined) return "-";

  const hasNoValue = [
    FeesType.NOT_APPLICABLE,
    InterestType.NOT_APPLICABLE,
  ].includes(type);

  if (hasNoValue) return noValueMessage;

  const isPercentageValue = [
    FeesType.PERCENTAGE,
    InterestType.PERCENTAGE_PER_MONTH,
  ].includes(type);

  return isPercentageValue
    ? masks.percentage(value / 100)
    : masks.currency(value, "R$");
};
