import styled from "styled-components";

export const GoBackLink = styled.a`
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: var(--font-sm);
  width: 100%;
  padding: var(--font-xs) var(--font-md);
  color: var(--theme-info);

  &:focus-visible {
    outline: 2px solid var(--theme-focus);
  }

  &:hover {
    color: var(--theme-info-hover);
  }
`;
