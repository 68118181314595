import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const useStepTypeSelector = () => {
  const { callService: getStepTypes, value } = useServiceCall(
    service.hubEnergy.getDiligenceStepType,
  );

  useEffect(() => {
    getStepTypes();
  }, [getStepTypes]);

  return { getStepTypes, stepTypes: value?.response ?? [] };
};
