import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./approve-contingency.form";
import { FormValuesType } from "./approve-contingency.types";

export const useToolsApproveContingency = () => {
  const navigate = useNavigate();
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        relationship: values.relationshipType,
        taxId: customer.identification,
      };

      await service.onboarding.approveOnboading(payload);

      OToastManager.success("Conclusão de cadastro aprovada.");

      navigate(corporateRouter.routes.cadastro.customer.tools.path);
    } catch (error) {
      OToastManager.danger("Erro ao aprovar conclusão de cadastro.");
    } finally {
      setLoading(false);
    }
  });

  return { form, submit, loading };
};
