import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useRegisterOperator } from "./register-operator.hook";

export const RegisterOperator = () => {
  const { createOperatorLoading, form, getLegalEntityLoading, submit } =
    useRegisterOperator();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Registrar atendente" />}
      actions={
        <LoadingButton
          dataAction="acoes:botao:enviar"
          dataLabel="enviar"
          loading={createOperatorLoading}
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="form:texto:cpf"
                dataLabel="cpf"
                id="taxIdentification"
                name="taxIdentification"
                tag="cpf"
                label="CPF"
                placeholder="Preencher"
                required
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="form:texto:email"
                dataLabel="email"
                id="email"
                name="email"
                tag="text"
                label="E-mail"
                placeholder="Preencher"
                required
              />
            </OCol>
            <OCol xs={12} md={6} align="center">
              <ORFieldInput
                dataAction="form:texto:nome"
                dataLabel="nome"
                id="name"
                name="name"
                tag="text"
                label="Nome"
                placeholder="Preencher o CPF"
                disabled
                required
              />
              {getLegalEntityLoading && <OLoader size="sm" />}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
