import {
  modalManager,
  OConfirmationModal,
  OToastManager,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { GetApiAdminScoreBenefitsItem } from "services/hubcreditmanager/models/responses";

interface DeleteScoreBenefitModalProps {
  getScoreBenefits: () => Promise<unknown>;
  benefit: GetApiAdminScoreBenefitsItem | undefined;
}

export const DELETE_SCORE_BENEFIT_MODAL = "delete-score-benefit-modal";

export const DeleteScoreBenefitModal = ({
  getScoreBenefits,
  benefit,
}: DeleteScoreBenefitModalProps) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { hide } = modalManager;

  const deleteBenefit = useCallback(async () => {
    if (!benefit?.id) return;

    setDeleteLoading(true);

    try {
      await service.hubCreditManager.deleteScoreBenefit(benefit.id);
      await getScoreBenefits();

      hide(DELETE_SCORE_BENEFIT_MODAL);

      OToastManager.success("Benefício removido com sucesso");
    } catch {
      OToastManager.danger("Não foi possível remover o benefício");
    } finally {
      setDeleteLoading(false);
    }
  }, [benefit, getScoreBenefits, hide]);

  return (
    <OConfirmationModal
      size="sm"
      modalId={DELETE_SCORE_BENEFIT_MODAL}
      onConfirm={deleteBenefit}
      loading={deleteLoading}
      title={`Tem certeza que deseja remover o benefício ${benefit?.name}?`}
    />
  );
};
