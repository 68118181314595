import { useServiceCall } from "hooks/service-call";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { useCustomer } from "contexts/customer";
import { FileProcessor, PaymentsFilterParams } from "./supplier-payments.type";
import {
  getEndOfMonth,
  getStartOfMonth,
  parseDateToFilter,
} from "./_compose/filters-modal/filters-modal.utils";

interface PaymentsContext {
  files?: FileProcessor[];
  paymentsFilters: PaymentsFilterParams;
  setPaymentsFilters: Dispatch<SetStateAction<PaymentsFilterParams>>;
  loading: boolean;
  hasError: boolean;
  getFiles: () => Promise<unknown>;
}

const paymentsContext = createContext({} as PaymentsContext);

interface PaymentsProviderProps {
  children: React.ReactNode;
}

export const SupplierPaymentsProvider = ({
  children,
}: PaymentsProviderProps) => {
  const initialDate = parseDateToFilter(getStartOfMonth());
  const finalDate = parseDateToFilter(getEndOfMonth());
  const [paymentsFilters, setPaymentsFilters] = useState<PaymentsFilterParams>({
    initialDate,
    finalDate,
  });

  const {
    customer: { identification },
  } = useCustomer();

  const {
    callService,
    hasError,
    loading,
    value: files,
  } = useServiceCall(service.adminBankinghub.getFiles);

  const getFiles = useCallback(
    () =>
      callService({
        pageNumber: 1,
        pageSize: 1000,
        products: "3bdabae9-8062-4c78-931a-877ccd766453",
        types: "inbound",
        onlyValidOutboundFile: "true",
        ...paymentsFilters,
        companyDocument: identification,
      }),
    [callService, identification, paymentsFilters],
  );

  useEffect(() => {
    getFiles();
  }, [getFiles, paymentsFilters]);

  const value = useMemo(
    () => ({
      paymentsFilters,
      setPaymentsFilters,
      files: files?.files as FileProcessor[],
      hasError,
      loading,
      getFiles,
    }),
    [paymentsFilters, files?.files, hasError, loading, getFiles],
  );

  return (
    <paymentsContext.Provider value={value}>
      {children}
    </paymentsContext.Provider>
  );
};

export const usePayments = () => useContext(paymentsContext);
