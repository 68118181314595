import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import { OButton, OLoader } from "@maestro/react";
import { masks } from "@maestro/utils";
import { AxiosError } from "axios";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import dayjs from "dayjs";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { adminBankinghubService } from "services/admin-bankinghub/admin-bankinghub.service";
import { BankAccount } from "services/clerk";
import {
  SharkAmortizationType,
  SharkErrorResponse,
  SharkPaymentMethodType,
  SharkSimulateResponse,
} from "services/shark/models/responses";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosClienteOperacoesByIdAndContractNumber } from "../../../../../../../routes/emprestimos.route-params";
import { currencySymbolMap } from "../../../../../ofertas/offers.type";
import {
  CONFIRM_PREPAYMENT_MODAL,
  CONFIRM_PREPAYMENT_STORAGE_HASH,
  ConfirmPrepaymentModal,
} from "../_compose/confirm-prepayment-modal.component";
import { PaymentMethodCard } from "../_compose/payment-method-card.component";
import {
  REDIRECT_OPERATIONS_MODAL,
  RedirectOperationsModal,
} from "../_compose/redirect-operations-modal.component";
import { AnticipationDetailsGenerator } from "./anticipation-details.details";
import { useAnticipation } from "./anticipation.context";
import {
  anticipationFormDefaultValues,
  anticipationFormSchema,
} from "./anticipation.form";
import { SimulationInstallment } from "./anticipation.types";

export const AnticipationComponent = () => {
  const [loadingAnticipation, setLoadingAnticipation] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [lastSimulationResult, setLastSimulationResult] =
    useState<SharkSimulateResponse>();
  const [bankAccount, setBankAccount] = useState<BankAccount>();

  const {
    callService: getAccountBalance,
    value: accountBalance,
    loading: loadingBalance,
  } = useServiceCall(adminBankinghubService.getBalanceByAccount);

  const { id, contractNumber } =
    useParams<EmprestimosClienteOperacoesByIdAndContractNumber>();
  if (!id || !contractNumber) throw new Error("No id or no contractNumber");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { customer } = useCustomer();

  const { confirmSimulation, error, getSharkSettlement, loading, simulate } =
    useAnticipation();

  const form = useForm({
    resolver: yupResolver(anticipationFormSchema),
    defaultValues: anticipationFormDefaultValues,
  });

  const { watch } = form;

  const paymentMethodWatch: SharkPaymentMethodType = watch("paymentMethod");
  const expirationDateWatch: string = watch("expirationDate");

  const selectedInstallments = useMemo(() => {
    const queryInstallments = JSON.parse(
      searchParams.get("installments") || "{}",
    ) as Record<SharkAmortizationType, number[]>;

    return Object.entries(queryInstallments).flatMap(
      ([amortizationFrequency, installments]) =>
        installments.map(
          (i) =>
            ({
              number: i,
              amortization_frequency: amortizationFrequency,
            } as SimulationInstallment),
        ),
    );
  }, [searchParams]);

  const simulationCache: Record<string, SharkSimulateResponse> = useMemo(
    () => ({}),
    [],
  );

  const beginSimulation = useCallback(
    async (
      selectedPaymentMethod: SharkPaymentMethodType,
      expirationDate?: string,
    ) => {
      const key =
        expirationDate !== undefined ? expirationDate : selectedPaymentMethod;

      if (
        simulationCache[key] &&
        new Date(simulationCache[key].expire_at) > new Date()
      ) {
        setLastSimulationResult(simulationCache[key]);
        return;
      }

      setLoadingSimulation(true);
      let simulationResponse: SharkSimulateResponse | undefined;

      try {
        if (selectedPaymentMethod === "C/C") {
          simulationResponse = await simulate(selectedInstallments);
        } else if (selectedPaymentMethod === "BOLETO") {
          simulationResponse = await simulate(
            selectedInstallments,
            expirationDate as string,
          );
        }
      } catch (err) {
        const e = err as AxiosError<SharkErrorResponse>;

        OToastManager.danger(
          e.response?.data?.error?.message ??
            "Um erro ocorreu ao tentarmos realizar a simulação para a data de pagamento selecionada. Por favor, tente novamente mais tarde",
        );
      } finally {
        setLoadingSimulation(false);
        setLastSimulationResult(simulationResponse);

        if (simulationResponse) {
          simulationCache[key] = simulationResponse;
        }
      }
    },
    [selectedInstallments, simulate, simulationCache],
  );

  const beginAnticipation = useCallback(async () => {
    try {
      if (!lastSimulationResult) throw new Error("Simulação não identificada");
      if (
        paymentMethodWatch === "C/C" &&
        lastSimulationResult.curve_value > (accountBalance?.balance ?? 0)
      ) {
        if (accountBalance) {
          OToastManager.warning({
            title: "Saldo em conta menor do que o valor total a pagar",
            message: `Cliente possui saldo de ${masks.currency(
              accountBalance.balance,
              currencySymbolMap.BRL,
              ".",
            )} em conta correte.`,
          });
        } else {
          OToastManager.danger(
            "Não conseguimos identificar o saldo na conta corrente do cliente. Por favor, tente novamente mais tarde.",
          );
        }

        return;
      }

      setLoadingAnticipation(true);

      const confirmationResponse = await confirmSimulation(
        lastSimulationResult.simulation_identification,
        paymentMethodWatch as SharkPaymentMethodType,
        bankAccount,
      );

      // Débito em conta
      if (confirmationResponse?.has_approval) {
        modalManager.show(REDIRECT_OPERATIONS_MODAL);
      } else if (
        !confirmationResponse?.has_approval &&
        confirmationResponse?.barcode_number
      ) {
        // Boleto
        navigate({
          pathname:
            corporateRouter.routes.emprestimos.customer.operations.quotes.anticipation.installmentAnticipation.success.path(
              { contractNumber, id },
            ),
          search: createSearchParams({
            barcode: String(confirmationResponse?.barcode_number),
            expirationDate: dayjs(expirationDateWatch).format("YYYY-MM-DD"),
            installmentsAmount: String(lastSimulationResult.value_to_pay),
            installmentsCount: selectedInstallments.length.toString(),
            discount: String(
              lastSimulationResult.amount_at_maturity -
                lastSimulationResult.curve_value,
            ),
          }).toString(),
        });
      } else {
        OToastManager.success({
          title: "Solicitação de antecipação realizada com sucesso.",
          message: "Em breve o valor total será debitado da conta do cliente.",
        });

        navigate(
          corporateRouter.routes.emprestimos.customer.operations.details.path({
            id,
          }),
        );
      }
    } catch (err) {
      const e = err as AxiosError<SharkErrorResponse>;

      OToastManager.warning(
        e.response?.data?.error?.message ??
          "Um erro ocorreu ao tentarmos realizar a antecipaçao das parcelas. Por favor, tente novamente mais tarde.",
      );
    } finally {
      setLoadingAnticipation(false);
    }
  }, [
    accountBalance,
    bankAccount,
    confirmSimulation,
    contractNumber,
    expirationDateWatch,
    id,
    lastSimulationResult,
    navigate,
    paymentMethodWatch,
    selectedInstallments.length,
  ]);

  useEffect(() => {
    if (paymentMethodWatch === "C/C") {
      beginSimulation(paymentMethodWatch);
    } else if (paymentMethodWatch === "BOLETO" && expirationDateWatch) {
      beginSimulation(paymentMethodWatch, expirationDateWatch);
    }
  }, [beginSimulation, expirationDateWatch, paymentMethodWatch]);

  useEffect(() => {
    getSharkSettlement();
  }, [getSharkSettlement]);

  useEffect(() => {
    const accountPME = customer.bankAccounts.find(
      (account) => account.bankCode === "208" && account.agency === "50",
    );

    if (accountPME) {
      setBankAccount(accountPME);
    }
  }, [customer.bankAccounts]);

  useEffect(() => {
    if (bankAccount) {
      let { accountNumber } = bankAccount;
      if (bankAccount.accountDigit) {
        accountNumber += bankAccount.accountDigit;
      }

      getAccountBalance(Number(accountNumber), Number(bankAccount.agency));
    }
  }, [bankAccount, getAccountBalance]);

  return (
    <>
      {(loadingAnticipation || loading || loadingSimulation) && (
        <OLoader absolute backdrop />
      )}
      <RedirectOperationsModal />
      <ConfirmPrepaymentModal beginAnticipation={beginAnticipation} />
      <FormProvider {...form}>
        <DetailsTemplate
          pageTitle={<PageTitle title="Antecipação de parcelas" />}
        >
          <div className="d-flex flex-column gap-3">
            <DetailsCard
              errorComponent={
                <ErrorComponent
                  messageTitle="Não foi possível carregar os dados da antecipação de parcelas."
                  messageParagraph="Por favor, tente novamente mais tarde."
                >
                  <TryAgainButton onClick={() => getSharkSettlement()} />
                </ErrorComponent>
              }
              hasError={error}
              value={lastSimulationResult}
              fields={AnticipationDetailsGenerator}
            />
            <PaymentMethodCard
              form={form}
              bankAccount={bankAccount}
              loadingBalance={loadingBalance}
            />
            <OButton
              className="align-self-end"
              disabled={
                loading ||
                loadingAnticipation ||
                loadingSimulation ||
                loadingBalance ||
                !lastSimulationResult ||
                !paymentMethodWatch ||
                (paymentMethodWatch === "BOLETO" && !expirationDateWatch)
              }
              onClick={() => {
                if (sessionStorage.getItem(CONFIRM_PREPAYMENT_STORAGE_HASH)) {
                  beginAnticipation();
                } else {
                  modalManager.show(CONFIRM_PREPAYMENT_MODAL);
                }
              }}
            >
              Confirmar
            </OButton>
          </div>
        </DetailsTemplate>
      </FormProvider>
    </>
  );
};
