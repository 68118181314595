import { env } from "utils/environments";

export const reconcilieroEndpoints = {
  reconciliations: {
    results: {
      endpoint: `${env.API_RECONCILIERO}/api/reconciliations/results`,
      byId: (id: string) =>
        `${env.API_RECONCILIERO}/api/reconciliations/results/${id}`,
      details: {
        byId: (id: string) =>
          `${env.API_RECONCILIERO}/api/reconciliations/results/details/${id}`,
      },
      justify: {
        byId: (id: string) =>
          `${env.API_RECONCILIERO}/api/reconciliations/results/justify/${id}`,
      },
      approve: {
        byId: (id: string) =>
          `${env.API_RECONCILIERO}/api/reconciliations/results/approve/${id}`,
      },
    },
    categories: {
      endpoint: `${env.API_RECONCILIERO}/api/categories`,
    },
  },
};
