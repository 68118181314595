import { logger } from "utils/logger";

export const customEventsBuilder = <PayloadType>(
  eventName: string,
  initialValue: PayloadType | undefined | null = null,
) => {
  let subscriberCount = 0;
  let currentValue = initialValue;

  return {
    /**
     * Dispatches event with the given payload
     * @param payload Payload
     */
    dispatch: (payload: PayloadType) => {
      currentValue = payload;

      if (subscriberCount === 0) {
        logger.debug(
          `[custom events] there are no subscribers to the event ${eventName}, make sure you subscribe before dispatching events`,
        );
      }

      const event = new CustomEvent(eventName, {
        detail: payload,
      });

      window.dispatchEvent(event);
    },

    /**
     * Subscribe to the custom event
     * @param callback Event listener callback
     * @param invokeImmediately If enabled, invokes callback immediately if there is a state
     * @returns Callback to unsubscribe
     */
    subscribe: (
      callback: (p: PayloadType) => void,
      invokeImmediately = true,
    ) => {
      subscriberCount += 1;

      if (invokeImmediately && currentValue) {
        callback(currentValue);
      }

      const handler = (ev: CustomEvent<PayloadType>) => {
        callback(ev.detail);
      };

      window.addEventListener(eventName, handler as EventListener);

      const unsubscribe = () =>
        window.removeEventListener(eventName, handler as EventListener);

      return unsubscribe;
    },
  };
};
