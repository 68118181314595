import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { CompanyDetailsCardTemplate } from "../template";
import { InviteCardContents } from "./invite-card-contents.component";
import { useInviteCard } from "./invite-card.hook";

export const InviteCard = () => {
  const { value, getInviteInfo, hasError, isNotFoundError, loading } =
    useInviteCard();

  return (
    <CompanyDetailsCardTemplate title="Convite">
      <ContentTemplate
        loading={loading}
        value={value}
        hasError={hasError}
        errorComponent={
          isNotFoundError ? (
            <EmptyState messageParagraph="Nenhuma informação" />
          ) : (
            <DefaultErrorComponent
              title="Não foi possível buscar as informações"
              callback={getInviteInfo}
            />
          )
        }
        render={(_inviteInfo) => <InviteCardContents {..._inviteInfo} />}
      />
    </CompanyDetailsCardTemplate>
  );
};
