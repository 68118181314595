import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GetDashboardChargesParams } from "services/fidc/models/requests";
import { GetDashboadChargesResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { ViewAssetsGrid } from "./view-assets.grid";

type ViewAssetsSearchParams = GetDashboardChargesParams;

export const ViewAssets = () => {
  const { fundCge, clientCge } = getSearchParams<ViewAssetsSearchParams>();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<GetDashboadChargesResponse[number]>(() => {
        const params = {
          fundCge: fundCge ?? "",
          clientCge: clientCge ?? "",
        };
        return service.fidc
          .getFundClientCharges(params)
          .then(({ data }) => ({ data, totalCount: data.length }))
          .catch(() => {
            const errorMessage = "Erro ao buscar os usuários";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [fundCge, clientCge],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ativos" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={ViewAssetsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
