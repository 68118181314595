import * as yup from "yup";

export interface AddLinkFormFields {
  bookUuid: string;
  catalogUuid: string;
  productUuid: string;
}

export const addLinkSchema: yup.ObjectSchema<AddLinkFormFields> = yup.object({
  bookUuid: yup.string().required("Este campo é obrigatório"),
  catalogUuid: yup.string().required("Este campo é obrigatório"),
  productUuid: yup.string().required("Este campo é obrigatório"),
});
