import {
  OButton,
  OCard,
  OCardBody,
  OIcon,
  OInputSearch,
  OModal,
  OModalBody,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { getValueFromMap } from "utils/get-value-from-map";
import { mapProcessorType } from "../../../../../../../../components/canvas/canvas.utils";
import {
  AddProcessorConfigModalId,
  useAddProcessorConfigModal,
} from "./add-processor-config-modal.hook";
import { OModalDiv, StyledOBadge } from "./add-processor-config-modal.styles";

export const AddProcessorConfigModal = () => {
  const { search, setSearch, filteredProcessors, addProcessorConfig } =
    useAddProcessorConfigModal();

  return (
    <OModal
      id={AddProcessorConfigModalId}
      position="center"
      size="md"
      onModalClose={() => modalManager.hide(AddProcessorConfigModalId)}
    >
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar configuração de processador
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OInputSearch
          placeholder="Buscar processador pelo tipo"
          id="processor-search"
          name="processor-search"
          autocomplete="off"
          value={search}
          onInput={(e) => setSearch(e.currentTarget.value)}
        />
        <OModalDiv>
          {filteredProcessors?.map((x) => (
            <OCard key={`processor-card-${x.id}`}>
              <OCardBody>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex flex-column gap-2 align-items-start">
                      <OTypography type="dark" weight="bold">
                        {getValueFromMap(mapProcessorType, x.type) || x.type}
                      </OTypography>
                      {!!getValueFromMap(mapProcessorType, x.type) && (
                        <OTypography
                          type="dark-80"
                          size="sm"
                        >{`(${x.type})`}</OTypography>
                      )}
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <OTypography type="dark" size="sm">
                        Ponto de entrada
                      </OTypography>
                      <StyledOBadge
                        type={x.canBeWfEntry ? "success" : "danger"}
                        outline
                      >
                        <OIcon
                          category="fa"
                          icon={x.canBeWfEntry ? "fa-check" : "fa-times"}
                        />
                      </StyledOBadge>
                    </div>
                  </div>

                  <OTypography type="dark">{x.description}</OTypography>
                  <div className="d-flex justify-content-end">
                    <OButton
                      type="dark"
                      onClick={() => addProcessorConfig(x.id)}
                    >
                      Adicionar
                    </OButton>
                  </div>
                </div>
              </OCardBody>
            </OCard>
          ))}
        </OModalDiv>
      </OModalBody>
    </OModal>
  );
};
