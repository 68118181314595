import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const LastWeekContestationsCard = () => {
  return (
    <InfoCard
      summaryType="contestationsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Contestações na última semana"
          body={_value.lastWeekQuantity.toLocaleString("pt-br")}
          footer="Número de contestações criadas na semana anterior"
        />
      )}
    />
  );
};
