import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { profileManagementChangesStatusMap } from "../profiles-management.utils";
import { profileManagementTypeMap } from "./_compose/profiles-role-form.schemas";

export const profileRequestGrid = (
  requestId: string,
  showActions: boolean,
): ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.gatekeeper.getProfileManagementRequestDetails>
  >["data"]["changes"][number]
> => ({
  datagrid: {
    noDataText: "Nenhuma mudança adicionada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "type",
      caption: "Tipo",
      cellRender: ({ data }) =>
        getValueFromMap(profileManagementTypeMap, data.type) ?? data.type,
    },
    {
      caption: "Detalhes",
      cssClass: "dx-word-wrap",
      cellRender: ({ data }) => (
        <>
          {data.name && <p>Nome: {data.name}</p>}
          {data.description && <p>Descrição: {data.description}</p>}
          {data.profileName && <p>Perfil: {data.profileName}</p>}
          {data.roleNames && <p>Roles: {data.roleNames?.join(", ")}</p>}
          {data.subProfileNames && (
            <p>Sub-perfis: {data.subProfileNames?.join(", ")}</p>
          )}
        </>
      ),
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) =>
        getValueFromMap(
          profileManagementChangesStatusMap,
          data.status,
        )?.(data.errors?.join(", ")) ?? data.status,
    },
    {
      caption: "Ações",
      alignment: "center",
      visible: showActions,
      cellRender: ({ component, rowIndex, data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar",
              visible:
                data.status === "EM_CONSTRUCAO" ||
                data.status === "PENDENTE_ALTERACAO",
              route:
                corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement.details.update.path(
                  {
                    changeId: data.id,
                    requestId,
                  },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir",
              onClick: () => {
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
});
