import { WorkflowProvider } from "../../../../components/hooks/use-workflow.context";
import { PropertyModal } from "./_compose/property-modal/property-modal.component";
import { EditProcessor } from "./_compose/edit-processor/edit-processor.component";
import { OutputModal } from "./_compose/output-modal/output-modal.component";
import { EditProcessorProvider } from "./edit-processor.context";

export const EditProcessorPage = () => {
  return (
    <WorkflowProvider>
      <EditProcessorProvider>
        <EditProcessor />
        <PropertyModal />
        <OutputModal />
      </EditProcessorProvider>
    </WorkflowProvider>
  );
};
