import { OStep, OStepHeader } from "@maestro/react";
import { useStepAnalysisContext } from "../step-analysis.context";

export const StepAnalysisStageSelector = () => {
  const { selectedStageIndex, setSelectedStageIndex, stages } =
    useStepAnalysisContext();

  return (
    <OStepHeader
      activeStep={selectedStageIndex}
      handleSelected={(index) => setSelectedStageIndex(index)}
      className="my-4"
      key={stages.map((s) => s.title).join()}
    >
      {stages.map((s, idx) => {
        return (
          <OStep key={s.key} position={idx % 2 ? "top" : "bottom"}>
            <p
              style={{
                color: "var(--theme-dark)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: `calc((100vw - 320px) / ${stages.length})`,
              }}
              title={s.title}
            >
              {s.title}
            </p>
          </OStep>
        );
      })}
    </OStepHeader>
  );
};
