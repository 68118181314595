import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { exchangeOdataQuotesGrid } from "../../../../../../components/odata/exchange-odata-quotes.grid";
import { CambioPreTicketsByIdRouteParams } from "../../../../../../routes/cambio.route-params";
import { useCurrencies } from "hooks/currency/currency.hook";

export const ExchangeProductPreTicketQuotesDetailsPage = () => {
  const { id } = useParams<CambioPreTicketsByIdRouteParams>();

  if (!id) throw new Error("No id");

  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();

  const grid = useMemo(
    () => exchangeOdataQuotesGrid(mapSymbol, ["PreTicketId", "=", +id]),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações do Pré-Ticket" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
