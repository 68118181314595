import { StatusBadge } from "components/status-badge";
import { UnstyledButton } from "components/unstyled-button";

interface PendencySummaryBadgeProps {
  level: HubEnergy.EAnalysisLevel;
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
  goToStage: () => void;
}

export const PendencySummaryBadge = ({
  pendency,
  level,
  goToStage,
}: PendencySummaryBadgeProps) => {
  if (
    (level === "BPO" && pendency.status === "PENDENTE_BPO") ||
    (level === "OPS" && pendency.status === "PENDENTE_OPS")
  )
    return (
      <UnstyledButton onClick={goToStage}>
        <StatusBadge type="warning">Análise pendente</StatusBadge>
      </UnstyledButton>
    );

  return <StatusBadge type="success">Análise concluída</StatusBadge>;
};
