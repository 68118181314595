import { OCol, ORFieldInput, ORFieldInputDate, ORow } from "@maestro/react";
import { consumerUnitFormFields } from "../../../../edit-consumer-unit.form";
import { DistributorSelector } from "../distributor-selector";

interface DistributorFieldsProps {
  disabled?: boolean;
}

export const DistributorFields = ({ disabled }: DistributorFieldsProps) => {
  return (
    <>
      <ORow gap={3}>
        <OCol xs={12} md={6}>
          <DistributorSelector disabled={disabled} />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...consumerUnitFormFields.installationNumber}
            disabled={disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...consumerUnitFormFields.clientNumber}
            disabled={disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput {...consumerUnitFormFields.ccer} disabled={disabled} />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInputDate
            {...consumerUnitFormFields.distributorContractExpirationDate}
            disabled={disabled}
          />
        </OCol>
      </ORow>
    </>
  );
};
