import {
  OButton,
  OCol,
  OIcon,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { PostAdminAccountopeningTemplatesResponse } from "services/onboarding-core-account/models";
import { CreateAccountOptinForm } from "../../../../create-accounts-optin.type";

interface CreateAccountsFieldsProps {
  index: number;
  templates: PostAdminAccountopeningTemplatesResponse;
  officers: string[];
  remove: UseFieldArrayRemove;
}

export const CreateAccountsFields = ({
  index,
  officers,
  templates,
  remove,
}: CreateAccountsFieldsProps) => {
  const { value: templateId } = useParseFromSearchParams<string>("templateId");

  const { watch } = useFormContext<
    | CreateAccountOptinForm
    | CreateAccountOptinForm["createAccountOptin"][number]
  >();

  const watchOfficerName = watch(`createAccountOptin.${index}.officerName`);
  const watchAccountTemplateCode = watch(
    `createAccountOptin.${index}.accountTemplateCode`,
  );

  return (
    <ORow className="d-flex flex-row align-items-center">
      <OCol xs={1}>
        <OButton
          dataAction="role_form:botao:remover"
          dataLabel="remover"
          type="danger"
          disabled={!index}
          onClick={() => remove(index)}
        >
          <OIcon category="fa" icon="fa-trash" />
        </OButton>
      </OCol>
      <OCol xs={3}>
        <ORFieldSelect
          dataAction="formulario-abertura-conta-optin:select:officerName"
          dataLabel="Officer"
          name={`createAccountOptin.${index}.officerName`}
          id={`createAccountOptin.${index}.officerName`}
          placeholder="Selecione"
          key={officers.map((item) => item).join(index.toString())}
          label="Officer"
        >
          {templateId
            ? templates
                .filter(
                  (template) => template.accountTemplateCode === templateId,
                )
                .map((template) => (
                  <OOption
                    key={`${template.officerName}_${index}`}
                    value={template.officerName}
                    selected
                  >
                    {template.officerName}
                  </OOption>
                ))
            : officers.map((officer) => (
                <OOption key={`${officer}_${index}`} value={officer}>
                  {officer}
                </OOption>
              ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={3}>
        {watchOfficerName && (
          <ORFieldSelect
            dataAction="formulario-abertura-conta-optin:select:accoutType"
            dataLabel="accoutType"
            id={`createAccountOptin.${index}.accountTemplateCode`}
            name={`createAccountOptin.${index}.accountTemplateCode`}
            placeholder="Selecione"
            key={templates
              .map(
                (template) =>
                  `${watchOfficerName}_${template.accountTemplateCode}_${template.agency}`,
              )
              .join(index.toString())}
            label="Tipo de Conta"
          >
            {templates
              .filter((template) => template.officerName === watchOfficerName)
              .map((template) => (
                <OOption
                  key={`${watchOfficerName}_${template.accountTemplateCode}_${template.agency}`}
                  value={template.accountTemplateCode}
                  selected={!!templateId}
                >
                  Ag {template.agency} - {template.accoutType}
                </OOption>
              ))}
          </ORFieldSelect>
        )}
      </OCol>
      <OCol xs={3}>
        {watchAccountTemplateCode && (
          <ORFieldInput
            dataAction="editar_grupo:select:valor"
            dataLabel="valor"
            id={`createAccountOptin.${index}.accountQuantity`}
            name={`createAccountOptin.${index}.accountQuantity`}
            label="Quantidade de Contas"
            tag="number"
            placeholder="0"
          />
        )}
      </OCol>
      <OCol xs={2}>
        {watchAccountTemplateCode && (
          <ORFieldInput
            dataAction="editar_grupo:select:valor"
            dataLabel="valor"
            id={`createAccountOptin.${index}.desk`}
            name={`createAccountOptin.${index}.desk`}
            label="Mesa"
            tag="text"
            placeholder="Mesa"
            value={
              templates.find(
                (template) =>
                  template.accountTemplateCode === watchAccountTemplateCode,
              )?.desk ?? ""
            }
            disabled
          />
        )}
      </OCol>
    </ORow>
  );
};
