import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { ExchangeNatureForm } from "pages/acc/components/nature/nature-form.component";
import {
  exchangeNatureFormValidationFormSchema,
  ExchangeNatureFormValues,
} from "pages/acc/components/nature/nature-form.schemas";
import { ACCNaturezasByIdRouteParams } from "pages/acc/routes/acc.route-params";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getTradeFinanceErrorMessage } from "../../../../../../components/error/error-code.utils";

export const AdminContingencyACCUpdateNature = () => {
  const { id } = useParams<ACCNaturezasByIdRouteParams>();

  if (!id) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<ExchangeNatureFormValues>({
    resolver: yupResolver(exchangeNatureFormValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getNatureDetails,
    loading: loadingDetails,
    hasError,
    value: natureDetails,
  } = useServiceCall(service.acc.nature.getNatureDetails);
  const { callService: updateNatures, loading } = useServiceCall(
    service.acc.nature.updateNatures,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateNatures({
          id: Number(id),
          ...values,
          isApproved: !!values.isApproved.length,
          isOutgoingOperationAllowed:
            !!values.isOutgoingOperationAllowed.length,
          isIncomingOperationAllowed:
            !!values.isIncomingOperationAllowed.length,
          isDifferentOwnershipAccountAllowed:
            !!values.isDifferentOwnershipAccountAllowed.length,
        });
        if (!success)
          return OToastManager.danger(getTradeFinanceErrorMessage(error));

        OToastManager.success("Natureza atualizada com sucesso");

        navigate(
          corporateRouter.routes.acc.product.contingency.natures.all.path,
        );
      }),
    [handleSubmit, updateNatures, id, navigate],
  );

  useEffect(() => {
    getNatureDetails(id);
  }, [getNatureDetails, id]);

  useEffect(() => {
    natureDetails &&
      reset({
        ...natureDetails.response,
        isApproved: natureDetails.response.isApproved ? ["true"] : [],
        isOutgoingOperationAllowed: natureDetails.response
          .isOutgoingOperationAllowed
          ? ["true"]
          : [],
        isIncomingOperationAllowed: natureDetails.response
          .isIncomingOperationAllowed
          ? ["true"]
          : [],
        isDifferentOwnershipAccountAllowed: natureDetails.response
          .isDifferentOwnershipAccountAllowed
          ? ["true"]
          : [],
        documentGroupIds: natureDetails.response.documentGroups?.map(
          (group) => group.id,
        ),
        genericFields: natureDetails.response.genericFieldConfigs?.map(
          (field) => ({
            typeId: field.typeId,
            isRequired: field.isRequired,
          }),
        ),
      } as ExchangeNatureFormValues);
  }, [natureDetails, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar Natureza" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={() => getNatureDetails(id)}
          />
        }
        value={natureDetails}
        render={() => (
          <FormProvider {...form}>
            <ExchangeNatureForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
