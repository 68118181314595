import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { StepTypeFormComponent } from "../_compose";
import { useEditStepType } from "./edit-step-type.hook";

export const EditStepTypePage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getStepType,
    submit,
    submitLoading,
  } = useEditStepType();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar tipo de step" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <ValidationErrorComponent
              callback={getStepType}
              error={fetchError}
              title="Erro ao buscar o tipo de step"
            />
          }
          noValue
          render={() => (
            <FormProvider {...form}>
              <StepTypeFormComponent />
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
