import * as yup from "yup";

export type OperatorDetailsFormFields = Omit<
  HubPartners.Admin.OperatorProfileDetails,
  | "phone"
  | "partnershipType"
  | "legacyTagCode"
  | "tagCode"
  | "originator"
  | "uuid"
  | "identifier"
>;

export const operatorDetailsValidationSchema: yup.ObjectSchema<OperatorDetailsFormFields> =
  yup.object({
    identification: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    email: yup
      .string()
      .required("Este campo é obrigatório")
      .email("Deve ser um e-mail válido"),
    cge: yup.string().required("Este campo é obrigatório"),
  });
