import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeDocumentGroupsForm } from "../../../../../../components/nature/document-groups/document-groups-form.component";
import {
  ExchangeDocumentGroupsFormValues,
  exchangeDocumentGroupsFormValidationFormSchema,
} from "../../../../../../components/nature/document-groups/document-groups-form.schemas";

export const ExchangeProductCreateDocumentGroup = () => {
  const form = useForm<ExchangeDocumentGroupsFormValues>({
    resolver: yupResolver(exchangeDocumentGroupsFormValidationFormSchema),
  });

  const navigate = useNavigate();

  const { handleSubmit } = form;

  const { callService: createNatureDocumentGroups, loading } = useServiceCall(
    service.hubFx.nature.createNatureDocumentGroups,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createNatureDocumentGroups({
          ...values,
          isRequired: !!values.isRequired.length,
          mustShowForPf: !!values.mustShowForPf.length,
          mustShowForPj: !!values.mustShowForPj.length,
        });
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success("Grupo de documento adicionado com sucesso");

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .documentGroups.path,
        );
      }),
    [createNatureDocumentGroups, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Grupo de Documentos" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          dataAction="adicionar"
          dataLabel="adicionar_grupo_documento:botao:adicionar"
        >
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ExchangeDocumentGroupsForm />
      </FormProvider>
    </FormTemplate>
  );
};
