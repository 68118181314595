import { OIcon } from "@maestro/react";
import { RouterLink } from "components/router-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { analysisStatusSearchParam } from "../../../avalistas/overdraft-guarantors.utils";

interface GuarantorLinkProps {
  analysisStatus: string | undefined | null;
}

export const GuarantorsLink = ({ analysisStatus }: GuarantorLinkProps) => (
  <RouterLink
    href={
      corporateRouter.routes.banking.customer.overdraft.guarantors.path +
      `?${analysisStatusSearchParam}=${analysisStatus}`
    }
    role={roles.banking.customer.overdraftGuarantorsPage.role}
  >
    <OIcon category="fas" icon="fa-external-link" className="me-2" />
    Ver avalistas
  </RouterLink>
);
