import { OButton, OIcon, OTypography } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { cadastroRoles } from "pages/cadastro/roles/cadastro.roles";
import { roles } from "roles/roles";
import { env } from "utils/environments";

export const RedirectOldAdminButton = () => {
  const { hasRole } = useRoles();

  return (
    <div>
      {hasRole(cadastroRoles.customer.oldAdminButtonPage.role) && (
        <OButton
          dataAction="header:botao:link_admin_antigo"
          dataLabel="link_admin_antigo"
          type="dark"
          size="sm"
          rounded
          outline
          onClick={() => window.open(env.LEGACY_ADMIN_LINK, "_blank")}
          role={roles.cadastro.customer.oldAdminButtonPage.role}
        >
          <OTypography tag="span" size="sm">
            <span className="d-flex gap-2">
              <OIcon category="orq" icon="orq-external-link" />
              Portal antigo
            </span>
          </OTypography>
        </OButton>
      )}
    </div>
  );
};
