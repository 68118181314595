import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";

type ContactsResponse = NonNullable<
  NonNullable<PostAdminCompaniesGetByTaxIdResponse["contacts"]>[number]
>;

export const grid: ODataGridGeneratorConfig<ContactsResponse> = {
  datagrid: {
    noDataText: "Nenhum cadastro",
  },
  columns: [
    { dataField: "contactType", caption: "Tipo" },
    { dataField: "isPrimary", caption: "Contato principal?" },
    { dataField: "countryCode", caption: "Código de pais" },
    { dataField: "email", caption: "E-mail" },
    { dataField: "zipCode", caption: "CEP" },
    { dataField: "street", caption: "Rua" },
    { dataField: "number", caption: "Número" },
    { dataField: "complement", caption: "Complemento" },
    {
      caption: "Telefone",
      cellRender: ({ data }) =>
        data.phones?.map((phone) => {
          const ddi = phone.ddi ?? "";
          const ddd = phone.ddd ?? "";
          const number = phone.number ?? "";

          return <span key={number}>{masks.phone(ddi + ddd + number)}</span>;
        }),
    },
  ],
};
