export const iconMap = {
  info: {
    category: "orq",
    icon: "orq-info",
    type: "dark",
  },
  success: {
    category: "orq",
    icon: "orq-status-success",
    type: "success",
  },
  warning: {
    category: "orq",
    icon: "orq-status-warning",
    type: "warning",
  },
  danger: {
    category: "orq",
    icon: "orq-status-disapproved",
    type: "danger",
  },
} as const;

export const biggerSizeMap = {
  xxs: "xs",
  xs: "sm",
  sm: "md",
  md: "lg",
  lg: "xl",
  xl: "xxl",
  xxl: "xxxl",
  xxxl: "xxxl",
} as const;
