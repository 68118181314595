import { OToastManager } from "@maestro/core";
import { OIcon, OLoader } from "@maestro/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { client } from "../../../../../../services/client";
import { MoneyMask } from "../../utils";
import { endpoints } from "../../utils/endpoints";
import { DayMonthMask } from "../../utils/masks/daymonthmask";
import { NoDataFound } from "../cards.styles";
import { Currency } from "../cards.types";
import { InvoiceCardInfo } from "./constants";
import { Details } from "./details/details.component";
import {
  InvoiceCard,
  InvoiceDate,
  InvoiceInfo,
  InvoiceStatus,
} from "./invoices.styles";
import {
  IInvoiceDetails,
  IInvoicesProps,
  MapperCycleStatus,
} from "./invoices.types";
import { ItemInfo } from "./item-info/item-info.component";
import { Pagination } from "./pagination";

const PAGE_SIZE = 4;

export const Invoices = ({
  invoicesData,
  accountId,
  selectedCompany,
  openContestations,
  setOpenContestations,
}: IInvoicesProps) => {
  const [page, setPage] = useState(1);
  const [sliceIndexes, setSliceIndexes] = useState<number[] | undefined>();
  const [activeInvoiceReference, setActiveInvoiceReference] = useState<
    string | undefined
  >();
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<
    IInvoiceDetails | undefined
  >();
  const [isLoadingInvoiceDetails, setIsLoadingInvoiceDetails] = useState(false);

  const LoadingDetailsContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoLoadingDetailsContainer = useCallback(() => {
    const lastQuestionElement =
      LoadingDetailsContainerRef.current?.lastElementChild;
    lastQuestionElement?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const calcPageIndexes = useCallback((page: number) => {
    const firstIndexPage = (page - 1) * PAGE_SIZE;
    return [firstIndexPage, firstIndexPage + PAGE_SIZE];
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      setPage(page);
      const pageIndexes: number[] = calcPageIndexes(page);
      setSliceIndexes(pageIndexes);
    },
    [calcPageIndexes],
  );

  useEffect(() => {
    const currentInvoiceIndex = invoicesData.findIndex(
      (invoice) => invoice.isCurrent,
    );
    const initialPage = Math.floor(currentInvoiceIndex / PAGE_SIZE) + 1;
    setPage(initialPage);
    const pageIndexes: number[] = calcPageIndexes(initialPage);
    setSliceIndexes(pageIndexes);
  }, [invoicesData, calcPageIndexes]);

  const getActiveInvoiceIndex = (reference: string) => {
    const [pageIndex, dataIndex] = reference?.split("-");
    return (
      Number.parseInt(dataIndex) + PAGE_SIZE * (Number.parseInt(pageIndex) - 1)
    );
  };

  const loadInvoiceDetails = useCallback(
    async (invoiceIndex: number) => {
      setTimeout(() => scrollIntoLoadingDetailsContainer(), 500);
      const activeInvoiceData = invoicesData[invoiceIndex];
      try {
        setIsLoadingInvoiceDetails(true);
        if (!selectedCompany) return;
        const { data } = await client.get(
          endpoints.bankinghub.getInvoiceDetails(
            accountId,
            activeInvoiceData.invoiceId,
          ),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        setInvoiceDetailsData(data);
      } catch (error) {
        setActiveInvoiceReference(undefined);
        OToastManager.danger(
          "Erro ao carregar Detalhes da fatura, tente novamente",
        );
      } finally {
        setIsLoadingInvoiceDetails(false);
        setTimeout(() => scrollIntoLoadingDetailsContainer(), 500);
      }
    },
    [
      selectedCompany,
      accountId,
      invoicesData,
      scrollIntoLoadingDetailsContainer,
    ],
  );

  return (
    <>
      {page && sliceIndexes && (
        <div className="d-flex flex-column">
          {invoicesData ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between">
                {invoicesData
                  .slice(sliceIndexes[0], sliceIndexes[1])
                  .map((invoice, index) => {
                    return (
                      <InvoiceCard
                        isActive={activeInvoiceReference === `${page}-${index}`}
                        key={`invoice-card-pagination-${page}-${index}`}
                        onClick={async () => {
                          setActiveInvoiceReference(`${page}-${index}`);
                          await loadInvoiceDetails(
                            index + PAGE_SIZE * (page - 1),
                          );
                        }}
                        colorStatus={
                          MapperCycleStatus[invoice.cycleStatus].color
                        }
                      >
                        <InvoiceDate
                          id="invoice-date"
                          isActive={
                            activeInvoiceReference === `${page}-${index}`
                          }
                          colorStatus={
                            MapperCycleStatus[invoice.cycleStatus].color
                          }
                        >
                          {DayMonthMask(invoice.dueDate, "/")}
                        </InvoiceDate>
                        <InvoiceInfo>
                          {InvoiceCardInfo.map((info, index) => (
                            <ItemInfo
                              label={info.label}
                              value={MoneyMask(
                                invoice[info.key as keyof typeof invoice] as
                                  | string
                                  | number,
                                Currency.BRL,
                              )}
                              key={index}
                            />
                          ))}
                        </InvoiceInfo>
                        <InvoiceStatus
                          id="invoice-status"
                          isActive={
                            activeInvoiceReference === `${page}-${index}`
                          }
                          colorStatus={
                            MapperCycleStatus[invoice.cycleStatus].color
                          }
                        >
                          {MapperCycleStatus[invoice.cycleStatus].title}
                        </InvoiceStatus>
                      </InvoiceCard>
                    );
                  })}
              </div>

              <Pagination
                className="d-flex flex-row justify-content-center w-100"
                page={page}
                setPage={onPageChange}
                numPages={Math.ceil(invoicesData.length / PAGE_SIZE)}
              />
              {!isLoadingInvoiceDetails ? (
                activeInvoiceReference !== undefined &&
                invoiceDetailsData && (
                  <Details
                    activeInvoiceData={
                      invoicesData[
                        getActiveInvoiceIndex(activeInvoiceReference)
                      ]
                    }
                    setActiveInvoiceReference={setActiveInvoiceReference}
                    invoiceDetailsData={invoiceDetailsData}
                    accountId={accountId}
                    selectedCompany={selectedCompany}
                    openContestations={openContestations}
                    setOpenContestations={setOpenContestations}
                  />
                )
              ) : (
                <OLoader type="primary" absolute backdrop />
              )}
            </div>
          ) : (
            <NoDataFound className="gap-2">
              <OIcon category="fal" icon="fa-exclamation-circle" /> Falha ao
              carregar os dados de Faturas.
            </NoDataFound>
          )}
        </div>
      )}
    </>
  );
};
