import { getValueFromMap } from "utils/get-value-from-map";
import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import {
  documentTypeReverseMap,
  countryCodeReverseMap,
  filterValues,
  toBoolean,
} from "../../../../../../utils";
import { Sacado } from "../../upload-sacados.types";

export const parseSacadosSpreadsheet = (
  buffer: FileReader["result"],
): Sacado[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return filterValues({
      officialName: cleanRow.empresa,
      companyEntityType:
        getValueFromMap(
          documentTypeReverseMap,
          cleanRow["tipo de documento"],
        ) ?? cleanRow["tipo de documento"],
      identificacao: cleanRow["cnpj/cpf/offshore"]?.toString().trim(),
      identificadorExterno: cleanRow["identificador externo"]
        ?.toString()
        .trim(),
      cge: cleanRow.cge?.toString().trim(),
      estrategiaGUID: cleanRow.estrategia,
      convenioGUID: cleanRow.estrategia,
      permiteOverrideEstrategia: toBoolean(
        cleanRow["permite sobreescrita da estrategia"],
      ),
      tipoProduto: cleanRow["tipo de produto"],
      tipoContrato: "SemContrato",
      country:
        getValueFromMap(countryCodeReverseMap, cleanRow["país"]) ??
        cleanRow["país"],
    }) as Sacado;
  });
};
