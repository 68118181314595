export enum CollectionStatus {
  Canceled = "CANCELED",
  CancelFailed = "CANCEL_FAILED",
  Canceling = "CANCELING",
  Created = "CREATED",
  Expired = "EXPIRED",
  Failed = "FAILED",
  Overdue = "OVERDUE",
  Paid = "PAID",
  Processing = "PROCESSING",
  ProcessingPayment = "PROCESSING_PAYMENT",
  Updated = "UPDATED",
  UpdateFailed = "UPDATE_FAILED",
  Updating = "UPDATING",
}

export enum SectionId {
  Resume = "resume",
  Payee = "payee",
  Payer = "payer",
  Fees = "fees",
  Payment = "payment",
  Details = "detail",
}

export enum CollectionOrigin {
  Web = "WEB",
  Developers = "DEVELOPERS",
  App = "APP",
  CNAB = "CNAB",
  PaymentLink = "PAYMENT-LINK",
}

export enum CollectionType {
  BANKSLIP_QRCODE = "BANKSLIP_QRCODE",
  BANKSLIP = "BANKSLIP",
}

export enum InterestType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE_PER_MONTH = "PERCENTAGE_PER_MONTH",
  FIXED_VALUE_PER_MONTH = "FIXED_VALUE_PER_MONTH",
}

export enum FeesType {
  PERCENTAGE = "PERCENTAGE",
  FIXED_VALUE = "FIXED_VALUE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export interface DetailItem {
  title: string;
  content: string;
  textToCopy?: string;
}
