import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { RoleAssignmentsForm } from "../role-assignments.types";

export const useBasicFields = (editing: boolean | undefined) => {
  const [phoneDefaultValue, setPhoneDefaultValue] = useState("");
  const { callService: getUserData, loading: getUserDataLoading } =
    useServiceCall(service.onboardingRelationships.getUserData);

  const form = useFormContext<RoleAssignmentsForm>();

  const { clearErrors, setError, setValue, watch } = form;

  const taxIdWatcher = watch("taxId");

  const [disableEmail, setDisableEmail] = useState<boolean>(false);

  useEffect(() => {
    setPhoneDefaultValue("");
    if (!editing && validators.cpfCnpj(taxIdWatcher)) {
      getUserData({ taxId: taxIdWatcher }).then(({ success, response }) => {
        if (success) {
          setValue("name", response.data.name);
          setValue("email", response.data.email);
          setPhoneDefaultValue(response.data.phone);
          clearErrors("taxId");
          if (response.data?.email) setDisableEmail(true);
          else setDisableEmail(false);
        } else {
          setError("taxId", { message: "Não foi possível encontrar o CPF" });
        }
      });
    }
  }, [clearErrors, editing, getUserData, setError, setValue, taxIdWatcher]);

  return {
    phoneDefaultValue,
    getUserDataLoading,
    disableEmail,
  };
};
