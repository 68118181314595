import { OIcon, OLink } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse } from "services/admin-bankinghub/models";
import { format } from "utils/date";
import {
  formatDate,
  StatusComponent,
  StatusComponentProps,
} from "./statement-history.utils";

const buildDetailsLink = (
  traceability: GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number],
) => {
  const baseUrl =
    corporateRouter.routes.banking.product.cnab.search.details.path;
  const searchParams = new URLSearchParams();
  const stringifiedTraceability = JSON.stringify(traceability);
  searchParams.set("traceability", stringifiedTraceability);

  return baseUrl + "?" + searchParams.toString();
};

export const statementHistoryGrid: ODataGridGeneratorConfig<
  GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhum arquivo de extrato encontrado.",
    pager: {
      showInfo: true,
      visible: true,
      infoText: "Página {0} de {1} ({2} extratos)",
      showNavigationButtons: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.banking.product.cnabSearchDetailsPage.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
    },
    {
      dataField: "createdAt",
      caption: "Criado em",
      cellRender: ({ data }) => format.date(data.createdAt.value),
    },
    {
      dataField: "company",
      caption: "Empresa",
      cellRender: ({ data }) => data.company.name,
    },
    {
      dataField: "account.agency",
      caption: "Agência",
      cellRender: ({ data }) => data.account.agency,
    },
    {
      dataField: "account.account",
      caption: "Conta",
      cellRender: ({ data }) => data.account.account,
    },
    {
      caption: "Período",
      cellRender: ({ data }) => {
        const beginAt = formatDate(data.beginAt.value, "DD/MM/YYYY", 3);
        const endAt = formatDate(data.endAt.value, "DD/MM/YYYY", 3);

        return `${beginAt} -> ${endAt}`;
      },
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => (
        <StatusComponent
          status={data.status as StatusComponentProps["status"]}
        />
      ),
    },
    {
      caption: "Ações",
      cellRender: ({ data }) =>
        data.file?.data.url && (
          <OLink href={data.file.data.url}>
            <OIcon category="orq" icon="orq-download" className="me-2" />
            Download
          </OLink>
        ),
    },
  ],
};
