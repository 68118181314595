import { OUploadCustomEvent } from "@maestro/core";
import { OCol, ORFieldInput, ORFieldUploadInput, ORow } from "@maestro/react";

interface OfferFormProps {
  onAddFile: (e: OUploadCustomEvent<File>) => void;
}

export const OfferForm = ({ onAddFile }: OfferFormProps) => (
  <form spellCheck="false">
    <ORow>
      <OCol sm={4}>
        <ORFieldInput
          id="title"
          name="title"
          label="Título"
          labelSize="md"
          tag="text"
          dataAction="form-oferta:texto:titulo"
          dataLabel="titulo"
          placeholder="Preencher"
        />
      </OCol>
      <OCol sm={4}>
        <ORFieldInput
          id="subtitle"
          name="subtitle"
          label="Subtítulo"
          labelSize="md"
          tag="text"
          dataAction="form-oferta:texto:subtitulo"
          dataLabel="subtitulo"
          placeholder="Preencher"
        />
      </OCol>
      <OCol sm={4}>
        <ORFieldInput
          id="label"
          name="label"
          label="Label"
          labelSize="md"
          tag="text"
          dataAction="form-oferta:texto:label"
          dataLabel="label"
          placeholder="Preencher"
        />
      </OCol>
    </ORow>
    <ORow>
      <OCol>
        <ORFieldInput
          id="description"
          name="description"
          label="Descrição"
          labelSize="md"
          tag="text"
          dataAction="form-oferta:texto:descricao"
          dataLabel="descricao"
          placeholder="Preencher"
        />
      </OCol>
    </ORow>
    <ORow>
      <OCol>
        <div className="w-100">
          <ORFieldUploadInput
            id="file"
            name="file"
            label="Imagem"
            labelSize="md"
            dataAction="form-oferta:texto:imagem"
            dataLabel="imagem"
            inputLabel="Selecionar imagem"
            accept=".jpeg,.jpg,.png"
            multiple={false}
            onAddFile={onAddFile}
          />
        </div>
      </OCol>
    </ORow>
  </form>
);
