import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

export const proposalDocumentsGrid = {
  datagrid: {
    noDataText: "Nenhum documento encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      caption: "Nome",
      dataField: "name",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.b2bAgro.getProposalDocuments>
  >["data"]["data"][number]
>;
