import { OToastManager } from "@maestro/core";
import { OInputDate } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../../../../../../../../../../../../utils";

export const buildDataSource = (
  property: HubEnergy.DiligenceStepPropertyResponse,
) =>
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceBillSimpleResponse>(
    () => Promise.resolve(property.bills),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            const { data } = await service.hubEnergy.upsertBill({
              diligenceStepPropertyId: property.id,
              diligenceBillId: null,
              date: values.date,
              kWh: values.kWh,
            });

            property.bills = data.response.bills;
            return data.response.bills[0]; // doesnt matter
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Não foi possível adicionar o item";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (oldValues, changes) => {
          try {
            const newValues = { ...oldValues, ...changes };

            const { data } = await service.hubEnergy.upsertBill({
              diligenceStepPropertyId: property.id,
              diligenceBillId: oldValues.id,
              date: newValues.date,
              kWh: newValues.kWh,
            });

            property.bills = data.response.bills;
            return data.response.bills[0]; // doesnt matter
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Não foi possível editar o item";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async (values) => {
          try {
            const { data } = await service.hubEnergy.deleteBill(values.id);

            property.bills = data.response.bills;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Não foi possível remover o item";

            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const energyBillsGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceBillSimpleResponse> =
  {
    datagrid: {
      noDataText: "Nenhum item",
      pager: { showPageSizeSelector: true, allowedPageSizes: [6, 12, 24, 36] },
      stateStoring: gridStorage("mle-energyBills"),
      editing: {
        allowUpdating: true,
        allowDeleting: true,
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "date",
        dataType: "date",
        format: "MMMM y",
        caption: "Data",
        editCellRender: ({ setValue }) => (
          <OInputDate
            size="md"
            picker="month"
            onChange={(date) =>
              setValue(date?.startOf("month").add("d", 1).toISOString())
            }
            aspect="unstyled"
            className="p-2"
          />
        ),
        sortOrder: "asc",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "kWh",
        dataType: "string",
        format: (value) => parseFloat(value).toFixed(4),
        caption: "kWh",
        validationRules: [{ type: "required" }],
      },
    ],
    paging: {
      defaultPageSize: 6,
    },
  };
