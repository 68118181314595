import {
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORFieldUploadInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { ContractTemplateDetailsFormValues } from "./contract-template-details-form.schemas";
import { SigningPartyForm } from "./signing-party-form.component";
import { signingOriginMap } from "./contract-templates.utils";

interface ContractTemplateDetailsFormProps {
  onDownloadFile?: (file: File) => void;
}

export const ContractTemplateDetailsForm = ({
  onDownloadFile,
}: ContractTemplateDetailsFormProps) => {
  const { watch } = useFormContext<ContractTemplateDetailsFormValues>();

  const watchFile = watch("file");

  return (
    <>
      <ORow>
        <OCol>
          <ORFieldInput
            tag="text"
            id="name"
            name="name"
            label="Nome do template"
          />
        </OCol>
        <OCol>
          <ORFieldSelect
            dataAction="recebiveis_template_contrato:select:origem_assinatura"
            dataLabel="origem_assinatura"
            id="signatureOrigin"
            name="signatureOrigin"
            label="Plataforma de assinatura"
            tooltip="Assinatura via Docusign funciona apenas por ICP e não aceita grupos de assinatura, todos os assinantes precisam assinar."
            placeholder="Selecione"
          >
            {Object.entries(signingOriginMap).map(([key, value]) => (
              <OOption key={key} value={key}>
                {value}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>

      <ORFieldUploadInput
        id="file"
        name="file"
        inputLabel="Escolher arquivo"
        accept="*"
        label="Arquivo"
        disabled={!!watchFile?.length}
        handleAddFile={(event, resolve) => resolve(event.detail)}
        handleRemove={(file, resolve) => resolve(file)}
        handleDownload={onDownloadFile}
      />

      <ORow className="mt-4">
        <OCol>
          <ORFieldCheckboxGroup id="needsSignature" name="needsSignature">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="sm" id="chk1" value="true" />
              <OLabel htmlFor="chk1">Assinatura na plataforma</OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol>
          <ORFieldCheckboxGroup id="geraNovosTermos" name="geraNovosTermos">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="sm" id="chk2" value="true" />
              <OLabel htmlFor="chk2">Gera novos termos</OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
        <OCol>
          <ORFieldCheckboxGroup id="hasComplement" name="hasComplement">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="sm" id="chk3" value="true" />
              <OLabel htmlFor="chk3">Possui complemento?</OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
      </ORow>

      <OTypography type="dark" size="lg">
        Signing parties
      </OTypography>

      <OTypography type="dark" className="py-2">
        Cedente
      </OTypography>

      <SigningPartyForm name="signingParties.cedente" mode="Cedente" />

      <OTypography type="dark" className="py-2">
        Sacado
      </OTypography>

      <SigningPartyForm name="signingParties.sacado" mode="Sacado" />
    </>
  );
};
