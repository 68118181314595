import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { service } from "services";
import { logger } from "utils/logger";
import { getValueFromMap } from "utils/get-value-from-map";
import { isAxiosError } from "axios";
import { OToastManager } from "@maestro/core";
import {
  FileDetails,
  FileProcessor,
  FileStatus,
  FileSummary,
  PaymentStatus,
  ProcessablePaymentsInfo,
} from "./supplier-payments.type";

export const fileStatusMap = {
  FAILED_TRANSLATING: {
    label: "Pagamentos inválidos",
    type: "danger" as const,
  },

  FAILED_PROCESSING: {
    label: "Pagamentos inválidos",
    type: "danger" as const,
  },

  ALL_PAYMENTS_REJECTED: {
    label: "Pagamentos rejeitados",
    type: "danger" as const,
  },

  FAILED: { label: "Pagamentos inválidos", type: "danger" as const },

  FAILED_AUTHORIZE: {
    label: "Pagamentos inválidos",
    type: "danger" as const,
  },

  REJECTED_FILE: { label: "Pagamentos inválidos", type: "danger" as const },

  PROCESSING: { label: "Em processamento", type: "info" as const },

  AUTHORIZED_FILE: { label: "Aprovado", type: "info" as const },

  WAITING_UPLOAD: { label: "Em validação", type: "info" as const },

  PROCESSED: { label: "Pagamentos processados", type: "success" as const },

  PARTIALLY_PROCESSED: {
    label: "Processado com falhas",
    type: "warning" as const,
  },

  TRANSLATED: { label: "Em validação", type: "info" as const },

  TRANSLATING: { label: "Em validação", type: "info" as const },

  ALREADY_PROCESSED: {
    label: "Processado anteriormente",
    type: "info" as const,
  },

  WAITING_AUTHORIZE: {
    label: "Em validação",
    type: "info" as const,
  },
};

const paymentStatusMap = {
  CANCELED: "CANCELADO",
  CREATED: "ACEITO",
  FAILED: "FALHOU",
  PAID: "PAGO",
  REJECTED: "REJEITADO",
  REVERTED: "REVERTIDO",
  SCHEDULED: "AGENDADO",
  TO_CANCEL: "PROCESSANDO",
  TO_UPDATE: "PROCESSANDO",
};

const paymentEntityMap = {
  BANKSLIP: "BOLETO",
  TED: "TED",
  PIX: "PIX",
  DARF: "DARF",
  TAXSLIP: "DARF/BOLETO",
};

const exportXlsx = (
  fileName: string,
  wsName: string,
  headers: Array<string | number>,
  xlsxData: Array<string | number>[],
  colWidths?: number[],
  footer: Array<string | number> = [],
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...xlsxData, footer]);

  if (colWidths) {
    ws["!cols"] = colWidths.map((colWidth) => ({ wch: colWidth }));
  }
  XLSX.utils.book_append_sheet(wb, ws, wsName);
  XLSX.writeFile(wb, fileName);
};

const getAllPaymentsFromFile = async (
  fileId: string,
  filter: string,
  totalRecords: number,
  pageSize = 250,
) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const promises = Array.from(Array(totalPages)).map((_, i) =>
    service.adminBankinghub.getFileDetails(fileId, {
      filter,
      isCSV: false,
      pageNumber: i + 1,
      pageSize,
    }),
  );

  const resolvedPromises = await Promise.all(promises);

  const payments = resolvedPromises.flatMap(
    (fileDetailsResponse) =>
      fileDetailsResponse.data.payments as FileDetails["payments"],
  );

  return payments;
};

export const downloadPaymentsMonthlyReport = (
  files: FileProcessor[],
  fileName = "relatorio_mensal_pagamentos_ao_fornecedor.xlsx",
) => {
  const wsName = "pagamentos_fornecedor";
  const headers = ["DATA", "PROCESSADO R$", "NÃO PROCESSADO R$", "TAXA"];
  const colWidths = [15, 20, 20, 8];

  const xlsxData = files
    .sort(
      (a, b) =>
        new Date(a.createdAt ?? "").getTime() -
        new Date(b.createdAt ?? "").getTime(),
    )
    .map(({ data, createdAt }) => [
      dayjs(createdAt).format("DD/MM/YYYY"),
      data?.fileSummary?.totalAmount,
      data?.fileSummary?.ineligibleTotalAmount,
      "",
    ]);

  const amountsSum = files.reduce(
    (prev, { data }) => {
      const totalAmount = data?.fileSummary?.totalAmount || 0;
      const ineligibleTotalAmount =
        data?.fileSummary?.ineligibleTotalAmount || 0;

      return {
        totalAmount: prev.totalAmount + totalAmount,
        ineligibleTotalAmount:
          prev.ineligibleTotalAmount + ineligibleTotalAmount,
      };
    },
    { totalAmount: 0, ineligibleTotalAmount: 0 },
  );

  const footer = [
    "Total",
    amountsSum.totalAmount,
    amountsSum.ineligibleTotalAmount,
    "",
  ];

  exportXlsx(fileName, wsName, headers, xlsxData, colWidths, footer);
};

export const downloadPaymentsReport = async (
  fileId: string,
  totalRecords: number,
  filter: string,
  fileName = "relatorio_pagamentos.xlsx",
) => {
  try {
    OToastManager.info("Seu relatório está sendo preparado para download");

    const payments = await getAllPaymentsFromFile(fileId, filter, totalRecords);
    const xlsxData = payments.map((payment) => [
      payment.paymentDate,
      getValueFromMap(paymentStatusMap, payment.status) ?? payment.status,
      getValueFromMap(paymentEntityMap, payment.entity) ?? payment.entity,
      payment.amount,
      payment.payerAgency,
      payment.payerAccount,
      payment.beneficiaryName,
      payment.beneficiaryDocument,
      payment.beneficiaryBank,
      payment.beneficiaryAgency,
      payment.beneficiaryAccount,
    ]);

    const wsName = "pagamentos";
    const headers = [
      "DATA DO PAGAMENTO",
      "STATUS",
      "FORMA DE PAGAMENTO",
      "VALOR R$",
      "AGÊNCIA DO PAGADOR",
      "CONTA DO PAGADOR",
      "NOME DO BENEFICIÁRIO",
      "DOCUMENTO DO BENEFICIÁRIO",
      "BANCO DO BENEFICIÁRIO",
      "AGÊNCIA DO BENEFICIÁRIO",
      "CONTA DO BENEFICIÁRIO",
    ];
    const colWidths = [20, 15, 20, 10, 20, 20, 30, 28, 24, 24, 20];
    exportXlsx(fileName, wsName, headers, xlsxData, colWidths);
  } catch (err) {
    if (!isAxiosError(err)) logger.error(err);
    OToastManager.danger("Não foi possível fazer o download");
  }
};

export const getProcessableValuesFromFileSummary = (
  fileSummary: FileSummary,
) => {
  const processedStatus: PaymentStatus[] = ["CREATED", "SCHEDULED", "PAID"];
  const notProcessedStatus: PaymentStatus[] = [
    "REJECTED",
    "REVERTED",
    "FAILED",
  ];
  const { statusDistribution, totalRecords, totalAmount } = fileSummary;

  const initialValues: ProcessablePaymentsInfo = {
    processed: {
      amount: 0,
      records: 0,
    },
    notProcessed: {
      amount: 0,
      records: 0,
    },
    totalRecords,
    totalAmount,
    status: "PROCESSED",
  };

  if (!statusDistribution) return initialValues;

  const processableValues = statusDistribution?.reduce(
    (result, statusDistributionItem) => {
      const { status, amount, records } = statusDistributionItem;
      if (processedStatus.includes(status)) {
        Object.assign(result, {
          ...result,
          processed: {
            amount: result.processed.amount + amount,
            records: result.processed.records + records,
          },
        });
      }

      if (notProcessedStatus.includes(status)) {
        Object.assign(result, {
          ...result,
          notProcessed: {
            amount: result.notProcessed.amount + amount,
            records: result.notProcessed.records + records,
          },
        });
      }

      return result;
    },
    initialValues,
  );

  const { processed, notProcessed } = processableValues;
  const isPartiallyProcessed = processed.amount && notProcessed.amount;
  const isAllFailed = !processed.amount && notProcessed.amount;

  let status: FileStatus = "PROCESSED";

  if (isPartiallyProcessed) {
    status = "PARTIALLY_PROCESSED";
  } else if (isAllFailed) {
    status = "ALL_PAYMENTS_REJECTED";
  }

  return { ...processableValues, status };
};

export const getPaymentsGridFileStatus = (data: FileProcessor) => {
  const overridableStatus = [
    "FAILED_PROCESSING",
    "WAITING_AUTHORIZE",
    "PROCESSING",
  ];
  const { fileSummary } = data.data;
  const { status } =
    fileSummary?.statusDistribution && !overridableStatus.includes(data.status)
      ? getProcessableValuesFromFileSummary(fileSummary)
      : data;

  return status;
};
