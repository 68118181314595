import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORow,
} from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { BaseStepFields } from "./base-step-fields.component";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";

interface GeneralStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const GeneralStep = ({ fieldName, index }: GeneralStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(`${fieldName}.${index}._t`, "GeneralPartnerConfigurationStep");
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(`${fieldName}.${index}.stepId`, `general-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="GENERAL"
        gridSize={4}
      />
      <OCol xs={4} align="center">
        <ORFieldCheckboxGroup
          dataAction={`partner_configuration_scf:checkbox:${fieldName}_${index}_is_fidc`}
          dataLabel={`${fieldName}_${index}_is_fidc`}
          id={`${fieldName}.${index}.isFidc`}
          name={`${fieldName}.${index}.isFidc`}
        >
          <div className="d-flex align-items-center gap-2 mb-2">
            <OCheckbox
              size="xs"
              id={`${fieldName}.${index}.isFidcCheckbox`}
              value="true"
            />
            <OLabel
              htmlFor={`${fieldName}.${index}.isFidcCheckbox`}
              id={`${fieldName}.${index}.isFidcLabel`}
            >
              É de FIDC?
            </OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
