import { modalManager, OButton, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useEditProcessor } from "../../edit-processor.context";
import { OUTPUT_MODAL_ID } from "../output-modal/output-modal.component";
import { outputsGrid } from "./outputs-grid.grid";

export const OutputsGrid = () => {
  const {
    processor: { value: processor },

    setSelectedOutput,
  } = useEditProcessor();

  const { show } = modalManager;

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between align-items-center">
        <OTypography tag="h2" weight="500" size="lg">
          Saídas
        </OTypography>
        <OButton
          dataAction="editar_processador:botao:adicionar_saida"
          dataLabel="adicionar_saida"
          onClick={() => {
            setSelectedOutput(undefined);
            show(OUTPUT_MODAL_ID);
          }}
        >
          Adicionar
        </OButton>
      </div>
      <ODataGridGenerator grid={outputsGrid} dataSource={processor?.outputs} />
    </div>
  );
};
