import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { OpenAccountsMasterDetail } from "./open-accounts.master-detail";

export const buildDataSource = (uuid: string) =>
  dataSourceCustomStoreGenerator<HubOnboarding.Onboarding.OnboardingOpenAccounts>(
    () =>
      service.onboarding
        .getAccountsByWorkflow(uuid)
        .then(({ data }) => data)
        .catch(() => {
          const errorMessage = "Não foi possível buscar as contas solicitadas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
  );

export const openAccountsGrid: ODataGridGeneratorConfig<HubOnboarding.Onboarding.OnboardingOpenAccounts> =
  {
    datagrid: {
      noDataText: "Nenhuma conta solicitada",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: {
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        dataField: "agency",
        dataType: "number",
        caption: "Agência",
      },
      {
        dataField: "accountNumber",
        dataType: "string",
        caption: "Conta",
        cellRender: ({ data }) => {
          return data.accountNumber
            ? data.accountNumber
            : "Onboarding em andamento para conta ser aberta";
        },
      },
      {
        dataField: "type",
        dataType: "string",
        caption: "Tipo",
      },
      {
        dataField: "segmentGroup.segment.name",
        dataType: "string",
        caption: "Segmento",
      },
      {
        dataField: "segmentGroup.coSegment.name",
        dataType: "string",
        caption: "CoSegmento",
        cellRender: ({ data }) =>
          data.segmentGroup.coSegment.code +
          " - " +
          data.segmentGroup.coSegment.name,
      },
      {
        dataField: "officer.cge",
        caption: "Cge do Officer",
      },
      {
        dataField: "officer.name",
        dataType: "string",
        caption: "Nome do Officer",
      },
      {
        dataField: "isPrimary",
        dataType: "boolean",
        caption: "É uma conta requisitada pelo canal de origem?",
      },
      {
        dataField: "description",
        dataType: "string",
        caption: "Descrição",
      },
    ],
    masterDetail: {
      enabled: true,
      component: OpenAccountsMasterDetail,
    },
  };
