interface StringProp {
  stringArray: string[];
}

export const StringList = ({ stringArray }: StringProp) => {
  return (
    <div className="position-relative">
      {stringArray?.map((value) => {
        return <li key={value}>{value}</li>;
      })}
    </div>
  );
};