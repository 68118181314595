import { validators } from "@maestro/utils";
import * as yup from "yup";
import { RoleAssignmentsForm } from "../../../components/role-assignments";

export const addUserDefaultValues = {
  sendEmail: ["check"],
};

export const addUserValidationSchema = yup.object<
  RoleAssignmentsForm,
  yup.ObjectSchema<RoleAssignmentsForm>["fields"]
>({
  email: yup
    .string()
    .required("Campo obrigatório")
    .email("Campo deve ser um e-mail"),
  phoneNumber: yup.string().required("Campo obrigatório"),
  taxId: yup
    .string()
    .required("Campo obrigatório")
    .test("taxId", "CPF inválido", validators.cpf),
  name: yup.string().required("Campo obrigatório"),
  sendEmail: yup.array().required("Campo obrigatório"),
  roles: yup
    .array()
    .required("Campo obrigatório")
    .min(1, "É necessário selecionar ao menos uma função."),
});
