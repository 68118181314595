import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { ContractTemplate } from "./company-contract-templates.types";
import { GridActions } from "./_compose";

export const buildDataSource = (companyId: string | number) =>
  dataSourceCustomStoreGenerator<ContractTemplate>(() =>
    service.quickfin
      .getCompanyContractTemplates(companyId)
      .then(({ data }) => data.filter((template) => !template.isPublic))
      .catch(() => {
        throw new Error("Erro ao buscar templates de contrato");
      }),
  );

export const companyContractTemplatesGrid: ODataGridGeneratorConfig<ContractTemplate> =
  {
    datagrid: {
      noDataText: "Nenhum template cadastrado.",
      filterRow: { visible: true },
      filterSyncEnabled: true,
    },
    columns: [
      { dataField: "name", caption: "Nome" },
      { dataField: "file.fileKey", caption: "Identificação" },
      { dataField: "isDefault", caption: "Padrão" },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <GridActions
            template={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
      },
    ],
  };
