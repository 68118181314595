import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import dayjs from "dayjs";
import { service } from "services";
import { Curve } from "./taxas-calculator.types";

export const buildDataSource = (estrategiaUuid: string, currency: string) =>
  dataSourceCustomStoreGenerator<Curve>(() =>
    service.senna
      .getTaxasByEstrategia(estrategiaUuid, currency)
      .then(({ data }) => data.curva)
      .catch(() => {
        const errorMessage = "Erro ao buscar a curva";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const curveGrid: ODataGridGeneratorConfig<Curve> = {
  datagrid: {
    noDataText: "Nenhuma taxa",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "dataBase",
      dataType: "date",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      sortIndex: 0,
      sortOrder: "asc",
      caption: "Data base",
    },
    {
      dataField: "dataFutura",
      dataType: "date",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      sortIndex: 1,
      sortOrder: "asc",
      caption: "Data futura",
    },
    {
      dataField: "txDi",
      caption: "tx %Di",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "diMais",
      caption: "DI +",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "spread",
      caption: "Spread",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "fatorDesconto",
      caption: "Fator Desconto",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preAaLin",
      caption: "Pré aa lin",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preExpDuAa",
      caption: "Pré exp du aa",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preExpDuAm",
      caption: "Pré exp du am",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preExpDcAa",
      caption: "Pré exp dc aa",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preExpDcAm",
      caption: "Pré exp dc am",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preAmEfetivaLin",
      caption: "Pré am efetiva lin",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "preAmSimplesLin",
      caption: "Pré am simples lin",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "rebate",
      caption: "Rebate",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "rebateOverTxDi",
      caption: "% Rebate Over TX DI+",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "taxaAoPeriodo",
      caption: "Taxa ao período",
      format: { type: "percent", precision: 4 },
    },
  ],
};
