import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { validators } from "@maestro/utils";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./energy-invite.form";
import { FormValuesType } from "./energy-invite.types";

export const useEnergyCompaniesInvite = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit, watch, setError } = form;

  const watchTaxId = watch("taxId");

  const getSemaphore = useCallback(async () => {
    try {
      setLoading(true);

      const payload = {
        taxId: watchTaxId,
      };

      const { data } = await service.onboarding.getSemaphore(payload);

      if (data.semaphore === "GREEN") {
        const resetValues = {
          taxId: data.taxId,
          officialName: data.name,
          status: data.statusRFB,
          relationshipType: data.product.relationshipType,
          product: data.product.description,
        };

        reset(resetValues);
      }

      if (data.semaphore === "RED") {
        setError("taxId", { message: "Entidade já está cadastrada" });
      }
    } catch (err) {
      OToastManager.danger(
        "Não foi possível encontrar o CPF/CNPJ na Receita Federal!",
      );
    } finally {
      setLoading(false);
    }
  }, [reset, setError, watchTaxId]);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const payload = {
        invite: {
          taxId: values.taxId,
          name: values.officialName,
          notifier: {
            email: values.email,
          },
        },
      };

      await service.onboarding.sendEnergyInvite(payload);

      OToastManager.success("Convite enviado com sucesso");
      navigate(routes.empresas.product.energy.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (watchTaxId && validators.cnpj(watchTaxId)) {
      getSemaphore();
    }
  }, [getSemaphore, watchTaxId]);

  return { form, submit, loading, setLoading };
};
