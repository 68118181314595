import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { cardIconMap } from "../../../../../../../../utils";
import { Trade } from "./operation-details.types";

export const tradesGrid: ODataGridGeneratorConfig<Trade> = {
  datagrid: {
    noDataText: "Nenhum trade.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "receivable.receivableStatus",
      caption: "Status",
    },
    {
      dataField: "receivable.rejectMessage",
      caption: "Erro",
      allowHeaderFiltering: false,
    },
    {
      dataField: "receivable.identification",
      caption: "Identificação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "receivable.sacado.officialName",
      caption: "Sacado",
      allowHeaderFiltering: false,
      width: 200,
    },
    {
      dataField: "receivable.sacado.identification",
      caption: "Sacado CNPJ",
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "receivable.cedente.officialName",
      caption: "Cedente",
      allowHeaderFiltering: false,
      width: 200,
    },
    {
      dataField: "receivable.cedente.identification",
      caption: "Cedente CNPJ",
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "receivable.bandeira",
      caption: "Bandeira",
      allowHeaderFiltering: true,
      cellRender: ({ data }) => {
        const icon =
          getValueFromMap(cardIconMap, data.receivable.bandeira) ||
          data.receivable.bandeira;

        return icon;
      },
    },
    {
      dataField: "receivable.maturityDate",
      dataType: "datetime",
      format: "shortDate",
      caption: "Vencimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "tradeMaturityDate",
      dataType: "datetime",
      format: "shortDate",
      caption: "Vencimento alongado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "receivable.currency",
      caption: "Moeda",
    },
    {
      dataField: "valorSolicitado",
      caption: "Valor solicitado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturityAmount",
      caption: "Valor de vencimento",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "price",
      caption: "Valor desembolsado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "taxaMensal",
      caption: "Taxa mensal",
      format: { type: "percent", precision: 4 },
      allowHeaderFiltering: false,
    },
    {
      dataField: "taxaTotal",
      caption: "Taxa total",
      format: { type: "percent", precision: 4 },
      allowHeaderFiltering: false,
    },
  ],
};
