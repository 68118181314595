import { ColumnGap } from "../../cards.styles";
import { Description } from "./description/description.component";
import { IDetailsProps } from "./details.types";
import { Resume } from "./resume/resume.component";
import { Subtitle } from "./subtitle/subtitle.component";
import { Title } from "./title/title.component";
import { Transactions } from "./transactions/transactions.component";

export const Details = ({
  activeInvoiceData,
  setActiveInvoiceReference,
  invoiceDetailsData,
  accountId,
  selectedCompany,
  openContestations,
  setOpenContestations,
}: IDetailsProps) => {
  return (
    <ColumnGap rowGap={30}>
      <Title
        activeInvoiceData={activeInvoiceData}
        invoiceDetailsData={invoiceDetailsData}
        setActiveInvoiceReference={setActiveInvoiceReference}
        accountId={accountId}
        selectedCompany={selectedCompany}
      />
      <Subtitle invoiceDetailsData={invoiceDetailsData} />
      <Description activeInvoiceData={activeInvoiceData} />
      <Resume summaryData={invoiceDetailsData.summary} />
      <Transactions
        accountId={accountId}
        invoiceId={activeInvoiceData.invoiceId}
        selectedCompany={selectedCompany}
        openContestations={openContestations}
        setOpenContestations={setOpenContestations}
      />
    </ColumnGap>
  );
};
