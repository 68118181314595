import { client } from "../client";
import { validatorEndpoints } from "./validator.endpoints";
import { GetAllowAllResponse, GetBlockAllResponse } from "./models/responses";
import { PostBlockListBody } from "./models/requests/post-block-list.request";
import { PutBlockListBody } from "./models/requests/put-block-list.request";
import { PutAllowListBody } from "./models/requests/put-allow-list.request";
import { PostIntubateListBody } from "./models/requests/post-intubate-list.request";
import { PostAllowListBody } from "./models/requests/post-allow-list.request";

export const validatorService = {
  getAllowList(
    params: Validator.BaseParams = {
      Limit: 5,
      Offset: 0,
    },
  ) {
    return client.get<GetAllowAllResponse>(
      validatorEndpoints.elegibilidade.allow,
      {
        params,
      },
    );
  },
  getBlockList(
    params: Validator.BaseParams = {
      Limit: 5,
      Offset: 0,
    },
  ) {
    return client.get<GetBlockAllResponse>(
      validatorEndpoints.elegibilidade.block,
      {
        params,
      },
    );
  },
  intubateBlockList(body: PostIntubateListBody) {
    return client.post(validatorEndpoints.elegibilidade.addBlock, body);
  },
  intubateAllowList(body: PostIntubateListBody) {
    return client.post(validatorEndpoints.elegibilidade.addAllow, body);
  },
  addBlockList(body: PostBlockListBody) {
    return client.post(validatorEndpoints.elegibilidade.addBlock, body);
  },
  addAllowList(body: PostAllowListBody) {
    return client.post(validatorEndpoints.elegibilidade.addAllow, body);
  },
  EditAllow(Id: string, body: PutAllowListBody) {
    return client.put(validatorEndpoints.elegibilidade.editAllow(Id), body);
  },
  EditBlock(Id: string, body: PutBlockListBody) {
    return client.put(validatorEndpoints.elegibilidade.editBlock(Id), body);
  },
  DeleteAllow(Id: string) {
    return client.delete(validatorEndpoints.elegibilidade.editAllow(Id));
  },
  DeleteBlock(Id: string) {
    return client.delete(validatorEndpoints.elegibilidade.editBlock(Id));
  },
};
