import { OButton } from "@maestro/react";
import styled from "styled-components";

export const OTextButton = styled(OButton)<{
  textDecoration?: "underline" | "none";
  padding?: string;
}>`
  button {
    padding: ${(props) => props.padding ?? "0px 5px"};
    text-decoration: ${(props) => props.textDecoration ?? "underline"};
  }
`;
