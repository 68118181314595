import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GatekeeperProfilesGetDetails } from "services/gatekeeper/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./details.grid";

export const GatekeeperProfilesDetails = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const [profileName, setProfileName] = useState<string>();

  const loadProfileName = useCallback(async () => {
    const { data } = await service.gatekeeper.getProfileDetails({
      profiles: [{ uuid }],
    });

    setProfileName(data[0].name);
  }, []);

  useEffect(() => {
    loadProfileName();
  }, [loadProfileName]);

  const gridRef = useRef<DataGrid>(null);

  const dataSource =
    dataSourceCustomStoreGenerator<GatekeeperProfilesGetDetails>(
      () =>
        service.gatekeeper
          .getProfileDetailsFromProfile({ profileName: profileName ?? "" })
          .then(({ data }) => data)
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar detalhes";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      {
        customStoreOptions: {
          update: async (original, updated) => {
            try {
              const payload = {
                ...original,
                ...updated,
                groupId: original.group.id,
              };

              await service.gatekeeper.updateProfileDetails(payload);
              OToastManager.success("Detalhe editado com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível editar o detalhe");
            }
          },
          remove: async (data) => {
            try {
              const payload = {
                id: data.id,
              };

              await service.gatekeeper.deleteProfileDetails(payload);
              OToastManager.success("Detalhe removido com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível removido o detalhe");
            }
          },
        },
      },
    );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Descrições detalhadas do profile"
          description={`Visualize os detalhes do profile ${profileName}`}
        />
      }
      actions={
        <RouterButton
          dataLabel="details"
          href={corporateRouter.routes.ferramentas.product.gatekeeper.profiles.profile.details.add.path(
            { uuid: uuid },
          )}
          role={roles.ferramentas.product.gatekeeperProfilesDetailsPage.role}
          dataAction="details:botao:adicionar"
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
