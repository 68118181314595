import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useStateMachineDetails } from "./state-machine.hook";
import { options } from "./state-machine.utils";

export const StateMachineDetails = () => {
  const { form, loading, submit } = useStateMachineDetails();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Máquina de Estados" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="maquina_estados:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldSelect
                dataAction="maquina_estados:select:status"
                dataLabel="status"
                id="status"
                name="status"
                label="Status"
                placeholder="Selecione"
              >
                {options.map((option) => (
                  <OOption key={option} value={option}>
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
