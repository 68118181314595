import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { UpdateStrategiesModal } from "../_compose";
import { OnboardingStrategiesForm } from "../_compose/formulario";
import { useEditOnboardingStrategies } from "./edit-onboarding-strategy.hook";

export const EditOnboardingStrategies = () => {
  const { form, loading, strategyId, onSubmit } = useEditOnboardingStrategies();

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Editar estratégia" />}
        actions={
          <LoadingButton
            loading={loading}
            dataAction="estrategias_onboarding:botao:salvar"
            dataLabel="salvar"
            onClick={onSubmit}
          >
            Salvar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <OnboardingStrategiesForm />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <UpdateStrategiesModal strategyId={strategyId} />
    </>
  );
};
