import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { GetPayrollListResponse } from "services/fopa";
import { PayrollStatus } from "./payment-details.type";

export const payrollStatusMap: Record<string, PayrollStatus> = {
  AGENDADO: {
    label: "AGENDADO",
    type: "info",
  },
  VALIDANDO: {
    label: "EM PROCESSAMENTO",
    type: "warning",
  },
  INICIADA: {
    label: "EM PROCESSAMENTO",
    type: "warning",
  },
  TEF_DEBITO_CONCLUIDO: {
    label: "EM PROCESSAMENTO",
    type: "warning",
  },
  CONTA_BTG_VALIDADA: {
    label: "EM PROCESSAMENTO",
    type: "warning",
  },
  PORTABILIDADE_INICIADA: {
    label: "EM PROCESSAMENTO",
    type: "warning",
  },
  CONCLUIDA: {
    label: "REALIZADO",
    type: "success",
  },
  PORTABILIDADE_CONCLUIDA: {
    label: "REALIZADO",
    type: "success",
  },
  PORTABILIDADE_DEVOLVIDA: {
    label: "REALIZADO",
    type: "success",
  },
  CANCELADO: {
    label: "CANCELADO",
    type: "danger",
  },
  ERRO_CONTA_BTG: {
    label: "ERRO NO PROCESSAMENTO",
    type: "danger",
  },
  ERRO_TEF: {
    label: "ERRO NO PROCESSAMENTO",
    type: "danger",
  },
  ERRO_TED: {
    label: "ERRO NO PROCESSAMENTO",
    type: "danger",
  },
};

export const fields = (
  data: GetPayrollListResponse["dashboardList"][number],
): DetailsFields => ({
  about: {
    items: [
      {
        label: "Natureza",
        value: data.tipoDePagamento,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Referência",
        value: data.lote,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Solicitante",
        value: data.solicitante,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  dates: {
    title: "Datas importantes",
    items: [
      {
        label: "Data de Envio",
        value: data.dataRecebimento,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Data de Débito",
        value: data.dataDebito,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Data de Crédito",
        value: data.dataCredito,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  informations: {
    title: "Informações",
    items: [
      {
        label: "Valor pago",
        value: masks.currency(data.valorPago, "R$", "."),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Status",
        value: data.status,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  error: {
    title: "Error",
    items: [
      {
        label: "Mensagem de erro",
        value: data.mensagemDeErro,
        grid: { md: 4, xl: 8 },
      },
    ],
    visible: !!data.mensagemDeErro,
  },
});
