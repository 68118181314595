import { IntervalCountSectionFilter } from "../../../../side-filter-bar.types";

export type IntervalCountProps = IntervalCountSectionFilter;

export enum OInputCountId {
  MIN_COUNT = "min-count",
  MAX_COUNT = "max-count",
}

export enum OInputCountLabel {
  MIN_COUNT = "Mínimo",
  MAX_COUNT = "Máximo",
}
