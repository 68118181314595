import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { validationSchema } from "../_compose/notificacoes-form/notification-form.form";
import { NotificationFormValuesType } from "../_compose/notificacoes-form/notification-form.types";

export const useRavenNotificationTypeAdd = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<NotificationFormValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        "legal_representative?": values["legalRepresentative?"] === "true",
        rendered_name: values.renderedName,
      };

      await service.raven.addNotificationType(payload);

      form.resetField("tenant");
      form.reset();

      OToastManager.success("Tipo de notificação adicionado com sucesso");
    } catch (err) {
      OToastManager.danger("Erro ao criar o tipo de notificação");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
