export const empresasRouteParams = {
  identification: "identification",
} as const;

type EmpresasParams = Record<keyof typeof empresasRouteParams, string>;

export type EmpresasClienteByIdentificationRouteParams = Pick<
  EmpresasParams,
  "identification"
>;
