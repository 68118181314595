import {
  modalManager,
  OButton,
  OIcon,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  OUpload,
  OUploadInput,
  OUploadLabel,
  OUploadTip,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { FileField } from "../file-field";

interface FilesModalProps {
  documentIndex: number;
  fields: Record<"id", string>[];
  handleAddFile: (file: File) => void;
  handleRemoveFile: (index: number) => void;
}

export const FilesModal = ({
  documentIndex,
  fields,
  handleAddFile,
  handleRemoveFile,
}: FilesModalProps) => {
  const form = useFormContext();
  const { getValues } = form;

  return (
    <OModal id={`document-${documentIndex}-files-modal`} position="center">
      <OModalHeader>
        <OTypography>{`Arquivos do documento ${getValues(
          `documents.${documentIndex}.type`,
        )}`}</OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <OUpload onAddFile={(e) => handleAddFile(e.detail)}>
            <OUploadInput
              id={`documents.${documentIndex}.addFile`}
              name={`documents.${documentIndex}.addFile`}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx"
            />
            <OUploadLabel
              htmlFor={`documents.${documentIndex}.addFile`}
              size="md"
            >
              Selecionar documento
              <OIcon category="orq" icon="orq-upload" size="lg" />
            </OUploadLabel>
            <OUploadTip>
              Serão aceitos documentos no formato .jpg, .jpeg, .png, .gif, .bmp,
              .pdf ou .doc/.docx
            </OUploadTip>
          </OUpload>
          {fields.map((field, fileIndex) => (
            <FileField
              documentIndex={documentIndex}
              index={fileIndex}
              fileKey={field.id}
              key={field.id}
              removeFile={handleRemoveFile}
            />
          ))}
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            type="dark"
            outline
            onClick={() => {
              modalManager.hide(`document-${documentIndex}-files-modal`);
            }}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
