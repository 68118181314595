import { env } from "utils/environments";

export const hubfxEndpoints = {
  countries: `${env.API_FX}/api/admin/country/approved`,
  payerTypes: `${env.API_FX}/api/admin/common/payer-types`,
  currency: `${env.API_FX}/api/currency`,
  admin: {
    quote: {
      cancel: `${env.API_FX}/api/admin/quote/cancel`,
    },
    orderConciliation: `${env.API_FX}/api/admin/order-conciliation/availables`,
    quoteById: (quoteId: number) => `${env.API_FX}/api/admin/quote/${quoteId}`,
    orderById: (orderId: string) => `${env.API_FX}/api/admin/order/${orderId}`,
    limitById: (limitId: string) => `${env.API_FX}/api/admin/limit/${limitId}`,
    beneficiaryById: (beneficiaryId: string) =>
      `${env.API_FX}/api/admin/beneficiary?id=${beneficiaryId}`,
    analysisById: (analysisId: string) =>
      `${env.API_FX}/api/admin/beneficiary/analysis?id=${analysisId}`,
    counterparty: `${env.API_FX}/api/admin/counterparty`,
    counterpartyById: (counterpartyId: string) =>
      `${env.API_FX}/api/admin/counterparty/${counterpartyId}`,
    counterpartyEnablePreTicket: `${env.API_FX}/api/admin/counterparty/enable-pre-ticket`,
    counterpartyDefaultAmounts: `${env.API_FX}/api/admin/counterparty/default-amounts`,
    counterpartyVerifyForthcomingCancellation: `${env.API_FX}/api/admin/counterparty/verify-forthcoming-cancellation`,
    common: `${env.API_FX}/api/admin/common`,
    bank: `${env.API_FX}/api/admin/bank`,
    currencies: `${env.API_FX}/api/admin/currency`,
    orders: `${env.API_FX}/api/admin/order`,
    pretickets: `${env.API_FX}/api/admin/preticket`,
    countries: `${env.API_FX}/api/admin/country`,
    beneficiaries: `${env.API_FX}/api/admin/beneficiary/analysis`,
    incorrectOrders: {
      endpoint: `${env.API_FX}/api/admin/incorrect-orders`,
      setActive: `${env.API_FX}/api/admin/incorrect-orders/set-active`,
    },
    customer: {
      incorrectOrders: `${env.API_FX}/api/admin/customer/incorrect-orders`,
      quote: `${env.API_FX}/api/admin/customer/quote`,
      quoteById: (quoteId: number) =>
        `${env.API_FX}/api/admin/customer/quote/${quoteId}`,
      order: `${env.API_FX}/api/admin/customer/order`,
      orderById: (orderId: string) =>
        `${env.API_FX}/api/admin/customer/order/${orderId}`,
      preTicket: `${env.API_FX}/api/admin/customer/pre-ticket`,
      preTicketById: (preTicketId: string) =>
        `${env.API_FX}/api/admin/customer/pre-ticket/${preTicketId}`,
      limit: `${env.API_FX}/api/admin/customer/limit`,
      limitById: (limitId: string) =>
        `${env.API_FX}/api/admin/customer/limit/${limitId}`,
    },
    nature: {
      endpoint: `${env.API_FX}/api/admin/nature`,
      byId: (id: string | number) => `${env.API_FX}/api/admin/nature/${id}`,
      documentGroups: {
        endpoint: `${env.API_FX}/api/admin/nature/document-groups`,
        byId: (id: string | number) =>
          `${env.API_FX}/api/admin/nature/document-groups/${id}`,
      },
      documentConfigs: {
        endpoint: `${env.API_FX}/api/admin/nature/document-configs`,
        byId: (id: string | number) =>
          `${env.API_FX}/api/admin/nature/document-configs/${id}`,
      },
      genericFieldTypes: {
        endpoint: `${env.API_FX}/api/admin/nature/generic-field-types`,
        byId: (id: string | number) =>
          `${env.API_FX}/api/admin/nature/generic-field-types/${id}`,
      },
    },
  },
  preticket: {
    byChannel: {
      get: (channel: string) => `${env.API_FX}/api/preticket/${channel}/get`,
    },
  },
  byChannel: {
    endpoint: (channel: string) => `${env.API_FX}/api/${channel}/dashboard`,
    dashboard: {
      endpoint: (channel: string) =>
        `${env.API_FX}/api/v2/${channel}/dashboard`,
      fluctuations: (channel: string) =>
        `${env.API_FX}/api/v2/${channel}/dashboard/fluctuations`,
    },
  },
  odata: {
    adminBanksOData: `${env.API_FX}/odata/AdminBanksOData`,
    adminCounterpartiesOData: `${env.API_FX}/odata/AdminCounterpartiesOData`,
    adminLimitsOData: `${env.API_FX}/odata/AdminLimitsOData`,
    adminBeneficiaryAnalysesOData: `${env.API_FX}/odata/AdminBeneficiaryAnalysesOData`,
    adminBeneficiariesOData: `${env.API_FX}/odata/AdminBeneficiariesOData`,
    adminCurrenciesOData: `${env.API_FX}/api/AdminCurrenciesOData`,
    adminCountriesOData: `${env.API_FX}/api/AdminCountriesOData`,
  },
  channelOdata: {
    channelOperationsOData: `${env.API_FX}/channel-odata/ChannelOperationsODataV2(Channel='admin')`,
    channelOrdersOData: `${env.API_FX}/channel-odata/ChannelOrdersODataV2(Channel='admin')`,
    channelPreTicketsOData: `${env.API_FX}/channel-odata/ChannelPreTicketsODataV2(Channel='admin')`,
  },
  file: {
    receipt: `${env.API_FX}/api/file/receipt`,
    contract: `${env.API_FX}/api/file/contract`,
    download: `${env.API_FX}/api/file/download`,
  },
};
