import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoProcessorWorkflowConfigById } from "../../../../../../routes/workflow.route-params";
import { processorConfigGrid } from "./processor-config.grid";

export const ProcessorConfigByWorkflowIdPage = () => {
  const { id } = useParams<WorkflowProdutoProcessorWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const processorWorkflowConfig = processorConfigGrid(id);
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Processadores Configurados" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={processorWorkflowConfig} />
    </GridTemplate>
  );
};
