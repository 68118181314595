import { OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { Group, Power } from "./company-acts.types";
import { RepresentativesMasterDetail } from "./representatives.master-detail";

const grid: ODataGridGeneratorConfig<Group> = {
  datagrid: {
    noDataText: "Nenhum grupo",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} grupos)",
    },
    paging: {
      pageSize: 10,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "groupTypeId", caption: "Tipo do grupo" },
    {
      dataField: "groupName",
      caption: "Nome do grupo",
    },
    {
      dataField: "amountSignature",
      caption: "Número de assinaturas",
    },
    {
      dataField: "usersInCompany",
      caption: "Usuários cadastrados",
    },
  ],
  masterDetail: {
    enabled: true,
    component: RepresentativesMasterDetail,
  },
};

type GroupsMasterDetailProps = MasterDetailComponentProps<Power>;

export const GroupsMasterDetail = ({ data }: GroupsMasterDetailProps) => {
  const {
    data: { groups },
  } = data;

  return groups?.length ? (
    <div>
      <OTypography type="dark" size="lg" className="mb-3">
        Grupos
      </OTypography>
      <ODataGridGenerator grid={grid} dataSource={groups} />
    </div>
  ) : (
    <RepresentativesMasterDetail data={data} />
  );
};
