import { OOption, OSelect } from "@maestro/react";
import { useEffect, useState } from "react";

const currencies = ["BRL", "USD", "EUR"];

interface CurrencySelectorProps {
  selectedCurrency: string;
  setSelectedCurrency: (selected: string) => void;
}

export const CurrencySelector = ({
  selectedCurrency,
  setSelectedCurrency,
}: CurrencySelectorProps) => {
  const [innerValue, setInnerValue] = useState<string>();

  // workaround to handle select bug
  useEffect(() => {
    setInnerValue(selectedCurrency);
  }, [selectedCurrency]);

  return (
    <div className="me-auto">
      <OSelect
        dataAction="taxas:select:seletor-moeda"
        dataLabel="seletor-moeda"
        value={innerValue}
        style={{
          width: "200px",
        }}
        onInput={(evt) =>
          setSelectedCurrency(
            (evt.currentTarget as HTMLOSelectElement).value as string,
          )
        }
      >
        {currencies.map((currency) => (
          <OOption key={currency} value={currency} size="sm">
            {currency}
          </OOption>
        ))}
      </OSelect>
    </div>
  );
};
