import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { Notifications } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";
import { LogsMasterDetail } from "./notification-workflow.master-detail";

export const grid = (
  refetch: () => Promise<unknown>,
  reprocessNotificationStatus: (params: {
    notificationUuid: string;
  }) => Promise<unknown>,
  changeStatusNotificationStatus: (params: {
    notificationUuid: string;
  }) => Promise<unknown>,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum Notificação",
      pager: {
        infoText: "Página {0} de {1} ({2} notificação)",
      },
      wordWrapEnabled: true,
      editing: {
        allowUpdating: true,
        mode: "row",
        selectTextOnEditStart: true,
      },
      onRowUpdated: async (row) => {
        await changeStatusNotificationStatus({
          notificationUuid: row.data.uuid,
        });
        refetch();
      },
    },
    columns: [
      {
        dataField: "description",
        caption: "Descrição",
        allowEditing: false,
      },
      {
        dataField: "run",
        caption: "Sucesso",
      },
      {
        dataField: "statusNotification",
        caption: "Status para notificar",
        allowEditing: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <IconButton
            disabled={data.run}
            icon={{ category: "orq", icon: "orq-refresh" }}
            type="default"
            outline
            onClick={async () => {
              await reprocessNotificationStatus({
                notificationUuid: data.uuid,
              });
              refetch();
            }}
          >
            Reprocessar
          </IconButton>
        ),
      },
    ],
    masterDetail: {
      enabled: true,
      component: (data) => <LogsMasterDetail items={data} />,
    },
  } as ODataGridGeneratorConfig<Notifications>;
};
