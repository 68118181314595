import { OConfirmationModal } from "@maestro/react";
import { ClearGridFiltersButton } from "components/clear-grid-filters-button";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { useOnboardingStrategies } from "./onboarding-strategies.hook";

export const OnboardingStrategies = () => {
  const { clearFilter, dataSource, deleteOnboarding, grid, gridRef, loading } =
    useOnboardingStrategies();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Estratégias de Onboarding"
          description="Gerenciamento de estratégias de onboarding"
        />
      }
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.recebiveis.product.onboardingStrategies.add
                .path
            }
            role={roles.recebiveis.product.onboardingStrategiesAddPage.role}
          >
            Adicionar
          </StandardLink>
          <ClearGridFiltersButton onClick={() => clearFilter()} />
        </>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        loading={loading}
        grid={grid}
        dataSource={dataSource}
      />
      <OConfirmationModal
        modalId="delete-onboarding-modal"
        title="Tem certeza que deseja eliminar este registro?"
        onConfirm={deleteOnboarding}
      />
    </GridTemplate>
  );
};
