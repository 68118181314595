import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByTradeIdRouteParams } from "../../../../../routes/energia.route-params";

export const useEnergyTrade = () => {
  const { tradeId } = useParams<MleByTradeIdRouteParams>();
  if (!tradeId) throw new Error("No tradeId");

  const { callService, error, hasError, loading, value } = useServiceCall(
    service.hubEnergy.getTradeDetails,
  );

  const getTrade = useCallback(
    async () => callService(tradeId),
    [callService, tradeId],
  );

  useEffect(() => {
    getTrade();
  }, [getTrade]);

  return {
    error,
    getTrade,
    hasError,
    loading,
    value,
  };
};
