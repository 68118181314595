import { OCheckbox, OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { AutoRelocationItem } from "./auto-relocation.types";

export const dataSource = dataSourceCustomStoreGenerator<AutoRelocationItem>(
  (loadOptions) =>
    service.recebiveisCartao
      .getAutoRelocations(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar as realocações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const autoRelocationGrid: ODataGridGeneratorConfig<AutoRelocationItem> =
  {
    datagrid: {
      noDataText: "Nenhuma empresa.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      pager: {
        allowedPageSizes: [20, 40, 100, 500],
        showPageSizeSelector: true,
      },
      remoteOperations: true,
    },
    columns: [
      {
        alignment: "center",
        caption: "Selecionar",
        cellRender: ({ data }) => (
          <OCheckbox
            id={String(data.company_id)}
            size="xs"
            value={String(data.company_id)}
            className="d-inline-block"
          />
        ),
        allowExporting: false,
      },
      {
        dataField: "company_id",
        dataType: "number",
        caption: "ID",
        allowHeaderFiltering: false,
      },
      {
        dataField: "company_name",
        dataType: "string",
        caption: "Empresa",
        allowHeaderFiltering: false,
      },
      {
        dataField: "company_tax_id",
        dataType: "string",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "realocar",
        dataType: "boolean",
        caption: "Realocação automática",
      },
      {
        dataField: "periodo",
        dataType: "string",
        caption: "Período",
      },
      {
        dataField: "horario",
        dataType: "string",
        caption: "Horário",
        allowHeaderFiltering: false,
      },
    ],
  };
