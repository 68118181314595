import {
  OButton,
  ODivider,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { BankslipEventDetail } from "./_compose/bankslip-event-detail";
import { BankslipEventsGroup } from "./_compose/bankslip-events-group";
import { useBankslipEvents } from "./bankslip-events-modal.hook";
import { BankslipEventsModalId } from "./bankslip-events-modal.types";

export const BankslipEventsModal = () => {
  const { bankslipEvents, isLoading } = useBankslipEvents();

  return (
    <OModal id={BankslipEventsModalId} position="center" size="md">
      <OModalHeader closeButton>
        <OTypography type="dark" size="lg">
          Eventos do Boleto
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column gap-3">
          {isLoading ? (
            <>
              <BankslipEventDetail isLoading />
              <ODivider />
              <BankslipEventDetail isLoading />
              <ODivider />
              <BankslipEventDetail isLoading />
            </>
          ) : (
            <BankslipEventsGroup events={bankslipEvents} />
          )}
        </div>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex justify-content-end">
          <OButton onClick={() => modalManager.hide(BankslipEventsModalId)}>
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
