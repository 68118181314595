import * as yup from "yup";

export interface AddBookFormValues {
  bookName: string;
  bookCr: number;
}

export const addBookFormValidationSchema = yup.object().shape({
  bookName: yup.string().required("Campo obrigatório"),
  bookCr: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "O valor mínimo é 0."),
});

export const addBookFormDefaultValues = {
  bookName: "",
  bookCr: NaN,
};
