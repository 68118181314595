export const DOTS = -1;

export const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => index + start);
};

export interface CustomPaginationProps {
  totalCount: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  pagination: number[];
  selectedPaginationSize: number;
  setSelectedPaginationSize: (paginationSize: number) => void;
  loading?: boolean;
}
