import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MoveFileForm } from "../../move-file-modal.form";
import { GetDiligenceDocumentsReturn } from "./diligence-documents-selector.types";

export const useDiligenceDocumentSelector = (
  consumerUnitId: number,
  getDocumentsCallService: GetDiligenceDocumentsReturn["callService"],
  getDocumentsLoading: GetDiligenceDocumentsReturn["loading"],
  getDocumentsValue: GetDiligenceDocumentsReturn["value"],
  getDocumentsHasError: GetDiligenceDocumentsReturn["hasError"],
) => {
  const { clearErrors, setError } = useFormContext<MoveFileForm>();

  const getDiligenceDocuments = useCallback(
    () => getDocumentsCallService({ consumerUnitId }),
    [consumerUnitId, getDocumentsCallService],
  );

  useEffect(() => {
    getDiligenceDocuments();
  }, [getDiligenceDocuments]);

  useEffect(() => {
    if (getDocumentsHasError)
      setError("destinationDocumentId", {
        message:
          "Erro ao buscar os documentos. Clique no botão ao lado para tentar novamente.",
      });
    else clearErrors("destinationDocumentId");
  }, [clearErrors, getDocumentsHasError, setError]);

  const documents = useMemo(
    () =>
      getDocumentsValue?.response
        ?.filter((property) => !!property.documentTemplate)
        ?.sort((a, b) => a.step.type.name.localeCompare(b.step.type.name))
        ?.map<SelectSearchFieldOption<number>>((property) => {
          const stepTypeName = property.step.type.name;
          const documentTypeName = property.documentTemplate.type.label;
          const numberOfFiles =
            (property.document?.files?.length ?? 0) +
            (property.documentTemplate?.files?.length ?? 0);
          return {
            label: `${stepTypeName} | ${documentTypeName} | ${numberOfFiles} arquivo(s)`,
            value: property.documentTemplate.id,
            customKey: property.documentTemplate.id,
          };
        }) ?? [],
    [getDocumentsValue?.response],
  );

  return {
    documents,
    getDiligenceDocuments,
    loading: getDocumentsLoading,
    hasFetchError: getDocumentsHasError,
  };
};
