import { env } from "utils/environments";

export const sharkEndpoints = {
  v1: {
    notifications: {
      messagesCountPerMonth: `${env.API_SHARK}/v1/notifications/messages-count-per-month`,
      operations: `${env.API_SHARK}/v1/notifications/operations`,
      messages: `${env.API_SHARK}/v1/notifications/messages`,
      users: `${env.API_SHARK}/v1/notifications/users`,
      republish: `${env.API_SHARK}/v1/notifications/republish`,
      createOperation: `${env.API_SHARK}/v1/notifications/operations/create`,
      attachments: `${env.API_SHARK}/v1/notifications/attachments`,
    },
    bankslips: {
      getByTradeId: `${env.API_SHARK}/v1/bankslips/trade-id`,
      sendViaEmail: `${env.API_SHARK}/v1/bankslips/send-via-email`,
      download: `${env.API_SHARK}/v1/bankslips/download/trade-id`,
    },
    calendar: {
      nextBusinessDays: (delta: number | string) =>
        `${env.API_SHARK}/v1/calendar/next-business-days?business_days=${delta}`,
    },
    prepayments: {
      getSettlement: (contractNumber: string) =>
        `${env.API_SHARK}/v1/prepayments/${encodeURIComponent(
          contractNumber,
        )}/balance`,
      simulateSettlement: (contractNumber: string) =>
        `${env.API_SHARK}/v1/prepayments/${encodeURIComponent(
          contractNumber,
        )}/simulate`,
      confirmSettlement: (contractNumber: string) =>
        `${env.API_SHARK}/v1/prepayments/${encodeURIComponent(
          contractNumber,
        )}/confirm`,
    },
  },
};
