import { MappedStatus } from "./status-maps.types";

export const pendencyStatusMap = {
  CRIADA: { type: "info", text: "Criada" },
  AGUARDANDO_DEPENDENCIA: { type: "info", text: "Aguardando dependência" },
  PENDENTE_CLIENTE: { type: "info", text: "Pendente cliente" },
  PENDENTE_OPS: { type: "warning", text: "Pendente OPS" },
  PENDENTE_BPO: { type: "warning", text: "Pendente BPO" },
  EM_AUTOMACAO: { type: "info", text: "Em automação" }, // automation
  AGUARDANDO_DISTRIBUIDORA: { type: "info", text: "Aguardando distribuidora" },
  CONCLUIDA: { type: "success", text: "Concluída" },
  CANCELADA: { type: "danger", text: "Cancelada" },
} satisfies Record<HubEnergy.EDiligenceStepPendencyStatus, MappedStatus>;

export const pendencyAutomationStatusMap = {
  EM_PREPARACAO: { type: "info", text: "Em preparação" },
  PENDENTE_EXECUCAO: { type: "warning", text: "Pendente execução" },
  EXECUTANDO: { type: "info", text: "Executando" },
  CONCLUIDA: { type: "success", text: "Concluída" },
  CANCELADA: { type: "danger", text: "Cancelada" },
} satisfies Record<HubEnergy.EAutomationStatus, MappedStatus>;
