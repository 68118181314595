import { OToastManager, modalManager } from "@maestro/react";
import { useState } from "react";
import { service } from "services";
import {
  FloatingSettingsModalFormFields,
  FloatingSettingsModalId,
} from "./floating-settings-modal.types";

export const useUpsertFloating = () => {
  const [isLoading, setIsLoading] = useState(false);

  const upsertFloating = async (
    formValues: FloatingSettingsModalFormFields,
    isCreating: boolean,
    handleSuccess: () => void,
  ) => {
    try {
      setIsLoading(true);
      if (isCreating) {
        await service.adminBankinghub.collection.createFloating(formValues);
      } else {
        await service.adminBankinghub.collection.updateFloating(formValues);
      }
      OToastManager.success(
        `Floating ${isCreating ? "cadastrado" : "atualizado"} com sucesso`,
      );
      modalManager.hide(FloatingSettingsModalId);
      handleSuccess();
    } catch {
      OToastManager.danger(
        `Ocorreu um erro para ${
          isCreating ? "cadastrar" : "atualizar"
        } o Floating do beneficiário.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { upsertFloating, isLoading };
};
