import { OLoader, ORFieldUploadInput } from "@maestro/react";
import { ErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { UploadContainer } from "../../../../components";
import { useImportAgendas } from "./import-agendas.hook";

export const ImportAgendas = () => {
  const { form, handleAddFile, hasError, loading } = useImportAgendas();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Importação manual de agendas CIP" />}
    >
      <CardTemplate>
        <div>
          <FormProvider {...form}>
            <UploadContainer>
              <ORFieldUploadInput
                id="agenda"
                name="agenda"
                inputLabel="Clique ou arraste um arquivo .XML para importar agendas"
                accept="text/xml"
                dataAction="importacao-agendas:upload:xml"
                dataLabel="importacao-agendas"
                multiple={false}
                handleAddFile={handleAddFile}
                disabled={loading}
                value={[]}
              />
            </UploadContainer>
          </FormProvider>
          <div className="d-flex flex-column align-items-center">
            {loading && <OLoader />}
            {hasError && (
              <ErrorComponent
                messageTitle="Não foi possível fazer o upload do arquivo"
                messageParagraph="Tente novamente"
              />
            )}
          </div>
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
