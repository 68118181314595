import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { validationSchema } from "../_compose/group-form/group-form.form";
import { FormValuesType } from "../_compose/group-form/group-form.types";

export const useGroupsAdd = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [companyGroup, setCompanyGroup] = useState<
    CompanyGroupAssociationsItem[]
  >([]);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        companyGroupAssociations: companyGroup,
      };

      await service.onboarding.registerGroupAndAssociations(payload);

      OToastManager.success("Grupo alterado com sucesso");

      navigate(routes.empresas.product.groups.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    setCompanyGroup,
    submit,
  };
};
