export interface PatchTemplateEstrategiaComercialBody {
  modoAtualizacaoVinculadas: UpdateStrategyTemplateChildrenOptions;
  estrategiaComercial: EstrategiaComercial;
  estrategiaJson: string;
}
interface EstrategiaComercial {
  id: number;
  nome: string;
  modeloDeTaxa: string;
  exigeAprovacaoBanco: boolean;
  prazoLimiteOperacao: number;
  instrumentCodeId: number;

  horarioLimiteOperacao?: string;
  tipoOnboarding?: string;
  biometryRequired?: boolean;
  configuracoesCadastroRelacionamento?: ConfiguracoesCadastroRelacionamento;
  pnlBook?: null | number;
  pnlStrategy?: null | number;
  penaltyFee?: null | number;
  interestArrearsRate?: null | number;
  contaLiquidacaoId?: null;

  approvalGroups?: string[];
  fccIdSacado?: number | null;
  exigeIndicacaoHedge?: boolean;
  exigeAprovacaoSacado?: boolean;
  reporteDiarioSacado?: boolean;
  diasParaVencimentoSacado?: number[];
  emailsSacadoNotification?: string[];
  marketplaceId?: number;
  portfolioId: number;
  creditorId?: number;
  toCredit: boolean;
  tipoContrato?: string;
  contractTemplateId?: number;
  cessaoOffshore?: boolean;
  reporteDiarioRiscoCedente?: boolean;
  diasParaVencimentoDataRepasseRiscoCedente?: unknown[];
  habilitaPagFor?: boolean;
  habilitaPagForBanking?: boolean;
  comandoOperacao?: string;
  aprovacaoAposAntecipacaoSacado?: boolean;
  permiteTaxaParticular?: boolean;
  tipoTaxaParticular?: string;
  sacadoDefineContaDesembolso?: boolean;
  cancelaNaoAntecipadasD0?: boolean;
  visualizaRebateSacado?: boolean;
  rebateRebarbaSacado?: boolean;
  rebateAlongaPrazo?: boolean;
  visibilidadePrazoVencimentoCedente?: string;
  rebateTolerance?: number;
  sendErrorCallback?: boolean;
  collateralRequired?: boolean;
  collateralIdentification?: string;
  notificacaoOnboardingSacado?: boolean;
  notificacaoTedDevolvidaSacado?: boolean;
  notaDisponivelCedente?: boolean;
  reporteNotasNaoAntecipadasCedente?: boolean;
  diasCampanhaNotasNaoAntecipadasCedente?: number[];
  notificacaoRecebiveisAntecipados?: boolean;
  notificacaoTedDevolvidaCedente?: boolean;
  needsFilesForReceivables?: boolean;
  filesForReceivables?: string[];
  needsFilesForOperation?: boolean;
  filesForOperation?: string[];
  needsRegistrationInfo?: boolean;
  sefazValidation?: boolean;
  typeFIDC?: string;
  eligibilityType?: string;
  worksiteEnvConfig?: string;
  fundConfig?: string;
  fundRelatedName?: string;
  habilitaAntecipacoesParciais?: boolean;
  riscoOperacao?: string;
  requiresPMEAccount?: boolean;
  linkTaggeado?: string[];
  idContaDeRetirada?: string;
  interestArrearsBase?: string;
  hasGuarantors?: boolean;
  hasPayerValidation?: boolean;
  gracePeriod?: number;
}
interface ConfiguracoesCadastroRelacionamento {
  contractTemplateId?: number;
}

export enum UpdateStrategyTemplateChildrenOptions {
  ONLY_DIRTY = "OnlyDirty",
  ALL_FIELDS = "AllFields",
  NOT_UPDATE = "NotUpdate",
}