import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../../../routes/ferramentas.route-params";
import { DeskAccountConfigRelationshipForm } from "../_compose/desk-account-config-relationship-form.component";
import {
  DeskAccountConfigRelationshipFormValues,
  deskAccountConfigRelationshipFormValidationSchema,
} from "../_compose/desk-account-config-relationship.form";

export const RegisterDeskAccountConfigRelationshipPage = () => {
  const { uuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const form = useForm<DeskAccountConfigRelationshipFormValues>({
    resolver: yupResolver(deskAccountConfigRelationshipFormValidationSchema),
  });
  const { handleSubmit } = form;

  const {
    callService: getDeskOnboardingConfigAccountConfigRelationships,
    value: currentAccountConfigRelationships,
  } = useServiceCall(
    service.onboarding.getDeskOnboardingAccountConfigRelationships,
  );
  const {
    callService: registerDeskOnboardingAccountConfigRelationships,
    loading,
  } = useServiceCall(
    service.onboarding.registerDeskOnboardingAccountConfigRelationships,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } =
          await registerDeskOnboardingAccountConfigRelationships({
            ...values,
            deskOnboardingAccountConfigUuid: uuid,
          });

        if (!success)
          return OToastManager.danger("Erro ao criar relacionamento");

        return OToastManager.success("Relacionamento criada com sucesso");
      }),
    [handleSubmit, registerDeskOnboardingAccountConfigRelationships, uuid],
  );

  useEffect(() => {
    getDeskOnboardingConfigAccountConfigRelationships(uuid);
  }, [getDeskOnboardingConfigAccountConfigRelationships, uuid]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Adicionar relacionamentos"
          description={`Agência: ${currentAccountConfigRelationships?.deskOnboardingAccountInfoAgency} | Conta: ${currentAccountConfigRelationships?.deskOnboardingAccountInfoAccountType} | Configuração: ${configIdentifier}`}
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskAccountConfigRelationshipForm />
      </FormProvider>
    </FormTemplate>
  );
};
