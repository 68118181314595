import {
  OButton,
  OCheckbox,
  OCol,
  OLabel,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldInputDatetime,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { StepRenderProps } from "../../banner-form.types";
import { BannerFormFields } from "../../../banner-management-form.schema";

export const Campaign = ({
  accessRules,
  setStep,
  updateBanner,
}: StepRenderProps) => {
  const { watch, setValue, setError, clearErrors, getValues } =
    useFormContext();

  const values = getValues();

  const isActiveWatch = watch("isActive");
  const startDate = watch("startingFrom");
  const endDate = watch("endingAt");

  const handleConfirm = useCallback(() => {
    if ((!startDate && !!endDate) || (!endDate && !!startDate)) {
      setError("startingFrom", {
        message: "Preencha a data de início e a final ou nenhuma delas.",
      });
      return;
    }

    const parsedStartDate = dayjs(startDate);
    const parsedEndDate = dayjs(endDate);

    if (dayjs(parsedEndDate).isBefore(parsedStartDate)) {
      setError("startingFrom", {
        message: "A data de início deve ser anterior a data final.",
      });
      return;
    }

    clearErrors();
    setStep && setStep("RESUMO");
    updateBanner && updateBanner(values as BannerFormFields);
  }, [
    startDate,
    endDate,
    clearErrors,
    setStep,
    updateBanner,
    values,
    setError,
  ]);

  return (
    <form spellCheck="false">
      <CardTemplate>
        <ORow>
          <OCol sm={6}>
            <ORFieldInput
              id="internalName"
              name="internalName"
              label="Nome da campanha"
              labelSize="md"
              tag="text"
              description="Para uso interno"
            />
          </OCol>
          {!!accessRules?.length && (
            <OCol>
              <ORFieldSelectMultiple
                label="Público-alvo"
                name="accessRules"
                id="accessRules"
                value={values.accessRules}
                labelSize="md"
                required
              >
                {accessRules?.map((rule) => (
                  <OOptionMultiple key={rule.name} value={rule.name}>
                    {rule.name}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </OCol>
          )}
        </ORow>
        <ORow>
          <OCol sm={6}>
            <ORFieldInputDatetime
              id="startingFrom"
              name="startingFrom"
              label="Data de início"
              labelSize="md"
            />
          </OCol>
          <OCol>
            <ORFieldInputDatetime
              label="Data final"
              name="endingAt"
              labelSize="md"
              id="endingAt"
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol sm={6}>
            <ORFieldCheckboxGroup name="isActive" id="isActive">
              <div className="d-flex align-items-center gap-2">
                <OCheckbox
                  id="isActive-checkbox"
                  value="true"
                  size="sm"
                  onInput={() => {
                    setValue("isActive", isActiveWatch.length ? ["true"] : []);
                  }}
                />
                <OLabel htmlFor="isActive-checkbox">Está ativo?</OLabel>
              </div>
            </ORFieldCheckboxGroup>
          </OCol>
        </ORow>
      </CardTemplate>

      <div className="d-flex justify-content-end mt-4 gap-2">
        <OButton
          type="dark"
          outline
          onClick={() => setStep && setStep("CRIACAO")}
        >
          Voltar
        </OButton>

        <OButton type="tertiary" onClick={() => handleConfirm()}>
          Continuar
        </OButton>
      </div>
    </form>
  );
};
