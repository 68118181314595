import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  TestConditionForm,
  TestConditionFormValues,
} from "../../../../../components/conditions/test-condition-form/test-condition-form.component";
import { testConditionValidationFormSchema } from "../../../../../components/conditions/test-condition-form/test-condition-form.schemas";
import {
  WorkflowProdutoCondicoesById,
  WorkflowProdutoCondicoesDetalhesRouteQueryParms,
} from "../../../../../routes/workflow.route-params";

export const TestConditionPage = () => {
  const { id } = useParams<WorkflowProdutoCondicoesById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoCondicoesDetalhesRouteQueryParms>(
      "data",
    );
  const form = useForm<TestConditionFormValues>({
    resolver: yupResolver(testConditionValidationFormSchema),
  });

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      value={value}
      render={(condition) => (
        <FormTemplate pageTitle={<PageTitle title="Testar condição" />}>
          <FormProvider {...form}>
            <TestConditionForm condition={condition} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};
