export const hiredOffersStatusMap = {
  CREATED: { label: "Disponível", type: "info" as const },
  HIRED: { label: "Contratado", type: "success" as const },
  EXPIRED: { label: "Expirado", type: "danger" as const },
  CANCELED: { label: "Cancelado", type: "danger" as const },
  PENDING_SIGNATURE: {
    label: "Assinatura pendente",
    type: "warning" as const,
  },
  PENDING_GUARANTORS_ONBOARD: {
    label: "Pendente devedores solidários ",
    type: "warning" as const,
  },
};
