import {
  ODataGridGeneratorConfig,
  blockDisabledRows,
  disableRows,
} from "components/data-grid";
import { banksList } from "utils/bank-list";
import { getValueFromMap } from "utils/get-value-from-map";
import { GridActions } from "./_compose";
import { Account } from "./account-close.types";
import { statusMap } from "./account-close.utils";

const isDisabled = ({ isAvailableToClose }: Account) => !isAvailableToClose;

export const buildAccountsGrid = (
  setIds: (ids: number[]) => void,
  filter: boolean,
): ODataGridGeneratorConfig<Account> => ({
  datagrid: {
    noDataText: "Nenhuma conta",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    onEditorPreparing: (evt) => disableRows(evt, isDisabled),
    onSelectionChanged: (evt) => {
      blockDisabledRows(evt, isDisabled);
      setIds(evt.component.getSelectedRowsData()?.map(({ id }) => id) ?? []);
    },
  },
  columns: [
    {
      dataField: "bankCode",
      dataType: "string",
      caption: "Banco",
      calculateCellValue: ({ bankCode }) => {
        const bankName = banksList.find((bank) => bank.id === bankCode)?.title;

        if (bankName) return `${bankName} - ${bankCode}`;
        return bankCode;
      },
    },
    {
      dataField: "agencyNumber",
      dataType: "string",
      caption: "Agência",
    },
    {
      dataField: "accountNumber",
      dataType: "string",
      caption: "Conta",
      allowHeaderFiltering: false,
    },
    {
      dataField: "openingDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de abertura",
      allowHeaderFiltering: false,
    },
    {
      dataField: "closeDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data do encerramento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
      customizeText: ({ value }) => getValueFromMap(statusMap, value) ?? value,
    },
    {
      dataField: "workflowId",
      dataType: "string",
      groupIndex: filter ? 0 : undefined,
      caption: "WorkflowId",
      visible: filter,
    },
    {
      caption: "Ações",
      cellRender: ({ component, data }) => (
        <GridActions
          data={data}
          reload={() => component.getDataSource()?.reload()}
        />
      ),
    },
  ],
  selection: {
    allowSelectAll: !filter,
    mode: "multiple",
  },
});
