import { Roles } from "roles/roles.types";

export const b2bRoles = {
  customer: {
    tab: {
      role: "admin:B2BTabCustomer.View",
      description: "Visualização da tab 'B2B' na visão cliente",
    },
    operatorsPage: {
      role: "admin:B2BOperatorsPageCustomer.View",
      description: "Visualização da página 'Operadores' na visão cliente",
    },
    originatorPage: {
      role: "admin:B2BOriginatorPageCustomer.View",
      description: "Visualização da página 'Originador' na visão cliente",
    },
    b2BCustomersPage: {
      role: "admin:B2BCustomersPageCustomer.View",
      description: "Visualização da página 'Clientes' na visão cliente",
    },
    b2BCustomersCompanyUsersPage: {
      role: "admin:B2BCustomersCompanyUsersPageCustomer.View",
      description:
        "Visualização da página 'Clientes - Usuários da empresa' na visão cliente",
    },
    enableCurrentAccountPage: {
      role: "admin:B2BEnableCurrentAccountPageCustomer.View",
      description:
        "Visualização da página 'Abertura de Conta Corrente' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:B2BTabProduct.View",
      description: "Visualização da tab 'B2B' na visão produto",
    },
    monitoringPage: {
      role: "admin:B2BAgroProposals.View",
      description: "Visualização da página 'Monitoramento' na visão produto",
    },
    summaryPage: {
      role: "admin:B2BAgroProposalSummary.View",
      description:
        "Visualização da página 'Resumo da proposta' na visão produto",
    },
    documentsPage: {
      role: "admin:B2BAgroProposalDocuments.View",
      description:
        "Visualização da página 'Documentos da proposta' na visão produto",
    },
    pendenciesPage: {
      role: "admin:B2BAgroProposalPendencies.View",
      description:
        "Visualização da página 'Pendências da proposta' na visão produto",
    },
    candidatosLeadsPage: {
      role: "admin:B2BCandidatosLeadsPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Leads' na visão produto",
    },
    candidatosAtendentesPage: {
      role: "admin:B2BCandidatosAtendentesPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Atendentes' na visão produto",
    },
    candidatosOnboardingEscritoriosPage: {
      role: "admin:B2BCandidatosOnboardingEscritoriosPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Onboarding de Escritório' na visão produto",
    },
    originadoresPage: {
      role: "admin:B2BOriginadoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Originadores' na visão produto",
    },
    operadoresPage: {
      role: "admin:B2BOperadoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Operadores' na visão produto",
    },
    consultoresPage: {
      role: "admin:B2BConsultoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Consultores' na visão produto",
    },
    catalogoPage: {
      role: "admin:B2BCreditoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Catálogo de produtos' na visão produto",
    },
    remuneracaoPage: {
      role: "admin:B2BRemuneracaoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Remuneração' na visão produto",
    },
    clientesPage: {
      role: "admin:B2BClientesPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Clientes' na visão produto",
    },
    operacoesPage: {
      role: "admin:B2BOperacoesPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Operações' na visão produto",
    },
    simuladorPage: {
      role: "admin:B2BSimuladoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Simuladores' na visão produto",
    },
    materiaisDeApoioPage: {
      role: "admin:B2BMateriaisDeApoioPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Materiais de Apoio' na visão produto",
    },
    entreEmContatoPage: {
      role: "admin:B2BAtendimentoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Entre em contato' na visão produto",
    },
  },
} satisfies Roles;
