import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { workflowLeadExportRequestsGrid } from "./lead-exports.grid";

export const ExportRequestsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Exportações"
          description="Solicitações para exportar propostas nos WFs"
        />
      }
      actions={
        <RouterButton
          role={roles.workflow.product.batchConfigurationsAddColumnPage.role}
          href={
            corporateRouter.routes.workflow.product.batchs.configuration.add
              .column.path
          }
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={workflowLeadExportRequestsGrid}
      />
    </GridTemplate>
  );
};
