import { validators } from "@maestro/utils";
import * as yup from "yup";
import { NewDeflatorForm } from "./new-deflator.types";

export const newDeflatorDefaulValues = {
  taxId: "",
  acquirerId: null,
  deflator: 0,
} satisfies Record<keyof NewDeflatorForm, unknown> as any;

export const newDeflatorValidationSchema: yup.ObjectSchema<NewDeflatorForm> =
  yup.object({
    taxId: yup
      .string()
      .required("É necessário preencher o CNPJ")
      .test("taxId", "CNPJ inválido", (value) => validators.cnpj(value)),
    acquirerId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("É necessário selecionar a credenciadora"),
    deflator: yup
      .number()
      .typeError("O deflator deve ser um número")
      .required("É necessário preencher o deflator")
      .min(0, "O deflator deve ser maior que 0")
      .max(1, "O deflator deve ser menor que 1"),
  });
