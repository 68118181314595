import {
  OButton,
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";
import { ExchangeNatureGenericFieldsForm } from "./nature-generic-fields-form.component";

export const ExchangeNatureForm = () => {
  const {
    callService: getNatureDocumentGroups,
    hasError,
    loading,
    value: documentGroups,
  } = useServiceCall(service.acc.nature.getNatureDocumentGroups);

  const documentGroupsOptions = useMemo(
    () =>
      documentGroups?.response.map((documentGroup) => ({
        label: `${documentGroup.id} - ${documentGroup?.documentConfigs
          ?.map((config) => config.typeName)
          .join(", ")}`,
        value: documentGroup.id,
      })) ?? [],
    [documentGroups],
  );

  useEffect(() => {
    getNatureDocumentGroups();
  }, [getNatureDocumentGroups]);

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput tag="text" id="code" name="code" label="Código" />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="groupCode"
                name="groupCode"
                label="Código do grupo"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="description"
                name="description"
                label="Descrição"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="disclaimer"
                name="disclaimer"
                label="Mensagem"
              />
            </OCol>
          </ORow>

          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="number"
                id="minimumAmount"
                name="minimumAmount"
                label="Valor mínimo"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="number"
                id="maximumAmount"
                name="maximumAmount"
                label="Valor máximo"
              />
            </OCol>
          </ORow>

          <ORow>
            <OCol sm={12} md={12} lg={3}>
              <ORFieldCheckboxGroup id="isApproved" name="isApproved">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="isApproved-checkbox" value="true" />
                  <OLabel htmlFor="isApproved-checkbox">Aprovado?</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={3}>
              <ORFieldCheckboxGroup
                id="isIncomingOperationAllowed"
                name="isIncomingOperationAllowed"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="isIncomingOperationAllowed-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="disableOutgoing-checkbox">
                    Permitido recebimento?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={3}>
              <ORFieldCheckboxGroup
                id="isOutgoingOperationAllowed"
                name="isOutgoingOperationAllowed"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="isOutgoingOperationAllowed-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="isOutgoingOperationAllowed-checkbox">
                    Permitido envio?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={3}>
              <ORFieldCheckboxGroup
                id="isDifferentOwnershipAccountAllowed"
                name="isDifferentOwnershipAccountAllowed"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="isDifferentOwnershipAccountAllowed-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="isDifferentOwnershipAccountAllowed-checkbox">
                    Permitido conta de um beneficiário diferente?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>

      <OCard>
        <OCardBody>
          <OCol sm={12} md={6} className="align-items-center">
            <SelectSearchMultiple
              id="documentGroupIds"
              name="documentGroupIds"
              options={documentGroupsOptions}
              label="Grupo de documentos"
              placeholder="Buscar"
            />
            {loading && <OLoader />}
            {hasError && (
              <OButton
                type="default"
                outline
                onClick={() => getNatureDocumentGroups()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
        </OCardBody>
      </OCard>

      <OCard>
        <OCardBody>
          <ExchangeNatureGenericFieldsForm />
        </OCardBody>
      </OCard>
    </div>
  );
};
