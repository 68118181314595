import * as yup from "yup";
import { EnergyAdequatorsFormValues } from "./energy-adequator.types";

/** @deprecated */
export const energyAdequatorsFormValidationFormSchema: yup.ObjectSchema<
  Omit<EnergyAdequatorsFormValues, "address">
> = yup.object({
  taxId: yup.string().required("Este campo é obrigatório"),
  name: yup.string().required("Este campo é obrigatório"),
  createdByCustomer: yup.array().default([]),
  contacts: yup
    .array<NonNullable<EnergyAdequatorsFormValues["contacts"]>[number]>()
    .default([]),
});
