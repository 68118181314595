import { OCard, OCardBody, OTypography } from "@maestro/react";
import { EmptyState } from "components/empty-state";
import { StatusBadge } from "components/status-badge";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../components/validation-error-component";
import { StepAnalysisContent } from "./step-analysis-content.component";
import { useStepAnalysis } from "./step-analysis.hook";

export const StepAnalysisPage = () => {
  const {
    error,
    getNextStepAnalysis,
    hasError,
    loading,
    value,
    impersonatedAgentFromParams,
  } = useStepAnalysis();

  const title = value?.response
    ? `Análise da etapa - ${value.response.stepType.label}`
    : "Análise da etapa";

  return (
    <DetailsTemplate
      pageTitle={
        <div className="mb-4 d-flex align-items-center gap-4">
          <OTypography tag="h1" type="dark" size="xl" key={title}>
            {title}
          </OTypography>
          {impersonatedAgentFromParams && (
            <StatusBadge type="warning">
              Impersonando agente: {impersonatedAgentFromParams}
            </StatusBadge>
          )}
        </div>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={value?.response}
        errorComponent={
          <ValidationErrorComponent
            callback={getNextStepAnalysis}
            error={error}
            title="Não foi possível buscar a etapa de migração"
          />
        }
        emptyStateComponent={
          <OCard>
            <OCardBody>
              <EmptyState
                messageTitle="Nenhuma etapa para ser analisada"
                messageParagraph="Todas as análises foram concluídas."
              />
            </OCardBody>
          </OCard>
        }
        render={(_stepAnalysis) => (
          <StepAnalysisContent
            stepAnalysis={_stepAnalysis}
            refetch={getNextStepAnalysis}
          />
        )}
      />
    </DetailsTemplate>
  );
};
