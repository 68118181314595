export const units = 3;

export const formatBankslipDate = (date: string) => {
  if (date.length !== 8) {
    return date;
  }
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return day + "/" + month + "/" + year;
};
