import { OCheckbox } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import dayjs from "dayjs";
import { RenegociacaoMasterDetail } from "./renegociacao-clientes.master-detail";
import { Contract } from "./renegociacao-clientes.type";

export const renegGrid: ODataGridGeneratorConfig<Contract> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      width: 64,
      alignment: "center",
      cellRender: ({ data }) => (
        <OCheckbox
          id={data.contract}
          size="xs"
          value={data.contract}
          className="d-inline-block"
        />
      ),
    },
    { dataField: "name", caption: "Produto" },
    {
      dataField: "cros_contract",
      caption: "Contrato",
    },
    {
      dataField: "balance.curve_value",
      caption: "Valor atual",
      alignment: "left",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "balance.principal_value",
      caption: "Valor Original",
      alignment: "left",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "due_days",
      sortOrder: "asc",
      caption: "Status",
      alignment: "left",
      cellRender: ({ value: expiredDays }) => {
        const hasExpiredDays = expiredDays > 1;
        const daysText = hasExpiredDays ? "dias" : "dia";
        const maturityDate = dayjs().subtract(expiredDays, "day");
        const label = hasExpiredDays
          ? `Vencido há ${expiredDays} ${daysText} (${maturityDate.format(
              "DD/MM/YYYY",
            )})`
          : "Em dia";
        const type = hasExpiredDays ? "danger" : "success";

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
  ],
  masterDetail: {
    enabled: true,
    component: RenegociacaoMasterDetail,
  },
};
