export enum LogLevels {
  "off",
  "fatal",
  "error",
  "warn",
  "info",
  "debug",
}

export interface InternalLoggerImpl {
  level: LogLevels;
  stackTraceEnabled: boolean;
  log(level: Exclude<LogLevels, LogLevels.off>, ...args: unknown[]): void;
}

export interface Logger {
  /** Use only when an error results in the application breaking */
  fatal: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
}
