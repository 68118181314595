import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./tools.grid";
import { tools } from "./tools.utils";

export const Tools = () => {
  const { hasRole } = useRoles();

  const allowedTools = useMemo(
    () => tools.filter((tool) => hasRole(tool.role)),
    [hasRole],
  );

  return (
    <GridTemplate pageTitle={<PageTitle title="Ferramentas" />}>
      <ODataGridGenerator grid={grid} dataSource={allowedTools} />
    </GridTemplate>
  );
};
