import { MappedStatus } from "./status-maps.types";

export const consumerUnitsImportDataStatusMap = {
  PROCESSANDO: { type: "info", text: "Processando" },
  ERRO_NO_PROCESSAMENTO: { type: "danger", text: "Erro no processamento" },
  PENDENTE_CORRECAO: { type: "warning", text: "Pendente correção" },
  PENDENTE_CONFIRMACAO: { type: "warning", text: "Pendente confirmação" },
  IMPORTADO: { type: "success", text: "Importado" },
} satisfies Record<
  HubEnergy.LegacyImport.EConsumerUnitsImportDataStatus,
  MappedStatus
>;
