import { OButton, OTypography } from "@maestro/react";
import {
  PreviousStepButton,
  ResetStepButton,
  useStepsBuilder,
} from "components/steps-builder";
import { useMemo } from "react";
import { useIntubateOperationsWithoutRegistry } from "../../intubate-operations-without-registry.context";

export const Confirmation = () => {
  const { dataReceivables, submit } = useIntubateOperationsWithoutRegistry();

  const { nextStep } = useStepsBuilder();

  const numberOfReceivablesGroup = useMemo(
    () =>
      [
        ...new Set(
          dataReceivables.map(
            (operation) =>
              `${operation.payerTaxIdentification}${operation.payeeTaxIdentification}${operation.bank}${operation.agency}${operation.account}${operation.accountDigit}`,
          ),
        ),
      ].length,
    [dataReceivables],
  );

  const numberOfReceivablesGroupText =
    numberOfReceivablesGroup > 1
      ? `Serão criados ${numberOfReceivablesGroup} grupos de recebíveis.`
      : "Será criado 1 grupo de recebíveis.";

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>{numberOfReceivablesGroupText}</OTypography>
      <OTypography>Esse processo pode demorar alguns minutos.</OTypography>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <OButton
          onClick={() => {
            submit();
            nextStep();
          }}
        >
          Criar Recebíveis
        </OButton>
      </div>
    </div>
  );
};
