import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const dataSource =
  dataSourceCustomStoreGenerator<HubPartners.Admin.Book>(() =>
    service.hubPartners
      .getBooks()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar books";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const booksGrid: ODataGridGeneratorConfig<HubPartners.Admin.Book> = {
  datagrid: {
    noDataText: "Nenhum book encontrado.",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "code",
      caption: "Código",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      caption: "Ações",
      allowExporting: false,
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: {
                category: "orq",
                icon: "orq-edit-pencil",
              },
              label: "Editar",
              visible: !!data.uuid,
              route: `${
                corporateRouter.routes.parceiros.product.configurations
                  .productMaintenance.books.edit.path
              }?data=${encodeURIComponent(
                JSON.stringify({
                  uuid: data.uuid,
                  name: data.name,
                  code: data.code,
                } satisfies HubPartners.Admin.Book),
              )}`,
              role: roles.parceiros.product.editBook.role,
            },
          ]}
        />
      ),
    },
  ],
};
