import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoLeadById } from "../../../../../../routes/workflow.route-params";
import { WorkflowContextForm } from "./workflow-context-form.component";
import {
  WorkflowContextFormValues,
  workflowContextValidationSchema,
} from "./workflow-context-form.schemas";

export const WorkflowAddContextPage = () => {
  const form = useForm<WorkflowContextFormValues>({
    resolver: yupResolver(workflowContextValidationSchema),
  });

  const { id } = useParams<WorkflowProdutoLeadById>();

  return (
    <FormTemplate pageTitle={<PageTitle title="Adicionar contexto" />}>
      <FormProvider {...form}>
        <WorkflowContextForm leadId={Number(id)} />
      </FormProvider>
    </FormTemplate>
  );
};
