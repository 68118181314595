import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

export const adminACCCounterpartiesODataGrid = {
  datagrid: {
    noDataText: "Nenhuma contraparte encontrada",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar contrapartes.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.acc.odata.adminACCCounterpartiesOData,
    ),
  },
  columns: [
    {
      type: "buttons",
      allowExporting: false,
      role: roles.acc.customer.tradesDetailsPage.role,
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={buildCustomerLink(
              data.identification ?? "",
              corporateRouter.routes.acc.customer.quotes.path,
            )}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
      dataType: "number",
    },
    {
      caption: "Nome",
      dataField: "name",
    },
    {
      caption: "Identificação",
      dataField: "identification",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Cge",
      dataField: "cge",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.odata.getAccCounterparties>
    >["data"]["value"]
  >[number]
>;
