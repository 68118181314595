import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetAdminLimitRequestMinifiedByIdResponse } from "services/hubcreditmanager/models/responses";

export const additionalOffersGrid = {
  datagrid: {
    noDataText: "Nenhuma oferta adicional encontrada",
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.limitManagerLimitsDetailsSummaryPage.role,
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.summary.path(
              { id: data.id },
            )}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id da oferta",
    },
    {
      dataField: "status",
      caption: "Status",
    },
    {
      dataField: "creditLineConfiguration.creditLine.identification",
      caption: "Identificação",
    },
    {
      dataField: "creditLineConfiguration.creditLineVisibleName",
      caption: "Descrição",
    },
    {
      dataField: "fundingType",
      caption: "Tipo de financiamento",
    },
    {
      dataField: "amount",
      caption: "Valor da oferta",
      alignment: "left",
      cellRender: ({ data }) => masks.currency(data.amount, "R$"),
    },
  ],
} satisfies ODataGridGeneratorConfig<GetAdminLimitRequestMinifiedByIdResponse>;
