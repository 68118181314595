import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { getComponentPropertyFieldId } from "../component-property-field.utils";

interface BooleanPropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  handleSubmitComponentProperty: (
    formStepCardComponentId: number,
    propertyId: number,
    value: string,
  ) => Promise<void>;
  formStepCardComponentId: number;
}

export const BooleanPropertyField = ({
  componentProperty,
  formStepCardComponentId,
  handleSubmitComponentProperty,
}: BooleanPropertyFieldProps) => {
  const valueRef = useRef("");

  const { setValue, watch } = useFormContext();

  const property = getComponentPropertyFieldId(
    componentProperty.name,
    componentProperty.id,
  );
  const fieldWatch = watch(property);

  const handleFocusOut = useCallback(() => {
    return handleSubmitComponentProperty(
      formStepCardComponentId,
      componentProperty.id,
      valueRef.current,
    );
  }, [
    handleSubmitComponentProperty,
    formStepCardComponentId,
    componentProperty,
  ]);

  useEffect(() => {
    if (componentProperty.value === undefined) return;

    valueRef.current = componentProperty.value;

    setValue(property, componentProperty.value === "true" ? ["true"] : []);
  }, [setValue, componentProperty, property]);

  useEffect(() => {
    valueRef.current = fieldWatch?.length ? "true" : "";

    const propertyInput = document.getElementById(property);

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [
    handleFocusOut,
    componentProperty.name,
    componentProperty.id,
    property,
    fieldWatch,
  ]);

  const booleanPropertyField = useMemo(() => {
    return (
      <ORFieldCheckboxGroup
        key={componentProperty.name + componentProperty.id}
        id={property}
        name={property}
        required={componentProperty.isRequired}
      >
        <div className="d-flex align-items-center gap-2 mb-2">
          <OCheckbox
            size="xs"
            id={`single-parent-checkbox-${componentProperty.name}`}
            value="true"
          />
          <OLabel htmlFor={`single-parent-checkbox-${componentProperty.name}`}>
            {componentProperty.description}
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
    );
  }, [componentProperty, property]);

  return <>{booleanPropertyField}</>;
};
