import { OLoader } from "@maestro/react";
import { EmptyState, ErrorComponent } from "components/empty-state";
import { ContentTemplateProps } from "./content.types";

export const ContentTemplate = <T,>({
  emptyStateComponent,
  errorComponent,
  loadingComponent,
  hasError,
  loading,
  render,
  value,
  noValue,
}: ContentTemplateProps<T>) => {
  if (loading === true)
    // allow user to pass null to have a blank error component
    return loadingComponent === undefined ? (
      <OLoader absolute data-component="ContentTemplate" />
    ) : (
      (loadingComponent as JSX.Element)
    );

  if (hasError === true)
    // allow user to pass null to have a blank error component
    return errorComponent === undefined ? (
      <ErrorComponent messageTitle="Ocorreu um erro." />
    ) : (
      (errorComponent as JSX.Element)
    );

  if (noValue) {
    return render();
  }

  if (!value || (value instanceof Array && value.length === 0))
    // allow user to pass null to have a blank empty state
    return emptyStateComponent === undefined ? (
      <EmptyState messageTitle="Não há dados." />
    ) : (
      (emptyStateComponent as JSX.Element)
    );

  return render(value);
};
