import { getValueFromMap } from "utils/get-value-from-map";
import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import {
  documentTypeReverseMap,
  countryCodeReverseMap,
  filterValues,
  toBoolean,
} from "../../../../../../utils";
import { Cedente } from "../../upload-cedentes.types";

export const parseCedentesSpreadsheet = (
  buffer: FileReader["result"],
): Cedente[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return filterValues({
      antecipaAutomaticamente: toBoolean(cleanRow["antecipa automaticamente"]),
      companyEntityType:
        getValueFromMap(
          documentTypeReverseMap,
          cleanRow["tipo de documento"],
        ) ?? cleanRow["tipo de documento"],
      convenioGUID: cleanRow.estrategia,
      desativaFornecedor: toBoolean(cleanRow["desativar fornecedor"]),
      estrategiaGUID: cleanRow.estrategia,
      identificadorExterno: cleanRow["identificador externo"]
        ?.toString()
        .trim(),
      officialName: cleanRow.empresa,
      tipoContrato: cleanRow["modelo de termo"],
      tipoProduto: cleanRow["tipo de produto"],
      cge: cleanRow.cge?.toString().trim(),
      contractTemplateGUID: cleanRow["identificador do template de contrato"],
      identificacao: cleanRow["cnpj/cpf/offshore"]?.toString().trim(),
      permiteOverrideEstrategia: toBoolean(
        cleanRow["permite sobreescrita da estrategia"],
      ),
      country:
        getValueFromMap(countryCodeReverseMap, cleanRow["país"]) ??
        cleanRow["país"],
    }) as Cedente;
  });
};
