import { useRedirectWithRoles } from "components/redirect-with-roles";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { ContentContainer, MenuContainer } from "layout/content/content.styles";
import { RoutesMenu } from "layout/global-menu";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getValueFromMap } from "utils/get-value-from-map";
import { logger } from "utils/logger";
import { ProposalDocumentsPage } from "../monitoramento/agro/[id]/documentos/proposal-documents.page";
import { ProposalPendenciesPage } from "../monitoramento/agro/[id]/pendencias/proposal-pendencies.page";
import { ProposalSummaryPage } from "../monitoramento/agro/[id]/resumo/proposal-summary.page";
import { MonitorAgroProposalsPage } from "../monitoramento/agro/monitor-agro-proposals.page";
import { B2BEmbeddedAdminBehavior } from "./b2b-embedded-admin-behavior.component";
import { B2BEmbeddedCorbanBehavior } from "./b2b-embedded-corban-behavior.component";
import { b2bEmbeddedRoutes } from "./b2b-embedded.routes";
import { matchDynamicPath, translateRouteToB2B } from "./b2b-embedded.utils";

const nonEmbeddedRoutes = {
  "/finders/produto/monitoramento": <MonitorAgroProposalsPage />,
  "/finders/produto/monitoramento/:proposalId/resumo": <ProposalSummaryPage />,
  "/finders/produto/monitoramento/:proposalId/documentos": (
    <ProposalDocumentsPage />
  ),
  "/finders/produto/monitoramento/:proposalId/pendencias": (
    <ProposalPendenciesPage />
  ),
};

export const B2BEmbedded = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { behavior } = useBehavior();

  const portalRoute = translateRouteToB2B(pathname);

  const redirectPath =
    useRedirectWithRoles(b2bEmbeddedRoutes, "/finders/produto") ??
    "/nao-autorizado";

  useEffect(() => {
    if (pathname.match(/^\/finders\/produto\/?$/))
      navigate(redirectPath, { replace: true });
  }, [navigate, pathname, redirectPath]);

  logger.debug("[B2BEmbedded]route:", portalRoute);

  const pathRouteKey = Object.keys(nonEmbeddedRoutes).find((path) =>
    matchDynamicPath(path.split("/"), pathname.split("/")),
  );

  const component = getValueFromMap(nonEmbeddedRoutes, pathRouteKey) ?? (
    <>
      {behavior.id === Behaviors.Corban ? (
        <B2BEmbeddedCorbanBehavior portalRoute={portalRoute} />
      ) : (
        <B2BEmbeddedAdminBehavior portalRoute={portalRoute} />
      )}
    </>
  );

  return (
    <div className="d-flex flex-grow-1">
      {behavior.id !== Behaviors.Corban && (
        <MenuContainer>
          <RoutesMenu routes={b2bEmbeddedRoutes} />
        </MenuContainer>
      )}

      <div className="w-100 position-relative" style={{ minWidth: 0 }}>
        <ContentContainer>{component}</ContentContainer>
      </div>
    </div>
  );
};
