import { OButton, OIcon } from "@maestro/react";

interface ClearGridFiltersButtonProps
  extends Omit<
    React.ComponentPropsWithRef<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  dataAction?: string;
  dataLabel?: string;
}

export const ClearGridFiltersButton = (props: ClearGridFiltersButtonProps) => {
  return (
    <OButton
      dataAction="grid:botao:recarregar_grid"
      dataLabel="recarregar_grid"
      style={{ position: "relative" }}
      {...props}
      type="default"
      outline
    >
      <OIcon category="far" icon="fa-filter" />
    </OButton>
  );
};
