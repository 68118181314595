import DataGrid, {
  Column,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";
import moment from "moment";
import { MoneyMask } from "../../../../../utils";
import { removeCipherFormatter } from "../../../../../utils/formatters/cipher";
import { Currency } from "../../../../cards.types";
import { capitalizeFirstLetter } from "../../../../utils/cases";
import { handleTimestampHeaderFiltering } from "../../../../utils/timestamp-header-filtering";
import { ICellRenderParams, IListProps } from "./list.types";

export const List = ({
  invoiceTransactionItems,
  transactionDetailsId,
  setTransactionDetailsId,
}: IListProps) => {
  return (
    <DataGrid
      id="grid-container-list"
      keyExpr="id"
      width="100%"
      rowAlternationEnabled={!transactionDetailsId}
      selectedRowKeys={[transactionDetailsId?.toString()]}
      dataSource={invoiceTransactionItems}
      showColumnLines
      showRowLines
      noDataText="Nenhuma Transação encontrada."
      columnAutoWidth
      scrolling={{
        showScrollbar: "always",
      }}
      headerFilter={{
        visible: true,
      }}
      pager={{
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      }}
    >
      <FilterRow visible />
      <Paging defaultPageSize={10} />
      <Column
        caption="Data e Hora"
        calculateFilterExpression={(
          filterValue: string,
          selectedFilterOperation: string,
        ) => {
          const timeZoneOffset: [number, number, number] = [3, 0, 0];
          const value = handleTimestampHeaderFiltering(
            filterValue,
            timeZoneOffset,
          );
          return ["eventDate", selectedFilterOperation, value];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="eventDate"
        cellRender={(params: ICellRenderParams) => {
          if (!params.data.eventDate) return "-";
          return moment(`${params.data.eventDate}Z`).format(
            "DD/MM/YYYY  HH:mm:ss",
          );
        }}
      />
      <Column
        caption="Título"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          return [
            "merchant.name",
            selectedFilterOperation,
            filterValue.toUpperCase(),
          ];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="merchant.name"
        cellRender={({ data }: ICellRenderParams) => {
          return capitalizeFirstLetter(data.merchant?.name);
        }}
      />
      <Column
        caption="Subtítulo"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          return [
            "transactionType.description",
            selectedFilterOperation,
            filterValue.toUpperCase(),
          ];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="transactionType.description"
        cellRender={({ data }: ICellRenderParams) => {
          return capitalizeFirstLetter(data.transactionType?.description);
        }}
      />
      <Column
        caption="Descrição"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          return [
            "authorization.description",
            selectedFilterOperation,
            filterValue.replace(/\D/g, ""),
          ];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="authorization.description"
        cellRender={({ data }: ICellRenderParams) => {
          if (!data.authorization?.description) return "-";
          return data.authorization.description;
        }}
      />
      <Column
        caption="Cartão Final"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          return ["card.last4Digits", selectedFilterOperation, filterValue];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="card.last4Digits"
        cellRender={({ data }: ICellRenderParams) => {
          if (!data.card?.last4Digits) return "-";
          return data.card.last4Digits;
        }}
      />
      <Column
        caption="Id"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          return ["id", selectedFilterOperation, filterValue];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="authorization.id"
        cellRender={({ data }: ICellRenderParams) => {
          if (!data.authorization?.id) return "-";
          return data.authorization.id;
        }}
      />
      <Column
        caption="Valor"
        calculateFilterExpression={(
          filterValue: any,
          selectedFilterOperation: string,
        ) => {
          const value = removeCipherFormatter(filterValue);
          return ["amount[0].value", selectedFilterOperation, value];
        }}
        allowHeaderFiltering={false}
        dataType="string"
        dataField="amount[0].value"
        cellRender={({ data }: ICellRenderParams) => {
          if (!data.amount[0]?.value) return "-";
          return MoneyMask(data.amount[0].value, Currency.BRL);
        }}
      />
      <Column
        caption="Ação"
        allowHeaderFiltering={false}
        type="buttons"
        buttons={[
          {
            text: "Detalhes",
            onClick: (e: { row: ICellRenderParams }) => {
              setTransactionDetailsId(e.row.data.id);
            },
          },
        ]}
      />
    </DataGrid>
  );
};
