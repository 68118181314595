import { OBadge, OIcon, OTypography } from "@maestro/react";
import { DefaultStatusBadgeProps } from "../status-badge.types";

export const DefaultStatusBadge = ({
  badgeType,
  icon,
  status,
}: DefaultStatusBadgeProps) => {
  return (
    <OBadge type={badgeType} size="sm" rounded>
      <div className="d-flex align-items-center gap-1">
        {icon && (
          <OIcon
            category={icon.category ?? "orq"}
            icon={icon.icon}
            type={icon.type}
          />
        )}
        <OTypography size="sm">{status}</OTypography>
      </div>
    </OBadge>
  );
};
