import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { adminCustomerAccSettlementsGrid } from "./admin-customer-acc-settlements.grid";
import { useMemo } from "react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCustomer } from "contexts/customer";

export const AdminCustomerACCSettlementsPage = () => {
  const { customer } = useCustomer();
  const gridRef = useGridRef();

  const grid = useMemo(() => adminCustomerAccSettlementsGrid(customer.identification), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Liquidações" />}
      gridRef={gridRef}
      actions={
        <RefreshGridButton
          onClick={() => {
            !grid.datagrid.dataSource.isLoading() &&
            grid.datagrid.dataSource.reload();
          }}
        />
      }
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
      />
    </GridTemplate>
  );
};
