import { MasterDetailComponentProps } from "components/data-grid";
import { DetailsCard, DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { CustomerInviteEvents } from "../../customer-invite-details.types";
import { useInvitesMasterDetails } from "./use-invites-master-details.hook";

type InviteMasterDetailProps = MasterDetailComponentProps<CustomerInviteEvents>;

interface InvitesDetailsGeneratorProps {
  status: string | null;
  trackerSend: string | null;
  trackerDelivery: string | null;
  trackerOpen: string | null;
  trackerClick: string | null;
  error: string | null;
  trackerBounce: string | null;
}

const inviteDetailsGenerator = (data: InvitesDetailsGeneratorProps) => {
  return {
    assignors: {
      items: [
        {
          label: "Status",
          value: data?.status,
          grid: { xs: 12 },
        },
        {
          label: "Enviado em",
          value:
            data?.trackerSend &&
            dayjs(data?.trackerSend).format("DD/MM/YYYY  HH:mm:ss"),
          grid: { xs: 12 },
        },
        {
          label: "Entregue em",
          value:
            data?.trackerDelivery &&
            dayjs(data?.trackerDelivery).format("DD/MM/YYYY  HH:mm:ss"),
          grid: { xs: 12 },
        },
        {
          label: "Aberto em",
          value:
            data?.trackerOpen &&
            dayjs(data?.trackerOpen).format("DD/MM/YYYY  HH:mm:ss"),
          grid: { xs: 12 },
        },
        {
          label: "Clicado em",
          value:
            data?.trackerClick &&
            dayjs(data?.trackerClick).format("DD/MM/YYYY  HH:mm:ss"),
          grid: { xs: 12 },
        },
        {
          label: "Erro",
          value: data?.error,
          grid: { xs: 12 },
        },
        {
          label: "Bounce em",
          value:
            data?.trackerBounce &&
            dayjs(data?.trackerBounce).format("DD/MM/YYYY  HH:mm:ss"),
          grid: { xs: 12 },
        },
      ],
    },
  } satisfies DetailsFields;
};

export const InvitesMasterDetails = ({
  data: { data },
}: InviteMasterDetailProps) => {
  const { hasError, loading, value } = useInvitesMasterDetails(data.ravenId);

  return (
    <div className="d-flex">
      <DetailsCard
        hasError={hasError}
        loading={loading}
        value={value}
        fields={inviteDetailsGenerator}
      />
    </div>
  );
};
