import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { FranquiasFormProps } from "./franquias.form";

export const FranquiasForm = ({ form }: FranquiasFormProps) => {
  return (
    <FormProvider {...form}>
      <form>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput tag="text" id="name" name="name" label="Nome" />
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
