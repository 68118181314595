import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ConditionForm } from "../../../../../components/conditions/condition-form/condition-form.component";
import {
  conditionFormDefaultValues,
  conditionFormValidationSchema,
} from "../../../../../components/conditions/condition-form/condition-form.schemas";
import {
  WorkflowProdutoCondicoesById,
  WorkflowProdutoCondicoesDetalhesRouteQueryParms,
} from "../../../../../routes/workflow.route-params";

export const EditConditionPage = () => {
  const { id } = useParams<WorkflowProdutoCondicoesById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoCondicoesDetalhesRouteQueryParms>(
      "data",
    );
  const form = useForm({
    resolver: yupResolver(conditionFormValidationSchema),
    defaultValues: conditionFormDefaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    value && reset(value);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate pageTitle={<PageTitle title="Editar condição" />}>
          <FormProvider {...form}>
            <ConditionForm mode="edit" conditionId={+id} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};
