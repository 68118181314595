import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  VerifyAditamentosItem,
  VerifyCorrecaoItem,
} from "services/quickfin/models";
import { logger } from "utils/logger";
import { formDefaultValues, validationSchema } from "./import-corrections.form";
import { FormValuesType } from "./import-corrections.types";
import {
  parseSpreadsheetAditamento,
  parseSpreadsheetCorrecao,
} from "./import-corrections.utils";

export const useTitleImportCorrections = () => {
  const navigate = useNavigate();
  const {
    error: readerError,
    loading: readerLoader,
    reader,
    result,
  } = useReadFile();

  const [loading, setLoading] = useState(false);
  const [spreadsheet, setSpreadsheet] = useState<
    VerifyAditamentosItem[] | VerifyCorrecaoItem[] | null
  >(null);

  const form = useForm<FormValuesType>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { watch } = form;

  const watchType = watch("type");

  const verifyFile = useCallback(
    async (sheet: VerifyAditamentosItem[] | VerifyCorrecaoItem[]) => {
      try {
        await service.quickfin.getAditamentosVerify({ aditamentos: sheet });

        setSpreadsheet(sheet);

        OToastManager.success("Dados verificados com sucesso");
      } catch (err) {
        OToastManager.danger(
          "Erro para verificar os dados. Preencha todos os campos e verifique se os dados estão corretos.",
        );
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const importData = useCallback(async () => {
    try {
      if (!spreadsheet) return;

      await service.quickfin.createAditamentos({ aditamentos: spreadsheet });

      navigate(
        corporateRouter.routes.recebiveis.product.titles.tradesCorrection.path,
      );
      OToastManager.success("Dados importados com sucesso");
    } catch (err) {
      OToastManager.danger("Erro para importar os dados.");
    } finally {
      setLoading(false);
    }
  }, [navigate, spreadsheet]);

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        setLoading(true);
        let sheet;

        if (watchType === "Aditamento") {
          sheet = parseSpreadsheetAditamento(result);
        } else {
          sheet = parseSpreadsheetCorrecao(result);
        }

        verifyFile(sheet);
      } catch (err) {
        logger.error(err);
        OToastManager.danger("Erro para pegar os dados do arquivo.");
      } finally {
        setLoading(false);
      }
    }
  }, [readerError, result, verifyFile, watchType]);

  return {
    form,
    loading,
    readerLoader,
    spreadsheet,
    watchType,
    handleAddFile,
    importData,
  };
};
