import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { ProcessStatus } from "../../../components/process-dashboard/process-status.component";

export const ProcessDashboard = () => {
  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Dashboard rotina matinal"
          description="Acompanhe o andamento de todos os processos envolvidos na coleta
                     e processamento de dados para a antecipação de cartões.
                     O valor 'Pendente' ocorre quando o processo está aguardando um outro processo matinal ter sucesso na execução ou quando alguma condição especial ser atingida."
        />
      }
    >
      <ProcessStatus />
    </DetailsTemplate>
  );
};
