export const notificationStatusMap = {
  CANCELADO: { type: "danger" as const, text: "Cancelado" },
  CONCLUIDA: { type: "success" as const, text: "Concluida" },
  INDEFINIDO: { type: "info" as const, text: "Indefinido" },
  PRIMEIRA_NOTIFICACAO: {
    type: "warning" as const,
    text: "Primeira notificação",
  },
  PRIMEIRA_REQUISICAO: {
    type: "warning" as const,
    text: "Primeira requisição",
  },
  SEGUNDA_NOTIFICACAO: {
    type: "warning" as const,
    text: "Segunda notificação",
  },
  TERCEIRA_NOTIFICACAO: {
    type: "warning" as const,
    text: "Terceira notificação",
  },
  PENDENTE_ACAO_DO_CLIENTE: {
    type: "warning" as const,
    text: "Pendente ação do cliente",
  },
};
