import { OCard, OCardBody, OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { CategorizationsFields } from "../../../../components/originator";
import { useUpdateOriginatorCategorizations } from "./update-originator-categorizations.hook";

export const UpdateOriginatorCategorizations = () => {
  const { form, isCorban, loading, loadingSubmit, onSubmit } =
    useUpdateOriginatorCategorizations();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Categorização do Originador"
          description="Selecione qual é o tipo deste parceiro e quais produtos ele
        poderá oferecer a seus clientes."
        />
      }
      actions={
        <LoadingButton loading={loading || loadingSubmit} onClick={onSubmit}>
          Atualizar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <OCard>
          <OCardBody>
            {loading ? (
              <OLoader />
            ) : (
              <CategorizationsFields isCorban={isCorban} />
            )}
          </OCardBody>
        </OCard>
      </FormProvider>
    </FormTemplate>
  );
};
