import { Dispatch, SetStateAction } from "react";
import {
  OModal,
  OModalHeader,
  OTypography,
  OModalBody,
  OModalFooter,
  OButton,
  OOption,
  ORFieldSelect,
  ORow,
  OCol,
  ORFieldInput,
  OToastManager,
} from "@maestro/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logger } from "utils/logger";
import {
  integrationUpdateSchema,
  IntegrationUpdateFormValues,
  integrationUpdateDefaultValues,
  IntegrationFileProcessor,
} from "../../integrations.form";
import { useFileProcessor } from "../../integrations.hook";
import { IntegrationStatus } from "../enums/integrationStatus";

interface EditIntegrationModalProps {
  integrations: IntegrationFileProcessor[];
  setIntegrations: Dispatch<SetStateAction<IntegrationFileProcessor[]>>;
  currentIntegration: IntegrationFileProcessor;
}

export const EditIntegrationModal = ({
  integrations,
  setIntegrations,
  currentIntegration,
}: EditIntegrationModalProps) => {
  const { updateIntegration } = useFileProcessor();
  const form = useForm<IntegrationUpdateFormValues>({
    resolver: yupResolver(integrationUpdateSchema),
    defaultValues: integrationUpdateDefaultValues,
  });
  const { handleSubmit, getValues } = form;
  const onSubmit = handleSubmit(async () => {
    try {
      const { status, observation } = getValues();
      await updateIntegration(currentIntegration, status, observation);
      const updatedIntegrations = integrations.map((item) =>
        item.id === currentIntegration.id
          ? { ...item, ...currentIntegration, status }
          : item,
      );

      setIntegrations(updatedIntegrations);
    } catch (err) {
      OToastManager.danger("Erro ao atualizar integrações");
      logger.error(err);
    }
  });

  return (
    <OModal
      id="edit-integration-modal"
      position="center"
      backdrop
      onModalClose={() => {}}
    >
      <OModalHeader closeButton divider>
        <OTypography size="lg">Atualizar integração</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow
            align="center"
            justify="center"
            className="d-flex flex-column pt-5 pb-5 mb-5"
          >
            <OCol sm={4} align="center" justify="center">
              <ORFieldSelect
                id="status"
                name="status"
                dataAction="atualizar_integracao:texto:produto_cnab"
                dataLabel="produto_cnab"
                label="Selecione o novo status da integração"
                size="md"
              >
                {Object.entries(IntegrationStatus).map(([key, value]) => (
                  <OOption id={key} value={key.toUpperCase()} key={key}>
                    {value.toUpperCase()}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4} align="center" justify="center">
              <ORFieldInput
                tag="textarea"
                id="observation"
                name="observation"
                dataAction="atualizar_integracao:texto:observacao"
                dataLabel="observacao"
                label="Observação"
              />
            </OCol>
          </ORow>
          <ORow align="center" justify="center" className="mt-5 pt-5">
            <OButton
              dataAction="pesquisar_integracao:texto:pesquisar"
              dataLabel="pesquisar"
              onClick={() => {
                onSubmit();
              }}
            >
              Atualizar
            </OButton>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider />
    </OModal>
  );
};
