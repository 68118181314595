import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OLoader,
  OOption,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services/service";
import { CardTemplate } from "templates/card-template";
import { ContingencyScript } from "./_compose";
import { contingencyValidatonSchema } from "./contingency.form";
import { ContingencyFormFields } from "./contingency.types";
import { contingencyProfiles } from "./contingency.utils";

export const Contingency = () => {
  const [contingencies, setContingencies] =
    useState<HubOnboardingRelationships.Admin.Contingency[]>();
  const [loading, setLoading] = useState(false);

  const form = useForm<ContingencyFormFields>({
    resolver: yupResolver(contingencyValidatonSchema),
  });
  const { watch } = form;
  const watchProfile = watch("profile");

  const fetch = useCallback(async () => {
    if (!watchProfile) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await service.onboardingRelationships.verifyScripts([
        watchProfile,
      ]);
      setContingencies(data);
    } catch {
      OToastManager.danger("Falha ao obter AAAA");
    } finally {
      setLoading(false);
    }
  }, [watchProfile]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <PageTitle
        title="Contingências Relationships"
        description="Contingências de onboarding-relationships"
      />
      <FormProvider {...form}>
        <ORow>
          <OCol xs={12} md={6} xl={4} className="d-flex flex-row w-50">
            <CardTemplate>
              <ORFieldSelect id="profile" name="profile" label="Perfil">
                {contingencyProfiles.map((profile) => (
                  <OOption key={profile} value={profile}>
                    {profile}
                  </OOption>
                ))}
              </ORFieldSelect>
            </CardTemplate>
          </OCol>
        </ORow>
      </FormProvider>
      {loading ? (
        <OLoader />
      ) : (
        contingencies?.map((contingency) => {
          if (!contingency.identifier) {
            return null;
          }

          return (
            <ContingencyScript
              key={contingency.uuid}
              contingency={contingency}
            />
          );
        })
      )}
    </>
  );
};
