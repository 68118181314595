import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { ODataBatchValueItem } from "services/hubcreditmanager/odata/models/responses/get-odata-batch-file-templates-admin.respose";
import {
  modalColumnFormDefaultValues,
  modalFormValidationSchema,
} from "./modal-form.schemas";

interface ComponentDetailsProps {
  template: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getBatchFileTemplates>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getBatchFileTemplates>
      >["data"]
    >
  >;
  selectedTemplate: ODataBatchValueItem | undefined;
}

export const BATCH_TEMPLATE_MODAL_ID = "batch-template-modal";

export const Modal = ({
  selectedTemplate,
  template,
}: ComponentDetailsProps) => {
  const form = useForm({
    resolver: yupResolver(modalFormValidationSchema),
    defaultValues: modalColumnFormDefaultValues,
  });

  const { reset, getValues } = form;

  const { hide } = modalManager;

  const { callService: getTemplateDetails } = template;

  const { callService: editBatchTemplate, loading: editTemplateLoading } =
    useServiceCall(service.hubCreditManager.editBatchFileTemplate);

  useEffect(() => {
    if (selectedTemplate) {
      reset({
        description: selectedTemplate.Description,
        name: selectedTemplate.Name,
      });
    } else {
      reset(modalColumnFormDefaultValues);
    }
  }, [reset, selectedTemplate, getValues]);

  const editTemplate = useCallback(
    async (templateId: number) => {
      const { description, name } = getValues();

      const { success } = await editBatchTemplate({
        name,
        description,
        BatchTemplateId: templateId,
      });

      if (success) {
        OToastManager.success("Template editado com sucesso.");
        hide(BATCH_TEMPLATE_MODAL_ID);

        getTemplateDetails();
      } else OToastManager.danger("Não foi possível editar o template.");
    },
    [getValues, editBatchTemplate, hide, getTemplateDetails],
  );

  return (
    <OModal id={BATCH_TEMPLATE_MODAL_ID} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography
          tag="h1"
          size="xxl"
          key={`template-modal-title-${selectedTemplate}`}
        >
          Editar coluna
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORFieldInput
            id="name"
            name="name"
            tag="text"
            label="Nome"
            tooltipPosition="top-right"
            labelSize="lg"
            dataAction="editar_template_batch:texto:nome"
            dataLabel="nome"
          />
          <ORFieldInput
            id="description"
            name="description"
            tag="text"
            label="Descrição"
            labelSize="lg"
            dataAction="editar_template_batch:texto:descricao"
            dataLabel="descricao"
          />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="editar_template_batch:botao:fechar"
            dataLabel="fechar"
            outline
            onClick={() => {
              modalManager.hide(BATCH_TEMPLATE_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <LoadingButton
            loading={selectedTemplate ? editTemplateLoading : false}
            dataAction="editar_template_batch:botao:adicionar"
            dataLabel="editar"
            onClick={async () => {
              if (selectedTemplate) editTemplate(selectedTemplate.Id);
            }}
          >
            Editar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
