import { useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosError } from "axios";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import omitBy from "lodash/omitBy";
import { OToastManager } from "@maestro/core";
import { helpers, validators } from "@maestro/utils";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services";
import {
  GetOwnerReceivablesResponse,
  ReceivableTotalResponse,
} from "services/moana/models";
import { logger } from "utils/logger";
import { walletInvoicesFilterFormDefaultValues } from "./_compose/wallet-invoices-filter";
import { WalletInvoicesFilterForm } from "./_compose/wallet-invoices-filter/wallet-invoices-filter.types";
import { isArray } from "lodash";

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => val !== "null" && !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("");

export const useWalletDetails = (documentNumber: string) => {
  const form = useForm({
    defaultValues: walletInvoicesFilterFormDefaultValues,
  });
  const { reset } = form;
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totals, setTotals] = useState<ReceivableTotalResponse>();
  const [search, setSearch] = useState<WalletInvoicesFilterForm>();
  const [sortQuery, setSortQuery] = useState("-issueDate");
  const returnPersonType = (identification: string) =>
    validators.cpf(identification) ? 1 : 2;

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        GetOwnerReceivablesResponse["data"][number]
      >(() => {
        return service.moana
          .getOwnerReceivables(
            buildQuery({
              personType: returnPersonType("" + documentNumber),
              documentNumber,
              limit: rows,
              offset: (page - 1) * rows,
              ...(!!sortQuery && { sort: sortQuery }),
              ...{
                ...search,
                ...(search?.maturityDate &&
                  search?.maturityDate[0] && {
                    toMaturityDate: dayjs(search.maturityDate[0]).format(
                      "YYYY-MM-DD",
                    ),
                  }),
                ...(search?.maturityDate &&
                  search?.maturityDate[1] && {
                    toMaturityDate: dayjs(search.maturityDate[1]).format(
                      "YYYY-MM-DD",
                    ),
                  }),
                  ...(search?.issueDate &&
                    search?.issueDate[0] && {
                      fromIssueDate: dayjs(search.issueDate[0]).format(
                        "YYYY-MM-DD",
                      ),
                    }),
                  ...(search?.issueDate &&
                    search?.issueDate[1] && {
                      toIssueDate: dayjs(search.issueDate[1]).format(
                        "YYYY-MM-DD",
                      ),
                    }),
              },
            }),
          )
          .then(({ data }) => {
            setTotals(data.totals);
            return data.data;
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar recebíveis.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [page, rows, documentNumber, search, sortQuery],
  );

  const onSubmit = (values: WalletInvoicesFilterForm) => {
    const filledValues = omitBy<WalletInvoicesFilterForm>(
      values,
      (val) => !val,
    );
    const hasValues = Object.values(filledValues).length;
    if (hasValues) {
      setSearch(filledValues);
    } else {
      setSearch(undefined);
    }
  };

  const resetFilter = () => {
    reset();
    setSearch(undefined);
  };

  useEffect(() => {
    setPage(1);
  }, [rows]);

  const exportData = useCallback(async () => {
    try {
      const { data } = await service.moana.exportOwnerReceivables(
        buildQuery({
          personType: returnPersonType("" + documentNumber),
          documentNumber,
          ...(!!sortQuery && { sort: sortQuery }),
          ...{
            ...search,
            ...(search?.maturityDate &&
              search?.maturityDate[0] && {
                toMaturityDate: dayjs(search.maturityDate[0]).format(
                  "YYYY-MM-DD",
                ),
              }),
            ...(search?.maturityDate &&
              search?.maturityDate[1] && {
                toMaturityDate: dayjs(search.maturityDate[1]).format(
                  "YYYY-MM-DD",
                ),
              }),
          },
        }),
      );
      helpers.downloadBase64(data, "receivables.xlsx");
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [documentNumber, search, sortQuery]);

  const getDiscountedPortfolio = useCallback(
    async (p: number, r: number, ranges?: number[] | null) => {
      return service.moana
        .getDiscountedPortfolio(
          buildQuery({
            limit: r,
            offset: (p - 1) * r,
            ...(!!sortQuery && { sort: sortQuery }),
            documentNumber,
            ranges,
          }),
        )
        .then(({ data }) => data)
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar recebíveis.";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    [documentNumber, sortQuery],
  );

  const getOwnWallet = useCallback(
    async (p: number, r: number, ranges?: number[] | null) => {
      return service.moana
        .getOwnWallet(
          buildQuery({
            limit: r,
            offset: (p - 1) * r,
            ...(!!sortQuery && { sort: sortQuery }),
            documentNumber,
            ranges,
          }),
        )
        .then(({ data }) => data)
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar recebíveis.";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    [documentNumber, sortQuery],
  );

  const exportDiscountedWallet = useCallback(
    async (ranges?: number[] | null) => {
      try {
        const { data } = await service.moana.exportDiscountedWallet(
          buildQuery({
            documentNumber,
            ranges,
          }),
        );
        helpers.downloadBase64(data, "carteira-descontada.xlsx");
      } catch {
        OToastManager.danger("Não foi possível baixar o arquivo.");
      }
    },
    [documentNumber, sortQuery],
  );

  const exportOwnWallet = useCallback(
    async (ranges?: number[] | null) => {
      try {
        const { data } = await service.moana.exportOwnWallet(
          buildQuery({
            documentNumber,
            ranges,
          }),
        );
        helpers.downloadBase64(data, "carteira-propria.xlsx");
      } catch {
        OToastManager.danger("Não foi possível baixar o arquivo.");
      }
    },
    [documentNumber, sortQuery],
  );

  const getLimit = useCallback(async () => {
    const { data } = await service.hubCreditManager.getLimit({
      taxId: documentNumber,
      identification: "ANTECIPACAO_DE_RECEBIVEIS_BOLETOS",
      companyTaxId: "",
      userEmail: "",
      originSystem: "",
      originIP: "",
    });
    return data;
  }, [documentNumber]);

  return {
    dataSource,
    rows,
    setRows,
    page,
    setPage,
    form,
    onSubmit,
    search,
    resetFilter,
    setSortQuery,
    exportData,
    getLimit,
    totals,
    getDiscountedPortfolio,
    getOwnWallet,
    exportDiscountedWallet,
    exportOwnWallet,
  };
};
