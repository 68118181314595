import { service } from "services/service";
import {
  dataSourceCustomStoreGenerator,
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  ILimitBag,
  ILimitBagsWithDependents,
} from "services/limit-control/models/responses";
import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { useMemo } from "react";
import { ILimitBagWithDetailsGrid } from "./controlador-limite-cedente.types";
import { IUpdateLimitBagParams } from "services/limit-control/models/request";

const buildDataSource = (id: string) =>
  dataSourceCustomStoreGenerator<ILimitBagWithDetailsGrid["data"][number]>(
    () =>
      service.quickfin
        .getDependentDetails(id)
        .then(({ data }) => {
          const response: ILimitBagWithDetailsGrid = {
            data: data.dependents,
            totalCount: data.dependents.length,
          };

          return response;
        })
        .catch(() => {
          const errorMessage = "Erro ao buscar os sacados do cedente";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        remove: async ({ id }) => {
          try {
            await service.limitControl.deleteDependentLimitBag(id);
            OToastManager.success("Limite por sacado removido com sucesso");
          } catch {
            const errorMessage = "Erro ao remover limite por sacado";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async ({ id }, { totalAmount }) => {
          try {
            const body: IUpdateLimitBagParams = { totalAmount: totalAmount };
            await service.limitControl.updateLimitBag(id, body);
            OToastManager.success(
              "Limite por sacado atualizado com sucesso",
            );
          } catch {
            const errorMessage = "Erro ao modificar limite por sacado";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const limiteCedenteDetailsGrid: ODataGridGeneratorConfig<ILimitBagsWithDependents> =
  {
    datagrid: {
      noDataText: "Nenhuma credenciadora cadastrada.",
      filterRow: {
        visible: false,
      },
      editing: {
        allowDeleting: true,
        allowUpdating: true,
        mode: "row",
        texts: {
          confirmDeleteMessage:
            "Tem certeza que deseja esse limite por sacado?",
        },
      },
    },
    columns: [
      {
        dataField: "id",
        dataType: "string",
        caption: "CNPJ do Sacado",
        allowFiltering: false,
        allowEditing: false,
        allowExporting: true,
        customizeText: ({ value }) =>
          masks.cnpj(value.replace(/QuickFin:Cartao:V2:[0-9]+:/gm, "")),
      },
      {
        dataField: "name",
        dataType: "number",
        caption: "Nome do Sacado",
        allowFiltering: false,
        allowEditing: false,
        allowExporting: true,
      },
      {
        dataField: "freeAmount",
        dataType: "number",
        caption: "Limite disponível",
        allowFiltering: false,
        allowEditing: false,
        allowExporting: true,
        format: (value) => masks.currency(value, "R$"),
      },
      {
        dataField: "consumedAmount",
        dataType: "number",
        caption: "Limite consumido",
        allowFiltering: false,
        allowEditing: false,
        allowExporting: true,
        format: (value) => masks.currency(value, "R$"),
      },
      {
        dataField: "totalAmount",
        dataType: "number",
        caption: "Limite total",
        allowFiltering: false,
        allowEditing: true,
        allowExporting: true,
        format: (value) => masks.currency(value, "R$"),
        editorOptions: {
          format: {
            style: "currency",
            currency: "BRL",
            useGrouping: true,
          },
        },
      },
    ],
  };

export const LimiteCedenteMasterDetail = ({
  data: {
    data: { id },
  },
}: MasterDetailComponentProps<ILimitBag>) => {
  const dataSource = useMemo(() => buildDataSource(id), [id]);
  return (
    <ODataGridGenerator
      grid={limiteCedenteDetailsGrid}
      dataSource={dataSource}
    />
  );
};
