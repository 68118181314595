import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { service } from "services/service";
import { resendBillSuccessSchema } from "./reenviar-boleto-sucesso.form";

export interface ResentBillSuccessFormValues {
  otherEmail: string;
}

export const useResendBoletoSuccess = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm<ResentBillSuccessFormValues>({
    resolver: yupResolver(resendBillSuccessSchema),
  });
  const { handleSubmit } = form;
  const { value: idAgreement, hasError } =
    useParseFromSearchParams<string>("id-agreement");

  const onSubmit = handleSubmit(async (values: ResentBillSuccessFormValues) => {
    if (!idAgreement) {
      return;
    }

    setLoading(true);

    try {
      await service.renegotiation.postAgreementFirstPayment(idAgreement, {
        email: values.otherEmail,
      });

      OToastManager.success("Boleto enviado com sucesso!");
    } catch {
      OToastManager.danger("Erro ao efetuar o reenvio do boleto");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    hasError,
    idAgreement,
    loading,
    onSubmit,
  };
};
