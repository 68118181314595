import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { bigNumbersGrid } from "./big-numbers.grid";

export const WorkflowBigNumbersPage = () => {
  return (
    <DetailsTemplate pageTitle={<PageTitle title="Big Numbers" />}>
      <GridTemplate
        actions={
          <>
            <RouterButton
              dataAction="big_numbers:botao:adicionar_big_number"
              dataLabel="adicionar_big_number"
              role={roles.workflow.product.configurationsAddBigNumberPage.role}
              href={
                corporateRouter.routes.workflow.product.configurations
                  .bigNumbers.add.path
              }
            >
              Adicionar
            </RouterButton>
            <RefreshGridButton
              onClick={() => {
                !bigNumbersGrid.datagrid.dataSource?.isLoading() &&
                  bigNumbersGrid.datagrid.dataSource?.reload();
              }}
            />
          </>
        }
      >
        <ODataGridGenerator grid={bigNumbersGrid} />
      </GridTemplate>
    </DetailsTemplate>
  );
};