import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { AddFranchisePage } from "../pages/produto/franquias/adicionar";
import { UpdateFranchisePage } from "../pages/produto/franquias/editar";
import { FranchisePage } from "../pages/produto/franquias/franquias.page";
import { franquiasRoles } from "../roles/franquias.roles";
import { franquiasRouteParams } from "./franquias.route-params";

export const franquiasRoutes = {
  product: {
    name: "Franquias",
    path: "/franquias/produto",
    element: <RedirectWithRoles />,
    role: franquiasRoles.product.tab.role,
    children: {
      franquias: {
        name: "Todas as franquias",
        path: "/franquias/produto/franquias",
        role: franquiasRoles.product.franchise.role,
        element: <FranchisePage />,
        children: {
          register: {
            name: "Adicionar",
            path: `/franquias/produto/franquias/adicionar`,
            role: franquiasRoles.product.addFranchise.role,
            element: <AddFranchisePage />,
          },
          details: {
            name: "Detalhes",
            path: `/franquias/produto/franquias/:${franquiasRouteParams.id}`,
            role: franquiasRoles.product.updateFranchise.role,
            element: <UpdateFranchisePage />,
          },
        },
      },
    },
  },
} as const satisfies Routes;
