import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { AxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  PostCalendarBody,
  PostCalendarResponse,
} from "services/legacys/models";
import { service } from "services/service";
import {
  calendarDefaultValues,
  calendarSchema,
} from "../_compose/calendar-form/calendar.form";

export const useAddCalendar = () => {
  const { loading, callService: createCustomCalendar } = useServiceCall(
    service.legacys.createCustomCalendar,
  );
  const form = useForm({
    resolver: yupResolver(calendarSchema),
    defaultValues: calendarDefaultValues,
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (values) => {
    const hasInputedOffDay = values.offDays.some((od) => !od.isBase);

    if (!hasInputedOffDay) {
      OToastManager.warning(
        "Inclua ao menos um feriado adicional para salvar o calendário personalizado.",
      );
      return;
    }

    const body: PostCalendarBody = {
      name: values.name,
      baseCalendarId: values.baseCalendarId!,
      offDays: values.offDays.map((offDay) => ({
        ...offDay,
        date: new Date(offDay.date),
      })),
    };

    const { success, error } = await createCustomCalendar(body);

    if (!success) {
      if (error) {
        const { response } = error as AxiosError<PostCalendarResponse>;
        if (response && response.status === 400) {
          const { validationErrors = [] } =
            response.data as PostCalendarResponse;
          if (validationErrors.length) {
            OToastManager.danger(
              validationErrors.map((errors) => errors.message).join(" "),
            );
          }
          return;
        }
      }
      return OToastManager.danger(
        "Não foi possível criar o calendário, tente novamente mais tarde!",
      );
    }

    OToastManager.success("O calendário foi criado com sucesso.");
    navigate(corporateRouter.routes.recebiveis.product.calendars.path);
  });

  return {
    form,
    loading,
    onSubmit,
  };
};
