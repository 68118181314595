import { OInputText } from "@maestro/react";
import { masks } from "@maestro/utils";
import { InputContent, InputLabel } from "../compose.styles";
import { IInputTelProps } from "./question-text-compose.types";

export const InputTel = ({
  name,
  label,
  placeholder,
  isEditable,
  fields,
  handleInputChange,
  errors,
}: IInputTelProps) => {
  return (
    <div key={name}>
      <InputLabel>{label}</InputLabel>
      <InputContent>
        <OInputText
          dataAction={`chargeback:input:${label}`}
          dataLabel={label}
          id={name}
          name={name}
          value={fields[name]}
          placeholder={placeholder}
          disabled={!isEditable}
          onInput={(e) => {
            handleInputChange({
              name: name,
              value: masks.phone(e.currentTarget.value),
              label: label,
            });
          }}
        />
        {errors[name] && <span>{errors[name]}</span>}
      </InputContent>
    </div>
  );
};
