import React from "react";
import { service } from "services";
import { ApiSelector } from "../../../../../../../components/api-selector";

interface DocumentTypeSelectorProps
  extends Omit<
    React.ComponentProps<typeof ApiSelector>,
    "optionMapper" | "serviceMethod"
  > {
  valueType: "id" | "name";
}

export const DocumentTypeSelector = ({
  valueType,
  ...rest
}: DocumentTypeSelectorProps) => {
  return (
    <ApiSelector
      {...rest}
      optionMapper={(value) => {
        const options =
          value?.response?.map(
            ({ name: typeName, label, description, id }) => ({
              content: `${label} - ${description}`,
              value: valueType === "id" ? id : typeName,
            }),
          ) ?? [];
        options.sort((a, b) => a.content.localeCompare(b.content));
        return options;
      }}
      serviceMethod={service.hubEnergy.getDocumentTypes}
    />
  );
};
