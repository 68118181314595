export const customerServiceRoles = [
  // Cadastro
  "admin:OnboardingTabCustomer.View",
  "admin:OnboardingDetailsEloaApproversPageCustomer.View",
  "admin:OnboardingDetailsOnboardingReviewsPageCustomer.View",
  "admin:OnboardingDetailsEnableCurrentAccountPageCustomer.View",
  "admin:OnboardingOnboardingsPageCustomer.View",
  "admin:OnboardingGuarantorsPageCustomer.View",
  "admin:OnboardingGuarantorsGuarantorsDetailsPageCustomer.View",
  "admin:OnboardingTermsPageCustomer.View",
  "admin:OnboardingCompanyPowersPageCustomer.View",
  // Usuários
  "admin:UserTabCustomer.View",
  "admin:UserDetailsPageCustomer.View",
  "admin:UserNotificationsEmailPageCustomer.View",
  "admin:UserNotificationsPushPageCustomer.View",
  // Antecipação a Fornecedor
  "admin:AntecipacaoAFornecedorTabCustomer.View",
  "admin:AntecipacaoAFornecedorOperationsPageCustomer.View",
  "admin:AntecipacaoAFornecedorOperationsDetailsPageCustomer.View",
  "admin:AntecipacaoAFornecedorReceivablesPageCustomer.View",
  "admin:AntecipacaoAFornecedorReceivablesSacadoPageCustomer.View",
  // B2B
  "admin:B2BTabCustomer.View",
  "admin:B2BOperatorsPageCustomer.View",
  "admin:B2BOriginatorPageCustomer.View",
  "admin:B2BCustomersPageCustomer.View",
  "admin:B2BCustomersCompanyUsersPageCustomer.View",
  "admin:B2BEnableCurrentAccountPageCustomer.View",
  // Câmbio
  "admin:FXTabCustomer.View",
  "admin:FXQuotesPageCustomer.View",
  "admin:FXQuotesDetailsPageCustomer.View",
  "admin:FXOrdersPageCustomer.View",
  "admin:FXOrdersDetailsPageCustomer.View",
  "admin:FXPreTicketsPageCustomer.View",
  "admin:FXPreTicketsDetailsPageCustomer.View",
  "admin:FXLimitsPageCustomer.View",
  "admin:FXLimitsDetailsPageCustomer.View",
  // Cartões
  "admin:CardsTabCustomer.View",
  // Conta Corrente
  "admin:BankingTabCustomer.View",
  "admin:BankingAccountsPageCustomer.View",
  "admin:BankingAccountsAccountAgencyBalancePageCustomer.View",
  "admin:BankingAccountsAccountAgencyDailyBalancePageCustomer.View",
  "admin:BankingAccountsAccountAgencyStatementPageCustomer.View",
  "admin:BankingBankslipListPageCustomer.View",
  "admin:BankingOverdraftPageCustomer.View",
  "admin:BankingOverdraftInstallmentsPageCustomer.View",
  "admin:BankingOverdraftGuarantorsPageCustomer.View",
  "admin:BankingTransfersScheduledPageCustomer.View",
  "admin:BankingTransfersSentPageCustomer.View",
  "admin:BankingPaymentsScheduledPageCustomer.View",
  "admin:BankingPaymentsSentPageCustomer.View",
  // Empréstimos
  "admin:LoanTabCustomer.View",
  "admin:LoanQuotesPageCustomer.View",
  "admin:LoanQuotesDetailsPageCustomer.View",
  "admin:LoanLimitManagerPageCustomer.View",
  "admin:LoanLimitManagerDetailsQuotesPageCustomer.View",
  "admin:LoanLimitManagerDetailsGuarantorsPageCustomer.View",
  // Folha de Pagamento
  "admin:FopaTabCustomer.View",
  "admin:FopaDetailsPageCustomer.View",
  "admin:FopaPaymentsPageCustomer.View",
  "admin:FopaPaymentsPaymentDetailsPageCustomer.View",
  "admin:FopaOnboardingsPageCustomer.View",
  // Renegociação/Quitação
  "admin:RenegociacoesTabCustomer.View",
  "admin:RenegociacoesMonitorConditionsPageCustomer.View",
  "admin:RenegociacoesMonitorHistoryPageCustomer.View",
  "admin:RenegociacoesMonitorHistoryDetailsPageCustomer.View",
  "admin:RenegociacoesMonitorHistoryDetailsSuccessPageCustomer.View",
  // Mercado Livre de Energia
  "admin:MLETabCustomer.View",
  "admin:MLEConsumerUnitDetailsPageCustomer.View",
  "admin:MLEConsumerUnitTradesDocumentsPageCustomer.View",
  "admin:MLEConsumerUnitTradesPageCustomer.View",
];
