import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import isEqual from "lodash/isEqual";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { BankAccountsItem } from "services/admin-bankinghub/models";
import { GetContactByIdResponseItem } from "services/bankinghub/models";
import { useFinancialInstitutionsList } from "hooks/financial-institution-list";
import { ContactsFavoredEditByUuidRouteParams } from "../../../../../cadastro/routes/cadastro.route-params";
import { FormValuesType, validationSchema } from "../_compose";
import { prepareToRequest } from "../contacts.utils";
import { assembleDefaultBankAccountValues } from "./contacts-favored-edit.utils";

export const useContactsFavoredEdit = () => {
  const { uuid } = useParams<ContactsFavoredEditByUuidRouteParams>();
  if (!uuid) throw new Error("No id in route parameters");

  const { financialInstitutions } = useFinancialInstitutionsList();
  const navigate = useNavigate();
  const { state }: { state: FormValuesType } = useLocation();

  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<
    GetContactByIdResponseItem | undefined
  >();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues: state,
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      if (!contact) return;

      const newBankAccount = prepareToRequest(values, uuid);
      const oldBankAccount = contact.product.bankAccounts?.find(
        ({ id }) => id === uuid,
      );

      if (isEqual(newBankAccount, oldBankAccount))
        return navigate(corporateRouter.routes.banking.customer.contacts.path);

      const bankAccounts = contact.product.bankAccounts?.map((account) =>
        account.id === uuid ? newBankAccount : account,
      );

      const payload = {
        ...contact,
        product: { ...contact.product, bankAccounts },
      };

      await service.adminBankinghub.updateContact(payload);

      OToastManager.success("Cadastrado com sucesso");

      navigate(corporateRouter.routes.banking.customer.contacts.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const resetValues = useCallback(
    (values?: BankAccountsItem) => {
      if (!values) return;

      reset(assembleDefaultBankAccountValues(values, financialInstitutions));
    },
    [reset, financialInstitutions],
  );

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.adminBankinghub.getContactByHolder();

      const getContact = data.find(({ product }) =>
        product.bankAccounts?.find(({ id }) => id === uuid),
      );

      const values = getContact?.product.bankAccounts?.find(
        (bank) => bank.id === uuid,
      );

      setContact(getContact);
      resetValues(values);
    } catch (error) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [resetValues, uuid]);

  useEffect(() => {
    load();
  }, [load]);

  return { form, submit, loading };
};
