export const manageDeliveryAddressFormModalFields = {
  zipCode: {
    tag: "cep",
    id: "zipCode",
    name: "zipCode",
    label: "CEP",
    placeholder: "Insira o código postal do endereço",
  },
  street: {
    tag: "text",
    id: "street",
    name: "street",
    label: "Endereço",
    placeholder: "Insira a rua do endereço",
  },
  number: {
    tag: "number",
    id: "number",
    name: "number",
    label: "Número",
    placeholder: "Insira o número",
  },
  complement: {
    tag: "text",
    id: "complement",
    name: "complement",
    label: "Complemento",
    placeholder: "Insira o complemento",
    maxlength: 50,
  },
  neighborhood: {
    tag: "text",
    id: "neighborhood",
    name: "neighborhood",
    label: "Bairro",
    placeholder: "Insira o bairro",
  },
  city: {
    tag: "text",
    id: "city",
    name: "city",
    label: "Cidade",
    placeholder: "Insira a cidade",
  },
  stateId: {
    id: "stateId",
    name: "stateId",
    label: "Estado",
    placeholder: "Selecione o estado",
  },
  type: {
    id: "type",
    name: "type",
    label: "Tipo de endereço",
    placeholder: "Selecione o tipo de endereço",
  },
} as const;
