import { SelectSearchFieldV2 } from "../../../../../../../components/select-search-v2";
import { diligenceStepStatusMap } from "../../../../../../../utils";

interface StepStatusSelectorProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  dropdownPosition?: "top" | "bottom";
}

export const StepStatusSelector = ({
  label,
  name,
  multiple,
  placeholder,
  disabled,
  required,
  dropdownPosition,
}: StepStatusSelectorProps) => {
  return (
    <SelectSearchFieldV2
      options={Object.entries(diligenceStepStatusMap).map(
        ([type, { text }]) => ({ content: text, value: type }),
      )}
      id={name}
      name={name}
      placeholder={placeholder}
      label={label}
      required={required}
      disabled={disabled}
      multiple={multiple}
      dropdownPosition={dropdownPosition}
    />
  );
};
