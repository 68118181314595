import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import DataSource from "devextreme/data/data_source";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { GetApiAdminContractByIdResponseItem } from "services/hubloan/models";
import { logger } from "utils/logger";
import { loanOperationsContractStatusOptions } from "../../loan-operations.utils";
import {
  operationsContractStatusModalFormDefaultValues,
  operationsContractStatusModalFormValidationSchema,
} from "./operations-contracts-status-modal.form.schemas";
import { useContracts } from "./operations-contracts.context";

export const OPERATIONS_CONTRACT_STATUS_MODAL_ID =
  "operations-contract-status-modal";

interface ContractsStatusModalProps {
  dataSource: DataSource<GetApiAdminContractByIdResponseItem, unknown>;
}

export const ContractsStatusModal = ({
  dataSource,
}: ContractsStatusModalProps) => {
  const [loading, setLoading] = useState(false);

  const { selectedContract } = useContracts();

  const form = useForm({
    resolver: yupResolver(operationsContractStatusModalFormValidationSchema),
    defaultValues: operationsContractStatusModalFormDefaultValues,
  });

  const { getValues, reset } = form;

  const updateContractStatus = useCallback(async () => {
    if (!selectedContract) return;

    try {
      setLoading(true);

      await service.hubLoan.updateContractStatus({
        ...getValues(),
        contractId: selectedContract.id,
      });

      dataSource?.reload();
      modalManager.hide(OPERATIONS_CONTRACT_STATUS_MODAL_ID);
      OToastManager.success("Status do contrato atualizado com sucesso.");
    } catch (err) {
      logger.error(err);
      OToastManager.danger(
        "Ocorreu um erro ao atualizar o status do contrato.",
      );
    } finally {
      setLoading(false);
    }
  }, [getValues, selectedContract, dataSource]);

  useEffect(() => {
    if (selectedContract?.status) {
      reset({
        status: selectedContract.status,
      });
    }
  }, [selectedContract?.status, reset]);

  return (
    <OModal
      id={OPERATIONS_CONTRACT_STATUS_MODAL_ID}
      position="center"
      size="sm"
    >
      <OModalHeader>
        <OTypography size="lg" style={{ whiteSpace: "normal" }}>
          Editando o contrato {selectedContract?.id}
        </OTypography>
      </OModalHeader>
      <OModalBody className="p-2">
        <FormProvider {...form}>
          <div className="d-flex flex-column align-items-stretch gap-3">
            <ORFieldSelect
              id="status"
              name="status"
              placeholder="Selecionar:"
              label="Status"
            >
              {loanOperationsContractStatusOptions.map((option) => (
                <OOption value={option} key={option}>
                  {option}
                </OOption>
              ))}
            </ORFieldSelect>
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(OPERATIONS_CONTRACT_STATUS_MODAL_ID)
            }
          >
            Cancelar
          </OButton>
          <LoadingButton
            loading={loading}
            onClick={updateContractStatus}
            disabled={!selectedContract}
          >
            Salvar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
