import { OTypography } from "@maestro/react";
import { useEffect, useState } from "react";
import { RowSpacedContainer } from "../../cards.styles";
import { CardSection } from "../already-approved.styles";
import { IConfigItem, ISetupsProps } from "./functionsAndSetups.types";

export const Setups = ({ cardData }: ISetupsProps) => {
  const [setupsData, setSetupsData] = useState<IConfigItem[]>([]);

  useEffect(() => {
    const data: IConfigItem[] = [
      {
        key: "isTravelNoticeEnabled",
        label: "Aviso viagem",
        value: cardData.isTravelNoticeEnabled,
      },
    ];
    setSetupsData(data);
  }, [cardData]);

  return (
    <CardSection>
      <OTypography weight="bold" className="mt-3 mb-2">
        · Configurações
      </OTypography>
      <RowSpacedContainer>
        <div className="d-flex flex-column gap-3">
          {!!setupsData.length &&
            setupsData.map((setupData, index) => (
              <div className="d-flex flex-column gap-2" key={`item-setups-content-${index}`}>
                <OTypography weight="500">{setupData?.label}</OTypography>
                <OTypography weight="300" className="px-3">
                  {setupData?.value ? "Habilitado" : "Desabilitado"}
                </OTypography>
              </div>
            ))}
        </div>
      </RowSpacedContainer>
    </CardSection>
  );
};
