import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { AddDeskFormFields, addDeskSchema } from "./add-desk.form";

export const AddDesk = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<AddDeskFormFields>({
    resolver: yupResolver(addDeskSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.postDesk(values);

      OToastManager.success("Mesa adicionada com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível cadastrar a mesa.");
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar mesa" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                label="Código"
                name="code"
                id="code"
                required
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                label="Nome"
                name="name"
                id="name"
                required
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
