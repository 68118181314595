import { OToastManager, modalManager } from "@maestro/react";
import { useState } from "react";
import { service } from "services";
import { CancelAllBankslipsModalId } from "./cancel-all-bankslips-modal.types";

export const useCancelAllBankslips = () => {
  const [isLoading, setIsLoading] = useState(false);

  const cancelAllBankslips = async () => {
    try {
      setIsLoading(true);
      await service.adminBankinghub.cancelAllBankslips();
      OToastManager.success(
        "O cancelamento dos boletos está sendo processado, podendo demorar alguns minutos.",
      );
      modalManager.hide(CancelAllBankslipsModalId);
    } catch {
      OToastManager.danger("Ocorreu um erro ao cancelar os boletos.");
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, cancelAllBankslips };
};
