import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { buildCustomerLink } from "utils/build-customer-link";
import { getValueFromMap } from "utils/get-value-from-map";

const biometryStatusMap = {
  APROVADO: {
    title: "Aprovada",
    type: "success",
  },
  PENDENTE: { title: "Pendente", type: "warning" },
} as const;

const accountStatusMap = {
  CONCLUIDO: { title: "Concluído", type: "success" },
  ATIVO: { title: "Ativo", type: "success" },
  EDITADO: { title: "Editado", type: "info" },
  EM_ANALISE: { title: "Em análise", type: "info" },
  ANALISE_CANCELADA: { title: "Análise cancelada", type: "danger" },
  PENDENTE: { title: "Pendente", type: "warning" },
  PENDENTE_ENVIO: { title: "Pendente envio", type: "warning" },
  PENDENTE_AJUSTE: { title: "Ajuste pendente", type: "warning" },
  PENDENTE_BIOMETRIA: { title: "Biometria pendente", type: "warning" },
} as const;

export const guarantorsGrid = {
  datagrid: {
    noDataText: "Nenhum avalista encontrado.",
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "taxId",
      caption: "CPF",
      format: (value) => masks.cpf(value),
    },
    {
      dataField: "portalStatus",
      caption: "Dados cadastrais",
      cellRender: ({ data }) => {
        const status = getValueFromMap(accountStatusMap, data.portalStatus);
        return (
          <StatusBadge type={status?.type ?? "warning"}>
            {status?.title ?? status}
          </StatusBadge>
        );
      },
    },
    {
      dataField: "biometryStatusType",
      caption: "Biometria",
      cellRender: ({ data }) => {
        const status = getValueFromMap(
          biometryStatusMap,
          data.biometryStatusType,
        );
        return (
          <StatusBadge type={status?.type ?? "warning"}>
            {status?.title ?? status}
          </StatusBadge>
        );
      },
    },
    {
      cellRender: ({ component, rowIndex, row, data }) => {
        return (
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  label: "Editar",
                  icon: { category: "fal", icon: "fa-edit" },
                  route: buildCustomerLink(
                    data.customerTaxId,
                    corporateRouter.routes.cadastro.customer.guarantors.guarantorsEmbedded.path(
                      {
                        guarantorUuid: data.uuid,
                      },
                    ),
                  ),
                  visible: data.canEditGuarantor,
                  role: roles.cadastro.customer.guarantorsGuarantorsEmbeddedPage
                    .role,
                },
                {
                  label: "Excluir",
                  icon: { category: "fal", icon: "fa-trash" },
                  onClick: () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          )
        );
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboardingRelationships.b2c.loadGuarantors>
  >["data"]["guarantorsResponse"][number] & {
    limitGuarantorId?: number;
    customerTaxId: string;
  }
>;
