import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services/service";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const dataSource = dataSourceCustomStoreGenerator<ScfDevelopers.Partner>(
  () =>
    service.scfDevelopers
      .fetchPartnersList()
      .then(({ data }) => ({
        data,
        totalCount: data.length,
      }))
      .catch(() => {
        const errorMessage = "Erro ao obter listagem de parceiros";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      update: async (original, updated) => {
        try {
          const payload = {
            partner: {
              ...original,
              ...updated,
            },
          };

          await service.scfDevelopers.upsertPartner(payload);
          OToastManager.success("Parceiro atualizado com sucesso");
        } catch {
          OToastManager.danger("Falha ao atualizar parceiro.");
        }
      },
      remove: async ({ authClientId }) => {
        try {
          if (!authClientId) {
            throw new Error("Missing clientId");
          }

          const payload = {
            authClientId,
          };

          await service.scfDevelopers.removePartner(payload);
          OToastManager.success("Parceiro excluído com sucesso");
        } catch {
          OToastManager.danger("Falha ao excluir parceiro.");
        }
      },
    },
  },
);

export const partnersGrid: ODataGridGeneratorConfig<ScfDevelopers.Partner> = {
  datagrid: {
    noDataText: "Nenhum parceiro encontrado.",
    filterRow: {
      visible: true,
    },
    pager: {
      infoText: "Página {0} de {1} ({2} parceiros)",
    },
    editing: {
      allowUpdating: true,
      allowDeleting: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      dataField: "authClientId",
      caption: "authClientId",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "taxId",
      caption: "CNPJ/CPF",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "isInternal",
      caption: "É parceiro interno",
      dataType: "boolean",
      alignment: "center",
      allowSorting: false,
    },
    {
      dataField: "legacyRelationalId",
      caption: "Id de relacionamento legado",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
  ],
};
