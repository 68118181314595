import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetOdataEstrategiasComerciaisOdataResponse } from "services/quickfin/models";

export const commercialStrategiesGrid: ODataGridGeneratorConfig<
  GetOdataEstrategiasComerciaisOdataResponse["value"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma estratégia encontrada.",
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "Nome",
      caption: "Nome",
    },
    {
      dataField: "UniqueId",
      caption: "Id único",
    },
    {
      dataField: "TipoProduto",
      caption: "Tipo produto",
    },
    {
      dataField: "Currency",
      caption: "Moeda",
    },
    {
      dataField: "CapitalizationType",
      caption: "Tipo de capitalização",
    },
    {
      dataField: "CapitalizationBase",
      caption: "Base de capitalização",
    },
    {
      dataField: "TaxaPrazos",
      caption: "Taxa prazos",
    },
    {
      dataField: "RebateType",
      caption: "Tipo de rebate",
    },
    {
      dataField: "RebateValue",
      caption: "Valor do rebate",
    },
  ],
};
