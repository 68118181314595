import { client } from "../../client";
import { hubfxEndpoints } from "../hubfx.endpoints";
import { GetAdminCustomerIncorrectOrdersResponse } from "../models/responses";
import { GetAdminCustomerLimitByIdResponse } from "./models/responses/get-admin-customer-limit-by-id.response";
import { GetAdminCustomerLimitResponse } from "./models/responses/get-admin-customer-limit.response";
import { GetAdminCustomerOrderByIdResponse } from "./models/responses/get-admin-customer-order-by-id.response";
import { GetAdminCustomerOrderResponse } from "./models/responses/get-admin-customer-order.response";
import { GetAdminCustomerPreTicketByIdResponse } from "./models/responses/get-admin-customer-pre-ticket.-by-id.response";
import { GetAdminCustomerPreTicketResponse } from "./models/responses/get-admin-customer-pre-ticket.response";
import { GetAdminCustomerQuoteByIdResponse } from "./models/responses/get-admin-customer-quote-by-id.response";
import { GetAdminCustomerQuoteResponse } from "./models/responses/get-admin-customer-quote.response";

export const hubFxAdminCustomerService = {
  getQuotes() {
    return client.get<GetAdminCustomerQuoteResponse>(
      hubfxEndpoints.admin.customer.quote,
    );
  },
  getQuoteDetails(quoteId: number) {
    return client.get<GetAdminCustomerQuoteByIdResponse>(
      hubfxEndpoints.admin.customer.quoteById(quoteId),
    );
  },
  getOrders() {
    return client.get<GetAdminCustomerOrderResponse>(
      hubfxEndpoints.admin.customer.order,
    );
  },
  getOrderDetails(orderId: string) {
    return client.get<GetAdminCustomerOrderByIdResponse>(
      hubfxEndpoints.admin.customer.orderById(orderId),
    );
  },
  getPreTickets() {
    return client.get<GetAdminCustomerPreTicketResponse>(
      hubfxEndpoints.admin.customer.preTicket,
    );
  },
  getPreTicketDetails(preTicketId: string) {
    return client.get<GetAdminCustomerPreTicketByIdResponse>(
      hubfxEndpoints.admin.customer.preTicketById(preTicketId),
    );
  },
  getLimits() {
    return client.get<GetAdminCustomerLimitResponse>(
      hubfxEndpoints.admin.customer.limit,
    );
  },
  getLimitDetails(limitId: string) {
    return client.get<GetAdminCustomerLimitByIdResponse>(
      hubfxEndpoints.admin.customer.limitById(limitId),
    );
  },
  getIncorrectOrders() {
    return client.get<GetAdminCustomerIncorrectOrdersResponse>(
      hubfxEndpoints.admin.customer.incorrectOrders,
    );
  },
};
