import moment from "moment";
import { MoneyMask } from "../../utils";
import {
  Currency,
  ItemCategory,
  ItemType,
  MapperCardType,
  MapperItemCategoryAndType,
} from "../cards.types";
import { ITransactionRelatedItem } from "../compose/transaction-details/related-items/related-items.types";
import { ITransactionDetailsData } from "../compose/transaction-details/transaction-details.types";
import { ITimelineItem } from "../timeline/timeline.types";
import { capitalizePhraseFirstLetters } from "./cases";

export const isTransaction = (type: ItemType): boolean => {
  return type === ItemType.Confirmation || type === ItemType.Transaction;
};

export const isTimelineItemWithoutAmount = (type: ItemType): boolean => {
  return type !== ItemType.Confirmation && type !== ItemType.Transaction;
};

export const isInvoicePayment = (
  type: ItemType,
  category: ItemCategory,
): boolean => {
  return (
    type === ItemType.Statement &&
    (category === ItemCategory.Payment ||
      category === ItemCategory.PartialPayment)
  );
};

const isValidChargebackTransactionCategory = (
  category: ItemCategory,
): boolean => {
  return (
    category === ItemCategory.Authorize || category === ItemCategory.Replacement
  );
};

export const isChargebackTransactionFromTimelineItem = (
  type: ItemType,
  category: ItemCategory,
  isCredit: boolean,
): boolean => {
  return (
    isTransaction(type) &&
    isValidChargebackTransactionCategory(category) &&
    !isCredit
  );
};

export const formContestationDescriptionFromTimelineItem = (
  item: ITimelineItem,
): string => {
  const base =
    item.type === ItemType.Transaction
      ? `1 (${MapperItemCategoryAndType[item.category]})`
      : "2";
  return capitalizePhraseFirstLetters(
    `Transação Base ${base} | ${item.data.item?.entryModeLiteral} - ${item.data.item?.subTitle}: ${item.data.item?.toName}`,
  );
};

export const formTransactionDetailsFromTimelineItem = (
  item: ITimelineItem,
): ITransactionDetailsData => {
  return {
    authorizationId: item.data.item?.id.toString(),
    date: moment(`${item.timestamp}Z`).format("DD/MM/YYYY"),
    hour: moment(`${item.timestamp}Z`).format("HH:mm:ss"),
    amount: MoneyMask(item.data.item?.principalAmount, Currency.BRL),
    cardType: MapperCardType[item.data.item?.cardType],
    cardLast4Digits: item.data.item?.cardLast4Digits,
    via: item.data.item?.authorizationDescription,
    category: item.data.item?.merchantCategoryGroup,
    isCredit: item.data.item.isCredit,
    type: item.data.item?.isInternational
      ? "Compra internacional"
      : "Compra nacional",
    merchantName: item.data.item?.merchantName,
    description: item.data.item?.subTitle,
    itemType: item.type,
    itemCategory: item.category,
    refusalReason: item.data.item?.refusalReason,
    attendantRefusalReason: item.data.item?.attendantRefusalReason,
    itemOrigin: "Timeline",
    dcc: item.data.item?.dcc ? "Sim" : "Não",
    localCurrencyAmount: MoneyMask(
      item.data.item?.localAmount,
      item.data.item?.currencyLiteral,
    ),
    settlementCurrencyAmount: MoneyMask(
      item.data.item?.settlementCurrencyAmount,
      item.data.item?.settlementCurrencyLiteral,
    ),
    settlementCurrencyRate:
      (item.data.item?.settlementCurrencyRate &&
        MoneyMask(
          Math.pow(item.data.item?.settlementCurrencyRate, -1),
          Currency.BRL,
        )) ||
      undefined,
    iofValue: MoneyMask(item.data.item?.tax[0]?.value, Currency.BRL),
  };
};

export const formTransactionRelatedItems = (data: ITimelineItem) => {
  return data.relatedItems?.map((item) => {
    const relatedItem: ITransactionRelatedItem = {
      type: item.type,
      category: item.category,
      timestamp: item.timestamp,
      correlationId: item.correlationId,
      title: item.data.item?.title,
      subTitle: item.data.item?.subTitle,
      authorizationDescription: item.data.item?.authorizationDescription,
      cardLast4Digits: item.data.item?.cardLast4Digits,
      principalAmount: item.data.item?.principalAmount,
    };
    return relatedItem;
  });
};

export const eventFilterExpression = () => {
  return [
    [
      [
        ["type", "notcontains", ItemType.Transaction],
        "and",
        ["type", "notcontains", ItemType.Confirmation],
        "and",
        ["type", "notcontains", ItemType.Statement],
      ],
      "or",
      [
        [
          ["type", "contains", ItemType.Statement],
          "and",
          ["category", "notcontains", ItemCategory.Payment],
        ],
        "and",
        [
          ["type", "contains", ItemType.Statement],
          "and",
          ["category", "notcontains", ItemCategory.PartialPayment],
        ],
      ],
    ],
  ];
};

export const transactionFilterExpression = () => {
  return [
    [
      ["type", "contains", ItemType.Transaction],
      "or",
      ["type", "contains", ItemType.Confirmation],
      "or",
      [
        [
          ["type", "contains", ItemType.Statement],
          "and",
          ["category", "contains", ItemCategory.Payment],
        ],
        "or",
        [
          ["type", "contains", ItemType.Statement],
          "and",
          ["category", "contains", ItemCategory.PartialPayment],
        ],
      ],
    ],
  ];
};
