import styled from "styled-components";

export const ColumnGap = styled.div<{
  rowGap: number;
  alignItems?: "start" | "center" | "end";
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems ?? "inherit"};
  row-gap: ${(props) => `${props.rowGap}px`};
`;

export const RowGap = styled.div<{
  columnGap: number;
}>`
  display: flex;
  flex-direction: row;
  column-gap: ${(props) => `${props.columnGap}px`};
  height: fit-content;
`;

export const NoDataFound = styled.div`
  display: flex;
  align-items: center;
`;

export const CardTitleWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-size: 21px;
    :hover {
      cursor: pointer;
      color: #1890ff;
    }
  }
`;

export const RowSpacedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .icon-button {
    font-size: 21px;
    :hover {
      color: #1890ff;
      cursor: pointer;
    }
  }
`;

export const DollarPriceContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  color: black;
  font-weight: 600;
  gap: 2;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 10px;
  max-width: 825px;
`;

export const InfoGrid = styled.div<{
  columns: number;
  columnsWidth: number;
  gridGap?: number;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.columns}, ${props.columnsWidth}px)`};
  grid-gap: ${(props) => (props.gridGap ? `${props.gridGap}px` : "15px")};
`;
