import { useSearchParams } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { OToastManager, modalManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  buildPayload,
  duplicatasNoteFilterModalId,
  filtersToSearchParams,
  getSearchParamsNoteFilter,
  newDuplicatasFilterDefaultValues,
  isEmpty,
} from "./duplicatas-note-filter.utils";

export const useDuplicatasNote = (documentNumberProp: string) => {
  const initializedParams = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, rows, documentNumber] = useMemo(() => {
    const initiadedPage = searchParams.get("page");
    const initiadedRows = searchParams.get("rows");
    const initiadedDocumentNumber = searchParams.get("documentNumber");
    return [
      initiadedPage ? +initiadedPage : 1,
      initiadedRows ? +initiadedRows : 10,
      initiadedDocumentNumber ?? documentNumberProp,
    ];
  }, [searchParams]);
  const [cedenteId, setCedenteId] = useState("");

  const [searchParamsNoteFilter, setSearchParamsNoteFilter] = useState(
    getSearchParamsNoteFilter(searchParams, initializedParams.current),
  );
  const form = useForm({
    defaultValues: searchParamsNoteFilter,
  });
  const { handleSubmit, reset } = form;

  const {
    callService: getSummary,
    loading: loadingSummary,
    value: summary,
  } = useServiceCall(service.quickfin.getReceivablesSummary);

  const { callService: getCompanyByTaxId } = useServiceCall(
    service.quickfin.getCompanyByTaxId,
  );

  const {
    callService: getDataSource,
    value: dataSource,
    loading: loadingDataSource,
  } = useServiceCall(service.quickfin.getReceivablesTitles);

  useEffect(() => {
    const newSearchParamsNoteFilter = getSearchParamsNoteFilter(
      searchParams,
      initializedParams.current,
    );
    if (!isEqual(newSearchParamsNoteFilter, searchParamsNoteFilter)) {
      setSearchParamsNoteFilter(newSearchParamsNoteFilter);
      initializedParams.current = true;
    }
  }, [searchParams, searchParamsNoteFilter]);

  const setPage = (newPage: number) => {
    setSearchParams((prev) => {
      if (prev.has("page")) prev.delete("page");
      prev.append("page", newPage.toString());
      return prev;
    });
  };
  const setRows = (newRows: number) => {
    setSearchParams((prev) => {
      if (prev.has("rows")) prev.delete("rows");
      prev.append("rows", newRows.toString());
      return prev;
    });
  };

  const parsedPayload = useMemo(
    () => buildPayload(searchParamsNoteFilter, cedenteId),
    [searchParamsNoteFilter, cedenteId],
  );

  const resetFilter = () => {
    setSearchParams([
      ...Array.from(
        filtersToSearchParams(newDuplicatasFilterDefaultValues).entries(),
      ),
      ...Object.entries({
        page: "1",
        rows: "10",
        cedenteId,
      }),
    ]);
    const parsedInitialBody = buildPayload(
      newDuplicatasFilterDefaultValues,
      cedenteId,
    );
    reset(newDuplicatasFilterDefaultValues);
    getSummary(parsedInitialBody);
    getDataSource({ ...parsedInitialBody, page: 1, rows });
    modalManager.hide(duplicatasNoteFilterModalId);
  };

  const onSubmit = handleSubmit(async (values) => {
    setSearchParams([
      ...Array.from(filtersToSearchParams(values).entries()),
      ...Object.entries({
        page: "1",
        rows: rows.toString(),
        cedenteId,
      }),
    ]);
    modalManager.hide(duplicatasNoteFilterModalId);
  });

  const refetchData = () => {
    getSummary(parsedPayload);
    getDataSource({ ...parsedPayload, page, rows });
  };

  const exportAllData = useCallback(async () => {
    try {
      const { data: blob } = await service.quickfin.exportAllNotes(
        parsedPayload,
      );
      helpers.downloadBlobFile("recebiveis_nota_a_nota.xlsx", blob);
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [parsedPayload]);

  useEffect(() => {
    if (!searchParams.size && !!cedenteId) {
      setSearchParams([
        ...Array.from(
          filtersToSearchParams(newDuplicatasFilterDefaultValues).entries(),
        ),
        ...Object.entries({
          page: "1",
          rows: "10",
          documentNumber: documentNumberProp,
          cedenteId,
        }),
      ]);
    }

    if (searchParams.size && !isEmpty(parsedPayload.cedenteIdList))
      getSummary(parsedPayload);
  }, [parsedPayload]);

  useEffect(() => {
    if (searchParams.size && !isEmpty(parsedPayload.cedenteIdList))
      getDataSource({ ...parsedPayload, page, rows });
  }, [parsedPayload, page, rows]);

  useEffect(() => {
    const getCompany = async () => {
      const { response } = await getCompanyByTaxId(documentNumber);
      if (!response?.data) return;
      setCedenteId(response.data.id.toString());
    };
    if (!cedenteId && documentNumber) getCompany();
  }, [cedenteId, documentNumber]);

  return {
    dataSource: dataSource ? dataSource.receivables : [],
    loadingDataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  };
};
