import { MaestroFields } from "utils/types";
import { RoleAssignmentsForm } from "../role-assignments.types";

export const basicFields = {
  taxId: {
    tag: "cpf",
    dataAction: "formulario:texto:cpf",
    dataLabel: "cpf",
    id: "taxId",
    name: "taxId",
    label: "CPF",
    placeholder: "Preencher",
    required: true,
  },
  name: {
    tag: "text",
    dataAction: "formulario:texto:nome",
    dataLabel: "nome",
    id: "name",
    name: "name",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  email: {
    tag: "text",
    dataAction: "formulario:texto:email",
    dataLabel: "email",
    id: "email",
    name: "email",
    label: "E-mail",
    placeholder: "Preencher",
    required: true,
  },
  phoneNumber: {
    dataAction: "formulario:texto:celular",
    dataLabel: "celular",
    id: "phoneNumber",
    name: "phoneNumber",
    label: "Celular",
    placeholder: "Preencher",
    required: true,
  },
  sendEmail: {
    dataAction: "formulario:checkbox:enviar_email",
    dataLabel: "enviar_email",
    id: "sendEmail",
    name: "sendEmail",
  },
} as const satisfies MaestroFields<RoleAssignmentsForm>;
