import * as PaymentsEnums from "services/api/models/types/payments/enums";
import {
  DataGridCellContentProps,
  DataGridStatusProps,
  DataSourceType,
  Filters,
  PaymentTypeGroup,
} from "./_compose";

export const INITIAL_PAGE_SIZE = 10;

export const INITIAL_PAGE_NUMBER = 1;

export const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

export interface Pagination {
  pageSize: number;
  pageNumber: number;
}

export interface UpdateDataSourceParams {
  filters?: Filters;
  pagination?: Pagination;
  cursor?: string;
  cursorOrigin?: string;
}

export interface DataSourceItem {
  paymentDate: DataGridCellContentProps;
  sender: DataGridCellContentProps;
  receiver: DataGridCellContentProps;
  amount: DataGridCellContentProps;
  status: DataGridStatusProps;
  product: DataGridCellContentProps;
  metaData: MetaData;
  id: string;
}

export type MetaData = Record<string, unknown>;

export const MapperPaymentTypeGroupToPaymentType: Record<
  PaymentTypeGroup,
  ApiPayments.PaymentType[]
> = {
  [PaymentTypeGroup.Bankslip]: [PaymentsEnums.PaymentType.Bankslip],
  [PaymentTypeGroup.Pix]: [
    PaymentsEnums.PaymentType.PixManual,
    PaymentsEnums.PaymentType.PixKey,
    PaymentsEnums.PaymentType.PixQrCode,
    PaymentsEnums.PaymentType.PixReversal,
  ],
  [PaymentTypeGroup.Ted]: [PaymentsEnums.PaymentType.Ted],
  [PaymentTypeGroup.Tef]: [PaymentsEnums.PaymentType.Tef],
  [PaymentTypeGroup.Darf]: [PaymentsEnums.PaymentType.Darf],
  [PaymentTypeGroup.Utilities]: [PaymentsEnums.PaymentType.Utilities],
  [PaymentTypeGroup.CreditCardInvoice]: [PaymentsEnums.PaymentType.Bankslip],
};

export const MapperDataSourceTypeToPaymentStatus: Record<
  DataSourceType,
  ApiPayments.PaymentStatus[] | undefined
> = {
  [DataSourceType.All]: undefined,
  [DataSourceType.Pending]: [
    PaymentsEnums.PaymentStatus.Started,
    PaymentsEnums.PaymentStatus.Created,
    PaymentsEnums.PaymentStatus.Dispatched,
    PaymentsEnums.PaymentStatus.Adjourned,
    PaymentsEnums.PaymentStatus.Processed,
  ],
  [DataSourceType.Paid]: [PaymentsEnums.PaymentStatus.Confirmed],
  [DataSourceType.Scheduled]: [PaymentsEnums.PaymentStatus.Scheduled],
  [DataSourceType.Unprocessed]: [
    PaymentsEnums.PaymentStatus.Failed,
    PaymentsEnums.PaymentStatus.Canceled,
    PaymentsEnums.PaymentStatus.Reverted,
  ],
};
