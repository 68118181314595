import { OTypography } from "@maestro/react";
import { MoneyMask } from "../../utils";
import { RowSpacedContainer } from "../cards.styles";
import { Currency } from "../cards.types";
import { LimitsCardInfo } from "./constants";
import {
  LimitsContainerGrid,
  LimitsContent
} from "./limits.styles";
import { ILimitsProps } from "./limits.types";

export const Limits = ({ creditLimitData }: ILimitsProps) => {
  return (
    <div>
      <RowSpacedContainer>
        <LimitsContainerGrid columns={4}>
          {LimitsCardInfo.map((limitData) => (
            <LimitsContent key={limitData.key}>
              <OTypography weight="500">· {limitData.label}</OTypography>
              <OTypography weight="300" className="px-3">
                {MoneyMask(
                  creditLimitData[
                    limitData.key as keyof typeof creditLimitData
                  ],
                  Currency.BRL,
                )}
              </OTypography>
            </LimitsContent>
          ))}
        </LimitsContainerGrid>
      </RowSpacedContainer>
    </div>
  );
};
