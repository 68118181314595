import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useStepConfigSelector = (field: string) => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDiligenceStepConfig,
  );

  const { clearErrors, setError } = useFormContext();

  const getStepConfigs = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors(field);
    else
      setError(field, {
        message:
          "Erro ao buscar as configurações de step. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, field, setError]);

  useEffect(() => {
    getStepConfigs();
  }, [getStepConfigs]);

  const stepConfigs = useMemo(
    () =>
      value?.response.map<SelectSearchFieldOption<number>>(({ name, id }) => ({
        label: name,
        value: id,
      })) ?? [],
    [value?.response],
  );

  return {
    stepConfigs,
    getStepConfigs,
    loading,
    hasFetchError: hasError,
  };
};
