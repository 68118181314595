import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { BankingClienteLimiteMaisByOwnerIdRouteParams } from "../../../../routes/banking.route-params";
import { overdraftInstallmentsGrid } from "./overdraft-installments.grid";

export const OverdraftInstallments = () => {
  const { ownerId } = useParams<BankingClienteLimiteMaisByOwnerIdRouteParams>();
  if (!ownerId) throw new Error("Invalid route params");

  const gridRef = useGridRef();

  const dataSource =
    dataSourceCustomStoreGenerator<BankingHub.InstallmentPlanDetails>(() => {
      return service.bankinghub
        .getOverdraftInstallmentPlans(ownerId)
        .catch(() => {
          const errorMessage = "Erro ao buscar as parcelas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    });

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Histórico de parcelamentos do Limite" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={overdraftInstallmentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
