import { OBadge } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  ProposalGroup,
  ProposedCreditLine,
} from "services/hubcreditmanager/models/responses";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { workflowRoles } from "../../../../../roles/workflow.roles";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { productCreditLineMapper } from "../../workflow-leads.utils";

const proposedCreditLinesGrid = (id: string, acceptedProposal: boolean) =>
  ({
    datagrid: {
      noDataText: "Nenhuma linha de crédito encontrada",
      columnAutoWidth: true,
    },
    columns: [
      {
        dataField: "creditLine.identification",
        caption: "Linha de crédito",
        customizeText: ({ value }) =>
          getValueFromMap(productCreditLineMapper, value) || "",
      },
      {
        dataField: "approvedAmount",
        caption: "Valor aprovado",
        alignment: "left",
        cellRender: ({ data }) =>
          data.approvedAmountType === "GUARANTEED_DISCOUNT"
            ? masks.percentage(data.approvedAmount)
            : masks.currency(
                data.approvedAmount,
                getValueFromMap(
                  currencySymbol,
                  data.approvedAmountCurrencyType,
                ) ?? currencySymbol.BRL,
                ".",
              ),
      },
      {
        dataField: "approvedAmountType",
        caption: "Tipo do valor aprovado",
        customizeText: ({ value }) => value || "Não aplicável",
      },
      {
        caption: "Garantias",
        dataField: "requiredCollaterals",
        customizeText: ({ value }) =>
          value.length
            ? Array.from(new Set(value.map((c) => c.collateralType))).join(", ")
            : "Não possui",
      },
      {
        caption: "Status",
        cellRender: ({ data }) =>
          acceptedProposal ? (
            <OBadge type="success" size="sm">
              Aceita
            </OBadge>
          ) : data.counterproposal ? (
            <OBadge type="info" size="sm">
              Contraproposta enviada
            </OBadge>
          ) : (
            <OBadge type="info" size="sm">
              Pendente aceite
            </OBadge>
          ),
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-stack-details" },
                label: "Detalhes",
                role: workflowRoles.product.leadsDetailsProposalsDetailsPage
                  .role,
                route:
                  corporateRouter.routes.workflow.product.leads.details.proposals.details.path(
                    {
                      id,
                      proposedCreditLineId: data.id,
                    },
                  ),
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<ProposedCreditLine>);

type WorkflowProposalMasterDetailProps =
  MasterDetailComponentProps<ProposalGroup>;

export const WorkflowProposalMasterDetail = ({
  data: { data },
}: WorkflowProposalMasterDetailProps) => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  return (
    <ODataGridGenerator
      grid={proposedCreditLinesGrid(id, !!data.acceptedOnWorkflowLead)}
      dataSource={data.proposedCreditLines}
    />
  );
};
