import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductCountriesGrid } from "./exchange-product-countries.grid";

export const ExchangeProductCountriesPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Países" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.cambio.product.contingency.countries.form
              .path
          }
          role={roles.cambio.product.contingencyCountriesFormPage.role}
        >
          Adicionar país
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeProductCountriesGrid}
      />
    </GridTemplate>
  );
};
