import { masks } from "@maestro/utils";
import {
  IMiddleComposedTextIteration,
  IMiddleFormTextIteration,
  IMiddleSimpleTextIteration,
  ITextMiddleNode,
} from "../chargeback.types";

import { useEffect, useState } from "react";
import {
  ICheckboxResponse,
  MapperLabelLinkCheckbox,
  QuestionTextType,
} from "./compose.types";
import { Currency } from "../../../cards.types";
import { MoneyMask } from "../../../../utils";

export const questionFieldFromType = (
  questionFieldValue: string,
  question:
    | IMiddleSimpleTextIteration
    | IMiddleComposedTextIteration
    | IMiddleFormTextIteration,
) => {
  if (question.type === "number" && question?.label.includes("R$")) {
    return MoneyMask(questionFieldValue, Currency.BRL);
  } else if (question.type === "tel" && questionFieldValue) {
    return masks.phone(questionFieldValue);
  } else return questionFieldValue;
};

type QuestionTextIteration =
  | IMiddleSimpleTextIteration
  | IMiddleComposedTextIteration
  | IMiddleFormTextIteration;

const QuestionFromResponse = (
  question: QuestionTextIteration,
  questions: QuestionTextIteration[],
  index: number,
  questionResponse: Record<string, string>,
  initialFields: Record<string, string>,
): boolean => {
  const isSameLabel = question.label === questionResponse.label;
  const initialFieldsKeys = Object.keys(initialFields);
  const isUniqueField = !initialFieldsKeys.includes(question.name);
  if (isSameLabel && isUniqueField) {
    const previousQuestion = questions[index - 1];
    if (previousQuestion) {
      if (
        question.type === "datetime-local" &&
        "link" in question &&
        "link" in previousQuestion
      ) {
        const hasPreviousField = initialFieldsKeys.includes(
          previousQuestion.name,
        );
        const hasSamePreviousFieldLink =
          question.link === previousQuestion.link;
        return hasPreviousField && hasSamePreviousFieldLink;
      } else return true;
    } else return true;
  } else return false;
};

export const QuestionTextFormGenerateInitialFields = async (
  data: ITextMiddleNode,
  inputLinksChecked: string[] | undefined,
) => {
  const initialFields: Record<string, string> = {};
  const { questions, response: questionsResponses } = data;
  if (questionsResponses) {
    (questionsResponses as Record<string, string>[]).forEach(
      (questionResponse) => {
        const questionData = questions.find((question, index) =>
          QuestionFromResponse(
            question,
            questions,
            index,
            questionResponse,
            initialFields,
          ),
        );
        if (questionData)
          initialFields[questionData.name] = questionFieldFromType(
            questionResponse.value,
            questionData,
          );
      },
    );
  }
  const questionsFilled = Object.keys(initialFields);
  await data.questions.forEach((question) => {
    if (!questionsFilled.includes(question.name)) {
      if (inputLinksChecked) {
        if ("link" in question && inputLinksChecked.includes(question.link))
          initialFields[question.name] = questionFieldFromType("", question);
      } else initialFields[question.name] = questionFieldFromType("", question);
    }
  });
  return initialFields;
};

export const QuestionTextFormInputTypeToMinimumSize = (
  type: QuestionTextType,
) => {
  if (type === "email") return 12;
  else if (type === "datetime-local") return 14;
  else if (type === "tel") return 17;
  else if (type === "text") return 1;
  else return 0;
};

const QuestionTextValidators = {
  email: (value: string) =>
    value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
  tel: (value: string) =>
    value.match(
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
    ),
  number: (value: string) =>
    value.match(/^([^\d\s]{1,}\s?[+-]?)(\d{1,3})(\.\d{3})*(,\d{1,})?$/),
};

export const QuestionTextFormErrorMessagesValidation = (
  value: string,
  type: QuestionTextType,
  minumumValue: number,
) => {
  if (type === "email") {
    if (!QuestionTextValidators.email(value)) return "E-mail inválido";
  } else if (type === "tel") {
    if (!QuestionTextValidators.tel(value)) return "Celular inválido";
  } else if (type === "number") {
    if (!QuestionTextValidators.number(value)) return "Valor inválido";
  }
  if (!value) return "Campo obrigatório";
  else if (value.length < minumumValue) return "Campo inválido";
  return "";
};

export const CheckboxLinksFromCheckedQuestionText = (
  data: Array<ICheckboxResponse>,
) => {
  const dataMapped = data.map((checkedOption) => {
    return MapperLabelLinkCheckbox[checkedOption.value];
  });
  return dataMapped as string[];
};

interface IUseFormProps {
  initialFields: Record<string, string>;
  validateOnChange?: boolean;
  validate: Function;
  negativeValues?: boolean;
}

interface IUseFormReturn {
  fields: Record<string, string>;
  setFields: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  handleInputChange: Function;
}

const useForm = ({
  initialFields,
  validateOnChange = false,
  validate,
  negativeValues = true,
}: IUseFormProps): IUseFormReturn => {
  const [fields, setFields] = useState<Record<string, string>>(initialFields);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setFields(initialFields);
  }, [initialFields]);

  const handleInputChange = (event: any, mask?: undefined | Function) => {
    const { name, value } = event;
    let newValue = value;
    if (!value.replace(/\s/g, "").length) {
      newValue = value.trim();
    }
    if (!negativeValues && parseInt(value) < 0) {
      newValue = "0";
    }
    setFields(
      typeof mask === "function"
        ? {
            ...fields,
            [name]: mask(newValue),
          }
        : { ...fields, [name]: newValue },
    );
    if (validateOnChange) {
      validate({ [name]: newValue });
    }
  };

  return {
    fields,
    setFields,
    errors,
    setErrors,
    handleInputChange,
  };
};

export default useForm;
