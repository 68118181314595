import { OToastManager } from "@maestro/core";
import {
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { AxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { Failure } from "services/onboarding/models";
import { minimumCriteriaFormDefaultValues } from "./minimum-criteria-form.schemas";

interface IErrorResponse {
  errorType: string;
  failures: Failure[];
  isInternal: boolean;
}

type MinimumCriteriaFormProps =
  | {
      mode: "add";
    }
  | {
      mode: "edit";
      id: string;
    };

export const MinimumCriteriaForm = (props: MinimumCriteriaFormProps) => {
  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const navigate = useNavigate();

  const { handleSubmit } =
    useFormContext<typeof minimumCriteriaFormDefaultValues>();

  const createMinimumCriteria = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setErrorList([]);

          setLoading(true);

          const { mode } = props;

          const isVisible = !!values.isVisible.length;

          if (mode === "add") {
            await service.hubCreditManager.createMinimumCriteria({...values, isVisible});
          } else {
            await service.hubCreditManager.updateMinimumCriteria({
              ...values,
              id: Number(props.id),
              isVisible,
            });
          }
          OToastManager.success("Critério Mínimo salvo com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.configurations
              .minimumCriteria.path,
          );
        } catch (err) {
          const error = err as AxiosError<IErrorResponse>;
          if (
            !!error?.response?.data?.failures?.some(
              (cm) => cm.errorCode === "MinimumCriteriaAlreadyExists",
            )
          ) {
            OToastManager.danger("Mínimo Critério já cadastrado.");
          } else {
            OToastManager.danger("Não foi possível cadastrar o mínimo critério.");
          }
          
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, props],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          onClick={() => createMinimumCriteria()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <form spellCheck="false">
          <div className="d-flex flex-direction-row gap-3">
            <OCol sm={8}>
              <ORFieldInput
                id="title"
                name="title"
                tag="text"
                label="Título"
                labelSize="md"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                id="type"
                name="type"
                tag="text"
                label="Tipo"
                labelSize="md"
              />
            </OCol>
          </div>
          <div className="d-flex flex-direction-row gap-3">
            <OCol sm={8}>
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                labelSize="md"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="validationLocation"
                name="validationLocation"
                label="Local de Validação"
                labelSize="md"
              >
                {["ON_VALIDATION_PROCESSOR", "BEFORE_WORKFLOW_STARTS"].map(
                  (type) => (
                    <OOption key={type} value={type}>
                      {type}
                    </OOption>
                  ),
                )}
              </ORFieldSelect>
            </OCol>
          </div>
          <ORFieldCheckboxGroup id="isVisible" name="isVisible">
            <div className="d-flex align-items-center gap-2 mb-2">
              <OCheckbox size="xs" id="wf-entry-checkbox" value="1" />
              <OLabel htmlFor="wf-entry-checkbox">
                É visível para o originador?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </form>
        {errorList.map((error) => (
          <OTypography key={error} size="sm" type="danger">
            {error}
          </OTypography>
        ))}
      </CardTemplate>
    </FormTemplate>
  );
};