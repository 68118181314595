import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";

export const exchangeOdataBeneficiariesGrid = {
  datagrid: {
    noDataText: "Nenhum beneficiário encontrado",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar beneficiários.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminBeneficiariesOData,
    ),
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) =>
        !!data.Id && (
          <DetailsLink
            href={`${corporateRouter.routes.cambio.product.beneficiaries.details.path(
              { id: data.Id },
            )}`}
          />
        ),
    },
    {
      dataField: "Id",
      caption: "Id",
      dataType: "number",
    },
    {
      dataField: "CreatedDate",
      caption: "Dt. Criação",
      dataType: "date",
    },
    {
      caption: "Nome",
      dataField: "Name",
    },
    {
      caption: "Status",
      dataField: "IsActive",
      cellRender: ({ data }) => (data.IsActive ? "Ativa" : "Inativa"),
    },
    {
      caption: "Contraparte",
      dataField: "CounterpartyName",
    },
    {
      caption: "Identificação",
      dataField: "CounterpartyName",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Moeda",
      dataField: "Currency",
    },
    {
      caption: "País banco principal",
      dataField: "Country",
    },
    {
      caption: "Nome banco principal",
      dataField: "MainBankName",
    },
    {
      caption: "Swift banco principal",
      dataField: "MainBankSwift",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getBeneficiaries>
    >["data"]["value"]
  >[number]
>;
