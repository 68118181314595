import dayjs from "dayjs";
import React, { createContext, useContext, useState } from "react";

const conciliacaoContext = createContext({} as ConciliacaoContext);

interface ConciliacaoProps {
  children: React.ReactNode;
}

interface RangeDate {
  dataVencimentoInicial: string;
  dataVencimentoFinal: string;
}

interface ConciliacaoContext {
  rangeDate: RangeDate;
  setRangeDate: React.Dispatch<React.SetStateAction<RangeDate>>;
}

export const ConciliacaoProvider = ({ children }: ConciliacaoProps) => {
  const [rangeDate, setRangeDate] = useState<RangeDate>({
    dataVencimentoInicial: dayjs().add(-7).format("YYYY-MM-DD"),
    dataVencimentoFinal: dayjs().format("YYYY-MM-DD"),
  });

  const value = {
    rangeDate,
    setRangeDate,
  };

  return (
    <conciliacaoContext.Provider value={value}>
      {children}
    </conciliacaoContext.Provider>
  );
};

export const useConciliacaoContext = () => useContext(conciliacaoContext);
