import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import { buildAccountsGrid } from "./account-close.grid";
import { useAccountClose, useGetAccounts } from "./account-close.hook";

export const AccountClose = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const gridRef = useGridRef();
  const gridRefAccountsEncerrada = useGridRef();

  const { ids, pendenciesHref, setIds } = useAccountClose();
  const { dataSource, filteredAccountsEncerrada } =
    useGetAccounts(identification);

  const gridNotFilter = useMemo(
    () => buildAccountsGrid(setIds, false),
    [setIds],
  );
  const gridFilter = useMemo(() => buildAccountsGrid(setIds, true), [setIds]);

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate
        pageTitle={<PageTitle title="Contas Ativas" />}
        actions={
          <RouterButton
            dataAction="acoes:botao:encerrar_contas"
            dataLabel="encerrar_contas"
            href={pendenciesHref}
            role={roles.cadastro.customer.accountClosePendenciesPage.role}
            disabled={ids.length < 1}
          >
            Encerrar conta(s)
          </RouterButton>
        }
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={gridNotFilter}
          dataSource={dataSource}
        />
      </GridTemplate>
      <GridTemplate
        pageTitle={<PageTitle title="Contas Encerradas" />}
        gridRef={gridRefAccountsEncerrada}
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRefAccountsEncerrada}
          grid={gridFilter}
          dataSource={filteredAccountsEncerrada}
        />
      </GridTemplate>
    </div>
  );
};
