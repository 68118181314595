import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { NewOriginatorFormSchema } from "./new-originator.form";

export const useNewOriginator = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<HubPartners.Admin.NewOriginator>({
    resolver: yupResolver(NewOriginatorFormSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.registerOriginator({
        identification: values.identification.replace(/\D/g, ""),
        identificationType: "CNPJ",
        officialName: values.officialName,
        tradingName: values.tradingName?.length ? values.tradingName : null,
        originatorSubTypesCodes: values.originatorSubTypesCodes,
        partnershipTypeCode: values.partnershipTypeCode,
        productsCodes: values.productsCodes,
        referrerOperatorProfileIdentifier:
          values.referrerOperatorProfileIdentifier,
      });

      OToastManager.success("Originador adicionado com sucesso!");
    } catch (err) {
      if (
        isAxiosError<
          | HubPartners.Admin.ErrorResponse
          | HubPartners.Admin.AlternateErrorResponse
        >(err)
      ) {
        if (err.response && err.response.data) {
          if ("failures" in err.response.data) {
            err.response.data.failures?.forEach((failure) => {
              failure.message && OToastManager.danger(failure.message);
            });
          } else if ("message" in err.response.data) {
            OToastManager.danger(
              err.response.data.message ??
                "Não foi possível cadastrar o originador.",
            );
          }
        }
      } else {
        OToastManager.danger("Não foi possível cadastrar o originador.");
      }
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    onSubmit,
  };
};
