import { CustomerType } from "contexts/customer/types/customer.type";
import { PostContactBody } from "services/admin-bankinghub/models";
import { FormValuesType } from "./_compose";

export const prepareToRequest = (values: FormValuesType, uuid?: string) => {
  const isDisbursementAccount = values.is_disbursement_account === "S";
  return {
    ...values,
    id: uuid || "",
    is_disbursement_account: isDisbursementAccount,
  };
};

export const assembleCompanyContact = ({
  identification,
  officialName,
}: CustomerType): PostContactBody => {
  const person_type = identification.length === 11 ? "F" : "J";

  return {
    contact_holder: identification,
    document_number: identification,
    name: officialName,
    person_type,
    product: { bankAccounts: [], address: [], pix: [] },
    same_ownership: true,
  };
};
