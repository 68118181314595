import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.odata.getAccCustomerSettlements>
    >["data"]["value"]
  >[number]
>;

export const adminCustomerAccSettlementsGrid = (
  customerTaxId?: string,
  filterValue?: Grid["datagrid"]["filterValue"],
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma liquidação encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar liquidações.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.acc.odata.adminACCCustomerSettlementsOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
          odataStoreOptions: {
            beforeSend: (config) => {
              config.headers["x-identification"] = customerTaxId;
            },
          },
        },
      ),
    },
    columns: [
      {
        type: "buttons",
        allowExporting: false,
        role: roles.acc.customer.settlementsDetailsPage.role,
        cellRender: ({ data }) =>
          data.preTicketId && (
            <DetailsLink
              href={corporateRouter.routes.acc.customer.settlements.details.path(
                {
                  id: data.preTicketId,
                },
              )}
            />
          ),
      },
      {
        dataField: "preTicketId",
        caption: "PreTicketId",
        dataType: "number",
        sortOrder: "desc",
      },
      {
        caption: "Nome",
        dataField: "preTicketCounterpartyName",
      },
      {
        caption: "Contrato",
        dataField: "contract",
      },
      {
        dataField: "proposalAmount",
        caption: "Valor da proposta",
        alignment: "left",
        cellRender: ({ data }) => {
          return (
            <OTypography>
              {masks.currency(
                data.proposalAmount,
                data.preTicketSellCurrencyType,
              )}
            </OTypography>
          );
        },
      },
      {
        caption: "Pagador",
        dataField: "payer",
      },
      {
        caption: "Moeda",
        dataField: "preTicketSellCurrencyType",
      },
      {
        caption: "FlowType",
        dataField: "preTicketFlowType",
      },
      {
        caption: "Valor total",
        dataField: "preTicketAmount",
        alignment: "left",
        cellRender: ({ data }) => {
          return (
            <OTypography>
              {masks.currency(
                data.preTicketAmount,
                data.preTicketSellCurrencyType,
              )}
            </OTypography>
          );
        },
      },
      {
        caption: "Valor disponível",
        dataField: "preTicketFxAvailableAmount",
        alignment: "left",
        cellRender: ({ data }) => {
          return (
            <OTypography>
              {masks.currency(
                data.preTicketFxAvailableAmount,
                data.preTicketSellCurrencyType,
              )}
            </OTypography>
          );
        },
      },
      {
        caption: "Identificação",
        dataField: "preTicketCounterpartyIdentification",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        caption: "Natureza",
        dataField: "preTicketNatureDescription",
      },
      {
        caption: "Juros",
        dataField: "fixedInterestValue",
      },
      {
        caption: "Status",
        dataField: "preTicketStatus",
      },
    ],
  } satisfies Grid);
