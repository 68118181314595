import {
  OBadge,
  OButton,
  OCol,
  OIcon,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { FormProvider, UseFormReturn, useFieldArray } from "react-hook-form";
import { EmailConfigsTypeSteps } from "services/clerk";
import {
  InviteTypeFormValues,
  nameFieldTypeMap,
  nameStepTypeMap,
} from "./invite-type.form";
import { StyledBadge, StyledRow } from "./invite-types-list.styles";
import { useEmailTemplates } from "./invite-types.hook";

export interface OnboardingInviteTypesFormProps {
  form: UseFormReturn<InviteTypeFormValues, any>;
}

interface StepsFormProps {
  index: number;
  stepkey: string;
  form: UseFormReturn<InviteTypeFormValues, any>;
}

interface FieldsFormProps {
  indexSteps: number;
  indexFields: number;
}

export const FieldsForm = ({ indexSteps, indexFields }: FieldsFormProps) => {
  return (
    <ORow>
      <ORow className="flex-fill">
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id={`steps.${indexSteps}.fields.${indexFields}.name`}
            name={`steps.${indexSteps}.fields.${indexFields}.name`}
            label="Nome do field"
            placeholder="Selecione"
          >
            {Object.entries(nameFieldTypeMap).map(([value, label]) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldRadioGroup
            id={`steps.${indexSteps}.fields.${indexFields}.required`}
            name={`steps.${indexSteps}.fields.${indexFields}.required`}
            label="É necessário?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldRadioGroup
            id={`steps.${indexSteps}.fields.${indexFields}.defaultValue`}
            name={`steps.${indexSteps}.fields.${indexFields}.defaultValue`}
            label="Valor padrão?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldRadioGroup
            id={`steps.${indexSteps}.fields.${indexFields}.allowChange`}
            name={`steps.${indexSteps}.fields.${indexFields}.allowChange`}
            label="Permite mudança?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldRadioGroup
            id={`steps.${indexSteps}.fields.${indexFields}.visible`}
            name={`steps.${indexSteps}.fields.${indexFields}.visible`}
            label="Field é visível?"
          >
            <div className="d-flex gap-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="yes" value="true" />
                <OLabel htmlFor="yes">Sim</OLabel>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id="no" value="false" />
                <OLabel htmlFor="no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
    </ORow>
  );
};

export const StepsForm = ({ form, index, stepkey }: StepsFormProps) => {
  const { control } = form;

  const fieldArrayFields = useFieldArray({
    control,
    name: `steps.${index}.fields`,
  });

  const { append, remove, fields } = fieldArrayFields;

  return (
    <ORow key={stepkey} className="flex-fill">
      <OCol xl={4}>
        <ORFieldSelect
          id={`steps.${index}.name`}
          name={typeof index === "number" ? `steps.${index}.name` : "name"}
          label="Nome do step"
          placeholder="Selecione"
        >
          {Object.entries(nameStepTypeMap).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xl={4}>
        <ORFieldInput
          id={`steps.${index}.priority`}
          name={
            typeof index === "number" ? `steps.${index}.priority` : "priority"
          }
          tag="text"
          label="Prioridade do step"
        />
      </OCol>
      <OCol xl={4}>
        <ORFieldRadioGroup
          id={`steps.${index}.visible`}
          name={
            typeof index === "number" ? `steps.${index}.visible` : "visible"
          }
          label="Step é visível?"
        >
          <div className="d-flex gap-4">
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="yes" value="true" />
              <OLabel htmlFor="yes">Sim</OLabel>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="no" value="false" />
              <OLabel htmlFor="no">Não</OLabel>
            </div>
          </div>
        </ORFieldRadioGroup>
      </OCol>

      <div className="d-flex flex-column">
        <OLabel htmlFor="fields">Fields</OLabel>
        <div className="d-flex flex-column" id="fields">
          <ORow className="mx-1">
            {fields.map((field, indexField) => (
              <StyledRow key={field.id}>
                <OCol xs={1} className="h-100" align="center">
                  <OBadge circle type="info">
                    {indexField + 1}
                  </OBadge>
                </OCol>
                <OCol xs={10} align="center">
                  <FieldsForm indexSteps={index} indexFields={indexField} />
                </OCol>
                <OCol xs={1}>
                  <OButton type="danger" onClick={() => remove(index)}>
                    <OIcon category="fa" icon="fa-trash" />
                  </OButton>
                </OCol>
              </StyledRow>
            ))}
          </ORow>
          <OButton
            type="tertiary"
            onClick={() =>
              append({} as NonNullable<EmailConfigsTypeSteps["fields"]>[number])
            }
            className="mt-3"
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      </div>
    </ORow>
  );
};

export const OnboardingInviteTypeForm = ({
  form,
}: OnboardingInviteTypesFormProps) => {
  const { control } = form;

  const fieldArraySteps = useFieldArray({
    control,
    name: "steps",
  });

  const { append, remove, fields } = fieldArraySteps;

  const { emailTemplates } = useEmailTemplates();

  return (
    <FormProvider {...form}>
      <ORow>
        <ORow>
          <OCol xl={4}>
            <ORFieldSelect
              id="emailConfigId"
              name="emailConfigId"
              label="Id do template de e-mail"
              placeholder="Selecione"
            >
              <div>
                {emailTemplates?.map((emailTemplate) => {
                  return (
                    <OOption value={emailTemplate.id} key={emailTemplate.id}>
                      {emailTemplate.id} - {emailTemplate.name}
                    </OOption>
                  );
                })}
              </div>
            </ORFieldSelect>
          </OCol>

          <OCol xl={4}>
            <ORFieldInput tag="text" id="name" name="name" label="Nome" />
          </OCol>

          <OCol xl={4}>
            <ORFieldInput
              tag="text"
              id="description"
              name="description"
              label="Descrição"
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol xl={4}>
            <ORFieldInput
              tag="text"
              id="expirationTime"
              name="expirationTime"
              label="Tempo de expiração"
            />
          </OCol>
          <OCol xl={4}>
            <ORFieldInput
              tag="text"
              id="totalUses"
              name="totalUses"
              label="Total de usos"
            />
          </OCol>
          <OCol xl={4}>
            <ORFieldInput
              tag="text"
              id="teamsNotificationGroup"
              name="teamsNotificationGroup"
              label="Grupo de notificação teams"
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol xl={4}>
            <ORFieldRadioGroup
              id="hasValidationToken"
              name="hasValidationToken"
              label="Tem validação de token?"
            >
              <div className="d-flex gap-4">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="yes" value="true" />
                  <OLabel htmlFor="yes">Sim</OLabel>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="no" value="false" />
                  <OLabel htmlFor="no">Não</OLabel>
                </div>
              </div>
            </ORFieldRadioGroup>
          </OCol>
          <OCol xl={4}>
            <ORFieldRadioGroup
              id="hasValidationCnpj"
              name="hasValidationCnpj"
              label="Tem validação de CNPJ?"
            >
              <div className="d-flex gap-4">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="yes" value="true" />
                  <OLabel htmlFor="yes">Sim</OLabel>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="no" value="false" />
                  <OLabel htmlFor="no">Não</OLabel>
                </div>
              </div>
            </ORFieldRadioGroup>
          </OCol>
          <OCol xl={4}>
            <ORFieldRadioGroup
              id="shouldNotifyUpdatesOnTeams"
              name="shouldNotifyUpdatesOnTeams"
              label="Deve notificar atualizações no Teams?"
            >
              <div className="d-flex gap-4">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="yes" value="true" />
                  <OLabel htmlFor="yes">Sim</OLabel>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <ORadio id="no" value="false" />
                  <OLabel htmlFor="no">Não</OLabel>
                </div>
              </div>
            </ORFieldRadioGroup>
          </OCol>
        </ORow>

        <div className="d-flex flex-column">
          <OLabel htmlFor="steps">Steps</OLabel>
          <div className="d-flex flex-column" id="steps">
            <ORow className="mx-1">
              {fields.map((field, index) => (
                <StyledRow key={field.id}>
                  <OCol xs={1} className="h-100" align="start">
                    <StyledBadge>{index + 1}</StyledBadge>
                  </OCol>
                  <OCol>
                    <StepsForm index={index} stepkey={field.id} form={form} />
                  </OCol>
                  <OCol xs={1} align="start">
                    <OButton type="danger" onClick={() => remove(index)}>
                      <OIcon category="fa" icon="fa-trash" />
                    </OButton>
                  </OCol>
                </StyledRow>
              ))}
            </ORow>
            <OButton
              type="tertiary"
              onClick={() =>
                append({} as NonNullable<InviteTypeFormValues["steps"]>[number])
              }
              className="mt-3"
            >
              <OIcon category="fa" icon="fa-plus" />
            </OButton>
          </div>
        </div>
      </ORow>
    </FormProvider>
  );
};
