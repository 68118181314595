import { masks } from "@maestro/utils";
import { DocumentUtils } from "./document";

const isMaskedTaxId = (value?: string) => {
  return value?.includes("*");
};

const maskAndFormatTaxIdText = (value?: string) => {
  if (DocumentUtils.hasCpfLength(value) && isMaskedTaxId(value)) {
    const regexPattern = /(\*{3})(\d{3})(\d{3})(\*{2})/;
    return value?.replace(regexPattern, "***.$2.$3-**");
  }

  if (DocumentUtils.hasCnpjLength(value) && isMaskedTaxId(value)) {
    const regexPattern = /(\*{3})(\d{2})(\d{3})(\d{1})(\*{2})(\d{1})(\d{2})/;
    return value?.replace(regexPattern, "**.*$2.$3/$4**$6-$7");
  }

  return masks.cpfCnpj(value);
};

export const MaskUtils = {
  maskAndFormatTaxIdText,
};
