import * as yup from "yup";

export interface RegisterNewStatementFormValues {
  cnpj: string;
  agency: string;
  account: string;
  van: string;
  scheduleType: string;
  scheduleDay: number;
  dsname: string | undefined;
}

export const registerNewStatementDefaultValues: RegisterNewStatementFormValues = {
  cnpj: "",
  account: "",
  agency: "",
  van: "0",
  scheduleType: "DAILY",
  scheduleDay: 1,
  dsname: undefined,
};

export const registerNewStatementSchema: yup.ObjectSchema<RegisterNewStatementFormValues> =
  yup.object({
    cnpj: yup.string().required("Este campo é obrigatório"),
    account: yup.string().required("Este campo é obrigatório"),
    agency: yup.string().required("Este campo é obrigatório"),
    van: yup.string().required("Este campo é obrigatório"),
    scheduleType: yup.string().required("Este campo é obrigatório"),
    scheduleDay: yup.number().required("Este campo é obrigatório"),
    dsname: yup
      .string()
      .test(
        "hasVAN",
        "Deve ter um DSName caso tenha escolhido uma VAN",
        (value, context) =>
          context.parent.van === "0" || (!!value && value.trim().length > 0),
      ),
  });
