import { env } from "utils/environments";

export const validatorEndpoints = {
  elegibilidade: {
    allow: `${env.API_VALIDATOR}/api/AllowList`,
    block: `${env.API_VALIDATOR}/api/BlockList`,
    addAllow: `${env.API_VALIDATOR}/api/AllowList`,
    addBlock: `${env.API_VALIDATOR}/api/BlockList`,
    editAllow: (Id: string) => `${env.API_VALIDATOR}/api/AllowList?Id=${Id}`,
    editBlock: (Id: string) => `${env.API_VALIDATOR}/api/BlockList?Id=${Id}`,
    deleteAllow: (Id: string) => `${env.API_VALIDATOR}/api/AllowList?Id=${Id}`,
    deleteBlock: (Id: string) => `${env.API_VALIDATOR}/api/BlockList?Id=${Id}`,
  },
};
