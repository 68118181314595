import { createContext, FC, useContext, useMemo, useState } from "react";

interface ImpersonateContextValue {
  isImpersonating?: boolean;
  setIsImpersonating?: (isImpersonating: boolean) => void;
}

export const ImpersonateContext = createContext<ImpersonateContextValue>({});

interface ImpersonateProviderProps {
  children?: React.ReactNode;
}

export const ImpersonateProvider: FC<ImpersonateProviderProps> = ({
  children,
}) => {
  const [isImpersonating, setIsImpersonating] = useState(false);

  const value = useMemo(
    () => ({ isImpersonating, setIsImpersonating }),
    [isImpersonating],
  );

  return (
    <ImpersonateContext.Provider value={value}>
      {children}
    </ImpersonateContext.Provider>
  );
};

export const useImpersonate = () => {
  const context = useContext(ImpersonateContext);

  if (!context) {
    throw new Error("useImpersonate must be used within a ImpersonateProvider");
  }

  return context;
};
