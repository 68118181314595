import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { AccountModality } from "../../../../legacy/cards/cards.types";
import { useCardsDashboard } from "../../cards-dashboard.context";

export const LastDayAccountsCard = () => {
  const { accountsSummary } = useCardsDashboard();

  return (
    <DetailsCard
      hasError={accountsSummary.hasError}
      loading={accountsSummary.loading}
      value={accountsSummary.value}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível buscar as informações"
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton onClick={accountsSummary.callService} />
        </ErrorComponent>
      }
      fields={(_value) => ({
        Accounts: {
          title: "Contas no último dia",
          items: [
            {
              label: "Contas de crédito criadas no dia anterior:",
              value:
                _value.lastDayQuantity.find(
                  (quantity) => quantity.modality === AccountModality.Credit,
                )?.quantity ?? 0,
            },
            {
              label: "Contas de débito criadas no dia anterior:",
              value:
                _value.lastDayQuantity.find(
                  (quantity) => quantity.modality === AccountModality.Debit,
                )?.quantity ?? 0,
            },
          ],
        },
      })}
    />
  );
};
