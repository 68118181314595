import { OToastManager } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { PageTitle } from "components/page-title";
import { CadastroClienteContasByAccountByAgencyRouteParams } from "../../../../../routes/cadastro.route-params";
import { PermissionHistory } from "./permission-history.type";
import { grid } from "./permission-history.grid";

export const AccountAdminPermissionHistory = () => {
  const { account, agency } =
    useParams<CadastroClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<PermissionHistory>(() =>
        service.adminBankinghub
          .getAccountsPermissionsOperationsByAgencyAndAccountNumber(
            agency,
            account,
          )
          .then(({ data }) => {
            if (!data) return [];
            return data.data;
          })
          .catch(() => {
            const errorMessage =
              "Não foi possível carregar o histórico de permissões";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [agency, account],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Histórico de Permissões" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
