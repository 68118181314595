import { MaestroFields } from "utils/types";

export interface DenialReasonForm {
  reproveStatus?: string;
}

export const denialReasonFields = {
  reproveStatus: {
    id: "reproveStatus",
    name: "reproveStatus",
    dataAction: "formulario:texto:motivo_repovacao",
    dataLabel: "motivo_repovacao",
    tag: "textarea",
    label: "Digite o motivo da reprovação",
    placeholder: "Preencher",
  },
} as const satisfies MaestroFields<DenialReasonForm>;
