import { OButton, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { DetailsTemplate } from "templates/details-template";
import { useStepAnalysisContext } from "../step-analysis.context";
import { AllPropertiesModalButton, SubmitAnalysisButton } from "../_compose";
import { stageMap } from "./step-analysis-stages.utils";

export const StepAnalysisStageComponent = () => {
  const { selectedStage, stages, setSelectedStageIndex, selectedStageIndex } =
    useStepAnalysisContext();

  const StageComponent = useMemo(
    () => stageMap[selectedStage.type],
    [selectedStage.type],
  );
  return (
    <DetailsTemplate
      actions={
        <div className="flex-fill d-flex align-items-center justify-content-between gap-2">
          <OTypography size="lg" type="dark" key={selectedStage.title}>
            {selectedStage.title}
          </OTypography>

          <div className="d-flex align-items-center gap-2">
            <AllPropertiesModalButton />
            {selectedStageIndex > 0 && (
              <OButton
                type="dark"
                onClick={() => setSelectedStageIndex(selectedStageIndex - 1)}
              >
                Anterior
              </OButton>
            )}
            {selectedStageIndex < stages.length - 1 && (
              <OButton
                type="dark"
                onClick={() => setSelectedStageIndex(selectedStageIndex + 1)}
              >
                Próximo
              </OButton>
            )}
            {selectedStage.type === "RESUMO" && <SubmitAnalysisButton />}
          </div>
        </div>
      }
    >
      <StageComponent />
    </DetailsTemplate>
  );
};
