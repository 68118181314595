import { FileDataItem } from "services/bankinghub/models";

export interface FormValuesType {
  year?: string;
  quarter?: Quarter;
}

export interface AccountAdminCorporateTaxProps {
  account: string | undefined;
  agency: string | undefined;
}

export interface ExportCorporateTaxModalProps {
  company: string;
  account: { number: string; agency: string } | undefined;
}

export enum Quarter {
  First = "FIRST",
  Second = "SECOND",
  Third = "THIRD",
  Fourth = "FOURTH",
}

export const InitialMonthKeyQuarters: Record<Quarter, number> = {
  [Quarter.First]: 0,
  [Quarter.Second]: 3,
  [Quarter.Third]: 6,
  [Quarter.Fourth]: 9,
};

export const QuartersFilenameBase: Record<Quarter, string> = {
  [Quarter.First]: "INFORME_DE_RENDIMENTOS_PRIMEIRO_TRIMESTRE",
  [Quarter.Second]: "INFORME_DE_RENDIMENTOS_SEGUNDO_TRIMESTRE",
  [Quarter.Third]: "INFORME_DE_RENDIMENTOS_TERCEIRO_TRIMESTRE",
  [Quarter.Fourth]: "INFORME_DE_RENDIMENTOS_QUARTO_TRIMESTRE",
};

export const filenameBaseByQuarterAndYear = (
  quarter?: Quarter,
  year?: string,
): string => {
  if (!quarter || !year) return "";
  return `${QuartersFilenameBase[quarter]}_${year}`;
};

export interface QuartersOfYearType {
  [Quarter.First]?: FileDataItem;
  [Quarter.Second]?: FileDataItem;
  [Quarter.Third]?: FileDataItem;
  [Quarter.Fourth]?: FileDataItem;
}

export type CorporateTax = Record<string, QuartersOfYearType>;

export type GroupedYears = Record<string, FileDataItem[]>;
