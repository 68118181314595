import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { useGetUser } from "../../../../hooks/use-get-user.hook";
import { buildRelatedEntitiesGrid } from "./empresas-relacionadas.grid";

export const UserRelatedEntities = () => {
  const { user, loading, hasError, getUserFromTaxId, userTaxId } = useGetUser();

  const grid = useMemo(
    () => buildRelatedEntitiesGrid(userTaxId, getUserFromTaxId),
    [getUserFromTaxId, userTaxId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Empresas Relacionadas" />}
      actions={<RefreshGridButton onClick={() => getUserFromTaxId()} />}
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={user}
        errorComponent={
          <ErrorComponent
            messageTitle="Ocorreu um erro ao buscar as empresas relacionadas"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => getUserFromTaxId()} />
          </ErrorComponent>
        }
        render={(_user) => (
          <ODataGridGenerator grid={grid} dataSource={_user.relatedEntities} />
        )}
      />
    </GridTemplate>
  );
};
