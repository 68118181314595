import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { OptinWorkflowUuidRouteParams } from "../../../../../routes/cadastro.route-params";

export const useWorkflowDetails = () => {
  const { workflowUuid } = useParams<OptinWorkflowUuidRouteParams>();

  if (!workflowUuid)
    throw new Error("No representative workflow uuid in route parameters");

  const {
    value: workflow,
    callService: getMasterDetailedWorkflow,
    hasError,
    loading,
  } = useServiceCall(service.onboardingCoreAccount.getMasterDetailedWorkflow);

  useEffect(() => {
    const uuid = workflowUuid!;
    getMasterDetailedWorkflow({ uuid });
  }, [getMasterDetailedWorkflow, workflowUuid]);

  return {
    workflow,
    loading,
    hasError,
    getMasterDetailedWorkflow,
    workflowUuid,
  };
};
