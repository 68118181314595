import { DeepNullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface AddPendencyModalForm {
  message: string;
  typeId: number;
}

export const addPendencyModalFormFields = {
  message: {
    id: "message",
    name: "message",
    tag: "textarea",
    label: "Mensagem",
    placeholder: "Preencher",
    adaptHeight: true,
  },
  typeId: {
    name: "typeId",
    label: "Tipo",
    placeholder: "Selecionar",
  },
} as const;

export const addPendencyModalFormValidationSchema = yup.object<
  AddPendencyModalForm,
  YupObjectSchema<AddPendencyModalForm>
>({
  typeId: yup
    .number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
});

export const addPendencyModalFormDefaultValues = {
  message: null,
  typeId: null,
} satisfies Partial<
  DeepNullable<AddPendencyModalForm>
> as unknown as AddPendencyModalForm;
