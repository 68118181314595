export interface HubOnboardingError {
  isInternal: boolean;
  errorType: string;
  failures: OnboardingFailure[];
}

export interface OnboardingFailure {
  fieldName: string;
  errorCode: string;
  errorMessage: string;
}

export const HubOnboardingErrorTypes = {
  DomainException: "DomainException",
  SemaphoreException: "SemaphoreException",
  BadRequestException: "BadRequestException",
  ValidationException: "ValidationException",
};
