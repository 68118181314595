export const statusMap = {
  DISPONIVEL: {
    text: "Disponível",
    color: "success",
    icon: "fa-check-circle",
  },
  INDISPONIVEL: {
    text: "Indisponível",
    color: "danger",
    icon: "fa-times-circle",
  },
  APROVADOR_NAO_CADASTRADO: {
    text: "Aprovador não cadastrado",
    color: "warning",
    icon: "fa-question-circle",
  },
  DEFAULT: {
    text: "STATUS NÃO MAPEADO",
    color: "danger",
    icon: "fa-question-circle",
  },
} as const;
