import { OTypography } from "@maestro/react";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";
import moment from "moment";
import { MoneyMask } from "../../../../utils";
import { removeCipherFormatter } from "../../../../utils/formatters/cipher";
import { ColumnGap } from "../../../cards.styles";
import {
  Currency,
  ItemCategory,
  ItemType,
  MapperItemCategoryAndType,
} from "../../../cards.types";
import {
  eventFilterExpression,
  transactionFilterExpression,
} from "../../../utils/timeline";
import { ICellRenderParams, IRelatedItemsProps } from "./related-items.types";

export const RelatedItems = ({
  transactionRelatedItems,
}: IRelatedItemsProps) => {
  return (
    <ColumnGap rowGap={10}>
      <OTypography>Transações relacionadas</OTypography>
      <DataGrid
        id="grid-container-transaction-related-items"
        keyExpr="correlationId"
        width="100%"
        rowAlternationEnabled
        dataSource={transactionRelatedItems}
        showColumnLines
        showRowLines
        noDataText="Nenhuma informação de Eventos relacionados encontrados."
        columnAutoWidth
        scrolling={{
          showScrollbar: "always",
        }}
        headerFilter={{
          visible: true,
        }}
        pager={{
          showInfo: true,
          visible: true,
          allowedPageSizes: [10, 20, 30],
          showPageSizeSelector: true,
        }}
      >
        <FilterRow visible />
        <Paging defaultPageSize={5} />
        <Column
          caption="Tipo"
          filterOperations={["contains"]}
          calculateFilterExpression={(filterValue: any) => {
            if ("Evento".includes(filterValue)) {
              return eventFilterExpression();
            }
            return transactionFilterExpression();
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="type"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.type) return "-";
            return data.type === ItemType.Transaction ||
              data.type === ItemType.Confirmation ||
              (data.type === ItemType.Statement &&
                (data.category === ItemCategory.Payment ||
                  data.category === ItemCategory.PartialPayment))
              ? "Transação"
              : "Evento";
          }}
        />
        <Column
          caption="Categoria"
          filterOperations={["contains"]}
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const key = Object.keys(MapperItemCategoryAndType).find((key) =>
              MapperItemCategoryAndType[
                key as keyof typeof MapperItemCategoryAndType
              ].includes(filterValue),
            );
            return ["category", selectedFilterOperation, key];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="category"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.category) return "-";
            if (data.type === ItemType.Confirmation)
              return MapperItemCategoryAndType[data.type];
            return MapperItemCategoryAndType[data.category];
          }}
        />
        <Column
          caption="Data e Hora"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const segments = filterValue.split(" ");
            const value = `${segments[0]
              .split("/")
              .filter((value: string) => value)
              .reverse()
              .join("-")}${segments[1] ? `T${segments[1]}` : ""}`;
            return ["timestamp", selectedFilterOperation, value];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="timestamp"
          cellRender={(params: ICellRenderParams) => {
            if (!params.data.timestamp) return "-";
            return moment(`${params.data.timestamp}Z`).format(
              "DD/MM/YYYY HH:mm:ss",
            );
          }}
        />
        <Column
          caption="Título"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            return ["title", selectedFilterOperation, filterValue];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="title"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.title) return "-";
            return data.title;
          }}
        />
        <Column
          caption="Subtítulo"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            return ["subTitle", selectedFilterOperation, filterValue];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="subTitle"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.subTitle) return "-";
            return data.subTitle;
          }}
        />
        <Column
          caption="Descrição"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            return [
              "authorizationDescription",
              selectedFilterOperation,
              filterValue,
            ];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="authorizationDescription"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.authorizationDescription) return "-";
            return data.authorizationDescription;
          }}
        />
        <Column
          caption="Cartão Final"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            return ["cardLast4Digits", selectedFilterOperation, filterValue];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="cardLast4Digits"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.cardLast4Digits) return "-";
            return data.cardLast4Digits;
          }}
        />
        <Column
          caption="Valor"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const value = removeCipherFormatter(filterValue);
            return ["principalAmount", selectedFilterOperation, value];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="principalAmount"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.principalAmount) return "-";
            return MoneyMask(data.principalAmount, Currency.BRL);
          }}
        />
      </DataGrid>
    </ColumnGap>
  );
};
