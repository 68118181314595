import { ODataGridGenerator } from "components/data-grid";
import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { useEffect } from "react";
import { OLoader, OToastManager, OTypography } from "@maestro/react";
import { useIntubateOperationsWithoutRegistry } from "../../intubate-operations-without-registry.context";
import { validateGrid } from "./validate.grid";

export const ValidateStep = () => {
  const { dataReceivables } = useIntubateOperationsWithoutRegistry();

  const {
    callService: validateOperationsWithoutRegistry,
    hasError: validateHasError,
    loading: validateLoading,
    value: validateResult,
  } = useServiceCall(service.quickfin.validateOperationsWithoutRegistry);

  useEffect(() => {
    async function validate() {
      await validateOperationsWithoutRegistry(dataReceivables);
    }
    validate();
  }, [dataReceivables, validateOperationsWithoutRegistry]);

  const gridRef = useGridRef();

  if (validateHasError) {
    OToastManager.danger(
      "Falha ao executar validações. Tente novamente ou entre em contato com o time de TI",
    );
  }

  const hasInvalid =
    validateResult !== undefined &&
    validateResult.some(({ status }) => status !== "Success");

  if (hasInvalid) {
    OToastManager.warning(
      "Atenção! Detectamos alguns pontos de alerta. Analise a tabela abaixo antes de prosseguir.",
    );
  }

  if (
    validateResult?.every(
      ({ status }) => status === "Success" || status === "Warning",
    )
  ) {
    OToastManager.success("Operação validada com sucesso!");
  }

  const allowNext =
    validateResult !== undefined &&
    validateResult.every(
      ({ status }) => status === "Success" || status === "Warning",
    );

  return validateLoading ? (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>
        Aguarde enquanto validamos a sua requisição. Isso pode demorar alguns
        minutos.
      </OTypography>
      <OLoader size="sm" />
    </div>
  ) : (
    <div className="d-flex flex-column gap-4">
      <GridTemplate gridRef={gridRef} showExportButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={validateGrid}
          dataSource={validateResult}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <NextStepButton disabled={!allowNext} />
      </div>
    </div>
  );
};
