import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { RecebiveisClienteEstrategiaComercialByEstrategiaIdRouteParams } from "../../../../../../routes/recebiveis.route-params";

export const useEstrategiaComercial = () => {
  const { estrategiaId } =
    useParams<RecebiveisClienteEstrategiaComercialByEstrategiaIdRouteParams>();
  if (!estrategiaId) throw new Error("No estrategiaId");

  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    callService,
    hasError,
    loading,
    value: estrategia,
  } = useServiceCall(service.quickfin.getCompanyEstrategia);

  const getCompanyEstrategia = useCallback(
    () => callService(quickfinId, estrategiaId),
    [callService, estrategiaId, quickfinId],
  );

  useEffect(() => {
    getCompanyEstrategia();
  }, [getCompanyEstrategia]);

  return {
    estrategia,
    estrategiaId,
    getCompanyEstrategia,
    hasError,
    loading,
    quickfinId,
  };
};
