import {
  OButton,
  OIcon,
  ONotification,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useCardsCustomerContext } from "pages/cartoes/contexts";
import {
  CardModality,
  MapperCardModality,
} from "services/bankinghub/models/types/cards";
import { RequirementsModal } from "./requirements-modal/requirements-modal.component";
import { RequirementsModalId } from "./requirements-modal/requirements-modal.types";

export const CardHiringStatusNotification = () => {
  const { account, offer } = useCardsCustomerContext();

  const ref = account?.offer ?? offer;

  if (!ref) return null;

  const { isOnHiringProcess, isOnDebitHiringProcess } = ref;

  const modality = isOnHiringProcess
    ? CardModality.Credit
    : isOnDebitHiringProcess
    ? CardModality.Debit
    : undefined;

  return (
    <div>
      <RequirementsModal />
      {modality && (
        <ONotification type="warning">
          <div className="d-flex flex-column gap-2">
            <OTypography weight="300">
              Verifique as etapas pendentes para a solicitação do cartão de{" "}
              {MapperCardModality[modality].toLowerCase()}. O pedido será
              encaminhado para a análise após as aprovações necessárias.
            </OTypography>

            <OButton
              type="primary"
              size="sm"
              outline
              bordered={false}
              disabled={!offer}
              onClick={() => {
                modalManager.show(RequirementsModalId);
              }}
            >
              <div className="d-flex flex-row gap-2">
                <OTypography type="primary" size="sm">
                  Conferir pendências
                </OTypography>
                <OIcon
                  className="align-self-center"
                  category="orq"
                  icon="orq-chevron-right"
                  type="primary"
                />
              </div>
            </OButton>
          </div>
        </ONotification>
      )}
    </div>
  );
};
