import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { FormalizeCollateralButton } from "./_compose/formalize-collateral-button/formalize-collateral-button.component";
import { CreateAutoSimulationButton } from "./create-auto-simulation-button.component";
import { limitManagerContingenciesGrid } from "./limit-manager-contingencies.grid";
import { LinkToExistingCprButton } from "./linkt-to-existing-cpr-button.component";
import { SyncAgromaticButton } from "./sync-agromatic-button.component";
import { UpdateAgroSacksButton } from "./update-agro-sacks-button.component";
import { UpdateAmountButton } from "./update-amount-button.component";

export const LimitManagerContingenciesPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const {
    callService: getLimitRequest,
    value: limitRequest,
    hasError: hasErrorLimitRequest,
    loading: loadingLimitRequest,
  } = useServiceCall(service.hubCreditManager.getLimitRequestMinified);

  useEffect(() => {
    getLimitRequest(id);
  }, [getLimitRequest, id]);

  const productIsAgro =
    limitRequest?.currentLimit?.creditLine.identification === "AGRO";

  const dataSource = useMemo(
    () => [
      {
        description: "Vincular limite a uma CPR existente",
        component: <LinkToExistingCprButton limitRequestId={+id} />,
      },
      {
        description: "Sincronizar dados da proposta com Agromatic",
        component: (
          <SyncAgromaticButton limitRequestId={+id} disabled={false} />
        ),
      },
      {
        description:
          "Atualizar data de apuração e preços das sacas de propostas de Agro",
        component: (
          <UpdateAgroSacksButton
            limitRequestId={+id}
            disabled={!productIsAgro}
          />
        ),
      },
      {
        description: "Gerar operação de Agro",
        component: <CreateAutoSimulationButton limitRequest={limitRequest} />,
      },
      {
        description: "Atualizar valor da proposta",
        component: <UpdateAmountButton limitRequestId={+id} />,
      },
      {
        description: "Formalizar garantia",
        component: <FormalizeCollateralButton limitRequest={limitRequest} />,
      },
    ],
    [id, limitRequest, productIsAgro],
  );

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Contingências" />}>
      <ContentTemplate
        loading={loadingLimitRequest}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os dados do limite."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getLimitRequest(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorLimitRequest}
        value={limitRequest}
        render={() => (
          <ODataGridGenerator
            grid={limitManagerContingenciesGrid}
            dataSource={dataSource}
          />
        )}
      />
    </DetailsTemplate>
  );
};
