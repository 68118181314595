import { OToastManager, modalManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { RoleAssignmentsForm } from "./role-assignments.types";
import { simpleConfirmationModalId } from "./simple-confirmation-modal";
import { vigencySelectModalId } from "./vigency-select-modal";

export const useRoleAssignmentsSubmission = (
  form: UseFormReturn<RoleAssignmentsForm>,
  editing: boolean,
) => {
  const [ceilingVigencyDate, setCeilingVigencyDate] = useState<string>();

  const {
    customer: { identification },
  } = useCustomer();

  const { callService: checkEmail, loading: checkEmailLoading } =
    useServiceCall(service.onboardingRelationships.checkEmail);

  const {
    callService: checkProfiles,
    loading: checkProfilesLoading,
    value: profileValidationResponse,
  } = useServiceCall(service.onboardingRelationships.checkProfiles);

  const { callService: createUser, loading: submitLoading } = useServiceCall(
    service.onboardingRelationships.createUser,
  );

  const navigate = useNavigate();

  const { getValues, handleSubmit } = form;

  const validateEmail = useCallback(
    async (email: string, taxId: string) => {
      const { success, response } = await checkEmail({
        email,
        taxId,
      });

      if (!success) {
        OToastManager.danger("Não foi possível validar o e-mail");
      } else {
        return response.data;
      }
    },
    [checkEmail],
  );

  const validateProfiles = useCallback(
    async (taxId: string, roles: string[]) => {
      const { success, response } = await checkProfiles({
        relatedTaxId: identification,
        roles,
        taxId,
      });

      if (!success) {
        OToastManager.danger("Não foi possível validar as funções");
      } else {
        return response.data.status;
      }
    },
    [checkProfiles, identification],
  );

  const submit = useCallback(
    async (vigencyDate?: string) => {
      const { sendEmail, ...values } = getValues();

      const { success } = await createUser({
        relatedTaxId: identification,
        ...values,
        sendEmail: sendEmail?.includes("check"),
        vigencyDate: vigencyDate ?? null,
      });

      if (success) {
        const message = editing
          ? "Usuário editado com sucesso"
          : "Usuário adicionado com sucesso";
        OToastManager.success(message);
        if (!editing) navigate(corporateRouter.routes.usuarios.customer.path);
      } else {
        const message = editing
          ? "Erro ao editar o usuário"
          : "Erro ao adicionar o usuário";
        OToastManager.danger(message);
      }
    },
    [createUser, editing, getValues, identification, navigate],
  );

  const preSubmit = useMemo(
    () =>
      handleSubmit(
        async (values) => {
          if (!editing) {
            const emailValidationData = await validateEmail(
              values.email,
              values.taxId,
            );

            if (!emailValidationData) return;

            switch (emailValidationData.status) {
              case "WORKFLOW_ALREADY_EXIST":
                OToastManager.danger(
                  emailValidationData.reason ??
                    "Usuário já existe. Redirecionando para a edição",
                );
                navigate(
                  corporateRouter.routes.usuarios.customer.users.roleAssignments.path(
                    {
                      identification: values.taxId,
                    },
                  ),
                );
                return;
              case "USER_EXIST_DIFFERENT_EMAIL":
              case "USER_NOT_EXIST_EMAIL_IN_USE":
                OToastManager.danger(
                  emailValidationData.reason ??
                    "Não foi possível validar o e-mail",
                );
                return;
              case "CAN_PROCEED":
              default:
                break;
            }
          }

          const profilesValidationStatus = await validateProfiles(
            values.taxId,
            values.roles,
          );

          switch (profilesValidationStatus) {
            case "NENHUMA_ACAO_NECESSARIA":
              submit();
              break;
            case "NAO_EXISTE_LESS":
            case "PROCURACAO_ELETRONICA_DESABILITADO":
              modalManager.show(simpleConfirmationModalId);
              break;
            case "NAO_POSSUI_PODERES":
              modalManager.show(vigencySelectModalId);
              break;
            default:
              break;
          }
        },
        (errors) => {
          if (errors.roles) {
            OToastManager.danger("Selecione ao menos uma função");
          }
        },
      ),
    [editing, handleSubmit, navigate, submit, validateEmail, validateProfiles],
  );

  return {
    ceilingVigencyDate,
    checkEmailLoading,
    checkProfilesLoading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
    submitLoading,
  };
};
