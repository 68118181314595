import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import {
  RegisterSolicitation,
  registerSolicitationsGrid,
} from "./register-solicitations.grid";

const dataSource = dataSourceCustomStoreGenerator<RegisterSolicitation>(
  (loadOptions) =>
    service.recebiveisCartao
      .getSolicitations(loadOptions as any) // sort
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar as solicitações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const RegisterSolicitations = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Solicitações de registro" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={registerSolicitationsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
