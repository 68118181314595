import { OToastManager } from "@maestro/core";
import { useCallback } from "react";
import { service } from "services";

export const usePropertyFieldComponent = () => {
  const handleSubmitProperty = useCallback(
    async (
      value: string,
      processorPropertyId: number,
      processorConfigId: number,
    ) => {
      try {
        const invalidValue = value === null || value === undefined;

        await service.hubCreditManager.updateProcessorConfigProperty({
          processorPropertyId,
          processorConfigId,
          value: invalidValue ? null : String(value),
        });
        OToastManager.success("Propriedades alteradas com sucesso.");
      } catch (err) {
        OToastManager.danger("Não foi possível atualizar a propriedade.");
      }
    },
    [],
  );

  return { handleSubmitProperty };
};
