import { OCol, ORFieldInputDateRange } from "@maestro/react";

export const IssueDate = () => {
  return (
    <OCol xs={12} md={6} xl={4}>
      <ORFieldInputDateRange
        allowClear
        id="issueDate"
        name="issueDate"
        label="Data emissão"
        placeholder={["Data de início", "Data de fim"]}
      />
    </OCol>
  );
};