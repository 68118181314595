import {
  modalManager,
  ODivider,
  OLoader,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { TipoProdutoField } from "../../../../../../components/estrategia-form/fields";
import { useCompanyEstrategiaDetails } from "./company-estrategia-details.hook";
import {
  UpdateEstrategiaConfirmationModal,
  updateEstrategiaConfirmationModalId,
} from "../_compose/update-estrategia-confirmation-modal";

export const CompanyEstrategiaDetails = () => {
  const { Form, form, loading, submit, submitLoading } =
    useCompanyEstrategiaDetails();

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Detalhes Estratégia" />}
        actions={
          <LoadingButton
            dataAction="formulario:botao:salvar"
            dataLabel="salvar"
            loading={submitLoading}
            disabled={loading}
            onClick={() =>
              modalManager.show(updateEstrategiaConfirmationModalId)
            }
          >
            Salvar
          </LoadingButton>
        }
      >
        <CardTemplate>
          {(loading || submitLoading) && <OLoader absolute backdrop />}
          <FormProvider {...form}>
            <OTypography size="lg">Tipo de produto</OTypography>
            <ODivider type="dark" size="xxs" className="mb-2" />
            <ORow columnGap={4} rowGap={5} className="ms-2">
              <TipoProdutoField type="company" disabled />
            </ORow>

            {Form && <Form form={form} />}
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <UpdateEstrategiaConfirmationModal submit={submit} form={form} />
    </>
  );
};
