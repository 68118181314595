import { JSX } from "@maestro/core";
import { OBadge, OIcon } from "@maestro/react";
import { ConfigurableStatus } from "services/hubcreditmanager/models/types/workflow/workflow.types";

export const ConfigurableStatusBadge = (
  configurableStatus: ConfigurableStatus,
) => {
  const { color, iconName, id, name } = configurableStatus;

  return (
    <OBadge
      type={color}
      size="sm"
      className="d-flex align-items-center"
      key={`configurable-status-badge-${id}`}
    >
      <OIcon
        size="sm"
        category={iconName.split("-")[0] as JSX.OIcon["category"]}
        className="mt-1"
        icon={iconName}
      />
      {name}
    </OBadge>
  );
};
