/* eslint-disable no-param-reassign */
import { OField, ORFieldInputProps, TooltipContainer } from "@maestro/react";

import { useFormContext } from "react-hook-form";
import { OTextareaStyled } from "./coding-text-area.styles";

const handleKeydown = (event: any) => {
  if (event.key === "Tab") {
    event.preventDefault();
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    event.target.value =
      event.target.value.substring(0, start) +
      "\t" +
      event.target.value.substring(end);
    event.target.selectionStart = start + 1;
    event.target.selectionEnd = start + 1;
  }
};

export const CodingTextArea = (props: ORFieldInputProps<"textarea">) => {
  const {
    id,
    name,
    label,
    labelType,
    labelSize,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    ...rest
  } = props;

  const { register, formState, trigger, getFieldState } = useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  const fieldOptions = {
    description,
    htmlFor: id,
    label,
    labelSize,
    labelType,
    required: !!required,
    error: !!fieldErrors,
    message: fieldErrors?.message,
  };

  return (
    <OField {...fieldOptions}>
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}

      <OTextareaStyled
        id={id}
        onKeyDown={(e) => handleKeydown(e)}
        {...rest}
        error={!!fieldErrors}
        {...register(name)}
        onInput={(e) => {
          register(name).onChange(e);
          if (fieldErrors) {
            trigger(name);
          }
        }}
      />
    </OField>
  );
};
