import { OToastManager } from "@maestro/core";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { customer as globalCustomer } from "./customer";
import { ClearCustomerCommand, GetCustomerCommand } from "./customer.commands";
import { customerEvents } from "./customer.event";
import { customerManager } from "./customer.invoker";
import { getCustomerFromStorage } from "./customer.utils";
import { CustomerType } from "./types/customer.type";
import { CustomerState } from "./types/state";

interface CustomerContext {
  customer: CustomerType | undefined;
  state: CustomerState;
  refetch: () => void;
}

const customerContext = createContext<CustomerContext>({
  customer: globalCustomer.value,
  state: globalCustomer.state,
  refetch: () => {},
});

export const CustomerProvider = ({ children }: PropsWithChildren) => {
  const [customer, setCustomer] = useState(globalCustomer.value);
  const [customerState, setCustomerState] = useState(globalCustomer.state);

  const refetch = useCallback(() => {
    if (!customer?.identification) {
      return;
    }

    customerManager.execute(new GetCustomerCommand(customer?.identification));
  }, [customer?.identification]);

  useEffect(() => {
    const cleanUp = customerEvents.subscribe((newCustomer) => {
      setCustomer(newCustomer.value);
      setCustomerState(newCustomer.state);
      if (newCustomer.state === CustomerState.NOT_FOUND) {
        OToastManager.warning(
          "Não foi possível obter mais informações da empresa. Verifique o processo de onboarding.",
        );
      } else if (newCustomer.state === CustomerState.ERROR) {
        OToastManager.danger(
          "Ocorreu um problema ao selecionar o cliente. Tente novamente.",
        );
      }
    });

    return cleanUp;
  }, []);

  /** Initialize with customer from storage */
  useEffect(() => {
    getCustomerFromStorage();
  }, []);

  const value = useMemo(
    () => ({
      customer,
      state: customerState,
      refetch,
    }),
    [customer, customerState, refetch],
  );

  return (
    <customerContext.Provider value={value}>
      {children}
    </customerContext.Provider>
  );
};

/** Simply returns the context as it is */
export const useUnsafeCustomer = () => useContext(customerContext);

/** Guarantees that there is a customer selected */
export const useCustomer = () => {
  const { customer, ...rest } = useContext(customerContext);

  if (!customer) {
    throw new Error(
      "No customer selected. Wrap your route in `<CustomerRoute>` or use `useUnsafeCustomer`.",
    );
  }

  return { customer, ...rest };
};

// Command wrappers

/** Fetches a customer for a given tax id */
export const getCustomer = (taxId: string) => {
  customerManager.execute(new GetCustomerCommand(taxId));
};

/** Clears the customer object */
export const clearCustomer = () => {
  customerManager.execute(new ClearCustomerCommand());
};
