import { OCol, ORFieldInputDateRange, ORow } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { InfoCardContent } from "./_compose/info-card/workflow-info-card-content.component";
import { InfoCard } from "./_compose/info-card/workflow-info-card.component";
import { DashboardOriginatorSelector } from "./_compose/selectors/originator-selector.component";
import { DashboardWorkflowConfigSelector } from "./_compose/selectors/workflow-config-selector.component";
import { workflowDashboardMap } from "./dashboard.grid";
import { useWorkflowLeadDashboardPage } from "./dashboard.hook";

export const WorkflowLeadDashboardPage = () => {
  const { form, dashboardDatas, handleGetDashboardByDateRange } =
    useWorkflowLeadDashboardPage();
  return (
    <FormTemplate pageTitle={<PageTitle title="Dashboard" />}>
      <CardTemplate>
        <FormProvider {...form}>
          <ORow align="start">
            <OCol xs={12} sm={6} lg={4}>
              <DashboardWorkflowConfigSelector />
            </OCol>
            <OCol xs={12} sm={6} lg={4}>
              <DashboardOriginatorSelector />
            </OCol>
            <OCol xs={12} lg={4}>
              <div className="position-relative w-100">
                <ORFieldInputDateRange
                  id="period"
                  name="period"
                  label="Período"
                />
              </div>
            </OCol>
          </ORow>
          <ORow gap={2}>
            <OCol xs={12} lg={12} />
            {workflowDashboardMap.map((column) => {
              return (
                <OCol
                  key={column.dataField}
                  xs={12}
                  sm={column.colLg ?? 6}
                  lg={column.colLg ?? 3}
                >
                  <InfoCard
                    {...dashboardDatas}
                    callService={handleGetDashboardByDateRange}
                    render={(_value) => {
                      let value;

                      const { cellRender } = column;

                      const Content = cellRender;

                      try {
                        value = JSON.parse(_value[column.dataField].toString());
                      } catch (error) {
                        value = "";
                      }

                      return (
                        <InfoCardContent
                          header={column.header}
                          footer={column.footer}
                          body={
                            Content ? (
                              <Content data={_value} />
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )
                          }
                        />
                      );
                    }}
                  />
                </OCol>
              );
            })}
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
