import { OBadge, OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { currencySymbol } from "utils/currency";

type WorkflowLeads = Awaited<
  ReturnType<typeof service.hubCreditManager.odata.getWorkflowLeads>
>["data"]["value"][number];

export const workflowLeadsBatchGrid = (
  batchRequestId: number,
  selectedWorkflowConfig?: string,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum lead do workflow encontrado",
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.workflowLeadsAdmin,
      ),
      headerFilter: { visible: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.workflow.product.leadsDetailsSummaryPage.role,
        cellRender: ({ data }) =>
          data.Id ? (
            <DetailsLink
              href={corporateRouter.routes.workflow.product.leads.details.summary.path(
                {
                  id: data.Id,
                },
              )}
            />
          ) : undefined,
      },
      {
        dataField: "BatchRequestId",
        filterValue: batchRequestId,
        dataType: "number",
      },
      {
        dataField: "Id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "LeadCreatedDate",
        caption: "Data de criação",
        dataType: "datetime",
        sortOrder: "desc",
      },
      {
        dataField: "LastStatusChangeDate",
        caption: "Atualizado em",
        dataType: "datetime",
      },
      {
        dataField: "WorkflowConfigName",
        caption: "Workflow",
        dataType: "string",
      },
      {
        dataField: "CustomerName",
        caption: "Cliente",
        dataType: "string",
      },
      {
        dataField: "CustomerTaxId",
        caption: "Identificação",
        dataType: "string",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        dataField: "DesiredAmount",
        caption: "Valor Solicitado",
        dataType: "number",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "ApprovedAmount",
        caption: "Valor Aprovado",
        dataType: "number",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "StatusConfigs",
        caption: "Status",
        dataType: "string",
        cellRender: ({ data }) => {
          return (
            <div className="d-flex flex-column gap-2">
              {data?.StatusConfigs.map((status) => (
                <OBadge key={status.Name} size="sm" type={status.Color}>
                  <OIcon category="orq" icon={status.IconName} />
                  {status.Name}
                </OBadge>
              ))}
            </div>
          );
        },
      },
      {
        dataField: "Channel",
        caption: "Canal",
        dataType: "string",
      },
      {
        dataField: "Originator",
        caption: "Originador",
        dataType: "string",
      },
      {
        dataField: "Operator",
        caption: "Operador",
        dataType: "string",
      },
      {
        dataField: "CorrelationId",
        dataType: "string",
      },
      {
        dataField: "WorkflowConfigIdentification",
        caption: "Identificador de Configuração do Workflow",
        filterValue: selectedWorkflowConfig,
        dataType: "string",
      },
      {
        dataField: "PartnerRelationshipTypes",
        caption: "Tipo de Relacionamento",
        dataType: "string",
      },
    ],
  } satisfies ODataGridGeneratorConfig<WorkflowLeads>);
