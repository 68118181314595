import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import {
  buildDataSource,
  workflowCreditLinesGrid,
} from "./workflow-credit-lines.grid";

export const WorkflowCreditLinesPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const gridRef = useGridRef();

  const workflowCreditLinesDataSource = useMemo(
    () => buildDataSource(id),
    [id],
  );

  return (
    <GridTemplate
      gridRef={gridRef}
      pageTitle={<PageTitle title="Linhas de crédito solicitadas" />}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={workflowCreditLinesGrid(id)}
        dataSource={workflowCreditLinesDataSource}
      />
    </GridTemplate>
  );
};
