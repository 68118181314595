import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { EstrategiaForm } from "../estrategia-form.types";

export const useInstrumentCodeIdField = () => {
  const {
    callService,
    loading,
    value: instrumentCodes,
    hasError,
  } = useServiceCall(service.quickfin.getInstrumentCode);

  const { clearErrors, setError, watch } = useFormContext<EstrategiaForm>();

  const tipoProdutoWatcher = watch("tipoProduto");

  const getInstrumentCodes = useCallback(async () => {
    const { success } = await callService(tipoProdutoWatcher);
    if (success) clearErrors("instrumentCodeId");
    else
      setError("instrumentCodeId", {
        message:
          "Erro ao buscar os instrument codes. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError, tipoProdutoWatcher]);

  useEffect(() => {
    getInstrumentCodes();
  }, [getInstrumentCodes]);

  return {
    instrumentCodes,
    getInstrumentCodes,
    loading,
    hasFetchError: hasError,
  };
};
