import { ODataGridGenerator } from "components/data-grid";
import { OTextButton } from "components/o-text-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import {
  Section,
  SideFilterBar,
  useSideFilterBar,
} from "components/side-filter-bar";
import { useGridRef } from "hooks/grid-ref";
import { useEffect, useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { assembleSections } from "./_compose";
import { DataGridPagination } from "./_compose/components/data-grid-pagination";
import { usePaymentsAndTransfersContext } from "./payments-and-transfers.context";
import { paymentsAndTransfersGrid } from "./payments-and-transfers.grid";
import { usePaymentsAndTransfers } from "./payments-and-transfers.hook";
import {
  DEFAULT_PAGE_SIZES,
  INITIAL_PAGE_NUMBER,
} from "./payments-and-transfers.types";

const PaymentsAndTransfersComponent = () => {
  const {
    dataSource,
    isLoadingDataSource,
    pageSize,
    filtersSubmitted,
    setPageSize,
    pageCursors: [previousPageCursors, nextPageCursors],
    pageNumber: page,
    setPageNumber: setPage,
  } = usePaymentsAndTransfersContext();
  const {
    initialUpdateDataSource,
    submitFilters,
    handleRefreshDataSource,
    handlePageSize,
    handleNext,
    handlePrevious,
    seeDetails,
    downloadSingleReceipt,
  } = usePaymentsAndTransfers();

  const sections = useMemo((): Section[] => {
    return assembleSections(filtersSubmitted);
  }, [filtersSubmitted]);

  const { clearForm } = useSideFilterBar(sections, submitFilters);

  const gridRef = useGridRef();
  const grid = useMemo(
    () => paymentsAndTransfersGrid(seeDetails, downloadSingleReceipt),
    [seeDetails, downloadSingleReceipt],
  );

  useEffect(() => {
    initialUpdateDataSource();
  }, [initialUpdateDataSource]);

  return (
    <div>
      <GridTemplate
        gridRef={gridRef}
        pageTitle={<PageTitle title="Pagamentos e transferências" />}
        actions={
          <div className="d-flex align-items-center gap-2">
            <OTextButton
              type="dark"
              outline
              bordered={false}
              onClick={clearForm}
              disabled={isLoadingDataSource || !filtersSubmitted}
            >
              Limpar filtros
            </OTextButton>

            <SideFilterBar
              sections={sections}
              submitFilters={submitFilters}
              defaultValuesSubmitted
            />

            <RefreshGridButton
              onClick={handleRefreshDataSource}
              disabled={isLoadingDataSource}
            />
          </div>
        }
      >
        <div className="d-flex flex-column gap-2">
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            dataSource={dataSource}
            loading={isLoadingDataSource}
          />

          <DataGridPagination
            hasNext={!!nextPageCursors}
            hasPrevious={
              !!previousPageCursors || page === INITIAL_PAGE_NUMBER - 1
            }
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handlePageSize={handlePageSize}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            pageSizes={DEFAULT_PAGE_SIZES}
          />
        </div>
      </GridTemplate>
    </div>
  );
};

export const PaymentsAndTransfers = () => <PaymentsAndTransfersComponent />;
