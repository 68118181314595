import { useUser } from "contexts/user";
import { useRoles } from "hooks/roles";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { roles } from "roles/roles";
import { safeLocalStorage } from "utils/storage";
import { buildDataSource } from "../../../components/consumer-units-grid";
import { useDebouncedMemo } from "../../../hooks/debounced-memo";

const AGENT_FILTER_STORAGE_KEY = "ENERGY_CONSUMER_UNITS_AGENT_FILTER_ENABLED";

const getSavedFilterValue = () => {
  try {
    const storageValue = safeLocalStorage.getItem(AGENT_FILTER_STORAGE_KEY);
    if (!storageValue) return [];
    return JSON.parse(storageValue) as string[];
  } catch {
    return [];
  }
};

export const useConsumerUnitsMonitor = () => {
  const { hasRole } = useRoles();
  const {
    user: { cpf },
  } = useUser();

  const showOnlyOwnConsumerUnits = useMemo(
    () => hasRole(roles.energia.product.showOnlyOwnConsumerUnits.role),
    [hasRole],
  );

  const form = useForm({
    defaultValues: {
      agent: showOnlyOwnConsumerUnits && cpf ? [cpf] : getSavedFilterValue(),
    },
  });

  const { watch } = form;

  const agentWatch = watch("agent");
  const stringifiedAgentWatch = JSON.stringify(agentWatch);

  const agentTaxIds = useDebouncedMemo(
    () => (agentWatch.length ? agentWatch : undefined),
    500,
    [stringifiedAgentWatch],
  );

  useEffect(() => {
    safeLocalStorage.setItem(AGENT_FILTER_STORAGE_KEY, stringifiedAgentWatch);
  }, [stringifiedAgentWatch]);

  const dataSource = useMemo(
    () => buildDataSource(undefined, agentTaxIds),
    [agentTaxIds],
  );

  return { form, dataSource, showOnlyOwnConsumerUnits };
};
