import { OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GroupForm, UploadGroupFile } from "../_compose";
import { useEditGroup } from "./edit-group.hook";

export const EditGroup = () => {
  const { form, loading, setCompanyGroup, submit } = useEditGroup();

  return (
    <>
      <PageTitle title="Editar grupo" />
      <FormTemplate
        actions={
          <LoadingButton
            loading={loading}
            dataAction="detalhes_grupo:botao:atualizar"
            dataLabel="atualizar"
            onClick={submit}
          >
            Atualizar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            {loading && <OLoader absolute />}

            <GroupForm />

            <UploadGroupFile setCompanyGroup={setCompanyGroup} />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
    </>
  );
};
