import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";

const today = new Date();
const firstDayISOString = new Date(
  today.getFullYear(),
  today.getMonth(),
  1,
).toISOString();
const todayISOString = today.toISOString();

const defaultValues = [firstDayISOString, todayISOString];

export const useExchangeProductDashboard = () => {
  const dateRange = useRef<(string | null)[]>(defaultValues);
  const dashboard = useServiceCall(service.hubFx.getByChannelDashboard);

  const form = useForm({
    defaultValues: { period: defaultValues },
  });

  const watchDate = form.watch("period");

  const getDashboard = dashboard.callService;

  const handleGetDashboardByDateRange = useCallback(async () => {
    const { period } = form.getValues();
    const [startDate, endDate] = period;

    const { response } = await getDashboard({ startDate, endDate });

    if (!response) return;

    dateRange.current = [response.data.startDate, response.data.endDate];
  }, [form, getDashboard]);

  useEffect(() => {
    handleGetDashboardByDateRange();
  }, [handleGetDashboardByDateRange]);

  useEffect(() => {
    const watchPeriodString = JSON.stringify(watchDate);
    const periodString = JSON.stringify(dateRange.current);

    if (watchPeriodString !== periodString) {
      dateRange.current = watchDate;
      handleGetDashboardByDateRange();
    }
  }, [handleGetDashboardByDateRange, watchDate]);

  return { handleGetDashboardByDateRange, form, dashboard };
};
