import { OOption, ORFieldSelect } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import {
  Desk,
  DeskMigrationOptionsForm,
} from "../../desk-migration-options.types";

interface AccountSelectorProps {
  desks: Desk[] | undefined;
}

const name = "accountStringified";

export const AccountSelector = ({ desks }: AccountSelectorProps) => {
  const form = useFormContext<DeskMigrationOptionsForm>();

  const { watch } = form;

  const deskCodeWatcher = watch("deskCode");

  const selectedDesk = desks?.find((desk) => desk.deskCode === deskCodeWatcher);

  return (
    <ORFieldSelect
      dataAction="formulario:select:conta"
      dataLabel="conta"
      id={name}
      name={name}
      label="Conta"
      placeholder={selectedDesk ? "Selecionar" : "Primeiro escolha uma mesa"}
      disabled={!selectedDesk}
      required
      key={`${deskCodeWatcher}${selectedDesk?.accounts?.length}`}
    >
      {selectedDesk?.accounts.map((account) => {
        const stringified = JSON.stringify(account);

        return (
          <OOption key={stringified} value={stringified}>
            {account.description}
          </OOption>
        );
      })}
    </ORFieldSelect>
  );
};
