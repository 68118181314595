import { OFilterItem, OIcon, OTypography } from "@maestro/react";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OTextButton } from "../../../../../o-text-button";
import { loadFieldValueReset } from "../../../../side-filter-bar.utils";

interface SectionLayoutProps {
  children: React.ReactNode;
  title: string;
  formId: string;
  formFieldIdsFiltered: string[];
  setFormFieldIdsFiltered: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SectionLayout: React.FC<SectionLayoutProps> = ({
  children,
  title,
  formId,
  formFieldIdsFiltered,
  setFormFieldIdsFiltered,
}) => {
  const isApplied = useMemo(
    () => formFieldIdsFiltered.includes(formId),
    [formFieldIdsFiltered, formId],
  );

  const { setValue, getValues, unregister } = useFormContext();

  const removeFilter = useCallback(
    (e: React.MouseEvent<HTMLOButtonElement, MouseEvent>) => {
      const fieldValue = getValues(formId);
      setValue(formId, loadFieldValueReset(fieldValue));
      unregister(formId);
      setFormFieldIdsFiltered((prev) => prev.filter((id) => id !== formId));
      e.preventDefault();
    },
    [getValues, formId, setValue, unregister, setFormFieldIdsFiltered],
  );

  return (
    <OFilterItem clean={false} notification={false} expanded={isApplied}>
      <div
        slot="title"
        className="d-flex flex-row justify-content-between align-content-center w-100"
      >
        <div className="d-flex align-items-center gap-2">
          {isApplied && (
            <OIcon category="fa" icon="fa-circle" type="tertiary" size="sm" />
          )}
          <OTypography>{title}</OTypography>
        </div>
        {isApplied && (
          <OTextButton
            className="px-4"
            type="primary"
            outline
            bordered={false}
            onClick={(e) => removeFilter(e)}
          >
            Remover
          </OTextButton>
        )}
      </div>

      <div className="p-3">{children}</div>
    </OFilterItem>
  );
};
