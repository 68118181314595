export const cardBrandMap = {
  master: "MasterCard",
  mastercard: "MasterCard",
  "mastercard credit": "MasterCard",
  "mastercard credito": "MasterCard",
  vis: "Visa",
  visa: "Visa",
  "visa credit": "Visa",
  "visa credito": "Visa",
  elo: "Elo",
  "elo credit": "Elo",
  "elo credito": "Elo",
  hiper: "HiperCard",
  hipercard: "HiperCard",
  "hipercard credit": "HiperCard",
  "hipercard credito": "HiperCard",
  amex: "AmericanExpress",
  "amex credit": "AmericanExpress",
  "amex credito": "AmericanExpress",
  american: "AmericanExpress",
  americanexpress: "AmericanExpress",
};
