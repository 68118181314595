import { OButton, OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

export const dashboardOnboardingGrid = {
  datagrid: {
    noDataText: "Nenhum registro encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "sacadoName", caption: "Sacado", sortOrder: "asc" },
    { dataField: "sacadoIdentification", caption: "Identificação" },
    {
      dataField: "estrategiaName",
      caption: "Nome Estratégia",
    },
    { dataField: "tipoContrato", caption: "Tipo" },
    { dataField: "statusContrato", caption: "Status" },
    { dataField: "externalReference", caption: "Referência Contrato" },
    { dataField: "isAtivo", caption: "Ativo" },
    {
      type: "buttons",
      cellRender: ({ data }) =>
        data.externalReference !== null &&
        data.statusContrato === "Signed" && (
          <OButton
            dataAction="onboarding_dashboard_detalhes:botao:baixar"
            dataLabel="baixar"
            outline
            onClick={async () => {
              try {
                if (!data.externalReference) return;
                const { data: blob } =
                  await service.paperclipv2.getContractSignedFileBinary(
                    data.externalReference,
                  );

                helpers.downloadBlobFile("contrato-assinado.pdf", blob);
                return;
              } catch {
                OToastManager.danger("Não foi possível validar a assinatura.");
              }
            }}
          >
            Baixar
          </OButton>
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.quickfin.getCompanyDetails>
  >["data"]["relacionamentosFornecedor"][number]
>;
