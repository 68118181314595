import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { HealthIndicator } from "./general-health.types";
import { healthStatusMap } from "./general-health.utils";

export const dataSource = dataSourceCustomStoreGenerator<HealthIndicator>(
  (loadOptions) =>
    service.recebiveisCartao
      .getHealthStatus(loadOptions as any)
      .then(({ data }) => data.data)
      .catch(() => {
        const errorMessage = "Erro ao buscar o status";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const generalHealthGrid: ODataGridGeneratorConfig<HealthIndicator> = {
  datagrid: {},
  columns: [
    {
      dataField: "titulo",
      caption: "Título",
      cssClass: "dx-word-wrap",
    },
    {
      dataField: "descricao",
      caption: "Descrição",
      cssClass: "dx-word-wrap",
    },
    {
      dataField: "count",
      caption: "Número",
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data: { status } }) => (
        <StatusBadge
          type={getValueFromMap(healthStatusMap, status) || "warning"}
        >
          {status}
        </StatusBadge>
      ),
    },
  ],
  paging: {
    defaultPageSize: 20,
  },
};
