import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValuesType } from "./batch-semaphore-consult.types";
import { validationSchema } from "./batch-semaphore-consult.form";

export const useBatchSemaphore = () => {
  const { callService: sendRequest, loading } = useServiceCall(
    service.onboarding.requestSemaphoreBatch,
  );

  const navigate = useNavigate();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const maxTaxIdsRequestQuantity = 1000;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { value } = values;

        if (!value) return;

        const taxIds = value.split(",").map((x) => x.trim());
        if (taxIds.length > maxTaxIdsRequestQuantity) {
          OToastManager.danger({
            title: "Não foi possível enviar a requisição",
            message: "Não é possível requisitar mais do que 5000 CNPJs/CPFs",
          });

          return;
        }

        const { success } = await sendRequest({ taxIds });

        if (success) {
          navigate(
            corporateRouter.routes.ferramentas.product.onboarding.semaphore
              .consults.path,
          );
          OToastManager.success({
            title: "Requisição enviada com sucesso",
            message: "Aguarde alguns minutos e estara disponível para consulta",
          });
        } else {
          OToastManager.danger({
            title: "Não foi possível enviar a requisição",
            message:
              "Tente novamente após um tempo caso não seja possível entre em contato com o time responsável",
          });
        }
      }),
    [handleSubmit, navigate, sendRequest],
  );

  return {
    form,
    loading,
    submit,
  };
};
