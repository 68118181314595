import styled, { css } from "styled-components";
import { OptionContainerProps } from "./option.types";

export const OptionContainer = styled.div<OptionContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  align-items: start;

  ${({ selected }) =>
    !selected &&
    css`
      border-bottom: 1px solid var(--theme-neutral-normal);
      :hover {
        background-color: var(--theme-dark-10);
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--theme-tertiary);
      o-typography {
        color: var(--theme-light);
        p {
          color: var(--theme-light);
        }
      }
    `}
`;
