import {
  OButton,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import React from "react";
import { FormProvider } from "react-hook-form";
import { FilterContent } from "./_compose";
import { FilterButton } from "./_compose/filter-button";
import { useFilter } from "./filter-modal.hook";
import { StyledModal } from "./filter-modal.styles";
import { FilterModalProps } from "./filter-modal.types";

export const FilterModal: React.FC<FilterModalProps> = ({
  gridRefInstance,
  ...props
}) => {
  const {
    prepareFilterValues,
    resetFilterValues,
    filterForm,
    filtersCount,
    ...filter
  } = useFilter(gridRefInstance);

  return (
    <>
      <FilterButton
        filtersCount={filtersCount}
        cleanFilters={resetFilterValues}
        onClick={() => modalManager.show("filter-modal")}
      />

      <StyledModal id="filter-modal" size="sm" position="right">
        <OModalHeader closeButton>
          <OTypography size="xxl" className="mb-2 text-left">
            Filtros
          </OTypography>
        </OModalHeader>

        <FormProvider {...filterForm}>
          <form className="w-100">
            <FilterContent {...filter} {...props} />
          </form>
        </FormProvider>

        <OModalFooter>
          <div className="d-flex justify-content-end align-items-center gap-2">
            <OButton type="dark" onClick={resetFilterValues} outline>
              Limpar
            </OButton>
            <OButton onClick={prepareFilterValues}>Aplicar</OButton>
          </div>
        </OModalFooter>
      </StyledModal>
    </>
  );
};
