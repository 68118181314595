import { OButton, OModal, OModalBody, OModalFooter, OModalHeader, OTypography } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { CompanySearch } from "../../../entuba-operacoes/_steps/_compose/company-search";
import { useSearchModal } from "./search-modal.hooks";

export const searchModalId = "search-modal-limit-control";

export const SearchModal = () => {
  const { form, submit, clear } = useSearchModal();

  return (
  <OModal id={searchModalId}>
    <OModalHeader>
      <OTypography size="lg">Parâmetros de busca</OTypography>
    </OModalHeader>
    <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <CompanySearch
                mode="Cedente"
                label="Nome ou identificação dos cedentes"
                name="cedentes"
                placeholder="Selecione o(s) cedente(s)"
                multiple
              /> 
          </div>
        </FormProvider>
    </OModalBody>
    <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            dataAction="entuba_sugestao:botao:limpar"
            dataLabel="limpar"
            type="dark"
            outline
            onClick={() => clear()}
          >
            Limpar
          </OButton>
          <OButton
            dataAction="entuba_sugestao:botao:buscar"
            dataLabel="buscar"
            onClick={() => submit()}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
  </OModal>
  )
};