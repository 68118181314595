import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { DownloadButtonProps } from "./download-button.types";

export const DownloadButton = ({ id }: DownloadButtonProps) => {
  const downloadFile = async () => {
    try {
      const { data } = await service.quickfin.downloadComprovanteById(id);

      helpers.downloadBlobFile("comprovante.pdf", data);
    } catch (e) {
      OToastManager.danger("Não foi possível baixar o comprovante");
    }
  };

  return (
    <IconButton
      dataAction="agendamentos:botao:baixar"
      dataLabel="baixar"
      icon={{ category: "orq", icon: "orq-download" }}
      onClick={downloadFile}
    >
      Baixar
    </IconButton>
  );
};
