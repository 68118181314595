import { OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
} from "components/data-grid";
import { Collaterals } from "../../collaterals.types";
import { CollateralsContractualPartiesGrid } from "./collaterals-contractual-parties-grid.component";
import { CollateralsRegistriesGrid } from "./collaterals-registries.grid.component";

type CollateralsPropertiesMasterDetailType = MasterDetailComponentProps<
  Collaterals[number]["properties"][number]
>;

export const CollateralsPropertiesMasterDetail = ({
  data: { data },
}: CollateralsPropertiesMasterDetailType) => {
  return (
    <div className="d-flex flex-column gap-4">
      <OTypography weight="bold">Partes relacionadas</OTypography>
      <ODataGridGenerator
        grid={CollateralsContractualPartiesGrid}
        dataSource={data.contractualParties}
      />

      <OTypography weight="bold">Cartórios</OTypography>
      <ODataGridGenerator
        grid={CollateralsRegistriesGrid}
        dataSource={data.registries}
      />
    </div>
  );
};
