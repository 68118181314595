import { Pie } from "react-chartjs-2";
import { useTheme } from "contexts/theme";

// register used components
// eslint-disable-next-line import/order, import/newline-after-import
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
Chart.register(ArcElement);
Chart.register(Legend);
Chart.register(Tooltip);

const chartConfig = {
  enabledPhysicalQuantity: {
    label: "Crédito",
    backgroundColor: {
      dark: "rgba(0, 160, 196, 0.8)",
      light: "rgba(0, 49, 60, 0.4)",
    },
    borderColor: {
      dark: "rgba(0, 160, 196, 1)",
      light: "rgba(0, 49, 60, 1)",
    },
  },
  enabledDebitQuantity: {
    label: "Débito",
    backgroundColor: {
      dark: "rgba(252, 255, 78, 0.8)",
      light: "rgba(243, 246, 18, 0.4)",
    },
    borderColor: {
      dark: "rgba(252, 255, 78, 1)",
      light: "rgba(165, 167, 25, 1)",
    },
  },
  enabledVirtualQuantity: {
    label: "Virtual",
    backgroundColor: {
      dark: "rgba(0, 173, 111, 0.4)",
      light: "rgba(0, 173, 111, 0.4)",
    },
    borderColor: {
      dark: "rgba(0, 173, 111, 1)",
      light: "rgba(0, 173, 111, 1)",
    },
  },
};

interface EnabledCardsPieChartProps {
  enabledPhysicalQuantity: number;
  enabledDebitQuantity: number;
  enabledVirtualQuantity: number;
}

export const EnabledCardsPieChart = ({
  enabledPhysicalQuantity,
  enabledDebitQuantity,
  enabledVirtualQuantity,
}: EnabledCardsPieChartProps) => {
  const { theme } = useTheme();

  return (
    <Pie
      data={{
        labels: [
          chartConfig.enabledPhysicalQuantity.label,
          chartConfig.enabledDebitQuantity.label,
          chartConfig.enabledVirtualQuantity.label,
        ],
        datasets: [
          {
            label: "Quantidade de Cartões Habilitados",
            data: [
              enabledPhysicalQuantity,
              enabledDebitQuantity,
              enabledVirtualQuantity,
            ],
            backgroundColor: [
              chartConfig.enabledPhysicalQuantity.backgroundColor[theme],
              chartConfig.enabledDebitQuantity.backgroundColor[theme],
              chartConfig.enabledVirtualQuantity.backgroundColor[theme],
            ],
            borderColor: [
              chartConfig.enabledPhysicalQuantity.borderColor[theme],
              chartConfig.enabledDebitQuantity.borderColor[theme],
              chartConfig.enabledVirtualQuantity.borderColor[theme],
            ],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};
