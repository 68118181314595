import * as yup from "yup";

export interface DeskConfigOfficerFormValues {
  officerCge: number;
  isDefault: boolean;
}

export const deskConfigOfficerFormValidationSchema: yup.ObjectSchema<DeskConfigOfficerFormValues> =
  yup.object({
    officerCge: yup.number().required("Este campo é obrigatório"),
    isDefault: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
  });
