import { OModalHeader, OTypography } from "@maestro/react";

export const ExportModalHeader = () => {
  return (
    <OModalHeader closeButton>
      <OTypography tag="h1" weight="400" size="xl">
        Configurações do arquivo de extrato
      </OTypography>
    </OModalHeader>
  );
};
