import { OToastManager } from "@maestro/core";
import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";
import { LoanData } from "../loan-summary.types";

interface RepublishTradeProps {
  loanData: LoanData;
}

const republishStatus = [
  "TradeEnviado",
  "Invalido",
  "Cancelado",
  "PendenteValidacao",
];

export const RepublishTrade = ({ loanData }: RepublishTradeProps) => {
  const [loading, setLoading] = useState(false);
  const form = useForm<{ eligibility: string[] }>({
    defaultValues: {
      eligibility: ["true"],
    },
  });

  const { handleSubmit } = form;

  const handleRepublishTrade = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubLoan.republishLoan({
            loanId: loanData.id,
            sendToEligibility: !!values?.eligibility?.length,
          });

          OToastManager.success("Trade reenviado com sucesso");
        } catch (e) {
          logger.error(e);

          OToastManager.danger("Houve um erro ao reenviar. Tente novamente.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, loanData.id],
  );

  return (
    <>
      {!!loanData && republishStatus.includes(loanData.status) && (
        <ButtonWithConfirmation
          confirmationModal={{
            title: "Tem certeza que deseja reenviar o trade para o FTS?",
            loading,
          }}
          onConfirm={() => !loading && handleRepublishTrade()}
          modalBody={
            <FormProvider {...form}>
              <ORFieldCheckboxGroup
                dataAction="section_name:type:named_event"
                dataLabel="named_event"
                description=""
                id="eligibility"
                name="eligibility"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox id="chk1" value="true" />
                  <OLabel htmlFor="chk1">Validação de elegibilidade</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </FormProvider>
          }
          type="default"
        >
          Reenviar trade - FTS
        </ButtonWithConfirmation>
      )}
    </>
  );
};
