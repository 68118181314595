import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { conciliacaoModalDefaultValues } from "./conciliacao-modal.form";
import { useConciliacaoContext } from "../extrato-liquidacao-posicao.context";

export const useConciliacaoModal = () => {
  const { setRangeDate } = useConciliacaoContext();

  const form = useForm({
    defaultValues: conciliacaoModalDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(
    () => reset(conciliacaoModalDefaultValues),
    [reset],
  );

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        setRangeDate({
          dataVencimentoInicial: dayjs(values.dataVencimentoInicial).format(
            "YYYY-MM-DD",
          ),
          dataVencimentoFinal: dayjs(values.dataVencimentoFinal).format(
            "YYYY-MM-DD",
          ),
        });
      }),
    [handleSubmit, setRangeDate],
  );

  return { form, clear, submit };
};
