import * as yup from "yup";

export interface AddStrategyFormValues {
  strategyName: string;
  strategyCr: number;
}

export const addStrategyFormValidationSchema = yup.object().shape({
  strategyName: yup.string().required("Campo obrigatório"),
  strategyCr: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "O valor mínimo é 0."),
});

export const addStrategyFormDefaultValues = {
  strategyName: "",
  strategyCr: NaN,
};
