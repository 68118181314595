import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ConfirmationDeleteCommonDomainModalProps } from "./delete-common-domain-modal.type";
import { deleteCommonDomainModalId } from "./delete-common-domain-modal.utils";

export const useConfirmationDeleteCommonDomainModal = ({
  id,
  reload,
}: ConfirmationDeleteCommonDomainModalProps) => {
  const modalId = deleteCommonDomainModalId(id);

  const { callService: deleteCommonDomain, loading } = useServiceCall(
    service.hubCreditManager.deleteCommonDomain,
  );

  const handleConfirm = useCallback(async () => {
    const commonDomainId = { id: Number(id) };
    const { success } = await deleteCommonDomain(commonDomainId);

    if (success) OToastManager.success("Common Domain deletada com sucesso!");
    else {
      OToastManager.danger("Erro para deletar a Common Domain.");
    }

    modalManager.hide(modalId);
    reload();
  }, [deleteCommonDomain, id, modalId, reload]);

  return { loading, modalId, handleConfirm };
};