import { OButton, OIcon, OLoader } from "@maestro/react";
import { useState } from "react";
import { generateCustomerServiceTokenAndRedirect } from "./customer-service-button.utils";

interface CustomerServiceButtonProps {
  cpf: string;
}

/**
 * Prevent children from rendering (and trying to make requests) while there is no authorization
 */
export const CustomerServiceButton = ({ cpf }: CustomerServiceButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handler = async () => {
    setLoading(true);

    await generateCustomerServiceTokenAndRedirect(cpf);

    setLoading(false);
  };

  return (
    <OButton
      dataAction="detalhes_usuarios:botao:ver_como_atendimento"
      dataLabel="ver_como_atendimento"
      type="dark"
      outline
      onClick={handler}
    >
      <span className="d-flex align-items-center gap-2">
        {loading ? (
          <OLoader size="xs" />
        ) : (
          <OIcon category="orq" icon="orq-support" />
        )}
        Ver como atendimento
      </span>
    </OButton>
  );
};
