import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { validators } from "@maestro/utils";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./assignor-new-account.form";

export const useNewAccountAssignor = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, watch, reset } = form;

  const watchTaxId = watch("taxId");

  const getSemaphore = useCallback(async () => {
    try {
      setLoading(true);

      const payload = {
        taxId: watchTaxId,
      };

      const { data } = await service.onboarding.getSemaphoreFirstAccess(
        payload,
      );

      if (data.semaphore === "GREEN") {
        const resetValues = {
          taxId: data.taxId,
          officialName: data.name,
          statusRFB: data.statusRFB,
          relationshipType: data.product.relationshipType,
          product: data.product.description,
        };

        reset(resetValues);
      }
    } catch (err) {
      OToastManager.danger(
        "Não foi possível encontrar o CPF/CNPJ na Receita Federal!",
      );
    } finally {
      setLoading(false);
    }
  }, [reset, watchTaxId]);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        taxId: values.taxId,
      };

      await service.onboarding.registerLegalEntity(payload);

      OToastManager.success("Cadastrado com sucesso");
      navigate(routes.empresas.product.assignor.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (watchTaxId && validators.cpfCnpj(watchTaxId)) {
      getSemaphore();
    }
  }, [getSemaphore, watchTaxId]);

  return { form, submit, loading, watchTaxId };
};
