import { DetailsTemplate } from "templates/details-template";
import { StepDetails, StepPendencies, StepProperties } from "./_compose";
import { DiligenceStepProvider } from "./diligence-step.context";

export const DiligenceStepPage = () => {
  return (
    <DiligenceStepProvider>
      <DetailsTemplate>
        <div className="d-flex flex-column gap-4">
          <StepDetails />
          <StepProperties />
          <StepPendencies />
        </div>
      </DetailsTemplate>
    </DiligenceStepProvider>
  );
};
