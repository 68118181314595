import { OToastManager } from "@maestro/core";
import { OBanner, OBannerBody, OBannerContent, OLink } from "@maestro/react";
import { ODataGridGenerator, dataSourceCustomStoreGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from 'react';
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { ddisManagementGrid } from "./management-ddis.grid";
import { ManagementForm } from "./management-ddis.type";

export const ManagementDdis = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo (() => dataSourceCustomStoreGenerator<ManagementForm>(() =>
    service.clerk
      .getDdis()
      .then(({ data }) => {
        if (!data) return [];
        return Promise.all(
          data.map(async (management) => {
            return {
              ...management,
              masksFormatted: management.masks.join(", "),
            };
          }),
        );
      }) // 204 returns empty string
      .catch(() => {
        const errorMessage = "Erro ao buscar a listagem";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  ), []);

  return (
    <div className="d-flex flex-column gap-4">
      <OBanner type="info" textTruncate={false}>
        <OBannerBody>
          <OBannerContent title="Aviso" size="md">
            Caso queira adicionar ou editar um DDI, entrar em contato com a <OLink type="light" weight="bold" href="mailto:OL-IT-BTGEmpresas-CashCorporate@btgpactual.com">OL-IT-BTGEmpresas-CashCorporate@btgpactual.com</OLink>
          </OBannerContent>
        </OBannerBody>
      </OBanner>
      <GridTemplate
        pageTitle={<PageTitle title="Gestão de DDIs" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={ddisManagementGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </div>
  );
};

