import {
  Banner,
  BannerTemplate,
} from "services/hubcreditmanager/models/responses";
import { GetApiAdminAccessRulesResponse } from "services/hubcreditmanager/models/responses/gep-api-admin-access-rules.response";
import { BannerFormFields } from "../banner-management-form.schema";

export const variablesKeys = ["temaPrincipal", "data", "hora"];

export interface StepRenderProps {
  children?: React.ReactElement;
  bannerTemplates?: BannerTemplate[];
  bannerTemplate?: BannerTemplate;
  data?: Banner;
  formData?: BannerFormFields;
  accessRules?: GetApiAdminAccessRulesResponse;
  setStep?: React.Dispatch<React.SetStateAction<string>>;
  updateBanner: (values: BannerFormFields) => Promise<void>;
}
