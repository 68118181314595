import { Currency } from "./currency.type";

interface CurrencySymbol {
  [key: string]: string;
}

export const currencySymbol: CurrencySymbol = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
  GBP: "£",
  CHF: "₣",
};

export const mapCurrencySymbol = (symbol: string | null | undefined) =>
  currencySymbol[symbol as Currency] || symbol || "";
