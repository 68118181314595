import { OToastManager } from "@maestro/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useBeneficiaryStatusCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [beneficiary, setBeneficiary] =
    useState<BankingHub.BeneficiaryInformation>();

  const getBeneficiaryInformation = useCallback(async () => {
    try {
      setErrorMessage("");
      setIsLoading(true);

      const { data } =
        await service.adminBankinghub.getBeneficiaryInformation();

      setBeneficiary(data);
    } catch (error: unknown) {
      const message =
        axios.isAxiosError(error) && error.response?.status === 404
          ? "Beneficiário não encontrado."
          : "Ocorreu um erro ao buscar o beneficiário.";

      OToastManager.danger(message);
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getBeneficiaryInformation();
  }, [getBeneficiaryInformation]);

  return {
    beneficiary,
    getBeneficiaryInformation,
    isLoading,
    errorMessage,
  };
};
