import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { TaxasPreCurvaItem } from "services/senna/models";
import { logger } from "utils/logger";
import { UploadFileHookType } from "./upload-file.types";
import { parseSpreadsheet } from "./upload-file.utils";

export const useUploadFile = ({ setCurvaPre }: UploadFileHookType) => {
  const {
    error: readerError,
    loading: readerLoader,
    reader,
    result,
  } = useReadFile();

  const [loading, setLoading] = useState(false);

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) reader.readAsBinaryString(file);
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        setLoading(true);

        const sheet = parseSpreadsheet(result) as TaxasPreCurvaItem[];

        setCurvaPre(sheet);
      } catch (err) {
        logger.error(err);
        OToastManager.danger("Erro para pegar os dados do arquivo.");
      } finally {
        setLoading(false);
      }
    }
  }, [readerError, result, setCurvaPre]);

  return {
    loading,
    readerLoader,
    handleAddFile,
  };
};
