import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { NotificationsComponent } from "../../../../components/notifications/notifications.component";
import { notificationsGrid } from "./notificacoes.grid";
import { useUserNotifications } from "./notificacoes.hook";

export const UserNotifications = () => {
  const { email, getUserByTaxId, hasError, loading } = useUserNotifications();

  return (
    <ContentTemplate
      hasError={hasError}
      loading={loading}
      value={email}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível buscar o usuário"
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton onClick={() => getUserByTaxId()} />
        </ErrorComponent>
      }
      render={(_email) => (
        <NotificationsComponent
          receiverEmail={_email}
          grid={notificationsGrid}
          notificationType="All"
          title="Notificações do usuário"
        />
      )}
    />
  );
};
