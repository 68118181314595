import { OToastManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { service } from "services";

interface ReprocessBatchButtonProps {
  gridRef: React.RefObject<
    DataGrid<HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem>
  >;
  reload: () => void;
}

export const ReprocessBatchButton = ({
  gridRef,
  reload,
}: ReprocessBatchButtonProps) => {
  const [loading, setLoading] = useState(false);

  const { callService } = useServiceCall(
    service.hubEnergy.reprocessConsumerUnitsImportData,
  );

  const reprocessImportData = useCallback(async () => {
    const selected =
      (await gridRef.current?.instance.getSelectedRowsData()) ?? [];

    if (!selected.length)
      return OToastManager.warning("Nenhuma importação selecionada");

    if (
      selected.some(
        ({ status }) => status === "IMPORTADO" || status === "PROCESSANDO",
      )
    )
      return OToastManager.warning(
        "Não é possível reprocessar importações com status IMPORTADO ou PROCESSANDO",
      );

    const promises = selected.map(({ id }) => callService(id));
    setLoading(true);
    try {
      await Promise.all(promises);
      OToastManager.success("As importações foram reprocessadas com sucesso");
      reload();
    } catch (err) {
      OToastManager.warning("Erro ao reprocessar as importações");
    } finally {
      setLoading(false);
    }
  }, [callService, gridRef, reload]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-swap-circle" }}
      loading={loading}
      onClick={reprocessImportData}
    >
      Reprocessar
    </IconButton>
  );
};
