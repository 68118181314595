import { OInputText } from "@maestro/react";
import { InputContent, InputLabel } from "../compose.styles";
import { IInputEmailProps } from "./question-text-compose.types";

export const InputEmail = ({
  name,
  label,
  placeholder,
  isEditable,
  fields,
  handleInputChange,
  errors,
}: IInputEmailProps) => {
  return (
    <div key={name}>
      <InputLabel>{label}</InputLabel>
      <InputContent>
        <OInputText
          dataAction={`chargeback:input:${label}`}
          dataLabel={label}
          id={name}
          name={name}
          value={fields[name]}
          placeholder={placeholder}
          disabled={!isEditable}
          onInput={(e) =>
            handleInputChange({
              name: name,
              value: e.currentTarget.value,
              label: label,
            })
          }
        />
        {errors[name] && <span>{errors[name]}</span>}
      </InputContent>
    </div>
  );
};
