import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { adminACCTradesODataGrid } from "../../../components/odata/acc-trades-odata.grid";

export const AdminProductACCTradesPage = () => {
  const gridRef = useGridRef();

  const quotesGrid = useMemo(() => adminACCTradesODataGrid(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !quotesGrid.datagrid.dataSource.isLoading() &&
              quotesGrid.datagrid.dataSource.reload();
          }}
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={quotesGrid} />
    </GridTemplate>
  );
};
