import { OOption, ORFieldSelect } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { ProposalDetails } from "./proposal-summary.types";
import { proposalStatusMap } from "../../monitor-agro-proposals.utils";

export const proposalSummaryDetailsGenerator = (proposal: ProposalDetails) => {
  return {
    details: {
      items: [
        {
          label: "Id da proposta",
          value: `AGRO#${proposal.data.id}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Nome do cliente",
          value: proposal.data.name ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CPF",
          value: masks.cpf(proposal.data.cpf) ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: (
            <ORFieldSelect
              dataAction="proposal_details:select:status"
              dataLabel="status"
              id="status"
              name="status"
            >
              {Object.values(proposalStatusMap).map((status) => (
                <OOption key={status.value} value={status.value}>
                  {status.label}
                </OOption>
              ))}
            </ORFieldSelect>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor em contratação",
          value: masks.currency(proposal.data.creditAmount, "R$", "."),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa",
          value: proposal.data.rate ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor aprovado",
          value: proposal.data.contractedValue
            ? masks.currency(proposal.data.contractedValue, "R$", ".")
            : "-",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    timeFields: {
      items: [
        {
          label: "Expira em",
          value: proposal.data.limitDateExpire
            ? dayjs(proposal.data.limitDateExpire).format("DD/MM/YYYY")
            : "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Atualizado em",
          value: proposal.data.lastModifiedAt
            ? dayjs(proposal.data.lastModifiedAt).format("DD/MM/YYYY")
            : "-",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    originator: {
      items: [
        {
          label: "Revenda",
          value: proposal.data.resaleName ?? "-",
          grid: { md: 8, xl: 6 },
        },
        {
          label: "Operador",
          value: proposal.data.operatorName ?? "-",
          grid: { md: 8, xl: 6 },
        },
      ],
    },
  } satisfies DetailsFields;
};
