import { OExpanderContent } from "@maestro/react";
import styled from "styled-components";
import { AbsoluteExpanderContentProps } from "./custom-select.types";

export const OptionsContainer = styled.div<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: auto;
`;

export const AbsoluteExpanderContent = styled(
  OExpanderContent,
)<AbsoluteExpanderContentProps>`
  position: absolute;
  background-color: var(--theme-light);
  width: ${(props) => props.width};
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
  z-index: 1;
`;
