import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { batchFileTemplatesGrid } from "./batch-file-templates.grid";
import { useBatchTemplate } from "./hook";
import { Modal } from "./modal/modal.component";

export const BatchFileTemplatesPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => batchFileTemplatesGrid, []);

  const { selectedTemplate, setSelectedTemplate, template } =
    useBatchTemplate();

  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Templates" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
        actions={
          <RouterButton
            dataAction="templates:botao:adicionar_template"
            dataLabel="adicionar_template"
            role={
              roles.workflow.product.batchTemplatesAddConfigurableColumnPage
                .role
            }
            href={
              corporateRouter.routes.workflow.product.batchs.batchTemplates
                .addTemplate.path
            }
          >
            Adicionar
          </RouterButton>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid(setSelectedTemplate)}
        />
      </GridTemplate>
      <Modal selectedTemplate={selectedTemplate} template={template} />
    </>
  );
};
