import { OClickableBox } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { PageTitle } from "components/page-title";
import { getCustomer, useUnsafeCustomer } from "contexts/customer";
import { useLegacySalesforceUserHook } from "hooks/legacy-salesforce";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";

export const SelectCustomerPage = () => {
  const navigate = useNavigate();
  const { state } = useUnsafeCustomer();
  const { userData, loading, getUserDetails, hasError } =
    useLegacySalesforceUserHook();

  useEffect(() => {
    if (["SELECTED", "LOADING"].includes(state)) {
      const { routes } = corporateRouter;
      navigate(routes.cadastro.customer.details.path);
    }
  }, [navigate, state]);

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100 my-5">
      <div>
        <PageTitle
          title="Selecione uma empresa"
          description="Consulte as informações da empresa selecionando-a abaixo"
        />
        <div className="position-relative">
          <ContentTemplate
            loading={loading}
            hasError={state === "ERROR" || hasError}
            value={userData?.companies}
            errorComponent={
              <ErrorComponent
                messageTitle="Ocorreu um erro ao buscar as empresas"
                messageParagraph="Clique no botão para tentar novamente."
              >
                <TryAgainButton onClick={() => getUserDetails()} />
              </ErrorComponent>
            }
            emptyStateComponent={
              <EmptyState messageTitle="Não foram encontradas empresas" />
            }
            render={(_options) => (
              <div className="mt-4 d-flex flex-column gap-4 align-items-center">
                {_options.map((option) => (
                  <OClickableBox
                    id={option.identification}
                    dataAction="company_select:clickable_box:selecionou_empresa"
                    dataLabel="company_select:clickable_box:selecionou_empresa"
                    value={option.identification}
                    key={option.identification}
                    onClick={() => {
                      getCustomer(option.identification);
                    }}
                  >
                    {option.officialName} -{" "}
                    {masks.cpfCnpj(option.identification)}
                  </OClickableBox>
                ))}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
