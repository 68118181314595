import { OOption, ORFieldSelect } from "@maestro/react";

interface OnboardingStatusSelectProps {
  dataAction: string;
  dataLabel: string;
  id: string;
  name: string;
  label: string;
  placeholder: string;
}

export const OnboardingStatusSelect = ({
  dataAction,
  dataLabel,
  id,
  name,
  label,
  placeholder,
}: OnboardingStatusSelectProps) => (
  <ORFieldSelect
    dataAction={dataAction}
    dataLabel={dataLabel}
    id={id}
    name={name}
    label={label}
    placeholder={placeholder}
  >
    <OOption value="AUTORIZADO">Autorizado</OOption>
    <OOption value="PENDENTE_CADASTRO">Pendente Cadastro</OOption>
    <OOption value="PENDENTE_PRECADASTRO">Pendente Pré-Cadastro</OOption>
    <OOption value="PENDENTE_ONBOARDING">Pendente Onboarding</OOption>
    <OOption value="PENDENTE_REQUISICAO">Pendente Requisição</OOption>
    <OOption value="PENDENTE_DOCUMENTACAO">Pendente documentação</OOption>
    <OOption value="REPROVADO">Reprovado</OOption>
    <OOption value="CONCLUIDO">Concluido</OOption>
    <OOption value="PENDENTE_ANALISE">Pendente análise</OOption>
    <OOption value="PENDENTE_VALIDACAO_MANUAL">
      Pendente validação manual
    </OOption>
    <OOption value="PENDENTE_VALIDACAO">Pendente Validação</OOption>
    <OOption value="PENDENTE_BIOMETRIA">Pendente biometria</OOption>
    <OOption value="PENDENTE_APROVACAO_MANUAL">
      Pendente aprovação manual
    </OOption>
    <OOption value="PAUSADO">Pausado</OOption>
  </ORFieldSelect>
);
