import styled from "styled-components";

export const TooltipContent = styled.div.attrs({
  slot: "tooltip-content",
})`
  line-height: 1.2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0;
`;
