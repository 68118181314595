import {
  modalManager,
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OOptionMultiple,
  ORFieldSelectMultiple,
  OTypography,
} from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { GetApiAdminConfigurableGridColumnResponse } from "services/hubcreditmanager/models/responses";
import { StyledOModalBody } from "../../../configuracoes-de-workflows/[id]/editar/edit-workflow-config.styles";
import { EditGridFormFields } from "./edit-grid.schema";

interface AddConfigurableGridColumnModalProps {
  form: UseFormReturn<EditGridFormFields>;
  gridColumnOptions?: GetApiAdminConfigurableGridColumnResponse;
}

export const ADD_CONFIGURABLE_GRID_COLUMN_MODAL =
  "add-configurable-grid-column-modal";

export const AddConfigurableGridColumnModal = ({
  form,
  gridColumnOptions,
}: AddConfigurableGridColumnModalProps) => {
  const { hide } = modalManager;

  return (
    <FormProvider {...form}>
      <form>
        <OModal id={ADD_CONFIGURABLE_GRID_COLUMN_MODAL} position="center">
          <OModalHeader>Adicionar coluna configurável</OModalHeader>
          <StyledOModalBody>
            <div key={`grid-column-options-${gridColumnOptions?.length}`}>
              <ORFieldSelectMultiple
                id="configurableGridColumns"
                name="configurableGridColumns"
                label="Colunas de monitoramento"
                labelSize="md"
              >
                {gridColumnOptions?.map((x) => (
                  <OOptionMultiple
                    value={String(x.id)}
                    key={`grid-column-option-${x.id}`}
                  >
                    <OTypography>{`${x.caption} (${x.dataField}) - ${x.dataType}: ${x.cellRenderType}`}</OTypography>
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </div>
          </StyledOModalBody>

          <OModalFooter>
            <div className="d-flex justify-content-end">
              <OButton
                onClick={() => hide(ADD_CONFIGURABLE_GRID_COLUMN_MODAL)}
                type="dark"
                outline
              >
                Fechar
              </OButton>
            </div>
          </OModalFooter>
        </OModal>
      </form>
    </FormProvider>
  );
};
