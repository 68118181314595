import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuoteList } from "../../quote-list.context";
import {
  searchModalDefaulValues,
  searchModalValidationSchema,
} from "./search-modal.form";
import { CompanySearchForm } from "./search-modal.types";

export const useSearchModal = () => {
  const { setFilterPayload } = useQuoteList();

  const form = useForm<CompanySearchForm>({
    defaultValues: searchModalDefaulValues,
    resolver: yupResolver(searchModalValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(() => reset(searchModalDefaulValues), [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((values)=> {
        setFilterPayload({
          cedentes: values.cedentes,
          bandeiras: values.bandeiras,
          dataVencimentoInicial: values.dataVencimentoInicial?.toDate(),
          dataVencimentoFinal: values.dataVencimentoFinal?.toDate(),
          sacados: values.sacados,
        });
      }),
    [handleSubmit, setFilterPayload],
  );

  return { form, submit, clear };
};
