import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const CalendarsActions: React.FC = () => {
  const { routes } = corporateRouter;
  return (
    <RouterButton
      dataAction="calendarios:botao:adicionar_calendario"
      dataLabel="adicionar_calendario"
      role={roles.recebiveis.product.calendarsAddPage.role}
      href={routes.recebiveis.product.calendars.add.path}
    >
      Adicionar
    </RouterButton>
  );
};
