import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetMarketplacesResponse } from "services/quickfin/models";

export const useMarketplaceIdField = () => {
  const [marketplaces, setMarketplaces] = useState<GetMarketplacesResponse>();
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { clearErrors, setError } = useFormContext();

  const getMarketplaces = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await service.quickfin.getMarketplaces();

      data.unshift({
        configuration: {
          defaultProduct: null,
          hasBalanceWebhook: false,
          hasBankSlipWebhook: true,
          hasReceivableUpdateWebhook: true,
          hasTradeUpdateWebhook: false,
          visiblePortal: false,
        },
        id: null,
        identification: "",
        name: "Quickfin",
        subgroup: null,
        tagCode: null,
        type: "",
      });

      setMarketplaces(data);

      clearErrors("marketplaceId");
    } catch (err) {
      setHasError(!!err);
      setError("marketplaceId", {
        message:
          "Erro ao buscar os marketplaces. Clique no botão ao lado para tentar novamente.",
      });
    } finally {
      setLoading(false);
    }
  }, [clearErrors, setError]);

  useEffect(() => {
    getMarketplaces();
  }, [getMarketplaces]);

  return {
    marketplaces,
    getMarketplaces,
    loading,
    hasFetchError: hasError,
  };
};
