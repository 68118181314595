import { ContractsGrid } from "pages/cadastro/components";
import { UseBankslipContracts } from "./bankslip-contracts.hook";
import { PageTitle } from "components/page-title";

interface BankslipContractsProps {
  documentNumber: string;
}
export const BankslipContracts = ({
  documentNumber,
}: BankslipContractsProps) => {
  const { gridFilter } = UseBankslipContracts(documentNumber);
  return (
    <ContractsGrid
      filter={gridFilter}
      isFullGrid={false}
      pageTitle={<PageTitle title="Contratos" />}
    />
  );
};
