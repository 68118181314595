import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";

export const useOriginatorInfoFields = (isEditing: boolean) => {
  const [foundEntityData, setFoundEntityData] = useState(false);
  const form = useFormContext();
  const { setValue, watch } = form;
  const watchIdentification = watch("identification");
  const watchIdentificationType = watch("identificationType");

  const fillByCpfCnpj = useCallback(async () => {
    if (isEditing) {
      return;
    }
    if (watchIdentification.replace(/\D/g, "").length === 14) {
      const body = {
        taxId: watchIdentification,
      };
      try {
        const { data } = await service.onboarding.searchLegalEntity(body);
        setValue("officialName", data.officialName);
        setValue("tradingName", data.name);
        setFoundEntityData(true);
      } catch {
        OToastManager.danger("Falha ao obter dados da empresa PJ");
        setFoundEntityData(false);
      }
    }
  }, [isEditing, setValue, watchIdentification]);

  useEffect(() => {
    if (watchIdentificationType && !isEditing) {
      setValue("identification", "");
      setValue("officialName", "");
      setValue("tradingName", "");
    }
  }, [isEditing, setValue, watchIdentificationType]);

  useEffect(() => {
    fillByCpfCnpj();
  }, [fillByCpfCnpj]);

  return {
    foundEntityData,
  };
};
