import * as yup from "yup";

export const workflowConfigViewFormValidationSchema = yup.object().shape({
  viewId: yup.number().required("Este campo é obrigatório"),
});

export const workflowConfigViewFormDefaultValues = {
  viewId: 0,
};

export interface WorkflowConfigViewFormValues {
  viewId: number;
}
