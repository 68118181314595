import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { useEffect } from "react";
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { OButton } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { DraggableScoreModules } from "./_compose/draggable-score-modules.component";

export const ScoreModulePage = () => {
  const {
    callService: getScoreModules,
    value: modules,
    hasError,
    loading,
  } = useServiceCall(service.hubCreditManager.getScoreModules);

  const navigate = useNavigate();

  useEffect(() => {
    getScoreModules();
  }, [getScoreModules]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Módulos" />}
      actions={
        <OButton
          onClick={() => {
            navigate(
              corporateRouter.routes.workflow.product.originatorScore.modules
                .add.path,
            );
          }}
        >
          Adicionar
        </OButton>
      }
    >
      <ContentTemplate
        loading={loading && !modules?.length}
        hasError={hasError}
        value={modules?.length}
        errorComponent={
          <ErrorComponent
            messageTitle="Um erro ocorreu ao buscar os módulos."
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={getScoreModules} />
          </ErrorComponent>
        }
        emptyStateComponent={
          <EmptyState messageTitle="Nenhum módulo foi adicionado no momento." />
        }
        render={() => (
          <DraggableScoreModules
            getScoreModules={getScoreModules}
            modules={modules}
          />
        )}
      />
    </DetailsTemplate>
  );
};
