import { BehaviorRoute, SecureRoute } from "components/access-control";
import { ErrorBoundary } from "components/error-boundary";
import { Behaviors } from "contexts/behavior/types";
import { BaseLayout } from "layout/base/base-layout.component";
import { Content } from "layout/content";
import { ForbiddenPage } from "pages/403/forbidden.page";
import { NotFoundPage } from "pages/404/not-found.page";
import { RedirectToNotFoundPage } from "pages/404/redirect-to-not-found-page.component";
import { accRoutes } from "pages/acc/routes/acc.routes";
import { antecipacaoAFornecedorRoutes } from "pages/antecipacao-a-fornecedor/routes/antecipacao-a-fornecedor.routes";
import { antecipacaoDeCartoesRoutes } from "pages/antecipacao-de-cartoes/routes/antecipacao-de-cartoes.routes";
import { bankingRoutes } from "pages/banking/routes/banking.routes";
import { billingRoutes } from "pages/billing/routes/billing.routes";
import { cadastroRoutes } from "pages/cadastro/routes/cadastro.routes";
import { cambioRoutes } from "pages/cambio/routes/cambio.routes";
import { cartoesRoutes } from "pages/cartoes/routes/cartoes.routes";
import { advisoredCompaniesRoutes } from "pages/clientes-assessorados/routes/advisored-customers.routes";
import { cobrancasRoutes } from "pages/cobrancas/routes/cobrancas.routes";
import { developersRoutes } from "pages/developers/routes/developers.routes";
import { duplicatasRoutes } from "pages/duplicatas/routes/duplicatas.routes";
import { empresasRoutes } from "pages/empresas/routes/empresas.routes";
import { emprestimosRoutes } from "pages/emprestimos/routes/emprestimos.routes";
import { energiaRoutes } from "pages/energia/routes/energia.routes";
import { ferramentasRoutes } from "pages/ferramentas/routes/ferramentas.routes";
import { fidcRoutes } from "pages/fidc/routes/fidc.routes";
import { b2bRoutes } from "pages/finders/routes/b2b.routes";
import { fopaRoutes } from "pages/folha-de-pagamento";
import { franquiasRoutes } from "pages/franquias/routes/franquias.routes";
import { HomeRedirect } from "pages/home-redirect";
import { Impersonate } from "pages/impersonate/impersonate.page";
import { Home } from "pages/inicio";
import { LogoutPage } from "pages/logout";
import { parceirosRoutes } from "pages/parceiros/routes/parceiros.routes";
import { recebiveisRoutes } from "pages/recebiveis/routes/recebiveis.routes";
import { renegociacoesRoutes } from "pages/renegociacoes/routes/renegociacoes.routes";
import { SelectCustomerPage } from "pages/selecao-empresa/select-customer.page";
import { SingleSignOn, SingleSignOnCallback } from "pages/single-sign-on";
import { usuariosRoutes } from "pages/usuarios";
import { workflowRmAdminRoutes } from "pages/workflow-rmadmin/routes/workflow-rmadmin.routes";
import { workflowRoutes } from "pages/workflow/routes/workflow.routes";
import {
  Outlet,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { corporateRouter, makeRoutesProxy } from "./corporate-router.context";
import { Routes } from "./routes.types";

const createRouteObject = (routes: Routes): RouteObject[] =>
  Object.values(routes).map((route) => {
    const RouteElement = (
      <SecureRoute role={route.role}>
        <Content route={route} element={route.element} />
      </SecureRoute>
    );

    const ContextProvider = route.contextProvider;

    return {
      ...route,
      element: ContextProvider ? (
        <ContextProvider>{RouteElement}</ContextProvider>
      ) : (
        RouteElement
      ),
      children: route.children && createRouteObject(route.children),
    };
  });

export const routes = {
  acc: { name: "ACC", path: "/acc", children: accRoutes },
  antecipacaoAFornecedor: {
    name: "Antecipação a Fornecedor",
    path: "/antecipacao-a-fornecedor",
    children: antecipacaoAFornecedorRoutes,
  },
  antecipacaoDeCartoes: {
    name: "Antecipação de Cartões",
    path: "/antecipacao-de-cartoes",
    children: antecipacaoDeCartoesRoutes,
  },
  b2b: { name: "Parceiros", path: "/finders", children: b2bRoutes },
  advisoredCustomers: {
    name: "Clientes assessorados",
    path: "/clientes-assessorados",
    children: advisoredCompaniesRoutes,
  },
  banking: { name: "Banking", path: "/banking", children: bankingRoutes },
  billing: {
    name: "Billing",
    path: "/billing",
    children: billingRoutes,
  },
  cadastro: { name: "Cadastro", path: "/cadastro", children: cadastroRoutes },
  cambio: { name: "", path: "/cambio", children: cambioRoutes },
  cartoes: { name: "Cartões", path: "/cartoes", children: cartoesRoutes },
  emprestimos: { name: "", path: "/emprestimos", children: emprestimosRoutes },
  energia: {
    name: "Energia",
    path: "/energia",
    children: energiaRoutes,
  },
  ferramentas: { name: "", path: "/ferramentas", children: ferramentasRoutes },
  fidc: { name: "FIDC", path: "/fidc", children: fidcRoutes },
  fopa: {
    name: "Folha de Pagamento",
    path: "/folha-de-pagamento",
    children: fopaRoutes,
  },
  parceiros: {
    name: "Parceiros",
    path: "/parceiros",
    children: parceirosRoutes,
  },
  renegociacao: {
    name: "Renegociação/quitação",
    path: "/renegociacoes",
    children: renegociacoesRoutes,
  },
  usuarios: {
    name: "Usuários",
    path: "/usuarios",
    children: usuariosRoutes,
  },
  empresas: {
    name: "Empresas",
    path: "/empresas",
    children: empresasRoutes,
  },
  recebiveis: {
    name: "Recebíveis",
    path: "/recebiveis",
    children: recebiveisRoutes,
  },
  duplicatas: {
    name: "Duplicatas",
    path: "/duplicatas",
    children: duplicatasRoutes,
  },
  workflow: {
    name: "Workflow",
    path: "/workflow",
    children: workflowRoutes,
  },
  workflowRmAdmin: {
    name: "workflow-rmadmin",
    path: "/workflow-rmadmin",
    children: workflowRmAdminRoutes,
  },
  cobrancas: {
    name: "Cobranças",
    path: "/cobrancas",
    children: cobrancasRoutes,
  },
  developers: {
    name: "Developers",
    path: "/developers",
    children: developersRoutes,
  },
  franquias: {
    name: "Franquias",
    path: "/franquias",
    children: franquiasRoutes,
  },
} as const satisfies Routes;

corporateRouter.routes = makeRoutesProxy(routes);

const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <BaseLayout>
          <Outlet />
        </BaseLayout>
      </ErrorBoundary>
    ),
    children: [
      {
        path: "/",
        element: <HomeRedirect />,
      },
      {
        path: "/inicio",
        element: <Home />,
      },
      {
        path: "/logout",
        element: <LogoutPage />,
      },
      {
        path: "/acesso/sso",
        element: <SingleSignOn />,
      },
      {
        path: "/acesso/callback",
        element: <SingleSignOnCallback />,
      },
      {
        path: "/impersonar/:token",
        element: <Impersonate />,
      },
      {
        path: "*",
        element: <RedirectToNotFoundPage />,
      },
      {
        path: "/pagina-nao-encontrada",
        element: <NotFoundPage />,
      },
      {
        path: "/nao-autorizado",
        element: <ForbiddenPage />,
      },
      {
        path: "/selecao-empresa",
        element: (
          <BehaviorRoute allowedBehaviors={[Behaviors.CustomerService]}>
            <SelectCustomerPage />
          </BehaviorRoute>
        ),
      },
      ...createRouteObject(routes),
    ],
  },
]);

export const BaseRoutes = () => {
  return <RouterProvider router={router} />;
};
