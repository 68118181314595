import { OLoader } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { EmpresasClienteByIdentificationRouteParams } from "../../../../routes/empresas.route-params";
import { CompanyGroup, GroupForm } from "../_compose";
import { useGroupsDetails } from "./details.hook";

export const GroupsDetails = () => {
  const { identification } =
    useParams<EmpresasClienteByIdentificationRouteParams>();

  const { companyGroup, form, loading } = useGroupsDetails();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle title="Grupos de empresas" description="Detalhes do grupo" />
      }
      actions={
        <RouterButton
          href={corporateRouter.routes.empresas.product.groups.edit.path({
            identification: identification ?? "",
          })}
          role={roles.empresas.product.groupsEditPage.role}
        >
          Editar
        </RouterButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          {loading && <OLoader absolute />}

          <GroupForm editing={false} />

          <CompanyGroup dataSource={companyGroup} />
        </FormProvider>
      </CardTemplate>
    </DetailsTemplate>
  );
};
