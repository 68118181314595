import React from "react";
import { service } from "services";
import { ApiSelector } from "../../../../../../../components/api-selector";

type StepTypeSelectorProps = Omit<
  React.ComponentProps<typeof ApiSelector>,
  "optionMapper" | "serviceMethod"
>;

export const StepTypeSelector = (props: StepTypeSelectorProps) => {
  return (
    <ApiSelector
      {...props}
      optionMapper={(value) => {
        const options =
          value?.response?.map(({ name: typeName }) => ({
            content: typeName,
            value: typeName,
          })) ?? [];
        options.sort((a, b) => a.content.localeCompare(b.content));
        return options;
      }}
      serviceMethod={service.hubEnergy.getDiligenceStepType}
    />
  );
};
