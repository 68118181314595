import { IntervalAmountSectionFilter } from "../../../../side-filter-bar.types";

export type IntervalAmountProps = IntervalAmountSectionFilter;

export enum OInputCurrencyId {
  MIN = "min",
  MAX = "max",
}

export enum OInputCurrencyLabel {
  MIN = "Mínimo",
  MAX = "Máximo",
}
