import {
  OButton,
  OCol,
  OFieldError,
  OIcon,
  OOption,
  ORFieldSelect,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { CreateProfileForm } from "./create-profile-form.component";
import { CreateRoleForm } from "./create-role-form.component";
import { ProfileDescription } from "./profile-description.component";
import { ProfileRolesForm } from "./profile-roles-forn.component";
import {
  ProfilesFormValues,
  profileManagementTypeMap,
} from "./profiles-role-form.schemas";
import { RoleDescription } from "./role-description.component";
import { ProfileSubProfilesForm } from "./profile-sub-profiles-form.component";

interface ProfilesFormProps {
  requestId: number;
}

export const ProfilesForm = ({ requestId }: ProfilesFormProps) => {
  const form = useFormContext<ProfilesFormValues>();
  const {
    control,
    formState: {
      errors: { changes: changesErrors },
    },
    watch,
  } = form;

  const watchType = watch("type");

  const fieldArray = useFieldArray({
    control,
    name: "changes",
  });

  const { append, remove, fields } = fieldArray;

  const {
    value: availableProfiles,
    callService: getAvailableProfiles,
    loading: loadingProfiles,
    hasError: hasErrorProfiles,
  } = useServiceCall(service.gatekeeper.getAvailableProfilesForChange);

  useEffect(() => {
    [
      "ADD_ROLES",
      "REMOVE_ROLES",
      "ADD_SUB_PROFILES",
      "REMOVE_SUB_PROFILES",
    ].includes(watchType) &&
      getAvailableProfiles({
        requestId,
        changeType: watchType as
          | "ADD_ROLES"
          | "REMOVE_ROLES"
          | "ADD_SUB_PROFILES"
          | "REMOVE_SUB_PROFILES",
      });
  }, [getAvailableProfiles, requestId, watchType]);

  useEffect(() => {
    !fields.length &&
      watchType &&
      append({} as ProfilesFormValues["changes"][number]);
  }, [append, fields.length, watchType]);

  const renderForm = useCallback(
    (index: number) => {
      switch (watchType) {
        case "NEW_ROLE":
          return <CreateRoleForm index={index} />;
        case "NEW_PROFILE":
          return <CreateProfileForm index={index} />;
        case "ADD_ROLES":
        case "REMOVE_ROLES":
          return (
            <ContentTemplate
              loading={loadingProfiles}
              hasError={hasErrorProfiles}
              errorComponent={
                <ErrorComponent
                  messageTitle="Não foi possível buscar os perfis"
                  messageParagraph="Clique no botão para tentar novamente"
                >
                  <TryAgainButton
                    onClick={() => {
                      hasErrorProfiles &&
                        getAvailableProfiles({
                          requestId,
                          changeType: watchType,
                        });
                    }}
                  />
                </ErrorComponent>
              }
              noValue
              render={() => (
                <ProfileRolesForm
                  changeType={watchType}
                  requestId={requestId}
                  profiles={availableProfiles?.profiles ?? []}
                  index={index}
                />
              )}
            />
          );
        case "ADD_SUB_PROFILES":
        case "REMOVE_SUB_PROFILES":
          return (
            <ContentTemplate
              loading={loadingProfiles}
              hasError={hasErrorProfiles}
              errorComponent={
                <ErrorComponent
                  messageTitle="Não foi possível buscar os perfis"
                  messageParagraph="Clique no botão para tentar novamente"
                >
                  <TryAgainButton
                    onClick={() => {
                      hasErrorProfiles &&
                        getAvailableProfiles({
                          requestId,
                          changeType: watchType,
                        });
                    }}
                  />
                </ErrorComponent>
              }
              noValue
              render={() => (
                <ProfileSubProfilesForm
                  changeType={watchType}
                  requestId={requestId}
                  profiles={availableProfiles?.profiles ?? []}
                  index={index}
                />
              )}
            />
          );
        default:
          return null;
      }
    },
    [
      availableProfiles,
      getAvailableProfiles,
      hasErrorProfiles,
      loadingProfiles,
      requestId,
      watchType,
    ],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <OCol sm={6}>
        <ORFieldSelect
          id="type"
          name="type"
          dataAction="profiles_form:select:tipo_de_mudanca"
          dataLabel="tipo_de_mudanca"
          label="Tipo de mudança"
          disabled={fields.length > 1}
        >
          {Object.entries(profileManagementTypeMap).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>

      {watchType === "NEW_PROFILE" && <ProfileDescription />}
      {watchType === "NEW_ROLE" && <RoleDescription />}

      {!!changesErrors?.message && (
        <OFieldError visible>{changesErrors.message}</OFieldError>
      )}
      {fields.map((field, index) => (
        <div
          className="d-flex gap-4 flex-fill align-items-center"
          key={field.id}
        >
          {renderForm(index)}

          <OButton
            dataAction="role_form:botao:remover"
            dataLabel="remover"
            type="danger"
            disabled={!index}
            onClick={() => remove(index)}
          >
            <OIcon category="fa" icon="fa-trash" />
          </OButton>
        </div>
      ))}

      {!!fields.length && (
        <div className="d-flex justify-content-end">
          <OButton
            dataAction="profiles_form:botao:adicionar"
            dataLabel="adicionar"
            onClick={() => append({} as ProfilesFormValues["changes"][number])}
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      )}
    </div>
  );
};
