import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import { GetProposalsResponse } from "services/b2b/agro/models/responses/get-proposals.response";

export const agroProposalsGrid = {
  datagrid: {
    noDataText: "Nenhum solicitação encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      cellRender: ({ data }) =>
        !!data.id && (
          <DetailsLink
            href={`/finders/produto/monitoramento/${data.uuid}/resumo`}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
      sortOrder: "desc",
      customizeText: ({ value }) => `AGRO#${value}`,
    },
    {
      caption: "Nome",
      calculateFilterExpression: (filterValue) => [
        ["name", "contains", filterValue],
        "or",
        ["cpf", "contains", filterValue.replace(/\D/g, "")],
      ],
      cellRender: ({ data }) => (
        <>
          <OTypography className="mb-1">{data.name}</OTypography>
          <OTypography type="dark-40" size="sm">
            {masks.cpf(data.cpf)}
          </OTypography>
        </>
      ),
    },
    {
      caption: "Status",
      dataField: "friendlyStatusName",
      customizeText: ({ value }) => value || "-",
    },
    {
      caption: "Limite",
      dataField: "productLimit",
      customizeText: ({ value }) =>
        value ? masks.currency(value, "R$", ".") : "-",
    },
    {
      caption: "Taxa",
      dataField: "rate",
      customizeText: ({ value }) => value || "-",
    },
    {
      caption: "Valor em contratação",
      dataField: "contractedValue",
      customizeText: ({ value }) =>
        value ? masks.currency(value, "R$", ".") : "-",
    },
    {
      caption: "Data de expiração",
      dataField: "limitDateExpire",
      customizeText: ({ value }) =>
        value ? dayjs(value).format("DD/MM/YYYY") : "-",
    },
    {
      caption: "Última atualização",
      dataField: "lastModifiedAt",
      customizeText: ({ value }) =>
        value ? dayjs(value).format("DD/MM/YYYY") : "-",
    },
    {
      caption: "Originador",
      dataField: "resaleName",
      calculateFilterExpression: (filterValue) => [
        ["resaleName", "contains", filterValue],
        "or",
        ["operatorName", "contains", filterValue],
      ],
      cellRender: ({ data }) => (
        <>
          <OTypography className="mb-1">
            {capitalize(data.resaleName)}
          </OTypography>
          {!!data.operatorName && (
            <OTypography type="dark-40" size="sm">
              {capitalize(data.operatorName)}
            </OTypography>
          )}
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<GetProposalsResponse[number]>;
