import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildGrid } from "./document-devolutive.grid";
import { useToolsDocumentDevolutive } from "./document-devolutive.hook";

export const ToolsDocumentDevolutive = () => {
  const { dataSource, loading, submit, downloadDocument } =
    useToolsDocumentDevolutive();

  const gridRef = useGridRef();

  const gridDefinition = useMemo(
    () => buildGrid(downloadDocument),
    [downloadDocument],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Devolutiva de documentos" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="formulario-ferramentas-devolutiva-documentos:botao:salvar"
          dataLabel="salvar"
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={gridDefinition}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
