import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { CardModality } from "services/bankinghub/models/types/cards";
import { logger } from "utils/logger";
import { useCardsCustomerContext } from "../../../../../../contexts";
import { CardHiringFeedbackModalId } from "./card-hiring-form/card-hiring-feedback-modal/card-hiring-feedback-modal.types";
import { cardHiringFormValidationSchema } from "./card-hiring-form/card-hiring-form.schema";
import { CardHiringForm } from "./card-hiring-form/card-hiring-form.types";
import {
  assemblePostCardsConfirmOfferBody,
  assemblePostCardsRequestCardBody,
} from "./fill-card-hiring.utils";

export const useFillCardHiring = () => {
  const { account, hasAccountRegistered, offer } = useCardsCustomerContext();

  const { callService: postRequestCard, loading: postRequestCardLoading } =
    useServiceCall(service.bankinghub.postRequestCard);

  const { callService: postConfirmOffer, loading: postConfirmOfferLoading } =
    useServiceCall(service.bankinghub.postConfirmOffer);

  const submitLoading = useMemo(
    () => postConfirmOfferLoading || postRequestCardLoading,
    [postRequestCardLoading, postConfirmOfferLoading],
  );

  const form = useForm<CardHiringForm>({
    resolver: yupResolver(cardHiringFormValidationSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      pinType: "random",
    },
  });

  const { handleSubmit, setValue, reset } = form;

  useEffect(() => {
    setValue("category", hasAccountRegistered ? "additional" : "initial");
    if (!account) return;
    setValue("accountId", account.id);
  }, [account, hasAccountRegistered, setValue]);

  const callService = useMemo(() => {
    return async (values: CardHiringForm) =>
      values.category === "initial"
        ? postConfirmOffer(assemblePostCardsConfirmOfferBody(values))
        : postRequestCard(assemblePostCardsRequestCardBody(values));
  }, [postConfirmOffer, postRequestCard]);

  const onFormSubmit = useCallback(
    async (values: CardHiringForm) => {
      try {
        const { success } = await callService(values);
        if (!success) throw new Error("Error from callService function");

        reset();

        const isAdditional = values.category === "additional";

        const isCredit = values.modality === CardModality.Credit;

        const toastMessage =
          isAdditional && isCredit
            ? "Contratação realizada com sucesso"
            : "Pedido do cartão pendente de aprovação";

        OToastManager.info(toastMessage);

        if (values.category === "initial") {
          modalManager.show(CardHiringFeedbackModalId);
        }
      } catch (error) {
        logger.warn(error);
        OToastManager.danger("Erro ao realizar a contratação");
      }
    },
    [callService, reset],
  );

  const onFormError = useCallback((errors?: Record<string, unknown>) => {
    if (!errors) return;
    logger.warn(errors);
  }, []);

  const submit = useMemo(
    () => handleSubmit(onFormSubmit, onFormError),
    [handleSubmit, onFormSubmit, onFormError],
  );

  const blockRenderCardHiringFormFieldsByOffer = useMemo((): boolean => {
    if (!offer) return false;
    return !!offer.isOnDebitHiringProcess || !!offer.isOnHiringProcess;
  }, [offer]);

  return {
    form,
    submit,
    submitLoading,
    blockRenderCardHiringFormFieldsByOffer,
  };
};
