import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { profileManagementRequestsStatusMap } from "./profiles-management.utils";

export const profilesManagementGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.gatekeeper.getProfileManagementRequests>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma solicitação encontrada",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      cellRender: ({ data }) =>
        data.status !== "CANCELADO" && (
          <DetailsLink
            href={corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement.details.path(
              {
                requestId: data.id,
              },
            )}
          />
        ),
    },
    { dataField: "id" },
    { dataField: "title", caption: "Titulo" },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) =>
        getValueFromMap(profileManagementRequestsStatusMap, data.status) ??
        data.status,
    },
    {
      dataField: "updatedAt",
      caption: "Última atualização",
      sortOrder: "desc",
      dataType: "datetime",
      customizeText: ({ value }) =>
        value
          ? dayjs(value).subtract(3, "hours").format("DD/MM/YYYY, HH:mm:ss")
          : "-",
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, component }) =>
        data.status !== "CANCELADO" && (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-cancel" },
                label: "Cancelar",
                onClick: async () => {
                  try {
                    await service.gatekeeper.cancelProfileManagementRequest({
                      requestId: data.id,
                    });

                    OToastManager.success("Solicitação cancelada com sucesso");

                    component.refresh();
                  } catch {
                    OToastManager.danger("Erro ao cancelar solicitação");
                  }
                },
              },
              {
                icon: { category: "orq", icon: "orq-email" },
                label: "Enviar",
                visible:
                  data.status === "EM_CONSTRUCAO" ||
                  data.status === "PENDENTE_ALTERACAO",
                onClick: async () => {
                  try {
                    await service.gatekeeper.sendProfileManagementRequest({
                      requestId: data.id,
                    });

                    OToastManager.success("Solicitação enviada com sucesso");

                    component.refresh();
                  } catch (err) {
                    if (isAxiosError<{ message: string }>(err)) {
                      return OToastManager.danger(
                        err.response?.data?.message ??
                          "Erro ao enviar solicitação",
                      );
                    }
                    OToastManager.danger("Erro ao enviar solicitação");
                  }
                },
              },
            ]}
          />
        ),
    },
  ],
};
