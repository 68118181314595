import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { Role } from "services/gatekeeper/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./roles.grid";

const dataSource = dataSourceCustomStoreGenerator<Role>(() =>
  service.gatekeeper
    .getRoles()
    .then(({ data }) => data)
    .catch((err) => {
      if (!isAxiosError(err)) logger.error(err);
      const errorMessage = "Erro ao buscar roles";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const GatekeeperRoles = () => {
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Roles Gatekeeper"
          description="Veja todas as roles cadastradas no Gatekeeper e suas informações principais"
        />
      }
      actions={
        <RouterButton
          dataAction="ferramentas_gatekeeper_role:botao:adicionar"
          dataLabel="adicionar"
          href={routes.ferramentas.product.gatekeeper.roles.add.path}
          role={roles.ferramentas.product.gatekeeperRolesAddPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
