import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import type DataGrid from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { AditamentoODataValueItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./trades-correction.grid";

const dataSource = dataSourceODataStoreGenerator<AditamentoODataValueItem>(
  endpoints.quickfin.odata.aditamentoOData,
);

export const TitleTradesCorrection = () => {
  const gridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Trades corrigidos" />}
      actions={
        <StandardLink
          role={
            roles.recebiveis.product.titlesTradesCorrectionImportCorrectionsPage
              .role
          }
          href={
            corporateRouter.routes.recebiveis.product.titles.tradesCorrection
              .importCorrections.path
          }
        >
          Importar correções
        </StandardLink>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
