import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { formatBankslipDate } from "./view-assets-details.utils";

export const ViewAssetsDetailsFields = (
  bankslip: BankingHub.Bankslip | undefined,
  installment: string,
  nextBusDay: string,
): DetailsFields => ({
  details: {
    items: [
      {
        label: "Nome do cliente",
        value: bankslip?.payer?.name,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Parcela",
        value: installment,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Valor atual do boleto",
        value: masks.currency(bankslip?.nominalAmount, currencySymbol.BRL, "."),
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Data de vencimento",
        value: formatBankslipDate(bankslip?.maturityDate ?? ""),
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Taxa de juros",
        value: `${(bankslip?.interest?.percentOrAmount ?? 0) * 100}%`,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Valor de multa",
        value: `${(bankslip?.fine?.percentOrAmount ?? 0) * 100}%`,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Data de vencimento máxima",
        value: nextBusDay,
        grid: { sm: 12, md: 6, lg: 4 },
      },
    ],
  },
});
