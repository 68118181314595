import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { OnboardingDomainConfig } from "services/onboarding/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./domain-config.grid";

const dataSource = dataSourceCustomStoreGenerator<OnboardingDomainConfig>(() =>
  service.onboarding
    .getAllDomainConfig()
    .then(({ data }) => data)
    .catch((err) => {
      if (!isAxiosError(err)) logger.error(err);
      const errorMessage = "Erro ao buscar domínios";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const OnboardingDomainConfigs = () => {
  const { routes } = corporateRouter;
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Listagem de domínios do hubonboarding" />}
      actions={
        <RouterButton
          dataAction="ferramentas_onboarding_domain-config:botao:adicionar"
          dataLabel="adicionar"
          href={routes.ferramentas.product.onboarding.domainConfig.add.path}
          role={roles.ferramentas.product.onboardingDomainConfigAddPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
