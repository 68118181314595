import { PageTitle } from "components/page-title";
import {
  StepsBuilder,
  StepsBuilderProvider,
  useStepsBuilder,
} from "components/steps-builder";
import { DetailsTemplate } from "templates/details-template";
import { IntubateOperationsProvider } from "./intubate-operations.context";

interface IntubateOperationsComponentProps {
  steps: {
    name: string;
    component: () => JSX.Element;
  }[];
}

const IntubateOperationsComponent = ({
  steps,
}: IntubateOperationsComponentProps) => {
  const { stepNumber } = useStepsBuilder();

  const CurrentStep = steps[stepNumber].component;

  const stepNames = steps.map(({ name }) => name);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Entuba de operações"
          description="Envie a lista de operações via Excel ou selecione diretamente os recebíveis de cartão sobre os quais as operações serão criadas. Uma operação é composta por cedente, sacado, data de vencimento, bandeira e valor solicitado no vencimento (opcional). A estratégia comercial utilizada será a existente do relacionamento entre cedente e sacado. Operações só podem ser criadas sobre recebíveis de cartão que estão disponíveis para cedente."
        />
      }
    >
      <StepsBuilder steps={stepNames}>
        <CurrentStep />
      </StepsBuilder>
    </DetailsTemplate>
  );
};

export const IntubateOperations = (props: IntubateOperationsComponentProps) => (
  <StepsBuilderProvider>
    <IntubateOperationsProvider>
      <IntubateOperationsComponent {...props} />
    </IntubateOperationsProvider>
  </StepsBuilderProvider>
);
