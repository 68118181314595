import { OBadge, OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { currencySymbol } from "utils/currency";
import { EdmLiteral } from "devextreme/data/odata/utils";

type WorkflowLeads = Awaited<
  ReturnType<typeof service.hubCreditManager.odata.getWorkflowLeads>
>["data"]["value"][number];

export const workflowLeadsGrid = (selectedWorkflowConfig?: string) =>
  ({
    datagrid: {
      noDataText: "Nenhum lead do workflow encontrado",
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.workflowLeadsAdmin,
      ),
      headerFilter: { visible: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.workflow.product.leadsDetailsSummaryPage.role,
        cellRender: ({ data }) =>
          data.Id ? (
            <DetailsLink
              href={corporateRouter.routes.workflow.product.leads.details.summary.path(
                {
                  id: data.Id,
                },
              )}
            />
          ) : undefined,
      },
      {
        dataField: "Id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "LeadCreatedDate",
        caption: "Data de criação",
        dataType: "datetime",
        sortOrder: "desc",
      },
      {
        dataField: "LastStatusChangeDate",
        caption: "Atualizado em",
        dataType: "datetime",
      },
      {
        dataField: "WorkflowConfigName",
        caption: "Workflow",
        dataType: "string",
      },
      {
        dataField: "CustomerName",
        caption: "Cliente",
        dataType: "string",
      },
      {
        dataField: "CustomerTaxId",
        caption: "Identificação",
        dataType: "string",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "DesiredAmount",
        caption: "Valor Solicitado",
        dataType: "number",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "ApprovedAmount",
        caption: "Valor Aprovado",
        dataType: "number",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "BigNumbersList",
        caption: "Etapa",
        calculateCellValue: (data) => data.BigNumbersList?.join(", ") ?? "",
        allowSorting: false,
        filterOperations: ["contains", "notcontains"],
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          if (!selectedFilterOperation) return [];

          let expression = "";

          if (selectedFilterOperation === "contains") {
            expression = `BigNumbersList/any(bigNumber:contains(tolower(bigNumber), '${filterValue.toLowerCase()}'))`;
          } else if (selectedFilterOperation === "notcontains") {
            expression = `BigNumbersList/all(bigNumber:not(contains(tolower(bigNumber), '${filterValue.toLowerCase()}')))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        cellRender: ({ data }) => data?.BigNumbersList?.[0] ?? "-",
      },
      {
        dataField: "StatusConfigs",
        caption: "Status",
        calculateCellValue: (data) =>
          data.StatusConfigs?.map((status) => status.Name).join(", ") ?? "",
        allowSorting: false,
        filterOperations: ["contains", "notcontains"],
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          if (!selectedFilterOperation) return [];

          let expression = "";

          if (selectedFilterOperation === "contains") {
            expression = `StatusConfigs/any(statusConfig:contains(tolower(statusConfig/name), '${filterValue.toLowerCase()}'))`;
          } else if (selectedFilterOperation === "notcontains") {
            expression = `StatusConfigs/all(statusConfig:not(contains(tolower(statusConfig/name), '${filterValue.toLowerCase()}')))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        cellRender: ({ data }) => {
          return (
            <div className="d-flex flex-column gap-2">
              {!data?.StatusConfigs.length && "-"}
              {data?.StatusConfigs.map((status) => (
                <OBadge key={status.Name} size="sm" type={status.Color}>
                  <OIcon category="orq" icon={status.IconName} />
                  {status.Name}
                </OBadge>
              ))}
            </div>
          );
        },
      },
      {
        dataField: "Deleted",
        caption: "Removido?",
        dataType: "boolean",
      },
      {
        dataField: "Channel",
        caption: "Canal",
        dataType: "string",
      },
      {
        dataField: "CreditLinesList",
        caption: "Produtos",
        calculateCellValue: (data) => data.CreditLinesList?.join(", ") ?? "",
        allowSorting: false,
        filterOperations: ["contains", "notcontains"],
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          if (!selectedFilterOperation) return [];

          let expression = "";

          if (selectedFilterOperation === "contains") {
            expression = `CreditLinesList/any(creditLine:contains(tolower(creditLine), '${filterValue.toLowerCase()}'))`;
          } else if (selectedFilterOperation === "notcontains") {
            expression = `CreditLinesList/any(creditLine:not(contains(tolower(creditLine), '${filterValue.toLowerCase()}')))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        cellRender: ({ data }) => data?.CreditLinesList?.[0],
      },
      {
        dataField: "Source",
        caption: "Origem",
        dataType: "string",
      },
      {
        dataField: "Originator",
        caption: "Originador",
        dataType: "string",
      },
      {
        dataField: "OriginatorTaxId",
        caption: "Originador Identificação",
        dataType: "string",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "Operator",
        caption: "Operador",
        dataType: "string",
      },
      {
        dataField: "CorrelationId",
        dataType: "string",
      },
      {
        dataField: "BatchRequestId",
        dataType: "number",
      },
      {
        dataField: "WorkflowConfigIdentification",
        caption: "Identificador de Configuração do Workflow",
        filterValue: selectedWorkflowConfig,
        dataType: "string",
      },
    ],
  } satisfies ODataGridGeneratorConfig<WorkflowLeads>);
