import { client } from "../client";
import { fopaEndpoints } from "./fopa.endpoints";
import {
  GetCheckCompanyExistsResponse,
  GetMassifyEmployeesListResponse,
  GetMassifyFiltersListResponse,
  GetMassifyListResponse,
  GetPayrollLinesResponse,
  GetPayrollListResponse,
  GetMassifyListParams,
  GetPayrollLinesParams,
  GetPayrollListParams,
} from "./models";

export const fopaService = {
  getCheckCompanyExist() {
    return client.get<GetCheckCompanyExistsResponse>(
      fopaEndpoints.checkCompanyExist,
    );
  },
  getPayrollList(params: GetPayrollListParams) {
    return client.get<GetPayrollListResponse>(fopaEndpoints.payroll.list, {
      params,
      paramsSerializer: {
        indexes: null,
      },
    });
  },
  getPayrollLines(params: GetPayrollLinesParams) {
    return client.get<GetPayrollLinesResponse>(fopaEndpoints.payroll.lines, {
      params,
    });
  },
  getMassifyList(params: GetMassifyListParams) {
    return client.get<GetMassifyListResponse>(fopaEndpoints.massify.list, {
      params,
    });
  },
  getMassifyFiltersList() {
    return client.get<GetMassifyFiltersListResponse>(
      fopaEndpoints.massify.filters,
    );
  },
  getMassifyEmployessList(params: GetMassifyListParams) {
    return client.get<GetMassifyEmployeesListResponse>(
      fopaEndpoints.massify.employees,
      { params },
    );
  },
};
