import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetBillingCustomersResponse } from "services/api/models/responses";
import { service } from "services/service";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { buildFilter } from "./billing-customers.utils";

export const dataSource = dataSourceCustomStoreGenerator<
  GetBillingCustomersResponse["list"][number]
>((loadOptions) => {
  const filter = buildFilter(loadOptions.filter);

  const body = {
    skip: loadOptions.skip ?? 0,
    take: loadOptions.take ?? 10,
    ...filter,
  };
  return service.api
    .getBillingCompanies(body)
    .then(({ data }) => ({
      data: data.list || [],
      totalCount: data.total || 0,
    }))
    .catch(() => {
      const errorMessage = "Erro ao buscar os clientes";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    });
});

export const billingCustomersGrid: ODataGridGeneratorConfig<
  GetBillingCustomersResponse["list"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma empresa encontrada.",
    filterRow: {
      visible: true,
    },
    pager: {
      infoText: "Página {0} de {1} ({2} clientes)",
    },
    remoteOperations: true,
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.billing.product.customersRateCustomizationPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.billing.product.customers.rateCustomization.path(
            {
              taxId: data.customerId,
            },
          )}
        />
      ),
    },
    {
      dataField: "name",
      caption: "Razão social/Nome",
    },
    {
      dataField: "customerId",
      caption: "CNPJ",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      customizeText: ({ value }) => masks.cpfCnpj(value),
    },
    {
      dataField: "createdAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
      allowFiltering: false,
    },
    {
      dataField: "lastModified",
      caption: "Atualizado em",
      dataType: "datetime",
      format: "shortDateShortTime",
      allowFiltering: false,
    },
  ],
};
