import React from "react";
import { service } from "services";
import { ApiSelector } from "../../../../../../../components/api-selector";

interface PendencyTypeSelectorProps
  extends Omit<
    React.ComponentProps<
      typeof ApiSelector<
        HubEnergy.ApiBaseResponse<
          HubEnergy.DiligenceStepPendencyTypeResponse[]
        >,
        number | string
      >
    >,
    "optionMapper" | "serviceMethod"
  > {
  valueType: "id" | "name";
}

export const PendencyTypeSelector = ({
  valueType,
  ...rest
}: PendencyTypeSelectorProps) => {
  return (
    <ApiSelector
      {...rest}
      optionMapper={(value) => {
        const options =
          value?.response?.map(({ name: typeName, label, id }) => ({
            content: `${label} [${typeName}]`,
            value: valueType === "id" ? id : typeName,
          })) ?? [];
        options.sort((a, b) => a.content.localeCompare(b.content));
        return options;
      }}
      serviceMethod={service.hubEnergy.getDiligenceStepPendencyTypes}
    />
  );
};
