import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { SignatureGroup } from "../../../_compose";
import { RemoveSignatureGroupButton } from "./_compose";
import { useSignatureGroupDetails } from "./signature-group-details.hook";

export const SignatureGroupDetails = () => {
  const { getSignatureRule, groupOfSignatures, hasError, loading, ruleId } =
    useSignatureGroupDetails();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={`Grupo de assinaturas${
            groupOfSignatures ? `: ${groupOfSignatures.name}` : ""
          }`}
          key={groupOfSignatures?.name}
        />
      }
      actions={
        groupOfSignatures && (
          <>
            <StandardLink
              href={corporateRouter.routes.recebiveis.customer.signatures.byRuleId.byGroupId.edit.path(
                { groupId: groupOfSignatures.id, ruleId },
              )}
              role={
                roles.recebiveis.customer.signaturesByRuleIdByGroupIdEditPage
                  .role
              }
            >
              Editar
            </StandardLink>
            <RemoveSignatureGroupButton
              groupId={groupOfSignatures.id}
              ruleId={ruleId}
            />
          </>
        )
      }
    >
      <div className="position-relative">
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          value={groupOfSignatures}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível encontrar a regra de assinaturas"
              callback={getSignatureRule}
            />
          }
          emptyStateComponent={
            <EmptyState messageTitle="Esse grupo de assinaturas não existe" />
          }
          render={(_groupOfSignatures) => (
            <SignatureGroup
              disabled
              ruleId={Number(ruleId)}
              groupOfSignatures={_groupOfSignatures}
            />
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
