export const batchRequestStatusMap = {
  PENDING: {
    type: "warning",
    label: "Pendente",
  },
  ERROR: {
    type: "danger",
    label: "Erro processamento",
  },
  IN_PROGRESS: {
    type: "info",
    label: "Em processamento",
  },
  PROCESSED: {
    type: "success",
    label: "Confirmado",
  },
  SCHEDULED: {
    type: "info",
    label: "Agendado",
  },
} as const;

export const PartnerRelationshipMap = {
  INTEGRATOR: {
    label: "Integrador",
  },
  DISTRIBUTOR: {
    label: "Distribuidor",
  },
} as const;
