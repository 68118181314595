import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { exchangeLimitDetailsGenerator } from "../../../../components/limits/limit.details";
import { exchangePreTicketDetailsGenerator } from "../../../../components/pre-tickets/pre-ticket.details";
import { CambioLimitesByIdRouteParams } from "../../../../routes/cambio.route-params";

export const ExchangeLimitDetailsPage = () => {
  const { hasRole } = useRoles();
  const { mapSymbol } = useCurrencies();
  const { id } = useParams<CambioLimitesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [limitDetails, setLimitDetails] =
    useState<
      Awaited<
        ReturnType<typeof service.hubFx.adminCustomer.getLimitDetails>
      >["data"]
    >();

  const getQuoteDetail = useCallback(async () => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      const { data } = await service.hubFx.adminCustomer.getLimitDetails(id);
      setLimitDetails(data);
    } catch (error) {
      setErrorMessage("Erro ao buscar detalhes do limite.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getQuoteDetail();
  }, [getQuoteDetail]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do Limite" />}>
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loading}
          errorComponent={
            <ErrorComponent
              messageTitle={errorMessage}
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton onClick={() => getQuoteDetail()} />
            </ErrorComponent>
          }
          hasError={!!errorMessage}
          value={limitDetails}
          fields={exchangeLimitDetailsGenerator}
        />
        {limitDetails?.preTicket && (
          <DetailsCard
            value={limitDetails.preTicket}
            fields={(preTicket) =>
              exchangePreTicketDetailsGenerator(
                preTicket,
                mapSymbol,
                preTicket?.id &&
                  hasRole(roles.cambio.customer.preTicketsDetailsPage.role) ? (
                  <StandardLink
                    href={corporateRouter.routes.cambio.customer.preTickets.details.path(
                      { id: preTicket.id },
                    )}
                    size="lg"
                    type="default"
                  >
                    Pré-Ticket
                  </StandardLink>
                ) : undefined,
              )
            }
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
