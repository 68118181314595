import { OOption, ORFieldInput, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { getValueFromMap } from "utils/get-value-from-map";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { processorPropertyTypeMap } from "../../property-field.utils";
import { useGenericPropertyField } from "./generic-property-field.hook";

export const GenericPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const {
    processorPropertyId,
    processorConfigId,
    name,
    allowedValues,
    description,
    type,
    isList,
  } = processorConfigProperty;

  useGenericPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const genericPropertyField = useMemo(
    () => (
      <>
        {!allowedValues?.length ? (
          <ORFieldInput
            key={`properties.${name}_${processorPropertyId}`}
            id={`properties.${name}_${processorPropertyId}`}
            name={`properties.${name}_${processorPropertyId}`}
            tag={getValueFromMap(processorPropertyTypeMap, type) ?? "text"}
            label={description}
            labelSize="md"
          />
        ) : (
          <ORFieldSelect
            key={allowedValues?.map((option) => option).join("")}
            id={`properties.${name}_${processorPropertyId}`}
            name={`properties.${name}_${processorPropertyId}`}
            label={description}
            labelSize="md"
            placeholder="Selecione"
            tooltip={isList ? "É uma lista (separar valores por vírgula)" : ""}
            tooltipMaxWidth="100px"
            tooltipPosition="right-top"
          >
            {allowedValues?.map((option) => (
              <OOption key={option} value={option}>
                {option}
              </OOption>
            ))}
            <OOption
              value=""
              key={`${name.toLowerCase()}-generic-property-null`}
            >
              NENHUM
            </OOption>
          </ORFieldSelect>
        )}
      </>
    ),
    [
      processorPropertyId,
      processorConfigId,
      name,
      allowedValues,
      description,
      type,
      isList,
    ],
  );

  return <>{genericPropertyField}</>;
};
