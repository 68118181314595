import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, occurrencesGrid } from "./occurrences-grid.grid";

export const OnboardingDetails = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();
  const dataSource = useMemo(
    () => buildDataSource(customer.identification),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ocorrências" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={occurrencesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
