import { OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useCustomer } from "contexts/customer";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FilesItem } from "services/onboarding/models";
import { DocumentsItemFormatted } from "./document-devolutive.types";
import { formatData } from "./document-devolutive.utils";

export const useToolsDocumentDevolutive = () => {
  const navigate = useNavigate();
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);
  const [companyDocuments, setCompanyDocuments] = useState<
    DocumentsItemFormatted[] | undefined
  >();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<DocumentsItemFormatted>(
        () => {
          if (companyDocuments) return Promise.resolve(companyDocuments);

          return service.onboarding
            .loadDocuments({
              taxId: customer.identification,
            })
            .then(({ data }) => {
              const formattedData = formatData(data) || [];
              setCompanyDocuments(formattedData);

              return formattedData;
            })
            .catch(() => {
              const errorMessage =
                "Ocorreu um erro para buscar documentos da empresa";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            });
        },
        {
          customStoreOptions: {
            update: async ({ uuid }, { justification }) => {
              if (!companyDocuments) return;

              const dataIndex = companyDocuments.findIndex(
                (company) => company.uuid !== uuid,
              );

              const findCompany = companyDocuments.find(
                (company) => company.uuid === uuid,
              );

              if (!findCompany) return;

              findCompany.justification = justification;
              companyDocuments.splice(dataIndex - 1, 1, findCompany);

              setCompanyDocuments(companyDocuments);
            },
          },
        },
      ),
    [companyDocuments, customer],
  );

  const downloadDocument = useCallback(async (item: FilesItem) => {
    try {
      if (!item.key) return;

      const { data } = await service.onboarding.downloadFile(item.key);

      helpers.downloadBlobFile(item.name, data);
    } catch (error) {
      OToastManager.danger("Erro ao realizar devolutivas.");
    }
  }, []);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      if (!companyDocuments) return;

      const occurrences = companyDocuments.map((document) => {
        return {
          identifier: document.taxId,
          errorMessage: document.justification || "",
          documentType: document.type,
          fileName: null,
        };
      });

      const payload = {
        taxId: customer.identification,
        relatonship: null,
        occurrences,
      };

      await service.onboarding.documentsOccurrences(payload);

      OToastManager.info("Devolutiva realizada com sucesso.");
      navigate(corporateRouter.routes.cadastro.customer.tools.path);
    } catch (error) {
      OToastManager.danger("Erro ao realizar devolutivas.");
    } finally {
      setLoading(false);
    }
  }, [companyDocuments, customer, navigate]);

  return {
    dataSource,
    loading,
    submit,
    downloadDocument,
  };
};
