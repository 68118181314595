import { modalManager, OButton, OIcon } from "@maestro/react";
import { configurationModalId } from "components/configuration-modal";

export const ConfigurationModalButton = () => {
  return (
    <OButton
      type="dark"
      outline
      bordered={false}
      dataAction="header:botao:configuracoes"
      dataLabel="configuracoes"
      title="Configuracoes"
      onClick={() => {
        modalManager.show(configurationModalId);
      }}
    >
      <OIcon category="orq" icon="orq-configuration" />
    </OButton>
  );
};
