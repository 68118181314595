import { masks } from "@maestro/utils";
import { AnimationEvent, TooltipItem } from "chart.js";

export const periodTabMap = [
  {
    id: "DAY",
    label: "um_dia",
    title: "1 D",
    format: "HH:mm",
  },
  {
    id: "WEEK",
    label: "uma_semana",
    title: "1 S",
    format: "DD/MM",
  },
  {
    id: "MONTH",
    label: "um_mes",
    title: "1 M",
    format: "DD/MM",
  },
  {
    id: "SEMESTER",
    label: "seis_meses",
    title: "6 M",
    format: "DD/MM",
  },
  {
    id: "YEAR",
    label: "um_ano",
    title: "1 A",
    format: "DD/MM",
  },
] as const;

export const options = {
  responsive: true,
  animation: {
    onComplete: (e: AnimationEvent) => {
      if (e.initial) {
        const { chart } = e;
        const meta = chart.legend?.legendItems;

        if (meta) {
          meta.forEach((item, index) => {
            if (item.text !== "USD") {
              const datasetMeta = chart.getDatasetMeta(index);

              const isCurrentlyHidden = datasetMeta.hidden === true;
              datasetMeta.hidden = !isCurrentlyHidden;

              chart.update();
            }
          });
        }
      }
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value: string | number) =>
          masks.currency(value, "R$", ".", ",", 3),
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: TooltipItem<"line">) =>
          `${context.dataset.label}: ${masks.currency(
            Number(context.raw),
            "R$",
          )}`,
      },
    },
  },
};
