import { OIcon, OLoader, OTypography } from "@maestro/react";
import styled from "styled-components";

export const AlignedIcon = styled(OIcon)`
  display: inline;
  margin-right: 0.5rem;
  vertical-align: middle;
`;

export const AlignedTypography = styled(OTypography)`
  span {
    display: flex;
    align-items: center;
  }
`;

export const AlignedLoader = styled(OLoader)`
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
`;
