import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldSelect,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanEquateFormValues,
  loanEquateValidationFormSchema,
} from "./loan-equate.schemas";

export const LoanEquatePage = () => {
  const {
    callService: agreementsCallService,
    hasError: agreementsHasError,
    value: agreementsValue,
    loading: agreementsLoading,
  } = useServiceCall(service.hubLoan.getAgreement);
  const {
    callService: partnersCallService,
    hasError: partnersHasError,
    loading: partnersLoading,
    value: partnersValue,
  } = useServiceCall(service.hubLoan.getPartner);

  const [loading, setLoading] = useState(false);

  const form = useForm<LoanEquateFormValues>({
    resolver: yupResolver(loanEquateValidationFormSchema),
  });

  const { handleSubmit } = form;

  const createProfile = useCallback(async (values: LoanEquateFormValues) => {
    try {
      setLoading(true);

      await service.hubLoan.postArrangement(values);

      OToastManager.success("Perfil criado com sucesso!");
    } catch {
      OToastManager.danger("Ocorreu algum erro. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    agreementsCallService();
    partnersCallService();
  }, [agreementsCallService, partnersCallService]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Cadastrar configuração de contratos de empréstimo" />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_cadastrar_perfis:botao:cadastrar"
          dataLabel="cadastrar"
          onClick={handleSubmit(createProfile)}
        >
          Cadastrar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol className="align-items-center">
            <ORFieldSelect
              id="agreementId"
              name="agreementId"
              dataAction="emprestimos_cadastrar_perfis:select:produto"
              dataLabel="produto"
              label="Convênio"
              key={agreementsValue?.length}
            >
              {agreementsValue?.map((agreement) => (
                <OOption key={agreement.id} value={agreement.id}>
                  {agreement.name}
                </OOption>
              ))}
            </ORFieldSelect>
            {agreementsLoading && <OLoader />}
            {agreementsHasError && (
              <OButton
                type="default"
                outline
                dataLabel="tentar_novamente_convenios"
                dataAction="emprestimos_cadastrar_perfis:botao:tentar_novamente_convenios"
                onClick={() => agreementsCallService()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol className="align-items-center">
            <ORFieldSelect
              id="partnerId"
              name="partnerId"
              dataAction="emprestimos_cadastrar_perfis:select:parceiro"
              dataLabel="parceiro"
              label="Parceiro"
              key={partnersValue?.length}
            >
              {partnersValue?.map((partner) => (
                <OOption key={partner.id} value={partner.id}>
                  {partner.name}
                </OOption>
              ))}
            </ORFieldSelect>
            {partnersLoading && <OLoader />}
            {partnersHasError && (
              <OButton
                type="default"
                outline
                dataLabel="tentar_novamente_parceiros"
                dataAction="emprestimos_cadastrar_perfis:botao:tentar_novamente_parceiros"
                onClick={() => partnersCallService()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
