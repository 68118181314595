import { cases } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { Quotes } from "./limit-conditions.types";
import {
  checkIfNullRule,
  greaterThanOrEqualRule,
  greaterThanOrEqualZeroRule,
  greaterThanZeroRule,
  numericRule,
  upToTwoDecimalsRule,
} from "./limit-conditions.utils";
import { LimitQuotesInstallmentMasterDetail } from "./limit-quotes-installment-options.master-detail";

export const limitConditionsGrid = (
  initialQuotes: Quotes,
  onChange: (quotes: Quotes) => void,
  hasAllSettlementInstruction: boolean,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum template de contrato.",
      dataSource: initialQuotes ?? [],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: true,
      },
      onRowInserted: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: Number((item.monthlyValue / 100).toFixed(4)),
              })),
          ),
        );
      },
      onRowUpdated: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: Number((item.monthlyValue / 100).toFixed(4)),
              })),
          ),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: Number((item.monthlyValue / 100).toFixed(4)),
              })),
          ),
        );
      },
      onEditorPreparing: (e) => {
        if (
          e.row?.data.amortizationFrequency === "BULLET" &&
          (e.dataField === "minTerm" || e.dataField === "maxTerm")
        ) {
          e.editorOptions.disabled = true;
          return;
        }

        e.editorOptions.disabled = false;
      },
      onRowValidating: (e) => {
        const { amortizationFrequency } = {
          ...e.oldData,
          ...e.newData,
        };

        const quotes = e.component.getVisibleRows().map((row) => row.data);

        if (
          quotes.length > 1 &&
          quotes.some((quote) => quote.amortizationFrequency === "BULLET")
        ) {
          e.errorText =
            'A opção de parcelamento com frequência de amortização do tipo "Bullet" deve ser única (as outras linhas de opção de parcelamento devem ser excluídas para poder prosseguir).';
          e.isValid = false;
          return;
        }

        const hasUniqueAmortizationFrequency = quotes.every(
          (quote) => quote.amortizationFrequency === amortizationFrequency,
        );
        if (!hasUniqueAmortizationFrequency) {
          e.errorText =
            "Todas as suas opções de parcelamento editadas devem ter frequências de amortização iguais entre si.";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "minTerm",
        caption: "De",
        dataType: "number",
        validationRules: hasAllSettlementInstruction
          ? []
          : [
              numericRule(),
              checkIfNullRule("minTerm"),
              greaterThanZeroRule("minTerm"),
            ],
      },
      {
        dataField: "maxTerm",
        caption: "Até",
        dataType: "number",
        validationRules: hasAllSettlementInstruction
          ? []
          : [
              numericRule(),
              checkIfNullRule("maxTerm"),
              greaterThanZeroRule("maxTerm"),
              greaterThanOrEqualRule(),
            ],
      },
      {
        dataField: "amortizationFrequency",
        caption: "Frequência de amortização",
        format: (value) => cases.title(value),
        lookup: {
          dataSource: [
            { id: "MENSAL", title: "Mensal" },
            { id: "ANUAL", title: "Anual" },
            { id: "BULLET", title: "Bullet" },
            { id: "BIMESTRAL", title: "Bimestral" },
            { id: "TRIMESTRAL", title: "Trimestral" },
            { id: "QUADRIMESTRAL", title: "Quadrimestral" },
            { id: "SEMESTRAL", title: "Semestral" },
          ],
          displayExpr: "title",
          valueExpr: "id",
        },
        setCellValue: (newData, value, currentRowData) => {
          const e = newData;

          e.minTerm = currentRowData.minTerm ?? null;
          e.maxTerm = currentRowData.maxTerm ?? null;
          e.amortizationFrequency = value;
        },
        validationRules: [
          { type: "required", message: "É necessário preencher este campo." },
        ],
      },
      {
        dataField: "monthlyValue",
        caption: "Taxa",
        dataType: "number",
        cellRender: ({ data }) => `${data.monthlyValue}%`,
        validationRules: [
          { type: "required", message: "É necessário preencher este campo." },
          { type: "numeric" },
          greaterThanOrEqualZeroRule("monthlyValue"),
          upToTwoDecimalsRule(),
        ],
      },
      {
        dataField: "gracePeriod",
        caption: "Carência",
        dataType: "number",
        validationRules: [
          { type: "required", message: "É necessário preencher este campo." },
          { type: "numeric" },
          greaterThanOrEqualZeroRule("gracePeriod"),
        ],
      },
    ],
    masterDetail: {
      enabled: true,
      component: LimitQuotesInstallmentMasterDetail,
    },
  } satisfies ODataGridGeneratorConfig<Quotes[number]>;
};
