import { getValueFromMap } from "utils/get-value-from-map";
import { logger } from "utils/logger";
import { buildSegment } from "./helpers";

const actionTypeActionTextMap = {
  CHANGE_PENDENCY_STATUS: (action) => {
    let text = "Mudar status da pendência";

    if (action.targetSamePendency) {
      text += " que disparou o evento";
    } else {
      text += " " + buildSegment("do_tipo", action.targetPendencyTypes);
      text += " " + buildSegment("com_status", action.targetPendencyStatuses);

      if (action.targetSameStep) {
        text += " na mesma step que disparou o evento";
      } else if (
        action.targetStepTypes?.length ||
        action.targetStepStatuses?.length
      ) {
        text += " na step";
        text += " " + buildSegment("do_tipo", action.targetStepTypes);
        text += " " + buildSegment("com_status", action.targetStepStatuses);
      }
    }
    text += " para";
    const payload = JSON.parse(
      action.payload,
    ) as HubEnergy.ChangePendencyStatusActionPayload;
    text += " " + payload.status;

    return text;
  },
  CHANGE_STEP_STATUS: (action) => {
    let text = "Mudar status da step";

    if (action.targetSameStep) {
      text += " que disparou o evento";
    } else {
      text += " " + buildSegment("do_tipo", action.targetStepTypes);
      text += " " + buildSegment("com_status", action.targetStepStatuses);
    }
    text += " para";
    const payload = JSON.parse(
      action.payload,
    ) as HubEnergy.ChangeStepStatusActionPayload;
    text += " " + payload.status;

    return text;
  },
  CREATE_PENDENCY: (action) => {
    let text = "Criar pendência";

    const payload = JSON.parse(
      action.payload,
    ) as HubEnergy.CreatePendencyActionPayload;
    if (payload.isPublic) {
      text += " para o cliente";
    }
    text += " do tipo " + payload.type;
    if (payload.hasBpoAction) {
      text += " com atuação de BPO";
    }
    if (payload.status !== "CRIADA") {
      text += " com status " + payload.status;
    }
    if (payload.message) {
      text += ' e mensagem "' + payload.message + '"';
    }

    if (action.targetSameStep) {
      text += " na mesma step que disparou o evento";
    } else if (
      action.targetStepTypes?.length ||
      action.targetStepStatuses?.length
    ) {
      text += " na step";
      text += " " + buildSegment("do_tipo", action.targetStepTypes);
      text += " " + buildSegment("com_status", action.targetStepStatuses);
    }

    return text;
  },
  CREATE_PROPERTY: (action) => {
    let text = "Criar propriedade";

    const payload = JSON.parse(
      action.payload,
    ) as HubEnergy.CreatePropertyActionPayload;
    if (payload.isPublic) {
      text += " para o cliente";
    }
    text += " do tipo " + payload.type;

    if (payload.documentType) {
      text += " e documento do tipo " + payload.documentType;
    }

    if (action.targetSameStep) {
      text += " na mesma step que disparou o evento";
    } else if (
      action.targetStepTypes?.length ||
      action.targetStepStatuses?.length
    ) {
      text += " na step";
      text += " " + buildSegment("do_tipo", action.targetStepTypes);
      text += " " + buildSegment("com_status", action.targetStepStatuses);
    }

    return text;
  },
} as const satisfies Record<
  HubEnergy.EDiligenceEventActionType,
  (action: HubEnergy.DiligenceEventActionSimpleResponse) => string
>;

export const actionTextBuilder = (
  action: HubEnergy.DiligenceEventActionSimpleResponse,
) => {
  let actionText = getValueFromMap(
    actionTypeActionTextMap,
    action.actionType,
  )?.(action);

  if (!actionText) {
    logger.error(
      `Unexpected DiligenceEventAction actionType: ${action.actionType}`,
    );
    return JSON.stringify(action);
  }

  actionText = actionText.trim().replace(/\s+/g, " ");

  if (!action.isEnabled) {
    actionText = "[DESABILITADO]" + actionText;
  }

  return actionText;
};
