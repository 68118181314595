import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductCommonDomainGrid } from "./exchange-product-common-domain.grid";

export const ExchangeProductCommonDomainPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Variáveis de Configuração" />}
      actions={
        <OButton
          dataAction="cambio_variaveis_configuracao:botao:adicionar"
          dataLabel="adicionar"
          onClick={() => {
            dataGridRef.current?.instance.addRow();
          }}
        >
          Adicionar
        </OButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={exchangeProductCommonDomainGrid}
      />
    </GridTemplate>
  );
};
