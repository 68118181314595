import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ReporteDiarioSacadoField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.reporteDiarioSacado}
      name="reporteDiarioSacado"
      description="E-mail de notificação ao sacado das próximas liquidações"
    />
  );
};
