import { OButton } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { useBatchUploadReceivables } from "../../batch-upload-receivables.context";
import { validationResultsGrid } from "./validation-results.grid";
import {
  addErrorsToReceivables,
  calculateSummary,
} from "./validation-results.utils";

export const ValidationResults = () => {
  const { receivables, validationResults, clear, submit, submitLoading } =
    useBatchUploadReceivables();

  const dataSource = useMemo(
    () => addErrorsToReceivables(receivables, validationResults),
    [receivables, validationResults],
  );

  const { maxDate, totalDisbursementAmount, totalErrors, totalMaturityAmount } =
    useMemo(() => calculateSummary(dataSource), [dataSource]);

  const canSubmit = validationResults?.length === 0;

  return (
    <GridTemplate
      actions={
        canSubmit ? (
          <LoadingButton
            dataAction="validacao:botao:importar_titulos"
            dataLabel="importar_titulos"
            loading={submitLoading}
            onClick={() => submit()}
          >
            Importar títulos
          </LoadingButton>
        ) : (
          <OButton
            dataAction="validacao:botao:enviar_outro_arquivo"
            dataLabel="enviar_outro_arquivo"
            onClick={() => clear()}
            type="dark"
            outline
          >
            Enviar outro arquivo
          </OButton>
        )
      }
    >
      <ODataGridGenerator grid={validationResultsGrid} dataSource={dataSource}>
        <Summary>
          <TotalItem
            column="errorMessages"
            summaryType="sum"
            customizeText={() => `Total de erros: ${totalErrors}`}
          />
          <TotalItem
            column="maturityAmount"
            summaryType="sum"
            customizeText={() =>
              `Valor de face total: ${masks.currency(
                totalMaturityAmount,
                getValueFromMap(currencySymbol, dataSource[0].currency),
              )}`
            }
          />
          <TotalItem
            column="disbursementAmount"
            summaryType="sum"
            customizeText={() =>
              `Valor de desembolso total: ${masks.currency(
                totalDisbursementAmount,
                getValueFromMap(currencySymbol, dataSource[0].currency),
              )}`
            }
          />
          <TotalItem
            column="maturityDate"
            summaryType="sum"
            customizeText={() => `Prazo mais longo: ${maxDate}`}
          />
        </Summary>
      </ODataGridGenerator>
    </GridTemplate>
  );
};
