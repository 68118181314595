import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoWorkflowConfigViewById } from "../../../../../../../../routes/workflow.route-params";
import { getNewCardsOrder } from "../../../edit-workflow-config.utils";
import {
  AddBigNumbersFormFields,
  defaultValues,
  schema,
} from "./workflow-config-view-big-numbers.schema";

export const useWorkflowConfigViewBigNumbers = () => {
  const [selectedBigNumbersInOrder, setSelectedBigNumbersInOrder] = useState<
    string[]
  >([]);

  const cardBeingDragged = useRef<number>();
  const cardBeingDraggedOver = useRef<number>();

  const { workflowConfigViewId } =
    useParams<WorkflowProdutoWorkflowConfigViewById>();
  if (!workflowConfigViewId) throw new Error("No workflowConfigViewId");

  const form = useForm<AddBigNumbersFormFields>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue, watch } = form;

  const bigNumbersWatch = watch("bigNumbers");

  const {
    value: workflowConfigView,
    callService: getWorkflowConfigViewById,
    loading: loadingWorkflowConfigView,
    hasError: hasErrorWorkflowConfigView,
  } = useServiceCall(service.hubCreditManager.getWorkflowConfigViewById);

  const {
    value: bigNumbersOptions,
    callService: getBigNumbers,
    loading: loadingBigNumbers,
    hasError: hasErrorBigNumbers,
  } = useServiceCall(service.hubCreditManager.getBigNumbers);

  const {
    callService: callUpdateViewBigNumbers,
    loading: loadingUpdateViewBigNumbers,
  } = useServiceCall(
    service.hubCreditManager.orderWorkflowConfigViewBigNumbers,
  );

  const getAllData = useCallback(() => {
    getWorkflowConfigViewById(workflowConfigViewId);
    getBigNumbers();
  }, [getWorkflowConfigViewById, getBigNumbers, workflowConfigViewId]);

  const reorderCards = useCallback(() => {
    if (
      typeof cardBeingDragged.current === "number" &&
      typeof cardBeingDraggedOver.current === "number"
    ) {
      setSelectedBigNumbersInOrder(
        getNewCardsOrder(
          selectedBigNumbersInOrder,
          cardBeingDragged.current,
          cardBeingDraggedOver.current,
        ),
      );
    }

    cardBeingDragged.current = undefined;
    cardBeingDraggedOver.current = undefined;
  }, [selectedBigNumbersInOrder]);

  const updateViewBigNumbers = useCallback(async () => {
    const { success } = await callUpdateViewBigNumbers({
      workflowConfigViewId: Number(workflowConfigViewId),
      bigNumberIds: selectedBigNumbersInOrder.map((bigNumberId) =>
        Number(bigNumberId),
      ),
    });
    if (success) {
      OToastManager.success("Os big numbers foram atualizados com sucesso.");

      getAllData();
    } else {
      OToastManager.danger(
        "Um erro ocorreu ao tentar atualizar os big numbers. Por favor, tente novamente mais tarde.",
      );
    }
  }, [
    callUpdateViewBigNumbers,
    getAllData,
    selectedBigNumbersInOrder,
    workflowConfigViewId,
  ]);

  useEffect(() => {
    if (selectedBigNumbersInOrder.length < bigNumbersWatch.length) {
      const bigNumberIdsNotIncluded = bigNumbersWatch.filter(
        (bigNumberId) => !selectedBigNumbersInOrder.includes(bigNumberId),
      );

      const selectedBigNumbersAux = [
        ...selectedBigNumbersInOrder,
        ...bigNumberIdsNotIncluded,
      ];

      setSelectedBigNumbersInOrder(selectedBigNumbersAux);
    } else if (selectedBigNumbersInOrder.length > bigNumbersWatch.length) {
      setSelectedBigNumbersInOrder([...bigNumbersWatch]);
    }
  }, [bigNumbersWatch, selectedBigNumbersInOrder]);

  useEffect(() => {
    if (workflowConfigView) {
      const bigNumbersIds = workflowConfigView.viewBigNumbers.map((x) =>
        String(x.bigNumber.id),
      );

      setValue("bigNumbers", bigNumbersIds);
    }
  }, [setValue, workflowConfigView]);

  useEffect(() => {
    hasErrorWorkflowConfigView &&
      OToastManager.danger(
        "Um erro ocorreu ao buscar pelos dados da configuração do workflow. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorWorkflowConfigView]);

  useEffect(() => {
    hasErrorBigNumbers &&
      OToastManager.warning(
        "Um erro ocorreu ao tentar buscar os big numbers. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorBigNumbers]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    form,
    updateViewBigNumbers,
    bigNumbersWatch,
    bigNumbersOptions,
    selectedBigNumbersInOrder,
    cardBeingDragged,
    cardBeingDraggedOver,
    getAllData,
    reorderCards,
    loadingWorkflowConfigView,
    loadingBigNumbers,
    loadingUpdateViewBigNumbers,
    hasErrorBigNumbers,
    hasErrorWorkflowConfigView,
    workflowConfigView,
  };
};
