import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { ReceivablesCustomerByStrategyIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { FormValuesType } from "../_compose/formulario/tco-strategies-form.types";
import {
  tcoStrategiesDefaultValues,
  validationSchema,
} from "../_compose/formulario/tco-strategies-form.form";
import { PatchAdminTCOStrategyBody } from "services/quickfin/models/requests/patch-admin-tco-strategy.request";
import { AxiosError, isAxiosError } from "axios";
import { ApiResponse, mapTCOStrategyError } from "../utils";

export const useEditTCOStrategies = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;
  const { strategyId } =
    useParams<ReceivablesCustomerByStrategyIdRouteParams>();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    defaultValues: tcoStrategiesDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, setValue, watch } = form;

  const load = useCallback(async () => {
    try {
      if (!strategyId) return;

      setLoading(true);

      const { data } = await service.quickfin.getAdminTCOStrategyById(
        strategyId,
      );

      const values = {
        description: data.description,
        portfolioId: data.portfolioId,
        tipoProduto: data.tipoProduto,
        companiesIds: data.companies.map((c) => String(c.id)),
        estrategiasIds: data.estrategias.map((e) => String(e.id)),
        basicMinValue: data.valuesByPlan?.simpleS2616?.minValue,
        basicMaxValue: data.valuesByPlan?.simpleS2616?.maxValue,
        basicPercentage: data.valuesByPlan?.simpleS2616?.percentage,
        intermediateMinValue: data.valuesByPlan?.valoR2588?.minValue,
        intermediateMaxValue: data.valuesByPlan?.valoR2588?.maxValue,
        intermediatePercentage: data.valuesByPlan?.valoR2588?.percentage,
        advancedMinValue: data.valuesByPlan?.gestaO2589?.minValue,
        advancedMaxValue: data.valuesByPlan?.gestaO2589?.maxValue,
        advancedPercentage: data.valuesByPlan?.gestaO2589?.percentage,
        corporateMinValue: data.valuesByPlan?.freE2458?.minValue,
        corporateMaxValue: data.valuesByPlan?.freE2458?.maxValue,
        corporatePercentage: data.valuesByPlan?.freE2458?.percentage,
      };

      reset(values);
    } catch {
      OToastManager.danger("Ocorreu um erro para buscar estratégia de tco.");
    } finally {
      setLoading(false);
    }
  }, [reset, strategyId]);

  const onSubmit = handleSubmit(async (values) => {
    if (!strategyId) return;

    setLoading(true);

    const payload: PatchAdminTCOStrategyBody = {
      description: values.description,
      strategyId: Number(strategyId),
      tipoProduto: "CCC",
      estrategiasIds: values.estrategiasIds,
      companiesIds: values.companiesIds,
      portfolioId: values.portfolioId,
      valuesByPlan: {
        SIMPLES2616: {
          maxValue: values.basicMaxValue,
          minValue: values.basicMinValue,
          percentage: values.basicPercentage,
        },
        VALOR2588: {
          maxValue: values.intermediateMaxValue,
          minValue: values.intermediateMinValue,
          percentage: values.intermediatePercentage,
        },
        GESTAO2589: {
          maxValue: values.advancedMaxValue,
          minValue: values.advancedMinValue,
          percentage: values.advancedPercentage,
        },
        FREE2458: {
          maxValue: values.corporateMaxValue,
          minValue: values.corporateMinValue,
          percentage: values.corporatePercentage,
        },
      },
    };

    try {
      await service.quickfin.updateAdminTCOStrategy(payload);

      OToastManager.success("Estratégia alterada com sucesso.");
      modalManager.show("update-strategies-modal");
    } catch (e) {
      if (isAxiosError<ApiResponse>(e)) {
        OToastManager.danger(mapTCOStrategyError(e as AxiosError));
      } else {
        OToastManager.danger("Ocorreu um erro ao atualizar a estratégia.");
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    const modalClose = () =>
      navigate(routes.recebiveis.product.tcoStrategies.path);

    const cleanUp = modalManager.on(
      "update-strategies-modal",
      "modalClose",
      modalClose,
    );

    return cleanUp;
  }, [navigate, routes]);

  return {
    form,
    loading,
    strategyId,
    onSubmit,
    setValue,
    watch,
  };
};
