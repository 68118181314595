export const productMap = [
  { value: "Cartao", title: "Cartão" },
  { value: "CCC", title: "Cessão com coobrigação" },
  { value: "RiscoCedente", title: "Cessão de crédito" },
  { value: "FIDC", title: "FIDC" },
  { value: "FundingProprio", title: "Funding próprio" },
  { value: "RiscoSacado", title: "Risco Sacado" },
];

export const priorityMap = [
  { value: "1", title: "Máxima" },
  { value: "2", title: "Alta" },
  { value: "3", title: "Média" },
  { value: "4", title: "Baixa" },
];

export const onboardingTypeMap = [
  { value: "CEDENTE_COMPLETO", title: "Cedente completo" },
  { value: "CEDENTE_SIMPLES", title: "Cedente simples" },
  { value: "SACADO_SIMPLES", title: "Sacado simples" },
];
