import { OTypography } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { ItemField } from "../../details-card.types";
import { ElementOrFallback } from "../element-or-fallback";
import { makeGridClasses } from "./detail-field.utils";

interface DetailFieldProps {
  field: ItemField;
}

export const DetailField = ({ field }: DetailFieldProps) => {
  const { hasRole } = useRoles();

  if (field.visible === false || !hasRole(field.role)) return null;

  const valueWithFallback = field.value ?? "-";

  return (
    <div
      className={makeGridClasses(field.grid)}
      style={{ boxSizing: "border-box" }}
    >
      <div className="d-flex flex-column gap-2 mb-3">
        <ElementOrFallback
          node={field.label}
          fallback={
            <OTypography weight="500" size="sm" type="default">
              {field.label}
            </OTypography>
          }
        />
        <ElementOrFallback
          node={valueWithFallback}
          fallback={<OTypography>{valueWithFallback}</OTypography>}
        />
      </div>
    </div>
  );
};
