import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostApiCustomerInviteOpenAccountBody } from "services/onboarding/models";
import {
  ContactFormValuesType,
  contactValidationSchema,
} from "../_compose/current-account-form";
import { useInviteRequests } from "../_compose/invite-requests/invite-requests-hook";

export const useAccountPjInvite = () => {
  const [loading, setLoading] = useState(false);
  const [optinTemplate, setOptinTemplate] = useState(String);
  const [payloadAux, setPayloadAux] =
    useState<PostApiCustomerInviteOpenAccountBody>();
  const [taxId, setTaxId] = useState(String);

  const form = useForm<ContactFormValuesType>({
    resolver: yupResolver(contactValidationSchema),
  });

  const { sentInvite } = useInviteRequests();
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values: ContactFormValuesType) => {
    try {
      setLoading(true);
      const payload = {
        invite: {
          taxId: values.taxId,
          name: values.officialName,
          identification: values.taxId,
          deskCode: String(values.desk),
          officerCge: values.officer,
          accounts: [
            {
              agency: values.account?.split("-")[0],
              type: values.account?.split("-")[1],
              optinTemplate: values.account?.split("-")[2],
            },
          ],
          notifier: {
            email: values?.email,
          },
        },
      };

      const optinTemplateRequested = payload.invite.accounts[0].optinTemplate;

      if (
        optinTemplateRequested !== "null" &&
        optinTemplateRequested !== undefined
      ) {
        payload.invite.accounts[0].optinTemplate =
          optinTemplateRequested.replace(
            "{officerCge}",
            String(values.officer),
          );

        setOptinTemplate(payload.invite.accounts[0].optinTemplate);
        setTaxId(payload.invite.taxId);
        setPayloadAux(payload);

        const { data } = await service.onboardingCoreAccount.verifyOptinByCode({
          taxId: payload.invite.taxId,
          templates: [payload.invite.accounts[0].optinTemplate],
        });

        if (data.isOptinAvaliable) {
          modalManager.show("invite-modal");
        } else {
          await sentInvite(payload);
        }
      } else {
        await sentInvite(payload);
      }
    } catch (err) {
      OToastManager.danger("Erro inesperado ao enviar o convite");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
    setLoading,
    optinTemplate,
    taxId,
    payloadAux,
  };
};
