import {
  OBreadcrumb,
  OBreadcrumbItem,
  OIcon,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { corporateRouter } from "routes/corporate-router.context";
import { BannerPreview } from "../_compose/banner-preview/banner-preview.component";
import { StepTimeline } from "./_compose/step-render";
import { stepsMap } from "./_compose/step-render/step-render.types";
import { useBannerForm } from "./banner-form.hook";

export const BannerManagementFormPage = () => {
  const { step, form, bannerTemplate, setStep, navigate, ...props } =
    useBannerForm();

  const renderStep = useMemo(() => {
    const StepRender = stepsMap[step].content;

    return (
      <StepRender setStep={setStep} bannerTemplate={bannerTemplate} {...props}>
        <BannerPreview form={form} bannerTemplate={bannerTemplate} />
      </StepRender>
    );
  }, [bannerTemplate, form, props, setStep, step]);

  return (
    <div className="d-flex flex-column gap-4">
      <div className="w-100 mb-2" key={step}>
        <OBreadcrumb>
          <OBreadcrumbItem
            href="#"
            onClick={() =>
              navigate(
                corporateRouter.routes.workflow.product.bannerManagement.path,
              )
            }
          >
            <span className="d-flex gap-2">
              <OIcon category="fal" icon="fa-home-alt" size="md" />
              Gerenciamento de campanhas
            </span>
          </OBreadcrumbItem>
          <OBreadcrumbItem href="">Campanha</OBreadcrumbItem>
        </OBreadcrumb>

        <StepTimeline step={step} />

        <OTypography
          size="xxl"
          type="dark"
          className="my-3"
          key={`${step}-title`}
        >
          {stepsMap[step].title}
        </OTypography>
        <OTypography type="dark-80" key={`${step}-subtitle`}>
          {stepsMap[step].subtitle}
        </OTypography>
      </div>

      <FormProvider {...form}>{renderStep}</FormProvider>
    </div>
  );
};
