import { OCard } from "@maestro/react";
import styled from "styled-components";

export const Container = styled.div`
  gap: 10px;
`;

export const StyledCard = styled(OCard)`
  margin-bottom: 15px;

  .card-body {
    padding: 2rem;
  }
`;
