import { useEffect, useMemo, useState } from "react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { percent4DigitsMask } from "utils/mask";
import { SimulatedOperationsWithoutRegistryResponseWithAvgInfos } from "services/quickfin/models/responses/post-admin-operations-without-registry.response";
import { OToastManager } from "@maestro/react";

export const BuildOperationDetails = (
  receivablesWithAvgInfos: SimulatedOperationsWithoutRegistryResponseWithAvgInfos,
): DetailsFields => {
  const [avgTerm, setAvgTerm] = useState(0);
  const [avgMonthlyRate, setAvgMonthlyRate] = useState(0);

  const totalMaturityValue = useMemo(
    () =>
      receivablesWithAvgInfos.receivablesWithRate.reduce(
        (acc, curr) => acc + curr.maturityAmount,
        0,
      ),
    [receivablesWithAvgInfos],
  );

  const totalDisbursementValue = useMemo(
    () =>
      receivablesWithAvgInfos.receivablesWithRate.reduce(
        (acc, curr) => acc + curr.disbursementAmount,
        0,
      ),
    [receivablesWithAvgInfos],
  );

  useEffect(() => {
    if (!receivablesWithAvgInfos) return;

    try {
      const avgTermValue = receivablesWithAvgInfos.avgTerm;
      const avgMonthlyRateValue = receivablesWithAvgInfos.avgMonthlyRate;

      setAvgTerm(avgTermValue);
      setAvgMonthlyRate(avgMonthlyRateValue);
    } catch (error) {
      OToastManager.danger(
        "Não foi possível calcular a Taxa Média Mensal e o Prazo Médio.",
      );
    }
  }, [receivablesWithAvgInfos]);

  return {
    details: {
      items: [
        {
          label: "Valor dos Vencimentos",
          value: masks.currency(totalMaturityValue, "R$"),
          grid: { sm: 6, md: 4, lg: 2 },
        },
        {
          label: "Valor dos Desembolsos",
          value: masks.currency(totalDisbursementValue, "R$"),
          grid: { sm: 6, md: 4, lg: 2 },
        },
        {
          label: "Número de Recebíveis",
          value: receivablesWithAvgInfos.receivablesWithRate.length,
          grid: { sm: 6, md: 4, lg: 2 },
        },
        {
          label: "Taxa Média Mensal",
          value: percent4DigitsMask(avgMonthlyRate),
          grid: { sm: 6, md: 4, lg: 2 },
        },
        {
          label: "Prazo Médio",
          value: `${avgTerm.toFixed(2)} dia(s)`,
          grid: { sm: 6, md: 4, lg: 2 },
        },
      ],
    },
  };
};
