import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { ErrorNotification } from "./error-notification.component";

export interface ReceivableError {
  bandeira: string | undefined;
  dataVencimento: string | undefined;
  cedenteCnpj: string | undefined;
  sacadoCnpj: string | undefined;
}

interface ErrorNotificationsProps {
  receivablesWithError: ReceivableError[];
}

export const ErrorNotifications = ({
  receivablesWithError,
}: ErrorNotificationsProps) => {
  return (
    <div className="d-flex flex-column gap-3 mb-3">
      {receivablesWithError.map((error) => (
        <ErrorNotification key={Object.keys(error).join()}>
          Não foi possível processar a combinação de bandeira{" "}
          {error.bandeira || "N/A"}, data de vencimento{" "}
          {error.dataVencimento
            ? dayjs(error.dataVencimento, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "N/A"}
          , cedente {masks.cnpj(error.cedenteCnpj) || "N/A"} e sacado{" "}
          {masks.cnpj(error.sacadoCnpj) || "N/A"}.
        </ErrorNotification>
      ))}
    </div>
  );
};
