import * as yup from "yup";
import { SimulationSummary } from "./simulation.types";

export const formFields = {
  amount: {
    id: "amount",
    name: "amount",
    labelSize: "lg" as const,
    size: "md" as const,
    initialValue: 0,
  },
  firstPaymentDate: {
    id: "firstPaymentDate",
    name: "firstPaymentDate",
    label: "Primeiro vencimento",
    labelSize: "lg" as const,
    size: "md" as const,
  },
  termOptions: {
    id: "termOptions",
    name: "termOptions",
    label: "Quantidade de parcelas",
    labelSize: "lg" as const,
    size: "md" as const,
    placeholder: "Selecione:",
  },
};

export const validationSchema = (
  summary: SimulationSummary | undefined,
  type: string | undefined,
) =>
  yup.object({
    amount: yup
      .number()
      .test(
        "minAmount",
        "Valor inferior ao mínimo oferecido",
        (value) =>
          (!!type && ["agro", "solar"].includes(type)) ||
          (!!value && !!summary && value >= summary.agreement.minAmount),
      )
      .test(
        "maxAmount",
        "Valor superior ao máximo oferecido",
        (value) =>
          (!!type && ["agro", "solar"].includes(type)) ||
          (!!value && !!summary && value <= summary.limit),
      ),
  });

export const formDefaultValues = {
  amount: formFields.amount.initialValue,
};
