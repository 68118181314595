
import {
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {  ILimitBagsResponse } from "services/limit-control/models/responses";
import { modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";

import { LimiteCedenteMasterDetail } from "./controlador-limite-cedente-details";
import { DataGridAction } from "components/datagrid-action";
import { AddLimiteModal, buildAddLimiteModalId } from "./compose/create-dependent-limite-modal";



export const limiteCedenteGrid: ODataGridGeneratorConfig<
  ILimitBagsResponse["limitBags"][number]
> = {
  datagrid: {
    noDataText: "Nenhum grupo.",
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "id",
      dataType: "string",
      caption: "CNPJ do Cedente",
      allowFiltering: false,
      allowExporting: true,
      customizeText:  ({ value }) => masks.cnpj(value.replace("Cartao:V2:", "")),
    },
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
      allowFiltering: false,
      allowExporting: true,
    },
    {
      dataField: "availableAmount",
      dataType: "number",
      caption: "Limite disponível",
      allowFiltering: false,
      allowExporting: true,
      format: (value) => masks.currency(value, "R$")
    },
    {
      dataField: "consumedAmount",
      dataType: "number",
      caption: "Limite consumido",
      allowFiltering: false,
      allowExporting: true,
      format: (value) => masks.currency(value, "R$")
    },
    {
      dataField: "totalAmount",
      dataType: "number",
      caption: "Limite total",
      allowFiltering: false,
      format: (value) => masks.currency(value, "R$")
    },
    {
      caption: "Ações",
      alignment: "center",
      allowExporting: false,
      cellRender: ({ data }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "far", icon: "fa-plus" },
                label: "Criar",
                onClick: () => {
                  modalManager.show(buildAddLimiteModalId(data.id));
                }
              },
            ]}
          />
          <AddLimiteModal
            limitBag={data}
          />
        </>
      )
    },
  ],
  masterDetail: {
    enabled: true,
    component: LimiteCedenteMasterDetail
  }
};
