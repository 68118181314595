import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GridTemplate } from "templates/grid-template";
import { checkboxValue } from "./attach-user.form";
import { attachUserProfilesGrid } from "./attach-user.grid";
import { useAttachUser } from "./attach-user.hook";

export const AttachUser = () => {
  const gridRef = useGridRef();
  const { dataSource, form, identification, initialValues, loading, submit } =
    useAttachUser();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Vincular usuário" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="acoes:botao:enviar"
          dataLabel="enviar"
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="cpf"
                id="taxId"
                name="taxId"
                label="CPF"
                placeholder="Preencher"
                required
                disabled={!!identification}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                label="Nome"
                placeholder="Preencher"
                required
                disabled={!!initialValues?.name}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="text"
                id="email"
                name="email"
                label="E-mail"
                placeholder="Preencher"
                required
                disabled={!!initialValues?.email}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="phone"
                id="phone"
                name="phone"
                dataAction="formulario:texto:celular"
                dataLabel="celular"
                label="Celular"
                placeholder="Preencher"
                required
                disabled={!!initialValues?.phone}
              />
            </OCol>
            <OCol xs={12}>
              <ORFieldCheckboxGroup
                id="sendWelcomeEmail"
                name="sendWelcomeEmail"
              >
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id={checkboxValue}
                    value={checkboxValue}
                    size="sm"
                  />
                  <OLabel htmlFor={checkboxValue} type="dark">
                    Enviar e-mail de boas vindas
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
          <GridTemplate gridRef={gridRef} showRefreshButton>
            <ORFieldCheckboxGroup id="profiles" name="profiles">
              <ODataGridGenerator
                gridRef={gridRef}
                grid={attachUserProfilesGrid}
                dataSource={dataSource}
              />
            </ORFieldCheckboxGroup>
          </GridTemplate>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
