import { FileUpload } from "./file-upload";
import { Preview } from "./preview";
import { Result } from "./result";

export const steps = [
  {
    name: "Envio de arquivo",
    component: FileUpload,
  },
  {
    name: "Confimação",
    component: Preview,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
