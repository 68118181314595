import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { ReconResultDetails } from "./reconciliation-details.types";
import { getResultDetailsFiltersFromLoadOptions } from "./reconciliation-details.utils";

export const buildDataSource = (reconResultId: string) =>
  dataSourceCustomStoreGenerator<ReconResultDetails>((loadOptions) => {
    const filters = getResultDetailsFiltersFromLoadOptions(loadOptions);
    return service.reconciliero
      .getReconciliationResultDetailsById(reconResultId, {
        keyFilter: filters.key,
        isBreakFilter: filters.isBreak,
        skip: loadOptions.skip ?? 0,
        take: loadOptions.take ?? 10,
      })
      .then(({ data }) => ({ data: data.results, totalCount: data.count }))
      .catch(() => {
        const errorMessage = "Erro ao buscar detalhes da reconciliação";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const detailsGrid: ODataGridGeneratorConfig<ReconResultDetails> = {
  datagrid: {
    noDataText: "Nenhum item encontrado.",
    filterRow: {
      visible: true,
    },
    pager: {
      infoText: "Página {0} de {1} ({2} resultados)",
      visible: true,
      showPageSizeSelector: true,
    },
    remoteOperations: {
      paging: true,
      filtering: true,
    },
    sorting: {
      mode: "none",
    },
  },
  columns: [
    {
      dataField: "key",
      dataType: "string",
      caption: "Chave",
    },
    {
      dataField: "isBreak",
      dataType: "boolean",
      caption: "Break",
      width: 85,
    },
    {
      dataField: "breakDescription",
      dataType: "string",
      caption: "Descrição",
      allowFiltering: false,
    },
    {
      dataField: "firstSideValue",
      dataType: "number",
      caption: "Primeiro instrumento",
      allowFiltering: false,
      calculateCellValue: ({ firstSideValue }) =>
        masks.currency(firstSideValue, "R$") || "-",
    },
    {
      dataField: "secondSideValue",
      dataType: "number",
      caption: "Segundo instrumento",
      allowFiltering: false,
      calculateCellValue: ({ secondSideValue }) =>
        masks.currency(secondSideValue, "R$") || "-",
    },
    {
      dataField: "expectedValue",
      dataType: "number",
      caption: "Valor esperado",
      allowFiltering: false,
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "breakValue",
      dataType: "number",
      caption: "Valor break",
      allowFiltering: false,
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "reconciliatedValue",
      dataType: "number",
      caption: "Valor reconciliado",
      allowFiltering: false,
      format: (value) => masks.currency(value, "R$"),
    },
  ],
};
