import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OTypography,
} from "@maestro/react";
import { sortById } from "../../../../../../utils";
import { useStepAnalysisContext } from "../../step-analysis.context";
import { PropertyTile } from "../property-tile";
import { StickyModalHeader } from "./all-properties-modal-button.styles";

const modalId = "all-properties-modal";

export const AllPropertiesModalButton = () => {
  const { stepAnalysis } = useStepAnalysisContext();

  return (
    <>
      <OButton outline onClick={() => modalManager.show(modalId)}>
        Ver propriedades
      </OButton>
      <OModal id={modalId} position="center" className="position-absolute">
        <StickyModalHeader closeButton>
          <OTypography tag="h1" size="lg" weight="900">
            Propriedades
          </OTypography>
        </StickyModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-4">
            {stepAnalysis.allProperties.length ? (
              sortById(stepAnalysis.allProperties).map((p) => (
                <PropertyTile key={p.id} property={p} readonly />
              ))
            ) : (
              <OTypography>
                <em>Esta etapa não possui nenhuma propriedade</em>
              </OTypography>
            )}
          </div>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex">
            <OButton
              type="dark"
              outline
              onClick={() => modalManager.hide(modalId)}
            >
              Fechar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
    </>
  );
};
