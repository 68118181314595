import { ODivider } from "@maestro/react";
import { IAddress } from "../../../../cards.types";
import { ITrackingCard, TrackingStatus } from "../../tracking.types";
import { AlertMessages } from "../alert-messages";
import { CardTrackingAddress } from "../card-tracking-address";
import { TrackingCode } from "../tracking-code";
import {
  externalTrackingStatusCodes,
  MapperTrackingStatusToPostOfficeMessages,
  postOfficeStatusCodes
} from "./types";

interface CardTrackingInfoProps {
  cardTracking: ITrackingCard;
  address: IAddress;
}

export const CardTrackingInfo = ({
  cardTracking,
  address,
}: CardTrackingInfoProps): JSX.Element => {
  const [currentHistory] = cardTracking.history.slice(-1);
  const currentStatus = currentHistory.status;

  const postOfficeMessages = MapperTrackingStatusToPostOfficeMessages[
    currentStatus
  ] ?? [""];

  const isPostOffice = postOfficeStatusCodes.includes(currentStatus);

  const hasExternalTracking =
    externalTrackingStatusCodes.includes(currentStatus);

  const showTrackingCode =
    hasExternalTracking &&
    cardTracking.deliveryCode &&
    cardTracking.externalTrackingURL;

  return (
    <div className="d-flex flex-column gap-4">
      {showTrackingCode && (
        <>
          <div className="d-flex flex-column gap-3">
            {isPostOffice && <AlertMessages messages={postOfficeMessages} />}
            <TrackingCode
              link={cardTracking.externalTrackingURL}
              code={cardTracking.deliveryCode}
              isPostOffice={isPostOffice}
            />
          </div>
          <ODivider />
        </>
      )}

      <CardTrackingAddress
        delivered={currentStatus === TrackingStatus.Delivery}
        address={address}
        bestDate={cardTracking.deliveryBestDate}
        worstDate={cardTracking.deliveryWorstDate}
      />
    </div>
  );
};
