import { validators } from "@maestro/utils";
import * as yup from "yup";

export const addGroupValidationSchema = yup.object({
  taxId: yup
    .string()
    .trim()
    .required("É necessário informar o CNPJ")
    .test("taxId", "CNPJ inválido", (value) => validators.cnpj(value)),
});
