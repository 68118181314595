import { BankIconSVG } from "../financial-institution-icon.types";

export const BankC6SVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#242424" />
      <path
        d="M48.31 58.002c-.322 1.493-1.005 2.7-2.053 3.617-1.047.916-2.261 1.374-3.644 1.374-1.774 0-3.21-.628-4.316-1.886-1.102-1.259-1.654-2.905-1.654-4.94 0-2.047.547-3.7 1.645-4.958 1.096-1.259 2.537-1.888 4.325-1.888 1.299 0 2.473.416 3.52 1.248 1.048.832 1.787 1.93 2.22 3.295h10.2c-.53-4.137-2.263-7.483-5.194-10.035C50.425 41.276 46.828 40 42.57 40c-3.157 0-5.998.693-8.526 2.08-2.528 1.387-4.5 3.314-5.918 5.78C26.71 50.329 26 53.097 26 56.17c0 3.072.709 5.84 2.126 8.307 1.418 2.468 3.39 4.394 5.918 5.78 2.528 1.387 5.37 2.081 8.526 2.081 4.328 0 7.974-1.323 10.935-3.968 2.96-2.645 4.678-6.1 5.153-10.367H48.31z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.986 53.211C84.995 55.138 86 57.631 86 60.685c0 3.492-1.156 6.308-3.47 8.445-2.314 2.139-5.362 3.207-9.148 3.207-3.74 0-6.782-1.073-9.125-3.218-2.344-2.146-3.515-4.925-3.515-8.344 0-1.866.322-3.744.965-5.633.642-1.89 1.637-3.956 2.983-6.198l4.756-7.91h10.052l-5.628 9.331c.561-.03 1.005-.044 1.33-.044 3.18 0 5.774.963 7.786 2.89zm-5.7 7.474c0 1.175-.363 2.13-1.087 2.867-.723.738-1.664 1.108-2.817 1.108-1.152 0-2.093-.37-2.816-1.108-.725-.737-1.086-1.692-1.086-2.867 0-1.174.36-2.137 1.087-2.89.722-.752 1.663-1.13 2.816-1.13 1.152 0 2.091.377 2.816 1.13.724.753 1.086 1.716 1.086 2.89z"
        fill="#fff"
      />
    </svg>
  );
};
