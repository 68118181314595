import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";

export const booksAndStrategiesDataSource = dataSourceCustomStoreGenerator(
  async () => {
    return service.hubCreditManager.getBookStrategies().catch(() => {
      throw new Error("Um erro ocorreu ao tentar buscar books / estratégias.");
    });
  },
  {
    customStoreOptions: {
      remove: async (data) => {
        try {
          await service.hubCreditManager.deleteBookStrategy({
            bookStrategyId: data.id,
          });
          OToastManager.success(
            "A configuração de template de book / estratégia foi removida com sucesso.",
          );
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao tentar remover a configuração de book / estratégia. Por favor, tente novamente mais tarde.",
          );
        }
      },
    },
  },
);

export const booksAndStrategiesGrid = {
  datagrid: {
    noDataText: "Nenhuma configuração de book / estratégia foi encontrada.",
    headerFilter: { visible: true, allowSearch: true },
    editing: {
      allowDeleting: true,
      confirmDelete: true,
    },
    paging: {
      pageSize: 10,
    },
    onRowRemoved: () => booksAndStrategiesDataSource.reload(),
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "book.bookCr",
      caption: "CR Book",
      alignment: "left",
    },
    {
      dataField: "book.bookName",
      caption: "Book",
      alignment: "left",
    },
    {
      dataField: "strategy.strategyCr",
      caption: "CR Estratégia",
      alignment: "left",
    },
    {
      dataField: "strategy.strategyName",
      caption: "Estratégia",
      alignment: "left",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.getBookStrategies>
    >["data"]
  >[number]
>;
