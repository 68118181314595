import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import dayjs from "dayjs";
import { service } from "services";
import { PostFilterTableRegistrosResponse } from "services/recebiveis-cartao/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

type Register = PostFilterTableRegistrosResponse["data"][number];

export const dataSource = dataSourceCustomStoreGenerator<Register>(
  (loadOptions) =>
    service.recebiveisCartao
      .getRegisters(loadOptions as any) // sort
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os registros";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const registersGrid: ODataGridGeneratorConfig<Register> = {
  datagrid: {
    noDataText: "Nenhum registro",
    pager: { showPageSizeSelector: true },
    headerFilter: { visible: true },
    filterRow: { visible: true },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "inserted_at",
      caption: "Inserido em",
      dataType: "datetime",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
    {
      dataField: "external_id",
      dataType: "string",
      caption: "Id externo",
      allowHeaderFiltering: false,
    },
    {
      dataField: "tipo",
      dataType: "string",
      caption: "Modelo",
    },

    {
      dataField: "action",
      dataType: "string",
      caption: "Ação",
    },
    {
      dataField: "trade_external_id",
      dataType: "string",
      caption: "Trade ID QuickFin",
      allowHeaderFiltering: false,
    },
    {
      dataField: "trade_id_cros",
      dataType: "string",
      caption: "Trade ID CROS",
      allowHeaderFiltering: false,
    },
    {
      dataField: "company_name",
      dataType: "string",
      caption: "Nome do cedente",
      allowHeaderFiltering: false,
    },
    {
      dataField: "company_tax_id",
      dataType: "string",
      caption: "CNPJ do cedente",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "credenciadora_name",
      dataType: "string",
      caption: "Nome do sacado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "credenciadora_tax_id",
      dataType: "string",
      caption: "CNPJ do sacado",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "bandeira",
      dataType: "string",
      caption: "Bandeira",
    },
    {
      dataField: "maturity_date",
      dataType: "date",
      format: "shortDate",
      caption: "Data de vencimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "valor_registrado",
      dataType: "number", // cast to number
      caption: "Valor registrado",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "valor_solicitado",
      dataType: "number", // cast to number
      caption: "Valor solicitado",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "valor_alocado_hoje",
      dataType: "number", // cast to number
      caption: "Valor alocado hoje",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "identop",
      dataType: "string",
      caption: "Identop",
      allowHeaderFiltering: false,
    },
    {
      dataField: "nu_op",
      dataType: "string",
      caption: "Nu op",
      allowHeaderFiltering: false,
    },
    {
      dataField: "cod_msg",
      dataType: "string",
      caption: "Cod mensagem",
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
      filterValue: "ativo",
      selectedFilterOperation: "=",
    },
    {
      dataField: "constituicao",
      dataType: "string",
      caption: "Constituição",
    },
    {
      dataField: "ident_negc_recebivel",
      dataType: "string",
      caption: "Id garantia",
      allowHeaderFiltering: false,
    },
    {
      dataField: "registro_solicitation_id",
      dataType: "number",
      caption: "Id solicitação",
      allowHeaderFiltering: false,
    },
  ],
};
