import { DetailsWithoutCard } from "components/details-card";
import { stepRelatedInfoFields } from "./step-related-info.details";

interface StepRelatedInfoProps {
  details: Record<string, string>;
}

export const StepRelatedInfo = ({ details }: StepRelatedInfoProps) => {
  return <DetailsWithoutCard value={details} fields={stepRelatedInfoFields} />;
};
