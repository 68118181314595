import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { CompanyTraitsProps } from "services/recebiveis-cartao/models";
import { ODataRequest } from "services/service.types";
import { modalManager, OToastManager } from "@maestro/core";
import { OButton, OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import { confirmationModalId } from "./confirmation-modal.component";
import { ParamsProps } from "./company-traits.types";
import { traitNameMapper } from "./company-traits.utils";

export const companyTraitsGrid = (setParams: (params: ParamsProps) => void) =>
  ({
    datagrid: {
      noDataText: "Nenhuma empresa",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      remoteOperations: true,
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
        infoText: "Página {0} de {1} ({2} empresas)",
      },
      dataSource: dataSourceCustomStoreGenerator<CompanyTraitsProps>(
        (loadOptions) =>
          service.recebiveisCartao
            .getCompanyTraits(loadOptions as ODataRequest)
            .then(({ data }) => ({
              data: data.data ?? [],
              totalCount: data.total_count,
            }))
            .catch(() => {
              const errorMessage = "Não foi possível buscar as empresas";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }),
      ),
    },
    columns: [
      {
        dataField: "name",
        dataType: "string",
        caption: "Razão social",
        allowHeaderFiltering: false,
      },
      {
        dataField: "taxId",
        dataType: "string",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "trait",
        dataType: "string",
        caption: "Propriedade",
        cellRender: ({ data }) =>
          data.trait in traitNameMapper
            ? traitNameMapper[data.trait as keyof typeof traitNameMapper]
            : "Propriedade desconhecida",
      },
      {
        dataField: "insertedAt",
        dataType: "datetime",
        caption: "Inserido em",
        allowHeaderFiltering: false,
      },
      {
        dataField: "updatedAt",
        dataType: "datetime",
        caption: "Atualizado em",
        allowHeaderFiltering: false,
      },
      {
        caption: "Remover",
        cellRender: ({ data }) => (
          <OButton
            type="danger"
            size="sm"
            outline
            onClick={() => {
              modalManager.show(confirmationModalId);
              setParams({
                taxId: data.taxId,
                trait: data.trait,
                id: data.id,
                name: data.name,
              });
            }}
          >
            <OIcon category="orq" icon="orq-edit-trash" size="md" />
          </OButton>
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<CompanyTraitsProps>);
