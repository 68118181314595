import { OClickableBox, OTypography } from "@maestro/react";
import {
  ChangeBehaviorCommand,
  behaviorManager,
  useUnsafeBehavior,
} from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";

export const BehaviorSelector = () => {
  const { behavior } = useUnsafeBehavior();

  return (
    <div className="d-flex justify-content-between flex-column gap-2 py-4">
      <OTypography weight="500" className="mb-2">
        Comportamentos
      </OTypography>

      <OClickableBox
        dataAction="modal_configuracoes:botao:comportamento_administrador"
        dataLabel="comportamento_administrador"
        inputType="radio"
        name="behavior-selector"
        id={Behaviors.Admin}
        value={Behaviors.Admin}
        checked={behavior?.id === Behaviors.Admin}
        size="sm"
        onInput={() =>
          behaviorManager.execute(new ChangeBehaviorCommand(Behaviors.Admin))
        }
      >
        Administrador
      </OClickableBox>

      <OClickableBox
        dataAction="modal_configuracoes:botao:comportamento_rtb"
        dataLabel="comportamento_rtb"
        inputType="radio"
        name="behavior-selector"
        id={Behaviors.RTB}
        value={Behaviors.RTB}
        checked={behavior?.id === Behaviors.RTB}
        size="sm"
        onInput={() =>
          behaviorManager.execute(new ChangeBehaviorCommand(Behaviors.RTB))
        }
      >
        RTB
      </OClickableBox>

      <OClickableBox
        dataAction="modal_configuracoes:botao:comportamento_corban"
        dataLabel="comportamento_corban"
        inputType="radio"
        name="behavior-selector"
        id={Behaviors.Corban}
        value={Behaviors.Corban}
        checked={behavior?.id === Behaviors.Corban}
        size="sm"
        onInput={() =>
          behaviorManager.execute(new ChangeBehaviorCommand(Behaviors.Corban))
        }
      >
        Corban
      </OClickableBox>
    </div>
  );
};
