import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { ResetButton, SubmitButton } from "../../_compose";
import { useUploadTiers } from "../../upload-tiers.context";
import { previewBatchTiersGrid } from "./preview.grid";

export const Preview = () => {
  const { previewData } = useUploadTiers();

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate>
        <ODataGridGenerator
          grid={previewBatchTiersGrid}
          dataSource={previewData}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetButton>Voltar</ResetButton>
        <SubmitButton />
      </div>
    </div>
  );
};
