import { useUnsafeCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { Navigate } from "react-router-dom";
import { Role } from "roles/roles.types";
import { logger } from "utils/logger";

interface SecureRouteProps {
  role: Role | undefined;
  children: React.ReactNode;
}

export const SecureRoute = ({ role, children }: SecureRouteProps) => {
  const { hasRole } = useRoles();
  const { customer } = useUnsafeCustomer();

  if (!hasRole(role)) {
    logger.error("secureRouteRedirect", {
      from: window.location.href,
      role,
      customer: customer?.identification,
    });

    return <Navigate to="/nao-autorizado" replace />;
  }

  return children as JSX.Element;
};
