import { roles } from "roles/roles";
import { ExcludeUser } from "./_compose";
import { Tool } from "./tools.types";

export const tools: Tool[] = [
  {
    description: "Excluir todos os dados do usuário do portal",
    type: "Cadastro",
    action: <ExcludeUser />,
    role: roles.usuarios.product.excludeUserToolsPage.role,
  },
];
