import { OLabel, ORadio } from "@maestro/react";

export interface Props {
  label: string;
  value: string;
}

export const RadioFilterItem = ({ label, value }: Props) => {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex flex-row justify-content-between gap-2 p-3">
        <OLabel htmlFor={value} size="md">
          {label}
        </OLabel>
        <ORadio id={value} name={value} value={value} size="xs" />
      </div>
    </div>
  );
};
