import { BankIconSVG } from "../financial-institution-icon.types";

export const BankDigioSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#1B234A" />
      <path
        d="M71.552 48.184a1.692 1.692 0 11-3.385 0 1.692 1.692 0 013.385 0zM46.32 46.48a1.797 1.797 0 11-3.594 0 1.797 1.797 0 013.594 0z"
        fill="#00A0E6"
      />
      <path
        d="M36.725 39.632a1.2 1.2 0 00-1.21 1.211v6.86a8.48 8.48 0 100 11.92v1.305a1.212 1.212 0 002.423 0V40.843a1.2 1.2 0 00-1.213-1.211zm-7.267 20.085a6.056 6.056 0 116.056-6.057c0 3.489-2.81 6.057-6.056 6.057zm15.146-9.603a1.2 1.2 0 011.21 1.21v9.656a1.211 1.211 0 01-2.421 0v-9.655a1.2 1.2 0 011.21-1.212zM69.79 51.64l-.006-.003a1.13 1.13 0 011.144 1.144v9.098a1.143 1.143 0 11-2.281 0v-9.096a1.13 1.13 0 011.143-1.143zm13.223-4.577A7.986 7.986 0 1091 55.05a7.966 7.966 0 00-7.987-7.986zm0 13.691a5.705 5.705 0 110-11.41 5.705 5.705 0 010 11.41zM64.212 48.208a1.129 1.129 0 00-1.144-1.144h-6.842a7.986 7.986 0 104.677 14.47A5.704 5.704 0 1150.6 65.75a1.143 1.143 0 00-2.255.354 7.966 7.966 0 008.106 6.677c4.161-.113 7.585-3.485 7.754-7.644a7.967 7.967 0 00-1.644-5.21 8.017 8.017 0 00-.736-10.578h1.245a1.129 1.129 0 001.144-1.143l-.002.002zm-2.285 6.843a5.705 5.705 0 11-11.41 0 5.705 5.705 0 0111.41 0z"
        fill="#fff"
      />
    </svg>
  );
};
