import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { getValueFromMap } from "utils/get-value-from-map";
import { StatusBadge } from "components/status-badge";
import { OIcon, OTooltip } from "@maestro/react";
import { creditConciliationStatusFileBadgeMap } from "./credit-conciliation-details.utils";
import { DownloadButton } from "./download-file-button/download-file-button.component";

export const filesGrid: ODataGridGeneratorConfig<Fidc.CreditConciliationsFileItem> =
  {
    datagrid: {
      noDataText: "Nenhum arquivo encontrado.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "dateCreated",
        dataType: "datetime",
        format: (value) =>
          dayjs(value).utc(true).local().format("DD/MM/YYYY HH:mm:ss"),
        caption: "Data Base",
      },
      {
        dataField: "agent",
        caption: "Origem",
        allowHeaderFiltering: false,
      },
      {
        dataField: "author",
        caption: "Autor",
        allowHeaderFiltering: false,
      },
      {
        dataField: "description",
        caption: "Descrição",
        allowHeaderFiltering: false,
      },
      {
        dataField: "approvedAmount",
        caption: "Valor Aprovado",
        allowHeaderFiltering: false,
        cellRender: ({ data }) => {
          return data.agent === "PARTNER"
            ? "-"
            : masks.currency(data.approvedAmount, "R$");
        },
      },
      {
        dataField: "approvedDiscount",
        caption: "Valor Desconto",
        allowHeaderFiltering: false,
        cellRender: ({ data }) => {
          return data.agent === "PARTNER"
            ? "-"
            : masks.currency(data.approvedDiscount, "R$");
        },
      },
      {
        dataField: "status",
        dataType: "string",
        caption: "Status",
        alignment: "center",
        cellRender: ({ data }) => {
          const badge = getValueFromMap(
            creditConciliationStatusFileBadgeMap,
            data.status,
          );

          if (
            data.status === "CANCELED" &&
            data.errors &&
            data.errors.length > 0
          ) {
            return (
              <OTooltip type="warning" position="top" maxWidth="500px" floating>
                <div slot="tooltip-content">
                  {data.errors.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{error}</div>
                  ))}
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <StatusBadge
                    type={badge?.type ?? "danger"}
                    size="sm"
                    className="d-inline-block"
                  >
                    {badge?.label ?? ""}
                  </StatusBadge>
                  <OIcon
                    category="fa"
                    icon="fa-exclamation-triangle"
                    size="sm"
                    className="ml-1"
                    type="warning"
                  />
                </div>
              </OTooltip>
            );
          }
          if (badge) {
            return (
              <StatusBadge
                type={badge.type}
                size="sm"
                className="d-inline-block"
              >
                {badge.label}
              </StatusBadge>
            );
          }
          return data.status;
        },
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DownloadButton fileConciliationId={data.id} />
        ),
      },
    ],
  };
