import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTypography } from "@maestro/react";
import { useSimulation } from "../../../simulation/simulation.context";
import {
  ADDITIONAL_FEES_MODAL,
  AdditionalFeesModal,
} from "../../additional-fees-modal/additional-fees.component";
import { InfoCardRow } from "./result-infos.styles";

export interface ResultInfosProps {
  items?: { value?: string | number; label: string }[];
}

export const ResultInfos = ({ items }: ResultInfosProps) => {
  const { selectedSimulationResult } = useSimulation();
  const hasAdditionalFees =
    !!selectedSimulationResult?.additionalFees?.length &&
    !!selectedSimulationResult?.additionalFees?.some((a) => a.amount > 0);

  if (!items) return <div />;

  return (
    <>
      {hasAdditionalFees && (
        <AdditionalFeesModal
          additionalFees={selectedSimulationResult.additionalFees}
          currency={selectedSimulationResult.currency}
        />
      )}
      {items.map((item, index) => (
        <InfoCardRow
          key={`info-card-row-${item.label === "" ? index : item.label}`}
          className="d-flex flex-row justify-content-between py-2"
        >
          {item.label === "Despesas da operação" && hasAdditionalFees ? (
            <OButton
              key={`${item.label} item`}
              className="d-flex align-items-center cursor-pointer"
              onClick={() => modalManager.show(ADDITIONAL_FEES_MODAL)}
              outline
              bordered={false}
              type="dark"
            >
              <div className="d-flex gap-2">
                <OTypography
                  className="m-0"
                  tag="span"
                  key={`${item.label} item`}
                >
                  {item.label}
                </OTypography>
                <OIcon category="fal" icon="fa-chevron-right" />
              </div>
            </OButton>
          ) : (
            <OTypography
              key={`${item.label} item`}
              weight="300"
              className="mt-2"
              tag="span"
            >
              {item.label}
            </OTypography>
          )}
          <OTypography className="mt-2" tag="span" key={item.value}>
            {item.value}
          </OTypography>
        </InfoCardRow>
      ))}
    </>
  );
};
