import { useMemo, useState } from "react";
import { getPropertyLabel } from "../../../../../utils";
import { StepAnalysisStage } from "./step-analysis-stages.types";

export const useStepAnalysisStages = (
  step: HubEnergy.DiligenceStepAnalysisResponse,
) => {
  const stages = useMemo<StepAnalysisStage[]>(() => {
    const _stages: StepAnalysisStage[] = [];

    if (step.stepPendencies.length) {
      _stages.push({
        type: "PENDENCIAS_DA_ETAPA",
        key: "PENDENCIAS_DA_ETAPA",
        title: "Pendências da etapa",
      });
    }

    step.propertiesWithPendencies.sort((a, b) => a.id - b.id);

    step.propertiesWithPendencies.forEach((p) =>
      _stages.push({
        type: "PROPRIEDADE",
        propertyId: p.id,
        key: p.id,
        title: getPropertyLabel(p),
      }),
    );

    _stages.push({
      type: "RESUMO",
      key: "RESUMO",
      title: "Resumo",
    });

    return _stages;
  }, [step.propertiesWithPendencies, step.stepPendencies.length]);

  const [selectedStageIndex, setSelectedStageIndex] = useState(0);

  const selectedStage = useMemo(
    () => stages[selectedStageIndex],
    [selectedStageIndex, stages],
  );

  return {
    stages,
    selectedStage,
    selectedStageIndex,
    setSelectedStageIndex,
  };
};
