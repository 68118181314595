import { OLoader, OToastManager } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { exchangeQuotesGrid } from "../../../components/quotes/quotes.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.hubFx.adminCustomer.getQuotes().catch(() => {
    throw new Error("Erro ao buscar operações.");
  }),
);

export const ExchangeQuotesPage = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();

  const { mapSymbol } = useCurrencies();

  const { loading, callService } = useServiceCall(
    service.hubFx.postVerifyForthcomingCancellation,
  );

  const sendNotification = useCallback(async () => {
    const { success } = await callService({
      counterpartyIdentification: customer.identification,
    });

    if (!success)
      return OToastManager.danger(
        "Não foi possível enviar notificação, tente novamente.",
      );

    OToastManager.success("Notificação de cancelamento enviada com sucesso.");
  }, [callService, customer]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={
        <ButtonWithConfirmation
          confirmationModal={{
            title: "Confirma a notificação de cancelamento para o cliente?",
            confirmLabel: "Sim",
            closeLabel: "Não",
            divider: false,
            loading: loading,
            size: "xs",
          }}
          dataAction="cambio_cliente_operacoes:botao:notificar_cancelamento"
          dataLabel="notificar_cancelamento"
          className="align-self-end"
          disabled={loading}
          onConfirm={() => sendNotification()}
        >
          <div className="d-flex flex-row gap-2">
            {loading && <OLoader size="sm" />}
            Notificar cancelamento
          </div>
        </ButtonWithConfirmation>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeQuotesGrid(mapSymbol)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
