import { OBadge, OTypography } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { getValueFromMap } from "utils/get-value-from-map";
import { percent4DigitsMask } from "utils/mask";
import {
  operationStatusMap,
  operationTintMap,
} from "../../../../../operations.utils";
import { OperationDetail } from "./operation-details.types";
import { calculateRates } from "./operation-details.utils";

export const buildOperationDetails = (
  operation: OperationDetail,
): DetailsFields => {
  const { avgMonthlyRate, avgTermDays, totalRate } = calculateRates(
    operation.trades ?? [],
  );

  return {
    operation: {
      items: [
        {
          value: (
            <OBadge
              size="lg"
              type={
                getValueFromMap(operationTintMap, operation.status) ||
                "warning-light"
              }
            >
              {getValueFromMap(operationStatusMap, operation.status) ||
                operation.status}
            </OBadge>
          ),
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          value: `cód ${operation.id} | ${dayjs(operation.createdAt).format(
            "DD/MM/YYYY",
          )}`,
          grid: { sm: 6, md: 4, lg: 3 },
        },
      ],
    },
    rates: {
      items: [
        {
          label: "Prazo médio",
          value: `${avgTermDays.toFixed(2)} ${
            avgTermDays === 1 ? "dia" : "dias"
          }`,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Taxa média",
          value: `${percent4DigitsMask(avgMonthlyRate)} a.m.`,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Taxa total",
          value: percent4DigitsMask(totalRate),
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Conta desembolso",
          value: operation.bankAccount && (
            <div className="ps-2">
              <OTypography>
                {operation.bankAccount.accountNumber}-
                {operation.bankAccount.accountDigit}
              </OTypography>
              <OTypography size="sm">
                Agência {operation.bankAccount.agency} Banco{" "}
                {operation.bankAccount.bankCode}
              </OTypography>
            </div>
          ),
          grid: { sm: 6, md: 4, lg: 3 },
        },
      ],
    },
  };
};
