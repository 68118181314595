import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  companyContractTemplatesGrid,
} from "./company-contract-templates.grid";

export const CompanyContractTemplates = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();
  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(quickfinId), [quickfinId]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Templates de contrato" />}
      actions={
        <StandardLink
          href={
            corporateRouter.routes.recebiveis.customer.contractTemplates.add
              .path
          }
          role={roles.recebiveis.customer.contractTemplatesAddPage.role}
        >
          Adicionar template
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={companyContractTemplatesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
