import { OBadge, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { SettlementTradeOData } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  settlementTradeStatusLibrary,
  settlementTradeStatusTintMap,
} from "../settlements.utils";

export const settlementTradesGrid: ODataGridGeneratorConfig<SettlementTradeOData> =
  {
    datagrid: {
      noDataText: "Nenhum trade.",
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "SettlementTradeId",
        caption: "Id",
      },
      {
        dataField: "PaymentStatus",
        caption: "Situação",
        cellRender: ({ data }) => (
          <OBadge
            size="sm"
            rounded
            type={
              getValueFromMap(
                settlementTradeStatusTintMap,
                data.PaymentStatus,
              ) || "warning-light"
            }
          >
            {getValueFromMap(
              settlementTradeStatusLibrary,
              data.PaymentStatus,
            ) || data.PaymentStatus}
          </OBadge>
        ),
        lookup: {
          dataSource: Object.entries(settlementTradeStatusLibrary).map(
            ([key, value]) => ({
              text: value,
              value: key,
            }),
          ),
          valueExpr: "value",
          displayExpr: "text",
        },
        allowSorting: false,
      },
      {
        dataField: "PaymentAmount",
        caption: "Valor pagamento",
        dataType: "number",
        format: (value) => masks.currency(value, "R$"),
        allowSorting: false,
      },
      {
        dataField: "PayerName",
        caption: "Sacado",
        calculateCellValue: (data) =>
          `${data.PayerName} ${masks.cpfCnpj(data.PayerDocument)}`,
        cellRender: ({ data }) => (
          <div>
            <OTypography>{data.PayerName}</OTypography>
            <OTypography size="sm" type="dark-80">
              {masks.cpfCnpj(data.PayerDocument)}
            </OTypography>
          </div>
        ),
        allowSorting: false,
        calculateFilterExpression: (filterValue) => [
          ["PayerName", "contains", filterValue],
          "or",
          ["PayerDocument", "contains", filterValue.replace(/[^\w]/g, "")],
        ],
      },
      {
        dataField: "PayeeName",
        caption: "Cedente",
        calculateCellValue: (data) =>
          `${data.PayeeName} ${masks.cpfCnpj(data.PayeeDocument)}`,
        cellRender: ({ data }) => (
          <div>
            <OTypography>{data.PayeeName}</OTypography>
            <OTypography size="sm" type="dark-80">
              {masks.cpfCnpj(data.PayeeDocument)}
            </OTypography>
          </div>
        ),
        calculateFilterExpression: (filterValue) => [
          ["PayeeName", "contains", filterValue],
          "or",
          ["PayeeDocument", "contains", filterValue.replace(/[^\w]/g, "")],
        ],
        allowSorting: false,
      },
      {
        dataField: "PaymentError",
        caption: "Mensagem",
        cellRender: ({ data }) => (
          <OTypography size="sm">{data.PaymentError}</OTypography>
        ),
        allowSorting: false,
      },
    ],
  };
