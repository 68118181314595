import { OToastManager } from "@maestro/core";
import { useCallback, useMemo } from "react";
import { service } from "services";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { BatchPropertyField } from "./_compose/batch-field.component";
import { BooleanPropertyField } from "./_compose/boolean-field.component";
import { ConditionPropertyField } from "./_compose/condition-field.component";
import { FilePropertyField } from "./_compose/file-field.component";
import { FormPropertyField } from "./_compose/form-field.component";
import { GenericPropertyField } from "./_compose/generic-field.component";

interface ComponentPropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  formStepCardComponentId: number;
}

export const ComponentPropertyField = ({
  componentProperty,
  formStepCardComponentId: formStepCardComponentIdProp,
}: ComponentPropertyFieldProps) => {
  const handleSubmitComponentProperty = useCallback(
    async (
      formStepCardComponentId: number,
      propertyId: number,
      value: string,
    ) => {
      try {
        await service.hubCreditManager.updateComponentConfigProperty({
          formStepCardComponentId,
          value,
          propertyId,
        });
      } catch {
        OToastManager.danger("Não foi possível atualizar a propriedade.");
      }
    },
    [],
  );
  const fields = useMemo(() => {
    switch (componentProperty.type) {
      case "FORM":
        return (
          <FormPropertyField
            componentProperty={componentProperty}
            handleSubmitComponentProperty={handleSubmitComponentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );
      case "FILE":
        return (
          <FilePropertyField
            componentProperty={componentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );
      case "CONDITION":
        return (
          <ConditionPropertyField
            componentProperty={componentProperty}
            handleSubmitComponentProperty={handleSubmitComponentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );

      case "BOOLEAN":
        return (
          <BooleanPropertyField
            componentProperty={componentProperty}
            handleSubmitComponentProperty={handleSubmitComponentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );

      case "BATCH_TEMPLATE":
        return (
          <BatchPropertyField
            componentProperty={componentProperty}
            handleSubmitComponentProperty={handleSubmitComponentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );
      default:
        return (
          <GenericPropertyField
            componentProperty={componentProperty}
            handleSubmitComponentProperty={handleSubmitComponentProperty}
            formStepCardComponentId={formStepCardComponentIdProp}
          />
        );
    }
  }, [
    componentProperty,
    formStepCardComponentIdProp,
    handleSubmitComponentProperty,
  ]);

  return <>{fields}</>;
};
