import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { PropertyFieldComponent } from "./_compose/property-field.component";
import { useProcessorConfigPropertiesPageContent } from "./processor-config-properties.hook";

export const ProcessorConfigPropertiesPage = () => {
  const { form, propertiesDetails } = useProcessorConfigPropertiesPageContent();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Propriedades"
        />
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <div
            className="d-flex gap-4 flex-wrap justify-content-between align-items-center"
            key={propertiesDetails
              ?.map((prop) => prop.processorPropertyId)
              .join("")}
          >
            {propertiesDetails
              ?.sort((a, b) => a.processorPropertyId - b.processorPropertyId)
              .map((prop) => (
                <div
                  className="w-48"
                  key={`processor-config-property-${prop.processorPropertyId}`}
                >
                  <PropertyFieldComponent processorConfigProperty={prop} />
                </div>
              ))}
          </div>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
