import { ODataGridGeneratorConfig } from "components/data-grid";
import { DocumentsMasterDetail } from "./documents-card.master-detail";

interface GridProps {
  refetch: () => Promise<void>;
  canEdit: boolean;
}

export const grid = ({ refetch, canEdit }: GridProps) => {
  return {
    datagrid: {
      noDataText: "Nenhum documento",
      pager: {
        infoText: "Página {0} de {1} ({2} documentos)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      { dataField: "type", caption: "Tipo de documento" },
      {
        dataField: "errorMessage",
        caption: "Ocorrência",
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "files",
        caption: "Arquivos",
        alignment: "center",
        cellRender: ({ data }) => <span>{data.files?.length || 0}</span>,
      },
    ],
    masterDetail: {
      enabled: true,
      component: (data) => (
        <DocumentsMasterDetail
          items={data}
          refetch={refetch}
          canEdit={canEdit}
        />
      ),
    },
  } as ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.DocumentsItem>;
};
