import { OIcon } from "@maestro/react";
import { RouterLink } from "components/router-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

interface InstallmentsLinkProps {
  ownerId: string | undefined | null;
}

export const InstallmentsLink = ({ ownerId }: InstallmentsLinkProps) => {
  if (!ownerId) return null;

  return (
    <RouterLink
      href={corporateRouter.routes.banking.customer.overdraft.installments.path(
        {
          ownerId,
        },
      )}
      role={roles.banking.customer.overdraftInstallmentsPage.role}
    >
      <OIcon category="fas" icon="fa-external-link" className="me-2" />
      Ver histórico de parcelamentos
    </RouterLink>
  );
};
