import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  ProcessorOutput,
  ProcessorProperty,
} from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { WorkflowProdutoProcessadoresById } from "../../../../routes/workflow.route-params";

interface EditProcessorContextProps {
  processor: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getProcessorDetails>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getProcessorDetails>
      >["data"]
    >
  >;
  selectedOutput: ProcessorOutput | undefined;
  setSelectedOutput: React.Dispatch<
    React.SetStateAction<ProcessorOutput | undefined>
  >;
  selectedProperty: ProcessorProperty | undefined;
  setSelectedProperty: React.Dispatch<
    React.SetStateAction<ProcessorProperty | undefined>
  >;
  id: string;
}

export const EditProcessorContext = createContext(
  {} as EditProcessorContextProps,
);

interface EditProcessorProviderProps {
  children: ReactNode;
}

export const EditProcessorProvider = ({
  children,
}: EditProcessorProviderProps) => {
  const { id } = useParams<WorkflowProdutoProcessadoresById>();

  if (!id) throw new Error("No id");

  const [selectedOutput, setSelectedOutput] = useState<ProcessorOutput>();

  const [selectedProperty, setSelectedProperty] = useState<ProcessorProperty>();

  const processor = useServiceCall(
    service.hubCreditManager.getProcessorDetails,
  );

  const { hasError, callService: getProcessorDetails } = processor;

  useEffect(() => {
    hasError &&
      OToastManager.danger(
        "Não foi possível carregar os campos pré-configurados do Processador.",
      );
  }, [hasError]);

  useEffect(() => {
    getProcessorDetails(id);
  }, [getProcessorDetails, id]);

  const value = useMemo(
    () => ({
      id,
      processor,
      selectedOutput,
      setSelectedOutput,
      selectedProperty,
      setSelectedProperty,
    }),
    [processor, selectedOutput, selectedProperty, id],
  );

  return (
    <EditProcessorContext.Provider value={value}>
      {children}
    </EditProcessorContext.Provider>
  );
};

export const useEditProcessor = () => useContext(EditProcessorContext);
