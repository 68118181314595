import { OCollapse, OInputText, ORFieldSelectMultiple } from "@maestro/react";
import styled from "styled-components";

export const StyledCollapse = styled(OCollapse)`
  o-collapse-header {
    o-expander-header.o-collapse-header--type-light {
      padding: 1rem;
    }
  }
  o-collapse-content {
    o-expander-content.o-collapse-content--type-light {
      background-color: var(--theme-neutral-light);

      o-field .o-field o-field-error {
        .o-field-error {
          display: none;
        }
      }
    }
  }
`;

export const StyledInput = styled(OInputText)`
  o-input {
    margin: 0 1rem 1rem 1rem;
    width: auto;
    padding-top: 1rem;
  }
  .o-icon {
    padding-left: 0.5rem;
  }
  input#o-selector-search {
    background-color: white;
    padding: 1.5rem 0;
    padding-left: calc(var(--font-md) * var(--base-height));
  }
`;

export const StyledContent = styled.div`
  z-index: 1000;
  min-width: 100%;
  max-height: 20rem;
  height: auto;
  background-color: var(--theme-neutral-light);
  margin-top: 0;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--theme-neutral-light);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--theme-neutral-medium);
  }

  o-field .o-field o-field-error {
    .o-field-error {
      display: none;
    }
  }
`;

export const StyledMultipleSelector = styled(ORFieldSelectMultiple)`
  .o-select__header {
    display: none;
  }

  .o-select__option {
    position: relative;
    width: 100%;
    margin: 0;
    background-color: var(--theme-neutral-light);
    box-shadow: none;
    padding: 0 1rem 0 1rem;
  }

  o-option-multiple .o-option-multiple div {
    padding: 0.5rem;
  }
`;
