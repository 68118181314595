import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useCurrencies } from "hooks/currency/currency.hook";
import { ExchangeLimitResponse } from "services/hubfx/models/types/limit/limit.model";
import { ExchangePreTicketResponse } from "services/hubfx/models/types/pre-ticket/pre-ticket.model";
import { format } from "utils/date";
import { Nullable } from "utils/types/nullable";

export const exchangeLimitDetailsGenerator = (
  limit:
    | (ExchangeLimitResponse &
        Nullable<{
          preTicket: ExchangePreTicketResponse;
        }>)
    | null,
) => {
  if (!limit) return {} as DetailsFields;

  const { mapSymbol } = useCurrencies();

  const symbol = mapSymbol(limit.currency) ?? "";

  return {
    limit: {
      title: "Limite",
      items: [
        {
          label: "Id",
          value: limit.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. criação",
          value: format.dateTime(limit.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: limit.flowType,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Categoria",
          value: limit?.preTicket ? "PRÉ-TICKET" : "ANUAL",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: limit.currency,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    value: {
      title: "Valores",
      items: [
        {
          label: "Vr. Disponivel",
          value: masks.currency(limit.availableAmount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. Total",
          value: masks.currency(limit.totalAmount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
