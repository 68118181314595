import { OTypography } from "@maestro/react";

type CellRenderFieldsType = {
  fields: string[];
};

export const CellRenderFields = ({ fields }: CellRenderFieldsType) => {
  return (
    <span className="d-flex flex-column gap-2">
      {fields.map((item, index) => {
        if (!index) return <span key={item}>{item}</span>;

        return (
          <OTypography key={item} tag="span" type="default">
            {item}
          </OTypography>
        );
      })}
    </span>
  );
};
