import { useEffect, useRef, useState } from "react";

export const useDebouncedMemo = <T>(
  factory: () => T,
  delay: number,
  deps: React.DependencyList,
) => {
  const [value, setValue] = useState(factory());
  const previousDeps = useRef<React.DependencyList>(deps);
  const timeoutId = useRef<NodeJS.Timeout>();

  if (
    deps.length !== previousDeps.current.length ||
    deps.some((currVal, idx) => currVal !== previousDeps.current[idx])
  ) {
    previousDeps.current = deps;
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      setValue(factory());
      timeoutId.current = undefined;
    }, delay);
  }

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return value;
};
