import { getValueFromMap } from "utils/get-value-from-map";
import { logger } from "utils/logger";
import { buildSegment, enumerateWithOu } from "./helpers";

const eventTypeRuleTextMap = {
  PENDENCY_CHANGED_STATUS: (rule) => {
    let text = "";

    text += " pendência";
    text += " " + buildSegment("do_tipo", rule.pendencyTypes);
    if (rule.stepTypes?.length || rule.stepStatuses?.length) {
      text += " em step";
      text += " " + buildSegment("do_tipo", rule.stepTypes);
      text += " " + buildSegment("com_status", rule.stepStatuses);
    }
    text += " mudar de status";
    if (rule.pendencyPreviousStatuses?.length) {
      text += " de";
      text += " " + enumerateWithOu(rule.pendencyPreviousStatuses);
    }
    if (rule.pendencyStatuses?.length) {
      text += " para";
      text += " " + enumerateWithOu(rule.pendencyStatuses);
    }

    return text;
  },
  PENDENCY_CREATED: (rule) => {
    let text = "";

    text += " pendência";
    text += " for criada";
    text += " " + buildSegment("com_tipo", rule.pendencyTypes);
    text += " " + buildSegment("com_status", rule.pendencyStatuses);
    if (rule.stepTypes?.length || rule.stepStatuses?.length) {
      text += " em step";
      text += " " + buildSegment("do_tipo", rule.stepTypes);
      text += " " + buildSegment("com_status", rule.stepStatuses);
    }

    return text;
  },
  PROPERTY_MODIFIED: (rule) => {
    let text = "";

    text += " propriedade";
    text += " " + buildSegment("do_tipo", rule.propertyTypes);
    if (rule.documentTypes?.length) {
      text += " com documento";
      text += " " + buildSegment("do_tipo", rule.documentTypes);
    }
    if (rule.stepTypes?.length || rule.stepStatuses?.length) {
      text += " em step";
      text += " " + buildSegment("do_tipo", rule.stepTypes);
      text += " " + buildSegment("com_status", rule.stepStatuses);
    }
    text += " for modificada";

    return text;
  },
  STEP_CHANGED_STATUS: (rule) => {
    let text = "";

    text += " step";
    text += " " + buildSegment("do_tipo", rule.stepTypes);
    text += " mudar de status";
    if (rule.stepPreviousStatuses?.length) {
      text += " de";
      text += " " + enumerateWithOu(rule.stepPreviousStatuses);
    }
    if (rule.stepStatuses?.length) {
      text += " para";
      text += " " + enumerateWithOu(rule.stepStatuses);
    }

    return text;
  },
  STEP_CREATED: (rule) => {
    let text = "";

    text += " step";
    text += " for criado";
    text += " " + buildSegment("com_tipo", rule.stepTypes);
    text += " " + buildSegment("com_status", rule.stepStatuses);

    return text;
  },
} as const satisfies Record<
  HubEnergy.EDiligenceEventType,
  (rule: HubEnergy.DiligenceEventRuleSimpleResponse) => string
>;

export const ruleTextBuilder = (
  rule: HubEnergy.DiligenceEventRuleSimpleResponse,
) => {
  let text = "Quando";

  const ruleText = getValueFromMap(
    eventTypeRuleTextMap,
    rule.eventType,
  )?.(rule);

  if (!ruleText) {
    logger.error(`Unexpected DiligenceEventRule eventType: ${rule.eventType}`);
    return JSON.stringify(rule);
  }

  text += " " + ruleText;

  return text.trim().replace(/\s+/g, " ");
};
