import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  {
    value: "Base30",
    label: "Base 30",
  },
  {
    value: "Base360",
    label: "Base 360",
  },
  {
    value: "Base30Slash360",
    label: "Base 30/360",
  },
];

interface InterestArrearsBaseFieldProps {
  required?: boolean;
  disabled?: boolean;
}

export const InterestArrearsBaseField = ({
  required,
  disabled,
}: InterestArrearsBaseFieldProps) => {
  return (
    <OCol xs={12} md={12}>
      <ORFieldSelect
        dataAction="geral:select:base_mora"
        dataLabel="base_mora"
        id="interestArrearsBase"
        name="interestArrearsBase"
        label={EstrategiaFormLabels.interestArrearsBase}
        placeholder="Selecionar"
        required={required}
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
