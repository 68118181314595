import { ODataGridGeneratorConfig } from "components/data-grid";
import { Collateral } from "../../imovel-additional-data.types";

export const registriesGrid = {
  datagrid: {
    noDataText: "Nenhum cadastro de propriedade foi encontrado.",
  },
  columns: [
    {
      caption: "Cartório",
      dataField: "registryFirmName",
    },
    {
      caption: "CNPJ",
      dataField: "registryFirmTaxId",
    },
    {
      caption: "Identificação",
      dataField: "registryFirmTaxId",
    },
    {
      caption: "Data de registro",
      dataField: "registryDate",
    },
    {
      caption: "Protocolo",
      dataField: "protocol",
    },
    {
      caption: "Cidade",
      dataField: "city",
    },
    {
      caption: "Bairro",
      dataField: "neighborhood",
    },
    {
      caption: "Estado",
      dataField: "state",
    },
    {
      caption: "Comarca",
      dataField: "county",
    },
    {
      caption: "Endereço",
      dataField: "address",
    },
    {
      caption: "País",
      dataField: "country",
    },
    {
      caption: "CEP",
      dataField: "zipCode",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Collateral["properties"][number]["registries"][number]
>;
