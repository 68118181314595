import { OButton, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { energiaRoles } from "../../../../../../../../roles/energia.roles";
import { useDiligenceStep } from "../../diligence-step.context";
import { buildDataSource, stepPendenciesGrid } from "./step-pendencies.grid";
import { mapPropertyLabels } from "./step-pendencies.utils";

export const StepPendencies = () => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const {
    pendencyTypesCallService,
    pendencyTypesHasError,
    pendencyTypes,
    stepId,
    propertiesValue,
  } = useDiligenceStep();

  const canEditPendency = hasRole(
    energiaRoles.customer.migrationStepsAddPendency.role,
  );

  const grid = useMemo(
    () =>
      stepPendenciesGrid(
        canEditPendency,
        pendencyTypes,
        mapPropertyLabels(propertiesValue?.response ?? []),
      ),
    [canEditPendency, pendencyTypes, propertiesValue?.response],
  );
  const dataSource = useMemo(() => buildDataSource(stepId), [stepId]);

  return (
    <GridTemplate
      pageTitle={
        <OTypography type="dark" size="lg">
          Pendências
        </OTypography>
      }
      actions={
        <>
          {pendencyTypesHasError && (
            <IconButton
              icon={{
                category: "orq",
                icon: "orq-status-warning",
              }}
              type="danger"
              onClick={pendencyTypesCallService}
            >
              Buscar tipos de pendência
            </IconButton>
          )}
          {hasRole(energiaRoles.customer.diligenceStepsAdd.role) && (
            <OButton onClick={() => gridRef.current?.instance.addRow()}>
              Adicionar
            </OButton>
          )}
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
