import { OButton, OFilterFooter } from "@maestro/react";

interface Props {
  handleClear: CallableFunction;
  handleFilter: CallableFunction;
  handleClose: CallableFunction;
}

export const FilterFooter = ({
  handleClear,
  handleFilter,
  handleClose,
}: Props) => {
  return (
    <OFilterFooter>
      <div className="d-flex justify-content-end">
        <OButton
          onClick={() => {
            handleClear();
          }}
          outline
          type="primary"
          bordered={false}
          dataLabel="statement-clear-button"
          dataAction="statement-clear-button"
        >
          Limpar
        </OButton>
        <OButton
          onClick={async () => {
            await handleFilter();
            handleClose();
          }}
          dataLabel="statement-filter-button"
          dataAction="statement-filter-button"
        >
          Filtrar
        </OButton>
      </div>
    </OFilterFooter>
  );
};
