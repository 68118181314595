import { client } from "../../client";
import { corbanEndpoints } from "../corban.endpoints";
import {
  GetSalesforceCustomersParams,
  GetSalesforceCustomersResponse,
  GetSalesforceOperatorParams,
  GetSalesforceOperatorResponse,
  GetSalesforceOriginatorResponse,
} from "./models";

export const corbanSalesforceService = {
  customers(params: GetSalesforceCustomersParams) {
    return client.get<GetSalesforceCustomersResponse>(
      corbanEndpoints.salesforce.customers,
      { params },
    );
  },
  operators(params: GetSalesforceOperatorParams) {
    return client.get<GetSalesforceOperatorResponse>(
      corbanEndpoints.salesforce.operators,
      { params },
    );
  },
  originator() {
    return client.get<GetSalesforceOriginatorResponse>(
      corbanEndpoints.salesforce.originator,
    );
  },
};
