import { client } from "../client";
import {
  PatchNotificationConfigByNameBody,
  PostNotificationConfigBody,
  PostNotificationConfigOdataBody,
  PostNotificationsAdminOdataBody,
  PostNotificationsDownloadEmailBody,
  PostFilterTableJobsBody,
  PostJobsRunJobBody,
  PostJobsStopJobBody,
  PostNotificationConfigurationBody,
  UpdateNotificationConfigurationBody,
  PostNotificationTypeBody,
} from "./models/requests";
import {
  DeleteNotificationConfigByNameResponse,
  GetNotificationConfigByNameResponse,
  PatchNotificationConfigByNameResponse,
  PostNotificationConfigOdataResponse,
  PostNotificationConfigResponse,
  PostNotificationsAdminOdataResponse,
  PostNotificationsDownloadEmailResponse,
  NotificationConfigurationOdataResponse,
  NotificationTypeOdataResponse,
  PostFilterTableJobsResponse,
  PostJobsRunJobResponse,
  PostJobsStopJobResponse,
} from "./models/responses";
import { ravenEndpoints } from "./raven.endpoints";

export const ravenService = {
  getNotifications(body: PostNotificationsAdminOdataBody) {
    return client.post<PostNotificationsAdminOdataResponse>(
      ravenEndpoints.notifications.adminOdata,
      body,
    );
  },
  addNotificationConfig(body: PostNotificationConfigBody) {
    return client.post<PostNotificationConfigResponse>(
      ravenEndpoints.notificationConfig.endpoint,
      body,
    );
  },
  getNotificationConfig(body: PostNotificationConfigOdataBody) {
    return client.post<PostNotificationConfigOdataResponse>(
      ravenEndpoints.notificationConfig.odata,
      body,
    );
  },
  updateNotificationByName(
    name: string,
    body: PatchNotificationConfigByNameBody,
  ) {
    return client.patch<PatchNotificationConfigByNameResponse>(
      ravenEndpoints.notificationConfig.byName(name),
      body,
    );
  },
  getNotificationByName(name: string) {
    return client.get<GetNotificationConfigByNameResponse>(
      ravenEndpoints.notificationConfig.byName(name),
    );
  },
  deleteNotificationByName(name: string) {
    return client.delete<DeleteNotificationConfigByNameResponse>(
      ravenEndpoints.notificationConfig.byName(name),
    );
  },
  downloadEmail(body: PostNotificationsDownloadEmailBody) {
    return client.post<PostNotificationsDownloadEmailResponse>(
      ravenEndpoints.notifications.downloadEmail,
      body,
    );
  },
  getAllNotificationTypes() {
    return client.get<{ data: NotificationTypeOdataResponse[] }>(
      ravenEndpoints.notificationTypes.endpoint,
    );
  },
  getNotificationTypeById(id: string) {
    return client.get<{ data: NotificationTypeOdataResponse }>(
      ravenEndpoints.notificationTypes.byId(id),
    );
  },
  addNotificationType(body: PostNotificationTypeBody) {
    return client.post<{ data: NotificationTypeOdataResponse }>(
      ravenEndpoints.notificationTypes.endpoint,
      body,
    );
  },
  deleteNotificationType(id: string) {
    return client.delete<{ data: NotificationTypeOdataResponse }>(
      ravenEndpoints.notificationTypes.byId(id),
    );
  },
  updateNotificationTypeById(id: string, body: PostNotificationTypeBody) {
    return client.patch<{ data: NotificationTypeOdataResponse }>(
      ravenEndpoints.notificationTypes.byId(id),
      body,
    );
  },
  getNotificationConfigurationById(id: string) {
    return client.get<{ data: NotificationConfigurationOdataResponse }>(
      ravenEndpoints.notification_configurations.byId(id),
    );
  },
  getNotificationConfigurationsByType(id: string) {
    return client.get<{ data: NotificationConfigurationOdataResponse[] }>(
      ravenEndpoints.notification_configurations.getNotificationConfigurationsbyType(
        id,
      ),
    );
  },
  addNotificationConfiguration(body: PostNotificationConfigurationBody) {
    return client.post<{ data: NotificationConfigurationOdataResponse }>(
      ravenEndpoints.notification_configurations.endpoint,
      body,
    );
  },
  deleteNotificationConfiguration(id: string) {
    return client.delete<{ data: NotificationConfigurationOdataResponse }>(
      ravenEndpoints.notification_configurations.byId(id),
    );
  },
  updateNotificationConfigurationById(
    id: string,
    body: UpdateNotificationConfigurationBody,
  ) {
    return client.patch<{ data: NotificationConfigurationOdataResponse }>(
      ravenEndpoints.notification_configurations.byId(id),
      body,
    );
  },
  getJobs(body: PostFilterTableJobsBody) {
    return client.post<PostFilterTableJobsResponse>(
      ravenEndpoints.jobs.endpoint,
      body,
    );
  },
  runJobs(body: PostJobsRunJobBody) {
    return client.post<PostJobsRunJobResponse>(
      ravenEndpoints.jobs.runJob,
      body,
    );
  },
  stopJobs(body: PostJobsStopJobBody) {
    return client.post<PostJobsStopJobResponse>(
      ravenEndpoints.jobs.stopJob,
      body,
    );
  },
};
