import { ODataGridGeneratorConfig } from "components/data-grid";
import { PreticketPendency } from "services/hubfx/models/responses";

export const exchangePreTicketPendenciesGrid = {
  datagrid: {
    noDataText: "Nenhuma pendência",
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "type",
      caption: "Tipo de pendência",
    },
    {
      dataField: "typeName",
      caption: "Campo",
    },
    {
      dataField: "message",
      caption: "Descrição",
      cssClass: "dx-word-wrap",
      cellRender: ({ data }) => (
        <>
          <p>{data.message}</p>
          <p>{data.description}</p>
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<PreticketPendency>;
