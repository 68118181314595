import { OBadge, OIcon } from "@maestro/react";
import { ISize } from "@maestro/core";
import { biggerSizeMap, iconMap } from "./status-badge.utils";

export interface StatusBadgeProps {
  type: "info" | "success" | "warning" | "danger";
  size?: ISize;
  className?: string;
  children: React.ReactNode;
}

export const StatusBadge = ({
  type,
  size = "sm",
  className,
  children,
}: StatusBadgeProps) => {
  const iconProps = iconMap[type];

  const iconSize = biggerSizeMap[size];

  return (
    <OBadge rounded type={`${type}-light`} size={size} className={className}>
      <OIcon {...iconProps} size={iconSize} />
      {children}
    </OBadge>
  );
};
