import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { productsMap } from "../../../../antecipacao-de-cartoes/pages/produto/enquadramento-carteira/cedentes/cedente-limits.utils";
import { EstrategiaComercial } from "./estrategia-templates.types";
import { GridActions } from "./_compose";

export const dataSource = dataSourceCustomStoreGenerator<EstrategiaComercial>(
  () =>
    service.quickfin
      .getEstrategias()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage =
          "Erro ao buscar os templates de estratégia comercial";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const estrategiasComerciaisGrid: ODataGridGeneratorConfig<EstrategiaComercial> =
  {
    datagrid: {
      noDataText: "Nenhum template de estratégia comercial",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "nome",
        caption: "Nome",
      },
      {
        dataField: "uniqueId",
        caption: "Identificador",
      },
      {
        dataField: "tipoProduto",
        caption: "Tipo do produto",
        customizeText: ({ value }) =>
          getValueFromMap(productsMap, value) ?? value,
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <GridActions
            estrategia={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
      },
    ],
  };
