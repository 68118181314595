import {
  OBannerAction,
  OBannerBody,
  OBannerContent,
  OBannerInfo,
  OButton,
  OImage,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBanner } from "../../banner-management.hook";
import { StyledOBadge, StyledOBanner } from "./banner-preview.styles";
import { BannerPreviewProps } from "./banner-preview.types";
import { BannerSkeleton } from "./banner-skeleton/banner-skeleton.component";

export const BannerPreview = ({
  form,
  image,
  data,
  bannerTemplate,
}: BannerPreviewProps) => {
  const navigate = useNavigate();

  const { actionRef, actionText, imageAlt, imageFile, openInNewTab } =
    bannerTemplate ?? {};
  const { key, name } = imageFile ?? {};

  const { imageUrl } = useBanner({ fileKey: key, fileName: name });

  const renderImage = useMemo(() => {
    return (
      <OImage
        key={image ?? imageUrl}
        src={image ?? imageUrl}
        alt={imageAlt ?? ""}
        width="400px"
        height="260px"
        objectFit="cover"
      />
    );
  }, [image, imageAlt, imageUrl]);

  const renderSkeleton = () => <BannerSkeleton />;

  if (!imageUrl) return renderSkeleton();

  const getDescription = (
    variables: Record<string, any>,
    templateDescription?: string,
  ) => {
    return templateDescription
      ? templateDescription
          .replace(
            "[[temaPrincipal]]",
            variables.temaPrincipal || "[tema principal]",
          )
          .replace("[[data]]", variables.data || "[data]")
          .replace("[[hora]]", variables.hora || "[hora]")
      : "";
  };

  const renderBannerContent = ({
    title,
    badge,
    description,
  }: {
    title: string;
    badge?: string;
    description: string;
  }) => (
    <>
      <OBannerBody>
        <OBannerInfo>
          <StyledOBadge key={badge} type="light" size="sm" rounded>
            {badge || "[Produto]"}
          </StyledOBadge>
        </OBannerInfo>
        <OBannerContent key={description} title={title} size="md" limitedSize>
          {description}
        </OBannerContent>
        <OBannerAction>
          <OButton
            outline
            onClick={() =>
              openInNewTab
                ? window.open(actionRef, "_blank")
                : navigate(actionRef ?? "")
            }
          >
            <OTypography type="dark">{actionText}</OTypography>
          </OButton>
        </OBannerAction>
      </OBannerBody>
      {renderImage}
    </>
  );

  if (data) {
    const {
      title,
      badge,
      variables,
      bannerTemplate: bannerTemplateData,
    } = data;
    const parsedVariables = JSON.parse(variables ?? "{}") as Record<
      string,
      string
    >;
    const descriptionShown = getDescription(
      parsedVariables,
      bannerTemplateData?.description,
    );

    return (
      <StyledOBanner orientation="horizontal">
        {renderBannerContent({
          title: title || "[Nome do evento]",
          badge,
          description: descriptionShown,
        })}
      </StyledOBanner>
    );
  }

  if (!form) return renderSkeleton();

  const { getValues } = form;
  const values = getValues();

  const temaPrincipalValue = values["temaPrincipal" as keyof typeof values];
  const dataValue = values["data" as keyof typeof values];
  const horaValue = values["hora" as keyof typeof values];

  const descriptionShown = getDescription(
    {
      temaPrincipal: temaPrincipalValue,
      data: dataValue,
      hora: horaValue,
    },
    bannerTemplate?.description,
  );

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Aplicação do banner</OTypography>
      <StyledOBanner orientation="horizontal">
        {renderBannerContent({
          title: values?.title || "[Nome do evento]",
          badge: values?.badge,
          description: descriptionShown,
        })}
      </StyledOBanner>
    </div>
  );
};
