import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiEmailConfigsTypeResponseItem } from "services/clerk";

export const dataSource =
  dataSourceCustomStoreGenerator<GetApiEmailConfigsTypeResponseItem>(() =>
    service.clerk
      .showEmailConfigsType()
      .then(({ data }) => data ?? [])
      .catch(() => {
        const validator = "Erro ao buscar tipos de convite.";
        OToastManager.danger(validator);
        throw new Error(validator);
      }),
  );

export const inviteTypesGrid: ODataGridGeneratorConfig<
  Awaited<ReturnType<typeof service.clerk.showEmailConfigsType>>["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum tipo de convite",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, component }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Detalhes",
              role: roles.ferramentas.product
                .onboardingInviteConfigsInviteTypesDetailsPage.role,
              route:
                corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs.inviteTypes.details.path(
                  { id: data.id },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              role: roles.ferramentas.product
                .onboardingInviteConfigsInviteTypesRemovePage.role,
              onClick: () => {
                try {
                  service.clerk.deleteEmailConfigsTypeById({ id: data.id });
                  component.getDataSource().reload();
                  OToastManager.success(
                    "Tipo de convite removido com sucesso!",
                  );
                } catch {
                  OToastManager.danger("Erro ao remover tipo de convite.");
                }
              },
            },
          ]}
        />
      ),
    },
  ],
};
