import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";

export const ErrorReportModal = () => {
  const navigate = useNavigate();
  const handleConfirm = () => {
    navigate(
      corporateRouter.routes.recebiveis.product.batchReports.requestForReports
        .path,
    );
    modalManager.hide("cancel-operation-modal");
  };

  return (
    <OConfirmationModal
      modalId="error-report-modal"
      title="O arquivo não foi gerado"
      onConfirm={handleConfirm}
      confirmLabel="Gerar novo arquivo"
      closeLabel="Voltar"
      className="position-absolute"
    >
      <OTypography size="md">
        O arquivo não foi gerado, pois não encontramos resultados na busca
        solicitada. Por favor, faça uma nova busca, caso queira gerar um novo
        arquivo.
      </OTypography>
    </OConfirmationModal>
  );
};
