import * as yup from "yup";

export interface DisparoManualFormValues {
  installment: number;
  content: string;
  metadata?: string;
  userName?: string;
  userReceiver: string;
  template: string;
  subtitle: string;
  title: string;
  operationId: string;
  taxId: string;
  file?: File[];
}

const REQUIRED_MESSAGE = "Campo obrigatório";

export const disparoManualValidationSchema: yup.ObjectSchema<DisparoManualFormValues> =
  yup.object({
    installment: yup.number().required(REQUIRED_MESSAGE),
    content: yup.string().required(REQUIRED_MESSAGE),
    metadata: yup.string().test("is-json", "Formato inválido", (value) => {
      if (!value) return true;
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
    userName: yup.string(),
    userReceiver: yup
      .string()
      .email("E-mail inválido")
      .required(REQUIRED_MESSAGE),
    template: yup.string().required(REQUIRED_MESSAGE),
    subtitle: yup.string().required(REQUIRED_MESSAGE),
    title: yup.string().required(REQUIRED_MESSAGE),
    operationId: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .transform((x) => x.replace(/\s/g, "")),
    taxId: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .transform((x) => x.replace(/\D/g, "")),
    file: yup.array(),
  });
