import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { stepTypeFormFields } from "./step-type-form.form";

export const StepTypeFormComponent = () => {
  return (
    <>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...stepTypeFormFields.name} />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...stepTypeFormFields.label} />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...stepTypeFormFields.description} />
        </OCol>
      </ORow>
    </>
  );
};
