import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { AdminCustomerACCContractsPage } from "../pages/cliente/contratos/admin-customer-acc-contracts.page";
import { AdminCustomerACCCounterpartiesDetailsPage } from "../pages/cliente/detalhes/admin-customer-acc-counterparties-details.page";
import { AdminCustomerACCSettlementDetailsPage } from "../pages/cliente/liquidacoes/[id]";
import { AdminCustomerACCSettlementsPage } from "../pages/cliente/liquidacoes/admin-customer-acc-settlements.page";
import { AdminCustomerACCTradesPage } from "../pages/cliente/operacoes";
import { AdminCustomerACCTradesDetailsPage } from "../pages/cliente/operacoes/[id]/admin-customer-acc-trades-details.page";
import { AdminCustomerACCOrderDetailsPage } from "../pages/cliente/ordem/admin-customer-acc-order-details.page";
import { AdminCustomerACCProposalsDetailsPage } from "../pages/cliente/propostas/[id]/admin-customer-acc-proposals-details.page";
import { AdminCustomerACCProposalsPage } from "../pages/cliente/propostas/admin-client-acc-proposals.page";
import { AdminContingencyACCUpdateGenericFields } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/[id]/admin-contingency-acc-update-generic-fields.page";
import { AdminContingencyACCCreateGenericFields } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/adicionar/admin-contingency-acc-create-generic-fields.page";
import { AdminContingencyACCUpdateDocumentConfig } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/[id]/admin-contingency-acc-update-document-configs.page";
import { AdminContingencyACCCreateDocumentConfig } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/adicionar/admin-contingency-acc-create-document-configs.page";
import { AdminContingencyACCDocumentConfigsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/admin-contingency-acc-document-configs.page";
import { AdminContingencyACCUpdateDocumentGroup } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/[id]/admin-contingency-acc-update-document-group.page";
import { ContingencyACCCreateDocumentGroup } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/adicionar/admin-contingency-acc-create-document-group.page";
import { AdminContingencyACCUpdateNature } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/[id]/admin-contingency-acc-update-nature.page";
import { AdminContingencyACCCreateNature } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/adicionar/admin-contingency-acc-create-nature.page";
import { AdminContingencyACCNaturesPage } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/admin-contingency-acc-natures.page";
import { AdminContingencyACCCommonDomainPage } from "../pages/produto/contingencia/variaveis-configuracao/admin-contingency-acc-common-domain.page";
import { AdminProductACCAddCounterpartyPage } from "../pages/produto/contrapartes/adicionar/admin-product-acc-add-counterparty.page";
import { AdminProductACCCounterpartiesPage } from "../pages/produto/contrapartes/admin-product-acc-counterparties.page";
import { AdminProductACCPreticketCreatePage } from "../pages/produto/liquidacoes/adicionar/admin-product-settlements-create.page";
import { AdminProductACCSettlementsPage } from "../pages/produto/liquidacoes/admin-product-acc-settlements.page";
import { AdminProductACCTradesPage } from "../pages/produto/operacoes/admin-product-acc-trades.page";
import { AdminProductACCProposalsPage } from "../pages/produto/propostas/admin-product-acc-proposals.page";
import { accRoles } from "../roles/acc.roles";
import { accRouteParams } from "./acc.route-params";
import { AdminContingencyACCDocumentGroupsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/admin-contingency-acc-document-groups.page";
import { AdminContingencyACCPreticketCreatePage } from "../pages/produto/contingencia/liquidacoes/adicionar/admin-contingency-acc-settlements-create.page";
import { AdminContingencyACCSettlementEditPage } from "../pages/produto/contingencia/liquidacoes/editar/admin-contingency-acc-settlements-edit.page";
import { AdminContingencyACCSettlementsPage } from "../pages/produto/contingencia/liquidacoes/admin-contingency-acc-settlements.page";
import { AdminCustomerACCSettlementSimulationPage } from "../pages/cliente/liquidacoes/[id]/[preTicketId]";
import { AdminContingencyACCGenericFieldsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/admin-contingency-acc-generic-field.page";

export const accRoutes = {
  customer: {
    name: "",
    path: "/acc/cliente",
    element: <RedirectWithRoles />,
    children: {
      proposals: {
        name: "Propostas",
        path: "/acc/cliente/propostas",
        element: <AdminCustomerACCProposalsPage />,
        role: accRoles.customer.proposalsPage.role,
        children: {
          details: {
            name: "Simular",
            path: `/acc/cliente/propostas/:${accRouteParams.id}`,
            element: <AdminCustomerACCProposalsDetailsPage />,
            role: accRoles.customer.proposalsDetailsPage.role,
          },
        },
      },
      details: {
        name: "Detalhes",
        path: "/acc/cliente/detalhes",
        element: <AdminCustomerACCCounterpartiesDetailsPage />,
        role: accRoles.customer.counterpartyDetailsPage.role,
      },
      settlements: {
        name: "Liquidações",
        path: "/acc/cliente/liquidacoes",
        element: <AdminCustomerACCSettlementsPage />,
        role: accRoles.customer.settlementsPage.role,
        children: {
          details: {
            name: "Detalhes da liquidação",
            path: `/acc/cliente/liquidacoes/:${accRouteParams.id}`,
            element: <AdminCustomerACCSettlementDetailsPage />,
            role: accRoles.customer.settlementsDetailsPage.role,
            children: {
              order: {
                name: "Ordem",
                path: `/acc/cliente/liquidacoes/:${accRouteParams.id}/ordem/:${accRouteParams.orderId}`,
                element: <AdminCustomerACCOrderDetailsPage />,
                role: accRoles.customer.orderDetailsPage.role,
              },
              simulate: {
                name: "Simulação da liquidação",
                path: `/acc/cliente/liquidacoes/:${accRouteParams.id}/simulacao/:${accRouteParams.preTicketId}`,
                element: <AdminCustomerACCSettlementSimulationPage />,
                role: accRoles.customer.simulationDetailsPage.role,
              },
            },
          },
        },
      },
      Contracts: {
        name: "Contratos",
        path: "/acc/cliente/contratos",
        element: <AdminCustomerACCContractsPage />,
        role: accRoles.customer.contractsPage.role,
      },
      quotes: {
        name: "Operações",
        path: "/acc/cliente/operacoes",
        element: <AdminCustomerACCTradesPage />,
        role: accRoles.customer.tradesPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/acc/cliente/operacoes/:${accRouteParams.id}`,
            element: <AdminCustomerACCTradesDetailsPage />,
            role: accRoles.customer.tradesDetailsPage.role,
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/acc/produto",
    element: <RedirectWithRoles />,
    children: {
      proposals: {
        name: "Propostas",
        path: "/acc/produto/propostas",
        element: <AdminProductACCProposalsPage />,
        role: accRoles.product.proposalsPage.role,
      },
      quotes: {
        name: "Operações",
        path: "/acc/produto/operacoes",
        element: <AdminProductACCTradesPage />,
        role: accRoles.product.tradesPage.role,
      },
      settlements: {
        name: "Liquidações",
        path: "/acc/produto/liquidacoes",
        element: <AdminProductACCSettlementsPage />,
        role: accRoles.product.settlementsPage.role,
        children: {
          create: {
            name: "Adicionar",
            path: "/acc/produto/liquidacoes/adicionar",
            element: <AdminProductACCPreticketCreatePage />,
            role: accRoles.product.settlementsFormPage.role,
          },
        },
      },
      counterparties: {
        name: "Contrapartes",
        path: "/acc/produto/contrapartes",
        element: <AdminProductACCCounterpartiesPage />,
        role: accRoles.product.counterpartiesPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: "/acc/produto/contrapartes/adicionar",
            element: <AdminProductACCAddCounterpartyPage />,
            role: accRoles.product.counterpartiesAddPage.role,
          },
        },
      },
      contingency: {
        name: "Contingência",
        path: "/acc/produto/contingencia",
        hasSubItems: true,
        children: {
          commonDomain: {
            name: "Variáveis de Configuração",
            path: "/acc/produto/contingencia/variavies-configuracao",
            element: <AdminContingencyACCCommonDomainPage />,
            role: accRoles.product.contingencyCommonDomainPage.role,
          },
          natures: {
            name: "Cadastro de Naturezas",
            path: "/acc/produto/contingencia/cadastro-de-naturezas",
            hasSubItems: true,
            children: {
              all: {
                name: "Naturezas",
                path: "/acc/produto/contingencia/cadastro-de-naturezas/naturezas",
                element: <AdminContingencyACCNaturesPage />,
                role: accRoles.product.contingencyNaturesAllPage.role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/acc/produto/contingencia/cadastro-de-naturezas/naturezas/adicionar",
                    element: <AdminContingencyACCCreateNature />,
                    role: accRoles.product.contingencyNaturesAllCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/acc/produto/contingencia/cadastro-de-naturezas/naturezas/:${accRouteParams.id}`,
                    element: <AdminContingencyACCUpdateNature />,
                    role: accRoles.product.contingencyNaturesAllUpdatePage.role,
                  },
                },
              },
              documentGroups: {
                name: "Grupo de Documentos",
                path: "/acc/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos",
                element: <AdminContingencyACCDocumentGroupsPage />,
                role: accRoles.product.contingencyNaturesDocumentGroupsPage
                  .role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/acc/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/adicionar",
                    element: <ContingencyACCCreateDocumentGroup />,
                    role: accRoles.product
                      .contingencyNaturesDocumentGroupsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/acc/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/:${accRouteParams.id}`,
                    element: <AdminContingencyACCUpdateDocumentGroup />,
                    role: accRoles.product
                      .contingencyNaturesDocumentGroupsUpdatePage.role,
                  },
                },
              },
              documentConfigs: {
                name: "Configuração de Documentos",
                path: "/acc/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos",
                element: <AdminContingencyACCDocumentConfigsPage />,
                role: accRoles.product.contingencyNaturesDocumentConfigsPage
                  .role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/acc/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/adicionar",
                    element: <AdminContingencyACCCreateDocumentConfig />,
                    role: accRoles.product
                      .contingencyNaturesDocumentConfigsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/acc/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/:${accRouteParams.id}`,
                    element: <AdminContingencyACCUpdateDocumentConfig />,
                    role: accRoles.product
                      .contingencyNaturesDocumentConfigsUpdatePage.role,
                  },
                },
              },
              genericFields: {
                name: "Campos Genéricos",
                path: "/acc/produto/contingencia/cadastro-de-naturezas/campos-genericos",
                element: <AdminContingencyACCGenericFieldsPage />,
                role: accRoles.product.contingencyNaturesGenericFieldsPage.role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/acc/produto/contingencia/cadastro-de-naturezas/campos-genericos/adicionar",
                    element: <AdminContingencyACCCreateGenericFields />,
                    role: accRoles.product
                      .contingencyNaturesGenericFieldsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/acc/produto/contingencia/cadastro-de-naturezas/campos-genericos/:${accRouteParams.id}`,
                    element: <AdminContingencyACCUpdateGenericFields />,
                    role: accRoles.product
                      .contingencyNaturesGenericFieldsUpdatePage.role,
                  },
                },
              },
            },
          },
          settlements: {
            name: "Liquidações",
            path: "/acc/produto/contingencia/liquidacoes",
            element: <AdminContingencyACCSettlementsPage />,
            role: accRoles.product.settlementsContingencyPage.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/acc/produto/contingencia/liquidacoes/adicionar",
                element: <AdminContingencyACCPreticketCreatePage />,
                role: accRoles.product.settlementsContingencyFormPage.role,
              },
              update: {
                name: "Atualizar",
                path: "/acc/produto/contingencia/liquidacoes/atualizar",
                element: <AdminContingencyACCSettlementEditPage />,
                role: accRoles.product.settlementsContingencyFormPage.role,
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
