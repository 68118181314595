import { CompanyTraitsContextProvider } from "./company-traits.context";
import { CompanyTraitsComponent } from "./company-traits.component";

export const CompanyTraits = () => {
  return (
    <CompanyTraitsContextProvider>
      <CompanyTraitsComponent />
    </CompanyTraitsContextProvider>
  );
};
