import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { AdminAccSimulateReponse } from "services/acc/models/responses/get-admin-simulate.response";
import { format } from "utils/date";

export const adminCustomerAccProposalsDetailsGenerator = (
  value: AdminAccSimulateReponse | null,
) => {
  if (!value) return {} as DetailsFields;

  const quoteDetails = {
    quote: {
      title: "Operação",
      items: [
        {
          label: "Dt. Criação",
          value: format.dateTime(value?.quoteSimulation?.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Liquidação",
          value: format.dateTime(value?.quoteSimulation?.disbursementDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor solicitado",
          value: masks.currency(
            value.quoteSimulation?.amount,
            value.accProposal?.currency,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: `Cotação ${value.quoteSimulation?.sellCurrencyType}/BRL`,
          value: `1 ${
            value.quoteSimulation?.sellCurrencyType
          } ≈ ${masks.currency(
            value?.quoteSimulation?.exchangeRatio,
            "BRL",
            ".",
            ",",
            4,
          )}`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    accProposal: {
      title: "Dados da proposta",
      items: [
        {
          label: "Moeda",
          value: value.accProposal.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total",
          value: masks.currency(
            value?.accProposal?.amount,
            value.accProposal?.currency,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de expiração",
          value: format.dateTime(value?.accProposal?.expirationDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de vencimento",
          value: format.dateTime(value?.accProposal?.settlementDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa de juros",
          value: masks.percentage(value?.accProposal?.interestRate) || "0,00%",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Risco",
          value: value?.accProposal?.ratingType,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    simulation: {
      title: "Simulação",
      items: [
        {
          label: "Taxa de juros",
          value: `BRL ${masks.percentage(
            value.creditSimulation?.monthlyInterestRate,
          )} a.a`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de vencimento",
          value: dayjs(
            value.creditSimulation?.operations[0].cashFlows[0].paymentDate,
          ).format("DD/MM/YYYY"),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    total: {
      title: "Valor total",
      items: [
        {
          value: masks.currency(
            value.creditSimulation?.operations[0].cashFlows[0].amount,
            value.accProposal.currency,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return quoteDetails;
};
