import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ConfirmationDeleteMinimumCriteriaModalProps } from "./delete-criteria-modal.type";
import { deleteMinimumCriteriaModalId } from "./delete-criteria-modal.utils";

export const useConfirmationDeleteMinimumCriteriaModal = ({
  id,
  reload,
}: ConfirmationDeleteMinimumCriteriaModalProps) => {
  const modalId = deleteMinimumCriteriaModalId(id);

  const { callService: deleteMinimumCriteria, loading } = useServiceCall(
    service.hubCreditManager.deleteMinimumCriteria,
  );

  const handleConfirm = useCallback(async () => {
    const minimumCriteriaId = { id: Number(id) };
    const { success } = await deleteMinimumCriteria(minimumCriteriaId);

    if (success) OToastManager.success("Critério Mínimo deletado com sucesso!");
    else {
      OToastManager.danger("Erro para deletar o critério mínimo.");
    }

    modalManager.hide(modalId);
    reload();
  }, [deleteMinimumCriteria, id, modalId, reload]);

  return { loading, modalId, handleConfirm };
};
