import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { OButton, OIcon, OTooltip, OTypography } from "@maestro/react";
import { helpers, validators } from "@maestro/utils";
import { isAxiosError } from "axios";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { LoanErrorResponse } from "services/hubcreditmanager/models/types/limit/limit.types";
import { ContractTemplateError } from "services/hubloan/models/responses/error/post-api-admin-company-upload-contract-template-error.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { LoanContractsTemplatesUploadErrorModal } from "../../../components/loan-contracts-templates/loan-contracts-templates-upload-error-modal.component";
import { contractsTemplatesGrid } from "./_compose/contract-templates.grid";
import { contractVariablesGrid } from "./_compose/contract-variables.grid";
import { CustomerAndCreditLineForm } from "./_compose/customer-and-credit-line-form.component";
import { contractGeneratorFormValidationsSchema } from "./contract-generator-form.schemas";
import { ContractGeneratorFormValues } from "./contract-generator.types";
import { contractGeneratorErrors } from "./contract-generator.utils";

export const ContractGeneratorPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ContractTemplateError[]>();

  const variablesGridRef = useRef<DataGrid>(null);
  const templatesGridRef = useRef<DataGrid>(null);

  const form = useForm<ContractGeneratorFormValues>({
    resolver: yupResolver(contractGeneratorFormValidationsSchema),
    defaultValues: {
      taxId: "",
    },
  });

  const { watch, setValue, handleSubmit, getValues } = form;

  const watchContractTemplates = watch("contractTemplates");
  const watchTaxId = watch("taxId");

  const variablesGrid = useMemo(
    () =>
      contractVariablesGrid((contractVariables) => {
        setValue("contractVariables", contractVariables);
      }),
    [setValue],
  );

  const templatesGrid = useMemo(
    () =>
      contractsTemplatesGrid(
        watchContractTemplates,
        setErrors,
        (contractTemplates) => {
          setValue("contractTemplates", contractTemplates);
        },
      ),
    [watchContractTemplates, setValue],
  );

  const renderContracts = useMemo(
    () =>
      handleSubmit(async (values) => {
        setLoading(true);
        setValue(
          "contractTemplates",
          values.contractTemplates.map((contract) => ({
            ...contract,
            status: "RENDERIZANDO" as const,
          })),
        );

        const renderTemplates = values.contractTemplates.map(
          (contractTemplate) => {
            return service.hubLoan
              .generateContract({
                fileKey: contractTemplate.template,
                product: values.creditLine,
                identification: values.taxId,
                variables: values.contractVariables,
              })
              .then(({ data }) => {
                setValue(
                  "contractTemplates",
                  getValues("contractTemplates").map((contract) =>
                    contract.template === contractTemplate.template
                      ? data.contractState?.hasError
                        ? {
                            ...contract,
                            status: "RENDERIZADO_COM_ERRO" as const,
                            renderedTemplate: data.renderedFileKey,
                            contractState: data.contractState,
                          }
                        : {
                            ...contract,
                            status: "RENDERIZADO" as const,
                            renderedTemplate: data.renderedFileKey,
                            contractState: data.contractState,
                          }
                      : contract,
                  ),
                );
                return data;
              })
              .catch((e) => {
                setValue(
                  "contractTemplates",
                  getValues("contractTemplates").map((contract) =>
                    contract.template === contractTemplate.template
                      ? {
                          ...contract,
                          status: "ERRO" as const,
                        }
                      : contract,
                  ),
                );

                let message =
                  "Houve um erro não mapeado na rendereização do contrato.";

                if (
                  isAxiosError<LoanErrorResponse>(e) &&
                  contractGeneratorErrors[
                    e.response?.data?.errorCodes?.[0] ?? ""
                  ]
                ) {
                  message =
                    contractGeneratorErrors[
                      e.response?.data?.errorCodes?.[0] ?? ""
                    ];
                }

                OToastManager.danger({
                  title: `Contrato: ${contractTemplate.type}`,
                  message,
                });
              });
          },
        );

        await Promise.all(renderTemplates);

        setLoading(false);
      }),
    [getValues, handleSubmit, setValue],
  );

  const downloadContractMapping = useCallback(async () => {
    try {
      const { data: blob } = await service.hubLoan.downloadMapping();

      helpers.downloadBlobFile("mapeamento-variaveis.xlsx", blob);
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, []);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Gerador de contratos" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_gerador_contrato:botao:renderizar_contratos"
          dataLabel="renderizar_contratos"
          onClick={renderContracts}
          disabled={
            !watchContractTemplates?.length || !validators.cpfCnpj(watchTaxId)
          }
        >
          Renderizar contratos
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <CustomerAndCreditLineForm />

          <div className="d-flex justify-content-between align-items-center my-4">
            <OTypography tag="h3" weight="normal" size="lg">
              Variáveis para tagueamento
            </OTypography>
            <div className="d-flex gap-2 align-items-center">
              <OTooltip type="info" position="top" arrow maxWidth="150px">
                <span slot="tooltip-content">
                  Detalhamento das variáveis de tagueamento das minutas
                </span>
                <OButton
                  dataAction="emprestimos_gerador_contrato:botao:download_de_para"
                  dataLabel="download_de_para"
                  type="primary"
                  onClick={() => downloadContractMapping()}
                >
                  <OIcon category="orq" icon="orq-download" size="lg" />
                </OButton>
              </OTooltip>
              <OButton
                dataAction="emprestimos_gerador_contrato:botao:adicionar"
                dataLabel="adicionar"
                outline
                type="primary"
                onClick={() => {
                  variablesGridRef.current?.instance.addRow();
                }}
              >
                Adicionar
              </OButton>
            </div>
          </div>
          <ODataGridGenerator gridRef={variablesGridRef} grid={variablesGrid} />

          <div className="d-flex justify-content-between align-items-center my-4">
            <OTypography tag="h3" weight="normal" size="lg">
              Templates
            </OTypography>
            <OButton
              dataAction="emprestimos_gerador_contrato:botao:adicionar"
              dataLabel="adicionar"
              outline
              type="primary"
              onClick={() => {
                templatesGridRef.current?.instance.addRow();
              }}
            >
              Adicionar
            </OButton>
          </div>
          <ODataGridGenerator gridRef={templatesGridRef} grid={templatesGrid} />
        </FormProvider>
      </CardTemplate>

      <LoanContractsTemplatesUploadErrorModal errors={errors} />
    </FormTemplate>
  );
};
