import { BankIconSVG } from "../financial-institution-icon.types";

export const BankXpSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#000" />
      <path
        fill="#fff"
        d="M76.42 75.723a9.713 9.713 0 01-4.285-1.013v8.484h7.184c3.656 0 6.555-2.913 6.555-6.585v-6.33c-2.017 3.291-5.42 5.444-9.454 5.444zM64.698 71.29v-15.7a2356.393 2356.393 0 01-5.799 7.85c.757 1.013 3.53 4.812 5.799 7.85z"
      />
      <path
        fill="#fff"
        d="M79.32 29H32.554C28.899 29 26 31.912 26 35.584V76.61c0 3.672 2.9 6.584 6.555 6.584h32.143v-7.85h-5.925s-2.899-3.925-4.16-5.825c-1.386 1.9-4.16 5.825-4.16 5.825H41.63s7.563-10.256 8.698-11.902c-1.135-1.646-8.194-11.27-8.194-11.27h8.824s2.521 3.42 3.782 5.192c1.26-1.773 3.781-5.191 3.781-5.191h10.336s.756 1.392 1.008 2.025c1.891-1.519 4.286-2.405 6.681-2.405 3.908 0 7.437 2.152 9.454 5.444V35.584C85.874 31.912 82.975 29 79.32 29z"
      />
      <path
        fill="#fff"
        d="M80.076 63.821c0-2.659-1.765-4.811-3.908-4.811-2.269 0-4.034 2.152-4.034 4.811 0 2.66 1.765 4.812 4.034 4.812 2.143 0 3.908-2.153 3.908-4.812z"
      />
    </svg>
  );
};
