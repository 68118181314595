import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { BorderosByIdReceivablesItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { ReceivablesCustomerByBatcheIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { grid } from "./import-details.grid";

export const TitleImportDetails = () => {
  const { batcheId } = useParams<ReceivablesCustomerByBatcheIdRouteParams>();

  const gridRef = useGridRef();

  const dataSource =
    dataSourceCustomStoreGenerator<BorderosByIdReceivablesItem>(() =>
      service.quickfin
        .getBorderosById(batcheId!)
        .then(({ data }) => data.receivables)
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar detalhes";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    );

  const gridSummary = useMemo(
    () => (
      <Summary texts={{ sum: "{0}" }}>
        <TotalItem
          column="maturityAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency: "BRL",
            precision: 3,
          }}
        />
      </Summary>
    ),
    [],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Detalhe da Importação"
          description="Veja os recebíveis importados no lote selecionado e suas informações principais"
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} dataSource={dataSource}>
        {gridSummary}
      </ODataGridGenerator>
    </GridTemplate>
  );
};
