import { clearCustomer } from "contexts/customer";
import React from "react";
import { isProd } from "utils/environments";
import { logger } from "utils/logger";
import {
  DevelopmentErrorUi,
  GoToHomeButton,
  ProductionErrorUi,
} from "./_compose";
import { ErrorBoundaryProps, ErrorBoundaryState } from "./error-boundary.types";

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const parsedError = new Error();

    parsedError.message =
      "[ErrorBoundary]" + this.handleUndefinedString(error?.message);
    parsedError.stack =
      this.handleUndefinedString(error?.stack) +
      "\n\n[Component stack]" +
      this.handleUndefinedString(info?.componentStack);

    logger.fatal(parsedError);

    this.setState({
      error,
      componentStack: info.componentStack,
    });

    clearCustomer();
  }

  clearErrorState = () => {
    this.setState({
      hasError: false,
      error: undefined,
      componentStack: undefined,
    });
  };

  private handleUndefinedString = (str: string | undefined) => {
    return str ?? "[UNDEFINED]";
  };

  render() {
    const { hasError, error, componentStack } = this.state;

    if (hasError) {
      return isProd() ? (
        <ProductionErrorUi
          customAction={<GoToHomeButton clearError={this.clearErrorState} />}
        />
      ) : (
        <DevelopmentErrorUi
          errorMessage={error?.message}
          componentStack={componentStack}
          customAction={<GoToHomeButton clearError={this.clearErrorState} />}
        />
      );
    }

    const { children } = this.props;

    return children;
  }
}
