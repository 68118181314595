import { masks } from "@maestro/utils";
import {
  GetCardsCompaniesUsersResponse,
  GetCardsDebitCardOwnersResponse,
} from "services/bankinghub/models";
import {
  CardModality,
  MapperAddressType,
  ProposalStatus,
} from "services/bankinghub/models/types/cards";
import { CardOwner, OfferedCard } from "./card-hiring.types";

export const assembleCardOwnersByGetCardsCompaniesUsersResponse = (
  data?: GetCardsCompaniesUsersResponse,
): CardOwner[] | undefined => {
  if (!data) return undefined;

  return data
    .map(({ user: { name, email, taxId: cpf } }) => {
      return { name, email, cpf };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

const isCreditOrMultipleByModality = (modality: CardModality) =>
  [CardModality.Credit, CardModality.Multiple].includes(modality);

export const assembleCardOwnersByGetCardsDebitCardOwnersResponse = (
  data?: GetCardsDebitCardOwnersResponse,
): CardOwner[] | undefined => {
  if (!data) return undefined;

  const { users } = data;

  return users
    .map(({ name, email, document: cpf }) => {
      return { name, email, cpf };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const assembleOfferedCardFromAccount = (data: {
  proposal: BankingHubCards.Proposal;
  offerId: string;
  garantedLimit: number;
}): OfferedCard => {
  const { proposal, offerId, garantedLimit } = data;

  const isAvailable = proposal.status === ProposalStatus.Available;

  const isCreditOrMultiple = isCreditOrMultipleByModality(proposal.modality);

  return {
    variant: proposal.variant,
    modality: proposal.modality,
    programId: proposal.externalId,
    proposalStatus: proposal.status,
    ...(isCreditOrMultiple && { issueDates: proposal.issueDates }),
    ...(isAvailable && { offerId }),
    ...(isCreditOrMultiple && isAvailable && { garantedLimit }),
  };
};

export const assembleOfferedCardFromOffer = (data: {
  program: BankingHubCards.Program;
  offerId: string;
  grantedLimit: number;
}): OfferedCard => {
  const { program, offerId, grantedLimit } = data;

  const isCreditOrMultiple = isCreditOrMultipleByModality(program.modality);

  return {
    offerId,
    variant: program.creditVariant,
    modality: program.modality,
    programId: program.id,
    proposalStatus: ProposalStatus.Available,
    ...(isCreditOrMultiple && { issueDates: program.issueDates, grantedLimit }),
  };
};

const removeSpecialCharsIgnoringSpace = (str: string) => {
  return str.replace(/[^a-zA-Z ]/g, "");
};

const removeMultipleSpaces = (str: string) => {
  return str.replace(/ +(?= )/g, "");
};

const removeAccents = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const formatPrintedName = (printedName: string): string => {
  const removeMultipleSpacesResult = removeMultipleSpaces(printedName);

  const removeAccentsResult = removeAccents(removeMultipleSpacesResult);

  const removeSpecialCharsIgnoringSpaceResult =
    removeSpecialCharsIgnoringSpace(removeAccentsResult);

  return removeSpecialCharsIgnoringSpaceResult.trim().toLocaleUpperCase();
};

export const assembleDefaultPrintedName = (name: string): string => {
  if (!name) return "";

  const parts = name.trim().split(" ");

  const { length } = parts;

  const printedName =
    length === 1 ? parts[0] : `${parts[0]} ${parts[length - 1]}`;

  const formattedPrintedName = formatPrintedName(printedName);

  if (formattedPrintedName.length <= 21) return formattedPrintedName;
  return "";
};

export const unmaskPhone = (phone: string) => {
  return phone.replace(/\+|-| |\(|\)/g, "");
};

export const unmaskDocument = (document: string) => {
  return document.replace(/\.|-|\//g, "");
};

export const addressToStreetAndNeighborhoodText = (
  address: BankingHubCards.Address,
) => {
  const addressNumberText = address.number ? `, ${address.number}` : "";
  const addressNumberComplement = address.complement
    ? ` (${address.complement})`
    : "";

  const streetText = `${MapperAddressType[address.type]}: ${
    address.street
  }${addressNumberText}${addressNumberComplement}`;

  const neighborhoodText = `${address.neighborhood}, ${address.city} - ${
    address.state
  } ${masks.cep(address.zipCode)}`;

  return {
    streetText,
    neighborhoodText,
  };
};

export const formatAddress = (
  address?: BankingHubCards.Address,
  separator = "-",
) => {
  if (!address) return null;

  const parts = [
    `${address.street}`,
    ` ${separator} ${address.neighborhood} ${separator} ${address.city}/${
      address.state
    } ${separator} ${masks.cep(address.zipCode)}`,
  ];

  const hasNumber = !!address.number;
  if (hasNumber) parts.splice(-1, 0, `, ${address.number}`);

  const hasComplement = !!address.complement;
  if (hasComplement) parts.splice(-1, 0, ` (${address.complement})`);

  return parts.join("");
};

export const formatStatementAccount = (account?: BankingHub.Account) => {
  if (!account) return null;
  return `Ag. ${account.agency} · Cc. ${masks.generic(account.number, {
    mask: "00000-0",
  })}`;
};
