import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { PendencyTypeFormComponent } from "../_compose";
import { useEditPendencyType } from "./edit-pendency-type.hook";

export const EditPendencyTypePage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getPendencyType,
    submit,
    submitLoading,
  } = useEditPendencyType();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar tipo de pendência" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <ValidationErrorComponent
              callback={getPendencyType}
              error={fetchError}
              title="Erro ao buscar o tipo de pendência"
            />
          }
          noValue
          render={() => (
            <FormProvider {...form}>
              <PendencyTypeFormComponent />
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
