import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { modalManager } from "@maestro/react";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  PostByMsgTypeParams,
  PostByMsgTypeReprocessParams,
} from "services/roadwalker/models/requests";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  roadwalkerSearchDefaultValues,
  roadwalkerSearchValidationSchema,
} from "./_compose/modal-busca-roadwalker/roadwalker-search-modal.form";
import { RoadwalkerSearch } from "./_compose/modal-busca-roadwalker/roadwalker-search-modal.types";
import { RoadwalkerMessage } from "./_compose/roadwalker-master-detail/roadwalker-master-detail.types";
import { roadwalkerGrid } from "./roadwalker.grid";
import {
  messageIndexMap,
  messageKeyMap,
  reprocessConfirmationModalId,
  roadwalkerSearchModalId,
} from "./roadwalker.utils";

export const useRoadwalker = () => {
  const [dataSource, setDataSource] = useState<RoadwalkerMessage[]>();
  const [idType, setIdType] = useState("tradeId");
  const [messageType, setMessageType] = useState("trade");
  const [payload, setPayload] = useState<PostByMsgTypeParams>();
  const [selectedMessages, setSelectedMessages] =
    useState<RoadwalkerMessage[]>();
  const [showMostRecent, setShowMostRecent] = useState(false);
  const form = useForm<RoadwalkerSearch>({
    defaultValues: roadwalkerSearchDefaultValues,
    resolver: yupResolver(roadwalkerSearchValidationSchema),
  });
  const gridRef = useGridRef();
  const { control, handleSubmit, reset, watch } = form;
  const messageTypeWatch = watch("messageType");

  const {
    callService: getMessages,
    loading: loadingMessages,
    value: messagesValue,
  } = useServiceCall(service.roadwalker.getMessages);

  const {
    callService: reprocessMessages,
    loading: loadingReprocess,
    value: reprocessedMessages,
  } = useServiceCall(service.roadwalker.reprocessMessage);

  const grid = useMemo(
    () =>
      roadwalkerGrid(
        idType,
        reprocessedMessages,
        setSelectedMessages,
        messageType,
      ),
    [idType, messageType, reprocessedMessages],
  );

  const onSearch = handleSubmit(async (values) => {
    const mappedFilters = Object.fromEntries(
      values.filters?.map(({ filter, value }) => [filter, value]) ?? [],
    );

    const body = {
      Parameters: {
        Ids: values.tradesIds ?? [],
        MostRecentOnly: showMostRecent,
        ...(values.indexedSearch === "index"
          ? {
              QueryAttributeName:
                getValueFromMap(messageIndexMap, values.messageType) ?? "",
              IndexName: getValueFromMap(messageIndexMap, values.messageType),
            }
          : {
              QueryAttributeName:
                getValueFromMap(messageKeyMap, values.messageType) ?? "",
            }),
        AttributeEquals: mappedFilters,
      },
    };

    setIdType(
      values.indexedSearch === "index"
        ? getValueFromMap(messageIndexMap, values.messageType) ?? "externalId"
        : getValueFromMap(messageKeyMap, values.messageType) ?? "tradeId",
    );
    setMessageType(values.messageType);
    setPayload(body);

    await getMessages(values.messageType, body);

    modalManager.hide(roadwalkerSearchModalId);
  });

  const reprocessMessage = useCallback(
    async (message: RoadwalkerMessage) => {
      if (message && payload) {
        const body: PostByMsgTypeReprocessParams = {
          Parameters: {
            ...payload?.Parameters,
            Ids: [
              message[
                payload?.Parameters.QueryAttributeName as keyof typeof message
              ] ?? "",
            ],
            AttributeEquals: {
              ...payload.Parameters.AttributeEquals,
              MessageId: message.MessageId,
            },
          },
        };

        const { success } = await reprocessMessages(
          messageType.toLowerCase(),
          body,
        );

        if (success) {
          setSelectedMessages([]);
          gridRef.current?.instance.clearSelection();
          OToastManager.success("Mensagens em reprocessamento!");
        }
      }
    },
    [gridRef, messageType, payload, reprocessMessages],
  );

  const onReprocess = useCallback(async () => {
    if (selectedMessages) {
      await selectedMessages.map((message) => reprocessMessage(message));

      modalManager.hide(reprocessConfirmationModalId);
    }
  }, [reprocessMessage, selectedMessages]);

  const refetch = useCallback(async () => {
    if (payload) {
      await getMessages(messageType, payload);
    }
  }, [getMessages, messageType, payload]);

  const resetSearch = useCallback(
    () => reset(roadwalkerSearchDefaultValues),
    [reset],
  );

  useEffect(() => {
    if (messagesValue) {
      const messages = messagesValue.Messages;
      const messagesById = Object.values(messages);
      setDataSource(messagesById.flat());
    }
  }, [messagesValue]);

  return {
    control,
    dataSource,
    form,
    grid,
    gridRef,
    loadingMessages,
    loadingReprocess,
    messageTypeWatch,
    onReprocess,
    onSearch,
    refetch,
    resetSearch,
    selectedMessages,
    setShowMostRecent,
    showMostRecent,
  };
};
