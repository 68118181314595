import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { ORFieldInput } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  addGridFormDefaultValues,
  addGridFormValidationSchema,
} from "./add-grid.schema";

export const AddGridPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(addGridFormValidationSchema),
    defaultValues: addGridFormDefaultValues,
  });

  const { handleSubmit } = form;

  const createGrid = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.upsertGrid({
            ...values,
            configurableGridColumnIds: [],
          });

          OToastManager.success("Grid criado com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.configurations.grids.path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao tentar criar o grid.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar grid" />}
      actions={
        <LoadingButton loading={loading} type="info" onClick={createGrid}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <div className="d-flex alig-items-start justify-content-between gap-5">
          <FormProvider {...form}>
            <form className="w-60">
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Nome"
                labelSize="md"
                dataAction="adicionar_status:texto:nome"
                dataLabel="nome"
              />
            </form>
          </FormProvider>
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
