import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const CessaoOffshoreField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.cessaoOffshore}
      name="cessaoOffshore"
    />
  );
};
