import * as yup from "yup";

export interface ForeignCurrencyIndexFormValues {
  currency: string;
  priceDay: number;
  currencyQuoteType: string;
  price?: number;
  priceDate?: string | null;
}

export const foreignCurrencyIndexFormValidationsSchema: yup.ObjectSchema<ForeignCurrencyIndexFormValues> =
  yup.object({
    currency: yup.string().required("Este campo é obrigatório"),
    priceDay: yup
      .number()
      .transform((value) => value || undefined)
      .required("Este campo é obrigatório"),
    currencyQuoteType: yup.string().required("Este campo é obrigatório"),
    price: yup.number().transform((value) => value || undefined),
    priceDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .nullable()
      .optional(),
  });
