import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useDownloadEmail = () => {
  const { callService, loading } = useServiceCall(service.raven.downloadEmail);

  const downloadEmail = useCallback(
    async (fileKey: string) => {
      const { success, response } = await callService({
        file_key: fileKey,
      });

      if (success) {
        helpers.downloadBlobFile(
          fileKey,
          new Blob([response.data], { type: "plain/text" }),
        );
      } else {
        OToastManager.danger("Erro ao fazer o download do e-mail");
      }
    },
    [callService],
  );

  return { downloadEmail, loading };
};
