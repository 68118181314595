import { theme } from "./theme";
import { ThemeCommand } from "./types/command";

class ThemeManager {
  private theme = theme;

  async execute(command: ThemeCommand) {
    await command.execute(this.theme);
  }
}

export const themeManager = new ThemeManager();
