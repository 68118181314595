import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useMemo, useState } from "react";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { AccountCloseIdsParams, accountCloseIdsParam } from "./_compose";
import { Account } from "./account-close.types";

export const useAccountClose = () => {
  const [ids, setIds] = useState<number[]>([]);

  const pendenciesHref = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set(
      accountCloseIdsParam,
      JSON.stringify(ids satisfies AccountCloseIdsParams),
    );
    return (
      corporateRouter.routes.cadastro.customer.accountClose.pendencies.path +
      "?" +
      searchParams.toString()
    );
  }, [ids]);

  return {
    ids,
    pendenciesHref,
    setIds,
  };
};

export const useGetAccounts = (taxId: string) => {
  const {
    callService: getAccounts,
    value: accounts,
    hasError,
  } = useServiceCall(service.onboardingCoreAccount.getAccounts);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<Account>(() =>
        getAccounts({ taxId }).then(({ response, success }) => {
          if (!success) throw new Error("Erro ao buscar contas");

          return (
            response.data?.filter(
              (account) => account.status !== "ENCERRADA",
            ) ?? []
          );
        }),
      ),
    [getAccounts, taxId],
  );

  const filteredAccountsEncerrada = useMemo(
    () =>
      dataSourceCustomStoreGenerator<Account>(() =>
        hasError
          ? Promise.reject(new Error("Erro ao buscar contas"))
          : Promise.resolve(
              accounts?.filter((account) => account.status === "ENCERRADA") ??
                [],
            ),
      ),
    [accounts, hasError],
  );

  return {
    dataSource,
    filteredAccountsEncerrada,
  };
};
