import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import dayjs from "dayjs";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  Bankslip,
  SharkAmortizationType,
} from "services/shark/models/responses";
import { sharkService } from "services/shark/shark.service";
import { GridTemplate } from "templates/grid-template";
import { emprestimosRoles } from "../../../../../roles/emprestimos.roles";
import { EmprestimosClienteOperacoesById } from "../../../../../routes/emprestimos.route-params";
import { OperationsInstallmentFromHubLoanGrid } from "./operations-installments-from-hub-loan.component";
import { useOperationsInstallments } from "./operations-installments.context";
import { OperationsInstallmentsGrid } from "./operations-installments.grid";
import { installmentsFromSharkStatus } from "./operations-installments.utils";

type GroupedInstallments = Record<SharkAmortizationType, number[]>;

export const OperationsInstallmentsComponent = () => {
  const { id } = useParams<EmprestimosClienteOperacoesById>();
  if (!id) throw new Error("No id");

  const [availableBankSlips, setAvailableBankSlips] = useState<Bankslip[]>([]);
  const [email, setEmail] = useState<string | null>();

  const { hasRole } = useRoles();
  const gridRef = useGridRef();

  const {
    checkedInstallmentsMap,
    loanDetails,
    loading,
    sharkSettlement,
    refresh,
    canAnticipate,
  } = useOperationsInstallments();

  const showActionColumn = useMemo(
    () =>
      hasRole(
        emprestimosRoles.customer.installmentsPageResendBankslipViaEmail.role,
      ) ||
      hasRole(
        emprestimosRoles.customer.installmentsPageCopyBankslipToClipboard.role,
      ) ||
      hasRole(emprestimosRoles.customer.installmentsPageDownloadBankslip.role),
    [hasRole],
  );

  const getBankslips = useCallback(async () => {
    if (!loanDetails?.tradeId) return;

    const { data } = await sharkService.getBankslipsByTradeId({
      trade_id: loanDetails.tradeId,
      find_email: "true",
    });

    setAvailableBankSlips(data.bankslips);
    setEmail(data.found_email);
  }, [loanDetails]);

  const navigate = useNavigate();

  const grid = useMemo(() => {
    if (
      loanDetails &&
      !installmentsFromSharkStatus.includes(loanDetails?.status)
    ) {
      return OperationsInstallmentFromHubLoanGrid(
        loanDetails?.settlement?.installments ?? [],
      );
    }

    const sortOrder =
      canAnticipate &&
      ["DESC", "DESC_EXP30"].includes(
        String(
          sharkSettlement?.agreement.agreement_prepayments_types?.[0]
            .prepayments_config.prepayment_allowed_order,
        ),
      )
        ? "desc"
        : "asc";

    const showInstallmentsType =
      !!sharkSettlement &&
      sharkSettlement.installments.some(
        (i) => i.amortization_frequency !== "NONE",
      );

    return OperationsInstallmentsGrid({
      availableBankSlips,
      canAnticipate,
      showInstallmentsType,
      email,
      showActionColumn,
      tradeId: loanDetails?.tradeId,
      sortOrder,
      sharkSettlement,
    });
  }, [
    loanDetails,
    sharkSettlement,
    availableBankSlips,
    canAnticipate,
    email,
    showActionColumn,
  ]);

  const nextInstallment = useMemo(
    () =>
      sharkSettlement?.installments?.reduce((a, b) =>
        a.maturity_date < b.maturity_date ? a : b,
      ),
    [sharkSettlement],
  );

  const canAnticipateNextExpiringWith30Days = useMemo(() => {
    return (
      loanDetails?.status === "Desembolsado" &&
      loanDetails?.product !== "FIDC" &&
      loanDetails?.currency === "BRL" &&
      sharkSettlement?.installments.length &&
      sharkSettlement?.agreement.agreement_prepayments_types.some(
        (type) =>
          type.prepayments_config.prepayment_allowed_order === "DESC_EXP30",
      ) &&
      !!nextInstallment &&
      dayjs(nextInstallment.maturity_date).diff(dayjs(), "days") <= 30 &&
      dayjs(nextInstallment.maturity_date).diff(dayjs(), "days") > 0
    );
  }, [loanDetails, nextInstallment, sharkSettlement]);

  const checkedInstallments = useMemo(() => {
    if (!sharkSettlement?.installments.length) return {} as GroupedInstallments;

    const filteredInstallments = Object.entries(checkedInstallmentsMap).flatMap(
      ([freq, installments]) =>
        sharkSettlement.installments.filter(
          (i) =>
            i.amortization_frequency === freq &&
            installments.has(i.installment_number),
        ),
    );

    const groupedInstallments = filteredInstallments.reduce(
      (result, currentValue) => {
        const acc = result;

        acc[currentValue.amortization_frequency] =
          result[currentValue.amortization_frequency] || [];

        acc[currentValue.amortization_frequency].push(
          currentValue.installment_number,
        );

        return acc;
      },
      {} as GroupedInstallments,
    );

    return groupedInstallments;
  }, [sharkSettlement?.installments, checkedInstallmentsMap]);

  const redirectToAnticipation = useCallback(
    (nextExpiringWithin30Days: boolean) => {
      let selectedInstallments: GroupedInstallments = {} as GroupedInstallments;

      if (nextExpiringWithin30Days) {
        selectedInstallments = (
          !nextInstallment
            ? {}
            : {
                [nextInstallment.amortization_frequency]: [
                  nextInstallment.installment_number,
                ],
              }
        ) as GroupedInstallments;
      } else {
        selectedInstallments = checkedInstallments;
      }

      navigate({
        pathname:
          corporateRouter.routes.emprestimos.customer.operations.quotes.anticipation.installmentAnticipation.path(
            {
              id,
              contractNumber: encodeURIComponent(
                sharkSettlement?.contract_number as string,
              ),
            },
          ),
        search: createSearchParams({
          installments: JSON.stringify(selectedInstallments),
          currency: String(loanDetails?.currency),
        }).toString(),
      });
    },
    [
      checkedInstallments,
      id,
      loanDetails?.currency,
      navigate,
      nextInstallment,
      sharkSettlement?.contract_number,
    ],
  );

  const gridActions = useMemo(() => {
    return (
      <>
        {canAnticipateNextExpiringWith30Days && (
          <IconButton
            icon={{ icon: "orq-anticipate", category: "orq" }}
            onClick={() => redirectToAnticipation(true)}
            type="secondary"
          >
            Antecipar próxima parcela a vencer
          </IconButton>
        )}
        {loanDetails?.currency === "BRL" && (
          <IconButton
            icon={{ icon: "orq-anticipate", category: "orq" }}
            disabled={
              loanDetails?.product === "FIDC" ||
              !sharkSettlement ||
              Object.values(checkedInstallmentsMap).every(
                (set) => set.size === 0,
              )
            }
            onClick={() => redirectToAnticipation(false)}
          >
            Antecipar parcelas
          </IconButton>
        )}

        <RefreshGridButton onClick={refresh} />
      </>
    );
  }, [
    canAnticipateNextExpiringWith30Days,
    checkedInstallmentsMap,
    loanDetails,
    redirectToAnticipation,
    refresh,
    sharkSettlement,
  ]);

  useEffect(() => {
    if (!loanDetails) return;
    getBankslips();
  }, [getBankslips, loanDetails]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Parcelas" />}
      gridRef={gridRef}
      showClearFiltersButton
      actions={gridActions}
    >
      <ODataGridGenerator
        loading={loading}
        gridRef={gridRef}
        grid={grid}
        dataSource={
          !loanDetails ||
          installmentsFromSharkStatus.includes(loanDetails?.status)
            ? sharkSettlement?.installments ?? []
            : loanDetails?.settlement?.installments ?? []
        }
      />
    </GridTemplate>
  );
};
