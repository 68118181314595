import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldRadioGroup,
  ORadio,
  OTypography,
} from "@maestro/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButton } from "components/loading-button";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { service } from "services/service";
import {
  ResetLegalEntityForm,
  resetLegalEntityValidationSchema,
} from "./reset-legal-entity-registration.form";
import {
  modalId,
  relationShipsMap,
} from "./reset-legal-entity-registration.utils";

export const ResetLegalEntityRegistration = () => {
  const { customer } = useCustomer();
  const form = useForm<ResetLegalEntityForm>({
    resolver: yupResolver(resetLegalEntityValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService, loading } = useServiceCall(
    service.onboarding.resetEntityRegistration,
  );

  const submit = handleSubmit(async (values) => {
    const { success } = await callService({
      taxId: customer.identification,
      relationShip: values.onboadingRelationShip,
    });

    if (success) {
      OToastManager.info("Jornada de onboarding resetada com sucesso.");
      modalManager.hide(modalId);
    } else {
      OToastManager.warning("Erro ao resetar jornada de onboarding.");
    }
  });

  return (
    <>
      <OButton
        dataAction="ferramentas:botao:resetar"
        dataLabel="resetar"
        onClick={() => modalManager.show(modalId)}
      >
        Resetar
      </OButton>

      <OModal id={modalId} size="sm" position="center">
        <OModalHeader closeButton>
          <OTypography tag="h1" size="lg">
            Resertar onboarding com qual relacionamento
          </OTypography>
        </OModalHeader>
        <OModalBody>
          <FormProvider {...form}>
            <ORFieldRadioGroup
              id="onboadingRelationShip"
              name="onboadingRelationShip"
              dataAction="ferramentas:radio:relacionamento"
              dataLabel="relacionamento"
            >
              {Object.entries(relationShipsMap).map(([value, label]) => (
                <div key={value} className="d-flex align-items-center gap-2">
                  <ORadio id={value} value={value} />
                  <OLabel htmlFor={value}>{label}</OLabel>
                </div>
              ))}
            </ORFieldRadioGroup>
          </FormProvider>
        </OModalBody>
        <OModalFooter divider>
          <div className="d-flex flex-row justify-content-end gap-2">
            <OButton
              dataAction="resetar_onboarding_modal:botao:cancelar"
              dataLabel="cancelar"
              outline
              onClick={() => modalManager.hide(modalId)}
            >
              Cancelar
            </OButton>
            <LoadingButton
              dataAction="resetar_onboarding_modal:botao:confirmar"
              dataLabel="confirmar"
              loading={loading}
              onClick={() => submit()}
            >
              Confirmar
            </LoadingButton>
          </div>
        </OModalFooter>
      </OModal>
    </>
  );
};
