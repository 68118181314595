import { OCol, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { AccountSelector, DeskSelector, OfficerSelector } from "./_compose";
import { useDeskMigrationOptions } from "./desk-migration-options.hook";

export const DeskMigrationOptions = () => {
  const {
    desks,
    form,
    getDesks,
    hasError,
    loading,
    submit,
    submitLoading,
    workflowId,
  } = useDeskMigrationOptions();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Migrar segmento de conta" description={workflowId} />
      }
      actions={
        <LoadingButton
          dataAction="acoes:botao:envia"
          dataLabel="enviar"
          loading={submitLoading}
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow gap={3}>
            <OCol xs={12} lg={6}>
              <DeskSelector
                desks={desks?.desks}
                loading={loading}
                hasError={hasError}
                refetch={getDesks}
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <OfficerSelector desks={desks?.desks} />
            </OCol>
            <OCol xs={12} lg={6}>
              <AccountSelector desks={desks?.desks} />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
