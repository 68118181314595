import { useCallback, useState } from "react";
import { service } from "services";
import { Cedente } from "../company-cedentes.types";

export const useGetCompanyCedente = (
  companyId: string | number,
  cedenteId: number,
  strategyId?: number,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<"NONE" | "FETCH" | "FIND">("NONE");
  const [cedente, setCedente] = useState<Cedente>();

  const getCedente = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.quickfin.getCompanyCedentes(companyId);

      const foundCedente = data.find(
        (_cedente) =>
          _cedente.id === cedenteId &&
          (_cedente.estrategia?.id === strategyId || !strategyId),
      );

      if (foundCedente) {
        setCedente(foundCedente);
        setError("NONE");
      } else {
        setError("FIND");
      }
    } catch (err) {
      setError("FETCH");
    } finally {
      setLoading(false);
    }
  }, [cedenteId, companyId, strategyId]);

  return {
    loading,
    error,
    cedente,
    getCedente,
  };
};
