import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { logger } from "utils/logger";
import { service } from "services";
import { OToastManager } from "@maestro/core";
import { corporateRouter } from "routes/corporate-router.context";
import { useGetCompanyCedente } from "../../../cliente/cedentes/detalhes/get-company-cedente.hook";
import { RecebiveisProductFillContractPendingComplementsRouteParams } from "../../../../routes/recebiveis.route-params";
import {
  parseContractComplementForm,
  useCedenteContractComplement,
} from "../../../cliente/cedentes/detalhes/_compose";
import { FillPendingComplementsForm } from "./fill-peding-complements.types";
import { fillComplementsDefaultValues } from "./fill-pending-complements.form";
import { parseFillContractComplementPayload } from "./fill-pending-complements.utils";

export const useFillPending = () => {
  const navigate = useNavigate();
  const { sacadoId, cedenteId, contractId } =
    useParams<RecebiveisProductFillContractPendingComplementsRouteParams>();
  if (!sacadoId || !cedenteId || !contractId) {
    throw Error("Invalid route params");
  }
  const { cedente, getCedente } = useGetCompanyCedente(
    Number(sacadoId),
    Number(cedenteId),
  );
  const form = useForm<FillPendingComplementsForm>({
    defaultValues: fillComplementsDefaultValues,
  });
  const { setValue, handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const {
    getCedenteContractComplement,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
    contractComplement,
  } = useCedenteContractComplement(Number(sacadoId), Number(cedenteId));

  useEffect(() => {
    (async () => {
      if (cedente) {
        const data = await getCedenteContractComplement(cedente);
        setValue("contractComplement", parseContractComplementForm(data));
        setValue("contractComplementInclusionList", data?.inclusionList ?? []);
      }
    })();
  }, [cedente, getCedenteContractComplement, setValue]);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const payload = parseFillContractComplementPayload(
        +contractId,
        values,
        contractComplement,
      );
      await service.quickfin.updateCompanyCedenteContractComplement(payload);
      OToastManager.success("Complementos do contrato preenchidos com sucesso");
      navigate(
        corporateRouter.routes.recebiveis.product.contractsPendingComplement
          .path,
      );
    } catch (e) {
      logger.error(e);
      OToastManager.danger(
        "Erro ao preencher complementos, tente novamente mais tarde.",
      );
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getCedente();
  }, [getCedente]);

  return {
    form,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
    submit,
    loading,
  };
};
