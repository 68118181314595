import { SharkSettlementData } from "services/shark/models/responses";
import { ViewParseType } from "./operations-installments.types";

export const parseInstallmentStatusType: ViewParseType = {
  Cancelada: "Cancelada",
  Vencida: "Vencida",
  APagar: "A Vencer",
  ParcialmentePaga: "Parcialmente Paga",
  Paga: "Quitada",
};

export const installmentsFromSharkStatus = [
  "Desembolsado",
  "Atrasado",
  "Liquidado",
];

export function getAgreementPrepaymentType(
  balance: SharkSettlementData | undefined,
  frequency: string,
  indexCode: string,
) {
  return (
    balance?.agreement?.agreement_prepayments_types.find(
      (type) =>
        frequency === type.amortization_frequency &&
        indexCode === type.index_code,
    ) ??
    balance?.agreement?.agreement_prepayments_types.find(
      (type) =>
        [frequency, "ANY"].includes(type.amortization_frequency) &&
        [indexCode, "ANY"].includes(type.index_code),
    )
  );
}

export function customizedJoin(value: string[] | number[]) {
  return value.join(", ").replace(/, ([^,]*)$/, " e $1");
}

export const amortizationFrequencyDescriptionMap: Record<string, string> = {
  MNTH: "Mensais",
  YEAR: "Anuais",
  MTRY: "Bullet",
  BNTH: "Bimestrais",
  QURT: "Trimestrais",
  QUDR: "Quadrimestrais",
  MIAN: "Semestrais",
};
