import { ORFieldInput } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useChangeUserEmail } from "./use-change-user-email-form.hook";

export const UserEmailManagement = () => {
  const { form, onSubmit, loading, user } = useChangeUserEmail();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Alteração de e-mail" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="atualizacao_email:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORFieldInput
            tag="text"
            id="currentEmail"
            name="currentEmail"
            dataAction="atualizacao_email:texto:email_atual"
            dataLabel="email_atual"
            label="E-mail atual"
            disabled
            value={user?.email ?? ""}
          />
          <ORFieldInput
            tag="text"
            id="newEmail"
            name="newEmail"
            dataAction="atualizacao_email:texto:email_novo"
            dataLabel="email_novo"
            label="E-mail novo"
            disabled={loading}
          />
          <ORFieldInput
            tag="text"
            id="updatingReason"
            name="updatingReason"
            dataAction="atualizacao_email:texto:motivo_atualizacao"
            dataLabel="motivo_atualizacao"
            label="Motivo da atualização"
            disabled={loading}
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
