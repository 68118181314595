import { DetailsFields } from "components/details-card";
import { PostAdminAccountopeningGetMasterDetailedWorkflowResponse } from "services/onboarding-core-account/models";

export const buildWorkflowDetailsFields = (
  workflow: PostAdminAccountopeningGetMasterDetailedWorkflowResponse,
): DetailsFields => ({
  info: {
    title: "Detalhes",
    items: [
      {
        label: "Identificador",
        value: workflow.uuid,
        grid: { md: 6, xl: 4 },
      },
      {
        label: "Requisitante",
        value: workflow.requester,
        grid: { md: 6, xl: 4 },
      },
      {
        label: "Status",
        value: workflow.status,
        grid: { md: 6, xl: 4 },
      },
    ],
  },
  status: {
    items: [
      {
        label: "Validações",
        value:
          workflow.accounts.flatMap((x) => x.validations).length +
          workflow.validations.length,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Notificações Runned",
        value:
          workflow.accounts.flatMap((x) =>
            x.notifications.filter((notification) => notification.run),
          ).length +
          workflow.notifications.filter((notification) => notification.run)
            .length,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Notificações Not Runned",
        value:
          workflow.accounts.flatMap((x) =>
            x.notifications.filter(
              (notification) => !notification.run && notification.failed === 0,
            ),
          ).length +
          workflow.notifications.filter(
            (notification) => !notification.run && notification.failed === 0,
          ).length,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Notificações Failed",
        value:
          workflow.accounts.flatMap((x) =>
            x.notifications.filter(
              (notification) => !notification.run && notification.failed !== 0,
            ),
          ).length +
          workflow.notifications.filter(
            (notification) => !notification.run && notification.failed !== 0,
          ).length,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
});
