import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import {
  NameAndTaxIdFormatted,
  calculateNameAndTaxIdFilterExpression,
} from "components/name-and-tax-id-formatted";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../utils";

export const invoicesDataSource = (billingReportId: string) => {
  return dataSourceCustomStoreGenerator<HubEnergy.InvoiceResponse>(() =>
    service.hubEnergy
      .getInvoicesByBillingReportId(billingReportId)
      .then(({ data }) => data.response ?? [])
      .catch((error) => {
        const errorMessage =
          getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao buscar os faturamentos";

        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );
};

export const invoicesGrid: ODataGridGeneratorConfig<HubEnergy.InvoiceResponse> =
  {
    datagrid: {
      noDataText: "Nenhum faturamento encontrado.",
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("invoicesGrid"),
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        caption: "Unidade Consumidora",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["entity.officialName", "entity.name", "entity.installationNumber"],
          "entity.taxId",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.consumerUnit.officialName}
            subtexts={[
              masks.cpfCnpj(data.consumerUnit.taxId),
              `Nº instalação: ${data.consumerUnit.installationNumber}`,
            ]}
          />
        ),
        allowHeaderFiltering: false,
      },
      {
        dataField: "price",
        caption: "Valor",
        cellRender: ({ data }) =>
          masks.currency(data.price, currencySymbol.BRL),
      },
      {
        dataField: "icms",
        caption: "ICMS",
        cellRender: ({ data }) => masks.currency(data.icms, currencySymbol.BRL),
      },
      {
        dataField: "orderItemId",
        caption: "Número da Entrega",
        dataType: "number",
      },
      {
        dataField: "invoiceStatus",
        caption: "Status de Faturamento",
        dataType: "string",
      },
      {
        dataField: "paymentStatus",
        caption: "Status de Pagamento",
        dataType: "string",
      },
      {
        format: "shortDate",
        sortOrder: "desc",
        dataType: "date",
        caption: "Data de Vencimento",
        dataField: "maturityDate",
      },
      {
        format: "shortDate",
        dataType: "date",
        caption: "Data de Pagamento",
        dataField: "payDate",
      },
    ],
  };
