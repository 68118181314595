import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { validationSchema } from "../_compose/notificacoes-form/notification-form.form";
import { NotificationFormValuesType } from "../_compose/notificacoes-form/notification-form.types";

export const useRavenNotificationDetails = (id: string | undefined) => {
  const [loading, setLoading] = useState(false);
  const [typeName, setTypeName] = useState("");

  const form = useForm<NotificationFormValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (id) {
        const payload = {
          ...values,
          "legal_representative?": values["legalRepresentative?"] === "true",
          rendered_name: values.renderedName,
        };

        const { data } = await service.raven.updateNotificationTypeById(
          id,
          payload,
        );
        setTypeName(data.data.renderedName);

        OToastManager.success("Tipo de notificação atualizado com sucesso");
      } else {
        OToastManager.warning("Notificação não encontrada");
      }
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    try {
      if (!id) return;
      setLoading(true);

      const { data } = await service.raven.getNotificationTypeById(id);

      setTypeName(data.data.renderedName);

      const formValue: NotificationFormValuesType = {
        ...data.data,
        "legalRepresentative?": String(data.data["legalRepresentative?"]),
      };

      reset(formValue);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [id, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    typeName,
    form,
    submit,
    loading,
  };
};
