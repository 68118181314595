import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

interface LatePaymentRateFieldProps {
  required?: boolean;
  disabled?: boolean;
}

export const LatePaymentRateField = ({
  required,
  disabled,
}: LatePaymentRateFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:juros_remuneratorios_atraso"
        dataLabel="juros_remuneratorios_atraso"
        id="latePaymentRate"
        name="latePaymentRate"
        tag="percentage"
        label={EstrategiaFormLabels.latePaymentRate}
        placeholder="Preencher"
        required={required}
        disabled={disabled}
      />
    </OCol>
  );
};
