import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { GetApiEmailConfigsResponse } from "services/clerk";
import { service } from "services/service";

export const useEmailTemplates = () => {
  const [emailTemplates, setEmailTemplates] =
    useState<GetApiEmailConfigsResponse>();

  const fetchEmailTemplates = useCallback(async () => {
    try {
      const { data } = await service.clerk.showEmailConfigs();
      setEmailTemplates(data);
    } catch {
      OToastManager.danger("Erro ao buscar os templates de e-mail.");
    }
  }, []);

  useEffect(() => {
    fetchEmailTemplates();
  }, [fetchEmailTemplates]);

  return {
    emailTemplates,
  };
};
