import {
  OBadge,
  OBreadcrumb,
  OBreadcrumbItem,
  OButton,
  OCard,
  OIcon,
  OTypography,
} from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";

export const BannerManagementSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column gap-3">
      <OBreadcrumb>
        <OBreadcrumbItem
          onClick={() =>
            navigate(
              corporateRouter.routes.workflow.product.bannerManagement.path,
            )
          }
          href="#"
        >
          <span className="d-flex gap-2">
            <OIcon category="fal" icon="fa-home-alt" size="md" />
            Gerenciamento de campanhas
          </span>
        </OBreadcrumbItem>
        <OBreadcrumbItem href="#">Sucesso</OBreadcrumbItem>
      </OBreadcrumb>

      <OCard className="p-4">
        <OBadge circle type="success-light" size="lg">
          <OIcon
            category="orq"
            icon="orq-status-success"
            type="success"
            size="xxl"
          />
        </OBadge>

        <OTypography size="xxl" type="dark" className="my-3">
          Campanha atualizada
        </OTypography>
        <OTypography type="dark-80">
          Você pode acompanhar, editar ou excluir a campanha na página do
          Gerenciador de campanhas.
        </OTypography>
      </OCard>

      <OButton
        outline
        size="md"
        onClick={() =>
          navigate(
            corporateRouter.routes.workflow.product.bannerManagement.path,
          )
        }
      >
        Voltar para Gerenciador de campanhas
      </OButton>
    </div>
  );
};
