import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetB2BCoreCustomersResponse } from "services/b2b/core/models";

export type B2BCoreCustomer = GetB2BCoreCustomersResponse[number];

const buildDetailsLink = (data: B2BCoreCustomer) => {
  const { routes } = corporateRouter;

  return routes.b2b.customer.b2bCustomers.companyUsers.path({
    customerId: data.identification,
  });
};

export const buildDataSource = (identification: string) =>
  dataSourceCustomStoreGenerator<B2BCoreCustomer>(() =>
    service.b2bCore
      .customers({ originatorIdentification: identification })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os clientes";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const customersGrid: ODataGridGeneratorConfig<B2BCoreCustomer> = {
  datagrid: {
    noDataText: "Esta empresa não possui clientes.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.b2b.customer.b2BCustomersCompanyUsersPage.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
    },
    {
      dataField: "identification",
      caption: "Nome/Documento",
      sortOrder: "asc",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["name", "contains", filterValue],
        "or",
        ["identification", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.name?.toUpperCase()}</OTypography>
          <OTypography type="default">
            {masks.cpfCnpj(data.identification)}
          </OTypography>
        </div>
      ),
    },
    {
      caption: "Status abertura de conta",
      dataField: "onboardings.status.label",
      cellRender: ({ data }) =>
        data.onboardings.status.label ? (
          <StatusBadge type={data.onboardings.status.type}>
            {data.onboardings.status.label}
          </StatusBadge>
        ) : (
          "Não iniciou abertura de conta"
        ),
    },
    {
      caption: "Data abertura de conta",
      dataField: "accountOpeningContractDate",
      dataType: "datetime",
      format: "shortDate",
      alignment: "center",
    },
    {
      dataField: "email",
      caption: "E-mail/Telefone",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["email", "contains", filterValue],
        "or",
        ["phone", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.email}</OTypography>
          <OTypography type="default">{masks.phone(data.phone)}</OTypography>
        </div>
      ),
    },
    {
      dataField: "originator.operator.identification",
      caption: "Operador",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["originator.operator.name", "contains", filterValue],
        "or",
        [
          "originator.operator.identification",
          "contains",
          filterValue.replace(/[^\w]/g, ""),
        ],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>
            {data.originator.operator.name?.toUpperCase()}
          </OTypography>
          <OTypography type="default">
            {masks.cpfCnpj(data.originator.operator.identification)}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "codCge",
      caption: "Código CGE",
      allowEditing: false,
    },
    {
      dataField: "lastModifiedAt",
      caption: "Data última atualização",
      dataType: "datetime",
      format: "shortDate",
      alignment: "center",
    },
    {
      dataField: "createdAt",
      caption: "Data do cadastro",
      dataType: "datetime",
      format: "shortDate",
      alignment: "center",
    },
  ],
};
