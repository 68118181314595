import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { BatchRequestsGrid } from "./batch-requests.grid";

export const BatchRequestsPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => BatchRequestsGrid(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lotes enviados" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
