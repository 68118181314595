import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { gridStorage } from "utils/storage";
import { OnboardingStatusTooltip, ReviewNotificationStatus } from "./_compose";
import { MonitorCompany, MonitorType } from "./company-monitor.types";
import { calculateDaysInOnboarding, serviceMap } from "./company-monitor.utils";

const reviewVisibleTypes: MonitorType[] = ["REVIEW_ACCOUNT"];
const dataUpdateVisibleTypes: MonitorType[] = ["DATA_UPDATE"];
const onboardingStatusVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "ENERGY",
  "PAYER",
];
const onboardingTypeVisibleTypes: MonitorType[] = ["ASSIGNOR"];
const originatorVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "PAYER",
  "OTHER",
];
const operatorVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "OTHER",
];
const channelVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "ENERGY",
  "OTHER",
];
const onboardingUpdatedAtVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "ENERGY",
  "PAYER",
];
const legalNatureVisibleTypes: MonitorType[] = [
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "ENERGY",
  "REVIEW_ACCOUNT",
  "OTHER",
  "CLIENT_DESK",
  "DATA_UPDATE",
];
const deskNameVisibleTypes: MonitorType[] = ["CLIENT_DESK"];
const deskCodeVisibleTypes: MonitorType[] = ["CLIENT_DESK"];
const deskSegmentNameVisibleTypes: MonitorType[] = ["CLIENT_DESK"];
const deskCosegmentNameVisibleTypes: MonitorType[] = ["CLIENT_DESK"];
const updatedAtVisibleTypes: MonitorType[] = [
  "ACCOUNT_PF",
  "ACCOUNT_PJ",
  "ASSIGNOR",
  "ENERGY",
  "OTHER",
  "REVIEW_ACCOUNT",
  "PAYER",
  "DATA_UPDATE",
];

const addUtcTimezone = (value: string) =>
  value && dayjs(`${value}Z`).format("DD/MM/YYYY HH:mm");

export const companyMonitorGrid = (type: MonitorType) =>
  ({
    datagrid: {
      noDataText: "Nenhuma empresa",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      remoteOperations: true,
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
        infoText: "Página {0} de {1} ({2} empresas)",
      },
      stateStoring: gridStorage("company-monitor"),
      dataSource: dataSourceCustomStoreGenerator<MonitorCompany>(
        (loadOptions) =>
          serviceMap[type](loadOptions as any) // sort type
            .then(({ data }) => ({
              data: data.data ?? [],
              totalCount: data.total_count,
            }))
            .catch(() => {
              const errorMessage = "Não foi possível buscar os optins";
              throw new Error(errorMessage);
            }),
      ),
    },
    columns: [
      {
        caption: "Detalhes",
        cellRender: ({ data: { identification } }) => (
          <DetailsLink href={buildCustomerLink(identification)} />
        ),
        allowExporting: false,
      },
      {
        dataField: "officialName",
        dataType: "string",
        caption: "Razão social",
        allowHeaderFiltering: false,
      },
      {
        dataField: "identification",
        dataType: "string",
        caption: "CNPJ/CPF",
        allowHeaderFiltering: false,
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "deskName",
        dataType: "string",
        caption: "Mesa",
        visible: deskNameVisibleTypes.includes(type),
        role: roles.empresas.product.clientDeskNameColumn.role,
      },
      {
        dataField: "deskCode",
        dataType: "number",
        caption: "Código da Mesa",
        visible: deskCodeVisibleTypes.includes(type),
        role: roles.empresas.product.clientDeskCodeColumn.role,
      },
      {
        dataField: "deskSegmentName",
        dataType: "string",
        caption: "Segmento",
        visible: deskSegmentNameVisibleTypes.includes(type),
        role: roles.empresas.product.clientDeskSegmentColumns.role,
      },
      {
        dataField: "deskCosegmentName",
        dataType: "string",
        caption: "Cosegmento",
        visible: deskCosegmentNameVisibleTypes.includes(type),
        role: roles.empresas.product.clientDeskSegmentColumns.role,
      },
      {
        dataField: "onboardingType",
        dataType: "string",
        caption: "Tipo de onboarding",
        visible: onboardingTypeVisibleTypes.includes(type),
      },
      {
        dataField: "onboardingStatus",
        dataType: "string",
        caption: "Status do onboarding",
        visible: onboardingStatusVisibleTypes.includes(type),
        cellRender: ({ data }) => (
          <OnboardingStatusTooltip onboardingStatus={data.onboardingStatus} />
        ),
      },
      {
        dataField: "dataUpdateStatus",
        dataType: "string",
        caption: "Status",
        visible: dataUpdateVisibleTypes.includes(type),
        cellRender: ({ data }) => (
          <OnboardingStatusTooltip onboardingStatus={data.dataUpdateStatus} />
        ),
      },
      {
        dataField: "uuid",
        dataType: "string",
        caption: "Identificador único",
        allowHeaderFiltering: false,
        role: roles.empresas.product.itColumns.role,
      },
      {
        dataField: "originatorName",
        dataType: "string",
        caption: "Originador",
        allowHeaderFiltering: false,
        visible: originatorVisibleTypes.includes(type),
        role: roles.empresas.product.b2bColumns.role,
      },
      {
        dataField: "originatorType",
        dataType: "string",
        caption: "Tipo de originador",
        visible: originatorVisibleTypes.includes(type),
        role: roles.empresas.product.b2bColumns.role,
      },
      {
        dataField: "operatorName",
        dataType: "string",
        caption: "Operador",
        allowHeaderFiltering: false,
        visible: operatorVisibleTypes.includes(type),
        role: roles.empresas.product.b2bColumns.role,
      },
      {
        dataField: "operatorType",
        dataType: "string",
        caption: "Tipo de operador",
        visible: operatorVisibleTypes.includes(type),
        role: roles.empresas.product.b2bColumns.role,
      },
      {
        dataField: "channel",
        dataType: "string",
        caption: "Canal de origem do cadastro",
        visible: channelVisibleTypes.includes(type),
        role: roles.empresas.product.b2bColumns.role,
      },
      {
        dataField: "onboardingUpdatedAt",
        dataType: "string",
        caption: "Dias em onboarding",
        name: "onboarding-duration",
        allowHeaderFiltering: false,
        visible: onboardingUpdatedAtVisibleTypes.includes(type),
        cellRender: ({ data }) => calculateDaysInOnboarding(data),
      },
      {
        dataField: "onboardingUpdatedAt",
        dataType: "datetime",
        caption: "Data de atualização do onboarding",
        allowHeaderFiltering: false,
        visible: onboardingUpdatedAtVisibleTypes.includes(type),
        format: addUtcTimezone,
      },
      {
        dataField: "reviewStatus",
        dataType: "string",
        caption: "Status",
        visible: reviewVisibleTypes.includes(type),
        cellRender: ({ data }) => (
          <OnboardingStatusTooltip onboardingStatus={data.reviewStatus} />
        ),
      },
      {
        dataField: "reviewNotificationStatus",
        dataType: "string",
        caption: "Tipo de Notificação",
        visible: reviewVisibleTypes.includes(type),
        cellRender: ({ data }) => (
          <ReviewNotificationStatus
            notificationStatus={data.reviewNotificationStatus}
          />
        ),
      },
      {
        dataField: "reviewUpdatedAt",
        dataType: "datetime",
        caption: "Última Atualização",
        allowHeaderFiltering: false,
        visible: reviewVisibleTypes.includes(type),
        format: addUtcTimezone,
      },
      {
        dataField: "reviewCreatedAt",
        dataType: "datetime",
        caption: "Revisão Criada em",
        allowHeaderFiltering: false,
        visible: reviewVisibleTypes.includes(type),
        format: addUtcTimezone,
      },
      {
        dataField: "dataUpdateUpdatedAt",
        dataType: "datetime",
        caption: "Última Atualização",
        allowHeaderFiltering: false,
        visible: dataUpdateVisibleTypes.includes(type),
        format: addUtcTimezone,
      },
      {
        dataField: "dataUpdateCreatedAt",
        dataType: "datetime",
        caption: "Atualização Criada em",
        allowHeaderFiltering: false,
        visible: dataUpdateVisibleTypes.includes(type),
        format: addUtcTimezone,
      },
      {
        dataField: "createdAt",
        dataType: "datetime",
        caption: "Cadastrado em",
        sortOrder: "desc",
        allowHeaderFiltering: false,
        format: addUtcTimezone,
      },
      {
        dataField: "updatedAt",
        dataType: "datetime",
        caption: "Data de atualização do cliente",
        allowHeaderFiltering: false,
        format: addUtcTimezone,
        visible: updatedAtVisibleTypes.includes(type),
      },
      {
        dataField: "birthDate",
        dataType: "datetime",
        caption: "Data de constituição/nascimento",
        allowHeaderFiltering: false,
        format: addUtcTimezone,
        role: roles.empresas.product.legalNatureColumns.role,
      },
      {
        dataField: "legalNature",
        dataType: "string",
        caption: "Natureza legal",
        visible: legalNatureVisibleTypes.includes(type),
        role: roles.empresas.product.legalNatureColumns.role,
      },
    ],
  } satisfies ODataGridGeneratorConfig<MonitorCompany>);
