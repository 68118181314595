import { OTypography } from "@maestro/react";
import styled from "styled-components";

export const OTruncateTypography = styled(OTypography)`
  overflow: hidden;
  p {
    overflow: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
