import { OOption, ORFieldSelect } from "@maestro/react";
import { useWorkflow } from "pages/workflow/components/hooks/use-workflow.context";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { getComponentPropertyFieldId } from "../component-property-field.utils";

interface ConditionPropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  handleSubmitComponentProperty: (
    formStepCardComponentId: number,
    propertyId: number,
    value: string,
  ) => Promise<void>;
  formStepCardComponentId: number;
}

export const ConditionPropertyField = ({
  componentProperty,
  formStepCardComponentId,
  handleSubmitComponentProperty,
}: ConditionPropertyFieldProps) => {
  const valueRef = useRef("");

  const { getValues, setValue, watch } = useFormContext();

  const {
    conditions: { value: allConditions },
  } = useWorkflow();

  const property = getComponentPropertyFieldId(
    componentProperty.name,
    componentProperty.id,
  );
  const fieldWatch = watch(property);

  const handleFocusOut = useCallback(() => {
    return handleSubmitComponentProperty(
      formStepCardComponentId,
      componentProperty.id,
      getValues(property),
    );
  }, [
    getValues,
    handleSubmitComponentProperty,
    formStepCardComponentId,
    componentProperty,
    property,
  ]);

  useEffect(() => {
    if (componentProperty.value === undefined) return;

    valueRef.current = componentProperty.value;

    setValue(property, componentProperty.value);
  }, [setValue, componentProperty, property]);

  useEffect(() => {
    if (valueRef.current === fieldWatch) return;

    const propertyInput = document.getElementById(property);

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [
    handleFocusOut,
    componentProperty.name,
    componentProperty.id,
    property,
    fieldWatch,
  ]);

  const conditionPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={componentProperty.name + componentProperty.id}
        id={property}
        name={property}
        placeholder="Selecione"
        label={componentProperty.description}
        required={componentProperty.isRequired}
      >
        <div>
          {allConditions?.map((cond) => (
            <OOption key={cond.conditionName} value={cond.conditionName}>
              {cond.description}
            </OOption>
          ))}
        </div>
      </ORFieldSelect>
    );
  }, [componentProperty, allConditions, property]);

  return <>{conditionPropertyField}</>;
};
