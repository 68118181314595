import { OCheckbox, OLabel } from "@maestro/react";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useCollectionsAgreementContext } from "../../../convenio";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";

export const DefaultAgreementInput = () => {
  const { agreementsList, agreementToUpdate } =
    useCollectionsAgreementContext();
  const { setValue, watch } =
    useFormContext<UpsertCollectionAgreementFormFields>();

  const showInput = useMemo(() => {
    const hasDefaultAgreement = agreementsList.some(
      ({ id }) => id === "DEFAULT",
    );
    return !agreementToUpdate && !hasDefaultAgreement;
  }, [agreementToUpdate, agreementsList]);

  const isDefaultAgreementWatched = watch("isDefaultAgreement");

  const handleChangeDefaultAgreementCheckbox = useCallback(() => {
    const newValue = !isDefaultAgreementWatched;
    setValue("isDefaultAgreement", !isDefaultAgreementWatched);
    setValue("id", newValue ? "DEFAULT" : undefined, { shouldValidate: true });
  }, [isDefaultAgreementWatched, setValue]);

  useEffect(() => {
    if (!showInput && !agreementToUpdate) setValue("isDefaultAgreement", false);
  }, [agreementToUpdate, setValue, showInput]);

  if (!showInput) return null;

  return (
    <div className="d-flex align-items-center gap-2">
      <OCheckbox
        id="isDefaultAgreement"
        value="isDefaultAgreement"
        checked={isDefaultAgreementWatched}
        onClick={handleChangeDefaultAgreementCheckbox}
        size="sm"
      />
      <OLabel htmlFor="isDefaultAgreement" type="dark">
        Definir como convênio padrão?
      </OLabel>
    </div>
  );
};
