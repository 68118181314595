export const emptyIntegrationContact = {
  name: "",
  type: "",
  email: "",
  phone: "",
};

export const emptyIntegrationAccount = {
  accountNumber: "",
  accountDigit: "",
  branch: "",
};

export const emptyIntegration = {
  id: "",
  productId: "",
  companyDocument: "",
  type: "VAN",
  status: "PENDING",
  contacts: [],
  accounts: [],
  createdAt: "",
  vanName: "",
  observations: [],
  layoutId: "",
  additionalInfo: {
    inboundFilename: "",
    outboundFilename: "",
  },
};
