import * as yup from "yup";

export const addRelationshipValidationSchema = yup.object({
  sourceName: yup.string().nullable(),
  sourceType: yup.string().nullable(),
  targetName: yup.string().nullable(),
  targetType: yup.string().nullable(),
  relationshipOutputId: yup
    .string()
    .nullable()
    .required("Este campo é obrigatório"),
});

export const addRelationshipFormDefaultValues = {
  sourceName: "",
  sourceType: "",
  targetName: "",
  targetType: "",
  relationshipOutputId: "",
};
