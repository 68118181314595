import { Fragment } from "react";
import {
  OBadge,
  OCard,
  OCol,
  ODivider,
  OExpander,
  OExpanderHeader,
  OExpanderContent,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import {
  resolveRuleDescription,
  minimumSigners,
  dateFormatter,
  badgeInfo,
} from "./rule-signatory.utils";
import {
  ContractDetails,
  RuleStatusColor,
  RuleStatusTitle,
} from "../../contracts-grid.types";

interface RulesSignatoryProps {
  contract: ContractDetails;
}

export const RulesSignatory = ({ contract }: RulesSignatoryProps) => {
  return (
    <OCard className="p-4">
      {contract.rules.map((rule, index) => (
        <Fragment key={index}>
          <OExpander>
            <OExpanderHeader>
              <ORow>
                <OCol justify="start">
                  <ORow>
                    <OTypography>Regra de aprovação {index + 1}</OTypography>
                    <OTypography type="default" className="mt-2">
                      {resolveRuleDescription(rule)}
                    </OTypography>
                  </ORow>
                </OCol>
                <OCol justify="end">
                  <OBadge type={RuleStatusColor[rule.status]}>
                    <OTypography>{RuleStatusTitle[rule.status]}</OTypography>
                  </OBadge>
                </OCol>
              </ORow>
            </OExpanderHeader>
            <OExpanderContent>
              {rule.groups.map((group, idx) => (
                <Fragment key={group.name}>
                  <ORow className="mt-3">
                    <OCol>
                      <OTypography>
                        {group.name
                          ? `Grupo ${idx + 1} -  ${group.name}`
                          : `Grupo ${idx + 1}`}
                      </OTypography>
                    </OCol>
                    <OCol justify="end" align="center">
                      <OTypography>{minimumSigners(group)}</OTypography>
                    </OCol>
                  </ORow>

                  {group.signatories.map((signatory) => {
                    const { cpf, name, signed, signedAt, userCountry } =
                      contract.signatories[signatory];
                    const [badgeType, iconType, icon] = badgeInfo(signed);

                    return (
                      <ORow
                        key={name}
                        justify="start"
                        align="center"
                        className="mt-2"
                      >
                        <OCol align="center">
                          <OTypography type="secondary-80">
                            {!!userCountry && userCountry !== "BR"
                              ? `${userCountry} - ${cpf}`
                              : cases.title(name) || masks.cpf(cpf)}
                          </OTypography>
                        </OCol>
                        <OCol
                          sm="auto"
                          align="center"
                          justify="end"
                          className="gap-2"
                        >
                          {signedAt && (
                            <OTypography size="sm" type="secondary-80">
                              Aprovado em {dateFormatter(signedAt)}
                            </OTypography>
                          )}
                          <OBadge
                            className="align-middle"
                            size="sm"
                            type={badgeType}
                            circle
                          >
                            <OIcon
                              className="py-1"
                              category="orq"
                              icon={icon}
                              type={iconType}
                            />
                          </OBadge>
                        </OCol>
                      </ORow>
                    );
                  })}
                </Fragment>
              ))}
            </OExpanderContent>
          </OExpander>
          {index !== contract.rules.length - 1 && <ODivider className="my-4" />}
        </Fragment>
      ))}
    </OCard>
  );
};
