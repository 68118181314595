export const fileToBlob = async (file: File) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export const getFormData = async (file: File, fileType?: string) => {
  const formData = new FormData();
  const blobFile = await fileToBlob(file);

  formData.append("File", blobFile, file.name);

  if (fileType) formData.append("FileType", fileType);

  return formData;
};
