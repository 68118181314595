import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { System } from "services/gatekeeper/models";

const redirectDetailLink = (name: string) => {
  const stringifiedName = JSON.stringify(name);
  const baseUrl =
    corporateRouter.routes.ferramentas.product.gatekeeper.systems.details.path;

  return `${baseUrl}?name=${stringifiedName}`;
};

export const grid: ODataGridGeneratorConfig<System> = {
  datagrid: {
    noDataText: "Nenhum sistema encontrado",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      role: roles.ferramentas.product.gatekeeperSystemsDetailsPage.role,
      cellRender: ({ data }) =>
        data.name ? (
          <DetailsLink href={redirectDetailLink(data.name)} />
        ) : undefined,
    },
    { dataField: "name", caption: "Nome" },
    {
      dataField: "token",
      caption: "Token",
    },
    { dataField: "webhook", caption: "Webhook" },
    {
      caption: "Profiles",
      width: "300px",
      cellRender: ({ data }) =>
        data.profiles && <span>{data.profiles.join("; ")}</span>,
    },
    {
      caption: "Roles",
      width: "300px",
      cellRender: ({ data }) =>
        data.roles && <span>{data.roles.join("; ")}</span>,
    },
  ],
};
