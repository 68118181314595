import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useCitySelector } from "./city-selector.hook";

interface CitySelectorProps {
  disabled?: boolean;
}

export const CitySelector = ({ disabled }: CitySelectorProps) => {
  const { cities, getCities, hasFetchError, loading, stateWatcher } =
    useCitySelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={cities}
        id="address.city"
        name="address.city"
        label="Cidade"
        required
        disabled={!stateWatcher || disabled}
        placeholder={
          stateWatcher ? "Selecionar" : "Primeiro selecione o Estado"
        }
      />
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={() => getCities()} />
      )}
    </div>
  );
};
