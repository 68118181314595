import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useProcessorConfigField = ({
  processorConfigProperty: {
    processorPropertyId,
    processorConfigId,
    name,
    value,
  },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    value: chidProcessorConfigs,
    callService: getChildProcessorConfigs,
    hasError: hasErrorChidProcessorConfigs,
    loading,
  } = useServiceCall(service.hubCreditManager.getChildProcessorConfigs);

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    name,
    processorPropertyId,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    hasErrorChidProcessorConfigs &&
      OToastManager.danger(
        "Não foi possivel buscar as opções de configurações de propriedade dos workflows filho. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorChidProcessorConfigs]);

  useEffect(() => {
    if (!chidProcessorConfigs) {
      getChildProcessorConfigs(processorPropertyId);
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    chidProcessorConfigs,
    value,
    setValue,
    getChildProcessorConfigs,
    reset,
    processorPropertyId,
  ]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (!fieldWatch || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, processorPropertyId, processorConfigId);
  }, [
    fieldWatch,
    handleSubmitProperty,
    processorPropertyId,
    processorConfigId,
    value,
    name,
  ]);

  return { chidProcessorConfigs, loading };
};
