import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const usePlanCard = () => {
  const { customer } = useCustomer();

  const {
    value: planInfo,
    callService: getCustomerPlans,
    error,
    loading,
  } = useServiceCall(service.clerk.getCustomerPlans);

  const getPlanInfo = useCallback(
    () => getCustomerPlans({ identification: customer.identification }),
    [customer.identification, getCustomerPlans],
  );

  useEffect(() => {
    getPlanInfo();
  }, [getPlanInfo]);

  return {
    planInfo,
    getPlanInfo,
    hasError: !!error,
    isNotFoundError: isAxiosError(error) && error.response?.status === 400,
    loading,
  };
};
