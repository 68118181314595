import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GetAdminProcessorConfigPropertyResponse } from "services/hubcreditmanager/models/responses/get-admin-processor-config-properties.response";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../routes/workflow.route-params";

export const useProcessorConfigPropertiesPageContent = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const form = useForm();

  const [propertiesDetails, setPropertiesDetails] = useState<
    GetAdminProcessorConfigPropertyResponse[]
  >([]);

  const getProperties = useCallback(async () => {
    try {
      if (!processorId) return;
      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsProperties(
          processorId,
        );
      setPropertiesDetails(data);
    } catch (err) {
      OToastManager.danger("Erro para retornar as propriedades.");
    }
  }, [processorId]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  return {
    form,
    propertiesDetails,
  };
};
