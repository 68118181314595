import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useEditTCOStrategies } from "./edit-tco-strategy.hook";
import { TCOStrategiesForm } from "../_compose/formulario/tco-strategies-form.component";

export const EditTCOStrategies = () => {
  const { form, loading, onSubmit, setValue, watch } = useEditTCOStrategies();

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Editar TCO" />}
        actions={
          <LoadingButton
            loading={loading}
            dataAction="estrategias_tco:botao:salvar"
            dataLabel="salvar"
            onClick={onSubmit}
          >
            Salvar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <TCOStrategiesForm setValue={setValue} watch={watch} />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
    </>
  );
};
