export const statusMap = {
  PENDENTE: {
    label: "Pendente",
    type: "warning",
  },
  PENDENTE_BIOMETRIA: {
    label: "Pendente biometria",
    type: "warning",
  },
  PENDENTE_ENVIO: {
    label: "Pendente envio",
    type: "warning",
  },
  PENDENTE_VALIDACAO_SEGURANCA: {
    label: "Pendente validação segurança",
    type: "info",
  },
  EM_ANALISE: {
    label: "Em análise",
    type: "info",
  },
  PENDENTE_AJUSTE: {
    label: "Pendente ajuste",
    type: "warning",
  },
  REPROVADO: {
    label: "Reprovado",
    type: "danger",
  },
  CONCLUIDO: {
    label: "Concluído",
    type: "success",
  },
} as const;
