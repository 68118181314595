import { JSX } from "@maestro/core";
import {
  OBadge,
  OField,
  OIcon,
  OInputText,
  OTypography,
  TooltipContainer,
} from "@maestro/react";
import { FieldProps } from "@maestro/react/dist/types/utils";
import { UnstyledButton } from "components/unstyled-button";
import { FieldError } from "react-hook-form";
import { useInputMultiple } from "./input-multiple.hook";

interface ORFieldInputMultipleProps extends JSX.OInputText, FieldProps {
  valuesLabel?: string;
}

export const InputMultiple = (props: ORFieldInputMultipleProps) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    valuesLabel = "Valores adicionados:",
    ...rest
  } = props;

  const { form, inputValue, keyDownListener, removeValue, setInputValue } =
    useInputMultiple(name);

  const { formState, getFieldState, watch } = form;

  const { error: fieldErrors } = getFieldState(name, formState);

  const outerValue = watch(name) as string[];

  return (
    <div className="w-100">
      <OField
        htmlFor={rest.id}
        error={!!fieldErrors}
        message={
          fieldErrors?.message ??
          (fieldErrors as FieldError[] | undefined)?.[0]?.message
        }
        description={description}
        label={label}
        labelType={labelType}
        required={required}
        disabled={rest.disabled}
        labelSize={rest.labelSize ?? "sm"}
      >
        {tooltip && (
          <TooltipContainer
            position={tooltipPosition}
            maxWidth={tooltipMaxWidth}
          >
            {tooltip}
          </TooltipContainer>
        )}
        <OInputText
          {...rest}
          placeholder={
            rest.placeholder ?? "Digite e aperte 'Enter' para adicionar"
          }
          name={name}
          value={inputValue}
          onInput={(evt) =>
            setInputValue((evt.target as HTMLOInputTextElement).value as string)
          }
          onKeyDown={keyDownListener}
        />
      </OField>
      {!!outerValue?.length && (
        <OTypography size="sm" className="mb-2">
          {valuesLabel}
        </OTypography>
      )}
      <div className="d-flex flex-wrap gap-2" key={outerValue?.length}>
        {outerValue?.map((value) => (
          <OBadge key={value} type="dark" size="sm" rounded outline>
            <div className="d-flex align-items-center gap-2">
              {value}
              <UnstyledButton onClick={() => removeValue(value)}>
                <OIcon category="fal" icon="fa-times" />
              </UnstyledButton>
            </div>
          </OBadge>
        ))}
      </div>
    </div>
  );
};
