export const bankingRouteParams = {
  account: "account",
  agency: "agency",
  ownerId: "ownerId",
  reconResultExternalId: "reconResultExternalId",
} as const;

type BankingParams = Record<keyof typeof bankingRouteParams, string>;

export type BankingClienteContasByAccountByAgencyRouteParams = Pick<
  BankingParams,
  "account" | "agency"
>;

export type BankingClienteLimiteMaisByOwnerIdRouteParams = Pick<
  BankingParams,
  "ownerId"
>;

export type BankingClienteReconResultByIdRouteParams = Pick<
  BankingParams,
  "reconResultExternalId"
>;
