import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { MasterDetail } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, tradesGrid } from "./trades.grid";
import { TradesMasterDetail } from "./trades.master-detail";

export const Trades = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Trades" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={tradesGrid}
        dataSource={dataSource}
      >
        <MasterDetail component={TradesMasterDetail} enabled />
      </ODataGridGenerator>
    </GridTemplate>
  );
};
