import { helpers } from "@maestro/utils";
import { service } from "services/service";

export const assetStatusMap: Record<string, string> = {
  NORMAL: "Normal",
  OPEN: "Em aberto",
  OVERDUE: "Inadimplente",
  RENEGOTIATED: "Renegociado",
  PAID: "Pago",
  SETTLED: "Quitado",
  UNDEFINED: "Indeterminado",
};

export const downloadBankslip = async (bankslipId: string) => {
  const { data: blob } = await service.fidc.getBankSlip(bankslipId);

  helpers.downloadBlobFile("boleto.pdf", blob);
};
