import { BankIconSVG } from "../financial-institution-icon.types";

export const BankNubankSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#8A05BE" />
      <path
        d="M84.08 64.949c0 1.866-2.228 4.16-4.04 4.16h-1.65a68259 68259 0 000-26.502.51.51 0 01.507-.517h4.672c.28 0 .51.236.51.521V64.95zm-7.614 8.169c0 1.181-.578 3.16-4.452 3.16-7.509 0-12.117-5.879-12.117-10.096 0 0-.009-13.635-.009-23.57a.51.51 0 01.51-.518h4.677c.28 0 .51.234.51.522v21.908c0 3.62 2.867 6.555 6.4 6.555h4.484l-.002 2.039zm7.458-32.993h-5.382c-1.144 0-2.076.956-2.076 2.128v26.86h-4.484c-2.474 0-4.479-2.055-4.479-4.59v-22.27c0-1.175-.93-2.129-2.077-2.129h-5.384c-1.147 0-2.078.954-2.08 2.129-.002 9.71.01 23.93.01 23.93 0 7.474 8.177 12.063 14.04 12.063 3.932 0 6.375-1.97 6.375-5.141v-2.026h1.651c2.897 0 5.962-3.15 5.962-6.13V42.253c.002-1.175-.928-2.129-2.076-2.129v.001zm-31.807 28.51a.512.512 0 01-.51.516h-4.676a.518.518 0 01-.512-.52v-21.91c0-3.62-2.867-6.556-6.4-6.556h-4.483v-2.038c0-1.179.578-3.16 4.453-3.16 7.508 0 12.119 5.879 12.119 10.096 0 0 .008 13.701.008 23.57zm-23.686.52a.516.516 0 01-.509-.522V46.3c0-1.867 2.228-4.164 4.04-4.164h1.651c-.002 16.358-.002 24.728 0 26.496a.516.516 0 01-.51.521h-4.671l-.001.002zm25.596-24.091C54.028 37.589 45.851 33 39.99 33c-3.933 0-6.376 1.969-6.376 5.141v2.025h-1.65c-2.898 0-5.963 3.15-5.963 6.131v22.696c0 1.175.93 2.128 2.076 2.128h5.381c.034 0 .062-.009.096-.009 1.1-.053 1.98-.978 1.98-2.119v-26.86h4.485c2.473 0 4.479 2.055 4.479 4.59v22.27c0 1.175.93 2.128 2.076 2.128h5.385c1.147 0 2.077-.95 2.077-2.128.004-9.892-.007-23.93-.007-23.93z"
        fill="#fff"
      />
    </svg>
  );
};
