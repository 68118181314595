import {
  OField,
  OIcon,
  OLink,
  OLoader,
  OUpload,
  OUploadInput,
  OUploadLabel,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { useUploadCedentes } from "../../upload-cedentes.context";
import { useFileUpload } from "./file-upload.hook";
import { UploadContainer } from "../../../../../../../antecipacao-de-cartoes/components";

export const FileUpload = () => {
  const { verifyCedentes, validationHasError, validationLoading } =
    useUploadCedentes();

  const {
    handleAddFile,
    hasParseError,
    readerLoading,
    reset,
    setHasParseError,
  } = useFileUpload();

  if (hasParseError)
    return (
      <div>
        <DefaultErrorComponent
          title="Erro ao processar a planilha"
          paragraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
          callback={() => {
            setHasParseError(false);
            reset();
          }}
        />
      </div>
    );

  if (validationHasError)
    return (
      <div>
        <DefaultErrorComponent
          title="Erro ao enviar o arquivo"
          callback={() => {
            verifyCedentes();
          }}
        />
      </div>
    );

  const disabled = readerLoading || validationLoading;

  return (
    <div>
      <UploadContainer>
        <OField message="Selecione uma estratégia">
          <OUpload onAddFile={handleAddFile}>
            <OUploadInput
              dataAction="upload_cedentes:upload:upload_cedentes"
              dataLabel="upload_cedentes"
              id="upload"
              name="upload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              multiple={false}
              disabled={disabled}
            />
            <OUploadLabel htmlFor="upload" size="md" disabled={disabled}>
              Clique ou arraste o arquivo aqui
              <OIcon category="orq" icon="orq-upload" size="lg" />
            </OUploadLabel>
          </OUpload>
        </OField>
      </UploadContainer>

      <div className="d-flex flex-column align-items-center gap-3 mt-4">
        <OLink href="/files/modelo_importacao_de_cedente.xlsx">
          <OIcon category="fas" icon="fa-external-link" className="me-2" />
          Baixar modelo
        </OLink>
        {(readerLoading || validationLoading) && <OLoader />}
      </div>
    </div>
  );
};
