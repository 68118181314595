import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const HistoryLink = () => {
  const { routes } = corporateRouter;

  return (
    <StandardLink
      role={roles.renegociacao.customer.monitorHistoryPage.role}
      href={routes.renegociacao.customer.monitor.history.path}
    >
      Ver histórico
    </StandardLink>
  );
};
