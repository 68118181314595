import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const ApproveContingency = () => {
  return (
    <RouterButton
      dataAction="ferramentas:botao:concluir"
      dataLabel="concluir"
      href={
        corporateRouter.routes.cadastro.customer.tools.approveContingency.path
      }
      role={roles.cadastro.customer.toolsApproveContingencyPage.role}
    >
      Concluir
    </RouterButton>
  );
};
