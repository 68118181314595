import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useInviteCard = () => {
  const { customer } = useCustomer();

  const { value, callService, error, loading } = useServiceCall(
    service.clerk.getWorkflowDetails,
  );

  const getInviteInfo = useCallback(() => {
    callService({
      identification: customer.identification,
    });
  }, [customer.identification, callService]);

  useEffect(() => {
    getInviteInfo();
  }, [getInviteInfo]);

  return {
    value,
    getInviteInfo,
    hasError: !!error,
    isNotFoundError: isAxiosError(error) && error.response?.status === 400,
    loading,
  };
};
