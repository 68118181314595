import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const PnlStrategyField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:pnl_estrategia"
        dataLabel="pnl_estrategia"
        id="pnlStrategy"
        name="pnlStrategy"
        tag="number"
        placeholder="Preencher"
        label={EstrategiaFormLabels.pnlStrategy}
      />
    </OCol>
  );
};
