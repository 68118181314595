import {
  OModal,
  OModalHeader,
  OTypography,
  OModalBody,
  ORFieldInputDate,
  OModalFooter,
  OButton,
  ORFieldSelectMultiple,
  OOptionMultiple,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import { modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { useEffect } from "react";
import { useSearchModal } from "./search-modal.hooks";
import { CompanySearch } from "../../../entuba-operacoes/_steps/_compose/company-search/company-search.component";
import { GroupedValuesForm } from "./search-modal.form";

export const searchModalId = "search-modal";

export const SearchModal = ({
  setParams,
}: {
  setParams: (newParams: GroupedValuesForm) => void;
}) => {
  const { form, submit, clear } = useSearchModal(setParams);

  const { watch } = form;

  const startDateWatch = watch("dataVencimentoInicial");
  const endDateWatch = watch("dataVencimentoFinal");

  const { value: brands, callService: getBrands } = useServiceCall(
    service.recebiveisCartao.getBrands,
  );

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  return (
    <OModal id={searchModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <CompanySearch
              mode="Cedente"
              label="Nome ou identificação dos cedentes"
              name="cedentes"
              placeholder="Selecione o(s) cedente(s)"
              multiple
            />
            <CompanySearch
              mode="Sacado"
              label="Nome ou identificação dos sacados"
              name="sacados"
              placeholder="Selecione o(s) sacado(s)"
              multiple
            />
            <ORFieldSelectMultiple
              id="bandeiras"
              name="bandeiras"
              label="Bandeiras de cartão"
            >
              {brands?.bandeiras?.map((brand) => (
                <OOptionMultiple key={brand.id} value={String(brand)}>
                  {brand.name}
                </OOptionMultiple>
              ))}
            </ORFieldSelectMultiple>
            <ORFieldInputDate
              id="dataVencimentoInicial"
              name="dataVencimentoInicial"
              label="Data de vencimento inicial"
              disabledDate={(date) =>
                endDateWatch
                  ? dayjs(date.toDate()).isAfter(
                      dayjs(endDateWatch.toDate()).startOf("day"),
                    )
                  : false
              }
            />
            <ORFieldInputDate
              id="dataVencimentoFinal"
              name="dataVencimentoFinal"
              label="Data de vencimento final"
              disabledDate={(date) =>
                startDateWatch
                  ? dayjs(date.toDate()).isBefore(
                      dayjs(startDateWatch.toDate()).startOf("day"),
                    )
                  : false
              }
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton type="dark" outline onClick={() => clear()}>
            Limpar
          </OButton>
          <OButton
            onClick={() => {
              submit();
              modalManager.hide(searchModalId);
            }}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
