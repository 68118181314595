export enum SettlementTradePaymentStatus {
  CREATED = "CREATED",
  WAITING_CALLBACK = "WAITING_CALLBACK",
  VALID = "VALID",
  INVALID = "INVALID",
  CANCELLED = "CANCELLED",
  REMOVED_BY_CUSTOMER = "REMOVED_BY_CUSTOMER",
  ERROR = "ERROR",
  INTEGRATION_ERROR = "INTEGRATION_ERROR",
  COMPLETED = "COMPLETED",
}

export interface SettlementTradeOData {
  SettlementTradeId: string;
  PaymentAmount: number;
  PaymentStatus: SettlementTradePaymentStatus;
  PayerName: string;
  PayerDocument: string;
  PayeeName: string;
  PayeeDocument: string;
  PaymentError: string;
}

export interface GetOdataSettlementTradesOdataResponse {
  "@odata.context": string;
  "@odata.count": number;
  value: SettlementTradeOData[];
}
