import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { CreatedOperationsWithoutRegistryResponse } from "../../intubate-operations-without-registry.types";

export const resultGrid: ODataGridGeneratorConfig<CreatedOperationsWithoutRegistryResponse> =
  {
    datagrid: {
      noDataText: "Vazio.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "error",
        caption: "Status",
        cellRender: ({ value }) => (
          <StatusBadge type={value ? "danger" : "success"}>
            {value
              ? "Houve um problema na criação dos recebíveis"
              : "Recebíveis enviados para a tela de aprovações do portal"}
          </StatusBadge>
        ),
      },
      {
        dataField: "operation.payerIdentification",
        dataType: "string",
        caption: "Sacado",
        allowHeaderFiltering: false,
        format: (value) => masks.cnpj(value),
      },
      {
        dataField: "operation.payeeIdentification",
        dataType: "string",
        caption: "Cedente",
        allowHeaderFiltering: false,
        format: (value) => masks.cnpj(value),
      },
      {
        dataField: "error",
        dataType: "string",
        caption: "Erro",
        allowHeaderFiltering: false,
      },
    ],
  };
