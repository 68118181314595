import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import {
  BankAccountWithTaxId,
  Contact,
  QuoteGenericBankDataForm,
} from "./generic-bank-data.types";
import {
  buildAccountsOptions,
  buildCedenteTaxIdsOptions,
  validateTaxIdRoot,
} from "./generic-bank-data.utils";

export const useGenericBankData = (
  cedenteTaxIds: string[],
  setBankAccount: (account: BankAccountWithTaxId | undefined) => void,
) => {
  const [accountTaxIds, setAccountTaxIds] = useState<string[]>(cedenteTaxIds);
  const [contactTaxId, setContactTaxId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [contact, setContact] = useState<Contact>();

  const {
    callService: getContact,
    hasError: contactHasError,
    loading: contactLoading,
    value: contactValue,
  } = useServiceCall(service.adminBankinghub.getContactByHolder);

  const form = useForm<QuoteGenericBankDataForm>();

  const { watch } = form;

  const taxIdWatch = watch("taxId");

  const clearTaxId = useCallback(() => {
    setContact(undefined);
    setContactTaxId(undefined);
    setErrorMessage(undefined);
    setBankAccount(undefined);
  }, [setBankAccount]);

  useEffect(() => {
    const cleanTaxId = taxIdWatch?.match(/\d/g)?.join("");

    if (cleanTaxId?.length === 14) {
      if (validators.cnpj(cleanTaxId)) {
        if (validateTaxIdRoot(accountTaxIds, cleanTaxId)) {
          if (!accountTaxIds.includes(cleanTaxId))
            setAccountTaxIds((_cedenteTaxIds) => [
              ..._cedenteTaxIds,
              cleanTaxId,
            ]);
        } else {
          setErrorMessage(
            "O CNPJ não possui a mesma raiz do cedente da operação.",
          );
        }
      } else {
        setErrorMessage("O CNPJ digitado é inválido.");
      }
    }
  }, [accountTaxIds, taxIdWatch]);

  useEffect(() => {
    if (contactHasError) {
      setErrorMessage("Erro ao buscar as contas deste CNPJ.");
    }
  }, [contactHasError]);

  useEffect(() => {
    const newContact = contactValue?.find((c) => c.same_ownership);
    setContact(newContact);
    if (newContact) setErrorMessage(undefined);
  }, [contactValue]);

  const accountsOptions = useMemo(
    () =>
      buildAccountsOptions(contact, (account) => {
        setBankAccount({ ...account, tax_id: contactTaxId as string });
      }),
    [contact, contactTaxId, setBankAccount],
  );

  const cedenteTaxIdsOptions = useMemo(
    () =>
      buildCedenteTaxIdsOptions(accountTaxIds, (taxId) => {
        getContact(taxId);
        setContactTaxId(taxId);
      }),
    [accountTaxIds, getContact],
  );

  return {
    form,
    cedenteTaxIdsOptions,
    accountsOptions,
    loading: contactLoading,
    errorMessage,
    clearTaxId,
  };
};
