import { ConfigurationModal } from "components/configuration-modal";
import {
  AuthorizationGuard,
  BehaviorGuard,
  ImpersonateGuard,
  UserGuard,
} from "components/render-guard";
import { BehaviorProvider } from "contexts/behavior";
import { CustomerProvider } from "contexts/customer";
import { ThemeProvider } from "contexts/theme";
import { UserProvider } from "contexts/user";
import { loadMessages, locale } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import { BaseRoutes } from "routes/routes";
import { OrqTheme } from "@orquestra-web/react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { env } from "utils/environments";
import { User } from "oidc-client-ts";
import "@orquestra-web/react/dist/css/orquestra-web.css";
import "@maestro/core/dist/components/components.css";
import "@maestro/core/dist/style/tokens.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import "./App.css";
import "./dark-mode.css";
import { ImpersonateProvider } from "contexts/impersonate";
import { IdleGuard } from "components/idle-guard";

loadMessages(ptMessages);
locale("pt");

const oidcConfig: AuthProviderProps = {
  authority: env.OIDC_AUTHORITY,
  client_id: env.OIDC_CLIENT_ID,
  redirect_uri: env.OIDC_REDIRECT_URI,
  response_type: "code",
  response_mode: "query",
  scope: "openid",
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export const App = () => {
  return (
    <ThemeProvider>
      <OrqTheme>
        <AuthProvider {...oidcConfig}>
          <BehaviorProvider>
            <BehaviorGuard>
              <UserProvider>
                <IdleGuard>
                  <ImpersonateProvider>
                    <ImpersonateGuard>
                      <AuthorizationGuard>
                        <UserGuard>
                          <CustomerProvider>
                            <BaseRoutes />
                          </CustomerProvider>
                        </UserGuard>
                      </AuthorizationGuard>
                    </ImpersonateGuard>
                  </ImpersonateProvider>
                </IdleGuard>
              </UserProvider>
            </BehaviorGuard>
            <ConfigurationModal />
          </BehaviorProvider>
        </AuthProvider>
      </OrqTheme>
    </ThemeProvider>
  );
};
