import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import {
  newDeflatorDefaulValues,
  newDeflatorValidationSchema,
} from "./new-deflator.form";
import { NewDeflatorForm } from "./new-deflator.types";

export const useNewDeflator = () => {
  const {
    callService: addDeflator,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.addDeflator);

  const {
    callService: getAcquirers,
    hasError: acquirersHasError,
    loading: acquirersLoading,
    value: acquirers,
  } = useServiceCall(service.recebiveisCartao.getAcquirers);

  const form = useForm<NewDeflatorForm>({
    defaultValues: newDeflatorDefaulValues,
    resolver: yupResolver(newDeflatorValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        addDeflator({
          company_tax_id: values.taxId,
          credenciadora_id: values.acquirerId,
          deflation_value: values.deflator,
        });
      }),
    [addDeflator, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    getAcquirers();
  }, [getAcquirers]);

  /** Error warning */
  useEffect(() => {
    if (acquirersHasError)
      OToastManager.danger("Erro ao buscar as credenciadoras");
  }, [acquirersHasError]);

  /** Error warning */
  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao adicionar o deflator");
  }, [hasError]);

  /** Success */
  useEffect(() => {
    if (value) OToastManager.success("Deflator adicionado com sucesso");
  }, [value]);

  return {
    acquirers,
    acquirersLoading,
    form,
    loading,
    submit,
  };
};
