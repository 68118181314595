import { yupResolver } from "@hookform/resolvers/yup";
import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useBatchFileTemplateColumns } from "../../../../../../../../components/hooks/use-workflow-batch-template.context";
import {
  addColumnFormDefaultValues,
  addColumnFormValidationSchema,
} from "./add-column-modal-form.schemas";

export const ADD_COLUMN_MODAL_ID = "add-column-modal";

interface TemplateDetailsProps {
  getBatchFileTemplatesDetails: (id: string | number) => void;
}

export const AddColumnModal = ({
  getBatchFileTemplatesDetails,
}: TemplateDetailsProps) => {
  const { id, batchConfigurableColumns } = useBatchFileTemplateColumns();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(addColumnFormValidationSchema),
    defaultValues: addColumnFormDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const { hide } = modalManager;

  const addColumn = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);
          await service.hubCreditManager.addBatchFileTemplateColumns({
            batchFileTemplateId: Number(id),
            configurableBatchFileColumnId: Number(values.column),
          });
          reset();
          hide(ADD_COLUMN_MODAL_ID);

          getBatchFileTemplatesDetails(id);

          OToastManager.success("Coluna salva com sucesso.");
        } catch {
          OToastManager.danger(
            "Não foi possível salvar coluna. Por favor, tente novamente mais tarde.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, hide, reset, getBatchFileTemplatesDetails],
  );

  return (
    <OModal id={ADD_COLUMN_MODAL_ID} position="center" size="md">
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar coluna
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {loading && <OLoader size="xl" absolute backdrop />}
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex flex-column gap-2">
              <ORFieldSelect
                id="column"
                name="column"
                label="Coluna"
                labelSize="lg"
                dataAction="adicionar_coluna:select:coluna"
                dataLabel="coluna"
              >
                <div>
                  {batchConfigurableColumns?.map((value) => (
                    <OOption key={value.id} value={value.id}>
                      {value.name}
                    </OOption>
                  ))}
                </div>
              </ORFieldSelect>
            </div>
          </form>
        </FormProvider>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="adicionar_coluna:botao:fechar"
            dataLabel="fechar"
            outline
            onClick={() => {
              hide(ADD_COLUMN_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <OButton
            dataAction="adicionar_coluna:botao:adicionar"
            dataLabel="adicionar"
            onClick={() => {
              addColumn();
            }}
          >
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
