import { ONotification, OTypography } from "@maestro/react";
import { StandardLink } from "components/standard-link";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { useGetUser } from "../../../../../../../../hooks";

export const ExpiringPowersUserNotification = () => {
  const { userTaxId } = useGetUser();

  const { hasRole } = useRoles();

  const { callService, value } = useServiceCall(
    service.onboardingRelationships.getPowersStatus,
  );

  useEffect(() => {
    if (hasRole(roles.usuarios.product.detailsExpiringPowersUserPage.role))
      callService({
        userTaxId,
      });
  }, [callService, hasRole, userTaxId]);

  const hasExpiredPowers = value?.status && value.status !== "NOT_EXPIRE";

  return hasExpiredPowers ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">
          O usuário possui expiração de poderes
        </OTypography>
        <StandardLink
          href={corporateRouter.routes.usuarios.product.b2c.user.details.expiringPowersUser.path(
            { identification: userTaxId },
          )}
          role={roles.usuarios.product.detailsExpiringPowersUserPage.role}
        >
          Detalhes
        </StandardLink>
      </div>
    </ONotification>
  ) : null;
};
