import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useStepConfigSelector } from "./step-config-selector.hook";

interface StepConfigSelectorProps {
  id: string;
  field: string;
  label: string;
}

export const StepConfigSelector = ({
  id,
  field,
  label,
}: StepConfigSelectorProps) => {
  const { getStepConfigs, hasFetchError, loading, stepConfigs } =
    useStepConfigSelector(field);

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        options={stepConfigs}
        id={id}
        name={field}
        label={label}
        placeholder="Selecionar"
      />
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getStepConfigs} />
      )}
    </div>
  );
};
