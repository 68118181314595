import { ODataGridGeneratorConfig } from "components/data-grid";
import { PmeAccount } from "services/clerk/models/responses/get-pme-accounts.response";

export const grid: ODataGridGeneratorConfig<PmeAccount> = {
  datagrid: {
    noDataText: "Nenhuma conta encontrada",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} contas)",
    },
  },
  columns: [
    {
      caption: "Agência",
      dataField: "branch",
    },
    {
      caption: "Conta",
      dataField: "number",
    },
    {
      caption: "Status",
      dataField: "statusDescription",
    },
    {
      caption: "Criado em",
      dataField: "insertedAt",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      caption: "Atualizado em",
      dataField: "updatedAt",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
  ],
};
