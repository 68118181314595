import { client } from "../client";
import { limitControlEndpoints } from "./limit-control.endpoints";
import { IUpdateLimitBagParams } from "./models/request";
import { ICreateLimitBagParams } from "./models/request/create-dependent-limit.request";
import { IGetLimitBagsByProductParams } from "./models/request/get-limit-bags-by-product.request";
import {
  ILimitBagsWithDependents,
  ILimitBagsResponse,
  ICreateDependentLimitBagResponse,
} from "./models/responses";

export const limitControlService = {
  getLimitBagWithDependents(productName: string, productId: string) {
    return client.get<ILimitBagsWithDependents>(
      limitControlEndpoints.getLimitBagWithDependents(productName, productId),
    );
  },
  getLimitsBagsByProduct(
    params: IGetLimitBagsByProductParams,
  ) {
    return client.post<ILimitBagsResponse>(
      limitControlEndpoints.getLimits(),
      {
        params,
      },
    );
  },
  createDependentLimitBag(parentId: string, params: ICreateLimitBagParams) {
    return client.post<ICreateDependentLimitBagResponse>(
      limitControlEndpoints.createDependentLimitBag(parentId),
      params,
    );
  },
  deleteDependentLimitBag(id: string) {
    return client.delete(limitControlEndpoints.deleteLimitBag(id));
  },
  updateLimitBag(id: string, params: IUpdateLimitBagParams) {
    return client.patch(limitControlEndpoints.updateLimitBag(id), params);
  },
};
