import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotificacaoRecebiveisAntecipadosField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.notificacaoRecebiveisAntecipados}
      name="notificacaoRecebiveisAntecipados"
      description="E-mail de descritivo de antecipações desembolsadas enviados aos fornecedores"
    />
  );
};
