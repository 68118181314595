import { CardReceivableWithOverrideMaturityAmount } from "../../intubate-operations.types";
import { ReceivableSuggestion } from "./quote-suggest.types";

const remapSuggestion = ({
  bandeira,
  cedente,
  cedenteIdentification,
  id,
  maturityAmount,
  maturityDate,
  sacado,
  sacadoIdentification,
  suggestedDisbursementAmount,
  suggestedMaturityAmount,
  taxaTotal,
}: ReceivableSuggestion): CardReceivableWithOverrideMaturityAmount => ({
  Bandeira: bandeira,
  Cedente: cedente,
  CedenteIdentification: cedenteIdentification,
  Currency: "BRL",
  DataVencimento: maturityDate,
  ReceivableId: id,
  Sacado: sacado,
  SacadoIdentification: sacadoIdentification,
  TaxaTotal: taxaTotal,
  ValorDesembolso: suggestedDisbursementAmount,
  ValorSolicitado: suggestedMaturityAmount,
  ValorVencimento: maturityAmount,
});

export const remapSuggestions = (receivables: ReceivableSuggestion[]) =>
  receivables.map((receivable) => remapSuggestion(receivable));
