import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ConfirmationDeleteAdequatorModalProps } from "./confirmation-delete-adequator-modal.types";
import { diligenceDeleteAdequatorModalId } from "./confirmation-delete-adequator-modal.utils";

export const useConfirmationDeleteAdequatorModal = ({
  id,
  reload,
}: ConfirmationDeleteAdequatorModalProps) => {
  const modalId = diligenceDeleteAdequatorModalId(id);

  const { callService: inactiveAdequacyOptionsById, loading } = useServiceCall(
    service.hubEnergy.inactiveAdequacyOptionsById,
  );

  const handleConfirm = useCallback(async () => {
    const { success } = await inactiveAdequacyOptionsById(id);

    if (!success) OToastManager.danger("Erro para deletar a adequadora");

    OToastManager.success("Adequadora deletada com sucesso!");
    modalManager.hide(modalId);
    reload();
  }, [inactiveAdequacyOptionsById, id, modalId, reload]);

  return { loading, modalId, handleConfirm };
};
