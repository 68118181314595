import { OConfirmationModal, OTypography } from "@maestro/react";

interface ConfirmationModalProps {
  id: string;
  loading: boolean;
  onConfirm: () => Promise<void>;
  email: string;
}

export const ConfirmationModal = ({
  id,
  loading,
  onConfirm,
  email,
}: ConfirmationModalProps) => {
  return (
    <OConfirmationModal
      modalId={id}
      title="Reenviar boleto"
      confirmLabel="Enviar"
      closeLabel="Cancelar"
      loading={loading}
      onConfirm={onConfirm}
    >
      <div className="d-flex justify-content-start">
        <OTypography>Reenviar boleto para o email {email}?</OTypography>
      </div>
    </OConfirmationModal>
  );
};
