import { isAxiosError } from "axios";
import { TradeFinanceErrorResponse } from "services/acc/models/responses/error/acc-error.response";

export const getTradeFinanceErrorMessage = (error: any) => {
  const data = error?.response?.data;
  const failures = (data as TradeFinanceErrorResponse)?.failures
    ?.flatMap((failure) => {
      const message = failure.errorMessage;
      return message ? [message] : [];
    })
    .join(", ");

  if (isAxiosError(error) && failures) return failures;

  return "Houve um erro na solicitação.";
};
