import {
  OCard,
  OCardBody,
  OCardHeader,
  OStep,
  OStepHeader,
} from "@maestro/react";
import React from "react";
import { useStepsBuilder } from "./steps-builder.context";

interface StepsBuilderProps {
  children?: React.ReactNode;
  disableNavigation?: boolean;
  divider?: boolean;
  steps: string[];
}

export const StepsBuilder = ({
  children,
  steps,
  divider = true,
  disableNavigation = true,
}: StepsBuilderProps) => {
  const { stepNumber } = useStepsBuilder();

  return (
    <OCard>
      <OCardHeader divider={divider}>
        <OStepHeader
          activeStep={stepNumber}
          disableNavigation={disableNavigation}
        >
          {steps.map((step) => (
            <OStep
              key={step}
              dataAction={`entuba:step:${step}`}
              dataLabel={step}
            >
              {step}
            </OStep>
          ))}
        </OStepHeader>
      </OCardHeader>
      <OCardBody className="pt-5">{children}</OCardBody>
    </OCard>
  );
};
