import styled from "styled-components";

export const SelectionContainer = styled("div")`
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar:hover {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: #f4f5f7;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    width: 8px !important;
  }
`;

export const SelectionOptionContainer = styled("div")`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface SelectionOptionInfoContainerProps {
  hasImage?: boolean;
  hasSecondaryInfo?: boolean;
}

export const SelectionOptionInfoContainer = styled.div<SelectionOptionInfoContainerProps>`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 0.5rem;
  align-items: center;

  #info {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    justify-content: ${({
      hasSecondaryInfo,
    }: SelectionOptionInfoContainerProps) =>
      hasSecondaryInfo ? "space-between" : "center"};

    max-width: ${({ hasImage }: SelectionOptionInfoContainerProps) =>
      hasImage ? "300px" : "350px"};
  }
`;
