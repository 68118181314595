import { OButton, OIcon, OTooltip, OTypography } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import { modalManagerPayload } from "utils/modal-manager-payload";
import {
  MoveFileModal,
  moveFileModalId,
} from "../../../../../../../../../../components/move-file-modal";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";

interface MoveFilesButtonProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const MoveFilesButton = ({ property }: MoveFilesButtonProps) => {
  const { refresh, stepAnalysis } = useStepAnalysisContext();

  const hasNoFiles =
    !property.document?.files?.length &&
    !property.documentTemplate?.files?.length;

  return (
    <>
      <OTooltip floating position="top-left" maxWidth="220px">
        <TooltipContent
          style={{ textAlign: "left" }}
          className="d-flex flex-column align-items-center gap-1"
        >
          <OTypography>Mover arquivos</OTypography>
          {hasNoFiles && (
            <OTypography size="sm">
              É necessário fazer o upload dos arquivos antes de movê-los
            </OTypography>
          )}
        </TooltipContent>
        <OButton
          type="dark"
          outline
          disabled={hasNoFiles}
          onClick={() => modalManagerPayload.show(moveFileModalId, property)}
        >
          <OIcon category="fal" icon="fa-folder-tree" />
        </OButton>
      </OTooltip>
      <MoveFileModal
        consumerUnitId={stepAnalysis.consumerUnit.relatedEntityId}
        reload={refresh}
      />
    </>
  );
};
