import { OBadge, OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { currencySymbol } from "utils/currency";
import { WorkflowLead } from "./workflow-lead-details.types";
import { WorkflowLeadStatusHistory } from "./compose/workflow-lead-status-history/workflow-lead-status-history.component";

export const workflowLeadDetailsGenerator = (workflowLead: WorkflowLead) => {
  return {
    details: {
      title: "Informações do Lead",
      items: [
        {
          label: "Empresa",
          value: `${workflowLead?.customer.legalEntity.name} - ${masks.cpfCnpj(
            workflowLead?.customer.legalEntity.taxId,
          )}`,
          grid: { md: 4 },
        },
        {
          label: "Status atual",
          value: (
            <div className="d-flex flex-column gap-2">
              {workflowLead?.currentStatus && (
                <OBadge
                  key={workflowLead.currentStatus.name}
                  size="sm"
                  type={workflowLead.currentStatus.color}
                >
                  <OIcon
                    category="orq"
                    icon={workflowLead.currentStatus.iconName}
                  />
                  {workflowLead.currentStatus.name}
                </OBadge>
              )}
            </div>
          ),
          grid: { md: 4 },
        },
        {
          label: "Etapa",
          value: workflowLead?.currentBigNumber?.name || "-",
          grid: { md: 4 },
        },
        {
          label: "Data de criação",
          value: dayjs(workflowLead?.createdDate).format("DD/MM/YYYY HH:mm"),
          grid: { md: 4 },
        },
        {
          label: "Workflow",
          value: workflowLead?.workflowConfig?.name,
          grid: { md: 4 },
        },
        {
          label: "Id de correlação",
          value: workflowLead?.correlationId,
          grid: { md: 4 },
        },
        {
          label: "Ativo?",
          value: workflowLead?.deleted ? "Não" : "Sim",
          grid: { md: 4 },
        },
        {
          label: "E-mail",
          value: workflowLead?.email ?? "-",
          grid: { md: 4 },
        },
        {
          label: "Telefone",
          value: workflowLead?.phoneNumber
            ? masks.phone(workflowLead?.phoneNumber)
            : "-",
          grid: { md: 4 },
        },
        {
          label: "Receita mensal",
          value: workflowLead?.revenue
            ? masks.currency(workflowLead?.revenue, currencySymbol.BRL, ".")
            : "-",
          grid: { md: 4 },
        },
        {
          label: "Possui E-CNPJ?",
          value: workflowLead?.hasECnpj ? "Sim" : "Não",
          grid: { md: 4 },
        },
        {
          label: "Parceiro possui Opt-in?",
          value: workflowLead?.hasOptin ? "Sim" : "Não",
          grid: { md: 4 },
        },
        {
          label: "Foi solicitado Opt-in?",
          value: workflowLead?.shouldRequestOptin ? "Sim" : "Não",
          grid: { md: 4 },
        },
        {
          label: "Originador",
          value: `${workflowLead?.originator?.name ?? ""} - ${masks.cpfCnpj(
            workflowLead?.originator?.identification,
          )}`,
          grid: { md: 4 },
        },
        {
          label: "Operador",
          value: `${
            workflowLead?.operator?.legalEntity?.name ?? ""
          } - ${masks.cpfCnpj(workflowLead?.operator?.legalEntity?.taxId)}`,
          grid: { md: 4 },
        },
        {
          label: "Origem",
          value: workflowLead?.source ?? "-",
          grid: { md: 4 },
        },
        {
          label: "Comentários",
          value: workflowLead?.finalityComments,
          grid: { md: 12 },
        },
      ],
    },
    status: {
      title: "Histórico de Status",
      items: [
        {
          value: <WorkflowLeadStatusHistory workflowLead={workflowLead} />,
        },
      ],
    },
  } satisfies DetailsFields;
};
