export const transferStatusMap = (
  paymentStatus: string,
  accepted: boolean,
  movements: BankingHub.PaymentMovement[],
) => {
  if (paymentStatus === "SCHEDULED") return "Agendado";
  if (!accepted) return "Erro de processamento";
  if (movements !== undefined) {
    if (movements[0]?.state === "Confirmed") return "Realizado";
    if (movements[0]?.state === "Canceled") return "Estornado";
  }
  return "Em processamento";
};
