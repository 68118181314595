import { OCard, OCardBody, OCol, ORFieldInput, ORow } from "@maestro/react";

interface DeskFormProps {
  editOnly?: boolean;
}

export const DeskForm = ({ editOnly }: DeskFormProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                disabled={editOnly}
                label="Código"
                tag="text"
                id="deskCode"
                name="deskCode"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Nome"
                tag="text"
                id="deskName"
                name="deskName"
              />
            </OCol>
          </ORow>
          <ORow>
            <ORFieldInput
              label="Descrição"
              tag="text"
              id="deskDescription"
              name="deskDescription"
            />
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
