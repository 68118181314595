import {
  OCol,
  OField,
  OLoader,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import dayjs from "dayjs";
import { useEligibilityBlockListAdd } from "./add.hook";
import { BlockListTypesMap } from "../block.types";

export const EligibilityBlockListAdd = () => {
  const { form, loading, submit } = useEligibilityBlockListAdd();
  const { watch } = form;
  const startDateWatch = dayjs(watch("from"));

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Adicionar Bloqueio"
          description="Adicionar novo identificador com bloqueio durante a validação de elegibilidade."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_bloqueio:botao:criar_bloqueio"
          dataLabel="criar_bloqueio"
          onClick={submit}
        >
          Adicionar Bloqueio
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader size="sm" />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="identification"
                  name="identification"
                  dataAction="block:input:identification"
                  dataLabel="identification"
                  label="Identificação"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="De" htmlFor="from">
                  <ORFieldInputDate
                    id="from"
                    name="from"
                    className="mt-2"
                    disabledDate={(date) =>
                      dayjs(date.toDate()).isBefore(dayjs().startOf("day"))
                    }
                  />
                </OField>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="Até" htmlFor="until">
                  <ORFieldInputDate
                    id="until"
                    name="until"
                    className="mt-2"
                    disabledDate={(date) =>
                      startDateWatch
                        ? dayjs(date.toDate()).isBefore(
                            dayjs(startDateWatch.toDate()).startOf("day"),
                          )
                        : false
                    }
                  />
                </OField>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="type"
                  name="type"
                  dataAction="block:input:type"
                  dataLabel="type"
                  label="Tipos"
                  value={BlockListTypesMap.VIA_VAREJO_STORE}
                  key={BlockListTypesMap.VIA_VAREJO_STORE}
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
