import { DefaultErrorComponent } from "components/empty-state";
import { getValidationMessages } from "../../utils";

interface ValidationErrorComponentProps {
  error: unknown;
  callback: () => void;
  title: string;
}

export const ValidationErrorComponent = ({
  callback,
  error,
  title,
}: ValidationErrorComponentProps) => {
  return (
    <DefaultErrorComponent
      callback={callback}
      title={title}
      paragraph={getValidationMessages(error)?.[0].errorMessage}
    />
  );
};
