import {
  OCol,
  ORFieldInput,
  ORFieldCheckboxGroup,
  ORow,
  OCheckbox,
  OLabel,
} from "@maestro/react";

interface SignatureGroupFieldsProps {
  disabled: boolean;
  legalRepresentatives: {
    name: string;
    email: string;
  }[];
}

export const SignatureGroupFields = ({
  disabled,
  legalRepresentatives,
}: SignatureGroupFieldsProps) => {
  return (
    <>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInput
          dataAction="grupo_de_assinaturas:texto:nome_do_grupo"
          dataLabel="nome_do_grupo"
          id="name"
          name="name"
          tag="text"
          label="Nome do grupo"
          placeholder="Preencher"
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} sm={6} md={3}>
        <ORFieldInput
          dataAction="grupo_de_assinaturas:texto:quantidade_assinaturas"
          dataLabel="quantidade_assinaturas"
          id="minimumNumberOfSigners"
          name="minimumNumberOfSigners"
          tag="mask"
          maskOption={{ mask: Number, scale: 0 }}
          label="Quantidade de assinaturas"
          placeholder="Preencher"
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12}>
        <ORFieldCheckboxGroup
          dataAction="grupo_de_assinaturas:texto:emails"
          dataLabel="emails"
          id="emails"
          name="emails"
          label="Signatários"
        >
          <ORow>
            {legalRepresentatives.map((representative) => (
              <OCol
                xs={12}
                sm={6}
                className="d-flex align-items-center gap-2"
                key={representative.email}
              >
                <OCheckbox
                  id={representative.email}
                  value={representative.email}
                  size="sm"
                  disabled={disabled}
                />
                <OLabel htmlFor={representative.email}>
                  {representative.name}
                </OLabel>
              </OCol>
            ))}
          </ORow>
        </ORFieldCheckboxGroup>
      </OCol>
    </>
  );
};
