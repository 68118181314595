import * as yup from "yup";

export const componentFormSchema = yup.object().shape({
  name: yup.string().nullable().trim().required("Campo obrigatório"),
  type: yup
    .string()
    .nullable()
    .trim()
    .required("Campo obrigatório")
    .matches(
      /^[^`~!@#$%^&*()+-={}[\]|\\:;“’<,>.?๐฿ ç]*$/,
      "O campo não deve conter caracteres especiais ou espaços. Utilize _ como separador",
    )
    .uppercase("O tipo do componente deve ser em maiúsculo"),
  description: yup.string().nullable().trim().required("Campo obrigatório"),
});

export const componentFormDefaultValues = {
  name: "",
  type: "",
  description: "",
};
