import { ChangeInfo } from "./_compose";
import { useChangeLogger } from "./change-logger.hook";
import { LoggerContent, LoggerTitle } from "./change-logger.styles";
import { ChangeLoggerProps } from "./change-logger.types";

export const ChangeLogger = (props: ChangeLoggerProps) => {
  const { changes } = useChangeLogger(props);

  return (
    <LoggerContent>
      <LoggerTitle>Change Logger</LoggerTitle>
      {changes.length === 0 ? (
        <>no changes triggered</>
      ) : (
        changes.map((change) => {
          const id =
            "id" in change
              ? change.id
              : Math.random().toString(36).substring(2);

          return <ChangeInfo key={id} change={change} />;
        })
      )}
    </LoggerContent>
  );
};
