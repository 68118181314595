export enum ReceivableStatus {
  Aberto = "Aberto",
  EmAgendamento = "EmAgendamento",
  Agendado = "Agendado",
  EmAprovacao = "EmAprovacao",
  Aprovado = "Aprovado",
  EmAntecipacao = "EmAntecipacao",
  Antecipada = "Antecipada",
  Liquidada = "Liquidada",
  Cancelada = "Cancelada",
  Indisponivel = "Indisponivel",
  EmCancelamento = "EmCancelamento",
  EmAtraso = "EmAtraso",
  Removido = "Removido",
  Pago = "Pago",
  Disponivel = "Disponivel",
}

export const ReceivableStatusLabel: {
  [key in ReceivableStatus]: string;
} = {
  Aberto: "Aberto",
  EmAprovacao: "Em aprovação",
  Aprovado: "Disponível",
  EmAntecipacao: "Pendente de Pagamento",
  Antecipada: "Antecipada",
  Liquidada: "Paga",
  Cancelada: "Cancelada",
  EmAtraso: "Paga",
  EmAgendamento: "Em Agendamento",
  EmCancelamento: "Em cancelamento",
  Agendado: "Agendado",
  Removido: "Removido",
  Indisponivel: "Indisponível",
  Pago: "Pago",
  Disponivel: "Disponível",
};
