export const FileStatusMap = {
  ACTIVE: "Ativo",
  PENDING: "Pendente",
  INACTIVE: "Inativo",
  DENIED: "Pedido negado",
};

export const ProductsCNAB = {
  Cobrança: "eef71456-7000-46ba-aeb3-123b44d06c57",
  Pagamento: "018411bc-611e-4615-a02c-f1a68d2a2296",
  Extrato: "5f7b8c92-66db-4ee1-9f44-8df1a70526bf",
  Fopa: "ddedbe71-faba-45ba-a431-08ba6fdd8591",
};

export interface ProductNames {
  Cobranca: string;
  Pagamento: string;
  Extrato: string;
  Fopa: string;
}
