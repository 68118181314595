import { env } from "utils/environments";

export const hubPartnersEndpoints = {
  admin: {
    economicGroup: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/economic-groups`,
      originators: {
        byOriginatorIdentification: {
          endpoint: `${env.API_PARTNERS}/api/v1/admin/economic-groups/originators/by-originator-identification`,
        },
      },
    },
    partnershipType: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/partnership-type`,
    },
    originatorSubtype: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/originator-subtype`,
    },
    desk: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/desk`,
    },
    books: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/book`,
    },
    products: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/product`,
    },
    catalogs: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/catalog`,
    },
    productLink: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/product/link`,
    },
    originators: {
      details: {
        endpoint: `${env.API_PARTNERS}/api/v1/admin/originators/details`,
      },
      byIdentifier: {
        endpoint: (identifier: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${identifier}`,
        indicator: (identifier: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${identifier}/referrer`,
        categorization: (identifier: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${identifier}/categorization`,
      },
      endpoint: `${env.API_PARTNERS}/api/v1/admin/originators`,
      main: `${env.API_PARTNERS}/api/v1/admin/originators/main`,
      search: `${env.API_PARTNERS}/api/v1/admin/originators/search`,
    },
    referrers: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/operator-profiles/referrers/search`,
    },

    odata: {
      adminGetODataAccountRelationships: `${env.API_PARTNERS}/api/odata/AdminGetODataAccountRelationships`,
      adminGetODataReferralRelationships: `${env.API_PARTNERS}/api/odata/AdminGetODataReferralRelationships`,
      adminGetODataEconomicGroups: `${env.API_PARTNERS}/api/odata/AdminGetODataEconomicGroups`,
      adminGetODataEntityRelationships: `${env.API_PARTNERS}/api/odata/AdminGetODataEntityRelationships`,
      adminGetODataOperators: `${env.API_PARTNERS}/api/odata/AdminGetODataOperatorProfiles`,
      adminGetODataOriginators: `${env.API_PARTNERS}/api/odata/AdminGetODataOriginators`,
    },
  },
  operatorProfiles: {
    byIdentifier: {
      details: `${env.API_PARTNERS}/api/v1/admin/operator-profiles/details`,
    },
    byOperatorProfileUUID: {
      hierarchies: (operatorProfileUUID: string) =>
        `${env.API_PARTNERS}/api/v1/operators/${operatorProfileUUID}/hierarchies`,
      unlink: (operatorProfileUUID: string) =>
        `${env.API_PARTNERS}/api/v1/operators/${operatorProfileUUID}/unlink`,
    },
    endpoint: `${env.API_PARTNERS}/api/v1/operators`,
    search: `${env.API_PARTNERS}/api/v1/operators/search`,
  },
};
