export const onboardingTypeOptions = [
  { value: "CEDENTE_COMPLETO", label: "Cedente Completo" },
  { value: "CEDENTE_SIMPLES", label: "Cedente Simples" },
];

export interface DashboarOnboardingsFormValues {
  onboardingType: (typeof onboardingTypeOptions)[number]["value"];
  sacadoIds: number[];
}

export const dashboardOnboardingFormDefaulValues: DashboarOnboardingsFormValues =
  {
    onboardingType: "CEDENTE_COMPLETO",
    sacadoIds: [],
  };
