import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalHeader,
  OOption,
  ORFieldSelect,
  ORow,
  OTypography,
  modalManager,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import {
  DashboarOnboardingsFormValues,
  onboardingTypeOptions,
} from "../../dashboard-onboardings.form";
import { SelectSacados } from "../select-sacados";
import { DashboardSearchProps } from "./search.types";

const dashboardSearchModalId = "dashboard-search-modal";

export const DashboardSearch = ({ onSubmit }: DashboardSearchProps) => {
  const { reset } = useFormContext<DashboarOnboardingsFormValues>();

  return (
    <>
      <IconButton
        dataAction="dashboard_onboardings:botao:pesquisar"
        dataLabel="pesquisar"
        icon={{ category: "far", icon: "fa-search" }}
        onClick={() => modalManager.show(dashboardSearchModalId)}
      >
        Pesquisar
      </IconButton>
      <OModal id={dashboardSearchModalId}>
        <OModalHeader>
          <OTypography size="lg">Parâmetros de busca</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-4">
            <ORow>
              <OCol xs={12}>
                <ORFieldSelect
                  dataAction="dashboard_onboardings:select:tipo_onboarding"
                  dataLabel="tipo_onboarding"
                  id="onboardingType"
                  name="onboardingType"
                  label="Tipo de Onboarding"
                >
                  {onboardingTypeOptions.map((option) => (
                    <OOption key={option.value} value={option.value}>
                      {option.label}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>

              <OCol xs={12}>
                <SelectSacados />
              </OCol>
            </ORow>

            <div className="d-flex justify-content-end gap-2">
              <OButton
                dataAction="dashboard_onboardings:botao:pesquisar"
                dataLabel="pesquisar"
                onClick={() => {
                  onSubmit();
                  modalManager.hide(dashboardSearchModalId);
                }}
              >
                Pesquisar
              </OButton>
              <OButton
                dataAction="dashboard_onboardings:botao:limpar"
                dataLabel="limpar"
                type="dark"
                outline
                onClick={() => reset()}
              >
                Limpar
              </OButton>
            </div>
          </div>
        </OModalBody>
      </OModal>
    </>
  );
};
