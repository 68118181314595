import {
  OBanner,
  OBannerBody,
  ODivider,
  OSkeletonCard,
  OSkeletonLine,
} from "@maestro/react";

export const BannerSkeleton = () => {
  return (
    <OBanner orientation="horizontal">
      <OBannerBody>
        <div className="d-flex flex-column justify-content-between h-100">
          {/* content */}
          <div>
            <div className="w-15 rounded mb-2">
              <OSkeletonLine size="xs" />
            </div>

            <div className="w-10 rounded mb-2">
              <OSkeletonLine size="sm" />
            </div>

            <div className="w-20 rounded">
              <OSkeletonLine size="sm" />
            </div>
          </div>

          {/* footer */}
          <div>
            <ODivider position="horizontal" />
            <div className="w-10 rounded mb-2 mt-4">
              <OSkeletonLine size="sm" />
            </div>
          </div>
        </div>
      </OBannerBody>
      <OSkeletonCard height="260px" width="400px" />
    </OBanner>
  );
};
