import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OConfirmationModal } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useState } from "react";
import { service } from "services";

export const UpdateCompanyRegistersFromLess = () => {
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);

      await service.onboarding.updateDataLess({
        identification: customer.identification,
      });

      OToastManager.info(
        "Os dados da empresa serão sincronizados em até cinco minutos!",
      );

      modalManager.hide("update-registeres-portal-from-less-modal");
    } catch (err) {
      OToastManager.danger("Ocorreu erro na sincronização");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <OButton
        dataAction="ferramentas:botao:atualizar"
        dataLabel="atualizar"
        onClick={() =>
          modalManager.show("update-registeres-portal-from-less-modal")
        }
      >
        Atualizar
      </OButton>

      <OConfirmationModal
        modalId="update-registeres-portal-from-less-modal"
        size="sm"
        title="Atualizar dados do portal empresas com o LESS ?"
        closeLabel="Cancelar"
        confirmLabel="Confirmar"
        onConfirm={submit}
        loading={loading}
      />
    </>
  );
};
