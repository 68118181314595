import { PatchByIdAdminPreticketBody } from "services/hubfx/models/requests/patch-by-id-admin-pretickets.request";
import * as yup from "yup";

export const preticketsFormValidationSchema: yup.ObjectSchema<
  Omit<
    PatchByIdAdminPreticketBody,
    "preTicketId" | "counterpartyIdentification"
  >
> = yup.object({
  fxAvailableAmount: yup.number().required("Este campo é obrigatório"),
  status: yup.string().required("Este campo é obrigatório"),
  country: yup.string().required("Este campo é obrigatório"),
  payerType: yup.string().required("Este campo é obrigatório"),
  clientLink: yup.string().required("Este campo é obrigatório"),
});
