import { DataGrid } from "devextreme-react/data-grid";
import styled from "styled-components";

export const ODataGridStyled = styled(DataGrid)`
  > * {
    font-family: Moderat;
  }

  /* Enable overflow ellipsis by default */
  td:not(.dx-disable-ellipsis) {
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
  }

  td.dx-word-wrap {
    white-space: normal;
    word-wrap: break-word;
  }

  /* Grid border */
  .dx-datagrid-headers {
    border-left: 1px solid var(--theme-dark-20);
    border-top: 1px solid var(--theme-dark-20);
    border-right: 1px solid var(--theme-dark-20);
  }

  // without header
  .dx-hidden ~ .dx-datagrid-rowsview {
    border-top: 1px solid var(--theme-dark-10);
  }

  .dx-datagrid-rowsview {
    border-left: 1px solid var(--theme-dark-20);
    border-right: 1px solid var(--theme-dark-20);
    border-bottom: 1px solid var(--theme-dark-20);
  }

  /* Unset background */
  .dx-datagrid.dx-gridbase-container {
    background-color: unset;
  }

  /* Inner background white */
  .dx-datagrid-headers,
  .dx-datagrid-rowsview {
    background: var(--theme-light);
  }

  /* Outer border radius */
  .dx-datagrid-headers {
    border-radius: 4px 4px 0 0;
  }
  .dx-datagrid-rowsview {
    border-radius: 0 0 4px 4px;
  }

  /* Row height */
  .dx-row.dx-header-row,
  .dx-row.dx-data-row {
    height: 4rem;
  }

  /* Text size and color */
  .dx-datagrid-text-content,
  .dx-command-edit,
  tr.dx-row.dx-data-row > td {
    font-size: 0.875rem !important;
    color: var(--theme-dark);
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row {
    /* Hover effect on table header */
    & td:hover .dx-datagrid-text-content {
      color: var(--theme-primary);
    }

    /* Text color for sort header */
    & > td .dx-sort,
    & > td .dx-sort-indicator {
      color: var(--theme-dark);
    }
  }

  .dx-datagrid .dx-header-filter:hover {
    color: var(--theme-info);
  }

  .dx-datagrid .dx-header-filter-empty {
    color: var(--theme-dark);
  }

  /* Row height and overflow */
  tr.dx-row.dx-data-row {
    min-height: 5.5rem;

    > td {
      overflow: visible;
    }
  }

  tr.dx-row-alt > td {
    background: var(--grid-row-alt);
  }

  /* Lines between rows */
  .dx-datagrid .dx-row-lines {
    > td {
      border-bottom: 1px solid var(--theme-dark-10);
    }

    /* No line on last row */
    &:nth-last-child(2) > td {
      border-bottom: 0;
    }
  }

  /* Lines between columns */
  .dx-datagrid .dx-column-lines > td {
    border-left: 1px solid var(--theme-dark-10);
    border-right: 1px solid var(--theme-dark-10);
  }

  .dx-datagrid-group-closed,
  .dx-datagrid-group-opened {
    color: var(--theme-dark);
  }

  /* Pager style */
  .dx-pager {
    /* Info text (Page x of y) */
    .dx-info {
      opacity: 0.7;
    }

    /* Page buttons */
    .dx-pages {
      color: var(--theme-dark);

      /* Page number buttons */
      .dx-page {
        border-radius: 4px;
        margin-left: 2px;
        margin-right: 2px;

        background: var(--theme-light);
        color: var(--theme-dark);

        &:hover {
          background: var(--theme-light-hover);
        }

        &.dx-selection {
          background: var(--theme-tertiary);
          color: var(--theme-light);
        }
      }

      /* Arrow buttons */
      .dx-navigate-button {
        border-radius: 4px;
        margin-left: 2px;
        margin-right: 2px;

        background: var(--theme-light);
        color: var(--theme-dark);

        &:hover {
          background: var(--theme-light-hover);
        }

        &.dx-button-disable {
          background: var(--theme-light-active);
        }
      }
    }

    /* Page size buttons */
    .dx-page-sizes {
      .dx-page-size {
        border-radius: 4px;
        background: var(--theme-light);
        color: var(--theme-dark);

        &:hover {
          background: var(--theme-light-hover);
        }

        &.dx-selection {
          background: var(--theme-tertiary);
          color: var(--theme-light);
        }
      }
    }

    /* Page picker */
    .dx-light-pages {
      margin-left: 2px;
      margin-right: 2px;
    }

    /* Page picker input */
    .dx-texteditor {
      background: unset;

      &.dx-state-active::before,
      &.dx-state-focused::before {
        height: 1px;
        border-bottom-color: var(--theme-focus);
      }
    }
  }

  .dx-master-detail-row {
    tr {
      font-family: Moderat;
    }

    .dx-datagrid-text-content,
    .dx-command-edit {
      font-family: Moderat;

      color: var(--theme-light) !important;
    }

    .dx-column-indicators > span::before {
      color: var(--theme-light);
    }

    .dx-row.dx-header-row {
      background: var(--theme-dark-60);
    }

    .dx-header-row .dx-datagrid-action:hover {
      .dx-datagrid-text-content,
      .dx-command-edit {
        color: var(--theme-dark-60) !important;
      }

      .dx-column-indicators > span::before {
        color: var(--theme-dark-60);
      }
    }
  }

  .dx-scrollable-scroll-content {
    background: var(--theme-default);
  }

  /* Overflow actions dropdown */
  .dx-datagrid-rowsview {
    overflow: visible;
  }

  /** LoadPanel **/
  .dx-overlay-content.dx-loadpanel-content {
    box-shadow: none;
    border: 0;
    background: transparent;
  }

  .dx-loadindicator-content {
    animation: none;

    .dx-loadindicator-icon {
      border-radius: 100%;
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
      animation: 0.8s linear infinite loader;
      color: var(--theme-dark-80);

      > * {
        display: none;
      }
    }
  }

  /* Hide content when loading */
  &.custom-loading .dx-datagrid-rowsview {
    opacity: 0.2;
    pointer-events: none;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Filter row */
  .dx-datagrid-filter-row {
    background-color: unset;

    &.dx-row > td {
      border-bottom: unset;
    }
  }

  /* Search bar icon */
  .dx-datagrid.dx-filter-menu .dx-menu-item-content .dx-icon {
    color: var(--theme-dark-80);
  }

  /* Filter-row focused */
  .dx-datagrid-focus-overlay {
    box-sizing: border-box;
    border: 2px solid var(--theme-focus);

    &:after {
      display: none;
    }
  }

  /* Column resizing indicator */
  .dx-datagrid-columns-separator {
    background-color: var(--theme-default);
  }
  /* Bug fix with column resize ui */
  .dx-datagrid-columns-separator-transparent {
    background-color: transparent;
  }

  /* Increase scrollbar height */
  .dx-scrollbar-horizontal.dx-scrollbar-hoverable,
  .dx-scrollable-scroll {
    height: 13px;
  }

  /* Checkbox icon */
  // checked
  .dx-checkbox[aria-checked="true"]
    .dx-checkbox-container
    span.dx-checkbox-icon {
    color: var(--theme-light);
    background-color: var(--theme-tertiary) !important;
  }
  // mixed (also null)
  .dx-checkbox[aria-checked="mixed"]
    .dx-checkbox-container
    span.dx-checkbox-icon {
    color: var(--theme-light);
    background-color: var(--theme-tertiary) !important;
  }
  // unchecked
  span.dx-checkbox-icon {
    border: 1px solid var(--theme-tertiary);
  }
  // disabled
  .dx-checkbox.dx-state-disabled {
    cursor: not-allowed;
    pointer-events: auto;

    background-color: var(--theme-neutral-normal);
    opacity: var(--disabled-opacity);

    span.dx-checkbox-icon {
      border-color: var(--theme-neutral-medium);
    }
  }

  .dx-datagrid-rowsview
    .dx-master-detail-row:not(.dx-datagrid-edit-form)
    .dx-master-detail-cell,
  .dx-datagrid-rowsview
    .dx-master-detail-row:not(.dx-datagrid-edit-form)
    > .dx-datagrid-group-space {
    background-color: var(--theme-neutral-normal);
  }

  /* Icon buttons */
  .dx-link {
    color: var(--theme-dark);
  }

  /* Selected rows */
  .dx-datagrid-rowsview .dx-selection.dx-row {
    td {
      background-color: var(--theme-info-light);
      color: var(--theme-info-light-inverse);
    }

    &:hover td {
      background-color: var(--theme-info-light-hover);
      color: var(--theme-info-light-hover-inverse);
    }
  }

  /* GroupPanel */
  .dx-datagrid-header-panel {
    background-color: unset;
    border: none;

    .dx-toolbar {
      background-color: unset;

      .dx-group-panel-item {
        background-color: var(--theme-light);
        color: var(--theme-dark);
        border: 1px solid var(--theme-dark-10);

        .dx-sort::before {
          color: var(--theme-dark);
        }
      }
    }
  }

  /* Grouping */
  .dx-group-row {
    background-color: var(--theme-light-20) !important;
    color: var(--theme-light-inverse) !important;
  }

  /* Header panel */
  .dx-datagrid-header-panel .dx-group-panel-message {
    color: var(--theme-dark-60);
  }

  /* Header button */
  .dx-datagrid-header-panel .dx-datagrid-toolbar-button .dx-icon {
    color: var(--theme-dark);
  }

  /* Edit form */
  .dx-datagrid-edit-form {
    background-color: var(--theme-light) !important;
    color: var(--theme-light-inverse) !important;

    .dx-field-item-label-text {
      color: var(--theme-light-inverse) !important;
    }

    .dx-texteditor.dx-editor-filled::after {
      border-bottom: 1px solid var(--theme-light-inverse);
    }

    .dx-texteditor.dx-state-readonly input {
      color: var(--theme-dark-40) !important;
    }
  }

  /* Fixed column */
  .dx-datagrid-content-fixed {
    col {
      background-color: var(--theme-light) !important;
      color: var(--theme-light-inverse) !important;
    }

    .dx-datagrid-filter-row input {
      border-bottom: 1px solid var(--theme-dark-80);
    }
  }
`;
