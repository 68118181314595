import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { getValueFromMap } from "utils/get-value-from-map";
import { productTypeMap, statesOptions } from "../../note-filter.utils";

export const MultiSelects = () => {
  return (
    <>
      <OCol xs={12} md={6}>
        <ORFieldSelectMultiple
          id="tipoProdutoList"
          name="tipoProdutoList"
          dataAction="titulos_nota_nota_novo:select:tipos_produto"
          dataLabel="tipos_produto"
          label="Tipos de produto"
        >
          {Object.keys(productTypeMap).map((product) => (
            <OOptionMultiple key={product} value={product}>
              {getValueFromMap(productTypeMap, product)}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldSelectMultiple
          id="currentStateList"
          name="currentStateList"
          dataAction="titulos_nota_nota_novo:select:status"
          dataLabel="status"
          label="Status"
        >
          {statesOptions.map((state) => (
            <OOptionMultiple key={state} value={state}>
              {state}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
    </>
  );
};
