import { safeSessionStorage } from "utils/storage";

const mockRolesStorage = safeSessionStorage;

const mockRolesStorageKey = "corporate-portal:mock-roles";

export const isFullMock = () =>
  mockRolesStorage.getItem(mockRolesStorageKey) === "FULL";

export const isCustomerServiceMock = () =>
  mockRolesStorage.getItem(mockRolesStorageKey) === "CUSTOMER_SERVICE";

export const isNormal = () =>
  mockRolesStorage.getItem(mockRolesStorageKey) === null;

export const setFullMock = () =>
  mockRolesStorage.setItem(mockRolesStorageKey, "FULL");

export const setCustomerServiceMock = () =>
  mockRolesStorage.setItem(mockRolesStorageKey, "CUSTOMER_SERVICE");

export const setNormal = () => mockRolesStorage.removeItem(mockRolesStorageKey);
