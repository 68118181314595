import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { StyledValue } from "../roadwalker-master-detail.style";
import { BalanceMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerBalanceDetails = (data: BalanceMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    balance: {
      items: [
        {
          label: "Identification",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.Identification}</span>
                {data.Identification}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradeId",
          value: <StyledValue>{data.TradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.ExternalId}</span>
                {data.ExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CalculationDate",
          value: (
            <StyledValue>
              {dayjs(data.CalculationDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProductCode",
          value: <StyledValue>{data.ProductCode}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ValueDate",
          value: (
            <StyledValue>
              {dayjs(data.ValueDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Amount",
          value: (
            <StyledValue>{masks.currency(data.Amount, "", ".")}</StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InterestArrears",
          value: (
            <StyledValue>
              {masks.currency(data.InterestArrears, "", ".")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PenaltyFee",
          value: (
            <StyledValue>
              {masks.currency(data.PenaltyFee, "", ".")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "LatePaymentFee",
          value: (
            <StyledValue>
              {masks.currency(data.LatePaymentFee, "", ".")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MaturityValue",
          value: (
            <StyledValue>
              {masks.currency(data.MaturityValue, "", ".")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PayerCodCGE",
          value: <StyledValue>{data.PayerCodCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PayeeCodCGE",
          value: <StyledValue>{data.PayeeCodCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Book",
          value: <StyledValue>{data.Book}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Strategy",
          value: <StyledValue>{data.Strategy}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
