import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  {
    value: "planilha",
    label: "Arquivo",
  },
  {
    value: "configuracao",
    label: "Automático",
  },
  {
    value: "semTaxa",
    label: "Sem taxa",
  },
];

export const ModeloDeTaxaField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:modelo_de_taxa"
        dataLabel="modelo_de_taxa"
        id="modeloDeTaxa"
        name="modeloDeTaxa"
        label={EstrategiaFormLabels.modeloDeTaxa}
        placeholder="Selecionar"
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
