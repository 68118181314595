import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { InputDdiPhone } from "../input-ddi-phone";

type ORFieldInputDdiPhoneProps = Omit<
  React.ComponentProps<typeof InputDdiPhone>,
  "onChange"
>;

export const ORFieldInputDdiPhone = ({
  disabled,
  defaultValue,
  ...props
}: ORFieldInputDdiPhoneProps) => {
  const { name } = props;
  const {
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();

  const onChangePhone = useCallback(
    (value: string) => {
      setValue(name, value);
    },
    [name, setValue],
  );

  return (
    <InputDdiPhone
      {...props}
      disabled={disabled ?? false}
      defaultValue={defaultValue ?? getValues(name)}
      onChange={onChangePhone}
      error={!!errors[name]}
      message={errors?.[name]?.message as string}
    />
  );
};
