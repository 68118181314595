import { modalManager } from "@maestro/core";
import dxDataGrid from "devextreme/ui/data_grid";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { filterFields, FormValuesType } from "./filter-modal.types";
import { filterData } from "./filter-modal.utils";

export const useFilter = (gridRefInstance?: dxDataGrid<any, any>) => {
  const filterForm = useForm<FormValuesType>();

  const [filtersCount, setFiltersCount] = useState(0);
  const [clearSearchs, setClearSearchs] = useState(true);

  const { getValues, reset, watch } = filterForm;
  const filterFormValues = getValues();

  const prepareFilterValues = useCallback(() => {
    const filterValuesAux: string[] = [];

    filterFields.forEach((field) => {
      const value = filterFormValues[field as keyof typeof filterFormValues];

      if (value?.length) {
        filterValuesAux.push(field);
      }
    });

    const dataSource = gridRefInstance?.getDataSource();

    if (dataSource) {
      const filteredData = filterData(dataSource, watch);

      gridRefInstance?.beginUpdate();
      gridRefInstance?.option("dataSource", filteredData as any);
      gridRefInstance?.endUpdate();
    }

    setFiltersCount(filterValuesAux.length);

    modalManager.hide("filter-modal");
  }, [filterFormValues, gridRefInstance, watch]);

  const resetFilterValues = useCallback(() => {
    reset();
    gridRefInstance?.clearFilter();

    setClearSearchs((value) => !value);
    setFiltersCount(0);

    modalManager.hide("filter-modal");
  }, [reset, gridRefInstance]);

  return {
    prepareFilterValues,
    resetFilterValues,
    filterForm,
    filtersCount,
    clearSearchs,
  };
};
