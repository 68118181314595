import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const DeskDocumentConfigForm = () => {
  const {
    callService: getDeskOnboardingDocumentConfigEnums,
    loading,
    value: enums,
    hasError,
  } = useServiceCall(service.onboarding.getDeskOnboardingDocumentConfigEnums);

  useEffect(() => {
    getDeskOnboardingDocumentConfigEnums();
  }, [getDeskOnboardingDocumentConfigEnums]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="type"
                name="type"
                label="Type"
                placeholder="Selecione"
                disabled={loading}
                key={enums?.documentTypeLevels?.length}
              >
                {enums?.documentTypeLevels?.map((documentType) => (
                  <OOption key={documentType} value={documentType}>
                    {documentType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loading && <OLoader className="d-block" size="sm" />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDeskOnboardingDocumentConfigEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="subType"
                name="subType"
                label="SubType"
                placeholder="Selecione"
                disabled={loading}
                key={enums?.documentSubTypeLevels?.length}
              >
                {enums?.documentSubTypeLevels?.map((documentSubType) => (
                  <OOption key={documentSubType} value={documentSubType}>
                    {documentSubType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loading && <OLoader className="d-block" size="sm" />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDeskOnboardingDocumentConfigEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Código do Less"
                tag="number"
                id="lessCode"
                name="lessCode"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput label="Label" tag="text" id="label" name="label" />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="isBiometry"
                name="isBiometry"
                label="Tem biometria?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="isRequired"
                name="isRequired"
                label="É obrigatorio?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
