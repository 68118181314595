import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { useWorkflowFormTemplate } from "../../../../../../../components/hooks/use-workflow-form-template.context";
import { formDetailsValidationSchema } from "../../form-details.schemas";
import { submitModalTypes, toastTypeOptions } from "./form-general-info.utils";

export const FormGeneralInfoComponent = () => {
  const {
    form: { loading, value: workflowFormTemplate },
  } = useWorkflowFormTemplate();
  const form = useForm({
    resolver: yupResolver(formDetailsValidationSchema),
    defaultValues: {
      name: "",
      submitToastType: "",
      submitToastMessage: "",
      submitModalType: "",
      readonly: [] as string[],
      hideSummary: [] as string[],
      singleStepSubmit: [] as string[],
    },
  });

  const { reset, handleSubmit } = form;

  const updateForm = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!workflowFormTemplate) return;
        try {
          await service.hubCreditManager.updateForm({
            formId: workflowFormTemplate.id,
            name: values.name,
            submitToastType: values.submitToastType,
            submitToastMessage: values.submitToastMessage,
            submitModalType: values.submitModalType,
            readonly: !!values.readonly.length,
            hideSummary: !!values.hideSummary.length,
            singleStepSubmit: !!values.singleStepSubmit.length,
          });
          OToastManager.success("Informações gerais atualizadas com sucesso.");
        } catch {
          OToastManager.danger("Não foi possível atualizar o formulário.");
        }
      }),
    [workflowFormTemplate, handleSubmit],
  );

  useEffect(() => {
    if (!workflowFormTemplate) return;
    reset({
      name: workflowFormTemplate.name,
      submitToastType: workflowFormTemplate.submitToastType,
      submitToastMessage: workflowFormTemplate.submitToastMessage,
      submitModalType: workflowFormTemplate.submitModalType,
      readonly: workflowFormTemplate.readonly ? ["true"] : [],
      hideSummary: workflowFormTemplate.hideSummary ? ["true"] : [],
      singleStepSubmit: workflowFormTemplate.singleStepSubmit ? ["true"] : [],
    });
  }, [workflowFormTemplate, reset]);

  return (
    <FormTemplate
      actions={
        <LoadingButton loading={loading} onClick={updateForm}>
          Salvar alterações
        </LoadingButton>
      }
    >
      <OCard>
        <OCardHeader>
          <OTypography type="dark" tag="h3" size="lg">
            Informações gerais
          </OTypography>
        </OCardHeader>
        <OCardBody>
          <FormProvider {...form}>
            <form spellCheck="false">
              <ORow align="end">
                <OCol sm={6}>
                  <ORFieldInput
                    id="name"
                    name="name"
                    tag="text"
                    label="Nome"
                    disabled={!workflowFormTemplate}
                  />
                </OCol>
                <OCol sm={2}>
                  <ORFieldCheckboxGroup id="readonly" name="readonly">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox
                        size="xs"
                        id="readonly-checkbox"
                        value="true"
                      />
                      <OLabel htmlFor="readonly-checkbox">
                        Somente leitura ?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
                <OCol sm={2}>
                  <ORFieldCheckboxGroup id="hideSummary" name="hideSummary">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox
                        size="xs"
                        id="hideSummary-checkbox"
                        value="true"
                      />
                      <OLabel htmlFor="hideSummary-checkbox">
                        Esconde o Resumo ?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
                <OCol sm={2}>
                  <ORFieldCheckboxGroup
                    id="singleStepSubmit"
                    name="singleStepSubmit"
                  >
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox
                        size="xs"
                        id="singleStepSubmit-checkbox"
                        value="true"
                      />
                      <OLabel htmlFor="singleStepSubmit-checkbox">
                        Step único com submit ?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
              </ORow>
              <ORow className="my-3">
                <OTypography size="lg">Mensagem de sucesso</OTypography>
              </ORow>
              <ORow>
                <OCol>
                  <ORFieldSelect
                    id="submitToastType"
                    name="submitToastType"
                    label="Tipo"
                  >
                    {toastTypeOptions.map((option) => (
                      <OOption key={option.value} value={option.value}>
                        {option.text}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
                <OCol>
                  <ORFieldInput
                    id="submitToastMessage"
                    name="submitToastMessage"
                    tag="text"
                    label="Mensagem"
                    disabled={!workflowFormTemplate}
                  />
                </OCol>
              </ORow>
              <ORow className="my-3">
                <OTypography size="lg">Tipo de modal ao enviar</OTypography>
              </ORow>
              <ORow>
                <OCol>
                  <ORFieldSelect
                    id="submitModalType"
                    name="submitModalType"
                    label="Tipo modal"
                  >
                    {submitModalTypes.map((option) => (
                      <OOption key={option.value} value={option.value}>
                        {option.text}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
              </ORow>
            </form>
          </FormProvider>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
