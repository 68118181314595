import { MaestroFields } from "utils/types";
import { InternacionalUserForm } from "../add-internacional-user.type";

export const basicFields = {
  identification: {
    tag: "text",
    id: "user.identification",
    name: "user.identification",
    label: "Identificação/Login",
    placeholder: "Preencher",
    required: true,
  },
  name: {
    tag: "text",
    id: "user.name",
    name: "user.name",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  email: {
    tag: "text",
    id: "user.email",
    name: "user.email",
    label: "E-mail",
    placeholder: "Preencher",
    required: true,
  },
  phoneNumber: {
    id: "user.phoneNumber",
    name: "user.phoneNumber",
    label: "Celular",
    placeholder: "Preencher",
    required: true,
  },
} as const satisfies MaestroFields<InternacionalUserForm>;
