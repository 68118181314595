import {
  OFilterModal,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OTypography,
  OFilter,
  OButton,
  OFilterItem,
  OToastManager,
  OInputDateRange,
} from "@maestro/react";
import { useRef, useState } from "react";
import { FilterFields } from "./filters-modal.type";
import { usePayments } from "../../supplier-payments.context";
import {
  getEndOfMonth,
  getStartOfMonth,
  parseDateToFilter,
  validateDates,
} from "./filters-modal.utils";

export const paymentsFilterModalId = "payments-filter-modal-id";

export const FiltersModal = () => {
  const filterModalRef = useRef<HTMLOFilterModalElement>(null);
  const { setPaymentsFilters } = usePayments();

  const startOfMonth = getStartOfMonth();
  const endOfMonth = getEndOfMonth();

  const [dateRangeError, setDateRangeError] = useState(false);

  const handleFilterItems = async () => {
    setDateRangeError(false);
    const params = (await filterModalRef.current?.filter()) as FilterFields;

    const { dateRange: initialDate, dateRange2: finalDate } = params;

    const dateErrorMessage = validateDates(initialDate, finalDate);
    if (dateErrorMessage) {
      OToastManager.danger(dateErrorMessage);
      setDateRangeError(true);
      return;
    }

    setPaymentsFilters((oldState) => ({
      ...oldState,
      initialDate: parseDateToFilter(initialDate || startOfMonth),
      finalDate: parseDateToFilter(finalDate || endOfMonth),
    }));

    filterModalRef.current?.closeFilter();
  };

  const handleClearFilterItems = async () => {
    setDateRangeError(false);
    setPaymentsFilters({
      initialDate: parseDateToFilter(startOfMonth),
      finalDate: parseDateToFilter(endOfMonth),
    });
    await filterModalRef.current?.clearFilter();
    filterModalRef.current?.closeFilter();
  };

  return (
    <OFilterModal ref={filterModalRef} id={paymentsFilterModalId}>
      <OFilterHeader className="pt-1">
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>

      <OFilterBody>
        <OFilter>
          <OFilterItem>
            <OTypography slot="title">Data</OTypography>
            <div className="py-2 px-3">
              <OInputDateRange
                size="md"
                value={[startOfMonth, endOfMonth]}
                onChange={() => {}}
                data-filterparam="dateRange"
                error={dateRangeError}
              />
            </div>
          </OFilterItem>
        </OFilter>
      </OFilterBody>

      <OFilterFooter>
        <div className="d-flex justify-content-end">
          <OButton
            dataAction="contas_a_pagar_pagamentos_filtros_sidebar:botao:limpar_filtro"
            dataLabel="limpar_filtro"
            outline
            type="primary"
            bordered={false}
            onClick={handleClearFilterItems}
          >
            Limpar
          </OButton>
          <OButton
            dataAction="contas_a_receber_inicio_filtros_do_sidebar:select:filtrar_itens"
            dataLabel="filtrar_itens"
            onClick={handleFilterItems}
          >
            Filtrar
          </OButton>
        </div>
      </OFilterFooter>
    </OFilterModal>
  );
};
