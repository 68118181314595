export const assembleDescription = (
  disabled: boolean,
  daysToStart?: number,
) => {
  if (disabled) return;
  const daysLabel = `${
    daysToStart
      ? daysToStart === 1
        ? "1 dia"
        : `${daysToStart} dias`
      : "N dias"
  } após a data de vencimento`;
  return `A multa será aplicada ${daysLabel}`;
};
