import { OTypography } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { ContentTemplate } from "templates/content-template";
import { buildContingenciesLink, buildEditLink } from "../guarantors.utils";
import { DocumentsCard, OccurrencesCard, OccurrencesGrid } from "./_compose";
import { useGuarantorDetails } from "./guarantor-details.hook";
import { buildGuarantorDetailsFields } from "./guarantor-detais.details";

export const GuarantorDetails = () => {
  const { guarantor, guarantorUuid, getGuarantor, hasError, loading } =
    useGuarantorDetails();

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      value={guarantor}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível encontrar o avalista"
          messageParagraph="Clique no botão para tentar novamente."
        >
          <TryAgainButton onClick={() => getGuarantor()} />
        </ErrorComponent>
      }
      emptyStateComponent={
        <EmptyState messageTitle="Sem dados para o avalista" />
      }
      render={(_guarantor) => (
        <div className="d-flex flex-column gap-4">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <OTypography size="lg" type="default">
                Dados do Avalista
              </OTypography>

              <div className="d-flex flex-row gap-2">
                <RouterButton
                  href={buildContingenciesLink(guarantorUuid)}
                  role={
                    roles.cadastro.customer.guarantorsContingenciesPage.role
                  }
                >
                  Contingências
                </RouterButton>
                {guarantor?.canEditGuarantor && (
                  <RouterButton
                    href={buildEditLink(guarantorUuid)}
                    role={
                      roles.cadastro.customer.guarantorsGuarantorsEmbeddedPage
                        .role
                    }
                  >
                    Editar
                  </RouterButton>
                )}
              </div>
            </div>
            <DetailsCard fields={buildGuarantorDetailsFields(_guarantor)} />
          </div>
          <div>
            <OTypography size="lg" type="default" className="mb-3">
              Ocorrências
            </OTypography>
            {_guarantor.portalStatus === "PENDENTE_AJUSTE" ? (
              <OccurrencesGrid workflowUuid={guarantorUuid} type="GUARANTOR" />
            ) : (
              <OccurrencesCard occurrences={_guarantor.errors ?? []} />
            )}
          </div>
          <div>
            <OTypography size="lg" type="default" className="mb-3">
              Documentos
            </OTypography>
            <DocumentsCard documents={_guarantor.documents ?? []} />
          </div>
        </div>
      )}
    />
  );
};
