import { PortfolioManagamentResponse } from "services/moana/models/responses/portfolio-management.response";
import { DiscountedWalletManagement } from "./discounted-wallet-management.component";
import { OwnWallet } from "./own-wallet.component";

interface BankslipPortfolioProps {
  portfolioManagement?: PortfolioManagamentResponse;
  documentNumber: string;
}

export const BankslipPortfolio = ({
  portfolioManagement,
  documentNumber,
}: BankslipPortfolioProps) => {
  return (
    <>
      <OwnWallet
        portfolioManagement={portfolioManagement}
        documentNumber={documentNumber}
      />
      <DiscountedWalletManagement
        portfolioManagement={portfolioManagement}
        documentNumber={documentNumber}
      />
    </>
  );
};
