import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";
import {
  stepConfigFormDefaultValues,
  stepConfigFormValidationSchema,
} from "./step-config-form.form";
import { StepConfigForm } from "./step-config-form.types";

export const useStepConfigForm = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.upsertDiligenceStepConfigBase,
  );

  const navigate = useNavigate();

  const form = useForm<StepConfigForm>({
    defaultValues: stepConfigFormDefaultValues,
    resolver: yupResolver(stepConfigFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useCallback(
    (diligenceStepConfigId: number | null = null) =>
      handleSubmit(async (values) => {
        const { success, error, response } = await callService({
          diligenceStepConfigId,
          isPublic: values.isPublic,
          maximumDuration: values.maximumDuration,
          maximumDurationType: values.maximumDurationType,
          name: values.name,
          label: values.label,
          needsPreApproval: values.needsPreApproval,
          dependencyTypeIds: values.dependencyTypeIds,
          typeId: values.typeId,
        });
        if (success) {
          const message =
            diligenceStepConfigId == null
              ? "Configuração de step adicionada"
              : "Configuração de step atualizada";
          OToastManager.success(message);
          if (diligenceStepConfigId == null)
            navigate(
              corporateRouter.routes.energia.product.diligenceConfig.stepConfigs.update.path(
                { stepConfigId: response.data.response.id },
              ),
              { replace: true },
            );
        } else {
          const defaultMessage =
            diligenceStepConfigId == null
              ? "Erro ao adicionar configuração de step"
              : "Erro ao atualizar configuração de step";
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ?? defaultMessage,
          );
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
