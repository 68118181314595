import { UseFormReturn } from "react-hook-form";
import * as yup from "yup";

export interface EmailTemplateFormValues {
  name: string;
  template: string;
  header: string;
  messageTemplate: string;
  portalUrl: string;
  mailTo: string;
}

export const emailTemplateFormValidationSchema: yup.ObjectSchema<EmailTemplateFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    template: yup.string().required("Este campo é obrigatório"),
    header: yup.string().required("Este campo é obrigatório"),
    messageTemplate: yup.string().required("Este campo é obrigatório"),
    portalUrl: yup.string().required("Este campo é obrigatório"),
    mailTo: yup.string().required("Este campo é obrigatório"),
  });

export interface OnboardingEmailTemplateFormProps {
  form: UseFormReturn<EmailTemplateFormValues, any>;
}
