import { PostAdminNewGetIrisPowersCachedResponse } from "services/onboarding-relationships/models";
import {
  GetCompaniesByIdCedentesContractComplementResponse,
  PostCompaniesByIdCedentesContractComplementBody,
} from "services/quickfin/models";
import { CedenteDetailsForm } from "../../cedente-details.types";
import { EmailOption, PowerType } from "./contract-complement.types";

// actId=15 (Ceder direitos creditários)
const ASSIGN_CREDIT_RIGHTS_ACT_ID = 15;

export const parseContractComplement = (
  formContractComplement?: {
    key: string;
    value: string;
  }[],
) => {
  return formContractComplement?.reduce(
    (prev, { key, value }) => (!key ? prev : { ...prev, [key]: value }),
    {},
  );
};

export const parseContractComplementPayload = (
  sacadoId: number,
  cedenteId: number,
  values: CedenteDetailsForm,
  parsedContractComplement: Record<string, string> | undefined,
  inclusionList: string[],
  actualContractComplement?: GetCompaniesByIdCedentesContractComplementResponse,
) => {
  const actualSignatories =
    actualContractComplement?.contractSpecificSignatories;
  return {
    contractTemplateComplement: {
      variables: parsedContractComplement ?? {},
      contractSignatories: {
        signatoriesEmails: actualSignatories?.signatoriesEmails ?? [],
        minimumNumberOfSigners: actualSignatories?.minimumNumberOfSigners ?? 0,
      },
      inclusionList:
        inclusionList ?? actualContractComplement?.inclusionList ?? [],
    },
    sacadoId,
    cedenteId,
    tipoProduto: values.tipoProduto,
  } satisfies PostCompaniesByIdCedentesContractComplementBody;
};

export const parseContractComplementForm = (
  actualContractComplement?: GetCompaniesByIdCedentesContractComplementResponse,
) => {
  if (!actualContractComplement) return [];

  return Object.entries(actualContractComplement.variables).map(
    ([key, value]) => ({ key, value }),
  ) satisfies CedenteDetailsForm["contractComplement"];
};

const parseGroupRepresenatives = (
  actualOptions: EmailOption[],
  groups: PostAdminNewGetIrisPowersCachedResponse["powers"][number]["groups"],
) => {
  return groups.reduce<EmailOption[]>((prevGroup, group) => {
    return [
      ...prevGroup,
      ...group.representatives.reduce<EmailOption[]>((prevRep, rep) => {
        const { name, email, taxId } = rep;
        const comparePrev = [...actualOptions, ...prevRep, ...prevGroup];
        if (
          comparePrev.findIndex((actualRep) => actualRep.taxId === rep.taxId) >
            -1 ||
          [null, undefined, ""].includes(rep.taxId)
        ) {
          return prevRep;
        }
        return [...prevRep, { name, email, taxId }];
      }, []),
    ];
  }, []);
};

export const parseEmailOptions = (
  powers: PostAdminNewGetIrisPowersCachedResponse["powers"],
) => {
  const powersToParse = powers.filter(
    (power) =>
      power.actId === ASSIGN_CREDIT_RIGHTS_ACT_ID &&
      power.statusName === "ATIVO" &&
      power.enable,
  );
  return powersToParse.reduce<EmailOption[]>((prev, power) => {
    if (power.type === PowerType.Groups) {
      return [...prev, ...parseGroupRepresenatives(prev, power.groups)];
    }
    if (power.type === PowerType.Representatives) {
      return [
        ...prev,
        ...power.representatives.reduce<EmailOption[]>(
          (prevReps, representative) => {
            const { name, email, taxId } = representative;
            const comparePrev = [...prev, ...prevReps];
            if (
              [null, undefined, ""].includes(representative.taxId) ||
              comparePrev.findIndex(
                (rep) => rep.taxId === representative.taxId,
              ) > -1
            ) {
              return prevReps;
            }
            return [...prevReps, { name, email, taxId }];
          },
          [],
        ),
      ];
    }

    return prev;
  }, []);
};
