import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { DocumentTypeFormComponent, useDocumentTypeForm } from "../_compose";

export const AddDocumentTypePage = () => {
  const { form, loading, submit } = useDocumentTypeForm(null);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar tipo de documento" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <DocumentTypeFormComponent />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
