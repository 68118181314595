import * as yup from "yup";
import { LoanContractsTemplatesFormValues } from "./loan-contracts-templates-form.component";

export const loanContractsTemplatesValidationFormSchema: yup.ObjectSchema<LoanContractsTemplatesFormValues> =
  yup.object({
    contractTemplates: yup
      .array()
      .min(1, "Necessário preencher pelo menos um template")
      .required("Necessário preencher pelo menos um template"),
  });
