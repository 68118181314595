import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { service } from "services/service";

const BOOTSTRAP_XXL = 1400;
const BOOTSTRAP_LG = 992;
const BOOTSTRAP_SM = 576;

type RoleSizeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const useStatusCarousel = (
  activeStatus: string,
  relationship: string,
  setActiveStatus: (activeStatus: string) => void,
) => {
  const [rowSize, setRowSize] = useState<RoleSizeType>(3);
  const [firstIndex, setFirstIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusList, setStatusList] =
    useState<HubOnboardingRelationships.Admin.MonitoringStatus[]>();
  const [filteredStatusList, setFilteredStatusList] =
    useState<HubOnboardingRelationships.Admin.MonitoringStatus[]>();

  const cardsCount = useMemo(() => 12 / rowSize, [rowSize]);

  const preferencesKey = useMemo(
    () => btoa(`${relationship}_preferences`),
    [relationship],
  );

  const resizedStatusList = useMemo(
    () =>
      filteredStatusList?.slice(firstIndex, cardsCount + firstIndex) ??
      undefined,
    [cardsCount, filteredStatusList, firstIndex],
  );

  const nullCountStatusList = useMemo(() => {
    if (!statusList) {
      return [];
    }
    return statusList
      .filter((status) => status.count === 0)
      .map((status) => status.status);
  }, [statusList]);

  const disableHideNullStatuses = useMemo(() => {
    for (const nullCountStatus of nullCountStatusList) {
      if (
        filteredStatusList?.find((status) => status.status === nullCountStatus)
      ) {
        return false;
      }
    }
    return true;
  }, [filteredStatusList, nullCountStatusList]);

  const calculateFilteredStatusList = useCallback(() => {
    if (!statusList) {
      setFilteredStatusList(undefined);
      return;
    }
    const filterList = localStorage.getItem(preferencesKey);

    if (!filterList) {
      setFilteredStatusList(statusList);
      return;
    }

    const parsedFilterList = JSON.parse(window.atob(filterList));
    setFilteredStatusList(
      statusList.filter((status) => !parsedFilterList.includes(status.status)),
    );
  }, [preferencesKey, statusList]);

  const removeStatus = useCallback(
    (status: string) => {
      const filterList = localStorage.getItem(preferencesKey);

      const parsedFilterList: string[] = filterList
        ? JSON.parse(window.atob(filterList))
        : [];

      parsedFilterList.push(status);

      localStorage.setItem(
        preferencesKey,
        window.btoa(JSON.stringify(parsedFilterList)),
      );

      if (activeStatus === status) {
        const newStatus = filteredStatusList?.find(
          (filteredStatus) => filteredStatus.status !== status,
        )?.status;
        setActiveStatus(newStatus ?? "TODOS");
      }

      calculateFilteredStatusList();
    },
    [
      activeStatus,
      calculateFilteredStatusList,
      filteredStatusList,
      preferencesKey,
      setActiveStatus,
    ],
  );

  const hideNullStatuses = useCallback(() => {
    if (!statusList) {
      return;
    }
    const filterList = localStorage.getItem(preferencesKey);

    const parsedFilterList: string[] = filterList
      ? JSON.parse(window.atob(filterList))
      : [];

    const newFilterList = [...parsedFilterList, ...nullCountStatusList];

    localStorage.setItem(
      preferencesKey,
      window.btoa(JSON.stringify(newFilterList)),
    );

    if (nullCountStatusList.includes(activeStatus)) {
      const newStatus = filteredStatusList?.find(
        (filteredStatus) =>
          !nullCountStatusList.includes(filteredStatus.status),
      )?.status;
      setActiveStatus(newStatus ?? "TODOS");
    }

    calculateFilteredStatusList();
  }, [
    activeStatus,
    calculateFilteredStatusList,
    filteredStatusList,
    nullCountStatusList,
    preferencesKey,
    setActiveStatus,
    statusList,
  ]);

  const resetPreferences = useCallback(() => {
    localStorage.removeItem(preferencesKey);
    calculateFilteredStatusList();
  }, [calculateFilteredStatusList, preferencesKey]);

  const onPageBackward = useCallback(() => {
    if (firstIndex === 0) {
      return;
    }
    setFirstIndex((oldValue) => oldValue - 1);
  }, [firstIndex]);

  const onPageForward = useCallback(() => {
    if (
      !filteredStatusList ||
      firstIndex + cardsCount >= filteredStatusList.length
    ) {
      return;
    }
    setFirstIndex((oldValue) => oldValue + 1);
  }, [cardsCount, firstIndex, filteredStatusList]);

  const fetchStatuses = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        type: relationship,
      };
      const { data } =
        await service.onboardingRelationships.getMonitoringStatus(payload);
      const list = data.status;
      list.unshift({
        count: data.count,
        description: data.description,
        status: "TODOS",
        title: "Todos",
      });
      setStatusList(list);
    } catch {
      OToastManager.danger("Falha ao obter status de monitoramento");
    } finally {
      setLoading(false);
    }
  }, [relationship]);

  const handleResize = useCallback(() => {
    if (window.innerWidth < BOOTSTRAP_SM) {
      setRowSize(12);
      return;
    }
    if (window.innerWidth < BOOTSTRAP_LG) {
      setRowSize(6);
      return;
    }
    if (window.innerWidth < BOOTSTRAP_XXL) {
      setRowSize(4);
      return;
    }
    setRowSize(3);
  }, []);

  const handleRemoval = useCallback(() => {
    if (!filteredStatusList) {
      return;
    }

    if (
      filteredStatusList.length - firstIndex < cardsCount &&
      filteredStatusList.length > cardsCount
    ) {
      setFirstIndex(filteredStatusList.length - cardsCount);
    }
  }, [cardsCount, filteredStatusList, firstIndex]);

  useEffect(() => {
    calculateFilteredStatusList();
  }, [calculateFilteredStatusList]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    handleRemoval();
  }, [handleRemoval]);

  return {
    cardsCount,
    disableHideNullStatuses,
    filteredStatusList,
    firstIndex,
    hideNullStatuses,
    loading,
    onPageBackward,
    onPageForward,
    removeStatus,
    resetPreferences,
    resizedStatusList,
    rowSize,
    statusList,
  };
};
