import { OConfirmationModal } from "@maestro/react";

export type ConfirmationModalProps = {
  onConfirm: () => void;
  id: string;
  isConfirmationModalProcessing: boolean;
};

export const ConfirmationChannelValidationModal = ({
  onConfirm,
  id,
  isConfirmationModalProcessing,
}: ConfirmationModalProps) => {
  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={id}
      loading={isConfirmationModalProcessing}
      title="Você confirma considerar todas as aprovações realizadas pelo canal?"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={onConfirm}
    />
  );
};
