import { OToastManager } from "@maestro/core";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { RelationshipContingencies } from "../../../../../../components/contingencias-relacionamento";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../../routes/cadastro.route-params";

export const LoaContingencies = () => {
  const { uuid } = useParams<CadastroClienteProcuracoesByUuidRouteParams>();
  if (!uuid)
    throw new Error("No representative workflow uuid in route parameters");

  const [workflow, setWorkflow] =
    useState<HubOnboardingRelationships.Admin.Loaworkflow>();

  const getRepresentativeWorkflow = useCallback(async () => {
    try {
      const { data } = await service.onboardingRelationships.getWorkflowDetails(
        {
          uuid,
        },
      );
      setWorkflow(data);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o workflow");
    }
  }, [uuid]);

  useEffect(() => {
    getRepresentativeWorkflow();
  }, [getRepresentativeWorkflow]);

  return (
    <div>
      <PageTitle title="Contingências Procuração física" />
      {workflow && (
        <RelationshipContingencies
          entity={workflow}
          relationship="REPRESENTANTE_PROCURACAO_FISICA"
        />
      )}
    </div>
  );
};
