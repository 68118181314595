import styled from "styled-components";

export const MenuContainer = styled.div`
  padding: 1.5rem 1rem;
  border-right: 1px solid var(--theme-dark-20);
  background-color: var(--theme-light);
`;

export const ContentContainer = styled.div`
  padding: 1.5rem 1rem;

  height: 100%;
  box-sizing: border-box;
`;
