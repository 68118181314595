import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { partnersGrid } from "./partners.grid";

export const PartnersPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Parceiros" />}
      actions={
        <RouterButton
          type="tertiary"
          href={corporateRouter.routes.emprestimos.product.partners.add.path}
          role={roles.emprestimos.product.partnersAddPage.role}
          outline
        >
          Cadastrar parceiro
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={partnersGrid} />
    </GridTemplate>
  );
};
