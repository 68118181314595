import { OIcon, OLink, ORFieldUploadInput, OTypography } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { UploadContainer } from "../../../../components";
import { useIntubateGeneric } from "./intubate-generic.hook";
import { IntubateType } from "./intubate-generic.types";
import { intubateTypeMap } from "./intubate-generic.utils";

interface IntubateGenericProps {
  type: IntubateType;
}

export const IntubateGeneric = ({ type }: IntubateGenericProps) => {
  const {
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    reset,
    setHasParseError,
  } = useIntubateGeneric(type);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title={intubateTypeMap[type].title} />}
    >
      <ContentTemplate
        loading={readerLoading || apiLoading}
        hasError={hasParseError || !!readerError}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao processar a planilha"
            messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
          >
            <TryAgainButton
              onClick={() => {
                setHasParseError(false);
                reset();
              }}
            />
          </ErrorComponent>
        }
        render={() => (
          <div>
            <FormProvider {...form}>
              <UploadContainer>
                <ORFieldUploadInput
                  id="quote"
                  name="quote"
                  inputLabel="Clique ou arraste o arquivo aqui"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  dataAction="cotacao:upload:cotacao"
                  dataLabel="cotacao"
                  multiple={false}
                  handleAddFile={handleAddFile}
                  value={[]}
                />
              </UploadContainer>

              <div className="d-flex flex-column align-items-center gap-3 mt-4">
                <OLink href={intubateTypeMap[type].modelUrl}>
                  <OIcon
                    category="fas"
                    icon="fa-external-link"
                    className="me-2"
                  />
                  Baixar modelo
                </OLink>
                <OTypography type="default">
                  {intubateTypeMap[type].tip}
                </OTypography>
              </div>
            </FormProvider>
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
