import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { getValueFromMap } from "utils/get-value-from-map";
import { limitAnalysisStatusMap } from "../overdraft-details.utils";

export const buildOverdraftAnalysisFields = (
  analysis: BankingHub.LimitAnalysis,
): DetailsFields => ({
  analysis: {
    title: "Limite ofertado",
    items: [
      {
        label: "Valor ",
        value: masks.currency(analysis.amount ?? 0, currencySymbol.BRL, "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Data de expiração",
        value: format.date(analysis.expirationDate, { timeZone: "UTC" }) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Status",
        value:
          getValueFromMap(limitAnalysisStatusMap, analysis.status) ??
          analysis.status,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
