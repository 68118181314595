import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useEligibilityTree } from "../../eligibility-tree.context";
import { mapCriteriaFields } from "./_compose/criteria-fields/criteria-fields.utils";
import {
  editEligibilityGroupFormDefaultValues,
  editEligibilityGroupValidationSchema,
} from "./edit-eligibility-group-modal.form";
import {
  EditEligibilityGroupForm,
  criteriaFieldName,
  editEligibilityGroupModalId,
} from "./edit-eligibility-group-modal.types";
import { editGroup } from "./edit-eligibility-group-modal.utils";

export const useEditEligibilityGroupModal = () => {
  const { editingGroup, removeGroupAndChildren, replaceNodeInTree } =
    useEligibilityTree();

  const form = useForm<EditEligibilityGroupForm>({
    defaultValues: editEligibilityGroupFormDefaultValues,
    resolver: yupResolver(editEligibilityGroupValidationSchema),
  });

  const { control, reset, handleSubmit } = form;

  const fieldArray = useFieldArray({
    control,
    name: criteriaFieldName,
  });

  const removeGroup = useCallback(() => {
    if (!editingGroup) {
      OToastManager.danger("Nenhum grupo selecionado");
      return;
    }
    removeGroupAndChildren(editingGroup.id);

    modalManager.hide(editEligibilityGroupModalId);
  }, [editingGroup, removeGroupAndChildren]);

  const saveGroup = useMemo(
    () =>
      handleSubmit((values) => {
        if (!editingGroup) {
          OToastManager.danger("Nenhum grupo selecionado");
          return;
        }

        const updatedGroup = editGroup(
          editingGroup,
          values.name,
          values[criteriaFieldName],
        );

        replaceNodeInTree(updatedGroup);

        modalManager.hide(editEligibilityGroupModalId);
      }),
    [editingGroup, handleSubmit, replaceNodeInTree],
  );

  useEffect(() => {
    if (editingGroup) {
      reset({
        name: editingGroup.groupName,
        criteria: mapCriteriaFields(editingGroup.eligibilityCriteria ?? []),
      });
    }
  }, [editingGroup, reset]);

  return {
    fieldArray,
    form,
    removeGroup,
    saveGroup,
  };
};
