import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingDomainConfigForm } from "../_compose";
import { useOnboardingDomainConfigEdit } from "./edit.hook";

export const OnboardingDomainConfigEdit = () => {
  const { value: group } = useParseFromSearchParams<string>("group");
  const { value: key } = useParseFromSearchParams<string>("key");

  const { form, submit, submitLoading } = useOnboardingDomainConfigEdit(
    group,
    key,
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Domínio"
          description="Visualize e edite os dados do domínio selecionado"
        />
      }
      actions={
        <LoadingButton
          loading={submitLoading}
          dataAction="editar:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <OnboardingDomainConfigForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
