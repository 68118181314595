import styled from "styled-components";

export const FlowDevtools = styled.div`
  --border-radius: 4px;
  --highlight-color: rgba(238, 58, 115, 1);

  border-radius: var(--border-radius);
  font-size: 11px;

  button {
    background: white;
    border: none;
    padding: 5px 15px;
    color: #222;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;

    &:hover {
      background: var(--highlight-color);
      opacity: 0.8;
      color: white;
    }

    &.active {
      background: var(--highlight-color);
      color: white;
    }

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    &:last-child {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      border-right: none;
    }
  }
`;
