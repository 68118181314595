import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse } from "services/quickfin/models";

export const FidcOperationsDetailsGrid: ODataGridGeneratorConfig<
  GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse["trades"][number]
> = {
  datagrid: {
    noDataText: "Nenhum trade encontrado.",
    pager: {
      infoText: "Página {0} de {1} ({2} trades)",
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "receivable.receivableStatus",
      caption: "Status",
    },
    {
      dataField: "receivable.rejectMessage",
      caption: "Erro",
    },
    {
      dataField: "receivable.identification",
      caption: "Identificação",
    },
    {
      dataField: "receivable.sacado.officialName",
      caption: "Sacado",
    },
    {
      dataField: "receivable.sacado.identification",
      caption: "CNPJ do Sacado",
    },
    {
      dataField: "receivable.cedente.officialName",
      caption: "Cedente",
    },
    {
      dataField: "receivable.cedente.identification",
      caption: "CNPJ do cedente",
    },
    {
      dataField: "receivable.maturityDate",
      caption: "Vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "tradeMaturityDate",
      caption: "Vencimento alongado",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "receivable.currency",
      caption: "Moeda",
    },
    {
      dataField: "valorSolicitado",
      caption: "Valor solicitado",
      dataType: "number",
      format: {
        type: "fixedPoint",
        precision: 2,
      },
    },
    {
      dataField: "maturityAmount",
      caption: "Valor de vencimento",
      dataType: "number",
      format: {
        type: "fixedPoint",
        precision: 2,
      },
    },
    {
      dataField: "price",
      caption: "Valor desembolsado",
      dataType: "number",
      format: {
        type: "fixedPoint",
        precision: 2,
      },
    },
    {
      dataField: "taxaMensal",
      caption: "Taxa mensal",
      cellRender: ({ data }) => `${(data.taxaMensal * 100).toFixed(4)}%`,
    },
    {
      dataField: "taxaTotal",
      caption: "Taxa total",
      cellRender: ({ data }) => `${(data.taxaTotal * 100).toFixed(4)}%`,
    },
  ],
};
