import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { service } from "services";
import { CedenteDetailsForm } from "../../cedente-details.types";
import { SacadoDetailsForm } from "../../../../sacados/detalhes/sacado-details.types";

export const useFilteredEstrategiaSelector = (
  companyId: string | number,
  tipoProduto: string | null,
  form: UseFormReturn<CedenteDetailsForm | SacadoDetailsForm>,
) => {
  const {
    callService,
    loading,
    value: estrategias,
    hasError,
  } = useServiceCall(service.quickfin.getCompanyEstrategias);

  const { clearErrors, setError, setValue, watch } = form;

  const estrategiaWatcher = watch("estrategiaId");

  const getCompanyEstrategias = useCallback(async () => {
    const { success } = await callService(companyId);
    if (success) clearErrors("estrategiaId");
    else
      setError("estrategiaId", {
        message:
          "Erro ao buscar as estratégias. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, companyId, setError]);

  useEffect(() => {
    getCompanyEstrategias();
  }, [getCompanyEstrategias]);

  useEffect(() => {
    const selectedEstrategia = estrategias?.find(
      (estrategia) => estrategia.id === estrategiaWatcher,
    );
    if (selectedEstrategia) {
      setValue("estrategiaGUID", selectedEstrategia.uniqueId);
      setValue("tipoProduto", selectedEstrategia.tipoProduto);
    }
  }, [estrategiaWatcher, estrategias, setValue]);

  return {
    estrategias: estrategias?.filter(
      (estrategia) => !tipoProduto || tipoProduto === estrategia.tipoProduto,
    ),
    getCompanyEstrategias,
    loading,
    hasFetchError: hasError,
  };
};
