import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";
import {
  formDefaultValues,
  submitCardModalFormValidationSchema,
} from "./submit-card-modal-form.schemas";
import { modalModeMap } from "./submit-card-modal.utils";

export const useSubimitCardModal = () => {
  const [loading, setLoading] = useState(false);
  const [isToggleable, setIsToggleable] = useState(false);

  const form = useForm({
    resolver: yupResolver(submitCardModalFormValidationSchema),
  });
  const { reset, handleSubmit } = form;

  const {
    form: { callService: getForm, value: workflowFormTemplate },
    selectedStep,
    selectedCard,
    setSelectedCard,
  } = useWorkflowFormTemplate();

  const { hide } = modalManager;
  const modalMode = modalModeMap[selectedCard ? "UPDATE" : "CREATE"];

  const submitCard = handleSubmit(async (values) => {
    try {
      if (!workflowFormTemplate || !selectedStep) return;

      setLoading(true);

      const payload = {
        ...values,
        formStepId: selectedStep?.id,
        position:
          selectedCard?.position ??
          (selectedStep?.cards?.length
            ? selectedStep.cards
                .map((s) => s.position)
                .reduce((a, b) => Math.max(a, b)) + 1
            : 0),
      };

      if (selectedCard) {
        await service.hubCreditManager.updateFormStepCard({
          ...payload,
          id: selectedCard.id,
          toggleable: isToggleable,
        });
      } else {
        await service.hubCreditManager.addFormStepCard(payload);
      }

      hide("submit-card-modal");
      if (modalMode.isCreation) reset();

      getForm(workflowFormTemplate.id);

      OToastManager.success("Card salvo com sucesso.");
    } catch {
      OToastManager.danger("Não foi possível salvar o card.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    reset({
      title: selectedCard?.title ?? formDefaultValues.title,
      description: selectedCard?.description ?? formDefaultValues.description,
      content: selectedCard?.content ?? "UNSET",
    });

    if (selectedCard?.toggleable === undefined) return;
    setIsToggleable(selectedCard?.toggleable);
  }, [selectedCard, reset, setIsToggleable]);

  return {
    form,
    isToggleable,
    loading,
    modalMode,
    workflowFormTemplate,
    getForm,
    setIsToggleable,
    setSelectedCard,
    submitCard,
  };
};
