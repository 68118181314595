import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useWalletGridActions = (
  documentNumber: string | string,
  personType: number,
) => {
  const { callService: createBordero, loading: createBorderoLoading } =
    useServiceCall(service.moana.createBordero);

  const reprocessarBordero = useCallback(async () => {
    const { success } = await createBordero({ documentNumber, personType });
    if (success) {
      OToastManager.success("Bordero reprocessado com sucesso");
    } else {
      OToastManager.danger("Não foi possível reprocessar a solicitação");
    }
  }, [createBordero, documentNumber, personType]);

  return {
    reprocessarBordero,
    loading: createBorderoLoading,
  };
};
