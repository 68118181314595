import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ToCreditField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.toCredit}
      name="toCredit"
      description="Deseja que o desembolso seja com financeiro (S) ou sem financeiro (N)?"
    />
  );
};
