import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const SendErrorCallbackField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.sendErrorCallback}
      name="sendErrorCallback"
      description="Envia callback de rejeição para o turtle road"
    />
  );
};
