import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OConfirmationModal,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

interface ResendOfferNotificationButtonProps {
  offerId: string;
  companyId: string;
  companyName: string;
}

export const ResendOfferNotificationButton = ({
  offerId,
  companyId,
  companyName,
}: ResendOfferNotificationButtonProps) => {
  const { callService, hasError, loading } = useServiceCall(
    service.adminBankinghub.card.resendOfferNotification,
  );

  useEffect(() => {
    if (hasError) {
      OToastManager.danger("Não foi possível reenviar a notificação");
    }
  }, [hasError]);

  const resendOfferNotificationModalId = `resend-offer-notification-${offerId}-modal`;

  return (
    <>
      <OButton
        dataAction="grid_contratacoes:botao:reenviar-notificacao"
        dataLabel="reenviar-notificacao"
        type="dark"
        outline
        disabled={loading}
        onClick={() => modalManager.show(resendOfferNotificationModalId)}
      >
        <div className="d-flex align-items-center gap-2">
          {loading ? (
            <OLoader size="sm" />
          ) : (
            <OIcon category="orq" icon="orq-email" />
          )}
          Reenviar notificação
        </div>
      </OButton>
      <OConfirmationModal
        modalId={resendOfferNotificationModalId}
        title="Reenviar notificação de oferta disponível?"
        confirmLabel="Sim"
        closeLabel="Não"
        divider={false}
        size="xs"
        loading={loading}
        onConfirm={() => callService({ companyId })}
      >
        <OTypography
          type="dark"
          style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
        >
          Tem certeza que deseja reenviar a notificão de oferta disponível para{" "}
          {companyName}?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
