import {
  ODropdown,
  ODropdownItem,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { HighlightText } from "components/highlight-text";
import { StyledDropdownList } from "./indicators-select-search.styles";
import { SelectIndicatorsSearch } from "./indicators-select-search.types";

export const SelectSearchIndicators = ({
  indicators,
  watchValue,
  setValue,
  setOperatorName,
  setOriginatorName,
  formField,
  disabled,
}: SelectIndicatorsSearch) => {
  return (
    <ODropdown className="w-100" positionY="top">
      <ORFieldInput
        {...formField}
        disabled={disabled}
        handleClear={() => {
          setValue("indicatorOnField", "");
          setValue("referrerOperatorProfileIdentifier", "");
          setValue("selectedOperator", "");
          setOperatorName("");
          setValue("selectedOriginator", "");
          setOriginatorName("");
        }}
      />
      <StyledDropdownList>
        {indicators.map((entity) => (
          <ODropdownItem
            key={`dropdownitem_${entity.identifier}`}
            onClick={() => {
              setValue("referrerOperatorProfileIdentifier", entity.identifier);
              setValue("selectedOperator", entity.identification);
              setValue("selectedOriginator", entity.originator.identification);
              setOperatorName(entity.name);
              setOriginatorName(entity.originator.name);
            }}
          >
            <>
              <OTypography>
                <HighlightText
                  text={`${entity.originator.name.toUpperCase()} - ${masks.cpfCnpj(
                    entity.originator.identification,
                  )} - ${entity.partnershipTypeName}`}
                  highlightValue={watchValue?.split(" ").join("|")}
                />
              </OTypography>
              <div className="d-flex justify-content-between">
                <OTypography size="sm">
                  <HighlightText
                    text={`${entity.name.toUpperCase()} - ${masks.cpfCnpj(
                      entity.identification,
                    )}`}
                    highlightValue={watchValue?.split(" ").join("|")}
                  />
                </OTypography>
              </div>
            </>
          </ODropdownItem>
        ))}

        {!indicators.length && (
          <ODropdownItem>
            <OTypography>Nenhum indicador encontrado</OTypography>
          </ODropdownItem>
        )}
      </StyledDropdownList>
    </ODropdown>
  );
};
