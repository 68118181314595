import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { UnstyledUl } from "../products-list/products-list.styles";

interface DesksListProp {
  desks: PostAdminCompaniesGetByTaxIdResponse["desks"];
}

export const DesksList = ({ desks }: DesksListProp) => {
  return (
    <div className="position-relative">
      <UnstyledUl>
        {desks?.map((desk) => {
          const segment = desk?.segment?.name
            ? `${desk?.segment?.name}/${desk?.coSegment?.name}`
            : "";
          return (
            <li key={desk.id}>{`${desk.code} ${segment} - ${desk.name}`}</li>
          );
        })}
      </UnstyledUl>
    </div>
  );
};
