import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { RelocationPlan, RelocationSimulation } from "../../relocate.types";

const relocationPlansMasterDetailGrid: ODataGridGeneratorConfig<RelocationPlan> =
  {
    datagrid: {
      filterRow: { visible: true },
      headerFilter: { visible: true },
    },
    columns: [
      {
        dataField: "agenda_id",
        caption: "Id agenda",
        allowHeaderFiltering: false,
      },
      {
        dataField: "bandeira",
        caption: "Bandeira",
      },
      {
        dataField: "company_tax_id",
        caption: "CNPJ do Cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "credenciadora_tax_id",
        caption: "CNPJ do Sacado",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "maturity_date",
        dataType: "date",
        format: "shortDate",
        caption: "Vencimento",
        allowHeaderFiltering: false,
      },
      {
        dataField: "maturity_amount",
        caption: "Valor vencimento",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "price",
        caption: "Valor líquido",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "realocacao_taxa_mensal",
        caption: "Taxa mensal",
        format: (value) => masks.percentage(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "realocacao_taxa_total",
        caption: "Taxa total",
        format: (value) => masks.percentage(value),
        allowHeaderFiltering: false,
      },
    ],
  };

type RelocationPlansMasterDetailProps =
  MasterDetailComponentProps<RelocationSimulation>;

export const RelocationPlansMasterDetail = ({
  data: {
    data: { realocacao_plans },
  },
}: RelocationPlansMasterDetailProps) => (
  <ODataGridGenerator
    grid={relocationPlansMasterDetailGrid}
    dataSource={realocacao_plans}
  />
);
