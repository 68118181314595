// b2b route <=> corporate route
const routeMap = {
  "/b2b/remuneracao": "/finders/produto/remuneracao",
  "/b2b/common/originadores": "/finders/produto/originadores",
  "/b2b/common/operadores": "/finders/produto/operadores",
  "/b2b/common/produtos": "/finders/produto/catalogo",
  "/b2b/common/clientes": "/finders/produto/clientes",
  "/b2b/common/operacoes": "/finders/produto/operacoes",
  "/b2b/common/simulador": "/finders/produto/simulador",
  "/b2b/common/perfil": "/finders/produto/perfil",
  "/b2b/common/atendimento/materiais-de-apoio":
    "/finders/produto/atendimento/materiais-de-apoio",
  "/b2b/common/atendimento/entre-em-contato":
    "/finders/produto/atendimento/entre-em-contato",
};

const splitPathChunks = (path: string | undefined) =>
  path?.replace(/^\//, "").replace(/\/$/, "").split("/") ?? [];

export const translateRouteToB2B = (route: string) =>
  Object.entries(routeMap).reduce(
    (translated, [b2bRoute, adminRoute]) =>
      translated.replace(adminRoute, b2bRoute),
    route,
  );

export const translateRouteToAdmin = (route: string) =>
  Object.entries(routeMap).reduce(
    (translated, [b2bRoute, adminRoute]) =>
      translated.replace(b2bRoute, adminRoute),
    route,
  );

export const matchDynamicPath = (
  nodePathChunks: string[],
  pathChunks: string[],
) => {
  if (nodePathChunks.length !== pathChunks.length) return false;

  for (let i = 0; i < nodePathChunks.length; i += 1) {
    if (
      nodePathChunks[i] !== pathChunks[i] &&
      !nodePathChunks[i].startsWith(":")
    ) {
      return false;
    }
  }

  return true;
};

export const getCurrentPathParams = (
  nodePath: string | undefined,
  currentPath: string,
) => {
  if (!nodePath?.match(/\/:\w+/)) return {};

  const params: Record<string, string> = {};

  const nodePathChunks = splitPathChunks(nodePath);
  const currentPathChunks = splitPathChunks(currentPath);

  for (let i = 0; i < nodePathChunks.length; i += 1) {
    if (nodePathChunks[i] !== currentPathChunks[i]) {
      params[nodePathChunks[i].replace(":", "")] = currentPathChunks[i];
    }
  }

  return params;
};
