import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useDesks = () => {
  const {
    callService: _getAllDesks,
    loading: getDesksLoading,
    hasError: getDesksHasError,
    value: allDesks,
  } = useServiceCall(service.hubPartners.getDesks);

  const getDesks = useCallback(async () => {
    const { success } = await _getAllDesks();
    if (!success) OToastManager.danger("Não foi possível buscar as mesas");
  }, [_getAllDesks]);

  useEffect(() => {
    getDesks();
  }, [getDesks]);

  return {
    allDesks: allDesks?.data ?? [],
    getDesks,
    getDesksHasError,
    getDesksLoading,
  };
};
