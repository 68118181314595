import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, companyCedentesGrid } from "./company-cedentes.grid";

export const CompanyCedentes = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(quickfinId), [quickfinId]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Cedentes" />}
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.recebiveis.customer.cedentes.batchImport
                .path
            }
            role={roles.recebiveis.customer.cedentesBatchImportPage.role}
          >
            Importar cedentes em lote
          </StandardLink>
          <StandardLink
            href={
              corporateRouter.routes.recebiveis.customer.cedentes.addCedente
                .path
            }
            role={roles.recebiveis.customer.cedentesAddCedentePage.role}
          >
            Adicionar cedente
          </StandardLink>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={companyCedentesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
