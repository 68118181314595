import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useState } from "react";
import { service } from "services";

interface SyncAgromaticButtonProps {
  limitRequestId: number;
  disabled?: boolean;
}

export const SyncAgromaticButton = ({
  limitRequestId,
  disabled,
}: SyncAgromaticButtonProps) => {
  const [loading, setLoading] = useState(false);

  const syncAgromatic = useCallback(async () => {
    try {
      setLoading(true);

      await service.hubCreditManager.syncAgromatic({
        id: limitRequestId,
      });

      OToastManager.success("Sincronização concluída.");
    } catch {
      OToastManager.danger("Não foi possível sincronizar com Agromatic.");
    } finally {
      setLoading(false);
    }
  }, [limitRequestId]);

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      dataAction="emprestimos_contingencia:botao:sync_agromatic"
      dataLabel="sync_agromatic"
      onClick={() => syncAgromatic()}
      outline
    >
      Sincronizar
    </LoadingButton>
  );
};
