import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface PropertyTypeForm {
  label: string;
  mask: string;
  name: string;
}

export const propertyTypeFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  label: {
    id: "label",
    name: "label",
    tag: "text",
    label: "Label",
    placeholder: "Preencher",
    required: true,
  },
  mask: {
    id: "mask",
    name: "mask",
    tag: "text",
    label: "Máscara",
    placeholder: "Preencher",
    required: true,
  },
} as const satisfies MaestroFields<PropertyTypeForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const propertyTypeFormValidationSchema = yup.object<
  PropertyTypeForm,
  YupObjectSchema<PropertyTypeForm>
>({
  label: stringRequired,
  mask: stringRequired,
  name: stringRequired,
});

export const propertyTypeFormDefaultValues = {
  label: null,
  mask: null,
  name: null,
} satisfies Partial<
  DeepNullable<PropertyTypeForm>
> as unknown as PropertyTypeForm;
