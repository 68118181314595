import * as yup from "yup";
import { EnergyDistributorDiligenceFormValues } from "./field-form.model";

export const energyDistributorDiligenceValidationFormSchema: yup.ObjectSchema<EnergyDistributorDiligenceFormValues> =
  yup.object({
    needsPreApproval: yup.array().required("Campo obrigatório").default([]),
    needsPostApproval: yup.array().required("Campo obrigatório").default([]),
    diligenceStepConfigIds: yup
      .array()
      .required("Campo obrigatório")
      .default([]),
  });
