import { OCol, ORFieldInputProgress } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { cardHiringFields } from "../../card-hiring-form.form";

export const PrintedNameField = () => {
  const { watch } = useFormContext();

  const valueWatcher = watch("printedName");

  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldInputProgress
        {...cardHiringFields.printedName}
        valueLength={valueWatcher?.length ?? 0}
      />
    </OCol>
  );
};
