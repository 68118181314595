import { OLoader } from "@maestro/react";
import { useEffect, useMemo, useRef, useCallback } from "react";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { useSearchParams } from "react-router-dom";
import { logger } from "utils/logger";

export const SingleSignOn = () => {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const idTokenHint = searchParams.get("id_token_hint");
  const idpHint = searchParams.get("idp_hint");
  const acrValues = searchParams.get("acr_values");
  const taxId = searchParams.get("tax_id");
  const signInRequested = useRef(false);
  const contexts: { [key: string]: AuthContextProps } = useMemo(
    () => ({
      "btg:id:digital:rmadmin": auth,
    }),
    [auth],
  );

  const signIn = useCallback(async () => {
    try {
      if (idTokenHint === null || idpHint === null || acrValues === null)
        return;

      const context = contexts[idpHint];

      await context.signinRedirect({
        extraQueryParams: {
          idp_hint: idpHint,
          acr_values: acrValues,
          id_token_hint: idTokenHint,
          prompt: "login",
        },
        state: { taxId },
      });
    } catch (error) {
      logger.error(error);
    }
  }, [acrValues, contexts, idTokenHint, idpHint, taxId]);

  useEffect(() => {
    if (!signInRequested.current) {
      signInRequested.current = true;
      signIn();
    }
  }, [signIn]);

  return <OLoader absolute data-component="SingleSignOnCallback" />;
};
