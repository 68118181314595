import dayjs from "dayjs";
import { useMemo } from "react";
import { mapLoanStatus } from "../../../../../../components/utils/loan-status.utils";
import { LoanData } from "../loan-summary.types";
import { StatusCard } from "./status-card.component";

interface LoanStatusCardsProps {
  loanData: LoanData;
}

const disbursementLoanStatus = [
  "AguardandoDesembolso",
  "AguardandoAprovacao",
  "Desembolsado",
  "Liquidado",
];

export const LoanStatusCards = ({ loanData }: LoanStatusCardsProps) => {
  const Cards = useMemo(() => {
    const cards: JSX.Element[] = [];

    if (loanData) {
      const isDisbursed = disbursementLoanStatus.includes(loanData.status);
      const isCanceled = loanData.status === "Cancelado";
      const isLate = loanData.status === "Atrasado";

      const loanStatusHistory = loanData.loanStatusHistory?.sort((a, b) =>
        dayjs(a.createdDate).isAfter(b.createdDate) ? 1 : -1,
      );

      loanStatusHistory.forEach((status, i, arr) =>
        cards.push(
          <div
            className="d-flex justify-content-between align-items-center"
            key={`status-history-${status.createdDate}-${status.newStatus}`}
          >
            {i !== 0 && <i className="fa fa-arrow-right" />}
            <StatusCard
              type={
                isDisbursed
                  ? "success-light"
                  : isCanceled && i + 1 === arr.length
                  ? "danger-light"
                  : isLate && i + 1 === arr.length
                  ? "warning-light"
                  : "light"
              }
              title={mapLoanStatus(status.newStatus)}
              updatedAt={status.createdDate}
            />
          </div>,
        ),
      );
    }

    return cards;
  }, [loanData]);

  return (
    <div className="d-flex justify-content-center gap-2 flex-wrap">{Cards}</div>
  );
};
