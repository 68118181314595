import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ContractTemplateDetailsForm } from "../../../../components/contract-templates/contract-template-details-form.component";
import {
  ContractTemplateDetailsFormValues,
  contractTemplateDetailsFormValidationSchema,
} from "../../../../components/contract-templates/contract-template-details-form.schemas";
import { ReceivablesProductContractTemplatesByTemplateIdRouteParams } from "../../../../routes/recebiveis.route-params";

export const EditContractTemplate = () => {
  const { templateId } =
    useParams<ReceivablesProductContractTemplatesByTemplateIdRouteParams>();

  if (!templateId) throw new Error("No templateId");

  const [loading, setLoading] = useState(false);
  const form = useForm<ContractTemplateDetailsFormValues>({
    resolver: yupResolver(contractTemplateDetailsFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getPublicContractTemplates,
    loading: loadingTemplates,
    value: templates,
    hasError: hasErrorTemplates,
  } = useServiceCall(service.quickfin.getPublicContractTemplates);

  const template = useMemo(
    () =>
      templates?.find(
        (contractTemplate) => contractTemplate.id === +templateId,
      ),
    [templateId, templates],
  );

  const onDownloadFile = useCallback(
    async (file: File) => {
      try {
        const { data: base } =
          await service.quickfin.downloadPublicContractTemplateFile(templateId);

        helpers.downloadBase64(base, file.name);
      } catch {
        OToastManager.danger("Não foi possível fazer o download do arquivo");
      }
    },
    [templateId],
  );

  const update = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.quickfin.updatePublicContractTemplate(templateId, {
            name: values.name,
            signatureOrigin: values.signatureOrigin,
            file: {
              originalName: values.file[0].name,
              fileContent:
                values.file[0] instanceof File
                  ? await helpers.convertFileToBase64(values.file[0])
                  : undefined,
            },
            needsSignature: !!values.needsSignature?.length,
            geraNovosTermos: !!values.geraNovosTermos?.length,
            hasComplement: !!values.hasComplement?.length,
            signingParties: [
              {
                partyType: "Sacado",
                ...values.signingParties?.sacado,
                companyId: 0,
                parties: values.signingParties?.sacado?.parties ?? [],
                validFor: values.signingParties?.sacado?.validFor ?? [],
                contractTemplateId: +templateId,
              },
              {
                partyType: "Cedente",
                ...values.signingParties?.cedente,
                companyId: 0,
                parties: values.signingParties?.cedente?.parties ?? [],
                validFor: values.signingParties?.cedente?.validFor ?? [],
                contractTemplateId: +templateId,
              },
            ],
          });

          OToastManager.success("Template atualizado com sucesso.");
        } catch (e) {
          OToastManager.danger("Não foi possível atualizar o template.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, templateId],
  );

  useEffect(() => {
    getPublicContractTemplates();
  }, [getPublicContractTemplates]);

  useEffect(() => {
    template &&
      reset({
        name: template.name,
        signatureOrigin: template.signatureOrigin,
        needsSignature: template.needsSignature ? ["true"] : [],
        hasComplement: template.hasComplement ? ["true"] : [],
        file: template.file
          ? [{ name: template.file.originalName } as File]
          : [],
        signingParties: {
          cedente: template.signingParties.find(
            (s) => s.partyType === "Cedente",
          ),
          sacado: template.signingParties.find((s) => s.partyType === "Sacado"),
        },
      });
  }, [reset, template]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do template" />}
      actions={
        <LoadingButton
          dataAction="recebiveis_editar_template_contrato:botao:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={update}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ContentTemplate
            loading={loadingTemplates}
            hasError={hasErrorTemplates}
            errorComponent={
              <ErrorComponent
                messageTitle="Ocorreu um erro ao buscar o template"
                messageParagraph="Clique no botão para tentar novamente."
              >
                <TryAgainButton onClick={() => getPublicContractTemplates()} />
              </ErrorComponent>
            }
            value={template}
            render={() => (
              <ContractTemplateDetailsForm onDownloadFile={onDownloadFile} />
            )}
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
