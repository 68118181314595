import { OTypography } from "@maestro/react";

export const RoleDescription = () => {
  return (
    <div className="d-flex flex-column gap-1 mb-4">
      <OTypography>
        <strong>Atenção:</strong> O nome de uma role deve seguir a seguinte
        formatação:
      </OTypography>
      <OTypography weight="500">[serviço]:[recurso].[ação]</OTypography>
      <OTypography>Ex: partner:OnboardingAgent.GetCustomer</OTypography>
    </div>
  );
};
