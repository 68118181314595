import { OCol, ORFieldInputDate, ORow } from "@maestro/react";

export const VigencyField = () => {
  return (
    <div className="d-flex align-items-center gap-4 flex-fill">
      <ORow className="flex-fill">
        <OCol xs={2}>
          <ORFieldInputDate
            allowClear
            id="vigency"
            name="vigency"
            label="Vigência"
            picker="date"
            disabledDate={(date) => date.isBefore()}
            size="md"
          />
        </OCol>
      </ORow>
    </div>
  );
};
