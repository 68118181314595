import { BankData } from "./bank-data";
import { Confirmation } from "./confirmation";
import { FileUpload } from "./file-upload";
import { QuoteFileStep } from "./quote-file";
import { QuoteListStep } from "./quote-list";
import { QuoteSuggestStep } from "./quote-suggest";
import { Result } from "./result";
import { SummaryStep } from "./summary";
import { ValidateStep } from "./validate";

export const fileSteps = [
  {
    name: "Envio de arquivo",
    component: FileUpload,
  },
  {
    name: "Cotação",
    component: QuoteFileStep,
  },
  {
    name: "Resumo",
    component: SummaryStep,
  },
  {
    name: "Validação",
    component: ValidateStep,
  },
  {
    name: "Dados bancários",
    component: BankData,
  },
  {
    name: "Criar operação",
    component: Confirmation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];

export const listSteps = [
  {
    name: "Cotação",
    component: QuoteListStep,
  },
  {
    name: "Resumo",
    component: SummaryStep,
  },
  {
    name: "Validação",
    component: ValidateStep,
  },
  {
    name: "Dados bancários",
    component: BankData,
  },
  {
    name: "Criar operação",
    component: Confirmation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];

export const suggestSteps = [
  {
    name: "Cotação",
    component: QuoteSuggestStep,
  },
  {
    name: "Resumo",
    component: SummaryStep,
  },
  {
    name: "Validação",
    component: ValidateStep,
  },
  {
    name: "Dados bancários",
    component: BankData,
  },
  {
    name: "Criar operação",
    component: Confirmation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
