import { masks } from "@maestro/utils";
import { generateCustomerServiceTokenAndRedirect } from "components/customer-service-button";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import {
  PostAdminOdataDesksAllUsersSelfResponse,
  PostAdminOdataDesksB2BUsersSelfResponse,
} from "services/clerk";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import { emailActivationBadgeMap } from "../../../../utils/maps";
import { statusBiometryMap } from "../../../cliente/utils";
import { userStatusMap } from "../../users.utils";

export type User = NonNullable<
  PostAdminOdataDesksAllUsersSelfResponse["data"]
>[number];

export type B2bUser = NonNullable<
  PostAdminOdataDesksB2BUsersSelfResponse["data"]
>[number];

const buildDetailsLink = (data: User | B2bUser, route: "b2b" | "b2c") => {
  return corporateRouter.routes.usuarios.product[route].user.details.path({
    identification: data.identification,
  });
};

export const usersGrid = (
  route: "b2b" | "b2c",
): ODataGridGeneratorConfig<User | B2bUser> => {
  return {
    datagrid: {
      noDataText: "Nenhum usuário encontrado.",
      headerFilter: { visible: true },
      remoteOperations: true,
      filterRow: {
        visible: true,
      },
      filterValue: [
        ["originatorName", "<>", "Prev Conecta"],
        "or",
        ["originatorName", "=", null],
      ],
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
        infoText: "Página {0} de {1} ({2} usuários)",
      },
      stateStoring: gridStorage("users-monitor"),
    },
    columns: [
      {
        type: "buttons",
        role: roles.usuarios.product[`${route}DetailsPage`].role,
        cellRender: ({ data }) => (
          <DetailsLink href={buildDetailsLink(data, route)} />
        ),
      },
      {
        dataField: "name",
        dataType: "string",
        caption: "Nome",
        allowHeaderFiltering: false,
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "identification",
        dataType: "string",
        caption: "CPF",
        allowHeaderFiltering: false,
        format: (value) => masks.cpf(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "email",
        dataType: "string",
        caption: "Email",
        allowHeaderFiltering: false,
      },
      {
        dataField: "companyIdentification",
        dataType: "string",
        caption: "CNPJ do Cliente",
        allowHeaderFiltering: false,
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "companyOfficialName",
        dataType: "string",
        caption: "Razão social",
        allowHeaderFiltering: false,
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "isActive",
        dataType: "boolean",
        caption: "Usuário ativo",
      },
      {
        dataField: "accountStatus",
        dataType: "string",
        caption: "Status do Login",
        alignment: "center",
        cellRender: ({ data: { accountStatus } }) => {
          const badge = getValueFromMap(userStatusMap, accountStatus);
          return badge ? (
            <StatusBadge type={badge.type} size="sm" className="d-inline-block">
              {badge.label}
            </StatusBadge>
          ) : (
            accountStatus
          );
        },
      },
      {
        dataField: "biometryStatus",
        dataType: "string",
        caption: "Status biometria",
        alignment: "center",
        cellRender: ({ data }) => {
          const badge = statusBiometryMap[data.biometryStatus ?? ""];
          return badge ? (
            <StatusBadge type={badge.type} size="sm" className="d-inline-block">
              {badge.label}
            </StatusBadge>
          ) : (
            data.biometryStatus
          );
        },
      },
      {
        dataField: "emailActivationStatus",
        dataType: "string",
        caption: "Ativação do e-mail",
        alignment: "center",
        cellRender: ({ data }) => {
          const badge = getValueFromMap(
            emailActivationBadgeMap,
            data.emailActivationStatus,
          );
          return badge ? (
            <StatusBadge type={badge.type} size="sm" className="d-inline-block">
              {badge.label}
            </StatusBadge>
          ) : (
            data.biometryStatus
          );
        },
      },
      {
        dataField: "uuid",
        dataType: "string",
        caption: "Identificador único",
      },
      {
        dataField: "insertedAt",
        dataType: "date",
        caption: "Data de criação",
        format: "dd/MM/yyyy HH:mm:ss",
        allowHeaderFiltering: false,
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "updatedAt",
        dataType: "date",
        caption: "Data de atualização",
        format: "dd/MM/yyyy HH:mm:ss",
        allowHeaderFiltering: false,
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "lastLogin",
        dataType: "date",
        caption: "Último login",
        format: "dd/MM/yyyy HH:mm:ss",
        allowHeaderFiltering: false,
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "originatorName",
        dataType: "string",
        caption: "Nome originador",
        allowHeaderFiltering: false,
        visible: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <IconButton
            icon={{ category: "orq", icon: "orq-support" }}
            dataAction="users_grid:botao:ver_como_atendimento"
            dataLabel="ver_como_atendimento"
            type="dark"
            outline
            onClick={() =>
              generateCustomerServiceTokenAndRedirect(data.identification)
            }
          >
            Ver como atendimento
          </IconButton>
        ),
      },
    ],
  };
};
