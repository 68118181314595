import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { EstimulosMensagensDetail } from "../../../components/mensagens-grid/cobrancas-estimulos-mensagens-detail.grid";

type CobrancasEstimulos = Awaited<
  ReturnType<typeof service.shark.getOperations>
>["data"]["data"][number];

export const estimulosGrid = {
  datagrid: {
    noDataText: "Nenhum estímulo encontrado.",
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      caption: "Disparado em",
      dataField: "insertedAt",
      dataType: "datetime",
      sortOrder: "desc",
    },
    {
      caption: "CNPJ/CPF",
      dataField: "taxId",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Produtos",
      dataField: "products",
      calculateCellValue: (value) => value.products?.join(", ") ?? null,
      width: 400,
    },
    {
      caption: "Número(s) do trade",
      dataField: "operationIds",
      calculateCellValue: (value) => value.operationIds?.join(", ") ?? null,
      width: 400,
    },
    {
      caption: "Número da parcela",
      dataField: "installment",
      dataType: "number",
      alignment: "left",
    },
  ],
  paging: {
    enabled: false,
  },
  masterDetail: {
    component: EstimulosMensagensDetail,
    enabled: true,
  },
} satisfies ODataGridGeneratorConfig<CobrancasEstimulos[][number]>;
