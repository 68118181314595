import { BankIconSVG } from "../financial-institution-icon.types";

export const BankOOBSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#00353D" />
      <path
        d="M49.594 57.027l6.407-11.067L50.823 37H28.995a3.357 3.357 0 00-1.368.426A3.242 3.242 0 0026 40.25c0 .493.108.984.343 1.454l16.014 27.83 5.193-8.981h.004l2.04-3.526z"
        fill="#75C044"
      />
      <path
        d="M50.822 37l13.621 23.553h10.348L85.68 41.638c.197-.413.321-.886.321-1.39 0-1.726-1.361-3.137-3.073-3.248H50.822z"
        fill="#00AE9D"
      />
      <path
        d="M74.02 60.553H47.553l-5.196 8.979 10.962 18.882c.27.395.615.733 1.047.985.51.295 1.067.437 1.623.437a3.276 3.276 0 002.726-1.477L74.79 60.553h-.77z"
        fill="#BFD730"
      />
    </svg>
  );
};
