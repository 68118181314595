import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OLoader,
  ORFieldInput,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import {
  componentFormDefaultValues,
  componentFormSchema,
} from "../../../component-form.schemas";
import { ComponentInfo } from "../../../component-info.types";

interface ComponentDetailsProps {
  id: string;
  component: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponentsById>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getComponentsById>
      >["data"]
    >
  >;
}

export const ComponentGeneralInfo = ({
  id,
  component,
}: ComponentDetailsProps) => {
  const form = useForm<ComponentInfo>({
    resolver: yupResolver(componentFormSchema),
    defaultValues: componentFormDefaultValues,
  });

  const { reset, handleSubmit } = form;

  const { callService, loading } = useServiceCall(
    service.hubCreditManager.updateComponents,
  );

  const { value: componentValue } = component;

  useEffect(() => {
    if (!componentValue) return;

    reset({
      name: componentValue?.name,
      type: componentValue?.type,
      description: componentValue?.description,
    });
  }, [componentValue, reset]);

  const handleSubmitEditComponent = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await callService({
          ...values,
          id: Number(id),
          name: values.name,
          type: values.type,
          description: values.description,
        });

        if (success) {
          OToastManager.success("Componente editado com sucesso!");
        } else {
          OToastManager.danger(
            "Houve um problema ao tentar editar o componente. Por favor, tente novamente mais tarde.",
          );
        }
      }),
    [handleSubmit, id, callService],
  );

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-4">
        {loading && <OLoader absolute backdrop />}

        <OTypography tag="h2" weight="500" size="lg">
          Dados gerais
        </OTypography>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex gap-4">
            <ORFieldInput
              id="name"
              name="name"
              tag="text"
              label="Nome"
              required
              labelSize="md"
              dataAction="editar_componente:texto:nome"
              dataLabel="nome"
            />
            <ORFieldInput
              id="type"
              name="type"
              tag="text"
              label="Tipo"
              tooltip="Ex.=NOME_COMPONENTE"
              tooltipPosition="top-right"
              required
              labelSize="md"
              dataAction="editar_componente:texto:tipo"
              dataLabel="tipo"
            />
            <ORFieldInput
              id="description"
              name="description"
              tag="text"
              label="Descrição"
              required
              labelSize="md"
              dataAction="editar_componente:texto:descricao"
              dataLabel="descricao"
            />
          </div>
          <div className="d-flex justify-content-end">
            <OButton
              onClick={() => handleSubmitEditComponent()}
              dataAction="editar_componente:botao:salvar"
              dataLabel="salvar"
            >
              Salvar
            </OButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
