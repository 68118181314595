import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./transfers.grid";

interface TransfersProps {
  status: "SENT" | "SCHEDULED";
}

export const Transfers = ({ status }: TransfersProps) => {
  const gridRef = useGridRef();
  const dataSource = dataSourceCustomStoreGenerator<BankingHub.v2.Payment>(
    () => {
      const params = {
        quantity: 50,
        status,
      };
      return service.bankinghub
        .getTransfers(params)
        .then(({ data }) => data.items ?? [])
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar transferências";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
  );
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Transferências" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
