import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { BankingManagerCompany } from "services/admin-bankinghub/models";

export const bankingCompaniesGrid: ODataGridGeneratorConfig<BankingManagerCompany> =
  {
    datagrid: {
      noDataText: "Nenhuma empresa encontrada.",
    },
    columns: [
      {
        caption: "Nome",
        dataField: "name",
        dataType: "string",
      },
      {
        caption: "CNPJ/CPF",
        dataField: "document",
        dataType: "string",
        cellRender: ({ data }) => masks.cpfCnpj(data.document),
      },
      {
        caption: "N° da Conta",
        dataField: "relationships",
        cellRender: ({ data }) => data.relationships?.[0]?.account_number ?? "",
      },
      {
        caption: "Convênio",
        dataField: "relationships",
        cellRender: ({ data }) => data.relationships?.[0]?.covenant ?? "",
      },
      {
        caption: "Id Finnet",
        dataField: "relationships",
        cellRender: ({ data }) =>
          data.relationships?.[0]?.id_partner_finnet ?? "",
      },
      {
        caption: "Ativada em",
        dataField: "created_at",
        dataType: "date",
        format: "dd/MM/yyyy",
      },
    ],
  };
