import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { format } from "utils/date";
import { DataUpdateEditType } from "./atualizacao-cadastral-edit.type";

export type DataUpdate = NonNullable<
  PostAdminCompaniesGetByTaxIdResponse["dataUpdates"]
>[number];

export const useDataUpdateEdit = ({ uuid }: DataUpdateEditType) => {
  const navigate = useNavigate();

  const dataUpdateDetails = useServiceCall(
    service.onboarding.getDataUpdateDetails,
  );
  const dataUpdateEnums = useServiceCall(service.onboarding.getDataUpdateEnums);
  const dataUpdateChanges = useServiceCall(
    service.onboarding.getDataUpdateChanges,
  );

  const { callService, value: dataUpdate } = dataUpdateDetails;
  const { callService: getEnums } = dataUpdateEnums;
  const { callService: getChanges } = dataUpdateChanges;

  const [loading, setLoading] = useState(false);

  const form = useForm<DataUpdate>();
  const { reset, handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      if (!dataUpdate?.id) throw new Error("Missing id");
      setLoading(true);

      await service.onboarding.updateDataUpdateById(dataUpdate.id, {
        portalStatus: values.status,
      });

      OToastManager.success("Atualização cadastral editada com sucesso");

      navigate(
        corporateRouter.routes.cadastro.customer.details.dataUpdates.path,
      );

      OToastManager.success(
        "Aguarde alguns minutos para que a atualização seja feita",
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    callService(uuid);
    getEnums();
  }, [callService, getEnums, uuid]);

  useEffect(() => {
    if (!dataUpdate) return;

    const { portalStatus, createdDate, updatedDate } = dataUpdate;

    reset({
      status: portalStatus,
      insertedAt: format.date(createdDate),
      updatedAt: updatedDate && format.date(updatedDate),
    });
  }, [uuid, reset, dataUpdate]);

  useEffect(() => {
    dataUpdate?.id && getChanges({ dataUpdateId: dataUpdate.id });
  }, [dataUpdate?.id, getChanges]);

  return {
    form,
    submit,
    loading,
    dataUpdateDetails,
    dataUpdateEnums,
    dataUpdateChanges,
  };
};
