import {
  OButton,
  OInputNumber,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useStepUpdate } from "./step-update.hook";
import { StepUpdateFormProps } from "./step-update.types";

export const StepOrderUpdateModal = ({
  step,
  templateUuid,
}: StepUpdateFormProps) => {
  const { updateLoading, updateStepOrder, order, setOrder } = useStepUpdate(
    step,
    templateUuid,
  );

  return (
    <>
      {updateLoading && <OLoader absolute backdrop />}
      <OModal id="step-order-modal" size="md" position="center" rounded>
        <OModalHeader closeButton>
          <OTypography tag="h2" size="lg">
            Defina a nova posição do Step: <i>{step?.description}</i>
          </OTypography>
        </OModalHeader>
        <OModalBody>
          <OInputNumber
            id="order"
            name="order"
            value={order}
            dataAction="input_order_stepUpdate"
            dataLabel="input_order_stepUpdate"
            onKeyUp={(e) => setOrder(e.currentTarget.value)}
          />
        </OModalBody>
        <OModalFooter>
          <div className="w-100 d-flex justify-content-end gap-2">
            <OButton
              dataAction="modal_atualizar_step:botao:atualizarStep"
              dataLabel="atualizarStep"
              onClick={async () => {
                await updateStepOrder();
              }}
            >
              Atualizar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
    </>
  );
};
