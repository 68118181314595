import { ODropdownItem } from "@maestro/react";
import styled, { css } from "styled-components";
import { PageSizeDropdownItemProps } from "./page-sizes.types";

export const PageSizeDropdownItem = styled(
  ODropdownItem,
)<PageSizeDropdownItemProps>`
  ${({ active }) =>
    active &&
    css`
      background-color: var(--theme-tertiary) !important;
      color: var(--theme-light);
    `}
`;
