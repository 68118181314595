import { ODataGridGenerator } from "components/data-grid";
import type { Options } from "devextreme/data/data_source";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildContractsDataSource,
  buildContractsGrid,
} from "./contracts-grid.grid";

interface ContractsGridProps {
  filter: Options["filter"];
  isFullGrid: boolean;
  pageTitle?: React.ReactNode;
  actions?: React.ReactNode;
}

export const ContractsGrid = ({
  filter,
  isFullGrid,
  actions,
  pageTitle,
}: ContractsGridProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(() => buildContractsDataSource(filter), [filter]);
  const grid = useMemo(() => buildContractsGrid(isFullGrid), [isFullGrid]);

  return (
    <GridTemplate
      pageTitle={pageTitle}
      actions={actions}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
