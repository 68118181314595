import { RouterButton } from "components/router-button";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { useUser } from "contexts/user";
import { corporateRouter } from "routes/corporate-router.context";
import { buildCustomerLink } from "utils/build-customer-link";

interface GoToCustomerButtonProps {
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse | undefined;
}

export const GoToCustomerButton = ({
  consumerUnit,
}: GoToCustomerButtonProps) => {
  const { user } = useUser();
  const { behavior } = useBehavior();

  const customerTaxId = consumerUnit?.customer.taxId;

  if (!customerTaxId) return null;
  if (
    behavior.id === Behaviors.CustomerService &&
    !user.relatedEntities?.find(
      ({ identification }) => identification === customerTaxId,
    )
  )
    return null;

  return (
    <RouterButton
      href={buildCustomerLink(
        customerTaxId,
        corporateRouter.routes.energia.customer.path,
      )}
    >
      Ver matriz
    </RouterButton>
  );
};
