import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
    ODataGridGeneratorConfig,
    dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { GatekeeperUserBlockedUsers } from "./gatekeeper-blocked-user.types";
import { format } from "utils/date";

export const dataSourceGenerator = (
    identification: string,
) =>
    dataSourceCustomStoreGenerator<GatekeeperUserBlockedUsers>(
        (loadOptions) => {
            return service.gatekeeper
                .getLogs(loadOptions as any)
                .then(({ data }) => ({
                    data: data.data ?? [],
                    totalCount: data.total_count,
                }))
                .catch(() => {
                    const errorMessage = "Erro ao buscar logs";
                    OToastManager.danger(errorMessage);
                    throw new Error(errorMessage);
                });
        },
        {
            dataSourceOptions: identification === "" ? {} : {
                filter:
                    ["identification", "contains", identification]
            },
        },
    );

export const gatekeeperLogsGrid: ODataGridGeneratorConfig<GatekeeperUserBlockedUsers> = {
    datagrid: {
        noDataText: "Nenhum usuário",
        filterRow: { visible: true },
        remoteOperations: true,
        pager: {
            showPageSizeSelector: true,
        },
    },
    columns: [
        {
            dataField: "id",
            dataType: "number",
            caption: "ID",
            width: 150,
            alignment: 'center'
        },
        {
            dataField: "identification",
            dataType: "string",
            caption: "CPF",
            format: (value) => masks.cpf(value),
            calculateFilterExpression: calculateTaxIdFilterExpression,
            alignment: 'center'
        },
        {
            caption: "Inserido Em",
            dataField: "insertedAt",
            customizeText: ({ valueText }) => format.date(valueText),
            alignment: 'center'
        },
        {
            caption: "Entidade",
            dataField: "entity",
            dataType: "string",
            alignment: 'center'
        },
        {
            caption: "Detalhes",
            dataField: "data",
            dataType: "string",
            alignment: 'center'
        },
    ],
};
