import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import {
  configurableColumnsFormDefaultValues,
  configurableColumnsFormValidationSchema,
} from "../configurable-columns-form.schemas";
import { ConfigurableColumnsForm } from "../configurable-columns-form.component";

export const AddConfigurableColumnsPage = () => {
  const form = useForm({
    resolver: yupResolver(configurableColumnsFormValidationSchema),
    defaultValues: configurableColumnsFormDefaultValues,
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Colunas Configuráveis" />}
    >
      <FormProvider {...form}>
        <ConfigurableColumnsForm mode="add" />
      </FormProvider>
    </FormTemplate>
  );
};
