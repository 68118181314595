import { client } from "../client";
import {
  GetTaxasPreParams,
  PostTaxasConfigTaxaBody,
  PostTaxasPreBody,
} from "./models/requests";
import {
  GetTaxasByEstrategiaUuidByCurrencyResponse,
  GetTaxasConfigTaxaByEstrategiaUuidResponse,
  GetTaxasPreResponse,
} from "./models/responses";
import { sennaEndpoints } from "./senna.endpoints";

export const sennaService = {
  getTaxasByEstrategia(estrategiaUuid: string, currency: string) {
    return client.get<GetTaxasByEstrategiaUuidByCurrencyResponse>(
      sennaEndpoints.taxas.byEstrategiaUuid.byCurrency(
        estrategiaUuid,
        currency,
      ),
    );
  },
  getConfigTaxasByEstrategia(estrategiaUuid: string) {
    return client.get<GetTaxasConfigTaxaByEstrategiaUuidResponse>(
      sennaEndpoints.taxas.configTaxa.byEstrategiaUuid.endpoint(
        estrategiaUuid,
      ),
    );
  },
  setConfigTaxas(body: PostTaxasConfigTaxaBody) {
    return client.post(sennaEndpoints.taxas.configTaxa.endpoint, body);
  },
  removeConfigTaxa(estrategiaUuid: string, currency: string) {
    return client.delete(
      sennaEndpoints.taxas.configTaxa.byEstrategiaUuid.byCurrency(
        estrategiaUuid,
        currency,
      ),
    );
  },
  removeTaxaPrazo(taxaId: string | number) {
    return client.delete(sennaEndpoints.taxas.taxaPrazo.byTaxaId(taxaId));
  },
  getTaxasPre(params?: GetTaxasPreParams) {
    return client.get<GetTaxasPreResponse>(sennaEndpoints.taxas.pre, {
      params,
    });
  },
  setTaxasPre(body: PostTaxasPreBody) {
    return client.post(sennaEndpoints.taxas.pre, body);
  },
};
