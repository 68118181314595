import * as yup from "yup";

const profileRegex = /\w+:\w+(-\w+)?\.\w+/i;
const roleRegex = /\w+:\w+\.\w+/i;

export const profileManagementTypeMap = {
  NEW_ROLE: "Criação de role",
  NEW_PROFILE: "Criação de profile",
  ADD_ROLES: "Associação de roles",
  REMOVE_ROLES: "Remoção de roles",
  ADD_SUB_PROFILES: "Associação de sub-perfis",
  REMOVE_SUB_PROFILES: "Remoção de sub-perfis",
};

export interface ProfilesFormValues {
  changes: {
    description: string;
    name: string;
    label: string;
    profileName: string;
    roleNames: string[];
    subProfileNames: string[];
  }[];
  type: keyof typeof profileManagementTypeMap;
}

const profileChangeNewRoleValidationSchema = yup.object({
  description: yup.string().required("Campo obrigatório"),
  name: yup
    .string()
    .required("Campo obrigatório")
    .test("format", "Está fora do padrão [serviço]:[recurso].[ação]", (value) =>
      roleRegex.test(value),
    ),
});
const profileChangeNewProfileValidationSchema = yup.object({
  description: yup.string().required("Campo obrigatório"),
  name: yup
    .string()
    .required("Campo obrigatório")
    .test(
      "format",
      "Está fora do padrão [portal ou serviço]:[produto ou recurso]-[categoria(opcional)].[perfil]",
      (value) => profileRegex.test(value),
    ),
  label: yup.string().required("Campo obrigatório"),
});
const profileChangeAddRolesValidationSchema = yup.object({
  profileName: yup.string().required("Campo obrigatório"),
  roleNames: yup.array().min(1, "Campo obrigatório"),
});
const profileChangeRemoveRolesValidationSchema = yup.object({
  profileName: yup.string().required("Campo obrigatório"),
  roleNames: yup.array().min(1, "Campo obrigatório"),
});
const profileChangeAddSubProfilesValidationSchema = yup.object({
  profileName: yup.string().required("Campo obrigatório"),
  subProfileNames: yup.array().min(1, "Campo obrigatório"),
});
const profileChangeRemoveSubProfilesValidationSchema = yup.object({
  profileName: yup.string().required("Campo obrigatório"),
  subProfileNames: yup.array().min(1, "Campo obrigatório"),
});

export const profileChangeFormValidationSchemaBuilder = (type: string) =>
  ({
    NEW_ROLE: profileChangeNewRoleValidationSchema,
    NEW_PROFILE: profileChangeNewProfileValidationSchema,
    ADD_ROLES: profileChangeAddRolesValidationSchema,
    REMOVE_ROLES: profileChangeRemoveRolesValidationSchema,
    ADD_SUB_PROFILES: profileChangeAddSubProfilesValidationSchema,
    REMOVE_SUB_PROFILES: profileChangeRemoveSubProfilesValidationSchema,
  }[type] ?? yup.object({}));

export const profileFormValidationSchema = yup.object().when(([value]) => {
  return yup.object({
    changes: yup
      .array()
      .of(profileChangeFormValidationSchemaBuilder(value?.type)),
  });
});
