import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const RequiresPMEAccountField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.requiresPMEAccount}
      name="requiresPMEAccount"
      description="Necessidade de abertura de conta do fornecedor para liquidação das operações"
    />
  );
};
