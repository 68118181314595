import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { service } from "services";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";

export const useVerifyReceivables = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationResults, setValidationResults] =
    useState<PostCompaniesByIdBorderosVerifyErrorResponse>();

  const verifyReceivables = useCallback(
    async (
      bypassValidation: boolean,
      ...args: Parameters<typeof service.quickfin.verifyReceivables>
    ) => {
      try {
        if (bypassValidation) {
          setValidationResults([]);
          setHasError(false);
        } else {
          setLoading(true);

          await service.quickfin.verifyReceivables(...args);

          setValidationResults([]);
          setHasError(false);
        }
      } catch (err) {
        let _hasError = true;
        if (
          isAxiosError<PostCompaniesByIdBorderosVerifyErrorResponse>(err) &&
          err.response?.status === 400 &&
          err.response.data
        ) {
          _hasError = false;
          setValidationResults(err.response?.data);
        }
        setHasError(_hasError);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const clear = useCallback(() => {
    setLoading(false);
    setHasError(false);
    setValidationResults(undefined);
  }, []);

  return {
    clear,
    hasError,
    loading,
    validationResults,
    verifyReceivables,
  };
};
