import { OToastManager } from "@maestro/core";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { Workflow, WorkflowDetail } from "./new-accounts-optin.type";

export const newAccountOptinMasterDetailGrid: ODataGridGeneratorConfig<WorkflowDetail> =
  {
    datagrid: {
      noDataText: "Nenhuma conta encontrada",
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "officer",
        caption: "Officer",
      },
      {
        dataField: "agency",
        caption: "Agência",
      },
      {
        dataField: "number",
        caption: "Nº da Conta",
      },
      {
        dataField: "segment",
        caption: "Segmento",
      },
      {
        dataField: "coSegment",
        caption: "Cosegmento",
      },
      {
        dataField: "status",
        caption: "Status",
      },
    ],
  };

type NewAccountOptinMasterDetailProps = MasterDetailComponentProps<Workflow>;

export const NewAccountOptinMasterDetail = ({
  data: {
    data: { uuid },
  },
}: NewAccountOptinMasterDetailProps) => {
  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<WorkflowDetail>(() =>
        service.onboardingCoreAccount
          .getDetailedWorkflow({ uuid })
          .then(({ data }) => data.accounts)
          .catch(() => {
            const errorMessage =
              "Não foi possível carregar as solicitações de cração de contas Optin feitas";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [uuid],
  );

  return (
    <ODataGridGenerator
      grid={newAccountOptinMasterDetailGrid}
      dataSource={dataSource}
    />
  );
};
