import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";

export const customerServiceTermsGrid: ODataGridGeneratorConfig<
  NonNullable<PostAdminCompaniesGetByTaxIdResponse["contracts"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhum termo",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "type", caption: "Tipo" },
    { dataField: "uuid", caption: "UUID", allowHeaderFiltering: false },
    { dataField: "status", caption: "Status" },
    {
      dataField: "insertedAt",
      caption: "Data de criação",
      allowHeaderFiltering: false,
      format: "shortDateShortTime",
    },
    {
      dataField: "updatedAt",
      caption: "Última atualização",
      allowHeaderFiltering: false,
      format: "shortDateShortTime",
    },
  ],
};
