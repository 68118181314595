import { OInputCurrency, OLabel, OSwitch } from "@maestro/react";
import { masks } from "@maestro/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OInputRightCurrency } from "./interval-amount.styles";
import {
  IntervalAmountProps,
  OInputCurrencyId,
  OInputCurrencyLabel,
} from "./interval-amount.types";

export const IntervalAmount = ({ formId }: IntervalAmountProps) => {
  const [isRangeActive, setIsRangeActive] = useState(true);
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const [min, max] = watch(formId) || [];

  const isInvalidMaxValue = useMemo(() => {
    if (!isRangeActive) {
      return false;
    }

    return max < min;
  }, [max, min, isRangeActive]);

  useEffect(() => {
    if (isInvalidMaxValue)
      setError(formId, {
        message: "O valor máximo não pode ser superior ao mínimo.",
      });
    else clearErrors(formId);
  }, [isInvalidMaxValue, formId, setError, clearErrors]);

  const handleInput = useCallback(() => {
    setIsRangeActive(!isRangeActive);
    clearErrors(formId);
    setValue(formId, [0, 0]);
  }, [setIsRangeActive, isRangeActive, clearErrors, setValue, formId]);

  const handleChange = useCallback(
    (value: string, fieldId: string) => {
      if (fieldId === OInputCurrencyId.MIN && !isRangeActive) {
        setValue(formId, [Number.parseFloat(value), Number.parseFloat(value)]);
        return;
      }

      setValue(
        formId,
        fieldId === OInputCurrencyId.MIN
          ? [Number.parseFloat(value), max ?? 0]
          : [min ?? 0, Number.parseFloat(value)],
      );
    },
    [formId, min, max, isRangeActive, setValue],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex align-items-center justify-content-between">
        <OLabel htmlFor="range-active" type="dark-80">
          Intervalo
        </OLabel>
        <OSwitch
          id="range-active"
          size="xs"
          value={isRangeActive}
          onInput={handleInput}
        />
      </div>
      <div className="d-flex justify-content-between gap-5">
        <div className="flex-fill d-flex flex-column gap-2">
          <OInputCurrency
            id={OInputCurrencyId.MIN}
            name={OInputCurrencyId.MIN}
            placeholder={masks.currency(min, "R$", ",")}
            value={min}
            symbol="R$"
            onChangeMasked={(e) => {
              const amount = e.detail.unmaskedValue;
              handleChange(amount, OInputCurrencyId.MIN);
            }}
          />
          {isRangeActive && (
            <OLabel size="sm" htmlFor={OInputCurrencyId.MAX} type="dark-80">
              {OInputCurrencyLabel.MIN}
            </OLabel>
          )}
        </div>
        {isRangeActive && (
          <div className="flex-fill d-flex flex-column align-items-end gap-2">
            <OInputRightCurrency
              id={OInputCurrencyId.MAX}
              name={OInputCurrencyId.MAX}
              placeholder={masks.currency(max, "R$", ",")}
              value={max}
              symbol="R$"
              onChangeMasked={(e) => {
                const amount = e.detail.unmaskedValue;
                handleChange(amount, OInputCurrencyId.MAX);
              }}
              error={isInvalidMaxValue}
            />
            <OLabel size="sm" htmlFor={OInputCurrencyId.MAX} type="dark-80">
              {OInputCurrencyLabel.MAX}
            </OLabel>
          </div>
        )}
      </div>
    </div>
  );
};
