import { modalManager, OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  dataSource,
  diligenceStepMonitorGrid,
} from "./diligence-steps-monitor.grid";
import {
  BatchStepStatusModal,
  batchStepStatusModalId,
  UpdateStepWithConfigModal,
  updateStepWithConfigModalId,
} from "./_compose";

export const DiligenceStepsMonitorPage = () => {
  const [selectedSteps, setSelectedSteps] = useState<
    HubEnergy.DiligenceStepMonitor[]
  >([]);
  const gridRef = useGridRef();

  const grid = useMemo(
    () => diligenceStepMonitorGrid(setSelectedSteps),
    [setSelectedSteps],
  );

  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Steps de migração" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
        actions={
          <>
            <OButton
              disabled={!selectedSteps?.length}
              onClick={() => modalManager.show(updateStepWithConfigModalId)}
            >
              Atualizar steps com configurações
            </OButton>
            <OButton
              disabled={!selectedSteps?.length}
              onClick={() => modalManager.show(batchStepStatusModalId)}
            >
              Editar status
            </OButton>
          </>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <BatchStepStatusModal selectedSteps={selectedSteps} gridRef={gridRef} />
      <UpdateStepWithConfigModal selectedSteps={selectedSteps} />
    </>
  );
};
