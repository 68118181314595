export const dummyAction: HubEnergy.DiligenceEventActionSimpleResponse = {
  id: 0,
  createdDate: "",
  updatedDate: "",
  actionType: "CHANGE_PENDENCY_STATUS",
  targetStepTypes: [],
  targetStepStatuses: [],
  targetPendencyTypes: [],
  targetPendencyStatuses: [],
  targetSamePendency: false,
  targetSameStep: false,
  payload: "",
  isEnabled: true,
  ruleId: 0,
};
