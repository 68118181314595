import { OTypography } from "@maestro/react";
import { modalManager } from "@maestro/core";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { NextStepButton } from "components/steps-builder";
import { IconButton } from "components/icon-button";
import type DataGrid from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildOperationDetails,
  uncapitalizeObjectKeys,
} from "../../../../../utils";
import { ClearChangesButton, ClearSelectionButton } from "./_compose";
import { QuoteListProvider, useQuoteList } from "./quote-list.context";
import { buildQuoteListGrid } from "./quote-list.grid";
import { SearchModal, searchModalId } from "./_compose/search-modal";

const QuoteListStepComponent = () => {
  const gridRef = useRef<DataGrid>(null);

  const {
    disbursementAmounts,
    requestedAmounts,
    selectedRows,
    setSelectedRows,
    updateParentContext,
    dataSource,
  } = useQuoteList();

  const quoteListGrid = useMemo(
    () => buildQuoteListGrid(setSelectedRows),
    [setSelectedRows],
  );

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <PageTitle
          title="Lista de recebíveis de cartão disponíveis para cedente"
          description='Caso deseje realizar um desembolso parcial, preencha a coluna "Valor Solicitado" (opcional). Quando a opção "Selecionar tudo" é ativada, os registros de todas as páginas são selecionados (apenas são considerados aqueles que atendem aos filtros aplicados).'
        />
        <DetailsCard
          emptyStateComponent={
            <OTypography>Selecione os recebíveis na coluna abaixo</OTypography>
          }
          value={selectedRows.map(uncapitalizeObjectKeys)}
          fields={buildOperationDetails(
            requestedAmounts,
            disbursementAmounts,
            {},
            selectedRows.map(({ CedenteIdentification }) => CedenteIdentification)
          )}
        />
        <GridTemplate
          actions={
            <>
              <ClearChangesButton />
              <ClearSelectionButton gridRef={gridRef} />
              <IconButton
                dataAction="entuba_sugestao:botao:abrir_busca"
                dataLabel="abrir_busca"
                icon={{ category: "far", icon: "fa-search" }}
                onClick={() => modalManager.show(searchModalId)}
              >
                Abrir busca
              </IconButton>
            </>
          }
          gridRef={gridRef}
          showRefreshButton
          showExportButton
          showClearFiltersButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={quoteListGrid}
            dataSource={dataSource}
          />
        </GridTemplate>
        <div className="d-flex justify-content-end gap-3 mt-4 w-100">
          <NextStepButton
            disabled={selectedRows.length < 1}
            onClick={() => updateParentContext()}
          />
        </div>
      </div>
      <SearchModal />
    </>
  );
};

export const QuoteListStep = () => (
  <QuoteListProvider>
    <QuoteListStepComponent />
  </QuoteListProvider>
);
