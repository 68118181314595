import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  closingReasonDefaultValues,
  closingReasonValidationSchema,
} from "./closing-reason.form";
import { ClosingReasonForm } from "./closing-reason.types";

export const useClose = (ids: number[]) => {
  const {
    customer: { identification },
  } = useCustomer();

  const { callService: closeAccounts, loading } = useServiceCall(
    service.onboardingCoreAccount.closeAccounts,
  );

  const navigate = useNavigate();

  const form = useForm<ClosingReasonForm>({
    defaultValues: closingReasonDefaultValues,
    resolver: yupResolver(closingReasonValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await closeAccounts({
          companyTaxId: identification,
          ids,
          reason: values.reasons,
          otherReason: values.otherReasons,
        });

        if (success) {
          OToastManager.success(
            "Solicitação de encerramento criada com sucesso",
          );
          navigate(corporateRouter.routes.cadastro.customer.accountClose.path);
        } else {
          OToastManager.danger(
            "Não foi possível criar a solicitação de encerramento",
          );
        }
      }),
    [closeAccounts, handleSubmit, identification, ids, navigate],
  );

  return {
    form,
    submit,
    loading,
  };
};
