import { WorkflowConfigViews } from "services/hubcreditmanager/models/responses/get-admin-processor-config-monitoring.response";

export const getOutputComponentsIds = (
  view: WorkflowConfigViews,
  outputId: string | number,
) => ({
  key: `key-${view.workflowConfigViewId}-${outputId}`,
  type: `type-${view.workflowConfigViewId}-${outputId}`,
  status: `status-${view.workflowConfigViewId}-${outputId}`,
  bigNumber: `numbers-${view.workflowConfigViewId}-${outputId}`,
});
