import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OConfirmationModal } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useState } from "react";
import { service } from "services";

export const SincronizeAccessWithPowers = () => {
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);

      await service.onboardingRelationships.runContingency(
        {
          identifier: "sync_profiles_with_iris_powers",
          data: {
            taxId: customer.identification,
          },
        },
      );

      OToastManager.info(
        "Os Usuários serão sincronizados em até cinco minutos!",
      );

      modalManager.hide("sincronize-access-with-powers-modal");
    } catch (err) {
      OToastManager.danger("Ocorreu erro na sincronização");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <OButton
        dataAction="ferramentas:botao:sincronizar"
        dataLabel="sincronizar"
        onClick={() => modalManager.show("sincronize-access-with-powers-modal")}
      >
        Sincronizar
      </OButton>

      <OConfirmationModal
        modalId="sincronize-access-with-powers-modal"
        size="sm"
        title="Sincronizar acessos no Portal Empresas com a IRIS ?"
        closeLabel="Cancelar"
        confirmLabel="Confirmar"
        onConfirm={submit}
        loading={loading}
      />
    </>
  );
};
