import * as yup from "yup";
import { FormValuesType } from "./details.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  name: yup.string().required("Este campo é obrigatorio"),
  id: yup.number().required("Este campo é obrigatorio"),
  hmac: yup.string().required("Este campo é obrigatorio"),
  webhook: yup.string().nullable(),
  profiles: yup.array(),
});
