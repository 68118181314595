import {
  OBadge,
  OCol,
  OField,
  OIcon,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { remapHookFormRegister } from "../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useDesks } from "./desks.hook";

export const Desks = () => {
  const {
    allDesks,
    form,
    loading,
    localForm,
    watchDesks,
    watchInput,
    setValue,
    submit,
  } = useDesks();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Mesas"
          description="Gestão de mesas de uma entidade"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="mesas:botao:salvar"
          dataLabel="salvar"
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="cpfcnpj"
                  id="taxId"
                  name="taxId"
                  dataAction="mesas:input:cnpj-cpf"
                  dataLabel="cnpj-cpf"
                  label="CNPJ / CPF"
                />
              </OCol>
            </ORow>
            <ORow align="start">
              <OCol xs={12} sm={6} lg={4}>
                <OField label="Estratégias" htmlFor="input">
                  <SelectSearch
                    id="input"
                    name="input"
                    placeholder="Selecionar"
                    options={allDesks}
                    value={watchInput}
                    className="w-100"
                    maxOptions={50}
                    innerProps={{
                      input: {
                        ...remapHookFormRegister(localForm.register("input")),
                      },
                    }}
                  />
                </OField>
              </OCol>

              {!!watchDesks?.length && (
                <OCol
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-column gap-4"
                >
                  <OTypography tag="h3">Selecionados:</OTypography>

                  <div className="d-flex flex-wrap gap-2">
                    {watchDesks.map((desk) => {
                      const findDesk = allDesks.find(
                        (all) => all.value === desk,
                      );

                      if (!findDesk?.label) return;

                      return (
                        <OBadge
                          key={desk}
                          type="dark"
                          size="sm"
                          rounded
                          outline
                        >
                          <div className="d-flex align-items-center gap-2">
                            {findDesk.label}
                            <UnstyledButton
                              onClick={() => {
                                const filteredDesks = watchDesks.filter(
                                  (d) => d !== desk,
                                );

                                setValue("desks", filteredDesks);
                              }}
                            >
                              <OIcon category="fal" icon="fa-times" />
                            </UnstyledButton>
                          </div>
                        </OBadge>
                      );
                    })}
                  </div>
                </OCol>
              )}
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
