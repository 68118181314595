import {
  OButton,
  OIcon,
  OLoader,
  ORFieldUploadInput,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { AxiosError } from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { client } from "../../../../../../../services/client";
import { endpoints } from "../../../utils/endpoints";
import { RowSpacedContainer, SubsectionContainer } from "../../cards.styles";
import {
  CreateFormData,
  MessageFromResponseErrorData,
} from "../utils/chargeback";
import { ChargeBackContainer } from "./chargeback.styles";
import * as ChargeBackTypes from "./chargeback.types";
import { ICheckboxResponse } from "./compose/compose.types";
import ChargeBackTreeJson from "./compose/files/chargeback.json";
import FraudTreeJson from "./compose/files/fraud.json";
import InitialTreeJson from "./compose/files/initial.json";
import { QuestionButton } from "./compose/question-button.component";
import { QuestionCheckbox } from "./compose/question-checkbox.component";
import { QuestionHtml } from "./compose/question-html.component";
import { QuestionText } from "./compose/question-text.component";
import { CheckboxLinksFromCheckedQuestionText } from "./compose/utils";

export const TransactionChargeBack = ({
  chargebackTransactionItemData,
  finishRequestContestation,
  selectedCompany,
  salesforceTokenInfo,
}: ChargeBackTypes.ITransactionChargeBackProps) => {
  const [isUploading, setUploading] = useState(false);
  const [activeTree, setActiveTree] =
    useState<ChargeBackTypes.TreeName>("initialTree");
  const [chargeBackQuestionsData, setChargeBackQuestionsData] = useState<
    | Array<
        | ChargeBackTypes.IInitialNode
        | ChargeBackTypes.IButtonMiddleNode
        | ChargeBackTypes.ITextMiddleNode
        | ChargeBackTypes.ICheckboxMiddleNode
      >
    | undefined
  >(undefined);
  const [chargeBackResponsesData] = useState<Map<string, Array<unknown>>>(
    new Map(),
  );
  const [activeQuestionData, setActiveQuestionData] = useState<
    ChargeBackTypes.IActiveQuestionData | undefined
  >(undefined);
  const [uploadFilesData, setUploadFilesData] = useState<Array<File>>([]);
  const ChargeBackContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoActiveQuestion = useCallback(() => {
    const lastQuestionElement =
      ChargeBackContainerRef.current?.lastElementChild;
    lastQuestionElement?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "center",
    });
  }, []);

  useEffect(() => {
    if (!chargeBackQuestionsData) {
      setActiveTree("initialTree");
      const initialData: Array<ChargeBackTypes.IInitialNode> = [
        {
          ...InitialTreeJson.nodes[0],
          response: [],
        } as ChargeBackTypes.IInitialNode,
      ];
      setChargeBackQuestionsData(initialData);
      setActiveQuestionData({ index: 0, data: initialData[0] });
    }
    setTimeout(() => scrollIntoActiveQuestion(), 500);
  }, [chargeBackQuestionsData, scrollIntoActiveQuestion]);

  const loadResponse = useCallback(
    (nodeName: string): Array<unknown> | undefined => {
      if (chargeBackResponsesData.has(nodeName))
        return chargeBackResponsesData.get(nodeName);
    },
    [chargeBackResponsesData],
  );

  const registerResponse = useCallback(
    (response: Array<unknown>, nodeName: string) => {
      chargeBackResponsesData.set(nodeName, response);
    },
    [chargeBackResponsesData],
  );

  const loadANode = useCallback(
    async (
      link: string | ChargeBackTypes.ILinkHeaded,
      newChargeBackQuestionsData: Array<
        | ChargeBackTypes.IInitialNode
        | ChargeBackTypes.IButtonMiddleNode
        | ChargeBackTypes.ITextMiddleNode
        | ChargeBackTypes.ICheckboxMiddleNode
      >,
    ) => {
      let referenceTree: string;
      let referenceLink: string;
      let jsonData;
      if (typeof link === "string") {
        referenceTree = activeTree;
        referenceLink = link;
      } else {
        referenceTree = link.treeName;
        referenceLink = link.nodeAlias;
        setActiveTree(referenceTree as ChargeBackTypes.TreeName);
      }

      if (referenceTree === "initialTree") jsonData = InitialTreeJson;
      else if (referenceTree === "chargeBackTree")
        jsonData = ChargeBackTreeJson;
      else jsonData = FraudTreeJson;

      const newQuestion = await (
        jsonData.nodes as Array<
          | ChargeBackTypes.IInitialNode
          | ChargeBackTypes.IButtonMiddleNode
          | ChargeBackTypes.ITextMiddleNode
          | ChargeBackTypes.ICheckboxMiddleNode
        >
      ).find(
        (
          node:
            | ChargeBackTypes.IInitialNode
            | ChargeBackTypes.IButtonMiddleNode
            | ChargeBackTypes.ITextMiddleNode
            | ChargeBackTypes.ICheckboxMiddleNode,
        ) => node.nodeAlias === referenceLink,
      );

      if (newChargeBackQuestionsData && newQuestion) {
        const response = loadResponse(newQuestion?.nodeName);
        newQuestion.response = response ?? [];
        newChargeBackQuestionsData.push(newQuestion);
        await setChargeBackQuestionsData(newChargeBackQuestionsData);
        await setActiveQuestionData({
          index: newChargeBackQuestionsData.length - 1,
          data: newQuestion,
        });
      }
    },
    [activeTree, loadResponse],
  );

  const pushDataAndReturn = async (response: Array<unknown>, index: number) => {
    if (!chargeBackQuestionsData) return [];
    const newChargeBackQuestionsData = [...chargeBackQuestionsData];
    registerResponse(response, chargeBackQuestionsData[index].nodeName);
    newChargeBackQuestionsData[index] = {
      ...newChargeBackQuestionsData[index],
      response: response as Array<Record<string, string>>,
    };
    return newChargeBackQuestionsData;
  };

  const registerAnswer = async (
    data:
      | ChargeBackTypes.IHeadedButtonIteration
      | ChargeBackTypes.IMiddleButtonIteration,
    index: number,
  ) => {
    const newChargeBackQuestionsData = await pushDataAndReturn([data], index);
    await loadANode(data.link, newChargeBackQuestionsData);
  };

  const getSalesforceUsersInfo = useCallback(async () => {
    try {
      const { data } = await client.get(
        endpoints.clerk.salesforce.getSalesforceUser,
      );
      return data as ChargeBackTypes.IUsersInfo;
    } catch (error) {
      OToastManager.danger("Erro ao carregar dados de Usuários");
    }
  }, []);

  const executeAction = async (
    id: "btnBack" | "btnAdvance" | "btnFinish",
    data: {
      response: Array<unknown>;
      index: number;
      link: string;
    },
  ) => {
    try {
      if (id === "btnBack" && chargeBackQuestionsData) {
        const newChargeBackQuestionsData = [...chargeBackQuestionsData];
        newChargeBackQuestionsData.pop();
        await setChargeBackQuestionsData(newChargeBackQuestionsData);
        const activeIndex = newChargeBackQuestionsData.length - 1;
        await setActiveQuestionData({
          index: activeIndex,
          data: newChargeBackQuestionsData[activeIndex],
        });
      } else if (id === "btnAdvance" && data) {
        const newChargeBackQuestionsData = await pushDataAndReturn(
          data.response,
          data.index,
        );
        await loadANode(data.link, newChargeBackQuestionsData);
      } else if (chargebackTransactionItemData && chargeBackQuestionsData) {
        const { dhOpenTicket, numberTicket } = salesforceTokenInfo;
        const ticketDt = moment(dhOpenTicket, "YYYY/MM/DD HH:mm")
          .format("YYYY-MM-DDTHH:mm:ss")
          .toString();
        const usersInfoData = await getSalesforceUsersInfo();
        if (!usersInfoData) return;
        const bodyFormData = await CreateFormData(
          usersInfoData.attendant.email,
          usersInfoData.attendant.name,
          numberTicket || "",
          ticketDt,
          selectedCompany,
          chargebackTransactionItemData.creditAccountId,
          chargebackTransactionItemData.authorizationId,
          chargebackTransactionItemData.description,
          JSON.stringify(chargeBackQuestionsData),
          uploadFilesData,
        );
        try {
          if (!selectedCompany) return;
          setUploading(true);
          const { status } = await client.post(
            endpoints.bankinghub.postContestations,
            bodyFormData,
            {
              headers: {
                "content-type": "multipart/form-data",
                "x-identification": selectedCompany,
              },
            },
          );
          if ([200, 201, 202].includes(status)) {
            OToastManager.success("Dados enviados com sucesso");
            finishRequestContestation();
          }
        } catch (error) {
          const { response } = error as AxiosError;
          if (response && response.status === 422) {
            OToastManager.warning(
              MessageFromResponseErrorData(response.data as any),
            );
          } else {
            OToastManager.danger("Erro ao enviar os dados");
          }
        } finally {
          setUploading(false);
        }
      }
    } catch (error) {
      OToastManager.danger("Erro interno na árvore.");
    }
  };

  const form = useForm();
  const { setError, setValue } = form;

  const onAddFile = useCallback(
    async (file: File) => {
      const isOnSize = file.size / 1024 / 1024 < 2.5;
      if (!isOnSize) {
        setError("file", { message: "Tamanho de arquivo excede 2.5MB." });
        return;
      }

      setUploadFilesData((files) => [...files, file]);
    },
    [setError],
  );

  const onRemoveFile = useCallback(async (file: File) => {
    setUploadFilesData((files) =>
      files.filter((uploadFile) => uploadFile.name !== file.name),
    );
  }, []);

  useEffect(() => {
    setValue("file", uploadFilesData);
  }, [uploadFilesData, setValue]);

  return (
    <>
      {isUploading && <OLoader absolute backdrop />}
      <SubsectionContainer>
        <RowSpacedContainer>
          <OTypography size="md">Contestação</OTypography>
          <OButton
            className="icon-button"
            outline
            bordered={false}
            dataAction="contestacao:botao:fechar-detalhes-transacao"
            dataLabel="fechar-detalhes-transacao"
            title="Fechar detalhes da transação"
            onClick={() => {
              setChargeBackQuestionsData(undefined);
              setActiveQuestionData(undefined);
              finishRequestContestation();
            }}
          >
            <OIcon category="fal" icon="fa-times-circle" />
          </OButton>
        </RowSpacedContainer>
        {chargebackTransactionItemData &&
          chargeBackQuestionsData &&
          activeQuestionData && (
            <ChargeBackContainer ref={ChargeBackContainerRef}>
              {(activeQuestionData.data.responseType === "button" && (
                <QuestionButton
                  questionIndex={activeQuestionData.index}
                  data={activeQuestionData.data}
                  registerAnswer={registerAnswer}
                  executeAction={executeAction}
                  isEditable
                />
              )) ||
                (activeQuestionData.data.responseType === "text" && (
                  <QuestionText
                    key={activeQuestionData.index}
                    questionIndex={activeQuestionData.index}
                    data={activeQuestionData.data}
                    maximumValue={chargebackTransactionItemData.amount}
                    executeAction={executeAction}
                    isEditable
                    inputLinksChecked={
                      chargeBackQuestionsData[activeQuestionData.index - 1]
                        .responseType === "checkbox"
                        ? CheckboxLinksFromCheckedQuestionText(
                            chargeBackQuestionsData[
                              activeQuestionData.index - 1
                            ].response as Array<ICheckboxResponse>,
                          )
                        : undefined
                    }
                  />
                )) ||
                (activeQuestionData.data.responseType === "checkbox" && (
                  <QuestionCheckbox
                    questionIndex={activeQuestionData.index}
                    data={activeQuestionData.data}
                    executeAction={executeAction}
                    isEditable
                  />
                )) ||
                (activeQuestionData.data.responseType === "html" && (
                  <QuestionHtml
                    questionIndex={activeQuestionData.index}
                    data={activeQuestionData.data}
                    executeAction={executeAction}
                    isEditable
                  />
                ))}

              <FormProvider {...form}>
                <ORFieldUploadInput
                  dataAction="chargeback:upload:named_event"
                  dataLabel="named_event"
                  inputLabel="Clique ou arraste o arquivo para esta área para fazer upload."
                  tip="Arquivos em formato: PDF, JPEG e JPG"
                  description="Quantidade máxima de arquivos: 10. Tamanho máximo por arquivo: 2.5MB."
                  accept=".pdf,.jpeg,.jpg,.png"
                  id="file"
                  name="file"
                  value={uploadFilesData}
                  disabled={uploadFilesData.length >= 10}
                  handleRemove={(file) => onRemoveFile(file)}
                  handleAddFile={(event) => {
                    if (uploadFilesData.length >= 10) {
                      OToastManager.warning("Máximo de 10 arquivos!");
                      return;
                    }

                    onAddFile(event.detail);
                  }}
                />
              </FormProvider>
            </ChargeBackContainer>
          )}
      </SubsectionContainer>
    </>
  );
};
