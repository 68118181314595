import { OButton, ORFieldUploadInput, OTypography } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { useLoadFileConciliation } from "./load-file-conciliation.hook";
import { FundSelect } from "../../../../components";

export const LoadFileConciliation = () => {
  const {
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    reset,
    setHasParseError,
    selectedFundWatch,
  } = useLoadFileConciliation();

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle title="Upload arquivo de liquidação por repasse" />
        <FundSelect />

        {selectedFundWatch && (
          <DetailsTemplate pageTitle={<PageTitle title="Upload de arquivo" />}>
            <ContentTemplate
              loading={readerLoading || apiLoading}
              hasError={hasParseError || !!readerError}
              noValue
              errorComponent={
                <ErrorComponent
                  messageTitle="Erro ao processar a planilha"
                  messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
                >
                  <TryAgainButton
                    onClick={() => {
                      setHasParseError(false);
                      reset();
                    }}
                  />
                </ErrorComponent>
              }
              render={() => (
                <div>
                  <div>
                    <ORFieldUploadInput
                      id="quote"
                      name="quote"
                      inputLabel="Clique ou arraste o arquivo aqui"
                      accept=".csv"
                      dataAction="elegibility:upload:elegibility"
                      dataLabel="elegibility"
                      multiple={false}
                      handleAddFile={handleAddFile}
                      value={[]}
                    />
                  </div>

                  <div className="d-flex flex-column align-items-center gap-3 mt-4">
                    <a
                      download
                      href="/files/modelo_credit_conciliation_input.csv"
                    >
                      <OButton
                        rounded
                        type="default"
                        style={{ height: "min-content" }}
                      >
                        Baixar Modelo
                      </OButton>
                    </a>
                    <OTypography type="default">
                      Carregar arquivo de liquidação por repasse, para validação
                      e liquidação no CROS.
                    </OTypography>
                  </div>
                </div>
              )}
            />
          </DetailsTemplate>
        )}
      </div>
    </FormProvider>
  );
};
