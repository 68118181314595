import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

type CustomerOriginator = Awaited<
  ReturnType<typeof service.hubCreditManager.getCustomerOriginator>
>["data"][number];

export const resalesGrid = {
  datagrid: {
    noDataText: "Nenhuma empresa encontrada",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getCustomerOriginator().catch(() => {
        throw new Error("Não foi possível buscar os Parceiros/Revendas.");
      }),
    ),
    headerFilter: { visible: true },
    filterRow: { visible: true },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "identification",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "state",
      caption: "Estado",
    },
    {
      dataField: "city",
      caption: "Cidade",
    },
  ],
} satisfies ODataGridGeneratorConfig<CustomerOriginator>;
