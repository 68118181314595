import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { useAgentImpersonate } from "./agent-impersonate.hook";

export const useStepAnalysisSummary = () => {
  const {
    callService: getSummary,
    error,
    hasError,
    loading,
    value,
  } = useServiceCall(service.hubEnergy.getStepAnalysisSummary);

  const { agentWatch, allowAgentImpersonation, form, searchParams } =
    useAgentImpersonate();

  useEffect(() => {
    const params =
      allowAgentImpersonation && agentWatch
        ? { impersonatedAgentTaxId: agentWatch }
        : undefined;

    getSummary(params);
  }, [agentWatch, allowAgentImpersonation, getSummary]);

  return {
    error,
    getSummary,
    hasError,
    loading,
    value,
    allowAgentImpersonation,
    form,
    searchParams,
  };
};
