import { client } from "../client";
import {
  DeleteSignatureRuleByRuleIdGroupOfSignaturesByGroupIdBody,
  GetOdataContractAdminODataParams,
  GetSignatureRuleParams,
  PatchSignatureRuleByRuleIdBody,
  PostContractsBatchUpdateSignatoriesBody,
  PostSignatureRuleBody,
  PostSignatureRuleByRuleIdGroupOfSignaturesBody,
  PutContractByUuidUpdateContractBody,
  PutSignatureRuleByRuleIdGroupOfSignaturesByGroupIdBody,
} from "./models/requests";
import {
  DeleteSignatureRuleByRuleIdGroupOfSignaturesByGroupIdResponse,
  GetContractByUuidDetailsResponse,
  GetContractByUuidSendNotificationsResponse,
  GetContractByUuidStatusResponse,
  GetOdataContractAdminODataResponse,
  GetSignatureRuleByRuleIdResponse,
  GetSignatureRuleResponse,
  PatchSignatureRuleByRuleIdResponse,
  PostContractsBatchUpdateSignatoriesResponse,
  PostSignatureRuleByRuleIdGroupOfSignaturesResponse,
  PutContractByUuidUpdateContractResponse,
  PutSignatureRuleByRuleIdGroupOfSignaturesByGroupIdResponse,
} from "./models/responses";
import { paperclipv2Endpoints } from "./paperclipv2.endpoints";

export const paperclipv2Service = {
  getContractsOdata(params?: GetOdataContractAdminODataParams) {
    return client.get<GetOdataContractAdminODataResponse>(
      paperclipv2Endpoints.odata.contractAdminOData,
      { params },
    );
  },
  getContractDetails(uuid: string) {
    return client.get<GetContractByUuidDetailsResponse>(
      paperclipv2Endpoints.contract.byUuid.details(uuid),
    );
  },
  getContractFile(contractId: string) {
    return client.get<Blob>(
      paperclipv2Endpoints.contract.byContractId.fileBinary(contractId),
      {
        responseType: "blob",
      },
    );
  },
  getContractStatus(uuid: string) {
    return client.get<GetContractByUuidStatusResponse>(
      paperclipv2Endpoints.contract.byUuid.status(uuid),
    );
  },
  getContractFileBinary(uuid: string) {
    return client.get<Blob>(
      paperclipv2Endpoints.contract.byUuid.fileBinary(uuid),
      {
        responseType: "blob",
      },
    );
  },
  getContractSignedFileBinary(uuid: string) {
    return client.get<Blob>(
      paperclipv2Endpoints.contract.byUuid.signedFileBinary(uuid),
      {
        responseType: "blob",
      },
    );
  },
  sendNotifications(uuid: string) {
    return client.get<GetContractByUuidSendNotificationsResponse>(
      paperclipv2Endpoints.contract.byUuid.sendNotifications(uuid),
    );
  },
  updateSignatories(body: PostContractsBatchUpdateSignatoriesBody) {
    return client.post<PostContractsBatchUpdateSignatoriesResponse>(
      paperclipv2Endpoints.contracts.batch.updateSignatories,
      body,
    );
  },
  updateContract(uuid: string, body: PutContractByUuidUpdateContractBody) {
    return client.put<PutContractByUuidUpdateContractResponse>(
      paperclipv2Endpoints.contract.byUuid.updateContract(uuid),
      body,
    );
  },
  getSignatureRules(params?: GetSignatureRuleParams) {
    return client.get<GetSignatureRuleResponse>(
      paperclipv2Endpoints.signatureRule.endpoint,
      { params },
    );
  },
  createSignatureRule(body: PostSignatureRuleBody) {
    return client.post(paperclipv2Endpoints.signatureRule.endpoint, body);
  },
  getSignatureRule(ruleId: string | number) {
    return client.get<GetSignatureRuleByRuleIdResponse>(
      paperclipv2Endpoints.signatureRule.byRuleId.endpoint(ruleId),
    );
  },
  editSignatureRule(
    ruleId: string | number,
    body: PatchSignatureRuleByRuleIdBody,
  ) {
    return client.patch<PatchSignatureRuleByRuleIdResponse>(
      paperclipv2Endpoints.signatureRule.byRuleId.endpoint(ruleId),
      body,
    );
  },
  addGroupOfSignatures(
    ruleId: string | number,
    body: PostSignatureRuleByRuleIdGroupOfSignaturesBody,
  ) {
    return client.post<PostSignatureRuleByRuleIdGroupOfSignaturesResponse>(
      paperclipv2Endpoints.signatureRule.byRuleId.groupOfSignatures.endpoint(
        ruleId,
      ),
      body,
    );
  },
  editGroupOfSignatures(
    ruleId: string | number,
    groupId: string | number,
    body: PutSignatureRuleByRuleIdGroupOfSignaturesByGroupIdBody,
  ) {
    return client.put<PutSignatureRuleByRuleIdGroupOfSignaturesByGroupIdResponse>(
      paperclipv2Endpoints.signatureRule.byRuleId.groupOfSignatures.byGroupId(
        ruleId,
        groupId,
      ),
      body,
    );
  },
  removeGroupOfSignatures(
    ruleId: string | number,
    groupId: string | number,
    body: DeleteSignatureRuleByRuleIdGroupOfSignaturesByGroupIdBody,
  ) {
    return client.delete<DeleteSignatureRuleByRuleIdGroupOfSignaturesByGroupIdResponse>(
      paperclipv2Endpoints.signatureRule.byRuleId.groupOfSignatures.byGroupId(
        ruleId,
        groupId,
      ),
      { data: body },
    );
  },
};
