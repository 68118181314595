import * as yup from "yup";
import { EditEligibilityGroupForm } from "./edit-eligibility-group-modal.types";

export const editEligibilityGroupFormDefaultValues = {
  name: null,
  criteria: [],
} as Record<keyof EditEligibilityGroupForm, unknown> as any;

export const editEligibilityGroupValidationSchema = yup.object({
  name: yup
    .string()
    .required("É necessário escolher um nome")
    .max(50, "O nome não pode ultrapassar 50 caracteres"),
  criteria: yup
    .array(
      yup.object({
        criterium: yup.string().required("Selecione um critério"),
        operation: yup.string().required("Selecione uma operação"),
        value: yup.string().required("Preencha um valor"),
        active: yup.boolean(),
      }),
    )
    .test(
      "min-1-active",
      "É necessário adicionar ao menos um critério",
      (value) => {
        const trueValues = value?.filter((v) => v.active).length;
        return !!trueValues;
      },
    )
    .required("É necessário adicionar ao menos um critério")
    .min(1, "É necessário adicionar ao menos um critério"),
});
