import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataEstrategiasComerciaisOdataResponse } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { commercialStrategiesGrid } from "./commercial-strategies.grid";

const dataSource = dataSourceODataStoreGenerator<
  GetOdataEstrategiasComerciaisOdataResponse["value"][number]
>(endpoints.quickfin.odata.estrategiasComerciaisOdata);

export const CommercialStrategies = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Estratégias comerciais"
          description="Gerenciamento de estratégias comerciais"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={commercialStrategiesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
