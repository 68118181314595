import { PageTitle } from "components/page-title";
import {
  StepsBuilder,
  StepsBuilderProvider,
  useStepsBuilder,
} from "components/steps-builder";
import { DetailsTemplate } from "templates/details-template";
import { steps } from "./_steps/steps";
import { QuoteProvider } from "./quote.context";

const QuoteComponent = () => {
  const { stepNumber } = useStepsBuilder();

  const CurrentStep = steps[stepNumber].component;

  const stepNames = steps.map(({ name }) => name);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Cotação"
          description="Envie a lista de recebíveis via Excel. Uma solicitação é composta por CNPJ do cedente, CNPJ do sacado, data de vencimento, bandeira e valor de vencimento. A estratégia comercial utilizada será a existente do relacionamento entre cedente e sacado."
        />
      }
    >
      <StepsBuilder steps={stepNames}>
        <CurrentStep />
      </StepsBuilder>
    </DetailsTemplate>
  );
};

export const Quote = () => (
  <StepsBuilderProvider>
    <QuoteProvider>
      <QuoteComponent />
    </QuoteProvider>
  </StepsBuilderProvider>
);
