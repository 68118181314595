import { OButton, OCheckbox, OField, ORFieldInput } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { anticipationsGrid } from "./anticipations.grid";
import { useAnticipations } from "./anticipations.hook";

export const Anticipations = () => {
  const {
    anticipations,
    automaticFetch,
    form,
    hasError,
    loading,
    onSubmit,
    setAutomaticFetch,
  } = useAnticipations();

  return (
    <div className="d-flex flex-column gap-5">
      <PageTitle title="Antecipações" />
      <FormTemplate
        actions={
          <OButton
            dataAction="antecipacao_fidc:texto:pesquisar"
            dataLabel="pesquisar"
            onClick={() => onSubmit()}
          >
            Pesquisar
          </OButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ORFieldInput
              tag="cnpj"
              id="cnpj"
              name="cnpj"
              dataAction="antecipacao_fidc:texto:cnpj_do_sacado"
              dataLabel="cnpj_do_sacado"
              label="Cnpj do Sacado"
              placeholder="00.000.000/0000-00"
            />
            <OField label="Atualização automática" htmlFor="automaticFetch">
              <OCheckbox
                size="sm"
                id="automaticFetch"
                name="automaticFetch"
                checked={automaticFetch}
                onChange={(e: any) => setAutomaticFetch(e.target.checked)}
                value=""
              />
            </OField>
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={anticipations}
        errorComponent={
          <ErrorComponent
            messageTitle="Ocorreu um erro ao buscar as antecipações"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => onSubmit()} />
          </ErrorComponent>
        }
        emptyStateComponent={null}
        render={(_anticipations) => (
          <ODataGridGenerator
            grid={anticipationsGrid}
            dataSource={_anticipations}
          />
        )}
      />
    </div>
  );
};
