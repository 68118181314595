import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OConfirmationModal } from "@maestro/react";
import { useCallback } from "react";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { service } from "services/service";

const modalId = "update-salesfore-data-modal";

export const UpdateSalesforceData = () => {
  const { customer } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.clerk.postSendUpdateMessage,
  );

  const submit = useCallback(async () => {
    const { success } = await callService({
      identification: customer.identification,
    });

    if (success) {
      OToastManager.info("Dados do salesforce atualizados com sucesso.");
    } else {
      OToastManager.danger("Erro ao atualizar dados no salesforce.");
    }
  }, [callService, customer.identification]);

  return (
    <>
      <OButton
        dataAction="ferramentas:botao:atualizar"
        dataLabel="atualizar"
        onClick={() => modalManager.show(modalId)}
      >
        Atualizar
      </OButton>

      <OConfirmationModal
        modalId={modalId}
        size="sm"
        title="Atualizar dados no Salesforce?"
        closeLabel="Cancelar"
        confirmLabel="Confirmar"
        onConfirm={submit}
        loading={loading}
      />
    </>
  );
};
