import {
  modalManager,
  OButton,
  OCol,
  OField,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { EstrategiaSelector } from "../../../components";
import { FileUpload, ValidationResults } from "./_compose";
import {
  BatchUploadReceivablesProvider,
  useBatchUploadReceivables,
} from "./batch-upload-receivables.context";

const BatchUploadReceivablesComponent = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    setEstrategia,
    validationResults,
    bypassValidation,
    setBypassValidation,
  } = useBatchUploadReceivables();

  return (
    <FormTemplate pageTitle={<PageTitle title="Recebíveis" />}>
      {!validationResults && (
        <CardTemplate>
          <ORow className="mb-12">
            <OCol xs={12} md={6}>
              <EstrategiaSelector
                companyId={quickfinId}
                setEstrategia={setEstrategia}
              />
            </OCol>
            <OCol xs={12} md={3}>
              <OField htmlFor="bypassValidation" label="Bypass validação">
                <OSwitch
                  id="bypassValidation"
                  size="sm"
                  value={bypassValidation}
                  onInput={() => {
                    if (bypassValidation) {
                      modalManager.hide("bypass-validation-modal");
                    } else {
                      modalManager.show("bypass-validation-modal");
                    }
                    setBypassValidation((oldValue) => !oldValue);
                  }}
                />
              </OField>
            </OCol>
          </ORow>
          <FileUpload />
        </CardTemplate>
      )}
      {validationResults && <ValidationResults />}
      <OModal
        position="center"
        id="bypass-validation-modal"
        onModalClose={() => {
          setBypassValidation(false);
        }}
      >
        <OModalHeader>
          <OTypography weight="bold">Confirmação</OTypography>
        </OModalHeader>
        <OModalBody>
          <OTypography>
            Essa ação é usada de forma extraordinária somente para casos de
            contigência! Nenhum dado do arquivo será pré-validado. Tem certeza
            que deseja utilizar essa ação?
          </OTypography>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <OButton
              type="default"
              outline
              dataAction="bypass-validation:botao:fechar"
              dataLabel="fechar"
              onClick={() => {
                setBypassValidation(false);
                modalManager.hide("bypass-validation-modal");
              }}
            >
              Fechar
            </OButton>
            <OButton
              type="primary"
              dataAction="bypass-validation:botao:aceitar"
              dataLabel="gerar"
              title="Aceitar"
              onClick={async () => {
                setBypassValidation(true);
                modalManager.hide("bypass-validation-modal");
              }}
            >
              Sim
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
    </FormTemplate>
  );
};

export const BatchUploadReceivables = () => (
  <BatchUploadReceivablesProvider>
    <BatchUploadReceivablesComponent />
  </BatchUploadReceivablesProvider>
);
