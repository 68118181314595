import { OIcon } from "@maestro/react";
import { FilterOButton, Label } from "./filter-button.styles";

export interface Props {
  onClick: () => void;
}

export const FilterButton = ({ onClick }: Props) => {
  return (
    <FilterOButton
      onClick={onClick}
      dataLabel="statement-filter"
      dataAction="conta-digital:botao:statement-filter"
      size="md"
    >
      <Label>
        Filtros <OIcon category="orq" icon="orq-tools-filter" />
      </Label>
    </FilterOButton>
  );
};
