import {
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { InputDdiPhone } from "components/input-ddi-phone";
import { basicFields } from "./basic-fields.form";
import { useBasicFields } from "./basic-fields.hook";
import { RoleAssignmentsForm } from "../role-assignments.types";

interface BasicFieldsProps {
  editing?: boolean;
  editingPhoneValue?: string;
}

export const BasicFields = ({
  editing,
  editingPhoneValue,
}: BasicFieldsProps) => {
  const { getUserDataLoading, disableEmail, phoneDefaultValue } =
    useBasicFields(editing);
  const {
    setValue,
    formState: { errors },
  } = useFormContext<RoleAssignmentsForm>();

  const onChangePhone = useCallback(
    (value: string) => {
      setValue("phoneNumber", value);
    },
    [setValue],
  );

  return (
    <ORow gap={3}>
      <OCol xs={12} md={6} align="center">
        <ORFieldInput {...basicFields.taxId} disabled={editing} />
        {getUserDataLoading && <OLoader size="sm" />}
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...basicFields.name} disabled={editing} />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...basicFields.email}
          disabled={editing || disableEmail}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputDdiPhone
          defaultValue={editingPhoneValue || phoneDefaultValue}
          onChange={onChangePhone}
          error={!!errors.phoneNumber}
          message={errors?.phoneNumber?.message}
          {...basicFields.phoneNumber}
          disabled={editing}
        />
      </OCol>
      {!editing && (
        <OCol xs={12} md={6}>
          <ORFieldCheckboxGroup {...basicFields.sendEmail}>
            <div className="d-flex align-items-center gap-2">
              <OCheckbox id="check" value="check" size="sm" />
              <OLabel htmlFor="check">Enviar e-mail de boas vindas</OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
      )}
    </ORow>
  );
};
