import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaRelacionamentosByUuidRouteParams } from "../../../../../../../../../routes/ferramentas.route-params";
import { DeskAccountConfigRelationshipForm } from "../_compose/desk-account-config-relationship-form.component";
import {
  deskAccountConfigRelationshipFormValidationSchema,
  DeskAccountConfigRelationshipFormValues,
} from "../_compose/desk-account-config-relationship.form";

export const UpdateDeskAccountConfigRelationshipPage = () => {
  const { uuid, relationshipUuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaRelacionamentosByUuidRouteParams>();
  if (!uuid || !relationshipUuid)
    throw new Error("No uuid or relationshipUuid");
  const form = useForm<DeskAccountConfigRelationshipFormValues>({
    resolver: yupResolver(deskAccountConfigRelationshipFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskOnboardingAccountConfigRelationships,
    loading,
    hasError,
    value: deskAccountConfiRelationships,
  } = useServiceCall(
    service.onboarding.getDeskOnboardingAccountConfigRelationships,
  );
  const {
    callService: updateDeskOnboardingAccountConfigRelationships,
    loading: submitLoading,
  } = useServiceCall(
    service.onboarding.updateDeskOnboardingAccountConfigRelationships,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } =
          await updateDeskOnboardingAccountConfigRelationships({
            ...values,
            deskOnboardingAccountConfigUuid: uuid,
            deskOnboardingAccountConfigRelationshipUuid: relationshipUuid,
          });

        if (!success)
          return OToastManager.danger("Erro ao atualizar relacionamento");

        return OToastManager.success("Relacionamento atualizada com sucesso");
      }),
    [
      handleSubmit,
      relationshipUuid,
      updateDeskOnboardingAccountConfigRelationships,
      uuid,
    ],
  );

  useEffect(() => {
    getDeskOnboardingAccountConfigRelationships(uuid);
  }, [getDeskOnboardingAccountConfigRelationships, uuid]);

  useEffect(() => {
    const deskAccountConfiRelationship =
      deskAccountConfiRelationships?.deskOnboardingRelationshipAccountConfigs.find(
        (relationship) => relationship.uuid === relationshipUuid,
      );
    deskAccountConfiRelationship &&
      reset({
        ...deskAccountConfiRelationship,
      });
  }, [
    deskAccountConfiRelationships?.deskOnboardingRelationshipAccountConfigs,
    relationshipUuid,
    reset,
  ]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Editar relacionamentos"
          description={`Agência: ${deskAccountConfiRelationships?.deskOnboardingAccountInfoAgency} | Conta: ${deskAccountConfiRelationships?.deskOnboardingAccountInfoAccountType} | Configuração: ${configIdentifier}`}
        />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar relacionamento"
            callback={() => getDeskOnboardingAccountConfigRelationships(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskAccountConfigRelationshipForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
