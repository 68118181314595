import { OToastManager } from "@maestro/core";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { Collaterals } from "./collaterals.types";

export const guaranteeFtsLimitsGrid = (limitRequestId: number) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantia encontrada.",
      editing: {
        allowUpdating: true,
      },
      onRowInserted: async (row) => {
        try {
          await service.hubCreditManager.createCollateral({
            identification: row.data.identification,
            description: row.data.description || undefined,
            lockDisbursement: row.data.lockDisbursement,
            hasOptional: row.data.hasOptional,
            expireDate: null,
            collateralType: "CDB",
            limitRequestId,
            formalized: false,
          });
          OToastManager.success("Garantia criado com sucesso.");
        } catch {
          OToastManager.danger(
            "Houve um problema, tente novamente em instantes.",
          );
        }
      },
      onRowUpdated: async (row) => {
        try {
          await service.hubCreditManager.updateCollateral({
            collateralId: row.data.id,
            identification: row.data.identification,
            description: row.data.description || undefined,
            lockDisbursement: row.data.lockDisbursement,
            hasOptional: row.data.hasOptional,
            formalized: false,
          });
          OToastManager.success("Garantia atualizado com sucesso.");
        } catch {
          OToastManager.danger(
            "Houve um problema, tente novamente em instantes.",
          );
        }
      },
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        alignment: "left",
      },
      {
        dataField: "identification",
        caption: "Identificação",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "lockDisbursement",
        caption: "Trava de desembolso",
        lookup: {
          dataSource: ["POS", "PRE"],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        caption: "Opcional",
        dataField: "hasOptional",
      },
    ],
  } satisfies ODataGridGeneratorConfig<Collaterals[number]>);
