import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, receivableDetailsGrid } from "./receivable-details.grid";

export const ReceivableDetails = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Detalhes dos recebíveis no portal" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={receivableDetailsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
