import {
  modalManager,
  OButton,
  OIcon,
  OModal,
  OModalFooter,
  OModalHeader,
  OOptionMultiple,
  ORFieldSelectMultiple,
  OTypography,
} from "@maestro/react";
import { GetApiAdminBigNumbersResponse } from "services/hubcreditmanager/models/responses";
import { JSX } from "@maestro/core";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { StyledOModalBody } from "../../../edit-workflow-config.styles";
import { AddBigNumbersFormFields } from "./workflow-config-view-big-numbers.schema";

interface AddBigNumbersModalProps {
  form: UseFormReturn<AddBigNumbersFormFields>;
  bigNumbersOptions?: GetApiAdminBigNumbersResponse;
}

export const ADD_BIG_NUMBERS_MODAL = "add-big-numbers-modal";

export const AddBigNumbersModal = ({
  form,
  bigNumbersOptions,
}: AddBigNumbersModalProps) => {
  const { hide } = modalManager;

  return (
    <FormProvider {...form}>
      <form>
        <OModal id={ADD_BIG_NUMBERS_MODAL} position="center">
          <OModalHeader>Adicionar Big Number</OModalHeader>
          <StyledOModalBody>
            <div key={`big-numbers-options-${bigNumbersOptions?.length}`}>
              <ORFieldSelectMultiple
                id="bigNumbers"
                name="bigNumbers"
                label="Big numbers"
                labelSize="md"
                dataAction="workflow-lead:multiselect:big-numbers"
                dataLabel="big-numbers"
              >
                {bigNumbersOptions?.map((option) => (
                  <OOptionMultiple
                    value={String(option.id)}
                    key={`big-number-option-${option.id}`}
                  >
                    <div className="d-flex gap-2">
                      <OTypography>{option.name}</OTypography>
                      <OIcon
                        icon={option.iconName}
                        category={
                          option.iconName.split("-")[0] as JSX.OIcon["category"]
                        }
                      />
                    </div>
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </div>
          </StyledOModalBody>

          <OModalFooter>
            <div className="d-flex justify-content-end">
              <OButton
                dataAction="add-big-numbers-modal:botao:fechar"
                dataLabel="fechar"
                onClick={() => hide(ADD_BIG_NUMBERS_MODAL)}
                type="dark"
                outline
              >
                Fechar
              </OButton>
            </div>
          </OModalFooter>
        </OModal>
      </form>
    </FormProvider>
  );
};
