import { GetCardOffersResponse } from "services/admin-bankinghub/card/models";

export type Offer = GetCardOffersResponse["offers"][number];

export enum OfferStatus {
  Created = "CREATED",
  Hired = "HIRED",
  Expired = "EXPIRED",
  Canceled = "CANCELED",
  PendingSignature = "PENDING_SIGNATURE",
  PendingGuarantorsOnboard = "PENDING_GUARANTORS_ONBOARD",
}
