import { useState } from "react";
import { OPillTab, OPillTabHeader, OPillTabs } from "@maestro/react";
import { CompanyInteractionDetails } from "./company-details.page";
import { InteractionsCompany } from "../interacoes";

export const CompanyInteractionDetailsOption = () => {
  const [viewGrid, setViewGrid] = useState("contract");

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex">
        <OPillTabs>
          <OPillTabHeader>
            <OPillTab onClick={() => setViewGrid("contract")}>
              Detalhes
            </OPillTab>
            <OPillTab onClick={() => setViewGrid("anticipation")}>
              Notificação
            </OPillTab>
          </OPillTabHeader>
        </OPillTabs>
      </div>
      {viewGrid === "contract" ? (
        <CompanyInteractionDetails />
      ) : (
        <InteractionsCompany />
      )}
    </div>
  );
};
