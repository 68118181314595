import { OTypography } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import dayjs from "dayjs";
import { PostFilterTableRegistrosSolicitationsResponse } from "services/recebiveis-cartao/models";
import { downloadRegister } from "./register-solicitations.utils";

export type RegisterSolicitation =
  PostFilterTableRegistrosSolicitationsResponse["data"][number];

export const registerSolicitationsGrid: ODataGridGeneratorConfig<RegisterSolicitation> =
  {
    datagrid: {
      noDataText: "Nenhuma solicitação",
      pager: {
        showPageSizeSelector: true,
      },
      headerFilter: {
        visible: true,
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "inserted_at",
        caption: "Inserido em",
        dataType: "datetime",
        format: "shortDateShortTime",
        filterValue: dayjs().startOf("day").toDate(),
        selectedFilterOperation: ">=",
        sortOrder: "desc",
        allowHeaderFiltering: false,
      },
      {
        dataField: "external_id",
        caption: "Id externo",
        allowHeaderFiltering: false,
      },
      { dataField: "tipo", caption: "Modelo" },
      { dataField: "action", caption: "Ação" },
      {
        dataField: "request_key",
        caption: "Chave de requisição",
        allowHeaderFiltering: false,
        cellRender: ({ data }) => {
          const key = data.request_key;

          return key ? (
            <IconButton
              icon={{ category: "orq", icon: "orq-download" }}
              dataAction="registros-solicitacoes:botao:download-chave-requisicao"
              dataLabel="download-chave-requisicao"
              type="dark"
              outline
              onClick={() => downloadRegister(key)}
            >
              {key.split("/").at(-1)}
            </IconButton>
          ) : (
            "------"
          );
        },
      },
      {
        dataField: "responses_key",
        caption: "Chaves de resposta",
        allowHeaderFiltering: false,
        cellRender: ({ data }) => {
          const keys = data.responses_key;

          return keys.length > 0 ? (
            <div className="d-flex flex-column gap-1">
              {keys.map((key) => (
                <IconButton
                  key={key}
                  icon={{ category: "orq", icon: "orq-download" }}
                  dataAction={`registros-solicitacoes:botao:download-chave-resposta-${key}`}
                  dataLabel={`download-chave-resposta-${key}`}
                  type="dark"
                  outline
                  onClick={() => downloadRegister(key)}
                >
                  {key}
                </IconButton>
              ))}
            </div>
          ) : (
            "Sem resposta"
          );
        },
      },
      { dataField: "status", caption: "Status" },
      {
        dataField: "errors",
        caption: "Erros no retorno",
        allowHeaderFiltering: false,
        cellRender: ({ data }) =>
          data.errors.length > 0 ? (
            <div className="d-flex flex-column gap-1">
              {data.errors.map((error) => (
                <OTypography key={error}>{error}</OTypography>
              ))}
            </div>
          ) : (
            "Sem erros"
          ),
      },
    ],
  };
