import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";
import { FileSearchReceivableWithOverrideMaturityAmount } from "../../intubate-operations.types";

export const quoteFileGrid: ODataGridGeneratorConfig<FileSearchReceivableWithOverrideMaturityAmount> =
  {
    datagrid: {
      noDataText: "Nenhum recebível.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "ReceivableId",
        dataType: "string",
        caption: "ID do recebível",
        allowHeaderFiltering: false,
        visible: false,
      },
      {
        dataField: "Sacado",
        dataType: "string",
        caption: "Sacado",
        allowHeaderFiltering: false,
      },
      {
        dataField: "SacadoIdentification",
        dataType: "string",
        caption: "CNPJ do Sacado",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "Cedente",
        dataType: "string",
        caption: "Cedente",
        allowHeaderFiltering: false,
      },
      {
        dataField: "CedenteIdentification",
        dataType: "string",
        caption: "CNPJ do Cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "Currency",
        dataType: "string",
        caption: "Moeda",
        alignment: "center",
      },
      {
        dataField: "Bandeira",
        dataType: "string",
        caption: "Bandeira",
        alignment: "center",
        cellRender: ({ data }) =>
          getValueFromMap(cardIconMap, data.Bandeira) || data.Bandeira,
      },
      {
        dataField: "DataVencimento",
        caption: "Data de Vencimento",
        dataType: "date",
        format: "shortDate",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorVencimento",
        caption: "Valor Vencimento Original",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorDesembolso",
        caption: "Valor Desembolso Original",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorSolicitado",
        caption: "Valor Vencimento Solicitado",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "ValorDesembolsoSolicitado",
        caption: "Valor Desembolso Solicitado",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "DataDesembolso",
        caption: "Data de Desembolso",
        dataType: "date",
        format: "shortDate",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "DiasCorridos",
        caption: "Dias Corridos",
        allowHeaderFiltering: false,
      },
      {
        dataField: "TaxaTotal",
        caption: "Taxa Efetiva",
        format: { type: "percent", precision: 4 },
        allowHeaderFiltering: false,
      },
      {
        dataField: "TaxaPeriodo",
        caption: "Taxa Mês",
        format: { type: "percent", precision: 4 },
        allowHeaderFiltering: false,
      },
    ],
  };
