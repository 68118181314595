import { ErrorComponent } from "../../../empty-state/error-component.component";

interface DevelopmentErrorUiProps {
  errorMessage: string | undefined;
  componentStack: string | undefined;
  customAction?: JSX.Element;
}

export const DevelopmentErrorUi = ({
  errorMessage,
  componentStack,
  customAction,
}: DevelopmentErrorUiProps) => {
  return (
    <div
      style={{ width: "max-content", maxWidth: "100%" }}
      className="py-5 mx-auto"
    >
      <ErrorComponent messageTitle={errorMessage}>
        <pre style={{ color: "var(--theme-dark)" }}>{componentStack}</pre>
        {customAction}
      </ErrorComponent>
    </div>
  );
};
