import { OButton, OTypography, modalManager } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { ManageDeliveryAddressFormModalId } from "../../manage-delivery-address-form-modal/manage-delivery-address-form-modal.types";

export const ManageAddressButtons = () => {
  const { watch, resetField } = useFormContext();

  const addressIdWatcher = watch("addressId");

  const ownerIdWatcher = watch("ownerId");

  return (
    <div className="d-flex flex-row gap-2">
      {addressIdWatcher && (
        <OButton
          type="secondary"
          size="sm"
          outline
          onClick={() => modalManager.show(ManageDeliveryAddressFormModalId)}
        >
          <OTypography size="sm">Editar endereço</OTypography>
        </OButton>
      )}
      <OButton
        type="primary"
        size="sm"
        outline
        disabled={!ownerIdWatcher}
        onClick={() => {
          resetField("addressId");
          modalManager.show(ManageDeliveryAddressFormModalId);
        }}
      >
        <OTypography size="sm">Adicionar novo endereço</OTypography>
      </OButton>
    </div>
  );
};
