import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { ResendOfferNotificationButton } from "./_compose";
import { offerStatusFilters } from "./hired-offers.filter-utils";
import { Offer } from "./hired-offers.types";
import { buildDetailsLink, hiredOffersStatusMap } from "./hired-offers.utils";

export const hiredOffersGrid: ODataGridGeneratorConfig<Offer> = {
  datagrid: {
    noDataText: "Nenhuma contratação",
    remoteOperations: {
      paging: true,
      filtering: true,
    },
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.cartoes.product.hiredOffersOfferDetailsPage.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
    },
    {
      dataField: "company.name",
      caption: "Empresa",
      allowSorting: false,
      allowHeaderFiltering: false,
    },
    {
      dataField: "company.document",
      caption: "CNPJ",
      format: (value) => masks.cpfCnpj(value),
      allowSorting: false,
      allowHeaderFiltering: false,
    },
    {
      dataField: "limitAnalysis.amount",
      caption: "Limite liberado",
      format: (value) => masks.currency(value, currencySymbol.BRL),
      allowSorting: false,
      allowFiltering: false,
    },
    {
      dataField: "status",
      caption: "Status",
      headerFilter: {
        dataSource: offerStatusFilters,
      },
      allowSorting: false,
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellRender: ({ data }) => {
        const { label, type } = getValueFromMap(
          hiredOffersStatusMap,
          data.status,
        ) ?? {
          label: data.status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      dataField: "limitAnalysis.expirationDate",
      dataType: "date",
      caption: "Data de expiração",
      format: "shortDate",
      allowSorting: false,
      allowFiltering: false,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <ResendOfferNotificationButton
          offerId={data.id}
          companyId={data.company.id}
          companyName={data.company.name}
        />
      ),
    },
  ],
  paging: {
    enabled: true,
    defaultPageSize: 15,
  },
};
