import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { buildQuery } from "pages/duplicatas/components/wallet-details/wallet-details.hook";
import { useCallback } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export const useWalletInfo = () => {
  const { customer } = useCustomer();
  const document = customer.identification;
  const documentType = validators.cpf("" + document) ? 1 : 2;

  const { callService: createBordero } = useServiceCall(
    service.moana.createBordero,
  );

  const { callService: portfolioManagement } = useServiceCall(
    service.moana.portfolioManagement,
  );

  const getOwner = useCallback(async () => {
    try {
      const x = buildQuery({
        limit: 1,
        offset: 0,
        ...(document && { personType: documentType }),
        ...{ documentNumber: document },
      });
      const { data } = await service.moana.getOwner(x);
      return data.data;
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      const errorMessage = "Erro ao buscar carteiras.";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }
  }, [document, documentType]);

  const reprocessarBordero = useCallback(async () => {
    const { success } = await createBordero({
      documentNumber: document,
      personType: documentType,
    });
    if (success) {
      OToastManager.success("Reprocessado com sucesso");
    } else {
      OToastManager.danger("Não foi possível reprocessar a solicitação");
    }
  }, [createBordero, document, documentType]);

  const getLimit = useCallback(async () => {
    const { data } = await service.hubCreditManager.getLimit({
      taxId: document,
      identification: "ANTECIPACAO_DE_RECEBIVEIS_BOLETOS",
      companyTaxId: "",
      userEmail: "",
      originSystem: "",
      originIP: "",
    });
    return data;
  }, [document]);

  const getPortfolioManagement = useCallback(async () => {
    const data = await portfolioManagement({
      documentNumber: document,
    });
    return data.response;
  }, [document]);

  return {
    getOwner,
    reprocessarBordero,
    getLimit,
    getPortfolioManagement,
  };
};
