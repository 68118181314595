import React from "react";
import { service } from "services";
import { ApiSelector } from "../../../../../../../components/api-selector";

interface PropertyTypeSelectorProps
  extends Omit<
    React.ComponentProps<typeof ApiSelector>,
    "optionMapper" | "serviceMethod"
  > {
  valueType: "id" | "name";
}

export const PropertyTypeSelector = ({
  valueType,
  ...rest
}: PropertyTypeSelectorProps) => {
  return (
    <ApiSelector
      {...rest}
      optionMapper={(value) => {
        const options =
          value?.response?.map(({ name: typeName, label, id }) => ({
            content: label,
            value: valueType === "id" ? id : typeName,
          })) ?? [];
        options.sort((a, b) => a.content.localeCompare(b.content));
        return options;
      }}
      serviceMethod={service.hubEnergy.getDiligenceStepPropertyTypes}
    />
  );
};
