/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  IFileUpload,
  ISize,
  JSX,
  OUploadCustomEvent,
} from "@maestro/core";
import {
  OField,
  OIcon,
  OUpload,
  OUploadInput,
  OUploadItem,
  OUploadLabel,
  OUploadTip,
  TooltipContainer,
} from "@maestro/react";
import { type FieldProps } from "@maestro/react/dist/types/utils";
import { useCallback, useMemo, useState } from "react";

interface UploadWithoutFormProps
  extends JSX.OUpload,
    JSX.OUploadInput,
    FieldProps {
  name: string;
  inputLabel: string;
  tip?: string;
  size?: ISize;
  handleDownload?: (file: File) => void;
  handleRemove?: (file: File, resolve: (file: IFileUpload) => void) => void;
  onAddFile?: (event: OUploadCustomEvent<File>) => void;
  handleAddFile?: (
    event: OUploadCustomEvent<File>,
    resolve: (file: IFileUpload) => void,
  ) => void;
  onlyFiles?: boolean;
  children?: React.ReactNode;
}

export const UploadWithoutForm = (props: UploadWithoutFormProps) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    inputLabel,
    tip,
    size = "md",
    handleDownload,
    handleRemove,
    handleAddFile,
    onAddFile,
    onlyFiles,
    children,
    ...rest
  } = props;

  const [files, setFiles] = useState<IFileUpload[]>(props.value ?? []);

  const resolveAddFile = useCallback((file: IFileUpload) => {
    setFiles((oldFiles) => [...oldFiles, file]);
  }, []);

  const resolveRemove = useCallback((file: IFileUpload) => {
    setFiles((oldFiles) => oldFiles.filter((f) => f !== file));
  }, []);

  const fieldOptions = useMemo(
    () => ({
      htmlFor: props.id,
      ...(props.description && { description: props.description }),
      ...(props.disabled && { disabled: props.disabled }),
      ...(props.label && { label: props.label }),
      ...(props.labelSize && { labelSize: props.labelSize }),
      ...(props.labelType && { labelType: props.labelType }),
      ...(props.required && { required: props.required }),
    }),
    [
      props.description,
      props.disabled,
      props.id,
      props.label,
      props.labelSize,
      props.labelType,
      props.required,
    ],
  );

  return (
    <OUpload
      value={files}
      onAddFile={(e: OUploadCustomEvent<File>) => {
        onAddFile?.(e);
        handleAddFile?.(e, resolveAddFile);
      }}
    >
      <div>
        {!onlyFiles && (
          <OField {...fieldOptions}>
            {tooltip && (
              <TooltipContainer
                position={tooltipPosition}
                maxWidth={tooltipMaxWidth}
              >
                {tooltip}
              </TooltipContainer>
            )}

            <div className="d-flex flex-column gap-3">
              <OUploadInput {...(rest as any)} />

              <OUploadLabel
                htmlFor={props.id}
                size={size}
                disabled={props.disabled}
              >
                {inputLabel}
                <OIcon category="far" icon="fa-cloud-upload" size={size} />
              </OUploadLabel>

              {tip && <OUploadTip size={size}>{tip}</OUploadTip>}
            </div>
          </OField>
        )}

        {!!files.length && (
          <div className="d-flex flex-column gap-3">
            {files.map((file: any) => (
              <OUploadItem
                key={file.id || file.key}
                size={size}
                errorMessage={file.errorMessage}
                disabled={props.disabled}
                handleDownload={
                  handleDownload ? () => handleDownload(file) : undefined
                }
                handleRemove={
                  handleRemove
                    ? () => handleRemove(file, resolveRemove)
                    : undefined
                }
              >
                {file.name}
              </OUploadItem>
            ))}
          </div>
        )}
      </div>
    </OUpload>
  );
};
