import {
  OButton,
  OFilter,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterItem,
  OFilterModal,
  OInputText,
  OTypography,
} from "@maestro/react";
import { useRef } from "react";
import { monitoringFilterModalId } from "../../monitoring-template.utils";

interface MonitoringFilterModalProps {
  fields: HubOnboardingRelationships.Odata.MotinoringFilterField[];
  setFilter: (filter: Record<string, string>) => void;
}

export const MonitoringFilterModal = ({
  fields,
  setFilter,
}: MonitoringFilterModalProps) => {
  const filterModalRef = useRef<HTMLOFilterModalElement>(null);

  const handleFilterItems = async () => {
    const params = await filterModalRef.current?.filter();

    if (!params) {
      return;
    }

    setFilter(params);

    filterModalRef.current?.closeFilter();
  };

  const handleClearFilterItems = async () => {
    await filterModalRef.current?.clearFilter();
    setFilter({});
    filterModalRef.current?.closeFilter();
  };

  return (
    <OFilterModal ref={filterModalRef} id={monitoringFilterModalId}>
      <OFilterHeader className="pt-1">
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>

      <OFilterBody>
        <OFilter>
          {fields.map((field) => (
            <OFilterItem key={field.property}>
              <OTypography slot="title">{field.label}</OTypography>
              <OInputText
                name={`${field}_field`}
                id={`${field}_field`}
                data-filterparam={field.property}
              />
            </OFilterItem>
          ))}
        </OFilter>
      </OFilterBody>

      <OFilterFooter>
        <div className="d-flex justify-content-end">
          <OButton
            type="primary"
            onClick={handleClearFilterItems}
            bordered={false}
            outline
          >
            Limpar
          </OButton>
          <OButton onClick={handleFilterItems}>Filtrar</OButton>
        </div>
      </OFilterFooter>
    </OFilterModal>
  );
};
