import { OToastManager, modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { NameAndTaxIdFormatted } from "components/name-and-tax-id-formatted";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GenerateContract } from "../_compose/generate-contract";
import { ReopenDataReview } from "./_compose/reopen-data-review.component";
import { ResendDataReview } from "./_compose/resend-data-review.component";
import { SyncDataReviewWorkflow } from "./_compose/sync-data-review-workflow.component";

export const buildDataSource = (requestTaxId: string) =>
  dataSourceCustomStoreGenerator(() =>
    service.onboarding
      .getDataReviewWorkflows({ requestTaxId })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Não foi possível buscar os workflows";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const customerDataReviewWorkflowsGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDataReviewWorkflows>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum workflow",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
  },
  columns: [
    {
      dataField: "uuid",
      dataType: "string",
      caption: "Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "workflowDataReviewConfigType",
      dataType: "string",
      caption: "Tipo",
    },
    {
      dataField: "portalStatus",
      dataType: "string",
      caption: "Status",
    },
    {
      dataField: "portalNotificationStatus",
      dataType: "string",
      caption: "Status da notificação",
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de criação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "updatedDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Última atualização",
      allowHeaderFiltering: false,
    },
    {
      dataField: "isRegulatory",
      caption: "É regulatório?",
    },
    {
      dataField: "createdByEmail",
      caption: "Criado por",
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.createdByEmail}
          taxId={data.createdByTaxId}
        />
      ),
    },
    {
      caption: "Ações",
      cellRender: ({ data: { uuid } }) => {
        const syncModalId = `sync-data-review-workflow-${uuid}-modal`;
        const resendDataReviewModalId = `resend-data-review-${uuid}-modal`;
        const reopenDataReviewModalId = `reopen-data-review-${uuid}-modal`;
        const generateContractModalId = `generate-contract-${uuid}-modal`;

        return (
          <>
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-swap-circle" },
                  label: "Sincronizar",
                  onClick: () => {
                    modalManager.show(syncModalId);
                  },
                },
                {
                  label: "Editar",
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  route:
                    corporateRouter.routes.cadastro.customer.details.onboardingReviews.details.edit.path(
                      {
                        uuid,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingReviewsEditPage.role,
                },
                {
                  label: "Logs",
                  icon: { category: "orq", icon: "orq-file" },
                  route:
                    corporateRouter.routes.cadastro.customer.details.onboardingReviews.details.logs.path(
                      {
                        uuid,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingReviewsLogsPage.role,
                },
                {
                  icon: { category: "orq", icon: "orq-telegram" },
                  label: "Reenviar",
                  onClick: () => {
                    modalManager.show(resendDataReviewModalId);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-locker-open" },
                  label: "Reabrir",
                  onClick: () => {
                    modalManager.show(reopenDataReviewModalId);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-contract-agreement" },
                  label: "Gerar contrato",
                  onClick: () => {
                    modalManager.show(generateContractModalId);
                  },
                },
              ]}
            />
            <SyncDataReviewWorkflow modalId={syncModalId} uuid={uuid} />
            <ResendDataReview
              modalId={resendDataReviewModalId}
              workflowUuid={uuid}
            />
            <ReopenDataReview
              modalId={reopenDataReviewModalId}
              workflowUuid={uuid}
            />
            <GenerateContract
              modalId={generateContractModalId}
              dataReviewUuid={uuid}
            />
          </>
        );
      },
    },
  ],
};
