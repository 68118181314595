import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetSemaphoreTemplateResponseItem } from "services/onboarding/models/responses/get-semaphore-configuration.response";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./template-config.grid";

export const SemaphoreTemplate = () => {
  const { routes } = corporateRouter;

  const dataSource =
    dataSourceCustomStoreGenerator<GetSemaphoreTemplateResponseItem>(() =>
      service.onboarding
        .getSemaphoreTemplateList()
        .then(({ data }) => data)
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar configurações de semáforo";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    );

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Semáforo"
          description="Visualizar configurações de semáforo cadastrados para onboarding"
        />
      }
      actions={
        <RouterButton
          dataAction="ferramentas_configuracao_semaforo:botao:adicionar"
          dataLabel="adicionar"
          href={
            routes.ferramentas.product.onboarding.semaphore.configuration
              .template.add.path
          }
          role={roles.ferramentas.product.semaphoreConfigTemplateAdd.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
