import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { GetFundAdminResponse } from "services/fidc/models/responses";
import { service } from "services/service";

export const useFidcFunds = () => {
  const [loading, setLoading] = useState(false);
  const [funds, setFunds] = useState<GetFundAdminResponse>();

  const getFunds = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await service.fidc.getFunds();

      setFunds(data);
    } catch {
      OToastManager.danger("Erro ao buscar os fundos de FIDC");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getFunds();
  }, [getFunds]);

  return {
    funds,
    loading,
  };
};
