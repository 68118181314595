import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OField,
  OLoader,
  ORFieldInput,
  ORow,
  OTypography,
  modalManager,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { impersonateRedirect } from "utils/impersonate";
import { SyncCpfConfirmationModal } from "./_compose";
import { syncCpfConfirmationModalId } from "./operator-details.utils";
import { useOperatorDetailByIdentification } from "./operator-details.hook";

export const OperatorDetailByIdentification = () => {
  const { form, loadingOperator, watchCge, watchEmail } =
    useOperatorDetailByIdentification();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do operador" />}
      actions={<LoadingButton loading={false}>Atualizar</LoadingButton>}
    >
      <DetailsTemplate
        actions={
          <>
            <IconButton
              icon={{ category: "orq", icon: "orq-link", type: "tertiary" }}
              type="tertiary"
              outline
              disabled={!watchEmail}
              onClick={() => impersonateRedirect(watchEmail, "PARCEIROS")}
            >
              Impersonar B2B
            </IconButton>
            <OButton
              type="default"
              outline
              onClick={() => modalManager.show(syncCpfConfirmationModalId)}
            >
              Sincronizar razão social
            </OButton>
          </>
        }
      >
        <FormProvider {...form}>
          {loadingOperator ? (
            <OLoader />
          ) : (
            <OCard>
              <OCardBody>
                <ORow>
                  <OCol xs={12} xl={6}>
                    <ORFieldInput
                      tag="cpf"
                      id="identification"
                      name="identification"
                      label="CPF"
                      disabled
                    />
                  </OCol>
                  <OCol xs={12} xl={6}>
                    <ORFieldInput
                      tag="text"
                      id="name"
                      name="name"
                      label="Nome"
                      disabled
                    />
                  </OCol>
                  <OCol xs={12} xl={6}>
                    <ORFieldInput
                      tag="text"
                      id="email"
                      name="email"
                      label="E-mail"
                    />
                  </OCol>
                  <OCol xs={12} xl={6}>
                    <OField htmlFor="cge-field" label="Código CGE">
                      <OTypography id="cge-field">{watchCge}</OTypography>
                    </OField>
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
          )}
          <SyncCpfConfirmationModal />
        </FormProvider>
      </DetailsTemplate>
    </FormTemplate>
  );
};
