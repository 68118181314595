import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";

interface RequestCorrectionStepButtonProps {
  diligenceStepId: number;
  reload: () => void;
}

export const RequestCorrectionStepButton = ({
  diligenceStepId,
  reload,
}: RequestCorrectionStepButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.requestCorrectionDiligenceStep,
  );

  const requestCorrectionDiligenceStep = useCallback(async () => {
    const { success, error } = await callService(diligenceStepId);
    if (!success)
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Não foi possível pendenciar o step",
      );
    else {
      OToastManager.success("Step pendenciado com sucesso");
      reload();
    }
  }, [callService, diligenceStepId, reload]);

  return (
    <LoadingButton loading={loading} onClick={requestCorrectionDiligenceStep}>
      Pendenciar
    </LoadingButton>
  );
};
