import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";

export const booksDataSource = dataSourceCustomStoreGenerator(
  async () => {
    return service.hubCreditManager.getBooks().catch(() => {
      throw new Error("Um erro ocorreu ao tentar buscar os books.");
    });
  },
  {
    customStoreOptions: {
      update: async (originalValues, updatedValues) => {
        try {
          await service.hubCreditManager.updateBook({
            bookId: originalValues.id,
            bookName: updatedValues.bookName || originalValues.bookName,
            bookCr: updatedValues.bookCr || originalValues.bookCr,
          });

          OToastManager.success("O book foi atualizado com sucesso.");
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao tentar atualizar o book. Por favor, tente novamente mais tarde.",
          );
        }
      },
      remove: async (data) => {
        try {
          await service.hubCreditManager.deleteBook({
            bookId: data.id,
          });
          OToastManager.success("O book foi removido com sucesso.");
        } catch (e) {
          OToastManager.danger(
            "Um erro ocorreu ao tentar remover o book. Por favor, tente novamente mais tarde.",
          );
        }
      },
    },
  },
);

export const booksGrid = {
  datagrid: {
    noDataText: "Nenhum book foi encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    editing: {
      allowUpdating: true,
      allowDeleting: true,
      confirmDelete: true,
    },
    paging: {
      pageSize: 10,
    },
    onRowUpdated: () => booksDataSource.reload(),
    onRowRemoved: () => booksDataSource.reload(),
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "bookCr",
      caption: "CR",
      alignment: "left",
    },
    {
      dataField: "bookName",
      caption: "Nome",
      alignment: "left",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubCreditManager.getBooks>>["data"]
  >[number]
>;
