import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NotaDisponivelCedenteField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.notaDisponivelCedente}
      name="notaDisponivelCedente"
      description="Dispara um e-mail para o fornecedor, no momento que a nota se torna disponível para antecipação"
    />
  );
};
