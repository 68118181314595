import {
  OButton,
  OCheckbox,
  OCheckboxGroup,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { disverbationApprovalConfirmationModalId } from "./disverbation-approvals-modal.utils";
import { DisverbationFunds } from "../../disverbation-approvals.utils";

interface ApprovalsModalGridProps {
  loading: boolean;
  disverbationFunds: DisverbationFunds[];
  sendDisverbatioFunds: () => void;
  selectedFunds: string[];
  setSelectedFunds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const FundsApprovalsModal = ({
  loading,
  disverbationFunds,
  sendDisverbatioFunds,
  selectedFunds,
  setSelectedFunds,
}: ApprovalsModalGridProps) => {
  return (
    <OModal
      id={disverbationApprovalConfirmationModalId}
      size="xs"
      position="center"
    >
      <OModalHeader closeButton>
        <OTypography tag="h2" size="lg" weight="normal">
          Aprovação de desaverbação.
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-2">
          <OTypography>
            Selecione os Fundos para Aprovar Desaverbações:
          </OTypography>
          <OCheckboxGroup
            dataAction="selected_funds:checkbox:funds"
            dataLabel="funds"
            value={selectedFunds}
            onInput={(event) => {
              const checkboxValue = (event.target as HTMLOCheckboxGroupElement)
                .value;
              Array.isArray(checkboxValue) && setSelectedFunds(checkboxValue);
            }}
          >
            {disverbationFunds.map((f) => (
              <div className="d-flex align-items-center gap-3 mb-1" key={f.Cge}>
                <OCheckbox id={f.Cge} size="sm" value={f.Cge} />
                <OLabel htmlFor={f.Cge}>{f.Name}</OLabel>
              </div>
            ))}
          </OCheckboxGroup>
        </div>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex justify-content-end gap-2">
          <OButton
            dataAction="aprovacao_recebiveis_modal:botao:cancelar"
            dataLabel="cancelar"
            outline
            onClick={() =>
              modalManager.hide(disverbationApprovalConfirmationModalId)
            }
          >
            Cancelar
          </OButton>
          <LoadingButton
            loading={loading}
            dataAction="aprovacao_recebiveis_modal:botao:confirmar"
            dataLabel="confirmar"
            type="tertiary"
            onClick={() => sendDisverbatioFunds()}
          >
            Aprovar Selecionados
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
