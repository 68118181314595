import { OToastManager, modalManager } from "@maestro/react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { GetCalendarResponse } from "services/legacys/models";
import { service } from "services/service";
import { CalendarFormFields } from "./calendars.types";

export const useCalendars = () => {
  const form = useForm<CalendarFormFields>({
    defaultValues: { name: "" },
  });
  const { handleSubmit, reset } = form;
  const gridRef = useGridRef();
  const [confirmDelete, setConfirmDelete] = useState<GetCalendarResponse>();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<GetCalendarResponse>(() =>
        service.legacys
          .getCustomCalendars()
          .then(({ data }) => data)
          .catch(() => {
            const errorMessage = "Erro ao buscar calendários personalizados";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [],
  );

  const onDelete = (calendar: GetCalendarResponse) => {
    setConfirmDelete(calendar);
    setTimeout(() => modalManager.show("confirm-remove-modal"), 500);
  };

  return {
    dataSource,
    gridRef,
    handleSubmit,
    reset,
    confirmDelete,
    setConfirmDelete,
    onDelete,
  };
};
