import { ODataGridGenerator } from "components/data-grid";
import { grid } from "./documents-card.grid";

interface DocumentsCardProps {
  documents: HubOnboardingRelationships.Guarantor.Document[];
}

export const DocumentsCard = ({ documents }: DocumentsCardProps) => {
  return <ODataGridGenerator grid={grid} dataSource={documents} />;
};
