import { ONotification, OTypography } from "@maestro/react";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const VeifyOptinPowersNotification = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const { hasRole } = useRoles();

  const { callService: postVerifyOptinPowers, value: verifyOptinPowers } =
    useServiceCall(service.onboardingRelationships.postVerifyOptinPowers);

  useEffect(() => {
    if (
      hasRole(
        roles.cadastro.customer
          .btgAccountsOptinCreateAccountsVerifyOptinPowersPage.role,
      )
    )
      postVerifyOptinPowers({
        taxId: identification,
      });
  }, [hasRole, identification, postVerifyOptinPowers]);

  const hasPowers =
    verifyOptinPowers?.status && verifyOptinPowers.status !== "SEM_PENDENCIAS";

  return hasPowers ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">{verifyOptinPowers.message}</OTypography>
        <StandardLink
          href={corporateRouter.routes.cadastro.customer.companyActs.path}
          role={roles.cadastro.customer.companyActsPage.role}
        >
          Detalhes
        </StandardLink>
      </div>
    </ONotification>
  ) : null;
};
