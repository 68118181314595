import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiAdminScoreModulesResponse } from "services/hubcreditmanager/models/responses";

interface DraggableScoreModulesProps {
  modules: GetApiAdminScoreModulesResponse | undefined;
  getScoreModules: () => Promise<unknown>;
}

export const DraggableScoreModules = ({
  modules,
  getScoreModules,
}: DraggableScoreModulesProps) => {
  const dragItem = useRef<number>(0);
  const dragOverItem = useRef<number>(0);

  const navigate = useNavigate();

  const updateModulesPositions = useCallback(async () => {
    if (!modules?.length) return;

    let modulesListAux = modules.map((m) => ({ ...m })); // .splice() e [...array] nao funcionam para array em um state
    const dragItemContent = modulesListAux[dragItem.current];

    modulesListAux.splice(dragItem.current, 1);
    modulesListAux.splice(dragOverItem.current, 0, dragItemContent);
    modulesListAux = modulesListAux.map((mod, index) => ({
      ...mod,
      order: index,
    }));

    if (modulesListAux[dragItem.current]?.id === modules[dragItem.current]?.id)
      return;

    try {
      await service.hubCreditManager.updateScoreModulesPositions({
        orders: modulesListAux.map((cl) => ({
          scoreModuleId: cl.id,
          order: cl.order,
        })),
      });

      await getScoreModules();
    } catch {
      OToastManager.danger("Não foi possível atualizar posições dos módulos");
    }
  }, [getScoreModules, modules]);

  return (
    <div className="d-flex flex-column gap-1">
      {modules?.map((mod, index) => {
        return (
          <OCard
            onDragStart={() => {
              dragItem.current = index;
            }}
            onDragEnter={() => {
              dragOverItem.current = index;
            }}
            onDragEnd={updateModulesPositions}
            draggable
            key={mod.id}
          >
            <OCardBody>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex gap-1 align-items-center">
                  <OTypography
                    tag="h4"
                    weight="normal"
                    size="lg"
                    type="dark"
                    key={mod.name}
                  >
                    {mod.name}
                  </OTypography>
                  <OButton
                    type="light"
                    onClick={() => {
                      navigate(
                        corporateRouter.routes.workflow.product.originatorScore.modules.edit.path(
                          {
                            scoreModuleId: mod.id,
                          },
                        ),
                      );
                    }}
                  >
                    <OIcon category="fal" icon="fa-pencil" />
                  </OButton>
                </div>
                <div className="d-flex flex-column gap-3">
                  <ORow>
                    <OCol xs={1}>
                      <OTypography>Tipo:</OTypography>
                    </OCol>
                    <OCol xs={11}>
                      <OTypography type="dark-60" key={mod.type}>
                        {mod.type}
                      </OTypography>
                    </OCol>
                  </ORow>
                  <ORow>
                    <OCol xs={1}>
                      <OTypography>Descrição:</OTypography>
                    </OCol>
                    <OCol xs={11}>
                      <OTypography type="dark-60" key={mod.description}>
                        {mod.description}
                      </OTypography>
                    </OCol>
                  </ORow>
                  <ORow>
                    <OCol xs={1}>
                      <OTypography>Visível?</OTypography>
                    </OCol>
                    <OCol xs={11}>
                      <OIcon
                        size="xl"
                        type="dark-60"
                        category="orq"
                        icon={mod.isVisible ? "orq-check" : "orq-close"}
                      />
                    </OCol>
                  </ORow>
                </div>
              </div>
            </OCardBody>
          </OCard>
        );
      })}
    </div>
  );
};
