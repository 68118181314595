import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useCallback } from "react";
import { service } from "services";
import { WorkflowErrorResponse } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { ProcessorsExecuting } from "./detalhes/workflow-lead-details.types";

export const useLeadApproval = () => {
  const evaluateLead = useCallback(
    async (approval: string, processorsExecuting?: ProcessorsExecuting) => {
      try {
        const processor = processorsExecuting?.find(
          (p) => p.processorType === "PENDENTE_APROVACAO",
        );

        if (!processor) return;

        await service.hubCreditManager.sendLeadApproval({
          processorContextId: processor?.workflowProcessorContextId,
          approval,
        });

        OToastManager.success("Análise enviada com sucesso.");
      } catch (error) {
        if (isAxiosError<WorkflowErrorResponse>(error)) {
          const message = error.response?.data?.failures?.[0]?.message;

          if (message) {
            OToastManager.danger({
              title:
                "A condição abaixo deve ser satisfeita para seguir com a aprovação:",
              message: `"${message}"`,
            });
          } else {
            OToastManager.danger(
              "Houve um erro ao enviar a aprovação da proposta.",
            );
          }
        }

        throw error;
      }
    },
    [],
  );
  return { evaluateLead };
};
