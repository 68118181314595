import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import { ErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { PostApiAdminTransactionCreateErrorResponse } from "services/hubcreditmanager/models/responses/error/post-api-admin-transaction-create-error.response";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { currencySymbol } from "utils/currency";
import {
  EmprestimosProdutoGestorLimitesById,
  EmprestimosProdutoGestorLimitesDetalhesTransacaoAdicionarRouteQueryParams,
} from "../../../../../../../routes/emprestimos.route-params";
import {
  AddTransactionFormValues,
  addTransactionFormValidationsSchema,
} from "./add-transaction-form.schemas";

export const AddTransactionPage = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  const { value: limitDetails, hasError } =
    useParseFromSearchParams<EmprestimosProdutoGestorLimitesDetalhesTransacaoAdicionarRouteQueryParams>(
      "data",
    );

  if (!id) throw new Error("No id parameter");

  const form = useForm<AddTransactionFormValues>({
    resolver: yupResolver(addTransactionFormValidationsSchema),
  });

  const { handleSubmit, setError } = form;

  const createTransaction = useCallback(
    async (values: AddTransactionFormValues) => {
      try {
        setLoading(true);

        await service.hubCreditManager.createTransaction({
          limitId: Number(limitDetails?.id),
          originator: "PORTAL_ADMIN",
          ...values,
          taxId: limitDetails?.customer.legalEntity.taxId ?? "",
          product: limitDetails?.creditLine.product ?? "",
          identification: limitDetails?.creditLine.identification ?? "",
        });

        OToastManager.success("Transação criada com sucesso");
      } catch (e) {
        if (isAxiosError<PostApiAdminTransactionCreateErrorResponse>(e)) {
          e.response?.data?.failures?.forEach((failure) => {
            switch (failure.errorCode) {
              case "CurrencyNotAllowed":
                return setError("currency", {
                  message: "Moeda não permitida.",
                });
              case "LimitNotAvailable":
                return setError("amount", {
                  message: `O valor é superior ao disponível (${masks.currency(
                    limitDetails?.availableAmount,
                    currencySymbol.BRL,
                  )})`,
                });
              default:
                OToastManager.danger("Houve um erro. Tente novamente.");
            }
          });
        }

        OToastManager.danger("Houve um problema. Tente novamente.");
      } finally {
        setLoading(false);
      }
    },
    [
      limitDetails?.availableAmount,
      limitDetails?.creditLine.identification,
      limitDetails?.creditLine.product,
      limitDetails?.customer.legalEntity.taxId,
      limitDetails?.id,
      setError,
    ],
  );

  return (
    <ContentTemplate
      hasError={hasError}
      errorComponent={<ErrorComponent messageTitle="Ocorreu um erro" />}
      noValue
      render={() => (
        <FormTemplate
          pageTitle={<PageTitle title="Adicionar Transação" />}
          actions={
            <LoadingButton
              loading={loading}
              dataAction="emprestimos_adicionar_debito:botao:adicionar"
              dataLabel="adicionar"
              onClick={handleSubmit(createTransaction)}
            >
              Adicionar
            </LoadingButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <ORFieldInput
                    tag="number"
                    id="amount"
                    name="amount"
                    dataAction="emprestimos_adicionar_debito:text:valor"
                    dataLabel="valor"
                    label="Valor"
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    id="externalId"
                    name="externalId"
                    dataAction="emprestimos_adicionar_debito:text:id_operacao"
                    dataLabel="id_operacao"
                    label="Id da operação"
                  />
                </OCol>
              </ORow>
              <ORow>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    id="reason"
                    name="reason"
                    dataAction="emprestimos_adicionar_debito:text:motivo"
                    dataLabel="motivo"
                    label="Motivo"
                  />
                </OCol>
                <OCol>
                  <ORFieldSelect
                    id="currency"
                    name="currency"
                    dataAction="emprestimos_convenios_form:select:moeda"
                    dataLabel="moeda"
                    label="Moeda"
                  >
                    {["BRL", "USD", "EUR"].map((option) => (
                      <OOption key={option} value={option}>
                        {option}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
              </ORow>
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
      )}
    />
  );
};
