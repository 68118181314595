import {
  ICategory,
  OCol,
  OIcon,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { getOutputComponentsIds } from "../../processor-config-monitoring.utils";
import { useProcessorOutputsComponent } from "./processor-outputs.hook";
import { ProcessorOutputsComponentProps } from "./processor-outputs.types";

export const ProcessorOutputsComponent = (
  props: ProcessorOutputsComponentProps,
) => {
  const { workflowConfigView } = props;

  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const { bigNumbers, configurableStatus } =
    useProcessorOutputsComponent(props);

  return (
    <div key={workflowConfigView.workflowConfigView.workflowConfigViewId}>
      {workflowConfigView.processorOutputConfig?.map((output) => {
        const { key, type, status, bigNumber } = getOutputComponentsIds(
          workflowConfigView.workflowConfigView,
          output.id,
        );

        const viewId =
          workflowConfigView.workflowConfigView.workflowConfigViewId;
        const statusKey = `${viewId}_${status}_${configurableStatus
          ?.map((s) => s.id)
          .join("")}`;
        const bigNumberKey = `${viewId}_${bigNumber}-${bigNumbers
          ?.map((bn) => bn.id)
          .join("")}`;

        return (
          <ORow key={key}>
            <OCol sm={4}>
              <ORFieldInput
                id={type}
                name={type}
                tag="text"
                label="Tipo da saída"
                labelSize="md"
                readonly
              />
            </OCol>

            <OCol sm={4}>
              <ORFieldSelect
                id={status}
                name={status}
                label="Status"
                labelSize="md"
                key={statusKey}
              >
                <OOption value={0} key={`${statusKey}_${0}`}>
                  <div className="d-flex gap-2">
                    <OTypography>Nenhum</OTypography>
                  </div>
                </OOption>

                {configurableStatus?.map((s) => (
                  <OOption value={s.id} key={`${statusKey}_${s.id}`}>
                    <div className="d-flex gap-2">
                      <OTypography>{s.name}</OTypography>
                      <OIcon
                        category={s.iconName.split("-")[0] as ICategory}
                        icon={s.iconName}
                        size="md"
                      />
                    </div>
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>

            <OCol sm={4}>
              <ORFieldSelectMultiple
                id={bigNumber}
                name={bigNumber}
                label="Big numbers"
                labelSize="md"
                key={bigNumberKey}
              >
                {bigNumbers?.map((bn) => (
                  <OOptionMultiple
                    value={bn.id.toString()}
                    key={`${key}${bn.id}`}
                  >
                    <div className="d-flex gap-2">
                      <OTypography>{bn.name}</OTypography>
                      <OIcon
                        category={bn.iconName.split("-")[0] as ICategory}
                        icon={bn.iconName}
                        size="md"
                      />
                    </div>
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </OCol>
          </ORow>
        );
      })}
    </div>
  );
};
