import {
  OButton,
  OCard,
  OCardBody,
  OInputDate,
  OLabel,
  ORadio,
  ORadioGroup,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { buildGrid } from "./renegociacao-condicoes.grid";
import {
  InstallmentOption,
  PaymentOption,
  RenegotiationSimulation,
} from "./renegociacao-condicoes.types";

export interface PaymentRadioGroupProps {
  title: string;
  paymentOptions: PaymentOption[];
  chosenPayment: PaymentOption | undefined;
  changePaymentOption: (
    value: React.SetStateAction<PaymentOption | undefined>,
  ) => void;
}

export const RenegociacoesConditions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contracts, setContracts] = useState<string[]>([]);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [chosenDate, setChosenDate] = useState<string>();
  const [simulation, setSimulation] = useState<RenegotiationSimulation>();
  const [selectedOffer, setSelectedOffer] = useState<InstallmentOption>();
  const [firstPaymentMethod, setFirstPaymentMethod] = useState<PaymentOption>();
  const [installmentsPaymentMethod, setInstallmentsPaymentMethod] =
    useState<PaymentOption>();

  const PaymentRadioGroup = useCallback(
    ({
      title,
      paymentOptions,
      chosenPayment,
      changePaymentOption,
    }: PaymentRadioGroupProps) => {
      return (
        <div>
          <OTypography type="dark" weight="bold">
            {title}
          </OTypography>
          <ORadioGroup
            dataAction={`condicoes_renegociacao:radio:${title}`}
            dataLabel={title}
            className="d-flex flex-row gap-3"
          >
            {paymentOptions.map((option) => {
              return (
                <div key={option.id} className="d-flex flex-row gap-2">
                  <ORadio
                    id={`first-payment-${option.id}`}
                    value={option.description}
                    size="xs"
                    checked={chosenPayment?.id === option.id}
                    onInput={() => changePaymentOption(option)}
                  />
                  <OLabel htmlFor={`first-payment-${option.id}`} type="dark">
                    {option.description}
                  </OLabel>
                </div>
              );
            })}
          </ORadioGroup>
        </div>
      );
    },
    [],
  );

  const fetchAvailableDates = useCallback(async () => {
    const stringifiedContracts: string[] = searchParams.getAll("contract");
    setContracts(stringifiedContracts);

    const dates = await service.renegotiation
      .postContractAvailabilityDates({ contracts: stringifiedContracts })
      .then(({ data }) => data)
      .catch((err) => {
        const errorMessage = "Combinação de contratos sem elegibilidade";
        OToastManager.danger(errorMessage);
        throw new Error(err);
      });

    setAvailableDates(dates);
  }, [searchParams]);

  const fetchOffers = useCallback(
    async (isoDate: string) => {
      setLoading(true);
      setHasError(false);
      const simulationData = await service.renegotiation
        .postSimulate({
          contracts,
          first_payment_date: isoDate,
        })
        .then(({ data }) => data)
        .catch((err) => {
          setHasError(true);
          const errorMessage = "Erro ao buscar as condições de renegociação";
          OToastManager.danger(errorMessage);
          throw new Error(err);
        })
        .finally(() => setLoading(false));
      setSimulation(simulationData);
    },
    [contracts],
  );

  const handleChosenDate = (isoDate: string) => {
    setChosenDate(isoDate);
    fetchOffers(isoDate);
  };

  const submitAgreement = async () => {
    setLoading(true);
    try {
      const payload = {
        first_payment_method_id: firstPaymentMethod?.id ?? 1,
        installments_count: selectedOffer?.installments_count ?? 1,
        installments_payment_method_id: installmentsPaymentMethod?.id ?? 1,
        simulation_id: simulation?.id ?? "",
      };

      await service.renegotiation.postAgreement(payload);

      OToastManager.success("Renegociação registrada com sucesso!");
      navigate(-1);
    } catch (err) {
      OToastManager.danger("Erro ao efetuar a renegociação");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableDates();
  }, [fetchAvailableDates]);

  const memoizedGrid = useMemo(
    () => buildGrid({ selectedOffer, setSelectedOffer }),
    [selectedOffer],
  );

  return (
    <>
      <PageTitle title="Condições" />
      <OCard className="mb-4">
        <OCardBody>
          <OTypography>Dia do primeiro pagamento</OTypography>
          <OInputDate
            allowClear
            size="md"
            disabled={availableDates.length === 0}
            onChange={(dt) =>
              handleChosenDate(dt?.toISOString() ?? new Date().toISOString())
            }
            disabledDate={(dt) => {
              const isoDate = dt.format("YYYY-MM-DD");
              return !availableDates.includes(isoDate);
            }}
          />
        </OCardBody>
      </OCard>
      <GridTemplate
        actions={
          chosenDate && (
            <RefreshGridButton onClick={() => fetchOffers(chosenDate)} />
          )
        }
      >
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          value={simulation}
          render={(_simulation) => (
            <>
              <ODataGridGenerator
                grid={memoizedGrid}
                dataSource={_simulation.installment_options}
              />
              <div className="d-flex flex-row justify-content-between my-4">
                <PaymentRadioGroup
                  title="Método do primeiro pagamento"
                  paymentOptions={simulation?.first_payment_options ?? []}
                  chosenPayment={firstPaymentMethod}
                  changePaymentOption={setFirstPaymentMethod}
                />
                <PaymentRadioGroup
                  title="Método de pagamento"
                  paymentOptions={
                    simulation?.installments_payment_options ?? []
                  }
                  chosenPayment={installmentsPaymentMethod}
                  changePaymentOption={setInstallmentsPaymentMethod}
                />
              </div>
              <div className="d-flex justify-content-end mb-4">
                <OButton
                  dataAction="condicoes_renegociacao:botao:confirmar"
                  dataLabel="confirmar"
                  disabled={
                    !(
                      selectedOffer &&
                      firstPaymentMethod &&
                      installmentsPaymentMethod
                    )
                  }
                  onClick={() => submitAgreement()}
                >
                  <OTypography type="light">Confirmar</OTypography>
                </OButton>
              </div>
            </>
          )}
        />
      </GridTemplate>
    </>
  );
};
