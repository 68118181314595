import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Receivable } from "./receivable-details.types";

export const dataSource = dataSourceODataStoreGenerator<Receivable>(
  endpoints.quickfin.odata.monitorDiario,
);

export const receivableDetailsGrid: ODataGridGeneratorConfig<Receivable> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    height: 900,
  },
  columns: [
    {
      dataField: "DataRecebivel",
      dataType: "datetime",
      format: "shortDate",
      caption: "Data recebível",
      allowHeaderFiltering: false,
    },
    {
      dataField: "Id",
      dataType: "number",
      caption: "Recebivel Id",
      cellRender: ({ data }) => <StandardLink href="#">{data.Id}</StandardLink>,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Id",
      dataType: "number",
      caption: "Trade Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "CedenteNome",
      dataType: "string",
      caption: "Nome do cedente",
      allowHeaderFiltering: false,
    },
    {
      dataField: "CedenteCnpj",
      dataType: "string",
      caption: "CNPJ do cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "SacadoNome",
      dataType: "string",
      caption: "Nome do sacado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "SacadoCnpj",
      dataType: "string",
      caption: "CNPJ do sacado",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Estado",
      dataType: "string",
      caption: "Estado",
    },
    {
      dataField: "Bandeira",
      dataType: "string",
      caption: "Bandeira",
    },
    {
      dataField: "DataVencimento",
      dataType: "datetime",
      format: "shortDate",
      caption: "Data vencimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorVencimentoRecebivel",
      dataType: "number",
      caption: "Valor vencimento recebível",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorVencimentoTrade",
      dataType: "number",
      caption: "Valor vencimento trade",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorDesembolsoTrade",
      dataType: "number",
      caption: "Valor desembolso trade",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "TipoTrade",
      dataType: "string",
      caption: "Tipo trade",
    },
    {
      dataField: "StatusTrade",
      dataType: "string",
      caption: "Status trade",
    },
    {
      dataField: "TaxaMensal",
      dataType: "number",
      caption: "Taxa mensal trade",
      format: (value) => masks.percentage(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "TaxaTotal",
      dataType: "number",
      caption: "Taxa total trade",
      format: (value) => masks.percentage(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "Posicao",
      dataType: "number",
      caption: "Posicao trade",
      format: (value) => value.toLocaleString("pt-br"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "IdentOp",
      dataType: "string",
      caption: "Ident Op",
      allowHeaderFiltering: false,
    },
    {
      dataField: "TradeIdCros",
      dataType: "number",
      caption: "Trade Id Cros",
      allowHeaderFiltering: false,
    },
    {
      dataField: "ExternalId",
      dataType: "string",
      caption: "External Id",
      allowHeaderFiltering: false,
    },
  ],
};
