import { masks } from "@maestro/utils";
import { GetOdataReceivablesSalesforceODataResponse } from "services/quickfin/models";
import { percent4DigitsMask } from "utils/mask";
import { ODataGridGeneratorConfig } from "components/data-grid";

export const grid: ODataGridGeneratorConfig<
  NonNullable<GetOdataReceivablesSalesforceODataResponse["value"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhum recebível encontrado.",
    pager: {
      infoText: "Página {0} de {1} ({2} recebíveis)",
    },
  },
  columns: [
    {
      dataField: "Identification",
      caption: "Nº da Nota",
    },
    {
      dataField: "MaturityDate",
      caption: "Vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "DiasCorridos",
      caption: "Dias corridos",
    },
    {
      dataField: "MaturityAmount",
      caption: "Valor de face (R$)",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "Price",
      caption: "Valor a Receber (R$)",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "TaxaMensal",
      caption: "Taxa mensal",
      format: (value) => percent4DigitsMask(value),
    },
  ],
};
