import { Navigate } from "react-router-dom";
import { routes as globalRoutes } from "routes/routes";
import { Routes } from "routes/routes.types";
import { useRedirectWithRoles } from "./redirect-with-roles.hook";

interface RedirectWithRolesProps {
  routes?: Routes;
}

export const RedirectWithRoles = ({
  routes = globalRoutes,
}: RedirectWithRolesProps) => {
  const redirectPath = useRedirectWithRoles(routes) ?? "/nao-autorizado";

  return <Navigate to={redirectPath} replace />;
};
