import { OLoader, OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { CalendarFormFields, OffDaysForm } from "../../calendars.types";
import { CalendarFields } from "../calendar-fields";
import { CalendarOffDays } from "../calendar-offdays/calendar-offdays.component";

interface CalendarFormProps {
  isUpdate?: boolean;
}

export const CalendarForm: React.FC<CalendarFormProps> = ({ isUpdate }) => {
  const {
    loading: loadingBaseCalendars,
    value: baseCalendars,
    callService: getBaseCalendars,
  } = useServiceCall(service.legacys.getBaseCalendars);
  const { value: baseCalendarSelected, callService: getBaseCalendarById } =
    useServiceCall(service.legacys.getBaseCalendarById);
  const { watch, getValues, setValue } = useFormContext<CalendarFormFields>();
  const baseCalendarIdWatch = watch("baseCalendarId");

  const loadBaseCalendarById = useCallback(async () => {
    if (baseCalendarIdWatch) {
      const { response, success } = await getBaseCalendarById(
        baseCalendarIdWatch,
      );
      if (!success)
        return OToastManager.danger(
          "Não foi buscar o calendário, tente novamente mais tarde!",
        );

      if (response) {
        const { data } = response;
        const baseOffDays = data.offDays?.map((bod) => ({
          name: `Calendário ${data.name}`,
          date: new Date(bod),
          isBase: true,
        })) as OffDaysForm[];
        const { offDays: currentOffDays } = getValues();
        const currentOffDaysParsed = currentOffDays
          .filter((od) => !od.isBase)
          .map((od) => {
            return {
              ...od,
              date: new Date(od.date),
            };
          });

        setValue(
          "offDays",
          baseOffDays
            ? [...baseOffDays, ...currentOffDaysParsed]
            : currentOffDaysParsed,
        );
      }
    }
  }, [baseCalendarIdWatch, getBaseCalendarById, getValues, setValue]);

  useEffect(() => {
    loadBaseCalendarById();
  }, [loadBaseCalendarById]);

  useEffect(() => {
    getBaseCalendars();
  }, [getBaseCalendars]);

  return (
    <CardTemplate>
      {baseCalendars && (
        <CalendarFields baseCalendars={baseCalendars} nameDisabled={isUpdate} />
      )}
      <CalendarOffDays baseCalendarSelected={baseCalendarSelected} />
      {loadingBaseCalendars && <OLoader absolute backdrop />}
    </CardTemplate>
  );
};
