export const dataUpdateStatusMap = {
    INDEFINIDO: {
      text: "Indefinido",
      type: "danger",
      icon: "fa-question-circle",
    },
    INICIADO: {
      text: "Atualização Cadastral Iniciado",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    PENDENTE_ANALISE_DE_FRAUDE: {
      text: "Em análise antifraude",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    REPROVADO_POR_FRAUDE: {
      text: "Reprovado após análise antifraude",
      type: "danger",
      icon: "fa-times-circle",
    },
    ENVIADO_PARA_ANALISE_DE_ONBOARDING: {
      text: "Enviado para Onboarding",
      type: "success",
      icon: "fa-check-circle",
    },
    EM_ANALISE_DE_AML: {
      text: "Em análise de AML",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    REPROVADO_POR_AML: {
      text: "Reprovado por AML",
      type: "danger",
      icon: "fa-times-circle",
    },
    EM_ANALISE_DE_ONBOARDING: {
      text: "Em análise de Onboarding",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    PENDENTE_AJUSTE_APONTADO_POR_ONBOARDING: {
      text: "Pendente correções apontadas por Onboarding",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    REPROVADO_POR_ONBOARDING: {
      text: "Reprovado após análise de Onboarding",
      type: "danger",
      icon: "fa-times-circle",
    },
    PENDENTE_ASSINATURA: {
      text: "Pendente assinatura do kit de atualização cadastral",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    CONCLUIDA: {
      text: "Atualização concluída",
      type: "success",
      icon: "fa-check-circle",
    },
    DEFAULT: {
      text: null,
      type: "warning",
      icon: "fa-question-circle",
    },
    SOB_ANALISE: {
      text: "Em análise",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    PENDENTE_ASSINATURA_PRE_LESS: {
      text: "Pendente assinatura do kit de atualização cadastral",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    NAO_AUTORIZADO: {
      text: "Usuario nao autorizado",
      type: "danger",
      icon: "fa-question-circle",
    },
    GERANDO_CONTRATO_PRE_LESS: {
      text: "Gerando contrato",
      type: "warning",
      icon: "fa-times-circle",
    },
    EDITADO: {
      text: "Usuário realizou edições em suas informações",
      type: "warning",
      icon: "fa-question-circle",
    },
    PENDENTE_ASSINATURA_POS_LESS: {
      text: "Pendente assinatura do kit de atualização cadastral",
      type: "warning",
      icon: "fa-exclamation-circle",
    },
    CANCELADO: {
      text: "Atualização cancelada",
      type: "success",
      icon: "fa-check-circle",
    },
  } as const;