import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import {
  scoreModuleFormDefaultValues,
  scoreModuleFormValidationSchema,
} from "../../modulos/_compose/score-module-form.schema";
import { ScoreBenefitForm } from "../_compose/score-benefit-form.component";

export const AddScoreBenefitPage = () => {
  const form = useForm({
    resolver: yupResolver(scoreModuleFormValidationSchema),
    defaultValues: scoreModuleFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Adicionar benefício" />}>
      <FormProvider {...form}>
        <ScoreBenefitForm mode="add" />
      </FormProvider>
    </FormTemplate>
  );
};
