import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { formatAddress } from "../../../../../card-hiring.utils";
import { cardHiringFields } from "../../card-hiring-form.form";

interface AddressIdFieldProps {
  data?: BankingHubCards.Address[];
  loading: boolean;
}

export const AddressIdField = ({ data, loading }: AddressIdFieldProps) => {
  const { watch } = useFormContext();

  const valueWatcher = watch("addressId");

  const selectKey = useMemo(() => {
    return `${valueWatcher} - ${data?.length}`;
  }, [valueWatcher, data]);

  return (
    <OCol xs={12} md={12} align="center">
      <ORFieldSelect
        {...cardHiringFields.addressId}
        key={selectKey}
        readonly={!data?.length}
      >
        {data?.map((address) => (
          <OOption key={address.id} value={address.id}>
            {formatAddress(address)}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
    </OCol>
  );
};
