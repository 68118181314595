import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";

export interface RemoveCollateralConfirmationModalProps {
  collateralId: number;
}

export const RemoveCollateralConfirmationModal = ({
  collateralId,
}: RemoveCollateralConfirmationModalProps) => {
  const { callService: removeCollateral, loading: removeLoading } =
    useServiceCall(service.hubCreditManager.removeCollateral);

  async function onConfirm() {
    if (!collateralId) return;

    const { success } = await removeCollateral(collateralId);

    if (success) {
      OToastManager.success("Garantia removida com sucesso");
      modalManager.hide(`remove-collateral-modal-${collateralId}`);
    } else OToastManager.danger("Houve um problema. Tente novamente.");
  }

  return (
    <OConfirmationModal
      modalId={`remove-collateral-modal-${collateralId}`}
      title="Remover garantia"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      size="sm"
      loading={removeLoading}
      onConfirm={() => onConfirm()}
    >
      <p>Tem certeza que deseja remover a garantia do limite do cliente?</p>
    </OConfirmationModal>
  );
};
