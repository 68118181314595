import { MappedStatus } from "./status-maps.types";

export const energyGuarantorStatusMap = {
  INEXISTENTE: { type: "info", text: "Inexistente" },
  INDEFINIDO: { type: "info", text: "Indefinido" },
  PENDENTE_DADOS: { type: "warning", text: "Pendente dados" },
  PENDENTE_BIOMETRIA: { type: "warning", text: "Pendente biometria" },
  PENDENTE_AJUSTE: { type: "warning", text: "Pendente ajuste" },
  EM_ANALISE: { type: "info", text: "Em análise" },
  CONCLUIDO: { type: "success", text: "Concluído" },
  REPROVADO: { type: "danger", text: "Reprovado" },
} satisfies Record<HubEnergy.EGuarantorStatus, MappedStatus>;
