/* eslint-disable import-rules/imports-in-modules */
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { DetailsCard } from "components/details-card";
import { useParams } from "react-router-dom";
import { AppIdentificationRouteParams } from "pages/developers/routes/developers.route-params";
import { useCustomer } from "contexts/customer";
// import { ContentTemplate } from "templates/content-template";
// import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useEffect } from "react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { buildAppDetailsFields } from "./detalhes-app.details";
import { useAppDetails } from "./detalhes-app.hook";

export const AppDetails = () => {
  const { appId } = useParams<AppIdentificationRouteParams>();
  if (!appId) throw new Error("No identification");
  const {
    customer: { identification },
  } = useCustomer();
  const { getApp, app, loading, hasError } = useAppDetails();
  // getApp(appId, identification);

  useEffect(() => {
    getApp(appId, identification);
  }, [appId, identification, getApp]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do app" />}>
      <DetailsCard
        loading={loading}
        hasError={hasError}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar os detalhes da operação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getApp(appId, identification)} />
          </ErrorComponent>
        }
        fields={buildAppDetailsFields}
        value={app}
      />
    </DetailsTemplate>
  );
};
