import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ACCPreticketByIdRouteParams } from "../../../../routes/acc.route-params";
import { adminCustomerAccSettlementDetailsGenerator } from "./admin-customer-acc-settlements.details";

export const AdminCustomerACCSettlementDetailsPage = () => {
  const { id } = useParams<ACCPreticketByIdRouteParams>();
  const { hasRole } = useRoles();

  if (!id) throw new Error("No id");

  const {
    value: preTicketDetails,
    callService: getAccPreticketById,
    hasError: hasErrorPreTicketDetails,
    loading: loadingPreTicketDetails,
  } = useServiceCall(service.acc.adminCustomer.getAccPreticketById);

  useEffect(() => {
    getAccPreticketById(id);
  }, [getAccPreticketById, id]);

  return (
    <DetailsTemplate
      actions={
        hasRole(roles.acc.customer.simulationDetailsPage.role) &&
        !!preTicketDetails?.response.isValid ? (
          <StandardLink
            href={corporateRouter.routes.acc.customer.settlements.details.simulate.path(
              {
                id,
                preTicketId: Number(preTicketDetails?.response.id),
              },
            )}
            size="lg"
            type="default"
          >
            Simular
          </StandardLink>
        ) : undefined
      }
      pageTitle={<PageTitle title="Detalhes da liquidação" />}
    >
      <ContentTemplate
        loading={loadingPreTicketDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da liquidação."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getAccPreticketById(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorPreTicketDetails}
        value={preTicketDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccSettlementDetailsGenerator}
            />

            {value.response.orderConciliationId && (
              <DetailsCard
                value={value.response}
                fields={(preTicket) => {
                  const { flowType, buyCurrencyType, sellCurrencyType } =
                    preTicket;

                  const currency =
                    flowType === "INTERNACAO"
                      ? sellCurrencyType
                      : buyCurrencyType;

                  return {
                    order: {
                      title: hasRole(
                        roles.acc.customer.orderDetailsPage.role,
                      ) ? (
                        <StandardLink
                          href={corporateRouter.routes.acc.customer.settlements.details.order.path(
                            {
                              id,
                              orderId: value.response.orderConciliationId,
                            },
                          )}
                          size="lg"
                          type="default"
                        >
                          Ordem
                        </StandardLink>
                      ) : undefined,
                      items: [
                        {
                          label: "Id",
                          value: value.response.orderConciliationId,
                          grid: { md: 4, xl: 3 },
                        },
                        {
                          label: "Moeda",
                          value: currency,
                          grid: { md: 4, xl: 3 },
                        },
                        {
                          label: "Pagador",
                          value: preTicket.accSettlement.payer,
                          grid: { md: 4, xl: 3 },
                        },
                      ],
                    },
                  };
                }}
              />
            )}
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
