import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { AddressForm } from "./address.form";

export const useAddressForm = () => {
  const { callService, loading } = useServiceCall(
    service.onboarding.getAddress,
  );
  const { watch, setError, getFieldState, setValue } =
    useFormContext<AddressForm>();

  const zipCodeWatch = watch("address.zipCode");

  const getAddressFromZipCode = useCallback(async () => {
    const { success, response } = await callService({ zipCode: zipCodeWatch });

    if (success) {
      setValue("address.city", response.data.cityName.toUpperCase());
      setValue(
        "address.neighborhood",
        response.data.neighborhood.toUpperCase(),
      );
      setValue("address.state", response.data.stateCode.toUpperCase());
      setValue("address.street", response.data.streetName.toUpperCase());
    } else {
      OToastManager.danger("Erro ao buscar o CEP");
    }
  }, [callService, setValue, zipCodeWatch]);

  useEffect(() => {
    if (
      zipCodeWatch?.length === 8 &&
      getFieldState("address.zipCode").isDirty
    ) {
      if (validators.cep(zipCodeWatch)) {
        getAddressFromZipCode();
      } else {
        setError("address.zipCode", { message: "CEP inválido" });
      }
    }
  }, [getAddressFromZipCode, getFieldState, setError, zipCodeWatch]);

  return { loading };
};
