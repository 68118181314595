import {
  OCard,
  OCardBody,
  OCardHeader,
  OLoader,
  OTypography,
} from "@maestro/react";
import { useWorkflowFormTemplate } from "../../../../../../../components/hooks/use-workflow-form-template.context";
import { DraggableCards } from "../draggable-cards/draggable-cards.component";
import { DraggableSteps } from "../draggable-steps/draggable-steps.component";

export const FormStructureComponent = () => {
  const {
    selectedStep,
    form: { loading, value: workflowFormTemplate },
  } = useWorkflowFormTemplate();

  return (
    <OCard>
      <OCardHeader>
        <OTypography type="dark" tag="h3" size="lg">
          Estrutura do formulário
        </OTypography>
      </OCardHeader>
      <OCardBody className="position-relative">
        {loading && <OLoader absolute />}
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-1">
            <DraggableSteps />

            {!!selectedStep && <DraggableCards />}

            {!selectedStep && !!workflowFormTemplate?.steps?.length && (
              <OTypography className="mt-4">
                Selecione um dos passos para visualizar os cards e os
                componentes.
              </OTypography>
            )}
          </div>
        </div>
      </OCardBody>
    </OCard>
  );
};
