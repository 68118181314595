import { OToastManager } from "@maestro/core";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { agreementsDetailsGenerator } from "./operations-agreements.details";

export const OperationsAgreementsPage = () => {
  const [loading, setLoading] = useState(false);

  const {
    callService: getArrangementId,
    value: partnerAgreement,
    hasError,
  } = useServiceCall(service.hubLoan.getArrangementId);

  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  if (!id) throw new Error("No id");

  const getPartnerAgreement = useCallback(
    async (loanId: string) => {
      try {
        setLoading(true);
        const { data: loanData } = await service.hubLoan.getLoanDetails(loanId);

        getArrangementId(loanData.partnerChannelAgreementId);
      } catch {
        OToastManager.danger("Houve um erro. Tente novamente.");
      } finally {
        setLoading(false);
      }
    },
    [getArrangementId],
  );

  useEffect(() => {
    getPartnerAgreement(id);
  }, [getPartnerAgreement, id]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Convênio" />}
      actions={
        <>
          {partnerAgreement?.agreement?.identification && (
            <RouterButton
              href={`${corporateRouter.routes.emprestimos.product.agreements.details.edit.path(
                {
                  id: partnerAgreement.agreement.id,
                },
              )}?data=${encodeURIComponent(
                JSON.stringify(partnerAgreement.agreement),
              )}`}
              role={roles.emprestimos.product.agreementsDetailsEditPage.role}
            >
              Detalhes
            </RouterButton>
          )}
        </>
      }
    >
      <DetailsCard
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar convênios."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getPartnerAgreement(id)} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={partnerAgreement}
        fields={agreementsDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
