import { DetailsCard } from "components/details-card";
import { NoteDetailProps } from "./note-detail.types";
import { NoteDetailsGenrator } from "./note-detail.details";

export const NoteDetail = ({ summary, loading }: NoteDetailProps) => {
  return (
    <DetailsCard
      fields={NoteDetailsGenrator}
      value={summary}
      loading={loading}
    />
  );
};
