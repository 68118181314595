import { ORFieldInputDate } from "@maestro/react";
import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";

export const diligenceFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
  editMigrationDate = true,
): DetailsFields => ({
  consumerUnit: {
    items: [
      {
        label: "Razão social",
        value: consumerUnit.entity.officialName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(consumerUnit.entity.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={consumerUnit.entity.taxId}
              toasterMessage="CPNJ copiado"
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data de migração",
        grid: { md: 6, lg: 4 },
        value: editMigrationDate ? (
          <ORFieldInputDate size="md" id="migrationDate" name="migrationDate" />
        ) : (
          dayjs(consumerUnit.migrationDate).format("DD/MM/YYYY")
        ),
      },
      {
        label: "Distribuidora Vinculada",
        value: `${
          consumerUnit.currentDistributor?.name ??
          consumerUnit.currentDistributor?.officialName
        } - ${masks.cpfCnpj(consumerUnit.currentDistributor?.taxId)}`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Número de instalação",
        value: consumerUnit.installationNumber,
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
