import styled, { css } from "styled-components";

interface MenuLinkProps {
  $active?: boolean;
  $disabled?: boolean;
}

export const MenuLink = styled.a<MenuLinkProps>`
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--font-xs) var(--font-md);
  border-radius: var(--border-radius-xxs);
  color: var(--theme-dark);

  transition: all ease 0.3s;

  &:focus-visible {
    outline: 2px solid var(--theme-focus);
  }

  &:hover {
    background-color: var(--theme-info-hover);
    color: var(--theme-info-inverse);
  }

  ${({ $active }) =>
    $active
      ? css`
          background-color: var(--theme-info);
          color: var(--theme-info-inverse);
        `
      : ""}

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: var(--disabled-opacity);
          cursor: not-allowed;
          background-color: unset;
        `
      : ""}
`;
