import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../../routes/workflow.route-params";
import { WorkflowConfigViewForm } from "../_compose/workflow-config-view-form.component";
import {
  workflowConfigViewFormValidationSchema,
  workflowConfigViewFormDefaultValues,
  WorkflowConfigViewFormValues,
} from "./add-workflow-config-view.schema";

export const AddWorkflowConfigView = () => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const form = useForm({
    resolver: yupResolver(workflowConfigViewFormValidationSchema),
    defaultValues: workflowConfigViewFormDefaultValues,
  });

  const { handleSubmit } = form;

  const navigate = useNavigate();

  const {
    value: views,
    callService: getViews,
    loading: loadingGetViews,
    hasError: hasErrorGetViews,
  } = useServiceCall(service.hubCreditManager.getViews);

  const {
    callService: createWorkflowConfigView,
    loading: loadingCreateWorkflowConfigView,
  } = useServiceCall(service.hubCreditManager.createWorkflowConfigView);

  const addWorkflowConfigView = useCallback(
    async ({ viewId }: WorkflowConfigViewFormValues) => {
      const { success } = await createWorkflowConfigView({
        viewId,
        workflowConfigId: Number(id),
      });

      if (success) {
        OToastManager.success(
          "Configuração de visualização adicionada com sucesso.",
        );

        navigate(
          corporateRouter.routes.workflow.product.workflowsConfig.details.edit.views.path(
            { id },
          ),
        );
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar criar a configuração de visualização. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [createWorkflowConfigView, id, navigate],
  );

  useEffect(() => {
    getViews();
  }, [getViews]);

  useEffect(() => {
    hasErrorGetViews &&
      OToastManager.danger("Um erro ocorreu ao tentar buscar as views.");
  }, [hasErrorGetViews]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar configuração de visualização" />}
      actions={
        <LoadingButton
          loading={loadingGetViews || loadingCreateWorkflowConfigView}
          onClick={handleSubmit(addWorkflowConfigView)}
          dataAction="adicionar-oferta:botao:adicionar"
          dataLabel="adicionar"
        >
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <WorkflowConfigViewForm viewOptions={views} />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
