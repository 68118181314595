import { useCustomerSelector } from "../../context";
import { DropdownItem } from "../dropdown-item/dropdown-item.component";
import { EmptyDropdown } from "../empty-dropdown";
import { LoadingDropdown } from "../loading-dropdown";
import { MaxHeightODropdownList } from "./dropdown-list.styles";

export const DropdownList = () => {
  const { loading, options, value } = useCustomerSelector();

  const showEmptyDropdown = options.length === 0 && value && !loading;

  const hiddenClass =
    options.length === 0 && !showEmptyDropdown && !loading
      ? "dropdown-hidden"
      : undefined;

  return (
    <MaxHeightODropdownList className={hiddenClass}>
      {/* Div to avoid rendering out of order (stencil bug) */}
      <div style={{ display: "contents" }}>
        {options.map((item) => (
          <DropdownItem
            key={`${item.officialName}${item.identification}`}
            item={item}
          />
        ))}
        {showEmptyDropdown && <EmptyDropdown />}
        {loading && <LoadingDropdown />}
      </div>
    </MaxHeightODropdownList>
  );
};
