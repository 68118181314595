import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { HttpStatusCode, isAxiosError } from "axios";
import { CustomerType } from "contexts/customer/types/customer.type";
import { service } from "services";

export const getCustomerEmails = (customer: CustomerType) => {
  const mainMail = customer.email;
  const extractedEmails = customer.users.flatMap(({ email }) => {
    return [["receiver", "=", email], "or"];
  });
  extractedEmails.push(["receiver", "=", mainMail]);
  return extractedEmails;
};

export const downloadEmail = async (file_key: string) => {
  try {
    const response = await service.raven.downloadEmail({ file_key });
    const blob = new Blob([response.data], { type: "plain/text" });
    helpers.downloadBlobFile(file_key, blob);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response?.status === HttpStatusCode.NotFound) {
        OToastManager.danger("E-mail não encontrado.");
      } else if (response?.status === HttpStatusCode.Unauthorized) {
        OToastManager.danger("Você não tem permissão para baixar e-mails.");
      } else {
        OToastManager.danger("Aconteceu um erro ao baixar o e-mail.");
      }
    }
  }
};
