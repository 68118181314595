import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { ORFieldInputDdiPhone } from "components/or-input-ddi-phone";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../../../routes/cadastro.route-params";
import { useRepresentativeAdd } from "./add-representatives.hook";

export const AddRepresentativePage = () => {
  const { uuid } = useParams<CadastroClienteProcuracoesByUuidRouteParams>();
  if (!uuid)
    throw new Error("No representative workflow uuid in route parameters");

  const { submit, form, loading } = useRepresentativeAdd({ uuid });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Representante" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_big_number:botao:adicionar"
          dataLabel="adicionar"
          type="info"
          onClick={submit}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="taxId"
                  name="taxId"
                  dataAction="adicionar_representante:input:Cpf"
                  dataLabel="Cpf"
                  label="Cpf"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="email"
                  name="email"
                  dataAction="adicionar_representante:input:email"
                  dataLabel="email"
                  label="Email"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="name"
                  name="name"
                  dataAction="adicionar_representante:input:name"
                  dataLabel="nome"
                  label="Nome"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInputDdiPhone
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Telefone"
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
