import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";

export const useReprocessEligibility = () => {
  const {
    callService: reprocessEligibility,
    error,
    loading,
  } = useServiceCall(service.eligibilityengine.reprocessEligibility);

  const form = useForm<{ "reprocess-eligibility": unknown }>();

  const { setError } = form;

  const handleAddFile = useCallback(
    async (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        if (file.size / 1024 ** 2 > 10) {
          setError("reprocess-eligibility", {
            message: "O tamanho máximo do arquivo é 10MB",
          });
          return;
        }

        const { success } = await reprocessEligibility(file);
        if (success) OToastManager.success("Arquivo enviado com sucesso!");
      }
    },
    [reprocessEligibility, setError],
  );

  return {
    error,
    form,
    handleAddFile,
    loading,
  };
};
