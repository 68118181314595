import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { FraudSysGridDataItem } from "services/gateholder/models/responses";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./antifraud-analysis.grid";

const dataSource = dataSourceCustomStoreGenerator<FraudSysGridDataItem>(
  (loadOptions) =>
    service.gateholder
      .getFraudSysGrid(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar dados";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const SecurityAntifraudAnalysis = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Fraudsys"
          description="Monitor do fraudsys do portal BTG Pactual Empresas"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
