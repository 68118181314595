import * as yup from "yup";

export interface AdminUpdateProfileChangeFormValues {
  message: string;
}

export const adminUpdateProfileChangeValidationFormSchema: yup.ObjectSchema<AdminUpdateProfileChangeFormValues> =
  yup.object({
    message: yup.string().required("Este campo é obrigatório"),
  });
