import { useEffect, useRef, useState } from "react";
import { NodeChange, OnNodesChange, useStore, useStoreApi } from "reactflow";
import { ChangeLoggerProps } from "./change-logger.types";

export const useChangeLogger = ({ limit = 20 }: ChangeLoggerProps) => {
  const onNodesChange = useStore((s) => s.onNodesChange);
  const store = useStoreApi();

  const [changes, setChanges] = useState<NodeChange[]>([]);
  const onNodesChangeIntercepted = useRef(false);

  useEffect(() => {
    if (!onNodesChange || onNodesChangeIntercepted.current) return;

    onNodesChangeIntercepted.current = true;
    const userOnNodesChange = onNodesChange;

    const onNodesChangeLogger: OnNodesChange = (nc) => {
      userOnNodesChange(nc);

      setChanges((oldChanges) => [...nc, ...oldChanges].slice(0, limit));
    };

    store.setState({ onNodesChange: onNodesChangeLogger });
  }, [limit, onNodesChange, store]);

  return { changes };
};
