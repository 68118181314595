import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/role-form/role-form.form";
import { FormValuesType } from "../_compose/role-form/role-form.types";

export const useGatekeeperRolesDetails = (name?: string) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (!values.id) return;

      const { type, resource, action, description, id } = values;
      const newName = `${type}:${resource}.${action}`;

      const payload = {
        id,
        description,
        name: newName,
      };

      await service.gatekeeper.editRole(payload);

      OToastManager.success("Role alterada com sucesso");

      navigate(routes.ferramentas.product.gatekeeper.roles.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    if (!name) return;

    const { data } = await service.gatekeeper.getRoleByName(name);

    const [type, rest] = data.name.split(":") as string[];
    const [resource, action] = rest.split(".") as string[];

    const resetValues = {
      type,
      resource,
      action,
      description: data.description ?? "",
      id: data.id,
    };

    reset(resetValues);
  }, [name, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    loading,
  };
};
