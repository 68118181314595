import { OLoader, OToastManager } from "@maestro/react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { buildLimitManagerGuarantorDetailsFields } from "../_compose/limit-manager-details.details";
import { useLimitManager } from "../limit-manager.hook";
import { grid } from "./limit-manager-guarantors.grid";

type CurrentLimitRequest = NonNullable<
  Awaited<
    ReturnType<typeof service.hubCreditManager.getCompanyCreditLineDetails>
  >["data"]["currentLimitRequest"]
>;

type LimitGuarantors = NonNullable<
  CurrentLimitRequest["limitGuarantors"]
>[number];

export const LimitManagerGuarantorsPage = () => {
  const { details, isLoading } = useLimitManager();

  const dataSource = dataSourceCustomStoreGenerator<LimitGuarantors>(() => {
    if (!details?.CreditLineIdentification || !details?.CreditLineProduct) {
      return Promise.resolve([]);
    }

    return service.hubCreditManager
      .getCompanyCreditLineDetails({
        Identification: details.CreditLineIdentification,
        Product: details.CreditLineProduct,
      })
      .then(({ data }) => data.currentLimitRequest?.limitGuarantors ?? [])
      .catch(() => {
        const errorMessage = "Erro ao buscar as cotações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

  return (
    <>
      {isLoading ? (
        <OLoader />
      ) : (
        <div className="d-flex flex-column gap-3">
          <DetailsCard
            fields={buildLimitManagerGuarantorDetailsFields(details)}
          />
          <GridTemplate
            pageTitle={<PageTitle title="Avalistas" />}
            actions={<RefreshGridButton onClick={() => dataSource.reload()} />}
          >
            <ODataGridGenerator grid={grid} dataSource={dataSource} />
          </GridTemplate>
        </div>
      )}
    </>
  );
};
