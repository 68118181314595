import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { energiaRoles } from "../../../../../roles/energia.roles";
import { EnergyDistributorDiligenceFieldsForm } from "./_compose";
import { useEnergyDistributorDiligencePage } from "./energy-distributor-diligence.hook";

export const EnergyDistributorDiligencePage = () => {
  const {
    form,
    loading,
    loadingDetails,
    hasError,
    genericFieldDetails,
    handleErrorCallback,
    submit,
  } = useEnergyDistributorDiligencePage();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Configuração de diligência" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          role={energiaRoles.product.updateDiligenceConfig.role}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar diligencia"
            callback={handleErrorCallback}
          />
        }
        value={genericFieldDetails}
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <EnergyDistributorDiligenceFieldsForm />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
