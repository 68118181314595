import type { Column } from "devextreme/ui/data_grid";

type Filter = (string | null | Filter)[];

export const calculateNameAndTaxIdFilterExpression = (
  nameFields: string | string[],
  taxIdFields: string | string[],
) =>
  function customCalculateFilterExpression(this: Column, filterValue: string) {
    const nameFieldsArr =
      typeof nameFields === "string" ? [nameFields] : nameFields;
    const taxIdFieldsArr =
      typeof taxIdFields === "string" ? [taxIdFields] : taxIdFields;

    const filter: Filter = [];

    nameFieldsArr.forEach((fieldName) => {
      filter.push([fieldName, "contains", filterValue]);
      filter.push("or");
    });

    const taxIdDigits = filterValue.replace(/\D/g, "");

    if (taxIdDigits)
      taxIdFieldsArr.forEach((fieldName) => {
        filter.push([fieldName, "contains", taxIdDigits]);
        filter.push("or");
      });

    filter.pop(); // remove last "or"

    return filter;
  };
