import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.odata.getAccProposals>
    >["data"]["value"]
  >[number]
>;

export const adminACCProposalsODataGrid = (
  filterValue?: Grid["datagrid"]["filterValue"],
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma proposta encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar propostas.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.acc.odata.adminACCProposalsOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
        },
      ),
    },
    columns: [
      {
        caption: "Nome",
        dataField: "counterpartyName",
      },
      {
        caption: "Identificação",
        dataField: "counterpartyIdentification",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Proposta",
        dataType: "number",
        alignment: "left",
      },
      {
        caption: "Valor solicitado",
        dataType: "date",
        dataField: "amount",
        cellRender: ({ data }) =>
          masks.currency(
            data.amount,
            getValueFromMap(currencySymbol, data.currency),
          ),
      },
      {
        caption: "Data de vencimento",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "settlementDate",
      },
      {
        caption: "Data de expiração",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "expirationDate",
      },
      {
        caption: "Taxa de juros",
        dataField: "interestRate",
        customizeText: ({ value }) => masks.percentage(value),
        alignment: "left",
      },
      {
        caption: "Status",
        dataField: "status",
      },
    ],
  } satisfies Grid);
