import { OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  NotificationMessage,
  NotificationUser,
  SharkDataGridResponse,
} from "services/shark/models/responses";
import { useGridRef } from "hooks/grid-ref";
import { useCobrancasEstimulosUsers } from "./cobrancas-estimulos-users.hook";
import { CustomPagination } from "../../../recebiveis/pages/produto/titulos/nota-a-nota/_compose/custom-pagination";
import { UserGridActions } from "./users-grid-actions.component";

const estimulosUsersGrid = {
  datagrid: {
    noDataText: "Nenhum estímulo encontrado.",
    headerFilter: { visible: true, allowSearch: false },
  },
  columns: [
    {
      caption: "Nome",
      dataField: "name",
    },
    {
      caption: "Destinatário",
      dataField: "receiver",
    },
    {
      caption: "Enviado em",
      dataField: "insertedAt",
      dataType: "datetime",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <UserGridActions id={data.id} receiver={data.receiver} />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<NotificationUser[][number]>;

type CobrancasEstimulosUsersDetailProps = MasterDetailComponentProps<
  SharkDataGridResponse<NotificationMessage>["data"][number]
>;

export const EstimulosUsersDetail = ({
  data: {
    data: { id },
  },
}: CobrancasEstimulosUsersDetailProps) => {
  const gridRef = useGridRef();
  const { dataSource, totalItems, page, setPage, rows, setRows } =
    useCobrancasEstimulosUsers(id);

  return (
    <>
      <OTypography weight="500" className="mb-3">
        Mensagens
      </OTypography>
      <ODataGridGenerator
        grid={estimulosUsersGrid}
        gridRef={gridRef}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[5, 10, 15]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
    </>
  );
};
