import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByConsumerUnitIdRouteParams } from "../../../../../routes/energia.route-params";
import { dateOnly, getValidationMessages } from "../../../../../utils";
import {
  diligenceFormDefaultValues,
  diligenceFormValidationSchema,
} from "./diligence.form";

export const useDiligenceSteps = () => {
  const { consumerUnitId } = useParams<MleByConsumerUnitIdRouteParams>();
  if (!consumerUnitId) throw new Error("No consumerUnitId");

  const { callService, value: diligenceValue } = useServiceCall(
    service.hubEnergy.getDiligenceSummary,
  );

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepSummaryResponseV1>(
        () =>
          callService(consumerUnitId)
            .then((data) => {
              if (!data.success) throw data.error;
              return data.response.data.response.steps;
            })
            .catch((err) => {
              const errorMessage =
                getValidationMessages(err)?.[0]?.errorMessage ??
                "Erro ao buscar a migração";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }),
      ),
    [callService, consumerUnitId],
  );

  const { callService: _updateDiligenceMigrationDate, loading: submitLoading } =
    useServiceCall(service.hubEnergy.updateDiligenceMigrationDate);

  const form = useForm({
    defaultValues: diligenceFormDefaultValues,
    resolver: yupResolver(diligenceFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const onLoad = useCallback(
    (value: HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitDetailsResponse>) =>
      reset({
        migrationDate: value.response.migrationDate,
      }),
    [reset],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!diligenceValue?.response)
          return OToastManager.danger("No diligence value");

        const { success, error } = await _updateDiligenceMigrationDate({
          diligenceId: diligenceValue.response.id,
          migrationDate: dateOnly(values.migrationDate),
        });

        if (success) {
          OToastManager.success("Migração atualizada com sucesso");
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Não foi possível atualizar a migração",
          );
        }
      }),
    [_updateDiligenceMigrationDate, handleSubmit, diligenceValue],
  );

  return {
    consumerUnitId,
    dataSource,
    diligenceValue,
    form,
    submitLoading,
    submit,
    onLoad,
  };
};
