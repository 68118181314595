import { useCallback } from "react";
import useSWR from "swr";
import { logger } from "utils/logger";
import { assetsService } from "services/assets/assets.services";

export const useFinancialInstitutionsList = () => {
  const fetcher = useCallback(async () => {
    try {
      const {
        data: { financialInstitutions },
      } = await assetsService.getFinancialInstitutions();
      return financialInstitutions ?? [];
    } catch (err) {
      logger.warn(err);
      return [];
    }
  }, []);

  const { data, error, isLoading } = useSWR<Assets.FinancialInstitution[]>(
    "financialInstitutionsList",
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return { financialInstitutions: data, error, isLoading };
};
