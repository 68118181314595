import { OInputDateRange } from "@maestro/react";
import dayjs from "dayjs";

interface DateFilterProps {
  datesInterval: [string, string];
  setDatesInterval: (interval: [string, string]) => void;
}

export const DateFilter = ({
  datesInterval,
  setDatesInterval,
}: DateFilterProps) => {
  return (
    <div className="me-auto">
      <OInputDateRange
        aspect="flushed"
        allowClear={false}
        value={
          datesInterval.map((date) => dayjs(date).format("DD/MM/YYYY")) as [
            string,
            string,
          ]
        }
        onChange={(dates) => {
          if (!dates?.length) return;
          const [start, end] = dates;
          setDatesInterval([
            (start || dayjs().startOf("month")).format("YYYY/MM/DD"),
            (end || dayjs().endOf("month")).format("YYYY/MM/DD"),
          ]);
        }}
        size="md"
        disabledDate={(current: moment.Moment | null) => {
          if (!current) return false;
          return dayjs()
            .startOf("day")
            .isBefore(dayjs(current?.toISOString()).startOf("day"));
        }}
      />
    </div>
  );
};
