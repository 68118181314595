import { OIcon, OTooltip, OTypography } from "@maestro/react";
import dayjs from "dayjs";
import { PostCustomerPlansGetFromCompanyResponse } from "services/clerk";
import { DottedUnderline, IconContainer } from "../cards.styles";

type PlanCardProps = Omit<
  PostCustomerPlansGetFromCompanyResponse,
  "insertedAt"
>;
export const PlanCardContents = ({
  bundleIdentifier,
  bundleName,
  bundleRank,
  limitReached,
  referenceDate,
  updatedAt,
}: PlanCardProps) => {
  const referenceDateSplit = referenceDate?.split("-");
  const referenceFormatted =
    !!referenceDateSplit?.[0] && !!referenceDateSplit[1]
      ? `${referenceDateSplit[1]}/${referenceDateSplit[0]}`
      : referenceDate;

  return (
    <div className="d-flex flex-column gap-3 w-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-usd-circle" />
          </IconContainer>
          <OTypography>Plano:</OTypography>
        </div>
        <OTooltip position="top" maxWidth="400px" floating>
          <div slot="tooltip-content">
            <OTypography>{bundleIdentifier}</OTypography>
          </div>
          <OTypography>
            <DottedUnderline>
              {bundleRank} - {bundleName}
            </DottedUnderline>
          </OTypography>
        </OTooltip>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-calendar-alt" />
          </IconContainer>
          <OTypography>Mês de referência:</OTypography>
        </div>
        <OTypography>{referenceFormatted}</OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-tachometer" />
          </IconContainer>
          <OTypography>Limite atingido:</OTypography>
        </div>
        <OTypography>{limitReached ? "Sim" : "Não"}</OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-history" />
          </IconContainer>
          <OTypography>Atualizado em:</OTypography>
        </div>
        <OTypography>{dayjs(updatedAt).format("DD/MM/YYYY")}</OTypography>
      </div>
    </div>
  );
};
