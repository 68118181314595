import * as yup from "yup";

export const addTemplateFormValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
});

export const addTemplateFormDefaultValues = {
  name: "",
  description: "",
};
