import * as yup from "yup";
import { AddPayerFormFields } from "./add-payer.types";

export const addPayerValidationSchema: yup.ObjectSchema<AddPayerFormFields> =
  yup.object({
    identification: yup.string().required("Este campo é obrigatório"),
    authClientId: yup.string().required("Este campo é obrigatório"),
    partnerConfigurationId: yup.string().required("Este campo é obrigatório"),
  });

export const addPayerDefaultValue: AddPayerFormFields = {
  identification: "",
  authClientId: "",
  partnerConfigurationId: "",
};
