import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, scfOfficersGrid } from "./officers.grid";

export const ScfOfficers = () => {
  const gridRef = useGridRef();

  return (
    <>
      <PageTitle title="Listagem de Officers" />

      <GridTemplate
        actions={
          <StandardLink
            href={
              corporateRouter.routes.ferramentas.product.onboarding
                .domainConfigScf.officers.add.path
            }
            role={roles.ferramentas.product.onboardingScfOfficerUpsertPage.role}
          >
            Adicionar officer
          </StandardLink>
        }
        gridRef={gridRef}
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={scfOfficersGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </>
  );
};
