export interface FormValuesType {
  id?: string;
  identification: string;
  from?: string | null | undefined;
  until?: string | null | undefined;
  type: string | null | undefined;
}

export const AllowListTypesMap: Record<string, string> = {
  VIA_VAREJO_CORBAN: "Corban Via Varejo",
};
