import { OCard, OCardBody, OIcon, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { SharkSimulateResponse } from "services/shark/models/responses";

export const TotalAmountCardComponent = ({
  simulation,
}: {
  simulation?: SharkSimulateResponse;
}) => {
  return (
    <OCard type="info-light">
      <OCardBody className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <OIcon category="orq" icon="orq-payment-bills" size="xl" />
          <OTypography>Valor total a pagar</OTypography>
        </div>
        <OTypography
          type="dark"
          key={`total-to-pay-${simulation?.curve_value}`}
        >
          {simulation !== undefined
            ? masks.currency(simulation.curve_value, "R$", ".")
            : "-"}
        </OTypography>
      </OCardBody>
    </OCard>
  );
};
