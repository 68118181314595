import { BankIconSVG } from "../financial-institution-icon.types";

export const BankInterSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#FF8700" />
      <path
        d="M28.448 46.752l-.001.145v.283l.001.146c.036.953.845 1.717 1.854 1.75.034.002.074.002.155.002l.154-.001c1.009-.034 1.819-.798 1.854-1.75.002-.033.002-.07.002-.147v-.283c0-.075 0-.114-.002-.145-.035-.953-.845-1.717-1.854-1.75-.034-.002-.074-.002-.154-.002l-.155.001c-1.009.034-1.818.798-1.854 1.75zm26.33 6.753v7.806c0 1.357.523 1.969 2.178 1.969.693 0 1.374-.193 1.782-.338v3.146c-.693.232-1.754.35-2.621.35-3.478 0-5.127-1.743-5.127-4.755V45.932h3.787v5.01h3.961v2.563h-3.96z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.02 58.25c0-4.624 2.916-7.773 7.582-7.773 4.985 0 7.456 3.637 7.195 8.912H63.91c.197 2.705 1.467 4.078 3.716 4.078 1.901 0 2.91-.917 3.345-2.214h3.7c-.72 3.18-3.238 5.185-7.069 5.185-4.73 0-7.582-3.12-7.582-7.804v-.384zm7.553-4.743c-2.075 0-3.315 1.11-3.663 3.32l7.084-.06c-.203-2.06-1.316-3.26-3.42-3.26z"
        fill="#fff"
      />
      <path
        d="M43.388 50.477c-2.435 0-3.938 1.439-4.69 2.854l-.174-2.389h-3.611v15.03h3.786v-8.447c0-2.493 1.322-4.02 3.32-4.02 1.999 0 2.738 1.323 2.738 3.612v8.854h3.787v-9.67c.006-3.355-1.451-5.824-5.156-5.824zM80.7 53.33c.961-1.882 2.924-2.854 4.398-2.854.262 0 .676.034.903.087v2.942a11.434 11.434 0 00-1.1-.059c-2.692 0-4.2 1.62-4.2 4.37v8.154h-3.788V50.942h3.67l.117 2.389zM26 50.942v2.56h2.562v12.47h3.787v-15.03H26z"
        fill="#fff"
      />
    </svg>
  );
};
