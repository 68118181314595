import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OOption,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { BankAccount } from "services/clerk";
import { SharkPaymentMethodType } from "services/shark/models/responses";
import { useAnticipation } from "../[contractNumber]/anticipation.context";
import { anticipationFormFields } from "../[contractNumber]/anticipation.form";
import { PaymentMethod } from "../[contractNumber]/anticipation.types";

interface PaymentMethodCardProps {
  form: UseFormReturn<{
    paymentMethod: SharkPaymentMethodType;
    expirationDate: string;
  }>;
  loadingBalance: boolean;
  bankAccount?: BankAccount;
}

export const PaymentMethodCard = ({
  form,
  loadingBalance,
  bankAccount,
}: PaymentMethodCardProps) => {
  const { sharkSettlement, nextBusinessDays } = useAnticipation();
  const { setValue, watch } = form;

  const paymentMethodWatch = watch("paymentMethod");

  const availablePaymentMethods: PaymentMethod[] = useMemo(() => {
    if (!sharkSettlement || loadingBalance) return [];

    let methods: PaymentMethod[] =
      sharkSettlement.agreement.payment_methods.map(
        ({ name, short_name: type }) => ({
          name,
          type,
        }),
      );

    methods.sort((a, b) => b.type.localeCompare(a.type));

    if (!bankAccount)
      methods = methods.filter((method) => method.type !== "C/C");

    if (methods.length >= 1) {
      setValue("paymentMethod", methods[0].type);
    }

    return methods;
  }, [bankAccount, loadingBalance, setValue, sharkSettlement]);

  return (
    <OCard>
      <OCardHeader>
        <OTypography size="lg" type="dark">
          Selecione o método de pagamento
        </OTypography>
      </OCardHeader>
      <OCardBody>
        <ORow>
          <OCol sm={6}>
            <ORFieldSelect
              {...anticipationFormFields.paymentMethod}
              key={`available-payment-methods-${availablePaymentMethods.length}`}
              disabled={!availablePaymentMethods.length}
            >
              {availablePaymentMethods.map((paymentMethod) => (
                <OOption
                  key={`payment-method-${paymentMethod.type}`}
                  value={paymentMethod.type}
                >
                  {paymentMethod.name}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          {paymentMethodWatch === "BOLETO" && (
            <OCol sm={6}>
              <ORFieldSelect
                {...anticipationFormFields.expirationDate}
                key={`expiration-date-${availablePaymentMethods.length}`}
                disabled={!availablePaymentMethods.length}
              >
                {nextBusinessDays.map((day) => (
                  <OOption
                    value={dayjs(day).format("YYYY-MM-DD")}
                    key={`option-${day}`}
                  >
                    {dayjs(day).format("DD/MM/YYYY")}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
