import { ONotification, OTypography } from "@maestro/react";
import { AccountModality } from "../cards.types";
import { CanceledByDefaultPaymentBannerProps } from "./types";

export const CanceledByDefaultPaymentBanner = ({
  cardAccountData,
}: CanceledByDefaultPaymentBannerProps) => {
  const isCredit = cardAccountData.program.modality === AccountModality.Credit;
  const isCreditAndCanceledByDefaultPayment =
    isCredit && cardAccountData?.isCanceledByPaymentDefault;

  if (!isCreditAndCanceledByDefaultPayment) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-2">
      <ONotification type="danger">
        <OTypography type="dark">
          Os cartões de crédito da empresa {cardAccountData.company.name} foram
          cancelados devido à falta de pagamento da fatura.
          <br />
          <br />
          Após 69 dias de atraso, o cartão é tombado e a dívida se torna uma
          dívida interna (CCB).
          <br />
          <br />
          Para renegociar ou pagar essa dívida, é necessário entrar em contato
          com a equipe de cobrança.
        </OTypography>
      </ONotification>
    </div>
  );
};
