import { OButton, OCheckbox, OCheckboxGroup, OLabel } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { RowGap } from "../../../cards.styles";
import {
  IMiddleButtonIteration,
  IMidleCheckboxIteration,
} from "../chargeback.types";
import { QuestionContainer, Title } from "./compose.styles";
import { IQuestionCheckboxProps } from "./compose.types";

export const QuestionCheckbox = ({
  questionIndex,
  data,
  executeAction,
  isEditable,
}: IQuestionCheckboxProps) => {
  const [checkboxOptionsData, setCheckboxOptionsData] = useState<
    string[] | undefined
  >();
  const [checkedboxOption, setCheckedboxOption] = useState<string[]>([]);

  useEffect(() => {
    const optionsData: string[] = [];
    const defaultData: string[] = [];
    data.questions.forEach((question: IMidleCheckboxIteration) => {
      optionsData.push(question.label);
      if (data.response) {
        const response = (data.response as Record<string, string>[]).find(
          (response) => response.value === question.label,
        );
        if (response) defaultData.push(response.value);
      }
    });
    setCheckboxOptionsData(optionsData);
    setCheckedboxOption(defaultData);
  }, [data.questions, data.response]);

  const onChange = (checkedValues: string[]) => {
    setCheckedboxOption(checkedValues);
  };

  const handleSubmit = useCallback(
    async (index: number, action: IMiddleButtonIteration) => {
      let labelFixed: string | undefined = undefined;
      if (action.link === "goChannelForm") labelFixed = "Canais:";
      if (checkedboxOption) {
        const response = await checkedboxOption.map(
          (checkedOption: string) => ({
            label: labelFixed || checkedOption,
            value: checkedOption,
            type: "checkbox",
          }),
        );
        executeAction(action.id, {
          response: response,
          index: index,
          link: action.link,
        });
      }
    },
    [checkedboxOption, executeAction],
  );

  return (
    <div key={`${questionIndex}-question-container`}>
      {checkboxOptionsData && (
        <QuestionContainer>
          <Title>
            {questionIndex + 1}. {data.title}
          </Title>
          <OCheckboxGroup
            dataAction="section_name:checkbox:named_event"
            dataLabel="named_event"
            value={checkedboxOption}
            disabled={!isEditable}
            key={checkboxOptionsData.length}
            onInput={(event) => {
              const checkboxValue = (event.target as HTMLOCheckboxGroupElement)
                .value;
              Array.isArray(checkboxValue) && onChange(checkboxValue);
            }}
          >
            {checkboxOptionsData.map((checkboxOption) => (
              <div className="d-flex align-items-center gap-2 mb-1">
                <OCheckbox
                  id={checkboxOption}
                  value={checkboxOption}
                ></OCheckbox>
                <OLabel htmlFor={checkboxOption}>{checkboxOption}</OLabel>
              </div>
            ))}
          </OCheckboxGroup>
          {"actions" in data && isEditable && (
            <RowGap columnGap={80}>
              {(data.actions as Array<IMiddleButtonIteration>).map(
                (action, index) => {
                  return (
                    <OButton
                      key={`${questionIndex}-action-option-${index}`}
                      onClick={async () =>
                        action.id === "btnAdvance"
                          ? handleSubmit(questionIndex, action)
                          : await executeAction(action.id, undefined)
                      }
                      disabled={
                        action.id !== "btnBack"
                          ? checkedboxOption.length === 0
                          : false
                      }
                      dataAction={`chargeback:botao:${action.label}`}
                      dataLabel={action.label}
                    >
                      {action.label}
                    </OButton>
                  );
                },
              )}
            </RowGap>
          )}
        </QuestionContainer>
      )}
    </div>
  );
};
