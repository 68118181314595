import { OToastManager } from "@maestro/core";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { GetInteractionCompanyResponse } from "services/fidc/models/responses/get-interaction-company.response";
// eslint-disable-next-line no-restricted-imports
import { isArray } from "lodash";
import { service } from "services";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
import { useSearchParams } from "react-router-dom";
import { useFidcFunds } from "../../../../hooks";

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("");

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");

export const useInteractionsCompany = () => {
  const [loading, setLoading] = useState(false);
  const { funds } = useFidcFunds();
  const form = useForm();
  const [searchParams] = useSearchParams();

  const taxId = searchParams.get("taxId");
  if (!taxId) throw new Error("No taxId");

  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(999);

  const resend = useCallback(async (interactionId: string) => {
    setLoading(true);

    try {
      await service.fidc.postInteractionTracking({ interactionId });
      OToastManager.success("Interação reenviada com sucesso!");
    } catch {
      OToastManager.danger("Erro ao reenviar interação para o destinatário");
    } finally {
      setLoading(false);
    }
  }, []);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        GetInteractionCompanyResponse["interactions"][number]
      >(() =>
        service.fidc
          .getInteractionCompany(
            buildQuery({
              fundTaxId: searchParams.get("taxId"),
              limit: rows,
              page,
            }),
          )
          .then(({ data }) => {
            setTotalItems(data.totalCount);
            return {
              data: data.interactions ?? [],
              totalCount: data.totalCount || 0,
            };
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro na busca das interações da empresa.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          })
          .finally(() => {
            setLoading(false);
          }),
      ),
    [searchParams, rows, page],
  );

  return {
    dataSource,
    form,
    funds,
    loading,
    resend,
    rows,
    setRows,
    page,
    setPage,
    totalItems,
    taxId,
  };
};
