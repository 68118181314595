import { Dispatch, SetStateAction, useState } from "react";
import {
  oneMonthAgoInMs,
  PaymentLineFilter,
  PaymentsFilters,
} from "./payment-filter-modal.types";

export const usePaymentsFilter = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [filters, setFilters] = useState<PaymentsFilters>({
    startDate: new Date(Date.now() - oneMonthAgoInMs).toISOString(),
    endDate: new Date().toISOString(),
  });

  return {
    visible: filterModal,
    setVisible: setFilterModal,
    filters,
    setFilters,
  };
};

export interface TransactionsFilterHook {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  filters: PaymentLineFilter;
  setFilters: Dispatch<SetStateAction<PaymentLineFilter>>;
}

export const useTransactionsFilter = (): TransactionsFilterHook => {
  const [filterModal, setFilterModal] = useState(false);
  const [filters, setFilters] = useState<PaymentLineFilter>({});

  return {
    visible: filterModal,
    setVisible: setFilterModal,
    filters,
    setFilters,
  };
};
