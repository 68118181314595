import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { OverdraftStatusBadge } from "../_compose";
import {
  calculateTaxes,
  dateDifferenceInDays,
} from "../overdraft-details.utils";

export const buildOverdraftLimitFields = (
  limit: BankingHub.FullLimit,
): DetailsFields => ({
  limit: {
    title: "Limite",
    items: [
      {
        label: "Status",
        value: <OverdraftStatusBadge limit={limit} />,
        grid: { xs: 12 },
      },
      {
        label: "Data de contratação",
        value:
          format.date(limit.analysis?.createdAt, { timeZone: "UTC" }) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Valor contratado",
        value: masks.currency(limit.hiredAmount ?? 0, currencySymbol.BRL, "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Valor utilizado",
        value: masks.currency(limit.usedAmount ?? 0, currencySymbol.BRL, "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      // TODO: it seems broken even in production
      {
        label: "Dias consecutivos de uso",
        value:
          limit.usage?.startedAt && limit.usage.finishedAt
            ? dateDifferenceInDays(
                limit.usage.startedAt,
                limit.usage.finishedAt,
              )
            : 0,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Dias totais de uso",
        value: limit.charge?.usedDays ?? "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Encargos totais",
        value: masks.currency(calculateTaxes(limit), "R$", "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Juros de mora",
        value: masks.currency(
          limit.charge?.totalDefaultInterest ?? 0,
          "R$",
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "IOF acumulado",
        value: masks.currency(
          limit.charge?.totalTax ?? 0,
          currencySymbol.BRL,
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Juros acumulado",
        value: masks.currency(
          limit.charge?.totalInterest ?? 0,
          currencySymbol.BRL,
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Data de cobrança",
        value:
          format.date(limit.charge?.chargeDate, { timeZone: "UTC" }) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
