import * as yup from "yup";
import { FormValuesType } from "./request-for-reports.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  cnpjCedentes: yup.array(),
  cnpjSacados: yup.array(),
  productType: yup.array().required("Este campo é obrigatorio"),
  reportName: yup.string().required("Este campo é obrigatorio"),
  reportPeriod: yup.string().required("Este campo é obrigatorio"),
  reportType: yup.string().required("Este campo é obrigatorio"),
  columns: yup.array(),
  disbursementDate: yup.array().nullable(),
  groupBy: yup.string(),
  loteId: yup.array(),
  maturityDate: yup.array().nullable(),
  operationId: yup.array(),
  periodDate: yup.array().nullable(),
  receivableId: yup.array(),
  receivableIdentification: yup.array(),
  statusAntecipations: yup.array(),
  tradeId: yup.array(),
});

export const batchRequestReportsDefaultValues: FormValuesType = {
  productType: ["RiscoSacado"],
  reportName: "",
  reportPeriod: "Today",
  reportType: "ComprovanteDemonstrativo",
};
