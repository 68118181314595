import React, { memo } from "react";
import {
  BankBradescoSVG,
  BankBrasilSVG,
  BankBtgSVG,
  BankBvSVG,
  BankC6SVG,
  BankCaixaSVG,
  BankCitiBankSVG,
  BankDigioSVG,
  BankHsbcSVG,
  BankInterSVG,
  BankItauSVG,
  BankNubankSVG,
  BankOOBSVG,
  BankOriginalSVG,
  BankOtherSVG,
  BankPanSVG,
  BankSafraSVG,
  BankSantanderSVG,
  BankXpSVG,
} from "./_compose";
import { BankIconProps, BankIconSVG } from "./financial-institution-icon.types";

export const FinancialInstitutionIcon = memo(
  ({ bankCode = "default", size, width, height }: BankIconProps) => {
    const Components = new Map<string, React.FC<BankIconSVG>>([
      ["208", BankBtgSVG],
      ["341", BankItauSVG],
      ["237", BankBradescoSVG],
      ["033", BankSantanderSVG],
      ["001", BankBrasilSVG],
      ["104", BankCaixaSVG],
      ["422", BankSafraSVG],
      ["260", BankNubankSVG],
      ["336", BankC6SVG],
      ["077", BankInterSVG],
      ["212", BankOriginalSVG],
      ["623", BankPanSVG],
      ["655", BankBvSVG],
      ["756", BankOOBSVG],
      ["335", BankDigioSVG],
      ["102", BankXpSVG],
      ["745", BankCitiBankSVG],
      ["269", BankHsbcSVG],
      ["default", BankOtherSVG],
    ]);

    return React.createElement(Components.get(bankCode) || BankOtherSVG, {
      width,
      height,
      size,
    });
  },
);
