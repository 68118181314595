export const ORFieldInputs = [
  {
    label: "Nome",
    name: "description",
    id: "name",
    dataAction: "paises:input:name",
    dataLabel: "name",
    radioGroup: false,
    required: true,
  },
  {
    label: "Código",
    name: "code",
    id: "code",
    dataAction: "paises:input:code",
    dataLabel: "code",
    radioGroup: false,
    required: true,
  },
  {
    label: "Código BCB",
    name: "bcbCode",
    id: "bcbCode",
    dataAction: "paises:input:bcbCode",
    dataLabel: "bcbCode",
    radioGroup: false,
    required: true,
  },
  {
    label: "Aprovado",
    name: "isApproved",
    id: "isApproved",
    dataAction: "paises:input:isApproved",
    dataLabel: "isApproved",
    radioGroup: true,
    required: true,
  },
];
