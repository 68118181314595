import { ParamsSerializerOptions } from "axios";
import qs from "qs";
import { client } from "../client";
import { apiEndpoints } from "./api.endpoints";
import { apiHeaders } from "./api.headers";
import {
  GetBilingCustomersParams,
  GetCompaniesPaymentsParams,
  GetCompaniesPaymentsReceiptsParams,
  PatchCompaniesByCustomerIdBillingProductsBody,
} from "./models/requests";
import {
  GetBillingCustomersResponse,
  GetCompaniesByCustomerIdBillingFullInfoResponse,
  GetCompaniesPaymentsResponse,
} from "./models/responses";

export const apiService = {
  getBillingCompanies(params: GetBilingCustomersParams) {
    return client.get<GetBillingCustomersResponse>(
      apiEndpoints.billing.customers,
      {
        params,
      },
    );
  },

  getCompanyBillingInfo(customerId: string) {
    return client.get<GetCompaniesByCustomerIdBillingFullInfoResponse>(
      apiEndpoints.companies.byCustomerId.billing.fullInfo(customerId),
    );
  },

  patchCompanyBillingProduct(
    customerId: string,
    body: PatchCompaniesByCustomerIdBillingProductsBody,
  ) {
    return client.patch(
      apiEndpoints.companies.byCustomerId.billing.products(customerId),
      body,
    );
  },

  getCompanyPayments(customerId: string, params?: GetCompaniesPaymentsParams) {
    const paramsSerializer: ParamsSerializerOptions = {
      serialize: (data: unknown) => {
        return qs.stringify(data, { arrayFormat: "comma" });
      },
    };

    return client.get<GetCompaniesPaymentsResponse>(
      apiEndpoints.companies.byCustomerId.payments.endpoint(customerId),
      {
        headers: apiHeaders.companies.byCustomerId.payments,
        params,
        paramsSerializer,
      },
    );
  },

  getCompanyPaymentSingleReceipt(customerId: string, receiptId: string) {
    return client.get<string>(
      apiEndpoints.companies.byCustomerId.receiptsPayments.single(
        customerId,
        receiptId,
      ),
      {
        responseType: "blob",
        headers: {
          ...apiHeaders.companies.byCustomerId.payments,
          Accept: "application/pdf",
        },
      },
    );
  },

  getCompanyPaymentsReceipts: async (
    customerId: string,
    params?: GetCompaniesPaymentsReceiptsParams,
  ) => {
    return client.get<string>(
      apiEndpoints.companies.byCustomerId.receiptsPayments.endpoint(customerId),
      {
        responseType: "blob",
        headers: {
          ...apiHeaders.companies.byCustomerId.payments,
          Accept: "application/zip",
        },
        params,
      },
    );
  },
};
