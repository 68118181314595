import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const WorksiteEnvConfigField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:pasta_worksite"
        dataLabel="pasta_worksite"
        id="worksiteEnvConfig"
        name="worksiteEnvConfig"
        label={EstrategiaFormLabels.worksiteEnvConfig}
        tag="text"
        placeholder="Preencher"
      />
    </OCol>
  );
};
