import {
  IIcon,
  OBadge,
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import React from "react";
import { useNavigate } from "react-router";
import { CardContainer } from "./products-card-style";

interface ProductCardProps {
  id: number;
  title: string;
  subtitle: string;
  iconName: IIcon;
  limit?: number;
  redirectPath: string;
  creditLineVisibleName: string;
  creditLineIcon: string;
  creditLineDescription: string;
  offerId: number;
  fundingType: string;
}

export const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    id,
    title,
    iconName,
    limit = 0,
    redirectPath,
    offerId,
    creditLineDescription,
    fundingType,
  } = props;

  const navigate = useNavigate();

  if (limit <= 0) return <div />;

  return (
    <CardContainer>
      <OCard>
        <OCardHeader divider>
          <div className="d-flex flex-column gap-2 align-items-start">
            <OBadge circle type="tertiary" size="lg">
              <OIcon category="orq" icon={iconName} size="xl" type="light" />
            </OBadge>
            <div className="position-relative w-100">
              <OTypography type="dark">{title}</OTypography>
            </div>
          </div>
        </OCardHeader>
        <OCardBody>
          <div className="d-flex flex-column gap-3">
            <OTypography type="dark-60" size="sm">
              {creditLineDescription}
            </OTypography>
            <div className="d-flex w-100 justify-content-between py-2">
              <OTypography size="sm">Oferta</OTypography>
              <OTypography size="sm">
                {masks.currency(limit, "R$", ".")}
              </OTypography>
            </div>
            <OButton
              onClick={() =>
                fundingType === "BNDES"
                  ? navigate(`${redirectPath}/${id}/landing-page/${offerId}`)
                  : navigate(`${redirectPath}/${id}/oferta/${offerId}`)
              }
              outline
            >
              <OTypography type="dark" size="sm">
                Conferir
              </OTypography>
            </OButton>
          </div>
        </OCardBody>
      </OCard>
    </CardContainer>
  );
};
