import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { OnboardingDetailsByUUIDRouteParams } from "../../../../routes/cadastro.route-params";
import { buildDataSource, openAccountsGrid } from "./open-accounts.grid";

export const OnboardingOpenAccounts = () => {
  const { externalReference } = useParams<OnboardingDetailsByUUIDRouteParams>();
  if (!externalReference) throw new Error("No uuid in route parameters");

  const gridRef = useGridRef();
  const dataSource = useMemo(
    () => buildDataSource(externalReference),
    [externalReference],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contas solicitadas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={openAccountsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
