import { ITipsList } from "./landing-page.types";

export const landingPageTipsList: ITipsList = [
  {
    icon: "orq-financing" as const,
    title: "Crédito para Micro e Pequenas Empresas",
    subtitle:
      "Disponível para alavancar os negócios dos micro e pequenos empresários que podem alcançar seus objetivos ou manter o bom funcionamento do negócio.",
  },
  {
    icon: "orq-online" as const,
    title: "Solicitação online",
    subtitle:
      "Contratação é 100% digital e sem burocracias na plataforma do BTG Empresas. As checagens de elegibilidade são realizadas durante o processo de contratação.",
  },
  {
    icon: "orq-fees-fines" as const,
    title: "Sem burocracias",
    subtitle:
      "Sem lastro ou comprovação pode ser utilizado para compra de insumos, estoques ou para investimentos.",
  },
  {
    icon: "orq-schedule" as const,
    title: "Prazos e condições flexíveis",
    subtitle:
      "Conte agora com taxas subsidiadas pelo BNDES para alavancar o crescimento do pequeno e médio empresário.",
  },
];
