import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { referralsRelationshipsODataGrid } from "./client-referrals-relationship.grid";

export const ClientReferralsRelationshipsPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => referralsRelationshipsODataGrid, []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Vínculos de Contas" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
