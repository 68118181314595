import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORFieldUploadInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useEffect } from "react";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useDisparoManual } from "./cobrancas-disparo-manual.hook";

export const DisparoManualForm = () => {
  const {
    loading,
    setValue,
    filesToUpload,
    handleAddFile,
    handleRemoveFile,
    onSubmit,
    templateOptions,
  } = useDisparoManual();

  useEffect(() => {
    setValue("file", filesToUpload);
  }, [filesToUpload, setValue]);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          dataAction="estimulo_disparo_manual:botao:enviar"
          dataLabel="enviar"
          onClick={onSubmit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <section className="mb-4">
          <ORow>
            <OTypography className="mb-1">Operação</OTypography>
            <OCol>
              <ORFieldInput
                id="operationId"
                name="operationId"
                label="Trade da operação"
                tag="text"
                dataAction="estimulo_disparo_manual:input:operationId"
                dataLabel="operationId"
                required
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="installment"
                name="installment"
                label="Parcela"
                tag="number"
                dataAction="estimulo_disparo_manual:input:installment"
                dataLabel="installment"
                required
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="taxId"
                name="taxId"
                label="CPF/CNPJ"
                tag="cpfcnpj"
                dataAction="estimulo_disparo_manual:input:taxId"
                dataLabel="taxId"
                required
              />
            </OCol>
          </ORow>
        </section>
        <section className="mb-4">
          <ORow>
            <OTypography className="mb-1">Destinatário</OTypography>
            <OCol>
              <ORFieldInput
                id="userName"
                name="userName"
                label="Nome do destinatário"
                tag="text"
                dataAction="estimulo_disparo_manual:input:userName"
                dataLabel="userName"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="userReceiver"
                name="userReceiver"
                label="E-mail do destinatário"
                tag="text"
                dataAction="estimulo_disparo_manual:input:userReceiver"
                dataLabel="userReceiver"
                required
              />
            </OCol>
          </ORow>
        </section>
        <section>
          <ORow>
            <OTypography className="mb-1">Mensagem</OTypography>
            <OCol>
              <ORFieldInput
                id="title"
                name="title"
                label="Título"
                tag="text"
                dataAction="estimulo_disparo_manual:input:title"
                dataLabel="title"
                required
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="subtitle"
                name="subtitle"
                label="Subtítulo"
                tag="text"
                dataAction="estimulo_disparo_manual:input:subtitle"
                dataLabel="subtitle"
                required
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                id="content"
                name="content"
                label="Corpo"
                tag="textarea"
                dataAction="estimulo_disparo_manual:input:content"
                dataLabel="content"
                required
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldSelect
                id="template"
                name="template"
                label="Template"
                dataAction="estimulo_disparo_manual:input:template"
                dataLabel="template"
                required
              >
                {templateOptions.map((template) => (
                  <OOption key={template} value={template}>
                    {template}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol>
              <ORFieldInput
                id="metadata"
                name="metadata"
                label="Metadados"
                tag="text"
                dataAction="estimulo_disparo_manual:input:metadata"
                dataLabel="metadata"
              />
            </OCol>
          </ORow>
          <ORow>
            <div className="w-50">
              <ORFieldUploadInput
                accept="*"
                dataAction="estimulo_disparo_manual:upload:attachments"
                dataLabel="attachments"
                id="file"
                name="file"
                disabled={loading}
                label="Anexos"
                inputLabel="Inserir anexos na mensagem"
                onAddFile={handleAddFile}
                handleRemove={handleRemoveFile}
                value={filesToUpload}
              />
            </div>
          </ORow>
        </section>
      </CardTemplate>
    </FormTemplate>
  );
};
