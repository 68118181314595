import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { currencySymbol } from "utils/currency";

interface InvoiceFormProps {
  disableAmount?: boolean;
}

export const InvoiceForm = ({ disableAmount }: InvoiceFormProps) => {
  return (
    <ORow>
      <OCol>
        <ORFieldInput
          tag="text"
          id="invoiceNumber"
          name="invoiceNumber"
          dataAction="emprestimos_notas_fiscais:text:numero_nota"
          dataLabel="numero_nota"
          label="Número da nota"
        />
      </OCol>
      <OCol>
        <ORFieldInput
          tag="currency"
          symbol={currencySymbol.BRL}
          id="amount"
          name="amount"
          dataAction="emprestimos_notas_fiscais:text:valor"
          dataLabel="valor"
          label="Valor"
          disabled={disableAmount}
        />
      </OCol>
    </ORow>
  );
};
