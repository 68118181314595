import React, { useCallback } from "react";
import { OToastManager } from "@maestro/core";
import { service } from "services";
import { getSearchParams } from "utils/search-params/get-search-params";
import { GetCreditConciliationParams } from "services/fidc/models/requests/get-credit-conciliation.request";
import { IconButton } from "components/icon-button";
import { buildQuery } from "../credit-conciliation-details.utils";

interface DownloadButtonProps {
  fileConciliationId: string;
}

type ViewCreditConciliationParams = GetCreditConciliationParams;

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileConciliationId,
}) => {
  const { orderId } = getSearchParams<ViewCreditConciliationParams>();
  if (!orderId) throw new Error("No url parameters");

  const generateURLFile = useCallback(async () => {
    try {
      const response = await service.fidc.getGenerateURLFileCreditConciliation(
        orderId,
        buildQuery({ fileId: fileConciliationId }),
      );

      if (!response || !response.data) {
        throw new Error("Nenhuma URL retornada pela API");
      }

      const downloadLink = response.data;
      const link = document.createElement("a");
      link.href = downloadLink;
      link.style.display = "none";
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Erro ao recuperar arquivo para download!";
      OToastManager.danger(errorMessage);
    } finally {
      OToastManager.success("Download finalizado.");
    }
  }, [fileConciliationId, orderId]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-download" }}
      dataAction="users_grid:botao:download_file_conciliation"
      dataLabel="download_file_conciliation"
      rounded
      outline
      type="default"
      onClick={generateURLFile}
    >
      Download
    </IconButton>
  );
};
