import { useCallback, useEffect, useState } from "react";
import { logger } from "utils/logger";

export const useReadFile = () => {
  const [reader, setReader] = useState(new FileReader());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [result, setResult] = useState<string | ArrayBuffer | null>();

  const reset = useCallback(() => {
    setReader(new FileReader());
    setLoading(false);
    setError(undefined);
    setResult(undefined);
  }, []);

  useEffect(() => {
    reader.onload = (evt) => {
      setError(undefined);
      setResult(evt.target?.result);
    };

    reader.onerror = () => {
      logger.error(reader.error);
      setError(reader.error);
    };

    reader.onloadstart = () => {
      setLoading(true);
    };

    reader.onloadend = () => {
      setLoading(false);
    };
  }, [reader]);

  return {
    error,
    loading,
    reader,
    reset,
    result,
  };
};
