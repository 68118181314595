import { IType, OBadge, OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { batchRequestStatusMap } from "./batch-requests.utils";

type BatchRequests = Awaited<
  ReturnType<
    typeof service.hubCreditManager.odata.getWorkflowLeadsBatchRequests
  >
>["data"]["value"][number];

export const BatchRequestsGrid = () =>
  ({
    datagrid: {
      noDataText: "Nenhum processamento encontrado",
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.workflowLeadsBatchRequestAdmin,
      ),
      headerFilter: { visible: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        role: roles.workflow.product.batchRequestsPage.role,
        cellRender: ({ data }) =>
          data.Id ? (
            <DetailsLink
              href={corporateRouter.routes.workflow.product.batchs.batchRequests.summary.path(
                {
                  id: data.Id,
                },
              )}
            />
          ) : undefined,
      },
      {
        dataField: "Id",
        caption: "Id",
        dataType: "number",
        sortOrder: "desc",
        cellRender: ({ data }) => `#${data.Id}`,
      },
      {
        dataField: "CreatedDate",
        caption: "Data solicitação",
        dataType: "datetime",
      },
      {
        dataField: "ProcessedLead",
        caption: "Solicitações Processadas",
        dataType: "number",
        alignment: "left",
      },
      {
        dataField: "Originator",
        caption: "Originador / Operador",
        dataType: "string",
        cellRender: ({ data }) => {
          return (
            <div className="d-flex flex-column">
              <OTypography>{data.Originator}</OTypography>
              <OTypography type="dark-80" size="sm">
                {data.Operator}
              </OTypography>
            </div>
          );
        },
      },
      {
        dataField: "WorkflowConfigName",
        caption: "Workflow",
        dataType: "string",
      },
      {
        dataField: "Name",
        caption: "Nome do Arquivo",
        dataType: "string",
      },
      {
        dataField: "Position",
        caption: "Posição",
        dataType: "number",
        cellRender: ({ data }) => {
          return data.Status === "IN_PROGRESS" || data.Status === "PENDING" ? (
            <div className="d-flex flex-column justify-content-center gap-1">
              {data.Position}
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center gap-1">
              -
            </div>
          );
        },
      },
      {
        dataField: "Status",
        caption: "Status",
        dataType: "string",
        cellRender: ({ data }) => {
          const badge = getValueFromMap(batchRequestStatusMap, data.Status);
          return badge ? (
            <div className="d-flex flex-column justify-content-center gap-1">
              <OBadge
                key={badge?.label}
                size="sm"
                type={`${badge?.type}-light` as IType}
                rounded
              >
                {badge?.label}
              </OBadge>
            </div>
          ) : (
            data.Status
          );
        },
      },
      {
        dataField: "Channel",
        caption: "Canal",
        dataType: "string",
      },
    ],
  } satisfies ODataGridGeneratorConfig<BatchRequests>);
