import {
  PreticketDocument,
  PreticketDocumentGroup,
} from "services/hubfx/models/responses";

export const filteredPreTicketDocuments = (
  groups: PreticketDocumentGroup[] | null,
) => {
  const documents: PreticketDocument[] = [];

  groups?.forEach((group) => {
    const document = group.documents?.filter(
      (d) =>
        d.genericFields?.some((gf) => !!gf.value) ||
        d.files?.some(
          (file) => !!file.key || file.genericFields?.some((gf) => !!gf.value),
        ),
    );

    document?.forEach((doc) => documents.push(doc));
  });

  return documents;
};
