import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { OToastManager, modalManager } from "@maestro/core";
import { useMemo } from "react";
import { ConciliacaoModal, conciliacaoModalId } from "./_compose";
import {
  ConciliacaoProvider,
  useConciliacaoContext,
} from "./extrato-liquidacao-posicao.context";
import { Conciliation } from "./extrato-liquidacao-posicao.types";
import { conciliacaoGrid } from "./extrato-liquidacao-posicao.grid";

const ConciliacaoComponent = () => {
  const gridRef = useGridRef();

  const { rangeDate } = useConciliacaoContext();

  const dataSource = useMemo(() => {
    if (rangeDate) {
      return dataSourceCustomStoreGenerator<
        NonNullable<Conciliation["data"]>[number]
      >(() =>
        service.recebiveisCartao
          .getConciliacao(
            rangeDate.dataVencimentoInicial,
            rangeDate.dataVencimentoFinal,
          )
          .then(({ data }) => {
            return data;
          })
          .catch(() => {
            const errorMessage = "Erro ao buscar dados de conciliação";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      );
    }
  }, [rangeDate]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Conciliação"
          description="
      As informações deste relatório são um comparativo entre os valores de
      vencimento das operações de antecipação e os valores pagos pela
      credenciadora. A liquidação desse valor em conta BTG depende da
      credenciadora efetivar os pagamentos."
        />
      }
      gridRef={gridRef}
      actions={
        <IconButton
          icon={{ category: "far", icon: "fa-search" }}
          onClick={() => modalManager.show(conciliacaoModalId)}
        >
          Abrir busca
        </IconButton>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={conciliacaoGrid}
        dataSource={dataSource}
      />
      <ConciliacaoModal />
    </GridTemplate>
  );
};

export const ConciliacaoPage = () => {
  return (
    <ConciliacaoProvider>
      <ConciliacaoComponent />
    </ConciliacaoProvider>
  );
};
