import { MoanaOwnerStatus } from "services/moana/models";

export const statusMapped = {
  [MoanaOwnerStatus.ProductContractSigned]: "Contrato do produto",
  [MoanaOwnerStatus.GuarantorRegistered]: "Cadastro dos avalistas",
  [MoanaOwnerStatus.AccountContractSigned]: "Contrato de abertura de conta",
  [MoanaOwnerStatus.OpeningAccount]: "Solicitação de abertura de conta",
  [MoanaOwnerStatus.AcquiringProduct]: "Processo de contratação",
};

export const OwnerInStateLibrary: {
  [key in number]: string;
} = {
  1: "Solicitação de abertura de conta",
  2: "Contrato de abertura de conta",
  3: "Cadastro dos avalistas",
  4: "Processo de contratação",
  5: "Contrato do produto",
};
