import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubFx.odata.getOrders>>["data"]["value"]
  >[number]
>;

export const exchangeOdataOrdersGrid = (
  filterValue?: Grid["datagrid"]["filterValue"],
  showEdit = false,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma ordem encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar ordens.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubFx.channelOdata.channelOrdersOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
        },
      ),
    },
    columns: [
      {
        allowExporting: false,
        type: "buttons",
        role: roles.cambio.product.ordersDetailsPage.role,
        cellRender: ({ data }) =>
          data.id && (
            <DetailsLink
              href={buildCustomerLink(
                `${data.counterpartyIdentification}` ?? "",
                corporateRouter.routes.cambio.customer.orders.details.path({
                  id: data.id,
                }),
              )}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "conciliationId",
        caption: "conciliationId",
        dataType: "number",
      },
      {
        caption: "Data da última operação",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        dataField: "newestReceipt",
      },
      {
        caption: "Recibo mais antigo",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        dataField: "olderReceipt",
      },
      {
        caption: "Pagador",
        dataField: "payer",
      },
      {
        caption: "Nome da empresa",
        dataField: "counterpartyName",
      },
      {
        caption: "CNPJ",
        dataField: "counterpartyIdentification",
        format: (value) => masks.cnpj(value),
      },
      {
        caption: "Moeda",
        dataField: "currency",
      },
      {
        dataField: "amount",
        caption: "Quantidade",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "preTicketAllocatedAmount",
        caption: "Valor alocado em Pré-Ticket",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "accAllocatedAmount",
        caption: "Valor alocado em ACC",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "accSettledAmount",
        caption: "Valor liquidado em ACC",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "balanceAmount",
        caption: "Saldo",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "notAllocatedAmount",
        caption: "Valor disponível",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        dataField: "inTransitAmount",
        caption: "Em trânsito",
        format: "#,##0.00",
        dataType: "number",
      },
      {
        role: roles.cambio.product.ordersContingencyFormPage.role,
        visible: showEdit,
        cellRender: ({ data }) => (
          <RouterButton
            href={`${
              corporateRouter.routes.cambio.product.contingency.orders.form.path
            }?data=${encodeURIComponent(JSON.stringify(data))}`}
            outline
          >
            Editar
          </RouterButton>
        ),
      },
    ],
  } satisfies Grid);
