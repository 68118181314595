import { OCol, ORow, OSkeletonCardNode, OTypography } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { DisbursementInstruction } from "../../../offers.type";
import { useSimulation } from "../simulation/simulation.context";
import { ProductItemInterface } from "../simulation/simulation.types";
import { AccountCards } from "./account-cards.component";
import { getBankAccountList } from "./bank-account.utils";

interface BankAccountListProps {
  simulationProducts: ProductItemInterface | undefined;
}

export const BankAccountList = ({
  simulationProducts,
}: BankAccountListProps) => {
  const { simulationResult, summary, bankAccountsList, setBankAccountsList } =
    useSimulation();

  const [initialSimulationFinished, setInitialSimulationFinished] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [disbursementInstructions, setDisbursementInstructions] = useState<
    DisbursementInstruction[]
  >([]);

  const hasAccountToShow =
    !!disbursementInstructions.length || !!bankAccountsList.length;

  const getBankingAccounts = useCallback(async () => {
    if (!summary) return;

    try {
      setLoading(true);

      const { data } = await service.clerk.getBankAccounts();

      const filteredAccounts = getBankAccountList(summary, data);

      setBankAccountsList(filteredAccounts);
    } catch (e) {
      const errorMessage = "Erro ao buscar as contas bancárias";
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [setBankAccountsList, summary]);

  useEffect(() => {
    getBankingAccounts();
  }, [getBankingAccounts]);

  useEffect(() => {
    if (!!simulationResult?.length && !initialSimulationFinished) {
      setInitialSimulationFinished(true);
      setDisbursementInstructions(simulationResult[0].disbursementInstructions);
    }
  }, [initialSimulationFinished, simulationResult]);

  return (
    <>
      {!!summary && (
        <>
          <ORow>
            <OCol>
              <OTypography size="xl" className="mb-2">
                Conta para desembolso
              </OTypography>
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <OTypography type="default" className="mb-4">
                {simulationProducts?.simulation.bankDescription}
              </OTypography>
            </OCol>
          </ORow>
          <OSkeletonCardNode
            loading={loading || !simulationResult.length}
            height="200px"
            width="100%"
            rounded
          >
            <div className="d-flex flex-row gap-3 mb-4">
              {hasAccountToShow ? (
                <AccountCards
                  disbursementInstructions={disbursementInstructions}
                />
              ) : (
                <OTypography>
                  Nenhuma conta para desembolso disponível no momento.
                </OTypography>
              )}
            </div>
          </OSkeletonCardNode>
        </>
      )}
    </>
  );
};
