import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useGridActions = (
  stepConfig: HubEnergy.DiligenceStepConfigBaseResponse,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceStepConfigById,
  );

  const deleteStepConfig = useCallback(async () => {
    const { success, error } = await callService(stepConfig.id);

    if (success) {
      OToastManager.success("Configuração de step excluída com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao excluir a configuração de step",
      );
    }
  }, [callService, stepConfig, reload]);

  return { deleteStepConfig, loading };
};
