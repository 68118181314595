import { BankIconSVG } from "../financial-institution-icon.types";

export const BankPanSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#00AFFF" />
      <path
        fill="#fff"
        d="M56.125 61.058c-4.1 0-7.433-3.268-7.433-7.288 0-4.02 3.333-7.287 7.433-7.287s7.433 3.267 7.433 7.287-3.333 7.288-7.433 7.288zm0-25.058C46.13 36 38 43.971 38 53.77V81h10.692V70.004l.841.323a18.366 18.366 0 006.592 1.213c9.995 0 18.125-7.97 18.125-17.77 0-9.799-8.13-17.77-18.125-17.77z"
      />
      <path fill="#373740" d="M38 31h10V21H38v10z" />
    </svg>
  );
};
