import * as yup from "yup";

export const bigNumberFormValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  nameId: yup.string().required("Campo obrigatório"),
  iconName: yup.string().required("Campo obrigatório"),
  description: yup.string().nullable(),
});

export const bigNumberFormDefaultValues = {
  name: "",
  nameId: "",
  iconName: "",
  description: "",
};
