import { OToastManager } from "@maestro/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PutCompaniesUpdateByIdBody } from "services/quickfin/models";
import { CompanyInfoForm, CompanyInfoFormConfig } from "./company-info.types";
import { baseFormValidationSchema } from "./_compose";

export const useCompanyInfo = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const [formConfig, setFormConfig] = useState<CompanyInfoFormConfig>({
    base: "CPF",
  });

  const {
    callService: getCompanyWithOnboarding,
    hasError: getCompanyHasError,
    loading: getCompanyLoading,
    value: company,
  } = useServiceCall(service.quickfin.getCompanyWithOnboarding);

  const { callService: updateCompany, loading: updateCompanyLoading } =
    useServiceCall(service.quickfin.updateCompany);

  const form = useForm<CompanyInfoForm>({
    resolver: yupResolver(baseFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getCompany = useCallback(
    () => getCompanyWithOnboarding(quickfinId),
    [getCompanyWithOnboarding, quickfinId],
  );

  const mapCompanyEntityType = (companyEntityType: string) => {
    if (companyEntityType === "PessoaFisica") return "CPF";
    if (companyEntityType === "PessoaJuridica") return "CNPJ";
    return "Offshore";
  };

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const payload: PutCompaniesUpdateByIdBody = {
          cge: values.cge,
          cnpj: values.taxId,
          companyEntityType: values.companyEntityType,
          companySacadoStatus: values.companySacadoStatus,
          companyType: values.companyType,
          isBtgBusiness: values.isBtgBusiness,
          officialName: values.officialName,
          portalId: values.portalId,
          country: values.country,
        };

        if (company?.companyCedenteStatus) {
          payload.onboarding = {
            onboardingCompletoStatus: values.onboardingCompletoStatus,
            onboardingSimplesStatus: values.onboardingSimplesStatus,
            onboardingContaPMEStatus: values.onboardingContaPMEStatus,
          };
        }

        if (company?.companySacadoStatus) {
          payload.config = {
            customSheetParser: values.customSheetParser,
            deal: values.deal,
          };
        }

        const { success } = await updateCompany(quickfinId, payload);

        if (success)
          OToastManager.success(
            "Informações da empresa atualizadas com sucesso",
          );
        else
          OToastManager.danger(
            "Não foi possível atualizar as informações da empresa",
          );
      }),
    [
      company?.companyCedenteStatus,
      company?.companySacadoStatus,
      quickfinId,
      handleSubmit,
      updateCompany,
    ],
  );

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  /** Configure form */
  useEffect(() => {
    if (company) {
      const baseForm = mapCompanyEntityType(company.companyEntityType);
      const cedenteForm = !!company.companyCedenteStatus;
      const sacadoForm = !!company.companySacadoStatus;
      const onboardingForm = !company.onboardingContaPMEStatus;

      setFormConfig({
        base: baseForm,
        cedente: cedenteForm,
        sacado: sacadoForm,
        onboarding: onboardingForm,
      });
    }
  }, [company]);

  /** Initialize form */
  useEffect(() => {
    if (company) {
      const initialValues: CompanyInfoForm = {
        taxId: company.identification,
        officialName: company.officialName,
        companySacadoStatus: company.companySacadoStatus,
        cge: company.cge,
        country: company.country,
        companyEntityType: company.companyEntityType,
        deal: company?.config?.deal,
        portalId: company?.portalId?.toString() ?? null,
      };

      if (company.companyCedenteStatus) {
        initialValues.onboardingCompletoStatus =
          company.onboardingCompletoStatus;
        initialValues.onboardingSimplesStatus = company.onboardingSimplesStatus;
        initialValues.onboardingContaPMEStatus =
          company.onboardingContaPMEStatus;
      }
      if (company.companySacadoStatus) {
        initialValues.customSheetParser =
          company.config?.customSheetParser ?? "Padrao";
      }

      reset(initialValues);
    }
  }, [company, reset]);

  return {
    company,
    form,
    formConfig,
    getCompany,
    getCompanyHasError,
    getCompanyLoading,
    submit,
    updateCompanyLoading,
  };
};
