import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { OToastManager } from "@maestro/react";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { service } from "services";
import { AntecipacaoAutomatica } from "services/recebiveis-cartao/models/responses/get-antecipacao-automatica-grid-response";

export const antecipacaoAutomaticaGrid: () => ODataGridGeneratorConfig<AntecipacaoAutomatica> =
  () => ({
    datagrid: {
      filterRow: {
        visible: true,
      },
      headerFilter: { visible: true },
      remoteOperations: false,
      dateSerializationFormat: "yyyy-MM-dd",
      height: 900,
      scrolling: {
        showScrollbar: "always",
        mode: "infinite",
        rowRenderingMode: "virtual",
      },
      dataSource: dataSourceCustomStoreGenerator<AntecipacaoAutomatica>(() =>
        service.recebiveisCartao
          .getAntecipacaoAutomaticaGrid()
          .then(({ data }) => ({
            data: data.data ?? [],
            total_count: data.total_count,
          }))
          .catch(() => {
            const errorMessage =
              "Não foi possível buscar as empresas de antecipação automática";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    },
    columns: [
      {
        dataField: "inserted_at",
        dataType: "datetime",
        caption: "Criado em",
        allowHeaderFiltering: false,
        sortOrder: "desc",
      },
      {
        dataField: "company_name",
        dataType: "string",
        caption: "Nome do cedente",
        allowHeaderFiltering: false,
      },
      {
        dataField: "company_tax_id",
        dataType: "string",
        caption: "CNPJ do cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "credenciadoras",
        dataType: "string",
        caption: "CNPJ dos sacados",
        format: (value) => masks.cnpj(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "bandeiras",
        dataType: "string",
        caption: "Bandeiras",
      },
      {
        dataField: "valor_desembolso_solicitado",
        dataType: "number",
        caption: "Valor desembolso solicitado",
      },
      {
        dataField: "ligado",
        dataType: "boolean",
        caption: "Ligada",
      },
      {
        dataField: "qtd_vencimento_inicial",
        dataType: "number",
        caption: "Range vencimento início",
      },
      {
        dataField: "qtd_vencimento_final",
        dataType: "number",
        caption: "Range vencimento fim",
      },
    ],
  });
