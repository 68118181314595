import {
  OCol,
  OLabel,
  OLoader,
  OOptionMultiple,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldRadioGroup,
  ORFieldSelectMultiple,
  ORadio,
  ORow,
} from "@maestro/react";
import dayjs from "dayjs";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useOptin } from "./optin.hook";

export const Optin = () => {
  const {
    acquirers,
    acquirersLoading,
    brands,
    brandsLoading,
    form,
    optinLoading,
    submit,
  } = useOptin();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Optin"
          description="Inclusão de Optin de empresas com consulta na CIP"
        />
      }
      actions={
        <LoadingButton onClick={submit} loading={optinLoading}>
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="cnpj"
                id="taxId"
                name="taxId"
                label="CNPJ"
                placeholder="00.000.000/0000-00"
              />
            </OCol>
            <OCol xs={12} md={6} align="center" className="gap-2">
              <ORFieldSelectMultiple
                id="brandNames"
                name="brandNames"
                label="Bandeiras"
                disabled={brandsLoading}
                key={brands?.bandeiras.length}
              >
                {brands?.bandeiras.map((brand) => (
                  <OOptionMultiple key={brand.id} value={brand.name}>
                    {brand.name}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
              {brandsLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
            <OCol xs={12} md={6} align="center" className="gap-2">
              <ORFieldSelectMultiple
                id="acquirerTaxIds"
                name="acquirerTaxIds"
                label="Credenciadoras"
                disabled={acquirersLoading}
                key={acquirers?.credenciadoras.length}
              >
                {acquirers?.credenciadoras.map((acquirer) => (
                  <OOptionMultiple key={acquirer.id} value={acquirer.tax_id}>
                    {acquirer.tax_id} - {acquirer.name}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
              {acquirersLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldRadioGroup
                id="isRootTaxId"
                name="isRootTaxId"
                label="Enviar pela raiz do CNPJ? (Por padrão enviamos pela raiz)"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="yes" value="yes" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="no" value="no" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6} align="center" className="gap-5">
              <ORFieldInputDate
                id="startDate"
                name="startDate"
                label="Data início do contrato de optin"
                disabledDate={(date) =>
                  dayjs(date.toDate()).isBefore(dayjs().startOf("day"))
                }
              />
              <ORFieldInputDate
                id="endDate"
                name="endDate"
                label="Data final do contrato de optin"
                disabledDate={(date) =>
                  dayjs(date.toDate()).isBefore(dayjs().endOf("day"))
                }
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
