import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { toastFailures } from "../../desk-migration-details.utils";
import { DenialReasonForm } from "../denial-reason-modal";

export const useDenyButton = (
  workflowMigrationId: string,
  successCallback: () => void,
) => {
  const { callService: _reprove, loading } = useServiceCall(
    service.onboarding.reproveDeskMigration,
  );

  const form = useForm<DenialReasonForm>();

  const { handleSubmit } = form;

  const reproveDeskMigration = useMemo(
    () =>
      handleSubmit(async ({ reproveStatus }) => {
        const { success, error } = await _reprove({
          workflowMigrationId,
          reproveStatus,
        });
        if (success) {
          OToastManager.success("Migração reprovada");
          successCallback();
        } else {
          OToastManager.danger("Erro ao reprovar a migração");
          toastFailures(error);
        }
      }),
    [_reprove, handleSubmit, successCallback, workflowMigrationId],
  );

  return {
    form,
    loading,
    reproveDeskMigration,
  };
};
