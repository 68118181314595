import { OInputDate } from "@maestro/react";

export const MonthPickerFilterItem = () => {
  return (
    <div className="d-flex flex-column gap-2 p-3">
      <OInputDate
        data-filterparam="referenceDate"
        allowClear
        aspect="flushed"
        picker="month"
        placeholder="Selecione o mês"
        size="md"
      />
    </div>
  );
};
