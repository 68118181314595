import { SelectSearchOption } from "components/select-search/select-search.types";
import uniqBy from "lodash/uniqBy";
import { GetCompaniesAdminGetAllCompaniesResponse } from "services/quickfin/models";
import { CleanCompany } from "./company-search.types";
import { fastCpfCnpjMask } from "../../../../../produto/emails-automaticos/novo/new-auto-email.utils";

export const cleanCompanies = (
  searchResults: GetCompaniesAdminGetAllCompaniesResponse | undefined,
) =>
  (searchResults?.companies.filter((company) =>
    Boolean(company.identification),
  ) ?? []) as CleanCompany[];

export const generateAggregates = (companies: CleanCompany[]): CleanCompany[] =>
  uniqBy(
    companies.map((company) => {
      const rootTaxId = company.identification.slice(0, 8);

      return {
        id: company.id * 999,
        identification: rootTaxId,
        name: `${company.name}: matriz e filiais com CNPJ raiz`,
      };
    }),
    "identification",
  );

export const buildCompanyOptions = (
  companies: CleanCompany[],
  selectCompany: (identification: string, label: string) => void,
): SelectSearchOption<string>[] =>
  companies.map((company) => {
    const label = `${company.name} - ${fastCpfCnpjMask(
      company.identification,
    )}`;

    return {
      value: company.identification,
      label,
      onSelect: () => selectCompany(company.identification, label),
      customKey: company.id,
    };
  }) ?? [];
