export interface DataGridCellContentProps {
  primary: string;
  secondary?: string;
  image?: {
    url?: string;
    alt: string;
  };
  textWrap?: boolean;
}

export interface DataGridStatusProps {
  status: string;
  badgeType: HTMLOBadgeElement["type"];
  icon?: {
    icon: HTMLOIconElement["icon"];
    type: HTMLOIconElement["type"];
    category?: HTMLOIconElement["category"];
  };
  tooltip?: string;
}

export enum ActionType {
  Default = "HANDLE_CLICK",
  Navigate = "HANDLE_NAVIGATE",
}

interface BaseAction {
  label: string;
  id: string;
  type: ActionType;
  icon: HTMLOIconElement["icon"];
  hideAction?: boolean;
}

export interface NavigateAction extends BaseAction {
  type: ActionType.Navigate;
  path: string;
}

export interface DefaultAction extends BaseAction {
  type: ActionType.Default;
  onClick: () => void;
}
