import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, deflatorsGrid } from "./deflators.grid";

export const Deflators = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Deflatores de Recebíveis" />}
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.antecipacaoDeCartoes.product.deflators
                .newDeflator.path
            }
            role={
              roles.antecipacaoDeCartoes.product.deflatorsNewDeflatorPage.role
            }
          >
            Criar deflator
          </StandardLink>
          <StandardLink
            href={
              corporateRouter.routes.antecipacaoDeCartoes.product.deflators
                .globalDeflator.path
            }
            role={
              roles.antecipacaoDeCartoes.product.deflatorsGlobalDeflatorPage
                .role
            }
          >
            Deflator global
          </StandardLink>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={deflatorsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
