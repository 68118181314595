import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GetUsersCompleteSelfResponse } from "services/clerk";
import { UserState } from "./types/state";
import { user as globalUser } from "./user";
import { userEvents } from "./user.event";
import { logger } from "utils/logger";

interface UserContext {
  user: GetUsersCompleteSelfResponse | undefined;
  state: UserState;
}

const userContext = createContext<UserContext>({
  user: globalUser.value,
  state: globalUser.state,
});

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState(globalUser.value);
  const [userState, setUserState] = useState(globalUser.state);

  useEffect(() => {
    const cleanUp = userEvents.subscribe((newUser) => {
      setUser(newUser.value);
      setUserState(newUser.state);
      if (newUser.state === "ERROR") {
        logger.error("Ocorreu um problema ao carregar o usuário.", newUser);
      }
    });

    return cleanUp;
  }, []);

  const value = useMemo(
    () => ({
      user,
      state: userState,
    }),
    [user, userState],
  );

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

/** Simply returns the context as it is */
export const useUnsafeUser = () => useContext(userContext);

/** Guarantees that there is a user loaded */
export const useUser = () => {
  const { user, ...rest } = useContext(userContext);

  if (!user) {
    throw new Error(
      "No user loaded. Wrap your route in `<UserGuard>` or use `useUnsafeUser`.",
    );
  }

  return { user, ...rest };
};
