import { OTypography } from "@maestro/react";
import {
  ExtractODataGridGeneratorConfigType,
  ODataGridGenerator,
} from "components/data-grid";
import { registriesGrid } from "./registries.grid";

interface RegistriesProps {
  registries: ExtractODataGridGeneratorConfigType<typeof registriesGrid>[];
}

export const Registries = ({ registries }: RegistriesProps) => {
  return (
    <>
      <OTypography className="my-4" tag="h3" size="lg" type="dark">
        Registro
      </OTypography>
      <ODataGridGenerator grid={registriesGrid} dataSource={registries} />
    </>
  );
};
