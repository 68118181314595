import { OCard, OCardBody, OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { useBranchCompany } from "./branch-company.hook";
import { BranchCompanyProps } from "./branch-company.types";

export const BranchCompany = ({
  setCompanyGroup,
  setNewCompanyGroup,
}: BranchCompanyProps) => {
  const { addBranchCompany, innerForm, loading } = useBranchCompany(
    setCompanyGroup,
    setNewCompanyGroup,
  );

  return (
    <FormProvider {...innerForm}>
      <OCard>
        <OCardBody>
          <FormTemplate
            actions={
              <LoadingButton
                dataAction="grupo_empresa:botao:adicionar"
                dataLabel="adicionar"
                onClick={() => addBranchCompany()}
                loading={loading}
              >
                Adicionar
              </LoadingButton>
            }
          >
            <ORow>
              <OCol xs={12} md={6}>
                <ORFieldInput
                  dataAction="grupo_empresa:texto:cnpj"
                  dataLabel="cnpj"
                  id="branchCnpj"
                  name="branchCnpj"
                  tag="cnpj"
                  label="Cnpj"
                />
              </OCol>
              <OCol xs={12} md={6}>
                <ORFieldInput
                  dataAction="grupo_empresa:texto:razao_social"
                  dataLabel="razao_social"
                  id="branchOfficialName"
                  name="branchOfficialName"
                  tag="text"
                  label="Razão social"
                  disabled
                />
              </OCol>
            </ORow>
          </FormTemplate>
        </OCardBody>
      </OCard>
    </FormProvider>
  );
};
