import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  EmailTemplateFormValues,
  emailTemplateFormValidationSchema,
} from "../_compose";

export const useUpdateEmailTemplate = (id: string) => {
  const { callService: getEmailConfigsById, value: emailConfigs } =
    useServiceCall(service.clerk.getEmailConfigsById);

  const { callService: updateEmailConfigs, loading: submitLoading } =
    useServiceCall(service.clerk.updateEmailConfigsById);

  const form = useForm<EmailTemplateFormValues>({
    resolver: yupResolver(emailTemplateFormValidationSchema),
  });
  const { handleSubmit, reset } = form;
  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const payload = {
          ...values,
          id,
        };
        const { success } = await updateEmailConfigs(payload);

        if (!success)
          return OToastManager.danger("Erro ao atualizar template de e-mail.");

        navigate(
          corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs
            .emailTemplates.path,
        );
        return OToastManager.success(
          "Template de e-mail atualizado com sucesso!",
        );
      }),
    [handleSubmit, id, navigate, updateEmailConfigs],
  );

  useEffect(() => {
    getEmailConfigsById({ id });
  }, [getEmailConfigsById, id]);

  useEffect(() => {
    emailConfigs && reset(emailConfigs);
  }, [emailConfigs, reset]);

  return {
    form,
    submit,
    submitLoading,
  };
};
