import dayjs from "dayjs";
import { Trade } from "./operation-details.types";

export const calculateRates = (trades: Trade[]) => {
  let prodValorPeriodoTaxa = 0;
  let prodValorPeriodo = 0;
  let valorSum = 0;

  for (const trade of trades) {
    const maturityDate = dayjs(trade.receivable.maturityDate);
    const daysDifference = maturityDate.diff(dayjs(), "day", true);
    prodValorPeriodoTaxa +=
      trade.maturityAmount * trade.taxaMensal * daysDifference;
    prodValorPeriodo += trade.maturityAmount * daysDifference;
    valorSum += trade.maturityAmount;
  }

  const avgTermDays = prodValorPeriodo / valorSum;
  const avgMonthlyRate = prodValorPeriodoTaxa / prodValorPeriodo;
  const totalRate = avgMonthlyRate * (avgTermDays / 30);

  return {
    avgTermDays,
    avgMonthlyRate,
    totalRate,
  };
};
