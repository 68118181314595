import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMaskSelector } from "./mask-selector.hook";

export const MaskSelector = () => {
  const { getMasks, hasFetchError, loading, masks } = useMaskSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <ORFieldSelect
        id="mask"
        name="mask"
        placeholder="Selecionar"
        label="Máscara"
        disabled={hasFetchError || loading}
        key={masks?.length}
        required
      >
        {masks?.map((mask) => (
          <OOption key={mask} value={mask}>
            {mask}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={() => getMasks()} />
      )}
    </div>
  );
};
