import { OToastManager, modalManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { DataGridAction } from "components/datagrid-action";
import { paperclipv2Service } from "services/paperclipv2/paperclipv2.service";
import { OPERATIONS_CONTRACT_STATUS_MODAL_ID } from "./operations-contracts-status-modal.component";
import { Contract, useContracts } from "./operations-contracts.context";

interface ContractsGridDataActionProps {
  contract: Contract;
}

export const ContractsGridDataAction = ({
  contract,
}: ContractsGridDataActionProps) => {
  const { setSelectedContract } = useContracts();

  const downloadContract = async () => {
    try {
      OToastManager.info("Download do contrato iniciado.");

      const { data } = await paperclipv2Service.getContractFileBinary(
        contract.externalId,
      );

      helpers.downloadBlobFile(`${contract.number}.pdf`, data);
    } catch {
      OToastManager.danger(
        "Houve um problema ao tentar baixar o contrato. Tente novamente mais tarde!",
      );
    }
  };
  const downloadSignedContract = async () => {
    try {
      OToastManager.info("Download do contrato iniciado.");

      const { data } = await paperclipv2Service.getContractSignedFileBinary(
        contract.externalId,
      );

      helpers.downloadBlobFile(`${contract.number}-assinado.pdf`, data);
    } catch {
      OToastManager.danger(
        "Houve um problema ao tentar baixar o contrato. Tente novamente mais tarde!",
      );
    }
  };

  return (
    <DataGridAction
      actions={[
        {
          icon: { category: "orq", icon: "orq-edit-pencil" },
          label: "Atualizar",
          onClick: () => {
            setSelectedContract(contract);
            modalManager.show(OPERATIONS_CONTRACT_STATUS_MODAL_ID);
          },
        },
        {
          icon: { category: "orq", icon: "orq-download" },
          label: "Download do contrato",
          onClick: () => downloadContract(),
        },
        {
          icon: { category: "orq", icon: "orq-download" },
          label: "Download do contrato assinado",
          onClick: () => downloadSignedContract(),
          visible: ["Completed", "FullySigned"].includes(contract.status),
        },
      ]}
    />
  );
};
