import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface StepsBuilderContext {
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
}

const stepsBuilderContext = createContext({} as StepsBuilderContext);

interface StepsBuilderProviderProps {
  children: React.ReactNode;
}

export const StepsBuilderProvider = ({
  children,
}: StepsBuilderProviderProps) => {
  const [stepNumber, setStepNumber] = useState(0);
  const nextStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber + 1),
    [],
  );

  const prevStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber - 1),
    [],
  );

  const reset = useCallback(() => {
    setStepNumber(0);
  }, []);

  const value = useMemo(
    () => ({
      stepNumber,
      setStepNumber,
      nextStep,
      prevStep,
      reset,
    }),
    [nextStep, prevStep, reset, stepNumber],
  );

  return (
    <stepsBuilderContext.Provider value={value}>
      {children}
    </stepsBuilderContext.Provider>
  );
};

export const useStepsBuilder = () => useContext(stepsBuilderContext);
