import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";
import { EnabledCardsPieChart } from "./_compose";

export const EnabledCardsPieChartCard = () => {
  return (
    <InfoCard
      summaryType="cardsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Quantidade de Cartões Habilitados"
          body={
            <EnabledCardsPieChart
              enabledPhysicalQuantity={_value.enabledPhysicalQuantity ?? 0}
              enabledDebitQuantity={_value.enabledDebitQuantity ?? 0}
              enabledVirtualQuantity={_value.enabledVirtualQuantity ?? 0}
            />
          }
        />
      )}
    />
  );
};
