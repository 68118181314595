import * as yup from "yup";
import { ViewAssetsDetailsForm } from "./view-assets-details.types";

export const ViewAssetsDetailsSchema: yup.ObjectSchema<ViewAssetsDetailsForm> =
  yup.object({
    nominalAmount: yup
      .number()
      .required("Este campo é obrigatório")
      .min(0)
      .max(999),
    maturityDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .required("Este campo é obrigatório"),
  });

export const ViewAssetsDetailsDefaultValues: ViewAssetsDetailsForm = {
  nominalAmount: 15,
  maturityDate: new Date().toISOString(),
};
