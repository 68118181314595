import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useDeskSelector } from "./desk-selector.hook";

interface DeskSelectorProps {
  loading: boolean;
}

export const DeskSelector = ({ loading: loadingProp }: DeskSelectorProps) => {
  const { getDesks, hasError, loading, desks } = useDeskSelector();

  const options = useMemo(
    () =>
      desks?.map((desk) => ({
        label: desk.name,
        value: desk.code,
        customKey: desk.id,
      })) ?? [],
    [desks],
  );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        id="desks"
        name="desks"
        options={options}
        label="Mesas"
        placeholder="Buscar"
      />
      {(loading || loadingProp) && <OLoader size="sm" />}
      {hasError && !loading && (
        <RefreshGridButton
          dataAction="selecionar_mesas:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getDesks()}
        />
      )}
    </div>
  );
};
