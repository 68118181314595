import { ODataGridGeneratorConfig } from "components/data-grid";
import { OTooltip, OTypography } from "@maestro/react";
import { WorkflowContext } from "./workflow-contexts.types";

export const workflowContextOutputsGrid = {
  datagrid: {
    noDataText: "Nenhum output encontrado",
  },
  columns: [
    {
      dataField: "createdDate",
      caption: "Data de criação",
      dataType: "datetime",
      sortOrder: "desc",
    },
    {
      caption: "Tipo",
      cellRender: ({ data }) => (
        <OTooltip floating position="top" type="info-light" maxWidth="300px">
          <span
            slot="tooltip-content"
            className="d-flex flex-column align-items-start p-2"
          >
            {data.processorOutput.description}
          </span>

          <OTypography key={data.processorOutput.type}>
            {data.processorOutput.type}
          </OTypography>
        </OTooltip>
      ),
    },
    {
      dataField: "processorOutput.executeOnStart",
      caption: "Saída inicial?",
    },
    {
      dataField: "processorOutput.contextStatusOnOutput",
      caption: "Status gerado no contexto",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  WorkflowContext["processorOutputsOnContext"][number]
>;
