import {
  OConfirmationModal,
  OIcon,
  OLoader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { UnstyledButton } from "components/unstyled-button";
import type dxDataGrid from "devextreme/ui/data_grid";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { useGridActions } from "./grid-actions.hook";

const startAutomationConfirmationModalId = (id: string | number) =>
  `start-automation-confirmation-modal-id-${id}`;
const pressForResponseConfirmationModalId = (id: string | number) =>
  `press-for-response-confirmation-modal-id-${id}`;
const restartPendencyAnalysisConfirmationModalId = (id: string | number) =>
  `restart-pendency-analysis-confirmation-modal-id-${id}`;

interface GridActionsProps {
  pendency: HubEnergy.DiligenceStepPendencyWithPropertyResponse;
  component: dxDataGrid<
    HubEnergy.DiligenceStepPendencyWithPropertyResponse,
    HubEnergy.DiligenceStepPendencyWithPropertyResponse
  >;
  rowIndex: number;
  isEditing: boolean;
  canEditPendency: boolean;
}

export const GridActions = ({
  canEditPendency,
  component,
  isEditing,
  pendency,
  rowIndex,
}: GridActionsProps) => {
  const {
    loading,
    startAutomation,
    pressForResponse,
    restartPendencyAnalysis,
  } = useGridActions(pendency.id, () => component.getDataSource()?.reload?.());

  if (isEditing)
    return (
      <div className="d-flex justify-content-center gap-2">
        <UnstyledButton
          title="Desfazer"
          onClick={() => component.cancelEditData()}
        >
          <OIcon category="fa" icon="fa-undo" />
        </UnstyledButton>
        <UnstyledButton title="Salvar" onClick={() => component.saveEditData()}>
          <OIcon category="fa" icon="fa-save" />
        </UnstyledButton>
      </div>
    );

  if (loading) return <OLoader size="sm" />;

  return (
    <>
      <DataGridActionV2
        actions={[
          {
            visible:
              !!pendency.isAutomation &&
              pendency.type.name === "ENVIAR_CARTA_DENUNCIA",
            disabled: pendency.automationStatus !== "PENDENTE_EXECUCAO",
            icon: { category: "orq", icon: "orq-email" },
            label: "Enviar carta denúncia",
            onClick: () =>
              modalManager.show(
                startAutomationConfirmationModalId(pendency.id),
              ),
          },
          {
            visible:
              !!pendency.isAutomation &&
              pendency.type.name === "ENVIAR_CARTA_DENUNCIA" &&
              pendency.automationStatus === "EXECUTANDO",
            icon: { category: "orq", icon: "orq-attention" },
            label: "Cobrar resposta",
            onClick: () =>
              modalManager.show(
                pressForResponseConfirmationModalId(pendency.id),
              ),
          },
          {
            visible: pendency.status === "AGUARDANDO_DISTRIBUIDORA",
            icon: { category: "orq", icon: "orq-batch" },
            label: "Retornar pendência para análise",
            onClick: () =>
              modalManager.show(
                restartPendencyAnalysisConfirmationModalId(pendency.id),
              ),
          },
          {
            visible: canEditPendency,
            icon: { category: "orq", icon: "orq-edit-pencil" },
            label: "Editar",
            onClick: () => component.editRow(rowIndex),
          },
          {
            visible: canEditPendency && !pendency?.isAutomation,
            icon: { category: "orq", icon: "orq-edit-trash" },
            label: "Excluir",
            onClick: () => component.deleteRow(rowIndex),
          },
        ]}
      />
      <OConfirmationModal
        {...datagridModalFix}
        modalId={startAutomationConfirmationModalId(pendency.id)}
        title="Enviar carta denúncia"
        divider={false}
        onConfirm={() => {
          startAutomation();
          modalManager.hide(startAutomationConfirmationModalId(pendency.id));
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja enviar a carta denúncia?
        </OTypography>
      </OConfirmationModal>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={pressForResponseConfirmationModalId(pendency.id)}
        title="Enviar cobrança de resposta"
        divider={false}
        onConfirm={() => {
          pressForResponse();
          modalManager.hide(pressForResponseConfirmationModalId(pendency.id));
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja enviar a cobrança de resposta?
        </OTypography>
      </OConfirmationModal>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={restartPendencyAnalysisConfirmationModalId(pendency.id)}
        title="Retornar pendência para análise"
        divider={false}
        onConfirm={() => {
          restartPendencyAnalysis();
          modalManager.hide(
            restartPendencyAnalysisConfirmationModalId(pendency.id),
          );
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja retornar pendência para análise?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
