import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { GetContractGeneratorByProductIdResponse } from "services/fidc/models/responses";
import { service } from "services/service";

export interface ConsolidateContractsFormValues {
  fromDate: string;
  limit: string;
  toDate: string;
}

export const formatProductName = (product: string) =>
  product.toUpperCase().replaceAll("-", " ");

export const quantityOptions = ["2500", "5000", "7500", "10000"];

export const consolidatedContractStatusMap = [
  "InPreparation",
  "ContractPrepared",
  "WaitingSignatures",
  "FullySigned",
  "Refused",
  "Expired",
  "Canceled",
  "WaitingApproval",
  "Complete",

  "InErrorSignatoryInfoNotFounded",
  "InErrorPreparationError",
  "InErrorNotificationError",
  "InErrorWithoutPower",
  "InErrorWithoutVigency",
];

export const downloadContract = async (
  item: GetContractGeneratorByProductIdResponse["items"][number],
) => {
  const isComplete = consolidatedContractStatusMap[item.contractInfo.status];

  const filename = isComplete
    ? `contrato-assinado-${item.relatedTaxId}.pdf`
    : `contrato-${item.relatedTaxId}.pdf`;

  try {
    const { data: blob } = await service.paperclipv2.getContractFile(
      item.contractInfo.paperclipId,
    );

    helpers.downloadBlobFile(filename, blob);
  } catch {
    OToastManager.danger("Não foi possível baixar o contrato");
  }
};
