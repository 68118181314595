import { ODataGridGeneratorConfig } from "components/data-grid";
import { GatekeeperProfilesGetDetails } from "services/gatekeeper/models";

export const grid: ODataGridGeneratorConfig<GatekeeperProfilesGetDetails> = {
  datagrid: {
    noDataText: "Nenhum perfil encontrado.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      allowDeleting: true,
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      allowHeaderFiltering: false,
    },
    {
      dataField: "group.name",
      caption: "Nome do Grupo",
      allowEditing: false,
    },
    { dataField: "description", caption: "Descrição" },
    {
      dataField: "isAvailable",
      caption: "É uma função disponível?",
    },
  ],
};
