import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  ORow,
  ORFieldInput,
  OCol,
} from "@maestro/react";
import { modalManager } from "@maestro/core";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { WalletInvoicesFilterForm } from "./wallet-invoices-filter.types";
import { StatusFilter } from "./_compose/status-filter.component";
import { PayerSearch } from "./_compose/payer-filter.component";
import { WalletType } from "./_compose/wallet-type-filter.component";
import { walletInvoicesFilterModalId } from "./wallet-invoices.utils";
import { MaturityDate } from "./_compose/maturity-date-filter.component";
import { IssueDate } from "./_compose/issue-date-filter.component";

interface WalletInvoicesFilterProps {
  form: UseFormReturn<WalletInvoicesFilterForm>;
  onSubmit: (values: WalletInvoicesFilterForm) => void;
  resetFilter: () => void;
}

export const WalletInvoicesFilter = ({
  form,
  onSubmit,
  resetFilter,
}: WalletInvoicesFilterProps) => {
  const { handleSubmit } = form;
  return (
    <OModal id={walletInvoicesFilterModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <ORow>
              <OCol xs={12} md={6} xl={3}>
                <ORFieldInput
                  id="receivableNumber"
                  label="Seu número"
                  name="receivableNumber"
                  tag="text"
                />
              </OCol>
              <OCol xs={12} md={6} xl={3}>
                <WalletType />
              </OCol>
              <OCol xs={12} md={6} xl={6}>
                <StatusFilter />
              </OCol>
            </ORow>
            <PayerSearch />
            <MaturityDate />
            <IssueDate />
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton onClick={() => resetFilter()} outline>
            Limpar
          </OButton>
          <OButton
            onClick={handleSubmit((values) => {
              onSubmit(values);
              modalManager.hide(walletInvoicesFilterModalId);
            })}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
