/**
 * Apply workaround to AspNetCoreOData bug where the datetime filter is desserialized
 * as a `DateTime` with kind Unspecified. The workaround is to remove the time part
 * from the filter, resulting in a `Date` object desserialization.
 *
 * Issue: https://github.com/OData/AspNetCoreOData/issues/378
 */
export const odataDateFilterWorkaround = (params: Record<string, string>) => {
  if (!params?.$filter) return;
  // strip time from ISO format
  // ex: 2023-07-25T00:00:00Z -> 2023-07-25
  // eslint-disable-next-line no-param-reassign
  params.$filter = (params.$filter as string).replace(
    /(\d{4}-\d{2}-\d{2})T\d{2}:\d{2}:\d{2}Z/g,
    "$1",
  );
};
