import { OToastManager, OTypography } from "@maestro/react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { collateralDocumentsGrid } from "./collateral-documents.grid";

interface CollateralDocumentsProps {
  collateralId: number;
}

export const CollateralDocuments = ({
  collateralId,
}: CollateralDocumentsProps) => {
  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        async () =>
          service.hubCreditManager
            .getCollateralDetails(collateralId?.toString())
            .then(({ data }) => {
              return data.collateralDocuments ?? [];
            })
            .catch(() => {
              throw new Error("Erro ao buscar documentos da garantia");
            }),
        {
          customStoreOptions: {
            insert: async (data) => {
              try {
                OToastManager.info("Enviando documento...");

                await service.hubCreditManager.createCollateralDocument({
                  fileKey: data.file.key,
                  documentType: data.documentType,
                  collateralId,
                });

                OToastManager.success(
                  "Documento de garantia enviado com sucesso",
                );

                return data;
              } catch {
                OToastManager.danger("Ocorreu um erro ao enviar o documento.");
                throw new Error("Houve um erro. Tente novamente.");
              }
            },
            remove: async (data) => {
              try {
                await service.hubCreditManager.deleteCollateralDocument(
                  data.id?.toString(),
                );
                OToastManager.success("Documento apagado com sucesso.");
              } catch {
                OToastManager.danger("Houve um erro. Tente novamente.");
              }
            },
          },
        },
      ),
    [collateralId],
  );

  return (
    <>
      <OTypography className="my-4" tag="h3" size="lg" type="dark">
        Documentos
      </OTypography>
      <ODataGridGenerator
        grid={collateralDocumentsGrid()}
        dataSource={dataSource}
      />
    </>
  );
};
