import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { RelocationSimulation } from "../../relocate.types";
import { RelocationPlansMasterDetail } from "./execution.master-detail";

export const relocateSimulationGrid: ODataGridGeneratorConfig<RelocationSimulation> =
  {
    datagrid: {
      filterRow: { visible: true },
      headerFilter: { visible: true },
    },
    columns: [
      {
        name: "agendas-count",
        dataField: "realocacao_plans",
        caption: "# Agendas achadas",
        calculateCellValue: ({ realocacao_plans }) => realocacao_plans.length,
        allowHeaderFiltering: false,
      },
      {
        name: "agendas-total-value",
        dataField: "realocacao_plans",
        caption: "Total agendas vencimento",
        calculateCellValue: ({ realocacao_plans }) =>
          realocacao_plans.reduce(
            (acc, current) => acc + current.maturity_amount,
            0,
          ),
        format: "#,##0.00",
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_external_id",
        caption: "TradeID Canal",
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_id_cros",
        caption: "TradeID CROS",
        allowHeaderFiltering: false,
      },
      {
        dataField: "remaining_price_descoberto",
        caption: "Preço ainda descoberto",
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_bandeira",
        caption: "Bandeira",
      },
      {
        dataField: "trade_company_tax_id",
        caption: "CNPJ do cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_credenciadora_tax_id",
        caption: "CNPJ do Sacado",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_maturity_date",
        dataType: "date",
        caption: "Vencimento",
        format: "shortDate",
        allowHeaderFiltering: false,
      },
      // {
      //   dataField: "trade_valor_descoberto",
      //   caption: "Valor vencimento",
      //   format: (value) => masks.currency(value, "R$"),
      //   allowHeaderFiltering: false,
      // },
      {
        dataField: "trade_maturity_amount",
        caption: "Valor vencimento",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_price",
        caption: "Valor desembolso",
        format: (value) => masks.currency(value, "R$"),
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_taxa_mensal",
        caption: "Taxa mensal",
        format: (value) => masks.percentage(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "trade_taxa_total",
        caption: "Taxa total",
        format: (value) => masks.percentage(value),
        allowHeaderFiltering: false,
      },
    ],
    masterDetail: {
      enabled: true,
      component: RelocationPlansMasterDetail,
    },
  };
