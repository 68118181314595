import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { RecommendedDistributorOptionsForm } from "../../recommended-distributor-options.types";

export const useDistributorSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDistributors,
  );

  const form = useFormContext<RecommendedDistributorOptionsForm>();

  const { clearErrors, setError } = form;

  const getDistributorOptions = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("distributorIds");
    else
      setError("distributorIds", {
        message:
          "Erro ao buscar as distribuidoras. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getDistributorOptions();
  }, [getDistributorOptions]);

  const distributorOptions = useMemo(
    () =>
      value?.response.map<SelectSearchMultipleOption<number>>(
        (distributor) => ({
          customKey: distributor.id,
          label: `${distributor.entity.name} - ${fastCpfCnpjMask(
            distributor.entity.taxId,
          )}`,
          value: distributor.id,
        }),
      ) ?? [],
    [value],
  );

  return {
    distributorOptions,
    getDistributorOptions,
    hasError,
    loading,
  };
};
