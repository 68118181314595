import { LogLevels } from "./logger.types";

const consoleMap = {
  [LogLevels.fatal]: "error",
  [LogLevels.error]: "error",
  [LogLevels.warn]: "warn",
  [LogLevels.info]: "log",
  [LogLevels.debug]: "log",
} as const;

const colorMap = {
  logger: "#5eff45",
  // levels
  [LogLevels.fatal]: "#ff00ff",
  [LogLevels.error]: "#ff0000",
  [LogLevels.warn]: "#ffff00",
  [LogLevels.info]: "#ffffff",
  [LogLevels.debug]: "#00ffff",
};

/** Console wrapper used internally by logger. It prepend some metadata.  */
export const _console = (
  level: Exclude<LogLevels, LogLevels.off>,
  ...args: unknown[]
) =>
  // eslint-disable-next-line no-restricted-syntax
  console[consoleMap[level]](
    `%c[logger]%c[${LogLevels[level]}]`,
    `color: ${colorMap.logger}; font-weight: bold;`,
    `color: ${colorMap[level]}; font-weight: bold;`,
    ...args,
  );
