import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import {
  VerifyAditamentosItem,
  VerifyCorrecaoItem,
} from "services/quickfin/models";

export const gridAditamento: ODataGridGeneratorConfig<VerifyAditamentosItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Situação",
      dataField: "ErrorMessages",
      cellRender: ({ data }) =>
        data.ErrorMessages ? (
          data.ErrorMessages
        ) : (
          <OIcon type="primary" size="lg" category="orq" icon="orq-check" />
        ),
    },
    { caption: "TradeId", dataField: "tradeId" },
    {
      dataField: "newMaturityAmount",
      caption: "Novo valor de vencimento",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      caption: "Nova data de vencimento",
      dataField: "newMaturityDate",
      dataType: "datetime",
      format: "dd/MM/yyyy",
      sortOrder: "desc",
    },
    {
      caption: "Nova data do trade",
      dataField: "tradeDate",
      dataType: "datetime",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Nova data de liquidação",
      dataField: "settlementDate",
      dataType: "datetime",
      format: "dd/MM/yyyy",
    },
  ],
};

export const gridCorrecao: ODataGridGeneratorConfig<VerifyCorrecaoItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Situação",
      dataField: "ErrorMessages",
      cellRender: ({ data }) =>
        data.ErrorMessages ? (
          data.ErrorMessages
        ) : (
          <OIcon type="primary" size="lg" category="orq" icon="orq-check" />
        ),
    },
    {
      caption: "TradeId",
      dataField: "tradeId",
    },
    {
      caption: "Data do trade",
      dataField: "tradeDate",
      dataType: "datetime",
      format: "dd/MM/yyyy",
    },
    {
      caption: "FCC",
      dataField: "IdFcc",
    },
    {
      caption: "Identificação",
      dataField: "Identification",
    },
    {
      caption: "Conta Numero",
      dataField: "AccountNumber",
    },
    {
      caption: "Conta Digito",
      dataField: "AccountDigit",
    },
    {
      caption: "Conta Tipo",
      dataField: "AccountType",
    },
    {
      caption: "Agência",
      dataField: "Agency",
    },
    {
      caption: "Cód. Banco",
      dataField: "BankCode",
    },
    {
      caption: "BookCode",
      dataField: "BookCode",
    },
    {
      caption: "Cód. Garantia",
      dataField: "CollateralIdentification",
    },
    {
      caption: "Contrato Referência",
      dataField: "ContractIdentification",
    },
    {
      caption: "HasHedge",
      dataField: "HasHedge",
    },
    {
      caption: "Holder TaxId",
      dataField: "HolderTaxId",
      format: (value) => masks.cpfCnpj(String(value)),
    },
    {
      caption: "Chave Pix",
      dataField: "PixKey",
    },
    {
      caption: "Tipo Pix",
      dataField: "PixType",
    },
    {
      caption: "Iof",
      dataField: "Iof",
    },
    {
      caption: "Liquidação Cód. Banco",
      dataField: "SettlementBankCode",
    },
    {
      caption: "Liquidação Agência",
      dataField: "SettlementAgency",
    },
    {
      caption: "Liquidação Dígito Agência",
      dataField: "SettlementAgencyDigit",
    },
    {
      caption: "Liquidação Nº Conta",
      dataField: "SettlementAccountNumber",
    },
    {
      caption: "Liquidação Dígito Conta",
      dataField: "SettlementAccountDigit",
    },
    {
      caption: "Liquidação HolderTaxId",
      dataField: "SettlementHolderTaxId",
      format: (value) => masks.cpfCnpj(String(value)),
    },
    {
      caption: "Liquidação Conta Primaria?",
      dataField: "SettlementIsPrimary",
    },
    {
      caption: "Liquidação Consolidado?",
      dataField: "SettlementIsConsolidated",
    },
    {
      caption: "Liquidação Lock Info",
      dataField: "SettlementLockInfo",
    },
    {
      caption: "Liquidação Cod Metodo",
      dataField: "SettlementMethodCode",
    },
    {
      caption: "Liquidação Party",
      dataField: "SettlementParty",
    },
    {
      caption: "Liquidação LockRef CGE",
      dataField: "SettlementLockRefCge",
    },
    {
      caption: "Nº de Registro",
      dataField: "RegistrationNumber",
    },
  ],
};
