import { OToastManager, modalManager } from "@maestro/core";
import { DataGridAction } from "components/datagrid-action";
import { useCallback, useState } from "react";
import { service } from "services";
import { ProcessorOutput } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useEditProcessor } from "../../edit-processor.context";
import { OUTPUT_MODAL_ID } from "../output-modal/output-modal.component";

interface OutputsGridActionsProps {
  data: ProcessorOutput;
}

export const OutputsGridActions = ({ data }: OutputsGridActionsProps) => {
  const {
    processor: { callService: getProcessorDetails },
    id,
    setSelectedOutput,
  } = useEditProcessor();

  const [loading, setLoading] = useState(false);

  const { show } = modalManager;

  const removeOutput = useCallback(
    async (processorOutputId: number) => {
      try {
        setLoading(true);

        await service.hubCreditManager.removeProcessorOutput({
          processorOutputId,
        });

        getProcessorDetails(id);
        OToastManager.success("A saída foi removida com sucesso.");
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao remover saída do processador. Por favor, tente novamente mais tarde.",
        );
      } finally {
        setLoading(false);
      }
    },
    [getProcessorDetails, setLoading, id],
  );

  return (
    <DataGridAction
      actions={[
        {
          icon: { category: "orq", icon: "orq-edit-pencil" },
          label: "Editar",
          onClick: () => {
            setSelectedOutput(data);
            show(OUTPUT_MODAL_ID);
          },
        },
        {
          icon: { category: "orq", icon: "orq-edit-trash" },
          label: "Remover",
          disabled: loading,
          onClick: () => {
            removeOutput(data.id);
          },
        },
      ]}
    />
  );
};
