import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { service } from "services";

export const useLandingPage = () => {
  const [frameStatus, setFrameStatus] = useState("PENDING");
  const { customer } = useCustomer();

  const { id, offerId } = useParams<
    keyof {
      id: number;
      offerId: number;
    }
  >();

  const navigate = useNavigate();

  const getStatus = useCallback(async () => {
    try {
      const { data } = await service.hubLoan.frameTaxId(
        customer.identification,
        offerId,
      );

      if (data === "REJECTED") {
        OToastManager.danger(
          "Não foi possível enquadrar seu CNPJ no BNDES. Por favor, tente outra linha de crédito.",
        );

        navigate(-1);
      }

      setFrameStatus(data);
    } catch (e) {
      OToastManager.danger(
        "Um erro ocorreu ao realizar o enquadramento. Por favor, tente novamente mais tarde.",
      );
      setFrameStatus("ERROR");
    }
  }, [offerId, navigate, customer]);

  useEffect(() => {
    if (frameStatus !== "PENDING") return;

    getStatus();

    const intervalId = setInterval(() => {
      getStatus();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [frameStatus, getStatus]);

  return {
    frameStatus,
    navigate,
    id,
    offerId,
  };
};
