import { modalManager } from "@maestro/core";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { SearchModal, searchModalId } from "./compose/search-modal";
import {
  LimiteCedenteProvider,
  useLimiteCedenteContext,
} from "./controlador-limite-cedente.context";
import { limiteCedenteGrid } from "./controlador-limite-cedente.grid";

const GridLimitCedente = () => {
  
  const gridRef = useGridRef();
  const {dataSource} = useLimiteCedenteContext();
  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Controle de Limite" />}
        gridRef={gridRef}
        showRefreshButton
        showExportButton
        actions={
          <IconButton
            dataAction="entuba_sugestao:botao:abrir_busca"
            dataLabel="abrir_busca"
            icon={{ category: "far", icon: "fa-search" }}
            onClick={() => modalManager.show(searchModalId)}
          >
            Abrir busca
          </IconButton>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={limiteCedenteGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <SearchModal />
    </>
  );
};

export const ControladorLimiteCedente = () => {
  return (
  <LimiteCedenteProvider>
    <GridLimitCedente />
  </LimiteCedenteProvider>);
};
