export const RequirementsModalId = "requirements-modal-id";

export enum RequirementKey {
  Solicitation,
  Guarantors,
  Signatures,
  Feedback,
}

export enum RequirementStatus {
  Approved = "Approved",
  Pending = "Pending",
  Failure = "Failure",
}

export interface Requirement {
  key: RequirementKey;
  status: RequirementStatus;
  title: string;
  description: string;
  show: boolean;
  link?: { text: string; url: string };
}
