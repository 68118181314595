import { modalManager } from "@maestro/react";
import { CancelFloatingModalId } from "./_compose/cancel-floating-modal";
import { FloatingSettingsModalId } from "./_compose/floating-settings-modal";
import { ActionButton } from "./floating-details.types";

export const assembleFloatingActions = (
  floatingInDays?: number,
): ActionButton[] => {
  const hasFloating = !!floatingInDays;

  if (hasFloating) {
    return [
      {
        label: "Editar Floating",
        onClick: () => modalManager.show(FloatingSettingsModalId),
      },
      {
        label: "Remover Floating",
        onClick: () => modalManager.show(CancelFloatingModalId),
      },
    ];
  }

  return [
    {
      label: "Adicionar Floating",
      onClick: () => modalManager.show(FloatingSettingsModalId),
    },
  ];
};
