import { OCheckbox, OLabel } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { MaskFieldsMapProps } from "../mask-fields-map.types";

export const BooleanField = ({ property }: MaskFieldsMapProps) => {
  const { setValue, watch } = useFormContext<{ value: string }>();

  const valueWatch = watch("value");

  return (
    <div className="d-flex align-items-center gap-2">
      <OCheckbox
        id={`value-${property.id}`}
        value=""
        checked={valueWatch?.toLowerCase() === "true"}
        onInput={(evt) =>
          setValue("value", evt.currentTarget.checked ? "true" : "false")
        }
        size="sm"
      />
      <OLabel htmlFor={`value-${property.id}`}>{property.type.label}</OLabel>
    </div>
  );
};
