import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useForm } from "react-hook-form";
import { service } from "services";
import {
  emailCotacaoDefaulValues,
  emailCotacaoValidationSchema,
} from "./email-cotacao.form";
import { EmailCotacaoForm } from "./email-cotacao.types";

export const useEmailCotacao = () => {
  const form = useForm<EmailCotacaoForm>({
    defaultValues: emailCotacaoDefaulValues,
    resolver: yupResolver(emailCotacaoValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      const groupByTaxId = values.byRootTaxId === "yes";
      const payload = [
        {
          headquarterTaxId: !groupByTaxId ? values.identification : null,
          rootTaxId: groupByTaxId ? values.identification.slice(0, 8) : null,
          emails: values.emails,
          isSacadoCnpj: values.isSacadoCnpj,
          routeVersion: "v2",
        },
      ];

      if (values.isSacadoCnpj && values.identification.length < 14) {
        OToastManager.danger("CNPJ do sacado deve ter 14 digitos");
        return;
      }

      await service.quickfin.sendEmailCotacao(payload);

      OToastManager.success("Email de cotação reenviado com sucesso");
    } catch (err) {
      OToastManager.danger("Erro ao reenviar email de cotação");
    }
  });

  return {
    form,
    submit,
  };
};
