import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NeedsFilesForReceivablesField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.needsFilesForReceivables}
      name="needsFilesForReceivables"
      description="Quando habilitado, valida se os arquivos foram enviados no borderô"
    />
  );
};
