import { OSkeletonCard, OTypography } from "@maestro/react";
import dayjs from "dayjs";

interface BankslipEventDetailProps {
  event?: BankingHub.BankslipEvent;
  isLoading?: boolean;
}

export const BankslipEventDetail = ({
  event,
  isLoading,
}: BankslipEventDetailProps) => {
  return (
    <div className="d-flex flex-column gap-1">
      {isLoading ? (
        <>
          <div className="d-flex gap-2">
            <OSkeletonCard height="1rem" width="9rem" rounded />
            <OSkeletonCard height="1rem" width="16rem" rounded />
          </div>
          <OSkeletonCard height="0.7rem" width="21rem" rounded />
        </>
      ) : (
        <>
          <div className="d-flex gap-2">
            <OTypography type="dark">
              [{dayjs(event?.receivedAt).format("DD/MM/YYYY, HH:mm:ss")}]
            </OTypography>
            <OTypography type="dark">{event?.status}</OTypography>
          </div>
          {!!event?.message && (
            <OTypography type="dark-60" size="sm" key={event.message}>
              {event.message}
            </OTypography>
          )}
        </>
      )}
    </div>
  );
};
