import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { agentsGrid, dataSource } from "./agents.grid";

export const AgentsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Agentes" />}
      gridRef={gridRef}
      actions={
        <OButton onClick={() => gridRef.current?.instance.addRow()}>
          Adicionar
        </OButton>
      }
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={agentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
