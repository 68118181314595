import { useCustomer } from "../../../../../contexts/customer";
import { IViewContentProps } from "../salesforce.types";

const RenderSalesforceLegacyComponentsComp = ({
  Element,
}: {
  Element: (props: IViewContentProps) => JSX.Element;
}) => {
  const { customer } = useCustomer();

  return (
    <Element
      companyDetails={customer}
      selectedCompany={customer.identification}
    />
  );
};

export const RenderSalesforceLegacyComponentsAux = ({
  Element,
}: {
  Element: (props: IViewContentProps) => JSX.Element;
}) => <RenderSalesforceLegacyComponentsComp Element={Element} />;
