import Icon001 from "assets/bank-logo/001.png";
import Icon033 from "assets/bank-logo/033.png";
import Icon077 from "assets/bank-logo/077.png";
import Icon102 from "assets/bank-logo/102.png";
import Icon104 from "assets/bank-logo/104.png";
import Icon208 from "assets/bank-logo/208.png";
import Icon212 from "assets/bank-logo/212.png";
import Icon237 from "assets/bank-logo/237.png";
import Icon260 from "assets/bank-logo/260.png";
import Icon269 from "assets/bank-logo/269.png";
import Icon335 from "assets/bank-logo/335.png";
import Icon336 from "assets/bank-logo/336.png";
import Icon341 from "assets/bank-logo/341.png";
import Icon422 from "assets/bank-logo/422.png";
import Icon623 from "assets/bank-logo/623.png";
import Icon665 from "assets/bank-logo/665.png";
import Icon745 from "assets/bank-logo/745.png";
import Icon756 from "assets/bank-logo/756.png";
import IconDefault from "assets/bank-logo/default.png";

interface StatusItem {
  icon: string;
}

type MapStatusType = { [key: string]: StatusItem };

export const mapBankIcon: MapStatusType = {
  "001": {
    icon: Icon001,
  },
  "033": {
    icon: Icon033,
  },
  "077": {
    icon: Icon077,
  },
  "102": {
    icon: Icon102,
  },
  "104": {
    icon: Icon104,
  },
  "208": {
    icon: Icon208,
  },
  "212": {
    icon: Icon212,
  },
  "237": {
    icon: Icon237,
  },
  "260": {
    icon: Icon260,
  },
  "269": {
    icon: Icon269,
  },
  "335": {
    icon: Icon335,
  },
  "336": {
    icon: Icon336,
  },
  "341": {
    icon: Icon341,
  },
  "422": {
    icon: Icon422,
  },
  "623": {
    icon: Icon623,
  },
  "665": {
    icon: Icon665,
  },
  "745": {
    icon: Icon745,
  },
  "756": {
    icon: Icon756,
  },
  default: {
    icon: IconDefault,
  },
};
