import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { RelatoriosEmLoteAdminODataValueItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { buildGrid } from "./reports-requested.grid";
import { ErrorReportModal } from "./_compose";

const dataSource =
  dataSourceODataStoreGenerator<RelatoriosEmLoteAdminODataValueItem>(
    endpoints.quickfin.odata.relatoriosEmLoteAdminOData,
  );

export const BatchReportsRequested = () => {
  const gridRef = useGridRef();

  const downloadReport = async (id: number, name: string) => {
    try {
      const { data } = await service.quickfin.getDocumentosById(id);

      helpers.downloadBlobFile(`${name || `Comprovantes-${id}`}.zip`, data);
    } catch (err) {
      OToastManager.danger("Erro para baixar os dados");
    }
  };

  const grid = useMemo(() => {
    return buildGrid(downloadReport);
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Relatórios solicitados"
          description="Escolha como gerar relatórios em lote das antecipações ou confira os relatórios já solicitados."
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ErrorReportModal />
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
