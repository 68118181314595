import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { ValidatedOperationsWithoutRegistryResponse } from "../../intubate-operations-without-registry.types";

const mapping = {
  success: "ok",
  warning: "alerta",
  danger: "inválido",
};

type StatusBadgeType = "info" | "success" | "warning" | "danger";

export const validateGrid: ODataGridGeneratorConfig<ValidatedOperationsWithoutRegistryResponse> =
  {
    datagrid: {
      noDataText: "Vazio.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "info",
        dataType: "string",
        caption: "Info",
        allowHeaderFiltering: false,
      },
      {
        dataField: "type",
        dataType: "string",
        caption: "Tipo",
        allowHeaderFiltering: false,
      },
      {
        dataField: "status",
        dataType: "string",
        caption: "Válido",
        allowHeaderFiltering: true,
        cellRender: ({ data: { status } }) => (
          <StatusBadge type={status.toLowerCase() as StatusBadgeType}>
            {mapping[status.toLowerCase() as keyof typeof mapping]}
          </StatusBadge>
        ),
      },
    ],
  };
