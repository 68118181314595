import { OToastManager } from "@maestro/react";
import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef, useState } from "react";
import { service } from "services";
import {
  AccountsItem,
  DesksItem,
  OfficersItem,
} from "services/onboarding/models";
import { getSemaphoreOnboardingErrors } from "services/onboarding/utils/onboarding-errors-messages";
import { parseOnboardingError } from "utils/parse-error/parse-onbording-error";
import { CurrentAccountFormType } from "./current-account-form.types";

export const useCurrentAccountForm = ({
  form,
  setLoading,
}: CurrentAccountFormType) => {
  const [desks, setDesks] = useState<DesksItem[]>([]);
  const [officers, setOfficers] = useState<OfficersItem[]>([]);
  const [accounts, setAccounts] = useState<AccountsItem[]>([]);
  const taxIdRef = useRef("");

  const { setError, watch, setValue, clearErrors } = form;

  const watchCNPJ = watch("taxId");
  const watchDeskCode = watch("desk");

  const {
    callService: getSemaphore,
    value: semaphoreValue,
    loading: semaphoreLoading,
  } = useServiceCall(service.onboarding.getSemaphore);

  const cleanUpEntity = useCallback(() => {
    setValue("officialName", "");
    setValue("status", "");
  }, [setValue]);

  const searchCNPJ = useCallback(
    async (cnpj: string) => {
      const payload = {
        taxId: cnpj,
      };

      const { success, error } = await getSemaphore(payload);

      if (!success) {
        cleanUpEntity();
        const semaphoreError = getSemaphoreOnboardingErrors(
          parseOnboardingError(error),
        );
        if (semaphoreError) OToastManager.danger(semaphoreError);
        else
          OToastManager.danger("Não foi possível encontrar o CNPJ fornecido");
      } else {
        clearErrors();
      }
    },
    [cleanUpEntity, getSemaphore, clearErrors],
  );

  useEffect(() => {
    if (!semaphoreValue) {
      return;
    }
    if (semaphoreValue.semaphore !== "GREEN") {
      cleanUpEntity();
      setError("taxId", {
        message: semaphoreValue?.message || "",
      });

      return;
    }

    setValue("taxId", semaphoreValue.taxId);
    setValue("officialName", semaphoreValue.officialName);
    setValue("status", semaphoreValue.statusRFB);
  }, [cleanUpEntity, setError, setValue, semaphoreValue]);

  const getDesks = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.getDesksOnboarding();

      setDesks(data.desks);
    } catch (err) {
      OToastManager.danger("Erro na busca dos dados");
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    getDesks();
  }, [getDesks]);

  useEffect(() => {
    if (watchCNPJ !== taxIdRef.current && validators.cnpj(watchCNPJ)) {
      taxIdRef.current = watchCNPJ;

      searchCNPJ(watchCNPJ);
    }
  }, [searchCNPJ, watchCNPJ, taxIdRef]);

  useEffect(() => {
    if (watchDeskCode) {
      const selectedDesk = desks.find(
        (desk) => desk.deskCode === watchDeskCode,
      );

      if (selectedDesk) {
        setValue("officer", undefined);
        setValue("account", undefined);

        setOfficers(selectedDesk.officers);
        setAccounts(selectedDesk.accounts);
      }
    }
  }, [desks, setValue, watchDeskCode]);

  return {
    watchDeskCode,
    desks,
    officers,
    accounts,
    semaphoreLoading,
  };
};
