import { OButton, OTypography, modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { cacGrid } from "./cac.grid";
import { cardsGrid } from "./cards.grid";
import { equipamentGrid } from "./equipament.grid";
import { guaranteeFtsLimitsGrid } from "./guarantee-fts-limits.grid";
import { imovelGrid } from "./imovel.grid";
import { insuranceGrid } from "./insurance.grid";
import { promissoryNoteGrid } from "./promissory-note.grid";
import { RemoveCollateralConfirmationModal } from "./remove-collateral-confirmation-modal.component";
import { sacasGrid } from "./sacas.grid";
import { solarGrid } from "./solar.grid";

export const CollateralsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();
  const dataGridRef = useRef<DataGrid>(null);

  if (!id) throw new Error("No id parameter");

  const {
    callService: getCollaterals,
    value: collaterals,
    hasError: hasErrorCollaterals,
    loading: loadingCollaterals,
  } = useServiceCall(service.hubCreditManager.getCollaterals);

  useEffect(() => {
    getCollaterals(id);
  }, [getCollaterals, id]);

  const collateralTypeSolar = collaterals?.filter(
    (collateral) => collateral.collateralType === "PAINEIS_SOLARES",
  );
  const collateralTypeCards = collaterals?.filter(
    (collateral) => collateral.collateralType === "RECEBIVEIS_CARTOES",
  );
  const collateralTypeSacas = collaterals?.filter(
    (collateral) => collateral.collateralType === "SACAS_GRAO",
  );
  const collateralTypeImovel = collaterals?.filter(
    (collateral) => collateral.collateralType === "IMOVEL",
  );
  const collateralTypeCDB = collaterals?.filter(
    (collateral) => collateral.collateralType === "CDB",
  );
  const collateralTypeCAC = collaterals?.filter(
    (collateral) => collateral.collateralType === "CAC",
  );
  const collateralTypeSeguro = collaterals?.filter(
    (collateral) => collateral.collateralType === "SEGURO",
  );
  const collateralTypeEquipamento = collaterals?.filter(
    (collateral) => collateral.collateralType === "EQUIPAMENTO",
  );
  const collateralTypePEAC = collaterals?.filter(
    (collateral) => collateral.collateralType === "PEAC",
  );
  const collateralTypeNotaPromissoria = collaterals?.filter(
    (collateral) => collateral.collateralType === "NOTA_PROMISSORIA",
  );

  useEffect(() => {
    modalManager.on("remove-collateral-modal", "modalClose", () =>
      getCollaterals(id),
    );
  }, [getCollaterals, id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Garantias" />}
      actions={
        <>
          <RouterButton
            type="tertiary"
            role={
              roles.emprestimos.product
                .limitManagerLimitsDetailsCollateralsAddPage.role
            }
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.add.path(
              { id },
            )}
            outline
          >
            Adicionar nova garantia
          </RouterButton>
          {!!collateralTypePEAC?.length && (
            <OButton
              type="danger"
              onClick={() =>
                modalManager.show(
                  `remove-collateral-modal-${collateralTypePEAC[0].id}`,
                )
              }
              outline
            >
              Remover PEAC
            </OButton>
          )}
          <RefreshGridButton
            onClick={() => {
              getCollaterals(id);
            }}
          />
        </>
      }
    >
      <ContentTemplate
        loading={loadingCollaterals}
        hasError={hasErrorCollaterals}
        value={collaterals}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getCollaterals(id)} />
          </ErrorComponent>
        }
        render={() => (
          <>
            {!!collateralTypePEAC?.length && (
              <>
                <RemoveCollateralConfirmationModal
                  collateralId={collateralTypePEAC[0].id}
                />
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  PEAC
                </OTypography>
              </>
            )}
            {!!collateralTypeCDB?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Garantias CDB
                </OTypography>
                <ODataGridGenerator
                  gridRef={dataGridRef}
                  grid={guaranteeFtsLimitsGrid(+id)}
                  dataSource={collaterals?.filter(
                    (c) => c.collateralType === "CDB",
                  )}
                />
              </>
            )}

            {!!collateralTypeEquipamento?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Equipamentos
                </OTypography>
                <ODataGridGenerator
                  grid={equipamentGrid(id)}
                  dataSource={collateralTypeEquipamento}
                />
              </>
            )}

            {!!collateralTypeSolar?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Painéis Solares
                </OTypography>
                <ODataGridGenerator
                  grid={solarGrid}
                  dataSource={collateralTypeSolar}
                />
              </>
            )}

            {!!collateralTypeCAC?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  CAC
                </OTypography>
                <ODataGridGenerator
                  grid={cacGrid(id)}
                  dataSource={collateralTypeCAC}
                />
              </>
            )}
            {!!collateralTypeNotaPromissoria?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Nota Promissória
                </OTypography>
                <ODataGridGenerator
                  grid={promissoryNoteGrid(id)}
                  dataSource={collateralTypeNotaPromissoria}
                />
              </>
            )}

            {!!collateralTypeSeguro?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Seguros
                </OTypography>
                <ODataGridGenerator
                  grid={insuranceGrid(id)}
                  dataSource={collateralTypeSeguro}
                />
              </>
            )}

            {!!collateralTypeCards?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Vendas em Cartão
                </OTypography>
                <ODataGridGenerator
                  grid={cardsGrid}
                  dataSource={collateralTypeCards}
                />
              </>
            )}

            {!!collateralTypeSacas?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Safra
                </OTypography>
                <ODataGridGenerator
                  grid={sacasGrid(id)}
                  dataSource={collateralTypeSacas}
                />
              </>
            )}

            {!!collateralTypeImovel?.length && (
              <>
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  Imóvel
                </OTypography>
                <ODataGridGenerator
                  grid={imovelGrid(id)}
                  dataSource={collateralTypeImovel}
                />
              </>
            )}
          </>
        )}
      />
    </GridTemplate>
  );
};
