import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import type { Options } from "devextreme/data/data_source";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo } from "react";
import { service } from "services";

export const useToolsGenerateTerm = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.onboarding.createAccountOpeningContracts,
  );

  const createAccountOpeningContracts = useCallback(async () => {
    const { success } = await callService({
      taxId: identification,
    });

    if (success) OToastManager.info("Geração de termos realizado com sucesso!");
    else OToastManager.info("Ocorreu um erro para gerar os termos");
  }, [callService, identification]);

  const gridFilter: Options = useMemo(
    () => [
      ["CompanyCNPJ", "=", identification],
      "and",
      ["ExternalPartnerId", "=", "HubOnboard"],
    ],
    [identification],
  );

  return {
    gridFilter,
    createAccountOpeningContracts,
    loading,
  };
};
