import { OToastManager } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { roles } from "roles/roles";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../utils";
import { NotifyInvoicedBillingReportButtonProps } from "./notify-invoiced-billing-report-button.types";

export const NotifyInvoicedBillingReportButton = ({
  billingReportId,
}: NotifyInvoicedBillingReportButtonProps) => {
  const { hasRole } = useRoles();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.notifyInvoicedBillingReport,
  );

  const callServiceNotifyInvoiced = useCallback(async () => {
    const { success, error } = await callService({
      billingReportId: Number(billingReportId),
    });

    if (success) {
      OToastManager.success("Notificação enviada com sucesso.");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao enviar notificação.",
      );
    }
  }, [billingReportId, callService]);

  return hasRole(roles.energia.product.billingReport.role) ? (
    <ButtonWithConfirmation
      confirmationModal={{
        title:
          "Confirma a notificação de relatório de faturamento disponível, para o cliente?",
        confirmLabel: "Sim",
        closeLabel: "Não",
        divider: false,
        loading,
        size: "xs",
      }}
      disabled={loading}
      onConfirm={() => callServiceNotifyInvoiced()}
    >
      Notificar cliente
    </ButtonWithConfirmation>
  ) : null;
};
