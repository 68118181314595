import { OButton } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { Tips } from "./_compose/tips/tips.component";
import { landingPageTipsList } from "./landing-page.utils";
import { useLandingPage } from "./hook";
// import { Banner } from "components/home/default-home/compose/banners/compose";
// import { bannerMap } from "components/home/default-home/compose/banners/utils";

export const LandingPage = () => {
  const { frameStatus, navigate, id, offerId } = useLandingPage();

  //   const { badge, description, image, title } = bannerMap["KGIRO_BNDES"];

  return (
    <div>
      <PageTitle
        title="BNDES Crédito Digital"
        description="Solução de crédito rotativo para as necessidades do dia-a-dia para micro e pequenas empresas."
      />
      <div className="d-flex flex-column gap-5 align-items-end">
        {/* <Banner
          badge={badge}
          description={description}
          image={image}
          title={title}
        /> */}
        <Tips
          title="Solução para Micro e Pequenas Empresas"
          tipsList={landingPageTipsList}
        />
        <div className="d-flex gap-2">
          <OButton outline type="default" onClick={() => navigate(-1)}>
            Voltar
          </OButton>
          <OButton
            disabled={frameStatus !== "APPROVED"}
            onClick={() =>
              navigate(
                `/emprestimos/cliente/ofertas/simulacao/kgiro/${id}/oferta/${offerId}`,
              )
            }
          >
            Continuar
          </OButton>
        </div>
      </div>
    </div>
  );
};
