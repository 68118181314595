import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";

export const useCobrancasDashboard = () => {
  const {
    callService: getDashboard,
    value: dashboardValue,
    loading,
    hasError,
  } = useServiceCall(service.shark.getNotificationsCountPerMonth);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  const dates = useMemo(
    () =>
      dashboardValue
        ?.map((item) => {
          const month = String(item.date.month).padStart(2, "0"); // Ensuring the month has leading zeros if necessary
          return `${month}/${item.date.year}`;
        })
        .reverse(),
    [dashboardValue],
  );

  const counts = dashboardValue?.map((item) => item.count).reverse();

  return {
    value: dashboardValue,
    loading,
    hasError,
    dates,
    counts,
  };
};
