import { AddressType } from "services/bankinghub/models/types/cards";

export interface ManageDeliveryAddressForm {
  zipCode: string;
  street: string;
  number?: string;
  numberNotInformed: "yes" | "no";
  complement?: string;
  complementNotInformed: "yes" | "no";
  neighborhood: string;
  city: string;
  stateId: string;
  type: AddressType;
}

export const UpdateDeliveryAddressFormModalInfo = {
  editTitle: "Editar endereço",
  createTitle: "Adicionar novo endereço",
  subtitle: "Informe o endereço onde você prefere receber o cartão",
};

export const ManageDeliveryAddressFormModalId =
  "manage-delivery-address-form-modal-id";

interface BrazilianStates {
  id: string;
  title: string;
}

export const STATES: BrazilianStates[] = [
  { id: "AC", title: "Acre" },
  { id: "AL", title: "Alagoas" },
  { id: "AP", title: "Amapá" },
  { id: "AM", title: "Amazonas" },
  { id: "BA", title: "Bahia" },
  { id: "CE", title: "Ceará" },
  { id: "DF", title: "Distrito Federal" },
  { id: "ES", title: "Espírito Santo" },
  { id: "GO", title: "Goiás" },
  { id: "MA", title: "Maranhão" },
  { id: "MT", title: "Mato Grosso" },
  { id: "MS", title: "Mato Grosso do Sul" },
  { id: "MG", title: "Minas Gerais" },
  { id: "PA", title: "Pará" },
  { id: "PB", title: "Paraíba" },
  { id: "PR", title: "Paraná" },
  { id: "PE", title: "Pernambuco" },
  { id: "PI", title: "Piauí" },
  { id: "RJ", title: "Rio de Janeiro" },
  { id: "RN", title: "Rio Grande do Norte" },
  { id: "RS", title: "Rio Grande do Sul" },
  { id: "RO", title: "Rondônia" },
  { id: "RR", title: "Roraima" },
  { id: "SC", title: "Santa Catarina" },
  { id: "SP", title: "São Paulo" },
  { id: "SE", title: "Sergipe" },
  { id: "TO", title: "Tocantins" },
];
