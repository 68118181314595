import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface DocumentTypeForm {
  name: string;
  label: string;
  description: string;
  isMigrationDocument: string[];
}

export const documentTypeFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  label: {
    id: "label",
    name: "label",
    tag: "text",
    label: "Label",
    placeholder: "Preencher",
    required: true,
  },
  description: {
    id: "description",
    name: "description",
    tag: "textarea",
    label: "Descrição",
    placeholder: "Preencher",
    required: true,
  },
  isMigrationDocument: {
    id: "isMigrationDocument",
    name: "isMigrationDocument",
  },
} as const satisfies MaestroFields<DocumentTypeForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const documentTypeFormValidationSchema = yup.object<
  DocumentTypeForm,
  YupObjectSchema<DocumentTypeForm>
>({
  name: stringRequired,
  label: stringRequired,
  description: stringRequired,
});

export const documentTypeFormDefaultValues = {
  description: null,
  label: null,
  name: null,
  isMigrationDocument: [],
} satisfies Partial<
  DeepNullable<DocumentTypeForm>
> as unknown as DocumentTypeForm;

export const checkboxValue = "true";
