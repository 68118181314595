export const operationSituationMap = {
  Pending: "Pendente",
  PartiallyExecuted: "Parcialmente processado",
  Completed: "Desembolsado",
  WaitingContractSignature: "Assinatura pendente",
  WaitingSacadoApproval: "Aguardando aprovação do sacado",
  Canceled: "Recusado",
  WaitingSolicitation: "Aguardando envio para desembolso",
  InDisbursement: "Em desembolso",
  PartiallySigned: "Parcialmente assinada",
  PartiallyCanceled: "Parcialmente recusado",
  PartiallyDisbursed: "Parcialmente desembolsado",
  DisbursedWithSomeCancels: "Desembolsado com algumas recusas",
  PartiallyDisbursedWithSomeCancels:
    "Parcialmente desembolsado com algumas recusas",
  WaitingRegister: "Aguardando registro",
};
