import { modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  ConfirmationDeleteCommonDomainModal,
  deleteCommonDomainModalId,
} from "./_compose/delete-common-domain-modal";

type CommonDomain = Awaited<
  ReturnType<typeof service.hubCreditManager.getCommonDomain>
>["data"][number];

export const commonDomainGrid = {
  datagrid: {
    noDataText: "Nenhum common domain encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getCommonDomain().catch(() => {
        throw new Error("Não foi possível buscar common domain.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "key",
      dataType: "string",
      caption: "Chave",
    },
    {
      dataField: "value",
      dataType: "string",
      caption: "Valor",
      cssClass: "dx-word-wrap",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                role: roles.workflow.product
                  .configurationsUpdateCommonDomainPage.role,
                route: `${corporateRouter.routes.workflow.product.configurations.commonDomain.update.path(
                  {
                    id: data.id,
                  },
                )}?data=${encodeURIComponent(JSON.stringify(data))}`,
              },
              {
                icon: { category: "orq", icon: "orq-edit-trash" },
                label: "Remover",
                onClick: () =>
                  modalManager.show(deleteCommonDomainModalId(data.id)),
              },
            ]}
          />
          <ConfirmationDeleteCommonDomainModal
            id={data.id}
            reload={() => component.getDataSource().reload()}
          />
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<CommonDomain>;