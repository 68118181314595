import { OButton, OIcon, OTypography } from "@maestro/react";
import { masks, helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { FinancedAsset } from "services/hubcreditmanager/models/responses";

export const creditLineFinancedAssetsGrid = {
  datagrid: {
    noDataText: "Nenhum ativo financiado disponível",
    columnAutoWidth: true,
  },
  columns: [
    {
      dataField: "id",
      caption: "Id do ativo",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "amount",
      caption: "Valor financiado",
      cellRender: ({ data }) => masks.currency(data.amount, "R$"),
    },
    {
      dataField: "invoice.id",
      caption: "Id da nota fiscal",
    },
    {
      dataField: "invoice.type",
      caption: "Tipo de nota fiscal",
      cellRender: ({ data }) => data.invoice?.type || "-",
    },
    {
      dataField: "invoice.invoiceDataOrigin",
      caption: "Origem",
    },
    {
      dataField: "invoice.amount",
      caption: "Valor da nota fiscal",
      cellRender: ({ data }) =>
        data.invoice ? masks.currency(data.invoice?.amount, "R$") : "-",
    },
    {
      dataField: "invoice.payee.taxId",
      caption: "Emitente",
      cellRender: ({ data }) => (
        <div className="d-flex flex-column">
          <OTypography size="sm">{data.invoice?.payee?.name}</OTypography>
          <OTypography size="sm">{data.invoice?.payee?.taxId}</OTypography>
        </div>
      ),
    },
    {
      caption: "Arquivo",
      cellRender: ({ data }) =>
        !!data.invoice?.file && (
          <OButton
            size="sm"
            onClick={async () => {
              const { data: response } =
                await service.hubCreditManager.download({
                  key: data.invoice.file.key,
                });

              helpers.downloadBlobFile(data.invoice.file.name, response);
            }}
          >
            <OIcon category="orq" icon="orq-download" size="sm" />
          </OButton>
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<FinancedAsset>;
