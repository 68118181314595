import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { GridTemplate } from "templates/grid-template";
import { formsGrid } from "./forms.grid";

export const FormsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de formulários" />}
      actions={
        <OButton
          onClick={() => {
            dataGridRef.current?.instance.addRow();
          }}
          outline
        >
          Adicionar
        </OButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={dataGridRef} grid={formsGrid} />
    </GridTemplate>
  );
};
