import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { loanContractsConfigGrid } from "./loan-contracts-config.grid";

export const LoanContractsConfigPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Templates de contrato" />}
      actions={
        <RouterButton
          type="tertiary"
          dataAction="emprestimos_templates_de_contrato:botao:adicionar"
          dataLabel="adicionar"
          href={
            corporateRouter.routes.emprestimos.product.contingency
              .contractsConfig.add.path
          }
          role={
            roles.emprestimos.product.contingencyContractsConfigAddPage.role
          }
          outline
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={loanContractsConfigGrid} />
    </GridTemplate>
  );
};
