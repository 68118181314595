export const loanIrisActs = [
  {
    value: 1,
    label: "1 - Abrir conta",
  },
  {
    value: 2,
    label: "2 - Encerrar conta",
  },
  {
    value: 3,
    label: "3 - Movimentar conta",
  },
  {
    value: 4,
    label: "4 - Aplicar em ativos financeiros",
  },
  {
    value: 6,
    label: "6 - Outorgar poderes",
  },
  {
    value: 8,
    label: "8 - Contratar câmbio",
  },
  {
    value: 11,
    label: "11 - Assinar carta de travamento",
  },
  {
    value: 12,
    label: "12 - Emitir debêntures",
  },
  {
    value: 13,
    label: "13 - Emitir notas promissórias",
  },
  {
    value: 14,
    label: "14 - Contratar adiantamento sobre contrato de câmbio",
  },
  {
    value: 15,
    label: "15 - Ceder direitos creditários",
  },
  {
    value: 16,
    label: "16 - Assinar contrato de compra e venda",
  },
  {
    value: 17,
    label: "17 - Contratar derivativos",
  },
  {
    value: 18,
    label: "18 - Contratar empréstimos",
  },
  {
    value: 19,
    label: "19 - Contratar financiamento",
  },
  {
    value: 20,
    label: "20 - Contratar prestação de fiança",
  },
  {
    value: 21,
    label: "21 - Contratar contragarantia",
  },
  {
    value: 22,
    label: "22 - Contratar prestação de serviços",
  },
  {
    value: 23,
    label: "23 - Confessar dívida",
  },
  {
    value: 24,
    label: "24 - Prestar garantias reais de bens móveis - Própria",
  },
  {
    value: 25,
    label: "25 - Prestar garantias reais de bens móveis - Terceiros",
  },
  {
    value: 26,
    label: "26 - Prestar garantias reais de bens imóveis - Própria",
  },
  {
    value: 27,
    label: "27 - Prestar garantias reais de bens imóveis - Terceiros",
  },
  {
    value: 28,
    label: "28 - Prestar garantia pessoal (Fidejussoria)",
  },
  {
    value: 29,
    label: "29 - Transferir títulos e valores mobiliários",
  },
  {
    value: 30,
    label: "30 - Assinar atas de assembleias de fundos de investimento",
  },
  {
    value: 31,
    label: "31 - (FIDC) Ceder direitos creditórios",
  },
];
