import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  estrategiaTemplateRelationshipsConfirmationModalDefaultValues,
  estrategiaTemplateRelationshipsConfirmationModalValidationSchema,
} from "./estrategia-template-relationships-confirmation-modal.form";
import {
  EstrategiaTemplateRelationshipsConfirmationModalForm,
  EstrategiaTemplateRelationshipsConfirmationModalProps,
} from "./estrategia-template-relationships-confirmation-modal.types";

export const useEstrategiaTemplateRelationshipsConfirmationModal = (
  submit: EstrategiaTemplateRelationshipsConfirmationModalProps["submit"],
) => {
  const form = useForm<EstrategiaTemplateRelationshipsConfirmationModalForm>({
    defaultValues:
      estrategiaTemplateRelationshipsConfirmationModalDefaultValues,
    resolver: yupResolver(
      estrategiaTemplateRelationshipsConfirmationModalValidationSchema,
    ),
  });

  const { handleSubmit, watch } = form;

  const confirm = useMemo(
    () =>
      handleSubmit(({ updateEstrategias, updateCedentes }) => {
        submit(updateEstrategias === "yes", updateCedentes === "yes")();
      }),
    [handleSubmit, submit],
  );

  const showUpdateCendentes = watch("updateEstrategias") === "yes";

  return {
    confirm,
    form,
    showUpdateCendentes,
  };
};
