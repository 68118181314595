export const contractStatusMap: Record<string, string> = {
  InPreparation: "Em preparação",
  ContractPrepared: "Contrato preparado",
  WaitingSignatures: "Aguardando assinatura",
  FullySigned: "Assinado por todos",
  Refused: "Recusado",
  Expired: "Expirado",
  Canceled: "Cancelado",
  WaitingApproval: "Aguardando aprovação",
  Complete: "Assinado",

  InErrorSignatoryInfoNotFounded: "Erro na assinatura",
  InErrorPreparationError: "Erro na preparação",
  InErrorNotificationError: "Erro na notificação",
  InErrorWithoutPower: "Erro sem poderes",
  InErrorWithoutVigency: "Erro sem vigência",

  CreationPending: "Criação pendente",
};
