import { modalManager } from "@maestro/core";
import {
  OButton,
  OCol,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldRadioGroup,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { useEstrategiaTemplateRelationshipsConfirmationModal } from "./estrategia-template-relationships-confirmation-modal.hook";
import { EstrategiaTemplateRelationshipsConfirmationModalProps } from "./estrategia-template-relationships-confirmation-modal.types";

export const estrategiaTemplateRelationshipsConfirmationModalId =
  "estrategia-template-relationships-confirmation-modal";

export const EstrategiaTemplateRelationshipsConfirmationModal = ({
  submit,
}: EstrategiaTemplateRelationshipsConfirmationModalProps) => {
  const { confirm, form, showUpdateCendentes } =
    useEstrategiaTemplateRelationshipsConfirmationModal(submit);

  const { reset, trigger } = form;

  return (
    <OModal
      id={estrategiaTemplateRelationshipsConfirmationModalId}
      position="center"
      backdrop
    >
      <OModalHeader>
        <OTypography size="lg">Confirmação</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow gap={3}>
            <OCol xs={12} md={6}>
              <ORFieldRadioGroup
                dataAction="modal_confirmacao:radio:atualizar_estrategias"
                dataLabel="atualizar_estrategias"
                id="updateEstrategias"
                name="updateEstrategias"
                label="Deseja atualizar todas as estratégias vinculadas a este template?"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="updateEstrategias-yes" value="yes" />
                    <OLabel htmlFor="updateEstrategias-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="updateEstrategias-no" value="no" />
                    <OLabel htmlFor="updateEstrategias-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            {showUpdateCendentes && (
              <OCol xs={12} md={6}>
                <ORFieldRadioGroup
                  dataAction="modal_confirmacao:radio:atualizar_relacionamentos"
                  dataLabel="atualizar_relacionamentos"
                  id="updateCedentes"
                  name="updateCedentes"
                  label="Deseja atualizar todos os relacionamentos existentes destas estratégias?"
                >
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-2">
                      <ORadio id="updateCedentes-yes" value="yes" />
                      <OLabel htmlFor="updateCedentes-yes">Sim</OLabel>
                    </div>
                    <div className="d-flex gap-2">
                      <ORadio id="updateCedentes-no" value="no" />
                      <OLabel htmlFor="updateCedentes-no">Não</OLabel>
                    </div>
                  </div>
                </ORFieldRadioGroup>
              </OCol>
            )}
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="modal_confirmacao:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => {
              reset();
              modalManager.hide(
                estrategiaTemplateRelationshipsConfirmationModalId,
              );
            }}
            className="me-auto"
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="modal_confirmacao:botao:confirmar"
            dataLabel="confirmar"
            onClick={async () => {
              if (await trigger()) {
                confirm();
                modalManager.hide(
                  estrategiaTemplateRelationshipsConfirmationModalId,
                );
              }
            }}
          >
            Confirmar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
