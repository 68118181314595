import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, energyTradesGrid } from "./energy-trades.grid";

export const EnergyTradesPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Propostas" />}
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={energyTradesGrid}
      />
    </GridTemplate>
  );
};
