import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DisparoManualFormValues,
  disparoManualValidationSchema,
} from "./cobrancas-disparo-manual-form.schemas";
import { DisparoManualForm } from "./cobrancas-disparo-manual-form.component";

export const CobrancasDisparoManualPage = () => {
  const form = useForm<DisparoManualFormValues>({
    resolver: yupResolver(disparoManualValidationSchema),
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Disparo manual de estímulos" />}>
      <FormProvider {...form}>
        <DisparoManualForm />
      </FormProvider>
    </FormTemplate>
  );
};
