import {
  OButton,
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { BannerFormFields } from "../../../banner-management-form.schema";
import { StepRenderProps } from "../../banner-form.types";
import { extractVariables } from "../../banner-form.utils";

export const Creation = ({
  setStep,
  updateBanner,
  children,
  bannerTemplates,
  bannerTemplate,
}: StepRenderProps) => {
  const navigate = useNavigate();
  const { watch, setError, clearErrors, getValues } = useFormContext();
  const values = getValues();

  const usedVariables = extractVariables(bannerTemplate?.description || "");

  const watchBannerTemplateId = watch("bannerTemplateId");

  const handleConfirm = useCallback(() => {
    if (!watchBannerTemplateId) {
      setError("bannerTemplateId", {
        message: "Escolha um template antes de prosseguir.",
      });
      return;
    }

    clearErrors();
    setStep && setStep("CAMPANHA");
    updateBanner && updateBanner(values as BannerFormFields);
  }, [
    watchBannerTemplateId,
    clearErrors,
    setStep,
    updateBanner,
    values,
    setError,
  ]);

  return (
    <form className="d-flex flex-column gap-4" spellCheck="false">
      <CardTemplate>
        <ORow>
          {!!bannerTemplates?.length && (
            <OCol>
              <ORFieldSelect
                label="Tipo de campanha"
                name="bannerTemplateId"
                id="bannerTemplateId"
                labelSize="md"
                required
              >
                {bannerTemplates?.map((template) => (
                  <OOption key={template.id} value={template.id}>
                    {template.identification}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          <OCol sm={6}>
            <ORFieldInput
              id="badge"
              name="badge"
              tag="text"
              label="Produto"
              labelSize="md"
              placeholder="Informar"
              required
            />
          </OCol>
          <OCol sm={6}>
            <ORFieldInput
              id="title"
              name="title"
              tag="text"
              label="Nome do evento"
              labelSize="md"
              placeholder="Informar"
              required
            />
          </OCol>
          {usedVariables.includes("temaPrincipal") && (
            <OCol sm={6}>
              <ORFieldInput
                id="temaPrincipal"
                name="temaPrincipal"
                tag="text"
                label="Tema principal"
                labelSize="md"
                placeholder="Informar"
                required
              />
            </OCol>
          )}
          {usedVariables.includes("data") && (
            <OCol sm={6}>
              <ORFieldInput
                id="data"
                name="data"
                tag="text"
                label="Data"
                labelSize="md"
                placeholder="Informar"
                required
              />
            </OCol>
          )}
          {usedVariables.includes("hora") && (
            <OCol sm={6}>
              <ORFieldInput
                id="hora"
                name="hora"
                tag="text"
                label="Horário"
                labelSize="md"
                placeholder="Informar"
                required
              />
            </OCol>
          )}
        </ORow>
      </CardTemplate>

      {children}

      <div className="d-flex justify-content-end gap-2">
        <OButton type="dark" outline onClick={() => navigate(-1)}>
          Voltar
        </OButton>

        <OButton type="tertiary" onClick={() => handleConfirm()}>
          Continuar
        </OButton>
      </div>
    </form>
  );
};
