import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const NeedsRegistrationInfoField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.needsRegistrationInfo}
      name="needsRegistrationInfo"
      description="Quando habilitado, não permite a criação de recebíveis sem essas informações"
    />
  );
};
