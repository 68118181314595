import {
  modalManager,
  OCol,
  OConfirmationModal,
  OField,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OTypography,
  OSwitch,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import dayjs from "dayjs";
import { useState } from "react";
import { useReprocessFile } from "./reprocess-file.hook";

export const ReprocessFile = () => {
  const {
    form,
    getValues,
    loading,
    onSubmit,
    setShouldSendVan,
    shouldSendVan,
  } = useReprocessFile();

  const [formValues, setFormValues] = useState({
    startAt: undefined,
    endAt: undefined,
  });
  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle title="Extrato Eletrônico - Reprocessar arquivo" />
        }
        actions={
          <LoadingButton
            loading={loading}
            dataAction="reprocessar_arquivo:botao:processar_arquivo"
            dataLabel=""
            onClick={() => {
              setFormValues({
                startAt: getValues("startAt"),
                endAt: getValues("endAt"),
              });
              const account = getValues("account");
              const agency = getValues("agency");

              if (account && agency) {
                modalManager.show("reprocess-file-modal");
              } else {
                OToastManager.danger("Todos os campos devem estar preenchidos");
              }
            }}
          >
            Processar arquivos
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ORow>
              <OCol xs={12} md={6} xl={3}>
                <ORFieldInput
                  tag="number"
                  dataAction="reprocessar_arquivo:input:conta"
                  dataLabel="conta"
                  id="account"
                  name="account"
                  label="Conta - com dígito"
                />
              </OCol>
              <OCol xs={12} md={6} xl={3}>
                <ORFieldInput
                  tag="number"
                  dataAction="reprocessar_arquivo:input:agencia"
                  dataLabel="agencia"
                  id="agency"
                  name="agency"
                  label="Agência"
                />
              </OCol>
              <OCol xs={12} md={6} xl={3}>
                <ORFieldInputDate
                  id="startAt"
                  name="startAt"
                  label="Data de início"
                />
              </OCol>
              <OCol xs={12} md={6} xl={3}>
                <ORFieldInputDate id="endAt" name="endAt" label="Data final" />
              </OCol>
              <OCol>
                <OField
                  htmlFor="shouldSendVan"
                  label="Arquivo deve ser enviado para VAN?"
                >
                  <OSwitch
                    id="shouldSendVan"
                    size="sm"
                    value={shouldSendVan}
                    onInput={() => setShouldSendVan((oldValue) => !oldValue)}
                  />
                </OField>
              </OCol>
            </ORow>
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <OConfirmationModal
        modalId="reprocess-file-modal"
        title="Processamento de arquivo"
        confirmLabel="Ok"
        closeLabel="Cancelar"
        divider={false}
        size="sm"
        loading={loading}
        onConfirm={() => {
          onSubmit();
          modalManager.hide("reprocess-file-modal");
        }}
      >
        <OTypography>{`Tem certeza que você deseja processar o arquivo com as movimentações \n do dia '${dayjs(
          formValues.startAt,
        )
          .add(-1, "days")
          .format("DD/MM/YYYY")} 00:00' -> '${dayjs(formValues.endAt)
          .add(-1, "days")
          .format("DD/MM/YYYY")}  23:59'`}</OTypography>
      </OConfirmationModal>
    </>
  );
};
