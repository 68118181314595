import { ODataGridGenerator } from "components/data-grid";
import { NextStepButton, ResetStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useIntubateOperations } from "../../intubate-operations.context";
import { summaryGrid } from "./summary.grid";

export const SummaryStep = () => {
  const { cardReceivables } = useIntubateOperations();

  const gridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate gridRef={gridRef} showExportButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={summaryGrid}
          dataSource={cardReceivables}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <NextStepButton />
      </div>
    </div>
  );
};
