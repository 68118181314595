import { OToastManager } from "@maestro/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PostBankingManagerCompanyBody } from "services/admin-bankinghub/models";
import { service } from "services/service";
import {
  RegisterBankingAccountFormValues,
  registerBankingAccountSchema,
} from "./cadastro-conta-banking.form";

export const useRegisterBankingAccount = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm<RegisterBankingAccountFormValues>({
    resolver: yupResolver(registerBankingAccountSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    const payload: PostBankingManagerCompanyBody = {
      name: values.name,
      type_document: values.type_document,
      document: values.document,
      covenant: values.covenant ?? "",
      agency_number: values.agency_number ?? "",
      agency_digit: values.agency_digit ?? "",
      account_number: values.account_number ?? "",
      account_digit: values.account_digit ?? "",
      wallet_code:
        values.wallet_code?.length === 1 ? `0${values.wallet_code}` : "",
    };

    setLoading(true);
    try {
      await service.adminBankinghub.postBankingCompany(payload);
      OToastManager.success("Empresa e dados salvos com sucesso!");
    } catch {
      OToastManager.danger("Erro ao salvar empresa e dados!");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    onSubmit,
  };
};
