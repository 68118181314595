import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../../../utils";
import { GridActions } from "./_compose";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepTypeResponse>(() =>
    service.hubEnergy
      .getDiligenceStepType()
      .then(({ data }) => data.response ?? [])
      .catch((error) => {
        const validator =
          getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao buscar os tipos de step";

        OToastManager.danger(validator);
        throw new Error(validator);
      }),
  );

export const stepTypesGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceStepTypeResponse> =
  {
    datagrid: {
      noDataText: "Nenhum tipo de step",
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("stepTypesGrid"),
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        role: roles.energia.product.diligenceConfigUpdateDocument.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.energia.product.diligenceConfig.stepTypes.update.path(
              {
                stepTypeId: data.id,
              },
            )}
          />
        ),
        width: "auto",
        allowExporting: false,
      },
      {
        dataField: "id",
        caption: "Id",
        width: "auto",
      },
      {
        dataField: "name",
        caption: "Nome",
        sortOrder: "asc",
        width: 200,
      },
      {
        dataField: "label",
        caption: "Label",
        width: 200,
      },
      {
        dataField: "description",
        caption: "Descrição",
        cssClass: "dx-word-wrap",
      },
      {
        caption: "Ações",
        width: "auto",
        cellRender: ({ data, component }) => (
          <GridActions
            stepType={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
        allowExporting: false,
      },
    ],
  };
