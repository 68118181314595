import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { GridTemplate } from "templates/grid-template";
import { scoreLevelsGrid } from "./score-levels.grid";

export const ScoreLevelsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de níveis" />}
      actions={
        <OButton
          onClick={() => {
            dataGridRef.current?.instance.addRow();
          }}
        >
          Adicionar
        </OButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={dataGridRef} grid={scoreLevelsGrid} />
    </GridTemplate>
  );
};
