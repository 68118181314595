import { service } from "services";
import {
  parseIntubateAddBlockSpreadsheet,
  parseIntubateAddAllowSpreadsheet,
} from "./_compose";

export const intubateTypeMap = {
  addBlock: {
    title: "Entuba - Bloqueio",
    service: service.validator.intubateBlockList,
    tip: "Enviar entuba de inclusao para adicionar novos registros de Bloqueio de identificação.",
    modelUrl: "/files/modelo_importacao_elegibilidade_block_list_item.xlsx",
    parser: parseIntubateAddBlockSpreadsheet,
  },
  addAllow: {
    title: "Entuba - Permissão",
    service: service.validator.intubateAllowList,
    tip: "Enviar entuba de inclusao para adicionar novos registros de Permissão de identificação.",
    modelUrl: "/files/modelo_importacao_elegibilidade_allow_list_item.xlsx",
    parser: parseIntubateAddAllowSpreadsheet,
  },
};
