import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { LimitRequest } from "../detalhes/limit-details.types";
import { transactionsGrid } from "./transactions.grid";

export const TransactionsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();
  const [limitDetails, setLimitDetails] =
    useState<LimitRequest["currentLimit"]>();

  if (!id) throw new Error("No id parameter");

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getLimitRequestMinified(id)
          .then(({ data: { currentLimit, limitId } }) => {
            setLimitDetails(currentLimit);

            return limitId
              ? service.hubCreditManager
                  .transactionsByLimit(limitId)
                  .then(({ data: { limitTransactions } }) => {
                    return (
                      limitTransactions?.map((transaction) => ({
                        ...transaction,
                        creditLineProduct: currentLimit?.creditLine.product,
                        creditLineIdentification:
                          currentLimit?.creditLine.identification,
                      })) ?? []
                    );
                  })
              : [];
          })
          .catch(() => {
            throw new Error("Erro ao buscar transações");
          }),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Transações" />}
      actions={
        <RouterButton
          type="tertiary"
          href={`${corporateRouter.routes.emprestimos.product.limitManager.limits.details.transactions.add.path(
            {
              id,
            },
          )}?data=${encodeURIComponent(JSON.stringify(limitDetails))}`}
          role={
            roles.emprestimos.product
              .limitManagerLimitsDetailsTransactionsAddPage.role
          }
          outline
        >
          Adicionar débito
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={transactionsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
