import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";
import { FormValuesType } from "../../../../request-for-reports.types";
import { LocalFormValuesType } from "./select-cedentes.types";

export const useSelectCedentes = () => {
  const [allCompanies, setAllCompanies] = useState<
    SelectSearchOption<string>[]
  >([]);

  const form = useFormContext<FormValuesType>();
  const { setValue, getValues, watch } = form;
  const watchCedentes = watch("cnpjCedentes");

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;

  const watchInput = localWatch("input");

  const search = useCallback(
    async (searchValue: string) => {
      try {
        const { data } = await service.quickfin.getAllCompanies({
          CompanyType: "Cedente",
          CompanyFilter: searchValue,
        });

        const newData = data.companies.map((d) => {
          return {
            value: String(d.id),
            label: `${d.name} (${d.identification})`,
            customKey: d.id,
            onSelect: (value: string) => {
              const cedentes = getValues("cnpjCedentes") ?? [];

              setValue("cnpjCedentes", [...new Set([...cedentes, value])]);
            },
          };
        });

        setAllCompanies(newData);
      } catch (e) {
        logger.error(e);
        OToastManager.danger("Erro ao buscar cedentes.");
      }
    },
    [getValues, setValue],
  );

  const debouncedSearch = useMemo(() => {
    return debounce(search, 500);
  }, [search]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  useEffect(() => {
    if (watchInput?.trim().length) {
      debouncedSearch(watchInput.trim());
    }
  }, [debouncedSearch, watchInput]);

  useEffect(() => {
    if (watchCedentes) localSetValue("input", undefined);
  }, [localSetValue, watchCedentes]);

  return {
    allCompanies,
    localForm,
    watchInput,
    setValue,
    watchCedentes,
  };
};
