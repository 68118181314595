import { modalManager } from "@maestro/core";
import { OLoader } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { modalManagerPayload } from "utils/modal-manager-payload";
import {
  DocumentTemplateModal,
  documentTemplateModalId,
} from "../../../../../../../../../../components/document-template-modal";
import { moveFileModalId } from "../../../../../../../../../../components/move-file-modal";
import {
  DeletePropertyConfirmationModal,
  deletePropertyConfirmationModalId,
} from "../delete-property-confirmation-modal";
import { editPropertyValueModalId } from "../edit-property-value-modal";
import { editStepPropertyModalId } from "../edit-step-property-modal";
import { moveStepPropertyModalId } from "../move-step-property-modal";
import {
  SendToPaperclipConfirmationModal,
  sendToPaperclipConfirmationModalId,
} from "../send-to-paperclip-confirmation-modal";
import { useGridActions } from "./grid-actions.hook";

export interface GridActionsProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
  reload: () => void;
}

export const GridActions = ({ property, reload }: GridActionsProps) => {
  const { loading, removeProperty, sendDocumentToPaperclip } = useGridActions(
    property,
    reload,
  );

  const isDocumentType = property.type.mask === "DOCUMENT";
  const documentHasNoFiles = !property?.document?.files?.length;
  const documentTemplateHasNoFiles = !property?.documentTemplate?.files?.length;

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar valor",
              onClick: () =>
                modalManagerPayload.show(editPropertyValueModalId, property),
            },
            {
              icon: { category: "orq", icon: "orq-settings" },
              label: "Editar propriedade",
              onClick: () =>
                modalManagerPayload.show(editStepPropertyModalId, property),
            },
            {
              visible: isDocumentType,
              icon: { category: "orq", icon: "orq-file" },
              label: "Template do documento",
              disabled: !!property.isAutomation,
              onClick: () =>
                modalManager.show(documentTemplateModalId(property.id)),
            },
            {
              visible: isDocumentType,
              icon: { category: "orq", icon: "orq-contract-signature" },
              label: "Enviar para assinatura",
              disabled: documentTemplateHasNoFiles,
              onClick: () =>
                modalManagerPayload.show(
                  sendToPaperclipConfirmationModalId(property.id),
                ),
            },
            {
              visible: isDocumentType,
              icon: { category: "orq", icon: "orq-shipping-remittance" },
              label: "Mover arquivos",
              disabled: documentHasNoFiles && documentTemplateHasNoFiles,
              onClick: () =>
                modalManagerPayload.show(moveFileModalId, property),
            },
            {
              icon: { category: "orq", icon: "orq-right" },
              label: "Mover propriedade",
              onClick: () =>
                modalManagerPayload.show(moveStepPropertyModalId, property),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir propriedade",
              onClick: () =>
                modalManager.show(
                  deletePropertyConfirmationModalId(property.id),
                ),
            },
          ]}
        />
      )}
      <DeletePropertyConfirmationModal
        property={property}
        onConfirm={removeProperty}
      />
      {isDocumentType && (
        <>
          <SendToPaperclipConfirmationModal
            property={property}
            onConfirm={sendDocumentToPaperclip}
          />
          <DocumentTemplateModal
            propertyId={property.id}
            documentTemplate={property.documentTemplate}
          />
        </>
      )}
    </>
  );
};
