import { OToastManager } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { bankingCompaniesGrid } from "./banking-manager.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.adminBankinghub
    .getBankingCompanies()
    .then(({ data }) => data.companies ?? [])
    .catch(() => {
      const errorMessage = "Erro ao buscar as empresas";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const BankingManager = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Banking manager" />}
      actions={
        <StandardLink
          href={
            corporateRouter.routes.banking.product.bankingManager.register.path
          }
          role={roles.banking.product.bankingManagerRegisterPage.role}
        >
          Cadastro
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={bankingCompaniesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
