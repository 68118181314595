import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { OperationsODataValueItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./operations.grid";

const dataSource = dataSourceODataStoreGenerator<OperationsODataValueItem>(
  endpoints.quickfin.odata.operationsOData,
  {
    odataStoreOptions: {
      beforeSend: (options) => {
        // eslint-disable-next-line no-param-reassign
        options.url = `${options.url}?tipoProduto=RiscoSacado&tipoProduto=Offshore`;
      },
    },
  },
);

export const Operations = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Operações - Trades"
          description="Acompanhe todas as operações de nota realizadas em nossa plataforma"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
