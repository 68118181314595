import { OButton, OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGrid } from "devextreme-react";
import { useMemo, useRef, useState } from "react";
import { roles } from "roles/roles";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { FileUpload } from "./file-card";

interface GridProps {
  documentUuid: string;
  refetch: () => Promise<void>;
  edditing: boolean;
  canEdit: boolean;
}

const grid = ({ refetch, documentUuid, edditing, canEdit }: GridProps) => {
  return {
    datagrid: {
      noDataText: "Nenhum arquivo",
      pager: {
        infoText: "Página {0} de {1} ({2} arquivos)",
      },
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      wordWrapEnabled: true,
      onRowInserted: async (row) => row.component.getDataSource().reload(),
    },
    columns: [
      {
        dataField: "id",
        caption: "ID",
        allowHeaderFiltering: false,
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "name",
        caption: "Nome",
        allowHeaderFiltering: false,
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "type",
        caption: "Tipo",
        allowEditing: false,
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "template-edit",
        caption: "Template",
        width: 500,
        editCellRender: () => {
          return <FileUpload uuid={documentUuid} refetch={refetch} />;
        },
      },
      {
        dataField: "errorMessage",
        caption: "Ocorrência",
        cssClass: "dx-word-wrap",
        allowHeaderFiltering: false,
        formItem: {
          visible: false,
        },
      },
      {
        visible: !edditing,
        cellRender: ({ data }) => (
          <div className="d-flex flex-row gap-2 align-items-end">
            <OButton
              title="Baixar"
              ariaLabel="Baixar"
              outline
              onClick={async () => {
                try {
                  const { uuid } = data;

                  const { data: blob } =
                    await service.onboardingRelationships.downloadFile({
                      uuid,
                    });

                  helpers.downloadBlobFile(data.name, blob);

                  OToastManager.success("Download realizado com sucesso!");
                } catch {
                  OToastManager.danger("Houve um erro ao baixar o arquivo.");
                }
              }}
            >
              Baixar
            </OButton>
            <OButton
              title="Remover"
              ariaLabel="Remover"
              disabled={canEdit}
              onClick={async () => {
                try {
                  const { uuid } = data;
                  if (uuid) {
                    await service.onboardingRelationships.removeFile({
                      uuid,
                    });
                  }
                  refetch();
                  OToastManager.success("Representante removido com sucesso");
                } catch {
                  OToastManager.danger("Erro ao remover Representante");
                }
              }}
            >
              Remover
            </OButton>
          </div>
        ),
        role: roles.cadastro.customer.lettersOfAttorneyVintageEmbeddedPage.role,
      },
    ],
  } as ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.FilesItem>;
};

interface RepresentativeCardProps {
  items: MasterDetailComponentProps<HubOnboardingRelationships.Admin.DocumentsItem>;
  refetch: () => Promise<void>;
  canEdit: boolean;
}

export const DocumentsMasterDetail = ({
  items: {
    data: {
      data: { files, uuid },
    },
  },
  refetch,
  canEdit,
}: RepresentativeCardProps) => {
  const [edditing, setEdditing] = useState(false);
  const gridComponent = useMemo(() => {
    return grid({ refetch, documentUuid: uuid, edditing, canEdit });
  }, [edditing, refetch, uuid, canEdit]);

  const templatesGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate>
      <OButton
        dataAction="emprestimos_gerador_contrato:botao:adicionar"
        dataLabel="adicionar"
        disabled={canEdit}
        outline
        type="primary"
        onClick={() => {
          if (!edditing) {
            templatesGridRef.current?.instance.addRow();
          } else {
            templatesGridRef.current?.instance.cancelEditData();
          }
          setEdditing(!edditing);
        }}
      >
        {edditing ? "Cancelar" : "Adicionar"}
      </OButton>

      <ODataGridGenerator
        gridRef={templatesGridRef}
        grid={gridComponent}
        dataSource={files}
      />
    </GridTemplate>
  );
};
