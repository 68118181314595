import {
  OCol,
  OLoader,
  OOption,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAdditionDetail } from "./detail.hook";
import { options } from "./detail.utils";

export const AdditionDetail = () => {
  const { additionDetail, detailLoader, form, loading, submit } =
    useAdditionDetail();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhe do Aditamento" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="aditamento_detalhe:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {detailLoader && <OLoader absolute />}
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <div className="d-flex flex-column">
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    ID:
                  </OTypography>
                  <OTypography type="dark">
                    {additionDetail?.aditamentoId}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Nova data vencimento:
                  </OTypography>
                  <OTypography type="dark">
                    {dayjs(additionDetail?.newMaturityDate).format(
                      "DD/MM/YYYY",
                    )}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Novo valor vencimento:
                  </OTypography>
                  <OTypography type="dark">
                    {masks.currency(additionDetail?.newMaturityAmount, "R$")}
                  </OTypography>
                </div>
              </div>
            </OCol>

            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                dataAction="aditamento_detalhe:select:status"
                dataLabel="status"
                id="status"
                name="status"
                label="Status"
                placeholder="Selecione"
              >
                {options.map((option) => (
                  <OOption key={option} value={option}>
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
