import { OLink, OTooltip } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { StyledValue } from "../roadwalker-master-detail.style";
import { BoletoMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerBoletoDetails = (data: BoletoMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    boleto: {
      items: [
        {
          label: "TradeId",
          value: <StyledValue>{data.TradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.ExternalId}</span>
                {data.ExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PublishDate",
          value: (
            <StyledValue>
              {dayjs(data.PublishDate).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TitleNumber",
          value: <StyledValue>{data.TitleNumber}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TitleNumberDAC",
          value: <StyledValue>{data.TitleNumberDAC}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BarCode",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.BarCode}</span>
                {data.BarCode}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BarCodeSymbols",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.BarCodeSymbols}</span>
                {data.BarCodeSymbols}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Url",
          value: (
            <StyledValue>
              <OLink href={data.Url} target="_blank">
                {data.Url}
              </OLink>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "StatusId",
          value: <StyledValue>{data.StatusId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "StatusDescription",
          value: <StyledValue>{data.StatusDescription}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
