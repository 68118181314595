import { modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { DeleteAgreementModal, DeleteAgreementModalId } from "./_compose";
import { useCollectionsAgreementContext } from "./collections-agreement.context";
import { grid } from "./collections-agreement.grid";

export const CollectionsAgreement = () => {
  const [agreementIdToDelete, setAgreementIdToDelete] = useState("");
  const { agreementsList, isLoadingAgreementsList, setAgreementToUpdate } =
    useCollectionsAgreementContext();
  const navigate = useNavigate();
  const gridRef = useGridRef();

  const handleDelete = useCallback(
    ({ id }: BankingHubAdminCollection.Agreement) => {
      setAgreementIdToDelete(id);
      modalManager.show(DeleteAgreementModalId);
    },
    [],
  );

  const handleUpdate = useCallback(
    (agreement: BankingHubAdminCollection.Agreement) => {
      setAgreementToUpdate(agreement);
      navigate(
        corporateRouter.routes.banking.customer.collection.agreement.edit.path,
      );
    },
    [navigate, setAgreementToUpdate],
  );

  const memoGrid = useMemo(
    () => grid(handleDelete, handleUpdate),
    [handleDelete, handleUpdate],
  );

  return (
    <div>
      <DeleteAgreementModal agreementId={agreementIdToDelete} />
      <GridTemplate
        pageTitle={<PageTitle title="Convênio - Cobranças" />}
        actions={
          <IconButton
            icon={{ category: "orq", icon: "orq-add-plus" }}
            onClick={() => {
              setAgreementToUpdate(undefined);
              navigate(
                corporateRouter.routes.banking.customer.collection.agreement
                  .create.path,
              );
            }}
          >
            Cadastrar convênio
          </IconButton>
        }
        gridRef={gridRef}
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={memoGrid}
          dataSource={agreementsList}
          loading={isLoadingAgreementsList}
        />
      </GridTemplate>
    </div>
  );
};
