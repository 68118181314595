import { OButton, OTypography } from "@maestro/react";
import {
  PreviousStepButton,
  ResetStepButton,
  useStepsBuilder,
} from "components/steps-builder";
import { useMemo } from "react";
import { useIntubateOperations } from "../../intubate-operations.context";

export const Confirmation = () => {
  const { cardReceivables, submit } = useIntubateOperations();

  const { nextStep } = useStepsBuilder();

  const numberOfOperations = useMemo(
    () =>
      [
        ...new Set(
          cardReceivables.map(
            (receivable) =>
              `${receivable.CedenteIdentification}${receivable.SacadoIdentification}`,
          ),
        ),
      ].length,
    [cardReceivables],
  );

  const numberOfOperationsText =
    numberOfOperations > 1
      ? `Serão criadas ${numberOfOperations} operações.`
      : "Será criada 1 operação.";

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>{numberOfOperationsText}</OTypography>
      <OTypography>Esse processo pode demorar alguns minutos.</OTypography>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <OButton
          onClick={() => {
            submit();
            nextStep();
          }}
        >
          Criar operação
        </OButton>
      </div>
    </div>
  );
};
