import { modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import { AutoRelocationForm } from "../../auto-relocation.types";
import { relocationTimeModalId } from "../relocation-time-modal";

interface EnableWeeklyRelocationButtonProps {
  loading: boolean;
  setRelocationPeriod: (period: "semanal" | "diario") => void;
}

export const EnableWeeklyRelocationButton = ({
  loading,
  setRelocationPeriod,
}: EnableWeeklyRelocationButtonProps) => {
  const { watch } = useFormContext<AutoRelocationForm>();

  const companyIds = watch("companyIds");

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-schedule-recurring" }}
      dataAction="auto_realocacao:botao:semanal"
      dataLabel="semanal"
      loading={loading}
      disabled={!companyIds?.length}
      onClick={() => {
        setRelocationPeriod("semanal");
        modalManager.show(relocationTimeModalId);
      }}
    >
      Ligar realocação automática semanal
    </IconButton>
  );
};
