import {
  ExchangeQuoteFlowType,
  ExchangeQuoteStatus,
} from "services/hubfx/models/types/quote/quote.types";
import { Currency } from "utils/currency";

export const quoteStatusMap = {
  ACCEPTED: "Recebido aceite da cotação pelo cliente",
  CANCELLED: "Operação cancelada",
  CONFIRMED: "Internação conciliada - Motor de câmbio",
  CREATED: "Cotação criada",
  EXPIRED: "Cotação expirada",
  DISBURSED: "Operação desembolsada",
  CHARGED_FEES: "Tarifas debitadas",
  CHARGED_IOF: "IOF debitado",
  CHARGED_PRINCIPAL: "Principal debitado",
  DRAFT: "Trade criado",
  EXPORTED: "Etapa da geração de contrato - FTS - Exported",
  PENDING_APPROVAL: "Necessidade da aprovação manual - FTS",
  UNDEFINED: "Indefinido",
  READY: "Em processamento - FTS - Ready",
  OPEN: "",
  REGISTERED: "",
  TRANSMITTED: "",
  CANCELLATION_REQUESTED: "Cancelamento solicitado pelo cliente",
  CANCELLATION_SENT_TO_FTS:
    "Solicitação de cancelamento enviado ao FTS - Aguardando confirmação",
  CANCELLATION_REQUEST_WITH_ERRORS: "Falha ao cancelar a operação no FTS",
} satisfies Record<ExchangeQuoteStatus, string>;

export const mapQuoteStatus = (status: string | null) =>
  quoteStatusMap[status as ExchangeQuoteStatus] || status || "";

interface ParseQuoteFlowTypeProps {
  buyCurrencyType: Currency | null;
  flowType: ExchangeQuoteFlowType | null;
  sellCurrencyType: Currency | null;
  beneficiaryName?: string | null;
  orderPayer?: string | null;
}

export const parseQuoteFlowType = (data: ParseQuoteFlowTypeProps) => {
  const flowTypeMap: Record<
    string,
    {
      type: string;
      externalParty: string;
      externalPartyName: string | null | undefined;
      currency: string;
    }
  > = {
    REMESSA: {
      type: "Remessa",
      externalParty: "Beneficiário",
      externalPartyName: data.beneficiaryName || data.beneficiaryName,
      currency: data.buyCurrencyType || "",
    },
    INTERNACAO: {
      type: "Internação",
      externalParty: "Pagador",
      externalPartyName: data.orderPayer || data.orderPayer,
      currency: data.sellCurrencyType || "",
    },
  };

  return data.flowType && flowTypeMap[data.flowType];
};
