import { OButton, OLoader, OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { BatchFileTemplateColumnsProvider } from "../../../../../../components/hooks/use-workflow-batch-template.context";
import { WorkflowProvider } from "../../../../../../components/hooks/use-workflow.context";
import { WorkflowProdutoBatchRequestById } from "../../../../../../routes/workflow.route-params";
import { AddColumnModal } from "./_compose/add-column-modal/add-column-modal.component";
import { ColumnsGrid } from "./_compose/columns-grid/columns-grid.component";
import { UpdateColumnModal } from "./_compose/update-column-modal/update-column-modal.component";
import { BatchFileTemplateDetailsGenerator } from "./batch-file-template-details.details";

export const BatchFileTemplateDetailsPage = () => {
  const { id } = useParams<WorkflowProdutoBatchRequestById>();

  if (!id) throw new Error("No id");

  const [pageLoading, setPageLoading] = useState(false);

  const {
    callService: getBatchFileTemplatesDetails,
    value: batchFileTemplateDetails,
    hasError,
    loading: loadingBatchFileTemplateDetails,
  } = useServiceCall(service.hubCreditManager.getBatchFileTemplatesDetails);

  const downloadBatchFileTemplate = useCallback(async () => {
    try {
      setPageLoading(true);
      const { data: blob } =
        await service.hubCreditManager.downloadBatchFileTemplate({
          id,
        });
      helpers.downloadBlobFile("template.xlsx", blob);

      OToastManager.success("Download realizado com sucesso!");
    } catch {
      OToastManager.danger(
        "Não foi possível baixar modelo de template. Tente novamente",
      );
    } finally {
      setPageLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getBatchFileTemplatesDetails(id);
  }, [getBatchFileTemplatesDetails, id]);

  return (
    <>
      {pageLoading && <OLoader backdrop absolute />}
      <DetailsTemplate pageTitle={<PageTitle title="Detalhes do Template" />}>
        <div className="d-flex justify-content-end pt-3 gap-3">
          <OButton
            className="pb-3"
            onClick={downloadBatchFileTemplate}
            dataAction="batch_file_template:botao:download_template"
            dataLabel="download_template"
          >
            Download do Template
          </OButton>
        </div>
        <DetailsCard
          loading={loadingBatchFileTemplateDetails}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível carregar os detalhes do template."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton
                onClick={() => getBatchFileTemplatesDetails(id)}
              />
            </ErrorComponent>
          }
          hasError={hasError}
          value={batchFileTemplateDetails}
          fields={BatchFileTemplateDetailsGenerator}
        />
        <WorkflowProvider>
          <BatchFileTemplateColumnsProvider>
            <AddColumnModal
              getBatchFileTemplatesDetails={getBatchFileTemplatesDetails}
            />
            <UpdateColumnModal />
            <ColumnsGrid />
          </BatchFileTemplateColumnsProvider>
        </WorkflowProvider>
      </DetailsTemplate>
    </>
  );
};
