import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";

export const parseSpreadsheet = (buffer: FileReader["result"]) => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row: any) => ({
    taxId: row.CNPJ.toString().replace(/\D/g, ""),
    name: row.Nome,
  }));
};
