import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react";
import { useRef } from "react";
import { GridTemplate } from "templates/grid-template";
import { dataSource, whitelistsGrid } from "./whitelists.grid";

export const Whitelist = () => {
  const gridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Priorização das Agendas"
          description="Listagem das agendas priorizadas"
        />
      }
      actions={
        <IconButton
          dataAction="agendas_priorizadas:botao:adicionar"
          dataLabel="adicionar"
          icon={{ category: "fal", icon: "fa-plus" }}
          onClick={() => gridRef.current?.instance.addRow()}
        >
          Adicionar
        </IconButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={whitelistsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
