import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { Collaterals } from "../../collaterals.types";

export const CollateralsContractualPartiesGrid = {
  datagrid: {
    noDataText: "Nenhuma parte relacionada encontrada",
  },
  columns: [
    {
      dataField: "legalEntity.name",
      caption: "Nome",
    },
    {
      dataField: "legalEntity.taxId",
      caption: "Documento",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Collaterals[number]["properties"][number]["contractualParties"][number]
>;
