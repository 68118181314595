import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { adminContingencyACCDocumentConfigsGrid } from "./admin-contingency-acc-document-configs.grid";

export const AdminContingencyACCDocumentConfigsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configuração de Documentos" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.acc.product.contingency.natures
              .documentConfigs.create.path
          }
          role={
            roles.acc.product.contingencyNaturesDocumentConfigsCreatePage.role
          }
        >
          Adicionar configuração de documento
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={adminContingencyACCDocumentConfigsGrid}
      />
    </GridTemplate>
  );
};
