import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { ContractsPendingComplement } from "services/quickfin/models";

export const pendingComplementsGrid: ODataGridGeneratorConfig<ContractsPendingComplement> =
  {
    datagrid: {
      noDataText: "Nenhum contrato com complemento pendente.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
        allowSearch: true,
      },
      pager: {
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
        showInfo: true,
        infoText: "Página {0} de {1} ({2} contratos)",
      },
      remoteOperations: true,
    },
    columns: [
      {
        allowExporting: false,
        cellRender: ({ data }) => (
          <StandardLink
            href={corporateRouter.routes.recebiveis.product.contractsPendingComplement.fillComplements.path(
              {
                sacadoId: data.SacadoId,
                cedenteId: data.CedenteId,
                contractId: data.Id,
              },
            )}
          >
            Preencher
          </StandardLink>
        ),
      },
      {
        dataField: "OperationId",
        caption: "Id operação",
        allowHeaderFiltering: false,
        sortOrder: "desc",
      },

      { dataField: "SacadoName", caption: "Sacado" },
      {
        dataField: "SacadoIdentification",
        caption: "CNPJ do sacado",
        cellRender: ({ data }) => masks.cpfCnpj(data.SacadoIdentification),
      },
      { dataField: "CedenteName", caption: "Cedente" },
      {
        dataField: "CedenteIdentification",
        caption: "CNPJ do cedente",
        cellRender: ({ data }) => masks.cpfCnpj(data.CedenteIdentification),
      },
      {
        dataField: "TotalTrades",
        dataType: "number",
        caption: "Quantidade de recebíveis",
      },
      {
        dataField: "TotalCurrentAmount",
        dataType: "number",
        caption: "Valor da operação",
        cellRender: ({ data }) => masks.currency(data.TotalCurrentAmount, "R$"),
      },
    ],
  };
