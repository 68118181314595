import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { NotificationConfigOdataDataItem } from "services/raven/models/responses";
import { format } from "utils/date";
import { env } from "utils/environments";

const redirectDetailLink = (baseUrl: string, name: string) => {
  const stringifiedName = JSON.stringify(name);

  return `${baseUrl}?name=${stringifiedName}`;
};

export const buildGrid = (
  deleteRow: (id: number) => void,
): ODataGridGeneratorConfig<NotificationConfigOdataDataItem> => ({
  datagrid: {
    noDataText: "Nenhum template encontrado",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    { dataField: "name", caption: "Nome", sortOrder: "asc" },
    { dataField: "type", caption: "Tipo" },
    {
      dataField: "fileKey",
      caption: "Diretório do Arquivo",
      width: "300px",
    },
    {
      dataField: "variablesList",
      caption: "Variáveis",
      width: "300px",
    },
    {
      dataField: "defaultTitle",
      caption: "Assunto/Título Padrão do E-mail",
    },
    {
      dataField: "createdAt",
      caption: "Criado em",
      dataType: "string",
      format: (value) => format.dateTime(value),
    },
    {
      dataField: "updatedAt",
      caption: "Atualizado em",
      dataType: "string",
      format: (value) => format.dateTime(value),
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Detalhes",
              role: roles.ferramentas.product.ravenDetailsPage.role,
              route: redirectDetailLink(
                corporateRouter.routes.ferramentas.product.raven.templates
                  .details.path,
                data.name,
              ),
            },
            {
              icon: { category: "orq", icon: "orq-file" },
              label: "Ver template",
              onClick: () => window.open(`${env.API_ASSETS}/${data.fileKey}`),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir",
              onClick: () => deleteRow(rowIndex),
            },
          ]}
        />
      ),
    },
  ],
});
