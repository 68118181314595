import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { GetContractGeneratorByProductIdResponse } from "services/fidc/models/responses";
import {
  consolidatedContractStatusMap,
  downloadContract,
} from "./consolidated-contracts.utils";

export const consolidatedContractsGrid: ODataGridGeneratorConfig<
  GetContractGeneratorByProductIdResponse["items"][number]
> = {
  datagrid: {
    noDataText: "Nenhum contrato encontrado.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} contratos)",
    },
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "relatedTaxId",
      caption: "CNPJ relacionado",
    },
    {
      dataField: "contractInfo.paperclipId",
      caption: "Id Paperclip",
      cellRender: ({ data }) => data.contractInfo.paperclipId,
    },
    {
      dataField: "contractInfo.status",
      caption: "Status",
      cellRender: ({ data }) =>
        consolidatedContractStatusMap[data.contractInfo.status] ?? "Indefinido",
    },
    {
      dataField: "fromDate",
      caption: "Data início",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "toDate",
      caption: "Data Final",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "sentToNotaryDate",
      caption: "Data de envio para cartório",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "notaryValidatedDate",
      caption: "Data de retorno do cartório",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) =>
        data.contractInfo.paperclipId && (
          <IconButton
            icon={{ category: "orq", icon: "orq-download" }}
            dataAction="contratos_consolidados:botao:baixar_contrato"
            dataLabel="baixar_contrato"
            outline
            onClick={() => downloadContract(data)}
          >
            Baixar contrato
          </IconButton>
        ),
    },
  ],
};
