import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { useGridRef } from "hooks/grid-ref";
import { LoginActivityGrid } from "./atividades-login.grid";
import { useLoginActivities } from "./atividades-login.hook";

export const LoginActivity = () => {
  const gridRef = useGridRef();
  const { dataSource } = useLoginActivities();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Atividades de Login" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={LoginActivityGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
