import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useServiceCall, UseServiceCallReturn } from "hooks/service-call";
import { service } from "services";

interface EditStepConfigContext {
  documentTypesLoading: boolean;
  documentTypesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DocumentTypeResponse[]>
    | undefined;
  pendencyConfigsFetch: UseServiceCallReturn<
    typeof service.hubEnergy.getDiligenceStepPendencyConfigs
  >["callService"];
  pendencyConfigsLoading: boolean;
  pendencyConfigsValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyConfigResponse[]>
    | undefined;
  pendencyTypesLoading: boolean;
  pendencyTypesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyTypeResponse[]>
    | undefined;
  propertyConfigsFetch: UseServiceCallReturn<
    typeof service.hubEnergy.getDiligenceStepPropertyConfigs
  >["callService"];
  propertyConfigsLoading: boolean;
  propertyConfigsValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyConfigResponse[]>
    | undefined;
  propertyTypesLoading: boolean;
  propertyTypesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse[]>
    | undefined;
}

const editStepConfigContext = createContext({} as EditStepConfigContext);

interface EditStepConfigProviderProps {
  children: React.ReactNode;
}

export const EditStepConfigProvider = ({
  children,
}: EditStepConfigProviderProps) => {
  const {
    callService: getPendencyTypes,
    loading: pendencyTypesLoading,
    value: pendencyTypesValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPendencyTypes);

  const {
    callService: getPropertyTypes,
    loading: propertyTypesLoading,
    value: propertyTypesValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPropertyTypes);

  const {
    callService: getDocumentTypes,
    loading: documentTypesLoading,
    value: documentTypesValue,
  } = useServiceCall(service.hubEnergy.getDocumentTypes);

  const {
    callService: propertyConfigsFetch,
    loading: propertyConfigsLoading,
    value: propertyConfigsValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPropertyConfigs);

  const {
    callService: pendencyConfigsFetch,
    loading: pendencyConfigsLoading,
    value: pendencyConfigsValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPendencyConfigs);

  useEffect(() => {
    getPropertyTypes();
  }, [getPropertyTypes]);

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  useEffect(() => {
    getPendencyTypes();
  }, [getPendencyTypes]);

  const value = useMemo(
    () => ({
      documentTypesLoading,
      documentTypesValue,
      pendencyConfigsFetch,
      pendencyConfigsLoading,
      pendencyConfigsValue,
      pendencyTypesLoading,
      pendencyTypesValue,
      propertyConfigsFetch,
      propertyConfigsLoading,
      propertyConfigsValue,
      propertyTypesLoading,
      propertyTypesValue,
    }),
    [
      documentTypesLoading,
      documentTypesValue,
      pendencyConfigsFetch,
      pendencyConfigsLoading,
      pendencyConfigsValue,
      pendencyTypesLoading,
      pendencyTypesValue,
      propertyConfigsFetch,
      propertyConfigsLoading,
      propertyConfigsValue,
      propertyTypesLoading,
      propertyTypesValue,
    ],
  );

  return (
    <editStepConfigContext.Provider value={value}>
      {children}
    </editStepConfigContext.Provider>
  );
};

export const useEditStepConfigContext = () => useContext(editStepConfigContext);
