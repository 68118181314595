import {
  VisaIcon,
  MastercardIcon,
  AmexIcon,
  EloIcon,
  HipercardIcon,
} from "../../components";

export const cardIconMap = {
  Visa: <VisaIcon width="28px" height="21px" />,
  MasterCard: <MastercardIcon width="28px" height="21px" />,
  AmericanExpress: <AmexIcon width="28px" height="21px" />,
  Elo: <EloIcon width="28px" height="21px" />,
  HiperCard: <HipercardIcon width="28px" height="21px" />,
};
