import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import {
  CollectionOrigin,
  CollectionStatus,
  CollectionType,
  DetailItem,
  FeesType,
  InterestType,
} from "./bankslip-details.types";

export const MapperCollectionStatus: Record<
  CollectionStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [CollectionStatus.Canceled]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.CancelFailed]: {
    type: "danger",
    label: "Falha no cancelamento",
  },
  [CollectionStatus.Canceling]: {
    type: "info",
    label: "Cancelando",
  },
  [CollectionStatus.Created]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Expired]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.Failed]: {
    type: "danger",
    label: "Falha",
  },
  [CollectionStatus.Overdue]: {
    type: "warning",
    label: "Vencido",
  },
  [CollectionStatus.Paid]: {
    type: "success",
    label: "Pago",
  },
  [CollectionStatus.Processing]: {
    type: "info",
    label: "Registrando",
  },
  [CollectionStatus.ProcessingPayment]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Updated]: {
    type: "success",
    label: "Atualizado",
  },
  [CollectionStatus.UpdateFailed]: {
    type: "danger",
    label: "Falha na atualização",
  },
  [CollectionStatus.Updating]: {
    type: "info",
    label: "Atualizando",
  },
};

const MapperCollectionOrigin: Record<CollectionOrigin, string> = {
  [CollectionOrigin.Web]: "Portal Web",
  [CollectionOrigin.Developers]: "Developers",
  [CollectionOrigin.App]: "Aplicativo",
  [CollectionOrigin.CNAB]: "CNAB",
  [CollectionOrigin.PaymentLink]: "Link de pagamento",
};

const MapperCollectionType: Record<CollectionType, string> = {
  [CollectionType.BANKSLIP]: "Boleto",
  [CollectionType.BANKSLIP_QRCODE]: "Boleto + QR Code",
};

const assembleFormattedDates = (bankslip: BankingHub.Collection) => {
  const createdAt = dayjs(bankslip.createdAt).format(
    "DD/MM/YYYY [às] HH:mm:ss",
  );
  const updatedAt = dayjs(bankslip.updatedAt)
    .subtract(3, "hours")
    .format("DD/MM/YYYY [às] HH:mm:ss");

  const dueDate = dayjs(bankslip.dueDate.replace("Z", "")).format("DD/MM/YYYY");
  const overDueDate = dayjs(bankslip.overDueDate.replace("Z", "")).format(
    "DD/MM/YYYY",
  );

  return { createdAt, dueDate, overDueDate, updatedAt };
};

const formatAccount = ({ branchCode, number }: BankingHub.Payee) => {
  const agency = branchCode.padStart(4, "0");
  const accountNumber = masks.generic(number.padStart(9, "0"), {
    mask: "00000000-0",
  });

  return `Ag. ${agency} | Conta ${accountNumber}`;
};

export const assembleFees = (fee?: BankingHub.Fee) => {
  if (!fee?.value) return;
  if (fee.type === FeesType.FIXED_VALUE) return masks.currency(fee.value, "R$");
  if (fee.type === FeesType.PERCENTAGE) return `${fee.value}%`;
  return "-";
};

export const assembleInterest = (interest: BankingHub.CollectionInterest) => {
  if (!interest.value) return;
  if (interest.type === InterestType.FIXED_VALUE_PER_MONTH)
    return masks.currency(interest.value, "R$");
  if (interest.type === InterestType.PERCENTAGE_PER_MONTH)
    return `${interest.value}%`;
  return "-";
};

export const assembleDetailLines = (bankslip: BankingHub.Collection) => {
  const { createdAt, dueDate, overDueDate, updatedAt } =
    assembleFormattedDates(bankslip);

  const {
    amount,
    anticipation,
    detail: { barCode, documentNumber, digitableLine, ourNumber },
    discounts,
    batchId,
    type,
    origin,
    paidAt,
    settledAt,
    amountPaid,
    description,
    fine,
    interest,
    payee,
    payer,
    status,
  } = bankslip;

  const resumeDetails: DetailItem[][] = [
    [
      { title: "Valor", content: masks.currency(amount, "R$") },
      { title: "Descrição", content: description || "-" },
    ],
    [
      { title: "Data de criação", content: createdAt },
      { title: "Última atualização", content: updatedAt },
    ],
    [
      { title: "Data de vencimento", content: dueDate },
      { title: "Data de expiração", content: overDueDate },
    ],
    [
      {
        title: "Código de barras",
        content: masks.generic(barCode, {
          mask: "000 0 000000000000000 00000 000000 00000000000000",
        }),
        textToCopy: barCode,
      },
      {
        title: "Linha digitável",
        content: masks.generic(digitableLine, {
          mask: "00000.00000 00000.000000 00000.000000 0 00000000000000",
        }),
        textToCopy: digitableLine,
      },
    ],
  ];

  const payeeDetails: DetailItem[][] = [
    [
      { title: "Nome / Razão social", content: payee.name },
      {
        title: "CPF / CNPJ",
        content: masks.cpfCnpj(payee.taxId),
        textToCopy: payee.taxId,
      },
    ],
    [{ title: "Conta para crédito", content: formatAccount(payee) }],
  ];

  const payerDetails: DetailItem[][] = [
    [
      { title: "Nome / Razão social", content: payer.name },
      {
        title: "CPF / CNPJ",
        content: masks.cpfCnpj(payer.taxId),
        textToCopy: payer.taxId,
      },
    ],
    [
      {
        title: "Telefone",
        content: payer.phoneNumber ? masks.phone(payer.phoneNumber) : "-",
      },
      { title: "E-mail", content: payer.email || "-" },
    ],
  ];

  const feesDetails: DetailItem[][] = [
    [
      { title: "Juros", content: assembleInterest(interest) ?? "Sem juros" },
      { title: "Multa", content: assembleFees(fine) ?? "Sem multa" },
    ],
    [
      {
        title: "Desconto",
        content: assembleFees(discounts?.[0]) ?? "Sem desconto",
      },
      {
        title: "Data limite de desconto",
        content:
          discounts?.length && discounts[0].limitDate
            ? dayjs(discounts[0].limitDate).format("DD/MM/YYYY")
            : "-",
      },
    ],
  ];

  const generalDetails: DetailItem[][] = [
    [
      {
        title: "Nº Documento",
        content: documentNumber ?? "-",
        textToCopy: documentNumber,
      },
      {
        title: "Nosso número",
        content: ourNumber ?? "-",
        textToCopy: ourNumber,
      },
    ],
    [
      {
        title: "Origem da emissão",
        content: origin ? MapperCollectionOrigin[origin] : "-",
      },
      {
        title: "Tipo da emissão",
        content: batchId ? "Lote" : "Unitária",
      },
    ],
    [
      {
        title: "Tipo do boleto",
        content: MapperCollectionType[type],
      },
      {
        title: "Antecipado?",
        content: anticipation?.isAnticipated ? "Sim" : "Não",
      },
    ],
  ];

  const paymentDetails: DetailItem[][] =
    status === CollectionStatus.Paid
      ? [
          [
            {
              title: "Data de pagamento",
              content: paidAt
                ? dayjs(paidAt.replace("Z", "")).format("DD/MM/YYYY")
                : "-",
            },
            {
              title: "Data de liquidação",
              content: settledAt ? dayjs(settledAt).format("DD/MM/YYYY") : "-",
            },
          ],
          [
            {
              title: "Valor pago",
              content: amountPaid ? masks.currency(amountPaid, "R$") : "-",
            },
          ],
        ]
      : [];

  return {
    resumeDetails,
    payeeDetails,
    payerDetails,
    feesDetails,
    paymentDetails,
    generalDetails,
  };
};
