import { CardType, Currency } from "../../../cards.types";

export interface ITransactionsProps {
  accountId: string;
  invoiceId: number;
  selectedCompany: string;
  openContestations: string[];
  setOpenContestations: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

interface IAmount {
  type: string;
  currency: Currency;
  value: number;
  rate?: number;
}

export enum AuthorizationType {
  Network = "NETWORK",
  Ecommerce = "ECOMMERCE",
  Chip = "CHIP",
  Contactless = "CONTACTLESS",
  CardOnFile = "CARDONFILE",
  Platform = "PLATFORM",
}

export interface IInvoiceTransactionItem {
  id: number;
  creditAccountId: number;
  statementId: number;
  installment: number;
  numberOfInstallments: number;
  softDescriptor: string;
  processingCode: string;
  processingDescription: string;
  customerId: number;
  userCategory: string;
  amount: Array<IAmount>;
  eventDate: string;
  createdAt: string;
  isInternational: boolean;
  dcc: boolean;
  transactionType: {
    id: number;
    description: string;
    isCredit: boolean;
    postedTransaction: boolean;
  };
  card: {
    id: number;
    name: string;
    last4Digits: string;
    type: CardType;
  };
  authorization: {
    id: number;
    type: AuthorizationType;
    code: string;
    description: string;
    paymentMethodId: string;
    trackingId: string;
  };
  merchant: {
    type: string;
    name: string;
    city: string;
    state: string;
    imageUrl: string;
    category: {
      code: string;
      description: string;
      groupName: string;
      networkGroup: string;
      imageURL: string;
    };
  };
}

export interface IInvoiceTransactions {
  hasNext: boolean;
  items: Array<IInvoiceTransactionItem>;
}
