import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { DocumentOrBills } from "./_compose";

interface PropertyValueRendererProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const PropertyValueRenderer = ({
  property,
}: PropertyValueRendererProps) => {
  switch (property.type.mask) {
    case "ADEQUACY_OPTION":
      return property.adequacyOption ? (
        <>
          {property.adequacyOption.name} -{" "}
          {masks.cpfCnpj(property.adequacyOption.entity.taxId)}
        </>
      ) : (
        <em>{"<em branco>"}</em>
      );
    case "BOOLEAN":
      return property.value?.toLowerCase() === "true" ? (
        <OIcon
          category="fa"
          icon="fa-check-square"
          type="info"
          className="d-inline-block"
          size="lg"
        />
      ) : (
        <OIcon
          category="fa"
          icon="fa-times-square"
          type="danger"
          className="d-inline-block"
          size="lg"
        />
      );
    case "CNPJ":
    case "CPF":
      return property.value ? (
        <>{masks.cpfCnpj(property.value)}</>
      ) : (
        <em>{"<em branco>"}</em>
      );
    case "DATE":
      return property.value ? (
        <>{dayjs(property.value).format("DD/MM/YYYY")}</>
      ) : (
        <em>{"<em branco>"}</em>
      );
    case "DOCUMENT":
      return <DocumentOrBills property={property} onlyFiles disabled />;
    default:
      return property.value ? <>{property.value}</> : <em>{"<em branco>"}</em>;
  }
};
