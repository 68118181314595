import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { validationSchema } from "../_compose/configuracoes-form/configuration-form.form";
import { ConfigurationFormValuesType } from "../_compose/configuracoes-form/configuration-form.types";

export const useRavenNotificationConfigurationDetails = (
  id: string | undefined,
) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<ConfigurationFormValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const submit = async () => {
    try {
      setLoading(true);

      if (id) {
        const {
          channel,
          "sendOnDefault?": sendOnDefault,
          "userOverridable?": userOverridable,
        } = form.getValues();

        const isSendOnDefault = sendOnDefault === "true";
        const isUserOverridable = userOverridable === "true";

        const payload = {
          channel,
          "send_on_default?": isSendOnDefault,
          "user_overridable?": isUserOverridable,
        };

        await service.raven.updateNotificationConfigurationById(id, payload);

        OToastManager.success("Tipo de notificação atualizado com sucesso");
      } else {
        OToastManager.warning("Notificação não encontrada");
      }
    } catch (err) {
      OToastManager.danger("Erro ao salvar os dados");
    } finally {
      setLoading(false);
    }
  };

  const load = useCallback(async () => {
    try {
      setLoading(true);

      if (id == null) return;

      const { data } = await service.raven.getNotificationConfigurationById(id);

      form.setValue("channel", data.data.channel);
      form.setValue("sendOnDefault?", String(data.data["sendOnDefault?"]));
      form.setValue("userOverridable?", String(data.data["userOverridable?"]));
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [form, id]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    loading,
  };
};
