import { ISize } from "@maestro/core";
import {
  AugmentedBadge,
  AugmentedIcon,
  CustomTokens,
} from "./augmented-status-badge.styles";
import { biggerSizeMap, typeMap } from "./augmented-status-badge.utils";

export interface AugmentedStatusBadgeProps {
  type: keyof typeof typeMap;
  size?: ISize;
  className?: string;
  children: React.ReactNode;
}

export const AugmentedStatusBadge = ({
  type,
  size = "sm",
  className,
  children,
}: AugmentedStatusBadgeProps) => {
  const { badgeColor, category, icon, iconColor } = typeMap[type];

  const iconSize = biggerSizeMap[size];

  return (
    <>
      <CustomTokens />
      <AugmentedBadge
        rounded
        $customColor={badgeColor}
        size={size}
        className={className}
        type="light" // dummy
      >
        <AugmentedIcon
          category={category}
          icon={icon}
          $customColor={iconColor}
          size={iconSize}
          type="light" // dummy
        />
        {children}
      </AugmentedBadge>
    </>
  );
};
