import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ReceivablesCustomerByReceivableIdRouteParams } from "../../../../../routes/recebiveis.route-params";

export const useAdditionsDetails = () => {
  const { receivableId } =
    useParams<ReceivablesCustomerByReceivableIdRouteParams>();

  const {
    callService: getReceivableDetail,
    value: detail,
    loading,
  } = useServiceCall(service.quickfin.getReceivableStateMachineById);

  useEffect(() => {
    if (!receivableId) return;

    getReceivableDetail(receivableId);
  }, [getReceivableDetail, receivableId]);

  return {
    detail,
    loading,
    receivableId,
  };
};
