import { OClickableBox, OLabel } from "@maestro/react";
import { Props } from "../../export-modal.types";

export const ExportRadio = ({
  label,
  value,
  radioName,
  ...props
}: Props & { radioName: string }) => {
  return (
    <OClickableBox
      inputType="radio"
      size="sm"
      id={value}
      name={radioName}
      value={value}
      dataAction="statement-export-filter"
      dataLabel="statement-export-filter"
      checked={!!props.default}
    >
      <OLabel className="text-nowrap" size="xxs" htmlFor={value}>
        {label}
      </OLabel>
    </OClickableBox>
  );
};
