import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { contractSignatoriesFields } from "./contract-signatories-form.form";
import { useContractSignatoriesFormFields } from "./contract-signatories-form.hook";
import { ContractSignatoriesForm } from "./contract-signatories-form.types";

interface ContractSignatoriesFormFieldsProps {
  form: UseFormReturn<ContractSignatoriesForm>;
  disableTaxId?: boolean;
}

export const ContractSignatoriesFormFields = ({
  form,
  disableTaxId,
}: ContractSignatoriesFormFieldsProps) => {
  const { getCompanyLoading, hasOptions, options } =
    useContractSignatoriesFormFields(form, disableTaxId);

  return (
    <FormProvider {...form}>
      <ORow className="mb-3">
        <OCol xs={12} md={6} align="center">
          <ORFieldInput
            {...contractSignatoriesFields.taxId}
            disabled={disableTaxId}
          />
          {getCompanyLoading && <OLoader size="sm" />}
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12}>
          <SelectSearchMultiple
            {...contractSignatoriesFields.identifications}
            options={options as any}
            disabled={!hasOptions}
            placeholder={
              hasOptions
                ? "Selecionar"
                : "Primeiro digite o CNPJ/CPF da empresa"
            }
          />
        </OCol>
      </ORow>
    </FormProvider>
  );
};
