import { OIcon, OToastManager } from "@maestro/react";
import { OTruncateTypography } from "../o-truncate-typography";
import { CopyToClipboardProps } from "./copy-to-clipboard.types";

export const CopyToClipboard = ({
  text,
  textToCopy,
  textType,
  textColSize,
  justifyContent = "unset",
  successText,
}: CopyToClipboardProps) => {
  const textColSizeClassName = textColSize ? `col-${textColSize}` : "";
  const buttonColSizeClassName =
    justifyContent !== "unset"
      ? "col-auto"
      : textColSize
      ? `col-${12 - textColSize}`
      : "";

  return (
    <div
      className={`d-flex align-items-center justify-content-${justifyContent} gap-${
        textColSize ? 0 : 2
      }`}
    >
      <div className={`${textColSizeClassName} p-0`}>
        <OTruncateTypography type={textType ?? "dark"} key={text} title={text}>
          {text}
        </OTruncateTypography>
      </div>
      <div className={`${buttonColSizeClassName} text-right p-0`}>
        <OIcon
          className="btn p-0"
          category="orq"
          icon="orq-edit-copy"
          size="lg"
          onClick={() => {
            navigator.clipboard.writeText(textToCopy ?? text);
            OToastManager.success(successText);
          }}
        />
      </div>
    </div>
  );
};
