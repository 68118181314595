import { OCol, OLoader, ORow, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import {
  SelectSearchMultiple,
  SelectSearchMultipleOption,
} from "components/form";
import { useEffect, useState } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export const ProfileRolesForm = () => {
  const [rolesOptions, setRolesOptions] = useState<
    SelectSearchMultipleOption<string | number>[]
  >([]);

  const [loadingRoles, setLoadingRoles] = useState<boolean>();

  const getRoles = async () => {
    try {
      setLoadingRoles(true);
      const { data } = await service.gatekeeper.getRoles();

      const extractedOptions = data.map(({ name }) => ({
        value: name,
        label: name,
      }));

      setRolesOptions(extractedOptions);
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      OToastManager.danger("Erro ao obter as roles");
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <ORow className="flex-fill">
      <OCol xs={12} sm={8} className="flex-column" align="center">
        <SelectSearchMultiple
          id="roles"
          name="roles"
          placeholder="Selecione"
          options={rolesOptions}
          label="Roles"
          disabled={loadingRoles}
        />
        {loadingRoles && <OLoader />}
      </OCol>
    </ORow>
  );
};
