const fastCpfMask = (text: string | undefined | null) => {
  if (!text) return "";

  const maskedDigits: string[] = [];

  text
    .replace(/\D/g, "")
    .split("")
    .forEach((digit, idx) => {
      maskedDigits.push(digit);
      if (idx === 2 || idx === 5) maskedDigits.push(".");
      if (idx === 8) maskedDigits.push("-");
    });

  return maskedDigits.slice(0, 14).join("");
};

const fastCnpjMask = (text: string | undefined | null) => {
  if (!text) return "";

  const maskedDigits: string[] = [];

  text
    .replace(/\D/g, "")
    .split("")
    .forEach((digit, idx) => {
      maskedDigits.push(digit);
      if (idx === 1 || idx === 4) maskedDigits.push(".");
      if (idx === 7) maskedDigits.push("/");
      if (idx === 11) maskedDigits.push("-");
    });

  return maskedDigits.slice(0, 18).join("");
};

export const fastCpfCnpjMask = (text: string | undefined | null) => {
  if (!text) return "";

  if (text.length <= 11) return fastCpfMask(text);

  return fastCnpjMask(text);
};
