import { AnticipationComponent } from "./anticipation.component";
import { AnticipationProvider } from "./anticipation.context";

export const AnticipationPage = () => {
  return (
    <AnticipationProvider>
      <AnticipationComponent />
    </AnticipationProvider>
  );
};
