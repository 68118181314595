import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GroupOfSignatures } from "../signature-group/signature-group.types";

const buildDetailsLink = (ruleId: number, groupId: number) =>
  corporateRouter.routes.recebiveis.customer.signatures.byRuleId.byGroupId.details.path(
    {
      groupId,
      ruleId,
    },
  );

export const buildSignatureGroupsGrid = (
  ruleId: number,
): ODataGridGeneratorConfig<GroupOfSignatures> => ({
  datagrid: {
    noDataText: "Nenhum grupo de assinaturas",
  },
  columns: [
    {
      role: roles.recebiveis.customer.signaturesByRuleIdByGroupIdDetailsPage
        .role,
      caption: "Detalhes",
      cellRender: ({ data }) => (
        <DetailsLink href={buildDetailsLink(ruleId, data.id)} />
      ),
    },
    {
      dataField: "name",
      caption: "Nome do grupo",
    },
    {
      dataField: "minimumNumberOfSigners",
      caption: "Quantidade de assinaturas",
      alignment: "left",
    },
    {
      dataField: "emails",
      caption: "Signatários",
      calculateCellValue: ({ emails }) => emails.join(", "),
    },
  ],
});
