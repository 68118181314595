import { OToastManager } from "@maestro/react";
import { helpers, masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { currencySymbol } from "utils/currency";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { invoiceProductsGrid } from "./invoice-products.grid";
import { LimitInvoicesEditButton } from "./limit-invoices-edit-button.component";
import { useLimitInvoices } from "./limit-invoices.hook";

export const LimitInvoicePage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const [loading, setLoading] = useState({ service: false, product: false });

  const {
    productsInvoice,
    serviceInvoice,
    hasError,
    loading: loadingLimitRequest,
    callService,
  } = useLimitInvoices(id);

  const downloadInvoice = useCallback(
    async (
      fileKey: string,
      fileName: string,
      symbol: "service" | "product",
    ) => {
      try {
        setLoading((previousLoading) => ({
          ...previousLoading,
          [symbol]: true,
        }));
        const { data: blob } = await service.hubCreditManager.download({
          key: fileKey,
        });

        helpers.downloadBlobFile(fileName, blob);
      } catch (error) {
        OToastManager.danger("Não foi possível baixar o arquivo.");
      } finally {
        setLoading((previousLoading) => ({
          ...previousLoading,
          [symbol]: false,
        }));
      }
    },
    [],
  );

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Notas fiscais" />}
      actions={
        <>
          {(serviceInvoice || productsInvoice) && (
            <LimitInvoicesEditButton limitRequestId={id} />
          )}
          {serviceInvoice?.file?.key && (
            <IconButton
              icon={{ category: "fal", icon: "fa-download" }}
              loading={loading.service}
              dataAction="emprestimos_notas_fiscais:botao:download_nota_servico"
              dataLabel="download_nota_servico"
              onClick={() =>
                downloadInvoice(
                  serviceInvoice.file.key,
                  serviceInvoice.file.name,
                  "service",
                )
              }
              outline
            >
              Dowload nota fiscal de serviço
            </IconButton>
          )}
          {productsInvoice?.file?.key && (
            <IconButton
              icon={{ category: "fal", icon: "fa-download" }}
              loading={loading.product}
              dataAction="emprestimos_notas_fiscais:botao:download_nota_produto"
              dataLabel="download_nota_produto"
              onClick={() =>
                downloadInvoice(
                  productsInvoice.file.key,
                  productsInvoice.file.name,
                  "product",
                )
              }
              outline
            >
              Dowload nota fiscal de produto
            </IconButton>
          )}
        </>
      }
    >
      <ContentTemplate
        loading={loadingLimitRequest}
        hasError={hasError}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => callService(id)} />
          </ErrorComponent>
        }
        render={() => (
          <DetailsCard
            fields={{
              servico: {
                title: "Nota fiscal de serviço",
                items: [
                  {
                    label: "Número da nota",
                    value: serviceInvoice?.invoiceNumber,
                    grid: { md: 6 },
                  },
                  {
                    label: "Valor total",
                    value: masks.currency(
                      serviceInvoice?.amount,
                      currencySymbol.BRL,
                    ),
                    grid: { md: 6 },
                  },
                ],
              },
              produto: {
                title: "Nota fiscal de produto",
                items: [
                  {
                    label: "Número da nota",
                    value: productsInvoice?.invoiceNumber,
                    grid: { md: 6 },
                  },
                  {
                    label: "Valor total",
                    value: masks.currency(
                      productsInvoice?.amount,
                      currencySymbol.BRL,
                    ),
                    grid: { md: 6 },
                  },
                  {
                    value: (
                      <ODataGridGenerator
                        grid={invoiceProductsGrid(true)}
                        dataSource={productsInvoice?.invoiceProducts ?? []}
                      />
                    ),
                  },
                ],
              },
            }}
          />
        )}
      />
    </DetailsTemplate>
  );
};
