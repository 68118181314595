import * as yup from "yup";

export interface ViewBigNumberFormFields {
  gridId: number;
}

export const viewBigNumberFormSchema = yup.object().shape({
  gridId: yup.number(),
});

export const viewBigNumberFormDefaultValues = {
  gridId: 0,
};
