import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  ORFieldUploadInput,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { createPortal } from "react-dom";
import { FormProvider } from "react-hook-form";
import { Contract } from "../../contracts-grid.types";
import { useEditContractModal } from "./edit-contract-modal.hook";
import {
  contractPlatformOptions,
  contractStatusOptions,
  editContractModalId,
} from "./edit-contract-modal.utils";

interface EditContractModalProps {
  contract: Contract;
  reload: () => void;
}

export const EditContractModal = ({
  contract,
  reload,
}: EditContractModalProps) => {
  const uuid = (contract.UUID as any)._value;

  const { fileLoading, form, handleAddFile, loading, updateContract } =
    useEditContractModal(contract, reload);

  const { clearErrors, setValue } = form;

  const modalId = editContractModalId(uuid);

  return createPortal(
    <OModal id={modalId} position="center" size="sm">
      <OModalHeader>
        <OTypography size="lg" style={{ whiteSpace: "normal" }}>
          Editando o contrato {uuid} - {contract.ContractType}
        </OTypography>
      </OModalHeader>
      <OModalBody className="p-2">
        <FormProvider {...form}>
          <div className="d-flex flex-column align-items-stretch gap-3">
            <ORFieldSelect
              dataAction="modal_editar_contrato:select:plataforma"
              dataLabel="plataforma"
              id="platform"
              name="platform"
              placeholder="Selecionar:"
              label="Plataforma"
            >
              {contractPlatformOptions.map((option) => (
                <OOption value={option} key={option}>
                  {option}
                </OOption>
              ))}
            </ORFieldSelect>
            <ORFieldSelect
              dataAction="modal_editar_contrato:select:status"
              dataLabel="status"
              id="status"
              name="status"
              placeholder="Selecionar:"
              label="Status"
            >
              {contractStatusOptions.map((option) => (
                <OOption value={option} key={option}>
                  {option}
                </OOption>
              ))}
            </ORFieldSelect>
            <ORFieldUploadInput
              id="uploadDummy"
              name="uploadDummy"
              inputLabel="Clique ou arraste o arquivo aqui"
              dataAction="modal_editar_contrato:upload:contrato"
              dataLabel="modal_editar_contrato"
              multiple={false}
              handleAddFile={handleAddFile}
              handleRemove={(file, resolve) => {
                clearErrors("uploadDummy");
                setValue("fileBase64", undefined);
                resolve(file);
              }}
              disabled={fileLoading}
              accept="*"
              label="Arquivo"
              value={[]}
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            dataAction="modal_editar_contrato:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton
            dataAction="modal_editar_contrato:botao:salvar"
            dataLabel="salvar"
            loading={loading}
            onClick={updateContract}
          >
            Salvar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>,
    window.document.body,
  );
};
