import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { ExchangeOrderEntriesResponse } from "services/hubfx/models/types/order/order-entries.model";

export const exchangeOrderEntriesGrid = (
  mapSymbol: (currency?: string | null) => string,
) => {
  return {
    datagrid: {
      noDataText: "Nenhuma entrada encontrada",
      headerFilter: { visible: true, allowSearch: true },
    },
    columns: [
      {
        dataField: "entryDate",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        allowHeaderFiltering: false,
        sortOrder: "desc",
        caption: "Data de entrada",
      },
      {
        dataField: "isActive",
        caption: "Entrada ativa?",
        dataType: "boolean",
        allowHeaderFiltering: false,
      },
      {
        caption: "Data de referência",
        dataField: "referenceDate",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        allowHeaderFiltering: false,
      },
      { caption: "Classificação", dataField: "classification" },
      { caption: "Moeda", dataField: "currency" },
      {
        caption: "Quantidade",
        cellRender: ({ data }) =>
          masks.currency(data.amount, mapSymbol(data.currency), "."),
      },
      { caption: "Referência", dataField: "reference" },
      { caption: "Lado", dataField: "side" },
      { caption: "Conta", dataField: "account" },
    ],
  } satisfies ODataGridGeneratorConfig<ExchangeOrderEntriesResponse>;
};
