import { OCard, OCardBody, ODivider } from "@maestro/react";
import { DetailsWithoutCard } from "components/details-card";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useRoles } from "hooks/roles";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../../../../../components/validation-error-component";
import { energiaRoles } from "../../../../../../../../roles/energia.roles";
import { useDiligenceStep } from "../../diligence-step.context";
import {
  ApproveStepButton,
  ConcludeStepButton,
  ConsumerUnitInfo,
  RequestCorrectionStepButton,
} from "./_compose";
import { diligenceStepFields } from "./step-details.details";
import { useStepDetails } from "./step-details.hook";

const concludeButtonAvailableStatus: HubEnergy.EDiligenceStepStatus[] = [
  "EM_ANALISE",
  "AGUARDANDO_DISTRIBUIDORA",
];

export const StepDetails = () => {
  const { hasRole } = useRoles();

  const { stepId } = useDiligenceStep();

  const {
    getDiligenceStep,
    stepError,
    stepHasError,
    stepLoading,
    stepData,
    form,
    submitLoading,
    submit,
  } = useStepDetails(stepId);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={stepData?.type?.name ?? "Etapa da migração"}
          description={stepData?.type?.description}
        />
      }
      actions={
        <>
          {stepData?.id &&
            !stepLoading &&
            hasRole(energiaRoles.customer.migrationStepsEdit.role) && (
              <>
                {stepData.status === "AGUARDANDO_APROVACAO_ETAPA" && (
                  <ApproveStepButton
                    diligenceStepId={stepData.id}
                    reload={getDiligenceStep}
                  />
                )}
                {concludeButtonAvailableStatus.includes(stepData.status) && (
                  <ConcludeStepButton
                    diligenceStepId={stepData.id}
                    reload={getDiligenceStep}
                  />
                )}
                {stepData.status === "EM_ANALISE" && (
                  <RequestCorrectionStepButton
                    diligenceStepId={stepData.id}
                    reload={getDiligenceStep}
                  />
                )}
              </>
            )}
          {hasRole(energiaRoles.customer.migrationStepsUpdateInfo.role) && (
            <LoadingButton
              loading={submitLoading}
              disabled={!stepData}
              onClick={submit}
            >
              Salvar
            </LoadingButton>
          )}
        </>
      }
    >
      <FormProvider {...form}>
        <OCard>
          <OCardBody className="position-relative">
            <ConsumerUnitInfo />
            <ODivider size="xxs" className="my-4" />
            <DetailsWithoutCard
              loading={stepLoading}
              hasError={stepHasError}
              errorComponent={
                <OCard>
                  <OCardBody>
                    <ValidationErrorComponent
                      error={stepError}
                      title="Erro ao buscar o step"
                      callback={getDiligenceStep}
                    />
                  </OCardBody>
                </OCard>
              }
              value={stepData}
              fields={diligenceStepFields}
            />
          </OCardBody>
        </OCard>
      </FormProvider>
    </DetailsTemplate>
  );
};
