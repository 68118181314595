import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeGenericFieldsForm } from "../../../../../../components/nature/generic-fields/generic-fields-form.component";
import {
  ExchangeGenericFieldsFormValues,
  exchangeGenericFieldsFormValidationFormSchema,
} from "../../../../../../components/nature/generic-fields/generic-fields-form.schemas";
import { parseFxPendencyCodes } from "../../../../../../components/nature/generic-fields/generic-fields.utils";
import { CambioNaturezasByIdRouteParams } from "../../../../../../routes/cambio.route-params";

export const ExchangeProductUpdateGenericFields = () => {
  const { id } = useParams<CambioNaturezasByIdRouteParams>();

  if (!id) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<ExchangeGenericFieldsFormValues>({
    resolver: yupResolver(exchangeGenericFieldsFormValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getNatureGenericFieldTypeDetails,
    loading: loadingDetails,
    hasError,
    value: genericFieldDetails,
  } = useServiceCall(service.hubFx.nature.getNatureGenericFieldTypeDetails);
  const { callService: updateNatureGenericFieldTypes, loading } =
    useServiceCall(service.hubFx.nature.updateNatureGenericFieldTypes);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateNatureGenericFieldTypes({
          id: Number(id),
          ...values,
        });
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success("Campo genérico atualizado com sucesso");

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .genericFields.path,
        );
      }),
    [handleSubmit, updateNatureGenericFieldTypes, id, navigate],
  );

  useEffect(() => {
    getNatureGenericFieldTypeDetails(id);
  }, [getNatureGenericFieldTypeDetails, id]);

  useEffect(() => {
    genericFieldDetails &&
      reset({
        ...genericFieldDetails,
        fxPendencyCodes: parseFxPendencyCodes(
          genericFieldDetails.fxPendencyCodes,
        ),
      });
  }, [genericFieldDetails, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar Campo Genérico" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          dataAction="adicionar"
          dataLabel="adicionar_campo_generico:botao:adicionar"
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={() => getNatureGenericFieldTypeDetails(id)}
          />
        }
        value={genericFieldDetails}
        render={() => (
          <FormProvider {...form}>
            <ExchangeGenericFieldsForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
