enum LoanDomainExceptions {
  JsonObjectWithErrors = "JsonObjectWithErrors",
  SystemNotConfigured = "SystemNotConfigured",
  LoanNotFound = "LoanNotFound",
  LoanHasContracted = "LoanHasContracted",
  LoanExpired = "LoanExpired",
  LoanRateHigherThanAllowed = "LoanRateHigherThanAllowed",
  LoanRateLowerThanAllowed = "LoanRateLowerThanAllowed",
  LoanTermHigherThanAllowed = "LoanTermHigherThanAllowed",
  LoanTermLowerThanAllowed = "LoanTermLowerThanAllowed",
  LimitAmountHigherThanAllowed = "LimitAmountHigherThanAllowed",
  LoanAmountLowerThanAllowed = "LoanAmountLowerThanAllowed",
  LoanGracePeriodHigherThanAllowed = "LoanGracePeriodHigherThanAllowed",
  BrokerDisbursementUpdateNotAllowed = "BrokerDisbursementUpdateNotAllowed",
  BankAccountInvalid = "BankAccountInvalid",
  BankAccountNotFound = "BankAccountNotFound",
  ContractNotFound = "ContractNotFound",
  ContractUriNotFound = "ContractUriNotFound",
  ContractTemplateNotImplemented = "ContractTemplateNotImplemented",
  ContractInvalid = "ContractInvalid",
  ContractCanNotExpire = "ContractCanNotExpire",
  ContractExpired = "ContractExpired",
  CounterpartyNotFound = "CounterpartyNotFound",
  CounterpartyNotReady = "CounterpartyNotReady",
  CounterpartyMissingGuarantee = "CounterpartyMissingGuarantee",
  BrokerNotFound = "BrokerNotFound",
  CompanyAlreadOperationContracted = "CompanyAlreadOperationContracted",
  CompanyNotReady = "CompanyNotReady",
  GuarantorMissingIntervener = "GuarantorMissingIntervener",
  ChannelNotFound = "ChannelNotFound",
  AgreementInvalid = "AgreementInvalid",
  AgreementNotFound = "AgreementNotFound",
  AgreementForChannelCompanyAlreadyExists = "AgreementForChannelCompanyAlreadyExists",
  PartnerChannelAgreementInvalid = "PartnerChannelAgreementInvalid",
  FileInvalid = "FileInvalid",
  FileSourceNotConfigured = "FileSourceNotConfigured",
  SettlementDayInvalid = "SettlementDayInvalid",
  InstallmentCodeInvalid = "InstallmentCodeInvalid",
  LimitOriginNotImplemented = "LimitOriginNotImplemented",
  ShippingNotFound = "ShippingNotFound",
  EmailTemplateNotImplemented = "EmailTemplateNotImplemented",
  EmailSubjectNotImplemented = "EmailSubjectNotImplemented",
  BellAttributesNotImplemented = "BellAttributesNotImplemented",
  RequestInvalid = "RequestInvalid",
}

const ExceptionToMessage: { [key in LoanDomainExceptions]: string } = {
  JsonObjectWithErrors: "Objeto JSON com erros.",
  SystemNotConfigured: "Sistema parceiro não configurado.",
  LoanNotFound: "Empréstimo não encontrado.",
  LoanHasContracted: "Empréstimo já contratado pela contraparte.",
  LoanExpired: "Empréstimo Expirado.",
  LoanRateHigherThanAllowed: "Taxa do empréstimo maior que a permitida.",
  LoanRateLowerThanAllowed: "Taxa do empréstimo menor que a permitida.",
  LoanTermHigherThanAllowed: "Termo do empréstimo maior que o permitido.",
  LoanTermLowerThanAllowed: "Termo do empréstimo menor que o permitido.",
  LimitAmountHigherThanAllowed: "Montante do empréstimo maior que o permitido.",
  LoanAmountLowerThanAllowed: "Montante do empréstimo menor que o permitido.",
  LoanGracePeriodHigherThanAllowed:
    "Período de Carência do empréstimo maior que o permitido.",
  BrokerDisbursementUpdateNotAllowed:
    "Não é permitido atualizar o Broker do desembolso.",
  BankAccountInvalid: "Conta bancária inválida.",
  BankAccountNotFound: "Conta bancária não encontrada.",
  ContractNotFound: "Contrato não encontrado.",
  ContractUriNotFound: "URL do Contrato não encontrada.",
  ContractTemplateNotImplemented: "Template do Contrato não implementado.",
  ContractInvalid: "Contrato inválido.",
  ContractCanNotExpire: "O Contrato não pode expirar.",
  ContractExpired: "Contrato expirado.",
  CounterpartyNotFound: "Contraparte não encontrada no LESS.",
  CounterpartyNotReady:
    "A Contraparte não está pronta para realizar empréstimos.",
  CounterpartyMissingGuarantee: "A Garantia da Contraparte está faltante.",
  BrokerNotFound: "Broker não encontrado.",
  CompanyAlreadOperationContracted:
    "Empresa já tem operação de empréstimo contratada.",
  CompanyNotReady: "A Empresa não está pronta para realizar empréstimos.",
  GuarantorMissingIntervener: "Garantidor não possui interventor.",
  ChannelNotFound: "Canal não encontrado.",
  AgreementInvalid: "Acordo inválido.",
  AgreementNotFound: "Acordo não encontrado.",
  AgreementForChannelCompanyAlreadyExists:
    "Acordo para canal/empresa já existe.",
  PartnerChannelAgreementInvalid: "Relação parceiro-canal-acordo inválida.",
  FileInvalid: "Arquivo inválido.",
  FileSourceNotConfigured:
    "Origem do arquivo não configurada. (Deve ser S3 ou Worksite).",
  SettlementDayInvalid: "Data de vencimento inválida.",
  InstallmentCodeInvalid: "Parcela inválida.",
  LimitOriginNotImplemented: "Origem financeira não implementada.",
  ShippingNotFound: "Remessa não encontrada.",
  EmailTemplateNotImplemented: "Template de Email não implementado.",
  EmailSubjectNotImplemented: "Assunto do Email não implementado.",
  BellAttributesNotImplemented:
    "Atributos da notificação de sino não implementados.",
  RequestInvalid: "Requisição inválida.",
};

export const parseLogErrorMessage = (message: string | undefined) => {
  if (message === undefined) return "";
  const exception = message
    .split("-")[0]
    .split(":")[1]
    .trim() as LoanDomainExceptions;
  return (
    ExceptionToMessage[exception] ?? "Erro não mapeado. Checar log abaixo."
  );
};
