import { service } from "services";
import { MonitorCompany, MonitorType } from "./company-monitor.types";

const msInDay = 24 * 60 * 60 * 1000;

export const calculateDaysInOnboarding = (company: MonitorCompany) => {
  if (
    !company.onboardingStatus ||
    !company.onboardingUpdatedAt ||
    !["CONCLUIDO", "AUTORIZADO", "REPROVADO"].includes(company.onboardingStatus)
  )
    return "-";

  return Math.floor(
    (new Date().getTime() - new Date(company.onboardingUpdatedAt).getTime()) /
      msInDay,
  ).toString();
};

export const serviceMap = {
  ACCOUNT_PF: service.clerk.odata.companies.getAccountPfSelf,
  ACCOUNT_PJ: service.clerk.odata.companies.getAccountPjSelf,
  ASSIGNOR: service.clerk.odata.companies.getAssignorSelf,
  ENERGY: service.clerk.odata.companies.getEnergySelf,
  PAYER: service.clerk.odata.companies.getPayerSelf,
  REVIEW_ACCOUNT: service.clerk.odata.companies.getReviewWorkflowSelf,
  OTHER: service.clerk.odata.companies.getNoOnboardingSelf,
  CLIENT_DESK: service.clerk.odata.companies.getClientDeskSelf,
  DATA_UPDATE: service.clerk.odata.companies. geDataUpdateWorkflowSelf,
} satisfies Record<MonitorType, unknown>;
