import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useGridActions = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.onboardingCoreAccount.cancelClosing,
  );

  const { callService: callServiceFile, loading: loadingFile } = useServiceCall(
    service.onboardingCoreAccount.generateDeclaration,
  );

  const { callService: callDownloadFile, loading: loadingDownloadFile } =
    useServiceCall(service.onboardingCoreAccount.downloadDeclaration);

  const cancelClosing = useCallback(
    async (workflowUuid: string) => {
      const { success } = await callService({
        companyTaxId: identification,
        workflowUuid,
      });

      if (success)
        OToastManager.success("Encerramento de conta cancelado com sucesso!");
      else OToastManager.danger("Erro ao cancelar encerramento da conta");
    },
    [callService, identification],
  );

  const generateFile = useCallback(
    async (id: number | undefined, workflowUuid: string | undefined) => {
      try {
        const { success, response } = await callServiceFile({
          workflowUuid,
          id,
        });

        if (success) {
          helpers.downloadBlobFile("carta-de-encerramento.pdf", response?.data);
          OToastManager.success("Sucesso ao gerar novo arquivo");
        } else OToastManager.danger("Falha ao gerar novo arquivo");
      } catch {
        OToastManager.danger("Falha ao gerar novo arquivo");
      }
    },
    [callServiceFile],
  );

  const downloadFile = useCallback(
    async (key: string) => {
      try {
        const { success, response } = await callDownloadFile({
          key,
        });

        if (success) {
          helpers.downloadBlobFile("carta-de-encerramento.pdf", response?.data);
          OToastManager.success("Sucesso ao gerar novo arquivo");
        } else OToastManager.danger("Falha ao gerar novo arquivo");
      } catch {
        OToastManager.danger("Falha ao gerar novo arquivo");
      }
    },
    [callDownloadFile],
  );

  return {
    loading,
    cancelClosing,
    loadingFile,
    generateFile,
    loadingDownloadFile,
    downloadFile,
  };
};
