import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DomainScfForm } from "../_compose/domain-scf-form";
import { useEditPartnerConfigurationScf } from "./edit-partner-configuration-scf.hook";

export const EditPartnerConfigurationScf = () => {
  const { form, loading } = useEditPartnerConfigurationScf();

  return (
    <>
      <PageTitle title="Informações do domínio" />

      <FormProvider {...form}>
        <DomainScfForm isEditing loading={loading} />
      </FormProvider>
    </>
  );
};
