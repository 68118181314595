import { OIcon, OLoader, OTab, OTabHeader, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { client } from "../../../../../../services/client";
import { logger } from "../../../../../../utils/logger";
import { endpoints } from "../../utils/endpoints";
import { NoDataFound, RowSpacedContainer } from "../cards.styles";
import {
  AccountModality,
  IOffers,
  MapperAccountModality,
} from "../cards.types";
import { InfoPad } from "../compose/info-pad/info-pad.component";
import { capitalizeFirstLetter } from "../utils/cases";
import { ICreditOffersProps } from "./credit-offers.types";

export const CreditOffers = ({
  selectedCompany,
  renderDollarPrice,
}: ICreditOffersProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [offersData, setOffersData] = useState<IOffers>();

  const loadOffers = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!selectedCompany) return;
      const { data } = await client.get(endpoints.bankinghub.getOffers, {
        headers: {
          "x-identification": selectedCompany,
          channel: "ADMIN",
        },
      });
      if (data) {
        setOffersData(data);
      }
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCompany]);

  useEffect(() => {
    loadOffers();
  }, [loadOffers]);

  const { activeModality, activeCreditVariant } = useMemo(() => {
    const program = offersData?.programs[tabIndex];
    return {
      activeModality: program?.modality,
      activeCreditVariant: program?.creditVariant,
    };
  }, [offersData, tabIndex]);

  return (
    <div>
      {!isLoading ? (
        offersData ? (
          <div className="d-flex flex-column gap-3">
            <RowSpacedContainer>
              <OTypography weight="bold">Ofertas</OTypography>
              {renderDollarPrice(loadOffers)}
            </RowSpacedContainer>
            <OTabHeader key={tabIndex} activeTab={tabIndex}>
              {offersData?.programs.map((data, index) => {
                const modality = offersData?.programs[index]?.modality;
                const creditVariant =
                  offersData?.programs[index]?.creditVariant;
                return (
                  <OTab
                    dataAction={`oferta-cartoes:tab:oferta-cartao-${data?.id}`}
                    dataLabel={`oferta-cartao-${data?.id}`}
                    key={`menu-item-CreditOffers-${index}`}
                    handleClick={() => setTabIndex(index)}
                  >
                    {modality !== AccountModality.Debit
                      ? capitalizeFirstLetter(creditVariant)
                      : MapperAccountModality[modality]}
                  </OTab>
                );
              })}
            </OTabHeader>
            {activeModality && activeCreditVariant && (
              <div className="d-flex flex-row flex-wrap gap-2">
                <InfoPad
                  label={"Modalidade ofertada"}
                  infos={[MapperAccountModality[activeModality]]}
                  icon={{
                    category: "orq",
                    icon: "orq-proposal",
                  }}
                />
                <InfoPad
                  label={"Status"}
                  infos={
                    activeModality !== AccountModality.Debit
                      ? [
                          `Em processo de contratação: ${
                            offersData.isOnHiringProcess
                              ? "Completo"
                              : "Pendente"
                          }`,
                          `Cadastro de devedores solidários: ${
                            offersData.isGuarantorsOnboarded
                              ? "Completo"
                              : "Pendente"
                          }`,
                          `Contrato assinado: ${
                            offersData.isContractSigned
                              ? "Completo"
                              : "Pendente"
                          }`,
                        ]
                      : [
                          `Iniciou o processo de contratação: ${
                            offersData.isOnDebitHiringProcess ||
                            offersData.isOnHiringProcess
                              ? "Sim"
                              : "Não"
                          }`,
                        ]
                  }
                  icon={{
                    category: "orq",
                    icon: "orq-proposal",
                  }}
                />
                {activeModality !== AccountModality.Debit && (
                  <>
                    <InfoPad
                      label={"Análise de Crédito"}
                      infos={[
                        "Disponível",
                        masks.currency(offersData?.grantedLimit, "R$"),
                      ]}
                      icon={{
                        category: "orq",
                        icon: "orq-tools-analysis",
                      }}
                    />
                    <InfoPad
                      label={"Variante Ofertada"}
                      infos={[capitalizeFirstLetter(activeCreditVariant)]}
                      icon={{
                        category: "orq",
                        icon: "orq-benefits",
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <NoDataFound className="gap-2">
            <OIcon category="fal" icon="fa-info-circle" /> Nenhuma oferta ou
            conta encontrada.
          </NoDataFound>
        )
      ) : (
        <OLoader absolute backdrop />
      )}
    </div>
  );
};
