import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { SignatureRule } from "../../customer-signatures.types";
import { SignatureRuleForm } from "./signature-rule-configs.types";

export const useSignatureRuleConfigs = (signatureRule: SignatureRule) => {
  const { callService, loading } = useServiceCall(
    service.paperclipv2.editSignatureRule,
  );

  const form = useForm<SignatureRuleForm>();

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await callService(signatureRule.id, {
          contractType: signatureRule.contractType,
          groupOfSignatures: signatureRule.groupOfSignatures,
          minimumValue: values.minimumValue,
          maximumValue: values.maximumValue,
          startingDateOfPermission: values.startingDateOfPermission,
          endingDateOfPermission: values.endingDateOfPermission,
        });
        if (success) OToastManager.success("Configurações salvas com sucesso");
        else OToastManager.danger("Erro ao salvar as configurações");
      }),
    [callService, handleSubmit, signatureRule],
  );

  useEffect(() => {
    if (signatureRule) {
      reset({
        minimumValue: signatureRule.minimumValue,
        maximumValue: signatureRule.maximumValue,
        startingDateOfPermission: signatureRule.startingDateOfPermission,
        endingDateOfPermission: signatureRule.endingDateOfPermission,
      });
    }
  }, [reset, signatureRule]);

  return {
    form,
    loading,
    submit,
  };
};
