import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { transactionTypeMap } from "./statement.utils";

export const statementGrid: ODataGridGeneratorConfig<BankingHub.Movement> = {
  datagrid: {
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "dateHour",
      dataType: "date",
      format: "shortDate",
      caption: "Data",
      allowHeaderFiltering: false,
    },
    {
      dataField: "type",
      caption: "Operação",
      calculateCellValue: ({ type }) =>
        getValueFromMap(transactionTypeMap, type) || type,
    },
    {
      dataField: "description",
      caption: "Detalhes",
      allowHeaderFiltering: false,
    },
    {
      dataField: "amount",
      caption: "Movimentação",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
  ],
};
