import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { OInputDate } from "@maestro/react";
import { CardTemplate } from "templates/card-template";
import dayjs from "dayjs";
import { useState } from "react";
import { BankingClienteContasByAccountByAgencyRouteParams } from "../../../../routes/banking.route-params";
import { fullYieldStatementGrid } from "./remunera-mais.grid";

export interface FullYieldStatementModel {
  initialAppliedBalance: number;
  totalApplications: number;
  totalRedemption: number;
  totalYields: number;
  monthlyYields: number;
  netIncome: number;
  netIncomeTransactionTax: number;
  netIncomeTax: number;
  provisionForIncome: number;
  finalAppliedBalance: number;
}

export const fullYieldStatementLabels: {
  [key in keyof Omit<FullYieldStatementModel, "provisionForIncome">]: string;
} = {
  initialAppliedBalance: "Saldo Inicial Aplicado",
  totalApplications: "Total de Aplicações",
  totalRedemption: "Total de Resgates",
  totalYields: "Total de Rendimentos",
  monthlyYields: "Rendimentos Disponíveis",
  netIncome: "Rendimentos Resgatados",
  netIncomeTransactionTax: "IOF",
  netIncomeTax: "IR",
  finalAppliedBalance: "Saldo Final Aplicado",
};

const getBalanceYield = async (
  account: string,
  agency: string,
  selectedDate: string,
) => {
  try {
    const balanceYieldResponse = await service.bankinghub.getBalanceYieldStatus(
      account,
      Number(agency),
    );
    const { balanceYieldId } = balanceYieldResponse.data;
    const fullYieldStatementResponse =
      await service.bankinghub.getStatementFullYield(balanceYieldId, {
        referenceStartDate: selectedDate,
        referenceEndDate: selectedDate,
      });
    const result = fullYieldStatementResponse.data;

    return result;
  } catch (error) {
    if (!isAxiosError(error)) logger.error(error);
    const errorMessage = "Erro ao buscar o extrato";
    OToastManager.danger(errorMessage);
    throw new Error(errorMessage);
  }
};

export const BalanceYieldStatement = () => {
  const { account, agency } =
    useParams<BankingClienteContasByAccountByAgencyRouteParams>();
  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs().format("YYYY-MM"),
  );

  if (!account || !agency) throw new Error("Invalid route params");

  const gridRef = useGridRef();

  const dataSource = dataSourceCustomStoreGenerator<BankingHub.Movement>(
    async () => {
      const fullYieldStatement: FullYieldStatementModel = await getBalanceYield(
        account,
        agency,
        selectedDate,
      );
      const result = (
        Object.keys(fullYieldStatementLabels) as Array<
          keyof Omit<FullYieldStatementModel, "provisionForIncome">
        >
      ).map((key) => ({
        label: fullYieldStatementLabels[key],
        amount: fullYieldStatement[key],
      }));

      return result;
    },
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Extrato Mensal Consolidado Remunera+" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <OInputDate
        className="mt-2 w-40 mb-3"
        data-filterparam="referenceDate"
        allowClear
        aspect="flushed"
        picker="month"
        placeholder="Selecione o mês"
        size="md"
        value={dayjs().format("YYYY-MM")}
        disabledDate={(dt) => {
          const isoDate = new Date(dt.format("YYYY-MM"));
          const today = new Date();

          return isoDate >= today;
        }}
        onChange={(event) => {
          setSelectedDate(event?.format("YYYY-MM") || "");
        }}
      />
      <CardTemplate>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={fullYieldStatementGrid}
          dataSource={dataSource}
        />
      </CardTemplate>
    </GridTemplate>
  );
};
