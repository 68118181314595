import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { usePartnerFields } from "./partner-fields.hook";

interface PartnerFieldsProps {
  isEditing: boolean;
}

export const PartnerFields = ({ isEditing }: PartnerFieldsProps) => {
  const { loading, partnersList } = usePartnerFields();

  return (
    <ORow>
      <OCol xs={4}>
        <ORFieldSelect
          dataAction="partner_configuration_scf:select:partner_name"
          dataLabel="partner_name"
          id="partnerName"
          name="partnerName"
          disabled={loading || isEditing}
          label="Nome parceiro"
        >
          {partnersList &&
            partnersList.map((partner) => (
              <OOption key={partner.authClientId} value={partner.name}>
                {partner.name}
              </OOption>
            ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          dataAction="partner_configuration_scf:checkbox:partner_id"
          dataLabel="partner_id"
          id="partnerId"
          name="partnerId"
          disabled={isEditing}
          label="Id do parceiro"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          dataAction="partner_configuration_scf:texto:auth_client_id"
          dataLabel="auth_client_id"
          tag="text"
          id="authClientId"
          name="authClientId"
          label="ClientId do parceiro"
          disabled
        />
      </OCol>
    </ORow>
  );
};
