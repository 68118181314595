import {
  modalManager,
  OBadge,
  OCard,
  OCardBody,
  ODropdownAction,
  ODropdownActionItem,
  OIcon,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useRef } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../components/hooks/use-workflow-form-template.context";
import { DraggableComponents } from "../draggable-components/draggable-components.component";

export const DraggableCards = () => {
  const { show } = modalManager;
  const {
    form: { value: workflowFormTemplate, callService: getForm },
    selectedStep,
    setSelectedCard,
  } = useWorkflowFormTemplate();

  const dragItem = useRef<number>(0);
  const dragOverItem = useRef<number>(0);

  const updateCardPositions = useCallback(async () => {
    if (!selectedStep) return;

    let cardsListAux = selectedStep.cards.map((c) => ({ ...c })); // .splice() e [...array] nao funcionam para array em um state
    const dragItemContent = cardsListAux[dragItem.current];

    cardsListAux.splice(dragItem.current, 1);
    cardsListAux.splice(dragOverItem.current, 0, dragItemContent);
    cardsListAux = cardsListAux.map((step, index) => ({
      ...step,
      position: index,
    }));

    if (
      cardsListAux[dragItem.current]?.id ===
      selectedStep.cards[dragItem.current]?.id
    )
      return;

    try {
      await service.hubCreditManager.updateFormStepCardPositions({
        formStepId: selectedStep.id,
        positions: cardsListAux.map((cl) => ({
          formStepCardId: cl.id,
          position: cl.position,
        })),
      });

      workflowFormTemplate?.id && getForm(workflowFormTemplate.id);
    } catch {
      OToastManager.danger("Não foi possível atualizar posições dos cards");
    }
  }, [workflowFormTemplate, selectedStep, getForm]);

  return (
    <div className="d-flex flex-column gap-1">
      {!selectedStep?.cards?.length && (
        <OTypography className="mt-4">
          Este passo ainda não possui cards
        </OTypography>
      )}

      {selectedStep?.cards.map((card, index) => {
        return (
          <OCard
            onDragStart={() => {
              dragItem.current = index;
            }}
            onDragEnter={() => {
              dragOverItem.current = index;
            }}
            onDragEnd={updateCardPositions}
            draggable
            key={card.id}
          >
            <OCardBody>
              <div className="d-flex flex-column gap-5">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2 align-items-center">
                      <OTypography
                        tag="h4"
                        weight="normal"
                        size="lg"
                        type="dark"
                        key={card?.title ? card?.title : "Card sem título"}
                      >
                        {card?.title ? card?.title : "Card sem título"}
                      </OTypography>

                      {card.toggleable && (
                        <OBadge type="info" size="xs">
                          Colapsável
                        </OBadge>
                      )}
                    </div>

                    {card?.description && (
                      <OTypography type="dark-60" key={card.description}>
                        {card.description}
                      </OTypography>
                    )}
                  </div>
                  <ODropdownAction>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedCard(card);
                        show("submit-card-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-pencil"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Editar</OTypography>
                    </ODropdownActionItem>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedCard(card);
                        show("add-component-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-add-circle"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">
                        Adicionar componente
                      </OTypography>
                    </ODropdownActionItem>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedCard(card);
                        show("remove-card-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-trash"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Remover</OTypography>
                    </ODropdownActionItem>
                  </ODropdownAction>
                </div>

                <DraggableComponents card={card} />
              </div>
            </OCardBody>
          </OCard>
        );
      })}
    </div>
  );
};
