import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { AddressForm } from "../../address.form";

export const useCitySelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.onboarding.getCities,
  );

  const { clearErrors, setError, watch } = useFormContext<AddressForm>();

  const stateWatcher = watch("address.state");

  const getCities = useCallback(async () => {
    if (!stateWatcher) return;
    const { success } = await callService({
      countryCode: "BR",
      stateCode: stateWatcher,
    });
    if (success) clearErrors("address.city");
    else
      setError("address.city", {
        message:
          "Erro ao buscar as cidades. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError, stateWatcher]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  const cities = useMemo(
    () =>
      value?.map<SelectSearchMultipleOption<string>>((city) => ({
        customKey: city.cityCode,
        label: city.cityName,
        value: city.cityName?.toUpperCase(),
      })) ?? [],
    [value],
  );

  return {
    cities,
    getCities,
    loading,
    hasFetchError: hasError,
    stateWatcher,
  };
};
