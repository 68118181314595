import {
  OButton,
  OConfirmationModal,
  OFieldError,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
  modalManager,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { AddFields, CriteriaFields } from "./_compose";
import { useEditEligibilityGroupModal } from "./edit-eligibility-group-modal.hook";
import {
  editEligibilityGroupModalId,
  pendingChangesConfirmationModalId,
  removeGroupConfirmationModalId,
} from "./edit-eligibility-group-modal.types";

export const EditEligibilityGroupModal = () => {
  const { fieldArray, form, removeGroup, saveGroup } =
    useEditEligibilityGroupModal();

  const {
    watch,
    formState: {
      isDirty,
      errors: { criteria },
    },
  } = form;

  const groupNameWatcher = watch("name");

  const { fields } = fieldArray;

  return (
    <>
      <OModal id={editEligibilityGroupModalId} backdrop={false}>
        <OModalHeader>
          <OTypography>Editar grupo de elegibilidade</OTypography>
        </OModalHeader>
        <OModalBody>
          <FormProvider {...form}>
            <ORFieldInput
              dataAction="editar_grupo:texto:nome"
              dataLabel="nome"
              id="name"
              name="name"
              tag="text"
              label="Nome do grupo"
              required
            />
            <OTypography>Critérios</OTypography>
            {!!criteria?.message && (
              <OFieldError visible>{criteria.message}</OFieldError>
            )}
            {fields.map((field, index) => (
              <CriteriaFields
                key={field.id}
                index={index}
                fieldArray={fieldArray}
                visible={field.active}
              />
            ))}
            <AddFields fieldArray={fieldArray} />
          </FormProvider>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-between">
            <OButton
              dataAction="editar_grupo:botao:fechar"
              dataLabel="fechar"
              onClick={() =>
                isDirty
                  ? modalManager.show(pendingChangesConfirmationModalId)
                  : modalManager.hide(editEligibilityGroupModalId)
              }
              type="dark"
              outline
            >
              Fechar
            </OButton>
            <div className="d-flex gap-2">
              <OButton
                dataAction="editar_grupo:botao:apagar"
                dataLabel="apagar"
                onClick={() =>
                  modalManager.show(removeGroupConfirmationModalId)
                }
                type="danger"
              >
                Apagar grupo
              </OButton>

              <OButton
                dataAction="editar_grupo:botao:salvar"
                dataLabel="salvar"
                onClick={() => saveGroup()}
              >
                Salvar
              </OButton>
            </div>
          </div>
        </OModalFooter>
      </OModal>
      <OConfirmationModal
        modalId={removeGroupConfirmationModalId}
        title={`Remover "${groupNameWatcher}"?`}
        confirmLabel="Sim"
        closeLabel="Não"
        onConfirm={() => {
          removeGroup();
          modalManager.hide(removeGroupConfirmationModalId);
        }}
        key={groupNameWatcher}
      >
        <OTypography key={groupNameWatcher}>
          Você tem certeza que deseja remover o grupo &quot;{groupNameWatcher}
          &quot;?
        </OTypography>
      </OConfirmationModal>
      <OConfirmationModal
        modalId={pendingChangesConfirmationModalId}
        title="Deseja fechar sem salvar?"
        confirmLabel="Descartar alterações"
        closeLabel="Voltar à edição"
        onConfirm={() => {
          modalManager.hide(editEligibilityGroupModalId);
          modalManager.hide(pendingChangesConfirmationModalId);
        }}
      >
        <OTypography>
          Existem mudanças que não foram salvas. Se você sair agora, elas serão
          perdidas. Tem certeza que deseja sair e descartar as mudanças não
          salvas?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
