import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { distributorBasicDataFormFields } from "./distributor-basic-data.form";

interface DistributorBasicDataFieldsProps {
  disableTaxId?: boolean;
}

export const DistributorBasicDataFields = ({
  disableTaxId,
}: DistributorBasicDataFieldsProps) => {
  return (
    <>
      <ORow gap={3}>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...distributorBasicDataFormFields.taxId}
            disabled={disableTaxId}
          />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} md={6}>
          <ORFieldInput {...distributorBasicDataFormFields.officialName} />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput {...distributorBasicDataFormFields.name} />
        </OCol>
      </ORow>
    </>
  );
};
