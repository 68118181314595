import {
    OButton,
    OModal,
    OModalBody,
    OModalFooter,
    OModalHeader,
    ORFieldInput,
    OTypography,
    modalManager,
  } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { useAppReject } from "./rejeitar-app-modal.hook";
  interface AddAppRejectModalProps {
    name: string;
    appId: string;
    version: number | string;
    tenantId: string;
    sucessCallback: () => void;
  }  
export const buildModalIdReject = (appId: string) =>
    `developers-modal-reject-${appId}`
export const AppRejectModal = ({appId, name, version, tenantId, sucessCallback}:AddAppRejectModalProps) =>{
    
    const modalId = buildModalIdReject(appId);
    const { form, loading, submit } = useAppReject(
        modalId,
        appId,
        version,
        tenantId,
        sucessCallback,
      );
     return(
    <OModal id={modalId} position="center" backdrop>
        <OModalHeader>
            <OTypography size="lg">Aprovar</OTypography>
        </OModalHeader>
        <OModalBody>
            <div>
                <OTypography>Deseja recusar o aplicativo {name}?</OTypography>
                <FormProvider {...form}>
                    <ORFieldInput
                        id="statusObservation"
                        name="statusObservation"
                        tag="text"
                        label="Envie uma mensagem sobre o status da verificação."
                        placeholder="Recusado"
                        disabled={loading}
                    />
                </FormProvider>
            </div>
        </OModalBody>
        <OModalFooter>
            <div className="d-flex justify-content-end gap-3">
                <OButton
                onClick={() => submit()}>
                    Recusar
                </OButton>
                <OButton
                onClick={()=>modalManager.hide(modalId)}>
                    Cancelar
                </OButton>
            </div>
        </OModalFooter>
    </OModal>
  );
     };