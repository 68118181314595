import { OButton, modalManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { useOfferGuarantorsContext } from "../../offer-guarantors.context";
import { OfferGuarantorsList } from "./offer-guarantors-list/offer-guarantors-list.component";
import {
  OfferGuarantorExplanationModal,
  OfferGuarantorExplanationModalId,
} from "./offer-guarantors-explanation-modal/offer-guarantors-explanation-modal.component";

export const OnboardingDetails = () => {
  const { fetch, fetchLoading, fetchHasError } = useOfferGuarantorsContext();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Responsáveis pelo cartão"
          description="Todos os responsáveis precisam estar com o cadastro completo e aprovar o pedido do cartão."
        />
      }
      actions={
        <OButton
          type="info"
          outline
          bordered={false}
          onClick={() => modalManager.show(OfferGuarantorExplanationModalId)}
        >
          Quem são os responsáveis pelo cartão?
        </OButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <DefaultErrorComponent
              title="Erro ao carregar os dados"
              callback={fetch}
            />
          }
          noValue
          render={() => (
            <div>
              <OfferGuarantorExplanationModal />
              <OfferGuarantorsList />
            </div>
          )}
        />
      </CardTemplate>
    </DetailsTemplate>
  );
};
