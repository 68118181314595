import { OCheckbox } from "@maestro/react";
import { useCallback, useMemo } from "react";
import { useOperationsInstallments } from "../operations-installments.context";
import { SharkInstallmentItem } from "../operations-installments.types";
import { getAgreementPrepaymentType } from "../operations-installments.utils";

interface AnticipationCheckboxProps {
  installment: SharkInstallmentItem;
}

export const AnticipationCheckbox = ({
  installment,
}: AnticipationCheckboxProps) => {
  const {
    checkedInstallmentsMap,
    installmentsMap,
    setCheckedInstallmentsMap,
    sharkSettlement,
  } = useOperationsInstallments();

  const isDisabled = useMemo(() => {
    const installmentsForFrequency =
      installmentsMap[installment.amortization_frequency] ?? [];

    if (!installmentsForFrequency.length || !sharkSettlement) return true;

    // Caso que é a primeira parcela (sempre habilitada)
    if (installmentsForFrequency[0] === installment.installment_number)
      return false;

    const agreementPrepaymentType = getAgreementPrepaymentType(
      sharkSettlement,
      installment.amortization_frequency,
      installment.index_code,
    );

    const prepaymentOrder =
      agreementPrepaymentType?.prepayments_config.prepayment_allowed_order;

    // Outros casos
    let start = 0;
    let end = installmentsForFrequency.length - 1;

    while (start <= end) {
      const middle = Math.floor((start + end) / 2);

      if (installmentsForFrequency[middle] === installment.installment_number) {
        return !checkedInstallmentsMap[installment.amortization_frequency]?.has(
          installmentsForFrequency[middle - 1],
        );
      }
      if (installmentsForFrequency[middle] < installment.installment_number) {
        if (prepaymentOrder === "ANY" || prepaymentOrder === "ASC") {
          start = middle + 1;
        } else {
          end = middle - 1;
        }
      } else if (
        installmentsForFrequency[middle] > installment.installment_number
      ) {
        if (prepaymentOrder === "ANY" || prepaymentOrder === "ASC") {
          end = middle - 1;
        } else {
          start = middle + 1;
        }
      }
    }

    return true;
  }, [sharkSettlement, checkedInstallmentsMap, installment, installmentsMap]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLOCheckboxElement, MouseEvent>) => {
      const target = e.target as HTMLOCheckboxElement;
      const amortizationFrequency = installment.amortization_frequency;

      if (target.checked) {
        checkedInstallmentsMap[amortizationFrequency].add(
          installment.installment_number,
        );
      } else {
        const index = installmentsMap[amortizationFrequency].findIndex(
          (i) => i === installment.installment_number,
        );

        checkedInstallmentsMap[amortizationFrequency] = new Set<number>(
          installmentsMap[amortizationFrequency].slice(0, index),
        );
      }

      setCheckedInstallmentsMap({ ...checkedInstallmentsMap });
    },
    [
      checkedInstallmentsMap,
      installment,
      installmentsMap,
      setCheckedInstallmentsMap,
    ],
  );

  return (
    <OCheckbox
      id={`installment-${installment.installment_number}`}
      value={installment.installment_number.toString()}
      size="xs"
      checked={checkedInstallmentsMap[installment.amortization_frequency]?.has(
        installment.installment_number,
      )}
      onClick={onClick}
      disabled={isDisabled}
    />
  );
};
