import { modalManager, OButton, OConfirmationModal } from "@maestro/react";
import React, { useId } from "react";
import { logger } from "utils/logger";

interface ButtonWithConfirmationProps
  extends React.ComponentProps<typeof OButton> {
  children: React.ReactNode;
  confirmationModal: Omit<
    React.ComponentProps<typeof OConfirmationModal>,
    "modalId"
  >;
  modalBody?: React.ReactNode;
  onConfirm: () => unknown | Promise<unknown>;
}

export const ButtonWithConfirmation = ({
  children,
  confirmationModal: modalProps,
  modalBody,
  onConfirm,
  ...buttonProps
}: ButtonWithConfirmationProps) => {
  const modalId = useId();
  const handleConfirm = async () => {
    try {
      await onConfirm();
      modalManager.hide(modalId);
    } catch {
      logger.debug("Confirmation modal error");
    }
  };

  return (
    <div className="d-flex flex-column">
      <OButton {...buttonProps} onClick={() => modalManager.show(modalId)}>
        {children}
      </OButton>
      <OConfirmationModal
        size="sm"
        {...modalProps}
        modalId={modalId}
        onConfirm={handleConfirm}
      >
        {modalBody}
      </OConfirmationModal>
    </div>
  );
};
