import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByDocumentTypeIdRouteParams } from "../../../../../routes/energia.route-params";
import { checkboxValue, useDocumentTypeForm } from "../_compose";

export const useEditDocumentType = () => {
  const { documentTypeId } = useParams<MleByDocumentTypeIdRouteParams>();
  if (!documentTypeId) throw new Error("No documentTypeId");

  const { callService, loading, hasError, error, value } = useServiceCall(
    service.hubEnergy.getDocumentTypesById,
  );

  const {
    form,
    loading: submitLoading,
    submit,
  } = useDocumentTypeForm(Number(documentTypeId));

  const { reset } = form;

  const getDocumentType = useCallback(
    () => callService(documentTypeId),
    [callService, documentTypeId],
  );

  useEffect(() => {
    getDocumentType();
  }, [getDocumentType]);

  useEffect(() => {
    if (value?.response)
      reset({
        description: value.response.description,
        isMigrationDocument: value.response.isMigrationDocument
          ? [checkboxValue]
          : [],
        label: value.response.label,
        name: value.response.name,
      });
  }, [reset, value]);

  return {
    error,
    form,
    getDocumentType,
    hasError,
    loading,
    submit,
    submitLoading,
  };
};
