import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams } from "../../../../../routes/ferramentas.route-params";
import { DeskAccountInfoForm } from "../_compose/desk-account-info-form.component";
import {
  DeskAccountInfoFormValues,
  deskAccountInfoFormValidationSchema,
} from "../_compose/desk-account-info.form";

export const UpdateDeskAccountInfoPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DeskAccountInfoFormValues>({
    resolver: yupResolver(deskAccountInfoFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskAccountInfoDetails,
    loading,
    hasError,
    value: deskAccountInfo,
  } = useServiceCall(service.onboarding.getDeskOnboardingAccountInfoDetails);
  const { callService: updateDeskAccountInfo, loading: submitLoading } =
    useServiceCall(service.onboarding.updateDeskOnboardingAccountInfo);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskAccountInfo(values);

        if (!success)
          return OToastManager.danger("Erro ao atualizar informação de conta");

        return OToastManager.success(
          "Informação de conta atualizada com sucesso",
        );
      }),
    [handleSubmit, updateDeskAccountInfo],
  );

  useEffect(() => {
    getDeskAccountInfoDetails(uuid);
  }, [getDeskAccountInfoDetails, uuid]);

  useEffect(() => {
    deskAccountInfo && reset(deskAccountInfo);
  }, [deskAccountInfo, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar informação de conta" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar mesa"
            callback={() => getDeskAccountInfoDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskAccountInfoForm editOnly />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
