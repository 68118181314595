import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { feesGrid } from "./fees-sacado.grid";
import { useFeesSacado } from "./fees-sacado.hook";

export const FeesSacado = () => {
  const gridRef = useGridRef();
  const { companyLoading, dataSource } = useFeesSacado();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Fees"
          description="Gerenciamento de taxas do sacado"
        />
      }
      actions={
        <StandardLink
          href={
            corporateRouter.routes.recebiveis.customer.feeSacado.feesRegister
              .path
          }
        >
          Adicionar
        </StandardLink>
      }
      showClearFiltersButton
      showExportButton
      showRefreshButton
      gridRef={gridRef}
    >
      <ODataGridGenerator
        grid={feesGrid}
        gridRef={gridRef}
        dataSource={dataSource}
        loading={companyLoading || !dataSource}
      />
    </GridTemplate>
  );
};
