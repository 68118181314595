import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { CardHiringFeedbackModalId } from "./card-hiring-feedback-modal.types";

export * from "./card-hiring-feedback-modal.types";

export const CardHiringFeedbackModal = () => {
  const navigate = useNavigate();
  return (
    <OConfirmationModal
      modalId={CardHiringFeedbackModalId}
      title="Pedido do cartão pendente de aprovação"
      confirmLabel="Ver cadastro dos sócios"
      closeLabel="Fechar"
      size="xs"
      onConfirm={() => {
        modalManager.hide(CardHiringFeedbackModalId);
        navigate(corporateRouter.routes.cadastro.customer.guarantors.path);
      }}
    >
      <div className="d-flex flex-column gap-1">
        <OTypography>
          O pedido do cartão foi realizado e está pendente de aprovação dos
          sócios.
        </OTypography>
        <OTypography>
          É essencial o cadastro de e-mail e telefone de sócios responsáveis
          para entrarmos em contato. Clique em Ver cadastro dos sócios e envie
          as informações necessárias para pedirmos a aprovação do cartão. Se
          possível, informe o endereço dos responsáveis.
        </OTypography>
      </div>
    </OConfirmationModal>
  );
};
