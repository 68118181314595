import { OToastManager, modalManager } from "@maestro/react";
import axios from "axios";
import { useCallback, useState } from "react";
import { service } from "services";
import { useBankslipContext } from "../../bankslip.context";
import { ArchiveBankslipModalId } from "./archive-bankslip-modal.types";

export const useArchiveBankslip = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { bankslipToArchive, refreshGrid } = useBankslipContext();

  const archiveCollection = useCallback(async () => {
    try {
      if (!bankslipToArchive?.collectionId) return;

      setIsLoading(true);
      modalManager.hide(ArchiveBankslipModalId);
      await service.adminBankinghub.archiveCollectionById(
        bankslipToArchive.collectionId,
      );
      OToastManager.success("O arquivamento do boleto está sendo processado.");
      refreshGrid();
    } catch (err) {
      const defaultErrorMessage =
        "Ocorreu um erro ao arquivar o boleto, tente novamente em alguns instantes.";

      const errorMessage = axios.isAxiosError(err)
        ? err.response?.data?.Details?.reason ?? defaultErrorMessage
        : defaultErrorMessage;

      OToastManager.danger(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [bankslipToArchive?.collectionId, refreshGrid]);

  return { isLoading, archiveCollection };
};
