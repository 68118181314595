import { ONotification, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";

export const InaptCompanyNotification = () => {
  const { customer } = useCustomer();

  const isInapt = customer.statusRfb === "INAPTA";

  return isInapt ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">Empresa inapta</OTypography>
        <OTypography type="dark">
          A empresa que você está acessando está inapta na Receita Federal e
          poderá ter problemas de elegibilidade
        </OTypography>
      </div>
    </ONotification>
  ) : null;
};
