import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { UserNotification } from "services/raven/models/types";
import { GridTemplate } from "templates/grid-template";
import { emailNotificationsGrid } from "./email-notifications.grid";
import { getCustomerEmails } from "./email-notifications.utils";

export const EmailNotifications = () => {
  const { customer } = useCustomer();
  const emails = getCustomerEmails(customer);
  const gridRef = useGridRef();

  const dataSourceGenerator = (receiverEmails: (string | string[])[]) =>
    dataSourceCustomStoreGenerator<UserNotification>(
      (loadOptions) => {
        return service.raven
          .getNotifications(loadOptions as any)
          .then(({ data }) => ({
            data: data.data ?? [],
            totalCount: data.total_count,
          }))
          .catch(() => {
            const errorMessage = "Erro ao buscar notificações";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      },
      {
        dataSourceOptions: {
          filter: [receiverEmails, "and", ["type", "=", "Email"]],
          sort: [
            {
              desc: true,
              selector: "insertedAt",
            },
          ],
        },
      },
    );

  const dataSource = useMemo(() => dataSourceGenerator(emails), [emails]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Notificações por e-mail" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={emailNotificationsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
