import { OIcon, OTooltip } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import { UnstyledButton } from "components/unstyled-button";
import { useState } from "react";

interface ClipboardButtonProps {
  content: string;
  size?: HTMLOIconElement["size"];
}

export const ClipboardButton = ({ content }: ClipboardButtonProps) => {
  const [copied, setCopied] = useState(false);

  const icon = copied ? "orq-check" : "orq-edit-copy";

  return (
    <OTooltip key={icon}>
      <TooltipContent>{copied ? "Copiado!" : "Copiar"}</TooltipContent>
      <UnstyledButton
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(content);
          setCopied(true);
        }}
        onMouseLeave={() => {
          setCopied(false);
        }}
      >
        <OIcon category="orq" type="dark" icon={icon} size="sm" />
      </UnstyledButton>
    </OTooltip>
  );
};
