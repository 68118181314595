import { ODataGridGenerator } from "components/data-grid/o-data-grid-generator.component";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button/refresh-grid-button.component";
import { PmeAccount } from "services/clerk";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./bankslip-accounts.grid";

interface BankslipAccountsProps {
  pmeAccounts: PmeAccount[] | null;
  fetchPmeAccounts: () => void;
}

export const BankslipAccounts = ({
  pmeAccounts,
  fetchPmeAccounts,
}: BankslipAccountsProps) => {
  return (
    pmeAccounts && (
      <GridTemplate
        pageTitle={<PageTitle title="Contas vinculadas" />}
        actions={<RefreshGridButton onClick={() => fetchPmeAccounts()} />}
      >
        <ODataGridGenerator grid={grid} dataSource={pmeAccounts} />
      </GridTemplate>
    )
  );
};
