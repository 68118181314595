export const monitoringFilterModalId = "monitoring-filter-modal-id";

export const buildFilter = (
  status: string,
  filter: Record<string, string>,
  initialFilter?: Record<string, string>,
) => {
  const newFilter = { ...filter, ...initialFilter };

  const filterEntries = Object.entries(newFilter);

  if (filterEntries.length === 0 && status === "TODOS") {
    return null;
  }

  const formattedFilter: (string | string[])[] = [];
  filterEntries.forEach(([key, value]) => {
    if (value) {
      formattedFilter.push(
        key.toLowerCase().includes("identification")
          ? [key, "contains", value.trim().replace(/[^\d]/g, "")]
          : [key, "contains", value.trim()],
      );
      formattedFilter.push("and");
    }
  });

  if (status !== "TODOS") {
    formattedFilter.push(["Status", "=", status]);
    formattedFilter.push("and");
  }

  formattedFilter.pop();

  if (formattedFilter.length === 0) {
    return null;
  }

  return formattedFilter;
};
