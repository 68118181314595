import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useEloaCard = () => {
  const { customer } = useCustomer();

  const {
    value: eloaInfo,
    callService: isEloaAvailable,
    error,
    loading,
  } = useServiceCall(service.clerk.isEloaAvailable);

  const getEloaInfo = useCallback(
    () => isEloaAvailable({ identification: customer.identification }),
    [customer.identification, isEloaAvailable],
  );

  useEffect(() => {
    getEloaInfo();
  }, [getEloaInfo]);

  return {
    eloaInfo,
    getEloaInfo,
    hasError: !!error,
    isNotFoundError: isAxiosError(error) && error.response?.status === 400,
    loading,
  };
};
