import { OIcon, OTypography } from "@maestro/react";

interface AlertMessagesProps {
  messages: string[];
}

export const AlertMessages = ({
  messages,
}: AlertMessagesProps): JSX.Element => (
  <div className="d-flex gap-2">
    <OIcon
      className="align-self-start mt-1 fa-inverse"
      category="orq"
      icon="orq-status-warning"
      type="warning"
    />
    <div key={messages.join()} className="d-flex flex-column gap-2">
      {messages.map((message, index) => {
        return (
          <OTypography key={`tracking-message-${index}`}>{message}</OTypography>
        );
      })}
    </div>
  </div>
);
