export interface EmailCotacaoForm {
  identification: string;
  emails: string[];
  isSacadoCnpj: boolean;
  byRootTaxId: string;
}

export const emailsOls = [
  {
    id: "ol-pme-cartoes@btgpactual.com",
    name: "OL-PME-CARTOES",
  },
  {
    id: "ol-pme-it-cartoes@btgpactual.com",
    name: "OL-PME-IT-CARTOES",
  },
];
