import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { masks, validators } from "@maestro/utils";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { ContractGeneratorFormValues } from "../contract-generator.types";

export const CustomerAndCreditLineForm = () => {
  const [loading, setLoading] = useState(false);
  const [limits, setLimits] = useState<
    Awaited<
      ReturnType<typeof service.hubCreditManager.fetchAllLimitsByTaxId>
    >["data"]
  >([]);

  const form = useFormContext<ContractGeneratorFormValues>();
  const { watch, setValue } = form;

  const taxIdWatch = watch("taxId");
  const creditLineWatch = watch("creditLine");

  const getLimits = useCallback(async (taxId: string) => {
    try {
      setLoading(true);
      const { data } = await service.hubCreditManager.fetchAllLimitsByTaxId({
        taxId,
      });

      if (!data.length)
        OToastManager.info(
          "Nenhuma linha de crédito disponível para o CPF/CNPJ informado.",
        );

      setLimits(data);
    } catch {
      OToastManager.danger(
        "Não foi possível buscar linhas de crédito para o cliente selecionado.",
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const getContractTemplates = useCallback(
    async (product: string, taxId: string) => {
      try {
        setLoading(true);
        const { data } = await service.hubLoan.getContractTemplates({
          taxId,
          product,
        });

        setValue(
          "contractTemplates",
          data.map((contract) => ({
            ...contract,
            status: "AGUARDANDO_INICIO" as const,
          })),
        );
      } catch {
        OToastManager.danger(
          "Não foi possível buscar templates de contrato para a linha de crédito selecionada",
        );
      } finally {
        setLoading(false);
      }
    },
    [setValue],
  );

  const debouncedGetLimits = useMemo(() => {
    return debounce(getLimits, 800);
  }, [getLimits]);

  useEffect(() => {
    if (!taxIdWatch || !validators.cpfCnpj(taxIdWatch)) {
      setLimits([]);
      setValue("creditLine", "");
      return;
    }

    debouncedGetLimits(taxIdWatch);
  }, [debouncedGetLimits, setValue, taxIdWatch]);

  useEffect(() => {
    return () => {
      debouncedGetLimits.cancel();
    };
  });

  useEffect(() => {
    if (!creditLineWatch || !taxIdWatch) return;
    getContractTemplates(creditLineWatch, taxIdWatch);
  }, [creditLineWatch, getContractTemplates, taxIdWatch]);

  return (
    <form className="w-100">
      {loading && <OLoader absolute backdrop />}
      <ORow gap={4}>
        <OCol sm={6}>
          <ORFieldInput
            tag="cpfcnpj"
            label="CPF/CNPJ"
            dataAction="gerador_contratos:texto:cpf_cnpj"
            dataLabel="cpf_cnpf"
            name="taxId"
            id="taxId"
          />
        </OCol>

        <OCol sm={6}>
          <ORFieldSelect
            id="creditLine"
            name="creditLine"
            label="Linha de crédito"
            placeholder="Informar"
            dataAction="gerador_contratos:select:linha_de_credito"
            dataLabel="linha_de_credito"
            disabled={!limits.length}
          >
            {limits.map((limit) => (
              <OOption
                value={limit.creditLine?.identification}
                key={`${limit.creditLine?.identification}-${limit.amount}`}
              >
                {`[${dayjs(limit.currentLimitRequest?.createdDate).format(
                  "DD/MM/YYYY",
                )}] ${limit.creditLine?.identification} - ${masks.currency(
                  limit.amount,
                  "R$",
                )}`}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
      </ORow>
    </form>
  );
};
