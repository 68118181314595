import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingDomainConfigForm } from "../_compose";
import { useOnboardingDomainConfigAdd } from "./add.hook";

export const OnboardingDomainConfigAdd = () => {
  const { submit, form, submitLoading } = useOnboardingDomainConfigAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Domínio de Hubonboarding"
          description="Adicione um domínio ao hubonboarding"
        />
      }
      actions={
        <LoadingButton
          loading={submitLoading}
          dataAction="adicionar_dominio:botao:criar_dominio"
          dataLabel="criar_dominio"
          onClick={submit}
        >
          Criar Domínio
        </LoadingButton>
      }
    >
      <CardTemplate>
        <OnboardingDomainConfigForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
