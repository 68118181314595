import {
  OButton,
  OCard,
  OCardBody,
  OIcon,
  OLoader,
  modalManager,
  OToastManager,
  OTooltip,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { CustomPagination } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/custom-pagination/custom-pagination.component";
import { grid } from "./duplicatas-note.grid";
import { duplicatasNoteFilterModalId } from "./duplicatas-note-filter.utils";
import { DuplicatasNoteFilter } from "./duplicatas-note-filter.component";
import { useDuplicatasNote } from "./duplicatas-note.hook";
import { DetailsCard } from "components/details-card/details-card.component";
import { DuplicatasNoteSummary } from "./duplicatas-note-summary";
import { customerQueryParam } from "components/access-control";

export const DuplicatasNoteDetailsComponent = (documentNumber: string) => {
  const gridRef = useGridRef();
  const {
    dataSource,
    loadingDataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  } = useDuplicatasNote(documentNumber);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Nota a nota"
          description="Visualize todos os recebíveis de duplicatas e suas informações principais"
        />
      }
    >
      <OCard>
        <OCardBody>
          <div className="d-flex flex-column gap-4">
            {!summary || loadingSummary ? (
              <div className="d-flex justify-content-center">
                <OLoader />
              </div>
            ) : (
              <DetailsCard
                fields={DuplicatasNoteSummary}
                value={summary}
                loading={loadingSummary}
              />
            )}
            <GridTemplate
              gridRef={gridRef}
              actions={
                <>
                  <IconButton
                    icon={{ category: "orq", icon: "orq-tools-search" }}
                    onClick={() =>
                      modalManager.show(duplicatasNoteFilterModalId)
                    }
                  >
                    Abrir busca
                  </IconButton>
                  <OTooltip type="info">
                    <span slot="tooltip-content">Copiar URL da busca</span>
                    <IconButton
                      outline
                      bordered
                      type="default"
                      icon={{
                        category: "orq",
                        icon: "orq-edit-copy",
                        size: "lg",
                        className: "m-0",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          window.location.href +
                            `&${customerQueryParam}=${documentNumber}`,
                        );
                        OToastManager.success(
                          "URL da busca copiada com sucesso!",
                        );
                      }}
                    />
                  </OTooltip>
                  <RefreshGridButton onClick={() => refetchData()} />
                  <OButton
                    onClick={() => exportAllData()}
                    type="default"
                    outline
                  >
                    <OIcon category="far" icon="fa-file-spreadsheet" />
                  </OButton>
                </>
              }
            >
              <ODataGridGenerator
                grid={grid}
                gridRef={gridRef}
                dataSource={dataSource}
                loading={loadingDataSource}
              />
              <CustomPagination
                totalCount={summary?.totalRecebiveis ?? -1}
                loading={loadingSummary}
                currentPage={page}
                setCurrentPage={setPage}
                pagination={[10, 20, 30]}
                selectedPaginationSize={rows}
                setSelectedPaginationSize={setRows}
              />
            </GridTemplate>
          </div>
        </OCardBody>
      </OCard>
      <DuplicatasNoteFilter
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
      />
    </DetailsTemplate>
  );
};
