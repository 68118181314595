import { OIcon } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import { ReadinessStatus } from "./limit-details.types";

export const readinessStatusDetailsGenerator = (
  readinessStatus: ReadinessStatus,
) => {
  const icon = (check: boolean) =>
    check ? (
      <OIcon type="success" icon="fa-check" category="fal" />
    ) : (
      <OIcon type="danger" icon="fa-times" category="fal" />
    );
  return {
    limit: {
      title: "Status Operacionais",
      items: [
        {
          label: (
            <div className="d-flex gap-1 align-align-items-center">
              {icon(readinessStatus?.isReady)}Pronto para operar
            </div>
          ),
        },
        {
          value: (
            <div className="d-flex gap-1 align-align-items-center">
              {icon(readinessStatus?.isEligibilityApproved)}Empresa elegível
            </div>
          ),
          grid: { md: 4 },
        },
        {
          value: (
            <div className="d-flex gap-1 align-align-items-center">
              {icon(readinessStatus?.accountReady)}Conta com status AT
            </div>
          ),
          grid: { md: 4 },
        },
        {
          value: (
            <div className="d-flex gap-1 align-align-items-center">
              {icon(readinessStatus?.termsOfUseReady)}Termos de uso assinado
            </div>
          ),
          grid: { md: 4 },
        },
      ],
    },
  } satisfies DetailsFields;
};
