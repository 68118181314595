import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { TaxasPreCurvaItem } from "services/senna/models";

export const useAddRateCurve = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [curvaPre, setCurvaPre] = useState<TaxasPreCurvaItem[]>([]);

  const submit = async () => {
    try {
      setLoading(true);

      const payload = {
        indexBase: "fptp",
        curvaPre,
      };

      await service.senna.setTaxasPre(payload);

      OToastManager.success("Curvas importadas com sucesso");

      navigate(routes.recebiveis.product.rateCurve.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  };

  return {
    curvaPre,
    loading,
    setCurvaPre,
    submit,
  };
};
