import { OCol, ORFieldInput } from "@maestro/react";

export const NameInput = () => {
  return (
    <OCol>
      <ORFieldInput
        id="name"
        name="name"
        tag="text"
        label="Nome do convênio"
        placeholder="Insira o nome do convênio"
        maxlength={50}
      />
    </OCol>
  );
};
