export interface OnboardingExceptionMap {
  message: string;
}

export const domainExceptionMap: Record<string, OnboardingExceptionMap> = {
  CompanyHasInvite: {
    message: "Entidade já contém um convite em aberto",
  },
  OfficerNotFound: {
    message:
      "Officer não registrado a mesa requisitada, verifique com o time responsável",
  },
  ErrorOnSentInvite: {
    message:
      "Erro inesperado ao enviar o convite, tente novamente e se não for possível verifique com o time responsável",
  },
};

export const validationExceptionMap: Record<string, OnboardingExceptionMap> = {
  InvalidInvite: {
    message:
      "Requisição inválida, tente novamente e se não for possível verifique com o time responsável",
  },
  InvalidTaxId: {
    message: "CNPJ inválido, verifique e tente novamente",
  },
  InvalidNotifier: {
    message:
      "Requisição inválida, tente novamente e se não for possível verifique com o time responsável",
  },
  InvalidEmail: {
    message: "E-mail inválido, verifique e tente novamente",
  },
  InvalidDeskCode: {
    message:
      "Código da mesa inválido, tente novamente e se não for possível verifique com o time responsável",
  },
  DeskConfigNotFound: {
    message:
      "Configuração de mesa não localizado, tente novamente e se não for possível verifique com o time responsável",
  },
  UserNotHaveRelationshipDesk: {
    message:
      "Requisitante sem permissão a mesa, tente novamente e se não for possível verifique com o time responsável",
  },
};

export const semaphoreExceptionMap: Record<string, OnboardingExceptionMap> = {
  SemaphoreValidationError: {
    message: "Não foi possível válidar a entidade no semáforo",
  },
  SemaphoreTemplateNotFound: {
    message: "Não foi localizado o template de semáforo a validar",
  },
};
