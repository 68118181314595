import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { service } from "services";
import { DownloadWorkflowPendency } from "./compose/download-workflow-pendency.component";

export const workflowPendenciesGrid = {
  datagrid: {
    noDataText: "Nenhuma pendência encontrada",
    editing: {
      texts: {
        confirmDeleteMessage: "Tem certeza que deseja remover essa pendência?",
      },
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "origin",
      caption: "Origem",
    },
    {
      dataField: "fieldName",
      caption: "Pendência",
    },
    {
      dataField: "reason",
      caption: "Descrição",
      width: 400,
    },
    {
      dataField: "status",
      caption: "Status",
      sortOrder: "asc",
    },
    {
      caption: "Data da resolução",
      cellRender: ({ data }) =>
        data.resolvedDate
          ? dayjs(data.resolvedDate).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      caption: "Resolução",
      cellRender: ({ data }) =>
        ["RESOLVED", "SUBMITTED"].includes(data.status) &&
        (data.type === "document" ? (
          <DownloadWorkflowPendency
            files={data.workflowLeadDocument.files.filter((f) => f.isActive)}
          />
        ) : (
          data.value
        )),
    },
    {
      caption: "Entidade legal associada",
      cellRender: ({ data }) =>
        data.legalEntity
          ? `${data.legalEntity.name} - ${masks.cpfCnpj(
              data.legalEntity.taxId,
            )}`
          : "-",
    },
    {
      dataField: "externalId",
      caption: "Id externo",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => {
        return (
          data.status !== "RESOLVED" && (
            <DataGridAction
              actions={[
                {
                  label: "Remover",
                  icon: { category: "orq", icon: "orq-cancel" },
                  onClick: async () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          )
        );
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubCreditManager.getWorkflowLeadsDetails>
  >["data"]["pendencies"][number]
>;
