import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useState } from "react";
import { ReceivableStateMachineByIdEventsItem } from "services/quickfin/models";
import { service } from "services/service";

export const useCollapseEvent = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState<string>();
  const { loading, callService: republishEvent } = useServiceCall(
    service.quickfin.republishEvent,
  );

  const republish = async (event: ReceivableStateMachineByIdEventsItem) => {
    const payload = {
      eventBody: editedMessage ?? event.eventData,
      className: event.eventName,
    };

    const { success } = await republishEvent(payload);

    if (success) {
      setIsEditing(false);
      setEditedMessage(undefined);
      OToastManager.success("Dados atualizados com sucesso");
    } else {
      OToastManager.danger("Erro para atualizar os dados");
    }
  };

  return {
    editedMessage,
    isEditing,
    loading,
    republish,
    setEditedMessage,
    setIsEditing,
  };
};
