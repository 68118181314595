import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOptionMultiple,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { DateInputs } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/_compose/date-inputs/date-inputs.component";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  duplicatasNoteFilterModalId,
  statesOptions,
} from "./duplicatas-note-filter.utils";
import { CompanySearchCanceling } from "pages/recebiveis/pages/produto/cancelamento/form-compose/sacado-cedente/busca-empresa-cancelamento/company-search-canceling.component";
import { NotesFilterForm } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/note-filter.types";
import { TagInputs } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/_compose";

interface DuplicatasNoteFilterProps {
  form: UseFormReturn<NotesFilterForm>;
  onSubmit: () => void;
  resetFilter: () => void;
}

export const DuplicatasNoteFilter = ({
  form,
  onSubmit,
  resetFilter,
}: DuplicatasNoteFilterProps) => {
  return (
    <OModal id={duplicatasNoteFilterModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <CompanySearchCanceling
              mode="Sacado"
              label="Nome ou identificação dos sacados"
              name="sacadoIdList"
              placeholder="Digite ao menos 3 (três) caracteres"
            />
            <OCol xs={12} md={6}>
              <ORFieldSelectMultiple
                id="currentStateList"
                name="currentStateList"
                label="Status"
              >
                {statesOptions.map((state) => (
                  <OOptionMultiple key={state} value={state}>
                    {state}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </OCol>
            <DateInputs />
            <TagInputs />
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton onClick={() => resetFilter()} outline>
            Limpar
          </OButton>
          <OButton onClick={() => onSubmit()}>Buscar</OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
