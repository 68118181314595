import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const useCollectionsAgreement = () => {
  const {
    callService: getAgreementsList,
    loading,
    value: agreementsList,
  } = useServiceCall(service.adminBankinghub.collection.getAgreements);

  useEffect(() => {
    getAgreementsList();
  }, [getAgreementsList]);

  return { agreementsList, getAgreementsList, loading };
};
