export const filePageTypeMap: Record<ScfDevelopers.FilePageType, string> = {
  COMPLETO: "Completo",
  FRENTE: "Frente",
  VERSO: "Verso",
  MULTI_PAGINAS: "Multi páginas",
};

export const documentSubtypeByDocumentMap: Record<
  ScfDevelopers.PayerDocumentType,
  ScfDevelopers.PayerDocumentSubType[] | undefined
> = {
  IDENTIFICACAO: ["CNH", "RG", "RNE", "PASSAPORTE"],
  BIOMETRIA: ["SELFIE"],
  CONTRATO_SOCIAL: undefined,
  ESTATUTO_SOCIAL: undefined,
  REQUERIMENTO_EMPRESARIO: undefined,
  PROCURACAO: undefined,
  ATA_ELEICAO_DIRETORIA: undefined,
  QUADRO_SOCIETARIO: undefined,
  DOCUMENTO_FINANCEIRO: undefined,
  CCMEI: undefined,
  COMPROVANTE_DE_RESIDENCIA: undefined,
  INDEFINIDO: undefined,
  ATA_ELEICAO: undefined,
  FICHA_CADASTRAL: undefined,
  DECLARACAO_ITIOF: undefined,
  DECLARACAO_ITIR: undefined,
  KNOW_YOUR_CUSTOMER: undefined,
  BALANCO_DRE: undefined,
  DRE_DEFIS: undefined,
  DECLARACAO_PREVISAO_FATURAMENTO: undefined,
  DECLARACAO_ANUAL_SIMPLES_NACIONAL: undefined,
  CERTIFICADO_CONDICACAO_MEI: undefined,
  CERTIDAO_DE_CASAMENTO: undefined,
};

export const DocumentSubtypeMap: Record<
  ScfDevelopers.PayerDocumentSubType,
  string
> = {
  CNH: "CNH",
  RG: "RG",
  RNE: "RNE",
  SELFIE: "Selfie",
  PASSAPORTE: "Passaporte",
  INDEFINIDO: "Indefinido",
};
