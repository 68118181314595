import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  tradeGuarantorsGrid,
} from "./trade-guarantors-grid.grid";

interface TradeGuarantorsGridProps {
  tradeId: number | string;
}

export const TradeGuarantorsGrid = ({ tradeId }: TradeGuarantorsGridProps) => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const dataSource = useMemo(() => buildDataSource(tradeId), [tradeId]);
  const grid = useMemo(
    () =>
      tradeGuarantorsGrid(
        hasRole(roles.energia.customer.tradesGuarantorsEdit.role),
      ),
    [hasRole],
  );

  return (
    <GridTemplate
      pageTitle={
        <OTypography type="dark" size="lg">
          Devedores solidários
        </OTypography>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
