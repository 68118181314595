import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  RoleAssignmentsForm,
  useRoleAssignmentsSubmission,
} from "../../../components/role-assignments";
import {
  addUserDefaultValues,
  addUserValidationSchema,
} from "./add-user.schema";

export const useAddUser = () => {
  const form = useForm<RoleAssignmentsForm>({
    defaultValues: addUserDefaultValues,
    resolver: yupResolver(addUserValidationSchema),
  });

  const {
    ceilingVigencyDate,
    checkEmailLoading,
    checkProfilesLoading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
    submitLoading,
  } = useRoleAssignmentsSubmission(form, false);

  return {
    ceilingVigencyDate,
    form,
    loading: checkEmailLoading || checkProfilesLoading || submitLoading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
  };
};
