import { EmbeddedContainer, EmbeddedPortal } from "components/portal-embeded";

import { translateRouteToAdmin } from "./b2b-embedded.utils";

export const B2BEmbeddedAdminBehavior = ({
  portalRoute,
}: {
  portalRoute: string;
}) => {
  return (
    <EmbeddedContainer>
      <EmbeddedPortal
        route={portalRoute}
        translateRouteToAdmin={translateRouteToAdmin}
      />
    </EmbeddedContainer>
  );
};
