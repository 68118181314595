import { Tags } from "@maestro/react";
import {
  FeesType,
  InterestType,
} from "services/admin-bankinghub/collection/models/types";

export interface PercentOrValueInputProps {
  fieldName: FieldName;
  typeWatched?: FeesType | InterestType;
  disabled: boolean;
}

export enum FieldName {
  Fine = "fine",
  Interest = "interest",
  Discount = "discount",
}

export interface FieldInputProps {
  key?: string;
  id: string;
  name: string;
  label: string;
  tag: Tags;
  symbol?: string;
  min?: number;
  max?: number;
  placeholder?: string;
}
