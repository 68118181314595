import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { endpoints } from "services/endpoints";
import { LoaMonitoringMasterDetail } from "./loa-monitoring.master-detail";

export const loaMonitoringGrid = (
  filterValue: (string | string[])[] | null,
): ODataGridGeneratorConfig<HubOnboardingRelationships.Odata.LetterOfAttorney> => ({
  datagrid: {
    noDataText: "Nenhuma procuração encontrada.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.onboardingRelationships.odata.companyPowers.letterofattorney,
      {
        dataSourceOptions: {
          filter: filterValue,
        },
      },
    ),
  },
  columns: [
    {
      caption: "Id",
      dataField: "Id",
    },
    {
      caption: "Uuid",
      dataField: "Uuid",
    },
    {
      caption: "Status",
      dataField: "Status",
    },
  ],
  masterDetail: {
    enabled: true,
    component: LoaMonitoringMasterDetail,
  },
});
