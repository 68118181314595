import * as yup from "yup";

export const baseFormDefaultValues = {
  taxId: null,
  officialName: null,
  companyEntityType: null,
  companySacadoStatus: null,
  deal: null,
  country: null,
};

export const baseFormValidationSchema = yup.object({
  officialName: yup.string().required("É necessário preencher o nome oficial"),
  companyEntityType: yup
    .string()
    .required("É necessário informar o tipo da entidade"),
  country: yup
    .string()
    .required("Este campo é obrigatório")
    .test(
      "country",
      "País não pode ser Brasil quando Tipo do documento for Offshore",
      (value, context) => {
        return context.parent.companyEntityType === "Offshore" && value === "BR"
          ? false
          : true;
      },
    )
    .test(
      "country",
      "Para Tipo do documento CPF ou CNPJ, País deve ser Brasil",
      (value, context) => {
        return context.parent.companyEntityType !== "Offshore" && value !== "BR"
          ? false
          : true;
      },
    ),
});
