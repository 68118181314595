import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { GroupedAgenda } from "./grouped-agendas.types";

export const groupedAgendasGrid: ODataGridGeneratorConfig<GroupedAgenda> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    dateSerializationFormat: "yyyy-MM-dd",
    height: 900,
  },
  columns: [
    {
      dataField: "data_agenda",
      dataType: "date",
      format: "shortDate",
      caption: "Data",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
    },
    {
      dataField: "cedente_nome",
      dataType: "string",
      caption: "Cedente",
    },
    {
      dataField: "cedente_cnpj",
      dataType: "string",
      caption: "CNPJ do cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "sacado_nome",
      dataType: "string",
      caption: "Sacado",
    },
    {
      dataField: "sacado_cnpj",
      dataType: "string",
      caption: "CNPJ do sacado",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "valor_total_bruto",
      dataType: "number", // casting to number
      caption: "Valor total bruto",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "valor_total_comprometido_btg",
      dataType: "number", // casting to number
      caption: "Valor total comprometido",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "valor_total_livre",
      dataType: "number", // casting to number
      caption: "Valor total livre",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "valor_total_disponivel",
      dataType: "number", // casting to number
      caption: "Valor total disponível",
      format: (value) => masks.currency(value, "R$", "."),
    },
  ],
};
