export interface CompanyInteractionFormValues {
  findTaxId: string;
}

export const companyFormDefaulValues: CompanyInteractionFormValues = {
  findTaxId: "",
};

export const cleanTaxId = (
  taxId: string | number | null | undefined,
): string | null => {
  if (!taxId) {
    return taxId === null ? null : "";
  }

  return taxId.toString().trim().replace(/\D/g, "");
};
