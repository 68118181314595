import { ODataGridGenerator } from "components/data-grid";
import { NextStepButton, ResetStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { DetailsCard } from "components/details-card";
import { OLoader, OToastManager, OTypography } from "@maestro/react";
import { useEffect, useState } from "react";
import { service } from "services";
import { SimulatedOperationsWithoutRegistryResponseWithAvgInfos } from "services/quickfin/models/responses/post-admin-operations-without-registry.response";
import { summaryGrid as importedSummaryGrid } from "./summary.grid";
import { useIntubateOperationsWithoutRegistry } from "../../intubate-operations-without-registry.context";
import { BuildOperationDetails } from "../../../../../utils/operation-details-entuba-sem-registro/operation-details.details";

const LoadingComponent = () => (
  <div className="d-flex flex-column align-items-center gap-2">
    <OTypography>
      Aguarde enquanto validamos a sua requisição. Isso pode demorar alguns
      minutos.
    </OTypography>
    <OLoader size="sm" />
  </div>
);

const DataComponent = ({
  simulatedData,
  gridRef,
  summaryGrid,
}: {
  simulatedData: any;
  gridRef: any;
  summaryGrid: any;
}) => {
  const hasReceivables =
    simulatedData?.receivablesWithRate &&
    simulatedData.receivablesWithRate.length > 0;

  return (
    <div className="d-flex flex-column gap-4">
      {!hasReceivables && (
        <div className="d-flex justify-content-center align-items-center">
          <OTypography>
            Nenhum recebível foi simulado, pois a planilha apresenta problemas
            ou o endpoint está fora do ar.
          </OTypography>
        </div>
      )}
      {hasReceivables && (
        <>
          <DetailsCard fields={BuildOperationDetails(simulatedData)} />
          <GridTemplate
            gridRef={gridRef}
            showExportButton
            showClearFiltersButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={summaryGrid}
              dataSource={simulatedData.receivablesWithRate}
            />
          </GridTemplate>
        </>
      )}
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        {hasReceivables && <NextStepButton />}
      </div>
    </div>
  );
};
export const SummaryStep = () => {
  const { dataReceivables } = useIntubateOperationsWithoutRegistry();
  const gridRef = useGridRef();
  const [simulatedData, setSimulatedData] =
    useState<SimulatedOperationsWithoutRegistryResponseWithAvgInfos>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSimulatedData = async () => {
      if (!dataReceivables) {
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const response =
          await service.quickfin.simulateOperationsWithoutRegistry(
            dataReceivables,
          );
        const data =
          response.data as SimulatedOperationsWithoutRegistryResponseWithAvgInfos;
        setSimulatedData(data);
      } catch {
        OToastManager.danger(
          "Não foi possível calcular Taxa Média Mensal e Prazo Médio.",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSimulatedData();
  }, [dataReceivables]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <DataComponent
      simulatedData={simulatedData}
      gridRef={gridRef}
      summaryGrid={importedSummaryGrid}
    />
  );
};
