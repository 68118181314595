import { BatchStatus } from "./lotes.type";

export const massifyStatusMap: Record<string, BatchStatus> = {
  APROVADO: {
    label: "Concluído",
    type: "success",
  },
  CONTA_ATIVA: {
    label: "Concluído",
    type: "success",
  },
  VALIDANDO_CANDIDATO: {
    label: "Em andamento",
    type: "info",
  },
  ENRIQUECENDO_DADOS: {
    label: "Em andamento",
    type: "info",
  },
  ANALISE_INICIADA: {
    label: "Em andamento",
    type: "info",
  },
  EM_ANALISE_ONBOARDING: {
    label: "Em andamento",
    type: "info",
  },
  EM_ANALISE_AML: {
    label: "Em andamento",
    type: "info",
  },
  EM_ANALISE_BIOMETRICA: {
    label: "Em andamento",
    type: "info",
  },
  ACAO_REQUERIDA: {
    label: "Em andamento",
    type: "info",
  },
  PENDENTE_ATIVACAO_CONTA: {
    label: "Em andamento",
    type: "info",
  },
  CADASTRO_NAO_FINALIZADO: {
    label: "Cadastro não finalizado",
    type: "warning",
  },
  FALHA_VALIDACAO: {
    label: "Erro",
    type: "danger",
  },
  REPROVADO: {
    label: "Erro",
    type: "danger",
  },
  ERRO: {
    label: "Erro",
    type: "danger",
  },
};
