import { PageTitle } from "components/page-title";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DetailsTemplate } from "templates/details-template";
import { LoadingButton } from "components/loading-button";
import { OIcon, OTooltip } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import { SelectConditionModal } from "../../../../../components/conditions/select-conditions-component/select-condition-modal/select-condition-modal.component";
import { TestConditionModal } from "../../../../../components/conditions/test-condition-modal/test-condition-modal.component";
import {
  WorkflowFormTemplateProvider,
  useWorkflowFormTemplate,
} from "../../../../../components/hooks/use-workflow-form-template.context";
import { WorkflowProvider } from "../../../../../components/hooks/use-workflow.context";
import { WorkflowProdutoFormById } from "../../../../../routes/workflow.route-params";
import { RemoveCardModal } from "./_compose/draggable-cards/_compose/remove-card-modal/remove-card-modal.component";
import { SubimitCardModal } from "./_compose/draggable-cards/_compose/submit-card-modal/submit-card-modal.component";
import { AddComponentModal } from "./_compose/draggable-components/_compose/add-component-modal/add-component-modal.component";
import { RemoveComponentModal } from "./_compose/draggable-components/_compose/remove-component-modal/remove-component-modal.component";
import { UpdateComponentModal } from "./_compose/draggable-components/_compose/update-component-modal/update-component-modal.component";
import { AddStepModal } from "./_compose/draggable-steps/_compose/add-step-modal/add-step-modal.component";
import { RemoveStepModal } from "./_compose/draggable-steps/_compose/remove-step-modal/remove-step-modal.component";
import { UpdateStepModal } from "./_compose/draggable-steps/_compose/update-step-modal/update-step-modal.component";
import { FormGeneralInfoComponent } from "./_compose/form-general-info/form-general-info.component";
import { FormStructureComponent } from "./_compose/form-structure/form-structure.component";

interface FormDetailsComponentProps {
  formId: number;
}

const FormDetailsComponent = ({ formId }: FormDetailsComponentProps) => {
  const {
    form: { callService: getForm, loading },
  } = useWorkflowFormTemplate();

  useEffect(() => {
    if (!formId) return;
    getForm(formId);
  }, [formId, getForm]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do formulário" />}
      actions={
        <OTooltip>
          <TooltipContent>Atualizar</TooltipContent>
          <LoadingButton
            loading={loading}
            onClick={() => getForm(formId)}
            type="default"
            outline
          >
            <OIcon category="orq" icon="orq-refresh" />
          </LoadingButton>
        </OTooltip>
      }
    >
      <>
        <AddStepModal />
        <UpdateStepModal />
        <RemoveStepModal />

        <SubimitCardModal />
        <RemoveCardModal />

        <AddComponentModal />
        <UpdateComponentModal />
        <RemoveComponentModal />

        <SelectConditionModal />
        <TestConditionModal />

        <div className="d-flex flex-column gap-5">
          <FormGeneralInfoComponent />
          <FormStructureComponent />
        </div>
      </>
    </DetailsTemplate>
  );
};

export const FormDetailsPage = () => {
  const { id } = useParams<WorkflowProdutoFormById>();

  return (
    <WorkflowProvider>
      <WorkflowFormTemplateProvider>
        <FormDetailsComponent formId={Number(id)} />
      </WorkflowFormTemplateProvider>
    </WorkflowProvider>
  );
};
