import * as yup from "yup";
import dayjs from "dayjs";
import { FormValuesType } from "./block.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  id: yup.string(),
  identification: yup.string().required("Este campo é obrigatorio"),
  type: yup.string().required("Este campo é obrigatorio"),
  from: yup.string().nullable(),
  until: yup
    .string()
    .nullable()
    .test({
      name: "should-be-greater-than-valid-from",
      message:
        "Fim de vigência deve ser uma data superior ao início de vigência",
      test: (value, context) => {
        return !value || dayjs(value).isAfter(dayjs(context.parent.from));
      },
    }),
});

export function getKeyByValue<T>(
  object: Record<string, T>,
  value: T,
): string | undefined {
  const keys = Object.keys(object);
  const foundKey = keys.find((key) => object[key] === value);
  return foundKey;
}
