import * as yup from "yup";

export interface DeskPartnerFormValues {
  partnerTypeCode: string;
  deskOnboardingConfigIdentifier: string;
  legalEntityResponsibleInstitutionTaxId: string;
  flow: string;
}

export const deskPartnerFormValidationSchema: yup.ObjectSchema<DeskPartnerFormValues> =
  yup.object({
    partnerTypeCode: yup.string().required("Este campo é obrigatório"),
    deskOnboardingConfigIdentifier: yup
      .string()
      .required("Este campo é obrigatório"),
    legalEntityResponsibleInstitutionTaxId: yup
      .string()
      .required("Este campo é obrigatório"),
    flow: yup.string().required("Este campo é obrigatório"),
  });
