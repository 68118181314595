import { Roles } from "roles/roles.types";

export const developersRoles = {
  product: {
    tab: {
      role: "admin:DevelopersTabProduct.View",
      description: "Visualização da tab de developers no portal admin",
    },
  },
  customer: {
    tab: {
      role: "admin:DevelopersTabProduct.View",
      description: "Visualização da tab de developers no portal admin",
    },
  },
} satisfies Roles;
