import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

import { service } from "services";

export type Nature = Awaited<
  ReturnType<typeof service.hubFx.nature.getNatures>
>["data"][number];

export const exchangeProductNaturesGrid = {
  datagrid: {
    noDataText: "Nenhuma natureza encontrada.",
    dataSource: dataSourceCustomStoreGenerator(
      () =>
        service.hubFx.nature.getNatures().catch(() => {
          throw new Error("Erro ao buscar naturezas");
        }),
      {
        customStoreOptions: {
          remove: async (removedNature) => {
            try {
              if (!removedNature.id) return;

              await service.hubFx.nature.deleteNatures(removedNature.id);

              OToastManager.success("A natureza foi removida com sucesso.");
            } catch {
              OToastManager.danger(
                "Um erro ocorreu ao tentar deletar a natureza. Por favor, tente novamente mais tarde.",
              );
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "code",
      caption: "Código",
      calculateSortValue: ({ code }) => Number(code),
    },
    {
      dataField: "groupCode",
      caption: "Código do grupo",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "disclaimer",
      caption: "Mensagem",
      width: 500,
    },
    {
      dataField: "minimumAmount",
      caption: "Valor mínimo",
    },
    {
      dataField: "maximumAmount",
      caption: "Valor máximo",
    },
    {
      dataField: "isApproved",
      caption: "Aprovado?",
    },
    {
      dataField: "isIncomingOperationAllowed",
      caption: "Permitido recebimento?",
    },
    {
      dataField: "isOutgoingOperationAllowed",
      caption: "Permitido envio?",
    },
    {
      dataField: "isDifferentOwnershipAccountAllowed",
      caption: "Permitido conta de um beneficiário diferente?",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "fas", icon: "fa-pen" },
              label: "Editar",
              role: roles.cambio.product.contingencyNaturesAllUpdatePage.role,
              route:
                corporateRouter.routes.cambio.product.contingency.natures.all.update.path(
                  {
                    id: data.id,
                  },
                ),
            },
            {
              icon: { category: "fas", icon: "fa-trash" },
              label: "Remover",
              onClick: () => {
                component.option(
                  "editing.texts.confirmDeleteMessage",
                  `Você confirma que a natureza '${data?.code} - ${data?.description}' será removida?`,
                );
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<Nature>;
