import { SelectSearchMultiple } from "components/form";
import { useSelectSacados } from "./select-sacados.hook";

export const SelectSacados = () => {
  const { allCompanies } = useSelectSacados();

  return (
    <SelectSearchMultiple
      label="Nome ou Identificação dos Sacados"
      id="sacadosIds"
      name="sacadosIds"
      placeholder="Selecionar"
      options={allCompanies}
      maxOptions={50}
    />
  );
};
