import { OIcon, OLink, OTypography } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse } from "services/admin-bankinghub/models";
import { DetailsTemplate } from "templates/details-template";
import { statementHistoryDetailsFields } from "./statement-history-details.details";

export const StatementHistoryDetails = () => {
  const { value: traceability, hasError } =
    useParseFromSearchParams<
      GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number]
    >("traceability");

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do extrato" />}
      actions={
        traceability?.file ? (
          <OLink href={traceability.file.data.url}>
            <OIcon category="orq" icon="orq-download" className="me-2" />
            Download
          </OLink>
        ) : (
          <OTypography type="default">Download indisponível</OTypography>
        )
      }
    >
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={!traceability}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível encontrar o extrato"
              messageParagraph="Ocorreu um erro ao buscar o extrato. Retorne à página anterior e tente novamente."
            />
          }
          value={traceability}
          fields={statementHistoryDetailsFields}
        />
      </div>
    </DetailsTemplate>
  );
};
