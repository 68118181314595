import { logger } from "utils/logger";
import {
  GetTokenFromSearchParamsCommand,
  GetTokenFromStorage,
  authManager,
} from "../../auth";
import { GetUserCommand, userManager } from "../../user";
import { Behaviors } from "../types/behavior-ids";
import { BehaviorConfiguration } from "../types/config";
import { BehaviorRoles } from "../types/roles";

export const customerServiceBehavior = {
  id: Behaviors.CustomerService,
  title: "Salesforce",
  homepage: "/selecao-empresa",
  domains: ["embedded-corporate.quickfin.com.br"],
  roles: [
    BehaviorRoles.HeaderLogo_Show,
    BehaviorRoles.HeaderCustomerSelector_Show,
    BehaviorRoles.HeaderCustomerServiceMenu_Show,
    BehaviorRoles.HeaderCustomerServiceUserMenu_Show,
    BehaviorRoles.HeaderUserMenu_Show,
    BehaviorRoles.HeaderConfiguration_Show,
  ],
  init() {
    this.auth();
  },
  async auth() {
    try {
      await authManager.execute(new GetTokenFromSearchParamsCommand());
    } catch (err) {
      logger.warn(err);

      // this may throw an error, in which case we won't have an
      // auth token, so it's unhandled to break the application
      await authManager.execute(new GetTokenFromStorage());
    }

    await userManager.execute(new GetUserCommand());
  },
} satisfies BehaviorConfiguration;
