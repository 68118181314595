import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { Apps } from "services/developers/models";
import { service } from "services";

export const useAppDetails = () => {
  const [app, setapp] = useState<Apps>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getApp = useCallback(async (appId: string, tenantId: string) => {
    try {
      setLoading(true);
      const {
        data: { items },
      } = await service.developers.getAppDetails(appId, tenantId);
      setapp(items[0]);
      setHasError(false);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o aplicativo");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    app,
    loading,
    hasError,
    getApp,
  };
};
