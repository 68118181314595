import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ACCOperacoesByIdRouteParams } from "../../../../routes/acc.route-params";
import { adminCustomerAccTradesDetailsGenerator } from "./admin-customer-acc-trades.details";

export const AdminCustomerACCTradesDetailsPage = () => {
  const { id } = useParams<ACCOperacoesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: quoteDetails,
    callService: getACCTradeDetails,
    hasError: hasErrorQuoteDetails,
    loading: loadingQuoteDetails,
  } = useServiceCall(service.acc.adminCustomer.getAccTradesById);

  useEffect(() => {
    getACCTradeDetails(id);
  }, [getACCTradeDetails, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da Operação" />}>
      <ContentTemplate
        loading={loadingQuoteDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da operação."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getACCTradeDetails(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorQuoteDetails}
        value={quoteDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccTradesDetailsGenerator}
            />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
