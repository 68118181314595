import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import dayjs from "dayjs";
import { CreditConciliation } from "./credit-conciliation.types";
import {
  buildDetailsLink,
  creditConciliationValidationStatusMap,
} from "./credit-conciliation.utils";

export const creditConciliationGrid: ODataGridGeneratorConfig<CreditConciliation> =
  {
    datagrid: {
      noDataText: "Nenhuma registro encontrado",
      paging: {
        enabled: false,
      },
      headerFilter: {
        visible: true,
        allowSearch: true,
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        role: roles.fidc.product.batchesPage.role,
        cellRender: ({ data }) => (
          <DetailsLink href={buildDetailsLink(data.id, data.fund)} />
        ),
      },
      {
        dataField: "fund",
        caption: "Fundo",
        allowSorting: false,
        allowHeaderFiltering: false,
      },
      {
        dataField: "fundTaxId",
        caption: "CNPJ",
        format: (value) => masks.cpfCnpj(value),
        allowSorting: false,
        allowHeaderFiltering: false,
      },
      {
        dataField: "totalAmount",
        caption: "Valor Total",
        format: (value) => masks.currency(value, currencySymbol.BRL),
        allowSorting: false,
        allowFiltering: false,
      },
      {
        dataField: "status",
        caption: "Status",
        allowSorting: false,
        allowFiltering: false,
        allowHeaderFiltering: true,
        cellRender: ({ data }) => {
          const { label, type } = getValueFromMap(
            creditConciliationValidationStatusMap,
            data.status,
          ) ?? {
            label: data.status,
            type: "info",
          };

          return <StatusBadge type={type}>{label}</StatusBadge>;
        },
      },
      {
        dataField: "receivedDate",
        dataType: "date",
        caption: "Data de envio",
        format: (value) =>
          dayjs(value).utc(true).local().format("DD/MM/YYYY HH:mm:ss"),
        allowSorting: false,
        allowFiltering: false,
      },
    ],
  };
