import { OConfirmationModal } from "@maestro/react";

export type ConfirmationModalProps = {
  onConfirm: () => void;
  id: string;
  isConfirmationModalProcessing: boolean;
};

export const resendInviteModalId = "jobs-stop-multiple-modal";

export const ConfirmationModal = ({
  onConfirm,
  id,
  isConfirmationModalProcessing,
}: ConfirmationModalProps) => {
  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={id}
      loading={isConfirmationModalProcessing}
      title="Reenviar Convite"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={onConfirm}
    >
      <div>Você deseja reenviar o convite?</div>
    </OConfirmationModal>
  );
};
