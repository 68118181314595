import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { CadastroClienteSearchByWorkflowUuiddRouteParams } from "../../../../routes/cadastro.route-params";
import { useGridActions } from "../_compose/grid-actions/grid-actions.hook";
import { useGetWorkflowDetails } from "./account-close-details.page.hook";
import { gridFiles } from "./files/account-close-details-files.grid";
import { buildClosingPendenciesDetails } from "./pendencias/account-close-details-pendencias.grid";
import { roles } from "roles/roles";

export const DetailedWorkflowClose = () => {
  const { workflowUuid } =
    useParams<CadastroClienteSearchByWorkflowUuiddRouteParams>();

  const { loadingFile, generateFile } = useGridActions();

  if (!workflowUuid) throw new Error("No workflow uuid in route parameters");
  const { dataSource, workflow, hasError } =
    useGetWorkflowDetails(workflowUuid);
  const gridRef = useGridRef();

  return (
    <>
      <DetailsTemplate>
        <DetailsCard
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível encontrar os dados"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => dataSource.reload()} />
            </ErrorComponent>
          }
          emptyStateComponent={
            <EmptyState messageParagraph="Nenhum dado foi enctrado para o workflow." />
          }
          fields={buildClosingPendenciesDetails}
          value={workflow}
          hasError={hasError}
        />
      </DetailsTemplate>

      <GridTemplate
        pageTitle={<PageTitle title="Arquivos Gerados" />}
        gridRef={gridRef}
      >
        <LoadingButton
          title="Gerar"
          ariaLabel="Gerar"
          loading={loadingFile}
          disabled={hasError}
          role={
            roles.cadastro.customer.accountCloseDetailedFileGeneratePage.role
          }
          onClick={async () => {
            generateFile(undefined, workflowUuid);
          }}
        >
          Gerar Arquivos
        </LoadingButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={gridFiles}
          dataSource={dataSource}
        />
      </GridTemplate>
    </>
  );
};
