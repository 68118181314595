import { masks } from "@maestro/utils";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { Company } from "./company-search-signing.types";

export const buildCompanyOptions = (
  companies: Company[] | undefined,
  selectCompany: (identification: string, label: string) => void,
): SelectSearchOption<string>[] =>
  companies
    ?.filter((company) => company.identification)
    .map((company) => {
      const label = `${company.name} - ${masks.cpfCnpj(
        company.identification,
      )}`;

      return {
        value: company.identification!,
        label,
        onSelect: () => selectCompany(company.identification!, label),
        customKey: company.id,
      };
    }) ?? [];
