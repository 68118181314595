import { SectionId } from "../../bankslip-details.types";

export const MapperSectionIdToTitle: Record<SectionId, string> = {
  [SectionId.Resume]: "Resumo",
  [SectionId.Payee]: "Beneficiário",
  [SectionId.Payer]: "Pagador",
  [SectionId.Fees]: "Juros, Multas e Desconto",
  [SectionId.Payment]: "Detalhes do pagamento",
  [SectionId.Details]: "Detalhes da cobrança",
};
