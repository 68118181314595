import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { ContractInstallment } from "services/renegotiation/models/types";
import { Contract } from "./renegociacao-clientes.type";
import { dangerIsExpired } from "./renegociacao-clientes.utils";

const renegociacoesColumns: ODataGridGeneratorConfig<ContractInstallment>["columns"] =
  [
    {
      caption: "Valor da curva",
      dataField: "curve_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
    {
      caption: "Valor no vencimento",
      dataField: "maturity_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
    {
      caption: "Valor principal",
      dataField: "principal_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
    {
      caption: "Data de vencimento",
      dataField: "maturity_date",
      dataType: "date",
      format: "shortDate",
      alignment: "left",
    },
    {
      caption: "Juros por atraso",
      dataField: "late_payment_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
    {
      caption: "Mora",
      dataField: "interest_arrears_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
    {
      caption: "Multa",
      dataField: "penalty_fee_value",
      format: (value) => masks.currency(value, "R$"),
      alignment: "left",
    },
  ];

export const renegociacoesMasterDetailGrid: ODataGridGeneratorConfig<ContractInstallment> =
  {
    datagrid: {
      noDataText: "Não existem parcelas.",
      onCellPrepared: dangerIsExpired,
    },
    columns: renegociacoesColumns,
  };

type RenegociacaoMasterDetailProps = MasterDetailComponentProps<Contract>;

export const RenegociacaoMasterDetail = ({
  data: {
    data: { installments },
  },
}: RenegociacaoMasterDetailProps) => {
  return (
    <ODataGridGenerator
      grid={renegociacoesMasterDetailGrid}
      dataSource={installments}
    />
  );
};
