import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { StatusBadge } from "components/status-badge";
import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { memo, useCallback } from "react";
import { client } from "services/client";
import { getValueFromMap } from "utils/get-value-from-map";
import { endpoints } from "../../utils/endpoints";
import { CardSection } from "../already-approved/already-approved.styles";
import { OffersPagination } from "../cards.types";
import { hiredOffersStatusMap } from "./limit-analysis-history.utils";
interface LimitAnalysisHistoryProps {
  selectedCompany: string;
}

export const LimitAnalysisHistory = memo(({
  selectedCompany,
}: LimitAnalysisHistoryProps) => {
  const loadOffers = useCallback(
    async ({ page, itemsPerPage }: OffersPagination) => {
      try {
        if (!selectedCompany) return;

        const { data } = await client.get(
          endpoints.adminBankinghub.card.getOfferList(page, itemsPerPage),
          {
            params: { document: selectedCompany },
          },
        );

        return data;
      } catch (error) {
        OToastManager.danger("Erro ao carregar limites");
      }
    },
    [selectedCompany],
  );

  const dataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load: async ({ skip, take }) => {
        const itemsPerPage = Number(take);
        const page = Math.ceil(Number(skip) / itemsPerPage) + 1;

        const queryParams = { page, itemsPerPage };

        const response = await loadOffers(queryParams);
        return {
          data: response.offers,
          totalCount: response.numberOfRecords,
        };
      },
    }),
  });
  return (
    <CardSection>
      <DataGrid
        id="grid-container-limit-analysis-history"
        keyExpr="id"
        width="100%"
        dataSource={dataSource}
        showBorders={true}
        bindingOptions={{
          dataSource: { deep: false, dataPath: "store" },
        }}
        remoteOperations={{ paging: true, filtering: false }}
        headerFilter={{ visible: false }}
        filterRow={{ visible: false }}
        sorting={{ mode: "none" }}
        noDataText="Nenhuma informação de análise de crédito encontrada."
      >
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 30]}
          showInfo={true}
        />
        <Column
          caption="Data de expiração"
          dataType="date"
          dataField="limitAnalysis.expirationDate"
          format="dd/MM/yyyy"
        />

        <Column
          caption="Status"
          dataType="string"
          cellRender={({ data }) => {
            const { label, type } = getValueFromMap(
              hiredOffersStatusMap,
              data.status,
            ) ?? {
              label: data.status,
              type: "warning",
            };

            return <StatusBadge type={type}>{label}</StatusBadge>;
          }}
        />
        <Column
          caption="Limite liberado"
          dataType="number"
          dataField="limitAnalysis.amount"
          format={(value: number) => masks.currency(value, "R$", ".")}
        />
      </DataGrid>
    </CardSection>
  );
});
