import {
  modalManager,
  OButton,
  OCard,
  OCardHeader,
  OCol,
  OTypography,
} from "@maestro/react";
import { PortfolioManagementCard } from "./portfolio-management-card.component";
import { PortfolioManagamentResponse } from "services/moana/models/responses/portfolio-management.response";
import { useState } from "react";
import { duplicatasRoles } from "pages/duplicatas/roles/duplicatas.roles";
import { discountedWalletModal } from "./portfolio-mangament.utils";
import { DiscountWalletModal } from "./discounted-wallet.component";

interface DiscountedWalletManagementProps {
  portfolioManagement?: PortfolioManagamentResponse;
  documentNumber: string;
}

export const DiscountedWalletManagement = ({
  portfolioManagement,
  documentNumber,
}: DiscountedWalletManagementProps) => {
  const [selectedAnticipatedRanges, setSelectedAnticipatedRanges] = useState<
    number[]
  >([]);

  const [selectedDiscountedRanges, setSelectedDiscountedRanges] = useState<
    number[]
  >([]);

  const [totalItems, setTotalItems] = useState<number>(0);

  return (
    <>
      <DiscountWalletModal
        documentNumber={documentNumber}
        ranges={selectedDiscountedRanges}
        totalItems={totalItems}
      />
      <OCard>
        <OCardHeader divider>
          <OCol className="justify-content-between">
            <OTypography type="default">Carteira descontada</OTypography>
            <OButton
              role={duplicatasRoles.product.walletDetailsPage.role}
              type="tertiary"
              outline
              disabled={selectedAnticipatedRanges.length <= 0}
              onClick={() => {
                setSelectedDiscountedRanges(selectedAnticipatedRanges);
                modalManager.show(discountedWalletModal);
              }}
            >
              Detalhar
            </OButton>
          </OCol>
        </OCardHeader>
        <PortfolioManagementCard
          receivables={portfolioManagement?.anticipatedReceivables}
          selectedRanges={selectedAnticipatedRanges}
          setSelectedRanges={setSelectedAnticipatedRanges}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
        />
      </OCard>
    </>
  );
};
