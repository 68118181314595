import { accService } from "./acc/acc.service";
import { adminBankinghubService } from "./admin-bankinghub/admin-bankinghub.service";
import { apiService } from "./api/api.service";
import { assetsService } from "./assets/assets.services";
import { b2bAgroService } from "./b2b/agro/b2bagro.service";
import { b2bCoreService } from "./b2b/core/b2bcore.service";
import { bankinghubService } from "./bankinghub/bankinghub.service";
import { clerkService } from "./clerk/clerk.service";
import { corbanService } from "./corban/corban.service";
import { customerServiceService } from "./customer-service/customer-service.service";
import { developersService } from "./developers/developers.service";
import { eligibilityengineService } from "./eligibilityengine/eligibilityengine.service";
import { fidcService } from "./fidc/fidc.service";
import { fopaService } from "./fopa/fopa.service";
import { gateholderService } from "./gateholder";
import { gatekeeperService } from "./gatekeeper/gatekeeper.service";
import { hubCreditManagerService } from "./hubcreditmanager/hubcreditmanager.service";
import { hubEnergyService } from "./hubenergy/hubenergy.service";
import { hubFxService } from "./hubfx/hubfx.service";
import { hubLoanService } from "./hubloan/hubloan.service";
import { hubPartnersService } from "./hubpartners/hubpartners.service";
import { legacysService } from "./legacys/legacys.service";
import { limitControlService } from "./limit-control/limit-control.service";
import { moanaService } from "./moana/moana.service";
import { onboardingCoreAccountService } from "./onboarding-core-account/onboarding-core-account.service";
import { onboardingRelationshipsService } from "./onboarding-relationships/onboarding-relationships.service";
import { onboardingService } from "./onboarding/onboarding.service";
import { paperclipv2Service } from "./paperclipv2/paperclipv2.service";
import { publicService } from "./public/public.service";
import { quickfinService } from "./quickfin/quickfin.service";
import { ravenService } from "./raven/raven.service";
import { recebiveisCartaoService } from "./recebiveis-cartao/recebiveis-cartao.service";
import { reconcilieroService } from "./reconciliero/reconciliero.service";
import { renegotiationService } from "./renegotiation/renegotiation.service";
import { roadwalkerService } from "./roadwalker/roadwalker.service";
import { scfDevelopersService } from "./scf-developers/scf-developers.service";
import { sennaService } from "./senna/senna.service";
import { validatorService } from "./validator/validator.service";
import { sharkService } from "./shark/shark.service";
import { turtlefeeService } from "./turtlefee/turtlefee.service";

export const service = {
  api: apiService,
  adminBankinghub: adminBankinghubService,
  assets: assetsService,
  bankinghub: bankinghubService,
  b2bAgro: b2bAgroService,
  b2bCore: b2bCoreService,
  clerk: clerkService,
  corban: corbanService,
  customerService: customerServiceService,
  developers: developersService,
  eligibilityengine: eligibilityengineService,
  fidc: fidcService,
  fopa: fopaService,
  gateholder: gateholderService,
  gatekeeper: gatekeeperService,
  hubCreditManager: hubCreditManagerService,
  hubEnergy: hubEnergyService,
  hubFx: hubFxService,
  acc: accService,
  hubLoan: hubLoanService,
  hubPartners: hubPartnersService,
  legacys: legacysService,
  limitControl: limitControlService,
  moana: moanaService,
  onboarding: onboardingService,
  onboardingCoreAccount: onboardingCoreAccountService,
  onboardingRelationships: onboardingRelationshipsService,
  paperclipv2: paperclipv2Service,
  public: publicService,
  quickfin: quickfinService,
  raven: ravenService,
  recebiveisCartao: recebiveisCartaoService,
  reconciliero: reconcilieroService,
  renegotiation: renegotiationService,
  roadwalker: roadwalkerService,
  scfDevelopers: scfDevelopersService,
  senna: sennaService,
  validator: validatorService,
  shark: sharkService,
  turtlefee: turtlefeeService,
};
