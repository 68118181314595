import { SelectSearchMultiple } from "components/form";
import { useOwnersFilter } from "./owners-filter.hook";

export const OwnersSearch = () => {
  const { selected } = useOwnersFilter();
  return (
    <SelectSearchMultiple
      label="Nome ou identificação dos cedentes"
      id="ownersUuid"
      name="ownersUuid"
      placeholder="Selecione os cedentes"
      options={selected}
      maxOptions={50}
    />
  );
};
