import {
  GetAccountAccountsResponseItem,
  GetBalanceBalanceByAccountResponse,
} from "services/bankinghub/models";

export interface Account
  extends GetAccountAccountsResponseItem,
    GetBalanceBalanceByAccountResponse {}

export enum MapperAccountStatus {
  "Ativa" = 1,
  "Bloqueio parcial" = 2,
  "Bloqueio total" = 3,
  "Inativa" = 4,
  "Encerrada" = 5,
  "Em processo de encerramento" = 6,
};