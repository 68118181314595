export const walletStatusMap = {
  CREATED: { label: "Disponível", type: "info" as const },
  APPROVED: { label: "Aprovada", type: "success" as const },
  REJECTED: { label: "Rejeitada", type: "danger" as const },
};

export const walletTypeMap = {
  SAMSUNG: { label: "Samsung Wallet", type: "info" as const },
  APPLE: { label: "Apple Wallet", type: "info" as const },
  GOOGLE: { label: "Google Wallet", type: "info" as const },
};
