import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OConfirmationModal,
  OIcon,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  OSelect,
  OTypography,
} from "@maestro/react";
import { AxiosError } from "axios";
import { useUser } from "contexts/user";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useState } from "react";
import { roles } from "roles/roles";
import { client } from "../../../../../../../services/client";
import { endpoints } from "../../../utils/endpoints";
import { ColumnGap, RowGap } from "../../cards.styles";
import { CardModality, CardStatus } from "../../cards.types";
import { setInfoById } from "../../utils/already-approved";
import { capitalizePhraseFirstLetters } from "../../utils/cases";
import { CardSection } from "../already-approved.styles";
import { MapperCardStatus } from "../already-approved.types";
import {
  AdressesCardInfo,
  blockedCardStatuses,
  PersonalCardInfo,
  SelectModalOptions,
} from "./constants";
import {
  AdressesCardInfoGrid,
  CardInfoItem,
  PersonalCardInfoGrid,
  TitleContainer,
} from "./description.styles";
import { DataRef, IDescriptionProps } from "./description.types";

const CARD_BLOCK_MODAL_ID = "card-block-confirmation-modal-id";
const CARD_BLOCK_TEMP_MODAL_ID = "card-block-temp-confirmation-modal-id";
const CARD_BLOCK_PREV_MODAL_ID = "card-block-prev-confirmation-modal-id";
const CARD_UNLOCK_MODAL_ID = "card-unlock-confirmation-modal-id";
const CARD_RESET_WRONG_PASSWORD_ATTEMPTS_MODAL_ID =
  "card-reset-wrong-password-attempts-modal-id";

export const Description = ({
  cardData,
  cardProgramData,
  cardInvoiceData,
  selectedCompany,
  loadCards,
}: IDescriptionProps) => {
  const [isModalBlockOppened, setIsModalBlockOppened] = useState(false);
  const [isConfirmationModalProcessing, setIsConfirmationModalProcessing] =
    useState(false);
  const [selectedModalValue, setSelectedModalValue] = useState<
    CardStatus | undefined
  >(undefined);

  const { hasRole } = useRoles();

  const { user } = useUser();

  const cardIsAlreadyBlocked = blockedCardStatuses.includes(cardData.status);
  const showDefinitivelyBlockButton =
    !cardIsAlreadyBlocked &&
    (hasRole(roles.cartoes.customer.definitivelyBlockCard.role) ||
      cardData.status === CardStatus.Normal ||
      cardData.status === CardStatus.Sleeping);

  const processCardBlock = useCallback(
    async (id: string, reason?: CardStatus) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.post(
          endpoints.bankinghub.postDefinitiveBlock(id),
          {
            reason,
          },
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
              channel: "ADMIN",
            },
          },
        );
        if (status === 200) {
          OToastManager.success(`Cartão bloqueado definitivamente com sucesso`);
          loadCards();
        } else if (status === 401) {
          OToastManager.danger("Erro ao Bloquear o cartão, sem autorização");
        }
      } catch (error) {
        OToastManager.danger("Erro ao Bloquear o cartão, tente novamente");
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, loadCards],
  );

  const processCardTemporaryBlock = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.post(
          endpoints.bankinghub.postTemporaryBlock(id),
          {},
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
            },
          },
        );
        if (status === 200) {
          loadCards();
        }
      } catch (error) {
        const status = (error as AxiosError<unknown>)?.response?.status;
        if (status === 401) {
          OToastManager.danger(
            "Erro ao Bloquear temporariamente o cartão, sem autorização",
          );
          return;
        }
        OToastManager.danger(
          "Erro ao Bloquear temporariamente o cartão, tente novamente",
        );
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, loadCards],
  );

  const processCardPreventiveBlock = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.post(
          endpoints.bankinghub.postPreventiveBlock(id),
          {
            operatorName: user.name,
            operatorDocument: user.cpf,
          },
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
            },
          },
        );
        if (status === 200) {
          loadCards();
        }
      } catch (error) {
        const status = (error as AxiosError<unknown>)?.response?.status;
        if (status === 401) {
          OToastManager.danger(
            "Erro ao Bloquear preventivamente o cartão, sem autorização",
          );
          return;
        }
        OToastManager.danger(
          "Erro ao Bloquear preventivamente o cartão, tente novamente",
        );
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, user, loadCards],
  );

  const processResetWrongCardPasswordAttempts = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.post(
          endpoints.bankinghub.postResetWrongCardPasswordAttempts(id),
          {
            operatorName: user.name,
            operatorDocument: user.cpf,
          },
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
            },
          },
        );
        if (status === 200) {
          loadCards();
        }
      } catch (error) {
        const status = (error as AxiosError<unknown>)?.response?.status;
        if (status === 401) {
          OToastManager.danger(
            "Erro ao redefinir tentativas de senha do cartão: sem autorização",
          );
          return;
        }
        OToastManager.danger(
          "Erro ao redefinir tentativas de senha do cartão, tente novamente",
        );
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, user, loadCards],
  );

  const processCardUnblock = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.delete(
          endpoints.bankinghub.deleteTemporaryBlocks(id),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        if (status === 200) {
          loadCards();
        } else if (status === 401) {
          OToastManager.danger("Erro ao Desbloquear o cartão, sem autorização");
        }
      } catch (error) {
        OToastManager.danger("Erro ao Desbloquear o cartão, tente novamente");
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, loadCards],
  );

  const renderCardInfoItem = useCallback(
    (
      dataRef: DataRef,
      title: string,
      key: string,
      subKey: string | undefined,
      id: string,
      subTitle?: string,
    ) => {
      let info: string | undefined = "";
      if (dataRef === "cardData") {
        info = setInfoById(cardData[key as keyof typeof cardData], id, subKey);
      } else if (dataRef === "cardProgramData") {
        info = setInfoById(
          cardProgramData[key as keyof typeof cardProgramData],
          id,
          subKey,
        );
      } else if (cardInvoiceData) {
        info = setInfoById(
          cardInvoiceData[key as keyof typeof cardInvoiceData],
          id,
          subKey,
        );
      }
      if (subTitle) info = `${subTitle}: ${info}`;
      return (
        <CardInfoItem key={`card-info-${id}`}>
          <OTypography weight="500">· {title}</OTypography>
          <OTypography weight="300" className="px-3">
            {(info && capitalizePhraseFirstLetters(info)) || "-"}
          </OTypography>
        </CardInfoItem>
      );
    },
    [cardData, cardInvoiceData, cardProgramData],
  );

  const verifyCardStatus = useCallback(
    (status: CardModality) => {
      return cardData?.modality === status ||
        cardData?.modality === CardModality.Multiple
        ? "Ativado"
        : "Desativado";
    },
    [cardData],
  );

  useEffect(() => {
    if (isModalBlockOppened) {
      modalManager.show("cards-block-modal");
    } else {
      modalManager.hide("cards-block-modal");
    }
  }, [isModalBlockOppened]);

  return (
    <CardSection>
      <OConfirmationModal
        modalId={CARD_BLOCK_MODAL_ID}
        title="Confirmar bloqueio"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          processCardBlock(cardData.id, selectedModalValue);
        }}
      >
        <span>
          Tem certeza que deseja bloquear o cartão com final{" "}
          <strong>{cardData?.last4Digits}</strong> por{" "}
          <strong>
            {
              SelectModalOptions.find(
                (option) => option.value === selectedModalValue,
              )?.label
            }
          </strong>
          .
        </span>
        <strong>Essa ação não pode ser desfeita.</strong>
      </OConfirmationModal>
      <OModal
        id="cards-block-modal"
        position="center"
        onModalClose={() => {
          setIsModalBlockOppened(false);
        }}
      >
        <OModalHeader>
          <OTypography weight="bold">{`Bloquear definitivamente o cartão ${cardData?.last4Digits}`}</OTypography>
        </OModalHeader>
        <OModalBody>
          <OSelect
            dataAction="cartao-bloquear-modal:select:motivo"
            dataLabel="motivo"
            label="Selecione o motivo"
            onInput={(e) => {
              setSelectedModalValue(
                (e?.currentTarget as HTMLOSelectElement)?.value as CardStatus,
              );
            }}
          >
            {SelectModalOptions.map((item, index) => {
              return (
                <OOption
                  key={`select-modal-option-${index}`}
                  value={item.value}
                >
                  {item.label}
                </OOption>
              );
            })}
          </OSelect>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <OButton
              dataAction="cartao-bloquear-modal:botao:fechar"
              dataLabel="fechar"
              onClick={() => {
                setIsModalBlockOppened(false);
              }}
              type="tertiary"
              outline
            >
              Fechar
            </OButton>
            <OButton
              dataAction="cartao-bloquear-modal:botao:bloquear"
              dataLabel="bloquear"
              onClick={() => {
                setIsModalBlockOppened(false);
                modalManager.show(CARD_BLOCK_MODAL_ID);
              }}
              disabled={!selectedModalValue}
            >
              Bloquear
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <OConfirmationModal
        modalId={CARD_UNLOCK_MODAL_ID}
        title="Confirmar desbloqueio"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          processCardUnblock(cardData.id);
        }}
      >
        <span>
          Tem certeza que deseja desbloquear o cartão com final{" "}
          <strong>{cardData?.last4Digits}</strong>.
        </span>
      </OConfirmationModal>
      <OConfirmationModal
        modalId={CARD_BLOCK_TEMP_MODAL_ID}
        title="Confirmar bloqueio temporário"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          processCardTemporaryBlock(cardData.id);
        }}
      >
        <span>
          Tem certeza que deseja bloquear o cartão com final{" "}
          <strong>{cardData?.last4Digits}</strong> temporariamente?
        </span>
      </OConfirmationModal>
      <OConfirmationModal
        modalId={CARD_BLOCK_PREV_MODAL_ID}
        title="Confirmar bloqueio preventivo"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          processCardPreventiveBlock(cardData.id);
        }}
      >
        <span>
          Tem certeza que deseja bloquear o cartão com final{" "}
          <strong>{cardData?.last4Digits}</strong> preventivamente?
        </span>
      </OConfirmationModal>
      <OConfirmationModal
        modalId={CARD_RESET_WRONG_PASSWORD_ATTEMPTS_MODAL_ID}
        title="Confirmar redefinição de tentativas de senha incorreta do cartão"
        confirmLabel="Confirmar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          processResetWrongCardPasswordAttempts(cardData.id);
        }}
      >
        <span>
          Tem certeza que deseja redefinir as tentativas de senha incorreta do
          cartão?
        </span>
      </OConfirmationModal>
      <div>
        <TitleContainer>
          <ColumnGap rowGap={15}>
            <OTypography
              weight="bold"
              size="lg"
              className="mb-3"
              key={cardData?.last4Digits}
            >
              Cartão (Final {cardData?.last4Digits})
            </OTypography>
            <RowGap columnGap={100}>
              <span className="d-flex align-items-center gap-2">
                <OIcon category="orq" icon="orq-info" size="lg" />
                {`Status Geral - ${MapperCardStatus[cardData.status]}`}
              </span>
              <span className="d-flex align-items-center gap-2">
                <OIcon category="orq" icon="orq-tariff" size="lg" />
                {`Status Crédito - ${verifyCardStatus(CardModality.Credit)}`}
              </span>
              <span className="d-flex align-items-center gap-2">
                <OIcon category="orq" icon="orq-money" size="lg" />
                {`Status Débito -  ${verifyCardStatus(CardModality.Debit)}`}
              </span>
            </RowGap>
          </ColumnGap>

          <ColumnGap rowGap={5} alignItems={"end"}>
            <RowGap columnGap={30}>
              {showDefinitivelyBlockButton && (
                <OButton
                  dataLabel="cartoes:botao:bloquear-cartao"
                  dataAction="bloquear-cartao"
                  outline
                  bordered={false}
                  onClick={() => setIsModalBlockOppened(true)}
                >
                  Bloquear cartão definitivamente
                </OButton>
              )}
              {cardData.status === CardStatus.BlockedInternal && (
                <OButton
                  dataLabel="cartoes:botao:desbloquear-cartao"
                  dataAction="desbloquear-cartao"
                  outline
                  bordered={false}
                  onClick={() => modalManager.show(CARD_UNLOCK_MODAL_ID)}
                >
                  Desbloquear cartão
                </OButton>
              )}
              {cardData.status === CardStatus.Normal && (
                <>
                  <OButton
                    dataLabel="cartoes:botao:bloquear-cartao-preventivamente"
                    dataAction="bloquear-cartao-preventivamente"
                    outline
                    bordered={false}
                    onClick={() => modalManager.show(CARD_BLOCK_PREV_MODAL_ID)}
                  >
                    Bloquear cartão preventivamente
                  </OButton>

                  <OButton
                    dataLabel="cartoes:botao:bloquear-cartao-temporariamente"
                    dataAction="bloquear-cartao-temporariamente"
                    outline
                    bordered={false}
                    onClick={() => modalManager.show(CARD_BLOCK_TEMP_MODAL_ID)}
                  >
                    Bloquear cartão temporariamente
                  </OButton>
                </>
              )}
              <OButton
                dataLabel="cartoes:botao:bloquear-cartao"
                dataAction="bloquear-cartao"
                outline
                bordered={false}
                onClick={() =>
                  modalManager.show(CARD_RESET_WRONG_PASSWORD_ATTEMPTS_MODAL_ID)
                }
              >
                Redefinir tentativas de senha incorreta do cartão
              </OButton>
            </RowGap>
          </ColumnGap>
        </TitleContainer>
      </div>
      <PersonalCardInfoGrid>
        {PersonalCardInfo.concat(
          cardData.modality !== CardModality.Debit
            ? [
                {
                  key: "variant",
                  id: "variant",
                  label: "Variante",
                  dataRef: "cardData",
                },
                {
                  key: "dueDate",
                  id: "dueDate",
                  label: "Vencimento",
                  dataRef: "cardInvoiceData",
                },
                {
                  key: "bestTransactionDay",
                  id: "bestTransactionDay",
                  label: "M. data p/ compra",
                  dataRef: "cardInvoiceData",
                },
              ]
            : [
                {
                  key: "debitAccount",
                  subKey: "number",
                  id: "debitAccount",
                  label: "Conta vinculada",
                  dataRef: "cardData",
                },
              ],
        ).map((info) =>
          renderCardInfoItem(
            info.dataRef,
            info.label,
            info.key,
            info.subKey,
            info.id,
          ),
        )}
      </PersonalCardInfoGrid>
      <AdressesCardInfoGrid>
        {AdressesCardInfo.map((info) =>
          renderCardInfoItem(
            info.dataRef,
            "Endereço",
            info.key,
            info.subKey,
            info.id,
            info.label,
          ),
        )}
      </AdressesCardInfoGrid>
    </CardSection>
  );
};
