import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { PostAdminReceivableData } from "services/quickfin/models/requests/post-admin-operations-without-registry.request";
import { CreatedOperationsWithoutRegistryResponse } from "./intubate-operations-without-registry.types";

interface IntubateOperationsWithoutRegistryContext {
  dataReceivables: PostAdminReceivableData;
  setDataReceivables: (receivables: PostAdminReceivableData) => void;
  createOperationsHasError?: boolean;
  createOperationsLoading?: boolean;
  createdOperations?: CreatedOperationsWithoutRegistryResponse[] | undefined;
  submit: () => void;
}

const intubateOperationsWithoutRegistryContext = createContext(
  {} as IntubateOperationsWithoutRegistryContext,
);

interface IntubateOperationsWithoutRegistryProps {
  children: React.ReactNode;
}

export const IntubateOperationsWithoutRegistryProvider = ({
  children,
}: IntubateOperationsWithoutRegistryProps) => {
  const [dataReceivables, setDataReceivables] =
    useState<PostAdminReceivableData>([]);

  const {
    callService: createOperations,
    hasError: createOperationsHasError,
    loading: createOperationsLoading,
    value: createdOperations,
  } = useServiceCall(service.quickfin.createOperationsWithoutRegistry);

  const submit = useCallback(() => {
    createOperations(dataReceivables);
  }, [createOperations, dataReceivables]);

  const value = useMemo(
    () => ({
      dataReceivables,
      createOperationsHasError,
      createOperationsLoading,
      createdOperations,
      setDataReceivables,
      submit,
    }),
    [
      dataReceivables,
      createOperationsHasError,
      createOperationsLoading,
      createdOperations,
      submit,
    ],
  );

  return (
    <intubateOperationsWithoutRegistryContext.Provider value={value}>
      {children}
    </intubateOperationsWithoutRegistryContext.Provider>
  );
};

export const useIntubateOperationsWithoutRegistry = () =>
  useContext(intubateOperationsWithoutRegistryContext);
