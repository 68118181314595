import {
  modalManager,
  OButton,
  OInputText,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useState } from "react";
import { useCancelAllBankslips } from "./cancel-all-bankslips-modal.hook";
import { CancelAllBankslipsModalId } from "./cancel-all-bankslips-modal.types";

export const CancelAllBankslipsModal = () => {
  const [currentValue, setCurrentValue] = useState("");
  const { cancelAllBankslips, isLoading } = useCancelAllBankslips();

  return (
    <OModal
      id={CancelAllBankslipsModalId}
      position="center"
      size="sm"
      backdrop={!isLoading}
    >
      <OModalHeader closeButton={!isLoading}>
        <OTypography type="dark" size="lg" className="text-nowrap">
          Cancelar todos os boletos?
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-2">
            <OTypography type="dark">
              Ao prosseguir, todos os boletos dessa empresa serão cancelados.
            </OTypography>
            <OTypography type="dark">
              Essa ação é irreversível, deseja continuar?
            </OTypography>
          </div>

          <div className="d-flex flex-column gap-1">
            <OLabel htmlFor="confirmation" size="sm">
              Digite &quot;CONFIRMAR&quot; para confirmar a operação
            </OLabel>
            <OInputText
              id="confirmation"
              name="confirmation"
              onInput={({ currentTarget: { value } }) => {
                setCurrentValue(value ?? "");
              }}
              value={currentValue}
            />
          </div>
        </div>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            outline
            onClick={() => modalManager.hide(CancelAllBankslipsModalId)}
          >
            Voltar
          </OButton>
          <OButton
            onClick={cancelAllBankslips}
            disabled={currentValue !== "CONFIRMAR"}
          >
            Cancelar boletos
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
