import { parseExcelDate } from "utils/parse-excel-date";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";

export const parseSpreadsheetAditamento = (buffer: FileReader["result"]) => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return sheetData.map((row: any) => ({
    type: "Aditamento",
    tradeId: row.TradeId,
    newMaturityAmount: row.ValorVencimentoAtualizado
      ? row.ValorVencimentoAtualizado
      : null,
    newMaturityDate: row.VencimentoAtualizado
      ? parseExcelDate(row.VencimentoAtualizado)
      : null,
    tradeDate: row.DataTrade ? parseExcelDate(row.DataTrade) : null,
    settlementDate: row.DataLiquidacao
      ? parseExcelDate(row.DataLiquidacao)
      : null,
  }));
};

export const parseSpreadsheetCorrecao = (buffer: FileReader["result"]) => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return sheetData.map((row: any) => ({
    type: "Correcao",
    Identification: row.NumeroNota ?? null,
    tradeId: row.TradeId ? row.TradeId : "",
    IdFcc: row.FCC ? row.FCC : null,
    AccountDigit: row.ContaDigito ? row.ContaDigito : "",
    AccountNumber: row.ContaNumero ? row.ContaNumero : "",
    AccountType: row.ContaTipo ? row.ContaTipo : "",
    Agency: row.Agencia ? row.Agencia : "",
    BankCode: row.CodigoBanco ? row.CodigoBanco : null,
    BookCode: row.BookCode ? row.BookCode : "",
    CollateralIdentification: row.CodigoGarantia ? row.CodigoGarantia : "",
    ContractIdentification: row.ContratoReferencia
      ? row.ContratoReferencia
      : "",
    HasHedge: row.HasHedge ? row.HasHedge : "",
    HolderTaxId: row.HolderTaxId ? row.HolderTaxId : "",
    PixKey: row.ChavePix ? row.ChavePix : "",
    PixType: row.TipoPix ? row.TipoPix : "",
    Iof: row.Iof ? row.Iof : null,
    SettlementBankCode: row.LiquidacaoCodBanco ? row.LiquidacaoCodBanco : null,
    SettlementAgency: row.LiquidacaoAgencia ? row.LiquidacaoAgencia : null,
    SettlementAccountNumber: row.LiquidacaoNumeroConta
      ? row.LiquidacaoNumeroConta
      : "",
    SettlementAccountDigit: row.LiquidacaoContaDigito
      ? row.LiquidacaoContaDigito
      : "",
    SettlementAgencyDigit: row.LiquidacaoAgenciaDigito
      ? row.LiquidacaoAgenciaDigito
      : "",
    SettlementHolderTaxId: row.LiquidacaoHolderTaxId
      ? row.LiquidacaoHolderTaxId
      : "",
    SettlementIsConsolidated: row.LiquidacaoConsolidado
      ? row.LiquidacaoConsolidado
      : null,
    SettlementMethodCode: row.LiquidacaoCodigoMetodo
      ? row.LiquidacaoCodigoMetodo
      : null,
    SettlementLockInfo: row.LiquidacaoLockInfo ? row.LiquidacaoLockInfo : null,
    SettlementParty: row.LiquidacaoParty ? row.LiquidacaoParty : null,
    SettlementLockRefCge: row.LiquidacaoLockRefCge
      ? row.LiquidacaoLockRefCge
      : null,
    SettlementIsPrimary: row.LiquidacaoContaPrimaria
      ? row.LiquidacaoContaPrimaria
      : null,
    tradeDate: row.DataTrade ? parseExcelDate(row.DataTrade) : null,
    RegistrationNumber: row.NumeroRegistro ? row.NumeroRegistro : null,
  }));
};
