import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { LimitGuarantorsItem } from "services/hubcreditmanager/models/responses";

export const grid: ODataGridGeneratorConfig<LimitGuarantorsItem> = {
  datagrid: {
    noDataText: "Nenhum avalista encontrado",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} avalistas)",
    },
  },
  columns: [
    {
      caption: "CPF/CNPJ",
      cellRender: ({ data }) => masks.cpf(data.guarantor?.legalEntity?.taxId),
    },
    {
      caption: "Nome",
      dataField: "guarantor.legalEntity.name",
    },
    {
      caption: "Tipo",
      dataField: "guarantor.type",
    },
    {
      caption: "Status",
      dataField: "guarantor.status",
    },
    {
      caption: "Ativo",
      cellRender: ({ data }) =>
        data.guarantor?.isActive === true ? "Sim" : "Não",
    },
  ],
};
