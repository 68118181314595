import { getStatusFromMap, MappedStatus } from "../../utils";
import { AugmentedStatusBadge } from "../augmented-status-badge";

interface MappedStatusBadgeProps {
  map: Record<string, MappedStatus>;
  status: string | null | undefined;
}

export const MappedStatusBadge = ({ map, status }: MappedStatusBadgeProps) => {
  const mappedStatus = getStatusFromMap(map, status);

  return (
    <AugmentedStatusBadge type={mappedStatus.type} className="d-inline-block">
      {mappedStatus.text}
    </AugmentedStatusBadge>
  );
};
