import { OCard, OCardBody, OCol, ORadio, ORow } from "@maestro/react";
import { useCallback } from "react";
import { useSimulation } from "../simulation/simulation.context";
import { DisbursementInstruction } from "../simulation/simulation.types";
import { getAccountCards } from "./bank-account.utils";

interface AccountCardsProps {
  disbursementInstructions: DisbursementInstruction[];
}

export const AccountCards = ({
  disbursementInstructions,
}: AccountCardsProps) => {
  const { selectedBank, bankAccountsList, setSelectedBank } = useSimulation();

  const handleChangeBank = useCallback(
    (value: string) => {
      const selectedBankAccount = bankAccountsList.find(
        (b) => b.id.toString() === value,
      );

      if (selectedBankAccount) {
        setSelectedBank(selectedBankAccount);
      }
    },
    [bankAccountsList, setSelectedBank],
  );

  return (
    <>
      {!disbursementInstructions?.length
        ? bankAccountsList?.map((account, index: number) => (
            <OCard key={`card-account-${account.id}`} className="w-30">
              <OCardBody>
                <ORow>
                  {getAccountCards(account, account.accountNumber)}
                  <OCol sm={2}>
                    <ORadio
                      value={account.id.toString()}
                      checked={selectedBank?.id === account.id}
                      onClick={() => handleChangeBank(account.id.toString())}
                      id={`radio-account${index}`}
                    />
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
          ))
        : disbursementInstructions?.map((account) => (
            <OCard
              className="w-30"
              key={`${account.account}-${account.agency}`}
            >
              <OCardBody>
                <ORow>
                  {getAccountCards(
                    account,
                    account.account,
                    account.holderName,
                    account.amount,
                  )}
                </ORow>
              </OCardBody>
            </OCard>
          ))}
    </>
  );
};
