import {
  OCol,
  OIcon,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { UseFieldArrayReturn, UseFormWatch } from "react-hook-form";
import { service } from "services";
import { EditTaxasForm } from "../../edit-taxas.types";

interface TermFieldProps {
  fieldArray: UseFieldArrayReturn<EditTaxasForm, "taxaPrazos", "id">;
  fieldType: "taxa" | "spread" | "diMais";
  index: number;
  label: string;
  useDatesForTerm: boolean;
  watch: UseFormWatch<EditTaxasForm>;
}

export const TermField = ({
  fieldType,
  fieldArray,
  index,
  label,
  useDatesForTerm,
  watch,
}: TermFieldProps) => {
  const { callService: removeTaxaPrazo, loading } = useServiceCall(
    service.senna.removeTaxaPrazo,
  );

  const id = watch(`taxaPrazos.${index}.id`);
  const rawValue = watch(`taxaPrazos.${index}.${fieldType}`);
  const percentValue = rawValue ? `${rawValue}%` : undefined;

  const { remove } = fieldArray;

  return (
    <div className="d-flex align-items-center gap-4 flex-fill">
      <ORow className="flex-fill">
        {useDatesForTerm ? (
          <>
            <OCol xs={4}>
              <ORFieldInputDate
                allowClear
                id={`taxaPrazos.${index}.fromDate`}
                name={`taxaPrazos.${index}.fromDate`}
                label="De data"
                picker="date"
                disabledDate={(date) => date.isBefore()}
                size="md"
              />
            </OCol>
            <OCol xs={4}>
              <ORFieldInputDate
                allowClear
                id={`taxaPrazos.${index}.toDate`}
                name={`taxaPrazos.${index}.toDate`}
                label="Até data"
                picker="date"
                disabledDate={(date) => date.isBefore()}
                size="md"
              />
            </OCol>
          </>
        ) : (
          <>
            <OCol xs={3}>
              <ORFieldInput
                dataAction="campo_prazos:texto:de_prazo"
                dataLabel="de_prazo"
                id={`taxaPrazos.${index}.fromDay`}
                name={`taxaPrazos.${index}.fromDay`}
                tag="number"
                placeholder="1"
                label="De prazo"
              />
            </OCol>
            <OCol xs={3}>
              <ORFieldInput
                dataAction="campo_prazos:texto:ate_prazo"
                dataLabel="ate_prazo"
                id={`taxaPrazos.${index}.toDay`}
                name={`taxaPrazos.${index}.toDay`}
                tag="number"
                placeholder="1"
                label="Até prazo"
              />
            </OCol>
            <OCol xs={3}>
              <ORFieldInput
                dataAction="campo_prazos:texto:pnl_strategy_override"
                dataLabel="pnl_strategy_override"
                id={`taxaPrazos.${index}.pnlStrategyOverride`}
                name={`taxaPrazos.${index}.pnlStrategyOverride`}
                tag="number"
                label="PNL Estratégia"
              />
            </OCol>
          </>
        )}

        <OCol xs={2}>
          <ORFieldInput
            dataAction={`campo_prazos:texto:${fieldType}`}
            dataLabel={fieldType}
            id={`taxaPrazos.${index}.${fieldType}`}
            name={`taxaPrazos.${index}.${fieldType}`}
            tag="number"
            placeholder="0"
            label={label}
            description={percentValue}
          />
        </OCol>
      </ORow>
      <LoadingButton
        dataAction="campo_prazos:botao:remover"
        dataLabel="remover"
        loading={id !== undefined && loading}
        type="danger"
        onClick={async () => {
          if (id !== undefined) {
            const { success } = await removeTaxaPrazo(id);
            if (!success) {
              OToastManager.danger("Não foi possível remover a taxa");
              return;
            }
            OToastManager.success("Taxa removida com sucesso");
          }
          remove(index);
        }}
      >
        <OIcon category="fa" icon="fa-trash" />
      </LoadingButton>
    </div>
  );
};
