import * as yup from "yup";
import { NewAutoEmailForm } from "./new-auto-email.types";

export const newAutoEmailDefaulValues = {
  companyId: null,
  emailType: "",
  sendToOL: "no",
  sendToLegalRepresentative: "no",
  sendToOperators: "no",
} satisfies Record<keyof NewAutoEmailForm, unknown> as any;

export const newAutoEmailValidationSchema = yup.object({
  companyId: yup
    .number()
    .typeError("Digite um valor numérico")
    .required("É necessário selecionar uma empresa"),
  emailType: yup
    .string()
    .trim()
    .required("É necessário selecionar o tipo de e-mail"),
});
