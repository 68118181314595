import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { OnboardingUpdateByUUIDRouteParams } from "../../../../../routes/cadastro.route-params";
import { buildGrid } from "./atualizacao-cadastral-logs.grid";

export const DataUpdateLogs = () => {
  const { uuid } = useParams<OnboardingUpdateByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid in route parameters");

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.onboarding.getDataUpdateLogs(uuid),
      ),
    [uuid],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Logs" description={`Atualização id: ${uuid}`} />
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
