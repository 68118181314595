import { modalManager } from "@maestro/core";
import { OButton, OCard, OCardBody, OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { OriginatorInfoFields } from "../../../../components/originator";
import { SyncCnpjConfirmationModal } from "./_compose";
import { useUpdateOriginatorInfo } from "./update-originator-info.hook";

export const UpdateOriginatorInfo = () => {
  const { form, isCNPJ, loading, loadingSubmit, onSubmit } =
    useUpdateOriginatorInfo();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do Originador" />}
      actions={
        <LoadingButton loading={loading || loadingSubmit} onClick={onSubmit}>
          Atualizar
        </LoadingButton>
      }
    >
      <DetailsTemplate
        actions={
          <OButton
            onClick={() => modalManager.show("sync-cnpj-confirmation-modal-id")}
            disabled={!isCNPJ}
          >
            Sincronizar razão social
          </OButton>
        }
      >
        <FormProvider {...form}>
          <OCard>
            <OCardBody>
              {loading ? <OLoader /> : <OriginatorInfoFields isEditing />}
            </OCardBody>
          </OCard>
          <SyncCnpjConfirmationModal />
        </FormProvider>
      </DetailsTemplate>
    </FormTemplate>
  );
};
