export const emailActivationBadgeMap = {
  EM_ANDAMENTO: {
    label: "Em andamento",
    type: "warning",
  },
  CONCLUIDO: {
    label: "Concluído",
    type: "success",
  },
} as const;

export const emailActivationTooltipMap = {
  EM_ANDAMENTO: "Em processo de preenchimento do formulário de onboarding",
  CONCLUIDO: "Ativação concluída",
};
