import { useServiceCall } from "hooks/service-call";
import { useParams } from "react-router-dom";
import { service } from "services";
import { useCallback, useEffect, useMemo } from "react";
import { AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes } from "../../../../../routes/antecipacao-de-cartoes.route-params";
import { useConciliacaoDetailsContext } from "./extrato-liquidacao-posicao-details.context";
import { findStatus } from "./helpers";

export const useExtratoLiquidacaoPosicaoDetails = () => {
  const { maturityDate } =
    useParams<AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes>();
  if (!maturityDate) throw new Error("No url parameters");
  const { maturityFilterDate } = useConciliacaoDetailsContext();

  const {
    callService: callConciliacaoDetail,
    hasError: hasErrorConciliacaoDetail,
    loading: loadingConciliacaoDetail,
    value: conciliacaoDetails,
  } = useServiceCall(service.recebiveisCartao.getConciliacaoDetail);

  const getConciliacaoDetail = useCallback(
    () => callConciliacaoDetail(maturityFilterDate || maturityDate),
    [callConciliacaoDetail, maturityDate, maturityFilterDate],
  );

  const {
    callService: callTradesPaymentsDetail,
    hasError: hasErrorTradesPaymentsDetail,
    loading: loadingTradesPaymentsDetail,
    value: tradesPaymentsDetails,
  } = useServiceCall(service.quickfin.getTradesPayments);

  const getTradesPaymentsDetail = useCallback(
    () => callTradesPaymentsDetail(maturityFilterDate || maturityDate),
    [callTradesPaymentsDetail, maturityDate, maturityFilterDate],
  );

  useEffect(() => {
    getConciliacaoDetail();
    getTradesPaymentsDetail();
  }, [getConciliacaoDetail, getTradesPaymentsDetail]);

  const extratoInfo = useMemo(() => {
    return {
      maturityAmountSum: conciliacaoDetails?.reduce(
        (acc, slc) => acc + Number(slc.soma_valor_trades),
        0,
      ),
      disbursementAmountSum: conciliacaoDetails?.reduce(
        (acc, slc) => acc + Number(slc.soma_valor_pagamento),
        0,
      ),
      liquidatedAmountSum: tradesPaymentsDetails?.reduce(
        (acc, extrato) => acc + Number(extrato.instructedAmount),
        0,
      ),
      balanceAmountSum: conciliacaoDetails?.reduce(
        (acc, slc) => acc + Number(slc.soma_valor_balance),
        0,
      ),
      feeAmountSum: conciliacaoDetails?.reduce(
        (acc, slc) => acc + Number(slc.soma_encargos),
        0,
      ),
      status: findStatus(conciliacaoDetails),
    };
  }, [conciliacaoDetails, tradesPaymentsDetails]);

  return {
    getConciliacaoDetail,
    getTradesPaymentsDetail,
    hasErrorConciliacaoDetail,
    hasErrorTradesPaymentsDetail,
    loadingConciliacaoDetail,
    loadingTradesPaymentsDetail,
    conciliacaoDetails,
    tradesPaymentsDetails,
    extratoInfo,
  };
};
