import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { guarantorTypeMap } from "./overdraft-guarantors.utils";

export const overdraftGuarantorGrid: ODataGridGeneratorConfig<BankingHub.AnalysisGuarantor> =
  {
    datagrid: {
      noDataText: "Nenhum avalista",
    },
    columns: [
      {
        dataField: "document",
        caption: "CPF",
        format: (value) => masks.cpf(value),
      },
      {
        dataField: "type",
        caption: "Tipo do avalista",
        customizeText: ({ valueText }) =>
          getValueFromMap(guarantorTypeMap, valueText) ?? valueText,
      },
    ],
  };
