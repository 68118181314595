import * as yup from "yup";

export const propertyModalFormValidationSchema = yup.object().shape({
  type: yup.string().nullable().trim().required("Campo obrigatório"),
  description: yup.string().nullable().trim().required("Campo obrigatório"),
  name: yup
    .string()
    .nullable()
    .trim()
    .required("Campo obrigatório")
    .matches(
      /^[^`~!@#$%^&*()+-={}[\]|\\:;“’<,>.?๐฿ ç]*$/,
      "O campo não deve conter caracteres especiais ou espaços. Utilize _ como separador",
    )
    .uppercase("O tipo do componente deve ser em maiúsculo"),
  allowedValues: yup
    .string()
    .nullable()
    .trim()
    .test(
      "nospaces",
      "Não pode conter espaços",
      (value) => !value?.includes(" ") ?? false,
    ),
  isRequired: yup.array().default([]),
});

export const propertyModalFormDefaultValues = {
  type: "",
  description: "",
  name: "",
  allowedValues: "",
  // isRequired: 0,
  isRequired: [] as string[],
};
