import { NodeInfoContent } from "../../node-inspector.styles";
import { NodeInfoProps } from "../../node-inspector.types";

export const NodeInfo = ({
  id,
  type,
  selected,
  x,
  y,
  width,
  height,
  data,
}: NodeInfoProps) => {
  if (!width || !height) return null;

  return (
    <NodeInfoContent
      transform={`translate(${x}px, ${y + height}px)`}
      width={width * 2}
    >
      <div>id: {id}</div>
      <div>type: {type}</div>
      <div>selected: {selected ? "true" : "false"}</div>
      <div>
        position: {x.toFixed(1)}, {y.toFixed(1)}
      </div>
      <div>
        dimensions: {width} × {height}
      </div>
      <div>data: {JSON.stringify(data, null, 2)}</div>
    </NodeInfoContent>
  );
};
