import {
  modalManager,
  OConfirmationModal,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";

export const RemoveComponentModal = () => {
  const [loading, setLoading] = useState(false);
  const {
    form: { callService: getForm, value: workflowFormTemplate },
    selectedComponent,
  } = useWorkflowFormTemplate();

  const { hide } = modalManager;

  const removeComponent = useCallback(async () => {
    try {
      if (!selectedComponent) return;

      setLoading(true);

      await service.hubCreditManager.removeFormStepCardComponent({
        formStepCardComponentId: selectedComponent.id,
      });

      OToastManager.success("Componente removido com sucesso!");
      hide("remove-component-modal");
      workflowFormTemplate && getForm(workflowFormTemplate.id);
    } catch {
      OToastManager.danger(
        "Não foi possível remover componente. Tente novamente mais tarde.",
      );
    } finally {
      setLoading(false);
    }
  }, [getForm, hide, selectedComponent, workflowFormTemplate]);

  return (
    <OConfirmationModal
      modalId="remove-component-modal"
      title="Tem certeza que deseja excluir este componente?"
      onConfirm={removeComponent}
      loading={loading}
    >
      <OTypography size="md">
        Ao confirmar a exclusão, não será possível reverter a ação.
      </OTypography>
    </OConfirmationModal>
  );
};
