import { OToastManager } from "@maestro/core";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCheckbox,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PutApiAdminScoreBenefitUpdateBody } from "services/hubcreditmanager/models/requests";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";

type ScoreBenefitFormProps = {
  id?: string | undefined;
  mode: "add" | "edit";
};

interface UpsertScoreBenefitForm {
  name: string;
  description: string;
  type: string;
  [key: `${string}Description`]: string;
  [key: `${string}IsActive`]: string[];
}

export const ScoreBenefitForm = ({ id, mode }: ScoreBenefitFormProps) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    callService: getScoreBenefit,
    value: scoreBenefit,
    loading: fetchLoading,
  } = useServiceCall(service.hubCreditManager.getScoreBenefitById);

  const {
    callService: getScoreLevels,
    value: scoreLevels,
    loading: levelsLoading,
  } = useServiceCall(service.hubCreditManager.getScoreLevels);

  const { handleSubmit, trigger, reset, setValue } =
    useFormContext<UpsertScoreBenefitForm>();

  const upsertScoreModule = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          const isValid = await trigger();

          if (!isValid) return;

          setLoading(true);

          const payload: PutApiAdminScoreBenefitUpdateBody = {
            id: Number(id),
            name: values.name,
            description: values.description,
            type: values.type,
            benefitLevels:
              scoreLevels?.map((level) => ({
                description: values[`${level.type}Description`],
                isActive: !!values[`${level.type}IsActive`]?.length,
                levelId: level.id,
              })) ?? [],
          };

          if (mode === "add") {
            await service.hubCreditManager.createScoreBenefit(payload);
          } else {
            await service.hubCreditManager.updateScoreBenefit(payload);
          }
          OToastManager.success("Benefício salvo com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.originatorScore.benefits
              .path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar o benefício.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, mode, navigate, scoreLevels, trigger],
  );

  useEffect(() => {
    if (mode !== "edit" || !id) return;

    getScoreBenefit(id);
  }, [getScoreBenefit, id, mode]);

  useEffect(() => {
    if (!scoreBenefit || !scoreLevels?.length) return;

    reset({
      name: scoreBenefit.name,
      description: scoreBenefit.description,
      type: scoreBenefit.type,
    });

    scoreLevels.forEach((level) => {
      const benefitLevel = scoreBenefit.benefitLevels.find(
        (b) => b.level?.type === level?.type,
      );

      setValue(`${level.type}Description`, benefitLevel?.description || "");
      setValue(`${level.type}IsActive`, benefitLevel?.isActive ? ["1"] : []);
    });
  }, [reset, scoreBenefit, scoreLevels, setValue]);

  useEffect(() => {
    getScoreLevels();
  }, [getScoreLevels]);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          type="info"
          onClick={upsertScoreModule}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {(fetchLoading || levelsLoading) && <OLoader backdrop absolute />}
        <ORFieldInput
          id="name"
          name="name"
          tag="text"
          label="Nome"
          labelSize="md"
        />
        <ORFieldInput
          id="type"
          name="type"
          tag="text"
          label="Tipo"
          labelSize="md"
        />
        <ORFieldInput
          id="description"
          name="description"
          tag="textarea"
          label="Descrição"
          labelSize="md"
          aspect="outline"
        />

        <OTypography size="lg" className="mb-4 mt-3">
          Níveis
        </OTypography>

        <div className="d-flex gap-2 w-100">
          {scoreLevels?.map((level) => (
            <OCard key={level.type}>
              <OCardHeader>
                <OTypography size="lg" key={level.name}>
                  {level.name}
                </OTypography>
              </OCardHeader>
              <OCardBody>
                <ORFieldCheckboxGroup
                  id={`${level.type}IsActive`}
                  name={`${level.type}IsActive`}
                >
                  <div className="d-flex align-items-center gap-2">
                    <OCheckbox size="xs" id="is-active" value="1" />
                    <OLabel htmlFor="is-active" type="dark-80">
                      Ativo?
                    </OLabel>
                  </div>
                </ORFieldCheckboxGroup>
                <ORFieldInput
                  key={`${level.type}Description`}
                  id={`${level.type}Description`}
                  name={`${level.type}Description`}
                  tag="text"
                  label="Descrição"
                  labelSize="md"
                />
              </OCardBody>
            </OCard>
          ))}
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
