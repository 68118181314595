import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { percent4DigitsMask } from "utils/mask";
import { InstallmentPlanStatusBadge } from "./_compose";

export const overdraftInstallmentsGrid: ODataGridGeneratorConfig<BankingHub.InstallmentPlanDetails> =
  {
    datagrid: {
      noDataText: "Nenhum avalista",
      pager: {
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        dataField: "processedAt",
        caption: "Data de contratação",
        sortOrder: "desc",
        format: (value) => format.date(value),
      },
      {
        caption: "Data do primeiro pagamento",
        cellRender: ({ data }) =>
          format.date(data.installments?.[0]?.maturityDate) ?? "-",
      },
      {
        dataField: "settlementDay",
        caption: "Data de pagamento",
        format: (value) => `Dia ${value}`,
      },
      {
        dataField: "contractNumber",
        caption: "Nº Contrato",
      },
      {
        dataField: "totalAmount",
        caption: "Valor solicitado",
        format: (value) => masks.currency(value ?? 0, currencySymbol.BRL, "."),
      },
      {
        caption: "Valor das parcelas",
        cellRender: ({ data }) =>
          masks.currency(
            data.installments?.[0]?.amount ?? 0,
            currencySymbol.BRL,
            ".",
          ),
      },
      {
        dataField: "interestRate",
        caption: "Taxa de juros (a.m.)",
        format: (value) => percent4DigitsMask(value ?? 0),
      },
      {
        dataField: "transactionTax",
        caption: "IOF",
        format: (value) => masks.currency(value ?? 0, currencySymbol.BRL, "."),
      },
      {
        dataField: "totalEffectiveCostRate",
        caption: "Taxa CET (a.a.)",
        format: (value) => percent4DigitsMask(value ?? 0),
      },
      {
        caption: "Parcelas pagas",
        cellRender: ({ data }) =>
          `${data.paidInstallments ?? 0}/${data.totalInstallments ?? 0}`,
      },
      {
        caption: "Status",
        cellRender: ({ data }) => (
          <InstallmentPlanStatusBadge installmentPlan={data} />
        ),
      },
    ],
  };
