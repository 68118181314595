import { modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { getPropertyLabel } from "../../../../../../../../../../utils";

export const sendToPaperclipConfirmationModalId = (id: number | string) =>
  `send-to-paperclip-confirmation-modal-${id}`;

interface SendToPaperclipConfirmationModalProps {
  property: HubEnergy.DiligenceStepPropertySimpleResponse;
  onConfirm: () => void;
}

export const SendToPaperclipConfirmationModal = ({
  property,
  onConfirm,
}: SendToPaperclipConfirmationModalProps) => {
  const modalId = sendToPaperclipConfirmationModalId(property.id);
  const filename = property.document.files?.[0]?.name;

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Enviar para assinatura"
      onConfirm={() => {
        modalManager.hide(modalId);
        onConfirm();
      }}
      divider={false}
      {...datagridModalFix}
    >
      <OTypography style={{ textAlign: "start", whiteSpace: "normal" }}>
        Tem certeza que deseja enviar o documento{" "}
        <strong>{getPropertyLabel(property)}</strong>
        {filename && `(${filename})`} para assinatura?
      </OTypography>
    </OConfirmationModal>
  );
};
