import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, sacadoTierConfigsGrid } from "./sacado-tier.grid";

export const SacadoTier = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Tier Mínimo do Sacado"
          description="Defina o tier mínimo que um sacado pode assumir de acordo com a análise de risco."
        />
      }
      actions={
        <StandardLink
          href={
            corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento
              .sacadoTier.add.path
          }
          role={
            roles.antecipacaoDeCartoes.product.enquadramentoSacadoTierAddPage
              .role
          }
        >
          Adicionar Configuração
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={sacadoTierConfigsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
