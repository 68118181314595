import { BankIconSVG } from "../financial-institution-icon.types";

export const BankBradescoSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#CC092F" />
      <path
        d="M50.743 83.586v-12.84c0-.472.223-.795.571-1.02l3.553-2.04v16.472h-3.553a.563.563 0 01-.571-.572z"
        fill="#fff"
      />
      <path
        d="M43.289 40.191c3.752-.647 7.578-.97 11.056-.97 17.666 0 31.63 6.668 31.655 15.278 0 6.37-7.404 11.645-17.169 14.854-.696.224-1.317.05-1.49-.497-.2-.573-.05-.996 1.291-1.593 5.59-2.488 10.287-6.196 10.287-9.903 0-7.316-14.287-12.914-31.208-12.914-2.608 0-4.969.15-7.155.323-1.069 2.439-1.615 5.151-1.615 7.938 0 5.524 2.335 11.122 6.51 14.88.546.497.596.97.223 1.468-.373.522-.944.522-1.59.1-6.585-4.156-10.858-11.77-10.684-19.782 0-1.244.149-2.439.372-3.583-2.186.472-4.298.97-6.335 1.567-.646.2-1.119 0-1.342-.498-.249-.547 0-.995.646-1.368 2.385-1.319 5.069-2.414 8-3.334 3.206-8.684 11.604-14.98 21.518-15.154 7.578-.124 13.988 3.31 18.361 8.386.522.597.572 1.07.1 1.517-.472.448-.945.399-1.54-.099-3.355-2.812-8.325-4.678-13.294-4.678-6.733 0-12.796 3.16-16.597 8.062z"
        fill="#fff"
      />
      <path
        d="M56.457 66.766l4.721-2.713c.572-.323 1.243-.05 1.243.622v18.563a.891.891 0 01-.895.896h-5.069V66.766z"
        fill="#fff"
      />
    </svg>
  );
};
