import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

export const removePropertyTypeConfirmationModalId = (id: number) =>
  `remove-property-type-confirmation-modal-${id}`;

export interface RemovePropertyTypeConfirmationModalProps {
  propertyType: HubEnergy.DiligenceStepPropertyTypeResponse;
  onConfirm: () => void;
}

export const RemovePropertyTypeConfirmationModal = ({
  propertyType,
  onConfirm,
}: RemovePropertyTypeConfirmationModalProps) => {
  const modalId = removePropertyTypeConfirmationModalId(propertyType.id);

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Remover tipo de propriedade"
      divider={false}
      onConfirm={() => {
        onConfirm();
        modalManager.hide(modalId);
      }}
      {...datagridModalFix}
    >
      <OTypography size="md" style={{ whiteSpace: "normal" }}>
        Tem certeza que deseja remover o tipo de propriedade {propertyType.name}
        ?
      </OTypography>
    </OConfirmationModal>
  );
};
