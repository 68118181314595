import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import {
  FormStep,
  FormStepCard,
  FormStepCardComponent,
} from "services/hubcreditmanager/models/types/workflow/form.types";

export interface WorkflowFormTemplateTemplateContextProps {
  selectedStep?: FormStep;
  setSelectedStep: React.Dispatch<React.SetStateAction<FormStep | undefined>>;
  selectedCard?: FormStepCard;
  setSelectedCard: React.Dispatch<
    React.SetStateAction<FormStepCard | undefined>
  >;
  selectedComponent?: FormStepCardComponent;
  setSelectedComponent: React.Dispatch<
    React.SetStateAction<FormStepCardComponent | undefined>
  >;
  formsList: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getFormsList>,
      Awaited<ReturnType<typeof service.hubCreditManager.getFormsList>>["data"]
    >
  >;
  form: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getFormDetails>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getFormDetails>
      >["data"]
    >
  >;
  components: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponents>,
      Awaited<ReturnType<typeof service.hubCreditManager.getComponents>>["data"]
    >
  >;
}

export const WorkflowFormTemplateContext = createContext(
  {} as WorkflowFormTemplateTemplateContextProps,
);

interface WorkflowFormTemplateProviderProps {
  children: ReactNode;
}

export const WorkflowFormTemplateProvider = ({
  children,
}: WorkflowFormTemplateProviderProps) => {
  const [selectedStep, setSelectedStep] = useState<FormStep>();
  const [selectedCard, setSelectedCard] = useState<FormStepCard>();
  const [selectedComponent, setSelectedComponent] =
    useState<FormStepCardComponent>();

  const formsList = useServiceCall(service.hubCreditManager.getFormsList);
  const form = useServiceCall(service.hubCreditManager.getFormDetails);
  const components = useServiceCall(service.hubCreditManager.getComponents);

  const { hasError: hasErrorFormList } = formsList;
  const { hasError: hasErrorForm, value: formValue } = form;
  const { hasError: hasErrorComponents } = components;

  useEffect(() => {
    if (!formValue) return;

    if (selectedStep)
      setSelectedStep(formValue.steps.find((s) => s.id === selectedStep.id));

    if (selectedCard)
      setSelectedCard(
        formValue.steps
          .flatMap((s) => s.cards)
          .find((s) => s.id === selectedCard.id),
      );
  }, [formValue, selectedCard, selectedStep]);

  useEffect(() => {
    hasErrorFormList &&
      OToastManager.danger("Não foi possível buscar os formulários");
  }, [hasErrorFormList]);

  useEffect(() => {
    hasErrorForm &&
      OToastManager.danger("Não foi possível buscar detalhes do formulário.");
  }, [hasErrorForm]);

  useEffect(() => {
    hasErrorComponents &&
      OToastManager.danger("Não foi possível buscar componentes.");
  }, [hasErrorComponents]);

  const value = useMemo(
    () => ({
      formsList,
      form,
      components,
      selectedStep,
      setSelectedStep,
      selectedCard,
      setSelectedCard,
      selectedComponent,
      setSelectedComponent,
    }),
    [
      formsList,
      form,
      components,
      selectedStep,
      selectedCard,
      selectedComponent,
    ],
  );

  return (
    <WorkflowFormTemplateContext.Provider value={value}>
      {children}
    </WorkflowFormTemplateContext.Provider>
  );
};

export const useWorkflowFormTemplate = () =>
  useContext(WorkflowFormTemplateContext);
