import { OLoader } from "@maestro/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";

export const Impersonate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(corporateRouter.routes.workflowRmAdmin.product.path);
  }, [navigate]);

  return <OLoader absolute data-component="Impersonate" />;
};
