import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { Trade } from "./trades.types";

export const warnLowBalance = (e: CellPreparedEvent<Trade>) => {
  if (!e?.data?.maturity_amount || !e?.data?.balance) return;
  if (parseFloat(e.data.maturity_amount) > parseFloat(e.data.balance)) {
    e.cellElement.style.backgroundColor = "var(--theme-warning)";
    e.cellElement.style.color = "var(--theme-warning-inverse)";
  }
};
