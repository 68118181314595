import { OConfirmationModal } from "@maestro/react";
import { ActiveDevice } from "../../atividades-login.types";

interface ConfirmBlockModalProps {
  device?: ActiveDevice;
  blockDevice?: () => Promise<void>;
}

export const ConfirmBlockModal = ({
  device,
  blockDevice,
}: ConfirmBlockModalProps) => {
  return (
    <OConfirmationModal
      modalId={`confirm-blocking-modal-${device?.sessionTokenId}`}
      title="Tem certeza que deseja bloquear esse dispositivo?"
      divider
      backdrop={false}
      onConfirm={blockDevice}
    />
  );
};
