import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { service } from "services";
import { PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse } from "services/quickfin/models";

export const useVerifyTaxas = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationResults, setValidationResults] =
    useState<PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse>();

  const verifyTaxas = useCallback(
    async (...args: Parameters<typeof service.quickfin.verifyTaxasFile>) => {
      try {
        setLoading(true);

        await service.quickfin.verifyTaxasFile(...args);

        setValidationResults([]);
        setHasError(false);
      } catch (err) {
        let _hasError = true;
        if (
          isAxiosError<PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse>(
            err,
          ) &&
          err.response?.status === 400 &&
          err.response.data
        ) {
          _hasError = false;
          setValidationResults(err.response?.data);
        }
        setHasError(_hasError);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const clear = useCallback(() => {
    setLoading(false);
    setHasError(false);
    setValidationResults(undefined);
  }, []);

  return {
    clear,
    hasError,
    loading,
    validationResults,
    verifyTaxas,
  };
};
