import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GatekeeperRoleForm } from "../_compose";
import { useGatekeeperRolesAdd } from "./add.hook";

export const GatekeeperRolesAdd = () => {
  const { submit, form, loading } = useGatekeeperRolesAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Roles Gatekeeper"
          description="Adicione uma role ao Gatekeeper"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="atualizar_role:botao:criar_role"
          dataLabel="criar_role"
          onClick={submit}
        >
          Criar role
        </LoadingButton>
      }
    >
      <CardTemplate>
        <GatekeeperRoleForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
