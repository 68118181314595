import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByDistributorIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import {
  DistributorConfigForm,
  distributorConfigFormDefaultValues,
  distributorConfigFormValidationSchema,
} from "../../_compose";

export const useDistributorConfiguration = () => {
  const { distributorId } = useParams<MleByDistributorIdRouteParams>();
  if (!distributorId) throw new Error("No distributorId");

  const {
    callService: _getDistributorDetails,
    loading: fetchLoading,
    error: fetchError,
    hasError: fetchHasError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDistributorDetails);
  const { callService: _updateDistributorConfig, loading: submitLoading } =
    useServiceCall(service.hubEnergy.updateDistributorConfig);

  const form = useForm<DistributorConfigForm>({
    resolver: yupResolver(distributorConfigFormValidationSchema),
    defaultValues: distributorConfigFormDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const getDistributor = useCallback(
    async () => _getDistributorDetails(distributorId),
    [_getDistributorDetails, distributorId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _updateDistributorConfig({
          distributorId: Number(distributorId),
          ...values,
        });

        if (success) {
          OToastManager.success(
            "Configuração da distribuidora atualizada com sucesso",
          );
        } else
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Não foi possível atualizar a configuração da distribuidora",
          );
      }),
    [handleSubmit, _updateDistributorConfig, distributorId],
  );

  useEffect(() => {
    getDistributor();
  }, [getDistributor]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        ...fetchValue.response,
      });
  }, [reset, fetchValue?.response]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getDistributor,
    submit,
    submitLoading,
  };
};
