import { BankAccountsItem } from "services/admin-bankinghub/models";
import { FinancialInstitution } from "services/assets/models/types/assets";
import { FinancialInstitutionUtils } from "utils/financial-institution";
import { FormValuesType } from "../_compose/update-contact";

export const assembleDefaultBankAccountValues = (
  bankAccount: BankAccountsItem,
  financialInstitutions: FinancialInstitution[] = [],
) => {
  const bankAccountData: FormValuesType = {
    ...bankAccount,
    ispb:
      bankAccount.ispb ??
      FinancialInstitutionUtils.findFinancialInstitution(
        financialInstitutions,
        [bankAccount.bank, bankAccount.ispb],
      )?.ispb ??
      "",
    is_disbursement_account: bankAccount.is_disbursement_account ? "S" : "N",
  };

  return bankAccountData;
};
