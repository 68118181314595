import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  EmailTemplateFormValues,
  emailTemplateFormValidationSchema,
} from "../_compose";

export const useAddEmailTemplate = () => {
  const form = useForm<EmailTemplateFormValues>({
    resolver: yupResolver(emailTemplateFormValidationSchema),
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const { callService: addEmailTemplate, loading: submitLoading } =
    useServiceCall(service.clerk.createEmailConfigs);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addEmailTemplate(values);

        if (!success)
          return OToastManager.danger("Erro ao criar template de e-mail.");

        navigate(
          corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs
            .emailTemplates.path,
        );
        return OToastManager.success("Template de e-mail criado com sucesso!");
      }),
    [addEmailTemplate, handleSubmit, navigate],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
