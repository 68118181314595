import { OToastManager } from "@maestro/core";
import { OButton, OTypography } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { energiaRoles } from "../../roles/energia.roles";
import { getValidationMessages } from "../../utils";
import { ContentFrame } from "./_compose";

const buildDataSource = (
  notes: HubEnergy.DiligenceStepPendencyNoteSimpleResponse[],
  pendencyId: number,
) =>
  dataSourceCustomStoreGenerator(async () => notes, {
    customStoreOptions: {
      insert: async (values) => {
        try {
          const { data } = await service.hubEnergy.upsertPendencyNote({
            pendencyId,
            content: values.content,
          });

          notes.push(data.response);
          return data.response;
        } catch (err) {
          const errorMessage =
            getValidationMessages(err)?.[0]?.errorMessage ??
            "Não foi possível criar a anotação";

          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
      update: async (oldValues, changes) => {
        try {
          const { data } = await service.hubEnergy.upsertPendencyNote({
            pendencyId,
            noteId: oldValues.id,
            content: changes.content ?? oldValues.content,
          });

          Object.assign(
            notes.find((note) => note.id === oldValues.id) ?? {},
            data.response,
          );
        } catch (err) {
          const errorMessage =
            getValidationMessages(err)?.[0]?.errorMessage ??
            "Não foi possível editar a anotação";

          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
      remove: async (data) => {
        try {
          await service.hubEnergy.removePendencyNote(data.id);

          notes.splice(notes.findIndex((note) => note.id === data.id));
        } catch (err) {
          const errorMessage =
            getValidationMessages(err)?.[0]?.errorMessage ??
            "Não foi possível remover a anotação";

          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    },
  });

const grid: ODataGridGeneratorConfig<HubEnergy.DiligenceStepPendencyNoteSimpleResponse> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      editing: {
        allowUpdating: true,
        allowDeleting: true,
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        caption: "Autor",
        dataField: "author",
        allowEditing: false,
        width: "auto",
      },
      {
        caption: "Conteúdo",
        dataField: "content",
        cssClass: "dx-word-wrap",
        cellRender: ({ data }) => <ContentFrame note={data} />,
        allowHeaderFiltering: false,
      },
      {
        caption: "Data de Criação",
        dataField: "createdDate",
        dataType: "date",
        format: "shortDateShortTime",
        allowEditing: false,
        width: "auto",
        sortOrder: "desc",
      },
      {
        caption: "Data de Atualização",
        dataField: "updatedDate",
        dataType: "date",
        format: "shortDateShortTime",
        allowEditing: false,
        width: "auto",
      },
    ],
  };

interface PendencyNotesMasterDetailProps {
  pendencyId: number;
  notes: HubEnergy.DiligenceStepPendencyNoteSimpleResponse[];
}

export const PendencyNotesMasterDetail = ({
  pendencyId,
  notes,
}: PendencyNotesMasterDetailProps) => {
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(notes, pendencyId),
    [notes, pendencyId],
  );

  return (
    <GridTemplate
      gridRef={gridRef}
      showClearFiltersButton
      pageTitle={
        <OTypography size="lg" type="dark">
          Anotações
        </OTypography>
      }
      actions={
        <OButton
          onClick={() => gridRef.current?.instance.addRow()}
          role={energiaRoles.customer.migrationStepsAddPendencyNotes.role}
        >
          Adicionar
        </OButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
