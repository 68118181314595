import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskConfigOfficerForm } from "../_compose/desk-config-officer-form.component";
import {
  DeskConfigOfficerFormValues,
  deskConfigOfficerFormValidationSchema,
} from "../_compose/desk-config-officer.form";

export const UpdateDeskConfigOfficerPage = () => {
  const { uuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid || !configIdentifier)
    throw new Error("No uuid or configIdentifier");
  const form = useForm<DeskConfigOfficerFormValues>({
    resolver: yupResolver(deskConfigOfficerFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskOnboardingConfigOfficers,
    loading,
    hasError,
    value: deskConfigOfficers,
  } = useServiceCall(service.onboarding.getDeskOnboardingConfigOfficers);
  const { callService: updateDeskConfigOfficer, loading: submitLoading } =
    useServiceCall(service.onboarding.updateOfficersOnDeskOnboardingConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskConfigOfficer({
          deskOnboardingConfigOfficerUuid: uuid,
          ...values,
        });

        if (!success)
          return OToastManager.danger("Erro ao atualizar relacionamento");

        return OToastManager.success("Relacionamento atualizado com sucesso");
      }),
    [handleSubmit, updateDeskConfigOfficer, uuid],
  );

  useEffect(() => {
    getDeskOnboardingConfigOfficers({
      deskOnboardingConfigIdentifier: configIdentifier,
    });
  }, [configIdentifier, getDeskOnboardingConfigOfficers]);

  useEffect(() => {
    const deskConfigOfficer = deskConfigOfficers?.find(
      (config) => config.uuid === uuid,
    );
    deskConfigOfficer &&
      reset({
        officerCge: deskConfigOfficer.officer.cge,
        ...deskConfigOfficer,
      });
  }, [deskConfigOfficers, reset, uuid]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={`Atualizar officers na mesa ${configIdentifier}`} />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar relacionamento"
            callback={() => getDeskOnboardingConfigOfficers()}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskConfigOfficerForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
