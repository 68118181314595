import * as yup from "yup";

export interface CollateralFormValues {
  description: string | undefined;
  guaranteedPercentage: number | undefined;
  amount: number | undefined;
  reviewPrice: number | undefined;
  collateralArea: number | undefined;
  formalized: boolean;
  formalizedContractNumber: string | undefined;
  timelineId: string | undefined;
}

export const collateralFormValidationsSchema: yup.ObjectSchema<CollateralFormValues> =
  yup.object({
    description: yup.string(),
    guaranteedPercentage: yup.number().transform((value) => value || undefined),
    amount: yup.number().transform((value) => value || undefined),
    reviewPrice: yup.number().transform((value) => value || undefined),
    collateralArea: yup.number().transform((value) => value || undefined),
    formalized: yup.boolean().required("Campo obrigatório"),
    formalizedContractNumber: yup.string().when("formalized", {
      is: true,
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
    }),
    timelineId: yup.string(),
  });
