import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { DetailsTemplate } from "templates/details-template";
import {
  OButton,
  OCard,
  OCardBody,
  OOptionMultiple,
  ORFieldSelectMultiple,
  OToastManager,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { useState } from "react";
import { companyTraitsGrid } from "./company-traits.grid";
import { ConfirmationModal } from "./confirmation-modal.component";
import { useCompanyTraitsContext } from "./company-traits.context";
import { useCompanyTraits } from "./company-traits.hook";
import { CompanySearch } from "../entuba-operacoes/_steps/_compose/company-search";
import { traitNameMapper } from "./company-traits.utils";

const FormComponent = ({
  setUpdate,
}: {
  setUpdate: (arg0: (arg0: boolean) => boolean) => void;
}) => {
  const [input, setInput] = useState<string | undefined>("");

  const [resetInput, setResetInput] = useState(false);

  const {
    form,
    clear,
    submit,
    properties,
    propertiesLoading,
    addCompanyTraitsHasError,
  } = useCompanyTraits(input);

  return (
    <FormProvider {...form}>
      <CompanySearch
        mode="Cedente"
        label="Nome ou identificação das empresas"
        name="taxIds"
        placeholder="Selecione a(s) empresa(s)"
        multiple
        resetInput={resetInput}
        setInput={setInput}
        noRoot
      />

      <ORFieldSelectMultiple
        id="traits"
        name="traits"
        label="Propriedades"
        disabled={propertiesLoading}
        key={properties?.data.length ?? 0}
      >
        {properties?.data.map((p) => (
          <OOptionMultiple key={p.key} value={p.key}>
            {p.key in traitNameMapper
              ? traitNameMapper[p.key as keyof typeof traitNameMapper]
              : "Propriedade desconhecida"}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>

      <div className="d-flex justify-content-between mt-5">
        <OButton
          type="dark"
          outline
          onClick={() => {
            clear();
            setResetInput((prevReset) => !prevReset);
          }}
        >
          Limpar
        </OButton>

        <OButton
          onClick={() => {
            submit();
            if (!addCompanyTraitsHasError) {
              OToastManager.success("A propriedade foi adicionada com sucesso");
              setUpdate((prevUpdate) => !prevUpdate);
            } else {
              const errorMessage = "Erro ao adicionar a propriedade";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }
          }}
        >
          Adicionar
        </OButton>
      </div>
    </FormProvider>
  );
};

export const CompanyTraitsComponent = () => {
  const gridRef = useGridRef();
  const { setParams } = useCompanyTraitsContext();

  const [_update, setUpdate] = useState(false);

  const grid = companyTraitsGrid(setParams);

  return (
    <>
      <DetailsTemplate
        pageTitle={
          <PageTitle
            title="Propriedades das empresas"
            description="
            Visualize e gerencie as propriedades de cada empresa:
 
                      1 - 'Exceção VNP' - Adicione para que todas as filiais e a matriz da empresa cadastrada entrem para a regra de exceção de VNP
                      2 - 'Optin padrão' - Adicione para que a empresa seja enviada como uma credenciadora no primeiro envio de optins, após a abertura de contas do cliente
                      3 - 'Credenciadora para Optin no Portal Cliente' - Adicione para que a empresa apareça como uma credenciadora na tela de 'Autorização de Maquininhas' do Portal do Cliente
                      4 - 'Credenciadora'- Adicione para que a empresa seja marcada como uma credenciadora, uso interno para o time de tecnologia
                      5 - 'Subcredenciadora' - Adicione para que a empresa seja marcada como uma subcredenciadora, uso interno para o time de tecnologia"
          />
        }
      >
        <OCard className="mb-4">
          <OCardBody>
            <FormComponent setUpdate={setUpdate} />
          </OCardBody>
        </OCard>
        <GridTemplate
          gridRef={gridRef}
          showClearFiltersButton
          showRefreshButton
          showExportButton
        >
          <ODataGridGenerator gridRef={gridRef} grid={grid} />
        </GridTemplate>
      </DetailsTemplate>
      <ConfirmationModal setUpdate={setUpdate} />
    </>
  );
};
