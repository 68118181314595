import { SharkPaymentMethodType } from "services/shark/models/responses";
import * as yup from "yup";

export const anticipationFormFields = {
  paymentMethod: {
    id: "paymentMethod",
    name: "paymentMethod",
    labelSize: "md" as const,
    label: "Método de pagamento",
    placeholder: "Selecione",
  },
  expirationDate: {
    id: "expirationDate",
    name: "expirationDate",
    labelSize: "md" as const,
    label: "Dia do pagamento",
    placeholder: "Selecione",
  },
};

export const anticipationFormSchema = yup.object({
  paymentMethod: yup.string().required("Este campo é obrigatório"),
  expirationDate: yup.string().when("paymentMethod", {
    is: (paymentMethod: SharkPaymentMethodType) => paymentMethod === "BOLETO",
    then: (expirationDate) =>
      expirationDate.required("Este campo é obrigatório"),
  }),
});

export const anticipationFormDefaultValues = {
  paymentMethod: "" as SharkPaymentMethodType,
  expirationDate: "",
};
