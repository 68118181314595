import { OTypography } from "@maestro/react";
import dayjs from "dayjs";
import { useSimulation } from "./simulation.context";

export const GracePeriodsTip = () => {
  const { summary } = useSimulation();
  return (
    <div>
      <OTypography>Possibilidades de datas de pagamento:</OTypography>
      {summary?.paymentDatesIntervals.map((interval) => (
        <OTypography key={interval.minDate}>
          {dayjs(interval.minDate).format("DD/MM/YYYY")} ~{" "}
          {dayjs(interval.maxDate).format("DD/MM/YYYY")}
        </OTypography>
      ))}
    </div>
  );
};
