import * as yup from "yup";

export const preticketsFormValidationSchema = (
  proposalAmount?: number,
  orderAmount?: number,
  isCounterpartyCorrect?: boolean,
) => {
  if (!proposalAmount || !orderAmount)
    return yup.object({
      counterpartyIdentification: yup
        .string()
        .required("Este campo é obrigatório")
        .test(
          "is-counterparty-correct",
          "A identificação da contraparte está incorreta.",
          (value) => isCounterpartyCorrect !== false ? !!value : false,
        ),
      orderConciliationId: yup.number().required("Este campo é obrigatório"),
      flowType: yup.string().nullable().required("Este campo é obrigatório"),
      correlationId: yup.string().required("Este campo é obrigatório"),
    });

  const minAmount = Math.min(proposalAmount, orderAmount);

  const minAmountMessage =
    proposalAmount < orderAmount
      ? `Escolha um valor menor ou igual ao valor disponível da proposta.`
      : `Escolha um valor menor ou igual ao valor disponível da ordem.`;

  return yup.object({
    amount: yup
      .number()
      .required("Este campo é obrigatório")
      .moreThan(0, "O valor precisa ser maior do que zero")
      .max(minAmount, minAmountMessage),
    currency: yup.string().required("Este campo é obrigatório"),
    flowType: yup.string().nullable().required("Este campo é obrigatório"),
    correlationId: yup.string().required("Este campo é obrigatório"),
    orderConciliationId: yup.string().required("Este campo é obrigatório"),
    channelInput: yup.string().required("Este campo é obrigatório"),
    counterpartyIdentification: yup
      .string()
      .required("Este campo é obrigatório")
      .test(
        "is-counterparty-correct",
        "A identificação da contraparte está incorreta.",
        (value) => isCounterpartyCorrect !== false ? !!value : false,
      ),
  });
};

export const defaultValues = {
  amount: 0,
  currency: "USD",
  channelInput: "B2C",
};
