import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostRealocacaoConfigBody } from "services/recebiveis-cartao/models";
import { relocationTimeModalId } from "./_compose";
import {
  autoRelocationDefaulValues,
  autoRelocationValidationSchema,
} from "./auto-relocation.form";
import { dataSource } from "./auto-relocation.grid";
import { AutoRelocationForm } from "./auto-relocation.types";

export const useAutoRelocation = () => {
  const {
    callService: configureAutoRelocation,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.configureAutoRelocation);

  const form = useForm<AutoRelocationForm>({
    defaultValues: autoRelocationDefaulValues,
    resolver: yupResolver(autoRelocationValidationSchema),
  });

  const { getValues, handleSubmit, reset } = form;

  const disableAutoRelocation = useCallback(async () => {
    const values = getValues();

    const payload: PostRealocacaoConfigBody = {
      company_ids: values.companyIds.map((id) => parseInt(id)),
      realocar: false,
    };

    await configureAutoRelocation(payload);
    modalManager.hide(relocationTimeModalId);
  }, [configureAutoRelocation, getValues]);

  const enableAutoRelocation = useCallback(
    (periodo: "semanal" | "diario") =>
      handleSubmit(async (values) => {
        const payload: PostRealocacaoConfigBody = {
          company_ids: values.companyIds.map((id) => parseInt(id)),
          realocar: true,
          horario: values.time,
          periodo,
        };

        await configureAutoRelocation(payload);
        modalManager.hide(relocationTimeModalId);
      }),
    [configureAutoRelocation, handleSubmit],
  );

  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao realizar a configuração");
  }, [hasError]);

  useEffect(() => {
    if (value) OToastManager.success("Configuração feita com sucesso");
    dataSource.reload();
    reset();
  }, [reset, value]);

  return {
    disableAutoRelocation,
    enableAutoRelocation,
    form,
    loading,
  };
};
