import { useBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import { useLegacySalesforceUserHook } from "hooks/legacy-salesforce";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomerSelectorItem } from "../customer-selector.types";

interface UserCustomersContext {
  loading: boolean;
  hasError: boolean;
  userCustomers: CustomerSelectorItem[];
}

const userCustomersContext = createContext({} as UserCustomersContext);

interface UserCustomersProps {
  children: React.ReactNode;
}

export const UserCustomersProvider = ({ children }: UserCustomersProps) => {
  const [userCustomers, setUserCustomers] = useState<CustomerSelectorItem[]>(
    [],
  );

  const { hasRoles } = useBehavior();

  const mode = hasRoles([BehaviorRoles.HeaderCustomerSelectorFetch_Enable])
    ? "fetch"
    : "select";

  const { loading, hasError, userData, getUserDetails } =
    useLegacySalesforceUserHook(false);

  useEffect(() => {
    if (mode === "select") getUserDetails();
  }, [getUserDetails, mode]);

  useEffect(() => {
    const mappedCustomers =
      userData?.companies?.map(
        (company): CustomerSelectorItem => ({
          identification: company.identification ?? "",
          officialName: company.officialName ?? "",
          type: "costumer",
        }),
      ) ?? [];

    setUserCustomers(mappedCustomers);
  }, [userData?.companies]);

  const value = useMemo(
    () => ({
      loading,
      hasError,
      userCustomers,
    }),
    [hasError, loading, userCustomers],
  );

  return (
    <userCustomersContext.Provider value={value}>
      {children}
    </userCustomersContext.Provider>
  );
};

export const useUserCustomers = () => useContext(userCustomersContext);
