import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { DeskDocumentConfigForm } from "../_compose/desk-document-config-form.component";
import {
  DeskDocumentConfigFormValues,
  deskDocumentConfigFormValidationSchema,
} from "../_compose/desk-document-config.form";

export const RegisterDeskDocumentConfigPage = () => {
  const form = useForm<DeskDocumentConfigFormValues>({
    resolver: yupResolver(deskDocumentConfigFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerDeskDocumentConfig, loading } = useServiceCall(
    service.onboarding.registerDeskOnboardingDocumentConfig,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDeskDocumentConfig(values);

        if (!success) return OToastManager.danger("Erro ao criar documento");

        return OToastManager.success("Dcoumaneto criado com sucesso");
      }),
    [handleSubmit, registerDeskDocumentConfig],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar configurção de documento" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskDocumentConfigForm />
      </FormProvider>
    </FormTemplate>
  );
};
