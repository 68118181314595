import dayjs from "dayjs";
import { GroupOfSignatory, Rule } from "services/paperclipv2/models";

export const dateFormatter = (date: string) => {
  if (!date) return null;
  return dayjs(date)
    .utc(true)
    .tz("America/Sao_Paulo")
    .format("DD/MM/YYYY [às] HH:mm:ss");
};

export const badgeInfo = (
  isSuccess: boolean,
): [
  HTMLOBadgeElement["type"],
  HTMLOIconElement["type"],
  HTMLOIconElement["icon"],
] =>
  isSuccess
    ? ["success-light", "success", "orq-status-success"]
    : ["warning-light", "warning", "orq-status-warning"];

const resolveSignaturePlural = (signersCount: number) => {
  if (signersCount === 1) {
    return "1 assinatura";
  }
  return `${signersCount} assinaturas`;
};

export const resolveRuleDescription = (rule: Rule) => {
  let message = "Para que essa operação seja aprovada, ";

  message +=
    rule.groups[0].minimumNumberOfSigners > 1
      ? "são necessárias "
      : "é necessária ";

  rule.groups.forEach((group, index) => {
    const signersCount = group.minimumNumberOfSigners;

    if (index === rule.groups.length - 1) message += " e ";
    message += `${resolveSignaturePlural(signersCount)} do grupo ${index + 1}`;
    if (rule.groups.length > 1 && index < rule.groups.length - 2)
      message += ", ";
  });

  return message;
};

export const minimumSigners = (group: GroupOfSignatory) => {
  const totalSigners = group.minimumNumberOfSigners;

  if (totalSigners === 1) {
    return `É necessária 1 assinatura`;
  }

  return `São necessárias ${totalSigners} assinaturas`;
};
