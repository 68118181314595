import { OField, OIcon, OInputMask, OLoader } from "@maestro/react";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { PhoneCPFRadio } from "./_compose/phone-cpf-radio";
import {
  OIconClearProps,
  OInputMaskPixProps,
  PixKeyInputProps,
  PixKeyType,
} from "./pix-key-input.types";
import {
  MapperPixKeyTypeToValidationFunction,
  assembleMaskOptionAndKey,
  handlePixKeyValue,
  isValidCPFandPhone,
  loadPixKeyTypeFromValue,
} from "./pix-key-input.utils";

export const PixKeyInput = memo(
  ({ debounceTime = 2000, isLoadingPixKey }: PixKeyInputProps) => {
    const { register, getFieldState, setValue, watch, formState, clearErrors } =
      useFormContext();

    const pixKeyValue: string = watch("pixKeyValue");
    const pixKeyType: PixKeyType | undefined = watch("pixKeyType");

    const validate = useCallback(() => {
      if (!pixKeyType || !pixKeyValue) return;
      return MapperPixKeyTypeToValidationFunction[pixKeyType](pixKeyValue);
    }, [pixKeyType, pixKeyValue]);

    register("pixKeyValue", {
      required: "Esse campo é obrigatório",
      validate,
    });

    register("pixKeyType", {
      required: "Esse campo é obrigatório",
    });

    const fieldState = getFieldState("pixKeyValue", formState);

    const { error, message, type } = useMemo((): {
      error: boolean;
      message?: string;
      type: "danger" | "default";
    } => {
      return {
        error: fieldState.invalid,
        message: fieldState.error?.message,
        type: fieldState.invalid ? "danger" : "default",
      };
    }, [fieldState]);

    useEffect(() => {
      const debounce = setTimeout(() => {
        if (pixKeyType) return;
        setValue("pixKeyType", loadPixKeyTypeFromValue(pixKeyValue));
      }, debounceTime);
      return () => clearTimeout(debounce);
    }, [debounceTime, pixKeyType, pixKeyValue, setValue]);

    const [maskValue, inputKey]: [HTMLOInputMaskElement["maskOption"], string] =
      useMemo(
        () => assembleMaskOptionAndKey(pixKeyValue, pixKeyType),
        [pixKeyValue, pixKeyType],
      );

    return (
      <div className="d-flex flex-column gap-3">
        <OField
          htmlFor="pixKeyValue"
          error={error}
          message={message}
          description="Pode ser CPF, CNPJ, e-mail, celular ou chave aleatória."
        >
          <div
            className="d-flex w-100 gap-2"
            style={{ borderBottom: `1px solid var(--theme-${type})` }}
          >
            <OIcon category="orq" icon="orq-pix" size="lg" type={type} />
            <OInputMask
              {...OInputMaskPixProps}
              maskOption={maskValue}
              key={inputKey}
              error={error}
              value={pixKeyValue}
              onBlur={() => {
                setValue("pixKeyType", loadPixKeyTypeFromValue(pixKeyValue));
              }}
              onChangeMasked={({ detail }) => {
                setValue("pixKeyType", undefined);
                const { unmaskedValue: value } = detail;
                const unmaskedValue = handlePixKeyValue(value);
                setValue("pixKeyValue", unmaskedValue);
                clearErrors("pixKeyValue");
              }}
              disabled={isLoadingPixKey}
            />
            {pixKeyValue && !isLoadingPixKey && (
              <OIcon
                {...OIconClearProps}
                type={type}
                onClick={() => {
                  setValue("pixKeyValue", undefined);
                  setValue("pixKeyType", undefined);
                }}
              />
            )}
            {isLoadingPixKey && <OLoader size="xs" type="default" />}
          </div>
        </OField>

        {!pixKeyType && isValidCPFandPhone(pixKeyValue) && <PhoneCPFRadio />}
      </div>
    );
  },
);
