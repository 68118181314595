import { masks } from "@maestro/utils";
import { OBadge, OTooltip } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetPayrollLinesResponse } from "services/fopa";
import { payrollStatusMap } from "./payment-details.utils";

export const payrollDetailsGrid: ODataGridGeneratorConfig<
  GetPayrollLinesResponse["lines"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma transação encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} transações)",
    },
  },
  columns: [
    {
      dataField: "nome",
      caption: "Colaborador",
      dataType: "string",
    },
    {
      dataField: "cpf",
      caption: "CPF",
      dataType: "string",
      cellRender: ({ data }) => masks.cpf(data.cpf),
    },
    {
      dataField: "agenciaBtg",
      caption: "Agência BTG",
      dataType: "string",
    },
    {
      dataField: "contaBtg",
      caption: "Conta BTG",
      dataType: "string",
    },
    {
      dataField: "nmStatusTransacao",
      caption: "Status",
      cellRender: ({ data }) =>
        data.nmStatusTransacao && payrollStatusMap[data.nmStatusTransacao] ? (
          <OTooltip floating position="top">
            <span slot="tooltip-content">{data.status}</span>
            <OBadge
              type={payrollStatusMap[data.nmStatusTransacao].type}
              size="sm"
            >
              {payrollStatusMap[data.nmStatusTransacao].label}
            </OBadge>
          </OTooltip>
        ) : (
          <OBadge type="info" size="sm">
            {data.nmStatusTransacao}
          </OBadge>
        ),
    },
    {
      caption: "Error",
      dataField: "mensagemDeErro",
    },
  ],
};
