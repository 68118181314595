import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GetAdminIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { incorrectOrdersGrid } from "./incorrect-orders-grid.component";

export const useExchangeProductIncorrectOrdersPage = () => {
  const [selectedOrders, setSelectedOrders] =
    useState<GetAdminIncorrectOrdersResponse>([]);

  const gridRef = useGridRef();

  const grid = useMemo(() => incorrectOrdersGrid(setSelectedOrders), []);

  return {
    grid,
    gridRef,
    selectedOrders,
  };
};
