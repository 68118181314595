import moment from "moment";
import { SimulationErrorMap } from "../../../offers.type";

interface PaymentDatesInterval {
  minDate: string;
  maxDate: string;
}

export const isValidDate = (
  date: string,
  intervals: PaymentDatesInterval[],
) => {
  if (!date || intervals.length === 0) {
    return false;
  }

  const indexOfInterval = intervals.findIndex((interval) => {
    return moment(date).isBetween(interval.minDate, interval.maxDate);
  });

  return indexOfInterval !== -1;
};

export const formatValue = (value?: string) => {
  let amount;

  if (Number.isNaN(Number(value)))
    amount = value?.replace("R$ ", "").replaceAll(".", "").replace(",", ".");
  else amount = value;

  return Number(amount);
};

export const simulationErrorMap: SimulationErrorMap = {
  LoanNotFound: {
    message: "Simulação de empréstimo expirada",
  },
};

interface CollateralTypeMapItem {
  value: string;
  label: string;
  description: string;
}

export const collateralTypeMap: Record<
  string,
  CollateralTypeMapItem | undefined
> = {
  RECEBIVEIS_CARTOES: {
    value: "RECEBIVEIS_CARTOES",
    label: "Vendas em Cartão",
    description: "",
  },
  PAINEIS_SOLARES: {
    value: "PAINEIS_SOLARES",
    label: "Painéis Solares",
    description: "",
  },
  SACAS_GRAO: {
    value: "SACAS_GRAO",
    label: "Safra de grãos",
    description: "",
  },
  IMOVEL: {
    value: "IMOVEL",
    label: "Imóvel",
    description: "",
  },
  CDB: {
    value: "CDB",
    label: "CDB",
    description: "CDB",
  },
  SEGURO: {
    value: "SEGURO",
    label: "Seguro",
    description: "",
  },
  PEAC: {
    value: "PEAC",
    label: "PEAC",
    description: "",
  },
  CAC: {
    value: "CAC",
    label: "Contrato de abertura de crédito",
    description: "",
  },
};
