import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { energyAdequatorsFormValidationFormSchema } from "../../../../../components/deprecated/adequator/energy-adequator.schemas";
import { EnergyAdequatorsFormValues } from "../../../../../components/deprecated/adequator/energy-adequator.types";
import { getHubEnergyErrorMessage } from "../../../../../components/deprecated/error/error-code.utils";
import { MleByAdequatorIdRouteParams } from "../../../../../routes/energia.route-params";

export const useEnergyUpdateAdequatorPage = () => {
  const navigate = useNavigate();

  const { adequatorId } = useParams<MleByAdequatorIdRouteParams>();
  if (!adequatorId) throw new Error("No id");

  const form = useForm<EnergyAdequatorsFormValues>({
    resolver: yupResolver(energyAdequatorsFormValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getAdequacyOptionsById,
    loading: loadingDetails,
    hasError,
    value: genericFieldDetails,
  } = useServiceCall(service.hubEnergy.getAdequacyOptionsById);
  const { callService: updateAdequacyOptions, loading } = useServiceCall(
    service.hubEnergy.updateAdequacyOptions,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateAdequacyOptions({
          ...values,
          adequacyOptionId: Number(adequatorId),
        });

        if (!success)
          return OToastManager.danger(getHubEnergyErrorMessage(error));

        OToastManager.success("Adequadora atualizada com sucesso");

        navigate(corporateRouter.routes.energia.product.adequators.path);
      }),
    [handleSubmit, updateAdequacyOptions, adequatorId, navigate],
  );

  const contactsDataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          service.hubEnergy
            .getAdequacyOptionsById(adequatorId)
            .then(({ data }) => data.response.contacts ?? [])
            .catch(() => {
              throw new Error("Erro ao buscar contatos");
            }),
        {
          customStoreOptions: {
            update: async (original, updated) => {
              const data = {
                ...original,
                ...updated,
              };

              try {
                await service.hubEnergy.updateAdequatorContact({
                  entityId: Number(adequatorId),
                  contact: data,
                });
                OToastManager.success("Contato atualizado com sucesso");
              } catch (err) {
                OToastManager.danger(getHubEnergyErrorMessage(err));
              }
            },
            insert: async (data) => {
              try {
                await service.hubEnergy.addAdequatorContact({
                  entityId: Number(adequatorId),
                  contact: {
                    id: null,
                    isMain: data.isMain,
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                  },
                });
                OToastManager.success("Contato atualizado com sucesso");
              } catch (err) {
                OToastManager.danger(getHubEnergyErrorMessage(err));
              }
              return data;
            },
            remove: async (data) => {
              try {
                await service.hubEnergy.removeAdequatorContact(
                  data.id,
                  adequatorId,
                );
                OToastManager.success("Contato removido com sucesso");
              } catch (err) {
                OToastManager.danger(getHubEnergyErrorMessage(err));
              }
            },
          },
        },
      ),
    [adequatorId],
  );

  const handleErrorCallback = useCallback(
    () => getAdequacyOptionsById(adequatorId),
    [getAdequacyOptionsById, adequatorId],
  );

  useEffect(() => {
    getAdequacyOptionsById(adequatorId);
  }, [getAdequacyOptionsById, adequatorId]);

  useEffect(() => {
    if (!genericFieldDetails?.response) return;

    reset({
      address: genericFieldDetails.response.address,
      contacts: genericFieldDetails.response.contacts,
      createdByCustomer: genericFieldDetails.response.createdByCustomer
        ? ["true"]
        : [],
      name: genericFieldDetails.response.name,
      taxId: genericFieldDetails.response.entity.taxId,
    });
  }, [genericFieldDetails, reset]);

  return {
    contactsDataSource,
    form,
    genericFieldDetails,
    hasError,
    loading,
    loadingDetails,
    handleErrorCallback,
    submit,
  };
};
