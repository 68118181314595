import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { DatagridActions } from "components/datagrid-action";
import { GetBatchGetByCnpjResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { AnticipationsStatusMap } from "./anticipations.types";

export const POOLING_TIMEOUT = 30 * 1000; // 30 seconds

export const batchStatusMap: AnticipationsStatusMap = {
  PENDING_PRE_VALIDATION: {
    label: "Em validação",
    type: "info",
  },
  VALIDATING: {
    label: "Em validação",
    type: "info",
  },
  PRE_VALIDATED: {
    label: "Pré-validado",
    type: "success",
  },
  POST_VALIDATED: {
    label: "Pós validado",
    type: "success",
  },
  ONGOING_DISBURSEMENT: {
    label: "Em antecipação",
    type: "info",
  },
  ON_HOLD: {
    label: "Indefinido",
    type: "default",
  },
  INVALID: {
    label: "Inválido",
    type: "danger",
  },
  FINISHED: {
    label: "Antecipado",
    type: "success",
  },
  FAILED_ANTICIPATION: {
    label: "Falha ao antecipar",
    type: "danger",
  },
} as const;

const getContracts = async (batchId: string) => {
  const { data: blob } = await service.fidc.getBatchTerms(batchId);

  helpers.downloadBlobFile("contrato.zip", blob);
};

const submitBatch = async (batchId: string) => {
  try {
    await service.fidc.getSubmitBatch(batchId);

    OToastManager.success("Solitação enviada com sucesso");
  } catch {
    OToastManager.danger("Erro ao enviar solicitação");
  }
};

const getPostValidatedFile = async (batchId: string) => {
  const { data: blob } = await service.fidc.getBatchGuarantee(batchId);

  helpers.downloadBlobFile("entuba.xlsx", blob);
};

export const getOperationActions = (
  anticipation: GetBatchGetByCnpjResponse[number],
): DatagridActions[] => {
  const { hasGuarantee, id, isSubmittable, status, termStatus, xlsxStatus } =
    anticipation;

  if (
    status !== "PRE_VALIDATED" &&
    status !== "FINISHED" &&
    status !== "FAILED_ANTICIPATION"
  ) {
    return [];
  }

  const canShowGuaranteeButton = status === "FINISHED" && hasGuarantee;

  const getContractsButtonText =
    termStatus === "PENDING" ? "Gerando Contratos" : "Baixar Contratos";

  const getGuaranteeButtonText =
    xlsxStatus === "PENDING"
      ? "Gerando Planilha Garantia"
      : "Baixar Planilha de Garantia";

  const buttons: DatagridActions[] = [
    {
      icon: {
        category: "orq",
        icon: "orq-download",
      },
      disabled: termStatus === "PENDING",
      label: getContractsButtonText,
      onClick: () => getContracts(id),
    },
  ];

  if (isSubmittable) {
    buttons.push({
      icon: {
        category: "orq",
        icon: "orq-anticipate",
      },
      label: "Antecipar",
      onClick: () => submitBatch(id),
    });
  }

  if (canShowGuaranteeButton) {
    buttons.push({
      icon: {
        category: "orq",
        icon: "orq-download",
      },
      label: getGuaranteeButtonText,
      onClick: () => getPostValidatedFile(id),
    });
  }

  return buttons;
};
