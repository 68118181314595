import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import {
  dataSourceCustomStoreGenerator,
  loadOptionsFilterToObject,
} from "components/data-grid";
import {
  GetMassifyEmployeesListResponse,
  GetMassifyFiltersListResponse,
  GetMassifyListResponse,
} from "services/fopa";
import { service } from "services/service";

const dataSourceGenerator = (loteId?: number) =>
  dataSourceCustomStoreGenerator<
    GetMassifyEmployeesListResponse["content"][number]
  >((loadOptions) => {
    return service.fopa
      .getMassifyEmployessList({
        ...loadOptionsFilterToObject(loadOptions.filter),
        size: 10,
        sort: ["id,desc"],
        loteId,
        page: (loadOptions?.skip || 0) / (loadOptions?.take || 1),
      })
      .then(({ data }) => {
        return {
          data: data.content,
          totalCount: data.totalElements,
        };
      })
      .catch(() => {
        const errorMessage = "Erro ao buscar o lote";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const useMassifyList = () => {
  const [massifyList, setMassifyList] =
    useState<GetMassifyListResponse["content"]>();
  const [massifyFilters, setMassifyFilters] =
    useState<GetMassifyFiltersListResponse>();
  const [selectedMassify, setSelectedMassify] =
    useState<GetMassifyListResponse["content"][number]["id"]>();
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();

  const fetchMassify = useCallback(async () => {
    try {
      const { data } = await service.fopa.getMassifyList({
        sort: ["id,desc"],
        page: 0,
        size: 99999,
      });
      setMassifyList(data.content);
    } catch {
      OToastManager.danger("Erro ao buscar os lotes");
    }
  }, []);

  const fetchMassifyFilter = useCallback(async () => {
    try {
      const { data } = await service.fopa.getMassifyFiltersList();
      setMassifyFilters(data);
    } catch {
      OToastManager.danger("Erro ao buscar filtros");
    }
  }, []);

  useEffect(() => {
    fetchMassify();
    fetchMassifyFilter();
  }, [fetchMassify, fetchMassifyFilter]);

  useEffect(() => {
    setDataSource(dataSourceGenerator(selectedMassify));
  }, [selectedMassify]);

  return {
    dataSource,
    massifyList,
    massifyFilters,
    selectedMassify,
    setSelectedMassify,
  };
};
