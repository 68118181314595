import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { GridTemplate } from "templates/grid-template";
import { billingCustomersGrid, dataSource } from "./billing-customers.grid";

export const BillingCustomers = () => {
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Clientes" />}
      actions={<RefreshGridButton onClick={() => dataSource.reload()} />}
    >
      <ODataGridGenerator grid={billingCustomersGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
