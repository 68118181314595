import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { GetApiStrategiesResponse, StrategyProductsItem } from "services/clerk";

export const useStrategies = () => {
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);
  const [companyStrategies, setCompanyStrategies] = useState<
    StrategyProductsItem[] | undefined
  >();
  const [strategies, setStrategies] = useState<
    GetApiStrategiesResponse | undefined
  >();

  const getStrategies = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.clerk.getStrategies();

      setStrategies(data);
    } catch {
      OToastManager.danger("Erro para buscar estratégias");
    } finally {
      setLoading(false);
    }
  }, []);

  const getStrategiesById = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.clerk.getStrategiesById(
        customer.identification,
      );

      setCompanyStrategies(data.strategyProducts);
    } catch {
      OToastManager.danger("Erro para buscar estratégias");
    } finally {
      setLoading(false);
    }
  }, [customer]);

  const addStrategy = useCallback(
    async (guid: string) => {
      try {
        setLoading(true);

        await service.clerk.addStrategiesByGuid(customer.identification, guid);

        getStrategiesById();
      } catch {
        OToastManager.danger("Erro para deletar estratégia");
      } finally {
        setLoading(false);
      }
    },
    [customer, getStrategiesById],
  );

  const deleteStrategy = useCallback(
    async (guid: string) => {
      try {
        setLoading(true);

        await service.clerk.deleteStrategiesByGuid(
          customer.identification,
          guid,
        );

        const newCompanyStrategies =
          companyStrategies?.filter(
            (strategy) => strategy.estrategiaUniqueId !== guid,
          ) ?? [];

        setCompanyStrategies(newCompanyStrategies);
      } catch {
        OToastManager.danger("Erro para deletar estratégia");
      } finally {
        setLoading(false);
      }
    },
    [companyStrategies, customer],
  );

  useEffect(() => {
    getStrategies();
    getStrategiesById();
  }, [getStrategies, getStrategiesById]);

  return {
    loading,
    strategies,
    companyStrategies,
    addStrategy,
    deleteStrategy,
  };
};
