import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetCompaniesByIdContractTemplatesByTemplateIdResponse } from "services/quickfin/models";
import { RecebiveisClienteCedentesByCedenteIdRouteParams } from "../../../../routes/recebiveis.route-params";
import {
  cedenteDetailsDefaultValues,
  cedenteDetailsValidationSchema,
  cedenteEditDetailsValidationSchema
} from "./cedente-details.form";
import { CedenteDetailsForm, ContractType } from "./cedente-details.types";
import { useGetCompanyCedente } from "./get-company-cedente.hook";
import {
  useCedenteContractComplement,
  parseContractComplementForm,
} from "./_compose";

export const useCedenteDetails = () => {
  const { cedenteId, strategyId } =
    useParams<RecebiveisClienteCedentesByCedenteIdRouteParams>();

  const navigate = useNavigate();

  const {
    customer: { quickfinId },
  } = useCustomer();

  const { cedente, error, getCedente, loading } = useGetCompanyCedente(
    quickfinId,
    Number(cedenteId), // Will be NaN when adding a new cedente, but getCedente won't be called
    Number(strategyId),
  );

  const {
    getCedenteContractComplement,
    editContractComplement,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
  } = useCedenteContractComplement(quickfinId, Number(cedenteId));

  const { callService: addCompanyCedente, loading: addCompanyCedenteLoading } =
    useServiceCall(service.quickfin.addCompanyCedente);

  const {
    callService: editCompanyCedente,
    loading: editCompanyCedenteLoading,
  } = useServiceCall(service.quickfin.editCompanyCedente);

  const { callService: searchLegalEntity } = useServiceCall(
    service.onboarding.searchLegalEntity,
  );

  const form = useForm<CedenteDetailsForm>({
    resolver:
      cedenteId == null
        ? yupResolver(cedenteDetailsValidationSchema)
        : yupResolver(cedenteEditDetailsValidationSchema),
    defaultValues: cedenteDetailsDefaultValues,
  });

  const { clearErrors, handleSubmit, reset, setValue, watch } = form;

  const tipoContratoWatcher = watch("tipoContrato");
  const identificationWatcher = watch("identification");

  const disableContractTemplateId = tipoContratoWatcher === "SemContrato";
  const disableReceiptTemplateId = ["SemContrato", "PorOperacao"].includes(
    tipoContratoWatcher,
  );

  const [contractTemplate, setContractTemplate] =
    useState<GetCompaniesByIdContractTemplatesByTemplateIdResponse>();

  const retrieveSelectedTemplate = (
    template: GetCompaniesByIdContractTemplatesByTemplateIdResponse,
  ) => {
    setContractTemplate(template);
  };

  const editCedente = useMemo(
    () => (atualizaEstrategiaDosRecebiveisAntigos: boolean) =>
      handleSubmit(async (values) => {
        const { success } = await editCompanyCedente(quickfinId, {
          cedenteId: Number(cedenteId),
          estrategiaId: values.estrategiaId,
          convenioGUID: values.estrategiaGUID,
          identificadorExterno: values.identificadorExterno,
          country: values.country,
          companyEntityType: values.companyEntityType,
          cge: values.cge,
          officialName: values.officialName,
          identificacao: values.identification,
          antecipaAutomaticamente: values.antecipaAutomaticamente === "yes",
          permiteOverrideEstrategia: values.permiteOverrideEstrategia === "yes",
          desativaFornecedor: values.desativaFornecedor === "yes",
          contractTemplateId: values.contractTemplateId,
          receiptTemplateId: values.receiptTemplateId,
          tipoProduto: values.tipoProduto,
          tipoContrato: values.tipoContrato,
          cancelContract: values.cancelContract
            ? values.cancelContract === "yes"
            : null,
          atualizaEstrategiaDosRecebiveisAntigos,
        });

        const hasComplement =
          values.tipoContrato === ContractType.PerOperation &&
          contractTemplate?.hasComplement;

        if (hasComplement) {
          editContractComplement(values);
        }

        if (success) {
          OToastManager.success("Cedente editado com sucesso");
          navigate(corporateRouter.routes.recebiveis.customer.cedentes.path);
        } else {
          OToastManager.danger("Erro ao editar o cedente");
        }
      }),
    [
      cedenteId,
      editCompanyCedente,
      editContractComplement,
      handleSubmit,
      navigate,
      quickfinId,
      contractTemplate,
    ],
  );

  const addCedente = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addCompanyCedente(quickfinId, {
          estrategiaGUID: values.estrategiaGUID,
          companyEntityType: values.companyEntityType,
          cge: values.cge,
          officialName: values.officialName,
          identificacao: values.identification,
          identificadorExterno: values.identificadorExterno,
          antecipaAutomaticamente: values.antecipaAutomaticamente === "yes",
          permiteOverrideEstrategia: values.permiteOverrideEstrategia === "yes",
          desativaFornecedor: values.desativaFornecedor === "yes",
          contractTemplateId: values.contractTemplateId,
          receiptTemplateId: values.receiptTemplateId,
          tipoProduto: values.tipoProduto,
          tipoContrato: values.tipoContrato,
          country: values.country,
        });

        const hasComplement =
          values.tipoContrato === ContractType.PerOperation &&
          contractTemplate?.hasComplement;

        if (hasComplement) {
          editContractComplement(values);
        }

        if (success) {
          OToastManager.success("Cedente adicionado com sucesso");
          navigate(corporateRouter.routes.recebiveis.customer.cedentes.path);
        } else {
          OToastManager.danger("Erro ao adicionar o cedente");
        }
      }),
    [
      addCompanyCedente,
      handleSubmit,
      navigate,
      quickfinId,
      contractTemplate,
      editContractComplement,
    ],
  );

  useEffect(() => {
    if (cedenteId && strategyId) getCedente();
  }, [cedenteId, getCedente, strategyId]);

  useEffect(() => {
    (async () => {
      const hasComplement =
        tipoContratoWatcher === "PorOperacao" &&
        contractTemplate?.hasComplement;
      if (cedente && hasComplement) {
        const contractComplement = await getCedenteContractComplement(cedente);
        setValue(
          "contractComplement",
          parseContractComplementForm(contractComplement),
        );
        setValue(
          "contractComplementInclusionList",
          contractComplement?.inclusionList ?? [],
        );
      }
    })();
  }, [
    cedente,
    getCedenteContractComplement,
    setValue,
    contractTemplate,
    tipoContratoWatcher,
  ]);

  /** Initial values */
  useEffect(() => {
    if (cedente) {
      reset({
        antecipaAutomaticamente: cedente.antecipaAutomaticamente ? "yes" : "no",
        cge: cedente.cge ?? "",
        identification: cedente.identification,
        desativaFornecedor: cedente.desativaFornecedor ? "yes" : "no",
        companyEntityType: cedente.companyEntityType,
        estrategiaId: cedente.estrategia?.id,
        identificadorExterno: cedente.identificadorExterno ?? "",
        officialName: cedente.officialName,
        permiteOverrideEstrategia: cedente.permiteOverrideEstrategia
          ? "yes"
          : "no",
        contractTemplateId: cedente.contractTemplate?.id,
        receiptTemplateId: cedente.receiptTemplate?.id,
        tipoContrato: cedente.tipoContrato,
        country: cedente.country,
        // helper
        _originalDesativaFornecedor: cedente.desativaFornecedor ? "yes" : "no",
      } as CedenteDetailsForm);
    }
  }, [cedente, reset]);

  useEffect(() => {
    if (disableContractTemplateId) {
      setValue("contractTemplateId", null);
      clearErrors("contractTemplateId");
    }
    if (disableReceiptTemplateId) setValue("receiptTemplateId", null);
  }, [
    clearErrors,
    disableContractTemplateId,
    disableReceiptTemplateId,
    setValue,
  ]);

  useEffect(() => {
    if (validators.cpfCnpj(identificationWatcher)) {
      searchLegalEntity({ taxId: identificationWatcher }).then(
        ({ success, response }) => {
          if (success) {
            setValue("officialName", response.data.officialName);
          }
        },
      );
    }
  }, [identificationWatcher, searchLegalEntity, setValue]);

  return {
    addCedente,
    addCompanyCedenteLoading,
    cedente,
    disableContractTemplateId,
    disableReceiptTemplateId,
    editCedente,
    editCompanyCedenteLoading,
    error,
    form,
    getCedente,
    isCreating: cedenteId === undefined,
    loading,
    contractTemplate,
    retrieveSelectedTemplate,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
  };
};
