import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { NavigateFunction } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";

export const denyListGrid = (navigate: NavigateFunction) => {
  const filter = "?$filter=(Status eq 'BLOCKED')";

  return {
    datagrid: {
      noDataText: "Nenhum cliente encontrado.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar clientes.";
      },
      filterRow: {
        visible: true,
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.denyListAdmin + filter,
      ),
    },
    columns: [
      {
        dataField: "Id",
        dataType: "number",
        caption: "Id",
        visible: false,
      },
      {
        caption: "CNPJ/CPF",
        dataField: "TaxId",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        caption: "Nome",
        dataField: "Name",
        sortOrder: "desc",
      },
      {
        caption: "Motivo",
        dataField: "ReasonDescription",
        allowFiltering: false,
      },
      {
        caption: "Origem",
        dataField: "Origin",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: {
                  category: "fal",
                  icon: "fa-trash",
                },
                label: "Remover",
                onClick: () => {
                  navigate(
                    corporateRouter.routes.workflow.product.denyList.edit.path({
                      denyListId: data.Id,
                    }),
                    { state: { taxId: data.TaxId, name: data.Name } },
                  );
                },
                role: roles.workflow.product.legalEntityDenialEditPage.role,
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<
        ReturnType<typeof service.hubCreditManager.odata.getDenyList>
      >["data"]["value"]
    >[number]
  >;
};
