import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
  OCard,
  OCardHeader,
  OIcon,
  OCardBody,
  OBadge,
} from "@maestro/react";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  eligibilityCriteriaModalId,
  SnapshotNode,
} from "../../eligibility-offer-details.types";
import {
  criteriaMap,
  operationMap,
} from "../../eligibility-offer-details.utils";
import { isExpiredDate } from "./eligibility-criteria.utils";

interface EligibilityCriteriaModalProps {
  currentNode: SnapshotNode;
}

export const EligibilityCriteriaModal = ({
  currentNode,
}: EligibilityCriteriaModalProps) => {
  const { id: nodeId, groupName, results } = currentNode;
  return (
    <OModal id={eligibilityCriteriaModalId} size="xs">
      <OModalHeader>
        <OTypography key={nodeId} tag="h2" size="lg">
          {groupName}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {results.map((result) => (
          <OCard
            className="mt-4"
            key={result.criteria.id}
            type={result.hasPassed ? "success" : "danger"}
            outline
          >
            <OCardHeader divider>
              <div className="d-flex align-items-center gap-2">
                <OIcon
                  category="fal"
                  icon={result.hasPassed ? "fa-check" : "fa-times"}
                />
                <OTypography tag="h3">
                  {getValueFromMap(criteriaMap, result.criteria.type) ??
                    result.criteria.type}
                </OTypography>
              </div>
            </OCardHeader>
            <OCardBody>
              {result.criteria.criteriaParams.map((param) => (
                <div
                  key={param.id}
                  className="d-flex justify-content-between align-items-center mb-4"
                >
                  <OTypography>
                    {getValueFromMap(operationMap, param.operation) ??
                      param.operation}
                  </OTypography>
                  <OTypography>{param.value}</OTypography>
                </div>
              ))}

              <div className="d-flex">
                {result.criteria.externalValue && (
                  <OBadge outline type="default">
                    <OTypography>
                      Valor recebido: {result.criteria.externalValue}
                    </OTypography>
                  </OBadge>
                )}
                {result.criteria.expirationDate &&
                  isExpiredDate(result.criteria.expirationDate) && (
                    <OBadge outline type="default">
                      <OTypography>Expirado</OTypography>
                    </OBadge>
                  )}
              </div>
            </OCardBody>
          </OCard>
        ))}
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            dataAction="ver_criterios_grupo:botao:fechar"
            dataLabel="fechar"
            onClick={() => modalManager.hide(eligibilityCriteriaModalId)}
            type="dark"
            outline
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
