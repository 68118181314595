import * as yup from "yup";

export interface StepUpdateFormValues {
  accountsToReproveOnRedStep: { branch: number; accountType: string }[];
}

export const stepUpdateFormValidationSchema: yup.ObjectSchema<StepUpdateFormValues> =
  yup.object({
    accountsToReproveOnRedStep: yup
      .array()
      .of(
        yup.object({
          branch: yup.number().required("Este campo é obrigatório"),
          accountType: yup.string().required("Este campo é obrigatório"),
        }),
      )
      .default([]),
  });
