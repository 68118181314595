export const disverbationApprovalStatusFileBadgeMap = {
  REJECTED: {
    label: "Rejeitado",
    type: "danger",
  },
  PENDING_APPROVAL: {
    label: "Aguardando Aprovação",
    type: "info",
  },
  SENT: {
    label: "Enviado",
    type: "success",
  },
  APPROVED: {
    label: "Aprovado",
    type: "warning",
  },
  ERROR: {
    label: "Erro",
    type: "danger",
  },
} as const;

export interface DisverbationFunds {
  Name: string;
  Cge: string;
}

export enum StatusDisverbation {
  REJECTED = "REJECTED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  SENT = "SENT",
  APPROVED = "APPROVED",
  ERROR = "ERROR",
}

export interface DisverbationApprovalsFormValues {
  findContractNumber: string;
  findFundName: string;
  findStatus: StatusDisverbation;
}

export const disverbationFormDefaulValues: DisverbationApprovalsFormValues = {
  findContractNumber: "",
  findFundName: "",
  findStatus: StatusDisverbation.PENDING_APPROVAL,
};
