import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { mapCurrencySymbol } from "utils/currency";
import { ParsedTooltip } from "../../parsed-tooltip/parsed-tooltip.component";
import { StyledValue } from "../roadwalker-master-detail.style";
import { PagForMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerPagForDetails = (data: PagForMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    pagfor: {
      items: [
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InvoiceId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.InvoiceId}</span>
                {data.InvoiceId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "OriginatorPartner",
          value: <StyledValue>{data.OriginatorPartner}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: <StyledValue>{data.Status}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Instruction",
          value: <StyledValue>{data.Instruction}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "IssueDate",
          value: <StyledValue>{data.IssueDate}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MaturityDate",
          value: <StyledValue>{data.MaturityDate}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Currency",
          value: <StyledValue>{mapCurrencySymbol(data.Currency)}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FaceValue",
          visible: !!data.FaceValue,
          value: (
            <StyledValue>
              {masks.currency(
                data.FaceValue,
                mapCurrencySymbol(data.Currency),
                ".",
              )}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InvoiceIdentification",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.InvoiceIdentification}</span>
                {data.InvoiceIdentification}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Payer",
          value: (
            <StyledValue>
              <ParsedTooltip value={data.Payer} />
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Payee",
          value: (
            <StyledValue>
              <ParsedTooltip value={data.Payee} />
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "IdFcc",
          visible: !!data.IdFcc,
          value: <StyledValue>{data.IdFcc}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreditNSU",
          visible: !!data.CreditNSU,
          value: <StyledValue>{data.CreditNSU}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Reasons",
          value: <StyledValue>{data.Reasons}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Payment",
          value: (
            <StyledValue>
              <ParsedTooltip value={data.Payment} />
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Errors",
          value: <StyledValue>{data.Errors}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Result",
          visible: !!data.Result,
          value: <StyledValue>{data.Result}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
