import { OCard, OCardBody } from "@maestro/react";

interface CardTemplateProps {
  cardBodyClassName?: string;
  children?: React.ReactNode;
  className?: string;
}

export const CardTemplate = ({
  children,
  className,
  cardBodyClassName = "position-relative",
}: CardTemplateProps) => (
  <OCard className={className}>
    <OCardBody className={cardBodyClassName}>{children}</OCardBody>
  </OCard>
);
