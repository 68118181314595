import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { BatchTaxas } from "../../../../../../components/batch-taxas";
import { useEstrategiaComercial } from "../../_compose";
import { ConfigureTaxasForm } from "./configure-taxas-form.component";

export const ConfigureTaxas = () => {
  const {
    estrategia,
    getCompanyEstrategia,
    hasError,
    loading: estrategiaLoading,
  } = useEstrategiaComercial();

  const isBatchTaxas = estrategia?.modeloDeTaxa === "planilha";

  if (estrategia) {
    if (isBatchTaxas) return <BatchTaxas estrategiaId={estrategia.id} />;
    return (
      <ConfigureTaxasForm
        estrategia={estrategia}
        estrategiaLoading={estrategiaLoading}
        getCompanyEstrategia={getCompanyEstrategia}
        hasError={hasError}
      />
    );
  }

  return (
    <FormTemplate pageTitle={<PageTitle title="Configurar taxas" />}>
      <CardTemplate>
        <ContentTemplate
          loading={estrategiaLoading}
          hasError={hasError}
          value={estrategia}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível encontrar a estratégia"
              callback={getCompanyEstrategia}
            />
          }
          render={() => <div />}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
