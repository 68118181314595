import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DomainScfValidationSchema } from "../domain-configuration-scf.form";

export const useAddPartnerConfigurationScf = () => {
  const form = useForm({
    resolver: yupResolver(DomainScfValidationSchema),
  });

  const { reset } = form;

  useEffect(() => {
    reset({
      integrationType: "SACADO_SIMPLES",
    });
  }, [reset]);

  return {
    form,
  };
};
