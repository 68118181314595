import * as yup from "yup";

export interface DeskFormValues {
  deskCode: number;
  deskName: string;
  deskDescription: string;
}

export const deskFormValidationSchema: yup.ObjectSchema<DeskFormValues> =
  yup.object({
    deskCode: yup.number().required("Este campo é obrigatório"),
    deskName: yup.string().required("Este campo é obrigatório"),
    deskDescription: yup.string().required("Este campo é obrigatório"),
  });
