import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { getTradeFinanceErrorMessage } from "pages/acc/components/error/error-code.utils";
import { ExchangeGenericFieldsForm } from "pages/acc/components/nature/generic-fields/generic-fields-form.component";
import {
  exchangeGenericFieldsFormValidationFormSchema,
  ExchangeGenericFieldsFormValues,
} from "pages/acc/components/nature/generic-fields/generic-fields-form.schemas";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";

export const AdminContingencyACCCreateGenericFields = () => {
  const form = useForm<ExchangeGenericFieldsFormValues>({
    resolver: yupResolver(exchangeGenericFieldsFormValidationFormSchema),
  });

  const navigate = useNavigate();

  const { handleSubmit } = form;

  const { callService: createNatureGenericFieldTypes, loading } =
    useServiceCall(service.acc.nature.createNatureGenericFieldTypes);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createNatureGenericFieldTypes(values);
        if (!success)
          return OToastManager.danger(getTradeFinanceErrorMessage(error));

        OToastManager.success("Campo genérico adicionado com sucesso");

        navigate(
          corporateRouter.routes.acc.product.contingency.natures.genericFields
            .path,
        );
      }),
    [createNatureGenericFieldTypes, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Campo Genérico" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ExchangeGenericFieldsForm />
      </FormProvider>
    </FormTemplate>
  );
};
