import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/step-config/step-config.form";
import { FormValuesType } from "../_compose/step-config/step-config.types";

export const useSemaphoreStepAdd = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const redirectDetailLink = (uuid: string) => {
    return corporateRouter.routes.ferramentas.product.onboarding.semaphore.configuration.steps.details.path(
      { uuid: encodeURIComponent(uuid) },
    );
  };

  const { callService: _add, loading: serviceLoading } = useServiceCall(
    service.onboarding.addSemaphoreStep,
  );

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(serviceLoading);

      const { success, response } = await _add(values);

      if (!success) {
        OToastManager.danger("Erro para salvar os dados");
        return;
      }

      OToastManager.success("Template de semáforo adicionado com sucesso");
      setLoading(serviceLoading);
      navigate(redirectDetailLink(response.data.uuid as string));
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
