import { OButton, ONotification } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { spreadsheetDataGrid } from "./spreadsheet-data.grid";

interface SpreadsheetDataProps {
  sheetData: HubEnergy.LegacyImport.ParseSpreadsheetResponse;
  setSheetData: (
    sheetData: HubEnergy.LegacyImport.ParseSpreadsheetResponse | undefined,
  ) => void;
}

export const SpreadsheetData = ({
  sheetData,
  setSheetData,
}: SpreadsheetDataProps) => {
  const gridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-4">
      {sheetData.isValid ? (
        <ONotification type="success">
          A planilha foi enviada com sucesso e os dados estão sendo processados.
        </ONotification>
      ) : (
        <ONotification type="danger">
          Os dados da planilha possuem erros. Faça as correções e envie
          novamente.
        </ONotification>
      )}
      <GridTemplate
        gridRef={gridRef}
        actions={
          <>
            <RouterButton
              href={
                corporateRouter.routes.energia.product.consumerUnitsImport.path
              }
              role={roles.energia.product.consumerUnitsImport.role}
            >
              Ver importações
            </RouterButton>
            <OButton onClick={() => setSheetData(undefined)}>
              Enviar outra planilha
            </OButton>
          </>
        }
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={spreadsheetDataGrid}
          dataSource={sheetData.spreadsheetData}
        />
      </GridTemplate>
    </div>
  );
};
