import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface MoveStepPropertyForm {
  destinationStepId: number;
  /** non editable */
  isAutomation: boolean | null;
  isPublic: string[];
  typeId: number;
  minimumAmount: number;
  documentTypeId: number | null;
}

export const moveStepPropertyFormFields = {
  isPublic: {
    id: "isPublic",
    name: "isPublic",
  },
  minimumAmount: {
    id: "minimumAmount",
    name: "minimumAmount",
    tag: "number",
    label: "Quantidade mínima",
    placeholder: "Preencher",
  },
} as const satisfies MaestroFields<MoveStepPropertyForm>;

export const moveStepPropertyFormValidationSchema = yup.object<
  MoveStepPropertyForm,
  YupObjectSchema<MoveStepPropertyForm>
>({
  destinationStepId: yup.number().required("Campo obrigatório"),
  typeId: yup.number().required("Campo obrigatório"),
});

export const checkboxValue = "true";

export const moveStepPropertyFormDefaultValues = {
  destinationStepId: null,
  isAutomation: null,
  isPublic: [],
  typeId: null,
  documentTypeId: null,
  minimumAmount: 1,
} satisfies Partial<
  Nullable<MoveStepPropertyForm>
> as unknown as MoveStepPropertyForm;
