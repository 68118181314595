import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { isAxiosError } from "axios";
import { useUser } from "contexts/user";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { PostApiAdmibOnboardBatchVerifyResponse } from "services/onboarding/models";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { errorMessageMap } from "../../../../../recebiveis/utils";
import { BatchImportFormFields, ImportedBatchPayer } from "./batch-import.type";
import {
  missingFieldMap,
  parseIntubateSpreadsheet,
} from "./batch-import.utils";

export const useBatchImportPayers = () => {
  const [payers, setPayers] = useState<ImportedBatchPayer[]>();
  const [payersWithError, setPayersWithError] =
    useState<ImportedBatchPayer[]>();
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationResults, setValidationResults] =
    useState<PostApiAdmibOnboardBatchVerifyResponse>();

  const { user } = useUser();

  const form = useForm<BatchImportFormFields>();
  const { watch } = form;

  const configurationIdWatch = watch("configurationId");

  const {
    error: readerError,
    loading: readerLoader,
    reader,
    result,
  } = useReadFile();

  const { callService: importBatches, loading: loadingImport } = useServiceCall(
    service.scfDevelopers.importBatches,
  );

  const {
    callService: getEntityDesks,
    loading: loadingDesks,
    value: possibleDesks,
  } = useServiceCall(service.clerk.getEntityDesks);

  const { callService: getConfigurations, value: configurations } =
    useServiceCall(service.scfDevelopers.getConfigurationsWithoutSteps);

  const possibleConfigurations = useMemo(() => {
    if (!configurations || !possibleDesks) {
      return [];
    }

    return configurations.filter((configuration) =>
      configuration.desks.find((desk) =>
        possibleDesks.find((entity) => entity.code === desk),
      ),
    );
  }, [configurations, possibleDesks]);

  const chosenConfiguration = useMemo(() => {
    if (!configurationIdWatch || !configurations) {
      return null;
    }

    return configurations?.find(
      (configuration) => configuration.configurationId === configurationIdWatch,
    );
  }, [configurationIdWatch, configurations]);

  const getConfigurationsWithoutSteps = useCallback(async () => {
    const { success } = await getConfigurations();

    if (!success) {
      OToastManager.danger(
        "Não foi possível obter as mesas que o usuário possui acesso",
      );
    }
  }, [getConfigurations]);

  const getPossibleDesks = useCallback(async () => {
    if (user) {
      const payload = {
        taxId: user.cpf ?? "",
      };

      const { success } = await getEntityDesks(payload);

      if (!success) {
        OToastManager.danger(
          "Não foi possível obter as mesas que o usuário possui acesso",
        );
      }
    }
  }, [user, getEntityDesks]);

  const handleAddFile = (evt: OUploadCustomEvent<File>) => {
    const file = evt.detail;

    if (file) {
      reader.readAsBinaryString(file);
    }
  };

  const verifyImports = useCallback(async () => {
    if (payers && !validationResults) {
      setLoading(true);

      const payload = {
        onboardings: payers,
      };

      try {
        setHasError(false);

        await service.scfDevelopers.verifyBatches(payload);
      } catch (err) {
        let _hasError = true;
        if (
          isAxiosError<PostApiAdmibOnboardBatchVerifyResponse>(err) &&
          err.response?.status === 400 &&
          err.response.data
        ) {
          _hasError = false;
          setValidationResults(err.response?.data);
        }
        setHasError(_hasError);
      } finally {
        setLoading(false);
      }
    }
  }, [payers, validationResults]);

  useEffect(() => {
    getConfigurationsWithoutSteps();
  }, [getConfigurationsWithoutSteps]);

  useEffect(() => {
    getPossibleDesks();
  }, [getPossibleDesks]);

  useEffect(() => {
    if (result && !readerError && chosenConfiguration) {
      setPayers(parseIntubateSpreadsheet(result, chosenConfiguration));
    }
  }, [chosenConfiguration, readerError, result]);

  useEffect(() => {
    verifyImports();
  }, [verifyImports]);

  useEffect(() => {
    if (validationResults && payers && !hasError) {
      const dataSourceCopy = cloneDeep(payers).map((row) => ({
        ...row,
        failure: [] as string[],
      }));
      validationResults.failures.forEach((err) => {
        const indexStr = err.fieldName.match(/\d+/)?.[0];
        if (indexStr === undefined) {
          return;
        }
        const index = parseInt(indexStr);
        let errorMessage =
          getValueFromMap(errorMessageMap, err.errorCode) ?? "";

        if (errorMessage === "") {
          errorMessage = err.errorMessage ?? "";
        }

        if (errorMessage === "Campo obrigatório não informado") {
          const errorField = err.fieldName.split(".")[1];
          errorMessage = `Campo obrigatório não informado: ${
            getValueFromMap(missingFieldMap, errorField) ?? errorField
          }`;
        }

        dataSourceCopy[index].failure.push(errorMessage);
      });

      setPayersWithError(dataSourceCopy);
    }
  }, [payers, hasError, validationResults]);

  const submitPayers = useCallback(async () => {
    if (payers) {
      const payload = {
        onboardings: payers,
      };

      const { success } = await importBatches(payload);

      if (success) {
        OToastManager.success("Onboarding(s) cadastrado(s) com sucesso!");
      } else {
        OToastManager.danger("Falha ao importar onboarding(s)");
      }
    }
  }, [payers, importBatches]);

  return {
    chosenConfiguration,
    payers,
    payersWithError,
    possibleConfigurations,
    form,
    handleAddFile,
    loading,
    loadingDesks,
    loadingImport,
    readerLoader,
    submitPayers,
    validationResults,
  };
};
