import {
  GetOfferSnapshotResponse,
  Company,
  Node,
} from "services/eligibilityengine/models";

export const eligibilityCriteriaModalId = "eligibility-criteria-modal-id";

export type EligibilityDetails = GetOfferSnapshotResponse;
export type SnapshotNode = Node;
export type SnapshotCompany = Company;
