import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { Advisor } from "services/gatekeeper/models";
import { AdvisoredEntities } from "./advisored-entities.master-detail";

export const dataSource = dataSourceCustomStoreGenerator<Advisor>(() =>
  service.gatekeeper
    .getAdvisors()
    .then((data) => ({
      data: data,
      totalCount: data.length,
    }))
    .catch(() => {
      const errorMessage = "Erro ao carregar os assessores!";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const grid: ODataGridGeneratorConfig<Advisor> = {
  datagrid: {
    noDataText: "Nenhum assessor",
    filterRow: { visible: true },
    remoteOperations: false,
    pager: {
      showPageSizeSelector: true,
    },
    paging: {
      pageSize: 20,
    },
    grouping: {
      expandMode: "rowClick",
      contextMenuEnabled: true,
      autoExpandAll: false,
    },
    searchPanel: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "main_partner.name",
      dataType: "string",
      caption: "Instituição responsável",
      alignment: "center",
      groupIndex: 0,
      calculateCellValue: (data) =>
        data.main_partner.name
          ? `${data.main_partner.name} - ${data.main_partner.cnpj}`
          : "",
    },
    {
      dataField: "partner.name",
      dataType: "string",
      caption: "Escritório",
      groupIndex: 1,
      calculateCellValue: (data) =>
        data.partner.name ? `${data.partner.name} - ${data.partner.cnpj}` : "",
    },
    {
      dataField: "user.name",
      caption: "Assessor",
      dataType: "string",
    },
    {
      dataField: "user.cpf",
      caption: "CPF",
      dataType: "string",
    },
    {
      dataField: "user.email",
      caption: "Email",
      dataType: "string",
    },
    {
      dataField: "user.phone",
      caption: "Telefone",
      dataType: "string",
    },
    {
      dataField: "user.gk_email",
      caption: "GK Email",
      dataType: "string",
    },
    {
      dataField: "partnership_type",
      caption: "Tipo de parceria",
      dataType: "string",
    },
  ],
  masterDetail: {
    enabled: true,
    component: ({
      data: {
        data: {
          user: { cpf },
        },
      },
    }) => <AdvisoredEntities identificationTuple={`cpf:${cpf}`} />,
  },
};
