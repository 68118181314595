import * as yup from "yup";

export interface StateMentHistoryFormValues {
  document: string;
  account: string;
  agency: string;
  startAt: string;
  endAt: string;
}

export const configureWorkTimeDefaultValues = {
  document: undefined,
  account: undefined,
  agency: undefined,
  startAt: new Date().toString(),
  endAt: new Date().toString(),
};

export const statementHistorySchema: yup.ObjectSchema<StateMentHistoryFormValues> =
  yup.object({
    document: yup.string().required("Este campo é obrigatório"),
    account: yup.string().required("Este campo é obrigatório"),
    agency: yup.string().required("Este campo é obrigatório"),
    startAt: yup.string().required("Este campo é obrigatório"),
    endAt: yup.string().required("Este campo é obrigatório"),
  });
