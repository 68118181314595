import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostApiSearchLegalEntityDetailsResponse } from "services/onboarding/models";
import { EligibilityForm } from "./eligibility.types";

export const useEligibility = () => {
  const { loading: loadingClear, callService: clearBCheckCache } =
    useServiceCall(service.onboarding.clearBCheckCache);

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] =
    useState<PostApiSearchLegalEntityDetailsResponse | null>(null);

  const form = useForm<EligibilityForm>();
  const { getValues, handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const { data } = await service.onboarding.getLegalEntityDetails({
        taxId: values.taxId,
      });

      setDetail(data);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  });

  const clearCache = useCallback(async () => {
    const payload = {
      tableClassName:
        "HubOnboardingBack.Application.DataCache.BCheck.BcheckDynamo",
      partitionKey: `#BCHECK#${getValues("taxId")}`,
      sortKey: ["0"],
      filter: "GreaterThan",
    };
    const { success } = await clearBCheckCache(payload);

    if (success) {
      submit();
    }
  }, [clearBCheckCache, getValues, submit]);

  return {
    clearCache,
    detail,
    form,
    loading,
    loadingClear,
    submit,
  };
};
