import BTGLogo from "assets/logo-min.svg";
import { AlignedLink } from "./logo.styles";

export const Logo = () => {
  return (
    <AlignedLink href="/">
      <BTGLogo height="30px" width="30px" />
    </AlignedLink>
  );
};
