import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useCardHiringContext } from "../../card-hiring.context";
import { CardHiringFormFields } from "./card-hiring-form/card-hiring-form.component";
import { CardHiringStatusNotification } from "./card-hiring-status-notification/card-hiring-status-notification.component";
import { useFillCardHiring } from "./fill-card-hiring.hook";

export const FillCardHiring = () => {
  const { fetch, fetchHasError, fetchLoading } = useCardHiringContext();

  const {
    form,
    submit,
    submitLoading,
    blockRenderCardHiringFormFieldsByOffer,
  } = useFillCardHiring();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Contratar cartão"
          description="Preencha as informações do cartão para fazer o pedido."
        />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Contratar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <DefaultErrorComponent
              title="Erro ao carregar os dados"
              callback={fetch}
            />
          }
          noValue
          render={() => (
            <div className="d-flex flex-column gap-3">
              {!blockRenderCardHiringFormFieldsByOffer && (
                <CardHiringFormFields form={form} />
              )}
              <CardHiringStatusNotification />
            </div>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
