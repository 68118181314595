import {
  OCard,
  OCardBody,
  ONotification,
  OSkeletonLine,
  OTypography,
} from "@maestro/react";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PixKeyInput } from "../pix-key-input";
import { usePixKeySearchCard } from "./pix-key-search-card.hook";
import { PixKeySearchFormField } from "./pix-key-search-card.types";
import { isAPotentialPixKey } from "./pix-key-search-card.utils";

export const PixKeySearchCard = () => {
  const { isLoading, getPixKey, errorMessage, pixKey, setErrorMessage } =
    usePixKeySearchCard();
  const form = useForm<PixKeySearchFormField>();
  const { watch } = form;

  const pixKeyValue: string = watch("pixKeyValue");
  const pixKeyType: BankingHub.PixKeyType | undefined = watch("pixKeyType");

  useEffect(() => {
    if (!pixKeyValue || !pixKeyType) {
      setErrorMessage("");
      return;
    }

    if (isAPotentialPixKey(pixKeyValue)) {
      getPixKey(pixKeyValue, pixKeyType);
    }
  }, [pixKeyValue, pixKeyType, getPixKey, setErrorMessage]);

  const showPixKeyDetails = useMemo(
    () => !errorMessage && !isLoading && !!pixKey?.key,
    [errorMessage, isLoading, pixKey?.key],
  );

  return (
    <FormProvider {...form}>
      <OCard>
        <OCardBody className="d-flex flex-column gap-3">
          <OTypography type="dark">
            Insira a chave Pix abaixo para consultar as informações
          </OTypography>
          <PixKeyInput isLoadingPixKey={isLoading} debounceTime={1200} />
          {!!errorMessage && (
            <ONotification
              type="danger"
              className="d-flex align-items-center"
              key={errorMessage}
            >
              {errorMessage}
            </ONotification>
          )}
          {showPixKeyDetails && (
            <div className="d-flex gap-3">
              <div className="d-flex flex-column col-4">
                <OTypography type="dark" size="sm">
                  Nome
                </OTypography>
                {isLoading ? (
                  <OSkeletonLine size="sm" />
                ) : (
                  <OTypography type="dark-80">
                    {pixKey?.owner?.name}
                  </OTypography>
                )}
              </div>
              <div className="d-flex flex-column col-4">
                <OTypography type="dark" size="sm">
                  Instituição
                </OTypography>
                {isLoading ? (
                  <OSkeletonLine size="sm" />
                ) : (
                  <OTypography type="dark-80">
                    {pixKey?.account?.bankData?.name}
                  </OTypography>
                )}
              </div>
              <div className="d-flex flex-column col-4">
                <OTypography type="dark" size="sm">
                  CPF/CNPJ
                </OTypography>
                {isLoading ? (
                  <OSkeletonLine size="sm" />
                ) : (
                  <OTypography type="dark-80">
                    {pixKey?.owner?.taxIdText}
                  </OTypography>
                )}
              </div>
            </div>
          )}
        </OCardBody>
      </OCard>
    </FormProvider>
  );
};
