import { modalManager } from "@maestro/core";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { PixKeySearchCard } from "components/pix-key-search-card";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { FiltersModal, PixKeysFilterModalId } from "./_compose/filters-modal";
import { PixKeysProvider, usePixKeysContext } from "./pix-keys.context";
import { grid } from "./pix-keys.grid";

const PixKeysComponent = () => {
  const gridRef = useGridRef();
  const { loading, pixKeysList } = usePixKeysContext();

  return (
    <div>
      <FiltersModal />

      <div className="d-flex flex-column gap-3">
        <PageTitle title="Chaves Pix" />

        <PixKeySearchCard />
        <GridTemplate
          gridRef={gridRef}
          actions={
            <div className="d-flex align-items-center gap-2">
              <IconButton
                dataAction=""
                dataLabel=""
                icon={{ category: "orq", icon: "orq-tools-filter" }}
                onClick={() => modalManager.show(PixKeysFilterModalId)}
              >
                Filtros
              </IconButton>
            </div>
          }
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            dataSource={pixKeysList}
            loading={loading}
          />
        </GridTemplate>
      </div>
    </div>
  );
};

export const PixKeys = () => {
  return (
    <PixKeysProvider>
      <PixKeysComponent />
    </PixKeysProvider>
  );
};
