import * as yup from "yup";

export const outputModalFormValidationSchema = yup.object().shape({
  type: yup.string().nullable().trim().required("Campo obrigatório"),
  description: yup.string().nullable().trim().required("Campo obrigatório"),
  isRequired: yup.array().default([]),
  contextStatusOnOutput: yup
    .string()
    .nullable()
    .trim()
    .required("Campo obrigatório"),
  configurableStatusId: yup.string().nullable(),
});

export const outputModalFormDefaultValues = {
  type: "",
  description: "",
  executeOnStart: [] as string[],
  contextStatusOnOutput: "",
  configurableStatusId: "",
};
