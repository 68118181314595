import { ITransactionDetailsInfo } from "./transaction-details.types";

export const TransactionGeneralDetails: Array<ITransactionDetailsInfo> = [
  {
    label: "Data",
    key: "date",
  },
  {
    label: "Horário",
    key: "hour",
  },
  {
    label: "Cartão Final",
    key: "cardLast4Digits",
  },
  {
    label: "Tipo do cartão",
    key: "cardType",
  },
  {
    label: "Valor em reais",
    key: "amount",
  },
  {
    label: "Tipo",
    key: "type",
  },
  {
    label: "Via",
    key: "via",
  },
  {
    label: "Categoria",
    key: "category",
  },
];

export const TransactionInternationalDetails: Array<ITransactionDetailsInfo> = [
  {
    label: "Valor na moeda de origem",
    key: "localCurrencyAmount",
  },
  {
    label: "Valor da compra em dólar",
    key: "settlementCurrencyAmount",
  },
  {
    label: "Cotação do dólar",
    key: "settlementCurrencyRate",
  },
  { label: "IOF", key: "iofValue" },
  { label: "DCC", key: "dcc" },
];

export const ChargebackInstructionWrongCaseType =
  "Para fazer uma contestação, seguir com abertura de um caso no Salesforce antes de entrar no Portal Admin.";

export const ChargebackInstructionAlreadyOpened =
  "Transação com contestação pendente. Aguardar resolução da contestação em aberto.";
