import { isAxiosError } from "axios";
import {
  HubOnboardingError,
  HubOnboardingErrorTypes,
} from "../models/responses/error";
import {
  OnboardingExceptionMap,
  domainExceptionMap,
  semaphoreExceptionMap,
  validationExceptionMap,
} from "./onboarding-map-errors";

const joinErrors = (errors: string[], separatorValue = "") => {
  if (separatorValue) return errors.join(separatorValue);

  return errors.join("\n");
};

const getMappedExceptionCode = (
  onboardingError: HubOnboardingError,
  exceptionMap: Record<string, OnboardingExceptionMap>,
  separatorValue = "",
): string => {
  const errors = onboardingError.failures
    .map((failure) => exceptionMap[failure.errorCode]?.message)
    .filter((error) => !!error);

  return joinErrors(errors, separatorValue);
};

const getMappedExceptionMessage = (
  onboardingError: HubOnboardingError,
  exceptionMap: Record<string, OnboardingExceptionMap>,
  separatorValue = "",
): string => {
  const errors = onboardingError.failures
    .map((failure) => exceptionMap[failure.errorMessage]?.message)
    .filter((error) => !!error);

  return joinErrors(errors, separatorValue);
};

export const getValidationOnboardingErrors = (
  error: HubOnboardingError,
  { separatorValue = "", onlyMappedErrorMessages = true } = {},
): string => {
  if (error.errorType !== HubOnboardingErrorTypes.ValidationException)
    return "";

  if (!onlyMappedErrorMessages)
    return joinErrors(error.failures.map((x) => x.errorMessage));

  const hasError = error.failures.some(
    (failure) => validationExceptionMap[failure.errorMessage],
  );

  if (hasError)
    return getMappedExceptionMessage(
      error,
      validationExceptionMap,
      separatorValue,
    );

  return joinErrors(error.failures.map((x) => x.errorCode));
};

export const getSemaphoreOnboardingErrors = (
  error: HubOnboardingError,
  separatorValue = "",
): string => {
  if (error.errorType !== HubOnboardingErrorTypes.SemaphoreException) return "";

  const hasError = error.failures.some(
    (failure) => semaphoreExceptionMap[failure.errorCode],
  );
  if (hasError)
    return getMappedExceptionCode(error, semaphoreExceptionMap, separatorValue);

  return joinErrors(error.failures.map((x) => x.errorCode));
};

export const getDomainOnboardingErrors = (
  error: HubOnboardingError,
  separatorValue = "",
): string => {
  if (error.errorType !== HubOnboardingErrorTypes.DomainException) return "";

  return getMappedExceptionCode(error, domainExceptionMap, separatorValue);
};

export const getOnboardingErrors = (
  error: HubOnboardingError,
  { separatorValue = "", onlyMappedErrorMessages = true } = {},
): string => {
  if (error.errorType === HubOnboardingErrorTypes.ValidationException)
    return getValidationOnboardingErrors(error, {
      separatorValue,
      onlyMappedErrorMessages,
    });

  if (error.errorType === HubOnboardingErrorTypes.SemaphoreException)
    return getSemaphoreOnboardingErrors(error, separatorValue);

  if (error.errorType === HubOnboardingErrorTypes.DomainException)
    return getDomainOnboardingErrors(error, separatorValue);

  return "";
};

export const parseOnboardingErrors = (
  err: unknown,
  { separatorValue = "", onlyMappedErrorMessages = true } = {},
): string => {
  if (!isAxiosError<HubOnboardingError>(err)) return "";

  const error = err.response?.data;

  if (!error) return "";

  return getOnboardingErrors(error, {
    separatorValue,
    onlyMappedErrorMessages,
  });
};
