import { env } from "utils/environments";

export const b2bCoreEndpoints = {
  admin: {
    customers: (originatorIdentification: string) =>
      `${env.API_B2B_CORE}/admin/client/${originatorIdentification}/customers`,
    operators: (originatorIdentification: string) =>
      `${env.API_B2B_CORE}/admin/client/${originatorIdentification}/operators`,
    originator: (originatorIdentification: string) =>
      `${env.API_B2B_CORE}/admin/client/${originatorIdentification}/details`,
  },
  assessors: {
    getPartnerByAuthOperator: `${env.API_B2B_CORE}/assessors/partner`,
  },
};
