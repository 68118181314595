import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "pages/workflow/routes/workflow.route-params";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  channelFormDefaultValues,
  channelFormValidationSchema,
} from "./processor-config-channel.schema";
import { WorkflowContext } from "pages/workflow/components/hooks/use-workflow.context";

export const useProcessorConfigChannelsPageContent = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const {
    workflowConfigViews: { value: workflowConfigViews },
    processorConfig: {
      value: selectedProcessorConfig,
      callService: getProcessorConfig,
    },
  } = useContext(WorkflowContext);

  const { value: channelList, callService: getAllChannels } = useServiceCall(
    service.hubCreditManager.getChannels,
  );

  const form = useForm({
    resolver: yupResolver(channelFormValidationSchema),
    defaultValues: channelFormDefaultValues,
  });

  const { handleSubmit, watch, setValue, reset } = form;
  const [loading, setLoading] = useState(false);

  const isWfEntryWatch = watch("isWfEntry");

  const getChannel = useCallback(async () => {
    if (!selectedProcessorConfig) return;
    setLoading(true);

    try {
      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsChannel(
          processorId,
        );
      reset({
        cardActionButtonDescription: data.cardActionButtonDescription,
        icon: data.icon,
        isWfEntry: data.isWfEntry ? ["1"] : [],
        showOnActions: data.showOnActions ? ["1"] : [],
        showOnTabs: data.showOnTabs ? ["1"] : [],
        showOnCards: data.showOnCards ? ["1"] : [],
        entryViews: data.entryViews.map((v) => v.type),
        entryChannels: data.entryChannels.map((c) => c.id.toString()),
      });
    } catch (err) {
      OToastManager.danger("Erro para retornar os canais.");
    } finally {
      setLoading(false);
    }
  }, [selectedProcessorConfig, processorId, reset, setValue, setLoading]);

  const updateChannel = handleSubmit(async (values) => {
    try {
      setLoading(true);
      await service.hubCreditManager.updateProcessorConfigDetailsChannel({
        ...values,
        processorConfigId: Number(processorId),
        isWfEntry: !!values.isWfEntry?.length,
        showOnActions: !!values.showOnActions?.length,
        showOnTabs: !!values.showOnTabs?.length,
        showOnCards: !!values.showOnCards?.length,
        entryViews: values.entryViews,
        entryChannels: values.entryChannels.map((c) => ({
          channelId: +c,
          subChannelId: null,
        })),
      });

      OToastManager.success("Canais alterados com sucesso.");
    } catch (err) {
      OToastManager.danger("Erro para salvar os canais.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getChannel();
  }, [getChannel]);

  useEffect(() => {
    if (!processorId) return;
    getProcessorConfig(processorId);
  }, [getProcessorConfig, processorId]);

  useEffect(() => {
    if (isWfEntryWatch?.length) {
      getAllChannels();
    }
  }, [isWfEntryWatch, getAllChannels]);

  return {
    form,
    isWfEntryWatch,
    updateChannel,
    workflowConfigViews,
    channelList,
    loading,
  };
};
