import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { workflowContextsGrid } from "./workflow-contexts.grid";

export const WorkflowContextsPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager.getWorkflowLeadProcessorContexts(id),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contextos" />}
      actions={
        <>
          <RouterButton
            dataAction="workflow_lead:botao:adicionar_contexto"
            dataLabel="adicionar_contexto"
            type="dark"
            outline
            role={roles.workflow.product.leadsDetailsContextsAddPage.role}
            href={corporateRouter.routes.workflow.product.leads.details.contexts.add.path(
              {
                id,
              },
            )}
          >
            Adicionar
          </RouterButton>
          <RefreshGridButton
            onClick={() => !dataSource?.isLoading() && dataSource?.reload()}
          />
        </>
      }
    >
      <ODataGridGenerator grid={workflowContextsGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
