import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const ReporteNotasNaoAntecipadasCedenteField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.reporteNotasNaoAntecipadasCedente}
      name="reporteNotasNaoAntecipadasCedente"
      description='Emite e-mails n dias após o cadastro da nota se não foram antecipadas. Configurar dias no campo: "Notificar depois de (dias) nota não antecipada'
    />
  );
};
