import { ILimitInfo } from "./limits.types";

export const LimitsCardInfo: Array<ILimitInfo> = [
  {
    key: "maxAmount",
    label: "Limite Concedido",
  },
  {
    key: "totalAmount",
    label: "Limite Contratado",
  },
  {
    key: "availableAmount",
    label: "Limite Disponível",
  },
  {
    key: "usedAmount",
    label: "Limite Utilizado",
  },
];
