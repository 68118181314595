import { Behaviors } from "contexts/behavior/types";
import { corbanMenuTabs } from "./corban-tabs";
import { MenuTabs } from "../tab-menu.types";
import { menuTabs } from "./tabs";

export const tabMenuBehavior: Record<Behaviors, MenuTabs> = {
  [Behaviors.Admin]: menuTabs,
  [Behaviors.CustomerService]: menuTabs,
  [Behaviors.RTB]: menuTabs,
  [Behaviors.Corban]: corbanMenuTabs,
};
