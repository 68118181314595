import { ODataGridGeneratorConfig } from "components/data-grid";
import { Tool } from "./tools.types";

export const grid: ODataGridGeneratorConfig<Tool> = {
  datagrid: {
    filterRow: { visible: true },
  },
  columns: [
    { dataField: "type", caption: "Tipo" },
    { dataField: "description", caption: "Descrição" },
    {
      caption: "Ação",
      cellRender: ({ data }) => data.action,
    },
  ],
  paging: {
    pageSize: 30,
  },
};
