import { OCard, OCardBody } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { cadastroRoles } from "../../../../../roles/cadastro.roles";
import { buildWorkflowDetailsFields } from "./account-workflow-details";
import { AccountsCard } from "./account-workflow/account-workflow-card.component";
import { useWorkflowDetails } from "./master-detailed-workflow.hook";
import { NotificationCard } from "./notification-workflow/notification-workflow-card.component";
import { ValidationCard } from "./validation-workflow/validation-workflow-card.component";

export const MasterDetailsAccount = () => {
  const {
    workflow,
    loading,
    hasError,
    getMasterDetailedWorkflow,
    workflowUuid,
  } = useWorkflowDetails();

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      value={workflow}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível encontrar o optin"
          messageParagraph="Clique no botão para tentar novamente."
        >
          <TryAgainButton
            onClick={() => getMasterDetailedWorkflow({ uuid: workflowUuid })}
          />
        </ErrorComponent>
      }
      emptyStateComponent={<EmptyState messageTitle="Sem dados do optin" />}
      render={() => (
        <div className="d-flex flex-column gap-4">
          <PageTitle title="Dados do Workflow" />
          <DetailsCard fields={buildWorkflowDetailsFields(workflow!)} />
          <OCard role={cadastroRoles.customer.checkLogsAccountOpening.role}>
            <OCardBody>
              <ValidationCard validations={workflow!.validations} />
            </OCardBody>
          </OCard>
          <OCard role={cadastroRoles.customer.checkLogsAccountOpening.role}>
            <OCardBody>
              <NotificationCard
                notifications={workflow!.notifications}
                refetch={() =>
                  getMasterDetailedWorkflow({ uuid: workflowUuid })
                }
              />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardBody>
              <AccountsCard
                accounts={workflow!.accounts}
                refetch={() =>
                  getMasterDetailedWorkflow({ uuid: workflowUuid })
                }
              />
            </OCardBody>
          </OCard>
        </div>
      )}
    />
  );
};
