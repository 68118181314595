import { OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { statesOptions } from "../wallet-invoices.utils";

export const StatusFilter = () => {
  return (
    <ORFieldSelectMultiple id="status" label="Status" name="status">
      {statesOptions.map((state) => (
        <OOptionMultiple key={state} value={state}>
          {state}
        </OOptionMultiple>
      ))}
    </ORFieldSelectMultiple>
  );
};
