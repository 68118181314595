export const capitalizeFirstLetter = (word: string) => {
  if (!word) return "-";
  if (word.length === 1) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizePhraseFirstLetters = (phrase: string) => {
  if (!phrase) return "-";
  const words = phrase.split(" ");
  const newPhrase = words.map((word: string) => {
    return capitalizeFirstLetter(word);
  });
  return newPhrase.join(" ");
};
