import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { IconButton } from "components/icon-button";
import {
  BasicFields,
  ProfileSelector,
  SimpleConfirmationModal,
  VigencySelectModal,
} from "../../../components/role-assignments";
import { FailureList } from "./_compose";
import { useUserRoleAssignments } from "./user-role-assignments.hook";
import { useNavigate, useParams } from "react-router-dom";
import { useFindUserFromCustomer } from "../../../hooks";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";

export const UserRoleAssignments = () => {

  const { identification } =
  useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const {
    ceilingVigencyDate,
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getInitialValues,
    initialValues,
    loading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
    editingPhoneValue,
  } = useUserRoleAssignments();

  const user = useFindUserFromCustomer(identification);

  const navigate = useNavigate();

  return (
    <>
      <FormTemplate
        pageTitle={
        <>
          <PageTitle title="Perfis de Acesso" />
          <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
            <IconButton
                dataAction="detalhes_usuarios:botao:perfis"
                dataLabel="impersonar"
                icon={{ category: "far", icon: "fa-door-open" }}
                onClick={() =>{
                    navigate(`${corporateRouter.routes.ferramentas.product.gatekeeper.users.path}/${user.cpf}/profiles-empresa`)
                  }
                }
              >
              Editar perfis
            </IconButton>
          </div>
        </>}
        actions={
          <LoadingButton
            dataAction="acoes:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            disabled={fetchHasError}
            onClick={preSubmit}
          >
            Enviar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <ContentTemplate
            loading={fetchLoading}
            hasError={fetchHasError}
            value={initialValues}
            errorComponent={
              <FailureList error={fetchError} refetch={getInitialValues} />
            }
            render={() => (
              <FormProvider {...form}>
                <BasicFields editing editingPhoneValue={editingPhoneValue} />
                <ProfileSelector
                  setCeilingVigencyDate={setCeilingVigencyDate}
                />
              </FormProvider>
            )}
          />
        </CardTemplate>
      </FormTemplate>
      <SimpleConfirmationModal
        message={profileValidationResponse?.message}
        submit={submit}
        editing
      />
      <VigencySelectModal
        message={profileValidationResponse?.message}
        submit={submit}
        maxVigency={ceilingVigencyDate}
        editing
      />
    </>
  );
};
