import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./onboarding-review.grid";

export const OnboardingReviewHistory = () => {
  const { customer } = useCustomer();
  const gridRef = useGridRef();
  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.clerk.admin
          .getCompanyDetails({
            taxId: customer.identification,
          })
          .then(({ data }) => data.dataReviews ?? []),
      ),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Histórico de revisão cadastral" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
