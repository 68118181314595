import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { OffDaysForm } from "../../calendars.types";
import { CalendarDateInput } from "../calendar-date-input";

export const grid = (
  offDays: OffDaysForm[],
  onAdd: (offDay: OffDaysForm) => void,
  disabled: boolean,
): ODataGridGeneratorConfig<OffDaysForm[][number]> => ({
  datagrid: {
    noDataText: "Nenhum feriado adicionado.",
    disabled,
    editing: {
      mode: "row",
      allowAdding: true,
      allowUpdating: ({ row }) => {
        return !(row?.data as OffDaysForm[][number]).isBase;
      },
      allowDeleting: ({ row }) => {
        return !(row?.data as OffDaysForm[][number]).isBase;
      },
      texts: {
        confirmDeleteMessage: "Tem certeza que deseja remover este feriado?",
      },
    },
    onRowInserted: ({ data }) => {
      onAdd(data as OffDaysForm);
    },
  },
  columns: [
    {
      dataField: "name",
      caption: "Feriado",
      allowSorting: false,
      validationRules: [
        { type: "required" },
        {
          type: "stringLength",
          max: 20,
          message: "Comprimento máximo é de 20 caracteres",
        },
      ],
    },
    {
      dataField: "date",
      caption: "Data",
      dataType: "date",
      format: "shortDate",
      sortOrder: "desc",
      allowSorting: false,
      validationRules: [{ type: "required" }],
      editCellRender: (props) => (
        <CalendarDateInput
          {...props}
          disabledDates={offDays.map((od) => dayjs(od.date).toDate())}
        />
      ),
    },
    {
      dataField: "isBase",
      caption: "É uma data do calendário",
      visible: false,
    },
  ],
});
