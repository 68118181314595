import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { logsGrid } from "./operations-logs.grid";

export const OperationsLogsPage = () => {
  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubLoan
          .getLoanDetails(id)
          .then(({ data }) => {
            return service.hubLoan.getLoanIntegrationLog({
              identification: data.identification,
            });
          })
          .catch(() => {
            throw new Error("Erro ao buscar logs");
          }),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Logs de Integração" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !dataSource?.isLoading() && dataSource?.reload();
          }}
        />
      }
    >
      <ODataGridGenerator grid={logsGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
