import { DetailsFields } from "components/details-card";
import { FilterItem } from "./lotes.type";

export const EmployeeOnboardingFields = (
  filtersList: FilterItem[],
): DetailsFields => {
  const totalFilters = filtersList.reduce(
    (final, filter) => (filter ? (filter.total || 0) + final : final),
    0,
  );
  const totalApproved = filtersList.find(
    (filter) => filter.value === "APROVADO",
  );
  const totalInAnalysis = filtersList.find(
    (filter) => filter.value === "EM_ANALISE_ONBOARDING",
  );
  const totalErrors = filtersList.find(
    (filter) => filter.value === "FALHA_VALIDACAO",
  );
  return {
    info: {
      items: [
        {
          label: "Quantidade de funcionários",
          value: totalFilters,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Concluídos",
          value: totalApproved?.total ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Em andamento",
          value: totalInAnalysis?.total ?? 0,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Erro",
          value: totalErrors?.total ?? 0,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  };
};
