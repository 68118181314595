import * as yup from "yup";

export const statusOptions = {
  CRIADO: "Criado",
  EM_ANDAMENTO: "Em andamento",
  FINALIZADO: "Finalizado",
};

export interface WorkflowUpdateContextStatusFormValues {
  newStatus: keyof typeof statusOptions;
  processorOutputId?: number;
}

export const workflowUpdateContextStatusValidationSchema: yup.ObjectSchema<WorkflowUpdateContextStatusFormValues> =
  yup.object({
    newStatus: yup
      .string<WorkflowUpdateContextStatusFormValues["newStatus"]>()
      .required("Campo obrigatório"),
    processorOutputId: yup.number().optional(),
  });
