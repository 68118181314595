export const toastTypeOptions = [
  {
    value: "INFO",
    text: "Info",
  },
  {
    value: "SUCCESS",
    text: "Success",
  },
  {
    value: "DANGER",
    text: "Danger",
  },
  {
    value: "WARNING",
    text: "Warning",
  },
];

export const submitModalTypes = [
  {
    value: "DEFAULT",
    text: "Default",
  },
  {
    value: "APPROVAL",
    text: "Approval",
  },
];
