import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTooltip } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import {
  AddPendencyModal,
  addPendencyModalId,
} from "./add-pendency-modal.component";

interface AddPendencyButtonProps {
  property: HubEnergy.DiligenceStepPropertySimpleResponse;
}

export const AddPendencyButton = ({ property }: AddPendencyButtonProps) => {
  return (
    <>
      <OTooltip floating position="top-left">
        <TooltipContent>Adicionar pendência</TooltipContent>
        <OButton
          type="dark"
          outline
          onClick={() => modalManager.show(addPendencyModalId)}
        >
          <OIcon category="fal" icon="fa-money-check-edit" />
        </OButton>
      </OTooltip>
      <AddPendencyModal property={property} />
    </>
  );
};
