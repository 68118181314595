import { StatusBadge } from "components/status-badge";
import { ContractState } from "services/hubloan/models";

type ContractGeneratorTemplate = {
  type: string;
  template: string;
  renderedTemplate?: string | null;
  contractState?: ContractState | null;
  status:
    | "RENDERIZADO"
    | "AGUARDANDO_INICIO"
    | "RENDERIZANDO"
    | "ERRO"
    | "RENDERIZADO_COM_ERRO";
};

interface ContractVariable {
  key: string;
  value: string;
}

export interface ContractGeneratorFormValues {
  taxId: string;
  creditLine: string | null;
  contractTemplates: ContractGeneratorTemplate[];
  contractVariables: ContractVariable[];
}

export const contractGeneratorStatusMap: Record<
  ContractGeneratorTemplate["status"],
  React.ComponentPropsWithRef<typeof StatusBadge>
> = {
  AGUARDANDO_INICIO: {
    type: "info",
    children: "Pronto para renderizar",
  },
  RENDERIZANDO: {
    type: "warning",
    children: "Renderizando...",
  },
  RENDERIZADO: {
    type: "success",
    children: "Renderizado",
  },
  RENDERIZADO_COM_ERRO: {
    type: "success",
    children: "Renderizado com pendências",
  },
  ERRO: {
    type: "danger",
    children: "Erro na renderização",
  },
};
