import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface MoveFileForm {
  documentFileId: number;
  destinationDocumentId: number;
}

export const moveFileFormFields = {
  documentFileId: {
    id: "documentFileId",
    name: "documentFileId",
    label: "Selecione o arquivo",
    placeholder: "Selecionar",
  },
} as const satisfies MaestroFields<MoveFileForm>;

export const moveFileFormValidationSchema = yup.object<
  MoveFileForm,
  YupObjectSchema<MoveFileForm>
>({
  documentFileId: yup.number().required("Campo obrigatório"),
  destinationDocumentId: yup.number().required("Campo obrigatório"),
});

export const moveFileFormDefaultValues = {
  destinationDocumentId: null,
  documentFileId: null,
} satisfies Partial<Nullable<MoveFileForm>> as unknown as MoveFileForm;
