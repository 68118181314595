import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { financedAssetPropertyContratualPatiesGrid } from "./financed-asset-property-contratual-parties.grid";
import { financedAssetPropertyRegistriesGrid } from "./financed-asset-property-registries.grid";
import { FinancedAsset } from "./financed-asset.types";

export const financedAssetPropertiesGrid = {
  datagrid: {
    noDataText: "Nenhum propriedade encontrada para o ativo financiado",
  },
  columns: [
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "registration",
      caption: "Matrícula",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "totalArea",
      caption: "Área total",
      format: (value) => `${masks.currency(value)} ha`,
    },
    {
      dataField: "ccir",
      caption: "CCIR",
    },
    {
      dataField: "nirf",
      caption: "NIRF",
    },
    {
      dataField: "rgi",
      caption: "RGI",
    },
    {
      dataField: "car",
      caption: "CAR",
    },
  ],
  masterDetail: {
    enabled: true,
    component: ({ data: { data } }) => (
      <div className="d-flex flex-column gap-4">
        <OTypography weight="bold">Partes relacionadas</OTypography>
        <ODataGridGenerator
          grid={financedAssetPropertyContratualPatiesGrid}
          dataSource={data.contractualParties}
        />

        <OTypography weight="bold">Cartórios</OTypography>
        <ODataGridGenerator
          grid={financedAssetPropertyRegistriesGrid}
          dataSource={data.registries}
        />
      </div>
    ),
  },
} satisfies ODataGridGeneratorConfig<FinancedAsset["properties"][number]>;
