import {
  OButton,
  OCol,
  ODivider,
  OFieldError,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { difference } from "lodash";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormValuesType } from "../../request-for-reports.types";
import { SortingButton, SortingOptionsUl } from "./sorting-options.styles";
import { reportTopicsOptions } from "./sorting-options.utils";

export const SortingOptions = () => {
  const form = useFormContext<FormValuesType>();
  const {
    watch,
    setValue,
    formState: { errors },
  } = form;

  const selectedValues = watch("columns");

  const availableOptions = useMemo(() => {
    const valuesAvailable = difference(
      reportTopicsOptions.map((x) => x.value),
      selectedValues || [],
    );

    return reportTopicsOptions.filter((x) => valuesAvailable.includes(x.value));
  }, [selectedValues]);

  const selectedOptions = useMemo(() => {
    return (
      selectedValues?.map((value) =>
        reportTopicsOptions.find((topic) => topic.value === value),
      ) || []
    );
  }, [selectedValues]);

  const onChangeSelected = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    val?: string,
  ) => {
    e.preventDefault();

    if (!val) return;

    const isSelected = selectedOptions.find((x) => x?.value === val);

    if (isSelected) {
      setValue(
        "columns",
        selectedValues?.filter((x) => x !== val),
      );
      return;
    }

    setValue("columns", [...(selectedValues || []), val]);
  };

  const onSelectAll = () => {
    setValue("columns", [
      ...(selectedValues || []),
      ...availableOptions.map((x) => x.value),
    ]);
  };

  return (
    <>
      <OTypography type="dark" className="mb-4">
        Selecione os tópicos e defina a ordem das colunas antes de gerar o
        arquivo
      </OTypography>

      <ORow className="d-flex w-100 mb-4">
        <OCol xs={5} class="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <OTypography type="dark" className="mb-4">
              Selecione os tópicos do arquivo
            </OTypography>

            <OButton
              dataAction="relatorios_em_lote:botao:selecionar_todas_colunas"
              dataLabel="selecionar_todas_colunas"
              size="sm"
              onClick={onSelectAll}
            >
              <div className="d-flex gap-2">
                Todos
                <OIcon
                  className="ml-2"
                  size="sm"
                  category="fal"
                  icon="fa-plus-circle"
                />
              </div>
            </OButton>
          </div>

          <SortingOptionsUl>
            {availableOptions?.map((x) => (
              <li key={x.value}>
                <SortingButton
                  type="button"
                  onClick={(e) => onChangeSelected(e, x.value)}
                >
                  <OIcon category="fal" icon="fa-plus-circle" />
                </SortingButton>

                <OTypography type="dark" tag="span">
                  {x.label}
                </OTypography>
              </li>
            ))}
          </SortingOptionsUl>
        </OCol>

        <OCol className="d-flex justify-content-center" sm={1}>
          <ODivider className="h-165 w-auto" size="xs" position="vertical" />
        </OCol>

        <OCol xs={5} className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <OTypography type="dark" className="mb-4">
              Ordem das colunas da planilha
            </OTypography>

            <OButton
              dataAction="relatorios_em_lote:botao:limpar_colunas_selecionadas"
              dataLabel="limpar_colunas_selecionadas"
              size="sm"
              outline
              onClick={() => setValue("columns", [])}
            >
              <div className="d-flex gap-2">
                Limpar
                <OIcon
                  className="ml-2"
                  size="sm"
                  category="fal"
                  icon="fa-minus-circle"
                />
              </div>
            </OButton>
          </div>

          <SortingOptionsUl>
            {selectedOptions?.map((x, idx) => {
              const label = `${idx + 1}. ${x?.label}`;

              return (
                <li
                  className="justify-content-between"
                  key={`options-${x?.label}-${x?.value}`}
                >
                  <OTypography type="dark" tag="span">
                    {label}
                  </OTypography>

                  <SortingButton
                    type="button"
                    onClick={(e) => onChangeSelected(e, x?.value)}
                  >
                    <OIcon category="fal" icon="fa-minus-circle" />
                  </SortingButton>
                </li>
              );
            })}
          </SortingOptionsUl>

          {!!errors.columns && (
            <OFieldError visible size="sm">
              {errors.columns.message}
            </OFieldError>
          )}
        </OCol>
      </ORow>
    </>
  );
};
