import { DetailsFields } from "components/details-card";
import { Apps } from "services/developers/models";
import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import dayjs from "dayjs";
import { StringList } from "./_compose/string-list.component";

export const buildAppDetailsFields = (app: Apps): DetailsFields => ({
  info: {
    items: [
      {
        label: "Nome",
        value: app.name,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF/CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(app.tenantId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={app.tenantId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Descrição",
        value: app.description,
        grid: { md: 4, xl: 4 },
      },
    ],
  },
  details: {
    title: "Detalhes",
    items: [
      {
        label: "Tipo",
        value: app.type,
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Client Channel",
        value: app.clientChannel,
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Status",
        value: app.verificationStatus,
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Engine",
        value: app.engine,
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Criado em",
        value: dayjs(app.createdAt).utc(false).format("DD-MM-YYYY HH:mm:ss"),
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Atualizado em",
        value: dayjs(app.updatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss"),
        grid: { md: 4, xl: 2 },
      },
    ],
  },
  lists: {
    title: "Listas",
    items: [
      {
        label: "Grant types",
        value: <StringList stringArray={app.grantTypes} />,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Redirect Uris",
        value: <StringList stringArray={app.redirectUris} />,
        grid: { md: 4, xl: 9 },
      },
      {
        label: "Scopes",
        value: (
          <StringList
            stringArray={app.products?.reduce((arr, item) => {
              item?.scopes?.map((x) =>
                arr.push(`${x.value} - ${x.description}`),
              );
              return arr;
            }, [] as string[])}
          />
        ),
        grid: { md: 4, xl: 12 },
      },
    ],
  },
});
