import * as yup from "yup";

export const basicDataFormValidationSchema = yup.object().shape({
  name: yup.string().nullable(),
  description: yup.string().nullable(),
});

export const basicDataFormDefaultValues = {
  name: "",
  description: "",
};
