import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useRelocate } from "../../relocate.context";
import { CancelButton, NextStepButton } from "../../_compose";
import { relocateSimulationGrid } from "./execution.grid";

export const Execution = () => {
  const gridRef = useGridRef();
  const {
    execute,
    simulatedRelocations,
    simulationHasError,
    simulationLoading,
  } = useRelocate();

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate
        pageTitle={
          <PageTitle description="A partir da simulação abaixo, você deseja executar essa realocação?" />
        }
        gridRef={gridRef}
        showClearFiltersButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          loading={simulationLoading}
          grid={relocateSimulationGrid}
          dataSource={simulatedRelocations}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <CancelButton />
        <NextStepButton disabled={simulationHasError} onClick={() => execute()}>
          Executar
        </NextStepButton>
      </div>
    </div>
  );
};
