import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { FileProcessor } from "./supplier-payments.type";
import {
  downloadPaymentsReport,
  fileStatusMap,
  getPaymentsGridFileStatus,
  getProcessableValuesFromFileSummary,
} from "./supplier-payments.utils";

export const filesGrid: ODataGridGeneratorConfig<FileProcessor> = {
  datagrid: {
    noDataText: "Nenhum arquivo encontrado.",
    remoteOperations: {
      paging: false,
      filtering: false,
    },
  },
  columns: [
    {
      dataField: "company.document",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),

      allowSorting: false,
    },
    {
      dataField: "company.name",
      dataType: "string",
      caption: "Empresa",

      allowSorting: false,
    },
    {
      dataType: "string",
      caption: "Processado",
      cellRender: ({ data }) => {
        const { fileSummary } = data.data;
        if (!fileSummary) return "-";

        const { processed } = getProcessableValuesFromFileSummary(fileSummary);

        return masks.currency(processed.amount, "R$");
      },
    },
    {
      dataType: "string",
      caption: "Não processado",
      cellRender: ({ data }) => {
        const { fileSummary } = data.data;
        if (!fileSummary) return "-";

        const { notProcessed } =
          getProcessableValuesFromFileSummary(fileSummary);

        return masks.currency(notProcessed.amount, "R$");
      },
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
      cellRender: ({ data }) => {
        const status = getPaymentsGridFileStatus(data);
        const { label, type } = getValueFromMap(fileStatusMap, status) ?? {
          label: data.status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      dataField: "createdAt",
      dataType: "date",
      caption: "Data",
      format: "shortDateShortTime",
      allowSorting: true,
      sortOrder: "desc",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        const fileId = data?.externalId?.value;
        const { fileSummary } = data.data;
        if (!fileId || !fileSummary) return;

        const { totalRecords, notProcessed } =
          getProcessableValuesFromFileSummary(fileSummary);

        const hasNotProcessedPayments = notProcessed.records > 0;

        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-download" },
                label: "Baixar relatório de todos os pagamentos",
                onClick: () =>
                  downloadPaymentsReport(
                    fileId,
                    totalRecords,
                    "validation",
                    "relatorio_pagamentos.xlsx",
                  ),
              },
              {
                icon: { category: "orq", icon: "orq-download" },
                label: "Baixar relatório de rejeitados",
                disabled: !hasNotProcessedPayments,
                onClick: () =>
                  downloadPaymentsReport(
                    fileId,
                    totalRecords,
                    "rejected",
                    "relatorio_pagamentos_rejeitados.xlsx",
                  ),
              },
            ]}
          />
        );
      },
    },
  ],
};
