import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetOperationsAttendanceByOperationIdResponse } from "services/quickfin/models";
import { format } from "utils/date";
import {
  ReceivableStatus,
  ReceivableStatusLabel,
} from "./operations-details.types";

export const grid: ODataGridGeneratorConfig<
  NonNullable<GetOperationsAttendanceByOperationIdResponse["trades"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhuma operação encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} operações)",
    },
  },
  columns: [
    {
      dataField: "receivable.identification",
      caption: "Nº da nota",
    },
    {
      caption: "Situação",
      dataField: "receivable.receivableStatus",
      format: (value) =>
        ReceivableStatusLabel[value as ReceivableStatus] ?? value,
    },
    {
      dataField: "receivable.sacado.officialName",
      caption: "Razão social",
    },
    {
      dataField: "receivable.sacado.identification",
      caption: "CPF/CNPJ",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "receivable.maturityDate",
      caption: "Vencimento",
      format: (value) => format.date(value),
    },
    {
      dataField: "taxaMensal",
      caption: "Taxa mensal",
    },
    {
      dataField: "maturityAmount",
      caption: "Valor de face",
      format: (value) => masks.currency(value, "R$", "."),
    },
    {
      caption: "Valor de pagamento",
      // this is wrong even in master
      customizeText: () => "-",
    },
    {
      dataField: "bankAccount.accountType",
      caption: "Tipo da conta",
    },
    {
      dataField: "bankAccount.agency",
      caption: "Agência",
    },
    {
      dataField: "bankAccount.accountNumber",
      caption: "Número da conta",
    },
    {
      dataField: "bankAccount.accountDigit",
      caption: "Dígito da conta",
    },
    {
      dataField: "bankAccount.bankCode",
      caption: "Código do banco",
    },
  ],
};
