import { logger } from "utils/logger";
import { GetUserCommand, userManager } from "../../user";
import { GetTokenFromStorage, authManager } from "../../auth";
import { Behaviors } from "../types/behavior-ids";
import { BehaviorConfiguration } from "../types/config";
import { BehaviorRoles } from "../types/roles";

export const corbanBehavior = {
  id: Behaviors.Corban,
  title: "Assessor",
  homepage: "/inicio",
  domains: ["empresas.access.btgpactual.com"],
  roles: [
    BehaviorRoles.HeaderBehavior_Show,
    BehaviorRoles.HeaderCustomerSelector_Show,
    BehaviorRoles.HeaderLogo_Show,
    BehaviorRoles.HeaderMenu_Show,
    BehaviorRoles.HeaderUserMenu_Show,
    BehaviorRoles.HeaderCustomerSelectorFetch_Enable,
    BehaviorRoles.SessionStorage_Enable,
    BehaviorRoles.GridExport_Show,
    BehaviorRoles.ShareLinkButton_Show,
  ],
  init() {
    this.auth();
  },
  async auth() {
    try {
      await authManager.execute(new GetTokenFromStorage());
      await userManager.execute(new GetUserCommand());
    } catch (err) {
      logger.error(err);
    }
  },
} satisfies BehaviorConfiguration;
