import { OTypography } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { buildOriginatorFields } from "./originator.fields";
import { useOriginator } from "./originator.hook";

export const OriginatorPage = () => {
  const { loading, hasError, originatorData, fetchOriginator } =
    useOriginator();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do originador" />}>
      <DetailsCard
        loading={loading}
        hasError={hasError}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível buscar os dados do originador"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => fetchOriginator()} />
          </ErrorComponent>
        }
        emptyStateComponent={
          <OTypography>Esta empresa não é um originador.</OTypography>
        }
        value={originatorData}
        fields={buildOriginatorFields}
      />
    </DetailsTemplate>
  );
};
