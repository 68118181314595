import { StyleOptions } from "./fullscreen-container.types";

type NullableOrUndefineHTMLElement = HTMLElement | undefined | null;

/**
 * Sets `style` properties of given HTML element.
 * Handles undefined/null element.
 * Auto convert numeric values to "px" strings.
 */
export const setStyle = (
  element: NullableOrUndefineHTMLElement,
  style: StyleOptions,
) => {
  if (!element) return;
  const stringStyles: Record<string, string> = {};
  const numericStyles: Record<string, number> = {};

  Object.entries(style).forEach(([key, value]) => {
    if (typeof value === "string") {
      stringStyles[key] = value;
    } else if (typeof value === "number") {
      numericStyles[key] = value;
    }
  });

  Object.entries(stringStyles).forEach(([key, value]) => {
    element.style.setProperty(key, value);
  });

  Object.entries(numericStyles).forEach(([key, value]) => {
    element.style.setProperty(key, `${value}px`);
  });
};

/**
 * Clear style `properties` of element`.
 */
export const unsetStyle = (
  element: NullableOrUndefineHTMLElement,
  properties: Array<keyof CSSStyleDeclaration>,
) => {
  if (!element) return;
  properties.forEach((property) => {
    element.style.setProperty(property as string, "");
  });
};

/**
 * Set element inset to 0.
 */
export const setInsetZero = (element: NullableOrUndefineHTMLElement) => {
  if (!element) return;
  setStyle(element, {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });
};
