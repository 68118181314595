import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  OutputConfigBigNumberItem,
  OutputConfigStatusItem,
} from "services/hubcreditmanager/models/requests";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../../routes/workflow.route-params";
import {
  ProcessorConfigMonitoringContentProps,
  UpsertOutputConfigBigNumbersType,
  UpsertOutputConfigStatusType,
} from "./processor-config-monitoring.types";
import { getOutputComponentsIds } from "./processor-config-monitoring.utils";

export const useProcessorConfigMonitoringPage = ({
  workflowConfigView,
}: ProcessorConfigMonitoringContentProps) => {
  const { id: workflowId, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();

  if (!workflowId || !processorId) throw new Error("No id");
  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const [saving, setSaving] = useState(false);

  const form = useForm();
  const { getValues } = form;

  const { callService: callUpsertOutputConfigBigNumbers } = useServiceCall(
    service.hubCreditManager.upsertOutputConfigBigNumbers,
  );

  const { callService: callUpsertOutputConfigStatus } = useServiceCall(
    service.hubCreditManager.upsertOutputConfigStatus,
  );

  const upsertOutputConfigStatus = useCallback(
    async (payload: UpsertOutputConfigStatusType) => {
      const { error } = await callUpsertOutputConfigStatus(payload);

      if (error)
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar uma das saídas desta configuração.",
        );
    },
    [callUpsertOutputConfigStatus],
  );

  const upsertOutputConfigBigNumbers = useCallback(
    async (payload: UpsertOutputConfigBigNumbersType) => {
      const { error } = await callUpsertOutputConfigBigNumbers(payload);

      if (error)
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar os big numbers desta configuração.",
        );
    },
    [callUpsertOutputConfigBigNumbers],
  );

  const updateProcessorConfigViewProperties = useCallback(async () => {
    if (!workflowConfigView.processorOutputConfig) return;

    try {
      setSaving(true);

      const outputConfigStatus: OutputConfigStatusItem[] = [];
      const outputConfigBigNumbers: OutputConfigBigNumberItem[] = [];
      const payloadDefault = {
        workflowConfigViewId:
          workflowConfigView.workflowConfigView.workflowConfigViewId,
        processorConfigId: workflowConfigView.processorConfigId,
      };

      workflowConfigView.processorOutputConfig.forEach((output) => {
        const outputComponentsIds = getOutputComponentsIds(
          workflowConfigView.workflowConfigView,
          output.id,
        );

        const statusId = getValues(outputComponentsIds.status);
        const bigNumberIds = getValues(outputComponentsIds.bigNumber).map(
          (id: string) => Number(id),
        );

        outputConfigStatus.push({
          configurableStatusId: statusId > 0 ? statusId : null,
          outputId: output.id,
        });

        outputConfigBigNumbers.push({
          bigNumberIds,
          outputId: output.id,
        });
      });

      // Chamadas para a API em série para não criar múltiplas entidades
      await upsertOutputConfigStatus({
        ...payloadDefault,
        outputConfigs: outputConfigStatus,
      });

      await upsertOutputConfigBigNumbers({
        ...payloadDefault,
        outputConfigs: outputConfigBigNumbers,
      });

      OToastManager.success("Configuração de monitoramento salva com sucesso.");
    } catch {
      OToastManager.danger(
        "Um erro ocorreu ao tentar salvar a configuração de monitoramento. Por favor, tente novamente mais tarde.",
      );
    } finally {
      setSaving(false);
    }
  }, [
    workflowConfigView,
    getValues,
    upsertOutputConfigBigNumbers,
    upsertOutputConfigStatus,
  ]);

  return {
    form,
    saving,
    updateProcessorConfigViewProperties,
  };
};
