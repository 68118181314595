/* eslint-disable import/newline-after-import */
import { useTheme } from "contexts/theme";
import { Bar } from "react-chartjs-2";
import { GetCardCardsSummaryResponse } from "services/admin-bankinghub/card/models";

// register used components
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  Tooltip,
} from "chart.js";
Chart.register(BarElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(Tooltip);

const chartConfig = {
  enabledBlackQuantity: {
    label: "Black",
    backgroundColor: {
      dark: "rgb(0, 0, 0)",
      light: "rgba(38, 38, 38, 0.6)",
    },
    borderColor: {
      dark: "#ffffff",
      light: "rgba(38, 38, 38, 1)",
    },
  },
  enabledGoldQuantity: {
    label: "Gold",
    backgroundColor: {
      dark: "rgba(255, 219, 57, 0.8)",
      light: "rgba(241, 196, 0, 0.6)",
    },
    borderColor: {
      dark: "rgba(255, 219, 57, 1)",
      light: "rgba(241, 196, 0, 1)",
    },
  },
  enabledPlatinumQuantity: {
    label: "Platinum",
    backgroundColor: {
      dark: "rgba(193, 207, 212, 0.8)",
      light: "rgba(162, 170, 173, 0.6)",
    },
    borderColor: {
      dark: "#ffffff",
      light: "rgba(162, 170, 173, 1)",
    },
  },
};

interface CardVariantsBarChartProps {
  variants: GetCardCardsSummaryResponse["variants"];
}

export const CardVariantsBarChart = ({
  variants,
}: CardVariantsBarChartProps) => {
  const { theme } = useTheme();

  const enabledBlackQuantity =
    variants.find((variant) => variant.type === "BLACK")?.enabledQuantity ?? 0;
  const enabledGoldQuantity =
    variants.find((variant) => variant.type === "GOLD")?.enabledQuantity ?? 0;
  const enabledPlatinumQuantity =
    variants.find((variant) => variant.type === "PLATINUM")?.enabledQuantity ??
    0;

  return (
    <Bar
      data={{
        labels: [
          chartConfig.enabledBlackQuantity.label,
          chartConfig.enabledGoldQuantity.label,
          chartConfig.enabledPlatinumQuantity.label,
        ],
        datasets: [
          {
            label: "Variantes de Cartão",
            data: [
              enabledBlackQuantity,
              enabledGoldQuantity,
              enabledPlatinumQuantity,
            ],
            backgroundColor: [
              chartConfig.enabledBlackQuantity.backgroundColor[theme],
              chartConfig.enabledGoldQuantity.backgroundColor[theme],
              chartConfig.enabledPlatinumQuantity.backgroundColor[theme],
            ],
            borderColor: [
              chartConfig.enabledBlackQuantity.borderColor[theme],
              chartConfig.enabledGoldQuantity.borderColor[theme],
              chartConfig.enabledPlatinumQuantity.borderColor[theme],
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
};
