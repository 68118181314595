import {
  modalManager,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  ORow,
  OSkeletonCardNode,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { useMemo } from "react";
import { mapCurrencySymbol } from "utils/currency";
import { useSimulation } from "../../simulation/simulation.context";
import { CALENDAR_MODAL } from "../calendar-modal/calendar.component";

export const SimulationTotalCard = () => {
  const { form, loading, selectedSimulationResult } = useSimulation();

  const totalAmount = selectedSimulationResult?.settlement?.amount ?? 0;

  const installmentAmount =
    selectedSimulationResult?.settlement.installments[0].amount ?? 0;

  const installmentsLength =
    selectedSimulationResult?.settlement?.installments?.length;

  const hasMultipleInstallments = installmentsLength
    ? installmentsLength > 1
    : false;

  const currency = selectedSimulationResult?.currency;

  const { watch } = form;

  const termWatch = watch("termOptions");

  const SimulationTotalCardMemo = useMemo(() => {
    return (
      <>
        {!!termWatch && (
          <OSkeletonCardNode
            loading={loading}
            height="110px"
            width="100%"
            rounded
          >
            <OCard type="info-light">
              {loading && <OLoader absolute />}
              <OCardBody className="d-flex flex-column">
                <ORow className="d-flex align-items-center">
                  <OCol>
                    <OTypography
                      size="lg"
                      weight="bold"
                      key={`${hasMultipleInstallments}-total-card`}
                    >
                      {hasMultipleInstallments ? "Total a prazo" : "Total"}
                    </OTypography>
                  </OCol>
                  <OCol className="d-flex justify-content-end">
                    <ORow
                      style={{
                        cursor: hasMultipleInstallments ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (hasMultipleInstallments) {
                          modalManager.show(CALENDAR_MODAL);
                        }
                      }}
                    >
                      <OCol className="d-flex flex-column align-items-center">
                        <OTypography
                          className={`${hasMultipleInstallments ? "mb-2" : ""}`}
                        >
                          {masks.currency(
                            totalAmount,
                            mapCurrencySymbol(currency),
                          )}
                        </OTypography>
                        {hasMultipleInstallments && (
                          <OTypography
                            key={`installments-${installmentsLength}x`}
                          >
                            {`Em ${installmentsLength}x de ${masks.currency(
                              installmentAmount,
                              mapCurrencySymbol(currency),
                            )}`}
                          </OTypography>
                        )}
                      </OCol>
                      <OCol lg={1}>
                        <OIcon category="fal" icon="fa-chevron-right" />
                      </OCol>
                    </ORow>
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
          </OSkeletonCardNode>
        )}
      </>
    );
  }, [
    currency,
    hasMultipleInstallments,
    installmentAmount,
    installmentsLength,
    loading,
    termWatch,
    totalAmount,
  ]);

  return <>{SimulationTotalCardMemo}</>;
};
