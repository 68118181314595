import { yupResolver } from "@hookform/resolvers/yup";
import { ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  CreateProfileRequestFormValues,
  createProfileRequestValidationFormSchema,
} from "./create-profile-request.form";

export const CreateProfileRequestPage = () => {
  const form = useForm<CreateProfileRequestFormValues>({
    resolver: yupResolver(createProfileRequestValidationFormSchema),
  });

  const { handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const { data } = await service.gatekeeper.createNewRequest({
            title: values.title,
          });

          OToastManager.success("Solicitação criada com sucesso");

          navigate(
            corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement.details.path(
              {
                requestId: data.id,
              },
            ),
          );
        } catch {
          OToastManager.danger("Erro ao criar solicitação");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar solicitação" />}
      actions={
        <LoadingButton
          dataAction="criar_solicitacao_perfil:input:criar"
          dataLabel="criar"
          loading={loading}
          onClick={submit}
        >
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORFieldInput
            id="title"
            name="title"
            tag="text"
            dataAction="criar_solicitacao_perfil:input:titulo"
            dataLabel="titulo"
            label="Informe um título para começar a montar a atualização"
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
