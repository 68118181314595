import { useMemo } from "react";
import { useEditStepConfigContext } from "../../edit-step-config.context";
import { buildDataSource } from "./property-configs-grid.grid";

export const usePropertyConfigsGrid = (diligenceStepConfigId: number) => {
  const { propertyConfigsFetch } = useEditStepConfigContext();

  const dataSource = useMemo(
    () => buildDataSource(diligenceStepConfigId, propertyConfigsFetch),
    [diligenceStepConfigId, propertyConfigsFetch],
  );

  return {
    dataSource,
  };
};
