import styled from "styled-components";

export const TooltipContent = styled.div`
  text-align: justify;
  padding: 0.5rem 0;
  line-height: 1.4rem;

  ul {
    margin: 0;
    margin-top: 0.25rem;
  }
`;
