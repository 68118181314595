import {
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  modalManager,
} from "@maestro/react";
import { DefaultErrorComponent, ErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { useMemo } from "react";
import { ContractTemplateSelector } from "../../../../components/contract-template-selector";
import {
  contractTypeMap,
  documentTypeMap,
  countryCodeMap
} from "../../../../utils";
import { SacadoDetailsForm } from "../../sacados/detalhes/sacado-details.types";
import {
  FilteredEstrategiaSelector,
  UpdateReceivablesConfirmationModal,
  updateReceivablesConfirmationModalId,
  ContractComplements,
} from "./_compose";
import { useCedenteDetails } from "./cedente-details.hook";
import { CedenteDetailsForm } from "./cedente-details.types";

export const CedenteDetails = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    addCedente,
    addCompanyCedenteLoading,
    cedente,
    disableContractTemplateId,
    disableReceiptTemplateId,
    editCedente,
    editCompanyCedenteLoading,
    error,
    form,
    getCedente,
    isCreating,
    loading,
    contractTemplate,
    retrieveSelectedTemplate,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
  } = useCedenteDetails();

  const { watch, trigger } = form;

  const entityTypeWatcher = watch("companyEntityType");
  const desativaFornecedorWatcher = watch("desativaFornecedor");
  const contractTypeWatcher = watch("tipoContrato");
  const _originalDesativaFornecedorWatcher = watch(
    "_originalDesativaFornecedor",
  );

  // CAREFUL: DELICATE LOGIC!!! _originalDesativaFornecedor can be undefined
  const showJustificative =
    (desativaFornecedorWatcher === "yes" &&
      _originalDesativaFornecedorWatcher !== "yes") ||
    (desativaFornecedorWatcher === "no" &&
      _originalDesativaFornecedorWatcher === "yes");

  const showComplement = useMemo(
    () =>
      contractTypeWatcher === "PorOperacao" && contractTemplate?.hasComplement,
    [contractTypeWatcher, contractTemplate],
  );

  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle
            title={isCreating ? "Adicionar cedente" : "Detalhes do cedente"}
          />
        }
        actions={
          <LoadingButton
            loading={editCompanyCedenteLoading || addCompanyCedenteLoading}
            disabled={!isCreating && (!cedente || loading)}
            onClick={async () => {
              if (isCreating) {
                addCedente();
              } else if (await trigger())
                modalManager.show(updateReceivablesConfirmationModalId);
            }}
          >
            {isCreating ? "Enviar" : "Salvar"}
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ContentTemplate
              loading={loading}
              hasError={error !== "NONE"}
              errorComponent={
                error === "FETCH" ? (
                  <DefaultErrorComponent
                    title="Erro ao buscar o cedente"
                    callback={getCedente}
                  />
                ) : (
                  <ErrorComponent messageTitle="Este cedente não está associado a esta empresa" />
                )
              }
              noValue
              render={() => (
                <ORow gap={3}>
                  <OCol xs={12} md={6}>
                    <ORFieldSelect
                      id="companyEntityType"
                      name="companyEntityType"
                      label="Tipo do documento"
                      placeholder="Selecionar"
                      required
                    >
                      {Object.values(documentTypeMap).map(
                        ({ label, value }) => (
                          <OOption key={value} value={value}>
                            {label}
                          </OOption>
                        )
                      )}
                    </ORFieldSelect>
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldSelect
                      id="country"
                      name="country"
                      label="País"
                      placeholder="Selecionar"
                      disabled={isCreating ? false : true}
                      required
                    >
                      {Object.values(countryCodeMap).map(
                        ({ label, value }) => (
                          <OOption key={value} value={value}>
                            {label}
                          </OOption>
                        )
                      )}
                    </ORFieldSelect>
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      id="identification"
                      name="identification"
                      label="Identificação"
                      placeholder="Preencher"
                      tag={
                        getValueFromMap(documentTypeMap, entityTypeWatcher)
                          ?.inputTag ?? "text"
                      }
                      key={entityTypeWatcher}
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      id="officialName"
                      name="officialName"
                      label="Razão social"
                      placeholder="Preencher"
                      tag="text"
                      required
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      id="identificadorExterno"
                      name="identificadorExterno"
                      label="Código do fornecedor"
                      placeholder="Preencher"
                      tag="text"
                      tooltip="(Legado! Verificar uso do NIF!) Código do fornecedor para o sacado. Esse código serve para identificar importações de contrapartes que não possuem CNPJ. Quando BRF Offshore, por exemplo, importa um recebível de um fornecedor estrangeiro sem CPF/CNPJ."
                      tooltipMaxWidth="600px"
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      id="cge"
                      name="cge"
                      label="CGE"
                      placeholder="Preencher"
                      tag="text"
                      tooltip="CGE para os fornecedores que não possuem CNPJ. Portanto, é necessário somente para contrapartes sem CNPJ. Este campo, em conjunto com o Código do Fornecedor, serve para identificarmos uma contraparte quando um sacado importa recebíveis."
                      tooltipMaxWidth="600px"
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <FilteredEstrategiaSelector
                      form={
                        form as UseFormReturn<
                          CedenteDetailsForm | SacadoDetailsForm
                        >
                      }
                      tipoProduto={cedente?.estrategia?.tipoProduto ?? null}
                      companyId={quickfinId}
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldSelect
                      id="tipoContrato"
                      name="tipoContrato"
                      label="Tipo de contrato"
                      placeholder="Selecionar"
                      required
                    >
                      {Object.entries(contractTypeMap).map(([value, label]) => (
                        <OOption key={value} value={value}>
                          {label}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ContractTemplateSelector
                      label="Modelo de termo de cessão"
                      companyId={quickfinId}
                      name="contractTemplateId"
                      disabled={disableContractTemplateId}
                      required={!disableContractTemplateId}
                      onChange={retrieveSelectedTemplate}
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ContractTemplateSelector
                      label="Modelo de comprovante da operação"
                      companyId={quickfinId}
                      name="receiptTemplateId"
                      disabled={disableReceiptTemplateId}
                    />
                  </OCol>
                  <OCol xs={12} md={3}>
                    <ORFieldRadioGroup
                      id="antecipaAutomaticamente"
                      name="antecipaAutomaticamente"
                      label="Antecipa automaticamente"
                      tooltip="Todas as notas que foram aprovadas pelo sacado e pelo BTG serão antecipadas automaticamente."
                      tooltipMaxWidth="600px"
                    >
                      <div className="d-flex gap-4">
                        <div className="d-flex gap-2">
                          <ORadio
                            id="antecipaAutomaticamente-yes"
                            value="yes"
                          />
                          <OLabel htmlFor="antecipaAutomaticamente-yes">
                            Sim
                          </OLabel>
                        </div>
                        <div className="d-flex gap-2">
                          <ORadio id="antecipaAutomaticamente-no" value="no" />
                          <OLabel htmlFor="antecipaAutomaticamente-no">
                            Não
                          </OLabel>
                        </div>
                      </div>
                    </ORFieldRadioGroup>
                  </OCol>
                  <OCol xs={12} md={3}>
                    <ORFieldRadioGroup
                      id="permiteOverrideEstrategia"
                      name="permiteOverrideEstrategia"
                      label="Permite override estratégia (Parceiro define as taxas?)"
                    >
                      <div className="d-flex gap-4">
                        <div className="d-flex gap-2">
                          <ORadio
                            id="permiteOverrideEstrategia-yes"
                            value="yes"
                          />
                          <OLabel htmlFor="permiteOverrideEstrategia-yes">
                            Sim
                          </OLabel>
                        </div>
                        <div className="d-flex gap-2">
                          <ORadio
                            id="permiteOverrideEstrategia-no"
                            value="no"
                          />
                          <OLabel htmlFor="permiteOverrideEstrategia-no">
                            Não
                          </OLabel>
                        </div>
                      </div>
                    </ORFieldRadioGroup>
                  </OCol>
                  <OCol xs={12} md={3}>
                    <ORFieldRadioGroup
                      id="desativaFornecedor"
                      name="desativaFornecedor"
                      label="Desativa fornecedor"
                    >
                      <div className="d-flex gap-4">
                        <div className="d-flex gap-2">
                          <ORadio id="desativaFornecedor-yes" value="yes" />
                          <OLabel htmlFor="desativaFornecedor-yes">Sim</OLabel>
                        </div>
                        <div className="d-flex gap-2">
                          <ORadio id="desativaFornecedor-no" value="no" />
                          <OLabel htmlFor="desativaFornecedor-no">Não</OLabel>
                        </div>
                      </div>
                    </ORFieldRadioGroup>
                  </OCol>
                  {showJustificative && (
                    <OCol xs={12}>
                      <ORFieldInput
                        id="justificative"
                        name="justificative"
                        label="Motivo de desativar/ativar o fornecedor"
                        placeholder="Preencher"
                        tag="text"
                        required
                      />
                    </OCol>
                  )}
                  {!isCreating && desativaFornecedorWatcher === "yes" && (
                    <OCol xs={12} md={3}>
                      <ORFieldRadioGroup
                        id="cancelContract"
                        name="cancelContract"
                        label="Cancela contratro cessão futura"
                        tooltip="Cencela contrato de cessão futura ao desativar o fornecedor."
                        tooltipMaxWidth="600px"
                        required
                      >
                        <div className="d-flex gap-4">
                          <div className="d-flex gap-2">
                            <ORadio id="cancelContract-yes" value="yes" />
                            <OLabel htmlFor="cancelContract-yes">Sim</OLabel>
                          </div>
                          <div className="d-flex gap-2">
                            <ORadio id="cancelContract-no" value="no" />
                            <OLabel htmlFor="cancelContract-no">Não</OLabel>
                          </div>
                        </div>
                      </ORFieldRadioGroup>
                    </OCol>
                  )}
                  {showComplement && (
                    <ContractComplements
                      emailsOptions={emailsOptions}
                      loadingEmails={loadingEmails}
                      loadingComplements={loadingContractComplements}
                    />
                  )}
                </ORow>
              )}
            />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <UpdateReceivablesConfirmationModal submit={editCedente} />
    </>
  );
};
