import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { calculateNameAndTaxIdFilterExpression } from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const dataSource = dataSourceCustomStoreGenerator(() =>
  service.onboarding
    .getDefaultContractFileConfiguration()
    .then(({ data }) => data ?? [])
    .catch(() => {
      const validator = "Erro ao buscar as configurações";

      OToastManager.danger(validator);
      throw new Error(validator);
    }),
);

export const defaultContractFileConfigurationGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDefaultContractFileConfiguration>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma configuração",
    headerFilter: { visible: true },
    filterRow: { visible: true },
  },
  columns: [
    {
      role: roles.ferramentas.product
        .onboardingWorkflowsConfigsDefaultContractFileConfigurationDetailsPage
        .role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.defaultContractFileConfiguration.details.path(
            {
              uuid: data.uuid,
            },
          )}
        />
      ),
    },
    {
      caption: "Configuração de arquivo de contrato",
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.contractFileConfigurationFileName}</OTypography>
          <OTypography type="default">
            {data.contractFileConfigurationDescription}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        [
          "contractFileConfigurationFileName",
          "contractFileConfigurationDescription",
        ],
        [],
      ),
      allowHeaderFiltering: false,
    },
    {
      dataField: "workflowRelationship",
    },
    {
      dataField: "isPJConfig",
    },
    {
      dataField: "isPFConfig",
    },
    {
      dataField: "uuid",
      allowFiltering: false,
    },
  ],
};
