import {
  IButtonMiddleNode,
  ICheckboxMiddleNode,
  IHTMLMiddleNode,
  IInitialNode,
  ITextMiddleNode,
} from "../chargeback.types";

export interface IQuestionButtonProps {
  questionIndex: number;
  data: IInitialNode | IButtonMiddleNode;
  registerAnswer: Function;
  executeAction: Function;
  isEditable: boolean;
}

export interface IQuestionCheckboxProps {
  questionIndex: number;
  data: ICheckboxMiddleNode;
  executeAction: Function;
  isEditable: boolean;
}

export type CheckboxResponseValue =
  | "Telefone"
  | "Whatsapp"
  | "Presencial"
  | "E-mail";

export interface ICheckboxResponse {
  label: "Canais:";
  value: CheckboxResponseValue;
  type: "checkbox";
}

export const MapperLabelLinkCheckbox: Record<CheckboxResponseValue, string> = {
  Telefone: "selectChannelPhone",
  Whatsapp: "selectChannelWhatsApp",
  Presencial: "selectChannelPresent",
  "E-mail": "selectChannelEmail",
};

export interface IQuestionTextProps {
  questionIndex: number;
  data: ITextMiddleNode;
  maximumValue: number;
  executeAction: Function;
  isEditable: boolean;
  inputLinksChecked?: string[];
}

export type QuestionTextType =
  | "email"
  | "datetime-local"
  | "tel"
  | "text"
  | "number";

export interface IQuestionHtmlProps {
  questionIndex: number;
  data: IHTMLMiddleNode;
  executeAction: Function;
  isEditable: boolean;
}
