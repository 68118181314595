import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, energyTasksGrid } from "./energy-tasks.grid";
import { BatchLetterOfComplaintButton } from "./_compose";

export const EnergyTasksPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Tarefas" />}
      gridRef={gridRef}
      actions={
        <BatchLetterOfComplaintButton
          gridRef={gridRef}
          reload={() => dataSource.reload()}
        />
      }
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={energyTasksGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
