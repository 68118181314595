export const contractTypeMap = {
  ContratoAberturaDeConta: {
    label: "Termo para abertura de conta escrow",
    info: "Kit para abertura de conta escrow (Kit cadastral + termo escrow)",
  },
  ContratoAberturaDeContaTransacional: {
    label: "Termo para abertura de conta transacional",
    info: "Kit para abertura de conta transacional (Kit cadastral + termo transacional)",
  },
  KitPMEAberturaDeContaTransacionalPF: {
    label: "Termo para abertura de conta transacional (Pessoa física)",
    info: "Kit para abertura de conta transacional PF (Kit cadastral + termo transacional)",
  },
  ContratoKitPMEContaTransacional: {
    label:
      "Termo para abertura de contas escrow e transacional (Pessoa jurídica)",
    info: "Kit para abertura de contas PJ (Kit cadastral + Termo transacional e escrow)",
  },
  Optin: {
    label: "Termo de uso",
    info: "Termo Optin para cartão",
  },
  OptinContaTransacional: {
    label: "Termo de abertura de conta transacional adicional",
    info: "Somente o termo transacional",
  },
  KitFichaCadastralFacta: {
    label: "Termo de revisão cadastral",
    info: "Kit para revisão cadastral",
  },
  TermoAtualizacaoCadastral: {
    label: "Termo de atualização cadastral",
    info: "Kit para atualização cadastral",
  },
};

export const notificationTypesList = ["StripeBanner", "Bell", "Email", "Push"];
