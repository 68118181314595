import {
  authManager,
  ConfigureAmplifyCommand,
  FederatedSignInCommand,
  RefreshCognitoTokenCommand,
  StartAuthRedirectListenersCommand,
} from "../../auth";
import { GetUserCommand, userManager } from "../../user";
import { Behaviors } from "../types/behavior-ids";
import { BehaviorConfiguration } from "../types/config";
import { BehaviorRoles } from "../types/roles";

export const adminBehavior = {
  id: Behaviors.Admin,
  title: "Administrador",
  homepage: "/inicio",
  domains: ["quickfin.com.br", "corporate.quickfin.com.br", "localhost"],
  roles: [
    BehaviorRoles.HeaderBehavior_Show,
    BehaviorRoles.HeaderCustomerSelector_Show,
    BehaviorRoles.HeaderLogo_Show,
    BehaviorRoles.HeaderMenu_Show,
    BehaviorRoles.HeaderConfiguration_Show,
    BehaviorRoles.HeaderUserMenu_Show,
    BehaviorRoles.HeaderRedirectToOldPortal_Show,
    BehaviorRoles.HeaderWikiLink_Show,
    BehaviorRoles.HeaderCustomerSelectorFetch_Enable,
    BehaviorRoles.ConfigurationModalBehaviorSelector_Show,
    BehaviorRoles.SessionStorage_Enable,
    BehaviorRoles.GridExport_Show,
    BehaviorRoles.ShareLinkButton_Show,
  ],
  init() {
    this.auth();
  },
  async auth() {
    await authManager.execute(new ConfigureAmplifyCommand());
    await authManager.execute(new StartAuthRedirectListenersCommand());

    const refreshOrSignIn = async () => {
      try {
        await authManager.execute(new RefreshCognitoTokenCommand());
      } catch (err) {
        await authManager.execute(new FederatedSignInCommand());
      }
    };

    await refreshOrSignIn();

    setInterval(() => {
      refreshOrSignIn();
    }, 1000 * 60 * 5);

    await userManager.execute(new GetUserCommand());
  },
} satisfies BehaviorConfiguration;
