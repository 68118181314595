export const componentPropertyTypeMap = {
  TEXT: "text",
  NUMBER: "currency",
  INTEGER: "number",
};

export const getComponentPropertyFieldId = (
  componentPropertyName: string,
  componentPropertyId: number | string,
) => `properties.${componentPropertyName}_${componentPropertyId}`;
