import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { Relationships } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";
import { grid } from "./relationships-workflow-card.grid";

interface RelationshipsProps {
  relationships: Relationships[];
}

export const RelationshipsCard = ({ relationships }: RelationshipsProps) => {
  const gridComponent = useMemo(() => {
    return grid();
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <OTypography size="lg" type="default" className="mb-3">
          Relacionamentos
        </OTypography>
      }
    >
      <ODataGridGenerator grid={gridComponent} dataSource={relationships} />
    </GridTemplate>
  );
};
