import { useCallback, useEffect, useState } from "react";
import { service } from "services";

/** @deprecated */
export const useLegacySalesforceUserHook = (autoFetch = true) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userData, setUserData] =
    useState<
      Awaited<
        ReturnType<typeof service.clerk.admin.getSalesforceUser>
      >["data"]
    >();

  const getUserDetails = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.clerk.admin.getSalesforceUser();

      setUserData(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (autoFetch) getUserDetails();
  }, [autoFetch, getUserDetails]);

  return {
    loading,
    hasError,
    userData,
    getUserDetails,
  };
};
