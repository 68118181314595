import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { DataUpdateMonitor } from "../pages/produto/atualizacao-cadastral";
import { ClientDeskCompanies } from "../pages/produto/clientes-mesa/client-desk-companies.page";
import { CurrentAccountPfCompanies } from "../pages/produto/conta-corrente-pf";
import { CurrentAccountPjCompanies } from "../pages/produto/conta-corrente-pj";
import { AccountPjInvite } from "../pages/produto/conta-corrente-pj/convite";
import { AccountPjNewAccount } from "../pages/produto/conta-corrente-pj/nova-conta";
import { EnergyCompanies } from "../pages/produto/energia";
import { EnergyCompaniesInvite } from "../pages/produto/energia/convite";
import { AssignorCompanies } from "../pages/produto/fornecedores";
import { NewAccountAssignor } from "../pages/produto/fornecedores/nova-conta";
import { Groups } from "../pages/produto/grupos";
import { GroupsAdd } from "../pages/produto/grupos/adicionar";
import { GroupsDetails } from "../pages/produto/grupos/detalhes";
import { EditGroup } from "../pages/produto/grupos/editar";
import { OtherCompanies } from "../pages/produto/other";
import { PayerCompanies } from "../pages/produto/payer";
import { AddPayer } from "../pages/produto/payer/adicionar-sacado-simples";
import { BatchImportPayers } from "../pages/produto/payer/importacao-em-lote";
import { ReviewAccountMonitor } from "../pages/produto/revisao-cadastral";
import { empresasRoles } from "../roles/empresas.roles";
import { empresasRouteParams } from "./empresas.route-params";

export const empresasRoutes = {
  product: {
    name: "",
    path: "/empresas/produto",
    element: <RedirectWithRoles />,
    children: {
      currentAccountPj: {
        name: "Conta Corrente PJ",
        path: "/empresas/produto/conta-corrente-pj",
        role: empresasRoles.product.currentAccountPjPage.role,
        element: <CurrentAccountPjCompanies />,
        children: {
          invite: {
            name: "Convite",
            path: "/empresas/produto/conta-corrente-pj/convite",
            role: empresasRoles.product.currentAccountPjInvitePage.role,
            element: <AccountPjInvite />,
          },
          newAccount: {
            name: "Nova Conta",
            path: "/empresas/produto/conta-corrente-pj/nova-conta",
            role: empresasRoles.product.currentAccountPjNewAccountPage.role,
            element: <AccountPjNewAccount />,
          },
        },
      },
      currentAccountPf: {
        name: "Conta Corrente PF",
        path: "/empresas/produto/conta-corrente-pf",
        role: empresasRoles.product.currentAccountPfPage.role,
        element: <CurrentAccountPfCompanies />,
      },
      energy: {
        name: "Energia",
        path: "/empresas/produto/energia",
        role: empresasRoles.product.energyPage.role,
        element: <EnergyCompanies />,
        children: {
          invite: {
            name: "Convite",
            path: "/empresas/produto/energia/convite",
            role: empresasRoles.product.energyInvitePage.role,
            element: <EnergyCompaniesInvite />,
          },
        },
      },
      assignor: {
        name: "Fornecedores",
        path: "/empresas/produto/fornecedores",
        role: empresasRoles.product.assignorPage.role,
        element: <AssignorCompanies />,
        children: {
          newAccount: {
            name: "Pré-cadastro de clientes",
            path: "/empresas/produto/fornecedores/nova-conta",
            role: empresasRoles.product.assignorNewAccountPage.role,
            element: <NewAccountAssignor />,
          },
        },
      },
      payer: {
        name: "Sacado Simples",
        path: "/empresas/produto/sacado-simples",
        role: empresasRoles.product.payerPage.role,
        element: <PayerCompanies />,
        children: {
          addPayer: {
            name: "Importar sacado simples",
            path: "/empresas/produto/sacado-simples/adicionar",
            role: empresasRoles.product.importPayerPage.role,
            element: <AddPayer />,
          },
          batchImport: {
            name: "Importar em lote",
            path: "/empresas/produto/sacado-simples/importar-lote",
            role: empresasRoles.product.batchImportPayerPage.role,
            element: <BatchImportPayers />,
          },
        },
      },
      reviewAccountMonitor: {
        name: "Revisão Cadastral",
        path: "/empresas/produto/revisao-cadastral",
        role: empresasRoles.product.reviewAccountMonitorPage.role,
        element: <ReviewAccountMonitor />,
      },
      dataUpdateMonitor: {
        name: "Atualização Cadastral",
        path: "/empresas/produto/atualizacao-cadastral",
        role: empresasRoles.product.dataUpdateMonitorPage.role,
        element: <DataUpdateMonitor />,
      },
      groups: {
        name: "Grupos",
        path: "/empresas/produto/grupos",
        role: empresasRoles.product.groupsPage.role,
        element: <Groups />,
        children: {
          add: {
            name: "Adicionar",
            path: "/empresas/produto/grupos/adicionar",
            role: empresasRoles.product.groupsAddPage.role,
            element: <GroupsAdd />,
          },
          details: {
            name: "Detalhes",
            path: `/empresas/produto/grupos/detalhes/:${empresasRouteParams.identification}`,
            role: empresasRoles.product.groupsDetailsPage.role,
            element: <GroupsDetails />,
          },
          edit: {
            name: "Editar",
            path: `/empresas/produto/grupos/edit/:${empresasRouteParams.identification}`,
            role: empresasRoles.product.groupsEditPage.role,
            element: <EditGroup />,
          },
        },
      },
      other: {
        name: "Outros",
        path: "/empresas/produto/outros",
        role: empresasRoles.product.otherPage.role,
        element: <OtherCompanies />,
      },
      clientDesk: {
        name: "Clientes Mesa",
        path: "/empresas/produto/clientes-mesa",
        role: empresasRoles.product.clientDeskPage.role,
        element: <ClientDeskCompanies />,
      },
    },
  },
} as const satisfies Routes;
