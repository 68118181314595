import { CellPreparedEvent } from "devextreme/ui/data_grid";
import cloneDeep from "lodash/cloneDeep";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";
import { Sacado } from "../../upload-sacados.types";

export const addErrorsToSacados = (
  sacados: Sacado[] = [],
  errorMessages: PostCompaniesByIdBorderosVerifyErrorResponse = [],
) => {
  const sacadosWithErrors = cloneDeep(sacados).map((sacado) => ({
    ...sacado,
    errorMessages: [],
  })) as Sacado[];

  errorMessages.forEach((error) => {
    const indexStr = error.key.match(/\d+/)?.[0];
    if (!indexStr) return;
    const index = Number(indexStr);
    sacadosWithErrors[index].errorMessages?.push(error);
  });

  return sacadosWithErrors;
};

export const highlightErrors = ({
  data,
  column: { dataField },
  cellElement,
  rowType,
}: CellPreparedEvent<Sacado>) => {
  if (
    rowType === "data" &&
    data?.errorMessages?.some(
      (err) =>
        err.key.split(".").pop()?.toLowerCase() === dataField?.toLowerCase(),
    )
  ) {
    cellElement.style.setProperty(
      "background-color",
      "var(--theme-danger-light)",
    );
    cellElement.style.setProperty("color", "var(--theme-danger-light-inverse)");
  }
};
