import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { AditamentosItem } from "services/quickfin/models";
import { ReceivablesCustomerByReceivableIdAndAditamentoIdRouteParams } from "../../../../../../routes/recebiveis.route-params";
import { AdditionDetailForm } from "./detail.types";

export const useAdditionDetail = () => {
  const { receivableId, aditamentoId } =
    useParams<ReceivablesCustomerByReceivableIdAndAditamentoIdRouteParams>();
  const [loading, setLoading] = useState(false);
  const [additionDetail, setAdditionDetail] = useState<AditamentosItem>();

  const form = useForm<AdditionDetailForm>();
  const { handleSubmit, setValue } = form;

  const {
    callService: getReceivableDetail,
    value: detail,
    loading: detailLoader,
  } = useServiceCall(service.quickfin.getReceivableStateMachineById);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const id = Number(aditamentoId);

      const payload = {
        aditamentoId: id,
        status: values.status,
      };

      await service.quickfin.setAditamento(payload);

      OToastManager.success("Dados atualizados com sucesso");
    } catch (err) {
      OToastManager.danger("Erro para atualizar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!receivableId) return;

    getReceivableDetail(receivableId);
  }, [getReceivableDetail, receivableId]);

  useEffect(() => {
    const findAddition = detail?.receivable.trade?.aditamentos.find(
      (aditamento) => aditamento.aditamentoId === Number(aditamentoId),
    );

    if (!findAddition) return;

    setValue("status", findAddition.aditamentoStatus);
    setAdditionDetail(findAddition);
  }, [aditamentoId, detail, setValue]);

  return {
    additionDetail,
    detailLoader,
    form,
    loading,
    submit,
  };
};
