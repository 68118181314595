import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FileUpload, ImportSettings, ValidationResults } from "./_compose";
import {
  UploadCedentesProvider,
  useUploadCedentes,
} from "./upload-cedentes.context";

const UploadCedentesComponent = () => {
  const { form, validationResults } = useUploadCedentes();

  return (
    <FormTemplate pageTitle={<PageTitle title="Importar cedentes em lote" />}>
      {!validationResults && (
        <CardTemplate>
          <ImportSettings form={form} classname="mb-5" />
          <FileUpload />
        </CardTemplate>
      )}
      {validationResults && <ValidationResults />}
    </FormTemplate>
  );
};

export const UploadCedentes = () => (
  <UploadCedentesProvider>
    <UploadCedentesComponent />
  </UploadCedentesProvider>
);
