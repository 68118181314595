export const userStatusMap = {
  CONFIRMED: "CONFIRMADO",
  FORCE_CHANGE_PASSWORD: "AGUARDANDO NOVA SENHA",
  RESET_REQUIRED: "AGUARDANDO NOVA SENHA",
  UNCONFIRMED: "NAO CONFIRMADO",
  NOT_CREATED: "NAO EXISTE",
};

export const userTooltipMap = {
  CONFIRMED: "Usuário cadastrou a senha e confirmou o login",
  FORCE_CHANGE_PASSWORD: "Usuário realizando o processo esqueci minha senha",
  RESET_REQUIRED:
    "Usuário precisa atualizar a senha para realizar o login por CPF",
  UNCONFIRMED: "Usuário cadastrou a senha, mas nao confirmou o login",
  NOT_CREATED: "Usuário nao cadastrou a senha no portal",
};

export const biometryStatusMap = {
  NOT_CREATED: "Não inicializada",
  CREATED: "Criada",
  STARTED: "Criada",
  NO_ID_UPLOADED: "Falha no documento",
  ERROR_NOT_READABLE_ID: "Documento ilegível",
  DENIED_UNSUPPORTED_ID_TYPE: "Documento nao suportado",
  DENIED_UNSUPPORTED_ID_COUNTRY: "Documento nao suportado",
  RESET: "Cancelado manualmente",
  APPROVED_VERIFIED: "Cadastro concluído com sucesso",
  APPROVED: "Cadastro concluído com sucesso",
  DENIED_FRAUD: "Foto inválida",
  NO_MATCH: "Documento não confere",
  NOT_POSSIBLE: "Foto inválida",
  REJECTED: "Foto inválida",
  PENDING: "Pendente Coleta",
  FAILED: "Foto inválida",
  RESET_FACE: "Pendente Validação Facial",
  WAITING_APPROVAL: "Aguardando Aprovação",
};

export const biometryTooltipMap = {
  NOT_CREATED: "Cliente ainda não iniciou o cadastro de biometria",
  CREATED: "Cliente iniciou processo de cadastro, aguardando processamento.",
  STARTED: "Cliente iniciou processo de cadastro, aguardando processamento.",
  NO_ID_UPLOADED:
    "Cadastro falhou por falta de documento, cliente precisa iniciar um novo cadastro.",
  ERROR_NOT_READABLE_ID:
    "Cadastro falhou por documento ilegível, cliente precisa iniciar um novo cadastro.",
  DENIED_UNSUPPORTED_ID_TYPE:
    "Cadastro falhou pelo documento não ser aceito, cliente precisa iniciar um novo cadastro.",
  DENIED_UNSUPPORTED_ID_COUNTRY:
    "Cadastro falhou pelo documento não ser aceito, cliente precisa iniciar um novo cadastro.",
  RESET:
    "Cadastro cancelado manualmente, cliente precisa iniciar um novo cadastro.",
  APPROVED_VERIFIED:
    "Cadastro concluído com sucesso. Se tiver relacionamento com a tabela 'manual_approvals', indica aprovação manual.",
  APPROVED:
    "Cadastro concluído com sucesso. Se tiver relacionamento com a tabela 'manual_approvals', indica aprovação manual.",
  DENIED_FRAUD: "Não foi possível validar pessoa da foto.",
  NO_MATCH: "Pessoa da foto diferente do documento.",
  NOT_POSSIBLE: "Não foi possível validar pessoa da foto",
  REJECTED: "Não foi possível validar pessoa da foto",
  PENDING: "Cliente não iniciou o processo de cadastro",
  FAILED:
    "Cadastro falhou na leitura do documento, cliente precisa iniciar um novo cadastro.",
  RESET_FACE:
    "Cliente iniciou o processo de cadastro, porem foi solicitado a revalidação facial",
  WAITING_APPROVAL:
    "Cliente iniciou o processo de cadastro e esta aguardando Aprovação",
};
