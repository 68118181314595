import { useEffect, useMemo, useState } from "react";
import { SelectSearchProps } from "./select-search.types";

const searchCharactersToIgnore = /[^0-9A-Z]+/gi;

export const useSelectSearch = <T>(
  value: SelectSearchProps<T>["value"],
  options: SelectSearchProps<T>["options"],
  onClear: SelectSearchProps<T>["onClear"],
  eager: SelectSearchProps<T>["eager"],
) => {
  const [searchValue, setSearchValue] = useState(value ?? "");
  const [selectedValue, setSelectedValue] = useState<T>();

  /** Reset search value when options change */
  // useEffect(() => {
  //   setSearchValue("");
  // }, [options]);

  /** Sync internal state with prop */
  useEffect(() => {
    setSearchValue(value ?? "");
  }, [value]);

  /** Trigger onSelect or onClear when user is typing */
  useEffect(() => {
    if (eager) {
      const selectedOption = options.find(
        (option) => option.label === searchValue,
      );
      if (selectedOption) {
        selectedOption.onSelect(selectedOption.value);
        setSelectedValue(selectedOption.value);
      } else {
        onClear?.();
        setSelectedValue(undefined);
      }
    }
  }, [eager, onClear, options, searchValue]);

  /** Filter shown options based on searchValue */
  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !!option.label
            ?.replace(searchCharactersToIgnore, "")
            ?.match(
              RegExp(searchValue?.replace(searchCharactersToIgnore, ""), "i"),
            ),
      ),
    [options, searchValue],
  );
  return {
    filteredOptions,
    searchValue,
    selectedValue,
    setSearchValue,
    setSelectedValue,
  };
};
