import { ODataGridGenerator } from "components/data-grid";
import * as pageTitle from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  billingReportsDataSource,
  billingReportsGrid,
} from "./billing-reports.grid";

interface BillingReportsComponentProps {
  taxId: string | null;
}

export const BillingReports = ({
  taxId = null,
}: BillingReportsComponentProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(() => billingReportsDataSource(taxId), [taxId]);

  return (
    <GridTemplate
      pageTitle={<pageTitle.PageTitle title="Relatórios de Faturamento" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={billingReportsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
