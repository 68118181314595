import { OToastManager, modalManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { service } from "services";
import { GetContactByContactHolderResponseItem } from "services/admin-bankinghub/models";
import { contactsGrid } from "./contacts.grid";
import { assembleCompanyContact } from "./contacts.utils";

export const useContactsFavored = () => {
  const [canCreateCompanyContact, setCanCreateCompanyContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { customer } = useCustomer();

  const dataSource = useMemo(() => {
    return dataSourceCustomStoreGenerator<GetContactByContactHolderResponseItem>(
      () =>
        service.adminBankinghub
          .getContactByHolder()
          .then(({ data }) => {
            setCanCreateCompanyContact(
              data.every(({ same_ownership }) => !same_ownership),
            );
            return data;
          })
          .catch(() => {
            const errorMessage = "Erro ao buscar os contas";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
    );
  }, []);

  const grid = useMemo(() => contactsGrid(), []);

  const getClerkContacts = useCallback(() => {
    return service.clerk
      .getBankAccount()
      .catch(() => OToastManager.danger("Erro aos buscar contas"));
  }, []);

  useEffect(() => {
    getClerkContacts();
  }, [getClerkContacts]);

  const reloadGrid = useCallback(async () => {
    await Promise.all([getClerkContacts(), dataSource.reload()]);
  }, [dataSource, getClerkContacts]);

  const createCompanyContact = useCallback(async () => {
    try {
      setIsLoading(true);
      await service.adminBankinghub.createContact(
        assembleCompanyContact(customer),
      );
      await reloadGrid();
    } catch {
      OToastManager.danger("Erro aos cadastrar contato da empresa");
    } finally {
      setIsLoading(false);
    }
  }, [customer, reloadGrid]);

  useEffect(() => {
    const cleanUp = modalManager.on(
      "contacts-favored-delete-modal",
      "modalClose",
      reloadGrid,
    );

    return cleanUp;
  }, [reloadGrid]);

  return {
    canCreateCompanyContact,
    createCompanyContact,
    dataSource,
    grid,
    isLoading,
    reloadGrid,
  };
};
