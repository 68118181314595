export const optinViewStatusMap = {
  iniciado: "Iniciado",
  solicitado: "Solicitado",
  aceito: "Aceito",
  rejeitado: "Rejeitado",
  cancelado: "Cancelado",
  opt_out_solicitado: "Opt-out Solicitado",
  opt_out: "Opt-out",
};

export const optinViewTintMap: {
  [key: string]:
    | "warning-light"
    | "success-light"
    | "danger-light"
    | "info-light";
} = {
  iniciado: "warning-light",
  solicitado: "warning-light",
  aceito: "success-light",
  rejeitado: "danger-light",
  cancelado: "danger-light",
  opt_out_solicitado: "warning-light",
  opt_out: "info-light",
};
