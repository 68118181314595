import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { registerOperatorValidationSchema } from "./register-operator.form";
import { RegisterOperatorForm } from "./register-operator.types";

export const useRegisterOperator = () => {
  const { callService: createOperator, loading: createOperatorLoading } =
    useServiceCall(service.customerService.createOperator);

  const {
    callService: getLegalEntityDetails,
    loading: getLegalEntityLoading,
    value: legalEntity,
  } = useServiceCall(service.onboarding.getLegalEntityDetails);

  const form = useForm<RegisterOperatorForm>({
    resolver: yupResolver(registerOperatorValidationSchema),
  });

  const { clearErrors, handleSubmit, setError, setValue, watch } = form;

  const taxIdWatcher = watch("taxIdentification");

  const autofillTaxId = useCallback(
    async (taxId: string) => {
      const { success } = await getLegalEntityDetails({
        taxId,
      });
      if (success) clearErrors("taxIdentification");
      else
        setError("taxIdentification", {
          message: "Não foi possível encontrar o documento",
        });
    },
    [clearErrors, getLegalEntityDetails, setError],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await createOperator({
          user: [values],
        });
        if (success) OToastManager.success("Atendente registrado com sucesso");
        else OToastManager.danger("Erro ao registrar o atendente");
      }),
    [createOperator, handleSubmit],
  );

  useEffect(() => {
    if (taxIdWatcher?.length === 11) {
      if (validators.cpf(taxIdWatcher)) {
        autofillTaxId(taxIdWatcher);
      } else {
        setError("taxIdentification", {
          message: "CPF inválido",
        });
      }
    }
  }, [autofillTaxId, setError, taxIdWatcher]);

  useEffect(() => {
    if (legalEntity) {
      setValue("name", legalEntity.officialName);
    }
  }, [legalEntity, setValue]);

  return {
    createOperatorLoading,
    form,
    getLegalEntityLoading,
    submit,
  };
};
