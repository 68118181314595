import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  ORFieldSelect,
  OOption,
} from "@maestro/react";
import { modalManager } from "@maestro/core";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { WalletsFilterForm } from "./wallets-filter.types";
import { walletsFilterModalId } from "./wallets-filter.utils";
import { OwnersSearch } from "../owners-filter/owners-filter.component";
import { OwnerInStatesFilter } from "../wallet-state/owner-in-states-filter/owner-in-states-filter.component";

interface WalletsFilterProps {
  form: UseFormReturn<WalletsFilterForm>;
  onSubmit: (values: WalletsFilterForm) => void;
  resetFilter: () => void;
}

export const WalletsFilter = ({
  form,
  onSubmit,
  resetFilter,
}: WalletsFilterProps) => {
  const { handleSubmit } = form;
  return (
    <OModal id={walletsFilterModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <OwnersSearch />
          <OwnerInStatesFilter />
          <ORFieldSelect id="isActive" label="Ativo" name="isActive">
            <>
              {[true, false, null].map((val) => (
                <OOption key={"" + val} value={"" + val}>
                  {val === null ? "Mostrar todos" : val ? "Sim" : "Não"}
                </OOption>
              ))}
            </>
          </ORFieldSelect>
          <ORFieldSelect
            id="activeLimit"
            label="Limite ativo"
            name="activeLimit"
          >
            <>
              {[true, false, null].map((val) => (
                <OOption key={"activeLimit" + val} value={"" + val}>
                  {val === null ? "Mostrar todos" : val ? "Sim" : "Não"}
                </OOption>
              ))}
            </>
          </ORFieldSelect>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton onClick={() => resetFilter()} outline>
            Limpar
          </OButton>
          <OButton
            onClick={handleSubmit((values) => {
              onSubmit(values);
              modalManager.hide(walletsFilterModalId);
            })}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
