import * as yup from "yup";

export const companyTraitsDefaultValues = {
  taxIds: [],
  traits: [],
};

export const companyTraitsValidationSchema = yup.object({
  taxIds: yup.array().required("É necessário selecionar ao menos uma empresa"),
  traits: yup
    .array()
    .required("É necessário selecionar ao menos uma propriedade")
    .min(1, "É necessário selecionar ao menos uma propriedade"),
});
