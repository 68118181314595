import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { exchangeOdataPreTicketsGrid } from "pages/cambio/components/odata/exchange-odata-pre-tickets.grid";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";

export const ExchangeContingencyPreTicketsPage = () => {
  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();

  const preTicketGrid = useMemo(
    () => exchangeOdataPreTicketsGrid(mapSymbol, undefined, true),
    [mapSymbol],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pré-Tickets" />}
      actions={
        <>
          <RefreshGridButton
            onClick={() => {
              !preTicketGrid?.datagrid?.dataSource?.isLoading() &&
                preTicketGrid?.datagrid?.dataSource?.reload();
            }}
          />
          <RouterButton
            href={
              corporateRouter.routes.cambio.product.contingency.preTickets
                .create.path
            }
            role={roles.cambio.product.contingencyCurrenciesFormPage.role}
          >
            Criar rascunho
          </RouterButton>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={preTicketGrid} />
    </GridTemplate>
  );
};
