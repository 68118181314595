import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { useAgentImpersonate } from "../agent-impersonate.hook";

export const useStepAnalysis = () => {
  const {
    callService: getNextStepAnalysis,
    error,
    hasError,
    loading,
    value,
  } = useServiceCall(service.hubEnergy.getNextStepAnalysis);

  const { allowAgentImpersonation, impersonatedAgentFromParams } =
    useAgentImpersonate();

  useEffect(() => {
    const params =
      allowAgentImpersonation && impersonatedAgentFromParams
        ? { impersonatedAgentTaxId: impersonatedAgentFromParams }
        : undefined;

    getNextStepAnalysis(params);
  }, [
    allowAgentImpersonation,
    getNextStepAnalysis,
    impersonatedAgentFromParams,
  ]);

  return {
    getNextStepAnalysis,
    error,
    hasError,
    loading,
    value,
    impersonatedAgentFromParams,
  };
};
