import { OCol, ORFieldInput } from "@maestro/react";
import { cardHiringFields } from "../../card-hiring-form.form";

export const PhoneField = () => {
  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldInput {...cardHiringFields.phone} />
    </OCol>
  );
};
