import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  ORFieldUploadInput,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { UploadContainer } from "./upload-container.styles";
import {
  UploadOnboardingFilesFormValues,
  uploadOnboardingFilesFormValidationSchema,
} from "./upload-onboarding-files.form";

export const UploadOnboardingFilesPage = () => {
  const form = useForm<UploadOnboardingFilesFormValues>({
    resolver: yupResolver(uploadOnboardingFilesFormValidationSchema),
  });

  const { handleSubmit, watch } = form;
  const watchFile = watch("file");

  const { callService: uploadFiles, loading } = useServiceCall(
    service.onboarding.uploadFiles,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async ({ file }) => {
        if (!file.length) return;

        const dataForm = new FormData();
        dataForm.append("file", file[0]);

        const { success } = await uploadFiles(dataForm);
        if (!success) return OToastManager.danger("Erro ao enviar arquivo");

        OToastManager.success("Upload feito com sucesso");
      }),
    [handleSubmit, uploadFiles],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Upload de arquivos para o S3" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-2">
        <FormProvider {...form}>
          <OCard>
            <OCardBody>
              <UploadContainer>
                <ORFieldUploadInput
                  id="file"
                  name="file"
                  inputLabel="Clique ou arraste o arquivo aqui"
                  accept="*"
                  multiple={false}
                  handleAddFile={(event, resolve) => resolve(event.detail)}
                  handleRemove={(file, resolve) => resolve(file)}
                  disabled={loading || !!watchFile?.length}
                  key={watchFile?.length}
                  value={[]}
                />
              </UploadContainer>
            </OCardBody>
          </OCard>
        </FormProvider>
      </div>
    </FormTemplate>
  );
};
