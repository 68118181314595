import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FileUpload, ImportSettings, ValidationResults } from "./_compose";
import {
  UploadSacadosProvider,
  useUploadSacados,
} from "./upload-sacados.context";

const UploadSacadosComponent = () => {
  const { form, validationResults } = useUploadSacados();

  return (
    <FormTemplate pageTitle={<PageTitle title="Importar sacados em lote" />}>
      {!validationResults && (
        <CardTemplate>
          <ImportSettings form={form} classname="mb-4" />
          <FileUpload />
        </CardTemplate>
      )}
      {validationResults && <ValidationResults />}
    </FormTemplate>
  );
};

export const UploadSacados = () => (
  <UploadSacadosProvider>
    <UploadSacadosComponent />
  </UploadSacadosProvider>
);
