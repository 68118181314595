import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { buildConsumerUnitsGrid } from "../../../components/consumer-units-grid";
import { useConsumerUnitsMonitor } from "./consumer-units-monitor.hook";
import { AgentSelector } from "./_compose";

const dataGrid = buildConsumerUnitsGrid(false);

export const ConsumerUnitsMonitor = () => {
  const gridRef = useGridRef();

  const { dataSource, form, showOnlyOwnConsumerUnits } =
    useConsumerUnitsMonitor();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Unidades consumidoras" />}
      gridRef={gridRef}
      actions={
        !showOnlyOwnConsumerUnits && (
          <div className="me-auto">
            <AgentSelector form={form} />
          </div>
        )
      }
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dataGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
