import { MaestroFields } from "utils/types";
import { InviteDetailsFormPayload } from "./invite-details.type";

export const updateInviteFields = {
  receiverEmail: {
    tag: "text",
    id: "receiverEmail",
    name: "receiverEmail",
    dataAction: "atualizar_convite:texto:receiverEmail",
    dataLabel: "email",
    label: "E-mail",
  },
  createdBy: {
    tag: "text",
    id: "createdBy",
    name: "createdBy",
    dataAction: "atualizar_convite:texto:responsavel",
    dataLabel: "responsavel",
    label: "Responsável",
    disabled: true,
  },
  officerName: {
    tag: "text",
    id: "officerName",
    name: "officerName",
    dataAction: "atualizar_convite:texto:officerName",
    dataLabel: "officer",
    label: "Officer",
    disabled: true,
  },
  type: {
    tag: "text",
    id: "type",
    name: "type",
    dataAction: "atualizar_convite:texto:tipo_conta",
    dataLabel: "tipo_conta",
    label: "Tipo de Conta",
    disabled: true,
  },
  token: {
    tag: "secret",
    id: "token",
    name: "token",
    dataAction: "atualizar_convite:texto:codigo_validador",
    dataLabel: "codigo_validador",
    label: "Código validador",
    readonly: true,
  },
  segmentName: {
    tag: "text",
    id: "segmentName",
    name: "segmentName",
    dataAction: "atualizar_convite:texto:segmento",
    dataLabel: "segmento",
    label: "Segmento",
    disabled: true,
  },
  deskName: {
    tag: "text",
    id: "deskName",
    name: "deskName",
    dataAction: "atualizar_convite:texto:nome_mesa",
    dataLabel: "nome_mesa",
    label: "Mesa",
    disabled: true,
  },
} as const satisfies MaestroFields<InviteDetailsFormPayload>;

export const updateInviteDefaultValues: InviteDetailsFormPayload = {
  receiverEmail: "",
  createdBy: "",
  officerName: "",
  type: "",
  token: "",
  segmentName: "",
  deskName: "",
};
