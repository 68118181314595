import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { proposalSummaryDetailsGenerator } from "./proposal-summary.details";
import { proposalHistoryGrid } from "./proposal-summary.grid";
import { useProposalSummary } from "./proposal-summary.hook";

export const ProposalSummaryPage = () => {
  const gridRef = useGridRef();

  const {
    handleUpdateStatus,
    hasError,
    loading,
    proposalStatus,
    updateStatusLoading,
    proposal,
    form,
    getProposalDetails,
    proposalId,
    handleUpdateCanEditGuarantee,
    updateCanEditGuaranteeLoading,
  } = useProposalSummary();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Resumo" />}
      actions={
        <>
          {proposal?.data.step === "EM_AUDITORIA" && (
            <LoadingButton
              dataLabel="editar_formulario_garantias"
              dataAction="proposal_details:botao:editar_formulario_garantias"
              loading={updateCanEditGuaranteeLoading}
              type="info"
              onClick={handleUpdateCanEditGuarantee}
            >
              {proposal?.data.canEditGuarantee ? "Fechar" : "Abrir"} formulário
              de garantias
            </LoadingButton>
          )}
          <LoadingButton
            dataLabel="salvar_status"
            dataAction="proposal_details:botao:salvar_status"
            loading={updateStatusLoading}
            type="info"
            disabled={!proposal || proposal.data.status === proposalStatus}
            onClick={handleUpdateStatus}
          >
            Salvar status
          </LoadingButton>
        </>
      }
    >
      <div className="d-flex flex-column gap-3">
        <FormProvider {...form}>
          <DetailsCard
            loading={loading}
            hasError={hasError}
            errorComponent={
              <ErrorComponent
                messageTitle="Não foi possível carregar os dados da proposta."
                messageParagraph="Tente novamente mais tarde."
              >
                <TryAgainButton
                  onClick={() => getProposalDetails(proposalId)}
                />
              </ErrorComponent>
            }
            value={proposal}
            fields={proposalSummaryDetailsGenerator}
          />
        </FormProvider>
        {!!proposal?.data.statusHistory.length && (
          <>
            <PageTitle title="Histórico" />
            <ODataGridGenerator
              gridRef={gridRef}
              grid={proposalHistoryGrid}
              dataSource={proposal?.data.statusHistory ?? []}
            />
          </>
        )}
      </div>
    </DetailsTemplate>
  );
};
