import { OIcon } from "@maestro/react";
import { useState } from "react";
import { FullcreenButtonBtn } from "./fullscreen-container.styles";
import { FullscreenCallback } from "./fullscreen-container.types";

interface FullscreenButtonProps {
  callbackRef: React.MutableRefObject<FullscreenCallback | null>;
}

export const FullscreenButton = ({ callbackRef }: FullscreenButtonProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const callback = () => {
    const newState = callbackRef.current?.();
    if (newState !== undefined) {
      setIsFullscreen(newState);
    }
  };

  return (
    <FullcreenButtonBtn onClick={() => callback()}>
      {isFullscreen ? (
        <OIcon category="orq" icon="orq-minimize-view" type="light" />
      ) : (
        <OIcon category="orq" icon="orq-expand-view" type="light" />
      )}
    </FullcreenButtonBtn>
  );
};
