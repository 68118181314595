import { useEffect, useState } from "react";
import { auth } from "./auth";
import { authEvents } from "./auth.event";

export const useAuthorization = () => {
  const [authorization, setAuthorization] = useState(auth.value);

  useEffect(() => {
    const cleanUp = authEvents.subscribe((newAuth) => {
      setAuthorization(newAuth.value);
    });

    return cleanUp;
  }, []);

  return { authorization };
};
