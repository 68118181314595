import { yupResolver } from "@hookform/resolvers/yup";
import { ONotification, OToastManager, OTypography } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { FerramentasProdutoGerenciamentoPerfisByRequestIdByChangeIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { CreateProfileForm } from "../_compose/create-profile-form.component";
import { CreateRoleForm } from "../_compose/create-role-form.component";
import { ProfileDescription } from "../_compose/profile-description.component";
import { ProfileRolesForm } from "../_compose/profile-roles-forn.component";
import { ProfileSubProfilesForm } from "../_compose/profile-sub-profiles-form.component";
import {
  ProfilesFormValues,
  profileChangeFormValidationSchemaBuilder,
  profileManagementTypeMap,
} from "../_compose/profiles-role-form.schemas";
import { RoleDescription } from "../_compose/role-description.component";

export const UpdateProfileChangePage = () => {
  const { requestId, changeId } =
    useParams<FerramentasProdutoGerenciamentoPerfisByRequestIdByChangeIdRouteParams>();

  if (!requestId || !changeId) throw new Error("No requestId or changeId");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    value: change,
    callService: getChange,
    hasError: hasErrorChange,
    loading: loadingChange,
  } = useServiceCall(
    service.gatekeeper.getProfileManagementRequestChangeDetails,
  );

  const form = useForm<ProfilesFormValues["changes"][number]>({
    resolver: yupResolver(
      profileChangeFormValidationSchemaBuilder(change?.type as string),
    ),
  });

  const { handleSubmit, reset } = form;

  const {
    value: availableProfiles,
    callService: getAvailableProfiles,
    loading: loadingProfiles,
    hasError: hasErrorProfiles,
  } = useServiceCall(service.gatekeeper.getAvailableProfilesForChange);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.gatekeeper.updateProfileManagementRequestChange({
            ...values,
            changeId: +changeId,
            status: "EM_CONSTRUCAO",
          });

          OToastManager.success("Mudança salvas com sucesso");

          navigate(
            corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement.details.path(
              { requestId },
            ),
          );
        } catch {
          OToastManager.danger("Erro ao salvar mudança");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, changeId, navigate, requestId],
  );

  const getProfiles = useCallback(() => {
    getAvailableProfiles({
      requestId: +requestId,
      changeType: change?.type as
        | "ADD_ROLES"
        | "REMOVE_ROLES"
        | "ADD_SUB_PROFILES"
        | "REMOVE_SUB_PROFILES",
    });
  }, [change?.type, getAvailableProfiles, requestId]);

  useEffect(() => {
    getChange({ changeId: +changeId });
  }, [changeId, getChange]);

  useEffect(() => {
    [
      "ADD_ROLES",
      "REMOVE_ROLES",
      "ADD_SUB_PROFILES",
      "REMOVE_SUB_PROFILES",
    ].includes(change?.type ?? "") && getProfiles();
  }, [change?.type, getProfiles]);

  useEffect(() => {
    change &&
      reset({
        description: change.description,
        label: change.label,
        name: change.name,
        profileName: change.profileName,
        roleNames: change.roleNames,
        subProfileNames: change.subProfileNames,
      });
  }, [change, reset]);

  const renderForm = useCallback(
    (type: NonNullable<typeof change>["type"]) => {
      switch (type) {
        case "NEW_ROLE":
          return <CreateRoleForm />;
        case "NEW_PROFILE":
          return <CreateProfileForm />;
        case "ADD_ROLES":
        case "REMOVE_ROLES":
          return (
            <ProfileRolesForm
              changeType={type}
              requestId={+requestId}
              profiles={availableProfiles?.profiles ?? []}
            />
          );
        case "ADD_SUB_PROFILES":
        case "REMOVE_SUB_PROFILES":
          return (
            <ProfileSubProfilesForm
              changeType={type}
              requestId={+requestId}
              profiles={availableProfiles?.profiles ?? []}
            />
          );

        default:
          return null;
      }
    },
    [availableProfiles, requestId],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar mudança" />}
      actions={
        <LoadingButton
          dataAction="atualizar_mudanca_perfil:input:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loadingChange || loadingProfiles}
          hasError={hasErrorChange || hasErrorProfiles}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível recuperar a mudança"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton
                onClick={() => {
                  hasErrorChange && getChange({ changeId: +changeId });
                  hasErrorProfiles && getProfiles();
                }}
              />
            </ErrorComponent>
          }
          value={change}
          render={(_change) => (
            <FormProvider {...form}>
              <OTypography type="dark" className="mb-4">
                {getValueFromMap(profileManagementTypeMap, _change.type)}
              </OTypography>

              {_change.type === "NEW_PROFILE" && <ProfileDescription />}
              {_change.type === "NEW_ROLE" && <RoleDescription />}

              {_change.status === "PENDENTE_ALTERACAO" &&
                _change.errors?.length && (
                  <ONotification className="my-4" type="danger">
                    {_change.errors?.join(", ")}
                  </ONotification>
                )}

              {renderForm(_change.type)}
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
