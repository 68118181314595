import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { cedenteLimitsGrid, dataSource } from "./cedente-limits.grid";

export const CedenteLimits = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Limite de Cedentes"
          description="Defina um limite máximo em valores monetários que um cedente pode operar no modelo de bancos emissores."
        />
      }
      actions={
        <StandardLink
          href={
            corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento
              .cedenteLimits.add.path
          }
          role={
            roles.antecipacaoDeCartoes.product.enquadramentoCedenteLimitsAddPage
              .role
          }
        >
          Criar limite
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={cedenteLimitsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
