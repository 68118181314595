import { IconButton } from "components/icon-button";
import { useState } from "react";
import { useProfileSelector } from "../../profile-selector.context";

export const Actions = () => {
  const [categoriesExpanded, setCategoriesExpanded] = useState(true);

  const { clearProfiles, gridRef } = useProfileSelector();

  return (
    <>
      <IconButton
        dataAction="acoes:botao:limpar_selecao"
        dataLabel="limpar_selecao"
        icon={{ category: "far", icon: "fa-check-square" }}
        onClick={() => clearProfiles()}
      >
        Limpar seleção
      </IconButton>
      {categoriesExpanded ? (
        <IconButton
          dataAction="acoes:botao:fechar_categorias"
          dataLabel="fechar_categorias"
          icon={{ category: "far", icon: "fa-compress-arrows-alt" }}
          onClick={() => {
            setCategoriesExpanded(false);
            gridRef.current?.instance.collapseAll();
          }}
        >
          Fechar categorias
        </IconButton>
      ) : (
        <IconButton
          dataAction="acoes:botao:abrir_categorias"
          dataLabel="abrir_categorias"
          icon={{ category: "far", icon: "fa-expand-arrows-alt" }}
          onClick={() => {
            setCategoriesExpanded(true);
            gridRef.current?.instance.expandAll(0);
          }}
        >
          Abrir categorias
        </IconButton>
      )}
      <IconButton
        dataAction="acoes:botao:fechar_descricoes"
        dataLabel="fechar_descricoes"
        icon={{ category: "far", icon: "fa-compress-alt" }}
        onClick={() => gridRef.current?.instance.collapseAll(-1)}
      >
        Fechar descrições
      </IconButton>
    </>
  );
};
