import { OCol, ORFieldInputDateRange, ORow } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { DashboardFluctuations } from "./_compose/fluctuations/dashboard-fluctuations.component";
import { InfoCardContent } from "./_compose/info-card";
import { InfoCard } from "./_compose/info-card/info-card.component";
import { exchangeProductDashboardMap } from "./exchange-product-dashboard.grid";
import { useExchangeProductDashboard } from "./exchange-product-dashboard.hook";

export const ExchangeProductDashboardPage = () => {
  const { handleGetDashboardByDateRange, form, dashboard } =
    useExchangeProductDashboard();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Dashboard" />}>
      <DashboardFluctuations />

      <ORow gap={2}>
        <OCol xs={12} lg={10} />
        <OCol xs={12} lg={2}>
          <div className="position-relative w-100 mt-3">
            <FormProvider {...form}>
              <ORFieldInputDateRange
                id="period"
                name="period"
                label="Período"
              />
            </FormProvider>
          </div>
        </OCol>

        {exchangeProductDashboardMap.map((column) => (
          <OCol
            key={column.key}
            xs={12}
            sm={column.colLg ?? 6}
            lg={column.colLg ?? 3}
          >
            <InfoCard
              {...dashboard}
              callService={handleGetDashboardByDateRange}
              render={(_value) => {
                const { cellRender } = column;

                const Content = cellRender;

                return (
                  <InfoCardContent
                    header={column.header}
                    footer={column.footer}
                    body={<Content data={_value} />}
                  />
                );
              }}
            />
          </OCol>
        ))}
      </ORow>
    </DetailsTemplate>
  );
};
