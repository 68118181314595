import { yupResolver } from "@hookform/resolvers/yup";
import { validators } from "@maestro/utils";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { branchCompanyFormValidationSchema } from "./branch-company.form";
import { BranchCompanyForm, BranchCompanyProps } from "./branch-company.types";

export const useBranchCompany = (
  setCompanyGroup: BranchCompanyProps["setCompanyGroup"],
  setNewCompanyGroup: BranchCompanyProps["setNewCompanyGroup"],
) => {
  const [loading, setLoading] = useState(false);
  const innerForm = useForm<BranchCompanyForm>({
    defaultValues: {
      branchCnpj: "",
      branchOfficialName: "",
    },
    resolver: yupResolver(branchCompanyFormValidationSchema),
  });
  const { handleSubmit, reset, setValue, watch } = innerForm;
  const cnpjWatch = watch("branchCnpj");

  const getOfficialNameFromCnpj = useCallback(async () => {
    const body = {
      taxId: cnpjWatch,
    };
    setLoading(true);

    try {
      const { data } = await service.onboarding.searchLegalEntity(body);
      setValue("branchOfficialName", data.officialName);
    } catch {
      setValue("branchOfficialName", "");
    } finally {
      setLoading(false);
    }
  }, [cnpjWatch, setValue]);

  useEffect(() => {
    if (cnpjWatch && validators.cnpj(cnpjWatch)) {
      getOfficialNameFromCnpj();
    }
  }, [cnpjWatch, getOfficialNameFromCnpj]);

  const addBranchCompany = handleSubmit(async (values) => {
    const newCompany = {
      taxId: values.branchCnpj,
      name: values.branchOfficialName,
    } as CompanyGroupAssociationsItem;
    setNewCompanyGroup((oldGroup) => [...oldGroup, newCompany]);
    setCompanyGroup((oldGroup) => [...oldGroup, newCompany]);
    reset();
  });

  return {
    addBranchCompany,
    innerForm,
    loading,
  };
};
