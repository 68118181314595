import {
  OCheckbox,
  OCheckboxGroup,
  OFilterItem,
  OLabel,
  OTypography,
} from "@maestro/react";
import { PixKeyType } from "../../../../pix-keys.type";
import { MapperPixKeyType } from "../../../../pix-keys.utils";

export const KeyTypeFilterItem = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Tipo de chave</OTypography>
      <OCheckboxGroup
        dataAction=""
        dataLabel=""
        className="d-flex flex-column align-items-center w-100"
        data-filterparam="keyType"
      >
        {Object.values(PixKeyType).map((keyType) => (
          <div
            className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom"
            key={keyType}
          >
            <OLabel htmlFor={`keyType_${keyType}`}>
              {MapperPixKeyType[keyType]}
            </OLabel>
            <OCheckbox id={`keyType_${keyType}`} value={keyType} size="sm" />
          </div>
        ))}
      </OCheckboxGroup>
    </OFilterItem>
  );
};
