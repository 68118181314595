import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useDistributorSelector } from "./distributor-selector.hook";

export const DistributorSelector = () => {
  const { distributorOptions, getDistributorOptions, hasError, loading } =
    useDistributorSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        options={distributorOptions}
        id="distributorIds"
        name="distributorIds"
        label="Distribuidoras"
        placeholder="Selecionar"
      />
      {loading && <OLoader size="sm" />}
      {hasError && !loading && (
        <RefreshGridButton onClick={() => getDistributorOptions()} />
      )}
    </div>
  );
};
