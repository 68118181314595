import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { Taxa } from "./view-taxas.types";

export const buildDataSource = (
  companyId: string | number,
  estrategiaId: string | number,
  currency: string,
) =>
  dataSourceCustomStoreGenerator<Taxa>(() =>
    service.quickfin
      .getCompanyEstrategiaTaxas(companyId, estrategiaId, currency)
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar as taxas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const buildTaxasGrid = (
  isSpreadsheet: boolean,
  hasRebate: boolean,
  isRebateFixo: boolean,
): ODataGridGeneratorConfig<Taxa> => ({
  datagrid: {
    noDataText: "Nenhuma taxa",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "dataBase",
      dataType: "date",
      format: "shortDate",
      sortIndex: 0,
      sortOrder: "asc",
      caption: "Data base",
    },
    {
      dataField: "dataFutura",
      dataType: "date",
      format: "shortDate",
      sortIndex: 1,
      sortOrder: "asc",
      caption: "Data futura",
    },
    {
      dataField: "diasCorridos",
      caption: "Dias corridos",
    },
    {
      dataField: "prazoAlongado",
      dataType: "date",
      format: "shortDate",
      caption: "Prazo alongado",
      visible: isRebateFixo,
    },
    {
      dataField: "taxaPeriodo",
      dataType: "number",
      format: { type: "percent", precision: 15 },
      caption: "Taxa período",
      visible: isSpreadsheet,
    },
    {
      dataField: "taxaMensal",
      dataType: "number",
      format: { type: "percent", precision: 15 },
      caption: "Taxa mensal",
      visible: !isSpreadsheet,
    },
    {
      dataField: "rebateValue",
      dataType: "number",
      format: { type: "percent", precision: 15 },
      caption: "% de rebate",
      visible: hasRebate,
    },
    {
      dataField: "taxaTotal",
      dataType: "number",
      format: { type: "percent", precision: 15 },
      caption: "Taxa total",
    },
    {
      dataField: "pnlEstrategia",
      dataType: "number",
      caption: "PNL Estrategia"
    }
  ],
});
