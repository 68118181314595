import { ClearCustomerCommand, customerManager } from "contexts/customer";
import React, { useEffect } from "react";

interface ProductRouteProps {
  children: React.ReactNode;
}

export const ProductRoute = ({ children }: ProductRouteProps) => {
  useEffect(() => {
    customerManager.execute(new ClearCustomerCommand());
  }, []);

  return children as JSX.Element;
};
