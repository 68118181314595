import * as yup from "yup";
import { CompanyInteractionFinancialEditForm } from "./interaction-financial-edit.type";

export const interactionFinancialEditValidationSchema: yup.ObjectSchema<CompanyInteractionFinancialEditForm> =
  yup.object({
    cellPhoneNumber: yup.array().required("Este campo é obrigatório"),
    email: yup.array().required("Este campo é obrigatório"),
    id: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
  });
