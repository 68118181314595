import { masks } from "@maestro/utils";
import { DeliveryMedium, FeesType, InterestType } from "../../bankslip.type";

export const assembleDeliveryMediums = (
  deliveryMediums: string[],
  phone: string,
  email: string,
): string | [string, string] => {
  if (!deliveryMediums.length) return "Sem escolha de envio";

  if (deliveryMediums.length === 1)
    return deliveryMediums.includes(DeliveryMedium.Sms)
      ? masks.phone(phone)
      : email;

  return [email, masks.phone(phone)];
};

export const assembleFees = (fee: BankingHub.Fee): string | undefined => {
  if (!fee.value) return;
  if (fee.type === FeesType.FIXED_VALUE) return masks.currency(fee.value, "R$");
  if (fee.type === FeesType.PERCENTAGE) return `${fee.value}%`;
};

export const assembleInterest = (
  interest: BankingHub.CollectionInterest,
): string | undefined => {
  if (!interest.value) return;
  if (interest.type === InterestType.FIXED_VALUE_PER_MONTH)
    return masks.currency(interest.value, "R$");
  if (interest.type === InterestType.PERCENTAGE_PER_MONTH)
    return `${interest.value}%`;
};
