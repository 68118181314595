import {
  ODivider,
  OIcon,
  OModal,
  OModalBody,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useUnsafeBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import React from "react";
import { isNotProd } from "utils/environments";
import { BehaviorSelector, MockRolesSelector } from "./_compose";
import { ThemeSwitch } from "./_compose/theme-switch";

export const configurationModalId = "configuration-modal";

export const ConfigurationModal: React.FC = () => {
  const { hasRoles } = useUnsafeBehavior();

  return (
    <OModal id={configurationModalId} size="xs">
      <OModalHeader closeButton divider>
        <span className="d-flex gap-2">
          <OIcon category="orq" icon="orq-configuration" /> Configurações
        </span>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex justify-content-between py-4">
          <OTypography weight="500">Tema</OTypography>

          <ThemeSwitch />
        </div>

        {hasRoles([BehaviorRoles.ConfigurationModalBehaviorSelector_Show]) && (
          <>
            <ODivider size="xxs" />

            <BehaviorSelector />
          </>
        )}

        {isNotProd() && (
          <>
            <ODivider size="xxs" />

            <MockRolesSelector />
          </>
        )}
      </OModalBody>
    </OModal>
  );
};
