import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

interface GridProps {
  refetch: () => Promise<void>;
  canEdit: boolean;
  uuid: string;
}

const buildEditLink = (uuid: string, representativeUuid: string) =>
  corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.update.path(
    {
      representativeUuid,
      uuid,
    },
  );

export const grid = ({ refetch, canEdit, uuid }: GridProps) => {
  return {
    datagrid: {
      noDataText: "Nenhum Representante",
      pager: {
        infoText: "Página {0} de {1} ({2} representantes)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      {
        dataField: "taxId",
        caption: "Cpf",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "email",
        caption: "Email",
      },
      {
        dataField: "phoneNumber",
        caption: "Telefone",
      },
      {
        dataField: "biometryStatus",
        caption: "Biometria",
      },
      {
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                route: buildEditLink(uuid, data.uuid),
              },
              {
                label: "Remover",
                icon: { category: "orq", icon: "orq-edit-trash" },
                disabled: !canEdit,
                onClick: async () => {
                  try {
                    const { uuid: representativeUuid } = data;
                    await service.onboardingRelationships.removeUser({
                      uuid: representativeUuid,
                    });

                    refetch();
                    OToastManager.success("Representante removido com sucesso");
                  } catch {
                    OToastManager.danger("Erro ao remover Representante");
                  }
                },
              },
            ]}
          />
        ),
        role: roles.cadastro.customer.lettersOfAttorneyVintageEmbeddedPage.role,
      },
    ],
  } as ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.LegalRepresentativesItem>;
};
