import {
  OCardBody,
  OCol,
  OIcon,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useCallback, useState } from "react";
import { service } from "services";
import {
  GetApiAdminConfigurableGridColumnResponseItem,
  GridResponseItem,
} from "services/hubcreditmanager/models/responses";

interface ConfigurableGridColumnCardContentProps {
  option: GetApiAdminConfigurableGridColumnResponseItem;
  workflowGrid?: GridResponseItem | undefined;
  refetch: () => Promise<void>;
}

export const ConfigurableGridColumnCardContent = ({
  option,
  refetch,
  workflowGrid,
}: ConfigurableGridColumnCardContentProps) => {
  const [loading, setLoading] = useState(false);

  const gridColumn = workflowGrid?.gridColumns.find(
    (g) => g.configurableGridColumn?.id === option.id,
  );

  const updateGridColumn = useCallback(async () => {
    try {
      if (!gridColumn?.id) return;

      setLoading(true);

      await service.hubCreditManager.updateGridColumn({
        gridColumnId: gridColumn.id,
        isGridDefaultSorting: !gridColumn.isGridDefaultSorting,
      });

      await refetch();

      OToastManager.success("Coluna atualizada com sucesso.");
    } catch {
      OToastManager.danger("Não foi possível atualizar a coluna.");
    } finally {
      setLoading(false);
    }
  }, [gridColumn, refetch]);

  return (
    <OCardBody
      className="d-flex flex-column gap-2 p-3"
      key={`grid-column-card-content-${option.id}`}
    >
      <ORow>
        <OCol sm={10} className="d-flex align-items-center">
          <OTypography>{option.caption}</OTypography>
          {gridColumn && (
            <ButtonWithConfirmation
              dataAction="workflow-grid:botao:atualizar-coluna"
              dataLabel="atualizar-coluna"
              type="info-light"
              confirmationModal={{
                title: `Tem certeza que deseja ${
                  gridColumn.isGridDefaultSorting ? "desfazer" : "definir"
                } a coluna "${option.caption}" como ordenação padrão do grid?`,
                loading,
              }}
              onConfirm={updateGridColumn}
            >
              <OIcon
                category="orq"
                icon={
                  gridColumn.isGridDefaultSorting
                    ? "orq-rate-on"
                    : "orq-rate-off"
                }
              />
            </ButtonWithConfirmation>
          )}
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={4}>
          <OTypography>Propriedade: {option.dataField}</OTypography>
        </OCol>
        <OCol sm={4}>
          <OTypography>
            Tipo: <em>{option.dataType}</em>
          </OTypography>
        </OCol>
        <OCol sm={4}>
          <OTypography>
            {`Renderização: `}
            <em>{option.cellRenderType}</em>
          </OTypography>
        </OCol>
      </ORow>
    </OCardBody>
  );
};
