import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useUser } from "contexts/user";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { useFidcFunds } from "../../../../hooks";

export const useLoadFileConciliation = () => {
  const [hasParseError, setHasParseError] = useState(false);
  const [selectedFund, setSelectedFund] = useState<Fidc.Fund>();
  const { user } = useUser();

  const form = useForm();
  const { watch } = form;
  const selectedFundWatch: string = watch("selectedFund");
  const { funds } = useFidcFunds();

  const { callService: postConciliationFile, loading: apiLoading } =
    useServiceCall(service.fidc.postConciliationFile);

  const { error: readerError, loading: readerLoading, reset } = useReadFile();

  const handleAddFile = useCallback(
    async (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (!file) {
        return OToastManager.danger(
          "Favor selecionar um arquivo '.csv' para upload. Utilize o modelo disponivel para completar os dados",
        );
      }

      try {
        const fileBlob = new Blob([file], {
          type: "text/csv;charset=utf-8;",
        });
        const dataForm = new FormData();
        dataForm.append("author", user.name ?? "");
        dataForm.append("file", fileBlob, file?.name);
        dataForm.append("fundId", selectedFund?.id ?? "");

        await service.fidc.postConciliationFile(dataForm);
        OToastManager.success("Arquivo enviado com sucesso!");
      } catch (err: any) {
        if (err.response) {
          const statusCode = err.response.status;
          const errorMessage = err.response.data || "Erro ao enviar arquivo.";

          if (Math.floor(statusCode / 100) === 5) {
            OToastManager.danger("Erro no servidor ao enviar o arquivo.");
          } else if (Math.floor(statusCode / 100) === 4) {
            OToastManager.danger(
              "Não foi possível encontrar uma configuração para o fundo selecionado.",
            );
          } else {
            OToastManager.danger(errorMessage);
          }
        } else if (err.request) {
          OToastManager.danger(
            "Nenhuma resposta do servidor. Verifique sua conexão.",
          );
        } else {
          OToastManager.danger("Erro ao configurar o envio do arquivo.");
        }
      } finally {
        reset();
      }
    },
    [reset, selectedFund?.id, user.name],
  );

  useEffect(() => {
    if (selectedFundWatch && funds?.length) {
      const fundConciliation = funds.find(
        (fund) => fund.taxId === selectedFundWatch,
      );
      setSelectedFund(fundConciliation);
    }
  }, [funds, selectedFundWatch]);

  return {
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    reset,
    setHasParseError,
    postConciliationFile,
    selectedFundWatch,
  };
};
