import { OButton, ORFieldInput, OTypography } from "@maestro/react";
import { ErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { useResendBoletoSuccess } from "./reenviar-boleto-sucesso.hook";

export const ResendBoletoSuccess = () => {
  const { form, hasError, idAgreement, loading, onSubmit } =
    useResendBoletoSuccess();

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível encontrar o boleto"
          messageParagraph="Ocorreu um erro ao buscar o boleto para reenviar. Retorne à página anterior e tente novamente."
        />
      }
      value={idAgreement}
      render={() => (
        <DetailsTemplate
          pageTitle={<PageTitle title="Envio de boleto bem sucedido!" />}
        >
          <FormTemplate
            actions={
              <OButton
                dataAction="sucesso_ao_reenviar_email:botao:enviar"
                dataLabel="enviar"
                disabled={loading}
                onClick={onSubmit}
              >
                Enviar
              </OButton>
            }
          >
            <CardTemplate>
              <div className="mb-4">
                <OTypography>
                  Foi enviado um e-mail com as informações do novo contrato
                  juntamente com o boleto para o cliente no e-mail
                </OTypography>
              </div>
              <FormProvider {...form}>
                <ORFieldInput
                  tag="text"
                  id="otherEmail"
                  name="otherEmail"
                  dataAction="sucesso_ao_reenviar_email:texto:email_para_enviar_boleto"
                  dataLabel="email_para_enviar_boleto"
                  label="Deseja enviar o boleto para um e-mail adicional?"
                  placeholder="E-mail que será enviado o boleto"
                  disabled={loading}
                />
              </FormProvider>
            </CardTemplate>
          </FormTemplate>
        </DetailsTemplate>
      )}
    />
  );
};
