import { Roles } from "roles/roles.types";

export const cartoesRoles = {
  customer: {
    tab: {
      role: "admin:CardsTabCustomer.View",
      description: "Visualização da tab 'Cartões' na visão cliente",
    },
    // @TODO: revise location: feature role vs page role
    definitivelyBlockCard: {
      role: "admin:CardsDefinitivelyBlockCard.View",
      description:
        "Visualização do botão 'Bloquear cartão definitivamente' na visão cliente",
    },
    hiringHireCard: {
      role: "admin:CardsHireCard.View",
      description: "Visualização da página 'Contratar Cartão' na visão cliente",
    },
    hiringOfferGuarantors: {
      role: "admin:CardsOfferGuarantors.View",
      description:
        "Visualização da página 'Devedores solidários' na visão cliente",
    },
    hiringOfferGuarantorsRegisterEmbedded: {
      role: "admin:CardsOfferGuarantorsRegisterEmbedded.View",
      description:
        "Visualização da página 'Registro - Embedado' na visão cliente",
    },
    wallets: {
      role: "admin:CardWallets.View",
      description: "Visualização da página 'Wallets' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:CardsTabProduct.View",
      description: "Visualização da tab 'Cartões' na visão produto",
    },
    dashboardPage: {
      role: "admin:CardsDashboardPageProduct.View",
      description:
        "Visualização da página 'Cartões - Dashboard' na visão produto",
    },
    hiredOffersPage: {
      role: "admin:CardsHiredOffersPageProduct.View",
      description:
        "Visualização da página 'Cartões - Contratações' na visão produto",
    },
    hiredOffersOfferDetailsPage: {
      role: "admin:CardsHiredOffersOfferDetailsPageProduct.View",
      description:
        "Visualização da página 'Cartões - Contratações - Detalhes da oferta' na visão produto",
    },
    companiesTransactionsSummary: {
      role: "admin:CardsTransactions.View",
      description:
        "Visualização da página 'Cartões - Transações por Empresas' na visão produto",
    },
    eligibilityTreesCreditPage: {
      role: "admin:CardsEligibilityTreesCreditPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Árvores de elegibilidade - Crédito' na visão produto",
    },
    eligibilityTreesDebitPage: {
      role: "admin:CardsEligibilityTreesDebitPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Árvores de elegibilidade - Débito' na visão produto",
    },
    eligibilityOffersPage: {
      role: "admin:CardsEligibilityOffersPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Propostas de oferta' na visão produto",
    },
    eligibilityOffersDetailsPage: {
      role: "admin:CardsEligibilityOffersDetailsPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Propostas de oferta - Detalhes da oferta' na visão produto",
    },
    eligibilityReprocessEligibilityPage: {
      role: "admin:CardsEligibilityReprocessEligibilityPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Reprocessar elegibilidade' na visão produto",
    },
    eligibilityIntubateDebitCardOffersPage: {
      role: "admin:CardsEligibilityIntubateDebitCardOffersPageProduct.View",
      description:
        "Visualização da página 'Cartões - Motor de ofertas - Entuba - Cartão de débito' na visão produto",
    },
  },
} satisfies Roles;
