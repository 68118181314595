import * as yup from "yup";
import { UserForm } from "./users-blocked.types";

export const usersBlockListFields = {
  users: {
    id: "users",
    name: "users",
    label: "Adicione um usuário à lista de restrição",
    placeholder: "Buscar um usuário",
  },
};

export const usersBlockListDefaultValues = {
  users: [],
};

export const usersBlockListValidationSchema = yup.object<
  UserForm,
  yup.ObjectSchema<UserForm>["fields"]
>({
  users: yup.array().required("Campo obrigatório"),
});
