import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { ActionCell } from "./_compose";
import { TierRelationshipsMasterDetail } from "./tiers.master-detail";
import { Tier } from "./tiers.types";
import { percentageValidator, warnLimit } from "./tiers.utils";

export const buildDataSource = (
  setTotal: (total: number) => void,
  cedenteId?: number,
) =>
  dataSourceCustomStoreGenerator<Tier>(
    () =>
      service.quickfin
        .getTiers(typeof cedenteId === "number" ? { cedenteId } : undefined)
        .then(({ data }) => data)
        .catch(() => {
          const errorMessage = "Erro ao buscar os tiers";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        onLoaded: (results) => {
          setTotal(results.reduce((acc, curr) => acc + curr.totalAmount, 0));
        },
        insert: async (newTier) => {
          try {
            const { data } = await service.quickfin.createTier(newTier);
            OToastManager.success("Tier editado com sucesso");

            return { ...newTier, ...data };
          } catch (err) {
            const errorMessage = "Erro ao adicionar o tier";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (original, updated) => {
          try {
            await service.quickfin.editTier(original.id, {
              ...original,
              ...updated,
            });
            OToastManager.success("Tier editado com sucesso");
          } catch {
            const errorMessage = "Erro ao editar o tier";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async ({ id }) => {
          try {
            await service.quickfin.removeTier(id);
            OToastManager.success("Tier removido com sucesso");
          } catch {
            const errorMessage = "Erro ao remover o tier";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const buildTiersGrid = (
  total: number | undefined,
): ODataGridGeneratorConfig<Tier> => ({
  datagrid: {
    noDataText: "Nenhum tier.",
    editing: {
      mode: "row",
      selectTextOnEditStart: true,
      texts: {
        confirmDeleteMessage: "Tem certeza que deseja remover este tier?",
      },
    },
    onCellPrepared: warnLimit(total),
  },
  columns: [
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "classification",
      dataType: "number",
      caption: "Ordem",
    },
    {
      dataField: "totalAmount",
      dataType: "number",
      caption: "Valor total",
      format: (value) => masks.currency(value, "R$"),
      allowEditing: false,
    },
    {
      dataField: "concentration",
      dataType: "number",
      caption: "Concentração",
      format: (value) => masks.percentage(value),
      allowEditing: false,
    },
    {
      dataField: "limit",
      dataType: "number",
      caption: "Limite",
      format: (value) => masks.percentage(value),
      validationRules: percentageValidator,
    },
    {
      dataField: "overLimit",
      dataType: "number",
      caption: "Gordura operacional",
      format: (value) => masks.percentage(value),
      validationRules: percentageValidator,
    },
    {
      dataField: "updatedAt",
      dataType: "datetime",
      format: "shortDate",
      caption: "Atualizado em",
      allowEditing: false,
    },
    {
      cellRender: ActionCell,
      alignment: "center",
    },
  ],
  masterDetail: {
    enabled: true,
    component: TierRelationshipsMasterDetail,
  },
});
