import { OLoader } from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { CardHiringFeedbackModal } from "./card-hiring-feedback-modal/card-hiring-feedback-modal.component";
import { useCardHiringForm } from "./card-hiring-form.hook";
import { CardHiringForm } from "./card-hiring-form.types";
import { FormFields } from "./form-fields/form-fields.component";
import { ManageDeliveryAddressFormModal } from "./manage-delivery-address-form-modal/manage-delivery-address-form-modal.component";

interface CardHiringFormFieldsProps {
  form: UseFormReturn<CardHiringForm>;
}

export const CardHiringFormFields = ({ form }: CardHiringFormFieldsProps) => {
  const {
    offeredCardOptions,
    ownerAddresses,
    issueDates,
    statementAccounts,
    loadingOwnerAddresses,
    loadOwnerAddresses,
  } = useCardHiringForm(form);

  const { setValue, watch, resetField } = form;

  const ownerIdWatcher = watch("ownerId");

  const addressIdWatcher = watch("addressId");

  const handleUpdateAddressId = (value?: string) => {
    if (value) setValue("addressId", value);
    loadOwnerAddresses(ownerIdWatcher);
  };

  const resetAddressId = () => resetField("addressId");

  if (!offeredCardOptions) return <OLoader absolute />;

  return (
    <>
      <CardHiringFeedbackModal />
      <ManageDeliveryAddressFormModal
        ownerId={ownerIdWatcher}
        address={ownerAddresses?.find(({ id }) => id === addressIdWatcher)}
        handleUpdateAddressId={handleUpdateAddressId}
        resetAddressId={resetAddressId}
      />
      <FormProvider {...form}>
        <FormFields
          offeredCards={offeredCardOptions}
          ownerAddresses={ownerAddresses}
          loadingOwnerAddresses={loadingOwnerAddresses}
          statementAccounts={statementAccounts}
          issueDates={issueDates}
        />
      </FormProvider>
    </>
  );
};
