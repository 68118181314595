import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByTradeIdRouteParams } from "../../../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../../../utils";

interface PreviewMigrationDocumentButtonProps {
  energyDocument: HubEnergy.PossibleDocumentResponse;
}

export const PreviewMigrationDocumentButton = ({
  energyDocument,
}: PreviewMigrationDocumentButtonProps) => {
  const { tradeId } = useParams<MleByTradeIdRouteParams>();
  if (!tradeId) throw new Error("No tradeId");

  const { callService, loading } = useServiceCall(
    service.hubEnergy.previewMigrationDocument,
  );

  const download = useCallback(async () => {
    const { success, response, error } = await callService({
      tradeId: Number(tradeId),
      consumerUnitId: energyDocument.consumerUnit.relatedEntityId,
      documentType: energyDocument.possibleDocument.type.name,
    });

    if (success && response.data.response) {
      helpers.downloadBase64(
        response.data.response,
        energyDocument.possibleDocument.files[0].name,
      );
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Não foi possível baixar o documento",
      );
    }
  }, [callService, energyDocument, tradeId]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-download" }}
      loading={loading}
      type="dark"
      outline
      onClick={download}
    >
      Download
    </IconButton>
  );
};
