import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { MleByAdequatorIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import { RecommendedDistributorOptionsForm } from "./recommended-distributor-options.types";

export const useRecommendedDistributorOptions = () => {
  const { adequatorId } = useParams<MleByAdequatorIdRouteParams>();
  if (!adequatorId) throw new Error("No id");

  const {
    callService: getCurrentDistributors,
    value: currentDistributors,
    loading: loadingCurrentDistributors,
    hasError: hasErrorCurrentDistributors,
    error: errorCurrentDistributors,
  } = useServiceCall(service.hubEnergy.getCurrentDistributors);

  const {
    callService: updateDistributors,
    loading: loadingUpdateDistributors,
  } = useServiceCall(service.hubEnergy.updateDistributors);

  const form = useForm<RecommendedDistributorOptionsForm>();
  const { handleSubmit, reset } = form;

  const fetchDistributors = useCallback(async () => {
    await getCurrentDistributors(adequatorId);
  }, [adequatorId, getCurrentDistributors]);

  const onSubmit = handleSubmit(async (values) => {
    const payload = {
      adequacyOptionId: parseInt(adequatorId),
      distributorIds: values.distributorIds,
    };
    const { error, success } = await updateDistributors(payload);

    if (success) {
      OToastManager.success(
        "Configuração da distribuidora atualizada com sucesso",
      );
    } else
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Não foi possível atualizar a configuração da distribuidora",
      );
  });

  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  useEffect(() => {
    if (currentDistributors?.response) {
      reset({
        distributorIds: currentDistributors?.response.map(
          (distributor) => distributor.id,
        ),
      });
    }
  }, [currentDistributors?.response, reset]);

  return {
    currentDistributors,
    errorCurrentDistributors,
    fetchDistributors,
    form,
    hasErrorCurrentDistributors,
    loadingCurrentDistributors,
    loadingUpdateDistributors,
    onSubmit,
  };
};
