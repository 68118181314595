export enum BehaviorRoles {
  "HeaderBehavior_Show",
  "HeaderCustomerSelector_Show",
  "HeaderCustomerServiceMenu_Show",
  "HeaderCustomerServiceUserMenu_Show",
  "HeaderLogo_Show",
  "HeaderMenu_Show",
  "HeaderConfiguration_Show",
  "HeaderUserMenu_Show",
  "HeaderRedirectToOldPortal_Show",
  "HeaderWikiLink_Show",
  "HeaderCustomerSelectorFetch_Enable",
  "ConfigurationModalBehaviorSelector_Show",
  "SessionStorage_Enable",
  "GridExport_Show",
  "ShareLinkButton_Show",
}
