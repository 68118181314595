import { ODivider } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { CollapseItem } from "./_compose";
import { FilterContentProps } from "./filter-content.types";
import { getFieldsContent } from "./filter-content.utils";

export const FilterContent = ({ ...props }: FilterContentProps) => {
  const { watch } = useFormContext();

  return (
    <div className="d-flex flex-column">
      <ODivider />

      {getFieldsContent({
        watch,
        ...props,
      }).map((field) => {
        if ("content" in field) {
          return (
            <CollapseItem key={field.label} {...field}>
              {field.content && field.content()}
            </CollapseItem>
          );
        }

        return <CollapseItem key={field.label} {...field} />;
      })}
    </div>
  );
};
