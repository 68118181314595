export const statesOptions = [
  "Recebível criado",
  "Recebível em aprovação",
  "Disponível para antecipação",
  "Antecipação solicitada pelo cedente",
  "Antecipada",
  "Liquidada",
  "Liquidação em atraso",
  "Elegível",
  "Inelegível",
];

export const walletInvoicesFilterModalId = "wallet-invoices-filter";
