import { DetailsCard } from "components/details-card";
import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { RenderOnlyIfParams, useValidateAccount } from "../_compose";
import { buildClosingPendenciesDetails } from "./closing-pendencies.details";

interface ClosingPendenciesContentProps {
  ids: number[];
}

const ClosingPendenciesContent = ({ ids }: ClosingPendenciesContentProps) => {
  const { canClose, hasError, loading, validateAccounts, validationResults } =
    useValidateAccount(ids);

  return (
    <DetailsTemplate
      actions={
        <RouterButton
          dataAction="acoes:botao:encerrar_contas"
          dataLabel="encerrar_contas"
          href={
            corporateRouter.routes.cadastro.customer.accountClose.reasons.path +
            window.location.search
          }
          role={roles.cadastro.customer.accountCloseReasonPage.role}
          disabled={!canClose}
        >
          Encerrar conta(s)
        </RouterButton>
      }
    >
      <DetailsCard
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Não foi possível buscar as informações"
            callback={validateAccounts}
          />
        }
        value={validationResults}
        fields={buildClosingPendenciesDetails}
      />
    </DetailsTemplate>
  );
};

export const ClosingPendencies = () => (
  <DetailsTemplate pageTitle={<PageTitle title="Pendências" />}>
    <RenderOnlyIfParams
      render={(ids) => <ClosingPendenciesContent ids={ids} />}
    />
  </DetailsTemplate>
);
