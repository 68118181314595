import { modalManager, OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { OIcon, OTypography } from "@maestro/react";
import { BankingClienteContasByAccountByAgencyRouteParams } from "../../../../routes/banking.route-params";
import { statementGrid } from "./statement.grid";
import { StatementContext } from "./statement.context";
import { ExportModal } from "./_compose/export/export-modal.component";
import { AccountBalance } from "./_compose/saldo";
import { FilterButton } from "./_compose/filter-button/filter-button.component";
import { StatementFilter } from "./_compose/filter/filter.component";

export const AccountStatement = () => {
  const { startDate, endDate, cursor, monthly, filters } =
    useContext(StatementContext);

  const [balanceYieldId, setBalanceYieldId] = useState<string>("");

  const { account, agency } =
    useParams<BankingClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  useEffect(() => {
    service.bankinghub
      .getBalanceYieldStatus(account, Number(agency))
      .then((response) => setBalanceYieldId(response.data.balanceYieldId));
  }, [account, agency]);

  const gridRef = useGridRef();

  const dataSource = dataSourceCustomStoreGenerator<BankingHub.Movement>(
    async () => {
      const category = String(filters.categories);
      const movementType = String(filters.movementTypes);
      const params = {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        pageSize: 100,
        cursor,
        ...(monthly && { monthly: dayjs(monthly).format("YYYY-MM") }),
        ...(category && { category }),
        ...(movementType && { movementType }),
      };

      try {
        const response = await service.bankinghub.getAccountMovement(
          account,
          agency,
          params,
        );

        const data = response.data?.data?.dailyMovements;
        const result = data?.reduce(
          (acc: BankingHub.Movement[], dailyMovement) => [
            ...acc,
            ...dailyMovement.movements,
          ],
          [],
        );
        return result;
      } catch (error) {
        if (!isAxiosError(error)) logger.error(error);
        const errorMessage = "Erro ao buscar o extrato";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }
    },
  );

  async function openFilter() {
    await customElements.whenDefined("o-filter-modal");
    const oFilterModalElement = document.querySelector("o-filter-modal");
    await oFilterModalElement?.openFilter();
  }

  async function openModal() {
    modalManager.show("export-statement-modal");
  }

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Extrato" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <div className="d-flex flex-row justify-content-end gap-3 my-1">
        <OIcon
          icon="orq-download"
          category="orq"
          size="lg"
          style={{ cursor: "pointer" }}
          onClick={() => openModal()}
        />
        <FilterButton onClick={() => openFilter()} />
      </div>
      <OTypography type="dark" size="md">
        <OIcon
          icon="orq-status-warning"
          category="orq"
          style={{ display: "inline" }}
          className="me-2"
        />
        Os filtros desta seção são aplicados somente aos dados
        carregados na tabela.
      </OTypography>
      <div className="my-4">
        <AccountBalance />
      </div>

      <ODataGridGenerator
        gridRef={gridRef}
        grid={statementGrid}
        dataSource={dataSource}
      />
      <StatementFilter />
      <ExportModal
        account={{ agency: Number(agency), number: account }}
        balanceYieldId={balanceYieldId}
      />
    </GridTemplate>
  );
};
