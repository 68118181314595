/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
import { Theme } from "./theme";
import { ThemeCommand } from "./types/command";
import { ThemeMode } from "./types/theme";

export class SetThemeCommand implements ThemeCommand {
  private themeMode: ThemeMode;

  constructor(themeMode: ThemeMode) {
    this.themeMode = themeMode;
  }

  async execute(theme: Theme) {
    theme.value = this.themeMode;
  }
}
