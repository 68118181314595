import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { roles } from "roles/roles";
import { CobrancasDashboardPage } from "../pages/produto/dashboard/cobrancas-dashboard.page";
import { CobrancasEstimulosPage } from "../pages/produto/estimulos/cobrancas-estimulos.page";
import { CobrancasDisparoManualPage } from "../pages/produto/contingencias/disparo-manual/cobrancas-disparo-manual.page";

export const cobrancasRoutes = {
  product: {
    name: "",
    path: "/cobrancas/produto",
    element: <RedirectWithRoles />,
    hasSubItems: true,
    children: {
      dashboard: {
        name: "Dashboard",
        path: "/cobrancas/produto/dashboard",
        element: <CobrancasDashboardPage />,
        role: roles.cobrancas.product.dashboardPage.role,
      },
      estimulos: {
        name: "Estímulos",
        path: "/cobrancas/produto/estimulos",
        element: <CobrancasEstimulosPage />,
        role: roles.cobrancas.product.estimulosPage.role,
      },
      contingencias: {
        name: "Contingências",
        path: "/cobrancas/produto/contingencias",
        hasSubItems: true,
        children: {
          disparoManual: {
            name: "Disparo manual",
            path: "/cobrancas/produto/contingencias/disparo-manual",
            element: <CobrancasDisparoManualPage />,
            role: roles.cobrancas.product.disparoManualPage.role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
