import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { getPropertyLabel } from "../../../../../../../../../../utils/get-property-label";
import { MoveStepPropertyFields } from "./_compose";
import { useMoveStepPropertyModal } from "./move-step-property-modal.hook";
import { moveStepPropertyModalId } from "./move-step-property-modal.utils";

interface MoveStepPropertyModalProps {
  stepId: number;
  reload: () => void;
}

export const MoveStepPropertyModal = ({
  stepId,
  reload,
}: MoveStepPropertyModalProps) => {
  const { form, loading, property, submit } = useMoveStepPropertyModal(
    stepId,
    reload,
  );

  return (
    <OModal
      id={moveStepPropertyModalId}
      position="center"
      backdrop
      className="position-absolute"
    >
      <OModalHeader>
        <OTypography size="lg">Mover propriedade</OTypography>
        {property && (
          <OTypography type="default">{getPropertyLabel(property)}</OTypography>
        )}
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <MoveStepPropertyFields idPrefix="move" />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(moveStepPropertyModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
