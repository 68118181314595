import { OFilter, OModal } from "@maestro/react";
import React, { useState } from "react";
import { modalManager, OToastManager } from "@maestro/core";
import { subDays } from "date-fns";

import { bankinghubService } from "services/bankinghub/bankinghub.service";
import dayjs from "dayjs";
import { ExportModalBody } from "./export-modal-body.component";
import { ExportModalHeader } from "./export-modal-header.component";
import { ExportModalFooter } from "./export-modal-footer.component";
import { validPeriodFilters } from "../filter/filter.component";
import { ExportProps } from "./export-modal.types";

interface GenerateFileInput {
  startDate: Date;
  endDate: Date;
  monthly: string;
  extension: "pdf" | "excel" | "ofx" | "legacy-ofx";
  order: "desc" | "asc";
  type: "simple" | "yield" | "application-withdraw";
  balanceYield: string | null;
}

const convertBase64ToIntArray = (base64: string) => {
  const bytes = atob(base64);
  const bytesArray = new Array(bytes.length);
  for (let i = 0; i < bytes.length; i += 1) {
    bytesArray[i] = bytes.charCodeAt(i);
  }
  return new Uint8Array(bytesArray);
};

export const convertBase64Download = (
  filename: string,
  base64: string,
  contentType: string,
) => {
  const intArray = convertBase64ToIntArray(base64);
  const url = URL.createObjectURL(new Blob([intArray], { type: contentType }));

  const a = document.createElement("a");
  document.body.appendChild(a);
  // @ts-ignore
  a.style = "display: none";
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};

export const ExportModal = ({ account, balanceYieldId }: ExportProps) => {
  const [loading, setLoading] = useState(false);
  const filterModalRef = React.useRef<HTMLOFilterElement>(null);

  const handleClear = () => {
    modalManager.hide("export-statement-modal");
  };

  const generateFile = async ({
    startDate,
    endDate,
    monthly,
    extension,
    order,
    type,
    balanceYield,
  }: GenerateFileInput) => {
    try {
      setLoading(true);
      const params = {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        order,
        type,
        ...(monthly && { monthly: dayjs(monthly).format("YYYY-MM") }),
        ...(balanceYield && monthly && { balanceYieldId: balanceYield }),
      };
      const result = await bankinghubService.getStatementFile(
        account.number,
        String(account.agency),
        extension,
        params,
      );
      const validExtensions = {
        excel: "xlsx",
        "legacy-ofx": "legacy-ofx",
        pdf: "pdf",
        csv: "csv",
        ofx: "ofx",
      };
      const fileExtension = validExtensions?.[extension];

      const file = result?.data?.data;
      if (!file) {
        throw new Error("file not found");
      }
      const filename = `${account.agency}_${account.number}_${dayjs().format(
        "DD-MM-YYYY",
      )}.${fileExtension}`;
      convertBase64Download(filename, file, "application/pdf;base64");
      handleClear();
    } catch (error) {
      OToastManager.danger(
        "Extrato não disponivel. Verifique o período selecionado.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = async () => {
    const params = await filterModalRef.current?.filter();
    const getDates =
      validPeriodFilters[(params?.periodFilter as string) || "last-week"](
        params,
      );
    const startDate = getDates.startDate || subDays(new Date(), 7);
    const endDate = getDates.endDate || new Date();
    const monthly = getDates.monthly || "";
    const order = params?.order || "desc";
    const type = params?.type || "simple";
    const balanceYield = balanceYieldId || null;
    const extension = params?.extension || "excel";

    await generateFile({
      startDate,
      endDate,
      monthly,
      extension,
      order,
      type,
      balanceYield,
    });
  };

  return (
    <OModal id="export-statement-modal" position="center" size="lg">
      <OFilter className="d-flex flex-column" ref={filterModalRef}>
        <ExportModalHeader />

        <ExportModalBody account={account} balanceYieldId={balanceYieldId} />

        <ExportModalFooter {...{ handleClear, handleFilter, loading }} />
      </OFilter>
    </OModal>
  );
};
