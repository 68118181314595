import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import dayjs from "dayjs";
import { optinDefaulValues, optinValidationSchema } from "./optin.form";
import { OptinForm } from "./optin.types";

export const useOptin = () => {
  const {
    callService: getAcquirers,
    hasError: acquirersHasError,
    loading: acquirersLoading,
    value: acquirers,
  } = useServiceCall(service.recebiveisCartao.getAcquirers);

  const {
    callService: getBrands,
    hasError: brandsHasError,
    loading: brandsLoading,
    value: brands,
  } = useServiceCall(service.recebiveisCartao.getBrands);

  const {
    callService: addOptin,
    hasError: optinHasError,
    loading: optinLoading,
    value: optin,
  } = useServiceCall(service.recebiveisCartao.addOptin);

  const form = useForm<OptinForm>({
    defaultValues: optinDefaulValues,
    resolver: yupResolver(optinValidationSchema),
  });

  const { handleSubmit, setValue } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) =>
        addOptin({
          credenciadoras: values.acquirerTaxIds,
          cnpjs: [values.taxId.replace(/[^\d]+/g, "")],
          bandeiras: values.brandNames,
          end_date: dayjs(values.endDate.toString()).format("YYYY-MM-DD"),
          start_date: dayjs(values.startDate.toString()).format("YYYY-MM-DD"),
          root: values.isRootTaxId === "yes",
        }),
      ),
    [addOptin, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    getAcquirers();
  }, [getAcquirers]);

  /** Initial request */
  useEffect(() => {
    getBrands();
  }, [getBrands]);

  /** Set initial value */
  useEffect(() => {
    if (brands) {
      setValue("brandNames", [
        "Mastercard",
        "Visa",
        "AmericanExpress",
        "Elo",
        "Hipercard",
      ]);
    }
    if (acquirers) {
      setValue("acquirerTaxIds", [
        "01027058000191",
        "10440482000154",
        "01425787000104",
        "58160789000128",
      ]);
    }
  }, [acquirers, brands, setValue]);

  /** Error warning */
  useEffect(() => {
    if (acquirersHasError)
      OToastManager.danger("Erro ao buscar as credenciadoras");
  }, [acquirersHasError]);

  /** Error warning */
  useEffect(() => {
    if (brandsHasError) OToastManager.danger("Erro ao buscar as bandeiras");
  }, [brandsHasError]);

  /** Error warning */
  useEffect(() => {
    if (optinHasError) OToastManager.danger("Erro ao inserir o Optin");
  }, [optinHasError]);

  /** Success */
  useEffect(() => {
    if (optin) {
      OToastManager.success("Optin inserido com sucesso");
      form.reset(optinDefaulValues);
    }
  }, [optin, form]);

  return {
    acquirers,
    acquirersLoading,
    brands,
    brandsLoading,
    form,
    optinLoading,
    submit,
  };
};
