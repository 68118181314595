import { getValueFromMap } from "utils/get-value-from-map";
import { parseExcelDate } from "utils/parse-excel-date";
import { cleanTaxId, normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { cardBrandMap } from "../../../../../../utils/card-brand-map";
import { IntubateUpdate } from "../../intubate-generic.types";

export const parseIntubateEditSpreadsheet = (
  buffer: FileReader["result"],
): IntubateUpdate[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  const rows = sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    const maturityDate = parseExcelDate(cleanRow["data de vencimento"]);
    const accountNumberWithDigit = String(cleanRow["numero da conta"]);

    return {
      sacadoTaxId: cleanTaxId(String(cleanRow["cnpj do sacado"])),
      cedenteTaxId: cleanTaxId(String(cleanRow["cnpj do cedente"])),
      bandeira:
        getValueFromMap(cardBrandMap, cleanRow.bandeira) || cleanRow.bandeira,
      maturityDate,
      valorSolicitado: cleanRow["valor solicitado"],
      tradeId: String(cleanRow["trade id (canal)"]),
      identop: String(cleanRow.identop).trim().replace(/\D/g, ""),
      externalId: String(cleanRow["id solicitante"]),
      contaLiquidacao: {
        agencia: String(cleanRow["agência"]),
        cnpjTitular: cleanTaxId(String(cleanRow["cnpj titular conta"])),
        codigoBanco: String(cleanRow.banco),
        numeroConta: accountNumberWithDigit.slice(0, -1),
        digitoConta: accountNumberWithDigit[accountNumberWithDigit.length - 1],
        tipoConta: String(cleanRow["tipo de conta"]),
      },
    };
  });
 
  return rows;
};
