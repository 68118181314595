import { OToastManager, modalManager } from "@maestro/react";
import { dataSourceODataStoreGenerator } from "components/data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { endpoints } from "services/endpoints";
import { GetOdataSettlementStrategiesOdataResponse } from "services/quickfin/models";
import { service } from "services/service";
import { settlementStrategiesGrid } from "./settlement-strategies.grid";

export const useSettlementStrategies = () => {
  const [selectedSettlement, setSelectedSettlement] = useState<string>();
  const [loading, setLoading] = useState(false);
  const dataSource = dataSourceODataStoreGenerator<
    GetOdataSettlementStrategiesOdataResponse["value"][number]
  >(endpoints.quickfin.odata.settlementStrategiesOData);

  const grid = useMemo(() => {
    return settlementStrategiesGrid(setSelectedSettlement);
  }, []);

  useEffect(() => {
    if (!selectedSettlement) {
      return;
    }
    modalManager.show("delete-settlement-modal");
  }, [selectedSettlement]);

  const deleteSettlement = useCallback(async () => {
    setLoading(true);
    try {
      await service.quickfin.deleteSettlementStrategyById(
        selectedSettlement ?? "",
      );
      OToastManager.success("Estratégia de liquidação deletada com sucesso!");
    } catch {
      OToastManager.danger("Falha ao deletar estratégia de liquidação");
    } finally {
      setSelectedSettlement(undefined);
      dataSource.reload();
      modalManager.hide("delete-settlement-modal");
      setLoading(false);
    }
  }, [dataSource, selectedSettlement]);

  return {
    dataSource,
    deleteSettlement,
    grid,
    loading,
  };
};
