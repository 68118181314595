import { OButton, OCol, OIcon, OLoader, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { ORFieldSelectSearch } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { ProfilesFormValues } from "./profiles-role-form.schemas";

interface ProfileRolesFormProps {
  index?: number;
  profiles: string[];
  requestId: number;
  changeType: "ADD_ROLES" | "REMOVE_ROLES";
}

export const ProfileRolesForm = ({
  index,
  profiles,
  requestId,
  changeType,
}: ProfileRolesFormProps) => {
  const {
    value: availableRoles,
    callService: getAvailableRoles,
    loading: loadingRoles,
    hasError: hasErrorRoles,
  } = useServiceCall(service.gatekeeper.getAvailableRolesForChange);

  const { setValue, watch, getValues } = useFormContext<
    ProfilesFormValues | ProfilesFormValues["changes"][number]
  >();

  const rolesResetValue = useRef(
    getValues(
      typeof index === "number" ? `changes.${index}.roleNames` : "roleNames",
    ),
  );

  const watchProfileName = watch(
    typeof index === "number" ? `changes.${index}.profileName` : "profileName",
  );

  const getRoles = useCallback(() => {
    if (!watchProfileName) return;
    return getAvailableRoles({
      changeType,
      profileName: watchProfileName,
      requestId,
    });
  }, [changeType, getAvailableRoles, requestId, watchProfileName]);

  const profileOptions = useMemo(
    () =>
      profiles?.map((_profile) => ({
        value: _profile,
        label: _profile,
        customKey: _profile,
        onSelect: (value: string) => {
          setValue(
            typeof index === "number"
              ? `changes.${index}.profileName`
              : "profileName",
            value,
          );

          setValue(
            typeof index === "number"
              ? `changes.${index}.roleNames`
              : "roleNames",
            rolesResetValue.current,
          );

          rolesResetValue.current = [];
        },
      })) ?? [],
    [profiles, index, setValue],
  );

  const rolesOptions = useMemo(
    () =>
      availableRoles?.roles?.map((_role) => ({
        value: _role,
        label: _role,
      })) ?? [],
    [availableRoles],
  );

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <ORow className="flex-fill">
      <OCol xs={12} sm={4}>
        <ORFieldSelectSearch
          id={`changes.${index}.profileName`}
          name={
            typeof index === "number"
              ? `changes.${index}.profileName`
              : "profileName"
          }
          placeholder="Selecionar"
          options={profileOptions}
          className="w-100"
          maxOptions={50}
          label="Perfil"
          value={watchProfileName}
        />
      </OCol>
      <OCol xs={12} sm={8} className="flex-column">
        <SelectSearchMultiple
          id={`changes.${index}.roleNames`}
          name={
            typeof index === "number"
              ? `changes.${index}.roleNames`
              : "roleNames"
          }
          placeholder="Selecionar"
          options={rolesOptions}
          maxOptions={50}
          label="Roles"
          disabled={loadingRoles}
        />
        {loadingRoles && <OLoader />}
        {hasErrorRoles && (
          <OButton
            type="default"
            outline
            dataLabel="adicionar_roles_form"
            dataAction="adicionar_roles_form:botao:roles"
            onClick={() => getRoles()}
          >
            <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
          </OButton>
        )}
      </OCol>
    </ORow>
  );
};
