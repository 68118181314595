import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import dayjs from "dayjs";
import { AccountAdminStatementProps, FormValuesType } from "./statement.types";
import { generateFileByFormat } from "./statement.utils";

export const useAccountAdminStatement = ({
  account,
  agency,
}: AccountAdminStatementProps) => {
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>();
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (!account || !agency || !values.date || !values.formatType) return;

      const [startDate, endDate] = values.date;
      const isBalanceYield = values.formatType === "csvWithBalanceYield";
      const format = isBalanceYield ? "csv" : values.formatType;

      const params = {
        startDate: dayjs(startDate?.toISOString()).format("YYYY-MM-DD"),
        endDate: dayjs(endDate?.toISOString()).format("YYYY-MM-DD"),
        isBalanceYield,
      };

      const { data } = await service.bankinghub.getStatementByAccount(
        account,
        agency,
        format,
        params,
      );

      if (data) {
        generateFileByFormat(customer.identification, account, agency, format, {
          [format]: data.data,
        });
      }
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    submit,
    loading,
    form,
  };
};
