import { OToastManager } from "@maestro/core";
import {
  OIcon,
  OUpload,
  OUploadInput,
  OUploadItem,
  OUploadLabel,
  OUploadTip,
} from "@maestro/react";
import { ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { useState } from "react";
import { service } from "services";
import { getFormData } from "utils/file/file.utils";
import { ContractGeneratorFormValues } from "../contract-generator.types";

export const ContractTemplatesUpload = ({
  component,
  rowIndex,
  data,
}: ColumnEditCellTemplateData<
  ContractGeneratorFormValues["contractTemplates"][number]
>) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = async (file: File) => {
    try {
      setLoading(true);
      OToastManager.info("Enviando arquivo...");
      const formData = await getFormData(file, "TemplateDeContrato");

      const { data: fileKey } = await service.hubLoan.upload(formData);

      component.cellValue(rowIndex, "template", fileKey);
      component.cellValue(rowIndex, "type", "Submissão manual");
      component.cellValue(rowIndex, "status", "AGUARDANDO_INICIO");
      OToastManager.destroy();
    } catch {
      OToastManager.danger(
        "Não foi possível fazer upload do arquivo, tente novamente.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <OUpload
      onAddFile={(event) => {
        if (data?.template || loading) {
          OToastManager.warning("Já tem um arquivo");
          return;
        }
        uploadFile(event.detail);
      }}
    >
      <OUploadInput
        id="file"
        name="file"
        dataAction="emprestimos_templates:file:arquivo_template"
        dataLabel="arquivo_template"
        accept=".docx"
      />
      <OUploadLabel htmlFor="file" size="md">
        Selecionar documento
        <OIcon category="orq" icon="orq-upload" size="lg" />
      </OUploadLabel>
      <OUploadTip>
        Serão aceitos documentos com no máximo 10mb, no formato .docx
      </OUploadTip>
      {data?.template && (
        <div className="d-flex flex-column">
          <OUploadItem
            handleRemove={() => {
              component.cellValue(rowIndex, "template", null);
            }}
          >
            <div style={{ overflow: "hidden" }}>{data.template}</div>
          </OUploadItem>
        </div>
      )}
    </OUpload>
  );
};
