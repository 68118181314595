import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCompanyGroupGetCompaniesFromGroupResponse } from "services/onboarding/models";

export const grid: ODataGridGeneratorConfig<
  PostAdminCompanyGroupGetCompaniesFromGroupResponse["companyGroupAssociations"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma solicitação encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "status", caption: "Status" },
    { allowFiltering: false, dataField: "rejectMessage", caption: "Mensagem" },
    {
      allowFiltering: false,
      dataField: "roles",
      caption: "Relacionamentos"
    },
  ],
};
