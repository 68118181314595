import { masks } from "@maestro/utils";
import { CardReceivable } from "../../../../intubate-operations.types";
import { useQuoteList } from "../../quote-list.context";

interface DisbursementAmountCellProps {
  data: CardReceivable;
}

export const DisbursementAmountCell = ({
  data,
}: DisbursementAmountCellProps) => {
  const { disbursementAmounts } = useQuoteList();

  const value = disbursementAmounts[data.ReceivableId] ?? data.ValorDesembolso;

  return <>{masks.currency(value, "R$")}</>;
};
