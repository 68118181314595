import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  SharkAmortizationType,
  SharkSimulateResponse,
} from "services/shark/models/responses";
import {
  amortizationFrequencyDescriptionMap,
  customizedJoin,
} from "../../operations-installments.utils";
import { TotalAmountCardComponent } from "../_compose/total-amount-card.component";

export const AnticipationDetailsGenerator = (
  simulation?: SharkSimulateResponse,
) => {
  const { contractNumber } = useParams<{ contractNumber: string }>();
  const [searchParams] = useSearchParams();

  const selectedInstallmentsText: JSX.Element = useMemo(() => {
    const values: Record<SharkAmortizationType, number[]> = JSON.parse(
      searchParams.get("installments") || "{}",
    );
    return (
      <>
        {!values ? (
          <OTypography type="dark">-</OTypography>
        ) : (
          <>
            {Object.entries(values).map(
              ([amortizationFrequency, installments]) => {
                const installmentsText = customizedJoin(installments);

                const formattedFrequency =
                  amortizationFrequencyDescriptionMap[amortizationFrequency];

                return (
                  <OTypography type="dark" key={amortizationFrequency}>{`${
                    formattedFrequency ? `${formattedFrequency}:` : ""
                  } ${installmentsText}`}</OTypography>
                );
              },
            )}
          </>
        )}
      </>
    );
  }, [searchParams]);

  return {
    details: {
      items: [
        {
          label: "Contrato",
          value: contractNumber,
          grid: { sm: 3 },
        },
        {
          label: "Parcelas a antecipar",
          value: selectedInstallmentsText,
          grid: { sm: 3 },
        },
        {
          label: "Valor total das parcelas",
          value: simulation
            ? masks.currency(simulation.amount_at_maturity, "R$", ".")
            : "-",
          grid: { sm: 3 },
        },
        {
          label: "Desconto",
          value: simulation
            ? masks.currency(
                simulation.amount_at_maturity - simulation.curve_value,
                "R$",
                ".",
              )
            : "-",
          grid: { sm: 3 },
        },
      ],
    },
    totalAmount: {
      items: [
        {
          value: <TotalAmountCardComponent simulation={simulation} />,
        },
      ],
    },
  } satisfies DetailsFields;
};
