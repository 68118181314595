import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminPreticketBody } from "services/acc/models/requests/post-admin-pretickets.request";
import { AccOrder } from "services/acc/models/responses/get-admin-orders.response";
import { AdminProposalsResponse } from "services/acc/models/responses/get-admin-proposals.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  defaultValues,
  preticketsFormValidationSchema,
} from "./admin-contingency-settlements-create.schemas";
import { mapFlowType } from "pages/acc/utils/map-flow-type";

export const AdminContingencyACCPreticketCreatePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [proposals, setProposals] = useState<AdminProposalsResponse[]>([]);
  const [proposal, setProposal] = useState<AdminProposalsResponse>();

  const [orders, setOrders] = useState<AccOrder[]>([]);
  const [order, setOrder] = useState<AccOrder>();

  const [isCounterpartyCorrect, setIsCounterpartyCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { currencies, mapSymbol } = useCurrencies();

  const amountAvailable =
    (proposal?.amount ?? 0) - (proposal?.settlementAllocatedAmount ?? 0);

  const form = useForm<PostAdminPreticketBody>({
    resolver: yupResolver(
      preticketsFormValidationSchema(
        amountAvailable,
        order?.notAllocatedAmount,
        isCounterpartyCorrect,
      ),
    ),
    defaultValues,
  });

  const { handleSubmit, watch, clearErrors } = form;
  const watchCurrency = watch("currency");
  const watchTargetId = watch("counterpartyIdentification");
  const watchCorrelationId = watch("correlationId");
  const watchOrder = watch("orderConciliationId");
  const watchFlowType = watch("flowType");

  const onAdd = useCallback(
    async (values: PostAdminPreticketBody) => {
      try {
        setLoading(true);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { counterpartyIdentification, flowType, ...formValues } = values;

        if (!counterpartyIdentification) return;

        const payload = {
          ...formValues,
          counterpartyIdentification: counterpartyIdentification.replace(
            /\D/g,
            "",
          ),
        };

        await service.acc.postSettlement(payload);

        OToastManager.success("Preticket adicionado com sucesso.");

        navigate(corporateRouter.routes.acc.product.settlements.path);
      } catch {
        OToastManager.danger("Erro ao adicionar preticket.");
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const getProposals = useCallback(
    async (targetId: string) => {
      try {
        setIsLoading(true);
        const { data } = await service.acc.postProposals(targetId);

        if (!data.success || !data.response.length) {
          setIsCounterpartyCorrect(false);
          return;
        }

        setIsCounterpartyCorrect(true);
        clearErrors("counterpartyIdentification");
        setProposals(data?.response.filter((p) => p.status === "DESEMBOLSADO"));
      } catch {
        setIsCounterpartyCorrect(false);
      } finally {
        setIsLoading(false);
      }
    },
    [clearErrors],
  );

  const getOrders = useCallback(async (targetId: string, currency: string) => {
    const { data: ordersData } = await service.acc.postOrders(
      targetId,
      currency,
    );

    setOrders(ordersData.response);
  }, []);

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        onAdd(formValues);
      }),
    [handleSubmit, onAdd],
  );

  useEffect(() => {
    const targetId = watchTargetId?.replace(/\D/g, "");
    if (targetId?.split("").length === 14) {
      !!watchCurrency && getOrders(targetId, watchCurrency);
      getProposals(targetId);
      return;
    }
    setIsCounterpartyCorrect(false);
  }, [watchTargetId, watchCurrency, getProposals, getOrders]);

  useEffect(() => {
    watchOrder && setOrder(orders.find((o) => o.conciliationId === watchOrder));
    watchCorrelationId &&
      setProposal(
        proposals.find((o) => o.correlationId === watchCorrelationId),
      );
  }, [watchCorrelationId, watchOrder, orders, proposals]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar rascunho de Pré-Ticket" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {isLoading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <OCol>
            <ORFieldSelect
              label="Channel"
              name="channelInput"
              id="channelInput"
            >
              {["B2C"]?.map((channel) => (
                <OOption key={channel} value={channel}>
                  {channel}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol>
            <ORFieldSelect label="FlowType" name="flowType" id="flowType">
              {mapFlowType?.map((flow) => (
                <OOption key={flow.value} value={flow.value}>
                  {flow.label}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          {currencies && (
            <OCol>
              <ORFieldSelect label="Moeda" name="currency" id="currency">
                {currencies
                  .filter((curr) => curr.isApproved && curr.code === "USD")
                  ?.map((curr) => (
                    <OOption key={curr.code} value={curr.code}>
                      {curr.description}
                    </OOption>
                  ))}
              </ORFieldSelect>
            </OCol>
          )}
          <OCol>
            <ORFieldInput
              label="CPF / CNPJ"
              name="counterpartyIdentification"
              id="counterpartyIdentification"
              tag="mask"
              maskOption={{
                mask: "00.000.000/0000-00",
              }}
            />
          </OCol>
          <OCol>
            <ORFieldSelect
              label="Proposta"
              name="correlationId"
              id="correlationId"
              disabled={!proposals?.length || !isCounterpartyCorrect}
              value={watchCorrelationId}
            >
              {proposals?.map((p) => (
                <OOption key={`${p.id}-option`} value={p.correlationId}>
                  <div key={`${p.id}-div`} className="d-flex flex-column">
                    <div className="d-flex flex-row gap-2">
                      <OTypography type="dark-60">
                        {"Id da proposta: "}
                      </OTypography>
                      <OTypography type="dark-80">{p.id}</OTypography>
                    </div>
                    <div key="amount" className="d-flex flex-row gap-2">
                      <OTypography type="dark-60">
                        {" Valor solicitado: "}
                      </OTypography>
                      <OTypography type="dark-80">
                        {masks.currency(p.amount, mapSymbol(p.currency))}
                      </OTypography>
                    </div>
                    <div className="d-flex flex-row gap-2">
                      <OTypography type="dark-60">
                        {" correlationId: "}
                      </OTypography>
                      <OTypography type="dark-80">
                        {p.correlationId}
                      </OTypography>
                    </div>
                  </div>
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          {watchFlowType === "INTERNACAO" && (
            <OCol>
              <ORFieldSelect
                label="Ordem"
                name="orderConciliationId"
                id="orderConciliationId"
                disabled={!orders?.length || !isCounterpartyCorrect}
              >
                {orders?.map((o) => (
                  <OOption key={`${o.id}-option`} value={o.conciliationId}>
                    <div key={`${o.id}-div`} className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {"Id da ordem: "}
                        </OTypography>
                        <OTypography type="dark-80">{o.id}</OTypography>
                      </div>
                      <div key="amount" className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {" Valor disponível: "}
                        </OTypography>
                        <OTypography type="dark-80">
                          {masks.currency(
                            o.notAllocatedAmount,
                            mapSymbol(o.currency),
                          )}
                        </OTypography>
                      </div>
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {" conciliationId: "}
                        </OTypography>
                        <OTypography type="dark-80">
                          {o.conciliationId}
                        </OTypography>
                      </div>
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">{" Pagador: "}</OTypography>
                        <OTypography type="dark-80">{o.payer}</OTypography>
                      </div>
                    </div>
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          <OCol>
            <ORFieldInput
              tag="currency"
              symbol={mapSymbol(watchCurrency)}
              label="Valor total"
              name="amount"
              id="amount"
              disabled={!watchCorrelationId || !watchOrder}
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
