import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadsRouteQueryParams } from "../../../routes/workflow.route-params";
import { workflowLeadsGrid } from "./workflow-leads.grid";

export const WorkflowLeadsPage = () => {
  const { value } =
    useParseFromSearchParams<WorkflowProdutoLeadsRouteQueryParams>("data");

  const gridRef = useGridRef();

  const grid = useMemo(
    () => workflowLeadsGrid(value?.selectedWorkflowConfig),
    [value?.selectedWorkflowConfig],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de leads do workflow" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
