import { OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GroupForm, UploadGroupFile } from "../_compose";
import { useGroupsAdd } from "./add.hook";

export const GroupsAdd = () => {
  const { form, loading, setCompanyGroup, submit } = useGroupsAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Grupos de empresas" description="Criar grupo" />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="criar_grupo:botao:criar_grupo"
          dataLabel="criar_grupo"
          onClick={submit}
        >
          Criar grupo
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          {loading && <OLoader absolute />}

          <GroupForm />

          <UploadGroupFile setCompanyGroup={setCompanyGroup} />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
