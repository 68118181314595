import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  GetGatekeeperGetActsResponse,
  PostGatekeeperProfilesGetActsResponseItem,
} from "services/gatekeeper/models";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { buildGrid } from "./acts-mapping.grid";

export const GatekeeperProfilesActsMapping = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const [profileName, setProfileName] = useState<string>();

  const loadProfileName = useCallback(async () => {
    const { data } = await service.gatekeeper.getProfileDetails({
      profiles: [{ uuid }],
    });

    setProfileName(data[0].name);
  }, []);

  const gridRef = useRef<DataGrid>(null);
  const [acts, setActs] = useState<GetGatekeeperGetActsResponse>();

  const dataSource =
    dataSourceCustomStoreGenerator<PostGatekeeperProfilesGetActsResponseItem>(
      () =>
        service.gatekeeper
          .getProfileActs({ profile_uuid: uuid ?? "" })
          .then(({ data }) => data)
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar profiles";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      {
        customStoreOptions: {
          insert: async (data) => {
            try {
              const findAct = acts?.find((act) => act.name === data.name);
              const act = findAct?.id ? [findAct?.id] : [];

              const payload = {
                acts: act,
                profile: profileName!,
              };
              await service.gatekeeper.addProfileActs(payload);
              OToastManager.success("Detalhe adicionado com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível adicionar o detalhe");
            }
            return data;
          },
          remove: async (data) => {
            try {
              const findAct = acts?.find((act) => act.name === data.name);
              const act = findAct?.id ? [findAct?.id] : [];

              const payload = {
                acts: act,
                profile: profileName!,
              };

              await service.gatekeeper.removeProfileActs(payload);
              OToastManager.success("Detalhe removido com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível remover o detalhe");
            }
          },
        },
      },
    );

  const grid = useMemo(() => buildGrid(acts), [acts]);

  const load = useCallback(async () => {
    try {
      const { data } = await service.gatekeeper.getActs();

      setActs(data);
    } catch (err) {
      OToastManager.danger("Erro para buscar os atos");
    }
  }, []);

  useEffect(() => {
    loadProfileName();
    load();
  }, [loadProfileName, load]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Mapeamento de atos"
          description={`Visualize os atos do profile ${profileName}`}
        />
      }
      actions={
        <IconButton
          dataAction="profiles_atos:botao:adicionar"
          dataLabel="adicionar"
          icon={{ category: "fal", icon: "fa-plus" }}
          onClick={() => gridRef.current?.instance.addRow()}
        >
          Adicionar
        </IconButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
