import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services";
import { logger } from "utils/logger";
import { getValidationMessages } from "../../../utils";

export type Guarantor = HubEnergy.GuarantorResponse & {
  errorMessages?: string[];
};

interface ErrorResponse {
  isInternal: boolean;
  errorType: string;
  failures: Failure[];
}

interface Failure {
  fieldName: string;
  errorCode: string;
  errorMessage: string;
}

/** @deprecated */
export const maritalStatusTypeMap = {
  CASADO: "Casado",
  DIVORCIADO: "Divorciado",
  SEPARADO_JUDICIALMENTE: "Separado judicialmente",
  SOLTEIRO: "Solteiro",
  UNIAO_ESTAVEL: "União estável",
  VIUVO: "Viúvo",
} satisfies Record<HubEnergy.EMaritalStatusType, string>;

/** @deprecated */
export const weddingRegimeTypeMap = {
  COMUNHAO_PARCIAL: "Comunhão parcial",
  COMUNHAO_TOTAL: "Comunhão total",
  SEPARACAO_TOTAL: "Separação total",
} satisfies Record<HubEnergy.EWeddingRegimeType, string>;

const getGuarantorsErrors = (error: unknown, guarantors: Guarantor[]) => {
  if (!isAxiosError<ErrorResponse>(error)) return guarantors;

  error.response?.data.failures.forEach(({ fieldName, errorMessage }) => {
    const indexStr = fieldName.match(/\d+/)?.[0];
    const index = Number(indexStr);
    if (guarantors[index]) {
      // eslint-disable-next-line no-param-reassign
      guarantors[index].errorMessages = guarantors[index].errorMessages ?? [];
      guarantors[index].errorMessages?.push(errorMessage);
    }
  });

  return guarantors;
};

/** @deprecated */
export const generateEnergyGuarantorsDataSource = (tradeId: string | number) =>
  dataSourceCustomStoreGenerator(
    () => {
      return service.hubEnergy
        .getTradeGuarantors(tradeId)
        .then(({ data: { response } }) => {
          if (!response?.lead.customerEntityTaxId)
            throw new Error("Cliente não encontrado");

          if (!response.leadGuarantors?.length) return Promise.resolve([]);

          return service.onboardingRelationships.b2c
            .loadGuarantors(
              {
                requiredGuarantors:
                  response.leadGuarantors.map(
                    (guarantor) => guarantor.entity.taxId,
                  ) ?? [],
                type: "SIMPLES",
              },
              response.lead.customerEntityTaxId,
            )
            .then(() => {
              return response.leadGuarantors ?? [];
            })
            .catch((err) => {
              return getGuarantorsErrors(err, response.leadGuarantors);
            });
        })
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          throw new Error("Erro ao buscar os avalistas");
        });
    },
    {
      customStoreOptions: {
        update: async (original, updated) => {
          try {
            const payload = {
              guarantorId: original.id,
              maritalStatusType: original.maritalStatusType,
              weddingRegimeType: original.weddingRegimeType,
            };

            Object.assign(payload, updated);

            const {
              data: { response },
            } = await service.hubEnergy.updateGuarantorMaritalStatus(payload);

            if (response) {
              OToastManager.success("Estado civil atualizado com sucesso");

              return response;
            }
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.errorMessage ??
              "Erro ao atualizar o estado civil";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );
