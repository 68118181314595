import { AssetsUrl } from "assets/s3-assets/index";

export const payers = {
  "10440482000154": { image: AssetsUrl.card.getnet, name: "Getnet" },
  "10573521000191": {
    image: AssetsUrl.card.mercado_pago,
    name: "Mercado Pago",
  },
  "14796606000190": { image: AssetsUrl.card.adyen, name: "Adyen" },
  "01027058000191": { image: AssetsUrl.card.cielo, name: "Cielo" },
  "58160789000128": { image: AssetsUrl.card.safrapay, name: "Safrapay" },
  "16501555000157": { image: AssetsUrl.card.stone, name: "Stone" },
  "16668076000120": { image: AssetsUrl.card.sumup, name: "Sumup" },
  "08561701000101": { image: AssetsUrl.card.pagseguro, name: "PagSeguro" },
  "08561701014323": { image: AssetsUrl.card.pagseguro, name: "PagSeguro" },
  "04962772000165": { image: AssetsUrl.card.bin, name: "BIN" },
  "20520298000178": { image: AssetsUrl.card.adiq, name: "Adiq" },
  "34699670000160": { image: AssetsUrl.card.ton, name: "TON" },
  "01425787000104": { image: AssetsUrl.card.rede, name: "Rede" },
  "02038232000164": { image: AssetsUrl.card.bancoob, name: "Bancoob" },
  "22177858000169": { image: AssetsUrl.card.granito, name: "Granito" },
  "17887874000105": {
    image: AssetsUrl.card.global_payments,
    name: "Global Payments",
  },
  "21491969000182": { image: AssetsUrl.card.cloudwalk, name: "Cloudwalk" },
  "92934215000106": { image: AssetsUrl.card.vero, name: "Vero" },
  // Default case
  default: { image: AssetsUrl.card.other, name: "Outro" },
};
