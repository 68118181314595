import { ISubtitleCardInfo } from "./subtitle.types";

export const SubtitleCardInfo: Array<ISubtitleCardInfo> = [
  { label: "Vencimento", key: "dueDate", strongValue: true },
  {
    label: "Data de Fechamento",
    key: "bestTransactionDay",
    strongValue: false,
  },
];
