import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { usePendingComplements } from "./pending-complements.hook";

export const PendingComplementsPage = () => {
  const { gridRef, grid, dataSource } = usePendingComplements();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Contratos com complementos pendentes"
          description="Visualize todos os contratos com complementos pendentes e edite-os."
        />
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
