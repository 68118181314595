import { masks } from "@maestro/utils";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { Company } from "../../../canceling.types";

export const buildCompanyOptions = (
  companies: Company[] | undefined,
  selectCompany: (id: string, label: string) => void,
): SelectSearchOption<string>[] =>
  companies?.map((company) => {
    const label = `${company.name} - ${masks.cpfCnpj(company.identification)}`;

    return {
      value: company.id.toString(),
      label,
      onSelect: () => selectCompany(company.id.toString(), label),
      customKey: company.id,
    };
  }) ?? [];
