import { DetailsCard } from "components/details-card";
import { RoadwalkerMessage } from "./roadwalker-master-detail.types";
import { roadwalkerDetail } from "./details";

interface RoadwalkerMasterDetailProps {
  data: RoadwalkerMessage;
  messageType: string;
}

export const RoadwalkerMasterDetail = ({
  data,
  messageType,
}: RoadwalkerMasterDetailProps) => (
  <DetailsCard
    value={data}
    fields={(_data) => roadwalkerDetail(_data, messageType)}
  />
);
