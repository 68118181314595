import { OCol, ORow } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { energiaRoles } from "../../../../../roles/energia.roles";
import { DistributorSelector } from "./_compose";
import { useRecommendedDistributorOptions } from "./recommended-distributor-options.hook";

export const RecommendedDistributorOptions = () => {
  const {
    currentDistributors,
    errorCurrentDistributors,
    fetchDistributors,
    form,
    hasErrorCurrentDistributors,
    loadingCurrentDistributors,
    loadingUpdateDistributors,
    onSubmit,
  } = useRecommendedDistributorOptions();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Distribuidoras" />}
      actions={
        <LoadingButton
          loading={loadingUpdateDistributors}
          onClick={onSubmit}
          role={energiaRoles.product.updateAdequatorDistributors.role}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loadingCurrentDistributors}
          hasError={hasErrorCurrentDistributors}
          errorComponent={
            <DefaultErrorComponent
              callback={fetchDistributors}
              title="Erro ao carregar as adequadoras recomendadas"
              paragraph={errorCurrentDistributors as string}
            />
          }
          value={currentDistributors}
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol xs={12} md={6}>
                  <DistributorSelector />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
