import { useServiceCall } from "hooks/service-call";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { service } from "services";
import { useCardsCustomerContext } from "../../../../contexts";
import {
  OfferGuarantorsContext,
  OfferGuarantorsContextDefaultData,
} from "./offer-guarantors.types";

const offerGuarantorsContext = createContext<OfferGuarantorsContext>(
  OfferGuarantorsContextDefaultData,
);

export const OfferGuarantorsProvider = ({ children }: PropsWithChildren) => {
  const { offer, loadingOffer, offerError, fetchCardsOffer } =
    useCardsCustomerContext();

  const {
    value: getCardsOfferGuarantorsResponse,
    callService: getCardsOfferGuarantors,
    error: getCardsOfferGuarantorsError,
    loading: getCardsOfferGuarantorsLoading,
  } = useServiceCall(service.bankinghub.getCardsOfferGuarantors);

  const offerGuarantors = useMemo(
    () => getCardsOfferGuarantorsResponse?.guarantorsResponse,
    [getCardsOfferGuarantorsResponse],
  );

  const handleOfferGuarantors = useCallback(() => {
    if (!offer) return;
    const { id } = offer;
    if (!offerGuarantors) getCardsOfferGuarantors(id);
  }, [offer, offerGuarantors, getCardsOfferGuarantors]);

  useEffect(() => handleOfferGuarantors(), [handleOfferGuarantors]);

  const fetch = useCallback(() => {
    if (!!offerError || !offer) fetchCardsOffer();
    else handleOfferGuarantors();
  }, [offerError, offer, fetchCardsOffer, handleOfferGuarantors]);

  const fetchHasError = useMemo(
    () => !!offerError || !!getCardsOfferGuarantorsError,
    [offerError, getCardsOfferGuarantorsError],
  );

  const fetchLoading = useMemo(
    () => loadingOffer || getCardsOfferGuarantorsLoading,
    [loadingOffer, getCardsOfferGuarantorsLoading],
  );

  const value = useMemo(
    () => ({
      offerGuarantors,
      fetch,
      fetchHasError,
      fetchLoading,
    }),
    [offerGuarantors, fetch, fetchHasError, fetchLoading],
  );

  return (
    <offerGuarantorsContext.Provider value={value}>
      {children}
    </offerGuarantorsContext.Provider>
  );
};

export const useOfferGuarantorsContext = () =>
  useContext(offerGuarantorsContext);
