import { OOption, ORFieldInput, ORFieldSelect, Tags } from "@maestro/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  componentPropertyTypeMap,
  getComponentPropertyFieldId,
} from "../component-property-field.utils";

interface GenericPropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  handleSubmitComponentProperty: (
    formStepCardComponentId: number,
    propertyId: number,
    value: string,
  ) => Promise<void>;
  formStepCardComponentId: number;
}

export const GenericPropertyField = ({
  componentProperty,
  formStepCardComponentId,
  handleSubmitComponentProperty,
}: GenericPropertyFieldProps) => {
  const valueRef = useRef("");

  const { setValue, getValues, watch } = useFormContext();

  const property = getComponentPropertyFieldId(
    componentProperty.name,
    componentProperty.id,
  );

  const fieldWatch = watch(property);

  const handleFocusOut = useCallback(() => {
    return handleSubmitComponentProperty(
      formStepCardComponentId,
      componentProperty.id,
      getValues(property).toString(),
    );
  }, [
    getValues,
    handleSubmitComponentProperty,
    formStepCardComponentId,
    componentProperty,
    property,
  ]);

  useEffect(() => {
    if (componentProperty.value === undefined) return;

    valueRef.current = componentProperty.value;

    setValue(property, componentProperty.value);
  }, [setValue, componentProperty, property]);

  useEffect(() => {
    if (valueRef.current === fieldWatch) return;

    const propertyInput = document.getElementById(property);

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [
    handleFocusOut,
    componentProperty.name,
    componentProperty.id,
    property,
    fieldWatch,
  ]);

  const genericPropertyField = useMemo(() => {
    return (
      <>
        {!componentProperty.allowedValues?.length ? (
          <ORFieldInput
            key={componentProperty.name + componentProperty.id}
            id={property}
            name={property}
            tag={
              getValueFromMap(
                componentPropertyTypeMap,
                componentProperty.type,
              ) as Tags
            }
            label={componentProperty.description}
            dataAction={`editar_propriedade_componente:texto:${componentProperty.name.toLowerCase()}`}
            dataLabel={componentProperty.name.toLowerCase()}
            required={componentProperty.isRequired}
          />
        ) : (
          <ORFieldSelect
            key={componentProperty.name + componentProperty.id}
            id={property}
            name={property}
            label={componentProperty.description}
            placeholder="Selecione"
            required={componentProperty.isRequired}
          >
            <div>
              {componentProperty.allowedValues?.map((option) => (
                <OOption key={option} value={option}>
                  {option}
                </OOption>
              ))}
            </div>
          </ORFieldSelect>
        )}
      </>
    );
  }, [componentProperty, property]);

  return <>{genericPropertyField}</>;
};
