import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export type WorkflowCondition = Awaited<
  ReturnType<typeof service.hubCreditManager.getConditions>
>["data"][number];

export const workflowConditionssGrid = {
  datagrid: {
    noDataText: "Nenhuma condição encontrada",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getConditions().catch(() => {
        throw new Error("Não foi possível buscar as condições.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "conditionName",
      caption: "Nome",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "status",
      caption: "Status",
    },
    {
      dataField: "expression",
      caption: "Expressão da condição",
    },
    {
      caption: "Ações",
      fixed: true,
      fixedPosition: "right",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar",
              role: roles.workflow.product.conditionsDetailsEditPage.role,
              route: `${corporateRouter.routes.workflow.product.conditions.details.edit.path(
                {
                  id: data.id,
                },
              )}?data=${encodeURIComponent(JSON.stringify(data))}`,
            },
            {
              icon: { category: "fal", icon: "fa-code" },
              label: "Testar",
              role: roles.workflow.product.conditionsDetailsTestPage.role,
              route: `${corporateRouter.routes.workflow.product.conditions.details.test.path(
                {
                  id: data.id,
                },
              )}?data=${encodeURIComponent(JSON.stringify(data))}`,
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowCondition>;
