import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { GetOdataReceivableStateMachineODataViewItem } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getHeaderFilter } from "./note-note-legacy.utils";

export const dataSource =
  dataSourceODataStoreGenerator<GetOdataReceivableStateMachineODataViewItem>(
    endpoints.quickfin.odata.receivableStateMachineODataView,
  );

export const grid: ODataGridGeneratorConfig<GetOdataReceivableStateMachineODataViewItem> =
  {
    datagrid: {
      noDataText: "Nenhum dado encontrado",
      filterRow: { visible: true },
      headerFilter: { visible: true },
    },
    columns: [
      {
        allowExporting: false,
        caption: "Detalhes",
        role: roles.recebiveis.product.titlesNoteNoteLegacyDetailsReceivablePage
          .role,
        cellRender: ({ data: { Id } }) => (
          <DetailsLink
            href={corporateRouter.routes.recebiveis.product.titles.noteNoteLegacy.details.receivable.path(
              {
                receivableId: Id,
              },
            )}
          />
        ),
      },
      {
        dataType: "number",
        caption: "Receivable Id",
        dataField: "ReceivableId",
      },
      {
        dataType: "number",
        caption: "Trade Id",
        dataField: "TradeId",
      },
      {
        dataType: "string",
        dataField: "ReceivableIdentification",
        caption: "Nº nota",
      },
      {
        dataType: "string",
        dataField: "State",
        caption: "State",
        headerFilter: {
          dataSource: getHeaderFilter("ReceivableStateMachine", "CurrentState"),
        },
      },
      {
        dataType: "string",
        caption: "Sacado",
        dataField: "Sacado",
        headerFilter: {
          dataSource: getHeaderFilter("Company", "OfficialName"),
        },
      },
      {
        dataType: "string",
        caption: "Cedente",
        dataField: "Cedente",
        headerFilter: {
          dataSource: getHeaderFilter("Company", "OfficialName"),
        },
      },
      {
        dataType: "string",
        dataField: "CedenteIdentification",
        caption: "Identificação cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        caption: "Valor vencimento",
        format: (value: number) => masks.currency(value),
        dataType: "number",
        calculateCellValue: ({ ValorVencimentoTrade, ValorVencimento }) =>
          ValorVencimentoTrade ?? ValorVencimento,
      },
      {
        format: (value) => masks.currency(value),
        dataType: "number",
        dataField: "ValorDesembolso",
        caption: "Valor desembolso",
      },
      {
        format: (value) => masks.currency(value),
        dataType: "number",
        caption: "Rebate",
        dataField: "Rebate",
      },
      {
        dataType: "string",
        caption: "Moeda",
        dataField: "Currency",
      },
      {
        caption: "Data do trade",
        dataField: "TradeDate",
        dataType: "date",
        format: "shortDate",
      },
      {
        caption: "Data desembolso",
        dataField: "DataDesembolso",
        dataType: "date",
        format: "shortDate",
      },
      {
        caption: "Data de vencimento",
        dataField: "DataVencimento",
        dataType: "date",
        format: "shortDate",
      },
      {
        caption: "Vencimento alongado",
        dataField: "DataVencimentoTrade",
        dataType: "date",
        format: "shortDate",
      },
      {
        caption: "Criado em",
        dataField: "CreatedAt",
        dataType: "date",
        format: "shortDate",
      },
      {
        dataType: "string",
        caption: "Estratégia",
        dataField: "EstrategiaComercialNome",
      },
      {
        dataType: "string",
        caption: "Marketplace",
        dataField: "MarketplaceName",
      },
      {
        caption: "Atualizado em",
        dataField: "UpdatedAt",
        dataType: "datetime",
        format: "shortDateShortTime",
        filterValue: dayjs().startOf("day").toDate(),
        selectedFilterOperation: ">=",
        sortOrder: "desc",
      },
      {
        dataType: "string",
        caption: "Tipo produto",
        dataField: "TipoProduto",
        headerFilter: {
          dataSource: getHeaderFilter("Receivable", "TipoProduto"),
        },
      },
      {
        dataType: "number",
        dataField: "BorderoId",
        caption: "Identificador importação",
        headerFilter: {
          dataSource: getHeaderFilter("Receivable", "BorderoId"),
        },
      },
      {
        dataType: "string",
        dataField: "ExternalId",
        caption: "Identificador Externo",
      },
    ],
  };
