import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetAmbevNotificationsResponse } from "services/fidc/models/responses";
import { FidcNotificationsMasterDetail } from "./notifications-table.master-detail";

export const notificationsTableGrid: ODataGridGeneratorConfig<
  GetAmbevNotificationsResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhuma notificação encontrada",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} notificações)",
    },
  },
  columns: [
    {
      caption: "Data de Envio",
      dataField: "dateSent",
      dataType: "datetime",
      format: "shortDateShortTime",
      sortOrder: "desc",
    },
    {
      caption: "Sender",
      dataField: "sender",
    },
    {
      caption: "Quantidade de Destinatários",
      dataField: "signersInfos",
      dataType: "number",
      cellRender: ({ data }) =>
        data.signersInfos ? data.signersInfos.length : 0,
    },
    {
      caption: "ID",
      dataField: "id",
    },
  ],
  masterDetail: {
    enabled: true,
    component: FidcNotificationsMasterDetail,
  },
};
