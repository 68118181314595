import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { Operations } from "../pages";
import { OperationsDetails } from "../pages/cliente/operacoes/[id]";
import {
  AvailableReceivables,
  SacadoSelection,
} from "../pages/cliente/recebiveis";
import { antecipacaoAFornecedorRoles } from "../roles/antecipacao-a-forncedor.roles";
import { antecipacaoAFornecedorRouteParams } from "./antecipacao-a-fornecedor.route-params";

export const antecipacaoAFornecedorRoutes = {
  customer: {
    name: "",
    path: "/antecipacao-a-fornecedor/cliente",
    element: <RedirectWithRoles />,
    children: {
      operations: {
        name: "Operações",
        path: "/antecipacao-a-fornecedor/cliente/operacoes",
        element: <Operations />,
        role: antecipacaoAFornecedorRoles.customer.operationsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/antecipacao-a-fornecedor/cliente/operacoes/:${antecipacaoAFornecedorRouteParams.id}`,
            element: <OperationsDetails />,
            role: antecipacaoAFornecedorRoles.customer.operationsDetailsPage
              .role,
          },
        },
      },
      receivables: {
        name: "Recebíveis disponíveis",
        path: "/antecipacao-a-fornecedor/cliente/recebiveis",
        element: <SacadoSelection />,
        role: antecipacaoAFornecedorRoles.customer.receivablesPage.role,
        children: {
          sacado: {
            name: "Sacado",
            path: `/antecipacao-a-fornecedor/cliente/recebiveis/:${antecipacaoAFornecedorRouteParams.sacadoId}`,
            element: <AvailableReceivables />,
            role: antecipacaoAFornecedorRoles.customer.receivablesSacadoPage
              .role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
