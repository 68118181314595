import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { CollateralDetailsModalProps } from "../collateral-details.types";

export const ImovelCollateralDetailsModalContent = ({
  collaterals,
}: CollateralDetailsModalProps) => {
  return (
    <div className="d-flex flex-column gap-3">
      {collaterals?.map((collateral) => (
        <div
          className="d-flex flex-column gap-3 p-3"
          key={`collateral-imovel-${collateral.id}`}
        >
          <div>
            <OTypography type="dark">Descrição</OTypography>
            <OTypography type="dark-60">{collateral.description}</OTypography>
          </div>
          <div>
            <OTypography type="dark">Valor</OTypography>
            <OTypography type="dark-60">
              {masks.currency(collateral.amount, "R$", ".")}
            </OTypography>
          </div>
          <div>
            <OTypography type="dark">Cidade</OTypography>
            <OTypography type="dark-60">
              {!!collateral.properties[0]?.address?.city &&
              !!collateral.properties[0]?.address?.state
                ? `${collateral.properties[0].address.city}, ${collateral.properties[0].address.state}`
                : "-"}
            </OTypography>
          </div>
          <div>
            <OTypography type="dark">Percentual Garantido</OTypography>
            <OTypography type="dark-60">
              {masks.percentage(collateral.guaranteedPercentage)}
            </OTypography>
          </div>
          <div>
            <OTypography type="dark">RGI</OTypography>
            <OTypography type="dark-60">
              {collateral.properties[0]?.rgi ?? "-"}
            </OTypography>
          </div>
          <div>
            <OTypography type="dark">Matrícula</OTypography>
            <OTypography type="dark-60">
              {collateral.properties[0]?.registration ?? "-"}
            </OTypography>
          </div>
        </div>
      ))}
    </div>
  );
};
