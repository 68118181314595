import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const NomeField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:descricao"
        dataLabel="descricao"
        id="nome"
        name="nome"
        tag="text"
        placeholder="Preencher"
        label={EstrategiaFormLabels.nome}
        description="Nome da estratégia comercial"
        required
      />
    </OCol>
  );
};
