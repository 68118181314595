import { ODataGridGeneratorConfig } from "components/data-grid";
import { WorkflowLeadAnalysisDetails } from "services/hubcreditmanager/models/responses";

export const creditLineAnalysisDetailsGrid = {
  datagrid: {
    noDataText: "Nenhum detalhe sobre a análise disponível",
    columnAutoWidth: true,
  },
  columns: [
    {
      dataField: "analysisDetailType",
      caption: "Tipo",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowLeadAnalysisDetails>;
