import { OTypography } from "@maestro/react";
import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useMemo } from "react";
import { useQuote } from "../../quote.context";

export const RequestAnticipation = () => {
  const { anticipate, quoteData } = useQuote();

  const numberOfOperations = useMemo(
    () =>
      [
        ...new Set(
          quoteData.map(
            (quote) =>
              `${quote.recebivel.cedenteCnpj}${quote.recebivel.sacadoCnpj}`,
          ),
        ),
      ].length,
    [quoteData],
  );

  const numberOfOperationsText =
    numberOfOperations > 1
      ? `Serão criadas ${numberOfOperations} operações.`
      : "Será criada 1 operação.";

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>{numberOfOperationsText}</OTypography>
      <OTypography>Esse processo pode demorar alguns minutos.</OTypography>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <NextStepButton label="Solicitar antecipação" onClick={anticipate} />
      </div>
    </div>
  );
};
