import type { CustomParamsSerializer } from "axios";

type StringifyParamsSerializer = (
  filterNullish: boolean,
) => CustomParamsSerializer;

export const stringifyParamsSerializer: StringifyParamsSerializer =
  (filterNullish) => (params) =>
    new URLSearchParams(
      Object.fromEntries(
        Object.entries(params)
          .filter(([_key, value]) => !filterNullish || !!value)
          .map(([key, value]) => [key, JSON.stringify(value)]),
      ),
    ).toString();
