import { Roles } from "roles/roles.types";

export const cobrancasRoles = {
  product: {
    tab: {
      role: "admin:CobrancasPageProduct.View",
      description: "Visualização da tab 'Cobranças' na visão produto",
    },
    dashboardPage: {
      role: "admin:CobrancasDashboardPageProduct.View",
      description:
        "Visualização da página 'Cobranças - Dashboard' na visão produto",
    },
    estimulosPage: {
      role: "admin:CobrancasEstimulosPageProduct.View",
      description:
        "Visualização da página 'Cobranças - Estímulos' na visão produto",
    },
    disparoManualPage: {
      role: "admin:CobrancasDisparoManualPageProduct.View",
      description:
        "Visualização da página 'Cobranças - Disparo manual' na visão produto",
    },
  },
} satisfies Roles;
