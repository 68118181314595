import { memo, useContext } from "react";
import { Column } from "devextreme-react/data-grid";
import { OButton, ORow, OCol, OTypography, modalManager } from "@maestro/react";
import { ODataGrid } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { IntegrationModalName } from "../enums/integrationModalName";
import { IntegrationContext } from "../context/integration.context";

const IntegrationAccountGrid = () => {
  const { integration, setIntegration } = useContext(IntegrationContext);

  const data = integration.accounts.map((account) => {
    return {
      ...account,
      accountNumber: `${account.accountNumber}-${account.accountDigit}`,
    };
  });

  const handleClick = (values: any) => {
    const filteredContact = integration.accounts.filter(
      (contact) =>
        contact.accountNumber !== values.key.accountNumber &&
        contact.branch !== values.key.branch,
    );
    setIntegration({ ...integration, accounts: filteredContact });
  };

  return (
    <ORow className="mt-5">
      <ORow className="p-2 w-100">
        <OCol className="pt-2 pr-3" align="start">
          <OTypography size="md">Contas</OTypography>
        </OCol>
        <OCol justify="end" align="end" className="pl-3">
          <OButton
            className="mt-1 mr-0 pr-0 pl-3"
            onClick={() => {
              modalManager.show(IntegrationModalName.ADD_ACCOUNT_INTEGRATION);
            }}
          >
            Adicionar Conta
          </OButton>
        </OCol>
      </ORow>
      <ODataGrid
        dataSource={data}
        showBorders={false}
        showRowLines
        showColumnLines={false}
        scrolling={{ showScrollbar: "onScroll" }}
        columnAutoWidth
      >
        <Column
          dataField="accountNumber"
          caption="Conta"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="branch"
          caption="Agência"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="actions"
          caption="Ações"
          cellRender={(values) => {
            return (
              <DataGridAction
                actions={[
                  {
                    label: "Excluir",
                    icon: {
                      category: "orq",
                      icon: "orq-edit-trash",
                    },
                    onClick: async () => {
                      handleClick(values);
                    },
                  },
                ]}
              />
            );
          }}
          alignment="right"
          allowSorting={false}
          data-testid="technical-contacts-grid-action-cell"
        />
      </ODataGrid>
    </ORow>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(IntegrationAccountGrid);
