import { OIcon, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { useSimulation } from "../../simulation/simulation.context";

export const ExpensesMessage = () => {
  const { selectedSimulationResult } = useSimulation();
  const hasAdditionalFees = !!selectedSimulationResult?.additionalFees?.some(
    (a) => a.amount > 0,
  );

  const isNotBrl = selectedSimulationResult?.currency?.toUpperCase() !== "BRL";

  const SimulationMessageMemo = useMemo(() => {
    return (
      <div className="d-flex mt-3 mb-2 gap-1">
        {isNotBrl && (
          <>
            <OIcon category="fa" icon="fa-exclamation-circle" type="info" />
            <OTypography className="d-inline m-0">
              {`Todos os valores descritos em Reais podem variar de acordo com o mercado${
                hasAdditionalFees ? ", à exceção das despesas da operação" : ""
              }.`}
            </OTypography>
          </>
        )}
      </div>
    );
  }, [hasAdditionalFees, isNotBrl]);

  return <>{SimulationMessageMemo}</>;
};
