import { OfferGuarantorPortalStatus } from "services/bankinghub/models/types/cards";

export const OFFER_GUARANTOR_PORTAL_PENDING_STATUSES: OfferGuarantorPortalStatus[] =
  [
    OfferGuarantorPortalStatus.Pendente,
    OfferGuarantorPortalStatus.PendenteAjuste,
    OfferGuarantorPortalStatus.PendenteBiometria,
  ];

export const OFFER_GUARANTOR_PORTAL_IN_ANALYSIS_STATUSES: OfferGuarantorPortalStatus[] =
  [
    OfferGuarantorPortalStatus.EmAnalise,
    OfferGuarantorPortalStatus.EmAnaliseKyc,
    OfferGuarantorPortalStatus.PendenteEnvio,
    OfferGuarantorPortalStatus.PententeAdmin,
    OfferGuarantorPortalStatus.PendenteAssinaturaDocumentos,
    OfferGuarantorPortalStatus.Indefinido,
    OfferGuarantorPortalStatus.AguardandoNaFilaDeEnvio,
  ];

export const OFFER_GUARANTOR_PORTAL_IN_REPROVED_STATUSES: OfferGuarantorPortalStatus[] =
  [OfferGuarantorPortalStatus.Reprovado];
