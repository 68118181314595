import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useDocumentTypeSelector } from "./document-type-selector.hook";

interface DocumentTypeSelectorProps {
  id: string;
}

export const DocumentTypeSelector = ({ id }: DocumentTypeSelectorProps) => {
  const { documentTypes, hasFetchError, loading, getDocumentTypes } =
    useDocumentTypeSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={documentTypes}
        id={id}
        name="documentTypeId"
        label="Tipo de documento"
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getDocumentTypes} />
      )}
    </div>
  );
};
