import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse } from "services/quickfin/models";
import { logger } from "utils/logger";
import { TaxaFromSpreadsheet } from "./batch-taxas.types";
import { useVerifyTaxas } from "./verify-taxas.hook";

interface BatchTaxasContext {
  clear: () => void;
  companyId: number;
  setTaxas: (taxas: TaxaFromSpreadsheet[]) => void;
  submit: () => void;
  submitLoading: boolean;
  taxas: TaxaFromSpreadsheet[] | undefined;
  validationHasError: boolean;
  validationLoading: boolean;
  validationResults:
    | PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse
    | undefined;
  verifyTaxas: () => Promise<void> | undefined;
}

const batchTaxasContext = createContext({} as BatchTaxasContext);

export interface BatchTaxasProviderProps {
  children?: React.ReactNode;
  estrategiaId: number;
}

export const BatchTaxasProvider = ({
  children,
  estrategiaId,
}: BatchTaxasProviderProps) => {
  const {
    customer: { quickfinId: companyId },
  } = useCustomer();

  const [taxas, setTaxas] = useState<TaxaFromSpreadsheet[]>();

  const {
    clear: validationClear,
    verifyTaxas: _verifyTaxas,
    hasError: validationHasError,
    loading: validationLoading,
    validationResults,
  } = useVerifyTaxas();

  const { callService: uploadTaxasFile, loading: submitLoading } =
    useServiceCall(service.quickfin.uploadTaxasFile);

  const navigate = useNavigate();

  const verifyTaxas = useCallback(() => {
    // this if should never happen
    if (!taxas) {
      logger.error("No taxas");
      return;
    }

    return _verifyTaxas(companyId, estrategiaId, {
      id: Number(companyId),
      taxas,
    });
  }, [_verifyTaxas, companyId, estrategiaId, taxas]);

  const clear = useCallback(() => {
    setTaxas(undefined);
    validationClear();
  }, [validationClear]);

  const submit = useCallback(async () => {
    // this if should never happen
    if (!taxas) {
      logger.error("No taxas");
      return;
    }

    const { success } = await uploadTaxasFile(companyId, estrategiaId, {
      id: companyId,
      taxas,
    });

    if (success) {
      OToastManager.success("Taxas importadas com sucesso");
      navigate(
        corporateRouter.routes.recebiveis.customer.estrategiaComercial.byEstrategiaId.taxas.view.path(
          { estrategiaId },
        ),
      );
      clear();
    } else {
      OToastManager.danger("Não foi possível importar as taxas");
    }
  }, [clear, companyId, estrategiaId, navigate, taxas, uploadTaxasFile]);

  useEffect(() => {
    if (taxas) verifyTaxas();
  }, [taxas, verifyTaxas]);

  const value = useMemo(
    () => ({
      clear,
      companyId,
      setTaxas,
      submit,
      submitLoading,
      taxas,
      validationHasError,
      validationLoading,
      validationResults,
      verifyTaxas,
    }),
    [
      clear,
      companyId,
      submit,
      submitLoading,
      taxas,
      validationHasError,
      validationLoading,
      validationResults,
      verifyTaxas,
    ],
  );

  return (
    <batchTaxasContext.Provider value={value}>
      {children}
    </batchTaxasContext.Provider>
  );
};

export const useBatchTaxas = () => useContext(batchTaxasContext);
