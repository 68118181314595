import { OToastManager } from "@maestro/core";
import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import { Power } from "./company-acts.types";
import { hasUnregisteredUsers, statusMap } from "./company-acts.utils";
import { GroupsMasterDetail } from "./groups.master-detail";

export const buildDataSource = (useCache: boolean) =>
  dataSourceCustomStoreGenerator<Power>(() =>
    service.onboardingRelationships
      .getIrisPowersNew({ useCache })
      .then(({ data }) => data.powers)
      .catch(() => {
        const errorMessage = "Não foi possível buscar os poderes";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const powersGrid: ODataGridGeneratorConfig<Power> = {
  datagrid: {
    noDataText: "Nenhum poder",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true, allowedPageSizes: [20, 50, 100, 500] },
    stateStoring: gridStorage("powersGrid"),
  },
  columns: [
    { dataField: "id", caption: "ID" },
    {
      dataField: "act",
      caption: "Ato",
    },
    {
      dataField: "groupName",
      caption: "Grupo",
    },
    {
      dataField: "amountSignature",
      caption: "Assinaturas",
      allowHeaderFiltering: false,
      alignment: "center",
    },
    {
      dataType: "string",
      dataField: "usersInCompany",
      caption: "Usuários cadastrados",
      allowFiltering: false,
      allowHeaderFiltering: true,
      alignment: "center",
      cellRender: ({ data, value }) => (
        <div className="d-inline-flex align-items-center gap-2">
          <span>{data.usersInCompany}</span>
          {value === "Check" ? (
            <OIcon
              category="orq"
              icon="orq-status-success"
              type="success"
              size="md"
            />
          ) : (
            <OIcon category="orq" icon="orq-swap-circle" size="md" />
          )}
        </div>
      ),
      calculateCellValue: (data) =>
        hasUnregisteredUsers(data) ? "Sync" : "Check",
    },
    {
      dataField: "statusName",
      caption: "Status",
      cellRender: ({ data }) => {
        const mappedStatus = getValueFromMap(statusMap, data.statusName);

        return mappedStatus ? (
          <StatusBadge type={mappedStatus.type}>
            {mappedStatus.label}
          </StatusBadge>
        ) : (
          data.statusName
        );
      },
    },
    { dataField: "justificativeRestriction", caption: "Restrição" },
    {
      dataField: "maxValue",
      caption: "Limite",
      format: (value) => masks.currency(value || 0, "R$", "."),
    },
    {
      dataField: "expiraDate",
      dataType: "date",
      format: "shortDate",
      caption: "Data de expiração",
      allowHeaderFiltering: false,
    },
  ],
  masterDetail: {
    enabled: true,
    component: GroupsMasterDetail,
  },
  paging: {
    defaultPageSize: 20,
  },
};
