import { IType } from "@maestro/core";

type PartnersProfiles = "Owner" | "Agent" | "Assistant";

type InternalProfiles =
  | "Business"
  | "Technology"
  | "Support"
  | "Visitor"
  | "Operation";

export type Profiles = PartnersProfiles | InternalProfiles;

export const PROFILES_COLORS: Record<Profiles, IType> = {
  Owner: "info-light",
  Agent: "success-light",
  Assistant: "warning-light",
  Business: "quaternary",
  Operation: "secondary",
  Support: "danger",
  Technology: "primary",
  Visitor: "default",
};

export enum EProfiles {
  Owner = "Administrador",
  Agent = "Agente",
  Assistant = "Assistente",
  Business = "Business",
  Operation = "Operation",
  Support = "Suporte",
  Technology = "Tecnologia",
  Visitor = "Visitante",
}
