import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { DateFilter } from "./_compose";
import { buildDataSource, grid } from "./companies-transactions-summary.grid";

export const CompaniesTransactionsSummary = () => {
  const [datesInterval, setDatesInterval] = useState<[string, string]>([
    dayjs().startOf("month").format("YYYY/MM/DD"),
    dayjs().endOf("month").format("YYYY/MM/DD"),
  ]);

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(datesInterval[0], datesInterval[1]),
    [datesInterval],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Transações por Empresas" />}
      gridRef={gridRef}
      actions={
        <DateFilter
          datesInterval={datesInterval}
          setDatesInterval={setDatesInterval}
        />
      }
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
