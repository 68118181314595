import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useRegisterBankingAccount } from "./cadastro-conta-banking.hook";

export const RegisterBankingAccount = () => {
  const { form, loading, onSubmit } = useRegisterBankingAccount();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Ativação da Empresa" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="cadastro_conta_banking:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Enviar Empresa
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                dataAction="cadastro_conta_banking:texto:razao_social"
                dataLabel="razao_social"
                label="Razão social"
              />
            </OCol>
            <OCol align="end" xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="type_document"
                name="type_document"
                dataAction="cadastro_conta_banking:select:tipo_documento"
                dataLabel="tipo_documento"
                label="Tipo de documento"
              >
                <OOption value="2">CNPJ</OOption>
                <OOption value="1">CPF</OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="cpfcnpj"
                id="document"
                name="document"
                dataAction="cadastro_conta_banking:texto:cnpj_cpf"
                dataLabel="cnpj_cpf"
                label="CNPJ/CPF"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="covenant"
                name="covenant"
                dataAction="cadastro_conta_banking:texto:numero_convenio"
                dataLabel="numero_convenio"
                label="Número de convênio"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="agency_number"
                name="agency_number"
                dataAction="cadastro_conta_banking:texto:numero_agencia"
                dataLabel="numero_agencia"
                label="Número da agência"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="agency_digit"
                name="agency_digit"
                dataAction="cadastro_conta_banking:texto:digito_agencia"
                dataLabel="digito_agencia"
                label="Dígito da agência"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="account_number"
                name="account_number"
                dataAction="cadastro_conta_banking:texto:numero_conta"
                dataLabel="numero_conta"
                label="Número da conta"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="account_digit"
                name="account_digit"
                dataAction="cadastro_conta_banking:texto:digito_conta"
                dataLabel="digito_conta"
                label="Dígito da conta"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="wallet_code"
                name="wallet_code"
                dataAction="cadastro_conta_banking:texto:carteira"
                dataLabel="carteira"
                label="Carteira"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
