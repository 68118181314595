import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { Currency, mapCurrencySymbol } from "utils/currency";
import { AdditionalFee } from "../../../../offers.type";

interface AdditionalFeesModalProps {
  additionalFees: AdditionalFee[];
  currency?: Currency;
}

export const ADDITIONAL_FEES_MODAL = "additional-fees-modal";

const AdditionalFeesCards: React.FC<AdditionalFeesModalProps> = ({
  additionalFees,
  currency,
}) => {
  return (
    <div>
      <div className="card-body text-left p-3">
        {additionalFees.map((fee, i) => (
          <div
            key={`fee-${i}`}
            className={`d-flex align-items-center justify-content-between ${
              i !== additionalFees.length - 1 ? "pb-3" : "pb-0"
            }`}
          >
            <OTypography size="md" className="m-0 mw-75">
              {(() => {
                switch (fee?.type) {
                  case "Diligence":
                  case "FORM":
                    return "Custos de auditoria";
                  case "Registration":
                    return "Registro da garantia em cartório";
                  case "Report":
                  case "INVC":
                    return "Laudo de avaliação de terra";
                  case "TAC":
                  case "CAPF":
                    return "Taxa de abertura de crédito";
                  case "Seguro":
                    return "Seguro";
                  default:
                    return "";
                }
              })()}
            </OTypography>
            <OTypography className="m-0">
              {masks.currency(fee.amount, mapCurrencySymbol(currency))}
            </OTypography>
          </div>
        ))}
      </div>
    </div>
  );
};

export const AdditionalFeesModal = ({
  additionalFees,
  currency,
}: AdditionalFeesModalProps) => {
  return (
    <OModal
      className="modal-show text-center"
      id={ADDITIONAL_FEES_MODAL}
      position="right"
    >
      <OModalHeader closeButton>
        <OTypography size="lg">Despesas da operação</OTypography>
      </OModalHeader>
      <OModalBody>
        <AdditionalFeesCards
          additionalFees={additionalFees}
          currency={currency}
        />
      </OModalBody>
      <OModalFooter>
        <OButton
          className="btn-outline-dark"
          type="light"
          onClick={() => modalManager.hide(ADDITIONAL_FEES_MODAL)}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
