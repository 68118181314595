import { Menu } from "components/menu";
import { MenuConfig } from "components/menu/menu.types";
import { useRoles } from "hooks/roles";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "routes/routes.types";
import { RoutesTreeNode } from "./routes-menu.types";
import {
  baseDepth,
  buildRoutesTree,
  fillPathParams,
  filterMenuByRoles,
  findCurrentNode,
  findNearestParentWithElement,
  getCurrentPathParams,
  getGoBackLink,
  mapNodeToMenuConfigItems,
} from "./routes-menu.utils";

interface RoutesMenuProps {
  routes: Routes;
}

export const RoutesMenu = ({ routes }: RoutesMenuProps) => {
  const [menuConfig, setMenuConfig] = useState<MenuConfig>({
    items: [],
  });
  const [menuNode, setMenuNode] = useState<RoutesTreeNode | undefined>();

  const customHistory = useRef<string[]>([]);

  const location = useLocation();

  const navigate = useNavigate();

  const { hasRole } = useRoles();

  const tree = useMemo(() => buildRoutesTree(routes), [routes]);

  const currentNode = useMemo(
    () => findCurrentNode(tree, location.pathname),
    [location.pathname, tree],
  );

  const currentPathParams = useMemo(
    () => getCurrentPathParams(currentNode?.path, location.pathname),
    [currentNode?.path, location.pathname],
  );

  useLayoutEffect(() => {
    setMenuNode(currentNode?.parent);
  }, [currentNode?.parent]);

  useLayoutEffect(() => {
    customHistory.current.unshift(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (menuNode && currentNode) {
      let previous: MenuConfig["previous"];

      // never show `previous` for root level menu
      if (menuNode.depth > baseDepth && !menuNode.hideGoBack) {
        const { link: historyLink, newHistory } = getGoBackLink(
          tree,
          currentNode,
          customHistory.current,
        );

        // look in customHistory or defaults to nearest parent node
        const previousLink =
          historyLink ??
          fillPathParams(
            findNearestParentWithElement(menuNode).path,
            currentPathParams,
          );

        const previousOnClick = menuNode.hasSubItems
          ? () => setMenuNode(menuNode.parent)
          : () => {
              navigate(previousLink);
              customHistory.current = newHistory;
            };

        previous = {
          link: previousLink,
          label: menuNode.name,
          onClick: previousOnClick,
        };
      }

      const showAllChildren =
        menuNode.depth === baseDepth ||
        menuNode.hasSubItems ||
        menuNode.showChildren;

      const childrenToShow = showAllChildren
        ? menuNode.children
        : [currentNode];

      setMenuConfig({
        previous,
        items: childrenToShow
          .filter((childNode) => filterMenuByRoles(childNode, hasRole))
          .map((childNode) =>
            mapNodeToMenuConfigItems(
              childNode,
              currentNode,
              currentPathParams,
              setMenuNode,
            ),
          ),
      });
    }
  }, [currentNode, currentPathParams, hasRole, menuNode, navigate, tree]);

  return <Menu config={menuConfig} />;
};
