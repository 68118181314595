import { useCallback, useMemo } from "react";
import {
  OfferGuarantorItemRow,
  OfferGuarantorItemRowData,
  OfferGuarantorProperty,
} from "./offer-guarantors-list.types";
import {
  assembleOIconProps,
  MapperOfferGuarantorPropertyToValidationFunction,
} from "./offer-guarantors-list.utils";

export const useOfferGuarantorsList = () => {
  const assembleAddressRowData = useCallback(
    (data: BankingHubCards.OfferGuarantor): OfferGuarantorItemRowData => {
      let text = "Endereço";
      let iconProps = assembleOIconProps({ type: "warning" });

      const validationFunction =
        MapperOfferGuarantorPropertyToValidationFunction[
          OfferGuarantorProperty.Address
        ];

      const addressValidation = validationFunction(data);

      if (addressValidation.isValid) {
        iconProps = assembleOIconProps({ type: "success" });
      } else {
        text = addressValidation.error?.errorMessage ?? text;
      }

      return { text, iconProps };
    },
    [],
  );

  const assembleBiometryRowData = useCallback(
    (data: BankingHubCards.OfferGuarantor): OfferGuarantorItemRowData => {
      let text = "Biometria";
      let iconProps = assembleOIconProps({ type: "warning" });

      const validationFunction =
        MapperOfferGuarantorPropertyToValidationFunction[
          OfferGuarantorProperty.Biometry
        ];

      const biometryValidation = validationFunction(data);

      if (biometryValidation.isValid) {
        iconProps = assembleOIconProps({ type: "success" });
      } else {
        text = biometryValidation.error?.errorMessage ?? text;
      }

      return { text, iconProps };
    },
    [],
  );

  const assembleEmailAndPhoneRowData = useCallback(
    (data: BankingHubCards.OfferGuarantor): OfferGuarantorItemRowData => {
      let text = "E-mail e telefone";
      let iconProps = assembleOIconProps({ type: "warning" });

      const phoneNumberValidationFunction =
        MapperOfferGuarantorPropertyToValidationFunction[
          OfferGuarantorProperty.PhoneNumber
        ];

      const phoneNumberValidation = phoneNumberValidationFunction(data);

      const emailValidationFunction =
        MapperOfferGuarantorPropertyToValidationFunction[
          OfferGuarantorProperty.Email
        ];

      const emailValidation = emailValidationFunction(data);

      if (phoneNumberValidation.isValid && emailValidation.isValid) {
        iconProps = assembleOIconProps({ type: "success" });
      } else {
        if (!phoneNumberValidation.isValid)
          text = phoneNumberValidation.error?.errorMessage ?? text;

        if (!emailValidation.isValid)
          text = emailValidation.error?.errorMessage ?? text;
      }

      return { text, iconProps };
    },
    [],
  );

  const MapperOfferGuarantorItemRowToAssemble = useMemo(() => {
    return {
      [OfferGuarantorItemRow.Address]: assembleAddressRowData,
      [OfferGuarantorItemRow.Biometry]: assembleBiometryRowData,
      [OfferGuarantorItemRow.MailAndPhone]: assembleEmailAndPhoneRowData,
    };
  }, [
    assembleAddressRowData,
    assembleBiometryRowData,
    assembleEmailAndPhoneRowData,
  ]);

  return { MapperOfferGuarantorItemRowToAssemble };
};
