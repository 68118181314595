import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetDashboardOverallStatusResponse } from "services/fidc/models/responses";
import { currencySymbol } from "utils/currency";
import { financialStateMap } from "./edit-bank-slip.utils";

const buildCompanyDetailsLink = (fundCge: string, clientCge: string) => {
  const baseUrl =
    corporateRouter.routes.fidc.product.bankslipEditing.assets.path;
  const searchParams = new URLSearchParams();
  searchParams.set("fundCge", fundCge);
  searchParams.set("clientCge", clientCge);

  return baseUrl + "?" + searchParams.toString();
};

export const EditBankslipGrid: ODataGridGeneratorConfig<
  GetDashboardOverallStatusResponse["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma interação disponível.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} interações)",
    },
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      allowEditing: false,
      caption: "Detalhes",
      role: roles.fidc.product.bankslipEditingAssetsPage.role,
      cellRender: ({ data }) =>
        data.client && (
          <DetailsLink
            href={buildCompanyDetailsLink(data.fundCge, data.client.cge)}
          />
        ),
    },
    {
      dataField: "client.taxId",
      caption: "Documento",
      cellRender: ({ data }) => masks.cnpj(data.client.taxId),
    },
    {
      dataField: "client.name",
      caption: "Nome",
    },
    {
      dataField: "financialState",
      caption: "Situação",
      cellRender: ({ data }) => {
        const financialState = financialStateMap[data.financialState];
        return (
          <StatusBadge type={financialState.type} size="sm">
            {financialState.label}
          </StatusBadge>
        );
      },
    },
    {
      dataField: "tradesAmount",
      caption: "Qtd. de ativos",
    },
    {
      dataField: "loanedValue",
      caption: "Valor do empréstimo",
      cellRender: ({ data }) =>
        masks.currency(data.loanedValue, currencySymbol.BRL, "."),
    },
    {
      dataField: "dueValue",
      caption: "Posição atualizada",
      cellRender: ({ data }) =>
        masks.currency(data.dueValue, currencySymbol.BRL, "."),
    },
  ],
};
