import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { adminACCCounterpartiesODataGrid } from "./admin-procuct-acc-counterparties-odata.grid";

export const AdminProductACCCounterpartiesPage = () => {
  const gridRef = useGridRef();

  const grid = useMemo(() => adminACCCounterpartiesODataGrid, []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contrapartes" />}
      actions={
        <RouterButton
          dataAction="cambio_contrapartes:botao:adicionar_contraparte"
          dataLabel="adicionar_contraparte"
          href={corporateRouter.routes.acc.product.counterparties.add.path}
          role={roles.acc.product.counterpartiesAddPage.role}
        >
          Adicionar contraparte
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
