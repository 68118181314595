import { ODataGridGeneratorConfig } from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { CustomerWorkflowTrackerResponse } from "./customer-workflows-tracker.types";

const buildButtonLink = (workflowIdentifier: string) =>
  corporateRouter.routes.cadastro.customer.notification.workflows.invites.path({
    workflowIdentifier,
  });

export const customerWorkflowsTrackerGrid: ODataGridGeneratorConfig<CustomerWorkflowTrackerResponse> =
  {
    datagrid: {
      noDataText: "Nenhum processo de onboarding aberto",
      filterRow: { visible: true },
    },
    columns: [
      {
        dataField: "identifier",
        dataType: "string",
        caption: "Identificador",
      },
      {
        dataField: "status",
        dataType: "string",
        caption: "Status",
      },
      {
        dataField: "insertedAt",
        dataType: "datetime",
        caption: "Criado em",
      },
      {
        dataField: "updatedAt",
        dataType: "datetime",
        caption: "Atualizado em",
      },
      {
        caption: "Ações",
        role: roles.cadastro.customer.notificationInvitesPage.role,
        cellRender: ({ data }) => {
          if (!data.identifier) return;

          return (
            <RouterButton
              dataAction="onboardings_grid:botao:details"
              dataLabel="Detalhes"
              type="dark"
              outline
              href={buildButtonLink(data.identifier)}
            >
              Detalhes
            </RouterButton>
          );
        },
      },
    ],
  };
