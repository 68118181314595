import { OToastManager } from "@maestro/core";
import { OCard, OCardBody } from "@maestro/react";
import { DetailsGroup } from "components/details-card/_compose";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { customer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { DetailsTemplate } from "templates/details-template";
import { ContentTemplate } from "templates/content-template";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { OriginatorFields } from "./originator.details";

export const Originator = () => {
  const {
    loading,
    value: originatorDetails,
    callService: getOriginatorDetails,
    hasError,
  } = useServiceCall(service.hubPartners.originatorDetails);

  const getInfo = useCallback(async () => {
    const customerTaxId = customer.value?.identification;

    if (!customerTaxId) {
      return;
    }

    const { success } = await getOriginatorDetails(customerTaxId);

    if (!success) {
      OToastManager.danger("Falha ao obter detalhes do originador");
    }
  }, [getOriginatorDetails]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={loading}
      value={originatorDetails}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível buscar as informações."
          messageParagraph="Atualize a página para tentar novamente."
        >
          <TryAgainButton onClick={() => getInfo()} />
        </ErrorComponent>
      }
      render={(details) => (
        <DetailsTemplate
          pageTitle={<PageTitle title="Detalhes do Originador" />}
          actions={
            <RouterButton
              href={
                corporateRouter.routes.parceiros.customer.originator.details
                  .path
              }
              role={roles.parceiros.customer.originatorEditInfo.role}
            >
              Editar Originador
            </RouterButton>
          }
        >
          <div className="d-flex flex-column gap-3">
            {Object.entries(OriginatorFields(details.data)).map(
              ([key, group]) => (
                <OCard key={key}>
                  <OCardBody>
                    <DetailsGroup group={group} />
                  </OCardBody>
                </OCard>
              ),
            )}
          </div>
        </DetailsTemplate>
      )}
    />
  );
};
