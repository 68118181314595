import { OButton, OIcon, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { getValueFromMap } from "utils/get-value-from-map";
import { IconContainer } from "../cards.styles";
import { CompanyDetailsCardTemplate } from "../template";
import {
  notificationStatusMap,
  reviewStatusMap,
  reviewTypeMap,
} from "./onboarding-review-card.utils";

export const OnboardingReviewCard = () => {
  const { customer } = useCustomer();

  const navigate = useNavigate();

  const lastReview = useMemo(() => {
    const sortedReviews = customer.dataReviews;
    sortedReviews?.sort(
      (a, b) =>
        new Date(b.updatedAt ?? "").getTime() -
        new Date(a.updatedAt ?? "").getTime(),
    );

    return sortedReviews?.[0];
  }, [customer.dataReviews]);

  if (!lastReview) return null;

  const statusProps =
    getValueFromMap(reviewStatusMap, lastReview.status) ??
    reviewStatusMap.DEFAULT;

  const notificationStatus =
    getValueFromMap(notificationStatusMap, lastReview.notificationStatus) ??
    notificationStatusMap.DEFAULT;

  const typeProps =
    getValueFromMap(reviewTypeMap, lastReview.type) ?? reviewTypeMap.NULL;

  return (
    <CompanyDetailsCardTemplate title="Revisão cadastral">
      <div className="d-flex flex-column gap-3 w-100">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1">
            <IconContainer>
              <OIcon
                category="fa"
                icon={statusProps.icon}
                type={statusProps.type}
              />
            </IconContainer>
            <OTypography>Status:</OTypography>
          </div>
          <OTypography>{statusProps.text ?? lastReview.status}</OTypography>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1">
            <IconContainer>
              <OIcon category="fa" icon={typeProps.icon} />
            </IconContainer>
            <OTypography>Tipo:</OTypography>
          </div>
          <OTypography>{typeProps.text ?? lastReview.type}</OTypography>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1">
            <IconContainer>
              <OIcon category="fa" icon="fa-step-forward" />
            </IconContainer>
            <OTypography>Etapa</OTypography>
          </div>
          <OTypography>{notificationStatus}</OTypography>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-1">
            <IconContainer>
              <OIcon category="fa" icon="fa-history" />
            </IconContainer>
            <OTypography>Atualizado em:</OTypography>
          </div>
          <OTypography>
            {dayjs(lastReview.updatedAt).format("DD/MM/YYYY")}
          </OTypography>
        </div>
        <OButton
          dataAction="revisao_cadastral:botao:abrir_modal_historico_revisao_cadastral"
          dataLabel="abrir_modal_historico_revisao_cadastral"
          onClick={() =>
            navigate(
              corporateRouter.routes.cadastro.customer.details.onboardingReviews
                .path,
            )
          }
          className="align-self-center"
        >
          <OTypography type="light">Abrir histórico</OTypography>
        </OButton>
      </div>
    </CompanyDetailsCardTemplate>
  );
};
