import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { additionalOffersGrid } from "./additional-offers.grid";

export const AdditionalOffersPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const {
    callService: getLimitRequest,
    value: limitRequest,
    loading,
  } = useServiceCall(service.hubCreditManager.getLimitRequestMinified);

  useEffect(() => {
    if (!id) return;

    getLimitRequest(id);
  }, [getLimitRequest, id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ofertas adicionais" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !loading && getLimitRequest(id);
          }}
        />
      }
    >
      <ODataGridGenerator
        grid={additionalOffersGrid}
        loading={loading}
        dataSource={limitRequest?.additionalOffers ?? []}
      />
    </GridTemplate>
  );
};
