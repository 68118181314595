import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  FeesPaymentConfigFields,
  FeesRegisterPaymentAccountFields,
} from "./_compose";
import { useRegisterFeesSacado } from "./register-fees.hook";

export const RegisterFeesSacado = () => {
  const { form, loadingSubmit, onSubmit, profitShare, setProfitShare } =
    useRegisterFeesSacado();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Cadastrar fee" />}
      actions={
        <LoadingButton
          dataAction="formulario_taxa_rebate:botao:salvar"
          dataLabel="salvar"
          loading={loadingSubmit}
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <FeesPaymentConfigFields
            profitShare={profitShare}
            setProfitShare={setProfitShare}
          />
          <FeesRegisterPaymentAccountFields />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
