import { CACFormComponent } from "./_compose/cac-form.component";
import { CDBFormComponent } from "./_compose/cdb-form.component";
import { EquipamentFormComponent } from "./_compose/equipament-form.component";
import { InsuranceFormComponent } from "./_compose/insurance/insurance-form.component";
import { PromissoryNoteFormComponent } from "./_compose/promissory-note-form.component";

export const collateralOptions = [
  {
    value: "CAC",
    label: "CAC",
  },
  {
    value: "CDB",
    label: "CDB",
  },
  {
    value: "EQUIPAMENTO",
    label: "Equipamento",
  },
  {
    value: "NOTA_PROMISSORIA",
    label: "Nota Promissória",
  },
  {
    value: "PEAC",
    label: "PEAC",
  },
  {
    value: "SEGURO",
    label: "Seguro",
  },
];

export const formTypeMapper = {
  CAC: <CACFormComponent />,
  SEGURO: <InsuranceFormComponent />,
  CDB: <CDBFormComponent />,
  EQUIPAMENTO: <EquipamentFormComponent />,
  NOTA_PROMISSORIA: <PromissoryNoteFormComponent />,
};
