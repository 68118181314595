import dayjs from "dayjs";

export const getStartOfMonth = () =>
  dayjs().startOf("month").format("DD/MM/YYYY");

export const getEndOfMonth = () => dayjs().endOf("month").format("DD/MM/YYYY");

export const parseDateToFilter = (unparsedDate: string) => {
  const [day, month, year] = unparsedDate.split("/");
  return `${year}-${month}-${day}`;
};

export const validateDates = (initialDate: string, finalDate: string) => {
  const parsedInitialDate = new Date(parseDateToFilter(initialDate));
  const parsedFinalDate = new Date(parseDateToFilter(finalDate));

  if (dayjs(parsedFinalDate).diff(parsedInitialDate, "days") > 31)
    return "Intervalo de datas não pode ser superior a 31 dias";
};
