import dayjs from "dayjs";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import cloneDeep from "lodash/cloneDeep";
import maxBy from "lodash/maxBy";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";
import { Receivable } from "../../batch-upload-receivables.types";

export const addErrorsToReceivables = (
  receivables: Receivable[] = [],
  errorMessages: PostCompaniesByIdBorderosVerifyErrorResponse = [],
) => {
  const receivablesWithErrors = cloneDeep(receivables).map((receivable) => ({
    ...receivable,
    errorMessages: [],
  })) as Receivable[];

  errorMessages.forEach((error) => {
    const indexStr = error.key.match(/\d+/)?.[0];
    if (!indexStr) return;
    const index = Number(indexStr);
    receivablesWithErrors[index].errorMessages?.push(error);
  });

  return receivablesWithErrors;
};

export const calculateSummary = (receivables: Receivable[] = []) => {
  const totalMaturityAmount = receivables.reduce(
    (acc, current) => acc + (current.maturityAmount ?? 0),
    0,
  );

  const totalDisbursementAmount = receivables.reduce(
    (acc, current) => acc + (current.disbursementAmount ?? 0),
    0,
  );

  const maxDate = dayjs(maxBy(receivables, "maturityDate")?.maturityDate)
    .utc(false)
    .format("DD/MM/YYYY");

  const totalErrors = receivables.reduce(
    (acc, current) => acc + (current.errorMessages?.length ?? 0),
    0,
  );

  return {
    totalMaturityAmount,
    totalDisbursementAmount,
    maxDate,
    totalErrors,
  };
};

export const highlightErrors = ({
  data,
  column: { dataField },
  cellElement,
  rowType,
}: CellPreparedEvent<Receivable>) => {
  if (
    rowType === "data" &&
    data?.errorMessages?.some(
      (err) => err.key.split(".")[1].toLowerCase() === dataField?.toLowerCase(),
    )
  ) {
    cellElement.style.setProperty(
      "background-color",
      "var(--theme-danger-light)",
    );
    cellElement.style.setProperty("color", "var(--theme-danger-light-inverse)");
  }
};
