import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
} from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { mapLoanStatus } from "../../../../../components/utils/loan-status.utils";

const loanPendencyTypeMap = {
  CONTRACT_CONFIGURATION: "Configuração de contrato",
  LIMIT_CONFIRMATION: "Confirmação do limite",
  EXCEPTION: "Erro",
};

export const pendenciesGrid = {
  datagrid: {
    noDataText: "Não foram encontrados pendências.",
  },
  columns: [
    {
      caption: "Tipo de Pendência",
      dataField: "type",
      cellRender: ({ data }) => {
        const type =
          getValueFromMap(loanPendencyTypeMap, data.type) || data.type;
        return (
          <>
            {data.message ? (
              <>
                <OModal position="center" id={`error_modal_${data.id}`}>
                  <OModalHeader>Mensagem de erro</OModalHeader>
                  <OModalBody>
                    <div
                      style={{
                        whiteSpace: "normal",
                        backgroundColor: "#f2f2f2",
                        overflow: "auto",
                      }}
                    >
                      {data.message}
                    </div>
                  </OModalBody>
                  <OModalFooter />
                </OModal>
                <OButton
                  type="danger"
                  onClick={() => modalManager.show(`error_modal_${data.id}`)}
                  outline
                  bordered={false}
                >
                  {type}
                </OButton>
              </>
            ) : (
              type
            )}
          </>
        );
      },
    },
    {
      caption: "Status em que ocorreu",
      dataField: "loanStatus",
      format: (value) => mapLoanStatus(value),
    },
    {
      caption: "Situação",
      alignment: "left",
      dataField: "pending",
      cellRender: ({ data }) => (
        <StatusBadge type={data.pending ? "warning" : "success"}>
          {data.pending ? "Pendente" : "Resolvida"}
        </StatusBadge>
      ),
    },
    {
      caption: "Resolvida em",
      dataField: "solvedDate",
      dataType: "date",
    },
    {
      caption: "Resolvida por",
      dataField: "resolvedBy",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) =>
        data.pending && (
          <ButtonWithConfirmation
            confirmationModal={{
              title:
                "Tem certeza que deseja marcar a pendência como resolvida?",
              confirmLabel: "Confirmar",
              closeLabel: "Cancelar",
            }}
            onConfirm={async () => {
              try {
                await service.hubLoan.resolvePendency(data.id);

                OToastManager.success("Pendência resolvida com sucesso");
              } catch {
                OToastManager.danger(
                  "Houve um problema para resolver a pendência.",
                );
              }
            }}
          >
            Marcar como resolvido
          </ButtonWithConfirmation>
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubLoan.getLoanDetails>
  >["data"]["pendencies"][number]
>;
