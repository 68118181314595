import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  expiringPowersGrid,
} from "../../../../../cadastro/components";
import { UsuariosClienteByIdentificationRouteParams } from "../../../../routes/usuarios.route-params";

export const ExpiringPowersCompanyUser = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const {
    customer: { identification: customerIdentification },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      buildDataSource([
        ["CompanyTaxId", "=", customerIdentification],
        "and",
        ["UserTaxId", "=", identification],
      ]),
    [customerIdentification, identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Expiração de poderes" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={expiringPowersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
