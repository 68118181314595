import * as yup from "yup";

export interface InvoiceFormValues {
  invoiceNumber: string;
  amount: number;
}

export const invoiceFormValidationFormSchema = yup.object({
  invoiceNumber: yup.string().required("Este campo é obrigatório"),
  amount: yup
    .number()
    .required("Este campo é obrigatório")
    .typeError("Este campo deve conter um número.")
    .positive("Este valor não pode ser negativo.")
    .test(
      "decimal",
      "Este valor não pode conter mais que 2 dígitos decimais.",
      (value) => {
        return (value?.toString().split(".")?.[1]?.length || 0) <= 2;
      },
    ),
  invoiceProducts: yup.array().default([]),
});
