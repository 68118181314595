import { ODropdownItem, OTypography } from "@maestro/react";

export const EmptyDropdown = () => {
  return (
    <ODropdownItem>
      <OTypography type="dark" tag="span" size="md">
        Não foram encontradas itens para esta busca.
      </OTypography>
    </ODropdownItem>
  );
};
