import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { FerramentasProdutoRavenNotificationTypeByIdRouteParams } from "../../../../routes/ferramentas.route-params";
import { NotificationForm } from "../_compose/notificacoes-form";
import { useRavenNotificationDetails } from "./details.hook";

export const RavenNotificationDetails = () => {
  const { uuid } =
    useParams<FerramentasProdutoRavenNotificationTypeByIdRouteParams>();

  const { submit, form, loading, typeName } = useRavenNotificationDetails(uuid);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={`Notificação: ${typeName}`}
          description="Atualize as informações desse tipo de notificação"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Atualizar
        </LoadingButton>
      }
    >
      <NotificationForm form={form} />
    </FormTemplate>
  );
};
