import { OBadge } from "@maestro/react";
import styled from "styled-components";

export const OModalDiv = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 16px 0 8px 0;
`;

export const StyledOBadge = styled(OBadge)`
  width: 30px;
  margin-top: 2px;
`;
