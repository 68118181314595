import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { ADD_CANVAS_ITEM_MODAL_ID } from "../../config-proposal-workflow.utils";
import { useAddCanvasItemModal } from "./add-canvas-item-modal.hook";
import { itemTypes } from "./add-canvas-item-modal.utils";

export const AddCanvasItemModal = () => {
  const { form, loading, closeModal, submit } = useAddCanvasItemModal();

  return (
    <OModal
      id={ADD_CANVAS_ITEM_MODAL_ID}
      position="center"
      size="md"
      onModalClose={closeModal}
    >
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar configuração de grupo
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput
                id="value"
                name="value"
                tag="text"
                label="Valor"
                size="md"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldSelect id="type" name="type" label="Tipo">
                {itemTypes.map(({ value, label }) => (
                  <OOption key={value} value={value}>
                    {label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton type="dark" outline onClick={closeModal}>
            Fechar
          </OButton>
          <OButton type="tertiary" disabled={loading} onClick={submit}>
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
