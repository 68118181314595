import { EmbeddedContainer, EmbeddedPortal } from "components/portal-embeded";
import { useCustomer } from "contexts/customer";
import { useParams } from "react-router-dom";
import { CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams } from "../../../../routes/cadastro.route-params";

export const GuarantorEmbedded = () => {
  const { guarantorUuid } =
    useParams<CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams>();
  if (!guarantorUuid)
    throw new Error("No guarantor workflow uuid in route parameters");

  const { customer } = useCustomer();

  const route = `/configuracoes/responsaveis-pelo-credito/${guarantorUuid}`;
  const headers = {
    "x-identification": customer.identification,
    "x-uuid": customer.uuid,
  };

  return (
    <EmbeddedContainer>
      <EmbeddedPortal route={route} headers={headers} />
    </EmbeddedContainer>
  );
};
