import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";
import { ReceivableSuggestion } from "./quote-suggest.types";

export const quoteSuggestGrid: ODataGridGeneratorConfig<ReceivableSuggestion> =
  {
    datagrid: {
      noDataText: "Nenhum recebível.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "id",
        dataType: "string",
        caption: "ID do recebível",
        allowHeaderFiltering: false,
        visible: false,
      },
      {
        dataField: "sacado",
        dataType: "string",
        caption: "Sacado",
        allowHeaderFiltering: false,
      },
      {
        dataField: "sacadoIdentification",
        dataType: "string",
        caption: "CNPJ do Sacado",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "cedente",
        dataType: "string",
        caption: "Cedente",
        allowHeaderFiltering: false,
      },
      {
        dataField: "cedenteIdentification",
        dataType: "string",
        caption: "CNPJ do Cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        dataField: "bandeira",
        dataType: "string",
        caption: "Bandeira",
        alignment: "center",
        cellRender: ({ data }) =>
          getValueFromMap(cardIconMap, data.bandeira) || data.bandeira,
      },
      {
        dataField: "receivableStatus",
        dataType: "string",
        caption: "Status",
        visible: false,
      },
      {
        dataField: "maturityDate",
        caption: "Data de Vencimento",
        dataType: "date",
        format: "shortDate",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "maturityAmount",
        dataType: "number",
        caption: "Valor Bruto no Vencimento",
        format: (value) => masks.currency(value),
        allowHeaderFiltering: false,
        visible: false,
      },
      {
        dataField: "valorDesembolso",
        dataType: "number",
        caption: "Valor Líquido no Vencimento",
        format: (value) => masks.currency(value),
        allowHeaderFiltering: false,
        visible: false,
      },
      {
        dataField: "suggestedMaturityAmount",
        dataType: "number",
        caption: "Valor Bruto Sugerido",
        format: (value) => masks.currency(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "suggestedDisbursementAmount",
        dataType: "number",
        caption: "Valor Líquido Sugerido",
        format: (value) => masks.currency(value),
        allowHeaderFiltering: false,
      },
      {
        dataField: "taxaTotal",
        dataType: "number",
        caption: "Taxa Total",
        format: { type: "percent", precision: 4 },
        allowHeaderFiltering: false,
      },
      {
        dataField: "taxaPeriodo",
        dataType: "number",
        caption: "Taxa no Período",
        format: { type: "percent", precision: 4 },
        allowHeaderFiltering: false,
      },
      {
        name: "term",
        dataField: "maturityDate",
        dataType: "number",
        caption: "Prazo (dias)",
        calculateCellValue: ({ maturityDate }) =>
          dayjs(maturityDate).diff(dayjs().startOf("day"), "day"),
        allowHeaderFiltering: false,
      },
    ],
  };
