import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useNewAccountAssignor } from "./assignor-new-account.hook";

export const NewAccountAssignor = () => {
  const { form, submit, loading, watchTaxId } = useNewAccountAssignor();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Pré-cadastro de clientes"
          description="Adicione o cliente do portal BTG Pactual Empresas"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="nova-conta-fornecedor:botao:criar"
          dataLabel="criar"
          onClick={submit}
        >
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} sm={6}>
                <ORFieldInput
                  tag="cnpj"
                  id="taxId"
                  name="taxId"
                  dataAction="nova-conta-fornecedor:input:cnpj-cpf"
                  dataLabel="cnpj-cpf"
                  label="CNPJ / CPF"
                  placeholder="Informar"
                  size="md"
                />
              </OCol>
            </ORow>

            {watchTaxId && (
              <>
                <ORow>
                  <OCol xs={12} sm={6}>
                    <ORFieldInput
                      tag="text"
                      id="officialName"
                      name="officialName"
                      dataAction="nova-conta-fornecedor:input:nome"
                      dataLabel="nome"
                      label="Nome"
                      size="md"
                      disabled
                    />
                  </OCol>
                  <OCol xs={12} sm={6}>
                    <ORFieldInput
                      tag="text"
                      id="statusRFB"
                      name="statusRFB"
                      dataAction="nova-conta-fornecedor:input:situacao-ativa-receita-federal"
                      dataLabel="situacao-ativa-receita-federal"
                      label="Situacao Ativa - Receita Federal"
                      size="md"
                      disabled
                    />
                  </OCol>
                </ORow>
                <ORow>
                  <OCol xs={12} sm={6}>
                    <ORFieldInput
                      tag="text"
                      id="relationshipType"
                      name="relationshipType"
                      dataAction="nova-conta-fornecedor:input:relacionamento"
                      dataLabel="relacionamento"
                      label="Relacionamento"
                      size="md"
                      disabled
                    />
                  </OCol>
                  <OCol xs={12} sm={6}>
                    <ORFieldInput
                      tag="text"
                      id="product"
                      name="product"
                      dataAction="nova-conta-fornecedor:input:produtos"
                      dataLabel="produtos"
                      label="Produtos"
                      size="md"
                      disabled
                    />
                  </OCol>
                </ORow>
              </>
            )}
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
