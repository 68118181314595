import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  commonDomainFormDefaultValues,
  commonDomainFormValidationSchema,
} from "../common-domain-form.schemas";
import { CommonDomainForm } from "../common-domain-form.component";
import {
  WorkflowProdutoCommonDomainById,
  WorkflowProdutoCommonDomainRouteQueryParms,
} from "pages/workflow/routes/workflow.route-params";

export const EditCommonDomainPage = () => {
  const { id } = useParams<WorkflowProdutoCommonDomainById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoCommonDomainRouteQueryParms>(
      "data",
    );
  const form = useForm({
    resolver: yupResolver(commonDomainFormValidationSchema),
    defaultValues: commonDomainFormDefaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    value && reset(value);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate pageTitle={<PageTitle title="Editar Common Domain" />}>
          <FormProvider {...form}>
            <CommonDomainForm mode="edit" id={id} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};
