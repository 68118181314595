import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { SelectSearchOption } from "../../select-search/select-search.types";
import { SelectSearchMultipleOption } from "./select-search-multiple.types";

export const useSelectSearchMultiple = <T>(
  name: string,
  originalOptions: SelectSearchMultipleOption<T>[],
) => {
  const form = useFormContext();

  const { clearErrors, getValues, setError, setValue } = form;

  const removeValue = useCallback(
    (valueToRemove: string | number) => {
      setValue(
        name,
        (getValues(name) ?? []).filter(
          (value: string | number) => value !== valueToRemove,
        ),
        {
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    },
    [getValues, name, setValue],
  );

  const options: SelectSearchOption<T>[] = useMemo(
    () =>
      originalOptions.map((option) => ({
        value: option.value,
        label: option.label,
        customKey: option.customKey,
        onSelect: () => {
          const values = getValues(name) ?? [];

          if (values.includes(option.value)) {
            setError(name, {
              message: `O valor "${option.label}" já foi adicionado`,
            });
          } else {
            clearErrors(name);
            setValue(name, [...values, option.value], {
              shouldDirty: true,
              shouldTouch: true,
            });
          }

          option.onSelect?.(option.value);
        },
      })),
    [clearErrors, getValues, name, originalOptions, setError, setValue],
  );

  return {
    form,
    options,
    removeValue,
  };
};
