import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const DeskDocumentConfigRelationshipForm = () => {
  const {
    callService: getDocumentConfigs,
    loading: loadingDocumentConfigs,
    value: documentConfigs,
    hasError: hasErrorDocumentConfigs,
  } = useServiceCall(service.onboarding.getDeskOnboardingDocumentConfig);
  const {
    callService: getDesksOnboardingConfigs,
    loading: loadingDesksConfigs,
    value: desksConfigs,
    hasError: hasErrorDesksConfigs,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigs);

  useEffect(() => {
    getDocumentConfigs();
    getDesksOnboardingConfigs();
  }, [getDocumentConfigs, getDesksOnboardingConfigs]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskOnboardingDocumentConfigUuid"
                name="deskOnboardingDocumentConfigUuid"
                label="Documento"
                placeholder="Selecione"
                disabled={loadingDocumentConfigs}
                key={documentConfigs?.length}
              >
                {documentConfigs?.map((document) => (
                  <OOption key={document.uuid} value={document.uuid}>
                    {`${document.id} - ${document.type}`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDocumentConfigs && (
                <OLoader className="d-block" size="sm" />
              )}
              {hasErrorDocumentConfigs && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDocumentConfigs()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskOnboardingConfigIdentifier"
                name="deskOnboardingConfigIdentifier"
                label="Configuração"
                placeholder="Selecione"
                disabled={loadingDesksConfigs}
                key={desksConfigs?.length}
              >
                {desksConfigs?.map((config) => (
                  <OOption
                    key={config.configIdentifier}
                    value={config.configIdentifier}
                  >
                    {`${config.configIdentifier} - ${config.configName} (${config.configDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDesksConfigs && <OLoader className="d-block" size="sm" />}
              {hasErrorDesksConfigs && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingConfigs()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Complementor"
                tag="text"
                id="complementor"
                name="complementor"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
