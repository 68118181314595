import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PreticketDocument } from "services/hubfx/models/responses";
import { exchangePreTicketDocumentFilesGrid } from "./pre-ticket-document-files.grid";
import { exchangePreTicketGenericFieldsGrid } from "./pre-ticket-generic-fields.grid";

interface ExchangePreTicketDocumentDetailsProps {
  preTicketDocument: PreticketDocument;
}

export const ExchangePreTicketDocumentDetails = ({
  preTicketDocument,
}: ExchangePreTicketDocumentDetailsProps) => {
  return (
    <div className="d-flex flex-column gap-2">
      {!!preTicketDocument.genericFields?.length && (
        <OCard>
          <OCardHeader>
            <OTypography weight="bold">
              Dados adicionais do documento
            </OTypography>
          </OCardHeader>
          <OCardBody>
            <ODataGridGenerator
              grid={exchangePreTicketGenericFieldsGrid}
              dataSource={preTicketDocument.genericFields}
            />
          </OCardBody>
        </OCard>
      )}

      {!!preTicketDocument.files?.length && (
        <OCard>
          <OCardHeader>
            <OTypography weight="bold">Arquivos do documento</OTypography>
          </OCardHeader>
          <OCardBody>
            <ODataGridGenerator
              grid={exchangePreTicketDocumentFilesGrid}
              dataSource={preTicketDocument.files}
            />
          </OCardBody>
        </OCard>
      )}
    </div>
  );
};
