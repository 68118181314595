import { masks } from "@maestro/utils";
import { CopyToClipboard } from "components/copy-to-clipboard";
import * as PaymentsEnums from "services/api/models/types/payments/enums";
import { AccountUtils, DetailItem, MaskUtils } from "../_compose";

export const loadBankslipDetails = (
  data: ApiPayments.BankslipDetails,
): DetailItem[] => {
  const bankslipItem = [
    {
      title: "Favorecido",
      content: data.payee.socialName,
    },
    {
      title: "CPF/CNPJ",
      content: masks.cpfCnpj(data.payee.taxId),
    },
  ];

  return bankslipItem;
};

export const loadPixKeyDetails = (
  data: ApiPayments.PixKeyDetails,
  formatedFinancialInstitutionName: string,
): DetailItem[] => {
  const isTaxIdKey = data.key.type === PaymentsEnums.PixKeyType.TaxId;
  const isPhoneKey = data.key.type === PaymentsEnums.PixKeyType.Phone;

  const pixKeyFormated = isTaxIdKey
    ? MaskUtils.maskAndFormatTaxIdText(data.key.value)
    : isPhoneKey
    ? masks.phone(data.key.value)
    : data.key.value;

  const pixKeyItems = [
    {
      title: "Favorecido",
      content: data.creditParty?.name,
    },
    {
      title: "Banco",
      content: formatedFinancialInstitutionName,
    },
    {
      title: "Tipo",
      content: data.key.type,
    },
    {
      title: "Chave",
      content: CopyToClipboard({
        text: pixKeyFormated ?? "",
        textToCopy: data.key.value,
        textColSize: 11,
        successText: "Chave copiada com sucesso!",
      }),
    },
  ];

  return pixKeyItems;
};

export const loadPixQrCodeDetails = (
  data: ApiPayments.PixQrCodeDetails,
  formatedFinancialInstitutionName: string,
): DetailItem[] => {
  const hasLocation = !!data.location;

  const pixQrCodeItems: DetailItem[] = [
    {
      title: "Favorecido",
      content: data.creditParty?.name,
    },
    {
      title: "Banco",
      content: formatedFinancialInstitutionName,
    },
    {
      title: "Tipo",
      content: data.key.type,
    },
    {
      title: "Chave",
      content: data.key.value,
    },
  ];

  if (hasLocation)
    pixQrCodeItems.push({
      title: "Localização",
      content: data.location,
    });

  return pixQrCodeItems;
};

export const loadBankingDetails = (
  data:
    | ApiPayments.PixReversalDetails
    | ApiPayments.PixManualDetails
    | ApiPayments.TedDetails
    | ApiPayments.TefDetails,
  formatedFinancialInstitutionName: string,
): DetailItem[] => {
  const bankingItems: DetailItem[] = [
    {
      title: "Favorecido",
      content: data.creditParty?.name,
    },
    {
      title: "Banco",
      content: formatedFinancialInstitutionName,
    },
    {
      title: "Agência",
      content: AccountUtils.formatAccountAgency(
        data.creditParty?.account.branch,
      ),
    },
    {
      title: "Conta",
      content: AccountUtils.formatAccountNumber(
        data.creditParty?.account.number,
      ),
    },
  ];

  return bankingItems;
};

export const loadDarfDetails = (
  data: ApiPayments.DarfDetails,
): DetailItem[] => {
  const darfItems: DetailItem[] = [
    {
      title: "Favorecido",
      content: data.taxPayer.name,
    },
    {
      title: "CPF/CNPJ",
      content: masks.cpfCnpj(data.taxPayer.id),
    },
  ];

  return darfItems;
};

export const loadUtilitiesDetails = (
  data: ApiPayments.UtilitiesDetails,
): DetailItem[] => {
  const utilitiesItems: DetailItem[] = [
    {
      title: "Favorecido",
      content: data.assignor,
    },
  ];

  return utilitiesItems;
};

export const loadCreditCardInvoicDetails = (): DetailItem[] => {
  const creditCardInvoicItems: DetailItem[] = [
    {
      title: "Favorecido",
      content: "BTG Pactual Empresas",
    },
  ];

  return creditCardInvoicItems;
};

export const MapperAccountType: Record<ApiPayments.AccountType, string> = {
  [PaymentsEnums.AccountType.CC]: "Conta corrente",
  [PaymentsEnums.AccountType.PP]: "Conta poupança",
  [PaymentsEnums.AccountType.PG]: "Conta pagamento",
  [PaymentsEnums.AccountType.CI]: "Conta investimento",
  [PaymentsEnums.AccountType.MASKED]: "Conta **********",
};
