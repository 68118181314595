import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { B2bAgroProdutoByProposalIdRouteParams } from "../../../../../../routes/b2b.route-params";
import { getCurrentPathParams } from "../../../../[...routes]/b2b-embedded.utils";
import { proposalPendenciesGrid } from "./proposal-pendencies.grid";

const TEMPLATE_PATH = "/finders/produto/monitoramento/:proposalId/pendencias";

export const ProposalPendenciesPage = () => {
  const gridRef = useGridRef();
  const location = useLocation();
  const { proposalId } = getCurrentPathParams(
    TEMPLATE_PATH,
    location.pathname,
  ) as B2bAgroProdutoByProposalIdRouteParams;

  if (!proposalId) throw new Error("No proposalId");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.b2bAgro
          .getProposalPendencies(proposalId)
          .then(({ data }) => {
            return data ?? [];
          })
          .catch(() => {
            throw new Error("Erro ao buscar documentos da proposta");
          }),
      ),
    [proposalId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pendências da proposta" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={proposalPendenciesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
