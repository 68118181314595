import { OClickableBox } from "@maestro/react";
import { masks } from "@maestro/utils";
import { useRoles } from "hooks/roles";
import { useNavigate } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetCompaniesCedenteSacadosSalesforceResponse } from "services/quickfin/models";

interface SacadoSelectorProps {
  sacado: GetCompaniesCedenteSacadosSalesforceResponse[number];
}

export const SacadoSelector = ({ sacado }: SacadoSelectorProps) => {
  const navigate = useNavigate();
  const { hasRole } = useRoles();

  return (
    <OClickableBox
      id={sacado.identification}
      value={sacado.identification}
      dataAction={`selecionar_sacado:clickable_box:sacado_${sacado.officialName}`}
      dataLabel={`sacado_${sacado.officialName}`}
      disabled={
        !hasRole(
          roles.antecipacaoAFornecedor.customer.receivablesSacadoPage.role,
        )
      }
      onClick={() => {
        if (
          !hasRole(
            roles.antecipacaoAFornecedor.customer.receivablesSacadoPage.role,
          )
        )
          return;

        const { routes } = corporateRouter;
        navigate(
          routes.antecipacaoAFornecedor.customer.receivables.sacado.path({
            sacadoId: sacado.id,
          }),
        );
      }}
    >
      {sacado.officialName} - {masks.cpfCnpj(sacado.identification)}
    </OClickableBox>
  );
};
