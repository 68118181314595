import { cases, masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { endpoints } from "services/endpoints";

export const dataSource =
  dataSourceODataStoreGenerator<HubOnboardingRelationships.Odata.CompanyPowerParametrizationView>(
    endpoints.onboardingRelationships.odata.companyPowers.parametrizationStatus,
  );

export const grid: ODataGridGeneratorConfig<HubOnboardingRelationships.Odata.CompanyPowerParametrizationView> =
  {
    datagrid: {
      filterRow: { visible: true },
      remoteOperations: true,
    },
    columns: [
      {
        dataField: "TaxId",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
      },
      { dataField: "OfficialName", caption: "Razão social" },
      { dataField: "ActName", caption: "Ato" },
      {
        dataField: "Status",
        caption: "Status",
        format: (value) => cases.title(value),
      },
      {
        dataField: "ExpirationDate",
        caption: "Data de expiração",
        dataType: "date",
        format: "shortDateShortTime",
        alignment: "center",
        sortOrder: "desc",
      },
    ],
  };
