import { OToastManager, modalManager } from "@maestro/react";
import { dataSourceODataStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useEffect, useMemo, useState } from "react";
import { endpoints } from "services/endpoints";
import { OnboardingStrategiesODataValueItem } from "services/quickfin/models";
import { service } from "services/service";
import { onboardingStrategiesGrid } from "./onboarding-strategies.grid";

export const useOnboardingStrategies = () => {
  const [strategyId, setStrategyId] = useState<number>();
  const [sacadosFilter, setSacadosFilter] = useState("");
  const [estrategiasFilter, setEstrategiasFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceODataStoreGenerator<OnboardingStrategiesODataValueItem>(
        `${endpoints.quickfin.odata.onboardingStrategiesOData.endpoint}?${sacadosFilter}&${estrategiasFilter}`,
      ),
    [estrategiasFilter, sacadosFilter],
  );

  const filterEstrategias = (filterValue: string) => {
    setEstrategiasFilter(`estrategia=${filterValue}`);
    return ["Description", "contains", ""];
  };

  const filterSacados = (filterValue: string) => {
    setSacadosFilter(`sacado=${filterValue}`);
    return ["Description", "contains", ""];
  };

  const grid = useMemo(() => {
    return onboardingStrategiesGrid(
      filterEstrategias,
      filterSacados,
      setStrategyId,
    );
  }, []);

  useEffect(() => {
    if (!strategyId) return;

    modalManager.show("delete-onboarding-modal");
  }, [strategyId]);

  const deleteOnboarding = useCallback(async () => {
    setLoading(true);
    try {
      if (!strategyId) return;

      await service.quickfin.deleteOnboardingStrategiesODataById(strategyId);
      OToastManager.success("Estratégia de onboarding deletada com sucesso!");
    } catch {
      OToastManager.danger("Falha ao deletar estratégia de onboarding");
    } finally {
      setStrategyId(undefined);
      dataSource.reload();
      modalManager.hide("delete-onboarding-modal");
      setLoading(false);
    }
  }, [dataSource, strategyId]);

  const clearFilter = () => {
    gridRef.current?.instance.clearFilter();
    setEstrategiasFilter("");
    setSacadosFilter("");
  };

  return {
    clearFilter,
    dataSource,
    deleteOnboarding,
    grid,
    gridRef,
    loading,
  };
};
