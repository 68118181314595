import { OConfirmationModal, OLoader, modalManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { TaxasFields, useEditTaxas } from "../../../../../components";
import { useEstrategiaTemplate } from "../_compose";
import {
  UpdateEstrategiasTaxasConfirmationModal,
  updateEstrategiasTaxasConfirmationModalId,
} from "./_compose";

const confirmationModalId = "remove-taxa-confirmation-modal";

export const EditTaxas = () => {
  const {
    estrategia,
    getCompanyEstrategia,
    hasError,
    loading: estrategiaLoading,
  } = useEstrategiaTemplate();

  const {
    form,
    isEditing,
    loading,
    remove,
    removeLoading,
    submit,
    submitLoading,
    updateLoading,
  } = useEditTaxas(estrategia);

  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle
            title={`Editar taxas${estrategia?.uniqueId?.replace(/^/, " - ") ?? ""}`}
          />
        }
        actions={
          <>
            <LoadingButton
              dataAction="acoes:botao:excluir"
              dataLabel="excluir"
              loading={removeLoading}
              onClick={() => modalManager.show(confirmationModalId)}
              disabled={!isEditing}
              type="danger"
            >
              Excluir
            </LoadingButton>
            <LoadingButton
              dataAction="acoes:botao:salvar"
              dataLabel="salvar"
              loading={submitLoading || updateLoading}
              onClick={() =>
                modalManager.show(updateEstrategiasTaxasConfirmationModalId)
              }
            >
              Salvar
            </LoadingButton>
          </>
        }
      >
        <CardTemplate>
          <ContentTemplate
            loading={estrategiaLoading}
            hasError={hasError}
            value={estrategia}
            errorComponent={
              <DefaultErrorComponent
                title="Não foi possível encontrar a estratégia"
                callback={getCompanyEstrategia}
              />
            }
            render={() => (
              <FormProvider {...form}>
                {loading && <OLoader absolute backdrop />}
                <TaxasFields isEditing={isEditing} />
              </FormProvider>
            )}
          />
        </CardTemplate>
      </FormTemplate>
      <UpdateEstrategiasTaxasConfirmationModal submit={submit} />
      <OConfirmationModal
        modalId={confirmationModalId}
        title="Certeza de deseja excluir a taxa?"
        confirmLabel="Sim"
        closeLabel="Cancelar"
        onConfirm={() => {
          remove();
          modalManager.hide(confirmationModalId);
        }}
      />
    </>
  );
};
