import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { IconButton } from "components/icon-button";
import { OTypography, OTooltip, modalManager, OLoader } from "@maestro/react";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUser } from "contexts/user";
import { useDisverbationApprovals } from "./disverbation-approvals.hook";
import { CustomPagination } from "./_compose/custom-pagination";
import { disverbationApprovalConfirmationModalId } from "./_compose/modal-aprovacoes/disverbation-approvals-modal.utils";
import { FundsApprovalsModal } from "./_compose/modal-aprovacoes";
import { DesverbationSearch } from "./_compose/search/search.component";
import {
  DisverbationApprovalsFormValues,
  disverbationFormDefaulValues,
  StatusDisverbation,
} from "./disverbation-approvals.utils";

export const DisverbationApprovals = () => {
  const {
    grid,
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    selectedDisverbations,
    sendDisverbatioFunds,
    updateStatusDisverbatios,
    fundsDisverbation,
    loading,
    selectedFunds,
    setSelectedFunds,
    setFindContractNumber,
    setFindFundName,
    setFindStatus,
  } = useDisverbationApprovals();

  const form = useForm<DisverbationApprovalsFormValues>({
    defaultValues: disverbationFormDefaulValues,
  });
  const { handleSubmit } = form;
  const { user } = useUser();
  const filter = useMemo(
    () =>
      handleSubmit((values) => {
        setFindFundName(values.findFundName);
        setFindStatus(values.findStatus);
        return setFindContractNumber(values.findContractNumber);
      }),
    [handleSubmit, setFindContractNumber, setFindFundName, setFindStatus],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Aprovações de Desaverbação"
          description="Visualize todos as parcelas pendentes de aprovação"
        />
      }
      gridRef={gridRef}
      actions={
        <>
          <IconButton
            type="success"
            dataAction="acoes:botao:aprovar_fundos"
            dataLabel="aprovar_fundos"
            icon={{ category: "far", icon: "fa-check" }}
            onClick={() =>
              modalManager.show(disverbationApprovalConfirmationModalId)
            }
          >
            Aprovação por fundo
          </IconButton>
          <OTooltip position="top" maxWidth="400px" floating>
            <div slot="tooltip-content">
              <OTypography>
                Selecione as parcelas que deseja rejeitar.
              </OTypography>
            </div>
            <IconButton
              dataAction="acoes:botao:rejeitar_parcelas"
              dataLabel="rejeitar_parcelas"
              type="danger"
              icon={{ category: "far", icon: "fa-check-square" }}
              disabled={
                !(
                  selectedDisverbations &&
                  selectedDisverbations.length > 0 &&
                  user.roles?.includes("FIDC:Disverbation.approval")
                )
              }
              onClick={() =>
                updateStatusDisverbatios(StatusDisverbation.REJECTED)
              }
            >
              Rejeitar
            </IconButton>
          </OTooltip>
          <IconButton
            dataAction="acoes:botao:rAprovar_parcelas"
            dataLabel="Aprovar_parcelas"
            type="info"
            icon={{ category: "far", icon: "fa-check-square" }}
            disabled={
              !(
                selectedDisverbations &&
                selectedDisverbations.length > 0 &&
                user.roles?.includes("FIDC:Disverbation.approval")
              )
            }
            onClick={() =>
              updateStatusDisverbatios(StatusDisverbation.APPROVED)
            }
          >
            Aprovar
          </IconButton>
          <FormProvider {...form}>
            <DesverbationSearch
              onSubmit={filter}
              fundsDesverbation={fundsDisverbation}
              setFundsDesverbation={setFindFundName}
            />
          </FormProvider>
        </>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      {loading && <OLoader size="sm" />}
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[10, 20, 30]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
      <FundsApprovalsModal
        disverbationFunds={fundsDisverbation}
        selectedFunds={selectedFunds}
        setSelectedFunds={setSelectedFunds}
        sendDisverbatioFunds={sendDisverbatioFunds}
        loading={loading}
      />
    </GridTemplate>
  );
};
