import { DetailsCard } from "components/details-card";
import { ValidationErrorComponent } from "../../../../../../../components/validation-error-component";
import { diligenceFields } from "../../diligence.details";
import { useConsumerUnitCard } from "./consumer-unit-card.hook";

interface ConsumerUnitCardProps {
  onLoad: (
    value: HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitDetailsResponse>,
  ) => void;
}

export const ConsumerUnitCard = ({ onLoad }: ConsumerUnitCardProps) => {
  const { error, getConsumerUnitDetails, hasError, loading, value } =
    useConsumerUnitCard(onLoad);

  return (
    <DetailsCard
      loading={loading}
      hasError={hasError}
      value={value?.response}
      fields={diligenceFields}
      errorComponent={
        <ValidationErrorComponent
          error={error}
          callback={getConsumerUnitDetails}
          title="Erro ao buscar a unidade consumidora"
        />
      }
    />
  );
};
