import type { RowPreparedEvent } from "devextreme/ui/data_grid";

export const highlightRowsWithError = ({
  data,
  rowElement,
  rowType,
}: RowPreparedEvent<HubEnergy.LegacyImport.SpreadsheetDataWithValidation>) => {
  if (rowType === "data" && !!data?.errors?.length) {
    rowElement.style.setProperty(
      "background-color",
      "var(--theme-danger-light-80)",
    );
    rowElement.style.setProperty("color", "var(--theme-danger-light-inverse)");
  }
};
