import { useStepsBuilder } from "components/steps-builder";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { OToastManager } from "@maestro/core";
import { PostAdminOperationsResponse } from "services/quickfin/models";
import { ReceivableError } from "../../../components";
import { BankAccountWithTaxId } from "../../../components/generic-bank-data/generic-bank-data.types";
import {
  CardReceivableWithOverrideMaturityAmount,
  FileSearchReceivableWithOverrideMaturityAmount,
} from "./intubate-operations.types";

interface IntubateOperationsContext {
  bankAccount: BankAccountWithTaxId | undefined;
  cardReceivables: CardReceivableWithOverrideMaturityAmount[];
  createOperationsHasError: boolean;
  createOperationsLoading: boolean;
  createdOperations: PostAdminOperationsResponse | undefined;
  fileSearchReceivables: FileSearchReceivableWithOverrideMaturityAmount[];
  receivablesNotFound: ReceivableError[];
  setBankAccount: (bankAccount: BankAccountWithTaxId | undefined) => void;
  setCardReceivables: (
    receivables: CardReceivableWithOverrideMaturityAmount[],
  ) => void;
  setFileSearchReceivables: (
    receivables: FileSearchReceivableWithOverrideMaturityAmount[],
  ) => void;
  setReceivablesNotFound: (receivables: ReceivableError[]) => void;
  setShouldGroupOperations: (value: boolean) => void;
  shouldGroupOperations: boolean;
  submit: () => Promise<unknown> | void;
}

const intubateOperationsContext = createContext(
  {} as IntubateOperationsContext,
);

interface IntubateOperationsProps {
  children: React.ReactNode;
}

export const IntubateOperationsProvider = ({
  children,
}: IntubateOperationsProps) => {
  const [cardReceivables, setCardReceivables] = useState<
    CardReceivableWithOverrideMaturityAmount[]
  >([]);
  const [bankAccount, setBankAccount] = useState<BankAccountWithTaxId>();
  const [shouldGroupOperations, setShouldGroupOperations] = useState(true);

  // used only in spreadsheet upload intubate
  const [fileSearchReceivables, setFileSearchReceivables] = useState<
    FileSearchReceivableWithOverrideMaturityAmount[]
  >([]);
  // used only in spreadsheet upload intubate
  const [receivablesNotFound, setReceivablesNotFound] = useState<
    ReceivableError[]
  >([]);

  const { stepNumber } = useStepsBuilder();

  const {
    callService: createOperations,
    hasError: createOperationsHasError,
    loading: createOperationsLoading,
    value: createdOperations,
  } = useServiceCall(service.quickfin.createOperations);

  useEffect(() => {
    if (stepNumber === 0) {
      setCardReceivables([]);
      setShouldGroupOperations(true);
    }
  }, [stepNumber]);

  const submit = useCallback(() => {
    if (!bankAccount?.bank) {
      return OToastManager.danger(
        "Não foi possível realizar a operação devido à ausência do código do banco.",
      );
    }

    return createOperations({
      agency: bankAccount.branch,
      bankCode: bankAccount.bank,
      accountDigit: bankAccount.account_digit,
      accountNumber: bankAccount.account,
      accountTaxId: bankAccount.tax_id,
      shouldGroupOperations,
      receivables: cardReceivables.map(
        ({
          ReceivableId,
          ValorSolicitado,
          ValorDesembolsoSolicitado,
          DataDesembolso,
        }) => ({
          id: ReceivableId,
          overrideMaturityAmount: ValorSolicitado,
          overrideDisbursementAmount: ValorDesembolsoSolicitado,
          disbursementDate: DataDesembolso,
        }),
      ),
    });
  }, [bankAccount, cardReceivables, createOperations, shouldGroupOperations]);

  const value = useMemo(
    () => ({
      bankAccount,
      cardReceivables,
      createOperationsHasError,
      createOperationsLoading,
      createdOperations,
      fileSearchReceivables,
      receivablesNotFound,
      setBankAccount,
      setCardReceivables,
      setFileSearchReceivables,
      setReceivablesNotFound,
      setShouldGroupOperations,
      shouldGroupOperations,
      submit,
    }),
    [
      bankAccount,
      cardReceivables,
      createOperationsHasError,
      createOperationsLoading,
      createdOperations,
      fileSearchReceivables,
      receivablesNotFound,
      shouldGroupOperations,
      submit,
    ],
  );

  return (
    <intubateOperationsContext.Provider value={value}>
      {children}
    </intubateOperationsContext.Provider>
  );
};

export const useIntubateOperations = () =>
  useContext(intubateOperationsContext);
