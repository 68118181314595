import dayjs from "dayjs";
import { parseExcelDate } from "utils/parse-excel-date";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";

export const parseSpreadsheet = (buffer: FileReader["result"]) => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row: any) => ({
    diasCorridos: row["Dias Corridos"],

    dataBase: dayjs(parseExcelDate(row["Data Base"])).toISOString(),

    dataFutura: dayjs(parseExcelDate(row["Data Futura"])).toISOString(),

    taxa: row.Curva,
  }));
};
