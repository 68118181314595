import { OLoader } from "@maestro/react";
import { CustomErrorComponent } from "./_compose";
import { useQuickfinProducts } from "./quickfin-products.hook";

interface QuickfinProductsProps {
  clerkProducts: string[];
}

export const QuickfinProducts = ({ clerkProducts }: QuickfinProductsProps) => {
  const { products, loading, hasError, getCompanyProducts } =
    useQuickfinProducts(clerkProducts);

  if (loading) return <OLoader size="xs" />;

  if (hasError) return <CustomErrorComponent callback={getCompanyProducts} />;

  return (
    <>
      {products?.map((product) => (
        <li key={product.type}>
          {product.type}
          {product.type === "FIDC" && !!product.funds?.length && (
            <ul>
              {product.funds?.map((fund) => (
                <li key={fund}>{fund}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </>
  );
};
