import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { logger } from "utils/logger";
import { useServiceCall } from "hooks/service-call";
import { PostTimePenaltyBody } from "services/gatekeeper/models";
import { OToastManager } from "@maestro/core";
import { UsuariosProdutoByIdentificationRouteParams } from "../../../../../../routes/usuarios.route-params";

export const useTimePenalty = () => {
  const { identification } =
    useParams<UsuariosProdutoByIdentificationRouteParams>();
  const [timePenalty, setTimePenalty] = useState<Date>();
  const { loading, callService: removeTimePenaltyService } = useServiceCall(
    service.gatekeeper.removeTimePenalty,
  );

  if (!identification) {
    throw new Error("No identification");
  }

  const getUserTimePenalty = useCallback(async () => {
    try {
      const { data } = await service.gatekeeper.getTimePenaltyStatus({
        taxId: identification,
      });
      setTimePenalty(data.time_penalty);
    } catch (err) {
      logger.error(err);
    }
  }, [identification]);

  const removeTimePenalty = useCallback(async () => {
    const body: PostTimePenaltyBody = {
      taxId: identification,
    };

    const { success } = await removeTimePenaltyService(body);

    if (success) {
      OToastManager.success("Penalidade de tempo removida!");
      getUserTimePenalty();
      return;
    }

    OToastManager.danger("Tivemos algum problema ao remover a penalidade!");
  }, [getUserTimePenalty, identification, removeTimePenaltyService]);

  useEffect(() => {
    getUserTimePenalty();
  }, [getUserTimePenalty]);

  return {
    loading,
    timePenalty,
    removeTimePenalty,
  };
};
