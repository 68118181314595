import { ODropdownList } from "@maestro/react";
import { PageSizesProps } from "./page-sizes.types";
import { PageSizeDropdownItem } from "./page-size-dropdown-item.style";

const defaultPageSizes = [5, 10, 20];

export const PaginationSizes = ({
  pageSize,
  setPageSize,
  pageSizes = defaultPageSizes,
  handlePageSize = () => {},
}: PageSizesProps) => {
  return (
    <ODropdownList minWidth="70px">
      {pageSizes.map((size) => {
        const active = size === pageSize;
        return (
          <PageSizeDropdownItem
            active={active}
            key={size}
            onClick={() => {
              setPageSize(size);
              handlePageSize(size);
            }}
          >
            {size}
          </PageSizeDropdownItem>
        );
      })}
    </ODropdownList>
  );
};
