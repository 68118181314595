import { cases, masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  biometryStatusesMap,
  guarantorTypesMap,
  maritalStatusesMap,
  portalStatusesMap,
  weddingRegimesMap,
} from "../guarantors.utils";

export const buildGuarantorDetailsFields = (
  guarantor: HubOnboardingRelationships.Guarantor.Guarantor,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Nome",
        value: cases.title(guarantor.name ?? ""),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF",
        value: masks.cpf(guarantor.taxId),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Tipo",
        value: getValueFromMap(guarantorTypesMap, guarantor.type),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Status",
        value: getValueFromMap(portalStatusesMap, guarantor.portalStatus),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Biometria",
        value: getValueFromMap(
          biometryStatusesMap,
          guarantor.biometryStatusType,
        ),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  contact: {
    title: "Contato",
    items: [
      {
        label: "Telefone",
        value: masks.phone(guarantor.phoneNumber),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "E-mail",
        value: guarantor.email,
        grid: { md: 4, xl: 6 },
      },
    ],
  },
  relationship: {
    title: "Situação conjugal",
    visible: guarantor?.type === "COMPLETO",
    items: [
      {
        label: "Status matrimonial",
        value: getValueFromMap(maritalStatusesMap, guarantor.maritalStatusType),
        grid: { md: 12, xl: 6 },
      },
      {
        label: "Regime de bens",
        value: getValueFromMap(weddingRegimesMap, guarantor.weddingRegimeType),
        grid: { md: 12, xl: 6 },
      },
      {
        label: "Nome do cônjuge",
        value: guarantor.legalEntityRelationship?.name,
        visible: !!guarantor.legalEntityRelationship,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF do cônjuge",
        value: masks.cpf(guarantor.legalEntityRelationship?.taxId),
        visible: !!guarantor.legalEntityRelationship,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Telefone do cônjuge",
        value: masks.phone(guarantor.legalEntityRelationship?.phoneNumber),
        visible: !!guarantor.legalEntityRelationship,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Email do cônjuge",
        value: guarantor.legalEntityRelationship?.email,
        visible: !!guarantor.legalEntityRelationship,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  address: {
    title: "Endereço",
    visible: guarantor.addresses.length > 0,
    items: [
      {
        label: "CEP",
        value: masks.cep(guarantor.addresses[0]?.zipCode),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Rua",
        value: guarantor.addresses[0]?.streetName,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Número",
        value: guarantor.addresses[0]?.number,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Complemento",
        value: guarantor.addresses[0]?.complement,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Bairro",
        value: guarantor.addresses[0]?.neighborhood,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Estado",
        value: guarantor.addresses[0]?.stateName,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Cidade",
        value: guarantor.addresses[0]?.cityName,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
});
