import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useGatekeeperSystemsDetails } from "./details.hook";

export const GatekeeperSystemsDetails = () => {
  const { value: name } = useParseFromSearchParams<string>("name");
  const { submit, form, loading, profiles } = useGatekeeperSystemsDetails(name);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Sistema Gatekeeper"
          description="Visualize e edite os dados do sistema selecionado"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="detalhe_sistema:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader size="sm" />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="name"
                  name="name"
                  dataAction="sistema:input:nome"
                  dataLabel="nome"
                  label="Nome"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="webhook"
                  name="webhook"
                  dataAction="sistema:input:webhook"
                  dataLabel="webhook"
                  label="Webhook"
                  disabled
                />
              </OCol>
            </ORow>

            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="hmac"
                  name="hmac"
                  dataAction="sistema:input:hmac"
                  dataLabel="hmac"
                  label="HMAC"
                  disabled
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="id"
                  name="id"
                  dataAction="sistema:input:id"
                  dataLabel="id"
                  label="Id"
                  disabled
                />
              </OCol>
            </ORow>

            <ORow>
              <OCol xs={12} md={8}>
                <ORFieldSelectMultiple
                  dataAction="sistema:select:profiles"
                  dataLabel="profiles"
                  id="profiles"
                  name="profiles"
                  label="Profiles"
                  disabled={loading}
                  key={profiles.length}
                >
                  {profiles.map((profile) => (
                    <OOptionMultiple key={profile} value={profile}>
                      {profile}
                    </OOptionMultiple>
                  ))}
                </ORFieldSelectMultiple>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
