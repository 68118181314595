import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoScoreBenefitById } from "../../../../../../routes/workflow.route-params";
import { ScoreBenefitForm } from "../../_compose/score-benefit-form.component";
import {
  scoreBenefitFormDefaultValues,
  scoreBenefitFormValidationSchema,
} from "../../_compose/score-benefit-form.schema";

export const EditScoreBenefitPage = () => {
  const { scoreBenefitId } = useParams<WorkflowProdutoScoreBenefitById>();

  if (!scoreBenefitId) throw new Error("No id");

  const form = useForm({
    resolver: yupResolver(scoreBenefitFormValidationSchema),
    defaultValues: scoreBenefitFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Editar benefício" />}>
      <FormProvider {...form}>
        <ScoreBenefitForm mode="edit" id={scoreBenefitId} />
      </FormProvider>
    </FormTemplate>
  );
};
