import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { modalManager, OToastManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { ODataRequest } from "services/service.types";
import { useState } from "react";
import { groupedAgendasGrid } from "./grouped-agendas.grid";
import {
  SearchModal,
  searchModalId,
} from "./search-modal/search-modal.component";
import { GroupedAgenda } from "./grouped-agendas.types";
import {
  GroupedValuesForm,
  searchModalDefaulValues,
} from "./search-modal/search-modal.form";

export const GroupedAgendas = () => {
  const [params, setParams] = useState<GroupedValuesForm>(
    searchModalDefaulValues,
  );
  const dataSource = dataSourceCustomStoreGenerator<GroupedAgenda>(
    (loadOptions) =>
      service.recebiveisCartao
        .getDailySummary({ ...(loadOptions as ODataRequest), ...params })
        .then(({ data }) => ({
          data: data.data ?? [],
        }))
        .catch(() => {
          const errorMessage = "Erro ao buscar as agendas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
  );

  const gridRef = useGridRef();
  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Valores agrupados por cedente/sacado" />}
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
        showExportButton
        actions={
          <IconButton
            icon={{ category: "far", icon: "fa-search" }}
            onClick={() => modalManager.show(searchModalId)}
          >
            Abrir busca
          </IconButton>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={groupedAgendasGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <SearchModal setParams={setParams} />
    </>
  );
};
