import {
  modalManager,
  OButton,
  OIcon,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useCallback, useMemo, useState } from "react";
import { AssociatedCondition } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useWorkflow } from "../../hooks/use-workflow.context";
import { selectConditionsGrid } from "./select-conditions.grid";
import { getRemoveConditionInfo } from "./select-conditions.utils";

export const SelectConditionsComponent = () => {
  const {
    setSelectedConditions,
    selectedConditions,
    setGridCondition,
    conditionDestination,
  } = useWorkflow();

  const [loading, setLoading] = useState(false);
  const { show } = modalManager;

  const removeCondition = useCallback(
    async (associatedCondition: AssociatedCondition) => {
      try {
        setLoading(true);
        const removeInfo = getRemoveConditionInfo(
          conditionDestination,
          associatedCondition,
        );

        await removeInfo.service();

        setSelectedConditions((state) =>
          state.filter((c) => c.id !== associatedCondition.id),
        );
        OToastManager.success("A condição foi removida com sucesso");
      } catch {
        OToastManager.danger("Não foi possível remover a condição.");
      } finally {
        setLoading(false);
      }
    },
    [conditionDestination, setSelectedConditions],
  );

  const grid = useMemo(
    () =>
      selectConditionsGrid(conditionDestination, (data) => (
        <div className="d-flex gap-2 justify-content-center">
          <OButton
            size="sm"
            type="secondary"
            dataAction="selecionar_condicao:botao:testar"
            dataLabel="testar"
            onClick={() => {
              setGridCondition(data);
              show("test-condition-modal");
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <OIcon category="fal" icon="fa-code" size="sm" />
              <OTypography size="sm">Testar</OTypography>
            </div>
          </OButton>
          <LoadingButton
            loading={loading}
            size="sm"
            type="secondary"
            outline
            dataAction="selecionar_condicao:botao:remover"
            dataLabel="remover"
            onClick={() => removeCondition(data)}
          >
            <div className="d-flex gap-1 align-items-center">
              <OIcon category="orq" icon="orq-edit-trash" size="md" />
              <OTypography size="sm">Remover</OTypography>
            </div>
          </LoadingButton>
        </div>
      )),
    [conditionDestination, removeCondition, setGridCondition, show, loading],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between align-items-center">
        <OTypography tag="h3" weight="normal" size="lg">
          Condições
        </OTypography>
        <OButton
          dataAction="selecionar_condicao:botao:adicionar"
          dataLabel="adicionar"
          type="primary"
          onClick={() => {
            show("select-condition-modal");
          }}
        >
          Adicionar
        </OButton>
      </div>
      <ODataGridGenerator grid={grid} dataSource={selectedConditions} />
    </div>
  );
};
