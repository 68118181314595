import {
  OModal,
  OModalBody,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { CancelingReceivablesForm } from "../canceling.types";
import { cancelingFilterModalId } from "../canceling.utils";
import {
  CancelingDateRange,
  CancelingDrawnAndTransferor,
  CancelingIdsInput,
} from "../form-compose";

interface CancelingModalFilterProps {
  form: UseFormReturn<CancelingReceivablesForm>;
  loading: boolean;
  onSubmit: () => void;
  resetForm: () => void;
}

export const CancelingFilterModal = ({
  form,
  loading,
  onSubmit,
  resetForm,
}: CancelingModalFilterProps) => {
  return (
    <OModal id={cancelingFilterModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormTemplate
          actions={
            <>
              <IconButton
                icon={{ category: "orq", icon: "orq-tools-search" }}
                loading={loading}
                dataAction="cancelamento_recebiveis:botao:pesquisar"
                dataLabel="pesquisar"
                onClick={() => onSubmit()}
              >
                Pesquisar
              </IconButton>
              <IconButton
                icon={{ category: "far", icon: "fa-filter" }}
                loading={loading}
                dataAction="cancelamento_recebiveis:botao:limpar_filtro"
                dataLabel="limpar_filtro"
                onClick={() => resetForm()}
              >
                Limpar filtro
              </IconButton>
            </>
          }
        >
          <FormProvider {...form}>
            <ORow>
              <CancelingDrawnAndTransferor />
              <CancelingIdsInput />
              <CancelingDateRange />
            </ORow>
          </FormProvider>
        </FormTemplate>
      </OModalBody>
    </OModal>
  );
};
