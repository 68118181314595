import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { GetAmbevNotificationsResponse } from "services/fidc/models/responses";

const grid: ODataGridGeneratorConfig<
  GetAmbevNotificationsResponse[number]["signersInfos"][number]
> = {
  datagrid: {
    noDataText: "Nenhum assinante",
    pager: {
      infoText: "Página {0} de {1} ({2} assinantes)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    wordWrapEnabled: true,
  },
  columns: [
    {
      dataField: "signerId",
      caption: "Signer Id",
    },
    {
      dataField: "mail",
      caption: "E-mail",
    },
  ],
};

type FidcNotificationMasterDetailProps = MasterDetailComponentProps<
  GetAmbevNotificationsResponse[number]
>;

export const FidcNotificationsMasterDetail = ({
  data: {
    data: { signersInfos },
  },
}: FidcNotificationMasterDetailProps) => {
  return <ODataGridGenerator grid={grid} dataSource={signersInfos} />;
};
