import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeConviteTemplateDeEmailByIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { OnboardingInviteTypeForm } from "../_compose";
import { useUpdateInviteType } from "./update.hook";

export const UpdateInviteTypePage = () => {
  const { id } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeConviteTemplateDeEmailByIdRouteParams>();
  if (!id) throw new Error("No id");

  const { form, submit, submitLoading } = useUpdateInviteType(id);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do tipo de convite" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <OnboardingInviteTypeForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
