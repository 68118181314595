import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeDocumentConfigsForm } from "../../../../../../components/nature/document-configs/document-configs-form.component";
import {
  ExchangeDocumentConfigsFormValues,
  exchangeDocumentConfigsFormValidationFormSchema,
} from "../../../../../../components/nature/document-configs/document-configs-form.schemas";
import { parseFxPendencyCodes } from "../../../../../../components/nature/generic-fields/generic-fields.utils";
import { CambioNaturezasByIdRouteParams } from "../../../../../../routes/cambio.route-params";

export const ExchangeProductUpdateDocumentConfig = () => {
  const { id } = useParams<CambioNaturezasByIdRouteParams>();

  if (!id) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<ExchangeDocumentConfigsFormValues>({
    resolver: yupResolver(exchangeDocumentConfigsFormValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getNatureDocumentConfigDetails,
    loading: loadingDetails,
    hasError,
    value: documentConfigDetails,
  } = useServiceCall(service.hubFx.nature.getNatureDocumentConfigDetails);
  const { callService: updateNatureDocumentConfigs, loading } = useServiceCall(
    service.hubFx.nature.updateNatureDocumentConfigs,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateNatureDocumentConfigs({
          id: Number(id),
          ...values,
          isFileAllowed: !!values.isFileAllowed.length,
        });
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success(
          "Configuração de documento atualizada com sucesso",
        );

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .documentConfigs.path,
        );
      }),
    [handleSubmit, updateNatureDocumentConfigs, id, navigate],
  );

  useEffect(() => {
    if (!id) return;

    getNatureDocumentConfigDetails(id);
  }, [getNatureDocumentConfigDetails, id]);

  useEffect(() => {
    documentConfigDetails &&
      reset({
        typeName: documentConfigDetails.typeName,
        typeDescription: documentConfigDetails.typeDescription,
        typeDescriptionDisplay: documentConfigDetails.typeDescriptionDisplay,
        isFileAllowed: documentConfigDetails.isFileAllowed ? ["true"] : [],
        genericFields: documentConfigDetails.genericFieldConfigs?.map(
          (field) => ({
            typeId: field.typeId,
            isRequired: !!field.isRequired,
          }),
        ),
        fileGenericFields:
          documentConfigDetails.documentFileConfig?.genericFieldConfigs?.map(
            (field) => ({
              typeId: field.typeId,
              isRequired: field.isRequired,
            }),
          ),
        fxPendencyCodes: parseFxPendencyCodes(
          documentConfigDetails.fxPendencyCodes,
        ),
      } as ExchangeDocumentConfigsFormValues);
  }, [documentConfigDetails, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar Configuração de Documentos" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={() => getNatureDocumentConfigDetails(id)}
          />
        }
        value={documentConfigDetails}
        render={() => (
          <FormProvider {...form}>
            <ExchangeDocumentConfigsForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
