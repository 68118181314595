import { OTooltip } from "@maestro/react";
import styled from "styled-components";

export const TooltipDisplayBlock = styled(OTooltip)`
  o-popover,
  o-floater {
    display: contents;
  }

  &:not(.hydrated) {
    display: none;
  }
`;

export const TooltipContent = styled.div`
  line-height: 1.4em;
  padding: 0.5rem 0;
  text-align: left;
`;

export const EmptyButton = styled.button`
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
`;
