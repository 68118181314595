import { OButton, OToastManager } from "@maestro/react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { additionalFeesGrid } from "./additional-fees.grid";

export const AdditionalFeesPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();
  const dataGridRef = useRef<DataGrid>(null);

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          service.hubCreditManager
            .getAdditionalFees(id)
            .then(({ data }) => {
              return data ?? [];
            })
            .catch(() => {
              throw new Error("Erro ao buscar taxas adicionais");
            }),
        {
          customStoreOptions: {
            insert: async (data) => {
              try {
                await service.hubCreditManager.createAdditionalFee({
                  limitRequestId: +id,
                  amount: data.amount,
                  type: data.type,
                  isFinanced: data.isFinanced,
                  isUserChoice: data.isUserChoice,
                });

                OToastManager.success("Taxa adicional criada com sucesso");

                return data;
              } catch {
                OToastManager.danger("Houve um erro. Tente novamente.");
                throw new Error("Houve um erro. Tente novamente.");
              }
            },
            update: async (original, updated) => {
              try {
                const data: typeof updated = { ...original, ...updated };
                await service.hubCreditManager.updateAdditionalFee({
                  additionalFeeId: data.id,
                  amount: data.amount,
                  type: data.type,
                  isFinanced: data.isFinanced,
                  isUserChoice: data.isUserChoice,
                });

                OToastManager.success("Taxa adicional editada com sucesso");
              } catch {
                OToastManager.danger("Houve um erro. Tente novamente.");
              }
            },
            remove: async (data) => {
              try {
                await service.hubCreditManager.deleteAdditionalFee({
                  additionalFeeId: data.id,
                });
                OToastManager.success("Taxa adicional deletada com sucesso");
              } catch {
                OToastManager.danger("Houve um erro. Tente novamente.");
              }
            },
          },
        },
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Taxas Adicionais" />}
      actions={
        <>
          <OButton
            type="tertiary"
            dataAction="emprestimos_getor_limites_taxas_adicionais:botao:adicionar"
            dataLabel="adicionar"
            onClick={() => {
              dataGridRef.current?.instance.addRow();
            }}
            outline
          >
            Adicionar
          </OButton>
          <RefreshGridButton
            onClick={() => {
              !dataSource?.isLoading() && dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={additionalFeesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
