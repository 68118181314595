import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { client } from "services/client";
import { hubCreditManagerEndpoints } from "services/hubcreditmanager/hubcreditmanager.endpoints";

type View = Awaited<
  ReturnType<
    typeof service.hubCreditManager.getWorkflowConfigViewsByWorkflowConfigId
  >
>["data"][number];

export const workflowConfigViewsGrid = (id: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma visualização encontrada",
      dataSource: dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getWorkflowConfigViewsByWorkflowConfigId(id)
          .catch(() => {
            throw new Error("Não foi possível encontrar os as visualizações");
          }),
      ),
      paging: { pageSize: 10 },
    },
    columns: [
      {
        dataField: "id",
        dataType: "number",
        caption: "Id",
        sortOrder: "asc",
      },
      {
        dataField: "view.name",
        caption: "Visualização",
      },
      {
        dataField: "view.type",
        caption: "Tipo",
      },
      {
        dataField: "defaultGrid.name",
        caption: "Grid padrão",
        cellRender: ({ data }) => data.defaultGrid?.name ?? "-",
      },
      {
        dataField: "isVisible",
        dataType: "boolean",
        caption: "Visivel ?",
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-file" },
                label: "Detalhes",
                role: roles.workflow.product.configurationsEditGridPage.role,
                route:
                  corporateRouter.routes.workflow.product.workflowsConfig.details.edit.views.configure.bigNumbers.path(
                    { id, workflowConfigViewId: data.id },
                  ),
              },
              {
                icon: { category: "orq", icon: "orq-remove-circle" },
                label: "Remover",
                role: roles.workflow.product.configurationsEditGridPage.role,
                onClick: async () => {
                  try {
                    await client.delete(
                      hubCreditManagerEndpoints.admin.workflowConfigView.byId(
                        data.id,
                      ),
                    );

                    OToastManager.success(
                      "Configuração de visualização excluída com sucesso.",
                    );

                    component.refresh();
                  } catch (e) {
                    OToastManager.danger(
                      "Um erro ocorreu ao tentar excluir a configuração de visualização. Por favor, tente novamente mais tarde.",
                    );
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<View>);
