import { OButton, OToastManager, OTypography } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../routes/cadastro.route-params";
import {
  OccurrencesCard,
  OccurrencesGrid,
} from "../../../avalistas/detalhes/_compose";
import { DocumentCard } from "./documents-card/document-card.component";
import { buildWorkflowDetailsFields } from "./letter-of-attorney-details";
import { useWorkflowDetails } from "./letter-of-attorney-details.hook";
import { RepresentativeCard } from "./representatives-card/representative-card.component";

export const WorkflowDetails = () => {
  const { uuid } = useParams<CadastroClienteProcuracoesByUuidRouteParams>();
  if (!uuid)
    throw new Error("No representative workflow uuid in route parameters");

  const {
    workflow,
    loading,
    hasError,
    getRepresentativeWorkflow,
    canSend,
    canEdit,
  } = useWorkflowDetails();

  useEffect(() => {
    getRepresentativeWorkflow(uuid);
  }, [getRepresentativeWorkflow, uuid]);

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      value={workflow}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível encontrar o avalista"
          messageParagraph="Clique no botão para tentar novamente."
        >
          <TryAgainButton onClick={() => getRepresentativeWorkflow(uuid)} />
        </ErrorComponent>
      }
      emptyStateComponent={
        <EmptyState messageTitle="Sem dados para o avalista" />
      }
      render={(workflowLoa) => (
        <div className="d-flex flex-column gap-4">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <OTypography size="lg" type="default">
                Dados do Workflow
              </OTypography>
            </div>
            <DetailsCard fields={buildWorkflowDetailsFields(workflowLoa)} />
          </div>
          <div>
            <OTypography size="lg" type="default" className="mb-3">
              Ocorrências
            </OTypography>
            {workflowLoa.status === "PENDENTE_AJUSTE" ? (
              <OccurrencesGrid workflowUuid={uuid} type="LOA" />
            ) : (
              <OccurrencesCard occurrences={workflowLoa.occurrences ?? []} />
            )}
          </div>
          <div>
            <RepresentativeCard
              representatives={workflowLoa.legalRepresentatives ?? []}
              uuid={uuid}
              refetch={() => getRepresentativeWorkflow(uuid)}
              canEdit={!canEdit}
            />
          </div>
          <div>
            <OTypography size="lg" type="default" className="mb-3">
              Documentos
            </OTypography>
            <DocumentCard
              documents={workflowLoa.documents ?? []}
              refetch={() => getRepresentativeWorkflow(uuid)}
              canEdit={!canEdit}
            />
          </div>
          <OButton
            title="Enviar"
            ariaLabel="Enviar"
            disabled={!canSend}
            onClick={async () => {
              try {
                await service.onboardingRelationships.sendWorkflow({
                  uuid,
                });
                getRepresentativeWorkflow(uuid);
                OToastManager.success("Representante removido com sucesso");
              } catch {
                OToastManager.danger("Erro ao remover Representante");
              }
            }}
          >
            Enviar
          </OButton>
        </div>
      )}
    />
  );
};
