import { OIcon } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { PostByMsgTypeReprocessResponse } from "services/roadwalker/models/responses";
import { getValueFromMap } from "utils/get-value-from-map";
import { RoadwalkerMasterDetail } from "./_compose/roadwalker-master-detail";
import { RoadwalkerMessage } from "./_compose/roadwalker-master-detail/roadwalker-master-detail.types";
import { reprocessResutMap } from "./roadwalker.utils";

export const roadwalkerGrid = (
  idType: string,
  reprocessResult: PostByMsgTypeReprocessResponse | undefined,
  setSelectedMessages: (value: RoadwalkerMessage[]) => void,
  messageType: string,
): ODataGridGeneratorConfig<RoadwalkerMessage> => ({
  datagrid: {
    noDataText: "Nenhuma mensagem encontrada",
    pager: {
      infoText: "Página {0} de {1} ({2} mensagens)",
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    onSelectionChanged: (evt) => setSelectedMessages(evt.selectedRowsData),
  },
  columns: [
    {
      caption: idType,
      cellRender: ({ data }) => data[idType as keyof RoadwalkerMessage],
    },
    {
      dataField: "MessageId",
      caption: "Message id",
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      cellRender: ({ data }) =>
        dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      caption: "Reprocessamento",
      visible: Object.values(reprocessResult ?? {}).length > 0,
      cellRender: ({ data }) => {
        const parsedReprocessResult = Object.values(
          reprocessResult ?? {},
        ).flat();
        const foundResult = parsedReprocessResult.find(
          (result) => result.MessageId === data.MessageId,
        );
        const mappedResult = getValueFromMap(
          reprocessResutMap,
          foundResult?.Result,
        );
        return mappedResult ? (
          <OIcon
            type={mappedResult.type}
            category={mappedResult.category}
            icon={mappedResult.icon}
          />
        ) : null;
      },
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "page",
    showCheckBoxesMode: "always",
  },
  masterDetail: {
    enabled: true,
    component: ({ data: { data } }) => (
      <RoadwalkerMasterDetail data={data} messageType={messageType} />
    ),
  },
});
