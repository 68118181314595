import { BankIconSVG } from "../financial-institution-icon.types";

export const BankItauSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#FD7E14" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.993 26h40.014c2.795 0 5.124.975 7.029 2.881C85.026 30.831 86 33.203 86 36v40c0 2.754-.974 5.127-2.964 7.076-1.905 1.907-4.234 2.882-7.029 2.882H35.993c-2.795 0-5.166-.975-7.114-2.882C26.974 81.127 26 78.754 26 76V36c0-2.797.974-5.17 2.88-7.119C30.826 26.975 33.197 26 35.992 26z"
        fill="#039"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.816 60.025h3.26l4.023-4.068h-4.404l-2.88 4.068zm-1.186 1.017h-4.658v8.686c0 2.33.593 4.068 1.736 5.212 1.017.975 2.33 1.44 4.023 1.44 2.329 0 4.065-.931 5.123-2.711h.043v2.373h4.107v-15h-4.658v8.729c0 .38-.042.72-.127.932-.55 1.271-1.44 1.949-2.88 1.949-1.778 0-2.709-1.271-2.709-3.729v-7.881zm-11.94 4.957c-3.05 0-5.378.467-6.987 1.441-1.652.975-2.499 2.5-2.499 4.407 0 1.186.508 2.246 1.355 3.136.974.932 2.245 1.355 3.769 1.355 2.075 0 3.6-.635 4.658-1.906h.127v1.567h4.276v-8.813c0-1.992-.55-3.517-1.524-4.619-1.228-1.271-3.006-1.949-5.378-1.949-2.456 0-4.488.466-6.097 1.44l.89 2.925c1.312-.806 2.794-1.187 4.445-1.187 2.075 0 3.049.636 3.049 2.034V66h-.085zm-11.306-4.957h-2.88v-5.085h-4.488v5.085h-2.117v3.432h2.117v6.017c0 2.076.381 3.602 1.27 4.45.847.931 2.117 1.355 3.642 1.355.974 0 1.778-.127 2.498-.381l-.042-3.517c-.17.127-.424.212-.89.212-.72 0-1.27-.255-1.609-.805-.254-.382-.38-1.06-.38-2.077v-5.254h2.879v-3.432zm6.14 10.424c0-1.78 1.778-2.585 5.292-2.543v1.61l-.042.89c-.212.593-.593 1.06-1.143 1.356-.55.466-1.186.678-1.906.678-1.44 0-2.202-.678-2.202-1.991zm-22.57-15.509v20.042h4.743V55.957h-4.742z"
        fill="#FF0"
      />
    </svg>
  );
};
