import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";

export const scoreLevelsGrid = {
  datagrid: {
    noDataText: "Nenhum nível encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceCustomStoreGenerator(
      () => {
        return service.hubCreditManager.getScoreLevels().catch(() => {
          throw new Error("Erro ao buscar níveis.");
        });
      },
      {
        customStoreOptions: {
          insert: async (values) => {
            try {
              await service.hubCreditManager.createScoreLevel(values);

              OToastManager.success("Nível adicionado com sucesso.");
              return values;
            } catch {
              OToastManager.danger("Não foi possível adicionar o nível.");
              throw new Error("Não foi possível adicionar o nível.");
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      alignment: "center",
    },
    {
      dataField: "name",
      caption: "Nome",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "type",
      caption: "Tipo",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "order",
      alignment: "left",
      caption: "Posição",
      sortOrder: "asc",
      validationRules: [{ type: "required" }],
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubCreditManager.getScoreLevels>>["data"]
  >[number]
>;
