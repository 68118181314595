import * as yup from "yup";

export const scoreModuleFormValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
  actionLabel: yup.string(),
  isVisible: yup.array().default([]),
});

export const scoreModuleFormDefaultValues = {
  name: "",
  type: "",
  description: "",
  actionLabel: "",
  isVisible: [] as string[],
};
