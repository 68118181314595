import {
  ODropdownAction,
  ODropdownActionItem,
  OIcon,
  OTypography,
} from "@maestro/react";
import { useRoles } from "hooks/roles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DatagridActions } from "./datagrid-action.types";

interface DataGridActionProps {
  actions: DatagridActions[];
}

export const DataGridAction: React.FC<DataGridActionProps> = ({ actions }) => {
  const navigate = useNavigate();
  const { hasRole } = useRoles();

  const filteredActions = actions.filter(
    (action) => action.visible !== false && hasRole(action.role),
  );

  return (
    <ODropdownAction key={filteredActions?.length}>
      {filteredActions.map((action) => (
        <ODropdownActionItem
          key={action.label}
          size="xxl"
          handleClick={() => {
            if (action.onClick) {
              action.onClick();
            } else {
              navigate(action.route);
            }
          }}
          disabled={action.disabled}
        >
          <OIcon
            type="dark"
            category={action.icon.category}
            icon={action.icon.icon}
            size="lg"
          />
          <OTypography type="dark" size="md">
            {action.label}
          </OTypography>
        </ODropdownActionItem>
      ))}
    </ODropdownAction>
  );
};
