import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { DeskAccountInfoForm } from "../_compose/desk-account-info-form.component";
import {
  DeskAccountInfoFormValues,
  deskAccountInfoFormValidationSchema,
} from "../_compose/desk-account-info.form";

export const RegisterDeskAccountInfoPage = () => {
  const form = useForm<DeskAccountInfoFormValues>({
    resolver: yupResolver(deskAccountInfoFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerDeskAccountInfo, loading } = useServiceCall(
    service.onboarding.registerDeskOnboardingAccountInfo,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDeskAccountInfo(values);

        if (!success)
          return OToastManager.danger("Erro ao criar informação de conta");

        return OToastManager.success("Informação de conta criada com sucesso");
      }),
    [handleSubmit, registerDeskAccountInfo],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar informação de conta" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskAccountInfoForm />
      </FormProvider>
    </FormTemplate>
  );
};
