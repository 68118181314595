export const dateDifferenceInDays = (start: string, end: string) =>
  (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60 * 60 * 24);

export const calculateTaxes = (limit?: BankingHub.FullLimit) =>
  (limit?.charge?.totalDefaultInterest || 0) +
  (limit?.charge?.totalTax || 0) +
  (limit?.charge?.totalInterest || 0);

export const limitAnalysisStatusMap = {
  Created: "Ofertado",
  Approved: "Aprovado",
  Expired: "Expirado",
  Canceled: "Cancelado",
};
