export const defaultUserNotificationTypeData = [
  {
    label: "Nenhum",
    value: "",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP",
  },
];
