import { OCard, OCardBody, ORow, OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { useEventDetails } from "./events.hook";
import { CollapseEvent } from "./_compose";

export const EventDetails = () => {
  const { detail } = useEventDetails();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Evento" />}>
      <OCard>
        <OCardBody>
          <ORow>
            {detail?.events && detail?.events.length > 0 ? (
              detail.events.map((e) => <CollapseEvent key={e.id} event={e} />)
            ) : (
              <OTypography>Nenhum evento encontrado</OTypography>
            )}
          </ORow>
        </OCardBody>
      </OCard>
    </DetailsTemplate>
  );
};
