import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ConditionForm } from "../../../../components/conditions/condition-form/condition-form.component";
import {
  conditionFormDefaultValues,
  conditionFormValidationSchema,
} from "../../../../components/conditions/condition-form/condition-form.schemas";

export const AddConditionPage = () => {
  const form = useForm({
    resolver: yupResolver(conditionFormValidationSchema),
    defaultValues: conditionFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Criar nova condição" />}>
      <FormProvider {...form}>
        <ConditionForm mode="add" />
      </FormProvider>
    </FormTemplate>
  );
};
