/* eslint-disable no-param-reassign */
import { OIcon } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { quickfinProductNameMap } from "../../../../../../../cadastro/pages/cliente/detalhes/_compose/products-list/_compose/quickfin-products/quickfin-products.utils";
import { documentTypeMap, errorMessageMap } from "../../../../../../utils";
import { Sacado } from "../../upload-sacados.types";
import { highlightErrors } from "./validation-results.utils";

export const validationResultsGrid: ODataGridGeneratorConfig<Sacado> = {
  datagrid: {
    noDataText: "Nenhum sacado",
    pager: { showPageSizeSelector: true },
    headerFilter: { visible: true },
    filterRow: { visible: true },
    onCellPrepared: highlightErrors,
  },
  columns: [
    {
      dataField: "errorMessages",
      caption: "Situação",
      sortOrder: "asc",
      allowHeaderFiltering: false,
      calculateCellValue: ({ errorMessages }) => errorMessages?.join(" "),
      cellRender: ({ data: { errorMessages } }) => (
        <div>
          {!errorMessages?.length ? (
            <OIcon category="fa" icon="fa-check" />
          ) : (
            errorMessages.map((error) => (
              <p key={error.key + error.message}>
                {getValueFromMap(errorMessageMap, error.message) ??
                  errorMessageMap.default}
              </p>
            ))
          )}
        </div>
      ),
    },
    {
      dataField: "officialName",
      caption: "Empresa",
      allowHeaderFiltering: false,
    },
    { dataField: "convenioGUID", caption: "Estratégia" },
    {
      dataField: "identificacao",
      caption: "Identificação",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "identificadorExterno",
      caption: "Identificador externo",
      allowHeaderFiltering: false,
    },
    { dataField: "cge", caption: "CGE" },
    {
      dataField: "companyEntityType",
      caption: "Tipo",
      format: (value) =>
        getValueFromMap(documentTypeMap, value)?.label ?? value,
    },
    {
      dataField: "tipoProduto",
      caption: "Tipo de produto",
      format: (value) =>
        getValueFromMap(quickfinProductNameMap, value) ?? value,
    },
    {
      dataField: "country",
      caption: "Código do país",
    },
  ],
};
