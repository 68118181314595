import { OToastManager, modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ConfirmationModal, resendInviteModalId } from "../confirmation-modal";

interface ResendInviteButttonProps {
  identifier: string;
  reload: () => void;
}

export const ResendInviteButton = ({
  identifier,
  reload,
}: ResendInviteButttonProps) => {
  const { callService: resendMailService, loading: resendLoading } =
    useServiceCall(service.clerk.resendWorkflowNotification);

  const resendMail = useCallback(
    async (id: string) => {
      const { success } = await resendMailService({ identifier: id });
      if (success) {
        OToastManager.success("Convite enviado");
        reload();
      } else {
        OToastManager.danger("Erro ao enviar o convite");
      }
    },
    [reload, resendMailService],
  );

  return (
    <>
      <IconButton
        dataAction="antecipacao_cartao_jobs:botao:parar_selecionados"
        dataLabel="parar_selecionados"
        icon={{ category: "far", icon: "fa-mail-bulk" }}
        type="tertiary"
        disabled={!identifier}
        onClick={() => modalManager.show(resendInviteModalId)}
      >
        Reenviar Convite
      </IconButton>
      <ConfirmationModal
        id={resendInviteModalId}
        isConfirmationModalProcessing={resendLoading}
        onConfirm={async () => {
          resendMail(identifier).then(() =>
            modalManager.hide(resendInviteModalId),
          );
        }}
      />
    </>
  );
};
