import * as yup from "yup";
import { NotificationFormValuesType } from "./notification-form.types";

export const validationSchema: yup.ObjectSchema<NotificationFormValuesType> =
  yup.object({
    renderedName: yup.string().required("Este campo é obrigatório"),
    icon: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    description: yup.string().required("Este campo é obrigatório"),
    tenant: yup.string().required("Este campo é obrigatório"),
    "legalRepresentative?": yup.string(),
    roles: yup.array().of(yup.string().required()),
  });
