import * as yup from "yup";

type BasePartnerConfigurationFormField = {
  stepId: string;
  stepType: ScfDevelopers.StepType;
  nextStepId: string | null;
  isStepActive: string[];
  _t: string;
};

interface GeneralPartnerConfigurationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "GENERAL";
  isFidc: string[];
}

interface ValidatioPartnerConfigurationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "VALIDATION";
  shouldValidateAddress: string[];
  requiredDocuments: string[];
}

interface ComplementPartnerConfigurationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "COMPLEMENT";
}

interface RiskAndAnalysisPartnerConfigurationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "RISKANALYSIS";
  segment: string;
  coSegment: string;
  riskAnalysisType: string;
}

interface LessPartnerConfigurationStepFormField
  extends BasePartnerConfigurationFormField {
  stepType: "LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType?: string;
  mainOfficerCGE: number;
  mainOfficerName?: string;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface WebhookPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "WEBHOOK";
  allowedWebhookStatusTypes: string[];
}

interface WebhookInternalPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "WEBHOOK_INTERNAL";
  allowedWebhookStatusTypes: string[];
}

interface DevelopersWebhookPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "DEVELOPERS_WEBHOOK";
  additionalPartnerAuthClientIds: string[];
}

interface ClerkPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "CLERK";
}

interface QuickfinSyncPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "QUICKFINSYNC";
}

interface ValidateCreateUserRelationshipPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "VALIDATE_CREATE_USER_RELATIONSHIP";
}

interface CreateUserRelationshipPartnerIntegrationFormField
  extends BasePartnerConfigurationFormField {
  stepType: "CREATE_USER_RELATIONSHIP";
  shouldSendEmail: string[];
}

export type ConfigurationFormField =
  | GeneralPartnerConfigurationFormField
  | ValidatioPartnerConfigurationFormField
  | ComplementPartnerConfigurationFormField
  | RiskAndAnalysisPartnerConfigurationFormField
  | LessPartnerConfigurationStepFormField
  | WebhookPartnerIntegrationFormField
  | WebhookInternalPartnerIntegrationFormField
  | DevelopersWebhookPartnerIntegrationFormField
  | ClerkPartnerIntegrationFormField
  | QuickfinSyncPartnerIntegrationFormField
  | ValidateCreateUserRelationshipPartnerIntegrationFormField
  | CreateUserRelationshipPartnerIntegrationFormField;

export interface DomainScfFormFields {
  allowedReprocessStatuses: string[];
  authClientId: string;
  configurationId?: string;
  configurationName: string;
  configurationDisplayName: string;
  configurations: ConfigurationFormField[];
  desks: number[];
  integrationType: string;
  isFidc: string[];
  mainDesk: number;
  partnerId: number;
  partnerName: string;
  requiredOnboardingDocuments: ScfDevelopers.PayerDocumentType[];
  syncConfigurations: ConfigurationFormField[];
  requestRequiredFields: string[];
}

const hasWhiteSpace = (str: string) => {
  return /\s/g.test(str);
};

const hasHash = (str: string) => {
  return /#/g.test(str);
};

const hasAccent = (str: string) => {
  return /[áéíóúâêîôûãõàç]+/gi.test(str);
};

const validateStringWithoutHash = yup
  .string()
  .required("Campo obrigatório")
  .test(
    "invalidWhitespaceChar",
    "Não deve haver espaço",
    (value) => !hasWhiteSpace(value),
  )
  .test(
    "invalidHashChar",
    "Caractere inválido: '#'",
    (value) => !hasHash(value),
  )
  .test(
    "InvalidAccentChar",
    "Caractere não deve possuir acento",
    (value) => !hasAccent(value),
  );

const basePartnerConfigurationValidationSchema: yup.ObjectSchema<BasePartnerConfigurationFormField> =
  yup.object({
    stepId: yup.string().required(),
    stepType: yup.mixed<ScfDevelopers.StepType>().defined(),
    nextStepId: yup.string().required().nullable(),
    isStepActive: yup.array(yup.string().required()).default([]),
    _t: yup.string().required(),
  });

const GeneralPartnerConfigurationValidationSchema: yup.ObjectSchema<GeneralPartnerConfigurationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"GENERAL">().required(),
    isFidc: yup.array(yup.string().required()).default([]),
  });

const ValidationPartnerConfigurationValidationSchema: yup.ObjectSchema<ValidatioPartnerConfigurationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"VALIDATION">().required(),
    shouldValidateAddress: yup.array(yup.string().required()).default([]),
    requiredDocuments: yup.array(yup.string().required()).default([]),
  });

const ComplementPartnerConfigurationValidationSchema: yup.ObjectSchema<ComplementPartnerConfigurationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"COMPLEMENT">().required(),
  });

const RiskAndAnalysisPartnerConfigurationValidationSchema: yup.ObjectSchema<RiskAndAnalysisPartnerConfigurationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"RISKANALYSIS">().required(),
    segment: yup.string().required("Este campo é obrigatório"),
    coSegment: yup.string().required("Este campo é obrigatório"),
    riskAnalysisType: yup.string().required("Este campo é obrigatório"),
  });

const LessPartnerConfigurationValidationSchema: yup.ObjectSchema<LessPartnerConfigurationStepFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"LESS">().required(),
    partyGroupTypeIds: yup
      .array(yup.number().typeError("Deve ser um Id numérico").required())
      .required("Insira ao menos um Id")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const WebhookPartnerConfigurationValidationSchema: yup.ObjectSchema<WebhookPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"WEBHOOK">().required(),
    allowedWebhookStatusTypes: yup.array(yup.string().required()).default([]),
  });

const WebhookInternalPartnerConfigurationValidationSchema: yup.ObjectSchema<WebhookInternalPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"WEBHOOK_INTERNAL">().required(),
    allowedWebhookStatusTypes: yup.array(yup.string().required()).default([]),
  });

const DevelopersWebhookPartnerConfigurationValidationSchema: yup.ObjectSchema<DevelopersWebhookPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"DEVELOPERS_WEBHOOK">().required(),
    additionalPartnerAuthClientIds: yup.array().default([]),
  });

const ClerkPartnerConfigurationValidationSchema: yup.ObjectSchema<ClerkPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"CLERK">().required(),
  });

const QuickfinSyncPartnerConfigurationValidationSchema: yup.ObjectSchema<QuickfinSyncPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"QUICKFINSYNC">().required(),
  });

const ValidateCreateUserRelationshipPartnerConfigurationValidationSchema: yup.ObjectSchema<ValidateCreateUserRelationshipPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"VALIDATE_CREATE_USER_RELATIONSHIP">().required(),
  });

const CreateUserRelationshipPartnerConfigurationValidationSchema: yup.ObjectSchema<CreateUserRelationshipPartnerIntegrationFormField> =
  basePartnerConfigurationValidationSchema.shape({
    stepType: yup.string<"CREATE_USER_RELATIONSHIP">().required(),
    shouldSendEmail: yup.array(yup.string().required()).default([]),
  });

const ConfigurationSchemaMapper = yup.lazy((value: ConfigurationFormField) => {
  if (value.stepType === "GENERAL") {
    return GeneralPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "VALIDATION") {
    return ValidationPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "COMPLEMENT") {
    return ComplementPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "RISKANALYSIS") {
    return RiskAndAnalysisPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "LESS") {
    return LessPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "WEBHOOK") {
    return WebhookPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "WEBHOOK_INTERNAL") {
    return WebhookInternalPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "DEVELOPERS_WEBHOOK") {
    return DevelopersWebhookPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "CLERK") {
    return ClerkPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "QUICKFINSYNC") {
    return QuickfinSyncPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "VALIDATE_CREATE_USER_RELATIONSHIP") {
    return ValidateCreateUserRelationshipPartnerConfigurationValidationSchema;
  }
  if (value.stepType === "CREATE_USER_RELATIONSHIP") {
    return CreateUserRelationshipPartnerConfigurationValidationSchema;
  }
  return yup.mixed<ConfigurationFormField>().required();
});

const ConfigurationsPartnerConfigurationValidationSchema = yup
  .array()
  .of(ConfigurationSchemaMapper)
  .default([]);

export const DomainScfValidationSchema: yup.ObjectSchema<DomainScfFormFields> =
  yup.object({
    allowedReprocessStatuses: yup.array(yup.string().required()).default([]),
    authClientId: yup.string().required("Campo obrigatório"),
    configurationId: yup.string(),
    configurationName: validateStringWithoutHash,
    configurationDisplayName: yup.string().required("Campo obrigatório"),
    configurations: ConfigurationsPartnerConfigurationValidationSchema,
    desks: yup
      .array(
        yup.number().typeError("Mesas devem possuir ids numéricos").required(),
      )
      .default([])
      .min(1, "Deve inserir ao menos uma mesa"),
    integrationType: yup.string().required(),
    isFidc: yup.array(yup.string().required()).default([]),
    mainDesk: yup
      .number()
      .typeError("Mesa deve possuir id numérico")
      .required("Selecione uma mesa")
      .test("choosesADesk", "Deve ser uma das mesas", (value, context) => {
        return value && context.parent.desks?.includes(value);
      }),
    partnerId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("Campo obrigatório"),
    partnerName: validateStringWithoutHash,
    requiredOnboardingDocuments: yup
      .array(yup.mixed<ScfDevelopers.PayerDocumentType>().required())
      .default([]),
    requestRequiredFields: yup
      .array()
      .transform((value) => (Array.isArray(value) ? value : []))
      .optional()
      .default([]),
    syncConfigurations: ConfigurationsPartnerConfigurationValidationSchema,
  });

const GeneralPartnerConfigurationDefaultValues: GeneralPartnerConfigurationFormField =
  {
    stepId: "",
    stepType: "GENERAL",
    nextStepId: null,
    isStepActive: ["true"],
    isFidc: [],
    _t: "GeneralPartnerConfigurationStep",
  };

const ValidatioPartnerConfigurationDefaultValues: ValidatioPartnerConfigurationFormField =
  {
    stepId: "",
    stepType: "VALIDATION",
    nextStepId: null,
    isStepActive: ["true"],
    shouldValidateAddress: [],
    requiredDocuments: [],
    _t: "ValidationPartnerConfigurationStep",
  };

const ComplementPartnerConfigurationDefaultValues: ComplementPartnerConfigurationFormField =
  {
    stepId: "",
    stepType: "COMPLEMENT",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "ComplementPartnerConfigurationStep",
  };

const RiskAndAnalysisPartnerConfigurationDefaultValues: RiskAndAnalysisPartnerConfigurationFormField =
  {
    stepId: "",
    stepType: "RISKANALYSIS",
    nextStepId: null,
    isStepActive: ["true"],
    segment: "",
    coSegment: "",
    riskAnalysisType: "",
    _t: "RiskAnalysisPartnerConfigurationStep",
  };

const LessPartnerConfigurationDefaultValues: LessPartnerConfigurationStepFormField =
  {
    stepId: "",
    stepType: "LESS",
    nextStepId: null,
    isStepActive: ["true"],
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
    _t: "LessPartnerConfigurationStep",
  };

const WebhookPartnerConfigurationDefaultValues: WebhookPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "WEBHOOK",
    nextStepId: null,
    isStepActive: ["true"],
    allowedWebhookStatusTypes: [],
    _t: "WebhookPartnerConfigurationStep",
  };

const WebhookInternalPartnerConfigurationDefaultValues: WebhookInternalPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "WEBHOOK_INTERNAL",
    nextStepId: null,
    isStepActive: ["true"],
    allowedWebhookStatusTypes: [],
    _t: "WebhookInternalConfigurationStep",
  };

const DevelopersWebhookPartnerConfigurationDefaultValues: DevelopersWebhookPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "DEVELOPERS_WEBHOOK",
    nextStepId: null,
    isStepActive: ["true"],
    additionalPartnerAuthClientIds: [],
    _t: "WebhookViaDevelopersPartnerConfigurationStep",
  };

const ClerkPartnerConfigurationDefaultValues: ClerkPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkPartnerConfigurationStep",
  };

const QuickfinSyncPartnerConfigurationDefaultValues: QuickfinSyncPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "QUICKFINSYNC",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "QuickFinSyncConfigurationStep",
  };

const ValidateCreateUserPartnerConfigurationDefaultValues: ValidateCreateUserRelationshipPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "VALIDATE_CREATE_USER_RELATIONSHIP",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "ValidateCreateUserRelationshipPartnerConfigurationStep",
  };

const CreateUserPartnerConfigurationDefaultValues: CreateUserRelationshipPartnerIntegrationFormField =
  {
    stepId: "",
    stepType: "CREATE_USER_RELATIONSHIP",
    nextStepId: null,
    isStepActive: ["true"],
    shouldSendEmail: [],
    _t: "CreateUserRelationshipPartnerConfigurationStep",
  };

export const ConfigurationDefaultValue = {
  GENERAL: GeneralPartnerConfigurationDefaultValues,
  VALIDATION: ValidatioPartnerConfigurationDefaultValues,
  COMPLEMENT: ComplementPartnerConfigurationDefaultValues,
  RISKANALYSIS: RiskAndAnalysisPartnerConfigurationDefaultValues,
  LESS: LessPartnerConfigurationDefaultValues,
  WEBHOOK: WebhookPartnerConfigurationDefaultValues,
  WEBHOOK_INTERNAL: WebhookInternalPartnerConfigurationDefaultValues,
  DEVELOPERS_WEBHOOK: DevelopersWebhookPartnerConfigurationDefaultValues,
  CLERK: ClerkPartnerConfigurationDefaultValues,
  QUICKFINSYNC: QuickfinSyncPartnerConfigurationDefaultValues,
  VALIDATE_CREATE_USER_RELATIONSHIP:
    ValidateCreateUserPartnerConfigurationDefaultValues,
  CREATE_USER_RELATIONSHIP: CreateUserPartnerConfigurationDefaultValues,
};
