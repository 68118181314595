import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchMultipleOption } from "components/form";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { FerramentasProdutoUsuariosByTaxIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { getModificationsToArray } from "../../../../../utils";
import { useGatekeeperUserProfiles, useProfiles } from "../_compose";
import {
  companyUserProfilesDefaultValues,
  companyUserProfilesValidationSchema,
} from "./company-user-profiles.form";
import { CompanyUserProfilesForm } from "./company-user-profiles.types";

export const useCompanyUserProfiles = () => {
  const { taxId } = useParams<FerramentasProdutoUsuariosByTaxIdRouteParams>();
  if (!taxId) throw new Error("No taxId");

  const {
    callService: addProfilesToCompanyUserRelationship,
    loading: addLoading,
  } = useServiceCall(service.gatekeeper.addProfilesToCompanyUserRelationship);

  const {
    callService: removeProfilesFromCompanyUserRelationship,
    loading: removeLoading,
  } = useServiceCall(
    service.gatekeeper.removeProfilesFromCompanyUserRelationship,
  );

  const { allProfiles, getProfiles, getProfilesHasError, getProfilesLoading } =
    useProfiles();

  const { getUser, getUserHasError, getUserLoading, userData } =
    useGatekeeperUserProfiles(taxId);

  const form = useForm<CompanyUserProfilesForm>({
    defaultValues: companyUserProfilesDefaultValues,
    resolver: yupResolver(companyUserProfilesValidationSchema),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    watch,
  } = form;

  const companyTaxIdWatcher = watch("companyTaxId");

  const selectedCompany = useMemo(
    () =>
      userData?.companies?.find(
        (company) => company.cnpj === companyTaxIdWatcher,
      ),
    [companyTaxIdWatcher, userData?.companies],
  );

  const resetProfiles = useCallback(
    () =>
      reset({
        companyTaxId: selectedCompany?.cnpj ?? "",
        profiles: selectedCompany?.profiles ?? [],
      }),
    [reset, selectedCompany],
  );

  const submit = useMemo(
    () =>
      handleSubmit(
        async (values) => {
          if (!selectedCompany)
            throw new Error("No selected Company in submit");
          if (!userData) throw new Error("No userData in submit");
          const { added, removed } = getModificationsToArray(
            selectedCompany.profiles,
            values.profiles,
          );

          if (added.length === 0 && removed.length === 0)
            return OToastManager.info("Nenhuma modificação");

          const promises: Promise<{ success: boolean }>[] = [];

          if (added.length > 0) {
            promises.push(
              addProfilesToCompanyUserRelationship({
                identification: values.companyTaxId,
                users: [
                  {
                    email: userData.email,
                    profiles: added,
                  },
                ],
              }),
            );
          }

          if (removed.length > 0) {
            promises.push(
              removeProfilesFromCompanyUserRelationship({
                identification: values.companyTaxId,
                users: [
                  {
                    email: userData.email,
                    profiles: removed,
                  },
                ],
              }),
            );
          }

          const successes = await Promise.all(promises);

          if (successes.every(({ success }) => success))
            OToastManager.success("Profiles salvos com sucesso");
          else OToastManager.danger("Não foi possível salvar os profiles");

          getUser();
        },
        (errors) => {
          OToastManager.danger(
            `Campos obrigatórios: ${Object.keys(errors).join(", ")}`,
          );
        },
      ),
    [
      addProfilesToCompanyUserRelationship,
      getUser,
      handleSubmit,
      removeProfilesFromCompanyUserRelationship,
      selectedCompany,
      userData,
    ],
  );

  // Reset when selectedCompany changes
  useEffect(() => {
    resetProfiles();
  }, [resetProfiles]);

  const companyOptions = useMemo(
    () =>
      userData?.companies.map<SelectSearchOption<string>>((company) => ({
        label: fastCpfCnpjMask(company.cnpj),
        value: company.cnpj,
        onSelect: () => {},
      })) ?? [],
    [userData?.companies],
  );

  const profileOptions = useMemo(
    () =>
      allProfiles?.map<SelectSearchMultipleOption<string>>((profile) => ({
        label: profile.name,
        value: profile.name,
      })) ?? [],
    [allProfiles],
  );

  const isDirty = !!dirtyFields.profiles;

  return {
    allProfiles,
    companyOptions,
    form,
    getProfiles,
    getProfilesHasError,
    getProfilesLoading,
    getUser,
    getUserHasError,
    getUserLoading,
    isDirty,
    loading:
      getUserLoading || getProfilesLoading || addLoading || removeLoading,
    profileOptions,
    resetProfiles,
    submit,
    userData,
  };
};
