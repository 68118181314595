import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OModal, OModalBody, OModalFooter } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { gridStorage } from "utils/storage";

export const errorMessagesGrid: ODataGridGeneratorConfig<HubEnergy.LegacyImport.ValidationFailure> =
  {
    datagrid: {
      noDataText: "Nenhum erro",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("mle-errorMessages"),
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "errorMessage",
        caption: "Erro",
        allowHeaderFiltering: false,
      },
      {
        dataField: "propertyName",
        caption: "Propriedade",
      },
      {
        caption: "Avançado",
        cellRender: ({ data, rowIndex }) => {
          const modalId = `error-details-${rowIndex}`;
          return (
            <>
              <OButton
                type="dark"
                outline
                onClick={() => modalManager.show(modalId)}
              >
                Detalhes
              </OButton>
              <OModal
                id={modalId}
                className="position-absolute"
                position="center"
                // size="xxl"
                {...datagridModalFix}
              >
                <OModalBody>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </OModalBody>
                <OModalFooter>
                  <div className="d-flex justify-content-end">
                    <OButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          JSON.stringify(data, null, 2),
                        );
                        OToastManager.info("Erro copiado");
                      }}
                    >
                      Copiar
                    </OButton>
                  </div>
                </OModalFooter>
              </OModal>
            </>
          );
        },
      },
    ],
  };
