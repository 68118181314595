import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { formatStatementAccount } from "../../../../../card-hiring.utils";
import { cardHiringFields } from "../../card-hiring-form.form";

interface AccountNumberFieldProps {
  data?: BankingHub.Account[];
}

export const AccountNumberField = ({ data }: AccountNumberFieldProps) => {
  const { setValue } = useFormContext();

  return (
    <OCol xs={12} md={12} align="center">
      <ORFieldSelect
        {...cardHiringFields.accountNumber}
        readonly={!data?.length}
      >
        {data?.map((statementAccount) => (
          <OOption
            key={statementAccount.number}
            value={statementAccount.number}
            onClick={() => {
              setValue("branch", String(statementAccount.agency));
            }}
          >
            {formatStatementAccount(statementAccount)}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
