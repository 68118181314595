import {
  OButton,
  OFilter,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterModal,
  OTypography,
} from "@maestro/react";
import { useRef } from "react";
import { usePixKeysContext } from "../../pix-keys.context";
import { KeyTypeFilterItem } from "./_compose/key-type-filter-item";
import { StatusFilterItem } from "./_compose/status-filter-item";
import { PixKeysFilterFields } from "./filters-modal.type";

export const PixKeysFilterModalId = "pix-keys-filter-modal-id";

export const FiltersModal = () => {
  const filterModalRef = useRef<HTMLOFilterModalElement>(null);
  const { setFilters } = usePixKeysContext();

  const handleFilterItems = async () => {
    const params =
      (await filterModalRef.current?.filter()) as PixKeysFilterFields;

    const { keyType, status } = params;

    if (Array.isArray(keyType)) {
      params.keyType = keyType.join();
    }

    if (Array.isArray(status)) {
      params.status = status.join();
    }

    setFilters(params);

    filterModalRef.current?.closeFilter();
  };

  const handleClearFilterItems = async () => {
    setFilters({});
    await filterModalRef.current?.clearFilter();
    filterModalRef.current?.closeFilter();
  };

  return (
    <OFilterModal ref={filterModalRef} id={PixKeysFilterModalId}>
      <OFilterHeader className="pt-1">
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>

      <OFilterBody>
        <OFilter>
          <KeyTypeFilterItem />
          <StatusFilterItem />
        </OFilter>
      </OFilterBody>

      <OFilterFooter>
        <div className="d-flex justify-content-end">
          <OButton
            dataAction=""
            dataLabel=""
            outline
            type="primary"
            bordered={false}
            onClick={handleClearFilterItems}
          >
            Limpar
          </OButton>
          <OButton dataAction="" dataLabel="" onClick={handleFilterItems}>
            Filtrar
          </OButton>
        </div>
      </OFilterFooter>
    </OFilterModal>
  );
};
