import { OToastManager } from "@maestro/core";
import { ORFieldInput } from "@maestro/react";
import { FormTemplate } from "templates/form-template";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { commonDomainFormDefaultValues } from "./common-domain-form.schemas";

type CommonDomainFormProps =
  | {
      mode: "add";
    }
  | {
      mode: "edit";
      id: string;
    };

export const CommonDomainForm = (props: CommonDomainFormProps) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { handleSubmit } =
    useFormContext<typeof commonDomainFormDefaultValues>();

  const createCommonDomain = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const { mode } = props;

          if (mode === "add") {
            await service.hubCreditManager.createCommonDomain(values);
          } else {
            await service.hubCreditManager.updateCommonDomain({
              ...values,
              id: Number(props.id),
            });
          }
          OToastManager.success("Common Domain salvo com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.configurations.commonDomain
              .path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar o Common Domain.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, props],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          type="info"
          onClick={createCommonDomain}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <form spellCheck="false">
          <div className="d-flex flex-direction-row gap-3">
            <div className="w-80">
              <ORFieldInput
                adaptHeight
                id="key"
                name="key"
                tag="textarea"
                label="Chave"
                labelSize="md"
              />
            </div>
            <div className="w-100">
              <ORFieldInput
                adaptHeight
                id="value"
                name="value"
                tag="textarea"
                label="Valor"
                labelSize="md"
              />
            </div>
          </div>
        </form>
      </CardTemplate>
    </FormTemplate>
  );
};
