import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { TooltipIcon } from "components/tooltip-icon";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../../../components/mapped-status-badge";
import { diligenceStepStatusMap } from "../../../../../utils";

export const diligenceStepsGrid = (
  consumerUnitId: number | string,
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepSummaryResponseV1> => ({
  datagrid: {
    noDataText: "Nenhum step",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("mle-diligenceStepsGrid"),
  },
  columns: [
    {
      role: roles.energia.customer.consumerUnitMigrationStepType.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.energia.customer.consumerUnit.diligence.step.path(
            {
              consumerUnitId,
              stepId: data.id,
            },
          )}
        />
      ),
      width: "auto",
    },
    {
      dataField: "name",
      caption: "Etapas",
    },
    {
      dataField: "status",
      cellRender: ({ data }) => {
        return (
          <div className="d-inline-flex gap-2 align-items-center">
            <MappedStatusBadge
              map={diligenceStepStatusMap}
              status={data.status}
            />
            {data.status === "AGUARDANDO_DEPENDENCIA" &&
              data.dependencies?.length && (
                <TooltipIcon
                  maxWidth="500px"
                  content={data.dependencies?.map((dependency) => (
                    <p key={dependency}>{dependency}</p>
                  ))}
                />
              )}
          </div>
        );
      },
      alignment: "center",
      caption: "Status",
      width: "auto",
    },
    {
      dataField: "expirationDate",
      dataType: "date",
      format: "shortDateShortTime",
      caption: "Data limite",
      width: "auto",
    },
    {
      caption: "Última atualização",
      dataType: "date",
      format: "shortDateShortTime",
      allowSorting: true,
      allowFiltering: true,
      calculateCellValue: ({ createdDate, updatedDate }) =>
        new Date(updatedDate ?? createdDate),
      width: "auto",
    },
    {
      dataField: "isPublic",
      dataType: "boolean",
      caption: "Mostra cliente",
      width: "auto",
    },
  ],
});
