import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

interface LoanAgreementIdFieldProps {
  disabled?: boolean;
}

export const LoanAgreementIdField = ({
  disabled,
}: LoanAgreementIdFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:convenio_emprestimo"
        dataLabel="convenio_emprestimo"
        id="loanAgreementId"
        name="loanAgreementId"
        tag="text"
        placeholder="Preencher"
        label={EstrategiaFormLabels.loanAgreementId}
        disabled={disabled}
      />
    </OCol>
  );
};
