import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { logger } from "utils/logger";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  batchRequestReportsDefaultValues,
  validationSchema,
} from "./request-for-reports.form";
import { FormValuesType, ReportDto } from "./request-for-reports.types";
import { calculateStartEndDate } from "./request-for-reports.utils";

export const useBatchRequestForReports = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues: batchRequestReportsDefaultValues,
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const startDate =
        values.periodDate && values.periodDate[0].format("YYYY-MM-DD");
      const endDate =
        values.periodDate && values.periodDate[1].format("YYYY-MM-DD");

      const { periodoDe, periodoAte } = calculateStartEndDate(
        values.reportPeriod,
        startDate,
        endDate,
      );

      const payload = {
        fileName: values.reportName,
        periodoDe,
        periodoAte,
        cedenteIdentification: values.cnpjCedente ?? "",
        sacadosIdentification: values.cnpjSacados ?? [],
        isCnpjRaiz: values.isCnpjRaiz,
      };

      await service.quickfin.getReceivablesReport(payload).then(({ data }) => {
        if (data) {
          if (data.length === 0) {
            OToastManager.warning(
              "Não há dados disponíveis com as configurações escolhidas.",
            );
          } else {
            const parsedData = data.map((op: ReportDto) => {
              return {
                Status: op.valorSolicitado != op.maturityAmount && op.receivableStatus === "Antecipada" ? "ParcialmenteAntecipado" : op.receivableStatus,
                Erro: op.receivableRejectMessage,
                Sacado: op.sacado,
                "Sacado CNPJ": op.sacadoIdentification,
                Cedente: op.cedente,
                "Cedente CNPJ": op.cedenteIdentification,
                Bandeira: op.bandeira,
                Vencimento: moment(op.maturityDate).format("DD/MM/YYYY"),
                "Data da operação": moment(op.disbursementDate).format(
                  "DD/MM/YYYY",
                ),
                "Valor solicitado": op.valorSolicitado,
                "Valor registrado": op.maturityAmount,
                "Valor desembolsado": op.valorDesembolso,
                "Taxa mensal": op.taxaPeriodo.toLocaleString("pt-BR", {style:"percent", minimumFractionDigits: 4}),
                "Taxa total": op.taxaTotal.toLocaleString("pt-BR", {style:"percent", minimumFractionDigits: 4}),
              };
            });

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(parsedData);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Operacoes");

            XLSX.writeFile(workbook, `${values.reportName}.xlsx`);

            OToastManager.info("Arquivo baixado com sucesso.");
          }
        }
      });
    } catch (e) {
      logger.error(e);
      OToastManager.danger(
        "Houve um problema ao solicitar seu relatório. Verifique as configurações.",
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    submit,
  };
};
