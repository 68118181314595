import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { PostApiAdminCompanyUploadContractTemplateErrorResponse } from "services/hubloan/models/responses/error/post-api-admin-company-upload-contract-template-error.response";

interface LoanContractsTemplatesUploadErrorModalProps {
  errors?: PostApiAdminCompanyUploadContractTemplateErrorResponse["errors"];
}

export const LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID =
  "loan-contracts-templates-upload-error-modal";

export const LoanContractsTemplatesUploadErrorModal = ({
  errors,
}: LoanContractsTemplatesUploadErrorModalProps) => {
  const { hide } = modalManager;

  return (
    <OModal
      id={LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID}
      position="center"
      size="lg"
    >
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Erros no template de contrato
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <div style={{ whiteSpace: "normal" }}>
          {errors?.map((error) => (
            <div className="my-4" key={`${error.message}-${error.snippet}`}>
              <OTypography>
                <b>Erro: </b>
                {error.message}
              </OTypography>
              <OTypography>
                <b>Trecho: </b>
                {error.snippet}
              </OTypography>
            </div>
          ))}
        </div>
      </OModalBody>

      <OModalFooter>
        <OButton
          dataAction="erro_submissao:botao:fechar"
          dataLabel="fechar"
          type="dark"
          outline
          onClick={() => hide(LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID)}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
