import { getPropertyLabel } from "../../../../../../../../utils/get-property-label";
import { PropertyLabel } from "./step-pendencies.types";

export const mapPropertyLabels = (
  properties: HubEnergy.DiligenceStepPropertyResponse[],
): PropertyLabel[] =>
  properties.map((property) => ({
    id: property.id,
    label: getPropertyLabel(property),
  }));
