import { OToastManager } from "@maestro/core";
import { OCard, OCardBody } from "@maestro/react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { ErrorComponent } from "components/empty-state";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { LoadingButton } from "components/loading-button";
import { CadastroClienteNotificacoesOnboardingByWorkflowIdentifierRouteParams } from "../../../../../routes/cadastro.route-params";
import { NotificationArea } from "../../../detalhes/_compose";
import { ResendInviteButton } from "./_compose/resend-invite-button/resend-invite-button.component";
import { UpdateInviteForm } from "./_compose/update-invite-form/update-invite-form.component";
import { customerInviteDetailsGrid } from "./customer-invite-details.grid";
import { CustomerInviteEvents } from "./customer-invite-details.types";
import { useInviteDetails } from "./customer-invite-details.hook";

export const CustomerInviteDetails = () => {
  const { workflowIdentifier } =
    useParams<CadastroClienteNotificacoesOnboardingByWorkflowIdentifierRouteParams>();

  if (!workflowIdentifier) {
    throw new Error("No workflowIdentifier in route parameters");
  }
  const { form, hasError, loadingUpdate, updateInvite } =
    useInviteDetails(workflowIdentifier);
  const gridRef = useGridRef();

  const dataSourceGenerator = (workflowId: string) =>
    dataSourceCustomStoreGenerator<CustomerInviteEvents>(
      () => {
        return service.clerk
          .getWorkflowEvents({ identifier: workflowId })
          .then(({ data }) => ({
            data: data ?? [],
          }))
          .catch(() => {
            const errorMessage = "Erro ao buscar convites";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      },
      {
        dataSourceOptions: {
          sort: [
            {
              desc: true,
              selector: "insertedAt",
            },
          ],
        },
      },
    );

  const dataSource = useMemo(
    () => dataSourceGenerator(workflowIdentifier),
    [workflowIdentifier],
  );

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Detalhes do convite" />}
        actions={
          <LoadingButton
            loading={loadingUpdate}
            dataAction="confiracao_atualizar:botao:atualizar"
            dataLabel="atualizar"
            onClick={updateInvite}
          >
            Atualizar
          </LoadingButton>
        }
      >
        <div className="d-flex flex-column gap-4">
          <NotificationArea />
          <DetailsTemplate>
            <OCard>
              <OCardBody>
                <ContentTemplate
                  hasError={hasError}
                  noValue
                  errorComponent={
                    <ErrorComponent
                      messageTitle="Não foi possível encontrar os detalhes do convite"
                      messageParagraph="Ocorreu um erro ao buscar o convite. Retorne à página anterior e tente novamente."
                    />
                  }
                  render={() => (
                    <FormProvider {...form}>
                      <UpdateInviteForm />
                    </FormProvider>
                  )}
                />
              </OCardBody>
            </OCard>
          </DetailsTemplate>
        </div>
      </FormTemplate>

      <GridTemplate
        pageTitle={<PageTitle title="Eventos" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
        actions={
          <ResendInviteButton
            identifier={workflowIdentifier}
            reload={() => dataSource.reload()}
          />
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={customerInviteDetailsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </>
  );
};
