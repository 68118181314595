import { useCustomer } from "contexts/customer";
import { useMemo } from "react";

export const useFindUserFromCustomer = (identification: string) => {
  const {
    customer: { users },
  } = useCustomer();

  const user = useMemo(
    () => users?.find((_user) => _user.cpf === identification),
    [identification, users],
  );

  if (!user)
    throw new Error(
      `Could not find user with identification ${identification}`,
    );

  return user;
};
