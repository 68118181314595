import { cases, masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { FraudSysGridDataItem } from "services/gateholder/models/responses";

const redirectDetailLink = (id: string) => {
  const stringifiedid = JSON.stringify(id);
  const baseUrl =
    corporateRouter.routes.ferramentas.product.security.antifraudAnalysis
      .details.path;

  return `${baseUrl}?id=${encodeURIComponent(stringifiedid)}`;
};

export const grid: ODataGridGeneratorConfig<FraudSysGridDataItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      caption: "Detalhes",
      role: roles.ferramentas.product.securityAntifraudAnalysisDetailsPage.role,
      cellRender: ({ data: { requestId } }) => (
        <DetailsLink href={redirectDetailLink(requestId)} />
      ),
    },
    {
      dataField: "taxId",
      caption: "CPF",
      format: (value) => masks.cpf(value),
    },
    { dataField: "name", caption: "Nome" },
    {
      dataField: "relatedEntityTaxId",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
    },
    { dataField: "relatedEntityName", caption: "Razão Social" },
    {
      dataField: "analysisType",
      caption: "Tipo da análise",
      format: (value) => cases.title(value),
    },
    {
      dataField: "status",
      caption: "Status",
      format: (value) => cases.title(value),
    },
    {
      dataField: "analysisResult",
      caption: "Resultado",
      format: (value) => cases.title(value),
    },
    { dataField: "requestId", caption: "Request ID" },
    { dataField: "justification", caption: "Justificativa" },
    {
      dataField: "insertedAt",
      caption: "Data de envio para análise",
      dataType: "date",
      format: "shortDateShortTime",
      alignment: "center",
    },
    {
      dataField: "analysisResultDate",
      caption: "Data do resultado",
      dataType: "date",
      format: "shortDateShortTime",
      alignment: "center",
    },
    {
      dataField: "manualAnalysisDate",
      caption: "Data da análise manual",
      dataType: "date",
      format: "shortDateShortTime",
      alignment: "center",
    },
    {
      dataField: "updatedAt",
      caption: "Data de atualização",
      dataType: "date",
      format: "shortDateShortTime",
      alignment: "center",
      sortOrder: "desc",
    },
  ],
};
