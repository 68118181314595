import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { percent4DigitsMask } from "utils/mask";
import { ReceivableInfo } from "./operation-details.types";
import { useMemo, useState } from "react";
import { service } from "services/service";
import { OToastManager } from "@maestro/core";

export const buildOperationDetails =
  (
    requestedAmounts: Record<number, number>,
    disbursementAmounts: Record<number, number>,
    requestedDisbursementAmounts: Record<number, number>,
    companyTaxId: string[] | undefined,
  ) =>
  (receivables: ReceivableInfo[]): DetailsFields => {
    const [avgTerm, setAvgTerm] = useState(0);
    const [avgMonthlyRate, setAvgMonthlyRate] = useState(0);
    const [limitsCedente, setLimitsCedente] = useState({
      availableAmount: "",
    });
    
    const hideLimiteCedente =
      !companyTaxId || (new Set(companyTaxId).size > 1);

    const totalMaturityValue = receivables.reduce(
      (acc, curr) => acc + curr.valorVencimento,
      0,
    );

    const totalRequestedAmount = receivables.reduce(
      (acc, curr) =>
        acc +
        (requestedAmounts[curr.receivableId] ?? curr.valorVencimento ?? 0),
      0,
    );

    const totalNetValue = receivables.reduce(
      (acc, curr) =>
        acc +
        (requestedDisbursementAmounts[curr.receivableId] ??
          disbursementAmounts[curr.receivableId] ??
          curr.valorDesembolso ??
          0),
      0,
    );

    useMemo(() => {
      if(!hideLimiteCedente){
        service.limitControl.getLimitBagWithDependents(
          "QuickFin:Cartao:V2",
          companyTaxId?.at(0)?.toString().slice(0,8) || "",
        )
        .then(({ data }) => {
          if (data) {
            setLimitsCedente({ ...data });
          }
        });
      }
    }, [hideLimiteCedente]);

    useMemo(async () => {
      try {
        if (!receivables) return [];

        const receivablesRequestPayload = receivables.map((r) => ({
          ReceivableId: r.receivableId,
          ValorSolicitado: requestedAmounts[r.receivableId]
        }),);
  
        const { data } = await service.quickfin.postAdminReceivablesCalculateAvgs({
            ReceivablesRequestPayload: receivablesRequestPayload,
            AreReceivablesAlreadyCreated: true,
        });
        setAvgTerm(data.avgTerm);
        setAvgMonthlyRate(data.avgMonthlyRate);
      } catch (error) {
        OToastManager.danger("Não foi possível calcular Taxa Média Mensal e Prazo Médio.");
        console.error(error);
      }       
    }, [receivables]);
    
    return {
      details: {
        items: [
          {
            label: "Valor dos Vencimentos",
            value: masks.currency(totalMaturityValue, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Valor Solicitado",
            value: masks.currency(totalRequestedAmount, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Valor Líquido",
            value: masks.currency(totalNetValue, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Número de Recebíveis",
            value: receivables.length,
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Taxa Média Mensal",
            value: percent4DigitsMask(avgMonthlyRate ),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Prazo Médio",
            value: `${avgTerm.toFixed(2)} dia(s)`,
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Limite Disponível",
            value: masks.currency(limitsCedente.availableAmount, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
            visible: !hideLimiteCedente,
          },
        ],
      },
    };
  };
