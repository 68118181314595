import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useMemo, useState } from "react";
import { service } from "services";
import { NotificationUser } from "services/shark/models/responses";
import { logger } from "utils/logger";

export const useCobrancasEstimulosUsers = (operationId: number) => {
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<NotificationUser>(() => {
        return service.shark
          .getUsers({
            message_id: operationId,
            take: rows,
            skip: (page - 1) * rows,
          })
          .then(({ data }) => {
            setTotalItems(data.total_count);
            return data.data;
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar estímulos.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [page, rows, operationId],
  );

  return {
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
  };
};
