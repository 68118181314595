import { FeesType, InterestType } from "./enums";

export const MapperInterestType: Record<InterestType, string> = {
  [InterestType.NOT_APPLICABLE]: "Sem juros",
  [InterestType.PERCENTAGE_PER_MONTH]: "Percentual",
  [InterestType.FIXED_VALUE_PER_MONTH]: "Valor (R$)",
};

export const MapperFeesType: Record<FeesType, string | undefined> = {
  [FeesType.NOT_APPLICABLE]: undefined,
  [FeesType.PERCENTAGE]: "Percentual",
  [FeesType.FIXED_VALUE]: "Valor (R$)",
};
