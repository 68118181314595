import {
  IIcon,
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OIcon,
  OTypography,
} from "@maestro/react";

interface CardWalletProps {
  icon: IIcon;
  infos: { title: string; value: any }[];
  widht: string;
}

export const CardWallet: React.FC<CardWalletProps> = ({
  infos,
  icon,
  widht,
}) => {
  return (
    <OCard className={widht}>
      <OCardHeader divider>
        <OCol>
          <OCol>
            <OIcon
              category="fas"
              icon={icon}
              className="lg"
              style={{ marginRight: "10px" }}
            />
            <OTypography type="default">{infos[0].title}</OTypography>
          </OCol>
          {infos.map(
            (info, index) =>
              index > 0 && (
                <OCol>
                  <OTypography type="default">{info.title}</OTypography>
                </OCol>
              ),
          )}
        </OCol>
      </OCardHeader>
      <OCardBody>
        <OCol>
          {infos.map((info) => (
            <OCol>{info.value}</OCol>
          ))}
        </OCol>
      </OCardBody>
    </OCard>
  );
};
