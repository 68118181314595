import { toggleTheme, useTheme } from "contexts/theme";
import { CenteredIcon, Switch } from "./theme-switch.styles";

export const ThemeSwitch = () => {
  const { theme } = useTheme();

  return (
    <div className="d-flex align-items-center gap-1">
      <CenteredIcon
        title="Light mode"
        category="fa"
        icon="fa-sun"
        size="sm"
        type="dark"
      />
      <Switch
        title="Trocar entre light e dark modes"
        size="xs"
        value={theme === "dark"}
        type={theme}
        onInput={() => {
          toggleTheme();
        }}
      />
      <CenteredIcon
        title="Dark mode"
        category="fa"
        icon="fa-moon"
        size="sm"
        type="dark"
      />
    </div>
  );
};
