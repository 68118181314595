import { ONotification } from "@maestro/react";

interface ErrorNotificationsProps {
  children: React.ReactNode;
}

export const ErrorNotification = ({ children }: ErrorNotificationsProps) => {
  return (
    <ONotification type="danger">
      <div>{children}</div>
    </ONotification>
  );
};
