import {
  OButton,
  OCheckbox,
  ODivider,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldCheckboxGroup,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useBankslipContext } from "../../bankslip.context";
import { BankslipGridColumn } from "../../bankslip.type";
import { MapperBankslipGridColumnCaption } from "../../bankslip.utils";
import {
  ManageBankslipGridFormFields,
  manageBankslipGridDrawerId,
} from "./manage-bankslip-grid-drawer.types";

export const ManageBankslipGridDrawer = () => {
  const { visibleColumns, updateVisibleColumns } = useBankslipContext();
  const availableColumns = useMemo(() => Object.values(BankslipGridColumn), []);

  const [allColumnsSelected, setAllColumnsSelected] = useState(
    availableColumns.length === visibleColumns.length,
  );

  const form = useForm<ManageBankslipGridFormFields>({
    defaultValues: { columns: visibleColumns },
  });
  const { handleSubmit, setValue, watch } = form;

  const handleConfirm = useCallback(
    (formValues: ManageBankslipGridFormFields) => {
      if (!formValues.columns.length) {
        OToastManager.info("Selecione uma coluna para continuar");
        return;
      }
      updateVisibleColumns(formValues.columns);
      modalManager.hide(manageBankslipGridDrawerId);
    },
    [updateVisibleColumns],
  );

  const handleSelectAll = useCallback(() => {
    setValue("columns", allColumnsSelected ? [] : availableColumns);
    setAllColumnsSelected((prevState) => !prevState);
  }, [allColumnsSelected, availableColumns, setValue]);

  const columnsSelected = watch("columns")?.length;

  useEffect(() => {
    setAllColumnsSelected(columnsSelected === availableColumns.length);
  }, [availableColumns.length, columnsSelected]);

  return (
    <FormProvider {...form}>
      <OModal id={manageBankslipGridDrawerId} size="xs">
        <OModalHeader closeButton>
          <OTypography type="dark" size="lg">
            Selecionar colunas visíveis
          </OTypography>
        </OModalHeader>

        <OModalBody>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex align-items-center gap-3">
              <OCheckbox
                id="allColumns"
                value="allColumns"
                size="sm"
                onClick={handleSelectAll}
                checked={allColumnsSelected}
              />
              <OTypography type="dark" size="lg">
                Exibir todas as colunas
              </OTypography>
            </div>

            <ORFieldCheckboxGroup
              name="columns"
              dataAction=""
              dataLabel=""
              id="columns"
            >
              <div className="d-flex flex-column gap-2">
                {availableColumns.map((column, index) => (
                  <Fragment key={column}>
                    <div className="d-flex align-items-center gap-3">
                      <OCheckbox id={column} value={column} size="sm" />
                      <OTypography type="dark">
                        {MapperBankslipGridColumnCaption[column]}
                      </OTypography>
                    </div>
                    {index !== availableColumns.length - 1 && <ODivider />}
                  </Fragment>
                ))}
              </div>
            </ORFieldCheckboxGroup>
          </div>
        </OModalBody>

        <OModalFooter>
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end gap-2">
              <OButton
                dataAction=""
                dataLabel=""
                outline
                onClick={() => modalManager.hide(manageBankslipGridDrawerId)}
              >
                Voltar
              </OButton>
              <OButton
                dataAction=""
                dataLabel=""
                onClick={handleSubmit(handleConfirm)}
              >
                Confirmar mudança
              </OButton>
            </div>
          </div>
        </OModalFooter>
      </OModal>
    </FormProvider>
  );
};
