import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetDashboardBatchesResponse } from "services/fidc/models/responses";

export const fidcBatchesGrid: ODataGridGeneratorConfig<
  GetDashboardBatchesResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhum ativo disponível",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "CNPJ Cedente",
      dataField: "sellerTaxId",
    },
    {
      caption: "Razão Social Cedente",
      dataField: "sellerOfficialName",
    },
    {
      caption: "Status",
      dataField: "status",
    },
    {
      caption: "Notas",
      dataField: "assetsCount",
    },
    {
      caption: "Notas Válidas",
      dataField: "validAssetsCount",
    },
    {
      caption: "Valor Total",
      dataField: "value",
    },
    {
      caption: "Data de Submissão",
      dataField: "dueDate",
      dataType: "date",
      format: "dd/MM/yyyy",
      sortOrder: "desc",
    },
  ],
};
