import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import dayjs from "dayjs";
import DataSource from "devextreme/data/data_source";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import {
  GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyParams,
  GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse,
} from "services/admin-bankinghub/models";
import { service } from "services/service";
import {
  StateMentHistoryFormValues,
  configureWorkTimeDefaultValues,
  statementHistorySchema,
} from "./statement-history.form";

const dataSourceGenerator = (values: StateMentHistoryFormValues) =>
  dataSourceCustomStoreGenerator<
    GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number]
  >((loadOptions) => {
    const documentId = values.document.replace(/[/.-]/g, "");
    const accountId = values.account.trim();
    const agencyId = values.agency.trim();

    const params: GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyParams =
      {
        startAt: values.startAt
          ? dayjs(values.startAt).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        endAt: values.endAt
          ? dayjs(values.endAt).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        pageNumber: ((loadOptions.skip ?? 0) + (loadOptions.take ?? 0)) / 10,
        pageSize: 10,
      };

    return service.adminBankinghub
      .getCnabTraceabilities(documentId, accountId, agencyId, params)
      .then(({ data }) => ({
        data,
        totalCount: 10000,
      }))
      .catch(() => {
        OToastManager.danger("Erro ao buscar o lote");
        return {
          data: [],
          totalCount: 0,
        };
      });
  });

export const useStatementHistory = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dataSource, setDataSource] =
    useState<
      DataSource<
        GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number],
        any
      >
    >();
  const form = useForm<StateMentHistoryFormValues>({
    resolver: yupResolver(statementHistorySchema),
    defaultValues: configureWorkTimeDefaultValues,
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      setHasError(false);

      setDataSource(dataSourceGenerator(values));
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  });

  return {
    dataSource,
    loading,
    hasError,
    form,
    onSubmit,
  };
};
