import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetAdminCustomerLimitsOdataResponse } from "services/hubcreditmanager/models/responses";
import { currencySymbol } from "utils/currency";

export const grid: ODataGridGeneratorConfig<
  NonNullable<GetAdminCustomerLimitsOdataResponse["value"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhuma Linha de Crédito encontrada",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} linhas de crédito)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      role: roles.emprestimos.customer.limitManagerDetailsQuotesPage.role,
      cellRender: ({ data }) =>
        data.Id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.customer.limitManager.details.quotes.path(
              {
                id: data.Id,
              },
            )}
          />
        ),
    },
    {
      dataField: "CreditLineName",
      caption: "Linha de crédito",
    },
    {
      dataField: "Amount",
      caption: "Limite",
      cellRender: ({ data }) =>
        masks.currency(data.Amount, currencySymbol.BRL, "."),
    },
    {
      dataField: "AvailableAmount",
      caption: "Limite disponível",
      cellRender: ({ data }) =>
        masks.currency(data.AvailableAmount, currencySymbol.BRL, "."),
    },
    {
      dataField: "LimitRequestStatus",
      caption: "Status",
    },
    {
      dataField: "ExpirationDate",
      caption: "Data de expiração",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
  ],
};
