import styled from "styled-components";

export const UploadContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;

  o-upload-label {
    height: calc(var(--font-xxl) * 3.5) !important;
  }
`;
