export const ORFieldInputs = [
  {
    label: "fxAvailableAmount",
    name: "fxAvailableAmount",
    id: "fxAvailableAmount",
    mask: true,
  },
  {
    label: "clientLink",
    name: "clientLink",
    id: "clientLink",
    blockedAtDraft: true,
  },
];

export const statusMap = [
  "RASCUNHO",
  "RECEBIDO",
  "ENVIANDO_FTS",
  "ENVIADO_FTS",
  "ENVIANDO_FX",
  "EM_ANALISE_FX",
  "PENDENTE_AJUSTE",
  "APROVADO",
  "RECUSADO",
  "EXPIRADO",
  "CANCELADO",
  "ERRO_ENVIO_FTS",
  "ERRO_ENVIO_ANEXO_FX",
  "ERRO_ENVIO_REANALISE_FX",
];

export const payerGroupMap: Record<string, string> = {
  OTHERS: "Outros",
  PF: "Pessoa Física",
  FINANCIAL_COMPANIES: "Empresas financeiras",
};
