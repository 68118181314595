import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { ReceivablesTitle } from "services/quickfin/models";

export const reprocessModalGrid: ODataGridGeneratorConfig<ReceivablesTitle> = {
  datagrid: {
    noDataText: "Nenhum título disponível.",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showInfo: true,
      infoText: "Página {0} de {1} ({2} títulos)",
    },
  },
  columns: [
    {
      dataField: "TipoProduto",
      caption: "Tipo de recebível",
    },
    {
      dataField: "Sacado",
      caption: "Sacado",
    },
    {
      dataField: "Cedente",
      caption: "Cedente",
    },
    {
      dataField: "ValorVencimento",
      caption: "Valor vencimento",
      cellRender: ({ data }) => masks.currency(data.ValorVencimento),
    },
    {
      dataField: "ValorDesembolso",
      caption: "Valor desembolso",
      cellRender: ({ data }) => masks.currency(data.ValorDesembolso),
    },
  ],
};
