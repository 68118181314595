import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  InviteTypeFormValues,
  inviteTypeFormValidationSchema,
} from "../_compose";

export const useUpdateInviteType = (id: string) => {
  const { callService: getEmailConfigsTypeById, value: emailConfigsType } =
    useServiceCall(service.clerk.getEmailConfigsTypeById);

  const { callService: updateEmailConfigsType, loading: submitLoading } =
    useServiceCall(service.clerk.updateEmailConfigsTypeById);

  const form = useForm<InviteTypeFormValues>({
    resolver: yupResolver(inviteTypeFormValidationSchema),
  });
  const { handleSubmit, reset } = form;
  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const payload = {
          ...values,
        };
        const { success } = await updateEmailConfigsType(payload);

        if (!success)
          return OToastManager.danger("Erro ao atualizar tipo de convite.");

        navigate(
          corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs
            .inviteTypes.path,
        );
        return OToastManager.success("Tipo de convite atualizado com sucesso!");
      }),
    [handleSubmit, navigate, updateEmailConfigsType],
  );

  useEffect(() => {
    getEmailConfigsTypeById({ id });
  }, [getEmailConfigsTypeById, id]);

  useEffect(() => {
    emailConfigsType && reset(emailConfigsType);
  }, [emailConfigsType, reset]);

  return {
    form,
    submit,
    submitLoading,
  };
};
