import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { customer } from "contexts/customer";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { copyToClipboard } from "utils/copy";
import { buildTaggedLink } from "../../../../../utils/build-link";

export const buildDataSource = (operatorIdentification: string) =>
  dataSourceODataStoreGenerator<HubPartners.Odata.OperatorProfile>(
    endpoints.hubPartners.admin.odata.adminGetODataOperators,
    {
      dataSourceOptions: {
        filter: ["operatorIdentification", "contains", operatorIdentification],
      },
      odataStoreOptions: {
        beforeSend: ({ params }) => {
          // eslint-disable-next-line no-param-reassign
          params.originatorIdentification = customer.value?.identification;
        },
      },
    },
  );

export const relatedOriginatorsGrid =
  (): ODataGridGeneratorConfig<HubPartners.Odata.OperatorProfile> => ({
    datagrid: {
      noDataText: "Nenhum operador encontrada ",
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar o operador.";
      },
    },
    columns: [
      {
        dataField: "originatorName",
        caption: "Originador",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.originatorName?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.originatorIdentification)}
            </OTypography>
          </div>
        ),
        calculateFilterExpression: (filterValue) => {
          if (filterValue.replace(/\D/g, "") === "") {
            return ["originatorName", "contains", filterValue];
          }
          return [
            ["originatorName", "contains", filterValue],
            "or",
            [
              "originatorIdentification",
              "contains",
              filterValue.replace(/\D/g, ""),
            ],
          ];
        },
      },
      {
        dataField: "relationshipFilters",
        caption: "Filtros de Relacionamentos",
        width: 200,
      },
      {
        dataField: "isActive",
        caption: "Operador ativo",
        dataType: "boolean",
      },
      {
        dataField: "tagCode",
        caption: "Link tagueado",
        calculateFilterExpression: (filterValue) => [
          "tagCode",
          "contains",
          filterValue,
        ],
      },
      {
        dataField: "identifier",
        caption: "Identificador externo",
        calculateFilterExpression: (filterValue) => [
          "identifier",
          "contains",
          filterValue,
        ],
      },
      {
        dataField: "createdDate",
        caption: "Data de criação",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        cellRender: ({ data }) => masks.cpfCnpj(data.createdBy),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "updatedDate",
        caption: "Data de atualização",
      },
      {
        dataField: "deactivatedAt",
        caption: "Data de desativação",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: {
                  category: "orq",
                  icon: "orq-link",
                },
                label: "Copiar link tagueado",
                onClick: () => {
                  copyToClipboard(buildTaggedLink(data.tagCode));
                  OToastManager.success("Link tagueado copiado com sucesso");
                },
              },
            ]}
          />
        ),
      },
    ],
  });
