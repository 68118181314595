import { modalManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EstrategiaRelationshipFields } from "../../../../../components";
import {
  EstrategiaTemplateRelationshipsConfirmationModal,
  estrategiaTemplateRelationshipsConfirmationModalId,
} from "./_compose";
import { useEstrategiaTemplatesRelationship } from "./estrategia-templates-relationship.hook";

export const EstrategiaTemplatesRelationship = () => {
  const {
    estrategiaName,
    estrategiaProduct,
    form,
    getCompanyEstrategia,
    hasError,
    loading,
    submit,
    submitLoading,
  } = useEstrategiaTemplatesRelationship();

  const { trigger } = form;

  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle
            title={`Cadastro de relacionamento${
              estrategiaName?.replace(/^/, " - ") ?? ""
            }`}
          />
        }
        actions={
          <LoadingButton
            dataAction="detalhes:botao:submeter"
            dataLabel="submeter"
            loading={submitLoading}
            disabled={loading}
            onClick={async () => {
              if (await trigger())
                modalManager.show(
                  estrategiaTemplateRelationshipsConfirmationModalId,
                );
            }}
          >
            Salvar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ContentTemplate
              loading={loading}
              hasError={hasError}
              errorComponent={
                <DefaultErrorComponent
                  title="Erro ao buscar a estratégia"
                  callback={getCompanyEstrategia}
                />
              }
              noValue
              render={() => (
                <EstrategiaRelationshipFields
                  companyId={null}
                  product={estrategiaProduct}
                />
              )}
            />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <EstrategiaTemplateRelationshipsConfirmationModal submit={submit} />
    </>
  );
};
