import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { dataSource, operationsGrid } from "./operations.grid";
import { OperationsDashboard } from "./_compose";

export const Operations = () => {
  const gridRef = useGridRef();
  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Operações de Antecipação de Cartões"
          description="Acompanhe todas as operações antecipação de recebíveis de cartões realizadas em nossa plataforma"
        />
      }
    >
      <div className="d-flex flex-column gap-4">
        <OperationsDashboard />
        <GridTemplate
          gridRef={gridRef}
          showClearFiltersButton
          showRefreshButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={operationsGrid}
            dataSource={dataSource}
          />
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
