import { cases, masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetAdminCustomerHistoryResponse } from "services/hubloan/admin-customer/models";
import { format } from "utils/date";
import { productTypes } from "./loan-operations.utils";

type HistoryResponseItem = GetAdminCustomerHistoryResponse[number];

export const grid = {
  datagrid: {
    noDataText: "Nenhuma operação encontrada.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} operações)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      role: roles.emprestimos.customer.limitManagerDetailsQuotesPage.role,
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.customer.operations.details.path(
              {
                id: data.id,
              },
            )}
          />
        ),
    },
    {
      dataField: "contract",
      caption: "Contrato",
      cellRender: ({ data }) => data.contract || "-",
    },
    {
      dataField: "createdDate",
      caption: "Data de contratação",
      cellRender: ({ data }) => format.dateTime(data.createdDate),
    },
    {
      caption: "Produto",
      cellRender: ({ data }) =>
        data.productName ||
        productTypes[data.product || ""] ||
        cases.title(data.product),
      calculateFilterExpression: (filterValue: string) => {
        const productKeys = Object.keys(productTypes).filter((p) =>
          productTypes[p]
            .toLocaleLowerCase()
            .includes(filterValue.toLocaleLowerCase()),
        );

        const expression: (string | string[])[] = [];

        productKeys.forEach((value, index) => {
          if (index > 0) expression.push("or");
          expression.push(["product", "contains", value]);
        });

        return expression;
      },
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => cases.title(data.status),
    },
    {
      dataField: "curveValue",
      caption: "Saldo devedor atual",
      cellRender: ({ data }) => masks.currency(data.curveValue, "R$", "."),
      alignment: "left",
    },
    {
      dataField: "amount",
      caption: "Valor solicitado",
      cellRender: ({ data }) => masks.currency(data.amount, "R$", "."),
      alignment: "left",
    },
  ],
} satisfies ODataGridGeneratorConfig<HistoryResponseItem>;
