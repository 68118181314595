import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { accountsGrid } from "./accounts.grid";

export const Accounts = () => {
  const gridRef = useGridRef();

  const fetchAccountBalance = async (account: BankingHub.Account) => {
    try {
      const { data } = await service.bankinghub.getAccountBalance(
        account.number,
        account.agency,
      );

      return { ...account, ...data.data };
    } catch (error) {
      if (!isAxiosError(error)) logger.error(error);
      return { ...account, error: true };
    }
  };

  const dataSource = dataSourceCustomStoreGenerator<BankingHub.Account>(() =>
    service.bankinghub
      .getStatementAccounts()
      .then(({ data }) => data.data ?? [])
      .then((accounts) =>
        accounts.map((account) => fetchAccountBalance(account)),
      )
      .then((promises) => Promise.all(promises))
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar as contas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contas" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={accountsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
