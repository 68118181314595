import { DetailsCard } from "components/details-card";
import { StandardLink } from "components/standard-link";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { FxBeneficiaryResponse } from "services/hubfx/models/types/beneficiary/beneficiary.model";
import { ExchangeCounterpartyResponse } from "services/hubfx/models/types/counterparty/counterparty.model";
import { ExchangeLimitResponse } from "services/hubfx/models/types/limit/limit.model";
import { ExchangeOrderResponse } from "services/hubfx/models/types/order/order.model";
import { ExchangePreTicketResponse } from "services/hubfx/models/types/pre-ticket/pre-ticket.model";
import { ExchangeQuoteResponse } from "services/hubfx/models/types/quote/quote.model";
import { exchangeBeneficiaryDetailsGenerator } from "../beneficiary/beneficiary.details";
import { exchangeOrderDetailsGenerator } from "../orders/order.details";
import { exchangePreTicketDetailsGenerator } from "../pre-tickets/pre-ticket.details";
import { exchangeQuoteDetailsGenerator } from "./quote.details";

interface ExchangeQuoteDetailsProps {
  quoteDetails:
    | (ExchangeQuoteResponse & {
        beneficiary: FxBeneficiaryResponse | null;
        counterparty: ExchangeCounterpartyResponse | null;
        order: ExchangeOrderResponse | null;
        preTicket:
          | (ExchangePreTicketResponse & {
              limit: ExchangeLimitResponse | null;
            })
          | null;
      })
    | null;
  type: "customer" | "product";
  counterparty?: string | null;
}

export const ExchangeQuoteDetails = ({
  quoteDetails,
  type,
  counterparty,
}: ExchangeQuoteDetailsProps) => {
  const { hasRole } = useRoles();
  const { mapSymbol } = useCurrencies();

  return (
    <div className="d-flex flex-column gap-2">
      <DetailsCard
        value={quoteDetails}
        fields={exchangeQuoteDetailsGenerator}
      />
      {quoteDetails?.beneficiary && (
        <DetailsCard
          value={quoteDetails.beneficiary}
          fields={exchangeBeneficiaryDetailsGenerator}
        />
      )}
      {quoteDetails?.order && (
        <DetailsCard
          value={quoteDetails.order}
          fields={(order) =>
            exchangeOrderDetailsGenerator(
              order,
              order?.id &&
                hasRole(roles.cambio[type].ordersDetailsPage.role) ? (
                <StandardLink
                  href={corporateRouter.routes.cambio[type].orders.details.path(
                    { id: order.id },
                  )}
                  size="lg"
                  type="default"
                >
                  Ordem
                </StandardLink>
              ) : undefined,
            )
          }
        />
      )}
      {quoteDetails?.preTicket && (
        <DetailsCard
          value={quoteDetails.preTicket}
          fields={(preTicket) =>
            exchangePreTicketDetailsGenerator(
              preTicket,
              mapSymbol,
              preTicket?.id &&
                hasRole(roles.cambio[type].preTicketsDetailsPage.role) ? (
                <>
                  {type === "customer" ? (
                    <StandardLink
                      href={corporateRouter.routes.cambio[
                        type
                      ].preTickets.details.path({ id: preTicket.id })}
                      size="lg"
                      type="default"
                    >
                      Pré-Ticket
                    </StandardLink>
                  ) : (
                    <StandardLink
                      href={corporateRouter.routes.cambio[
                        type
                      ].preTickets.id.details.preTicket.path({
                        id: preTicket.id,
                        counterparty: counterparty ?? "",
                      })}
                      size="lg"
                      type="default"
                    >
                      Pré-Ticket
                    </StandardLink>
                  )}
                </>
              ) : undefined,
            )
          }
        />
      )}
    </div>
  );
};
