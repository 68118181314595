import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ExchangeLimitResponse } from "services/hubfx/models/types/limit/limit.model";

export const exchangeLimitsGrid = (
  mapSymbol: (currency: string | null) => string,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum Limite encontrado.",
      headerFilter: { visible: true },
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.cambio.customer.limitsDetailsPage.role,
        cellRender: ({ data }) =>
          !!data.id && (
            <DetailsLink
              href={corporateRouter.routes.cambio.customer.limits.details.path({
                id: data.id,
              })}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "currency",
        caption: "Moeda",
      },
      {
        caption: "Tipo",
        dataField: "flowType",
      },
      {
        caption: "Status",
        dataField: "status",
      },
      {
        caption: "Categoria",
        cellRender: ({ data }) => (data.hasPreTicket ? "PRE_TICKET" : "ANUAL"),
      },
      {
        caption: "Valor disponível",
        cellRender: ({ data }) =>
          masks.currency(data.availableAmount, mapSymbol(data.currency), "."),
      },
      {
        caption: "Valor total",
        cellRender: ({ data }) =>
          masks.currency(data.totalAmount, mapSymbol(data.currency), "."),
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    ExchangeLimitResponse & { hasPreTicket: boolean | null }
  >;
};
