import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const EnableAccount = () => {
  return (
    <RouterButton
      dataAction="ferramentas:botao:habilitar"
      dataLabel="habilitar"
      href={
        corporateRouter.routes.cadastro.customer.details.enableCurrentAccount
          .path
      }
      role={roles.cadastro.customer.detailsEnableCurrentAccountPage.role}
    >
      Habilitar
    </RouterButton>
  );
};
