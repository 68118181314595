import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ExchangePreTicketDetails } from "../../../../components/pre-tickets/pre-ticket-details.component";
import { CambioPreTicketsByIdRouteParams } from "../../../../routes/cambio.route-params";

export const ExchangePreTicketDetailsPage = () => {
  const { customer } = useCustomer();
  const { id } = useParams<CambioPreTicketsByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: preTicketDetails,
    callService: getPreTicketDetails,
    hasError: hasErrorPreTicketDetails,
    loading: loadingPreTicketDetails,
  } = useServiceCall(service.hubFx.adminCustomer.getPreTicketDetails);

  useEffect(() => {
    getPreTicketDetails(id);
  }, [getPreTicketDetails, id, customer]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do Pré-Ticket" />}>
      <ContentTemplate
        loading={loadingPreTicketDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes do pré-ticket."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getPreTicketDetails(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorPreTicketDetails}
        value={preTicketDetails}
        render={(preTicket) => (
          <ExchangePreTicketDetails
            preTicketDetails={preTicket}
            type="customer"
          />
        )}
      />
    </DetailsTemplate>
  );
};
