import { masks } from "@maestro/utils";
import { OColumnProps, ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { OptionChangedEventInfo } from "devextreme/core/dom_component";
import dxDataGrid from "devextreme/ui/data_grid";
import { corporateRouter } from "routes/corporate-router.context";
import { GetOwnerResponse, MoanaOwner } from "services/moana/models";
import { buildCustomerLink } from "utils/build-customer-link";
import { parseSortingToUrl } from "./wallets.utils";
import { WalletState } from "./_compose/wallet-state";
import { WalletGridActions } from "./_compose/wallets-grid-actions";

const walletsGridColumns: OColumnProps<MoanaOwner>[] = [
  {
    allowExporting: false,
    cellRender: ({ data: { documentNumber } }) =>
      documentNumber && (
        <DetailsLink
          href={buildCustomerLink(
            documentNumber,
            corporateRouter.routes.duplicatas.customer.wallets.path,
          )}
        />
      ),
  },
  {
    dataField: "name",
    caption: "Empresa",
    sortOrder: "asc",
  },
  {
    dataField: "documentNumber",
    caption: "Identificação",
    format: (value) => masks.cpfCnpj(value),
    allowSorting: false,
  },
  {
    dataField: "state",
    caption: "Status",
    cellRender: ({ data }) => <WalletState state={data.state ?? []} />,
    allowSorting: false,
  },
  {
    dataField: "totalReceivableValue",
    caption: "Carteira total",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "totalPendingPaymentValue",
    caption: "Vencidos total",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "totalEligibleValue",
    caption: "Carteira disponível",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "totalAnticipatedValue",
    caption: "Carteira descontada",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "totalLateSettlement",
    caption: "Carteira vencida",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "totalAvailableLimit",
    caption: "Limite aprovado",
    format: (value) => masks.currency(value, "R$"),
  },
  {
    dataField: "isActive",
    caption: "Limite ativo",
    dataType: "boolean",
    allowSorting: false,
  },
  {
    caption: "Ações",
    cellRender: ({ data, component }) => (
      <WalletGridActions
        name={data.name}
        documentNumber={data.documentNumber}
        personType={data.personType}
        reload={() => component.getDataSource().reload()}
        showReprocessBordero={data.isActive}
      />
    ),
  },
];

export const grid: (
  setSortQuery: React.Dispatch<React.SetStateAction<string>>,
  rows: number,
) => ODataGridGeneratorConfig<GetOwnerResponse["data"][number]> = (
  setSortQuery,
  rows,
) => ({
  datagrid: {
    noDataText: "Nenhuma carteira encontrada",
    pager: { visible: false },
    paging: { pageSize: rows },
    onOptionChanged: (e) => {
      if (!!e.name && e.name === "columns") {
        setSortQuery(
          parseSortingToUrl(
            e as OptionChangedEventInfo<dxDataGrid<unknown, unknown>>,
          ),
        );
      }
    },
  },
  columns: walletsGridColumns,
});
