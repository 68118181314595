import styled from "styled-components";

export const transitionDurationMs = 200;
const transitionDurationCss = `${transitionDurationMs / 1000}s`;

export const FullcreenContainerDiv = styled.div`
  transition: inset ${transitionDurationCss} ease,
    padding ${transitionDurationCss} ease,
    background-color ${transitionDurationCss} ease;
`;

export const FullcreenButtonBtn = styled.button.attrs({
  type: "button",
})`
  all: unset;
  cursor: pointer;

  background-color: var(--theme-tertiary);
  border-radius: 50%;
  padding: 0.5rem;
`;
