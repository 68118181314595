import { OButton, OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { ContactContext } from "contexts/contact/contact.component";
import { useFinancialInstitutionsList } from "hooks/financial-institution-list";
import { useRoles } from "hooks/roles";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import {
  AddressItem,
  BankAccountsItem,
  GetContactByContactHolderResponseItem,
  PixItem,
} from "services/admin-bankinghub/models";
import { FinancialInstitutionUtils } from "utils/financial-institution";
import { AccountUtils } from "utils/financial-institution/account";
import { ContactBanksGridActions } from "./_compose/contact-banks-grid-actions";

const contactsBanksDetailsGrid = (
  financialInstitutions: Assets.FinancialInstitution[] = [],
) => {
  return {
    datagrid: {
      noDataText: "Nenhuma conta encontrada",
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        allowHeaderFiltering: true,
        dataField: "bank",
        caption: "Código de banco",
        alignment: "center",
        cellRender: ({ data: { bank } }) => bank ?? "S/N",
      },
      {
        allowHeaderFiltering: true,
        dataField: "ispb",
        caption: "Instituição financeira",
        cellRender: ({ data: { bank, ispb } }) =>
          FinancialInstitutionUtils.findFinancialInstitution(
            financialInstitutions,
            [bank, ispb],
          )?.shortName,
      },
      {
        allowHeaderFiltering: true,
        dataField: "ispb",
        caption: "ISPB",
        alignment: "center",
        cellRender: ({ data: { bank, ispb } }) =>
          FinancialInstitutionUtils.findFinancialInstitution(
            financialInstitutions,
            [bank, ispb],
          )?.ispb,
      },
      {
        allowHeaderFiltering: true,
        dataField: "account",
        caption: "Conta",
        alignment: "center",
      },
      {
        allowHeaderFiltering: true,
        dataField: "account_digit",
        caption: "Dígito",
        alignment: "center",
      },
      {
        allowHeaderFiltering: true,
        dataField: "branch",
        caption: "Agência",
        format: (value) => AccountUtils.formatAccountAgency(value),
        alignment: "center",
      },
      {
        dataField: "is_disbursement_account",
        caption: "Conta principal",
      },
      {
        cellRender: ({ data }) => (
          <ContactBanksGridActions
            financialInstitutions={financialInstitutions}
            {...data}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<BankAccountsItem>;
};

const contactsPixDetailsGrid: ODataGridGeneratorConfig<PixItem> = {
  datagrid: {
    noDataText: "Nenhuma chave encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowHeaderFiltering: true,
      dataField: "key_type",
      caption: "Tipo",
    },
    {
      allowHeaderFiltering: true,
      dataField: "pix_key",
      caption: "Chave",
    },
  ],
};

const contactsAddressDetailsGrid: ODataGridGeneratorConfig<AddressItem> = {
  datagrid: {
    noDataText: "Nenhum endereço encontrado",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowHeaderFiltering: true,
      dataField: "address_street",
      caption: "Rua",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_number",
      caption: "Número",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_neighborhood",
      caption: "Bairro",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_city",
      caption: "Cidade",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_state_label",
      caption: "Estado",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_complement",
      caption: "Complemento",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_zipcode",
      caption: "CEP",
    },
  ],
};

type ContactsMasterDetailProps =
  MasterDetailComponentProps<GetContactByContactHolderResponseItem>;

export const ContactsMasterDetail = ({
  data: { data },
}: ContactsMasterDetailProps) => {
  const { routes } = corporateRouter;
  const { setContact } = useContext(ContactContext);
  const { hasRole } = useRoles();
  const navigate = useNavigate();
  const { financialInstitutions } = useFinancialInstitutionsList();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <OTypography size="lg">Contas Bancárias</OTypography>
        {hasRole(roles.banking.customer.contactsFavoredCreatePage.role) && (
          <OButton
            onClick={() => {
              navigate(routes.banking.customer.contacts.create.path);
              setContact(data);
            }}
          >
            Criar
          </OButton>
        )}
      </div>
      <ODataGridGenerator
        grid={contactsBanksDetailsGrid(financialInstitutions)}
        dataSource={data.product.bankAccounts}
      />
      <OTypography size="lg" className="mb-1 mt-4">
        Pix
      </OTypography>
      <ODataGridGenerator
        grid={contactsPixDetailsGrid}
        dataSource={data.product.pix}
      />
      <OTypography size="lg" className="mb-1 mt-4">
        Endereços
      </OTypography>
      <ODataGridGenerator
        grid={contactsAddressDetailsGrid}
        dataSource={data.product.address}
      />
    </>
  );
};
