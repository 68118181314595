import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useNavigate } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { denyListGrid } from "./deny-list.grid";

export const DenyListPage = () => {
  const gridRef = useGridRef();

  const navigate = useNavigate();

  return (
    <div>
      <GridTemplate
        pageTitle={<PageTitle title="Lista de Bloqueios" />}
        showClearFiltersButton
        showRefreshButton
        gridRef={gridRef}
        actions={
          <OButton
            role={roles.workflow.product.legalEntityDenialAddPage.role}
            onClick={() => {
              navigate(
                corporateRouter.routes.workflow.product.denyList.add.path,
              );
            }}
          >
            Adicionar
          </OButton>
        }
      >
        <ODataGridGenerator gridRef={gridRef} grid={denyListGrid(navigate)} />
      </GridTemplate>
    </div>
  );
};
