import * as yup from "yup";
import {
  BuyingAndSellingFormValues,
  FidcImportPossibleFormTypes,
  RentingFormValues,
} from "./import-fidc.types";

const rentingValidationSchema: yup.ObjectSchema<RentingFormValues> = yup.object(
  {
    buyerTaxId: yup.string().required("Este campo é obrigatório"),
    dueDate: yup.string().optional().defined(),
    faceValue: yup.number().required("Este campo é obrigatório"),
    gracePeriod: yup
      .number()
      .required("Este campo é obrigatório")
      .min(0)
      .max(999),
    guarantorTaxId: yup.string().optional().defined(),
    installments: yup
      .number()
      .required("Este campo é obrigatório")
      .min(1)
      .max(999),
    operationDate: yup
      .mixed<moment.Moment>()
      .required("Este campo é obrigatório"),
    sellerTaxId: yup.string().required("Este campo é obrigatório"),
    selectedProduct: yup
      .string<RentingFormValues["selectedProduct"]>()
      .required("Este campo é obrigatório"),
    type: yup.string().required("Este campo é obrigatório"),
  },
);

const buyingAndSellingValidationSchema: yup.ObjectSchema<BuyingAndSellingFormValues> =
  yup
    .object({
      brandNew: yup.boolean().required("Este campo é obrigatório"),
      contractDate: yup.string().required("Este campo é obrigatório"),
      isBuyerGuarantee: yup.boolean().required("Este campo é obrigatório"),
      originalDate: yup.string().required("Este campo é obrigatório"),
      selectedProduct: yup
        .string<BuyingAndSellingFormValues["selectedProduct"]>()
        .required("Este campo é obrigatório"),
    })
    .concat(rentingValidationSchema.omit(["faceValue", "selectedProduct"]));

export const fidcImportPossibleFormValidationSchema = yup
  .object()
  .when(([value]) => {
    const { selectedProduct }: FidcImportPossibleFormTypes = value;
    return selectedProduct === "simpar-locacao"
      ? rentingValidationSchema
      : buyingAndSellingValidationSchema;
  });

export const rentingDefaultValues: RentingFormValues = {
  buyerTaxId: null,
  dueDate: null,
  faceValue: null,
  gracePeriod: null,
  guarantorTaxId: null,
  installments: null,
  operationDate: null,
  selectedProduct: "simpar-locacao",
  sellerTaxId: null,
  type: null,
};

export const buyingAndSellingDefaultValues: BuyingAndSellingFormValues = {
  buyerTaxId: null,
  brandNew: null,
  contractDate: null,
  dueDate: null,
  gracePeriod: null,
  guarantorTaxId: null,
  installments: null,
  isBuyerGuarantee: null,
  operationDate: null,
  originalDate: null,
  selectedProduct: "simpar-compra-e-venda",
  sellerTaxId: null,
  type: null,
};
