import { OToastManager } from "@maestro/core";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getFormData } from "utils/file/file.utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useFilePropertyField = ({
  processorConfigProperty: {
    processorPropertyId,
    processorConfigId,
    value,
    name,
  },
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();

  const handleSubmitFile = useCallback(
    async (
      file: File,
      processorPropertyId: number,
      processorConfigId: number,
      resolve: (file: File) => void,
    ) => {
      try {
        const formData = await getFormData(file, "");

        const { data } = await service.hubCreditManager.upload(formData);

        await service.hubCreditManager.updateProcessorConfigProperty({
          processorPropertyId,
          processorConfigId,
          value: data,
        });

        resolve(file);
      } catch {
        OToastManager.danger(
          "Não foi possível enviar o arquivo. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [],
  );

  const getInitialFile = useCallback(async () => {
    try {
      if (!value) return;

      const { data: blob } = await service.hubCreditManager.download({
        key: value,
      });

      const splittedKey = value?.split("/");

      setValue(`properties.${name}_${processorPropertyId}`, [
        new File([blob], splittedKey?.at(-1) ?? ""),
      ]);
    } catch {
      OToastManager.danger("Um erro ocorreu ao buscar pelo arquivo.");
    }
  }, [setValue, value, processorPropertyId, processorConfigId]);

  useEffect(() => {
    if (value) {
      getInitialFile();
    }
  }, [getInitialFile, value]);

  return { handleSubmitFile };
};
