import { sortById } from "../../../../../../utils";
import { useStepAnalysisContext } from "../../step-analysis.context";
import { PendencyTile } from "../../_compose";

export const StepPendenciesStageComponent = () => {
  const { stepAnalysis } = useStepAnalysisContext();

  return (
    <div className="d-flex flex-column gap-4">
      {sortById(stepAnalysis.stepPendencies).map((p) => (
        <PendencyTile key={p.id} pendency={p} />
      ))}
    </div>
  );
};
