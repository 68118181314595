import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OLabel,
  ORFieldInput,
  ORow,
  OSwitch,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { currencySymbol } from "utils/currency";
import { Collateral } from "../../imovel-additional-data.types";
import {
  CollateralFormValues,
  collateralFormValidationsSchema,
} from "./collateral-form.schemas";

interface CollateralFormProps {
  collateral?: Collateral;
}

export const CollateralForm = ({ collateral }: CollateralFormProps) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<CollateralFormValues>({
    resolver: yupResolver(collateralFormValidationsSchema),
  });

  const { reset, handleSubmit, watch, setValue } = form;

  const formalizedWatcher = watch("formalized");

  const handleUpdateCollateral = useCallback(
    async (values: CollateralFormValues) => {
      try {
        if (!collateral) return;

        setLoading(true);

        await service.hubCreditManager.updateCollateral({
          ...values,
          identification: collateral.identification,
          collateralId: collateral.id,
          formalizedContractNumber: values.formalized
            ? values.formalizedContractNumber
            : undefined,
          timelineId: values.formalized ? values.timelineId : undefined,
          hasOptional: false,
        });

        OToastManager.success("Garantia atualizada com sucesso");
      } catch {
        OToastManager.danger("Ocorreu um erro ao atualizar a garantia.");
      } finally {
        setLoading(false);
      }
    },
    [collateral],
  );

  useEffect(() => {
    collateral &&
      reset({
        ...collateral,
        formalizedContractNumber: collateral.formalizedContractNumber || "",
        timelineId: collateral.timelineId || "",
      } as CollateralFormValues);
  }, [collateral, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Dados adicionais da garantia" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_imovel_garantia_form:botao:atualizar_dados_da_propriedade"
          dataLabel="atualizar_dados_da_propriedade"
          onClick={handleSubmit(handleUpdateCollateral)}
        >
          Atualizar dados da garantia
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                id="description"
                name="description"
                dataAction="emprestimos_imovel_garantia_form:input:descricao"
                dataLabel="descricao"
                label="Descrição"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="percentage"
                id="guaranteedPercentage"
                name="guaranteedPercentage"
                dataAction="emprestimos_imovel_garantia_form:input:porcentagem_garantia"
                dataLabel="porcentagem_garantia"
                label="Porcentagem de garantia (%)"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="currency"
                symbol={currencySymbol.BRL}
                id="amount"
                name="amount"
                dataAction="emprestimos_imovel_garantia_form:input:valor_garantia"
                dataLabel="valor_garantia"
                label="Valor da garantia"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="currency"
                symbol={currencySymbol.BRL}
                id="reviewPrice"
                name="reviewPrice"
                dataAction="emprestimos_imovel_garantia_form:input:valor_constatado_laudo"
                dataLabel="valor_constatado_laudo"
                label="Valor constatado no laudo"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol md={6}>
              <ORFieldInput
                tag="number"
                id="collateralArea"
                name="collateralArea"
                dataAction="emprestimos_imovel_garantia_form:input:arear_alienacao_fiduciaria"
                dataLabel="arear_alienacao_fiduciaria"
                label="Área de alienação fiduciária (hectares)"
              />
            </OCol>
          </ORow>

          <div className="d-flex align-items-center mb-2 gap-2 w-25">
            <OSwitch
              id="formalized"
              size="xs"
              className="mr-2"
              value={!!formalizedWatcher}
              onInput={() => setValue("formalized", !formalizedWatcher)}
            />
            <OLabel htmlFor="formalized" size="md">
              Já assinado
            </OLabel>
          </div>
          {!!formalizedWatcher && (
            <ORow>
              <OCol md={6}>
                <ORFieldInput
                  tag="text"
                  id="formalizedContractNumber"
                  name="formalizedContractNumber"
                  dataAction="emprestimos_imovel_garantia_form:input:numero_contrato_af"
                  dataLabel="numero_contrato_af"
                  label="Número do contrato da AF"
                />
              </OCol>
              <OCol md={6}>
                <ORFieldInput
                  tag="text"
                  id="timelineId"
                  name="timelineId"
                  dataAction="emprestimos_imovel_garantia_form:input:timeline_id"
                  dataLabel="timeline_id"
                  label="Timeline Id"
                />
              </OCol>
            </ORow>
          )}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
