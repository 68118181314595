import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { RuleComponent } from "../_compose";

export const AddEventRulePage = () => {
  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Adicionar regra de evento" />}
    >
      <RuleComponent initialMode="editing" />
    </DetailsTemplate>
  );
};
