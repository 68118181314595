import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { filterValues, formatExcelDate, parseNumber } from "../../../../utils";
import { TaxaFromSpreadsheet } from "../../batch-taxas.types";

export const parseTaxasSpreadsheet = (buffer: FileReader["result"]) => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return filterValues({
      dataBase: formatExcelDate(cleanRow.database),
      dataFutura: formatExcelDate(cleanRow.datafutura),
      diasCorridos: parseNumber(cleanRow.diascorridos),
      taxaPeriodo: parseNumber(cleanRow.taxaperiodo),
      taxaTotal: parseNumber(cleanRow.taxatotal),
      currency: cleanRow.currency,
      base: cleanRow.base,
      capitalizationType: cleanRow.capitalizationtype,
      rebateValue: parseNumber(cleanRow.rebatevalue),
      pnlStrategyOverride: parseNumber(cleanRow.pnlestrategia)
    }) as TaxaFromSpreadsheet;
  });
};
