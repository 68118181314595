import {
  OCol,
  OModal,
  OModalBody,
  OModalHeader,
  ORow,
  OTypography,
  modalManager,
  ORFieldInput,
  OButton,
  OOption,
  ORFieldSelect,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import { DisverbationSearchProps } from "./search.types";
import {
  DisverbationApprovalsFormValues,
  StatusDisverbation,
} from "../../disverbation-approvals.utils";

const desverbationSearchModalId = "desverbation-search-modal";

export const DesverbationSearch = ({
  onSubmit,
  fundsDesverbation,
}: DisverbationSearchProps) => {
  const { reset } = useFormContext<DisverbationApprovalsFormValues>();
  return (
    <>
      <IconButton
        dataAction="desverbation_search:botao:pesquisar"
        dataLabel="pesquisar"
        icon={{ category: "far", icon: "fa-search" }}
        type="default"
        onClick={() => modalManager.show(desverbationSearchModalId)}
      >
        Pesquisar
      </IconButton>
      {/* <OTooltip>
        <TooltipContent>Limpar pesquisa</TooltipContent>
        <OButton
          dataAction="grid:botao:recarregar_grid"
          dataLabel="recarregar_grid"
          style={{ position: "relative" }}
          type="danger"
          outline
          onClick={() => {
            reset();
            onSubmit();
          }}
        >
          <OIcon category="fas" icon="fa-sync-alt" />
        </OButton>
      </OTooltip> */}

      <OModal id={desverbationSearchModalId}>
        <OModalHeader>
          <OTypography size="lg">Parâmetros de busca</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-4">
            <ORow>
              <OCol xs={12}>
                <ORFieldInput
                  dataAction="find_contract:input:findContractNumber"
                  dataLabel="findContractNumber"
                  id="findContractNumber"
                  name="findContractNumber"
                  label="Número do Contrato:"
                  aspect="flushed"
                  tag="text"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol xs={12}>
                <ORFieldSelect
                  id="findFundName"
                  name="findFundName"
                  dataAction="cancelamento_recebiveis:select:fundo"
                  dataLabel="fundo"
                  label="Selecione um fundo"
                >
                  {fundsDesverbation.map((fund) => (
                    <OOption key={fund.Cge} value={fund.Cge}>
                      {fund.Name}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
            </ORow>
            <ORow>
              <OCol xs={12}>
                <ORFieldSelect
                  id="findStatus"
                  name="findStatus"
                  dataAction="cancelamento_recebiveis:select:status"
                  dataLabel="status"
                  label="Selecione um status"
                >
                  {Object.values(StatusDisverbation).map((status) => (
                    <OOption key={status} value={status}>
                      {status}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
            </ORow>

            <div className="d-flex justify-content-end gap-2">
              <OButton
                dataAction="dashboard_onboardings:botao:pesquisar"
                dataLabel="pesquisar"
                onClick={() => {
                  onSubmit();
                  modalManager.hide(desverbationSearchModalId);
                }}
              >
                Pesquisar
              </OButton>
              <OButton
                dataAction="dashboard_onboardings:botao:limpar"
                dataLabel="limpar"
                type="dark"
                outline
                onClick={() => reset()}
              >
                Limpar
              </OButton>
            </div>
          </div>
        </OModalBody>
      </OModal>
    </>
  );
};
