import { useState } from "react";
import { ActionDisplay, ActionEditor } from "./_compose";

interface ActionComponentProps {
  rule: HubEnergy.DiligenceEventRuleResponse;
  action: HubEnergy.DiligenceEventActionSimpleResponse;
  initialMode: "display" | "editing";
  setActions: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventActionSimpleResponse[]>
  >;
}

export const ActionComponent = ({
  rule,
  action,
  initialMode,
  setActions,
}: ActionComponentProps) => {
  const [mode, setMode] = useState(!action ? "editing" : initialMode);

  if (mode === "display")
    return (
      <ActionDisplay
        action={action}
        setMode={setMode}
        setActions={setActions}
      />
    );

  return (
    <ActionEditor
      rule={rule}
      action={action}
      setMode={setMode}
      setActions={setActions}
    />
  );
};
