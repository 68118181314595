import { env } from "utils/environments";

export const renegotiationEndpoints = {
  salesforce: {
    contracts: {
      endpoint: `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/contracts`,
      availableDates: `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/contracts/available-dates`,
    },
    simulate: `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/simulate`,
    accept: `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce`,
    agreements: {
      endpoint: `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/agreements`,
      byId: {
        endpoint: (agreementId: string) =>
          `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/agreements/${agreementId}`,
        firstPayment: (agreementId: string) =>
          `${env.API_RENEGOTIATION}/v1/renegotiations/salesforce/agreements/${agreementId}/first-payment/send-bank-slip`,
      },
    },
  },
};
