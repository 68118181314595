import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { UsuariosClienteByIdentificationRouteParams } from "../../../../routes/usuarios.route-params";

export const useUserNotifications = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const { callService, hasError, loading, value } = useServiceCall(
    service.clerk.getUsersByTaxId,
  );

  const getUserByTaxId = useCallback(
    () => callService({ taxId: identification }),
    [callService, identification],
  );

  useEffect(() => {
    getUserByTaxId();
  }, [getUserByTaxId]);

  return {
    email: value?.email,
    getUserByTaxId,
    hasError,
    loading,
  };
};
