import { ODataGridGeneratorConfig } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { ComponentProperty } from "../../../component-info.types";
import { ComponentPropertiesGridActions } from "./component-properties-grid-actions.component";

export const componentPropertiesGrid = (
  id: string,
  component: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponentsById>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getComponentsById>
      >["data"]
    >
  >,
  setSelectedProperty: React.Dispatch<
    React.SetStateAction<ComponentProperty | undefined>
  >,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma propriedade configurada",
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        sortOrder: "asc",
      },
      {
        dataField: "type",
        caption: "Tipo",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "isRequired",
        caption: "É obrigatório",
      },
      {
        cellRender: ({ data }) => data.allowedValues?.join(", "),
        caption: "Valores permitidos",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <ComponentPropertiesGridActions
            data={data}
            id={id}
            component={component}
            setSelectedProperty={setSelectedProperty}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    GetApiAdminComponentsByIdResponse["properties"][number]
  >);
