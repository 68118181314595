import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { SelectSearchOption } from "../select-search.types";
import { SelectSearchFieldOption } from "./select-search-field.types";

export const useSelectSearchField = <T>(
  name: string,
  originalOptions: SelectSearchFieldOption<T>[],
) => {
  const form = useFormContext();

  const { setValue } = form;

  const options: SelectSearchOption<T>[] = useMemo(
    () =>
      originalOptions.map((option) => ({
        value: option.value,
        label: option.label,
        customKey: option.customKey,
        onSelect: () => {
          setValue(name, option.value, {
            shouldDirty: true,
            shouldTouch: true,
          });
          option.onSelect?.(option.value);
        },
      })),
    [name, originalOptions, setValue],
  );

  return {
    form,
    options,
  };
};
