import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useUnsafeCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  editTaxasFormDefaultValues,
  editTaxasValidationSchema,
} from "./edit-taxas.form";
import { CurrencyTaxa, EditTaxasForm } from "./edit-taxas.types";
import { scfCalendarMap } from "./taxas-fields.utils";

export const useEditTaxas = (
  estrategia:
    | Omit<
        GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse,
        "configuracoesCadastroRelacionamento" // remove conflict  between types
      >
    | undefined,
) => {
  const { customer } = useUnsafeCustomer();
  const [isEditing, setIsEditing] = useState(false);

  const {
    callService,
    loading: taxasLoading,
    value,
  } = useServiceCall(service.senna.getConfigTaxasByEstrategia);

  const { callService: setConfigTaxas, loading: submitLoading } =
    useServiceCall(service.senna.setConfigTaxas);

  const { callService: updateEstrategiasConfigTaxa, loading: updateLoading } =
    useServiceCall(service.quickfin.updateEstrategiasConfigTaxa);

  const { callService: removeConfigTaxa, loading: removeLoading } =
    useServiceCall(service.senna.removeConfigTaxa);

  const form = useForm<EditTaxasForm>({
    defaultValues: editTaxasFormDefaultValues,
    resolver: yupResolver(editTaxasValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const currencyWatcher = watch("currency");

  const setFormValues = useCallback(
    (taxa: CurrencyTaxa | null) => {
      if (taxa) {
        reset({
          calendar:
            taxa.calendar && taxa.calendar.length > 0 ? taxa.calendar : null,
          capitalizationBase: taxa.capitalizationBase,
          capitalizationType: taxa.capitalizationType,
          casasDecimais: taxa.casasDecimais,
          configType: taxa.configType,
          currency: taxa.currency,
          dataBasePeriod: taxa.dataBasePeriod,
          daysToDataBase: taxa.daysToDataBase,
          forceUpdate: taxa.forceUpdate ? "yes" : "no",
          index: taxa.index,
          rebateBase: taxa.rebateBase,
          rebateType: taxa.rebateType,
          rebateValue: taxa.rebateValue,
          limitFutureDays: taxa.limitFutureDays,
          taxasColunas: taxa.emailsTaxas.colunas,
          emailsTaxas: taxa.emailsTaxas.emails?.map((email) => ({ email })),
          taxaPrazos: taxa.taxaPrazos,
          vigency: taxa.vigency,
        } as EditTaxasForm);
      } else {
        const defaultCalendar =
          getValueFromMap(scfCalendarMap, estrategia?.tipoProduto) ?? "";

        reset({
          currency: currencyWatcher,
          ...editTaxasFormDefaultValues,
          calendar: defaultCalendar,
        });
      }
    },
    [currencyWatcher, estrategia?.tipoProduto, reset],
  );

  const isValidCalendar = useCallback(
    (values: EditTaxasForm) => {
      const isDefaultCalendar = !values.calendar;

      if (values.currency !== "BRL" || estrategia?.tipoProduto === "CCC")
        return isDefaultCalendar;

      if (estrategia?.estrategia.habilitaPagFor || estrategia?.estrategia.habilitaPagForBanking)
        return isDefaultCalendar || values.calendar === "SCF";

      return true;
    },
    [estrategia?.estrategia.habilitaPagFor, estrategia?.estrategia.habilitaPagForBanking, estrategia?.tipoProduto],
  );

  const submit = useCallback(
    (updateEstrategias: boolean) =>
      handleSubmit((values) => {
        if (!estrategia?.uniqueId)
          return OToastManager.danger("Erro inesperado: sem estratégia");

        if (!isValidCalendar(values))
          return OToastManager.danger("Calendário inválido");

        const configTaxa = {
          currency: values.currency,
          configType: values.configType,
          index: values.index,
          capitalizationType: values.capitalizationType,
          casasDecimais: values.casasDecimais,
          capitalizationBase: values.capitalizationBase,
          calendar:
            values.calendar && values.calendar.length > 0
              ? values.calendar
              : null,
          daysToDataBase: values.daysToDataBase,
          dataBasePeriod: values.dataBasePeriod,
          rebateBase: values.rebateBase,
          rebateType: values.rebateType,
          rebateValue: values.rebateValue,
          forceUpdate: values.forceUpdate === "yes",
          convenioExternalId: estrategia.uniqueId,
          indexBase: "fptp",
          limitFutureDays: values.limitFutureDays,
          taxaPrazos: values.taxaPrazos,
          emailsTaxas: {
            colunas: values.taxasColunas,
            emails: values.emailsTaxas?.map(({ email }) => email),
            sacadoName: customer?.officialName ?? "",
          },
          taxasColunas: values.taxasColunas,
          taxasEmails: values.emailsTaxas?.map(({ email }) => email),
          vigency: values.vigency,
        };

        if (
          configTaxa.taxaPrazos[0]?.fromDay === null &&
          configTaxa.taxaPrazos[0]?.fromDate === null
        ) {
          configTaxa.taxaPrazos.pop();
        }

        setConfigTaxas(configTaxa).then(({ success }) => {
          if (success) OToastManager.success("Taxa salva com sucesso");
          else OToastManager.danger("Erro ao salvar a taxa");
        });

        if (updateEstrategias) {
          updateEstrategiasConfigTaxa(estrategia.id, { configTaxa }).then(
            ({ success }) => {
              if (success)
                OToastManager.success("Estratégias atualizadas com sucesso");
              else OToastManager.danger("Erro atualizar as estratégias");
            },
          );
        }
      }),
    [
      customer?.officialName,
      estrategia?.id,
      estrategia?.uniqueId,
      handleSubmit,
      isValidCalendar,
      setConfigTaxas,
      updateEstrategiasConfigTaxa,
    ],
  );

  const remove = useCallback(async () => {
    if (!estrategia?.uniqueId)
      return OToastManager.danger("Erro inesperado: sem estratégia");

    const { success } = await removeConfigTaxa(
      estrategia.uniqueId,
      currencyWatcher,
    );

    if (success) {
      OToastManager.success("Taxa excluída com sucesso");
      callService(estrategia.uniqueId);
    } else OToastManager.danger("Não foi possível excluir a taxa");
  }, [callService, currencyWatcher, estrategia?.uniqueId, removeConfigTaxa]);

  useEffect(() => {
    if (estrategia?.uniqueId) callService(estrategia.uniqueId);
  }, [callService, estrategia?.uniqueId]);

  useEffect(() => {
    if (value?.configTaxas?.[0]) {
      const brlConfig = value?.configTaxas.find(
        (taxa) => taxa.currency === "BRL",
      );
      setFormValues(brlConfig ?? value.configTaxas[0]);
      setIsEditing(true);
    } else {
      setFormValues(null);
      setIsEditing(false);
    }
  }, [setFormValues, value]);

  useEffect(() => {
    if (currencyWatcher && value) {
      const currencyTaxa = value.configTaxas.find(
        (taxa) => taxa.currency === currencyWatcher,
      );

      if (currencyTaxa) {
        setFormValues(currencyTaxa);
        setIsEditing(true);
      } else {
        setFormValues(null);
        setIsEditing(false);
      }
    }
  }, [currencyWatcher, setFormValues, value]);

  return {
    form,
    isEditing,
    loading: taxasLoading,
    remove,
    removeLoading,
    submit,
    submitLoading,
    updateLoading,
  };
};
