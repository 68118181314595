import { ApiCodeErrorText } from "../chargeback/chargeback.types";

export const MessageFromResponseErrorData = (data: {
  Message: string;
  Details: { code: string; message: string };
}): string => {
  const code = data.Details.code;
  return ApiCodeErrorText[code];
};

export const ChargebackStatusFromTransactionsId = (
  id: string,
  transactionsIds: string[],
): { status: boolean; message: string } => {
  const status = transactionsIds.includes(id);
  const message = status
    ? "Transação com contestação pendente"
    : "Abrir contestação da transação";
  return { status, message };
};

export const CreateFormData = async (
  attendantUsername: string,
  attendantName: string,
  ticketId: string,
  ticketDate: string,
  companyDocument: string,
  externalCreditAccountId: string,
  externalAuthorizationId: string,
  description: string,
  decisionFlow: string,
  files: Array<File>,
) => {
  const bodyFormData = new FormData();
  bodyFormData.append("attendantUsername", attendantUsername);
  bodyFormData.append("attendantName", attendantName);
  bodyFormData.append("ticketId", ticketId);
  bodyFormData.append("ticketDate", ticketDate);
  bodyFormData.append("companyDocument", companyDocument);
  bodyFormData.append("externalCreditAccountId", externalCreditAccountId);
  bodyFormData.append("externalAuthorizationId", externalAuthorizationId);
  bodyFormData.append("description", description);
  bodyFormData.append("decisionFlow", decisionFlow);
  await files.forEach((file) => {
    bodyFormData.append("files", file);
  });
  return bodyFormData;
};
