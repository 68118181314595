import { TooltipIcon } from "components/tooltip-icon";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  onboardingStatusMap,
  onboardingStatusTooltipMap,
} from "./onboarding-status-tooltip.utils";

interface OnboardingStatusTooltipProps {
  onboardingStatus?: string | null;
}

export const OnboardingStatusTooltip = ({
  onboardingStatus,
}: OnboardingStatusTooltipProps) => {
  const tooltipContent = getValueFromMap(
    onboardingStatusTooltipMap,
    onboardingStatus,
  );

  const textContent =
    getValueFromMap(onboardingStatusMap, onboardingStatus) || onboardingStatus;

  return (
    <span className="d-flex gap-2">
      {textContent}
      {tooltipContent && <TooltipIcon content={tooltipContent} />}
    </span>
  );
};
