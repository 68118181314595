import { useEffect, useRef, useState } from "react";
import {
  FullcreenContainerDiv,
  transitionDurationMs,
} from "./fullscreen-container.styles";
import { FullscreenCallback, OldInset } from "./fullscreen-container.types";
import {
  setInsetZero,
  setStyle,
  unsetStyle,
} from "./fullscreen-container.utils";

interface FullscreenContainerProps {
  callbackRef: React.MutableRefObject<FullscreenCallback | null>;
  children: React.ReactNode;
  className?: string;
}

export const FullscreenContainer = ({
  callbackRef,
  children,
  className,
}: FullscreenContainerProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [oldInset, setOldInset] = useState({} as OldInset);

  const containerRef = useRef<HTMLDivElement>(null);
  const dummyRef = useRef<HTMLDivElement>(null);

  const callback = () => {
    if (!containerRef.current) return;

    const screenWidth = document.documentElement.clientWidth;
    const screenHeight = document.documentElement.clientHeight;

    if (isFullscreen) {
      // set old inset (non-fullscreen) values
      setStyle(containerRef.current, {
        padding: "", // clear set value
        top: oldInset.top,
        left: oldInset.left,
        bottom: oldInset.bottom,
        right: oldInset.right,
        "background-color": "unset",
        "z-index": "",
      });

      // disable overflow in containerDiv and enable in body
      document.body.style.overflow = "";
      unsetStyle(containerRef.current, ["overflow"]);

      // wait until css transition finished
      setTimeout(() => {
        // clear styles
        unsetStyle(dummyRef.current, ["width", "height"]);
        unsetStyle(containerRef.current, ["position", "inset"]);
      }, transitionDurationMs);
    } else {
      // get position of container div
      const rect = containerRef.current.getBoundingClientRect();

      // set dummy div's size to the same of container div
      // to keep scroll position
      setStyle(dummyRef.current, {
        width: rect.width,
        height: rect.height,
      });

      // change position to fixed, but set inset values
      // to the current position
      setStyle(containerRef.current, {
        position: "fixed",
        padding: "1rem",
        top: rect.top,
        left: rect.left,
        bottom: screenHeight - rect.bottom,
        right: screenWidth - rect.right,
        "background-color": "var(--theme-background)",
        "z-index": "10",
      });

      // save current inset (non-fullscreen)
      setOldInset({
        top: rect.top,
        left: rect.left,
        bottom: screenHeight - rect.bottom,
        right: screenWidth - rect.right,
      });

      // enable overflow in containerDiv and disable in body
      containerRef.current.style.overflow = "auto";
      document.body.style.overflow = "hidden";

      // timeout necessary to not skip transition
      setTimeout(() => {
        // set inset 0 to go fullscreen
        setInsetZero(containerRef.current);
      }, 1);
    }

    const newState = !isFullscreen;

    setIsFullscreen(newState);

    // return state to be used by FullscreenButton
    return newState;
  };

  // pass callback to FullscreenButton
  // eslint-disable-next-line no-param-reassign
  callbackRef.current = callback;

  // reset overflow when component is unmounted
  useEffect(
    () => () => {
      document.body.style.overflow = "";
    },
    [],
  );

  return (
    <>
      <FullcreenContainerDiv ref={containerRef} className={className}>
        {/* <FullscreenButton> */}
        {children}
      </FullcreenContainerDiv>
      <div ref={dummyRef} className="dummy-div" />
    </>
  );
};
