import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { CompanySignatories } from "./contract-signatories.types";

export const dataSource = dataSourceCustomStoreGenerator<CompanySignatories>(
  () =>
    service.quickfin
      .getContractSignatories()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar as empresas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const contractSignatoriesGrid: ODataGridGeneratorConfig<CompanySignatories> =
  {
    datagrid: {
      noDataText: "Nenhuma empresa.",
      filterRow: { visible: true },
    },
    columns: [
      {
        role: roles.antecipacaoDeCartoes.product.contractSignatoriesEditPage
          .role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.antecipacaoDeCartoes.product.contractSignatories.edit.path(
              { taxId: data.companyIdentification },
            )}
          />
        ),
      },
      {
        dataField: "companyName",
        dataType: "string",
        caption: "Nome",
      },
      {
        dataField: "companyIdentification",
        dataType: "string",
        caption: "CNPJ/CPF",
        customizeText: ({ value }) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "users",
        caption: "Assinantes",
        calculateCellValue: ({ users }) =>
          users.map((taxId) => masks.cpf(taxId)).join(", "),
      },
    ],
  };
