import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ProcessorConfigViewResponse } from "services/hubcreditmanager/models/responses/get-admin-processor-config-monitoring.response";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../routes/workflow.route-params";

export const useProcessorConfigMonitoringPage = () => {
  const { processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();

  const [loading, setLoading] = useState(false);
  const [workflowConfigViews, setWorkflowConfigViews] = useState<
    ProcessorConfigViewResponse[]
  >([]);

  const getMonitoring = useCallback(async () => {
    try {
      if (!processorId) return;
      setLoading(true);

      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsMonitoring(
          processorId,
        );

      setWorkflowConfigViews(data);
    } catch (err) {
      OToastManager.danger(
        "Erro para retornar as configurações de monitoramento.",
      );
    } finally {
      setLoading(false);
    }
  }, [processorId]);

  useEffect(() => {
    getMonitoring();
  }, [getMonitoring]);

  return {
    workflowConfigViews,
    loading,
  };
};
