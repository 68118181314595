import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import { service } from "services";

const modalId = "execute-batch-imports-confirmation-modal";

interface ExecuteBatchButtonProps {
  gridRef: React.RefObject<
    DataGrid<HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem>
  >;
  reload: () => void;
}

export const ExecuteBatchButton = ({
  gridRef,
  reload,
}: ExecuteBatchButtonProps) => {
  const [loading, setLoading] = useState(false);

  const { callService } = useServiceCall(
    service.hubEnergy.executeConsumerUnitsImport,
  );

  const executeImportData = useCallback(async () => {
    const selected =
      (await gridRef.current?.instance.getSelectedRowsData()) ?? [];

    if (!selected.length)
      return OToastManager.warning("Nenhuma importação selecionada");

    if (
      selected.some(
        ({ status }) => status === "IMPORTADO" || status === "PROCESSANDO",
      )
    )
      return OToastManager.warning(
        "Só é possível executar importações com status PENDENTE_CONFIRMACAO",
      );

    const promises = selected.map(({ id }) =>
      callService({ consumerUnitsImportDataId: id }),
    );
    setLoading(true);
    try {
      const results = await Promise.all(promises);
      if (results.some((r) => !r.response?.data?.success))
        throw new Error("Some imports failed");
      OToastManager.success("As importações foram executadas com sucesso");
    } catch (err) {
      OToastManager.warning("Erro ao executar as importações");
    } finally {
      setLoading(false);
      reload();
    }
  }, [callService, gridRef, reload]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-play" }}
        loading={loading}
        onClick={() => modalManager.show(modalId)}
      >
        Executar
      </IconButton>
      <OConfirmationModal
        modalId={modalId}
        title="Executar importações"
        divider={false}
        onConfirm={() => {
          executeImportData();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja executar as importações selecionadas?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
