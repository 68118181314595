import {
  OCollapse,
  OCollapseContent,
  OCollapseHeader,
  OTypography,
} from "@maestro/react";
import { PropsWithChildren } from "react";

export const WrapCollapse: React.FC<
  PropsWithChildren & { expanded?: boolean }
> = ({ children, expanded = false }) => {
  if (expanded) {
    return (
      <>
        <OTypography>Variáveis</OTypography>
        <div>{children}</div>
      </>
    );
  }

  return (
    <OCollapse>
      <OCollapseHeader>
        <OTypography>Variáveis</OTypography>
      </OCollapseHeader>
      <OCollapseContent>{children}</OCollapseContent>
    </OCollapse>
  );
};
