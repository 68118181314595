import { OButton, OSkeletonCard, OTypography } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { CancelFloatingModal } from "./_compose/cancel-floating-modal";
import { FloatingSettingsModal } from "./_compose/floating-settings-modal";
import { FloatingDetailsProps } from "./floating-details.types";
import { assembleFloatingActions } from "./floating-details.utils";

export const FloatingDetails = ({
  handleUpdate,
  isLoading,
  floatingInDays,
}: FloatingDetailsProps) => {
  const { hasRole } = useRoles();

  const showFloatingActions = useMemo(
    () => hasRole(roles.banking.customer.beneficiaryFloatingActions.role),
    [hasRole],
  );

  const actions = useMemo(
    () => assembleFloatingActions(floatingInDays),
    [floatingInDays],
  );

  return (
    <div>
      {showFloatingActions && (
        <>
          <FloatingSettingsModal
            currentFloating={floatingInDays}
            handleSuccess={handleUpdate}
          />
          <CancelFloatingModal handleSuccess={handleUpdate} />
        </>
      )}

      <div className="d-flex flex-column gap-3">
        <OTypography type="dark" size="lg">
          Floating
        </OTypography>

        <div className="d-flex flex-column gap-2 w-50">
          <OTypography type="dark">Quantidade em dias</OTypography>
          {isLoading ? (
            <OSkeletonCard height="1rem" width="4rem" rounded />
          ) : (
            <OTypography type="dark-80" key={floatingInDays}>
              {floatingInDays ?? "-"}
            </OTypography>
          )}
        </div>

        {showFloatingActions && !isLoading && (
          <div className="d-flex align-items-center gap-3">
            {actions.map(({ label, onClick }) => (
              <OButton key={label} onClick={onClick} type="default" outline>
                {label}
              </OButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
