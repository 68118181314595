import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";

interface SyncOnboardingWorkflowProps {
  workflowUuid: string;
  modalId: string;
}

export const SyncOnboardingWorkflow = ({
  workflowUuid,
  modalId,
}: SyncOnboardingWorkflowProps) => {
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      await service.onboarding.syncWorkflow({
        workflowUuid,
      });

      OToastManager.info("Onboarding sincronizado com sucesso.");

      modalManager.hide(modalId);
    } catch (error) {
      OToastManager.warning("Erro ao sincronizar onboarding.");
    } finally {
      setLoading(false);
    }
  }, [modalId, workflowUuid]);

  return (
    <OConfirmationModal
      modalId={modalId}
      size="sm"
      title="Sincronizar o workflow?"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      onConfirm={submit}
      loading={loading}
    >
      <div> {`Id: ${workflowUuid}`}</div>
    </OConfirmationModal>
  );
};
