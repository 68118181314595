import { useGridRef } from "hooks/grid-ref";
import { PageTitle } from "components/page-title";
import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { useCustomer } from "contexts/customer";
import { useMemo } from "react";
import {
  buildDataSourceByTenant,
  resourceListGrid,
} from "./listagem-resources.grid";

export const ResourceByTenantList = () => {
  const gridRef = useGridRef();
  const {
    customer: { identification },
  } = useCustomer();

  const dataSource = useMemo(
    () => buildDataSourceByTenant(identification),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de consentimentos de recursos" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={resourceListGrid(dataSource)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
