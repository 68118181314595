import { ValidationRule } from "devextreme/common";

export const numericRule = (): ValidationRule => {
  return { type: "numeric", ignoreEmptyValue: true };
};

export const checkIfNullRule = (field: string): ValidationRule => {
  return {
    type: "custom",
    message:
      'Este campo não pode ser nulo se a frequência de amortização for do tipo "Mensal"',
    validationCallback: (e) => {
      if (e.data.amortizationFrequency === "MENSAL" && e.data[field] === null)
        return false;
      return true;
    },
  };
};

export const greaterThanZeroRule = (field: string): ValidationRule => {
  return {
    type: "custom",
    message: "Este campo deve conter um número maior do que 0.",
    ignoreEmptyValue: true,
    validationCallback: (e) => {
      if (e.data[field] <= 0) return false;
      return true;
    },
  };
};

export const greaterThanOrEqualZeroRule = (field: string): ValidationRule => {
  return {
    type: "custom",
    message: "Este campo deve conter maior do que ou igual a 0.",
    validationCallback: (e) => {
      if (e.data[field] < 0) return false;
      return true;
    },
  };
};

export const upToTwoDecimalsRule = (): ValidationRule => {
  return {
    type: "custom",
    message: "O campo de taxa só pode conter até 2 casas decimais.",
    validationCallback: (e) => {
      const monthlyValueAux = String(e.data.monthlyValue).split(".");
      if (monthlyValueAux.length === 2 && monthlyValueAux[1].length > 2)
        return false;
      return true;
    },
  };
};

export const greaterThanOrEqualRule = (): ValidationRule => {
  return {
    type: "custom",
    message: '"O campo "Até" não pode ser menor do que o respectivo campo "De"',
    ignoreEmptyValue: true,
    validationCallback: (e) => {
      if (e.value < e.data.minTerm) return false;
      return true;
    },
  };
};
