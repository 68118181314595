import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import dayjs from "dayjs";
import { useBankslipContext } from "../../bankslip.context";
import { MapperCollectionStatus } from "../../bankslip.utils";
import { DetailLine } from "./_compose/detail-line";
import { useBankslipDetails } from "./bankslip-details-drawer.hook";
import { BankslipDetailsDrawerId } from "./bankslip-details-drawer.types";
import {
  assembleFees,
  assembleInterest,
} from "./bankslip-details-drawer.utils";

export const BankslipDetailsDrawer = () => {
  const { detailedBankslip } = useBankslipContext();
  const { isLoading, validOnCIP } = useBankslipDetails();

  return (
    <OModal id={BankslipDetailsDrawerId}>
      <OModalHeader closeButton>
        <OTypography type="dark" size="lg">
          Detalhes do boleto
        </OTypography>
      </OModalHeader>

      <OModalBody>
        {!!detailedBankslip && (
          <div className="d-flex flex-column gap-3">
            <DetailLine
              title="Linha digitável"
              content={
                detailedBankslip.detail.digitableLine
                  ? masks.generic(detailedBankslip.detail.digitableLine, {
                      mask: "00000.00000 00000.000000 00000.000000 0 00000000000000",
                    })
                  : "-"
              }
            />
            <DetailLine
              title="Nº Documento"
              content={detailedBankslip.detail.documentNumber}
            />
            <DetailLine
              title="Nosso número"
              content={detailedBankslip.detail.ourNumber ?? "-"}
            />
            <DetailLine
              title="Pagador"
              content={[
                cases.title(detailedBankslip.payer.name),
                masks.cpfCnpj(detailedBankslip.payer.taxId),
              ]}
            />
            <DetailLine
              title="Status Boleto"
              content={MapperCollectionStatus[detailedBankslip.status].label}
            />
            <DetailLine
              title="Data de criação"
              content={dayjs(detailedBankslip.createdAt).format("DD/MM/YYYY")}
            />
            <DetailLine
              title="Data de vencimento"
              content={dayjs(detailedBankslip.dueDate).format("DD/MM/YYYY")}
            />
            <DetailLine
              title="Valor do boleto"
              content={masks.currency(detailedBankslip.amount, "R$")}
            />
            <DetailLine
              title="Valor liquidado"
              content={
                detailedBankslip.amountPaid
                  ? masks.currency(detailedBankslip.amountPaid, "R$")
                  : "-"
              }
            />
            <DetailLine
              title="Juros"
              content={
                assembleInterest(detailedBankslip.interest) ?? "Sem juros"
              }
            />
            <DetailLine
              title="Multa"
              content={assembleFees(detailedBankslip.fine) ?? "Sem multa"}
            />
            <DetailLine
              title="Desconto"
              content={
                detailedBankslip.discounts?.length
                  ? assembleFees(detailedBankslip.discounts[0]) ??
                    "Sem desconto"
                  : "Sem desconto"
              }
            />
            <DetailLine
              title="Instruções da cobrança"
              content={detailedBankslip.description ?? "-"}
            />
            <DetailLine
              title="Válido para pagamento na CIP"
              content={validOnCIP ? "Sim" : "Não"}
              isLoading={isLoading}
              omitDivider
            />
          </div>
        )}
      </OModalBody>

      <OModalFooter>
        <div className="d-flex justify-content-end">
          <OButton
            dataAction=""
            dataLabel=""
            onClick={() => modalManager.hide(BankslipDetailsDrawerId)}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
