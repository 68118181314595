import {
  OCard,
  OCardBody,
  ODivider,
  OSkeletonCard,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { StatusBadge } from "components/status-badge";
import { useCallback, useMemo } from "react";
import { FloatingDetails } from "./_compose/floating-details";
import { useBeneficiaryStatusCard } from "./beneficiary-status-card.hook";
import { MapperBeneficiaryStatusBadge } from "./beneficiary-status-card.utils";

export const BeneficiaryStatusCard = () => {
  const { beneficiary, errorMessage, getBeneficiaryInformation, isLoading } =
    useBeneficiaryStatusCard();

  const [name, fantasyName, taxId] = useMemo(() => {
    if (errorMessage) return ["-", "-", "-"];

    return [
      beneficiary?.companyName ?? "",
      beneficiary?.fantasyName ?? "",
      masks.cpfCnpj(beneficiary?.taxId ?? ""),
    ];
  }, [beneficiary, errorMessage]);

  const renderStatus = useCallback(() => {
    if (isLoading) return <OSkeletonCard height="1rem" width="10rem" rounded />;

    if (errorMessage || !beneficiary?.status)
      return (
        <OTypography type="dark-80" key={errorMessage}>
          {errorMessage}
        </OTypography>
      );

    const { label, type } = MapperBeneficiaryStatusBadge[beneficiary?.status];
    return (
      <StatusBadge type={type} key={beneficiary.status}>
        {label}
      </StatusBadge>
    );
  }, [beneficiary?.status, errorMessage, isLoading]);

  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-3">
        <OTypography type="dark" size="lg">
          Beneficiário
        </OTypography>

        <div className="d-flex align-items-center gap-3">
          <div className="d-flex flex-column gap-2 w-50">
            <OTypography type="dark">Razão Social</OTypography>
            {isLoading ? (
              <OSkeletonCard height="1rem" width="20rem" rounded />
            ) : (
              <OTypography type="dark-80" key={name}>
                {name}
              </OTypography>
            )}
          </div>
          <div className="d-flex flex-column gap-2 w-50">
            <OTypography type="dark">Nome fantasia</OTypography>
            {isLoading ? (
              <OSkeletonCard height="1rem" width="20rem" rounded />
            ) : (
              <OTypography type="dark-80" key={fantasyName}>
                {fantasyName}
              </OTypography>
            )}
          </div>
        </div>

        <div className="d-flex align-items-center gap-3">
          <div className="d-flex flex-column gap-2 w-50">
            <OTypography type="dark">CPF/CNPJ</OTypography>
            {isLoading ? (
              <OSkeletonCard height="1rem" width="10rem" rounded />
            ) : (
              <OTypography type="dark-80" key={taxId}>
                {taxId}
              </OTypography>
            )}
          </div>
          <div className="d-flex flex-column gap-1 w-50">
            <OTypography type="dark">Status</OTypography>
            {renderStatus()}
          </div>
        </div>

        <ODivider size="xxs" />

        <FloatingDetails
          floatingInDays={beneficiary?.floatingInDays}
          handleUpdate={getBeneficiaryInformation}
          isLoading={isLoading}
        />
      </OCardBody>
    </OCard>
  );
};
