import { ORFieldCheckboxGroup, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useCustomer } from "contexts/customer";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { Actions } from "./_compose";
import {
  ProfileSelectorProvider,
  useProfileSelector,
} from "./profile-selector.context";
import { buildDataSource, roleAssingmentsGrid } from "./profile-selector.grid";

interface ProfileSelectorComponentProps {
  setCeilingVigencyDate: (maxVigency: string) => void;
}

const ProfileSelectorComponent = ({
  setCeilingVigencyDate,
}: ProfileSelectorComponentProps) => {
  const { gridRef, selected, setProfiles } = useProfileSelector();

  const {
    customer: { identification },
  } = useCustomer();

  const dataSource = useMemo(
    () => buildDataSource(identification, setProfiles, setCeilingVigencyDate),
    [identification, setCeilingVigencyDate, setProfiles],
  );

  return (
    <div className="mt-2">
      <OTypography size="lg">
        Selecione as funções para este usuário
      </OTypography>
      <OTypography key={JSON.stringify(selected)}>
        Selecionados: {selected || "---"}
      </OTypography>
      <GridTemplate
        actions={<Actions />}
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
      >
        <ORFieldCheckboxGroup
          dataAction="grid:checkbox:funcoes"
          dataLabel="funcoes"
          name="roles"
          id="roles"
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={roleAssingmentsGrid}
            dataSource={dataSource}
          />
        </ORFieldCheckboxGroup>
      </GridTemplate>
    </div>
  );
};

export const ProfileSelector = (props: ProfileSelectorComponentProps) => (
  <ProfileSelectorProvider>
    <ProfileSelectorComponent {...props} />
  </ProfileSelectorProvider>
);
