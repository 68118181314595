import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetAgreementsResponse } from "services/renegotiation/models";
import { AgreementHistory } from "services/renegotiation/models/types";
import dayjs from "dayjs";

const buildDetailsLink = (data: AgreementHistory) => {
  const { routes } = corporateRouter;
  const baseUrl = routes.renegociacao.customer.monitor.history.details.path;

  const stringifiedContract = JSON.stringify(data);
  const searchParams = new URLSearchParams();
  searchParams.set("contract-data", stringifiedContract);

  return baseUrl + "?" + searchParams.toString();
};

export const grid: ODataGridGeneratorConfig<
  GetAgreementsResponse["items"][number]
> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.renegociacao.customer.monitorHistoryDetailsPage.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
    },
    { dataField: "contract", caption: "Contrato" },
    {
      dataField: "created_in",
      caption: "Renegociado em",
      cellRender: ({ data }) => dayjs(data.created_in).format("DD/MM/YYYY"),
    },
    {
      dataField: "total_value",
      caption: "Valor total",
      cellRender: ({ data }) => masks.currency(data.total_value, "R$", "."),
    },
    {
      dataField: "total_value_with_discount",
      caption: "Valor com desconto",
      cellRender: ({ data }) =>
        masks.currency(data.total_value_with_discount, "R$", "."),
    },
    { dataField: "status", caption: "Status" },
  ],
};
