import { currencySymbol } from "../../../../../../utils/currency";
import { Currency } from "../../cards/cards.types";

export function MoneyMask(
  value: number | string | undefined | null,
  currency?: Currency | undefined | null,
  notation?: "standard" | "compact",
): string {
  let amount: number;
  if (
    value === undefined ||
    value === null ||
    Number.isNaN(parseFloat(value as any))
  ) {
    amount = 0;
  } else if (typeof value !== "number") {
    amount = parseFloat(value);
    if (Number.isNaN(amount)) {
      return "Sem valor";
    }
  } else {
    amount = value;
  }
  if (currency)
    return amount
      .toLocaleString("pt-BR", { style: "currency", currency, notation })
      .replace(currency, currencySymbol[currency]);

  return amount
    .toLocaleString("pt-BR", { style: "currency", currency: Currency.BRL })
    .replace(Currency.BRL, currencySymbol.BRL);
}

export function MoneyMaskWithoutCurrency(
  value: number | string | undefined | null,
): string {
  return MoneyMask(value, Currency.BRL).replace("R$ ", "");
}
