import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useAdequacyOptionSelector } from "./adequacy-option-selector.hook";

interface AdequacyOptionSelectorProps {
  id: string;
}

export const AdequacyOptionSelector = ({ id }: AdequacyOptionSelectorProps) => {
  const { adequacyOptions, hasFetchError, loading, getAdequacyOptions } =
    useAdequacyOptionSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={adequacyOptions}
        id={id}
        name="adequacyOptionId"
        label="Adequadora"
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getAdequacyOptions} />
      )}
    </div>
  );
};
