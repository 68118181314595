import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { rejectedOptinsGrid } from "./optins-rejeitados.grid";
import { ConfirmationModal } from "./confirmation-modal.component";
import { RejectedOptinContextProvider } from "./optins-rejeitados.context";

export const OptinsRejeitados = () => {
  const gridRef = useGridRef();

  return (
    <RejectedOptinContextProvider>
      <GridTemplate
        pageTitle={
          <PageTitle
            title="Optins rejeitados"
            description="Faça a consulta de empresas cujos Optins foram 100% rejeitados pela registradora"
          />
        }
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator gridRef={gridRef} grid={rejectedOptinsGrid} />
      </GridTemplate>
      <ConfirmationModal />
    </RejectedOptinContextProvider>
  );
};
