const dateOptions = {
  dateStyle: "short",
} as const satisfies Intl.DateTimeFormatOptions;

const dateTimeOptions = {
  dateStyle: "short",
  timeStyle: "short",
} as const satisfies Intl.DateTimeFormatOptions;

const formatter =
  (options: Intl.DateTimeFormatOptions) =>
  (
    strDate: string | null | undefined,
    customOptions?: Intl.DateTimeFormatOptions,
    locale = "pt-BR",
  ) => {
    if (!strDate) return "";
    const mergedOptions = { ...options, ...customOptions };
    const { format } = new Intl.DateTimeFormat(locale, mergedOptions);
    return format(new Date(strDate));
  };

/**
 * Use `dayjs`
 * @deprecated
 */
export const format = {
  dateTime: formatter(dateTimeOptions),
  date: formatter(dateOptions),
  custom: formatter({}),
};
