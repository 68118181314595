export const assembleDescription = (
  disabled: boolean,
  discountValidityInDays: number | string = 0,
) => {
  if (disabled) return;

  let daysLabel = "";

  const daysCount = +discountValidityInDays;

  if (!daysCount) {
    daysLabel = "até o dia do vencimento";
  } else {
    daysLabel = `a ${daysCount} ${
      daysCount === 1 ? "dia" : "dias"
    } do vencimento ou antes`;
  }

  return `O desconto será para boletos pagos ${daysLabel}`;
};
