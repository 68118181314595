import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { bankslipPaymentsGrid } from "./bankslip-payments.grid";

interface BankslipPaymentsProps {
  status: "sent" | "scheduled";
}

export const BankslipPayments = ({ status }: BankslipPaymentsProps) => {
  const gridRef = useGridRef();
  const dataSource = dataSourceCustomStoreGenerator<BankingHub.BankSlipPayment>(
    () => {
      const params = {
        status,
      };
      return service.bankinghub
        .getBankslipPayments(params)
        .then(({ data }) => data.payments ?? [])
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar os pagamentos";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
  );
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pagamentos" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={bankslipPaymentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
