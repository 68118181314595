import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

type Documents = Awaited<
  ReturnType<typeof service.hubCreditManager.getConsumerUnitDetails>
>["data"]["documents"];

export const mapDocuments = (documents: Documents) =>
  documents?.flatMap((d) =>
    d.files.map((file) => ({ ...file, group: d.type, documentId: d.id })),
  );

export const documentsGrid = {
  datagrid: {
    noDataText: "Nenhum documento encontrado",
  },
  columns: [
    {
      caption: "Tipo de documento",
      dataField: "group",
    },
    {
      caption: "Arquivo",
      dataField: "name",
    },
    {
      type: "buttons",
      caption: "Ações",
      buttons: [
        {
          hint: "Baixar",
          icon: "fa fa-download",
          onClick: async ({ row }) => {
            try {
              if (!row?.data.key) return;

              OToastManager.info("Baixando arquivo...");

              const { data: blob } = await service.hubCreditManager.download({
                key: row?.data.key,
              });

              helpers.downloadBlobFile(row?.data.name ?? "", blob);
              OToastManager.destroy();
            } catch {
              OToastManager.danger("Houve um erro ao baixar o arquivo.");
            }
          },
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<ReturnType<typeof mapDocuments>[number]>;
