export function extractAgencyAccount(str: string): {
  agency: string,
  account: string
} {
  const isZeroEnding = str.endsWith("0");
  const agency = isZeroEnding ? str.slice(-2) : str.slice(-1);
  const account = isZeroEnding ? str.slice(0, -2) : str.slice(0, -1);

  const normalizedAgency = (agency === "01") ? "1" : agency;

  return {
    agency: normalizedAgency,
    account,
  };
}