import { BankIconSVG } from "../financial-institution-icon.types";

export const BankOriginalSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#01A858" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.433 48.636h.354c.908 0 1.64.73 1.64 1.639v19.933c0 .62-.355 1.152-.842 1.44L57.11 85.778a1.732 1.732 0 01-.864.222c-.288 0-.598-.066-.864-.221L30.887 71.648a1.64 1.64 0 01-.842-1.44V50.275c0-.908.731-1.64 1.64-1.64h.354c.908 0 1.639.732 1.639 1.64v18.782L56.247 82.08l22.57-13.023V50.275a1.603 1.603 0 011.616-1.64z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.117 26.377a1.684 1.684 0 012.126 0l23.943 13.842c.133.045.266.133.377.222.553.31.841.908.82 1.506a1.667 1.667 0 01-.82 1.506 2.12 2.12 0 01-.377.221L57.244 57.517a1.684 1.684 0 01-2.126 0l-23.92-13.865a2.051 2.051 0 01-.377-.221c-.554-.31-.842-.908-.82-1.506a1.667 1.667 0 01.82-1.506c.11-.089.243-.155.376-.222l23.92-13.82zm1.086 27.53l20.73-11.982-20.73-11.983-20.731 11.983 20.73 11.982z"
        fill="#fff"
      />
    </svg>
  );
};
