import { OLoader, OTypography } from "@maestro/react";
import {
  PaginationButton,
  PaginationDescription,
} from "./custom-pagination.styles";
import { CustomPaginationProps, DOTS } from "./custom-pagination.utils";
import { useCustomPagination } from "./custom-pagination.hook";

export const CustomPagination = ({
  totalCount,
  currentPage = 1,
  setCurrentPage,
  pagination = [10, 20, 30],
  selectedPaginationSize = 10,
  setSelectedPaginationSize,
  loading = false,
}: CustomPaginationProps) => {
  const { pageCount, paginationRange } = useCustomPagination(
    totalCount,
    currentPage,
    setCurrentPage,
    selectedPaginationSize,
  );

  return (
    <div className="d-flex flex-row justify-content-between mt-2">
      <div className="d-flex flex-row gap-2">
        {pagination.map((paginationSize) => (
          <PaginationButton
            key={paginationSize}
            selected={paginationSize === selectedPaginationSize}
            onClick={() => setSelectedPaginationSize(paginationSize)}
          >
            {paginationSize}
          </PaginationButton>
        ))}
      </div>
      <div className="d-flex flex-row gap-2 align-items-center">
        <PaginationDescription>
          {loading
            ? "Carregando paginação"
            : `Página ${currentPage} de ${pageCount} (${totalCount} títulos)`}
        </PaginationDescription>
        {loading ? (
          <OLoader size="xs" />
        ) : (
          paginationRange.map((num, index) =>
            num === DOTS ? (
              <OTypography key={`dots-${index - num}`}>...</OTypography>
            ) : (
              <PaginationButton
                key={`page-${num}-button`}
                selected={currentPage === num}
                onClick={() => setCurrentPage(num)}
              >
                {num}
              </PaginationButton>
            ),
          )
        )}
      </div>
    </div>
  );
};
