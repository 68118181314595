import { modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Node, Edge } from "reactflow";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  snapshotNodeProps,
  SnapshotNodeData,
} from "../../../../../../../components/flow-container/snapshot-node";
import { CartoesProdutoMotorDeOfertasPropostasDeOfertaByOfferIdByTaxIdParams } from "../../../../../../../routes/cartoes.route-params";
import {
  eligibilityCriteriaModalId,
  EligibilityDetails,
  SnapshotNode,
} from "./eligibility-offer-details.types";
import { isEligibleTree } from "./eligibility-offer-details.utils";

export const useEligibilityOfferDetails = () => {
  const { taxId, offerId } =
    useParams<CartoesProdutoMotorDeOfertasPropostasDeOfertaByOfferIdByTaxIdParams>();
  if (!taxId || !offerId) throw new Error("No route params");

  const [eligibilityDetails, setEligibilityDetails] =
    useState<EligibilityDetails>({
      nodes: [],
      company: { governmentId: "", id: 0, companyEligibilityData: [] },
    });
  const [currentNode, setCurrentNode] = useState<SnapshotNode>({
    id: 0,
    groupName: "",
    hasPassed: false,
    parentId: null,
    results: [],
    root: 0,
  });

  const {
    callService,
    hasError,
    loading,
    value: snapshotResponse,
  } = useServiceCall(service.eligibilityengine.getOfferSnapshot);

  const getOfferSnapshot = useCallback(
    () => callService(offerId),
    [callService, offerId],
  );

  const onNodeClick = useCallback(
    (nodeId: number) => {
      const selectedNode = eligibilityDetails.nodes.find(
        (snapshotNode) => snapshotNode.id === nodeId,
      );

      if (selectedNode) setCurrentNode({ ...selectedNode });
      modalManager.show(eligibilityCriteriaModalId);
    },
    [eligibilityDetails.nodes],
  );

  useEffect(() => {
    if (snapshotResponse) setEligibilityDetails(snapshotResponse);
  }, [snapshotResponse]);

  useEffect(() => {
    getOfferSnapshot();
  }, [getOfferSnapshot]);

  const chart = useMemo(() => {
    const nodes: Node<SnapshotNodeData>[] = eligibilityDetails.nodes.map(
      (node) => ({
        id: `${node.id}`,
        data: {
          isEligibleTree:
            node.id === node.root
              ? isEligibleTree(node, eligibilityDetails.nodes)
              : undefined,
          label: node.groupName,
          hasPassed: node.hasPassed,
          onClick: () => {
            onNodeClick(node.id);
          },
        },
        ...snapshotNodeProps,
      }),
    );

    const edges: Edge[] = eligibilityDetails.nodes
      .filter((node) => node.parentId)
      .map((node) => ({
        id: `${node.parentId}-${node.id}`,
        source: `${node.parentId}`,
        target: `${node.id}`,
        type: "smoothstep",
        animated: true,
      }));

    return { nodes, edges };
  }, [onNodeClick, eligibilityDetails]);

  return {
    snapshot: eligibilityDetails,
    currentNode,
    chart,
    getOfferSnapshot,
    hasError,
    loading,
  };
};
