import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../utils";
import {
  AddDiligenceStepForm,
  addDiligenceStepFormDefaultValues,
  addDiligenceStepFormValidationSchema,
} from "./add-diligence-step-modal.form";
import { addDiligenceStepModalId } from "./add-diligence-step-modal.utils";

export const useAddDiligenceStepModal = (
  diligenceId: number,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.addStepToDiligence,
  );

  const form = useForm<AddDiligenceStepForm>({
    defaultValues: addDiligenceStepFormDefaultValues,
    resolver: yupResolver(addDiligenceStepFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          diligenceId,
          diligenceStepConfigId: values.diligenceStepConfigId,
        });

        if (success) {
          OToastManager.success("Step adicionado com sucesso");
          reload();
          modalManager.hide(addDiligenceStepModalId);
        } else {
          OToastManager.success(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Erro ao adicionar step",
          );
        }
      }),
    [callService, diligenceId, handleSubmit, reload],
  );

  return {
    form,
    loading,
    submit,
  };
};
