import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OOption,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ProductType, productTypeMap } from "../../settlement-strategies.utils";
import { SettlementStrategisFormProps } from "../formulario/settlement-strategies-form.types";
import { SettlementStrategySearch } from "../busca-estrategia-liquidacao";

export const PredicatesFields = ({
  initialValues,
}: Pick<SettlementStrategisFormProps, "initialValues">) => {
  const form = useFormContext();
  const { setValue } = form;

  useEffect(() => {
    if (initialValues) {
      setValue("tipoProduto", initialValues.tipoProduto);
      setValue("cedenteId", initialValues.cedente?.id);
      setValue("sacadoId", initialValues.sacado?.id);
      setValue("estrategiaComercialId", initialValues.estrategiaComercial?.id);
    }
  }, [initialValues, setValue]);

  const companyFilter = useCallback(
    (input: string) =>
      `((contains(tolower(OfficialName), '${input}')) or (startswith(Cnpj, '${input}')))`,
    [],
  );

  const strategyFilter = useCallback(
    (input: string) =>
      `((contains(tolower(Nome), '${input}')) or (startswith(UniqueId, '${input}')))`,
    [],
  );

  return (
    <OCard>
      <OCardHeader divider>Predicados</OCardHeader>
      <OCardBody>
        <ORow>
          <OCol xs={12} md={6} xl={3}>
            <ORFieldSelect
              aspect="outline"
              label="Tipo de produto"
              name="tipoProduto"
              id="tipoProduto"
              dataAction="formulario_estrategia_liquidacao:select:tipo_produto"
              dataLabel="tipo_produto"
            >
              {Object.keys(productTypeMap).map((product) => (
                <OOption key={product} value={product}>
                  {productTypeMap[product as ProductType]}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol xs={12} md={6} xl={3}>
            <SettlementStrategySearch
              filter={companyFilter}
              initialValue={initialValues?.cedente ?? null}
              name="cedenteId"
              label="Cedente"
              placeholder="Selecione o cedente"
            />
          </OCol>
          <OCol xs={12} md={6} xl={3}>
            <SettlementStrategySearch
              filter={companyFilter}
              initialValue={initialValues?.sacado ?? null}
              name="sacadoId"
              label="Sacado"
              placeholder="Selecione o sacado"
            />
          </OCol>
          <OCol xs={12} md={6} xl={3}>
            <SettlementStrategySearch
              filter={strategyFilter}
              initialValue={initialValues?.estrategiaComercial ?? null}
              name="estrategiaComercialId"
              label="Estratégia comercial"
              placeholder="Selecione a estratégia comercial"
            />
          </OCol>
        </ORow>
      </OCardBody>
    </OCard>
  );
};
