import { behavior } from "./behavior";
import { BehaviorCommand } from "./types/command";

class BehaviorManager {
  private behavior = behavior;

  async execute(command: BehaviorCommand) {
    await command.execute(this.behavior);
  }
}

export const behaviorManager = new BehaviorManager();
