import { IconButton } from "components/icon-button";
import { useQuoteList } from "../../quote-list.context";

export const ClearChangesButton = () => {
  const { setRequestedAmounts } = useQuoteList();

  return (
    <IconButton
      dataAction="entuba_listagem:botao:limpar_alteracoes"
      dataLabel="limpar_alteracoes"
      icon={{ category: "far", icon: "fa-edit" }}
      onClick={() => setRequestedAmounts({})}
    >
      Limpar alterações
    </IconButton>
  );
};
