import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { service } from "services/service";
import {
  addOfficerDefaultValues,
  addOfficerValidationschema,
} from "./add-officer.form";

export const useAddOfficer = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const form = useForm<ScfDevelopers.Officer>({
    defaultValues: addOfficerDefaultValues,
    resolver: yupResolver(addOfficerValidationschema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    setLoading(true);

    try {
      const payload = {
        officer: {
          ...values,
        },
      };

      await service.scfDevelopers.upsertOfficer(payload);
      OToastManager.success("Parceiro adicionado com sucesso!");
      navigate(-1);
    } catch (err) {
      const message = isAxiosError(err)
        ? err.response?.data.failures[0].errorMessage
        : "Falha ao adicionar parceiro";
      OToastManager.danger(message);
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    submit,
  };
};
