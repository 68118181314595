import {
  OCol,
  OLabel,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORadio,
  ORow,
  OToastManager,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { EmailFields, TermFields } from "./_compose";
import {
  calendarOptions,
  capitalizationBaseOptions,
  capitalizationTypeOptions,
  configTypeOptions,
  currencyOptions,
  rebateBaseOptions,
  rebateTypeOptions,
  taxasColunasOptions,
} from "./taxas-fields.utils";

interface TaxasFieldsProps {
  isEditing: boolean;
  hasTermFields?: boolean;
}

export const TaxasFields = ({ isEditing, hasTermFields }: TaxasFieldsProps) => {
  const [calendars, setCalendars] =
    useState<{ value: string; label: string }[]>();

  const getCustomCalendars = useCallback(async () => {
    try {
      const { data } = await service.legacys.getCalendarNames();
      const customCalendars = data.map((calendarName: string) => ({
        value: calendarName,
        label: calendarName,
      }));
      setCalendars(calendarOptions.concat(customCalendars));
    } catch (err) {
      const errorMessage = "Erro ao buscar calendários personalizados";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }
  }, []);

  useEffect(() => {
    getCustomCalendars();
  }, [getCustomCalendars]);

  return (
    <ORow gap={3}>
      <OCol xs={12} md={6}>
        <ORFieldSelect
          dataAction="formulario:select:moeda"
          dataLabel="moeda"
          id="currency"
          name="currency"
          label="Moeda"
          placeholder="Selecionar"
          required
        >
          {currencyOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldSelect
          dataAction="formulario:select:tipo_taxa"
          dataLabel="tipo_taxa"
          id="configType"
          name="configType"
          label="Tipo de taxa"
          placeholder="Selecionar"
          disabled={isEditing}
          required
        >
          {configTypeOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldSelect
          dataAction="formulario:select:indice"
          dataLabel="indice"
          id="index"
          name="index"
          label="Índice"
          placeholder="Selecionar"
          disabled={isEditing}
          required
        >
          <OOption key="PRE" value="PRE">
            PRE
          </OOption>
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldSelect
          dataAction="formulario:select:tipo_capitalizacao"
          dataLabel="tipo_capitalizacao"
          id="capitalizationType"
          name="capitalizationType"
          label="Tipo de capitalização"
          placeholder="Selecionar"
          required
        >
          {capitalizationTypeOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          tag="number"
          dataAction="formulario:texto:casas_decimais"
          dataLabel="casas_decimais"
          id="casasDecimais"
          name="casasDecimais"
          label="Casas decimais (arredondamento)"
          placeholder="Preencher"
          required
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldSelect
          dataAction="formulario:select:tipo_base"
          dataLabel="tipo_base"
          id="capitalizationBase"
          name="capitalizationBase"
          label="Tipo de base"
          placeholder="Selecionar"
          disabled={isEditing}
          required
        >
          {capitalizationBaseOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldSelect
          dataAction="formulario:select:calendario"
          dataLabel="calendario"
          id="calendar"
          name="calendar"
          label="Calendário"
          placeholder="Selecionar"
          key={calendars?.length}
          required
        >
          {calendars?.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldInput
          tag="number"
          dataAction="formulario:texto:data_base"
          dataLabel="data_base"
          id="daysToDataBase"
          name="daysToDataBase"
          label="Data base (D+)"
          placeholder="Preencher"
          disabled={isEditing}
          required
        />
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldInput
          tag="number"
          dataAction="formulario:texto:periodo_curva"
          dataLabel="periodo_curva"
          id="dataBasePeriod"
          name="dataBasePeriod"
          label="Período da curva"
          placeholder="Preencher"
          disabled={isEditing}
          required
        />
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldSelect
          dataAction="formulario:select:base_rebate"
          dataLabel="base_rebate"
          id="rebateBase"
          name="rebateBase"
          label="Base rebate"
          placeholder="Selecionar"
        >
          {rebateBaseOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldSelect
          dataAction="formulario:select:tipo_rebate"
          dataLabel="tipo_rebate"
          id="rebateType"
          name="rebateType"
          label="Tipo rebate"
          placeholder="Selecionar"
          required
        >
          {rebateTypeOptions.map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldInput
          tag="number"
          dataAction="formulario:texto:valor_rebate"
          dataLabel="valor_rebate"
          id="rebateValue"
          name="rebateValue"
          label="Valor rebate"
          placeholder="Preencher"
          required
        />
      </OCol>
      <OCol xs={12}>
        <EmailFields />
      </OCol>
      <OCol xs={12}>
        <ORFieldSelectMultiple
          dataAction="formulario:texto:colunas_export_taxas"
          dataLabel="colunas_export_taxas"
          id="taxasColunas"
          name="taxasColunas"
          label="Colunas export taxas"
          placeholder="Preencher"
        >
          {taxasColunasOptions.map(({ label, value }) => (
            <OOptionMultiple key={value} value={value}>
              {label}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldRadioGroup
          dataAction="formulario:texto:forçar_atualizacao_curva"
          dataLabel="forçar_atualizacao_curva"
          id="forceUpdate"
          name="forceUpdate"
          label="Forçar atualização da curva"
        >
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center gap-2">
              <ORadio id="yes" value="yes" />
              <OLabel htmlFor="yes">Sim</OLabel>
            </div>
            <div className="d-flex align-items-center gap-2">
              <ORadio id="no" value="no" />
              <OLabel htmlFor="no">Não</OLabel>
            </div>
          </div>
        </ORFieldRadioGroup>
      </OCol>
      <OCol xs={12} md={4}>
        <ORFieldInput
          tag="number"
          dataAction="formulario:texto:limite_dias"
          dataLabel="limite_dias"
          id="limitFutureDays"
          name="limitFutureDays"
          maxlength={4}
          label="Limite de dias da curva"
        />
      </OCol>
      {hasTermFields && (
        <OCol xs={12}>
          <TermFields />
        </OCol>
      )}
    </ORow>
  );
};
