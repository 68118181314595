import { MappedStatus } from "./status-maps.types";

export const diligenceStatusMap = {
  PENDENTE_CONFIGURACAO_DISTRIBUIDORA: {
    type: "warning",
    text: "Pendente configuração distribuidora",
  },
  CRIANDO_ETAPAS: { type: "info", text: "Criando etapas" },
  PENDENTE_APROVACAO_DILIGENCIA: {
    type: "warning",
    text: "Pendente aprovação diligência",
  },
  EM_ANDAMENTO: { type: "warning", text: "Em andamento" },
  EM_ANALISE_OPS: { type: "warning", text: "Em análise OPs" },
  CONCLUIDA: { type: "success", text: "Concluída" },
  EXPIRADA: { type: "warning", text: "Expirada" },
  CANCELADA: { type: "danger", text: "Cancelada" },
} satisfies Record<HubEnergy.EDiligenceStatus, MappedStatus>;
