import { IRefusalTransactionInfoData } from "./refusal-transaction-modal.types";

export const RefusalTransactionInfoData: Array<IRefusalTransactionInfoData> = [
  { label: "Estabelecimento", key: "merchantName" },
  { label: "Valor", key: "amount" },
  { label: "Data", key: "date" },
  { label: "Hora", key: "hour" },
  { label: "Cartão Final", key: "cardLast4Digits" },
  { label: "Tipo do cartão", key: "cardType" },
  { label: "Tipo", key: "type" },
  { label: "Via", key: "via" },
  { label: "Categoria", key: "category" },
];
