import * as yup from "yup";

export interface AddBookStrategyFormFields {
  bookId: number;
  strategyId: number;
}

export const addBookStrategyFormValidationSchema = yup.object().shape({
  bookId: yup.number().required("Campo obrigatório"),
  strategyId: yup.number().required("Campo obrigatório"),
});

export const addBookStrategyFormDefaultValues = {
  bookId: NaN,
  strategyId: NaN,
};
