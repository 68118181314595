import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useValidateAccount = (ids: number[]) => {
  const {
    customer: { identification },
  } = useCustomer();

  const { callService, hasError, loading, value } = useServiceCall(
    service.onboardingCoreAccount.validateAccounts,
  );

  const validateAccounts = useCallback(
    () =>
      callService({
        companyTaxId: identification,
        ids,
      }),
    [callService, identification, ids],
  );

  useEffect(() => {
    validateAccounts();
  }, [validateAccounts]);

  return {
    hasError,
    loading,
    validateAccounts,
    validationResults: value,
    canClose: value?.canProceed,
  };
};
