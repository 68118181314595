import { OToastManager } from "@maestro/core";
import {
  downloadCsv,
  makeCsvFromObjectArray,
} from "../../../cartoes/pages/utils/csv";
import { Metric } from "../chart-card/chart-card.types";

export const exportChart = (metric: Metric) => {
  if (!metric.records)
    return OToastManager.warning("Nenhum dado para ser exportado");

  const cleanRecords = metric.records.map((record) => ({
    date: record.relatedDate,
    value: record.value,
  }));

  downloadCsv(
    makeCsvFromObjectArray(cleanRecords),
    `${metric.configIdentifier}.csv`,
  );
};
