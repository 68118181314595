import {
  OCol,
  OField,
  OLoader,
  OOption,
  ORFieldInput,
  ORow,
  OSelect,
  OSwitch,
} from "@maestro/react";
import { useSemaphoreStepForm } from "./step-config.hook";

export const SemaphoreStepForm = () => {
  const {
    loading,
    allPipelines,
    setStopValidation,
    watchShouldStopOnRed,
    watchShouldStopOnGreen,
    watchShouldStopOnYellow,
    watchInput,
    setInput,
  } = useSemaphoreStepForm();

  return (
    <>
      {loading && <OLoader absolute backdrop />}
      <ORow>
        <OCol xs={12} md={4} xl={4}>
          <ORFieldInput
            tag="text"
            id="name"
            name="name"
            dataAction="configuracao_semaforo_step:input:name"
            dataLabel="Nome"
            label="Nome"
          />
        </OCol>
        <OCol xs={12} md={8} xl={8}>
          <ORFieldInput
            tag="text"
            id="description"
            name="description"
            dataAction="configuracao_semaforo_step:input:description"
            dataLabel="Descrição"
            label="Descrição"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={12} xl={12}>
          <ORFieldInput
            tag="text"
            id="responseMessage"
            name="responseMessage"
            dataAction="configuracao_semaforo_step:input:responseMessage"
            dataLabel="Resposta de validação"
            label="Resposta de validação"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={12} xl={12}>
          <ORFieldInput
            tag="text"
            id="responseMessageTypeCode"
            name="responseMessageTypeCode"
            dataAction="configuracao_semaforo_step:input:responseMessageTypeCode"
            dataLabel="Código da resposta de validação"
            label="Código da resposta de validação"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={4} xl={4}>
          <OField htmlFor="shouldStopOnRed" label="Parar validação no vermelho">
            <OSwitch
              id="shouldStopOnRed"
              value={watchShouldStopOnRed}
              onInput={(e) =>
                setStopValidation(e.currentTarget.id, e.currentTarget.value)
              }
            />
          </OField>
        </OCol>
        <OCol xs={12} md={4} xl={4}>
          <OField
            htmlFor="shouldStopOnYellow"
            label="Parar validação no amarelo"
          >
            <OSwitch
              id="shouldStopOnYellow"
              value={watchShouldStopOnYellow}
              onInput={(e) =>
                setStopValidation(e.currentTarget.id, e.currentTarget.value)
              }
            />
          </OField>
        </OCol>
        <OCol xs={12} md={4} xl={4}>
          <OField htmlFor="shouldStopOnGreen" label="Parar validação no verde">
            <OSwitch
              id="shouldStopOnGreen"
              value={watchShouldStopOnGreen}
              onInput={(e) =>
                setStopValidation(e.currentTarget.id, e.currentTarget.value)
              }
            />
          </OField>
        </OCol>
      </ORow>
      <ORow align="start">
        <OCol xs={12} sm={8} lg={8}>
          <OField label="Steps de Validação" htmlFor="input">
            <OSelect
              id="input"
              value={watchInput}
              onInput={setInput}
              placeholder="Selecione uma opção"
              dataAction="configuracao_semaforo_step:input:pipelineUuid"
              dataLabel="Seleção de step"
            >
              {allPipelines.length &&
                allPipelines.map((pipe) => {
                  return (
                    <OOption key={pipe.uuid} value={pipe.uuid}>
                      {pipe.pipelineName}
                    </OOption>
                  );
                })}
            </OSelect>
          </OField>
        </OCol>
      </ORow>
    </>
  );
};
