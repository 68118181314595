import { OBadge } from "@maestro/react";

const hasInstallmentsExpired = (
  installments: BankingHub.Installment[] | null | undefined,
) => {
  if (!installments) {
    return true;
  }

  return installments.some((installment) => installment.status === "Expired");
};

const getStatus = (installmentPlan: BankingHub.InstallmentPlanDetails) => {
  if (installmentPlan.status === "Failed") {
    return { color: "danger" as const, text: "CANCELADO" };
  }

  if (
    installmentPlan.processedAt &&
    installmentPlan.paidInstallments === installmentPlan.totalInstallments
  ) {
    return { color: "success" as const, text: "FINALIZADO" };
  }

  if (
    installmentPlan.processedAt &&
    hasInstallmentsExpired(installmentPlan.installments)
  ) {
    return { color: "danger" as const, text: "ATRASADO" };
  }

  if (
    installmentPlan.processedAt &&
    !hasInstallmentsExpired(installmentPlan.installments)
  ) {
    return { color: "info" as const, text: "EM DIA" };
  }

  return null;
};

interface InstallmentPlanStatusBadgeProps {
  installmentPlan: BankingHub.InstallmentPlanDetails;
}

export const InstallmentPlanStatusBadge = ({
  installmentPlan,
}: InstallmentPlanStatusBadgeProps) => {
  const status = getStatus(installmentPlan);

  if (!status) return null;

  return <OBadge type={status.color}>{status.text}</OBadge>;
};
