import { OCol, ORow, OToastManager, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { useGuarantorsSetup } from "../../../offers-guarantors-hook.component";
import { ProductItemInterface } from "../simulation/simulation.types";
import { guarantorsGrid } from "./guarantors.grid";

interface GuarantorsProps {
  simulationProduct: ProductItemInterface | undefined;
}

export const Guarantors = ({ simulationProduct }: GuarantorsProps) => {
  const [loading, setLoading] = useState(false);

  const { guarantorsSetup, setGuarantors, guarantors } = useGuarantorsSetup();

  const displayOnboardingGuarantorButton = useMemo(() => {
    return guarantors?.some((g) => g.canSendToAnalysis);
  }, [guarantors]);

  const sendGuarantors = useCallback(async () => {
    try {
      if (!guarantorsSetup) return;
      setLoading(true);

      await service.onboardingRelationships.onboardingGuarantor(
        guarantorsSetup,
      );

      OToastManager.success("As alterações foram enviadas com sucesso.");
    } catch (err) {
      logger.error(err);

      OToastManager.danger(
        "Ocorreu um erro ao enviar as alterações dos dados do(s) avalista(s).",
      );
    } finally {
      setLoading(false);
    }
  }, [guarantorsSetup]);

  const loadGuarantors = useCallback(async () => {
    try {
      if (!guarantorsSetup) return;

      const { data } = await service.onboardingRelationships.loadGuarantors(
        guarantorsSetup,
      );

      setGuarantors(data.guarantorsResponse);
    } catch (err) {
      logger.error(err);
    }
  }, [guarantorsSetup, setGuarantors]);

  useEffect(() => {
    loadGuarantors();
  }, [loadGuarantors]);

  const GuarantorsMemo = useMemo(() => {
    return (
      <>
        {!!guarantorsSetup && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <ORow>
                <OCol lg={12}>
                  <OTypography className="mb-2" size="xl">
                    {simulationProduct?.guarantors.title}
                  </OTypography>
                </OCol>
                <OCol lg={12}>
                  <OTypography type="default">
                    {simulationProduct?.guarantors.description}
                  </OTypography>
                </OCol>
              </ORow>
            </div>
            <GridTemplate
              actions={
                <>
                  {displayOnboardingGuarantorButton && (
                    <LoadingButton
                      loading={loading}
                      type="tertiary"
                      onClick={sendGuarantors}
                      className="m-2"
                    >
                      Enviar alterações
                    </LoadingButton>
                  )}
                </>
              }
            >
              <div className="mb-4">
                <ODataGridGenerator
                  grid={guarantorsGrid}
                  dataSource={guarantors}
                />
              </div>
            </GridTemplate>
          </>
        )}
      </>
    );
  }, [
    simulationProduct,
    displayOnboardingGuarantorButton,
    loading,
    sendGuarantors,
    guarantorsSetup,
    guarantors,
  ]);

  return <>{GuarantorsMemo}</>;
};
