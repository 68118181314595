import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ContractTemplateDetailsForm } from "../../../../components/contract-templates/contract-template-details-form.component";
import { useAddCompanyContractTemplates } from "./add-company-contract-template.hook";

export const AddCompanyContractTemplate = () => {
  const { form, submit, submitLoading } = useAddCompanyContractTemplates();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar template de contrato" />}
      actions={
        <LoadingButton
          dataAction="recebiveis_adicionar_template_contrato:botao:salvar"
          dataLabel="salvar"
          loading={submitLoading}
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ContractTemplateDetailsForm />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
