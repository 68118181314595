import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useProfileSelector } from "./profile-selector.hook";

interface ProfileSelectorProps {
  loading: boolean;
}

export const ProfileSelector = ({
  loading: loadingProp,
}: ProfileSelectorProps) => {
  const { getProfiles, hasError, loading, profiles } = useProfileSelector();

  const options = useMemo(
    () =>
      profiles?.map((profile) => ({
        label: profile,
        value: profile,
      })) ?? [],
    [profiles],
  );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        id="profiles"
        name="profiles"
        options={options}
        label="Profiles"
        placeholder="Buscar"
      />
      {(loading || loadingProp) && <OLoader size="sm" />}
      {hasError && !loading && (
        <RefreshGridButton
          dataAction="selecionar_perfis:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getProfiles()}
        />
      )}
    </div>
  );
};
