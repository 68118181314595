import { OTypography } from "@maestro/react";
import { NodeProps, NodeResizer } from "reactflow";
import "./group-node.css";
import { useGroupNode } from "./group-node.hook";
import { GroupNodeDataType } from "./group-node.types";

export const GroupNode = ({
  id,
  data,
  selected,
}: NodeProps<GroupNodeDataType>) => {
  const { handleResize } = useGroupNode(id);

  return (
    <>
      <NodeResizer
        color="var(--theme-dark)"
        isVisible={selected}
        onResizeEnd={handleResize}
      />
      <OTypography size="lg" type="dark">
        {data.value}
      </OTypography>
    </>
  );
};
