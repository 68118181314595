import { OBadge, OIcon } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

type ConfigurableStatus = Awaited<
  ReturnType<typeof service.hubCreditManager.getConfigurableStatus>
>["data"][number];

export const configurableStatusGrid = {
  datagrid: {
    noDataText: "Nenhum status encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getConfigurableStatus().catch(() => {
        throw new Error("Não foi possível buscar status.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "nameId",
      dataType: "string",
      caption: "Identificador",
    },
    {
      caption: "Visualização",
      dataField: "name",
      dataType: "string",
      cellRender: ({ data }) => (
        <OBadge type={`${data.color}-light` as const} size="sm" rounded>
          <OIcon
            category="orq"
            icon={data.iconName}
            type={data.color}
            size="lg"
          />
          {data.name}
        </OBadge>
      ),
    },
    {
      dataField: "endOnStart",
      dataType: "boolean",
      caption: "Status deve inicializar encerrado",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "fas", icon: "fa-pen" },
              label: "Editar",
              role: roles.workflow.product.configurationsUpdateStatusPage.role,
              route: `${corporateRouter.routes.workflow.product.configurations.status.update.path(
                {
                  id: data.id,
                },
              )}?data=${encodeURIComponent(JSON.stringify(data))}`,
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<ConfigurableStatus>;
