import { OButton, OIcon } from "@maestro/react";
import React from "react";

type TryAgainButtonProps = Partial<
  Pick<
    React.ComponentPropsWithRef<typeof OButton>,
    "onClick" | "dataAction" | "dataLabel"
  >
>;

export const TryAgainButton = (props: TryAgainButtonProps) => {
  return (
    <OButton
      className="mt-2"
      type="default"
      typeButton="button"
      disabled={false}
      size="sm"
      outline
      rounded={false}
      bordered
      fullWidth={false}
      circle={false}
      dataLabel="tentar_novamente"
      dataAction="pagina:botao:tentar_novamente"
      {...props}
    >
      <span className="d-flex align-items-center gap-1">
        <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
        Tentar novamente
      </span>
    </OButton>
  );
};
