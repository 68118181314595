import { OButton, OFieldError, OIcon, OTypography } from "@maestro/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { EditTaxasForm } from "../../edit-taxas.types";
import { EmailField } from "./email-field.component";

export const EmailFields = () => {
  const form = useFormContext<EditTaxasForm>();

  const {
    control,
    formState: {
      errors: { emailsTaxas },
    },
  } = form;

  const fieldArray = useFieldArray({
    control,
    name: "emailsTaxas",
  });

  const { append, fields } = fieldArray;

  return (
    <div className="d-flex flex-column flex-fill">
      <OTypography>E-mails</OTypography>
      {!!emailsTaxas?.message && (
        <OFieldError visible>{emailsTaxas.message}</OFieldError>
      )}
      {fields.map((field, index) => (
        <EmailField key={field.id} index={index} fieldArray={fieldArray} />
      ))}
      <div className="d-flex justify-content-end">
        <OButton
          dataAction="campo_emails:botao:adicionar"
          dataLabel="adicionar"
          onClick={() => append({ email: null as any })}
        >
          <OIcon category="fa" icon="fa-plus" />
        </OButton>
      </div>
    </div>
  );
};
