import * as yup from "yup";

export const addOfficerValidationschema: yup.ObjectSchema<ScfDevelopers.Officer> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    taxId: yup.string().required("Este campo é obrigatório"),
    cge: yup.string().required("Este campo é obrigatório"),
  });

export const addOfficerDefaultValues: ScfDevelopers.Officer = {
  name: "",
  taxId: "",
  cge: "",
};
