import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.adminCustomer.getAccTrades>
    >["data"]["response"]
  >[number]
>;

export const adminCustomerAccTradesGrid = () =>
  ({
    datagrid: {
      noDataText: "Nenhuma operação encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar operações.";
      },
      dataSource: dataSourceCustomStoreGenerator(() =>
        service.acc.adminCustomer
          .getAccTrades()
          .then(({ data }) => data.response ?? []),
      ),
    },
    columns: [
      {
        type: "buttons",
        allowExporting: false,
        role: roles.acc.customer.tradesDetailsPage.role,
        cellRender: ({ data }) =>
          data.id && (
            <DetailsLink
              href={corporateRouter.routes.acc.customer.quotes.details.path({
                id: data.id,
              })}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Proposta",
        dataType: "number",
        alignment: "left",
      },
      {
        caption: "Valor solicitado",
        dataType: "date",
        dataField: "accProposal.amount",
        cellRender: ({ data }) =>
          masks.currency(
            data.accProposal.amount,
            getValueFromMap(
              currencySymbol,
              data.quoteSimulation?.buyCurrencyType,
            ),
          ),
      },
      {
        caption: "Data de vencimento",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "accProposal.settlementDate",
      },
      {
        caption: "Data de expiração",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "accProposal.expirationDate",
      },
      {
        caption: "Taxa de juros",
        dataField: "accProposal.interestRate",
        customizeText: ({ value }) => masks.percentage(value),
        alignment: "left",
      },
      {
        caption: "Status",
        dataField: "accProposal.status",
      },
    ],
  } satisfies Grid);
