export const cardHiringFields = {
  programId: {
    id: "programId",
    name: "programId",
    label: "Tipo do cartão",
    placeholder: "Selecione o tipo do cartão",
  },
  ownerDocument: {
    id: "ownerDocument",
    name: "ownerDocument",
    label: "Portador do cartão",
    placeholder: "Selecione o portador do cartão",
    tooltip: "Para seleção do portador, por favor selecione o tipo do cartão",
    tooltipMaxWidth: "300px",
  },
  phone: {
    tag: "phone",
    description: "Insira o número do celular",
    id: "phone",
    name: "phone",
    label: "Celular do portador do cartão",
    placeholder: "+55 (00) 0 0000-0000",
  },
  printedName: {
    tag: "text",
    id: "printedName",
    name: "printedName",
    label: "Nome impresso no cartão",
    placeholder: "Qual o nome impresso no cartão?",
    tooltip:
      "Deve conter nome e sobrenome, com espaçamentos simples e sem caracteres especiais",
    tooltipMaxWidth: "400px",
    maxlength: 21,
  },
  addressId: {
    id: "addressId",
    name: "addressId",
    label: "Endereço de entrega",
    placeholder: "Selecione o endereço de entrega do cartão",
    tooltip:
      "Para seleção do endereço de entrega, por favor selecione o portador e preencha o número de celular",
    tooltipMaxWidth: "450px",
  },
  accountNumber: {
    id: "accountNumber",
    name: "accountNumber",
    label: "Conta do BTG",
    placeholder: "Selecione a conta de emissão do cartão",
    tooltip:
      "Cartões de débito só podem ser emitidos para contas da agência 50.",
    tooltipMaxWidth: "250px",
  },
  issueDateId: {
    id: "issueDateId",
    name: "issueDateId",
    label: "Dia de vencimento da fatura",
    placeholder: "Selecione o dia de vencimento da fatura",
  },
  hiredAmount: {
    id: "hiredAmount",
    name: "hiredAmount",
    tag: "currency",
    symbol: "R$",
    label: "Limite de crédito da empresa",
    placeholder: "Limite de crédito da empresa",
    tooltip:
      "Você pode ajustar o valor, respeitando o limite máximo pré-aprovado para a empresa.",
    tooltipMaxWidth: "400px",
  },
} as const;
