import { OCol, OOption, ORFieldInput, ORFieldSelect } from "@maestro/react";
import { countryCodeMap } from "pages/recebiveis/utils";

interface BaseFormProps {
  identificationType: "CPF" | "CNPJ" | "Offshore";
}

export const BaseForm = ({ identificationType }: BaseFormProps) => {
  return (
    <>
      <OCol xs={12} md={6} xl={4} key={identificationType}>
        {identificationType === "CPF" ? (
          <ORFieldInput
            dataAction="formulario_base:texto:cpf"
            dataLabel="cpf"
            id="taxId"
            name="taxId"
            tag="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            disabled
          />
        ) : identificationType === "CNPJ" ? (
          <ORFieldInput
            dataAction="formulario_base:texto:cnpj"
            dataLabel="cnpj"
            id="taxId"
            name="taxId"
            tag="cnpj"
            label="CNPJ"
            placeholder="00.000.000/0000-00"
            disabled
          />
        ) : (
          <ORFieldInput
            dataAction="formulario_base:texto:offshore"
            dataLabel="offshore"
            id="taxId"
            name="taxId"
            tag="text"
            label="Offshore"
            placeholder="00000000000000"
            disabled
          />
        )}
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_base:select:country"
          dataLabel="country"
          id="country"
          name="country"
          label="País"
          placeholder="Selecionar"
          required
        >
          {Object.values(countryCodeMap).map(({ label, value }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          dataAction="formulario_base:texto:nome_oficial"
          dataLabel="nome_oficial"
          id="officialName"
          name="officialName"
          tag="text"
          label="Nome oficial"
          placeholder="Preencher"
          required
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_base:select:tipo_entidade"
          dataLabel="tipo_entidade"
          id="companyEntityType"
          name="companyEntityType"
          label="Tipo da entidade"
          placeholder="Selecionar"
          required
          disabled={identificationType === "CNPJ"}
        >
          <OOption value="PessoaJuridica">Pessoa Jurídica</OOption>
          <OOption value="PessoaFisica">Pessoa Física</OOption>
          <OOption value="Offshore">Offshore</OOption>
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_base:select:sacado_status"
          dataLabel="sacado_status"
          id="companySacadoStatus"
          name="companySacadoStatus"
          label="Sacado status"
          placeholder="Selecionar"
        >
          <OOption value="EM_ONBOARDING">Em onboarding</OOption>
          <OOption value="AUTORIZADO">Autorizado</OOption>
          <OOption value="REPROVADO">Reprovado</OOption>
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        {identificationType === "CPF" ? (
          <ORFieldSelect
            dataAction="formulario_base:select:deal"
            dataLabel="deal"
            id="deal"
            name="deal"
            label="Deal"
            placeholder="Selecionar"
          >
            <OOption value="EM_ONBOARDING">Em onboarding</OOption>
            <OOption value="AUTORIZADO">Autorizado</OOption>
            <OOption value="REPROVADO">Reprovado</OOption>
          </ORFieldSelect>
        ) : (
          <ORFieldInput
            dataAction="formulario_base:select:deal"
            dataLabel="deal"
            id="deal"
            name="deal"
            tag="text"
            label="Deal"
            placeholder="Preencher"
          />
        )}
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          dataAction="formulario_base:select:cge"
          dataLabel="cge"
          id="cge"
          name="cge"
          tag="text"
          label="CGE"
          placeholder="Preencher"
        />
      </OCol>
    </>
  );
};
