import { OBadge } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DataGridAction } from "components/datagrid-action";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetBatchGetByCnpjResponse } from "services/fidc/models/responses";
import { currencySymbol } from "utils/currency";
import { batchStatusMap, getOperationActions } from "./anticipations.utils";

export const anticipationsGrid: ODataGridGeneratorConfig<
  GetBatchGetByCnpjResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhuma antecipação encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} antecipações)",
    },
  },
  columns: [
    {
      dataField: "identifier",
      caption: "Id",
      cellRender: ({ data }) => `#${data.identifier}`,
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => {
        const mappedAnticipationStatus = batchStatusMap[data.status] ?? {
          label: "Indeterminado",
          type: "default",
        };
        return (
          <OBadge type={mappedAnticipationStatus.type}>
            {mappedAnticipationStatus.label}
          </OBadge>
        );
      },
    },
    {
      dataField: "value",
      caption: "Valor da operação",
      cellRender: ({ data }) =>
        masks.currency(data.value, currencySymbol.BRL, "."),
    },
    {
      dataField: "dateCreated",
      caption: "Data de criação",
      dataType: "date",
      format: "dd/MM/yyyy - hh:mm:ss",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        const buttonActions = getOperationActions(data);
        return (
          buttonActions.length > 0 && (
            <DataGridAction actions={getOperationActions(data)} />
          )
        );
      },
    },
    {
      dataField: "errors",
      caption: "Errors",
      cellRender: ({ data }) =>
        data.status === "INVALID" && data.errors?.length ? data.errors : "",
    },
  ],
};
