import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskConfigRelationshipForm } from "../_compose/desk-config-relationship-form.component";
import {
  DeskConfigRelationshipFormValues,
  deskConfigRelationshipFormValidationSchema,
} from "../_compose/desk-config-relationship.form";

export const RegisterDeskConfigRelationshipPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  const form = useForm<DeskConfigRelationshipFormValues>({
    resolver: yupResolver(deskConfigRelationshipFormValidationSchema),
    defaultValues: {
      configIdentifier,
    },
  });
  const { handleSubmit } = form;

  const { callService: registerDeskConfigRelationship, loading } =
    useServiceCall(service.onboarding.registerDeskConfigRelationship);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDeskConfigRelationship(values);

        if (!success)
          return OToastManager.danger("Erro ao criar relacionamento");

        return OToastManager.success("Relacionamento criada com sucesso");
      }),
    [handleSubmit, registerDeskConfigRelationship],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Adicionar relacionamento mesa/configuração" />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskConfigRelationshipForm />
      </FormProvider>
    </FormTemplate>
  );
};
