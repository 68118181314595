import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { AddressFields } from "../../../../../../../../components/address-form/address.component";
import {
  BasicDataFields,
  DistributorFields,
  EnergyConsumptionFields,
} from "./_compose";

interface ConsumerUnitFieldsProps {
  disabled?: boolean;
}

export const ConsumerUnitFields = ({ disabled }: ConsumerUnitFieldsProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardHeader>
          <OTypography>Dados básicos</OTypography>
        </OCardHeader>
        <OCardBody>
          <BasicDataFields disabled={disabled} disabledTaxId />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography>Informações de consumo</OTypography>
        </OCardHeader>
        <OCardBody>
          <EnergyConsumptionFields disabled={disabled} />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography>
            Informações da unidade consumidora na distribuidora
          </OTypography>
        </OCardHeader>
        <OCardBody>
          <DistributorFields disabled={disabled} />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography>Endereço</OTypography>
        </OCardHeader>
        <OCardBody>
          <AddressFields disabled={disabled} />
        </OCardBody>
      </OCard>
    </div>
  );
};
