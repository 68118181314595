import { OTooltip, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostAdminCompanyGetByTaxIdResponse } from "services/onboarding-relationships/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { impersonateRedirect } from "utils/impersonate";
import {
  documentsMap,
  statusBiometryMap,
  statusBiometryTooltipMap,
  statusOnboardingMap,
  statusOnboardingTooltipMap,
} from "./utils";
import { userDataSearchParam } from "./vincular/attach-user.types";

const buildDetailsLink = (cpf: string) => {
  const { routes } = corporateRouter;
  const baseUrl = routes.usuarios.customer.users.details.path({
    identification: cpf,
  });

  return baseUrl;
};

export const buildAttachUsersLink = ({
  cpf,
  name,
  email,
  phone,
  profilesToEnable,
}: PostAdminCompanyGetByTaxIdResponse[number]) => {
  const baseUrl =
    corporateRouter.routes.usuarios.customer.attachUser.byIdentificaton.path({
      identification: cpf,
    });

  const profiles =
    profilesToEnable?.map((profile) => window.btoa(profile.uuid)) ?? [];
  const stringifyedProfiles = JSON.stringify(profiles);

  const userData = JSON.stringify({
    name,
    email,
    phone,
    profiles: stringifyedProfiles,
  });
  const searchParams = new URLSearchParams();
  searchParams.set(userDataSearchParam, userData);

  return baseUrl + "?" + searchParams.toString();
};

export const grid = (
  canUpdate: boolean,
): ODataGridGeneratorConfig<PostAdminCompanyGetByTaxIdResponse[number]> => ({
  datagrid: {
    noDataText: "Nenhum usuário",
    filterRow: {
      visible: true,
    },
    editing: {
      allowUpdating: canUpdate,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      role: roles.usuarios.customer.detailsPage.role,
      cellRender: ({ data }) =>
        data.email &&
        data.identificationType === "CPF" &&
        data.identification && (
          <DetailsLink href={buildDetailsLink(data.identification)} />
        ),
      allowEditing: false,
    },
    {
      dataField: "name",
      caption: "Nome/CPF",
      sortOrder: "asc",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["name", "contains", filterValue],
        "or",
        ["identification", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.name}</OTypography>
          <OTypography type="default">
            {data.identificationType === "CPF"
              ? masks.cpf(data.identification)
              : data.identification}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "email",
      caption: "E-mail/Telefone",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["email", "contains", filterValue],
        "or",
        ["phone", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.email}</OTypography>
          <OTypography type="default">{masks.phone(data.phone)}</OTypography>
        </div>
      ),
    },
    {
      dataField: "profiles",
      caption: "Perfis",
      width: "110px",
      allowEditing: false,
    },
    {
      dataField: "legalRepresentative",
      caption: "É representante legal?",
    },
    {
      dataField: "powersVigency",
      dataType: "datetime",
      format: "shortDate",
      selectedFilterOperation: ">=",
      caption: "Vencimento dos poderes",
      allowEditing: false,
    },
    {
      dataField: "document",
      caption: "Documento",
      lookup: {
        dataSource: Object.entries(documentsMap).map(([key, label]) => ({
          value: key,
          name: label,
        })),
        displayExpr: "name",
        valueExpr: "value",
      },
      cellRender: ({ data }) => getValueFromMap(documentsMap, data.document),
      allowEditing: false,
    },
    {
      dataField: "biometryStatus",
      caption: "Status biometria",
      lookup: {
        dataSource: Object.entries(statusBiometryMap).map(([key, value]) => ({
          value: key,
          name: value.label,
        })),
        displayExpr: "name",
        valueExpr: "value",
      },
      cellRender: ({ data }) => {
        if (!data.biometryStatus) {
          return;
        }
        const badge = getValueFromMap(statusBiometryMap, data.biometryStatus);
        const tooltip = getValueFromMap(
          statusBiometryTooltipMap,
          data.biometryStatus,
        );
        return badge && tooltip ? (
          <OTooltip floating>
            <span slot="tooltip-content">{tooltip}</span>
            <StatusBadge type={badge.type}>{badge.label}</StatusBadge>
          </OTooltip>
        ) : (
          badge?.label ?? data.biometryStatus
        );
      },
      allowEditing: false,
    },
    {
      dataField: "registerStatus",
      caption: "Status do cadastro",
      lookup: {
        dataSource: Object.entries(statusOnboardingMap).map(([key, value]) => ({
          value: key,
          name: value.label,
        })),
        displayExpr: "name",
        valueExpr: "value",
      },
      cellRender: ({ data }) => {
        const badge = getValueFromMap(statusOnboardingMap, data.registerStatus);
        const tooltip = getValueFromMap(
          statusOnboardingTooltipMap,
          data.registerStatus,
        );
        return badge && tooltip ? (
          <OTooltip floating>
            <span slot="tooltip-content">{tooltip}</span>
            <StatusBadge type={badge.type}>{badge.label}</StatusBadge>
          </OTooltip>
        ) : (
          badge?.label ?? data.registerStatus
        );
      },
      allowEditing: false,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) =>
        data.email ? (
          <IconButton
            icon={{ category: "fal", icon: "fa-portrait" }}
            type="default"
            outline
            onClick={() =>
              impersonateRedirect(data.email ?? "", "BTG_PACTUAL_EMPRESAS")
            }
          >
            Impersonar
          </IconButton>
        ) : (
          <RouterButton
            href={buildAttachUsersLink(data)}
            type="default"
            outline
          >
            Habilitar acesso
          </RouterButton>
        ),
    },
  ],
});
