import { ODataGridGeneratorConfig } from "components/data-grid";
import { Representatives } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";

export const grid = () => {
  return {
    datagrid: {
      noDataText: "Nenhum Representante",
      pager: {
        infoText: "Página {0} de {1} ({2} representante)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "taxId",
        caption: "Cpf",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "phoneNumber",
        caption: "Telefone",
      },
      {
        dataField: "email",
        caption: "E-mail",
      },
      {
        dataField: "type",
        caption: "Tipo",
      },
      {
        dataField: "cge",
        caption: "Cge",
      },
    ],
  } as ODataGridGeneratorConfig<Representatives>;
};
