import { TrackingStatus } from "../../tracking.types";

export const postOfficeStatusCodes = [
  TrackingStatus.PostOffice,
  TrackingStatus.SendThird,
];

export const externalTrackingStatusCodes = [
  TrackingStatus.Send,
  ...postOfficeStatusCodes,
];

export const MapperTrackingStatusToPostOfficeMessages: Record<
  string,
  string[]
> = {
  [TrackingStatus.PostOffice]: [
    "O cartão foi enviado. Copie o código de rastreio e cole no site dos Correios para acompanhar a entrega. Você tem até 30 dias para fazer a retirada. Após esse prazo o cartão será cancelado.",
  ],
  [TrackingStatus.SendThird]: [
    "O cartão será entregue pelos Correios. Copie o código de rastreio abaixo e cole no site dos Correios para acompanhar a entrega.",
  ],
};
