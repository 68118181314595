import { OButton } from "@maestro/react";
import { CardTemplate } from "templates/card-template";
import { StepRenderProps } from "../../banner-form.types";
import { InfoList } from "./_compose";
import { getBannerInfo, getCampaignInfo } from "./summary.utils";

export const Summary = ({
  data,
  children,
  formData,
  bannerTemplate,
  setStep,
  updateBanner,
}: StepRenderProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <CardTemplate>
        <div className="d-flex flex-column gap-4">
          <InfoList
            items={getBannerInfo(formData, bannerTemplate)}
            title="Criação do banner"
            divider="horizontal"
            colSize={4}
          />
          {!!getCampaignInfo(data).length && (
            <InfoList
              items={getCampaignInfo(data)}
              title="Configuração da campanha"
              divider="horizontal"
              colSize={4}
            />
          )}
        </div>
      </CardTemplate>

      {children}

      <div className="d-flex justify-content-end gap-2">
        <OButton
          type="dark"
          outline
          onClick={() => setStep && setStep("CAMPANHA")}
        >
          Voltar
        </OButton>

        <OButton
          type="tertiary"
          onClick={() => formData && updateBanner(formData)}
        >
          Continuar
        </OButton>
      </div>
    </div>
  );
};
