import styled, { css } from "styled-components";

export const InputDarkerPlaceholder = styled.div<{
  darkerPlaceholder?: boolean;
}>`
  ${({ darkerPlaceholder }) =>
    darkerPlaceholder
      ? css`
          o-input input:not(:focus)::placeholder {
            color: var(--theme-dark);
          }
        `
      : ""}

  .no-padding-left input {
    padding-left: 0 !important;
  }
`;
