import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, propertyTypesGrid } from "./property-types.grid";

export const PropertyTypesPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Tipos de Propriedade" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.energia.product.diligenceConfig.propertyTypes
              .create.path
          }
          role={roles.energia.product.diligenceConfigCreatePropertyType.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={propertyTypesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
