import { formFields } from "./values-by-plan-form.form";
import {
  OButton,
  OCol,
  ODivider,
  ORFieldInput,
  ORow,
  OTypography,
  TooltipContainer,
} from "@maestro/react";
import { TCOStrategiesForm } from "../tco-strategies-form.component";

export const ValuesByPlan = (props: TCOStrategiesForm) => {
  const basicMinValueWatch = props.watch("basicMinValue");
  const basicMaxValueWatch = props.watch("basicMaxValue");
  const basicPercentageWatch = props.watch("basicPercentage");

  const handleReplicate = () => {
    props.setValue("intermediateMinValue", basicMinValueWatch);
    props.setValue("intermediateMaxValue", basicMaxValueWatch);
    props.setValue("intermediatePercentage", basicPercentageWatch);
    props.setValue("advancedMinValue", basicMinValueWatch);
    props.setValue("advancedMaxValue", basicMaxValueWatch);
    props.setValue("advancedPercentage", basicPercentageWatch);
    props.setValue("corporateMinValue", basicMinValueWatch);
    props.setValue("corporateMaxValue", basicMaxValueWatch);
    props.setValue("corporatePercentage", basicPercentageWatch);
  };

  return (
    <div className="w-50">
      <ORow>
        <OCol>
          <OTypography className="pt-3">Plano básico</OTypography>
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["basicMinValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["basicMaxValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["basicPercentage"]} />
        </OCol>
        <OCol>
          <OButton
            className="pt-3"
            onClick={() => handleReplicate()}
            dataAction="estrategia_tco_button_replicate"
            dataLabel="salvar"
          >
            ↓&nbsp;&nbsp;&nbsp;
            <TooltipContainer>Replicar para todos</TooltipContainer>
          </OButton>
        </OCol>
      </ORow>
      <ODivider type="dark" size="xxs" />
      <ORow>
        <OCol>
          <OTypography className="pt-3">Plano intermediário</OTypography>
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["intermediateMinValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["intermediateMaxValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["intermediatePercentage"]} />
        </OCol>
        <OCol />
      </ORow>
      <ODivider type="dark" size="xxs" />
      <ORow>
        <OCol>
          <OTypography className="pt-3">Plano avançado</OTypography>
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["advancedMinValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["advancedMaxValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["advancedPercentage"]} />
        </OCol>
        <OCol />
      </ORow>
      <ODivider type="dark" size="xxs" />
      <ORow>
        <OCol>
          <OTypography className="pt-3">Plano corporate</OTypography>
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["corporateMinValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["corporateMaxValue"]} />
        </OCol>
        <OCol>
          <ORFieldInput {...formFields["corporatePercentage"]} />
        </OCol>
        <OCol />
      </ORow>
    </div>
  );
};
