import { service } from "services";
import { useCallback, useEffect, useState } from "react";
import { useServiceCall } from "hooks/service-call";
import { modalManager } from "@maestro/react";
import { GetSemaphoreStepItem } from "services/onboarding/models/responses/get-semaphore-step.response";

export const useStepUpdate = (
  step?: GetSemaphoreStepItem | undefined,
  templateUuid?: string | undefined,
) => {
  const [order, setOrder] = useState(step?.order);
  const { callService: updateStep, loading: updateLoading } = useServiceCall(
    service.onboarding.updateStepOrderOnTemplate,
  );

  const updateStepOrder = useCallback(async () => {
    if (!step) return;

    if (!templateUuid) return;

    await updateStep({
      templateUuid,
      stepUuid: step?.uuid,
      order,
    });

    modalManager.hide("step-order-modal");
  }, [order, step, templateUuid, updateStep]);

  useEffect(() => {
    setOrder(step?.order);
  }, [step, setOrder]);

  return {
    updateLoading,
    updateStepOrder,
    order,
    setOrder,
  };
};
