import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useMemo } from "react";
import { GenericBankData } from "../../../../../components";
import { useQuote } from "../../quote.context";

export const BankData = () => {
  const { bankAccount, quoteData, setBankAccount } = useQuote();

  const cedenteTaxIds = useMemo(
    () => [
      ...new Set(
        quoteData.map((item) => item.recebivel.cedenteCnpj).filter(Boolean),
      ),
    ],
    [quoteData],
  );

  return (
    <div>
      <GenericBankData
        cedenteTaxIds={cedenteTaxIds}
        setBankAccount={setBankAccount}
      />
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <NextStepButton
          disabled={!bankAccount}
          title={bankAccount ? "" : "Escolha uma conta"}
        />
      </div>
    </div>
  );
};
