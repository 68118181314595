import { DataGridStatusProps } from "../../types";
import { StatusBadge } from "../status-badge";

export const DataGridStatusCellContent = ({
  badgeType,
  icon,
  tooltip,
  status,
}: DataGridStatusProps) => {
  return (
    <StatusBadge
      badgeType={badgeType}
      icon={icon && { ...icon }}
      status={status}
      tooltip={{
        text: tooltip ?? "",
        position: "top",
        maxWidth: "246px",
        justifyText: true,
      }}
    />
  );
};
