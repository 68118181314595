import { OToastManager } from "@maestro/core";
import { modalManager } from "@maestro/react";
import { isAxiosError } from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  MiniMap,
  Node,
  NodeChange,
  NodePositionChange,
  OnSelectionChangeFunc,
  ReactFlowInstance,
} from "reactflow";
import { service } from "services";
import { NodesPositionContentType } from "services/hubcreditmanager/models/requests";
import {
  ProcessorConfig,
  ViewPointType,
} from "services/hubcreditmanager/models/types/workflow/canvas.types";
import { WorkflowErrorResponse } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { env } from "utils/environments";
import { ADD_RELATIONSHIP_MODAL_ID } from "../../../pages/produto/configuracoes-de-workflows/[id]/editar/canvas/config-proposal-workflow.utils";
import { WorkflowProdutoWorkflowConfigById } from "../../../routes/workflow.route-params";
import { processorProps, relationshipProps } from "../../canvas/canvas.utils";
import { nodeTypes } from "../../canvas/node-types.utils";
import { CanvaDevtools } from "../../canvas/_compose";
import {
  CanvasContextProps,
  CanvasProviderProps,
  Processor,
  RemovedElement,
} from "./use-canvas.types";
import { getNodeIdNumber } from "./use-canvas.utils";

export const CanvasContext = createContext({} as CanvasContextProps);

export const CanvasProvider = ({ children }: CanvasProviderProps) => {
  const { id: workflowId } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!workflowId) throw new Error("No id");

  const [nodes, setNodes] = useState<Node<ProcessorConfig>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [processorOptions, setProcessorOptions] = useState<Processor[]>();
  const [currentConnection, setCurrentConnection] = useState<Connection>();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [viewPoint, setViewPoint] = useState<ViewPointType>({
    anchor: {
      x: 0,
      y: 0,
    },
    client: {
      x: 0,
      y: 0,
    },
  });
  const [processorConfigsOnCanvas, setProcessorConfigsOnCanvas] = useState<
    ProcessorConfig[]
  >([]);
  const [selectedNodes, setSelectedNodes] = useState<Node<ProcessorConfig>[]>(
    [],
  );

  const reactFlowRef = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useRef<ReactFlowInstance>();

  const { show } = modalManager;

  const onInit = useCallback((instance: ReactFlowInstance) => {
    reactFlowInstance.current = instance;
  }, []);

  const onMoveStart = useCallback(() => {
    setShowContextMenu(false);
  }, []);

  const handleClick = useCallback(() => {
    setShowContextMenu(false);
  }, []);

  const onSelectionChange: OnSelectionChangeFunc = useCallback(
    ({ nodes: _selectedNodes }) => {
      setSelectedNodes(_selectedNodes);
    },
    [],
  );

  const handleContextMenu = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      const viewport = reactFlowInstance.current?.getViewport();
      const bounds = reactFlowRef.current?.getBoundingClientRect();

      if (!!bounds && !!viewport && !!reactFlowInstance.current) {
        const reactFlowPosition = reactFlowInstance.current.project({
          x: e.clientX - bounds.left + viewport.x,
          y: e.clientY - bounds.top + viewport.y,
        });

        setViewPoint({
          anchor: {
            x: reactFlowPosition.x,
            y: reactFlowPosition.y,
          },
          client: {
            x: e.clientX,
            y: e.clientY,
          },
        });

        setShowContextMenu(true);
      }
    },
    [],
  );

  const handleRequestError = useCallback(
    (error?: unknown, previewMessage?: string) => {
      if (!error && !previewMessage) return;

      let errorMessage = previewMessage ?? "";

      if (isAxiosError<WorkflowErrorResponse>(error)) {
        const errorTitle = error.response?.data?.errorType;
        error.response?.data?.failures?.forEach((failure) => {
          errorMessage += ` ${failure.errorCode} (${failure.fieldName}).`;
        });

        OToastManager.danger({
          title: errorTitle ?? "",
          message: errorMessage,
        });
      } else if (errorMessage) {
        OToastManager.danger(errorMessage);
      }
    },
    [],
  );

  const getProcessors = useCallback(async () => {
    const { data } = await service.hubCreditManager.getProcessor();

    setProcessorOptions(data);
  }, []);

  const getCanvasData = useCallback(async () => {
    try {
      const { data } =
        await service.hubCreditManager.getWorkflowConfigCanvasData(workflowId);

      setProcessorConfigsOnCanvas(data.processorConfigs);

      const nodesAux =
        data.processorConfigs?.map((processorConfig) => ({
          ...processorProps,
          id: String(processorConfig.id),
          data: processorConfig,
          content: null,
          position: {
            x: processorConfig.canvasData.positionX,
            y: processorConfig.canvasData.positionY,
          },
        })) ?? [];

      const canvasItemsAux =
        data.canvasItems
          ?.filter((ci) => ci.type === "GROUP")
          ?.map((ci) => ({
            ...processorProps,
            id: `groupConfig-${ci.id}`,
            type: ci.type,
            data: { value: ci.value },
            position: {
              x: ci.canvasData.positionX,
              y: ci.canvasData.positionY,
            },
            style: {
              width: ci.width,
              height: ci.height,
            },
          })) ?? [];

      setNodes([...canvasItemsAux, ...nodesAux] as Node<ProcessorConfig>[]);

      const edgesAux =
        nodesAux.flatMap((node) =>
          node.data.relationshipsAsParent?.map((relationship) => ({
            ...relationshipProps,
            id: String(relationship.id),
            source: String(node.data.id),
            target: String(relationship.childRelationshipConfigId),
            label: relationship.relationshipOutput.type,
          })),
        ) ?? [];

      setEdges([...edgesAux]);
    } catch (err) {
      handleRequestError(
        err,
        "Um erro ocorreu ao tentar receber os dados do Workflow.",
      );
    }
  }, [handleRequestError, workflowId]);

  const updatePosition = useCallback(
    async (_: unknown, node: Node) => {
      try {
        const nodeId = getNodeIdNumber(node.id, node.type);

        if (!nodeId || !node.type) return;

        await service.hubCreditManager.updateConvasItemsPosition({
          workflowConfigId: Number(workflowId),
          nodes: [
            {
              id: nodeId,
              type: node.type,
              canvasData: {
                positionX: Math.round(Number(node.positionAbsolute?.x)),
                positionY: Math.round(Number(node.positionAbsolute?.y)),
              },
            },
          ],
        });
      } catch (err) {
        handleRequestError(
          err,
          "Não foi possível salvar a posição da configuração do processador.",
        );
      }
    },
    [handleRequestError, workflowId],
  );

  const updateNodesPosition = useCallback(
    async (nodesData: NodePositionChange[]) => {
      try {
        const nodePositions = nodesData.map((n) => {
          const nodeData = nodes.find((b) => b.id === n.id);
          if (!nodeData || !nodeData.type) return;

          const nodeId = getNodeIdNumber(nodeData.id, nodeData.type);
          if (!nodeId) return;

          return {
            id: nodeId,
            type: nodeData.type,
            canvasData: {
              positionX: Math.round(Number(nodeData.positionAbsolute?.x)),
              positionY: Math.round(Number(nodeData.positionAbsolute?.y)),
            },
          };
        }) as NodesPositionContentType[];

        if (!nodePositions.length) return;

        await service.hubCreditManager.updateConvasItemsPosition({
          workflowConfigId: Number(workflowId),
          nodes: nodePositions,
        });
      } catch (err) {
        handleRequestError(
          err,
          "Não foi possível salvar a posição da configuração do processador.",
        );
      }
    },
    [handleRequestError, nodes, workflowId],
  );

  const removeProcessorConfigs = useCallback(
    async (removedNodes: RemovedElement[]) => {
      try {
        await Promise.all(
          removedNodes.map(async (rm) => {
            const data = nodes.find((n) => n.id === rm.id);
            if (!data || !data.type) return;

            const nodeId = getNodeIdNumber(data.id, data.type);
            if (!nodeId) return;

            await service.hubCreditManager.removeCanvasItem({
              id: nodeId,
              type: data.type,
            });

            setNodes((nds) => applyNodeChanges(removedNodes, nds));
          }),
        );
      } catch (err) {
        handleRequestError(
          err,
          "Um erro ocorreu ao tentar excluir a configuração do processador.",
        );
      }
    },
    [handleRequestError, nodes],
  );

  const removeRelationships = useCallback(
    async (edgesRemoved: RemovedElement[]) => {
      try {
        await Promise.all(
          edgesRemoved.map(async (edgeRemoved) => {
            return service.hubCreditManager.removeProcessorConfigRelationship({
              processorConfigRelationshipId: Number(edgeRemoved.id),
            });
          }),
        );

        setEdges((nds) => applyEdgeChanges(edgesRemoved, nds));
      } catch (err) {
        handleRequestError(
          err,
          "Ocorreu um problema ao tentar remover o(s) relacionamento(s).",
        );
      }
    },
    [handleRequestError],
  );

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const changesOfTypePosition = changes.filter(
        (change) => change.type === "position" && !change.dragging,
      ) as NodePositionChange[];
      const changesOfTypeRemove = changes.filter(
        (change) => change.type === "remove",
      ) as RemovedElement[];
      const otherChanges = changes.filter((change) => change.type !== "remove");

      if (changesOfTypeRemove.length) {
        removeProcessorConfigs(changesOfTypeRemove);
      }

      if (changesOfTypePosition.length > 1) {
        updateNodesPosition(changesOfTypePosition);
      }

      setNodes((nds) => applyNodeChanges(otherChanges, nds));
    },
    [removeProcessorConfigs, updateNodesPosition],
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const changesOfTypeRemove = changes.filter(
        (change) => change.type === "remove",
      );

      const otherChanges = changes.filter((change) => change.type !== "remove");

      if (changesOfTypeRemove.length) {
        removeRelationships(changesOfTypeRemove as RemovedElement[]);
      }

      setEdges((edgs) => applyEdgeChanges(otherChanges, edgs));
    },
    [removeRelationships, setEdges],
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      setCurrentConnection(connection);
      show(ADD_RELATIONSHIP_MODAL_ID);
    },
    [show],
  );

  useEffect(() => {
    getProcessors();
    getCanvasData();
  }, [getCanvasData, getProcessors]);

  const value = useMemo(
    () => ({
      currentConnection,
      edges,
      nodes,
      selectedNodes,
      processorConfigsOnCanvas,
      processorOptions,
      reactFlowRef,
      showContextMenu,
      viewPoint,
      workflowId,
      getCanvasData,
      handleRequestError,
      setEdges,
      setNodes,
      setProcessorOptions,
    }),
    [
      currentConnection,
      edges,
      nodes,
      selectedNodes,
      processorConfigsOnCanvas,
      processorOptions,
      showContextMenu,
      viewPoint,
      workflowId,
      getCanvasData,
      handleRequestError,
    ],
  );

  return (
    <CanvasContext.Provider value={value}>
      <ReactFlow
        style={{
          background: "var(--theme-light)",
          border: "1px solid var(--theme-dark-20)",
          borderRadius: "var(--border-radius-xxs)",
        }}
        edges={edges}
        nodeTypes={nodeTypes}
        nodes={nodes}
        onClick={handleClick}
        onConnect={onConnect}
        onContextMenu={handleContextMenu}
        onEdgesChange={onEdgesChange}
        onInit={onInit}
        onMoveStart={onMoveStart}
        onNodeDragStop={updatePosition}
        onNodesChange={onNodesChange}
        onSelectionChange={onSelectionChange}
        ref={reactFlowRef}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
      >
        {children}

        <Background />
        <MiniMap
          nodeColor="var(--theme-tertiary)"
          nodeStrokeColor="var(--theme-light)"
          nodeStrokeWidth={3}
          pannable
        />
        <Controls showInteractive={false} />

        {env.PROJECT_ENV !== "prod" && <CanvaDevtools />}
      </ReactFlow>
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => useContext(CanvasContext);
