import { useServiceCall } from "hooks/service-call";
// eslint-disable-next-line no-restricted-imports
import { uniqBy } from "lodash";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { SelectedCompanies } from "./company-search.types";
import {
  buildCompanyOptions,
  cleanCompanies,
  generateAggregates,
} from "./company-search.utils";

export const useCompanySearch = (
  mode: "Sacado" | "Cedente",
  name: string,
  noRoot: boolean,
) => {
  const [selected, setSelected] = useState<SelectedCompanies[]>([]);

  const { callService, hasError, loading, value } = useServiceCall(
    service.quickfin.getAllCompanies,
  );

  const outerForm = useFormContext();

  const {
    setValue: outerSetValue,
    formState: {
      errors: { [name]: outerError },
    },
    watch: outerWatch,
  } = outerForm;

  const inputOuterWatch = outerWatch(name);

  const localForm = useForm<{ input: string | undefined }>();

  const {
    watch: localWatch,
    setError: localSetError,
    setValue: localSetValue,
    clearErrors: localClearErrors,
  } = localForm;

  const debouncedGetCompanies = useMemo(
    () => debounce(callService, 500),
    [callService],
  );

  const inputWatcher = localWatch("input");

  const selectCompany = useCallback((identification: string, label: string) => {
    setSelected((oldSelected) =>
      uniqBy(
        [
          ...oldSelected,
          {
            identification,
            label,
          },
        ],
        "identification",
      ),
    );
  }, []);

  const removeCompany = useCallback((identification: string) => {
    setSelected((oldSelected) =>
      oldSelected.filter(
        (company) => company.identification !== identification,
      ),
    );
  }, []);

  const hasntFetched =
    value === undefined && loading === false && hasError === false;

  useEffect(() => {
    if (
      (mode === "Sacado" && hasntFetched) ||
      (mode === "Cedente" && inputWatcher)
    ) {
      debouncedGetCompanies({
        CompanyType: mode,
        CompanyFilter: inputWatcher,
      });
    }
  }, [debouncedGetCompanies, inputWatcher, mode, hasntFetched]);

  useEffect(() => {
    if (hasError)
      localSetError("input", {
        message: "Erro ao buscar as empresas",
      });
  }, [hasError, localSetError]);

  useEffect(() => {
    localSetValue("input", undefined);
    outerSetValue(
      name,
      selected.map((company) => company.identification),
    );
  }, [selected, localSetValue, outerSetValue, name]);

  useEffect(() => {
    if (outerError?.message) {
      localSetError("input", {
        message: outerError.message as string,
      });
    } else {
      localClearErrors("input");
    }
  }, [outerError, localSetError, localClearErrors]);

  useEffect(() => {
    if (inputOuterWatch?.length === 0) {
      setSelected([]);
    }
  }, [inputOuterWatch?.length]);

  const options = useMemo(() => {
    const cleanedCompanies = cleanCompanies(value);
    const aggregates =
      mode === "Cedente" && !noRoot ? generateAggregates(cleanedCompanies) : [];

    return buildCompanyOptions(
      [...aggregates, ...cleanedCompanies],
      selectCompany,
    );
  }, [mode, noRoot, selectCompany, value]);

  return {
    loading,
    localForm,
    options,
    removeCompany,
    selected,
  };
};
