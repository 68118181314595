import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetOdataFidcOperationsOdataResponse } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { operationSituationMap } from "./fidc-operations.utils";

const buildCompanyDetailsLink = (Id: number, companyId: number) => {
  const baseUrl = corporateRouter.routes.fidc.product.operations.details.path;
  const searchParams = new URLSearchParams();
  searchParams.set("operationId", Id.toString());
  searchParams.set("companyId", companyId.toString());

  return baseUrl + "?" + searchParams.toString();
};

export const FidcOperationsGrid: ODataGridGeneratorConfig<
  GetOdataFidcOperationsOdataResponse["value"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma operação encontrada.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} operações)",
    },
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      caption: "Detalhes",
      role: roles.fidc.product.operationsDetailsPage.role,
      cellRender: ({ data }) =>
        data.CompanyId && (
          <DetailsLink
            href={buildCompanyDetailsLink(data.Id, data.CompanyId)}
          />
        ),
    },
    {
      dataField: "Id",
      caption: "Id",
    },
    {
      dataField: "SacadoName",
      caption: "Sacado",
    },
    {
      dataField: "PrazoMedio",
      caption: "Prazo médio",
    },
    {
      dataField: "TaxaMensal",
      caption: "Taxa mensal",
      cellRender: ({ data }) => `${(data.TaxaMensal * 100).toFixed(4)}%`,
    },
    {
      dataField: "Status",
      caption: "Situação",
      cellRender: ({ data }) =>
        getValueFromMap(operationSituationMap, data.Status),
    },
    {
      dataField: "ValorDesembolsoAntecipado",
      caption: "Valor desembolso antecipado",
      cellRender: ({ data }) => masks.currency(data.ValorDesembolsoAntecipado),
    },
    {
      dataField: "ValorVencimentoAntecipado",
      caption: "Valor vencimento antecipado",
      cellRender: ({ data }) => masks.currency(data.ValorVencimentoAntecipado),
    },
    {
      dataField: "ValorSolicitado",
      caption: "Valor solicitado",
      cellRender: ({ data }) => masks.currency(data.ValorSolicitado),
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorDesembolsoRecusado",
      caption: "Valor desembolso recusado",
      cellRender: ({ data }) => masks.currency(data.ValorDesembolsoRecusado),
    },
    {
      dataField: "ValorVencimentoRecusado",
      caption: "Valor vencimento recusado",
      cellRender: ({ data }) => masks.currency(data.ValorVencimentoRecusado),
    },
    {
      dataField: "ValorDesembolsoPendente",
      caption: "Valor desembolso pendente",
      cellRender: ({ data }) => masks.currency(data.ValorDesembolsoPendente),
    },
    {
      dataField: "ValorVencimentoPendente",
      caption: "Valor vencimento pendente",
      cellRender: ({ data }) => masks.currency(data.ValorVencimentoPendente),
    },
    {
      dataField: "TradesAntecipadosQtd",
      caption: "Trades bem sucedidos",
    },
    {
      dataField: "TradesRecusadosQtd",
      caption: "Trades recusados",
    },
    {
      dataField: "TradesPendentesQtd",
      caption: "Trades pendente",
    },
  ],
};
