import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { PostRealocacaoExecutarResponse } from "services/recebiveis-cartao/models";
import { Company, RelocationSimulation } from "./relocate.types";

interface RelocateContext {
  execute: () => Promise<unknown>;
  executionHasError: boolean;
  executionLoading: boolean;
  executionValue: PostRealocacaoExecutarResponse | undefined;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  selectedCompanies: Company[];
  setSelectedCompanies: React.Dispatch<React.SetStateAction<Company[]>>;
  simulate: () => Promise<unknown>;
  simulatedRelocations: RelocationSimulation[];
  simulationHasError: boolean;
  simulationLoading: boolean;
  stepNumber: number;
}

const relocateContext = createContext({} as RelocateContext);

interface RelocateProps {
  children: React.ReactNode;
}

export const RelocateProvider = ({ children }: RelocateProps) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);

  const {
    callService: simulateRelocation,
    hasError: simulationHasError,
    loading: simulationLoading,
    value: simulationValue,
  } = useServiceCall(service.recebiveisCartao.simulateRelocation);

  const {
    callService: executeRelocation,
    hasError: executionHasError,
    loading: executionLoading,
    value: executionValue,
  } = useServiceCall(service.recebiveisCartao.executeRelocation);

  const simulate = useCallback(
    () =>
      simulateRelocation({
        company_tax_ids: selectedCompanies.map((company) => company.tax_id),
      }),
    [selectedCompanies, simulateRelocation],
  );

  const execute = useCallback(
    () =>
      executeRelocation({
        company_tax_ids: selectedCompanies.map((company) => company.tax_id),
      }),
    [executeRelocation, selectedCompanies],
  );

  const nextStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber + 1),
    [],
  );

  const prevStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber - 1),
    [],
  );

  const reset = useCallback(() => {
    setStepNumber(0);
    setSelectedCompanies([]);
  }, []);

  useEffect(() => {
    if (simulationHasError)
      OToastManager.danger("Erro ao executar a simulação");
  }, [simulationHasError]);

  const simulatedRelocations = useMemo(
    () => simulationValue?.realocacoes ?? [],
    [simulationValue?.realocacoes],
  );

  const value = useMemo(
    () => ({
      execute,
      executionHasError,
      executionLoading,
      executionValue,
      nextStep,
      prevStep,
      reset,
      selectedCompanies,
      setSelectedCompanies,
      simulate,
      simulatedRelocations,
      simulationHasError,
      simulationLoading,
      stepNumber,
    }),
    [
      execute,
      executionHasError,
      executionLoading,
      executionValue,
      nextStep,
      prevStep,
      reset,
      selectedCompanies,
      simulate,
      simulatedRelocations,
      simulationHasError,
      simulationLoading,
      stepNumber,
    ],
  );

  return (
    <relocateContext.Provider value={value}>
      {children}
    </relocateContext.Provider>
  );
};

export const useRelocate = () => useContext(relocateContext);
