import { OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { logger } from "utils/logger";
import { useBatchUploadReceivables } from "../../batch-upload-receivables.context";
import { parseReceivablesSpreadsheet } from "./file-upload.utils";

export const useFileUpload = () => {
  const { setReceivables } = useBatchUploadReceivables();

  const [hasParseError, setHasParseError] = useState(false);

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset,
  } = useReadFile();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        const receivables = parseReceivablesSpreadsheet(result);

        setReceivables(receivables);
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    }
  }, [readerError, result, setReceivables]);

  return {
    handleAddFile,
    hasParseError: readerError || hasParseError,
    readerLoading,
    reset,
    setHasParseError,
  };
};
