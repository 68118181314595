export const reprocessConfirmationModalId =
  "reprocess-messages-confirmation-modal-id";

export const roadwalkerSearchModalId = "roadwalker-search-modal-id";

export const messageTypesMap = {
  balance: "Balance",
  boleto: "Boleto",
  feepayment: "FeePayment",
  pagfor: "PagFor",
  payment: "Payment",
  trade: "Trade",
  tradecallback: "TradeCallback",
  assignmenttradecallback: "AssignmentTradeCallback",
};

export const messageKeyMap = {
  balance: "TradeId",
  boleto: "TradeId",
  feepayment: "Identification",
  pagfor: "InvoiceId",
  payment: "TradeId",
  trade: "TradeId",
  tradecallback: "TradeId",
  assignmenttradecallback: "AssignmentId",
};

export const messageIndexMap = {
  balance: "ExternalId",
  boleto: "ExternalId",
  feepayment: "",
  pagfor: "InvoiceIdentification",
  payment: "ExternalId",
  trade: "ExternalId",
  tradecallback: "ExternalId",
  assignmenttradecallback: "AssignmentExternalId"
};

export const reprocessResutMap = {
  Success: {
    icon: "orq-status-success",
    category: "orq",
    type: "success",
    label: "Sucesso",
  },
  Retry: {
    icon: "orq-refresh",
    category: "orq",
    type: "warning",
    label: "Retry",
  },
  Skip: {
    icon: "fa-angle-double-right",
    category: "fa",
    type: "dark-40",
    label: "Skip",
  },
  Failure: {
    icon: "orq-status-disapproved",
    category: "orq",
    type: "danger",
    label: "Falha",
  },
} as const;
