import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { LimitDisbursementsInstruction } from "services/hubcreditmanager/models/types/limit/limit.types";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

const disbursementTypeMap = {
  FIXED_VALUE: "VALOR FIXO",
  TOTAL: "VALOR TOTAL",
};

export const disbursementGrid = {
  datagrid: {
    noDataText: "Nenhuma informação de desembolso encontrada",
  },
  columns: [
    {
      dataField: "type",
      caption: "Tipo de desembolso",
      format: (value) => getValueFromMap(disbursementTypeMap, value) || value,
    },
    {
      dataField: "amount",
      caption: "Valor de desembolso",
      cellRender: ({ data }) =>
        data.type === "FIXED_VALUE"
          ? masks.currency(data.amount, currencySymbol.BRL)
          : "",
    },
    {
      dataField: "bankAccount.holderName",
      caption: "Nome do portador",
    },
    {
      dataField: "bankAccount.holderTaxId",
      caption: "Documento do portador",
    },
    {
      dataField: "bankAccount.contractualParty.type",
      caption: "Tipo da parte contratual",
    },
    {
      dataField: "bankAccount.bankCode",
      caption: "Código do banco",
    },
    {
      dataField: "bankAccount.account",
      caption: "Número da conta",
    },
    {
      dataField: "bankAccount.accountDigit",
      caption: "Dígito da conta",
    },
    {
      dataField: "bankAccount.agency",
      caption: "Agência",
    },
    {
      dataField: "bankAccount.agencyDigit",
      caption: "Dígito da agência",
    },
    {
      dataField: "bankAccount.accountType",
      caption: "Tipo de conta",
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitDisbursementsInstruction>;
