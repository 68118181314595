import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useLinkTaggeado = () => {
  const {
    callService,
    loading,
    value: portals,
    hasError,
  } = useServiceCall(service.quickfin.portal);

  const { clearErrors, setError } = useFormContext();

  const getPortals = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("linkTaggeado");
    else
      setError("linkTaggeado", {
        message:
          "Erro ao buscar os portais. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getPortals();
  }, [getPortals]);

  return {
    portals,
    getPortals,
    loading,
    hasFetchError: hasError,
  };
};
