import { OTypography } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { DetailsFields } from "../../details-card.types";
import { DetailField } from "../detail-field";
import { ElementOrFallback } from "../element-or-fallback";

interface DetailsGroupProps {
  group: DetailsFields[string];
}

export const DetailsGroup = ({ group }: DetailsGroupProps) => {
  const { hasRole } = useRoles();

  const hasAnyRole = group.items?.some((item) => hasRole(item.role));

  if (group.visible === false || !hasRole(group.role) || !hasAnyRole)
    return null;

  return (
    <div className="row w-100">
      <ElementOrFallback
        node={group.title ?? null}
        fallback={
          <OTypography size="lg" type="default" className="mb-3">
            {group.title}
          </OTypography>
        }
      />
      {group.items.map((field, colIndex) => (
        <DetailField
          // eslint-disable-next-line react/no-array-index-key
          key={colIndex}
          field={field}
        />
      ))}
    </div>
  );
};
