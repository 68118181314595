import styled from "styled-components";

export const List = styled.ul`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  li {
    line-height: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const DottedUnderline = styled.span`
  text-decoration: underline;
  text-decoration-style: dotted;
`;

/** Set fixed width and center icon */
export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  width: 20px;
`;
