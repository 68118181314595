import { useServiceCall } from "hooks/service-call";
import { service } from "services";

export const useCreditConciliationDashboard = () => {
  const {
    callService: getCreditConciliationDetails,
    hasError: creditConciliationDetailHasError,
    loading: creditConciliationDetailLoading,
    value: creditConciliationDetailValue,
  } = useServiceCall(service.fidc.getCreditConciliationDetails);

  return {
    creditConciliationDetailHasError,
    creditConciliationDetailLoading,
    creditConciliationDetailValue,
    getCreditConciliationDetails,
  };
};
