import { useEffect, useMemo, useState } from "react";
import { usePropertyFieldComponent } from "./property-field.hook";
import { ProcessorConfigPropertyProps } from "./property-field.types";
import { fields } from "./property-field.utils";

export const PropertyFieldComponent = ({
  processorConfigProperty,
}: ProcessorConfigPropertyProps) => {
  const { handleSubmitProperty } = usePropertyFieldComponent();
  const [value, setValue] = useState(processorConfigProperty.value);

  useEffect(() => {
    setValue(processorConfigProperty.value);
  }, [processorConfigProperty]);

  const fieldRender = useMemo(() => {
    const type = processorConfigProperty.type;
    const fieldType = Object.keys(fields).includes(type) ? type : "DEFAULT";

    return fields[fieldType]({
      processorConfigProperty: { ...processorConfigProperty, value },
      handleSubmitProperty: async (value) => {
        setValue(value);
        await handleSubmitProperty(
          value,
          processorConfigProperty.processorPropertyId,
          processorConfigProperty.processorConfigId,
        );
      },
    });
  }, [processorConfigProperty, handleSubmitProperty, value]);

  return fieldRender;
};
