import {
  Integration,
  IntegrationRegisterHeader,
  ListIntegrationHeader,
  RegisterCompanyLayoutFileProcessor,
} from "pages/banking/pages/produto/cnab/integracoes/compose/interfaces/integration";
import {
  IntegrationResponse,
  RegisterCompanyFileProcessor,
} from "pages/banking/pages/produto/cnab/integracoes/integrations.form";
import { client } from "../client";
import { adminBankinghubEndpoints } from "./admin-bankinghub.endpoints";
import { adminBankinghubCardService } from "./card/admin-bankinghub-card.service";
import { adminBankinghubCollectionService } from "./collection/admin-bankinghub-collection.service";
import { adminBankinghubConfigHubService } from "./config-hub/admin-bankinghub-config-hub.service";
import {
  DeleteContractParamsUnlockOfBlockedUsers,
  GetAccountAccountsQueryParams,
  GetBankslipEventsQueryParams,
  GetBankslipIssueParams,
  GetCnabCnabTracingParams,
  GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyParams,
  GetFileProcessorFilesDetailsByFileIdParams,
  GetFileProcessorFilesParams,
  GetPixKeyParams,
  PatchContractByContractHolderBody,
  PostAccountAccountsByIdBody,
  PostBankingManagerCompanyBody,
  PostCnabStatementAccountBody,
  PostCnabStatementReprocessFileBody,
  PostContactBody,
  PostContractBodyInsertOfBlockedUsers,
  PutOverdraftOperationsByIdBody,
} from "./models/requests";
import {
  GetAccountAccountsPermissionsResponse,
  GetAccountAccountsResponse,
  GetAccountsPermissionsOperationsByAgencyAndAccountNumberResponse,
  GetBalanceBalanceByAccountResponse,
  GetBalanceYieldByIdResponse,
  GetBankingManagerCompaniesResponse,
  GetBankslipEventsResponse,
  GetBankslipIssueResponse,
  GetBankslipIssueSearchBankslipCIPByDigitableLineResponse,
  GetCnabCnabTracingResponse,
  GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse,
  GetCnabStatementVansResponse,
  GetContactByContactHolderResponse,
  GetFileProcessorFilesDetailsByFileId,
  GetFileProcessorFilesDetailsByFileIdCSVResponse,
  GetFileProcessorFilesResponse,
  GetOverdraftLimitByIdentificationResponse,
  GetOverdraftOperationsResponse,
  GetPixKeyResponse,
  PostAccountAccountsByIdResponse,
  PostCnabStatementAccountResponse,
  PostContactResponse,
} from "./models/responses";
import { GetPixKeysByTaxIdResponse } from "./models/responses/get-pix-keys-by-tax-id.response";

export const adminBankinghubService = {
  card: adminBankinghubCardService,
  collection: adminBankinghubCollectionService,
  configHub: adminBankinghubConfigHubService,
  addAccountBusiness(id: string) {
    return client.post(adminBankinghubEndpoints.account.accounts.byId(id));
  },

  cancelAllBankslips() {
    return client.delete(adminBankinghubEndpoints.collection.cancelAll);
  },

  getAccounts(params: GetAccountAccountsQueryParams) {
    return client.get<GetAccountAccountsResponse>(
      adminBankinghubEndpoints.account.accounts.endpoint,
      {
        params,
      },
    );
  },

  getAccountsPermissions() {
    return client.get<GetAccountAccountsPermissionsResponse>(
      adminBankinghubEndpoints.account.permissions,
    );
  },

  archiveCollectionById(collectionId: string) {
    return client.delete(
      adminBankinghubEndpoints.collection.archiveById(collectionId),
    );
  },

  cancelCollectionById(collectionId: string) {
    return client.delete(
      adminBankinghubEndpoints.collection.byValue(collectionId),
    );
  },

  searchBankslip(value: string) {
    return client.get<BankingHub.Collection>(
      adminBankinghubEndpoints.collection.byValue(value),
    );
  },

  getBankslipEvents({ collectionId, type }: GetBankslipEventsQueryParams) {
    return client.get<GetBankslipEventsResponse>(
      adminBankinghubEndpoints.collection.getEvents(collectionId),
      { params: { type } },
    );
  },

  getAdminCollections(params: GetBankslipIssueParams) {
    return client.get<GetBankslipIssueResponse>(
      adminBankinghubEndpoints.collection.endpoint,
      {
        params,
      },
    );
  },

  getBalanceByAccount(account: number, agency: number) {
    return client.get<GetBalanceBalanceByAccountResponse>(
      adminBankinghubEndpoints.balance.byAccount.byAgency(account, agency),
    );
  },

  getBalanceYieldById(identification: string) {
    return client.get<GetBalanceYieldByIdResponse>(
      adminBankinghubEndpoints.balanceYield.balanceYield.byId(identification),
    );
  },

  getBankingCompanies() {
    return client.get<GetBankingManagerCompaniesResponse>(
      adminBankinghubEndpoints.bankingManager.companies,
    );
  },

  getBankslipCIP(digitableLine: string) {
    return client.get<GetBankslipIssueSearchBankslipCIPByDigitableLineResponse>(
      adminBankinghubEndpoints.collection.searchBankslipCIP(digitableLine),
    );
  },

  getBeneficiaryInformation() {
    return client.get<BankingHub.BeneficiaryInformation>(
      adminBankinghubEndpoints.beneficiary.endpoint,
    );
  },

  getCnabStatements(params: GetCnabCnabTracingParams) {
    return client.get<GetCnabCnabTracingResponse>(
      adminBankinghubEndpoints.cnab.cnabTracing,
      {
        params,
      },
    );
  },

  getCnabTraceabilities(
    documentId: string,
    accountId: string,
    agencyId: string,
    params: GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyParams,
  ) {
    return client.get<GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse>(
      adminBankinghubEndpoints.cnab.statement.traceabilities.byDocument.byAccout.byAgency(
        documentId,
        accountId,
        agencyId,
      ),
      {
        params,
      },
    );
  },

  getFileProcessorIntegrations(
    document: string,
    params?: ListIntegrationHeader,
  ): Promise<IntegrationResponse> {
    return client.get(
      adminBankinghubEndpoints.fileProcessor.integration.listIntegrations,
      {
        headers: {
          document,
        },
        params,
      },
    );
  },

  registerCompany(body: RegisterCompanyFileProcessor): Promise<void> {
    return client.post(
      adminBankinghubEndpoints.fileProcessor.company.registerCompany,
      body,
    );
  },

  registerCompanyLayout(body: RegisterCompanyLayoutFileProcessor) {
    return client.post(
      adminBankinghubEndpoints.fileProcessor.company.registerCompanyLayout,
      body,
    );
  },

  registerIntegration(headers: IntegrationRegisterHeader, body: Integration) {
    return client.post(
      adminBankinghubEndpoints.fileProcessor.integration.registerIntegration,
      body,
      {
        headers: {
          ...headers,
        },
      },
    );
  },

  updateIntegration(
    id: string,
    status: string,
    observation: string,
  ): Promise<void> {
    return client.patch(
      adminBankinghubEndpoints.fileProcessor.integration.updateIntegration,
      {
        id,
        status,
        observation,
      },
    );
  },

  getCnabVans() {
    return client.get<GetCnabStatementVansResponse>(
      adminBankinghubEndpoints.cnab.statement.vans,
    );
  },

  getContactByHolder(contactHolder?: string) {
    return client.get<GetContactByContactHolderResponse>(
      adminBankinghubEndpoints.contact.byContactHolder(contactHolder),
    );
  },

  getOperations() {
    return client.get<GetOverdraftOperationsResponse>(
      adminBankinghubEndpoints.overdraft.operations.endpoint,
    );
  },

  getPixKey(keyValue: string, params: GetPixKeyParams) {
    return client.get<GetPixKeyResponse>(
      adminBankinghubEndpoints.pix.keys.byKey(keyValue),
      { params },
    );
  },

  getPixKeysByTaxId() {
    return client.get<GetPixKeysByTaxIdResponse>(
      adminBankinghubEndpoints.pix.keys.byTaxId,
    );
  },

  postBankingCompany(body: PostBankingManagerCompanyBody) {
    return client.post(adminBankinghubEndpoints.bankingManager.company, body);
  },

  postCnabAccount(body: PostCnabStatementAccountBody) {
    return client.post<PostCnabStatementAccountResponse>(
      adminBankinghubEndpoints.cnab.statement.account,
      body,
    );
  },

  postCnabReprocessFile(body: PostCnabStatementReprocessFileBody) {
    return client.post(
      adminBankinghubEndpoints.cnab.statement.reprocessFile,
      body,
    );
  },

  putNewOperationTime(
    operationId: string,
    body: PutOverdraftOperationsByIdBody,
  ) {
    return client.put(
      adminBankinghubEndpoints.overdraft.operations.operationById(operationId),
      body,
    );
  },

  removeAccountBusiness(id: string) {
    return client.delete(adminBankinghubEndpoints.account.accounts.byId(id));
  },

  updateContact(body: PatchContractByContractHolderBody) {
    return client.post<PostContactResponse>(
      adminBankinghubEndpoints.contact.byContactHolder(),
      body,
    );
  },

  createContact(body: PostContactBody) {
    return client.post<PostContactResponse>(
      adminBankinghubEndpoints.contact.byContactHolder(),
      body,
    );
  },

  deleteContact(body: PostContactBody) {
    return client.post<PostContactResponse>(
      adminBankinghubEndpoints.contact.byContactHolder(),
      body,
    );
  },

  getOverdraft(identification: string) {
    return client.get<GetOverdraftLimitByIdentificationResponse>(
      adminBankinghubEndpoints.overdraft.limit.byIdentification(identification),
    );
  },

  insertBlockUsers(body: PostContractBodyInsertOfBlockedUsers) {
    return client.post(
      adminBankinghubEndpoints.account.accounts.blockUser(
        body.accountNumber,
        body.agency,
      ),
      { UserDocument: body.userDocument },
    );
  },

  unlockBlockUsers(body: DeleteContractParamsUnlockOfBlockedUsers) {
    return client.delete(
      adminBankinghubEndpoints.account.accounts.unlockUser(
        body.accountNumber,
        body.agency,
      ),
      { data: { UserDocument: body.userDocument } },
    );
  },

  getFiles(params?: GetFileProcessorFilesParams) {
    return client.get<GetFileProcessorFilesResponse>(
      adminBankinghubEndpoints.fileProcessor.files.endpoint,
      { params },
    );
  },

  getFileDetails(
    fileId: string,
    params: GetFileProcessorFilesDetailsByFileIdParams,
  ) {
    return client.get<
      | GetFileProcessorFilesDetailsByFileId
      | GetFileProcessorFilesDetailsByFileIdCSVResponse
    >(adminBankinghubEndpoints.fileProcessor.files.details.byFileId(fileId), {
      params,
    });
  },

  insertListOfBlockedUsers(body: PostAccountAccountsByIdBody) {
    return client.post<PostAccountAccountsByIdResponse>(
      adminBankinghubEndpoints.account.accounts.byId(body.accountId),
      body,
    );
  },

  async getAccountsPermissionsOperationsByAgencyAndAccountNumber(
    agency: string,
    accountNumber: string,
  ) {
    return client.get<GetAccountsPermissionsOperationsByAgencyAndAccountNumberResponse>(
      adminBankinghubEndpoints.account.accounts.operations(
        agency,
        accountNumber,
      ),
    );
  },
};
