import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowProdutoStatusById } from "../../../../routes/workflow.route-params";
import {
  statusFormDefaultValues,
  statusFormValidationSchema,
} from "./configurable-status-form.schemas";
import { StatusFormProps } from "./configurable-status-form.types";

export const useStatusForm = ({ mode }: StatusFormProps) => {
  const navigate = useNavigate();
  const { id } = useParams<WorkflowProdutoStatusById>();

  const {
    callService: getAllConfigurableStatus,
    loading: loadingAllConfigurableStatus,
    value: allConfigurableStatusValue,
    hasError: allConfigurableStatusHasError,
  } = useServiceCall(service.hubCreditManager.getConfigurableStatus);

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(statusFormValidationSchema),
    defaultValues: statusFormDefaultValues,
  });
  const { handleSubmit, watch, setValue, trigger, reset } = form;
  const colorWatcher = watch("color");
  const iconName = watch("iconName");
  const labelName = watch("name");

  const upsertStatus = handleSubmit(async (values) => {
    const isValid = await trigger();
    if (!isValid) return;

    try {
      setLoading(true);

      if (mode === "add") {
        await service.hubCreditManager.createConfigurableStatus({
          ...values,
          endOnStart: values.endOnStart.includes("true"),
        });
      } else {
        await service.hubCreditManager.updateConfigurableStatus({
          ...values,
          id: Number(id),
          endOnStart: values.endOnStart.includes("true"),
        });
      }

      OToastManager.success("Status salvo com sucesso.");

      navigate(
        corporateRouter.routes.workflow.product.configurations.status.path,
      );
    } catch {
      OToastManager.danger("Houve um erro ao criar o status.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (mode === "edit") getAllConfigurableStatus();
  }, [getAllConfigurableStatus, mode]);

  useEffect(() => {
    if (!allConfigurableStatusValue) return;

    const status = allConfigurableStatusValue.find((s) => s.id === Number(id));

    if (!status) return;

    reset({
      ...status,
      endOnStart: status.endOnStart ? ["true"] : [],
    });
  }, [reset, allConfigurableStatusValue, id]);

  useEffect(() => {
    if (mode === "edit" && allConfigurableStatusHasError) {
      OToastManager.danger(
        "Não foi possível buscar os dados deste status configurável. Por favor, tente novamente mais tarde.",
      );
    }
  }, [allConfigurableStatusHasError, mode]);

  return {
    allConfigurableStatusValue,
    colorWatcher,
    form,
    iconName,
    labelName,
    loading,
    loadingAllConfigurableStatus,
    setValue,
    upsertStatus,
  };
};
