import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { empresasRoles } from "../../../roles/empresas.roles";
import { companyMonitorGrid } from "../company-monitor.grid";

const grid = companyMonitorGrid("PAYER");

export const PayerCompanies = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Sacado Simples" />}
      actions={
        <>
          <StandardLink
            role={empresasRoles.product.importPayerPage.role}
            href={corporateRouter.routes.empresas.product.payer.addPayer.path}
          >
            Registrar sacado simples
          </StandardLink>
          <StandardLink
            role={empresasRoles.product.batchImportPayerPage.role}
            href={
              corporateRouter.routes.empresas.product.payer.batchImport.path
            }
          >
            Importar em lote
          </StandardLink>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
