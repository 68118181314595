import { client } from "../../client";
import { hubfxEndpoints } from "../hubfx.endpoints";
import {
  PatchAdminNatureBody,
  PatchAdminNatureDocumentConfigsBody,
  PatchAdminNatureDocumentGroupsBody,
  PatchAdminNatureGenericFieldTypesBody,
  PostAdminNatureBody,
  PostAdminNatureDocumentConfigsBody,
  PostAdminNatureDocumentGroupsBody,
  PostAdminNatureGenericFieldTypesBody,
} from "./models/requests";
import {
  GetAdminNatureByIdResponse,
  GetAdminNatureDocumentConfigsByIdResponse,
  GetAdminNatureDocumentConfigsResponse,
  GetAdminNatureDocumentGroupsByIdResponse,
  GetAdminNatureDocumentGroupsResponse,
  GetAdminNatureGenericFieldTypesByIdResponse,
  GetAdminNatureGenericFieldTypesResponse,
  GetAdminNatureResponse,
} from "./models/responses";

export const hubFxNatureService = {
  getNatures() {
    return client.get<GetAdminNatureResponse>(
      hubfxEndpoints.admin.nature.endpoint,
    );
  },
  getNatureGenericFieldTypes() {
    return client.get<GetAdminNatureGenericFieldTypesResponse>(
      hubfxEndpoints.admin.nature.genericFieldTypes.endpoint,
    );
  },
  getNatureDocumentConfigs() {
    return client.get<GetAdminNatureDocumentConfigsResponse>(
      hubfxEndpoints.admin.nature.documentConfigs.endpoint,
    );
  },
  getNatureDocumentGroups() {
    return client.get<GetAdminNatureDocumentGroupsResponse>(
      hubfxEndpoints.admin.nature.documentGroups.endpoint,
    );
  },
  getNatureDetails(id: string) {
    return client.get<GetAdminNatureByIdResponse>(
      hubfxEndpoints.admin.nature.byId(id),
    );
  },
  getNatureGenericFieldTypeDetails(id: string) {
    return client.get<GetAdminNatureGenericFieldTypesByIdResponse>(
      hubfxEndpoints.admin.nature.genericFieldTypes.byId(id),
    );
  },
  getNatureDocumentConfigDetails(id: string) {
    return client.get<GetAdminNatureDocumentConfigsByIdResponse>(
      hubfxEndpoints.admin.nature.documentConfigs.byId(id),
    );
  },
  getNatureDocumentGroupDetails(id: string) {
    return client.get<GetAdminNatureDocumentGroupsByIdResponse>(
      hubfxEndpoints.admin.nature.documentGroups.byId(id),
    );
  },
  createNatures(body: PostAdminNatureBody) {
    return client.post(hubfxEndpoints.admin.nature.endpoint, body);
  },
  createNatureGenericFieldTypes(body: PostAdminNatureGenericFieldTypesBody) {
    return client.post(
      hubfxEndpoints.admin.nature.genericFieldTypes.endpoint,
      body,
    );
  },
  createNatureDocumentConfigs(body: PostAdminNatureDocumentConfigsBody) {
    return client.post(
      hubfxEndpoints.admin.nature.documentConfigs.endpoint,
      body,
    );
  },
  createNatureDocumentGroups(body: PostAdminNatureDocumentGroupsBody) {
    return client.post(
      hubfxEndpoints.admin.nature.documentGroups.endpoint,
      body,
    );
  },
  updateNatures(body: PatchAdminNatureBody) {
    return client.patch(hubfxEndpoints.admin.nature.endpoint, body);
  },
  updateNatureGenericFieldTypes(body: PatchAdminNatureGenericFieldTypesBody) {
    return client.patch(
      hubfxEndpoints.admin.nature.genericFieldTypes.endpoint,
      body,
    );
  },
  updateNatureDocumentConfigs(body: PatchAdminNatureDocumentConfigsBody) {
    return client.patch(
      hubfxEndpoints.admin.nature.documentConfigs.endpoint,
      body,
    );
  },
  updateNatureDocumentGroups(body: PatchAdminNatureDocumentGroupsBody) {
    return client.patch(
      hubfxEndpoints.admin.nature.documentGroups.endpoint,
      body,
    );
  },
  deleteNatures(id: number) {
    return client.delete(hubfxEndpoints.admin.nature.byId(id));
  },
  deleteNatureGenericFieldTypes(id: number) {
    return client.delete(
      hubfxEndpoints.admin.nature.genericFieldTypes.byId(id),
    );
  },
  deleteNatureDocumentConfigs(id: number) {
    return client.delete(hubfxEndpoints.admin.nature.documentConfigs.byId(id));
  },
  deleteNatureDocumentGroups(id: number) {
    return client.delete(hubfxEndpoints.admin.nature.documentGroups.byId(id));
  },
};
