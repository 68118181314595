import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { StatusBadge } from "components/status-badge";
import dayjs from "dayjs";
import { getValueFromMap } from "utils/get-value-from-map";
import { migrationStatusMap } from "../manage-desk-migrations.utils";
import { DeskMigration } from "./desk-migration-details.types";

export const buildDeskMigrationFields = (
  deskMigration: DeskMigration,
): DetailsFields => {
  const currentDesk = deskMigration.workflowMigration.actualDesk;
  const newDesk = deskMigration.workflowMigration.deskToMigrate;

  const status = getValueFromMap(
    migrationStatusMap,
    deskMigration.workflowMigration.actualMigrationStatus,
  );

  return {
    status: {
      items: [
        {
          label: "Status",
          value: status ? (
            <StatusBadge type={status.type}>{status.label}</StatusBadge>
          ) : (
            deskMigration.workflowMigration.actualMigrationStatus
          ),
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Motivo reprovação",
          visible: !!deskMigration.workflowMigration.reproveDescription,
          value: deskMigration.workflowMigration.reproveDescription,
          grid: { xs: 12, md: 4 },
        },
      ],
    },
    details: {
      items: [
        {
          label: "CPF/CNPJ",
          value: masks.cpfCnpj(deskMigration.workflowMigration.taxId),
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Identificador da migração",
          value: deskMigration.workflowMigration.id,
          grid: { xs: 12, md: 4 },
        },
      ],
    },
    dates: {
      items: [
        {
          label: "Data criação",
          value: dayjs(deskMigration.workflowMigration.createdDate).format(
            "DD-MM-YYYY HH:mm",
          ),
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Data atualização",
          value: dayjs(deskMigration.workflowMigration.updatedDate).format(
            "DD-MM-YYYY HH:mm",
          ),
          grid: { xs: 12, md: 4 },
        },
      ],
    },
    currentDesk: {
      title: "Segmento a migrar",
      visible: !!currentDesk,
      items: [
        {
          label: "Mesa",
          value: `${currentDesk?.desk.deskCode} - ${currentDesk?.desk.deskName} (${currentDesk?.desk.segmentGroup})`,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Officer",
          value: currentDesk?.officer.name,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "CGE",
          value: currentDesk?.officer.cge,
          grid: { xs: 12, md: 4 },
        },
      ],
    },
    newDesk: {
      title: "Novo segmento",
      visible: !!newDesk,
      items: [
        {
          label: "Mesa",
          value: `${newDesk?.desk.deskCode} - ${newDesk?.desk.deskName} (${newDesk?.desk.segmentGroup})`,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Officer",
          value: newDesk?.officer.name,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "CGE",
          value: newDesk?.officer.cge,
          grid: { xs: 12, md: 4 },
        },
      ],
    },
  };
};
