import { env } from "utils/environments";

export const b2bagroEndpoints = {
  admin: {
    proposals: {
      endpoint: `${env.API_B2B_AGRO}/admin/proposals`,
      byProposalId: {
        endpoint: (proposalId: string) =>
          `${env.API_B2B_AGRO}/admin/proposals/${proposalId}`,
        documents: (proposalId: string) =>
          `${env.API_B2B_AGRO}/admin/proposals/${proposalId}/documents`,
        pendencies: (proposalId: string) =>
          `${env.API_B2B_AGRO}/admin/proposals/${proposalId}/pendencies`,
        updateStatus: (proposalId: string) =>
          `${env.API_B2B_AGRO}/admin/proposals/${proposalId}/status`,
        editGuarantee: (proposalId: string) =>
          `${env.API_B2B_AGRO}/admin/proposals/${proposalId}/edit-guarantee`,
      },
    },
  },
};
