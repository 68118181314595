import { OCol, OField, OLoader, ORow } from "@maestro/react";
import { masks } from "@maestro/utils";
import { SelectSearch } from "components/select-search";
import { FormProvider } from "react-hook-form";
import { useGenericBankData } from "./generic-bank-data.hook";
import { BankAccountWithTaxId } from "./generic-bank-data.types";
import { remapHookFormRegister } from "./generic-bank-data.utils";

interface GenericBankDataProps {
  cedenteTaxIds: string[];
  setBankAccount: (account: BankAccountWithTaxId | undefined) => void;
}

export const GenericBankData = ({
  setBankAccount,
  cedenteTaxIds,
}: GenericBankDataProps) => {
  const {
    form,
    cedenteTaxIdsOptions,
    errorMessage,
    accountsOptions,
    loading,
    clearTaxId,
  } = useGenericBankData(cedenteTaxIds, setBankAccount);

  const hasAccountsOptions = accountsOptions.length > 0;

  return (
    <FormProvider {...form}>
      <ORow>
        <OCol xs={12} md={6}>
          <OField
            label="CNPJ da conta de desembolso"
            htmlFor="taxId"
            message={errorMessage}
            error={!!errorMessage}
            className="w-100"
          >
            <SelectSearch
              id="taxId"
              name="taxId"
              placeholder="Digite ou selecione um CNPJ"
              options={cedenteTaxIdsOptions}
              onClear={clearTaxId}
              customizeText={masks.cnpj}
              innerProps={{
                input: {
                  ...remapHookFormRegister(form.register("taxId")),
                  error: !!errorMessage,
                  maxlength: 18,
                },
              }}
              className="w-100"
            />
          </OField>
        </OCol>
        <OCol xs={12} md={6}>
          <OField
            label="Conta de desembolso"
            htmlFor="account"
            className="w-100"
          >
            <div className="d-flex align-items-center gap-2">
              <SelectSearch
                id="account"
                name="account"
                placeholder={
                  hasAccountsOptions ? "Selecione" : "Primeiro informe o CNPJ"
                }
                disabled={!hasAccountsOptions}
                options={accountsOptions}
                className="w-100"
                innerProps={{ dropdown: { positionX: "left" } }}
              />

              {loading && <OLoader size="sm" />}
            </div>
          </OField>
        </OCol>
      </ORow>
    </FormProvider>
  );
};
