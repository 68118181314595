import { OField } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { loanContractsTemplatesFormGrid } from "./loan-contracts-templates-form.grid";
import { ContractTemplate } from "./loan-contracts-templates.types";

export interface LoanContractsTemplatesFormValues {
  contractTemplates: ContractTemplate[];
}

interface LoanContractsTemplatesFormProps {
  edit?: boolean;
  detailsLoanContractsConfigId?: string;
}

export const LoanContractsTemplatesForm = ({
  edit,
  detailsLoanContractsConfigId,
}: LoanContractsTemplatesFormProps) => {
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<LoanContractsTemplatesFormValues>();

  const watchContractTemplates = watch("contractTemplates");

  const grid = useMemo(
    () =>
      loanContractsTemplatesFormGrid(
        watchContractTemplates,
        (contractTemplates) => {
          setValue("contractTemplates", contractTemplates);
        },
        { edit, detailsLoanContractsConfigId },
      ),
    [watchContractTemplates, edit, detailsLoanContractsConfigId, setValue],
  );

  return (
    <>
      {errors?.contractTemplates?.message && (
        <OField error message={errors.contractTemplates.message} />
      )}
      <ODataGridGenerator grid={grid} />
    </>
  );
};
