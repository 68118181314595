import { OButton } from "@maestro/react";
import { useNavigate } from "react-router-dom";

interface GoToHomeButtonProps {
  clearError: () => void;
}

export const GoToHomeButton = ({ clearError }: GoToHomeButtonProps) => {
  const navigate = useNavigate();

  return (
    <OButton
      dataAction="error_boundary:botao:voltar_pagina_inicial"
      dataLabel="voltar_pagina_inicial"
      outline
      type="default"
      className="mt-3"
      onClick={() => {
        clearError();
        navigate("/");
      }}
    >
      Voltar para a página inicial
    </OButton>
  );
};
