import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { Profile } from "../../../components/role-assignments/profile-selector/profile-selector.types";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";
import {
  attachUserFormDefaultValues,
  attachUserFormValidationSchema,
  checkboxValue,
} from "./attach-user.form";
import { buildDataSource } from "./attach-user.grid";
import {
  AttachUserForm,
  AttachUserSearchParams,
  userDataSearchParam,
} from "./attach-user.types";
import { buildUserProfiles } from "./attach-user.utils";

export const useAttachUser = () => {
  const { value: initialValues } =
    useParseFromSearchParams<AttachUserSearchParams>(userDataSearchParam);
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();

  if (!identification) throw new Error("No identification");

  const [profiles, setProfiles] = useState<Profile[]>([]);

  const navigate = useNavigate();

  const { callService: attachUser, loading } = useServiceCall(
    service.onboardingRelationships.attachUser,
  );

  const form = useForm<AttachUserForm>({
    defaultValues: attachUserFormDefaultValues,
    resolver: yupResolver(attachUserFormValidationSchema),
  });
  const { handleSubmit, reset, setValue } = form;

  const dataSource = useMemo(() => buildDataSource(setProfiles), []);

  useEffect(() => {
    reset({
      ...initialValues,
      taxId: identification,
    });
  }, [identification, initialValues, reset]);

  useEffect(() => {
    const parsedProfiles: string[] =
      JSON.parse(initialValues?.profiles ?? "") ?? [];
    const decodedProfiles = parsedProfiles.map((uuid) => window.atob(uuid));
    setValue("profiles", buildUserProfiles(decodedProfiles, profiles));
  }, [initialValues?.profiles, profiles, setValue]);

  const submit = handleSubmit(async (values) => {
    const { success } = await attachUser({
      taxId: values.taxId,
      name: values.name,
      email: values.email,
      phoneNumber: values.phone,
      sendEmail: !!values.sendWelcomeEmail?.includes(checkboxValue),
    });

    if (success) {
      OToastManager.success("Usuário vinculado com sucesso");
      navigate(corporateRouter.routes.usuarios.customer.path);
    } else {
      OToastManager.danger("Não foi possível vincular o usuário");
    }
  });

  return {
    dataSource,
    form,
    identification,
    initialValues,
    loading,
    submit,
  };
};
