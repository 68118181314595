import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react";
import { useCallback, useMemo, useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { NotificationConfigOdataDataItem } from "services/raven/models/responses";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { buildGrid } from "./raven-templates.grid";

export const RavenTemplates = () => {
  const { routes } = corporateRouter;

  const gridRef = useRef<DataGrid>(null);

  const dataSource =
    dataSourceCustomStoreGenerator<NotificationConfigOdataDataItem>(
      (loadOptions) =>
        service.raven
          .getNotificationConfig(loadOptions as any)
          .then(({ data }) => ({
            data: data.data,
            totalCount: data.total_count,
          }))
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar profiles";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      {
        customStoreOptions: {
          remove: async (data) => {
            try {
              await service.raven.deleteNotificationByName(data.name);
              OToastManager.success("Template removido com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível remover o template");
            }
          },
        },
      },
    );

  const deleteRow = useCallback(
    (id: number) => gridRef.current?.instance.deleteRow(id),
    [],
  );

  const grid = useMemo(() => buildGrid(deleteRow), [deleteRow]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Templates"
          description="Veja todos os templates e suas informações principais"
        />
      }
      actions={
        <RouterButton
          dataAction="ferramentas_raven:botao:adicionar"
          dataLabel="adicionar"
          href={routes.ferramentas.product.raven.templates.add.path}
          role={roles.ferramentas.product.ravenAddPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
