import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddLink } from "./add-link.hook";

export const AddLink = () => {
  const { books, catalogs, form, loading, onSubmit, products } = useAddLink();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar vínculo" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="productUuid"
                name="productUuid"
                placeholder="Selecione"
                label="Produto"
                key={products.length}
              >
                {products.map((product) => (
                  <OOption key={product.code} value={product.uuid}>
                    {product.name}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="bookUuid"
                name="bookUuid"
                placeholder="Selecione"
                label="Book"
                key={books.length}
              >
                {books.map((book) => (
                  <OOption key={book.code} value={book.uuid}>
                    {book.name}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="catalogUuid"
                name="catalogUuid"
                placeholder="Selecione"
                label="Catálogo"
                key={catalogs.length}
              >
                {catalogs.map((catalog) => (
                  <OOption key={catalog.code} value={catalog.uuid}>
                    {catalog.name}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
