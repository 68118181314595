import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { PropertyTypeForm } from "../../property-type-form.form";

export const useMaskSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDiligenceStepPropertyTypeMasks,
  );

  const { clearErrors, setError } = useFormContext<PropertyTypeForm>();

  const getMasks = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("mask");
    else
      setError("mask", {
        message:
          "Erro ao buscar as máscaras. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getMasks();
  }, [getMasks]);

  return {
    masks: value?.response,
    getMasks,
    loading,
    hasFetchError: hasError,
  };
};
