import { DetailsWithoutCard } from "components/details-card";
import { ValidationErrorComponent } from "../../../../../../../../../../components/validation-error-component";
import { useConsumerUnitDetails } from "../../../../../../details/consumer-unit-details.hook";
import { consumerUnitInfoFields } from "./consumer-unit-info.details";

export const ConsumerUnitInfo = () => {
  const { error, getConsumerUnitDetails, hasError, loading, value } =
    useConsumerUnitDetails();

  return (
    <DetailsWithoutCard
      loading={loading}
      hasError={hasError}
      value={value?.response}
      fields={consumerUnitInfoFields}
      errorComponent={
        <ValidationErrorComponent
          error={error}
          callback={getConsumerUnitDetails}
          title="Erro ao buscar a migração"
        />
      }
    />
  );
};
