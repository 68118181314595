import { OCol, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { AdequacyOptionSelector } from "./_compose";
import { useRecommendedAdequacyOptions } from "./recommended-adequacy-options.hook";

export const RecommendedAdequacyOptionsPage = () => {
  const {
    canUpdate,
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getRecommendedAdequacyOptions,
    submit,
    submitLoading,
  } = useRecommendedAdequacyOptions();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adequadoras recomendadas" />}
      actions={
        <>
          {canUpdate && (
            <LoadingButton loading={submitLoading} onClick={submit}>
              Salvar
            </LoadingButton>
          )}
        </>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <ValidationErrorComponent
              error={fetchError}
              title="Erro ao carregar as adequadoras recomendadas"
              callback={getRecommendedAdequacyOptions}
            />
          }
          value={fetchValue}
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol xs={12} md={6}>
                  <AdequacyOptionSelector />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
