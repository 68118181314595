import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  InterestType,
  MapperInterestType,
} from "services/admin-bankinghub/collection/models/types";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";
import { FieldName, PercentOrAmountInput } from "../percent-or-amount-input";
import { assembleDescription } from "./interest-fields.utils";

export const InterestFields = () => {
  const { setValue, watch } =
    useFormContext<UpsertCollectionAgreementFormFields>();
  const typeWatched = watch("settings.interest.type");
  const daysToStartWatched = watch("settings.interest.daysToStart");

  const disabled = useMemo(
    () => !typeWatched || typeWatched === InterestType.NOT_APPLICABLE,
    [typeWatched],
  );

  const description = useMemo(
    () => assembleDescription(disabled, daysToStartWatched),
    [disabled, daysToStartWatched],
  );

  const handleSelect = useCallback(
    (option: InterestType) => {
      if (option === InterestType.NOT_APPLICABLE) {
        setValue("settings.interest.percentOrAmount", undefined, {
          shouldValidate: true,
        });
        setValue("settings.interest.daysToStart", undefined, {
          shouldValidate: true,
        });
        return;
      }
      setValue("settings.interest.percentOrAmount", 0);
    },
    [setValue],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <OTypography type="dark">Juros</OTypography>

      <ORow align="center" gap={3}>
        <OCol>
          <ORFieldSelect
            id="settings.interest.type"
            name="settings.interest.type"
            label="Tipo de juros"
            placeholder="Selecione uma opção"
          >
            {Object.values(InterestType).map((value) => (
              <OOption
                value={value}
                key={value}
                onClick={() => handleSelect(value)}
              >
                {MapperInterestType[value]}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <PercentOrAmountInput
          fieldName={FieldName.Interest}
          typeWatched={typeWatched}
          disabled={disabled}
        />
        <OCol>
          <ORFieldInput
            id="settings.interest.daysToStart"
            name="settings.interest.daysToStart"
            label="Início do juros (em dias)"
            placeholder={disabled ? "Não se aplica" : "Insira o número de dias"}
            description={description}
            disabled={disabled}
            tag="number"
          />
        </OCol>
      </ORow>
    </div>
  );
};
