export const occurrenceTypeMap = {
  DOCUMENT: "Documento",
  ENTITY: "Entidade",
};

export const documentTypeMap = {
  INDEFINIDO: "Indefinido",
  CONTRATO_DE_UNIAO_ESTAVEL: "Contrato de união estável",
  IDENTIDADE: "Identidade",
  IDENTIDADE_CONJUGE: "Identidade cônjuge",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  COMPROVANTE_DE_RESIDENCIA_ELETRONICO: "Comprovante de residência eletrônico",
  CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
  IDENTIFICACAO: "Identidade",
  ESTATUTO_SOCIAL: "Estatuto social",
  PROCURACAO: "Procuração",
  GIIN: "GIIN",
  BALANCO_DRE: "DRE",
  QUADRO_SOCIETARIO: "Quadro societário",
  ATA_ELEICAO_DIRETORIA: "Ata de eleição de diretoria",
  CONTRATO_SOCIAL_REQUERIMENTO_EMPRESARIO_CCMEI: "Contrato social",
};
