import { OToastManager } from "@maestro/react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetDashboardOverallStatusParams } from "services/fidc/models/requests";
import { GetDashboardOverallStatusResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { useFidcFunds } from "../../../hooks";

const dataSourceGenerator = (taxId: string) =>
  dataSourceCustomStoreGenerator<
    GetDashboardOverallStatusResponse["data"][number]
  >((loadOptions) => {
    const params: GetDashboardOverallStatusParams = {
      cnpj: taxId,
      skip: loadOptions.skip ?? 0,
      take: loadOptions.take ?? 10,
    };
    return service.fidc
      .getBuyers(params)
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar clientes.";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const useEditBankSlip = () => {
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();
  const { funds } = useFidcFunds();
  const form = useForm();
  const { watch } = form;
  const selectedFundWatch: string = watch("selectedFund");

  useEffect(() => {
    if (selectedFundWatch) {
      setDataSource(dataSourceGenerator(selectedFundWatch ?? ""));
    }
  }, [funds, selectedFundWatch]);

  return {
    dataSource,
    form,
    selectedFundWatch,
  };
};
