import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  attachUserFormDefaultValues,
  attachUserFormValidationSchema,
  checkboxValue,
} from "./attach-user.form";
import { AttachUserForm, userDataSearchParam } from "./attach-user.types";

export const useAttachUser = () => {
  const { value: initialValues } =
    useParseFromSearchParams<Partial<AttachUserForm>>(userDataSearchParam);

  const { callService: attachUser, loading } = useServiceCall(
    service.onboardingRelationships.attachUser,
  );

  const navigate = useNavigate();

  const form = useForm<AttachUserForm>({
    defaultValues: attachUserFormDefaultValues,
    resolver: yupResolver(attachUserFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await attachUser({
          taxId: values.taxId,
          name: values.name,
          email: values.email,
          phoneNumber: values.phone,
          sendEmail: !!values.sendWelcomeEmail?.includes(checkboxValue),
        });

        if (success) {
          OToastManager.success("Usuário vinculado com sucesso");
          navigate(corporateRouter.routes.cadastro.customer.companyActs.path);
        } else {
          OToastManager.danger("Não foi possível vincular o usuário");
        }
      }),
    [attachUser, handleSubmit, navigate],
  );

  return {
    form,
    initialValues,
    loading,
    submit,
  };
};
