import DataSource from "devextreme/data/data_source";
import type dxDataGrid from "devextreme/ui/data_grid";
import React from "react";
import { ODataGridProps } from "./o-data-grid.types";

export const setExternalRef = <T>(ref: React.ForwardedRef<T>, element: T) => {
  if (ref) {
    if ("current" in ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = element;
    } else {
      ref(element);
    }
  }
};

/**
 * Try to `updateDimensions()` multiple times, because we don't know
 * when the grid is actually ready. Previously, I was using a single
 * `setTimeout` with a bigger interval, but it was too long. Since
 * we can't know the exact time, we try to `updateDimensions()` in rapid
 * succession so that it works as soon as possible. I'm not certain if
 * there are performance issues with this solution, but I can always
 * tweak the parameters.
 */
export const updateDimensions = (
  dataGrid: dxDataGrid,
  attempts: number,
  max: number,
  interval: number,
) => {
  if (attempts > max) return;
  dataGrid.updateDimensions();
  setTimeout(() => {
    updateDimensions(dataGrid, attempts + 1, max, interval);
  }, interval);
};

export const removeKeyFromDataSourceItems = (
  dataSource: ReturnType<DataSource["items"]>,
) =>
  dataSource.map((e) => {
    delete e?.__KEY__;
    return e;
  });

export const disableRows = <T>(
  evt: Parameters<NonNullable<ODataGridProps<T>["onEditorPreparing"]>>[0],
  disableCriteria: (data: T) => boolean,
) => {
  if (
    evt.type === "selection" &&
    evt.parentType === "dataRow" &&
    evt.row?.data
  ) {
    // eslint-disable-next-line no-param-reassign
    evt.editorOptions.disabled = disableCriteria(evt.row.data);
  }
};

export const blockDisabledRows = <T>(
  evt: Parameters<NonNullable<ODataGridProps<T>["onSelectionChanged"]>>[0],
  disableCriteria: (data: T) => boolean,
) => {
  const disabledRows = evt.selectedRowsData.filter(disableCriteria);
  const disabledRowsKeys = disabledRows.map((rowData) =>
    evt.component.keyOf(rowData),
  );
  evt.component.deselectRows(disabledRowsKeys);
};
