import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useParams, useNavigate } from "react-router-dom";
import {
  OButton,
  OCol,
  OField,
  OInputText,
  OLoader,
  ORow,
  OSwitch,
} from "@maestro/react";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { roles } from "roles/roles";
import { useSemaphoreConsultDetails } from "./details.hook";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "../../../../../routes/ferramentas.route-params";

export const SemaphoreConsultDetails = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  const { loading, semaphoreEntityItem } = useSemaphoreConsultDetails(
    uuid as string,
  );

  const navigate = useNavigate();

  const redirectDetailLink = (templateUuid: string) => {
    navigate(
      corporateRouter.routes.ferramentas.product.onboarding.semaphore.configuration.template.details.path(
        { uuid: templateUuid },
      ),
    );
  };

  return (
    <>
      {loading && <OLoader absolute backdrop />}
      <FormTemplate
        pageTitle={
          <PageTitle
            title="Consulta de semáforo"
            description="Detalhes da consulta de semáforo"
          />
        }
        actions={
          semaphoreEntityItem && (
            <OButton
              dataAction="acoes:botao:redirecionar"
              dataLabel="redirecionar"
              onClick={() =>
                redirectDetailLink(semaphoreEntityItem.semaphoreTemplateUuid)
              }
              role={
                roles.ferramentas.product.semaphoreConfigTemplateDetails.role
              }
            >
              Visualizar template utilizado
            </OButton>
          )
        }
      >
        {semaphoreEntityItem && (
          <CardTemplate>
            <>
              <ORow>
                <OCol xs={4} md={4} xl={4}>
                  <OField htmlFor="taxId" label="CNPJ / CPF">
                    <OInputText
                      disabled
                      id="taxId"
                      name="taxId"
                      dataAction="consulta_semaforo_step:input:taxId"
                      dataLabel="CNPJ / CPF"
                      value={semaphoreEntityItem.taxId}
                    />
                  </OField>
                </OCol>
                <OCol xs={4} md={4} xl={4}>
                  <OField htmlFor="requestedBy" label="Requisitado por">
                    <OInputText
                      disabled
                      id="requestedBy"
                      name="requestedBy"
                      dataAction="consulta_semaforo_step:input:requestedBy"
                      dataLabel="Requisitado por"
                      value={semaphoreEntityItem.requestedBy}
                    />
                  </OField>
                </OCol>
                <OCol id="createdDate" xs={4} md={4} xl={4}>
                  <OField htmlFor="createdDate" label="Requisitado em">
                    <OInputText
                      disabled
                      id="createdDate"
                      name="createdDate"
                      dataAction="consulta_semaforo_step:input:createdDate"
                      dataLabel="Requisitado em"
                      value={dayjs(semaphoreEntityItem.createdDate).format(
                        "DD/MM/YYYY HH:mm:ss",
                      )}
                    />
                  </OField>
                </OCol>
              </ORow>

              <ORow>
                <OCol xs={4} md={4} xl={4}>
                  <OField htmlFor="color" label="Cor da resposta">
                    <OInputText
                      disabled
                      id="color"
                      name="color"
                      dataAction="consulta_semaforo_step:input:color"
                      dataLabel="Cor da resposta"
                      value={semaphoreEntityItem.color}
                    />
                  </OField>
                </OCol>
                <OCol xs={8} md={8} xl={8}>
                  <OField
                    htmlFor="responseMessage"
                    label="Mensagem da resposta"
                  >
                    <OInputText
                      disabled
                      id="responseMessage"
                      name="responseMessage"
                      dataAction="consulta_semaforo_step:input:responseMessage"
                      dataLabel="resposta da mensagem"
                      value={
                        semaphoreEntityItem.errorOnExecution
                          ? "-"
                          : semaphoreEntityItem.responseMessage
                      }
                    />
                  </OField>
                </OCol>
              </ORow>
              <ORow>
                <OCol xs={4} md={4} xl={4}>
                  <OField
                    htmlFor="errorOnExecution"
                    label="Houve erro na execução"
                  >
                    <OSwitch
                      disabled
                      id="errorOnExecution"
                      value={semaphoreEntityItem.errorOnExecution}
                    />
                  </OField>
                </OCol>
                <OCol xs={8} md={8} xl={8}>
                  <OField
                    htmlFor="executionOnErrorMessage"
                    label="Mensagem de erro de execução"
                  >
                    <OInputText
                      disabled
                      id="executionOnErrorMessage"
                      name="executionOnErrorMessage"
                      dataAction="consulta_semaforo_step:input:executionOnErrorMessage"
                      dataLabel="resposta da mensagem"
                      value={
                        semaphoreEntityItem.errorOnExecution
                          ? semaphoreEntityItem.executionErrorMessage
                          : "-"
                      }
                    />
                  </OField>
                </OCol>
              </ORow>
              <ORow>
                <OCol xs={6} md={6} xl={6}>
                  <OField
                    htmlFor="semaphoreTemplateIdentifier"
                    label="Template utilizado na validação"
                  >
                    <OInputText
                      disabled
                      id="semaphoreTemplateIdentifier"
                      name="semaphoreTemplateIdentifier"
                      dataAction="consulta_semaforo_step:input:semaphoreTemplateIdentifier"
                      dataLabel="Template utilizado na validação"
                      value={semaphoreEntityItem.semaphoreTemplateIdentifier}
                    />
                  </OField>
                </OCol>
              </ORow>
            </>
          </CardTemplate>
        )}
      </FormTemplate>
    </>
  );
};
