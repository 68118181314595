import * as yup from "yup";
import {
  CreditManagerAddressFormValues,
  creditManagerAddressValidationFormSchema,
} from "../../../../../../components/address/credit-manager-address-form.schemas";

export type WorkflowConsumerUnitFormValues = CreditManagerAddressFormValues & {
  installationNumber: string;
};
export const workflowConsumerUnitValidationFormSchema: yup.ObjectSchema<WorkflowConsumerUnitFormValues> =
  yup
    .object({
      installationNumber: yup
        .string()
        .required("Este campo é obrigatório")
        .test("installationNumber", "Não são permitidos espaços", (value) =>
          !!value ? value.match(/^\S+$/) !== null : true,
        ),
    })
    .concat(creditManagerAddressValidationFormSchema);
