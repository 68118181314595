import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import {
  getDocumentFiles,
  workflowDocumentsGrid,
} from "./workflow-documents.grid";

export const WorkflowDocumentsPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getWorkflowLeadDocuments(id)
          .then(({ data }) => getDocumentFiles(data)),
      ),
    [id],
  );

  const grid = useMemo(() => workflowDocumentsGrid(id), [id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Documentos" />}
      actions={
        <RefreshGridButton
          onClick={() => !dataSource?.isLoading() && dataSource?.reload()}
        />
      }
    >
      <ODataGridGenerator grid={grid} dataSource={dataSource} />
    </GridTemplate>
  );
};
