import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { GetOdataFidcReceivableStateMachineOdataViewResponse } from "services/quickfin/models";

export const fidcReceivablesTableGrid: ODataGridGeneratorConfig<
  GetOdataFidcReceivableStateMachineOdataViewResponse["value"][number]
> = {
  datagrid: {
    noDataText: "Nenhum recebível",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} recebíveis)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "ReceivableId",
      caption: "Receivable id",
    },
    {
      dataField: "TradeId",
      caption: "Trade id",
    },
    {
      dataField: "State",
      caption: "Status",
    },
    {
      dataField: "Sacado",
      caption: "Sacado",
    },
    {
      dataField: "Cedente",
      caption: "Cedente",
    },
    {
      dataField: "ValorVencimento",
      caption: "Valor vencimento",
    },
    {
      dataField: "ValorDesembolso",
      caption: "Valor desembolso",
    },
    {
      dataField: "Rebate",
      caption: "Rebate",
    },
    {
      dataField: "TradeDate",
      caption: "Data do trade",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "DataVencimento",
      caption: "Data de vencimento",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDate",
    },
    {
      dataField: "UpdatedAt",
      caption: "Atualizado em",
      dataType: "datetime",
      format: "shortDate",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
    {
      dataField: "Type",
      caption: "Tipo de produto",
    },
    {
      dataField: "BorderoId",
      caption: "Identificador da importação",
    },
  ],
};
