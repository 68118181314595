import { OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";

export const useFileUpload = (uuid: string, refetch: () => Promise<void>) => {
  const [hasParseError, setHasParseError] = useState(false);

  const form = useForm();

  const { error: readerError, loading: readerLoading, reset } = useReadFile();

  const handleAddFile = useCallback(
    async (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;
      if (file) {
        await service.onboardingRelationships.addFile(file, { UUID: uuid });
        refetch();
      }
    },
    [refetch, uuid],
  );

  return {
    handleAddFile,
    hasParseError: readerError || hasParseError,
    readerLoading,
    reset,
    setHasParseError,
    form,
  };
};
