interface DomainExceptionMap {
  message: string;
}

export const domainExceptionMap: Record<string, DomainExceptionMap> = {
  RfInvalidStatus: {
    message:
      "O CNPJ informado não se encontra em situação cadastral ativa na Receita Federal.",
  },
  CustomerTaxIdIsInvalid: {
    message: "O cliente informado não é uma Pessoa Jurídica.",
  },
  DistributorNotInCooperative: {
    message:
      "Uma das distribuidoras selecionadas não atua em conjunto de nossas cooperativas parceiras.",
  },
  InvoiceAmountIsNotAboveTenThousand: {
    message:
      "A soma dos valores das faturas de energia da(s) unidade(s) consumidora(s) é inferior a R$ 10.000,00.",
  },
  UnitIsNotOnMidOrHighTension: {
    message: "A unidade consumidora não opera em média ou alta tensão.",
  },
};
