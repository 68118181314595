import { OToastManager } from "@maestro/core";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { energiaRoles } from "../../../../../roles/energia.roles";
import { MleByDistributorIdRouteParams } from "../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../utils";
import {
  RecommendedAdequacyOptionsForm,
  recommendedAdequacyOptionsFormDefaultValues,
} from "./recommended-adequacy-options.form";

export const useRecommendedAdequacyOptions = () => {
  const { distributorId } = useParams<MleByDistributorIdRouteParams>();
  if (!distributorId) throw new Error("No distributorId");

  const { hasRole } = useRoles();

  const {
    callService: _getDistributorAdequacyOptions,
    loading: fetchLoading,
    error: fetchError,
    hasError: fetchHasError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDistributorAdequacyOptions);
  const {
    callService: _updateDistributorAdequacyOptions,
    loading: submitLoading,
  } = useServiceCall(service.hubEnergy.updateDistributorAdequacyOptions);

  const form = useForm<RecommendedAdequacyOptionsForm>({
    defaultValues: recommendedAdequacyOptionsFormDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const canUpdate = hasRole(
    energiaRoles.product.updateAdequacyDistributors.role,
  );

  const getRecommendedAdequacyOptions = useCallback(
    async () => _getDistributorAdequacyOptions(distributorId),
    [_getDistributorAdequacyOptions, distributorId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _updateDistributorAdequacyOptions({
          distributorId: Number(distributorId),
          ...values,
        });

        if (success) {
          OToastManager.success(
            "Configuração da distribuidora atualizada com sucesso",
          );
        } else
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Não foi possível atualizar a configuração da distribuidora",
          );
      }),
    [handleSubmit, _updateDistributorAdequacyOptions, distributorId],
  );

  useEffect(() => {
    getRecommendedAdequacyOptions();
  }, [getRecommendedAdequacyOptions]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        adequacyOptionIds: fetchValue.response.map(({ id }) => id),
      });
  }, [reset, fetchValue?.response]);

  return {
    canUpdate,
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getRecommendedAdequacyOptions,
    submit,
    submitLoading,
  };
};
