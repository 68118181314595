import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GetApiAdminBatchConfigurableColumnsResponse } from "services/hubcreditmanager/models/responses/get-api-admin-batch-configurable-columns.response";
import { GetApiAdminBatchFileTemplateColumnsResponse } from "services/hubcreditmanager/models/responses/get-api-admin-batch-file-template-columns.response";
import { TemplateColumn } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { WorkflowProdutoBatchRequestById } from "../../routes/workflow.route-params";

interface BatchFileTemplateColumnsContextProps {
  selectedTemplateColumn?: TemplateColumn;
  setSelectedTemplateColumn: React.Dispatch<
    React.SetStateAction<TemplateColumn | undefined>
  >;
  batchTemplateColumns: GetApiAdminBatchFileTemplateColumnsResponse | undefined;
  batchConfigurableColumns:
    | GetApiAdminBatchConfigurableColumnsResponse
    | undefined;
  id: string;
}

export const BatchFileTemplateColumnsContext = createContext(
  {} as BatchFileTemplateColumnsContextProps,
);

interface BatchFileTemplateColumnsProviderProps {
  children: ReactNode;
}

export const BatchFileTemplateColumnsProvider = ({
  children,
}: BatchFileTemplateColumnsProviderProps) => {
  const [selectedTemplateColumn, setSelectedTemplateColumn] =
    useState<TemplateColumn>();
  const { id } = useParams<WorkflowProdutoBatchRequestById>();
  if (!id) throw new Error("No id");

  const batchFileTemplateColumns = useServiceCall(
    service.hubCreditManager.getBatchFileTemplateColumns,
  );

  const configurableColumns = useServiceCall(
    service.hubCreditManager.getBatchConfigurableColumns,
  );

  const {
    hasError,
    callService: getBatchFileTemplateColumns,
    value: batchTemplateColumns,
  } = batchFileTemplateColumns;

  useEffect(() => {
    if (selectedTemplateColumn)
      setSelectedTemplateColumn(
        batchFileTemplateColumns.value?.find(
          (s) => s.id === selectedTemplateColumn.id,
        ),
      );
  }, [batchFileTemplateColumns.value, selectedTemplateColumn]);

  const {
    value: batchConfigurableColumns,
    callService: getBatchConfigurableColumns,
  } = configurableColumns;

  useEffect(() => {
    hasError &&
      OToastManager.danger("Não foi possível carregar as colunas do Template.");
  }, [hasError]);

  useEffect(() => {
    getBatchFileTemplateColumns(id);
    getBatchConfigurableColumns();
  }, [getBatchFileTemplateColumns, getBatchConfigurableColumns, id]);

  const value = useMemo(
    () => ({
      id,
      selectedTemplateColumn,
      setSelectedTemplateColumn,
      batchTemplateColumns,
      batchConfigurableColumns,
      hasError,
    }),
    [
      batchTemplateColumns,
      batchConfigurableColumns,
      hasError,
      id,
      selectedTemplateColumn,
    ],
  );

  return (
    <BatchFileTemplateColumnsContext.Provider value={value}>
      {children}
    </BatchFileTemplateColumnsContext.Provider>
  );
};

export const useBatchFileTemplateColumns = () =>
  useContext(BatchFileTemplateColumnsContext);
