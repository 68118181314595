import styled from "styled-components";

export const StyledSelect = styled.select`
  border-color: transparent transparent #ccc transparent;
  height: 36px;
  width: 100%;

  &:focus {
    border-color: transparent transparent var(--theme-focus) transparent;
  }
`;
