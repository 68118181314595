import { OModalBody, OTypography } from "@maestro/react";
import { useState } from "react";

import { ExportProps } from "./export-modal.types";
import { ExportOptions } from "./_compose/export-options.component";
import { PeriodOptions } from "./_compose/period-options.component";
import { OrdenationOptions } from "./_compose/ordenation-options.component";
import { StatementTypeOptions } from "./_compose/statement-type-options.component";
import { BalanceYieldOptions } from "./_compose/balance-yield-options.component";

export const ExportModalBody = ({ balanceYieldId }: ExportProps) => {
  const [periodFilter, setPeriodFilter] = useState<string>("");

  return (
    <OModalBody className="d-flex flex-column justify-content-around">
      <ExportOptions />
      <PeriodOptions filter={periodFilter} setFilter={setPeriodFilter} />
      <OrdenationOptions />
      <OTypography className="mt-2 mb-2">
        Deseja incluir informações do Remunera+ nesse extrato?
      </OTypography>
      <OTypography size="sm" className="mt-2 mb-2" weight="lighter">
        Selecione as informações que desejar incluir no extrato
      </OTypography>
      <StatementTypeOptions />
      {periodFilter === "monthly" && balanceYieldId && <BalanceYieldOptions />}
    </OModalBody>
  );
};
