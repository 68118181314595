import {
  modalManager, OLoader, OModal,
  OModalBody,
  OModalHeader
} from "@maestro/react";
import { useEffect, useState } from "react";
import { InfoGrid } from "../../cards.styles";
import { ItemInfo } from "../../invoices/item-info/item-info.component";
import { RefusalTransactionInfoData } from "./constants";
import { RefusalTransacionModalInfo } from "./refusal-transaction-modal.styles";
import { IRefusalTransactionModalProps } from "./refusal-transaction-modal.types";

export const RefusalTransactionModal = ({
  info,
}: IRefusalTransactionModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => modalManager.show("refusal-transaction-modal-id"), 3000);
  }, [info]);

  useEffect(() => {
    const openCleanUp = modalManager.on(
      "refusal-transaction-modal-id",
      "modalOpen",
      () => {
        setIsLoading(false);
      },
    );
    return () => {
      openCleanUp();
    };
  });

  return (
    <>
      {isLoading && <OLoader absolute backdrop />}
      <OModal position="center" id="refusal-transaction-modal-id">
        <OModalHeader closeButton>Transação negada</OModalHeader>
        <OModalBody>
          <RefusalTransacionModalInfo>
            <InfoGrid columns={5} columnsWidth={150} gridGap={10}>
              {RefusalTransactionInfoData.map((infoData) => (
                <ItemInfo
                  key={infoData.key}
                  label={infoData.label}
                  value={info[infoData.key as keyof typeof info]}
                />
              ))}
            </InfoGrid>
            <ItemInfo label={"Descritivo"} value={info.description} />
            {info.refusalReason && (
              <ItemInfo label={"Motivo"} value={info?.refusalReason} />
            )}
            {info.attendantRefusalReason && (
              <ItemInfo
                label={"Instruções"}
                value={info.attendantRefusalReason}
              />
            )}
          </RefusalTransacionModalInfo>
        </OModalBody>
      </OModal>
    </>
  );
};
