import { OToastManager } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

interface DeleteLeadButtonProps {
  workflowId: number;
}

export const DeleteLeadButton = ({ workflowId }: DeleteLeadButtonProps) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const deleteLead = useCallback(async () => {
    try {
      setLoading(true);

      await service.hubCreditManager.deleteWorkflowLead({
        ids: [workflowId],
      });

      navigate(corporateRouter.routes.workflow.product.leads.path);

      OToastManager.success("Lead deletado com sucesso.");
    } catch {
      OToastManager.danger("Não foi possível deletar lead.");
    } finally {
      setLoading(false);
    }
  }, [navigate, workflowId]);

  return (
    <ButtonWithConfirmation
      type="danger"
      outline
      confirmationModal={{
        title: "Tem certeza que deseja deletar esse lead?",
        loading,
      }}
      onConfirm={deleteLead}
    >
      Deletar
    </ButtonWithConfirmation>
  );
};
