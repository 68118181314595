import { ODataGridGeneratorConfig } from "components/data-grid";
import { PreticketDocument } from "services/hubfx/models/responses";
import { ExchangePreTicketDocumentDetails } from "./pre-ticket-document-details.component";

export const exchangePreTicketDocumentsGrid: ODataGridGeneratorConfig<PreticketDocument> =
  {
    datagrid: {
      noDataText: "Nenhum documento",
    },
    columns: [
      {
        dataField: "typeName",
        caption: "Tipo de documento",
        cellRender: ({ data }) => data.typeDescription || data.typeName,
      },
    ],
    masterDetail: {
      enabled: true,
      component: ({ data }) => (
        <ExchangePreTicketDocumentDetails preTicketDocument={data.data} />
      ),
    },
  };
