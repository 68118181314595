import { OIcon, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { ImportedBatchPayer } from "./batch-import.type";

export const batchImportPayersGrid: ODataGridGeneratorConfig<ImportedBatchPayer> =
  {
    datagrid: {
      noDataText: "Nenhum lote entubado",
      pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [10, 20, 30],
      },
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "failure",
        caption: "Error",
        cellRender: ({ data }) =>
          data.failure ? (
            <div>
              {data.failure.map((fail) => (
                <OTypography key={fail} type="dark">
                  {fail}
                </OTypography>
              ))}
            </div>
          ) : (
            <OIcon category="orq" icon="orq-check" size="xl" />
          ),
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "identification",
        caption: "CNPJ/CPF",
        cellRender: ({ data }) => masks.cpfCnpj(data.identification),
      },
      {
        dataField: "emailAddress",
        caption: "E-mail",
      },
      {
        dataField: "phoneNumber",
        caption: "Telefone",
      },
      {
        caption: "País",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].countryCode : "",
      },
      {
        caption: "Estado",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].stateCode : "",
      },
      {
        caption: "Cidade",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].cityName : "",
      },
      {
        caption: "Endereço",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].street : "",
      },
      {
        caption: "Número",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].number : "",
      },
      {
        caption: "Complemento",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].complement : "",
      },
      {
        caption: "CEP",
        cellRender: ({ data }) =>
          data.addresses ? data.addresses[0].zipCode : "",
      },
      {
        dataField: "gender",
        caption: "Sexo",
      },
      {
        dataField: "birthDate",
        caption: "Data de nascimento",
      },
    ],
  };
