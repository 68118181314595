import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { adminContingencyACCGenericFieldsGrid } from "./admin-contingency-acc-generic-fields.grid";

export const AdminContingencyACCGenericFieldsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Campos Genéricos" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.acc.product.contingency.natures.genericFields
              .create.path
          }
          role={
            roles.acc.product.contingencyNaturesGenericFieldsCreatePage.role
          }
        >
          Adicionar campo genérico
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={adminContingencyACCGenericFieldsGrid}
      />
    </GridTemplate>
  );
};
