import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";

interface ApproveStepButtonProps {
  diligenceStepId: number;
  reload: () => void;
}

export const ApproveStepButton = ({
  diligenceStepId,
  reload,
}: ApproveStepButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.approveDiligenceStep,
  );

  const approveDiligenceStep = useCallback(async () => {
    const { success, error } = await callService(diligenceStepId);
    if (!success)
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Não foi possível aprovar o step",
      );
    else {
      OToastManager.success("Step aprovado com sucesso");
      reload();
    }
  }, [callService, diligenceStepId, reload]);

  return (
    <LoadingButton loading={loading} onClick={approveDiligenceStep}>
      Aprovar
    </LoadingButton>
  );
};
