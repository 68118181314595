import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { billingRouteParams } from "./billing.route-params";
import { BillingCustomers, RateCustomization } from "../pages/produto";
import { billingRoles } from "../roles/billing.roles";

export const billingRoutes = {
  product: {
    name: "",
    path: "/billing/produto",
    element: <RedirectWithRoles />,
    children: {
      customers: {
        name: "Clientes",
        path: `/billing/produto/clientes`,
        role: billingRoles.product.customersPage.role,
        element: <BillingCustomers />,
        children: {
          rateCustomization: {
            name: "Configuração de tarifa",
            path: `/billing/produto/clientes/:${billingRouteParams.taxId}/configuracao-tarifa`,
            role: billingRoles.product.customersRateCustomizationPage.role,
            element: <RateCustomization />,
          },
        },
      },
    },
  },
} as const satisfies Routes;
