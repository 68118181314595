import { OConfirmationModal } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildCompanyUsersGrid } from "./company-users.grid";
import { useCompanyUsers } from "./company-users.hook";
import {
  invalidateUserModalId,
  sendWelcomeModalId,
} from "./company-users.types";

export const CompanyUsersPage = () => {
  const {
    customerBasicData,
    dataSource,
    sendWelcomeEmail,
    invalidateUser,
    salesforceCompanyUserRef,
    sendWelcomeLoading,
    invalidateUserLoading,
  } = useCompanyUsers();

  const gridRef = useGridRef();

  const gridDefinition = useMemo(
    () => buildCompanyUsersGrid(salesforceCompanyUserRef),
    [salesforceCompanyUserRef],
  );

  return (
    <>
      <GridTemplate
        pageTitle={
          <PageTitle
            key={customerBasicData?.officialName}
            title={`Usuários da empresa ${
              customerBasicData
                ? `${customerBasicData.officialName} - ${masks.cpfCnpj(
                    customerBasicData.identification,
                  )}`
                : ""
            }`}
          />
        }
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={gridDefinition}
          dataSource={dataSource}
        />
      </GridTemplate>
      <OConfirmationModal
        modalId={sendWelcomeModalId}
        title="Confirma o envio de e-mail de boas vindas?"
        confirmLabel="Sim"
        closeLabel="Não"
        divider={false}
        size="xs"
        loading={sendWelcomeLoading}
        onConfirm={sendWelcomeEmail}
      />
      <OConfirmationModal
        modalId={invalidateUserModalId}
        title="Confirma o bloqueio de todos os dispositivos confiáveis do usuário?"
        confirmLabel="Sim"
        closeLabel="Não"
        divider={false}
        size="xs"
        loading={invalidateUserLoading}
        onConfirm={invalidateUser}
      />
    </>
  );
};
