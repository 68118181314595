import { OUploadCustomEvent } from "@maestro/core";
import { ORFieldUploadInput } from "@maestro/react";
import { useCallback } from "react";
import { service } from "services";
import { UploadFieldContainer } from "./upload-field.style";

interface DocumentUuid {
  uuid: string;
  refetch: () => void;
  canEdit: boolean;
}

export const FileUpload = ({ uuid, refetch, canEdit }: DocumentUuid) => {
  const handleAddFile = useCallback(
    async (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;
      if (file) {
        await service.onboardingRelationships.addFile(file, { UUID: uuid });
        refetch();
      }
    },
    [refetch, uuid],
  );

  return (
    <UploadFieldContainer>
      <ORFieldUploadInput
        id="documentFiles"
        name="documentFiles"
        inputLabel="Clique ou arraste o arquivo aqui"
        accept="application/pdf,image/jpg,image/jpeg,image/png"
        size="md"
        multiple={false}
        handleAddFile={handleAddFile}
        value={[]}
        disabled={!canEdit}
      />
    </UploadFieldContainer>
  );
};
