import { OLoader, OOption, OSelect } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { format } from "utils/date";
import { EmployeeOnboardingFields } from "./lotes.details";
import { massifyListGrid } from "./lotes.grid";
import { useMassifyList } from "./lotes.hook";

export const MassifyPage = () => {
  const {
    dataSource,
    massifyList,
    massifyFilters,
    selectedMassify,
    setSelectedMassify,
  } = useMassifyList();

  const gridRef = useGridRef();

  return (
    <>
      <PageTitle title="Onboarding Funcionários" />
      {massifyFilters ? (
        <DetailsCard fields={EmployeeOnboardingFields(massifyFilters)} />
      ) : (
        <OLoader />
      )}
      <div className="my-4 w-25">
        <OSelect
          value={selectedMassify}
          dataAction="onboarding_funcionarios_fopa:select:selecionar_lote"
          dataLabel="selecionar_lote"
          readonly={!massifyList}
          placeholder="Escolha a planilha para acompanhar o cadastro em cada arquivo"
          onInput={(e: any) => setSelectedMassify(e.target.value)}
        >
          {massifyList &&
            massifyList.map((massify) => (
              <OOption key={massify.id} value={massify.id}>
                {`Lote-${massify.id} - ${format.date(massify.dateCreate)}`}
              </OOption>
            ))}
        </OSelect>
      </div>
      <GridTemplate gridRef={gridRef} showRefreshButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={massifyListGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </>
  );
};
