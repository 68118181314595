import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { gridStorage } from "utils/storage";
import { energyGuarantorStatusMap } from "../../../utils";
import { MappedStatusBadge } from "../../mapped-status-badge";
import {
  Guarantor,
  maritalStatusTypeMap,
  weddingRegimeTypeMap,
} from "./energy-trade-guarantors.utils";

/** @deprecated */
export const energyTradeGuarantorsGrid = (allowUpdating = false) =>
  ({
    datagrid: {
      noDataText: "Nenhum avalista",
      editing: {
        allowUpdating,
      },
      stateStoring: gridStorage("energyTradeGuarantorsGrid"),
      onEditorPreparing: (e) => {
        e.editorOptions = {
          ...e.editorOptions,
          dropDownOptions: {
            ...e.editorOptions?.dropDownOptions,
            minWidth: 400,
          },
          itemTemplate: (itemData, _, itemElement) => {
            (itemElement as HTMLElement).setAttribute("title", itemData.label);
            return itemData.label;
          },
        };
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        allowEditing: false,
        dataField: "entity.name",
        caption: "Nome",
      },
      {
        allowEditing: false,
        dataField: "entity.taxId",
        caption: "CPF",
        customizeText: ({ value }) => masks.cpf(value),
      },
      {
        allowEditing: false,
        dataField: "status",
        caption: "Status",
        alignment: "center",
        cellRender: ({ data }) => (
          <MappedStatusBadge
            map={energyGuarantorStatusMap}
            status={data.status}
          />
        ),
      },
      {
        dataField: "maritalStatusType",
        caption: "Estado civil",
        lookup: {
          dataSource: Object.entries(maritalStatusTypeMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "weddingRegimeType",
        caption: "Regime de comunhão de bens",
        lookup: {
          dataSource: Object.entries(weddingRegimeTypeMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
          allowClearing: true,
        },
      },
      {
        allowEditing: false,
        dataField: "errorMessages",
        caption: "Erros",
        calculateCellValue: ({ errorMessages }) => errorMessages?.join(" "),
        cellRender: ({ data: { errorMessages } }) =>
          errorMessages?.map((error) => <p key={error}>{error}</p>),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Guarantor>);
