import { IconButton } from "components/icon-button";
import { useXMLDownloadButton } from "./xml-download-button.hook";

interface XMLDownloadButtonProps {
  s3Key: string;
}

export const XMLDownloadButton = ({ s3Key }: XMLDownloadButtonProps) => {
  const { download, loading } = useXMLDownloadButton(s3Key);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-download" }}
      dataAction="agendas:botao:download"
      dataLabel="download"
      type="dark"
      outline
      onClick={download}
      loading={loading}
    >
      Baixar arquivo
    </IconButton>
  );
};
