import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  OModal,
  OModalHeader,
  OTypography,
  OModalBody,
  OModalFooter,
  OButton,
  ORow,
  OCol,
  ORFieldInput,
  modalManager,
  OToastManager,
} from "@maestro/react";
import { IntegrationModalName } from "../enums/integrationModalName";
import { IntegrationContext } from "../context/integration.context";
import { inputValidate } from "../validator/input-validator.utils";

export const AddAccountIntegrationModal = () => {
  const { integration, setIntegration } = useContext(IntegrationContext);
  const form = useForm();

  const validateForm = (accountNumber: string, branch: string) => {
    const validations = [
      {
        value: accountNumber,
        message: "número da conta",
      },
      { value: branch, message: "número da agência" },
    ];

    return inputValidate(validations);
  };

  const handleSubmit = () => {
    const formValues = form.getValues();
    const { account, branch } = formValues;
    if (!account || !branch) {
      OToastManager.danger({
        title: "Dados inválidos",
        message: "Preencha os campos de agência e conta",
      });
      return;
    }
    const accountNumber = account.toString().slice(0, -1);
    const accountDigit =
      accountNumber !== "" ? account.toString().slice(-1) : "";

    const isvalid = validateForm(accountNumber, branch);

    if (!isvalid) return false;

    const integrationAccount = {
      accountNumber,
      accountDigit,
      branch,
    };

    integration.accounts.push(integrationAccount);
    setIntegration({ ...integration, accounts: integration.accounts });
    form.setValue("account", "");
    form.setValue("branch", "");

    return true;
  };

  return (
    <OModal id={IntegrationModalName.ADD_ACCOUNT_INTEGRATION} position="center">
      <OModalHeader closeButton divider>
        <OTypography tag="h2" size="lg">
          Adicionar conta
        </OTypography>
        <OTypography tag="h3" size="md">
          Adicione a conta que será utilizada para a criação da integração
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <form>
            <ORow class="mb-3">
              <OCol>
                <ORFieldInput
                  id="branch"
                  label="Agência"
                  name="branch"
                  placeholder="99"
                  tag="number"
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  id="account"
                  label="Conta com DV"
                  name="account"
                  placeholder="9999999-9"
                  tag="number"
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <ORow className="w-100" justify="end" align="center">
          <OButton
            class="mr-3 w-auto"
            onClick={() => {
              handleSubmit();
            }}
          >
            Salvar e adicionar nova conta
          </OButton>
          <OButton
            class="mr-3 w-auto"
            onClick={() => {
              const registerWithSuccess = handleSubmit();
              if (registerWithSuccess) {
                modalManager.hide(IntegrationModalName.ADD_ACCOUNT_INTEGRATION);
              }
            }}
          >
            Salvar e sair
          </OButton>
          <OButton
            class="w-auto"
            outline
            type="default"
            onClick={() => {
              modalManager.hide(IntegrationModalName.ADD_ACCOUNT_INTEGRATION);
            }}
          >
            Fechar
          </OButton>
        </ORow>
      </OModalFooter>
    </OModal>
  );
};
