import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMarketplaceIdField } from "./marketplace-id-field.hook";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const MarketplaceIdField = () => {
  const { marketplaces, getMarketplaces, loading, hasFetchError } =
    useMarketplaceIdField();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:marketplaces"
        dataLabel="marketplaces"
        id="marketplaceId"
        name="marketplaceId"
        label={EstrategiaFormLabels.marketplaceId}
        description="Portal de negociação das notas"
        placeholder="Quickfin"
        disabled={hasFetchError || loading}
        key={marketplaces?.length}
      >
        {marketplaces?.map((marketplace) => (
          <OOption key={marketplace.id} value={marketplace?.id ?? -1}>
            {marketplace.subgroup
              ? `${marketplace.name} - ${marketplace.subgroup}`
              : marketplace.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="marketplaces:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getMarketplaces()}
        />
      )}
    </OCol>
  );
};
