import {
  OCol,
  OField,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
  OSwitch,
} from "@maestro/react";
import { paymentTypeMap, rebateTypeMap } from "../../../fees-sacado.utils";

interface FeesPaymentConfigProps {
  profitShare: boolean;
  setProfitShare: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeesPaymentConfigFields = ({
  profitShare,
  setProfitShare,
}: FeesPaymentConfigProps) => {
  return (
    <ORow>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_taxa_rebate:select:tipo_rebate"
          dataLabel="tipo_rebate"
          id="rebateType"
          name="rebateType"
          label="Tipo de rebate"
          placeholder="Selecione"
        >
          {Object.entries(rebateTypeMap).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          tag="percentage"
          dataAction="formulario_taxa_rebate:input:taxa"
          dataLabel="taxa"
          id="tax"
          name="tax"
          label="Taxa"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_taxa_rebate:select:tipo_pagamento"
          dataLabel="tipo_pagamento"
          id="paymentType"
          name="paymentType"
          label="Tipo do pagamento"
          placeholder="Selecione"
        >
          {Object.entries(paymentTypeMap).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          tag="text"
          dataAction="formulario_taxa_rebate:inout:dia_pagamento"
          dataLabel="dia_pagamento"
          id="paymentDay"
          name="paymentDay"
          label="Dia do pagamento"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDate
          allowClear
          size="md"
          id="effectiveDate"
          name="effectiveDate"
          label="Início da vigência"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDate
          allowClear
          size="md"
          id="endEffectiveDate"
          name="endEffectiveDate"
          label="Fim da vigência"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <OField htmlFor="profitShare" label="Participação dos lucros">
          <OSwitch
            id="profitShare"
            size="sm"
            value={profitShare}
            onInput={() => setProfitShare((oldValue) => !oldValue)}
          />
        </OField>
      </OCol>
    </ORow>
  );
};
