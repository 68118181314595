import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useState } from "react";
import { service } from "services";
import { GetPayrollListParams, GetPayrollListResponse } from "services/fopa";
import { GridTemplate } from "templates/grid-template";
import { paymentsGrid } from "./payments.grid";
import { PaymentFilterModal } from "./_compose";
import { PaymentsFilterFields } from "./_compose/payment-filter-modal/payment-filter-modal.types";
import { usePaymentsFilter } from "./_compose/payment-filter-modal/payment-filter.hooks";

const dataSourceGenerator = (params: GetPayrollListParams) =>
  dataSourceCustomStoreGenerator<
    GetPayrollListResponse["dashboardList"][number]
  >((loadOptions) =>
    service.fopa
      .getPayrollList({
        ...params,
        pageNumber: (loadOptions?.skip || 0) / (loadOptions?.take || 1) + 1,
      })
      .then(({ data }) => {
        return {
          data: data.dashboardList,
          totalCount: data.pageInfos?.totalCount,
        };
      })
      .catch(() => {
        const errorMessage = "Erro ao buscar os pagamentos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

const defaultPageSize = 10;

export const PaymentsPage = () => {
  const {
    customer: { identification },
  } = useCustomer();
  const filterModal = usePaymentsFilter();

  const gridRef = useGridRef();

  const [dataSource, setDataSource] = useState(
    dataSourceGenerator({
      cnpjEmpresa: identification,
      pageNumber: 1,
      pageSize: defaultPageSize,
      ...filterModal.filters,
    }),
  );

  const handleFilter = useCallback(
    (newFilters: PaymentsFilterFields) => {
      setDataSource(
        dataSourceGenerator({
          cnpjEmpresa: identification,
          pageNumber: 1,
          pageSize: defaultPageSize,
          ...newFilters,
          status: newFilters?.status
            ? JSON.parse(newFilters.status)
            : undefined,
        }),
      );
    },
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pagamentos" />}
      actions={
        <PaymentFilterModal
          filters={filterModal.filters}
          onFilter={handleFilter}
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={paymentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
