/* eslint-disable no-param-reassign */
import type { LoadOptions } from "devextreme/data";
import {
  Wallet,
  WalletProvisionRequestStatus,
  WalletType,
} from "./wallets.types";

/** @deprecated */
type FilterItem = string | Array<FilterItem>;

/** @deprecated */
const walletProvisionRequestStatusList: WalletProvisionRequestStatus[] = [
  "CREATED",
  "APPROVED",
  "REJECTED",
  "EXPIRED",
];

/** @deprecated */
const walletProvisionRequestTypeList: WalletType[] = [
  "SAMSUNG",
  "APPLE",
  "GOOGLE",
];

/** @deprecated */
interface ParsedFilter {
  status?: string;
  type?: string;
  cardOwnerDocument?: string;
}

/** @deprecated */
const parseDXFilter = (filter: FilterItem, parsed: ParsedFilter = {}) => {
  if (typeof filter === "string" || !filter) return parsed;

  if (filter[0] === "!") {
    const { status: invertedStatus } = parseDXFilter(filter[1]);
    parsed.status = walletProvisionRequestStatusList
      .filter(
        (walletRequestStatus) => !invertedStatus?.match(walletRequestStatus),
      )
      .join(",");
    return parsed;
  }

  if (filter[0] === "!") {
    const { type: invertedType } = parseDXFilter(filter[1]);
    parsed.type = walletProvisionRequestTypeList
      .filter((walletType) => !invertedType?.match(walletType))
      .join(",");
    return parsed;
  }

  if (filter[0] === "status") {
    parsed.status = [parsed.status, filter[1] as string]
      .filter(Boolean)
      .join(",");
    return parsed;
  }

  if (filter[0] === "type") {
    parsed.type = [parsed.type, filter[1] as string].filter(Boolean).join(",");
    return parsed;
  }

  if (filter[0] === "card.owner.document") {
    parsed.cardOwnerDocument = (filter[2] as string)
      .trim()
      .replace(/[^0-9]/g, "");
    return parsed;
  }

  filter.forEach((f) => {
    parsed = parseDXFilter(f, parsed);
  });

  return parsed;
};

/** @deprecated */
export const walletProvisionRequestStatusFilters = [
  { text: "Disponível", value: ["status", "CREATED"] },
  { text: "Aprovado", value: ["status", "APPROVED"] },
  { text: "Rejeitado", value: ["status", "REJECTED"] },
  { text: "Expirado", value: ["status", "EXPIRED"] },
];

/** @deprecated */
export const walletTypeFilters = [
  { text: "Samsung Wallet", value: ["type", "SAMSUNG"] },
  { text: "Apple Wallet", value: ["type", "APPLE"] },
  { text: "Google Wallet", value: ["type", "GOOGLE"] },
];

/** @deprecated */
export const remapFilter = (loadOptions: LoadOptions<Wallet>) => {
  const itemsPerPage = loadOptions.take || 10;
  const page = (loadOptions.skip || 0) / (loadOptions.take || 10);

  const parsedFilter = Object.fromEntries(
    Object.entries(parseDXFilter(loadOptions.filter)).filter(
      ([_key, value]) => !!value,
    ),
  );

  const filter = {
    page,
    itemsPerPage,
    ...parsedFilter,
  };

  return filter;
};
