import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
} from "components/data-grid";
import { useParams } from "react-router-dom";
import { WorkflowLeadCreditLine } from "services/hubcreditmanager/models/responses";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { creditLineAnalysisDetailsGrid } from "./workflow-credit-lines-analysis-details.grid";
import { creditLineFinancedAssetsGrid } from "./workflow-credit-lines-financed-assets.grid";

type WorkflowCreditLineMasterDetailProps =
  MasterDetailComponentProps<WorkflowLeadCreditLine>;

export const WorkflowCreditLineMasterDetail = ({
  data: { data },
}: WorkflowCreditLineMasterDetailProps) => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardHeader>
          <OTypography weight="bold">Detalhes da análise</OTypography>
        </OCardHeader>
        <OCardBody>
          <ODataGridGenerator
            grid={creditLineAnalysisDetailsGrid}
            dataSource={data.workflowLeadAnalysisDetails.sort(
              (a, b) => a.order - b.order,
            )}
          />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardHeader>
          <OTypography weight="bold">Ativos financiados</OTypography>
        </OCardHeader>
        <OCardBody>
          <ODataGridGenerator
            grid={creditLineFinancedAssetsGrid}
            dataSource={data.financedAssets}
          />
        </OCardBody>
      </OCard>
    </div>
  );
};
