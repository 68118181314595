import { Routes } from "routes/routes.types";
import {
  RenegociacoesConditions,
  RenegociacoesCustomer,
  RenegociacoesHistory,
  RenegociacoesHistoryDetails,
  ResendBoletoSuccess,
} from "../pages";
import { renegociacoesRoles } from "../roles/renegociacoes.roles";

export const renegociacoesRoutes = {
  customer: {
    name: "Renegociação/Quitação de contratos",
    path: "/renegociacoes/cliente",
    element: <RenegociacoesCustomer />,
    role: renegociacoesRoles.customer.tab.role,
    isFullScreen: true,
    children: {
      monitor: {
        name: "Renegociação/Quitação de contratos",
        path: "/renegociacoes/cliente/monitor",
        children: {
          conditions: {
            name: "Condições",
            path: "/renegociacoes/cliente/monitor/condicoes",
            element: <RenegociacoesConditions />,
            role: renegociacoesRoles.customer.monitorConditionsPage.role,
          },
          history: {
            name: "Histórico",
            path: "/renegociacoes/cliente/monitor/historico",
            element: <RenegociacoesHistory />,
            role: renegociacoesRoles.customer.monitorHistoryPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: "/renegociacoes/cliente/monitor/historico/detalhes",
                element: <RenegociacoesHistoryDetails />,
                role: renegociacoesRoles.customer.monitorHistoryDetailsPage
                  .role,
                children: {
                  success: {
                    name: "Sucesso",
                    path: "/renegociacoes/cliente/monitor/historico/detalhes/sucesso",
                    element: <ResendBoletoSuccess />,
                    role: renegociacoesRoles.customer
                      .monitorHistoryDetailsSuccessPage.role,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
