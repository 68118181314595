import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataFidcBorderosOdataResponse } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { fidcReceivablesTableGrid } from "./fidc-borderos-trable.grid";

dayjs.extend(utc);

const dataSource = dataSourceODataStoreGenerator<
  GetOdataFidcBorderosOdataResponse["value"][number]
>(endpoints.quickfin.odata.fidcBorderosOData);

export const FidcBorderosTable = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lotes FIDC importados" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={fidcReceivablesTableGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
