import { client } from "../client";
import {
  GetBusinessagreementSearchParams,
  PostBusinessagreementBody,
  PutBusinessagreementByIdBody,
} from "./models/requests";
import {
  GetBusinessagreementSearchResponse,
  PostBusinessagreementResponse,
  PutBusinessagreementByIdResponse,
} from "./models/responses";
import { turtlefeeEndpoints } from "./turtlefee.endpoints";

export const turtlefeeService = {
  createFee(body: PostBusinessagreementBody) {
    return client.post<PostBusinessagreementResponse>(
      turtlefeeEndpoints.businessagreement.endpoint,
      body,
    );
  },

  getFees(params: GetBusinessagreementSearchParams) {
    return client.get<GetBusinessagreementSearchResponse>(
      turtlefeeEndpoints.businessagreement.search,
      {
        params,
      },
    );
  },

  updateFee(id: string | number, body: PutBusinessagreementByIdBody) {
    return client.put<PutBusinessagreementByIdResponse>(
      turtlefeeEndpoints.businessagreement.byId(id),
      body,
    );
  },
};
