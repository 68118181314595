import { client } from "../../client";
import { onboardingEndpoints } from "../onboarding.endpoints";
import { GetOdataOnboardingDetailsRequest } from "./models/requests";
import { GetOdataOnboardingDetailsResponse } from "./models/responses";

export const hubOnboardingODataService = {
  getOnboardingDetailsOdata(params?: GetOdataOnboardingDetailsRequest) {
    return client.get<GetOdataOnboardingDetailsResponse>(
      onboardingEndpoints.odata.onboarding.details,

      { params },
    );
  },
};
