import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetOdataAgendamentoViewItem } from "services/quickfin/models";
import { DownloadButton } from "./_compose";

export const grid: ODataGridGeneratorConfig<GetOdataAgendamentoViewItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      caption: "Agendamento Id",
      dataField: "Id",
      allowEditing: false,
    },
    {
      caption: "Receivable Id",
      dataField: "ReceivableId",
      allowEditing: false,
    },
    {
      caption: "Status",
      dataField: "Status",
      lookup: {
        dataSource: [
          "Criado",
          "Solicitado",
          "Confirmado",
          "Cancelado",
          "Concluído",
          "CancelamentoSolicitado",
        ],
      },
    },
    {
      caption: "Criado em",
      dataField: "CreatedAt",
      dataType: "date",
      format: "shortDateShortTime",
      allowEditing: false,
    },
    {
      caption: "Atualizado em",
      dataField: "UpdatedAt",
      dataType: "date",
      format: "shortDateShortTime",
      sortOrder: "desc",
      allowEditing: false,
    },
    {
      caption: "Erros",
      dataField: "Errors",
      cellRender: ({ data }) =>
        data.Errors.map((val) => <p key={val}>{val}</p>),
      allowEditing: false,
    },
    {
      caption: "Comprovante",
      cellRender: ({ data }) => {
        if (data.Status === "Concluido") return <DownloadButton id={data.Id} />;
      },
      allowEditing: false,
    },
  ],
};
