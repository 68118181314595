import { isAxiosError } from "axios";

const comparisonOperations = {
  "===": (value: number, target: number) => value === target,
  "!==": (value: number, target: number) => value !== target,
  ">": (value: number, target: number) => value > target,
  ">=": (value: number, target: number) => value >= target,
  "<": (value: number, target: number) => value < target,
  "<=": (value: number, target: number) => value <= target,
};

export const isErrorStatusCode = (
  error: unknown,
  operation: keyof typeof comparisonOperations,
  target: number,
) =>
  isAxiosError(error) &&
  error.status &&
  comparisonOperations[operation](error.status, target);
