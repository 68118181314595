import { configs } from "./configs";
import { Behaviors } from "./types";

const stripEnvironmentFromUrl = (url: string) =>
  url.replace(/(?:local\.|sandbox\.|dev\.|dev2\.|uat\.|uat2\.|prod\.)/g, "");

export const getBehaviorFromDomain = () => {
  let foundBehavior: Behaviors | undefined;

  Object.entries(configs).forEach(([id, behavior]) => {
    if (
      behavior.domains.some(
        (domain) =>
          stripEnvironmentFromUrl(window.location.hostname) === domain,
      )
    ) {
      foundBehavior = id as Behaviors;
    }
  });

  return foundBehavior;
};
