import { client } from "../client";
import { hubFxAdminCustomerService } from "./customer/hubfx-admin-customer.service";
import { hubfxEndpoints } from "./hubfx.endpoints";
import {
  GetByChannelDashboardBody,
  GetFileContractParams,
  GetFileDownloadParams,
  GetFileReceiptParams,
  PatchAdminBankBody,
  PatchAdminCounterpartyBody,
  PatchAdminIncorrectOrdersIsActiveBody,
  PostAdminBankBody,
  PostAdminCommonBody,
  PostAdminCounterpartyBody,
  PostAdminCounterpartyVerifyForthcomingCancellationBody,
  PostByChannelDashboardFluctuationsBody,
  PostPreticketByChannelGet,
  PutAdminQuoteCancel,
} from "./models/requests";
import { PatchBeneficiaryIsActive } from "./models/requests/patch-beneficiary-is-active.request";
import { PatchByIdAdminOrderBody } from "./models/requests/patch-by-id-admin-orders.request";
import { PatchByIdAdminPreticketBody } from "./models/requests/patch-by-id-admin-pretickets.request";
import { PostAdminCountryBody } from "./models/requests/post-admin-countries.request";
import { PostAdminCurrenciesBody } from "./models/requests/post-admin-currencies.request";
import { PostAdminOrdersBody } from "./models/requests/post-admin-orders.request";
import { PostAdminPreticketBody } from "./models/requests/post-admin-pretickets.request";
import { PostByIdAdminCountryBody } from "./models/requests/post-by-id-admin-countries.request";
import { PostByIdAdminCurrenciesBody } from "./models/requests/post-by-id-admin-currencies.request";
import {
  DashboardByChannelResponses,
  GetAdminCommonResponse,
  GetAdminIncorrectOrdersResponse,
  GetAdminLimitByIdResponse,
  GetAdminOrderByIdResponse,
  GetAdminQuoteByIdResponse,
  GetCommonCountriesResponse,
  PatchAdminBankResponse,
  PatchAdminCounterpartyResponse,
  PatchAdminIncorrectOrdersIsActiveResponse,
  PostAdminBankResponse,
  PostAdminCounterpartyResponse,
  PostAdminCounterpartyVerifyForthcomingCancellationResponse,
  PostByChannelDashboardFluctuationsResponse,
  PostPreticketByChannelGetResponse,
} from "./models/responses";
import { GetAdminCurrenciesResponse } from "./models/responses/get-admin-currencies.response";
import { GetAccOrdersResponse } from "./models/responses/get-admin-orders.response";
import { GetCurrencyResponse } from "./models/responses/get-currencies.response";
import { GetFileReceiptResponse } from "./models/responses/get-file-receipt.response";
import { AdminPayerTypesResponse } from "./models/responses/get-payer-types.response";
import {
  BeneficiaryAnalysisDetails,
  BeneficiaryDetails,
} from "./models/types/beneficiary/beneficiary-details.model";
import { hubFxNatureService } from "./nature/hubfx-nature.service";
import { hubFxODataService } from "./odata/hubfx-odata.service";

export const hubFxService = {
  getCountries() {
    return client.get<GetCommonCountriesResponse>(hubfxEndpoints.countries);
  },
  getPayerTypes() {
    return client.get<AdminPayerTypesResponse>(hubfxEndpoints.payerTypes);
  },
  getCurrencies() {
    return client.get<GetAdminCurrenciesResponse>(
      hubfxEndpoints.admin.currencies,
    );
  },
  postCurrency(body: PostAdminCurrenciesBody) {
    return client.post(hubfxEndpoints.admin.currencies, body);
  },
  postByIdCurrency(body: PostByIdAdminCurrenciesBody) {
    return client.post(hubfxEndpoints.admin.currencies, body);
  },
  patchByIdOrder(body: PatchByIdAdminOrderBody) {
    return client.patch(hubfxEndpoints.admin.orders, body);
  },
  patchByIdPreticket(body: PatchByIdAdminPreticketBody) {
    return client.patch(hubfxEndpoints.admin.pretickets, body);
  },
  patchCounterparty(body: PatchAdminCounterpartyBody) {
    return client.patch<PatchAdminCounterpartyResponse>(
      hubfxEndpoints.admin.counterparty,
      body,
    );
  },
  postPreticket(body: PostAdminPreticketBody) {
    return client.post(hubfxEndpoints.admin.pretickets, body);
  },
  postCountry(body: PostAdminCountryBody) {
    return client.post(hubfxEndpoints.admin.countries, body);
  },
  postOrders(body: PostAdminOrdersBody) {
    return client.post<GetAccOrdersResponse>(
      hubfxEndpoints.admin.orderConciliation,
      body,
    );
  },
  patchCountry(body: PostByIdAdminCountryBody) {
    return client.post(hubfxEndpoints.admin.countries, body);
  },
  odata: hubFxODataService,
  adminCustomer: hubFxAdminCustomerService,
  nature: hubFxNatureService,
  getApprovedCurrencies() {
    return client.get<GetCurrencyResponse>(hubfxEndpoints.currency);
  },
  getQuoteDetails(quoteId: number) {
    return client.get<GetAdminQuoteByIdResponse>(
      hubfxEndpoints.admin.quoteById(quoteId),
    );
  },
  cancelQuote(body: PutAdminQuoteCancel) {
    return client.put(hubfxEndpoints.admin.quote.cancel, body);
  },
  getPreTicketDetails(body: PostPreticketByChannelGet) {
    return client.post<PostPreticketByChannelGetResponse>(
      hubfxEndpoints.preticket.byChannel.get("admin"),
      body,
    );
  },
  getOrderDetails(orderId: string) {
    return client.get<GetAdminOrderByIdResponse>(
      hubfxEndpoints.admin.orderById(orderId),
    );
  },
  getLimitDetails(limitId: string) {
    return client.get<GetAdminLimitByIdResponse>(
      hubfxEndpoints.admin.limitById(limitId),
    );
  },
  getBeneficiaryDetails(beneficiaryId: string) {
    return client.get<BeneficiaryDetails>(
      hubfxEndpoints.admin.beneficiaryById(beneficiaryId),
    );
  },
  getAnalysisDetails(analysisId: string) {
    return client.get<BeneficiaryAnalysisDetails>(
      hubfxEndpoints.admin.analysisById(analysisId),
    );
  },
  patchBeneficiaryIsActive(body: PatchBeneficiaryIsActive) {
    return client.patch<GetAdminLimitByIdResponse>(
      hubfxEndpoints.admin.beneficiaries,
      body,
    );
  },
  getCommonDomain() {
    return client.get<GetAdminCommonResponse>(hubfxEndpoints.admin.common);
  },
  postCommonDomain(body: PostAdminCommonBody) {
    return client.post(hubfxEndpoints.admin.common, body);
  },
  postCounterparty(body: PostAdminCounterpartyBody) {
    return client.post<PostAdminCounterpartyResponse>(
      hubfxEndpoints.admin.counterparty,
      body,
    );
  },
  postVerifyForthcomingCancellation(
    body: PostAdminCounterpartyVerifyForthcomingCancellationBody,
  ) {
    return client.post<PostAdminCounterpartyVerifyForthcomingCancellationResponse>(
      hubfxEndpoints.admin.counterpartyVerifyForthcomingCancellation,
      body,
    );
  },
  getByChannelDashboard(body: GetByChannelDashboardBody) {
    return client.post<DashboardByChannelResponses>(
      hubfxEndpoints.byChannel.dashboard.endpoint("admin"),
      body,
    );
  },
  getByChannelDashboardFluctuations(
    body: PostByChannelDashboardFluctuationsBody,
  ) {
    return client.post<PostByChannelDashboardFluctuationsResponse>(
      hubfxEndpoints.byChannel.dashboard.fluctuations("admin"),
      body,
    );
  },
  postBank(body: PostAdminBankBody) {
    return client.post<PostAdminBankResponse>(hubfxEndpoints.admin.bank, body);
  },
  patchBank(body: PatchAdminBankBody) {
    return client.patch<PatchAdminBankResponse>(
      hubfxEndpoints.admin.bank,
      body,
    );
  },
  downloadReceipt(params: GetFileReceiptParams) {
    return client.get<GetFileReceiptResponse>(hubfxEndpoints.file.receipt, {
      params,
    });
  },
  downloadContract(params: GetFileContractParams) {
    return client.get<string>(hubfxEndpoints.file.contract, {
      params,
    });
  },
  downloadFile(params: GetFileDownloadParams) {
    return client.get<Blob>(hubfxEndpoints.file.download, {
      params,
      responseType: "blob",
    });
  },
  getIncorrectOrders() {
    return client.get<GetAdminIncorrectOrdersResponse>(
      hubfxEndpoints.admin.incorrectOrders.endpoint,
    );
  },
  changeIncorrectOrderIsActive(body: PatchAdminIncorrectOrdersIsActiveBody) {
    return client.patch<PatchAdminIncorrectOrdersIsActiveResponse>(
      hubfxEndpoints.admin.incorrectOrders.setActive,
      body,
    );
  },
};
