import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";

export interface LimitCollateralsFormValues {
  identification: string;
  collateralType: "RECEBIVEIS_CARTOES";
}

export const limitCollateralsFormGrid = (
  onChange: (contractTemplates: LimitCollateralsFormValues[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantias - cartões adicionada",
      dataSource: [],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false,
      },
      onRowInserted: async (e) => {
        e.data.collateralType = "RECEBIVEIS_CARTOES";
        onChange?.(
          removeKeyFromDataSourceItems(e.component.getDataSource().items()),
        );
      },
      onRowUpdated: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowValidating: (e) => {
        if (
          e.component
            .getDataSource()
            .items()
            .some((item) => item.identification === e.newData?.identification)
        ) {
          e.errorText =
            "Não é possível selecionar mais de uma vez uma bandeira";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "identification",
        caption: "Bandeira",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: [
            { value: "VISA", label: "Visa" },
            { value: "MASTERCARD", label: "MasterCard" },
            { value: "AMERICANEXPRESS", label: "AmericanExpress" },
            { value: "ELO", label: "Elo" },
            { value: "HIPERCARD", label: "HiperCard" },
          ],
          valueExpr: "value",
          displayExpr: "label",
        },
      },
      {
        dataField: "collateralType",
        allowEditing: false,
        visible: false,
      },
    ],
  } satisfies ODataGridGeneratorConfig<LimitCollateralsFormValues>);
