import * as yup from "yup";

export const submitCardModalFormValidationSchema = yup.object().shape({
  title: yup.string().trim(),
  description: yup.string().trim().nullable(),
  content: yup.string().required("Este campo é obrigatório"),
});

export const formDefaultValues = {
  title: "",
  description: "",
  content: "",
};
