import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, grid } from "./note-note-legacy.grid";

export const TitleNoteNoteLegacy = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Nota a nota (antigo)"
          description="Visualize todos os títulos recebíveis e suas informações principais"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
