import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { pendencyTypeFormFields } from "./pendency-type-form.form";

export const PendencyTypeFormComponent = () => {
  return (
    <>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...pendencyTypeFormFields.name} />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...pendencyTypeFormFields.label} />
        </OCol>
      </ORow>
      <ORow gap={3}>
        <OCol xs={12} lg={6}>
          <ORFieldInput {...pendencyTypeFormFields.description} />
        </OCol>
      </ORow>
    </>
  );
};
