import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  dataSourceODataStoreGenerator,
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GetApiFranchiseResponseItem } from "services/clerk";
import { FranchiseOdata } from "services/clerk/models/types";
import { endpoints } from "services/endpoints";
import { GridTemplate } from "templates/grid-template";
import { buildCustomerLink } from "utils/build-customer-link";

export const buildDetailsDataSource = (id: number) =>
  dataSourceODataStoreGenerator(endpoints.clerk.franchise.odata, {
    dataSourceOptions: {
      filter: ["franchise_id", "=", id],
    },
    odataStoreOptions: {
      beforeSend(options) {
        // eslint-disable-next-line no-param-reassign
        options.method = "POST";
      },
    },
  });

export const dataSourceFranchise = (id: number) => {
  return dataSourceCustomStoreGenerator<FranchiseOdata>(() =>
    service.clerk
      .getFranchiseClients({ filter: ["franchise_id", "=", id] })
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os clientes";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );
};

export const buildDetailsGrid: ODataGridGeneratorConfig<FranchiseOdata> = {
  datagrid: {
    noDataText: "Nenhum cliente encontrado.",
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Detalhes",
      cellRender: ({ data: { identification } }) => (
        <DetailsLink href={buildCustomerLink(identification)} />
      ),
      allowExporting: false,
    },
    {
      dataField: "identification",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: true,
    },
    {
      dataField: "official_name",
      dataType: "string",
      caption: "Nome",
      allowHeaderFiltering: true,
    },
    {
      dataField: "onboarding_status",
      dataType: "string",
      caption: "Status de cadastro",
      allowHeaderFiltering: true,
    },
  ],
};

type FranchiseMasterDetailProps =
  MasterDetailComponentProps<GetApiFranchiseResponseItem>;

export const FranchiseMasterDetail = ({
  data: {
    data: { id },
  },
}: FranchiseMasterDetailProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(() => dataSourceFranchise(id), [id]);
  return (
    <GridTemplate gridRef={gridRef} showExportButton>
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildDetailsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
