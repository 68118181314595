import { ICard } from "../../cards.types";

export interface IFunctionsProps {
  cardData: ICard;
  selectedCompany: string;
}

export interface ISetupsProps {
  cardData: ICard;
}

export interface IConfigItem {
  key: string;
  label: string;
  value: boolean;
}

export interface IConfigItemManager extends IConfigItem{
  endpoint: (cardId: string) => string;
}

export enum FunctionStatus {
  Enable = "ENABLE",
  Disable = "DISABLE",
}
