import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const HorarioLimiteOperacaoField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:horario_limite_operacao"
        dataLabel="horario_limite_operacao"
        id="horarioLimiteOperacao"
        name="horarioLimiteOperacao"
        tag="mask"
        label={EstrategiaFormLabels.horarioLimiteOperacao}
        placeholder="00:00"
        description="Limite de horário no qual a operação pode ser efetuada (Ex: 16:30)"
        maskOption={{ mask: "00{:}00" }}
      />
    </OCol>
  );
};
