import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../routes/ferramentas.route-params";
import { buildDeskNotificationsGrid } from "./desk-notifications.grid";

export const DeskNotificationsPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  const gridRef = useGridRef();

  if (!configIdentifier) throw new Error("No configIdentifier");

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Notificações" description={configIdentifier} />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
      actions={
        <OButton
          onClick={() => {
            gridRef.current?.instance.addRow();
          }}
        >
          Adicionar
        </OButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildDeskNotificationsGrid(configIdentifier)}
      />
    </GridTemplate>
  );
};
