import styled, { css } from "styled-components";
import { ICardSectionStylesProps } from "./already-approved.types";

const ColumnFlex = css`
  display: flex;
  flex-direction: column;
`;

export const MenuItemContent = styled.div`
  ${ColumnFlex};
  padding-bottom: 5px;
  text-align: left;
  * {
    cursor: pointer;
  }
  span {
    font-size: 16px;
  }
  label {
    span {
      margin-left: 5px;
    }
  }
`;

export const CardContainer = styled.div`
  ${ColumnFlex};
  row-gap: 40px;
  margin-left: 20px;
`;

export const CardSection = styled.div<ICardSectionStylesProps>`
  ${ColumnFlex};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;
