import { client } from "../../client";
import { clerkEndpoints } from "../clerk.endpoints";
import {
  GetSalesforceUsersResponse,
  PostAdminCompaniesGetByTaxIdNullableResponse,
  PostSalesforceGetCompanyUsersResponse,
  PostAdminCompaniesGetByTaxIdBody,
  PostSalesforceGetCompanyUsersBody,
} from "./models";

export const clerkAdminService = {
  getCompanyDetails(body: PostAdminCompaniesGetByTaxIdBody) {
    return client.post<PostAdminCompaniesGetByTaxIdNullableResponse>(
      clerkEndpoints.adminCustomer.getCompanyDetails,
      body,
    );
  },
  getCompanyUsers(body: PostSalesforceGetCompanyUsersBody) {
    return client.post<PostSalesforceGetCompanyUsersResponse>(
      clerkEndpoints.adminCustomer.getCompanyUsers,
      body,
    );
  },
  getSalesforceUser() {
    return client.get<GetSalesforceUsersResponse>(
      clerkEndpoints.adminCustomer.users,
    );
  },
};
