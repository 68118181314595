import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuoteSuggest } from "../../quote-suggest.context";
import {
  searchModalDefaulValues,
  searchModalValidationSchema,
} from "./search-modal.form";
import { CompanySearchForm } from "./search-modal.types";

export const useSearchModal = () => {
  const { setSearchPayload } = useQuoteSuggest();

  const form = useForm<CompanySearchForm>({
    defaultValues: searchModalDefaulValues,
    resolver: yupResolver(searchModalValidationSchema),
  });

  const { handleSubmit, reset, setValue } = form;

  const clear = useCallback(() => reset(searchModalDefaulValues), [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        setSearchPayload({
          bandeiras: values.bandeiras,
          cedentes: values.cedentes,
          dataVencimentoFinal:
            values?.dataVencimentoFinal?.toDate().toISOString().split("T")[0] ??
            null,
          dataVencimentoInicial:
            values?.dataVencimentoInicial
              ?.toDate()
              .toISOString()
              .split("T")[0] ?? null,
          sacados: values.sacados,
          valorDesembolsoDesejado: values.valorDesembolsoDesejado,
        });
      }),
    [handleSubmit, setSearchPayload],
  );

  return { form, submit, clear, setValue };
};
