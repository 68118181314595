import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { CardReceivable } from "../../../../intubate-operations.types";
import { useQuoteList } from "../../quote-list.context";

export const useRequestedAmountCell = (data: CardReceivable) => {
  const { requestedAmounts, setDisbursementAmounts, setRequestedAmounts } =
    useQuoteList();

  const [innerValue, setInnerValue] = useState(
    requestedAmounts[data.ReceivableId] ?? data.ValorVencimento,
  );
  const [disabled, setDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);

  const edit = useCallback(() => setDisabled(false), []);

  const save = useCallback(() => {
    if (innerValue <= 0) {
      OToastManager.danger("O valor solicitado deve ser maior que zero");
      setHasError(true);
    } else if (innerValue > data.ValorVencimento) {
      OToastManager.danger(
        "O valor solicitado deve ser menor ou igual ao valor do vencimento",
      );
      setHasError(true);
    } else {
      setRequestedAmounts((_requestedAmounts) => ({
        ..._requestedAmounts,
        [data.ReceivableId]: innerValue,
      }));
      setDisbursementAmounts((_disbursementAmounts) => ({
        ..._disbursementAmounts,
        [data.ReceivableId]: innerValue * (1 - data.TaxaTotal),
      }));
      setDisabled(true);
      setHasError(false);
    }
  }, [
    data.ReceivableId,
    data.TaxaTotal,
    data.ValorVencimento,
    innerValue,
    setDisbursementAmounts,
    setRequestedAmounts,
  ]);

  useEffect(() => {
    setInnerValue(requestedAmounts[data.ReceivableId] ?? data.ValorVencimento);
  }, [data.ReceivableId, data.ValorVencimento, requestedAmounts]);

  return {
    disabled,
    edit,
    hasError,
    innerValue,
    save,
    setInnerValue,
  };
};
