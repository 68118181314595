import {
  Requirement,
  RequirementKey,
  RequirementStatus,
} from "./requirements-modal.types";

export const DEFAULT_REQUIREMENTS: Requirement[] = [
  {
    key: RequirementKey.Solicitation,
    status: RequirementStatus.Approved,
    show: true,
    title: "Solicitação do cartão",
    description: "Os dados do cartão já estão completos.",
  },
  {
    key: RequirementKey.Guarantors,
    status: RequirementStatus.Pending,
    show: true,
    title: "Cadastro dos responsáveis pelo cartão",
    description:
      "Os sócios responsáveis pelo cartão precisam estar com o cadastro completo e atualizado.",
  },
  {
    key: RequirementKey.Signatures,
    status: RequirementStatus.Pending,
    show: true,
    title: "Assinaturas necessárias",
    description:
      "É preciso aguardar enquanto os aprovadores e responsáveis assinam o pedido do cartão.",
  },
  {
    key: RequirementKey.Feedback,
    status: RequirementStatus.Pending,
    show: true,
    title: "Emissão e envio do cartão",
    description:
      "Quando todas as etapas acima estiverem ok iremos seguir com o envio do cartão.",
  },
];

export const MapperRequirementStatus: Record<
  RequirementStatus,
  [
    string,
    HTMLOIconElement["icon"],
    HTMLOIconElement["category"],
    HTMLOIconElement["type"],
    HTMLOBadgeElement["type"],
  ]
> = {
  [RequirementStatus.Approved]: [
    "Concluído",
    "orq-status-success",
    "orq",
    "success",
    "success-light",
  ],
  [RequirementStatus.Pending]: [
    "Pendente",
    "orq-status-warning",
    "orq",
    "warning",
    "warning-light",
  ],
  [RequirementStatus.Failure]: [
    "Falha",
    "orq-status-error",
    "orq",
    "danger",
    "danger-light",
  ],
};
