import { ODivider, OSkeletonCard, OTypography } from "@maestro/react";
import { DetailLineProps } from "./detail-line.type";

export const DetailLine = ({
  title,
  content,
  isLoading,
  omitDivider,
}: DetailLineProps) => {
  return (
    <>
      <div className="d-flex aling-items-center justify-content-between gap-3">
        <OTypography type="dark" key={title}>
          {title}
        </OTypography>

        {isLoading ? (
          <OSkeletonCard width="4rem" height="1rem" rounded />
        ) : Array.isArray(content) ? (
          <div className="d-flex flex-column align-items-end">
            <OTypography type="dark-80" key={content[0]}>
              {content[0]}
            </OTypography>
            <OTypography type="dark-60" key={content[1]} size="sm">
              {content[1]}
            </OTypography>
          </div>
        ) : (
          <OTypography type="dark-80" key={content}>
            {content}
          </OTypography>
        )}
      </div>
      {!omitDivider && <ODivider />}
    </>
  );
};
