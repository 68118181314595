import { datadogRum } from "@datadog/browser-rum";
import { customerEvents } from "contexts/customer";
import { userEvents } from "contexts/user";

interface DDUser extends Record<string, unknown> {
  id?: string; // taxId
  name?: string;
  email?: string;
  uuid?: string;
  company?: {
    identification?: string;
    name?: string;
  };
}

export const trackUser = () => {
  let ddUser: DDUser = {};

  userEvents.subscribe((user) => {
    if (user.state === "AUTHENTICATED" && user.value) {
      const { cpf: id, name, email, uuid } = user.value;

      ddUser = {
        ...ddUser,
        id: id ?? undefined,
        name: name ?? undefined,
        email: email ?? undefined,
        uuid: uuid ?? undefined,
      };

      datadogRum.setUser(ddUser);
    }
  });

  customerEvents.subscribe((customer) => {
    if (customer.state === "SELECTED" && customer.value) {
      const { identification, officialName: name } = customer.value;

      ddUser = {
        ...ddUser,
        company: {
          identification,
          name: name ?? undefined,
        },
      };
    } else if (["ERROR", "EMPTY"].includes(customer.state)) {
      ddUser = {
        ...ddUser,
        company: undefined,
      };
    }

    datadogRum.setUser(ddUser);
  });
};
