import { OCardBody, OCol, ORow } from "@maestro/react";
import { DetailWallet } from "pages/duplicatas/pages/cliente/informacoes/_compose/detail-wallet/detail-wallet.component";
import { OwnersInState } from "services/moana/models";

interface OwnerInStateCardProps {
  ownersInState: OwnersInState;
  totalOwners: number;
}

export const OwnerInStateCard = ({
  ownersInState,
  totalOwners,
}: OwnerInStateCardProps) => {
  function tryGetValue(states: OwnersInState | undefined, key: number) {
    if (!states) return null;
    var result = states[key];
    if (!result?.portfolioStatus) return null;
    return typeof result !== "undefined" ? result : null;
  }

  return (
    <OCardBody className="position-relative">
      <ORow>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Processo de contratação"
            value={(
              totalOwners - (tryGetValue(ownersInState, 3)?.ownersCount ?? 0)
            ).toString()}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Solicitação de abertura de conta"
            value={(
              totalOwners - (tryGetValue(ownersInState, 0)?.ownersCount ?? 0)
            ).toString()}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Contrato de abertura de conta"
            value={(
              totalOwners - (tryGetValue(ownersInState, 1)?.ownersCount ?? 0)
            ).toString()}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Cadastro dos avalistas"
            value={(
              totalOwners - (tryGetValue(ownersInState, 2)?.ownersCount ?? 0)
            ).toString()}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Contrato do produto"
            value={(
              totalOwners - (tryGetValue(ownersInState, 4)?.ownersCount ?? 0)
            ).toString()}
          />
        </OCol>
      </ORow>
    </OCardBody>
  );
};
