import dayjs from "dayjs";
import { NotesFilterForm } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/note-filter.types";
import {
  filtersToSearchParams,
  isEmpty,
} from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/note-filter.utils";
import { PostAdminReceivablesSummaryBody } from "services/quickfin/models";
import { parsePeriodDate } from "utils/parse-period-date";

export { filtersToSearchParams, isEmpty };

export const statesOptions = [
  "AguardandoRegistro",
  "AprovadoBanco",
  "AprovadoCedente",
  "AprovadoOnboarding",
  "DisponivelParaCedente",
  "EmCancelamento",
  "FundingProprioRecusado",
  "Inicial",
  "PendenteAprovacaoBanco",
  "PendenteAprovacaoCedente",
  "PendenteContratacao",
  "PendenteEligibilidade",
  "PendenteOnboarding",
  "PendenteReleaseTrade",
  "PendenteSolicitacaoTrade",
  "PendenteValidacaoTaxas",
  "Recusado",
  "Removido",
  "TradeDesembolsado",
  "TradeEmAtraso",
  "TradeLiquidado",
  "TradeSolicitado",
];

export const newDuplicatasFilterDefaultValues: NotesFilterForm = {
  sacadoIdList: [],
  cedenteIdList: [],
  tipoProdutoList: ["CCC"],
  currentStateList: [],
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  borderoId: [],
  operationId: [],
  creditorIds: [],
  marketplaceIds: [],
  portfolioIds: [],
  tradeDate: [],
  disbursementDate: [],
  maturityDate: [],
  createdAt: [],
  updatedAt: [dayjs(), dayjs()],
};

export const duplicatasNoteFilterModalId = "note-filter-modal";

export const buildPayload = (
  values: NotesFilterForm,
  cedenteId?: string,
): Omit<PostAdminReceivablesSummaryBody, "page" | "rows"> => {
  const createdAt = parsePeriodDate(values.createdAt);
  const disbursementDate = parsePeriodDate(values.disbursementDate);
  const maturityDate = parsePeriodDate(values.maturityDate);
  const tradeDate = parsePeriodDate(values.tradeDate);
  const updatedAt = parsePeriodDate(values.updatedAt);

  return {
    borderoId: values.borderoId,
    currentStateList: values.currentStateList,
    fromCreatedAt: createdAt[0] ?? "",
    fromDisbursementDate: disbursementDate[0] ?? "",
    fromMaturityDate: maturityDate[0] ?? "",
    fromTradeDate: tradeDate[0] ?? "",
    fromUpdatedAt: updatedAt[0] ?? "",
    operationId: values.operationId,
    receivableId: values.receivableId,
    receivableIdentification: values.receivableIdentification,
    externalIds: values.externalIds,
    contractNumbers: values.contractNumbers,
    strategyGuids: values.strategyGuids,
    sacadoIdList: values.sacadoIdList,
    tipoProdutoList: values.tipoProdutoList,
    toCreatedAt: createdAt[1] ?? "",
    toDisbursementDate: disbursementDate[1] ?? "",
    toMaturityDate: maturityDate[1] ?? "",
    toTradeDate: tradeDate[1] ?? "",
    toUpdatedAt: updatedAt[1] ?? "",
    tradeId: values.tradeId,
    cedenteIdList: !isEmpty(cedenteId) ? [cedenteId] : [],
  };
};

const newFilterDefaultValuesEntries = Object.entries(
  newDuplicatasFilterDefaultValues,
);

export const getSearchParamsNoteFilter = (
  searchParams: URLSearchParams,
  allowEmpty = true,
) =>
  newFilterDefaultValuesEntries.reduce((acc, [key, value]) => {
    const isArrayValue = Array.isArray(value);
    const searchParamValue = isArrayValue
      ? searchParams.getAll(key)
      : searchParams.get(key);
    const emptyValue = isArrayValue ? [] : "";
    return {
      ...acc,
      [key]: isEmpty(searchParamValue)
        ? allowEmpty
          ? emptyValue
          : value
        : searchParamValue,
    };
  }, newDuplicatasFilterDefaultValues);
