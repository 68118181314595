import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useEffect, useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { useEstrategiaComercial } from "../../_compose";
import { buildDataSource, buildTaxasGrid } from "./view-taxas.grid";
import { Taxa } from "./view-taxas.types";
import { CurrencySelector } from "./_compose";

export const ViewTaxas = () => {
  const gridRef = useGridRef();
  const [selectedCurrency, setSelectedCurrency] = useState("BRL");
  const [isSpreadsheet, setIsSpreadsheet] = useState(false);
  const [hasRebate, setHasRebate] = useState(false);
  const [isRebateFixo, setIsRebateFixo] = useState(false);

  const { estrategia, estrategiaId, quickfinId } = useEstrategiaComercial();

  const dataSource = useMemo(
    () => buildDataSource(quickfinId, estrategiaId, selectedCurrency),
    [estrategiaId, quickfinId, selectedCurrency],
  );

  const taxasGrid = useMemo(
    () => buildTaxasGrid(isSpreadsheet, hasRebate, isRebateFixo),
    [hasRebate, isRebateFixo, isSpreadsheet],
  );

  useEffect(() => {
    if (estrategia) {
      setIsSpreadsheet(estrategia.modeloDeTaxa === "planilha");
    }
  }, [estrategia]);

  useEffect(() => {
    dataSource.on("changed", () => {
      const firstTaxa = dataSource.items()?.[0] as Taxa | undefined;
      if (firstTaxa) {
        setHasRebate(
          !["SemRebate", "SemRebateComTrava"].includes(firstTaxa.rebateType),
        );
        setIsRebateFixo(firstTaxa.rebateType === "Fixo");
      }
    });
  }, [dataSource]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title={"Visualizar taxas - " + estrategia?.uniqueId} />}
      actions={
        <CurrencySelector
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={taxasGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
