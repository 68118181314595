import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddTCOStrategies } from "./add-tco-strategy.hook";
import { TCOStrategiesForm } from "../_compose";

export const AddTCOStrategies = () => {
  const { form, loading, onSubmit, setValue, watch } = useAddTCOStrategies();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar estratégia" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="estrategias_tco:botao:adicionar"
          dataLabel="adicionar"
          onClick={onSubmit}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <TCOStrategiesForm setValue={setValue} watch={watch} />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
