import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { AditamentoODataValueItem } from "services/quickfin/models";

export const grid: ODataGridGeneratorConfig<AditamentoODataValueItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      caption: "Tipo",
      dataField: "TradeType",
      dataType: "string",
    },
    {
      caption: "ID Trade",
      dataField: "TradeId",
      dataType: "number",
    },
    {
      caption: "ID Alteração",
      dataField: "Id",
      dataType: "number",
    },
    {
      caption: "Identificação",
      dataField: "Identification",
      dataType: "string",
    },
    {
      dataField: "Status",
      caption: "Status",
      dataType: "string",
    },
    {
      dataField: "ParentAditamentoId",
      caption: "ID Aditamento Pai",
      dataType: "number",
    },
    {
      caption: "Sacado",
      dataField: "SacadoName",
      dataType: "string",
    },
    {
      caption: "Cedente",
      dataField: "CedenteName",
      dataType: "string",
    },
    {
      caption: "Valor original",
      dataField: "OldMaturityAmount",
      format: (value) => masks.currency(value, "R$"),
      dataType: "number",
    },
    {
      caption: "Valor aditado",
      dataField: "NewMaturityAmount",
      format: (value) => masks.currency(value, "R$"),
      dataType: "number",
    },
    {
      caption: "Vencimento original",
      dataField: "OldMaturityDate",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Vencimento aditado",
      dataField: "NewMaturityDate",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Data Trade",
      dataField: "TradeDate",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "FCC",
      dataField: "Fcc",
      dataType: "number",
    },
    {
      caption: "Conta número",
      dataField: "AccountNumber",
      dataType: "string",
    },
    {
      caption: "Conta dígito",
      dataField: "AccountDigit",
      dataType: "string",
    },
    {
      caption: "Conta tipo",
      dataField: "AccountType",
      dataType: "string",
    },
    {
      caption: "Agência",
      dataField: "Agency",
      dataType: "string",
    },
    {
      caption: "Código banco",
      dataField: "BankCode",
      dataType: "string",
    },
    {
      caption: "BookCode",
      dataField: "BookCode",
      dataType: "number",
    },
    {
      caption: "Código Garantia",
      dataField: "GaranteeCode",
      dataType: "string",
    },
    {
      caption: "Contrato Referência",
      dataField: "ContractIdentification",
      dataType: "string",
    },
    {
      caption: "HasHedge",
      dataField: "HasHedge",
      dataType: "boolean",
    },
    {
      caption: "HolderTaxId",
      dataField: "HolderTaxId",
      format: (value) => masks.cpfCnpj(value),
      dataType: "string",
    },
    {
      caption: "Chave pix",
      dataField: "PixKey",
      dataType: "string",
    },
    {
      caption: "Tipo pix",
      dataField: "PixType",
      dataType: "string",
    },
    {
      caption: "Iof",
      dataField: "Iof",
      dataType: "string",
    },
    {
      caption: "Data Liquidação",
      dataField: "SettlementDate",
      dataType: "date",
    },
    {
      caption: "Liquidação Cód. Banco",
      dataField: "SettlementBankCode",
      dataType: "string",
    },
    {
      caption: "Liquidação Agência",
      dataField: "SettlementAgency",
      dataType: "string",
    },
    {
      caption: "Liquidação Dígito Agência",
      dataField: "SettlementAgencyDigit",
      dataType: "string",
    },
    {
      caption: "Liquidação Número Conta",
      dataField: "SettlementAccountNumber",
      dataType: "string",
    },
    {
      caption: "Liquidação Dígito Conta",
      dataField: "SettlementAccountDigit",
      dataType: "string",
    },
    {
      caption: "Liquidação Holder TaxId",
      dataField: "SettlementHolderTaxId",
      format: (value) => masks.cpfCnpj(value),
      dataType: "string",
    },
    {
      caption: "Liquidação Conta Primária?",
      dataField: "SettlementIsPrimary",
      dataType: "boolean",
    },
    {
      caption: "Liquidação Consolidado?",
      dataField: "SettlementIsConsolidated",
      dataType: "boolean",
    },
    {
      caption: "Liquidação Lock Info",
      dataField: "SettlementLockInfo",
      dataType: "string",
    },
    {
      caption: "Liquidação Party",
      dataField: "SettlementParty",
      dataType: "string",
    },
    {
      caption: "Liquidação Lock Ref CGE",
      dataField: "SettlementLockRefCge",
      dataType: "number",
    },
    {
      caption: "Liquidação Cód. Método",
      dataField: "SettlementMethodCode",
      dataType: "string",
    },
    {
      caption: "Nº de Registro",
      dataField: "RegistrationNumber",
      dataType: "string",
    },
    {
      caption: "Criado em",
      dataField: "CreatedAt",
      dataType: "date",
      format: "shortDate",
    },
    {
      caption: "Atualizado em",
      dataField: "UpdatedAt",
      dataType: "datetime",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
    },
  ],
};
