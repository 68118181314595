import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import {
  ClientAccountsRelationshipsPage,
  ClientCustomersRelationshipsPage,
  ClientReferralsRelationshipsPage,
  ClientOperatorsPage,
  ProductOperatorsPage,
  ProductOriginatorsPage,
} from "../pages";
import { Originator } from "../pages/cliente/originadores";
import { UpdateOriginatorCategorizations } from "../pages/cliente/originadores/atualiza-categorizacoes-originador";
import { UpdateOriginatorInfo } from "../pages/cliente/originadores/atualizar-detalhes-originador";
import { UpdateOriginatorIndication } from "../pages/cliente/originadores/atualizar-indicacao-originador";
import { BooksPage } from "../pages/produto/configuracoes/manutencao-de-produtos/books";
import { AddBook } from "../pages/produto/configuracoes/manutencao-de-produtos/books/adicionar";
import { EditBook } from "../pages/produto/configuracoes/manutencao-de-produtos/books/editar";
import { CatalogsPage } from "../pages/produto/configuracoes/manutencao-de-produtos/catalogos";
import { AddCatalog } from "../pages/produto/configuracoes/manutencao-de-produtos/catalogos/adicionar";
import { EditCatalog } from "../pages/produto/configuracoes/manutencao-de-produtos/catalogos/editar";
import { Products } from "../pages/produto/configuracoes/manutencao-de-produtos/produtos";
import { AddProduct } from "../pages/produto/configuracoes/manutencao-de-produtos/produtos/adicionar";
import { EditProduct } from "../pages/produto/configuracoes/manutencao-de-produtos/produtos/editar";
import { Links } from "../pages/produto/configuracoes/manutencao-de-produtos/vinculos";
import { AddLink } from "../pages/produto/configuracoes/manutencao-de-produtos/vinculos/adicionar";
import { OriginatorSubtypes } from "../pages/produto/configuracoes/subtipos-originadores";
import { AddOriginatorSubtype } from "../pages/produto/configuracoes/subtipos-originadores/adicionar";
import { EditOriginatorSubtype } from "../pages/produto/configuracoes/subtipos-originadores/editar";
import { PartnershipTypes } from "../pages/produto/configuracoes/tipos-parceria";
import { AddPartnershipType } from "../pages/produto/configuracoes/tipos-parceria/adicionar";
import { EditPartnershipType } from "../pages/produto/configuracoes/tipos-parceria/editar";
import { Desks } from "../pages/produto/configuracoes/mesas";
import { AddDesk } from "../pages/produto/configuracoes/mesas/adicionar";
import { EditDesk } from "../pages/produto/configuracoes/mesas/editar";
import {
  OperatorDetailByIdentification,
  ProfilesByIdentification,
} from "../pages/produto/operadores/[operatorId]";
import { NewOriginator } from "../pages/produto/originadores/novo-originador";
import { ProductCustomersRelationships } from "../pages/produto/vinculos-clientes";
import { ProductAccountsRelationships } from "../pages/produto/vinculos-contas";
import { ProductReferralsRelationships } from "../pages/produto/vinculos-indicacoes";
import { parceirosRoles } from "../roles/parceiros.roles";
import { parceirosRouteParams } from "./parceiros.route-params";
import { ClientEconomicGroupPage } from "../pages/cliente/grupo-economico";

export const parceirosRoutes = {
  customer: {
    name: "",
    path: "/parceiros/cliente",
    element: <RedirectWithRoles />,
    children: {
      originator: {
        name: "Originador",
        path: "/parceiros/cliente/originador",
        role: parceirosRoles.customer.originatorPage.role,
        element: <Originator />,
        showChildren: true,
        hasSubItems: false,
        children: {
          details: {
            name: "Detalhes",
            path: "/parceiros/cliente/originador/detalhes",
            role: parceirosRoles.customer.originatorEditInfo.role,
            element: <UpdateOriginatorInfo />,
          },
          permissions: {
            name: "Categorização",
            path: "/parceiros/cliente/originador/permissoes",
            role: parceirosRoles.customer.originatorEditPermissions.role,
            element: <UpdateOriginatorCategorizations />,
          },
          indication: {
            name: "Indicação",
            path: "/parceiros/cliente/originador/indicacao",
            role: parceirosRoles.customer.originatorEditIndication.role,
            element: <UpdateOriginatorIndication />,
          },
        },
      },
      economicGroup: {
        name: "Grupo Econômico",
        path: "/parceiros/cliente/grupo-economico",
        role: parceirosRoles.customer.economicGroupPage.role,
        element: <ClientEconomicGroupPage />,
      },
      operators: {
        name: "Perfis de Operador",
        path: "/parceiros/cliente/perfis-operadores",
        role: parceirosRoles.customer.operatorsPage.role,
        element: <ClientOperatorsPage />,
        hasSubItems: false,
        children: {
          details: {
            name: "Detalhes",
            path: `/parceiros/cliente/perfis-operadores/:${parceirosRouteParams.operatorProfileIdentifier}/:${parceirosRouteParams.operatorIdentification}/detalhes`,
            role: parceirosRoles.customer.operatorDetailsPage.role,
            element: <OperatorDetailByIdentification />,
          },
        },
      },
      customersRelationship: {
        name: "Vínculos de clientes",
        path: "/parceiros/cliente/vinculos-clientes",
        role: parceirosRoles.customer.customersRelationship.role,
        element: <ClientCustomersRelationshipsPage />,
      },
      accountsRelationship: {
        name: "Vínculos de contas",
        path: "/parceiros/cliente/vinculos-contas",
        role: parceirosRoles.customer.accountsRelationship.role,
        element: <ClientAccountsRelationshipsPage />,
      },
      referralsRelationship: {
        name: "Vínculos de Indicações",
        path: "/parceiros/cliente/vinculos-indicacoes",
        showChildren: true,
        role: parceirosRoles.customer.referralsRelationship.role,
        element: <ClientReferralsRelationshipsPage />,
      },
    },
  },
  product: {
    name: "Parceiros",
    path: "/parceiros/produto",
    element: <RedirectWithRoles />,
    children: {
      customersRelationship: {
        name: "Vínculos de Clientes",
        path: "/parceiros/produto/vinculos-cliente",
        showChildren: true,
        role: parceirosRoles.product.customersRelationship.role,
        element: <ProductCustomersRelationships />,
      },
      accountsRelationship: {
        name: "Vínculos de Contas",
        path: "/parceiros/produto/vinculos-contas",
        showChildren: true,
        role: parceirosRoles.product.accountsRelationship.role,
        element: <ProductAccountsRelationships />,
      },
      referralsRelationship: {
        name: "Vínculos de Indicações",
        path: "/parceiros/produto/vinculos-indicacoes",
        showChildren: true,
        role: parceirosRoles.product.referralsRelationship.role,
        element: <ProductReferralsRelationships />,
      },
      originators: {
        name: "Originadores",
        path: "/parceiros/produto/originador",
        role: parceirosRoles.product.originatorsPage.role,
        element: <ProductOriginatorsPage />,
        children: {
          add: {
            name: "Cadastro",
            path: "/parceiros/produto/originador/cadastro",
            element: <NewOriginator />,
            role: parceirosRoles.product.originatorsAdd.role,
          },
        },
      },
      operators: {
        name: "Perfis de Operador",
        path: "/parceiros/produto/perfis-operadores",
        role: parceirosRoles.customer.operatorsPage.role,
        element: <ProductOperatorsPage />,
        hasSubItems: false,
        children: {
          details: {
            name: "Detalhes do operador",
            path: `/parceiros/produto/perfis-operadores/:${parceirosRouteParams.operatorProfileIdentifier}/:${parceirosRouteParams.operatorIdentification}`,
            hasSubItems: true,
            children: {
              details: {
                name: "Detalhes",
                path: `/parceiros/produto/perfis-operadores/:${parceirosRouteParams.operatorProfileIdentifier}/:${parceirosRouteParams.operatorIdentification}/detalhes`,
                role: parceirosRoles.product.operatorDetails.role,
                element: <OperatorDetailByIdentification />,
                hasSubItems: false,
              },
              profiles: {
                name: "Perfis",
                path: `/parceiros/produto/perfis-operadores/:${parceirosRouteParams.operatorProfileIdentifier}/:${parceirosRouteParams.operatorIdentification}/perfis`,
                role: parceirosRoles.product.operatorRelatedEntities.role,
                element: <ProfilesByIdentification />,
                hasSubItems: false,
              },
            },
          },
        },
      },
      configurations: {
        name: "Configurações",
        path: "/parceiros/produto/configuracoes",
        hasSubItems: true,
        role: parceirosRoles.product.configurations.role,
        children: {
          partnershipTypes: {
            name: "Tipos de Parceria",
            path: "/parceiros/produto/configuracoes/tipos-parceria",
            showChildren: false,
            role: parceirosRoles.product.partnershipTypes.role,
            element: <PartnershipTypes />,
            children: {
              add: {
                name: "Adicionar Tipo de Originador",
                path: "/parceiros/produto/configuracoes/tipos-parceria/adicionar",
                element: <AddPartnershipType />,
                role: parceirosRoles.product.addPartnershipType.role,
              },
              edit: {
                name: "Editar Tipo de Originador",
                path: "/parceiros/produto/configuracoes/tipos-parceria/editar",
                element: <EditPartnershipType />,
                role: parceirosRoles.product.editPartnershipType.role,
              },
            },
          },
          originatorSubtypes: {
            name: "Subtipos de Originador",
            path: "/parceiros/produto/configuracoes/subtipos-originador",
            showChildren: false,
            role: parceirosRoles.product.originatorSubtypes.role,
            element: <OriginatorSubtypes />,
            children: {
              add: {
                name: "Adicionar Subtipo de Originador",
                path: "/parceiros/produto/configuracoes/subtipos-originador/adicionar",
                element: <AddOriginatorSubtype />,
                role: parceirosRoles.product.addOriginatorSubtype.role,
              },
              edit: {
                name: "Editar Subtipo de Originador",
                path: "/parceiros/produto/configuracoes/subtipos-originador/editar",
                element: <EditOriginatorSubtype />,
                role: parceirosRoles.product.editOriginatorSubtype.role,
              },
            },
          },
          desks: {
            name: "Mesas",
            path: "/parceiros/produto/configuracoes/mesas",
            showChildren: false,
            role: parceirosRoles.product.desks.role,
            element: <Desks />,
            children: {
              add: {
                name: "Adicionar Mesar",
                path: "/parceiros/produto/configuracoes/mesas/adicionar",
                element: <AddDesk />,
                role: parceirosRoles.product.addDesk.role,
              },
              edit: {
                name: "Editar Mesa",
                path: "/parceiros/produto/configuracoes/mesas/editar",
                element: <EditDesk />,
                role: parceirosRoles.product.editDesk.role,
              },
            },
          },
          productMaintenance: {
            name: "Manutenção de Produtos",
            path: "/parceiros/produto/configuracoes/manutencao-produtos",
            hasSubItems: true,
            children: {
              products: {
                name: "Produtos",
                path: "/parceiros/produto/configuracoes/manutencao-produtos/produtos",
                element: <Products />,
                role: parceirosRoles.product.products.role,
                showChildren: false,
                children: {
                  add: {
                    name: "Cadastro de Produto",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/produtos/adicionar",
                    element: <AddProduct />,
                    role: parceirosRoles.product.addProduct.role,
                  },
                  edit: {
                    name: "Cadastro de Produto",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/produtos/editar",
                    element: <EditProduct />,
                    role: parceirosRoles.product.editProduct.role,
                  },
                },
              },
              books: {
                name: "Books",
                path: "/parceiros/produto/configuracoes/manutencao-produtos/books",
                element: <BooksPage />,
                role: parceirosRoles.product.books.role,
                showChildren: true,
                children: {
                  add: {
                    name: "Cadastro de Book",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/books/adicionar",
                    element: <AddBook />,
                    role: parceirosRoles.product.addBook.role,
                  },
                  edit: {
                    name: "Edição de Book",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/books/editar",
                    element: <EditBook />,
                    role: parceirosRoles.product.editBook.role,
                  },
                },
              },
              catalogs: {
                name: "Catálogos",
                path: "/parceiros/produto/configuracoes/manutencao-produtos/catalogs",
                element: <CatalogsPage />,
                role: parceirosRoles.product.catalogs.role,
                hasSubItems: false,
                children: {
                  add: {
                    name: "Cadastro de Catálogo",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/catalogs/adicionar",
                    element: <AddCatalog />,
                    role: parceirosRoles.product.addCatalog.role,
                  },
                  edit: {
                    name: "Edição de Catálogo",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/catalogs/editar",
                    element: <EditCatalog />,
                    role: parceirosRoles.product.editCatalog.role,
                  },
                },
              },
              links: {
                name: "Vínculos",
                path: "/parceiros/produto/configuracoes/manutencao-produtos/vinculos",
                element: <Links />,
                role: parceirosRoles.product.links.role,
                showChildren: true,
                children: {
                  add: {
                    name: "Parametrizar Produto",
                    path: "/parceiros/produto/configuracoes/manutencao-produtos/vinculos/adicionar",
                    element: <AddLink />,
                    role: parceirosRoles.product.addLink.role,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
