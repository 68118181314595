import styled from "styled-components";

export const ContainerGrid = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 15px;
  margin-bottom: 30px;
`;

export const InvoiceCard = styled.div<{
  isActive: boolean;
  colorStatus: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: ${(props) =>
    props.isActive ? "2px solid #1890ff" : `2px solid ${props.colorStatus}90`};
  background-color: white;
  color: grey;
  width: 20%;

  :hover {
    border: ${(props) =>
      props.isActive ? "2px solid #1890ff" : `2px solid ${props.colorStatus}`};

    * {
      cursor: ${(props) => (props.isActive ? "auto" : "pointer")};
    }

    #invoice-date {
      border-bottom: ${(props) =>
        props.isActive
          ? "2px solid #1890ff"
          : `2px solid ${props.colorStatus}`};
    }
    #invoice-status {
      border-top: ${(props) =>
        props.isActive
          ? "2px solid #1890ff"
          : `2px solid ${props.colorStatus}`};
    }
  }
`;

export const InvoiceDate = styled.span<{
  isActive: boolean;
  colorStatus: string;
}>`
  text-align: center;
  margin: auto;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  padding: 15px 0px;
  color: ${(props) => (props.isActive ? "white" : "black")};
  background-color: ${(props) =>
    props.isActive ? "rgba(24, 144, 255, 0.65)" : "white"};
  border-bottom: ${(props) =>
    props.isActive ? "2px solid #1890ff" : `2px solid ${props.colorStatus}90`};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const InvoiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  row-gap: 15px;
  font-size: 16px;

  span:last-child {
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
`;

export const InvoiceStatus = styled.span<{
  isActive: boolean;
  colorStatus: string;
}>`
  text-align: center;
  margin: auto;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  padding: 15px 0px;
  color: ${(props) => (props.isActive ? "white" : props.colorStatus)};
  background-color: ${(props) =>
    props.isActive ? "rgba(24, 144, 255, 0.65)" : "white"};
  border-top: ${(props) =>
    props.isActive ? "2px solid #1890ff" : `2px solid ${props.colorStatus}90`};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
