import {
  OModal,
  OModalHeader,
  OModalBody,
  modalManager,
  OTypography,
  OConfirmationModal,
  OToastManager,
} from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { useState } from "react";
import { sharkService } from "services/shark/shark.service";

interface ModalSectionProps {
  title: string;
  content: string;
}

const ModalSection = ({ title, content }: ModalSectionProps) => {
  return (
    <div
      className="d-flex flex-column gap-2"
      style={{ whiteSpace: "pre-wrap" }}
    >
      <OTypography size="lg" weight="500">
        {title}
      </OTypography>
      <OTypography>{content}</OTypography>
    </div>
  );
};

interface MensagemGridActionsProps {
  title?: string;
  subtitle?: string;
  body?: string;
  id: number;
}

export const MensagemGridActions = ({
  title,
  subtitle,
  body,
  id,
}: MensagemGridActionsProps) => {
  const viewModalId = `visualiza-notification-message-${id}-content`;
  const resendModalId = `reenvia-estimulo-grupo`;

  const [loading, setLoading] = useState(false);

  const handleResendNotification = async () => {
    try {
      setLoading(true);
      await sharkService.republishNotification({
        type: "notification_group",
        id,
      });
      OToastManager.success("Estímulo reenviado com sucesso!");
      modalManager.hide(resendModalId);
    } catch {
      OToastManager.danger("Erro ao reenviar estímulo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataGridAction
        actions={[
          {
            icon: { category: "far", icon: "fa-eye" },
            label: "Visualizar conteúdo",
            onClick: () => modalManager.show(viewModalId),
          },
          {
            icon: { category: "far", icon: "fa-paper-plane" },
            label: "Reenviar estímulo",
            onClick: () => modalManager.show(resendModalId),
          },
        ]}
      />
      <OModal id={viewModalId} position="center">
        <OModalHeader closeButton divider>
          <OTypography size="xl" weight="bold">
            Conteúdo da mensagem
          </OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-5 mb-4">
            {title && <ModalSection title="Título" content={title} />}
            {subtitle && <ModalSection title="Subtítulo" content={subtitle} />}
            {body && <ModalSection title="Corpo da mensagem" content={body} />}
          </div>
        </OModalBody>
      </OModal>
      <OConfirmationModal
        modalId={resendModalId}
        title="Reenviar estímulo?"
        closeLabel="Cancelar"
        confirmLabel="Sim"
        onConfirm={handleResendNotification}
        loading={loading}
      >
        <OTypography>
          Deseja reenviar o estímulo para todos os seus destinatários?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
