export enum AccountType {
  CC = "CC",
  PP = "PP",
  PG = "PG",
  CI = "CI",
  MASKED = "**",
}

export enum PixKeyType {
  Email = "EMAIL",
  Phone = "PHONE",
  TaxId = "TAX_ID",
  EVP = "EVP",
  QrCode = "QR_CODE", // Front value
}

export enum PixQrCodeType {
  Static = "STATIC",
  Dynamic = "DYNAMIC",
}

export enum BankslipType {
  Bit = "BIT",
  Bpc = "BPC",
  Bcc = "BCC",
}

export enum PaymentType {
  PixManual = "PIX_MANUAL",
  PixKey = "PIX_KEY",
  PixQrCode = "PIX_QR_CODE",
  PixReversal = "PIX_REVERSAL",
  Ted = "TED",
  Tef = "TEF",
  Darf = "DARF",
  Bankslip = "BANKSLIP",
  Utilities = "UTILITIES",
  CreditCardInvoice = "CREDIT_CARD_INVOICE",
}

export enum PaymentStatus {
  AwaitingApproval = "AWAITING_APPROVAL", // FRONT STATUS
  Started = "STARTED",
  Created = "CREATED",
  Scheduled = "SCHEDULED",
  Canceled = "CANCELED",
  Adjourned = "ADJOURNED",
  Dispatched = "DISPATCHED",
  Processed = "PROCESSED",
  Failed = "FAILED",
  Confirmed = "CONFIRMED",
  Reverted = "REVERTED",
  Temporized = "TEMPORIZED",
}

export enum ReportType {
  Infraction = "INFRACTION",
}

export enum ReportStatus {
  ManualAnalysis = "MANUAL_ANALYSIS",
  Rejected = "REJECTED",
  Approved = "APPROVED",
}
