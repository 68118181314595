import { loadOptionsFilterToObject } from "components/data-grid";
import { LoadOptions } from "devextreme/data";
import {
  ReconResultDetails,
  ReconResultDetailsFilters,
} from "./reconciliation-details.types";

export const getResultDetailsFiltersFromLoadOptions = (
  loadOptions: LoadOptions<ReconResultDetails>,
): ReconResultDetailsFilters => {
  if (!Array.isArray(loadOptions.filter)) return {};

  const loadOptionsHasArray = loadOptions.filter.some((x) => Array.isArray(x));

  if (loadOptionsHasArray) {
    return loadOptions.filter.reduce((filters, item) => {
      if (Array.isArray(item)) {
        return { ...filters, ...loadOptionsFilterToObject(item) };
      }
      return filters;
    }, {} as ReconResultDetailsFilters);
  }

  return loadOptionsFilterToObject(loadOptions.filter);
};
