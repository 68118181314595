import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  AprovacaoAposAntecipacaoSacadoField,
  BiometryRequiredField,
  ComandoOperacaoField,
  DiasCampanhaNotasNaoAntecipadasCedenteField,
  DiasParaVencimentoSacadoField,
  EligibilityTypeField,
  EmailsSacadoNotificationField,
  ExigeAprovacaoBancoField,
  FccIdSacadoField,
  FilesForOperationField,
  FilesForReceivablesField,
  FundConfigField,
  FundRelatedNameField,
  HorarioLimiteOperacaoField,
  InstrumentCodeIdField,
  MarketplaceIdField,
  ModeloDeTaxaField,
  NeedsFilesForOperationField,
  NeedsFilesForReceivablesField,
  NomeField,
  NotaDisponivelCedenteField,
  NotificacaoOnboardingSacadoField,
  NotificacaoRecebiveisAntecipadosField,
  NotificacaoTedDevolvidaCedenteField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  ReporteDiarioSacadoField,
  ReporteNotasNaoAntecipadasCedenteField,
  SacadoDefineContaDesembolsoField,
  SefazValidationField,
  TipoOnboardingField,
  TypeFidcField,
  WorksiteEnvConfigField,
  GracePeriodField,
  NeedsRegistrationInfoField,
} from "../fields";
import { ToCreditField } from "../fields/to-credit-field.component";

interface FidcFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const FidcForm = ({ form, shouldReset }: FidcFormProps) => {
  const { getValues, reset, watch } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        horarioLimiteOperacao: "23:59",
        modeloDeTaxa: "configuracao",
        exigeAprovacaoBanco: "yes",
        toCredit: "yes",
      });
  }, [getValues, reset, shouldReset]);

  const reporteDiarioSacadoWatch = watch("reporteDiarioSacado");
  const reporteNotasNaoAntecipadasCedenteWatch = watch(
    "reporteNotasNaoAntecipadasCedente",
  );
  const needsFilesForOperationWatch = watch("needsFilesForOperation");
  const needsFilesForReceivablesWatch = watch("needsFilesForReceivables");

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <HorarioLimiteOperacaoField />
        <ExigeAprovacaoBancoField />
        <TipoOnboardingField type="FIDC" />
        <PrazoLimiteOperacaoField />
        <GracePeriodField/>
        <PnlBookField />
        <PnlStrategyField />
        <InstrumentCodeIdField />
        <MarketplaceIdField />
        <PortfolioIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        FIDC
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <SacadoDefineContaDesembolsoField />
        <ComandoOperacaoField required />
        <AprovacaoAposAntecipacaoSacadoField />
        <SefazValidationField />
        <TypeFidcField required />
        <EligibilityTypeField required />
        <WorksiteEnvConfigField />
        <FundConfigField />
        <FundRelatedNameField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Validações de entrada
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NeedsFilesForReceivablesField />
        <FilesForReceivablesField
          disabled={needsFilesForReceivablesWatch !== "yes"}
        />
        <NeedsFilesForOperationField />
        <FilesForOperationField
          disabled={needsFilesForOperationWatch !== "yes"}
        />
        <NeedsRegistrationInfoField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (sacado)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NotificacaoOnboardingSacadoField />
        <ReporteDiarioSacadoField />
        {reporteDiarioSacadoWatch === "yes" && (
          <DiasParaVencimentoSacadoField />
        )}
        <EmailsSacadoNotificationField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (cedente)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NotaDisponivelCedenteField />
        <ReporteNotasNaoAntecipadasCedenteField />
        {reporteNotasNaoAntecipadasCedenteWatch === "yes" && (
          <DiasCampanhaNotasNaoAntecipadasCedenteField />
        )}
        <NotificacaoRecebiveisAntecipadosField />
        <NotificacaoTedDevolvidaCedenteField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
        <FccIdSacadoField />
      </ORow>
    </>
  );
};
