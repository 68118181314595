import { OLoader, ORFieldUploadInput, OToastManager } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { useDocumentUpload } from "./document-upload.hook";

interface DocumentUploadProps {
  id: string;
  name: string;
  label?: string;
  description?: string;
  documentId: number | string;
  onlyOneFile?: boolean;
}

export const DocumentUpload = ({
  documentId,
  id,
  name,
  description,
  label,
  onlyOneFile,
}: DocumentUploadProps) => {
  const {
    download,
    remove,
    upload,
    downloadLoading,
    removeLoading,
    uploadLoading,
  } = useDocumentUpload();

  const { watch } = useFormContext();
  const files: unknown[] = watch(name);

  return (
    <div className="position-relative">
      <ORFieldUploadInput
        name={name}
        id={id}
        size="md"
        accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx"
        inputLabel="Selecionar arquivo"
        disabled={downloadLoading || removeLoading || uploadLoading}
        label={label}
        description={description}
        handleDownload={download}
        handleRemove={remove}
        handleAddFile={async (evt, resolve) => {
          if (onlyOneFile && !!files?.length)
            return OToastManager.warning({
              title: "Apenas um arquivo pode ser enviado",
              message: "Remova o arquivo atual para enviar outro",
            });

          await upload(evt, resolve, documentId);
        }}
      />
      {(downloadLoading || removeLoading || uploadLoading) && (
        <OLoader absolute />
      )}
    </div>
  );
};
