import { ODataGridGenerator } from "components/data-grid";
import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { useEffect } from "react";
import { PostAdminOperationsBody } from "services/quickfin/models";
import { OLoader, OToastManager, OTypography } from "@maestro/react";
import { useIntubateOperations } from "../../intubate-operations.context";
import { validateGrid } from "./validate.grid";

const ALLOW_NEXT = ["EstrategiaSemDesembolso", "EstadoVnp", "ValorMinimo"];

export const ValidateStep = () => {
  const { cardReceivables } = useIntubateOperations();

  const {
    callService: validateOperations,
    hasError: validateHasError,
    loading: validateLoading,
    value: validateResult,
  } = useServiceCall(service.quickfin.validateOperations);

  useEffect(() => {
    async function validate() {
      const payload = cardReceivables.map(
        ({
          ReceivableId,
          ValorSolicitado,
          ValorDesembolsoSolicitado,
          DataDesembolso,
          DataVencimento,
        }) => ({
          id: ReceivableId,
          overrideMaturityAmount: ValorSolicitado,
          overrideDisbursementAmount: ValorDesembolsoSolicitado,
          disbursementDate: DataDesembolso,
          maturityDate: DataVencimento,
        }),
      );

      await validateOperations({
        receivables: payload,
      } as PostAdminOperationsBody);
    }
    validate();
  }, [cardReceivables, validateOperations]);

  const gridRef = useGridRef();

  if (validateHasError) {
    OToastManager.danger(
      "Falha ao executar validações. Tente novamente ou entre em contato com o time de TI",
    );
  }

  const hasInvalid =
    validateResult !== undefined &&
    validateResult.filter((x) => !x.valid).length > 0;

  if (hasInvalid) {
    OToastManager.warning(
      "Atenção! Detectamos alguns pontos inválidos. Analise a tabela abaixo.",
    );
  }

  if (validateResult?.every((x) => x.valid)) {
    OToastManager.success("Operação validada com sucesso!");
  }

  // permite prosseguir se todos estiverem validos ou se apenas a estrategia esta sem desembolso
  const allowNext =
    validateResult !== undefined &&
    (validateResult.every((x) => x.valid) ||
      validateResult
        .filter((x) => !x.valid)
        .every((x) => ALLOW_NEXT.includes(x.type)));

  return validateLoading ? (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>
        Aguarde enquanto validamos a sua requisição. Isso pode demorar alguns
        minutos.
      </OTypography>
      <OLoader size="sm" />
    </div>
  ) : (
    <div className="d-flex flex-column gap-4">
      <GridTemplate gridRef={gridRef} showExportButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={validateGrid}
          dataSource={validateResult}
        />
      </GridTemplate>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <NextStepButton disabled={!allowNext} />
      </div>
    </div>
  );
};
