import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useUploadSacados } from "../../upload-sacados.context";
import { validationResultsGrid } from "./validation-results.grid";
import { addErrorsToSacados } from "./validation-results.utils";

export const ValidationResults = () => {
  const { sacados, validationResults, clear, submit, submitLoading } =
    useUploadSacados();

  const dataSource = useMemo(
    () => addErrorsToSacados(sacados, validationResults),
    [sacados, validationResults],
  );

  const canSubmit = validationResults?.length === 0;

  return (
    <GridTemplate
      actions={
        canSubmit ? (
          <LoadingButton
            dataAction="validacao:botao:importar_sacados"
            dataLabel="importar_sacados"
            loading={submitLoading}
            onClick={() => submit()}
          >
            Importar sacados
          </LoadingButton>
        ) : (
          <OButton
            dataAction="validacao:botao:enviar_outro_arquivo"
            dataLabel="enviar_outro_arquivo"
            onClick={() => clear()}
            type="dark"
            outline
          >
            Enviar outro arquivo
          </OButton>
        )
      }
    >
      <ODataGridGenerator
        grid={validationResultsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
