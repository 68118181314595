import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { OnboardingEmailTemplateFormProps } from "./email-template.form";

export const OnboardingEmailTemplateForm = ({
  form,
}: OnboardingEmailTemplateFormProps) => {
  return (
    <FormProvider {...form}>
      <form>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput tag="text" id="name" name="name" label="Nome" />
          </OCol>
          <OCol xs={12} md={6}>
            <ORFieldInput
              tag="text"
              id="header"
              name="header"
              label="Subtítulo de e-mail"
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              tag="text"
              id="template"
              name="template"
              label="Template do raven"
            />
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              tag="text"
              id="portalUrl"
              name="portalUrl"
              label="URL do portal"
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              tag="text"
              id="messageTemplate"
              name="messageTemplate"
              label="Template da mensagem"
            />
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              tag="text"
              id="mailTo"
              name="mailTo"
              label="OL de contato"
            />
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
