import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services/service";

export const useInvitesMasterDetails = (ravenId: number | null | undefined) => {
  const {
    callService: getNotifications,
    loading,
    hasError,
    value: details,
  } = useServiceCall(service.raven.getNotifications);

  useEffect(() => {
    getNotifications({
      filter: ["id", "=", ravenId],
    });
  }, [getNotifications, ravenId]);

  const value = useMemo(
    () =>
      details?.data[0]
        ? {
            status: details.data[0].status,
            trackerSend: details.data[0].trackerSend,
            trackerDelivery: details.data[0].trackerDelivery,
            trackerOpen: details.data[0].trackerOpen,
            trackerClick: details.data[0].trackerClick,
            error: details.data[0].error,
            trackerBounce: details.data[0].trackerBounce,
          }
        : undefined,
    [details],
  );
  return {
    hasError,
    loading,
    value,
  };
};
