import {
  OCol,
  OField,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OSwitch,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useRegisterNewStatement } from "./register-new-statement.hook";

export const RegisterNewStatement = () => {
  const scheduleTypes = [{name: 'DIÁRIO', type: 'DAILY'}, {name: 'MENSAL', type: 'MONTHLY'}]
  const { active, form, loading, onSubmit, setActive, vans, vanWatch } =
    useRegisterNewStatement();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Cadastrar novo extrato" />}
      actions={
        <LoadingButton
          dataAction="cadastro_extrato:texto:cadastrar_conta"
          dataLabel="cadastrar_conta"
          loading={loading}
          onClick={() => onSubmit()}
        >
          Cadastrar conta
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="cnpj"
                id="cnpj"
                name="cnpj"
                dataAction="cadastro_extrato:texto:email_atual"
                dataLabel="email_atual"
                label="CNPJ da empresa"
                placeholder="ex.: 99.999.999/9999-99"
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="text"
                id="agency"
                name="agency"
                dataAction="cadastro_extrato:texto:numero_agencia"
                dataLabel="numero_agencia"
                label="Agência - sem dígito"
                placeholder="ex.: 50"
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="text"
                id="account"
                name="account"
                dataAction="cadastro_extrato:texto:numero_conta"
                dataLabel="agencia"
                label="Conta - com dígito"
                placeholder="ex.: 999999-9"
              />
            </OCol>
            <OCol xs={12} md={6}>
              <OCol className="gap-1">
                <ORFieldSelect
                  id="van"
                  name="van"
                  label="Meio de transmissão"
                  key={vans.length}
                >
                  {vans.map((van) => {
                    return (
                      <OOption value={van.id} key={van.id}>
                        {van.name === "EMPTY" ? "PORTAL WEB" : van.name}
                      </OOption>
                    );
                  })}
                </ORFieldSelect>
              </OCol>
              <OCol className="justify-content-end">
                <div className="w-95" >
                  {vanWatch && vanWatch !== "0" && (
                    <ORFieldInput
                      tag="text"
                      id="dsname"
                      name="dsname"
                      dataAction="cadastro_extrato:texto:dsname"
                      dataLabel="dsname"
                      label="DSName - obrigatório para cadastro com VAN"
                      placeholder="ex.: DSNAME"
                    />
                  )}
                </div>
              </OCol>
            </OCol>
            <OCol xs={12} md={6}>
              <OField
                htmlFor="active"
                label={`Cadastrar conta com status "ATIVO"`}
              >
                <OSwitch
                  id="active"
                  size="sm"
                  value={active}
                  onInput={() => setActive((oldValue) => !oldValue)}
                />
              </OField>
            </OCol>
            <OCol xs={12} md={6}>
              <OCol className="gap-2">
                <ORFieldSelect
                  id="scheduleType"
                  name="scheduleType"
                  label="Período de geração do arquivo"
                  key={scheduleTypes.length}
                >
                  {scheduleTypes.map((scheduleType) => {
                    return (
                      <OOption value={scheduleType.type} key={scheduleType.type}>
                        {scheduleType.name}
                      </OOption>
                    );
                  })}
                </ORFieldSelect>
              </OCol>
              <OCol className="justify-content-end">
                <div className="w-95" />
                </OCol>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
