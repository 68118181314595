import { OLoader } from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { useWalletGridActions } from "./wallet-grid-actions.hooks";

interface WalletGridActionsProps {
  name: string;
  documentNumber: string;
  personType: number;
  reload: () => void;
  showReprocessBordero: boolean | undefined;
}

export const WalletGridActions = ({
  documentNumber,
  personType,
  reload,
  showReprocessBordero,
}: WalletGridActionsProps) => {
  const { reprocessarBordero, loading } = useWalletGridActions(
    documentNumber,
    personType,
  );

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-paper-plane" },
              label: "Reprocessar bordero",
              onClick: () => {
                reprocessarBordero();
                reload();
              },
              visible: showReprocessBordero,
            },
          ]}
        />
      )}
    </>
  );
};
