import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskDocumentConfigRelationshipForm } from "../_compose/desk-document-config-relationship-form.component";
import {
  DeskDocumentConfigRelationshipFormValues,
  deskDocumentConfigRelationshipFormValidationSchema,
} from "../_compose/desk-document-config-relationship.form";

export const RegisterDeskDocumentConfigRelationshipPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  const form = useForm<DeskDocumentConfigRelationshipFormValues>({
    resolver: yupResolver(deskDocumentConfigRelationshipFormValidationSchema),
    defaultValues: {
      deskOnboardingConfigIdentifier: configIdentifier,
    },
  });
  const { handleSubmit } = form;

  const { callService: registerDeskDocumentConfigRelationship, loading } =
    useServiceCall(
      service.onboarding.registerDeskOnboardingDocumentConfigRelationship,
    );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDeskDocumentConfigRelationship(
          values,
        );

        if (!success)
          return OToastManager.danger("Erro ao criar relacionamento");

        return OToastManager.success("Relacionamento criada com sucesso");
      }),
    [handleSubmit, registerDeskDocumentConfigRelationship],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Adicionar relacionamento documento/configuração" />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskDocumentConfigRelationshipForm />
      </FormProvider>
    </FormTemplate>
  );
};
