import * as yup from "yup";
import { FormValuesType } from "./tco-strategies-form.types";

export const tcoStrategiesDefaultValues = {
  description: "",
  portfolioId: "",
  tipoProduto: "CCC",
  companiesIds: [],
  estrategiasIds: [],
  basicMinValue: 0,
  basicMaxValue: 0,
  basicPercentage: 0,
  intermediateMinValue: 0,
  intermediateMaxValue: 0,
  intermediatePercentage: 0,
  advancedMinValue: 0,
  advancedMaxValue: 0,
  advancedPercentage: 0,
  corporateMinValue: 0,
  corporateMaxValue: 0,
  corporatePercentage: 0,
};

const requiredNumber = yup
  .number()
  .required("Este campo é obrigatório")
  .typeError("O valor deve ser numérico")
  .min(0, "Este valor não pode ser negativo");

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup
  .object()
  .shape({
    description: yup.string().optional().notRequired(),
    portfolioId: yup.string().optional().notRequired(),
    tipoProduto: yup.string().optional().notRequired(),
    companiesIds: yup.array().optional().notRequired(),
    estrategiasIds: yup.array().optional().notRequired(),
    basicMinValue: requiredNumber,
    basicMaxValue: requiredNumber,
    basicPercentage: requiredNumber.max(100, "Valor máximo: 100"),
    intermediateMinValue: requiredNumber,
    intermediateMaxValue: requiredNumber,
    intermediatePercentage: requiredNumber.max(100, "Valor máximo: 100"),
    advancedMinValue: requiredNumber,
    advancedMaxValue: requiredNumber,
    advancedPercentage: requiredNumber.max(100, "Valor máximo: 100"),
    corporateMinValue: requiredNumber,
    corporateMaxValue: requiredNumber,
    corporatePercentage: requiredNumber.max(100, "Valor máximo: 100"),
  });
