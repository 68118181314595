import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import dayjs from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { service } from "services/service";
import { getSearchParams } from "utils/search-params/get-search-params";
import {
  ViewAssetsDetailsForm,
  ViewAssetsDetailsSearchParams,
} from "./view-assets-details.types";
import { units } from "./view-assets-details.utils";
import {
  ViewAssetsDetailsDefaultValues,
  ViewAssetsDetailsSchema,
} from "./view-assets-details.form";

export const useViewAssetsDetails = () => {
  const [bankslip, setBankslip] = useState<BankingHub.Bankslip>();
  const [installment, setInstallment] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [nextBusDay, setNextBusDay] = useState<string>();
  const form = useForm<ViewAssetsDetailsForm>({
    resolver: yupResolver(ViewAssetsDetailsSchema),
    defaultValues: ViewAssetsDetailsDefaultValues,
  });
  const { control, handleSubmit } = form;
  const nominalAmountWatch = useWatch({ control, name: "nominalAmount" });

  const fetchBankslip = useCallback(async () => {
    const { installmentNumber, slipId } =
      getSearchParams<ViewAssetsDetailsSearchParams>();

    if (!slipId || !installmentNumber) {
      return;
    }
    setInstallment(installmentNumber);

    setLoading(true);
    try {
      const { data } = await service.fidc.getV1Bankslip(slipId);

      setBankslip(data.bankslip);
    } catch {
      OToastManager.danger("Erro ao buscar detalhes do boleto");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchNextBusDay = useCallback(async () => {
    const params = {
      referenceDate: dayjs().format("YYYY-MM-DD"),
      units,
    };

    try {
      const { data } = await service.fidc.getV1NextBusDay(params);

      const date = data.result.data;
      setNextBusDay(dayjs(date).format("DD/MM/YYYY"));
    } catch {
      OToastManager.danger(
        "Erro ao buscar data máxima de vencimento do boleto",
      );
    }
  }, []);

  useEffect(() => {
    fetchBankslip();
    fetchNextBusDay();
  }, [fetchBankslip, fetchNextBusDay]);

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);

    const maturityDate = dayjs(values.maturityDate.toString());
    const laterDay = maturityDate.add(1, "day").format("YYYYMMDD");
    const { data } = await service.fidc.getV1NextBusDay({
      referenceDate: maturityDate.format("YYYY-MM-DD"),
      units,
    });
    const rollbackDate = dayjs(data.result.data).format();

    const body = {
      rollbackDate,
      bankslip: {
        bankslipId: bankslip?.bankslipId ?? "",
        nominalAmount: nominalAmountWatch,
        maturityDate: maturityDate.format("YYYYMMDD"),
        interest: {
          code: bankslip?.interest?.code ?? "",
          percentOrAmount: bankslip?.interest?.percentOrAmount ?? 0,
          initialDate: laterDay,
        },
        fine: {
          code: bankslip?.fine?.code ?? "",
          percentOrAmount: bankslip?.fine?.percentOrAmount ?? 0,
          date: laterDay,
        },
      },
    };

    try {
      await service.fidc.putV1Bankslip(body);
    } catch {
      OToastManager.danger("Erro ao tentar editar o boleto.");
    } finally {
      setLoading(false);
    }
  });

  return {
    bankslip,
    form,
    installment,
    loading,
    nextBusDay,
    nominalAmountWatch,
    onSubmit,
  };
};
