import { OToastManager } from "@maestro/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  RegisterNewStatementFormValues,
  registerNewStatementDefaultValues,
  registerNewStatementSchema,
} from "./register-new-statement.form";

export const useRegisterNewStatement = () => {
  const [loading, setLoading] = useState(false);
  const [vans, setVans] = useState<BankingHub.admin.Vans[]>([]);
  const [active, setActive] = useState(true);
  const form = useForm<RegisterNewStatementFormValues>({
    defaultValues: registerNewStatementDefaultValues,
    resolver: yupResolver(registerNewStatementSchema),
  });
  const { handleSubmit, watch } = form;
  const vanWatch = watch("van");

  const fetchVans = useCallback(async () => {
    try {
      const { data } = await service.adminBankinghub.getCnabVans();
      setVans(data);
    } catch {
      OToastManager.danger("Erro ao buscar as VANs");
    }
  }, []);

  useEffect(() => {
    fetchVans();
  }, [fetchVans]);

  const onSubmit = handleSubmit(async (values) => {
    const account = values.account.replace("-", "").slice(0, -1);
    const digit = values.account.replace("-", "").slice(-1);
    const { scheduleDay, scheduleType } = values;

    setLoading(true);
    try {
      const payload = {
        taxId: values.cnpj.replace(/\D/g, ""),
        account,
        digit,
        scheduleDay,
        scheduleType,
        agency: values.agency,
        vanId: values.van,
        status: active ? "ACTIVE" : "INACTIVE",
        DSName: values.dsname?.trim() ?? "",
      };

      await service.adminBankinghub.postCnabAccount(payload);

      OToastManager.success("Conta registrada/atualizada com sucesso!");
    } catch {
      OToastManager.danger(
        "Um erro ocorreu ao cadastrar/atualizar a conta da empresa",
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    active,
    form,
    loading,
    onSubmit,
    setActive,
    vans,
    vanWatch,
  };
};
