import { OField, ORFieldInput, OSwitch } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { useConfigureWorkTimeForm } from "./configuracao-horario-operacao-form.hook";
import { panelTitleMap } from "./configuracao-horario-operacao-form.utls";

interface ConfiguraWorkTimeFormProps {
  operation: BankingHub.admin.Operation;
}

export const ConfigureWorkTimeForm = ({
  operation,
}: ConfiguraWorkTimeFormProps) => {
  const { form, loading, onlyBusinessDay, onSubmit, setOnlyBusinessDay } =
    useConfigureWorkTimeForm(operation);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={getValueFromMap(panelTitleMap, operation.operationType)}
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction={`horario_operacao_limite_mais:texto:salvar_${operation.operationType}`}
          dataLabel={`salvar_${operation.operationType}`}
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <div className="d-flex flex-row gap-5">
            <div className="w-25">
              <OField label="Horário de início" htmlFor="start">
                <ORFieldInput
                  tag="mask"
                  id="start"
                  name="start"
                  dataAction={`horario_operacao_limite_mais:texto:inicio_${operation.operationType}`}
                  dataLabel={`inicio_${operation.operationType}`}
                  maskOption={{ mask: "00:00" }}
                />
              </OField>
            </div>
            <div className="w-25">
              <OField label="Horário de fim" htmlFor="end">
                <ORFieldInput
                  tag="mask"
                  id="end"
                  name="end"
                  dataAction={`horario_operacao_limite_mais:texto:fim_${operation.operationType}`}
                  dataLabel={`fim_${operation.operationType}`}
                  placeholder={operation.end}
                  maskOption={{ mask: "00:00" }}
                />
              </OField>
            </div>
            <div className="d-flex flex-column">
              <OField label="Apenas dias úteis" htmlFor="onlyBusinessDay">
                <OSwitch
                  value={onlyBusinessDay}
                  id="onlyBusinessDay"
                  size="sm"
                  onInput={() => setOnlyBusinessDay((oldValue) => !oldValue)}
                />
              </OField>
            </div>
          </div>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
