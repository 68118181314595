import { OCol, ORow, OToastManager } from "@maestro/react";
import {
  SelectSearchMultiple,
  SelectSearchMultipleOption,
} from "components/form/select-search-multiple";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface DevelopersWebhookStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const DevelopersWebhookStep = ({
  fieldName,
  index,
}: DevelopersWebhookStepProps) => {
  const [partnerOptions, setPartnerOptions] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const { getValues, setValue } = useFormContext();

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await service.scfDevelopers.fetchPartnersList();
      setPartnerOptions(
        data.map<SelectSearchMultipleOption<string>>((partner) => ({
          label: partner.name,
          value: partner.authClientId,
        })) ?? [],
      );
    } catch {
      OToastManager.danger("Erro ao obter parceiros");
    }
  }, []);

  useEffect(() => {
    setValue(
      `${fieldName}.${index}._t`,
      "WebhookViaDevelopersPartnerConfigurationStep",
    );
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(`${fieldName}.${index}.stepId`, `webhook-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="DEVELOPERS_WEBHOOK"
        gridSize={4}
      />
      <OCol xs={4}>
        <SelectSearchMultiple
          label="Webhook para Parceiros adicionais"
          id={`${fieldName}.${index}.additionalPartnerAuthClientIds`}
          name={`${fieldName}.${index}.additionalPartnerAuthClientIds`}
          placeholder="Selecione os parceiros"
          options={partnerOptions}
          valuesLabel="Parceiros selecionados:"
          maxOptions={50}
        />
      </OCol>
    </ORow>
  );
};
