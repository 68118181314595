import { modalManager } from "@maestro/core";
import { OConfirmationModal, OLoader, OTypography } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import {
  RemoveImportDataConfirmationModal,
  removeImportDataConfirmationModalId,
} from "./_compose";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem;
  reload: () => void;
}

export const GridActions = ({ importData, reload }: GridActionsProps) => {
  const { deleteImportData, executeImport, reprocessImportData, loading } =
    useGridActions(importData, reload);

  const executeImportConfirmationModalId = `execute-import-confirmation-modal-id-${importData.id}`;

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              disabled:
                importData.status === "IMPORTADO" ||
                importData.status === "PROCESSANDO",
              onClick: () =>
                modalManager.show(
                  removeImportDataConfirmationModalId(importData.id),
                ),
            },
            {
              icon: { category: "orq", icon: "orq-swap-circle" },
              label: "Reprocessar",
              disabled:
                importData.status === "IMPORTADO" ||
                importData.status === "PROCESSANDO",
              onClick: () => reprocessImportData(),
            },
            {
              icon: { category: "orq", icon: "orq-play" },
              label: "Executar importação",
              disabled: importData.status !== "PENDENTE_CONFIRMACAO",
              onClick: () =>
                modalManager.show(executeImportConfirmationModalId),
            },
          ]}
        />
      )}
      <RemoveImportDataConfirmationModal
        importData={importData}
        onConfirm={deleteImportData}
      />
      <OConfirmationModal
        modalId={executeImportConfirmationModalId}
        title="Executar importação"
        divider={false}
        onConfirm={() => {
          executeImport();
          modalManager.hide(executeImportConfirmationModalId);
        }}
        className="position-absolute"
      >
        <OTypography>Tem certeza que deseja executar a importação?</OTypography>
      </OConfirmationModal>
    </>
  );
};
