import { DetailsFields } from "components/details-card";
import { GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse } from "services/quickfin/models";
import { OperationCalculatedValues } from "./fidc-operations-details.utils";

export const FidcOperationsDetailsFields = (
  operation:
    | GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse
    | undefined,
  calculatedValues: OperationCalculatedValues,
): DetailsFields => ({
  details: {
    title: "Detalhes desembolsado",
    items: [
      {
        label: "Código",
        value: operation?.id,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Prazo médio",
        value: `${calculatedValues.meanTerm.toFixed(2)} dias`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "taxa média",
        value: `${calculatedValues.meanMonthlyTax.toFixed(4)}% a.m.`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Taxa total",
        value: `${calculatedValues.totalTax.toFixed(2)}%`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  account: {
    title: "Dados de desembolso",
    items: [
      {
        label: "Conta desembolso",
        value: `${operation?.bankAccount.accountNumber} - ${operation?.bankAccount.accountDigit}`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Agência",
        value: operation?.bankAccount.agency,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Banco",
        value: operation?.bankAccount.bankCode,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
