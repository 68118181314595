import { OToastManager } from "@maestro/core";
import { OCol, ORFieldInput } from "@maestro/react";
import { FormTemplate } from "templates/form-template";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { bigNumberFormDefaultValues } from "./big-number-form.schema";
import { orqIcons } from "../workflow-configuration.utils";
import { SelectSearchField } from "components/select-search";

type BigNumbersFormProps =
  | {
      mode: "add";
    }
  | {
      mode: "edit";
      id: string;
    };

export const BigNumbersForm = (props: BigNumbersFormProps) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { handleSubmit } = useFormContext<typeof bigNumberFormDefaultValues>();

  const createBigNumber = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const { mode } = props;

          if (mode === "add") {
            await service.hubCreditManager.createBigNumbers(values);
          } else {
            await service.hubCreditManager.updateBigNumbers({
              ...values,
              id: Number(props.id),
            });
          }
          OToastManager.success("Big Number salvo com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.configurations.bigNumbers
              .path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar o big number.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, props],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton loading={loading} type="info" onClick={createBigNumber}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <div className="alig-items-start justify-content-between">
          <OCol sm={8}>
            <ORFieldInput
              id="name"
              name="name"
              tag="text"
              label="Título"
              labelSize="md"
              dataAction="adicionar_big_number:texto:nome"
              dataLabel="nome"
            />
          </OCol>
          <OCol sm={8}>
            <SelectSearchField
              id="iconName"
              name="iconName"
              label="Nome do ícone"
              labelSize="md"
              options={orqIcons.map((icon) => ({
                label: icon,
                value: icon,
              }))}
            />
          </OCol>
          <OCol sm={8}>
            <ORFieldInput
              id="description"
              name="description"
              tag="text"
              label="Descrição da etapa"
              labelSize="md"
              dataAction="adicionar_big_number:texto:descricao"
              dataLabel="descricao"
            />
          </OCol>
          <OCol sm={8}>
            <ORFieldInput
              id="nameId"
              name="nameId"
              tag="text"
              label="Identificador da etapa"
              labelSize="md"
              dataAction="adicionar_big_number:texto:name_id"
              dataLabel="name_id"
            />
          </OCol>
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
