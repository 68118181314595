import { AxiosResponse, isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { logger } from "utils/logger";

type ServiceMethod<T extends unknown[], U> = (
  ...args: T
) => Promise<AxiosResponse<U>>;

export const useServiceCall = <T extends unknown[], U>(
  serviceMethod: ServiceMethod<T, U>,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [value, setValue] = useState<U>();

  const callService = useCallback(
    async (...args: T) => {
      try {
        setError(undefined);
        setLoading(true);

        const response = await serviceMethod(...args);

        setValue(response.data);

        return {
          success: true,
          response,
        } as const;
      } catch (err) {
        if (!isAxiosError(err)) logger.error(err);
        setError(err);

        return {
          success: false,
          error: err,
        } as const;
      } finally {
        setLoading(false);
      }
    },
    [serviceMethod],
  );

  const clear = useCallback(() => {
    setLoading(false);
    setError(undefined);
    setValue(undefined);
  }, []);

  return {
    loading,
    error,
    hasError: !!error,
    value,
    callService,
    clear,
  };
};
