import { OCol, ORow } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useOffers } from "./offers.hook";
import { Products } from "./compose/products/product.component";

export const OffersPage = () => {
  const { availableProducts, loading } = useOffers();

  return (
    <div>
      <div className="position-relative">
        {!loading && !!availableProducts.length && (
          <>
            <PageTitle
              title="Ofertas"
              description="Abaixo estão as ofertas aprovadas para o cliente. Selecione para
          visualizar as condições"
            />
            <div className="d-flex flex-column gap-3">
              <ORow>
                <OCol className="gap-4">
                  <Products availableProducts={availableProducts} />
                </OCol>
              </ORow>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
