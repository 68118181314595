import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { customer } from "contexts/customer";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { copyToClipboard } from "utils/copy";
import { EdmLiteral } from "devextreme/data/odata/utils";
import { buildTaggedLink } from "../../../utils/build-link";

export const operatorsODataGrid =
  (): ODataGridGeneratorConfig<HubPartners.Odata.OperatorProfile> => ({
    datagrid: {
      noDataText: "Nenhum operador encontrado",
      filterRow: { visible: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar o operador.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubPartners.admin.odata.adminGetODataOperators,
        {
          dataSourceOptions: {
            sort: [
              {
                desc: true,
                selector: "createdDate",
              },
            ],
          },
          odataStoreOptions: {
            beforeSend: ({ params }) => {
              // eslint-disable-next-line no-param-reassign
              params.originatorIdentification = customer.value?.identification;
            },
          },
        },
      ),
    },
    columns: [
      {
        caption: "Detalhes",
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.parceiros.customer.operators.details.path(
              {
                operatorIdentification: data.operatorIdentification ?? "",
                operatorProfileIdentifier: data.identifier,
              },
            )}
          />
        ),
        role: roles.parceiros.customer.operatorDetailsPage.role,
        allowExporting: false,
      },
      {
        dataField: "operatorName",
        caption: "Operador",
        width: 240,
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.operatorName?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.operatorIdentification)}
            </OTypography>
          </div>
        ),
        calculateFilterExpression: (filterValue) => {
          if (filterValue.replace(/\D/g, "") === "") {
            return ["operatorName", "contains", filterValue];
          }
          return [
            ["operatorName", "contains", filterValue],
            "or",
            [
              "operatorIdentification",
              "contains",
              filterValue.replace(/\D/g, ""),
            ],
          ];
        },
      },
      {
        dataField: "email",
        caption: "Email",
      },
      {
        dataField: "partnershipType",
        caption: "Tipo de Parceria",
      },
      {
        dataField: "mainOriginatorName",
        caption: "Instituição Responsável",
        width: 240,
      },
      {
        dataField: "relationshipFilters",
        caption: "Filtros de Relacionamentos",
        filterOperations: ["contains", "notcontains"],
        allowSorting: false,
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          let expression = "";

          if (selectedFilterOperation === "notcontains") {
            expression = `RelationshipFilters/any(relationshipFilter:not(contains(tolower(relationshipFilter), '${filterValue.toLowerCase()}')))`;
          } else {
            expression = `RelationshipFilters/any(relationshipFilter:contains(tolower(relationshipFilter), '${filterValue.toLowerCase()}'))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        width: 200,
      },
      {
        dataField: "isActive",
        caption: "Operador ativo",
        dataType: "boolean",
      },
      {
        dataField: "tagCode",
        caption: "Link tagueado",
        calculateFilterExpression: (filterValue) => [
          "tagCode",
          "contains",
          filterValue,
        ],
      },
      {
        dataField: "identifier",
        caption: "Identificador externo",
      },
      {
        dataField: "operatorCge",
        caption: "Código CGE",
      },
      {
        dataField: "createdDate",
        caption: "Data de criação",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        width: 160,
      },
      {
        dataField: "updatedDate",
        caption: "Data de atualização",
      },
      {
        dataField: "deactivatedAt",
        caption: "Data de desativação",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: {
                  category: "orq",
                  icon: "orq-link",
                },
                label: "Copiar link tagueado",
                onClick: () => {
                  copyToClipboard(buildTaggedLink(data.tagCode));
                  OToastManager.success("Link tagueado copiado com sucesso");
                },
              },
            ]}
          />
        ),
      },
    ],
  });
