import { OIcon, OInputCurrency } from "@maestro/react";
import { UnstyledButton } from "components/unstyled-button";
import { CardReceivable } from "../../../../intubate-operations.types";
import { useRequestedAmountCell } from "./requested-amount-cell.hook";

interface RequestedAmountCellProps {
  data: CardReceivable;
}

export const RequestedAmountCell = ({ data }: RequestedAmountCellProps) => {
  const { disabled, edit, hasError, innerValue, save, setInnerValue } =
    useRequestedAmountCell(data);

  return (
    <div className="d-flex align-items-center gap-2">
      <OInputCurrency
        dataAction={`entuba_lista:texto:valor_solicitado_${data.ReceivableId}`}
        dataLabel={`valor_solicitado_${data.ReceivableId}`}
        id={`requested-amount-${data.ReceivableId}`}
        name={`requested-amount-${data.ReceivableId}`}
        symbol="R$"
        value={innerValue}
        disabled={disabled}
        error={hasError}
        onInput={(evt) => setInnerValue(evt.currentTarget.value ?? 0)}
      />
      <UnstyledButton
        aria-label={disabled ? "edit" : "save"}
        onClick={disabled ? edit : save}
      >
        {disabled ? (
          <OIcon category="fas" icon="fa-pen" type="dark" size="sm" />
        ) : (
          <OIcon category="fas" icon="fa-save" type="dark" size="sm" />
        )}
      </UnstyledButton>
    </div>
  );
};
