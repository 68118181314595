import { OToastManager } from "@maestro/core";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GetAmbevNotificationsResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { notificationsTableGrid } from "./notifications-table.grid";

export const NotificationsTable = () => {
  const dataSource = dataSourceCustomStoreGenerator<
    GetAmbevNotificationsResponse[number]
  >(() => {
    return service.fidc
      .getDonusNotification()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar as notificações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Notificações enviadas do fundo DONUS FUNDO DE INVESTIMENTO EM DIREITOS CREDITÓRIOS" />
      }
    >
      <ODataGridGenerator
        grid={notificationsTableGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
