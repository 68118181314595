import { ODropdown } from "@maestro/react";
import styled from "styled-components";

export const FullWidthDropdown = styled(ODropdown)`
  width: 100%;

  .o-floater__floating-element {
    min-width: 100%;
  }

  o-dropdown-item {
    display: flex;
    justify-content: space-between;

    &.selected {
      background-color: var(--theme-info);
      color: var(--theme-info-inverse);

      &:hover {
        background-color: var(--theme-info-80);
      }

      &::after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        display: block;
      }
    }
  }
`;
