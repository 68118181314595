import { OToastManager } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { OTruncateTypography } from "components/o-truncate-typography";
import { copyToClipboard } from "utils/copy";
import {
  assembleDaysToCancelResume,
  formatPercentOrAmount,
} from "./collections-agreement.utils";

export const grid: (
  handleDelete: (agreement: BankingHubAdminCollection.Agreement) => void,
  handleUpdate: (agreement: BankingHubAdminCollection.Agreement) => void,
) => ODataGridGeneratorConfig<BankingHubAdminCollection.Agreement> = (
  handleDelete,
  handleUpdate,
) => ({
  datagrid: { noDataText: "Nenhum convênio cadastrado" },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      width: "20%",
      allowSorting: false,
      alignment: "left",
    },
    {
      dataField: "name",
      caption: "Nome",
      width: "20%",
      cellRender: ({ data: { name = "-" } }) => (
        <OTruncateTypography title={name}>{name}</OTruncateTypography>
      ),
      allowSorting: false,
      alignment: "left",
    },
    {
      dataField: "settings.daysToCancel",
      caption: "Prazo para cancelamento automático",
      cellRender: ({ data }) => assembleDaysToCancelResume(data),
      width: "20%",
      alignment: "left",
    },
    {
      dataField: "settings.interest.percentOrAmount",
      caption: "Juros",
      cellRender: ({
        data: {
          settings: { interest },
        },
      }) =>
        formatPercentOrAmount(
          "Sem juros",
          interest?.percentOrAmount,
          interest?.type,
        ),
      width: "15%",
      alignment: "left",
    },
    {
      dataField: "settings.fine.percentOrAmount",
      caption: "Multa",
      cellRender: ({
        data: {
          settings: { fine },
        },
      }) =>
        formatPercentOrAmount("Sem multa", fine?.percentOrAmount, fine?.type),
      width: "15%",
      alignment: "left",
    },
    {
      dataField: "settings.discount.percentOrAmount",
      caption: "Desconto",
      cellRender: ({
        data: {
          settings: { discount },
        },
      }) =>
        formatPercentOrAmount(
          "Sem desconto",
          discount?.percentOrAmount,
          discount?.type,
        ),
      width: "15%",
      alignment: "left",
    },
    {
      caption: "Ações",
      alignment: "center",
      width: "85px",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-copy" },
                label: "Copiar ID",
                onClick: () => {
                  copyToClipboard(data.id);
                  OToastManager.success("Id do convênio copiado com sucesso");
                },
              },
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar convênio",
                onClick: () => handleUpdate(data),
              },
              {
                icon: { category: "orq", icon: "orq-edit-trash" },
                label: "Excluir convênio",
                onClick: () => handleDelete(data),
              },
            ]}
          />
        );
      },
    },
  ],
});
