import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import "leaflet/dist/leaflet.css";

import dayjs from "dayjs";
import { MapContainer, TileLayer } from "react-leaflet";
import { ActiveDevice, StatusType } from "../../atividades-login.types";
import "./leaflet-styling.css";

interface ActivityDetailsProps {
  device?: ActiveDevice;
  blockDevice?: () => Promise<void>;
}

export const ActivityDetails = ({
  blockDevice,
  device,
}: ActivityDetailsProps) => {
  const latitude = device?.latitude || 0;
  const longitude = device?.longitude || 0;

  return (
    <OModal
      id={`activity-details-modal-${device?.sessionTokenId}`}
      position="center"
      rounded
      size="sm"
    >
      <OModalHeader closeButton>
        <OTypography size="xl">Detalhes da atividade</OTypography>
      </OModalHeader>
      <OModalBody style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            height: "300px",
            width: "100%",
            marginBottom: "25px",
            boxShadow: "0.5px 0.5px 5px 1px rgba(0,0,0,.3)",
          }}
        >
          <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </div>
        <div>
          <ORow>
            <OCol lg={6} xs={12}>
              <div className="d-flex flex-column gap-1 mb-3 justify-content-start align-items-start">
                <OTypography type="default" size="sm">
                  Última conexão
                </OTypography>
                <OTypography tag="span">
                  {device?.insertedAt
                    ? dayjs(device.insertedAt + "Z").format("DD/MM/YYYY HH:mm")
                    : ""}
                </OTypography>
              </div>
            </OCol>
            <OCol lg={6} xs={12}>
              <div className="d-flex flex-column gap-1 mb-3 justify-content-start align-items-start">
                <OTypography type="default" size="sm">
                  Local
                </OTypography>
                <OTypography tag="span">
                  {[device?.city, device?.country].join(", ")}
                </OTypography>
              </div>
            </OCol>
            <OCol lg={6} xs={12}>
              <div className="d-flex flex-column gap-1 mb-3 justify-content-start align-items-start">
                <OTypography type="default" size="sm">
                  Dispositivo
                </OTypography>
                <OTypography id="device" tag="span">
                  {device?.device ?? ""}
                </OTypography>
              </div>
            </OCol>
            <OCol lg={6} xs={12}>
              <div className="d-flex flex-column gap-1 mb-3 justify-content-start align-items-start">
                <OTypography type="default" size="sm">
                  Informações adicionais
                </OTypography>
                <OTypography id="extra-info" tag="span">
                  {device?.browser ?? ""}
                </OTypography>
              </div>
            </OCol>
          </ORow>
        </div>
      </OModalBody>
      <OModalFooter>
        {device?.status !== StatusType.ExpiredSession && (
          <OButton
            type="tertiary"
            dataAction=""
            dataLabel=""
            typeButton="button"
            onClick={blockDevice}
          >
            Bloquear dispositivo
          </OButton>
        )}
      </OModalFooter>
    </OModal>
  );
};
