import { modalManager } from "@maestro/core";
import { OBadge } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { getValueFromMap } from "utils/get-value-from-map";
import { RelatoriosEmLoteAdminODataValueItem } from "services/quickfin/models";
import { reportTypeOptions, statusOptions } from "./reports-requested.utils";

export const buildGrid = (
  downloadReport: (id: number, name: string) => void,
): ODataGridGeneratorConfig<RelatoriosEmLoteAdminODataValueItem> => ({
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Criação",
      dataField: "CreatedAt",
      dataType: "date",
      format: "shortDate",
      sortOrder: "desc",
    },
    {
      caption: "Expira em",
      dataField: "ExpiresAt",
      dataType: "date",
      format: "shortDate",
    },
    {
      caption: "Nome",
      dataField: "FileName",
    },
    {
      dataField: "TipoDocumento",
      caption: "Tipo",
      cellRender: ({ data }) =>
        getValueFromMap(reportTypeOptions, data.TipoDocumento),
      lookup: {
        dataSource: Object.keys(reportTypeOptions).map((key) => ({
          key,
          desc: reportTypeOptions[key],
        })),
        valueExpr: "key",
        displayExpr: "desc",
      },
    },
    {
      caption: "Valor",
      dataField: "TotalAmount",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "Status",
      caption: "Status",
      cellRender: ({ data }) => {
        const mappedStatus = getValueFromMap(statusOptions, data.Status) ?? {
          label: "UNDEFINED",
          type: "default",
        };
        return <OBadge type={mappedStatus?.type}>{mappedStatus?.label}</OBadge>;
      },
      lookup: {
        dataSource: Object.keys(statusOptions).map((key) => ({
          key,
          desc: statusOptions[key].label,
        })),
        valueExpr: "key",
        displayExpr: "desc",
      },
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data }) => {
        const showData = ["Requested", "Processing"].includes(data.Status);
        const errorStatus = ["NoContent", "Error"].includes(data.Status);

        if (showData) return;

        return errorStatus ? (
          <IconButton
            dataAction="relatorios_solicitados:botao:informacao"
            dataLabel="informacao"
            icon={{ category: "orq", icon: "orq-info" }}
            outline
            onClick={() => modalManager.show("error-report-modal")}
          >
            Informação
          </IconButton>
        ) : (
          <IconButton
            dataAction="relatorios_solicitados:botao:download"
            dataLabel="download"
            icon={{ category: "orq", icon: "orq-download" }}
            outline
            onClick={() => downloadReport(data.Id, data.FileName)}
          >
            Download
          </IconButton>
        );
      },
    },
  ],
});
