import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeGenericFieldsForm } from "../../../../../../components/nature/generic-fields/generic-fields-form.component";
import {
  ExchangeGenericFieldsFormValues,
  exchangeGenericFieldsFormValidationFormSchema,
} from "../../../../../../components/nature/generic-fields/generic-fields-form.schemas";

export const ExchangeProductCreateGenericFields = () => {
  const form = useForm<ExchangeGenericFieldsFormValues>({
    resolver: yupResolver(exchangeGenericFieldsFormValidationFormSchema),
  });

  const navigate = useNavigate();

  const { handleSubmit } = form;

  const { callService: createNatureGenericFieldTypes, loading } =
    useServiceCall(service.hubFx.nature.createNatureGenericFieldTypes);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createNatureGenericFieldTypes(values);
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success("Campo genérico adicionado com sucesso");

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .genericFields.path,
        );
      }),
    [createNatureGenericFieldTypes, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Campo Genérico" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          dataAction="adicionar"
          dataLabel="adicionar_campo_generico:botao:adicionar"
        >
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ExchangeGenericFieldsForm />
      </FormProvider>
    </FormTemplate>
  );
};
