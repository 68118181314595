import { SelectSearchFieldV2 } from "../../../../../../../components/select-search-v2";
import { pendencyStatusMap } from "../../../../../../../utils";

interface PendencyStatusSelectorProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  dropdownPosition?: "top" | "bottom";
}

export const PendencyStatusSelector = ({
  label,
  name,
  multiple,
  placeholder,
  required,
  disabled,
  dropdownPosition,
}: PendencyStatusSelectorProps) => {
  return (
    <SelectSearchFieldV2
      options={Object.entries(pendencyStatusMap).map(([type, { text }]) => ({
        content: text,
        value: type,
      }))}
      id={name}
      name={name}
      placeholder={placeholder}
      label={label}
      required={required}
      disabled={disabled}
      multiple={multiple}
      dropdownPosition={dropdownPosition}
    />
  );
};
