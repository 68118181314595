import { ORFieldUploadInput, OToastManager } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { getFormData } from "utils/file/file.utils";
import { getComponentPropertyFieldId } from "../component-property-field.utils";

interface FilePropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  formStepCardComponentId: number;
}

export const FilePropertyField = ({
  componentProperty,
  formStepCardComponentId,
}: FilePropertyFieldProps) => {
  const { setValue } = useFormContext();

  const property = getComponentPropertyFieldId(
    componentProperty.name,
    componentProperty.id,
  );

  const handleSubmitFile = useCallback(
    async (file: File, propertyId: number, resolve: (file: File) => void) => {
      try {
        const formData = await getFormData(file, "");

        const { data } = await service.hubCreditManager.upload(formData);

        await service.hubCreditManager.updateComponentConfigProperty({
          formStepCardComponentId,
          value: data,
          propertyId,
        });

        resolve(file);
      } catch {
        OToastManager.danger(
          "Não foi possível enviar o arquivo. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [formStepCardComponentId],
  );

  const handleRemoveFile = useCallback(
    async (file: File, propertyId: number, resolve: (file: File) => void) => {
      try {
        await service.hubCreditManager.updateComponentConfigProperty({
          formStepCardComponentId,
          value: "",
          propertyId,
        });
        resolve(file);
      } catch {
        OToastManager.danger(
          "Não foi possível remover o arquivo. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [formStepCardComponentId],
  );

  const getInitialFile = useCallback(async () => {
    try {
      if (!componentProperty.value) {
        setValue(property, "");
        return;
      }

      const { data: blob } = await service.hubCreditManager.download({
        key: componentProperty.value,
      });

      const splittedKey = componentProperty.value?.split("/");

      setValue(property, [new File([blob], splittedKey?.at(-1) ?? "")]);
    } catch {
      OToastManager.danger("Um erro ocorreu ao buscar pelo arquivo.");
    }
  }, [setValue, componentProperty, property]);

  useEffect(() => {
    if (componentProperty.value === undefined) return;

    getInitialFile();
  }, [getInitialFile, componentProperty, property]);

  const filePropertyField = useMemo(() => {
    return (
      <ORFieldUploadInput
        key={componentProperty.name + componentProperty.id}
        id={property}
        name={property}
        label={componentProperty.description}
        inputLabel="Selecionar documento"
        required={componentProperty.isRequired}
        accept={[
          ".xls",
          ".xlsx",
          ".csv",
          ".jpg",
          ".jpeg",
          ".bmp",
          ".pdf",
          ".png",
          ".doc",
          ".docx",
        ].join(",")}
        multiple={false}
        handleAddFile={(e, resolve) =>
          handleSubmitFile(e.detail, componentProperty.id, resolve)
        }
        handleDownload={async (file) => {
          const base64 = await helpers.convertFileToBase64(file);
          helpers.downloadBase64(base64, file.name);
        }}
        handleRemove={(file, resolve) => {
          handleRemoveFile(file, componentProperty.id, resolve);
        }}
      />
    );
  }, [componentProperty, handleSubmitFile, property, handleRemoveFile]);

  return <>{filePropertyField}</>;
};
