import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { isAxiosError } from "axios";
import { service } from "services";
import { logger } from "utils/logger";

export const downloadRegister = async (key: string) => {
  try {
    const { data } = await service.recebiveisCartao.downloadRegister({
      s3_key: key,
    });

    helpers.downloadBlobFile(key, data);
  } catch (err) {
    if (!isAxiosError(err)) logger.error(err);
    OToastManager.danger("Não foi possível fazer o download");
  }
};
