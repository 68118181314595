import { OTypography } from "@maestro/react";
import { useMemo } from "react";

interface InfoCardProps {
  header: string;
  body: React.ReactNode;
  footer?: string;
}

export const InfoCardContent = ({ header, body, footer }: InfoCardProps) => {
  const bodyElement = useMemo(
    () =>
      typeof body === "string" ? (
        <OTypography size="xxxl" type="dark" weight="700">
          {body}
        </OTypography>
      ) : (
        body
      ),
    [body],
  );

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="d-flex justify-content-start mb-3">
        <OTypography size="md" type="dark">
          {header}
        </OTypography>
      </div>
      <div className="d-flex justify-content-center">{bodyElement}</div>
      {footer && (
        <div className="d-flex justify-content-end mt-3">
          <OTypography size="sm" type="dark">
            {footer}
          </OTypography>
        </div>
      )}
    </div>
  );
};
