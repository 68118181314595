import * as yup from "yup";

export const channelFormValidationSchema = yup.object().shape({
  cardActionButtonDescription: yup
    .string()
    .trim()
    .required("Campo obrigatório"),
  icon: yup.string(),
  showOnActions: yup.array().default([]),
  showOnTabs: yup.array().default([]),
  showOnCards: yup.array().default([]),
  isWfEntry: yup.array().default([]),
  entryViews: yup.array().default([]),
  entryChannels: yup.array().default([]),
});

export const channelFormDefaultValues = {
  cardActionButtonDescription: "",
  icon: "",
  showOnActions: [] as string[],
  showOnTabs: [] as string[],
  showOnCards: [] as string[],
  isWfEntry: [] as string[],
  entryViews: [] as string[],
  entryChannels: [] as string[],
};
