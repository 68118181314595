import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { UserNotification } from "./notifications.types";

interface NotificationsProps {
  notificationType: "Email" | "Push" | "All";
  grid: ODataGridGeneratorConfig<UserNotification>;
  receiverEmail: string;
  title: string;
}

const dataSourceGenerator = (
  receiverEmail: string,
  notificationType: "Email" | "Push" | "All",
) =>
  dataSourceCustomStoreGenerator<UserNotification>(
    (loadOptions) => {
      return service.raven
        .getNotifications(loadOptions as any)
        .then(({ data }) => ({
          data: data.data ?? [],
          totalCount: data.total_count,
        }))
        .catch(() => {
          const errorMessage = "Erro ao buscar notificações";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    {
      dataSourceOptions: {
        filter:
          notificationType === "All"
            ? ["receiver", "=", receiverEmail]
            : [
                ["receiver", "=", receiverEmail],
                "and",
                ["type", "=", notificationType],
              ],
        sort: [
          {
            desc: true,
            selector: "insertedAt",
          },
        ],
      },
    },
  );

export const NotificationsComponent = ({
  notificationType,
  grid,
  receiverEmail,
  title,
}: NotificationsProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(
    () => dataSourceGenerator(receiverEmail, notificationType),
    [receiverEmail, notificationType],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title={title} />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
