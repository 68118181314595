import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { logger } from "utils/logger";
import { IntubateType } from "./intubate-generic.types";
import { intubateTypeMap } from "./intubate-generic.utils";

export const useIntubateGeneric = (type: IntubateType) => {
  const [hasParseError, setHasParseError] = useState(false);

  const {
    callService,
    hasError: apiError,
    loading: apiLoading,
    value: apiResponse,
    // using `any` because I couldn't match the types
  } = useServiceCall<any, unknown>(intubateTypeMap[type].service);

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset,
  } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        const parsed = intubateTypeMap[type].parser(result);

        callService(parsed);
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    }
  }, [callService, readerError, result, type]);

  useEffect(() => {
    if (apiError) {
      OToastManager.danger("Erro ao enviar o arquivo");
      reset();
    }
  }, [apiError, reset]);

  useEffect(() => {
    if (apiResponse) {
      OToastManager.success("Arquivo enviado com sucesso");
      reset();
    }
  }, [apiResponse, reset]);

  return {
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    reset,
    setHasParseError,
  };
};
