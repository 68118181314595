import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { readinessStatusDetailsGenerator } from "./readiness-status.details";

interface ReadinessStatusProps {
  taxId: string;
}

export const ReadinessStatus = ({ taxId }: ReadinessStatusProps) => {
  const {
    callService: getReadinessStatus,
    value: readinessStatus,
    hasError: hasErrorReadinessStatus,
    loading: loadingReadinessStatus,
  } = useServiceCall(service.hubLoan.getReadinessStatus);

  useEffect(() => {
    getReadinessStatus(taxId);
  }, [getReadinessStatus, taxId]);

  return (
    <DetailsCard
      loading={loadingReadinessStatus}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível carregar os status operacionais."
          messageParagraph="Tente novamente mais tarde."
        >
          <TryAgainButton onClick={() => getReadinessStatus(taxId)} />
        </ErrorComponent>
      }
      hasError={hasErrorReadinessStatus}
      value={readinessStatus}
      fields={readinessStatusDetailsGenerator}
    />
  );
};
