import { OToastManager } from "@maestro/react";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CreateManualNotificationBody } from "services/shark/models/requests";
import { getFormData } from "utils/file/file.utils";
import { DisparoManualFormValues } from "./cobrancas-disparo-manual-form.schemas";
import { UploadedFile } from "./cobrancas-disparo-manual.types";

export const useDisparoManual = () => {
  const [loading, setLoading] = useState(false);

  const [attachmentFiles, setAttachmentFiles] = useState<Array<UploadedFile>>(
    [],
  );
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);

  const { handleSubmit, setValue } = useFormContext<DisparoManualFormValues>();

  const navigate = useNavigate();

  const templateOptions = useMemo(
    () => ["cobranca_padrao", "cobranca_antecipacao", "cobranca_senior"],
    [],
  );

  const transformData = useCallback(
    (data: DisparoManualFormValues): CreateManualNotificationBody => {
      return {
        installment: data.installment.toString(),
        operation_ids: data.operationId.split(","),
        tax_id: data.taxId,
        notification_messages: [
          {
            content: data.content,
            metadata: data.metadata ? JSON.parse(data.metadata) : null,
            notification_attachments: attachmentFiles.map((file) => {
              if (!file.url || !file.uploadedFilename) throw new Error();

              return {
                url: file.url,
                name: file.uploadedFilename,
              };
            }),
            notification_users: [
              {
                name: data.userName,
                receiver: data.userReceiver,
              },
            ],
            sub_title: data.subtitle,
            title: data.title,
            template: data.template,
            type: "Email",
          },
        ],
      };
    },
    [attachmentFiles],
  );

  const handleAddFile = async (event: CustomEvent) => {
    try {
      setLoading(true);
      OToastManager.info("Inserindo arquivo...");

      const uploadedFile: UploadedFile = {
        file: event.detail,
      };

      const formData = await getFormData(uploadedFile.file);

      const { data } = await service.shark.uploadAttachment(formData);

      setFilesToUpload((files: Array<File>) => {
        return [...files, uploadedFile.file];
      });
      setAttachmentFiles((files: Array<UploadedFile>) => {
        return [
          ...files,
          { ...uploadedFile, url: data.url, uploadedFilename: data.name },
        ];
      });

      OToastManager.destroy();
      OToastManager.success("Arquivo inserido com sucesso!");
    } catch {
      OToastManager.destroy();
      OToastManager.danger(
        "Não foi possível inserir arquivo, tente novamente.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFile = async (file: File) => {
    const filteredFiles = filesToUpload.filter(
      (item) =>
        item.name !== file.name && item.lastModified !== file.lastModified,
    );
    setFilesToUpload(filteredFiles);

    setAttachmentFiles((files: Array<UploadedFile>) =>
      files.filter((item) => filteredFiles.includes(item.file)),
    );

    OToastManager.success("Arquivo removido com sucesso!");
  };

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data: DisparoManualFormValues) => {
        try {
          setLoading(true);

          const requestData = transformData(data);
          await service.shark.createNotificationOperation(requestData);

          OToastManager.success("Estímulo enviado com sucesso!");
          navigate(corporateRouter.routes.cobrancas.product.estimulos.path);
        } catch {
          OToastManager.danger("Houve um problema ao enviar o estímulo");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, transformData],
  );

  return {
    loading,
    setLoading,
    setValue,
    filesToUpload,
    handleAddFile,
    handleRemoveFile,
    onSubmit,
    templateOptions,
  };
};
