import { useResolvedPath } from "react-router-dom";
import { setInitialParams } from "./use-link-new-tab-handler.utils";

/**
 * Handles middle click mouse events to open a new tab with
 * initialization search params already set
 * @param href - The desired destination
 * @returns - Event handler for `auxclick`
 */
export const useLinkNewTabHandler = (href: string) => {
  const path = useResolvedPath(href);

  const linkNewTabHandler = (evt: React.MouseEvent) => {
    // only change behavior of opening link in new tab:
    // if the click was with the middle button (1) or
    // if the click was with the main button (0) while CTRL was pressed
    if (evt.button === 1 || (evt.ctrlKey && evt.button === 0)) {
      evt.preventDefault();

      const searchParams = setInitialParams(path.search);

      const url = path.pathname + "?" + searchParams.toString();
      window.open(url, "_blank");
    }
  };

  return linkNewTabHandler;
};
