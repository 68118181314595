import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { gridsGrid } from "./grids.grid";

export const WorkflowGridsPage = () => {
  return (
    <DetailsTemplate pageTitle={<PageTitle title="Grids" />}>
      <GridTemplate
        actions={
          <>
            <RouterButton
              dataAction="grids:botao:adicionar_grid"
              dataLabel="adicionar_grid"
              role={roles.workflow.product.configurationsAddGridPage.role}
              href={
                corporateRouter.routes.workflow.product.configurations.grids.add
                  .path
              }
            >
              Adicionar
            </RouterButton>
            <RefreshGridButton
              onClick={() => {
                !gridsGrid.datagrid.dataSource?.isLoading() &&
                  gridsGrid.datagrid.dataSource?.reload();
              }}
            />
          </>
        }
      >
        <ODataGridGenerator grid={gridsGrid} />
      </GridTemplate>
    </DetailsTemplate>
  );
};
