import { DetailsFields } from "components/details-card";
import { BeneficiaryAnalysisDetails } from "services/hubfx/models/types/beneficiary/beneficiary-details.model";

export const exchangeBeneficiaryAnalysisDetailsGenerator = (
  beneficiary: BeneficiaryAnalysisDetails | null,
) => {
  if (!beneficiary) return {} as DetailsFields;

  return {
    beneficiary: {
      title: "Beneficiário",
      items: [
        {
          label: "Nome",
          value: beneficiary?.beneficiaryName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Natureza",
          value: beneficiary?.beneficiaryNature,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CNPJ",
          value: beneficiary?.counterpartyIdentification,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Contraparte",
          value: beneficiary?.counterpartyName,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    analysis: {
      title: "Dados da análise",
      items: [
        {
          label: "Mensagem de erro",
          value: beneficiary?.errorMessage,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: beneficiary?.isActive ? "Ativa" : "Inativa",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Informação de remessa",
          value: beneficiary?.remittanceInformation,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    benefitiaryAccount: {
      title: "Conta beneficiário",
      items: [
        {
          label: "Expense Id",
          value: beneficiary?.beneficiaryAccountExpenseId,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: beneficiary?.beneficiaryAccountCurrencyId,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: beneficiary?.beneficiaryAccountCountryId,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    beneficiaryBank: {
      title: "Banco Beneficiário",
      items: [
        {
          label: "Nº Conta",
          value: beneficiary?.beneficiaryBankAccountNumber,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Nome do banco",
          value: beneficiary?.beneficiaryBankName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.beneficiaryBankSwift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.beneficiaryBankFedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    beneficiaryClient: {
      title: "Cliente",
      items: [
        {
          label: "Nome",
          value: beneficiary?.beneficiaryClientName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: beneficiary?.beneficiaryCountryId,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Id Cge",
          value: beneficiary?.beneficiaryClientIdCge,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Link",
          value: beneficiary?.beneficiaryClientLink,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Nature Id",
          value: beneficiary?.beneficiaryClientNature,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    intermediateBank: {
      visible: !!beneficiary?.beneficiaryIntermediateBankName,
      title: "Banco Intermedário",
      items: [
        {
          label: "Nº Conta",
          value: beneficiary?.beneficiaryIntermediateBankAccountNumber,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Banco Intermedário",
          value: beneficiary?.beneficiaryIntermediateBankName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.beneficiaryIntermediateBankSwift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.beneficiaryIntermediateBankFedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
