import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  dataSource,
  estrategiasComerciaisGrid,
} from "./estrategia-templates.grid";

export const EstrategiaTemplates = () => {
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Templates de estratégia" />}
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.recebiveis.product.estrategiaTemplates
                .addEstrategia.path
            }
            role={
              roles.recebiveis.product.estrategiaTemplatesAddEstrategiaPage.role
            }
          >
            Adicionar
          </StandardLink>
          <RefreshGridButton />
        </>
      }
    >
      <ODataGridGenerator
        grid={estrategiasComerciaisGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
