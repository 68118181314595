import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldSelectMultiple,
} from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useLinkTaggeado } from "./link-taggeado-field.hook";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const LinkTaggeadoField = () => {
  const { portals, getPortals, loading, hasFetchError } = useLinkTaggeado();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelectMultiple
        dataAction="geral:texto:link_taggeado"
        dataLabel="link_taggeado"
        id="linkTaggeado"
        name="linkTaggeado"
        label={EstrategiaFormLabels.linkTaggeado}
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={portals?.length}
      >
        {portals?.map(({ name, portalCode }) => (
          <OOptionMultiple key={portalCode} value={portalCode}>
            {name} - {portalCode}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="marketplaces:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getPortals()}
        />
      )}
    </OCol>
  );
};
