import {
  OButton,
  OCol,
  OIcon,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { UseFieldArrayReturn } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { useEligibilityTree } from "../../../../eligibility-tree.context";
import {
  criteriaTypeMap,
  operationMap,
} from "../../../../eligibility-tree.utils";
import {
  EditEligibilityGroupForm,
  criteriaFieldName,
} from "../../edit-eligibility-group-modal.types";

interface CriteriaFieldsProps {
  index: number;
  fieldArray: UseFieldArrayReturn<EditEligibilityGroupForm, "criteria", "id">;
  visible: boolean | undefined;
}

export const CriteriaFields = ({
  index,
  fieldArray,
  visible,
}: CriteriaFieldsProps) => {
  const { criteria } = useEligibilityTree();

  const { remove, update, fields } = fieldArray;

  return visible ? (
    <ORow>
      <OCol xs={4}>
        <ORFieldSelect
          dataAction="editar_grupo:select:criterio"
          dataLabel="criterio"
          id={`${criteriaFieldName}.${index}.criterium`}
          name={`${criteriaFieldName}.${index}.criterium`}
          placeholder="Selecione:"
          key={criteria.length}
        >
          {criteria.map((criterium) => (
            <OOption key={criterium} value={criterium}>
              {getValueFromMap(criteriaTypeMap, criterium) ?? criterium}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={4}>
        <ORFieldSelect
          dataAction="editar_grupo:select:operacao"
          dataLabel="operacao"
          id={`${criteriaFieldName}.${index}.operation`}
          name={`${criteriaFieldName}.${index}.operation`}
          placeholder="Selecione:"
        >
          {Object.entries(operationMap).map(([operation, operationLabel]) => (
            <OOption key={operation} value={operation}>
              {operationLabel}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={3}>
        <ORFieldInput
          dataAction="editar_grupo:select:valor"
          dataLabel="valor"
          id={`${criteriaFieldName}.${index}.value`}
          name={`${criteriaFieldName}.${index}.value`}
          tag="text"
          placeholder="0.0"
        />
      </OCol>
      <OCol xs={1}>
        <OButton
          dataAction="editar_grupo:botao:remover"
          dataLabel="remover"
          type="danger"
          onClick={() => {
            if (fields[index].isNewlyCreated) remove(index);
            else update(index, { ...fields[index], active: false });
          }}
        >
          <OIcon category="fa" icon="fa-trash" />
        </OButton>
      </OCol>
    </ORow>
  ) : (
    <div />
  );
};
