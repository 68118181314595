import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Asset } from "./assignment-details.types";

export const assetsGrid: ODataGridGeneratorConfig<Asset> = {
  datagrid: {
    noDataText: "Nenhum asset.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "state",
      caption: "Status",
    },
    {
      dataField: "identification",
      caption: "Chave",
      allowHeaderFiltering: false,
    },
    {
      dataField: "sacado.officialName",
      caption: "Sacado",
      allowHeaderFiltering: false,
      width: 200,
    },
    {
      dataField: "sacado.identification",
      caption: "Sacado CNPJ",
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "sacado.cge",
      caption: "Sacado CGE",
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturityDate",
      dataType: "datetime",
      format: "shortDate",
      caption: "Vencimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "installmentsQuantity",
      caption: "Nº de parcelas",
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturityAmount",
      caption: "Valor principal",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "price",
      caption: "Valor de cessão",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
  ],
};
