import { isAxiosError } from "axios";
import { HubfxErrorResponse } from "services/hubfx/models/responses/error/hubfx-error.response";
import { getValueFromMap } from "utils/get-value-from-map";

const natureErrorCodeMap = {
  TypeAlreadyRegistered: "Já existe essa configuração de documentos",
  GenericFieldTypeAlreadyRegistered: "Já existe esse campo genérico",
  NatureAlreadyRegistered: "Já existe essa natureza",
  CannotDeleteDocumentConfig: "Não pode deletar essa configuração de documento",
  CannotDeleteDocumentGroup: "Não pode deletar esse grupo de documento",
  CannotDeleteGenericFieldType: "Não pode deletar esse campo genérico",
  CannotDeleteNature: "Não pode deletar essa natureza",
  NatureNotApproved: "Natureza não aprovada",
  DocumentTypeMustNotContainNewLineOrWhiteSpace:
    "Nome da configuração de documento não pode conter espaços ou quebra de linha",
  GenericFieldTypeMustNotContainNewLineOrWhiteSpace:
    "Nome do campo genérico não pode conter espaços ou quebra de linha",
  InvalidCharacter: "Contém caracter inválido",
  InvalidMinimumAmount: "Valor mínimo inválido",
  InvalidMaximumAmount: "Valor máximo inválido",
  InvalidAmountRange: "Intervalo de valores inválidos",
};

const exchangeErrorCodeMap = {
  ...natureErrorCodeMap,
};

export const getHubfxErrorMessage = (error: unknown) =>
  (isAxiosError<HubfxErrorResponse>(error)
    ? error?.response?.data?.response.failures?.map((failure) => {
        const message = getValueFromMap(
          exchangeErrorCodeMap,
          failure.errorCode,
        );
        return message ? [message] : [];
      }).join(", ")
    : null) || "Ocorreu um erro";
