import { OToastManager } from "@maestro/core";
import { OCheckbox } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { customer } from "contexts/customer";
import { service } from "services/service";
import { ProfileMasterDetail } from "../../../components/role-assignments/profile-selector/_compose";
import { Profile } from "../../../components/role-assignments/profile-selector/profile-selector.types";

export const buildDataSource = (
  setProfiles: React.Dispatch<React.SetStateAction<Profile[]>>,
) =>
  dataSourceCustomStoreGenerator<Profile>(
    () =>
      service.onboardingRelationships
        .getProfiles({
          companyTaxId: customer.value?.identification ?? "",
        })
        .then(({ data }) => {
          setProfiles(data.profiles);
          return data.profiles;
        })
        .catch(() => {
          const errorMessage = "Erro ao buscar as funções";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        key: "uuid",
      },
    },
  );

export const attachUserProfilesGrid: ODataGridGeneratorConfig<Profile> = {
  datagrid: {
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
    },
  },
  columns: [
    {
      cellRender: ({ data }) => (
        <OCheckbox id={data.uuid} value={data.uuid} size="xs" disabled />
      ),
      width: 40,
      alignment: "center",
    },
    {
      dataField: "nameLabel",
      caption: "Função",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "groupNameLabel",
      caption: "Categoria",
      groupIndex: 0,
      groupCellRender: ({ displayValue }) => displayValue,
    },
  ],
  paging: {
    pageSize: 20,
  },
  masterDetail: {
    enabled: true,
    component: ProfileMasterDetail,
  },
};
