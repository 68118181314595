import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";

export const createDocumentsConfirmationModalId =
  "create-documents-confirmation-modal";

interface CreateDocumentsConfirmationModalProps {
  create: () => void;
}

export const CreateDocumentsConfirmationModal = ({
  create,
}: CreateDocumentsConfirmationModalProps) => {
  return (
    <OModal
      id={createDocumentsConfirmationModalId}
      position="center"
      size="sm"
      backdrop
    >
      <OModalHeader>
        <OTypography size="lg">Gerar documentos</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ whiteSpace: "normal" }}>
          Após a geração, os documentos serão enviados para a assinatura do
          cliente. Certeza que deseja prosseguir?
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(createDocumentsConfirmationModalId)
            }
          >
            Cancelar
          </OButton>
          <OButton
            onClick={() => {
              create();
              modalManager.hide(createDocumentsConfirmationModalId);
            }}
          >
            Confirmar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
