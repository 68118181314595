import { DetailsFields } from "components/details-card";
import {
  AccountPendencies,
  ValidationResults,
} from "./closing-pendencies.types";
import { Pendency } from "./_compose/pendency";

const buildPendencyDetailsByAccount = (
  accountPendencies: AccountPendencies,
): DetailsFields[string] => ({
  title: `Agência: ${accountPendencies.agencyNumber} | Conta: ${accountPendencies.accountNumber}`,
  items: accountPendencies.productOccurrences.map((productPendency) => ({
    label: productPendency.productName,
    value: <Pendency pendency={productPendency} />,
    grid: { xs: 12, md: 6 },
  })),
});

export const buildClosingPendenciesDetails = (
  validationResults: ValidationResults,
): DetailsFields => {
  return Object.fromEntries(
    validationResults.accounts.map((accountPendencies, idx) => [
      idx,
      buildPendencyDetailsByAccount(accountPendencies),
    ]),
  );
};
