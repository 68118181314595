import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services";
import { ConfigurableColumn } from "../batch.types";

export const useConfigurableColumn = () => {
  const [selectedColumn, setSelectedColumn] = useState<ConfigurableColumn>();

  const column = useServiceCall(
    service.hubCreditManager.getBatchConfigurableColumns,
  );

  const { callService: getColumnDetails } = column;

  useEffect(() => {
    if (!selectedColumn) return;
    getColumnDetails();
  }, [getColumnDetails, selectedColumn]);

  return {
    selectedColumn,
    setSelectedColumn,
    column,
  };
};
