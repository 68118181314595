import { modalManager, OButton } from "@maestro/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RedirectSignatureModal } from "../redirect-signature-modal/redirect-signature.component";
import { useSimulation } from "../simulation/simulation.context";
import {
  AcceptModal,
  CONFIRMATION_MODAL,
} from "./accept-modal/accept-modal.component";

export const AcceptFooter = () => {
  const navigate = useNavigate();
  const [acceptLoading, setAcceptLoading] = useState(false);

  const { form, isAvailable, loading: simulationLoading } = useSimulation();

  const term = form.getValues()?.termOptions;

  return (
    <div>
      <RedirectSignatureModal />
      <div className="mt-5 d-flex justify-content-between">
        <OButton
          type="light"
          onClick={() => navigate("/emprestimos/cliente/ofertas")}
        >
          Voltar
        </OButton>

        <OButton
          type="dark"
          onClick={() => modalManager.show(CONFIRMATION_MODAL)}
          disabled={!isAvailable || acceptLoading || simulationLoading || !term}
        >
          Enviar para aprovação
        </OButton>
      </div>
      <AcceptModal
        acceptLoading={acceptLoading}
        setAcceptLoading={setAcceptLoading}
      />
    </div>
  );
};
