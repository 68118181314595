import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getModificationsToArray } from "../../utils";
import { validationSchema } from "./desks.form";
import { FormValuesType, LocalFormValuesType } from "./desks.types";

export const useDesks = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [allDesks, setAllDesks] = useState<SelectSearchOption<number>[]>([]);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, getValues, watch, setValue } = form;

  const watchTaxId = watch("taxId");
  const watchDesks = watch("desks");

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;

  const watchInput = localWatch("input");

  const { callService: removeDesksFromEntity, loading: removeLoading } =
    useServiceCall(service.clerk.removeDesksFromEntity);

  const { callService: upsertEntityDesks, loading: upsertLoading } =
    useServiceCall(service.clerk.upsertEntityDesks);

  const [initialDesks, setInitialDesks] = useState<number[]>([]);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        desks: values.desks ?? [],
      };

      if (!initialDesks || !watchDesks) return;

      const { added, removed } = getModificationsToArray(
        initialDesks,
        watchDesks,
      );

      if (added.length === 0 && removed.length === 0) {
        return OToastManager.info("Nenhuma modificação");
      }

      const promises: Promise<{ success: boolean }>[] = [];

      if (added.length > 0) {
        promises.push(upsertEntityDesks(payload));
      }

      if (removed.length > 0) {
        promises.push(
          removeDesksFromEntity({
            taxId: values.taxId,
            desks: removed,
          }),
        );
      }

      const successes = await Promise.all(promises);

      if (successes.every(({ success }) => success)) {
        OToastManager.success("Atualizado com sucesso");
        navigate(routes.ferramentas.product.desks.path);
      } else {
        OToastManager.danger("Erro para salvar os dados");
      }
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const search = useCallback(
    async (taxId: string) => {
      try {
        setLoading(true);
        const { data } = await service.clerk.getEntityDesks({ taxId });

        const newData = data.map((d) => d.code);
        if (!newData) return;
        setInitialDesks(newData);
        setValue("desks", newData);
      } catch (err) {
        OToastManager.danger("Erro para buscar os dados");
      } finally {
        setLoading(false);
      }
    },
    [setValue],
  );

  const debouncedSearch = useMemo(() => {
    return debounce(search, 500);
  }, [search]);

  const load = useCallback(async () => {
    const { data } = await service.clerk.getDesks();

    const newData = data.map((d) => {
      const segment = d.segmentName
        ? ` ${d.segmentName}/${d.cosegmentName}`
        : "";

      return {
        value: d.code,
        label: `${d.code}${segment} - ${d.name}`,
        onSelect: () => {
          const desks = getValues("desks") ?? [];

          setValue("desks", [...new Set([...desks, d.code])]);
        },
      };
    });

    setAllDesks(newData);
  }, [getValues, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (watchDesks) localSetValue("input", undefined);
  }, [localSetValue, watchDesks]);

  useEffect(() => {
    if (watchTaxId) debouncedSearch(watchTaxId);

    return () => debouncedSearch.cancel();
  }, [debouncedSearch, watchTaxId]);

  return {
    allDesks,
    form,
    loading,
    localForm,
    watchDesks,
    watchInput,
    setValue,
    submit,
    upsertLoading,
    removeLoading,
  };
};
