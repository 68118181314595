import {
  OCard,
  OCardHeader,
  OCardBody,
  ORow,
  OCol,
  ORFieldInput,
} from "@maestro/react";
import { useBankAccountField } from "./bank-account-field.hook";
import { BankAccountCompanySearch } from "./busca-empresa-conta-bancaria";
import { SettlementStrategisFormProps } from "../formulario/settlement-strategies-form.types";

export const BankAccountField = ({
  initialValues,
}: Pick<SettlementStrategisFormProps, "initialValues">) => {
  const { enableBankAccountForm } = useBankAccountField(
    initialValues?.bankAccount,
  );

  return (
    <OCard>
      <OCardHeader divider>Conta bancária de liquidação</OCardHeader>
      <OCardBody>
        <ORow>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <BankAccountCompanySearch
              disabled={!enableBankAccountForm}
              initialValue={initialValues?.bankAccount?.company ?? null}
            />
          </OCol>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <ORFieldInput
              tag="text"
              label="Banco"
              id="bankAccount.bankCode"
              name="bankAccount.bankCode"
              disabled={!enableBankAccountForm}
              dataAction="formulario_estrategia_liquidacao:texto:codigo_banco"
              dataLabel="codigo_banco"
            />
          </OCol>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <ORFieldInput
              tag="text"
              label="Agência"
              id="bankAccount.branch"
              name="bankAccount.branch"
              disabled={!enableBankAccountForm}
              dataAction="formulario_estrategia_liquidacao:texto:agencia"
              dataLabel="agencia"
            />
          </OCol>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <ORFieldInput
              tag="text"
              label="Dígito da agência"
              id="bankAccount.branchCheckDigit"
              name="bankAccount.branchCheckDigit"
              disabled={!enableBankAccountForm}
              dataAction="formulario_estrategia_liquidacao:texto:digito_agencia"
              dataLabel="digito_agencia"
            />
          </OCol>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <ORFieldInput
              tag="text"
              label="Conta"
              id="bankAccount.accountNumber"
              name="bankAccount.accountNumber"
              disabled={!enableBankAccountForm}
              dataAction="formulario_estrategia_liquidacao:texto:conta"
              dataLabel="conta"
            />
          </OCol>
          <OCol sm={12} md={4} lg={3} xl={2}>
            <ORFieldInput
              tag="text"
              label="Dígito da conta"
              id="bankAccount.accountCheckDigit"
              name="bankAccount.accountCheckDigit"
              disabled={!enableBankAccountForm}
              dataAction="formulario_estrategia_liquidacao:texto:digito_conta"
              dataLabel="digito_conta"
            />
          </OCol>
        </ORow>
      </OCardBody>
    </OCard>
  );
};
