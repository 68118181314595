import { ORFieldUploadInput } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { EmptyState } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { UploadContainer } from "../../../../../antecipacao-de-cartoes/components";
import { buildReprocessEligibilityResultFields } from "./reprocess-eligibility.details";
import { useReprocessEligibility } from "./reprocess-eligibility.hook";

export const ReprocessEligibility = () => {
  const { error, form, handleAddFile, loading } = useReprocessEligibility();

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate
        pageTitle={<PageTitle title="Reprocessar elegibilidade" />}
      >
        <CardTemplate>
          <FormProvider {...form}>
            <UploadContainer>
              <ORFieldUploadInput
                dataAction="reprocessar_elegibilidade:upload:reprocessar_elegibilidade"
                dataLabel="reprocessar_elegibilidade"
                id="reprocess-eligibility"
                name="reprocess-eligibility"
                inputLabel="Clique ou arraste o arquivo aqui"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple={false}
                handleAddFile={handleAddFile}
                disabled={loading}
              />
            </UploadContainer>
          </FormProvider>
        </CardTemplate>
      </DetailsTemplate>
      <DetailsTemplate>
        <DetailsCard
          fields={buildReprocessEligibilityResultFields}
          emptyStateComponent={
            <EmptyState messageParagraph="Envie um arquivo no campo acima" />
          }
          loading={loading}
          value={error}
        />
      </DetailsTemplate>
    </div>
  );
};
