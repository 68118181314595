import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  BasicFields,
  ProfileSelector,
  SimpleConfirmationModal,
  VigencySelectModal,
} from "../../../components/role-assignments";
import { useAddUser } from "./add-user.hook";

export const AddUser = () => {
  const {
    ceilingVigencyDate,
    form,
    loading,
    preSubmit,
    profileValidationResponse,
    setCeilingVigencyDate,
    submit,
  } = useAddUser();

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Novo usuário" />}
        actions={
          <LoadingButton
            dataAction="acoes:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            onClick={preSubmit}
          >
            Enviar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <BasicFields />
            <ProfileSelector setCeilingVigencyDate={setCeilingVigencyDate} />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <SimpleConfirmationModal
        message={profileValidationResponse?.message}
        submit={submit}
      />
      <VigencySelectModal
        message={profileValidationResponse?.message}
        submit={submit}
        maxVigency={ceilingVigencyDate}
      />
    </>
  );
};
