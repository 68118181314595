export enum PixKeyType {
  EVP = "EVP",
  CPF = "CPF",
  CNPJ = "CNPJ",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export const OInputMaskPixProps = {
  className: "w-100",
  id: "pix",
  name: "pix",
  dataAction: "pix:texto:inserir_texto",
  dataLabel: "inserir_texto",
  placeholder: "Insira a chave PIX",
  aspect: "unstyled" as HTMLOInputMaskElement["aspect"],
};

export const OIconClearProps = {
  className: "btn p-0 align-self-center",
  category: "orq" as HTMLOIconElement["category"],
  icon: "orq-close" as HTMLOIconElement["icon"],
  size: "xl" as HTMLOIconElement["size"],
  title: "Limpar campo",
};

export interface PixKeyInputProps {
  isLoadingPixKey: boolean;
  debounceTime?: number;
}
