/* eslint-disable no-param-reassign */
import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { TradeHealthIndicator } from "./trade-health.types";

export const dataSource = dataSourceCustomStoreGenerator<TradeHealthIndicator>(
  (loadOptions) =>
    service.recebiveisCartao
      .getTradesHealthStatus(loadOptions as any)
      .then(({ data }) => data.data)
      .catch(() => {
        const errorMessage = "Erro ao buscar o status";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const tradeHealthGrid: ODataGridGeneratorConfig<TradeHealthIndicator> = {
  datagrid: {
    onCellPrepared: (evt) => {
      if (evt.displayValue === "nulo") {
        evt.cellElement.style.backgroundColor = "var(--theme-danger-light)";
        evt.cellElement.style.color = "var(--theme-danger-light-inverse)";
      }
    },
  },
  columns: [
    {
      dataField: "count",
      caption: "Total",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "status",
      caption: "Status",
    },
    {
      dataField: "balance_missing",
      caption: "Balance",
      calculateCellValue: ({ balance_missing }) =>
        balance_missing ? "nulo" : "ok",
      cellRender: ({ data: { balance_missing } }) =>
        balance_missing ? "nulo" : "ok",
    },
    // identop not on api response
    {
      dataField: "price_missing",
      caption: "Price",
      calculateCellValue: ({ price_missing }) =>
        price_missing ? "nulo" : "ok",
      cellRender: ({ data: { price_missing } }) =>
        price_missing ? "nulo" : "ok",
    },
    {
      dataField: "type_missing",
      caption: "Tipo",
      calculateCellValue: ({ type_missing }) => (type_missing ? "nulo" : "ok"),
      cellRender: ({ data: { type_missing } }) =>
        type_missing ? "nulo" : "ok",
    },
    {
      dataField: "status_missing",
      caption: "Status",
      calculateCellValue: ({ status_missing }) =>
        status_missing ? "nulo" : "ok",
      cellRender: ({ data: { status_missing } }) =>
        status_missing ? "nulo" : "ok",
    },
    {
      dataField: "inserted_after_definitive_model",
      caption: "Modelo definitivo",
    },
    {
      dataField: "inserted_after_reallocation_feature",
      caption: "Realocação",
    },
    {
      dataField: "external_ids",
      caption: "External IDs",
      width: 150,
    },
  ],
};
