import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";

export const useAdequacyOptionSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getAdequacyOptions,
  );

  const { clearErrors, setError } = useFormContext<{
    adequacyOptionId: number;
  }>();

  const getAdequacyOptions = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("adequacyOptionId");
    else
      setError("adequacyOptionId", {
        message:
          "Erro ao buscar as adequadoras. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getAdequacyOptions();
  }, [getAdequacyOptions]);

  const adequacyOptions = useMemo(
    () =>
      value?.response.map<SelectSearchFieldOption<number>>(
        ({ id, name, entity }) => ({
          label: `${name} - ${fastCpfCnpjMask(entity.taxId)}`,
          value: id,
        }),
      ) ?? [],
    [value?.response],
  );

  return {
    adequacyOptions,
    getAdequacyOptions,
    loading,
    hasFetchError: hasError,
  };
};
