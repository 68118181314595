export const options = [
  "Aberto",
  "Agendado",
  "Antecipada",
  "Aprovado",
  "Cancelada",
  "EmAgendamento",
  "EmAntecipacao",
  "EmAprovacao",
  "EmAtraso",
  "Indisponivel",
  "Liquidada",
  "Pago",
  "Removido",
];
