import { OBadge, OCard, OCardBody, OIcon } from "@maestro/react";
import { Handle, Position, NodeProps } from "reactflow";
import { SnapshotNodeLabel } from "./snapshot-node.styles";

export type SnapshotNodeData = {
  isEligibleTree?: boolean;
  label: string;
  hasPassed: boolean;
  onClick: (groupId: string) => void;
};

export const SnapshotNode = ({ data, id }: NodeProps<SnapshotNodeData>) => {
  return (
    <div className="h-100">
      <Handle
        type="target"
        position={Position.Top}
        className="invisible"
        id={`target-${id}`}
      />
      <OCard
        className="h-100"
        rounded
        type={data.hasPassed ? "success-light" : "danger-light"}
        onClick={() => data.onClick(id)}
      >
        <OCardBody className="position-relative h-100">
          <div className="position-absolute top-0 start-50 translate-middle">
            {data.isEligibleTree !== undefined && (
              <OBadge
                size="xs"
                className="mt-2"
                type={data.isEligibleTree ? "success" : "danger"}
              >
                <OIcon
                  category="orq"
                  icon={
                    data.isEligibleTree
                      ? "orq-status-success"
                      : "orq-status-disapproved"
                  }
                  size="xs"
                  type={data.isEligibleTree ? "success-light" : "danger-light"}
                />
                {data.isEligibleTree ? "Árvore elegível" : "Árvore inelegível"}
              </OBadge>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center h-100">
            <SnapshotNodeLabel>{data.label}</SnapshotNodeLabel>
          </div>
        </OCardBody>
      </OCard>
      <Handle
        type="source"
        position={Position.Bottom}
        id={`source-${id}`}
        className="invisible"
      />
    </div>
  );
};
