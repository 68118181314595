import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

type ProcessorConfigColumns = Awaited<
  ReturnType<
    typeof service.hubCreditManager.getProcessorConfigsByWorkflowConfigId
  >
>["data"][number];

export const workflowConfigProcessorDataSource = (
  workflowConfigId: number | string,
) =>
  dataSourceCustomStoreGenerator(() =>
    service.hubCreditManager
      .getProcessorConfigsByWorkflowConfigId(workflowConfigId)
      .catch(() => {
        throw new Error(
          "Não foi possível carregar os processadores configurados.",
        );
      }),
  );

export const processorConfigGrid = (workflowConfigId: number | string) =>
  ({
    datagrid: {
      noDataText: "Nenhum processador configurado encontrado",
      dataSource: workflowConfigProcessorDataSource(workflowConfigId),
      paging: {
        pageSize: 10,
      },
      headerFilter: { visible: true, allowSearch: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.workflow.product.workflowConfigProcessorDetailsBasicDataPage
          .role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.workflow.product.workflowsConfig.details.edit.processor.details.dataBasic.path(
              {
                id: workflowConfigId,
                processorId: data.id,
              },
            )}
          />
        ),
      },
      {
        dataField: "id",
        dataType: "number",
        caption: "Id",
        sortOrder: "asc",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "processorType",
        caption: "Tipo do Processador",
      },
      {
        dataField: "validationStatus",
        caption: "Status da Validação",
      },
      {
        dataField: "processorId",
        caption: "Id do Processador",
      },
    ],
  } satisfies ODataGridGeneratorConfig<ProcessorConfigColumns>);
