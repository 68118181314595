import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";

export const dataSource = dataSourceCustomStoreGenerator(() =>
  service.onboarding
    .getDeskOnboardingDocumentConfig()
    .then(({ data }) => data ?? [])
    .catch(() => {
      const validator = "Erro ao buscar os documentos";

      OToastManager.danger(validator);
      throw new Error(validator);
    }),
);

export const deskDocumentConfigGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDeskOnboardingDocumentConfig>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum documento",
  },
  columns: [
    {
      dataField: "id",
    },
    {
      dataField: "type",
    },
    {
      dataField: "subType",
    },
    {
      dataField: "lessCode",
      alignment: "left",
    },
    {
      dataField: "label",
    },
    {
      dataField: "uuid",
    },
  ],
};
