export const migrationStatusMap = {
  REGISTRADO: {
    label: "Registrado",
    type: "warning" as const,
  },
  PENDENTE_VALIDACAO: {
    label: "Pendente validação",
    type: "warning" as const,
  },
  APROVADO: {
    label: "Aprovado",
    type: "success" as const,
  },
  REPROVADO: {
    label: "Reprovado",
    type: "danger" as const,
  },
};
