import { OCard, OCardHeader, OCol, OTypography } from "@maestro/react";
import { OwnersInState } from "services/moana/models";
import { OwnerInStateCard } from "./owner-in-state-card.component";

interface OwnerInStateProps {
  ownersInState?: OwnersInState;
  totalOwners: number;
}

export const OwnerInState = ({
  ownersInState,
  totalOwners,
}: OwnerInStateProps) => {
  return (
    <>
      {ownersInState && (
        <OCard className="mb-4">
          <OCardHeader divider>
            <OCol className="justify-content-between">
              <OTypography type="default">
                Status faltantes para clientes
              </OTypography>
            </OCol>
          </OCardHeader>
          <OwnerInStateCard
            ownersInState={ownersInState}
            totalOwners={totalOwners}
          />
        </OCard>
      )}
    </>
  );
};
