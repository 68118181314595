import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { ParceirosProdutoOperatorDetailsRouteParams } from "../../../../../routes/parceiros.route-params";
import { buildDataSource, relatedOriginatorsGrid } from "./profiles.grid";

export const ProfilesByIdentification = () => {
  const { operatorIdentification } =
    useParams<ParceirosProdutoOperatorDetailsRouteParams>();

  if (!operatorIdentification) throw new Error("No Id");

  const gridRef = useGridRef();

  const grid = useMemo(() => relatedOriginatorsGrid(), []);
  const dataSource = buildDataSource(operatorIdentification);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Perfis" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        dataSource={dataSource}
        gridRef={gridRef}
        grid={grid}
      />
    </GridTemplate>
  );
};
