import { OField, OTypography } from "@maestro/react";
import { memo, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FinancialInstitutionUtils } from "utils/financial-institution";
import { useFinancialInstitutionsList } from "hooks/financial-institution-list";
import { CustomSelect } from "../custom-select";
import { FinancialInstitutionOption } from "../side-filter-bar/_compose/side-form-modal/_compose/mult-financial-institution-selection/_compose/financial-institution-option.component";
import { FinancialInstitutionSelectProps } from "./financial-institution-select.types";

export const FinancialInstitutionSelect = memo(
  ({
    fieldName,
    required,
    showFullName,
    handleSelect = () => {},
    optionsMaxHeight = "200px",
    label = "Instituição financeira",
    placeholder = "Selecione a instituição financeira",
  }: FinancialInstitutionSelectProps) => {
    const [selectedOption, setSelectedOption] =
      useState<Assets.FinancialInstitution>();
    const { clearErrors, getFieldState, getValues, setValue } =
      useFormContext();
    const { financialInstitutions } = useFinancialInstitutionsList();

    const currentValue = getValues(fieldName);

    useEffect(() => {
      setSelectedOption(
        financialInstitutions?.find(({ ispb }) => ispb === currentValue),
      );
    }, [currentValue, financialInstitutions]);

    const handleSelectInstitution = useCallback(
      (financialInstitution?: Assets.FinancialInstitution) => {
        if (!financialInstitution) return;
        setValue(fieldName, financialInstitution.ispb);
        clearErrors(fieldName);
        handleSelect(financialInstitution);
      },
      [clearErrors, fieldName, handleSelect, setValue],
    );

    const renderEmpty = useCallback(() => {
      return (
        <div className="d-flex align-items-center">
          <OTypography type="dark" key={placeholder}>
            {placeholder}
          </OTypography>
        </div>
      );
    }, [placeholder]);

    const resumeOption = useCallback(
      (financialInstitution: Assets.FinancialInstitution) =>
        FinancialInstitutionUtils.assembleInstitutionResume(
          financialInstitution,
          showFullName,
        ),
      [showFullName],
    );

    const renderOption = useCallback(
      (
        financialInstitution: Assets.FinancialInstitution,
        isHeader?: boolean,
      ) => {
        const title = resumeOption(financialInstitution);
        const { bankCode } = financialInstitution;

        return (
          <FinancialInstitutionOption
            title={title}
            bankCode={bankCode}
            isHeader={isHeader}
          />
        );
      },
      [resumeOption],
    );

    return (
      <OField
        label={label}
        htmlFor="financialInstitutionSelect"
        required={required}
        error={!!getFieldState(fieldName).error}
        message={getFieldState(fieldName).error?.message}
      >
        <CustomSelect
          options={financialInstitutions ?? []}
          uniqueKeyProp="ispb"
          renderOption={renderOption}
          resumeOption={resumeOption}
          renderEmpty={renderEmpty}
          handleSelect={handleSelectInstitution}
          id="financialInstitutionSelect"
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          searchPlaceholder="Busque pelo nome da instituição"
          hideOptionsDescription="Para acessar outras instituições, digite o nome ou o número na busca."
          optionsMaxHeight={optionsMaxHeight}
          blockSingleOptionAutoSelection
        />
      </OField>
    );
  },
);
