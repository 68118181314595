export const parsedAmortizationFrequency = (value: string) => {
  switch (value) {
    case "ANUAL":
      return "a";

    case "MENSAL":
      return "m";

    case "BULLET":
      return "a";

    case "BIMESTRAL":
      return "b";

    case "TRIMESTRAL":
      return "t";

    case "QUADRIMESTRAL":
      return "q";

    case "SEMESTRAL":
      return "s";
    default:
      return "-";
  }
};
