import styled from "styled-components";

export const TabMenuContainer = styled.div`
  background-color: var(--theme-light);
  box-shadow: inset var(--theme-dark-20) 0 -2px 0 0;

  o-tab .o-tab-link,
  o-tab .o-tab,
  o-tab-link .o-tab-link {
    border-color: transparent;
  }
`;
