import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSourceGuarantor,
  buildDataSourceLoa,
  occurrencesGrid,
} from "./occurrences-grid.grid";

interface OccurrencesGridProps {
  workflowUuid: string;
  type: string;
}

export const OccurrencesGrid = ({
  workflowUuid,
  type,
}: OccurrencesGridProps) => {
  const gridRef = useGridRef();

  const dataSource = useMemo(() => {
    if (type === "LOA") {
      return buildDataSourceLoa(workflowUuid);
    }

    if (type === "GUARANTOR") {
      return buildDataSourceGuarantor(workflowUuid);
    }
  }, [workflowUuid, type]);

  return (
    <GridTemplate gridRef={gridRef} showRefreshButton showClearFiltersButton>
      <ODataGridGenerator
        gridRef={gridRef}
        grid={occurrencesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
