import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { ContentTemplate } from "templates/content-template";
import { QuantidadeDeDisparosChart } from "../../../components/charts/quantidade-de-disparos-chart.component";
import { useCobrancasDashboard } from "./cobrancas-dashboard.hook";

export const CobrancasDashboardPage = () => {
  const { value, loading, hasError, dates, counts } = useCobrancasDashboard();

  return (
    <ContentTemplate
      hasError={hasError}
      loading={loading}
      value={value}
      render={(_value) => (
        <OCard>
          <OCardHeader>
            <OTypography size="lg">
              Quantidade de disparos feita mensalmente
            </OTypography>
          </OCardHeader>
          <OCardBody>
            <QuantidadeDeDisparosChart
              labels={dates ?? []}
              datasets={counts ?? []}
            />
          </OCardBody>
        </OCard>
      )}
    />
  );
};
