import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { logger } from "utils/logger";
import { useCollectionsAgreementContext } from "../../../convenio";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";
import { assembleUpsertAgreementParams } from "./form-footerline.utils";

export const useFormFooterline = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getAgreementsList, agreementToUpdate, setAgreementToUpdate } =
    useCollectionsAgreementContext();
  const navigate = useNavigate();

  const upsertAgreement = useCallback(
    async (formValues: UpsertCollectionAgreementFormFields) => {
      const isUpdating = !!agreementToUpdate;

      try {
        setIsLoading(true);
        const params = assembleUpsertAgreementParams(formValues);
        const emptySettings = !Object.keys(params.settings).length;
        if (emptySettings) {
          return OToastManager.warning(
            "Insira alguma configuração antes de continuar.",
          );
        }
        if (isUpdating) {
          const { name, settings } = params;
          await service.adminBankinghub.collection.updateAgreement(
            agreementToUpdate.id,
            { name, settings },
          );
          setAgreementToUpdate(undefined);
        } else {
          await service.adminBankinghub.collection.createAgreement(params);
        }
        const successMessage = `Convênio ${
          isUpdating ? "editado" : "cadastrado"
        } com sucesso.`;
        OToastManager.success(successMessage);
        navigate(
          corporateRouter.routes.banking.customer.collection.agreement.path,
        );
        getAgreementsList();
      } catch (err) {
        logger.warn(err);
        const errorMessage = `Ocorreu um erro para ${
          isUpdating ? "editar" : "cadastrar"
        } o convênio.`;
        OToastManager.danger(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [agreementToUpdate, getAgreementsList, navigate, setAgreementToUpdate],
  );

  return { upsertAgreement, isLoading };
};
