import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "services/client";
import { gatekeeperEndpoints } from "services/gatekeeper/gatekeeper.endpoints";
import { UsuariosProdutoByIdentificationRouteParams } from "../../../../routes/usuarios.route-params";
import { ActiveDevice } from "./atividades-login.types";

export const useLoginActivities = () => {
  const { identification } =
    useParams<UsuariosProdutoByIdentificationRouteParams>();
  const [dataSource, setDataSource] = useState<ActiveDevice[]>();

  if (!identification) throw new Error("No identification");

  const fetchData = useCallback(async () => {
    if (client) {
      try {
        const { data } = await client.post(
          gatekeeperEndpoints.sessionToken.endpoint_by_tax_id,
          { taxId: identification },
        );
        setDataSource(data);
      } catch {
        OToastManager.danger("Não foi possível buscar as atividades de login!");
      }
    }
  }, [identification]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { dataSource };
};
