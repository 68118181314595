import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { GetAdminIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { incorrectOrdersGrid } from "./incorrect-orders-grid.component";

const dataSource = dataSourceCustomStoreGenerator<
  GetAdminIncorrectOrdersResponse[number]
>(() => {
  return service.hubFx.adminCustomer
    .getIncorrectOrders()
    .then(({ data }) => data)
    .catch(() => {
      const errorMessage = "Erro ao buscar ordens incorretas";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    });
});

export const ExchangeCustomerIncorrectOrdersPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ordens incorretas" />}
      actions={<RefreshGridButton onClick={() => dataSource.reload()} />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
    >
      <ODataGridGenerator
        grid={incorrectOrdersGrid}
        gridRef={gridRef}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
