import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useMemo } from "react";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

const buildDataSource = (workflowUuid: string) =>
  dataSourceODataStoreGenerator<HubOnboardingRelationships.Odata.Representative>(
    endpoints.onboardingRelationships.odata.companyPowers
      .letterofattorneyRepresentative,
    {
      dataSourceOptions: {
        filter: ["workflowUuid", "=", workflowUuid],
      },
    },
  );

export const companyGroupsMasterDetailGrid: ODataGridGeneratorConfig<HubOnboardingRelationships.Odata.Representative> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        caption: "Id",
        dataField: "Id",
      },
      {
        caption: "TaxId",
        dataField: "UserIdentification",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        caption: "Nome",
        dataField: "UserName",
      },
      {
        caption: "Telefone",
        dataField: "UserPhone",
      },
      {
        caption: "E-mail",
        dataField: "UserEmail",
      },
    ],
  };

type LoaMonitoringMasterDetailProps =
  MasterDetailComponentProps<HubOnboardingRelationships.Odata.LetterOfAttorney>;

export const LoaMonitoringMasterDetail = ({
  data: {
    data: { Uuid },
  },
}: LoaMonitoringMasterDetailProps) => {
  const dataSource = useMemo(() => buildDataSource(Uuid), [Uuid]);
  return (
    <ODataGridGenerator
      grid={companyGroupsMasterDetailGrid}
      dataSource={dataSource}
    />
  );
};
