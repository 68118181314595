import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services/service";

interface ApproveOnboardingWorkflowProps {
  workflowId: string;
  modalId: string;
}

export const ApproveOnboardingWorkflow = ({
  workflowId,
  modalId,
}: ApproveOnboardingWorkflowProps) => {
  const { callService, loading } = useServiceCall(
    service.onboarding.approveWorkflow,
  );

  const submit = useCallback(async () => {
    const { success } = await callService({
      workflowId,
    });

    if (!success)
      return OToastManager.danger("Erro ao aprovar conclusão de cadastro.");

    OToastManager.success("Conclusão de cadastro aprovada.");
    modalManager.hide(modalId);
  }, [callService, modalId, workflowId]);

  return (
    <OModal id={modalId} size="sm" position="center">
      <OModalHeader closeButton>
        <OTypography tag="h1" size="lg">
          Deseja aprovar para conclusão do cadastro
        </OTypography>
      </OModalHeader>
      <OModalBody>{`Id: ${workflowId}`}</OModalBody>
      <OModalFooter divider>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton outline onClick={() => modalManager.hide(modalId)}>
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={() => submit()}>
            Aprovar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
