import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";

export const buildDeskDocumentConfigRelationshipGrid = (
  configIdentifier: string,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum relacionamento",
      dataSource: dataSourceCustomStoreGenerator(
        () =>
          service.onboarding
            .getDeskOnboardingDocumentConfigRelationships({
              deskOnboardingConfigIdentifier: configIdentifier,
            })
            .then(({ data }) => data ?? [])
            .catch(() => {
              const validator = "Erro ao buscar os relacionamentos";

              OToastManager.danger(validator);
              throw new Error(validator);
            }),
        {
          customStoreOptions: {
            remove: async (data) => {
              try {
                if (!data.uuid) throw new Error("No uuid");

                await service.onboarding.removeDeskOnboardingDocumentConfigRelationship(
                  data.uuid,
                );

                OToastManager.success("Relacionamento removido com sucesso.");
              } catch (error) {
                OToastManager.danger("Erro ao remover relacionamento");
              }
            },
          },
        },
      ),
    },
    columns: [
      {
        caption: "Documento",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{`${data.deskOnboardingDocumentConfig.id} - ${data.deskOnboardingDocumentConfig.type}`}</OTypography>
          </div>
        ),
      },
      {
        caption: "Configuração",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{`${data.deskOnboardingConfig.configIdentifier} - ${data.deskOnboardingConfig.configName}`}</OTypography>
            <OTypography type="default">
              {data.deskOnboardingConfig.configDescription}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "complementor",
      },
      {
        dataField: "uuid",
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex, row }) =>
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Remover",
                  onClick: () => {
                    component.option(
                      "editing.texts.confirmDeleteMessage",
                      `Você confirma que o documento '${data?.uuid}' será removido?`,
                    );
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          ),
      },
      {
        type: "buttons",
        buttons: [
          {
            hint: "Salvar",
            icon: "save",
            name: "save",
          },
          {
            hint: "Cancelar",
            icon: "undo",
            name: "cancel",
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    Awaited<
      ReturnType<
        typeof service.onboarding.getDeskOnboardingDocumentConfigRelationships
      >
    >["data"][number]
  >);
