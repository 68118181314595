import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import {
  ProcessStatusCardContent,
  ProcessStatusCardContentProps,
} from "./process-status-card-content.component";

export interface ProcessStatusCardProps extends ProcessStatusCardContentProps {
  hasError: boolean;
}

export const ProcessStatusCard = ({
  item,
  index,
  loading,
  hasError,
}: ProcessStatusCardProps) => {
  return (
    <OCard className="position-relative">
      <OCardBody>
        <ContentTemplate
          hasError={hasError}
          loading={loading}
          noValue
          errorComponent={
            <ErrorComponent messageTitle="Não foi possível buscar as informações" />
          }
          render={() => (
            <ProcessStatusCardContent
              item={item}
              index={index}
              loading={loading}
            />
          )}
        />
      </OCardBody>
    </OCard>
  );
};
