import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  bannerFormDefaultValues,
  BannerFormFields,
} from "../banner-management-form.schema";
import { variablesKeys } from "./banner-form.types";

export const useBannerForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState("CRIACAO");
  const { id } = useParams();

  if (!id) throw Error("No id");

  const form = useForm<BannerFormFields>({
    defaultValues: bannerFormDefaultValues,
  });

  const { reset, setValue, watch, getValues } = form;

  const formData = getValues();
  const watchTemplateId = watch("bannerTemplateId");

  const { callService: callServiceGetBannerById, value: bannerData } =
    useServiceCall(service.hubCreditManager.getBannerById);

  const { callService: callAccessRules, value: accessRules } = useServiceCall(
    service.hubCreditManager.getAccessRules,
  );

  const { callService: callBannerTemplates, value: bannerTemplates } =
    useServiceCall(service.hubCreditManager.getBannerTemplates);

  const { callService: callBannerTemplateById, value: bannerTemplate } =
    useServiceCall(service.hubCreditManager.getBannerTemplateById);

  const { callService: callUpdateBanner, value: data } = useServiceCall(
    service.hubCreditManager.updateBanner,
  );

  const updateBanner = useCallback(
    async (values: BannerFormFields) => {
      const variablesOnPayload: Record<string, any> = {};

      variablesKeys.forEach((key) => {
        variablesOnPayload[key] = getValues(key as keyof typeof values);
      });

      const variables = JSON.stringify(variablesOnPayload);

      const accessRuleIds =
        accessRules
          ?.filter((rule) => formData.accessRules?.includes(rule.name))
          .map((accessRule) => accessRule.id) ?? null;

      const {
        isActive,
        badge,
        bannerTemplateId,
        title,
        endingAt,
        internalName,
        startingFrom,
      } = values;

      const payload = {
        isActive: !!isActive.length,
        variables,
        id: Number(id),
        accessRuleIds,
        badge,
        bannerTemplateId,
        title,
        internalName,
        ...(!!endingAt && { endingAt }),
        ...(!!startingFrom && { startingFrom }),
      };

      const { success: createSuccess } = await callUpdateBanner({
        ...payload,
      });

      if (createSuccess) {
        step === "RESUMO" &&
          navigate(
            corporateRouter.routes.workflow.product.bannerManagement.success
              .path,
          );
      } else {
        OToastManager.danger(`Um erro ocorreu ao tentar atualizar a campanha.`);
      }
    },
    [
      accessRules,
      callUpdateBanner,
      formData.accessRules,
      getValues,
      id,
      navigate,
      step,
    ],
  );

  useEffect(() => {
    callAccessRules();
    callBannerTemplates();
  }, [callAccessRules, callBannerTemplates]);

  useEffect(() => {
    if (!watchTemplateId) return;

    callBannerTemplateById(watchTemplateId);
  }, [bannerData, callBannerTemplateById, watchTemplateId]);

  useEffect(() => {
    callServiceGetBannerById(id);
  }, [callServiceGetBannerById, id]);

  useEffect(() => {
    if (!bannerData) return;

    const {
      isActive,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      accessRules: rules,
      bannerTemplate: template,
      variables,
      ...values
    } = bannerData;

    const formVariables = JSON.parse(variables ?? "{}") as Record<
      string,
      string
    >;

    reset(values);
    variablesKeys.forEach((key) =>
      setValue(key as keyof typeof formData, formVariables[key]),
    );

    setValue(
      "accessRules",
      rules.map((rule) => rule.name),
    );
    setValue("isActive", isActive ? ["true"] : []);
    template && setValue("bannerTemplateId", template?.id);
  }, [bannerData, reset, setValue]);

  return {
    setStep,
    navigate,
    updateBanner,
    step,
    bannerTemplates,
    form,
    accessRules,
    bannerTemplate,
    data,
    formData,
  };
};
