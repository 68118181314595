import { modalManager } from "@maestro/core";
import {
  OButton,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORadio,
  ORadioGroup,
  OTypography,
} from "@maestro/react";
import { EstrategiaForm, EstrategiaFormLabels, EstrategiaFormLabelsEnum } from "pages/recebiveis/components/estrategia-form";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { UpdateStrategyTemplateChildrenOptions } from "services/quickfin/models/requests/patch-template-estrategia-comercial.request";

interface UpdateEstrategiasConfirmationModalProps {
  submit: (updateOptionEstrategias: UpdateStrategyTemplateChildrenOptions) => () => void;
  form: UseFormReturn<EstrategiaForm, any>
}

export const updateEstrategiasConfirmationModalId =
  "update-estrategias-confirmation-modal";

export const UpdateEstrategiasConfirmationModal = ({
  submit,
  form,
}: UpdateEstrategiasConfirmationModalProps) => {
  const [updateChildrenOption, setUpdateChildrenOption] = useState(UpdateStrategyTemplateChildrenOptions.ONLY_DIRTY);

  const handleInput = (e: any) => {
    setUpdateChildrenOption(e.target.value);
  };

  return (
    <OModal
      id={updateEstrategiasConfirmationModalId}
      position="center"
      backdrop
      size="lg"
    >
      <OModalHeader>
        <OTypography size="lg">
          O que deseja fazer a respeito das estratégias vinculadas a este template?
        </OTypography>

        <OTypography className="mt-3">
          {Object.keys(form.formState.dirtyFields).map((key) => (
            <OTypography size="md" key={key} className="mt-1">
              {EstrategiaFormLabels[key as EstrategiaFormLabelsEnum]}:{" "}
              {form.getValues(key as keyof EstrategiaForm)}
            </OTypography>
          ))}
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <ORadioGroup
          id="updateChildrenStrategies"
          value={updateChildrenOption}
          onInput={(e: any) => handleInput(e)}
        >
          <div className="d-flex gap-4">
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id={UpdateStrategyTemplateChildrenOptions.ONLY_DIRTY} value={UpdateStrategyTemplateChildrenOptions.ONLY_DIRTY} />
              <OLabel htmlFor={UpdateStrategyTemplateChildrenOptions.ONLY_DIRTY}>Atualizar somente os campos modificados</OLabel>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id={UpdateStrategyTemplateChildrenOptions.ALL_FIELDS} value={UpdateStrategyTemplateChildrenOptions.ALL_FIELDS} />
              <OLabel htmlFor={UpdateStrategyTemplateChildrenOptions.ALL_FIELDS}>Atualizar todos os campos</OLabel>
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id={UpdateStrategyTemplateChildrenOptions.NOT_UPDATE} value={UpdateStrategyTemplateChildrenOptions.NOT_UPDATE} />
              <OLabel htmlFor={UpdateStrategyTemplateChildrenOptions.NOT_UPDATE}>Não desejo atualizar as estratégias vinculadas</OLabel>
            </div>
          </div>
        </ORadioGroup>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(updateEstrategiasConfirmationModalId)
            }
            className="me-auto"
          >
            Cancelar
          </OButton>
          <OButton
            onClick={() => {
              submit(updateChildrenOption)();
              modalManager.hide(updateEstrategiasConfirmationModalId);
            }}
          >
            Enviar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
