import { OToastManager } from "@maestro/core";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useCallback } from "react";
import { GetDashboardSignersAmbevNfeResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { signersTableGrid } from "./signers-table.grid";

export const SignersTable = () => {
  const dataSource = dataSourceCustomStoreGenerator<
    GetDashboardSignersAmbevNfeResponse[number]
  >(() => {
    return service.fidc
      .getDonusSigners()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os assinantes";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

  const GenerateContracts = useCallback(async () => {
    try {
      // taxId da Donus
      await service.fidc.postGenerateAllContracts({ cnpj: "38455447000128" });
    } catch {
      OToastManager.danger("Erro ao gerar contratos");
    }
  }, []);

  const resendEmails = useCallback(async () => {
    try {
      // taxId da Donus
      await service.fidc.postFundNotifications({ cnpj: "38455447000128" });
    } catch {
      OToastManager.danger("Erro ao gerar contratos");
    }
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Assinantes do fundo DONUS FUNDO DE INVESTIMENTO EM DIREITOS CREDITÓRIOS" />
      }
      actions={
        <>
          <IconButton
            icon={{ category: "orq", icon: "orq-email" }}
            dataAction=""
            dataLabel=""
            onClick={() => GenerateContracts()}
          >
            Gerar contratos e agendar e-mails
          </IconButton>
          <IconButton
            icon={{ category: "orq", icon: "orq-refresh" }}
            dataAction=""
            dataLabel=""
            onClick={() => resendEmails()}
          >
            Reenviar emails
          </IconButton>
        </>
      }
    >
      <ODataGridGenerator grid={signersTableGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
