import { client } from "../client";
import { ODataRequest } from "../service.types";
import {
  ConfirmSettlementPrepaymentBody,
  CreateManualNotificationBody,
  DonwloadBankslipsByTradeIdParams,
  GetBankslipsByTradeIdParams,
  RepublishNotificationBody,
  SendBankslipViaEmailBody,
  SimulateSettlementPrepaymentBody,
} from "./models/requests";
import {
  AttachmentUploadResponse,
  GetBankslipsByTradeIdResponse,
  GetNotificationsCountPerMonthResponse,
  NotificationMessage,
  NotificationOperation,
  NotificationUser,
  SharkDataGridResponse,
  SharkSettlementData,
} from "./models/responses";
import { sharkEndpoints } from "./shark.endpoints";

export const sharkService = {
  getNotificationsCountPerMonth() {
    return client.get<GetNotificationsCountPerMonthResponse>(
      sharkEndpoints.v1.notifications.messagesCountPerMonth,
    );
  },
  getOperations(params: ODataRequest) {
    return client.post<SharkDataGridResponse<NotificationOperation>>(
      sharkEndpoints.v1.notifications.operations,
      params,
    );
  },
  getMessages(params: ODataRequest & { operation_id: number }) {
    return client.post<SharkDataGridResponse<NotificationMessage>>(
      sharkEndpoints.v1.notifications.messages,
      params,
    );
  },
  getUsers(params: ODataRequest & { message_id: number }) {
    return client.post<SharkDataGridResponse<NotificationUser>>(
      sharkEndpoints.v1.notifications.users,
      params,
    );
  },
  republishNotification(body: RepublishNotificationBody) {
    return client.post(sharkEndpoints.v1.notifications.republish, body);
  },
  createNotificationOperation(body: CreateManualNotificationBody) {
    return client.post(sharkEndpoints.v1.notifications.createOperation, body);
  },
  uploadAttachment(body: FormData) {
    return client.post<AttachmentUploadResponse>(
      sharkEndpoints.v1.notifications.attachments,
      body,
    );
  },
  getBankslipsByTradeId(params: GetBankslipsByTradeIdParams) {
    return client.get<GetBankslipsByTradeIdResponse>(
      sharkEndpoints.v1.bankslips.getByTradeId,
      {
        params,
      },
    );
  },
  sendBankslipViaEmail(body: SendBankslipViaEmailBody) {
    return client.post(sharkEndpoints.v1.bankslips.sendViaEmail, body);
  },
  downloadBankslipByTradeId(params: DonwloadBankslipsByTradeIdParams) {
    return client.get(sharkEndpoints.v1.bankslips.download, {
      params,
      responseType: "blob",
    });
  },
  getSettlementPrepayment(contractNumber: string) {
    return client.get<SharkSettlementData>(
      sharkEndpoints.v1.prepayments.getSettlement(contractNumber),
    );
  },
  simulateSettlementPrepayment(
    contractNumber: string,
    body: SimulateSettlementPrepaymentBody,
  ) {
    return client.post(
      sharkEndpoints.v1.prepayments.simulateSettlement(contractNumber),
      body,
    );
  },
  confirmSettlementPrepayment(
    contractNumber: string,
    body: ConfirmSettlementPrepaymentBody,
  ) {
    return client.post(
      sharkEndpoints.v1.prepayments.confirmSettlement(contractNumber),
      body,
    );
  },
};
