import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { useAddPartner } from "./add-partner.hook";

export const AddPartner = () => {
  const { form, loading, submit } = useAddPartner();

  return (
    <>
      <PageTitle title="Adicionar parceiro" />

      <FormTemplate
        actions={
          <LoadingButton
            dataAction="partner_configuration_scf:botao:enviar"
            dataLabel="enviar"
            onClick={submit}
            loading={loading}
          >
            Enviar
          </LoadingButton>
        }
      >
        <OCard>
          <OCardBody>
            <FormProvider {...form}>
              {loading && <OLoader absolute backdrop />}
              <ORow>
                <OCol xs={6}>
                  <ORFieldInput
                    tag="text"
                    id="authClientId"
                    name="authClientId"
                    label="ClientId"
                  />
                </OCol>
                <OCol xs={6}>
                  <ORFieldInput tag="text" id="name" name="name" label="Nome" />
                </OCol>
                <OCol xs={6}>
                  <ORFieldInput
                    tag="cpfcnpj"
                    id="taxId"
                    name="taxId"
                    label="CNPJ/CPF"
                  />
                </OCol>
                <OCol xs={6} className="d-flex flex-row align-items-center">
                  <ORFieldCheckboxGroup id="isInternal" name="isInternal">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox
                        size="xs"
                        id="isInternalCheckbox"
                        value="true"
                      />
                      <OLabel
                        htmlFor="isInternalCheckbox"
                        id="isInternalCheckboxLabel"
                      >
                        Parceiro é interno
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
              </ORow>
            </FormProvider>
          </OCardBody>
        </OCard>
      </FormTemplate>
    </>
  );
};
