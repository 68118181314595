import { masks } from "@maestro/utils";
import { PixKeyStatus, PixKeyType, PixKeysGridColumn } from "./pix-keys.type";

export const MapperPixKeyStatus: Record<
  PixKeyStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [PixKeyStatus.Registered]: {
    type: "success",
    label: "Registrado",
  },
  [PixKeyStatus.Deleting]: {
    type: "danger",
    label: "Em exclusão",
  },
  [PixKeyStatus.ClaimedPortability]: {
    type: "info",
    label: "Portabilidade para outro banco",
  },
  [PixKeyStatus.ClaimedPortabilityRequest]: {
    type: "info",
    label: "Portabilidade para o BTG",
  },
  [PixKeyStatus.ClaimedOwnership]: {
    type: "info",
    label: "Reivindicação para outro banco",
  },
  [PixKeyStatus.ClaimedOwnershipRequest]: {
    type: "info",
    label: "Reivindicação para o BTG",
  },
  [PixKeyStatus.CompleteOwnershipRequest]: {
    type: "warning",
    label: "Pendente",
  },
};

export const MapperPixKeyType: Record<PixKeyType, string> = {
  [PixKeyType.CNPJ]: "CNPJ",
  [PixKeyType.CPF]: "CPF",
  [PixKeyType.EMAIL]: "E-mail",
  [PixKeyType.EVP]: "Chave aleatória",
  [PixKeyType.PHONE]: "Celular",
};

export const MapperPixKeysGridColumnWidth: Record<PixKeysGridColumn, string> = {
  [PixKeysGridColumn.KeyType]: "20%",
  [PixKeysGridColumn.KeyValue]: "40%",
  [PixKeysGridColumn.AgencyId]: "10%",
  [PixKeysGridColumn.AccountNumber]: "15%",
  [PixKeysGridColumn.Status]: "25%",
};

export const MapperPixKeyTypeToFormattedKeyValue: Record<
  PixKeyType,
  (keyValue: string) => string
> = {
  [PixKeyType.CNPJ]: (keyValue: string) => masks.cnpj(keyValue),
  [PixKeyType.CPF]: (keyValue: string) => masks.cpf(keyValue),
  [PixKeyType.EMAIL]: (keyValue: string) => keyValue,
  [PixKeyType.EVP]: (keyValue: string) => keyValue,
  [PixKeyType.PHONE]: (keyValue: string) =>
    masks.phone(keyValue).split("+55 ")[1],
};

export const formatPixKeyToCopy = (keyValue: string, keyType: PixKeyType) => {
  return keyType === PixKeyType.PHONE
    ? keyValue.replace(/\W/g, "").replace("55", "")
    : keyValue;
};
