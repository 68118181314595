import { useCallback } from "react";
import { PaymentType } from "services/api/models/types/payments/enums";
import { FinancialInstitutionUtils } from "utils/financial-institution";
import {
  DateFormatType,
  DateUtils,
  DetailsLine,
  MapperPaymentTypeGroup,
  MapperPaymentTypeToPaymentTypeGroup,
  PaymentUtils,
} from "../_compose";
import { usePaymentsAndTransfersContext } from "../payments-and-transfers.context";
import {
  loadBankingDetails,
  loadBankslipDetails,
  loadCreditCardInvoicDetails,
  loadDarfDetails,
  loadPixKeyDetails,
  loadPixQrCodeDetails,
  loadUtilitiesDetails,
} from "./payments-and-transfers-details.utils";

type PaymentDetailWithCreditPartyInfo =
  | ApiPayments.PixKeyDetails
  | ApiPayments.PixQrCodeDetails
  | ApiPayments.PixReversalDetails
  | ApiPayments.PixManualDetails
  | ApiPayments.TedDetails
  | ApiPayments.TefDetails;

export const usePaymentsAndTransfersDetails = () => {
  const { financialInstitutions } = usePaymentsAndTransfersContext();

  const groupAndMapPaymentType = (type: ApiPayments.PaymentType): string => {
    const typeGroup = MapperPaymentTypeToPaymentTypeGroup[type];
    return MapperPaymentTypeGroup[typeGroup];
  };

  const loadFinancialInstitutionName = useCallback(
    (data: PaymentDetailWithCreditPartyInfo) => {
      const financialInstitutionName =
        FinancialInstitutionUtils.loadFinancialInstitutionName(
          financialInstitutions,
          [data.creditParty?.account.bankCode, data.creditParty?.account.ispb],
          "shortName",
          true,
        );

      return financialInstitutionName;
    },
    [financialInstitutions],
  );

  const assembleDetailLines = (
    paymentData: ApiPayments.PaymentData,
  ): DetailsLine[] => {
    const paymentDate = DateUtils.formatDate(
      DateFormatType.ViewFormat,
      paymentData.paymentDate,
    );
    const operationType = groupAndMapPaymentType(paymentData.type);
    const { description } = paymentData;

    const items = [];

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.BankslipDetails>(
        paymentData,
        PaymentType.Bankslip,
      )
    ) {
      items.push(...loadBankslipDetails(paymentData.detail));
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.PixKeyDetails>(
        paymentData,
        PaymentType.PixKey,
      )
    ) {
      items.push(
        ...loadPixKeyDetails(
          paymentData.detail,
          loadFinancialInstitutionName(paymentData.detail),
        ),
      );
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.PixQrCodeDetails>(
        paymentData,
        PaymentType.PixQrCode,
      )
    ) {
      items.push(
        ...loadPixQrCodeDetails(
          paymentData.detail,
          loadFinancialInstitutionName(paymentData.detail),
        ),
      );
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.PixReversalDetails>(
        paymentData,
        PaymentType.PixReversal,
      ) ||
      PaymentUtils.validatePaymentFromType<ApiPayments.PixManualDetails>(
        paymentData,
        PaymentType.PixManual,
      ) ||
      PaymentUtils.validatePaymentFromType<ApiPayments.TedDetails>(
        paymentData,
        PaymentType.Ted,
      ) ||
      PaymentUtils.validatePaymentFromType<ApiPayments.TefDetails>(
        paymentData,
        PaymentType.Tef,
      )
    ) {
      items.push(
        ...loadBankingDetails(
          paymentData.detail,
          loadFinancialInstitutionName(paymentData.detail),
        ),
      );
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.DarfDetails>(
        paymentData,
        PaymentType.Darf,
      )
    ) {
      items.push(...loadDarfDetails(paymentData.detail));
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.UtilitiesDetails>(
        paymentData,
        PaymentType.Utilities,
      )
    ) {
      items.push(...loadUtilitiesDetails(paymentData.detail));
    }

    if (
      PaymentUtils.validatePaymentFromType<ApiPayments.CreditCardInvoiceDetails>(
        paymentData,
        PaymentType.CreditCardInvoice,
      )
    ) {
      items.push(...loadCreditCardInvoicDetails());
    }

    items.push(
      ...[
        {
          title: "Data do pagamento",
          content: paymentDate,
        },
        {
          title: "Tipo de operação",
          content: operationType,
        },
      ],
    );

    items.push({
      title: "Descrição",
      content: description,
    });

    const detailLines = items.reduce<DetailsLine[]>((data, item, index) => {
      if (index % 2 === 0) data.push([item]);
      else data[data.length - 1].push(item);
      return data;
    }, []);

    return detailLines;
  };

  return {
    assembleDetailLines,
  };
};
