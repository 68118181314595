import {
  OCard,
  OCardBody,
  OCol,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";

interface OperationAmountProps {
  amount: number;
}

export const OperationAmount = ({ amount }: OperationAmountProps) => {
  return (
    <OCard type="info-light">
      <OCardBody>
        <ORow align="center" justify="start">
          <OCol className="order-0 gap-3" justify="start">
            <OIcon category="orq" icon="orq-bank-slip" size="lg" type="dark" />
            <OTypography type="dark" size="lg">
              Total da transferência
            </OTypography>
          </OCol>
          <OCol className="order-1" justify="end" align="center">
            {masks.currency(amount, "R$")}
          </OCol>
        </ORow>
      </OCardBody>
    </OCard>
  );
};
