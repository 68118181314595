import { client } from "../../client";
import { hubloanEndpoints } from "../hubloan.endpoints";
import { GetApiAdminLoanByIdDetailsResponse } from "../models";
import { GetAdminCustomerHistoryResponse } from "./models";

export const hubLoanAdminCustomerService = {
  getHistory() {
    return client.get<GetAdminCustomerHistoryResponse>(
      hubloanEndpoints.admin.customer.history,
    );
  },
  getContract(contractId: string) {
    return client.get<BlobPart>(
      hubloanEndpoints.admin.customer.contractByIdDownlod(contractId),
      {
        responseType: "blob",
      },
    );
  },
  getLoanDetails(loanId: string) {
    return client.get<GetApiAdminLoanByIdDetailsResponse>(
      hubloanEndpoints.admin.customer.loanById(loanId),
    );
  },
};
