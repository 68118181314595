import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { Collaterals } from "../../collaterals.types";
import { sacasPropertiesGrid } from "./sacas-properties.grid";

const cultureTypeMap = {
  Soy: "Soja",
  Corn: "Milho",
  SugarCane: "Cana-de-açúcar",
  Cotton: "Algodão",
  CoffeeArabica: "Café Arábica",
  CoffeeConilon: "Café Conilon",
  CoffeeType6: "Café (não-especificado)",
  CoffeeType6_7: "Café (não-especificado)",
  CoffeeType7: "Café (não-especificado)",
  Other: "Outro",
};

export const agroAdditionalDataDetailsGenerator = (
  collateral: Collaterals[number] | undefined,
  goal: string | null,
) => {
  return {
    sacas: {
      title: "",
      items: [
        {
          label: "Tipo de cultura",
          value:
            getValueFromMap(cultureTypeMap, collateral?.identification) ??
            cultureTypeMap.Other,
          grid: { md: 3 },
        },
        {
          label: "Objetivo",
          value: goal,
          grid: { md: 3 },
        },
        {
          label: "Quantidade",
          value: masks.currency(collateral?.quantity, "", ".", ",", 0),
          grid: { md: 3 },
        },
        {
          label: "Valor unitário",
          value: masks.currency(collateral?.unitPrice, currencySymbol.BRL),
          grid: { md: 3 },
        },
        {
          label: "Peso unitário",
          value: `${collateral?.weight ?? "-"} ${
            collateral?.weightUnit ?? "-"
          }`,
          grid: { md: 3 },
        },
        {
          label: "Data de apuração do preço",
          value: dayjs(collateral?.priceDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY",
          ),
          grid: { md: 3 },
        },
      ],
    },
    properties: {
      title: "Propriedades",
      items: [
        {
          value: (
            <ODataGridGenerator
              grid={sacasPropertiesGrid}
              dataSource={collateral?.properties}
            />
          ),
        },
      ],
    },
  } satisfies DetailsFields;
};
