import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { DeskNotificationVariablesMasterDetail } from "./desk-notification-variables.master-detail";

export type DeskNotification = Awaited<
  ReturnType<typeof service.onboarding.getDeskNotifications>
>["data"][number];

export const buildDeskNotificationsGrid = (configIdentifier: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma notificação",
      dataSource: dataSourceCustomStoreGenerator(
        () =>
          service.onboarding
            .getDeskNotifications({
              deskOnboardingConfigIdentifier: configIdentifier,
            })
            .then(({ data }) => data ?? [])
            .catch(() => {
              const validator = "Erro ao buscar as notificações";

              OToastManager.danger(validator);
              throw new Error(validator);
            }),
        {
          customStoreOptions: {
            insert: async (values) => {
              try {
                await service.onboarding.registerDeskNotification({
                  deskOnboardingConfigIdentifier: configIdentifier,
                  notificationType: values.notificationType,
                });

                OToastManager.success("Notificação adicionada com sucesso");

                return values;
              } catch (error) {
                OToastManager.danger("Um erro ocorreu ao tentar adicionar.");
                throw new Error("Um erro ocorreu ao tentar adicionar.");
              }
            },
            update: async (originalData, updatedData) => {
              const data = { ...originalData, ...updatedData };

              try {
                if (!data.uuid) throw new Error("No uuid");
                await service.onboarding.updateDeskNotification({
                  deskNotificationConfigUuid: data.uuid,
                  notificationType: data.notificationType,
                });

                OToastManager.success("Notificação atualizada com sucesso.");
              } catch (error) {
                OToastManager.danger("Erro ao atualizar notificação");
              }
            },
            remove: async (data) => {
              try {
                if (!data.uuid) throw new Error("No uuid");

                await service.onboarding.removeDeskNotification(data.uuid);

                OToastManager.success("Notificação removida com sucesso.");
              } catch (error) {
                OToastManager.danger("Erro ao remover notificação");
              }
            },
          },
        },
      ),
    },
    columns: [
      {
        caption: "Configuração",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{`${data.deskOnboardingConfig?.configIdentifier} - ${data.deskOnboardingConfig?.configName}`}</OTypography>
            <OTypography type="default">
              {data.deskOnboardingConfig?.configDescription}
            </OTypography>
          </div>
        ),
        dataField: "deskOnboardingConfig.configIdentifier",
        allowEditing: true,
        editCellRender: () => configIdentifier,
      },
      {
        dataField: "notificationType",
        caption: "Tipo de notificação",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: ["APP", "EMAIL", "EVENTO"],
        },
      },
      {
        dataField: "uuid",
        caption: "Uuid",
        allowEditing: false,
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex, row }) =>
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  label: "Editar",
                  onClick: () => {
                    component.editRow(rowIndex);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Remover",
                  onClick: () => {
                    component.option(
                      "editing.texts.confirmDeleteMessage",
                      `Você confirma que a notificação '${data?.uuid}' será removida?`,
                    );
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          ),
      },
      {
        type: "buttons",
        buttons: [
          {
            hint: "Salvar",
            icon: "save",
            name: "save",
          },
          {
            hint: "Cancelar",
            icon: "undo",
            name: "cancel",
          },
        ],
      },
    ],
    masterDetail: {
      enabled: true,
      component: DeskNotificationVariablesMasterDetail,
    },
  } satisfies ODataGridGeneratorConfig<DeskNotification>);
