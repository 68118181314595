import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useEstrategiaSelector = (companyId: string | number) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    callService,
    loading,
    value: estrategias,
  } = useServiceCall(service.quickfin.getCompanyEstrategias);

  const getCompanyEstrategias = useCallback(async () => {
    const { success } = await callService(companyId);
    if (success) setErrorMessage(undefined);
    else
      setErrorMessage(
        "Erro ao buscar as estratégias. Clique no botão ao lado para tentar novamente.",
      );
  }, [callService, companyId]);

  useEffect(() => {
    getCompanyEstrategias();
  }, [getCompanyEstrategias]);

  return {
    errorMessage,
    estrategias,
    getCompanyEstrategias,
    loading,
  };
};
