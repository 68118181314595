import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";

export const loanLogsGrid = {
  datagrid: {
    noDataText: "Nenhum log encontrado.",
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar logs.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubLoan.odata.failedRequests,
    ),
  },
  columns: [
    {
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.logsDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.emprestimos.product.logs.details.path({
            id: data.id,
          })}
        />
      ),
    },
    {
      dataField: "id",
      caption: "Id",
    },
    {
      sortOrder: "desc",
      caption: "Data de criação",
      dataType: "datetime",
      dataField: "createdDate",
    },
    {
      caption: "Nome do Comando",
      dataField: "commandName",
    },
    {
      caption: "Requisição",
      dataField: "request",
      width: 600,
    },
    {
      caption: "Detalhes do Erro",
      dataField: "exceptionDetails",
      width: 1200,
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.odata.getFailedRequests>
    >["data"]["value"]
  >[number]
>;
