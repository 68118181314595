import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  {
    value: "TaxaParticularFinal",
    label: "Taxa final",
  },

  {
    value: "TaxaParticularAdicional",
    label: "Taxa adicional",
  },
];

export const TipoTaxaParticularField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:configuracao_taxa"
        dataLabel="configuracao_taxa"
        id="tipoTaxaParticular"
        name="tipoTaxaParticular"
        label={EstrategiaFormLabels.tipoTaxaParticular}
        description="Configuração para definir se as taxas cadastradas pelo Sacado serão taxas finais ou taxas adicionais à taxa mensal BTG"
        placeholder="Selecionar"
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
