import { OCard, OCardBody } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { CustomOptions, ReportsConfig, SortingOptions } from "./_compose";
import { useBatchRequestForReports } from "./request-for-reports.hook";

export const BatchRequestForReports = () => {
  const { form, loading, watchReportType, submit } =
    useBatchRequestForReports();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Relatórios em lote das antecipações"
          description="Escolha como gerar relatórios em lote das antecipações ou confira os relatórios já solicitados."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="relatorios_antecipacoes:botao:gerar_arquivo"
          dataLabel="gerar_arquivo"
          onClick={submit}
        >
          Gerar arquivo
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <form>
          <div className="d-flex flex-column gap-4">
            <OCard>
              <OCardBody>
                <ReportsConfig isExcel={watchReportType === "Planilha"} />
              </OCardBody>
            </OCard>

            {watchReportType === "Planilha" && (
              <OCard>
                <OCardBody>
                  <SortingOptions />
                </OCardBody>
              </OCard>
            )}

            <OCard>
              <OCardBody>
                <CustomOptions isExcel={watchReportType === "Planilha"} />
              </OCardBody>
            </OCard>
          </div>
        </form>
      </FormProvider>
    </FormTemplate>
  );
};
