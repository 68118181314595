import {
  OCheckbox,
  ORFieldCheckboxGroup,
  ORFieldInputDate,
} from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { StatusSelector } from "./_compose";
import { checkboxValue } from "./step-details.form";

export const diligenceStepFields = (
  data: HubEnergy.DiligenceStepSimpleResponse,
) => {
  return {
    limit: {
      title: "Informações da etapa",
      items: [
        {
          label: "Status",
          grid: { md: 4, xl: 3 },
          value: <StatusSelector />,
        },
        {
          label: "Nº Pendências",
          grid: { md: 4, xl: 3 },
          value: data?.pendencyIds?.length,
        },
        {
          label: "Mostra Cliente",
          grid: { md: 4, xl: 6 },
          value: (
            <ORFieldCheckboxGroup id="isPublic" name="isPublic">
              <OCheckbox
                id="checkbox-isPublic"
                value={checkboxValue}
                size="sm"
              />
            </ORFieldCheckboxGroup>
          ),
        },
        {
          label: "Data Limite",
          grid: { md: 4, xl: 3 },
          value: (
            <ORFieldInputDate
              size="md"
              id="expirationDate"
              name="expirationDate"
            />
          ),
        },
        {
          label: "Data Criação",
          grid: { md: 4, xl: 3 },
          value:
            data?.createdDate &&
            dayjs(data.createdDate).format("DD/MM/YYYY HH:mm"),
        },
        {
          label: "Data Inicio",
          grid: { md: 4, xl: 3 },
          value:
            data?.startingDate &&
            dayjs(data.startingDate).format("DD/MM/YYYY HH:mm"),
        },
        {
          label: "Data Atualização",
          grid: { md: 4, xl: 3 },
          value:
            data?.updatedDate &&
            dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm"),
        },
      ],
    },
  } satisfies DetailsFields;
};
