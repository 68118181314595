import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { TooltipIcon } from "components/tooltip-icon";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import type { Options } from "devextreme/data/data_source";
import { endpoints } from "services/endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { GridActions } from "./_compose";
import { Contract } from "./contracts-grid.types";
import {
  contractTypeMap,
  statusDescriptionMap,
  statusMap,
} from "./contracts-grid.utils";
import { ContractsMasterDetail } from "./signatories.master-detail";

dayjs.extend(tz);

export const buildContractsDataSource = (filter: Options["filter"]) =>
  dataSourceODataStoreGenerator<Contract>(
    endpoints.paperclipv2.odata.contractAdminOData,
    {
      dataSourceOptions: {
        filter,
      },
    },
  );

export const buildContractsGrid = (
  isFullGrid: boolean,
): ODataGridGeneratorConfig<Contract> => ({
  datagrid: {
    noDataText: "Nenhum termo",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "Status",
      caption: "Status",
      cellRender: ({ data }) => (
        <span>
          {getValueFromMap(statusMap, data.Status)}{" "}
          <TooltipIcon
            content={
              getValueFromMap(statusDescriptionMap, data.Status) ?? data.Status
            }
          />
        </span>
      ),
    },
    {
      dataField: "ContractType",
      caption: "Tipo do contrato",
      cellRender: ({ value }) =>
        getValueFromMap(contractTypeMap, value) || value,
    },
    { dataField: "ExternalPartnerId", caption: "Origem", visible: isFullGrid },
    {
      dataField: "UUID",
      caption: "Referencia do Arquivo",
      allowFiltering: false,
    },
    {
      dataField: "CreatedAt",
      dataType: "date",
      caption: "Criado em",
      sortOrder: "desc",
      allowHeaderFiltering: false,
      cellRender: ({ value }) =>
        dayjs(value)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
    {
      dataField: "ChangedAt",
      dataType: "date",
      caption: "Atualizado em",
      allowHeaderFiltering: false,
      cellRender: ({ data }) =>
        dayjs(data.ChangedAt)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <GridActions
          contract={data}
          reload={() => component.getDataSource()?.reload?.()}
        />
      ),
      alignment: "center",
      visible: isFullGrid,
    },
  ],
  masterDetail: {
    enabled: true,
    component: ContractsMasterDetail,
  },
});
