import { GridConfig } from "../../details-card.types";

/**
 * Generates grid system classes from a configuration object
 * @example
 * const classes = makeGridClasses({ sm: 12, lg: 4 })
 * // const classes = "col col-sm-12 col-lg-4"
 */
export const makeGridClasses = (config: GridConfig = {}) => {
  // by default, xs breakpoint is full width
  // eslint-disable-next-line no-param-reassign
  config.xs = config.xs || 12;

  return Object.entries(config)
    .reduce((classes, [breakpoint, size]) => {
      const className = `col-${breakpoint}-${size}`;

      return classes + " " + className;
    }, "col")
    .replace("col-xs", "col"); // There are no col-xs-* classes
};
