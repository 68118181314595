/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import { logger } from "utils/logger";
import { clearSearchParam } from "utils/search-params/clear-search-param";
import { getSearchParams } from "utils/search-params/get-search-params";
import { Auth } from "../auth";
import { AuthCommand } from "../types/command";
import { AuthorizationSearchParams } from "../types/search-params";

export class GetTokenFromSearchParamsCommand implements AuthCommand {
  async execute(auth: Auth) {
    logger.debug("[auth]GetTokenFromSearchParamsCommand");

    const { authorization } = getSearchParams<AuthorizationSearchParams>();

    if (!authorization) throw new Error("No authorization in search params");

    auth.value = authorization;
    Auth.storage.set(authorization);

    clearSearchParam("authorization");
  }
}

export class GetTokenFromStorage implements AuthCommand {
  async execute(auth: Auth) {
    logger.debug("[auth]GetTokenFromStorage");

    const authorization = Auth.storage.get();

    if (!authorization) throw new Error("No authorization in storage");

    auth.value = authorization;
  }
}

export class RemoveToken implements AuthCommand {
  async execute(auth: Auth) {
    auth.value = "";
    Auth.storage.remove();
  }
}
