import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services/service";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const dataSource = dataSourceCustomStoreGenerator<ScfDevelopers.Officer>(
  () =>
    service.scfDevelopers
      .fetchOfficers()
      .then(({ data }) => ({
        data,
        totalCount: data.length,
      }))
      .catch(() => {
        const errorMessage = "Erro ao obter listagem de officers";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      update: async (original, updated) => {
        try {
          const payload = {
            officer: {
              ...original,
              ...updated,
            },
          };

          await service.scfDevelopers.upsertOfficer(payload);
          OToastManager.success("Parceiro atualizado com sucesso");
        } catch {
          OToastManager.danger("Falha ao atualizar parceiro.");
        }
      },
      remove: async ({ taxId }) => {
        try {
          if (!taxId) {
            throw new Error("Missing taxId");
          }

          const payload = {
            taxId,
          };

          await service.scfDevelopers.removeOfficer(payload);
          OToastManager.success("Parceiro excluído com sucesso");
        } catch (err) {
          const message = isAxiosError(err)
            ? err.response?.data.failures[0].errorMessage
            : "Falha ao excluir parceiro.";
          OToastManager.danger(message);
        }
      },
    },
  },
);

export const scfOfficersGrid: ODataGridGeneratorConfig<ScfDevelopers.Officer> =
  {
    datagrid: {
      noDataText: "Nenhum parceiro encontrado.",
      filterRow: {
        visible: true,
      },
      pager: {
        infoText: "Página {0} de {1} ({2} parceiros)",
      },
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        mode: "row",
        selectTextOnEditStart: true,
      },
    },
    columns: [
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "cge",
        caption: "authClientId",
      },
      {
        dataField: "taxId",
        caption: "TaxId",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
    ],
  };
