import { OTabs } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { customMatchRoutes } from "routes/routes.utils";
import { RenderTabsWithDropdown } from "./compose";
import { TabMenuContainer } from "./tab-menu.styles";
import { MenuTabs } from "./tab-menu.types";
import { findTabType } from "./tab-menu.utils";

interface SubMenuProps {
  tabs: MenuTabs;
}

export const TabMenu = ({ tabs }: SubMenuProps) => {
  const location = useLocation();
  const { hasRole } = useRoles();

  const tabType = findTabType(tabs, location.pathname, false);

  const menuTabs = useMemo(() => {
    const excludedTabs = ["Ferramentas"];
    return tabType
      ? tabs[tabType].filter(
          (tab) => !excludedTabs.includes(tab.name) && hasRole(tab.role),
        )
      : [];
  }, [hasRole, tabType, tabs]);

  const active = menuTabs.findIndex((tab) =>
    customMatchRoutes(tab.path, location.pathname, false),
  );

  return (
    <TabMenuContainer id="tabs-container">
      <OTabs>
        <RenderTabsWithDropdown activeTab={active ?? 0} tabs={menuTabs} />
      </OTabs>
    </TabMenuContainer>
  );
};
