import { OConfirmationModal, OLoader, OTypography } from "@maestro/react";
import { useArchiveBankslip } from "./archive-bankslip-modal.hook";
import { ArchiveBankslipModalId } from "./archive-bankslip-modal.types";

export const ArchiveBankslipModal = () => {
  const { archiveCollection, isLoading } = useArchiveBankslip();

  return (
    <>
      {isLoading && <OLoader absolute />}

      <OConfirmationModal
        modalId={ArchiveBankslipModalId}
        title="Arquivar boleto?"
        onConfirm={archiveCollection}
        confirmLabel="Arquivar boleto"
      >
        <OTypography type="dark">
          Ao prosseguir, o boleto será arquivado, não aparecendo mais na
          listagem de boletos para o cliente. Deseja continuar?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
