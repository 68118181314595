import { OToastManager } from "@maestro/core";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";

export const buildDetailsLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.guarantorsDetails.path({
    guarantorUuid: uuid,
  });

export const buildEditLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.guarantorsEmbedded.path({
    guarantorUuid: uuid,
  });

export const buildContingenciesLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.contingencies.path({
    guarantorUuid: uuid,
  });

export const resentInvite = async (uuid: string) => {
  const payload = {
    uuid,
  };
  try {
    await service.onboardingRelationships.resendGuarantorsInvite(payload);
  } catch {
    OToastManager.danger("Falha ao reenviar convite");
  }
};

export const guarantorTypesMap: Record<
  HubOnboardingRelationships.Guarantor.GuarantorType,
  string
> = {
  COMPLETO: "Completo",
  SIMPLES: "Simples",
};

export const documentTypesMap: Record<
  HubOnboardingRelationships.Guarantor.DocumentType,
  string
> = {
  INDEFINIDO: "Indefinido",
  CONTRATO_SOCIAL: "Contrato social",
  ESTATUTO_SOCIAL: "Estatuto social",
  REQUERIMENTO_EMPRESARIO: "Requerimento empresário",
  CONTRATO_DE_UNIAO_ESTAVEL: "Contrato de união estável",
  IDENTIDADE: "Identidade",
  PROCURACAO: "Procuração",
  ATA_ELEICAO: "Ata de eleição",
  QUADRO_SOCIETARIO: "Quadro societário",
  FICHA_CADASTRAL: "Ficha cadastral",
  CERTIFICADO_CONDICACAO_MEI: "Certificado MEI",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  COMPROVANTE_DE_RESIDENCIA_ELETRONICO: "Comprovante eletrônico de residência",
  CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
};

export const maritalStatusesMap: Record<
  HubOnboardingRelationships.Guarantor.MaritalStatusType,
  string
> = {
  INDEFINIDO: "Indefinido",
  UNIAO_ESTAVEL: "União estável",
  CASADO: "Casado",
  DIVORCIADO: "Divorciado",
  SEPARADO_JUDICIALMENTE: "Separado judicialmente",
  SOLTEIRO: "Solteiro",
  VIUVO: "Viúvo",
};

export const weddingRegimesMap: Record<
  HubOnboardingRelationships.Guarantor.WeddingRegimeType,
  string
> = {
  INDEFINIDO: "Indefinido",
  SEPARACAO_TOTAL: "Separação total de bens",
  COMUNHAO_PARCIAL: "Comunhão parcial",
  COMUNHAO_TOTAL: "Comunhão total",
};

export const biometryStatusesMap: Record<
  HubOnboardingRelationships.Guarantor.BiometryStatusType,
  string
> = {
  APROVADO: "Aprovado",
  PENDENTE: "Pendente",
};

export const portalStatusesMap: Record<
  HubOnboardingRelationships.Guarantor.PortalStatusType,
  string
> = {
  PENDENTE: "Pendente",
  PENDENTE_BIOMETRIA: "Pendente biometria",
  PENDENTE_ENVIO: "Pendente envio",
  EM_ANALISE: "Em análise",
  PENDENTE_AJUSTE: "Pendente ajuste",
  REPROVADO: "Reprovado",
  CONCLUIDO: "Concluído",
};
