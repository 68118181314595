import { ODataGridGeneratorConfig } from "components/data-grid";
import { roles } from "roles/roles";
import { FileResponse } from "services/onboarding-core-account/models";
import { AccountCloseDownloadFileButton } from "./account-close-download-file.component";

export interface FileResponseWithUuid extends FileResponse {
  uuid: string;
}

export const gridFiles = {
  datagrid: {
    noDataText: "Nenhum arquivo",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} arquivos)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "key",
      caption: "Key",
      allowHeaderFiltering: false,
    },
    {
      dataField: "generatedBy",
      caption: "Criado Por",
      allowHeaderFiltering: false,
    },
    {
      dataField: "createdAt",
      caption: "Criado Em",
      allowHeaderFiltering: false,
      dataType: "datetime",
      format: "shortDateShortTime",
      sortOrder: "desc",
    },
    {
      cellRender: ({ data }) => {
        return <AccountCloseDownloadFileButton filekey={data.key} />;
      },
      role: roles.cadastro.customer.accountCloseDetailedFileGeneratePage.role,
    },
  ],
  paging: {
    defaultPageSize: 10,
  },
} satisfies ODataGridGeneratorConfig<FileResponseWithUuid>;
