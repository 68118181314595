import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { RecebiveisClienteSacadosBySacadoIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { useGetCompanySacado } from "./get-company-sacado.hook";
import {
  sacadoDetailsDefaultValue,
  sacadoDetailsValidationSchema,
  sacadoEditDetailsValidationSchema,
} from "./sacado-details.form";
import { SacadoDetailsForm } from "./sacado-details.types";

export const useSacadoDetails = () => {
  const { sacadoId, strategyId } =
    useParams<RecebiveisClienteSacadosBySacadoIdRouteParams>();

  const navigate = useNavigate();

  const {
    customer: { quickfinId },
  } = useCustomer();

  const { sacado, error, getSacado, loading } = useGetCompanySacado(
    quickfinId,
    Number(sacadoId), // Will be NaN when adding a new sacado, but getSacado won't be called
    Number(strategyId),
  );

  const { callService: addCompanySacado, loading: addCompanySacadoLoading } =
    useServiceCall(service.quickfin.addCompanySacado);

  const { callService: editCompanySacado, loading: editCompanySacadoLoading } =
    useServiceCall(service.quickfin.editCompanySacado);

  const { callService: searchLegalEntity } = useServiceCall(
    service.onboarding.searchLegalEntity,
  );

  const form = useForm<SacadoDetailsForm>({
    defaultValues: sacadoDetailsDefaultValue,
    resolver:
      sacadoId == null
        ? yupResolver(sacadoDetailsValidationSchema)
        : yupResolver(sacadoEditDetailsValidationSchema),
  });

  const { handleSubmit, reset, setValue, watch } = form;

  const identificationWatcher = watch("identificacao");

  const editSacado = useMemo(
    () => (atualizaEstrategiaDosRecebiveisAntigos: boolean) =>
      handleSubmit(async (values) => {
        const { success } = await editCompanySacado(quickfinId, {
          sacadoId: Number(sacadoId),
          estrategiaId: values.estrategiaId,
          convenioGUID: values.estrategiaGUID,
          identificadorExterno: values.identificadorExterno,
          cge: values.cge,
          companyEntityType: values.companyEntityType,
          officialName: values.officialName,
          identificacao: values.identificacao,
          permiteOverrideEstrategia: values.permiteOverrideEstrategia === "yes",
          tipoContrato: values.tipoContrato,
          tipoProduto: values.tipoProduto,
          atualizaEstrategiaDosRecebiveisAntigos,
        });

        if (success) {
          OToastManager.success("Sacado editado com sucesso");
          navigate(corporateRouter.routes.recebiveis.customer.sacados.path);
        } else {
          OToastManager.danger("Erro ao editar o sacado");
        }
      }),
    [handleSubmit, editCompanySacado, quickfinId, sacadoId, navigate],
  );

  const addSacado = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addCompanySacado(quickfinId, {
          estrategiaGUID: values.estrategiaGUID,
          identificadorExterno: values.identificadorExterno,
          cge: values.cge,
          companyEntityType: values.companyEntityType,
          officialName: values.officialName,
          identificacao: values.identificacao,
          permiteOverrideEstrategia: values.permiteOverrideEstrategia === "yes",
          tipoContrato: values.tipoContrato,
          tipoProduto: values.tipoProduto,
          country: values.country,
        });

        if (success) {
          OToastManager.success("Sacado adicionado com sucesso");
          navigate(corporateRouter.routes.recebiveis.customer.sacados.path);
        } else {
          OToastManager.danger("Erro ao adicionar o sacado");
        }
      }),
    [addCompanySacado, handleSubmit, navigate, quickfinId],
  );

  useEffect(() => {
    if (sacadoId && strategyId) getSacado();
  }, [sacadoId, getSacado, strategyId]);

  /** Initial values */
  useEffect(() => {
    if (sacado) {
      reset({
        estrategiaId: sacado.estrategia.id,
        estrategiaGUID: sacado.estrategia.uniqueId,
        identificadorExterno: sacado.identificadorExterno,
        cge: sacado.cge,
        companyEntityType: sacado.companyEntityType,
        officialName: sacado.officialName,
        identificacao: sacado.identification,
        permiteOverrideEstrategia: sacado.permiteOverrideEstrategia
          ? "yes"
          : "no",
        tipoContrato: "SemContrato",
        tipoProduto: sacado.estrategia.tipoProduto,
        country: sacado.country,
      } as SacadoDetailsForm);
    }
  }, [sacado, reset]);

  useEffect(() => {
    if (validators.cpfCnpj(identificationWatcher)) {
      searchLegalEntity({ taxId: identificationWatcher }).then(
        ({ success, response }) => {
          if (success) {
            setValue("officialName", response.data.officialName);
          }
        },
      );
    }
  }, [identificationWatcher, searchLegalEntity, setValue]);

  return {
    addSacado,
    addCompanySacadoLoading,
    sacado,
    editSacado,
    editCompanySacadoLoading,
    error,
    form,
    getSacado,
    isCreating: sacadoId === undefined,
    loading,
  };
};
