import type { ODataGridGeneratorConfig } from "components/data-grid";

export const analysisLogTypeMap = {
  ANALISE_ENVIADA: "Envio de análise",
  ARQUIVO_ENVIADO: "Upload de arquivo",
  ARQUIVO_MOVIDO: "Movimentação de arquivo",
  ARQUIVO_REMOVIDO: "Remoção de arquivo",
  AUTOMACAO_EXECUTADA: "Execução de automação",
  PENDENCIA_AGUARDANDO_DISTRIBUIDORA: "Pendência aguardando distribuidora",
  PENDENCIA_CONCLUIDA: "Conclusão de pendência",
  PENDENCIA_CRIADA: "Criação de pendência",
  PENDENCIA_ESCALADA: "Escalamento de pendência",
  PENDENCIA_REINICIOU_ANALISE: "Reabertura de análise da pendência",
  PROPRIEDADE_EDITADA: "Edição de propriedade",
} satisfies Record<HubEnergy.EDiligenceStepAnalysisLogType, string>;

// grid export
const consumerUnitOfficialNameColumn = "consumerUnitOfficialNameColumn";
const consumerUnitTaxIdColumn = "consumerUnitTaxIdColumn";
const installationNumberColumn = "installationNumberColumn";

export const exportOnlyColumnNames = [
  consumerUnitOfficialNameColumn,
  consumerUnitTaxIdColumn,
  installationNumberColumn,
];

export const exportOnlyColumns = [
  {
    visible: false,
    dataField: "consumerUnitOfficialName",
    dataType: "string",
    name: consumerUnitOfficialNameColumn,
    caption: "Unidade consumidora - Razão social",
  },
  {
    visible: false,
    dataField: "consumerUnitTaxId",
    dataType: "string",
    name: consumerUnitTaxIdColumn,
    caption: "Unidade consumidora - CNPJ",
  },
  {
    visible: false,
    dataField: "consumerUnitInstallationNumber",
    dataType: "string",
    name: installationNumberColumn,
    caption: "Unidade consumidora - Número de instalação",
  },
] satisfies ODataGridGeneratorConfig<HubEnergy.DiligenceStepAnalysisLogODataResponse>["columns"];
