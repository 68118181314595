import {
  OConfirmationModal,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

interface ExcludeUserProps {
  removeUserTaxId: string;
}

export const ExcludeUserModal = ({ removeUserTaxId }: ExcludeUserProps) => {
  const navigate = useNavigate();
  const { callService } = useServiceCall(
    service.onboardingRelationships.excludeUser,
  );

  const excludeUser = useCallback(async () => {
    const { success } = await callService({ removeUserTaxId });

    if (success) {
      navigate(corporateRouter.routes.usuarios.product.b2c.path);
      OToastManager.success("Usuário excluído com sucesso");
    } else {
      OToastManager.danger("Erro ao excluir usuário");
    }
  }, [callService, removeUserTaxId]);

  return (
    <OConfirmationModal
      modalId="delete-user-modal"
      title="Tem certeza que deseja excluir todos os dados do usuário do portal?"
      confirmLabel="Excluir"
      closeLabel="Cancelar"
      divider={false}
      onConfirm={() => {
        excludeUser();
        modalManager.hide("delete-user-modal");
      }}
    >
      <OTypography>
        O usuário perderá acesso à todas as empresas que possuí e perderá todos
        os dados cadastrados no portal.
      </OTypography>
    </OConfirmationModal>
  );
};
