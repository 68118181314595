import { OOption, ORFieldSelect } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { GetAdminReceivableStateMachineByIdResponse } from "services/quickfin/models";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { options } from "./receivable.utils";

export const ReceivableDetailsGenerator = (
  receivable:
    | GetAdminReceivableStateMachineByIdResponse["receivable"]
    | undefined,
) => {
  if (!receivable) return {} as DetailsFields;

  return {
    receivable: {
      items: [
        {
          label: "Sacado",
          value: receivable?.sacado.officialName ?? "",
          grid: { md: 12, xl: 6 },
        },
        {
          label: "Cedente",
          value: receivable?.cedente.officialName ?? "",
          grid: { md: 12, xl: 6 },
        },
        {
          label: "Nota fiscal",
          value: receivable.identification ?? "",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: receivable.currency ?? "BRL",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor Vencimento",
          value: masks.currency(
            receivable.maturityAmount,
            getValueFromMap(currencySymbol, receivable.currency),
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data Vencimento",
          value: dayjs(receivable.maturityDate).format("DD/MM/YYYY"),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: (
            <ORFieldSelect
              dataAction="recebivel:select:status"
              dataLabel="status"
              id="status"
              name="status"
              placeholder="Selecione"
            >
              {options.map((option) => (
                <OOption key={option} value={option}>
                  {option}
                </OOption>
              ))}
            </ORFieldSelect>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Mensagem de rejeição",
          value: receivable.rejectMessage,
          visible: !!receivable.rejectMessage,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
