import { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { logger } from "utils/logger";

type UseParseFromSearchParamsReturn<T> =
  | { value: undefined; hasError: true }
  | { value: T; hasError: false };

export const useParseFromSearchParams = <T>(key: string) => {
  const hasErrorRef = useRef(false);
  const [searchParams] = useSearchParams();

  const value = useMemo(() => {
    const stringifiedValue = searchParams.get(key);
    if (!stringifiedValue) {
      hasErrorRef.current = true;
      return;
    }
    try {
      const parsedValue = JSON.parse(stringifiedValue);
      return parsedValue as T;
    } catch (err) {
      logger.error(
        `Unable to parse ${key} from queryString: ${stringifiedValue}`,
      );
      hasErrorRef.current = true;
    }
  }, [key, searchParams]);

  return {
    value,
    hasError: hasErrorRef.current,
  } as UseParseFromSearchParamsReturn<T>;
};
