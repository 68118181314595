import { Roles } from "roles/roles.types";

export const franquiasRoles = {
  product: {
    tab: {
      role: "admin:FranchiseTabProduct.View",
      description:
        "Visualização da tab de franquias no portal admin na visão produto",
    },
    franchise: {
      role: "admin:FranchisePageProduct.View",
      description: "Visualização da página 'Franquias' na visão produto",
    },
    addFranchise: {
      role: "admin:FranchiseAddPageProduct.View",
      description:
        "Visualização da página 'Franquias - Adicionar' na visão produto",
    },
    updateFranchise: {
      role: "admin:FranchiseUpdatePageProduct.View",
      description:
        "Visualização da página 'Franquias - Atualizar' na visão produto",
    },
    deleteFranchise: {
      role: "admin:FranchiseDeletePageProduct.View",
      description:
        "Visualização da página 'Franquias - Remover' na visão produto",
    },
  },
  customer: {
    tab: {
      role: "admin:FranchiseTabCustomer.View",
      description:
        "Visualização da tab de franquias no portal admin na visão customer",
    },
  },
} satisfies Roles;
