import { OLink, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { RavenForm } from "../_compose";
import { useRavenTemplatesAdd } from "./add.hook";

export const RavenTemplatesAdd = () => {
  const { submit, form, loading } = useRavenTemplatesAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Template" description="Adicione um novo template" />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="raven_template:botao:adicionar"
          dataLabel="adicionar"
          onClick={submit}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <OTypography tag="p" type="default" className="mb-3">
        Para mais detalhes, acesse a{" "}
        <OLink
          href="https://btgpactual.visualstudio.com/PME/_wiki/wikis/PME.wiki/15676/Cadastrando-templates-de-email"
          target="_blank"
        >
          documentação
        </OLink>
      </OTypography>

      <RavenForm form={form} />
    </FormTemplate>
  );
};
