import { OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useBatchTemplatePropertyField } from "./batch-template-field.hook";

export const BatchTemplatePropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, processorConfigId, name, description } =
    processorConfigProperty;
  const { allBatchTemplates } = useBatchTemplatePropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const formPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={allBatchTemplates
          ?.map((template) => template.identification)
          .join("")}
        id={`properties.${name}_${processorPropertyId}`}
        name={`properties.${name}_${processorPropertyId}`}
        placeholder="Selecione"
        labelSize="md"
        label={description}
      >
        {allBatchTemplates?.map((template) => (
          <OOption
            key={template.identification}
            value={template.identification}
          >
            {template.name}
          </OOption>
        ))}
      </ORFieldSelect>
    );
  }, [allBatchTemplates, processorPropertyId, processorConfigId]);

  return <>{formPropertyField}</>;
};
