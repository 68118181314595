import { customMatchRoutes } from "routes/routes.utils";
import { MenuTabs } from "./tab-menu.types";

export const findTabType = (tabs: MenuTabs, path: string, exact: boolean) =>
  (Object.keys(tabs) as (keyof MenuTabs)[]).find((type) =>
    tabs[type].some((tab) => customMatchRoutes(tab.path, path, exact)),
  );

export const findActiveTab = (
  tabs: MenuTabs[keyof MenuTabs],
  path: string,
  exact: boolean,
) => tabs.find((tab) => customMatchRoutes(tab.path, path, exact));
