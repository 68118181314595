import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  submitModalTypes,
  toastTypeOptions,
} from "./[id]/_compose/form-general-info/form-general-info.utils";

export const formsGrid = {
  datagrid: {
    noDataText: "Nenhum formulário encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceCustomStoreGenerator(
      () => {
        return service.hubCreditManager.getFormsList().catch(() => {
          throw new Error("Erro ao buscar formulários.");
        });
      },
      {
        customStoreOptions: {
          insert: async (values) => {
            try {
              await service.hubCreditManager.addForm(values);

              OToastManager.success("Formulário adicionado com sucesso.");
              return values;
            } catch {
              OToastManager.danger("Não foi possível adicionar o componente.");
              throw new Error("Não foi possível adicionar o formulário.");
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      cellRender: ({ row }) =>
        row.data.id &&
        !row.isEditing && (
          <DetailsLink
            href={corporateRouter.routes.workflow.product.formsManager.forms.details.path(
              {
                id: row.data.id,
              },
            )}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      sortOrder: "asc",
    },
    {
      dataField: "identification",
      caption: "Identificação",
      allowEditing: false,
    },
    {
      dataField: "name",
      caption: "Nome",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "submitToastType",
      caption: "Tipo de mensagem de sucesso",
      lookup: {
        dataSource: toastTypeOptions,
        displayExpr: "text",
        valueExpr: "value",
      },
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "submitToastMessage",
      caption: "Mensagem de sucesso",
    },
    {
      dataField: "submitModalType",
      caption: "Tipo de modal ao enviar",
      lookup: {
        dataSource: submitModalTypes,
        displayExpr: "text",
        valueExpr: "value",
      },
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "readonly",
      caption: "Somente leitura",
      dataType: "boolean",
    },
    {
      dataField: "hideSummary",
      caption: "Esconde o Resumo",
      dataType: "boolean",
    },
    {
      dataField: "singleStepSubmit",
      caption: "Step único com submit",
      dataType: "boolean",
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubCreditManager.getFormsList>>["data"]
  >[number]
>;
