import { OCol, ORFieldInput } from "@maestro/react";
import { useMemo } from "react";
import { PercentOrValueInputProps } from "./percent-or-amount-input.types";
import { assembleFieldInputProps } from "./percent-or-amount-input.utils";

export const PercentOrAmountInput = ({
  fieldName,
  disabled,
  typeWatched,
}: PercentOrValueInputProps) => {
  const fieldInputProps = useMemo(
    () => assembleFieldInputProps(fieldName, typeWatched),
    [fieldName, typeWatched],
  );

  return (
    <OCol>
      <ORFieldInput {...fieldInputProps} disabled={disabled} />
    </OCol>
  );
};
