import { OLoader } from "@maestro/react";
import React from "react";
import { useAuthorization } from "contexts/auth";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { ErrorComponent } from "components/empty-state";

interface AuthorizationGuardProps {
  children: React.ReactNode;
}

/**
 * Prevent children from rendering (and trying to make requests) while there is no authorization
 */
export const AuthorizationGuard = ({ children }: AuthorizationGuardProps) => {
  const { authorization } = useAuthorization();
  const { behavior } = useBehavior();

  if (!authorization && !window.location.pathname.startsWith("/acesso")) {
    if (behavior.id === Behaviors.Corban) {
      return (
        <div className="d-flex justify-content-center align-items-center p-5">
          <ErrorComponent messageTitle="Por motivos de segurança, sua sessão foi encerrada. Por favor, realize autenticação novamente pelo RM Admin." />
        </div>
      );
    }
    return <OLoader absolute data-component="AuthorizationGuard" />;
  }

  return children as JSX.Element;
};
