import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { ReceivablesItem } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const grid: ODataGridGeneratorConfig<ReceivablesItem> = {
  datagrid: {
    noDataText: "Nenhuma nota encontrada",
    paging: {
      enabled: false,
    },
  },
  columns: [
    {
      caption: "Receivable Id",
      dataField: "receivableId",
    },
    { caption: "TradeId", dataField: "tradeId" },
    {
      dataField: "receivableIdentification",
      caption: "Nº nota",
    },
    { dataField: "state", caption: "State" },
    { caption: "Sacado", dataField: "sacado" },
    {
      dataField: "sacadoIdentification",
      caption: "Identificação sacado",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Valor vencimento",
      format: "#,##0.00",
      dataType: "number",
      cellRender: ({ data }) => {
        const value = data.valorVencimentoTrade
          ? data.valorVencimentoTrade
          : data.valorVencimento;

        return <OTypography tag="span">{masks.currency(value)}</OTypography>;
      },
    },
    {
      dataField: "valorDesembolso",
      caption: "Valor desembolso",
      format: (value) => masks.currency(value),
    },
    {
      caption: "Rebate",
      dataField: "rebate",
      format: (value) => masks.currency(value),
    },
    {
      caption: "Data trade",
      dataField: "tradeDate",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Data desembolso",
      dataField: "dataDesembolso",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Data vencimento",
      dataField: "dataVencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Criado em",
      dataField: "createdAt",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      caption: "Atualizado em",
      dataField: "updatedAt",
      dataType: "datetime",
      format: "dd/MM/yyyy HH:mm:ss",
      sortOrder: "desc",
    },
    {
      caption: "Estratégia",
      dataField: "estrategiaComercialNome",
    },
    {
      caption: "Data repasse",
      dataField: "dataRepasse",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "borderoId",
      caption: "Identificador importação",
    },
    {
      dataField: "externalId",
      caption: "Identificador externo",
    },
    {
      dataField: "contractNumber",
      caption: "Nº contrato",
    },
    {
      dataField: "iof",
      caption: "IOF",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "charge",
      caption: "TCO",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "saldoDevedor",
      caption: "Saldo devedor",
      format: (value) => masks.currency(value),
    },
  ],
};
