import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PatchByIdAdminOrderBody } from "services/hubfx/models/requests/patch-by-id-admin-orders.request";
import { GetCommonCountriesResponse } from "services/hubfx/models/responses";
import { ChannelOdataOrdersValue } from "services/hubfx/odata/models/responses/get-admin-orders-o-data.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { orderFormValidationSchema } from "./exchange-product-orders-form.schemas";
import { ORFieldInputs } from "./exchange-product-orders-form.utils";

export const ExchangeProductContingencyOrdersFormPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<GetCommonCountriesResponse>();

  const { value: order } =
    useParseFromSearchParams<ChannelOdataOrdersValue>("data");

  const form = useForm<PatchByIdAdminOrderBody>({
    resolver: yupResolver(orderFormValidationSchema),
    defaultValues: { ...order },
  });

  const { handleSubmit } = form;

  const onEdit = useCallback(
    async (values: PatchByIdAdminOrderBody & { id: number }) => {
      try {
        setLoading(true);

        await service.hubFx.patchByIdOrder(values);

        OToastManager.success("Ordem atualizada com sucesso.");

        navigate(corporateRouter.routes.cambio.product.contingency.orders.path);
      } catch {
        OToastManager.danger("Erro ao atualizar ordem.");
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        if (order?.id) {
          onEdit({ ...formValues, id: order.id });
        }
      }),
    [order, handleSubmit, onEdit],
  );

  const getCountries = useCallback(async () => {
    const { data: response } = await service.hubFx.getCountries();

    if (!response) return;

    setCountries(response);
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar ordem" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          {ORFieldInputs.map((item) => (
            <OCol key={`${item.id}-col`}>
              <ORFieldInput
                symbol={String(order?.currency)}
                tag={item.mask ? "currency" : "text"}
                {...item}
              />
            </OCol>
          ))}

          {countries && (
            <OCol>
              <ORFieldSelect label="País" name="country" id="country">
                {countries?.map((country) => (
                  <OOption key={country.code} value={String(country.code)}>
                    {country.description}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}

          <ORFieldRadioGroup
            label="Ativo"
            name="isActive"
            id="isActive"
            required
          >
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="sim" value="true" />
              Sim
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="nao" value="false" />
              Não
            </div>
          </ORFieldRadioGroup>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
