import { OField, TooltipContainer } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { SelectSearch } from "../select-search.component";
import { useSelectSearchField } from "./select-search-field.hook";
import { SelectSearchFieldProps } from "./select-search-field.types";

export const SelectSearchField = <T extends string | number>(
  props: SelectSearchFieldProps<T>,
) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    options: originalOptions,
    onClear,
    value,
    ...rest
  } = props;

  const [selectSearchLabel, setSelectSearchLabel] = useState<string>();

  const { form, options } = useSelectSearchField(name, originalOptions);

  const { formState, getFieldState, setValue, watch } = form;

  const valueWatcher = watch(name);

  const { error: fieldErrors } = getFieldState(name, formState);

  const handleClear = useCallback(() => {
    setValue(name, null);
    onClear?.();
  }, [name, onClear, setValue]);

  useEffect(() => {
    const initialLabel = options.find(
      (option) => option.value === valueWatcher,
    )?.label;

    setSelectSearchLabel(initialLabel ?? value);
  }, [options, value, valueWatcher]);

  return (
    <OField
      htmlFor={rest.id}
      error={!!fieldErrors}
      message={fieldErrors?.message}
      description={description}
      label={label}
      labelType={labelType}
      required={required}
      disabled={rest.disabled}
      labelSize={rest.labelSize ?? "sm"}
    >
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}
      <SelectSearch
        {...props}
        value={selectSearchLabel}
        onClear={handleClear}
        name={`${name}-search`}
        options={options}
        eager={false}
        className="w-100"
      />
    </OField>
  );
};
