import { ODataGridGeneratorConfig } from "components/data-grid";

export const grid: ODataGridGeneratorConfig<HubOnboarding.Onboarding.AMLResponseView> =
  {
    datagrid: {
      noDataText: "Nenhuma reprovação encontrada",
    },
    columns: [
      { dataField: "TaxId", caption: "CNPJ/CPF" },
      { dataField: "Status", caption: "Status" },
      { dataField: "DelfosJustification", caption: "Justificativa" },
      { dataField: "RequestId", caption: "Id do workflow" },
      { dataField: "Type", caption: "Tipo do workflow" },
      { dataField: "AnalyzedBy", caption: "Responsável" },
      { dataField: "DelfosStatus", caption: "Status no Delfos" },
      {
        dataField: "CreatedDate",
        caption: "Data de análise",
        dataType: "date",
        format: "shortDateShortTime",
        alignment: "center",
        sortOrder: "desc",
      },
    ],
  };
