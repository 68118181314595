import { ORadioGroup, OTypography } from "@maestro/react";
import { exportationTypes } from "../../filter/filters";
import { ExportRadio } from "./items/export-radio.component";

export const ExportOptions = () => {
  return (
    <>
      <OTypography className="mb-2 mt-24" weight="lighter">
        Antes de baixar o extrato, escolha o formato e o período do arquivo.
      </OTypography>
      <OTypography className="mt-2 mb-2">
        Selecione o formato do arquivo
      </OTypography>
      <ORadioGroup
        className="d-flex flex-row justify-content-start gap-2 w-40"
        data-filterparam="extension"
        dataAction="statement-export-filter"
        dataLabel="statement-export-filter"
      >
        {exportationTypes.map((item) => {
          return (
            <ExportRadio radioName="extension" {...item} key={item.value} />
          );
        })}
      </ORadioGroup>
      <OTypography className="mb-2 mt-24" size="sm" weight="lighter">
        * A exportação do tipo "legacy ofx" é referente ao antigo formato de
        ofx, compatível com o software Conta Azul
      </OTypography>
    </>
  );
};
