import { FetchAddressByCepResponse } from "./models/responses/fetch-address-by-cep.response";
import { FetchCitiesByUFResponse } from "./models/responses/fetch-cities-by-uf.response";
import { BrazilUF } from "./models/types/brazil-uf.type";
import { publicEndpoints } from "./public.endpoints";

export const publicService = {
  getCitiesByUF: async (uf: BrazilUF) => {
    const res = await fetch(publicEndpoints.getCitiesByUF(uf.toUpperCase()));
    if (!res.ok) throw new Error("Falha ao buscar as cidades para a UF");
    return (await res.json()) as FetchCitiesByUFResponse;
  },
  getAddessByCEP: async (cep: string) => {
    const res = await fetch(
      publicEndpoints.getAddessByCEP(cep.replace(/\D/g, "")),
    );
    const resJson = await res.json();
    if (resJson.erro) throw new Error("Falha ao buscar endereço");
    return resJson as FetchAddressByCepResponse;
  },
};
