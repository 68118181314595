import { useRoles } from "hooks/roles";
import {
  buildRoutesTree,
  fillPathParams,
  findCurrentNode,
  getCurrentPathParams,
} from "layout/global-menu/_compose/routes-menu/routes-menu.utils";
import { useLocation } from "react-router-dom";
import { Routes } from "routes/routes.types";
import { findChildNodeWithRole } from "./redirect-with-roles.utils";

export const useRedirectWithRoles = (
  routes: Routes,
  customPathname?: string,
) => {
  const { hasRole } = useRoles();

  const location = useLocation();

  const tree = buildRoutesTree(routes);

  const currentNode = findCurrentNode(
    tree,
    customPathname ?? location.pathname,
  );

  if (!currentNode) return undefined;

  const path = findChildNodeWithRole(currentNode, hasRole)?.path;

  return (
    path &&
    fillPathParams(
      path,
      getCurrentPathParams(currentNode.path, location.pathname),
    )
  );
};
