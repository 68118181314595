import { masks } from "@maestro/utils";

export const percent4DigitsMask = (value: number | null) => {
  return masks.generic(((value ?? 0) * 100).toFixed(4), {
    mask: "num%",
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 4,
        padFractionalZeros: true,
      },
    },
  });
};
