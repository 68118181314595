import { StatusBadge } from "components/status-badge";

interface EntityOnboardingStatusBadgeProps {
  isEnergyOnboardingCompleted: boolean | null | undefined;
}

export const EntityOnboardingStatusBadge = ({
  isEnergyOnboardingCompleted,
}: EntityOnboardingStatusBadgeProps) => {
  return (
    <StatusBadge
      type={isEnergyOnboardingCompleted ? "success" : "warning"}
      className="d-inline-block"
    >
      {isEnergyOnboardingCompleted ? "Completo" : "Pendente"}
    </StatusBadge>
  );
};
