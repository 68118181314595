import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { PostCnabStatementReprocessFileBody } from "services/admin-bankinghub/models";
import {
  reprocessFileDefaulValues,
  reprocessFileValidationSchema,
} from "./reprocess-file.form";

export const useReprocessFile = () => {
  const [loading, setLoading] = useState(false);
  const [shouldSendVan, setShouldSendVan] = useState(false);
  const form = useForm({
    defaultValues: reprocessFileDefaulValues,
    resolver: yupResolver(reprocessFileValidationSchema),
  });
  const { getValues, handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    const startAt = dayjs(values.startAt).format("YYYY-MM-DD");
    const endAt = dayjs(values.endAt).format("YYYY-MM-DD");
    const body: PostCnabStatementReprocessFileBody = {
      agency: values.agency,
      account: values.account,
      startAt,
      endAt,
      shouldSendVan,
    };

    setLoading(true);
    try {
      await service.adminBankinghub.postCnabReprocessFile(body);
      OToastManager.success(
        "Reprocessamento iniciado com sucesso! Visualize o arquivo na página de histórico de arquivos",
      );
    } catch {
      OToastManager.danger("Não foi possível reprocessar o arquivo.");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    getValues,
    loading,
    onSubmit,
    setShouldSendVan,
    shouldSendVan,
  };
};
