import { useRoles } from "hooks/roles";
import { RoutesTreeNode } from "layout/global-menu/_compose/routes-menu/routes-menu.types";

/**
 * Flatten tree breadth first
 *
 * Contains only "surface-level" nodes (not children that are not sub items)
 * */
const flattenTree = (node: RoutesTreeNode) => {
  const flatTree: RoutesTreeNode[] = [node];

  const childrenWithoutSubItems = node.children.filter(
    (child) => !child.hasSubItems,
  );
  const childrenWithSubItems = node.children.filter(
    (child) => child.hasSubItems,
  );

  flatTree.push(...childrenWithoutSubItems);

  childrenWithSubItems.forEach((child) => {
    flatTree.push(...flattenTree(child));
  });

  return flatTree;
};

export const findChildNodeWithRole = (
  node: RoutesTreeNode,
  hasRole: ReturnType<typeof useRoles>["hasRole"],
) => {
  const flatTree = flattenTree(node).slice(1);

  return flatTree.find(
    (currentNode) => hasRole(currentNode.role) && currentNode.hasElement,
  );
};
