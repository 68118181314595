import { RouterButton } from "components/router-button";
import { corporateRouter } from "routes/corporate-router.context";

interface GoToTradeButtonProps {
  tradeId: number | undefined;
}

export const GoToTradeButton = ({ tradeId }: GoToTradeButtonProps) => {
  if (tradeId === undefined) return null;

  return (
    <RouterButton
      href={corporateRouter.routes.energia.product.trades.byId.details.path({
        tradeId,
      })}
      // role
    >
      Ver proposta
    </RouterButton>
  );
};
