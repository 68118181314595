import { IconButton } from "components/icon-button";
import type DataGrid from "devextreme-react/data-grid";

interface ClearSelectionButtonProps {
  gridRef: React.RefObject<DataGrid>;
}

export const ClearSelectionButton = ({
  gridRef,
}: ClearSelectionButtonProps) => (
  <IconButton
    dataAction="entuba_listagem:botao:limpar_selecao"
    dataLabel="limpar_selecao"
    icon={{ category: "far", icon: "fa-check-square" }}
    onClick={() => gridRef.current?.instance.clearSelection()}
  >
    Limpar seleção
  </IconButton>
);
