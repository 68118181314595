import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";
import { ReceivableWithError } from "./result.types";
import { errorMap } from "./result.utils";

export const resultGrid: ODataGridGeneratorConfig<ReceivableWithError> = {
  datagrid: {
    noDataText: "Nenhum recebível.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    paging: {
      pageSize: 20,
    },
  },
  columns: [
    {
      dataField: "ReceivableId",
      dataType: "string",
      caption: "ID do recebível",
      allowHeaderFiltering: false,
    },
    {
      dataField: "Sacado",
      dataType: "string",
      caption: "Sacado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "SacadoIdentification",
      dataType: "string",
      caption: "CNPJ do Sacado",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Cedente",
      dataType: "string",
      caption: "Cedente",
      allowHeaderFiltering: false,
    },
    {
      dataField: "CedenteIdentification",
      dataType: "string",
      caption: "CNPJ do Cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Currency",
      dataType: "string",
      caption: "Moeda",
      alignment: "center",
    },
    {
      dataField: "Bandeira",
      dataType: "string",
      caption: "Bandeira",
      alignment: "center",
      cellRender: ({ data }) =>
        getValueFromMap(cardIconMap, data.Bandeira) || data.Bandeira,
    },
    {
      dataField: "DataVencimento",
      caption: "Data de vencimento",
      dataType: "date",
      format: "shortDate",
      alignment: "center",
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorVencimento",
      caption: "Valor bruto",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorSolicitado",
      caption: "Valor solicitado",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorDesembolso",
      caption: "Valor líquido",
      format: (value) => masks.currency(value, "R$"),
      allowFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "DataDesembolso",
      caption: "Data de desembolso",
      dataType: "date",
      format: "shortDate",
      alignment: "center",
      allowHeaderFiltering: false,
    },
    {
      dataField: "error",
      caption: "Erro",
      customizeText: ({ value }) =>
        getValueFromMap(errorMap, value) ?? value ?? "---",
      allowFiltering: false,
      allowSorting: false,
    },
  ],
};
