import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { getValueFromMap } from "utils/get-value-from-map";
import { operationStatusLibrary } from "../trades/operations.utils";
import { AssignmentsODataValueItem } from "services/quickfin/models/responses/get-odata-assignments-o-data.response";
import { endpoints } from "services/endpoints";

export const grid: ODataGridGeneratorConfig<AssignmentsODataValueItem> = {
  datagrid: {
    noDataText: "Nenhuma cessão",
    dataSource: dataSourceODataStoreGenerator<AssignmentsODataValueItem>(
      endpoints.quickfin.odata.assignmentsOData,
    ),
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      role: roles.recebiveis.product.operationsDetalhesPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.recebiveis.product.operations.assignments.detalhes.path(
            {
              companyId: data.CompanyId,
              operationId: data.Id,
            },
          )}
        />
      ),
    },
    { dataField: "Id", caption: "Id" },
    { dataField: "CedenteName", caption: "Cedente" },
    { dataField: "CedenteIdentification", caption: "CNPJ/CPF" },
    {
      dataField: "Status",
      caption: "Situação",
      calculateCellValue: ({ Status }) =>
        getValueFromMap(operationStatusLibrary, Status) ?? Status,
      calculateFilterExpression: (filterValue, selectedFilterOperation) => {
        const key = (Object.keys(operationStatusLibrary) as Array<string>).find(
          (key) => operationStatusLibrary[key] === filterValue,
        );
        return ["Status", selectedFilterOperation, key];
      },
    },
    {
      dataField: "DisbursementAmount",
      caption: "Valor desembolso",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
    },
    { dataField: "Portfolio", caption: "Carteira" },
    { dataField: "AssetsQuantity", caption: "Quantidade assets" },
    { dataField: "ExternalId", caption: "External Id" },
    { dataField: "AssignmentId", caption: "Assignment Id" },
    { dataField: "ContractNumber", caption: "Nº contrato" },
  ],
};
