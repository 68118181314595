import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { AntecipacaoAFornecedorClienteRecebiveisBySacadoIdRouteParams } from "../../../../routes/antecipacao-a-fornecedor.route-params";
import { grid } from "./available-receivables.grid";

export const AvailableReceivables = () => {
  const { sacadoId } =
    useParams<AntecipacaoAFornecedorClienteRecebiveisBySacadoIdRouteParams>();
  if (!sacadoId) throw new Error("No sacadoId");

  const gridRef = useGridRef();

  const [sacadoTitle, setSacadoTitle] = useState("");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        NonNullable<
          Awaited<
            ReturnType<typeof service.quickfin.getReceivables>
          >["data"]["value"]
        >[number]
      >(() =>
        service.quickfin
          .getReceivables({ sacadoId })
          .then(({ data }) => {
            if (data.value?.[0]) {
              setSacadoTitle(
                ` - ${data.value[0].Sacado} - ${masks.cpfCnpj(
                  data.value[0].SacadoCnpj,
                )}`,
              );
            }
            return data.value ?? [];
          })
          .catch(() => {
            const errorMessage = "Erro ao buscar os recebíveis";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [sacadoId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title={`Recebíveis disponíveis${sacadoTitle}`} />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
