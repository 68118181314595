import { DetailsFields } from "components/details-card";
import { percent4DigitsMask } from "utils/mask";

export const buildOverdraftTaxesFields = (
  taxes: BankingHub.Tax,
): DetailsFields => ({
  taxes: {
    title: "Taxas",
    items: [
      {
        label: "Taxa de juros",
        value: `${percent4DigitsMask(taxes.interestRate ?? 0)} ao mês`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "CET",
        value: percent4DigitsMask(taxes.totalEffectiveCostRate ?? 0),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Multa",
        value: `${percent4DigitsMask(taxes.penaltyRate ?? 0)} cobrança única`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Juros de Mora",
        value: `${percent4DigitsMask(taxes.defaultInterestRate ?? 0)} ao mês`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "IOF",
        value: `${percent4DigitsMask(taxes.taxRate ?? 0)} ao dia`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "IOF Adicional",
        value: `${percent4DigitsMask(taxes.additionalTaxRate ?? 0)} ao mês`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
