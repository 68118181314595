import * as yup from "yup";

export const addStepModalValidatioNFormSchema = yup.object().shape({
  name: yup.string().trim().required("Campo obrigatório"),
  description: yup.string().trim().nullable(),
  content: yup.string().required("Este campo é obrigatório"),
});

export const addStepModalFormDefaultValues = {
  name: "",
  description: "",
  content: "CARD",
};
