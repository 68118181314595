import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { OnboardingEditByIdRouteParams } from "../../../../../routes/cadastro.route-params";
import { buildGrid } from "./onboarding-logs.grid";

export const OnboardingLogs = () => {
  const { id } = useParams<OnboardingEditByIdRouteParams>();
  if (!id) throw new Error("No id in route parameters");
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.onboarding.getWorkflowLogs(id),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Logs" description={`Workflow id: ${id}`} />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
