import { DetailsFields } from "components/details-card";
import { service } from "services";
import { format } from "utils/date";
import { parseLogErrorMessage } from "./loan-log-details.utils";

type Log = Awaited<ReturnType<typeof service.hubLoan.getLog>>["data"];

export const loanLogDetailsGenerator = (log: Log) => {
  return {
    details: {
      items: [
        {
          label: "ID do Log",
          value: log.id ?? "-",
          grid: { md: 4 },
        },
        {
          label: "Data da ocorrência",
          value: format.dateTime(log.createdDate) || "-",
          grid: { md: 4 },
        },

        {
          label: "Nome do Comando",
          value: log.commandName,
          grid: { md: 4 },
        },
      ],
    },
    error: {
      title: "Erro",
      items: [
        {
          label: parseLogErrorMessage(log?.exceptionDetails),
          value: (
            <div style={{ overflow: "auto" }}>
              <pre>{log?.exceptionDetails}</pre>
            </div>
          ),
        },
      ],
    },
    request: {
      title: "Requisição",
      items: [
        {
          value: <pre>{JSON.stringify(JSON.parse(log?.request), null, 2)}</pre>,
        },
      ],
    },
  } satisfies DetailsFields;
};
