export const statusType = [
  "PRE_CADASTRADO",
  "PENDENTE_CADASTRO",
  "PENDENTE_PRECADASTRO",
  "PENDENTE_REQUISICAO",
  "PENDENTE_ONBOARDING",
  "PENDENTE_DOCUMENTACAO",
  "PENDENTE_ANALISE",
  "AUTORIZADO",
  "REPROVADO",
  "CONCLUIDO",
  "PENDENTE_AJUSTE",
  "PENDENTE_BIOMETRIA",
  "PENDENTE_VALIDACAO",
  "PENDENTE_VALIDACAO_MANUAL",
  "PAUSADO",
  "PENDENTE_APROVACAO_MANUAL",
  "PENDENTE_ASSINATURA",
];

export interface FormValuesType {
  type: string;
  status: string;
  insertedAt: string;
  updatedAt: string;
}

export interface OnboardingEditType {
  id: string;
}
