import {
  OCol,
  ORadio,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { NotificationFormProps } from "./notification-form.types";
import { ProfileRolesForm } from "./roles-form.component";

export const NotificationForm = ({ form }: NotificationFormProps) => {
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <CardTemplate>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="icon"
                name="icon"
                label="Ícone"
                tooltip="Digite no padrão: categoria/nome."
                placeholder="Exemplo: orq/orq-anticipate"
                required
                autocomplete="off"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="rendered_name"
                name="renderedName"
                label="Nome renderizado"
                required
                autocomplete="off"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                label="Nome"
                required
                autocomplete="off"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="description"
                name="description"
                label="Descrição"
                required
                autocomplete="off"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="tenant"
                name="tenant"
                label="Responsável"
                required
              >
                <OOption id="btg_pactual" value="btg_pactual">
                  BTG Pactual
                </OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                id="legal_representative?"
                name="legalRepresentative?"
                label="Destinado apenas a representantes legais?"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="leagalRepresentative-yes" value="true" />
                    <OLabel htmlFor="leagalRepresentative-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="leagalRepresentative-no" value="false" />
                    <OLabel htmlFor="leagalRepresentative-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6} xl={4} className="flex-fill">
              <ProfileRolesForm />
            </OCol>
          </ORow>
        </form>
      </FormProvider>
    </CardTemplate>
  );
};
