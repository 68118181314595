/* eslint-disable import-rules/imports-in-modules */
import { useGridRef } from "hooks/grid-ref";
import { PageTitle } from "components/page-title";
import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AppIdentificationRouteParams } from "pages/developers/routes/developers.route-params";
import {
  buildDataSourceByApp,
  resourceListGrid,
} from "./listagem-resources.grid";

export const ResourceByAppList = () => {
  const gridRef = useGridRef();
  const { appId } = useParams<AppIdentificationRouteParams>();
  if (!appId) throw new Error("No app");

  const dataSource = useMemo(() => buildDataSourceByApp(appId), [appId]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Lista de consentimentos de recursos por app" />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={resourceListGrid(dataSource)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
