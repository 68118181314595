import { ODivider, OSkeletonCard, OTypography } from "@maestro/react";
import { OTruncateTypography } from "components/o-truncate-typography";
import React from "react";
import {
  Content,
  DoubleDetailsCollectionProps,
} from "./double-details-collection.types";

export const DoubleDetailsCollection = ({
  detailLines,
  hasDividerOnEnd,
  omitDivider,
}: DoubleDetailsCollectionProps) => {
  const renderContent = (content?: Content, isLoading?: boolean) => {
    if (isLoading) {
      return (
        <div className="w-49">
          <OSkeletonCard rounded width="100%" height="25px" />
        </div>
      );
    }

    if (React.isValidElement(content)) return content;

    if (Array.isArray(content)) {
      const [title, subtitle] = content;
      return (
        <div className="d-flex flex-column">
          {!!title && (
            <OTruncateTypography type="dark-80" key={title}>
              {title}
            </OTruncateTypography>
          )}
          {!!subtitle && (
            <OTruncateTypography type="dark-80" key={subtitle}>
              {subtitle}
            </OTruncateTypography>
          )}
        </div>
      );
    }

    return (
      <OTruncateTypography type="dark-80" key={content?.toString()}>
        {content ?? "-"}
      </OTruncateTypography>
    );
  };

  return (
    <div className="d-flex flex-column gap-3">
      {detailLines.map((line, lineIndex) => {
        const hasDivider =
          !omitDivider &&
          (hasDividerOnEnd || lineIndex < detailLines.length - 1);

        const [firstDetailItem] = line;

        return (
          <div key={`${firstDetailItem.title}`}>
            <div className="d-flex justify-content-between">
              {line.map((cell) => (
                <div className="d-flex flex-column w-49" key={`${cell.title}`}>
                  {cell && (
                    <>
                      <OTypography
                        key={cell.title}
                        type="dark"
                        className="text-nowrap"
                      >
                        {cell.title}
                      </OTypography>

                      {renderContent(cell.content, cell.isLoading)}
                    </>
                  )}
                </div>
              ))}
            </div>

            {hasDivider && (
              <div className="d-flex justify-content-between">
                <div className="w-49">
                  <ODivider size="xxs" className="pt-2" />
                </div>
                <div className="w-49">
                  {line.length === 2 && (
                    <ODivider size="xxs" className="pt-2" />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
