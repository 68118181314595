import {
  OButton,
  OCol,
  OIcon,
  OLink,
  OLoader,
  OOption,
  ORFieldSelect,
  ORFieldUploadInput,
  ORow,
} from "@maestro/react";
import { cases } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import {
  VerifyAditamentosItem,
  VerifyCorrecaoItem,
} from "services/quickfin/models";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { gridAditamento, gridCorrecao } from "./import-corrections.grid";
import { useTitleImportCorrections } from "./import-corrections.hook";
import { UploadContainer } from "./import-corrections.styles";

export const TitleImportCorrections = () => {
  const {
    form,
    loading,
    readerLoader,
    spreadsheet,
    watchType,
    handleAddFile,
    importData,
  } = useTitleImportCorrections();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Importar correções" />}
      actions={
        <OLink
          href={`/files/modelo_importacao_alteracoes_trade_${cases.lower(
            watchType,
          )}.xlsx`}
        >
          <OIcon category="fas" icon="fa-external-link" className="me-2" />
          Baixar modelo
        </OLink>
      }
    >
      <CardTemplate>
        {(loading || readerLoader) && <OLoader absolute />}

        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                dataAction="importar_correcoes:select:tipo"
                dataLabel="tipo"
                id="type"
                name="type"
                label="Tipo"
                placeholder="Selecione"
              >
                <OOption value="Aditamento">Aditamento</OOption>
                <OOption value="Correcao">Correção</OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <UploadContainer>
                <ORFieldUploadInput
                  id="correction"
                  name="correction"
                  dataAction="importar_correcoes:upload:documento"
                  dataLabel="documento"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  size="md"
                  inputLabel="Selecionar documento"
                  multiple={false}
                  disabled={loading}
                  handleAddFile={handleAddFile}
                />
              </UploadContainer>
            </OCol>
          </ORow>
        </FormProvider>

        {spreadsheet && (
          <FormTemplate
            actions={
              <OButton
                dataAction="importar_correcoes:botao:importar"
                dataLabel="importar"
                onClick={importData}
              >
                Importar
              </OButton>
            }
          >
            <div className="mt-3">
              {watchType === "Aditamento" ? (
                <ODataGridGenerator
                  grid={gridAditamento}
                  dataSource={spreadsheet as VerifyAditamentosItem[]}
                />
              ) : (
                <ODataGridGenerator
                  grid={gridCorrecao}
                  dataSource={spreadsheet as VerifyCorrecaoItem[]}
                />
              )}
            </div>
          </FormTemplate>
        )}
      </CardTemplate>
    </DetailsTemplate>
  );
};
