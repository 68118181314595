import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormValuesType, validationSchema } from "../_compose";

export const useOnboardingDomainConfigAdd = () => {
  const navigate = useNavigate();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const { callService: addConfigDomain, loading: submitLoading } =
    useServiceCall(service.onboarding.addDomainConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { group, key, value } = values;

        const payload = {
          groupKeyValues: [
            {
              group,
              key,
              value,
            },
          ],
        };
        const { success } = await addConfigDomain(payload);

        if (success) {
          navigate(
            corporateRouter.routes.ferramentas.product.onboarding.domainConfig
              .path,
          );
          OToastManager.success("Domínio criado com sucesso");
        } else {
          OToastManager.danger("Erro para salvar os dados");
        }
      }),
    [handleSubmit, navigate, addConfigDomain],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
