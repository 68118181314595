import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  buildConsumerUnitsGrid,
} from "../../../../components/consumer-units-grid";

const dataGrid = buildConsumerUnitsGrid(true);

export const ConsumerUnitsGrid = () => {
  const { customer } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(customer.identification),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={
        <OTypography type="dark" size="lg">
          Unidades consumidoras
        </OTypography>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dataGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
