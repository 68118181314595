export const showConfigurationsModalId = "show-cnfigurations-modal-id";

export const stepTypeMap: Record<ScfDevelopers.StepType, string> = {
  GENERAL: "General",
  VALIDATION: "Validation",
  COMPLEMENT: "Complement",
  RISKANALYSIS: "Risk analysis",
  LESS: "LESS",
  WEBHOOK: "Webhook",
  WEBHOOK_INTERNAL: "Webhook interno",
  DEVELOPERS_WEBHOOK: "Developers webhook",
  CLERK: "Clerk sync",
  QUICKFINSYNC: "Quickfin sync",
  VALIDATE_CREATE_USER_RELATIONSHIP: "Validar criação de relationship",
  CREATE_USER_RELATIONSHIP: "Criar relationship",
};

export const scfDevelopersOnboardingStatusMap = {
  CADASTRO_INICIADO: "Cadastro iniciado",
  DADOS_ENRIQUECIDOS: "Dados enriquecidos",
  ENVIADO_PARA_ANALISE: "Enviado para análise",
  SOLICITANDO_ANALISE: "Solicitando análise",
  ANALISE_INICIADA: "Análise iniciada",
  ANALISE_RISCO_SOLICITADA: "Análise de risco solicitada",
  ANALISE_RISCO_APROVADO: "Análise de risco aprovada",
  CONCLUIDO: "Concluído",
  PENDENTE_AJUSTE: "Pendente ajuste",
  REPROVADO: "Reprovado",
  ERRO: "Erro",
  BAIXADO_NA_RECEITA: "Baixado na receita",
  NAO_ENCONTRADO_NA_RECEITA: "Não encontrado na receita",
  VALIDANDO: "Validando",
  INDEFINIDO: "Indefinido",
  INAPTA_NA_RECEITA: "Inapta na receita",
};

export const integrationDocumentsMap: Record<
  ScfDevelopers.PayerDocumentType,
  string
> = {
  CONTRATO_SOCIAL: "Contrato social",
  ESTATUTO_SOCIAL: "Estatuto social",
  REQUERIMENTO_EMPRESARIO: "Requerimento empresário",
  PROCURACAO: "Procuração",
  ATA_ELEICAO_DIRETORIA: "Ata eleição da diretoria",
  QUADRO_SOCIETARIO: "Quadro societário",
  DOCUMENTO_FINANCEIRO: "Documento financeiro",
  CCMEI: "CCMEI",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  INDEFINIDO: "Indefinido",
  IDENTIFICACAO: "Identificação",
  ATA_ELEICAO: "Ata eleição",
  FICHA_CADASTRAL: "Ficha cadastral",
  DECLARACAO_ITIOF: "Declaração ITIOF",
  DECLARACAO_ITIR: "Declaração ITIR",
  KNOW_YOUR_CUSTOMER: "Know your customer",
  BALANCO_DRE: "Balanço DRE",
  DRE_DEFIS: "DRE DEFIS",
  DECLARACAO_PREVISAO_FATURAMENTO: "Declaração previsão de faturamento",
  DECLARACAO_ANUAL_SIMPLES_NACIONAL: "Declaração anual simples nacional",
  CERTIFICADO_CONDICACAO_MEI: "Certificado condicação MEI",
  CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
  BIOMETRIA: "Biometria",
};

export const requiredFieldsMap = {
  NAME: "Nome",
  PHONENUMBER: "Telefone",
  EMAILADDRESS: "E-mail",
  BIRTHDATE: "Data de nascimento",
  ADDRESSES: "Endereço",
};

export const fidcAnalysisMap = {
  FIDC_LOW_RISK: "Risco baixo",
  FIDC_MEDIUM_RISK: "Risco mediano",
  FIDC_HIGH_RISK: "Risco alto",
};
