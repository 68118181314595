import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataFidcReceivableStateMachineOdataViewResponse } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { fidcReceivablesTableGrid } from "./fidc-receivables-table.grid";

const dataSource = dataSourceODataStoreGenerator<
  GetOdataFidcReceivableStateMachineOdataViewResponse["value"][number]
>(endpoints.quickfin.odata.fidcReceivableStateMachineODataView);

export const FidcReceivablesTable = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Recebíveis" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={fidcReceivablesTableGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
