import { useCallback, useEffect, useState } from "react";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { service } from "services/service";
import { GetAdminReceivableFidcDailySummaryResponse } from "services/quickfin/models";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FidcReceivablesDailySummaryFields } from "./fidc-receivable.details";
import { FidcBorderosTable, FidcReceivablesTable } from "./_compose";

export const FidcReceivable = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dailySummary, setDailySummary] =
    useState<GetAdminReceivableFidcDailySummaryResponse>();

  const fetchDailySummary = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await service.quickfin.getFidcReceivablesSummary();
      setDailySummary(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDailySummary();
  }, [fetchDailySummary]);

  return (
    <div className="d-flex flex-column gap-5">
      <DetailsTemplate pageTitle={<PageTitle title="Recebíveis FIDC" />}>
        <DetailsCard
          loading={loading}
          hasError={hasError}
          value={dailySummary}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar o saldo"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={() => fetchDailySummary()} />
            </ErrorComponent>
          }
          fields={FidcReceivablesDailySummaryFields}
        />
      </DetailsTemplate>
      <FidcReceivablesTable />
      <FidcBorderosTable />
    </div>
  );
};
