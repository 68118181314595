import { OCol, OOption, ORFieldSelect } from "@maestro/react";

export const BTGBusinessForm = () => {
  return (
    <OCol xs={12} md={6} xl={4}>
      <ORFieldSelect
        dataAction="formulario_btg_business:select:btg_pactual_empresas"
        dataLabel="btg_pactual_empresas"
        id="isBtgBusiness"
        name="isBtgBusiness"
        label="BTG Pactual Empresas?"
        placeholder="Selecionar"
      >
        <OOption value="true">Sim</OOption>
        <OOption value="false">Não</OOption>
      </ORFieldSelect>
    </OCol>
  );
};
