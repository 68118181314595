import * as yup from "yup";
import { RoleAssignmentsForm } from "../../../components/role-assignments/role-assignments.types";

export const userRoleAssignmentsValidationSchema = yup.object<
  Partial<RoleAssignmentsForm>,
  yup.ObjectSchema<Partial<RoleAssignmentsForm>>["fields"]
>({
  email: yup.string().optional(),
  phoneNumber: yup.string().optional(),
  taxId: yup.string().optional(),
  name: yup.string().optional(),
  sendEmail: yup.array().optional(),
  roles: yup
    .array()
    .required("Campo obrigatório")
    .min(1, "É necessário selecionar ao menos uma função."),
});
