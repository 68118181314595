import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { GetAdminCustomerOrderDetailsResponse } from "services/acc/models/responses/get-admin-acc-order-details.response";
import { format } from "utils/date";

export const adminCustomerAccOrderDetailsGenerator = (
  value: GetAdminCustomerOrderDetailsResponse | null,
) => {
  if (!value) return {} as DetailsFields;

  const counterpartyDetails = {
    counterparty: {
      title: "Ordem",
      items: [
        {
          label: "Conciliation Id",
          value: value.conciliationId,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: value.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(value.createdDate),
          grid: { md: 3, xl: 2 },
        },
      ],
    },
    payer: {
      title: "Pagador",
      items: [
        {
          label: "Pagador",
          value: value.payer,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    values: {
      title: "Valores",
      items: [
        {
          label: "Valor disponível",
          value: masks.currency(value.notAllocatedAmount, value.currency),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor total",
          value: masks.currency(value.amount, value.currency),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor em trânsito",
          value: masks.currency(value?.inTransitAmount, value.currency),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return counterpartyDetails;
};
