import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { corporateRouter } from "routes/corporate-router.context";
import { roles } from "roles/roles";
import { DataGrid } from "devextreme-react";
import { ODataGridGenerator } from "components/data-grid";
import { OToastManager } from "@maestro/core";
import { NotificationTypeOdataResponse } from "services/raven/models/responses/notification-types-odata.response";
import { buildGrid } from "./raven-notificacoes.grid";

export const RavenNotifications = () => {
  const { routes } = corporateRouter;
  const gridRef = useRef<DataGrid>(null);

  const [responseData, setResponseData] = useState<
    NotificationTypeOdataResponse[]
  >([]);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await service.raven.getAllNotificationTypes();
      setResponseData(data.data);
    } catch (err) {
      OToastManager.danger("Não foi possível obter os tipos de notificação");
    } finally {
      setLoading(false);
    }
  };

  const deleteRow = useCallback(async (rowIndex: number) => {
    try {
      gridRef.current?.instance.deleteRow(rowIndex);
    } catch (err) {
      OToastManager.danger("Não foi possível remover o tipo de notificação");
    }
  }, []);

  const grid = useMemo(() => buildGrid(deleteRow), [deleteRow]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Gestão de Notificações"
          description="Veja todos os tipos de notificações e suas informações principais"
        />
      }
      actions={
        <RouterButton
          href={routes.ferramentas.product.raven.notificacoes.gestao.add.path}
          role={roles.ferramentas.product.ravenAddNotificationTypesPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
    >
      {responseData && (
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={responseData}
          loading={loading}
        />
      )}
    </GridTemplate>
  );
};
