import { OLink, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { FormTemplate } from "templates/form-template";
import { RavenForm } from "../_compose";
import { useRavenTemplatesDetails } from "./details.hook";

export const RavenTemplatesDetails = () => {
  const { value: name } = useParseFromSearchParams<string>("name");
  const { submit, form, loading } = useRavenTemplatesDetails(name);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={`Template ${name}`}
          description="Adicione um novo template"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="raven_template:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <OTypography tag="p" type="default" className="mb-3">
        Para mais detalhes, acesse a{" "}
        <OLink
          href="https://btgpactual.visualstudio.com/PME/_wiki/wikis/PME.wiki/15676/Cadastrando-templates-de-email"
          target="_blank"
        >
          documentação
        </OLink>
      </OTypography>

      <RavenForm form={form} />
    </FormTemplate>
  );
};
