import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useReactFlow } from "reactflow";
import { service } from "services";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { ADD_CANVAS_ITEM_MODAL_ID } from "../../config-proposal-workflow.utils";
import {
  addCanvasItemFormDefaultValues,
  addCanvasItemValidationSchema,
} from "./add-canvas-item-modal.schemas";

export const useAddCanvasItemModal = () => {
  const { screenToFlowPosition } = useReactFlow();
  const { viewPoint, workflowId, getCanvasData, handleRequestError } =
    useCanvas();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    defaultValues: addCanvasItemFormDefaultValues,
    resolver: yupResolver(addCanvasItemValidationSchema),
  });
  const { handleSubmit, reset } = form;

  const closeModal = useCallback(() => {
    setLoading(false);
    modalManager.hide(ADD_CANVAS_ITEM_MODAL_ID);

    reset();
  }, [reset]);

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const { x, y } = screenToFlowPosition({
        x: viewPoint.client.x,
        y: viewPoint.client.y,
      });

      await service.hubCreditManager.addCanvasItem({
        workflowConfigId: Number(workflowId),
        canvasData: { positionX: x, positionY: y },
        ...values,
      });

      getCanvasData();
    } catch (err) {
      handleRequestError(
        err,
        "Um problema ocorreu ao tentar adicionar a configuração de grupo ao workflow.",
      );
    } finally {
      closeModal();
    }
  });

  return { form, loading, closeModal, submit };
};
