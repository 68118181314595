import { OIcon } from "@maestro/react";
import { TryAgainButton } from "components/empty-state";

interface CustomErrorComponentProps {
  callback: () => void;
}

export const CustomErrorComponent = ({
  callback,
}: CustomErrorComponentProps) => (
  <div
    style={{
      marginLeft: "-19px",
      marginTop: "0.75rem",
    }}
    className="d-flex"
  >
    <OIcon
      category="orq"
      icon="orq-status-error"
      type="danger"
      size="sm"
      className="d-inline me-2"
    />

    <div>
      <span>Não foi possível buscar o resto dos produtos</span>
      <TryAgainButton onClick={callback} />
    </div>
  </div>
);
