import { PostContingencyDocumentsLoadResponse } from "services/onboarding/models";
import { DocumentsItemFormatted } from "./document-devolutive.types";

export function formatData(
  data?: PostContingencyDocumentsLoadResponse,
): DocumentsItemFormatted[] | undefined {
  if (!data) return;

  const docs: DocumentsItemFormatted[] = [];

  data.companyDocuments.forEach((companyDoc) => {
    companyDoc.documents.forEach((doc) => {
      docs.push({ ...doc, taxId: companyDoc.taxId, name: companyDoc.name });
    });
  });

  data.naturalPersonDocuments.forEach((companyDoc) => {
    companyDoc.documents.forEach((doc) => {
      docs.push({ ...doc, taxId: companyDoc.taxId, name: companyDoc.name });
    });
  });

  return docs;
}
