import { OCard, OModalBody } from "@maestro/react";
import styled from "styled-components";

export const DraggableCard = styled(OCard)`
  transition: all 0.5s ease-in-out;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export const StyledOModalBody = styled(OModalBody)`
  min-height: 300px;
`;
