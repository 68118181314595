import {
  OCol,
  OLabel,
  OLoader,
  OOption,
  ORadio,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CadastroClienteContasByAccountByAgencyRouteParams } from "../../../../../cadastro/routes/cadastro.route-params";
import { useAccountAdminCorporateTax } from "./corporate-tax.hook";
import { Quarter } from "./corporate-tax.types";

export const AccountAdminCorporateTax = () => {
  const { account, agency } =
    useParams<CadastroClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  const {
    submit,
    form,
    loading,
    corporateTax,
    years,
    yearWatch,
    quarterWatch,
  } = useAccountAdminCorporateTax({
    account,
    agency,
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Informe de rendimentos" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="gerar_informe_de_rendimentos:botao:baixar"
          dataLabel="baixar"
          disabled={!yearWatch || !quarterWatch || loading}
          onClick={submit}
        >
          Baixar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <div className="col col-sm-6">
                <ORFieldSelect
                  dataAction="modal_gerar_informe_de_rendimentos:select:selecionar_ano"
                  dataLabel="selecionar_ano"
                  id="year"
                  name="year"
                  label="Selecione o ano:"
                  key={years.join("")}
                >
                  {years.map((year) => (
                    <OOption key={year} value={year}>
                      {year}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </div>
            </ORow>

            {yearWatch && corporateTax && (
              <ORow>
                <OCol sm={12} className="d-flex flex-column gap-3">
                  <OTypography tag="h1" type="primary" size="lg">
                    Selecione o trimestre:
                  </OTypography>
                  <ORFieldRadioGroup
                    id="quarter"
                    name="quarter"
                    dataAction="gerar_informe_de_rendimentos:seletor:selecionar_trimestre"
                    dataLabel="selecionar_trimestre"
                  >
                    <div className="d-flex flex-column gap-1">
                      {Object.values(Quarter).map((quarter, index) => {
                        const isIndisponible =
                          !corporateTax[yearWatch][quarter];

                        return (
                          <div
                            className="d-flex align-items-center gap-2"
                            key={quarter}
                            title={`Informe de rendimentos ${
                              isIndisponible ? "indisponível" : "disponível"
                            }`}
                          >
                            <ORadio
                              size="xs"
                              id={quarter}
                              name={quarter}
                              value={quarter}
                              disabled={isIndisponible}
                            />
                            <OLabel htmlFor={quarter} size="md">
                              {`${index + 1}º Trimestre`}
                            </OLabel>
                          </div>
                        );
                      })}
                    </div>
                  </ORFieldRadioGroup>
                </OCol>
              </ORow>
            )}
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
