import { OField } from "@maestro/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { SelectSearch } from "../select-search.component";
import { SelectSearchProps } from "../select-search.types";
import { remapHookFormRegister } from "./or-field-select-search.utils";

interface ORFieldSelectSearchProps<T>
  extends SelectSearchProps<T>,
    Omit<React.ComponentProps<typeof OField>, "id"> {}

export const ORFieldSelectSearch = <T,>(props: ORFieldSelectSearchProps<T>) => {
  const { name, innerProps, id } = props;
  const { register, formState, getFieldState } = useFormContext();
  const { error: fieldErrors } = getFieldState(name, formState);

  return (
    <OField
      htmlFor={id}
      {...props}
      error={!!fieldErrors}
      message={fieldErrors?.message}
    >
      <SelectSearch
        className="w-100"
        {...props}
        innerProps={{
          ...innerProps,
          input: {
            ...innerProps?.input,
            ...remapHookFormRegister(register(name)),
          },
        }}
      />
    </OField>
  );
};
