import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostTemplateEstrategiaComercialBody, UpdateStrategyTemplateChildrenOptions } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  EstrategiaForm,
  addEstrategiaFormDefaultValues,
  addEstrategiaFormValidationSchema,
  productFormMap,
} from "../../../../../../components/estrategia-form";
import { buildPayload, toastErrors } from "./add-estrategia-template.utils";

export const useAddEstrategiaTemplate = () => {
  const { callService, loading } = useServiceCall(
    service.quickfin.addEstrategia,
  );

  const navigate = useNavigate();

  const form = useForm<EstrategiaForm>({
    defaultValues: addEstrategiaFormDefaultValues,
    resolver: yupResolver(addEstrategiaFormValidationSchema),
  });

  const { handleSubmit, watch } = form;

  const tipoProdutoWatch = watch("tipoProduto");

  const Form = getValueFromMap(productFormMap, tipoProdutoWatch);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService(
          buildPayload(values, UpdateStrategyTemplateChildrenOptions.NOT_UPDATE) as PostTemplateEstrategiaComercialBody,
        );
        if (success) {
          OToastManager.success("Estratégia criada com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.product.estrategiaTemplates.path,
          );
        } else {
          toastErrors(error);
          OToastManager.danger("Não foi possível criar a estratégia");
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    Form,
    loading,
    submit,
  };
};
