import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { roles } from "roles/roles";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../utils";
import { SyncBillingReportWithNesButtonProps } from "./sync-billing-report-with-nes-button.types";

export const SyncBillingReportWithNesButton = ({
  billingReportId,
}: SyncBillingReportWithNesButtonProps) => {
  const { hasRole } = useRoles();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.syncBillingReportWithNesById,
  );

  const callServiceSyncBillingReportWithNesButton = useCallback(async () => {
    const { success, error } = await callService(billingReportId);

    if (success) {
      OToastManager.success("Sincronização concluída.");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Não foi possível sincronizar com o NES.",
      );
    }
  }, [billingReportId, callService]);

  return hasRole(roles.energia.product.billingReport.role) ? (
    <LoadingButton
      loading={loading}
      onClick={callServiceSyncBillingReportWithNesButton}
    >
      Sincronizar com o NES
    </LoadingButton>
  ) : null;
};
