import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { EmprestimosClienteGestorLimitesById } from "../../../../routes/emprestimos.route-params";

type SalesforceCreditLines = Awaited<
  ReturnType<typeof service.hubCreditManager.getCompanyCreditLines>
>["data"]["value"];

export const useLimitManager = () => {
  const { id } = useParams<EmprestimosClienteGestorLimitesById>();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] =
    useState<NonNullable<SalesforceCreditLines>[number]>();
  const [selectedCreditLine, setSelectedCreditLine] =
    useState<SalesforceCreditLines>([]);

  const getCompanyLimits = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await service.hubCreditManager.getCompanyCreditLines();
      setSelectedCreditLine(data.value);
    } catch (error) {
      const errorMessage = "Erro ao buscar os detalhes do gestor de limites";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getCompanyLimits();
  }, [getCompanyLimits]);

  useEffect(() => {
    selectedCreditLine?.forEach((item) => {
      if (item.Id?.toString() === id) {
        setDetails(item);
      }
    });
  }, [id, selectedCreditLine]);

  return {
    isLoading,
    details,
  };
};
