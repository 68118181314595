import { useContext, useState } from "react";
import {
  OModal,
  OModalHeader,
  OTypography,
  OModalBody,
  OModalFooter,
  OButton,
  ORow,
  ORFieldSelect,
  OOption,
  OCol,
  ORFieldInput,
  modalManager,
  OToastManager,
} from "@maestro/react";
import { FormProvider, useForm } from "react-hook-form";
import { logger } from "utils/logger";
import IntegrationContactGrid from "../tables/integration-contact.grid";
import IntegrationAccountGrid from "../tables/integration-account.grid";
import { IntegrationModalName } from "../enums/integrationModalName";
import { VanNames } from "../enums/integrationVans";
import { IntegrationLayout, LayoutCNAB } from "../enums/integrationLayout";
import { IntegrationContext } from "../context/integration.context";
import { inputValidate } from "../validator/input-validator.utils";
import {
  IntegrationAccount,
  IntegrationContact,
} from "../interfaces/integration";
import { useFileProcessor } from "../../integrations.hook";
import { ProductsCNAB } from "../../integrations.utils";

export const AddIntegrationModal = () => {
  const { integration } = useContext(IntegrationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { registerIntegration, registerCompany, registerCompanyLayout } =
    useFileProcessor();
  const form = useForm();

  const validateForm = (
    vanName: string,
    productName: string,
    layoutName: string,
    operatorEmail: string,
    operatorTaxId: string,
    taxId: string,
    companyName: string,
    accounts: IntegrationAccount[],
    contacts: IntegrationContact[],
  ) => {
    const isRequesterDataValid =
      operatorEmail && operatorTaxId && companyName && taxId;
    const hasSomeTecnicalContact = contacts.some(
      (contact) => contact.type === "Técnico",
    );
    const hasMissingLayoutInfo = vanName && productName && layoutName;

    const validations = [
      { value: hasMissingLayoutInfo, message: "Dados da integração" },
      { value: isRequesterDataValid, message: "Dados do solicitante" },
      {
        value: hasSomeTecnicalContact,
        message: "contatos tecnicos do solicitante",
      },
      {
        value: accounts.length,
        message: "contas do solicitante",
      },
    ];

    return inputValidate(validations);
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getValues();
      setIsLoading(true);
      const {
        vanName,
        productName,
        layoutName,
        operatorEmail,
        operatorTaxId,
        taxId,
        companyName,
      } = formValues;

      const isValidIntegration = validateForm(
        vanName,
        productName,
        layoutName,
        operatorEmail,
        operatorTaxId,
        taxId,
        companyName,
        integration.accounts,
        integration.contacts,
      );

      if (!isValidIntegration) return;

      const hasContactTypeVAN = integration.contacts.find(
        (contact) => contact.type === "VAN",
      );

      if (!hasContactTypeVAN) {
        OToastManager.danger(
          "É necessário adiciona pelo menos um contato da VAN",
        );
      }

      const product: "Cobrança" | "Pagamento" | "Extrato" | "Fopa" =
        productName;
      const layout: "FEBRABAN-240" = layoutName;
      const productId = ProductsCNAB[product];
      const layoutId = LayoutCNAB[layout][product];
      const integrationPayload = integration;
      integrationPayload.layoutId = layoutId;
      integrationPayload.productId = productId;
      integrationPayload.companyDocument = taxId;
      integrationPayload.additionalInfo = {
        ...integration.additionalInfo,
        van: vanName.toUpperCase(),
      };

      await registerCompanyLayout({
        layoutId,
        productId,
        taxId,
        operatorTaxId: operatorTaxId.replace(/[.-]/g, ""),
        operatorEmail,
      });
      await registerCompany(taxId, companyName);
      await registerIntegration(integrationPayload);
      OToastManager.success("Integração registrada com sucesso!");
    } catch (err) {
      logger.error(err);
      OToastManager.danger("Não foi possível registrar a integração");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OModal id={IntegrationModalName.ADD_INTEGRATION}>
      <OModalHeader closeButton divider>
        <OTypography tag="h1" size="lg">
          Nova integração
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <form>
            <OTypography size="md">Integração</OTypography>
            <ORow className="mb-3 pt-3">
              <OCol>
                <ORFieldSelect
                  placeholder="VAN"
                  label="Selecione a VAN:"
                  id="vanName"
                  name="vanName"
                >
                  {Object.keys(VanNames).map((vanName) => (
                    <OOption value={vanName} key={vanName}>
                      {vanName}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <ORFieldSelect
                  placeholder="Produto"
                  label="Selecione o produto:"
                  id="productName"
                  name="productName"
                >
                  {Object.keys(ProductsCNAB).map((product) => (
                    <OOption value={product} key={product}>
                      {product}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <ORFieldSelect
                  placeholder="Layout"
                  label="Selecione o Layout:"
                  id="layoutName"
                  name="layoutName"
                >
                  {Object.keys(IntegrationLayout).map((layout) => (
                    <OOption value={layout} key={layout}>
                      {layout}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
            </ORow>
            <OTypography size="md">Solicitante</OTypography>
            <ORow class="mb-3" className="pt-2">
              <OCol>
                <ORFieldInput
                  id="taxId"
                  label="Documento da empresa"
                  name="taxId"
                  placeholder="99.999.999/9999-99"
                  tag="cpfcnpj"
                  tooltip="Digite aqui o CNPJ da empresa que terá uma nova integração cadastrada"
                  tooltipMaxWidth="30rem"
                  tooltipPosition="top-right"
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  id="companyName"
                  label="Nome da empresa"
                  name="companyName"
                  placeholder="Digite aqui o nome da empresa"
                  tag="text"
                  tooltip="Digite o nome da empresa que terá uma nova integração cadastrada"
                  tooltipMaxWidth="30rem"
                />
              </OCol>
            </ORow>
            <ORow class="mb-3">
              <OCol>
                <ORFieldInput
                  id="operatorTaxId"
                  label="CPF do operador padrão"
                  name="operatorTaxId"
                  placeholder="999.999.999-99"
                  tag="cpf"
                  tooltip="Digite aqui o CPF do operador. Será utilizado para abrir contratos em firmas e poderes. Normalmente é quem iniciou a solicitação"
                  tooltipMaxWidth="30rem"
                  tooltipPosition="top-right"
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  id="operatorEmail"
                  label="E-mail do operador padrão"
                  name="operatorEmail"
                  placeholder="Digite aqui e-mail do operador"
                  tag="text"
                  tooltip="Digite o e-mail do operador padrão. Será utilizado para abrir contratos em firmas e poderes. Normalmente é quem iniciou a solicitação"
                  tooltipMaxWidth="30rem"
                />
              </OCol>
            </ORow>

            <IntegrationContactGrid />
            <IntegrationAccountGrid />
          </form>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <ORow className="w-100" justify="end" align="center">
          <OButton
            class="mr-3 w-auto"
            onClick={() => {
              handleSubmit();
            }}
            disabled={isLoading}
          >
            Salvar
          </OButton>
          <OButton
            class="w-auto"
            outline
            type="default"
            onClick={() => {
              modalManager.hide(IntegrationModalName.ADD_INTEGRATION);
            }}
          >
            Cancelar
          </OButton>
        </ORow>
      </OModalFooter>
    </OModal>
  );
};
