import * as yup from "yup";
import { AddPartnerFormFields } from "./add-partner.types";

const hasWhiteSpace = (str: string) => {
  return /\s/g.test(str);
};

const hasHash = (str: string) => {
  return /#/g.test(str);
};

export const addPartnerValidationschema: yup.ObjectSchema<AddPartnerFormFields> =
  yup.object({
    authClientId: yup.string().required("Este campo é obrigatório"),
    name: yup
      .string()
      .required("Este campo é obrigatório")
      .test(
        "invalidWhitespaceChar",
        "Não deve haver espaço",
        (value) => !hasWhiteSpace(value),
      )
      .test(
        "invalidHashChar",
        "Caractere inválido: '#'",
        (value) => !hasHash(value),
      ),
    taxId: yup.string().required("Este campo é obrigatório"),
    isInternal: yup.array(yup.string().required()).default([]),
    legacyRelationalId: yup.string().required().nullable(),
  });

export const addPartnerDefaultValues: AddPartnerFormFields = {
  authClientId: "",
  name: "",
  taxId: "",
  isInternal: [],
  legacyRelationalId: null,
};
