import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { behavior as globalBehavior } from "./behavior";
import { behaviorEvents } from "./behavior.event";
import { BehaviorConfiguration } from "./types/config";
import { BehaviorRoles } from "./types/roles";
import { BehaviorState } from "./types/state";

export interface BehaviorContext {
  behavior: BehaviorConfiguration | undefined;
  state: BehaviorState;
  hasRoles: (roles: BehaviorRoles[]) => boolean;
}

const behaviorContext = createContext<BehaviorContext>({
  behavior: globalBehavior.value,
  state: globalBehavior.state,
  hasRoles: (_roles: BehaviorRoles[]) => false,
});

export const BehaviorProvider = ({ children }: PropsWithChildren) => {
  const [behavior, setBehavior] = useState(globalBehavior.value);
  const [behaviorState, setBehaviorState] = useState(globalBehavior.state);

  useEffect(() => {
    const cleanUp = behaviorEvents.subscribe((newBehavior) => {
      setBehavior(newBehavior.value);
      setBehaviorState(newBehavior.state);
    });

    return cleanUp;
  }, []);

  const hasRoles = useCallback(
    (roles: BehaviorRoles[]) =>
      roles.some((item) => behavior?.roles.includes(item)),
    [behavior],
  );

  const value = useMemo(
    () => ({
      behavior,
      state: behaviorState,
      hasRoles,
    }),
    [behavior, behaviorState, hasRoles],
  );

  return (
    <behaviorContext.Provider value={value}>
      {children}
    </behaviorContext.Provider>
  );
};

/** Simply returns the context as it is */
export const useUnsafeBehavior = () => useContext(behaviorContext);

/** Guarantees that there is a user loaded */
export const useBehavior = () => {
  const { behavior, ...rest } = useContext(behaviorContext);

  if (!behavior) {
    throw new Error(
      "No behavior loaded. Wrap your component in `<BehaviorGuard>` or use `useUnsafeBehavior`.",
    );
  }

  return { behavior, ...rest };
};
