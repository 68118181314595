import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  expiringPowersGrid,
} from "../../../../../../cadastro/components";
import { useGetUser } from "../../../../../hooks";

export const ExpiringPowersUser = () => {
  const { userTaxId } = useGetUser();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(["UserTaxId", "=", userTaxId]),
    [userTaxId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Expiração de poderes" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={expiringPowersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
