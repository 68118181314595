import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { Collateral } from "../../imovel-additional-data.types";
import { CollateralDocumentsUpload } from "./collateral-documents-upload.component";

export type CollateralDocument = Collateral["collateralDocuments"][number];

export const collateralDocumentsGrid = () =>
  ({
    datagrid: {
      noDataText: "Nenhum documento foi encontrado para esta garantia",
      editing: {
        mode: "form",
        allowAdding: true,
      },
      onRowValidating: (e) => {
        if (!e.newData?.file?.key && !e.oldData?.file?.key) {
          e.errorText = "Documento não anexado";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        caption: "Tipo de documento",
        dataField: "documentType",
        format: (value) =>
          ({
            REPORT: "Laudo",
            DEED: "Escritura",
            CONTRATO_AF: "Contrato AF",
          }[value] || value),
        lookup: {
          dataSource: [
            { value: "REPORT", label: "Laudo" },
            { value: "DEED", label: "Escritura" },
            { value: "CONTRATO_AF", label: "Contrato AF" },
          ],
          valueExpr: "value",
          displayExpr: "label",
        },
        validationRules: [{ type: "required" }],
      },
      {
        visible: false,
        caption: "Arquivo",
        dataField: "file",
        editCellRender: (prop) => <CollateralDocumentsUpload {...prop} />,
      },
      {
        caption: "Nome do arquivo",
        dataField: "file.name",
        formItem: { visible: false },
      },
      {
        visible: false,
        dataField: "file.key",
        formItem: { visible: false },
      },
      {
        type: "buttons",
        caption: "Ações",
        buttons: [
          {
            hint: "Download",
            icon: "fa fa-download",
            onClick: async ({ row }) => {
              try {
                if (!row?.data.file.key) return;

                OToastManager.info("Baixando arquivo...");

                const { data: blob } = await service.hubCreditManager.download({
                  key: row?.data.file.key,
                });

                helpers.downloadBlobFile(row?.data.file.name ?? "", blob);
                OToastManager.destroy();
              } catch {
                OToastManager.danger("Não foi possível baixar arquivo.");
              }
            },
          },
          {
            hint: "Apagar",
            icon: "fa fa-trash",
            onClick: async ({ component, row }) => {
              try {
                if (!row?.data.id) return;

                // OToastManager.info("Apagando documento...");
                // await service.hubCreditManager.deleteCollateralDocument(
                //   row?.data.id.toString(),
                // );

                // OToastManager.destroy();
                component.deleteRow(row.rowIndex);
              } catch {
                OToastManager.danger("Não foi possível apagar documento.");
              }
            },
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<CollateralDocument>);
