import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { service } from "services";
import { useServiceCall } from "hooks/service-call";
import { OptinViewForm, optinViewDefaultValues } from "./consulta-optins.form";

export const useOptinView = (
  input: string | undefined,
  setCnpjs: (arg0: string[]) => void,
) => {
  const form = useForm<OptinViewForm>({
    defaultValues: optinViewDefaultValues,
  });

  const { handleSubmit, reset, watch } = form;

  const watchEmpresas = watch("empresas");

  const clear = useCallback(() => {
    reset(optinViewDefaultValues);
  }, [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((loadOptions) => {
        if (input) setCnpjs([...loadOptions.empresas, input]);
        else setCnpjs(loadOptions.empresas);
      }),
    [handleSubmit, input, setCnpjs],
  );

  return {
    watchEmpresas,
    submit,
    form,
    clear,
  };
};

export const useOptOut = () => {
  const { callService: postOptOut, hasError: postOptOutHasError } =
    useServiceCall(service.recebiveisCartao.postOptOut);

  return {
    postOptOut,
    postOptOutHasError,
  };
};
