import { Power } from "./company-acts.types";

export const statusMap = {
  ATIVO: {
    label: "Ativo",
    type: "success" as const,
  },
  REPROVADO: {
    label: "Reprovado",
    type: "danger" as const,
  },
  DOUBLE_CHECK: {
    label: "Double check",
    type: "info" as const,
  },
  EM_PARAMETRIZAÇÃO: {
    label: "Em parametrização",
    type: "info" as const,
  },
  VENCIDO: {
    label: "Vencido",
    type: "danger" as const,
  },
  PROXIMO_VENCIMENTO: {
    label: "Próximo do vencimento",
    type: "warning" as const,
  },
  INDEFINIDO: {
    label: "Indefinido",
    type: "warning" as const,
  },
};

export const hasUnregisteredUsers = (power: Power) =>
  [
    ...(power.representatives ?? []),
    ...(power.groups?.flatMap((group) => group.representatives) ?? []),
  ].some((representative) => !representative.isCompanyUser);
