import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { MinimumCriteriaForm } from "../../minimum-criteria-form.component";
import {
  minimumCriteriaFormDefaultValues,
  minimumCriteriaFormValidationSchema,
} from "../../minimum-criteria-form.schemas";
import {
  WorkflowProdutoMinimumCriteriaById,
  WorkflowProdutoMinimumCriteriaRouteQueryParms,
} from "pages/workflow/routes/workflow.route-params";

export const EditMinimumCriteriaPage = () => {
  const { id } = useParams<WorkflowProdutoMinimumCriteriaById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoMinimumCriteriaRouteQueryParms>(
      "data",
    );
  const form = useForm({
    resolver: yupResolver(minimumCriteriaFormValidationSchema),
    defaultValues: minimumCriteriaFormDefaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    value &&
      reset({...value, isVisible: value.isVisible ? ["1"] : []});
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate pageTitle={<PageTitle title="Editar critério mínimo" />}>
          <FormProvider {...form}>
            <MinimumCriteriaForm mode="edit" id={id} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};