import { useEffect, useMemo } from "react";
import { DOTS, range } from "./custom-pagination.utils";

export const useCustomPagination = (
  totalCount: number,
  currentPage: number,
  setCurrentPage: (currentPage: number) => void,
  selectedPaginationSize: number,
) => {
  const pageCount = Math.ceil(totalCount / selectedPaginationSize);

  const showLeftDots = currentPage > 3;
  const showRightDots = currentPage < pageCount - 3;

  useEffect(() => {
    if (currentPage > pageCount && pageCount > 0) {
      setCurrentPage(pageCount);
    }
  }, [currentPage, pageCount, setCurrentPage]);

  const paginationRange = useMemo(() => {
    // FirstPage + lastPage + currentPage + 2*neighborPages + 2*dots
    const totalPageNumbers = 7;

    if (totalPageNumbers >= pageCount) {
      return range(1, pageCount);
    }

    if (!showLeftDots && showRightDots) {
      const leftItemCount = 5;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, pageCount];
    }

    if (showLeftDots && !showRightDots) {
      const rightItemCount = 5;
      const rightRange = range(pageCount - rightItemCount + 1, pageCount);

      return [1, DOTS, ...rightRange];
    }

    const middleRange = range(currentPage - 1, currentPage + 1);
    return [1, DOTS, ...middleRange, DOTS, pageCount];
  }, [currentPage, pageCount, showLeftDots, showRightDots]);

  return {
    pageCount,
    paginationRange,
  };
};
