import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

interface EligibilityTypeFieldProps {
  required?: boolean;
}

export const EligibilityTypeField = ({
  required,
}: EligibilityTypeFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:tipo_elegibilidade"
        dataLabel="tipo_elegibilidade"
        id="eligibilityType"
        name="eligibilityType"
        tag="text"
        label={EstrategiaFormLabels.eligibilityType}
        placeholder="Ex: SKIP"
        required={required}
      />
    </OCol>
  );
};
