import { EmailModal, OverflowWithModal, ShadowContainer } from "./_compose";

interface ContentFrameProps {
  note: HubEnergy.DiligenceStepPendencyNoteSimpleResponse;
}

export const ContentFrame = ({ note }: ContentFrameProps) => {
  if (note.content.match(/<\/\w+?>/)) {
    return (
      <OverflowWithModal
        maxHeight={150}
        modalContent={<EmailModal note={note} />}
      >
        <ShadowContainer>
          <div dangerouslySetInnerHTML={{ __html: note.content }} />
        </ShadowContainer>
      </OverflowWithModal>
    );
  }

  return <>{note.content}</>;
};
