import { Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface DiligenceForm {
  migrationDate: string;
}

export const diligenceFormValidationSchema = yup.object<
  DiligenceForm,
  YupObjectSchema<DiligenceForm>
>({
  migrationDate: yup.string().required("Campo obrigatório"),
});

export const diligenceFormDefaultValues = {
  migrationDate: null,
} satisfies Partial<Nullable<DiligenceForm>> as unknown as DiligenceForm;
