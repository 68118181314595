import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Sacado } from "./company-sacados.types";

export const buildDataSource = (id: string | number) =>
  dataSourceCustomStoreGenerator<Sacado>(() =>
    service.quickfin
      .getCompanySacados(id)
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os sacados";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

const buildDetailsLink = (id: number, estrategia: number) => {
  return corporateRouter.routes.recebiveis.customer.sacados.details.path({
    sacadoId: id,
    strategyId: estrategia,
  });
};

export const companySacadosGrid: ODataGridGeneratorConfig<Sacado> = {
  datagrid: {
    noDataText: "Nenhum sacado",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.recebiveis.customer.sacadosDetailsPage.role,
      cellRender: ({ data }) =>
        !!data.id &&
        !!data.estrategia.id && (
          <DetailsLink href={buildDetailsLink(data.id, data.estrategia.id)} />
        ),
    },
    {
      dataField: "officialName",
      caption: "Nome",
      allowHeaderFiltering: false,
    },
    {
      dataField: "identification",
      caption: "Identificação",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpfCnpj(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "companySacadoStatus",
      caption: "Status",
    },
    {
      dataField: "identificadorExterno",
      caption: "Referência externa",
      allowHeaderFiltering: false,
    },
    {
      dataField: "onboardingCompletoStatus",
      caption: "Onboarding completo",
    },
    {
      dataField: "onboardingSimplesStatus",
      caption: "Onboarding simples",
    },
    {
      dataField: "estrategia.nome",
      caption: "Estratégia",
    },
    {
      dataField: "country",
      caption: "País",
    }
  ],
};
