import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { MonitoringTemplate } from "../../../../cadastro/components/monitoramento";
import { eloasMonitoringGrid } from "./eloas-monitoring.grid";

export const EloasMonitoring = () => {
  const gridRef = useGridRef();
  return (
    <>
      <PageTitle title="Monitoramento Procuração eletrônica" />
      <MonitoringTemplate
        filterFields={[
          {
            label: "Nome",
            property: "CompanyName",
          },
          {
            label: "TaxId",
            property: "CompanyIdentification",
          },
          {
            label: "Nome do Representante",
            property: "UserName",
          },
          {
            label: "TaxId do Representante",
            property: "UserIdentification",
          },
        ]}
        relationship="ROLEASSIGNMENT"
        gridBuilder={eloasMonitoringGrid}
        gridRef={gridRef}
      />
    </>
  );
};
