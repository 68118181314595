import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import {
  modalManager,
  OButton,
  OField,
  OIcon,
  OToastManager,
  OTooltip,
} from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCallback, useEffect, useRef, useState } from "react";
import { service } from "services";
import { useUser } from "contexts/user";
import { getSearchParams } from "utils/search-params/get-search-params";
import { GetCreditConciliationParams } from "services/fidc/models/requests/get-credit-conciliation.request";
import { CreditConciliationDashboard } from "./credit-conciliation-dashboard";
import { filesGrid } from "./credit-conciliation-details.grid";
import {
  CancelApprovalModal,
  ConfirmationApprovalModal,
} from "./confirmation-modal";
import { UploadFileProcess } from "./upload-modal";
import { StatusBadgeComponent } from "../_compose/file-status-badge/file-status-badge.component";
import {
  FullcreenButtonBtn,
  OInputNumberStyled,
} from "./credit-conciliation-details.styles";

type ViewCreditConciliationParams = GetCreditConciliationParams;

interface ConciliationDetails {
  fund: string;
  status: string;
  paymentTypeDetails: Fidc.CreditConciliationsDetailItem[];
  filesHistory: Fidc.CreditConciliationFiles;
}

export const CreditConciliationDetails = () => {
  const { orderId, fundName } = getSearchParams<ViewCreditConciliationParams>();
  if (!orderId || !fundName) throw new Error("No url parameters");

  const [loading, setLoading] = useState(false);
  const [creditConciliation, setCreditConciliation] =
    useState<ConciliationDetails>();

  const getConciliationDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await service.fidc.getCreditConciliationDetails(orderId);
      setCreditConciliation(data);
    } catch {
      OToastManager.danger("Erro na consulta de detalhes.");
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  const gridRef = useGridRef();
  const { user } = useUser();

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState<number>(10);

  useEffect(() => {
    getConciliationDetails();
  }, [getConciliationDetails]);

  const setIntervalLoad = () => {
    if (time > 300 || time < 1) {
      setTime(20);
      OToastManager.warning(
        "Valor não permitido. Defina um valor entre 1 segundo e 300 segundos.",
      );
      return;
    }

    OToastManager.info(`Atualização automática a cada ${time} segundos!`);
    getConciliationDetails();

    const timeMill = time * 1000;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      getConciliationDetails();
    }, timeMill);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  };

  const confirmationCreditConciliation = useCallback(async () => {
    try {
      await service.fidc.postConciliationRelease(orderId);
      gridRef.current?.instance.clearSelection();
      gridRef.current?.instance.getDataSource().reload();
      OToastManager.success("Aprovação enviada ao CROS com sucesso.");
    } catch {
      OToastManager.danger("Erro ao enviar aprovação ao CROS!");
    } finally {
      modalManager.hide("modal-confirmation-approval");
      getConciliationDetails();
    }
  }, [getConciliationDetails, gridRef, orderId]);

  const cancelCreditConciliation = useCallback(async () => {
    try {
      await service.fidc.postConciliationCancel(orderId);
      gridRef.current?.instance.clearSelection();
      gridRef.current?.instance.getDataSource().reload();
      OToastManager.success("Cancelamento enviada com sucesso.");
    } catch {
      OToastManager.danger("Erro ao cancelar!");
    } finally {
      modalManager.hide("modal-confirmation-cancel");
      getConciliationDetails();
    }
  }, [getConciliationDetails, gridRef, orderId]);

  const shouldShowUploadAndCancelButton = useCallback(() => {
    return (
      creditConciliation?.status === "WAITING_CUSTODIAN_VALIDATION" ||
      creditConciliation?.status === "WAITING_CUSTODIAN_RELEASE"
    );
  }, [creditConciliation]);

  const shouldShowApproveButton = useCallback(() => {
    return (
      creditConciliation?.filesHistory?.files?.some(
        (file: { agent: string; status: string; approvedAmount: string }) =>
          file.agent === "CROS" &&
          file.status === "WAITING_RELEASE" &&
          Number(file.approvedAmount) !== 0,
      ) && user.roles?.includes("FIDC:CreditConciliation.approval")
    );
  }, [creditConciliation, user]);

  return (
    <DetailsTemplate
      pageTitle={
        <div className="d-flex flex-row justify-content-between ms-2">
          <PageTitle title={`Detalhes do lote ${fundName}`} />
          <OTooltip floating position="left">
            <span slot="tooltip-content">
              Definir valor em segundos para recarregar dados.
            </span>
            <div className="d-flex justify-content-between align-items-center">
              <OField htmlFor="time" label="Definir tempo atualizar:">
                <OInputNumberStyled
                  id="time"
                  name="time"
                  placeholder="Segundos"
                  value={time}
                  onInput={(e: any) => setTime(e.target.value)}
                  max={300}
                  min={1}
                  size="sm"
                  aspect="unstyled"
                  style={{ textAlignLast: "center" }}
                />
              </OField>
              <div className="d-flex align-items-center gap-2 ms-2">
                <FullcreenButtonBtn
                  onClick={setIntervalLoad}
                  title="Definir tempo"
                >
                  <OIcon category="fas" icon="fa-sync" type="light" size="sm" />
                </FullcreenButtonBtn>
              </div>
            </div>
          </OTooltip>
        </div>
      }
    >
      <div className="d-flex flex-column gap-4">
        <StatusBadgeComponent status={creditConciliation?.status} />
        <CreditConciliationDashboard
          paymentTypeDetails={creditConciliation?.paymentTypeDetails}
          loading={loading}
        />
        <GridTemplate
          pageTitle={
            <PageTitle
              title="Histórico"
              description="Histórico do arquivo de lote para processamento."
            />
          }
          actions={
            <div className="d-flex justify-content-end gap-4">
              {shouldShowApproveButton() && (
                <OButton
                  dataAction="botao:aprovar"
                  dataLabel="aprovar"
                  type="success"
                  onClick={() =>
                    modalManager.show("modal-confirmation-approval")
                  }
                >
                  Aprovar
                </OButton>
              )}
              {shouldShowUploadAndCancelButton() && (
                <>
                  <OButton
                    dataAction="botao:aprovar"
                    dataLabel="aprovar"
                    type="danger-light"
                    onClick={() =>
                      modalManager.show("modal-confirmation-cancel")
                    }
                  >
                    Cancelar
                  </OButton>
                  <OButton
                    dataAction="botao:upload"
                    dataLabel="upload"
                    type="info"
                    onClick={() => modalManager.show("upload-file")}
                  >
                    Upload do arquivo
                  </OButton>
                </>
              )}
              <OTooltip floating position="top">
                <span slot="tooltip-content">Atualizar</span>
                <RefreshGridButton onClick={() => getConciliationDetails()} />
              </OTooltip>
            </div>
          }
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={filesGrid}
            dataSource={creditConciliation?.filesHistory.files}
            loading={loading}
          />
        </GridTemplate>
      </div>
      <UploadFileProcess orderId={orderId} />
      <ConfirmationApprovalModal
        id="modal-confirmation-approval"
        isConfirmationModalProcessing={loading}
        onConfirm={async () => {
          confirmationCreditConciliation().then(() =>
            modalManager.hide(orderId),
          );
        }}
      />
      <CancelApprovalModal
        id="modal-confirmation-cancel"
        isConfirmationModalProcessing={loading}
        onConfirm={async () => {
          cancelCreditConciliation().then(() => modalManager.hide(orderId));
        }}
      />
    </DetailsTemplate>
  );
};
