import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./operations.grid";

const dataSource = dataSourceCustomStoreGenerator<
  Awaited<
    ReturnType<typeof service.quickfin.getOperationsAttendance>
  >["data"]["value"][number]
>((loadOptions) => {
  return service.quickfin
    .getOperationsAttendance({
      top: loadOptions.take,
      skip: loadOptions.skip,
      orderBy: "CreatedAt desc",
      $count: true,
      TipoProduto: ["Cartao", "RiscoSacado", "Offshore"],
    })
    .then(({ data }) => ({
      data: data.value,
      totalCount: data["@odata.count"],
    }))
    .catch(() => {
      const errorMessage = "Erro ao buscar as operações";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    });
});

export const Operations = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
