import { OBadge, OField, OIcon, OInputText } from "@maestro/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { UnstyledButton } from "../unstyled-button";
import { MultiInputProps } from "./multi-input.types";

export const MultiInput = (props: MultiInputProps) => {
  const { onChange, label, ...rest } = props;

  const [inputValue, setInputValue] = useState("");

  const form = useFormContext();
  const { watch } = form;
  const watchInput = watch(rest.id) as string[] | undefined;

  return (
    <div className="d-flex flex-column gap-2">
      <OField label={label} htmlFor="input">
        <OInputText
          {...rest}
          value={inputValue}
          onInput={(e) => {
            const { value } = e.target as HTMLOInputTextElement;

            if (!value) return;

            setInputValue(value);
          }}
          onKeyDown={(e) => {
            if (!inputValue || !["Enter", "Tab"].includes(e.key)) return;
            e.preventDefault();

            onChange([...(watchInput || []), inputValue]);
            setInputValue("");
          }}
        />
      </OField>

      {!!watchInput?.length && (
        <div className="d-flex flex-wrap gap-2">
          {watchInput.map((input) => (
            <OBadge key={input} type="dark" size="sm" rounded outline>
              <div className="d-flex align-items-center gap-2">
                {input}
                <UnstyledButton
                  onClick={() => {
                    const filteredInput = watchInput.filter((i) => i !== input);

                    onChange(filteredInput);
                  }}
                >
                  <OIcon category="fal" icon="fa-times" />
                </UnstyledButton>
              </div>
            </OBadge>
          ))}
        </div>
      )}
    </div>
  );
};
