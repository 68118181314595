import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services";
import { newCreateAccountOptin } from "../../create-accounts-optin.form";
import { CreateAccountOptinForm } from "../../create-accounts-optin.type";

export const useCreateAccountsForm = () => {
  const form = useFormContext<CreateAccountOptinForm>();

  const {
    control,
    formState: {
      errors: { createAccountOptin: createAccountOptinErrors },
    },
  } = form;

  const fieldArray = useFieldArray({
    control,
    name: "createAccountOptin",
  });

  const { append, remove, fields } = fieldArray;

  const { callService: getTemplates, value: templates } = useServiceCall(
    service.onboardingCoreAccount.getTemplates,
  );

  const [officers, setOfficers] = useState<string[]>([]);

  const handleTemplates = useCallback(async () => {
    await getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    handleTemplates();
  }, [handleTemplates]);

  const getOfficers = useCallback(() => {
    if (templates) {
      const officersFiltered = templates.map(
        (template) => template.officerName,
      );

      const uniqueOfficers = [...new Set(officersFiltered)];

      setOfficers(uniqueOfficers);
    }
  }, [templates]);

  useEffect(() => getOfficers(), [getOfficers]);

  useEffect(() => {
    !fields.length &&
      append(
        newCreateAccountOptin as CreateAccountOptinForm["createAccountOptin"][number],
      );
  }, [append, fields.length]);

  return {
    handleTemplates,
    officers,
    templates,
    createAccountOptinErrors,
    fields,
    append,
    remove,
  };
};
