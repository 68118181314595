import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetCalendarResponse } from "services/legacys/models";

export const grid = (
  onDelete: (calendar: GetCalendarResponse) => void,
): ODataGridGeneratorConfig<GetCalendarResponse> => ({
  datagrid: {
    noDataText: "Nenhum calendário encontrado.",
  },
  columns: [
    {
      allowExporting: false,
      role: roles.recebiveis.product.calendarsDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.recebiveis.product.calendars.details.path(
            {
              calendarId: data.id,
            },
          )}
        />
      ),
    },
    {
      dataField: "name",
      caption: "Calendário",
    },
    {
      dataField: "baseCalendar",
      caption: "Calendário base",
      cellRender: ({ data }) => data.baseCalendar.name,
    },
    {
      dataField: "offDays",
      caption: "Feriados adicionais",
      dataType: "number",
      cellRender: ({ value }) => {
        return value.length;
      },
    },
    {
      dataField: "updatedAt",
      caption: "Atualizado em",
      dataType: "date",
      format: "shortDateShortTime",
    },
    {
      dataField: "createdAt",
      caption: "Criado em",
      dataType: "date",
      format: "shortDateShortTime",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              onClick: () => onDelete(data),
            },
          ]}
        />
      ),
    },
  ],
});
