import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  InviteTypeFormValues,
  OnboardingInviteTypeForm,
  inviteTypeFormValidationSchema,
} from "../_compose";

export const AddInviteTypePage = () => {
  const form = useForm<InviteTypeFormValues>({
    resolver: yupResolver(inviteTypeFormValidationSchema),
  });
  const { handleSubmit } = form;

  const navigate = useNavigate();

  const { callService: addInviteType, loading: submitLoading } = useServiceCall(
    service.clerk.createEmailConfigsType,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addInviteType(values);

        if (!success)
          return OToastManager.danger("Erro ao criar tipo de convite.");

        navigate(
          corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs
            .inviteTypes.path,
        );
        return OToastManager.success("Tipo de convite criado com sucesso!");
      }),
    [addInviteType, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar tipo de convite" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OnboardingInviteTypeForm form={form} />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
