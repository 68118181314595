import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const LastDayContestationsCard = () => {
  return (
    <InfoCard
      summaryType="contestationsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Contestações no último dia"
          body={_value.lastDayQuantity.toLocaleString("pt-br")}
          footer="Número de contestações criadas no dia anterior"
        />
      )}
    />
  );
};
