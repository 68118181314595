import { OLoader, modalManager } from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { Contract } from "../../contracts-grid.types";
import { EditContractModal } from "../edit-contract-modal";
import { editContractModalId } from "../edit-contract-modal/edit-contract-modal.utils";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  contract: Contract;
  reload: () => void;
}

export const GridActions = ({ contract, reload }: GridActionsProps) => {
  const {
    downloadContract,
    downloadSignedContract,
    reprocessContracts,
    resendNotifications,
    sendSignedContractToLess,
    reprocessSignatories,
    loading,
  } = useGridActions(contract);

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-download" },
              label: "Download do contrato",
              onClick: () => downloadContract(),
            },
            {
              icon: { category: "fas", icon: "fa-download" },
              label: "Download do contrato assinado",
              onClick: () => downloadSignedContract(),
            },
            {
              icon: { category: "far", icon: "fa-edit" },
              label: "Editar",
              onClick: () =>
                modalManager.show(
                  editContractModalId((contract.UUID as any)._value),
                ),
            },
            {
              icon: { category: "far", icon: "fa-paper-plane" },
              label: "Reenviar notificações",
              onClick: () => resendNotifications(),
              visible: contract.Status === "WaitingSignatures",
            },
            {
              icon: { category: "far", icon: "fa-file-contract" },
              label: "Reprocessar contrato",
              onClick: () => reprocessContracts(),
              visible: contract.ExternalPartnerId === "HubOnboard",
            },
            {
              icon: { category: "far", icon: "fa-arrow-alt-circle-right" },
              label: "Enviar contrato para o LESS",
              onClick: () => sendSignedContractToLess(),
              // visible: contract.Status === "FullySigned",
            },
            {
              icon: { category: "orq", icon: "orq-associations-on" },
              label: "Reprocessar assinantes",
              onClick: () => reprocessSignatories(),
              visible: [
                "WaitingSignatures",
                "InErrorSignatoryInfoNotFounded",
              ].includes(contract.Status),
            },
          ]}
        />
      )}
      <EditContractModal contract={contract} reload={reload} />
    </>
  );
};
