export const occurrenceTypeMap = {
  DOCUMENT: "Documento",
  ENTITY: "Entidade",
};

export const documentTypeMap = {
  INDEFINIDO: "Indefinido",
  CONTRATO_DE_UNIAO_ESTAVEL: "Contrato de união estável",
  IDENTIDADE: "Identidade",
  IDENTIDADE_CONJUGE: "Identidade cônjuge",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  COMPROVANTE_DE_RESIDENCIA_ELETRONICO: "Comprovante de residência eletrônico",
  CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
};
