import { OToastManager } from "@maestro/core";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../utils";

interface CreateOperationButtonProps {
  tradeId: number;
}

export const CreateOperationButton = ({
  tradeId,
}: CreateOperationButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createOperation,
  );

  const createOperation = useCallback(async () => {
    const { success, error } = await callService({
      tradeId,
    });

    if (success) {
      OToastManager.success("Operação criada com sucesso");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao criar a operação",
      );
    }
  }, [callService, tradeId]);

  return (
    <ButtonWithConfirmation
      confirmationModal={{
        title: "Tem certeza que deseja criar a operação?",
        divider: false,
        loading,
      }}
      onConfirm={createOperation}
      disabled={loading}
    >
      Criar operação
    </ButtonWithConfirmation>
  );
};
