import dayjs from "dayjs";

export const SideFilterBarModalId = "side-filter-bar-modal-id";

export interface SideFilterBarForm {
  [x: string]: any;
}

export interface FormFieldsToFilter {
  [x: string]: NonNullable<any>;
}

export interface FiltersToSubmit {
  [x: string]: string | string[];
}

export interface RefreshButtonProps {
  /** @param {void} handleRefresh Função executada após o evento de click no botão */
  handleRefresh: () => void;
  /** @param {boolean} [disabled] Parâmetro que desabilita o click no botão */
  disabled?: boolean;
  /** @param {boolean} [title] Parâmetro title do CSS @defaultValue "Atualizar dados" */
  title?: string;
}

export type Section =
  | MultSelectionSectionFilter
  | SingleSelectionSectionFilter
  | DateRangeSectionFilter
  | CustomLabelSectionFilter
  | IntervalAmountSectionFilter
  | SelectableSearchSectionFilter
  | IntervalCountSectionFilter
  | MultFinancialInstitutionSelectionSectionFilter;

export interface SideFilterBarProps {
  sections: Section[];
  submitFilters: (data: Record<string, unknown>) => void;
  defaultValuesSubmitted?: boolean;
  refreshButton?: RefreshButtonProps;
}

export enum SectionFilterType {
  SingleSelection,
  MultSelection,
  DateRange,
  CustomLabel,
  IntervalAmount,
  IntervalCount,
  SelectableSearch,
  MultFinancialInstitution,
}

export interface BaseSectionFilter {
  title: string;
  formId: string;
  type: SectionFilterType;
}

export interface SelectionOption {
  label: { primary: string; secondary?: string; imageUrl?: string };
  value: string;
}

export interface MultSelectionSectionFilter extends BaseSectionFilter {
  data: SelectionOption[];
  defaultValue: string[] | undefined;
  type: SectionFilterType.MultSelection;
}

export interface SingleSelectionSectionFilter extends BaseSectionFilter {
  data: SelectionOption[];
  defaultValue: string | undefined;
  type: SectionFilterType.SingleSelection;
}

export interface DateRangeSectionFilter extends BaseSectionFilter {
  defaultValue: [dayjs.Dayjs, dayjs.Dayjs] | undefined;
}

export interface CustomLabelSectionFilter extends BaseSectionFilter {
  maskOption?: IMask.AnyMaskedOptions;
  unmaskedValidator?: (value: string) => boolean;
  placeholder: string;
  defaultValue: string[] | undefined;
  type: SectionFilterType.CustomLabel;
}

export type IntervalValue = [number, number];

export interface IntervalAmountSectionFilter extends BaseSectionFilter {
  defaultValue: IntervalValue | undefined;
  type: SectionFilterType.IntervalAmount;
}

export interface IntervalCountSectionFilter extends BaseSectionFilter {
  defaultValue: IntervalValue | undefined;
  type: SectionFilterType.IntervalCount;
}

export interface MultFinancialInstitutionSelectionSectionFilter
  extends BaseSectionFilter {
  data: Assets.FinancialInstitution[];
  defaultValue: string[] | undefined;
  type: SectionFilterType.MultFinancialInstitution;
}

export interface SelectableSearchValue {
  filterParam: string;
  value: string;
}

interface SelectableSearchOption {
  filterParam: string;
  label: string;
  maskOption?: IMask.AnyMaskedOptions;
  unmaskedValidator?: (value: string) => boolean;
  inputValueFormatter?: (value: string) => string;
}

export interface SelectableSearchSectionFilter extends BaseSectionFilter {
  data: SelectableSearchOption[];
  defaultValue: SelectableSearchValue | undefined;
  type: SectionFilterType.SelectableSearch;
}
