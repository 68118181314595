import * as yup from "yup";

export interface OfferFormValues {
  title: string;
  subtitle: string;
  label: string;
  description: string;
  file?: File[];
}

export const offerFormValidationSchema = yup.object().shape({
  title: yup.string(),
  subtitle: yup.string(),
  label: yup.string(),
  description: yup.string(),
  file: yup.array().nullable(),
});

export const offerFormDefaultValues = {
  title: "",
  subtitle: "",
  label: "",
  description: "",
  file: [] as File[],
};
