import { OToastManager, modalManager } from "@maestro/core";
import { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { GetAdminIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { service } from "services/service";
import { incorrectordersModalIds } from "./incorrect-orders-modal.utils";

export const useIncorrectOrdersModal = (
  closes: boolean,
  gridRef: React.RefObject<DataGrid>,
) => {
  const { callService, loading } = useServiceCall(
    service.hubFx.changeIncorrectOrderIsActive,
  );

  const modalId = incorrectordersModalIds[closes ? "close" : "reopen"];

  const submitChanges = useCallback(
    async (orders: GetAdminIncorrectOrdersResponse) => {
      const body = {
        incorrectOrders: orders.map((order) => ({
          id: order.id,
          isActive: !closes,
        })),
      };

      const { success } = await callService(body);
      modalManager.hide(modalId);
      gridRef.current?.instance.getDataSource().reload();

      if (success) {
        OToastManager.success("Ordens alteradas com sucesso!");
      } else {
        OToastManager.danger("Erro ao alterar status das ordens selecionadas.");
      }
    },
    [callService, closes, gridRef, modalId],
  );

  return {
    loading,
    modalId,
    submitChanges,
  };
};
