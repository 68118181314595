import { EligibilityCriteria } from "../../../../eligibility-tree.types";
import { CriteriaFields } from "../../edit-eligibility-group-modal.types";

export const mapCriteriaFields = (eligibilityCriteria: EligibilityCriteria[]) =>
  eligibilityCriteria.reduce((mappedCriteria, eligibilityCriterium) => {
    eligibilityCriterium.criteriaParams.forEach((criteriaParam) => {
      mappedCriteria.push({
        criterium: eligibilityCriterium.type,
        operation: criteriaParam.operation,
        value: criteriaParam.value,
        active: criteriaParam.active,
        isNewlyCreated: criteriaParam.isNewlyCreated,
        criteriaParamsId: criteriaParam.id ?? 0,
        eligibilityCriteriaId: eligibilityCriterium.id ?? 0,
      });
    });

    return mappedCriteria;
  }, [] as CriteriaFields[]);
