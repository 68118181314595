import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { GridTemplate } from "templates/grid-template";
import { useContactsFavored } from "./contacts.hook";

export const ContactsFavored = () => {
  const {
    canCreateCompanyContact,
    createCompanyContact,
    dataSource,
    grid,
    isLoading,
    reloadGrid,
  } = useContactsFavored();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contatos" />}
      actions={
        <div className="d-flex align-items-center gap-3">
          <RefreshGridButton onClick={reloadGrid} />
          {canCreateCompanyContact && (
            <LoadingButton loading={isLoading} onClick={createCompanyContact}>
              Criar contato da empresa
            </LoadingButton>
          )}
        </div>
      }
    >
      <ODataGridGenerator grid={grid} dataSource={dataSource} />
    </GridTemplate>
  );
};
