import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { workflowConsumerUnitsGrid } from "./workflow-consumer-units.grid";

export const WorkflowConsumerUnitsPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  const gridRef = useGridRef();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getWorkflowLeadsEnergyConsumerUnits(id)
          .then(({ data }) => data ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar unidades consumidoras");
          }),
      ),
    [id],
  );

  const grid = useMemo(() => workflowConsumerUnitsGrid(id), [id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Unidades consumidoras" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
