import { OToastManager } from "@maestro/core";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { GetBatchGetByCnpjResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { POOLING_TIMEOUT } from "./anticipations.utils";

export const useAnticipations = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [automaticFetch, setAutomaticFetch] = useState(true);
  const [anticipations, setAntecipations] =
    useState<GetBatchGetByCnpjResponse>();
  const form = useForm();
  const { handleSubmit } = form;
  const taxIdRef = useRef<string>();
  const automaticFetchRef = useRef<boolean>();

  const getAnticipations = async () => {
    if (!taxIdRef.current) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.fidc.getFidcBatches({
        cnpj: taxIdRef.current,
      });
      setAntecipations(data);
    } catch {
      OToastManager.danger("Nenhuma antecipação disponível");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    automaticFetchRef.current = automaticFetch;

    if (automaticFetch && !!anticipations) {
      const pooling = () => {
        if (automaticFetchRef.current) {
          getAnticipations();
        }
      };

      setTimeout(pooling, POOLING_TIMEOUT);
    }
  }, [anticipations, automaticFetch]);

  const onSubmit = handleSubmit(async (values) => {
    const cleanTaxId = values.cnpj.replace(/[/.-]/g, "");

    taxIdRef.current = cleanTaxId;

    getAnticipations();
  });

  return {
    anticipations,
    automaticFetch,
    form,
    hasError,
    loading,
    onSubmit,
    setAutomaticFetch,
  };
};
