import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { getValueFromMap } from "./company-group.utils";

export const grid: ODataGridGeneratorConfig<CompanyGroupAssociationsItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "taxId",
      caption: "CNPJ",
      format: (value) => masks.cpfCnpj(value),
    },
    { dataField: "officialName", caption: "Razão Social" },
    { dataField: "name", caption: "Nome" },
    {
      dataField: "status",
      caption: "Status",
      format: (value) => getValueFromMap(value),
    },
    { dataField: "link", caption: "Link" },
    { dataField: "rejectMessage", caption: "Mensagem" },
  ],
};
