import { OTypography } from "@maestro/react";
import { WalletState } from "./wallet-state.component";
import { MoanaOwnerStatus } from "services/moana/models";

interface CardStateProps {
  title: string;
  state?: MoanaOwnerStatus[];
}

export const CardState: React.FC<CardStateProps> = ({ title, state = [] }) => {
  return (
    <div style={{ boxSizing: "border-box" }}>
      <div className="d-flex flex-column gap-2 mb-3">
        <OTypography weight="500" size="sm" type="default">
          {title}
        </OTypography>
        <WalletState state={state ?? []} />
      </div>
    </div>
  );
};
