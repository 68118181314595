import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { profilesManagementGrid } from "./profiles-management.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.gatekeeper.getProfileManagementRequests().catch(() => {
    throw new Error("Erro ao buscar solicitações");
  }),
);

export const ProfilesManagementPage = () => {
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Minhas Solicitações" />}
      actions={
        <RouterButton
          dataAction="ferramentas_gatekeeper_gerenciamento_perfis:botao:nova_solicitacao"
          dataLabel="nova_solicitacao"
          href={
            routes.ferramentas.product.gatekeeper.profilesManagement.create.path
          }
        >
          Nova solicitação
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={profilesManagementGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
