import { DataGridAction } from "components/datagrid-action";
import { BankAccountsItem } from "services/admin-bankinghub/models";
import { modalManager } from "@maestro/core";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { FinancialInstitution } from "services/assets/models/types/assets";
import { ContactsFavoredDeleteModal } from "../contacts-favored-delete-modal";
import { assembleDefaultBankAccountValues } from "../../editar/contacts-favored-edit.utils";

export const ContactBanksGridActions = ({
  financialInstitutions,
  ...bankAccount
}: { financialInstitutions: FinancialInstitution[] } & BankAccountsItem) => {
  const navigate = useNavigate();

  return (
    <>
      <DataGridAction
        actions={[
          {
            label: "Excluir",
            icon: { category: "orq", icon: "orq-edit-trash" },
            onClick: () => modalManager.show("contacts-favored-delete-modal"),
          },
          {
            label: "Editar",
            icon: { category: "orq", icon: "orq-edit-pencil" },
            onClick: () =>
              navigate(
                corporateRouter.routes.banking.customer.contacts.edit.path({
                  uuid: bankAccount.id,
                }),
                {
                  state: assembleDefaultBankAccountValues(
                    bankAccount,
                    financialInstitutions,
                  ),
                },
              ),
          },
        ]}
      />
      <ContactsFavoredDeleteModal uuid={bankAccount.id} />
    </>
  );
};
