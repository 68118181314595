import {
  modalManager,
  OConfirmationModal,
  OToastManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { useRetryOptin } from "./optins-rejeitados.hooks";
import { useRejectedOptinContext } from "./optins-rejeitados.context";

export const confirmationModalId = "retry-optin-modal";

export const ConfirmationModal = () => {
  const { putRetryOptin, putRetryOptinHasError } = useRetryOptin();

  const { params } = useRejectedOptinContext();

  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={confirmationModalId}
      title="Realizar Opt-out"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={() => {
        putRetryOptin({ taxId: params.taxId }).then(() => {
          if (!putRetryOptinHasError)
            OToastManager.success("O Optin foi reenviado com sucesso");
          else {
            const errorMessage = "Erro ao reenviar o Optin";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        });
        modalManager.hide(confirmationModalId);
      }}
    >
      <div>
        {`Tem certeza que deseja refazer os optins de: ${
          params.companyName || "Empresa não registrada"
        } - CNPJ: ${masks.cnpj(params.taxId)} ?`}
      </div>
    </OConfirmationModal>
  );
};
