import { PaymentStatus } from "./payment.type";

export const PaymentStatusMap: Record<string, PaymentStatus> = {
  "Pagamento efetuado": {
    label: "PAGO",
    type: "success",
  },
  "Estorno para conta da empresa pagadora realizada": {
    label: "PAGO",
    type: "success",
  },
  "Aguardando horario para realizacao do credito na conta dos funcionarios": {
    label: "AGENDADO",
    type: "warning",
  },
  Aprovado: {
    label: "AGENDADO",
    type: "warning",
  },
  "Realizando transferencia para a conta transitoria": {
    label: "AGENDADO",
    type: "warning",
  },
  "Realizando transacoes validas": {
    label: "AGENDADO",
    type: "warning",
  },
  "Transacoes agendadas": {
    label: "AGENDADO",
    type: "warning",
  },
  "Aguardando Aprovacao": {
    label: "APROVAÇÃO PENDENTE",
    type: "default",
  },
  "Validando transacoes": {
    label: "AGUARDANDO ANÁLISE",
    type: "info",
  },
  "Verificando necessidade de estorno para conta da empresa": {
    label: "AGUARDANDO ANÁLISE",
    type: "info",
  },
  "Estornando valores nao transferidos para conta da empresa": {
    label: "AGUARDANDO ANÁLISE",
    type: "info",
  },
  "Verificando necessidade de aprovaþao da folha de pagamentos": {
    label: "AGUARDANDO ANÁLISE",
    type: "info",
  },
  "Transacoes com erro": {
    label: "ERRO",
    type: "danger",
  },
  "Erro ao realizar a transferencia do montante para a conta transitoria": {
    label: "ERRO",
    type: "danger",
  },
  "Erro ao realizar a transferencia do estorno para empresa pagadora": {
    label: "ERRO",
    type: "danger",
  },
  "Cnab cancelado": {
    label: "CANCELADO",
    type: "danger",
  },
  Rejeitado: {
    label: "CANCELADO",
    type: "danger",
  },
};
