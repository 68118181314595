import {
  OButton,
  OIcon,
  OLabel,
  OOptionMultiple,
  ORFieldSelectMultiple,
  OSkeletonLine,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CedenteDetailsForm } from "../../cedente-details.types";
import { ContractComplementField } from "./contract-complement.component";
import { EmailOption } from "./contract-complement.types";
import { ContractComplementsLoading } from "./_compose/contract-complements-loading.component";
import { WrapCollapse } from "./_compose/wrap-collapse.component";

export const ContractComplements = ({
  emailsOptions,
  loadingEmails,
  loadingComplements,
  expanded = false,
}: {
  emailsOptions?: EmailOption[];
  loadingEmails: boolean;
  loadingComplements: boolean;
  expanded?: boolean;
}) => {
  const form = useFormContext<CedenteDetailsForm>();

  const { control, watch } = form;

  const fieldArray = useFieldArray({
    control,
    name: "contractComplement",
  });

  const inclusionListWatch = watch("contractComplementInclusionList");

  const { append, fields } = fieldArray;

  const EmailOptions = useMemo(
    () =>
      emailsOptions?.map((option) => (
        <OOptionMultiple key={option.taxId} value={option.taxId}>
          {option.email}
        </OOptionMultiple>
      )),
    [emailsOptions],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <OTypography className="mb-2" size="lg">
        Complementos do contrato
      </OTypography>

      <OLabel htmlFor="contractComplementInclusionList">
        E-mails notificados
      </OLabel>

      {loadingEmails ? (
        <OSkeletonLine />
      ) : (
        <ORFieldSelectMultiple
          id="contractComplementInclusionList"
          name="contractComplementInclusionList"
          placeholder="E-mails"
          value={inclusionListWatch || []}
        >
          {EmailOptions}
        </ORFieldSelectMultiple>
      )}
      <WrapCollapse expanded={expanded}>
        <div className="py-2">
          {loadingComplements ? (
            <ContractComplementsLoading />
          ) : (
            <>
              {fields.map((field, index) => (
                <ContractComplementField
                  key={field.id}
                  index={index}
                  fieldArray={fieldArray}
                />
              ))}
              <div className="d-flex justify-content-end">
                <OButton
                  onClick={() =>
                    append({
                      key: "",
                      value: "",
                    })
                  }
                >
                  <OIcon category="fa" icon="fa-plus" />
                </OButton>
              </div>
            </>
          )}
        </div>
      </WrapCollapse>
    </div>
  );
};
