import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  ORow,
  OTypography,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { EntitySearch } from "./_compose";
import { useStrategies } from "./strategies.hook";

export const Strategies = () => {
  const {
    loading,
    strategies,
    companyStrategies,
    addStrategy,
    deleteStrategy,
  } = useStrategies();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Estratégias" />}>
      {loading && <OLoader absolute />}

      <ORow align="start">
        <OCol xs={12} sm={6} lg={4}>
          {/* TODO: SelectSearch */}
          <EntitySearch strategies={strategies} addStrategy={addStrategy} />
        </OCol>

        <OCol xs={12} sm={6} lg={6} className="d-flex flex-column gap-4">
          <OTypography type="default">Estratégias selecionadas:</OTypography>

          <div className="d-flex flex-column gap-2">
            {companyStrategies &&
              companyStrategies.map((strategy) => {
                const title = `${strategy.estrategiaUniqueId} - ${strategy.name}`;

                return (
                  <OCard key={strategy.estrategiaUniqueId}>
                    <OCardBody className="d-flex justify-content-between align-items-center">
                      <OTypography tag="span">{title}</OTypography>

                      <OButton
                        aria-label="excluir estratégia"
                        dataLabel="excluir"
                        dataAction="estrategias:botao:excluir"
                        type="dark"
                        outline
                        onClick={() =>
                          deleteStrategy(strategy.estrategiaUniqueId)
                        }
                      >
                        <OIcon category="orq" icon="orq-edit-trash" />
                      </OButton>
                    </OCardBody>
                  </OCard>
                );
              })}
          </div>
        </OCol>
      </ORow>
    </DetailsTemplate>
  );
};
