import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import DataGrid from "devextreme-react/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { AdvisoredEntity } from "services/gatekeeper/models/responses/get-gatekeeper-advisored-by.response";
import { buildCustomerLink } from "utils/build-customer-link";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";

const grid: ODataGridGeneratorConfig<AdvisoredEntity> = {
  datagrid: {
    noDataText: "Nenhum assessorado",
    filterRow: { visible: true },
    remoteOperations: false,
    pager: {
      showPageSizeSelector: true,
    },
    paging: {
      pageSize: 10,
    }
  },
  columns: [
    {
      dataField: "account_owner.name",
      dataType: "string",
      caption: "Nome",
      alignment: "left",
    },
    {
      dataField: "account_owner.tax_id",
      dataType: "string",
      caption: "Identificador Fiscal",
      calculateDisplayValue: (data) => fastCpfCnpjMask(data.account_owner.tax_id),
      calculateFilterExpression: function (this: any, filterValue: string, selectedFilterOperation, target) {
        return this.defaultCalculateFilterExpression(filterValue.replace(/\D/g, ""), selectedFilterOperation, target);
      }
    },
    {
      dataField: "branch",
      caption: "Agência",
      dataType: "string",
    },
    {
      dataField: "account",
      caption: "Conta",
      dataType: "string",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "fas", icon: "fa-link" },
              label: "Visualizar",
              route: buildCustomerLink(data.account_owner.tax_id)
            },
          ]}
        />
      ),
    },
  ],
};

const buildDataSource = (identificationTuple: `cpf:${string}` | "self") =>
  dataSourceCustomStoreGenerator<AdvisoredEntity>(async () => {
    if (identificationTuple)
      try {
        const data = await service.gatekeeper.getAdvisoredEntities(identificationTuple);
        return {
          data: data,
          totalCount: data.length,
        };
      } catch {
        const errorMessage = "Erro ao carregar os assessores!";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }

    return Promise.resolve({ data: [], totalCount: 0 });
  });

export const AdvisoredEntities = ({ identificationTuple, gridRef }: { identificationTuple: `cpf:${string}` | "self", gridRef?: React.Ref<DataGrid> }) => {
  const dataSource = useMemo(() => buildDataSource(identificationTuple), [identificationTuple]);

  return <ODataGridGenerator gridRef={gridRef} grid={grid} dataSource={dataSource} />;
};
