import {
  OCard,
  OCardBody,
  OCardHeader,
  ORow,
  OTooltip,
  OTypography,
} from "@maestro/react";
import dayjs from "dayjs";
import { StatusHistory } from "services/hubcreditmanager/models/types/workflow/workflow.types";

interface WorkflowLeadStatusCardProps {
  statusHistory: StatusHistory;
}

export const WorkflowLeadStatusCard = ({
  statusHistory,
}: WorkflowLeadStatusCardProps) => {
  return (
    <OTooltip floating position="top" type="info-light">
      <div
        slot="tooltip-content"
        className="d-flex flex-column align-items-start p-2"
      >
        <div className="d-flex gap-2">
          <OTypography type="dark-80" size="sm">
            Processador:
          </OTypography>
          <OTypography>{statusHistory.processorName}</OTypography>
        </div>
        <div className="d-flex gap-2">
          <OTypography type="dark-80" size="sm">
            Output:
          </OTypography>
          <OTypography>{statusHistory.outputName}</OTypography>
        </div>
        <div className="d-flex gap-2">
          <OTypography type="dark-80" size="sm">
            ProcessorOutputOnContext:
          </OTypography>
          <OTypography>
            {statusHistory.createdForProcessorOutputOnContextId}
          </OTypography>
        </div>
      </div>

      <OCard
        style={{ width: "max-content" }}
        type={!statusHistory.statusEndedAt ? "success-light" : "light"}
      >
        <OCardHeader>{statusHistory.name}</OCardHeader>
        <OCardBody className="d-flex flex-column gap-2">
          <ORow>
            <OTypography size="sm" type="dark-80">
              Início
            </OTypography>
            <OTypography>
              {dayjs(statusHistory.createdDate).format("DD/MM/YYYY HH:mm")}
            </OTypography>
          </ORow>
          <ORow>
            <OTypography size="sm" type="dark-80">
              Fim
            </OTypography>
            <OTypography>
              {statusHistory.statusEndedAt
                ? dayjs(statusHistory.statusEndedAt).format("DD/MM/YYYY HH:mm")
                : "-"}
            </OTypography>
          </ORow>
        </OCardBody>
      </OCard>
    </OTooltip>
  );
};
