import * as yup from "yup";

interface SigningPartyFormValues {
  ruleOrigin: string;
  parties: string[];
  minimumNumberOfSigners?: number | null;
  validFor?: string[] | null;
}

export interface ContractTemplateDetailsFormValues {
  name: string;
  signatureOrigin: string;
  file: File[];
  needsSignature?: string[] | null;
  geraNovosTermos?: string[] | null;
  hasComplement?: string[] | null;
  signingParties: {
    sacado: SigningPartyFormValues;
    cedente: SigningPartyFormValues;
  };
}

export const signingPartyFormValidationSchema: yup.ObjectSchema<SigningPartyFormValues> =
  yup.object({
    ruleOrigin: yup.string().required("Este campo é obrigatório"),
    parties: yup.array().required(),
    minimumNumberOfSigners: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    validFor: yup.array().optional().nullable(),
  });

export const contractTemplateDetailsFormValidationSchema: yup.ObjectSchema<ContractTemplateDetailsFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    signatureOrigin: yup.string().required("Este campo é obrigatório"),
    file: yup
      .array()
      .typeError("Este campo é obrigatório")
      .required("Este campo é obrigatório"),
    needsSignature: yup.array().optional().nullable(),
    geraNovosTermos: yup.array().optional().nullable(),
    hasComplement: yup.array().optional().nullable(),
    signingParties: yup.object({
      sacado: signingPartyFormValidationSchema,
      cedente: signingPartyFormValidationSchema,
    }),
  });
