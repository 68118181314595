import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const componentsGrid = {
  datagrid: {
    noDataText: "Nenhum componente encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getComponents().catch(() => {
        throw new Error("Erro ao buscar componentes.");
      }),
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      sortOrder: "asc",
    },
    {
      dataField: "name",
      caption: "Nome",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "type",
      caption: "Tipo",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "description",
      caption: "Descrição",
      validationRules: [{ type: "required" }],
    },
    {
      caption: "Ações",
      role: roles.workflow.product.formsManagerFormsDetailsPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          href={corporateRouter.routes.workflow.product.formsManager.components.edit.path(
            {
              id: data.id,
            },
          )}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubCreditManager.getComponents>>["data"]
  >[number]
>;
