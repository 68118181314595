import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";

export const REDIRECT_OPERATIONS_MODAL = "redirect-operations-modal";

export const RedirectOperationsModal = () => {
  const navigate = useNavigate();

  return (
    <OModal
      className="modal-show text-center"
      id={REDIRECT_OPERATIONS_MODAL}
      position="center"
      backdrop={false}
      size="xs"
    >
      <OModalHeader>
        <OTypography size="lg">
          Pré-pagamento solicitado com sucesso.
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div>
            <OTypography size="md">
              A solicitação de aprovação será enviada para o cliente. Após
              aprovada, o pré-pagamento será concluído.
            </OTypography>
          </div>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-2 align-items-center">
          <OButton
            type="light"
            onClick={() => {
              modalManager.hide(REDIRECT_OPERATIONS_MODAL);
              navigate(
                corporateRouter.routes.emprestimos.customer.operations.path,
              );
            }}
          >
            Voltar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
