import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { CompaniesRelationship } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { relationshipsGrid } from "./relationships.grid";

const dataSource = dataSourceODataStoreGenerator<CompaniesRelationship>(
  endpoints.quickfin.odata.companiesRelationshipsOData,
);

export const Relationships = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Relacionamentos"
          description="Visualize todos os relacionamentos"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={relationshipsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
