import { masks } from "@maestro/utils";
import { EllipsisTypography } from "./name-and-tax-id-formatted.styles";

interface NameAndTaxIdFormattedProps {
  name: string | null | undefined;
  subtexts?: (string | null | undefined)[] | null;
  taxId?: string | null;
}

export const NameAndTaxIdFormatted = ({
  name,
  subtexts,
  taxId,
}: NameAndTaxIdFormattedProps) => (
  <div className="d-flex flex-column">
    <EllipsisTypography tag="span" className="mb-1" title={name || undefined}>
      {name}
    </EllipsisTypography>

    {taxId && (
      <EllipsisTypography
        tag="span"
        size="sm"
        type="dark-80"
        title={masks.cpfCnpj(taxId) || undefined}
      >
        {masks.cpfCnpj(taxId)}
      </EllipsisTypography>
    )}

    {subtexts?.filter(Boolean).map((subtext) => (
      <EllipsisTypography
        key={subtext}
        tag="span"
        size="sm"
        type="dark-80"
        title={subtext || undefined}
      >
        {subtext}
      </EllipsisTypography>
    ))}
  </div>
);
