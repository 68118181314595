import {
  OButton,
  OLabel,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORadio,
  ORFieldInput,
  ORFieldRadioGroup,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { ADD_RELATIONSHIP_MODAL_ID } from "../../config-proposal-workflow.utils";
import { useAddRelationshipModal } from "./add-relationship-modal.hook";

export const AddRelationshipModal = () => {
  const {
    form,
    loading,
    processorOutputs,
    addRelationship,
    closeModal,
    trigger,
  } = useAddRelationshipModal();

  return (
    <OModal
      id={ADD_RELATIONSHIP_MODAL_ID}
      position="center"
      size="md"
      className="position-relative"
      onModalClose={closeModal}
    >
      <OModalHeader closeButton>
        <OTypography tag="h2" size="xl">
          Adicionar novo relacionamento
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <form>
            <div className="d-flex p-2">
              <OTypography className="mr-5 mt-4">Origem</OTypography>
              <ORFieldInput
                id="sourceName"
                name="sourceName"
                tag="text"
                label="Nome"
                size="md"
                disabled
              />
              <ORFieldInput
                id="sourceType"
                name="sourceType"
                tag="text"
                label="Tipo"
                size="md"
                disabled
              />
            </div>
            <div className="d-flex p-2">
              <OTypography className="mr-5 mt-4">Destino</OTypography>
              <ORFieldInput
                id="targetName"
                name="targetName"
                tag="text"
                label="Nome"
                placeholder=""
                size="md"
                disabled
              />
              <ORFieldInput
                id="targetType"
                name="targetType"
                tag="text"
                label="Tipo"
                size="md"
                disabled
              />
            </div>
            {loading && <OLoader absolute />}
            {!loading && (
              <ORFieldRadioGroup
                id="relationshipOutputId"
                name="relationshipOutputId"
                label="Tipo de relacionamento"
                required
                key={processorOutputs?.outputs?.length}
              >
                {processorOutputs?.outputs?.map((output) => {
                  return (
                    <div key={output.id} className="d-flex gap-3">
                      <ORadio
                        size="xs"
                        id={`option-${output.type}`}
                        value={String(output.id)}
                        key={`option-${output.type}`}
                      />
                      <OLabel htmlFor={`option-${output.type}`}>
                        {`${output.type} - ${output.description}`}
                      </OLabel>
                    </div>
                  );
                })}
              </ORFieldRadioGroup>
            )}
          </form>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-center gap-5">
          <OButton type="dark" outline onClick={closeModal}>
            Fechar
          </OButton>
          <OButton
            type="tertiary"
            disabled={loading}
            onClick={async () => {
              const isValid = await trigger();

              if (isValid) {
                addRelationship();
                closeModal();
              }
            }}
          >
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
