import { ORFieldInputDateRange } from "@maestro/react";
import { GetApiAdminBannersResponse } from "services/hubcreditmanager/models/responses";
import { FieldsMapContentProps } from "./filter-content.types";
import { statusMap } from "../../../../banner-management.utils";

const getUniqueValues = (
  data: GetApiAdminBannersResponse,
  key: string,
): string[] => {
  const path = key.split(".");

  return (
    data
      ?.map((row) => path.reduce((obj, part) => obj?.[part], row))
      .filter(Boolean)
      .map(String)
      .filter((value, index, self) => self.indexOf(value) === index) || []
  );
};

const getArrayFieldValues = (
  data: GetApiAdminBannersResponse,
  field: string,
) => {
  const path = field.split(".");

  return data
    ?.map((item) => path.reduce((acc, key) => acc?.[key], item))
    .filter(Boolean)
    .flat()
    .map((value) => JSON.stringify(value))
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((value) => JSON.parse(value));
};

const uniqueValuesFieldsMap = [
  { label: "Produtos", field: "badge", name: "badge" },
  {
    label: "Campanhas",
    field: "bannerTemplate.identification",
    name: "identification",
  },
  { label: "Responsáveis", field: "createdBy.name", name: "createdBy" },
];

const dateFieldsMap = [
  {
    label: "Data de início",
    name: "startingFrom",
  },
  {
    label: "Data final",
    name: "endingAt",
  },
];

export const getFieldsContent = ({
  clearSearchs,
  data,
  watch,
}: FieldsMapContentProps) => {
  const getUniqueFieldValues = (field: string) =>
    data && getUniqueValues(data, field);

  const watchStartingFrom = watch("startingFrom");
  const watchEndingAt = watch("endingAt");

  const uniqueValuesFields = uniqueValuesFieldsMap.map(
    ({ label, field, name }) => {
      const values = getUniqueFieldValues(field);
      return {
        label,
        name,
        values,
        visible: !!values?.length,
        clearSearchs,
      };
    },
  );

  const dateFields = dateFieldsMap.map(({ label, name }) => ({
    label,
    content: () => (
      <div className="p-3">
        <ORFieldInputDateRange
          id={name}
          name={name}
          size="md"
          value={name === "startingFrom" ? watchStartingFrom : watchEndingAt}
        />
      </div>
    ),
    visible: true,
  }));

  const accessRulesValues =
    data && getArrayFieldValues(data, "accessRules")?.map((rule) => rule.name);

  const allFields = [
    ...dateFields,
    {
      label: "Público alvo",
      values: accessRulesValues,
      name: "accessRules",
      visible: !!accessRulesValues?.length,
    },
    ...uniqueValuesFields,
    {
      label: "Status",
      name: "isActive",
      statusMap: Object.values(statusMap),
      visible: true,
    },
  ];

  return allFields.filter((item) => item.visible);
};
