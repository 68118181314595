import {
  OCard,
  OCardBody,
  OIcon,
  OLink,
  OLoader,
  ORFieldUploadInput,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { UploadContainer } from "../../../../distribuidora/adicionar-lote/upload-container.styles";
import { useUploadStep } from "./upload-step.hook";

interface UploadStepProps {
  setSheetData: (
    sheetData: HubEnergy.LegacyImport.ParseSpreadsheetResponse,
  ) => void;
}

export const UploadStep = ({ setSheetData }: UploadStepProps) => {
  const { form, loading, uploadSpreadsheet } = useUploadStep(setSheetData);

  return (
    <OCard>
      <OCardBody>
        <div className="mb-4">
          <OTypography>Regras da importação:</OTypography>
          <ul>
            <li>
              Todas as colunas, exceto <strong>ID COTACAO</strong> são
              obrigatórias
            </li>
            <li>
              A coluna <strong>ID COTACAO</strong> só é obrigatória em casos que
              mais de uma unidade consumidora tenha o mesmo CNPJ
            </li>
            <li>
              É necessário informar todas as unidades consumidoras associadas a
              um agrupamento
            </li>
            <li>
              Todas as unidades de um agrupamento devem ter a mesma matriz e o
              mesmo valor para <strong>CONCLUIU MIGRACAO</strong>
            </li>
          </ul>
        </div>
        <div className="position-relative">
          {loading && <OLoader absolute />}
          <FormProvider {...form}>
            <UploadContainer className="pt-4">
              <ORFieldUploadInput
                id="file"
                name="file"
                inputLabel="Clique ou arraste o arquivo aqui"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple={false}
                handleAddFile={({ detail: file }) => uploadSpreadsheet(file)}
                disabled={loading}
                value={[]}
              />
            </UploadContainer>
            <div className="d-flex flex-column align-items-center gap-3 mt-4">
              <OLink href="/files/modelo_importacao_unidades_consumidoras.xlsx">
                <OIcon category="fa" icon="fa-external-link" className="me-2" />
                Baixar modelo
              </OLink>
            </div>
          </FormProvider>
        </div>
      </OCardBody>
    </OCard>
  );
};
