import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { GetApiV1CorporateTaxParams } from "services/bankinghub/models";
import {
  AccountAdminCorporateTaxProps,
  CorporateTax,
  FormValuesType,
  filenameBaseByQuarterAndYear,
} from "./corporate-tax.types";
import { groupByYear, groupByYearsQuarters } from "./corporate-tax.utils";

export const useAccountAdminCorporateTax = ({
  account,
  agency,
}: AccountAdminCorporateTaxProps) => {
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);
  const [corporateTax, setCorporateTax] = useState<CorporateTax>();
  const [years, setYears] = useState<string[]>([]);

  const form = useForm<FormValuesType>();
  const { handleSubmit, watch } = form;

  const yearWatch = watch("year");
  const quarterWatch = watch("quarter");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const { year, quarter } = values;

      if (!corporateTax || !account || !agency || !year || !quarter) return;

      const fileData = corporateTax[year][quarter];
      const filenameBase = filenameBaseByQuarterAndYear(quarter, year);

      if (!fileData?.idArquivo) return;

      const params: GetApiV1CorporateTaxParams = {
        accountNumber: account,
        branch: agency,
        getOnlyParameters: false,
        fileId: fileData.idArquivo,
      };

      OToastManager.info("Aguarde, iniciando o donwload do arquivo");

      const { data } = await service.bankinghub.getCorporateTax(params);

      if (data) {
        const { fileData: quartersFiles } = data;

        const [file] = quartersFiles;
        const filename = `${filenameBase}_${customer.identification}${agency}_${account}.pdf`;

        helpers.downloadBase64(file.contentBody, filename);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        if (status === 401 || status === 403) {
          OToastManager.danger({
            title: "Sem autorização",
            message: "Erro ao obter informações de informe de rendimentos",
          });

          return;
        }
      }

      OToastManager.danger("Erro ao fazer download do informe de rendimentos");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    try {
      setLoading(true);

      if (!account || !agency) return;

      const params: GetApiV1CorporateTaxParams = {
        accountNumber: account,
        branch: agency,
        getOnlyParameters: true,
      };

      const { data } = await service.bankinghub.getCorporateTax(params);

      if (data) {
        const { fileData: quartersFiles } = data;

        if (!quartersFiles.length) {
          OToastManager.warning(
            "Nenhum informe de rendimentos disponível para a conta selecionada",
          );

          return;
        }

        const groupedYears = groupByYear(quartersFiles);
        setYears(Object.keys(groupedYears));

        const yearsQuartersGrouped = groupByYearsQuarters(groupedYears);
        setCorporateTax(yearsQuartersGrouped);
      }
    } catch (error) {
      OToastManager.danger("Erro para buscar dados do informe de rendimentos");
    } finally {
      setLoading(false);
    }
  }, [account, agency]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    submit,
    loading,
    form,
    corporateTax,
    years,
    yearWatch,
    quarterWatch,
  };
};
