import { 
    ODataGridGeneratorConfig,
    dataSourceCustomStoreGenerator,
 } from "components/data-grid";
import { OToastManager, OTypography, modalManager } from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { Apps } from "services/developers/models";
import { AppApprovalModal, buildModalId } from "./compose";
import { AppRejectModal, buildModalIdReject } from "./compose/rejeitar-app-modal/rejeitar-app-modal.component";

export const dataSource = dataSourceCustomStoreGenerator<Apps>(()=>
    service.developers.getPendingApps()
    .then(({ data }) => data.items ?? [])
    .catch(() => {
      const errorMessage = "Erro ao buscar os Aplicativos";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);
const reload = () => dataSource.reload();
export const appVerifierGrid: ODataGridGeneratorConfig<Apps> = {
    datagrid:{
        noDataText: "Nenhum aplicativo encontrado",
        filterRow: {
          visible: true,
        },
    },
    columns:[
        {
            caption:"Nome",
            dataField:"name",
            alignment: "center",
            dataType:"string",
        },
        {
            caption:"CNPJ",
            dataField: "tenantId",
            alignment: "center",
            dataType:"string",
        },
        {
            caption:"Client Id",
            dataField:"appId",
            alignment: "center",
            dataType:"string",
        },
        {
          caption:"Modelo de integração",
          dataField:"clientChannel",
          alignment:"center",
          dataType:"string",
        },
        {
          caption: "Versão",
          dataField:"version",
          alignment: "center",
          dataType: "number",
      },
        {
          caption: "Tipo de integração",
          dataField:"type",
          alignment: "center",
          dataType: "string",
        },
         {
          caption: "Escopos",
          dataField: "scopes",
          cellRender: ({ data }) =>
            data.scopes?.map((scope: string) => (
              <OTypography key={scope}>{scope}</OTypography>
            )),
          
        },
        {
          caption:"Url de redirect",
          dataField:"redirectUris",
          cellRender: ({ data }) =>
            data.redirectUris?.map((uris: string) => (
              <OTypography key={uris}>{uris}</OTypography>
            )),
        },

        {
            caption: "Criado em",
            dataField: "createdAt",
            alignment: "center",
            dataType: "string",
        },
        {
          caption: "Atualizado em",
          dataField: "updatedAt",
          alignment: "center",
          dataType: "string"
        },
        {
            caption: "Ações",
            alignment: "center",
            allowExporting: false,
            cellRender: ({ data }) => (
                <>
                  <DataGridAction
                    actions={[
                      {
                        icon: { category: "far", icon: "fa-link" },
                        label: "Aprovar", 
                        onClick(){
                          const modalId = buildModalId(data.appId)
                          modalManager.show(modalId)
                        }
                      },
                      {
                        icon: { category: "far", icon: "fa-unlink" },
                        label: "Rejeitar",
                        onClick(){
                          const modalIdReject = buildModalIdReject(data.appId)
                          modalManager.show(modalIdReject);
                        } 
                      },
                    ]}
                  />
                  <AppApprovalModal
                  name={data.name}
                  appId={data.appId} 
                  version={data.version}
                  tenantId={data.tenantId}
                  successCallback={reload}
                  />

                  <AppRejectModal
                  name={data.name}
                  appId={data.appId}
                  version={data.version}
                  tenantId={data.tenantId}
                  sucessCallback={reload}
                  />
                </>
            )
        },    
    ],
};