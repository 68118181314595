import { OToastManager } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { useServiceCall } from "hooks/service-call";
import { parseOnboardingError } from "utils/parse-error/parse-onbording-error";
import { getOnboardingErrors } from "services/onboarding/utils/onboarding-errors-messages";
import { PostApiCustomerInviteOpenAccountBody } from "services/onboarding/models";
import { getCustomer } from "contexts/customer";

export const useInviteRequests = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const { callService: openAccount } = useServiceCall(
    service.onboarding.openAccount,
  );

  const redirectToOptin = (taxId: string, optinTemplate: string) => {
    getCustomer(taxId);
    navigate(
      `${routes.cadastro.customer.btgAccounts.optin.createAccounts.path}?templateId=${optinTemplate}`,
    );
  };

  const sentInvite = async (
    payload: PostApiCustomerInviteOpenAccountBody | undefined,
  ) => {
    if (!payload) {
      OToastManager.danger({
        title: "Convite não enviado",
        message:
          "Tente novamente, caso não seja possível entre em contato com o time responsável",
      });
      return;
    }

    const result = await openAccount(payload);
    if (!result.success) {
      const errorMessage = getOnboardingErrors(
        parseOnboardingError(result.error),
      );
      if (errorMessage) {
        OToastManager.danger({
          title: "Convite não enviado",
          message: errorMessage,
        });
      } else {
        OToastManager.danger({
          title: "Convite não enviado",
          message:
            "Tente novamente, caso não seja possível entre em contato com o time responsável",
        });
      }
    } else {
      OToastManager.success("Convite enviado com sucesso");
      navigate(routes.empresas.product.currentAccountPj.path);
    }
  };

  return { sentInvite, redirectToOptin };
};
