import { OCol, ORFieldInput, ORFieldInputDate, ORow } from "@maestro/react";
import dayjs from "dayjs";

export const EquipamentFormComponent = () => {
  return (
    <>
      <ORow>
        <OCol sm={12} md={4}>
          <ORFieldInput
            id="manufacturer"
            name="manufacturer"
            label="Marca"
            tag="text"
          />
        </OCol>
        <OCol sm={12} md={4}>
          <ORFieldInput
            id="identification"
            name="identification"
            label="Chassi/Série"
            tag="text"
          />
        </OCol>
        <OCol sm={12} md={4}>
          <ORFieldInputDate
            id="manufacturingYear"
            name="manufacturingYear"
            label="Ano"
            picker="year"
            size="md"
            disabledDate={(currentDate) =>
              dayjs().isBefore(currentDate.toISOString(), "year")
            }
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={4}>
          <ORFieldInput
            id="description"
            name="description"
            label="Descrição"
            tag="text"
          />
        </OCol>
        <OCol sm={12} md={4}>
          <ORFieldInput id="finame" name="finame" label="FINAME" tag="text" />
        </OCol>
        <OCol sm={12} md={4}>
          <ORFieldInput
            id="unitPrice"
            name="unitPrice"
            label="Valor unitário"
            tag="currency"
            symbol="R$"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={12}>
          <ORFieldInput
            id="storageLocation"
            name="storageLocation"
            label="Local de armazenamento"
            tag="text"
          />
        </OCol>
      </ORow>
      <div />
    </>
  );
};
