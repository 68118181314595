import { Roles } from "roles/roles.types";

export const workflowRmAdminRoles = {
  customer: {
    tab: {
      role: "admin:WorkflowRMAdminPageCustomer.View",
      description:
        "Visualização da tab 'Workflow de Leads - RM' na visão cliente",
    },
    workflowsRmAdminPage: {
      role: "admin:WorkflowWorkflowsRMAdminPageCustomer.View",
      description:
        "Visualização da página 'Workflows - RMAdmin' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:WorkflowRMAdminPageProduct.View",
      description:
        "Visualização da tab 'Workflow de Leads - RM' na visão produto",
    },
    workflowsRmAdminPage: {
      role: "admin:WorkflowWorkflowsRMAdminPageProduct.View",
      description:
        "Visualização da página 'Workflows - RMAdmin' na visão produto",
    },
  },
} satisfies Roles;
