import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import {
  ChangeTradeStatusButton,
  CreateOperationButton,
  ReserveOperationButton,
} from "./_compose";
import { energyTradeProductDetailsGenerator } from "./energy-trade.details";
import { useEnergyTrade } from "./energy-trade.hook";

export const EnergyTradePage = () => {
  const { hasRole } = useRoles();

  const { error, getTrade, hasError, loading, value } = useEnergyTrade();

  const trade = value?.response;

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes" />}
      actions={
        trade && (
          <>
            {trade.status === "PENDENTE_KIT_CADASTRAL" && (
              <StandardLink
                href={corporateRouter.routes.energia.product.trades.byId.documents.path(
                  { tradeId: trade.id },
                )}
                role={roles.energia.product.tradesDetailsGenerateDocuments.role}
              >
                Gerar kit cadastral
              </StandardLink>
            )}
            {hasRole(roles.energia.product.tradesGridActions.role) && (
              <>
                <ReserveOperationButton tradeId={trade.id} />
                <CreateOperationButton tradeId={trade.id} />
                <ChangeTradeStatusButton trade={trade} reload={getTrade} />
              </>
            )}
          </>
        )
      }
    >
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ValidationErrorComponent
              title="Não foi possível buscar a proposta"
              error={error}
              callback={getTrade}
            />
          }
          value={trade}
          fields={energyTradeProductDetailsGenerator}
        />
      </div>
    </DetailsTemplate>
  );
};
