import { OCard, OCardBody } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { AccountMasterDetails } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";
import { cadastroRoles } from "../../../../../../roles/cadastro.roles";
import { NotificationCard } from "../notification-workflow/notification-workflow-card.component";
import { RelationshipsCard } from "../relationships-workflow/relationships-workflow.component";
import { RepresentativeCard } from "../representatives-workflow/representatives-workflow.component";
import { ValidationCard } from "../validation-workflow/validation-workflow-card.component";

export const grid = (refetch: () => Promise<unknown>) => {
  return {
    datagrid: {
      noDataText: "Nenhuma conta",
      pager: {
        infoText: "Página {0} de {1} ({2} conta)",
      },
      wordWrapEnabled: true,
    },
    columns: [
      {
        dataField: "number",
        caption: "Número",
      },
      {
        dataField: "agency",
        caption: "Agência",
      },
      {
        dataField: "officer",
        caption: "Officer",
      },
      {
        dataField: "segment",
        caption: "Segmento",
      },
      {
        dataField: "coSegment",
        caption: "CoSegmento",
      },
      {
        dataField: "status",
        caption: "Status",
      },
    ],
    masterDetail: {
      enabled: true,
      component: ({ data: { data } }) => (
        <div className="d-flex flex-column gap-4">
          <OCard>
            <OCardBody>
              <RelationshipsCard relationships={data!.relationships} />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardBody>
              <RepresentativeCard representatives={data!.representatives} />
            </OCardBody>
          </OCard>
          <OCard role={cadastroRoles.customer.checkLogsAccountOpening.role}>
            <OCardBody>
              <NotificationCard
                notifications={data!.notifications}
                refetch={refetch}
              />
            </OCardBody>
          </OCard>
          <OCard role={cadastroRoles.customer.checkLogsAccountOpening.role}>
            <OCardBody>
              <ValidationCard validations={data!.validations} />
            </OCardBody>
          </OCard>
        </div>
      ),
    },
  } as ODataGridGeneratorConfig<AccountMasterDetails>;
};
