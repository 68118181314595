import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";

export const useImportAgendas = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const form = useForm();

  const handleAddFile = useCallback(async (evt: OUploadCustomEvent<File>) => {
    const file = evt.detail;

    if (file) {
      const base64 = await helpers.convertFileToBase64(file);

      try {
        setLoading(true);

        await service.recebiveisCartao.importAgenda({
          file_name: file.name,
          base64,
        });

        setHasError(false);
        OToastManager.success("Arquivo enviado com sucesso");
      } catch (err) {
        setHasError(true);
        OToastManager.danger("Não foi possível fazer o upload do arquivo");
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return {
    form,
    handleAddFile,
    hasError,
    loading,
  };
};
