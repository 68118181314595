import { CompanySearchCanceling } from "./busca-empresa-cancelamento/company-search-canceling.component";

export const CancelingDrawnAndTransferor = () => {
  return (
    <>
      <CompanySearchCanceling
        mode="Sacado"
        label="Nome ou identificação dos sacados"
        name="sacadoIdList"
        placeholder="Digite ao menos 3 (três) caracteres"
      />
      <CompanySearchCanceling
        mode="Cedente"
        label="Nome ou identificação dos cedentes"
        name="cedenteIdList"
        placeholder="Digite ao menos 3 (três) caracteres"
      />
    </>
  );
};
