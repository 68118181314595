import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useSearchParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { overdraftGuarantorGrid } from "./overdraft-guarantors.grid";
import { analysisStatusSearchParam } from "./overdraft-guarantors.utils";

export const OverdraftGuarantors = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get(analysisStatusSearchParam) ?? undefined;

  const gridRef = useGridRef();

  const dataSource =
    dataSourceCustomStoreGenerator<BankingHub.AnalysisGuarantor>(() => {
      return service.bankinghub
        .getOverdraftAnalysis({ status })
        .then(({ data }) => data.analysis?.pop()?.guarantors ?? [])
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar os avalistas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    });

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Avalistas" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={overdraftGuarantorGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
