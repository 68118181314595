import { ORow } from "@maestro/react";
import styled from "styled-components";

export const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-quaternary);
  border-radius: 50%;
  color: var(--theme-light);
  height: 36px;
  width: 36px;
  font-size: 16px;
`;

export const StyledRow = styled(ORow)`
  display: flex;
  flex-direction: row;
  align-self: center;

  border: 1px solid var(--theme-dark-20);
  padding: 16px;
`;
