import * as yup from "yup";

export interface DeskDocumentConfigRelationshipFormValues {
  deskOnboardingConfigIdentifier: string;
  deskOnboardingDocumentConfigUuid: string;
  complementor?: string | null;
}

export const deskDocumentConfigRelationshipFormValidationSchema: yup.ObjectSchema<DeskDocumentConfigRelationshipFormValues> =
  yup.object({
    deskOnboardingConfigIdentifier: yup
      .string()
      .required("Este campo é obrigatório"),
    deskOnboardingDocumentConfigUuid: yup
      .string()
      .required("Este campo é obrigatório"),
    complementor: yup
      .string()
      .transform((value) => value || null)
      .nullable(),
  });
