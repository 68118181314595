import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";

export const BuildBalanceDetailsFields = (
  balance: BankingHub.BalanceResponse & { plan?: string },
): DetailsFields => ({
  balance: {
    items: [
      {
        label: "Saldo disponível",
        value: masks.currency(balance?.balance || 0, currencySymbol.BRL, "."),
        grid: { sm: 6, md: 4, lg: 2 },
      },
      {
        label: "Saldo bloqueado",
        value: masks.currency(
          balance?.blockedBalance || 0,
          currencySymbol.BRL,
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 2 },
      },
      {
        label: "Limite da conta",
        value: masks.currency(
          balance?.overdraftLimit || 0,
          currencySymbol.BRL,
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 2 },
      },
      {
        label: "Saldo e Limite da conta",
        value: masks.currency(
          (balance?.balance || 0) + (balance?.overdraftLimit || 0),
          currencySymbol.BRL,
          ".",
        ),
        grid: { sm: 6, md: 4, lg: 2 },
      },
      {
        label: "Plano",
        value: balance?.plan,
        grid: { sm: 6, md: 4, lg: 2 },
      },
    ],
  },
});
