import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const optionsDefault = [
  {
    value: "CEDENTE_COMPLETO",
    label: "Cedente Completo",
  },
  {
    value: "CEDENTE_SIMPLES",
    label: "Cedente Simples",
  },
  {
    value: "CONTA_ESCROW",
    label: "Conta Escrow",
  },
  {
    value: "CEDENTE_OFFSHORE",
    label: "Offshore",
  },
];

const optionsFIDC = [
  {
    value: "CEDENTE_COMPLETO",
    label: "Cedente Completo",
  },
  {
    value: "CEDENTE_SIMPLES",
    label: "Cedente Simples",
  },
];

const optionsCCC = [
  {
    value: "SACADO_SIMPLES",
    label: "Sacado Simples",
  },
  {
    value: "SEM_ONBOARDING",
    label: "Sem Onboarding",
  },
];

const optionsRiscoCedente = [
  {
    value: "SACADO_SIMPLES",
    label: "Sacado Simples",
  },
];

const options = {
  DEFAULT: optionsDefault,
  FIDC: optionsFIDC,
  RISCO_CEDENTE: optionsRiscoCedente,
  CCC: optionsCCC,
};

interface TipoOnboardingFieldProps {
  disabled?: boolean;
  required?: boolean;
  type: keyof typeof options;
}

export const TipoOnboardingField = ({
  disabled,
  required,
  type,
}: TipoOnboardingFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:tipo_onboarding"
        dataLabel="tipo_onboarding"
        id="tipoOnboarding"
        name="tipoOnboarding"
        label={EstrategiaFormLabels.tipoOnboarding}
        placeholder="Selecionar"
        disabled={disabled}
        required={required}
      >
        {options[type].map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
