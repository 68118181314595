import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { ValidationResponse } from "./desk-migration-details.types";

export const toastFailures = (err: unknown) => {
  if (
    isAxiosError<ValidationResponse>(err) &&
    err.response?.data?.failures?.length
  ) {
    err.response.data.failures.forEach((failure) =>
      OToastManager.danger(failure.errorMessage),
    );
  }
};
