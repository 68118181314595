import { OCol, ORFieldInput } from "@maestro/react";
import { useEffect, useMemo } from "react";
import { masks } from "@maestro/utils";
import { useFormContext } from "react-hook-form";
import { cardHiringFields } from "../../card-hiring-form.form";
import { useCardsCustomerContext } from "../../../../../../../../../contexts";

export const HiredAmountField = () => {
  const { offer } = useCardsCustomerContext();

  const { watch, clearErrors, setError } = useFormContext();

  const valueWatcher = watch("hiredAmount");

  const [hiredAmountFieldInputValidate, hiredAmountFieldInputDescription] =
    useMemo(() => {
      const maxLimitValue = offer?.grantedLimit;
      if (!maxLimitValue) {
        return [() => false, "Falha ao carregar o limite máximo pré-aprovado"];
      }
      const maxLimitValueFormatted = masks.currency(maxLimitValue, "R$");
      const description = `Limite máximo pré-aprovado de ${maxLimitValueFormatted}`;
      const validate = (value: number) => value > 0 && value <= maxLimitValue;
      return [validate, description];
    }, [offer]);

  useEffect(() => {
    if (!valueWatcher) return;

    const isValid = hiredAmountFieldInputValidate(valueWatcher);

    if (isValid) clearErrors("hiredAmount");
    else setError("hiredAmount", { message: hiredAmountFieldInputDescription });
  }, [
    valueWatcher,
    hiredAmountFieldInputValidate,
    clearErrors,
    setError,
    hiredAmountFieldInputDescription,
  ]);

  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldInput
        {...cardHiringFields.hiredAmount}
        description={hiredAmountFieldInputDescription}
      />
    </OCol>
  );
};
