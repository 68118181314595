import { CellPreparedEvent } from "devextreme/ui/data_grid";
import cloneDeep from "lodash/cloneDeep";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";
import { Cedente } from "../../upload-cedentes.types";

export const addErrorsToCedentes = (
  cedentes: Cedente[] = [],
  errorMessages: PostCompaniesByIdBorderosVerifyErrorResponse = [],
) => {
  const cedentesWithErrors = cloneDeep(cedentes).map((cedente) => ({
    ...cedente,
    errorMessages: [],
  })) as Cedente[];

  errorMessages.forEach((error) => {
    const indexStr = error.key.match(/\d+/)?.[0];
    if (!indexStr) return;
    const index = Number(indexStr);
    cedentesWithErrors[index].errorMessages?.push(error);
  });

  return cedentesWithErrors;
};

export const highlightErrors = ({
  data,
  column: { dataField },
  cellElement,
  rowType,
}: CellPreparedEvent<Cedente>) => {
  if (
    rowType === "data" &&
    data?.errorMessages?.some(
      (err) =>
        err.key.split(".").pop()?.toLowerCase() === dataField?.toLowerCase(),
    )
  ) {
    cellElement.style.setProperty(
      "background-color",
      "var(--theme-danger-light)",
    );
    cellElement.style.setProperty("color", "var(--theme-danger-light-inverse)");
  }
};
