export const pluralMap = {
  com_status: {
    1: "com status",
    2: "com status",
  },
  com_tipo: {
    1: "com tipo",
    2: "com tipos",
  },
  do_tipo: {
    1: "do tipo",
    2: "dos tipos",
  },
} as const satisfies Record<string, Record<1 | 2, string>>;

export const withPlural = (
  expression: keyof typeof pluralMap,
  amount: number,
) => {
  const amountIndex = amount === 1 ? 1 : 2;
  return pluralMap[expression][amountIndex];
};

export const enumerateWithOu = (words: string[]) => {
  let text = "";
  words.forEach((w, idx) => {
    text += w;
    if (idx < words.length - 2) {
      text += ", ";
    } else if (idx === words.length - 2) {
      text += " ou ";
    }
  });
  return text;
};

export const buildSegment = (
  expression: keyof typeof pluralMap,
  items: string[],
) =>
  items.length
    ? withPlural(expression, items.length) + " " + enumerateWithOu(items)
    : "";

export const uncapitalize = (str: string) =>
  str[0].toLowerCase() + str.slice(1);
