import { Roles } from "roles/roles.types";

export const antecipacaoAFornecedorRoles = {
  customer: {
    tab: {
      role: "admin:AntecipacaoAFornecedorTabCustomer.View",
      description:
        "Visualização da tab 'Antecipação a Fornecedor' na visão cliente",
    },
    operationsPage: {
      role: "admin:AntecipacaoAFornecedorOperationsPageCustomer.View",
      description: "Visualização da página 'Operações' na visão cliente",
    },
    operationsDetailsPage: {
      role: "admin:AntecipacaoAFornecedorOperationsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão cliente",
    },
    receivablesPage: {
      role: "admin:AntecipacaoAFornecedorReceivablesPageCustomer.View",
      description:
        "Visualização da página 'Recebíveis disponíveis' na visão cliente",
    },
    receivablesSacadoPage: {
      role: "admin:AntecipacaoAFornecedorReceivablesSacadoPageCustomer.View",
      description:
        "Visualização da página 'Recebíveis disponíveis - Sacado' na visão cliente",
    },
  },
} satisfies Roles;
