import { OButton } from "@maestro/react";
import { useUploadTiers } from "../../upload-tiers.context";

type ResetButtonProps = Omit<
  React.ComponentProps<typeof OButton>,
  "dataAction" | "dataLabel"
>;

export const ResetButton = (props: ResetButtonProps) => {
  const { onClick } = props;

  const { stepNumber, reset } = useUploadTiers();

  return (
    <OButton
      dataAction={`upload-tiers-step-${stepNumber}:botao:resetar`}
      dataLabel="resetar"
      type="dark"
      outline
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        reset();
      }}
    />
  );
};
