import { OIcon } from "@maestro/react";
import { useEffect, useState } from "react";
import { useFavorites } from "../../../../context/favorites.context";
import { CustomerSelectorItem } from "../../../../customer-selector.types";
import { FavoriteEmptyButton } from "./favorite-button.styles";

interface FavoriteButtonProps {
  item: CustomerSelectorItem;
}

export const FavoriteButton = ({ item }: FavoriteButtonProps) => {
  const [isFavorite, setIsFavorite] = useState(!!item?.isFavorite);
  const [category, setCategory] = useState<"fal" | "far" | "fas">("fal");

  const { addFavorite, removeFavorite } = useFavorites();

  const defaultState = isFavorite ? "fas" : "fal";

  useEffect(() => {
    setCategory(defaultState);
  }, [defaultState]);

  return (
    <FavoriteEmptyButton
      onClickCapture={(evt) => {
        if (isFavorite) {
          removeFavorite(item);
        } else {
          addFavorite(item);
        }
        setIsFavorite(!isFavorite);
        evt.preventDefault();
        evt.stopPropagation();
      }}
      className="ms-auto"
      style={{ height: "calc(1.2 * var(--font-md))" }} // align with text
    >
      <OIcon category={category} icon="fa-star" type="dark" size="sm" />
    </FavoriteEmptyButton>
  );
};
