import { ODataGridGeneratorConfig } from "components/data-grid";
import {
  AssociatedCondition,
  ConditionDestination,
} from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { getValueFromMap } from "utils/get-value-from-map";
import { conditionLabelsMap } from "./select-conditions.utils";

export const selectConditionsGrid = (
  conditionDestination: ConditionDestination,
  actions: (data: AssociatedCondition) => JSX.Element,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma condição adicionada",
    },
    columns: [
      {
        dataField: "conditionName",
        caption: "Nome",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        dataField: "conditionType",
        caption: "Tipo",
        format: (value) =>
          getValueFromMap(conditionLabelsMap, value)?.label(
            conditionDestination,
          ) ?? "",
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => actions(data),
      },
    ],
  } satisfies ODataGridGeneratorConfig<AssociatedCondition>);
