import { SelectSearchMultiple } from "components/form";
import { useWalletInvoicesFilter } from "../wallet-invoices-filter.hook";

export const PayerSearch = () => {
  const { selected } = useWalletInvoicesFilter();
  return (
    <SelectSearchMultiple
      label="Nome ou identificação dos sacados"
      id="payersUuid"
      name="payersUuid"
      placeholder="Selecione os sacados"
      options={selected}
      maxOptions={50}
    />
  );
};
