import { ODropdown, OInputSearch, OTypography } from "@maestro/react";
import {
  HighlightText,
  buildTextHighlight,
} from "components/customer-selector";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetApiStrategiesResponseItem } from "services/clerk";
import {
  ButtonItem,
  EntitySearchContainer,
  StyledDropdownItem,
  StyledDropdownList,
} from "./entity-search.styles";
import { EntitySearchProps } from "./entity-search.types";

export const EntitySearch = ({
  strategies,
  addStrategy,
}: EntitySearchProps) => {
  const inputSearchRef = useRef<HTMLOInputSearchElement>(null);
  const [query, setQuery] = useState("");
  const [searchValues, setSearchValues] = useState<
    GetApiStrategiesResponseItem[]
  >([]);

  const search = useCallback(
    (searchValue: string) => {
      const data = strategies?.filter((strategy) => {
        return strategy.estrategiaUniqueId.includes(searchValue);
      });

      setSearchValues(data || []);
    },
    [strategies],
  );

  const debouncedSearch = useMemo(() => {
    return debounce(search, 500);
  }, [search]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  useEffect(() => {
    if (query?.trim().length) {
      debouncedSearch(query.trim());
    }
  }, [debouncedSearch, query]);

  return (
    <EntitySearchContainer>
      <ODropdown className="w-100">
        <OInputSearch
          ref={inputSearchRef}
          dataAction="estrategias:search:buscar-estrategia"
          dataLabel="buscar-estrategia"
          placeholder="Buscar estratégias"
          id="entity-search"
          name="entity-search"
          autocomplete="off"
          value={query}
          onInput={(e) => {
            if (e.currentTarget.value) setQuery(e.currentTarget.value);
          }}
          handleClear={() => {
            setQuery("");
            setSearchValues([]);
            inputSearchRef.current?.querySelector("input")?.focus();
          }}
        />
        <StyledDropdownList className="w-100">
          {searchValues.map((searchValue) => {
            const name = `${searchValue?.estrategiaUniqueId} - ${searchValue.name}`;

            return (
              <StyledDropdownItem
                key={searchValue.estrategiaUniqueId}
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  addStrategy(searchValue.estrategiaUniqueId)
                }
              >
                <ButtonItem
                  className="d-flex align-items-center gap-2"
                  onClick={(e) => {
                    e.preventDefault();
                    addStrategy(searchValue.estrategiaUniqueId);
                  }}
                >
                  <div className="text-left w-100">
                    <OTypography type="default">
                      <HighlightText
                        text={name}
                        highlightExpression={buildTextHighlight(query)}
                      />
                    </OTypography>
                  </div>
                </ButtonItem>
              </StyledDropdownItem>
            );
          })}
        </StyledDropdownList>
      </ODropdown>
    </EntitySearchContainer>
  );
};
