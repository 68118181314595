import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { GetCheckCompanyExistsResponse, fopaService } from "services/fopa";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { buildDetails } from "./detalhes.details";

export const FopaDetails = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [companyData, setCompanyData] =
    useState<GetCheckCompanyExistsResponse>();

  const fetch = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await fopaService.getCheckCompanyExist();

      setCompanyData(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setHasError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Folha de pagamento" />}>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={companyData}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar as informações"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => fetch()} />
          </ErrorComponent>
        }
        render={(_accountOpeningData) => (
          <div className="d-flex flex-column gap-2">
            {companyData && <DetailsCard fields={buildDetails(companyData)} />}
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
