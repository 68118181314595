import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useCustomerSignatures = () => {
  const {
    callService,
    error,
    loading,
    value: signatureRules,
  } = useServiceCall(service.paperclipv2.getSignatureRules);

  const {
    customer: { identification },
  } = useCustomer();

  const getSignatureRules = useCallback(
    () => callService({ cnpj: identification }),
    [callService, identification],
  );

  useEffect(() => {
    getSignatureRules();
  }, [getSignatureRules]);

  return {
    getSignatureRules,
    hasError:
      isAxiosError(error) &&
      error.response?.data?.message !== "SignatureRuleNotFound",
    loading,
    signatureRules,
  };
};
