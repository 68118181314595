import { Routes } from "routes/routes.types";
import { AdvisoredCustomersPage } from "../advisored-customers.page";
import { advisoredCompaniesRoles } from "../roles/advisored-customers.roles";

export const advisoredCompaniesRoutes: Routes = {
    product: {
        name: "Clientes assessorados",
        path: "/clientes-assessorados",
        element: <AdvisoredCustomersPage />,
        role: advisoredCompaniesRoles.product.clientesAssessoradosPage.role
    }
}