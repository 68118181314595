import { useParams } from "react-router-dom";
import { UsuariosClienteByIdentificationRouteParams } from "../../../../routes/usuarios.route-params";
import { notificationsGrid } from "../_compose";
import { NotificationsComponent } from "../../../../components";
import { useFindUserFromCustomer } from "../../../../hooks";

export const NotificationsEmailPage = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const { email } = useFindUserFromCustomer(identification);

  return (
    <NotificationsComponent
      receiverEmail={email}
      grid={notificationsGrid("Email")}
      notificationType="Email"
      title="Notificações por Email"
    />
  );
};
