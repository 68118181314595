import { OField, OLoader } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { GetAdminSettlementStrategiesBySettlementIdResponse } from "services/quickfin/models";
import { remapHookFormRegister } from "../../../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useBankAccountCompanySearch } from "./bank-account-company-search.hook";

interface CompanySearchProps {
  disabled: boolean;
  initialValue: GetAdminSettlementStrategiesBySettlementIdResponse["bankAccount"]["company"];
}

export const BankAccountCompanySearch = ({
  disabled,
  initialValue,
}: CompanySearchProps) => {
  const { localForm, loading, options } =
    useBankAccountCompanySearch(initialValue);

  const {
    formState: {
      errors: { input: error },
    },
    watch,
  } = localForm;

  const inputWatch = watch("input");

  return (
    <div className="d-flex align-items-center gap-2">
      <OField
        label="Titular"
        htmlFor="input"
        error={!!error}
        message={error?.message as string}
      >
        <SelectSearch
          id="input"
          name="input"
          placeholder="Selecione a empresa"
          disabled={disabled}
          options={options}
          value={inputWatch}
          className="w-100"
          maxOptions={50}
          eager={false}
          innerProps={{
            input: {
              ...remapHookFormRegister(localForm.register("input")),
            },
          }}
        />
      </OField>
      {loading && <OLoader className="d-block" size="sm" />}
    </div>
  );
};
