import { yupResolver } from "@hookform/resolvers/yup";
import { ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  componentFormDefaultValues,
  componentFormSchema,
} from "../component-form.schemas";
import { ComponentInfo } from "../component-info.types";

export const AddComponentPage = () => {
  const navigate = useNavigate();

  const form = useForm<ComponentInfo>({
    resolver: yupResolver(componentFormSchema),
    defaultValues: componentFormDefaultValues,
  });

  const { reset, handleSubmit } = form;

  const { callService, loading } = useServiceCall(
    service.hubCreditManager.addComponents,
  );

  const handleSubmitAddComponent = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await callService({
          ...values,
          name: values.name,
          type: values.type,
          description: values.description,
        });

        if (success) {
          reset();
          OToastManager.success("Componente adicionado com sucesso.");
          navigate(
            corporateRouter.routes.workflow.product.formsManager.components
              .path,
          );
        } else OToastManager.danger("Não foi possível adicionar o componente.");
      }),
    [handleSubmit, reset, navigate, callService],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <FormTemplate
        pageTitle={<PageTitle title="Adicionar componente" />}
        actions={
          <LoadingButton
            loading={loading}
            onClick={() => handleSubmitAddComponent()}
          >
            Adicionar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex gap-4">
                <ORFieldInput
                  id="name"
                  name="name"
                  tag="text"
                  label="Nome"
                  required
                  labelSize="md"
                />
                <ORFieldInput
                  id="type"
                  name="type"
                  tag="text"
                  label="Tipo"
                  tooltip="Ex.=NOME_COMPONENTE"
                  tooltipPosition="top-right"
                  required
                  labelSize="md"
                />
                <ORFieldInput
                  id="description"
                  name="description"
                  tag="text"
                  label="Descrição"
                  required
                  labelSize="md"
                />
              </div>
            </div>
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
    </div>
  );
};
