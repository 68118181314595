import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { logger } from "utils/logger";
import { getValidationMessages } from "../../../../../../../../utils";
import {
  ActionEditorForm,
  actionEditorFormDefaultValues,
  actionEditorFormValidationSchema,
} from "./action-editor.form";
import { payloadTypeMap, pickPayload } from "./action-editor.utils";

export const useActionEditor = (
  ruleId: number,
  action: HubEnergy.DiligenceEventActionSimpleResponse,
  setActions: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventActionSimpleResponse[]>
  >,
  setMode: React.Dispatch<React.SetStateAction<"display" | "editing">>,
) => {
  const { callService: create, loading: createLoading } = useServiceCall(
    service.hubEnergy.createDiligenceEventAction,
  );
  const { callService: update, loading: updateLoading } = useServiceCall(
    service.hubEnergy.updateDiligenceEventAction,
  );

  const initializedRef = useRef(false);

  const form = useForm<ActionEditorForm>({
    defaultValues: actionEditorFormDefaultValues,
    resolver: yupResolver(actionEditorFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const actionTypeWatcher = watch(
    "actionType",
  ) as HubEnergy.EDiligenceEventActionType;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (action.ruleId !== 0) {
          const { success, response, error } = await update({
            ruleId,
            diligenceEventActionId: action.id,
            ...pickPayload(values),
          });

          if (success) {
            OToastManager.success("Ação atualizada com sucesso");
            const updatedAction = response.data.response;
            setActions((old) =>
              old.map((oldAction) =>
                oldAction.id === updatedAction.id ? updatedAction : oldAction,
              ),
            );
            setMode("display");
          } else {
            OToastManager.danger(
              getValidationMessages(error)?.[0]?.errorMessage ??
                "Erro ao atualizar a ação",
            );
          }
        } else {
          const { success, response, error } = await create({
            ruleId,
            ...pickPayload(values),
          });

          if (success) {
            OToastManager.success("Ação criada com sucesso");
            const newAction = response.data.response;
            setActions((old) => [
              ...old.filter(({ id }) => id !== action.id),
              newAction,
            ]);
            setMode("display");
          } else {
            OToastManager.danger(
              getValidationMessages(error)?.[0]?.errorMessage ??
                "Erro ao criar a ação",
            );
          }
        }
      }),
    [
      action.id,
      action.ruleId,
      create,
      handleSubmit,
      ruleId,
      setActions,
      setMode,
      update,
    ],
  );

  useEffect(() => {
    if (!initializedRef.current && actionTypeWatcher === action?.actionType) {
      initializedRef.current = true;
      return;
    }
    reset({ ...actionEditorFormDefaultValues, actionType: actionTypeWatcher });
  }, [action?.actionType, actionTypeWatcher, reset]);

  useEffect(() => {
    if (action) {
      let payload: any;
      try {
        payload = JSON.parse(action.payload);
      } catch {
        logger.debug("Unable to parse: ", action.payload);
        payload = undefined;
      }
      const payloadType = getValueFromMap(payloadTypeMap, action.actionType);
      if (!payloadType) throw new Error("Unexpected action type");
      reset({
        ...actionEditorFormDefaultValues,
        actionType: action.actionType,
        targetStepTypes: action.targetStepTypes,
        targetStepStatuses: action.targetStepStatuses,
        targetPendencyTypes: action.targetPendencyTypes,
        targetPendencyStatuses: action.targetPendencyStatuses,
        targetSamePendency: action.targetSamePendency,
        targetSameStep: action.targetSameStep,
        isEnabled: action.isEnabled,
        payload: {
          ...actionEditorFormDefaultValues.payload,
          [payloadType]: payload,
        },
      });
    }
  }, [reset, action]);

  return {
    actionTypeWatcher,
    form,
    submit,
    submitLoading: createLoading || updateLoading,
  };
};
