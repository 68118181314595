import { BeneficiaryStatus } from "./beneficiary-status-card.types";

export const MapperBeneficiaryStatusBadge: Record<
  BeneficiaryStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [BeneficiaryStatus.ABLE]: {
    type: "success",
    label: "Apto para emitir boleto",
  },
  [BeneficiaryStatus.PROCESSING]: {
    type: "info",
    label: "Registrando",
  },
  [BeneficiaryStatus.PROCESSING_TREATY]: {
    type: "info",
    label: "Registrando conta",
  },
  [BeneficiaryStatus.NOT_REGISTERED_CIP]: {
    type: "danger",
    label: "Não registrado na CIP",
  },
};
