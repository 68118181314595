import styled from "styled-components";

export const CardContainer = styled.div`
  width: auto;
  padding: 0.5rem;
`;

export const InfoCardContainer = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  gap: 1rem;

  & > * {
    flex: 0 0 auto;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
