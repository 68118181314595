import { OCol, ORow } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useFormContext } from "react-hook-form";
import { BaseCalendar, OffDay } from "services/legacys/models";
import { CalendarFormFields } from "../../calendars.types";
import { grid } from "./calendar-offdays.grid";

interface CalendarOffDaysProps {
  baseCalendarSelected?: BaseCalendar;
}

export const CalendarOffDays: React.FC<CalendarOffDaysProps> = ({
  baseCalendarSelected,
}) => {
  const { watch, setValue } = useFormContext<CalendarFormFields>();
  const offDaysWatch = watch("offDays");

  const onAdd = (offDay: OffDay) => {
    if (offDaysWatch.includes(offDay)) {
      return;
    }
    setValue("offDays", [...offDaysWatch, offDay]);
  };

  return (
    <ORow>
      <OCol sm={12}>
        <ODataGridGenerator
          grid={grid(offDaysWatch, onAdd, !baseCalendarSelected)}
          dataSource={offDaysWatch}
        />
      </OCol>
    </ORow>
  );
};
