import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { Base64FileType, RadioDataExportFormatType } from "./statement.types";

export const radioDataExportFormats: RadioDataExportFormatType[] = [
  {
    label: "CSV (Excel)",
    value: "csv",
  },
  {
    label: "CSV com Remunera+ (Excel)",
    value: "csvWithBalanceYield",
  },
  {
    label: "PDF",
    value: "pdf",
  },
  {
    label: "OFX",
    value: "ofx",
  },
];

export const MapperFormatFileToAssembleBase64Data: Record<
  string,
  (data: Record<string, string>) => Base64FileType
> = {
  csv: (data: Record<string, string>) => {
    const BOM = "\uFEFF";
    const Base64BOM = btoa(unescape(encodeURIComponent(BOM)));
    const base64 = data?.csv;

    if (!base64) throw new Error("Undefined CSV data from response");

    return {
      base64: Base64BOM + base64,
      contentType: "text/csv;charset=utf-8",
    };
  },
  pdf: (data: Record<string, string>) => {
    const base64 = data?.pdf;

    if (!base64) throw new Error("Undefined PDF data from response");

    return {
      base64,
      contentType: "application/pdf;base64",
    };
  },
  ofx: (data: Record<string, string>) => {
    const base64 = data?.ofx;

    if (!base64) throw new Error("Undefined OFX data from response");

    return {
      base64,
      contentType: "application/octet-stream;base64",
    };
  },
};

export const generateFileByFormat = (
  id: string,
  account: string,
  agency: string,
  format: string,
  data: Record<string, string>,
) => {
  try {
    const today = new Date(Date.now()).toLocaleString("pt-BR").split(",")[0];
    const filename = `${id}${agency}_${account}_${today}.${format}`;

    const assembleBase64Data = MapperFormatFileToAssembleBase64Data[format];
    const { base64 } = assembleBase64Data(data);

    helpers.downloadBase64(base64, filename);
  } catch (error) {
    OToastManager.danger("Erro ao gerar arquivo de download");
  }
};
