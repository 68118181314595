import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";

interface SyncDataUpdateWorkflowProps {
  uuid: string;
  modalId: string;
}

export const SyncDataUpdateWorkflow = ({
  uuid,
  modalId,
}: SyncDataUpdateWorkflowProps) => {
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      await service.onboarding.syncDataUpdate({
        workflowDataUpdateUuid: uuid,
      });

      OToastManager.info("Atualização sincronizada com sucesso.");

      modalManager.hide(modalId);
    } catch (error) {
      OToastManager.warning("Erro ao sincronizar atualização.");
    } finally {
      setLoading(false);
    }
  }, [modalId, uuid]);

  return (
    <OConfirmationModal
      modalId={modalId}
      size="sm"
      title="Sincronizar a atualização?"
      closeLabel="Cancelar"
      confirmLabel="Confirmar"
      onConfirm={submit}
      loading={loading}
    >
      <div> {`Id: ${uuid}`}</div>
    </OConfirmationModal>
  );
};
