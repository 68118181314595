import { modalManager, OCard, OCardBody } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { cancelingGrid } from "./canceling.grid";
import { useCanceling } from "./canceling.hook";
import {
  cancelingFilterModalId,
  receivablesCancelingModalId,
} from "./canceling.utils";
import { CancelingModal } from "./modal-cancelamento";
import { CancelingFilterModal } from "./modal-filtro";

export const Canceling = () => {
  const {
    form,
    gridRef,
    loading,
    onSubmit,
    receivables,
    resetForm,
    selectedReceivables,
    setSelectedReceivables,
    updateValue,
  } = useCanceling();

  const grid = useMemo(() => {
    return cancelingGrid(setSelectedReceivables);
  }, [setSelectedReceivables]);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Cancelamento"
          description="Visualize todos os títulos que podem ser cancelados"
        />
      }
    >
      <OCard>
        <OCardBody>
          <GridTemplate
            gridRef={gridRef}
            showExportButton
            actions={
              <>
                <IconButton
                  icon={{ category: "orq", icon: "orq-tools-filter" }}
                  dataAction="cancelamento_recebiveis:botao:mostrar_form"
                  dataLabel="mostrar_form"
                  onClick={() => modalManager.show(cancelingFilterModalId)}
                >
                  Mostrar filtro
                </IconButton>
                <IconButton
                  icon={{ category: "orq", icon: "orq-cancel" }}
                  dataAction="cancelamento_recebiveis:botao:cancelar_titulos"
                  dataLabel="cancelar_titulos"
                  disabled={
                    !selectedReceivables || selectedReceivables.length === 0
                  }
                  onClick={() => modalManager.show(receivablesCancelingModalId)}
                >
                  Cancelar títulos
                </IconButton>
                <RefreshGridButton onClick={() => updateValue()} />
              </>
            }
          >
            <ODataGridGenerator
              loading={loading}
              grid={grid}
              gridRef={gridRef}
              dataSource={receivables}
            />
          </GridTemplate>
        </OCardBody>
      </OCard>
      <CancelingModal
        gridRef={gridRef}
        receivables={selectedReceivables ?? []}
        setSelectedReceivables={setSelectedReceivables}
        refetchReceivables={updateValue}
      />
      <CancelingFilterModal
        form={form}
        loading={loading}
        onSubmit={onSubmit}
        resetForm={resetForm}
      />
    </DetailsTemplate>
  );
};
