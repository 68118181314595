import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";

interface AccountOpeningInfo {
  isAccountOpening?: boolean | null;
  officialName?: string | null;
  taxId?: string | null;
}

export const buildDetails = (info: AccountOpeningInfo): DetailsFields => ({
  info: {
    items: [
      {
        label: "Empresa",
        value: info.officialName,
        grid: { md: 4 },
      },
      {
        label: "CNPJ",
        value: masks.cnpj(info.taxId),
        grid: { md: 4 },
      },
      {
        label: "Status",
        value: info.isAccountOpening
          ? "Não Disponivel para Habilitação"
          : "Disponivel para habilitação",
        grid: { md: 4 },
      },
    ],
  },
});
