import * as yup from "yup";
import { CalendarFormFields } from "../../calendars.types";

export const calendarDefaultValues: CalendarFormFields = {
  name: "",
  baseCalendarId: undefined,
  offDays: [],
};

export const calendarSchema: yup.ObjectSchema<CalendarFormFields> = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  baseCalendarId: yup.number().required("Este campo é obrigatório"),
  offDays: yup.array().required("Este campo é obrigatório"),
});
