import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { BankingClienteContasByAccountByAgencyRouteParams } from "../../../../../routes/banking.route-params";
import { buildDataSource, dailyBalanceGrid } from "./daily-balance.grid";

export const DailyBalance = () => {
  const { account, agency } =
    useParams<BankingClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(account, agency),
    [account, agency],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Saldo final do dia" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dailyBalanceGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
