import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { MleByTradeIdRouteParams } from "../../../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../../../utils";

const modalId = `generate-contract-again-confirmation-modal`;

export const GenerateContractAgainButton = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.generateContract,
  );

  const { tradeId } = useParams<MleByTradeIdRouteParams>();

  const generate = useCallback(async () => {
    if (!tradeId) return OToastManager.danger("No tradeId");

    const { success, response, error } = await callService({
      tradeId: Number(tradeId),
    });

    if (success && response.data.success)
      OToastManager.success(
        "O contrato foi gerado novamente e enviado para a assinatura do cliente",
      );
    else
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao gerar o contrato",
      );
  }, [callService, tradeId]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-swap-circle" }}
        loading={loading}
        type="danger"
        onClick={() => modalManager.show(modalId)}
      >
        Gerar novamente
      </IconButton>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={modalId}
        title="Gerar novo contrato"
        onConfirm={() => {
          generate();
          modalManager.hide(modalId);
        }}
        divider={false}
        className="position-absolute"
        confirmLabel="Confirmar"
        closeLabel="Cancelar"
      >
        <OTypography style={{ whiteSpace: "normal" }}>
          Certeza que deseja gerar o contrato novamente? Após a confirmação, o
          documento será gerado e o cliente será notificado para realizar a
          assinatura.
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
