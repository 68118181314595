import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

type Offers = Awaited<
  ReturnType<typeof service.hubCreditManager.getWorkflowConfigOffers>
>["data"][number];

export const workflowConfigOffersDataSource = (
  workflowConfigId: number | string,
) =>
  dataSourceCustomStoreGenerator(() =>
    service.hubCreditManager
      .getWorkflowConfigOffers(workflowConfigId)
      .catch(() => {
        throw new Error(
          "Não foi possível carregar os workflows pré-configurados.",
        );
      }),
  );

export const workflowConfigOffersGrid = (workflowConfigId: number | string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma oferta encontrada",
      dataSource: workflowConfigOffersDataSource(workflowConfigId),
      paging: {
        pageSize: 10,
      },
    },
    columns: [
      {
        dataField: "title",
        caption: "Título",
      },
      {
        dataField: "subtitle",
        caption: "Subtítulo",
      },
      {
        dataField: "label",
        caption: "Label",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        dataField: "file",
        caption: "Imagem",
        cellRender: ({ data }) => data.file?.name,
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar",
                role: roles.workflow.product
                  .workflowsConfigDetailsOffersEditPage.role,
                route:
                  corporateRouter.routes.workflow.product.workflowsConfig.details.edit.offers.edit.path(
                    {
                      id: workflowConfigId,
                      offerId: data.id,
                    },
                  ),
              },
              {
                icon: { category: "orq", icon: "orq-cancel" },
                label: "Remover",
                role: roles.workflow.product
                  .workflowsConfigDetailsOffersEditPage.role,
                onClick: async () => {
                  try {
                    await service.hubCreditManager.deleteOffer({ id: data.id });

                    OToastManager.success("A oferta foi removida com sucesso.");

                    component.getDataSource().reload();
                  } catch (e) {
                    OToastManager.danger(
                      "Um erro ocorreu ao tentar remover a oferta.",
                    );
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Offers>);
