import { OButton, OIcon, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { buildCustomerLink } from "utils/build-customer-link";
import { LimitRequest } from "../detalhes/limit-details.types";

interface CreateAutoSimulationButtonProps {
  limitRequest: LimitRequest | undefined;
}

const parseReadinessPendencies = (
  readiness?: Awaited<
    ReturnType<typeof service.hubLoan.getAutoSimulationReadiness>
  >["data"],
) => {
  if (!readiness) return "Não foi possível buscar status do limite";

  const pendencies = [];

  if (!readiness.isLimitAvailable) pendencies.push("Limite indisponível");

  if (!readiness.isOnboardingFinished)
    pendencies.push("Onboarding não finalizado");

  return pendencies.join(", ");
};

export const CreateAutoSimulationButton = ({
  limitRequest,
}: CreateAutoSimulationButtonProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    callService,
    value: autoSimulationReadiness,
    hasError: hasErrorAutoSimulationReadiness,
    loading: loadingAutoSimulationReadiness,
  } = useServiceCall(service.hubLoan.getAutoSimulationReadiness);

  const createAutosimulation = useCallback(
    async (limit: typeof limitRequest) => {
      try {
        setLoading(true);

        const { data } = await service.hubLoan.createAutosimulation({
          taxId: limit?.currentLimit?.customer?.legalEntity?.taxId ?? "",
          product: limit?.currentLimit?.creditLine?.identification ?? "",
        });

        OToastManager.success("Operação gerada com sucesso!");

        navigate(
          buildCustomerLink(
            limit?.currentLimit?.customer?.legalEntity?.taxId ?? "",
            corporateRouter.routes.emprestimos.customer.operations.details.path(
              {
                id: data,
              },
            ),
          ),
        );
      } catch {
        OToastManager.danger(
          "Não foi possível gerar operação. Tente novamente.",
        );
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const getAutoSimulationReadiness = useCallback(
    (limit: typeof limitRequest) => {
      callService({
        taxId: limit?.currentLimit?.customer?.legalEntity?.taxId ?? "",
        product: limit?.currentLimit?.creditLine?.identification ?? "",
      });
    },
    [callService],
  );

  useEffect(() => {
    getAutoSimulationReadiness(limitRequest);
  }, [getAutoSimulationReadiness, limitRequest]);

  return (
    <div className="d-flex gap-1 align-items-center">
      <LoadingButton
        loading={loading || loadingAutoSimulationReadiness}
        disabled={
          !autoSimulationReadiness?.isAvailable ||
          !limitRequest?.currentLimit?.creditLine.identification.includes(
            "AGRO",
          )
        }
        onClick={() => {
          createAutosimulation(limitRequest);
        }}
        outline
        {...(!autoSimulationReadiness?.isAvailable
          ? { title: parseReadinessPendencies(autoSimulationReadiness) }
          : {})}
      >
        Gerar operação
      </LoadingButton>
      {hasErrorAutoSimulationReadiness && (
        <OButton
          type="default"
          outline
          onClick={() => getAutoSimulationReadiness(limitRequest)}
        >
          <OIcon category="fa" icon="fa-repeat" size="sm" type="default" />
        </OButton>
      )}
    </div>
  );
};
