import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { SignatureGroup } from "../../../_compose";
import { useSignatureGroupDetails } from "../detalhes/signature-group-details.hook";

export const EditSignatureGroup = () => {
  const { getSignatureRule, groupOfSignatures, hasError, loading, ruleId } =
    useSignatureGroupDetails();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={`Editar grupo de assinaturas${
            groupOfSignatures ? `: ${groupOfSignatures.name}` : ""
          }`}
          key={groupOfSignatures?.name}
        />
      }
    >
      <div className="position-relative">
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          value={groupOfSignatures}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível encontrar a regra de assinaturas"
              callback={getSignatureRule}
            />
          }
          emptyStateComponent={
            <EmptyState messageTitle="Esse grupo de assinaturas não existe" />
          }
          render={(_groupOfSignatures) => (
            <SignatureGroup
              disabled={false}
              ruleId={Number(ruleId)}
              groupOfSignatures={_groupOfSignatures}
            />
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
