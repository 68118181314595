import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { ProposalGroup } from "services/hubcreditmanager/models/responses";
import { WorkflowProposalMasterDetail } from "./workflow-proposal.master-detail";

export const buildDataSource = (id: string) =>
  dataSourceCustomStoreGenerator(() =>
    service.hubCreditManager.getWorkflowLeadProposalGroups(id),
  );

export const workflowProposalsGrid = {
  datagrid: {
    noDataText: "Nenhuma proposta encontrada",
  },
  columns: [
    {
      cellRender: (data) => `Proposta #${data.rowIndex + 1}`,
    },
  ],
  masterDetail: {
    component: WorkflowProposalMasterDetail,
    enabled: true,
  },
} satisfies ODataGridGeneratorConfig<ProposalGroup[][number]>;
