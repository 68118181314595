import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { service } from "services";

export const useContractTemplateSelector = (
  companyId: string | number | null,
  form: UseFormReturn,
  name: string,
) => {
  const {
    callService: getCompanyContractTemplates,
    loading: companyTemplatesLoading,
    value: companyTemplates,
    hasError: companyTemplatesHasError,
  } = useServiceCall(service.quickfin.getCompanyContractTemplates);

  const {
    callService: getPublicContractTemplates,
    loading: publicTemplatesLoading,
    value: publicTemplates,
    hasError: publicTemplatesHasError,
  } = useServiceCall(service.quickfin.getPublicContractTemplates);

  const { clearErrors, setError } = form;

  const getCompanyTemplates = useCallback(async () => {
    if (companyId === null) throw new Error("companyId is null");
    const { success } = await getCompanyContractTemplates(companyId);
    if (success) clearErrors(name);
    else
      setError(name, {
        message:
          "Erro ao buscar os templates. Clique no botão ao lado para tentar novamente.",
      });
  }, [companyId, name, setError, clearErrors, getCompanyContractTemplates]);

  const getPublicTemplates = useCallback(async () => {
    const { success } = await getPublicContractTemplates();
    if (success) clearErrors(name);
    else
      setError(name, {
        message:
          "Erro ao buscar os templates. Clique no botão ao lado para tentar novamente.",
      });
  }, [clearErrors, getPublicContractTemplates, name, setError]);

  useEffect(() => {
    if (companyId === null) getPublicTemplates();
    else getCompanyTemplates();
  }, [companyId, getCompanyTemplates, getPublicTemplates]);

  return {
    contractTemplates: companyTemplates || publicTemplates,
    refetch: companyId === null ? getPublicTemplates : getCompanyTemplates,
    loading: companyTemplatesLoading || publicTemplatesLoading,
    hasFetchError: companyTemplatesHasError || publicTemplatesHasError,
  };
};
