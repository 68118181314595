import {
  OCol,
  OLoader,
  ORFieldInputDate,
  ORFieldInput,
  OField,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useAllowListDetails } from "./details.hook";
import { AllowListTypesMap } from "../allow.types";

export const AllowListDetails = () => {
  const { state } = useLocation();
  const { submit, form, loading } = useAllowListDetails(state.allowList);
  const { watch } = form;
  const startDateWatch = dayjs(watch("from"));

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Editar Permissão"
          description="Visualize e edite os dados do identificador selecionado."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="detalhe_allow:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader size="sm" />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="identification"
                  name="identification"
                  dataAction="block:input:identification"
                  dataLabel="identification"
                  label="Identificação"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="De" htmlFor="from">
                  <ORFieldInputDate
                    id="from"
                    name="from"
                    className="mt-2"
                    disabledDate={(date) =>
                      dayjs(date.toDate()).isBefore(dayjs().startOf("day"))
                    }
                  />
                </OField>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <OField label="Até" htmlFor="until">
                  <ORFieldInputDate
                    id="until"
                    name="until"
                    className="mt-2"
                    disabledDate={(date) =>
                      startDateWatch
                        ? dayjs(date.toDate()).isBefore(
                            dayjs(startDateWatch.toDate()).startOf("day"),
                          )
                        : false
                    }
                  />
                </OField>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="type"
                  name="type"
                  dataAction="block:input:type"
                  dataLabel="type"
                  label="Tipos"
                  value={AllowListTypesMap.VIA_VAREJO_CORBAN}
                  key={AllowListTypesMap.VIA_VAREJO_CORBAN}
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
