import { OBadge, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsFields } from "components/details-card";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { documentsGrid, mapDocuments } from "./documents.grid";
import { invoiceGrid } from "./invoice.grid";

export const workflowConsumerUnitDetailsGenerator = (
  consumerUnit: Awaited<
    ReturnType<typeof service.hubCreditManager.getConsumerUnitDetails>
  >["data"],
) => {
  return {
    details: {
      title: (
        <div className="d-flex justify-content-between">
          <OTypography size="lg" type="default" className="mb-3">
            Dados gerais
          </OTypography>
          {!!consumerUnit?.identification && (
            <OBadge type="info" size="sm">
              {consumerUnit?.identification}
            </OBadge>
          )}
        </div>
      ),
      items: [
        {
          label: "Razão social",
          value: consumerUnit?.legalEntity.name,
          grid: { md: 4 },
        },
        {
          label: "CNPJ/CPF",
          value: masks.cpfCnpj(consumerUnit?.legalEntity.taxId),
          grid: { md: 4 },
        },
      ],
    },
    invoice: {
      title: "Fatura de energia",
      items: [
        {
          label: "Valor",
          value: masks.currency(consumerUnit?.amount, currencySymbol.BRL, "."),
          grid: { md: 4 },
        },
        {
          label: "Modalidade",
          value: consumerUnit?.modality,
          grid: { md: 4 },
        },
        {
          label: "Grupo tarifário",
          value: consumerUnit?.fareGroup || "-",
          grid: { md: 4 },
        },
        {
          value: (
            <ODataGridGenerator
              grid={invoiceGrid}
              dataSource={consumerUnit?.energyInvoice?.history ?? []}
            />
          ),
        },
      ],
    },
    documents: {
      title: "Documentos",
      items: [
        {
          value: (
            <ODataGridGenerator
              grid={documentsGrid}
              dataSource={mapDocuments(consumerUnit?.documents)}
            />
          ),
        },
      ],
    },
  } satisfies DetailsFields;
};
