import { OBadge, OField, OIcon, OTypography } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { remapHookFormRegister } from "../../../../../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useSelectSacados } from "./select-sacados.hook";

export const SelectSacados = () => {
  const { allCompanies, localForm, watchInput, setValue, watchSacados } =
    useSelectSacados();

  return (
    <div className="d-flex flex-column gap-2 w-100">
      <OField label="Nome ou Identificação dos Sacados" htmlFor="input">
        <SelectSearch
          id="input"
          name="input"
          placeholder="Selecionar"
          options={allCompanies}
          value={watchInput}
          className="w-100"
          maxOptions={50}
          innerProps={{
            input: {
              ...remapHookFormRegister(localForm.register("input")),
            },
          }}
        />
      </OField>

      {!!watchSacados?.length && (
        <div className="d-flex flex-column gap-4">
          <OTypography tag="h3">Selecionados:</OTypography>

          <div className="d-flex flex-wrap gap-2">
            {watchSacados.map((sacado) => {
              const title = allCompanies.find((comp) => comp.value === sacado);

              return (
                <OBadge key={sacado} type="dark" size="sm" rounded outline>
                  <div className="d-flex align-items-center gap-2">
                    {title?.label}
                    <UnstyledButton
                      onClick={() => {
                        const filteredSacados = watchSacados.filter(
                          (s) => s !== sacado,
                        );

                        setValue("cnpjSacados", filteredSacados);
                      }}
                    >
                      <OIcon category="fal" icon="fa-times" />
                    </UnstyledButton>
                  </div>
                </OBadge>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
