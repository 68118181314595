import {
  OBadge,
  OButton,
  ODropdown,
  ODropdownList,
  OLoader,
  OTypography,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { UserBadge, getFirstAndLastName } from "components/user-badge";
import { useImpersonate } from "contexts/impersonate";
import { useUnsafeUser } from "contexts/user";

export const UserDropdown = () => {
  const { user, state } = useUnsafeUser();
  const impersonateContext = useImpersonate();

  if (state === "LOADING") {
    return <OLoader size="sm" className="me-1" />;
  }

  if (state === "ERROR") {
    return <OTypography type="default">Erro ao carregar o usuário</OTypography>;
  }

  if (!user) {
    return <OTypography type="default">Usuário não carregado</OTypography>;
  }

  return (
    <ODropdown positionX="left" positionY="bottom">
      <OButton
        dataLabel="usuario"
        dataAction="header:botao:usuario"
        type="dark"
        outline
        bordered={false}
      >
        <div className="d-flex align-items-center gap-2">
          <UserBadge name={user.name ?? ""} size="sm" />
          <OTypography type="dark" tag="span">
            {cases.title(getFirstAndLastName(user.name ?? "").shortName)}
          </OTypography>
          {impersonateContext.isImpersonating && (
            <div className="d-flex justify-content-center">
              <OBadge size="sm" type="warning">
                Impersonando
              </OBadge>
            </div>
          )}
        </div>
      </OButton>
      <ODropdownList
        className="me-1 p-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="d-flex align-items-center gap-3">
          <UserBadge name={user.name ?? ""} size="xl" />
          <div className="d-flex flex-column gap-1">
            <OTypography type="dark" tag="span" weight="bold">
              {cases.title(user.name ?? "")}
            </OTypography>
            <OTypography type="dark" tag="span">
              {masks.cpf(user.cpf)}
            </OTypography>
            <OTypography type="dark" tag="span">
              {user.email}
            </OTypography>
            <OTypography type="dark" tag="span">
              {masks.phone(user.phone)}
            </OTypography>
          </div>
        </div>
      </ODropdownList>
    </ODropdown>
  );
};
