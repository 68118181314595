import { OLoader } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { buildDetails } from "./enable-current-account.details";
import { grid } from "./enable-current-account.grid";
import { useEnableCurrentAccount } from "./enable-current-account.hook";

export const EnableCurrentAccount = () => {
  const {
    loading,
    hasError,
    accountOpeningData,
    createLoading,
    fetch,
    createAccountOpening,
  } = useEnableCurrentAccount();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle title="Liberação de jornada de Abertura de Conta Corrente" />
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={accountOpeningData}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar as informações"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => fetch()} />
          </ErrorComponent>
        }
        render={(_accountOpeningData) => (
          <DetailsTemplate
            actions={
              <ButtonWithConfirmation
                confirmationModal={{
                  title:
                    "Confirma a liberação de jornada de Abertura de Conta Corrente?",
                  confirmLabel: "Sim",
                  closeLabel: "Não",
                  divider: false,
                  loading: createLoading,
                  size: "xs",
                }}
                dataAction="abertura_conta_corrente:botao:solicitar"
                dataLabel="solicitar"
                className="align-self-end"
                disabled={_accountOpeningData.isAccountOpening || createLoading}
                onConfirm={() => createAccountOpening()}
              >
                <div className="d-flex flex-row gap-2">
                  {createLoading && <OLoader size="sm" />}
                  Solicitar
                </div>
              </ButtonWithConfirmation>
            }
          >
            <div className="d-flex flex-column gap-2">
              <DetailsCard fields={buildDetails(_accountOpeningData)} />
              <ODataGridGenerator
                grid={grid}
                dataSource={_accountOpeningData.timeline}
              />
            </div>
          </DetailsTemplate>
        )}
      />
    </DetailsTemplate>
  );
};
