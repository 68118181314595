import {
  OCol,
  OModal,
  OModalBody,
  OModalHeader,
  ORow,
  OTypography,
  modalManager,
  ORFieldInput,
  OButton,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import { SearchCompanyProps } from "./search-company.types";
import { CompanyInteractionFormValues } from "../company.utils";

const desverbationSearchModalId = "desverbation-search-modal";

export const CompanySearch = ({ onSubmit }: SearchCompanyProps) => {
  const { reset } = useFormContext<CompanyInteractionFormValues>();
  return (
    <>
      <IconButton
        dataAction="desverbation_search:botao:pesquisar"
        dataLabel="pesquisar"
        icon={{ category: "far", icon: "fa-search" }}
        type="default"
        onClick={() => modalManager.show(desverbationSearchModalId)}
      >
        Pesquisar
      </IconButton>
      <OModal id={desverbationSearchModalId} size="xxs">
        <OModalHeader>
          <OTypography size="lg">Parâmetro de busca</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-4">
            <ORow>
              <OCol xs={12}>
                <ORFieldInput
                  dataAction="find_tax_id:input:findTaxId"
                  dataLabel="findTaxId"
                  id="findTaxId"
                  name="findTaxId"
                  label="Número do CPF/CNPJ:"
                  aspect="flushed"
                  tag="text"
                />
              </OCol>
            </ORow>

            <div className="d-flex justify-content-end gap-2">
              <OButton
                dataAction="dashboard_onboardings:botao:pesquisar"
                dataLabel="pesquisar"
                onClick={() => {
                  onSubmit();
                  modalManager.hide(desverbationSearchModalId);
                }}
              >
                Pesquisar
              </OButton>
              <OButton
                dataAction="dashboard_onboardings:botao:limpar"
                dataLabel="limpar"
                type="dark"
                outline
                onClick={() => reset()}
              >
                Limpar
              </OButton>
            </div>
          </div>
        </OModalBody>
      </OModal>
    </>
  );
};
