import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const AveragePhysicalCardsCard = () => {
  return (
    <InfoCard
      summaryType="cardsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Cartões físicos por conta"
          body={_value.averagePhysicalQuantityPerAccount.toLocaleString(
            "pt-br",
          )}
          footer="Média de cartões físicos em contas com cartão físico ativo"
        />
      )}
    />
  );
};
