import * as yup from "yup";

export const configurableColumnsFormValidationSchema = yup.object().shape({
  dataField: yup.string().required("Campo obrigatório"),
  dataType: yup.string().required("Campo obrigatório"),
  caption: yup.string().required("Campo obrigatório"),
  cellRenderType: yup.string().required("Campo obrigatório"),
  sortable: yup.array().default([]),
  filterable: yup.array().default([]),
  headerExportConfiguration: yup.string().optional(),
});

export const configurableColumnsFormDefaultValues = {
  dataField: "",
  dataType: "",
  caption: "",
  cellRenderType: "",
  sortable: [] as string[],
  filterable: [] as string[],
  headerExportConfiguration: "",
};
