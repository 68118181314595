import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { RuleAndActionsGridCell } from "./_compose";
import { actionTextBuilder, ruleTextBuilder } from "./_utils";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceEventRuleResponse>(() =>
    service.hubEnergy
      .getDiligenceEventRules()
      .then(({ data }) => data.response)
      .catch(() => {
        const errorMessage = "Não foi possível buscar as regras de eventos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const diligenceEventRulesGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceEventRuleResponse> =
  {
    datagrid: {
      noDataText: "Nenhuma regra de evento",
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("diligenceEventRules"),
      columnAutoWidth: false,
    },
    columns: [
      {
        role: roles.energia.product.diligenceConfigDiligenceEventsDetails.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.energia.product.diligenceConfig.diligenceEvents.details.path(
              {
                diligenceEventRuleId: data.id,
              },
            )}
          />
        ),
        calculateCellValue: ({ id }) => id,
        allowFiltering: true,
        allowSorting: true,
        width: "auto",
      },
      {
        // hidden column for default sorting
        visible: false,
        dataField: "id",
        sortIndex: 0,
        sortOrder: "asc",
      },
      {
        dataType: "string",
        caption: "Regra / Ações",
        cellRender: ({ data }) => <RuleAndActionsGridCell rule={data} />,
        calculateCellValue: (data) =>
          [
            ruleTextBuilder(data),
            ...(data.actions?.map((a) => actionTextBuilder(a)) ?? []),
          ].join(" "),
        allowFiltering: true,
        cssClass: "dx-word-wrap",
      },
      {
        dataType: "string",
        caption: "Distribuidoras",
        calculateCellValue: (data) =>
          data.allowedDistributors?.length
            ? data.allowedDistributors.map((ad) => ad.officialName).join(", ")
            : "Todas",
        allowFiltering: true,
        width: "200px",
      },
    ],
  };
