import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeDocumentGroupsForm } from "../../../../../../components/nature/document-groups/document-groups-form.component";
import {
  ExchangeDocumentGroupsFormValues,
  exchangeDocumentGroupsFormValidationFormSchema,
} from "../../../../../../components/nature/document-groups/document-groups-form.schemas";
import { CambioNaturezasByIdRouteParams } from "../../../../../../routes/cambio.route-params";

export const ExchangeProductUpdateDocumentGroup = () => {
  const { id } = useParams<CambioNaturezasByIdRouteParams>();

  if (!id) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<ExchangeDocumentGroupsFormValues>({
    resolver: yupResolver(exchangeDocumentGroupsFormValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const {
    callService: getNatureDocumentGroupDetails,
    loading: loadingDetails,
    hasError,
    value: documentGroupDetails,
  } = useServiceCall(service.hubFx.nature.getNatureDocumentGroupDetails);
  const { callService: updateNatureDocumentGroups, loading } = useServiceCall(
    service.hubFx.nature.updateNatureDocumentGroups,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await updateNatureDocumentGroups({
          id: Number(id),
          ...values,
          isRequired: !!values.isRequired.length,
          mustShowForPf: !!values.mustShowForPf.length,
          mustShowForPj: !!values.mustShowForPj.length,
        });
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success("Grupo de documento atualizada com sucesso");

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .documentGroups.path,
        );
      }),
    [handleSubmit, updateNatureDocumentGroups, id, navigate],
  );

  useEffect(() => {
    getNatureDocumentGroupDetails(id);
  }, [getNatureDocumentGroupDetails, id]);

  useEffect(() => {
    documentGroupDetails &&
      reset({
        documentConfigIds: documentGroupDetails.documentConfigs?.map(
          (doc) => doc.typeId,
        ),
        isRequired: documentGroupDetails.isRequired ? ["true"] : [],
        mustShowForPf: documentGroupDetails.mustShowForPf ? ["true"] : [],
        mustShowForPj: documentGroupDetails.mustShowForPj ? ["true"] : [],
      });
  }, [documentGroupDetails, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar Grupo de Documentos" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          dataAction="adicionar"
          dataLabel="adicionar_grupo_documento:botao:adicionar"
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={() => getNatureDocumentGroupDetails(id)}
          />
        }
        value={documentGroupDetails}
        render={() => (
          <FormProvider {...form}>
            <ExchangeDocumentGroupsForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
