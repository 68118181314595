import { OConfirmationModal, modalManager } from "@maestro/react";
import { IconButton } from "components/icon-button";
import styled from "styled-components";
import { DetailsTemplate } from "templates/details-template";

interface JsonResponseModalProps {
  jsonResponseModalId: string;
  content: string;
}

const StyledPre = styled.pre`
  background: #2c3e50;
  color: #ecf0f1;
  height: 400px;
  line-height: 150%;
  overflow-y: auto;
  padding: 20px;
  text-align: left;
`;

export const JsonResponseModal = ({
  jsonResponseModalId,
  content,
}: JsonResponseModalProps) => {
  function Parser(cont: string) {
    try {
      return JSON.stringify(JSON.parse(content), null, 2);
    } catch {
      return cont;
    }
  }

  return (
    <OConfirmationModal
      modalId={jsonResponseModalId}
      title="Json Message"
      confirmLabel="Ok"
      divider={false}
      size="md"
      onConfirm={() => modalManager.hide(jsonResponseModalId)}
    >
      <DetailsTemplate
        actions={
          <IconButton
            onClick={() => navigator.clipboard.writeText(Parser(content))}
            icon={{ category: "orq", icon: "orq-edit-copy" }}
          >
            Copiar
          </IconButton>
        }
      >
        <StyledPre>{Parser(content)}</StyledPre>
      </DetailsTemplate>
    </OConfirmationModal>
  );
};
