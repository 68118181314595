import { OButton, OIcon, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, contactsGrid } from "./contacts-grid.grid";

interface ContactsGridProps {
  customerId: number;
}

export const ContactsGrid = ({ customerId }: ContactsGridProps) => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const allowUpdating = useMemo(
    () => hasRole(roles.energia.customer.contactsEdit.role),
    [hasRole],
  );

  const dataSource = useMemo(() => buildDataSource(customerId), [customerId]);
  const grid = useMemo(() => contactsGrid(allowUpdating), [allowUpdating]);

  return (
    <GridTemplate
      pageTitle={
        <OTypography type="dark" size="lg">
          Contatos
        </OTypography>
      }
      actions={
        allowUpdating && (
          <OButton onClick={() => gridRef.current?.instance.addRow()}>
            <OIcon category="orq" icon="orq-add-plus" />
          </OButton>
        )
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
