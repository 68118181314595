import React, { useCallback, useMemo } from "react";
import { OCheckbox } from "@maestro/react";
import { SharkAmortizationType } from "services/shark/models/responses";
import { useOperationsInstallments } from "../operations-installments.context";

export const AnticipationHeaderCheckbox = () => {
  const { checkedInstallmentsMap, installmentsMap, setCheckedInstallmentsMap } =
    useOperationsInstallments();

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLOCheckboxElement, MouseEvent>) => {
      const target = e.target as HTMLOCheckboxElement;

      const checkedInstallmentsMapAux = {} as Record<
        SharkAmortizationType,
        Set<number>
      >;

      Object.keys(installmentsMap).forEach((amortizationFrequency) => {
        const amortizationType = amortizationFrequency as SharkAmortizationType;

        checkedInstallmentsMapAux[amortizationType] = target.checked
          ? new Set(installmentsMap[amortizationType])
          : new Set<number>();
      });

      setCheckedInstallmentsMap(checkedInstallmentsMapAux);
    },
    [installmentsMap, setCheckedInstallmentsMap],
  );

  const shouldBeChecked = useMemo(() => {
    let allInstallmentsSum = 0;
    let currentCheckedInstallmentsSum = 0;

    Object.values(installmentsMap).forEach((installments) => {
      allInstallmentsSum += installments.length;
    });

    Object.values(checkedInstallmentsMap).forEach((set) => {
      currentCheckedInstallmentsSum += set.size;
    });

    return allInstallmentsSum === currentCheckedInstallmentsSum;
  }, [checkedInstallmentsMap, installmentsMap]);

  return (
    <OCheckbox
      id="checkAll"
      value="checkAll"
      checked={shouldBeChecked}
      size="xs"
      onClick={onClick}
    />
  );
};
