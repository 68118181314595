import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useInputMultiple = (name: string) => {
  const [inputValue, setInputValue] = useState<string>("");

  const form = useFormContext();

  const { clearErrors, getValues, setError, setValue } = form;

  const keyDownListener = useCallback(
    (evt) => {
      if (evt.key === "Enter") {
        const values = getValues(name) ?? [];
        const { value } = evt.target as HTMLOInputTextElement;

        if (!value) {
          setError(name, { message: "Digite um valor" });
        } else if (values.includes(value)) {
          setError(name, { message: `O valor "${value}" já foi adicionado` });
        } else {
          clearErrors(name);
          setValue(name, [...values, value]);
          setInputValue("");
        }
      }
    },
    [clearErrors, getValues, name, setError, setValue],
  ) as React.KeyboardEventHandler<HTMLOInputTextElement>;

  const removeValue = useCallback(
    (valueToRemove: string) => {
      setValue(
        name,
        (getValues(name) ?? []).filter(
          (value: string) => value !== valueToRemove,
        ),
      );
    },
    [getValues, name, setValue],
  );

  return {
    form,
    inputValue,
    keyDownListener,
    removeValue,
    setInputValue,
  };
};
