import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { SelectStrategies } from "../select-estrategias";
import { SelectSacados } from "../select-sacados";
import {
  onboardingTypeMap,
  priorityMap,
  productMap,
} from "./onboarding-strategies-form.utils";

export const OnboardingStrategiesForm = () => {
  const form = useFormContext();
  const { watch } = form;

  const watchPriority = watch("onboardingPriority");
  const watchType = watch("onboardingType");
  const watchProductType = watch("productType");

  return (
    <ORow>
      <OCol xs={12}>
        <ORFieldInput
          tag="text"
          id="description"
          name="description"
          dataAction="estrategias_onboarding:input:descricao"
          dataLabel="descricao"
          label="Descrição"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          id="onboardingPriority"
          name="onboardingPriority"
          dataAction="estrategias_onboarding:input:prioridade"
          dataLabel="prioridade"
          label="Prioridade"
          placeholder="Selecione"
          value={watchPriority}
        >
          {priorityMap.map((priority) => (
            <OOption
              key={priority.value}
              id={priority.value}
              value={priority.value}
            >
              {priority.title}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          id="productType"
          name="productType"
          dataAction="estrategias_onboarding:input:tipo_produto"
          dataLabel="tipo_produto"
          label="Tipo de Produto"
          placeholder="Selecione"
          value={watchProductType}
        >
          {productMap.map((product) => (
            <OOption
              key={product.value}
              id={product.value}
              value={product.value}
            >
              {product.title}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          id="onboardingType"
          name="onboardingType"
          dataAction="estrategias_onboarding:input:tipo_onboarding"
          dataLabel="tipo_onboarding"
          label="Tipo de Onboarding"
          placeholder="Selecione"
          value={watchType}
          key={watchProductType}
        >
          {onboardingTypeMap
            .filter((item) => {
              if (watchProductType === "RiscoCedente")
                return item.value === "SACADO_SIMPLES";

              return true;
            })
            .map((type) => (
              <OOption key={type.value} id={type.value} value={type.value}>
                {type.title}
              </OOption>
            ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <SelectSacados />
      </OCol>
      <OCol xs={12} md={6}>
        <SelectStrategies />
      </OCol>
    </ORow>
  );
};
