import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "pages/workflow/routes/workflow.route-params";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import {
  basicDataFormDefaultValues,
  basicDataFormValidationSchema,
} from "./processor-config-basic-data.schema";

export const useProcessorConfigBasicDataPageContent = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const [loading, setLoading] = useState(false);
  const [processorType, setProcessorType] = useState<string>('');

  const form = useForm({
    resolver: yupResolver(basicDataFormValidationSchema),
    defaultValues: basicDataFormDefaultValues,
  });
  const { handleSubmit, setValue, reset, watch } = form;

  const getBasicData = useCallback(async () => {
    try {
      if (!processorId) return;
      setLoading(true);
      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsBasicData(
          processorId,
        );
      reset(data);
      setProcessorType(data.processorType);
    } catch (err) {
      OToastManager.danger("Erro para retornar os dados básicos.");
    } finally {
      setLoading(false);
    }
  }, [setValue, setLoading]);

  const updateBasicData = handleSubmit(async (values) => {
    try {
      if (!processorId) return;

      setLoading(true);

      await service.hubCreditManager.updateProcessorConfigDetailsBasicData({
        ...values,
        processorConfigId: Number(processorId),
      });

      OToastManager.success("Dados básicos alterados com sucesso.");
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados básicos.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getBasicData();
  }, []);

  return {
    loading,
    updateBasicData,
    getBasicData,
    watch,
    form,
    processorType
  };
};
