import { TooltipIcon } from "components/tooltip-icon";
import { SelectSearchFieldV2Option } from "../../select-search-field.types";
import { getOptionsFromValues } from "../../select-search-field.utils";

interface SelectedValuesTooltipProps<T> {
  renderValuesTooltip: boolean;
  originalOptions: SelectSearchFieldV2Option<T>[];
  internalValue: T[];
}

export const SelectedValuesTooltip = <T,>({
  internalValue,
  originalOptions,
  renderValuesTooltip,
}: SelectedValuesTooltipProps<T>) => {
  return (
    <div
      style={{ zIndex: 1 }} // allow hovering when disabled
      className={renderValuesTooltip ? undefined : "d-none"}
    >
      <TooltipIcon
        maxWidth="unset"
        content={
          <div className="d-flex flex-column align-items-start gap-2">
            {getOptionsFromValues(originalOptions, internalValue).map(
              ({ value, content }) => (
                <span
                  key={String(value)}
                  style={{
                    maxWidth: "500px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={content}
                >
                  {content}
                </span>
              ),
            )}
          </div>
        }
      />
    </div>
  );
};
