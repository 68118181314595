export type ActivityLogin = ActiveDeviceResponse["data"][number];

export interface ActiveDevice {
  active: boolean;
  browser: string;
  city: string;
  clientIp: string;
  country: string;
  device: string;
  fingerprint: string;
  insertedAt: string;
  latitude: number;
  longitude: number;
  operationalSystem: string;
  sessionTokenId?: string;
  userAgent: string;
  userId: number;
  status: StatusType;
}

export interface ActiveDeviceResponse {
  data: ActiveDevice[];
}

export enum StatusType {
  ActiveToken = "active_token",
  ActiveSession = "active_session",
  ExpiredSession = "expired_session",
}

export const StatusTypeLabel: {
  [key in StatusType]: string;
} = {
  active_token: "Token ativo",
  active_session: "Sessão atual",
  expired_session: "Sessão expirada",
};
