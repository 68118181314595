import { OToastManager } from "@maestro/core";
import { ORFieldInput, OTypography } from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CodingTextArea } from "../coding-text-area/coding-text-area.component";
import { conditionFormDefaultValues } from "./condition-form.schemas";

interface CompilationError {
  compilationMessages: string[];
  success: boolean;
  conditionName: string;
}

type ConditionFormProps =
  | {
      mode: "add";
    }
  | {
      mode: "edit";
      conditionId: number;
    };

export const ConditionForm = (props: ConditionFormProps) => {
  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const navigate = useNavigate();

  const { handleSubmit } = useFormContext<typeof conditionFormDefaultValues>();

  const submitCondition = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setErrorList([]);

          setLoading(true);

          const { mode } = props;

          if (mode === "add") {
            await service.hubCreditManager.createCondition(values);
          } else {
            const { conditionId } = props;
            await service.hubCreditManager.updateCondition({
              ...values,
              id: conditionId,
            });
          }

          OToastManager.success("A condição foi salva com sucesso.");

          navigate(corporateRouter.routes.workflow.product.conditions.path);
        } catch (err) {
          if (isAxiosError<CompilationError>(err)) {
            setErrorList(err?.response?.data?.compilationMessages ?? []);
          }

          OToastManager.danger("Não foi possível salvar a condição.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, props],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          dataAction="enviar_condicao:botao:enviar"
          dataLabel="enviar"
          type="dark"
          onClick={() => submitCondition()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <form spellCheck="false">
          <div className="d-flex flex-column gap-2">
            <ORFieldInput
              id="conditionName"
              name="conditionName"
              tag="text"
              label="Nome único"
              dataAction="enviar_condicao:texto:nome_unico"
              dataLabel="nome_unico"
            />
            <ORFieldInput
              id="description"
              name="description"
              tag="text"
              label="Descrição"
              dataAction="enviar_condicao:texto:descricao"
              dataLabel="descricao"
            />
            <CodingTextArea
              id="expression"
              name="expression"
              tag="textarea"
              label="Expressão"
              dataAction="enviar_condicao:texto:expressao"
              dataLabel="expressao"
              adaptHeight
            />
          </div>
        </form>
        {errorList.map((error) => (
          <OTypography key={error} size="sm" type="danger">
            {error}
          </OTypography>
        ))}
      </CardTemplate>
    </FormTemplate>
  );
};
