import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  addBookStrategyFormDefaultValues,
  AddBookStrategyFormFields,
  addBookStrategyFormValidationSchema,
} from "./add-book-strategy.schema";

export const useAddBookStrategy = () => {
  const form = useForm<AddBookStrategyFormFields>({
    resolver: yupResolver(addBookStrategyFormValidationSchema),
    defaultValues: addBookStrategyFormDefaultValues,
  });

  const navigate = useNavigate();

  const {
    value: books,
    callService: getBooks,
    loading: loadingBooks,
    hasError: hasErrorBooks,
  } = useServiceCall(service.hubCreditManager.getBooks);

  const {
    value: strategies,
    callService: getStrategies,
    loading: loadingStrategies,
    hasError: hasErrorStrategies,
  } = useServiceCall(service.hubCreditManager.getStrategies);

  const {
    callService: callAddStrategyToBook,
    loading: loadingAddStrategyToBook,
  } = useServiceCall(service.hubCreditManager.addStrategiesToBook);

  const addStrategyToBook = useCallback(
    async ({ bookId, strategyId }: AddBookStrategyFormFields) => {
      const { success, error } = await callAddStrategyToBook({
        bookId,
        strategyIds: [strategyId],
      });
      if (success) {
        OToastManager.success(
          "A configuração de book / estratégia foi adicionada com sucesso.",
        );

        navigate(
          corporateRouter.routes.emprestimos.product.contingency
            .booksAndStrategies.path,
        );
      } else if (
        isAxiosError(error) &&
        error.response?.data.errorType === "DbUpdateException"
      ) {
        OToastManager.danger(
          "A configuração de book / estratégia a ser adicionada já existe.",
        );
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar salvar a configuração de book / estratégia. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callAddStrategyToBook, navigate],
  );

  const getAllData = useCallback(() => {
    getBooks();
    getStrategies();
  }, [getBooks, getStrategies]);

  useEffect(() => {
    hasErrorBooks &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber os books. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorBooks]);

  useEffect(() => {
    hasErrorStrategies &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber as estratégias. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorStrategies]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    form,
    books,
    loadingBooks,
    strategies,
    loadingStrategies,
    getAllData,
    addStrategyToBook,
    loadingAddStrategyToBook,
    hasErrorBooks,
    hasErrorStrategies,
  };
};
