import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";

interface UpdateReceivablesConfirmationModalProps {
  submit: (updateReceivables: boolean) => () => void;
}

export const updateReceivablesConfirmationModalId =
  "update-receivables-confirmation-modal";

export const UpdateReceivablesConfirmationModal = ({
  submit,
}: UpdateReceivablesConfirmationModalProps) => {
  return (
    <OModal
      id={updateReceivablesConfirmationModalId}
      position="center"
      backdrop
    >
      <OModalHeader>
        <OTypography size="lg">
          Atualizar recebíveis não antecipados?
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ textAlign: "start" }}>
          Atualiza a estratégia dos recebíveis que ainda não foram antecipados?
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_atualizar_recebiveis:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(updateReceivablesConfirmationModalId)
            }
            className="me-auto"
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_recebiveis:botao:nao"
            dataLabel="nao"
            type="dark"
            outline
            onClick={() => {
              submit(false)();
              modalManager.hide(updateReceivablesConfirmationModalId);
            }}
          >
            Não
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_recebiveis:botao:sim"
            dataLabel="sim"
            onClick={() => {
              submit(true)();
              modalManager.hide(updateReceivablesConfirmationModalId);
            }}
          >
            Sim
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
