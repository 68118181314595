import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { service } from "services";
import { FileResponseWithUuid } from "./files/account-close-details-files.grid";

export const useGetWorkflowDetails = (workflowUuid: string) => {
  const {
    callService: getWorkflowDetails,
    value: workflow,
    hasError,
  } = useServiceCall(service.onboardingCoreAccount.getWorkflowDetails);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<FileResponseWithUuid>(() =>
        getWorkflowDetails({ workflowUuid }).then(({ response, success }) => {
          if (!success) throw new Error("Erro ao buscar contas");

          const fileKeys = new Set<string>();
          return (
            response.data?.flatMap((x) =>
              x.files.flatMap((file) => {
                if (fileKeys.has(file.key)) {
                  return [];
                }
                fileKeys.add(file.key);
                return [{ ...file, uuid: workflowUuid }];
              }),
            ) ?? []
          );
        }),
      ),
    [getWorkflowDetails, workflowUuid],
  );

  return {
    dataSource,
    workflow,
    hasError,
  };
};
