import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { getValueFromMap } from "utils/get-value-from-map";
import { SnapshotCompany } from "./eligibility-offer-details.types";
import { criteriaMap } from "./eligibility-offer-details.utils";

export const buildEligibilityDetailsFields = (
  company: SnapshotCompany,
): DetailsFields => ({
  company: {
    items: [
      {
        label: "Documento",
        value: masks.cpfCnpj(company.governmentId),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      ...company.companyEligibilityData.map((eligibilityData) => ({
        label:
          getValueFromMap(criteriaMap, eligibilityData.type) ||
          eligibilityData.type,
        value: eligibilityData.externalValue,
        grid: { sm: 6, md: 4, lg: 3 } as const,
      })),
    ],
  },
});
