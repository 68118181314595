import { RouterLink } from "components/router-link";
import { HeaderNavigation } from "./tools-menu.styles";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const ToolsMenu = () => {

  return (
    <HeaderNavigation className="d-flex align-items-center gap-4">
      {(
        <RouterLink href={corporateRouter.routes.ferramentas.product.eligibility.path}
          role={roles.ferramentas.product.tab.role}>Ferramentas</RouterLink>
      )}
    </HeaderNavigation>
  );
};
