import {
  OButton,
  ODropdown,
  ODropdownList,
  OIcon,
  OTypography,
} from "@maestro/react";
import { useRoles } from "hooks/roles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DatagridActions } from "../datagrid-action.types";
import { DropdownItemWithDisabled } from "./datagrid-action-v2.styles";

interface DataGridActionProps {
  actions: DatagridActions[];
}

export const DataGridActionV2: React.FC<DataGridActionProps> = ({
  actions,
}) => {
  const navigate = useNavigate();
  const { hasRole } = useRoles();

  const filteredActions = actions.filter(
    (action) => action.visible !== false && hasRole(action.role),
  );

  return (
    <ODropdown
      key={filteredActions?.length}
      floating
      positionX="left"
      positionY="top"
    >
      <OButton dataAction="" dataLabel="" outline bordered={false}>
        <OIcon category="fal" icon="fa-ellipsis-h" size="xxl" type="dark" />
      </OButton>
      <ODropdownList>
        {filteredActions.map((action) => (
          <DropdownItemWithDisabled
            key={action.label}
            // size="xxl"
            handleInteraction={() => {
              if (action.disabled) return;
              if (action.onClick) {
                action.onClick();
              } else {
                navigate(action.route);
              }
            }}
            className={action.disabled ? "dropdown-item-disabled" : ""}
            // disabled={action.disabled}
          >
            <div className="d-flex align-items-center gap-2">
              <OIcon
                type="dark"
                category={action.icon.category}
                icon={action.icon.icon}
                size="lg"
              />
              <OTypography type="dark" size="md">
                {action.label}
              </OTypography>
            </div>
          </DropdownItemWithDisabled>
        ))}
      </ODropdownList>
    </ODropdown>
  );
};
