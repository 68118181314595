import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { EmpresasClienteByIdentificationRouteParams } from "../../../../routes/empresas.route-params";
import { validationSchema } from "../_compose/group-form/group-form.form";
import { FormValuesType } from "../_compose/group-form/group-form.types";

export const useGroupsDetails = () => {
  const { identification } =
    useParams<EmpresasClienteByIdentificationRouteParams>();

  const [loading, setLoading] = useState(false);
  const [companyGroup, setCompanyGroup] = useState<
    CompanyGroupAssociationsItem[]
  >([]);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { reset } = form;

  const load = useCallback(async () => {
    if (!identification) {
      return;
    }

    try {
      setLoading(false);
      const { data } = await service.onboarding.getCompaniesFromGroupByTaxId(
        identification,
      );

      const resetValues = {
        description: data.description,
        groupName: data.groupName,
        officialName: data.officialName,
        taxId: data.taxId,
        type: data.type,
      };

      setCompanyGroup(data.companyGroupAssociations);
      reset(resetValues);
    } catch {
      OToastManager.danger("Erro ao obter detalhes do grupo");
    } finally {
      setLoading(false);
    }
  }, [identification, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    companyGroup,
    form,
    loading,
  };
};
