import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const entityScoresGrid = {
  datagrid: {
    noDataText: "Nenhum score encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar scores.";
    },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubCreditManager.odata.entityScoresAdmin,
    ),
  },
  columns: [
    {
      dataField: "Id",
      dataType: "number",
      caption: "Id",
    },
    {
      caption: "Data da recepção",
      dataField: "CreatedDate",
      dataType: "datetime",
      sortOrder: "desc",
    },
    {
      caption: "Data do score",
      dataField: "ScoreDate",
      dataType: "datetime",
    },
    {
      caption: "Nome",
      dataField: "LegalEntityName",
    },
    {
      caption: "CNPJ",
      dataField: "LegalEntityTaxId",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Score",
      dataField: "Score",
    },
    {
      caption: "Nível",
      dataField: "LevelName",
    },
    {
      caption: "Taxa conversão",
      dataField: "ConversionRate",
      format: (value) => masks.percentage(value),
    },
    {
      caption: "Taxa aprovação",
      dataField: "ApprovalRate",
      format: (value) => masks.percentage(value),
    },
    {
      caption: "Meta",
      dataField: "GoalValue",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      caption: "Valor atingido",
      dataField: "CreatedProposalsValue",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      caption: "Clientes inadimplentes",
      dataField: "ContractsDefaultAmount",
    },
    {
      caption: "Clientes em carência",
      dataField: "ContractsGracePeriodAmount",
    },
    {
      caption: "Clientes adimplentes",
      dataField: "ContractsCompliantAmount",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubCreditManager.odata.getEntityScores>
    >["data"]["value"]
  >[number]
>;
