import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

type Invoice = NonNullable<
  Awaited<
    ReturnType<typeof service.hubCreditManager.getConsumerUnitDetails>
  >["data"]["energyInvoice"]
>["history"][number];

export const invoiceGrid = {
  datagrid: {
    noDataText: "Nenhuma fatura encontrada",
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "effectiveDate",
      dataType: "date",
      format: "MMMM 'de' yyyy",
      caption: "Data de vigência",
      sortOrder: "asc",
    },
    {
      dataField: "peakConsumption",
      caption: "Consumo ponta",
      format: (value) => `${value.toString().replace(".", ",")} kWh`,
    },
    {
      dataField: "offPeakConsumption",
      caption: "Consumo fora ponta",
      format: (value) => `${value.toString().replace(".", ",")} kWh`,
    },
    {
      dataField: "peakDemand",
      caption: "Demanda ponta",
      format: (value) => `${value.toString().replace(".", ",")} kW`,
    },
    {
      dataField: "offPeakDemand",
      caption: "Demanda fora ponta",
      format: (value) => `${value.toString().replace(".", ",")} kW`,
    },
    {
      alignment: "center",
      caption: "ACL",
      columns: [
        {
          dataField: "peakDemandAcr",
          caption: "Demanda ponta",
          format: (value) => `${value.toString().replace(".", ",")} kW`,
        },
        {
          dataField: "offPeakDemandAcr",
          caption: "Demanda fora ponta",
          format: (value) => `${value.toString().replace(".", ",")} kW`,
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<Invoice>;
