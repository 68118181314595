import { OBadge, ISize } from "@maestro/react";
import { getInitials } from "./user-menu.utils";

interface UserBadgeProps {
  name: string;
  size?: ISize;
}

export const UserBadge = ({ name, size = "md" }: UserBadgeProps) => {
  return (
    <OBadge title={name} type="dark" size={size} outline circle>
      {getInitials(name)}
    </OBadge>
  );
};
