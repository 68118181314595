import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { brazilStates } from "services/public/models/types/brazil-states.utils";
import { CitySelector } from "./_compose";
import { addressFormFields } from "./address.form";
import { useAddressForm } from "./address.hook";

interface AddressFieldsProps {
  disabled?: boolean;
}

export const AddressFields = ({ disabled }: AddressFieldsProps) => {
  const { loading } = useAddressForm();

  return (
    <>
      <ORow>
        <OCol xs={12} md={6} className="d-flex align-items-center gap-2">
          <ORFieldInput
            {...addressFormFields.address.zipCode}
            disabled={disabled}
          />
          {loading && <OLoader />}
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...addressFormFields.address.street}
            disabled={loading || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...addressFormFields.address.number}
            disabled={loading || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...addressFormFields.address.complement}
            disabled={loading || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            {...addressFormFields.address.neighborhood}
            disabled={loading || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldSelect
            {...addressFormFields.address.state}
            disabled={loading || disabled}
          >
            {brazilStates.map((state) => (
              <OOption key={state.uf} value={state.uf}>
                {state.uf}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6}>
          <CitySelector disabled={loading || disabled} />
        </OCol>
      </ORow>
    </>
  );
};
