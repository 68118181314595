import {
  OCol,
  OField,
  OIcon,
  OLoader,
  ORow,
  OUpload,
  OUploadInput,
  OUploadLabel,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { grid } from "./upload-file.grid";
import { useUploadFile } from "./upload-file.hook";
import { UploadContainer } from "./upload-file.styles";
import { UploadFileProps } from "./upload-file.types";

export const UploadFile = ({ curvaPre, setCurvaPre }: UploadFileProps) => {
  const { loading, readerLoader, handleAddFile } = useUploadFile({
    setCurvaPre,
  });

  return (
    <>
      {(loading || readerLoader) && <OLoader absolute />}

      <ORow className="mb-3">
        <OCol xs={12} md={6} xl={4}>
          <UploadContainer>
            <OUpload onAddFile={handleAddFile}>
              <div>
                <OField id="rateCurve" disabled={loading}>
                  <div className="d-flex flex-column gap-3">
                    <OUploadInput
                      id="rateCurve"
                      name="rateCurve"
                      dataAction="curva_taxas:upload:documento"
                      dataLabel="documento"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      multiple={false}
                      disabled={loading}
                    />

                    <OUploadLabel htmlFor="rateCurve" size="md">
                      Selecionar documento
                      <OIcon category="far" icon="fa-cloud-upload" />
                    </OUploadLabel>
                  </div>
                </OField>
              </div>
            </OUpload>
          </UploadContainer>
        </OCol>
      </ORow>

      <ODataGridGenerator grid={grid} dataSource={curvaPre} />
    </>
  );
};
