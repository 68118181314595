import {
  OCard,
  OCardBody,
  OCol,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
// import {
//   getAccountCards,
//   getBankAccountList,
// } from "../bank-account-list/bank-account.utils";
import { useSimulation } from "../simulation/simulation.context";
import { paymentMethodMap } from "./settlement-type.utils";

export const SettlementTypes = () => {
  const {
    selectedSettlementType,
    setSettlementType,
    summary,
    bankAccountsList,
    // handleChangeSettlementBank,
    // selectedSettlementBank,
  } = useSimulation();

  const hasMultipleSettlementTypes =
    !!summary && summary?.allowedSettlementTypes.length > 1;

  // alterar para ser o loading de bankAccountsList
  // const hasAccountToShow = !!bankAccountsList.length;

  const renderSettlements = useMemo(() => {
    if (!hasMultipleSettlementTypes || !bankAccountsList.length) return;

    return (
      <div className="d-flex flex-row gap-3 mb-4">
        {summary?.allowedSettlementTypes.map((opt) => (
          <OCard
            key={`card-settlement-type-${opt}`}
            type="light"
            className="w-30 mb-4"
          >
            <OCardBody className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                {paymentMethodMap[opt].icon}
                <OTypography>{paymentMethodMap[opt].label}</OTypography>
              </div>
              <ORadio
                className="gap-2"
                id={`settlementPaymentMethodId-${opt}`}
                value={opt.toString()}
                checked={selectedSettlementType === opt}
                onClick={() => setSettlementType(opt)}
                defaultChecked
              />
            </OCardBody>
          </OCard>
        ))}
      </div>
    );
  }, [
    bankAccountsList,
    selectedSettlementType,
    setSettlementType,
    summary?.allowedSettlementTypes,
    hasMultipleSettlementTypes,
  ]);

  // const renderSettlementAccount = useMemo(() => {
  //   if (
  //     !selectedSettlementType ||
  //     paymentMethodMap[selectedSettlementType].display !== "DebitoEmConta"
  //   )
  //     return;

  //   return (
  //     <>
  //       <ORow>
  //         <OCol>
  //           <OTypography size="xl" className="mb-2">
  //             Conta para Liquidação
  //           </OTypography>
  //         </OCol>
  //       </ORow>
  //       <ORow>
  //         <OCol>
  //           <OTypography type="default" className="mb-4">
  //             Indique a conta no qual o dinheiro será debitado
  //           </OTypography>
  //         </OCol>
  //       </ORow>
  //       <div className="d-flex flex-row gap-3 mb-4">
  //         {!!summary?.allowedAccountsForSettlement.length &&
  //           getBankAccountList(summary, bankAccountsList)
  //             ?.filter(
  //               (bank) =>
  //                 bank.bankCode === "208" &&
  //                 summary?.allowedAccountsForSettlement.includes(bank.agency),
  //             )
  //             .map((account) => (
  //               <OCard key={`$card-info_${account.accountNumber}`}>
  //                 <OCardBody>
  //                   <ORow>
  //                     {getAccountCards(account, account.accountNumber)}
  //                     <OCol sm={2}>
  //                       <ORadio
  //                         value={account.id.toString()}
  //                         checked={selectedSettlementBank?.id === account.id}
  //                         onClick={() => {
  //                           handleChangeSettlementBank(account.id.toString());
  //                         }}
  //                         defaultChecked
  //                         id={`settlement-bank-radio-${account.id}`}
  //                       />
  //                     </OCol>
  //                   </ORow>
  //                 </OCardBody>
  //               </OCard>
  //             ))}
  //       </div>
  //     </>
  //   );
  // }, [
  //   bankAccountsList,
  //   handleChangeSettlementBank,
  //   selectedSettlementBank?.id,
  //   selectedSettlementType,
  //   summary,
  // ]);

  return (
    <div>
      {hasMultipleSettlementTypes && (
        <>
          <ORow>
            <OCol>
              <OTypography size="xl" className="mb-2">
                Forma de pagamento das parcelas
              </OTypography>
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <OTypography type="default" className="mb-4">
                Escolha uma das formas disponíveis
              </OTypography>
            </OCol>
          </ORow>
          <ORow>{renderSettlements}</ORow>

          {/* {hasAccountToShow && <ORow>{renderSettlementAccount}</ORow>} */}
        </>
      )}
    </div>
  );
};
