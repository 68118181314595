import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { distributorConfigFormFields } from "./distributor-config.form";

export const DistributorConfigFields = () => {
  return (
    <ORow gap={3}>
      <OCol xs={12} md={6}>
        <ORFieldSelect {...distributorConfigFormFields.communicationType}>
          <OOption value="EMAIL">Via e-mail</OOption>
          <OOption value="PORTAL">Via portal</OOption>
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...distributorConfigFormFields.portalUrl} />
      </OCol>
    </ORow>
  );
};
