import * as yup from "yup";

export interface DeskAccountInfoFormValues {
  agency: number;
  idAccountType: number;
  idAccountSubtype?: number | null;
  idAccountService?: number | null;
  accountType: string;
  accountDescription: string;
}

export const deskAccountInfoFormValidationSchema: yup.ObjectSchema<DeskAccountInfoFormValues> =
  yup.object({
    agency: yup.number().required("Este campo é obrigatório"),
    idAccountType: yup.number().required("Este campo é obrigatório"),
    idAccountSubtype: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    idAccountService: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    accountType: yup.string().required("Este campo é obrigatório"),
    accountDescription: yup.string().required("Este campo é obrigatório"),
  });
