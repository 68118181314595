import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { logger } from "utils/logger";
import { RefreshGridButton } from "components/refresh-grid-button";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { useLeadApproval } from "../workflow-lead-details.hook";
import { workflowLeadDetailsGenerator } from "./workflow-lead-details.details";

export const WorkflowLeadDetailsPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();
  const [loading, setLoading] = useState(false);

  if (!id) throw new Error("No id");

  const { evaluateLead } = useLeadApproval();

  const {
    callService: getWorkflowLeadsSummary,
    value: leadDetails,
    hasError,
    loading: loadingLeadDetails,
  } = useServiceCall(service.hubCreditManager.getWorkflowLeadsSummary);

  const {
    callService: getWorkflowLeadsProcessorsExecuting,
    value: processorsExecuting,
    loading: loadingProcessorsExecuting,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowLeadsProcessorsExecuting,
  );

  const fetch = useCallback(async () => {
    await Promise.all([
      getWorkflowLeadsSummary(id),
      getWorkflowLeadsProcessorsExecuting(id),
    ]);
  }, [getWorkflowLeadsProcessorsExecuting, getWorkflowLeadsSummary, id]);

  const sendLeadApproval = useCallback(
    async (approval: string) => {
      try {
        setLoading(true);

        await evaluateLead(approval, processorsExecuting);

        fetch();
      } catch (error) {
        logger.error(error);
      } finally {
        setLoading(false);
      }
    },
    [evaluateLead, fetch, processorsExecuting],
  );

  useEffect(() => {
    fetch();
  }, [fetch, id]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do Lead" />}
      actions={
        <>
          {processorsExecuting?.some(
            (p) => p.processorType === "PENDENTE_APROVACAO",
          ) && (
            <>
              <LoadingButton
                loading={loading}
                type="info"
                onClick={() => sendLeadApproval("APPROVED")}
              >
                Aprovar
              </LoadingButton>
              <LoadingButton
                loading={loading}
                type="danger"
                onClick={() => sendLeadApproval("DISAPPROVED")}
              >
                Reprovar
              </LoadingButton>
              <RouterButton
                type="warning"
                href={corporateRouter.routes.workflow.product.leads.details.pendencies.path(
                  {
                    id,
                  },
                )}
              >
                Pendenciar
              </RouterButton>
            </>
          )}
          <RefreshGridButton
            onClick={() =>
              !loadingLeadDetails && !loadingProcessorsExecuting && fetch()
            }
          />
        </>
      }
    >
      <DetailsCard
        loading={loadingLeadDetails || loadingProcessorsExecuting}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os detalhes do lead."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getWorkflowLeadsSummary(id)} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={leadDetails}
        fields={workflowLeadDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
