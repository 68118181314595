import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { CedenteTierConfig } from "./cedente-limits.types";
import { productsMap } from "./cedente-limits.utils";

export const dataSource = dataSourceCustomStoreGenerator<CedenteTierConfig>(
  () =>
    service.quickfin
      .getCededenteLimits()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage =
          "Erro ao buscar as configurações de limite de cedente";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      update: async (original, updated) => {
        try {
          await service.quickfin.editCededenteLimit(original.id, {
            ...original,
            ...updated,
            limit: String(updated.limit ?? original.limit),
          });
          OToastManager.success("Limite editado com sucesso");
        } catch {
          const errorMessage = "Erro ao editar o limite";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
      remove: async ({ id }) => {
        try {
          await service.quickfin.removeCededenteLimit(id);
          OToastManager.success("Limite removido com sucesso");
        } catch {
          const errorMessage = "Erro ao remover o limite";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    },
  },
);

export const cedenteLimitsGrid: ODataGridGeneratorConfig<CedenteTierConfig> = {
  datagrid: {
    noDataText: "Nenhum limite.",
    filterRow: { visible: true },
    editing: {
      allowUpdating: true,
      allowDeleting: true,
      mode: "row",
      selectTextOnEditStart: true,
      texts: {
        confirmDeleteMessage: "Tem certeza que deseja remover este limite?",
      },
    },
  },
  columns: [
    {
      dataField: "cedenteName",
      dataType: "string",
      caption: "Cedente",
      allowEditing: false,
    },
    {
      dataField: "cedenteIdentification",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowEditing: false,
    },
    {
      dataField: "limit",
      dataType: "number",
      caption: "Limite",
      format: (value) => masks.currency(value, "R$"),
      validationRules: [{ type: "required" }, { type: "numeric" }],
    },
    {
      dataField: "tipoProduto",
      dataType: "string",
      caption: "Tipo do produto",
      lookup: {
        dataSource: Object.entries(productsMap).map(([k, v]) => ({
          value: k,
          label: v,
        })),
        displayExpr: "label",
        valueExpr: "value",
      },
    },
    {
      dataField: "createdAt",
      dataType: "datetime",
      format: "shortDate",
      caption: "Data de criação",
      allowEditing: false,
    },
    {
      dataField: "updatedAt",
      dataType: "datetime",
      format: "shortDate",
      caption: "Última atualização",
      allowEditing: false,
    },
  ],
};
