import { OToastManager, modalManager } from "@maestro/core";
import { DataGrid } from "devextreme-react";
import { useCallback, useMemo, useState } from "react";
import { service } from "services";
import {
  PostAdminCancelableReceivablesResponse,
  PostAdminReceivablesCancelBody,
} from "services/quickfin/models";
import { receivablesCancelingModalId } from "../canceling.utils";

export const useCancelingModal = (
  gridRef: React.RefObject<DataGrid>,
  receivables: PostAdminCancelableReceivablesResponse["cancelableReceivables"],
  setSelectedReceivables: React.Dispatch<
    React.SetStateAction<
      | PostAdminCancelableReceivablesResponse["cancelableReceivables"]
      | undefined
    >
  >,
  refetchReceivables: () => Promise<void>,
) => {
  const [loading, setLoading] = useState(false);

  const reducedReceivables = useMemo(() => {
    const list: PostAdminCancelableReceivablesResponse["cancelableReceivables"] =
      [];

    const receivablesCopy = structuredClone(receivables);

    receivablesCopy.forEach((receivable) => {
      const found = list.find(
        (query) =>
          query.sacado === receivable.sacado &&
          query.cedente === receivable.cedente,
      );
      if (found) {
        found.valorVencimento += receivable.valorVencimento;
      } else {
        list.push(receivable);
      }
    });

    return list;
  }, [receivables]);

  const totalSum = useMemo(() => {
    return reducedReceivables.reduce<number>(
      (sum, receivable) => sum + receivable.valorVencimento,
      0,
    );
  }, [reducedReceivables]);

  const cancelReceivables = useCallback(async () => {
    const body: PostAdminReceivablesCancelBody = {
      receivablesId: receivables?.map((receivable) => receivable.id) ?? [],
      sacadoId: 1,
    };

    setLoading(true);
    try {
      await service.quickfin.postCancelReceivables(body);
      OToastManager.success("Recebíveis cancelados com sucesso!");
      gridRef.current?.instance.clearSelection();
      gridRef.current?.instance.getDataSource().reload();
      setSelectedReceivables([]);
      refetchReceivables();
    } catch {
      OToastManager.danger("Não foi possivel cancelar os recebíveis");
    } finally {
      setLoading(false);
      modalManager.hide(receivablesCancelingModalId);
    }
  }, [gridRef, receivables, refetchReceivables, setSelectedReceivables]);

  return {
    cancelReceivables,
    loading,
    reducedReceivables,
    totalSum,
  };
};
