import { OButton, modalManager } from "@maestro/react";
import { ExcludeUserModal } from "../delete-user-modal";
import { UsuariosProdutoByIdentificationRouteParams } from "pages/usuarios/routes";
import { useParams } from "react-router-dom";

export const ExcludeUser = () => {
  const { identification } = useParams<UsuariosProdutoByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  return (
    <>
      <OButton
      dataAction="ferramentas:botao:excluir"
      dataLabel="excluir"
      onClick={() => modalManager.show("delete-user-modal")}
      >   
      Excluir
      </OButton>
      <ExcludeUserModal removeUserTaxId={identification}/>
    </>

  );
};
