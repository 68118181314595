import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { DomainScfForm } from "../_compose/domain-scf-form";
import { useAddPartnerConfigurationScf } from "./add-partner-configuration-scf.hook";

export const AddPartnerConfigurationScf = () => {
  const { form } = useAddPartnerConfigurationScf();

  return (
    <>
      <PageTitle title="Adicionar domínio" />

      <FormProvider {...form}>
        <DomainScfForm />
      </FormProvider>
    </>
  );
};
