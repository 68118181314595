import { OField, OTypography, TooltipContainer } from "@maestro/react";
import { FieldError } from "react-hook-form";
import { SelectSearch } from "../../select-search";
import { SelectedValue } from "./_compose";
import { useSelectSearchMultiple } from "./select-search-multiple.hook";
import { SelectSearchMultipleProps } from "./select-search-multiple.types";
import { findLabel } from "./select-search-multiple.utils";

export const SelectSearchMultiple = <T extends string | number>(
  props: SelectSearchMultipleProps<T>,
) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    valuesLabel = "Opções selecionadas:",
    options: originalOptions,
    ...rest
  } = props;

  const { form, options, removeValue } = useSelectSearchMultiple(
    name,
    originalOptions,
  );

  const { formState, getFieldState, watch } = form;

  const { error: fieldErrors } = getFieldState(name, formState);

  const outerValue = watch(name) as Array<string | number> | null | undefined;

  return (
    <div className="w-100">
      <OField
        htmlFor={rest.id}
        error={!!fieldErrors}
        message={
          fieldErrors?.message ??
          (fieldErrors as FieldError[] | undefined)?.[0]?.message
        }
        description={description}
        label={label}
        labelType={labelType}
        required={required}
        disabled={rest.disabled}
        labelSize={rest.labelSize ?? "sm"}
      >
        {tooltip && (
          <TooltipContainer
            position={tooltipPosition}
            maxWidth={tooltipMaxWidth}
          >
            {tooltip}
          </TooltipContainer>
        )}
        <SelectSearch
          {...props}
          name={`${name}-search`}
          options={options}
          eager={false}
          clearInputOnSelect
          className="w-100"
        />
      </OField>
      {!!outerValue?.length && (
        <OTypography size="sm" className="mb-2">
          {valuesLabel}
        </OTypography>
      )}
      <div className="d-flex flex-wrap gap-2" key={outerValue?.length}>
        {outerValue?.map((value) => (
          <SelectedValue
            key={value}
            disabled={rest.disabled ?? false}
            value={findLabel(value, options)}
            removeValue={() => removeValue(value)}
          />
        ))}
      </div>
    </div>
  );
};
