import { getValueFromMap } from "utils/get-value-from-map";
import { parseExcelDate } from "utils/parse-excel-date";
import { cleanTaxId, normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { cardBrandMap } from "../../../../../../utils/card-brand-map";
import { IntubateUpdate } from "../../intubate-generic.types";

export const parseIntubateCancelSpreadsheet = (
  buffer: FileReader["result"],
): IntubateUpdate[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  const rows = sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    const identop = String(cleanRow.identop).trim().replace(/\D/g, "");
    const externalId = String(cleanRow["id solicitante"]);

    if (
      [
        cleanRow["cnpj do cedente"],
        cleanRow["cnpj do sacado"],
        cleanRow["data de vencimento"],
        cleanRow.bandeira,
        cleanRow["valor solicitado"],
        cleanRow["trade id (canal)"],
      ].some((value) => value === undefined)
    ) {
      return {
        externalId: externalId,
        identop: identop
      };
    }

    const maturityDate = parseExcelDate(cleanRow["data de vencimento"]);

    return {
      sacadoTaxId: cleanTaxId(String(cleanRow["cnpj do sacado"])),
      cedenteTaxId: cleanTaxId(String(cleanRow["cnpj do cedente"])),
      bandeira:
        (getValueFromMap(cardBrandMap, cleanRow.bandeira) || cleanRow.bandeira).trim(),
      maturityDate,
      valorSolicitado: cleanRow["valor solicitado"],
      tradeId: String(cleanRow["trade id (canal)"]),
      externalId: externalId,
      identop: identop,
    };
  });

  return rows;
};
