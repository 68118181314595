import { OInputText } from "@maestro/react";
import { InputContent, InputLabel } from "../compose.styles";
import { IInputTextProps } from "./question-text-compose.types";

export const InputText = ({
  name,
  label,
  placeholder,
  type,
  isEditable,
  fields,
  handleInputChange,
  errors,
}: IInputTextProps) => {
  const onIteration = (value: string) => {
    handleInputChange({
      name: name,
      value: type === "text" ? value : value.replace(/\D/g, ""),
      label: label,
    });
  };
  return (
    <div key={name}>
      <InputLabel>{label}</InputLabel>
      <InputContent>
        <OInputText
          dataAction={`chargeback:input:${label}`}
          dataLabel={label}
          id={name}
          name={name}
          value={fields[name]}
          placeholder={placeholder}
          disabled={!isEditable}
          onInput={(e) => onIteration(e.currentTarget.value || "")}
        />
        {errors[name] && <span>{errors[name]}</span>}
      </InputContent>
    </div>
  );
};
