import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { LoadingButton } from "components/loading-button";
import { roles } from "roles/roles";
import { useSemaphoreConfigurationDetails } from "./details.hook";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "../../../../../../routes/ferramentas.route-params";
import { SemaphoreTemplateForm } from "../_compose";

export const SemaphoreConfigurationDetails = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  const { form, loading, removeSemaphoreTemplate } =
    useSemaphoreConfigurationDetails(uuid as string);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de semáforo"
          description="Detalhes da configuração de semáforo"
        />
      }
      actions={
        <LoadingButton
          dataAction="acoes:botao:remover"
          dataLabel="aprovar"
          loading={loading}
          onClick={removeSemaphoreTemplate}
          type="danger"
          role={roles.ferramentas.product.semaphoreConfigTemplateRemove.role}
        >
          Remover
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <SemaphoreTemplateForm mode="update" uuid={uuid} />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
