import { OButton, OLoader, OModalFooter } from "@maestro/react";

interface Props {
  handleFilter: CallableFunction;
  handleClear: CallableFunction;
  loading: boolean;
}

export const ExportModalFooter = ({
  handleFilter,
  handleClear,
  loading,
}: Props) => {
  return (
    <OModalFooter divider>
      {loading ? (
        <OLoader className="d-flex justify-content-end gap-2 md-10" />
      ) : (
        <div className="d-flex justify-content-end gap-2">
          <OButton
            onClick={() => {
              handleClear();
            }}
            outline
            dataLabel=""
            dataAction=""
          >
            Voltar
          </OButton>

          <OButton
            onClick={async () => {
              handleFilter();
            }}
            dataLabel=""
            dataAction=""
          >
            Finalizar
          </OButton>
        </div>
      )}
    </OModalFooter>
  );
};
