import { ODataGridGenerator } from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { SignatureRule } from "../../customer-signatures.types";
import { buildSignatureGroupsGrid } from "./signature-groups-grid.grid";

interface SignatureGroupsGridProps {
  signatureRule: SignatureRule;
}

export const SignatureGroupsGrid = ({
  signatureRule,
}: SignatureGroupsGridProps) => {
  const signatureGroupsGrid = useMemo(
    () => buildSignatureGroupsGrid(signatureRule.id),
    [signatureRule.id],
  );

  return (
    <GridTemplate
      actions={
        <StandardLink
          href={corporateRouter.routes.recebiveis.customer.signatures.byRuleId.add.path(
            { ruleId: signatureRule.id },
          )}
          role={roles.recebiveis.customer.signaturesByRuleIdAddPage.role}
        >
          Adicionar
        </StandardLink>
      }
    >
      <ODataGridGenerator
        grid={signatureGroupsGrid}
        dataSource={signatureRule.groupOfSignatures}
      />
    </GridTemplate>
  );
};
