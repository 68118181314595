import {
  OCard,
  OCardBody,
  OCardHeader,
  OStep,
  OStepHeader,
} from "@maestro/react";
import { cases } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { steps } from "./_steps/steps";
import { UploadTiersProvider, useUploadTiers } from "./upload-tiers.context";

const UploadTiersComponent = () => {
  const { stepNumber } = useUploadTiers();

  const CurrentStep = steps[stepNumber].component;

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Upload de Tiers"
          description="Envie a lista de relacionamentos via Excel. Um relacionamento é composto por banco emissor, credenciadora e tier."
        />
      }
    >
      <OCard>
        <OCardHeader divider>
          <OStepHeader activeStep={stepNumber} disableNavigation>
            {steps.map((step) => {
              const dataLabel = cases.snake(step.name);

              return (
                <OStep
                  key={step.name}
                  dataAction={`tier_upload:step:${dataLabel}`}
                  dataLabel={dataLabel}
                >
                  {step.name}
                </OStep>
              );
            })}
          </OStepHeader>
        </OCardHeader>
        <OCardBody className="pt-5">
          <CurrentStep />
        </OCardBody>
      </OCard>
    </DetailsTemplate>
  );
};

export const UploadTiers = () => (
  <UploadTiersProvider>
    <UploadTiersComponent />
  </UploadTiersProvider>
);
