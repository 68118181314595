import { env } from "utils/environments";

export const adminBankinghubEndpoints = {
  account: {
    accounts: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/Account/accounts`,
      byId: (id: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Account/accounts/${id}`,
      blockUser: (account: string, agency: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Account/208/${agency}/${account}/blockUser`,
      unlockUser: (account: string, agency: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Account/208/${agency}/${account}/unlockUser`,
      operations: (agency: string, accountNumber: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Account/operations/agency/${agency}/accountNumber/${accountNumber}`,
    },
    permissions: `${env.API_BANKINGHUB_ADMIN}/Account/permissions`,
  },
  balance: {
    byAccount: {
      byAgency: (account: number, agency: number) =>
        `${env.API_BANKINGHUB_ADMIN}/Balance/balance/208/${account}/${agency}`,
    },
  },
  balanceYield: {
    balanceYield: {
      byId: (id: string) =>
        `${env.API_BANKINGHUB_ADMIN}/BalanceYield/balanceyield/${id}`,
    },
  },
  bankingManager: {
    companies: `${env.API_BANKINGHUB_ADMIN}/BankingManager/companies`,
    company: `${env.API_BANKINGHUB_ADMIN}/BankingManager/company`,
  },
  beneficiary: {
    endpoint: `${env.API_BANKINGHUB_ADMIN}/Beneficiary`,
    floating: `${env.API_BANKINGHUB_ADMIN}/Beneficiary/floating`,
  },
  card: {
    accounts: {
      summary: `${env.API_BANKINGHUB_ADMIN}/Card/accounts/summary`,
    },
    cards: {
      summary: `${env.API_BANKINGHUB_ADMIN}/Card/cards/summary`,
    },
    contestations: {
      summary: `${env.API_BANKINGHUB_ADMIN}/Card/contestations/summary`,
    },
    offers: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/Card/offers`,
      notifications: `${env.API_BANKINGHUB_ADMIN}/Card/offers/notifications`,
      summary: `${env.API_BANKINGHUB_ADMIN}/Card/offers/summary`,
    },
    transactions: {
      summary: `${env.API_BANKINGHUB_ADMIN}/Card/transactions/summary`,
    },
    companies: {
      transactions: {
        summary: `${env.API_BANKINGHUB_ADMIN}/Card/companies/transactions/summary`,
      },
    },
  },
  cnab: {
    cnabTracing: `${env.API_BANKINGHUB_ADMIN}/Cnab/cnabTracing`,
    statement: {
      vans: `${env.API_BANKINGHUB_ADMIN}/Cnab/statement/vans`,
      account: `${env.API_BANKINGHUB_ADMIN}/Cnab/statement/account`,
      reprocessFile: `${env.API_BANKINGHUB_ADMIN}/Cnab/statement/reprocess-file`,
      traceabilities: {
        byDocument: {
          byAccout: {
            byAgency: (
              documentId: string,
              accountId: string,
              agencyId: string,
            ) =>
              `${env.API_BANKINGHUB_ADMIN}/Cnab/statement/traceabilities/${documentId}/${accountId}/${agencyId}`,
          },
        },
      },
    },
    integration: {
      byProduct: (product: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Cnab/integrations?productId=${product}`,
    },
  },
  collection: {
    endpoint: `${env.API_BANKINGHUB_ADMIN}/Collection`,
    byValue: (collectionId: string) =>
      `${env.API_BANKINGHUB_ADMIN}/Collection/${collectionId}`,
    archiveById: (collectionId: string) =>
      `${env.API_BANKINGHUB_ADMIN}/Collection/archive/${collectionId}`,
    cancelAll: `${env.API_BANKINGHUB_ADMIN}/Collection/batch`,
    searchBankslipCIP: (digitableLine: string) =>
      `${env.API_BANKINGHUB_ADMIN}/Collection/bank-slip/cip/${digitableLine}`,
    getEvents: (collectionId: string) =>
      `${env.API_BANKINGHUB_ADMIN}/Collection/events/${collectionId}`,
    agreement: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/Collection/agreement`,
      byId: (agreementId: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Collection/agreement/${agreementId}`,
    },
  },
  configHub: {
    agreements: `${env.API_BANKINGHUB_ADMIN}/ConfigHub/agreements`,
  },
  contact: {
    byContactHolder: (taxId?: string) =>
      `${env.API_BANKINGHUB_ADMIN}/Contact${taxId ? `/${taxId}` : ""}`,
  },
  overdraft: {
    limit: {
      byIdentification: (identification: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Overdraft/limit/${identification}`,
    },
    operations: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/overdraft/operations`,
      operationById: (operationId: string) =>
        `${env.API_BANKINGHUB_ADMIN}/overdraft/operations/${operationId}`,
    },
  },
  pix: {
    keys: {
      byTaxId: `${env.API_BANKINGHUB_ADMIN}/Pix/keys/active`,
      byKey: (keyValue: string) =>
        `${env.API_BANKINGHUB_ADMIN}/Pix/key/${keyValue}`,
    },
  },
  fileProcessor: {
    files: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/files`,
      details: {
        byFileId: (fileId: string) =>
          `${env.API_BANKINGHUB_ADMIN}/FileProcessor/files/details/${fileId}`,
      },
    },
    integration: {
      endpoint: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/integration`,
      listIntegrations: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/integrations`,
      updateIntegration: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/integration`,
      registerIntegration: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/integrations`,
    },
    company: {
      registerCompanyLayout: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/companies/layouts`,
      registerCompany: `${env.API_BANKINGHUB_ADMIN}/FileProcessor/companies`,
    },
  },
};
