import { OTypography } from "@maestro/react";

export interface PageTitleProps {
  title?: string;
  description?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, description }) => {
  return (
    <div className="mb-4">
      {title && (
        <OTypography tag="h1" type="dark" size="xl" key={title}>
          {title}
        </OTypography>
      )}

      {description && (
        <OTypography tag="p" type="default" key={description}>
          {description}
        </OTypography>
      )}
    </div>
  );
};
