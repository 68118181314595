import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { MleByConsumerUnitIdRouteParams } from "../../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../../utils";
import {
  ConsumerUnitForm,
  consumerUnitFormDefaultValues,
  consumerUnitFormValidationSchema,
} from "./edit-consumer-unit.form";
import {
  mapFormToRequest,
  mapResponseToForm,
} from "./edit-consumer-unit.utils";

export const useEditConsumerUnit = () => {
  const { consumerUnitId } = useParams<MleByConsumerUnitIdRouteParams>();
  if (!consumerUnitId) throw new Error("No consumerUnitId");

  const {
    callService: _getConsumerUnit,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getConsumerUnitDetails);

  const { callService: _updateConsumerUnit, loading: submitLoading } =
    useServiceCall(service.hubEnergy.updateConsumerUnit);

  const navigate = useNavigate();

  const form = useForm<ConsumerUnitForm>({
    defaultValues: consumerUnitFormDefaultValues,
    resolver: yupResolver(consumerUnitFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getConsumerUnit = useCallback(
    async () => _getConsumerUnit(consumerUnitId),
    [_getConsumerUnit, consumerUnitId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!fetchValue?.response.id)
          return OToastManager.danger("Valores não carregados");

        const { success, error } = await _updateConsumerUnit({
          consumerUnitId: fetchValue.response.id,
          ...mapFormToRequest(values),
        });

        if (success) {
          OToastManager.success("Unidade consumidora editada com sucesso");
          navigate(
            corporateRouter.routes.energia.customer.consumerUnit.details.path({
              consumerUnitId,
            }),
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Erro ao editar a unidade consumidora",
          );
        }
      }),
    [_updateConsumerUnit, consumerUnitId, fetchValue, handleSubmit, navigate],
  );

  useEffect(() => {
    getConsumerUnit();
  }, [getConsumerUnit]);

  useEffect(() => {
    if (fetchValue?.response) {
      reset(mapResponseToForm(fetchValue.response));
    }
  }, [fetchValue?.response, reset]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getConsumerUnit,
    submit,
    submitLoading,
  };
};
