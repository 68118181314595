import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { roles } from "roles/roles";
import { useSemaphoreStepAdd } from "./add.hook";
import { SemaphoreStepForm } from "../_compose";

export const SemaphoreStepAdd = () => {
  const { submit, form, loading } = useSemaphoreStepAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Novo step para semáforo de onboarding"
          description="Adicionar novo step para configuração de semáforo"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="configuracao_semaforo_step:botao:adicionar"
          dataLabel="adicionar"
          onClick={submit}
          role={roles.ferramentas.product.semaphoreConfigStepAdd.role}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <SemaphoreStepForm />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
