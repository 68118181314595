import {
  OCol,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
} from "@maestro/react";
import {
  receivableStatusOptions,
  receivableTypesOptions,
} from "../../canceling.utils";

export const CancelingIdsInput = () => {
  return (
    <>
      <OCol xs={12} lg={4} xl={3}>
        <ORFieldInput
          tag="text"
          id="receivableIdentification"
          name="receivableIdentification"
          dataAction="cancelamento_recebiveis:texto:numero_nota"
          dataLabel="numero_nota"
          label="Nº nota"
        />
      </OCol>
      <OCol xs={12} lg={4} xl={2}>
        <ORFieldInput
          tag="text"
          id="receivableId"
          name="receivableId"
          dataAction="cancelamento_recebiveis:texto:id_recebivel"
          dataLabel="id_recebivel"
          label="Id Recebível"
        />
      </OCol>
      <OCol xs={12} lg={4} xl={2}>
        <ORFieldInput
          tag="text"
          id="borderoId"
          name="borderoId"
          dataAction="cancelamento_recebiveis:texto:id_bordero"
          dataLabel="id_bordero"
          label="Id borderô"
        />
      </OCol>
      <OCol xs={12} lg={6} xl={2}>
        <ORFieldSelect
          id="type"
          name="type"
          dataAction="cancelamento_recebiveis:select:tipo_recebiveis"
          dataLabel="tipo_recebiveis"
          label="Tipo dos recebíveis"
        >
          {receivableTypesOptions.map((type, idx) => (
            <OOption key={type} value={idx.toString()}>
              {type}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} lg={6} xl={3}>
        <ORFieldSelectMultiple
          id="receivableStatusList"
          name="receivableStatusList"
          dataAction="cancelamento_recebiveis:select:status_recebiveis"
          dataLabel="status_recebiveis"
          label="Status dos recebíveis"
        >
          {receivableStatusOptions.map((status) => (
            <OOptionMultiple key={status.value} value={status.value}>
              {status.label}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
    </>
  );
};
