import { PostCountry } from "services/hubfx/models/requests/post-admin-countries.request";
import * as yup from "yup";

export const countryFormValidationSchema: yup.ObjectSchema<PostCountry> =
  yup.object({
    code: yup
      .string()
      .length(2, "O código do país deve ter 2 dígitos")
      .matches(/^[^\d]+$/, "O código do país não pode conter números")
      .required("Este campo é obrigatório"),
    description: yup
      .string()
      .matches(/^[^\d]+$/, "O nome do país não pode conter números")
      .required("Este campo é obrigatório"),
    isApproved: yup.bool().required("Este campo é obrigatório"),
    bcbCode: yup.number().required("Este campo é obrigatório"),
  });
