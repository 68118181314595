import { JSX } from "@maestro/core";
import { OLink } from "@maestro/react";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { useRoles } from "hooks/roles";
import { ReactNode } from "react";
import { useLinkClickHandler } from "react-router-dom";
import { Role } from "roles/roles.types";

export interface RouterLinkProps {
  href: string;
  size?: JSX.OLink["size"];
  type?: JSX.OLink["type"];
  children: ReactNode;
  role?: Role;
}

const Link = ({ href, children, ...rest }: RouterLinkProps) => {
  return (
    <OLink
      href={href}
      onClick={useLinkClickHandler(href)}
      onAuxClick={useLinkNewTabHandler(href)}
      {...rest}
    >
      {children}
    </OLink>
  );
};

export const RouterLink = ({ role, ...rest }: RouterLinkProps) => {
  const { hasRole } = useRoles();
  return <>{hasRole(role) && <Link {...rest} />}</>;
};
