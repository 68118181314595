import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../utils";
import { useStepAnalysisStages } from "./_stages/step-analysis-stages.hook";
import { StepAnalysisStage } from "./_stages/step-analysis-stages.types";

interface StepAnalysisContext {
  /** Refaz a requisição perdendo o state */
  refetch: () => void;
  /** Refaz a requisição sem perder o state */
  refresh: () => Promise<void>;
  refreshLoading: boolean;
  selectedStage: StepAnalysisStage;
  selectedStageIndex: number;
  setSelectedStageIndex: React.Dispatch<React.SetStateAction<number>>;
  stages: StepAnalysisStage[];
  stepAnalysis: HubEnergy.DiligenceStepAnalysisResponse;
  setStepAnalysis: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceStepAnalysisResponse>
  >;
}

const stepAnalysisContext = createContext({} as StepAnalysisContext);

interface StepAnalysisProviderProps {
  stepAnalysis: HubEnergy.DiligenceStepAnalysisResponse;
  refetch: () => void;
  children: React.ReactNode;
}

export const StepAnalysisProvider = ({
  stepAnalysis: initialStepAnalysis,
  refetch,
  children,
}: StepAnalysisProviderProps) => {
  const [stepAnalysis, setStepAnalysis] = useState(initialStepAnalysis);

  const { callService: getNextStepAnalysis, loading: refreshLoading } =
    useServiceCall(service.hubEnergy.getNextStepAnalysis);

  const { selectedStage, selectedStageIndex, setSelectedStageIndex, stages } =
    useStepAnalysisStages(stepAnalysis);

  const refresh = useCallback(async () => {
    const { success, response, error } = await getNextStepAnalysis();

    if (success) {
      setStepAnalysis(response.data.response);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao fazer a requisição",
      );
    }
  }, [getNextStepAnalysis]);

  const value = useMemo(
    () => ({
      refetch,
      refreshLoading,
      refresh,
      selectedStage,
      selectedStageIndex,
      setSelectedStageIndex,
      setStepAnalysis,
      stages,
      stepAnalysis,
    }),
    [
      refetch,
      refresh,
      refreshLoading,
      selectedStage,
      selectedStageIndex,
      setSelectedStageIndex,
      stages,
      stepAnalysis,
    ],
  );

  return (
    <stepAnalysisContext.Provider value={value}>
      {children}
    </stepAnalysisContext.Provider>
  );
};

export const useStepAnalysisContext = () => useContext(stepAnalysisContext);
