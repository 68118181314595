import dayjs from "dayjs";
import DataSource from "devextreme/data/data_source";
import { UseFormWatch } from "react-hook-form";
import { Banner } from "services/hubcreditmanager/models/responses";
import { FormValuesType } from "./filter-modal.types";

const validateDate = (date: dayjs.Dayjs, value: string[]) => {
  if (value.length !== 2) return true;

  const [startDate, endDate] = value;

  if (!date.isValid()) return false;

  const isAfterStart =
    date.isAfter(dayjs(startDate), "day") ||
    date.isSame(dayjs(startDate), "day");

  const isBeforeEnd =
    date.isBefore(dayjs(endDate), "day") || date.isSame(dayjs(endDate), "day");

  return isAfterStart && isBeforeEnd;
};

const getFilters = () => {
  return [
    {
      key: "isActive",
      condition: (item: Banner, value: string[]) => {
        const isActive = value.includes("Ativo");
        const isInativo = value.includes("Inativo");

        const isBefore =
          item.isActive && dayjs().isBefore(dayjs(item.startingFrom));
        const isAfter = item.isActive && dayjs().isAfter(dayjs(item.endingAt));

        if (isBefore) {
          return value.includes("Lançamento futuro");
        }
        if (isAfter) {
          return value.includes("Expirado");
        }

        return (
          (isActive && item.isActive === true) ||
          (isInativo && item.isActive === false)
        );
      },
    },
    {
      key: "identification",
      condition: (item: Banner, value: string[]) => {
        return value.includes(item.bannerTemplate?.identification ?? "");
      },
    },
    {
      key: "accessRules",
      condition: (item: Banner, value: string[]) => {
        return item.accessRules.some((rule) => value.includes(rule.name));
      },
    },
    {
      key: "badge",
      condition: (item: Banner, value: string[]) => {
        return value.includes(item.badge);
      },
    },
    {
      key: "createdBy",
      condition: (item: Banner, value: string[]) => {
        return value.includes(item.createdBy.name);
      },
    },
    {
      key: "startingFrom",
      condition: (item: Banner, value: string[]) => {
        return validateDate(dayjs(item.startingFrom), value);
      },
    },
    {
      key: "endingAt",
      condition: (item: Banner, value: string[]) => {
        return validateDate(dayjs(item.endingAt), value);
      },
    },
  ];
};

export const filterData = (
  dataSource: DataSource<any, any>,
  watch: UseFormWatch<FormValuesType>,
) => {
  const filters = getFilters();

  return dataSource.filter((item: Banner) => {
    return filters.every(({ key, condition }) => {
      const watchValue = watch(key as keyof FormValuesType);
      if (watchValue?.length) return condition(item, watchValue);
      return true;
    });
  });
};
