import { OLabel, ORow } from "@maestro/react";
import styled from "styled-components";

export const StyledIndex = styled.div`
  font-size: 36px;
`;

export const StyledRow = styled(ORow)`
  display: flex;
  flex-direction: row;

  border: 1px solid var(--theme-dark-20);
  padding: 16px;
`;

export const JustifiedLabel = styled(OLabel)`
  text-align: left;
`;
