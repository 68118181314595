import { ONotification, OTypography } from "@maestro/react";
import { useGetUser } from "../../../../../../../../hooks";

export const InactiveUserNotification = () => {
    const { user } = useGetUser();

    return (user?.isActive ?? true) ? null : (
        <ONotification type="danger" width="100%">
            <div className="d-flex flex-column gap-2">
                <OTypography type="dark">
                    O usuário esta inativo
                </OTypography>
            </div>
        </ONotification>
    );
};
