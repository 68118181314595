import { OCard, OCardBody, OTypography } from "@maestro/react";
import { MasterDetailComponentProps } from "components/data-grid";
import { useMemo } from "react";
import { Profile } from "../../profile-selector.types";
import { AccessDetailsGrid } from "./access-details-grid.component";
import { ActsGrid } from "./acts-grid.component";

type CompanyGroupsMasterDetailProps = MasterDetailComponentProps<Profile>;

export const ProfileMasterDetail = ({
  data: {
    data: {
      acts,
      availableDetails,
      unavailableDetails,
      availablePermissionsDescription,
      unavailablePermissionsDescription,
    },
  },
}: CompanyGroupsMasterDetailProps) => {
  const accessDetails = useMemo(
    () => [
      ...availableDetails.map((detail) => ({ ...detail, isAvailable: true })),
      ...unavailableDetails.map((detail) => ({
        ...detail,
        isAvailable: false,
      })),
    ],
    [availableDetails, unavailableDetails],
  );

  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-4">
        <div>
          <OTypography type="dark" size="lg" className="mb-2">
            Descrição
          </OTypography>
          {availablePermissionsDescription && (
            <OTypography type="dark-60">
              {availablePermissionsDescription}
            </OTypography>
          )}
          {unavailablePermissionsDescription && (
            <OTypography type="dark-60">
              {unavailablePermissionsDescription}
            </OTypography>
          )}
        </div>
        {accessDetails.length > 0 && (
          <div>
            <OTypography type="dark" size="lg" className="mb-2">
              Detalhes do acesso
            </OTypography>
            <AccessDetailsGrid accessDetails={accessDetails} />
          </div>
        )}
        {acts.length > 0 && (
          <div>
            <OTypography type="dark" size="lg" className="mb-2">
              Atos
            </OTypography>
            <ActsGrid acts={acts} />
          </div>
        )}
      </OCardBody>
    </OCard>
  );
};
