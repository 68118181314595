import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { GetOdataFidcBorderosOdataResponse } from "services/quickfin/models";

export const fidcReceivablesTableGrid: ODataGridGeneratorConfig<
  GetOdataFidcBorderosOdataResponse["value"][number]
> = {
  datagrid: {
    noDataText: "Sem dados",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} borderôs)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "Id",
      caption: "Id",
    },
    {
      dataField: "SacadoName",
      caption: "Sacado",
    },
    {
      dataField: "MaturityAmount",
      caption: "Valor do vencimento",
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDate",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
  ],
};
