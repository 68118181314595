import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { companyMonitorGrid } from "../company-monitor.grid";

const grid = companyMonitorGrid("ACCOUNT_PJ");

export const CurrentAccountPjCompanies = () => {
  const gridRef = useGridRef();
  const { routes } = corporateRouter;

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Conta Corrente PJ" />}
      actions={
        <>
          <RouterButton
            dataAction="conta-corrente-pj:botao:convite"
            dataLabel="convite"
            type="secondary"
            href={routes.empresas.product.currentAccountPj.invite.path}
            role={roles.empresas.product.currentAccountPjInvitePage.role}
          >
            Convite
          </RouterButton>

          <RouterButton
            dataAction="conta-corrente-pj:botao:nova-conta"
            dataLabel="nova-conta"
            href={routes.empresas.product.currentAccountPj.newAccount.path}
            role={roles.empresas.product.currentAccountPjNewAccountPage.role}
          >
            Nova conta
          </RouterButton>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
