import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { disbursementGrid } from "./disbursement.grid";

export const DisbursementPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getDisbursementInstructions(id)
          .then(({ data }) => data ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar desembolso");
          }),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Desembolso" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !dataSource.isLoading() && dataSource.reload();
          }}
        />
      }
    >
      <ODataGridGenerator grid={disbursementGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
