import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useRemoveRelationshipButton = (
  companyTaxId: string,
  userTaxId: string,
) => {
  const { callService, loading } = useServiceCall(
    service.onboardingRelationships.removeRelationship,
  );

  const removeRelationship = useCallback(async () => {
    const { success } = await callService({
      companyTaxId,
      companyUserTaxId: userTaxId,
    });

    if (success) OToastManager.success("Empresa removida com sucesso");
    else OToastManager.danger("Erro ao remover a empresa");
  }, [callService, companyTaxId, userTaxId]);

  return {
    loading,
    removeRelationship,
  };
};
