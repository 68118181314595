import type { ODataGridGeneratorConfig } from "components/data-grid";
import { approvedAmountTypeMap } from "../../../components/trade/trade-fields.utils";

// grid export
const customerOfficialNameColumn = "customerOfficialNameColumn";
const customerNameColumn = "customerNameColumn";
const customerTaxIdColumn = "customerTaxIdColumn";
const approvedAmountTypeColumn = "approvedAmountTypeColumn";
const approvedAmountColumn = "approvedAmountColumn";

export const exportOnlyColumnNames = [
  customerOfficialNameColumn,
  customerNameColumn,
  customerTaxIdColumn,
  approvedAmountTypeColumn,
  approvedAmountColumn,
];

export const exportOnlyColumns = [
  {
    visible: false,
    dataField: "customerName",
    dataType: "string",
    name: customerNameColumn,
    caption: "Cliente - Nome fantasia",
  },
  {
    visible: false,
    dataField: "customerOfficialName",
    dataType: "string",
    name: customerOfficialNameColumn,
    caption: "Cliente - Razão social",
  },
  {
    visible: false,
    dataField: "customerTaxId",
    dataType: "string",
    name: customerTaxIdColumn,
    caption: "Cliente - CNPJ",
  },
  {
    visible: false,
    dataField: "approvedAmountType",
    dataType: "string",
    name: approvedAmountTypeColumn,
    caption: "Proposta - Tipo",
    lookup: {
      dataSource: Object.entries(approvedAmountTypeMap).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      displayExpr: "label",
      valueExpr: "value",
    },
  },
  {
    visible: false,
    dataField: "approvedAmount",
    dataType: "string",
    name: approvedAmountColumn,
    caption: "Proposta - Valor",
  },
] satisfies ODataGridGeneratorConfig<HubEnergy.TradeODataResponse>["columns"];
