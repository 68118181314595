import { OTypography } from "@maestro/react";
import { MasterDetailComponentProps } from "components/data-grid";
import { DefaultErrorComponent } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { AccDocument } from "services/acc/models/responses/get-admin-acc-counterparties-details.response";
import { ContentTemplate } from "templates/content-template";
import { AccRulesSignatory } from "./acc-contract-rule-signatory.component";

type AccContractSignatoriesMasterDetailType =
  MasterDetailComponentProps<AccDocument>;
export const AccContractSignatoriesMasterDetail = ({
  data: {
    data: { externalId, status },
  },
}: AccContractSignatoriesMasterDetailType) => {
  const {
    callService: getContractDetails,
    loading,
    hasError,
    value: contractDetails,
  } = useServiceCall(service.paperclipv2.getContractDetails);

  useEffect(() => {
    if (status === "CreationPending" || !externalId) return;

    getContractDetails(externalId);
  }, [externalId, getContractDetails, status]);

  return (
    <ContentTemplate
      loading={loading || (!contractDetails && !hasError)}
      hasError={hasError}
      errorComponent={
        <DefaultErrorComponent
          title="Não foi possível buscar os detalhes do contrato"
          callback={() => getContractDetails(externalId)}
        />
      }
      value={contractDetails}
      render={(_contractDetails) => (
        <div className="d-flex flex-column gap-4">
          {contractDetails?.details && (
            <>
              <OTypography tag="h3" size="lg" type="default">
                Detalhes do contrato
              </OTypography>
              <div
                dangerouslySetInnerHTML={{ __html: contractDetails.details }}
              />
            </>
          )}
          <OTypography tag="h3" size="lg" type="default">
            Signatários
          </OTypography>
          <AccRulesSignatory contract={_contractDetails} />
        </div>
      )}
    />
  );
};
