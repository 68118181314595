import { useServiceCall } from "hooks/service-call";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";

interface OperationsContractsContextProps {
  selectedContract: Contract | undefined;
  setSelectedContract: React.Dispatch<
    React.SetStateAction<Contract | undefined>
  >;
}

export type Contract = NonNullable<
  Awaited<ReturnType<typeof service.hubLoan.getContracts>>["data"]
>[number];

export const OperationsContractsContext = createContext(
  {} as OperationsContractsContextProps,
);

interface OperationsContractsProviderProps {
  children: ReactNode;
}

export const OperationsContractsProvider = ({
  children,
}: OperationsContractsProviderProps) => {
  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  if (!id) throw new Error("No id");

  const [selectedContract, setSelectedContract] = useState<Contract>();

  const contracts = useServiceCall(service.hubLoan.getContracts);

  const value = useMemo(
    () => ({
      contracts,
      selectedContract,
      setSelectedContract,
    }),
    [contracts, selectedContract],
  );

  return (
    <OperationsContractsContext.Provider value={value}>
      {children}
    </OperationsContractsContext.Provider>
  );
};

export const useContracts = () => useContext(OperationsContractsContext);
