import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { service } from "services/service";
import { AddPayerFormFields } from "../../add-payer.types";
import {
  extraDataDefaultValue,
  extraDataValidationSchema,
} from "./extra-data.form";
import { ExtraDataFormFields } from "./extra-data.types";

export const useExtraData = (
  requiredFields: string[],
  setCurrentStep: (value: string) => void,
) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const outerForm = useFormContext<AddPayerFormFields>();
  const { getValues: outerGetValue } = outerForm;

  const form = useForm<ExtraDataFormFields>({
    resolver: yupResolver(extraDataValidationSchema(requiredFields)),
    defaultValues: extraDataDefaultValue,
  });
  const { handleSubmit } = form;

  const goBack = () => {
    setCurrentStep("INITIAL_STEP");
  };

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        birthDate:
          dayjs(values.birthDate).utc().format() ?? dayjs().utc().format(),
        identification: outerGetValue("identification"),
        authClientId: outerGetValue("authClientId"),
        partnerConfigurationId: outerGetValue("partnerConfigurationId"),
      };

      await service.scfDevelopers.importPayer(payload);

      OToastManager.success("Sacado simples criado com sucesso");
      navigate(-1);
    } catch {
      OToastManager.danger("Erro ao cadastrar sacado simples");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    goBack,
    onSubmit,
    loading,
  };
};
