import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { useOfferGuarantorsContext } from "../../../offer-guarantors.context";
import { useOfferGuarantorsList } from "./offer-guarantors-list.hook";
import { OfferGuarantorItemRow } from "./offer-guarantors-list.types";
import { validateOfferGuarantorOnboarding } from "./offer-guarantors-list.utils";
import { PortalStatusesResumes } from "./portal-statuses-resumes/portal-statuses-resumes.component";

export const OfferGuarantorsList = () => {
  const { offerGuarantors } = useOfferGuarantorsContext();

  const { MapperOfferGuarantorItemRowToAssemble } = useOfferGuarantorsList();

  const navigate = useNavigate();

  const renderOfferOfferGuarantorItemRow = useCallback(
    (
      data: BankingHubCards.OfferGuarantor,
      row: OfferGuarantorItemRow,
    ): JSX.Element => {
      const assemble = MapperOfferGuarantorItemRowToAssemble[row];
      const { iconProps, text } = assemble(data);
      return (
        <>
          <OIcon {...iconProps} />
          <OTypography key={text} type="secondary">
            {text}
          </OTypography>
        </>
      );
    },
    [MapperOfferGuarantorItemRowToAssemble],
  );

  return (
    <ORow gap={4} wrap="wrap">
      <>
        {offerGuarantors?.map((data) => (
          <OCol xs={12} lg={4} md={6} sm={12} key={data.id}>
            <OCard key={data.id}>
              <OCardBody className="h-100">
                <div className="d-flex flex-column h-100 gap-3 justify-content-between">
                  <div className="d-flex flex-column gap-4">
                    <div>
                      <OTypography>{cases.title(data.name)}</OTypography>
                      <OTypography type="secondary" size="sm">
                        {masks.cpfCnpj(data.taxId)}
                      </OTypography>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex flex-row gap-2">
                        {renderOfferOfferGuarantorItemRow(
                          data,
                          OfferGuarantorItemRow.MailAndPhone,
                        )}
                      </div>
                      <div className="d-flex flex-row gap-2">
                        {renderOfferOfferGuarantorItemRow(
                          data,
                          OfferGuarantorItemRow.Address,
                        )}
                      </div>
                      <div className="d-flex flex-row gap-2">
                        {renderOfferOfferGuarantorItemRow(
                          data,
                          OfferGuarantorItemRow.Biometry,
                        )}
                      </div>
                      <PortalStatusesResumes data={data.portalStatus} />
                    </div>
                  </div>
                  <OButton
                    outline
                    disabled={!data.canEditGuarantor}
                    onClick={() => {
                      navigate(
                        corporateRouter.routes.cartoes.customer.hiring.offerGuarantors.registerEmbedded.path(
                          { offerGuarantorUuid: data.uuid },
                        ),
                      );
                    }}
                  >
                    {validateOfferGuarantorOnboarding(data)
                      ? "Editar cadastro"
                      : "Completar cadastro"}
                  </OButton>
                </div>
              </OCardBody>
            </OCard>
          </OCol>
        ))}
      </>
    </ORow>
  );
};
