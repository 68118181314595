import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  ORow,
  OTypography,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { useUser } from "contexts/user";
import { cases } from "@maestro/utils";
import { ChartCard, DatadogMonitor } from "./_compose";

export const Home = () => {
  const {
    callService: getMetricsByRoles,
    value: metrics,
    hasError: hasErrorMetrics,
    loading: loadingMetrics,
  } = useServiceCall(service.clerk.getMetricsByRoles);

  const { behavior } = useBehavior();

  const { user } = useUser();

  useEffect(() => {
    getMetricsByRoles();
  }, [getMetricsByRoles]);

  if (behavior.id === Behaviors.Corban)
    return (
      <div className="container mt-4">
        <OCard rounded>
          <OCardHeader divider>
            <OTypography tag="h1" type="dark" size="xl" key={user.name}>
              Olá, {cases.title(user.name)}!
            </OTypography>
          </OCardHeader>
          <OCardBody>
            Você está no portal administrativo do BTG Empresas. Por favor,
            comece a usar a barra de pesquisa para encontrar uma empresa.
          </OCardBody>
        </OCard>
      </div>
    );

  return (
    <div
      style={{ maxWidth: "1800px", boxSizing: "border-box" }}
      className="w-100 mx-auto pt-4 pb-5 px-3"
    >
      <PageTitle title="Primeiro Acesso" />
      <ContentTemplate
        loading={loadingMetrics}
        hasError={hasErrorMetrics}
        errorComponent={
          <DefaultErrorComponent
            title="Não foi possível buscar as informações"
            callback={getMetricsByRoles}
          />
        }
        value={metrics}
        render={(_metrics) => (
          <ORow gap={3}>
            {_metrics?.map((metricData) => (
              <OCol key={metricData.configIdentifier} xs={12} sm={6} xl={4}>
                <ChartCard metric={metricData} />
              </OCol>
            ))}
          </ORow>
        )}
      />
      <DatadogMonitor />
    </div>
  );
};
