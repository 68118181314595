import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { FundSelect } from "../../../components";
import { EditBankslipGrid } from "./edit-bank-slip.grid";
import { useEditBankSlip } from "./edit-bank-slip.hook";

export const EditBankSlip = () => {
  const { dataSource, form, selectedFundWatch } = useEditBankSlip();
  const gridRef = useGridRef();

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle title="Edição de boleto" />
        <FundSelect />
        {selectedFundWatch && (
          <GridTemplate
            pageTitle={<PageTitle title="Visão geral - cliente" />}
            gridRef={gridRef}
            showClearFiltersButton
            showRefreshButton
            showExportButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={EditBankslipGrid}
              dataSource={dataSource}
            />
          </GridTemplate>
        )}
      </div>
    </FormProvider>
  );
};
