import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { logger } from "utils/logger";
import {
  batchRequestReportsDefaultValues,
  validationSchema,
} from "./request-for-reports.form";
import { FormValuesType } from "./request-for-reports.types";
import { calculateStartEndDate } from "./request-for-reports.utils";

export const useBatchRequestForReports = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues: batchRequestReportsDefaultValues,
  });
  const { handleSubmit, watch } = form;

  const watchReportType = watch("reportType");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const isPlanilha = values.reportType === "Planilha";
      const startDate =
        values.periodDate && values.periodDate[0].format("YYYY-MM-DD");
      const endDate =
        values.periodDate && values.periodDate[1].format("YYYY-MM-DD");

      const { periodoDe, periodoAte } = calculateStartEndDate(
        values.reportPeriod,
        startDate,
        endDate,
      );

      const payload = {
        fileName: values.reportName,
        filtroRequisicao: {
          tiposProduto: values.productType,
          operationId: values.operationId ?? [],
          tipoDocumento: values.reportType,
          receivableIdentifications: values.receivableIdentification ?? [],
          agrupamentos: values.groupBy ? [values.groupBy] : [],
          receivableStatuses: values.statusAntecipations ?? [],
          colunasRelatorio: values.columns ?? [],
          periodoDe,
          periodoAte,
          cedenteId: values.cnpjCedentes ?? [],
          sacadoId: values.cnpjSacados ?? [],
          receivableId: values.receivableId ?? [],
          tradeId: values.tradeId ?? [],
          borderoId: values.loteId ?? [],
          dataVencimentoDe:
            isPlanilha && values.maturityDate
              ? values.maturityDate[0].format("YYYY-MM-DD")
              : "",
          dataVencimentoAte:
            isPlanilha && values.maturityDate
              ? values.maturityDate[1].format("YYYY-MM-DD")
              : "",
          dataDesembolsoDe:
            isPlanilha && values.disbursementDate
              ? values.disbursementDate[0].format("YYYY-MM-DD")
              : "",
          dataDesembolsoAte:
            isPlanilha && values.disbursementDate
              ? values.disbursementDate[1].format("YYYY-MM-DD")
              : "",
        },
      };

      await service.quickfin.createDocumentos(payload);

      OToastManager.info(
        "Arquivo em processamento. Você será notificado quando o arquivo estiver disponível para download.",
      );

      navigate(
        corporateRouter.routes.recebiveis.product.batchReports.reportsRequested
          .path,
      );
    } catch (e) {
      logger.error(e);
      OToastManager.danger(
        "Houve um problema ao solicitar seu relatório. Verifique as configurações.",
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    watchReportType,
    submit,
  };
};
