import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/onboarding-form/onboarding-form.form";
import { FormValuesType } from "../_compose/onboarding-form/onboarding-form.types";

export const useOnboardingDomainConfigEdit = (group?: string, key?: string) => {
  const { callService: getConfigDomainByGroupKey } = useServiceCall(
    service.onboarding.getConfigDomainByGroupKey,
  );

  const { callService: updateConfigDomain, loading: submitLoading } =
    useServiceCall(service.onboarding.addDomainConfig);

  const navigate = useNavigate();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const fetch = useCallback(() => {
    if (!group || !key) return;
    getConfigDomainByGroupKey(group, key);
  }, [getConfigDomainByGroupKey, group, key]);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { value } = values;
        const payload = {
          groupKeyValues: [
            {
              group,
              key,
              value,
            },
          ],
        };
        const { success } = await updateConfigDomain(payload);

        if (success) {
          navigate(
            corporateRouter.routes.ferramentas.product.onboarding.domainConfig
              .path,
          );
          OToastManager.success("Domínio alterado com sucesso");
        } else {
          OToastManager.danger("Erro para salvar os dados");
        }
      }),
    [handleSubmit, navigate, group, key, updateConfigDomain],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const load = useCallback(async () => {
    if (!group || !key) return;
    const { response } = await getConfigDomainByGroupKey(group, key);

    if (!response?.data) return;
    const { value } = response.data;

    const resetValues = {
      group,
      key,
      value,
    };

    reset(resetValues);
  }, [group, key, reset, getConfigDomainByGroupKey]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    submitLoading,
  };
};
