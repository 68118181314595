import { OCard, OCardBody, OCardHeader } from "@maestro/react";
import { format } from "utils/date";

interface StatusCardProps {
  type: React.ComponentPropsWithRef<typeof OCard>["type"];
  title: string;
  updatedAt?: string;
  futureUpdate?: boolean;
}

export const StatusCard = ({
  type,
  title,
  updatedAt,
  futureUpdate,
}: StatusCardProps) => {
  return (
    <OCard style={{ width: "min-content" }} type={type}>
      <OCardHeader>{title}</OCardHeader>
      <OCardBody>
        {updatedAt
          ? format.dateTime(updatedAt)
          : futureUpdate
          ? "-"
          : "Data de atualização indisponível"}
      </OCardBody>
    </OCard>
  );
};
