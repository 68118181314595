import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { InputMultiple } from "../../../../recebiveis/components/input-multiple/input-multiple.component";
import { ExchangeNatureGenericFieldsForm } from "../nature-generic-fields-form.component";

export const ExchangeDocumentConfigsForm = () => {
  const { callService: getNatureGenericFieldTypes, value: genericFields } =
    useServiceCall(service.acc.nature.getNatureGenericFieldTypes);

  useEffect(() => {
    getNatureGenericFieldTypes();
  }, [getNatureGenericFieldTypes]);

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="typeName"
                name="typeName"
                label="Nome"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="typeDescriptionDisplay"
                name="typeDescriptionDisplay"
                label="Descrição visível"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="typeDescription"
                name="typeDescription"
                label="Descrição"
              />
            </OCol>
          </ORow>

          <ORow>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup id="isFileAllowed" name="isFileAllowed">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="isFileAllowed-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="isFileAllowed-checkbox">
                    Permitido arquivo?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      {genericFields && (
        <>
          <OCard>
            <OCardBody>
              <ExchangeNatureGenericFieldsForm genericFields={genericFields} />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardBody>
              <ExchangeNatureGenericFieldsForm
                genericFields={genericFields}
                name="fileGenericFields"
              />
            </OCardBody>
          </OCard>
        </>
      )}
      <OCard>
        <OCardBody>
          <OCol sm={12} md={6}>
            <InputMultiple
              id="fxPendencyCodes"
              name="fxPendencyCodes"
              label="Códigos de pendencia"
            />
          </OCol>
        </OCardBody>
      </OCard>
    </div>
  );
};
