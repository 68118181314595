import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { documentSubtypeByDocumentMap } from "../../add-payer.utils";
import { ExtraDataFormFields } from "../extra-data/extra-data.types";

export const useDocumentsManager = (
  requiredDocuments: ScfDevelopers.PayerDocumentType[],
) => {
  const form = useFormContext<ExtraDataFormFields>();
  const { setValue } = form;

  const updateRequiredDocuments = useCallback(() => {
    const documentsList = requiredDocuments.map((document) => {
      const subTypes = getValueFromMap(documentSubtypeByDocumentMap, document);
      return {
        type: document,
        subType: subTypes ? subTypes[0] : undefined,
        files: [] as ScfDevelopers.PayerFile[],
      };
    });

    if (requiredDocuments.length === 0) {
      return;
    }

    setValue("documents", documentsList);
  }, [requiredDocuments, setValue]);

  useEffect(() => {
    updateRequiredDocuments();
  }, [updateRequiredDocuments]);
};
