import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const GuarantorsMonitoringGrid = (
  filterValue: (string | string[])[] | null,
): ODataGridGeneratorConfig<HubOnboardingRelationships.Odata.GuarantorMonitoring> => ({
  datagrid: {
    noDataText: "Nenhum avalista encontrado.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.onboardingRelationships.odata.companyPowers.guarantor,
      {
        dataSourceOptions: {
          filter: filterValue,
        },
      },
    ),
  },
  columns: [
    {
      caption: "Id",
      dataField: "Id",
    },
    {
      caption: "Uuid",
      dataField: "Uuid",
    },
    {
      caption: "Status",
      dataField: "Status",
    },
    {
      caption: "Tipo",
      dataField: "Type",
    },
    {
      caption: "CNPJ",
      dataField: "CompanyIdentification",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Nome",
      dataField: "CompanyName",
    },
    {
      caption: "TaxId do avalista",
      dataField: "UserIdentification",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Nome do avalista",
      dataField: "UserName",
    },
    {
      caption: "Situação matrimonial",
      dataField: "MaritalStatusType",
    },
    {
      caption: "Regime do casamento",
      dataField: "WeddingRegimeType",
    },
  ],
});
