import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { ParamsProps } from "./company-traits.types";

interface CompanyTraitsContextProps {
  params: ParamsProps;
  setParams: (params: ParamsProps) => void;
}

const CompanyTraitsContext = createContext<
  CompanyTraitsContextProps | undefined
>(undefined);

export const useCompanyTraitsContext = () => {
  const context = useContext(CompanyTraitsContext);
  if (!context)
    throw new Error(
      "useRejectedOptinContext must be used within an RejectedOptinContextProvider",
    );
  return context;
};

export const CompanyTraitsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [params, setParams] = useState<ParamsProps>({
    id: "",
    taxId: "",
    name: "",
    trait: "",
  });

  const contextValue = useMemo(
    () => ({
      params,
      setParams,
    }),
    [params],
  );

  return (
    <CompanyTraitsContext.Provider value={contextValue}>
      {children}
    </CompanyTraitsContext.Provider>
  );
};
