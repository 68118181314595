import * as yup from "yup";
import { CompanySearchForm } from "./search-modal.types";

export const searchModalDefaulValues = {
  bandeiras: [],
  cedentes: [],
  dataVencimentoFinal: null,
  dataVencimentoInicial: null,
  sacados: [],
  valorDesembolsoDesejado: 0,
} satisfies CompanySearchForm;

export const searchModalValidationSchema = yup.object({
  valorDesembolsoDesejado: yup
    .number()
    .typeError("O valor de desembolso deve ser um número")
    .required("É necessário preencher o valor de desembolso")
    .moreThan(0, "O valor de desembolso deve ser maior que 0"),
  cedentes: yup
    .array()
    .required("É necessário selecionar ao menos um cedente")
    .min(1, "É necessário selecionar ao menos um cedente"),
});
