import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { globalDeflatorValidationSchema } from "./global-deflator.form";
import { GlobalDeflatorForm } from "./global-deflator.types";

export const useGlobalDeflator = () => {
  const {
    callService: setDefaultDeflator,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.setDefaultDeflator);

  const {
    callService: getDefaultDeflator,
    hasError: defaultDeflatorHasError,
    loading: defaultDeflatorLoading,
    value: defaultDeflator,
  } = useServiceCall(service.recebiveisCartao.getDefaultDeflator);

  const form = useForm<GlobalDeflatorForm>({
    resolver: yupResolver(globalDeflatorValidationSchema),
  });

  const { handleSubmit, setValue } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        setDefaultDeflator({
          deflation_value: values.deflator.toString(),
        });
      }),
    [setDefaultDeflator, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    getDefaultDeflator();
  }, [getDefaultDeflator]);

  /** Initial value */
  useEffect(() => {
    if (defaultDeflator) {
      setValue("deflator", parseFloat(defaultDeflator.deflation_value));
    }
  }, [defaultDeflator, setValue]);

  /** Error warning */
  useEffect(() => {
    if (defaultDeflatorHasError)
      OToastManager.danger("Erro ao buscar o deflator global");
  }, [defaultDeflatorHasError]);

  /** Error warning */
  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao modificar o deflator global");
  }, [hasError]);

  /** Success */
  useEffect(() => {
    if (value) OToastManager.success("Deflator global modificado com sucesso");
  }, [value]);

  return {
    defaultDeflatorLoading,
    form,
    loading,
    submit,
  };
};
