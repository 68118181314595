import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { DataGrid } from "devextreme-react";
import { useRef } from "react";
import { service } from "services";
import { AllowListNoBiometryDataItem } from "services/gateholder/models/responses";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./biometrics-release.grid";

export const SecurityBiometricsRelease = () => {
  const gridRef = useRef<DataGrid>(null);

  const dataSource =
    dataSourceCustomStoreGenerator<AllowListNoBiometryDataItem>(
      (loadOptions) =>
        service.gateholder
          .getNoBiometryAllowList(loadOptions as any)
          .then(({ data }) => ({
            data: data.data,
            totalCount: data.total_count,
          }))
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar CPFs";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      {
        customStoreOptions: {
          insert: async (data) => {
            try {
              const payload = {
                allow_list: {
                  tax_id: data.tax_id,
                  justification: data.justification,
                  type: "NO_BIOMETRY",
                },
              };

              await service.gateholder.createAllowList(payload);
              OToastManager.success("CPF liberado com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível liberar o CPF");
            }
            return data;
          },
          remove: async (data) => {
            try {
              await service.gateholder.deleteNoBiometryByTaxid(data.tax_id);
              OToastManager.success("CPF removido com sucesso");
            } catch (err) {
              if (!isAxiosError(err)) logger.error(err);
              OToastManager.danger("Não foi possível remover o CPF");
            }
          },
        },
      },
    );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Pessoas liberadas da biometria"
          description="Listagem dos CPFs liberados da biometria."
        />
      }
      actions={
        <IconButton
          dataAction="seguranca_liberacao_biometrica:botao:adicionar"
          dataLabel="adicionar"
          icon={{ category: "fal", icon: "fa-plus" }}
          onClick={() => gridRef.current?.instance.addRow()}
        >
          Adicionar
        </IconButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
