import { ODataGridGeneratorConfig } from "components/data-grid";
import { format } from "utils/date";

export const grid: ODataGridGeneratorConfig<HubOnboarding.CustomerService.Timeline> =
  {
    datagrid: {},
    columns: [
      {
        dataField: "createdDate",
        caption: "Data de criação",
        format: (value) => format.dateTime(value, { timeStyle: "medium" }),
      },
      { dataField: "type", caption: "Tipo de ação" },
    ],
  };
