export enum Behaviors {
  "Admin" = "Admin",
  "CustomerService" = "CustomerService",
  "RTB" = "RTB",
  "Corban" = "Corban"
}

export function isBehavior(
  id: Behaviors | string | null | undefined,
): id is Behaviors {
  return Object.keys(Behaviors).includes(String(id));
}
