import {
  modalManager,
  OButton,
  OCard,
  ODivider,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import React, { useState } from "react";
import {
  COLLATERAL_DETAILS_MODAL,
  CollateralDetailsModal,
} from "../collateral-details-modal/collateral-details.component";
import { useSimulation } from "../simulation/simulation.context";
import { collateralTypeMap } from "../simulation/simulation.utils";

const collateralsWithDetails = ["IMOVEL", "SACAS_GRAO", "PAINEIS_SOLARES"];

export const CollateralsCard = () => {
  const { loading, selectedSimulationResult } = useSimulation();
  const hasCollaterals = !!selectedSimulationResult?.collaterals.length;

  const [selectedCollateralType, setSelectedCollateralType] =
    useState<string>();

  return (
    <>
      {hasCollaterals && (
        <>
          <CollateralDetailsModal
            collaterals={selectedSimulationResult.collaterals}
            selectedCollateralType={selectedCollateralType}
          />
          <OCard className="bg-light mb-4 position-relative">
            {loading && <OLoader backdrop absolute />}

            <div className="card-header bg-light p-4">
              <OTypography tag="h2" type="primary" className="m-0" size="xl">
                Garantias utilizadas
              </OTypography>
            </div>
            <ODivider size="xxs" />
            <div className="my-2">
              {selectedSimulationResult?.collaterals.map(
                (collateral, i: number) => {
                  return (
                    <React.Fragment key={`collateral-row-${i + 1}`}>
                      <div className="px-4 py-2 d-flex justify-content-between">
                        <OTypography className="mt-2">
                          {collateralTypeMap[collateral.collateralType]
                            ?.label || collateral.collateralType}
                        </OTypography>
                        {collateralsWithDetails.includes(
                          collateral.collateralType,
                        ) && (
                          <OButton
                            className="d-flex align-items-center cursor-pointer"
                            onClick={() => {
                              setSelectedCollateralType(
                                collateral.collateralType,
                              );
                              modalManager.show(COLLATERAL_DETAILS_MODAL);
                            }}
                            outline
                            bordered={false}
                            type="dark"
                          >
                            <div className="d-flex gap-2">
                              <OTypography className="mb-0">
                                Visualizar detalhes
                              </OTypography>
                              <OIcon category="fal" icon="fa-chevron-right" />
                            </div>
                          </OButton>
                        )}
                      </div>
                      {i + 1 !==
                        selectedSimulationResult?.collaterals.length && (
                        <ODivider type="light" />
                      )}
                    </React.Fragment>
                  );
                },
              )}
            </div>
          </OCard>
        </>
      )}
    </>
  );
};
