import { OBadge, OClickableBox, OTypography } from "@maestro/react";
import { Handle, Position, NodeProps } from "reactflow";
import { NodeLabel } from "./eligibility-group-node.styles";

export type EligibilityGroupNodeData = {
  label: string;
  variantId: string;
  handleCreateChild: (groupId: string) => void;
  handleEditGroup: (groupId: string) => void;
};

export const EligibilityGroupNode = ({
  data,
  id,
}: NodeProps<EligibilityGroupNodeData>) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        className="invisible"
        id={`target-${id}`}
      />
      <div>
        <OClickableBox
          dataAction={`editar_arvores:botao:grupo_${id}`}
          dataLabel={`grupo_${id}`}
          id={`grupo_${id}`}
          name={`grupo_${id}`}
          value={data.label}
          size="xxxl"
          onClick={(e) => {
            e.preventDefault();
            data.handleEditGroup(id);
          }}
          checked={false}
        >
          <div className="position-absolute top-50 start-50 translate-middle">
            <NodeLabel key={`label-${data.label}`} weight="bold" size="sm">
              {data.label}
            </NodeLabel>
          </div>
        </OClickableBox>
        <OBadge
          size="xs"
          type="info"
          className="position-absolute top-100 start-50 translate-middle"
          onClick={() => data.handleCreateChild(id)}
        >
          <OTypography size="xs">Adicionar</OTypography>
        </OBadge>
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id={`source-${id}`}
        className="invisible"
      />
    </>
  );
};
