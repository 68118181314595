import { OToastManager } from "@maestro/core";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { GetCompanyProductsResponse } from "services/hubloan/models";
import { productMap } from "./offers.utils";

export const useOffers = () => {
  const [loading, setLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<
    GetCompanyProductsResponse[]
  >([]);

  const { hasRole } = useRoles();

  const getAvailableProducts = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.hubLoan.getAvailableProducts();

      const filteredProducts = data.filter(
        (product) =>
          product.identification in productMap &&
          hasRole(productMap[product.identification]?.roles),
      );

      setAvailableProducts(filteredProducts);
    } catch (e) {
      const errorMessage = "Erro ao buscar os produtos";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [hasRole]);

  useEffect(() => {
    getAvailableProducts();
  }, [getAvailableProducts]);

  return {
    availableProducts,
    loading,
  };
};
