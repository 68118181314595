import { OBadge, OTypography } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { getValueFromMap } from "utils/get-value-from-map";
import { AssignmentDetail } from "./assignment-details.types";
import {
  operationStatusLibrary,
  operationTintMap,
} from "../../trades/operations.utils";

export const buildAssignmentDetails = (
  assignment: AssignmentDetail,
): DetailsFields => {
  return {
    assignment: {
      items: [
        {
          value: (
            <OBadge
              size="lg"
              type={
                getValueFromMap(operationTintMap, assignment.status) ||
                "warning-light"
              }
            >
              {getValueFromMap(operationStatusLibrary, assignment.status) ||
                assignment.status}
            </OBadge>
          ),
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          value: `cód ${assignment.id} | ${dayjs(assignment.createdAt).format(
            "DD/MM/YYYY",
          )}`,
          grid: { sm: 6, md: 4, lg: 3 },
        },
      ],
    },
    rates: {
      items: [
        {
          label: "External Id",
          value: assignment.externalId,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Assignment Id",
          value: assignment.assignmentId,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Nº contrato",
          value: assignment.contractNumber,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Conta desembolso",
          value: assignment.bankAccount && (
            <div className="ps-2">
              <OTypography>
                {assignment.bankAccount.accountNumber}-
                {assignment.bankAccount.accountDigit}
              </OTypography>
              <OTypography size="sm">
                Agência {assignment.bankAccount.agency} Banco{" "}
                {assignment.bankAccount.bankCode}
              </OTypography>
            </div>
          ),
          grid: { sm: 6, md: 4, lg: 3 },
        },
      ],
    },
  };
};
