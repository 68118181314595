import {
  OConfirmationModal,
  OTypography,
  modalManager,
  OToastManager,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";

interface CalendarDeleteModalProps {
  calendarId?: number;
  name?: string;
  onRemoved: () => void;
}

export const CalendarDeleteModal: React.FC<CalendarDeleteModalProps> = ({
  calendarId,
  name,
  onRemoved,
}) => {
  const { loading: loadingRemove, callService: removeCalendar } =
    useServiceCall(service.legacys.deleteCustomCalendar);

  const handleRemove = async () => {
    if (!calendarId) return;
    const { success } = await removeCalendar(calendarId);

    if (!success)
      return OToastManager.danger(
        "Não foi possível remover o calendário no momento, tente novamente mais tarde!",
      );

    onRemoved();
    OToastManager.success("Calendário removido com sucesso!");
    modalManager.hide("confirm-remove-modal");
  };

  return (
    <OConfirmationModal
      modalId="confirm-remove-modal"
      title={`Deseja excluir o calendário ${name}?`}
      closeLabel="Cancelar"
      confirmLabel="Excluir"
      divider={false}
      size="sm"
      loading={loadingRemove}
      onConfirm={handleRemove}
    >
      <OTypography className="d-flex jusitfy-content-start">
        Esta ação não poderá ser desfeita se confirmado.
      </OTypography>
    </OConfirmationModal>
  );
};
