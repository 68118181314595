import { ODataGridGeneratorConfig } from "components/data-grid";
import { WorkflowContext } from "./workflow-contexts.types";

export const workflowContextHistoryGrid = {
  datagrid: {
    noDataText: "Nenhum histórico encontrado",
  },
  columns: [
    {
      dataField: "status",
      caption: "Status",
    },
    {
      dataField: "startedStatusOn",
      caption: "Iniciado em",
      dataType: "datetime",
      sortOrder: "desc",
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowContext["history"][number]>;
