import { GetProcessDashboardResponseItem } from "services/recebiveis-cartao/models/responses/get-process-dashboard-response";
import useSWR from "swr";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { ProcessStatusCard } from "./process-status-card.component";

export const ProcessStatus = () => {
  const { callService: getProcessDashboard } = useServiceCall(
    service.recebiveisCartao.getProcessDashboard,
  );

  const { data, error, isLoading } = useSWR(
    "/antecipacao-de-cartoes/produto/dashboard-rotina-matinal",
    getProcessDashboard,
  );

  return (
    <div className="row g-2">
      {data?.response?.data?.map(
        (item: GetProcessDashboardResponseItem, index: number) => (
          <div key={item.id} className="col-sm-12 col-xl-6 col-xxl-4">
            <ProcessStatusCard
              item={item}
              index={index}
              loading={isLoading}
              hasError={error}
            />
          </div>
        ),
      )}
    </div>
  );
};
