import { useServiceCall } from "hooks/service-call";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { useCardsCustomerContext } from "../../../../contexts";
import {
  CardHiringContext,
  CardHiringContextDefaultData,
} from "./card-hiring.types";
import {
  assembleCardOwnersByGetCardsCompaniesUsersResponse,
  assembleCardOwnersByGetCardsDebitCardOwnersResponse,
} from "./card-hiring.utils";

const cardHiringContext = createContext<CardHiringContext>(
  CardHiringContextDefaultData,
);

export const CardHiringProvider = ({ children }: PropsWithChildren) => {
  const [canHire, setCanHire] = useState<boolean>(false);

  const {
    account,
    loadingAccount,
    hasAccountRegistered,
    fetchCardsAccount,
    offer,
    loadingOffer,
    hasOfferActive,
    fetchCardsOffer,
  } = useCardsCustomerContext();

  const {
    value: getCardsCompaniesUsersResponse,
    callService: getCardsCompaniesUsers,
    error: creditCardOwnersError,
  } = useServiceCall(service.bankinghub.getCardsCompaniesUsers);

  const {
    value: getCardsDebitCardOwnersResponse,
    callService: getCardsDebitCardOwners,
    error: debitCardOwnersError,
  } = useServiceCall(service.bankinghub.getCardsDebitCardOwners);

  useEffect(() => {
    if (!getCardsCompaniesUsersResponse) getCardsCompaniesUsers();
  }, [getCardsCompaniesUsersResponse, getCardsCompaniesUsers]);

  useEffect(() => {
    if (!getCardsDebitCardOwnersResponse) getCardsDebitCardOwners();
  }, [getCardsDebitCardOwnersResponse, getCardsDebitCardOwners]);

  const {
    value: getCardsAccountProposalsResponse,
    callService: getCardsAccountProposals,
    error: accountProposalsError,
  } = useServiceCall(service.bankinghub.getCardsAccountProposals);

  const accountProposals = useMemo(
    () => getCardsAccountProposalsResponse?.proposals,
    [getCardsAccountProposalsResponse],
  );

  const [creditCardOwners, debitCardOwners] = useMemo(() => {
    return [
      assembleCardOwnersByGetCardsCompaniesUsersResponse(
        getCardsCompaniesUsersResponse,
      ),
      assembleCardOwnersByGetCardsDebitCardOwnersResponse(
        getCardsDebitCardOwnersResponse,
      ),
    ];
  }, [getCardsCompaniesUsersResponse, getCardsDebitCardOwnersResponse]);

  const handleAccountProposals = useCallback(() => {
    if (hasAccountRegistered === true && !!account) {
      const { id } = account;

      if (!accountProposals) getCardsAccountProposals(id);
    }
  }, [
    hasAccountRegistered,
    account,
    accountProposals,
    getCardsAccountProposals,
  ]);

  useEffect(() => handleAccountProposals(), [handleAccountProposals]);

  const fetch = useCallback(() => {
    if (!hasAccountRegistered && !account) fetchCardsAccount();
    else handleAccountProposals();

    if (!hasOfferActive && !offer) fetchCardsOffer();
  }, [
    fetchCardsAccount,
    fetchCardsOffer,
    handleAccountProposals,
    hasAccountRegistered,
    account,
    hasOfferActive,
    offer,
  ]);

  const fetchHasError = useMemo(
    () =>
      (!hasAccountRegistered && !hasOfferActive) ||
      !!creditCardOwnersError ||
      !!debitCardOwnersError ||
      (hasAccountRegistered === true && !!accountProposalsError),
    [
      hasAccountRegistered,
      hasOfferActive,
      creditCardOwnersError,
      accountProposalsError,
      debitCardOwnersError,
    ],
  );

  const fetchLoading = useMemo(
    () => loadingAccount || loadingOffer,
    [loadingAccount, loadingOffer],
  );

  const value = useMemo(
    () => ({
      accountProposals,
      creditCardOwners,
      debitCardOwners,
      canHire,
      setCanHire,
      fetch,
      fetchHasError,
      fetchLoading,
    }),
    [
      accountProposals,
      creditCardOwners,
      debitCardOwners,
      canHire,
      setCanHire,
      fetch,
      fetchHasError,
      fetchLoading,
    ],
  );

  return (
    <cardHiringContext.Provider value={value}>
      {children}
    </cardHiringContext.Provider>
  );
};

export const useCardHiringContext = () => useContext(cardHiringContext);
