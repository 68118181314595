// eslint-disable-next-line no-restricted-imports
import { isArray } from "lodash";

enum StatusDetails {
  INADIMPLENTE = "Inadimplente",
  NAO_IDENTIFICADO = "Não identificado",
  ORDINARIA = "Ordinária",
  RECOMPRA = "Recompra",
  COOBRIGACAO = "Coobrigação",
  DIREITO_DE_ARREPENDIMENTO = "Direito de arrependimento",
  PRE_PAGAMENTO = "Pre-Pagamento",
  DEVOLUCAO_DE_IOF = "Devolução de IOF",
  PORTABILIDADE = "Portabilidade",
}

export const formatStatus = (status: string) => {
  return StatusDetails[status as keyof typeof StatusDetails] || status;
};

export const creditConciliationStatusFileBadgeMap = {
  CANCELED: {
    label: "Cancelado",
    type: "danger",
  },
  WAITING_RELEASE: {
    label: "Aguardando Aprovação",
    type: "warning",
  },
  RECEIVED: {
    label: "Recebido",
    type: "success",
  },
  PROCESSING: {
    label: "Processando",
    type: "info",
  },
} as const;

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("");

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");
