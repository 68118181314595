import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

export const proposalHistoryGrid = {
  datagrid: {
    noDataText: "Nenhum status encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "initialDate",
      caption: "Data da ocorrência",
      sortOrder: "desc",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      caption: "Status",
      dataField: "friendlyStatusName",
    },
    {
      caption: "Step",
      dataField: "friendlyStepName",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.b2bAgro.getProposalDetails>
  >["data"]["data"]["statusHistory"][number]
>;
