import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const getDocumentFiles = (
  workflowDocuments: Awaited<
    ReturnType<typeof service.hubCreditManager.getWorkflowLeadDocuments>
  >["data"],
) =>
  workflowDocuments?.flatMap((document) =>
    document.files.map((file) => ({
      ...file,
      group: document.type,
      documentId: document.id,
    })),
  );

export const workflowDocumentsGrid = (leadId: string) =>
  ({
    datagrid: {
      noDataText: "Nenhum documento encontrado",
    },
    columns: [
      {
        dataField: "group",
        caption: "Tipo de documento",
      },
      {
        dataField: "name",
        caption: "Arquivo",
      },
      {
        dataField: "isActive",
        caption: "Situação",
        cellRender: ({ data }) => (data.isActive ? "Ativo" : "Inativo"),
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  label: "Baixar",
                  icon: { category: "orq", icon: "orq-download" },
                  onClick: async () => {
                    try {
                      const { data: blob } =
                        await service.hubCreditManager.download({
                          key: data.key,
                        });

                      helpers.downloadBlobFile(data.name, blob);

                      OToastManager.success("Download realizado com sucesso!");
                    } catch {
                      OToastManager.danger(
                        "Houve um erro ao baixar o arquivo.",
                      );
                    }
                  },
                },
                {
                  label: "Pendenciar",
                  icon: { category: "orq", icon: "orq-tag" },
                  role: roles.workflow.product.leadsDetailsDocumentsPendencyPage
                    .role,
                  route:
                    corporateRouter.routes.workflow.product.leads.details.documents.pendency.path(
                      {
                        id: leadId,
                        documentId: data.documentId,
                      },
                    ),
                },
              ]}
            />
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<ReturnType<typeof getDocumentFiles>>[number]
  >);
