import {
  OIcon,
  OLink,
  OLoader,
  ORFieldUploadInput,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FormProvider } from "react-hook-form";
import { UploadContainer } from "../../../../../components";
import { useFileUpload } from "./file-upload.hook";

export const FileUpload = () => {
  const { form, handleAddFile, hasError, loading, reset, setHasParseError } =
    useFileUpload();

  if (hasError)
    return (
      <div>
        <ErrorComponent
          messageTitle="Erro ao processar a planilha"
          messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
        >
          <TryAgainButton
            onClick={() => {
              setHasParseError(false);
              reset();
            }}
          />
        </ErrorComponent>
      </div>
    );

  return (
    <div>
      <FormProvider {...form}>
        <UploadContainer>
          <ORFieldUploadInput
            id="quote"
            name="quote"
            inputLabel="Clique ou arraste o arquivo aqui"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            dataAction="entuba_operacoes:upload:entuba_operacoes"
            dataLabel="entuba_operacoes"
            multiple={false}
            handleAddFile={handleAddFile}
            disabled={loading}
            value={[]}
          />
        </UploadContainer>

        <div className="d-flex flex-column align-items-center gap-3 mt-4">
          <OLink href="/files/modelo_importacao_entuba_operacoes.xlsx">
            <OIcon category="fas" icon="fa-external-link" className="me-2" />
            Baixar modelo
          </OLink>
          <OTypography type="default">
            Por favor, preencha as colunas em verde. Caso deseje realizar
            antecipação parcial, preencha a coluna em amarelo. As colunas em
            vermelho não serão usadas.
          </OTypography>
          {loading && <OLoader />}
        </div>
      </FormProvider>
    </div>
  );
};
