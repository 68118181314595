import { modalManager, ODivider, OIcon, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { useReactFlow } from "reactflow";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { ADD_CANVAS_ITEM_MODAL_ID } from "../../config-proposal-workflow.utils";
import { AddProcessorConfigModalId } from "../add-processor-config-modal/add-processor-config-modal.hook";
import { IMPORT_CANVAS_MODAL_ID } from "../import-canvas-modal";
import "./canvas-context-menu.css";
import { useCanvasContextMenu } from "./canvas-context-menu.hook";

export const CanvasContextMenu = () => {
  const { viewPoint, showContextMenu, getCanvasData, selectedNodes } =
    useCanvas();
  const { getZoom } = useReactFlow();

  const { exportCanvas } = useCanvasContextMenu();

  const getContextMenuStyle = useMemo(() => {
    const zoom = getZoom();
    const top = viewPoint.anchor.y * zoom;
    const left = viewPoint.anchor.x * zoom;

    return {
      top,
      left,
    };
  }, [viewPoint, getZoom]);

  if (!showContextMenu) return null;

  return (
    <div className="context-menu" style={getContextMenuStyle}>
      <button
        className="context-menu-item"
        onClick={getCanvasData}
        type="button"
      >
        <OIcon category="fa" icon="fa-sync" />
        <OTypography>Recarregar</OTypography>
      </button>

      <ODivider size="xxs" />

      <button
        className="context-menu-item"
        onClick={() => modalManager.show(AddProcessorConfigModalId)}
        type="button"
      >
        <OIcon category="fa" icon="fa-layer-plus" />
        <OTypography>Adicionar processador</OTypography>
      </button>

      <ODivider size="xxs" />

      <button
        className="context-menu-item"
        onClick={() => modalManager.show(ADD_CANVAS_ITEM_MODAL_ID)}
        type="button"
      >
        <OIcon category="fa" icon="fa-plus" />
        <OTypography>Adicionar grupo</OTypography>
      </button>

      <ODivider size="xxs" />

      {!!selectedNodes.length && (
        <>
          <button
            className="context-menu-item"
            onClick={exportCanvas}
            type="button"
          >
            <OIcon category="fa" icon="fa-download" />
            <OTypography>Exportar processadores selecionados</OTypography>
          </button>

          <ODivider size="xxs" />
        </>
      )}

      <button
        className="context-menu-item"
        onClick={() => modalManager.show(IMPORT_CANVAS_MODAL_ID)}
        type="button"
      >
        <OIcon category="fa" icon="fa-upload" />
        <OTypography>Importar processadores</OTypography>
      </button>
    </div>
  );
};
