import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";

export interface NatureGenericFieldsForm {
  typeId: number;
  isRequired: boolean;
}

export const exchangeNatureGenericFieldsFormGrid = (
  typeIdOptions: { label: string; value: number }[],
  initialValue: NatureGenericFieldsForm[],
  onChange?: (fields: NatureGenericFieldsForm[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum campo adicionado.",
      dataSource: initialValue ?? [],
      editing: {
        allowUpdating: true,
        allowDeleting: true,
      },
      onRowInserted: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowUpdated: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowRemoved: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowValidating: (e) => {
        const { typeId } = {
          ...e.oldData,
          ...e.newData,
        };
        const sameTypeId = e.component
          .getVisibleRows()
          .flatMap((row) => (row.isEditing ? [] : [row.data]))
          .some((item) => item.typeId === typeId);
        if (sameTypeId) {
          e.errorText = "Campo já adicionado";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "typeId",
        caption: "Tipo",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: typeIdOptions,
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "isRequired",
        caption: "Obrigatório?",
        dataType: "boolean",
        validationRules: [
          {
            type: "custom",
            validationCallback: ({ value }) => typeof value === "boolean",
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<NatureGenericFieldsForm>);
