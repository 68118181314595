import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { migrationStatusMap } from "../manage-desk-migrations.utils";
import { MigrationMovement } from "./desk-migration-details.types";

export const migrationMovementsGrid: ODataGridGeneratorConfig<MigrationMovement> =
  {
    datagrid: {
      noDataText: "Nenhuma movimentação",
      filterRow: { visible: true },
      pager: {
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        dataField: "userName",
        dataType: "string",
        caption: "Nome",
      },
      {
        dataField: "userTaxId",
        dataType: "string",
        caption: "CPF",
        format: (value) => masks.cpf(value),
      },
      {
        dataField: "userEmail",
        dataType: "string",
        caption: "E-mail",
      },
      {
        dataField: "createdDate",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Data criação",
      },
      {
        dataField: "requestStatus",
        dataType: "string",
        caption: "Status",
      },
      {
        dataField: "requestStatus",
        dataType: "string",
        caption: "Status",
        cellRender: ({ data: { requestStatus } }) => {
          const status = getValueFromMap(migrationStatusMap, requestStatus);

          return status ? (
            <StatusBadge type={status.type}>{status.label}</StatusBadge>
          ) : (
            requestStatus
          );
        },
      },
    ],
  };
