import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface DistributorBasicDataForm {
  name: string;
  officialName: string;
  taxId: string;
}

export const distributorBasicDataFormFields = {
  taxId: {
    id: "taxId",
    name: "taxId",
    tag: "cpfcnpj",
    label: "CPNJ",
    placeholder: "Preencher",
    required: true,
  },
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  officialName: {
    id: "officialName",
    name: "officialName",
    tag: "text",
    label: "Razão social",
    placeholder: "Preencher",
    required: true,
  },
} as const satisfies MaestroFields<DistributorBasicDataForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const distributorBasicDataFormValidationSchema = yup.object<
  DistributorBasicDataForm,
  YupObjectSchema<DistributorBasicDataForm>
>({
  name: stringRequired,
  officialName: stringRequired,
  taxId: stringRequired,
});

export const distributorBasicDataFormDefaultValues = {
  name: null,
  officialName: null,
  taxId: null,
} satisfies Partial<
  Nullable<DistributorBasicDataForm>
> as unknown as DistributorBasicDataForm;
