import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { SelectConditionModal } from "../../../../../../../../components/conditions/select-conditions-component/select-condition-modal/select-condition-modal.component";
import { SelectConditionsComponent } from "../../../../../../../../components/conditions/select-conditions-component/select-conditions.component";
import { TestConditionModal } from "../../../../../../../../components/conditions/test-condition-modal/test-condition-modal.component";
import { useWorkflow } from "../../../../../../../../components/hooks/use-workflow.context";

export const UpdateColumnModal = () => {
  const { setConditionDestination } = useWorkflow();
  setConditionDestination("BATCH");

  return (
    <OModal
      id="update-column-modal"
      position="center"
      size="md"
      className="position-relative"
      onModalClose={async () => {
        modalManager.hide("update-column-modal");
      }}
    >
      <OModalHeader closeButton>
        <div className="d-flex align-items-center gap-3 p-0">
          <OTypography tag="h2" weight="normal" size="xl">
            Condições da Coluna
          </OTypography>
        </div>
      </OModalHeader>
      <OModalBody className="d-flex flex-column">
        <SelectConditionsComponent />
        <SelectConditionModal />
        <TestConditionModal />
      </OModalBody>
      <OModalFooter>
        <OButton
          dataAction="editar_processor_config:botao:fechar"
          dataLabel="fechar"
          type="dark"
          outline
          onClick={async () => {
            modalManager.hide("update-column-modal");
          }}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
