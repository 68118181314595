import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { ORFieldInput } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  addTemplateFormDefaultValues,
  addTemplateFormValidationSchema,
} from "./add-batch-template-form.schema";

export const AddTemplatePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(addTemplateFormValidationSchema),
    defaultValues: addTemplateFormDefaultValues,
  });

  const { handleSubmit } = form;

  const createTemplate = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.createBatchFileTemplate(values);

          OToastManager.success("Template criado com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.batchs.batchTemplates.path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar template.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Template" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_template:botao:salvar"
          dataLabel="salvar"
          type="info"
          onClick={createTemplate}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <div className="d-flex alig-items-start justify-content-between gap-5">
          <FormProvider {...form}>
            <form className="w-100">
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Título"
                labelSize="md"
                dataAction="adicionar_template:texto:nome"
                dataLabel="nome"
              />
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                labelSize="md"
                dataAction="adicionar_template:texto:descricao"
                dataLabel="descricao"
              />
            </form>
          </FormProvider>
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
