import * as yup from "yup";

export type UpdateOriginatorIndicationFormFields = Pick<
  HubPartners.Admin.NewOriginator,
  "referrerOperatorProfileIdentifier"
> & {
  hasIndicator: string[];
  originatorName?: string;
  operatorName?: string;
  indicatorOnField?: string;
};

export const updateOriginatorIndcationValidationSchema: yup.ObjectSchema<UpdateOriginatorIndicationFormFields> =
  yup.object({
    hasIndicator: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
    referrerOperatorProfileIdentifier: yup
      .string()
      .test(
        "requiredWhenIndicated",
        "Selecionar originador que indicou",
        (value, context) =>
          (!!value && context.parent.hasIndicator.includes("check")) ||
          (!value && !context.parent.hasIndicator.includes("check")),
      ),
    originatorName: yup.string(),
    operatorName: yup.string(),
    indicatorOnField: yup.string(),
  });

export const updateOriginatorIndcationDefaultValues: UpdateOriginatorIndicationFormFields =
  {
    hasIndicator: [],
    referrerOperatorProfileIdentifier: undefined,
    originatorName: undefined,
    operatorName: undefined,
    indicatorOnField: undefined,
  };
