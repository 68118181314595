import { useGridRef } from "hooks/grid-ref";
import { PageTitle } from "components/page-title";
import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { useCustomer } from "contexts/customer";
import { useMemo } from "react";
import { buildDataSource, appListGrid } from "./listagem-apps.grid";

export const AppList = () => {
  const gridRef = useGridRef();
  const {
    customer: { identification },
  } = useCustomer();

  const dataSource = useMemo(
    () => buildDataSource(identification),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de Aplicativos" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={appListGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
