import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OField,
  OInputNumber,
  OOption,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
  OSelect,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GridTemplate } from "templates/grid-template";
import { consolidatedContractsGrid } from "./consolidated-contracts.grid";
import { useConsolidatedContracts } from "./consolidated-contracts.hook";
import {
  formatProductName,
  quantityOptions,
} from "./consolidated-contracts.utils";

export const ConsolidatedContracts = () => {
  const {
    dataSource,
    form,
    onSubmit,
    page,
    allPagesGeneration,
    products,
    selectedProduct,
    brokers,
    selectedBroker,
    setPage,
    setAllPagesGeneration,
    setSelectedProduct,
    setSelectedBroker,
  } = useConsolidatedContracts();

  const gridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-5">
      <PageTitle title="Contratos Consolidados" />
      <OCard>
        <OCardBody>
          <PageTitle title="Selecione um produto" />
          
          <ORow>
            <OCol>
              <OField label="Produto" htmlFor="produtos">
                <OSelect
                  dataAction="constratos_consolidados:select:produtos"
                  dataLabel="produtos"
                  label="Selecione um produto"
                  value={selectedProduct}
                  onInput={(e: any) => setSelectedProduct(e.target.value)}
                >
                  {products?.map((product) => (
                    <OOption key={product.id} value={product.id ?? ""}>
                      {formatProductName(product.name)}
                    </OOption>
                  ))}
                </OSelect>
              </OField>
            </OCol>
            <OCol>
              <OField label="Broker" htmlFor="brokers">
                <OSelect
                  dataAction="constratos_consolidados:select:brokers"
                  dataLabel="brokers"
                  label="Selecione um broker"
                  value={selectedBroker}
                  onInput={(e: any) => setSelectedBroker(e.target.value)}
                >
                  {brokers?.map((broker) => (
                    <OOption key={broker.id} value={broker.id ?? ""}>
                      {broker.name}
                    </OOption>
                  ))}
                </OSelect>
              </OField>
            </OCol>
          </ORow>

        </OCardBody>
      </OCard>
      <FormTemplate
        pageTitle={<PageTitle title="Gerar contratos consolidados" />}
        actions={
          <IconButton
            icon={{ category: "orq", icon: "orq-file" }}
            dataAction="contratos_consolidados:botao:gerar_todos"
            dataLabel="gerar_todos"
            onClick={() => onSubmit()}
          >
            Gerar todos
          </IconButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ORow>
              <OCol>
                <OField label="Data inicial" htmlFor="fromDate">
                  <ORFieldInputDate
                    id="fromDate"
                    name="fromDate"
                    className="mt-2"
                  />
                </OField>
              </OCol>
              <OCol>
                <OField label="Data final" htmlFor="toDate">
                  <ORFieldInputDate
                    id="toDate"
                    name="toDate"
                    className="mt-2"
                  />
                </OField>
              </OCol>
              <OCol>
                <OField label="Número" htmlFor="page">
                  <OInputNumber
                    id="page"
                    name="page"
                    dataAction="contratos_consolidados:texto:numero"
                    dataLabel="numero"
                    value={page}
                    onInput={(e: any) => setPage(e.target.value)}
                  />
                </OField>
              </OCol>
              <OCol>
                <ORFieldSelect
                  id="limit"
                  name="limit"
                  label="Quantidade"
                  dataAction="contratos_consolidados:select:quantidade"
                  dataLabel="quantidade"
                >
                  {quantityOptions.map((option) => (
                    <OOption key={option} value={option}>
                      {option}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <OField label="Gerar Todas as Páginas" htmlFor="allPagesGeneration">
                  <OCheckbox
                    id="allPagesGeneration"
                    name="allPagesGeneration"
                    size="sm"
                    checked={allPagesGeneration}
                    onClick={(e: any) => setAllPagesGeneration(e.target.checked)}
                    value=""
                  />
                </OField>
              </OCol>
            </ORow>
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <GridTemplate
        pageTitle={<PageTitle title="Contratos consolidados" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={consolidatedContractsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </div>
  );
};
