import { ONotification, OTypography } from "@maestro/react";
import { GetOperationsAttendanceByOperationIdResponse } from "services/quickfin/models";

interface SignatureAlertProps {
  operation?: GetOperationsAttendanceByOperationIdResponse;
}

export const SignatureAlert = ({ operation }: SignatureAlertProps) => {
  if (
    !operation?.hasContract ||
    operation?.contract?.status !== "WaitingSignatures"
  )
    return null;

  return (
    <ONotification type="warning" className="mb-3">
      <OTypography type="dark">
        Pendente assinatura do termo para liberação de antecipação
      </OTypography>
    </ONotification>
  );
};
