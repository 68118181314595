import * as yup from "yup";
import { RolesForm } from "./roles.types";

export const userProfilesFields = {
  roles: {
    id: "roles",
    name: "roles",
    label: "Roles",
    placeholder: "Selecionar",
  },
};

export const userProfilesDefaultValues = {
  roles: [],
};

export const userProfilesValidationSchema = yup.object<
  RolesForm,
  yup.ObjectSchema<RolesForm>["fields"]
>({
  roles: yup.array().required("Campo obrigatório"),
});
