import {
  CriteriaType,
  Operation,
} from "services/eligibilityengine/models/types/eligibility-criteria.types";

export const editEligibilityGroupModalId = "edit-eligibility-group-modal";
export const removeGroupConfirmationModalId = "remove-group-confirmation-modal";
export const pendingChangesConfirmationModalId =
  "pending-changes-confirmation-modal";

export const criteriaFieldName = "criteria";

export interface CriteriaFields {
  criterium: CriteriaType;
  operation: Operation;
  value: string;
  id?: string;
  active?: boolean;
  isNewlyCreated?: boolean;
  eligibilityCriteriaId: number;
  criteriaParamsId: number;
}

export const newCriterium = {
  criterium: null as any,
  operation: null as any,
  value: null as any,
  active: true,
  isNewlyCreated: true,
  eligibilityCriteriaId: 0,
  criteriaParamsId: 0,
} satisfies CriteriaFields;

export interface EditEligibilityGroupForm {
  name: string;
  [criteriaFieldName]: CriteriaFields[];
}
