import { OLoader, modalManager } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { RecreateTradeConfirmationModal } from "./_compose";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  trade: HubEnergy.TradeODataResponse;
}

export const GridActions = ({ trade }: GridActionsProps) => {
  const { loading, recreateTrade } = useGridActions();

  const recreateTradeConfirmationModalId = `recreate-trade-confirmation-modal-${trade.id}`;

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-refresh" },
              label: "Recriar trade",
              onClick: () =>
                modalManager.show(recreateTradeConfirmationModalId),
            },
          ]}
        />
      )}
      <RecreateTradeConfirmationModal
        modalId={recreateTradeConfirmationModalId}
        customerName={trade.customerName ?? trade.customerOfficialName}
        callback={() => recreateTrade(trade.id)}
      />
    </>
  );
};
