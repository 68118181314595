import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useOptOut } from "../consulta-optins.hooks";
import { useOptinContext } from "../consulta-optin.context";

export const confirmationModalId = "confirmation-modal";

export const ConfirmationModal = () => {
  const { postOptOut, postOptOutHasError } = useOptOut();

  const { identop } = useOptinContext();
  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={confirmationModalId}
      title="Realizar Opt-out"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={() => {
        postOptOut({ identop }).then(() => {
          if (!postOptOutHasError)
            OToastManager.success("O Opt-out foi realizado com sucesso");
          else {
            const errorMessage = "Erro ao realizar o Opt-out";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        });
        modalManager.hide(confirmationModalId);
      }}
    >
      <div>
        Tem certeza que deseja realizar o Opt-out para o identop {identop}?
      </div>
    </OConfirmationModal>
  );
};
