import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { EmprestimosProdutoCreditLineConfigurationById } from "../../../../../routes/emprestimos.route-params";
import {
  UpdateCreditLineLineConfigurationFormValues,
  creditLineConfigurationFormDefaultValues,
  creditLineConfigurationFormValidationSchema,
} from "../credit-line-configuration.schema";

export const useUpdateCreditLineConfiguration = () => {
  const { id } = useParams<EmprestimosProdutoCreditLineConfigurationById>();

  const form = useForm<UpdateCreditLineLineConfigurationFormValues>({
    resolver: yupResolver(creditLineConfigurationFormValidationSchema),
    defaultValues: creditLineConfigurationFormDefaultValues,
  });

  const { setValue } = form;

  const navigate = useNavigate();

  const {
    value: creditLineConfiguration,
    callService: getCreditLineConfiguration,
    loading: loadingCreditLineConfiguration,
    hasError: hasErrorCreditLineConfiguration,
  } = useServiceCall(service.hubCreditManager.getCreditLineConfigurationById);

  const {
    value: bookStrategies,
    callService: getBookStrategies,
    loading: loadingBookStrategies,
    hasError: hasErrorBookStrategies,
  } = useServiceCall(service.hubCreditManager.getBookStrategies);

  const {
    value: creditLines,
    callService: getCreditLines,
    loading: loadingCreditLines,
    hasError: hasErrorCreditLines,
  } = useServiceCall(service.hubCreditManager.getCreditLines);

  const {
    callService: callUpdateCreditLineConfiguration,
    loading: loadingUpdateCreditLineConfiguration,
  } = useServiceCall(
    service.hubCreditManager.updateCreditLineLineConfiguration,
  );

  const updateCreditLineConfiguration = useCallback(
    async ({
      associateGuarantorMinimumShare,
      bookStrategyId,
      collateralTypes,
      creditLineId,
      maximumLimitForSimpleGuarantor,
      partner,
      shouldSyncGuarantors,
      automaticallyApproveOnFts,
      agreementId,
      fundingType,
      creditLineVisibleName,
      creditLineDescription,
      creditLineIcon,
    }: UpdateCreditLineLineConfigurationFormValues) => {
      const { success } = await callUpdateCreditLineConfiguration({
        id: Number(id),
        associateGuarantorMinimumShare,
        bookStrategyId,
        collateralTypes,
        partner,
        creditLineId,
        maximumLimitForSimpleGuarantor,
        shouldSyncGuarantors: !!shouldSyncGuarantors,
        automaticallyApproveOnFts: !!automaticallyApproveOnFts,
        agreementId,
        fundingType,
        creditLineVisibleName,
        creditLineDescription,
        creditLineIcon,
      });

      if (success) {
        OToastManager.success(
          "A configuração de linha de crédito foi atualizada com sucesso.",
        );

        navigate(
          corporateRouter.routes.emprestimos.product.contingency
            .creditLinesConfigurations.path,
        );
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar atualizar a configuração de linha de crédito. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callUpdateCreditLineConfiguration, id, navigate],
  );

  const getAllData = useCallback(() => {
    if (!id) return;

    getCreditLines();
    getBookStrategies();
    getCreditLineConfiguration(id);
  }, [getBookStrategies, getCreditLineConfiguration, getCreditLines, id]);

  useEffect(() => {
    if (creditLineConfiguration) {
      const {
        associateGuarantorMinimumShare,
        bookStrategy,
        collateralTypes,
        creditLine,
        maximumLimitForSimpleGuarantor,
        partner,
        shouldSyncGuarantors,
        automaticallyApproveOnFts,
        agreementId,
        fundingType,
        creditLineVisibleName,
        creditLineDescription,
        creditLineIcon,
      } = creditLineConfiguration;

      setValue("collateralTypes", collateralTypes);
      setValue("shouldSyncGuarantors", shouldSyncGuarantors ? 1 : 0);
      setValue("automaticallyApproveOnFts", automaticallyApproveOnFts ? 1 : 0);
      setValue("creditLineId", creditLine.id);
      setValue("agreementId", agreementId);
      setValue("fundingType", fundingType);

      if (partner) {
        setValue("partner", partner);
      }

      if (creditLineVisibleName) {
        setValue("creditLineVisibleName", creditLineVisibleName);
      }

      if (creditLineDescription) {
        setValue("creditLineDescription", creditLineDescription);
      }

      if (creditLineIcon) {
        setValue("creditLineIcon", creditLineIcon);
      }

      if (bookStrategy) {
        setValue("bookStrategyId", bookStrategy.id);
      }

      if (associateGuarantorMinimumShare) {
        setValue(
          "associateGuarantorMinimumShare",
          associateGuarantorMinimumShare,
        );
      }

      if (maximumLimitForSimpleGuarantor) {
        setValue(
          "maximumLimitForSimpleGuarantor",
          maximumLimitForSimpleGuarantor,
        );
      }
    }
  }, [creditLineConfiguration, setValue]);

  useEffect(() => {
    hasErrorBookStrategies &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber as configurações de book / estratégia. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorBookStrategies]);

  useEffect(() => {
    hasErrorCreditLines &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar receber as linhas de crédito. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorCreditLines]);

  useEffect(() => {
    hasErrorCreditLineConfiguration &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar os dados da configuração da linha de crédito. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorCreditLineConfiguration]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    form,
    getAllData,
    bookStrategies,
    creditLines,
    updateCreditLineConfiguration,
    loadingBookStrategies,
    loadingCreditLineConfiguration,
    loadingCreditLines,
    loadingUpdateCreditLineConfiguration,
    hasErrorBookStrategies,
    hasErrorCreditLineConfiguration,
    hasErrorCreditLines,
  };
};
