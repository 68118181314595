import { OContainer } from "@maestro/react";
import { OfferGuarantorsProvider } from "./offer-guarantors.context";
import { OnboardingDetails } from "./_compose";

export const OfferGuarantors = () => {
  return (
    <OfferGuarantorsProvider>
      <OContainer type="sm">
        <OnboardingDetails />
      </OContainer>
    </OfferGuarantorsProvider>
  );
};
