import { OCardBody } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ProcessorOutputsComponent } from "./_compose";
import { useProcessorConfigMonitoringPage } from "./processor-config-monitoring.hook";
import { ProcessorConfigMonitoringContentProps } from "./processor-config-monitoring.types";

export const ProcessorConfigMonitoringPageContent = ({
  workflowConfigView,
}: ProcessorConfigMonitoringContentProps) => {
  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const { form, saving, updateProcessorConfigViewProperties } =
    useProcessorConfigMonitoringPage({
      workflowConfigView,
    });

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={saving}
          onClick={updateProcessorConfigViewProperties}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCardBody>
            <ProcessorOutputsComponent
              workflowConfigView={workflowConfigView}
            />
          </OCardBody>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
