import { OButton, OIcon, OOption, OSelect, OTypography } from "@maestro/react";
import { IIcon } from "@maestro/core";
import React, { useCallback, useMemo } from "react";

enum PaginationLink {
  Previous = "PREVIOUS",
  Next = "NEXT",
  Inactive = "INACTIVE",
}

interface Link {
  icon: IIcon;
  type: "button" | "icon";
  link: number;
  title?: string;
}

const MapperPaginationLink: Record<PaginationLink, Link> = {
  [PaginationLink.Previous]: {
    icon: "fa-angle-double-left",
    type: "button",
    link: -3,
    title: "Retroceder 3 páginas",
  },
  [PaginationLink.Next]: {
    icon: "fa-angle-double-right",
    type: "button",
    link: 3,
    title: "Avançar 3 páginas",
  },
  [PaginationLink.Inactive]: {
    icon: "fa-ellipsis-h",
    type: "icon",
    link: 0,
  },
};

interface PaginationProps {
  page: number;
  setPage: (value: number) => void;
  numPages: number;
  hasPaginationResume?: boolean;
  className?: string;
  pageData?: {
    size: number;
    setSize: (value: React.SetStateAction<number>) => void;
    sizes: number[];
  };
}

export const Pagination = ({
  page,
  setPage,
  numPages,
  hasPaginationResume,
  className,
  pageData,
}: PaginationProps) => {
  const loadClassName = useCallback(() => {
    const justifyContentValue = !!pageData ? "between" : "end";
    return (
      className ??
      `d-flex flex-row justify-content-${justifyContentValue} w-100`
    );
  }, [className, pageData]);

  const loadPaginationLinks = useCallback(
    (
      reference: number,
      initial: number,
      final: number,
    ): Array<PaginationLink> => {
      const links = [];

      const hasPreviousLink = reference - 3 >= initial;
      if (hasPreviousLink) links.push(PaginationLink.Previous);
      else links.push(PaginationLink.Inactive);

      const hasNextLink = reference + 3 <= final;
      if (hasNextLink) links.push(PaginationLink.Next);
      else links.push(PaginationLink.Inactive);

      return links;
    },
    [],
  );

  const pagination = useMemo((): Array<number | PaginationLink> | undefined => {
    try {
      if (!numPages) return;

      const pages = Array.from({ length: numPages }, (_, i) => i + 1);

      if (numPages < 4) return pages;

      const [initial, final] = [pages.at(0), pages.at(-1)];
      if (!initial || !final) return;

      const [previousLink, nextLink] = loadPaginationLinks(
        page,
        initial,
        final,
      );

      if (page === initial) {
        const [second, third] = [pages.at(1), pages.at(2)];
        if (!second || !third) return;
        return [initial, second, third, nextLink, final];
      }

      if (page === final) {
        const [penultimate, antepenultimate] = [pages.at(-3), pages.at(-2)];
        if (!penultimate || !antepenultimate) return;
        return [initial, previousLink, penultimate, antepenultimate, final];
      }

      const [previous, next] = [pages.at(page - 2), pages.at(page)];
      if (!previous || !next) return;

      const numberings: Array<number | PaginationLink> = [
        initial,
        previous,
        page,
        next,
        final,
      ];

      if (previous === initial) numberings.splice(1, 1);
      if (next === final) numberings.splice(-1, 1);

      if (previous > initial + 1) numberings.splice(1, 0, previousLink);
      if (next < final - 1) numberings.splice(-1, 0, nextLink);

      return numberings;
    } catch (error) {
      console.error(error);
    }
  }, [numPages, page, loadPaginationLinks]);

  return (
    <>
      {pagination && (
        <div className={loadClassName()}>
          {pageData && (
            <div className="d-flex flex-row gap-2" key={`${pageData.sizes}`}>
              <OSelect
                style={{ minWidth: 70 }}
                dataAction="paginacao:select:selecionar_tamanho_da_pagina"
                dataLabel="selecionar_tamanho_da_pagina"
                placeholder={pageData.size.toString()}
                onInput={(e) => {
                  const value = (e.target as HTMLOSelectElement).value;
                  pageData.setSize(value as number);
                }}
                value={pageData.size}
              >
                {pageData.sizes?.map((size) => (
                  <OOption key={size} value={size}>
                    {size}
                  </OOption>
                ))}
              </OSelect>
              <OTypography
                className="align-self-center text-nowrap"
                type="primary-80"
              >
                Linhas por página
              </OTypography>
            </div>
          )}
          <div className="d-flex flex-row gap-2" key={page}>
            {hasPaginationResume && pageData && (
              <OTypography
                className="text-nowrap"
                key={`${pageData.size}/${numPages}`}
                type="primary-80"
              >
                1-{pageData.size} de {numPages}
              </OTypography>
            )}
            <OButton
              dataAction="paginacao:button:pagina_anterior"
              dataLabel="pagina_anterior"
              type="light"
              disabled={page === 1}
              title={"Página anterior"}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <OIcon category="far" icon="fa-angle-left" size="md" />
            </OButton>

            {pagination.map((item) => {
              if (typeof item === "number") {
                return (
                  <OButton
                    key={item}
                    dataAction={`paginacao:button:pagina_${item}`}
                    dataLabel={`pagina_${item}`}
                    type={item === page ? "tertiary" : "light"}
                    title={`Página ${item}`}
                    onClick={() => {
                      setPage(item);
                    }}
                  >
                    <OTypography
                      key={item === page ? "active" : "deactive"}
                      type={item === page ? "light" : "primary"}
                    >
                      {item}
                    </OTypography>
                  </OButton>
                );
              }
              const { icon, title, type, link } = MapperPaginationLink[item];
              if (type === "icon")
                <OIcon category="far" icon={icon} size="md" />;
              if (type === "button")
                return (
                  <OButton
                    key={item}
                    dataAction="paginacao:button:link_de_paginacao"
                    dataLabel="link_de_paginacao"
                    type="light"
                    title={title}
                    onClick={() => {
                      if (!link) return;
                      setPage(page + link);
                    }}
                  >
                    <OIcon category="far" icon={icon} size="md" />
                  </OButton>
                );
            })}

            <OButton
              dataAction="paginacao:button:proxima_pagina"
              dataLabel="proxima_pagina"
              type="light"
              disabled={page === numPages}
              title={"Próxima página"}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <OIcon category="far" icon="fa-angle-right" size="md" />
            </OButton>
          </div>
        </div>
      )}
    </>
  );
};
