import { DetailsFields } from "components/details-card";
import { BeneficiaryDetails } from "services/hubfx/models/types/beneficiary/beneficiary-details.model";

export const exchangeBeneficiariesDetailsGenerator = (
  beneficiary: BeneficiaryDetails | null,
) => {
  if (!beneficiary) return {} as DetailsFields;

  return {
    beneficiary: {
      title: "Beneficiário",
      items: [
        {
          label: "Nome",
          value: beneficiary?.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo de pagador",
          value: beneficiary?.payerTypeDescription,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Id tipo de pagador",
          value: beneficiary?.payerType,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    benefitiaryAccount: {
      title: "Conta beneficiário",
      items: [
        {
          label: "Nº Conta",
          value: beneficiary?.mainBankAccountNumber,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: beneficiary?.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: beneficiary?.country,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    client: {
      title: "Cliente",
      items: [
        {
          label: "Link",
          value: beneficiary?.clientLink,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "LinkDescription",
          value: beneficiary?.clientLinkDescription,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    beneficiaryBank: {
      title: "Banco Beneficiário",
      items: [
        {
          label: "Banco Beneficiário",
          value: beneficiary?.mainBankName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.mainBankSwift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.mainBankFedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    intermediateBank: {
      visible: !!beneficiary?.intermediateBankName,
      title: "Banco Intermedário",
      items: [
        {
          label: "Banco Intermedário",
          value: beneficiary?.intermediateBankName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SWIFT",
          value: beneficiary?.intermediateBankSwift,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FedWire",
          value: beneficiary?.intermediateBankFedWire,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
