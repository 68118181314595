import { FaqItem } from "../../../../../components/faq/faq.component";

export interface FaqItemMap {
  [key: string]: FaqItem[];
}

export const faqItems: FaqItemMap = {
  SOLAR: [
    {
      title:
        "O financiamento cobre equipamentos e projeto de instalação? Caso queira financiar um valor menor, é possível?",
      description:
        "Sim, financiamos tanto a aquisição dos equipamentos quanto o valor do projeto de instalação. Caso o valor solicitado de financiamento seja inferior ao valor total do projeto, a prioridade sempre será financiar a aquisição de equipamentos.",
    },
    {
      title: "O BTG Pactual financia para Condomínios e Igrejas?",
      description: "Não.",
    },
    {
      title:
        "Qual o valor máximo financiável pela plataforma de financiamento solar pelo BTG Pactual?",
      description:
        "O valor máximo aceito para financiamento pela plataforma do BTG Pactual Empresas é R$ 1.500.000,00 (um milhão e meio de reais). Acima desse valor, os casos deverão ser analisados um a um manualmente.",
    },
    {
      title:
        "Quem são os clientes aceitos pelo BTG Pactual para financiamento solar?",
      description:
        "Financiamos tanto pessoas físicas quanto jurídicas que atendam os critérios de elegibilidade da nossa política de crédito e que instalem os projetos em imóvel próprio (não estamos financiando projetos para imóveis alugados).",
    },
    {
      title: "Quais as idades mínima e máxima para solicitar financiamento?",
      description: "Respectivamente 18 e 74 anos.",
    },
    {
      title: "Qual a renda mínima para solicitar o financiamento?",
      description:
        "A renda mínima para solicitar financiamento é R$ 4.000,00 (quatro mil reais) por mês.",
    },
    {
      title: "O cliente é obrigado a abrir uma conta bancária no BTG Pactual?",
      description:
        "Sim, o cliente será obrigado a realizar a abertura de conta no BTG Pactual Empresas. Porém, não será cobrado pela abertura ou manutenção da conta.",
    },
    {
      title: "Qual a forma de pagamento das parcelas do financiamento?",
      description:
        "O cliente será cobrado por meio de débito em conta. Dessa forma, é necessário que, nas datas de vencimento, a conta do cliente no BTG Pactual Empresas possua saldo suficiente para liquidação das parcelas.",
    },
    {
      title: "Quais são as condições de financiamento?",
      description:
        "São elegíveis para o financiamento pessoas físicas ou jurídicas e estabelecimentos residenciais ou comerciais. As taxas variam conforme avaliação de crédito de cada cliente, com até 180 dias de carência e pagamento em até 96 parcelas mensais para pessoas físicas e em até 72 parcelas mensais para pessoas jurídicas. O cálculo é feito com base na Tabela Price e as parcelas cobradas são fixas e equivalentes à economia na conta de luz. Como garantia, é necessária a Alienação Fiduciária dos kits fotovoltaicos e, em caso de clientes pessoas jurídicas, também é exigido o aval dos sócios que representem mais de 25% do capital social.",
    },
    {
      title: "Quanto tempo leva para análise e contratação de crédito?",
      description: (
        <>
          <b>Análise</b>: A pré-análise será praticamente automática. Após a
          pré-aprovação, o parceiro deve enviar a documentação solicitada para
          que a validemos em 2 (dois) dias úteis.
          <br />
          <br />
          <b>Contratação</b>: Após aprovação da documentação, o parceiro deverá
          realizar o cadastro do cliente e este deverá cadastrar biometria e
          assinar contrato de abertura. Uma vez com a conta aberta, o parceiro
          deverá enviar as notas fiscais, que serão validadas em até 1 (um) dia
          útil. Após validação de notas, o contrato será disponibilizado para
          assinatura eletrônica pelo cliente e avalistas, se for o caso.,
        </>
      ),
    },
    {
      title: "Como cadastrar biometria?",
      description: (
        <>
          Os clientes finais receberão um link de boas-vindas para o primeiro
          acesso, cadastrando login e senha. Assim que criados, o cliente deverá
          baixar o app digitando BTG Pactual Empresas na Apple Store ou Play
          Store para finalizar a biometria.
          <br />
          Após baixar o aplicativo, entre no app, clicar em “entrar” &gt; “Estou
          Pronto” &gt; Biometria Coletada &gt; Cadastrar documentos (RG, CNH ou
          passaporte) &gt; Tirar foto &gt; conferir se o token biométrico está
          cadastrado &gt; selecione a empresa para começar a usar a conta.
        </>
      ),
    },
    {
      title: "Como fazer o cadastro de avalistas?",
      description:
        "Sempre que o cliente for uma pessoa jurídica, será exigido aval dos sócios que representem mais de 25% do capital social da empresa. O cadastro do(s) avalista(s) é feito após o cadastro da própria empresa, quando o item “Avalistas” sinalizar “pendente” na tela da simulação do cliente final. Nesse momento, o cliente deve clicar na opção “AÇÕES” e enviar as informações e documentos a respeito dos avalistas. Após o cadastro dos avalistas concluído, ao clicar em “CONTINUAR” será gerada a Cédula de Crédito Bancário para assinatura.",
    },
    {
      title: "Como o financiamento é formalizado?",
      description:
        "Os financiamentos são assinados eletronicamente, sem necessidade de impressão de documentos e envio físico. O cliente e os avalistas, caso aplicável, receberão um link para acessar o portal de clientes e assinar eletronicamente o contrato.",
    },
  ],
  KGIRO: [],
  FUMACA: [],
  AGRO: [],
  AGRO_COSTING: [],
  AGRO_INVESTMENT: [],
  AGRO_USED_INVESTMENT: [],
  FUMACAPEAC: [],
  KGIROPEAC: [],
  TOMBAMENTO_CARTAO: [],
};
