import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams } from "../../../../../routes/ferramentas.route-params";
import { DefaultContractFileConfigurationForm } from "../_compose/default-contract-file-configuration-form.component";
import {
  DefaultContractFileConfigurationFormValues,
  defaultContractFileConfigurationFormValidationSchema,
} from "../_compose/default-contract-file-configuration.form";

export const UpdateDefaultContractFileConfigurationPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DefaultContractFileConfigurationFormValues>({
    resolver: yupResolver(defaultContractFileConfigurationFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDefaultContractFileConfigurationDetails,
    loading,
    hasError,
    value: defaultContractFileConfiguration,
  } = useServiceCall(
    service.onboarding.getDefaultContractFileConfigurationDetails,
  );
  const {
    callService: updateDefaultContractFileConfiguration,
    loading: submitLoading,
  } = useServiceCall(service.onboarding.updateDefaultContractFileConfiguration);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDefaultContractFileConfiguration(
          uuid,
          values,
        );

        if (!success)
          return OToastManager.danger("Erro ao atualizar configuração");

        return OToastManager.success("Configuração atualizada com sucesso");
      }),
    [handleSubmit, updateDefaultContractFileConfiguration, uuid],
  );

  useEffect(() => {
    getDefaultContractFileConfigurationDetails(uuid);
  }, [getDefaultContractFileConfigurationDetails, uuid]);

  useEffect(() => {
    defaultContractFileConfiguration && reset(defaultContractFileConfiguration);
  }, [defaultContractFileConfiguration, reset]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar configuração padrão de arquivo do contrato" />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar configuração"
            callback={() => getDefaultContractFileConfigurationDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DefaultContractFileConfigurationForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
