import { ErrorComponent } from "../../../empty-state/error-component.component";

interface ProductionErrorUiProps {
  customAction?: JSX.Element;
}

export const ProductionErrorUi = ({ customAction }: ProductionErrorUiProps) => {
  return (
    <div
      style={{ width: "max-content", maxWidth: "100%" }}
      className="py-5 mx-auto"
    >
      <ErrorComponent
        messageTitle="Ocorreu um erro inesperado e não foi possível recuperar a página"
        messageParagraph="A nossa equipe já foi notificada e resolverá o problema assim que possível."
      >
        {customAction}
      </ErrorComponent>
    </div>
  );
};
