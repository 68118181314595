import { OButton, OField, OIcon } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DefaultErrorComponent } from "components/empty-state";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetAdminNatureGenericFieldTypesResponse } from "services/hubfx/nature/models/responses";
import { ContentTemplate } from "templates/content-template";
import {
  NatureGenericFieldsForm,
  exchangeNatureGenericFieldsFormGrid,
} from "./nature-generic-fields-form.grid";

interface ExchangeNatureGenericFieldsFormProps {
  name?: "fileGenericFields" | "genericFields";
  genericFields?: GetAdminNatureGenericFieldTypesResponse;
}

export const ExchangeNatureGenericFieldsForm = ({
  name = "genericFields",
  genericFields: fields,
}: ExchangeNatureGenericFieldsFormProps) => {
  const form = useFormContext<Record<typeof name, NatureGenericFieldsForm[]>>();
  const { getValues, setValue } = form;

  const {
    callService: getNatureGenericFieldTypes,
    value: genericFields,
    loading,
    hasError,
  } = useServiceCall(service.hubFx.nature.getNatureGenericFieldTypes);

  const gridRef = useGridRef();

  const grid = useMemo(
    () =>
      exchangeNatureGenericFieldsFormGrid(
        (genericFields ?? fields)?.map((field) => ({
          label: field.description,
          value: field.id,
        })) ?? [],
        getValues(name),
        (natureGenericFields) => {
          setValue(name, natureGenericFields);
        },
      ),
    [genericFields, getValues, name, setValue, fields],
  );

  useEffect(() => {
    if (!fields) getNatureGenericFieldTypes();
  }, [getNatureGenericFieldTypes, fields]);

  return (
    <ContentTemplate
      loading={loading}
      value={fields ?? genericFields}
      hasError={hasError}
      errorComponent={
        <DefaultErrorComponent
          title="Erro ao buscar campos genéricos"
          callback={getNatureGenericFieldTypes}
        />
      }
      render={() => (
        <>
          <div className="d-flex justify-content-between">
            <OField
              htmlFor="genericFields"
              label={
                name === "genericFields"
                  ? "Campos Genéricos"
                  : "Campos Genéricos do Documento"
              }
            />

            <OButton onClick={() => gridRef.current?.instance.addRow()}>
              <OIcon category="fa" icon="fa-plus" />
            </OButton>
          </div>
          <ODataGridGenerator gridRef={gridRef} grid={grid} />
        </>
      )}
    />
  );
};
