import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { WorkflowProdutoLeadByProposedCreditLineId } from "../../../../../../routes/workflow.route-params";
import { workflowCreditLineProposalDetailsFields } from "./workflow-creditline-proposal.details";

export const WorkflowCreditlineProposalPage = () => {
  const { id, proposedCreditLineId } =
    useParams<WorkflowProdutoLeadByProposedCreditLineId>();

  if (!id || !proposedCreditLineId)
    throw new Error("No id and proposedCreditLineId");

  const {
    callService: getWorkflowLeadProposedCreditLine,
    value: proposedCreditLine,
    loading,
    hasError,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowLeadProposedCreditLine,
  );

  useEffect(() => {
    getWorkflowLeadProposedCreditLine(id, proposedCreditLineId);
  }, [id, proposedCreditLineId, getWorkflowLeadProposedCreditLine]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da proposta" />}>
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar os detalhes da proposta"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton
                onClick={() =>
                  getWorkflowLeadProposedCreditLine(id, proposedCreditLineId)
                }
              />
            </ErrorComponent>
          }
          value={proposedCreditLine}
          fields={workflowCreditLineProposalDetailsFields}
        />
      </div>
    </DetailsTemplate>
  );
};
