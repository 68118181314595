import { OInputNumber } from "@maestro/react";
import styled from "styled-components";

export const FullcreenButtonBtn = styled.button.attrs({
  type: "button",
})`
  all: unset;
  cursor: pointer;

  background-color: var(--theme-tertiary);
  border-radius: 50%;
  padding: 0.5rem;
`;

export const OInputNumberStyled = styled(OInputNumber)`
  width: 10px;
  max-width: 10px;
`;
