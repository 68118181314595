import { env } from "utils/environments";

export const legacysEndpoints = {
  baseCalendar: {
    endpoint: `${env.API_LEGACYS}/BaseCalendar`,
    getById: (id: number) => `${env.API_LEGACYS}/BaseCalendar/${id}`,
  },
  customCalendar: {
    endpoint: `${env.API_LEGACYS}/CustomCalendar`,
    getById: (id: number) => `${env.API_LEGACYS}/CustomCalendar/${id}`,
  },
  calendar: {
    endpoint: `${env.API_LEGACYS}/Calendar`,
  },
};
