import type { Options } from "devextreme/data/data_source";
import { useMemo } from "react";

export const UseBankslipContracts = (documentNumber: string) => {
  const gridFilter: Options = useMemo(
    () => [
      ["CompanyCNPJ", "=", documentNumber],
      "and",
      [
        ["ContractType", "=", "OptinAberturaDeConta"],
        "or",
        ["ContractType", "=", "ContratacaoDuplicatas"],
      ],
    ],
    [documentNumber],
  );

  return {
    gridFilter,
  };
};
