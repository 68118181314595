import { modalManager, OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { CompanyGroupsMasterDetail } from "./company-groups.master-detail";
import { CompanyGroup } from "./company-groups.types";
import { maskTaxIdGroup } from "./company-groups.utils";
import {
  AddGroupConfirmationModal,
  buildAddGroupConfirmationModalId,
  buildUngroupConfirmationModalId,
  UngroupConfirmationModal,
} from "./compose";

export const dataSource = dataSourceCustomStoreGenerator<CompanyGroup>(
  (loadOptions) =>
    service.recebiveisCartao
      .getAgendaCompanies(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os grupos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

const reload = () => dataSource.reload();

export const companyGroupsGrid: ODataGridGeneratorConfig<CompanyGroup> = {
  datagrid: {
    noDataText: "Nenhum grupo.",
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "tax_id",
      dataType: "string",
      caption: "CNPJ",
      calculateCellValue: ({ tax_id }) => tax_id.slice(0, 8),
      format: (value) => maskTaxIdGroup(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "target_tax_id",
      dataType: "string",
      caption: "CNPJ Matriz",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "grouped_count",
      dataType: "number",
      caption: "CNPJs Agrupados",
      alignment: "center",
    },
    {
      dataField: "ungrouped_count",
      dataType: "number",
      caption: "CNPJs Não Agrupados",
      alignment: "center",
    },
    {
      caption: "Ações",
      alignment: "center",
      allowExporting: false,
      cellRender: ({ data }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "far", icon: "fa-link" },
                label: "Agrupar",
                onClick: () =>
                  modalManager.show(
                    buildAddGroupConfirmationModalId(data.tax_id),
                  ),
              },
              {
                icon: { category: "far", icon: "fa-unlink" },
                label: "Desagrupar",
                onClick: () =>
                  modalManager.show(
                    buildUngroupConfirmationModalId(data.tax_id),
                  ),
              },
            ]}
          />
          <AddGroupConfirmationModal
            prefix={data.tax_id}
            // Don't use inline function, or it will reload twice!!
            successCallback={reload}
          />
          <UngroupConfirmationModal
            prefix={data.tax_id}
            // Don't use inline function, or it will reload twice!!
            successCallback={reload}
          />
        </>
      ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: CompanyGroupsMasterDetail,
  },
};
