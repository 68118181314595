import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetDashboadChargesResponse } from "services/fidc/models/responses";
import { currencySymbol } from "utils/currency";
import { assetStatusMap } from "./view-assets.utils";
import { ViewAssetsMasterDetail } from "./view-assets.master-detail";

export const ViewAssetsGrid: ODataGridGeneratorConfig<
  GetDashboadChargesResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhum valor carregado.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} valores)",
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "tradeId",
      caption: "Identificação",
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => assetStatusMap[data.status],
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "rate",
      caption: "Taxa",
      cellRender: ({ data }) => `${(data.rate * 100).toFixed(3)}%`,
    },
    {
      dataField: "loanValue",
      caption: "Valor do empréstimo",
      cellRender: ({ data }) =>
        masks.currency(data.loanValue, currencySymbol.BRL, "."),
    },
    {
      dataField: "dueValue",
      caption: "POsição atualizada",
      cellRender: ({ data }) =>
        masks.currency(data.dueValue, currencySymbol.BRL, "."),
    },
    {
      dataField: "startDate",
      caption: "Data inicial",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "maturityDate",
      caption: "Data de vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
  ],
  masterDetail: {
    enabled: true,
    component: ViewAssetsMasterDetail,
  },
};
