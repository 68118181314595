import { client } from "../client";
import { ApiBaseResponse } from "./acc";
import { accEndpoints } from "./acc.endpoints";
import { accAdminCustomerService } from "./customer/acc-admin-customer.service";
import { PatchByIdAdminSettlementBody } from "./models/requests/patch-by-id-admin-preticket.request";
import { PostAdminAccGenerateContract } from "./models/requests/post-admin-acc-generate-contract.request";
import { PostAdminCommonBody } from "./models/requests/post-admin-common.request";
import { PostAdminCounterpartyBody } from "./models/requests/post-admin-counterparty.request";
import { PostAdminPreticketBody } from "./models/requests/post-admin-pretickets.request";
import { GetAdminCommonResponse } from "./models/responses/get-admin-common.response";
import { GetAccOrdersResponse } from "./models/responses/get-admin-orders.response";
import { GetAdminPayerTypesResponse } from "./models/responses/get-admin-payer-types.response";
import { GetAdminProposalsResponse } from "./models/responses/get-admin-proposals.response";
import { GetCommonCountriesResponse } from "./models/responses/get-common-countries.response";
import { PostAdminCounterpartyResponse } from "./models/responses/post-admin-counterparty.response";
import { accNatureService } from "./nature/acc-nature.service";
import { accODataService } from "./odata/acc-odata.service";

export const accService = {
  generateAccContract(body: PostAdminAccGenerateContract) {
    return client.post(accEndpoints.admin.acc.generateContract, body);
  },
  adminCustomer: accAdminCustomerService,
  odata: accODataService,
  nature: accNatureService,
  getCommonDomain() {
    return client.get<GetAdminCommonResponse>(accEndpoints.admin.common);
  },
  getPayerTypes() {
    return client.get<GetAdminPayerTypesResponse>(accEndpoints.admin.payers);
  },
  getCountries() {
    return client.get<ApiBaseResponse<GetCommonCountriesResponse>>(
      accEndpoints.admin.countries,
    );
  },
  postCommonDomain(body: PostAdminCommonBody) {
    return client.post(accEndpoints.admin.common, body);
  },
  postCounterparty(body: PostAdminCounterpartyBody) {
    return client.post<PostAdminCounterpartyResponse>(
      accEndpoints.admin.counterparty,
      body,
    );
  },
  patchByIdSettlement(body: PatchByIdAdminSettlementBody) {
    return client.patch(accEndpoints.admin.pretickets, body);
  },
  postSettlement(body: PostAdminPreticketBody) {
    return client.post(accEndpoints.admin.pretickets, body);
  },
  postProposals(counterpartyIdentification: string) {
    return client.post<GetAdminProposalsResponse>(
      accEndpoints.admin.acc.proposals,
      {
        counterpartyIdentification,
      },
    );
  },
  postOrders(counterpartyIdentification: string, currency: string) {
    return client.post<GetAccOrdersResponse>(accEndpoints.admin.acc.orders, {
      counterpartyIdentification,
      currency,
    });
  },
};
