import {
  OCol,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import {
  groupingType,
  signingPartyRuleOrigiMap,
} from "./contract-templates.utils";
import { CompanySearchSigning } from "./company-search-signing/company-search-signing.component";

interface SigningPartyFormProps {
  name: string;
  mode: "Sacado" | "Cedente";
}

export const SigningPartyForm = ({ name, mode }: SigningPartyFormProps) => {
  return (
    <>
      <ORow>
        <OCol>
          <ORFieldSelect
            dataAction="recebiveis_template_contrato:select:regra_de_assinatura"
            dataLabel="regra_de_assinatura"
            id="ruleOrigin"
            name={`${name}.ruleOrigin`}
            label="Regra de assinatura"
            placeholder="Selecione"
          >
            {Object.entries(signingPartyRuleOrigiMap).map(([key, value]) => (
              <OOption key={key} value={key}>
                {value}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol>
          <ORFieldInput
            tag="number"
            dataAction="recebiveis_template_contrato:select:numero_minimo_assinantes"
            dataLabel="numero_minimo_assinantes"
            id="minimumNumberOfSigners"
            name={`${name}.minimumNumberOfSigners`}
            label="Número minimo de assinantes"
          />
        </OCol>
      </ORow>

      <ORow>
        <CompanySearchSigning
          mode={mode}
          label="Nome ou identificação das empresas"
          name={`${name}.parties`}
          placeholder="Digite ao menos 3 (três) caracteres"
        />
      </ORow>

      <OCol md={6}>
        <ORFieldSelectMultiple
          dataAction="recebiveis_template_contrato:select:valido_para"
          dataLabel="valido_para"
          id="validFor"
          name={`${name}.validFor`}
          label="Válido para"
          placeholder="Selecione"
        >
          {groupingType.map((option) => (
            <OOptionMultiple key={option} value={option}>
              {option}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
    </>
  );
};
