export const editContractModalId = (uuid: string) =>
  `${uuid}-edit-contract-modal`;

export const contractPlatformOptions = ["Paperclip", "Clicksign", "Fisico"];

export const contractStatusOptions = [
  "InPreparation",
  "ContractPrepared",
  "WaitingSignatures",
  "FullySigned",
  "Refused",
  "Expired",
  "Canceled",
  "WaitingApproval",
  "Complete",
  "InErrorSignatoryInfoNotFounded",
  "InErrorPreparationError",
  "InErrorNotificationError",
  "InErrorWithoutPower",
  "InErrorWithoutVigency ",
];
