import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { remapFilter } from "./hired-offers.filter-utils";
import { hiredOffersGrid } from "./hired-offers.grid";
import { Offer } from "./hired-offers.types";

const dataSource = dataSourceCustomStoreGenerator<Offer>((loadOptions) =>
  service.adminBankinghub.card
    .getOffers(remapFilter(loadOptions))
    .then(({ data }) => ({
      data: data.offers,
      totalCount: data.numberOfRecords,
    }))
    .catch(() => {
      const errorMessage = "Erro ao buscar as contratações";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const HiredOffers = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contratações" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={hiredOffersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
