import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useBatchTaxas } from "../../batch-taxas.context";
import { validationResultsGrid } from "./validation-results.grid";
import { addErrorsToTaxas } from "./validation-results.utils";

export const ValidationResults = () => {
  const { taxas, validationResults, clear, submit, submitLoading } =
    useBatchTaxas();

  const dataSource = useMemo(
    () => addErrorsToTaxas(taxas, validationResults),
    [taxas, validationResults],
  );

  const canSubmit = validationResults?.length === 0;

  return (
    <GridTemplate
      actions={
        canSubmit ? (
          <LoadingButton
            dataAction="validacao:botao:importar_titulos"
            dataLabel="importar_titulos"
            loading={submitLoading}
            onClick={() => submit()}
          >
            Importar taxas
          </LoadingButton>
        ) : (
          <OButton
            dataAction="validacao:botao:enviar_outro_arquivo"
            dataLabel="enviar_outro_arquivo"
            onClick={() => clear()}
            type="dark"
            outline
          >
            Enviar outro arquivo
          </OButton>
        )
      }
    >
      <ODataGridGenerator
        grid={validationResultsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
