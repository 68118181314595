import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const DashboardOriginatorSelector = () => {
  const form = useFormContext();
  const { setValue, watch } = form;
  const workflowConfigIdsWatcher = watch("workflowConfigIds");

  const { callService: getOriginators, value: originators } = useServiceCall(
    service.hubCreditManager.getOriginatorsByLead,
  );

  const getAllOriginators = useCallback(async () => {
    if (!workflowConfigIdsWatcher.length) return;

    await getOriginators({ workflowConfigIds: workflowConfigIdsWatcher });
  }, [getOriginators, workflowConfigIdsWatcher]);

  useEffect(() => {
    getAllOriginators();
  }, [getAllOriginators]);

  useEffect(() => {
    const idsFromOriginators =
      originators?.map((originator) => originator.identification) || [];
    setValue("originatorIds", idsFromOriginators);
  }, [originators, setValue]);

  return (
    <OCol xs={12}>
      <ORFieldSelectMultiple
        id="originatorIds"
        name="originatorIds"
        label="Originators"
        placeholder="Buscar Originadores"
        dataAction="dashboard_workflow_lead:multiselect:filtrar_originadores"
        dataLabel="filtrar_originadores"
        key={originators?.length}
      >
        {originators?.map((originator) => (
          <OOptionMultiple
            value={originator.identification}
            key={`${originator.identification}`}
          >
            {originator.name}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
    </OCol>
  );
};
