import { OButton } from "@maestro/react";
import { RowGap } from "../../../cards.styles";
import {
  IHeadedButtonIteration,
  IMiddleButtonIteration
} from "../chargeback.types";
import { OptionsContainer, QuestionContainer, Title } from "./compose.styles";
import { IQuestionButtonProps } from "./compose.types";

export const QuestionButton = ({
  questionIndex,
  data,
  registerAnswer,
  executeAction,
  isEditable,
}: IQuestionButtonProps) => {
  return (
    <QuestionContainer key={`${questionIndex}-question-container`}>
      <Title>
        {questionIndex + 1}. {data.title}
      </Title>
      {data.questions && (
        <OptionsContainer>
          {(
            data.questions as Array<
              IHeadedButtonIteration | IMiddleButtonIteration
            >
          ).map((question, index) => {
            return (
              <OButton
                outline={
                  data.response &&
                  data.response.includes(
                    question as IHeadedButtonIteration & IMiddleButtonIteration,
                  )
                }
                key={`${questionIndex}-question-option-${index}`}
                onClick={() => registerAnswer(question, questionIndex)}
                disabled={!isEditable}
                dataAction={""}
                dataLabel={""}
              >
                {question.label}
              </OButton>
            );
          })}
        </OptionsContainer>
      )}
      {"actions" in data && isEditable && (
        <RowGap columnGap={80}>
          {(data.actions as Array<IMiddleButtonIteration>).map(
            (action, index) => {
              return (
                <OButton
                  key={`${questionIndex}-action-option-${index}`}
                  onClick={() => {
                    executeAction(action.id, undefined);
                  }}
                  dataAction={`chargeback:botao:${action.label}`}
                  dataLabel={action.label}
                >
                  {action.label}
                </OButton>
              );
            },
          )}
        </RowGap>
      )}
    </QuestionContainer>
  );
};
