import { OToastManager } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { OTruncateTypography } from "components/o-truncate-typography";
import { StatusBadge } from "components/status-badge";
import { copyToClipboard } from "utils/copy";
import { PixKeyStatus } from "./pix-keys.type";
import {
  MapperPixKeyStatus,
  MapperPixKeyType,
  MapperPixKeyTypeToFormattedKeyValue,
  MapperPixKeysGridColumnWidth,
  formatPixKeyToCopy,
} from "./pix-keys.utils";

export const grid: ODataGridGeneratorConfig<BankingHub.PixKeyOption> = {
  datagrid: { noDataText: "Nenhuma chave pix cadastrada" },
  columns: [
    {
      dataField: "keyType",
      caption: "Tipo de chave",
      width: MapperPixKeysGridColumnWidth.KeyType,
      cellRender: ({ data }) => {
        const keyType = MapperPixKeyType[data.keyType];
        return (
          <OTruncateTypography title={keyType}>{keyType}</OTruncateTypography>
        );
      },
    },
    {
      dataField: "keyValue",
      caption: "Chave",
      width: MapperPixKeysGridColumnWidth.KeyValue,
      cellRender: ({ data }) => {
        const keyValue = MapperPixKeyTypeToFormattedKeyValue[data.keyType](
          data.keyValue,
        );

        return (
          <OTruncateTypography title={keyValue}>{keyValue}</OTruncateTypography>
        );
      },
    },
    {
      dataField: "agencyId",
      caption: "Agência",
      width: MapperPixKeysGridColumnWidth.AgencyId,
      allowSorting: true,
      cellRender: ({ data }) => {
        const formattedAgency = data.agencyId.padStart(4, "0");
        return (
          <OTruncateTypography title={formattedAgency}>
            {formattedAgency}
          </OTruncateTypography>
        );
      },
    },
    {
      dataField: "accountNumber",
      caption: "Conta",
      width: MapperPixKeysGridColumnWidth.AccountNumber,
      cellRender: ({ data }) => {
        const accountNumber = data.accountNumber.padStart(9, "0");
        const formattedAccountNumber = `${accountNumber.slice(
          0,
          -1,
        )}-${accountNumber.slice(-1)}`;
        return (
          <OTruncateTypography title={formattedAccountNumber}>
            {formattedAccountNumber}
          </OTruncateTypography>
        );
      },
    },
    {
      dataField: "status",
      caption: "Status",
      width: MapperPixKeysGridColumnWidth.Status,
      cellRender: ({ data }) => {
        const { status } = data;
        const { label, type } =
          MapperPixKeyStatus[status ?? PixKeyStatus.Registered];

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
    },
    {
      caption: "Ações",
      alignment: "right",
      width: "85px",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-pix" },
                label: "Copiar chave Pix",
                onClick: () => {
                  copyToClipboard(
                    formatPixKeyToCopy(data.keyValue, data.keyType),
                  );
                  OToastManager.success("Chave pix copiada com sucesso");
                },
              },
            ]}
          />
        );
      },
    },
  ],
};
