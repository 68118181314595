import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useDownloadContractTemplateFile = (
  templateId: string | number,
) => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const { callService, loading } = useServiceCall(
    service.quickfin.downloadCompanyContractTemplateFile,
  );

  const onDownloadFile = useCallback(
    async (file: { name: string }) => {
      const { success, response } = await callService(quickfinId, templateId);

      if (success) helpers.downloadBase64(response.data, file.name);
      else OToastManager.danger("Não foi possível fazer o download do arquivo");
    },
    [callService, quickfinId, templateId],
  );

  return { onDownloadFile, loading };
};
