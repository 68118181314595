import { OConfirmationModal } from "@maestro/react";
import { DataGrid } from "devextreme-react/data-grid";
import { GetAdminIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { useIncorrectOrdersModal } from "./incorrect-orders-modal.hook";

interface IncorrectOrdersModalProps {
  orders: GetAdminIncorrectOrdersResponse;
  closes?: boolean;
  gridRef: React.RefObject<DataGrid>;
}

export const IncorrectOrdersModal = ({
  orders,
  closes = false,
  gridRef,
}: IncorrectOrdersModalProps) => {
  const { loading, modalId, submitChanges } = useIncorrectOrdersModal(
    closes,
    gridRef,
  );

  return (
    <OConfirmationModal
      modalId={modalId}
      loading={loading}
      title={`Confirma a ${
        closes
          ? "alteração das ordens selecionadas para corrigido?"
          : "reativação das ordens selecionadas?"
      }`}
      onConfirm={() => submitChanges(orders)}
    />
  );
};
