import {
  OCol,
  OLabel,
  ORadio,
  ORFieldInputDateRange,
  ORFieldRadioGroup,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CadastroClienteContasByAccountByAgencyRouteParams } from "../../../../../routes/cadastro.route-params";
import { useAccountAdminStatement } from "./statement.hook";
import { radioDataExportFormats } from "./statement.utils";

export const AccountAdminStatement = () => {
  const { account, agency } =
    useParams<CadastroClienteContasByAccountByAgencyRouteParams>();
  if (!account || !agency) throw new Error("Invalid route params");

  const { submit, form, loading } = useAccountAdminStatement({
    account,
    agency,
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Como você deseja exportar o extrato ?" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="gerar_extrato:botao:exportar"
          dataLabel="exportar"
          onClick={submit}
        >
          Exportar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <div className="col col-sm-6">
                <ORFieldInputDateRange
                  id="date"
                  name="date"
                  aspect="flushed"
                  placeholder={["Data de inicio", "Data de fim"]}
                  size="md"
                  disabledDate={(dt) => {
                    const isoDate = new Date(dt.format("YYYY-MM-DD"));
                    const today = new Date();

                    return isoDate >= today;
                  }}
                />
              </div>
            </ORow>
            <ORow>
              <OCol sm={12} className="d-flex flex-column gap-3">
                <OTypography tag="h1" type="primary" size="lg">
                  Selecione o formato:
                </OTypography>
                <ORFieldRadioGroup
                  id="formatType"
                  name="formatType"
                  dataAction="gerar_extrato:seletor:selecionar_formato"
                  dataLabel="selecionar_formato"
                >
                  <div className="d-flex flex-column gap-1">
                    {radioDataExportFormats.map((format) => {
                      return (
                        <div
                          className="d-flex align-items-center gap-2"
                          key={format.value}
                        >
                          <ORadio
                            id={format.value}
                            name={format.value}
                            value={format.value}
                            size="xs"
                          />
                          <OLabel htmlFor={format.value} size="md">
                            {format.label}
                          </OLabel>
                        </div>
                      );
                    })}
                  </div>
                </ORFieldRadioGroup>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
