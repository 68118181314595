import * as yup from "yup";

export const conditionFormValidationSchema = yup.object().shape({
  conditionName: yup
    .string()
    .trim()
    .required("Campo obrigatório")
    .matches(
      /^[^`~!@#$%^&*()_+={}[\]|\\:;“’<,>.?๐฿ ç0-9]*$/,
      "O campo não deve conter números, caracteres especiais ou espaços",
    ),
  description: yup.string().trim().required("Campo obrigatório"),
  expression: yup.string().trim().required("Campo obrigatório"),
});

export const conditionFormDefaultValues = {
  conditionName: "",
  description: "",
  expression: "",
};
