import { modalManager, OToastManager } from "@maestro/core";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  PostAdminCancelableReceivablesBody,
  PostAdminCancelableReceivablesResponse,
} from "services/quickfin/models";
import { service } from "services/service";
import { cancelingReceivablesDefaultValues } from "./canceling.form";
import { CancelingReceivablesForm } from "./canceling.types";
import { cancelingFilterModalId } from "./canceling.utils";

export const useCanceling = () => {
  const [selectedReceivables, setSelectedReceivables] =
    useState<PostAdminCancelableReceivablesResponse["cancelableReceivables"]>();
  const [payload, setPayload] = useState<PostAdminCancelableReceivablesBody>();
  const form = useForm<CancelingReceivablesForm>({
    defaultValues: cancelingReceivablesDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const gridRef = useGridRef();

  const { callService, loading, value } = useServiceCall(
    service.quickfin.postCancelableReceivables,
  );

  const receivables = value?.cancelableReceivables;

  const onSubmit = handleSubmit(async (values) => {
    const body: PostAdminCancelableReceivablesBody = {
      borderoId: values.borderoId ?? "",
      cedenteIdList: values.cedenteIdList,
      fromCreatedAt: values.createdAt
        ? values.createdAt[0].format("YYYY/MM/DD")
        : null,
      fromMaturityDate: values.maturityDate
        ? values.maturityDate[0].format("YYYY/MM/DD")
        : null,
      fromUpdatedAt: values.updatedAt
        ? values.updatedAt[0].format("YYYY/MM/DD")
        : null,
      receivableId: values.receivableId ?? "",
      receivableIdentification: values.receivableIdentification ?? "",
      receivableStatusList: values.receivableStatusList ?? [],
      sacadoIdList: values.sacadoIdList,
      toCreatedAt: values.createdAt
        ? values.createdAt[1].format("YYYY/MM/DD")
        : null,
      toMaturityDate: values.maturityDate
        ? values.maturityDate[1].format("YYYY/MM/DD")
        : null,
      toUpdatedAt: values.updatedAt
        ? values.updatedAt[1].format("YYYY/MM/DD")
        : null,
      type: values.type,
    };

    setPayload(body);
  });

  const updateValue = useCallback(async () => {
    if (payload) {
      const { success } = await callService(payload);

      if (success) {
        modalManager.hide(cancelingFilterModalId);
      } else {
        OToastManager.danger("Erro ao buscar os sacados canceláveis");
      }
    }
  }, [callService, payload]);

  const resetForm = () => {
    reset(cancelingReceivablesDefaultValues);
  };

  useEffect(() => {
    updateValue();
  }, [updateValue]);

  return {
    form,
    gridRef,
    loading,
    onSubmit,
    receivables,
    resetForm,
    selectedReceivables,
    setSelectedReceivables,
    updateValue,
  };
};
