import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../../routes/ferramentas.route-params";
import { DeskAccountConfigForm } from "../../_compose/desk-account-config-form.component";
import {
  DeskAccountConfigFormValues,
  deskAccountConfigFormValidationSchema,
} from "../../_compose/desk-account-config.form";

export const UpdateDeskAccountConfigPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DeskAccountConfigFormValues>({
    resolver: yupResolver(deskAccountConfigFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskAccountConfigDetails,
    loading,
    hasError,
    value: deskAccountConfig,
  } = useServiceCall(service.onboarding.getDeskOnboardingAccountConfigDetails);
  const { callService: updateDeskAccountConfig, loading: submitLoading } =
    useServiceCall(service.onboarding.updateDeskOnboardingAccountConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskAccountConfig({
          ...values,
          deskOnboardingAccountConfigUuid: uuid,
        });

        if (!success)
          return OToastManager.danger("Erro ao atualizar relacionamento");

        return OToastManager.success("Relacionamento atualizada com sucesso");
      }),
    [handleSubmit, updateDeskAccountConfig, uuid],
  );

  useEffect(() => {
    getDeskAccountConfigDetails(uuid);
  }, [getDeskAccountConfigDetails, uuid]);

  useEffect(() => {
    deskAccountConfig &&
      reset({
        ...deskAccountConfig,
        deskOnboardingConfigIdentifier:
          deskAccountConfig.deskOnboardingConfigConfigIdentifier,
      });
  }, [deskAccountConfig, reset]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar relacionamento informação de conta/configuração" />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar relacionamento"
            callback={() => getDeskAccountConfigDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskAccountConfigForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
