import styled from "styled-components";
import { NodeInfoContentProps } from "./node-inspector.types";

export const InspectorContent = styled.div`
  pointer-events: none;
  font-size: 10px;
`;

export const NodeInfoContent = styled.div<NodeInfoContentProps>`
  position: absolute;
  top: 5px;
  transform: ${(props) => props.transform};
  width: ${(props) => props.width};
`;
