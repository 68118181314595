import dayjs from "dayjs";
import { OInputDate } from "@maestro/react";
import { CalendarDateInputProps } from "./calendar-date-input.types";

export const CalendarDateInput: React.FC<CalendarDateInputProps> = (props) => {
  const { setValue, data, disabledDates } = props;

  const disabledDate = (date: moment.Moment) => {
    const ods = disabledDates.map((d) => dayjs(d).format("DD/MM/YYYY"));
    return ods.includes(date.format("DD/MM/YYYY"));
  };

  return (
    <OInputDate
      picker="date"
      disabledDate={disabledDate}
      value={data?.date ? data.date.toISOString() : undefined}
      onChange={setValue}
    />
  );
};
