import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomerSelectorItem } from "../customer-selector.types";
import {
  compareItems,
  filterDuplicatedItems,
  loadFavoritesFromStorage,
  saveFavoritesToStorage,
} from "../utils";

interface FavoritesContext {
  favorites: CustomerSelectorItem[];
  addFavorite: (item: CustomerSelectorItem) => void;
  removeFavorite: (item: CustomerSelectorItem) => void;
}

const favoritesContext = createContext({} as FavoritesContext);

interface FavoritesProps {
  children: React.ReactNode;
}

export const FavoritesProvider = ({ children }: FavoritesProps) => {
  const [favorites, setFavorites] = useState<CustomerSelectorItem[]>(
    loadFavoritesFromStorage(),
  );

  /** Update localstorage when state changes */
  useEffect(() => {
    saveFavoritesToStorage(favorites);
  }, [favorites]);

  const addFavorite = useCallback((item: CustomerSelectorItem) => {
    setFavorites((_favorites) =>
      filterDuplicatedItems([..._favorites, { ...item, isFavorite: true }]),
    );
  }, []);

  const removeFavorite = useCallback((item: CustomerSelectorItem) => {
    setFavorites((_favorites) =>
      _favorites.filter((favorite) => !compareItems(favorite, item)),
    );
  }, []);

  const value = useMemo(
    () => ({
      favorites,
      addFavorite,
      removeFavorite,
    }),
    [addFavorite, favorites, removeFavorite],
  );

  return (
    <favoritesContext.Provider value={value}>
      {children}
    </favoritesContext.Provider>
  );
};

export const useFavorites = () => useContext(favoritesContext);
