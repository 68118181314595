import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { configurableStatusGrid } from "./configurable-status.grid";

export const WorkflowConfigurableStatusPage = () => {
  return (
    <DetailsTemplate pageTitle={<PageTitle title="Status" />}>
      <GridTemplate
        actions={
          <>
            <RouterButton
              role={roles.workflow.product.configurationsAddStatusPage.role}
              href={
                corporateRouter.routes.workflow.product.configurations.status
                  .create.path
              }
            >
              Adicionar
            </RouterButton>
            <RefreshGridButton
              onClick={() => {
                !configurableStatusGrid.datagrid.dataSource?.isLoading() &&
                  configurableStatusGrid.datagrid.dataSource?.reload();
              }}
            />
          </>
        }
      >
        <ODataGridGenerator grid={configurableStatusGrid} />
      </GridTemplate>
    </DetailsTemplate>
  );
};
