import { client } from "../../client";
import { ApiBaseResponse } from "../acc";
import { accEndpoints } from "../acc.endpoints";
import { GetAdminCustomerCounterpartiesDetailsResponse } from "../models/responses/get-admin-acc-counterparties-details.response";
import { GetAdminCustomerOrderDetailsResponse } from "../models/responses/get-admin-acc-order-details.response";
import { CustomerAccSettlement } from "../models/responses/get-admin-settlements.response";
import { PostSimulateValue } from "../models/responses/get-admin-simulate-preticket.response";
import { AdminAccSimulateReponse } from "../models/responses/get-admin-simulate.response";
import { AccProposal } from "../models/types/acc-proposal.model";
import { AdminAccTradeResponse } from "../models/types/acc-trades/acc-trades.model";

export const accAdminCustomerService = {
  getAccCounterpartiesDetails() {
    return client.get<
      ApiBaseResponse<GetAdminCustomerCounterpartiesDetailsResponse>
    >(accEndpoints.admin.customer.counterpartiesDetails);
  },
  getAccTrades() {
    return client.get<ApiBaseResponse<AdminAccTradeResponse[]>>(
      accEndpoints.admin.customer.trades,
    );
  },
  getAccTradesById(id: string) {
    return client.get<ApiBaseResponse<AdminAccTradeResponse>>(
      accEndpoints.admin.customer.tradesById(id),
    );
  },
  postAccSimulate(correlationId: string) {
    return client.post<ApiBaseResponse<AdminAccSimulateReponse>>(
      accEndpoints.admin.customer.simulate,
      { correlationId },
    );
  },
  postAccSimulateSettlement(preTicketId: string) {
    return client.post<ApiBaseResponse<PostSimulateValue>>(
      accEndpoints.admin.customer.simulateSettlement,
      { preTicketId },
    );
  },
  getAccPreticketById(id: string) {
    return client.get<ApiBaseResponse<CustomerAccSettlement>>(
      accEndpoints.admin.customer.preticketById(id),
    );
  },
  getAccOrderById(id: string) {
    return client.get<ApiBaseResponse<GetAdminCustomerOrderDetailsResponse>>(
      accEndpoints.admin.customer.orderById(id),
    );
  },
  getAccProposals() {
    return client.get<ApiBaseResponse<AccProposal[]>>(
      accEndpoints.admin.customer.proposals,
    );
  },
};
