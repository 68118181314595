import { useServiceCall } from "hooks/service-call";
import { service } from "services";

export const useRetryOptin = () => {
  const { callService: putRetryOptin, hasError: putRetryOptinHasError } =
    useServiceCall(service.recebiveisCartao.putRetryOptin);

  return {
    putRetryOptin,
    putRetryOptinHasError,
  };
};
