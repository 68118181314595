import { OConfirmationModal } from "@maestro/react";

type ConfirmationModalProps = {
  id: string;
  onConfirm: () => void;
  job: string;
};

export const ConfirmationModal = ({
  id,
  job,
  onConfirm,
}: ConfirmationModalProps) => (
  <OConfirmationModal
    style={{ position: "absolute" }}
    modalId={id}
    title="Disparo de novo job"
    confirmLabel="Sim"
    closeLabel="Cancelar"
    onConfirm={onConfirm}
  >
    <div>Tem certeza que deseja disparar o job {job}?</div>
  </OConfirmationModal>
);
