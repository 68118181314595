import { OInputDateRange } from "@maestro/react";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { logger } from "utils/logger";
import { DateRangeSectionFilter } from "../../../../side-filter-bar.types";

type OInputDateRangeOnChangeValueType = [dayjs.Dayjs, dayjs.Dayjs] | null;

type DateRangeProps = DateRangeSectionFilter;

export const DateRange = ({ formId }: DateRangeProps) => {
  const { watch, setValue } = useFormContext();

  const fieldValueWatched = watch(formId);

  // TODO: watch fix/improvement in OInputDateRange MAESTRO component wrapper position in sidebar initial render
  const [
    needToRerenderOInputDateRangeWrapper,
    setNeedToReenderOInputDateRangeWrapper,
  ] = useState(true);

  const simulateOInputDateRangeMouseClick = useCallback(() => {
    try {
      const element = document.body
        .getElementsByClassName(
          "o-input-date o-input-date-range o-input-date-input--flushed o-input-date-input--size-md",
        )
        .item(0);
      if (!element) return;
      ["mousedown", "click", "mouseup"].forEach((mouseEventType) =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          }),
        ),
      );
    } catch (error) {
      logger.error(error);
    }
  }, []);

  const onChange = useCallback(
    (dates: OInputDateRangeOnChangeValueType) => {
      const [firstDate, secondDate] = dates ?? [];

      // The OInputDateRange component provides dates in Moment, but it is necessary to convert to DayJs
      const datesInDayJs = [dayjs(firstDate), dayjs(secondDate)];

      const newValue = !!firstDate && !!secondDate ? datesInDayJs : undefined;

      setValue(formId, newValue);
    },
    [formId, setValue],
  );

  return (
    <OInputDateRange
      key={
        needToRerenderOInputDateRangeWrapper
          ? "before-rerender-wrapper"
          : "after-rerender-wrapper"
      }
      onFocus={() => {
        if (needToRerenderOInputDateRangeWrapper) {
          setNeedToReenderOInputDateRangeWrapper(false);
          setTimeout(simulateOInputDateRangeMouseClick, 500);
        }
      }}
      onChange={(dates) => onChange(dates as OInputDateRangeOnChangeValueType)}
      placeholder={["Data de Início", "Data de Término"]}
      size="md"
      value={fieldValueWatched}
    />
  );
};
