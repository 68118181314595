import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";
import { logger } from "utils/logger";
import { ReceivableForBordero } from "./batch-upload-borderos.types";
import { useProcessBorderos } from "./process-borderos.hook";
import { useVerifyBorderos } from "./verify-borderos.hook";

interface BatchUploadBorderosContext {
  clear: () => unknown;
  receivables: ReceivableForBordero[] | undefined;
  setReceivables: (receivables: ReceivableForBordero[]) => void;
  submit: () => unknown;
  submitLoading: boolean;
  validationHasError: boolean;
  validationLoading: boolean;
  validationResults: PostCompaniesByIdBorderosVerifyErrorResponse | undefined;
  verifyBorderos: () => unknown;
  bypassValidation: boolean;
  setBypassValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const batchUploadBorderosContext = createContext(
  {} as BatchUploadBorderosContext,
);

interface BatchUploadBorderosProviderProps {
  children: React.ReactNode;
}

export const BatchUploadBorderosProvider = ({
  children,
}: BatchUploadBorderosProviderProps) => {
  const [bypassValidation, setBypassValidation] = useState<boolean>(false);
  const [receivables, setReceivables] = useState<ReceivableForBordero[]>();

  const {
    clear: validationClear,
    verifyBorderos: _verifyBorderos,
    hasError: validationHasError,
    loading: validationLoading,
    validationResults,
  } = useVerifyBorderos();

  const { callService: processBorderos, loading: submitLoading } =
    useProcessBorderos();

  const verifyBorderos = useCallback(() => {
    // Neither of these ifs should happen
    if (!receivables) {
      logger.error("No receivables");
      return;
    }
    return _verifyBorderos(bypassValidation, {
      receivables: receivables as Required<ReceivableForBordero>[],
    });
  }, [_verifyBorderos, bypassValidation, receivables]);

  const clear = useCallback(() => {
    setReceivables(undefined);
    validationClear();
  }, [validationClear]);

  const submit = useCallback(async () => {
    // Neither of these ifs should happen
    if (!receivables) {
      logger.error("No receivables");
      return;
    }

    await processBorderos({
      receivables: receivables as Required<ReceivableForBordero>[],
    });
  }, [receivables, processBorderos]);

  useEffect(() => {
    if (receivables) verifyBorderos();
  }, [receivables, submit, verifyBorderos]);

  const value = useMemo(
    () => ({
      clear,
      receivables,
      setReceivables,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifyBorderos,
      setBypassValidation,
      bypassValidation,
    }),
    [
      clear,
      receivables,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifyBorderos,
      setBypassValidation,
      bypassValidation,
    ],
  );

  return (
    <batchUploadBorderosContext.Provider value={value}>
      {children}
    </batchUploadBorderosContext.Provider>
  );
};

export const useBatchUploadBorderos = () =>
  useContext(batchUploadBorderosContext);
