import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCol,
  OFieldError,
  OIcon,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { service } from "services";
import {
  StepUpdateFormValues,
  stepUpdateFormValidationSchema,
} from "./step-update.form";
import { StepUpdateFormProps } from "./step-update.types";

export const StepUpdateModal = ({
  step,
  templateUuid,
}: StepUpdateFormProps) => {
  const form = useForm<StepUpdateFormValues>({
    resolver: yupResolver(stepUpdateFormValidationSchema),
  });
  const {
    control,
    formState: {
      errors: { accountsToReproveOnRedStep: accountsToReproveOnRedStepErrors },
    },
    reset,
    handleSubmit,
  } = form;

  const fieldArray = useFieldArray({
    control,
    name: "accountsToReproveOnRedStep",
  });

  const { append, remove, fields } = fieldArray;

  const {
    value: enums,
    callService: getEnums,
    loading: loadingEnums,
    hasError: hasErrorEnums,
  } = useServiceCall(service.onboarding.getDeskOnboardingAccountInfoEnums);

  const { callService: updateStepOnTemplate, loading: updateLoading } =
    useServiceCall(service.onboarding.updateStepOnTemplate);

  const updateStep = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!step) return;

        if (!templateUuid) return;

        const { success } = await updateStepOnTemplate({
          semaphoreStepTemplateUuid: step.pipelineUuid,
          ...values,
        });

        if (!success) return OToastManager.danger("Erro ao atualizar step");

        modalManager.hide("step-modal");
      }),
    [handleSubmit, step, templateUuid, updateStepOnTemplate],
  );

  useEffect(() => {
    getEnums();
  }, [getEnums]);

  useEffect(() => {
    step &&
      reset({
        accountsToReproveOnRedStep: step?.accountsToReproveOnRedStep ?? [],
      });
  }, [reset, step]);

  return (
    <>
      {updateLoading && <OLoader absolute backdrop />}
      <OModal id="step-modal" size="lg" position="center" rounded>
        <OModalHeader closeButton>
          <OTypography tag="h2" size="lg">
            Defina as contas a recusar caso o step tenha sido vermelho do Step:{" "}
            <i>{step?.description}</i>
          </OTypography>
        </OModalHeader>
        <OModalBody>
          <FormProvider {...form}>
            {!!accountsToReproveOnRedStepErrors?.message && (
              <OFieldError visible>
                {accountsToReproveOnRedStepErrors.message}
              </OFieldError>
            )}
            {fields.map((field, index) => (
              <div
                className="d-flex gap-4 flex-fill align-items-center"
                key={field.id}
              >
                <ORow className="flex-fill">
                  <OCol xs={12} lg={6}>
                    <ORFieldInput
                      label="Agência"
                      id={`accountsToReproveOnRedStep.${index}.branch`}
                      name={`accountsToReproveOnRedStep.${index}.branch`}
                      tag="number"
                    />
                  </OCol>

                  <OCol xs={12} lg={6} align="center" className="gap-2">
                    <ORFieldSelect
                      label="Tipo de conta"
                      id={`accountsToReproveOnRedStep.${index}.accountType`}
                      name={`accountsToReproveOnRedStep.${index}.accountType`}
                      placeholder="Selecione"
                      disabled={loadingEnums}
                      key={enums?.accountTypes?.length}
                    >
                      {enums?.accountTypes?.map((accountType) => (
                        <OOption key={accountType} value={accountType}>
                          {accountType}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                    {loadingEnums && <OLoader className="d-block" size="sm" />}
                    {hasErrorEnums && (
                      <OButton
                        type="default"
                        outline
                        onClick={() => getEnums()}
                      >
                        <OIcon
                          category="fa"
                          icon="fa-repeat"
                          size="sm"
                          type="default"
                        />
                      </OButton>
                    )}
                  </OCol>
                </ORow>

                <OButton type="danger" onClick={() => remove(index)}>
                  <OIcon category="fa" icon="fa-trash" />
                </OButton>
              </div>
            ))}

            <div className="d-flex justify-content-end">
              <OButton
                onClick={() =>
                  append(
                    {} as StepUpdateFormValues["accountsToReproveOnRedStep"][number],
                  )
                }
              >
                <OIcon category="fa" icon="fa-plus" />
              </OButton>
            </div>
          </FormProvider>
        </OModalBody>
        <OModalFooter>
          <div className="w-100 d-flex justify-content-end gap-2">
            <OButton
              onClick={async () => {
                await updateStep();
              }}
            >
              Atualizar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
    </>
  );
};
