import { OCardBody, OCol, ORow } from "@maestro/react";
import { PortfolioManagementReceivables } from "services/moana/models/responses/portfolio-management.response";
import { PortfolioManagement } from "./portfolio-management.component";
import { useCallback } from "react";

interface PortfolioManagementProps {
  receivables?: PortfolioManagementReceivables;
  selectedRanges: number[];
  setSelectedRanges: (r: number[]) => void;
  totalItems: number;
  setTotalItems: (r: number) => void;
}

export const PortfolioManagementCard = ({
  receivables,
  selectedRanges,
  setSelectedRanges,
  totalItems,
  setTotalItems,
}: PortfolioManagementProps) => {
  const tryGetValue = useCallback(
    (key: number) => {
      if (!receivables) return;
      return receivables[key];
    },
    [receivables],
  );

  return (
    <>
      {receivables && (
        <>
          <OCardBody className="position-relative">
            <ORow>
              <OCol>
                <PortfolioManagement
                  title="A vencer em 30 dias"
                  receivablesInRange={tryGetValue(0)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={0}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="A vencer de 31 a 60 dias"
                  receivablesInRange={tryGetValue(1)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={30}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="A vencer de 61 a 90 dias"
                  receivablesInRange={tryGetValue(2)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={60}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="A vencer de 91 a 120 dias"
                  receivablesInRange={tryGetValue(3)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={90}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="A vencer de 121 a 150 dias"
                  receivablesInRange={tryGetValue(4)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={120}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="A vencer de 151 a 180 dias"
                  receivablesInRange={tryGetValue(5)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={150}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
              <OCol>
                <PortfolioManagement
                  title="Vencidos"
                  receivablesInRange={tryGetValue(-1)}
                  selectedRanges={selectedRanges}
                  setSelectedRanges={setSelectedRanges}
                  range={-1}
                  totalItems={totalItems}
                  setTotalItems={setTotalItems}
                />
              </OCol>
            </ORow>
          </OCardBody>
        </>
      )}
    </>
  );
};
