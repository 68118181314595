import * as yup from "yup";

export const commonDomainFormValidationSchema = yup.object().shape({
  key: yup.string().required("Campo obrigatório"),
  value: yup.string().required("Campo obrigatório"),
});

export const commonDomainFormDefaultValues = {
  key: "",
  value: "",
};