import { OInputNumber, OLabel, OSwitch } from "@maestro/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  IntervalCountProps,
  OInputCountId,
  OInputCountLabel,
} from "./interval-count.types";

export const IntervalCount = ({ formId }: IntervalCountProps) => {
  const [isRangeActive, setIsRangeActive] = useState(false);
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const [minCount, maxCount] = watch(formId) || [];

  const isValidMaxValue = useMemo(() => {
    if (!isRangeActive) {
      return true;
    }

    return maxCount >= minCount;
  }, [maxCount, minCount, isRangeActive]);

  useEffect(() => {
    if (!isValidMaxValue)
      setError(formId, {
        message: "O valor máximo não pode ser superior ao mínimo.",
      });
    else clearErrors(formId);
  }, [isValidMaxValue, formId, setError, clearErrors]);

  const handleInput = useCallback(() => {
    setIsRangeActive(!isRangeActive);
    clearErrors(formId);
    setValue(formId, [1, 1]);
  }, [setIsRangeActive, isRangeActive, clearErrors, setValue, formId]);

  const handleChange = useCallback(
    (fieldId: string, value?: number) => {
      if (fieldId === OInputCountId.MIN_COUNT && !isRangeActive) {
        setValue(formId, [Number(value), Number(value)]);
        return;
      }

      setValue(
        formId,
        fieldId === OInputCountId.MIN_COUNT
          ? [Number(value), maxCount ?? 0]
          : [minCount ?? 0, Number(value)],
      );
    },
    [formId, minCount, maxCount, isRangeActive, setValue],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex align-items-center justify-content-between">
        <OLabel htmlFor="count-range-active" type="dark-80">
          Intervalo
        </OLabel>
        <OSwitch
          id="count-range-active"
          size="xs"
          value={isRangeActive}
          onInput={handleInput}
        />
      </div>
      <div className="d-flex justify-content-between gap-5">
        <div className="flex-fill d-flex flex-column gap-2">
          <OInputNumber
            id={OInputCountId.MIN_COUNT}
            name={OInputCountId.MIN_COUNT}
            value={minCount}
            onInput={(e) => {
              const value = (e.target as HTMLOInputNumberElement)?.value;
              handleChange(OInputCountId.MIN_COUNT, value);
            }}
            error={!isValidMaxValue}
          />
          {isRangeActive && (
            <OLabel size="sm" htmlFor={OInputCountId.MIN_COUNT} type="dark-80">
              {OInputCountLabel.MIN_COUNT}
            </OLabel>
          )}
        </div>
        {isRangeActive && (
          <div className="flex-fill d-flex flex-column align-items-end gap-2">
            <OInputNumber
              id={OInputCountId.MAX_COUNT}
              name={OInputCountId.MAX_COUNT}
              value={maxCount}
              onInput={(e) => {
                const value = (e.target as HTMLOInputNumberElement)?.value;
                handleChange(OInputCountId.MAX_COUNT, value);
              }}
              error={!isValidMaxValue}
            />
            <OLabel size="sm" htmlFor={OInputCountId.MAX_COUNT} type="dark-80">
              {OInputCountLabel.MAX_COUNT}
            </OLabel>
          </div>
        )}
      </div>
    </div>
  );
};
