import { masks } from "@maestro/utils";
import { dateFormatter } from "../../utils/formatters/date";
import { MapperCardModalityAndType } from "../already-approved/already-approved.types";
import { capitalizePhraseFirstLetters } from "./cases";

const DeliveryAddressSequence = [
  "street",
  "number",
  "complement",
  "neighborhood",
  "city",
  "state",
  "zipCode",
];

const DeliveryAddressPontuationSequence = [
  ", ",
  " - ",
  " - ",
  ", ",
  " ",
  " - CEP: ",
  "",
];

export const setDeliveryAddressCardInfo = (info: Record<string, string>) => {
  const address: Array<string> = DeliveryAddressSequence.map(
    (key, index) =>
      (info[key as keyof typeof info] &&
        `${info[key as keyof typeof info]}${
          DeliveryAddressPontuationSequence[index]
        }`) ||
      "",
  );
  return address.join("");
};

export const setInfoById = (info: any, id: string, subKey?: string) => {
  if (!info) return "";
  if (id === "owner" || id === "printedName" || id === "variant") {
    return capitalizePhraseFirstLetters(
      (subKey && info[subKey as keyof typeof info]) || info,
    );
  }
  if (id === "modality") {
    return MapperCardModalityAndType[
      (subKey && info[subKey as keyof typeof info]) ||
        (info as keyof typeof MapperCardModalityAndType)
    ];
  }
  if (id === "type") {
    return MapperCardModalityAndType[
      (subKey && info[subKey as keyof typeof info]) ||
        (info as keyof typeof MapperCardModalityAndType)
    ];
  }
  if (id === "activatedAt" || id === "dueDate" || id === "bestTransactionDay") {
    return dateFormatter((subKey && info[subKey as keyof typeof info]) || info);
  }
  if (id === "address") {
    return setDeliveryAddressCardInfo(
      (subKey && info[subKey as keyof typeof info]) || info,
    );
  }
  if (id === "debitAccount") {
    const value = info[subKey as keyof typeof info];
    if (!value) return "";
    return `CC. ${masks.generic(value.padStart(9, "0"), {
      mask: "00000000-0",
    })}`;
  }
};
