export interface IconProps {
  className: string;
  size: HTMLOIconElement["size"];
  type: HTMLOIconElement["type"];
  category: HTMLOIconElement["category"];
  icon: HTMLOIconElement["icon"];
}

export type OfferGuarantorItemRowData = {
  text: string;
  iconProps: IconProps;
};

export enum OfferGuarantorItemRow {
  MailAndPhone,
  Address,
  Biometry,
}

export enum OfferGuarantorProperty {
  Email = "email",
  PhoneNumber = "phoneNumber",
  Address = "addresses",
  Document = "documents",
  Biometry = "biometryStatusType",
}

export type OfferGuarantorPropertyValidation = {
  isValid: boolean;
  error: BankingHubCards.OfferGuarantorError | undefined;
};

export interface AssembleOIconPropsParams {
  type: Exclude<HTMLOIconElement["type"], undefined>;
  category?: HTMLOIconElement["category"];
  icon?: HTMLOIconElement["icon"];
  size?: HTMLOIconElement["size"];
}
