import { OToastManager, modalManager } from "@maestro/react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { service } from "services/service";
import { GetAdminCompanyFlagsResponseItem } from "services/quickfin/models";

export const useRestrictionsList = () => {
  const gridRef = useGridRef();
  const [confirmDelete, setConfirmDelete] = useState<GetAdminCompanyFlagsResponseItem>();

  const dataSource = useMemo(
    () => dataSourceCustomStoreGenerator<GetAdminCompanyFlagsResponseItem>(() =>
        service.quickfin
          .getAdminCompanyFlags()
          .then(({ data }) => data)
          .catch(() => {
            const errorMessage = "Erro ao buscar lista de restrições";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    []
  );
  
  const onDelete = (item: GetAdminCompanyFlagsResponseItem) => {
    setConfirmDelete(item);
    setTimeout(() => modalManager.show("confirm-remove-modal"), 500);
  };

  return {
    dataSource,
    gridRef,
    confirmDelete,
    setConfirmDelete,
    onDelete,
  };
};
