export const modalModeMap = {
  CREATE: {
    isCreation: true,
    modalTitle: "Adicionar card",
    modalAction: "Adicionar",
  },
  UPDATE: {
    isCreation: false,
    modalTitle: "Editar card",
    modalAction: "Editar",
  },
};
