import * as yup from "yup";

export type AddProductFormFields = Omit<HubPartners.Admin.Product, "uuid">;

export const addProductSchema: yup.ObjectSchema<AddProductFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    description: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
  });
