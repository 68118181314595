import { OCardBody, OIcon, OTypography } from "@maestro/react";
import { CardButton } from "./info-pad.styles";
import { IInfoPadProps } from "./info-pad.types";

export const InfoPad = ({
  label,
  infos,
  icon,
  button,
  active,
}: IInfoPadProps) => {
  return (
    <CardButton
      isButton={!!button}
      type={active ? "info-light" : undefined}
      title={button ? button.title : label}
      onClick={button ? button.onClick : undefined}
    >
      <OCardBody>
        <div className="d-flex flex-column justify-content-between gap-3">
          <div className="d-flex flex-row justify-content-between gap-1">
            <OTypography type="dark" weight="500">
              {label}
            </OTypography>

            <OIcon
              category={icon.category}
              icon={icon.icon}
              size="xl"
              type={active ? "dark" : undefined}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            {infos.map((info) => (
              <OTypography key={info} type="dark" weight="300">
                {info}
              </OTypography>
            ))}
          </div>
        </div>
      </OCardBody>
    </CardButton>
  );
};
