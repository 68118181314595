import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoBatchRequestById } from "../../../../../../../routes/workflow.route-params";
import { useContingencyEdit } from "./contingencies-edit.hook";

const statusList = ["PROCESSED", "ERROR", "PENDING"];

export const ContingenciesEditPage = () => {
  const { id } = useParams<WorkflowProdutoBatchRequestById>();
  if (!id) throw new Error("No id in route parameters");

  const { submit, form, loading, watchStatus } = useContingencyEdit({ id });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar status do lote" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="envio-lote-contingencias:botao:editar"
          dataLabel="editar"
          onClick={submit}
        >
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol>
                <ORFieldSelect
                  id="status"
                  name="status"
                  dataAction="editar_batch_request:input:status"
                  dataLabel="status"
                  placeholder="Status"
                  label="Status"
                  value={watchStatus}
                >
                  {statusList.map((status) => (
                    <OOption key={status} id={status} value={status}>
                      {status}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
