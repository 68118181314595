import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { OptionChangedEventInfo } from "devextreme/core/dom_component";
import { GetOwnerReceivablesResponse } from "services/moana/models";
import { parseSortingToUrl } from "../../pages/produto/carteiras/wallets.utils";
import dxDataGrid from "devextreme/ui/data_grid";

export const grid: (
  setSortQuery: React.Dispatch<React.SetStateAction<string>>,
  rows: number,
) => ODataGridGeneratorConfig<GetOwnerReceivablesResponse["data"][number]> = (
  setSortQuery,
  rows,
) => ({
  datagrid: {
    noDataText: "Nenhuma cobrança encontrada",
    pager: { visible: false },
    paging: { pageSize: rows },
    onOptionChanged: (e) => {
      if (!!e.name && e.name === "columns") {
        setSortQuery(
          parseSortingToUrl(
            e as OptionChangedEventInfo<dxDataGrid<unknown, unknown>>,
          ),
        );
      }
    },
  },
  columns: [
    {
      dataField: "receivableNumber",
      caption: "Seu número",
    },
    {
      dataField: "payerName",
      caption: "Devedor",
    },
    {
      dataField: "payerDocumentNumber",
      caption: "Identificação do devedor",
      format: (value) => masks.cpfCnpj(value),
      allowSorting: false,
    },
    {
      dataField: "amountValue",
      caption: "Valor Vencimento",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "maturityDate",
      caption: "Data Vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "issueDate",
      caption: "Data Emissão",
      dataType: "date",
      format: "dd/MM/yyyy",
      sortOrder: "desc",
    },
    {
      dataField: "status",
      caption: "Status",
      allowSorting: false,
    },
    {
      dataField: "receivableType",
      caption: "Tipo",
      cellRender: ({ data }) =>
        data.receivableType === "1" ? "Boleto" : "Bolepix",
      allowSorting: false,
    },
  ],
});
