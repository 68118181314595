import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { NotifySignatoriesProps } from "./notify-signatories.types";
import { useNotifySignatories } from "./notify-signatories.hook";

export const NotifySignatories = ({ currentTrade }: NotifySignatoriesProps) => {
  const { loadingNotify, notifiySignatories } =
    useNotifySignatories(currentTrade);

  return (
    <ButtonWithConfirmation
      confirmationModal={{
        title: "Deseja notificar os assinantes de contratos desse trade?",
        loading: loadingNotify,
      }}
      disabled={loadingNotify}
      onConfirm={notifiySignatories}
    >
      Notificar assinantes
    </ButtonWithConfirmation>
  );
};
