export enum ContractType {
  PerOperation = "PorOperacao",
}

export interface CedenteDetailsForm {
  antecipaAutomaticamente: string;
  cge: string;
  companyEntityType: string;
  contractTemplateId: number | null;
  desativaFornecedor: string;
  estrategiaGUID: string;
  estrategiaId: number;
  identificadorExterno: string;
  identification: string;
  officialName: string;
  permiteOverrideEstrategia: string;
  receiptTemplateId: number | null;
  tipoContrato: string;
  tipoProduto: string;
  country: string;
  justificative?: string;
  cancelContract?: string;
  // helper for validation
  _originalDesativaFornecedor?: string;
  contractComplement?: {
    key: string;
    value: string;
  }[];
  contractComplementInclusionList: string[];
}
