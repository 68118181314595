import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { exchangeOrderEntriesGrid } from "../../../../components/orders/order-entries/order-entries.grid";
import { exchangeOrderDetailsGenerator } from "../../../../components/orders/order.details";
import { exchangePreTicketsGrid } from "../../../../components/pre-tickets/pre-tickets.grid";
import { exchangeQuotesGrid } from "../../../../components/quotes/quotes.grid";
import { CambioOrdensByIdRouteParams } from "../../../../routes/cambio.route-params";

export const ExchangeOrderDetailsPage = () => {
  const { id } = useParams<CambioOrdensByIdRouteParams>();

  if (!id) throw new Error("No id");

  const { mapSymbol } = useCurrencies();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [details, setDetails] =
    useState<
      Awaited<
        ReturnType<typeof service.hubFx.adminCustomer.getOrderDetails>
      >["data"]
    >();

  const getQuoteDetail = useCallback(async () => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      const { data } = await service.hubFx.adminCustomer.getOrderDetails(id);
      setDetails(data);
    } catch (error) {
      setErrorMessage("Erro ao buscar detalhes da ordem.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getQuoteDetail();
  }, [getQuoteDetail]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da Ordem" />}>
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loading}
          errorComponent={
            <ErrorComponent
              messageTitle={errorMessage}
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton onClick={() => getQuoteDetail()} />
            </ErrorComponent>
          }
          hasError={!!errorMessage}
          value={details}
          fields={exchangeOrderDetailsGenerator}
        />
        {!!details?.quotes?.length && (
          <DetailsCard
            value={details.quotes}
            fields={(quotes) => ({
              quotes: {
                title: "Operações",
                items: [
                  {
                    value: (
                      <ODataGridGenerator
                        grid={exchangeQuotesGrid(mapSymbol)}
                        dataSource={quotes}
                      />
                    ),
                  },
                ],
              },
            })}
          />
        )}
        {!!details?.preTickets?.length && (
          <DetailsCard
            value={details?.preTickets}
            fields={(preTickets) => ({
              preTickets: {
                title: "Pré-Tickets",
                items: [
                  {
                    value: (
                      <ODataGridGenerator
                        grid={exchangePreTicketsGrid(mapSymbol)}
                        dataSource={preTickets}
                      />
                    ),
                  },
                ],
              },
            })}
          />
        )}
        {!!details?.entries?.length && (
          <DetailsCard
            value={details.entries}
            fields={(entries) => ({
              orderEntries: {
                title: "Entradas da Ordem",
                items: [
                  {
                    value: (
                      <ODataGridGenerator
                        grid={exchangeOrderEntriesGrid(mapSymbol)}
                        dataSource={entries}
                      />
                    ),
                  },
                ],
              },
            })}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
