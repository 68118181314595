import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OContainer,
  OLoader,
  OModal,
  OModalBody,
  ORow,
  OToastManager,
  useModal,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowProdutoBatchRequestById } from "../../../../../../routes/workflow.route-params";

export const BatchRequestContingenciesPage = () => {
  const { id } = useParams<WorkflowProdutoBatchRequestById>();
  if (!id) throw new Error("No id");

  const [pageLoading, setPageLoading] = useState(false);

  const { hide, show } = useModal();

  const reprocessBatchFile = useCallback(async () => {
    try {
      setPageLoading(true);
      hide("reprocess-modal");
      await service.hubCreditManager.reprocessWorkflowLeadsBatchRequest(id!);
      OToastManager.success("Iremos reprocessar o arquivo em lote.");
    } catch (err) {
      OToastManager.danger(
        "Um erro ocorreu ao tentar processar arquivo. Por favor, tente novamente mais tarde.",
      );
    } finally {
      setPageLoading(false);
    }
  }, [hide, id]);

  const buildButtonLink =
    corporateRouter.routes.workflow.product.batchs.batchRequests.contigencies.statusEdit.path(
      {
        id,
      },
    );

  return (
    <>
      <div>
        {pageLoading && <OLoader backdrop absolute />}
        <PageTitle title="Contingências" />
        <OCard>
          <OCardBody className="position-relative">
            <OContainer>
              <ORow className="pb-3">
                <OCol sm={3}>Reprocessar arquivo do entuba</OCol>
                <OCol sm={2}>
                  <OButton
                    onClick={() => {
                      show("reprocess-modal");
                    }}
                    dataAction="envio-lote-contingencias:botao:reprocess-batch-file"
                    fullWidth
                    size="md"
                    dataLabel="reprocess-batch-file"
                  >
                    Reprocessar
                  </OButton>
                </OCol>
              </ORow>
              <ORow>
                <OCol sm={3}>Alterar status</OCol>
                <OCol sm={2}>
                  <RouterButton
                    dataAction="envio-lote-contingencias:botao:alterar-status"
                    size="md"
                    dataLabel="alterar-status"
                    fullWidth
                    href={buildButtonLink}
                  >
                    Alterar
                  </RouterButton>
                </OCol>
              </ORow>
            </OContainer>
          </OCardBody>
        </OCard>
      </div>
      <OModal id="reprocess-modal" position="center" backdrop size="xxs">
        <OModalBody className="pb-3">
          <OContainer>
            <ORow>
              <OCol sm={12} className="justify-content-center">
                <h3>Tem Certeza?</h3>
              </OCol>
            </ORow>
            <ORow>
              <OCol sm={6}>
                <OButton
                  outline
                  onClick={() => {
                    hide("reprocess-modal");
                  }}
                  dataAction="envio-lote-contingencias:botao:reprocess-batch-file"
                  fullWidth
                  size="md"
                  dataLabel="reprocess-batch-file"
                >
                  Cancelar
                </OButton>
              </OCol>
              <OCol sm={6}>
                <OButton
                  onClick={reprocessBatchFile}
                  dataAction="envio-lote-contingencias:botao:reprocess-batch-file"
                  fullWidth
                  size="md"
                  dataLabel="reprocess-batch-file"
                >
                  Confirmar
                </OButton>
              </OCol>
            </ORow>
          </OContainer>
        </OModalBody>
      </OModal>
    </>
  );
};
