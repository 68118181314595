import { OContainer } from "@maestro/react";
import { CardHiringProvider } from "./card-hiring.context";
import { FillCardHiring } from "./_compose";

export const CardHiring = () => {
  return (
    <CardHiringProvider>
      <OContainer type="sm">
        <FillCardHiring />
      </OContainer>
    </CardHiringProvider>
  );
};
