import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useAddBook } from "./add-book.hook";

export const AddBookPage = () => {
  const { addBook, form, loadingAddBook } = useAddBook();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar book" />}
      actions={
        <LoadingButton loading={loadingAddBook} onClick={handleSubmit(addBook)}>
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <form spellCheck="false">
                <ORow>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="bookName"
                      name="bookName"
                      label="Nome"
                      labelSize="md"
                      tag="text"
                      placeholder="Preencher"
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="bookCr"
                      name="bookCr"
                      label="CR"
                      labelSize="md"
                      tag="number"
                      placeholder="Preencher"
                    />
                  </OCol>
                </ORow>
              </form>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
