import { OIcon, OTypography } from "@maestro/react";
import { CustomTokens } from "../../../../../../components/augmented-status-badge/augmented-status-badge.styles";
import { Badge } from "./badges.style";

export const NotesBadge = () => {
  return (
    <>
      <CustomTokens />
      <Badge
        backgroundColor="var(--theme-custom-yellow-light)"
        className="px-2 d-flex align-items-center gap-2"
      >
        <OIcon
          style={{ color: "black" }}
          category="far"
          icon="fa-pencil"
          size="sm"
        />
        <OTypography style={{ color: "black" }} size="sm">
          Anotações
        </OTypography>
      </Badge>
    </>
  );
};
