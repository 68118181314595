import { OLoader, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { DetailsCard } from "components/details-card";
import { ErrorComponent } from "components/empty-state";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useLegacySalesforceUserHook } from "hooks/legacy-salesforce";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { logger } from "utils/logger";
import { Contract } from "../renegociacao-historico.type";
import { buildDetails } from "./renegociacao-historico-detalhes.details";

const cancelableStatuses = [
  "OPEN",
  "ERROR_BLOCKING",
  "BLOCKED",
  "ERROR_CREATE_FIRST_PAYMENT",
  "CREATED_FIRST_PAYMENT",
  "VALIDATED",
  "WAITING_SIGNATURES",
];

export const RenegociacoesHistoryDetails = () => {
  const { userData } = useLegacySalesforceUserHook();
  const [contract, setContract] = useState<Contract>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const redirectToResendBoletoSuccessPage = useCallback(
    (idAgreement: string) => {
      const { routes } = corporateRouter;
      const baseUrl =
        routes.renegociacao.customer.monitor.history.details.success.path;

      const stringifiedIdAgreement = JSON.stringify(idAgreement);
      searchParams.set("id-agreement", stringifiedIdAgreement);

      navigate(baseUrl + "?" + searchParams.toString());
    },
    [navigate, searchParams],
  );

  const sendBoleto = useCallback(async () => {
    if (!userData) {
      return;
    }

    try {
      OToastManager.info("Enviando boleto...");

      const payload = { email: userData.email };
      await service.renegotiation.postAgreementFirstPayment(
        contract?.id_agreement ?? "",
        payload,
      );

      redirectToResendBoletoSuccessPage(contract?.id_agreement ?? "");
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      OToastManager.danger("Erro ao efetuar o reenvio do boleto");
    }
  }, [contract?.id_agreement, redirectToResendBoletoSuccessPage, userData]);

  const handleResendBoleto = useCallback(async () => {
    setLoading(true);
    try {
      const agreement = await service.renegotiation
        .getAgreementDetails(contract?.id_agreement ?? "")
        .then(({ data }) => data);

      if (
        agreement.should_be_signed &&
        agreement.contract_status !== "Completed"
      ) {
        const title = "Atenção!";
        const message =
          "Esta renegociação necessita da assinatura de contrato via portal B2C. Foi enviado para o cliente um e-mail com as informações de assinatura juntamente com o contrato. O boleto de adesão será enviado apenas quando as assinaturas do contrato forem finalizadas.";
        OToastManager.warning({ title, message });
      } else {
        await sendBoleto();
      }
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      OToastManager.danger("Erro ao reenviar boleto");
    } finally {
      setLoading(false);
    }
  }, [contract?.id_agreement, sendBoleto]);

  const handleCancelAgreement = useCallback(async () => {
    setLoading(true);
    try {
      await service.renegotiation.deleteAgreement(contract?.id_agreement ?? "");

      OToastManager.success("Renegociação cancelada com sucesso!");
      navigate(
        corporateRouter.routes.renegociacao.customer.monitor.history.path,
      );
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      OToastManager.danger("Erro ao efetuar o cancelamento da renegociação!");
    } finally {
      setLoading(false);
    }
  }, [contract?.id_agreement, navigate]);

  useEffect(() => {
    const stringifiedContract = searchParams.get("contract-data");
    if (!stringifiedContract) {
      setHasError(true);
      return;
    }

    try {
      const parsedCustomer = JSON.parse(stringifiedContract);
      setContract(parsedCustomer);
    } catch (err) {
      if (!isAxiosError(err))
        logger.error(
          new Error(
            `Unable to parse customer from queryString: ${stringifiedContract}`,
          ),
        );
      setHasError(true);
    }
  }, [searchParams]);

  if (hasError) {
    return (
      <ErrorComponent
        messageTitle="Não foi possível encontrar o contrato"
        messageParagraph="Ocorreu um erro ao buscar o contrato de renegociação. Retorne à página anterior e tente novamente."
      />
    );
  }

  if (!contract) {
    return <OLoader absolute />;
  }

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do acordo" />}
      actions={
        <>
          {contract.status_code === "VALIDATED" && (
            <IconButton
              dataAction="detalhes_historico_renegociacao:botao:reenviar_boleto"
              dataLabel="reenviar_boleto"
              icon={{ category: "orq", icon: "orq-payroll" }}
              disabled={loading}
              onClick={() => handleResendBoleto()}
            >
              Reenviar boleto
            </IconButton>
          )}
          {cancelableStatuses.includes(contract.status_code) && (
            <IconButton
              dataAction="detalhes_historico_renegociacao:botao:cancelar_acordo"
              dataLabel="cancelar_acordo"
              icon={{ category: "orq", icon: "orq-close" }}
              disabled={loading}
              onClick={() => handleCancelAgreement()}
            >
              Cancelar
            </IconButton>
          )}
        </>
      }
    >
      <DetailsCard fields={buildDetails(contract)} />
    </DetailsTemplate>
  );
};
