import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams } from "../../../../routes/ferramentas.route-params";
import { profileManagementRequestsStatusMap } from "../profiles-management.utils";
import { profileRequestGrid } from "./profile-request.grid";

export const ProfileRequestPage = () => {
  const gridRef = useGridRef();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] =
    useState<
      Awaited<
        ReturnType<typeof service.gatekeeper.getProfileManagementRequestDetails>
      >["data"]
    >();
  const [canEdit, setCanEdit] = useState(false);
  const { requestId } =
    useParams<FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams>();

  const navigate = useNavigate();

  if (!requestId) throw new Error("No requestId");

  const { routes } = corporateRouter;

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          service.gatekeeper
            .getProfileManagementRequestDetails({
              requestId: +requestId,
            })
            .then(({ data }) => {
              setRequest(data);
              setCanEdit(
                data.status === "EM_CONSTRUCAO" ||
                  data.status === "PENDENTE_ALTERACAO",
              );
              return data.changes ?? [];
            })
            .catch(() => {
              throw new Error("Erro ao buscar detalhes da solicitação");
            }),
        {
          customStoreOptions: {
            remove: async (change) => {
              try {
                await service.gatekeeper.deleteProfileManagementRequestChange({
                  changeId: change.id,
                });
                OToastManager.success("Mudança removida com sucesso");
              } catch {
                OToastManager.danger("Erro ao remover mudança");
              }
            },
          },
        },
      ),
    [requestId],
  );

  const grid = useMemo(
    () => profileRequestGrid(requestId, canEdit),
    [canEdit, requestId],
  );

  const sendRequest = useCallback(async () => {
    try {
      setLoading(true);

      await service.gatekeeper.sendProfileManagementRequest({
        requestId: +requestId,
      });

      OToastManager.success("Solicitação enviada com sucesso");

      navigate(
        corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement
          .path,
      );
    } catch (err) {
      if (isAxiosError<{ message: string }>(err)) {
        return OToastManager.danger(
          err.response?.data?.message ?? "Erro ao enviar solicitação",
        );
      }
      OToastManager.danger("Erro ao enviar solicitação");
    } finally {
      setLoading(false);
    }
  }, [navigate, requestId]);

  return (
    <GridTemplate
      pageTitle={
        <>
          <PageTitle title={`Mudanças - ${request?.title ?? ""}`} />
          <OTypography
            type="dark"
            tag="p"
            className="mt-4 d-flex align-items-center gap-2"
          >
            Status da solicitação:
            {getValueFromMap(
              profileManagementRequestsStatusMap,
              request?.status,
            ) ?? request?.status}
          </OTypography>
        </>
      }
      actions={
        <>
          {canEdit && (
            <>
              <LoadingButton
                dataAction="ferramentas_gatekeeper_detalhes_solicitacao:botao:enviar_para_revisao"
                dataLabel="enviar_para_revisao"
                disabled={!request?.changes?.length}
                loading={loading}
                onClick={sendRequest}
              >
                Enviar para revisão
              </LoadingButton>
              <RouterButton
                dataAction="ferramentas_gatekeeper_detalhes_solicitacao:botao:nova_mudanca"
                dataLabel="nova_mudanca"
                href={routes.ferramentas.product.gatekeeper.profilesManagement.details.add.path(
                  { requestId },
                )}
              >
                Nova mudança
              </RouterButton>
            </>
          )}
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
