import { env } from "utils/environments";

export const fopaEndpoints = {
  checkCompanyExist: `${env.API_BANKINGHUB}/api/v1/Fopa/CheckCompanyExist`,
  payroll: {
    list: `${env.API_BANKINGHUB}/api/v1/Fopa/Payroll/List`,
    lines: `${env.API_BANKINGHUB}/api/v1/Fopa/Payroll/Lines`,
  },
  massify: {
    list: `${env.API_BANKINGHUB}/api/v1/Fopa/Massify/List`,
    filters: `${env.API_BANKINGHUB}/api/v1/Fopa/Massify/Employees/ListFilters`,
    employees: `${env.API_BANKINGHUB}/api/v1/Fopa/Massify/Employees/List`,
  },
};
