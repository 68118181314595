import { AlertMessages } from "../alert-messages";
import { MapperTrackingStatusToAlertMessages } from "./types";
import { ITrackingCard } from "../../tracking.types";
import { OCard, OCardBody } from "@maestro/react";

interface CardTrackingAlertProps {
  cardTracking: ITrackingCard;
  cardLast4Digits: string;
}

export const CardTrackingAlert = ({
  cardTracking,
  cardLast4Digits,
}: CardTrackingAlertProps): JSX.Element => {
  const [currentHistory] = cardTracking.history.slice(-1);
  const currentStatus = currentHistory.status;

  const alertMessages = MapperTrackingStatusToAlertMessages[currentStatus];

  if (!alertMessages) return <></>;

  const messages = alertMessages(cardLast4Digits);

  return (
    <OCard>
      <OCardBody>
        <AlertMessages messages={messages} />
      </OCardBody>
    </OCard>
  );
};
