import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  FranquiasFormValues,
  franquiasFormValidationSchema,
} from "../_compose";

export interface FranchiseName {
  name: string;
}
export const useUpdateFranchise = (id: string) => {
  const { value: name } = useParseFromSearchParams<FranchiseName>("data");

  const { callService: updateFranchise, loading: submitLoading } =
    useServiceCall(service.clerk.updateFranchise);

  const form = useForm<FranquiasFormValues>({
    resolver: yupResolver(franquiasFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    name && reset(name);
  }, [name, reset]);

  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateFranchise(Number(id), values);

        if (!success)
          return OToastManager.danger("Erro ao atualizar franquia.");

        navigate(corporateRouter.routes.franquias.product.franquias.path);
        return OToastManager.success("Franquia atualizada com sucesso!");
      }),
    [handleSubmit, id, navigate, updateFranchise],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
