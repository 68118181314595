import { modalManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { DenialReasonModal, denialReasonModalId } from "../denial-reason-modal";
import { useDenyButton } from "./deny-button.hook";

interface DenyButtonProps {
  successCallback: () => void;
  workflowMigrationId: string;
  disabled: boolean;
}

export const DenyButton = ({
  successCallback,
  workflowMigrationId,
  disabled,
}: DenyButtonProps) => {
  const { form, loading, reproveDeskMigration } = useDenyButton(
    workflowMigrationId,
    successCallback,
  );

  return (
    <>
      <LoadingButton
        dataAction="acoes:botao:reprovar"
        dataLabel="reprovar"
        loading={loading}
        disabled={disabled}
        onClick={() => modalManager.show(denialReasonModalId)}
        type="danger"
      >
        Reprovar
      </LoadingButton>
      <DenialReasonModal form={form} submit={reproveDeskMigration} />
    </>
  );
};
