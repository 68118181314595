import { PageTitle } from "components/page-title";
import {
  StepsBuilder,
  StepsBuilderProvider,
  useStepsBuilder,
} from "components/steps-builder";
import { DetailsTemplate } from "templates/details-template";
import { IntubateOperationsWithoutRegistryProvider } from "./intubate-operations-without-registry.context";

interface IntubateOperationsWithoutRegistryComponentProps {
  steps: {
    name: string;
    component: () => JSX.Element;
  }[];
}

const IntubateOperationsWithoutRegistryComponent = ({
  steps,
}: IntubateOperationsWithoutRegistryComponentProps) => {
  const { stepNumber } = useStepsBuilder();

  const CurrentStep = steps[stepNumber].component;

  const stepNames = steps.map(({ name }) => name);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Entuba de operações sem registro"
          description="Envie a lista de recebíveis a serem criados via Excel. Os campos necessários na planilha são: CNPJ do cedente, Credenciadora, CNPJ do sacado, Data de vencimento, Valor de vencimento, Bandeira e dados bancários (Banco, Agência, Conta e Dígito da Conta)."
        />
      }
    >
      <StepsBuilder steps={stepNames}>
        <CurrentStep />
      </StepsBuilder>
    </DetailsTemplate>
  );
};

export const IntubateOperationsWithoutRegistry = (
  props: IntubateOperationsWithoutRegistryComponentProps,
) => (
  <StepsBuilderProvider>
    <IntubateOperationsWithoutRegistryProvider>
      <IntubateOperationsWithoutRegistryComponent {...props} />
    </IntubateOperationsWithoutRegistryProvider>
  </StepsBuilderProvider>
);
