import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  lettersOfAttorneyGrid as electronicLettersOfAttorneyGrid,
} from "./electronic-letters-of-attorney.grid";

export const ElectronicLettersOfAttorneyPage = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Procurações eletrônicas" />}
      gridRef={gridRef}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.cadastro.customer.lettersOfAttorney
              .electronic.monitoring.path
          }
        >
          Monitoramento
        </RouterButton>
      }
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={electronicLettersOfAttorneyGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
