import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostCompaniesByIdContractTemplatesBody } from "services/quickfin/models";
import {
  ContractTemplateDetailsFormValues,
  contractTemplateDetailsFormValidationSchema,
} from "../../../../components/contract-templates/contract-template-details-form.schemas";

export const useAddCompanyContractTemplates = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const { callService: addContractTemplate, loading: submitLoading } =
    useServiceCall(service.quickfin.addContractTemplate);

  const navigate = useNavigate();

  const form = useForm<ContractTemplateDetailsFormValues>({
    resolver: yupResolver(contractTemplateDetailsFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addContractTemplate(quickfinId, {
          name: values.name,
          signatureOrigin: values.signatureOrigin,
          file: {
            originalName: values.file[0].name,
            fileContent: await helpers.convertFileToBase64(values.file[0]),
          },
          needsSignature: !!values.needsSignature?.length,
          geraNovosTermos: !!values.geraNovosTermos?.length,
          hasComplement: !!values.hasComplement?.length,
          signingParties: [
            {
              partyType: "Sacado",
              ...values.signingParties?.sacado,
              parties: values.signingParties?.sacado?.parties ?? [],
              companyId: quickfinId,
              validFor: values.signingParties?.sacado?.validFor ?? [],
            },
            {
              partyType: "Cedente",
              ...values.signingParties?.cedente,
              parties: values.signingParties?.cedente?.parties ?? [],
              companyId: quickfinId,
              validFor: values.signingParties?.cedente?.validFor ?? [],
            },
          ],
        } as PostCompaniesByIdContractTemplatesBody);

        if (success) {
          OToastManager.success("Template atualizado com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.customer.contractTemplates.path,
          );
        } else OToastManager.danger("Não foi possível atualizar o template");
      }),
    [addContractTemplate, handleSubmit, navigate, quickfinId],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
