import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import type { DataGrid } from "devextreme-react/data-grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildJobsGrid, dataSource } from "./jobs.grid";
import { Job } from "./jobs.types";
import {
  BatchRunButton,
  BatchStopButton,
  MasterDetailsButton,
} from "./_compose";

export const Jobs = () => {
  const [autoExpandAll, setAutoExpandAll] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);
  const gridRef = useRef<DataGrid<Job>>(null);

  // clear selection on load
  useEffect(() => {
    dataSource.on("changed", () => {
      gridRef.current?.instance.clearSelection();
      setSelectedJobs([]);
    });
  }, []);

  const grid = useMemo(
    () => buildJobsGrid(setSelectedJobs, autoExpandAll),
    [autoExpandAll],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Jobs de notificações" />}
      actions={
        <>
          <MasterDetailsButton
            autoExpandAll={autoExpandAll}
            setAutoExpandAll={setAutoExpandAll}
          />
          <BatchRunButton
            selectedJobs={selectedJobs}
            reload={() => dataSource.reload()}
          />
          <BatchStopButton
            selectedJobs={selectedJobs}
            reload={() => dataSource.reload()}
          />
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
