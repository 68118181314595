import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
// eslint-disable-next-line no-restricted-imports
import { isArray } from "lodash";
import dayjs from "dayjs";
import { CreditConciliation } from "./credit-conciliation.types";
import { useFidcFunds } from "../../../../hooks";

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array
        .map((val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        .join("&"),
    )
    .join("&");

export const buildQuery = (filters: Record<string, any>) =>
  Object.entries(filters)
    .filter(([key, val]) => key && val !== undefined && val !== null)
    .map(([key, val]) =>
      isArray(val)
        ? arrayToQuery({ [key]: val })
        : `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join("&");

export const useCreditConciliation = () => {
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(999);

  const gridRef = useGridRef();

  const { funds } = useFidcFunds();

  const [findStatus, setFindStatus] = useState<string[]>([]);
  const [findReceivedDate, setFindReceivedDate] = useState<string>("");
  const [findFundTaxId, setFundTaxId] = useState<string>("");
  const [filter, setFilter] = useState(false);

  const dataSource = useMemo(() => {
    return dataSourceCustomStoreGenerator<CreditConciliation>(() =>
      service.fidc
        .getCreditConciliation(
          buildQuery({
            status: filter && findStatus.length !== 0 ? findStatus : [],
            page,
            limit: rows,
            receivedDate:
              filter && findReceivedDate !== ""
                ? dayjs(findReceivedDate).format("YYYY-MM-DD")
                : undefined,
            fundTaxId:
              filter && findFundTaxId !== "" ? findFundTaxId : undefined,
          }),
        )
        .then(({ data }) => {
          setTotalItems(data.totalItems);
          return {
            data: data.orders,
            totalCount: data.totalItems || 0,
          };
        })
        .catch((err) => {
          if (!isAxiosError(err)) {
            logger.error(err);
          }
          const errorMessage =
            "Erro ao buscar os dados de liquidação por repasse.";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    );
  }, [filter, findFundTaxId, findReceivedDate, findStatus, page, rows]);

  return {
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    setFindStatus,
    setFindReceivedDate,
    setFundTaxId,
    setFilter,
    funds,
  };
};
