import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { energyAdequatorsGrid } from "./energy-adequators.grid";

export const EnergyAdequatorsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Adequadoras" />}
      actions={
        <RouterButton
          href={corporateRouter.routes.energia.product.adequators.create.path}
          role={roles.energia.product.createAdequator.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={dataGridRef} grid={energyAdequatorsGrid} />
    </GridTemplate>
  );
};
