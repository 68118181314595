import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { isAxiosError } from "axios";
import {
  updateOriginatorIndcationDefaultValues,
  updateOriginatorIndcationValidationSchema,
} from "./update-originator-indication.form";

export const useUpdateOriginatorIndication = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [identifier, setIdentifier] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorIndcationValidationSchema),
    defaultValues: updateOriginatorIndcationDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    const payload = {
      referrerOperatorProfileIdentifier:
        values.referrerOperatorProfileIdentifier,
    };

    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorIndicator(identifier, payload);
      OToastManager.success("Originador atualizado com sucesso!");
    } catch (err) {
      if (
        isAxiosError<
          | HubPartners.Admin.ErrorResponse
          | HubPartners.Admin.AlternateErrorResponse
        >(err)
      ) {
        if (err.response && err.response.data) {
          if ("failures" in err.response.data) {
            err.response.data.failures?.forEach((failure) => {
              failure.message && OToastManager.danger(failure.message);
            });
          } else if ("message" in err.response.data) {
            OToastManager.danger(
              err.response.data.message ??
                "Não foi possível atualizar a indicação do originador.",
            );
          }
        }
      } else {
        OToastManager.danger(
          "Não foi possível atualizar a indicação do originador.",
        );
      }
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;
      if (details.indicatorOriginator) {
        setValue("hasIndicator", ["check"]);
      }
      setValue(
        "referrerOperatorProfileIdentifier",
        details.indicatorIdentifier,
      );
      setValue("originatorName", details.indicatorOriginator?.name);
      setValue("operatorName", details.indicatorOperator?.name);
      setValue("indicatorOnField", details.indicatorOperator?.name);

      setIdentifier(details.identifier);
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
