import * as yup from "yup";

export const operationsContractStatusModalFormValidationSchema = yup
  .object()
  .shape({
    status: yup.string().nullable().trim().required("Campo obrigatório"),
  });

export const operationsContractStatusModalFormDefaultValues = {
  status: "",
};
