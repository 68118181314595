import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { DocumentUploadWithoutForm } from "../../../../../../../../../../components/document-upload-without-form";
import {
  buildDataSource,
  energyBillsGrid,
} from "../../../../../../../../../cliente/unidades-consumidoras/[consumerUnitId]/migracao/[stepId]/_compose/properties/_compose/edit-property-value-modal/_compose/mask-fields/bills-field/bills-fiels.grid";

interface DocumentOrBillsProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
  disabled?: boolean;
  onlyFiles?: boolean;
}

const Bills = ({ property, disabled, onlyFiles }: DocumentOrBillsProps) => {
  const gridRef = useGridRef();
  const dataSource = useMemo(() => buildDataSource(property), [property]);

  return (
    <div className="pt-2 d-flex flex-column gap-4">
      <GridTemplate
        actions={
          <OButton onClick={() => gridRef.current?.instance.addRow()}>
            Adicionar
          </OButton>
        }
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={energyBillsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <DocumentUploadWithoutForm document={property.document} />
      {property.document.files.length ? (
        <DocumentUploadWithoutForm
          document={property.document}
          disabled={disabled}
          onlyFiles={onlyFiles}
        />
      ) : (
        <em>{"<nenhum arquivo>"}</em>
      )}
    </div>
  );
};

export const DocumentOrBills = ({
  property,
  disabled,
  onlyFiles,
}: DocumentOrBillsProps) => {
  if (property?.document.type.name === "FATURA_ENERGIA")
    return <Bills property={property} />;

  return (
    <div style={{ minWidth: "600px" }} className="pt-2">
      {property.document.files.length ? (
        <DocumentUploadWithoutForm
          document={property.document}
          disabled={disabled}
          onlyFiles={onlyFiles}
        />
      ) : (
        <em>{"<nenhum arquivo>"}</em>
      )}
    </div>
  );
};
