import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import type DataGrid from "devextreme-react/data-grid";
import { useMemo, useRef, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { TiersProvider, useTiers } from "./tiers.context";
import { buildDataSource, buildTiersGrid } from "./tiers.grid";
import { CedenteSelector } from "./_compose";

const TiersComponent = () => {
  const [total, setTotal] = useState<number>();
  const gridRef = useRef<DataGrid>(null);

  const { selectedCedente, setSelectedCedente } = useTiers();

  const dataSource = useMemo(
    () => buildDataSource(setTotal, selectedCedente),
    [selectedCedente],
  );

  const grid = useMemo(() => buildTiersGrid(total), [total]);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title="Tiers" description="Enquadramento de Carteira" />
      }
      actions={
        <>
          <CedenteSelector
            selectedCedente={selectedCedente}
            setSelectedCedente={setSelectedCedente}
          />
          <IconButton
            dataAction="tiers:botao:adicionar_tier"
            dataLabel="adicionar_tier"
            icon={{ category: "fal", icon: "fa-plus" }}
            onClick={() => gridRef.current?.instance.addRow()}
          >
            Adicionar Tier
          </IconButton>
        </>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};

export const Tiers = () => (
  <TiersProvider>
    <TiersComponent />
  </TiersProvider>
);
