export const onboardingStatusMap = {
  AUTORIZADO: "Autorizado",
  CONCLUIDA: "Concluído",
  CONCLUIDO: "Concluído",
  EM_ANALISE_DE_ONBOARDING: "Em análise",
  FICHA_ENVIADA_PARA_ONBOARDING: "Ficha enviada para onboarding",
  PENDENTE_ANALISE: "Pendente análise",
  PENDENTE_ASSINATURA: "Pendente assinatura",
  PENDENTE_BIOMETRIA: "Pendente biometria",
  PENDENTE_CADASTRO: "Pendente cadastro",
  PENDENTE_DOCUMENTACAO: "Pendente documentação",
  PENDENTE_ONBOARDING: "Pendente onboarding",
  PENDENTE_PRECADASTRO: "Pendente pré-cadastro",
  PENDENTE_REQUISICAO: "Pendente requisição",
  PENDENTE_VALIDACAO: "Pendente validação",
  PENDENTE_VALIDACAO_MANUAL: "Pendente validação manual",
  PRE_CADASTRADO: "Pré-cadastrado",
  EM_REVISAO_CADASTRAL: "Em revisão",
  REPROVADO: "Reprovado",
};

export const onboardingStatusTooltipMap = {
  PENDENTE_PRECADASTRO: (
    <>
      <p>PORTAL: envia e-mail de boas-vindas e confirmação de login.</p>
      <p>CLIENTE: cliente cadastrou o login, mas não iniciou o onboarding.</p>
    </>
  ),
  PENDENTE_CADASTRO: (
    <p>
      CLIENTE: avançou o primeiro step na tela de onboarding, mas ainda não
      finalizou os outros steps.
    </p>
  ),
  PENDENTE_BIOMETRIA: (
    <>
      <p>CLIENTE: preencheu todos os dados cadastrais no portal.</p>
      <p>
        PORTAL: notifica o cliente que ele precisa realizar o processo de prova
        de vida pelo app. Pelo menos um representante legal ainda não concluiu o
        processo de biometria com sucesso.
      </p>
    </>
  ),
  PENDENTE_VALIDACAO: (
    <p>Status transitório que indica consulta no BrScan e Fraudsys.</p>
  ),
  PENDENTE_VALIDACAO_MANUAL: (
    <p>
      Foram apontados alguns riscos na análise do BrScan e Fraudsys, ou tivemos
      problema na integração e precisamos verificar manualmente.
    </p>
  ),
  PENDENTE_REQUISICAO: (
    <p>
      PORTAL: este status é transitório e identifica o envio dos dados
      cadastrais ao LESS.
    </p>
  ),
  PENDENTE_ONBOARDING: <p>LESS: os dados cadastrais estão sendo analisados.</p>,
  PENDENTE_DOCUMENTACAO: (
    <>
      <p>
        LESS: identificou alguma inconsistência com os documentos enviados pelo
        cliente.
      </p>
      <p>PORTAL: envio de email notificando o cliente.</p>
      <p>
        CLIENTE: deverá acessar o portal e corrigir os documentos em destaque.
      </p>
    </>
  ),
  PENDENTE_ANALISE: <p>LESS: análise de AML</p>,
  AUTORIZADO: (
    <>
      <p>
        LESS: foi criado o CGE do cliente, mas ainda falta a parametrização de
        poderes.
      </p>
      <p>
        PORTAL: os representantes legais recebem email para cadastrar o login.
      </p>
    </>
  ),
  PENDENTE_ASSINATURA: (
    <>
      <p>
        PORTAL: os representantes legais são notificados para assinar o contrato
        de abertura de conta.
      </p>
      <p>
        CLIENTE: deverá assinar o contrato de abertura de conta. Deverá realizar
        o optin permitindo que o BTG solicite as agendas.
      </p>
    </>
  ),
  CONCLUIDO: <p>LESS: parametrização de poderes concluída.</p>,
  REPROVADO: (
    <p>
      Este cliente pode ter sido reprovado por hit de fraude, BCheck, AML ou
      elegibilidade.
    </p>
  ),
};
