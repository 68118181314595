import * as yup from "yup";

export interface LoanEquateFormValues {
  agreementId: number;
  partnerId?: number;
}

export const loanEquateValidationFormSchema: yup.ObjectSchema<LoanEquateFormValues> =
  yup.object({
    agreementId: yup.number().required("Este campo é obrigatório"),
    partnerId: yup.number().optional(),
  });
