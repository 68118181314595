import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { validationSchema } from "../_compose/formulario/onboarding-strategies-form.form";
import { FormValuesType } from "../_compose/formulario/onboarding-strategies-form.types";

export const useAddOnboardingStrategies = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);

    const payload = {
      ...values,
    };

    try {
      await service.quickfin.setAdminOnboardingStrategy(payload);

      OToastManager.success("Estratégia adicionada com sucesso.");
      navigate(routes.recebiveis.product.onboardingStrategies.path);
    } catch {
      OToastManager.danger("Ocorreu um erro para adicionar a estratégia.");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    onSubmit,
  };
};
