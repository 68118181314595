import { DetailsFields } from "components/details-card";
import { PostAdminLoaAllResponseItemResponse } from "services/onboarding-relationships/models";

export const buildWorkflowDetailsFields = (
  workflow: PostAdminLoaAllResponseItemResponse,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Uuid",
        value: workflow.uuid,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Representantes",
        value: workflow.legalRepresentatives.length,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Arquivos",
        value: workflow.documents[0].files.length,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  status: {
    title: "Status",
    items: [
      {
        label: "Status",
        value: workflow.status,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Detalhado",
        value: workflow.detailedStatus,
        grid: { md: 4, xl: 6 },
      },
    ],
  },
});
