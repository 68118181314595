import { masks } from "@maestro/utils";
import { currencySymbol } from "utils/currency";
import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const TotalMonthlyTransactionsCard = () => {
  return (
    <InfoCard
      summaryType="transactionsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Resumo das transações mensal"
          body={masks.currency(_value.monthlyValue, currencySymbol.BRL)}
          footer="Valor total de transações do mês"
        />
      )}
    />
  );
};
