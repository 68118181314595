import { PaymentType } from "./enums";

export const MapperPaymentType: Record<PaymentType, string> = {
  [PaymentType.PixManual]: "Pix",
  [PaymentType.PixKey]: "Pix",
  [PaymentType.PixQrCode]: "Pix",
  [PaymentType.PixReversal]: "Pix",
  [PaymentType.Ted]: "TED",
  [PaymentType.Tef]: "TEF",
  [PaymentType.Darf]: "DARF",
  [PaymentType.Bankslip]: "Boleto",
  [PaymentType.Utilities]: "Conta / Tributo",
  [PaymentType.CreditCardInvoice]: "Fatura de cartão de crédito",
};
