import { OModalCustomEvent } from "@maestro/core";

type EventCallback = (e: Event) => void;

interface ModalEventDetail<T = unknown> {
  id: string;
  payload: T;
}

function show<T = unknown>(id: string, payload?: T) {
  document.dispatchEvent(
    new CustomEvent("modalOpen", { detail: { id, payload } }),
  );
}

function hide<T = unknown>(id: string, payload?: T) {
  document.dispatchEvent(
    new CustomEvent("modalClose", { detail: { id, payload } }),
  );
}

function on<T = unknown>(
  id: string | string[],
  type: "modalOpen" | "modalClose",
  callback: (payload?: T) => void,
) {
  let internalCallback: (e: OModalCustomEvent<ModalEventDetail<T>>) => void;

  if (typeof id === "string") {
    internalCallback = function useModalOnInternalCallback(e) {
      if (e.detail.id === id) callback(e.detail.payload);
    };
  } else {
    internalCallback = function useModalOnInternalCallback(e) {
      if (id.includes(e.detail.id)) callback(e.detail.payload);
    };
  }

  document.addEventListener(type, internalCallback as EventCallback);

  return () =>
    document.removeEventListener(type, internalCallback as EventCallback);
}

export const modalManagerPayload = { show, hide, on };
