import { Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface RuleEditorForm {
  eventType: string;
  stepTypes: string[];
  pendencyTypes: string[];
  propertyTypes: string[];
  documentTypes: string[];
  stepPreviousStatuses: string[];
  stepStatuses: string[];
  pendencyPreviousStatuses: string[];
  pendencyStatuses: string[];
  allowedDistributorIds: number[];
}

export const ruleEditorFormFields = {
  eventType: {
    name: "eventType",
    label: "Quando",
    placeholder: "Selecionar",
    required: true,
  },
  stepTypes: {
    name: "stepTypes",
    label: "Tipo da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  pendencyTypes: {
    name: "pendencyTypes",
    label: "Tipo da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  propertyTypes: {
    name: "propertyTypes",
    label: "Tipo da propriedade",
    placeholder: "Selecionar",
    multiple: true,
  },
  documentTypes: {
    name: "documentTypes",
    label: "Tipo do documento",
    placeholder: "Selecionar",
    multiple: true,
  },
  stepPreviousStatuses: {
    name: "stepPreviousStatuses",
    label: "Status anterior da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  stepStatuses: {
    name: "stepStatuses",
    label: "Status da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  pendencyPreviousStatuses: {
    name: "pendencyPreviousStatuses",
    label: "Status anterior da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  pendencyStatuses: {
    name: "pendencyStatuses",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  allowedDistributorIds: {
    name: "allowedDistributorIds",
    label: "Nas distribuidoras",
    placeholder: "Selecionar",
    multiple: true,
  },
} as const;

export const ruleEditorFormValidationSchema = yup.object<
  RuleEditorForm,
  YupObjectSchema<RuleEditorForm>
>({
  eventType: yup.string().required("Campo obrigatório"),
});

export const ruleEditorFormDefaultValues = {
  eventType: null,
  stepTypes: [],
  pendencyTypes: [],
  propertyTypes: [],
  documentTypes: [],
  stepPreviousStatuses: [],
  stepStatuses: [],
  pendencyPreviousStatuses: [],
  pendencyStatuses: [],
  allowedDistributorIds: [],
} satisfies Partial<Nullable<RuleEditorForm>> as unknown as RuleEditorForm;
