import { useMemo } from "react";
import { useSimulation } from "../simulation/simulation.context";
import { CalendarModal } from "./calendar-modal/calendar.component";
import { ExpensesMessage } from "./expenses-message/expenses.component";
import { SimulationInfoCard } from "./simulation-info-card/info-card.component";
import { SimulationTotalCard } from "./simulation-total/simulation-total.component";

export const SimulationResult = () => {
  const { selectedSimulationResult } = useSimulation();

  const CalendarModalMemo = useMemo(() => {
    const installmentsLength =
      selectedSimulationResult?.settlement?.installments?.length ?? 0;

    return <>{!!installmentsLength && <CalendarModal />}</>;
  }, [selectedSimulationResult]);

  return (
    <div>
      {CalendarModalMemo}
      <SimulationInfoCard />
      <SimulationTotalCard />
      <ExpensesMessage />
    </div>
  );
};
