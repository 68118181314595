import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { LoadingButton } from "components/loading-button";
import { roles } from "roles/roles";
import { useSemaphoreConfigurationDetails } from "./details.hook";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "../../../../../../routes/ferramentas.route-params";
import { SemaphoreStepForm } from "../_compose";

export const SemaphoreStepDetails = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  const { form, loading, updateSemaphoreTemplate, removeSemaphoreTemplate } =
    useSemaphoreConfigurationDetails(uuid as string);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de semáforo"
          description="Detalhes da configuração de semáforo"
        />
      }
      actions={
        <>
          <LoadingButton
            dataAction="acoes:botao:atualizar"
            dataLabel="atualizar"
            loading={loading}
            onClick={updateSemaphoreTemplate}
            role={roles.ferramentas.product.semaphoreConfigStepUpdate.role}
          >
            Atualizar
          </LoadingButton>
          <LoadingButton
            dataAction="acoes:botao:remover"
            dataLabel="remover"
            loading={loading}
            onClick={removeSemaphoreTemplate}
            role={roles.ferramentas.product.semaphoreConfigStepRemove.role}
            type="danger"
          >
            Remover
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <SemaphoreStepForm />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
