import { useRoles } from "hooks/roles";
import React from "react";
import { Role } from "roles/roles.types";
import { logger } from "utils/logger";

interface WithRoleProps {
  role?: Role;
}

export const withRole =
  <T extends object>(
    Component: React.ComponentType<T>,
  ): React.ComponentType<T & WithRoleProps> =>
  ({ role, ...props }) => {
    const { hasRole } = useRoles();

    const userHasRole = hasRole(role);

    if (!userHasRole) logger.debug("[withRole]No role:", role);

    return userHasRole ? <Component {...(props as T)} /> : null;
  };
