import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ReceivablesCustomerByReceivableIdRouteParams } from "../../../../../routes/recebiveis.route-params";

export const useTradeDetails = () => {
  const { receivableId } =
    useParams<ReceivablesCustomerByReceivableIdRouteParams>();
  const [loading, setLoading] = useState(false);

  const {
    callService: getReceivableDetail,
    value: detail,
    loading: detailLoader,
    hasError,
  } = useServiceCall(service.quickfin.getReceivableStateMachineById);

  const downloadTrade = async (id?: number) => {
    try {
      setLoading(true);

      if (!id) return;

      const { data } = await service.quickfin.getTradeComprovanteById(id);

      helpers.downloadBlobFile("comprovante.pdf", data);
    } catch (err) {
      OToastManager.danger("Erro para baixar os dados");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!receivableId) return;

    getReceivableDetail(receivableId);
  }, [getReceivableDetail, receivableId]);

  return {
    detail,
    detailLoader,
    downloadTrade,
    hasError,
    loading,
  };
};
