import styled from "styled-components";

export const LimitsContainerGrid = styled.div<{
  columns: number;
}>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 200px)`};
  grid-gap: 15px;
`;

export const LimitsContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
