import { OIcon } from "@maestro/react";
import { DayMonthMask } from "../../../../utils/masks/daymonthmask";
import { RowGap, RowSpacedContainer } from "../../../cards.styles";
import { GeneralInfo } from "../../../compose/general-info/general-info.component";
import { MapperCycleStatus } from "../../invoices.types";
import { DetailStatus } from "../details.styles";
import { SubtitleCardInfo } from "./constants";
import { ISubtitleProps } from "./subtitle.types";

export const Subtitle = ({ invoiceDetailsData }: ISubtitleProps) => {
  return (
    <RowSpacedContainer>
      <RowGap columnGap={80}>
        {SubtitleCardInfo.map((info) => (
          <div key={info.key}>
            <GeneralInfo
              label={info.label}
              value={DayMonthMask(
                invoiceDetailsData[
                  info.key as keyof typeof invoiceDetailsData
                ] as string,
                " ",
              )}
              strongValue={info.strongValue}
            />
          </div>
        ))}
      </RowGap>
      <DetailStatus>
        <OIcon
          style={{
            color: MapperCycleStatus[invoiceDetailsData.cycleStatus].color,
          }}
          category="fa"
          icon="fa-info-circle"
        />
        <label
          style={{
            color: MapperCycleStatus[invoiceDetailsData.cycleStatus].color,
          }}
        >
          {
            MapperCycleStatus[
              invoiceDetailsData.cycleStatus as keyof typeof MapperCycleStatus
            ].title
          }
        </label>
      </DetailStatus>
    </RowSpacedContainer>
  );
};
