import { service } from "services";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { Apps } from "services/developers/models";
import { OToastManager } from "@maestro/react";
import { corporateRouter } from "routes/corporate-router.context";
import { DataGridAction } from "components/datagrid-action";

export const buildDataSource = (identification: string) =>
  dataSourceCustomStoreGenerator<Apps>(() =>
    service.developers
      .getFilteredApps(`tenantId=${identification}`)
      .then(({ data }) => ({
        data: data.items ?? [],
        totalCount: data.items.length,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os Aplicativos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

const buildDetailsLink = (appId: string) =>
  corporateRouter.routes.developers.customer.listagemApps.details.path({
    appId,
  });

const buildResourceLink = (appId: string) =>
  corporateRouter.routes.developers.customer.listagemApps.listagemResource.path(
    {
      appId,
    },
  );

const buildScopeLink = (appId: string) =>
  corporateRouter.routes.developers.customer.listagemApps.listagemScope.path({
    appId,
  });


export const appListGrid: ODataGridGeneratorConfig<Apps> = {
  datagrid: {
    noDataText: "Nenhum aplicativo encontrado",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      caption: "Nome",
      dataField: "name",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "CNPJ",
      dataField: "tenantId",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "Client Id",
      dataField: "appId",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "Modelo de integração",
      dataField: "clientChannel",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "Versão",
      dataField: "version",
      alignment: "center",
      dataType: "number",
    },
    {
      caption: "Tipo de integração",
      dataField: "type",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "Ações",
      alignment: "center",
      allowExporting: false,
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-link" },
              label: "Detalhes",
              route: buildDetailsLink(data.appId),
            },
            {
              icon: { category: "far", icon: "fa-link" },
              label: "Consentimentos (recursos)",
              route: buildResourceLink(data.appId),
            },
            {
              icon: { category: "far", icon: "fa-link" },
              label: "Consentimentos (escopos)",
              route: buildScopeLink(data.appId),
            },
          ]}
        />
      ),
    },
  ],
};
