import {
  dataSourceCustomStoreGenerator,
  ExtractODataGridGeneratorConfigType,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { exchangeOrdersGrid } from "../../../components/orders/orders.grid";

const dataSource = dataSourceCustomStoreGenerator<
  ExtractODataGridGeneratorConfigType<ReturnType<typeof exchangeOrdersGrid>>
>(() =>
  service.hubFx.adminCustomer.getOrders().catch(() => {
    throw new Error("Erro ao buscar ordens.");
  }),
);

export const ExchangeOrdersPage = () => {
  const { mapSymbol } = useCurrencies();
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ordens" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeOrdersGrid(mapSymbol)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
