import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { UpdateContact } from "../_compose";
import { useContactsFavoredCreate } from "./contacts-favored-create.hook";

export const ContactsFavoredCreate = () => {
  const { form, submit, loading } = useContactsFavoredCreate();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar conta bancária" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="formulario-criar-conta-banking:botao:criar-conta"
          dataLabel="criar-conta"
          onClick={submit}
        >
          Criar conta bancária
        </LoadingButton>
      }
    >
      <CardTemplate>
        <UpdateContact form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
