import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OIcon,
  OInputText,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useEffect, useState } from "react";
import { client } from "../../../../../../../../services/client";
import { MoneyMask } from "../../../../utils";
import { endpoints } from "../../../../utils/endpoints";
import { removeCipherFormatter } from "../../../../utils/formatters/cipher";
import { RowGap, RowSpacedContainer } from "../../../cards.styles";
import { Currency } from "../../../cards.types";
import { InputCopy } from "../../../compose/input-copy/input-copy.component";
import { convertBase64Download } from "../../../utils/invoices";
import { IPaymentMethods } from "../details.types";
import { ITitleProps } from "./title.types";

export const Title = ({
  activeInvoiceData,
  invoiceDetailsData,
  setActiveInvoiceReference,
  accountId,
  selectedCompany,
}: ITitleProps) => {
  const [isModalOppened, setIsModalOppened] = useState(false);
  const [isModalProcessing, setIsModalProcessing] = useState(false);
  const [inputModalValue, setInputModalValue] = useState<string | undefined>();
  const [paymentMethodData, setPaymentMethodData] = useState<
    IPaymentMethods | undefined
  >();
  const [isDownloadingInvoice, setIsDownloadingInvoice] =
    useState(false);

  useEffect(() => {
    setInputModalValue(
      MoneyMask(activeInvoiceData.remainingBalance, Currency.BRL),
    );
  }, [activeInvoiceData]);

  const generateInvoicePayment = async (amount: number) => {
    try {
      setIsModalProcessing(true);
      if (!selectedCompany) return;
      const { data } = await client.post(
        endpoints.bankinghub.postInvoicePaymentMethods(
          accountId,
          activeInvoiceData.invoiceId,
        ),
        {
          amount,
        },
        {
          headers: {
            "x-identification": selectedCompany,
            "content-type": "application/json",
          },
        },
      );
      if (data) {
        setPaymentMethodData(data);
      }
    } catch (error) {
      OToastManager.danger("Erro ao gerar o boleto de pagamento da fatura.");
    } finally {
      setIsModalProcessing(false);
    }
  };

  const onBlur = (value: string) => {
    const amount = removeCipherFormatter(value);
    setInputModalValue(MoneyMask(amount, Currency.BRL));
  };

  const loadInvoiceReports = async () => {
    setIsDownloadingInvoice(true);
    try {
      if (!selectedCompany) return;
      const { data } = await client.get(
        endpoints.bankinghub.getInvoiceReports(
          accountId,
          activeInvoiceData.invoiceId,
        ),
        {
          headers: {
            "x-identification": selectedCompany,
          },
        },
      );
      if (data) {
        const filename = `${activeInvoiceData.dueDate}.pdf`;
        convertBase64Download(
          filename,
          data.reports[0].pdf,
          "application/pdf;base64",
        );
      }
    } catch (error) {
      OToastManager.danger("Erro ao fazer download da fatura.");
    } finally {
      setIsDownloadingInvoice(false);
    }
  };

  const closeModal = () => {
    setIsModalOppened(false);
    setInputModalValue(
      MoneyMask(activeInvoiceData.minimumPayment, Currency.BRL),
    );
    setPaymentMethodData(undefined);
  };

  useEffect(() => {
    if (isModalOppened) {
      modalManager.show("cards-invoices-title-modal-id");
    } else {
      modalManager.hide("cards-invoices-title-modal-id");
    }
  }, [isModalOppened]);

  return (
    <>
      <OModal
        position="center"
        id="cards-invoices-title-modal-id"
        onModalClose={() => {
          closeModal();
        }}
      >
        <OModalHeader>
          <OTypography weight="bold">Gerar Boleto.</OTypography>
        </OModalHeader>
        <OModalBody>
          <label style={{ fontSize: "16px", fontWeight: 600 }}>
            Digite o valor do boleto a ser pago:
          </label>
          <OInputText
            dataAction="cartoes-fatura:texto:boleto"
            dataLabel="boleto"
            id="additional-email"
            name="additional-email"
            value={inputModalValue}
            onInput={(event) => setInputModalValue(event.currentTarget.value)}
            onBlur={(event) => onBlur(event.currentTarget.value as string)}
          />
          {paymentMethodData && (
            <InputCopy
              value={paymentMethodData.digitableLine}
              dataAction="cartoes-fatura:input:boleto"
              dataLabel="boleto"
              disabled
            />
          )}
          {isModalProcessing && <OLoader absolute backdrop />}
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <OButton
              type="default"
              outline
              dataAction="cartoes-fatura:botao:fechar"
              dataLabel="fechar"
              onClick={() => closeModal()}
            >
              Fechar
            </OButton>
            <OButton
              type="primary"
              dataAction="cartoes-fatura:botao:gerar"
              dataLabel="gerar"
              title="Gerar boleto"
              onClick={async () => {
                if (inputModalValue)
                  await generateInvoicePayment(
                    Number.parseFloat(removeCipherFormatter(inputModalValue)),
                  );
              }}
              disabled={
                paymentMethodData !== undefined
                  ? true
                  : inputModalValue !== undefined
                  ? inputModalValue.length < 4
                  : true
              }
            >
              Gerar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <RowSpacedContainer>
        <OTypography>· Detalhes da Fatura</OTypography>
        <RowGap columnGap={35}>
          <>
            {invoiceDetailsData.canPay && (
              <OButton
                dataLabel="cartoes-fatura:botao:gerar-boleto"
                dataAction="gerar-boleto"
                type="default"
                bordered={false}
                onClick={() => setIsModalOppened(true)}
              >
                <OTypography type="light">Gerar boleto</OTypography>
              </OButton>
            )}
            {invoiceDetailsData.canDownloadInvoice && (
              <>
                {!isDownloadingInvoice ? (
                  <OButton
                    className="icon-button"
                    type="default"
                    bordered={false}
                    title="Download da fatura"
                    dataAction="cartoes-fatura:botao:download-fatura"
                    dataLabel="Download da fatura"
                    onClick={() => loadInvoiceReports()}
                  >
                    <OIcon
                      type="light"
                      category="orq"
                      size="lg"
                      icon="orq-download"
                    />
                  </OButton>
                ) : (
                  <OLoader />
                )}
              </>
            )}
          </>
          <OButton
            className="icon-button"
            type="default"
            bordered={false}
            dataAction="cartoes-fatura:botao:fechar-detalhes-fatura"
            dataLabel="Fechar detalhes da fatura"
            title="Fechar detalhes da fatura"
            onClick={() => setActiveInvoiceReference(undefined)}
          >
            <OIcon type="light" category="orq" size="lg" icon="orq-close" />
          </OButton>
        </RowGap>
      </RowSpacedContainer>
    </>
  );
};
