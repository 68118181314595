export const proposalStatusMap = {
  FORMULARIO_PREENCHIMENTO: {
    label: "Formulário em preenchimento",
    value: "FORMULARIO_PREENCHIMENTO",
  },
  AGUARDANDO_AUTORIZACAO: {
    label: "Aguardando autorização cliente",
    value: "AGUARDANDO_AUTORIZACAO",
  },
  EM_ANALISE: {
    label: "Em análise de crédito",
    value: "EM_ANALISE",
  },
  PRE_APROVADO: {
    label: "Crédito Pré Aprovado",
    value: "PRE_APROVADO",
  },
  PENDENTE_DOCUMENTACAO: {
    label: "Pendente documentação",
    value: "PENDENTE_DOCUMENTACAO",
  },
  REPROVADO_MOTOR: {
    label: "Reprovado",
    value: "REPROVADO_MOTOR",
  },
  VENCIDO: {
    label: "Vencido",
    value: "VENCIDO",
  },
  AGUARDANDO_AUDITORIA: {
    label: "Aguardando auditoria",
    value: "AGUARDANDO_AUDITORIA",
  },
  PENDENTE_APROVACAO: {
    label: "Pendente Aprovação",
    value: "PENDENTE_APROVACAO",
  },
  PENDENCIA_AUDITORIA: {
    label: "Pendência auditoria",
    value: "PENDENCIA_AUDITORIA",
  },
  NEGADO_AUDITORIA: {
    label: "Negado auditoria",
    value: "NEGADO_AUDITORIA",
  },
  APROVADO_AUDITORIA: {
    label: "Aprovado auditoria",
    value: "APROVADO_AUDITORIA",
  },
  AGUARDANDO_ASSINATURA: {
    label: "Aguardando assinatura",
    value: "AGUARDANDO_ASSINATURA",
  },
  AGUARDANDO_REGISTROS: {
    label: "Aguardando registros",
    value: "AGUARDANDO_REGISTROS",
  },
  PENDENCIA_REGISTROS: {
    label: "Pendência registros",
    value: "PENDENCIA_REGISTROS",
  },
  AGUARDANDO_DESEMBOLSO: {
    label: "Aguardando desembolso",
    value: "AGUARDANDO_DESEMBOLSO",
  },
  CONCLUIDO: {
    label: "Concluído",
    value: "CONCLUIDO",
  },
};
