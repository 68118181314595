import { OToastManager } from "@maestro/react";
import { helpers, masks } from "@maestro/utils";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { ExchangeQuoteDetails } from "../../../../components/quotes/quote-details.component";
import { CambioOperacoesByIdRouteParams } from "../../../../routes/cambio.route-params";
import { parseQuoteFlowType } from "../../../../utils/quote.utils";

export const ExchangeProductQuoteDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<CambioOperacoesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: quoteDetails,
    callService: getQuoteDetails,
    hasError: hasErrorQuoteDetails,
    loading: loadingQuoteDetails,
  } = useServiceCall(service.hubFx.getQuoteDetails);

  const downloadContract = useCallback(async () => {
    try {
      if (!quoteDetails) return;

      const name = parseQuoteFlowType(quoteDetails)?.externalPartyName ?? "";
      const fileName = `BACEN_Contract_${
        quoteDetails?.natureCode ?? ""
      }_${name}.pdf`;

      if (quoteDetails?.contracts?.[0]?.externalId) {
        const { data: blob } = await service.paperclipv2.getContractFileBinary(
          quoteDetails.contracts[0].externalId,
        );

        helpers.downloadBlobFile(fileName, blob);
        return;
      }

      if (quoteDetails?.bacenContractId) {
        const { data } = await service.hubFx.downloadContract({
          contractId: quoteDetails.bacenContractId,
        });

        helpers.downloadBase64(data, fileName);
      }
    } catch {
      OToastManager.danger(
        "Erro ao baixar arquivo. Por favor, tente novamente.",
      );
    }
  }, [quoteDetails]);

  const downloadReceipt = useCallback(
    async (contractId: string) => {
      try {
        if (!quoteDetails) return;

        const { data } = await service.hubFx.downloadReceipt({
          contractId,
        });

        const name = parseQuoteFlowType(quoteDetails)?.externalPartyName ?? "";

        helpers.downloadBase64(
          data,
          `Comprovante_${quoteDetails?.natureCode ?? ""}_${name}.pdf`,
        );
      } catch {
        OToastManager.danger(
          "Erro ao baixar arquivo. Por favor, tente novamente.",
        );
      }
    },
    [quoteDetails],
  );

  const cancelOperation = useCallback(async () => {
    try {
      setLoading(true);
      await service.hubFx.cancelQuote({ id });

      OToastManager.success("Operação cancelada com sucesso");
      getQuoteDetails(Number(id));
    } catch {
      OToastManager.danger("Houve um erro ao cancelar. Tente novamente.");
    } finally {
      setLoading(false);
    }
  }, [getQuoteDetails, id]);

  useEffect(() => {
    getQuoteDetails(Number(id));
  }, [getQuoteDetails, id]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes da Operação" />}
      actions={
        <>
          {!!quoteDetails?.bacenContractId && (
            <IconButton
              icon={{
                category: "fal",
                icon: "fa-download",
              }}
              dataAction="cambio_detalhes_operacao:botao:baixar_contrato_bacen"
              dataLabel="baixar_contrato_bacen"
              onClick={downloadContract}
            >
              Baixar Contrato BACEN
            </IconButton>
          )}
          {!!quoteDetails?.hasReceipt && (
            <IconButton
              icon={{
                category: "fal",
                icon: "fa-download",
              }}
              dataAction="cambio_detalhes_operacao:botao:baixar_comprovante_swift"
              dataLabel="baixar_comprovante_swift"
              onClick={() =>
                quoteDetails?.bacenContractId &&
                downloadReceipt(quoteDetails.bacenContractId)
              }
            >
              Baixar Comprovante SWIFT
            </IconButton>
          )}
          {quoteDetails?.status !== "CANCELLED" && (
            <ButtonWithConfirmation
              type="danger"
              dataAction="cambio_detalhes_operacao:botao:cancelar_operacao"
              dataLabel="cancelar_operacao"
              confirmationModal={{
                title: "Tem certeza que deseja cancelar esta operação?",
                loading,
              }}
              modalBody="Ao confirmar o cancelamento não será possível reverter a ação."
              onConfirm={cancelOperation}
            >
              Cancelar operação
            </ButtonWithConfirmation>
          )}
        </>
      }
    >
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loadingQuoteDetails}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar detalhes da operação."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton onClick={() => getQuoteDetails(Number(id))} />
            </ErrorComponent>
          }
          hasError={hasErrorQuoteDetails}
          value={quoteDetails}
          fields={(quote) => ({
            company: {
              title: "Empresa",
              items: [
                {
                  label: "Nome da empresa",
                  value: quote.counterparty?.name,
                  grid: { md: 4 },
                },
                {
                  label: quote.counterparty?.identificationType,
                  value: masks.cpfCnpj(quote.counterparty?.identification),
                  grid: { md: 4 },
                },
                quote.bacenContractId
                  ? {
                      label: "Número contrato BACEN",
                      value: quote.bacenContractId,
                      grid: { md: 4 },
                    }
                  : {},
              ],
            },
          })}
        />
        {quoteDetails && (
          <ExchangeQuoteDetails
            quoteDetails={quoteDetails}
            type="product"
            counterparty={quoteDetails.counterparty?.identification}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
