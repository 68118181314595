import { useCustomer } from "contexts/customer";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GetEstrategiaComercialByCompanyIdResponse,
  PostCompaniesByIdBorderosVerifyErrorResponse,
} from "services/quickfin/models";
import { logger } from "utils/logger";
import { Receivable } from "./batch-upload-receivables.types";
import { useUploadReceivables } from "./upload-receivables.hook";
import { useVerifyReceivables } from "./verify-receivables.hook";

interface BatchUploadReceivablesContext {
  clear: () => unknown;
  companyId: number;
  estrategia: GetEstrategiaComercialByCompanyIdResponse[number] | undefined;
  receivables: Receivable[] | undefined;
  setEstrategia: (
    estrategia: GetEstrategiaComercialByCompanyIdResponse[number],
  ) => void;
  setReceivables: (receivables: Receivable[]) => void;
  submit: () => unknown;
  submitLoading: boolean;
  validationHasError: boolean;
  validationLoading: boolean;
  validationResults: PostCompaniesByIdBorderosVerifyErrorResponse | undefined;
  verifyReceivables: () => unknown;
  bypassValidation: boolean;
  setBypassValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const batchUploadReceivablesContext = createContext(
  {} as BatchUploadReceivablesContext,
);

interface BatchUploadReceivablesProviderProps {
  children: React.ReactNode;
}

export const BatchUploadReceivablesProvider = ({
  children,
}: BatchUploadReceivablesProviderProps) => {
  const {
    customer: { identification, quickfinId: companyId },
  } = useCustomer();

  const [bypassValidation, setBypassValidation] = useState<boolean>(false);
  const [receivables, setReceivables] = useState<Receivable[]>();
  const [estrategia, setEstrategia] =
    useState<GetEstrategiaComercialByCompanyIdResponse[number]>();

  const {
    clear: validationClear,
    verifyReceivables: _verifyReceivables,
    hasError: validationHasError,
    loading: validationLoading,
    validationResults,
  } = useVerifyReceivables();

  const { callService: uploadReceivables, loading: submitLoading } =
    useUploadReceivables();

  const verifyReceivables = useCallback(() => {
    // Neither of these ifs should happen
    if (!receivables) {
      logger.error("No receivables");
      return;
    }
    if (!estrategia) {
      logger.error("No estrategia");
      return;
    }
    return _verifyReceivables(bypassValidation, companyId, {
      companyId: Number(companyId),
      estrategiaId: estrategia.id,
      receivables: receivables as Required<Receivable>[], // backend validates anyway
      isFromAdmin: true,
      ownerTaxId: identification,
    });
  }, [
    _verifyReceivables,
    bypassValidation,
    companyId,
    estrategia,
    identification,
    receivables,
  ]);

  const clear = useCallback(() => {
    setReceivables(undefined);
    setEstrategia(undefined);
    validationClear();
  }, [validationClear]);

  const submit = useCallback(async () => {
    // Neither of these ifs should happen
    if (!receivables) {
      logger.error("No receivables");
      return;
    }
    if (!estrategia) {
      logger.error("No estrategia");
      return;
    }

    await uploadReceivables(companyId, {
      companyId: Number(companyId),
      estrategiaId: estrategia.id,
      isFromAdmin: true,
      receivables: receivables as Required<Receivable>[], // backend validates anyway
      ownerTaxId: identification,
    });
  }, [companyId, estrategia, identification, receivables, uploadReceivables]);

  useEffect(() => {
    if (receivables && estrategia) verifyReceivables();
  }, [estrategia, receivables, submit, verifyReceivables]);

  const value = useMemo(
    () => ({
      clear,
      companyId,
      estrategia,
      receivables,
      setEstrategia,
      setReceivables,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifyReceivables,
      setBypassValidation,
      bypassValidation,
    }),
    [
      clear,
      companyId,
      estrategia,
      receivables,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifyReceivables,
      setBypassValidation,
      bypassValidation,
    ],
  );

  return (
    <batchUploadReceivablesContext.Provider value={value}>
      {children}
    </batchUploadReceivablesContext.Provider>
  );
};

export const useBatchUploadReceivables = () =>
  useContext(batchUploadReceivablesContext);
