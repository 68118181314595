import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { useCollectionsAgreement } from "./collections-agreement.hook";

interface CollectionsAgreementContextProps {
  agreementsList: BankingHubAdminCollection.Agreement[];
  getAgreementsList: () => void;
  isLoadingAgreementsList: boolean;
  agreementToUpdate?: BankingHubAdminCollection.Agreement;
  setAgreementToUpdate: (
    agreement?: BankingHubAdminCollection.Agreement,
  ) => void;
}

const CollectionsAgreementDefaultValue: CollectionsAgreementContextProps = {
  agreementsList: [],
  getAgreementsList: () => {},
  isLoadingAgreementsList: false,
  setAgreementToUpdate: () => {},
};

const CollectionsAgreementContext = createContext(
  CollectionsAgreementDefaultValue,
);

export const CollectionsAgreementProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [agreementToUpdate, setAgreementToUpdate] =
    useState<BankingHubAdminCollection.Agreement>();
  const {
    agreementsList = [],
    getAgreementsList,
    loading: isLoadingAgreementsList,
  } = useCollectionsAgreement();

  const value = useMemo(
    () => ({
      agreementsList,
      getAgreementsList,
      isLoadingAgreementsList,
      agreementToUpdate,
      setAgreementToUpdate: (agreement?: BankingHubAdminCollection.Agreement) =>
        setAgreementToUpdate(agreement),
    }),
    [
      agreementsList,
      getAgreementsList,
      isLoadingAgreementsList,
      agreementToUpdate,
    ],
  );

  return (
    <CollectionsAgreementContext.Provider value={value}>
      {children}
    </CollectionsAgreementContext.Provider>
  );
};

export const useCollectionsAgreementContext = () =>
  useContext(CollectionsAgreementContext);
