import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetApiGroupsResponseItem } from "services/clerk";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const grid: ODataGridGeneratorConfig<GetApiGroupsResponseItem> = {
  datagrid: {
    noDataText: "Nenhum grupo encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.empresas.product.groupsDetailsPage.role,
      cellRender: ({ data: { identification } }) =>
        identification && (
          <DetailsLink
            href={corporateRouter.routes.empresas.product.groups.details.path({
              identification,
            })}
          />
        ),
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    { dataField: "type", caption: "Tipo" },
    {
      dataField: "identification",
      caption: "Entidade principal",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
  ],
};
