import {
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";

type StatusCardProps = {
  active: boolean;
  count: number;
  description: string;
  disabled?: boolean;
  onRemove: () => void;
  onSelect: () => void;
  title: string;
};

export const StatusCard = ({
  active,
  count,
  description,
  disabled = false,
  onRemove,
  onSelect,
  title,
}: StatusCardProps) => {
  return (
    <OCard
      style={{
        height: 154,
        border: active ? "1px solid var(--theme-tertiary)" : "",
        cursor: disabled ? "default" : "pointer",
      }}
    >
      <OCardHeader>
        <div className="d-flex justify-content-between w-100">
          <OTypography type={active ? "info" : "dark"}>{title}</OTypography>
          <OIcon
            category="orq"
            icon="orq-close"
            type={active ? "info" : "dark"}
            onClick={() => onRemove()}
          />
        </div>
      </OCardHeader>
      <OCardBody
        className="d-flex flex-column justify-content-between h-100"
        onClick={() => !disabled && onSelect()}
      >
        <OTypography size="sm" type={active ? "dark" : "dark-80"}>
          {description}
        </OTypography>
        <OTypography
          size="sm"
          type={active ? "dark-80" : "dark-60"}
        >{`${count} clientes`}</OTypography>
      </OCardBody>
    </OCard>
  );
};
