import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { ConfirmationDeleteConfigurableColumnsModalProps } from "./delete-configurable-columns-modal.type";
import { useConfirmationDeleteConfigurableColumnsModal } from "./delete-configurable-columns-modal.hook";

export const ConfirmationDeleteConfigurableColumnsModal = ({
  id,
  reload,
}: ConfirmationDeleteConfigurableColumnsModalProps) => {
  const { loading, modalId, handleConfirm } =
    useConfirmationDeleteConfigurableColumnsModal({
      id,
      reload,
    });

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Tem certeza que deseja remover esta coluna configurável?"
      divider={false}
      onConfirm={handleConfirm}
      loading={loading}
      className="position-absolute"
      {...datagridModalFix}
    >
      <OTypography size="md">
        Ao confirmar a operação não será possível reverter a ação.
      </OTypography>
    </OConfirmationModal>
  );
};
