import * as yup from "yup";
import { DeskMigrationOptionsForm } from "./desk-migration-options.types";

export const deskMigrationOptionsValidationSchema = yup.object<
  Omit<DeskMigrationOptionsForm, "workflowId">,
  yup.ObjectSchema<Omit<DeskMigrationOptionsForm, "workflowId">>["fields"]
>({
  accountStringified: yup.string().required("Campo obrigatório"),
  deskCode: yup
    .number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  officerCge: yup
    .number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
});
