import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetAdminBalancesResponseItem } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { productTypeLibrary } from "./overdue-positions.utils";

export const grid: ODataGridGeneratorConfig<GetAdminBalancesResponseItem> = {
  datagrid: {
    noDataText: "Nenhuma posição em atraso",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "cedente", caption: "Cedente" },
    {
      dataField: "cedenteIdentification",
      caption: "CNPJ Cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    { dataField: "sacado", caption: "Sacado" },
    {
      dataField: "sacadoIdentification",
      caption: "CNPJ Sacado",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "latePaymentFee",
      caption: "Juros por Atraso",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "interestArrears",
      caption: "Mora",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "penaltyFee",
      caption: "Multa",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "maturityValue",
      caption: "Valor Principal",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "interestArrears",
      caption: "Posição",
      cellRender: ({ data }) => {
        const value =
          data.maturityValue +
          data.penaltyFee +
          data.interestArrears +
          data.latePaymentFee;

        return (
          <OTypography tag="span">{masks.currency(value, "R$")}</OTypography>
        );
      },
    },
    {
      dataField: "tipoProduto",
      caption: "Tipo de produto",
      format: (value) => productTypeLibrary[value],
    },
    {
      caption: "Data de Vencimento Mais Antiga",
      dataField: "oldestMaturityDate",
      dataType: "date",
      format: "shortDate",
      sortOrder: "desc",
    },
    {
      caption: "Data da Posição",
      dataField: "oldestValueDate",
      dataType: "date",
      format: "shortDate",
    },
  ],
};
