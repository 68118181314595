import { OCol, ODivider, ORFieldInput, ORow } from "@maestro/react";
import { SelectCompanies } from "./select-companies/select-companies.component";
import { ValuesByPlan } from "./values-by-plan/values-by-plan.component";
import { SelectTipoProduto } from "./select-tipo-produto";
import { SelectStrategies } from "../select-estrategias";
import { SelectPortfolio } from "./select-portfolio";

export interface TCOStrategiesForm {
  setValue: (v1: any, v2: any) => void;
  watch: (w: any) => void;
}

export const TCOStrategiesForm = (props: TCOStrategiesForm) => {
  return (
    <>
      <ORow>
        <OCol>
          <ORFieldInput
            name="description"
            id="description"
            dataLabel="description"
            dataAction="estrategia_tco:input:description"
            label="Descrição"
            tag="text"
          />
        </OCol>
        <OCol>
          <SelectTipoProduto />
        </OCol>
        <OCol>
          <SelectPortfolio />
        </OCol>
      </ORow>
      <ODivider type="dark" size="xxs" className="mb-2 mt-2" />
      <ORow>
        <OCol>
          <SelectCompanies />
        </OCol>
      </ORow>
      <ODivider type="dark" size="xxs" className="mb-2 mt-2" />
      <ORow>
        <OCol>
          <SelectStrategies />
        </OCol>
      </ORow>
      <ODivider type="dark" size="xxs" className="mb-2 mt-2" />
      <ValuesByPlan setValue={props.setValue} watch={props.watch} />
    </>
  );
};
