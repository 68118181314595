export const reviewStatusMap = {
  INDEFINIDO: {
    text: "Indefinido",
    type: "danger",
    icon: "fa-question-circle",
  },
  EM_REVISAO_CADASTRAL: {
    text: "Em revisão cadastral",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  PENDENTE_ANALISE_DE_FRAUDE: {
    text: "Em análise antifraude",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  REPROVADO_POR_FRAUDE: {
    text: "Reprovado após análise antifraude",
    type: "danger",
    icon: "fa-times-circle",
  },
  ENVIADO_PARA_ANALISE_DE_ONBOARDING: {
    text: "Enviado para Onboarding",
    type: "success",
    icon: "fa-check-circle",
  },
  EM_ANALISE_DE_AML: {
    text: "Em análise de AML",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  REPROVADO_POR_AML: {
    text: "Reprovado por AML",
    type: "danger",
    icon: "fa-times-circle",
  },
  EM_ANALISE_DE_ONBOARDING: {
    text: "Em análise de Onboarding",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  ERRO_WORKFLOW_REVISAO_CADASTRAL: {
    text: "Erro não mapeado no workflow de Onboarding",
    type: "danger",
    icon: "fa-times-circle",
  },
  PENDENTE_AJUSTE_APONTADO_POR_ONBOARDING: {
    text: "Pendente correções apontadas por Onboarding",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  REPROVADO_POR_ONBOARDING: {
    text: "Reprovado após análise de Onboarding",
    type: "danger",
    icon: "fa-times-circle",
  },
  PENDENTE_ASSINATURA: {
    text: "Pendente assinatura do kit de revisão cadastral",
    type: "warning",
    icon: "fa-exclamation-circle",
  },
  FICHA_ENVIADA_PARA_ONBOARDING: {
    text: "Kit de revisão cadastral enviado para Onboarding",
    type: "success",
    icon: "fa-check-circle",
  },
  CONCLUIDA: {
    text: "Revisão concluída",
    type: "success",
    icon: "fa-check-circle",
  },
  DEFAULT: {
    text: null,
    type: "warning",
    icon: "fa-question-circle",
  },
} as const;

export const notificationStatusMap = {
  INDEFINIDO: "Indefinido",
  PRIMEIRA_NOTIFICACAO: "Primeira notificação",
  SEGUNDA_NOTIFICACAO: "Segunda notificação",
  TERCEIRA_NOTIFICACAO: "Terceira notificação",
  PENDENTE_REVISAO_CONTA_BLOQUEADA:
    "Conta bloqueada devido atraso na revisão cadastral",
  PENDENTE_ACAO_DO_CLIENTE: "Pendente ação do cliente",
  CONCLUIDA: "Análise concluída",
  DEFAULT: "Status não registrado",
};

export const reviewTypeMap = {
  REVISAO_CADASTRAL_PJ_PME: {
    text: "Revisão Cadastral PJ PME",
    icon: "fa-tag",
  },
  REVISAO_CADASTRAL_PJ_IB: {
    text: "Revisão Cadastral PJ IB",
    icon: "fa-tag",
  },
  REVISAO_CADASTRAL_PJ_SP: {
    text: "Revisão Cadastral PJ SP",
    icon: "fa-tag",
  },
  NULL: {
    text: "Revisão Cadastral PJ PME",
    icon: "fa-tag",
  },
} as const;
