import { OBadge, OTooltip } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { GetPayrollListResponse } from "services/fopa";
import { PaymentStatusMap } from "./payment.utils";

const buildDetailsLink = (
  data: GetPayrollListResponse["dashboardList"][number],
) => {
  const baseUrl =
    "/folha-de-pagamento/cliente/pagamentos/detalhes-do-pagamento";
  const stringifiedGuarantor = JSON.stringify(data);
  const searchParams = new URLSearchParams();
  searchParams.set("data", stringifiedGuarantor);

  return baseUrl + "?" + searchParams.toString();
};

export const paymentsGrid = {
  datagrid: {
    noDataText: "Nenhuma Operação encontrada.",
    selection: { mode: "single" },
    remoteOperations: true,
    headerFilter: { visible: true },
    filterRow: {
      visible: true,
    },
    pager: {
      showInfo: true,
      visible: true,
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    loadPanel: { enabled: true },
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
    },
    { caption: "Tipo de Pagamento", dataField: "tipoDePagamento" },
    {
      caption: "Lote",
      dataField: "lote",
    },
    { caption: "Solicitante", dataField: "solicitante" },
    {
      caption: "Data de Envio",
      dataField: "dataRecebimento",
    },
    {
      caption: "Data de Débito",
      dataField: "dataDebito",
    },
    {
      caption: "Data de Crédito",
      dataField: "dataCredito",
    },
    {
      caption: "Original",
      dataField: "valorOriginal",
      format: (value) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value),
    },
    {
      caption: "Validado",
      dataField: "valorValidado",
      format: (value) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value),
    },
    {
      caption: "Pago",
      dataField: "valorPago",
      format: (value) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value),
    },
    {
      caption: "Status",
      dataField: "status",
      cellRender: ({ data }) =>
        data.status && PaymentStatusMap[data.status] ? (
          <OTooltip floating position="top-left">
            <span slot="tooltip-content">{data.status}</span>
            <OBadge type={PaymentStatusMap[data.status].type} size="sm">
              {PaymentStatusMap[data.status].label}
            </OBadge>
          </OTooltip>
        ) : (
          <OBadge type="info" size="sm">
            {data.status}
          </OBadge>
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  GetPayrollListResponse["dashboardList"][number]
>;
