import { GetConciliacaoDetailResponseItem } from "services/recebiveis-cartao/models";

export function findStatus(
  slcData: GetConciliacaoDetailResponseItem[] | undefined,
) {
  const conciliacaoStatus = slcData?.map(({ status }) => status);
  if (conciliacaoStatus) {
    if (conciliacaoStatus.includes("nao_pago")) return "nao_pago";
    if (conciliacaoStatus.includes("em_aberto")) return "em_aberto";
    if (conciliacaoStatus.every((status) => status === "pago")) return "pago";
    return "";
  }
  return "";
}
