import { OToastManager } from "@maestro/core";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../utils";

interface ReserveOperationButtonProps {
  tradeId: number;
}

export const ReserveOperationButton = ({
  tradeId,
}: ReserveOperationButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.reserveOperation,
  );

  const reserveOperation = useCallback(async () => {
    const { success, error } = await callService({
      tradeId,
    });

    if (success) {
      OToastManager.success("Operação reservada com sucesso");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao reservar a operação",
      );
    }
  }, [callService, tradeId]);

  return (
    <ButtonWithConfirmation
      confirmationModal={{
        title: "Tem certeza que deseja reservar a operação?",
        divider: false,
        loading,
      }}
      onConfirm={reserveOperation}
      disabled={loading}
    >
      Reservar operação
    </ButtonWithConfirmation>
  );
};
