import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { customer } from "contexts/customer";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { OperationState } from "./company-details.types";
import { operationStatusMap } from "../../produto/operacoes/operations.utils";

export const dataSource = dataSourceODataStoreGenerator<OperationState>(
  endpoints.quickfin.odata.operationStateOData,
  {
    odataStoreOptions: {
      beforeSend: ({ params }) => {
        // eslint-disable-next-line no-param-reassign
        params.companyIdentification = customer.value?.identification;
        // eslint-disable-next-line no-param-reassign
        params.tipoProduto = "Cartao";
      },
    },
  },
);

const buildDetailsLink = (operation: OperationState) =>
  corporateRouter.routes.antecipacaoDeCartoes.product.operations.details.path({
    companyId: operation.CompanyId,
    operationId: operation.Id,
  });

export const companyDetailsGrid: ODataGridGeneratorConfig<OperationState> = {
  datagrid: {
    noDataText: "Nenhuma operação.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
  },
  columns: [
    {
      dataField: "SacadoName",
      dataType: "string",
      caption: "Credenciadora",
      allowHeaderFiltering: false,
    },
    {
      dataField: "OperationDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data da Operação",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
    {
      dataField: "PrazoMedio",
      dataType: "number",
      caption: "Prazo Médio",
      format: "#,##0.00",
      allowHeaderFiltering: false,
    },
    {
      dataField: "TaxaMediaPonderada",
      dataType: "number",
      caption: "Taxa Média Ponderada",
      format: { type: "percent", precision: 4 },
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorAntecipado",
      dataType: "number",
      caption: "Valor Bruto",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorRecebido",
      dataType: "number",
      caption: "Valor Líquido",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "Status",
      dataType: "string",
      caption: "Status",
      customizeText: ({ value }) =>
        getValueFromMap(operationStatusMap, value) || value,
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      role: roles.antecipacaoDeCartoes.product.operationsDetailsPage.role,
      cellRender: ({ data }) => <DetailsLink href={buildDetailsLink(data)} />,
      allowExporting: false,
    },
  ],
  filterPanel: {
    visible: true,
  },
};
