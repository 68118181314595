import { OCol, OOption, ORFieldSelect } from "@maestro/react";

export const DaysToCancelInput = () => {
  return (
    <OCol>
      <ORFieldSelect
        id="settings.daysToCancel"
        name="settings.daysToCancel"
        placeholder="Selecione uma opção"
        label="Prazo para cancelamento automático da cobrança"
      >
        {[120, 90, 60, 30, 15, 7].map((value) => (
          <OOption
            value={value}
            key={value}
          >{`${value} dias após o vencimento`}</OOption>
        ))}
        <OOption value={0}>No dia do vencimento</OOption>
      </ORFieldSelect>
    </OCol>
  );
};
