import { OToastManager } from "@maestro/core";
import {
  OButton,
  ODropdown,
  ODropdownItem,
  ODropdownList,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services/service";
import { portals } from "./portals";

interface ImpersonateDropdownProps {
  email: string;
}

export const ImpersonateDropdown = ({ email }: ImpersonateDropdownProps) => {
  const [loading, setLoading] = useState(false);

  const getToken = useCallback(
    async (key: string) => {
      try {
        setLoading(true);

        const { data } = await service.gatekeeper.impersonate({
          user: email,
          client_id: portals[key].client_id,
        });

        window.open(
          portals[key].url + `/impersonar/${data.token}?portal=${key}`,
        );
      } catch (error) {
        OToastManager.danger("Usuário não pode ser impersonado");
      } finally {
        setLoading(false);
      }
    },
    [email],
  );

  return (
    <ODropdown>
      <OButton disabled={loading}>
        <div className="d-flex align-items-center gap-2">
          {loading ? (
            <OLoader size="xs" />
          ) : (
            <OIcon category="orq" icon="orq-visibility-on" />
          )}
          Impersonar
        </div>
      </OButton>
      <ODropdownList>
        {Object.keys(portals).map((key) => (
          <ODropdownItem key={key} onClick={() => getToken(key)}>
            <OTypography className="px-2" type="dark">
              {portals[key].text}
            </OTypography>
          </ODropdownItem>
        ))}
      </ODropdownList>
    </ODropdown>
  );
};
