import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";

export const useIntubateDebitCardOffers = () => {
  const {
    callService: intubateDebitCardOffers,
    error,
    loading,
  } = useServiceCall(service.eligibilityengine.intubateDebitCardOffers);

  const form = useForm<{ "intubate-debit-card-offers": unknown }>();

  const { setError } = form;

  const handleAddFile = useCallback(
    async (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        if (file.size / 1024 ** 2 > 10) {
          setError("intubate-debit-card-offers", {
            message: "O tamanho máximo do arquivo é 10MB",
          });
          return;
        }

        const { success } = await intubateDebitCardOffers(file);
        if (success) OToastManager.success("Arquivo enviado com sucesso!");
      }
    },
    [intubateDebitCardOffers, setError],
  );

  return {
    error,
    form,
    handleAddFile,
    loading,
  };
};
