import * as yup from "yup";
import { FormValuesType } from "./request-for-reports.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  cnpjCedente: yup.string(),
  cnpjSacados: yup.array(),
  reportName: yup.string().required("Este campo é obrigatorio"),
  reportPeriod: yup.string().required("Este campo é obrigatorio"),
  periodDate: yup.array().nullable(),
  isCnpjRaiz: yup.boolean().required(),
});

export const batchRequestReportsDefaultValues: FormValuesType = {
  reportName: "",
  reportPeriod: "Today",
  isCnpjRaiz: false,
};
