import { ODataGridGeneratorConfig } from "components/data-grid";
import { UserNotification } from "../../../../../components/notifications/notifications.types";

export const notificationsGrid = (
  type: "Email" | "Push",
): ODataGridGeneratorConfig<UserNotification> => ({
  datagrid: {
    noDataText: "Nenhuma notificação",
    remoteOperations: true,
    filterRow: { visible: true },
  },
  columns: [
    {
      dataField: "title",
      dataType: "string",
      caption: "Título",
    },

    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
    },
    {
      dataField: "trackerSend",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Entregue em",
    },
    {
      dataField: "trackerOpen",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Lido em",
      visible: type === "Email",
    },
    {
      dataField: "trackerBounce",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Devolvido em",
      visible: type === "Email",
    },
    {
      dataField: "insertedAt",
      dataType: "datetime",
      visible: false,
    },
  ],
});
