import {
  AddressForm,
  addressFormValidationSchema,
  addressFormDefaultValues,
} from "../../../../../components/address-form/address.form";
import {
  DistributorBasicDataForm,
  distributorBasicDataFormValidationSchema,
  distributorBasicDataFormDefaultValues,
} from "../../_compose";

export interface DistributorRegistrationDataForm
  extends DistributorBasicDataForm,
    AddressForm {}

export const distributorRegistrationDataFormValidationSchema =
  distributorBasicDataFormValidationSchema.concat(addressFormValidationSchema);

export const distributorRegistrationDataFormDefaultValues = {
  ...distributorBasicDataFormDefaultValues,
  ...addressFormDefaultValues,
};
