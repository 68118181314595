import {
  OButton,
  OCol,
  OField,
  OLoader,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { DocumentsManager } from "../documents-manager";
import { useExtraData } from "./extra-data.hook";
import { AddressesList } from "../addresses-list";

interface ExtraDataProps {
  requiredDocuments: ScfDevelopers.PayerDocumentType[];
  requiredFields: string[];
  setCurrentStep: (value: string) => void;
}

export const ExtraData = ({
  requiredDocuments,
  requiredFields,
  setCurrentStep,
}: ExtraDataProps) => {
  const { form, goBack, onSubmit, loading } = useExtraData(
    requiredFields,
    setCurrentStep,
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Registrar sacado simples" />}
      actions={
        <div className="d-flex flex-row justify-content-between w-100">
          <OButton type="tertiary" outline onClick={() => goBack()}>
            Voltar
          </OButton>
          <LoadingButton
            dataAction="importar_sacado_simples:botao:concluir"
            dataLabel="concluir"
            loading={loading}
            onClick={() => onSubmit()}
          >
            Concluir Onboarding
          </LoadingButton>
        </div>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute />}

        <FormProvider {...form}>
          <ORow>
            <OCol xl={4}>
              <ORFieldInput tag="text" id="name" name="name" label="Nome" />
            </OCol>
            <OCol xl={4}>
              <ORFieldInput
                tag="text"
                id="emailAddress"
                name="emailAddress"
                label="E-mail"
              />
            </OCol>
            <OCol xl={4}>
              <ORFieldInput
                tag="phone"
                id="phoneNumber"
                name="phoneNumber"
                label="Telefone"
              />
            </OCol>
            <OCol xl={4}>
              <OField htmlFor="birthDate" label="Data de nascimento">
                <ORFieldInputDate
                  id="birthDate"
                  name="birthDate"
                  className="mt-2"
                />
              </OField>
            </OCol>
            <AddressesList />
            <DocumentsManager requiredDocuments={requiredDocuments} />
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
