import { validators } from "@maestro/utils";
import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface AddressForm {
  address: {
    street: string;
    number: string | null;
    complement: string | null;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

export const addressFormFields = {
  address: {
    zipCode: {
      id: "address.zipCode",
      name: "address.zipCode",
      tag: "cep",
      label: "CEP",
      placeholder: "Preencher",
      required: true,
    },
    street: {
      id: "address.street",
      name: "address.street",
      tag: "text",
      label: "Rua",
      placeholder: "Preencher",
      required: true,
    },
    number: {
      id: "address.number",
      name: "address.number",
      tag: "text",
      label: "Número",
      placeholder: "Preencher",
    },
    complement: {
      id: "address.complement",
      name: "address.complement",
      tag: "text",
      label: "Complemento",
      placeholder: "Preencher",
      required: false,
    },
    neighborhood: {
      id: "address.neighborhood",
      name: "address.neighborhood",
      tag: "text",
      label: "Bairro",
      placeholder: "Preencher",
      required: true,
    },
    state: {
      id: "address.state",
      name: "address.state",
      label: "Estado",
      placeholder: "Selecionar",
      required: true,
    },
  },
} as const satisfies MaestroFields<AddressForm>;

const stringRequired = yup.string().required("Campo obrigatório").trim();

export const addressFormValidationSchema = yup.object({
  address: yup.object<
    AddressForm["address"],
    YupObjectSchema<AddressForm["address"]>
  >({
    city: stringRequired,
    neighborhood: stringRequired,
    state: stringRequired,
    street: stringRequired,
    zipCode: stringRequired.test("zipCode", "CEP inválido", (value) =>
      validators.cep(value),
    ),
  }),
});

export const addressFormDefaultValues = {
  address: {
    city: null,
    complement: null,
    neighborhood: null,
    number: null,
    state: null,
    street: null,
    zipCode: null,
    country: null,
  },
} satisfies Partial<DeepNullable<AddressForm>> as unknown as AddressForm;
