import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, importDataListGrid } from "./import-data-list.grid";
import {
  ExecuteBatchButton,
  RemoveBatchButton,
  ReprocessBatchButton,
} from "./_compose/batch-buttons";

export const ImportDataListPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Importação de unidades consumidoras" />}
      gridRef={gridRef}
      actions={
        <>
          <RemoveBatchButton
            gridRef={gridRef}
            reload={() => dataSource.reload()}
          />
          <ReprocessBatchButton
            gridRef={gridRef}
            reload={() => dataSource.reload()}
          />
          <ExecuteBatchButton
            gridRef={gridRef}
            reload={() => dataSource.reload()}
          />
          <RouterButton
            href={
              corporateRouter.routes.energia.product.consumerUnitsImport
                .spreadsheet.path
            }
            role={roles.energia.product.consumerUnitsImportSpreadsheet.role}
          >
            Enviar planilha
          </RouterButton>
        </>
      }
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={importDataListGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
