import { Outlet, useLocation } from "react-router-dom";
import { Route } from "routes/routes.types";
import { customMatchRoutes } from "routes/routes.utils";
import { findTabType, useMenuTabs } from "../tab-menu";
import { MenusLayout } from "./menus-layout.component";

interface ContentProps {
  route: Route;
  element: React.ReactNode;
}

export const Content = ({ route, element }: ContentProps) => {
  const location = useLocation();
  const menuTabs = useMenuTabs();

  const parentRouteTabType = findTabType(menuTabs, route.path, true);

  const isActiveRoute = customMatchRoutes(route.path, location.pathname, true);

  const renderElement = element && isActiveRoute ? <>{element}</> : <Outlet />;

  const isTabParentRoute = !!parentRouteTabType;

  if (isTabParentRoute) {
    return (
      <MenusLayout
        element={renderElement}
        route={route}
        tabType={parentRouteTabType}
      />
    );
  }

  return renderElement;
};
