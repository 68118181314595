import { OToastManager } from "@maestro/core";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { RelationshipContingencies } from "../../../../components/contingencias-relacionamento";
import { CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams } from "../../../../routes/cadastro.route-params";

export const GuarantorContingencies = () => {
  const { guarantorUuid } =
    useParams<CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams>();
  if (!guarantorUuid)
    throw new Error("No guarantor workflow uuid in route parameters");

  const [guarantor, setGuarantor] =
    useState<HubOnboardingRelationships.Guarantor.Guarantor>();

  const getRepresentativeWorkflow = useCallback(async () => {
    try {
      const { data } = await service.onboardingRelationships.getGuarantor({
        getOnboardingByUuidQueryBody: {
          onboardingUuid: guarantorUuid,
        },
      });
      setGuarantor(data);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o workflow");
    }
  }, [guarantorUuid]);

  useEffect(() => {
    getRepresentativeWorkflow();
  }, [getRepresentativeWorkflow]);

  return (
    <div>
      <PageTitle title="Contingências Avalista" />
      {guarantor && (
        <RelationshipContingencies entity={guarantor} relationship="AVALISTA" />
      )}
    </div>
  );
};
