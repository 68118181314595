import { MaestroFields } from "utils/types";
import { UserDetailsFormPayload } from "../../user-details.type";

export const updateUserFields = {
  name: {
    tag: "text",
    id: "name",
    name: "name",
    dataAction: "atualizar_usuario:texto:nome",
    dataLabel: "nome",
    label: "Nome",
  },
  birthDate: {
    id: "birthDate",
    name: "birthDate",
  },
  phone: {
    tag: "phone",
    id: "phone",
    name: "phone",
    dataAction: "atualizar_usuario:texto:telefone",
    dataLabel: "telefone",
    label: "Telefone",
  },
  isActive: {
    dataAction: "atualizar_usuario:texto:ativo",
    dataLabel: "ativo",
    id: "isActive",
    name: "isActive",
    label: "Usuário ativo",
  },
  cpf: {
    tag: "cpf",
    id: "cpf",
    name: "cpf",
    dataAction: "atualizar_usuario:texto:cpf",
    dataLabel: "cpf",
    label: "CPF",
    disabled: true,
  },
  email: {
    tag: "text",
    id: "email",
    name: "email",
    dataAction: "atualizar_usuario:texto:email",
    dataLabel: "email",
    label: "E-mail",
    disabled: true,
  },
  accountStatus: {
    tag: "text",
    id: "accountStatus",
    name: "accountStatus",
    dataAction: "atualizar_usuario:texto:status_conta",
    dataLabel: "status_conta",
    disabled: true,
    label: "Status do Login",
  },
  biometryStatus: {
    tag: "text",
    id: "biometryStatus",
    name: "biometryStatus",
    dataAction: "atualizar_usuario:texto:status_biometria",
    dataLabel: "status_biometria",
    disabled: true,
    label: "Status da Biometria",
  },
  timePenalty: {
    id: "timePenalty",
    name: "timePenalty",
    dataAction: "atualizar_usuario:texto:time_penalty",
    dataLabel: "time_penalty",
    disabled: true,
    label: "Penalidade de tempo",
  },
} as const satisfies MaestroFields<UserDetailsFormPayload>;

export const updateUserDefaultValues: UserDetailsFormPayload = {
  name: "",
  birthDate: "",
  phone: "",
  isActive: "",
  cpf: "",
  email: "",
  accountStatus: "",
  biometryStatus: "",
  timePenalty: "",
};
