import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCustomer } from "contexts/customer";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./monitor-aml.grid";

export const MonitorAML = () => {
  const { customer } = useCustomer();

  const dataSource =
    dataSourceCustomStoreGenerator<HubOnboarding.Onboarding.AMLResponseView>(
      () =>
        service.onboarding
          .getAMLMonitor()
          .then(({ data }) =>
            data.value.filter((value) => {
              return value.TaxId === customer.identification;
            }),
          )
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar reprovações";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
    );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Reprovações de AML" />}
      actions={<RefreshGridButton onClick={() => dataSource.reload()} />}
    >
      <ODataGridGenerator grid={grid} dataSource={dataSource} />
    </GridTemplate>
  );
};
