import { OIcon, OTypography } from "@maestro/react";
import { CustomTokens } from "../../../../../../components/augmented-status-badge/augmented-status-badge.styles";
import { Badge } from "./badges.style";

interface NewPendencyBadgeProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

export const NewPendencyBadge = ({ pendency }: NewPendencyBadgeProps) => {
  if (pendency.status !== "CRIADA") return null;

  return (
    <>
      <CustomTokens />
      <Badge
        backgroundColor="var(--theme-custom-violet-light)"
        className="px-2 d-flex align-items-center gap-2"
      >
        <OIcon category="far" icon="fa-stars" type="current-color" size="sm" />
        <OTypography size="sm">Nova</OTypography>
      </Badge>
    </>
  );
};
