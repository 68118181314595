import { OButton } from "@maestro/react";
import { useStepsBuilder } from "../../steps-builder.context";

interface PreviousStepButtonProps
  extends Omit<
    React.ComponentProps<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  label?: string;
}

export const PreviousStepButton = (props: PreviousStepButtonProps) => {
  const { onClick, label = "Voltar" } = props;

  const { stepNumber, prevStep } = useStepsBuilder();

  return (
    <OButton
      dataAction={`acoes_step_${stepNumber}:botao:voltar`}
      dataLabel="voltar"
      type="dark"
      outline
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        prevStep();
      }}
    >
      {label}
    </OButton>
  );
};
