import { ODataGridGeneratorConfig } from "components/data-grid";
import { TaxasPreCurvaItem } from "services/senna/models";

export const grid: ODataGridGeneratorConfig<TaxasPreCurvaItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "dataBase",
      caption: "Data Base",
      dataType: "date",
      format: "shortDate",
      sortOrder: "desc",
    },
    {
      dataField: "diasCorridos",
      caption: "Dias Corridos",
    },
    {
      dataField: "dataFutura",
      caption: "Data Futura",
      dataType: "date",
      format: "shortDate",
    },
    {
      dataField: "taxa",
      caption: "Taxa",
      format: (value) => `${value.toFixed(4)}%`,
    },
  ],
};
