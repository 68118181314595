import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { InternacionalUserForm } from "../add-internacional-user.type";

export const useBasicFields = (editing: boolean | undefined) => {
  const [phoneDefaultValue, setPhoneDefaultValue] = useState("");

  const { callService: getUserData, loading: getUserDataLoading } =
    useServiceCall(service.onboardingRelationships.getUserData);
  const { callService: getCountries, value } = useServiceCall(
    service.onboarding.getCountries,
  );

  const form = useFormContext<InternacionalUserForm>();
  const { clearErrors, setError, setValue, watch } = form;
  const taxIdWatcher = watch("user.identification");
  const [disableEmail, setDisableEmail] = useState<boolean>(false);

  const getAllCountries = useCallback(async () => {
    const { success, response } = await getCountries();
    if (!success) {
      OToastManager.danger("Não foi possível encontrar os países");
    } else {
      return response.data;
    }
  }, [getCountries]);

  useEffect(() => {
    setPhoneDefaultValue("");
    if (!editing && validators.cpfCnpj(taxIdWatcher)) {
      getUserData({ taxId: taxIdWatcher }).then(({ success, response }) => {
        if (success) {
          setValue("user.name", response.data.name);
          setValue("user.email", response.data.email);
          setPhoneDefaultValue(response.data.phone);
          setValue("user.phoneNumber", response.data.phone);
          clearErrors("user.identification");
          if (response.data?.email) setDisableEmail(true);
          else setDisableEmail(false);
        } else {
          setError("user.identification", {
            message: "Não foi possível encontrar o CPF",
          });
        }
      });
    }
  }, [clearErrors, editing, getUserData, setError, setValue, taxIdWatcher]);

  const countries = useMemo(
    () =>
      value?.map<SelectSearchMultipleOption<string>>((country) => ({
        customKey: country.code,
        label: `${country.code?.toUpperCase()} - ${country.name?.toUpperCase()}`,
        value: country.code,
      })) ?? [],
    [value],
  );

  return {
    phoneDefaultValue,
    getUserDataLoading,
    disableEmail,
    countries,
    getAllCountries,
  };
};
