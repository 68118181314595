import { client } from "../../client";
import { hubPartnersEndpoints } from "../hubpartners.endpoints";
import {
  PutOperatorsByOperatorProfileUUIDHierarchiesBody,
  PutOperatorsByOperatorProfileUUIDUnlinkBody,
} from "./models/request";
import {
  GetOperatorProfileDetailsByIdentifierResponse,
  GetOperatorsByOperatorProfileUUIDHierarchiesResponse,
  GetOperatorsSearchResponse,
} from "./models/responses";

export const portalService = {
  getOperators() {
    client.get<GetOperatorsSearchResponse>(
      hubPartnersEndpoints.operatorProfiles.search,
    );
  },
  getOperatorProfileDetails(operatorProfileIdentifier: string) {
    return client.post<GetOperatorProfileDetailsByIdentifierResponse>(
      hubPartnersEndpoints.operatorProfiles.byIdentifier.details,
      {
        operatorProfileIdentifier,
      },
    );
  },
  getOperatorHierarchy(operatorProfileUUID: string) {
    return client.get<GetOperatorsByOperatorProfileUUIDHierarchiesResponse>(
      hubPartnersEndpoints.operatorProfiles.byOperatorProfileUUID.hierarchies(
        operatorProfileUUID,
      ),
    );
  },
  updateOperatorHierarchies(
    operatorProfileUUID: string,
    body: PutOperatorsByOperatorProfileUUIDHierarchiesBody,
  ) {
    return client.put(
      hubPartnersEndpoints.operatorProfiles.byOperatorProfileUUID.hierarchies(
        operatorProfileUUID,
      ),
      body,
    );
  },
  unlinkOperator(
    operatorProfileUUID: string,
    body: PutOperatorsByOperatorProfileUUIDUnlinkBody,
  ) {
    return client.put(
      hubPartnersEndpoints.operatorProfiles.byOperatorProfileUUID.unlink(
        operatorProfileUUID,
      ),
      body,
    );
  },
};
