import { OConfirmationModal, modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { IconButton } from "components/icon-button";
import { StatusBadge } from "components/status-badge";
import styled from "styled-components";
import { DetailsTemplate } from "templates/details-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { fraudVerificationsStatusMap } from "./fraud-verification.utils";

const StyledPre = styled.pre`
  background: #2c3e50;
  color: #ecf0f1;
  height: 400px;
  line-height: 150%;
  overflow-y: auto;
  padding: 20px;
  text-align: left;
`;

export const fraudVerificationGrid: ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.FraudVerificationsItem> =
  {
    datagrid: {
      noDataText: "Nenhuma validação de segurança.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "user.taxId",
        dataType: "string",
        caption: "CPF",
        customizeText: ({ valueText }) => masks.cpf(valueText),
      },
      {
        dataField: "user.name",
        dataType: "string",
        caption: "Nome",
      },
      {
        dataField: "status",
        dataType: "string",
        caption: "Status",
        cellRender: ({ data }) => {
          const mappedStatus = getValueFromMap(
            fraudVerificationsStatusMap,
            data.status,
          );

          return mappedStatus ? (
            <StatusBadge type={mappedStatus.type}>
              {mappedStatus.label}
            </StatusBadge>
          ) : (
            data.status
          );
        },
      },
      {
        dataField: "createdAt",
        caption: "Data de criação",
        dataType: "date",
        format: "shortDateShortTime",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        dataField: "updatedAt",
        caption: "Data de atualização",
        dataType: "date",
        format: "shortDateShortTime",
        alignment: "center",
        allowHeaderFiltering: false,
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data }) => {
          const modalId = `fraud-verification-${data.user.taxId}-modal`;
          return (
            <>
              <DataGridAction
                actions={[
                  {
                    icon: { category: "orq", icon: "orq-unified-invoice" },
                    label: "Exibir JSON",
                    onClick: () => modalManager.show(modalId),
                  },
                ]}
              />
              <OConfirmationModal
                modalId={modalId}
                title="Request JSON"
                confirmLabel="Ok"
                divider={false}
                size="md"
                onConfirm={() => modalManager.hide(modalId)}
              >
                <DetailsTemplate
                  actions={
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(
                          JSON.stringify(
                            {
                              ...data,
                              jsonMessage: JSON.parse(data.jsonRequest),
                            },
                            null,
                            2,
                          ),
                        )
                      }
                      icon={{ category: "orq", icon: "orq-edit-copy" }}
                    >
                      Copiar
                    </IconButton>
                  }
                >
                  <StyledPre>
                    {JSON.stringify(
                      { ...data, jsonMessage: JSON.parse(data.jsonRequest) },
                      null,
                      2,
                    )}
                  </StyledPre>
                </DetailsTemplate>
              </OConfirmationModal>
            </>
          );
        },
      },
    ],
  };
