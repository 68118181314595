import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  GetApiAdminScoreBenefitsItem,
  GetApiAdminScoreBenefitsResponse,
} from "services/hubcreditmanager/models/responses";
import {
  DELETE_SCORE_BENEFIT_MODAL,
  DeleteScoreBenefitModal,
} from "./delete-score-benefit-modal.component";

interface DraggableScoreBenefitsProps {
  benefits: GetApiAdminScoreBenefitsResponse | undefined;
  getScoreBenefits: () => Promise<unknown>;
}

export const DraggableScoreBenefits = ({
  benefits,
  getScoreBenefits,
}: DraggableScoreBenefitsProps) => {
  const navigate = useNavigate();
  const dragItem = useRef<number>(0);
  const dragOverItem = useRef<number>(0);

  const [selectedBenefit, setSelectedBenefit] = useState<
    GetApiAdminScoreBenefitsItem | undefined
  >();

  const { show } = modalManager;

  const updateBenefitsPositions = useCallback(async () => {
    if (!benefits?.length) return;

    let benefitsListAux = benefits.map((m) => ({ ...m })); // .splice() e [...array] nao funcionam para array em um state
    const dragItemContent = benefitsListAux[dragItem.current];

    benefitsListAux.splice(dragItem.current, 1);
    benefitsListAux.splice(dragOverItem.current, 0, dragItemContent);
    benefitsListAux = benefitsListAux.map((benefit, index) => ({
      ...benefit,
      order: index,
    }));

    if (
      benefitsListAux[dragItem.current]?.id === benefits[dragItem.current]?.id
    )
      return;

    try {
      await service.hubCreditManager.updateScoreBenefitsPositions({
        orders: benefitsListAux.map((cl) => ({
          scoreBenefitId: cl.id,
          order: cl.order,
        })),
      });

      await getScoreBenefits();
    } catch {
      OToastManager.danger(
        "Não foi possível atualizar as posições dos benefícios",
      );
    }
  }, [getScoreBenefits, benefits]);

  return (
    <>
      <div className="d-flex flex-column gap-1">
        {benefits?.map((benefit, index) => {
          return (
            <OCard
              onDragStart={() => {
                dragItem.current = index;
              }}
              onDragEnter={() => {
                dragOverItem.current = index;
              }}
              onDragEnd={updateBenefitsPositions}
              draggable
              key={benefit.id}
            >
              <OCardBody>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                      <OTypography
                        tag="h4"
                        weight="normal"
                        size="lg"
                        type="dark"
                        key={benefit.name}
                      >
                        {benefit.name}
                      </OTypography>
                      <OButton
                        type="light"
                        onClick={() => {
                          navigate(
                            corporateRouter.routes.workflow.product.originatorScore.benefits.edit.path(
                              {
                                scoreBenefitId: benefit.id,
                              },
                            ),
                          );
                        }}
                      >
                        <OIcon category="fal" icon="fa-pencil" />
                      </OButton>
                    </div>
                    <OButton
                      type="light"
                      onClick={() => {
                        setSelectedBenefit(benefit);
                        show(DELETE_SCORE_BENEFIT_MODAL);
                      }}
                    >
                      <OIcon category="orq" icon="orq-close" size="lg" />
                    </OButton>
                  </div>

                  <OTypography type="dark-60" key={benefit.description}>
                    {benefit.description}
                  </OTypography>
                  <ORow className="w-50">
                    {benefit.benefitLevels.map((benefitLevel) => (
                      <OCol
                        key={benefitLevel.id}
                        className="d-flex flex-column gap-2 align-items-start"
                      >
                        <OTypography key={benefitLevel.level.name}>
                          {benefitLevel.level.name}
                        </OTypography>

                        {benefitLevel.description ? (
                          <OTypography
                            key={benefitLevel.description}
                            type="dark-60"
                          >
                            {benefitLevel.description}
                          </OTypography>
                        ) : (
                          <OIcon
                            size="xxxl"
                            type={benefitLevel.isActive ? "success" : "danger"}
                            category="orq"
                            icon={
                              benefitLevel.isActive
                                ? "orq-status-success"
                                : "orq-status-disapproved"
                            }
                          />
                        )}
                      </OCol>
                    ))}
                  </ORow>
                </div>
              </OCardBody>
            </OCard>
          );
        })}
      </div>
      <DeleteScoreBenefitModal
        getScoreBenefits={getScoreBenefits}
        benefit={selectedBenefit}
      />
    </>
  );
};
