import { BankIconSVG } from "../financial-institution-icon.types";

export const BankSantanderSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={56}
        cy={56}
        r={55.25}
        fill="#fff"
        stroke="#E9EBEF"
        strokeWidth={1.5}
      />
      <path
        fill="#EA1D25"
        d="M67.368 44.273c1.096 1.781 1.644 3.836 1.781 5.89 10.137 2.603 16.986 8.22 16.849 14.384 0 9.04-13.424 16.3-29.999 16.3S26 73.587 26 64.547c0-6.439 6.986-12.055 16.986-14.657 0 2.465.548 4.93 1.78 7.123l9.452 16.3c.685 1.233 1.233 2.603 1.507 3.973l.411-.685c2.329-3.972 2.329-9.04 0-13.013l-7.534-13.014c-2.329-4.109-2.329-9.04 0-13.013l.41-.685c.275 1.37.823 2.74 1.508 3.973l4.383 7.67 6.85 11.918c.684 1.233 1.232 2.603 1.506 3.973l.411-.685c2.329-3.973 2.329-9.041 0-13.014l-7.534-13.013c-2.329-3.972-2.329-9.04 0-13.013l.41-.685c.275 1.37.823 2.74 1.508 3.973l9.314 16.3z"
      />
    </svg>
  );
};
