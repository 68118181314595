import React from "react";
import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { creditConciliationValidationStatusMap } from "../../credit-conciliation.utils";

interface DownloadButtonProps {
  status: string | undefined;
}

export const StatusBadgeComponent: React.FC<DownloadButtonProps> = ({
  status,
}) => {
  const statusMap = getValueFromMap(
    creditConciliationValidationStatusMap,
    status,
  );

  return status ? (
    <div>
      <StatusBadge
        type={statusMap?.type ?? "info"}
        size="md"
        className="d-inline-block"
      >
        {statusMap?.label}
      </StatusBadge>
    </div>
  ) : null;
};
