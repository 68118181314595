/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
import _ from "lodash";
import { isAxiosError } from "axios";
import { service } from "services";
import { logger } from "utils/logger";
import { Customer } from "./customer";
import { CustomerCommand } from "./types/command";
import { CustomerType } from "./types/customer.type";
import { CustomerState } from "./types/state";

export class GetCustomerCommand implements CustomerCommand {
  private taxId: string;

  constructor(taxId: string) {
    this.taxId = taxId;
  }

  async execute(customer: Customer) {
    logger.debug(`[customer]GetCustomerCommand. TaxId: ${this.taxId}`);

    try {
      if (customer?.value) {
        customer.value = undefined;
      }
      customer.state = CustomerState.LOADING;

      const { data: clerkData } = await service.clerk.admin.getCompanyDetails({
        taxId: this.taxId,
      });

      if (!clerkData || !clerkData?.identification)
        throw new Error("Could not get customer");

      const quickfinData = await this.getQuickfinData(clerkData.uuid);
      customer.value = {
        ...clerkData,
        quickfinId: quickfinData.id,
      } as CustomerType;
      customer.state = CustomerState.SELECTED;

      logger.debug("[customer]GetCustomerCommand. Selected");
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);

      customer.value = undefined;
      customer.state = this.isCompanyNotFoundError(err)
        ? CustomerState.NOT_FOUND
        : CustomerState.ERROR;

      logger.debug("[customer]GetCustomerCommand. Error");
    }
  }

  async getQuickfinData(UUID: string | null) {
    let quickfinResponse;
    // Try first with UUID (offshore)
    if (UUID) {
      try {
        quickfinResponse = await service.quickfin.getCompanyByUUID(UUID);
      } catch {
        logger.debug("get company by UUID failed");
      }
    }
    // Try get by identification when was not possible get by UUID
    if (
      !quickfinResponse ||
      (quickfinResponse && quickfinResponse.status !== 200)
    ) {
      quickfinResponse = await service.quickfin.getCompanyByTaxId(this.taxId);
    }
    return quickfinResponse.data;
  }

  isCompanyNotFoundError(error: any) {
    const errorCode = _.get(error, "response.data.failures[0].errorCode");
    if (errorCode === "COMPANY_NOT_FOUND") return true;
    return false;
  }
}

export class ClearCustomerCommand implements CustomerCommand {
  async execute(customer: Customer) {
    logger.debug("[customer]ClearCustomerCommand");

    customer.value = undefined;
    customer.state = CustomerState.EMPTY;
  }
}
