import { client } from "../../client";
import { adminBankinghubEndpoints } from "../admin-bankinghub.endpoints";
import {
  CreateAgreementRequest,
  CreateFloatingParams,
  GetAgreementsResponse,
  UpdateAgreementRequest,
  UpdateFloatingParams,
} from "./models";

export const adminBankinghubCollectionService = {
  getAgreements() {
    return client.get<GetAgreementsResponse>(
      adminBankinghubEndpoints.collection.agreement.endpoint,
    );
  },

  createAgreement(params: CreateAgreementRequest) {
    return client.post(
      adminBankinghubEndpoints.collection.agreement.endpoint,
      params,
    );
  },

  deleteAgreement(agreementId: string) {
    return client.delete(
      adminBankinghubEndpoints.collection.agreement.byId(agreementId),
    );
  },

  updateAgreement(agreementId: string, params: UpdateAgreementRequest) {
    return client.put(
      adminBankinghubEndpoints.collection.agreement.byId(agreementId),
      params,
    );
  },

  createFloating(params: CreateFloatingParams) {
    return client.post(adminBankinghubEndpoints.beneficiary.floating, params);
  },

  updateFloating(params: UpdateFloatingParams) {
    return client.put(adminBankinghubEndpoints.beneficiary.floating, params);
  },

  cancelFloating() {
    return client.delete(adminBankinghubEndpoints.beneficiary.floating);
  },
};
