import { generatePath } from "react-router-dom";
import type { CorporateRoutes } from "./corporate-router.types";
import type { Routes } from "./routes.types";

const isProxy = Symbol("isProxy");

/**
 * Proxy to make accessing routes easier than just using `routes` object directly
 *
 * Source: https://stackoverflow.com/a/50723478
 * @example
 * // instead of doing this
 * routes.cadastro.children.customer.children.details.path
 * // the proxy allows this
 * routes.cadastro.customer.details.path
 */
const proxyHandler: ProxyHandler<Routes> = {
  // already strongly typed with `CorporateRouter`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(target: any, key) {
    if (key === isProxy) return true;

    let next = target[key];

    // target["path"] is a string, just return it
    if (key === "path") {
      // if path has parameters, return a function that takes and fills them
      if ((next as string).match(/:/)) {
        return (params: Record<string, string>) => generatePath(next, params);
      }
      // else, just return the path string
      return next;
    }

    // no path in target, therefore it must be a child route
    // skip children property and retur child directly
    if (!next) {
      next = target.children[key];
    }

    return new Proxy(next, proxyHandler);
  },
};

/**
 * Proxy to make accessing routes easier than just using `routes` object directly
 * @example
 * // instead of doing this
 * routes.cadastro.children.customer.children.details.path
 * // the proxy allows this
 * const { routes } = corporateRouter
 * routes.cadastro.customer.details.path
 */
export const corporateRouter = {
  routes: {} as CorporateRoutes,
};

export const makeRoutesProxy = (routesObject: Routes) => {
  return new Proxy(routesObject, proxyHandler) as unknown as CorporateRoutes;
};
