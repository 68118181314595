import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const ContractFileConfigurationForm = () => {
  const {
    callService: getContractFileConfigurationEnums,
    loading,
    value: enums,
    hasError,
  } = useServiceCall(service.onboarding.getContractFileConfigurationEnums);

  useEffect(() => {
    getContractFileConfigurationEnums();
  }, [getContractFileConfigurationEnums]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Nome do arquivo"
                tag="text"
                id="fileName"
                name="fileName"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Ordem de prioridade do arquivo"
                tag="number"
                id="filePriorityOrder"
                name="filePriorityOrder"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                disabled={loading}
                id="documentLessId"
                name="documentLessId"
                label="Document Less id"
                placeholder="Selecione"
                key={enums?.documentIntegrationIds?.length}
              >
                {enums?.documentIntegrationIds?.map((documentIntegrationId) => (
                  <OOption
                    key={documentIntegrationId.intValue}
                    value={documentIntegrationId.intValue}
                  >
                    {documentIntegrationId.stringValue}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loading && <OLoader className="d-block" size="sm" />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getContractFileConfigurationEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Less document code"
                tag="text"
                id="lessDocumentCode"
                name="lessDocumentCode"
              />
            </OCol>
          </ORow>
          <ORow>
            <ORFieldInput
              label="Descrição"
              tag="text"
              id="description"
              name="description"
            />
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
