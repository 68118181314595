import { OBadge, OBanner } from "@maestro/react";
import styled from "styled-components";

export const StyledOBanner = styled(OBanner)`
  o-card {
    background-color: #87baff;
  }
`;

export const StyledOBadge = styled(OBadge)`
  span {
    background-color: #b1d2ff;
  }
`;
