import { masks, validators } from "@maestro/utils";
import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { service } from "services";
import { ContractSignatoriesForm } from "./contract-signatories-form.types";

export const useContractSignatoriesFormFields = (
  form: UseFormReturn<ContractSignatoriesForm>,
  disableTaxId: boolean | undefined,
) => {
  const {
    callService: getCompanyDetails,
    loading: getCompanyLoading,
    value: companyDetails,
  } = useServiceCall(service.clerk.admin.getCompanyDetails);

  const { clearErrors, reset, setError, watch } = form;

  const taxIdWatcher = watch("taxId");

  const legalEntitySearch = useCallback(
    async (identification: string | undefined) => {
      if (identification && identification?.length >= 11) {
        // early return
        if (!validators.cpfCnpj(identification))
          return setError("taxId", {
            message: "Documento inválido",
          });

        const { success, response } = await getCompanyDetails({
          taxId: identification,
        });

        // early return
        if (!success)
          return setError("taxId", {
            message: "Não foi possível encontrar o documento",
          });

        const hasLegalRepresentatives = !!response.data.users?.filter(
          (user) => user.legalRepresentative && user.cpf,
        ).length;

        // early return
        if (!hasLegalRepresentatives)
          return setError("taxId", {
            message: "Empresa não tem representantes legais",
          });

        clearErrors("taxId");
        if (!disableTaxId)
          reset({ identifications: [] }, { keepDirtyValues: true });
      }
    },
    [clearErrors, disableTaxId, getCompanyDetails, reset, setError],
  );

  useEffect(() => {
    legalEntitySearch(taxIdWatcher);
  }, [legalEntitySearch, taxIdWatcher]);

  const options = useMemo(
    () =>
      companyDetails?.users
        ?.filter((user) => user.legalRepresentative && user.cpf)
        ?.map<SelectSearchMultipleOption<string>>((user) => ({
          value: user.cpf!, // filtered
          label: `${user.name} - ${masks.cpf(user.cpf)}`,
        })) ?? [],
    [companyDetails?.users],
  );

  const hasOptions = options.length > 0;

  return {
    form,
    getCompanyLoading,
    hasOptions,
    options,
  };
};
