import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

import { service } from "services";

export type DocumentGroup = Awaited<
  ReturnType<typeof service.hubFx.nature.getNatureDocumentGroups>
>["data"][number];

export const exchangeProductDocumentGroupsGrid = {
  datagrid: {
    noDataText: "Nenhum grupo de documento encontrado.",
    dataSource: dataSourceCustomStoreGenerator(
      () =>
        service.hubFx.nature.getNatureDocumentGroups().catch(() => {
          throw new Error("Erro ao buscar grupo de documentos");
        }),
      {
        customStoreOptions: {
          remove: async (removedDocuemntGroup) => {
            try {
              if (!removedDocuemntGroup.id) return;

              await service.hubFx.nature.deleteNatureDocumentGroups(
                removedDocuemntGroup.id,
              );

              OToastManager.success(
                "O grupo de documento foi removida com sucesso.",
              );
            } catch {
              OToastManager.danger(
                "Um erro ocorreu ao tentar deletar o grupo de documento. Por favor, tente novamente mais tarde.",
              );
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "documentConfigs",
      caption: "Documento",
      customizeText: ({ value }) =>
        value?.map((doc) => doc.typeName).join(", ") ?? "",
    },
    {
      dataField: "documentConfigs",
      caption: "Descrição",
      customizeText: ({ value }) =>
        value?.map((doc) => doc.typeDescription).join(", ") ?? "",
    },
    {
      dataField: "isRequired",
      caption: "Obrigatório?",
    },
    {
      dataField: "mustShowForPf",
      caption: "Mostrar para PF?",
    },
    {
      dataField: "mustShowForPj",
      caption: "Mostrar para PJ?",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "fas", icon: "fa-pen" },
              label: "Editar",
              role: roles.cambio.product
                .contingencyNaturesDocumentGroupsUpdatePage.role,
              route:
                corporateRouter.routes.cambio.product.contingency.natures.documentGroups.update.path(
                  {
                    id: data.id,
                  },
                ),
            },
            {
              icon: { category: "fas", icon: "fa-trash" },
              label: "Remover",
              onClick: () => {
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<DocumentGroup>;
