import * as yup from "yup";

export const minimumCriteriaFormValidationSchema = yup.object().shape({
    title: yup.string().required("Campo obrigatório"),
    type: yup.string().nullable(),
    description: yup.string().required("Campo obrigatório"),
    isVisible: yup.array().default([]),
    validationLocation: yup.string().nullable(),
});

export const minimumCriteriaFormDefaultValues = {
    title: "",
    type: "",
    description: "",
    isVisible: [] as string[],
    validationLocation: "",
};