import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  booksAndStrategiesGrid,
  booksAndStrategiesDataSource,
} from "./books-and-strategies.grid";
import { booksDataSource, booksGrid } from "./books.grid";
import { strategiesDataSource, strategiesGrid } from "./strategies.grid";

export const BooksAndStrategiesPage = () => {
  const booksAndStrategiesGridRef = useGridRef();
  const booksGridRef = useGridRef();
  const strategiesGridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-4">
      <GridTemplate
        pageTitle={<PageTitle title="Books / Estratégias" />}
        gridRef={booksAndStrategiesGridRef}
        showRefreshButton
        actions={
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_templates_de_contrato:botao:adicionar"
            dataLabel="adicionar"
            href={
              corporateRouter.routes.emprestimos.product.contingency
                .booksAndStrategies.add.path
            }
            role={
              roles.emprestimos.product.contingencyBooksAndStrategiesAddPage
                .role
            }
            outline
          >
            Adicionar
          </RouterButton>
        }
      >
        <ODataGridGenerator
          gridRef={booksAndStrategiesGridRef}
          grid={booksAndStrategiesGrid}
          dataSource={booksAndStrategiesDataSource}
        />
      </GridTemplate>
      <div>
        <OTypography type="dark" tag="h3" size="lg">
          Books
        </OTypography>
        <GridTemplate
          gridRef={booksGridRef}
          showRefreshButton
          actions={
            <RouterButton
              type="tertiary"
              dataAction="emprestimos_templates_de_contrato:botao:adicionar-book"
              dataLabel="adicionar-book"
              href={
                corporateRouter.routes.emprestimos.product.contingency
                  .booksAndStrategies.addBook.path
              }
              role={
                roles.emprestimos.product
                  .contingencyBooksAndStrategiesAddBookPage.role
              }
              outline
            >
              Adicionar book
            </RouterButton>
          }
        >
          <ODataGridGenerator
            gridRef={booksGridRef}
            grid={booksGrid}
            dataSource={booksDataSource}
          />
        </GridTemplate>
      </div>

      <div>
        <OTypography type="dark" tag="h3" size="lg">
          Estratégias
        </OTypography>
        <GridTemplate
          gridRef={strategiesGridRef}
          showRefreshButton
          actions={
            <RouterButton
              type="tertiary"
              dataAction="emprestimos_templates_de_contrato:botao:adicionar-estrategia"
              dataLabel="adicionar-estrategia"
              href={
                corporateRouter.routes.emprestimos.product.contingency
                  .booksAndStrategies.addStrategy.path
              }
              role={
                roles.emprestimos.product
                  .contingencyBooksAndStrategiesAddStrategyPage.role
              }
              outline
            >
              Adicionar estratégia
            </RouterButton>
          }
        >
          <ODataGridGenerator
            gridRef={strategiesGridRef}
            grid={strategiesGrid}
            dataSource={strategiesDataSource}
          />
        </GridTemplate>
      </div>
    </div>
  );
};
