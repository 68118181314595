import groupBy from "lodash/groupBy";
import { GetAdminLimitRequestsByIdCollateralsResponse } from "services/hubcreditmanager/models/responses";
import * as yup from "yup";

export const collateralsToFormalize = ["CAC", "IMOVEL", "NOTA_PROMISSORIA"];

export const formalizeCollateralFormValidationsSchema = (
  collaterals: GetAdminLimitRequestsByIdCollateralsResponse | undefined,
) => {
  const collateralsCanFormalize = collaterals?.filter((c) =>
    collateralsToFormalize.includes(c.collateralType),
  );

  const groupedCollaterals = groupBy(collateralsCanFormalize, "collateralType");

  const collateralFormalized = Object.keys(groupedCollaterals).filter((key) =>
    groupedCollaterals[key].some((c) => c.formalized),
  );

  let defaultSchema = yup.object().shape({});

  collateralFormalized.forEach((c) => {
    defaultSchema = defaultSchema.shape({
      [`contract-${c}`]: yup
        .string()
        .test(
          "collateral-formalized-check",
          "Campo obrigatório",
          (value, ctx) =>
            !ctx.parent.collateralFormalized.includes(c) || !!value,
        ),
      [`signed-date-${c}`]: yup
        .string()
        .test(
          "collateral-formalized-check",
          "Campo obrigatório",
          (value, ctx) =>
            !ctx.parent.collateralFormalized.includes(c) || !!value,
        ),
    });
  });

  return defaultSchema;
};
