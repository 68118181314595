import {
  OCollapse,
  OCollapseContent,
  OCollapseHeader,
  OTextarea,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import dayjs from "dayjs";
import { ReceivableStateMachineByIdEventsItem } from "services/quickfin/models";
import { useCollapseEvent } from "./collapse-event.hook";
import { FormattedText } from "./collapse-event.styles";

interface CollapseEventProps {
  event: ReceivableStateMachineByIdEventsItem;
}

export const CollapseEvent = ({ event }: CollapseEventProps) => {
  const {
    editedMessage,
    isEditing,
    loading,
    republish,
    setEditedMessage,
    setIsEditing,
  } = useCollapseEvent();

  return (
    <OCollapse key={event.id}>
      <OCollapseHeader>
        <OTypography>
          {event.eventName} -{" "}
          {dayjs(event.createdAt).format("DD/MM/YYYY hh:mm:ss")}
        </OTypography>
      </OCollapseHeader>
      <OCollapseContent>
        <div className="py-2">
          {isEditing ? (
            <OTextarea
              id="event-edit-text-area"
              name="event-edit-text-area"
              dataAction="evento:textarea:editar_evento"
              dataLabel="editar_evento"
              value={editedMessage || event.eventData}
              onInput={(e) => setEditedMessage(e.currentTarget?.value)}
              adaptHeight
            />
          ) : (
            <FormattedText>{event.eventData}</FormattedText>
          )}

          <div className="d-flex justify-content-end gap-2">
            <LoadingButton
              dataAction="evento:botao:editar"
              dataLabel="editar"
              type="default"
              onClick={() => setIsEditing((oldState) => !oldState)}
              loading={loading}
            >
              {isEditing ? "Cancelar edição" : "Editar"}
            </LoadingButton>
            <LoadingButton
              dataAction="evento:botao:republicar"
              dataLabel="republicar"
              onClick={() => republish(event)}
              loading={loading}
            >
              Republicar
            </LoadingButton>
          </div>
        </div>
      </OCollapseContent>
    </OCollapse>
  );
};
