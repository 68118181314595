import { validators } from "@maestro/utils";
import { PixKeyType } from "./pix-key-input.types";

const CNPJ_LENGTH = 14;
const EVP_LENGTH = 36;

const MapperKeySizeToKeyType: Record<number, PixKeyType> = {
  [CNPJ_LENGTH]: PixKeyType.CNPJ,
  [EVP_LENGTH]: PixKeyType.EVP,
};

const validatePhone = (value = "") => {
  return /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}?$/.test(
    value,
  );
};

export const isValidCPFandPhone = (value = "") => {
  return validators.cpf(value) && validatePhone(value);
};

export const assembleMaskOptionAndKey = (
  value?: string,
  pixKeyType?: PixKeyType,
): [HTMLOInputMaskElement["maskOption"], string] => {
  const isValidCPF = validators.cpf(value);
  const isValidPhone = validatePhone(value);
  const isInvalidKey = !isValidCPF && !isValidPhone;

  const maskByKeyType =
    pixKeyType === PixKeyType.CPF
      ? "000.000.000-00"
      : pixKeyType === PixKeyType.PHONE
      ? "(00) 00000-0000"
      : undefined;

  return [
    {
      mask: [
        { mask: "0000000000" },
        {
          mask:
            maskByKeyType ||
            (isValidCPFandPhone(value) || isInvalidKey
              ? "00000000000"
              : isValidCPF
              ? "000.000.000-00"
              : "(00) 00000-0000"),
        },
        { mask: "00.000.000/0000-00" },
        { mask: /[\s\S]*/ },
      ],
    },
    isValidCPF && !isValidPhone ? "cpfMask" : "defaultMask",
  ];
};

export const handlePixKeyValue = (value: string): string => {
  const hasLetterRegExp = /[a-zA-Z]/;
  const requireRemoveSpecialChars = !hasLetterRegExp.test(value);

  return requireRemoveSpecialChars ? value.replace(/\W/g, "") : value;
};

export const loadPixKeyTypeFromValue = (
  value: string,
): PixKeyType | undefined => {
  if (!value) return;
  const size = value.length;

  const isEmailKey = validators.email(value);
  if (isEmailKey) return PixKeyType.EMAIL;

  const isValidCpf = validators.cpf(value);
  const isValidPhone = validatePhone(value);

  if (isValidCpf && isValidPhone) return;
  if (!isValidCpf && isValidPhone) return PixKeyType.PHONE;
  if (isValidCpf) return PixKeyType.CPF;

  if (![CNPJ_LENGTH, EVP_LENGTH].includes(size)) return;
  return MapperKeySizeToKeyType[size];
};

export const isAValidEVP = (value: string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(
    value,
  );
};

export const MapperPixKeyTypeToValidationFunction: Record<
  PixKeyType,
  (value: string) => true | string
> = {
  [PixKeyType.CPF]: (value) => {
    const isValid = validators.cpf(value);
    return isValid ? true : "CPF inválido";
  },
  [PixKeyType.CNPJ]: (value) => {
    const isValid = validators.cnpj(value);
    return isValid ? true : "CNPJ inválido";
  },
  [PixKeyType.PHONE]: (value) => {
    const isValid = validators.phone(value);
    return isValid ? true : "Número de telefone inválido";
  },
  [PixKeyType.EMAIL]: (value) => {
    const isValid = validators.email(value);
    return isValid ? true : "E-mail inválido";
  },
  [PixKeyType.EVP]: (value) => {
    const isValid = isAValidEVP(value);
    return isValid ? true : "Chave aleatória inválida";
  },
};

export const MapperPixKeyType: Record<PixKeyType, string> = {
  [PixKeyType.CNPJ]: "CNPJ",
  [PixKeyType.CPF]: "CPF",
  [PixKeyType.EMAIL]: "E-mail",
  [PixKeyType.EVP]: "Chave aleatória",
  [PixKeyType.PHONE]: "Celular",
};
