import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OField,
  OOption,
  ORFieldSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { LimitConditions } from "../../limit-conditions.types";
import { limitConditionsGrid } from "./limit-conditions.grid";
import { LimitQuotesFormValues } from "./limit-conditions.types";
import { limitQuotesFormValidationsSchema } from "./limit-quotes-form.schemas";

interface LimitQuotesFormProps {
  financialConditions: LimitConditions;
  hasAllSettlementInstruction: boolean;
}

export const LimitQuotesForm = ({
  financialConditions,
  hasAllSettlementInstruction,
}: LimitQuotesFormProps) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<LimitQuotesFormValues>({
    resolver: yupResolver(limitQuotesFormValidationsSchema),
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const watchQuotes = watch("quotes");

  const updateQuotes = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.updateQuotesIndexcode({
            indexCode: values.indexCode,
            limitRequestId: financialConditions.id,
            quotes: values.quotes,
          });

          OToastManager.success("As condições foram atualizadas com sucesso.");
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao tentar atualizar as condições.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [financialConditions.id, handleSubmit],
  );

  const grid = useMemo(() => {
    return limitConditionsGrid(
      watchQuotes?.map((q) => ({
        ...q,
        monthlyValue: Number((100 * q.monthlyValue).toFixed(2)),
      })),
      (quotes) => {
        setValue("quotes", quotes);
      },
      hasAllSettlementInstruction,
    );
  }, [setValue, watchQuotes, hasAllSettlementInstruction]);

  useEffect(() => {
    reset({
      indexCode: financialConditions?.interest?.indexCode,
      quotes: financialConditions?.rate?.quotes,
    });
  }, [
    financialConditions?.interest?.indexCode,
    financialConditions?.rate?.quotes,
    reset,
  ]);

  return (
    <FormTemplate
      actions={
        <LoadingButton loading={loading} onClick={updateQuotes}>
          Salvar alterações
        </LoadingButton>
      }
    >
      <OCard>
        <OCardHeader>
          <OTypography type="dark" tag="h3" size="lg">
            Condições de Pagamento
          </OTypography>
        </OCardHeader>
        <OCardBody className="position-relative">
          <FormProvider {...form}>
            <OCol md={4}>
              <ORFieldSelect id="indexCode" name="indexCode" label="Indexador">
                {["CDIE", "PRE"].map((option) => (
                  <OOption key={option} value={option}>
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OTypography type="dark" tag="h4" className="mt-3">
              Opções de parcelamento
            </OTypography>
            {errors?.quotes?.message && (
              <OField error message={errors.quotes.message} />
            )}
            <ODataGridGenerator grid={grid} />
          </FormProvider>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
