export const calculateFilterExpressionFields = (
  fields: string[],
  ...args: (string | null)[]
) => {
  const [value, selectedFilterOperations, target] = args;
  const unformattedValue = (value ?? "").replace(/[^\w\s]/gi, "");

  if (value && target === "filterRow") {
    const filterExprs: (string | (string | null)[])[] = [];

    fields.forEach((item, index) => {
      if (index >= 1) filterExprs.push("or");

      if (Number.isInteger(Number(unformattedValue))) {
        filterExprs.push([item, selectedFilterOperations, unformattedValue]);
      } else {
        filterExprs.push([item, selectedFilterOperations, value]);
      }
    });

    return filterExprs;
  }

  return [fields[0], selectedFilterOperations, unformattedValue];
};
