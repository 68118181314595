import { OCol, OLabel, ORFieldRadioGroup, ORadio, ORow } from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ImportSettingsForm } from "./import-settings.types";

interface ImportSettingsProps {
  form: UseFormReturn<ImportSettingsForm>;
  classname?: string;
}

export const ImportSettings = ({ form, classname }: ImportSettingsProps) => {
  return (
    <FormProvider {...form}>
      <ORow gap={3} className={classname}>
        <OCol xs={12} md={3}>
          <ORFieldRadioGroup
            dataAction="importar_sacado_lote:select:atualizar_sacados"
            dataLabel="atualizar_sacados"
            id="atualizaSacado"
            name="atualizaSacado"
            label="Atualizar sacados"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="atualizaSacado-yes" value="yes" />
                <OLabel htmlFor="atualizaSacado-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="atualizaSacado-no" value="no" />
                <OLabel htmlFor="atualizaSacado-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={3}>
          <ORFieldRadioGroup
            dataAction="importar_sacado_lote:select:atualizar_recebiveis_nao_antecipados"
            dataLabel="atualizar_recebiveis_nao_antecipados"
            id="updateActiveReceivables"
            name="updateActiveReceivables"
            label="Atualizar recebíveis não antecipados"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="updateActiveReceivables-yes" value="yes" />
                <OLabel htmlFor="updateActiveReceivables-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="updateActiveReceivables-no" value="no" />
                <OLabel htmlFor="updateActiveReceivables-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
      </ORow>
    </FormProvider>
  );
};
