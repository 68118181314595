import { OButton, OCol, OIcon, ORFieldInput, ORow } from "@maestro/react";
import { UseFieldArrayReturn } from "react-hook-form";
import { EditTaxasForm } from "../../edit-taxas.types";

interface EmailsFieldProps {
  index: number;
  fieldArray: UseFieldArrayReturn<EditTaxasForm, "emailsTaxas", "id">;
}

export const EmailField = ({ fieldArray, index }: EmailsFieldProps) => {
  const { remove } = fieldArray;

  return (
    <div className="d-flex align-items-center gap-4 flex-fill">
      <ORow className="flex-fill">
        <OCol xs={12}>
          <ORFieldInput
            dataAction="campo_emails:texto:email"
            dataLabel="email"
            id={`emailsTaxas.${index}.email`}
            name={`emailsTaxas.${index}.email`}
            tag="text"
            placeholder="email@email.com"
          />
        </OCol>
      </ORow>
      <OButton
        dataAction="campo_emails:botao:remover"
        dataLabel="remover"
        type="danger"
        onClick={() => remove(index)}
      >
        <OIcon category="fa" icon="fa-trash" />
      </OButton>
    </div>
  );
};
