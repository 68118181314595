import { OButton } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import {
  LoanContractsTemplatesForm,
  LoanContractsTemplatesFormValues,
} from "../../../../../../components/loan-contracts-templates/loan-contracts-templates-form.component";
import { EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsId } from "../../../../../../routes/emprestimos.route-params";
import { loanContractsConfigDetailsGenerator } from "./loan-contracts-config-details.details";

export const LoanContractsConfigDetails = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contractConfig, setContractConfig] =
    useState<
      Awaited<
        ReturnType<typeof service.hubLoan.getLoanContractsConfigDetails>
      >["data"]
    >();

  const form = useForm<LoanContractsTemplatesFormValues>();
  const { setValue, getValues } = form;

  const navigate = useNavigate();
  const { loanContractsId } =
    useParams<EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsId>();

  if (!loanContractsId) throw new Error("No loanContractsId");

  const getContractConfig = useCallback(async () => {
    try {
      setHasError(false);
      setLoading(true);
      const { data } = await service.hubLoan.getLoanContractsConfigDetails(
        loanContractsId,
      );

      setValue("contractTemplates", data.contractTemplates ?? []);

      setContractConfig(data);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [loanContractsId, setValue]);

  useEffect(() => {
    getContractConfig();
  }, [getContractConfig]);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle title="Detalhes da configuração de contratos de empréstimo" />
      }
      actions={
        <OButton
          type="tertiary"
          onClick={() =>
            contractConfig &&
            navigate(
              `${corporateRouter.routes.emprestimos.product.contingency.contractsConfig.details.edit.path(
                { loanContractsId },
              )}?data=${encodeURIComponent(
                JSON.stringify({
                  ...contractConfig,
                  contractTemplates: getValues("contractTemplates"),
                }),
              )}`,
            )
          }
          outline
        >
          Editar
        </OButton>
      }
    >
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loading}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar detalhes da configuração de template."
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => getContractConfig()} />
            </ErrorComponent>
          }
          value={contractConfig}
          hasError={hasError}
          fields={(value) => loanContractsConfigDetailsGenerator(value)}
        />
        {contractConfig && (
          <DetailsCard
            fields={{
              template: {
                title: "Templates de Contrato",
                items: [
                  {
                    value: (
                      <FormProvider {...form}>
                        <LoanContractsTemplatesForm
                          detailsLoanContractsConfigId={loanContractsId}
                        />
                      </FormProvider>
                    ),
                  },
                ],
              },
            }}
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
