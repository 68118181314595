import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GetPayrollLinesResponse, GetPayrollListResponse } from "services/fopa";
import { service } from "services/service";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { payrollDetailsGrid } from "./payment-details.grid";
import { fields } from "./payment-details.utils";

const dataSourceGenerator = (idCnab: number, idTipoCnab: number) =>
  dataSourceCustomStoreGenerator<GetPayrollLinesResponse["lines"][number]>(
    (loadOptions) => {
      const pageSize = (loadOptions?.take ?? 10) - (loadOptions.skip ?? 0);
      const pageNumber =
        pageSize > 0 ? (loadOptions?.take ?? 10) / pageSize : 1;
      return service.fopa
        .getPayrollLines({
          idCnab,
          pageSize,
          pageNumber,
          idTipoCnab,
        })
        .then(({ data }) => data.lines);
    },
  );

export const PaymentDetailsPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [data, setData] =
    useState<GetPayrollListResponse["dashboardList"][number]>();
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();
  const [searchParams] = useSearchParams();
  const gridRef = useGridRef();

  useEffect(() => {
    const stringifiedPayroll = searchParams.get("data");
    if (!stringifiedPayroll) {
      setErrorMessage("não foi possível carregar a folha de pagamento");
      return;
    }
    try {
      const parsedPayroll = JSON.parse(stringifiedPayroll);
      setData(parsedPayroll);
    } catch (err) {
      logger.error(
        new Error(
          `Unable to parse payroll from queryString: ${stringifiedPayroll}`,
        ),
      );
      setErrorMessage("não foi possível carregar a folha de pagamento");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDataSource(dataSourceGenerator(data.idCnab, data.idTipoCnab));
  }, [data]);

  return (
    <div className="d-flex flex-column gap-5">
      <DetailsTemplate pageTitle={<PageTitle title="Detalhes do pagamento" />}>
        <DetailsCard
          errorComponent={
            <ErrorComponent
              messageTitle={errorMessage}
              messageParagraph="Tente novamente mais tarde."
            />
          }
          hasError={!!errorMessage}
          value={data}
          fields={fields}
        />
      </DetailsTemplate>
      <GridTemplate
        pageTitle={<PageTitle title="Transações" />}
        gridRef={gridRef}
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={payrollDetailsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </div>
  );
};
