import { ORow } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { SignatureGroupFields } from "./signature-group-fields.component";
import { useSignatureGroup } from "./signature-group.hook";
import { GroupOfSignatures } from "./signature-group.types";

interface SignatureGroupProps {
  disabled: boolean;
  ruleId: string | number;
  groupOfSignatures?: GroupOfSignatures;
}

export const SignatureGroup = ({
  disabled,
  ruleId,
  groupOfSignatures,
}: SignatureGroupProps) => {
  const {
    form,
    getCompanyHasError,
    getCompanyWithOnboarding,
    loading,
    legalRepresentatives,
    submit,
    submitLoading,
  } = useSignatureGroup(ruleId, groupOfSignatures);

  return (
    <div className="position-relative">
      <ContentTemplate
        loading={loading}
        hasError={getCompanyHasError}
        errorComponent={
          <DefaultErrorComponent
            title="Não foi possível buscar os representantes do cliente"
            callback={getCompanyWithOnboarding}
          />
        }
        noValue
        render={() => (
          <FormTemplate
            actions={
              !disabled && (
                <LoadingButton
                  dataAction="regra_assinatura:botao:salvar"
                  dataLabel="salvar"
                  loading={submitLoading}
                  onClick={() => submit()}
                >
                  Salvar
                </LoadingButton>
              )
            }
          >
            <CardTemplate>
              <FormProvider {...form}>
                <ORow>
                  <SignatureGroupFields
                    disabled={disabled}
                    legalRepresentatives={legalRepresentatives}
                  />
                </ORow>
              </FormProvider>
            </CardTemplate>
          </FormTemplate>
        )}
      />
    </div>
  );
};
