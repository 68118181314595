import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { StepTypeFormComponent } from "../_compose";
import { useAddStepType } from "./add-step-type.hook";

export const AddStepTypePage = () => {
  const { form, loading, submit } = useAddStepType();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar tipo de step" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <StepTypeFormComponent />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
