import { env } from "utils/environments";

export const developersEndpoints = {
  apps: {
    pending: `${env.API_DEVELOPERS}/admin/apps?verificationStatus=TESTING`,
    verifyApp: (appId: string, version: number | string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/apps/${appId}/versions/${version}/approve?tenantId=${tenantId}`,
    rejectApp: (appId: string, version: number | string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/apps/${appId}/versions/${version}/reject?tenantId=${tenantId}`,
    filteredApp: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/apps/filter${filter}`,
    detailsApp: (appId: string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/app/${appId}/${tenantId}`,
    filteredResource: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/resources${filter}`,
    filteredScope: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/scopes${filter}`,
    revokeConsentScope: (id: string) =>
      `${env.API_DEVELOPERS}/admin/scopes/${id}`,
    revokeConsentResource: (id: string) =>
      `${env.API_DEVELOPERS}/admin/resources/${id}`,
  },
};
