import { HTMLAttributes } from "react";
import { ToolButtonProps } from "./tool-button.types";

export const ToolButton = ({
  active,
  setActive,
  children,
  ...rest
}: ToolButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      onClick={() => setActive((a) => !a)}
      className={active ? "active" : ""}
      {...rest}
    >
      {children}
    </button>
  );
};
