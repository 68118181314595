import { yupResolver } from "@hookform/resolvers/yup";
import { ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoGerenciamentoPerfisByRequestIdByChangeIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import {
  AdminUpdateProfileChangeFormValues,
  adminUpdateProfileChangeValidationFormSchema,
} from "./admin-update-profile-change.form";

export const AdminUpdateProfileChangePage = () => {
  const { requestId, changeId } =
    useParams<FerramentasProdutoGerenciamentoPerfisByRequestIdByChangeIdRouteParams>();

  if (!requestId || !changeId) throw new Error("No requestId or changeId");

  const form = useForm<AdminUpdateProfileChangeFormValues>({
    resolver: yupResolver(adminUpdateProfileChangeValidationFormSchema),
  });

  const { handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async ({ message }) => {
        try {
          setLoading(true);

          await service.gatekeeper.updateAdminProfileManagementRequestChange({
            changeId: +changeId,
            status: "PENDENTE_ALTERACAO",
            errors: [message ?? ""],
          });

          OToastManager.success("Mudança reprovada com sucesso");

          navigate(
            corporateRouter.routes.ferramentas.product.gatekeeper.adminProfilesManagement.details.path(
              { requestId },
            ),
          );
        } catch {
          OToastManager.danger("Erro ao reprovar mudança");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, changeId, navigate, requestId],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Reprovar mudança" />}
      actions={
        <LoadingButton
          type="danger"
          dataAction="atualizar_mudanca_perfil:input:reprovar"
          dataLabel="reprovar"
          loading={loading}
          onClick={submit}
        >
          Reprovar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORFieldInput
            id="message"
            name="message"
            tag="text"
            dataAction="atualizar_mudanca_perfil:input:mensagem_reprovadao"
            dataLabel="mensagem_reprovadao"
            label="Mensagem de reprovação"
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
