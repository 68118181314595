import {
  OFilter,
  OFilterBody,
  OFilterHeader,
  OFilterModal,
  OTypography,
} from "@maestro/react";
import React, { useContext, useState } from "react";
import { subDays, toDate, parse } from "date-fns";
import { StatementContext } from "../../statement.context";
import { PeriodFilter } from "./_compose/period-filter.component";
import { ActivityTypeFilter } from "./_compose/activity-type-filter.component";
import { MovementTypeFilter } from "./_compose/movement-type-filter-item.component";
import { FilterFooter } from "./_compose/filter-footer.component";

interface PeriodFilterParams {
  startDate: Date;
  endDate: Date;
  monthly?: any;
}

export const validPeriodFilters: {
  [key: string]: (params: any) => PeriodFilterParams;
} = {
  today: () => ({ startDate: new Date(), endDate: new Date() }),
  yesterday: () => ({
    startDate: subDays(new Date(), 1),
    endDate: new Date(),
  }),
  "last-three-days": () => ({
    startDate: subDays(new Date(), 2),
    endDate: new Date(),
  }),
  "last-week": () => ({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  }),
  monthly: (params) => ({
    monthly:
      params?.referenceDate &&
      toDate(parse(params?.referenceDate, "MM/yyyy", new Date())),
    startDate: toDate(parse(params?.referenceDate, "MM/yyyy", new Date())),
    endDate: toDate(parse(params?.referenceDate, "MM/yyyy", new Date())),
  }),
  custom: (params) => {
    const startDate = toDate(
      parse(params?.referenceDate, "dd/MM/yyyy", new Date()),
    );
    const endDate = toDate(
      parse(params?.referenceDate2, "dd/MM/yyyy", new Date()),
    );

    return { startDate, endDate };
  },
};

export const StatementFilter = () => {
  const {
    setFetchStatus,
    setHasFilters,
    setStartDate,
    setEndDate,
    setMonthly,
    setFilters,
    setIsFirstFetch,
    setCursor,
    setIsAllFetched,
  } = useContext(StatementContext);
  const filterModalRef = React.useRef<HTMLOFilterModalElement>(null);
  const [periodFilter, setPeriodFilter] = useState<string>("");

  const handleClear = () => {
    filterModalRef.current?.clearFilter();
    setMonthly("");
  };

  const handleClose = () => {
    filterModalRef.current?.closeFilter();
  };

  const handleFilter = async () => {
    const params = await filterModalRef.current?.filter();

    const getDates =
      validPeriodFilters[(params?.periodFilter as string) || "last-week"](
        params,
      );
    setMonthly(getDates.monthly || "");
    setStartDate(getDates.startDate);
    setEndDate(getDates.endDate);

    const selectedActivity = params?.activityFilter;
    const selectedMovementType = params?.movementTypeFilter;
    setFilters({
      categories: [selectedActivity],
      movementTypes: [selectedMovementType],
    });

    setHasFilters(true);
    setCursor(null);
    setIsAllFetched(false);
    setIsFirstFetch(true);

    setFetchStatus(true);
  };

  return (
    <OFilterModal id="filter-statement" ref={filterModalRef}>
      <OFilterHeader>
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>
      <OFilterBody>
        <OFilter>
          <PeriodFilter filter={periodFilter} setFilter={setPeriodFilter} />
          <ActivityTypeFilter />
          <MovementTypeFilter />
        </OFilter>
      </OFilterBody>
      <FilterFooter {...{ handleClear, handleFilter, handleClose }} />
    </OFilterModal>
  );
};
