import { OTypography } from "@maestro/react";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { SacadoSelector } from "./_compose";
import { useSacadoSelection } from "./sacado-selection.hook";

export const SacadoSelection = () => {
  const { loading, hasError, sacados, getSacados } = useSacadoSelection();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Recebíveis disponíveis" />}>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={sacados}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível buscar os sacados"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => getSacados()} />
          </ErrorComponent>
        }
        emptyStateComponent={
          <EmptyState messageParagraph="Nenhum recebível disponível para esta empresa." />
        }
        render={(_sacados) => (
          <div className="ms-4">
            <OTypography type="dark" className="my-4">
              Selecione um sacado:
            </OTypography>
            <div className="d-flex flex-column gap-4">
              {_sacados.map((_sacado) => (
                <SacadoSelector key={_sacado.identification} sacado={_sacado} />
              ))}
            </div>
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
