import { OCol, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { SimulationSummaryResponse } from "services/hubloan/models/responses/get-api-simulation-summary.response";
import { banksList } from "utils/bank-list";
import { BankAccount, DisbursementInstruction } from "../../../offers.type";
import { mapBankIcon } from "./bank-account-icon";

const mapBank = (bankCode: string) => banksList.find((i) => i.id === bankCode);

export const getBankAccountList = (
  summary?: SimulationSummaryResponse,
  accountList?: BankAccount[],
) => {
  if (accountList)
    return summary?.agreement.additionalDisbursementAccountType === "INTERNAL"
      ? accountList.filter((account) =>
          ["0001", "0050", "50", "1"].includes(account.agency),
        )
      : accountList;

  return [];
};

export const getAccountCards = (
  account: BankAccount | DisbursementInstruction,
  accountNumber: string,
  holderName?: string,
  disbursementAmount?: number,
) => {
  return (
    <>
      <OCol className="d-flex align-items-center">
        <img
          src={mapBankIcon[account.bankCode]?.icon ?? mapBankIcon.default.icon}
          alt={mapBank(account.bankCode)?.title}
          style={{
            height: "50px",
            width: "50px",
          }}
        />
      </OCol>
      <OCol className="d-flex flex-column" sm={7}>
        <OTypography size="sm">{mapBank(account.bankCode)?.title}</OTypography>
        {!!holderName && <OTypography size="sm">{holderName}</OTypography>}
        <OTypography size="sm">{`Agência ${account.agency}`}</OTypography>
        <OTypography size="sm">
          {`Conta ${accountNumber}${
            account.accountDigit ? ` - ${account.accountDigit}` : ""
          }`}
        </OTypography>
        {!!disbursementAmount && (
          <OTypography key={disbursementAmount} size="sm">
            {`Valor a desembolsar: ${masks.currency(disbursementAmount, "R$")}`}
          </OTypography>
        )}
      </OCol>
    </>
  );
};
