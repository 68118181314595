export interface FormValuesType {
  id?: string;
  identification: string;
  from?: string | null | undefined;
  until?: string | null | undefined;
  type: string | null | undefined;
}

export const BlockListTypesMap: Record<string, string> = {
  VIA_VAREJO_STORE: "Loja Via Varejo",
};
