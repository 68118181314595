import { masks } from "@maestro/utils";
import { MapperPaymentType } from "services/api/models/types/payments";
import * as PaymentsEnums from "services/api/models/types/payments/enums";
import { logger } from "utils/logger";
import {
  AccountUtils,
  DataGridCellContentProps,
  DateFormatType,
  DateUtils,
  Filters,
  MaskUtils,
  PaymentTypeGroup,
  PaymentUtils,
} from "./_compose";
import {
  DataSourceItem,
  MapperDataSourceTypeToPaymentStatus,
  MapperPaymentTypeGroupToPaymentType,
} from "./payments-and-transfers.types";

const formatReceiverInfoInOneLine = (data: {
  taxId?: string;
  branch?: string;
  number?: string;
  accountType?: ApiPayments.AccountType;
}) => {
  const { taxId, branch, number, accountType } = data;
  const taxIdFormatted = MaskUtils.maskAndFormatTaxIdText(taxId);

  if (!branch || !number || !accountType) return taxIdFormatted;

  const branchFormatted = AccountUtils.formatAccountAgency(branch);
  const numberFormatted = AccountUtils.formatAccountNumber(number);

  const accountInfo = `Ag: ${branchFormatted} | ${accountType}: ${numberFormatted}`;

  if (taxId) return `${taxIdFormatted} | ${accountInfo}`;
  return accountInfo;
};

const formatHiddenReceiverInfoInOneLine = (data: {
  taxId?: string;
  branch?: string;
  number?: string;
  accountType?: ApiPayments.AccountType;
}) => {
  const { taxId, branch, number, accountType } = data;
  const taxIdFormatted = MaskUtils.maskAndFormatTaxIdText(taxId);

  if (!branch || !number || !accountType) return taxIdFormatted;

  const branchFormatted = MaskUtils.maskAndFormatTaxIdText(branch);
  const numberFormatted = MaskUtils.maskAndFormatTaxIdText(number);

  const accountInfo = `Ag: ${branchFormatted} | ${accountType}: ${numberFormatted}`;

  if (taxId) return `${taxIdFormatted} | ${accountInfo}`;
  return accountInfo;
};

export const assembleGetPaymentFilters = (
  data?: Filters,
): ApiPayments.Filters | undefined => {
  if (!data) return;
  const { paymentType, status, paymentDate, amount } = data;

  const filters: ApiPayments.Filters = {
    ...(paymentType && {
      paymentType: paymentType.reduce<ApiPayments.PaymentType[]>(
        (listType, typeGroup: PaymentTypeGroup) => {
          const mapped = MapperPaymentTypeGroupToPaymentType[typeGroup];
          if (!mapped) return listType;
          return listType.concat(mapped);
        },
        [],
      ),
    }),
    ...(status && {
      status: status.reduce<ApiPayments.PaymentStatus[]>(
        (paymentStatus, dataSourceTypeStatus) => {
          const mapped =
            MapperDataSourceTypeToPaymentStatus[dataSourceTypeStatus];
          if (!mapped) return paymentStatus;
          return paymentStatus.concat(mapped);
        },
        [],
      ),
    }),
    ...(paymentDate && {
      minPaymentDate: paymentDate[0].format(DateFormatType.ApiFormat),
      maxPaymentDate: paymentDate[1].format(DateFormatType.ApiFormat),
    }),
    ...(amount && {
      minAmount: amount[0],
      maxAmount: amount[1],
    }),
  };
  return filters;
};

const getBankslipItems = (
  detail: ApiPayments.BankslipDetails,
): DataGridCellContentProps => {
  return {
    primary: detail.payee.socialName,
    secondary: masks.cpfCnpj(detail.payee.taxId),
  };
};

const getUtilitiesItems = (
  detail: ApiPayments.UtilitiesDetails,
): DataGridCellContentProps => {
  return {
    primary: detail.assignor,
  };
};

const getDarfItems = (
  detail: ApiPayments.DarfDetails,
): DataGridCellContentProps => {
  return {
    primary: detail.taxPayer.name,
    secondary: masks.cpfCnpj(detail.taxPayer.id),
  };
};

const getCreditCardInvoiceItems = (): DataGridCellContentProps => {
  return {
    primary: "BTG Pactual Empresas",
  };
};

const getBankingItems = (
  detail: ApiPayments.TedDetails | ApiPayments.TefDetails,
) => {
  return {
    primary: detail.creditParty?.name.toUpperCase() ?? "-",
    secondary: formatReceiverInfoInOneLine({
      branch: detail.creditParty?.account.branch ?? "",
      number: detail.creditParty?.account.number ?? "",
      accountType: detail.creditParty?.account.type ?? undefined,
      taxId: detail.creditParty?.taxId ?? "",
    }),
  };
};

const getPixItems = (
  paymentType: ApiPayments.PaymentType,
  detail:
    | ApiPayments.PixManualDetails
    | ApiPayments.PixKeyDetails
    | ApiPayments.PixQrCodeDetails
    | ApiPayments.PixReversalDetails,
): DataGridCellContentProps => {
  const isPixManual = paymentType === PaymentsEnums.PaymentType.PixManual;

  const { creditParty } = detail;

  const { account, taxId } = creditParty || {};

  const { branch, number, type: accountType } = account || {};

  return {
    primary: detail.creditParty?.name.toUpperCase() ?? "-",
    secondary: isPixManual
      ? formatReceiverInfoInOneLine({ taxId, branch, number, accountType })
      : formatHiddenReceiverInfoInOneLine({ taxId }),
  };
};

const getReceiverItems = (
  payment: ApiPayments.PaymentData,
): DataGridCellContentProps => {
  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.BankslipDetails>(
      payment,
      PaymentsEnums.PaymentType.Bankslip,
    )
  ) {
    return getBankslipItems(payment.detail);
  }

  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.UtilitiesDetails>(
      payment,
      PaymentsEnums.PaymentType.Utilities,
    )
  ) {
    return getUtilitiesItems(payment.detail);
  }

  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.DarfDetails>(
      payment,
      PaymentsEnums.PaymentType.Darf,
    )
  ) {
    return getDarfItems(payment.detail);
  }

  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.CreditCardInvoiceDetails>(
      payment,
      PaymentsEnums.PaymentType.CreditCardInvoice,
    )
  ) {
    return getCreditCardInvoiceItems();
  }

  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.TedDetails>(
      payment,
      PaymentsEnums.PaymentType.Ted,
    ) ||
    PaymentUtils.validatePaymentFromType<ApiPayments.TefDetails>(
      payment,
      PaymentsEnums.PaymentType.Tef,
    )
  ) {
    return getBankingItems(payment.detail);
  }

  if (
    PaymentUtils.validatePaymentFromType<ApiPayments.PixManualDetails>(
      payment,
      PaymentsEnums.PaymentType.PixManual,
    ) ||
    PaymentUtils.validatePaymentFromType<ApiPayments.PixKeyDetails>(
      payment,
      PaymentsEnums.PaymentType.PixKey,
    ) ||
    PaymentUtils.validatePaymentFromType<ApiPayments.PixQrCodeDetails>(
      payment,
      PaymentsEnums.PaymentType.PixQrCode,
    ) ||
    PaymentUtils.validatePaymentFromType<ApiPayments.PixReversalDetails>(
      payment,
      PaymentsEnums.PaymentType.PixReversal,
    )
  ) {
    return getPixItems(payment.type, payment.detail);
  }

  return { primary: "-" };
};

export const assembleDataSourceFromPayments = (
  data: ApiPayments.PaymentData[],
): DataSourceItem[] => {
  try {
    return data.reduce<DataSourceItem[]>((dataSource, payment) => {
      try {
        const item: DataSourceItem = {
          paymentDate: {
            primary: DateUtils.formatDate(
              DateFormatType.ViewFormat,
              payment.paymentDate,
            ),
          },
          sender: {
            primary: "BTG Pactual",
            secondary: formatReceiverInfoInOneLine({
              branch: payment.debitParty.account.branch,
              number: payment.debitParty.account.number,
              accountType: payment.debitParty.account.type,
            }),
          },
          receiver: getReceiverItems(payment),
          amount: { primary: masks.currency(payment.amount, "R$") },
          status: PaymentUtils.assemblePaymentStatus(payment),
          product: { primary: MapperPaymentType[payment.type] },
          id: payment.id,
          metaData: { ...payment },
        };
        return dataSource.concat(item);
      } catch (error) {
        logger.error(error, payment);
        return dataSource;
      }
    }, []);
  } catch (error) {
    return [];
  }
};
