import { env } from "utils/environments";

export const moanaEndpoints = {
  limit: {
    change: `${env.API_MOANA}limit/change`,
    disable: `${env.API_MOANA}limit/disable`,
  },
  owner: {
    endpoint: `${env.API_MOANA}owner`,
    totals: `${env.API_MOANA}owner/totals`,
    export: `${env.API_MOANA}owner/export`,
    exportEmail: `${env.API_MOANA}owner/export-email`,
    receivables: `${env.API_MOANA}owner/receivables`,
    receivablesExport: `${env.API_MOANA}owner/receivables/export`,
    walletManagement: `${env.API_MOANA}owner/wallet-management`,
    payersFromOwner: `${env.API_MOANA}owner/payers-from-owner`,
    getAll: `${env.API_MOANA}owner/get-all`,
    discountedWallet: `${env.API_MOANA}owner/wallet/get-discounted`,
    exportDiscountedWallet: `${env.API_MOANA}owner/wallet/export-discounted`,
    ownWallet: `${env.API_MOANA}owner/wallet/get-own`,
    exportOwnWallet: `${env.API_MOANA}owner/wallet/export-own`,
  },
  bordero: {
    byDocumentNumber: `${env.API_MOANA}bordero/create-with-specific-owner`,
  },
};
