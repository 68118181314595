import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { useCalendars } from "./calendars.hook";
import { grid } from "./calendars.grid";
import { CalendarsActions } from "./_compose/calendars-actions";
import { CalendarDeleteModal } from "./_compose/calendar-delete-modal";

export const CalendarsPage = () => {
  const { dataSource, gridRef, onDelete, confirmDelete, setConfirmDelete } =
    useCalendars();

  return (
    <GridTemplate
      gridRef={gridRef}
      pageTitle={
        <PageTitle
          title="Calendários"
          description="Visualize todos os calendários e registre calendários personalizados"
        />
      }
      actions={<CalendarsActions />}
      showRefreshButton
    >
      <ODataGridGenerator
        grid={grid(onDelete)}
        gridRef={gridRef}
        dataSource={dataSource}
      />
      <CalendarDeleteModal
        calendarId={confirmDelete?.id}
        name={confirmDelete?.name}
        onRemoved={() => {
          gridRef.current?.instance.refresh();
          setConfirmDelete(undefined);
        }}
      />
    </GridTemplate>
  );
};
