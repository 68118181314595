import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { useEstrategiaComercial } from "../../_compose";
import { CurrencySelector } from "../visualizar/_compose";
import { buildDataSource, curveGrid } from "./taxas-calculator.grid";
import { useGridRef } from "hooks/grid-ref";

export const TaxasCalculator = () => {
  const gridRef = useGridRef();
  const [selectedCurrency, setSelectedCurrency] = useState("BRL");

  const { estrategia, loading } = useEstrategiaComercial();

  const dataSource = useMemo(
    () =>
      estrategia
        ? buildDataSource(estrategia.uniqueId, selectedCurrency)
        : undefined,
    [estrategia, selectedCurrency],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title={"Calculadora - " + estrategia?.uniqueId} />}
      actions={
        <>
          <CurrencySelector
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />
          <RefreshGridButton onClick={() => dataSource?.reload()} />
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        loading={loading}
        gridRef={gridRef}
        grid={curveGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
