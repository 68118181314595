import { FormProvider } from "react-hook-form";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInputDate,
  modalManager,
} from "@maestro/react";
import dayjs from "dayjs";
import { useConciliacaoModal } from "./conciliacao-modal.hook";

export const conciliacaoModalId = "conciliacao-modal-client";

export const ConciliacaoModal = () => {
  const { form, clear, submit } = useConciliacaoModal();
  const { hide } = modalManager;

  return (
    <OModal id={conciliacaoModalId}>
      <OModalHeader closeButton divider>
        <span className="d-flex gap-2">Escolha os vencimentos para busca</span>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <ORFieldInputDate
              id="dataVencimentoInicial"
              name="dataVencimentoInicial"
              label="Data de vencimento inicial"
            />
            <ORFieldInputDate
              id="dataVencimentoFinal"
              name="dataVencimentoFinal"
              label="Data de vencimento final"
              disabledDate={(date) =>
                dayjs(date.toDate()).isAfter(dayjs().endOf("day"))
              }
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton type="dark" outline onClick={() => clear()}>
            Limpar
          </OButton>
          <OButton
            onClick={() => {
              submit();
              hide(conciliacaoModalId);
            }}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
