import { behaviorSessionStorage } from "utils/storage";
import { customerEvents } from "./customer.event";
import { CustomerState } from "./types/state";
import { CustomerType } from "./types/customer.type";

export class Customer {
  private _state = CustomerState.INITIAL;

  private static storageKey = "corporate-portal:selected-customer";

  private static store = behaviorSessionStorage;

  static storage = {
    get: () => this.store.getItem(this.storageKey),
    set: (identification: string) =>
      this.store.setItem(this.storageKey, identification),
    remove: () => this.store.removeItem(this.storageKey),
  };

  value?: CustomerType;

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
    customerEvents.dispatch(this);

    if (this.value?.identification) {
      Customer.storage.set(this.value.identification);
    } else {
      Customer.storage.remove();
    }
  }
}

export const customer = new Customer();
