import {
  OBanner,
  OBannerAction,
  OBannerBody,
  OBannerContent,
  OButton,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { PageTitle, PageTitleProps } from "components/page-title";
import { PropsWithChildren, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { useCardsCustomerContext } from "../../../../contexts";

interface NoAccountRegisteredTemplateProps extends PropsWithChildren {
  pageTitleProps?: PageTitleProps;
}

export const NoAccountRegisteredTemplate = ({
  children,
  pageTitleProps,
}: NoAccountRegisteredTemplateProps) => {
  const navigate = useNavigate();

  const { fetchCardsAccount, loadingAccount, hasAccountRegistered } =
    useCardsCustomerContext();

  const fetchError = useMemo(() => {
    if (hasAccountRegistered === undefined) return true;
    return false;
  }, [hasAccountRegistered]);

  return (
    <>
      <PageTitle {...pageTitleProps} />

      <ContentTemplate
        loading={loadingAccount}
        hasError={fetchError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao carregar os dados da conta"
            callback={fetchCardsAccount}
          />
        }
        value={hasAccountRegistered}
        emptyStateComponent={
          <OBanner type="warning-light" textTruncate={false}>
            <OBannerBody>
              <OBannerContent title="Conta cartão não encontrada" size="md">
                Não foi encontrado um cartão contratado para essa empresa. Para
                acessar essa página, visite a aba de contratação e contrate um
                cartão.
              </OBannerContent>
              <OBannerAction>
                <OButton
                  type="info"
                  onClick={() => {
                    navigate(
                      corporateRouter.routes.cartoes.customer.hiring.hireCard
                        .path,
                    );
                  }}
                >
                  Contratar cartão
                </OButton>
              </OBannerAction>
            </OBannerBody>
          </OBanner>
        }
        render={() => <>{children}</>}
      />
    </>
  );
};
