import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { ConfirmationDeleteMinimumCriteriaModalProps } from "./delete-criteria-modal.type";
import { useConfirmationDeleteMinimumCriteriaModal } from "./delete-criteria-modal.hook";

export const ConfirmationDeleteMinimumCriteriaModal = ({
  id,
  reload,
}: ConfirmationDeleteMinimumCriteriaModalProps) => {
  const { loading, modalId, handleConfirm } =
    useConfirmationDeleteMinimumCriteriaModal({
      id,
      reload,
    });

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Tem certeza que deseja remover este critério mínimo ?"
      divider={false}
      onConfirm={handleConfirm}
      loading={loading}
      className="position-absolute"
      {...datagridModalFix}
    >
      <OTypography size="md">
        Ao confirmar a operação não será possível reverter a ação.
      </OTypography>
    </OConfirmationModal>
  );
};