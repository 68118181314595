import { modalManager } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { ConfirmationDeleteAdequatorModal } from "../confirmation-delete-adequator-modal";
import { diligenceDeleteAdequatorModalId } from "../confirmation-delete-adequator-modal/confirmation-delete-adequator-modal.utils";

interface GridActionsProps {
  data: HubEnergy.AdequacyOptionResponse;
  reload: () => void;
}

export const GridActions = ({ data, reload }: GridActionsProps) => {
  return (
    <>
      <DataGridActionV2
        actions={[
          {
            icon: { category: "orq", icon: "orq-edit-trash" },
            label: "Inativar",
            onClick: () =>
              modalManager.show(diligenceDeleteAdequatorModalId(data.id)),
          },
        ]}
      />

      <ConfirmationDeleteAdequatorModal id={data.id} reload={reload} />
    </>
  );
};
