import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  OIcon,
  OLink,
  ORFieldUploadInput,
  OToastManager,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { EnergyValidationResults } from "./_compose/validation-results/energy-validation-results.component";
import {
  EnergyCreateDistributorsFormValues,
  energyCreateDistributorsFormValidationSchema,
} from "./energy-create-distributors.form";
import { UploadContainer } from "./upload-container.styles";

export const EnergyCreateDistributorsPage = () => {
  const form = useForm<EnergyCreateDistributorsFormValues>({
    resolver: yupResolver(energyCreateDistributorsFormValidationSchema),
  });

  const { handleSubmit, watch } = form;
  const watchFile = watch("file");

  const {
    callService: createDistributors,
    loading,
    error,
  } = useServiceCall(service.hubEnergy.createDistributors);

  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async ({ file }) => {
        if (!file.length) return;

        const dataForm = new FormData();
        dataForm.append("file", file[0]);

        const { success } = await createDistributors(dataForm);
        if (!success) return OToastManager.danger("Erro ao processar arquivo");

        OToastManager.success("Distribuidoras adicionadas com sucesso");
        navigate(corporateRouter.routes.energia.product.distributor.path);
      }),
    [handleSubmit, createDistributors, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar distribuidoras" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-2">
        <FormProvider {...form}>
          <OCard>
            <OCardBody>
              <UploadContainer>
                <ORFieldUploadInput
                  id="file"
                  name="file"
                  inputLabel="Clique ou arraste o arquivo aqui"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  multiple={false}
                  handleAddFile={(event, resolve) => resolve(event.detail)}
                  handleRemove={(file, resolve) => resolve(file)}
                  disabled={loading || !!watchFile?.length}
                  key={watchFile?.length}
                  value={[]}
                />
              </UploadContainer>
              <div className="d-flex flex-column align-items-center gap-3 mt-4">
                <OLink href="/files/modelo_distribuidora.xlsx">
                  <OIcon
                    category="fa"
                    icon="fa-external-link"
                    className="me-2"
                  />
                  Baixar modelo
                </OLink>
              </div>
            </OCardBody>
          </OCard>
        </FormProvider>
        {isAxiosError<HubEnergy.ValidationErrorResponse>(error) &&
          error.response?.data && (
            <EnergyValidationResults errorResponse={error.response.data} />
          )}
      </div>
    </FormTemplate>
  );
};
