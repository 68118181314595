import { StatusBadgeProps } from "components/status-badge";
import {
  AddressType,
  CardVariant,
  CardType,
  CardModality,
  WalletProvisionRequestStatus,
  WalletType,
} from "./enums";

export const MapperCardVariant: Record<CardVariant, string> = {
  [CardVariant.Black]: "Black",
  [CardVariant.Platinum]: "Platinum",
  [CardVariant.Business]: "Business",
  [CardVariant.Gold]: "Business",
  [CardVariant.BusinessDebit]: "Business",
};

export const MapperCardType: Record<CardType, string> = {
  [CardType.Physical]: "Físico",
  [CardType.Virtual]: "Virtual",
  [CardType.Plastic]: "Físico",
};

export const MapperCardModality: Record<CardModality, string> = {
  [CardModality.Credit]: "Crédito",
  [CardModality.Debit]: "Débito",
  [CardModality.Multiple]: "Múltiplo (Crédito e Débito)",
};

export const MapperAddressType: Record<AddressType, string> = {
  [AddressType.RESIDENTIAL]: "Residencial",
  [AddressType.COMMERCIAL]: "Comercial",
  [AddressType.OTHERS]: "Outros",
};

export const MapperWalletStatusToStatusBadgeProps: Record<
  WalletProvisionRequestStatus,
  StatusBadgeProps
> = {
  [WalletProvisionRequestStatus.Created]: {
    children: "Disponível",
    type: "info",
  },
  [WalletProvisionRequestStatus.Approved]: {
    children: "Aprovada",
    type: "success",
  },
  [WalletProvisionRequestStatus.Rejected]: {
    children: "Rejeitada",
    type: "danger",
  },
  [WalletProvisionRequestStatus.Expired]: {
    children: "Expirada",
    type: "warning",
  },
  [WalletProvisionRequestStatus.Deleted]: {
    children: "Apagada",
    type: "warning",
  },
};

export const MapperWalletTypeToStatusBadgeProps: Record<
  WalletType,
  StatusBadgeProps
> = {
  [WalletType.Apple]: {
    children: "Apple Wallet",
    type: "info",
  },
  [WalletType.Samsung]: {
    children: "Samsung Wallet",
    type: "info",
  },
  [WalletType.Google]: {
    children: "Google Wallet",
    type: "info",
  },
};
