import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";
import { fastCpfCnpjMask } from "../../../../emails-automaticos/novo/new-auto-email.utils";
import { Whitelist } from "../../whitelists.types";

export const useAcquirerSelector = (
  data: Whitelist,
  setValue: (value: string) => void,
) => {
  const {
    callService: getCompanies,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.getCompanies);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const companies = useMemo<SelectSearchOption<number>[]>(
    () =>
      value?.companies.map((company) => ({
        label: `${company.name} - ${fastCpfCnpjMask(company.tax_id)}`,
        value: company.id,
        onSelect: () => {
          // eslint-disable-next-line no-param-reassign
          data.credenciadora_id = company.id;
          setValue(company.name ?? "");
        },
      })) ?? [],
    [data, setValue, value?.companies],
  );

  return {
    hasError,
    loading,
    companies,
  };
};
