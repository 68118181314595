import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ExchangeQuoteDetails } from "../../../../components/quotes/quote-details.component";
import { CambioOperacoesByIdRouteParams } from "../../../../routes/cambio.route-params";

export const ExchangeQuoteDetailsPage = () => {
  const { id } = useParams<CambioOperacoesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: quoteDetails,
    callService: getQuoteDetails,
    hasError: hasErrorQuoteDetails,
    loading: loadingQuoteDetails,
  } = useServiceCall(service.hubFx.adminCustomer.getQuoteDetails);

  useEffect(() => {
    getQuoteDetails(Number(id));
  }, [getQuoteDetails, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da Operação" />}>
      <ContentTemplate
        loading={loadingQuoteDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da operação."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getQuoteDetails(Number(id))} />
          </ErrorComponent>
        }
        hasError={hasErrorQuoteDetails}
        value={quoteDetails}
        render={(quote) => (
          <ExchangeQuoteDetails quoteDetails={quote} type="customer" />
        )}
      />
    </DetailsTemplate>
  );
};
