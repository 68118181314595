import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const useOperationsDashboard = () => {
  const {
    callService: getDailySummary,
    hasError: dailySummaryHasError,
    loading: dailySummaryLoading,
    value: dailySummaryValue,
  } = useServiceCall(service.quickfin.getDailySummary);

  const {
    callService: getTradesMaturing,
    hasError: tradesMaturingHasError,
    loading: tradesMaturingLoading,
    value: tradesMaturingValue,
  } = useServiceCall(service.quickfin.getTradesMaturing);

  useEffect(() => {
    getDailySummary();
  }, [getDailySummary]);

  useEffect(() => {
    getTradesMaturing();
  }, [getTradesMaturing]);

  return {
    dailySummaryHasError,
    dailySummaryLoading,
    dailySummaryValue,
    getDailySummary,
    getTradesMaturing,
    tradesMaturingHasError,
    tradesMaturingLoading,
    tradesMaturingValue,
  };
};
