import { OToastManager } from "@maestro/core";

interface ValidationItem {
  value: string | number | boolean;
  message: string;
}

export const inputValidate = (items: ValidationItem[]): boolean => {
  const errorMessages = items
    .filter((item) => !item.value)
    .map((item) => item.message)
    .join(",");

  if (errorMessages.length > 0) {
    OToastManager.danger({
      title: "Dados inválidos",
      message: "Por favor preencher os campos: " + errorMessages,
    });
    return false;
  }

  return true;
};
