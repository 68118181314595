import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { ContentTemplate } from "templates/content-template";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { userActivationsGrid } from "./ativacoes.grid";
import { useGetUser } from "../../../../hooks/use-get-user.hook";

export const UserActivations = () => {
  const { user, loading, hasError, getUserFromTaxId } = useGetUser();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ativações" />}
      actions={<RefreshGridButton onClick={() => getUserFromTaxId()} />}
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={user}
        errorComponent={
          <ErrorComponent
            messageTitle="Ocorreu um erro ao buscar o usuário"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => getUserFromTaxId()} />
          </ErrorComponent>
        }
        render={(_user) => (
          <ODataGridGenerator
            grid={userActivationsGrid}
            dataSource={_user.activations}
          />
        )}
      />
    </GridTemplate>
  );
};
