import { yupResolver } from "@hookform/resolvers/yup";
import {
  modalManager,
  OButton,
  OCheckbox,
  OLabel,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useWorkflow } from "../../../../../../components/hooks/use-workflow.context";
import { getPayloadId } from "../../../workflow-processors.utils";
import { useEditProcessor } from "../../edit-processor.context";
import {
  outputModalFormDefaultValues,
  outputModalFormValidationSchema,
} from "./output-modal-form.schemas";

export const OUTPUT_MODAL_ID = "output-modal";
export const CONTEXT_STATUS_LIST = ["CRIADO", "EM_ANDAMENTO", "FINALIZADO"];

export const OutputModal = () => {
  const {
    processor: { callService: getProcessorDetails, loading: procesorLoading },
    id,
    selectedOutput,
    setSelectedOutput,
  } = useEditProcessor();
  const [loading, setLoading] = useState(false);

  const {
    configurableStatus: { value: status },
  } = useWorkflow();

  const form = useForm({
    resolver: yupResolver(outputModalFormValidationSchema),
    defaultValues: outputModalFormDefaultValues,
  });
  const { getValues, reset, setValue, handleSubmit } = form;

  const { hide } = modalManager;

  const addOutput = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const {
            description,
            executeOnStart,
            contextStatusOnOutput,
            configurableStatusId,
            type,
          } = values;

          await service.hubCreditManager.addProcessorOutput({
            description,
            type,
            executeOnStart: !!executeOnStart.length,
            contextStatusOnOutput,
            processorId: Number(id),
            configurableStatusId: getPayloadId(configurableStatusId),
          });

          getProcessorDetails(id);
          hide(OUTPUT_MODAL_ID);
          reset();
          OToastManager.success("Saída salva com sucesso.");
        } catch {
          OToastManager.danger("Não foi possível salvar a saída.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, getProcessorDetails, hide, reset],
  );

  const editOutput = useCallback(
    async (outputId: number) => {
      try {
        setLoading(true);

        const {
          description,
          configurableStatusId,
          type,
          executeOnStart,
          contextStatusOnOutput,
        } = getValues();

        await service.hubCreditManager.editProcessorOutput({
          description,
          type,
          executeOnStart: !!executeOnStart.length,
          contextStatusOnOutput,
          outputId,
          configurableStatusId: getPayloadId(configurableStatusId),
        });

        getProcessorDetails(id);
        hide(OUTPUT_MODAL_ID);
        reset();
        setSelectedOutput(undefined);
        OToastManager.success("Saída editada com sucesso.");
      } catch {
        OToastManager.danger("Não foi possível editar a saída.");
      } finally {
        setLoading(false);
      }
    },
    [getProcessorDetails, getValues, hide, reset, setSelectedOutput, id],
  );

  useEffect(() => {
    if (selectedOutput) {
      reset({
        type: selectedOutput.type,
        description: selectedOutput.description,
        executeOnStart: selectedOutput.executeOnStart ? ["1"] : [],
        contextStatusOnOutput: selectedOutput.contextStatusOnOutput,
        configurableStatusId:
          selectedOutput.configurableStatus?.id.toString() || "",
      });
    } else {
      reset();
    }
  }, [reset, selectedOutput, setValue]);

  return (
    <OModal id={OUTPUT_MODAL_ID} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography
          tag="h1"
          size="xxl"
          key={`output-modal-title-${selectedOutput}`}
        >
          {`${selectedOutput ? "Editar" : "Adicionar"} saída`}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {procesorLoading && <OLoader size="xl" absolute />}
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex flex-column gap-2">
              <ORFieldInput
                id="type"
                name="type"
                tag="text"
                label="Tipo"
                labelSize="lg"
                dataAction="adicionar_saida:texto:tipo"
                dataLabel="tipo"
              />
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                labelSize="lg"
                dataAction="adicionar_saida:texto:descricao"
                dataLabel="descricao"
              />
            </div>
            <div className="d-flex flex-column">
              <ORFieldSelect
                id="contextStatusOnOutput"
                name="contextStatusOnOutput"
                label="Status do contexto após saída"
                labelSize="lg"
                dataAction="adicionar_saida:select:status_contexto"
                dataLabel="status_contexto"
              >
                <div>
                  {CONTEXT_STATUS_LIST?.map((contextStatus) => (
                    <OOption value={contextStatus} key={contextStatus}>
                      {contextStatus}
                    </OOption>
                  ))}
                </div>
              </ORFieldSelect>
            </div>
            <div className="d-flex flex-column">
              <ORFieldSelect
                id="configurableStatusId"
                name="configurableStatusId"
                label="Status da saída"
                labelSize="lg"
                dataAction="adicionar_saida:select:status_saida"
                dataLabel="status_saida"
                key={`configurable-status-description-${status?.length}`}
              >
                <div>
                  {status?.map((s) => (
                    <OOption
                      value={s.id.toString()}
                      key={`configurable-status-option-${s.id.toString()}`}
                    >
                      {s.name}
                    </OOption>
                  ))}
                  <OOption value="" key="configurable-status-option-null">
                    Nenhum
                  </OOption>
                </div>
              </ORFieldSelect>
            </div>
            <div>
              <ORFieldCheckboxGroup
                id="executeOnStart"
                name="executeOnStart"
                dataAction="adicionar_saida:checkbox:saida_inicial"
                dataLabel="saida_inicial"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="execute-on-start-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="execute-on-start-checkbox">
                    Saída inicial?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </div>
          </form>
        </FormProvider>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="adicionar_saida:botao:fechar"
            dataLabel="fechar"
            outline
            onClick={() => {
              hide(OUTPUT_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <LoadingButton
            loading={loading}
            dataAction="adicionar_saida:botao:adicionar"
            dataLabel="adicionar"
            onClick={async () => {
              if (selectedOutput) editOutput(selectedOutput.id);
              else addOutput();
            }}
          >
            {selectedOutput ? "Editar" : "Adicionar"}
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
