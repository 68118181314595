import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useServiceCall } from "hooks/service-call";
import * as CardsEnums from "services/bankinghub/models/types/cards/enums";
import { service } from "services";

interface CardsCustomerContext {
  account: BankingHubCards.Account | undefined;
  loadingAccount: boolean;
  accountError: unknown;
  hasAccountRegistered: boolean | undefined;
  fetchCardsAccount: () => void;
  offer: BankingHubCards.Offer | undefined;
  loadingOffer: boolean;
  offerError: unknown;
  hasOfferActive: boolean | undefined;
  fetchCardsOffer: () => void;
}

const CardsCustomerContextDefaultData: CardsCustomerContext = {
  account: undefined,
  loadingAccount: true,
  accountError: undefined,
  hasAccountRegistered: undefined,
  fetchCardsAccount: () => {},
  offer: undefined,
  loadingOffer: true,
  offerError: undefined,
  hasOfferActive: undefined,
  fetchCardsOffer: () => {},
};

const cardsCustomerContext = createContext<CardsCustomerContext>(
  CardsCustomerContextDefaultData,
);

export const CardsCustomerProvider = ({ children }: PropsWithChildren) => {
  const {
    value: account,
    loading: loadingAccount,
    error: accountError,
    callService: getCardsAccount,
  } = useServiceCall(service.bankinghub.getCardsAccount);

  const {
    value: offer,
    loading: loadingOffer,
    error: offerError,
    callService: getCardsOffer,
  } = useServiceCall(service.bankinghub.getCardsOffer);

  const hasAccountRegistered = useMemo(() => {
    if (!account && !accountError) return;
    return !!account;
  }, [account, accountError]);

  const hasOfferActive = useMemo(() => {
    if (!offer && !offerError) return;
    const activesOfferStatus = [
      CardsEnums.OfferStatus.Created,
      CardsEnums.OfferStatus.PendingGuarantorsOnboard,
      CardsEnums.OfferStatus.PendingSignature,
    ];
    return !!offer && activesOfferStatus.includes(offer.status);
  }, [offer, offerError]);

  useEffect(() => {
    if (!account && !accountError && !loadingAccount) getCardsAccount();
  }, [account, accountError, loadingAccount, getCardsAccount]);

  useEffect(() => {
    if (!offer && !offerError && !loadingOffer) getCardsOffer();
  }, [offer, offerError, loadingOffer, getCardsOffer]);

  const value = useMemo(
    () => ({
      account,
      loadingAccount,
      accountError,
      hasAccountRegistered,
      fetchCardsAccount: getCardsAccount,
      offer,
      loadingOffer,
      offerError,
      hasOfferActive,
      fetchCardsOffer: getCardsOffer,
    }),
    [
      account,
      loadingAccount,
      accountError,
      hasAccountRegistered,
      getCardsAccount,
      offer,
      loadingOffer,
      offerError,
      hasOfferActive,
      getCardsOffer,
    ],
  );

  return (
    <cardsCustomerContext.Provider value={value}>
      {children}
    </cardsCustomerContext.Provider>
  );
};

export const useCardsCustomerContext = () => useContext(cardsCustomerContext);
