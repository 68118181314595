import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiSystemsResponseItem } from "services/clerk";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./clerk-systems.grid";

const dataSource = dataSourceCustomStoreGenerator<GetApiSystemsResponseItem>(
  () =>
    service.clerk
      .getSystems()
      .then(({ data }) => data)
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar sistemas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const ClerkSystems = () => {
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Sistemas Clerk"
          description="Visualizar sistemas cadastrados no clerk"
        />
      }
      actions={
        <RouterButton
          dataAction="ferramentas_clerk_sistemas:botao:adicionar"
          dataLabel="adicionar"
          href={routes.ferramentas.product.clerk.add.path}
          role={roles.ferramentas.product.clerkAddPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
