import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetOdataBorderosODataItem } from "services/quickfin/models";

export const grid: ODataGridGeneratorConfig<GetOdataBorderosODataItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      caption: "Detalhes",
      role: roles.recebiveis.product.titlesImportedBatchesDetailsPage.role,
      cellRender: ({ data: { Id } }) => (
        <DetailsLink
          href={corporateRouter.routes.recebiveis.product.titles.importedBatches.details.path(
            {
              batcheId: Id,
            },
          )}
        />
      ),
    },
    {
      caption: "Id",
      dataField: "Id",
    },
    { caption: "Sacado", dataField: "SacadoName" },
    {
      dataField: "MaturityAmount",
      caption: "Valor vencimento",
      cellRender: ({ data }) => (
        <OTypography tag="span">
          {masks.currency(data.MaturityAmount)}
        </OTypography>
      ),
    },
    {
      caption: "Criado em",
      dataField: "CreatedAt",
      dataType: "date",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
    },
  ],
};
