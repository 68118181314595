import { OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { OwnerInStateLibrary } from "../wallet-state.utils";

export const OwnerInStatesFilter = () => {
  return (
    <ORFieldSelectMultiple id="states" label="Status faltantes" name="states">
      {Object.keys(OwnerInStateLibrary).map((state) => (
        <OOptionMultiple key={state} value={state}>
          {OwnerInStateLibrary[Number(state)]}
        </OOptionMultiple>
      ))}
    </ORFieldSelectMultiple>
  );
};
