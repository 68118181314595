import { OConfirmationModal } from "@maestro/react";
import { ConfirmationModalProps } from "./confirmation-modal.types";

export const runMultipleModalId = "jobs-run-multiple-modal";
export const stopMultipleModalId = "jobs-stop-multiple-modal";

const typeMap = {
  run: "redisparar",
  stop: "parar",
};

export const ConfirmationModal = ({
  id,
  job,
  jobsAmount,
  onConfirm,
  type,
}: ConfirmationModalProps) => {
  const modalTitle = type === "run" ? "Reprocessamento" : "Parar processamento";

  const modalText =
    job !== undefined
      ? `Tem certeza que deseja ${typeMap[type]} o job ${job}?`
      : jobsAmount === 1
      ? `Tem certeza que deseja ${typeMap[type]} o job selecionado?`
      : `Tem certeza que deseja ${typeMap[type]} os ${jobsAmount} jobs selecionados?`;

  return (
    <OConfirmationModal
      modalId={id}
      title={modalTitle}
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={onConfirm}
      backdrop
    >
      <div>{modalText}</div>
    </OConfirmationModal>
  );
};
