import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import { service } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo } from "react";
import { CompanyTraitFormProps } from "./company-traits.types";
import {
  companyTraitsDefaultValues,
  companyTraitsValidationSchema,
} from "./company-traits.form";

export const useCompanyTraits = (input: string | undefined = undefined) => {
  const {
    callService: deleteCompanyTraits,
    hasError: deleteCompanyTraitsHasError,
  } = useServiceCall(service.recebiveisCartao.deleteCompanyTraits);

  const { callService: addCompanyTraits, hasError: addCompanyTraitsHasError } =
    useServiceCall(service.recebiveisCartao.addCompanyTraits);

  const form = useForm<CompanyTraitFormProps>({
    defaultValues: companyTraitsDefaultValues,
    resolver: yupResolver(companyTraitsValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(() => reset(companyTraitsDefaultValues), [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        const taxIds = input ? values.taxIds.concat(input) : values.taxIds;
        addCompanyTraits({
          taxIds,
          traits: values.traits,
        });
        clear();
      }),
    [addCompanyTraits, clear, handleSubmit, input],
  );

  const {
    callService: getCompanyProperties,
    loading: propertiesLoading,
    value: properties,
  } = useServiceCall(service.recebiveisCartao.getCompanyProperties);

  useEffect(() => {
    getCompanyProperties();
  }, [getCompanyProperties]);

  return {
    form,
    clear,
    submit,
    addCompanyTraits,
    addCompanyTraitsHasError,
    deleteCompanyTraits,
    deleteCompanyTraitsHasError,
    properties,
    propertiesLoading,
  };
};
