export const antecipacaoDeCartoesRouteParams = {
  companyId: "companyId",
  operationId: "operationId",
  taxId: "taxId",
  tierId: "tierId",
  maturityDate: "maturityDate",
} as const;

type AntecipacaoDeCartoesParams = Record<
  keyof typeof antecipacaoDeCartoesRouteParams,
  string
>;

export type AntecipacaoDeCartoesEnquadramentoCarteiraTiersByTierId = Pick<
  AntecipacaoDeCartoesParams,
  "tierId"
>;

export type AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes =
  Pick<AntecipacaoDeCartoesParams, "companyId" | "operationId">;

export type AntecipacaoDeCartoesContractSignatoriesByTaxId = Pick<
  AntecipacaoDeCartoesParams,
  "taxId"
>;

export type AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes = Pick<
  AntecipacaoDeCartoesParams,
  "maturityDate"
>;
