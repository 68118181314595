import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

export const DocumentDevolutive = () => {
  return (
    <RouterButton
      dataAction="ferramentas:botao:visualizar"
      dataLabel="visualizar"
      href={
        corporateRouter.routes.cadastro.customer.tools.documentDevolutive.path
      }
      role={roles.cadastro.customer.toolsDocumentDevolutivePage.role}
    >
      Visualizar
    </RouterButton>
  );
};
