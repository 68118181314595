import { OTypography } from "@maestro/react";
import { CopyButton } from "components/copy-button";
import { OTruncateTypography } from "components/o-truncate-typography";
import { DetailItem } from "../../../../bankslip-details.types";

export const DetailItemComponent = ({
  content,
  title,
  textToCopy,
}: DetailItem) => {
  return (
    <div className="d-flex flex-column gap-1 w-50">
      <OTypography type="dark" key={title}>
        {title}
      </OTypography>

      <div className="d-flex align-items-center gap-2">
        <OTruncateTypography type="dark-80" key={content}>
          {content}
        </OTruncateTypography>
        {!!textToCopy && (
          <CopyButton
            target={textToCopy}
            toasterMessage="Copiado com sucesso."
            size="md"
          />
        )}
      </div>
    </div>
  );
};
