import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { resalesGrid } from "./resales.grid";

export const ResalesPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de Parceiros/Revendas" />}
      actions={
        <RouterButton
          dataAction="revendas:botao:adicionar_revenda"
          dataLabel="adicionar_revenda"
          role={roles.workflow.product.resalesAddPage.role}
          href={corporateRouter.routes.workflow.product.resales.add.path}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={resalesGrid} />
    </GridTemplate>
  );
};
