import { OTooltip } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { StyledValue } from "../roadwalker-master-detail.style";
import { TradeCallbackMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerTrdeCallbackDetails = (
  data: TradeCallbackMasterDetail,
) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    tradecallback: {
      items: [
        {
          label: "TradeId",
          value: <StyledValue>{data.TradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.ExternalId}</span>
                {data.ExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BackOfficeStatus",
          value: <StyledValue>{data.BackOfficeStatus}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "RegisterIdentOp",
          value: <StyledValue>{data.RegisterIdentOp}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "GuaranteeId",
          value: <StyledValue>{data.GuaranteeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Reason",
          value: <StyledValue>{data.Reason}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Code",
          value: <StyledValue>{data.Code}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProductName",
          value: <StyledValue>{data.ProductName}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
