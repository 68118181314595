import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { brazilStates } from "services/public/models/types/brazil-states.utils";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { Collateral } from "../../imovel-additional-data.types";
import {
  PropertyFormValues,
  propertyFormValidationsSchema,
} from "./property-form.schemas";

interface PropertyFormProps {
  collateral?: Collateral;
}

export const PropertyForm = ({ collateral }: PropertyFormProps) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<PropertyFormValues>({
    resolver: yupResolver(propertyFormValidationsSchema),
  });

  const { reset, setValue, watch, handleSubmit, resetField } = form;

  const stateWatch = watch("state");

  const {
    callService: getCities,
    value: cities,
    hasError: hasErrorCities,
    loading: loadingCities,
  } = useServiceCall(service.onboarding.getCities);

  const handleUpdateProperty = useCallback(
    async (values: PropertyFormValues) => {
      try {
        if (!collateral?.properties?.[0]?.id) return;

        setLoading(true);

        await service.hubCreditManager.updateProperty({
          ...values,
          city:
            cities?.find((city) => city.cityCode === String(values.cityId))
              ?.cityName ?? "",
          id: +collateral.properties[0].id,
        });

        OToastManager.success("Propriedade atualizada com sucesso!");
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao tentar atualizar a propriedade.",
        );
      } finally {
        setLoading(false);
      }
    },
    [cities, collateral?.properties],
  );

  const handleGetCityByExternalId = useCallback(
    async (externalId: number) => {
      try {
        const { data } = await service.onboarding.getCity({ externalId });
        setValue("state", data?.stateCode);
        setValue("cityId", data?.externalId as number);
      } catch {
        OToastManager.danger("Ocorreu um erro ao buscar a cidade da garantia.");
      }
    },
    [setValue],
  );

  useEffect(() => {
    collateral?.properties?.length &&
      reset({
        ...collateral.properties[0],
        ...collateral?.properties[0].address,
      } as PropertyFormValues);

    if (collateral?.properties?.[0]?.address?.cityId)
      handleGetCityByExternalId(collateral.properties[0].address.cityId);
  }, [collateral, handleGetCityByExternalId, reset]);

  useEffect(() => {
    if (!stateWatch) return;

    resetField("cityId");

    getCities({
      stateCode: stateWatch,
      countryCode: "BR",
    });
  }, [getCities, resetField, stateWatch]);

  useEffect(() => {
    hasErrorCities &&
      OToastManager.danger("Ocorreu um erro ao buscar as cidades.");
  }, [hasErrorCities]);

  return (
    <FormTemplate
      pageTitle={
        <OTypography className="my-4" tag="h3" size="lg" type="dark">
          Dados da propriedade
        </OTypography>
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_imovel_garantia_form:botao:atualizar_dados_da_propriedade"
          dataLabel="atualizar_dados_da_propriedade"
          onClick={handleSubmit(handleUpdateProperty)}
        >
          Atualizar dados da propriedade
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                dataAction="emprestimos_imovel_garantia_form:input:nome"
                dataLabel="nome"
                label="Nome"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                id="nirf"
                name="nirf"
                dataAction="emprestimos_imovel_garantia_form:input:nirf"
                dataLabel="nirf"
                label="NIRF"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                id="ccir"
                name="ccir"
                dataAction="emprestimos_imovel_garantia_form:input:ccir"
                dataLabel="ccir"
                label="CCIR"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                id="rgi"
                name="rgi"
                dataAction="emprestimos_imovel_garantia_form:input:rgi"
                dataLabel="rgi"
                label="RGI"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="number"
                id="idealFraction"
                name="idealFraction"
                dataAction="emprestimos_imovel_garantia_form:input:fracao_ideal"
                dataLabel="fracao_ideal"
                label="Fração ideal"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="number"
                id="totalArea"
                name="totalArea"
                dataAction="emprestimos_imovel_garantia_form:input:area_total"
                dataLabel="area_total"
                label="Área Total (hectares)"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldSelect
                id="state"
                name="state"
                dataAction="emprestimos_imovel_garantia_form:select:estado"
                dataLabel="estado"
                label="Estado"
              >
                {brazilStates.map((state) => (
                  <OOption key={state.uf} value={state.uf}>
                    {state.uf}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol>
              <ORFieldSelect
                id="cityId"
                name="cityId"
                dataAction="emprestimos_imovel_garantia_form:select:cidade"
                dataLabel="cidade"
                label="Cidade"
                key={cities?.length}
              >
                {cities?.map((city) => (
                  <OOption
                    key={city.externalId + city.cityName}
                    value={city.externalId ?? ""}
                  >
                    {city.cityName}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingCities && <OLoader size="sm" />}
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                id="car"
                name="car"
                dataAction="emprestimos_imovel_garantia_form:input:cadastro_ambiente_rural"
                dataLabel="cadastro_ambiente_rural"
                label="Cadastro Ambiental Rural (CAR)"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                id="registration"
                name="registration"
                dataAction="emprestimos_imovel_garantia_form:input:matricula"
                dataLabel="matricula"
                label="Matrícula"
              />
            </OCol>
          </ORow>
          <OCol md={6}>
            <ORFieldInput
              tag="number"
              id="identification"
              name="identification"
              dataAction="emprestimos_imovel_garantia_form:input:identificacao"
              dataLabel="identificacao"
              label="Identificação"
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
