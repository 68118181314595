import { masks } from "@maestro/utils";
import { currencySymbol } from "utils/currency";
import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const AvailableOffersCard = () => {
  return (
    <InfoCard
      summaryType="offersSummary"
      render={(_value) => (
        <InfoCardContent
          header="Ofertas disponíveis"
          body={masks.currency(_value.availableAmount, currencySymbol.BRL)}
          footer="Valor em reais das ofertas disponíveis"
        />
      )}
    />
  );
};
