import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";

export const useOwnersFilter = () => {
  const [selected, setSelected] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const { callService: getAllOwners } = useServiceCall(
    service.moana.getAllOwners,
  );

  const getOwners = useCallback(async () => {
    const data = await getAllOwners();
    return data.response;
  }, [getAllOwners]);

  useEffect(() => {
    const fetchOwners = async () => {
      const response = await getOwners();
      if (!response?.data) return;
      const newData = response.data.map((v) => {
        return {
          value: v.uuid,
          label: `${v.name} (${fastCpfCnpjMask(v.documentNu)})`,
        };
      });
      setSelected(newData);
    };

    fetchOwners();
  }, [getOwners]);

  return { selected };
};
