import { OIcon, OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { DetailsCard } from "components/details-card";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useNavigate, useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { impersonateRedirect } from "utils/impersonate";
import { RemoveRelationshipButton } from "../../../components";
import { useFindUserFromCustomer } from "../../../hooks";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";
import { invalidateToken, sendWelcomeEmail } from "../utils";
import { NotificationArea } from "./_compose";
import { formatUsersField } from "./user-details.details";

export const UserCustomerDetails = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const {
    customer: { identification: customerIdentification },
  } = useCustomer();

  const user = useFindUserFromCustomer(identification);

  const navigate = useNavigate();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Dados do Usuário" />}>
      <div className="d-flex flex-column gap-4">
        <NotificationArea />
        <DetailsTemplate
          actions={
            <>
              <RemoveRelationshipButton
                callback={() =>
                  navigate(corporateRouter.routes.usuarios.customer.path)
                }
                companyTaxId={customerIdentification}
                userTaxId={identification}
                role={
                  roles.usuarios.product.removeUserCompanyRelationshipButton
                    .role
                }
              />
              <ButtonWithConfirmation
                confirmationModal={{
                  title: "Reenviar boas-vindas",
                  confirmLabel: "Sim",
                  closeLabel: "Não",
                }}
                modalBody={
                  <OTypography>
                    Confirma o envio de e-mail de boas vindas?
                  </OTypography>
                }
                dataAction="detalhes_usuarios:botao:reenviar_boas_vindas"
                dataLabel="reenviar_boas_vindas"
                onConfirm={() =>
                  sendWelcomeEmail(user.cpf, customerIdentification)
                }
              >
                <div className="d-flex flex-row gap-2 align-center">
                  <OIcon icon="orq-email" category="orq" size="lg" />
                  <OTypography>Reenviar boas-vindas</OTypography>
                </div>
              </ButtonWithConfirmation>
              <ButtonWithConfirmation
                confirmationModal={{
                  title: "Bloquear dispositivos",
                  confirmLabel: "Sim",
                  closeLabel: "Não",
                }}
                modalBody={
                  <OTypography>
                    Confirma o bloqueio de todos os dispositivos confiáveis do
                    usuário?
                  </OTypography>
                }
                dataAction="detalhes_usuarios:botao:bloquear_dispositivos"
                dataLabel="bloquear_dispositivos"
                onConfirm={() => user.cpf && invalidateToken(user.cpf)}
              >
                <div className="d-flex flex-row gap-2 align-center">
                  <OIcon icon="orq-block" category="orq" size="lg" />
                  <OTypography>Bloquear dispositivos</OTypography>
                </div>
              </ButtonWithConfirmation>
              <IconButton
                dataAction="detalhes_usuarios:botao:impersonar"
                dataLabel="impersonar"
                icon={{ category: "far", icon: "fa-portrait" }}
                onClick={() =>
                  user.email &&
                  impersonateRedirect(user.email, "BTG_PACTUAL_EMPRESAS")
                }
              >
                Impersonar
              </IconButton>
            </>
          }
        >
          <DetailsCard fields={formatUsersField(user)} />
        </DetailsTemplate>
      </div>
    </DetailsTemplate>
  );
};
