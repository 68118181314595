import { OTooltip, OTypography } from "@maestro/react";
import { StatusBadgeProps } from "./status-badge.types";
import { DefaultStatusBadge } from "./_compose";

export const StatusBadge = ({
  badgeType,
  icon,
  status,
  tooltip,
}: StatusBadgeProps) => {
  if (tooltip?.text) {
    return (
      <OTooltip
        maxWidth={tooltip.maxWidth ?? "20rem"}
        position={tooltip.position}
        floating
      >
        <div
          className={`py-2 ${tooltip.justifyText ? "text-justify" : ""}`}
          slot="tooltip-content"
        >
          <OTypography size={tooltip.textSize}>{tooltip.text}</OTypography>
        </div>
        <DefaultStatusBadge badgeType={badgeType} icon={icon} status={status} />
      </OTooltip>
    );
  }

  return (
    <DefaultStatusBadge badgeType={badgeType} icon={icon} status={status} />
  );
};
