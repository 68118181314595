import { OField, OLoader, OOption, OSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { GetEstrategiaComercialByCompanyIdResponse } from "services/quickfin/models";
import { useEstrategiaSelector } from "./estrategia-selector.hook";

interface EstrategiaSelectorProps {
  companyId: string | number;
  setEstrategia: (
    estrategia: GetEstrategiaComercialByCompanyIdResponse[number],
  ) => void;
}

export const EstrategiaSelector = ({
  companyId,
  setEstrategia,
}: EstrategiaSelectorProps) => {
  const { errorMessage, estrategias, getCompanyEstrategias, loading } =
    useEstrategiaSelector(companyId);

  return (
    <div className="d-flex align-items-center flex-fill">
      <OField
        label="Estratégia"
        htmlFor="estrategia_selector"
        message={errorMessage}
        error={!!errorMessage}
      >
        <div>
          <OSelect
            dataAction="selecionar_estrategia:select:selecionar_estrategia"
            dataLabel="selecionar_estrategia"
            id="estrategia_selector"
            placeholder="Selecionar"
            disabled={!!errorMessage || loading}
            onInput={(evt) => {
              setEstrategia(
                estrategias!.find(
                  ({ id }) => id === (evt.target as HTMLOSelectElement).value,
                )!,
              );
            }}
            key={estrategias?.length}
          >
            {estrategias?.map((estrategia) => (
              <OOption key={estrategia.id} value={estrategia.id}>
                {estrategia.nome}
              </OOption>
            ))}
          </OSelect>
        </div>
      </OField>
      {loading && <OLoader size="sm" />}
      {!!errorMessage && !loading && (
        <RefreshGridButton
          dataAction="selecionar_estrategia:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getCompanyEstrategias()}
        />
      )}
    </div>
  );
};
