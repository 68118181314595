import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
// eslint-disable-next-line no-restricted-imports
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { service } from "services";
import { PostEmailCotacaoBody } from "services/quickfin/models";
import { PostApiSearchLegalEntityDetailsResponse } from "services/onboarding/models";
import { CedenteSacadoInfos } from "services/quickfin/models/responses/get-sacados-summaries-response";
import { logger } from "utils/logger";

export const useCompanyDetails = () => {
  const [contaTransacional, setContaTransacional] = useState({ balance: 0 });
  const [contaGarantia, setContaGarantia] = useState({ balance: 0 });
  const [cedenteSacadosInfo, setCedenteSacadosInfo] = useState<
    CedenteSacadoInfos[]
  >([]);
  const [vnpDays, setVnpDays] = useState(0);
  const [antecipacaoAutomaticaLigada, setAntecipacaoAutomaticaLigada] =
    useState(false);
  const [limitsCedente, setLimitsCedente] = useState({
    availableAmount: "",
    totalAmount: "",
    consumedAmount: "",
  });
  const [detail, setDetail] =
    useState<PostApiSearchLegalEntityDetailsResponse | null>(null);

  const customerInfo = customer.value;

  const contaLiquidacao = useMemo(() => {
    const liquidacao = customer.value?.bankAccounts.find(
      (acc) => acc.agency === "1",
    );

    if (liquidacao) {
      return liquidacao.accountNumber + liquidacao.accountDigit;
    }

    return "";
  }, []);

  const balanceArray = useCallback(async () => {
    return service.adminBankinghub
      .getAccounts({
        taxId: customer.value?.identification || "",
      })
      .then(({ data }) => {
        Promise.all(
          data
            .filter((acc) => acc.businessAccount && acc.service !== null)
            .map(async (acc) => {
              const balance = await service.adminBankinghub.getBalanceByAccount(
                Number(acc.number),
                acc.agency,
              );

              if (acc.agency === 50) {
                setContaTransacional({ ...acc, ...balance.data });
              } else
                setContaGarantia({
                  ...acc,
                  ...balance.data,
                });
            }),
        );
      });
  }, []);

  const sacadosSummaries = useCallback(async () => {
    return service.quickfin
      .getSacadosSummaries(customer.value?.identification || "", "sacados")
      .then(({ data }) => {
        if (data.items) {
          setCedenteSacadosInfo(data.items);
        }
      });
  }, []);

  const onboardingInfo = {
    status: customer.value?.onboardings
      .find((onb) => onb.type === "CONTA_ESCROW")
      ?.status.toLowerCase(),
    data: moment(
      customer.value?.onboardings.find((onb) => onb.type === "CONTA_ESCROW")
        ?.updatedAt,
    ).format("DD/MM/YYYY"),
  };

  const eligibilityStatus = useCallback(async () => {
    try {
      const { data } = await service.onboarding.getLegalEntityDetails({
        taxId: customer.value?.identification || "",
      });

      setDetail(data);
    } catch (err) {
      logger.error("Erro ao buscar os dados");
    }
  }, []);

  const vnpDaysDiff = useCallback(async () => {
    return service.quickfin.getAdminBalances().then(({ data }) => {
      if (data) {
        const lastPaymentDate = data.find(
          (company) =>
            company.cedenteIdentification === customer.value?.identification &&
            company.tipoProduto === "Cartao",
        )?.oldestValueDate;
        const diffDays = moment().diff(lastPaymentDate, "days");
        setVnpDays(diffDays + 1);
      }
    });
  }, []);

  const antecipacaoAutomaticaConfig = useCallback(async () => {
    return service.recebiveisCartao
      .getAntecipacaoAutomaticaConfig()
      .then(({ data }) => {
        if (data) {
          setAntecipacaoAutomaticaLigada(data.ligado);
        }
      });
  }, []);

  const limits = useCallback(async () => {
    return service.limitControl
      .getLimitBagWithDependents(
        "QuickFin:Cartao:V2",
        customer.value?.identification.slice(0, 8) || "",
      )
      .then(({ data }) => {
        if (data) {
          setLimitsCedente({ ...data });
        }
      });
  }, []);

  const { callService: callCedenteDeflator, value: cedenteDeflator } =
    useServiceCall(service.recebiveisCartao.getCedenteDeflator);

  const { callService: callVnpSummary, value: vnpSummary } = useServiceCall(
    service.recebiveisCartao.getVnpSummary,
  );

  const submit = useCallback(async () => {
    try {
      const payload: PostEmailCotacaoBody[] = [
        {
          emails: ["ol-pme-cartoes@btgpactual.com"],
          headquarterTaxId: customer.value?.identification,
          rootTaxId: null,
          routeVersion: "v2"
        },
      ];

      await service.quickfin.sendEmailCotacao(payload);

      OToastManager.success("Email de cotação reenviado com sucesso");
    } catch (err) {
      OToastManager.danger("Erro ao reenviar email de cotação");
    }
  }, []);

  const redisponibilizarRecebiveis = useCallback(async () => {
    try {
      const payload = {
        cnpj: (customer.value?.identification || "").slice(0, 8),
      };

      await service.recebiveisCartao.redisponibilizarRecebiveis(payload);

      OToastManager.success(
        "Redisponibilização enviada para processamento. Aguarde alguns minutos e verifique.",
      );
    } catch (err) {
      OToastManager.danger("Erro ao reprocessar recebíveis");
    }
  }, []);

  useEffect(() => {
    callCedenteDeflator();
    balanceArray();
    sacadosSummaries();
    vnpDaysDiff();
    antecipacaoAutomaticaConfig();
    limits();
    callVnpSummary();
    eligibilityStatus();
  }, [
    callCedenteDeflator,
    balanceArray,
    sacadosSummaries,
    vnpDaysDiff,
    antecipacaoAutomaticaConfig,
    limits,
    callVnpSummary,
    eligibilityStatus,
  ]);

  return {
    contaTransacional,
    contaGarantia,
    cedenteSacadosInfo,
    vnpDays,
    antecipacaoAutomaticaLigada,
    limitsCedente,
    contaLiquidacao,
    onboardingInfo,
    detail,
    cedenteDeflator,
    submit,
    redisponibilizarRecebiveis,
    customerInfo,
    vnpSummary,
  };
};
