import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CreateAccountForm, NotificationOptin } from "./_compose";
import { useCreateAccountsOptin } from "./create-accounts-optin.hook";

export const NewAccountsOptinCreateAccountsPage = () => {
  const { onSubmit, loading, form } = useCreateAccountsOptin();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Abertura de Contas Opt-in"
          description="Preencha o formulário abaixo para enviar a solicitação para abertura de novas contas."
        />
      }
      actions={
        <LoadingButton
          dataAction="criacao-conta-optin:botao:criar-contas"
          dataLabel="Enviar Solicitação"
          onClick={onSubmit}
          loading={loading}
        >
          Enviar Solicitação
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-4">
        <NotificationOptin />
        <CardTemplate>
          <FormProvider {...form}>
            <CreateAccountForm />
          </FormProvider>
        </CardTemplate>
      </div>
    </FormTemplate>
  );
};
