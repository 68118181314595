import { OCard, OCardBody, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { DetailsContent } from "components/details-card/details-content.component";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import {
  GuarantorsLink,
  InstallmentsLink,
  OverdraftErrorComponent,
  OverdraftStatusBadge,
} from "./_compose";
import { buildOverdraftAnalysisFields } from "./_details/overdraft-analysis.details";
import { buildOverdraftLimitFields } from "./_details/overdraft-limit.details";
import { buildOverdraftTaxesFields } from "./_details/overdraft-taxes.details";
import { useOverdraftAnalysis } from "./overdraft-analysis.hook";
import { useOverdraftLimit } from "./overdraft-limit.hook";

export const OverdraftDetails = () => {
  const {
    loading: limitLoading,
    hasError: limitHasError,
    limit,
    analysisStatus,
    getLimit,
  } = useOverdraftLimit();

  const {
    loading: analysisLoading,
    hasError: analysisHasError,
    analysis,
    taxes,
    getAnalysis,
  } = useOverdraftAnalysis();

  useEffect(() => {
    getAnalysis(analysisStatus);
  }, [analysisStatus, getAnalysis]);

  const noLimitOrAnalysis =
    !limitLoading &&
    !limitHasError &&
    !analysisLoading &&
    !analysisHasError &&
    !limit &&
    !analysis;

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Limite da conta" />}>
      {noLimitOrAnalysis ? (
        <OTypography type="default">Sem Limite contratado</OTypography>
      ) : (
        <div className="d-flex flex-column gap-4">
          <ContentTemplate
            loading={limitLoading}
            hasError={limitHasError}
            errorComponent={null}
            noValue
            render={() => (
              <div className="d-flex align-items-center gap-2">
                <OTypography type="default">Status do Limite:</OTypography>
                <OverdraftStatusBadge limit={limit} />
              </div>
            )}
          />

          <ContentTemplate
            loading={limitLoading}
            hasError={limitHasError}
            value={limit}
            emptyStateComponent={null}
            errorComponent={
              <OverdraftErrorComponent tryAgainCallback={() => getLimit()} />
            }
            render={(_limit) => (
              <DetailsTemplate
                actions={<InstallmentsLink ownerId={_limit.owner?.id} />}
              >
                <OCard>
                  <OCardBody>
                    <DetailsContent
                      fields={buildOverdraftLimitFields(_limit)}
                    />
                  </OCardBody>
                </OCard>
              </DetailsTemplate>
            )}
          />
          <ContentTemplate
            loading={analysisLoading}
            hasError={analysisHasError}
            value={taxes}
            emptyStateComponent={null}
            errorComponent={
              <OverdraftErrorComponent
                tryAgainCallback={() => getAnalysis(analysisStatus)}
              />
            }
            render={(_taxes) => (
              <OCard>
                <OCardBody>
                  <DetailsContent fields={buildOverdraftTaxesFields(_taxes)} />
                </OCardBody>
              </OCard>
            )}
          />
          <ContentTemplate
            loading={analysisLoading}
            hasError={analysisHasError}
            value={analysis}
            emptyStateComponent={null}
            errorComponent={
              <OverdraftErrorComponent
                tryAgainCallback={() => getAnalysis(analysisStatus)}
              />
            }
            render={(_analysis) => (
              <DetailsTemplate
                actions={<GuarantorsLink analysisStatus={analysisStatus} />}
              >
                <OCard>
                  <OCardBody>
                    <DetailsContent
                      fields={buildOverdraftAnalysisFields(_analysis)}
                    />
                  </OCardBody>
                </OCard>
              </DetailsTemplate>
            )}
          />
        </div>
      )}
    </DetailsTemplate>
  );
};
