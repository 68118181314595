import {
  OButton,
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { newDeflatorDefaulValues } from "./new-deflator.form";
import { useNewDeflator } from "./new-deflator.hook";

export const NewDeflator = () => {
  const { acquirers, acquirersLoading, form, loading, submit } =
    useNewDeflator();

  const { reset } = form;

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar deflator" />}
      actions={
        <>
          <OButton
            dataAction="novo_deflator:botao:limpar"
            dataLabel="limpar"
            type="dark"
            outline
            onClick={() => reset(newDeflatorDefaulValues)}
          >
            Limpar
          </OButton>
          <LoadingButton
            dataAction="novo_deflator:botao:salvar"
            dataLabel="salvar"
            onClick={submit}
            loading={loading}
          >
            Salvar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={4}>
              <ORFieldInput
                dataAction="novo_deflator:texto:cnpj"
                dataLabel="cnpj"
                id="taxId"
                name="taxId"
                tag="cnpj"
                label="CNPJ da empresa"
                placeholder="00.000.000/0000-00"
              />
            </OCol>
            <OCol xs={12} md={4} align="center" className="gap-2">
              <ORFieldSelect
                dataAction="novo_deflator:select:credenciadoras"
                dataLabel="credenciadoras"
                id="acquirerId"
                name="acquirerId"
                label="Credenciadoras"
                placeholder="Selecione"
                disabled={acquirersLoading}
                key={acquirers?.credenciadoras.length}
              >
                {acquirers?.credenciadoras.map((acquirer) => (
                  <OOption key={acquirer.id} value={String(acquirer.id)}>
                    {acquirer.name}
                  </OOption>
                ))}
              </ORFieldSelect>
              {acquirersLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
            <OCol xs={12} md={4}>
              <ORFieldInput
                dataAction="novo_deflator:numero:deflator"
                dataLabel="deflator"
                id="deflator"
                name="deflator"
                tag="mask"
                label="Deflator"
                maskOption={{ mask: Number }}
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
