import { OperationsInstallmentsProvider } from "../parcelas/operations-installments.context";
import { OperationsPaymentsComponent } from "./operations-payments.component";

export const OperationsPaymentsPage = () => {
  return (
    <OperationsInstallmentsProvider>
      <OperationsPaymentsComponent />
    </OperationsInstallmentsProvider>
  );
};
