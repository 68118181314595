import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OField,
  ORFieldInput,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PutSnakeFinancialByFinancialIdBody } from "services/fidc/models/requests";
import { service } from "services/service";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { EditList } from "../../../edit-list";
import { FinancialActionSearchParams } from "../interaction-financials.type";
import { interactionFinancialEditValidationSchema } from "./interaction-financial-edit.form";
import { CompanyInteractionFinancialEditForm } from "./interaction-financial-edit.type";

export const CompanyInteractionFinancialEdit = () => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<CompanyInteractionFinancialEditForm>({
    resolver: yupResolver(interactionFinancialEditValidationSchema),
  });
  const { handleSubmit, reset } = form;

  const fetchFinancialContact = useCallback(async () => {
    const { contact } = getSearchParams<FinancialActionSearchParams>();
    if (!contact) {
      return;
    }

    const parsedContact = JSON.parse(contact);

    setIsDisabled(parsedContact.isDisabled);
    const initialValue = {
      ...parsedContact,
      cellPhoneNumber: parsedContact.cellPhoneNumber.split(";"),
      email: parsedContact.email.split(";"),
    };
    reset(initialValue);
  }, [reset]);

  useEffect(() => {
    fetchFinancialContact();
  }, [fetchFinancialContact]);

  const onSubmit = handleSubmit(async (values) => {
    const validateField = (field: string | string[]) => {
      if (Array.isArray(field)) {
        return field.filter((value) => value && value.trim() !== "");
      }
      return field && field.trim() !== "" ? [field] : [];
    };

    const validEmails = validateField(values.email);
    const validPhones = validateField(values.cellPhoneNumber);

    if (validEmails.length === 0 || validPhones.length === 0) {
      OToastManager.danger(
        "É necessário fornecer ao menos um email e um número de telefone válido.",
      );
      return;
    }

    const body: PutSnakeFinancialByFinancialIdBody = {
      id: values.id,
      name: values.name,
      email: validEmails.join(";"),
      cellPhoneNumber: validPhones.join(";"),
      isDisabled,
    };

    setLoading(true);
    try {
      await service.fidc.putFinancial(body);
      OToastManager.success("Contato financeiro atualizado com sucesso!");
      navigate(-1);
    } catch {
      OToastManager.danger("Erro ao tentar atualizar contato financeiro.");
    } finally {
      setLoading(false);
    }
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Edição do contato" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="editar_contato_financeiro:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="editar_contato_financeiro:texto:id"
                dataLabel="id"
                id="id"
                name="id"
                tag="text"
                label="Id"
                disabled
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="editar_contato_financeiro:texto:nome"
                dataLabel="nome"
                id="name"
                name="name"
                tag="text"
                label="Nome"
              />
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="email" label="Email">
                <EditList
                  field="email"
                  sectionName="editar_contato_financeiro"
                />
              </OField>
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="cellPhoneNumber" label="Telefone">
                <EditList
                  field="cellPhoneNumber"
                  sectionName="editar_contato_financeiro"
                />
              </OField>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
