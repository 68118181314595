import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export const useProcessBorderos = () => {
  const [loading, setLoading] = useState(false);

  const callService = useCallback(
    async (...args: Parameters<typeof service.quickfin.processMultiBorderos>) => {
      try {
        setLoading(true);

        await service.quickfin.processMultiBorderos(...args);
        OToastManager.success("Recebíveis cadastrados com sucesso");
      } catch (err) {
        if (!isAxiosError(err)) logger.error(err);
        OToastManager.danger("Não foi possível fazer o upload dos recebíveis");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    loading,
    callService,
  };
};
