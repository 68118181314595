import { OTooltip } from "@maestro/react";
import { logger } from "utils/logger";

interface ParsedTooltipProps {
  value: string;
}

export const ParsedTooltip = ({ value }: ParsedTooltipProps) => {
  let parsedText = value;

  try {
    parsedText = JSON.stringify(JSON.parse(value), null, 2);
  } catch {
    logger.debug("Unable to format json");
  }

  return (
    <OTooltip floating position="top-left">
      <pre
        style={{
          textAlign: "initial",
        }}
        slot="tooltip-content"
      >
        {parsedText}
      </pre>
      {value}
    </OTooltip>
  );
};
