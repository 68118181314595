import { OOption, ORFieldSelect, OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetApiAdminComponentsByIdResponse } from "services/hubcreditmanager/models/responses";
import { useWorkflowFormTemplate } from "../../../../../../../../../../../../../components/hooks/use-workflow-form-template.context";
import { getComponentPropertyFieldId } from "../component-property-field.utils";

interface FormPropertyFieldProps {
  componentProperty: GetApiAdminComponentsByIdResponse["properties"][number];
  handleSubmitComponentProperty: (
    formStepCardComponentId: number,
    propertyId: number,
    value: string,
  ) => Promise<void>;
  formStepCardComponentId: number;
}

export const FormPropertyField = ({
  componentProperty,
  formStepCardComponentId,
  handleSubmitComponentProperty,
}: FormPropertyFieldProps) => {
  const valueRef = useRef("");

  const { setValue, getValues, watch } = useFormContext();

  const {
    value: formList,
    callService: getFormList,
    hasError: hasErrorFormList,
  } = useServiceCall(service.hubCreditManager.getFormsList);

  const property = getComponentPropertyFieldId(
    componentProperty.name,
    componentProperty.id,
  );
  const fieldWatch = watch(property);

  const {
    form: { value: workflowFormTemplate },
  } = useWorkflowFormTemplate();

  useEffect(() => {
    hasErrorFormList &&
      OToastManager.danger(
        "Não foi buscar as opções de formulário. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorFormList]);

  const handleFocusOut = useCallback(() => {
    return handleSubmitComponentProperty(
      formStepCardComponentId,
      componentProperty.id,
      getValues(property),
    );
  }, [
    getValues,
    handleSubmitComponentProperty,
    formStepCardComponentId,
    componentProperty,
    property,
  ]);

  useEffect(() => {
    if (!formList?.length) {
      getFormList();
      return;
    }

    if (componentProperty.value === undefined) return;

    valueRef.current = componentProperty.value;

    setValue(property, componentProperty.value);
  }, [setValue, componentProperty, property, formList, getFormList]);

  useEffect(() => {
    if (valueRef.current === fieldWatch) return;

    const propertyInput = document.getElementById(property);

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [
    handleFocusOut,
    componentProperty.name,
    componentProperty.id,
    property,
    fieldWatch,
  ]);

  const formPropertyField = useMemo(() => {
    return (
      <ORFieldSelect
        key={`${componentProperty.name}_${componentProperty.id}_${formList?.length}`}
        id={property}
        name={property}
        placeholder="Selecione"
        label={componentProperty.description}
        required={componentProperty.isRequired}
      >
        <div>
          {formList?.map(
            (form) =>
              workflowFormTemplate?.identification !== form.identification && (
                <OOption key={form.identification} value={form.identification}>
                  {form.name}
                </OOption>
              ),
          )}
        </div>
      </ORFieldSelect>
    );
  }, [
    componentProperty,
    formList,
    workflowFormTemplate?.identification,
    property,
  ]);

  return <>{formPropertyField}</>;
};
