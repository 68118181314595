import { OCol, OLabel, ORFieldRadioGroup, ORadio } from "@maestro/react";

interface YesNoRadioFieldProps {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
}

export const YesNoRadioField = ({
  label,
  name,
  description,
  required,
}: YesNoRadioFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldRadioGroup
        dataAction={`geral:radio:${name}`}
        dataLabel={name}
        id={name}
        name={name}
        label={label}
        description={description}
        required={required}
      >
        <div className="d-flex gap-4">
          <div className="d-flex gap-2">
            <ORadio id={`${name}-yes`} value="yes" />
            <OLabel htmlFor={`${name}-yes`}>Sim</OLabel>
          </div>
          <div className="d-flex gap-2">
            <ORadio id={`${name}-no`} value="no" />
            <OLabel htmlFor={`${name}-no`}>Não</OLabel>
          </div>
        </div>
      </ORFieldRadioGroup>
    </OCol>
  );
};
