import * as yup from "yup";

export const statusFormValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  nameId: yup.string().required("Campo obrigatório"),
  iconName: yup.string().required("Campo obrigatório"),
  color: yup.string().required("Campo obrigatório"),
  statusGroupId: yup.number(),
  endOnStart: yup.array().of(yup.string()),
});

export const statusFormDefaultValues = {
  name: "",
  nameId: "",
  iconName: "",
  color: "info",
  statusGroupId: 0,
  endOnStart: [] as string[],
};
