import { getValueFromMap } from "utils/get-value-from-map";
import { parseExcelDate } from "utils/parse-excel-date";
import { cleanTaxId, normalizeObjectKeysCards } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { PostAdminReceivableData } from "services/quickfin/models/requests/post-admin-operations-without-registry.request";
import { logger } from "utils/logger";
import { cardBrandMap } from "../../../../../utils/card-brand-map";

export const parseIntubateSpreadsheet = (
  buffer: FileReader["result"],
): PostAdminReceivableData => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  try {
    const dataWithPlaceholders = sheetData.map((row) => {
      const cleanRow = normalizeObjectKeysCards(row);
      return {
        brand:
          getValueFromMap(
            cardBrandMap,
            cleanRow.bandeira.toLowerCase().replace(/\s+/g, ""),
          ) || cleanRow.bandeira.toLowerCase().replace(/\s+/g, ""),
        maturityDate: parseExcelDate(cleanRow["data de vencimento"]),
        maturityAmount: cleanRow["valor de vencimento"],
        payeeTaxIdentification: cleanTaxId(String(cleanRow["cnpj do cedente"])),
        payerTaxIdentification: cleanTaxId(String(cleanRow["cnpj do sacado"])),
        bank: cleanRow.banco,
        agency: cleanRow.agencia,
        account: cleanRow.conta,
        accountDigit: cleanRow["digito da conta"],
      };
    });
    return dataWithPlaceholders;
  } catch (error) {
    logger.error("Error processing data:", error);
    return [];
  }
};
