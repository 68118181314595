import { ODataGridGeneratorConfig } from "components/data-grid";

export const billingReportItemGroupGrid: ODataGridGeneratorConfig<HubEnergy.BillingReportItemResponse> =
  {
    datagrid: {
      noDataText: "Nenhum item encontrado.",
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "name",
        caption: "Descrição",
        dataType: "string",
      },
      {
        dataField: "quantity",
        caption: "Quantidade",
        dataType: "number",
      },
      {
        dataField: "value",
        caption: "Tarifa",
        dataType: "number",
      },
      {
        dataField: "totalValue",
        caption: "Total",
        dataType: "number",
      },
    ],
  };
