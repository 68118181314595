import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ConfirmationDeleteConfigurableColumnsModalProps } from "./delete-configurable-columns-modal.type";
import { deleteConfigurableColumnsModalId } from "./delete-configurable-columns-modal.utils";

export const useConfirmationDeleteConfigurableColumnsModal = ({
  id,
  reload,
}: ConfirmationDeleteConfigurableColumnsModalProps) => {
  const modalId = deleteConfigurableColumnsModalId(id);

  const { callService: deleteConfigurableColumns, loading } = useServiceCall(
    service.hubCreditManager.deleteConfigurableGridColumns,
  );

  const handleConfirm = useCallback(async () => {
    const configurableColumnsId = { id: Number(id) };
    const { success } = await deleteConfigurableColumns(configurableColumnsId);

    if (success)
      OToastManager.success("Coluna Configurável deletada com sucesso!");
    else {
      OToastManager.danger("Erro para deletar a Coluna Configurável.");
    }

    modalManager.hide(modalId);
    reload();
  }, [deleteConfigurableColumns, id, modalId, reload]);

  return { loading, modalId, handleConfirm };
};
