import * as yup from "yup";

export interface CreateProfileRequestFormValues {
  title: string;
}

export const createProfileRequestValidationFormSchema: yup.ObjectSchema<CreateProfileRequestFormValues> =
  yup.object({
    title: yup.string().required("Este campo é obrigatório"),
  });
