import * as yup from "yup";

export interface AddTransactionFormValues {
  amount: number;
  externalId: string;
  reason: string;
  currency: string;
}

export const addTransactionFormValidationsSchema: yup.ObjectSchema<AddTransactionFormValues> =
  yup.object({
    amount: yup
      .number()
      .transform((value) => value || undefined)
      .required("Este campo é obrigatório"),
    externalId: yup.string().required("Este campo é obrigatório"),
    currency: yup.string().required("Este campo é obrigatório"),
    reason: yup.string().default(""),
  });
