import { env } from "utils/environments";

export const sennaEndpoints = {
  taxas: {
    byEstrategiaUuid: {
      byCurrency: (estrategiaUuid: string, currency: string) =>
        `${env.API_SENNA}rates/${estrategiaUuid}/${currency}`,
    },
    configTaxa: {
      endpoint: `${env.API_SENNA}rates/config-rates`,
      byEstrategiaUuid: {
        endpoint: (estrategiaUuid: string) =>
          `${env.API_SENNA}rates/config-rates/${estrategiaUuid}`,
        byCurrency: (estrategiaUuid: string, currency: string) =>
          `${env.API_SENNA}rates/config-rates/${estrategiaUuid}/${currency}`,
      },
    },
    pre: `${env.API_SENNA}rates/pre`,
    taxaPrazo: {
      byTaxaId: (taxaId: string | number) =>
        `${env.API_SENNA}rates/rate-term/${taxaId}`,
    },
  },
};
