import { OToastManager, modalManager } from "@maestro/core";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useMemo, useState } from "react";
import { ReceivablesReprocess } from "services/quickfin/models";
import { service } from "services/service";
import { reprocessConfirmationModalId } from "./modal-reprocess/reprocess-modal.utils";
import { reprocessGrid } from "./reprocess.grid";

export const useReprocess = () => {
  const [selectedReceivables, setSelectedReceivables] =
    useState<ReceivablesReprocess[]>();
  const [loading, setLoading] = useState(false);

  const gridRef = useGridRef();

  const grid = useMemo(
    () => reprocessGrid(setSelectedReceivables),
    [setSelectedReceivables],
  );

  const reducedReceivables = useMemo(() => {
    if (!selectedReceivables) {
      return [];
    }

    const list: ReceivablesReprocess[] = [];

    const receivablesCopy = structuredClone(selectedReceivables);

    receivablesCopy.forEach((receivable) => {
      const found = list.find(
        (query) =>
          query.Sacado === receivable.Sacado &&
          query.Cedente === receivable.Cedente &&
          query.TipoProduto === receivable.TipoProduto,
      );
      if (found) {
        found.ValorVencimento += receivable.ValorVencimento;
        found.ValorDesembolso = found.ValorDesembolso ?? 0;
        found.ValorDesembolso += (receivable.ValorDesembolso ?? 0)
      } else {
        list.push(receivable);
      }
    });

    return list;
  }, [selectedReceivables]);

  const totalSum = useMemo(() => {
    return reducedReceivables.reduce<number>(
      (sum, receivable) => sum + receivable.ValorVencimento,
      0,
    );
  }, [reducedReceivables]);

  const totalDesembolso = useMemo(() => {
    return reducedReceivables.reduce<number>(
      (sum, receivable) => sum + (receivable.ValorDesembolso ?? 0),
      0,
    );
  }, [reducedReceivables]);

  const reprocessReceivables = useCallback(async () => {
    if (selectedReceivables) {
      const receivablesId: number[] = selectedReceivables.map(
        (receivable) => receivable.ReceivableId,
      );

      const body = { receivablesId };

      setLoading(true);

      try {
        await service.quickfin.postReceivableReprocess(body);
        gridRef.current?.instance.clearSelection();
        gridRef.current?.instance.getDataSource().reload();
        setSelectedReceivables([]);
        OToastManager.success("Recebíveis reprocessados com sucesso!");
      } catch {
        OToastManager.danger("Não podemos realizar essa operação!");
      } finally {
        setLoading(false);
        modalManager.hide(reprocessConfirmationModalId);
      }
    }
  }, [
    gridRef,
    selectedReceivables,
    setSelectedReceivables,
  ]);

  return {
    reprocessReceivables,
    grid,
    gridRef,
    loading,
    reducedReceivables,
    selectedReceivables,
    totalSum,
    totalDesembolso
  };
};
