import { validators } from "@maestro/utils";
import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";
import { AttachUserForm } from "./attach-user.types";

export const attachUserFields = {
  taxId: {
    id: "taxId",
    name: "taxId",
    tag: "cpf",
    dataAction: "formulario:texto:cpf",
    dataLabel: "cpf",
    label: "CPF",
    placeholder: "Preencher",
    required: true,
  },
  name: {
    id: "name",
    name: "name",
    tag: "text",
    dataAction: "formulario:texto:nome",
    dataLabel: "nome",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
  },
  email: {
    id: "email",
    name: "email",
    tag: "text",
    dataAction: "formulario:texto:email",
    dataLabel: "email",
    label: "E-mail",
    placeholder: "Preencher",
    required: true,
  },
  phone: {
    id: "phone",
    name: "phone",
    tag: "phone",
    dataAction: "formulario:texto:celular",
    dataLabel: "celular",
    label: "Celular",
    placeholder: "Preencher",
    required: true,
  },
  sendWelcomeEmail: {
    id: "sendWelcomeEmail",
    name: "sendWelcomeEmail",
    dataAction: "formulario:checkbox:enviar-boas-vindas",
    dataLabel: "enviar-boas-vindas",
  },
} as const satisfies MaestroFields<AttachUserForm>;

export const attachUserFormValidationSchema = yup.object<
  AttachUserForm,
  YupObjectSchema<AttachUserForm>
>({
  taxId: yup
    .string()
    .required("Campo obrigatório")
    .test("taxId", "Documento inválido", validators.cpfCnpj),
  name: yup.string().required("Campo obrigatório"),
  email: yup
    .string()
    .required("Campo obrigatório")
    .test("taxId", "E-mail inválido", validators.email),
  phone: yup
    .string()
    .required("Campo obrigatório")
    .test("taxId", "Celular inválido", validators.phone),
});

export const checkboxValue = "send-welcome-email";

export const attachUserFormDefaultValues = {
  taxId: null,
  name: null,
  email: null,
  phone: null,
  sendWelcomeEmail: [],
} satisfies Partial<Nullable<AttachUserForm>> as unknown as AttachUserForm;
