import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ICreateLimitModalForm } from "./create-dependent-limite.types";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { modalManager, OToastManager } from "@maestro/core";
import { buildAddLimiteModalId } from "./create-dependent-limite-modal.component";
import { ICreateLimitBagParams } from "services/limit-control/models/request";
import { ILimitBag } from "services/limit-control/models/responses";
import { getErrorCode } from "../../controlador-limite-cedente.utils";
import { useLimiteCedenteContext } from "../../controlador-limite-cedente.context";

export const useCreateLimitModal = (
  parentLimitBag: ILimitBag,
) => {
  const form = useForm<ICreateLimitModalForm>({
    defaultValues: { sacadoCnpj: "" },
    resolver: yupResolver(
      yup.object({
        sacadoCnpj: yup
          .string()
          .required("É obrigatório selecionar uma credenciadora"),
        totalAmount: yup
          .number()
          .positive("É obrigatório definir um limite")
          .max(
            parseFloat(parentLimitBag.totalAmount),
            "O valor do limite não pode ser maior que o limite raiz",
          ),
      }),
    ),
  });

  const { handleSubmit, setValue, trigger } = form;

  const {
    callService: createDependentLimit,
    hasError: submitHasError,
    loading: submitLoading,
    value: submitResponse,
    error: errorResponse,
  } = useServiceCall(service.quickfin.createDependentLimit);

  const {
    sacadosResponse,
    sacadosHasError,
    sacadosLoading,
    dataSource
  } = useLimiteCedenteContext();

  const sacadosOptions = useMemo<SelectSearchOption<string>[]>(
    () => sacadosResponse.map((sacado, _index) => ({
      label: sacado.label,
      value: sacado.value,
      onSelect: () => {
        if (sacado.value) {
          setValue("sacadoCnpj", sacado.value);
          setValue("sacadoName", sacado.label);
        }
      },
      customKey: sacado.customKey,
    })) ?? []
  ,[sacadosResponse, setValue, trigger])

  const submit = useMemo(
    () =>
      handleSubmit(({ sacadoCnpj, totalAmount, sacadoName }) => {
        const params: ICreateLimitBagParams = {
          limitBagId: `QuickFin:${parentLimitBag.id}:${sacadoCnpj}`,
          totalAmount: totalAmount,
          limitBagName: sacadoName,
        };

        createDependentLimit(parentLimitBag.id, params);
      }),
    [createDependentLimit, handleSubmit, parentLimitBag],
  );

  /** Error warning */
  useEffect(() => {
    if (submitHasError) {
      const errorCode = getErrorCode(errorResponse);
      if (errorCode) {
        switch (errorCode) {
          case "LCORE0005":
            OToastManager.danger("Essa credenciadora já foi cadastrada.");
            break;
          default:
            OToastManager.danger("Erro ao criar limite");
        }
      } else {
        OToastManager.danger("Erro ao criar limite");
      }
    }
  }, [submitHasError]);

  /** Error warning */
  useEffect(() => {
    if (sacadosHasError) OToastManager.danger("Erro ao buscar os sacados");
  }, [sacadosHasError]);

  /** Success */
  useEffect(() => {
    if (submitResponse) {
      OToastManager.success("Limite criado com sucesso");
      dataSource.reload();
      modalManager.hide(buildAddLimiteModalId(parentLimitBag.id));
    }
  }, [submitResponse]);

  return {
    sacadosLoading,
    sacadosOptions,
    sacadosHasError,
    form,
    submitHasError,
    submitLoading,
    submitResponse,
    submit,
  };
};
