import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { EnergyAdequatorFieldsForm } from "../../../../components/deprecated/adequator/energy-adequator.component";
import { useEnergyCreateAdequatorPage } from "./energy-create-adequator.hook";

export const EnergyCreateAdequatorPage = () => {
  const { form, loading, submit } = useEnergyCreateAdequatorPage();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Adequadora" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <EnergyAdequatorFieldsForm />
      </FormProvider>
    </FormTemplate>
  );
};
