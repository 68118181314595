import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAllowOperationGetSignatureCombinationsResponse } from "services/gatekeeper/models";

export type Signatory = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        PostAllowOperationGetSignatureCombinationsResponse["availableCombinations"]
      >[number]
    >["groups"]
  >[number]["signatories"]
>[number];

export const grid: ODataGridGeneratorConfig<Signatory> = {
  datagrid: {
    noDataText: "Nenhum aprovador",
    pager: {
      infoText: "Página {0} de {1} ({2} aprovadores)",
    },
  },
  columns: [
    { dataField: "name", caption: "Nome" },
    {
      dataField: "taxIdentification",
      caption: "CPF",
      format: (value) => masks.cpf(value),
    },
    { dataField: "email", caption: "E-mail" },
    { dataField: "isUser", caption: "É usuário?" },
  ],
};
