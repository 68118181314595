import { ODropdownItem } from "@maestro/react";
import styled from "styled-components";

interface TextColor {
  lighterText: boolean;
}

export const StyledDropdownItem = styled(ODropdownItem)`
  border-bottom: none !important;
  padding: 0 !important;
`;

export const NameSpan = styled.span<TextColor>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ lighterText }) => {
    return lighterText
      ? "color: var(--theme-dark-60);"
      : "color: var(--theme-dark);";
  }}
  font-size: 0.875rem;
`;

export const IdentificationSpan = styled.span<TextColor>`
  ${({ lighterText }) => {
    return lighterText
      ? "color: var(--theme-dark-60);"
      : "color: var(--theme-dark);";
  }}
  font-size: 0.75rem;
`;

export const UnstyledAnchor = styled.a`
  all: unset;
  cursor: pointer;
  padding: var(--font-xs);
`;
