import { Node, Edge, Position } from "reactflow";
import dagre from "dagre";

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;
  const nodeHeight = 90;

  dagreGraph.setGraph({ rankdir: "TD" });

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight,
    });
  });

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const updatedNodes = nodes.map((node: Node) => {
    const updatedNode = { ...node };
    const nodeWithPosition = dagreGraph.node(node.id);
    updatedNode.targetPosition = Position.Top;
    updatedNode.sourcePosition = Position.Bottom;

    updatedNode.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return updatedNode as Node;
  });

  return { nodes: updatedNodes, edges };
};
