import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ConfigurationsForm } from "../configuration-form";
import { DesksFields } from "../desks-fields";
import { MultiSelectFields } from "../multi-select-fields";
import { useDomainScfForm } from "./domain-scf-form.hook";
import { PartnerFields } from "../partner-fields";

interface DomainScfFormProps {
  isEditing?: boolean;
  loading?: boolean;
}

export const DomainScfForm = ({
  isEditing = false,
  loading = false,
}: DomainScfFormProps) => {
  const { form, loadingSubmit, submit } = useDomainScfForm(isEditing);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          dataAction="partner_configuration_scf:botao:enviar"
          dataLabel="enviar"
          onClick={submit}
          loading={loadingSubmit || loading}
        >
          {isEditing ? "Atualizar" : "Enviar"}
        </LoadingButton>
      }
    >
      <OCard>
        <OCardBody>
          <FormProvider {...form}>
            {loading && <OLoader absolute backdrop />}
            <ORow>
              <OCol xs={4}>
                <ORFieldInput
                  dataAction="partner_configuration_scf:texto:configuration_name"
                  dataLabel="configuration_name"
                  tag="text"
                  id="configurationName"
                  name="configurationName"
                  label="Nome configuração"
                />
              </OCol>
              <OCol xs={4}>
                <ORFieldInput
                  dataAction="partner_configuration_scf:texto:configuration_display_name"
                  dataLabel="configuration_display_name"
                  tag="text"
                  id="configurationDisplayName"
                  name="configurationDisplayName"
                  label="Nome visual da configuração"
                />
              </OCol>
              <OCol xs={4}>
                <ORFieldInput
                  dataAction="partner_configuration_scf:texto:configuration_id"
                  dataLabel="configuration_id"
                  tag="text"
                  id="configurationId"
                  name="configurationId"
                  label="Configuration Id"
                  disabled
                />
              </OCol>
              <PartnerFields isEditing={isEditing} />
              <DesksFields />
              <OCol xs={4} align="center">
                <ORFieldCheckboxGroup
                  dataAction="partner_configuration_scf:checkbox:is_fidc"
                  dataLabel="is_fidc"
                  id="isFidc"
                  name="isFidc"
                >
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <OCheckbox size="xs" id="isFidc-checkbox" value="true" />
                    <OLabel htmlFor="isFidc-checkbox">É de FIDC?</OLabel>
                  </div>
                </ORFieldCheckboxGroup>
              </OCol>
              <MultiSelectFields />
              <OCol xs={12} className="mb-3">
                <OTypography size="xl">
                  Configurações da Esteira de Onboarding
                </OTypography>
              </OCol>
              <OCol xs={12}>
                <ConfigurationsForm
                  fieldName="configurations"
                  title="Configurações"
                />
              </OCol>
              <OCol xs={12}>
                <ConfigurationsForm
                  fieldName="syncConfigurations"
                  title="Configurações sync"
                />
              </OCol>
            </ORow>
          </FormProvider>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
