import { datadogRum } from "@datadog/browser-rum";
import { authManager, RemoveToken } from "contexts/auth";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { LogoutUserCommand, userManager } from "contexts/user";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth } from "react-oidc-context";

interface IdleGuardProps {
  children?: React.ReactNode;
}

export const IdleGuard: React.FC<IdleGuardProps> = ({ children }) => {
  const { behavior } = useBehavior();
  const auth = useAuth();

  const onIdle = async () => {
    if (behavior.id === Behaviors.Corban) {
      await authManager.execute(new RemoveToken());
      await userManager.execute(new LogoutUserCommand());
    }

    if (auth.isAuthenticated) {
      await auth.removeUser();
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = Math.ceil(getRemainingTime() / 1000);
      // We want to track the remaining time to be idle only when it's less than 5 minutes
      if (remainingTime < 300) {
        datadogRum.addAction("remainingTimeToBeIdle", {
          remainingTime,
        });
      }
    }, 60_000);

    return () => {
      clearInterval(interval);
    };
  });

  return children as JSX.Element;
};
