import { logger } from "utils/logger";

interface ParsedMessage<T> {
  type: string;
  payload: T;
}

/** Send a message to embedded portal in iframe */
export const sendMessage = (
  iframe: HTMLIFrameElement,
  payload: any,
  type = "ADMIN",
  origin = "*",
) => {
  const message = {
    type,
    payload,
  };
  iframe.contentWindow?.postMessage(message, origin);
};

/** Receive a message from embedded portal in iframe */
export const receiveMessage = <T>(
  type: string,
  callback: (payload: T) => void,
) => {
  const messageHandler = (msg: MessageEvent) => {
    try {
      if (!msg.data) return;
      const content = msg.data as ParsedMessage<T>;

      if (content.type !== type) return;
      callback(content.payload);
    } catch (error) {
      logger.error("[useEmbedded]Failed to handle message: ", msg);
    }
  };
  window.addEventListener("message", messageHandler);

  return messageHandler;
};
