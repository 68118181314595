export const amortizationFrequencyCodeMap = {
  MNTH: "Mensal",
  MTRY: "Bullet",
};

export const spreadDayCountCodeMap = {
  B024: "30",
  B013: "30/360",
  B001: "365",
  B003: "252",
};
