import {
  ODropdownItem,
  ODropdownList,
  OInputText,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { FullWidthDropdown } from "./select-search.styles";
import { SelectSearchV2Props } from "./select-search.types";

export const SelectSearchV2 = ({
  id = "",
  name = "",
  options,
  onInput,
  inputRef,
  placeholder,
  disabled,
  slotBefore,
  slotAfter,
  inputClassName,
  dropdownPosition = "bottom",
}: SelectSearchV2Props) => {
  const optionList = useMemo(
    () => (
      <ODropdownList
        style={{ maxHeight: "264px", overflow: "auto" }}
        minWidth="unset"
      >
        {options?.map((opt) => (
          <ODropdownItem
            key={opt.key}
            onClick={opt.onClick}
            className={opt.selected ? "selected" : undefined}
          >
            {typeof opt.content === "string" ? (
              <OTypography>{opt.content}</OTypography>
            ) : (
              opt.content
            )}
          </ODropdownItem>
        ))}
      </ODropdownList>
    ),
    [options],
  );

  return (
    <FullWidthDropdown positionY={dropdownPosition}>
      <OInputText
        id={id}
        name={name}
        onInput={(evt) => onInput?.(evt.currentTarget.value)}
        ref={inputRef}
        placeholder={placeholder}
        autocomplete="off"
        className={inputClassName}
        disabled={disabled}
      >
        {slotBefore && <div slot="before">{slotBefore}</div>}
        {slotAfter && <div slot="after">{slotAfter}</div>}
      </OInputText>
      {optionList}
    </FullWidthDropdown>
  );
};
