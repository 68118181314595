import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { AccountType } from "services/bankinghub/models";
import { FinancialInstitutionSelect } from "components/financial-institution-select";
import { MapperAccountType, UpdateContactType } from "./update-contact.types";

export const UpdateContact = ({ form }: UpdateContactType) => {
  return (
    <FormProvider {...form}>
      <form>
        <ORow>
          <OCol>
            <FinancialInstitutionSelect
              required
              fieldName="ispb"
              placeholder="Selecionar"
              handleSelect={({ bankCode }) => form.setValue("bank", bankCode)}
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              id="branch"
              name="branch"
              dataAction="formulario-conta-banking:input:agencia"
              dataLabel="agencia"
              label="Agência"
              placeholder="Informar"
              maxlength={4}
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol>
            <ORFieldInput
              tag="text"
              id="account"
              name="account"
              dataAction="formulario-conta-banking:input:conta"
              dataLabel="conta"
              label="Conta"
              placeholder="Informar"
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              id="account_digit"
              name="account_digit"
              dataAction="formulario-conta-banking:input:digito"
              dataLabel="digito"
              label="Dígito"
              placeholder="Informar"
              maxlength={1}
            />
          </OCol>
        </ORow>
        <ORow>
          <OCol>
            <ORFieldSelect
              id="account_type"
              name="account_type"
              dataAction="formulario-conta-banking:select:tipo-conta"
              dataLabel="tipo-conta"
              placeholder="Selecionar"
              label="Tipo da conta"
            >
              {Object.values(AccountType).map((accountType) => (
                <OOption value={accountType} key={accountType}>
                  {MapperAccountType[accountType]}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol>
            <ORFieldSelect
              id="is_disbursement_account"
              name="is_disbursement_account"
              dataAction="formulario-conta-banking:select:conta-principal"
              dataLabel="conta-principal"
              placeholder="Selecionar"
              label="Conta principal"
            >
              <OOption value="S">Sim</OOption>
              <OOption value="N">Não</OOption>
            </ORFieldSelect>
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
