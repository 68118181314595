import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { AdminPayeesODataValueItem } from "services/quickfin/models";
import { service } from "services/service";

export const useAssignorsMasterDetails = ({
  OnboardingType,
  ProductType,
  PayerId,
  Id,
}: AdminPayeesODataValueItem) => {
  const {
    callService: getCompaniesDetails,
    loading,
    hasError,
    value: details,
  } = useServiceCall(service.quickfin.getCompaniesDetails);

  useEffect(() => {
    getCompaniesDetails({
      TipoOnboarding: OnboardingType,
      ProductType,
      SacadoId: PayerId.toString(),
      CedenteId: Id.toString(),
    });
  }, [Id, OnboardingType, PayerId, ProductType, getCompaniesDetails]);

  const value = useMemo(
    () =>
      details
        ? {
            cedenteIdentification: details.cedenteIdentification,
            TipoOnboarding: OnboardingType,
            statusOnboarding: details.statusOnboarding,
            email: details.email,
          }
        : undefined,
    [OnboardingType, details],
  );

  return {
    hasError,
    loading,
    value,
  };
};
