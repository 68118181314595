import {
  PostCardsConfirmOfferBody,
  PostCardsRequestCardBody,
} from "services/bankinghub/models";
import { CardModality, CardType } from "services/bankinghub/models/types/cards";
import { unmaskPhone } from "../../card-hiring.utils";
import { CardHiringForm } from "./card-hiring-form/card-hiring-form.types";

export const assemblePostCardsRequestCardBody = (form: CardHiringForm) => {
  if (!form.accountId) throw new Error("Empty accountId value");

  const isCredit = form.modality === CardModality.Credit;

  const data: PostCardsRequestCardBody = {
    ownerId: form.ownerId,
    ownerDocument: form.ownerDocument,
    programPlanId: form.programId,
    printedName: form.printedName,
    accountId: form.accountId,
    type: CardType.Physical,
    grantedLimit: 0,
    addressId: form.addressId,
    modality: form.modality,
    ...(!isCredit && {
      branch: form.branch,
      accountNumber: form.accountNumber,
    }),
    ...(form.pinType === "personal" && { pin: form.pin }),
  };

  return data;
};

export const assemblePostCardsConfirmOfferBody = (form: CardHiringForm) => {
  if (!form.offerId) throw new Error("Empty offerId value");

  const isCredit = form.modality === CardModality.Credit;

  const isDebit = form.modality === CardModality.Debit;

  if (!form.issueDateId || !form.issueDateDay) {
    throw new Error("Empty issueDateId or issueDateDay value");
  }

  const data: PostCardsConfirmOfferBody = {
    ownerId: form.ownerId,
    offerId: form.offerId,
    ownerDocument: form.ownerDocument,
    addressId: form.addressId,
    programId: form.programId,
    printedName: form.printedName,
    phone: unmaskPhone(form.phone),
    modality: form.modality,
    ...(isCredit && { hiredAmount: form.hiredAmount }),
    ...(!isDebit && {
      issueDate: { id: form.issueDateId, day: form.issueDateDay },
    }),
    ...(!isCredit && {
      branch: form.branch,
      accountNumber: form.accountNumber,
    }),
    ...(form.pinType === "personal" && { pin: form.pin }),
  };
  return data;
};
