import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./renegociacao-historico.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.renegotiation
    .getAgreements()
    .then(({ data }) => data.items)
    .catch(() => {
      const errorMessage = "Erro ao buscar histórico";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const RenegociacoesHistory = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Histórico" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
