import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PutAdminLegalEntityDenyBody } from "services/hubcreditmanager/models/requests";
import { WorkflowProdutoDenyListById } from "../../../../routes/workflow.route-params";
import {
  modalFormDefaultValues,
  modalFormValidationSchema,
} from "./remove-deny-form.schema";

export const useRemoveDenyHook = () => {
  const { denyListId } = useParams<WorkflowProdutoDenyListById>();

  if (!denyListId) throw new Error("No id");

  const navigate = useNavigate();

  const location = useLocation();
  const { taxId, name } = location.state || {};

  const form = useForm({
    resolver: yupResolver(modalFormValidationSchema),
    defaultValues: modalFormDefaultValues,
  });

  const { getValues, setValue, trigger } = form;

  useEffect(() => {
    if (!taxId || !name) return;
    setValue("taxId", taxId);
    setValue("name", name);
  }, [name, setValue, taxId]);

  const { callService: removeLegalEntityFromDenyList, loading } =
    useServiceCall(service.hubCreditManager.removeLegalEntityFromDenyList);

  const removeDeny = useCallback(async () => {
    const isValid = await trigger();

    if (!isValid) throw new Error("Error on form validation");

    const { unblockReason } = getValues();

    const body: PutAdminLegalEntityDenyBody = {
      unblockReason,
      denyListId,
    };

    const { success } = await removeLegalEntityFromDenyList(body);

    if (success) {
      navigate(corporateRouter.routes.workflow.product.denyList.path);

      OToastManager.success("Cliente removido com sucesso.");
    } else OToastManager.danger("Não foi possível remover o cliente.");
  }, [trigger, getValues, denyListId, removeLegalEntityFromDenyList, navigate]);

  return { form, loading, removeDeny };
};
