import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTooltip } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";
import {
  EscalateConfirmationModal,
  escalateConfirmationModalId,
} from "./escalate-confirmation-modal.component";

interface EscalateButtonProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

const allowedPendencyStatuses: HubEnergy.EDiligenceStepPendencyStatus[] = [
  "PENDENTE_BPO",
  "PENDENTE_OPS",
];

export const EscalateButton = ({ pendency }: EscalateButtonProps) => {
  const { stepAnalysis } = useStepAnalysisContext();

  if (
    !allowedPendencyStatuses.includes(pendency.status) ||
    stepAnalysis.level === "OPS"
  )
    return null;

  return (
    <>
      <OTooltip floating position="top-left">
        <TooltipContent>
          {pendency.status !== "PENDENTE_OPS" ? "Escalar" : "Escalada"}
        </TooltipContent>
        <OButton
          type={pendency.status === "PENDENTE_OPS" ? "danger" : "dark"}
          outline={pendency.status !== "PENDENTE_OPS"}
          style={{
            pointerEvents:
              pendency.status === "PENDENTE_OPS" ? "none" : "unset",
          }}
          onClick={() =>
            modalManager.show(escalateConfirmationModalId(pendency.id))
          }
        >
          <OIcon category="fal" icon="fa-engine-warning" />
        </OButton>
      </OTooltip>
      <EscalateConfirmationModal pendency={pendency} />
    </>
  );
};
