import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useSacadoSelection = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sacados, setSacados] = useState<
    Awaited<ReturnType<typeof service.quickfin.getSacados>>["data"]
  >([]);

  const getSacados = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.quickfin.getSacados({
        TipoProduto: "RiscoSacado",
      });

      setSacados(data);
      setHasError(false);
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSacados();
  }, [getSacados]);

  return {
    loading,
    hasError,
    sacados,
    getSacados,
  };
};
