import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { FundSelect } from "../../../components";
import { NotificationsTable, SignersTable } from "./_compose";
import { fidcBatchesGrid } from "./fidc-batches.grid";
import { useFidcBatches } from "./fidc-batches.hook";

export const FidcBatches = () => {
  const { dataSource, form, funds, isDonus, selectedFundWatch } =
    useFidcBatches();

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle title="Lotes" />
        <FundSelect />
        {selectedFundWatch && (
          <>
            <GridTemplate
              pageTitle={
                <PageTitle
                  title={`Lotes do fundo ${
                    funds?.find((fund) => fund.taxId === selectedFundWatch)
                      ?.name
                  }`}
                />
              }
            >
              <ODataGridGenerator
                grid={fidcBatchesGrid}
                dataSource={dataSource}
              />
            </GridTemplate>
            {isDonus && (
              <>
                <SignersTable />
                <NotificationsTable />
              </>
            )}
          </>
        )}
      </div>
    </FormProvider>
  );
};
