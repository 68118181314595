import { OToastManager } from "@maestro/core";
import { customer } from "contexts/customer";
import { customerQueryParam } from "../../../access-control";

export const copyShareLink = () => {
  const baseUrl = window.location.href.replace(window.location.search, "");

  const searchParams = new URLSearchParams(window.location.search);
  if (customer.value?.identification)
    searchParams.set(customerQueryParam, customer.value.identification);

  const link = baseUrl + "?" + searchParams.toString();

  navigator.clipboard.writeText(link);
  OToastManager.info("Link copiado");
};
