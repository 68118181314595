import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { GetAdminIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { service } from "services/service";

export const dataSource = dataSourceCustomStoreGenerator<
  GetAdminIncorrectOrdersResponse[number]
>(() => {
  return service.hubFx
    .getIncorrectOrders()
    .then(({ data }) => data)
    .catch(() => {
      const errorMessage = "Erro ao buscar ordens incorretas";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    });
});

export const incorrectOrdersGrid = (
  setSelectedOrders: (orders: GetAdminIncorrectOrdersResponse) => void,
): ODataGridGeneratorConfig<GetAdminIncorrectOrdersResponse[number]> => ({
  datagrid: {
    noDataText: "Nenhuma ordem incorreta.",
    filterRow: {
      visible: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    onSelectionChanged: (evt) => setSelectedOrders(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "conciliationId",
      caption: "Conciliation Id",
      cellRender: ({ data }) => (
        <>
          {data.conciliationId}{" "}
          <CopyButton
            action="detalhes_do_cliente:botao:copiar-conciliation_id"
            label="copiar-conciliation_id"
            target={data.conciliationId ?? ""}
            toasterMessage="Código de conciliação copiado."
          />
        </>
      ),
    },
    {
      dataField: "counterparty.name",
      caption: "Nome contraparte",
    },
    {
      dataField: "counterparty.identification",
      caption: "Id da contraparte",
      cellRender: ({ data }) => masks.cnpj(data.counterparty?.identification),
    },
    {
      dataField: "errorMessage",
      caption: "Erro",
    },
    {
      dataField: "isActive",
      caption: "Ordem Corrigida?",
      calculateCellValue: ({ isActive }) => (isActive ? "Não" : "Sim"),
    },
    {
      dataField: "currency",
      caption: "Moeda",
    },
    {
      dataField: "countryCode",
      caption: "Código do país",
    },
    {
      dataField: "amount",
      caption: "Quantia",
      cellRender: ({ data }) => masks.currency(data.amount),
    },
    {
      dataField: "payer",
      caption: "Pagador",
    },
    {
      dataField: "createdDate",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      dataField: "updatedDate",
      caption: "Atualizado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
