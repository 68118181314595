import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { mapCurrencySymbol } from "utils/currency";
import { ParsedTooltip } from "../../parsed-tooltip/parsed-tooltip.component";
import { StyledValue } from "../roadwalker-master-detail.style";
import { PaymentMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerPaymentDetails = (data: PaymentMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    payment: {
      items: [
        {
          label: "TradeId",
          value: <StyledValue>{data.TradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.ExternalId}</span>
                {data.ExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Type",
          value: <StyledValue>{data.Type}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreditDebitIndicator",
          value: <StyledValue>{data.CreditDebitIndicator}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SettlementDate",
          value: (
            <StyledValue>
              {dayjs(data.SettlementDate).format("DD-MM-YYYY")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProcessingDateTime",
          value: (
            <StyledValue>
              {dayjs(data.ProcessingDateTime).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InstallmentCode",
          value: <StyledValue>{data.InstallmentCode}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "NettedPaymentIdentification",
          value: <StyledValue>{data.NettedPaymentIdentification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ProductCode",
          value: <StyledValue>{data.ProductCode}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Currency",
          value: <StyledValue>{mapCurrencySymbol(data.Currency)}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InstructedAmounts",
          value: (
            <StyledValue>
              <ParsedTooltip value={data.InstructedAmounts} />
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InvoiceIdentification",
          value: (
            <OTooltip floating position="top">
              <span slot="tooltip-content">{data.InvoiceIdentification}</span>
              {data.InvoiceIdentification}
            </OTooltip>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreationDate",
          value: (
            <StyledValue>
              {dayjs(data.CreationDate).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ObligationFulfillmentStatus",
          value: <StyledValue>{data.ObligationFulfillmentStatus}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PartyCGE",
          value: <StyledValue>{data.PartyCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PartyAccountIdentification",
          value: <StyledValue>{data.PartyAccountIdentification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CounterPartyCGE",
          value: <StyledValue>{data.CounterPartyCGE}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CounterPartyAccountIdentification",
          value: (
            <StyledValue>{data.CounterPartyAccountIdentification}</StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PaymentDescription",
          value: <StyledValue>{data.PaymentDescription}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SourceSystem",
          value: <StyledValue>{data.SourceSystem}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PrincipalAmount",
          value: (
            <StyledValue>
              {masks.currency(
                data.PrincipalAmount,
                mapCurrencySymbol(data.Currency),
                ".",
              )}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Fcc",
          value: <StyledValue>{data.Fcc}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradePayerCge",
          value: <StyledValue>{data.TradePayerCge}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "TradePayeeCge",
          value: <StyledValue>{data.TradePayeeCge}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ContractIdentification",
          value: <StyledValue>{data.ContractIdentification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "RequestedCollectionDate",
          value: (
            <StyledValue>
              {data.RequestedCollectionDate
                ? dayjs(data.RequestedCollectionDate).format(
                    "DD-MM-YYYY HH:mm:ss",
                  )
                : ""}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
