import { OToastManager, modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { CONFIGURABLE_COLUMN_MODAL_ID } from "./modal/modal.component";
import { ConfigurableColumn } from "../batch.types";

type BatchConfigurableColumns = Awaited<
  ReturnType<typeof service.hubCreditManager.getBatchConfigurableColumns>
>["data"][number];

export const configurableColumnsGrid = (
  setSelectedColumn: React.Dispatch<
    React.SetStateAction<ConfigurableColumn | undefined>
  >,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma coluna encontrada",
      dataSource: dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager.getBatchConfigurableColumns().catch(() => {
          throw new Error("Não foi possível buscar a lista de Colunas.");
        }),
      ),
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar",
                onClick: () => {
                  setSelectedColumn(data);
                  modalManager.show(CONFIGURABLE_COLUMN_MODAL_ID);
                },
              },
              {
                icon: {
                  category: "fal",
                  icon: "fa-trash",
                },
                label: "Remover",
                onClick: async () => {
                  try {
                    await service.hubCreditManager.removeConfigurableBatchColumns(
                      data.id,
                    );
                    component.getDataSource().reload();
                    OToastManager.success("Configuração removida com sucesso");
                  } catch {
                    OToastManager.danger("Erro ao remover configuração");
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<BatchConfigurableColumns>);
