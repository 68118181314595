import { OLabel } from "@maestro/react";
import { DocumentField } from "./_compose";
import { useDocumentsManager } from "./documents-manager.hook";

interface DocumentsManagerProps {
  requiredDocuments: ScfDevelopers.PayerDocumentType[];
}

export const DocumentsManager = ({
  requiredDocuments,
}: DocumentsManagerProps) => {
  useDocumentsManager(requiredDocuments);

  if (requiredDocuments.length === 0) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-3 mt-4">
      <OLabel htmlFor="payer-documents">Documentos necessários</OLabel>
      <div id="payer-documents" className="d-flex flex-column gap-4">
        {requiredDocuments.map((document, index) => (
          <DocumentField
            key={document}
            documentKey={`document_${index}_${document}`}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
