import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import { AccountMasterDetails } from "services/onboarding-core-account/models/responses/post-admin-accountopening-get-master-detailed-wokflow.response";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./account-workflow-card.grid";

interface AccountProps {
  accounts: AccountMasterDetails[];
  refetch: () => Promise<unknown>;
}

export const AccountsCard = ({ accounts, refetch }: AccountProps) => {
  const gridComponent = useMemo(() => {
    return grid(refetch);
  }, [refetch]);

  return (
    <GridTemplate
      pageTitle={
        <OTypography size="lg" type="default" className="mb-3">
          Contas
        </OTypography>
      }
    >
      <ODataGridGenerator grid={gridComponent} dataSource={accounts} />
    </GridTemplate>
  );
};
