import * as yup from "yup";
import { ExtraDataFormFields } from "./extra-data.types";

const phoneNumRegex = /^[0-9\- ]{8,14}$/;

const AddressValidationSchema: yup.ObjectSchema<ScfDevelopers.Address> =
  yup.object({
    cityName: yup.string().optional(),
    complement: yup.string().optional(),
    countryCode: yup.string().optional(),
    number: yup.string().optional(),
    stateCode: yup.string().optional(),
    street: yup.string().optional(),
    neighborhood: yup.string().optional(),
    zipCode: yup.string().optional(),
  });

export const extraDataValidationSchema: (
  mandatoryFields: string[],
) => yup.ObjectSchema<ExtraDataFormFields> = (mandatoryFields) =>
  yup.object({
    addresses: yup
      .array(AddressValidationSchema)
      .default([])
      .test("addresses", "Campo obrigatório", (value) =>
        mandatoryFields.includes("ADDRESSES") ? !!value : true,
      ),
    birthDate: yup
      .string()
      .test("birthDate", "Campo obrigatório", (value) =>
        mandatoryFields.includes("BIRTHDATE") ? !!value : true,
      ),
    emailAddress: yup
      .string()
      .email("e-mail deve ser válido")
      .test("emailAddress", "Campo obrigatório", (value) =>
        mandatoryFields.includes("EMAILADDRESS") ? !!value : true,
      ),
    name: yup
      .string()
      .test("name", "Campo obrigatório", (value) =>
        mandatoryFields.includes("NAME") ? !!value : true,
      ),
    phoneNumber: yup
      .string()
      .matches(phoneNumRegex, {
        message: "telefone inválido",
        excludeEmptyString: true,
      })
      .test("phoneNumber", "Campo obrigatório", (value) =>
        mandatoryFields.includes("PHONENUMBER") ? !!value : true,
      ),
    documents: yup
      .array(
        yup
          .mixed<ScfDevelopers.PayerDocument>()
          .required()
          .test(
            "one-file-minimum",
            "Deve conter ao menos um arquivo",
            (value) => value.files.length > 0,
          )
          .test(
            "complete-file-only",
            "Não devem haver outros tipos de arquivo misturados com um de tipo completo",
            (value) => {
              const completoFiles = value.files.filter(
                (file) => file.pageType === "COMPLETO",
              );
              return !(
                completoFiles.length > 0 &&
                completoFiles.length !== value.files.length
              );
            },
          )
          .test(
            "multiple-complete-files",
            "Deve haver apenas um arquivo do tipo completo",
            (value) => {
              const completoFiles = value.files.filter(
                (file) => file.pageType === "COMPLETO",
              );
              return !(completoFiles.length > 1);
            },
          )
          .test(
            "multi-page-only",
            "Não devem haver outros tipos de arquivo misturados com um de tipo multi página",
            (value) => {
              const multiPaginasFiles = value.files.filter(
                (file) => file.pageType === "MULTI_PAGINAS",
              );
              return !(
                multiPaginasFiles.length > 0 &&
                multiPaginasFiles.length !== value.files.length
              );
            },
          )
          .test(
            "single-multi-pages-file",
            "Deve haver mais de um arquivo do tipo multi página",
            (value) => {
              const multiPaginasFiles = value.files.filter(
                (file) => file.pageType === "MULTI_PAGINAS",
              );
              return !(multiPaginasFiles.length === 1);
            },
          )
          .test("missing-front-file", "Falta frente do documento", (value) => {
            const frenteFiles = value.files.filter(
              (file) => file.pageType === "FRENTE",
            );
            const versoFiles = value.files.filter(
              (file) => file.pageType === "VERSO",
            );
            return !(frenteFiles.length === 0 && versoFiles.length > 0);
          })
          .test("missing-back-file", "Falta verso do documento", (value) => {
            const frenteFiles = value.files.filter(
              (file) => file.pageType === "FRENTE",
            );
            const versoFiles = value.files.filter(
              (file) => file.pageType === "VERSO",
            );
            return !(frenteFiles.length > 0 && versoFiles.length === 0);
          }),
      )
      .default([]),
  });

export const extraDataDefaultValue: ExtraDataFormFields = {
  birthDate: undefined,
  addresses: [],
  name: "",
  emailAddress: "",
  phoneNumber: "",
  documents: [],
};
