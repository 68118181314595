export type IntubateType = "addAllow" | "addBlock";
export interface IntubateValues {
  identification: string;
  from: string;
  until: string;
  type: string;
}

export const BlockListTypesMap = {
  VIA_VAREJO_STORE: "Loja Via Varejo",
};

export const AllowListTypesMap = {
  VIA_VAREJO_CORBAN: "Corban Via Varejo",
};
