import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./_compose/details-add-form/details-add-form.form";
import { FormValuesType } from "./_compose/details-add-form/details-add-form.types";

export const useGatekeeperProfilesDetailsAdd = (uuid?: string) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        isAvailable: values.isAvailable,
        uuid: uuid!,
        groupId: values.groupId,
        description: values.description,
      };

      await service.gatekeeper.createProfileDetails(payload);

      OToastManager.success("Detalhe criado com sucesso");

      if (!uuid) throw new Error("No uuid");

      navigate(
        routes.ferramentas.product.gatekeeper.profiles.profile.details.path({
          uuid: uuid,
        }),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
