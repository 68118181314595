import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GetPmeAcountsParams } from "services/clerk";
import {
  GetAdminSettlementStrategiesBySettlementIdResponse,
  SacadosCompaniesViewValueItem,
  ValueItem,
} from "services/quickfin/models";
import { service } from "services/service";
import { useBankAccountCompanySearch } from "./busca-empresa-conta-bancaria/bank-account-company-search.hook";

export const useBankAccountField = (
  initialValue?: GetAdminSettlementStrategiesBySettlementIdResponse["bankAccount"],
) => {
  const { enableBankAccountForm, value } = useBankAccountCompanySearch();
  const form = useFormContext();
  const { getValues, setValue, watch } = form;
  const tipoProdutoWatch = watch("tipoProduto");
  const companyIdWatch = watch("bankAccount.companyId");

  const isSettlementAccountFromCedente = useMemo(() => {
    return ["Cartao", "CCC", "RiscoCedente"].includes(
      tipoProdutoWatch ?? "Cartao",
    );
  }, [tipoProdutoWatch]);

  const findBankAccountOnClerk = useCallback(
    async (companyCnpj: string | null) => {
      if (!companyCnpj) {
        return;
      }

      if (tipoProdutoWatch === "CCC") {
        return;
      }

      if (companyCnpj === "30306294000145") {
        setValue("bankAccount.bankCode", "208");
        setValue("bankAccount.branch", "1");
        setValue("bankAccount.accountNumber", "817670");

        OToastManager.info("Usando a conta reserva do BTG.");

        return;
      }

      try {
        const params: GetPmeAcountsParams = {
          identification: companyCnpj,
        };
        const { data } = await service.clerk.getPmeAccount(params);

        const account = data.find(
          (acc) =>
            acc.branch === "1" && acc.type === "ESCROW" && acc.status === "AT",
        );

        if (account) {
          setValue("bankAccount.bankCode", "208");
          setValue("bankAccount.branch", "1");
          setValue("bankAccount.accountNumber", account.number);

          OToastManager.success(
            "Conta de garantia encontrada no Clerk e automaticamente preenchida.",
          );
        }
      } catch {
        OToastManager.danger(
          "Não foi possível encontrar uma conta de liquidação ativa no Clerk para a empresa selecionada",
        );
      }
    },
    [setValue, tipoProdutoWatch],
  );

  useEffect(() => {
    if (initialValue) {
      setValue("bankAccount.companyId", initialValue.company?.id);
      setValue("bankAccount.bankCode", initialValue.bank);
      setValue("bankAccount.branch", initialValue.agency);
      setValue("bankAccount.branchCheckDigit", initialValue.agencyDigit ?? "");
      setValue("bankAccount.accountNumber", initialValue.number);
      setValue("bankAccount.accountCheckDigit", initialValue.digit ?? "");
    }
  }, [initialValue, setValue]);

  useEffect(() => {
    if (!companyIdWatch || !value) {
      return;
    }

    if (isSettlementAccountFromCedente) {
      const cedenteInput = getValues("cedenteId");
      if (cedenteInput) {
        const foundCedente = (value as ValueItem[]).find(
          (cedente) => cedente.Id === parseInt(cedenteInput),
        );
        const cedenteCnpj = foundCedente?.Cnpj ?? "";
        findBankAccountOnClerk(cedenteCnpj);
      }
    } else {
      const sacadoInput = getValues("sacadoId");
      if (sacadoInput) {
        const foundSacado = (value as SacadosCompaniesViewValueItem[]).find(
          (sacado) => sacado.Id === parseInt(sacadoInput),
        );
        const sacadoCnpj = foundSacado?.Cnpj ?? "";
        findBankAccountOnClerk(sacadoCnpj);
      }
    }
  }, [
    companyIdWatch,
    enableBankAccountForm,
    findBankAccountOnClerk,
    getValues,
    isSettlementAccountFromCedente,
    setValue,
    value,
  ]);

  useEffect(() => {
    if (!enableBankAccountForm) {
      setValue("bankAccount.companyId", undefined);
      setValue("bankAccount.bankCode", undefined);
      setValue("bankAccount.branch", undefined);
      setValue("bankAccount.branchCheckDigit", undefined);
      setValue("bankAccount.accountNumber", undefined);
      setValue("bankAccount.accountCheckDigit", undefined);
    }
  }, [enableBankAccountForm, setValue]);

  return {
    enableBankAccountForm,
  };
};
