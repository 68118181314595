import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, grid } from "./advisors.grid";

export const GatekeeperAdvisorsPage = () => {
  const gridRef = useGridRef();

  return (
      <GridTemplate
          pageTitle={<PageTitle title="Assessores" />}
          gridRef={gridRef}
          showRefreshButton
          showClearFiltersButton
      >
          <ODataGridGenerator
              gridRef={gridRef}
              grid={grid}
              dataSource={dataSource}
          />
      </GridTemplate>
  );
};