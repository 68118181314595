export function getNewCardsOrder(
  selectedBigNumbersInOrder: string[],
  cardBeingDragged: number,
  cardBeingDraggedOver: number,
) {
  let firstPart = selectedBigNumbersInOrder.slice(0, cardBeingDraggedOver);

  let secondPart = selectedBigNumbersInOrder.slice(cardBeingDraggedOver);

  const idOfBigNumberBeingDragged = selectedBigNumbersInOrder[cardBeingDragged];

  if (firstPart.includes(idOfBigNumberBeingDragged)) {
    firstPart = firstPart.filter(
      (bigNumberId) => bigNumberId !== idOfBigNumberBeingDragged,
    );

    secondPart.unshift(idOfBigNumberBeingDragged);
  } else if (secondPart.includes(idOfBigNumberBeingDragged)) {
    secondPart = secondPart.filter(
      (bigNumberId) => bigNumberId !== idOfBigNumberBeingDragged,
    );

    firstPart.push(idOfBigNumberBeingDragged);
  }

  return [...firstPart, ...secondPart];
}
