import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CardOwner } from "../../../../../card-hiring.types";
import { cardHiringFields } from "../../card-hiring-form.form";

interface OwnerDocumentFieldProps {
  data?: CardOwner[];
}

export const OwnerDocumentField = ({ data }: OwnerDocumentFieldProps) => {
  const { watch } = useFormContext();

  const valueWatcher = watch("ownerDocument");

  const selectKey = useMemo(() => {
    return `${valueWatcher} - ${data?.length}`;
  }, [valueWatcher, data]);

  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldSelect
        {...cardHiringFields.ownerDocument}
        key={selectKey}
        readonly={!data?.length}
      >
        {data?.map(({ name, cpf }) => (
          <OOption key={cpf} value={cpf}>
            {name}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
