import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";

export const simpleConfirmationModalId = `simple-confirmation-modal`;

interface SimpleConfirmationModalProps {
  message: string | undefined;
  submit: () => void;
  editing?: boolean;
}

export const SimpleConfirmationModal = ({
  message,
  submit,
  editing,
}: SimpleConfirmationModalProps) => {
  return (
    <OModal id={simpleConfirmationModalId} position="center" size="sm" backdrop>
      <OModalHeader>
        <OTypography size="lg">
          {editing ? "Editar perfis de acesso" : "Adicionar usuário"}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ whiteSpace: "normal" }}>
          {message}
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_simples:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(simpleConfirmationModalId)}
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_simples:botao:enviar"
            dataLabel="enviar"
            onClick={() => {
              modalManager.hide(simpleConfirmationModalId);
              submit();
            }}
          >
            Enviar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
