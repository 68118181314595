import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { PostUsersGetByTaxIdResponse } from "services/clerk";
import { UsuariosProdutoByIdentificationRouteParams } from "../routes/usuarios.route-params";

export const useGetUser = () => {
  const { identification } =
    useParams<UsuariosProdutoByIdentificationRouteParams>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [user, setUser] = useState<PostUsersGetByTaxIdResponse>();

  if (!identification) {
    throw new Error("No identification");
  }

  const getUserFromTaxId = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await service.clerk.getUsersByTaxId({
        taxId: identification,
      });
      setUser(data);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [identification]);

  useEffect(() => {
    getUserFromTaxId();
  }, [getUserFromTaxId]);

  return {
    getUserFromTaxId,
    hasError,
    loading,
    user,
    userTaxId: identification,
  };
};
