import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { CompaniesRelationship } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const relationshipsGrid: ODataGridGeneratorConfig<CompaniesRelationship> =
  {
    datagrid: {
      noDataText: "Nenhum relacionamento encontrado.",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "Cedente",
        caption: "Cedente",
      },
      {
        dataField: "CedenteIdentificacao",
        caption: "Identificação cedente",
        cellRender: ({ data }) => masks.cnpj(data.CedenteIdentificacao),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "Sacado",
        caption: "Sacado",
      },
      {
        dataField: "SacadoIdentificacao",
        caption: "Identificação sacado",
      },
      {
        dataField: "StatusOnboardingCompleto",
        caption: "Status onboarding completo",
      },
      {
        dataField: "StatusCessaoFutura",
        caption: "Status termos futuro",
      },
    ],
  };
