import * as yup from "yup";

export interface LimitDetailsFormValues {
  expirationDate?: string | null;
  status?: string;
}

export const limitDetailsFormValidationsSchema: yup.ObjectSchema<LimitDetailsFormValues> =
  yup.object({
    status: yup.string().optional(),
    expirationDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .nullable()
      .optional(),
  });
