import { Roles } from "roles/roles.types";

export const advisoredCompaniesRoles = {
    product: {
        clientesAssessoradosPage: {
            role: "admin:B2BClientesPageProduct.View",
            description:
                "Visualização da página 'Portal B2B - Clientes assessorados' na visão produto",
        }
    },
} satisfies Roles;
