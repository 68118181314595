import { OCol, OLoader, ORFieldInput, ORow, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useProcessorConfigBasicDataPageContent } from "./processor-config-basic-data.hook";

export const ProcessorConfigBasicDataPage = () => {
  const { loading, updateBasicData, form, processorType } =
    useProcessorConfigBasicDataPageContent();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Dados básicos"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={updateBasicData}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Nome"
                labelSize="md"
              />
            </OCol>
            <OCol className="d-flex flex-column gap-3">
              <OTypography>Tipo</OTypography>
              <OTypography>{processorType}</OTypography>
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                labelSize="md"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
