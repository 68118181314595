import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { sharkService } from "services/shark/shark.service";
import { helpers } from "@maestro/utils";
import dayjs from "dayjs";
import { copyToClipboard } from "utils/copy";
import { Bankslip } from "services/shark/models/responses";
import { emprestimosRoles } from "../../../../../roles/emprestimos.roles";
import { ConfirmationModal } from "./_compose/confirmation-modal.component";
import { EmailFormModal } from "./_compose/email-form-modal.component";
import { UnavailableBankslip } from "./_compose/unavailable-bankslip.component";
import {
  OperationsQuotesActionFormValues,
  operationsQuotesActionValidationSchema,
} from "./operations-quotes-action.schema";

interface OperationsQuotesGridActionsProps {
  tradeId: string;
  installment: number;
  installmentFrequency: string;
  hasBankslip: boolean;
  email: string | null | undefined;
  bankslips: Array<Bankslip> | undefined;
}

export const OperationsQuotesGridActions = ({
  tradeId,
  installment,
  installmentFrequency,
  hasBankslip,
  email,
  bankslips,
}: OperationsQuotesGridActionsProps) => {
  const resendModalId = useMemo(
    () => `reenvia-boleto-${installment}`,
    [installment],
  );
  const predefinedResendModalId = useMemo(
    () => `reenvia-boleto-email-predefinido-${installment}`,
    [installment],
  );

  const [loading, setLoading] = useState(false);

  const form = useForm<OperationsQuotesActionFormValues>({
    resolver: yupResolver(operationsQuotesActionValidationSchema),
    defaultValues: {
      email: "",
    },
  });

  const { handleSubmit, reset } = form;

  const handleResendBankslip = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const data = {
        email: values.email,
        trade_id: tradeId,
        installment,
        installment_frequency: installmentFrequency,
      };

      await sharkService.sendBankslipViaEmail(data);

      OToastManager.success("Boleto reenviado com sucesso!");
      modalManager.hide(resendModalId);
      reset();
    } catch {
      OToastManager.danger("Erro ao reenviar boleto.");
    } finally {
      setLoading(false);
    }
  });

  const handleResendBankslipToPredefinedEmail = async () => {
    try {
      if (!email) return;

      setLoading(true);

      const data = {
        email,
        trade_id: tradeId,
        installment,
        installment_frequency: installmentFrequency,
      };

      await sharkService.sendBankslipViaEmail(data);

      OToastManager.success("Boleto reenviado com sucesso!");
      modalManager.hide(predefinedResendModalId);
    } catch {
      OToastManager.danger("Erro ao reenviar boleto.");
    } finally {
      setLoading(false);
    }
  };

  const getBankslipFromShark = useCallback(
    async (
      installmentNumber: number,
      trade_id: string,
      installmentFreq: string,
    ) => {
      try {
        OToastManager.info("Download iniciado...");
        const { data: bankSlipBlob } =
          await sharkService.downloadBankslipByTradeId({
            installment: installmentNumber,
            installment_frequency: installmentFreq,
            trade_id,
          });

        helpers.downloadBlobFile(
          `boleto-${dayjs().format("DDMMYYYY")}.pdf`,
          bankSlipBlob,
        );
      } catch (err) {
        OToastManager.danger(
          "Erro ao buscar boleto. Tente novamente mais tarde.",
        );
      }
    },
    [],
  );

  const getBankslipBarcode = useCallback(
    (instNumber: number) => {
      try {
        const bankslip = bankslips?.find(
          (item) => item.installment === instNumber,
        );

        copyToClipboard(bankslip?.barcode_number || "");

        OToastManager.success("Linha digitável copiada com sucesso.");
      } catch (err) {
        OToastManager.danger("Não foi possível copiar a linha digitável.");
      }
    },
    [bankslips],
  );

  return !hasBankslip ? (
    <UnavailableBankslip />
  ) : (
    <FormProvider {...form}>
      <DataGridAction
        actions={[
          {
            label: "Reenviar boleto",
            role: emprestimosRoles.customer
              .installmentsPageResendBankslipViaEmail.role,
            icon: { category: "far", icon: "fa-paper-plane" },
            onClick: () =>
              email
                ? modalManager.show(predefinedResendModalId)
                : modalManager.show(resendModalId),
          },
          {
            label: "Linha digitável",
            role: emprestimosRoles.customer
              .installmentsPageCopyBankslipToClipboard.role,
            icon: { category: "orq", icon: "orq-bank-slip" },
            onClick: () => getBankslipBarcode(installment),
          },
          {
            label: "Baixar boleto",
            role: emprestimosRoles.customer.installmentsPageDownloadBankslip
              .role,
            icon: { category: "orq", icon: "orq-download" },
            onClick: () =>
              getBankslipFromShark(installment, tradeId, installmentFrequency),
          },
        ]}
      />

      {email ? (
        <ConfirmationModal
          email={email}
          id={predefinedResendModalId}
          loading={loading}
          onConfirm={handleResendBankslipToPredefinedEmail}
        />
      ) : (
        <EmailFormModal
          loading={loading}
          id={resendModalId}
          onConfirm={handleResendBankslip}
        />
      )}
    </FormProvider>
  );
};
