export const antecipacaoAFornecedorRouteParams = {
  id: "id",
  sacadoId: "sacadoId",
} as const;

type AntecipacaoAFornecedorParams = Record<
  keyof typeof antecipacaoAFornecedorRouteParams,
  string
>;

export type AntecipacaoAFornecedorClienteOperacoesByIdRouteParams = Pick<
  AntecipacaoAFornecedorParams,
  "id"
>;

export type AntecipacaoAFornecedorClienteRecebiveisBySacadoIdRouteParams = Pick<
  AntecipacaoAFornecedorParams,
  "sacadoId"
>;
