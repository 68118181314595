import { JSX } from "@maestro/core";
import {
  modalManager,
  OButton,
  OCardBody,
  OIcon,
  OTypography,
} from "@maestro/react";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewById } from "../../../../../../../../routes/workflow.route-params";
import { DraggableCard } from "../../../edit-workflow-config.styles";
import {
  AddBigNumbersModal,
  ADD_BIG_NUMBERS_MODAL,
} from "./add-big-numbers-modal.component";
import { useWorkflowConfigViewBigNumbers } from "./workflow-config-view-big-numbers.hook";

export const WorkflowConfigViewBigNumbers = () => {
  const {
    bigNumbersOptions,
    bigNumbersWatch,
    cardBeingDragged,
    cardBeingDraggedOver,
    form,
    loadingBigNumbers,
    loadingWorkflowConfigView,
    loadingUpdateViewBigNumbers,
    reorderCards,
    selectedBigNumbersInOrder,
    updateViewBigNumbers,
    hasErrorBigNumbers,
    hasErrorWorkflowConfigView,
    getAllData,
    workflowConfigView,
  } = useWorkflowConfigViewBigNumbers();

  const { id, workflowConfigViewId } =
    useParams<WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewById>();

  if (!id || !workflowConfigViewId)
    throw new Error("No id / No workflowConfigViewId");

  const navigate = useNavigate();

  return (
    <>
      <DetailsTemplate
        pageTitle={<PageTitle title="Configurar visualização - Big numbers" />}
        actions={
          <>
            <OButton
              dataAction="editar-big-numbers:botao:adicionar-big-number"
              dataLabel="adicionar-big-number"
              onClick={() => modalManager.show(ADD_BIG_NUMBERS_MODAL)}
              type="dark"
              disabled={!bigNumbersOptions}
              outline
            >
              Adicionar / Remover big number(s)
            </OButton>
            <LoadingButton
              loading={loadingUpdateViewBigNumbers}
              onClick={updateViewBigNumbers}
              dataAction="editar_workflow:botao:salvar"
              dataLabel="salvar"
              disabled={!bigNumbersWatch.length}
            >
              Salvar
            </LoadingButton>
          </>
        }
      >
        <ContentTemplate
          loading={loadingBigNumbers || loadingWorkflowConfigView}
          hasError={hasErrorBigNumbers || hasErrorWorkflowConfigView}
          value={bigNumbersWatch?.length}
          errorComponent={
            <ErrorComponent
              messageTitle="Um erro ocorreu ao buscar pelos dados de configuração."
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={getAllData} />
            </ErrorComponent>
          }
          emptyStateComponent={
            <EmptyState messageTitle="Nenhum big number foi adicionado no momento." />
          }
          render={() => (
            <div className="d-flex flex-column gap-2">
              {bigNumbersOptions &&
                selectedBigNumbersInOrder
                  .map((selectedBigNumberId) =>
                    bigNumbersOptions.find(
                      (option) => option.id === Number(selectedBigNumberId),
                    ),
                  )
                  .map(
                    (option, index) =>
                      option !== undefined && (
                        <DraggableCard
                          key={`big-number-card-${option?.id}`}
                          type="info-light"
                          onDragStart={() => {
                            cardBeingDragged.current = index;
                          }}
                          onDragEnter={() => {
                            cardBeingDraggedOver.current = index;
                          }}
                          onDragEnd={reorderCards}
                          draggable
                        >
                          <OCardBody className="d-flex justify-content-between align-items-center gap-3 p-3">
                            <div className="d-flex gap-2 align-items-center">
                              <OTypography>{option?.name}</OTypography>
                              <OIcon
                                category={
                                  (option?.iconName.split(
                                    "-",
                                  )[0] as JSX.OIcon["category"]) ?? "fa"
                                }
                                icon={option?.iconName ?? "fa-question-circle"}
                                className="mt-1"
                              />
                            </div>
                            {workflowConfigView?.viewBigNumbers
                              .map(
                                (viewBigNumber) => viewBigNumber.bigNumber.id,
                              )
                              .includes(option.id) && (
                              <OButton
                                dataAction="workflow-config-view-big-numbers:botao:configurar-grid"
                                dataLabel="configurar-grid"
                                type="info-light"
                                onClick={() => {
                                  navigate(
                                    corporateRouter.routes.workflow.product.workflowsConfig.details.edit.views.configure.bigNumbers.grid.path(
                                      {
                                        id,
                                        workflowConfigViewId,
                                        viewBigNumberId: Number(
                                          workflowConfigView.viewBigNumbers.find(
                                            (viewBigNumber) =>
                                              viewBigNumber.bigNumber.id ===
                                              option.id,
                                          )?.id,
                                        ),
                                      },
                                    ),
                                  );
                                }}
                              >
                                <OIcon category="fal" icon="fa-pencil" />
                              </OButton>
                            )}
                          </OCardBody>
                        </DraggableCard>
                      ),
                  )}
            </div>
          )}
        />
      </DetailsTemplate>
      <AddBigNumbersModal form={form} bigNumbersOptions={bigNumbersOptions} />
    </>
  );
};
