import { Profile } from "./profile-selector.types";

export const mapProfileUuids = (
  uuids: string[] | undefined,
  profiles: Profile[] | undefined,
) =>
  uuids
    ?.map(
      (uuid) => profiles?.find((profile) => profile.uuid === uuid)?.nameLabel,
    )
    ?.join(", ");
