import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ExchangeQuoteResponse } from "services/hubfx/models/types/quote/quote.model";
import { currencySymbol } from "utils/currency";
import { mapQuoteStatus, parseQuoteFlowType } from "../../utils/quote.utils";

export const exchangeQuotesGrid = (
  mapSymbol: (currency?: string | null) => string,
) => {
  return {
    datagrid: {
      noDataText: "Nenhuma operação encontrada.",
      headerFilter: { visible: true },
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.cambio.customer.quotesDetailsPage.role,
        cellRender: ({ data }) =>
          !!data.id && (
            <DetailsLink
              href={corporateRouter.routes.cambio.customer.quotes.details.path({
                id: data.id,
              })}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "createdDate",
        caption: "Data de solicitação",
        dataType: "datetime",
        format: "shortDateShortTime",
      },
      {
        caption: "Beneficiário/Pagador",
        cellRender: ({ data }) => parseQuoteFlowType(data)?.externalPartyName,
      },
      {
        caption: "Tipo",
        dataField: "flowType",
        cellRender: ({ data }) => parseQuoteFlowType(data)?.type,
      },
      { caption: "Enquadramento", dataField: "natureDescription" },
      {
        caption: "Moeda Estrangeira",
        cellRender: ({ data }) => parseQuoteFlowType(data)?.currency,
      },
      {
        caption: "Status",
        dataField: "status",
        cellRender: ({ data }) => {
          return mapQuoteStatus(data.status);
        },
      },
      {
        caption: "Cotação",
        cellRender: ({ data }) => {
          return data.exchangeRatio?.toString().replace(".", ",");
        },
      },
      {
        caption: "VR. Moeda Estrangeira",
        cellRender: ({ data }) => {
          return masks.currency(
            data.orderAmount,
            mapSymbol(parseQuoteFlowType(data)?.currency),
            ".",
          );
        },
      },
      {
        caption: "VR. Moeda Nacional",
        cellRender: ({ data }) => {
          return masks.currency(
            data.sellCurrencyTotal,
            currencySymbol.BRL,
            ".",
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<ExchangeQuoteResponse>;
};
