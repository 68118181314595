import { OInputPhone } from "@maestro/react";
import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { gridStorage } from "utils/storage";

/** @deprecated */
export const energyContactsFormGrid = (
  onChange: (contacts: HubEnergy.ContactResponse[]) => void,
  readonly?: boolean,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum contato.",
      stateStoring: gridStorage("energyContactsFormGrid"),
      editing: {
        confirmDelete: !readonly,
        allowDeleting: !readonly,
        allowUpdating: !readonly,
      },
      onRowUpdated: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowInserted: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowValidating: (e) => {
        const { email } = {
          ...e.oldData,
          ...e.newData,
        };
        const noEditingValues = e.component
          .getVisibleRows()
          .flatMap((row) => (row.isEditing ? [] : [row.data]));

        const hasContactWithSameEmail = noEditingValues.some(
          (item) => item.email === email,
        );

        if (hasContactWithSameEmail) {
          e.errorText = "Email já cadastrado";
          e.isValid = false;
        }
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        caption: "Nome",
        dataField: "name",
        dataType: "string",
      },
      {
        caption: "Contato principal?",
        dataField: "isMain",
        dataType: "boolean",
        validationRules: [
          {
            type: "custom",
            validationCallback: ({ value }) => typeof value === "boolean",
          },
        ],
      },
      {
        caption: "Telefone",
        dataField: "phoneNumber",
        dataType: "string",
        format: (value) => masks.phone(value),
        editCellRender: ({ setValue, rowIndex, value }) => (
          <OInputPhone
            id={`phone-number-${rowIndex}`}
            name={`phone-number-${rowIndex}`}
            value={value}
            onInput={(evt) => setValue(evt.currentTarget.value)}
            aspect="unstyled"
            className="p-2"
          />
        ),
        validationRules: [
          {
            type: "custom",
            message: "Telefone inválido",
            validationCallback: (option) => {
              return !option.value || validators.phone(option.value);
            },
          },
        ],
      },
      {
        caption: "Email",
        dataField: "email",
        dataType: "string",
        validationRules: [
          {
            type: "custom",
            message: "Email inválido",
            validationCallback: (option) => {
              return validators.email(option.value);
            },
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<HubEnergy.ContactResponse>;
};
