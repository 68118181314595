import * as yup from "yup";
import { AddRelationshipForm } from "./add-relationship.types";

export const addRelationshipDefaulValues = {
  cedenteId: null,
  sacadoId: null,
} satisfies Record<keyof AddRelationshipForm, unknown> as any;

export const addRelationshipValidationSchema = yup.object({
  cedenteId: yup
    .number()
    .typeError("Digite um valor numérico")
    .required("É necessário selecionar um cedente"),
  sacadoId: yup
    .number()
    .typeError("Digite um valor numérico")
    .required("É necessário selecionar um sacado"),
});
