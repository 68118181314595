import { useUnsafeCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useCollateralIdentification = () => {
  const { customer } = useUnsafeCustomer();

  const { callService, loading, value, hasError } = useServiceCall(
    service.hubCreditManager.getLimit,
  );

  const { clearErrors, setError } = useFormContext();

  const getCollaterals = useCallback(async () => {
    if (!customer?.identification) return;
    const { success } = await callService({
      taxId: customer.identification,
      identification: "RISCOSACADO",
      companyTaxId: "",
      userEmail: "",
      originSystem: "",
      originIP: "",
    });
    if (success) clearErrors("collateralIdentification");
    else
      setError("collateralIdentification", {
        message:
          "Erro ao buscar as garantias. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, customer?.identification, setError]);

  useEffect(() => {
    getCollaterals();
  }, [getCollaterals]);

  return {
    collaterals: value?.currentLimitRequest?.collaterals,
    getCollaterals,
    hasFetchError: hasError,
    loading,
  };
};
