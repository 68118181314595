import { OTypography } from "@maestro/react";
import { IAddress } from "../../../../cards.types";
import { addressToStreetAndNeighborhoodText, dateRangeFormatToDayMonthAndYear } from "./utils";

interface CardTrackingAddressProps {
  delivered: boolean;
  bestDate: string;
  worstDate: string;
  address: IAddress;
}

export const CardTrackingAddress = ({
  delivered,
  bestDate,
  worstDate,
  address,
}: CardTrackingAddressProps): JSX.Element => {
  const deliverRange = dateRangeFormatToDayMonthAndYear(
    bestDate,
    worstDate,
  );

  const { streetText, neighborhoodText } =
    addressToStreetAndNeighborhoodText(address);

  const deliverSubtitle = delivered
    ? "O cartão foi entregue em:"
    : "Previsão de entrega:";

  return (
    <div className="d-flex flex-column gap-2">
      <OTypography key={deliverSubtitle} size="md" weight="500">
        {deliverSubtitle}
      </OTypography>
      <OTypography weight="300" className="px-3">
        {deliverRange}
      </OTypography>
      <OTypography size="md" weight="500">
        Endereço de entrega:
      </OTypography>
      <div>
        <OTypography weight="300" className="px-3">
          {streetText}
        </OTypography>
        <OTypography weight="300" className="px-3">
          {neighborhoodText}
        </OTypography>
      </div>
    </div>
  );
};
