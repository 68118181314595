import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { Representative } from "./company-acts.types";
import { userDataSearchParam } from "./vincular/attach-user.types";

export const buildAttachUsersLink = ({
  taxId,
  name,
  email,
  phone,
}: Representative) => {
  const baseUrl =
    corporateRouter.routes.cadastro.customer.companyActs.attachUser.path;

  const userData = JSON.stringify({ taxId, name, email, phone });
  const searchParams = new URLSearchParams();
  searchParams.set(userDataSearchParam, userData);

  return baseUrl + "?" + searchParams.toString();
};

const grid: ODataGridGeneratorConfig<Representative> = {
  datagrid: {
    noDataText: "Nenhum representante",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} representantes)",
    },
    paging: {
      pageSize: 10,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "cge", caption: "CGE" },
    {
      dataField: "taxId",
      caption: "CPF",
      format: (value) => masks.cpf(value),
    },
    { dataField: "name", caption: "Nome" },
    { dataField: "email", caption: "E-mail" },
    {
      dataField: "phone",
      caption: "Telefone",
      format: (value) => masks.phone(value),
    },
    {
      dataField: "isCompanyUser",
      caption: "É usuário?",
    },
    {
      calculateCellValue: ({ isCompanyUser }) =>
        isCompanyUser ? "Detalhes" : "Vincular",
      cellRender: ({ data }) =>
        data.isCompanyUser ? (
          <StandardLink
            href={corporateRouter.routes.usuarios.customer.users.details.path({
              identification: data.taxId,
            })}
            role={roles.usuarios.customer.detailsPage.role}
          >
            Detalhes
          </StandardLink>
        ) : (
          !!data.taxId && (
            <StandardLink
              href={buildAttachUsersLink(data)}
              role={roles.cadastro.customer.companyActsAttachUserPage.role}
            >
              Vincular
            </StandardLink>
          )
        ),
    },
  ],
};

interface RepresentativesMasterDetailProps {
  data: {
    data: {
      representatives: Representative[];
    };
  };
}

export const RepresentativesMasterDetail = ({
  data: {
    data: { representatives },
  },
}: RepresentativesMasterDetailProps) => {
  return (
    <div>
      <OTypography type="dark" size="lg" className="mb-3">
        Representantes
      </OTypography>
      <ODataGridGenerator grid={grid} dataSource={representatives} />
    </div>
  );
};
