import { safeSessionStorage } from "utils/storage";
import { authEvents } from "./auth.event";

export class Auth {
  private static storageKey = "corporate-portal:authorization";

  private static store = safeSessionStorage;

  static storage = {
    get: () => this.store.getItem(this.storageKey),
    set: (authorization: string) =>
      this.store.setItem(this.storageKey, authorization),
    remove: () => this.store.removeItem(this.storageKey),
  };

  private _value = "";

  get value() {
    return this._value;
  }

  set value(newAuthorization) {
    this._value = newAuthorization;
    authEvents.dispatch(this);
  }
}

export const auth = new Auth();
