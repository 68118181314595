import {
  OCol,
  OField,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { productsMap } from "../sacado-tier.utils";
import { useAddSacadoTierConfig } from "./add-sacado-tier-config.hook";

export const AddSacadoTierConfig = () => {
  const { sacadosOptions, sacadosLoading, form, submit, submitLoading } =
    useAddSacadoTierConfig();

  const {
    formState: { errors },
    setValue,
  } = form;

  const clearSacado = useCallback(
    () => setValue("sacadoId", null as any),
    [setValue],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Tier Mínimo do Sacado"
          description="Adicionar"
        />
      }
      actions={
        <LoadingButton
          dataAction="configuracao_sacado:botao:criar_configuracao"
          dataLabel="criar_configuracao"
          onClick={submit}
          loading={submitLoading}
        >
          Criar configuração
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow gap={3}>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldInput
                dataAction="configuracao_sacado:texto:tier_minimo"
                dataLabel="tier_minimo"
                id="minimumTier"
                name="minimumTier"
                tag="mask"
                label="Tier mínimo"
                maskOption={{ mask: Number }}
              />
            </OCol>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldSelect
                dataAction="configuracao_sacado:select:tipo_produto"
                dataLabel="tipo_produto"
                id="tipoProduto"
                name="tipoProduto"
                label="Tipo de produto"
              >
                {Object.entries(productsMap).map(([key, value]) => (
                  <OOption key={key} value={key}>
                    {value}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <OField
                label="Sacado"
                htmlFor="sacadoId"
                error={!!errors.sacadoId}
                message={errors?.sacadoId?.message}
              >
                <SelectSearch
                  id="sacadoId"
                  name="sacadoId"
                  placeholder="Busque o nome do sacado"
                  options={sacadosOptions}
                  disabled={sacadosLoading}
                  onClear={clearSacado}
                  className="w-100"
                  maxOptions={50}
                  eager={false}
                />
              </OField>
              {sacadosLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
