import { OToastManager } from "@maestro/core";
import { useEffect, useState } from "react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useForm } from "react-hook-form";
import { GetDashboardBatchesParams } from "services/fidc/models/requests";
import { GetDashboardBatchesResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { useFidcFunds } from "../../../hooks";

const dataSourceGenerator = (fundTaxId: string) =>
  dataSourceCustomStoreGenerator<GetDashboardBatchesResponse[number]>(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const params: GetDashboardBatchesParams = {
      fundTaxId: parseInt(fundTaxId, 10),
      from: yesterday.toISOString(),
      to: new Date().toISOString(),
    };
    return service.fidc
      .getFundBatches(params)
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os ativos.";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const useFidcBatches = () => {
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();
  const [isDonus, setIsDonus] = useState(false);
  const { funds } = useFidcFunds();
  const form = useForm();
  const { watch } = form;
  const selectedFundWatch: string = watch("selectedFund");

  useEffect(() => {
    if (!selectedFundWatch) {
      return;
    }
    setDataSource(dataSourceGenerator(selectedFundWatch ?? ""));
    setIsDonus(
      funds
        ?.find((fund) => fund.taxId === selectedFundWatch)
        ?.name.includes("DONUS") ?? false,
    );
  }, [funds, selectedFundWatch]);

  return {
    dataSource,
    form,
    funds,
    isDonus,
    selectedFundWatch,
  };
};
