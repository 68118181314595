import { OInputDatetime } from "@maestro/react";
import moment, { Moment } from "moment";
import { InputContent, InputLabel } from "../compose.styles";
import { IInputDatetimeProps } from "./question-text-compose.types";

export const InputDatetime = ({
  name,
  label,
  isEditable,
  fields,
  handleInputChange,
  errors,
}: IInputDatetimeProps) => {
  const onChange = (dateMoment: Moment | null, _: string) => {
    handleInputChange({
      name: name,
      value: (dateMoment && dateMoment.format("DD/MM/YYYY HH:mm")) || "",
      label: label,
    });
  };

  return (
    <div key={name}>
      <InputLabel>{label}</InputLabel>
      <InputContent>
        <OInputDatetime
          value={
            (fields[name] && moment(fields[name], "DD/MM/YYYY HH:mm:ss")) ||
            undefined
          }
          onChange={onChange}
          disabled={!isEditable}
          disabledDate={(date) => !date || date.isAfter(moment())}
        />
        {errors[name] && <span>{errors[name]}</span>}
      </InputContent>
    </div>
  );
};
