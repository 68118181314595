import { SelectSearchFieldV2 } from "../../../../../../../components/select-search-v2";
import { actionTypeMap } from "../../../diligence-events.utils";

interface ActionTypeSelectorProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  dropdownPosition?: "top" | "bottom";
}

export const ActionTypeSelector = ({
  label,
  name,
  placeholder,
  required,
  dropdownPosition,
}: ActionTypeSelectorProps) => {
  return (
    <SelectSearchFieldV2
      options={Object.entries(actionTypeMap).map(([type, text]) => ({
        content: text,
        value: type,
      }))}
      id={name}
      name={name}
      placeholder={placeholder}
      label={label}
      required={required}
      multiple={false}
      dropdownPosition={dropdownPosition}
    />
  );
};
