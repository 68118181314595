import * as yup from "yup";

export interface ExchangeDocumentConfigsFormValues {
  typeName: string;
  typeDescription: string;
  typeDescriptionDisplay: string;
  isFileAllowed: string[];
  fileGenericFields: {
    typeId: number;
    isRequired: boolean;
  }[];
  genericFields: {
    typeId: number;
    isRequired: boolean;
  }[];
  fxPendencyCodes?: string[];
}

export const exchangeDocumentConfigsFormValidationFormSchema: yup.ObjectSchema<ExchangeDocumentConfigsFormValues> =
  yup.object({
    typeName: yup.string().required("Campo obrigatório"),
    typeDescription: yup.string().required("Campo obrigatório"),
    typeDescriptionDisplay: yup.string().required("Campo obrigatório"),
    isFileAllowed: yup.array().default([]),
    genericFields: yup
      .array(
        yup.object({
          typeId: yup.number().required("Campo obrigatório"),
          isRequired: yup.boolean().required("Campo obrigatório"),
        }),
      )
      .default([]),
    fileGenericFields: yup
      .array(
        yup.object({
          typeId: yup.number().required("Campo obrigatório"),
          isRequired: yup.boolean().required("Campo obrigatório"),
        }),
      )
      .default([]),
    fxPendencyCodes: yup.array().default([]),
  });
