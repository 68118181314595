import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OField,
  OIcon,
  OOption,
  ORFieldSelect,
  ORow,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  lockInfoTypes,
  settlementMethodCodes,
} from "../../settlement-strategies.utils";
import { SettlementStrategisFormProps } from "../formulario/settlement-strategies-form.types";

export const StrategyParametersFields = ({
  initialValues,
  isConsolidated,
  setIsConsolidated,
}: Omit<SettlementStrategisFormProps, "form">) => {
  const form = useFormContext();
  const { setValue } = form;

  useEffect(() => {
    if (initialValues) {
      setValue("settlementMethodCode", initialValues.settlementMethodCode);
      setValue("settlementLockInfo", initialValues.settlementLockInfo);
    }
  }, [initialValues, setValue]);

  return (
    <OCard>
      <OCardHeader divider>Parâmetros da estratégia</OCardHeader>
      <OCardBody>
        <ORow>
          <OCol sm={12} xl={4}>
            <ORFieldSelect
              aspect="outline"
              label="Method Code"
              name="settlementMethodCode"
              id="settlementMethodCode"
              dataAction="formulario_estrategia_liquidacao:select:method_code"
              dataLabel="method_code"
            >
              {settlementMethodCodes.map((settlement) => (
                <OOption key={settlement} value={settlement}>
                  {settlement}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol sm={12} xl={4}>
            <ORFieldSelect
              aspect="outline"
              label="Lock Info"
              name="settlementLockInfo"
              id="settlementLockInfo"
              dataAction="formulario_estrategia_liquidacao:select:lock_info"
              dataLabel="lock_info"
            >
              {lockInfoTypes.map((lockInfo) => (
                <OOption key={lockInfo} value={lockInfo}>
                  {lockInfo}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol sm={12} xl={4}>
            <OField label="Consolidado?" htmlFor="isConsolidated">
              <OSwitch
                value={isConsolidated}
                onInput={() =>
                  setIsConsolidated((oldValue: boolean) => !oldValue)
                }
                className="w-100 mt-2"
                size="xs"
              />
            </OField>
          </OCol>
        </ORow>
        <div className="d-flex flex-row align-items-center gap-2">
          <OIcon size="lg" category="orq" icon="orq-info" />
          <OTypography tag="p" type="dark" size="sm">
            Para liquidação via TED, escolha EXTN e DRCT. Para liquidação via
            Boleto, escolha INVC e DRCT. Para Swift, escolha SWFT e NONE. Para
            liquidação em conta interna, escolha INTN e DRCT. Para liquidação em
            conta escrow, escolha INTN e RFCD. Para liquidação multicontas
            internamente, escolha INTN e SCAN. Para habilitar bloqueio, escolha
            INTN e LOCK. Para liquidação com arrecadação múltipla e trava em
            qualquer recurso para todas as contas, escolha INTN e MSTT.
          </OTypography>
        </div>
      </OCardBody>
    </OCard>
  );
};
