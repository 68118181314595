import { OConfirmationModal, OLoader, modalManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse } from "services/quickfin/models";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { TaxasFields } from "../../../../../../components";
import { useEditTaxas } from "../../../../../../components/taxas-form/edit-taxas.hook";

const confirmationModalId = "remove-taxa-confirmation-modal";

interface ConfigureTaxasFormProps {
  estrategia:
    | GetEstrategiaComercialByCompanyIdByEstrategiaIdResponse
    | undefined;
  getCompanyEstrategia: () => void;
  hasError: boolean;
  estrategiaLoading: boolean;
}

export const ConfigureTaxasForm = ({
  estrategia,
  getCompanyEstrategia,
  hasError,
  estrategiaLoading,
}: ConfigureTaxasFormProps) => {
  const {
    form,
    isEditing,
    loading,
    submit,
    submitLoading,
    remove,
    removeLoading,
  } = useEditTaxas(estrategia);

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title={"Configurar taxas - " + estrategia?.uniqueId} />}
        actions={
          <>
            <LoadingButton
              dataAction="acoes:botao:excluir"
              dataLabel="excluir"
              loading={removeLoading}
              onClick={() => modalManager.show(confirmationModalId)}
              disabled={!isEditing}
              type="danger"
            >
              Excluir
            </LoadingButton>
            <LoadingButton
              dataAction="acoes:botao:salvar"
              dataLabel="salvar"
              loading={submitLoading}
              onClick={submit(false)}
            >
              Salvar
            </LoadingButton>
          </>
        }
      >
        <CardTemplate>
          <ContentTemplate
            loading={estrategiaLoading}
            hasError={hasError}
            value={estrategia}
            errorComponent={
              <DefaultErrorComponent
                title="Não foi possível encontrar a estratégia"
                callback={getCompanyEstrategia}
              />
            }
            render={() => (
              <FormProvider {...form}>
                {loading && <OLoader absolute backdrop />}
                <TaxasFields isEditing={isEditing} hasTermFields />
              </FormProvider>
            )}
          />
        </CardTemplate>
      </FormTemplate>
      <OConfirmationModal
        modalId={confirmationModalId}
        title="Certeza de deseja excluir a taxa?"
        confirmLabel="Sim"
        closeLabel="Cancelar"
        onConfirm={() => {
          remove();
          modalManager.hide(confirmationModalId);
        }}
      />
    </>
  );
};
