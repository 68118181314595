import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { BatchTierInfo, PreviewBatchTiersInfo } from "./upload-tiers.types";

interface UploadTiersContext {
  nextStep: () => void;
  parsedBatchTiersData: BatchTierInfo[];
  prevStep: () => void;
  previewData: PreviewBatchTiersInfo[];
  reset: () => void;
  setParsedBatchTiersData: React.Dispatch<
    React.SetStateAction<BatchTierInfo[]>
  >;
  setPreviewData: React.Dispatch<React.SetStateAction<PreviewBatchTiersInfo[]>>;
  stepNumber: number;
  submit: () => Promise<unknown>;
  submitHasError: boolean;
  submitLoading: boolean;
  submitResponse: unknown;
}

const uploadTiersContext = createContext({} as UploadTiersContext);

interface UploadTiersProps {
  children: React.ReactNode;
}

export const UploadTiersProvider = ({ children }: UploadTiersProps) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [parsedBatchTiersData, setParsedBatchTiersData] = useState<
    BatchTierInfo[]
  >([]);
  const [previewData, setPreviewData] = useState<PreviewBatchTiersInfo[]>([]);

  const {
    callService: batchSendTiers,
    hasError: submitHasError,
    loading: submitLoading,
    value: submitResponse,
  } = useServiceCall(service.quickfin.batchSendTiers);

  const nextStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber + 1),
    [],
  );

  const prevStep = useCallback(
    () => setStepNumber((_stepNumber) => _stepNumber - 1),
    [],
  );

  const reset = useCallback(() => {
    setStepNumber(0);
    setParsedBatchTiersData([]);
    setPreviewData([]);
  }, []);

  const submit = useCallback(
    () => batchSendTiers(parsedBatchTiersData),
    [batchSendTiers, parsedBatchTiersData],
  );

  const value = useMemo(
    () => ({
      nextStep,
      parsedBatchTiersData,
      prevStep,
      previewData,
      reset,
      setParsedBatchTiersData,
      setPreviewData,
      stepNumber,
      submit,
      submitHasError,
      submitLoading,
      submitResponse,
    }),
    [
      nextStep,
      parsedBatchTiersData,
      prevStep,
      previewData,
      reset,
      stepNumber,
      submit,
      submitHasError,
      submitLoading,
      submitResponse,
    ],
  );

  return (
    <uploadTiersContext.Provider value={value}>
      {children}
    </uploadTiersContext.Provider>
  );
};

export const useUploadTiers = () => useContext(uploadTiersContext);
