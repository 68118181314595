import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState, useCallback } from "react";
import { service } from "services";
import { WalletProvisionRequestStatus } from "../../wallets.types";
import { useUser } from "contexts/user";
import { getValueFromMap } from "utils/get-value-from-map";

interface WalletProvisionRequestActionButtonsProps {
  requestId: string;
  cardId: string;
}

export const WalletProvisionRequestActionButtons = ({
  requestId,
  cardId,
}: WalletProvisionRequestActionButtonsProps) => {
  const [action, setAction] = useState<WalletProvisionRequestStatus>("CREATED");
  const { callService, hasError, loading } = useServiceCall(
    service.bankinghub.updateCardWalletProvision,
  );

  const modalText = {
    APPROVED: {
      title: "Aprovar requisição de Wallet?",
      subtitle: "Deseja realmente APROVAR a requisição de Wallet?",
    },
    REJECTED: {
      title: "Rejeitar requisição de Wallet?",
      subtitle: "Deseja realmente REJEITAR a requisição de Wallet?",
    },
  };

  const updateWalletProvisionRequestStatusModalId = `update-wallet-provision-request-${requestId}-modal`;

  const { user } = useUser();

  useEffect(() => {
    if (hasError) {
      OToastManager.danger(
        "Não foi possível modificar o status da requisição de wallet",
      );
    }
  }, [hasError]);

  const handleUpdateWalletCardProvision = useCallback(async () => {
    try {
      await callService(cardId, requestId, {
        action,
        operatorDocument: user.cpf,
        operatorName: user.name,
      });
    } catch (error) {
      console.error(error);
    } finally {
      modalManager.hide(updateWalletProvisionRequestStatusModalId);
    }
  }, [user, cardId, requestId, updateWalletProvisionRequestStatusModalId]);

  return (
    <>
      <div className="d-flex gap-2" style={{ width: "150px" }}>
        <IconButton
          icon={{
            category: "orq",
            icon: "orq-close",
          }}
          loading={loading}
          type="danger"
          outline
          onClick={() => {
            setAction("REJECTED");
            modalManager.show(updateWalletProvisionRequestStatusModalId);
          }}
          fullWidth
        >
          Rejeitar
        </IconButton>
        <IconButton
          icon={{
            category: "orq",
            icon: "orq-check",
          }}
          loading={loading}
          type="success"
          outline
          onClick={() => {
            setAction("APPROVED");
            modalManager.show(updateWalletProvisionRequestStatusModalId);
          }}
          fullWidth
        >
          Aprovar
        </IconButton>
      </div>

      <OConfirmationModal
        modalId={updateWalletProvisionRequestStatusModalId}
        title={
          getValueFromMap(modalText, action)?.title ||
          "Alterar status da requisição de Wallet?"
        }
        confirmLabel="Sim"
        closeLabel="Não"
        divider={false}
        size="xs"
        loading={loading}
        onConfirm={handleUpdateWalletCardProvision}
      >
        <OTypography
          type="dark"
          style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
        >
          {getValueFromMap(modalText, action)?.subtitle ||
            "Alterar status da requisição de Wallet?"}
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
