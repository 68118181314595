export const reportTypeOptions = [
  {
    label: "Comprovante e Demonstrativo",
    value: "ComprovanteDemonstrativo",
  },
  { label: "Comprovante", value: "Comprovante" },
  { label: "Demonstrativo", value: "Demonstrativo" },
  {
    label: "Planilha (Excel)",
    value: "Planilha",
  },
];

export const periodOptions = [
  { label: "Hoje", value: "Today" },
  { label: "Últimos 7 dias", value: "LastSevenDays" },
  { label: "Últimos 30 dias", value: "LastThirtyDays" },
  { label: "Últimos 60 dias", value: "LastSixtyDays" },
  { label: "Período específico", value: "CustomPeriod" },
];

export const productTypesOptions = [
  { value: "RiscoSacado", label: "Risco Sacado" },
  {
    value: "RiscoCedente",
    label: "Risco Cedente",
  },
  {
    value: "Offshore",
    label: "Offshore",
    visibleFor: "Planilha",
  },
  { value: "FIDC", label: "FIDC" },
  { value: "FundingProprio", label: "Funding Próprio" },
  { value: "CCC", label: "CCC" },
];

export const statusAntecipationOptions = [
  { label: "Todos", value: "Todos" },
  { label: "Aberto", value: "Aberto" },
  { label: "Agendado", value: "Agendado" },
  { label: "Antecipada", value: "Antecipada" },
  { label: "Aprovado", value: "Aprovado" },
  { label: "Cancelado", value: "Cancelada" },
  { label: "Pago", value: "Pago" },
  { label: "Em antecipação", value: "EmAntecipacao" },
  { label: "Em aprovação", value: "EmAprovacao" },
  { label: "Em atraso", value: "EmAtraso" },
  { label: "Em agendamento", value: "EmAgendamento" },
  { label: "Em cancelamento", value: "EmCancelamento" },
  { label: "Indisponível", value: "Indisponivel" },
  { label: "Liquidado", value: "Liquidada" },
];
