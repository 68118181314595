import { env } from "utils/environments";

export const apiEndpoints = {
  billing: {
    customers: `${env.API}/v1/billing/customers`,
  },
  companies: {
    byCustomerId: {
      billing: {
        fullInfo: (customerId: string) =>
          `${env.API}/v1/companies/${customerId}/billing/fullinfo`,
        products: (customerId: string) =>
          `${env.API}/v1/companies/${customerId}/billing/products`,
      },
      payments: {
        endpoint: (customerId: string) =>
          `${env.API}/v1/companies/${customerId}/payments`,
      },
      receiptsPayments: {
        endpoint: (customerId: string) =>
          `${env.API}/v1/companies/${customerId}/receipts-payments`,
        single: (customerId: string, receiptId: string) =>
          `${env.API}/v1/companies/${customerId}/receipts-payments/${receiptId}`,
      },
    },
  },
};
