import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import {
  CardsDashboardContext,
  useCardsDashboard,
} from "../../cards-dashboard.context";

interface InfoCardProps<T extends keyof CardsDashboardContext> {
  summaryType: T;
  render: (
    value: NonNullable<CardsDashboardContext[T]["value"]>,
  ) => JSX.Element;
}

export const InfoCard = <T extends keyof CardsDashboardContext>({
  summaryType,
  render,
}: InfoCardProps<T>) => {
  const context = useCardsDashboard();
  const { callService, hasError, loading, value } = context[summaryType];

  return (
    <OCard className="position-relative">
      <OCardBody className="h-100">
        <ContentTemplate
          hasError={hasError}
          loading={loading}
          value={value as Exclude<typeof value, undefined>}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar as informações"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => callService()} />
            </ErrorComponent>
          }
          render={render}
        />
      </OCardBody>
    </OCard>
  );
};
