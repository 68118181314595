export type FraudVerificationsStatusValues =
  | "APROVED"
  | "APROVED_ADMIN"
  | "EXPIRED"
  | "REPROVED"
  | "SERVICE_UNAVALIABLE"
  | "WAITING";

interface BadgeProperties {
  label: string;
  type: "success" | "danger" | "warning" | "info";
}

export const fraudVerificationsStatusMap: Record<
  FraudVerificationsStatusValues,
  BadgeProperties
> = {
  APROVED: { label: "Aprovado", type: "success" },
  APROVED_ADMIN: { label: "Aprovado pelo Portal Admin", type: "success" },
  EXPIRED: { label: "Expirado", type: "warning" },
  REPROVED: { label: "Reprovado", type: "danger" },
  SERVICE_UNAVALIABLE: { label: "Serviço indisponível", type: "danger" },
  WAITING: { label: "Aguardando", type: "warning" },
} as const;
