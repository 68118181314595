import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useState } from "react";
import isEqual from "lodash/isEqual";
import { service } from "services";
import { GetCompaniesByIdCedentesContractComplementResponse } from "services/quickfin/models";
import { logger } from "utils/logger";
import { Cedente } from "../../../company-cedentes.types";
import { CedenteDetailsForm } from "../../cedente-details.types";
import {
  parseContractComplement,
  parseContractComplementPayload,
  parseEmailOptions,
} from "./contract-complement.utils";
import { EmailOption } from "./contract-complement.types";

export const useCedenteContractComplement = (
  sacadoId: number,
  cedenteId: number,
) => {
  const [contractComplement, setContractComplement] =
    useState<GetCompaniesByIdCedentesContractComplementResponse>();
  const [emailsOptions, setEmailsOptions] = useState<EmailOption[]>();
  const [loadingEmails, setLoadingEmails] = useState(true);
  const [loadingContractComplements, setLoadingContractComplements] =
    useState(true);
  const { callService: addCompanyCedenteContractComplement } = useServiceCall(
    service.quickfin.addCompanyCedenteContractComplement,
  );

  const editContractComplement = async (values: CedenteDetailsForm) => {
    const parsedContractComplement = parseContractComplement(
      values.contractComplement,
    );
    const isDirty =
      !isEqual(contractComplement?.variables, parsedContractComplement) ||
      !isEqual(
        contractComplement?.inclusionList,
        values.contractComplementInclusionList,
      );

    if (isDirty) {
      const payload = parseContractComplementPayload(
        sacadoId,
        cedenteId,
        values,
        parsedContractComplement,
        values.contractComplementInclusionList,
        contractComplement,
      );
      const { success } = await addCompanyCedenteContractComplement(
        sacadoId,
        payload,
      );
      if (success) {
        OToastManager.success("Complemento de contrato atualizado com sucesso");
      } else {
        OToastManager.danger("Erro ao atualizar complementos de contrato");
      }
    }
  };

  const getElegibleEmailsForNotification = async (cedente: Cedente) => {
    try {
      setLoadingEmails(true);
      const { data } = await service.onboardingRelationships.getIrisPowersNew(
        {
          taxId: cedente.identification,
          useCache: false,
        },
        cedente.identification,
      );
      const parsedEmails = parseEmailOptions(data.powers);

      setEmailsOptions(parsedEmails);
    } catch (e) {
      logger.error(e);
      OToastManager.danger("Não foi possível obter e-mails para notificação");
    } finally {
      setLoadingEmails(false);
    }
  };

  const getCedenteContractComplement = useCallback(
    async (cedente: Cedente) => {
      try {
        if (!cedente) {
          throw Error("Can't call get contract complement before get cedente");
        }
        setLoadingContractComplements(true);
        getElegibleEmailsForNotification(cedente);

        const { data } =
          await service.quickfin.getCompanyCedenteContractComplement(
            sacadoId,
            cedente.id,
            cedente.estrategia?.tipoProduto,
          );

        setContractComplement(data);
        return data;
      } catch (e) {
        logger.error(e);
      } finally {
        setLoadingContractComplements(false);
      }
    },
    [sacadoId],
  );

  return {
    contractComplement,
    loadingContractComplements,
    editContractComplement,
    getCedenteContractComplement,
    emailsOptions,
    loadingEmails,
  };
};
