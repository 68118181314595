import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search/field/select-search-field.component";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddProposalWorkflowPage } from "./add-proposal-workflow.hook";

export const AddProposalWorkflowPage = () => {
  const {
    detailsPageTypeData,
    form,
    iconOptions,
    loading,
    loadingGetDetailsPageType,
    onSubmit,
    setValue,
  } = useAddProposalWorkflowPage();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar workflow" />}
      actions={
        <LoadingButton
          loading={loading || loadingGetDetailsPageType}
          onClick={() => onSubmit()}
        >
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form spellCheck="false">
            <ORow>
              <OCol sm={6}>
                <ORFieldInput id="name" name="name" tag="text" label="Nome" />
              </OCol>
              <OCol sm={6}>
                <ORFieldInput
                  id="tabName"
                  name="tabName"
                  tag="text"
                  label="Label da Tab"
                />
              </OCol>
              <OCol sm={12}>
                <ORFieldInput
                  id="description"
                  name="description"
                  tag="text"
                  label="Descrição"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol sm={4}>
                <ORFieldSelect
                  id="detailsPageType"
                  name="detailsPageType"
                  label="Tipo da página de detalhes"
                  labelSize="md"
                  key={detailsPageTypeData?.join("_")}
                >
                  {detailsPageTypeData?.map((detailsPageType) => (
                    <OOption
                      value={String(detailsPageType)}
                      key={`details-page-type-${detailsPageType}`}
                    >
                      <OTypography>{detailsPageType}</OTypography>
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol sm={4}>
                <SelectSearchField
                  id="icon"
                  name="icon"
                  label="Ícone"
                  placeholder="Selecionar"
                  options={iconOptions}
                  onClear={() => setValue("icon", "")}
                  maxOptions={50}
                />
              </OCol>
              <OCol sm={4}>
                <ORFieldInput
                  id="uniqueNameId"
                  name="uniqueNameId"
                  tag="text"
                  label="Unique Name Id"
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
