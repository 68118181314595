import { GetApiAdminLimitRequestsGetLimitRequestByIdResponse } from "services/hubcreditmanager/models/responses/get-api-admin-limit-requests-get-limit-request-by-id.response";
import {
  GetApiAdminAgreementResponse,
  GetApiAdminPartnerResponse,
} from "services/hubloan/models";
import { GetApiAdminLoanContractsConfigByIdResponse } from "services/hubloan/models/responses/get-api-admin-loan-contracts-config-by-id.response";

export const emprestimosRouteParams = {
  id: "id",
  loanContractsId: "loanContractsId",
  partnerName: "partnerName",
  partnerId: "partnerId",
  collateralId: "collateralId",
  type: "type",
  contractNumber: "contractNumber",
  offerId: "offerId",
} as const;

type EmprestimosParams = Record<keyof typeof emprestimosRouteParams, string>;

export type EmprestimosClienteOperacoesById = Pick<EmprestimosParams, "id">;

export type EmprestimosClienteGestorLimitesById = Pick<EmprestimosParams, "id">;

export type EmprestimosClienteOperacoesByIdAndContractNumber = Pick<
  EmprestimosParams,
  "id" | "contractNumber"
>;

export type EmprestimosProdutoGestorLimitesById = Pick<EmprestimosParams, "id">;

export type EmprestimosProdutoEmprestimosById = Pick<EmprestimosParams, "id">;

export type EmprestimosProdutoLogsById = Pick<EmprestimosParams, "id">;

export type EmprestimosProdutoGestorLimitesByIdAndByCollateralId = Pick<
  EmprestimosParams,
  "id" | "collateralId"
>;

export type EmprestimosProdutoParceirosByPartnerNameByPartnerId = Pick<
  EmprestimosParams,
  "partnerName" | "partnerId"
>;

export type EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsId =
  Pick<EmprestimosParams, "loanContractsId">;

export type EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsIdEditarRouteQueryParams =
  GetApiAdminLoanContractsConfigByIdResponse;

export type EmprestimosProdutoConveniosByIdEditarRouteQueryParams =
  GetApiAdminAgreementResponse[number];

export type EmprestimosProdutoParceiroEditarRouteQueryParams =
  GetApiAdminPartnerResponse[number];

export type EmprestimosProdutoParceiroAssociarConvenioRouteQueryParams = {
  agreementId: number;
}[];

export type EmprestimosProdutoGestorLimitesDetalhesTransacaoAdicionarRouteQueryParams =
  GetApiAdminLimitRequestsGetLimitRequestByIdResponse["currentLimit"];

export type EmprestimosProdutoCreditLineConfigurationById = Pick<
  EmprestimosParams,
  "id"
>;
