import { OIcon } from "@maestro/react";
import { EmptyState, EmptyStateProps } from "./empty-state.component";

type ErrorComponentProps = Omit<EmptyStateProps, "icon">;

export const ErrorComponent = (props: ErrorComponentProps) => {
  return (
    <EmptyState
      {...props}
      icon={
        <OIcon
          category="orq"
          icon="orq-status-error"
          size="xxxl"
          type="default"
        />
      }
    />
  );
};
