import { OIcon } from "@maestro/react";
import { FullcreenButtonBtn } from "components/fullscreen-container/fullscreen-container.styles";
import { Metric } from "../chart-card/chart-card.types";
import { exportChart } from "./export-chart-button.utils";

interface ExportChartButtonProps {
  metric: Metric;
}

export const ExportChartButton = ({ metric }: ExportChartButtonProps) => {
  return (
    <FullcreenButtonBtn
      onClick={() => exportChart(metric)}
      title="Exportar gráfico"
    >
      <OIcon category="orq" type="light" icon="orq-file" />
    </FullcreenButtonBtn>
  );
};
