import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { BatchFileTemplate } from "./batch-file-template-details.types";

export const BatchFileTemplateDetailsGenerator = (
  batchFileTemplate: BatchFileTemplate,
) => {
  return {
    details: {
      title: "Informações do Template",
      items: [
        {
          label: "Nome da coluna",
          value: batchFileTemplate?.name,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Descrição",
          value: batchFileTemplate?.description,
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Data de criação",
          value:
            dayjs(batchFileTemplate?.createdDate).format(
              "DD/MM/YYYY HH:mm:ss",
            ) ?? "-",
          grid: { xs: 12, md: 4 },
        },
        {
          label: "Última atualização",
          value:
            dayjs(batchFileTemplate?.updatedDate).format(
              "DD/MM/YYYY HH:mm:ss",
            ) ?? "-",
          grid: { xs: 12, md: 4 },
        },
      ],
    },
  } satisfies DetailsFields;
};
