import dayjs from "dayjs";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { loanProductDashboardDescriptionMap } from "./loan-product-dashboard.utils";

const defaultValues = [
  dayjs().startOf("day").toISOString(),
  dayjs().endOf("day").toISOString(),
];

export const useLoanProductDashboard = () => {
  const [amountKeyValue, setAmountKeyValue] = useState<[string, number][]>();
  const dashboard = useServiceCall(service.hubLoan.getApiAdminDashboard);

  const form = useForm({
    defaultValues: { period: defaultValues },
  });

  const [fromDate, endDate] = form.watch("period") ?? defaultValues;

  const getDashboard = dashboard.callService;

  const handleGetDashboardByDateRange = useCallback(async () => {
    const toDate = dayjs(new Date(endDate)).endOf("day").toISOString();

    getDashboard({ fromDate, toDate });
  }, [fromDate, getDashboard, endDate]);

  const memoizedAmountDescription = useMemo(
    () =>
      amountKeyValue
        ? amountKeyValue.map(
            (x) =>
              getValueFromMap(loanProductDashboardDescriptionMap, x[0])?.color,
          )
        : [],
    [amountKeyValue],
  );

  useEffect(() => {
    handleGetDashboardByDateRange();
  }, [handleGetDashboardByDateRange]);

  useEffect(() => {
    if (dashboard.value) {
      setAmountKeyValue(
        Object.entries(dashboard.value.amountByStatus).filter(
          (value) => value[1],
        ),
      );
    }
  }, [dashboard.value]);

  return {
    handleGetDashboardByDateRange,
    form,
    dashboard,
    amountKeyValue,
    memoizedAmountDescription,
  };
};
