import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { dateOnly, getValidationMessages } from "../../../../../../../../utils";
import {
  checkboxValue,
  diligenceStepFormDefaultValues,
  diligenceStepFormValidationSchema,
} from "./step-details.form";

export const useStepDetails = (stepId: string) => {
  const [stepData, setStepData] = useState<
    HubEnergy.DiligenceStepSimpleResponse | undefined
  >();

  const {
    callService: _getDiligenceStepById,
    error: stepError,
    hasError: stepHasError,
    loading: stepLoading,
    value: stepValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepById);

  const {
    callService: _updateDiligenceConsumerUnitByStepType,
    loading: submitLoading,
  } = useServiceCall(service.hubEnergy.updateDiligenceConsumerUnitByStepType);

  const form = useForm({
    defaultValues: diligenceStepFormDefaultValues,
    resolver: yupResolver(diligenceStepFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const getDiligenceStep = useCallback(
    () => _getDiligenceStepById(stepId),
    [_getDiligenceStepById, stepId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!stepValue?.response) return OToastManager.danger("No step value");

        const { success, response, error } =
          await _updateDiligenceConsumerUnitByStepType({
            status: values.status,
            expirationDate: dateOnly(values.expirationDate),
            diligenceStepId: stepValue.response.id,
            isPublic: values.isPublic.includes(checkboxValue),
          });

        if (success) {
          OToastManager.success("Step atualizado com sucesso");
          setStepData(response.data.response);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Não foi possível atualizar o step",
          );
        }
      }),
    [_updateDiligenceConsumerUnitByStepType, handleSubmit, stepValue?.response],
  );

  useEffect(() => {
    getDiligenceStep();
  }, [getDiligenceStep]);

  useEffect(() => {
    setStepData(stepValue?.response);
  }, [stepValue]);

  useEffect(() => {
    if (stepData)
      reset({
        status: stepData.status,
        expirationDate: stepData.expirationDate,
        isPublic: stepData.isPublic ? [checkboxValue] : [],
      });
  }, [reset, stepData]);

  return {
    getDiligenceStep,
    stepError,
    stepHasError,
    stepLoading,
    stepData,
    form,
    submitLoading,
    submit,
  };
};
