import { BankIconSVG } from "../financial-institution-icon.types";

export const BankCitiBankSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#004785" />
      <path
        d="M44.27 64.226l-.108.103c-1.73 1.763-3.732 2.697-5.795 2.697-4.306 0-7.432-3.23-7.432-7.688 0-4.45 3.126-7.685 7.432-7.685 2.063 0 4.065.938 5.795 2.705l.108.107 2.77-3.349-.075-.09c-2.303-2.723-5.067-4.048-8.464-4.048-3.41 0-6.526 1.148-8.772 3.217-2.44 2.24-3.729 5.4-3.729 9.143 0 3.74 1.288 6.908 3.729 9.15 2.246 2.078 5.363 3.219 8.772 3.219 3.397 0 6.16-1.324 8.464-4.047l.074-.085-2.77-3.35zm5.568 7.026h4.892V47.367h-4.892v23.885zm24.058-5.194c-1.306.795-2.52 1.194-3.612 1.194-1.58 0-2.295-.834-2.295-2.692V51.886h4.983V47.39H67.99v-7.432l-4.795 2.566v4.866h-4.137v4.497h4.137v13.482c0 3.673 2.176 6.182 5.424 6.248 2.205.043 3.534-.614 4.342-1.094l.047-.035 1.178-4.606-.29.177zm4.052 5.194h4.896V47.367h-4.896v23.885z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.774 43.129C81.296 36.785 73.858 33 66.29 33c-7.564 0-15.004 3.785-19.475 10.129l-.23.326h5.64l.062-.067c3.839-3.962 8.85-6.057 14.003-6.057 5.155 0 10.164 2.095 14.01 6.057l.062.067H86l-.226-.326z"
        fill="#D9261C"
      />
    </svg>
  );
};
