import {
  OCol,
  OExpanderContent,
  OOption,
  ORFieldInputDateRange,
  ORFieldSelect,
  ORow,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { MultiInput } from "components/multi-input";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReportsProps } from "../../request-for-reports.types";
import { groupingOptions } from "./custom-options.utils";

export const CustomOptions = ({ isExcel }: ReportsProps) => {
  const [showCustomOptions, setShowCustomOptions] = useState(false);

  const form = useFormContext();
  const { setValue } = form;

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div>
          <OTypography type="dark" className="mb-1">
            Opções avançadas de filtro do relatório
          </OTypography>
          <OTypography type="secondary" size="sm">
            Habilite as opcões para gerar relatórios específicos de clientes,
            fornecedores ou pagadores e outras configurações.
          </OTypography>
        </div>
        <OSwitch
          value={!!showCustomOptions}
          size="xs"
          onInput={() => setShowCustomOptions(!showCustomOptions)}
        />
      </div>

      <OExpanderContent expanded={!!showCustomOptions}>
        <ORow className="w-100 mb-3">
          <OCol sm={6} className="d-flex flex-column">
            <ORFieldSelect
              dataAction="relatorios_em_lote:select:tipo_de_agrupamento"
              dataLabel="tipo_de_agrupamento"
              id="groupBy"
              name="groupBy"
              placeholder="Selecione"
              selectLabel="Tipo de agrupamento"
              label="Tipo de agrupamento"
            >
              {groupingOptions.map((option) => (
                <OOption key={option.value} value={option.value}>
                  {option.label}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol sm={6} className="d-flex flex-column">
            <MultiInput
              id="receivableIdentification"
              name="receivableIdentification"
              placeholder="Insira o número"
              dataAction="relatorios_em_lote:select:numero_da_nota"
              dataLabel="numero_da_nota"
              label="Número da nota"
              onChange={(value: string[]) =>
                setValue("receivableIdentification", value)
              }
            />
          </OCol>
        </ORow>

        <ORow className="w-100 mb-4">
          <OCol sm={6} className="d-flex flex-column">
            <MultiInput
              id="receivableId"
              name="receivableId"
              placeholder="Insira o número"
              dataAction="relatorios_em_lote:select:receivable_id"
              dataLabel="receivable_id"
              label="Receivable Id"
              onChange={(value: string[]) => setValue("receivableId", value)}
            />
          </OCol>
          <OCol sm={6} className="d-flex flex-column">
            <MultiInput
              id="tradeId"
              name="tradeId"
              placeholder="Insira o número"
              dataAction="relatorios_em_lote:select:trade_id"
              dataLabel="trade_id"
              label="Trade Id"
              onChange={(value: string[]) => setValue("tradeId", value)}
            />
          </OCol>
        </ORow>

        <ORow className="w-100 mb-4">
          <OCol sm={6} className="d-flex flex-column">
            <MultiInput
              id="loteId"
              name="loteId"
              placeholder="Insira o número"
              dataAction="relatorios_em_lote:select:numero_da_lote"
              dataLabel="numero_da_lote"
              label="Número do lote"
              onChange={(value: string[]) => setValue("loteId", value)}
            />
          </OCol>
          <OCol sm={6} className="d-flex flex-column">
            <MultiInput
              id="operationId"
              name="operationId"
              placeholder="Insira o número"
              dataAction="relatorios_em_lote:select:numero_da_operacao"
              dataLabel="numero_da_operacao"
              label="Número da operação"
              onChange={(value: string[]) => setValue("operationId", value)}
            />
          </OCol>
        </ORow>

        {isExcel && (
          <ORow className="w-100 mb-4">
            <OCol sm={6} className="d-flex flex-column">
              <ORFieldInputDateRange
                id="disbursementDate"
                name="disbursementDate"
                aspect="flushed"
                label="Data de desembolso"
                placeholder={[
                  "Data de desembolso inicial",
                  "Data de desembolso final",
                ]}
              />
            </OCol>
            <OCol sm={6} className="d-flex flex-column">
              <ORFieldInputDateRange
                id="maturityDate"
                name="maturityDate"
                aspect="flushed"
                label="Data de vencimento"
                placeholder={[
                  "Data de vencimento inicial",
                  "Data de vencimento final",
                ]}
              />
            </OCol>
          </ORow>
        )}
      </OExpanderContent>
    </>
  );
};
