import { ODropdownItem } from "@maestro/react";
import styled from "styled-components";

export const DropdownItemWithDisabled = styled(ODropdownItem)`
  &.dropdown-item-disabled.o-dropdown-item {
    cursor: not-allowed;
    background-color: var(--theme-light) !important;

    &:hover,
    &:active,
    &:focus {
      background-color: var(--theme-light) !important;
      outline: none;
    }

    o-typography,
    o-icon {
      opacity: var(--disabled-opacity);
    }
  }
`;
