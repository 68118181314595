export interface EmailOption {
  name: string;
  email: string;
  taxId: string;
}

export enum PowerType {
  Groups = "GROUPS",
  Representatives = "REPRESENTATIVES",
}
