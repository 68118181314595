import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("America/Sao_Paulo");

export enum DateFormatType {
  ApiFormat = "YYYY-MM-DD",
  ViewFormat = "DD/MM/YYYY",
  TimeFormat = "HH:mm:ss",
}

const formatDate = (
  dateFormatType: DateFormatType,
  date: string | dayjs.Dayjs = "",
) => {
  if (dayjs.isDayjs(date)) date.format(dateFormatType);
  const currentDate = date ? dayjs(date) : dayjs().startOf("day");
  return currentDate.format(dateFormatType);
};

export const DateUtils = { formatDate };
