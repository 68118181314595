import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useCallback, useState } from "react";
import { service } from "services";
import { LoanData } from "../loan-summary.types";

interface CancelLoanProps {
  loanData?: LoanData | undefined;
  getLoanData: (id: string) => Promise<unknown>;
}

const cancelStatus = [
  "GeracaoDeContrato",
  "Criado",
  "PendenteAssinatura",
  "Simulado",
];

export const CancelLoan = ({ loanData, getLoanData }: CancelLoanProps) => {
  const [loadingCancel, setLoadingCancel] = useState(false);

  const cancelLoan = useCallback(async () => {
    if (!loanData) return;
    try {
      setLoadingCancel(true);
      await service.hubLoan.cancelLoan(loanData.id);

      OToastManager.success("Empréstimo cancelado com sucesso");

      getLoanData(loanData.id.toString());
    } catch {
      OToastManager.danger("Houve um erro ao cancelar. Tente novamente.");
    } finally {
      setLoadingCancel(false);
    }
  }, [getLoanData, loanData]);

  return (
    <>
      {!!loanData && cancelStatus.includes(loanData.status) && (
        <ButtonWithConfirmation
          type="danger"
          confirmationModal={{
            title: "Confirmar ação",
            loading: loadingCancel,
          }}
          onConfirm={cancelLoan}
          modalBody={
            <OTypography>
              A contratação será cancelada e o progresso atual será perdido. A
              oferta será reestabelecida caso ainda esteja disponível.
            </OTypography>
          }
        >
          Cancelar contratação
        </ButtonWithConfirmation>
      )}
    </>
  );
};
