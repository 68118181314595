import { OButton, OIcon, OTypography } from "@maestro/react";
import { withRole } from "../with-role";
import {
  AlignedIcon,
  AlignedLoader,
  AlignedTypography,
} from "./icon-button.styles";

interface IconButtonProps extends React.ComponentProps<typeof OButton> {
  icon: React.ComponentProps<typeof OIcon>;
  typography?: React.ComponentProps<typeof OTypography>;
  loading?: boolean;
  children?: React.ReactNode;
}

export const IconButton = withRole(
  ({
    children,
    icon: iconProps,
    typography: typographyProps,
    loading,
    ...buttonProps
  }: IconButtonProps) => (
    <OButton {...buttonProps} disabled={loading || buttonProps.disabled}>
      <AlignedTypography {...typographyProps} tag="span">
        <span>
          {loading ? (
            <AlignedLoader type="current-color" size="xs" />
          ) : (
            <AlignedIcon {...iconProps} />
          )}
        </span>

        {children}
      </AlignedTypography>
    </OButton>
  ),
);

IconButton.displayName = "IconButton";
