import { useServiceCall } from "hooks/service-call";
import { service } from "services";

export const useReprocessNotification = () => {
  const {
    value: workflow,
    callService: reprocessNotificationStatus,
    hasError,
    loading,
  } = useServiceCall(service.onboardingCoreAccount.reprocessNotificationStatus);

  const {
    callService: changeStatusNotificationStatus,
    hasError: hasErrorChangeStatusNotifcation,
    loading: loadingChangeStatusNotifcation,
  } = useServiceCall(
    service.onboardingCoreAccount.changeStatusNotificationStatus,
  );

  return {
    workflow,
    loading,
    hasError,
    reprocessNotificationStatus,
    hasErrorChangeStatusNotifcation,
    loadingChangeStatusNotifcation,
    changeStatusNotificationStatus,
  };
};
