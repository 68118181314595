import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useNavigate } from "react-router-dom";

export const REDIRECT_MODAL = "redirect-modal";

export const RedirectSignatureModal = () => {
  const navigate = useNavigate();

  return (
    <OModal
      className="modal-show text-center"
      id={REDIRECT_MODAL}
      position="center"
      backdrop={false}
      size="xs"
    >
      <OModalHeader>
        <OTypography size="lg">Assinatura dos contratos</OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div>
            <OTypography size="md">
              Os contratos estão sendo gerados e serão enviados para aprovação
              do cliente. Você pode acompanhá-los em &quot;Operações&quot;.
            </OTypography>
          </div>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-2 align-items-center">
          <OButton
            type="light"
            onClick={() => navigate("/emprestimos/cliente/ofertas")}
          >
            Voltar
          </OButton>
          <OButton
            type="dark"
            onClick={() => {
              modalManager.hide(REDIRECT_MODAL);
              navigate("/emprestimos/cliente/operacoes");
            }}
          >
            Ir para Operações
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
