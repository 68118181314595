import { OCol, ORFieldInput } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

interface InterestArrearsRateFieldProps {
  required?: boolean;
  disabled?: boolean;
}

export const InterestArrearsRateField = ({
  required,
  disabled,
}: InterestArrearsRateFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldInput
        dataAction="geral:texto:mora"
        dataLabel="mora"
        id="interestArrearsRate"
        name="interestArrearsRate"
        tag="percentage"
        label={EstrategiaFormLabels.interestArrearsRate}
        placeholder="Preencher"
        required={required}
        disabled={disabled}
      />
    </OCol>
  );
};
