import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

export const adminACCContingencySettlementsODataGrid = {
  datagrid: {
    noDataText: "Nenhuma liquidação encontrada",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar liquidações.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.acc.odata.adminACCSettlementsOData,
    ),
  },
  columns: [
    {
      type: "buttons",
      allowExporting: false,
      role: roles.acc.customer.settlementsPage.role,
      cellRender: ({ data }) =>
        data.preTicketCounterpartyIdentification && (
          <DetailsLink
            href={buildCustomerLink(
              data.preTicketCounterpartyIdentification ?? "",
              corporateRouter.routes.acc.customer.settlements.details.path({
                id: data.preTicketId,
              }),
            )}
          />
        ),
    },
    {
      dataField: "preTicketId",
      caption: "PreTicketId",
      dataType: "number",
      sortOrder: "desc",
    },
    {
      caption: "Nome",
      dataField: "preTicketCounterpartyName",
    },
    {
      caption: "Contrato",
      dataField: "contract",
    },
    {
      dataField: "proposalAmount",
      caption: "Valor da proposta",
      alignment: "left",
      cellRender: ({ data }) => {
        return (
          <OTypography>
            {masks.currency(
              data.proposalAmount,
              data.preTicketSellCurrencyType,
            )}
          </OTypography>
        );
      },
    },
    {
      caption: "Pagador",
      dataField: "payer",
    },
    {
      caption: "Moeda",
      dataField: "preTicketSellCurrencyType",
    },
    {
      caption: "Identificação",
      dataField: "preTicketCounterpartyIdentification",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Natureza",
      dataField: "preTicketNatureDescription",
    },
    {
      caption: "FlowType",
      dataField: "preTicketFlowType",
    },
    {
      caption: "Valor total",
      dataField: "preTicketAmount",
      alignment: "left",
      cellRender: ({ data }) => {
        return (
          <OTypography>
            {masks.currency(
              data.preTicketAmount,
              data.preTicketSellCurrencyType,
            )}
          </OTypography>
        );
      },
    },
    {
      caption: "Valor disponível",
      dataField: "preTicketFxAvailableAmount",
      alignment: "left",
      cellRender: ({ data }) => {
        return (
          <OTypography>
            {masks.currency(
              data.preTicketFxAvailableAmount,
              data.preTicketSellCurrencyType,
            )}
          </OTypography>
        );
      },
    },
    {
      caption: "Juros",
      dataField: "fixedInterestValue",
      cellRender: ({ data }) => {
        return (
          <OTypography>
            {masks.currency(
              data.fixedInterestValue,
              "R$",
            )}
          </OTypography>
        );
      },
    },
    {
      caption: "Juros em moeda estrangeira",
      dataField: "foreignFixedInterestValue",
    },
    {
      caption: "Status",
      dataField: "preTicketStatus",
    },
    {
      role: roles.acc.product.settlementsContingencyFormPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          href={`${
            corporateRouter.routes.acc.product.contingency.settlements.update
              .path
          }?data=${encodeURIComponent(JSON.stringify(data))}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.odata.getAccSettlements>
    >["data"]["value"]
  >[number]
>;
