import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import {
  FopaDetails,
  MassifyPage,
  PaymentDetailsPage,
  PaymentsPage,
} from "../pages";
import { fopaRoles } from "../roles/folha-de-pagamento.roles";

export const fopaRoutes = {
  customer: {
    name: "Folha de Pagamento",
    path: "/folha-de-pagamento/cliente",
    element: <RedirectWithRoles />,
    children: {
      details: {
        name: "Detalhes",
        path: "/folha-de-pagamento/cliente/detalhes",
        element: <FopaDetails />,
        role: fopaRoles.customer.detailsPage.role,
      },
      payments: {
        name: "Pagamentos",
        path: "/folha-de-pagamento/cliente/pagamentos",
        element: <PaymentsPage />,
        role: fopaRoles.customer.paymentsPage.role,
        children: {
          paymentDetails: {
            name: "Detalhes do pagamento",
            path: "/folha-de-pagamento/cliente/pagamentos/detalhes-do-pagamento",
            element: <PaymentDetailsPage />,
            role: fopaRoles.customer.paymentsPaymentDetailsPage.role,
          },
        },
      },
      onboardings: {
        name: "Onboarding Funcionários",
        path: "/folha-de-pagamento/cliente/lotes",
        element: <MassifyPage />,
        role: fopaRoles.customer.onboardingsPage.role,
      },
    },
  },
} as const satisfies Routes;
