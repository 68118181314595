import { BankIconSVG } from "../financial-institution-icon.types";

export const BankHsbcSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={56}
        cy={56}
        r={55.25}
        fill="#fff"
        stroke="#E9EBEF"
        strokeWidth={1.5}
      />
      <path
        d="M86 56.026L71.009 41v30.018L86 56.026zm-29.982 0L71.008 41H41.027l14.992 15.026zm-30.018 0l15.026 14.992V41L26 56.026z"
        fill="#DB0011"
      />
      <path
        d="M56.018 56.026L41.026 71.018H71.01L56.018 56.026z"
        fill="#DB0011"
      />
    </svg>
  );
};
