import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { RefreshGridButton } from "components/refresh-grid-button";
import { EmprestimosClienteOperacoesById } from "../../../../../routes/emprestimos.route-params";
import { useOperationsInstallments } from "../parcelas/operations-installments.context";
import { operationsPaymentsGrid } from "./operations-payments.grid";

export const OperationsPaymentsComponent = () => {
  const { id } = useParams<EmprestimosClienteOperacoesById>();
  if (!id) throw new Error("No id");

  const { loading, sharkSettlement, refresh } = useOperationsInstallments();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pagamentos" />}
      actions={<RefreshGridButton onClick={refresh} />}
    >
      <ODataGridGenerator
        loading={loading}
        grid={operationsPaymentsGrid}
        dataSource={sharkSettlement?.payments ?? []}
      />
    </GridTemplate>
  );
};
