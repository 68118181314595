import { ISizes } from "@maestro/core";
import { masks } from "@maestro/utils";
import { PostAdminWorkflowDashboardGetSelectedOriginatorsResponse } from "services/hubcreditmanager/models/responses";

type DataSource<T> = {
  header: string;
  footer: string;
  dataField: keyof T;
  colLg?: ISizes;
  cellRender?: (props: { data: T }) => JSX.Element;
  format?: (value: keyof T) => string;
}[];

export const workflowDashboardMap: DataSource<PostAdminWorkflowDashboardGetSelectedOriginatorsResponse> =
  [
    {
      dataField: "requestTotal",
      header: "Valor total solicitado",
      footer: "Qtd. solicitada no período",
      format: (value) => masks.currency(value, "R$"),
      colLg: 3,
    },
    {
      dataField: "approvedTotal",
      header: "Valor total aprovado",
      footer: "Qtd. aprovada no período",
      format: (value) => masks.currency(value, "R$"),
      colLg: 3,
    },
    {
      dataField: "disbursedTotal",
      header: "Valor desembolsado",
      footer: "Qtd. desembolsada no período",
      format: (value) => masks.currency(value, "R$"),
      colLg: 3,
    },
    {
      dataField: "conversionTax",
      header: "Taxa de conversão",
      footer: "Conversão no período",
      format: (value) => masks.percentage(value),
      colLg: 3,
    },
  ];
