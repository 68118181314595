import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoLeadByIdDocumentsByDocumentId } from "../../../../../../routes/workflow.route-params";
import { WorkflowPendencyForm } from "../../pendencias/adicionar/workflow-pendency-form.component";
import {
  WorkflowPendencyFormValues,
  workflowPendencyValidationSchema,
} from "../../pendencias/adicionar/workflow-pendency-form.schemas";

export const WorkflowDocumentPendencyPage = () => {
  const form = useForm<WorkflowPendencyFormValues>({
    resolver: yupResolver(workflowPendencyValidationSchema),
  });
  const { reset } = form;

  const { id, documentId } =
    useParams<WorkflowProdutoLeadByIdDocumentsByDocumentId>();

  useEffect(() => {
    reset({
      type: "document",
      documentId: Number(documentId),
    });
  }, [documentId, reset]);

  return (
    <FormTemplate pageTitle={<PageTitle title="Pendenciar documento" />}>
      <FormProvider {...form}>
        <WorkflowPendencyForm leadId={Number(id)} />
      </FormProvider>
    </FormTemplate>
  );
};
