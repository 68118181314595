import { modalManager } from "@maestro/core";
import { OButton } from "@maestro/react";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGrid } from "devextreme-react";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { MonitoringFilterModal, StatusCarousel } from "./_compose";
import {
  buildFilter,
  monitoringFilterModalId,
} from "./monitoring-template.utils";

interface MonitoringTemplateProps {
  filterFields: HubOnboardingRelationships.Odata.MotinoringFilterField[];
  gridBuilder: (
    filter: (string | string[])[] | null,
  ) => ODataGridGeneratorConfig<any>;
  gridRef: React.RefObject<DataGrid<any>>;
  initialFilter?: Record<string, string>;
  relationship: string;
}

export const MonitoringTemplate = ({
  filterFields,
  gridBuilder,
  gridRef,
  initialFilter,
  relationship,
}: MonitoringTemplateProps) => {
  const [activeStatus, setActiveStatus] = useState<string>("TODOS");
  const [filter, setFilter] = useState<Record<string, string>>({});

  const grid = useMemo(
    () => gridBuilder(buildFilter(activeStatus, filter, initialFilter)),
    [activeStatus, filter, gridBuilder, initialFilter],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <StatusCarousel
        activeStatus={activeStatus}
        relationship={relationship}
        setActiveStatus={setActiveStatus}
      />
      <GridTemplate
        actions={
          <OButton onClick={() => modalManager.show(monitoringFilterModalId)}>
            Abrir filtro
          </OButton>
        }
        gridRef={gridRef}
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator gridRef={gridRef} grid={grid} />
      </GridTemplate>
      <MonitoringFilterModal fields={filterFields} setFilter={setFilter} />
    </div>
  );
};
