import * as yup from "yup";

export interface BeneficiaryBankFormValues {
  Name: string;
  Country: string;
  Swift?: string | null;
  FedWire?: string | null;
}

export const beneficiaryBankFormValidationSchema: yup.ObjectSchema<BeneficiaryBankFormValues> =
  yup.object({
    Name: yup.string().required("Este campo é obrigatório"),
    Country: yup.string().required("Este campo é obrigatório"),
    FedWire: yup
      .string()
      .test(
        "fedWireAndSwift",
        "Você deve preencher o FedWire e/ou Swift",
        (value, context) => {
          return (
            context.parent.Country !== "US" || value || context.parent.Swift
          );
        },
      )
      .nullable(),
    Swift: yup
      .string()
      .test(
        "same-country",
        "O SWIFT informado não pertence ao país selecionado",
        (value, context) =>
          !value || value.substring(6, 4) === context.parent.Country,
      )
      .test(
        "swiftRequiredWhenCountryIsNotUS",
        "Este campo é obrigatório",
        (value, context) => {
          return (
            (context.parent.Country !== "US" && !!value) ||
            context.parent.Country === "US"
          );
        },
      )
      .test(
        "fedWireAndSwift",
        "Você deve preencher o FedWire e/ou Swift",
        (value, context) => {
          return value || context.parent.FedWire;
        },
      )
      .nullable(),
  });
