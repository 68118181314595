import { OConfirmationModal } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { useSettlementStrategies } from "./settlement-strategies.hook";

export const SettlementStrategies = () => {
  const { dataSource, deleteSettlement, grid, loading } =
    useSettlementStrategies();

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Estratégias de liquidação"
          description="Gerenciamento de estratégias de liquidação"
        />
      }
      actions={
        <StandardLink
          href={
            corporateRouter.routes.recebiveis.product.settlementStrategies.add
              .path
          }
          role={roles.recebiveis.product.settlementStrategiesAddPage.role}
        >
          Adicionar
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        loading={loading}
        grid={grid}
        dataSource={dataSource}
      />
      <OConfirmationModal
        modalId="delete-settlement-modal"
        title="Tem certeza que deseja deletar esse registro?"
        onConfirm={deleteSettlement}
      />
    </GridTemplate>
  );
};
