import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { Offer } from "../hired-offers.types";
import { StatusIcon } from "../../../../components/status-icon";

export const buildOfferDetails = (offer: Offer): DetailsFields => ({
  status: {
    items: [
      {
        label: "Contrato assinado",
        value: (
          <StatusIcon status={offer.isContractSigned ? "success" : "danger"} />
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Devedores solidários",
        value: (
          <StatusIcon
            status={offer.isGuarantorsOnboarded ? "success" : "danger"}
          />
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Em processo de contratação",
        value: (
          <StatusIcon status={offer.isOnHiringProcess ? "success" : "danger"} />
        ),
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  hiringData: {
    title: "Informações preenchidas na contratação",
    visible: !!offer.metaData,
    items: [
      {
        label: "Nome impresso",
        value: offer.metaData?.printedName,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Contato",
        value: masks.phone(offer.metaData?.phone),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Documento",
        value: masks.cpf(offer.metaData?.ownerDocument),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Dia de vencimento da fatura",
        value: offer.metaData?.issueDate.day,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Limite contratado",
        value: masks.currency(offer.metaData?.hiredAmount, currencySymbol.BRL),
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
