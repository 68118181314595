import { corporateRouter } from "routes/corporate-router.context";
import { Offer } from "./hired-offers.types";

export const offerSearchParam = "offer-data";

export const buildDetailsLink = (data: Offer) => {
  const { routes } = corporateRouter;
  const baseUrl = routes.cartoes.product.hiredOffers.offerDetails.path;

  const stringifiedOffer = JSON.stringify(data);
  const searchParams = new URLSearchParams();
  searchParams.set(offerSearchParam, stringifiedOffer);

  return baseUrl + "?" + searchParams.toString();
};

export const hiredOffersStatusMap = {
  CREATED: { label: "Criado", type: "info" as const },
  HIRED: { label: "Contratado", type: "success" as const },
  EXPIRED: { label: "Expirado", type: "danger" as const },
  CANCELED: { label: "Cancelado", type: "danger" as const },
  PENDING_SIGNATURE: {
    label: "Assinatura pendente",
    type: "warning" as const,
  },
  PENDING_GUARANTORS_ONBOARD: {
    label: "Pendente devedores solidários ",
    type: "warning" as const,
  },
};
