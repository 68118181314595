import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useOriginator = () => {
  const {
    customer: { identification },
  } = useCustomer();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [originatorData, setOriginatorData] =
    useState<Awaited<ReturnType<typeof service.b2bCore.originator>>["data"]>();

  const fetchOriginator = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await service.b2bCore.originator(identification);
      setOriginatorData(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [identification]);

  useEffect(() => {
    fetchOriginator();
  }, [fetchOriginator]);

  return {
    loading,
    hasError,
    originatorData,
    fetchOriginator,
  };
};
