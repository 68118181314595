import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services/service";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ConfigureWorkTimeForm } from "./_compose";

export const LimiteMaisWorkingTime = () => {
  const {
    callService: getOperations,
    hasError,
    loading,
    value: operations,
  } = useServiceCall(service.adminBankinghub.getOperations);

  useEffect(() => {
    getOperations();
  }, [getOperations]);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle title="Configuração de horário de operação de Limite da conta" />
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={operations}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível buscar os horários de operação"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => getOperations()} />
          </ErrorComponent>
        }
        render={(_operations) => (
          <div className="d-flex flex-column gap-5">
            {_operations.map((operation) => (
              <ConfigureWorkTimeForm key={operation.id} operation={operation} />
            ))}
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
