import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import {
  DocumentTypeSelector,
  PropertyTypeSelector,
} from "../../../edit-step-property-modal/_compose";
import {
  checkboxValue,
  moveStepPropertyFormFields,
} from "../../move-step-property-modal.form";
import { DestinationStepSelector } from "../destination-step-selector";

interface MoveStepPropertyFieldsProps {
  idPrefix: string;
}

export const MoveStepPropertyFields = ({
  idPrefix,
}: MoveStepPropertyFieldsProps) => {
  return (
    <div>
      <ORow>
        <OCol xs={12}>
          <DestinationStepSelector id={`${idPrefix}-destinationStepId`} />
        </OCol>
      </ORow>
      <ORow align="end">
        <OCol xs={12} md={6}>
          <PropertyTypeSelector id={`${idPrefix}-typeId`} />
        </OCol>
        <OCol xs={12} md={6}>
          <DocumentTypeSelector id={`${idPrefix}-documentTypeId`} />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput {...moveStepPropertyFormFields.minimumAmount} />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldCheckboxGroup {...moveStepPropertyFormFields.isPublic}>
            <div className="d-flex align-items-center gap-2">
              <OCheckbox
                id={`${idPrefix}-is-public-checkbox`}
                value={checkboxValue}
                size="sm"
              />
              <OLabel htmlFor={`${idPrefix}-is-public-checkbox`}>
                Público?
              </OLabel>
            </div>
          </ORFieldCheckboxGroup>
        </OCol>
      </ORow>
    </div>
  );
};
