import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

interface DeskAccountInfoFormProps {
  editOnly?: boolean;
}

export const DeskAccountInfoForm = ({ editOnly }: DeskAccountInfoFormProps) => {
  const {
    callService: getDeskOnboardingAccountInfoEnums,
    loading,
    value: enums,
    hasError,
  } = useServiceCall(service.onboarding.getDeskOnboardingAccountInfoEnums);

  useEffect(() => {
    getDeskOnboardingAccountInfoEnums();
  }, [getDeskOnboardingAccountInfoEnums]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                disabled={editOnly}
                label="Agencia"
                tag="text"
                id="agency"
                name="agency"
              />
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                disabled={editOnly || loading}
                id="accountType"
                name="accountType"
                label="Tipo de conta"
                placeholder="Selecione"
                key={enums?.accountTypes?.length}
              >
                {enums?.accountTypes?.map((accounType) => (
                  <OOption key={accounType} value={accounType}>
                    {accounType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loading && <OLoader className="d-block" size="sm" />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDeskOnboardingAccountInfoEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <ORFieldInput
              label="Descrição"
              tag="text"
              id="accountDescription"
              name="accountDescription"
            />
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Id tipo da conta"
                tag="number"
                id="idAccountType"
                name="idAccountType"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Id subtipo da conta"
                tag="number"
                id="idAccountSubtype"
                name="idAccountSubtype"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Id serviço da conta"
                tag="number"
                id="idAccountService"
                name="idAccountService"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
