import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import {
  modalColumnFormDefaultValues,
  modalFormValidationSchema,
} from "./modal-form.schemas";
import { ConfigurableColumn } from "../../batch.types";

interface ComponentDetailsProps {
  column: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getBatchConfigurableColumns>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getBatchConfigurableColumns>
      >["data"]
    >
  >;
  selectedColumn: ConfigurableColumn | undefined;
}

export const CONFIGURABLE_COLUMN_MODAL_ID = "configurable-column-modal";

export const Modal = ({ selectedColumn, column }: ComponentDetailsProps) => {
  const form = useForm({
    resolver: yupResolver(modalFormValidationSchema),
    defaultValues: modalColumnFormDefaultValues,
  });

  const { reset, getValues } = form;

  const { hide } = modalManager;

  const { callService: getColumnDetails } = column;

  const {
    callService: editConfigurableColumn,
    loading: editComponentPropertyLoading,
  } = useServiceCall(service.hubCreditManager.editConfigurableBatchColumns);

  useEffect(() => {
    if (selectedColumn) {
      reset({
        description: selectedColumn.description,
        name: selectedColumn.name,
      });
    } else {
      reset(modalColumnFormDefaultValues);
    }
  }, [reset, selectedColumn, getValues]);

  const editColumn = useCallback(
    async (columnId: number) => {
      const { description, name } = getValues();

      const { success } = await editConfigurableColumn({
        name,
        description,
        ConfigurableBatchColumnId: columnId,
      });

      if (success) {
        OToastManager.success("Coluna editada com sucesso.");
        hide(CONFIGURABLE_COLUMN_MODAL_ID);

        getColumnDetails();
      } else OToastManager.danger("Não foi possível editar a coluna.");
    },
    [getValues, editConfigurableColumn, hide, getColumnDetails],
  );

  return (
    <OModal id={CONFIGURABLE_COLUMN_MODAL_ID} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography
          tag="h1"
          size="xxl"
          key={`property-modal-title-${selectedColumn}`}
        >
          Editar coluna
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORFieldInput
            id="name"
            name="name"
            tag="text"
            label="Nome"
            tooltip="Ex.=NOME_COLUNA"
            tooltipPosition="top-right"
            labelSize="lg"
            dataAction="editar_coluna_configuravel_batch:texto:nome"
            dataLabel="nome"
          />
          <ORFieldInput
            id="description"
            name="description"
            tag="text"
            label="Descrição"
            labelSize="lg"
            dataAction="editar_coluna_configuravel_batch:texto:descricao"
            dataLabel="descricao"
          />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="editar_coluna_configuravel_batch:botao:fechar"
            dataLabel="fechar"
            outline
            onClick={() => {
              modalManager.hide(CONFIGURABLE_COLUMN_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <LoadingButton
            loading={selectedColumn ? editComponentPropertyLoading : false}
            dataAction="editar_coluna_configuravel_batch:botao:adicionar"
            dataLabel="adicionar"
            onClick={async () => {
              if (selectedColumn) editColumn(selectedColumn.id);
            }}
          >
            Editar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
