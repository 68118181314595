import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { logger } from "utils/logger";
import { LoanStatus } from "../../../ofertas/offers.type";

export const disbursementsGrid = (
  disbursed: boolean,
  hasEditRole: boolean,
  loanStatus?: LoanStatus,
) =>
  ({
    datagrid: {
      noDataText:
        "Não foram encontrados desembolsos associados a este empréstimo.",
      editing: {
        allowUpdating:
          loanStatus === LoanStatus.Invalido && !disbursed && hasEditRole,
      },
      onRowUpdated: async (e) => {
        try {
          await service.hubLoan.updateDisbursementValue({
            disbursementId: e.data.id,
            value: e.data.disbursementValue,
            accountNumber: e.data.account.accountNumber,
            accountDigit: e.data.account.accountDigit ?? null,
            accountHolder: String(e.data.account.acccountHolder),
            taxIdHolder: String(e.data.account.taxIdHolder),
            agency: e.data.account.agency,
          });
        } catch (error) {
          logger.error(error);
          OToastManager.danger(
            "Um erro ocorreu ao tentarmos atualizar os dados do desembolso.",
          );
        }
      },
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        allowEditing: false,
      },
      {
        caption: "Valor do desembolso",
        dataField: "disbursementValue",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        caption: "Tipo",
        dataField: "type",
        allowEditing: false,
      },
      {
        caption: "Proprietário da conta",
        dataField: "account.acccountHolder",
      },
      {
        caption: "CPF/CNPJ",
        dataField: "account.taxIdHolder",
      },
      {
        caption: "Número da conta",
        dataField: "account.accountNumber",
      },
      {
        caption: "Dígito da conta",
        dataField: "account.accountDigit",
      },
      {
        caption: "Agência",
        dataField: "account.agency",
      },
      {
        caption: "Ações",
        visible:
          loanStatus === LoanStatus.Invalido && !disbursed && hasEditRole,
        buttons: ["edit", "save", "cancel"],
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<
        ReturnType<typeof service.hubLoan.getLoanDetails>
      >["data"]["disbursements"]
    >[number]
  >);
