import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { StatusBadge } from "components/status-badge";
import dayjs from "dayjs";
import { getValueFromMap } from "utils/get-value-from-map";
import { ReconResult } from "../reconciliations.types";
import { reconResultStatusMap } from "../reconciliations.utils";

export const buildReconciliationDetailsFields = (
  reconResult: ReconResult,
): DetailsFields => {
  const { label, type } = getValueFromMap(
    reconResultStatusMap,
    reconResult.status,
  ) ?? {
    label: reconResult.status,
    type: "warning",
  };

  return {
    company: {
      items: [
        {
          label: "Descrição",
          value: reconResult.description,
          grid: { sm: 12, md: 12, lg: 12 },
        },
        {
          label: "Status",
          value: <StatusBadge type={type}>{label}</StatusBadge>,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Data",
          value: dayjs(reconResult.creationDate).format("DD/MM/YYYY"),
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Área",
          value: reconResult.area,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Perfil",
          value: reconResult.profile,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Produto",
          value: reconResult.product,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Categoria",
          value: reconResult.category,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Conta",
          value: reconResult.account,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Quantidade de operações",
          value: reconResult.operationQuantity,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Quantidade de Breaks",
          value: reconResult.breakQuantity,
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Total esperado",
          value: masks.currency(reconResult.expectedValue, "R$") || "-",
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Total Break",
          value: masks.currency(reconResult.diff, "R$") || "-",
          grid: { sm: 6, md: 4, lg: 3 },
        },
        {
          label: "Total reconciliado",
          value: masks.currency(reconResult.value, "R$") || "-",
          grid: { sm: 6, md: 4, lg: 3 },
        },
      ],
    },
  };
};
