import { Dispatch, SetStateAction } from "react";
import { modalManager } from "@maestro/core";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { DataGridAction } from "components/datagrid-action";
import { IntegrationFileProcessor } from "./integrations.form";
import { IntegrationModalName } from "./compose/enums/integrationModalName";
import { IntegrationStatus } from "./compose/enums/integrationStatus";

export const listIntegrationGrid = (
  setIntegration: Dispatch<SetStateAction<IntegrationFileProcessor>>,
): ODataGridGeneratorConfig<IntegrationFileProcessor> => {
  return {
    datagrid: {
      noDataText: "Nenhuma integração encontrado.",
      pager: {
        showInfo: true,
        visible: true,
        infoText: "Página {0} de {1} ({2} integrações)",
        showNavigationButtons: true,
      },
      remoteOperations: true,
    },
    columns: [
      {
        dataField: "companyDocument",
        caption: "Empresa",
        cellRender: ({ data }) => data.companyDocument,
      },
      {
        dataField: "type",
        caption: "Tipo",
      },
      {
        dataField: "createdAt",
        caption: "Criado em",
        dataType: "datetime",
      },
      {
        dataField: "status",
        caption: "Status",
        cellRender: ({ data }) =>
          getValueFromMap(IntegrationStatus, data.status),
      },
      {
        caption: "Ações",
        alignment: "center",
        allowExporting: false,
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "far", icon: "fa-pencil" },
                label: "Editar",
                onClick: () => {
                  setIntegration(data);
                  modalManager.show(
                    IntegrationModalName.EDIT_INTEGRATION_MODAL,
                  );
                },
              },
            ]}
          />
        ),
      },
    ],
  };
};
