import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { service } from "services";
import { getLeadExportStatusBadge } from "./lead-exports.utils";

type WorkflowLeadExportRequestsGridColumn = Awaited<
  ReturnType<typeof service.hubCreditManager.getWorkflowLeadExportRequests>
>["data"][number];

export const workflowLeadExportRequestsGrid = {
  datagrid: {
    noDataText: "Nenhuma solicitação de exportação encontrada.",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getWorkflowLeadExportRequests().catch(() => {
        throw new Error(
          "Não foi possível buscar as solicitações de exportação.",
        );
      }),
    ),
    headerFilter: { visible: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "desc",
      sortIndex: 0,
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      caption: "Data de criação",
      cellRender: ({ data }) =>
        dayjs(data.createdDate).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      dataField: "updatedDate",
      dataType: "datetime",
      caption: "Data de atualização",
      cellRender: ({ data }) =>
        data.updatedDate
          ? dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm:ss")
          : "-",
    },
    {
      dataField: "workflowConfig",
      dataType: "string",
      caption: "Workflow",
    },
    {
      dataField: "view",
      dataType: "string",
      caption: "Visualização",
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
      cellRender: ({ data }) => getLeadExportStatusBadge(data.status),
    },
    {
      caption: "Originador",
      dataField: "originator.identification",
      dataType: "string",
      cellRender: ({ data }) =>
        `${data.originator.identification} - ${data.originator.name}`,
    },
    {
      caption: "Operador",
      dataField: "operator.identification",
      dataType: "string",
      cellRender: ({ data }) =>
        `${data.operator.identification} - ${data.operator.legalEntity.name}`,
    },
    {
      dataField: "filter",
      dataType: "string",
      caption: "Filtro",
    },
    {
      dataField: "impersonator.taxId",
      caption: "Impersonador",
      dataType: "string",
      cellRender: ({ data }) =>
        data.impersonator
          ? `${data.impersonator.taxId} - ${data.impersonator.name}`
          : "",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <DataGridAction
          actions={[
            {
              icon: {
                category: "fal",
                icon: "fa-trash",
              },
              visible: data.status === "EXECUTING",
              label: "Rejeitar",
              onClick: async () => {
                try {
                  await service.hubCreditManager.rejectWorkflowLeadExportRequest(
                    { id: data.id },
                  );

                  component.getDataSource().reload();
                  OToastManager.success("Soliticação rejeitada com sucesso.");
                } catch {
                  OToastManager.danger(
                    "Um erro ocorreu ao rejeitar a solicitação.",
                  );
                }
              },
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowLeadExportRequestsGridColumn>;
