export const FloatingSettingsModalId = "floating-settings-modal-id";

export interface FloatingSettingsModalFormFields {
  quantityInDays: number;
  immediateEffect: boolean;
}

export interface FloatingSettingsModalProps {
  currentFloating?: number;
  handleSuccess: () => void;
}
