import { OLoader } from "@maestro/react";
import { useTheme } from "contexts/theme";
import { useRoles } from "hooks/roles";
import { useState } from "react";
import { roles } from "roles/roles";

export const DatadogMonitor = () => {
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();
  const { hasRole } = useRoles();

  if (!hasRole(roles.cadastro.product.datadogDashboard.role)) return null;

  return (
    <div
      style={{
        position: "relative",
        height: "1200px",
      }}
      className="mt-5"
    >
      {loading && <OLoader absolute />}
      <iframe
        title="Primeiro Acesso"
        style={{
          height: "100%",
          width: "100%",
          border: "none",
          visibility: loading ? "hidden" : "visible",
        }}
        src={`https://p.datadoghq.com/sb/0f8lzr0dlm4ne96m-de14403d713dd88c80674537f17f3c36?theme=${theme}`}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};
