import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ComponentGeneralInfo } from "./_compose/component-general-info/component-general-info.component";
import { ComponentPropertyModal } from "./_compose/component-properties-modal/component-properties-modal.component";
import { ComponentProperties } from "./_compose/component-properties/component-properties.component";
import { useEditComponent } from "./edit-component.hook";

export const EditComponentPage = () => {
  const { id, selectedProperty, setSelectedProperty, component } =
    useEditComponent();
  return (
    <>
      <FormTemplate pageTitle={<PageTitle title="Editar componente" />}>
        <CardTemplate>
          <ComponentGeneralInfo component={component} id={id} />
        </CardTemplate>
      </FormTemplate>
      <ComponentProperties
        id={id}
        component={component}
        setSelectedProperty={setSelectedProperty}
      />
      <ComponentPropertyModal
        id={id}
        component={component}
        selectedProperty={selectedProperty}
      />
    </>
  );
};
