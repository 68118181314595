import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { logger } from "utils/logger";
import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useStepsBuilder } from "components/steps-builder";
import { parseIntubateSpreadsheet } from "./file-upload.utils";
import { useIntubateOperationsWithoutRegistry } from "../../intubate-operations-without-registry.context";

export const useFileUpload = () => {
  const [hasParseError, setHasParseError] = useState(false);
  const [isParsing, setIsParsing] = useState(false);

  const { setDataReceivables } = useIntubateOperationsWithoutRegistry();

  const { nextStep } = useStepsBuilder();

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset,
  } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsArrayBuffer(file);
      }
    },
    [reader],
  );

  const parseReceivables = useCallback(
    async (readerResult: string | ArrayBuffer) => {
      setIsParsing(true);
      try {
        if (setDataReceivables) {
          const receivables = parseIntubateSpreadsheet(readerResult);
          if (receivables.length === 0) {
            setHasParseError(true);
            OToastManager.danger("A planilha apresenta problemas");
          }
          setDataReceivables(receivables);
        }
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      } finally {
        setIsParsing(false);
        nextStep();
      }
    },
    [nextStep, setDataReceivables],
  );

  useEffect(() => {
    if (result && !readerError) {
      parseReceivables(result);
    }
  }, [readerError, result, parseReceivables]);

  return {
    form,
    handleAddFile,
    loading: readerLoading || isParsing,
    hasError: readerError || hasParseError,
    reset,
    setHasParseError,
  };
};
