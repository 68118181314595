import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { orqIcons } from "../../configuracoes/workflow-configuration.utils";
import {
  addProposalWorkflowFormDefaultValues,
  addProposalWorkflowFormValidationSchema,
} from "./add-proposal-workflow-form.schemas";

export const useAddProposalWorkflowPage = () => {
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(addProposalWorkflowFormValidationSchema),
    defaultValues: addProposalWorkflowFormDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const [loading, setLoading] = useState(false);

  const {
    value: detailsPageTypeData,
    callService: getDetailsPageType,
    loading: loadingGetDetailsPageType,
  } = useServiceCall(service.hubCreditManager.getDetailsPageType);

  const iconOptions = orqIcons.map((icon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("icon", value),
  }));

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubCreditManager.createWorkflowConfig(values);

      OToastManager.success("Workflow adicionado com sucesso!");

      navigate(corporateRouter.routes.workflow.product.workflowsConfig.path);
    } catch {
      OToastManager.danger(
        "Não foi possível criar o Workflow. Por favor, tente novamente mais tarde.",
      );
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getDetailsPageType();
  }, [getDetailsPageType]);

  return {
    detailsPageTypeData,
    form,
    iconOptions,
    loading,
    loadingGetDetailsPageType,
    onSubmit,
    setValue,
  };
};
