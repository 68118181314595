import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services/service";
import { dataSourceGenerator } from "./fees-sacado.grid";

export const useFeesSacado = () => {
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();
  const { customer } = useCustomer();

  const {
    callService: getCompany,
    loading: companyLoading,
    value: company,
  } = useServiceCall(service.quickfin.getCompanyWithOnboarding);

  useEffect(() => {
    getCompany(customer.quickfinId);
  }, [customer.quickfinId, getCompany]);

  useEffect(() => {
    if (company) {
      if (!company.cge) {
        OToastManager.danger(
          "Empresa deve estar com CGE cadastrado para obter e cadastrar Fees.",
        );
        return;
      }

      setDataSource(dataSourceGenerator(company.cge));
    }
  }, [company]);

  return {
    companyLoading,
    dataSource,
  };
};
