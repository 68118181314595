import { OToastManager, modalManager } from "@maestro/core";
import { OButton, OConfirmationModal } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import styled from "styled-components";
import { GridTemplate } from "templates/grid-template";
import { type DeskNotification } from "./desk-notifications.grid";

const StyledPre = styled.pre`
  background: #2c3e50;
  color: #ecf0f1;
  height: 400px;
  line-height: 150%;
  overflow-y: auto;
  padding: 20px;
  text-align: left;
`;

type DeskNotificationVariables = Omit<
  DeskNotification["variablesNotificationConfigs"][number],
  "variables" | "to" | "cc" | "bcc"
> & {
  variables: string;
  to: string;
  cc: string;
  bcc: string;
};

const buildDataSource = (deskNotification: DeskNotification) =>
  dataSourceCustomStoreGenerator<DeskNotificationVariables>(
    () =>
      Promise.resolve(
        deskNotification.variablesNotificationConfigs.map((config) => ({
          ...config,
          variables: JSON.stringify(config.variables, null, 2),
          to: config.to?.join(","),
          cc: config.cc?.join(","),
          bcc: config.bcc?.join(","),
        })),
      ),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            await service.onboarding.registerDeskNotificationVariables({
              deskNotificationUuid: deskNotification.uuid,
              ...values,
              variables: JSON.parse(values.variables),
              to: values.to?.split(","),
              cc: values.cc?.split(","),
              bcc: values.bcc?.split(","),
            });

            OToastManager.success("Variavel adicionada com sucesso");

            return values;
          } catch (error) {
            OToastManager.danger("Um erro ocorreu ao tentar adicionar.");
            throw new Error("Um erro ocorreu ao tentar adicionar.");
          }
        },
        remove: async (data) => {
          try {
            if (!data.uuid) throw new Error("No uuid");

            await service.onboarding.removeDeskNotificationVariables(data.uuid);

            OToastManager.success("Variavel removida com sucesso.");
          } catch (error) {
            OToastManager.danger("Erro ao remover variavel");
          }
        },
      },
    },
  );

export const deskNotificationVariablesMasterDetailGrid: ODataGridGeneratorConfig<DeskNotificationVariables> =
  {
    datagrid: {
      noDataText: "Nenhuma variavel",
      editing: {
        mode: "form",
      },
    },
    columns: [
      {
        dataField: "emailTemplate",
      },
      {
        dataField: "templateType",
      },
      {
        dataField: "serviceNotificationType",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "customTitle",
      },
      {
        dataField: "to",
      },
      {
        dataField: "cc",
      },
      {
        dataField: "bcc",
      },
      {
        dataField: "variables",
        formItem: {
          editorType: "dxTextArea",
        },
        editorOptions: {
          autoResizeEnabled: true,
        },
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "Formato inválido",
            ignoreEmptyValue: true,
            validationCallback: ({ value }) => {
              try {
                JSON.parse(value);
                return true;
              } catch {
                return false;
              }
            },
          },
        ],
        cssClass: "dx-word-wrap",
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex, row }) => {
          const modalId = `notification-variables-${data.uuid}-modal`;
          return (
            !row.isEditing && (
              <>
                <DataGridAction
                  actions={[
                    {
                      icon: { category: "orq", icon: "orq-unified-invoice" },
                      label: "Exibir",
                      onClick: () => modalManager.show(modalId),
                    },
                    {
                      icon: { category: "orq", icon: "orq-edit-trash" },
                      label: "Remover",
                      onClick: () => {
                        component.option(
                          "editing.texts.confirmDeleteMessage",
                          `Você confirma que a variavel '${data?.uuid}' será removida?`,
                        );
                        component.deleteRow(rowIndex);
                      },
                    },
                  ]}
                />
                <OConfirmationModal
                  modalId={modalId}
                  title="Variaveis de notificação"
                  confirmLabel="Ok"
                  divider={false}
                  size="md"
                  onConfirm={() => modalManager.hide(modalId)}
                >
                  <StyledPre>
                    {JSON.stringify(
                      { ...data, variables: JSON.parse(data.variables) },
                      null,
                      2,
                    )}
                  </StyledPre>
                </OConfirmationModal>
              </>
            )
          );
        },
      },
    ],
  };

type DeskNotificationVariablesMasterDetailProps =
  MasterDetailComponentProps<DeskNotification>;

export const DeskNotificationVariablesMasterDetail = ({
  data: { data: deskNotification },
}: DeskNotificationVariablesMasterDetailProps) => {
  const dataGridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(deskNotification),
    [deskNotification],
  );
  return (
    <GridTemplate
      actions={
        <OButton
          onClick={() => {
            dataGridRef.current?.instance.addRow();
          }}
        >
          Adicionar
        </OButton>
      }
      gridRef={dataGridRef}
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={deskNotificationVariablesMasterDetailGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
