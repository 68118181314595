import { OToastManager } from "@maestro/core";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { pendenciesGrid } from "./operations-pendencies.grid";

export const OperationsPendenciesPage = () => {
  const [loading, setLoading] = useState(false);
  const [showResolveAllPrencies, setShowResolveAllPrencies] = useState(false);
  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubLoan
          .getLoanDetails(id)
          .then(({ data }) => {
            setShowResolveAllPrencies(
              data.pendencies?.some((pendency) => pendency.pending),
            );
            return data.pendencies;
          })
          .catch(() => {
            throw new Error("Erro ao buscar pendeêcias");
          }),
      ),
    [id],
  );

  const resolveAllPendencies = useCallback(async () => {
    try {
      setLoading(true);

      await service.hubLoan.resolveAllPendencies(id);

      OToastManager.success("Pendências resolvidas com sucesso");
    } catch {
      OToastManager.danger(
        "Houve um problema para resolver todas as pendências.",
      );
    } finally {
      setLoading(false);
    }
  }, [id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pendências" />}
      actions={
        <>
          {showResolveAllPrencies && (
            <ButtonWithConfirmation
              confirmationModal={{
                title: "Houve um problema para resolver todas as pendências.",
                confirmLabel: "Confirmar",
                closeLabel: "Cancelar",
                loading,
              }}
              onConfirm={resolveAllPendencies}
              disabled={loading}
              outline
            >
              Marcar todas as pendências como resolvidas
            </ButtonWithConfirmation>
          )}
          <RefreshGridButton
            onClick={() => {
              !dataSource?.isLoading() && dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator grid={pendenciesGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
