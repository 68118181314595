import { env } from "utils/environments";

export const buildOperatorLink = (
  operator: string,
  originator: string,
  route: string,
) => {
  const params = new URLSearchParams();
  params.set("operator", window.btoa(operator));
  params.set("originator", window.btoa(originator));
  return route + "?" + params.toString();
};

export const buildHierarchyLink = (
  operatorProfileUUID: string,
  originatorName: string,
  originatorIdentification: string,
  route: string,
) => {
  const params = new URLSearchParams();
  params.set("operatorProfileUUID", window.btoa(operatorProfileUUID));
  params.set("originatorName", window.btoa(originatorName));
  params.set("originatorIdentification", window.btoa(originatorIdentification));
  return route + "?" + params.toString();
};

export const buildTaggedLink = (tagCode: string) =>
  `${env.PORTAL_CORE_FRONT_URL}/criar-conta?tag=${tagCode}`;
