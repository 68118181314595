import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useEnergyCustomer = () => {
  const { customer } = useCustomer();

  const { callService, loading, hasError, value, error } = useServiceCall(
    service.hubEnergy.getEntity,
  );

  const getEntity = useCallback(
    () => callService({ companyTaxId: customer.identification }),
    [callService, customer.identification],
  );

  useEffect(() => {
    getEntity();
  }, [getEntity]);

  const currentTrade = value?.response?.customerCurrentTrade;

  return {
    currentTrade,
    error,
    getEntity,
    hasError,
    loading,
    value,
  };
};
