import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByStepConfigIdRouteParams } from "../../../../../routes/energia.route-params";
import { useStepConfigForm } from "../_compose";

export const useEditStepConfig = () => {
  const { stepConfigId } = useParams<MleByStepConfigIdRouteParams>();
  if (!stepConfigId) throw new Error("No stepConfigId");

  const {
    callService: _fetch,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepConfigBase);

  const {
    form,
    loading: submitLoading,
    submit: originalSubmit,
  } = useStepConfigForm();

  const { reset } = form;

  const getStepConfig = useCallback(
    () => _fetch(stepConfigId),
    [_fetch, stepConfigId],
  );

  const submit = useMemo(
    () => originalSubmit(Number(stepConfigId)),
    [originalSubmit, stepConfigId],
  );

  useEffect(() => {
    getStepConfig();
  }, [getStepConfig]);

  useEffect(() => {
    if (fetchValue?.response)
      reset({
        isPublic: !!fetchValue.response.isPublic,
        maximumDuration: fetchValue.response.maximumDuration,
        maximumDurationType: fetchValue.response.maximumDurationType,
        name: fetchValue.response.name,
        label: fetchValue.response.label,
        needsPreApproval: !!fetchValue.response.needsPreApproval,
        dependencyTypeIds:
          fetchValue.response.dependencies.map(({ id }) => id) ?? [],
        typeId: fetchValue.response.typeId,
      });
  }, [reset, fetchValue]);

  return {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getStepConfig,
    submit,
    submitLoading,
    stepConfigId,
  };
};
