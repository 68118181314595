import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { chartOptions } from "./chart-card.utils";
import { MetricRecord } from "./chart-card.types";
import "dayjs/locale/pt-br";

interface BarChartProps {
  configName: string;
  records: MetricRecord[];
}

export const BarChart = ({ records, configName }: BarChartProps) => (
  <div style={{ maxWidth: "540px", maxHeight: "270px" }}>
    <Bar
      options={chartOptions}
      data={{
        labels: records.map((record) =>
          dayjs(record.relatedDate).locale("pt-br").utc(true).format("DD MMM"),
        ),
        datasets: [
          {
            label: configName,
            data: records.map((record) => record.value),
            backgroundColor: "#5081c6",
          },
        ],
      }}
    />
  </div>
);
