import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FerramentasProdutoUsuariosByTaxIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { getModificationsToArray } from "../../../../../utils";
import { useGatekeeperUserProfiles, useProfiles } from "../_compose";
import {
  userProfilesDefaultValues,
  userProfilesValidationSchema,
} from "./user-profiles.form";
import { UserProfilesForm } from "./user-profiles.types";

export const useUserProfiles = () => {
  const { taxId } = useParams<FerramentasProdutoUsuariosByTaxIdRouteParams>();
  if (!taxId) throw new Error("No taxId");

  const { callService: addProfilesToUser, loading: addLoading } =
    useServiceCall(service.gatekeeper.addProfilesToUser);

  const { callService: removeProfilesFromUser, loading: removeLoading } =
    useServiceCall(service.gatekeeper.removeProfilesFromUser);

  const { allProfiles, getProfiles, getProfilesHasError, getProfilesLoading } =
    useProfiles();

  const { getUser, getUserHasError, getUserLoading, userData } =
    useGatekeeperUserProfiles(taxId);

  const form = useForm<UserProfilesForm>({
    defaultValues: userProfilesDefaultValues,
    resolver: yupResolver(userProfilesValidationSchema),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    resetField,
  } = form;

  const resetProfiles = useCallback(
    () => resetField("profiles", { defaultValue: userData?.profiles ?? [] }),
    [resetField, userData?.profiles],
  );

  const submit = useMemo(
    () =>
      handleSubmit(
        async (values) => {
          if (!userData) throw new Error("No userData in submit");

          const { added, removed } = getModificationsToArray(
            userData.profiles,
            values.profiles,
          );

          if (added.length === 0 && removed.length === 0)
            return OToastManager.info("Nenhuma modificação");

          const promises: Promise<{ success: boolean }>[] = [];

          if (added.length > 0) {
            promises.push(
              addProfilesToUser({
                email: userData.email,
                profiles: added,
              }),
            );
          }

          if (removed.length > 0) {
            promises.push(
              removeProfilesFromUser({
                email: userData.email,
                profiles: removed,
              }),
            );
          }

          const successes = await Promise.all(promises);

          if (successes.every(({ success }) => success))
            OToastManager.success("Profiles salvos com sucesso");
          else OToastManager.danger("Não foi possível salvar os profiles");

          getUser();
        },
        (errors) => {
          OToastManager.danger(
            `Campos obrigatórios: ${Object.keys(errors).join(", ")}`,
          );
        },
      ),
    [
      addProfilesToUser,
      getUser,
      handleSubmit,
      removeProfilesFromUser,
      userData,
    ],
  );

  useEffect(() => {
    if (userData) {
      reset({ profiles: userData.profiles });
    }
  }, [reset, userData]);

  const options = useMemo(
    () =>
      allProfiles?.map<SelectSearchMultipleOption<string>>((profile) => ({
        label: profile.name,
        value: profile.name,
      })) ?? [],
    [allProfiles],
  );

  const isDirty = !!dirtyFields.profiles;

  return {
    allProfiles,
    form,
    getProfiles,
    getProfilesHasError,
    getProfilesLoading,
    getUser,
    getUserHasError,
    getUserLoading,
    isDirty,
    loading:
      getUserLoading || getProfilesLoading || addLoading || removeLoading,
    options,
    resetProfiles,
    submit,
    userData,
  };
};
