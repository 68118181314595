import { FormProvider } from "react-hook-form";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { OTypography } from "@maestro/react";
import { useFillPending } from "./fill-pending-complements.hook";
import { ContractComplements } from "../../../cliente/cedentes/detalhes/_compose/contract-complement";

export const FillPendingComplementsPage = () => {
  const {
    form,
    loading,
    emailsOptions,
    loadingEmails,
    loadingContractComplements,
    submit,
  } = useFillPending();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Preencher complementos do contrato" />}
      actions={
        <ButtonWithConfirmation
          confirmationModal={{
            loading,
            title:
              "Tem certeza que deseja enviar para assinatura esse contrato?",
          }}
          modalBody={
            <OTypography>
              Após enviar, não é possível editar novamente.
            </OTypography>
          }
          disabled={loading}
          onConfirm={submit}
        >
          Enviar
        </ButtonWithConfirmation>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ContractComplements
            emailsOptions={emailsOptions}
            loadingEmails={loadingEmails}
            loadingComplements={loadingContractComplements}
            expanded
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
