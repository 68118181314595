import * as yup from "yup";

export interface IntegrationResponse {
  data: {
    integrations: IntegrationFileProcessor[];
  };
}

export interface IntegrationFileProcessor {
  id: string;
  companyDocument: string;
  type: string;
  status: string;
  inboundFilename: string;
  outboundFilename: string;
  contacts: Contact[];
  accounts: Account[];
  createdAt: string;
}

export interface Contact {
  name: string;
  type: string;
  email: string;
  phone: string;
}

export interface Account {
  accountNumber: string;
  accountDigit: string;
  branch: string;
}

export const integrationDefaultValues = {
  document: "",
  product: "",
};

export const integrationUpdateDefaultValues = {
  observation: "",
  status: "",
};

export interface IntegrationFormValues {
  document: string;
  product: string;
}

export interface IntegrationUpdateFormValues {
  observation: string;
  status: string;
}

export interface RegisterCompanyFileProcessor {
  document: string;
  name: string;
}

export const integrationSchema: yup.ObjectSchema<IntegrationFormValues> =
  yup.object({
    document: yup.string().required("Este campo é obrigatório"),
    product: yup.string().required("Este campo é obrigatório"),
  });

export const integrationUpdateSchema: yup.ObjectSchema<IntegrationUpdateFormValues> =
  yup.object({
    observation: yup.string().required("Este campo é obrigatório"),
    status: yup.string().required("Este campo é obrigatório"),
  });
