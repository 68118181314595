import { OInputText } from "@maestro/react";
import { MoneyMask } from "../../../../../utils";
import { removeCipherFormatter } from "../../../../../utils/formatters/cipher";
import { Currency } from "../../../../cards.types";
import { InputContent, InputLabel } from "../compose.styles";
import { IInputNumberProps } from "./question-text-compose.types";

export const InputNumber = ({
  name,
  label,
  maximumValue,
  placeholder,
  isEditable,
  fields,
  handleInputChange,
  errors,
}: IInputNumberProps) => {
  const onBlur = (value: string | undefined) => {
    if (!value) return;
    let amount = removeCipherFormatter(value);
    if (maximumValue && Number(amount) > maximumValue) {
      amount = maximumValue.toString();
    }
    handleInputChange({
      name,
      value: MoneyMask(amount, Currency.BRL),
      label,
    });
  };
  return (
    <div key={name}>
      <InputLabel>{label}</InputLabel>
      <InputContent>
        <OInputText
          dataAction={`chargeback:input:${label}`}
          dataLabel={label}
          id={name}
          name={name}
          value={fields[name]}
          placeholder={placeholder}
          disabled={!isEditable}
          onInput={(e) =>
            handleInputChange({
              name,
              value: e.currentTarget.value,
              label,
            })
          }
          onBlur={(e) => onBlur(e.currentTarget.value)}
        />
        {errors[name] && <span>{errors[name]}</span>}
      </InputContent>
    </div>
  );
};
