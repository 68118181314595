import { useState, useEffect } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export const useFinancialInstitution = (
  filterBy?: Assets.CentralBankEnabledSystem,
) => {
  const [financialInstitutionList, setFinancialInstitutionList] = useState<
    Assets.FinancialInstitution[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await service.assets.getFinancialInstitutions();
        setFinancialInstitutionList(
          data.financialInstitutions
            .filter((fi: Assets.FinancialInstitution) => {
              if (filterBy)
                return fi.centralBankEnabledSystems.includes(filterBy);
              return true;
            })
            .sort(
              (
                a: Assets.FinancialInstitution,
                b: Assets.FinancialInstitution,
              ) => {
                if (a.bankCode && b.bankCode)
                  return a.bankCode.localeCompare(b.bankCode);
                return 0;
              },
            ),
        );
      } catch (error) {
        logger.error("Error fetching bank data:", error);
      }
    };

    fetchData();
  }, [filterBy]);

  return financialInstitutionList;
};
