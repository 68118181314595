import {
  OCol,
  OOption,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ProcessorConfig } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowContextFormValues } from "./workflow-context-form.schemas";

interface ContextFormProps {
  leadId: number;
}

export const WorkflowContextForm = ({ leadId }: ContextFormProps) => {
  const [processorConfigs, setProcessorConfigs] = useState<ProcessorConfig[]>(
    [],
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    service.hubCreditManager
      .getProcessorConfigsByWorkflowLeadId(leadId)
      .then((response) => setProcessorConfigs(response.data))
      .then(() => setLoading(false));
  }, [leadId]);

  const { handleSubmit } = useFormContext<WorkflowContextFormValues>();

  const navigate = useNavigate();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        const { processorConfig } = data;

        try {
          setLoading(true);
          await service.hubCreditManager.addProcessorContext({
            workflowLeadId: leadId,
            processorConfigId: Number(processorConfig),
          });

          OToastManager.success("Contexto criado com sucesso!");
          navigate(
            corporateRouter.routes.workflow.product.leads.details.contexts.path(
              {
                id: leadId,
              },
            ),
          );
        } catch {
          OToastManager.danger("Houve um problema ao criar o contexto");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, leadId, navigate],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          dataAction="lead_adicionar_contexto:botao:enviar"
          dataLabel="enviar"
          onClick={onSubmit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ORow>
          <OCol>
            <ORFieldSelect
              id="processorConfig"
              name="processorConfig"
              label="Configuração de processador"
              dataAction="lead_contexto:select:processador"
              dataLabel="processador"
              key={processorConfigs.join()}
            >
              {processorConfigs.map((config) => (
                <OOption key={config.id} value={config.id}>
                  {config.name}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
        </ORow>
      </CardTemplate>
    </FormTemplate>
  );
};
