import { modalManager } from "@maestro/core";
import {
  OButton,
  OCheckbox,
  OCol,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { TooltipIcon } from "components/tooltip-icon";
import { useUpdateStepWithConfigModal } from "./update-step-with-config-modal.hook";
import { OperationLogs, OperationSummary } from "./_compose";

export const updateStepWithConfigModalId = "update-step-with-config-modal-id";

interface UpdateStepWithConfigModalProps {
  selectedSteps: HubEnergy.DiligenceStepMonitor[];
}

export const UpdateStepWithConfigModal = ({
  selectedSteps,
}: UpdateStepWithConfigModalProps) => {
  const {
    clear,
    loading,
    notDryRun,
    overwrite,
    result,
    setNotDryRun,
    setOverwrite,
    updateStepWithConfig,
  } = useUpdateStepWithConfigModal(selectedSteps);

  return (
    <OModal
      id={updateStepWithConfigModalId}
      position="center"
      backdrop={!loading}
      onModalClose={() => {
        clear();
        setNotDryRun(false);
        setOverwrite(false);
      }}
    >
      <OModalHeader closeButton={!loading}>
        <OTypography type="dark" size="lg">
          Atualizar etapas com as configurações
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <ORow>
          <OCol xs={12} sm={6}>
            <div className="d-flex align-items-center gap-2">
              <OCheckbox
                id="overwrite"
                value="overwrite"
                size="sm"
                checked={overwrite}
                onInput={(evt) => setOverwrite(evt.currentTarget.checked)}
              />
              <OLabel htmlFor="overwrite">
                Recriar etapas{" "}
                <TooltipIcon
                  content="Se marcado, vai excluir as steps atuais (PERDENDO TODOS OS STATUS DAS PENDÊNCIAS E VALORES DAS PROPRIEDADES) e recriar a partir das respectivas configurações. Caso contrário, vai tentar fazer um match do step existente com a configuração e apenas criar ou modificar o que está faltando"
                  maxWidth="500px"
                />
              </OLabel>
            </div>
          </OCol>
          <OCol xs={12} sm={6}>
            <div className="d-flex align-items-center gap-2">
              <OCheckbox
                id="notDryRun"
                value="notDryRun"
                size="sm"
                type="danger"
                checked={notDryRun}
                onInput={(evt) => setNotDryRun(evt.currentTarget.checked)}
              />
              <OLabel htmlFor="notDryRun">
                Salvar mudanças{" "}
                <TooltipIcon
                  content="Se marcado, vai salvar as mudanças. Caso contrário, vai apenas mostrar o que será feito, sem salvar."
                  maxWidth="500px"
                />
              </OLabel>
            </div>
          </OCol>
        </ORow>
        {result && (
          <div className="mt-4 d-flex gap-4">
            <OperationSummary
              notDryRun={notDryRun}
              overwrite={overwrite}
              result={result}
            />
            <OperationLogs result={result} />
          </div>
        )}
      </OModalBody>
      <OModalFooter>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(updateStepWithConfigModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={updateStepWithConfig}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
