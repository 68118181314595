import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useCurrencies } from "hooks/currency/currency.hook";
import { ExchangeQuoteResponse } from "services/hubfx/models/types/quote/quote.model";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { parseQuoteFlowType, quoteStatusMap } from "../../utils/quote.utils";

export const exchangeQuoteDetailsGenerator = (
  quote: ExchangeQuoteResponse | null,
) => {
  if (!quote) return {} as DetailsFields;

  const { mapSymbol } = useCurrencies();

  const symbol = mapSymbol(parseQuoteFlowType(quote)?.currency);

  const quoteDetails = {
    quote: {
      title: "Operação",
      items: [
        {
          label: "Id",
          value: quote.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(quote?.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Liquidação",
          value: format.dateTime(quote?.disbursementDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: quoteStatusMap[quote?.status || "UNDEFINED"],
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: parseQuoteFlowType(quote)?.type,
          grid: { md: 4, xl: 3 },
        },
        {
          label: `Nome do ${parseQuoteFlowType(quote)?.externalParty}`,
          value: parseQuoteFlowType(quote)?.externalPartyName,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    currency: {
      title: "Moeda da operação",
      items: [
        {
          label: "Moeda",
          value: parseQuoteFlowType(quote)?.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Cotação",
          value: quote?.exchangeRatio?.toString().replace(".", ","),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    actualValue: {
      title: "Valor real",
      items: [
        {
          label: "Vr. Moeda",
          value: masks.currency(
            quote?.orderAmount?.toFixed(2).replace(".", ","),
            symbol,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. VET",
          value: masks.currency(quote?.vet, currencySymbol.BRL, "."),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. Reais",
          value: masks.currency(
            quote?.nationalCurrencyTotal,
            currencySymbol.BRL,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    operationNature: {
      title: "Enquadramento da operação",
      items: [
        {
          label: "Enquadramento",
          value: quote.natureDescription,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    quoteTaxes: {
      visible: !!quote.taxes,
      title: "Taxas",
      items: [
        {
          label: "IOF",
          value: `${masks.currency(
            quote?.taxes?.iofAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.iofRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ISS",
          value: `${masks.currency(
            quote?.taxes?.issAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.issRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PIS",
          value: `${masks.currency(
            quote?.taxes?.pisAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.pisRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CIDE",
          value: `${masks.currency(
            quote?.taxes?.cideAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.cideRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "IRRF",
          value: `${masks.currency(
            quote?.taxes?.irrfAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.irrfRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "COFINS",
          value: `${masks.currency(
            quote?.taxes?.cofinsAmount,
            currencySymbol.BRL,
            ".",
          )} (${masks.percentage(quote?.taxes?.cofinsRate) || "0,00%"})`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return quoteDetails;
};
