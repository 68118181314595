export const cleanTaxId = (taxId: string | number) =>
  taxId.toString().trim().replace(/\D/g, "").padStart(14, "0");

/** Returns an object with keys trimmed and lowercase */
export const normalizeObjectKeys = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.trim().toLowerCase(),
      value,
    ]),
  ) as Record<Lowercase<string>, any>;

export const normalizeObjectKeysCards = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const normalizedKey = key.trim().toLowerCase();
      const processedValue =
        ["banco", "agencia", "conta"].includes(normalizedKey) &&
        typeof value === "string"
          ? value.replace(/^0+/, "")
          : value;
      return [normalizedKey, processedValue];
    }),
  ) as Record<Lowercase<string>, any>;
