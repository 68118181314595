import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowPendencyFormValues } from "./workflow-pendency-form.schemas";

interface PendencyFormProps {
  leadId: number;
}

export const WorkflowPendencyForm = ({ leadId }: PendencyFormProps) => {
  const [loading, setLoading] = useState(false);

  const { watch, handleSubmit } = useFormContext<WorkflowPendencyFormValues>();

  const navigate = useNavigate();

  const documentIdWatcher = watch("documentId");

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        const { type, documentId, reason, fieldName } = data;

        try {
          setLoading(true);
          await service.hubCreditManager.addWorkflowLeadsPendency({
            type,
            documentId: Number(documentId),
            reason,
            leadId,
            fieldName,
          });

          OToastManager.success("Pendência criada com sucesso!");
          navigate(
            corporateRouter.routes.workflow.product.leads.details.pendencies.path(
              {
                id: leadId,
              },
            ),
          );
        } catch {
          OToastManager.danger("Houve um problema ao criar a pendência");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, leadId, navigate],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          dataAction="lead_adicionar_pendencias:botao:enviar"
          dataLabel="enviar"
          onClick={onSubmit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ORow>
          <OCol lg={6}>
            <ORFieldSelect
              id="type"
              name="type"
              label="Tipo de pendência"
              dataAction="lead_pendencia:select:tipo_de_pendencia"
              dataLabel="tipo_de_pendencia"
              readonly={!!documentIdWatcher}
            >
              <OOption value="form">Formulário</OOption>
              <OOption value="document">Documento</OOption>
              <OOption value="text">Texto</OOption>
              <OOption value="number">Número</OOption>
              <OOption value="currency">Valor monetário</OOption>
              <OOption value="date">Data</OOption>
            </ORFieldSelect>
          </OCol>
          <OCol lg={6}>
            {!documentIdWatcher && (
              <ORFieldInput
                id="fieldName"
                name="fieldName"
                tag="text"
                label="Título da pendência"
                dataAction="lead_pendencia:select:nome_do_campo"
                dataLabel="nome_do_campo"
              />
            )}
          </OCol>
        </ORow>
        <ORow>
          <OCol>
            <ORFieldInput
              id="reason"
              name="reason"
              tag="textarea"
              label="Descrição da pendência"
              dataAction="lead_pendencia:select:motivo"
              dataLabel="motivo"
            />
          </OCol>
        </ORow>
      </CardTemplate>
    </FormTemplate>
  );
};
