import { OButton } from "@maestro/react";
import { useRelocate } from "../../relocate.context";

type CancelButtonProps = Omit<
  React.ComponentProps<typeof OButton>,
  "dataAction" | "dataLabel"
>;

export const CancelButton = (props: CancelButtonProps) => {
  const { onClick } = props;

  const { stepNumber, reset } = useRelocate();

  return (
    <OButton
      dataAction={`realocacao_step_${stepNumber}:botao:cancelar`}
      dataLabel="cancelar"
      className="me-auto"
      type="dark"
      outline
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        reset();
      }}
    >
      Cancelar
    </OButton>
  );
};
