import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { exchangeOdataLimitsGrid } from "../../../components/odata/exchange-odata-limits.grid";
import { useCurrencies } from "hooks/currency/currency.hook";

export const ExchangeProductLimitsPage = () => {
  const { mapSymbol } = useCurrencies();
  const grid = exchangeOdataLimitsGrid(mapSymbol);
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Limites" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
