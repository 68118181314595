import { FileUpload } from "./file-upload";
import { SummaryStep } from "./summary";
import { ValidateStep } from "./validate";
import { Confirmation } from "./confirmation";
import { Result } from "./result";

export const fileSteps = [
  {
    name: "Envio de arquivo",
    component: FileUpload,
  },
  {
    name: "Resumo",
    component: SummaryStep,
  },
  {
    name: "Validação",
    component: ValidateStep,
  },
  {
    name: "Criar recebíveis",
    component: Confirmation,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
