import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GetAdminCustomerHistoryResponse } from "services/hubloan/admin-customer/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./loan-operations.grid";

const dataSource = dataSourceCustomStoreGenerator<
  GetAdminCustomerHistoryResponse[number]
>(() =>
  service.hubLoan.adminCustomer.getHistory().catch(() => {
    const errorMessage = "Erro ao buscar as operações";
    OToastManager.danger(errorMessage);
    throw new Error(errorMessage);
  }),
);

export const LoanOperations = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
