import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { ContractInstallment } from "services/renegotiation/models/types";

export const dangerIsExpired = (e: CellPreparedEvent<ContractInstallment>) => {
  if (!e?.data?.is_vnp) return;

  if (e?.data?.is_vnp) {
    e.cellElement.style.backgroundColor = "var(--theme-danger-80)";
    e.cellElement.style.color = "var(--theme-danger-inverse)";
  }
};
