import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";

export const dashboardOnboardingsGrid = (onboardingType: string) =>
  ({
    datagrid: {
      noDataText: "Nenhum registro encontrado",
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.quickfin.odata.onboardingsFornecedoresOData,
      ),
    },
    columns: [
      {
        allowExporting: false,
        type: "buttons",
        role: roles.recebiveis.product.dashboardOnboardingDetailsPage.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.recebiveis.product.dashboard.onboarding.details.path(
              {
                cedenteId: data.Id,
                cedenteType: onboardingType,
              },
            )}
          />
        ),
      },
      { dataField: "Fornecedor", caption: "Fornecedor", sortOrder: "asc" },
      { dataField: "Identificacao", caption: "Identificação" },
      {
        dataField: "UltimaAtualizacao",
        caption: "Última atualização",
        dataType: "datetime",
      },
      { dataField: "Status", caption: "Status" },
    ],
  } satisfies ODataGridGeneratorConfig<
    Awaited<
      ReturnType<typeof service.quickfin.getOnboardingsFornecedores>
    >["data"]["value"][number]
  >);
