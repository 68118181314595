export const generateContractFilename = (
  createdAt: string,
  isSigned: boolean,
) => {
  const date = createdAt
    ? new Date(createdAt).toISOString().split("T")[0]
    : undefined;
  const filename = date ? "contrato-" + date + ".pdf" : "contrato.pdf";

  if (isSigned) return filename.replace("contrato", "contrato-assinado");
  return filename;
};
