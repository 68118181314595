import { OptinContextProvider } from "./consulta-optin.context";
import { ConsultaOptinsContent } from "./consulta-optins.component";

export const ConsultaOptins = () => {
  return (
    <OptinContextProvider>
      <ConsultaOptinsContent />
    </OptinContextProvider>
  );
};
