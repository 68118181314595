import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { validators } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../../routes/emprestimos.route-params";
import {
  GuarantorFormValues,
  guarantorValidationFormSchema,
} from "./add-guarantor-form.schemas";

export const AddGuarantorPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<GuarantorFormValues>({
    resolver: yupResolver(guarantorValidationFormSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id parameter");

  const taxIdWatch = watch("guarantorTaxId");

  const handleSubmitCreateGuarantor = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.createGuarantor({
            guarantorTaxId: values.guarantorTaxId,
            guarantorType: values.guarantorType,
            limitId: +id,
          });

          OToastManager.success("Avalista criado com sucesso.");
        } catch {
          OToastManager.danger("Ocorreu um erro ao tentar criar o avalista.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id],
  );

  useEffect(() => {
    const getCompanyInfo = async () => {
      const { data } = await service.onboarding.searchLegalEntity({
        taxId: taxIdWatch,
      });

      setValue("guarantorName", data.officialName);
    };

    if (validators.cpf(taxIdWatch)) {
      getCompanyInfo();
    }
  }, [setValue, taxIdWatch]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar avalista" />}
      actions={
        <LoadingButton
          loading={loading}
          type="tertiary"
          dataAction="emprestimos_adicionar_avalista:botao:adicionar"
          dataLabel="adicionar"
          onClick={handleSubmitCreateGuarantor}
        >
          Adicionar avalista
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="cpf"
                id="guarantorTaxId"
                name="guarantorTaxId"
                dataAction="emprestimos_adicionar_avalista:input:cpf"
                dataLabel="cpf"
                label="CPF"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                id="guarantorName"
                name="guarantorName"
                dataAction="emprestimos_adicionar_avalista:input:nome"
                dataLabel="nome"
                label="Nome"
              />
            </OCol>
          </ORow>
          <OCol md={6}>
            <ORFieldSelect
              id="guarantorType"
              name="guarantorType"
              dataAction="emprestimos_adicionar_avalista:select:tipo"
              dataLabel="tipo"
              label="Tipo"
            >
              {["SIMPLES", "COMPLETO"].map((type) => (
                <OOption key={type} value={type}>
                  {type}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
