import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasMesaByDeskCodeRouteParams } from "../../../../../routes/ferramentas.route-params";
import { DeskForm } from "../_compose/desk-form.component";
import {
  DeskFormValues,
  deskFormValidationSchema,
} from "../_compose/desk.form";

export const UpdateDeskPage = () => {
  const { deskCode } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasMesaByDeskCodeRouteParams>();
  if (!deskCode) throw new Error("No deskCode");
  const form = useForm<DeskFormValues>({
    resolver: yupResolver(deskFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskDetails,
    loading,
    hasError,
    value: desk,
  } = useServiceCall(service.onboarding.getDeskDetails);
  const { callService: updateDesk, loading: submitLoading } = useServiceCall(
    service.onboarding.updateDesk,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDesk(values);

        if (!success) return OToastManager.danger("Erro ao atualizar mesa");

        return OToastManager.success("Mesa atualizada com sucesso");
      }),
    [handleSubmit, updateDesk],
  );

  useEffect(() => {
    getDeskDetails(deskCode);
  }, [getDeskDetails, deskCode]);

  useEffect(() => {
    desk && reset(desk);
  }, [desk, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar mesa" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar mesa"
            callback={() => getDeskDetails(deskCode)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskForm editOnly />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
