import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

interface ConditionsLinkProps {
  chosenContracts: string[];
}

export const ConditionsLink = ({ chosenContracts }: ConditionsLinkProps) => {
  const { routes } = corporateRouter;

  const baseUrl = routes.renegociacao.customer.monitor.conditions.path;

  const search = new URLSearchParams(
    chosenContracts.map((contract) => ["contract", contract]),
  );
  const redirectURL = baseUrl + "?" + search.toString();

  if (chosenContracts.length === 0) return null;

  return (
    <StandardLink
      href={redirectURL}
      role={roles.renegociacao.customer.monitorConditionsPage.role}
    >
      Visualizar condições
    </StandardLink>
  );
};
