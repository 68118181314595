import {
  modalManager,
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useForm } from "react-hook-form";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";
import { ComponentMenu } from "./_compose/component-menu.component";
import { StyledOModalBody } from "./update-component-modal.styles";

export const UpdateComponentModal = () => {
  const { hide } = modalManager;
  const {
    form: { callService: getForm, value: workflowFormTemplate },
  } = useWorkflowFormTemplate();

  const form = useForm();

  return (
    <OModal
      id="edit-component-modal"
      position="center"
      size="lg"
      className="position-absolute"
      backdrop={false}
      onModalClose={() => {
        workflowFormTemplate?.id && getForm(workflowFormTemplate.id);
      }}
    >
      <OModalHeader>
        <OTypography tag="h2" size="xl">
          Editar componente
        </OTypography>
      </OModalHeader>
      <StyledOModalBody>
        <ComponentMenu form={form} />
      </StyledOModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end">
          <OButton
            type="dark"
            outline
            onClick={() => {
              workflowFormTemplate?.id && getForm(workflowFormTemplate.id);
              hide("edit-component-modal");
            }}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
