import { OCol, ORFieldInput, ORow, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import { useRoles } from "hooks/roles";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ferramentasRoles } from "../../roles/ferramentas.roles";
import { useEligibility } from "./eligibility.hook";

export const Eligibility = () => {
  const { clearCache, detail, form, loading, loadingClear, submit } =
    useEligibility();

  const { hasRole } = useRoles();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Elegibilidade"
          description="Consulte a situação do CPF/CNPJ na receita"
        />
      }
      actions={
        <>
          {hasRole(
            ferramentasRoles.product.eligibilityClearCacheBCheckButton.role,
          ) &&
            detail && (
              <LoadingButton
                dataAction="elegibilidade:botao:linpar_cache"
                dataLabel="linpar_cache"
                loading={loadingClear || loading}
                onClick={clearCache}
              >
                Limpar cache Bcheck
              </LoadingButton>
            )}
          <LoadingButton
            loading={loading}
            dataAction="elegibilidade:botao:buscar"
            dataLabel="buscar"
            onClick={submit}
          >
            Buscar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldInput
                dataAction="optin:texto:cnpj-cpf"
                dataLabel="cnpj-cpf"
                tag="cpfcnpj"
                id="taxId"
                name="taxId"
                label="CNPJ / CPF"
                placeholder="Preencher"
              />
            </OCol>
          </ORow>

          {detail && (
            <ORow>
              <div className="d-flex flex-column gap-1">
                <OTypography>
                  <strong>Razao Social:</strong> {detail.officialName}
                </OTypography>
                <OTypography>
                  <strong>Situacao Ativa - Receita Federal:</strong>{" "}
                  {detail.statusRFB}
                </OTypography>
                <OTypography>
                  <strong>Data de nascimento:</strong>{" "}
                  {dayjs(detail.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </OTypography>
              </div>
            </ORow>
          )}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
