import {
  OBadge,
  OCard,
  OCardBody,
  OCol,
  OField,
  OIcon,
  OLink,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { useGatekeeperProfilesSubProfiles } from "./sub-profiles.hook";

export const GatekeeperProfilesSubProfiles = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const [profileName, setProfileName] = useState<string>();

  const loadProfileName = useCallback(async () => {
    const { data } = await service.gatekeeper.getProfileDetails({
      profiles: [{ uuid }],
    });

    setProfileName(data[0].name);
  }, []);

  useEffect(() => {
    loadProfileName();
  }, [loadProfileName]);

  const {
    allProfiles,
    labelRef,
    loading,
    subProfiles,
    setSubProfiles,
    submit,
  } = useGatekeeperProfilesSubProfiles(uuid, profileName);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Perfil Gatekeeper"
          description="Atualize os subperfis do perfil selecionado"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="atualizar_subperfis:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <OCard>
        <OCardBody>
          <OTypography className="mb-4">
            Para mais detalhes, acesse a{" "}
            <OLink
              href="https://wiki.quickfin.com.br/en/Plataforma/Enablers/primeiro-acesso/dev/gatekeeper/profiles-roles"
              target="_blank"
            >
              documentação
            </OLink>
          </OTypography>

          <ORow align="start">
            <OCol xs={12} sm={6} lg={4}>
              <OField label="Subperfis" htmlFor="input">
                <SelectSearch
                  id="input"
                  name="input"
                  placeholder="Selecionar"
                  options={allProfiles}
                  value={labelRef.current}
                  className="w-100"
                  maxOptions={50}
                />
              </OField>
            </OCol>

            {!!subProfiles.length && (
              <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-4">
                <OTypography tag="h3">Selecionados:</OTypography>

                <div className="d-flex flex-wrap gap-2">
                  {subProfiles.map((profile) => (
                    <OBadge key={profile.uuid} type="dark" size="sm" rounded outline>
                      <div className="d-flex align-items-center gap-2">
                      <OLink
                        href={`/ferramentas/produto/gatekeeper/profiles/${profile.uuid}/informacoes-gerais`}
                        target="_blank"
                      >
                        <OTypography size="sm">
                          {profile.name}
                        </OTypography>
                      </OLink>
                        <UnstyledButton
                          onClick={() => {
                            const filteredProfiles = subProfiles.filter(
                              (p) => p !== profile,
                            );

                            labelRef.current = "";
                            setSubProfiles(filteredProfiles);
                          }}
                        >
                          <OIcon category="fal" icon="fa-times" />
                        </UnstyledButton>
                      </div>
                    </OBadge>
                  ))}
                </div>
              </OCol>
            )}
          </ORow>
        </OCardBody>
      </OCard>
    </DetailsTemplate>
  );
};
