import { UserCommand } from "./types/command";
import { user } from "./user";

class UserManager {
  private user = user;

  async execute(command: UserCommand) {
    await command.execute(this.user);
  }
}

export const userManager = new UserManager();
