import { Roles } from "roles/roles.types";

export const emprestimosRoles = {
  customer: {
    tab: {
      role: "admin:LoanTabCustomer.View",
      description: "Visualização do menu 'Empréstimos' na visão cliente",
    },
    operationsPage: {
      role: "admin:LoanOperationsPageCustomer.View",
      description: "Visualização da página 'Operações' na visão cliente",
    },
    operationsDetailsPage: {
      role: "admin:LoanOperationsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão cliente",
    },
    operationsDetailsApproveDisbursement: {
      role: "admin:LoanOperationsDetailsApproveDisbursementCustomer.View",
      description:
        "Visualização do botão para aprovar desembolso na visão cliente",
    },
    operationsDetailsConfirmStatus: {
      role: "admin:LoanOperationsDetailsConfirmStatusCustomer.View",
      description:
        "Visualização do botão para confirmar status na visão cliente",
    },
    operationsDetailsLoanCancel: {
      role: "admin:LoanOperationsDetailsCancelLoanCustomer.View",
      description:
        "Visualização do botão para cancelar contratação na visão cliente",
    },
    operationsDetailsRepublishTrade: {
      role: "admin:LoanOperationsDetailsRepublishTradeCustomer.View",
      description: "Visualização do botão para reenviar trade na visão cliente",
    },
    operationsDetailsUpdatePosition: {
      role: "admin:LoanOperationsDetailsUpdatePositionCustomer.View",
      description:
        "Visualização do botão para atualizar posição na visão cliente",
    },
    operationsContractsPage: {
      role: "admin:LoanOperationsContractsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Contratos' na visão cliente",
    },
    operationsContractsDataAction: {
      role: "admin:LoanOperationsContractsDataActionCustomer.View",
      description:
        "Visualização da página 'Operações - Contratos' na visão cliente",
    },
    operationsQuotesPage: {
      role: "admin:LoanOperationsQuotesPageCustomer.View",
      description:
        "Visualização da página 'Operações - Parcelas' na visão cliente",
    },
    operationsPrepaymentsConfirmPage: {
      role: "admin:LoanOperationsPrepaymentsConfirmPage.View",
      description:
        "Visualização da página 'Operações - Parcelas - Pré-pagamentos - Parcelas' na visão cliente",
    },
    operationsPrepaymentSuccessPage: {
      role: "admin:LoanOperationsPrepaymentsSuccessPage.View",
      description:
        "Visualização da página 'Operações - Parcelas - Pré-pagamentos - Parcelas - Boleto' na visão cliente",
    },
    operationsPaymentsPage: {
      role: "admin:LoanOperationsPaymentsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação - Pagamentos' na visão cliente",
    },
    operationsDisbursementsPage: {
      role: "admin:LoanOperationsDisbursementsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação - Desembolsos' na visão cliente",
    },
    operationsAgreementsPage: {
      role: "admin:LoanOperationsAgreementsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação - Convênio' na visão cliente",
    },
    operationsPendenciesPage: {
      role: "admin:LoanOperationsPendenciesPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação - Pendências' na visão cliente",
    },
    operationsLogsPage: {
      role: "admin:LoanOperationsLogsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes da operação - Logs' na visão cliente",
    },
    offersPage: {
      role: "admin:LoanOffersPageCustomer.View",
      description: "Visualização da página 'Ofertas' na visão cliente",
    },
    simulationsPage: {
      role: "admin:LoanSimulationsPageCustomer.View",
      description: "Visualização da página 'Simulação' na visão cliente",
    },
    limitManagerPage: {
      role: "admin:LoanLimitManagerPageCustomer.View",
      description:
        "Visualização da página 'Gestor de limites' na visão cliente",
    },
    limitManagerDetailsQuotesPage: {
      role: "admin:LoanLimitManagerDetailsQuotesPageCustomer.View",
      description:
        "Visualização da página 'Gestor de limites - Detalhes - Parcelas' na visão cliente",
    },
    limitManagerDetailsGuarantorsPage: {
      role: "admin:LoanLimitManagerDetailsGuarantorsPageCustomer.View",
      description:
        "Visualização da página 'Gestor de limites - Detalhes - Avalistas' na visão cliente",
    },
    offersPageKgiroCard: {
      role: "admin:LoanOffersKgiroCardCustomer.View",
      description:
        "Visualização do card de Capital de giro da página 'Ofertas' na visão cliente",
    },
    offersPageSoftwareFinancingCard: {
      role: "admin:LoanOffersSoftwareFinancingCardCustomer.View",
      description:
        "Visualização do card de Financiamento de software da página 'Ofertas' na visão cliente",
    },
    offersPageSolarCard: {
      role: "admin:LoanOffersSolarCardCustomer.View",
      description:
        "Visualização do card de Solar da página 'Ofertas' na visão cliente",
    },
    offersPageAgroCard: {
      role: "admin:LoanOffersAgroCardCustomer.View",
      description:
        "Visualização do card de Agro da página 'Ofertas' na visão cliente",
    },
    installmentsPageDownloadBankslip: {
      role: "admin:InstallmentsDownloadBankslipCustomer.View",
      description:
        "Visualização do botão de download de boleto da página 'Operações - Parcelas' na visão cliente",
    },
    installmentsPageCopyBankslipToClipboard: {
      role: "admin:InstallmentsCopyBankslipToClipboardCustomer.View",
      description:
        "Visualização do botão de copiar linha digitável do boleto da página 'Operações - Parcelas' na visão cliente",
    },
    installmentsPageResendBankslipViaEmail: {
      role: "admin:InstallmentsResendBankslipViaEmailCustomer.View",
      description:
        "Visualização do botão de reenvio de boleto por e-mail da página 'Operações - Parcelas' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:LoanTabProduct.View",
      description: "Visualização do menu 'Empréstimos' na visão produto",
    },
    loanPage: {
      role: "admin:LoanLoanPageProduct.View",
      description: "Visualização da página 'Empréstimos' na visão produto",
    },
    loanDetailsSummaryPage: {
      role: "admin:LoanLoanDetailsSummaryPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Resumo' na visão produto",
    },
    loanDetailsDisbursementsPage: {
      role: "admin:LoanLoanDetailsDisbursementsPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Desembolsos' na visão produto",
    },
    loanDetailsContingencyPage: {
      role: "admin:LoanLoanDetailsContingencyPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Contingência' na visão produto",
    },
    loanDetailsContractsPage: {
      role: "admin:LoanLoanDetailsContractsPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Contratos' na visão produto",
    },
    loanDetailsAgreementsPage: {
      role: "admin:LoanLoanDetailsAgreementsPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Convênio' na visão produto",
    },
    loanDetailsPendenciesPage: {
      role: "admin:LoanLoanDetailsPendenciesPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Pendências' na visão produto",
    },
    loanDetailsLogsPage: {
      role: "admin:LoanLoanDetailsLogsPageProduct.View",
      description:
        "Visualização da página 'Empréstimos -  Detalhes do Emprestimo - Logs' na visão produto",
    },
    installmentsPage: {
      role: "admin:LoanInstallmentsPageProduct.View",
      description: "Visualização da página 'Parcelas' na visão produto",
    },
    tradeImportsPage: {
      role: "admin:LoanTradeImportsPageProduct.View",
      description:
        "Visualização da página 'Importações de Trade' na visão produto",
    },
    tradeImportsFtsPage: {
      role: "admin:LoanTradeImportsFtsPageProduct.View",
      description:
        "Visualização da página 'Importações de Trade - Importações de Trade - FTS' na visão produto",
    },
    logsPage: {
      role: "admin:LoanLogsPageProduct.View",
      description: "Visualização da página 'Logs' na visão produto",
    },
    logsDetailsPage: {
      role: "admin:LoanLogsDetailsPageProduct.View",
      description: "Visualização da página 'Logs - Detalhes' na visão produto",
    },
    partnersPage: {
      role: "admin:LoanPartnersPageProduct.View",
      description: "Visualização da página 'Parceiros' na visão produto",
    },
    partnersAddPage: {
      role: "admin:LoanPartnersAddPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Cadastrar' na visão produto",
    },
    partnersEditPage: {
      role: "admin:LoanPartnersEditPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Editar' na visão produto",
    },
    partnersPartnerDetailsPage: {
      role: "admin:LoanPartnersPartnerDetailsPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Parceiro - Detalhes' na visão produto",
    },
    partnersPartnerDetailsAssociateAgreementPage: {
      role: "admin:LoanPartnersPartnerDetailsAssociateAgreementPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Parceiro - Detalhes - Associar convênio' na visão produto",
    },
    contingencyConfigVariablePage: {
      role: "admin:LoanContingencyConfigVariablePageProduct.View",
      description:
        "Visualização da página 'Contingência - Variáveis de configuração' na visão produto",
    },
    contingencyAddConfigVariablePage: {
      role: "admin:LoanAddContingencyConfigVariablePageProduct.View",
      description:
        "Visualização da página 'Contingência - Variáveis de configuração - Adcionar' na visão produto",
    },
    contingencyContractsConfigPage: {
      role: "admin:LoanContingencyContractsConfigPageProduct.View",
      description:
        "Visualização da página 'Contingência - Templates de contrato' na visão produto",
    },
    contingencyContractsConfigAddPage: {
      role: "admin:LoanContingencyContractsConfigAddPageProduct.View",
      description:
        "Visualização da página 'Contingência - Templates de contrato - Adicionar' na visão produto",
    },
    contingencyContractsConfigDetailsPage: {
      role: "admin:LoanContingencyContractsConfigDetailsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Templates de contrato - Detalhes' na visão produto",
    },
    contingencyContractsConfigDetailsEditPage: {
      role: "admin:LoanContingencyContractsConfigDetailsEditPageProduct.View",
      description:
        "Visualização da página 'Contingência - Templates de contrato - Detalhes - Editar' na visão produto",
    },
    contingencyCreditLineConfigurationsPage: {
      role: "admin:LoanContingencyCreditLineConfigurationsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Linhas de crédito' na visão produto",
    },
    contingencyCreditLineConfigurationsAddPage: {
      role: "admin:LoanContingencyCreditLineConfigurationsAddPageProduct.View",
      description:
        "Visualização da página 'Contingência - Linhas de crédito - Adicionar' na visão produto",
    },
    contingencyCreditLineConfigurationsUpdatePage: {
      role: "admin:LoanContingencyCreditLineConfigurationsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Linhas de crédito - Atualizar' na visão produto",
    },
    contingencyBooksAndStrategiesPage: {
      role: "admin:LoanContingencyBooksAndStrategiesPageProduct.View",
      description:
        "Visualização da página 'Contingência - Books / Estratégias' na visão produto",
    },
    contingencyBooksAndStrategiesAddPage: {
      role: "admin:LoanContingencyBooksAndStrategiesAddPageProduct.View",
      description:
        "Visualização da página 'Contingência - Books / Estratégias - Adicionar' na visão produto",
    },
    contingencyBooksAndStrategiesAddBookPage: {
      role: "admin:LoanContingencyBooksAndStrategiesAddBookPageProduct.View",
      description:
        "Visualização da página 'Contingência - Books / Estratégias - Adicionar book' na visão produto",
    },
    contingencyBooksAndStrategiesAddStrategyPage: {
      role: "admin:LoanContingencyBooksAndStrategiesAddStrategyPageProduct.View",
      description:
        "Visualização da página 'Contingência - Books / Estratégias - Adicionar estratégia' na visão produto",
    },
    agreementsPage: {
      role: "admin:LoanAgreementsPageProduct.View",
      description: "Visualização da página 'Convênios' na visão produto",
    },
    agreementsAddPage: {
      role: "admin:LoanAgreementsAddPageProduct.View",
      description:
        "Visualização da página 'Convênios - Adicionar' na visão produto",
    },
    agreementsCreateProfilePage: {
      role: "admin:LoanAgreementsCreateProfilePageProduct.View",
      description:
        "Visualização da página 'Convênios - Cadastrar Perfis' na visão produto",
    },
    agreementsDetailsEditPage: {
      role: "admin:LoanAgreementsDetailsEditPageProduct.View",
      description:
        "Visualização da página 'Convênios - Convênios - Editar' na visão produto",
    },
    limitManagerLimitsPage: {
      role: "admin:LoanLimitManagerLimitsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes' na visão produto",
    },
    limitManagerLimitsAddPage: {
      role: "admin:LoanLimitManagerLimitsAddPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Adicionar' na visão produto",
    },
    limitManagerLimitsDetailsSummaryPage: {
      role: "admin:LoanLimitManagerLimitsDetailsSummaryPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Resumo' na visão produto",
    },
    limitManagerLimitsDetailsConditionsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsConditionsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Condições' na visão produto",
    },
    limitManagerLimitsDetailsTransactionsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsTransactionsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Transações' na visão produto",
    },
    limitManagerLimitsDetailsTransactionsAddPage: {
      role: "admin:LoanLimitManagerLimitsDetailsTransactionsAddPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Transações - Adicionar' na visão produto",
    },
    limitManagerLimitsDetailsGuarantorsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsGuarantorsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Avalistas' na visão produto",
    },
    limitManagerLimitsDetailsGuarantorsAddPage: {
      role: "admin:LoanLimitManagerLimitsDetailsGuarantorsAddPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Avalistas - Adicionar' na visão produto",
    },
    limitManagerLimitsDetailsInvoicesPage: {
      role: "admin:LoanLimitManagerLimitsDetailsInvoicesPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Notas fiscais' na visão produto",
    },
    limitManagerLimitsDetailsInvoicesEditPage: {
      role: "admin:LoanLimitManagerLimitsDetailsInvoicesEditPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Notas fiscais - Editar' na visão produto",
    },
    limitManagerLimitsDetailsFinancedAssetsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsFinancedAssetsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Ativos financiados' na visão produto",
    },
    limitManagerLimitsDetailsFinancedAssetsDetailsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsFinancedAssetsDetailsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Ativos financiados - Detalhes' na visão produto",
    },
    limitManagerLimitsDetailsCollateralsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsCollateralsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Garantias' na visão produto",
    },
    limitManagerLimitsDetailsCollateralsAddPage: {
      role: "admin:LoanLimitManagerLimitsDetailsCollateralsAddPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Garantias - Adicionar' na visão produto",
    },
    limitManagerLimitsDetailsCollateralsUpdatePage: {
      role: "admin:LoanLimitManagerLimitsDetailsCollateralsUpdatePageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Garantias - Editar' na visão produto",
    },
    limitManagerLimitsDetailsCollateralsDetailsSafraPage: {
      role: "admin:LoanLimitManagerLimitsDetailsCollateralsDetailsSafraPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Garantias - Garantias - Safra' na visão produto",
    },
    limitManagerLimitsDetailsCollateralsDetailsImovelPage: {
      role: "admin:LoanLimitManagerLimitsDetailsCollateralsDetailsImovelPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Garantias - Garantias - Imóvel' na visão produto",
    },
    limitManagerLimitsDetailsAdditionalFeesPage: {
      role: "admin:LoanLimitManagerLimitsDetailsAdditionalFeesPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Taxas Adicionais' na visão produto",
    },
    limitManagerLimitsDetailsDisbursementPage: {
      role: "admin:LoanLimitManagerLimitsDetailsDisbursementPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Desembolso' na visão produto",
    },
    limitManagerLimitsDetailsAdditionalOffersPage: {
      role: "admin:LoanLimitManagerLimitsDetailsAdditionalOffersPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Ofertas adicionais' na visão produto",
    },
    limitManagerLimitsDetailsContractTemplatesPage: {
      role: "admin:LoanLimitManagerLimitsDetailsContractTemplatesPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Templates de contrato' na visão produto",
    },
    limitManagerLimitsDetailsExternalIdentificationsPage: {
      role: "admin:LoanLimitManagerLimitsDetailsExternalIdentificationsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Identificações externas' na visão produto",
    },
    limitManagerLimitsDetailsContingenciesPage: {
      role: "admin:LoanLimitManagerLimitsDetailsContingenciesPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Contingências' na visão produto",
    },
    limitManagerLimitsDetailsContingenciesUpdateAmountPage: {
      role: "admin:LoanLimitManagerLimitsDetailsContingenciesUpdateAmountPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites Vigentes - Gestor de limite: Detalhes - Contingências - Atualizar valor' na visão produto",
    },
    limitManagerLimitRequestsPage: {
      role: "admin:LoanLimitManagerLimitRequestsPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Solicitações Pendentes' na visão produto",
    },
    limitManagerLimitRequestsInactivesPage: {
      role: "admin:LoanLimitManagerLimitRequestsInactivesPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites inativos' na visão produto",
    },
    limitManagerLimitRequestsInactivesDetailsSummaryPage: {
      role: "admin:LoanLimitManagerLimitRequestsInactivesDetailsSummaryPageProduct.View",
      description:
        "Visualização da página 'Gestor de limites - Limites inativos: Detalhes - Gestor de limite: Detalhes - Resumo' na visão produto",
    },
    contractGeneratorPage: {
      role: "admin:LoanContractGeneratorPageProduct.View",
      description:
        "Visualização da página 'Gerador de contratos' na visão produto",
    },
    dashboardPage: {
      role: "admin:LoanDashboardPageProduct.View",
      description: "Visualização da página 'Dashboard' na visão produto",
    },
  },
} satisfies Roles;
