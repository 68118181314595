import { useServiceCall } from "hooks/service-call";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { service } from "services";
import {
  GetCardAccountsSummaryResponse,
  GetCardCardsSummaryResponse,
  GetCardContestationsSummaryResponse,
  GetCardOffersSummaryResponse,
  GetCardTransactionsSummaryResponse,
} from "services/admin-bankinghub/card/models";

export interface CardsDashboardContext {
  accountsSummary: {
    loading: boolean;
    hasError: boolean;
    value: GetCardAccountsSummaryResponse | undefined;
    callService: () => Promise<unknown>;
  };
  cardsSummary: {
    loading: boolean;
    hasError: boolean;
    value: GetCardCardsSummaryResponse | undefined;
    callService: () => Promise<unknown>;
  };
  contestationsSummary: {
    loading: boolean;
    hasError: boolean;
    value: GetCardContestationsSummaryResponse | undefined;
    callService: () => Promise<unknown>;
  };
  offersSummary: {
    loading: boolean;
    hasError: boolean;
    value: GetCardOffersSummaryResponse | undefined;
    callService: () => Promise<unknown>;
  };
  transactionsSummary: {
    loading: boolean;
    hasError: boolean;
    value: GetCardTransactionsSummaryResponse | undefined;
    callService: () => Promise<unknown>;
  };
}

const cardsDashboardContext = createContext({} as CardsDashboardContext);

interface CardsDashboardProps {
  children: React.ReactNode;
}

export const CardsDashboardProvider = ({ children }: CardsDashboardProps) => {
  const accountsSummary = useServiceCall(
    service.adminBankinghub.card.getAccountsSummary,
  );
  const cardsSummary = useServiceCall(
    service.adminBankinghub.card.getCardsSummary,
  );
  const contestationsSummary = useServiceCall(
    service.adminBankinghub.card.getContestationsSummary,
  );
  const offersSummary = useServiceCall(
    service.adminBankinghub.card.getOffersSummary,
  );
  const transactionsSummary = useServiceCall(
    service.adminBankinghub.card.getTransactionsSummary,
  );

  const getAccountsSummary = accountsSummary.callService;
  const getCardsSummary = cardsSummary.callService;
  const getContestationsSummary = contestationsSummary.callService;
  const getOffersSummary = offersSummary.callService;
  const getTransactionsSummary = transactionsSummary.callService;

  useEffect(() => {
    getAccountsSummary();
    getCardsSummary();
    getContestationsSummary();
    getOffersSummary();
    getTransactionsSummary();
  }, [
    getAccountsSummary,
    getCardsSummary,
    getContestationsSummary,
    getOffersSummary,
    getTransactionsSummary,
  ]);

  const value = useMemo(
    () => ({
      accountsSummary,
      cardsSummary,
      contestationsSummary,
      offersSummary,
      transactionsSummary,
    }),
    [
      accountsSummary,
      cardsSummary,
      contestationsSummary,
      offersSummary,
      transactionsSummary,
    ],
  );

  return (
    <cardsDashboardContext.Provider value={value}>
      {children}
    </cardsDashboardContext.Provider>
  );
};

export const useCardsDashboard = () => useContext(cardsDashboardContext);
