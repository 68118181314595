import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { NameAndTaxIdFormatted } from "components/name-and-tax-id-formatted";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { format } from "utils/date";

type DataReview = NonNullable<
  PostAdminCompaniesGetByTaxIdResponse["dataReviews"]
>[number];

export const grid: ODataGridGeneratorConfig<DataReview> = {
  datagrid: {
    noDataText: "Nenhuma revisão cadastral",
  },
  columns: [
    { dataField: "status", caption: "Status" },
    { dataField: "notificationStatus", caption: "Status da notificação" },
    {
      dataField: "type",
      caption: "Tipo",
      calculateCellValue: (data) => data.type || "REVISAO_CADASTRAL_PJ_PME",
    },
    {
      dataField: "insertedAt",
      caption: "Data de criação",
      format: (value) => format.dateTime(value),
    },
    {
      dataField: "updatedAt",
      caption: "Última atualização",
      format: (value) => format.dateTime(value),
    },
    {
      dataField: "isRegulatory",
      caption: "É regulatório?",
    },
    {
      dataField: "createdByEmail",
      caption: "Criado por",
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.createdByEmail}
          taxId={data.createdByTaxId}
        />
      ),
    },
    {
      dataField: "externalReference",
      caption: "Id",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) =>
        data.externalReference != null && (
          <DataGridAction
            actions={[
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                route:
                  corporateRouter.routes.cadastro.customer.details.onboardingReviews.details.edit.path(
                    {
                      uuid: data.externalReference,
                    },
                  ),
                role: roles.cadastro.customer.onboardingReviewsEditPage.role,
              },
            ]}
          />
        ),
    },
  ],
};
