import { modalManager } from "@maestro/core";
import {
  OBadge,
  OButton,
  ODivider,
  OIcon,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCardsCustomerContext } from "../../../../../../../../contexts";
import { RequirementsModalId } from "./requirements-modal.types";
import { MapperRequirementStatus } from "./requirements-modal.utils";
import { useRequirementsModal } from "./requirements-modal.hook";

export const RequirementsModal = () => {
  const { offer } = useCardsCustomerContext();

  const { data } = useRequirementsModal(offer);

  const navigate = useNavigate();

  return (
    <OModal id={RequirementsModalId} position="center" size="md">
      <OModalHeader closeButton>
        <OTypography type="primary" size="lg">
          Etapas da solicitação
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <OTypography type="primary-80">
            Verifique as etapas para a solicitação do cartão. O pedido será
            enviado para análise após as aprovações necessárias.
          </OTypography>
          {data.map(({ key, status, title, description, link }) => {
            const [label, icon, category, iconType, badgeType] =
              MapperRequirementStatus[status];
            return (
              <React.Fragment key={key}>
                <div className="d-flex flex-row gap-3 align-items-center">
                  <OBadge
                    className="d-flex flex-row gap-2"
                    type={badgeType}
                    rounded
                  >
                    <OIcon
                      category={category}
                      icon={icon}
                      size="lg"
                      type={iconType}
                    />
                    <OTypography type="primary" size="sm">
                      {label}
                    </OTypography>
                  </OBadge>
                  <div className="d-flex flex-column gap-1">
                    <OTypography type="dark" weight="500">
                      {title}
                    </OTypography>
                    <OTypography type="dark-80">{description}</OTypography>

                    {link && (
                      <OButton
                        type="primary"
                        size="sm"
                        outline
                        bordered={false}
                        disabled={!offer}
                        onClick={() => {
                          navigate(link.url);
                        }}
                      >
                        <div className="d-flex flex-row gap-2">
                          <OTypography type="primary" size="sm">
                            {link.text}
                          </OTypography>
                          <OIcon
                            className="align-self-center"
                            category="orq"
                            icon="orq-chevron-right"
                            type="primary"
                          />
                        </div>
                      </OButton>
                    )}
                  </div>
                </div>
                <ODivider size="xxs" />
              </React.Fragment>
            );
          })}
        </div>
      </OModalBody>
      <OModalFooter divider={false}>
        <ORow align="end">
          <OButton
            type="primary"
            outline
            onClick={() => modalManager.hide(RequirementsModalId)}
          >
            Voltar
          </OButton>
        </ORow>
      </OModalFooter>
    </OModal>
  );
};
