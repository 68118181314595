/* eslint-disable no-param-reassign */

import axios from "axios";
import { auth } from "contexts/auth";
import { logger } from "utils/logger";
import { onboardingRelationshipsEndpoints } from "../onboarding-relationships.endpoints";
import { PostGuarantorLoadGuarantorsBody } from "./models/requests/post-guarantor-load-guarantors.request";
import { PostGuarantorOnboardingGarantorBody } from "./models/requests/post-guarantor-onboarding-guarantor.request";
import { PostGuarantorLoadGuarantorsResponse } from "./models/responses/post-guarantor-load-guarantors.response";

const clientB2C = axios.create();

clientB2C.interceptors.request.use((config) => {
  config.headers.Authorization = auth.value;
  return config;
});

clientB2C.interceptors.response.use(null, (err) => {
  logger.error(err);
  return Promise.reject(err);
});

export const onboardingRelationshipsB2CService = {
  loadGuarantors(body: PostGuarantorLoadGuarantorsBody, taxIdHeader: string) {
    return clientB2C.post<PostGuarantorLoadGuarantorsResponse>(
      onboardingRelationshipsEndpoints.guarantor.loadGuarantors,
      body,
      {
        headers: {
          "x-identification": taxIdHeader,
        },
      },
    );
  },
  onboardingGuarantor(
    body: PostGuarantorOnboardingGarantorBody,
    taxIdHeader: string,
  ) {
    return clientB2C.post(
      onboardingRelationshipsEndpoints.guarantor.onboardingGuarantor,
      body,
      {
        headers: {
          "x-identification": taxIdHeader,
        },
      },
    );
  },
};
