import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosProdutoGestorLimitesByIdAndByCollateralId } from "../../../../../../../../routes/emprestimos.route-params";
import { agroAdditionalDataDetailsGenerator } from "./agro-additional-data.details";

export const AgroAdditionalDataPage = () => {
  const { id, collateralId } =
    useParams<EmprestimosProdutoGestorLimitesByIdAndByCollateralId>();

  if (!id || !collateralId) throw new Error("No id or collateralId");

  const {
    callService: getLimitRequest,
    value: limitRequest,
    hasError: hasErrorLimitRequest,
    loading: loadingLimitRequest,
  } = useServiceCall(service.hubCreditManager.getLimitRequest);

  useEffect(() => {
    getLimitRequest(id);
  }, [getLimitRequest, id]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Dados adicionais da garantia" />}
    >
      <DetailsCard
        loading={loadingLimitRequest}
        hasError={hasErrorLimitRequest}
        value={limitRequest}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getLimitRequest(id)} />
          </ErrorComponent>
        }
        fields={(limit) =>
          agroAdditionalDataDetailsGenerator(
            limit.collaterals.find(
              (collateral) => collateral.id === +collateralId,
            ),
            limit.goal,
          )
        }
      />
    </DetailsTemplate>
  );
};
