import styled from "styled-components";

export const Container = styled.div`
  height: 50vh;
  * {
    > .react-flow__attribution {
      display: none;
    }
  }
`;
