import {
  OCol,
  ODivider,
  OIcon,
  OLink,
  OLoader,
  OOption,
  ORFieldSelect,
  ORFieldUploadInput,
  ORow,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GridTemplate } from "templates/grid-template";
import { UploadContainer } from "../../grupos/_compose/upload-group-file/upload-group-file.styles";
import { batchImportPayersGrid } from "./batch-import.grid";
import { useBatchImportPayers } from "./batch-import.hook";

export const BatchImportPayers = () => {
  const {
    chosenConfiguration,
    payers,
    payersWithError,
    possibleConfigurations,
    form,
    handleAddFile,
    loading,
    loadingDesks,
    loadingImport,
    readerLoader,
    submitPayers,
    validationResults,
  } = useBatchImportPayers();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Importar em lote" />}
      actions={
        <LoadingButton
          dataAction="importar_em_lote:botao_importar_onboardings"
          dataLabel="botao_importar_onboardings"
          loading={loadingImport}
          disabled={
            !chosenConfiguration ||
            !payers ||
            (validationResults && validationResults.failures.length > 0)
          }
          onClick={() => submitPayers()}
        >
          Importar Onboardings
        </LoadingButton>
      }
    >
      <CardTemplate>
        {(loading || readerLoader) && <OLoader absolute />}

        <FormProvider {...form}>
          <ORow>
            <OCol xs={6}>
              <ORFieldSelect
                dataAction="importar_correcoes:select:configuracoes"
                dataLabel="configuracoes"
                id="configurationId"
                name="configurationId"
                label="Fluxo onboarding"
                disabled={loadingDesks || !possibleConfigurations}
              >
                {possibleConfigurations?.map((desk) => (
                  <OOption
                    key={desk.configurationId}
                    value={desk.configurationId}
                  >
                    {desk.configurationDisplayName}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
          <ODivider size="xs" className="my-4" />
          <ORow justify="end">
            <OCol xs={2} className="d-flex justify-content-end mb-3">
              <OLink href="/files/modelo_importacao_sacados_simples.xlsx">
                <OIcon
                  category="fas"
                  icon="fa-external-link"
                  className="me-2"
                />
                Baixar modelo
              </OLink>
            </OCol>
          </ORow>
          <UploadContainer>
            <ORFieldUploadInput
              id="correction"
              name="correction"
              dataAction="importar_correcoes:upload:documento"
              dataLabel="documento"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              inputLabel="Selecionar documento"
              multiple={false}
              disabled={loading || !chosenConfiguration}
              handleAddFile={handleAddFile}
              value={[]}
            />
          </UploadContainer>
        </FormProvider>
        {payers && (
          <GridTemplate>
            <ODataGridGenerator
              grid={batchImportPayersGrid}
              dataSource={payersWithError ?? payers}
            >
              <Summary>
                <TotalItem
                  column="failure"
                  summaryType="sum"
                  customizeText={() =>
                    `Total de erros: ${
                      validationResults ? validationResults.failures.length : 0
                    }`
                  }
                />
              </Summary>
            </ODataGridGenerator>
          </GridTemplate>
        )}
      </CardTemplate>
    </FormTemplate>
  );
};
