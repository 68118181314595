import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/role-form/role-form.form";
import { FormValuesType } from "../_compose/role-form/role-form.types";

export const useGatekeeperRolesAdd = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const { type, resource, action, description } = values;
      const newName = `${type}:${resource}.${action}`;

      const payload = {
        role: {
          description,
          name: newName,
        },
      };

      await service.gatekeeper.addRole(payload);

      OToastManager.success("Role adicionada com sucesso");

      navigate(routes.ferramentas.product.gatekeeper.roles.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
