import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { DocumentUploadWithoutForm } from "../document-upload-without-form";
import { useDocumentTemplateModal } from "./document-template-modal.hook";
import { DocumentTemplateModalProps } from "./document-template-modal.types";

export const documentTemplateModalId = (relatedId: number) =>
  `document-template-modal-${relatedId}`;

export const DocumentTemplateModal = (props: DocumentTemplateModalProps) => {
  const {
    createDocumentTemplate,
    createTemplateLoading,
    documentTemplate,
    removeDocumentTemplate,
    removeTemplateLoading,
  } = useDocumentTemplateModal(props);

  const { propertyId, propertyConfigId, onModalClose } = props;

  const modalId = documentTemplateModalId(propertyId ?? propertyConfigId);

  return (
    <OModal
      style={{ position: "absolute" }}
      id={modalId}
      position="center"
      backdrop
      onModalClose={onModalClose}
      {...datagridModalFix}
    >
      <OModalHeader>
        <OTypography size="lg">Template do documento</OTypography>
      </OModalHeader>
      <OModalBody>
        {documentTemplate ? (
          <DocumentUploadWithoutForm document={documentTemplate} onlyOneFile />
        ) : (
          <OTypography>Nenhum template do documento</OTypography>
        )}
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Fechar
          </OButton>
          {documentTemplate ? (
            <LoadingButton
              loading={removeTemplateLoading}
              onClick={removeDocumentTemplate}
              type="danger"
            >
              Remover template
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={createTemplateLoading}
              onClick={createDocumentTemplate}
              type="info"
            >
              Adicionar template do documento
            </LoadingButton>
          )}
        </div>
      </OModalFooter>
    </OModal>
  );
};
