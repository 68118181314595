import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { PostLoadAccountOpeningResponse } from "services/onboarding/models/responses";

export const useEnableCurrentAccount = () => {
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [accountOpeningData, setAccountOpeningData] =
    useState<PostLoadAccountOpeningResponse>();

  const {
    customer: { identification },
  } = useCustomer();

  const fetch = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.loadAccountOpening({
        taxId: identification,
      });

      setAccountOpeningData(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [identification]);

  const createAccountOpening = useCallback(async () => {
    try {
      setCreateLoading(true);

      await service.onboarding.createAccountOpening({
        taxId: identification,
      });

      OToastManager.success("Solicitação enviada com sucesso.");
    } catch (err) {
      if (isAxiosError<HubOnboarding.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.errorMessage && OToastManager.danger(failure.errorMessage);
        });
      }
    } finally {
      setCreateLoading(false);
    }
  }, [identification]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    loading,
    hasError,
    accountOpeningData,
    createLoading,
    fetch,
    createAccountOpening,
  };
};
