import { Roles } from "roles/roles.types";

export const billingRoles = {
  product: {
    tab: {
      role: "admin:BillingTabProduct.View",
      description: "Visualização da tab 'Billing' na visão Produto",
    },
    customersPage: {
      role: "admin:BillingCustomersPageProduct.View",
      description:
        "Visualização da página de 'Billing - Clientes' na visão Produto",
    },
    customersRateCustomizationPage: {
      role: "admin:BillingCustomersRateCustomizationPageProduct.View",
      description:
        "Visualização da página de 'Billing - Clientes - Customização de Tarifa' na visão Produto",
    },
  },
} satisfies Roles;
