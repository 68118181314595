import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { AdminTerms, CustomerServiceTerms } from "./_pages";

export const Terms = () => {
  const { hasRole } = useRoles();

  const hasAdminPage = hasRole(roles.cadastro.customer.contractsPage.role);

  return hasAdminPage ? <AdminTerms /> : <CustomerServiceTerms />;
};
