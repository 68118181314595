import * as yup from "yup";

export interface ConfiguraWorkTimeFormValues {
  start: string;
  end: string;
}

export const configureWorkTimeDefaultValues = (
  operation: BankingHub.admin.Operation,
) => ({
  start: operation.start,
  end: operation.end,
});

export const configureWorkTimeSchema: yup.ObjectSchema<ConfiguraWorkTimeFormValues> =
  yup.object({
    start: yup.string().required("Este campo é obrigatório"),
    end: yup.string().required("Este campo é obrigatório"),
  });
