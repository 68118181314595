import { OCol, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { ORFieldSelectSearch } from "components/select-search";
import { useCategorizationFields } from "./categorization-fields.hook";

interface CategorizationsFieldsProps {
  isCorban?: boolean;
}

export const CategorizationsFields = ({
  isCorban = false,
}: CategorizationsFieldsProps) => {
  const {
    loadingOriginatorSubtypes,
    loadingPartnershipTypes,
    loadingProducts,
    originatorSubtypeOptions,
    partnershipTypeOptions,
    productOptions,
    partnershipTypeName,
  } = useCategorizationFields();

  return (
    <ORow>
      <OCol lg={6} xs={12}>
        <ORFieldSelectSearch
          label="Tipo de Parceria"
          name="partnershipTypeName"
          id="partnershipTypeName"
          placeholder="Selecione"
          value={partnershipTypeName}
          disabled={isCorban || loadingPartnershipTypes}
          options={partnershipTypeOptions}
        />
      </OCol>
      <OCol lg={6} xs={12}>
        <SelectSearchMultiple
          id="originatorSubTypesCodes"
          name="originatorSubTypesCodes"
          placeholder="Selecione"
          label="Subtipo do Originador"
          options={originatorSubtypeOptions}
          disabled={isCorban || loadingOriginatorSubtypes}
        />
      </OCol>
      <OCol lg={6} xs={12}>
        <SelectSearchMultiple
          id="productsCodes"
          name="productsCodes"
          placeholder="Selecione"
          label="Produtos"
          options={productOptions}
          disabled={isCorban || loadingProducts}
          required
        />
      </OCol>
    </ORow>
  );
};
