import { RedirectWithRoles } from "components/redirect-with-roles";
import { Navigate } from "react-router-dom";
import { Routes } from "routes/routes.types";
import { ExchangeLimitDetailsPage } from "../pages/cliente/limites/[id]/exchange-limit-details.page";
import { ExchangeLimitsPage } from "../pages/cliente/limites/exchange-limits.page";
import { ExchangeQuotesPage } from "../pages/cliente/operacoes";
import { ExchangeQuoteDetailsPage } from "../pages/cliente/operacoes/[id]/exchange-quote-details.page";
import { ExchangeOrdersPage } from "../pages/cliente/ordens";
import { ExchangeCustomerIncorrectOrdersPage } from "../pages/cliente/ordens-incorretas/incorrect-orders.page";
import { ExchangeOrderDetailsPage } from "../pages/cliente/ordens/[id]/exchange-order-details.page";
import { ExchangePreTicketDetailsPage } from "../pages/cliente/pre-tickets/[id]/exchange-pre-ticket-details.page";
import { ExchangePreTicketsPage } from "../pages/cliente/pre-tickets/exchange-pre-tickets.page";
import { ExchangeProductBeneficiaryAnalysisDetailsPage } from "../pages/produto/analises-beneficiarios/[id]/detalhes/exchange-product-beneficiary-analyses-details.page";
import { ExchangeProductBeneficiaryAnalysesPage } from "../pages/produto/analises-beneficiarios/exchange-product-beneficiary-analyses.page";
import { ExchangeProductBeneficiariesDetailsPage } from "../pages/produto/beneficiarios/[id]/detalhes/exchange-product-beneficiaries-details.page";
import { ExchangeProductBeneficiariesPage } from "../pages/produto/beneficiarios/exchange-product-beneficiaries.page";
import { ExchangeProductBeneficiaryBanksPage } from "../pages/produto/contingencia/bancos-de-beneficiarios/exchange-product-beneficiary-banks.page";
import { ExchangeProductBeneficiaryBankFormPage } from "../pages/produto/contingencia/bancos-de-beneficiarios/form/exchange-product-beneficiary-bank-form.page";
import { ExchangeProductUpdateGenericFields } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/[id]/exchange-product-update-generic-fields.page";
import { ExchangeProductCreateGenericFields } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/adicionar/exchange-product-create-generic-fields.page";
import { ExchangeProductGenericFieldsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/campos-genericos/exchange-product-generic-field.page";
import { ExchangeProductUpdateDocumentConfig } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/[id]/exchange-product-update-document-configs.page";
import { ExchangeProductCreateDocumentConfig } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/adicionar/exchange-product-create-document-configs.page";
import { ExchangeProductDocumentConfigsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/exchange-product-document-configs.page";
import { ExchangeProductUpdateDocumentGroup } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/[id]/exchange-product-update-document-group.page";
import { ExchangeProductCreateDocumentGroup } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/adicionar/exchange-product-create-document-group.page";
import { ExchangeProductDocumentGroupsPage } from "../pages/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/exchange-product-document-groups.page";
import { ExchangeProductUpdateNature } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/[id]/exchange-product-update-nature.page";
import { ExchangeProductCreateNature } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/adicionar/exchange-product-create-nature.page";
import { ExchangeProductNaturesPage } from "../pages/produto/contingencia/cadastro-de-naturezas/naturezas/exchange-product-natures.page";
import { ExchangeProductCurrenciesDetailsPage } from "../pages/produto/contingencia/moedas/details/exchange-product-currencies-details.page";
import { ExchangeProductCurrenciesPage } from "../pages/produto/contingencia/moedas/exchange-product-currencies.page";
import { ExchangeProductCurrenciesFormPage } from "../pages/produto/contingencia/moedas/form/exchange-product-currencies-form.page";
import { ExchangeProductCountriesPage } from "../pages/produto/contingencia/paises/exchange-product-countries.page";
import { ExchangeProductCountriesFormPage } from "../pages/produto/contingencia/paises/form/exchange-product-countries.page";
import { ExchangeProductPreticketCreatePage } from "../pages/produto/pre-tickets/adicionar/exchange-product-preticket-create.page";
import { ExchangeProductCommonDomainPage } from "../pages/produto/contingencia/variaveis-configuracao/exchange-product-common-domain.page";
import { ExchangeProductAddCounterpartyPage } from "../pages/produto/contrapartes/adicionar/exchange-product-add-counterparty.page";
import { ExchangeProductCounterpartiesPage } from "../pages/produto/contrapartes/exchange-product-counterparties.page";
import { ExchangeProductDashboardPage } from "../pages/produto/dashboard/exchange-product-dashboard.page";
import { ExchangeProductLimitDetailsPage } from "../pages/produto/limites/[id]/detalhes/exchange-product-limit-details.page";
import { ExchangeProductLimitQuotesDetailsPage } from "../pages/produto/limites/[id]/operacoes/exchange-product-limit-quotes-details.page";
import { ExchangeProductLimitsPage } from "../pages/produto/limites/exchange-product-limits.page";
import { ExchangeProductQuoteDetailsPage } from "../pages/produto/operacoes/[id]/exchange-product-quotes-details.page";
import { ExchangeProductQuotesPage } from "../pages/produto/operacoes/exchange-product-quotes.page";
import { ExchangeProductIncorrectOrdersPage } from "../pages/produto/ordens-incorretas/incorrect-orders.page";
import { ExchangeProductPreTicketDetailsPage } from "../pages/produto/pre-tickets/[id]/[counterparty]/detalhes/exchange-product-pre-ticket-details.page";
import { ExchangeProductPreTicketQuotesDetailsPage } from "../pages/produto/pre-tickets/[id]/[counterparty]/operacoes/exchange-product-pre-ticket-quotes-details.page";
import { ExchangeProductPreTicketsPage } from "../pages/produto/pre-tickets/exchange-product-pre-tickets.page";
import { cambioRoles } from "../roles/cambio.roles";
import { cambioRouteParams } from "./cambio.route-params";
import { ExchangeContingencyPreticketCreatePage } from "../pages/produto/contingencia/pre-tickets/adicionar/exchange-product-preticket-create.page";
import { ExchangeContingencyPreTicketsPage } from "../pages/produto/contingencia/pre-tickets/exchange-product-pre-tickets.page";
import { ExchangeContingencyPreticketEditPage } from "../pages/produto/contingencia/pre-tickets/editar/exchange-product-preticket-edit.page";
import { ExchangeProductContingencyOrdersPage } from "../pages/produto/contingencia/ordens/exchange-product-orders.page";
import { ExchangeProductContingencyOrdersFormPage } from "../pages/produto/contingencia/ordens/form/exchange-product-orders-form.page";
import { ExchangeProductContingencyOrderDetailsPage } from "../pages/produto/contingencia/ordens/[id]/detalhes/exchange-product-order-details.page";
import { ExchangeProductContingencyOrderQuotesDetailsPage } from "../pages/produto/contingencia/ordens/[id]/operacoes/exchange-product-order-quotes-details.page";
import { ExchangeProductContingencyOrderPreTicketsDetailsPage } from "../pages/produto/contingencia/ordens/[id]/pre-tickets/exchange-product-order-pre-tickets-details.page";
import { ExchangeProductOrdersPage } from "../pages/produto/ordens/exchange-product-orders.page";
import { ExchangeProductOrderDetailsPage } from "../pages/produto/ordens/[id]/detalhes/exchange-product-order-details.page";
import { ExchangeProductOrderQuotesDetailsPage } from "../pages/produto/ordens/[id]/operacoes/exchange-product-order-quotes-details.page";
import { ExchangeProductOrderPreTicketsDetailsPage } from "../pages/produto/ordens/[id]/pre-tickets/exchange-product-order-pre-tickets-details.page";
import { ExchangeProductEditCounterpartyPage } from "../pages/produto/contrapartes/editar/exchange-product-edit-counterparty.page";

export const cambioRoutes = {
  customer: {
    name: "",
    path: "/cambio/cliente",
    element: <RedirectWithRoles />,
    children: {
      quotes: {
        name: "Operações",
        path: "/cambio/cliente/operacoes",
        element: <ExchangeQuotesPage />,
        role: cambioRoles.customer.quotesPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/cliente/operacoes/:${cambioRouteParams.id}`,
            element: <ExchangeQuoteDetailsPage />,
            role: cambioRoles.customer.quotesDetailsPage.role,
          },
        },
      },
      orders: {
        name: "Ordens",
        path: "/cambio/cliente/ordens",
        element: <ExchangeOrdersPage />,
        role: cambioRoles.customer.ordersPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/cliente/ordens/:${cambioRouteParams.id}`,
            element: <ExchangeOrderDetailsPage />,
            role: cambioRoles.customer.ordersDetailsPage.role,
          },
        },
      },
      incorrectOrders: {
        name: "Ordens incorretas",
        path: "/cambio/cliente/ordens-incorretas",
        element: <ExchangeCustomerIncorrectOrdersPage />,
        role: cambioRoles.customer.incorrectOrdersPage.role,
      },
      preTickets: {
        name: "Pré-Tickets",
        path: "/cambio/cliente/pre-tickets",
        element: <ExchangePreTicketsPage />,
        role: cambioRoles.customer.preTicketsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/cliente/pre-tickets/:${cambioRouteParams.id}`,
            element: <ExchangePreTicketDetailsPage />,
            role: cambioRoles.customer.preTicketsDetailsPage.role,
          },
        },
      },
      limits: {
        name: "Limites",
        path: "/cambio/cliente/limites",
        element: <ExchangeLimitsPage />,
        role: cambioRoles.customer.limitsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/cliente/limites/:${cambioRouteParams.id}`,
            element: <ExchangeLimitDetailsPage />,
            role: cambioRoles.customer.limitsDetailsPage.role,
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/cambio/produto",
    element: <RedirectWithRoles />,
    children: {
      dashboard: {
        name: "Dashboard",
        path: "/cambio/produto/dashboard",
        element: <ExchangeProductDashboardPage />,
        role: cambioRoles.product.dashboardPage.role,
      },
      quotes: {
        name: "Operações",
        path: "/cambio/produto/operacoes",
        element: <ExchangeProductQuotesPage />,
        role: cambioRoles.product.quotesPage.role,

        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/produto/operacoes/:${cambioRouteParams.id}`,
            element: <ExchangeProductQuoteDetailsPage />,
            role: cambioRoles.product.quotesDetailsPage.role,
          },
        },
      },
      orders: {
        name: "Ordens",
        path: "/cambio/produto/ordens",
        element: <ExchangeProductOrdersPage />,
        role: cambioRoles.product.ordersPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/produto/ordens/:${cambioRouteParams.id}`,
            element: <Navigate replace to="detalhes" />,
            showChildren: true,
            children: {
              order: {
                name: "Detalhes",
                path: `/cambio/produto/ordens/:${cambioRouteParams.id}/detalhes`,
                element: <ExchangeProductOrderDetailsPage />,
                role: cambioRoles.product.ordersDetailsPage.role,
              },
              quotes: {
                name: "Operações",
                path: `/cambio/produto/ordens/:${cambioRouteParams.id}/operacoes`,
                element: <ExchangeProductOrderQuotesDetailsPage />,
                role: cambioRoles.product.ordersDetailsPage.role,
              },
              preTickets: {
                name: "Pré-Tickets",
                path: `/cambio/produto/ordens/:${cambioRouteParams.id}/pre-tickets`,
                element: <ExchangeProductOrderPreTicketsDetailsPage />,
                role: cambioRoles.product.ordersDetailsPage.role,
              },
            },
          },
        },
      },
      incorrectOrders: {
        name: "Ordens incorretas",
        path: "/cambio/produto/ordens-incorretas",
        element: <ExchangeProductIncorrectOrdersPage />,
        role: cambioRoles.product.incorrectOrdersPage.role,
      },
      preTickets: {
        name: "Pré-Tickets",
        path: "/cambio/produto/pre-tickets",
        element: <ExchangeProductPreTicketsPage />,
        role: cambioRoles.product.preTicketsPage.role,
        children: {
          create: {
            name: "Adicionar",
            path: "/cambio/produto/pre-tickets/adicionar",
            element: <ExchangeProductPreticketCreatePage />,
            role: cambioRoles.product.preticketsFormPage.role,
          },
          id: {
            name: "",
            path: `/cambio/produto/pre-tickets/:${cambioRouteParams.id}`,
            children: {
              details: {
                name: "Detalhes",
                path: `/cambio/produto/pre-tickets/:${cambioRouteParams.id}/:${cambioRouteParams.counterparty}`,
                showChildren: true,
                children: {
                  preTicket: {
                    name: "Detalhes",
                    path: `/cambio/produto/pre-tickets/:${cambioRouteParams.id}/:${cambioRouteParams.counterparty}/detalhes`,
                    element: <ExchangeProductPreTicketDetailsPage />,
                    role: cambioRoles.product.preTicketsDetailsPage.role,
                  },
                  quotes: {
                    name: "Operações",
                    path: `/cambio/produto/pre-tickets/:${cambioRouteParams.id}/:${cambioRouteParams.counterparty}/operacoes`,
                    element: <ExchangeProductPreTicketQuotesDetailsPage />,
                    role: cambioRoles.product.preTicketsDetailsPage.role,
                  },
                },
              },
            },
          },
        },
      },
      limits: {
        name: "Limites",
        path: "/cambio/produto/limites",
        element: <ExchangeProductLimitsPage />,
        role: cambioRoles.product.limitsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/produto/limites/:${cambioRouteParams.id}`,
            element: <Navigate replace to="detalhes" />,
            showChildren: true,
            children: {
              limit: {
                name: "Detalhes",
                path: `/cambio/produto/limites/:${cambioRouteParams.id}/detalhes`,
                element: <ExchangeProductLimitDetailsPage />,
                role: cambioRoles.product.limitsDetailsPage.role,
              },
              quotes: {
                name: "Operações",
                path: `/cambio/produto/limites/:${cambioRouteParams.id}/operacoes`,
                element: <ExchangeProductLimitQuotesDetailsPage />,
                role: cambioRoles.product.limitsDetailsPage.role,
              },
            },
          },
        },
      },
      counterparties: {
        name: "Contrapartes",
        path: "/cambio/produto/contrapartes",
        element: <ExchangeProductCounterpartiesPage />,
        role: cambioRoles.product.counterpartiesPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: "/cambio/produto/contrapartes/adicionar",
            element: <ExchangeProductAddCounterpartyPage />,
            role: cambioRoles.product.counterpartiesAddPage.role,
          },
          edit: {
            name: "Editar",
            path: "/cambio/produto/contrapartes/editar",
            element: <ExchangeProductEditCounterpartyPage />,
            role: cambioRoles.product.counterpartiesEditPage.role,
          },
        },
      },
      beneficiaries: {
        name: "Beneficiários",
        path: "/cambio/produto/beneficiarios",
        element: <ExchangeProductBeneficiariesPage />,
        role: cambioRoles.product.beneficiariesPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/produto/beneficiarios/:${cambioRouteParams.id}`,
            element: <ExchangeProductBeneficiariesDetailsPage />,
            role: cambioRoles.product.beneficiariesDetailsPage.role,
          },
        },
      },
      beneficiaryAnalyses: {
        name: "Análises de beneficiários",
        path: "/cambio/produto/analises-beneficiarios",
        element: <ExchangeProductBeneficiaryAnalysesPage />,
        role: cambioRoles.product.beneficiaryAnalysisPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/cambio/produto/analises-beneficiarios/:${cambioRouteParams.id}`,
            element: <ExchangeProductBeneficiaryAnalysisDetailsPage />,
            role: cambioRoles.product.beneficiaryAnalysisDetailsPage.role,
          },
        },
      },
      contingency: {
        name: "Contingência",
        path: "/cambio/produto/contingencia",
        hasSubItems: true,
        children: {
          commonDomain: {
            name: "Variáveis de Configuração",
            path: "/cambio/produto/contingencia/variavies-configuracao",
            element: <ExchangeProductCommonDomainPage />,
            role: cambioRoles.product.contingencyCommonDomainPage.role,
          },
          natures: {
            name: "Cadastro de Naturezas",
            path: "/cambio/produto/contingencia/cadastro-de-naturezas",
            hasSubItems: true,
            children: {
              all: {
                name: "Naturezas",
                path: "/cambio/produto/contingencia/cadastro-de-naturezas/naturezas",
                element: <ExchangeProductNaturesPage />,
                role: cambioRoles.product.contingencyNaturesAllPage.role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/cambio/produto/contingencia/cadastro-de-naturezas/naturezas/adicionar",
                    element: <ExchangeProductCreateNature />,
                    role: cambioRoles.product.contingencyNaturesAllCreatePage
                      .role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/cambio/produto/contingencia/cadastro-de-naturezas/naturezas/:${cambioRouteParams.id}`,
                    element: <ExchangeProductUpdateNature />,
                    role: cambioRoles.product.contingencyNaturesAllUpdatePage
                      .role,
                  },
                },
              },
              documentGroups: {
                name: "Grupo de Documentos",
                path: "/cambio/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos",
                element: <ExchangeProductDocumentGroupsPage />,
                role: cambioRoles.product.contingencyNaturesDocumentGroupsPage
                  .role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/cambio/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/adicionar",
                    element: <ExchangeProductCreateDocumentGroup />,
                    role: cambioRoles.product
                      .contingencyNaturesDocumentGroupsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/cambio/produto/contingencia/cadastro-de-naturezas/grupo-de-documentos/:${cambioRouteParams.id}`,
                    element: <ExchangeProductUpdateDocumentGroup />,
                    role: cambioRoles.product
                      .contingencyNaturesDocumentGroupsUpdatePage.role,
                  },
                },
              },
              documentConfigs: {
                name: "Configuração de Documentos",
                path: "/cambio/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos",
                element: <ExchangeProductDocumentConfigsPage />,
                role: cambioRoles.product.contingencyNaturesDocumentConfigsPage
                  .role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/cambio/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/adicionar",
                    element: <ExchangeProductCreateDocumentConfig />,
                    role: cambioRoles.product
                      .contingencyNaturesDocumentConfigsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/cambio/produto/contingencia/cadastro-de-naturezas/configuracao-de-documentos/:${cambioRouteParams.id}`,
                    element: <ExchangeProductUpdateDocumentConfig />,
                    role: cambioRoles.product
                      .contingencyNaturesDocumentConfigsUpdatePage.role,
                  },
                },
              },
              genericFields: {
                name: "Campos Genéricos",
                path: "/cambio/produto/contingencia/cadastro-de-naturezas/campos-genericos",
                element: <ExchangeProductGenericFieldsPage />,
                role: cambioRoles.product.contingencyNaturesGenericFieldsPage
                  .role,
                children: {
                  create: {
                    name: "Adicionar",
                    path: "/cambio/produto/contingencia/cadastro-de-naturezas/campos-genericos/adicionar",
                    element: <ExchangeProductCreateGenericFields />,
                    role: cambioRoles.product
                      .contingencyNaturesGenericFieldsCreatePage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/cambio/produto/contingencia/cadastro-de-naturezas/campos-genericos/:${cambioRouteParams.id}`,
                    element: <ExchangeProductUpdateGenericFields />,
                    role: cambioRoles.product
                      .contingencyNaturesGenericFieldsUpdatePage.role,
                  },
                },
              },
            },
          },
          preTickets: {
            name: "Pré-Tickets",
            path: "/cambio/produto/contingencia/pre-tickets",
            element: <ExchangeContingencyPreTicketsPage />,
            role: cambioRoles.product.preTicketsContingencyPage.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/cambio/produto/contingencia/pre-tickets/adicionar",
                element: <ExchangeContingencyPreticketCreatePage />,
                role: cambioRoles.product.preticketsContingencyFormPage.role,
              },
              update: {
                name: "Atualizar",
                path: "/cambio/produto/contingencia/pre-tickets/atualizar",
                element: <ExchangeContingencyPreticketEditPage />,
                role: cambioRoles.product.preticketsContingencyFormPage.role,
              },
            },
          },
          beneficiaryBanks: {
            name: "Bancos de Beneficiário",
            path: "/cambio/produto/contingencia/bancos-de-beneficiario",
            element: <ExchangeProductBeneficiaryBanksPage />,
            role: cambioRoles.product.contingencyBeneficiaryBanksPage.role,
            children: {
              form: {
                name: "Formulário",
                path: "/cambio/produto/contingencia/bancos-de-beneficiario/form",
                element: <ExchangeProductBeneficiaryBankFormPage />,
                role: cambioRoles.product.contingencyBeneficiaryBanksFormPage
                  .role,
              },
            },
          },
          currencies: {
            name: "Moedas",
            path: "/cambio/produto/contingencia/moedas",
            element: <ExchangeProductCurrenciesPage />,
            role: cambioRoles.product.contingencyCurrenciesPage.role,
            children: {
              form: {
                name: "Formulário",
                path: "/cambio/produto/contingencia/moedas/form",
                element: <ExchangeProductCurrenciesFormPage />,
                role: cambioRoles.product.contingencyCurrenciesFormPage.role,
              },
              details: {
                name: "Detalhes",
                path: `/cambio/produto/contingencia/moedas/details`,
                element: <ExchangeProductCurrenciesDetailsPage />,
                role: cambioRoles.product.contingencyCurrenciesDetailsPage.role,
              },
            },
          },
          countries: {
            name: "Países",
            path: "/cambio/produto/contingencia/paises",
            element: <ExchangeProductCountriesPage />,
            role: cambioRoles.product.contingencyCountriesPage.role,
            children: {
              form: {
                name: "Formulário",
                path: "/cambio/produto/contingencia/paises/form",
                element: <ExchangeProductCountriesFormPage />,
                role: cambioRoles.product.contingencyCountriesFormPage.role,
              },
            },
          },
          orders: {
            name: "Ordens",
            path: "/cambio/produto/contingencia/ordens",
            element: <ExchangeProductContingencyOrdersPage />,
            role: cambioRoles.product.ordersContingencyPage.role,
            children: {
              form: {
                name: "Formulário",
                path: "/cambio/produto/contingencia/ordens/form",
                element: <ExchangeProductContingencyOrdersFormPage />,
                role: cambioRoles.product.ordersContingencyFormPage.role,
              },
              details: {
                name: "Detalhes",
                path: `/cambio/produto/contingencia/ordens/:${cambioRouteParams.id}`,
                element: <Navigate replace to="detalhes" />,
                showChildren: true,
                children: {
                  order: {
                    name: "Detalhes",
                    path: `/cambio/produto/contingencia/ordens/:${cambioRouteParams.id}/detalhes`,
                    element: <ExchangeProductContingencyOrderDetailsPage />,
                    role: cambioRoles.product.ordersContingencyDetailsPage.role,
                  },
                  quotes: {
                    name: "Operações",
                    path: `/cambio/produto/contingencia/ordens/:${cambioRouteParams.id}/operacoes`,
                    element: (
                      <ExchangeProductContingencyOrderQuotesDetailsPage />
                    ),
                    role: cambioRoles.product.ordersContingencyDetailsPage.role,
                  },
                  preTickets: {
                    name: "Pré-Tickets",
                    path: `/cambio/produto/contingencia/ordens/:${cambioRouteParams.id}/pre-tickets`,
                    element: (
                      <ExchangeProductContingencyOrderPreTicketsDetailsPage />
                    ),
                    role: cambioRoles.product.ordersContingencyDetailsPage.role,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
