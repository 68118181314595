import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { useProductsHomeRoute } from "hooks/products-home-route";
import { useCallback } from "react";
import { useRoles } from "hooks/roles";
import { findChildNodeWithRole } from "../../redirect-with-roles/redirect-with-roles.utils";
import { findProductLevelNode } from "./customer-route.utils";

export const useCustomerRoute = () => {
  const { behavior } = useBehavior();
  const { hasRole } = useRoles();

  const productsRoute = useProductsHomeRoute();

  /**
   * Redirect from customer to product route, with fallbacks
   *
   * Ex: redirect from /cambio/cliente to /cambio/produto
   */
  const getRedirectRoute = useCallback(() => {
    if (behavior.id === Behaviors.CustomerService) return "/";

    const productLevelNode = findProductLevelNode();
    const productNode = productLevelNode?.children.find(({ path }) =>
      /\/produto$/.test(path),
    );

    if (!productNode) return productsRoute ?? "/";

    const path = findChildNodeWithRole(productNode, hasRole)?.path;

    return path ?? productsRoute ?? "/";
  }, [behavior.id, hasRole, productsRoute]);

  return { getRedirectRoute };
};
