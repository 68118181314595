import { Nullable } from "utils/types";

export interface RecommendedAdequacyOptionsForm {
  adequacyOptionIds: number[];
}

export const recommendedAdequacyOptionsFormDefaultValues = {
  adequacyOptionIds: [],
} satisfies Partial<
  Nullable<RecommendedAdequacyOptionsForm>
> as unknown as RecommendedAdequacyOptionsForm;
