import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ReceivablesCustomerByReceivableIdRouteParams } from "../../../../../routes/recebiveis.route-params";
import { StateMachineDetailsForm } from "./receivable.types";

export const useReceivableDetails = () => {
  const { receivableId } =
    useParams<ReceivablesCustomerByReceivableIdRouteParams>();
  const [loading, setLoading] = useState(false);

  const form = useForm<StateMachineDetailsForm>();
  const { handleSubmit, setValue } = form;

  const { callService: getReceivableDetail, value: detail } = useServiceCall(
    service.quickfin.getReceivableStateMachineById,
  );

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const id = Number(receivableId);

      const payload = {
        receivableStatus: values.status,
        receivablesId: [id],
      };

      await service.quickfin.updateReceivableStatus(payload);

      OToastManager.success("Dados atualizados com sucesso");
    } catch (err) {
      OToastManager.danger("Erro para atualizar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!detail) return;

    setValue("status", detail.receivable.receivableStatus);
  }, [detail, setValue]);

  useEffect(() => {
    if (!receivableId) return;

    getReceivableDetail(receivableId);
  }, [getReceivableDetail, receivableId]);

  return {
    detail,
    form,
    loading,
    submit,
  };
};
