import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { ACCPreticketByIdRouteParams } from "pages/acc/routes/acc.route-params";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { adminCustomerAccOrderDetailsGenerator } from "./admin-customer-acc-order.details";

export const AdminCustomerACCOrderDetailsPage = () => {
  const { orderId } = useParams<ACCPreticketByIdRouteParams>();

  const {
    value: counterpartyDetails,
    hasError,
    loading,
    callService: getAccOrderById,
  } = useServiceCall(service.acc.adminCustomer.getAccOrderById);

  useEffect(() => {
    if (!orderId) return;

    getAccOrderById(orderId);
  }, [getAccOrderById, orderId]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da ordem" />}>
      <ContentTemplate
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da contraparte."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getAccOrderById(String(orderId))} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={counterpartyDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccOrderDetailsGenerator}
            />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
