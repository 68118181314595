import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CurrentAccountForm } from "../_compose/current-account-form";
import { useAccountPjNewAccount } from "./new-account.hook";

export const AccountPjNewAccount = () => {
  const { form, submit, loading, setLoading } = useAccountPjNewAccount();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Solicitação de abertura de conta"
          description="Preencha o formulário abaixo para iniciar o seu cadastro."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="convite:botao:iniciar-cadastro"
          dataLabel="iniciar-cadastro"
          onClick={submit}
        >
          Iniciar cadastro
        </LoadingButton>
      }
    >
      <CardTemplate>
        <CurrentAccountForm form={form} setLoading={setLoading} />
      </CardTemplate>
    </FormTemplate>
  );
};
