import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { fidcAnalysisMap } from "../../../domain-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";

interface RiskAnalysisStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const RiskAnalysisStep = ({
  fieldName,
  index,
}: RiskAnalysisStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      `${fieldName}.${index}._t`,
      "RiskAnalysisPartnerConfigurationStep",
    );
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(`${fieldName}.${index}.stepId`, `risk-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="RISKANALYSIS"
      />
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          dataAction={`partner_configuration_scf:input:${fieldName}_${index}_segment`}
          dataLabel={`${fieldName}_${index}_segment`}
          id={`${fieldName}.${index}.segment`}
          name={`${fieldName}.${index}.segment`}
          label="Segmento"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          dataAction={`partner_configuration_scf:input:${fieldName}_${index}_cossegment`}
          dataLabel={`${fieldName}_${index}_cossegment`}
          id={`${fieldName}.${index}.coSegment`}
          name={`${fieldName}.${index}.coSegment`}
          label="Co-segmento"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldSelect
          dataAction={`partner_configuration_scf:select:${fieldName}_${index}_risk_type`}
          dataLabel={`${fieldName}_${index}_risk_type`}
          id={`${fieldName}.${index}.riskAnalysisType`}
          name={`${fieldName}.${index}.riskAnalysisType`}
          label="Nível de risco"
        >
          {Object.entries(fidcAnalysisMap).map(([value, label]) => (
            <OOption key={`onboarding-status-${value}`} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
    </ORow>
  );
};
