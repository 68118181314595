import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { mapCurrencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { buildCustomerLink } from "utils/build-customer-link";
import { loanProductOptions } from "../../../components/utils/loan-products.utils";
import { loanStatus } from "../../../components/utils/loan-status.utils";

export const loanGrid = {
  datagrid: {
    noDataText: "Nenhum empréstimo encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar empréstimos.";
    },
    onRowPrepared: (e) => {
      if (e.data?.pendencyTotal && e.data.pendencyTotal > 0) {
        e.rowElement.style.backgroundColor = "var(--theme-warning-light)";
      }
    },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubLoan.odata.loanAdmin,
    ),
  },
  columns: [
    {
      allowSearch: false,
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.loanDetailsSummaryPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={buildCustomerLink(
            data.counterPartyTaxId,
            corporateRouter.routes.emprestimos.customer.operations.details.path(
              {
                id: data.id,
              },
            ),
          )}
        />
      ),
    },
    {
      dataField: "id",
      caption: "Id",
      allowHeaderFiltering: false,
      sortOrder: "desc",
    },
    {
      caption: "Criado em",
      dataField: "createdDate",
      dataType: "datetime",
      allowHeaderFiltering: false,
    },
    {
      caption: "Produto",
      dataField: "product",
      headerFilter: {
        dataSource: loanProductOptions,
      },
    },
    {
      caption: "Valor",
      dataField: "amount",
      dataType: "number",
      cellRender: ({ data }) =>
        masks.currency(data.amount, mapCurrencySymbol(data.currency)),
      allowSorting: true,
      allowHeaderFiltering: false,
    },
    {
      caption: "Cliente",
      dataField: "counterParty",
      allowHeaderFiltering: false,
    },
    {
      caption: "CNPJ/CPF",
      dataField: "counterPartyTaxId",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Status",
      dataField: "status",
      cellRender: ({ data }) =>
        getValueFromMap(loanStatus, data.status)?.description ? (
          <OTooltip floating position="top-left">
            <span slot="tooltip-content">
              {getValueFromMap(loanStatus, data.status)?.description}
            </span>
            {data.status}
          </OTooltip>
        ) : (
          data.status
        ),
      headerFilter: {
        dataSource: Object.values(loanStatus),
      },
    },
    {
      caption: "Parceiro",
      dataField: "partner",
    },
    {
      dataField: "identification",
      allowHeaderFiltering: false,
    },
    {
      dataField: "externalId",
      allowHeaderFiltering: false,
    },
    {
      caption: "Data do trade",
      dataField: "tradeDate",
      dataType: "datetime",
      allowHeaderFiltering: false,
    },
    {
      dataField: "rate",
      dataType: "number",
      format: "#,##0.0000",
      allowHeaderFiltering: false,
    },
    {
      caption: "Parcelas",
      dataField: "term",
      dataType: "number",
      allowHeaderFiltering: false,
    },
    {
      caption: "CET",
      dataField: "cet",
      dataType: "number",
      format: "#,##0.0000",
      allowHeaderFiltering: false,
    },
    {
      caption: "Motivo da Rejeição",
      dataField: "rejectedStatusCodes",
      allowHeaderFiltering: false,
    },
    {
      caption: "Cotação",
      dataField: "currencyPrice",
      allowHeaderFiltering: false,
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.odata.getLoanAdmin>
    >["data"]["value"]
  >[number]
>;
