import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const approvalGroupsOptions = [
  "Back Office",
  "PME Credit SCF",
  "FTS IT CREDIT",
  "PME Anti-Fraud",
  "PME - Cartão",
  "Credit Risk Control",
].map((item) => ({ value: item, label: item }));

export const ApprovalGroupsField = () => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelectMultiple
        dataAction="geral:texto:grupos_de_aprovacao"
        dataLabel="grupos_de_aprovacao"
        id="approvalGroups"
        name="approvalGroups"
        label={EstrategiaFormLabels.approvalGroups}
      >
        {approvalGroupsOptions.map(({ label, value }) => (
          <OOptionMultiple key={value} value={value}>
            {label}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
    </OCol>
  );
};
