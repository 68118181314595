import { DetailsCard } from "components/details-card";
import { DetailsTemplate } from "templates/details-template";
import {
  ExecuteButton,
  RemoveButton,
  ReprocessButton,
} from "../action-buttons";
import { consumerUnitInfo, importDataInfo } from "./import-data-info.details";

interface ImportDataInfoProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse;
}

export const ImportDataInfo = ({ importData }: ImportDataInfoProps) => {
  return (
    <>
      <DetailsTemplate
        actions={
          !importData?.errors?.length && (
            <>
              <RemoveButton importData={importData} />
              <ReprocessButton importData={importData} />
              <ExecuteButton importData={importData} />
            </>
          )
        }
      >
        <DetailsCard value={importData} fields={importDataInfo} />
      </DetailsTemplate>
      {importData.lead?.freeEnergyMarketMigration?.units?.map((u) => (
        <DetailsCard
          key={`${u.name}${u.taxId}${u.installationNumber}`}
          value={u}
          fields={consumerUnitInfo}
        />
      ))}
    </>
  );
};
