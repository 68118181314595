import { OBadge, OIcon, OLink, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import dayjs from "dayjs";
import { NavigateFunction } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { SettlementOData } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  settlementStatusLibrary,
  settlementTintMap,
  settlementPaymentMethods,
} from "./settlements.utils";

export const grid: (
  navigate: NavigateFunction,
) => ODataGridGeneratorConfig<SettlementOData> = (navigate) => ({
  datagrid: {
    noDataText: "Nenhuma liquidação encontrada",
    dataSource: dataSourceODataStoreGenerator<SettlementOData>(
      endpoints.quickfin.odata.settlementsOData,
      { odataStoreOptions: { key: "SettlementId", keyType: "Guid" } },
    ),
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      role: roles.recebiveis.product.settlementDetailsPage.role,
      cellRender: ({ data }) => (
        <span style={{ width: "88px", display: "inline-block" }}>
          <OLink
            onClick={() => {
              navigate(
                corporateRouter.routes.recebiveis.product.operations.settlements.details.path(
                  {
                    settlementId: data.SettlementId,
                  },
                ),
                { state: data },
              );
            }}
          >
            <u>Detalhes</u>
            <OIcon
              category="fas"
              icon="fa-external-link"
              className="ms-1"
              size="sm"
            />
          </OLink>
        </span>
      ),
    },
    {
      dataField: "SettlementId",
      caption: "Id",
    },
    {
      dataField: "PayerName",
      caption: "Pagador",
      calculateCellValue: (data) =>
        `${data.PayerName} ${masks.cpfCnpj(data.PayerDocument)}`,
      cellRender: ({ data }) => (
        <div>
          <OTypography>{data.PayerName}</OTypography>
          <OTypography size="sm" type="dark-80">
            {masks.cpfCnpj(data.PayerDocument)}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: (filterValue) => [
        ["PayerName", "contains", filterValue],
        "or",
        ["PayerDocument", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      allowSorting: false,
    },
    {
      dataField: "PaymentStatus",
      caption: "Situação",
      allowSorting: false,
      allowSearch: false,
      cellRender: ({ data }) => (
        <OBadge
          size="sm"
          rounded
          type={
            getValueFromMap(settlementTintMap, data.PaymentStatus) ||
            "warning-light"
          }
        >
          {getValueFromMap(settlementStatusLibrary, data.PaymentStatus) ||
            data.PaymentStatus}
        </OBadge>
      ),
      lookup: {
        dataSource: Object.entries(settlementStatusLibrary).map(
          ([key, value]) => ({
            text: value,
            value: key,
          }),
        ),
        valueExpr: "value",
        displayExpr: "text",
      },
    },
    {
      dataField: "PaymentMethod",
      caption: "Forma de pagamento",
      cellRender: ({ data }) => (
        <OTypography>
          {getValueFromMap(settlementPaymentMethods, data.PaymentMethod)}
        </OTypography>
      ),
      allowSorting: false,
      lookup: {
        dataSource: Object.entries(settlementPaymentMethods).map(
          ([key, value]) => ({
            text: value,
            value: key,
          }),
        ),
        valueExpr: "value",
        displayExpr: "text",
      },
      filterValue: "CC",
    },
    {
      dataField: "PaymentDate",
      caption: "Data de pagamento",
      dataType: "date",
      format: "shortDate",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
    },
    {
      dataField: "PaymentError",
      caption: "Mensagem",
      cellRender: ({ data }) => (
        <OTypography size="sm">{data.PaymentError}</OTypography>
      ),
      allowFiltering: false,
      allowSorting: false,
    },
  ],
});
