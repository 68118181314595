import { DetailsFields } from "components/details-card";
import { PostByIdAdminCurrency } from "services/hubfx/models/requests/post-by-id-admin-currencies.request";

export const exchangeCurrencyDetailsGenerator = (
  currency: PostByIdAdminCurrency | null,
) => {
  if (!currency) return {} as DetailsFields;

  return {
    currency: {
      title: "Moeda",
      items: [
        {
          label: "Nome",
          value: currency.description,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Code",
          value: currency.code,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. criação",
          value: currency.createdDate
            ? new Date(currency.createdDate).toISOString().substring(0, 10)
            : "",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Quantidade de casas decimais",
          value: currency.decimals,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. update",
          value: currency.updatedDate
            ? new Date(currency.updatedDate).toISOString().substring(0, 10)
            : "",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    limits: {
      title: "Limites",
      items: [
        {
          label: "Limite padrão",
          value: currency.standardQuoteLimitAmount,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Limite pré-ticket",
          value: currency.preTicketQuoteLimitAmount,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    sameDay: {
      title: "D+0",
      items: [
        {
          label: "sameDayIncomingLimitTime",
          value: currency.sameDayIncomingLimitTime,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "sameDayOutgoingLimitTime",
          value: currency.sameDayOutgoingLimitTime,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    incomingOutgoing: {
      title: "24/7",
      items: [
        {
          label: "incomingStartTime",
          value: currency.incomingStartTime,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "incomingEndTime",
          value: currency.incomingEndTime,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "outgoingStartTime",
          value: currency.outgoingStartTime,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "outgoingEndTime",
          value: currency.outgoingEndTime,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
