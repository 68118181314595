import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { StepConfigFormComponent } from "../_compose";
import { EditStepConfigProvider } from "./edit-step-config.context";
import { useEditStepConfig } from "./edit-step-config.hook";
import { PendencyConfigsGrid, PropertyConfigsGrid } from "./_compose";

export const EditStepConfigPage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getStepConfig,
    submit,
    submitLoading,
    stepConfigId,
  } = useEditStepConfig();

  return (
    <EditStepConfigProvider>
      <DetailsTemplate
        pageTitle={<PageTitle title="Editar configuração de step" />}
        actions={
          <LoadingButton loading={submitLoading} onClick={submit}>
            Salvar
          </LoadingButton>
        }
      >
        <div className="d-flex flex-column gap-4">
          <ContentTemplate
            loading={fetchLoading}
            hasError={fetchHasError}
            errorComponent={
              <ValidationErrorComponent
                callback={getStepConfig}
                error={fetchError}
                title="Erro ao buscar a configuração de step"
              />
            }
            value={fetchValue?.response}
            render={() => (
              <FormProvider {...form}>
                <StepConfigFormComponent />
              </FormProvider>
            )}
          />
          <PropertyConfigsGrid diligenceStepConfigId={Number(stepConfigId)} />
          <PendencyConfigsGrid diligenceStepConfigId={Number(stepConfigId)} />
        </div>
      </DetailsTemplate>
    </EditStepConfigProvider>
  );
};
