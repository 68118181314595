import { useGridRef } from "hooks/grid-ref";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { GetDisverbationApprovalsResponse } from "services/fidc/models/responses/get-disverbation-approvals.response";
import { service } from "services";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
import { isArray } from "lodash";
import { useUser } from "contexts/user";
import { PathDisverbationStatusBody } from "services/fidc/models/requests/path-disverbation-status";
import { disverbationApprovalsGrid } from "./disverbation-approvals.grid";
import { DisverbationFunds } from "./disverbation-approvals.utils";

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("");

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");

export const useDisverbationApprovals = () => {
  const [selectedDisverbations, setSelectedDisverbations] =
    useState<Fidc.Installments[]>();

  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(999);
  const { user } = useUser();
  const gridRef = useGridRef();
  const [loading, setLoading] = useState(false);
  const [selectedFunds, setSelectedFunds] = useState<string[]>([]);
  const [fundsDisverbation, setFundsDisverbation] = useState<
    DisverbationFunds[]
  >([]);
  const [findContractNumber, setFindContractNumber] = useState("");
  const [findFundName, setFindFundName] = useState<string>("");
  const [findStatus, setFindStatus] = useState<string>("PENDING_APPROVAL");
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const updateDataSource = useCallback(() => {
    setUpdateTrigger((prev) => prev + 1);
  }, []);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        GetDisverbationApprovalsResponse["Parcelas"][number]
      >(() =>
        service.fidc
          .getDisverbations(
            buildQuery({
              limit: rows,
              page,
              status: findStatus,
              contractNumber: findContractNumber,
              fundCge: findFundName,
            }),
          )
          .then(({ data }) => {
            setTotalItems(data.TotalCount);
            return {
              data: data.Parcelas,
              totalCount: data.TotalCount || 0,
            };
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro na buscar das parcelas de desaverbação.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [rows, page, findStatus, findContractNumber, findFundName, updateTrigger],
  );

  const rejectedSource = () => {
    setFindStatus("REJECTED");
  };

  const getFunds = useCallback(async () => {
    try {
      const { data } = await service.fidc.getDisverbationFunds();
      setFundsDisverbation(data.Funds);
      const cgeList = data.Funds.map((fund: DisverbationFunds) => fund.Cge);
      setSelectedFunds(cgeList);
    } catch {
      OToastManager.danger("Erro ao buscar produtos");
    }
  }, [setFundsDisverbation]);

  useEffect(() => {
    getFunds();
  }, [getFunds]);

  const grid = useMemo(
    () => disverbationApprovalsGrid(setSelectedDisverbations),
    [setSelectedDisverbations],
  );

  const sendDisverbatioFunds = useCallback(() => {
    setLoading(true);

    const body: PathDisverbationStatusBody = {
      Ids: [],
      FundCge: selectedFunds,
      Status: "APPROVED",
      Username: user.name ?? "",
    };

    service.fidc
      .pathDisverbations(body)
      .then(() => {
        OToastManager.success("Solicitação realizada com sucesso!");
        updateDataSource();
      })
      .catch(() => {
        OToastManager.danger("Não foi possível realizar a solicitação!");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedFunds, updateDataSource, user.name]);

  const updateStatusDisverbatios = useCallback(
    (status: string) => {
      setLoading(true);

      const Ids =
        selectedDisverbations?.map((disverbation) => disverbation.Id) || [];

      const body: PathDisverbationStatusBody = {
        Ids,
        FundCge: [],
        Status: status,
        Username: user.name ?? "",
      };

      service.fidc
        .pathDisverbations(body)
        .then(() => {
          OToastManager.success("Solicitação realizada com sucesso!");
          updateDataSource();
        })
        .catch(() => {
          OToastManager.danger("Não foi possível realizar a solicitação!");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedDisverbations, updateDataSource, user.name],
  );

  return {
    grid,
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    selectedDisverbations,
    sendDisverbatioFunds,
    updateStatusDisverbatios,
    fundsDisverbation,
    loading,
    selectedFunds,
    setSelectedFunds,
    setFindContractNumber,
    setFindFundName,
    rejectedSource,
    setFindStatus,
  };
};
