import { OToastManager } from "@maestro/core";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { getTradeFinanceErrorMessage } from "../../../components/error/error-code.utils";
import { adminCustomerAccCounterpartiesDetailsGenerator } from "./admin-customer-acc-counterparties.details";

export const AdminCustomerACCCounterpartiesDetailsPage = () => {
  const { customer } = useCustomer();

  const {
    value: counterpartyDetails,
    hasError,
    loading,
    callService: getAccCounterpartiesDetails,
  } = useServiceCall(service.acc.adminCustomer.getAccCounterpartiesDetails);

  const { loading: genereateContractLoading, callService: generateAccContrat } =
    useServiceCall(service.acc.generateAccContract);

  const genereateContract = useCallback(async () => {
    const { success, error } = await generateAccContrat({
      counterpartyIdentification: customer.identification,
    });

    if (!success) {
      return OToastManager.danger(getTradeFinanceErrorMessage(error));
    }

    OToastManager.success("CGC gerado com sucesso");
  }, [customer.identification, generateAccContrat]);

  useEffect(() => {
    getAccCounterpartiesDetails();
  }, [getAccCounterpartiesDetails]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes da contraparte" />}
      actions={
        <ButtonWithConfirmation
          confirmationModal={{
            title: "Tem certeza que deseja gerar o CGC?",
            loading: genereateContractLoading,
          }}
          type="dark"
          outline
          onConfirm={() => genereateContract()}
          disabled={
            loading ||
            genereateContractLoading ||
            !!counterpartyDetails?.response.activeContract
          }
        >
          Gerar CGC
        </ButtonWithConfirmation>
      }
    >
      <ContentTemplate
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da contraparte."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getAccCounterpartiesDetails()} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={counterpartyDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccCounterpartiesDetailsGenerator}
            />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
