import { OConfirmationModal, OTypography } from "@maestro/react";
import { useUpdateStrategiesModal } from "./update-strategies-modal.hook";
import { UpdateStrategiesModalProps } from "./update-strategies-modal.types";

export const UpdateStrategiesModal = ({
  strategyId,
}: UpdateStrategiesModalProps) => {
  const { loading, onConfirm } = useUpdateStrategiesModal(strategyId);

  return (
    <OConfirmationModal
      modalId="update-strategies-modal"
      title="Atualizar as estratégias relacionadas"
      confirmLabel="Sim"
      closeLabel="Não"
      size="xs"
      loading={loading}
      onConfirm={onConfirm}
    >
      <OTypography>
        Deseja atualizar os tipos de onboarding das estratégias referentes aos
        sacados selecionados?
      </OTypography>
    </OConfirmationModal>
  );
};
