import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { diligenceStepStatusMap } from "../../../../../../../../../../utils";
import { DiligenceStepForm } from "../../step-details.form";

export const useStatusSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.listDiligenceStepStatuses,
  );

  const { clearErrors, setError } = useFormContext<DiligenceStepForm>();

  const getPossibleStatuses = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("status");
    else
      setError("status", {
        message:
          "Erro ao buscar os status possíveis. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getPossibleStatuses();
  }, [getPossibleStatuses]);

  const options = useMemo(
    () =>
      value?.response.map((status) => {
        const mappedStatus = getValueFromMap(diligenceStepStatusMap, status);
        return {
          value: status,
          label: mappedStatus?.text ?? status,
        };
      }) ?? [],
    [value?.response],
  );

  return {
    options,
    getPossibleStatuses,
    loading,
    hasFetchError: hasError,
  };
};
