import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";
import {
  EditStepPropertyForm,
  editStepPropertyFormDefaultValues,
  editStepPropertyFormValidationSchema,
} from "../edit-step-property-modal/edit-step-property-modal.form";
import { addStepPropertyModalId } from "./add-step-property-modal.utils";

export const useAddStepPropertyModal = (
  diligenceStepId: number | string,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.addDiligenceStepProperties,
  );

  const form = useForm<EditStepPropertyForm>({
    defaultValues: editStepPropertyFormDefaultValues,
    resolver: yupResolver(editStepPropertyFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          diligenceStepId: Number(diligenceStepId),
          property: {
            isPublic: !!values.isPublic?.length,
            isAutomation: false,
            documentTypeId: values.documentTypeId,
            minimumAmount: values.minimumAmount,
            typeId: values.typeId,
          },
        });

        if (success) {
          OToastManager.success("Propriedade adicionada com sucesso");
          reload();
          modalManager.hide(addStepPropertyModalId);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Erro ao adicionar a propriedade",
          );
        }
      }),
    [callService, diligenceStepId, handleSubmit, reload],
  );

  useEffect(() => {
    const cleanup = modalManager.on(addStepPropertyModalId, "modalOpen", () => {
      reset(editStepPropertyFormDefaultValues);
    });

    return cleanup;
  }, [reset]);

  return {
    form,
    loading,
    submit,
  };
};
