import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, dofficersGrid } from "./officers.grid";

export const OfficersPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Officers" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
      actions={
        <OButton
          onClick={() => {
            gridRef.current?.instance.addRow();
          }}
        >
          Adicionar
        </OButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dofficersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
