import { OLoader } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { type ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { Whitelist } from "../../whitelists.types";
import { useAcquirerSelector } from "./acquirer-selector.hook";

export const AcquirerSelector = ({
  data,
  setValue,
}: ColumnEditCellTemplateData<Whitelist>) => {
  if (!data) throw new Error("No datagrid data");
  const { companies, loading } = useAcquirerSelector(data, setValue);

  const value = companies.find(
    (company) => company.value === data?.credenciadora_id,
  )?.label;

  return (
    <div className="d-flex align-items-center gap-2">
      <SelectSearch
        id="companyId"
        name="companyId"
        placeholder="Busque pelo nome ou CNPJ"
        options={companies}
        disabled={loading}
        value={value}
        // onClear={clear}
        className="w-100"
        maxOptions={50}
      />
      {loading && <OLoader className="d-block" size="sm" />}
    </div>
  );
};
