import {
  ConcludeButton,
  EscalateButton,
  ExecuteAutomationButton,
  WaitForDistributorButton,
} from "./_compose";

interface ActionsAreaProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

export const ActionsArea = ({ pendency }: ActionsAreaProps) => {
  return (
    <div className="d-flex gap-2">
      <ExecuteAutomationButton pendency={pendency} />
      <ConcludeButton pendency={pendency} />
      <WaitForDistributorButton pendency={pendency} />
      <EscalateButton pendency={pendency} />
    </div>
  );
};
