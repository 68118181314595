import * as yup from "yup";
import { FormValuesType } from "./role-form.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  id: yup.number(),
  type: yup.string().required("Este campo é obrigatório"),
  resource: yup.string().required("Este campo é obrigatório"),
  action: yup.string().required("Este campo é obrigatório"),
  description: yup.string().required("Este campo é obrigatório"),
});
