import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";

export const AverageVirtualCardsCard = () => {
  return (
    <InfoCard
      summaryType="cardsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Cartões virtuais por conta"
          body={_value.averageVirtualQuantityPerAccount.toLocaleString("pt-br")}
          footer="Média de cartões virtuais em contas com cartão virtual ativo"
        />
      )}
    />
  );
};
