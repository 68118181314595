import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { UseFormSetValue } from "react-hook-form";
import { GetAdminPolicyByLimitRequestIdResponseItem } from "services/hubcreditmanager/models/responses/get-admin-policy-by-limit-request-id.response";
import { currencySymbol } from "utils/currency";
import { CollateralFormValues } from "../../add-collateral-form.schema";

export const policyGrid = (
  setValue: UseFormSetValue<CollateralFormValues>,
): ODataGridGeneratorConfig<
  GetAdminPolicyByLimitRequestIdResponseItem & { selected: boolean }
> => {
  return {
    datagrid: {
      noDataText: "Nenhum registro.",
      selection: {
        mode: "single",
        selectAllMode: "page",
        showCheckBoxesMode: "always",
      },
      filterRow: {
        visible: true,
      },
      pager: {
        showPageSizeSelector: true,
      },
      onSelectionChanged: (evt) => {
        setValue("identification", evt.selectedRowsData[0].policy.policyNumber);
        setValue("expireDate", evt.selectedRowsData[0].endDate);
        setValue("amount", evt.selectedRowsData[0].limitValue);
        setValue("totalPremiumAmount", evt.selectedRowsData[0].totalPremium);
      },
    },
    columns: [
      {
        dataField: "policy.policyNumber",
        dataType: "string",
        caption: "Número da apólice",
      },
      {
        dataField: "endDate",
        dataType: "date",
        caption: "Data de vencimento",
        format: "shortDate",
      },
      {
        dataField: "limitValue",
        dataType: "number",
        caption: "Valor da apólice",
        alignment: "left",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "totalPremium",
        dataType: "number",
        caption: "Valor do prêmio",
        alignment: "left",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
    ],
  };
};
