import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PatchApiUsersUpdateByEmailBody } from "services/clerk";
import { useGetUser } from "../../../../hooks";
import { updateUserDefaultValues } from "./_compose/update-user-form/update-user-form.form";

export const useUserDetails = () => {
  const { user, hasError } = useGetUser();
  const { loading: loadingUpdate, callService: updateUserInfo } =
    useServiceCall(service.clerk.updateUserInfo);

  const form = useForm({
    defaultValues: updateUserDefaultValues,
  });
  const { getValues, handleSubmit, reset } = form;

  const hasChanged = useMemo(
    () =>
      user &&
      (user.name !== getValues("name") ||
        user.phone !== getValues("phone") ||
        user.birthDate !== getValues("birthDate")),
    [getValues, user],
  );

  const updateUser = handleSubmit(async (values) => {
    if (!user) {
      return;
    }

    const body: PatchApiUsersUpdateByEmailBody = {
      email: values.email,
      data: {
        name: user.name !== values.name ? values.name : undefined,
        phone: user.phone !== values.phone ? values.phone : undefined,
        birthDate:
          user.birthDate !== values.birthDate ? values.birthDate : undefined,
      },
    };

    const { success } = await updateUserInfo(body);

    if (success) {
      OToastManager.success("Usuário atualizado com sucesso!");
    } else {
      OToastManager.danger("Tivemos algum problema, verifique os erros!");
    }
  });

  useEffect(() => {
    if (user) {
      reset({
        name: user.name ?? "",
        birthDate: user.birthDate ?? "",
        phone: user.phone ?? "",
        isActive: user.isActive ? "yes" : "no",
        cpf: user.cpf ?? "",
        email: user.email ?? "",
        accountStatus: user.accountStatus ?? "",
        biometryStatus: user.biometryStatus ?? "",
      });
    }
  }, [reset, user]);

  return {
    form,
    hasChanged,
    hasError,
    loadingUpdate,
    updateUser,
    user,
  };
};
