import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { modalManager } from "@maestro/core";
import { ConfirmationDeleteConfigurableColumnsModal } from "./_compose/delete-configurable-columns-modal/delete-configurable-columns-modal.component";
import { deleteConfigurableColumnsModalId } from "./_compose/delete-configurable-columns-modal/delete-configurable-columns-modal.utils";

type ConfigurableGridColumns = Awaited<
  ReturnType<typeof service.hubCreditManager.getConfigurableGridColumns>
>["data"][number];

export const configurableColumnsGrid = {
  datagrid: {
    noDataText: "Nenhuma coluna configurável encontrada",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getConfigurableGridColumns().catch(() => {
        throw new Error("Não foi possível buscar as colunas configuráveis.");
      }),
    ),
    paging: { pageSize: 10 },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "caption",
      caption: "Legenda",
    },
    {
      dataField: "dataField",
    },
    {
      dataField: "dataType",
    },
    {
      dataField: "cellRenderType",
      caption: "Tipo de renderização",
    },
    {
      caption: "Filtrável?",
      cellRender: ({ data }) => (data.filterable ? "Sim" : "Não"),
    },
    {
      caption: "Exportação?",
      cellRender: ({ data }) => (data.sortable ? "Sim" : "Não"),
    },
    {
      dataField: "headerExportConfiguration",
      caption: "Nome na planilha de extração",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                role: roles.workflow.product
                  .configurationsUpdateConfigurableColumnsPage.role,
                route: `${corporateRouter.routes.workflow.product.configurations.configurableColumns.update.path(
                  {
                    id: data.id,
                  },
                )}?data=${encodeURIComponent(JSON.stringify(data))}`,
              },
              {
                icon: { category: "orq", icon: "orq-edit-trash" },
                label: "Remover",
                onClick: () =>
                  modalManager.show(deleteConfigurableColumnsModalId(data.id)),
              },
            ]}
          />
          <ConfirmationDeleteConfigurableColumnsModal
            id={data.id}
            reload={() => component.getDataSource().reload()}
          />
        </>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<ConfigurableGridColumns>;
