import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductBeneficiaryBanksGrid } from "./exchange-product-beneficiary-banks.grid";

export const ExchangeProductBeneficiaryBanksPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Bancos de beneficiários" />}
      actions={
        <RouterButton
          dataAction="cambio_bancos_de_beneficiarios:botao:adicionar_banco"
          dataLabel="adicionar_banco"
          href={
            corporateRouter.routes.cambio.product.contingency.beneficiaryBanks
              .form.path
          }
          role={roles.cambio.product.contingencyBeneficiaryBanksFormPage.role}
        >
          Adicionar banco
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeProductBeneficiaryBanksGrid}
      />
    </GridTemplate>
  );
};
