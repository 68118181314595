import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  OSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { client } from "../../../../../../../services/client";
import { endpoints } from "../../../utils/endpoints";
import { RowSpacedContainer } from "../../cards.styles";
import { CardSection } from "../already-approved.styles";
import {
  FunctionStatus,
  IConfigItemManager,
  IFunctionsProps,
} from "./functionsAndSetups.types";

export const Functions = ({ cardData, selectedCompany }: IFunctionsProps) => {
  const [isModalOppened, setIsModalOppened] = useState(false);
  const [isModalProcessing, setIsModalProcessing] = useState(false);
  const [functionValues, setFunctionValues] = useState<boolean[]>([]);
  const [functionsData, setFunctionsData] = useState<IConfigItemManager[]>([]);

  useEffect(() => {
    const data: IConfigItemManager[] = [
      {
        key: "isContactlessEnabled",
        label: "Compras por aproximação",
        value: cardData.isContactlessEnabled,
        endpoint: endpoints.bankinghub.patchContactless,
      },
      {
        key: "isOnlineShoppingEnabled",
        label: "Compras online",
        value: cardData.isOnlineShoppingEnabled,
        endpoint: endpoints.bankinghub.patchOnlineShopping,
      },
    ];
    setFunctionValues(data.map(({ value }) => value));
    setFunctionsData(data);
  }, [cardData]);

  const selectModalHandleChange = useCallback(
    (index: number, value: string) => {
      const newValues = [...functionValues];
      newValues[index] = value === "Habilitado" ? true : false;
      setFunctionValues(newValues);
    },
    [functionValues],
  );

  const confirmNewValue = useCallback(
    (index: number) => {
      const newFunctionData: IConfigItemManager[] = [...functionsData];
      newFunctionData[index].value = functionValues[index];
      setFunctionsData(newFunctionData);
    },
    [functionsData, functionValues],
  );

  const processNewFunctionValue = useCallback(
    async (id: string, index: number) => {
      const isANewValue = functionValues[index] !== functionsData[index].value;
      if (!isANewValue) return;
      try {
        setIsModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.patch(
          functionsData[index].endpoint(id),
          {
            action: functionValues[index]
              ? FunctionStatus.Enable
              : FunctionStatus.Disable,
          },
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
            },
          },
        );
        if (status === 200) {
          confirmNewValue(index);
          if (index === functionValues.length - 1) setIsModalOppened(false);
        }
      } catch (error) {
        OToastManager.danger(
          `Erro ao alterar status da funcionalidade ${functionsData[index].label}, tente novamente`,
        );
      } finally {
        setIsModalProcessing(false);
      }
    },
    [confirmNewValue, functionsData, functionValues, selectedCompany],
  );

  useEffect(() => {
    if (isModalOppened) {
      modalManager.show("card-functions-modal");
    } else {
      modalManager.hide("card-functions-modal");
    }
  }, [isModalOppened]);

  return (
    <CardSection>
      <OModal
        id="card-functions-modal"
        position="center"
        onModalClose={() => setIsModalOppened(false)}
      >
        <OModalHeader>
          <OTypography weight="bold">{`Alterar funcionalidades do cartão ${
            cardData?.last4Digits || ""
          }`}</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-3">
            {!!functionsData.length &&
              functionsData.map((functionData, index) => (
                <div
                  className="d-flex flex-column gap-2"
                  key={`item-functions-content-${index}`}
                >
                  <OTypography>{functionData?.label}</OTypography>
                  <OSelect
                    id="quantity-select"
                    dataAction="cartoes-funcoes:select:transacoes"
                    dataLabel="transacoes"
                    type="light"
                    value={
                      functionValues[index] ? "Habilitado" : "Desabilitado"
                    }
                    onInput={(e) => {
                      selectModalHandleChange(
                        index,
                        (e?.currentTarget as HTMLOSelectElement)
                          ?.value as string,
                      );
                    }}
                  >
                    <OOption value="Habilitado">Habilitado</OOption>
                    <OOption value="Desabilitado">Desabilitado</OOption>
                  </OSelect>
                </div>
              ))}
          </div>
          {isModalProcessing && <OLoader absolute backdrop />}
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <OButton
              dataAction="cartoes-funcoes:botao:fechar"
              dataLabel="fechar"
              onClick={() => {
                setIsModalOppened(false);
              }}
              type="tertiary"
              outline
            >
              Fechar
            </OButton>
            <OButton
              dataAction="cartoes-funcoes:botao:alterar"
              dataLabel="alterar"
              onClick={async () => {
                const promises = functionsData.map((_, index) =>
                  processNewFunctionValue(cardData.id, index),
                );
                await Promise.all(promises);
              }}
              disabled={!cardData?.last4Digits}
            >
              Alterar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <OTypography weight="bold" className="mt-3 mb-2">
        · Funções
      </OTypography>
      <RowSpacedContainer>
        <div className="d-flex flex-row gap-5">
          {!!functionsData.length &&
            functionsData.map((functionData, index) => (
              <div
                className="d-flex flex-column gap-3"
                key={`select-content-${index}`}
              >
                <OTypography weight="500">{functionData?.label}</OTypography>
                <OTypography weight="300" className="px-3">
                  {functionData?.value ? "Habilitado" : "Desabilitado"}
                </OTypography>
              </div>
            ))}
        </div>
        <OButton
          dataLabel="cartoes:botao:alterar-funcoes"
          dataAction="alterar-funcoes"
          outline
          bordered={false}
          onClick={() => {
            setIsModalOppened(true);
          }}
        >
          Alterar funções
        </OButton>
      </RowSpacedContainer>
    </CardSection>
  );
};
