import { OField, TooltipContainer } from "@maestro/react";
import { SelectSearchV2 } from "../select-search.component";
import { useSelectSearchFieldV2 } from "./select-search-field.hook";
import { InputDarkerPlaceholder } from "./select-search-field.styles";
import { SelectSearchFieldV2Props } from "./select-search-field.types";
import { getOptionsFromValues } from "./select-search-field.utils";
import { ClearButton, SelectedValuesTooltip } from "./_compose";

export const SelectSearchFieldV2 = <T extends string | number>(
  props: SelectSearchFieldV2Props<T>,
) => {
  // DO NOT DELETE UNUSED VARIABLES
  // unused destructured variables to remove them from `...rest`,
  // thus avoiding to pass wrong props to the component
  const {
    multiple = false,
    name,
    label,
    labelType,
    description,
    required,
    tooltip,
    tooltipPosition,
    tooltipMaxWidth,
    options: originalOptions,
    dropdownPosition,
    ...rest
  } = props;

  const {
    fieldErrors,
    inputRef,
    internalValue,
    options,
    setInternalValue,
    setSearchText,
  } = useSelectSearchFieldV2(name, multiple, originalOptions);

  const valueString = getOptionsFromValues(originalOptions, internalValue)
    .map(({ content }) => content)
    .join(", ");
  const renderValuesTooltip = multiple && !!internalValue.length;

  return (
    <OField
      htmlFor={rest.id}
      error={!!fieldErrors}
      message={fieldErrors?.message}
      description={description}
      label={label}
      labelType={labelType}
      required={required}
      disabled={rest.disabled}
      labelSize={rest.labelSize ?? "sm"}
    >
      {tooltip && (
        <TooltipContainer position={tooltipPosition} maxWidth={tooltipMaxWidth}>
          {tooltip}
        </TooltipContainer>
      )}
      <InputDarkerPlaceholder darkerPlaceholder={!!internalValue.length}>
        <SelectSearchV2
          id={rest.id}
          name={name}
          placeholder={valueString || rest.placeholder}
          disabled={rest.disabled}
          options={options}
          inputRef={inputRef}
          onInput={setSearchText}
          inputClassName={renderValuesTooltip ? undefined : "no-padding-left"}
          dropdownPosition={dropdownPosition}
          slotBefore={
            <SelectedValuesTooltip
              renderValuesTooltip={renderValuesTooltip}
              originalOptions={originalOptions}
              internalValue={internalValue}
            />
          }
          slotAfter={
            <ClearButton
              onClick={(evt) => {
                setInternalValue([]);
                if (inputRef.current) inputRef.current.value = "";
                setSearchText(undefined);
                evt.stopPropagation();
              }}
              disabled={!!rest.disabled}
            />
          }
        />
      </InputDarkerPlaceholder>
    </OField>
  );
};
