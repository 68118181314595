import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useAddBookStrategy } from "./add-book-strategy.hook";

export const AddBookStrategyPage = () => {
  const {
    addStrategyToBook,
    books,
    form,
    getAllData,
    loadingAddStrategyToBook,
    loadingBooks,
    loadingStrategies,
    strategies,
    hasErrorBooks,
    hasErrorStrategies,
  } = useAddBookStrategy();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Book / Estratégia" />}
      actions={
        <LoadingButton
          loading={loadingAddStrategyToBook}
          onClick={handleSubmit(addStrategyToBook)}
          disabled={loadingBooks || loadingStrategies}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasErrorBooks || hasErrorStrategies}
        loading={loadingBooks || loadingStrategies}
        noValue
        errorComponent={
          <DefaultErrorComponent
            title="Não foi possível carregar os dados referentes aos books e/ou estratégias"
            callback={getAllData}
          />
        }
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <form spellCheck="false">
                <ORow>
                  <OCol sm={6}>
                    <ORFieldSelect
                      id="bookId"
                      name="bookId"
                      label="Book"
                      labelSize="md"
                      placeholder="Selecione"
                      disabled={loadingBooks || hasErrorBooks}
                    >
                      {books?.map((book) => (
                        <OOption value={book.id} key={`book-${book.id}`}>
                          {book.bookName} ({book.bookCr})
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldSelect
                      id="strategyId"
                      name="strategyId"
                      label="Estratégia"
                      labelSize="md"
                      placeholder="Selecione"
                      disabled={loadingStrategies || hasErrorStrategies}
                    >
                      {strategies?.map((strategy) => (
                        <OOption
                          value={strategy.id}
                          key={`strategy-${strategy.id}`}
                        >
                          {strategy.strategyName} ({strategy.strategyCr})
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                </ORow>
              </form>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
