import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../components/validation-error-component";
import { ErrorMessages, ImportDataInfo } from "./_compose";
import { useImportDataDetails } from "./import-data-details.hook";

export const ImportDataDetailsPage = () => {
  const { error, getImportDataDetails, hasError, loading, value } =
    useImportDataDetails();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes" />}>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={value?.response}
        errorComponent={
          <ValidationErrorComponent
            callback={getImportDataDetails}
            error={error}
            title="Erro ao buscar a importação"
          />
        }
        render={(_importDataDetails) => (
          <div className="d-flex flex-column gap-4">
            {!!_importDataDetails?.errors?.length && (
              <ErrorMessages importData={_importDataDetails} />
            )}
            <ImportDataInfo importData={_importDataDetails} />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
