import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { configurableColumnsGrid } from "./configurable-columns.grid";
import { useConfigurableColumn } from "./hook";
import { Modal } from "./modal/modal.component";

export const BatchConfigurableColumnsPage = () => {
  const gridRef = useGridRef();
  const { setSelectedColumn, selectedColumn, column } = useConfigurableColumn();

  return (
    <>
      <GridTemplate
        pageTitle={
          <PageTitle title="Configurações" description="Lista de Colunas" />
        }
        actions={
          <RouterButton
            dataAction="colunas_configuraveis:botao:adicionar_coluna_batch"
            dataLabel="adicionar_coluna_batch"
            role={roles.workflow.product.batchConfigurationsAddColumnPage.role}
            href={
              corporateRouter.routes.workflow.product.batchs.configuration.add
                .column.path
            }
          >
            Adicionar
          </RouterButton>
        }
        gridRef={gridRef}
        showRefreshButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={configurableColumnsGrid(setSelectedColumn)}
        />
      </GridTemplate>
      <Modal selectedColumn={selectedColumn} column={column} />
    </>
  );
};
