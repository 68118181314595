import { DeepNullable } from "utils/types";
import * as yup from "yup";

export type ExchangeProductEditCounterpartyFormValues = DeepNullable<{
  Id: number;
  Cge: number;
  ForcePreTicket: string[];
  HasPreTicketEnabled: string[];
}>;

export const exchangeProductEditCounterpartyFormValidationSchema = yup
  .object()
  .shape({
    Cge: yup.number(),
    ForcePreTicket: yup.array(),
    HasPreTicketEnabled: yup.array(),
  });

export const exchangeProductEditCounterpartyFormDefaultValues = {
  Cge: null,
  ForcePreTicket: [],
  HasPreTicketEnabled: [],
};
