import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useProfileSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.gatekeeper.getAllProfiles,
  );

  const { clearErrors, setError } = useFormContext<{ profiles: unknown }>();

  const getProfiles = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors();
    else
      setError("profiles", {
        message:
          "Erro ao buscar os profiles. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  const profiles = useMemo(
    () => value?.map((profile) => profile.name),
    [value],
  );

  return {
    getProfiles,
    hasError,
    loading,
    profiles,
  };
};
