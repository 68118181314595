import { cases } from "@maestro/utils";
import { IconButton } from "components/icon-button";

interface ConfigureColumnButtonProps {
  withOperatedValue: boolean;
  setWithOperatedValue: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfigureColumnButton = ({
  withOperatedValue,
  setWithOperatedValue,
}: ConfigureColumnButtonProps) => {
  const buttonText = withOperatedValue ? "Desabilitar" : "Habilitar";
  const dataLabel = cases.lower(buttonText);
  const icon = withOperatedValue ? "fa-times" : "fa-check";
  const type = withOperatedValue ? "tertiary" : "dark";

  return (
    <IconButton
      dataAction={`agendas:botao:${dataLabel}`}
      dataLabel={dataLabel}
      icon={{
        category: "fal",
        icon,
      }}
      type={type}
      outline={!withOperatedValue}
      onClick={() => setWithOperatedValue((value) => !value)}
    >
      <span>{buttonText} coluna de valor operado</span>
    </IconButton>
  );
};
