import dxDataGrid from "devextreme/ui/data_grid";
import { GetApiAdminBannersResponse } from "services/hubcreditmanager/models/responses";

export const filterFields = [
  "startingFrom",
  "endingAt",
  "isActive",
  "badge",
  "identification",
  "createdBy",
  "accessRules",
];

type ValueType =
  | "badge"
  | "identification"
  | "isActive"
  | "createdBy"
  | "accessRules";
type DateType = "startingFrom" | "endingAt";

export type FormValuesType = {
  [K in DateType]: [string, string];
} & {
  [K in ValueType]: string[];
};

export interface FilterModalProps {
  gridRefInstance?: dxDataGrid<any, any>;
  data?: GetApiAdminBannersResponse;
}
