import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { currencySymbol } from "utils/currency";

export const billingReportFields = (
  billingReport: HubEnergy.BillingReportResponse,
): DetailsFields => ({
  customer: {
    items: [
      {
        label: "Nome",
        value: billingReport.consumerUnit.name,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Razão social",
        value: billingReport.consumerUnit.officialName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(billingReport.consumerUnit.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={billingReport?.consumerUnit.taxId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Número da boleta",
        value: billingReport.boletaRegistryId,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data do relatório",
        value:
          billingReport?.reportDate &&
          dayjs(billingReport.reportDate).format("DD/MM/YYYY"),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Consumo",
        value: billingReport.consumption + " MWh",
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Bandeira no Mercado Regulado",
        value: billingReport.regulatedMarketEnergyFlagName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Possui DEVEC",
        value: billingReport.devec ? "Sim" : "Não",
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Valor",
        value: masks.currency(billingReport.value, currencySymbol.BRL),
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
