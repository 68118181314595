import { SelectSearchMultiple } from "components/form";
import { useSelectStrategies } from "./select-strategies.hook";

export const SelectStrategies = () => {
  const { allStrategies } = useSelectStrategies();

  return (
    <SelectSearchMultiple
      label="Nome ou UniqueId das Estratégias"
      id="estrategiasId"
      name="estrategiasId"
      placeholder="Selecionar"
      options={allStrategies}
      maxOptions={50}
    />
  );
};
