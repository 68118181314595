import { Profile } from "../../../components/role-assignments/profile-selector/profile-selector.types";

export const buildUserProfiles = (
  profilesUuidList: string[],
  profiles: Profile[],
) => {
  const newProfilesList: string[] = [...profilesUuidList];
  profilesUuidList.forEach((profileUuid) => {
    const currentProfile = profiles.find(
      (profile) => profile.uuid === profileUuid,
    );
    if (currentProfile) {
      const { subProfiles } = currentProfile;
      subProfiles.forEach((subProfile) => {
        if (!newProfilesList.includes(subProfile.uuid)) {
          newProfilesList.push(subProfile.uuid);
        }
      });
    }
  });

  return newProfilesList;
};
