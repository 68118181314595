import * as yup from "yup";

export const scoreBenefitFormValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  description: yup.string(),
});

export const scoreBenefitFormDefaultValues = {
  name: "",
  type: "",
  description: "",
};
