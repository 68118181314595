import { OToastManager, modalManager } from "@maestro/core";
import {
  OIcon,
  OUpload,
  OUploadInput,
  OUploadItem,
  OUploadLabel,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { useState } from "react";
import { service } from "services";
import { PostApiAdminCompanyUploadContractTemplateErrorResponse } from "services/hubloan/models/responses/error/post-api-admin-company-upload-contract-template-error.response";
import { getFormData } from "utils/file/file.utils";
import {
  LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID,
  LoanContractsTemplatesUploadErrorModal,
} from "./loan-contracts-templates-upload-error-modal.component";
import { ContractTemplate } from "./loan-contracts-templates.types";

export const LoanContractsTemplatesUpload = ({
  component,
  rowIndex,
  data,
}: ColumnEditCellTemplateData<ContractTemplate>) => {
  const [errorList, setErrorList] =
    useState<
      PostApiAdminCompanyUploadContractTemplateErrorResponse["errors"]
    >();
  const [loading, setLoading] = useState(false);
  const { show } = modalManager;

  const onAddFile = async (file: File) => {
    try {
      setLoading(true);
      OToastManager.info("Enviando arquivo...");
      const formData = await getFormData(file, "TemplateDeContrato");

      const {
        data: { fileKey },
      } = await service.hubLoan.uploadContractTemplate(formData);

      component.cellValue(rowIndex, "template", fileKey);
      OToastManager.destroy();
    } catch (err) {
      if (
        isAxiosError<PostApiAdminCompanyUploadContractTemplateErrorResponse>(
          err,
        )
      ) {
        const uploadErrors = err?.response?.data?.errors;

        if (uploadErrors) {
          setErrorList(uploadErrors);
          show(LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID);
        }
      }

      OToastManager.danger(
        "Não foi possível fazer upload do arquivo, tente novamente.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <OUpload
        onAddFile={(event) => {
          if (data?.template || loading) {
            OToastManager.warning("Já tem um arquivo");
            return;
          }
          onAddFile(event.detail);
        }}
      >
        <OUploadInput
          id="file"
          name="file"
          dataAction="emprestimos_templates_form:file:arquivo_template"
          dataLabel="arquivo_template"
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
        <OUploadLabel htmlFor="file" size="md">
          Selecionar documento
          <OIcon category="orq" icon="orq-upload" size="lg" />
        </OUploadLabel>
        {data?.template && (
          <div className="d-flex flex-column">
            <OUploadItem
              handleRemove={() => {
                component.cellValue(rowIndex, "template", null);
              }}
            >
              <div style={{ overflow: "hidden" }}>{data.template}</div>
            </OUploadItem>
          </div>
        )}
      </OUpload>
      <LoanContractsTemplatesUploadErrorModal errors={errorList} />
    </>
  );
};
