import * as yup from "yup";

export interface EnergyCreateDistributorsFormValues {
  file: File[];
}

export const energyCreateDistributorsFormValidationSchema: yup.ObjectSchema<EnergyCreateDistributorsFormValues> =
  yup.object({
    file: yup
      .array()
      .typeError("Este campo é obrigatorio")
      .required("Este campo é obrigatorio")
      .min(1, "Este campo é obrigatorio"),
  });
