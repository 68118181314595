import {
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./restrictions-list.grid";
import { useRestrictionsList } from "./restrictions-list.hook";
import { RestrictionDeleteModal } from "./_compose/restriction-delete-modal/restriction-delete-modal.component";

export const RestrictionsList = () => {
  const { routes } = corporateRouter;
  const { dataSource, gridRef, onDelete, confirmDelete, setConfirmDelete } = useRestrictionsList();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Lista de restrições"
          description="Listagem das empresas que possuem algum tipo de restrição"
        />
      }
      actions={
        <RouterButton
          dataAction="lista_restricoes:botao:adicionar"
          dataLabel="adicionar"
          role={roles.recebiveis.product.restrictionsListAddPage.role}
          href={routes.recebiveis.product.restrictionsList.add.path}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid(onDelete)}
        dataSource={dataSource}
      />
      <RestrictionDeleteModal
        restriction={confirmDelete}
        onRemoved={() => {
          gridRef.current?.instance.refresh();
          setConfirmDelete(undefined);
        }}
      />
    </GridTemplate>
  );
};
