import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OLoader,
  OCardFooter,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "components/loading-button";
import { GridTemplate } from "templates/grid-template";
import { useGridRef } from "hooks/grid-ref";
import { ODataGridGenerator } from "components/data-grid";
import { buildReconciliationDetailsFields } from "./reconciliation-details.details";
import { useReconciliationDetails } from "./reconciliation-details.hook";
import { detailsGrid } from "./reconciliation-details.grid";

export const ReconciliationDetails = () => {
  const {
    reconLoading,
    reconHasError,
    reconResult,
    categories,
    getReconResult,
    saveJustification,
    sendReconApproval,
    approvalLoading,
    categoriesLoading,
    form,
    showApproveCard,
    showJustifyButton,
    detailsDataSource,
  } = useReconciliationDetails();
  const gridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate pageTitle={<PageTitle title="Sumário" />}>
        <DetailsCard
          fields={buildReconciliationDetailsFields}
          loading={reconLoading}
          value={reconResult}
          hasError={reconHasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar o resultado da reconciliação"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => getReconResult()} />
            </ErrorComponent>
          }
        />
      </DetailsTemplate>
      {categoriesLoading && !reconLoading && <OLoader absolute />}
      {reconResult && categories && !!categories.length && (
        <>
          <FormProvider {...form}>
            <OCard>
              <OCardHeader>
                <OTypography tag="h2">Justificativa</OTypography>
              </OCardHeader>
              <OCardBody>
                <ORFieldInput
                  tag="textarea"
                  dataAction="enviar_justificativa:texto:justificativa"
                  dataLabel="justificativa"
                  id="justification"
                  name="justification"
                  aspect="outline"
                  readonly={!showJustifyButton}
                />
                <ORFieldSelect
                  dataAction="enviar_justificativa:select:categoria"
                  dataLabel="categoria"
                  id="categoryId"
                  name="categoryId"
                  label="Categoria"
                  placeholder="Selecione"
                  readonly={!showJustifyButton}
                  key={categories.length}
                >
                  {categories?.map(({ id, name }) => (
                    <OOption key={id} value={id}>
                      {name}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCardBody>

              <OCardFooter>
                <div className="d-flex justify-content-between">
                  <div className="flex flex-column gap-2">
                    {reconResult?.justifiedBy && (
                      <>
                        <OTypography weight="500" size="sm" type="default">
                          Justificado por
                        </OTypography>
                        <OTypography>{reconResult.justifiedBy}</OTypography>
                      </>
                    )}
                  </div>
                  {showJustifyButton && (
                    <OButton
                      dataAction="enviar_justificativa:select:enviar"
                      dataLabel="enviar"
                      onClick={saveJustification}
                    >
                      Salvar
                    </OButton>
                  )}
                </div>
              </OCardFooter>
            </OCard>
          </FormProvider>
          <OCard>
            <OCardBody>
              <GridTemplate
                pageTitle={<OTypography tag="h2">Detalhes</OTypography>}
                gridRef={gridRef}
                showRefreshButton
              >
                <ODataGridGenerator
                  gridRef={gridRef}
                  grid={detailsGrid}
                  dataSource={detailsDataSource}
                />
              </GridTemplate>
            </OCardBody>
          </OCard>
          {showApproveCard && (
            <OCard>
              <OCardHeader>
                <OTypography tag="h2">Ações</OTypography>
              </OCardHeader>
              <OCardBody>
                <div className="d-flex gap-4">
                  <LoadingButton
                    loading={approvalLoading}
                    dataAction="aprovar_reconciliacao:botao:aprovar"
                    dataLabel="aprovar"
                    type="info"
                    onClick={() => sendReconApproval(true)}
                  >
                    Aprovar
                  </LoadingButton>
                  <LoadingButton
                    loading={approvalLoading}
                    dataAction="aprovar_reconciliacao:botao:rejeitar"
                    dataLabel="rejeitar"
                    type="warning"
                    onClick={() => sendReconApproval(false)}
                  >
                    Reprovar
                  </LoadingButton>
                </div>
              </OCardBody>
            </OCard>
          )}
        </>
      )}
    </div>
  );
};
