import { useAuthorization } from "contexts/auth";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { buildCustomerLink } from "utils/build-customer-link";
import { env } from "utils/environments";
import { logger } from "utils/logger";
import { receiveMessage, sendMessage } from "./portal-embeded.utils";

const embeddedOnboardingRouteRe =
  /\/empresas\/clientes\/(\d+)\?active=cadastro/;

interface EmbeddedPortalProps {
  headers?: Record<string, string | unknown>;
  route: string;
  translateRouteToAdmin?: (route: string) => string;
}

const defaultHeaders = { "x-identification": "30306294000145" };

export const EmbeddedPortal = ({
  headers = defaultHeaders,
  route,
  translateRouteToAdmin,
}: EmbeddedPortalProps) => {
  const navigate = useNavigate();
  const { authorization } = useAuthorization();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const dispatchToken = useCallback(
    (_route: string) => {
      const payload = {
        route: _route,
        headers: {
          Authorization: authorization,
          ...headers,
        },
      };
      sendMessage(iframeRef.current!, payload, "UPDATE_TOKEN");
      logger.debug("[ADMIN][DISPATCH][EVENT]", "DISPATCH_TOKEN");
    },
    [authorization, headers],
  );

  useEffect(() => {
    if (!iframeRef.current) return;

    /** Listen for embedded message to then dispatchToken */
    const initCb = receiveMessage<string>(
      "EMBEDDED_PROVIDER",
      (payload: string) => {
        if (payload.match(/INIT_EMBEDDED/)) {
          logger.debug("[ADMIN][LISTENER][EVENT]", payload);
          dispatchToken(route);
        }
      },
    );

    /** Change route in browser address bar without navigating */
    const routeCb = receiveMessage<string>(
      "EMBEDDED_ROUTE_CHANGE",
      (payload: string) => {
        logger.debug("[ADMIN][EMBEDDED_ROUTE_CHANGE]", payload);
        window.history.replaceState(
          null,
          "",
          translateRouteToAdmin && translateRouteToAdmin(payload),
        );
      },
    );

    /** Navigate to different route */
    const navigateCb = receiveMessage<string>(
      "EMBEDDED_ADMIN_NAVIGATE",
      (payload: string) => {
        logger.debug("[ADMIN][EMBEDDED_ADMIN_NAVIGATE]", payload);

        const cadastroEmbeddedIdentification = payload.match(
          embeddedOnboardingRouteRe,
        )?.[1];

        const newRoute = cadastroEmbeddedIdentification
          ? buildCustomerLink(
              cadastroEmbeddedIdentification,
              corporateRouter.routes.cadastro.customer.onboardingEmbedded.path,
            )
          : translateRouteToAdmin && translateRouteToAdmin(payload);

        newRoute && navigate(newRoute);
      },
    );

    return function cleanup() {
      window.removeEventListener("message", initCb);
      window.removeEventListener("message", routeCb);
      window.removeEventListener("message", navigateCb);
    };
  }, [dispatchToken, navigate, route, translateRouteToAdmin]);

  useEffect(() => {
    dispatchToken("");
  }, [dispatchToken]);

  useEffect(() => {
    if (!iframeRef.current) return;
    /** Admin route changed, send message to embedded page to change route too */
    sendMessage(iframeRef.current, route, "ADMIN_ROUTE_CHANGE");
  }, [route]);

  return (
    <iframe
      ref={(ref) => {
        iframeRef.current = ref;
      }}
      id="embedded-portal"
      title="embedded_portal"
      src={`${env.EMBEDDED_URL}/auth`}
      style={{
        height: "100%",
        width: "100%",
        border: "none",
      }}
    />
  );
};
