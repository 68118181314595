import { OBadge, OTypography } from "@maestro/react";
import dayjs from "dayjs";
import { Banner } from "services/hubcreditmanager/models/responses";
import { getStatus } from "../banner-management.utils";

export const getBannerDetailsInfo = (value?: Banner) => {
  if (!value) return [];

  const { title, badge, bannerTemplate, variables } = value;
  const parsedVariables = JSON.parse(variables ?? "{}") as Record<
    string,
    string
  >;

  const allItems = [
    {
      content: (
        <div className="text-left">
          <OTypography type="default">
            {bannerTemplate?.identification}
          </OTypography>
        </div>
      ),
      label: "Tipo de campanha",
      visible: !!bannerTemplate?.identification,
    },
    {
      content: (
        <div className="text-left">
          <OTypography type="default">{badge}</OTypography>
        </div>
      ),
      label: "Produto",
      visible: !!badge,
    },
    {
      content: (
        <div className="text-left">
          <OTypography type="default">{title}</OTypography>
        </div>
      ),
      label: `${badge}`,
      visible: !!title,
    },
    {
      content: (
        <div className="text-left">
          <OTypography type="default">
            {parsedVariables?.temaPrincipal}
          </OTypography>
        </div>
      ),
      label: "Tema principal",
      visible: !!parsedVariables?.temaPrincipal,
    },
    {
      content: (
        <div className="text-left">
          <OTypography type="default">{parsedVariables?.data}</OTypography>
        </div>
      ),
      label: "Data",
      visible: !!parsedVariables?.data,
    },
    {
      content: (
        <div className="text-left">
          <OTypography type="default">{parsedVariables?.hora}</OTypography>
        </div>
      ),
      label: "Horário",
      visible: !!parsedVariables?.hora,
    },
  ];

  return allItems.filter((item) => item.visible);
};

export const getCampaignDetailsInfo = (value?: Banner) => {
  if (!value) return [];

  const { endingAt, startingFrom, internalName, accessRules } = value;

  const { backgroundColor, label, icon, isAfter, isBefore } = getStatus(value);

  const allItems = [
    {
      content: <OTypography type="default">{internalName}</OTypography>,
      label: "Nome da campanha",
      visible: !!internalName,
    },
    {
      content: (
        <OTypography key={startingFrom} type="default">
          {dayjs(startingFrom).format("DD/MM/YYYY [às] HH:mm")}
        </OTypography>
      ),
      label: "Data de início",
      visible: !!startingFrom,
      tooltip: isBefore && (
        <OTypography>
          A campanha está ativa mas a data de início ainda não chegou.
        </OTypography>
      ),
    },
    {
      content: (
        <OTypography key={endingAt} type="default">
          {dayjs(endingAt).format("DD/MM/YYYY [às] HH:mm")}
        </OTypography>
      ),
      label: "Data final",
      visible: !!endingAt,
      tooltip: isAfter && (
        <OTypography>
          A campanha está ativa mas a data de validade já foi atingida.
        </OTypography>
      ),
      tooltipIcon: "orq-attention" as const,
    },
    {
      content: (
        <OTypography key={accessRules.length} type="default">
          {accessRules.map((rule) => rule.name).join(", ")}
        </OTypography>
      ),
      label: "Público alvo",
      visible: !!accessRules?.length,
    },
    {
      content: (
        <OBadge
          className="mt-2"
          key={backgroundColor}
          type={backgroundColor}
          size="sm"
          rounded
        >
          {icon}
          <OTypography size="sm" key={label}>
            {label}
          </OTypography>
        </OBadge>
      ),
      label: "Status",
      visible: !!label,
    },
  ];

  return allItems.filter((item) => item.visible);
};
