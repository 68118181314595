import { OCol, ORFieldInput, ORFieldInputDate, ORow } from "@maestro/react";
import { consumerUnitFormFields } from "../../../../edit-consumer-unit.form";

interface EnergyConsumptionFieldsProps {
  disabled?: boolean;
}

export const EnergyConsumptionFields = ({
  disabled,
}: EnergyConsumptionFieldsProps) => {
  return (
    <ORow gap={3}>
      <OCol xs={12} md={6} lg={3}>
        <ORFieldInput
          {...consumerUnitFormFields.fareGroup}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6} lg={3}>
        <ORFieldInput
          {...consumerUnitFormFields.modality}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...consumerUnitFormFields.volume} disabled={disabled} />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.peakDemand}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.offPeakDemand}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.minimumPeakAllocation}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6} lg={3}>
        <ORFieldInputDate
          {...consumerUnitFormFields.migrationDate}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6} lg={3}>
        <ORFieldInputDate
          {...consumerUnitFormFields.supplyEndDate}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.agentProfile}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.energyType}
          disabled={disabled}
        />
      </OCol>
    </ORow>
  );
};
