import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const AprovacaoAposAntecipacaoSacadoField = () => {
  return (
    <YesNoRadioField
      name="aprovacaoAposAntecipacaoSacado"
      description="Represamento de trades solicitados até que o sacado aprove"
      label={EstrategiaFormLabels.aprovacaoAposAntecipacaoSacado}
    />
  );
};
