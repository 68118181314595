import { IconButton } from "components/icon-button";

interface MasterDetailsButtonProps {
  autoExpandAll: boolean;
  setAutoExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MasterDetailsButton = ({
  autoExpandAll,
  setAutoExpandAll,
}: MasterDetailsButtonProps) => (
  <IconButton
    dataAction="antecipacao_cartao_jobs:botao:mostrar_detalhes"
    dataLabel="mostrar_detalhes"
    icon={{
      category: "far",
      icon: autoExpandAll ? "fa-compress-alt" : "fa-expand-alt",
    }}
    outline={autoExpandAll}
    type={autoExpandAll ? "dark" : "tertiary"}
    onClick={() => setAutoExpandAll((previous) => !previous)}
  >
    {autoExpandAll ? "Esconder detalhes" : "Mostrar detalhes"}
  </IconButton>
);
