import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { GetAdminCustomerCounterpartiesDetailsResponse } from "services/acc/models/responses/get-admin-acc-counterparties-details.response";
import { format } from "utils/date";
import { contractStatusMap } from "../../../components/utils/acc-contract-status.utils";

export const adminCustomerAccCounterpartiesDetailsGenerator = (
  value: GetAdminCustomerCounterpartiesDetailsResponse | null,
) => {
  if (!value) return {} as DetailsFields;

  const counterpartyDetails = {
    counterparty: {
      title: "Contraparte",
      items: [
        {
          label: "Id",
          value: value.id,
          grid: { md: 2, xl: 1 },
        },
        {
          label: "Nome",
          value: value.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CNPJ",
          value: masks.cpfCnpj(value.identification),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Requisição",
          value: format.dateTime(value?.createdDate),
          grid: { md: 3, xl: 2 },
        },
        {
          label: "Dt. Atualização",
          value: format.dateTime(value.updatedDate),
          grid: { md: 3, xl: 2 },
        },
      ],
    },
    cgc: {
      title: "CGC Vigente",
      items: [
        {
          label: "Status CGC",
          value:
            contractStatusMap[value?.activeContract?.status] ??
            (value?.activeContract?.status || "Não assinado"),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ExternalId",
          value: value?.activeContract?.externalId,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return counterpartyDetails;
};
