import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { CompanyDetailsCardTemplate } from "../template";
import { PlanCardContents } from "./plan-card-contents.component";
import { usePlanCard } from "./plan-card.hook";

export const PlanCard = () => {
  const { planInfo, getPlanInfo, hasError, isNotFoundError, loading } =
    usePlanCard();

  return (
    <CompanyDetailsCardTemplate title="Plano da empresa">
      <ContentTemplate
        loading={loading}
        value={planInfo}
        hasError={hasError}
        errorComponent={
          isNotFoundError ? (
            <EmptyState messageParagraph="Nenhuma informação" />
          ) : (
            <DefaultErrorComponent
              title="Não foi possível buscar as informações"
              callback={getPlanInfo}
            />
          )
        }
        render={(_planInfo) => <PlanCardContents {..._planInfo} />}
      />
    </CompanyDetailsCardTemplate>
  );
};
