import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../routes/cadastro.route-params";
import { fraudVerificationGrid } from "./fraud-verification.grid";
import { useFraudVerification } from "./fraud-verification.hook";

export const FraudVerificationPage = () => {
  const { uuid } = useParams<CadastroClienteProcuracoesByUuidRouteParams>();
  if (!uuid)
    throw new Error("No representative workflow uuid in route parameters");

  const gridRef = useGridRef();
  const { fraudVerifications, getFraudVerification } = useFraudVerification();

  useEffect(() => {
    getFraudVerification(uuid);
  }, [getFraudVerification, uuid]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Validações de Segurança" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={fraudVerificationGrid}
        dataSource={fraudVerifications}
      />
    </GridTemplate>
  );
};
