import { OToastManager } from "@maestro/core";
import { OLoader, OTypography } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { auth } from "../../../../../../../../contexts/auth";
import { client } from "../../../../../../../../services/client";
import { endpoints } from "../../../../utils/endpoints";
import { ColumnGap, SubsectionContainer } from "../../../cards.styles";
import { TransactionChargeBack } from "../../../compose/chargeback/chargeback.component";
import { ISalesforceTokenInfo } from "../../../compose/chargeback/chargeback.types";
import { TransactionDetails } from "../../../compose/transaction-details/transaction-details.component";
import {
  formContestationDescriptionFromInvoiceItem,
  formTransactionDetailsFromInvoiceItem,
  isChargebackTransactionFromInvoiceItem,
} from "../../../utils/invoices";
import { List } from "./list/list.component";
import {
  IInvoiceTransactionItem,
  IInvoiceTransactions,
  ITransactionsProps,
} from "./transactions.types";

export const Transactions = ({
  accountId,
  invoiceId,
  selectedCompany,
  openContestations,
  setOpenContestations,
}: ITransactionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceTransactionsData, setInvoiceTransactionsData] =
    useState<IInvoiceTransactions>();
  const [transactionDetailsId, setTransactionDetailsId] = useState<
    number | undefined
  >();
  const [onRequestContestation, setOnRequestContestation] = useState(false);
  const [invoiceTransactionItem, setInvoiceTransactionItem] = useState<
    IInvoiceTransactionItem | undefined
  >();
  const [salesforceTokenInfo, setSalesforceTokenInfo] =
    useState<ISalesforceTokenInfo>();

  const getSalesforceTokenInfo = useCallback(async () => {
    try {
      const hash = auth.value; // window.location.hash.split("#/salesforce/")[1];
      const decodedHash = atob(hash.split(".")[1]);
      const { numberTicket, dhOpenTicket, caseType } = JSON.parse(decodedHash);
      const isChargeback = caseType === "Chargeback";
      setSalesforceTokenInfo({
        numberTicket,
        dhOpenTicket,
        isChargeback,
      });
    } catch (err) {
      OToastManager.danger(
        "Erro ao capturar informações do pedido. Por favor recarregue a página.",
      );
    }
  }, []);

  useEffect(() => {
    getSalesforceTokenInfo();
  }, [getSalesforceTokenInfo]);

  const ITEMS_PER_PAGE = 1000;

  const loadInvoiceTransactions = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!selectedCompany) return;
      const { data } = await client.get(
        endpoints.bankinghub.getInvoiceTransactions(
          accountId,
          invoiceId,
          ITEMS_PER_PAGE,
          0,
        ),
        {
          headers: {
            "x-identification": selectedCompany,
          },
        },
      );
      setInvoiceTransactionsData(data);
    } catch (error) {
      OToastManager.danger(
        "Erro ao carregar Transações da fatura, tente novamente",
      );
    } finally {
      setIsLoading(false);
    }
  }, [selectedCompany, accountId, invoiceId]);

  useEffect(() => {
    loadInvoiceTransactions();
  }, [loadInvoiceTransactions]);

  useEffect(() => {
    if (invoiceTransactionsData && transactionDetailsId) {
      const detailsData = invoiceTransactionsData.items.find(
        (invoice) => invoice.id === transactionDetailsId,
      );
      setInvoiceTransactionItem(detailsData);
    } else {
      setInvoiceTransactionItem(undefined);
    }
  }, [invoiceTransactionsData, transactionDetailsId]);

  return (
    <>
      {!isLoading ? (
        invoiceTransactionsData && (
          <SubsectionContainer>
            <ColumnGap rowGap={10}>
              <OTypography>Transações</OTypography>
              <List
                invoiceTransactionItems={invoiceTransactionsData.items}
                transactionDetailsId={transactionDetailsId}
                setTransactionDetailsId={setTransactionDetailsId}
              />
            </ColumnGap>
            {invoiceTransactionItem && (
              <TransactionDetails
                transactionDetailsData={formTransactionDetailsFromInvoiceItem(
                  invoiceTransactionItem,
                )}
                closeTransactionDetails={() => {
                  setTransactionDetailsId(undefined);
                }}
                onRequestContestationData={{
                  onRequestContestation,
                  setOnRequestContestation,
                }}
                isChargeback={!!salesforceTokenInfo?.isChargeback}
                isChargebackTransaction={isChargebackTransactionFromInvoiceItem(
                  invoiceTransactionItem.transactionType?.isCredit,
                  invoiceTransactionItem.authorization?.type,
                )}
                openContestations={openContestations}
              />
            )}
            {onRequestContestation &&
              salesforceTokenInfo &&
              invoiceTransactionItem && (
                <TransactionChargeBack
                  chargebackTransactionItemData={{
                    creditAccountId:
                      invoiceTransactionItem?.creditAccountId.toString(),
                    authorizationId:
                      invoiceTransactionItem?.authorization.id.toString(),
                    description: formContestationDescriptionFromInvoiceItem(
                      invoiceTransactionItem,
                    ),
                    amount: invoiceTransactionItem?.amount[0].value,
                  }}
                  finishRequestContestation={() => {
                    setOnRequestContestation(false);
                    setOpenContestations((prev) =>
                      (prev ?? []).concat(
                        invoiceTransactionItem?.authorization.id.toString(),
                      ),
                    );
                  }}
                  selectedCompany={selectedCompany}
                  salesforceTokenInfo={salesforceTokenInfo}
                />
              )}
          </SubsectionContainer>
        )
      ) : (
        <div style={{ marginTop: "30px", marginLeft: "300px" }}>
          <OLoader type="light" />
        </div>
      )}
    </>
  );
};
