import { OCard, OCardBody } from "@maestro/react";
import { ContentTemplateProps } from "templates/content-template/content.types";
import { DetailsFields } from "./details-card.types";
import { DetailsWithoutCard } from "./details-without-card.component";

type DetailsCardProps<T> = Omit<
  ContentTemplateProps<T>,
  "render" | "noValue"
> & {
  fields: DetailsFields | ((value: T) => DetailsFields);
};

export const DetailsCard = <T,>(props: DetailsCardProps<T>) => {
  return (
    <OCard>
      <OCardBody className="position-relative">
        <DetailsWithoutCard {...props} />
      </OCardBody>
    </OCard>
  );
};
