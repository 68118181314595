import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const PersonalCardInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 15px;
`;

export const AdressesCardInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 415px);
  grid-gap: 15px;
  margin-top: 15px;
`;

export const CardInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
