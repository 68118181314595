import * as yup from "yup";

export const emailCotacaoDefaulValues = {
  identification: "",
  emails: [],
  isSacadoCnpj: false,
  byRootTaxId: "no",
};

export const emailCotacaoValidationSchema = yup.object({
  identification: yup
    .string()
    .trim()
    .required("É necessário informar um CNPJ")
    .min(8, "É necessário que o CNPJ seja uma raiz ou CNPJ completo")
    .max(14, "É necessário que o CNPJ seja uma raiz ou CNPJ completo")
    .test(
      "is-valid-length",
      "É necessário que o CNPJ seja uma raiz ou CNPJ completo",
      (value) => {
        if (typeof value !== "string") {
          return false;
        }
        const { length } = value;
        return length === 8 || length === 14;
      },
    ),
  emails: yup
    .array()
    .required("É necessário selecionar ao menos um email para reenvio")
    .min(1, "É necessário selecionar ao menos um email para reenvio"),
  isSacadoCnpj: yup.boolean().required(),
  byRootTaxId: yup.string().required("É necessário selecionar uma opção"),
});
