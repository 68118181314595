import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { energyDistributorsGrid } from "./energy-distributors.grid";

export const EnergyDistributorsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Distribuidoras" />}
      actions={
        <>
          <RouterButton
            role={roles.energia.product.distributorCreate.role}
            href={
              corporateRouter.routes.energia.product.distributor.createBatch
                .path
            }
          >
            Adicionar em lote
          </RouterButton>
          <RouterButton
            role={roles.energia.product.distributorCreate.role}
            href={
              corporateRouter.routes.energia.product.distributor.create.path
            }
          >
            Adicionar
          </RouterButton>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={energyDistributorsGrid} />
    </GridTemplate>
  );
};
