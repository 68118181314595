import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { copyToClipboard } from "utils/copy";

export const logsGrid = {
  datagrid: {
    noDataText:
      "Não foram encontrados logs de integração associados a este empréstimo.",
  },
  columns: [
    {
      caption: "Data",
      dataField: "createdDate",
      dataType: "datetime",
    },
    { caption: "Tipo", dataField: "type" },
    {
      caption: "Conteúdo",
      dataField: "log",
      width: 300,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: {
                category: "fal",
                icon: "fa-copy",
              },
              label: "Copiar conteúdo",
              onClick: () => copyToClipboard(data.log ?? ""),
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubLoan.getLoanIntegrationLog>
  >["data"][number]
>;
