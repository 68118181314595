import { useEffect } from "react";
import ReactFlow, {
  Controls,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  NodeTypes,
  ConnectionLineType,
} from "reactflow";
import { Container } from "./flow-container.styles";
import { getLayoutedElements } from "./flow-container.utils";
import { NewTreeNode } from "./new-tree-node";
import { SnapshotNode } from "./snapshot-node";
import { EligibilityGroupNode } from "./eligibility-group-node";
import "reactflow/dist/style.css";

interface FlowContainerProps {
  chart: { nodes: Node[]; edges: Edge[] };
  autoFormat?: boolean;
}

const nodeTypes: NodeTypes = {
  eligibilityGroup: EligibilityGroupNode,
  addNewTree: NewTreeNode,
  snapshot: SnapshotNode,
};

export const FlowContainer = ({
  chart,
  autoFormat = true,
}: FlowContainerProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(chart.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(chart.edges);

  useEffect(() => {
    const updatedChart = autoFormat
      ? getLayoutedElements(chart.nodes, chart.edges)
      : { ...chart };
    setNodes(updatedChart.nodes);
    setEdges(updatedChart.edges);
  }, [autoFormat, chart, setEdges, setNodes]);

  return (
    <Container>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        connectionLineType={ConnectionLineType.SmoothStep}
        attributionPosition="top-right"
        fitView
        fitViewOptions={{ maxZoom: 1.2 }}
      >
        <Controls />
      </ReactFlow>
    </Container>
  );
};
