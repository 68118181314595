import { OButton } from "@maestro/react";
import { useEffect, useState } from "react";
import { removeCipherFormatter } from "../../../../utils/formatters/cipher";
import { RowGap } from "../../../cards.styles";
import {
  IMiddleButtonIteration,
  IMiddleComposedTextIteration,
  IMiddleFormTextIteration,
  IMiddleSimpleTextIteration,
} from "../chargeback.types";
import { InputsContainer, QuestionContainer, Title } from "./compose.styles";
import { IQuestionTextProps, QuestionTextType } from "./compose.types";
import { InputDatetime } from "./question-text-compose/input-datetime.component";
import { InputEmail } from "./question-text-compose/input-email.component";
import { InputNumber } from "./question-text-compose/input-number.component";
import { InputTel } from "./question-text-compose/input-tel.component";
import { InputText } from "./question-text-compose/input-text.component";
import useForm, {
  QuestionTextFormErrorMessagesValidation as ErrorMessagesValidation,
  QuestionTextFormGenerateInitialFields as GenerateInitialFields,
  QuestionTextFormInputTypeToMinimumSize as InputTypeToMinimumSize,
} from "./utils";

export const QuestionText = ({
  questionIndex,
  data,
  maximumValue,
  executeAction,
  isEditable,
  inputLinksChecked,
}: IQuestionTextProps) => {
  const [initialFields, setInitialFields] = useState<Record<string, string>>(
    {},
  );

  useEffect(() => {
    const callAsync = async () => {
      const fieldValues = await GenerateInitialFields(data, inputLinksChecked);
      await setInitialFields(fieldValues);
    };
    callAsync();
  }, [data, inputLinksChecked]);

  const validationIsValid = (data: Record<string, string>) => {
    return Object.values(data).every((x) => x === "");
  };

  const validate = (fieldValues: Record<string, string> = fields) => {
    const temp = { ...errors };
    Object.keys(fieldValues).forEach((name) => {
      const question = (
        data.questions as Array<
          IMiddleSimpleTextIteration | IMiddleComposedTextIteration
        >
      ).find((question) => question.name === name);
      let type: QuestionTextType = "text";
      if (question) {
        if (question.type === "number" && !question?.label.includes("R$"))
          type = "text";
        else type = question.type;
      }
      temp[name] = ErrorMessagesValidation(
        fieldValues[name],
        type,
        InputTypeToMinimumSize(type),
      );
    });

    setErrors({ ...temp });
    return fieldValues === fields && validationIsValid(temp);
  };

  const { fields, errors, setErrors, handleInputChange } = useForm({
    initialFields,
    validateOnChange: false,
    validate,
  });

  const handleSubmit = async (action: IMiddleButtonIteration) => {
    if (validate()) {
      const response = await Object.keys(fields).map(
        (name: string, index: number) => {
          const question = data.questions.find(
            (question) => question.name === name,
          );
          return {
            label: question?.label,
            value:
              data.questions[index].type === "number"
                ? removeCipherFormatter(fields[name])
                : data.questions[index].type === "tel"
                ? fields[name].replace("+", "")
                : fields[name],
          };
        },
      );
      executeAction(action.id, {
        response,
        index: questionIndex,
        link: action.link,
      });
    }
  };

  return (
    <div key={`${questionIndex}-question-container`}>
      {fields && (
        <QuestionContainer>
          <Title>
            {questionIndex + 1}. {data.title}
          </Title>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "inherit",
            }}
          >
            <InputsContainer>
              {(
                data.questions as Array<
                  IMiddleSimpleTextIteration | IMiddleComposedTextIteration
                >
              )
                ?.filter(
                  (
                    question:
                      | IMiddleSimpleTextIteration
                      | IMiddleComposedTextIteration
                      | IMiddleFormTextIteration,
                  ) =>
                    inputLinksChecked && "link" in question
                      ? inputLinksChecked?.includes(question.link)
                      : true,
                )
                .map((question, index) => {
                  return (
                    <div key={`${index}-${question.label}`}>
                      {"placeholder" in question ? (
                        question.type === "email" ? (
                          <InputEmail
                            name={question.name}
                            label={question.label}
                            placeholder={question.placeholder}
                            isEditable={isEditable}
                            fields={fields}
                            handleInputChange={handleInputChange}
                            errors={errors}
                          />
                        ) : question.type === "datetime-local" ? (
                          <InputDatetime
                            name={question.name}
                            label={question.label}
                            isEditable={isEditable}
                            fields={fields}
                            handleInputChange={handleInputChange}
                            errors={errors}
                          />
                        ) : question.type === "tel" ? (
                          <InputTel
                            name={question.name}
                            label={question.label}
                            placeholder={question.placeholder}
                            isEditable={isEditable}
                            fields={fields}
                            handleInputChange={handleInputChange}
                            errors={errors}
                          />
                        ) : question.type === "text" ? (
                          <InputText
                            name={question.name}
                            label={question.label}
                            placeholder={question.placeholder}
                            type="text"
                            isEditable={isEditable}
                            fields={fields}
                            handleInputChange={handleInputChange}
                            errors={errors}
                          />
                        ) : (
                          <InputNumber
                            name={question.name}
                            label={question.label}
                            placeholder={
                              question.placeholder === "00.00"
                                ? "R$ 0,00"
                                : question.placeholder
                            }
                            isEditable={isEditable}
                            fields={fields}
                            handleInputChange={handleInputChange}
                            errors={errors}
                            maximumValue={maximumValue}
                          />
                        )
                      ) : (
                        <InputText
                          name={question.name}
                          label={question.label}
                          placeholder={question.label}
                          type={question.type}
                          isEditable={isEditable}
                          fields={fields}
                          handleInputChange={handleInputChange}
                          errors={errors}
                        />
                      )}
                    </div>
                  );
                })}
            </InputsContainer>
            {"actions" in data && isEditable && (
              <RowGap columnGap={80}>
                {(data.actions as Array<IMiddleButtonIteration>).map(
                  (action, index) => {
                    return (
                      <OButton
                        key={`${questionIndex}-action-option-${index}`}
                        onClick={async () =>
                          action.id === "btnBack"
                            ? executeAction(action.id, undefined)
                            : handleSubmit(action)
                        }
                        dataAction={`chargeback:botao:${action.label}`}
                        dataLabel={action.label}
                      >
                        {action.label}
                      </OButton>
                    );
                  },
                )}
              </RowGap>
            )}
          </form>
        </QuestionContainer>
      )}
    </div>
  );
};
