import {
  OCheckbox,
  OCol,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { Control, FormProvider, UseFormReturn } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { InputMultiple } from "../../../../../recebiveis/components/input-multiple/input-multiple.component";
import {
  messageIndexMap,
  messageKeyMap,
  messageTypesMap,
  roadwalkerSearchModalId,
} from "../../roadwalker.utils";
import { RoadWalkerExtraFields } from "./extra_fields";
import { RoadwalkerSearch } from "./roadwalker-search-modal.types";

interface RoadwalkerSearchModalProps {
  control: Control<RoadwalkerSearch>;
  form: UseFormReturn<RoadwalkerSearch>;
  loadingMessages: boolean;
  messageType: string;
  onSubmit: () => void;
  resetSearch: () => void;
  setShowMostRecent: React.Dispatch<React.SetStateAction<boolean>>;
  showMostRecent: boolean;
}

export const RoadwalkerSearchModal = ({
  control,
  form,
  loadingMessages,
  messageType,
  onSubmit,
  resetSearch,
  setShowMostRecent,
  showMostRecent,
}: RoadwalkerSearchModalProps) => {
  return (
    <OModal id={roadwalkerSearchModalId}>
      <OModalHeader>
        <OTypography type="dark" size="lg">
          Parâmetros de busca
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldSelect
                dataAction="roadwalker_monitor:select:tipo_mensagem"
                dataLabel="tipo_mensagem"
                id="messageType"
                name="messageType"
                label="Tipo de mensagem"
              >
                {Object.entries(messageTypesMap).map(([key, value]) => (
                  <OOption key={key} value={key}>
                    {value}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6}>
              <div className="w-100">
                <InputMultiple
                  dataAction="roadwalker_monitor:input:trade_ids"
                  dataLabel="trade_ids"
                  id="tradesIds"
                  name="tradesIds"
                  label="Ids"
                  placeholder="Digite os ids e pressione [Enter]"
                />
              </div>
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldRadioGroup
                dataAction="roadwalker_monitor:radio:message_key_index"
                dataLabel="message_key_index"
                id="indexedSearch"
                name="indexedSearch"
                label="Utilizar chave/index"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="message-key" value="key" />
                    <OLabel htmlFor="message-key">{`Chave (${getValueFromMap(
                      messageKeyMap,
                      messageType,
                    )})`}</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="message-index" value="index" />
                    <OLabel htmlFor="message-index">{`Index (${getValueFromMap(
                      messageIndexMap,
                      messageType,
                    )})`}</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6}>
              <div className="d-flex align-items-center gap-2">
                <OCheckbox
                  id="showMostRecent"
                  name="showMostRecent"
                  size="xs"
                  checked={showMostRecent}
                  value=""
                  onInput={() =>
                    setShowMostRecent((oldValue: boolean) => !oldValue)
                  }
                />
                <OLabel htmlFor="showMostRecent">Apenas mais recentes</OLabel>
              </div>
            </OCol>
            <OCol xs={12}>
              <RoadWalkerExtraFields control={control} />
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex flex-row justify-content-end gap-2">
          <LoadingButton
            dataAction="roadwalker_monitor:botao:limpar_busca"
            dataLabel="limpar_busca"
            loading={loadingMessages}
            outline
            onClick={() => resetSearch()}
          >
            Limpar
          </LoadingButton>
          <LoadingButton
            dataAction="roadwalker_monitor:botao:buscar"
            dataLabel="buscar"
            loading={loadingMessages}
            onClick={() => onSubmit()}
          >
            Buscar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
