import {
  ClosedDownCompanyNotification,
  ExpiringPowersCompanyNotification,
  InaptCompanyNotification,
  PendingReviewNotification,
} from "./_compose";

export const NotificationArea = () => {
  return (
    <>
      <ClosedDownCompanyNotification />
      <InaptCompanyNotification />
      <PendingReviewNotification />
      <ExpiringPowersCompanyNotification />
    </>
  );
};
