import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { EnergyCustomerPage } from "../pages/cliente";
import { BillingReportsCustomerPage } from "../pages/cliente/relatorios-faturamentos/billing-reports-customer.page";
import { InvoicesClientPage } from "../pages/cliente/relatorios-faturamentos/[billingReportId]/faturamentos/invoices-client.page";
import { BillingReportCustomerPage } from "../pages/cliente/relatorios-faturamentos/[billingReportId]/relatorio/billing-report-customer.page";
import { ConsumerUnitDetailsPage } from "../pages/cliente/unidades-consumidoras/[consumerUnitId]/details";
import { EditConsumerUnitPage } from "../pages/cliente/unidades-consumidoras/[consumerUnitId]/details/editar";
import { DiligenceStepsPage } from "../pages/cliente/unidades-consumidoras/[consumerUnitId]/migracao";
import { DiligenceStepPage } from "../pages/cliente/unidades-consumidoras/[consumerUnitId]/migracao/[stepId]";
import { EnergyCreateAdequatorPage } from "../pages/produto/adequadoras/adicionar/energy-create-adequator.page";
import { EnergyAdequatorsPage } from "../pages/produto/adequadoras/energy-adequators.page";
import { EnergyUpdateAdequatorPage } from "../pages/produto/adequadoras/[id]/atualizar-adequadora/energy-update-adequator.page";
import { RecommendedDistributorOptions } from "../pages/produto/adequadoras/[id]/distribuidoras-recomendadas";
import { StepAnalysisSummaryPage } from "../pages/produto/analise-migracao";
import { StepAnalysisPage } from "../pages/produto/analise-migracao/etapa";
import { StepConfigsPage } from "../pages/produto/configuracao-de-diligencia/configuracoes-de-step";
import { AddStepConfigPage } from "../pages/produto/configuracao-de-diligencia/configuracoes-de-step/adicionar";
import { EditStepConfigPage } from "../pages/produto/configuracao-de-diligencia/configuracoes-de-step/[stepConfigId]";
import { DiligenceEventsPage } from "../pages/produto/configuracao-de-diligencia/eventos";
import { AddEventRulePage } from "../pages/produto/configuracao-de-diligencia/eventos/adicionar";
import { EventRuleDetailsPage } from "../pages/produto/configuracao-de-diligencia/eventos/detalhes";
import { DocumentTypesPage } from "../pages/produto/configuracao-de-diligencia/tipos-de-documento";
import { AddDocumentTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-documento/adicionar";
import { EditDocumentTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-documento/[documentTypeId]";
import { PendencyTypesPage } from "../pages/produto/configuracao-de-diligencia/tipos-de-pendencia";
import { AddPendencyTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-pendencia/adicionar";
import { EditPendencyTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-pendencia/[pendencyTypeId]";
import { PropertyTypesPage } from "../pages/produto/configuracao-de-diligencia/tipos-de-propriedade";
import { AddPropertyTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-propriedade/adicionar";
import { EditPropertyTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-propriedade/[propertyTypeId]";
import { StepTypesPage } from "../pages/produto/configuracao-de-diligencia/tipos-de-step";
import { AddStepTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-step/adicionar";
import { EditStepTypePage } from "../pages/produto/configuracao-de-diligencia/tipos-de-step/[stepTypeId]";
import { AddDistributorPage } from "../pages/produto/distribuidora/adicionar";
import { EnergyCreateDistributorsPage } from "../pages/produto/distribuidora/adicionar-lote/energy-create-distributors.page";
import { EnergyDistributorsPage } from "../pages/produto/distribuidora/energy-distributors.page";
import { RecommendedAdequacyOptionsPage } from "../pages/produto/distribuidora/[distributorId]/adequadoras-recomendadas";
import { EnergyDistributorDiligencePage } from "../pages/produto/distribuidora/[distributorId]/configuracao-diligencia/energy-distributor-diligence.page";
import { DistributorConfigurationPage } from "../pages/produto/distribuidora/[distributorId]/configuracoes";
import { DistributorRegistrationDataPage } from "../pages/produto/distribuidora/[distributorId]/dados-cadastrais";
import { AgentsPage } from "../pages/produto/gerenciamento/agentes";
import { ConsumerUnitAssignmentPage } from "../pages/produto/gerenciamento/atribuicao-unidades-consumidoras";
import { AnalysisLogsPage } from "../pages/produto/gerenciamento/logs-analise";
import { ImportDataListPage } from "../pages/produto/importacao-unidades-consumidoras";
import { UploadImportSpreadsheetPage } from "../pages/produto/importacao-unidades-consumidoras/enviar";
import { ImportDataDetailsPage } from "../pages/produto/importacao-unidades-consumidoras/[consumerUnitsImportDataId]";
import { BillingReportsProductPage } from "../pages/produto/relatorios-faturamentos/billing-reports-product.page";
import { DiligenceStepsMonitorPage } from "../pages/produto/steps-migracao";
import { EnergyTasksPage } from "../pages/produto/tarefas/energy-tasks.page";
import { EnergyTradesPage } from "../pages/produto/trades/energy-trades.page";
import { EnergyTradeGuarantorsPage } from "../pages/produto/trades/[tradeId]/avalistas/energy-trade-guarantors.page";
import { EnergyTradePage } from "../pages/produto/trades/[tradeId]/detalhes/energy-trade.page";
import { TradeDocumentsPage } from "../pages/produto/trades/[tradeId]/documentos";
import { ConsumerUnitsMonitor } from "../pages/produto/unidades-consumidoras";
import { EnergyCommonDomainPage } from "../pages/produto/variaveis-configuracao/energy-common-domain.page";
import { energiaRoles } from "../roles/energia.roles";
import { mleRouteParams } from "./energia.route-params";

export const energiaRoutes = {
  customer: {
    name: "Matriz",
    path: "/energia/cliente",
    element: <EnergyCustomerPage />,
    children: {
      consumerUnit: {
        name: "Unidades consumidoras",
        path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}`,
        showChildren: true,
        hideGoBack: true,
        children: {
          details: {
            name: "Detalhes",
            path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/detalhes`,
            element: <ConsumerUnitDetailsPage />,
            role: energiaRoles.customer.consumerUnitDetails.role,
            children: {
              edit: {
                name: "Editar",
                path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/detalhes/editar`,
                element: <EditConsumerUnitPage />,
                role: energiaRoles.customer.consumerUnitDetailsEdit.role,
              },
            },
          },
          diligence: {
            name: "Migração",
            path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/migracao`,
            element: <DiligenceStepsPage />,
            role: energiaRoles.customer.consumerUnitMigration.role,
            children: {
              step: {
                name: "Etapa da migração",
                path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/migracao/:${mleRouteParams.stepId}`,
                element: <DiligenceStepPage />,
                role: energiaRoles.customer.consumerUnitMigrationStepType.role,
              },
            },
          },
          billingReports: {
            name: "Relatórios de Faturamento",
            path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/relatorios-faturamentos`,
            element: <BillingReportsCustomerPage />,
            role: energiaRoles.customer.billingReport.role,
            children: {
              byBillingReportId: {
                name: "Detalhes",
                path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/relatorios-faturamentos/:${mleRouteParams.billingReportId}`,
                showChildren: true,
                children: {
                  report: {
                    name: "Relatório",
                    path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/relatorios-faturamentos/:${mleRouteParams.billingReportId}/relatorio`,
                    element: <BillingReportCustomerPage />,
                    role: energiaRoles.customer.billingReportDetails.role,
                  },
                  invoices: {
                    name: "Faturamentos",
                    path: `/energia/cliente/unidades-consumidoras/:${mleRouteParams.consumerUnitId}/relatorios-faturamentos/:${mleRouteParams.billingReportId}/faturamentos`,
                    element: <InvoicesClientPage />,
                    role: energiaRoles.customer.billingReportInvoices.role,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  product: {
    name: "MLE",
    path: "/energia/produto",
    element: <RedirectWithRoles />,
    children: {
      consumerUnits: {
        name: "Unidades consumidoras",
        path: "/energia/produto/unidades-consumidoras",
        element: <ConsumerUnitsMonitor />,
        role: energiaRoles.product.consumerUnit.role,
      },
      trades: {
        name: "Propostas",
        path: "/energia/produto/trades",
        element: <EnergyTradesPage />,
        role: energiaRoles.product.trades.role,
        children: {
          byId: {
            name: "Propostas",
            path: `/energia/produto/trades/:${mleRouteParams.tradeId}`,
            showChildren: true,
            children: {
              details: {
                name: "Detalhes",
                path: `/energia/produto/trades/:${mleRouteParams.tradeId}/detalhes`,
                element: <EnergyTradePage />,
                role: energiaRoles.product.tradesDetails.role,
              },
              documents: {
                name: "Documentos",
                path: `/energia/produto/trades/:${mleRouteParams.tradeId}/documentos`,
                element: <TradeDocumentsPage />,
                role: energiaRoles.product.tradesDetailsGenerateDocuments.role,
              },
              guarantors: {
                name: "Avalistas",
                path: `/energia/produto/trades/:${mleRouteParams.tradeId}/avalistas`,
                element: <EnergyTradeGuarantorsPage />,
                role: energiaRoles.product.tradesGuarantors.role,
              },
            },
          },
        },
      },
      distributor: {
        name: "Distribuidoras",
        path: "/energia/produto/distribuidora",
        element: <EnergyDistributorsPage />,
        role: energiaRoles.product.distributor.role,
        children: {
          byId: {
            name: "Detalhes",
            path: `/energia/produto/distribuidora/:${mleRouteParams.distributorId}`,
            showChildren: true,
            children: {
              basicData: {
                name: "Dados cadastrais",
                path: `/energia/produto/distribuidora/:${mleRouteParams.distributorId}/dados-cadastrais`,
                element: <DistributorRegistrationDataPage />,
                role: energiaRoles.product.distributorDetails.role,
              },
              config: {
                name: "Configuração da distribuidora",
                path: `/energia/produto/distribuidora/:${mleRouteParams.distributorId}/configuracoes`,
                element: <DistributorConfigurationPage />,
                role: energiaRoles.product.distributorConfig.role,
              },
              adequacyOptions: {
                name: "Adequadoras recomendadas",
                path: `/energia/produto/distribuidora/:${mleRouteParams.distributorId}/adequadoras-recomendadas`,
                element: <RecommendedAdequacyOptionsPage />,
                role: energiaRoles.product.distributorAdequator.role,
              },
              diligenceConfig: {
                name: "Configuração de diligência",
                path: `/energia/produto/distribuidora/:${mleRouteParams.distributorId}/configuracao-diligencia`,
                element: <EnergyDistributorDiligencePage />,
                role: energiaRoles.product.distributorDiligence.role,
              },
            },
          },
          create: {
            name: "Cadastrar",
            path: "/energia/produto/distribuidora/adicionar",
            element: <AddDistributorPage />,
            role: energiaRoles.product.distributorCreate.role,
          },
          createBatch: {
            name: "Adicionar em lote",
            path: "/energia/produto/distribuidora/adicionar-lote",
            element: <EnergyCreateDistributorsPage />,
            role: energiaRoles.product.distributorCreate.role,
          },
        },
      },
      adequators: {
        name: "Adequadoras",
        path: "/energia/produto/adequadoras",
        element: <EnergyAdequatorsPage />,
        role: energiaRoles.product.adequators.role,
        children: {
          create: {
            name: "Adicionar",
            path: "/energia/produto/adequadoras/adicionar",
            element: <EnergyCreateAdequatorPage />,
            role: energiaRoles.product.createAdequator.role,
          },
          byId: {
            name: "Detalhes",
            path: `/energia/produto/adequadoras/:${mleRouteParams.adequatorId}`,
            showChildren: true,
            children: {
              update: {
                name: "Atualizar",
                path: `/energia/produto/adequadoras/:${mleRouteParams.adequatorId}/atualizar`,
                element: <EnergyUpdateAdequatorPage />,
                role: energiaRoles.product.updateAdequator.role,
              },
              distributorOptions: {
                name: "Distribuidoras",
                path: `/energia/produto/adequadoras/:${mleRouteParams.adequatorId}/distribuidoras`,
                element: <RecommendedDistributorOptions />,
                role: energiaRoles.product.adequatorDistributor.role,
              },
            },
          },
        },
      },
      tasks: {
        name: "Tarefas",
        path: "/energia/produto/tarefas",
        element: <EnergyTasksPage />,
        role: energiaRoles.product.tasks.role,
      },
      diligenceAnalysis: {
        name: "Análise de migração",
        path: "/energia/produto/analise-migracao",
        element: <StepAnalysisSummaryPage />,
        role: energiaRoles.product.diligenceAnalysis.role,
        children: {
          step: {
            name: "Análise da etapa",
            path: "/energia/produto/analise-migracao/etapa",
            element: <StepAnalysisPage />,
            role: energiaRoles.product.diligenceAnalysisStep.role,
          },
        },
      },
      billingReports: {
        name: "Relatórios de Faturamento",
        path: "/energia/produto/relatorios-faturamentos",
        element: <BillingReportsProductPage />,
        role: energiaRoles.product.billingReport.role,
      },
      management: {
        name: "Gerenciamento",
        path: "/energia/produto/gerenciamento",
        hasSubItems: true,
        children: {
          agents: {
            name: "Agentes",
            path: "/energia/produto/gerenciamento/agentes",
            element: <AgentsPage />,
            role: energiaRoles.product.managementAgents.role,
          },
          agentAssignments: {
            name: "Atribuição de unidades consumidoras",
            path: "/energia/produto/gerenciamento/atribuicao-unidades-consumidoras",
            element: <ConsumerUnitAssignmentPage />,
            role: energiaRoles.product.managementAgentAssignments.role,
          },
          analysisLogs: {
            name: "Logs de análise",
            path: "/energia/produto/gerenciamento/logs-analise",
            element: <AnalysisLogsPage />,
            role: energiaRoles.product.managementAnalysisLogs.role,
          },
        },
      },
      commonDomain: {
        name: "Variáveis de configuração",
        path: "/energia/produto/variaveis-de-configuracao",
        element: <EnergyCommonDomainPage />,
        role: energiaRoles.product.commonDomain.role,
      },
      diligenceConfig: {
        name: "Configuração de Diligência",
        path: "/energia/produto/configuracao-de-diligencia",
        hasSubItems: true,
        children: {
          propertyTypes: {
            name: "Tipos de Propriedade",
            path: "/energia/produto/configuracao-de-diligencia/tipos-de-propriedade",
            element: <PropertyTypesPage />,
            role: energiaRoles.product.diligenceConfigPropertiesType.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/tipos-de-propriedade/adicionar",
                element: <AddPropertyTypePage />,
                role: energiaRoles.product.diligenceConfigCreatePropertyType
                  .role,
              },
              update: {
                name: "Editar",
                path: `/energia/produto/configuracao-de-diligencia/tipos-de-propriedade/:${mleRouteParams.propertyTypeId}`,
                element: <EditPropertyTypePage />,
                role: energiaRoles.product.diligenceConfigUpdatePropertyType
                  .role,
              },
            },
          },
          stepTypes: {
            name: "Tipos de Step",
            path: "/energia/produto/configuracao-de-diligencia/tipos-de-step",
            element: <StepTypesPage />,
            role: energiaRoles.product.diligenceConfigStepsType.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/tipos-de-step/adicionar",
                element: <AddStepTypePage />,
                role: energiaRoles.product.diligenceConfigCreateStepType.role,
              },
              update: {
                name: "Editar",
                path: `/energia/produto/configuracao-de-diligencia/tipos-de-step/:${mleRouteParams.stepTypeId}`,
                element: <EditStepTypePage />,
                role: energiaRoles.product.diligenceConfigUpdateStepType.role,
              },
            },
          },
          pendencyTypes: {
            name: "Tipos de Pendência",
            path: "/energia/produto/configuracao-de-diligencia/tipos-de-pendencia",
            element: <PendencyTypesPage />,
            role: energiaRoles.product.diligenceConfigPendenciesType.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/tipos-de-pendencia/adicionar",
                element: <AddPendencyTypePage />,
                role: energiaRoles.product.diligenceConfigCreatePendencyType
                  .role,
              },
              update: {
                name: "Editar",
                path: `/energia/produto/configuracao-de-diligencia/tipos-de-pendencia/:${mleRouteParams.pendencyTypeId}`,
                element: <EditPendencyTypePage />,
                role: energiaRoles.product.diligenceConfigUpdatePendencyType
                  .role,
              },
            },
          },
          documentTypes: {
            name: "Tipos de Documento",
            path: "/energia/produto/configuracao-de-diligencia/tipos-de-documento",
            element: <DocumentTypesPage />,
            role: energiaRoles.product.diligenceConfigDocuments.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/tipos-de-documento/adicionar",
                element: <AddDocumentTypePage />,
                role: energiaRoles.product.diligenceConfigCreateDocument.role,
              },
              update: {
                name: "Editar",
                path: `/energia/produto/configuracao-de-diligencia/tipos-de-documento/:${mleRouteParams.documentTypeId}`,
                element: <EditDocumentTypePage />,
                role: energiaRoles.product.diligenceConfigUpdateDocument.role,
              },
            },
          },
          stepConfigs: {
            name: "Configurações de Step",
            path: "/energia/produto/configuracao-de-diligencia/configuracoes-de-step",
            element: <StepConfigsPage />,
            role: energiaRoles.product.diligenceConfigStepsConfig.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/configuracoes-de-step/adicionar",
                element: <AddStepConfigPage />,
                role: energiaRoles.product.diligenceConfigCreateStepConfig.role,
              },
              update: {
                name: "Editar",
                path: `/energia/produto/configuracao-de-diligencia/configuracoes-de-step/:${mleRouteParams.stepConfigId}`,
                element: <EditStepConfigPage />,
                role: energiaRoles.product.diligenceConfigUpdateStepConfig.role,
              },
            },
          },
          diligenceEvents: {
            name: "Regras de Eventos",
            path: "/energia/produto/configuracao-de-diligencia/eventos",
            element: <DiligenceEventsPage />,
            role: energiaRoles.product.diligenceConfigDiligenceEvents.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/energia/produto/configuracao-de-diligencia/eventos/adicionar",
                element: <AddEventRulePage />,
                role: energiaRoles.product.diligenceConfigDiligenceEventsCreate
                  .role,
              },
              details: {
                name: "Detalhes",
                path: `/energia/produto/configuracao-de-diligencia/eventos/:${mleRouteParams.diligenceEventRuleId}`,
                element: <EventRuleDetailsPage />,
                role: energiaRoles.product.diligenceConfigDiligenceEventsDetails
                  .role,
              },
            },
          },
        },
      },
      consumerUnitsImport: {
        name: "Importação de unidades consumidoras",
        path: "/energia/produto/importacao-unidades-consumidoras",
        element: <ImportDataListPage />,
        role: energiaRoles.product.consumerUnitsImport.role,
        children: {
          spreadsheet: {
            name: "Enviar planilha",
            path: "/energia/produto/importacao-unidades-consumidoras/enviar",
            element: <UploadImportSpreadsheetPage />,
            role: energiaRoles.product.consumerUnitsImportSpreadsheet.role,
          },
          byConsumerUnitsImportDataId: {
            name: "Detalhes",
            path: `/energia/produto/importacao-unidades-consumidoras/:${mleRouteParams.consumerUnitsImportDataId}`,
            element: <ImportDataDetailsPage />,
            role: energiaRoles.product
              .consumerUnitsImportByConsumerUnitsImportDataId.role,
          },
        },
      },
      diligenceStepsMonitor: {
        name: "Steps de migração",
        path: "/energia/produto/steps-migracao",
        element: <DiligenceStepsMonitorPage />,
        role: energiaRoles.product.diligenceStepsMonitor.role,
      },
    },
  },
} as const satisfies Routes;
