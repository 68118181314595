import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import {
  GetAdminSettlementStrategiesBySettlementIdResponse,
  SacadosCompaniesViewValueItem,
  ValueItem,
} from "services/quickfin/models";
import { service } from "services/service";
import { ODataQueryRequest } from "services/service.types";
import { logger } from "utils/logger";
import { SelectedEntity } from "../../busca-estrategia-liquidacao/settlement-strategy-search.types";

export const useBankAccountCompanySearch = (
  initialValue?: GetAdminSettlementStrategiesBySettlementIdResponse["cedente"],
) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<unknown>();
  const [value, setValue] = useState<
    SacadosCompaniesViewValueItem[] | ValueItem[]
  >();
  const [selected, setSelected] = useState<SelectedEntity>();

  const outerForm = useFormContext();
  const {
    setValue: outerSetValue,
    formState: {
      errors: { bankAccount: outerError },
    },
    watch: outerWatch,
  } = outerForm;
  const settlementMethodCodeWatch = outerWatch("settlementMethodCode");
  const tipoProdutoWatch = outerWatch("tipoProduto");
  const cedenteWatch = outerWatch("cedenteId");
  const sacadoWatch = outerWatch("sacadoId");

  const localForm = useForm<{ input: string | undefined }>();
  const {
    watch: localWatch,
    setError: localSetError,
    setValue: localSetValue,
    clearErrors: localClearErrors,
  } = localForm;
  const inputWatcher = localWatch("input");

  const isSettlementAccountFromCedente = useMemo(() => {
    return ["Cartao", "CCC", "RiscoCedente"].includes(
      tipoProdutoWatch ?? "Cartao",
    );
  }, [tipoProdutoWatch]);

  const enableBankAccountForm = useMemo(
    () => settlementMethodCodeWatch === "INTN",
    [settlementMethodCodeWatch],
  );

  const callService = useCallback(
    async (params?: ODataQueryRequest) => {
      try {
        setHasError(undefined);
        setLoading(true);

        const { data } = isSettlementAccountFromCedente
          ? await service.quickfin.listCedentes(params)
          : await service.quickfin.listSacados(params);
        setValue(data.value);
      } catch (err) {
        if (!isAxiosError(err)) {
          logger.error(err);
        }
        setHasError(!!err);
      } finally {
        setLoading(false);
      }
    },
    [isSettlementAccountFromCedente],
  );

  const debouncedGetCompanies = useMemo(
    () => debounce(callService, 500),
    [callService],
  );

  useEffect(() => {
    if (initialValue) {
      localSetValue(
        "input",
        `${initialValue.officialName} - ${initialValue.id}`,
      );
    }
  }, [initialValue, localSetValue]);

  useEffect(() => {
    if (settlementMethodCodeWatch === "INTN") {
      localSetValue("input", undefined);
    }
  }, [localSetValue, settlementMethodCodeWatch]);

  useEffect(() => {
    if (inputWatcher && inputWatcher.length > 0) {
      debouncedGetCompanies({
        $filter: `((contains(tolower(OfficialName), '${inputWatcher}')) or (startswith(Cnpj, '${inputWatcher}')))`,
        $top: 50,
      });
    }
  }, [debouncedGetCompanies, inputWatcher]);

  useEffect(() => {
    if (!enableBankAccountForm) {
      return;
    }

    const cedenteIsFilled =
      isSettlementAccountFromCedente && cedenteWatch && cedenteWatch.length > 0;

    const isSacadoFilled =
      !isSettlementAccountFromCedente && sacadoWatch && sacadoWatch.length > 0;

    if (cedenteIsFilled || isSacadoFilled) {
      callService();
    }
  }, [
    callService,
    cedenteWatch,
    enableBankAccountForm,
    isSettlementAccountFromCedente,
    sacadoWatch,
  ]);

  useEffect(() => {
    if (!value || value.length === 0 || !enableBankAccountForm) {
      return;
    }

    if (isSettlementAccountFromCedente) {
      const parsedCedenteId = parseInt(cedenteWatch) ?? 0;
      const foundCedente = (value as ValueItem[]).find(
        (cedente) => cedente.Id === parsedCedenteId,
      );
      localSetValue("input", foundCedente?.OfficialName ?? "");
      outerSetValue("bankAccount.companyId", parsedCedenteId);
    } else {
      const parsedSacadoId = parseInt(sacadoWatch) ?? 0;
      const foundSacado = (value as SacadosCompaniesViewValueItem[]).find(
        (sacado) => sacado.Id === parsedSacadoId,
      );
      localSetValue("input", foundSacado?.OfficialName ?? "");
      outerSetValue("bankAccount.companyId", parsedSacadoId);
    }
  }, [
    cedenteWatch,
    enableBankAccountForm,
    isSettlementAccountFromCedente,
    localSetValue,
    outerSetValue,
    sacadoWatch,
    value,
  ]);

  useEffect(() => {
    if (hasError)
      localSetError("input", {
        message: "Erro ao buscar as empresas",
      });
  }, [hasError, localSetError]);

  useEffect(() => {
    outerSetValue("bankAccount.companyId", selected?.id ?? 0);
  }, [selected, outerSetValue]);

  useEffect(() => {
    if (outerError?.message) {
      localSetError("input", {
        message: outerError.message as string,
      });
    } else {
      localClearErrors("input");
    }
  }, [outerError, localSetError, localClearErrors]);

  const options = useMemo(() => {
    if (!value) {
      return [];
    }

    return (
      value?.map((entity) => {
        const label = `${entity.OfficialName ?? ""} - ${masks.cnpj(
          entity.Cnpj,
        )}`;

        return {
          value: entity.Id,
          label,
          onSelect: () => setSelected({ id: entity.Id.toString(), label }),
        };
      }) ?? []
    );
  }, [value]);

  return {
    enableBankAccountForm,
    loading,
    localForm,
    options,
    selected,
    value,
  };
};
