import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useCreditorIdField = () => {
  const {
    callService,
    loading,
    value: creditors,
    hasError,
  } = useServiceCall(service.quickfin.getCreditors);

  const { clearErrors, setError } = useFormContext();

  const getCreditors = useCallback(async () => {
    const { success } = await callService();

    if (success) {
      clearErrors("creditorId");
    } else {
      setError("creditorId", {
        message:
          "Erro ao buscar as carteiras. Clique no botão ao lado para tentar novamente.",
      });
    }
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getCreditors();
  }, [getCreditors]);

  return {
    creditors,
    getCreditors,
    loading,
    hasFetchError: hasError,
  };
};
