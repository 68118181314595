interface FinancialState {
  label: string;
  type: "info" | "success" | "warning";
}

export const financialStateMap: FinancialState[] = [
  {
    label: "Quitado",
    type: "success",
  },
  {
    label: "Adimplente",
    type: "info",
  },
  {
    label: "Inadimplente",
    type: "warning",
  },
];
