export const cadastroRouteParams = {
  id: "id",
  guarantorUuid: "guarantorUuid",
  uuid: "uuid",
  account: "account",
  agency: "agency",
  workflowId: "workflowId",
  workflowIdentifier: "workflowIdentifier",
  externalReference: "externalReference",
  representativeUuid: "representativeUuid",
  workflowUuid: "workflowUuid",
} as const;

type CadastroParams = Record<keyof typeof cadastroRouteParams, string>;

export type OnboardingEditByIdRouteParams = Pick<CadastroParams, "id">;
export type OnboardingDetailsByUUIDRouteParams = Pick<
  CadastroParams,
  "externalReference"
>;
export type OnboardingReviewByUUIDRouteParams = Pick<CadastroParams, "uuid">;
export type OnboardingUpdateByUUIDRouteParams = Pick<CadastroParams, "uuid">;
export type ContactsFavoredEditByUuidRouteParams = Pick<CadastroParams, "uuid">;
export type CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams = Pick<
  CadastroParams,
  "guarantorUuid"
>;

export type CadastroClienteContasByAccountByAgencyRouteParams = Pick<
  CadastroParams,
  "account" | "agency"
>;
export type CadastroClienteToolsMigrateDesksByWorkflowIdRouteParams = Pick<
  CadastroParams,
  "workflowId"
>;

export type AtualizacaoRepresentanteLegalByUuidRouteParams = Pick<
  CadastroParams,
  "representativeUuid"
>;

export type CadastroClienteProcuracoesByUuidRouteParams = Pick<
  CadastroParams,
  "uuid"
>;
export type CadastroClienteNotificacoesOnboardingByWorkflowIdentifierRouteParams =
  Pick<CadastroParams, "workflowIdentifier">;

export type CadastroClienteSearchByWorkflowUuiddRouteParams = Pick<
  CadastroParams,
  "workflowUuid"
>;

export type OptinWorkflowUuidRouteParams = Pick<CadastroParams, "workflowUuid">;
