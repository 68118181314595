import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";
import {
  addStepModalFormDefaultValues,
  addStepModalValidatioNFormSchema,
} from "./add-step-modal.schemas";
import { contentTypeOptions } from "../../../../form-details.utils";

export const AddStepModal = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(addStepModalValidatioNFormSchema),
    defaultValues: addStepModalFormDefaultValues,
  });
  const { handleSubmit, reset } = form;
  const { hide } = modalManager;
  const {
    form: { callService: getForm, value: workflowFormTemplate },
    selectedStep,
  } = useWorkflowFormTemplate();

  const submitStep = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          if (!workflowFormTemplate) return;

          setLoading(true);

          await service.hubCreditManager.addFormStep({
            ...values,
            formId: workflowFormTemplate.id,
            position:
              selectedStep?.position ?? workflowFormTemplate.steps?.length
                ? workflowFormTemplate.steps
                    .map((s) => s.position)
                    .reduce((a, b) => Math.max(a, b)) + 1
                : 0,
          });

          reset();
          hide("add-step-modal");
          OToastManager.success("O passo foi adicionado com sucesso.");
          getForm(workflowFormTemplate?.id);
        } catch {
          OToastManager.danger("Não foi possível adicionar o passo.");
        } finally {
          setLoading(false);
        }
      }),
    [
      handleSubmit,
      workflowFormTemplate,
      selectedStep?.position,
      reset,
      hide,
      getForm,
    ],
  );

  useEffect(() => {
    if (!selectedStep) reset({ description: "", content: "CARD" });
  }, [selectedStep, reset]);

  return (
    <OModal
      id="add-step-modal"
      position="center"
      size="md"
      className="position-absolute"
      onModalClose={() =>
        workflowFormTemplate?.id && getForm(workflowFormTemplate.id)
      }
    >
      <OModalHeader closeButton>
        <div className="d-flex gap-3 align-items-center">
          <OTypography tag="h1" size="xxl">
            Adicionar novo passo
          </OTypography>
          {loading && <OLoader size="xs" />}
        </div>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-5">
          <FormProvider {...form}>
            <form spellCheck="false">
              <ORFieldInput
                id="add-step-name"
                name="name"
                tag="text"
                label="Nome"
              />
              <ORFieldInput
                id="add-step-description"
                name="description"
                tag="text"
                label="Descrição"
              />
              <ORFieldSelect
                id="add-step-content"
                name="content"
                label="Tipo do conteúdo"
              >
                {contentTypeOptions.map((option) => (
                  <OOption
                    value={option}
                    key={`content-type-component-${option}`}
                  >
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </form>
          </FormProvider>
        </div>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            type="dark"
            outline
            onClick={() => {
              workflowFormTemplate?.id && getForm(workflowFormTemplate.id);
              hide("add-step-modal");
            }}
          >
            Fechar
          </OButton>

          <OButton type="dark" onClick={() => submitStep()}>
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
