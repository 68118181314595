import { OToastManager } from "@maestro/core";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { profileManagementTypeMap } from "../../gerenciamento-de-perfis/[requestId]/_compose/profiles-role-form.schemas";
import { profileManagementChangesStatusMap } from "../../gerenciamento-de-perfis/profiles-management.utils";
import { Change } from "./admin-profile-request.types";

export const profileRequestGrid = (
  requestId: string,
  showActions: boolean,
  selectChanges: (selectedChanges: Change[]) => void,
): ODataGridGeneratorConfig<Change> => ({
  datagrid: {
    noDataText: "Nenhuma mudança encontrada",
    headerFilter: {
      visible: true,
    },
    onSelectionChanged: (evt) => selectChanges(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "type",
      caption: "Tipo",
      cellRender: ({ data }) =>
        getValueFromMap(profileManagementTypeMap, data.type) ?? data.type,
    },
    {
      caption: "Detalhes",
      cssClass: "dx-word-wrap",
      cellRender: ({ data }) => (
        <>
          {data.name && <p>Nome: {data.name}</p>}
          {data.description && <p>Descrição: {data.description}</p>}
          {data.profileName && <p>Perfil: {data.profileName}</p>}
          {data.roleNames && <p>Roles: {data.roleNames?.join(", ")}</p>}
          {data.subProfileNames && (
            <p>Sub-perfis: {data.subProfileNames?.join(", ")}</p>
          )}
        </>
      ),
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) =>
        getValueFromMap(
          profileManagementChangesStatusMap,
          data.status,
        )?.(data.errors?.join(", ")) ?? data.status,
    },
    {
      caption: "Ações",
      alignment: "center",
      visible: showActions,
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-check" },
              label: "Aprovar",
              onClick: async () => {
                try {
                  const { data: newData } =
                    await service.gatekeeper.updateAdminProfileManagementRequestChange(
                      {
                        changeId: data.id,
                        status: "APROVADA",
                      },
                    );

                  OToastManager.success("Mudança aprovada com sucesso");

                  // eslint-disable-next-line no-param-reassign
                  data.status = newData.status;
                  component.repaintRows([rowIndex]);
                } catch {
                  OToastManager.danger("Erro ao aprovar mudança");
                }
              },
            },
            {
              icon: { category: "orq", icon: "orq-close" },
              label: "Reprovar",
              route:
                corporateRouter.routes.ferramentas.product.gatekeeper.adminProfilesManagement.details.update.path(
                  {
                    changeId: data.id,
                    requestId,
                  },
                ),
            },
          ]}
        />
      ),
    },
  ],
  ...(showActions && {
    selection: {
      mode: "multiple",
      showCheckBoxesMode: "always",
      selectAllMode: "allPages",
    },
  }),
});
