import { OConfirmationModal } from "@maestro/react";
import { DataGrid } from "devextreme-react/data-grid";
import { AdminBeneficiaryAnalyses } from "services/hubfx/odata/models/responses/get-admin-beneficiary-analyses-o-data.response";
import { useAnalysisModal } from "./analysis-modal.hook";

interface IncorrectOrdersModalProps {
  orders: AdminBeneficiaryAnalyses[];
  closes?: boolean;
  gridRef: React.RefObject<DataGrid>;
}

export const AnalysisModal = ({
  orders,
  closes = false,
  gridRef,
}: IncorrectOrdersModalProps) => {
  const { loading, modalId, submitChanges } = useAnalysisModal(closes, gridRef);

  return (
    <OConfirmationModal
      modalId={modalId}
      loading={loading}
      size="sm"
      title={`Confirma a ${
        closes
          ? "desativação das análises selecionadas?"
          : "ativação das análises selecionadas?"
      }`}
      onConfirm={() => submitChanges(orders)}
    />
  );
};
