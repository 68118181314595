import { ODataGridGenerator } from "components/data-grid/o-data-grid-generator.component";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button/refresh-grid-button.component";
import { LimitGuarantorsItem } from "services/hubcreditmanager/models/responses";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./limit-guarators.grid";

interface LimitGuarantorsProps {
  limitGuarantors: LimitGuarantorsItem[] | null;
  fetchWalletLimit: () => void;
}

export const LimitGuarantors = ({
  limitGuarantors,
  fetchWalletLimit,
}: LimitGuarantorsProps) => {
  return (
    limitGuarantors && (
      <GridTemplate
        pageTitle={<PageTitle title="Avalistas" />}
        actions={<RefreshGridButton onClick={() => fetchWalletLimit()} />}
      >
        <ODataGridGenerator grid={grid} dataSource={limitGuarantors} />
      </GridTemplate>
    )
  );
};
