import { isAxiosError } from "axios";

export const getValidationMessages = (err: unknown) => {
  if (isAxiosError<HubEnergy.ValidationErrorResponse>(err)) {
    const errorType = err.response?.data.response?.errorType;

    if (
      errorType === "ValidationException" &&
      err.response?.data.response?.failures
    ) {
      return err.response.data.response.failures;
    }
  }

  return null;
};
