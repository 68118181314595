import type { OIcon } from "@maestro/react";
import { BaseColor } from "./augmented-status-badge.types";

export const customColors = {
  red: {
    color: "var(--theme-danger)",
    contrastColor: "var(--theme-danger-inverse)",
  },
  redAlt: {
    color: "var(--theme-danger-light)",
    contrastColor: "var(--theme-danger-light-inverse)",
  },
  green: {
    color: "var(--theme-success)",
    contrastColor: "var(--theme-success-inverse)",
  },
  greenAlt: {
    color: "var(--theme-success-light)",
    contrastColor: "var(--theme-success-light-inverse)",
  },
  orange: {
    color: "var(--theme-warning)",
    contrastColor: "var(--theme-warning-inverse)",
  },
  orangeAlt: {
    color: "var(--theme-warning-light)",
    contrastColor: "var(--theme-warning-light-inverse)",
  },
  blue: {
    color: "var(--theme-info)",
    contrastColor: "var(--theme-info-inverse)",
  },
  blueAlt: {
    color: "var(--theme-info-light)",
    contrastColor: "var(--theme-info-light-inverse)",
  },
  gray: {
    color: "var(--theme-custom-gray)",
    contrastColor: "var(--theme-light)",
  },
  grayAlt: {
    color: "var(--theme-custom-gray-light)",
    contrastColor: "var(--theme-dark)",
  },
  yellow: {
    color: "var(--theme-custom-yellow)",
    contrastColor: "var(--theme-dark)",
  },
  yellowAlt: {
    color: "var(--theme-custom-yellow-light)",
    contrastColor: "var(--theme-dark)",
  },
  black: {
    color: "var(--theme-dark)",
    contrastColor: "var(--theme-light)",
  },
} as const satisfies Record<string, BaseColor>;

export const typeMap = {
  info: {
    category: "orq",
    icon: "orq-info",
    badgeColor: "blueAlt",
    iconColor: "blue",
  },
  success: {
    category: "orq",
    icon: "orq-status-success",
    badgeColor: "greenAlt",
    iconColor: "green",
  },
  warning: {
    category: "orq",
    icon: "orq-status-warning",
    badgeColor: "orangeAlt",
    iconColor: "orange",
  },
  danger: {
    category: "orq",
    icon: "orq-status-disapproved",
    badgeColor: "redAlt",
    iconColor: "red",
  },
  gray: {
    category: "orq",
    icon: "orq-info",
    badgeColor: "grayAlt",
    iconColor: "gray",
  },
} as const satisfies Record<
  string,
  {
    category: React.ComponentProps<typeof OIcon>["category"];
    icon: React.ComponentProps<typeof OIcon>["icon"];
    badgeColor: keyof typeof customColors;
    iconColor: keyof typeof customColors;
  }
>;

export const biggerSizeMap = {
  xxs: "xs",
  xs: "sm",
  sm: "md",
  md: "lg",
  lg: "xl",
  xl: "xxl",
  xxl: "xxxl",
  xxxl: "xxxl",
} as const;
