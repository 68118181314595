import { service } from "services";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { OToastManager, modalManager } from "@maestro/react";
// import { DetailsLink } from "components/standard-link";
import { DataGridAction } from "components/datagrid-action";
import DataSource from "devextreme/data/data_source";
import { Resource } from "../../consent.types";
import {
  buildModalId,
  RevokeConsentModal,
} from "../../compose/revoke-consent-modal/revoke-consent-modal.component";

export const buildDataSourceByTenant = (tenantId: string) => {
  return dataSourceCustomStoreGenerator<Resource>(() =>
    service.developers
      .getFilteredResources(`tenantId=${tenantId}`)
      .then(({ data }) => ({
        data: data.items ?? [],
        totalCount: data.items.length,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os Consentimentos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );
};

export const buildDataSourceByApp = (appId: string) => {
  return dataSourceCustomStoreGenerator<Resource>(() =>
    service.developers
      .getFilteredResources(`appId=${appId}`)
      .then(({ data }) => ({
        data: data.items ?? [],
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os Consentimentos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );
};

export const resourceListGrid = (
  dataSource: DataSource<Resource, unknown>,
): ODataGridGeneratorConfig<Resource> => {
  const reload = () => dataSource.reload();
  return {
    datagrid: {
      noDataText: "Nenhum consentimento encontrado",
      filterRow: { visible: true },
      headerFilter: { visible: true },
    },
    columns: [
      {
        caption: "CNPJ",
        dataField: "tenantId",
        alignment: "center",
        dataType: "string",
      },
      {
        caption: "CPF",
        dataField: "subject",
        alignment: "center",
        dataType: "string",
      },
      {
        caption: "App",
        dataField: "appName",
        alignment: "center",
        dataType: "string",
      },
      {
        caption: "Data de Criação",
        dataField: "grantedAt",
        alignment: "center",
        dataType: "datetime",
      },
      {
        caption: "Revogado",
        dataField: "isRevoked",
        alignment: "center",
        dataType: "boolean",
      },
      {
        caption: "Data de Revogação",
        dataField: "revokedAt",
        alignment: "center",
        dataType: "datetime",
      },
      {
        caption: "Ações",
        alignment: "center",
        allowExporting: false,
        cellRender: ({ data }) => (
          <>
            <DataGridAction
              actions={[
                {
                  icon: { category: "far", icon: "fa-link" },
                  label: "Revogar",
                  disabled: data.isRevoked,
                  onClick() {
                    const modalId = buildModalId(data.id);
                    modalManager.show(modalId);
                  },
                },
              ]}
            />
            <RevokeConsentModal
              successCallback={reload}
              id={data.id}
              revokeConsentType="resource"
            />
          </>
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Resource>;
};
