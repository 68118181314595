import { OTooltip, OTypography } from "@maestro/react";
import React, { useEffect, useRef, useState } from "react";
import { GetB2BCoreOperatorResponse } from "services/b2b/core/models";
import { TooltipContent, TooltipDisplayBlock } from "./operators.styles";

interface TagCodeCellProps {
  operator: GetB2BCoreOperatorResponse[number];
}

export const TagCodeCell: React.FC<TagCodeCellProps> = ({ operator }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenContentRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const text = operator.tagCode || "-";

  /** Check if text would cause overflow */
  useEffect(() => {
    if (containerRef.current && hiddenContentRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const hiddenContentWidth =
        hiddenContentRef.current.getBoundingClientRect().width;
      setIsOverflowing(hiddenContentWidth > containerWidth);
    }
  }, [text]);

  let content = <>{text}</>;

  if (text === "-") {
    // if there are no profiles
    content = (
      <OTooltip floating>
        <TooltipContent slot="tooltip-content">
          <OTypography>Não possui Tag code</OTypography>
        </TooltipContent>
        <span className="d-inline-block">{text}</span>
      </OTooltip>
    );
  } else if (isOverflowing) {
    // if the text would overflow
    content = (
      <TooltipDisplayBlock floating>
        <TooltipContent slot="tooltip-content">
          <OTypography>{operator.tagCode}</OTypography>
        </TooltipContent>
        <span className="d-block text-truncate">{text}</span>
      </TooltipDisplayBlock>
    );
  }

  return (
    <div ref={containerRef} style={{ maxWidth: "300px" }}>
      {/* dummy text to check if `text` would cause overflow */}
      <span
        ref={hiddenContentRef}
        style={{ visibility: "hidden", position: "fixed" }}
      >
        {text}
      </span>

      {content}
    </div>
  );
};
