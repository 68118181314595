import {
  OButton,
  OCol,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { statementHistoryGrid } from "./statement-history.grid";
import { useStatementHistory } from "./statement-history.hook";

export const StatementHistory = () => {
  const { dataSource, loading, hasError, form, onSubmit } =
    useStatementHistory();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Extrato eletrônico - CNAB" />}
    >
      <div className="d-flex flex-column gap-5">
        <FormTemplate
          pageTitle={<PageTitle title="Histórico de arquivos" />}
          actions={
            <OButton
              dataAction="historico_cnab:texto:pesquisar"
              dataLabel="pesquisar"
              disabled={loading}
              onClick={() => onSubmit()}
            >
              Pesquisar
            </OButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <ORow>
                <OCol md={12} xl={4}>
                  <ORFieldInput
                    tag="cnpj"
                    id="document"
                    name="document"
                    dataAction="historico_cnab:texto:cnpj"
                    dataLabel="cnpj"
                    label="CNPJ"
                  />
                </OCol>
                <OCol xs={12} md={6} xl={4}>
                  <ORFieldInput
                    tag="text"
                    id="account"
                    name="account"
                    dataAction="historico_cnab:texto:conta_com_digito"
                    dataLabel="conta_com_digito"
                    label="Conta - com dígito"
                  />
                </OCol>
                <OCol xs={12} md={6} xl={4}>
                  <ORFieldInput
                    tag="text"
                    id="agency"
                    name="agency"
                    dataAction="historico_cnab:texto:agencia"
                    dataLabel="agencia"
                    label="Agência"
                  />
                </OCol>
              </ORow>
              <ORow>
                <OCol xs={12} md={6}>
                  <ORFieldInputDate
                    id="startAt"
                    name="startAt"
                    data-action="historico_cnab:texto:data_inicio"
                    data-label="data_inicio"
                    label="Data de início"
                  />
                </OCol>
                <OCol xs={12} md={6}>
                  <ORFieldInputDate
                    id="endAt"
                    name="endAt"
                    data-action="historico_cnab:texto:data_final"
                    data-label="data_final"
                    label="Data final"
                  />
                </OCol>
              </ORow>
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          noValue
          errorComponent={
            <ErrorComponent
              messageTitle="Ocorreu um erro ao buscar os extratos"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => onSubmit()} />
            </ErrorComponent>
          }
          render={() => (
            <ODataGridGenerator
              grid={statementHistoryGrid}
              dataSource={dataSource}
            />
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
