export const BankslipDetailsDrawerId = "bankslip-details-drawer-id";

interface CipBancoEmissor {
  ISPB: string;
  Nome: string;
  Codigo: number;
}

interface Beneficiario {
  Agencia: string | null;
  Conta: string | null;
  ContaDv: string | null;
  Documento: string | null;
  NomeFantasia: string | null;
  RazaoSocial: string | null;
  TipoPessoa: string | null;
}

interface CipDestinatario {
  ISPB: string;
  Codigo: string;
}

interface CipPagador {
  RazaoSocial: string;
  NomeFantasia: string;
  TipoPessoa: string;
  Documento: string;
}

interface CipSacadorAvarista {
  Documento: string | null;
  Nome: string | null;
}

interface CipTipoBoleto {
  Codigo: string;
  Descricao: string;
  QuantidadeParciais: number;
  QuantidadeParcelas: number;
  ParcelaAtual: number;
}

interface CipValidaPagamento {
  BoletoValidoPagamento: boolean;
  Codigo: string;
  Descricao: string;
}

interface CipDataConsumer {
  Agregador: string | null;
  BancoEmissor: CipBancoEmissor;
  BankslipIssue: null;
  BeneficiarioFinal: Beneficiario;
  BeneficiarioOriginal: Beneficiario;
  CodigoBarras: string;
  DadosBaixa: null;
  DataCalculo: string;
  DataLimitePagamento: string;
  DataLiquidacao: string;
  DataVencimento: string;
  Destinatario: CipDestinatario;
  LinhaDigitavel: string;
  Pagador: CipPagador;
  SacadorAvalista: CipSacadorAvarista;
  TipoBoleto: CipTipoBoleto;
  ValidaPagamento: CipValidaPagamento;
  ValorAbatido: number;
  ValorDesconto: number;
  ValorJuros: number;
  ValorMaximo: number;
  ValorMinimo: number;
  ValorMulta: number;
  ValorOriginal: number;
  ValorTotalACobrar: number;
}

export interface BankslipCip {
  AmountToPay: null;
  Authentication: null;
  AuthorizationId: null;
  Count: number;
  DataConsumer: CipDataConsumer;
  OriginId: null;
  PaymentChannel: null;
  PaymentType: null;
  Status: null;
  TraceKey: null;
}

export interface BankslipQueryParams {
  digitableLine: string;
  statusHistory: BankingHub.StatusHistory[];
}
