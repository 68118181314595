import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  contractSignatoriesGrid,
  dataSource,
} from "./contract-signatories.grid";

export const ContractSignatories = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Assinantes de contratos" />}
      actions={
        <StandardLink
          href={
            corporateRouter.routes.antecipacaoDeCartoes.product
              .contractSignatories.add.path
          }
          role={
            roles.antecipacaoDeCartoes.product.contractSignatoriesAddPage.role
          }
        >
          Adicionar
        </StandardLink>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={contractSignatoriesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
