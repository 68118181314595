import { OCol, OOption, ORFieldSelect } from "@maestro/react";

const templateOptions = [
  {
    value: "RiscoCedente",
    label: "Cessão de crédito",
  },
  {
    value: "RiscoSacado",
    label: "Risco sacado",
  },
  {
    value: "FIDC",
    label: "FIDC",
  },
  {
    value: "Cartao",
    label: "Cartões",
  },
  {
    value: "CCC",
    label: "Cessão com coobrigação",
  },
];

const companyOptions = [
  ...templateOptions,
  {
    value: "Offshore",
    label: "Offshore",
  },
  {
    value: "FundingProprio",
    label: "Funding próprio",
  },
];

interface TipoProdutoFieldProps {
  type: "company" | "template";
  disabled?: boolean;
}

export const TipoProdutoField = ({ disabled, type }: TipoProdutoFieldProps) => {
  const options = type === "template" ? templateOptions : companyOptions;

  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:tipo_produto"
        dataLabel="tipo_produto"
        id="tipoProduto"
        name="tipoProduto"
        placeholder="Selecionar"
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
