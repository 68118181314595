import { ICard } from "../../cards.types";

export interface ITrackingProps {
  cardData: ICard;
  selectedCompany: string;
}

export const MapperDateKey: Record<string, string> = {
  deliveryBestDate: "Melhor data de entrega",
  deliveryExactDate: "Data de entrega",
  deliveryWorstDate: "Última data de entrega",
};

export enum TrackingStatus {
  Requested = "REQUESTED",
  Solicitation = "SOLICITATION",
  Production = "PRODUCTION",
  Send = "SEND",
  SendThird = "SEND_THIRD",
  Delivery = "DELIVERY",
  PostOffice = "POST_OFFICE",
  UpdatedAddressWrongAddress = "DELIVERY_FAILED_WRONG_ADDRESS",
  UpdatedAddressCustomerAway = "DELIVERY_FAILED_CUSTOMER_AWAY",
  UpdatedAddress = "DELIVERY_FAILED_GENERIC_ADDRESS_ERROR",
  UpdateAddressRequested = "DELIVERY_ADDRESS_UPDATE_REQUESTED",
  Destroyed = "DESTROYED",
}

export interface ITrackingHistory {
  createdAt: string;
  id: string;
  status: TrackingStatus;
}

export enum DeliveryType {
  Carrier = "CARRIER",
  PostOffice = "POST_OFFICE",
}

export interface ITrackingCard {
  id: string;
  externalId: string;
  deliveryBestDate: string;
  deliveryWorstDate: string;
  deliveryExactDate: string;
  deliveryType: string;
  deliveryCode: string;
  externalTrackingURL: string;
  postOfficeWithdrawalLimitDate: string;
  history: ITrackingHistory[];
}


export enum TrackingStepType {
  Info,
  Alert,
}

export enum TrackingStepsNumber {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
}

export type CardTrackingStep = {
  subtitle: string;
  stepIndex: TrackingStepsNumber;
  type: TrackingStepType;
};

export const defaultStepsLabels = [
  "Cartão solicitado",
  "Em produção",
  "Cartão enviado",
  "Cartão entregue",
];

export const TrackingStatusToStep: Record<TrackingStatus, CardTrackingStep> = {
  [TrackingStatus.Requested]: {
    subtitle: "Cartão solicitado",
    stepIndex: TrackingStepsNumber.Step0,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.Solicitation]: {
    subtitle: "Cartão solicitado",
    stepIndex: TrackingStepsNumber.Step0,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.Production]: {
    subtitle: "Em produção",
    stepIndex: TrackingStepsNumber.Step1,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.Send]: {
    subtitle: "Cartão enviado",
    stepIndex: TrackingStepsNumber.Step2,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.SendThird]: {
    subtitle: "Cartão enviado",
    stepIndex: TrackingStepsNumber.Step2,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.Delivery]: {
    subtitle: "Cartão entregue",
    stepIndex: TrackingStepsNumber.Step3,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.PostOffice]: {
    subtitle: "Retire nos correios",
    stepIndex: TrackingStepsNumber.Step3,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.UpdatedAddressWrongAddress]: {
    subtitle: "Endereço incorreto",
    stepIndex: TrackingStepsNumber.Step3,
    type: TrackingStepType.Alert,
  },
  [TrackingStatus.UpdatedAddressCustomerAway]: {
    subtitle: "Cliente ausente",
    stepIndex: TrackingStepsNumber.Step3,
    type: TrackingStepType.Alert,
  },
  [TrackingStatus.UpdatedAddress]: {
    subtitle: "Endereço incorreto",
    stepIndex: TrackingStepsNumber.Step3,
    type: TrackingStepType.Alert,
  },
  [TrackingStatus.UpdateAddressRequested]: {
    subtitle: "Endereço alterado",
    stepIndex: TrackingStepsNumber.Step2,
    type: TrackingStepType.Info,
  },
  [TrackingStatus.Destroyed]: {
    subtitle: "Endereço incorreto",
    stepIndex: TrackingStepsNumber.Step4,
    type: TrackingStepType.Alert,
  },
};
