import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import {
  LoanAgreementForm,
  LoanAgreementFormValues,
} from "../../../../components/loan-agreement/loan-agreement-form.component";
import { loanAgreementValidationFormSchema } from "../../../../components/loan-agreement/loan-agreement-form.schemas";

export const LoanAddAgreementPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<LoanAgreementFormValues>({
    resolver: yupResolver(loanAgreementValidationFormSchema),
  });

  const { handleSubmit } = form;

  const createAgreement = useCallback(
    async (values: LoanAgreementFormValues) => {
      try {
        setLoading(true);

        await service.hubLoan.createAgreement(values);

        OToastManager.success("Convênio criado com sucesso!");
      } catch {
        OToastManager.danger("Ocorreu algum erro. Tente novamente mais tarde");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicione um convênio" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_cadastrar_perfis:botao:criar"
          dataLabel="criar"
          onClick={handleSubmit(createAgreement)}
        >
          Cadastrar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <LoanAgreementForm />
      </FormProvider>
    </FormTemplate>
  );
};
