import { useCallback, useState } from "react";
import { service } from "services";

export const useOverdraftAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [analysis, setAnalysis] = useState<BankingHub.LimitAnalysis>();
  const [taxes, setTaxes] = useState<BankingHub.Tax>();

  const getAnalysis = useCallback(async (status: string | undefined) => {
    if (!status) return;

    try {
      setLoading(true);
      setHasError(false);

      const { data } = await service.bankinghub.getOverdraftAnalysis({
        status,
      });

      setAnalysis(data.analysis?.pop());
      setTaxes(data.taxes);
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    hasError,
    analysis,
    taxes,
    getAnalysis,
  };
};
