import { PortfolioManagamentResponse } from "../responses/portfolio-management.response";

export interface MoanaLimit {
  availableValue: number;
  isActive: boolean;
  limitType: number;
}

export const MoanaOwnerStatusLibrary: {
  [key in MoanaOwnerStatus]: string;
} = {
  ProductContractSigned: "Contrato do produto",
  GuarantorRegistered: "Cadastro dos avalistas",
  AccountContractSigned: "Contrato de abertura de conta",
  OpeningAccount: "Solicitação de abertura de conta",
  AcquiringProduct: "Processo de contratação",
};

export enum MoanaOwnerStatus {
  AcquiringProduct = "AcquiringProduct",
  OpeningAccount = "OpeningAccount",
  AccountContractSigned = "AccountContractSigned",
  GuarantorRegistered = "GuarantorRegistered",
  ProductContractSigned = "ProductContractSigned",
}

export interface MoanaOwner {
  name: string;
  documentNumber: string;
  personType: number;
  totalEligibleValue: number;
  totalAnticipatedValue: number;
  totalReceivableValue: number;
  totalValue: number;
  totalLimiteValue: number;
  state: MoanaOwnerStatus[];
  isActive: boolean;
  portfolioManagement?: PortfolioManagamentResponse;
  totalPendingPaymentValue: number;
  totalLateSettlement: number;
  totalAvailableLimit?: number;
  limitStatus: string;
  expirationDate: string;
  activePortfolio: boolean;
}

export interface MoanaOwnerReceivable {
  id: string;
  barCode: string;
  receivableNumber: string;
  payerName: string;
  payerDocumentNumber: string;
  payerPersonType: number;
  amountValue: number;
  maturityDate: string;
  issueDate: string;
  status: string;
  receivableType: string;
  defensibleReceivable: boolean;
  ownerPayerAnalysis?: string[] | null;
  scoreAnalysis?: string[] | null;
}

export interface MoanaBaseParams {
  personType?: 1 | 2;
  documentNumber?: string | number;
  limit?: number;
  offset?: number;
  sort?: string;
}

export interface MoanaOwnerSearchParams {
  ownersUuid?: string[] | null;
  states?: number[] | null;
  isActive?: string;
  activeLimit?: string;
}

export interface MoanaOwnerReceivableSearchParams {
  documentNumber?: string | number;
  personType?: 1 | 2;
  sort?: string;
  payersUuid?: string[] | null;
  status?: string[] | null;
  receivableNumber?: string | null;
  maturityDate?: [string, string] | null;
  issueDate?: [string, string] | null;
  walletType?: string | null;
}

export interface MoanaOwnerReceivableParams {
  payersUuid?: string[] | null;
  status?: string[] | null;
  receivableNumber?: string | null;
  fromMaturityDate?: string | null;
  toMaturityDate?: string | null;
  walletType?: string | null;
}
