import { OButton } from "@maestro/react";
import { useStepsBuilder } from "../../steps-builder.context";

interface ResetStepButtonProps
  extends Omit<
    React.ComponentProps<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  label?: string;
}

export const ResetStepButton = (props: ResetStepButtonProps) => {
  const { onClick, label = "Cancelar" } = props;

  const { stepNumber, reset } = useStepsBuilder();

  return (
    <OButton
      dataAction={`entuba_step_${stepNumber}:botao:cancelar`}
      dataLabel="cancelar"
      type="dark"
      outline
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        reset();
      }}
    >
      {label}
    </OButton>
  );
};
