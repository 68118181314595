import { OCol } from "@maestro/react";
import { InputMultiple } from "../../../../../../../../components/input-multiple/input-multiple.component";

export const TagInputs = () => {
  return (
    <>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="receivableIdentification"
          name="receivableIdentification"
          dataAction="filtro_recebiveis:texto:identificacao_do_recebivel"
          dataLabel="identificacao_do_recebivel"
          label="Identificação(s) do(s) recebível(s)"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="receivableId"
          name="receivableId"
          dataAction="filtro_recebiveis:texto:receivable_id"
          dataLabel="receivable_id"
          label="Receivable(s) Id"
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="tradeId"
          name="tradeId"
          dataAction="filtro_recebiveis:texto:trade_id"
          dataLabel="trade_id"
          label="Trade(s) Id"
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="borderoId"
          name="borderoId"
          dataAction="filtro_recebiveis:texto:id_do_lote"
          dataLabel="id_do_lote"
          label="Id(s) do(s) lote(s)"
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="operationId"
          name="operationId"
          dataAction="filtro_recebiveis:texto:id_da_operacao"
          dataLabel="id_da_operacao"
          label="Id(s) da(s) operação(s)"
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="contractNumbers"
          name="contractNumbers"
          dataAction="filtro_recebiveis:texto:numero_do_contrato"
          dataLabel="numero_do_contrato"
          label="Número(s) do(s) contrato(s)"
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputMultiple
          id="externalIds"
          name="externalIds"
          dataAction="filtro_recebiveis:texto:identificador_externo"
          dataLabel="identificador_externo"
          label="Identificador(es) externo(s)"
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputMultiple
          id="strategyGuids"
          name="strategyGuids"
          dataAction="filtro_recebiveis:texto:guid_estrategia"
          dataLabel="guid_estrategia"
          label="Identificador(es) da(s) estratégia(s)"
        />
      </OCol>
    </>
  );
};
