import { OButton, OCol, OIcon, OLabel, ORow } from "@maestro/react";
import { useFieldArray, useForm } from "react-hook-form";
import { extraDataDefaultValue } from "../extra-data/extra-data.form";
import { ExtraDataFormFields } from "../extra-data/extra-data.types";
import { AddressFields } from "./_compose";
import { StyledBadge, StyledRow } from "./addresses-list.styles";

export const AddressesList = () => {
  const form = useForm<ExtraDataFormFields>();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  return (
    <div className="d-flex flex-column gap-3">
      <OLabel htmlFor="addresses">
        {fields.length > 1 ? "Endereços" : "Endereço"}
      </OLabel>
      <div className="d-flex flex-column" id="addresses">
        <ORow className="mx-1">
          {fields.map((field, index) => (
            <StyledRow key={field.id}>
              <OCol xs={1} className="h-100">
                <StyledBadge>{index + 1}</StyledBadge>
              </OCol>
              <OCol xs={10} align="center">
                <AddressFields addressKey={field.id} index={index} />
              </OCol>
              <OCol xs={1} align="center">
                <OButton onClick={() => remove(index)}>
                  <OIcon category="fa" icon="fa-trash" />
                </OButton>
              </OCol>
            </StyledRow>
          ))}
        </ORow>
        <OButton
          type="tertiary"
          onClick={() => append(extraDataDefaultValue.addresses[0])}
          className="mt-3"
        >
          <OIcon category="fa" icon="fa-plus" />
        </OButton>
      </div>
    </div>
  );
};
