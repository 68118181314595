import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  MapperCardModality,
  MapperCardVariant,
} from "services/bankinghub/models/types/cards";
import { OfferedCard } from "../../../../../card-hiring.types";
import { cardHiringFields } from "../../card-hiring-form.form";

interface ProgramIdFieldProps {
  data?: OfferedCard[];
}

export const ProgramIdField = ({ data }: ProgramIdFieldProps) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (data?.length === 1) {
      const [option] = data;
      setValue("programId", option.programId);
      setValue("proposalStatus", option.proposalStatus);
      setValue("modality", option.modality);
      setValue("offerId", option.offerId);
      setValue("hiredAmount", option.grantedLimit);
    }
  }, [data, setValue]);

  const onClick = (option: OfferedCard) => {
    setValue("proposalStatus", option.proposalStatus);
    setValue("modality", option.modality);
    setValue("offerId", option.offerId);
    setValue("hiredAmount", option.grantedLimit);
  };

  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldSelect {...cardHiringFields.programId}>
        {data?.map((option) => {
          const variantMapped = MapperCardVariant[option.variant];
          const modalityMapped = MapperCardModality[option.modality];
          return (
            <OOption
              key={option.programId}
              value={option.programId}
              onClick={() => onClick(option)}
            >
              {`Cartão ${variantMapped} • Modalidade ${modalityMapped}`}
            </OOption>
          );
        })}
      </ORFieldSelect>
    </OCol>
  );
};
