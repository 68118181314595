import { OToastManager } from "@maestro/core";
import axios from "axios";
import { useUser } from "contexts/user";
import { useCallback, useState } from "react";
import { service } from "services";
import { PixKeyType } from "../pix-key-input";

export const usePixKeySearchCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pixKey, setPixKey] = useState<BankingHub.PixKey | undefined>();

  const {
    user: { cpf },
  } = useUser();

  const getPixKey = useCallback(
    async (value: string, type: PixKeyType) => {
      try {
        setErrorMessage("");
        setPixKey(undefined);
        setIsLoading(true);
        const isPhone = type === PixKeyType.PHONE;

        const { data } = await service.adminBankinghub.getPixKey(value, {
          ownerTaxIdNumber: cpf ?? "",
          isPhone,
        });

        setPixKey(data.entry);
      } catch (error: unknown) {
        const message =
          axios.isAxiosError(error) && error.response?.status === 404
            ? "Chave Pix não encontrada."
            : "Ocorreu um erro ao buscar a chave Pix.";

        OToastManager.danger(message);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    },
    [cpf],
  );

  return {
    isLoading,
    getPixKey,
    errorMessage,
    pixKey,
    setErrorMessage,
  };
};
