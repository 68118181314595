import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { DeskMigrationWorkflow } from "./manage-desk-migrations.types";
import { migrationStatusMap } from "./manage-desk-migrations.utils";

export const dataSource = dataSourceCustomStoreGenerator<DeskMigrationWorkflow>(
  () =>
    service.onboarding
      .getDeskMigrations()
      .then(({ data }) => data || []) // 204 returns empty string
      .catch(() => {
        const errorMessage = "Erro ao buscar as migrações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

const buildDetailsLink = (migrationId: string) =>
  corporateRouter.routes.ferramentas.product.manageDeskMigrations.details.path({
    migrationId,
  });

export const deskMigrationsGrid: ODataGridGeneratorConfig<DeskMigrationWorkflow> =
  {
    datagrid: {
      noDataText: "Nenhuma migração",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: {
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        cellRender: ({ data: { id } }) => (
          <DetailsLink href={buildDetailsLink(id)} />
        ),
      },
      {
        dataField: "taxId",
        dataType: "string",
        caption: "CPF/CNPJ",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowHeaderFiltering: false,
      },
      {
        caption: "Segmento a migrar",
        calculateCellValue: ({ actualDesk }) =>
          [actualDesk?.desk?.segmentGroup, actualDesk?.desk?.deskName]
            .filter(Boolean)
            .join(" - ") || "-",
        calculateFilterExpression: (filterValue) => [
          ["actualDesk.desk.segmentGroup", "contains", filterValue],
          "or",
          ["actualDesk.desk.deskName", "contains", filterValue],
        ],
      },
      {
        caption: "Novo segmento",
        calculateCellValue: ({ deskToMigrate }) =>
          [deskToMigrate?.desk?.segmentGroup, deskToMigrate?.desk?.deskName]
            .filter(Boolean)
            .join(" - ") || "-",
        calculateFilterExpression: (filterValue) => [
          ["deskToMigrate.desk.segmentGroup", "contains", filterValue],
          "or",
          ["deskToMigrate.desk.deskName", "contains", filterValue],
        ],
      },
      {
        dataField: "createdDate",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Data criação",
        allowHeaderFiltering: false,
      },
      {
        dataField: "updatedDate",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Data atualização",
        allowHeaderFiltering: false,
      },
      {
        dataField: "actualMigrationStatus",
        caption: "Status",
        cellRender: ({ data: { actualMigrationStatus } }) => {
          const status = getValueFromMap(
            migrationStatusMap,
            actualMigrationStatus,
          );

          return status ? (
            <StatusBadge type={status.type}>{status.label}</StatusBadge>
          ) : (
            actualMigrationStatus
          );
        },
      },
    ],
  };
