import { ORFieldUploadInput } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FormProvider } from "react-hook-form";
import { UploadContainer } from "../../../../../../../../antecipacao-de-cartoes/components";
import { useFileUpload } from "./file-card.hook";

interface DocumentUuid {
  uuid: string;
  refetch: () => Promise<void>;
}

export const FileUpload = ({ uuid, refetch }: DocumentUuid) => {
  const {
    handleAddFile,
    hasParseError,
    readerLoading,
    reset,
    setHasParseError,
    form,
  } = useFileUpload(uuid, refetch);

  if (hasParseError)
    return (
      <div>
        <ErrorComponent
          messageTitle="Erro ao processar a planilha"
          messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
        >
          <TryAgainButton
            onClick={() => {
              setHasParseError(false);
              reset();
            }}
          />
        </ErrorComponent>
      </div>
    );

  return (
    <div>
      <FormProvider {...form}>
        <UploadContainer>
          <ORFieldUploadInput
            id="batch-tiers"
            name="batch-tiers"
            inputLabel="Clique ou arraste o arquivo aqui"
            accept="application/pdf,image/jpg,image/jpeg,image/png"
            dataAction="tiers:upload:tiers"
            dataLabel="tiers"
            multiple={false}
            handleAddFile={handleAddFile}
            disabled={readerLoading}
            value={[]}
          />
        </UploadContainer>
      </FormProvider>
    </div>
  );
};
