import {
  OCard,
  OCardBody,
  OLoader,
  OOption,
  ORFieldSelect,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useFidcFunds } from "../../hooks";

export const FundSelect = () => {
  const { funds, loading } = useFidcFunds();

  return (
    <OCard>
      <OCardBody>
        <PageTitle title="Selecione um fundo" />
        {funds && !loading ? (
          <ORFieldSelect
            name="selectedFund"
            id="selectedFund"
            dataAction="interacoes_fidc:select:fundo_fidc"
            dataLabel="fundo_fidc"
            placeholder="Selecione aqui"
          >
            {funds
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((fund) => (
                <OOption key={fund.id} value={fund.taxId ?? ""}>
                  {fund.name}
                </OOption>
              ))}
          </ORFieldSelect>
        ) : (
          <OLoader />
        )}
      </OCardBody>
    </OCard>
  );
};
