import { OButton, OIcon } from "@maestro/react";
import type DataGrid from "devextreme-react/data-grid";
import { exportGrid } from "./export-grid.utils";

interface ExportGridButtonProps
  extends Omit<
    React.ComponentPropsWithRef<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  gridRef: React.RefObject<DataGrid>;
  filename?: string;
  dataAction?: string;
  dataLabel?: string;
}

export const ExportGridButton = (props: ExportGridButtonProps) => {
  const { filename, gridRef } = props;

  return (
    <OButton
      dataAction="grid:botao:exportar_grid"
      dataLabel="exportar_grid"
      {...props}
      onClick={() => exportGrid(gridRef, filename)}
      type="default"
      outline
    >
      <OIcon
        category="far"
        icon="fa-file-spreadsheet"
        style={{ width: "16px" }} // same width as refresh grid icon
      />
    </OButton>
  );
};
