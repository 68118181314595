import {
  OCard,
  OCardBody,
  OCol,
  OLoader,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { useAdditionsDetails } from "./additions.hook";

export const AdditionsDetails = () => {
  const { detail, loading, receivableId } = useAdditionsDetails();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Aditamentos" />}>
      {loading && <OLoader absolute />}
      {!loading && !detail?.receivable.trade?.aditamentos && (
        <ORow>
          <OCol>Nenhum aditamento encontrado</OCol>
        </ORow>
      )}
      <ORow>
        {detail?.receivable.trade?.aditamentos.map((aditamento) => (
          <OCol xs={12} md={6} xl={4} key={aditamento.aditamentoId}>
            <OCard>
              <OCardBody className="d-flex flex-column">
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    ID:
                  </OTypography>
                  <OTypography type="dark">
                    {aditamento.aditamentoId}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Nova data vencimento:
                  </OTypography>
                  <OTypography type="dark">
                    {dayjs(aditamento.newMaturityDate).format("DD/MM/YYYY")}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Novo valor vencimento:
                  </OTypography>
                  <OTypography type="dark">
                    {masks.currency(aditamento.newMaturityAmount, "R$")}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Status:
                  </OTypography>
                  <OTypography type="dark">
                    {aditamento.aditamentoStatus}
                  </OTypography>
                </div>

                <div className="d-flex justify-content-end">
                  <RouterButton
                    dataAction="aditamento:botao:alterar_status"
                    dataLabel="alterar_status"
                    role={
                      roles.recebiveis.product
                        .titlesNoteNoteLegacyDetailsAdditionsDetailPage.role
                    }
                    href={corporateRouter.routes.recebiveis.product.titles.noteNote.details.additions.detail.path(
                      {
                        receivableId: receivableId!,
                        aditamentoId: aditamento.aditamentoId,
                      },
                    )}
                  >
                    Alterar Status
                  </RouterButton>
                </div>
              </OCardBody>
            </OCard>
          </OCol>
        ))}
      </ORow>
    </DetailsTemplate>
  );
};
