import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  OModal,
  OModalHeader,
  OTypography,
  OModalBody,
  OModalFooter,
  OButton,
  ORow,
  OOption,
  OCol,
  ORFieldInput,
  modalManager,
  ORFieldSelect,
} from "@maestro/react";
import { IntegrationModalName } from "../enums/integrationModalName";
import { IntegrationContext } from "../context/integration.context";
import { inputValidate } from "../validator/input-validator.utils";

export const AddContactIntegrationModal = () => {
  const { integration, setIntegration } = useContext(IntegrationContext);
  const form = useForm();

  const validateForm = (
    contactName: string,
    contactType: string,
    contactEmail: string,
    contactPhone: string,
  ) => {
    const validations = [
      { value: contactName, message: "nome do contato" },
      { value: contactType, message: "tipo do contato" },
      { value: contactEmail, message: "e-mail do contato" },
      { value: contactPhone, message: "telefone do contato" },
    ];

    return inputValidate(validations);
  };

  const handleSubmit = () => {
    const formValues = form.getValues();
    const { contactName, contactPhone, contactEmail, contactType } = formValues;
    const isvalid = validateForm(
      contactName,
      contactType,
      contactEmail,
      contactPhone,
    );

    if (!isvalid) return false;

    const contact = {
      name: contactName,
      type: contactType,
      email: contactEmail,
      phone: contactPhone,
    };

    integration.contacts.push(contact);

    setIntegration({ ...integration, contacts: integration.contacts });
    form.setValue("contactName", "");
    form.setValue("contactType", "");
    form.setValue("contactEmail", "");
    form.setValue("contactPhone", "");

    return true;
  };

  return (
    <OModal id={IntegrationModalName.ADD_CONTACT_INTEGRATION} position="center">
      <OModalHeader closeButton divider>
        <OTypography tag="h2" size="lg">
          Cadastrar novo contato
        </OTypography>
        <OTypography tag="h3" size="md">
          Preencha os campos abaixo para adicionar um novo contato.
          <br />
          Contato técnico é responsável por aplicar as configurações da VAN no
          sistema do cliente. Já o contato na VAN é o ponto focal para dúvidas e
          suporte entre cliente e VAN, não é obrigatório para a criação da
          integração.
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <form>
            <ORow class="mb-3">
              <OCol>
                <ORFieldInput
                  description=""
                  required
                  id="contactName"
                  label="Nome do contato"
                  name="contactName"
                  placeholder="Digite aqui o nome do contato"
                  tag="text"
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  id="contactPhone"
                  label="Telefone do contato"
                  name="contactPhone"
                  placeholder="Digite aqui o telefone do contato"
                  tag="phone"
                />
              </OCol>
            </ORow>
            <ORow class="mb-3">
              <OCol>
                <ORFieldInput
                  id="userDefaultName"
                  label="E-mail do contato"
                  name="contactEmail"
                  placeholder="Digite aqui o e-mail do contato"
                  tag="text"
                />
              </OCol>
              <OCol className="mt-4">
                <ORFieldSelect
                  placeholder="Selecione o tipo do contato"
                  id="contactType"
                  name="contactType"
                >
                  <OOption value="Técnico">Técnico</OOption>
                  <OOption value="VAN">VAN</OOption>
                </ORFieldSelect>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <ORow className="w-100" justify="end" align="center">
          <OButton
            class="mr-3 w-auto"
            onClick={() => {
              handleSubmit();
            }}
          >
            Salvar e adicionar novo contato
          </OButton>
          <OButton
            class="mr-3 w-auto"
            onClick={() => {
              const registerWithSuccess = handleSubmit();
              if (registerWithSuccess) {
                modalManager.hide(IntegrationModalName.ADD_CONTACT_INTEGRATION);
              }
            }}
          >
            Salvar e sair
          </OButton>
          <OButton
            onClick={() => {
              modalManager.hide(IntegrationModalName.ADD_CONTACT_INTEGRATION);
            }}
            class="w-auto"
            outline
            type="default"
          >
            Fechar
          </OButton>
        </ORow>
      </OModalFooter>
    </OModal>
  );
};
