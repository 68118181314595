import { OLoader } from "@maestro/react";

export const TinyLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: 0,
        padding: "inherit",
      }}
    >
      <OLoader size="xs" />
    </div>
  );
};
