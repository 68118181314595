import { OIcon, OTypography } from "@maestro/react";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { IconContainer } from "../cards.styles";
import { CompanyDetailsCardTemplate } from "../template";

export const CurrentAccountCard = () => {
  return (
    <CompanyDetailsCardTemplate title="Abertura de Conta Corrente">
      <div className="d-flex flex-column justify-content-between gap-3 w-100 h-100">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-info-circle" type="dark" />
          </IconContainer>
          <OTypography>
            Habilitação de Conta Corrente para fornecedores e parceiros
          </OTypography>
        </div>
        <div className="align-self-center">
          <RouterButton
            dataAction="abertura_conta_corrente:botao:habilitar"
            dataLabel="habilitar"
            href={
              corporateRouter.routes.cadastro.customer.details
                .enableCurrentAccount.path
            }
            role={roles.cadastro.customer.detailsEnableCurrentAccountPage.role}
          >
            <OTypography type="light">Habilitar</OTypography>
          </RouterButton>
        </div>
      </div>
    </CompanyDetailsCardTemplate>
  );
};
