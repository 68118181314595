import { masks, validators } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { EdmLiteral } from "devextreme/data/odata/utils";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const productOriginatorsODataGrid: ODataGridGeneratorConfig<HubPartners.Odata.Originator> =
  {
    datagrid: {
      noDataText: "Nenhum originador encontrado",
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) {
          e.error.message = "Erro ao buscar originador.";
        }
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubPartners.admin.odata.adminGetODataOriginators,
        {
          dataSourceOptions: {
            sort: [
              {
                desc: true,
                selector: "createdDate",
              },
            ],
          },
        },
      ),
    },
    columns: [
      {
        caption: "Detalhes",
        cellRender: ({ data: { identification } }) => (
          <DetailsLink
            href={buildCustomerLink(
              identification,
              corporateRouter.routes.parceiros.customer.originator.path,
            )}
          />
        ),
        allowExporting: false,
      },
      {
        dataField: "officialName",
        caption: "Razão Social",
        width: 240,
      },
      {
        dataField: "tradingName",
        caption: "Nome fantasia",
        width: 240,
      },
      {
        dataField: "identification",
        caption: "CNPJ",
        width: 160,
        format: (value) =>
          validators.cnpj(value) ? masks.cpfCnpj(value) : value,
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "partnershipTypes",
        caption: "Tipo de parceria",
        filterOperations: ["contains", "notcontains"],
        allowSorting: false,
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          let expression = "";

          if (selectedFilterOperation === "notcontains") {
            expression = `PartnershipTypes/any(type:not(contains(tolower(type), '${filterValue.toLowerCase()}')))`;
          } else {
            expression = `PartnershipTypes/any(type:contains(tolower(type), '${filterValue.toLowerCase()}'))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        width: 200,
      },
      {
        dataField: "originatorSubTypes",
        caption: "Subtipos do Originador",
        filterOperations: ["contains", "notcontains"],
        allowSorting: false,
        calculateFilterExpression: (
          filterValue: string,
          selectedFilterOperation,
        ) => {
          let expression = "";

          if (selectedFilterOperation === "notcontains") {
            expression = `OriginatorSubTypes/any(subtype:not(contains(tolower(subtype), '${filterValue.toLowerCase()}')))`;
          } else {
            expression = `OriginatorSubTypes/any(subtype:contains(tolower(subtype), '${filterValue.toLowerCase()}'))`;
          }

          return [[new EdmLiteral(expression)]];
        },
        width: 200,
      },
      {
        dataField: "cge",
        caption: "CGE",
      },
      {
        dataField: "mainOriginatorName",
        caption: "Instituição Responsável",
        width: 240,
      },
      {
        dataField: "identifier",
        caption: "Identificador Externo",
      },
      {
        dataField: "createdDate",
        caption: "Data de criação",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        width: 160,
      },
      {
        dataField: "updatedDate",
        caption: "Data de atualização",
      },
      {
        dataField: "updatedBy",
        caption: "Atualizado por",
      },
    ],
  };
