import {
  ODropdown,
  ODropdownList,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { getFirstAndLastName } from "components/user-badge";
import { useLegacySalesforceUserHook } from "hooks/legacy-salesforce/legacy-salesforce-user.hook";

export const CustomerServiceUserMenu = () => {
  const { userData: user, loading, hasError } = useLegacySalesforceUserHook();

  if (loading) {
    return <OLoader size="sm" className="me-1" />;
  }

  if (hasError) {
    return <OTypography type="default">Erro ao carregar o usuário</OTypography>;
  }

  if (!user) {
    return <OTypography type="default">Usuário não carregado</OTypography>;
  }

  return (
    <div className="d-flex align-items-start flex-column">
      <ODropdown positionX="right" positionY="bottom">
        <OTypography
          type="dark-40"
          tag="span"
          size="sm"
          style={{ cursor: "pointer" }}
        >
          <span className="d-flex gap-2">
            Solicitante{" "}
            <OIcon category="orq" icon="orq-chevron-down" size="sm" />
          </span>
        </OTypography>
        <ODropdownList
          className="me-1 p-3"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex flex-column gap-1">
              <OTypography type="dark" tag="span" weight="bold">
                {cases.title(user.name)}
              </OTypography>
              <OTypography type="dark" tag="span">
                {masks.cpf(user.cpf)}
              </OTypography>
              <OTypography type="dark" tag="span">
                {user.email}
              </OTypography>
              <OTypography type="dark" tag="span">
                {masks.phone(user.phone)}
              </OTypography>
            </div>
          </div>
        </ODropdownList>
      </ODropdown>

      <OTypography type="dark" tag="span">
        {cases.title(getFirstAndLastName(user.name).shortName)}
      </OTypography>

      <OTypography type="dark" tag="span" size="sm">
        <span className="d-flex align-items-center gap-2">
          {masks.cpf(user.cpf)}
          <CopyButton
            action="usuario-atendido:botao:copiar-cpf"
            label="copiar-cpf"
            target={user.cpf}
            toasterMessage="CPF copiado."
          />
        </span>
      </OTypography>
    </div>
  );
};
