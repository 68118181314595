import { OLoader, OToastManager } from "@maestro/react";
import { useDocumentUploadWithoutForm } from "./document-upload-without-form.hook";
import { UploadWithoutForm } from "./upload-without-form.component";

interface DocumentUploadWithoutFormProps {
  document: HubEnergy.DocumentResponse;
  label?: string;
  description?: string;
  disabled?: boolean;
  onlyOneFile?: boolean;
  onlyFiles?: boolean;
}

export const DocumentUploadWithoutForm = ({
  document,
  description,
  label,
  disabled,
  onlyOneFile,
  onlyFiles,
}: DocumentUploadWithoutFormProps) => {
  const {
    download,
    remove,
    upload,
    downloadLoading,
    removeLoading,
    uploadLoading,
  } = useDocumentUploadWithoutForm(document);

  return (
    <div className="position-relative">
      <UploadWithoutForm
        name={`document-${document.id}`}
        id={`document-${document.id}`}
        size="md"
        accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx"
        inputLabel="Selecionar arquivo"
        multiple={false}
        value={(document.files ?? []) as unknown as File[]}
        disabled={disabled || downloadLoading || removeLoading || uploadLoading}
        label={label}
        description={description}
        onlyFiles={onlyFiles}
        handleDownload={download}
        handleRemove={remove}
        handleAddFile={async (evt, resolve) => {
          if (onlyOneFile && !!document.files?.length)
            return OToastManager.warning({
              title: "Apenas um arquivo pode ser enviado",
              message: "Remova o arquivo atual para enviar outro",
            });

          await upload(evt, resolve, document.id);
        }}
      />
      {(downloadLoading || removeLoading || uploadLoading) && (
        <OLoader absolute />
      )}
    </div>
  );
};
