import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import {
  checkboxValue,
  documentTypeFormFields,
} from "./document-type-form.form";

export const DocumentTypeFormComponent = () => {
  return (
    <ORow gap={3}>
      <OCol xs={12} md={6}>
        <ORFieldInput {...documentTypeFormFields.name} />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...documentTypeFormFields.label} />
      </OCol>
      <OCol xs={12}>
        <ORFieldInput {...documentTypeFormFields.description} />
      </OCol>
      <OCol xs={12}>
        <ORFieldCheckboxGroup {...documentTypeFormFields.isMigrationDocument}>
          <div className="d-flex align-items-center gap-2">
            <OCheckbox
              id="checkbox-isMigrationDocument"
              value={checkboxValue}
              size="sm"
            />
            <OLabel htmlFor="checkbox-isMigrationDocument">
              Documento de migração
            </OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
