import { modalManager, OButton, OModal } from "@maestro/react";
import { useEffect, useId, useRef, useState } from "react";
import { OverflowWithFade } from "./overflow-with-modal.styles";

interface OverflowWithModalProps {
  children: React.ReactNode;
  modalContent: React.ReactNode;
  maxHeight: number;
}

export const OverflowWithModal = ({
  children,
  maxHeight,
  modalContent,
}: OverflowWithModalProps) => {
  const [hasOverflow, setHasOverflow] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const modalId = useId();

  useEffect(() => {
    if (containerRef.current) {
      setHasOverflow(
        containerRef.current.scrollHeight > containerRef.current.clientHeight,
      );
    }
  }, []);

  return (
    <>
      <OverflowWithFade
        ref={containerRef}
        $maxHeightPx={maxHeight}
        $hasFade={hasOverflow}
      >
        {children}
      </OverflowWithFade>
      {hasOverflow && (
        <>
          <OButton onClick={() => modalManager.show(modalId)}>
            Ver conteúdo completo
          </OButton>
          <OModal id={modalId}>{modalContent}</OModal>
        </>
      )}
    </>
  );
};
