import { OButton, OCol, OIcon, ORow, OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useState, useEffect } from "react";
import { MoanaOwner } from "services/moana/models";
import { DetailsTemplate } from "templates/details-template";
import { WalletBaseForm } from "./wallet-info.component";
import { useWalletInfo } from "./wallet-info.hook";
import { PostAdminLimitsGetLimitResponse } from "services/hubcreditmanager/models/responses";
import { PortfolioManagamentResponse } from "services/moana/models/responses/portfolio-management.response";
import moment from "moment";

export const WalletInfoPage = () => {
  const [owner, setOwner] = useState<MoanaOwner>();
  const [limit, setLimit] = useState<PostAdminLimitsGetLimitResponse>();
  const [portfolioManagement, setPortfolioManagement] =
    useState<PortfolioManagamentResponse>();
  const { getOwner, reprocessarBordero, getLimit, getPortfolioManagement } =
    useWalletInfo();

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const response = await getOwner();
      setOwner(response[0]);
    };

    fetchWalletInfo();
  }, [getOwner]);

  useEffect(() => {
    const fetchWalletLimit = async () => {
      const response = await getLimit();
      setLimit(response);
    };

    fetchWalletLimit();
  }, [getLimit]);

  useEffect(() => {
    const fetchPortfolioManagement = async () => {
      const response = await getPortfolioManagement();
      setPortfolioManagement(response?.data);
    };

    fetchPortfolioManagement();
  }, [getPortfolioManagement]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Informações da carteira" />}>
      <ORow>
        <OCol>
          {owner?.isActive && (
            <OButton
              dataAction="header:botao:reprocessar_bordero"
              dataLabel="reprocessar_bordero"
              type="tertiary"
              onClick={() => {
                reprocessarBordero();
              }}
              outline
              hidden={!owner?.isActive}
              style={{ marginRight: "20px" }}
            >
              <div className="d-flex flex-row gap-2">
                <OIcon category="fas" icon="fa-paper-plane" className="me-2" />
                <OTypography>Reprocessar bordero</OTypography>
              </div>
            </OButton>
          )}
        </OCol>
      </ORow>
      <div className="d-flex flex-column gap-4" style={{ marginTop: "20px" }}>
        <WalletBaseForm
          documentNumber={owner?.documentNumber ?? ""}
          personType={owner?.personType ?? 0}
          totalEligibleValue={owner?.totalEligibleValue ?? 0}
          totalAnticipatedValue={owner?.totalAnticipatedValue ?? 0}
          totalReceivableValue={owner?.totalReceivableValue ?? 0}
          state={owner?.state ?? []}
          activePortfolio={owner?.activePortfolio ?? false}
          isActive={owner?.isActive ?? false}
          name={owner?.name ?? ""}
          totalLimiteValue={limit?.isActive ? limit.availableAmount : 0}
          totalValue={limit?.amount ?? 0}
          portfolioManagement={portfolioManagement}
          totalPendingPaymentValue={owner?.totalPendingPaymentValue ?? 0}
          totalLateSettlement={owner?.totalLateSettlement ?? 0}
          limitStatus={limit?.currentLimitRequest?.status ?? ""}
          expirationDate={
            limit?.currentLimitRequest?.expirationDate
              ? moment(
                  limit.currentLimitRequest.expirationDate.toString(),
                ).format("DD/MM/YYYY")
              : ""
          }
        />
      </div>
    </DetailsTemplate>
  );
};
