import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  {
    value: "Cedente",
    label: "Cedente",
  },

  {
    value: "Sacado",
    label: "Sacado",
  },
];

interface RiscoOperacaoFieldProps {
  required?: boolean;
}

export const RiscoOperacaoField = ({ required }: RiscoOperacaoFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:risco_operacao"
        dataLabel="risco_operacao"
        id="riscoOperacao"
        name="riscoOperacao"
        label={EstrategiaFormLabels.riscoOperacao}
        placeholder="Selecionar"
        required={required}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
