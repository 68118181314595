import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { Act } from "../../profile-selector.types";

export const grid: ODataGridGeneratorConfig<Act> = {
  datagrid: {
    filterRow: { visible: true },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "ID",
      sortOrder: "asc",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
  ],
};

interface ActsGridProps {
  acts: Act[];
}

export const ActsGrid = ({ acts }: ActsGridProps) => {
  return <ODataGridGenerator grid={grid} dataSource={acts} />;
};
