import { OToastManager, modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { ODataBatchValueItem } from "services/hubcreditmanager/odata/models/responses/get-odata-batch-file-templates-admin.respose";
import { BATCH_TEMPLATE_MODAL_ID } from "./modal/modal.component";

type BatchFileTemplates = Awaited<
  ReturnType<typeof service.hubCreditManager.odata.getBatchFileTemplates>
>["data"]["value"][number];

export const batchFileTemplatesGrid = (
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<ODataBatchValueItem | undefined>
  >,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum template encontrado",
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.batchFileTemplateAdmin,
      ),
      headerFilter: { visible: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        role: roles.workflow.product.batchTemplatesDetailsSummaryPage.role,
        cellRender: ({ data }) =>
          data.Id ? (
            <DetailsLink
              href={corporateRouter.routes.workflow.product.batchs.batchTemplates.summary.path(
                {
                  id: data.Id,
                },
              )}
            />
          ) : undefined,
      },
      {
        dataField: "CreatedDate",
        caption: "Data criação",
        dataType: "datetime",
        sortOrder: "desc",
      },
      {
        dataField: "UpdatedDate",
        caption: "Última atualização",
        dataType: "string",
        cellRender: ({ data }) =>
          data.UpdatedDate
            ? dayjs(data?.UpdatedDate).format("DD/MM/YYYY HH:mm:ss")
            : "-",
      },
      {
        dataField: "Name",
        caption: "Nome",
        dataType: "string",
      },
      {
        dataField: "Description",
        caption: "Descrição",
        dataType: "string",
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar",
                onClick: () => {
                  setSelectedTemplate(data);
                  modalManager.show(BATCH_TEMPLATE_MODAL_ID);
                },
              },
              {
                icon: {
                  category: "fal",
                  icon: "fa-trash",
                },
                label: "Remover",
                onClick: async () => {
                  try {
                    await service.hubCreditManager.removeBatchFileTemplate(
                      data.Id,
                    );
                    component.getDataSource().reload();
                    OToastManager.success("Template removido com sucesso");
                  } catch {
                    OToastManager.danger("Erro ao remover template");
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<BatchFileTemplates>);
