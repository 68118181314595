import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { GetB2BCoreOriginatorResponse } from "services/b2b/core/models";

export const buildOriginatorFields = (
  originator: GetB2BCoreOriginatorResponse,
): DetailsFields => ({
  dates: {
    items: [
      {
        label: "Data de criação",
        value: originator.createdAt
          ? dayjs(originator.createdAt).format("DD/MM/YYYY")
          : "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Última atualização",
        value: originator.lastModifiedAt
          ? dayjs(originator.lastModifiedAt).format("DD/MM/YYYY")
          : "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  originator: {
    title: "Originador",
    items: [
      {
        label: "Razão social",
        value: originator.corporateName?.toUpperCase(),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "CNPJ",
        value: masks.cpfCnpj(originator.identification) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Tipo do parceiro",
        value: originator.partnerTypes?.map((pt) => pt.friendlyName).join(", "),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Código CGE",
        value: originator?.codCge ?? "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  details: {
    items: [
      {
        label: "Cidade / Estado",
        value: `${originator.address.city?.toUpperCase()} / ${originator.address.state?.toUpperCase()}`,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Produtos do parceiro",
        value: originator.products?.map((p) => p.friendlyName).join(", "),
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  indicatedOriginator: {
    title: "Indicador",
    items: [
      {
        label: "Originador que indicou",
        value: originator?.indicatorOriginator?.name?.toUpperCase(),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "CNPJ do originador",
        value:
          masks.cpfCnpj(originator?.indicatorOriginator?.identification) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Operador que indicou",
        value: originator.indicatorOperator?.name?.toUpperCase(),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "CPF do operador",
        value: masks.cpf(originator.indicatorOperator?.identification) || "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
