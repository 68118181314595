import { OToastManager } from "@maestro/react";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GatekeeperSubProfilesDetails } from "services/gatekeeper/models/responses/post-gatekeeper-profiles-get-details.response"

export const useGatekeeperProfilesSubProfiles = (
  uuid?: string,
  name?: string,
) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const labelRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [subProfiles, setSubProfiles] = useState<GatekeeperSubProfilesDetails[]>([]);
  const [allProfiles, setAllProfiles] = useState<SelectSearchOption<string>[]>(
    [],
  );


  const submit = async () => {
    try {
      setLoading(true);

      if (!name) return;

      const payload = {
        profile: name,
        subProfiles: subProfiles.map(s => s.name),
      };

      await service.gatekeeper.updateSubProfiles(payload);

      OToastManager.success("Adicionado subperfis no profile com sucesso");

      if (!uuid) throw new Error("No uuid");

      navigate(
        routes.ferramentas.product.gatekeeper.profiles.profile.subProfiles.path(
          { uuid: uuid },
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  };

  const getAllRoles = useCallback(async () => {
    if (!name) return;

    const { data } = await service.gatekeeper.getAllProfiles();
    const newData = data.map((d) => {
      return {
        value: d.name,
        label: d.name,
        customKey: d.name,
        onSelect: async (value: string) => {
          const { data } = await service.gatekeeper.getProfileByName(value)
          setSubProfiles((prevState) => {
            return [...prevState, data];
          })
        }
      };
    });

    setAllProfiles(newData);
  }, [name]);

  const load = useCallback(async () => {
    if (!uuid || !name) return;

    const { data } = await service.gatekeeper.getProfileDetails({ profiles: [ { uuid } ] });

    setSubProfiles(data[0].subProfiles)

  }, [name]);

  useEffect(() => {
    getAllRoles();
    load();
  }, [getAllRoles, load]);

  return {
    allProfiles,
    labelRef,
    loading,
    subProfiles,
    setSubProfiles,
    submit,
  };
};
