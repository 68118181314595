import {
  OCol,
  OLabel,
  OLink,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { service } from "services";
import { GatekeeperProfileFormProps } from "./profile-form.types";

export const GatekeeperProfileForm = ({ form }: GatekeeperProfileFormProps) => {
  const [groups, setGroup] =
    useState<
      Awaited<ReturnType<typeof service.gatekeeper.getProfileGroup>>["data"]
    >();

  const getGroup = useCallback(async () => {
    try {
      const { data } = await service.gatekeeper.getProfileGroup();

      setGroup(data);
    } catch {
      OToastManager.danger("Erro ao buscar grupos");
    }
  }, []);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  return (
    <>
      <div className="d-flex flex-column gap-1 mb-4">
        <OTypography>
          <strong>Atenção:</strong> O nome de um profile deve seguir a seguinte
          formatação:
        </OTypography>
        <OTypography weight="500">
          [portal ou serviço]:[produto ou
          recurso]-[categoria(opcional)].[perfil].
        </OTypography>
        <OTypography>Ex: parceiro:Usuario-Payer.ImpactoSocial</OTypography>
        <OTypography>
          Para mais detalhes, acesse a documentaçãoclique aqui para ver a{" "}
          <OLink
            href="https://wiki.quickfin.com.br/en/Plataforma/Enablers/primeiro-acesso/dev/gatekeeper/profiles-roles"
            target="_blank"
          >
            documentação
          </OLink>
        </OTypography>
      </div>

      <FormProvider {...form}>
        <form>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="nameLabel"
                name="nameLabel"
                dataAction="adicionar_perfil:input:label"
                dataLabel="label"
                label="Label"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="portal"
                name="portal"
                dataAction="adicionar_perfil:input:portal-ou-servico"
                dataLabel="portal-ou-servico"
                label="Portal ou Serviço"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="product"
                name="product"
                dataAction="adicionar_perfil:input:produto-ou-recurso"
                dataLabel="produto-ou-recurso"
                label="Produto ou recurso"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="profile"
                name="profile"
                dataAction="adicionar_perfil:input:perfil"
                dataLabel="perfil"
                label="Perfil"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="availablePermissionsDescription"
                name="availablePermissionsDescription"
                dataAction="adicionar_perfil:input:breve-descricao-da-funcionalidade-disponivel"
                dataLabel="breve-descricao-da-funcionalidade-disponivel"
                label="Breve descrição da funcionalidade disponível"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="unavailablePermissionsDescription"
                name="unavailablePermissionsDescription"
                dataAction="adicionar_perfil:input:breve-descricao-da-funcionalidade-indisponivel"
                dataLabel="breve-descricao-da-funcionalidade-indisponivel"
                label="Breve descrição da funcionalidade indisponível"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="type"
                name="type"
                dataAction="adicionar_perfil:input:tipo"
                dataLabel="tipo"
                placeholder="Tipo"
                label="Tipo"
              >
                <OOption id="usuario" value="USUARIO">
                  Usuário
                </OOption>
                <OOption id="sistema" value="SISTEMA">
                  Sistema
                </OOption>
                <OOption id="visitante" value="VISITANTE">
                  Visitante
                </OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="groupName"
                name="groupName"
                dataAction="adicionar_perfil:input:labelGrupo"
                dataLabel="labelGrupo"
                placeholder="Label do Grupo"
                label="Label do Grupo"
                key={groups?.length}
              >
                {groups?.map((group) => (
                  <OOption key={group.name} value={group.name}>
                    {group.nameLabel}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                dataAction="criar_email_automatico:radio:e-um-profile-built-in"
                dataLabel="e-um-profile-built-in"
                id="isBuiltIn"
                name="isBuiltIn"
                label="É um profile built-in?"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="isBuiltInTrue" value="true" />
                    <OLabel htmlFor="isBuiltInTrue">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="isBuiltInFalse" value="false" />
                    <OLabel htmlFor="isBuiltInFalse">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </form>
      </FormProvider>
    </>
  );
};
