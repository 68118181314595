import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "./add.form";
import { FormValuesType, LocalFormValuesType } from "./add.types";

export const useGatekeeperSystemsAdd = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [allProfiles, setAllProfiles] = useState<SelectSearchOption<string>[]>(
    [],
  );

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue, getValues, watch } = form;

  const watchProfiles = watch("profiles");

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;

  const watchInput = localWatch("input");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        system: {
          ...values,
          profiles: values.profiles ?? [],
        },
      };

      await service.gatekeeper.addSystem(payload);

      OToastManager.success("Sistema adicionado com sucesso");

      navigate(routes.ferramentas.product.gatekeeper.systems.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.gatekeeper.getAllProfiles();

      const newData = data.map((d) => {
        return {
          value: d.name,
          label: d.name,
          customKey: d.name,
          onSelect: (value: string) => {
            const profiles = getValues("profiles") ?? [];

            setValue("profiles", [...new Set([...profiles, value])]);
          },
        };
      });

      setAllProfiles(newData);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [getValues, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (watchProfiles) localSetValue("input", undefined);
  }, [localSetValue, watchProfiles]);

  return {
    allProfiles,
    form,
    loading,
    localForm,
    watchInput,
    watchProfiles,
    setValue,
    submit,
  };
};
