import { OToastManager } from "@maestro/core";
import { OCol, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";

export const useIndicationFields = (isEditing: boolean) => {
  const [indicators, setIndicators] = useState<HubPartners.Admin.Referrer[]>(
    [],
  );
  const [loadingReferrers, setLoadingReferrers] = useState(false);
  const [wasIndicated, setWasIndicated] = useState(false);
  const [operatorName, setOperatorName] = useState("");
  const [originatorName, setOriginatorName] = useState("");

  const form = useFormContext();
  const { getValues, setValue, watch } = form;
  const indicatorOnFieldWatch = watch("indicatorOnField");
  const selectedOriginatorWatch = watch("selectedOriginator");
  const selectedOperatorWatch = watch("selectedOperator");

  const selectedIndicators = useMemo(() => {
    if (!originatorName || !operatorName) return;

    return (
      <OCol sm={6}>
        <div>
          <OTypography tag="p" size="sm" type="dark-60">
            Indicador selecionado
          </OTypography>
          <OTypography>
            {`${originatorName} - ${masks.cpfCnpj(selectedOriginatorWatch)}`}
          </OTypography>
          <div className="d-flex justify-content-between">
            <OTypography size="sm">
              {`${operatorName} - ${masks.cpfCnpj(selectedOperatorWatch)}`}
            </OTypography>
          </div>
        </div>
      </OCol>
    );
  }, [
    originatorName,
    operatorName,
    selectedOriginatorWatch,
    selectedOperatorWatch,
  ]);

  const toggleHasIndicatorCheckbox = useCallback(
    (hasIndication: boolean) => {
      if (!hasIndication) {
        setValue("referrerOperatorProfileIdentifier", undefined);
        setValue("indicatorOnField", undefined);
        setValue("selectedOriginator", undefined);
        setValue("selectedOperator", undefined);
      }
      setWasIndicated(hasIndication);
    },
    [setValue],
  );

  const fetchReferrers = useCallback(async (search: string) => {
    try {
      setLoadingReferrers(true);

      const { data } = await service.hubPartners.getReferrersOperatorProfiles(
        search,
      );

      setIndicators(data.data);
    } catch (e) {
      logger.error(e);
      OToastManager.danger("Erro ao buscar os Indicadores.");
    } finally {
      setLoadingReferrers(false);
    }
  }, []);

  const debouncedIndicatorsSearch = useMemo(() => {
    return debounce(fetchReferrers, 800);
  }, [fetchReferrers]);

  useEffect(() => {
    if (!indicatorOnFieldWatch) return;

    debouncedIndicatorsSearch(indicatorOnFieldWatch);

    return () => {
      debouncedIndicatorsSearch.cancel();
    };
  }, [debouncedIndicatorsSearch, indicatorOnFieldWatch]);

  useEffect(() => {
    if (isEditing) {
      setWasIndicated(getValues("hasIndicator").includes("check"));
    }
  }, [getValues, isEditing]);

  return {
    loadingReferrers,
    toggleHasIndicatorCheckbox,
    wasIndicated,
    form,
    setOperatorName,
    setOriginatorName,
    indicators,
    indicatorOnFieldWatch,
    setValue,
    selectedIndicators,
  };
};
