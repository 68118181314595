import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { buildCustomerLink } from "utils/build-customer-link";
import {
  FormValuesType,
  validationSchema,
} from "../_compose/current-account-form";

export const useAccountPjNewAccount = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const payload = {
        identification: values.taxId,
        deskCode: String(values.desk),
        officerCge: values.officer,
        accounts: [
          {
            agency: values.account?.split("-")[0],
            type: values.account?.split("-")[1],
          },
        ],
      };

      await service.onboarding.registerEntityFromDesk(payload);

      OToastManager.success("Cadastrado com sucesso");
      navigate(
        buildCustomerLink(
          values.taxId,
          corporateRouter.routes.cadastro.customer.onboardingEmbedded.path,
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return { form, submit, loading, setLoading };
};
