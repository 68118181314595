import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  OfferFormValues,
  offerFormDefaultValues,
  offerFormValidationSchema,
} from "../offer-form.schema";

export const useEditOffer = () => {
  const { id: workflowConfigId, offerId } = useParams<{
    id: string;
    offerId: string;
  }>();
  if (!workflowConfigId || !offerId) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<OfferFormValues>({
    resolver: yupResolver(offerFormValidationSchema),
    defaultValues: offerFormDefaultValues,
  });

  const { setValue } = form;

  const { callService: callEditOffer, loading: loadingEditOffer } =
    useServiceCall(service.hubCreditManager.updateOffer);

  const {
    callService: getOfferById,
    loading: loadingGetOfferById,
    value: offer,
    hasError: hasErrorGetOfferById,
  } = useServiceCall(service.hubCreditManager.getOfferById);

  const onAddFile = useCallback(
    (e: OUploadCustomEvent<File>) => {
      const file = e.detail;

      setValue("file", [file]);
    },
    [setValue],
  );

  const editOffer = useCallback(
    async ({ description, label, subtitle, title, file }: OfferFormValues) => {
      const formData = new FormData();

      formData.append("description", description);
      formData.append("label", label);
      formData.append("subtitle", subtitle);
      formData.append("title", title);
      formData.append("id", offerId);

      if (file?.length && file[0].size) {
        formData.append("file", file[0]);
      }

      const { success } = await callEditOffer(formData);

      if (success) {
        OToastManager.success("A oferta foi adicionada com sucesso.");

        navigate(
          corporateRouter.routes.workflow.product.workflowsConfig.details.edit.offers.path(
            { id: workflowConfigId },
          ),
        );
      } else {
        OToastManager.danger("Um erro ocorreu ao tentar editar a oferta.");
      }
    },
    [callEditOffer, navigate, offerId, workflowConfigId],
  );

  useEffect(() => {
    getOfferById(offerId);
  }, [getOfferById, offerId]);

  useEffect(() => {
    if (offer) {
      setValue("description", offer.description);
      setValue("label", offer.label);
      setValue("subtitle", offer.subtitle);
      setValue("title", offer.title);
      if (offer.file) {
        const fileAux = new File([], offer.file.name);

        setValue("file", [fileAux]);
      }
    }
  }, [offer, setValue]);

  useEffect(() => {
    hasErrorGetOfferById &&
      OToastManager.danger(
        "Um error ocorreu ao tentar receber os dados da oferta. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorGetOfferById]);

  return {
    editOffer,
    form,
    getOfferById,
    hasError: hasErrorGetOfferById,
    loadingEditOffer,
    loadingGetOfferById,
    offer,
    onAddFile,
  };
};
