import {
    OButton,
    OModal,
    OModalBody,
    OModalFooter,
    OModalHeader,
    ORFieldInput,
    OTypography,
    modalManager,
  } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { useAppApprove } from "./aprovar-app-modal.hook";
  interface AddAppApprovalModalProps {
    name: string;
    appId: string;
    version: number | string;
    tenantId: string;
    successCallback: () => void;
  }  
export const buildModalId = (appId: string) =>
    `developers-modal-approve-${appId}`
export const AppApprovalModal = ({appId, name, version, tenantId, successCallback}:AddAppApprovalModalProps) =>{
    const modalId = buildModalId(appId)
    const { form, loading, submit } = useAppApprove(
        modalId,
        appId,
        version,
        tenantId,
        successCallback,
      );
     return(
    <OModal id={modalId} position="center" backdrop>
        <OModalHeader>
            <OTypography size="lg">Aprovar</OTypography>
        </OModalHeader>
        <OModalBody>
            <div>
                <OTypography>Deseja aprovar o aplicativo {name}?</OTypography>
                <FormProvider {...form}>
                    <ORFieldInput
                        id="statusObservation"
                        name="statusObservation"
                        tag="text"
                        label="Envie uma mensagem sobre o motivo da recusa."
                        placeholder="Aprovado"
                        disabled={loading}
                    />
                </FormProvider>
            </div>
        </OModalBody>
        <OModalFooter>
            <div className="d-flex justify-content-end gap-3">
                <OButton
                onClick={() => submit()}>
                    Aprovar
                </OButton>
                <OButton
                onClick={()=>modalManager.hide(modalId)}>
                    Cancelar
                </OButton>
            </div>
        </OModalFooter>
    </OModal>
  );
     };