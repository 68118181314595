import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

export const exchangeOdataCounterpartiesGrid = {
  datagrid: {
    noDataText: "Nenhuma contraparte encontrada",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar contrapartes.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminCounterpartiesOData,
    ),
  },
  columns: [
    {
      type: "buttons",
      allowExporting: false,
      role: roles.cambio.product.counterpartiesCounterpartyDetailsPage.role,
      cellRender: ({ data }) => {
        if (data.Identification) {
          return (
            <DetailsLink
              href={buildCustomerLink(
                data.Identification,
                corporateRouter.routes.cambio.customer.quotes.path,
              )}
            />
          );
        }
        return null;
      },
    },
    {
      dataField: "Id",
      caption: "Id",
      dataType: "number",
    },
    {
      caption: "Nome",
      dataField: "Name",
    },
    {
      caption: "Identificação",
      dataField: "Identification",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Cge",
      dataField: "Cge",
    },
    {
      caption: "Pré-Ticket habilitado",
      dataField: "HasPreTicketEnabled",
    },
    {
      caption: "Pré-Ticket obrigatório",
      dataField: "ForcePreTicket",
    },
    {
      role: roles.cambio.product.counterpartiesEditPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          href={`${
            corporateRouter.routes.cambio.product.counterparties.edit.path
          }?data=${encodeURIComponent(JSON.stringify(data))}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getCounterparties>
    >["data"]["value"]
  >[number]
>;
