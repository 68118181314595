import { OCol } from "@maestro/react";
import { RuleEditorField } from "../../rule-editor.types";

interface FieldRendererProps {
  field: RuleEditorField;
  eventType: HubEnergy.EDiligenceEventType;
}

export const FieldRenderer = ({ field, eventType }: FieldRendererProps) => (
  <OCol
    xs={12}
    style={{
      display: field.availableEventTypes.includes(eventType)
        ? undefined
        : "none",
    }}
    className="col-lg"
  >
    <field.Component />
  </OCol>
);
