import {
  OCol,
  ODivider,
  OIcon,
  ORow,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { InfoListProps } from "./info-list.types";

export const InfoList = ({
  items,
  title,
  colSize,
  children,
  divider,
  contentTop,
}: InfoListProps) => {
  return (
    <div>
      {title && (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between">
            <OTypography size="lg">{title}</OTypography>
            {contentTop && contentTop}
          </div>
        </div>
      )}

      <ORow align="start">
        {items.map((item, index) => {
          const itemsPerRow = Number(colSize) - 1;
          const isLastRow =
            Math.floor(index / itemsPerRow) ===
            Math.floor((items.length - 1) / itemsPerRow);

          return (
            <OCol
              sm={colSize}
              key={item.label}
              className={
                divider === "vertical" ? "my-4" : "d-flex flex-column my-4"
              }
            >
              {divider === "vertical" &&
                index !== 0 &&
                (colSize !== 3 || index !== 4) && (
                  <ODivider position="vertical" className="mr-3 d-block" />
                )}

              <div
                className={
                  divider === "vertical"
                    ? "d-flex flex-column"
                    : "d-flex flex-column gap-2"
                }
              >
                <div className="d-flex flex-row gap-2">
                  <OTypography type="dark-80">{item.label}</OTypography>

                  {item.tooltip && (
                    <OTooltip maxWidth="400px">
                      <span slot="tooltip-content">{item.tooltip}</span>
                      <OIcon
                        size="lg"
                        category="orq"
                        icon={item.tooltipIcon ?? "orq-info"}
                        type={item.tooltipIcon ? "warning" : "info"}
                      />
                    </OTooltip>
                  )}

                  {item.icon && item.icon}
                </div>

                {item.content}

                {divider === "horizontal" && !isLastRow && (
                  <ODivider size="xs" className="w-100 my-4" />
                )}
              </div>
            </OCol>
          );
        })}
      </ORow>

      {children && children}
    </div>
  );
};
