import {
  CustomLabelSectionFilter,
  DateRangeSectionFilter,
  IntervalAmountSectionFilter,
  IntervalCountSectionFilter,
  MultFinancialInstitutionSelectionSectionFilter,
  MultSelectionSectionFilter,
  Section,
  SectionFilterType,
  SelectableSearchSectionFilter,
  SingleSelectionSectionFilter,
} from "../../side-filter-bar.types";

export const isAMultSelectionFieldValue = (
  fieldValue: unknown,
): fieldValue is Array<unknown> => {
  return Array.isArray(fieldValue);
};

export const isASingleSelectionSectionFilter = (
  section: Section,
): section is SingleSelectionSectionFilter => {
  return section.type === SectionFilterType.SingleSelection;
};

export const isAMultSelectionSectionFilter = (
  section: Section,
): section is MultSelectionSectionFilter => {
  return section.type === SectionFilterType.MultSelection;
};

export const isADateRangeSectionFilter = (
  section: Section,
): section is DateRangeSectionFilter => {
  return section.type === SectionFilterType.DateRange;
};

export const isACustomLabelSectionFilter = (
  section: Section,
): section is CustomLabelSectionFilter => {
  return section.type === SectionFilterType.CustomLabel;
};

export const isAIntervalAmountSectionFilter = (
  section: Section,
): section is IntervalAmountSectionFilter => {
  return section.type === SectionFilterType.IntervalAmount;
};

export const isASelectableSearchSectionFilter = (
  section: Section,
): section is SelectableSearchSectionFilter => {
  return section.type === SectionFilterType.SelectableSearch;
};

export const isAIntervalCountSectionFilter = (
  section: Section,
): section is IntervalCountSectionFilter => {
  return section.type === SectionFilterType.IntervalCount;
};

export const isAMultFinancialInstitutionSelectionSectionFilter = (
  section: Section,
): section is MultFinancialInstitutionSelectionSectionFilter => {
  return section.type === SectionFilterType.MultFinancialInstitution;
};
