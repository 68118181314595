import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../routes/ferramentas.route-params";
import { buildDeskConfigOfficerGrid } from "./desk-config-officer.grid";

export const DeskConfigOfficerPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  const gridRef = useGridRef();

  if (!configIdentifier) throw new Error("No configIdentifier");

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Officers relacionado com a mesa"
          description={configIdentifier}
        />
      }
      actions={
        <RouterButton
          href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.desksConfigs.details.officers.register.path(
            {
              configIdentifier,
            },
          )}
          role={
            roles.ferramentas.product
              .onboardingWorkflowsConfigsDeskOfficersConfigPage.role
          }
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildDeskConfigOfficerGrid(configIdentifier)}
      />
    </GridTemplate>
  );
};
