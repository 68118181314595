import * as yup from "yup";
import { FormValuesType } from "./add.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  companyEntityType: yup.string().required("Este campo é obrigatório"),
  identificacao: yup.string().required("Este campo é obrigatório"),
  officialName: yup.string().required("Este campo é obrigatório"),
  country: yup
    .string()
    .required("Este campo é obrigatório")
    .test(
      "country",
      "País não pode ser Brasil quando Tipo do documento for Offshore",
      (value, context) => {
        return context.parent.companyEntityType === "Offshore" && value === "BR"
          ? false
          : true;
      },
    )
    .test(
      "country",
      "Para Tipo do documento CPF ou CNPJ, País deve ser Brasil",
      (value, context) => {
        return context.parent.companyEntityType !== "Offshore" && value !== "BR"
          ? false
          : true;
      },
    ),
});
