import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { adminProfilesRequestsGrid } from "./admin-profiles-requests.grid";

const dataSource = dataSourceCustomStoreGenerator(() =>
  service.gatekeeper
    .getAdminProfileManagementRequests()
    .then(({ data }) => data.data)
    .catch(() => {
      throw new Error("Erro ao buscar solicitações");
    }),
);

export const AdminProfilesRequestsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Solicitações" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={adminProfilesRequestsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
