import {
  OBadge,
  OCol,
  OField,
  OIcon,
  OLoader,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { remapHookFormRegister } from "../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useClerkSystemsForm } from "./system-form.hook";
import { ClerkSystemsFormProps } from "./system-form.types";

export const ClerkSystemsForm = ({ mode }: ClerkSystemsFormProps) => {
  const {
    localForm,
    loading,
    watchStrategies,
    allStrategies,
    watchInput,
    setValue,
  } = useClerkSystemsForm();

  return (
    <>
      {loading && <OLoader absolute backdrop />}
      <ORow>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="name"
            name="name"
            dataAction="sistemas_clerk:input:nome"
            dataLabel="nome"
            label="Nome"
            disabled={mode === "update"}
          />
        </OCol>
      </ORow>
      <ORow align="start">
        <OCol xs={12} sm={6} lg={4}>
          <OField label="Estratégias" htmlFor="input">
            <SelectSearch
              id="input"
              name="input"
              placeholder="Selecionar"
              options={allStrategies}
              value={watchInput}
              className="w-100"
              maxOptions={50}
              innerProps={{
                input: {
                  ...remapHookFormRegister(localForm.register("input")),
                },
              }}
            />
          </OField>
        </OCol>

        {!!watchStrategies?.length && (
          <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-4">
            <OTypography tag="h3">Selecionados:</OTypography>

            <div className="d-flex flex-wrap gap-2">
              {watchStrategies.map((strategy) => {
                const findStrategy = allStrategies.find(
                  (all) => all.value === strategy,
                );

                if (!findStrategy?.label) return;

                return (
                  <OBadge key={strategy} type="dark" size="sm" rounded outline>
                    <div className="d-flex align-items-center gap-2">
                      {findStrategy.label}
                      <UnstyledButton
                        onClick={() => {
                          const filteredStrategies = watchStrategies.filter(
                            (s) => s !== strategy,
                          );

                          setValue("strategies", filteredStrategies);
                        }}
                      >
                        <OIcon category="fal" icon="fa-times" />
                      </UnstyledButton>
                    </div>
                  </OBadge>
                );
              })}
            </div>
          </OCol>
        )}
      </ORow>
    </>
  );
};
