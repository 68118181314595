import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useGlobalDeflator } from "./global-deflator.hook";

export const GlobalDeflator = () => {
  const { defaultDeflatorLoading, form, loading, submit } = useGlobalDeflator();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Deflator global"
          description="Esse deflator é aplicado a todas as empresas, caso não estejam listadas na página anterior."
        />
      }
      actions={
        <LoadingButton
          dataAction="deflator_global:botao:salvar"
          dataLabel="salvar"
          onClick={submit}
          loading={loading}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={4} align="center" className="gap-2">
              <ORFieldInput
                dataAction="deflator_global:numero:deflator"
                dataLabel="deflator"
                id="deflator"
                name="deflator"
                tag="mask"
                label="Deflator global"
                disabled={defaultDeflatorLoading || loading}
                maskOption={{ mask: Number }}
              />
              {defaultDeflatorLoading && (
                <OLoader className="d-block" size="sm" />
              )}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
