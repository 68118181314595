interface ClerkProductsProps {
  products: string[];
}

export const ClerkProducts = ({ products }: ClerkProductsProps) => {
  return (
    <>
      {products?.map((product) => (
        <li key={product}>{product}</li>
      ))}
    </>
  );
};
