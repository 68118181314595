import { OButton, OIcon, OToastManager, OTypography } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { service } from "services";
import { FileName, OCardStyled } from "./file-item.styles";
import { FileItemProps } from "./file-item.types";

export const FileItem = ({ filename, fileKey }: FileItemProps) => {
  const downloadContract = async () => {
    try {
      const { data } = await service.gateholder.getFileByUuid(fileKey);

      helpers.downloadBlobFile(filename, data);

      return true;
    } catch (e) {
      OToastManager.danger("Erro para fazer download do documento");
    }
  };

  return (
    <OCardStyled type="light">
      <div className="d-flex justify-content-between w-100">
        <FileName>
          <OIcon category="orq" icon="orq-file" size="xl" />
          <OTypography tag="span" size="md">
            {filename}
          </OTypography>
        </FileName>

        <OButton
          ariaLabel="download"
          dataAction="analise_antifraude:botao:download"
          dataLabel="download"
          bordered={false}
          outline
          type="dark"
          size="lg"
          onClick={downloadContract}
        >
          <OIcon category="orq" icon="orq-download" size="xl" />
        </OButton>
      </div>
    </OCardStyled>
  );
};
