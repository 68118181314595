import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { CambioBankFormRouteQueryParam } from "../../../../routes/cambio.route-params";

export const exchangeProductBeneficiaryBanksGrid = {
  datagrid: {
    noDataText: "Nenhum banco encontrado.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminBanksOData,
    ),
  },
  columns: [
    {
      dataField: "Id",
      caption: "Id",
    },
    {
      dataField: "Name",
      caption: "Nome",
    },
    {
      dataField: "Country",
      caption: "Código do país",
    },
    {
      dataField: "Swift",
      caption: "Swift",
    },
    {
      dataField: "FedWire",
      caption: "FedWire",
    },
    {
      dataField: "CreatedDate",
      caption: "Data de criação",
    },
    {
      dataField: "UpdatedDate",
      caption: "Última atualização",
    },
    {
      role: roles.cambio.product.contingencyBeneficiaryBanksFormPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          dataAction="cambio_bancos_de_beneficiarios:botao:editar"
          dataLabel="editar"
          href={`${
            corporateRouter.routes.cambio.product.contingency.beneficiaryBanks
              .form.path
          }?data=${encodeURIComponent(
            JSON.stringify(data as CambioBankFormRouteQueryParam),
          )}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubFx.odata.getBanks>
  >["data"]["value"][number]
>;
