import { validators } from "@maestro/utils";
import * as yup from "yup";
import { AttachUserForm } from "./attach-user.types";

export const attachUserFormValidationSchema: yup.ObjectSchema<AttachUserForm> =
  yup.object({
    taxId: yup
      .string()
      .required("Campo obrigatório")
      .test("taxId", "Documento inválido", validators.cpfCnpj),
    name: yup.string().required("Campo obrigatório"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    sendWelcomeEmail: yup.array().default([]),
    profiles: yup.array().default([]),
  });

export const checkboxValue = "send-welcome-email";

export const attachUserFormDefaultValues: AttachUserForm = {
  taxId: "",
  name: "",
  email: "",
  phone: "",
  sendWelcomeEmail: [],
  profiles: [],
};
