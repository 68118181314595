import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useDestinationStepSelector } from "./destination-step-selector.hook";

interface DestinationStepSelectorProps {
  id: string;
}

export const DestinationStepSelector = ({
  id,
}: DestinationStepSelectorProps) => {
  const { getSteps, hasFetchError, loading, steps } =
    useDestinationStepSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={steps}
        id={id}
        name="destinationStepId"
        label="Step destino"
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && <RefreshGridButton onClick={getSteps} />}
    </div>
  );
};
