import { AddressType } from "services/bankinghub/models/types/cards";
import * as yup from "yup";
import { ManageDeliveryAddressForm } from "./manage-delivery-address-form-modal.types";

const stringRequired = yup.string().required("Campo obrigatório");

const notRequired = yup.mixed().notRequired();

const validateWhenNotInformed = (
  fieldNotInformed: keyof ManageDeliveryAddressForm,
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as ManageDeliveryAddressForm)[fieldNotInformed] === "no"
      ? schema
      : notRequired,
  );

const validateZipCode = (value: string | undefined | null) => {
  if (!value) return false;
  const zipCodeNumbers = value.replace(/[^0-9]/gi, "");
  return zipCodeNumbers.length === 8;
};

export const manageDeliveryAddressFormValidationSchema = yup.object<
  ManageDeliveryAddressForm,
  yup.ObjectSchema<ManageDeliveryAddressForm>["fields"]
>({
  zipCode: yup
    .string()
    .required("Campo obrigatório")
    .test("zipCode", "CEP inválido", validateZipCode),
  street: stringRequired,
  number: validateWhenNotInformed("numberNotInformed", stringRequired),
  numberNotInformed: yup
    .string()
    .equals(["yes", "no"])
    .required("Campo obrigatório"),
  complement: validateWhenNotInformed("complementNotInformed", stringRequired),
  complementNotInformed: yup
    .string()
    .equals(["yes", "no"])
    .required("Campo obrigatório"),
  neighborhood: stringRequired,
  city: stringRequired,
  stateId: stringRequired,
  type: yup.mixed<AddressType>().required("Campo obrigatório"),
});
