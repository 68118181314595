// Helper to clean up type
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

export type CapitalizeObjectKeys<T> = {
  [key in keyof T as Capitalize<key & string>]: T[key];
};

export type UncapitalizeObjectKeys<T> = {
  [key in keyof T as Uncapitalize<key & string>]: T[key];
};

export const capitalizeObjectKeys = <T extends object>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key[0].toUpperCase() + key.slice(1),
      value,
    ]),
  ) as Expand<CapitalizeObjectKeys<T>>;

export const uncapitalizeObjectKeys = <T extends object>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key[0].toLowerCase() + key.slice(1),
      value,
    ]),
  ) as Expand<UncapitalizeObjectKeys<T>>;
