import { datadogRum } from "@datadog/browser-rum";

const inputTags = [
  "input",
  "o-select",
  "o-checkbox-group",
  "o-radio-group",
  "o-input-upload",
];

const clickTags = ["button", "a"];

const trackedEvents = {
  click: clickTags,
  change: inputTags,
  focusin: inputTags,
  focusout: inputTags,
};

export const trackDOMEvents = () => {
  Object.entries(trackedEvents).forEach(([eventType, trackedTags]) => {
    window.addEventListener(eventType, (evt) => {
      const target = evt.target as HTMLElement | null;
      const closestTagged = target?.closest<HTMLElement>("[data-action]");
      const isTrackedElement = trackedTags.some((tag) =>
        closestTagged?.nodeName.match(new RegExp(tag, "i")),
      );

      if (!isTrackedElement || !closestTagged) return;

      const dataAction = closestTagged.dataset.action;
      const dataLabel = closestTagged.dataset.label;

      if (!dataAction) return;

      const [actionSection, actionType, actionLabel] = dataAction.split(":");

      datadogRum.addAction(`tracking:${eventType}`, {
        action: dataAction,
        label: dataLabel,
        details: {
          event: eventType,
          section: actionSection,
          type: actionType,
          label: actionLabel,
        },
      });
    });
  });
};
