import { OToastManager } from "@maestro/core";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { Job } from "./jobs.types";

const jobStateBackgroundMap = {
  available: "var(--theme-warning-light)",
  scheduled: "var(--theme-warning-light)",
  executing: "var(--theme-info-light)",
  retryable: "var(--theme-danger-light)",
  completed: "var(--theme-success-light)",
};

const jobStateColorMap = {
  available: "var(--theme-warning-light-inverse)",
  scheduled: "var(--theme-warning-light-inverse)",
  executing: "var(--theme-info-light-inverse)",
  retryable: "var(--theme-danger-light-inverse)",
  completed: "var(--theme-success-light-inverse)",
};

export const jobStateMap = {
  available: "Aguardando",
  scheduled: "Agendado",
  discarded: "Cancelado",
  executing: "Executando",
  retryable: "Falha",
  completed: "Processado",
};

export const styleStateCells = (e: CellPreparedEvent<Job>) => {
  if (!e?.data?.state || e.column.name !== "state") return;
  e.cellElement.style.backgroundColor =
    getValueFromMap(jobStateBackgroundMap, e.data.state) || "";
  e.cellElement.style.color =
    getValueFromMap(jobStateColorMap, e.data.state) || "";
};

export const runJobs = async (ids: number[]) => {
  const successMessage =
    ids.length > 1 ? "Jobs redisparados" : "Job redisparado";
  const failureMessage = "Erro ao redisparar";

  return service.recebiveisCartao
    .runJobs({ job_ids: ids })
    .then(() => OToastManager.success(successMessage))
    .catch(() => OToastManager.danger(failureMessage));
};

export const stopJobs = async (ids: number[]) => {
  const successMessage = ids.length > 1 ? "Jobs parados" : "Job parado";
  const failureMessage =
    ids.length > 1 ? "Erro ao parar os jobs" : "Erro ao parar o job";

  return service.recebiveisCartao
    .stopJobs({ job_ids: ids })
    .then(() => OToastManager.success(successMessage))
    .catch(() => OToastManager.danger(failureMessage));
};
