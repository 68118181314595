import { ODataGridGeneratorConfig } from "components/data-grid";
import { DeviceToken } from "services/clerk/models/types";

export const tokensGrid: ODataGridGeneratorConfig<DeviceToken> = {
  datagrid: {
    noDataText: "Nenhum token encontrado.",
  },
  columns: [
    {
      dataField: "appName",
      dataType: "string",
      caption: "Nome do aplicativo",
    },
    {
      dataField: "token",
      dataType: "string",
      caption: "Token",
    },
    {
      dataField: "insertedAt",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
      caption: "Data de criação",
    },
    {
      dataField: "updatedAt",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
      caption: "Última atualização",
    },
  ],
};
