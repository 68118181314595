import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { conciliacaoDetailsModalDefaultValues } from "./conciliacao-details-modal.form";
import { useConciliacaoDetailsContext } from "../extrato-liquidacao-posicao-details.context";

export const useConciliacaoDetailsModal = () => {
  const { setMaturityFilterDate } = useConciliacaoDetailsContext();

  const form = useForm({
    defaultValues: conciliacaoDetailsModalDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(
    () => reset(conciliacaoDetailsModalDefaultValues),
    [reset],
  );

  const submit = useMemo(
    () =>
      handleSubmit((values) => {
        setMaturityFilterDate(
          dayjs(values.dataVencimento).format("YYYY-MM-DD"),
        );
      }),
    [handleSubmit, setMaturityFilterDate],
  );

  return { form, clear, submit };
};
