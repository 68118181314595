/* eslint-disable react/no-array-index-key */
import {
  OCollapse,
  OCollapseContent,
  OCollapseHeader,
  OTypography,
} from "@maestro/react";
import { useCallback, useMemo } from "react";
import { DetailItemComponent } from "./_compose/detail-item";
import { InformationSectionProps } from "./information-section.types";
import { MapperSectionIdToTitle } from "./information-section.utils";

export const InformationSection = ({
  detailItems,
  sectionId,
  sectionsExpanded,
  updateSectionsExpanded,
}: InformationSectionProps) => {
  const handleExpand = useCallback(
    () => updateSectionsExpanded(sectionId),
    [sectionId, updateSectionsExpanded],
  );

  const isExpanded = useMemo(
    () => sectionsExpanded.includes(sectionId),
    [sectionId, sectionsExpanded],
  );

  return (
    <OCollapse id={sectionId} expanded={isExpanded}>
      <OCollapseHeader onClick={handleExpand}>
        <OTypography type="dark">
          {MapperSectionIdToTitle[sectionId]}
        </OTypography>
      </OCollapseHeader>

      <OCollapseContent>
        <div className="d-flex flex-column gap-3 pb-3">
          {detailItems.map((line, lineIndex) => (
            <div
              className="d-flex align-items-center gap-3"
              key={`${sectionId}_${lineIndex}`}
            >
              {line.map((item) => (
                <DetailItemComponent
                  {...item}
                  key={`${sectionId}_${lineIndex}-${item.content}`}
                />
              ))}
            </div>
          ))}
        </div>
      </OCollapseContent>
    </OCollapse>
  );
};
