import { AxiosError } from "axios";
import { ILimitControlError } from "services/limit-control/models/responses";

export const buildFilter = (filter: Array<string>) => {
  return filter && filter[0] == "id"
    ? { search: filter[2].replaceAll(/[\.\,\-\/]/g, "") }
    : {};
};

export const getErrorCode = (error: unknown) => {
  const axiosError = error as AxiosError;
  if(axiosError)
  {
    const limitControlError = axiosError.response?.data as ILimitControlError;
    return limitControlError.code;
  }
};
