import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GetApiAdminCollateralByIdResponse } from "services/hubcreditmanager/models/responses";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { collateralOptions, formTypeMapper } from "./add-collateral-form.utils";
import { useCollateralForm } from "./add-collateral.hook";

interface AddCollateralPageProps {
  collateral?: GetApiAdminCollateralByIdResponse;
  loadingCollateral?: boolean;
  isUpdating?: boolean;
}

export const AddCollateralPage = ({
  loadingCollateral = false,
  isUpdating = false,
  collateral,
}: AddCollateralPageProps) => {
  const {
    loading,
    form,
    collateralType,
    addCollateral,
    handleSubmit,
    updateCollateral,
  } = useCollateralForm(collateral);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={isUpdating ? "Atualizar garantia" : "Adicionar garantia"}
        />
      }
      actions={
        isUpdating ? (
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(updateCollateral)}
          >
            Atualizar
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(addCollateral)}
          >
            Adicionar
          </LoadingButton>
        )
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loading || loadingCollateral}
          noValue
          render={() => (
            <FormProvider {...form}>
              <form>
                <ORow>
                  <OCol sm={12} md={6}>
                    <ORFieldSelect
                      id="collateralType"
                      name="collateralType"
                      dataAction="emprestimos_garantia_form:select:tipo_garantia"
                      dataLabel="tipo_garantia"
                      label="Tipo da garantia"
                      readonly={!!collateral}
                    >
                      {collateralOptions.map((option) => (
                        <OOption value={option.value} key={option.value}>
                          {option.label}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                </ORow>
                {getValueFromMap(formTypeMapper, collateralType)}
              </form>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
