import { OSkeletonAvatar } from "@maestro/react";
import { BtgIconAvatarProps, BtgIconAvatarType } from "./btg-icon-avatar.types";
import BTGBankingIcon from "./icons/btg-banking-icon.svg";
import BTGBusinessIcon from "./icons/btg-business-icon.svg";
import BTGInvestmentIcon from "./icons/btg-investment-icon.svg";

export const BtgIconAvatar = ({
  type,
  size = "md",
  isLoading = false,
}: BtgIconAvatarProps) => {
  if (isLoading) return <OSkeletonAvatar size={size} />;

  const MapperBtgIconAvatarTypeSVG: Record<
    BtgIconAvatarType,
    React.ElementType<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >
  > = {
    [BtgIconAvatarType.Investment]: BTGInvestmentIcon,
    [BtgIconAvatarType.Business]: BTGBusinessIcon,
    [BtgIconAvatarType.Banking]: BTGBankingIcon,
  };

  const Content = MapperBtgIconAvatarTypeSVG[type];

  return <Content width="48px" height="48px" />;
};
