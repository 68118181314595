import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

type WorkflowProcessor = Awaited<
  ReturnType<typeof service.hubCreditManager.getProcessor>
>["data"][number];

export const workflowProcessorsGrid = {
  datagrid: {
    noDataText: "Nenhum processador encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getProcessor().catch(() => {
        throw new Error("Não foi possível buscar os processadores.");
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      role: roles.workflow.product.processorsEditPage.role,
      cellRender: ({ data }) =>
        data.id ? (
          <DetailsLink
            href={corporateRouter.routes.workflow.product.processors.edit.path({
              id: data.id,
            })}
          />
        ) : undefined,
    },
    {
      dataField: "id",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "canBeWfEntry",
      caption: "Pode ser entrada",
    },
    {
      dataField: "hasUserAction",
      caption: "Possui ação de usuário",
    },
    {
      dataField: "eventName",
      caption: "Nome do evento",
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowProcessor>;
