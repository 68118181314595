import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const AllowAggregateBorderosField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.allowAggregateBorderos}
      name="allowAggregateBorderos"
      description="Quando habilitado, permite a criação de operações com recebíveis de diferentes lotes (borderos)"
    />
  );
};
