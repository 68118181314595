import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataAgendamentoViewItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./schedules.grid";

const dataSource = dataSourceODataStoreGenerator<GetOdataAgendamentoViewItem>(
  endpoints.quickfin.odata.agendamentoView,
  {
    odataStoreOptions: {
      beforeSend(options) {
        if (options.method.toLowerCase() === "patch") {
          // eslint-disable-next-line no-param-reassign
          options.url = endpoints.quickfin.superAdmin.agendamento;
        }
      },
      onUpdating(row, newValues) {
        // eslint-disable-next-line no-param-reassign
        newValues.agendamentoId = row.Id;
      },
    },
  },
);

export const Schedules = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Agendamentos"
          description="Visualize todos os títulos recebíveis e suas informações principais"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
