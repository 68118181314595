import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";

export const fullYieldStatementGrid: ODataGridGeneratorConfig<{
  label: string;
  amount: number;
}> = {
  datagrid: {
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "label",
      caption: "Campo",
      allowHeaderFiltering: true,
    },
    {
      dataField: "amount",
      caption: "Valor",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
  ],
};
