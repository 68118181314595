import { OInputDdiPhone, OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

type InputDdiPhoneProps = Omit<
  React.ComponentProps<typeof OInputDdiPhone>,
  "countries" | "loading"
> & {
  showErrorToast?: boolean;
};

export const InputDdiPhone = ({
  showErrorToast = true,
  ...props
}: InputDdiPhoneProps) => {
  const {
    loading: countriesLoading,
    callService: getActiveDdis,
    value: countries,
    hasError,
  } = useServiceCall(service.clerk.getDdis);

  useEffect(() => {
    getActiveDdis();
  }, [getActiveDdis]);

  useEffect(() => {
    if (hasError && showErrorToast) {
      OToastManager.warning("Falha ao buscar países.");
    }
  }, [hasError, showErrorToast]);

  return (
    <OInputDdiPhone
      {...props}
      countries={countries || []}
      loading={countriesLoading}
    />
  );
};
