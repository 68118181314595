import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ACCOperacoesByIdRouteParams } from "../../../../routes/acc.route-params";
import {
  OProgressBarStyled,
  ProgressContent,
  StyledText,
} from "./admin-customer-acc-proposals-details.styles";
import { adminCustomerAccProposalsDetailsGenerator } from "./admin-customer-acc-proposals.details";

export const AdminCustomerACCProposalsDetailsPage = () => {
  const { id } = useParams<ACCOperacoesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: quoteDetails,
    callService: postAccSimulate,
    hasError: hasErrorQuoteDetails,
    loading: loadingQuoteDetails,
  } = useServiceCall(service.acc.adminCustomer.postAccSimulate);

  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (!!prevTimer ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) setTimer(60);
  }, [timer]);

  useEffect(() => {
    postAccSimulate(id);
  }, [postAccSimulate, id]);

  useEffect(() => {
    if (!id) return;

    if (!timer) postAccSimulate(id);
  }, [timer, postAccSimulate, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Simular proposta" />}>
      <ContentTemplate
        loading={loadingQuoteDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da operação."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => postAccSimulate(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorQuoteDetails}
        value={quoteDetails}
        render={(value) => (
          <div className="d-flex flex-column gap-2">
            <ProgressContent>
              <StyledText>
                {value.response.accProposal.currency} comercial:{" "}
                {masks.currency(
                  value.response.quoteSimulation?.exchangeRatio,
                  "R$",
                  ".",
                  ",",
                  4,
                )}
              </StyledText>

              <div className="d-flex justify-content-start align-items-center gap-2 w-100">
                <OTypography size="sm">Atualização em {timer}</OTypography>
                <OProgressBarStyled value={timer} max={60} className="w-20" />
              </div>
            </ProgressContent>
            <DetailsCard
              value={value.response}
              fields={adminCustomerAccProposalsDetailsGenerator}
            />
          </div>
        )}
      />
    </DetailsTemplate>
  );
};
