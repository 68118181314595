import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { currencySymbol } from "utils/currency";

type WorkflowLeads = NonNullable<
  Awaited<
    ReturnType<typeof service.hubCreditManager.getWorkflowLeadsDetails>
  >["data"]["freeEnergyMarketMigration"]
>["units"][number];

export const workflowConsumerUnitsGrid = (leadId: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma unidade encontrada",
      headerFilter: { visible: true },
    },
    columns: [
      {
        type: "buttons",
        role: roles.workflow.product.leadsDetailsConsumerUnitsDetailsPage.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.workflow.product.leads.details.consumerUnits.details.path(
              {
                id: leadId,
                unitId: data.id,
              },
            )}
          />
        ),
      },
      {
        dataField: "identification",
        caption: "Identificação",
      },
      {
        dataField: "legalEntity.name",
        caption: "Razão social",
      },
      {
        dataField: "legalEntity.taxId",
        caption: "CNPJ",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        dataField: "amount",
        caption: "Valor da conta",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "modality",
        caption: "Modalidade",
      },
      {
        dataField: "fareGroup",
        caption: "Grupo tarifário",
      },
    ],
  } satisfies ODataGridGeneratorConfig<WorkflowLeads>);
