import moment from "moment";
import { MoneyMask } from "../../utils";
import {
  Currency,
  ItemCategory,
  ItemType,
  MapperCardType,
} from "../cards.types";
import { ITransactionDetailsData } from "../compose/transaction-details/transaction-details.types";
import {
  AuthorizationType,
  IInvoiceTransactionItem,
} from "../invoices/details/transactions/transactions.types";
import { capitalizeFirstLetter, capitalizePhraseFirstLetters } from "./cases";

export const convertBase64Download = (
  filename: string,
  base64: string,
  contentType: string,
) => {
  const a = document.createElement("a");

  const convertBase64ToIntArray = (base: string) => {
    const bytes = atob(base);
    const bytesArray = new Array(bytes.length);
    for (let i = 0; i < bytes.length; i += 1) {
      bytesArray[i] = bytes.charCodeAt(i);
    }
    return new Uint8Array(bytesArray);
  };

  const intArray = convertBase64ToIntArray(base64);

  const url = URL.createObjectURL(new Blob([intArray], { type: contentType }));
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};

export const formContestationDescriptionFromInvoiceItem = (
  item: IInvoiceTransactionItem,
) => {
  return capitalizePhraseFirstLetters(
    `Transação Base 2 | ${item.authorization.description} - ${item.transactionType.description}: ${item.merchant.name}`,
  );
};

export const isChargebackTransactionFromInvoiceItem = (
  isCredit: boolean,
  authorizationType: AuthorizationType,
): boolean => {
  return !isCredit && authorizationType !== AuthorizationType.Platform;
};

export const formTransactionDetailsFromInvoiceItem = (
  item: IInvoiceTransactionItem,
): ITransactionDetailsData => {
  return {
    authorizationId: item.authorization?.id.toString(),
    date: moment(`${item.eventDate}Z`).format("DD/MM/YYYY"),
    hour: moment(`${item.eventDate}Z`).format("HH:mm:ss"),
    amount: MoneyMask(item.amount[0]?.value, Currency.BRL),
    cardType: MapperCardType[item.card?.type],
    cardLast4Digits: item.card?.last4Digits,
    via: capitalizeFirstLetter(item.authorization?.description),
    category: item.merchant?.category?.networkGroup,
    isCredit: item.transactionType?.isCredit,
    type: item.isInternational ? "Compra internacional" : "Compra nacional",
    merchantName: item.merchant?.name,
    description: capitalizeFirstLetter(item.softDescriptor),
    itemType: ItemType.Confirmation,
    itemCategory: ItemCategory.Authorize,
    itemOrigin: "Invoice",
    dcc: item.dcc ? "Sim" : "Não",
    localCurrencyAmount: MoneyMask(
      item.amount[1]?.value,
      item.amount[1]?.currency,
    ),
    settlementCurrencyAmount: MoneyMask(
      item.amount[2]?.value,
      item.amount[2]?.currency,
    ),
    settlementCurrencyRate:
      (item.amount[2]?.rate &&
        MoneyMask(Math.pow(item.amount[2].rate, -1), Currency.BRL)) ||
      undefined,
    iofValue: "Incluso na fatura",
  };
};
