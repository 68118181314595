import type { DataGrid } from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useRef } from "react";
import { ODataGridStyled } from "./o-data-grid.styles";
import { ODataGridProps } from "./o-data-grid.types";
import { setExternalRef, updateDimensions } from "./o-data-grid.utils";

export const ODataGrid: React.FC<ODataGridProps> = React.forwardRef(
  function ODataGrid({ loading, ...props }, externalRef) {
    const internalRef = useRef<DataGrid | null>(null);

    /**
     * Starts or ends data-grid custom loading based on prop
     * 1. Called in the `contentReady` event to guarantee
     * that `beginCustomLoading` is called after the grid is ready
     * 2. Called in the `useEffect` to handle rerenders
     * (grid already rendered, so `contentReady` doesn't fire)
     */
    const controlLoading = useCallback(
      (grid: DataGrid["instance"]) => {
        if (loading) {
          grid.beginCustomLoading("");
          grid.element().classList.add("custom-loading");
        } else {
          grid.endCustomLoading();
          grid.element().classList.remove("custom-loading");
        }
      },
      [loading],
    );

    useEffect(() => {
      if (internalRef.current?.instance)
        controlLoading(internalRef.current?.instance);
    }, [controlLoading, loading]);

    return (
      <ODataGridStyled
        {...props}
        ref={(grid) => {
          internalRef.current = grid;
          setExternalRef(externalRef, grid);
        }}
        noDataText={loading ? "" : props.noDataText}
        onContentReady={(e) => {
          controlLoading(e.component);
          updateDimensions(e.component, 0, 10, 500);
          props.onContentReady?.(e);
        }}
      />
    );
  },
);
