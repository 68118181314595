import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { ExtraDataFormFields } from "../../../extra-data/extra-data.types";

export const useAddressFields = (index: number) => {
  const [hasFoundAddress, setHasFoundAddress] = useState(true);

  const { callService, loading: loadingAddress } = useServiceCall(
    service.onboarding.getAddress,
  );

  const form = useFormContext<ExtraDataFormFields>();
  const { setValue, watch } = form;

  const zipCodeWatch = watch(`addresses.${index}.zipCode`);

  const getAddressFromZipCode = useCallback(async () => {
    if (!(zipCodeWatch && zipCodeWatch.length === 8)) {
      setValue(`addresses.${index}.cityName`, "");
      setValue(`addresses.${index}.countryCode`, "");
      setValue(`addresses.${index}.neighborhood`, "");
      setValue(`addresses.${index}.stateCode`, "");
      setValue(`addresses.${index}.street`, "");
      return;
    }
    const { success, response } = await callService({ zipCode: zipCodeWatch });

    if (success) {
      const newAddress: ScfDevelopers.Address = {
        cityName: response.data.cityName.toUpperCase(),
        complement: "",
        countryCode: "BR",
        neighborhood: response.data.neighborhood.toUpperCase(),
        number: "",
        stateCode: response.data.stateCode.toUpperCase(),
        street: response.data.streetName.toUpperCase(),
        zipCode: zipCodeWatch,
      };

      setValue(`addresses.${index}`, newAddress);
    } else {
      setHasFoundAddress(false);
      OToastManager.danger("Erro ao buscar o CEP");
    }
  }, [callService, index, setValue, zipCodeWatch]);

  useEffect(() => {
    getAddressFromZipCode();
  }, [getAddressFromZipCode]);

  return {
    hasFoundAddress,
    loadingAddress,
  };
};
