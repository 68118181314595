import { PostApiDesksGetEntityDesksResponse } from "services/clerk";
import { InitialStep } from "../initial-step";
import { ExtraData } from "../extra-data";

export const stepManager = (
  configurations: ScfDevelopers.PartnerConfiguration[] | undefined,
  currentStep: string,
  loadingDesks: boolean,
  possibleDesks: PostApiDesksGetEntityDesksResponse | undefined,
  requiredDocuments: ScfDevelopers.PayerDocumentType[],
  requiredFields: string[],
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (currentStep === "INITIAL_STEP") {
    return (
      <InitialStep
        configurations={configurations}
        loadingDesks={loadingDesks}
        possibleDesks={possibleDesks}
        setCurrentStep={setCurrentStep}
      />
    );
  }
  if (currentStep === "EXTRA_DATA") {
    return (
      <ExtraData
        requiredDocuments={requiredDocuments}
        requiredFields={requiredFields}
        setCurrentStep={setCurrentStep}
      />
    );
  }
  return <>Step não determinado</>;
};
