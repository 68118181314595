import * as yup from "yup";
import { VigencyForm } from "./vigency-select-modal.types";

export const vigencyValidationSchema = yup.object<
  VigencyForm,
  yup.ObjectSchema<VigencyForm>["fields"]
>({
  vigencyDate: yup
    .string()
    .transform((date) => {
      return date && new Date(date).toISOString();
    })
    .required("Campo obrigatório"),
});
