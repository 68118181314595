import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { Behaviors, behaviorSearchKey } from "contexts/behavior/types";
import { service } from "services";
import { logger } from "utils/logger";

export async function generateCustomerServiceTokenAndRedirect(cpf: string) {
  try {
    const { data } = await service.gatekeeper.generateSalesforceToken({
      customer_user_cpf: cpf,
    });

    window.open(
      `/?${behaviorSearchKey}=${Behaviors.CustomerService}&authorization=${data.token}`,
      "_blank", // <- This is what makes it open in a new window.
    );
  } catch (err) {
    if (!isAxiosError(err)) logger.error(err);
    OToastManager.info("Não foi possível atender o usuário");
  }
}
