import { datadogRum } from "@datadog/browser-rum";
import { xhrInterceptor } from "../../xhr-interceptor";

const trackedDomains = [
  /https:\/\/.*\.btgpactual\.com/,
  /https:\/\/.*\.empresas\.btgpactual\.com/,
  /https:\/\/.*\.btgpactualbusiness\.com/,
  /https:\/\/.*\.quickfin\.com\.br/,
];

const trackedMethods = ["POST", "PUT"];

export const trackRequests = () => {
  xhrInterceptor.listen("afterSend", (request) => {
    const isTrackedDomain = trackedDomains.some((domain) =>
      request.url.match(domain),
    );
    const isTrackedMethod = trackedMethods.some(
      (method) => request.method === method,
    );

    if (isTrackedDomain && isTrackedMethod) {
      datadogRum.addAction("tracking:request", {
        url: request.url,
        method: request.method,
        status: request.status,
      });
    }
  });
};
