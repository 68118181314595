import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { AxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  PatchCalendarBody,
  PostCalendarResponse,
} from "services/legacys/models";
import { service } from "services/service";
import { RecebiveisProductCalendarsCalendarByIdRouteParams } from "../../../../routes/recebiveis.route-params";
import {
  calendarDefaultValues,
  calendarSchema,
} from "../_compose/calendar-form/calendar.form";

export const useCalendarDetails = () => {
  const { calendarId } =
    useParams<RecebiveisProductCalendarsCalendarByIdRouteParams>();
  const {
    loading,
    value: calendarDetails,
    callService: getCalendarDetails,
  } = useServiceCall(service.legacys.getCustomCalendarById);
  const { loading: updatingCalendar, callService: updateCustomCalendar } =
    useServiceCall(service.legacys.updateCustomCalendar);
  const form = useForm({
    resolver: yupResolver(calendarSchema),
    defaultValues: calendarDefaultValues,
  });
  const { setValue, handleSubmit } = form;
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (values) => {
    if (!calendarDetails) return;

    const hasInputedOffDay = values.offDays.some((od) => !od.isBase);

    if (!hasInputedOffDay) {
      OToastManager.warning(
        "Inclua ao menos um feriado adicional para salvar o calendário personalizado.",
      );
      return;
    }

    const body: PatchCalendarBody = {
      id: calendarDetails.id,
      name: values.name,
      baseCalendarId: values.baseCalendarId!,
      offDays: values.offDays.map((offDay) => ({
        name: offDay.name,
        date: new Date(offDay.date),
      })),
    };

    const { success, error } = await updateCustomCalendar(body);

    if (!success) {
      if (error) {
        const { response } = error as AxiosError<PostCalendarResponse>;

        if (response && response.status === 400) {
          const { validationErrors = [] } =
            response.data as PostCalendarResponse;
          if (validationErrors.length) {
            OToastManager.danger(
              validationErrors.map((errors) => errors.message).join(" "),
            );
          }
          return;
        }
      }
      return OToastManager.danger(
        "Não foi possível atualizar o calendário, tente novamente mais tarde!",
      );
    }

    OToastManager.success("O calendário foi atualizado com sucesso.");
    navigate(corporateRouter.routes.recebiveis.product.calendars.path);
  });

  const loadDetails = useCallback(async () => {
    if (calendarId) {
      const { response, success } = await getCalendarDetails(+calendarId);
      if (!success)
        return OToastManager.danger(
          "Não foi buscar detalhes do calendário, tente novamente mais tarde!",
        );

      if (response) {
        const { data } = response;
        setValue("baseCalendarId", data.baseCalendarId);
        setValue("name", data.name);
        setValue(
          "offDays",
          data.offDays.map((od) => ({
            ...od,
            date: new Date(od.date),
          })),
        );
      }
    }
  }, [calendarId, getCalendarDetails, setValue]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  return {
    form,
    calendarDetails,
    loading,
    updatingCalendar,
    onSubmit,
  };
};
