import { OToastManager } from "@maestro/core";
import { OButton } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { GetApiAdminTransactionByIdGetAllByLimitResponse } from "services/hubcreditmanager/models/responses/get-api-admin-transaction-by-id-get-all-by-limit.response";

type Transaction =
  GetApiAdminTransactionByIdGetAllByLimitResponse["limitTransactions"][number];

export const transactionsGrid = {
  datagrid: {
    noDataText: "Nenhuma transação encontrada",
  },
  columns: [
    {
      dataField: "createdDate",
      caption: "Data",
      dataType: "datetime",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      dataField: "amount",
      dataType: "number",
      caption: "Valor",
      cellRender: ({ data }) =>
        data.amount.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: data.currency ?? "BRL",
        }),
    },
    {
      dataField: "currencyPrice",
      dataType: "number",
      caption: "Preço da moeda",
      cellRender: ({ data }) =>
        data.currencyPrice?.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      dataType: "number",
      caption: "Valor em R$",
      cellRender: ({ data }) =>
        (data.currency !== "BRL" && data.currencyPrice
          ? data.currencyPrice * data.amount
          : data.amount
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      dataField: "status",
      caption: "Status",
    },
    {
      dataField: "externaId",
      caption: "External Id",
    },
    {
      dataField: "originator",
      caption: "Origem",
    },
    {
      dataField: "reason",
      caption: "Motivo",
    },
    {
      allowExporting: false,
      cellRender: ({ data, component }) => {
        return (
          data.status === "DEBITADO" && (
            <OButton
              dataAction="transacos_emprestimos:botao:estornar"
              dataLabel="estornar"
              outline
              onClick={async () => {
                try {
                  OToastManager.info("Enviando arquivo...");

                  await service.hubCreditManager.reverseTransaction({
                    transactionId: data.id,
                    product: data.creditLineProduct,
                    identification: data.creditLineIdentification,
                    originator: "PORTAL_ADMIN",
                    transactionExternalId: data.externaId,
                  });

                  OToastManager.destroy();
                  OToastManager.success("Estorno solicitado com sucesso");
                } catch (err) {
                  OToastManager.danger(
                    "Falha ao estornar a transação de débito. Tente novamente.",
                  );
                } finally {
                  component.refresh();
                }
              }}
            >
              Estornar
            </OButton>
          )
        );
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Transaction & { creditLineProduct: string; creditLineIdentification: string }
>;
