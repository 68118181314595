import { ODropdownItem, OIcon, OTabDropdown, OTabHeader } from "@maestro/react";
import { useLayoutEffect, useMemo, useState } from "react";
import { MenuTabs } from "../../tab-menu.types";
import { TabItem } from "../tab-item";
import {
  calculateVisibleTabs,
  estimateTabWidth,
  generateTabsKey,
} from "./render-tabs-with-dropdown.utils";
import { useWindowSize } from "./use-window-size.hook";

interface RenderTabsWithDropdownProps {
  activeTab: number;
  tabs: MenuTabs[keyof MenuTabs];
}

export const RenderTabsWithDropdown = ({
  activeTab,
  tabs,
}: RenderTabsWithDropdownProps) => {
  const [numberVisibleTabs, setNumberVisibleTabs] = useState(tabs.length);

  const { width: screenWidth } = useWindowSize();

  const showTabDropdown = numberVisibleTabs < tabs.length;

  useLayoutEffect(() => {
    // Estimate lengths of tabs
    const tabWidths = tabs.map((tab) => estimateTabWidth(tab.name));
    // calculate the number of tabs that fit in the screen
    const num = calculateVisibleTabs(screenWidth, tabWidths, showTabDropdown);
    if (num) setNumberVisibleTabs(num);
  }, [screenWidth, showTabDropdown, tabs]);

  const tabHeader = useMemo(
    () => (
      <OTabHeader
        activeTab={activeTab}
        key={generateTabsKey(tabs) + numberVisibleTabs}
      >
        {tabs.slice(0, numberVisibleTabs).map((tab) => (
          <TabItem key={tab.path} tab={tab} />
        ))}

        {showTabDropdown && (
          <OTabDropdown
            dataAction="tab_menu:tab:tab_dropdown"
            dataLabel="tab_dropdown"
          >
            <OIcon category="orq" icon="orq-more-options" size="xl" />

            <div slot="list">
              {tabs.slice(numberVisibleTabs).map((tab) => (
                <ODropdownItem key={tab.path}>
                  <TabItem tab={tab} />
                </ODropdownItem>
              ))}
            </div>
          </OTabDropdown>
        )}
      </OTabHeader>
    ),
    [activeTab, numberVisibleTabs, showTabDropdown, tabs],
  );

  return tabHeader;
};
