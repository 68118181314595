import { OToastManager } from "@maestro/core";
import { useCallback } from "react";
import { ResizeDragEvent, ResizeParams } from "reactflow";
import { service } from "services";
import { getNodeIdNumber } from "../../../hooks/use-canvas/use-canvas.utils";

export const useGroupNode = (id: string) => {
  const handleResize = useCallback(
    async (_: ResizeDragEvent, params: ResizeParams) => {
      try {
        const { height, width, x, y } = params;
        const itemId = getNodeIdNumber(id, "GROUP");

        if (!itemId) return;

        await service.hubCreditManager.updateCanvasItem({
          id: itemId,
          canvasData: { positionX: x, positionY: y },
          height,
          width,
        });
      } catch (err) {
        OToastManager.danger(
          "Um problema ocorreu ao tentar atualizar o tamanho do grupo.",
        );
      }
    },
    [id],
  );

  return { handleResize };
};
