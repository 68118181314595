import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { ExchangeQuoteStatus } from "services/hubfx/models/types/quote/quote.types";
import { buildCustomerLink } from "utils/build-customer-link";
import { currencySymbol } from "utils/currency";
import { mapQuoteStatus, parseQuoteFlowType } from "../../utils/quote.utils";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getOperations>
    >["data"]["value"]
  >[number]
>;

export const exchangeOdataQuotesGrid = (
  mapSymbol: (currency?: string | null) => string,
  filterValue?: Grid["datagrid"]["filterValue"],
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma operação encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar operações.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubFx.channelOdata.channelOperationsOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
        },
      ),
    },
    columns: [
      {
        allowExporting: false,
        type: "buttons",
        role: roles.cambio.product.quotesDetailsPage.role,
        cellRender: ({ data }) =>
          data.id && (
            <DetailsLink
              href={buildCustomerLink(
                `${data.counterpartyIdentification}` ?? "",
                corporateRouter.routes.cambio.customer.quotes.details.path({
                  id: data.id,
                }),
              )}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        caption: "Data de criação",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        dataField: "createdDate",
      },
      {
        caption: "Beneficiário/Pagador",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.externalPartyName,
      },
      {
        dataField: "counterpartyName",
        caption: "Nome da empresa",
      },
      {
        dataField: "counterpartyIdentification",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
      },
      {
        caption: "Tipo",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.type,
      },
      {
        caption: "Enquadramento",
        dataField: "natureDescription",
      },
      {
        caption: "Moeda estrangeira",
        dataField: "buyCurrencyType",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.currency,
      },
      {
        caption: "Status",
        dataField: "status",
        format: (value) => mapQuoteStatus(value),
        headerFilter: {
          dataSource: (options) => {
            if (options?.dataSource)
              // eslint-disable-next-line no-param-reassign
              options.dataSource.postProcess = (
                results: {
                  text: ExchangeQuoteStatus;
                  value: unknown;
                }[],
              ) =>
                results.map((item) => ({
                  text: mapQuoteStatus(item.text),
                  value: item.value,
                }));
          },
        },
      },
      {
        caption: "Cotação",
        dataField: "exchangeRatio",
        format: (value) => value?.toString().replace(".", ","),
      },
      {
        caption: "Valor moeda estrangeira",
        cellRender: ({ data }) =>
          masks.currency(
            data.amount,
            mapSymbol(
              parseQuoteFlowType({
                buyCurrencyType: data.buyCurrencyType,
                flowType: data.flowType,
                sellCurrencyType: data.sellCurrencyType,
                beneficiaryName: data.beneficiaryName,
                orderPayer: data.orderPayer,
              })?.currency,
            ),
            ".",
          ),
      },
      {
        caption: "Valor em reais",
        cellRender: ({ data }) =>
          masks.currency(data.nationalCurrencyTotal, currencySymbol.BRL, "."),
      },
    ],
  } satisfies Grid);
