import {
  OCard,
  OCol,
  ODivider,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
  OSkeletonCardNode,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { currencySymbol, mapCurrencySymbol } from "utils/currency";
import { GracePeriodsTip } from "./grace-period.component";
import { useSimulation } from "./simulation.context";
import { formFields } from "./simulation.form";

export const SimulationComponent = () => {
  const {
    summary,
    disabledDates,
    termOptions,
    loading,
    hideForm,
    summaryLoading,
  } = useSimulation();

  const navigate = useNavigate();

  const navigateTriggered = useRef(false);

  useEffect(() => {
    if (!navigateTriggered.current && summary?.limit === 0) {
      navigateTriggered.current = true;
      OToastManager.warning(
        "Usuário(a) não possui limite suficiente para prosseguir com a proposta",
      );
      navigate("/emprestimos/cliente/ofertas");
    }
  }, [navigate, summary]);

  const canEditAmount = summary?.agreement.partialLimitConsumeAllowed;
  const canEditTerms = summary?.isTermsChangeAvailable;
  const canEditFirstPaymentDate = summary?.isFirstPaymentDateChangeAvailable;

  return (
    <div className="mb-4">
      <OSkeletonCardNode
        loading={summaryLoading}
        height="325px"
        width="100%"
        rounded
      >
        <OCard
          className={`position-relative ${hideForm ? "invisible" : "bg-light"}`}
        >
          {loading && <OLoader backdrop absolute />}
          <div className={hideForm ? "d-none" : "d-block"}>
            <div className="card-header bg-light p-4">
              <OTypography className="m-0" size="xl" weight="normal">
                Valores e condições
                {!!summary && canEditAmount && (
                  <div>
                    <OTypography tag="span">
                      Seu limite máximo para contratação é:{" "}
                    </OTypography>
                    <OTypography tag="span">
                      {masks.currency(
                        summary.limit,
                        mapCurrencySymbol(currencySymbol.BRL),
                      )}
                    </OTypography>
                  </div>
                )}
              </OTypography>
            </div>

            <ODivider size="xxs" />
            <div className="card-body p-4">
              <ORow>
                <OCol>
                  {canEditAmount && (
                    <OTypography size="lg" weight="bold">
                      Quanto você deseja?
                    </OTypography>
                  )}
                </OCol>
              </ORow>
              <ORow>
                <OCol lg={4} sm={12} className="d-flex flex-column p-4">
                  <ORFieldInput
                    {...formFields.amount}
                    label={
                      canEditAmount
                        ? "Digite um valor:"
                        : "Valor do financiamento"
                    }
                    tag="currency"
                    symbol={currencySymbol.BRL}
                    disabled={!canEditAmount}
                  />
                </OCol>
                <OCol lg={4} sm={12} className="d-flex flex-column p-4">
                  <ORFieldInputDate
                    {...formFields.firstPaymentDate}
                    allowClear={canEditFirstPaymentDate}
                    aspect="flushed"
                    disabledDate={disabledDates}
                    disabled={!canEditFirstPaymentDate}
                    tooltip={GracePeriodsTip()}
                  />
                </OCol>

                <OCol lg={4} sm={12} className="p-4">
                  <ORFieldSelect
                    {...formFields.termOptions}
                    disabled={!canEditTerms}
                    key={`terms-${termOptions}`}
                  >
                    <div>
                      {termOptions.map((term) => (
                        <OOption value={term.value} key={term.value}>
                          {term.terms} {term.amount} {term.rate}
                        </OOption>
                      ))}
                    </div>
                  </ORFieldSelect>
                </OCol>
              </ORow>
            </div>
          </div>
        </OCard>
      </OSkeletonCardNode>
    </div>
  );
};
