import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostAdminWorkflowDashboardGetSelectedWorkflowConfigsBody } from "services/hubcreditmanager/models/requests/post-admin-workflow-dashboard-get-selected-workflow-configs.request";

const today = new Date();
today.setHours(0, 0, 0, 0);
const todayISOString = today.toISOString();

const initialValues = {
  workflowConfigIds: [] as string[],
  originatorIds: [] as string[],
  startDate: new Date(todayISOString),
  endDate: new Date(todayISOString),
  period: [todayISOString, todayISOString],
};

export const useWorkflowLeadDashboardPage = () => {
  const form =
    useForm<PostAdminWorkflowDashboardGetSelectedWorkflowConfigsBody>({
      defaultValues: initialValues,
    });

  const { watch } = form;
  const workflowConfigIdsWatcher = watch("workflowConfigIds");
  const watchDate = watch("period");
  const originatorsWatcher = watch("originatorIds");

  const dashboardDatas = useServiceCall(
    service.hubCreditManager.getSelectedOriginators,
  );

  const getDashboard = dashboardDatas.callService;

  const handleGetDashboardByDateRange = useCallback(async () => {
    const { workflowConfigIds, originatorIds, period } = form.getValues();
    const [startDate, endDate] = period;

    if (!workflowConfigIds.length) return;

    await getDashboard({
      workflowConfigIds,
      originatorIds,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  }, [form, getDashboard]);

  useEffect(() => {
    if (!originatorsWatcher.length) return;

    handleGetDashboardByDateRange();
  }, [
    handleGetDashboardByDateRange,
    watchDate,
    originatorsWatcher,
    workflowConfigIdsWatcher,
  ]);

  return {
    dashboardDatas,
    handleGetDashboardByDateRange,
    form,
  };
};
