import { useGridRef } from "hooks/grid-ref";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { GetDisverbationApprovalsResponse } from "services/fidc/models/responses/get-disverbation-approvals.response";
import { service } from "services";
import { modalManager, OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
import { isArray } from "lodash";
import { useForm } from "react-hook-form";
import { PostSendDisverbationBody } from "services/fidc/models/requests/post-send-disverbation.request";
import { disverbationHistorysGrid } from "./disverbation-historys.grid";
import { useFidcFunds } from "../../../../hooks";
import { repostDisverbationConfirmationModalId } from "./_compose/confirmation-modal-repost/confirmation-modal.utils";
import { pendingDisverbationConfirmationModalId } from "./_compose/confirmation-modal-pending/confirmation-modal.utils";

const arrayToQuery = <T extends string[]>(arr: Record<string, T>) =>
  Object.entries(arr)
    .map(([key, array]) =>
      array.map((val, index) => `${key}[${index}]=${val}`).join("&"),
    )
    .join("");

export const buildQuery = (filters: any) =>
  Object.entries(filters)
    .filter(([key, val]) => !!key && !!val)
    .map(([key, val]) =>
      isArray(val) ? arrayToQuery({ [key]: val }) : `${key}=${val}`,
    )
    .join("&");

export const useDisverbationHistorys = () => {
  const { funds } = useFidcFunds();
  const form = useForm();
  const { watch } = form;

  const selectedFundWatch: string = watch("selectedFund");
  const [loading, setLoading] = useState(false);
  const [cgeFundSelected, setCgeFundSelected] = useState<string | undefined>(
    "",
  );

  const [selectedDisverbations, setSelectedDisverbations] =
    useState<Fidc.Installments[]>();
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(999);

  const gridRef = useGridRef();
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const updateDataSource = useCallback(() => {
    setUpdateTrigger((prev) => prev + 1);
  }, []);

  useEffect(() => {
    return setCgeFundSelected(
      funds?.find((fund) => fund.taxId === selectedFundWatch)?.cge,
    );
  }, [funds, selectedFundWatch]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        GetDisverbationApprovalsResponse["Parcelas"][number]
      >(() =>
        service.fidc
          .getDisverbations(
            buildQuery({
              limit: rows,
              page,
              fundCge: cgeFundSelected,
            }),
          )
          .then(({ data }) => {
            setTotalItems(data.TotalCount);
            return {
              data: data.Parcelas,
              totalCount: data.TotalCount || 0,
            };
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro na buscar das desaverbações.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [cgeFundSelected, page, rows, updateTrigger],
  );

  const grid = useMemo(
    () => disverbationHistorysGrid(setSelectedDisverbations),
    [setSelectedDisverbations],
  );

  const repostDisverbation = useCallback(() => {
    setLoading(true);

    if (
      selectedDisverbations?.some(
        (disverbation) => disverbation.Status === "ERROR",
      )
    ) {
      OToastManager.warning(
        "Não foi possível reenviar parcela com status de Erro!",
      );
      setLoading(false);
      modalManager.hide(repostDisverbationConfirmationModalId);
      return;
    }

    const Ids =
      selectedDisverbations?.map((disverbation) => disverbation.Id) || [];

    const body: PostSendDisverbationBody = {
      Ids,
      FundCges: [cgeFundSelected ?? ""],
      Republish: true,
    };

    service.fidc
      .postResendDisverbation(body)
      .then(() => {
        OToastManager.success("Reenvio realizado com sucesso!");
        updateDataSource();
      })
      .catch(() => {
        OToastManager.danger("Não foi possível reenviar a notificação!");
      })
      .finally(() => {
        setLoading(false);
        modalManager.hide(repostDisverbationConfirmationModalId);
      });
  }, [cgeFundSelected, selectedDisverbations, updateDataSource]);

  const pendingDisverbation = useCallback(() => {
    setLoading(true);

    const body: PostSendDisverbationBody = {
      Ids: [],
      FundCges: [cgeFundSelected ?? ""],
      Republish: true,
    };

    service.fidc
      .postResendDisverbation(body)
      .then(() => {
        OToastManager.success("Reenvio realizado com sucesso!");
        updateDataSource();
      })
      .catch(() => {
        OToastManager.danger("Não foi possível reenviar a notificação!");
      })
      .finally(() => {
        setLoading(false);
        modalManager.hide(pendingDisverbationConfirmationModalId);
      });
  }, [cgeFundSelected, updateDataSource]);

  return {
    grid,
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    selectedDisverbations,
    setSelectedDisverbations,
    form,
    selectedFundWatch,
    loading,
    repostDisverbation,
    pendingDisverbation,
  };
};
