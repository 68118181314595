import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { EmprestimosProdutoGestorLimitesByIdAndByCollateralId } from "../../../../../../../../routes/emprestimos.route-params";
import { CollateralDocuments } from "./_compose/collateral-documents/collateral-documents.component";
import { CollateralForm } from "./_compose/collateral-form/collateral-form.component";
import { PropertyForm } from "./_compose/property-form/property-form.component";
import { Registries } from "./_compose/registries/registries.component";

export const ImovelAdditionalDataPage = () => {
  const { id, collateralId } =
    useParams<EmprestimosProdutoGestorLimitesByIdAndByCollateralId>();

  if (!id || !collateralId) throw new Error("No id or collateralId");

  const {
    callService: getCollateral,
    value: collateral,
    hasError: hasErrorCollateral,
    loading: loadingCollateral,
  } = useServiceCall(service.hubCreditManager.getCollateralDetails);

  useEffect(() => {
    getCollateral(collateralId);
  }, [getCollateral, collateralId]);

  return (
    <ContentTemplate
      loading={loadingCollateral}
      hasError={hasErrorCollateral}
      value={collateral}
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível carregar informações."
          messageParagraph="Tente novamente mais tarde."
        >
          <TryAgainButton onClick={() => getCollateral(collateralId)} />
        </ErrorComponent>
      }
      render={(value) => (
        <>
          <CollateralForm collateral={value} />
          <PropertyForm collateral={value} />
          <Registries registries={value.properties?.[0]?.registries ?? []} />
          <CollateralDocuments collateralId={value.id} />
        </>
      )}
    />
  );
};
