import { OButton, OIcon, OTypography } from "@maestro/react";

interface ActionButtonProps {
  text: string;
  onclick: () => void;
  iconType?: HTMLOIconElement["type"];
  icon?: HTMLOIconElement["icon"];
}

export const ActionButton = ({
  icon,
  onclick,
  text,
  iconType,
}: ActionButtonProps) => {
  return (
    <OButton outline type="default" onClick={onclick} size="sm">
      <div className="d-flex gap-2">
        {icon && <OIcon category="orq" icon={icon} type={iconType} />}
        <OTypography type="dark">{text}</OTypography>
      </div>
    </OButton>
  );
};
