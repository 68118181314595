import { auth } from "./auth";
import { AuthCommand } from "./types/command";

class AuthManager {
  private auth = auth;

  async execute(command: AuthCommand) {
    await command.execute(this.auth);
  }
}

export const authManager = new AuthManager();
