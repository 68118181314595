import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { service } from "services";
import { GetCompaniesPaymentsReceiptsParams } from "services/api/models/requests";
import * as PaymentsEnums from "services/api/models/types/payments/enums";
import { logger } from "utils/logger";
import { DataGridStatusProps, DataSourceType } from "../types";

const validatePaymentFromType = <Details extends ApiPayments.Payment["detail"]>(
  payment: ApiPayments.Payment,
  type: ApiPayments.PaymentType,
): payment is Omit<ApiPayments.Payment, "detail"> & { detail: Details } => {
  return payment.type === type;
};

const getFirstErrorDetail = (errors?: ApiPayments.PaymentError[]): string => {
  if (!errors?.length) return "";
  const [error] = errors;
  return error.detail;
};

const MapperPaymentStatusToDataGridStatusProps: Record<
  ApiPayments.PaymentStatus,
  (errors?: ApiPayments.PaymentError[]) => DataGridStatusProps
> = {
  [PaymentsEnums.PaymentStatus.AwaitingApproval]: (errors) => {
    return {
      status: "Aprovação pendente",
      badgeType: "warning-light",
      icon: {
        icon: "orq-status-warning",
        type: "warning",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Started]: (errors) => {
    return {
      status: "Aprovação pendente",
      badgeType: "warning-light",
      icon: {
        icon: "orq-status-warning",
        type: "warning",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Created]: (errors) => {
    return {
      status: "Em processamento",
      badgeType: "info-light",
      icon: {
        icon: "orq-info-on",
        type: "info",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Scheduled]: (errors) => {
    return {
      status: "Agendado",
      badgeType: "info-light",
      icon: {
        icon: "orq-info-on",
        type: "info",
        category: "orq",
      },
      type: DataSourceType.Scheduled,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Canceled]: (errors) => {
    return {
      status: "Cancelado",
      badgeType: "danger-light",
      icon: {
        icon: "orq-status-disapproved",
        type: "danger",
        category: "orq",
      },
      type: DataSourceType.Unprocessed,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Adjourned]: () => {
    return {
      status: "Saldo insuficiente",
      badgeType: "warning-light",
      icon: {
        icon: "orq-status-warning",
        type: "warning",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip:
        "Regularize o saldo de sua conta para concluir essa operação dentro do prazo.",
    };
  },
  [PaymentsEnums.PaymentStatus.Dispatched]: (errors) => {
    return {
      status: "Em processamento",
      badgeType: "info-light",
      icon: {
        icon: "orq-info-on",
        type: "info",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Processed]: (errors) => {
    return {
      status: "Em processamento",
      badgeType: "info-light",
      icon: {
        icon: "orq-info-on",
        type: "info",
        category: "orq",
      },
      type: DataSourceType.Pending,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Failed]: (errors) => {
    return {
      status: "Rejeitado",
      badgeType: "default",
      type: DataSourceType.Unprocessed,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Confirmed]: (errors) => {
    return {
      status: "Realizado",
      badgeType: "success-light",
      icon: {
        icon: "orq-status-success",
        type: "success",
        category: "orq",
      },
      type: DataSourceType.Paid,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Reverted]: (errors) => {
    return {
      status: "Rejeitado",
      badgeType: "default",
      type: DataSourceType.Unprocessed,
      tooltip: getFirstErrorDetail(errors),
    };
  },
  [PaymentsEnums.PaymentStatus.Temporized]: () => {
    return {
      status: "Em análise",
      badgeType: "info-light",
      icon: {
        icon: "orq-info-on",
        type: "info",
      },
      type: DataSourceType.Pending,
      tooltip:
        "Regularize o saldo de sua conta para concluir essa operação dentro do prazo.",
    };
  },
};

export const assemblePaymentStatus = (
  item: ApiPayments.PaymentData,
): DataGridStatusProps => {
  return MapperPaymentStatusToDataGridStatusProps[item.status](item.errors);
};

const downloadSingleReceipt = async (identification: string, id: string) => {
  try {
    OToastManager.info("Iniciando download do comprovante. Por favor aguarde.");
    const { data } = await service.api.getCompanyPaymentSingleReceipt(
      identification,
      id,
    );
    helpers.downloadBlobFile("comprovante.pdf", data);
  } catch (error) {
    logger.error(error);
    OToastManager.danger(
      "Não foi possível fazer o download do comprovante. Tente novamente.",
    );
  }
};

const downloadMultipleReceipts = async (
  identification: string,
  ids: string[],
) => {
  const params: GetCompaniesPaymentsReceiptsParams = {
    ids,
  };

  try {
    OToastManager.info(
      "Iniciando download dos comprovantes. Por favor aguarde.",
    );
    const { data } = await service.api.getCompanyPaymentsReceipts(
      identification,
      params,
    );
    helpers.downloadBlobFile("comprovantes.zip", data);
  } catch (error) {
    logger.error(error);
    OToastManager.danger(
      "Não foi possível fazer o download dos comprovantes. Tente novamente.",
    );
  }
};

const handleDownloadPaymentsReceipt = async (
  identification: string,
  ids: Array<string | undefined>,
) => {
  const validIds = ids.flatMap((id) => (id ? [id] : []));
  const idsLenght = validIds.length;
  if (!idsLenght) throw new Error("Empty payment receipt ids");
  const isASingleReceipt = idsLenght === 1;
  if (isASingleReceipt) downloadSingleReceipt(identification, validIds[0]);
  else downloadMultipleReceipts(identification, validIds);
};

const canDownloadPaymentReceipt = (
  status: ApiPayments.PaymentStatus,
): boolean => {
  return [
    PaymentsEnums.PaymentStatus.Scheduled,
    PaymentsEnums.PaymentStatus.Confirmed,
  ].includes(status);
};

const isAPaymentStatus = (
  paymentStatus: unknown,
): paymentStatus is PaymentsEnums.PaymentStatus => {
  return Object.values<unknown>(PaymentsEnums.PaymentStatus).includes(
    paymentStatus,
  );
};

export const PaymentUtils = {
  validatePaymentFromType,
  assemblePaymentStatus,
  handleDownloadPaymentsReceipt,
  canDownloadPaymentReceipt,
  isAPaymentStatus,
};
