import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { ForeignCurrencyIndexForm } from "./_compose/foreign-currency-index-form/foreign-currency-index-form.component";
import { LimitQuotesForm } from "./_compose/limit-quotes/limit-quotes-form.component";
import { SettlementInstructionForm } from "./_compose/settlement-instruction-form/settlement-instruction-form.component";

export const LimitConditionsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const { callService, value, hasError, loading } = useServiceCall(
    service.hubCreditManager.getFinancialConditions,
  );

  const [hasAllSettlementInstruction, setHasAllSettlementInstruction] =
    useState<boolean>(false);

  useEffect(() => {
    callService(id);
  }, [callService, id]);

  useEffect(() => {
    if (value?.settlementInstruction) {
      setHasAllSettlementInstruction(
        !Object.values(value?.settlementInstruction).some((date) => !date),
      );
    }
  }, [value?.settlementInstruction, setHasAllSettlementInstruction]);

  return (
    <FormTemplate pageTitle={<PageTitle title="Condições" />}>
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        value={value}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => callService(id)} />
          </ErrorComponent>
        }
        render={(financialConditions) => (
          <div className="d-flex flex-column gap-4">
            <LimitQuotesForm
              financialConditions={financialConditions}
              hasAllSettlementInstruction={hasAllSettlementInstruction}
            />
            <SettlementInstructionForm
              financialConditions={financialConditions}
              setHasAllSettlementInstruction={setHasAllSettlementInstruction}
            />
            <ForeignCurrencyIndexForm
              financialConditions={financialConditions}
            />
          </div>
        )}
      />
    </FormTemplate>
  );
};
