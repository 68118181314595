import { client } from "../client";
import { customerServiceEndpoints } from "./customer-service.endpoints";
import { PostV1OperatorBody } from "./models/requests";
import { PostV1OperatorResponse } from "./models/responses";

export const customerServiceService = {
  createOperator(body: PostV1OperatorBody) {
    return client.post<PostV1OperatorResponse>(
      customerServiceEndpoints.v1.operator,
      body,
    );
  },
};
