import {
  OButton,
  OField,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { ILimitBag } from "services/limit-control/models/responses";
import { useCreateLimitModal } from "./create-dependent-limite-modal.hooks";
interface AddGroupConfirmationModalProps {
  limitBag: ILimitBag;
}

export const buildAddLimiteModalId = (prefix: string) =>
  `add-group-confirmation-modal-id-${prefix}`;

export const AddLimiteModal = ({
  limitBag
}: AddGroupConfirmationModalProps) => {
  const modalId = buildAddLimiteModalId(limitBag.id);
  const { sacadosLoading, sacadosOptions, form, submit } = useCreateLimitModal(
    limitBag
  );

  const {
    formState: { errors },
    setValue,
  } = form;
  const clearSacado = useCallback(
    () => {
      setValue("sacadoCnpj", null as any);
      setValue("sacadoName", null as any);
      document.getElementById("sacadoId")?.setAttribute("value", "");
    },
    [setValue],
  );

  return (
    <OModal
      id={modalId}
      position="center"
      backdrop
      onModalClose={() => {
        clearSacado();
        setValue("totalAmount", "0");
      }}
    >
      <OModalHeader closeButton>
        <OTypography size="lg">Criar limite por sacado</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <OField
            label="Credenciadora"
            htmlFor="sacadoId"
            error={!!errors.sacadoCnpj}
            message={errors?.sacadoCnpj?.message}
            required
          >
            <SelectSearch
              id="sacadoId"
              name="sacadoId"
              placeholder="Busque o nome da credenciadora"
              options={sacadosOptions}
              disabled={sacadosLoading}
              onClear={clearSacado}
              className="w-100"
              maxOptions={50}
              eager={false}
            />
          </OField>
          <ORFieldInput
            dataAction="controle_limite:valor_novo_sublimite"
            dataLabel="limite_total"
            id="totalAmount"
            name="totalAmount"
            tag="currency"
            symbol="R$"
            label="Valor total do limite"
            required
          />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
      <OButton
          dataAction="controle_limite:cria_novo_sublimite"
          dataLabel="cria_novo_sublimite"
          onClick={submit}
        >
          Criar Limite
        </OButton>{" "}
      </OModalFooter>
    </OModal>
  );
};
