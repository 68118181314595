import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const BiometryRequiredField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.biometryRequired}
      name="biometryRequired"
    />
  );
};
