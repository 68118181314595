import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useUngroupConfirmationModal = (
  modalId: string,
  prefix: string,
  successCallback: () => void,
) => {
  const {
    callService: removeGroup,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.removeGroup);

  const ungroup = useCallback(() => removeGroup(prefix), [prefix, removeGroup]);

  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao desagrupar");
  }, [hasError]);

  useEffect(() => {
    if (value) {
      OToastManager.success("Prefixo desagrupado com sucesso");
      modalManager.hide(modalId);
      successCallback();
    }
  }, [modalId, successCallback, value]);

  return {
    ungroup,
    loading,
  };
};
