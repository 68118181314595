import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { LoadingButton } from "components/loading-button";
import { CardTemplate } from "templates/card-template";
import { FormProvider } from "react-hook-form";
import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { useBatchSemaphore } from "./batch-semaphore-consult.hook";

export const BatchSemaphoreConsult = () => {
  const { submit, loading, form } = useBatchSemaphore();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Consulta em Lote"
          description="Consultar CNPJs/CPFs em lote na validação de semáforo de Onboarding"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="mesas:botao:salvar"
          dataLabel="salvar"
          onClick={submit}
        >
          Consultar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={12} xl={12}>
                <ORFieldInput
                  tag="textarea"
                  id="value"
                  name="value"
                  dataAction="consulta-semaforo:input:value"
                  dataLabel="input:value"
                  label="CNPJ/CPF"
                  initialHeight={300}
                  description="Insira os CNPJs e/ou CPFs a serem validados separados por vírgula"
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
