import { helpers } from "@maestro/utils";
import type DataGrid from "devextreme-react/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { ODataGridGeneratorConfig } from "../data-grid";

export const exportGrid = async (
  gridRef: React.RefObject<DataGrid>,
  filename = "grid.xlsx",
) => {
  const component = gridRef.current?.instance;
  if (!component) return;

  const props = gridRef.current
    ?.props as ODataGridGeneratorConfig<unknown>["datagrid"];

  props.exportGrid?.onExporting?.({ component });

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  await exportDataGrid({
    component,
    worksheet,
  });

  const buffer = await workbook.xlsx.writeBuffer();

  helpers.downloadBlobFile(props.exportGrid?.fileName ?? filename, buffer);

  props.exportGrid?.onExported?.({ component });
};
