import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { LimitConditions } from "../../limit-conditions.types";
import {
  ForeignCurrencyIndexFormValues,
  foreignCurrencyIndexFormValidationsSchema,
} from "./foreign-currency-index-form.schemas";

interface ForeignCurrencyIndexFormProps {
  financialConditions: LimitConditions;
}

export const ForeignCurrencyIndexForm = ({
  financialConditions,
}: ForeignCurrencyIndexFormProps) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<
    ForeignCurrencyIndexFormValues & {
      id?: number;
    }
  >({
    resolver: yupResolver(foreignCurrencyIndexFormValidationsSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          if (typeof values.id === "number") {
            await service.hubCreditManager.updateForeignCurrencyIndex({
              ...values,
              limitRequestId: financialConditions.id,
              id: values.id,
            });
          } else {
            await service.hubCreditManager.createForeignCurrencyIndex({
              ...values,
              limitRequestId: financialConditions.id,
            });
          }

          OToastManager.success("Dados de moeda estrangeira atualizados.");
        } catch {
          OToastManager.danger("Ocorreu um erro ao atualizar os dados.");
        } finally {
          setLoading(false);
        }
      }),
    [financialConditions.id, handleSubmit],
  );

  useEffect(() => {
    reset(
      financialConditions.foreignCurrencyIndex as ForeignCurrencyIndexFormValues,
    );
  }, [financialConditions.foreignCurrencyIndex, reset]);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_limite_condicoes:botao:atualizar-adicionar"
          dataLabel="atualizar-adicionar"
          onClick={submit}
        >
          Salvar alterações
        </LoadingButton>
      }
    >
      <OCard>
        <OCardHeader>
          <OTypography type="dark" tag="h3" size="lg">
            Moeda Estrangeira
          </OTypography>
        </OCardHeader>
        <OCardBody className="position-relative">
          <FormProvider {...form}>
            <ORow>
              <OCol>
                <ORFieldSelect id="currency" name="currency" label="Moeda">
                  {["USD", "EUR"].map((option) => (
                    <OOption key={option} value={option}>
                      {option}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="number"
                  id="priceDay"
                  name="priceDay"
                  label="Dia de referência do preço da moeda"
                  tooltip="Exemplos: -2, -1, 0 (0 é o momento da geração do contrato)"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol>
                <ORFieldSelect
                  id="currencyQuoteType"
                  name="currencyQuoteType"
                  label="Conversão no momento do desembolso"
                >
                  {["PTAX", "SPOT"].map((option) => (
                    <OOption key={option} value={option}>
                      {option}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="currency"
                  id="price"
                  name="price"
                  label="Valor da moeda"
                  tooltip="Se existir, este valor será usado no lugar da taxa definida pelo dia de referência"
                />
              </OCol>
            </ORow>
            <OCol md={6}>
              <ORFieldInputDate
                id="priceDate"
                name="priceDate"
                label="Data do valor da moeda"
                tooltip="Obrigatório apenas quando o valor da moeda for informado"
              />
            </OCol>
          </FormProvider>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
