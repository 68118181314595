import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskConfigOfficerForm } from "../_compose/desk-config-officer-form.component";
import {
  DeskConfigOfficerFormValues,
  deskConfigOfficerFormValidationSchema,
} from "../_compose/desk-config-officer.form";

export const RegisterDeskConfigOfficerPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  if (!configIdentifier) throw new Error("No configIdentifier");
  const form = useForm<DeskConfigOfficerFormValues>({
    resolver: yupResolver(deskConfigOfficerFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerDeskConfigOfficer, loading } = useServiceCall(
    service.onboarding.registerOfficersOnDeskOnboardingConfig,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await registerDeskConfigOfficer({
          deskOnboardingConfigIdentifier: configIdentifier,
          ...values,
        });

        if (!success)
          return OToastManager.danger("Erro ao criar relacionamento");

        return OToastManager.success("Relacionamento criada com sucesso");
      }),
    [configIdentifier, handleSubmit, registerDeskConfigOfficer],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={`Adicionar officers na mesa ${configIdentifier}`} />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskConfigOfficerForm />
      </FormProvider>
    </FormTemplate>
  );
};
