import { OToastManager } from "@maestro/core";
import {
  OCheckbox,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { scoreModuleFormDefaultValues } from "./score-module-form.schema";

type ScoreModuleFormProps = {
  id?: string | undefined;
  mode: "add" | "edit";
};

export const ScoreModuleForm = ({ id, mode }: ScoreModuleFormProps) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    callService: getScoreModule,
    value: scoreModule,
    loading: fetchLoading,
  } = useServiceCall(service.hubCreditManager.getScoreModuleById);

  const { handleSubmit, watch, trigger, reset } =
    useFormContext<typeof scoreModuleFormDefaultValues>();

  const visibleWatch = watch("isVisible");

  const upsertScoreModule = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          const isValid = await trigger();

          if (!isValid) return;

          setLoading(true);

          if (mode === "add") {
            await service.hubCreditManager.createScoreModule({
              ...values,
              isVisible: !!visibleWatch?.length,
            });
          } else {
            await service.hubCreditManager.updateScoreModule({
              ...values,
              isVisible: !!visibleWatch?.length,
              id: Number(id),
            });
          }
          OToastManager.success("Módulo salvo com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.originatorScore.modules
              .path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar o módulo.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, mode, navigate, trigger, visibleWatch],
  );

  useEffect(() => {
    if (mode !== "edit" || !id) return;

    getScoreModule(id);
  }, [getScoreModule, id, mode]);

  useEffect(() => {
    if (!scoreModule) return;

    reset({
      name: scoreModule.name,
      description: scoreModule.description,
      actionLabel: scoreModule.actionLabel,
      type: scoreModule.type,
      isVisible: scoreModule.isVisible ? ["1"] : [],
    });
  }, [reset, scoreModule]);

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          type="info"
          onClick={upsertScoreModule}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {fetchLoading && <OLoader backdrop absolute />}
        <ORFieldInput
          id="name"
          name="name"
          tag="text"
          label="Nome"
          labelSize="md"
        />
        <ORFieldInput
          id="type"
          name="type"
          tag="text"
          label="Tipo"
          labelSize="md"
        />
        <ORFieldInput
          id="actionLabel"
          name="actionLabel"
          tag="text"
          label="Ação"
          labelSize="md"
        />
        <ORFieldInput
          id="description"
          name="description"
          tag="textarea"
          label="Descrição"
          labelSize="md"
          aspect="outline"
        />
        <ORFieldCheckboxGroup id="isVisible" name="isVisible">
          <div className="d-flex align-items-center gap-2 mb-2">
            <OCheckbox size="xs" id="is-visible" value="1" />
            <OLabel htmlFor="is-visible">Visível?</OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </CardTemplate>
    </FormTemplate>
  );
};
