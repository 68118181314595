import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { FerramentasProdutoRavenNotificationConfigurationByIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { ConfigurationForm } from "../_compose/configuracoes-form";
import { useRavenNotificationConfigurationDetails } from "./details.hook";

export const RavenNotificationConfigurationsDetails = () => {
  const { configurationId } =
    useParams<FerramentasProdutoRavenNotificationConfigurationByIdRouteParams>();

  const { submit, form, loading } =
    useRavenNotificationConfigurationDetails(configurationId);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Notificações"
          description="Atualize as informações dessa configuração"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Atualizar
        </LoadingButton>
      }
    >
      <ConfigurationForm form={form} />
    </FormTemplate>
  );
};
