/* eslint-disable no-param-reassign */
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { TaxaWithErrors } from "./validation-results.types";
import { highlightErrors } from "./validation-results.utils";

export const validationResultsGrid: ODataGridGeneratorConfig<TaxaWithErrors> = {
  datagrid: {
    noDataText: "Nenhuma taxa",
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 50, 100, 1000],
    },
    filterRow: { visible: true },
    onRowPrepared: highlightErrors,
  },
  columns: [
    {
      dataField: "dataBase",
      dataType: "datetime",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      caption: "Data base",
    },
    {
      dataField: "dataFutura",
      dataType: "datetime",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      caption: "Data futura",
    },
    {
      dataField: "base",
      caption: "Base",
    },
    {
      dataField: "capitalizationType",
      caption: "Capitalização",
    },
    {
      dataField: "diasCorridos",
      caption: "Dias corridos",
    },
    {
      dataField: "taxaPeriodo",
      format: { type: "percent", precision: 4 },
      caption: "Taxa período",
    },
    {
      dataField: "taxaTotal",
      format: { type: "percent", precision: 4 },
      caption: "Taxa total",
    },
    {
      dataField: "rebateValue",
      format: { type: "percent", precision: 4 },
      caption: "Rebate",
    },
    {
      dataField: "currency",
      caption: "Moeda",
    },
    {
      dataField: "errorMessages",
      caption: "Erro",
      cellRender: ({ data: { errorMessages } }) =>
        errorMessages.map((message) => <p key={message}>{message}</p>),
    },
  ],
};
