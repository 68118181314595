import { DetailsFields } from "components/details-card";
import { GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse } from "services/admin-bankinghub/models";

export const statementHistoryDetailsFields = (
  traceability: GetCnabStatementTraceabilitiesByDocumentByAccountByAgencyResponse[number],
) => {
  const fields: DetailsFields = {
    info: {
      items: [
        {
          label: "Nome do arquivo",
          value: traceability?.file?.name || "Sem movimentação",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Origem",
          value: traceability?.origin,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "VAN para onde foi enviado",
          value:
            traceability?.file?.name &&
            parseInt(traceability?.account.vanId, 10) > 0
              ? traceability?.account.vanId
              : "Sem relacionamento",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Quantidade de movimentações",
          value: traceability?.countMovements,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Layout do arquivo",
          value: "FEBRABAN 240",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  };
  return fields;
};
