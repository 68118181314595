import { DetailsFields } from "components/details-card";
import { GetAdminReceivableFidcDailySummaryResponse } from "services/quickfin/models";

export const FidcReceivablesDailySummaryFields = (
  dailySummary: GetAdminReceivableFidcDailySummaryResponse,
): DetailsFields => ({
  balance: {
    items: [
      {
        label: "Total desembolsado",
        value: dailySummary.disbursementTotalAmmount,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Pendentes de desembolso",
        value: dailySummary.refusedReceivablesCount,
        grid: { sm: 12, md: 6, lg: 4 },
      },
      {
        label: "Notas recusadas",
        value: dailySummary.pendingDisbursementReceivablesCount,
        grid: { sm: 12, md: 6, lg: 4 },
      },
    ],
  },
});
