import type { Column } from "devextreme/ui/data_grid";

export function calculateTaxIdFilterExpression(
  this: Column,
  filterValue: string,
  selectedFilterOperation: string | null,
) {
  return [
    this.dataField,
    selectedFilterOperation,
    filterValue.replace(/\D/g, ""),
  ];
}
