import { auth } from "contexts/auth";
import DataSource, {
  type Options as DataSourceOptions,
} from "devextreme/data/data_source";
import ODataStore, {
  type Options as ODataStoreOptions,
} from "devextreme/data/odata/store";

export interface DataSourceODataStoreGeneratorOptions<T> {
  odataStoreOptions?: Partial<ODataStoreOptions<T, T>>;
  dataSourceOptions?: DataSourceOptions<T>;
}

export const dataSourceODataStoreGenerator = <T>(
  endpoint: string,
  options?: DataSourceODataStoreGeneratorOptions<T>,
) =>
  new DataSource<T>({
    store: new ODataStore<T>({
      url: endpoint,
      version: 4,
      ...options?.odataStoreOptions,
      beforeSend: (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          Authorization: auth.value,
          ...config.headers,
        };
        return options?.odataStoreOptions?.beforeSend?.(config) ?? config;
      },
    }),
    ...options?.dataSourceOptions,
  });
