export const productMap: Record<string, string> = {
  CCC: "Cessão com coobrigação",
  Cartao: "Cartão",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  RiscoCedente: "Cessão de crédito",
  RiscoSacado: "Risco Sacado",
};

export const priorityMap: Record<number, string> = {
  1: "Máxima",
  2: "Alta",
  3: "Média",
  4: "Baixa",
};
