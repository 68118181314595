import { Execution } from "./execution";
import { Result } from "./result";
import { Simulation } from "./simulation";

export const steps = [
  {
    name: "Simulação",
    component: Simulation,
  },
  {
    name: "Execução",
    component: Execution,
  },
  {
    name: "Resultado",
    component: Result,
  },
];
