import { OToastManager } from "@maestro/core";
import {
  OIcon,
  OLoader,
  OStep,
  OStepHeader,
  OStepsTimeline,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { client } from "../../../../../../../services/client";
import { endpoints } from "../../../utils/endpoints";
import { NoDataFound } from "../../cards.styles";
import { CardType } from "../../cards.types";
import { CardSection } from "../already-approved.styles";
import { TrackingContent } from "./tracking.styles";
import {
  defaultStepsLabels,
  ITrackingCard,
  ITrackingProps,
  TrackingStatusToStep,
  TrackingStepsNumber,
  TrackingStepType,
} from "./tracking.types";
import { CardTrackingAlert } from "./_compose/card-tracking-alert";
import { CardTrackingInfo } from "./_compose/card-tracking-info";

export const Tracking = ({ cardData, selectedCompany }: ITrackingProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trackingData, setTrackingData] = useState<ITrackingCard>();

  const loadTracking = useCallback(async () => {
    setTrackingData(undefined);
    try {
      setIsLoading(true);
      if (!selectedCompany) return;
      const { data } = await client.get<ITrackingCard>(
        endpoints.bankinghub.getTrackings(cardData.id, cardData.trackingId),
        {
          headers: {
            "x-identification": selectedCompany,
          },
        },
      );
      if (data) {
        data.history.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
        setTrackingData(data);
      }
    } catch (error) {
      OToastManager.danger("Erro ao carregar informações de Entrega do cartão");
    } finally {
      setIsLoading(false);
    }
  }, [selectedCompany, cardData]);

  useEffect(() => {
    loadTracking();
  }, [loadTracking]);

  const currentStep = useMemo(() => {
    if (!trackingData) return;
    const [currentHistory] = trackingData.history.slice(-1);
    const currentStatus = currentHistory.status;
    return TrackingStatusToStep[currentStatus];
  }, [trackingData]);

  const stepsLabels = useMemo(() => {
    if (!currentStep) return;
    return defaultStepsLabels.map((step, index) => {
      if (currentStep.stepIndex === index) return currentStep.subtitle;
      return step;
    });
  }, [currentStep]);

  const hasTimeline = useMemo(
    () => currentStep?.stepIndex !== TrackingStepsNumber.Step4,
    [currentStep],
  );

  return (
    <CardSection>
      <OTypography weight="bold" className="mt-3 mb-2">
        · Entrega do cartão
      </OTypography>
      {!isLoading && trackingData && currentStep ? (
        <TrackingContent>
          {stepsLabels && hasTimeline && (
            <OStepsTimeline
              key={stepsLabels.join()}
              activeStep={currentStep.stepIndex}
              className="mb-3"
            >
              <OStepHeader disableNavigation={true}>
                {stepsLabels.map((stepLabel, index) => {
                  return (
                    <OStep
                      key={`tracking-step-${index}`}
                      dataAction={`rastreamento_cartao:steps_timeline:selecionar_etapa`}
                      dataLabel={`selecionar_etapa`}
                    >
                      {stepLabel}
                    </OStep>
                  );
                })}
              </OStepHeader>
            </OStepsTimeline>
          )}
          <div className="d-flex justify-content-between flex-md-row flex-column gap-4">
            <div className="d-flex flex-column flex-grow-1">
              {cardData.type === CardType.Physical &&
              currentStep.type === TrackingStepType.Info ? (
                <CardTrackingInfo
                  cardTracking={trackingData}
                  address={cardData.deliveryAddress}
                />
              ) : (
                <CardTrackingAlert
                  cardLast4Digits={cardData.last4Digits}
                  cardTracking={trackingData}
                />
              )}
            </div>
          </div>
        </TrackingContent>
      ) : isLoading ? (
        <div
          style={{
            marginTop: "30px",
            marginLeft: "50px",
          }}
        >
          <OLoader />
        </div>
      ) : (
        <NoDataFound className="gap-2 px-3">
          <OIcon category="fal" icon="fa-info-circle" />
          <OTypography>
            Nenhum dado de entrega do cartão encontrado.
          </OTypography>
        </NoDataFound>
      )}
    </CardSection>
  );
};
