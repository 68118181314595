import type { ValidationRule } from "devextreme/common";
import type { CellPreparedEvent } from "devextreme/ui/data_grid";
import { Tier } from "./tiers.types";

export const percentageValidator: ValidationRule[] = [
  { type: "required" },
  {
    type: "custom",
    message: "O valor deve ser um número entre 0 e 1",
    validationCallback: ({ value = -1 }: { value?: number }) => {
      try {
        return value >= 0 && value <= 1;
      } catch {
        return false;
      }
    },
  },
];

export const warnLimit =
  (total: number | undefined) =>
  ({ data, cellElement }: CellPreparedEvent<Tier>) => {
    if (!total || !data?.totalAmount) return;

    if (data.totalAmount / total >= data.limit + data.overLimit) {
      cellElement.style.setProperty("background-color", "var(--theme-danger)");
      cellElement.style.setProperty("color", "var(--theme-danger-inverse)");
    } else if (data.totalAmount / total >= data.limit) {
      cellElement.style.setProperty("background-color", "var(--theme-warning)");
      cellElement.style.setProperty("color", "var(--theme-warning-inverse)");
    }
  };
