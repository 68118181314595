import { useGridRef } from "hooks/grid-ref";
import { PageTitle } from "components/page-title";
import {
    ODataGridGenerator,
} from "components/data-grid";
import { appVerifierGrid } from "./verificar-app.grid";
import { GridTemplate } from "templates/grid-template";
import { dataSource } from "./verificar-app.grid";

export const AppVerifier = () => {
    const gridRef = useGridRef();
    return (
        <GridTemplate
            pageTitle={<PageTitle title="Homologação de aplicativo" />}
            gridRef={gridRef}
        >
            <ODataGridGenerator
                gridRef={gridRef}
                grid={appVerifierGrid}
                dataSource={dataSource}
            />
        </GridTemplate>
    );
};

