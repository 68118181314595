import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  BiometryRequiredField,
  CancelaNaoAntecipadasD0Field,
  ComandoOperacaoField,
  ExigeAprovacaoBancoField,
  FccIdSacadoField,
  HabilitaAntecipacoesParciaisField,
  InstrumentCodeIdField,
  LinkTaggeadoField,
  ModeloDeTaxaField,
  NomeField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  RequiresPMEAccountField,
  RiscoOperacaoField,
  TipoOnboardingField,
  ToCreditField,
  GracePeriodField,
} from "../fields";

interface CartaoFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const CartaoForm = ({ form, shouldReset }: CartaoFormProps) => {
  const { getValues, reset } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        horarioLimiteOperacao: "16:45",
        modeloDeTaxa: "configuracao",
        exigeAprovacaoBanco: "no",
        prazoLimiteOperacao: 5,
        toCredit: "yes",
        tipoOnboarding: "CEDENTE_COMPLETO",
        riscoOperacao: "Sacado",
        comandoOperacao: "Cedente",
        habilitaAntecipacoesParciais: "yes",
        requiresPMEAccount: "yes",
        cancelaNaoAntecipadasD0: "yes",
      });
  }, [getValues, reset, shouldReset]);

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <ExigeAprovacaoBancoField />
        <TipoOnboardingField type="DEFAULT" />
        <PrazoLimiteOperacaoField />
        <GracePeriodField />
        <PnlBookField />
        <PnlStrategyField />
        <PortfolioIdField />
        <InstrumentCodeIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Cartões
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <HabilitaAntecipacoesParciaisField />
        <RiscoOperacaoField required />
        <ComandoOperacaoField required />
        <RequiresPMEAccountField />
        <CancelaNaoAntecipadasD0Field />
        <LinkTaggeadoField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
        <FccIdSacadoField />
      </ORow>
    </>
  );
};
