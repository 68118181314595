import { OUploadCustomEvent } from "@maestro/core";
import {
  OIcon,
  OLink,
  OLoader,
  ORFieldUploadInput,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useStepsBuilder } from "components/steps-builder";
import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { logger } from "utils/logger";
import { UploadContainer } from "../../../../../components";
import { parseQuoteSpreadsheet } from "../../../../../utils";
import { useQuote } from "../../quote.context";
import { validatePayload } from "../../quote.utils";

export const FileUpload = () => {
  const [hasParseError, setHasParseError] = useState(false);

  const { setQuoteFileData, getQuote } = useQuote();

  const { nextStep } = useStepsBuilder();

  const { error: readerError, loading, reader, result, reset } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        const quote = parseQuoteSpreadsheet(result);

        if (quote.length > 0) {
          setQuoteFileData(validatePayload(quote));
          getQuote(quote);
        }

        nextStep();
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    }
  }, [getQuote, nextStep, readerError, result, setQuoteFileData]);

  if (hasParseError || !!readerError)
    return (
      <div>
        <ErrorComponent
          messageTitle="Erro ao processar a planilha"
          messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
        >
          <TryAgainButton
            onClick={() => {
              setHasParseError(false);
              reset();
            }}
          />
        </ErrorComponent>
      </div>
    );

  return (
    <div>
      <FormProvider {...form}>
        <UploadContainer>
          <ORFieldUploadInput
            id="quote"
            name="quote"
            inputLabel="Clique ou arraste o arquivo aqui"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            dataAction="cotacao:upload:cotacao"
            dataLabel="cotacao"
            multiple={false}
            handleAddFile={handleAddFile}
            disabled={loading}
            value={[]}
          />
        </UploadContainer>

        <div className="d-flex flex-column align-items-center gap-3 mt-4">
          <OLink href="/files/modelo_importacao_cotacao.xlsx">
            <OIcon category="fas" icon="fa-external-link" className="me-2" />
            Baixar modelo
          </OLink>
          <OTypography type="default">
            Por favor, preencha as colunas em verde. As colunas em vermelho não
            serão usadas.
          </OTypography>
          {loading && <OLoader />}
        </div>
      </FormProvider>
    </div>
  );
};
