import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const RebateRebarbaSacadoField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.rebateRebarbaSacado}
      name="rebateRebarbaSacado"
      description="Configuração que define para quem vai o que sobra do rebate em prazo. Caso esteja ativada (Sim), volta para o sacado. Caso contrário (Não), volta para o BTG"
    />
  );
};
