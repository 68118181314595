import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useParams } from "react-router-dom";
import { OToastManager } from "@maestro/react";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../../../../routes/emprestimos.route-params";
import { CollateralFormValues } from "../../add-collateral-form.schema";
import { policyGrid } from "./policy.grid";

export const Policy = () => {
  const gridRef = useGridRef();
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager.getPolicies(id).catch(() => {
          const errorMessage = "Erro ao buscar as apólices";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
      ),
    [id],
  );

  const form = useFormContext<CollateralFormValues>();
  const { setValue } = form;

  const memoizedGrid = useMemo(() => policyGrid(setValue), [setValue]);

  return (
    <ODataGridGenerator
      gridRef={gridRef}
      grid={memoizedGrid}
      dataSource={dataSource}
    />
  );
};
