import { OOption, ORFieldSelect } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import {
  Desk,
  DeskMigrationOptionsForm,
} from "../../desk-migration-options.types";

interface OfficerSelectorProps {
  desks: Desk[] | undefined;
}

const name = "officerCge";

export const OfficerSelector = ({ desks }: OfficerSelectorProps) => {
  const form = useFormContext<DeskMigrationOptionsForm>();

  const { watch } = form;

  const deskCodeWatcher = watch("deskCode");

  const selectedDesk = desks?.find((desk) => desk.deskCode === deskCodeWatcher);

  return (
    <ORFieldSelect
      dataAction="formulario:select:officer"
      dataLabel="officer"
      id={name}
      name={name}
      label="Officer"
      placeholder={selectedDesk ? "Selecionar" : "Primeiro escolha uma mesa"}
      disabled={!selectedDesk}
      required
      key={`${deskCodeWatcher}${selectedDesk?.officers?.length}`}
    >
      {selectedDesk?.officers.map((officer) => (
        <OOption key={officer.cge} value={officer.cge}>
          {officer.name}
        </OOption>
      ))}
    </ORFieldSelect>
  );
};
