export const documentTypeMap = {
  PROCURACAO: "Procuração",
  CCMEI: "CCMEI",
  ATA_ELEICAO_DIRETORIA: "Ata de eleição de diretoria",
  CONTRATO_SOCIAL: "Contrato Social",
  REQUERIMENTO_EMPRESARIO: "Requerimento de empresario",
  INDEFINIDO: "Indefinido",
};

export const documentTypeIdMap = {
  11: "CONTRATO DE CARTEIRA",
  45: "SUBSTABELECIMENTO DE PROCURAÇÃO",
  491: "ESTATUTO SOCIAL",
  496: "PROCURAÇÃO",
  499: "ATA ELEIÇÃO DIRETORIA/GERENTE",
  505: "CONTRATO SOCIAL",
  675: "ATA",
  907: "CERTIFICATE OF INCUMBENCY",
  908: "POWER OF ATTORNEY",
  1007: "BY LAWS",
  20076: "CERTIFICAÇÃO DE RESOLUÇÃO CORPORATIVA",
  20089: "CONTRATO DE GESTÃO DE INVESTIMENTOS",
  20093: "CERTIFICAÇÃO DE RESOLUÇÃO DA EMPRESA DE RESPONSABILIDADE LIMITADA",
  20104: "FORMULÁRIO DE CONTA DE PARCERIA",
  20118: "AUTORIZAÇÃO DE NEGOCIAÇÃO/POA E FORMULÁRIO DE INDENIZAÇÃO",
  20121: "CERTIFICAÇÃO DE TRUSTEE DE PODERES DE INVESTIMENTO",
  20140: "CONTRATO DISCRICIONÁRIO DE GESTÃO DE PORTFOLIO",
};
