import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { InviteModal } from "../_compose";
import { CurrentAccountForm } from "../_compose/current-account-form";
import { useAccountPjInvite } from "./invite.hook";

export const AccountPjInvite = () => {
  const {
    form,
    submit,
    loading,
    setLoading,
    optinTemplate,
    taxId,
    payloadAux,
  } = useAccountPjInvite();

  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle
            title="Convite"
            description="Preencha o formulário abaixo para enviar o convite."
          />
        }
        actions={
          <LoadingButton
            loading={loading}
            dataAction="convite:botao:enviar"
            dataLabel="enviar"
            onClick={submit}
          >
            Enviar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <CurrentAccountForm
            showEmailField
            form={form}
            setLoading={setLoading}
          />
        </CardTemplate>
      </FormTemplate>

      <InviteModal
        taxId={taxId}
        payloadAux={payloadAux}
        optinTemplate={optinTemplate}
      />
    </>
  );
};
