import {
  FeesType,
  InterestType,
} from "services/admin-bankinghub/collection/models/types";
import { FieldInputProps, FieldName } from "./percent-or-amount-input.types";

const MapperFieldNameToLabel: Record<FieldName, string> = {
  [FieldName.Discount]: "Taxa de desconto",
  [FieldName.Fine]: "Taxa de multa",
  [FieldName.Interest]: "Taxa de juros (ao mês)",
};

export const assembleFieldInputProps = (
  fieldName: FieldName,
  inputType?: FeesType | InterestType,
): FieldInputProps => {
  const name = `settings.${fieldName}.percentOrAmount`;
  const id = name;
  const label = MapperFieldNameToLabel[fieldName];

  const defaultValues = { id, name, label, key: inputType };

  if (inputType) {
    const isPercentage = [
      FeesType.PERCENTAGE,
      InterestType.PERCENTAGE_PER_MONTH,
    ].includes(inputType);

    if (isPercentage)
      return { ...defaultValues, tag: "percentage", min: 0, max: 99.99 };

    const isCurrency = [
      FeesType.FIXED_VALUE,
      InterestType.FIXED_VALUE_PER_MONTH,
    ].includes(inputType);

    if (isCurrency) return { ...defaultValues, tag: "currency", symbol: "R$" };
  }

  return { ...defaultValues, tag: "text", placeholder: "Não se aplica" };
};
