export const ferramentasRouteParams = {
  requestId: "requestId",
  changeId: "changeId",
  taxId: "taxId",
  migrationId: "migrationId",
  uuid: "uuid",
  deskCode: "deskCode",
  configIdentifier: "configIdentifier",
  relationshipUuid: "relationshipUuid",
  configurationId: "configurationId",
  id: "id",
} as const;

type FerramentasParams = Record<keyof typeof ferramentasRouteParams, string>;

export type FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams = Pick<
  FerramentasParams,
  "requestId"
>;

export type FerramentasProdutoGerenciamentoPerfisByRequestIdByChangeIdRouteParams =
  Pick<FerramentasParams, "requestId" | "changeId">;

export type FerramentasProdutoUsuariosByTaxIdRouteParams = Pick<
  FerramentasParams,
  "taxId"
>;

export type FerramentasProdutoUsuariosByUUIDRouteParams = Pick<
  FerramentasParams,
  "uuid"
>;

export type FerramentasProdutoMigracoesMesasByMigrationIdRouteParams = Pick<
  FerramentasParams,
  "migrationId"
>;

export type FerramentasProdutoOnboardingConfiguracaoDeMesasMesaByDeskCodeRouteParams =
  Pick<FerramentasParams, "deskCode">;

export type FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams =
  Pick<FerramentasParams, "configIdentifier">;

export type FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams =
  Pick<FerramentasParams, "uuid" | "configIdentifier">;

export type FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaByUuidRouteParams =
  Pick<FerramentasParams, "uuid">;

export type FerramentasProdutoOnboardingConfiguracaoDeMesasInformacoesDeContaRelacionamentosByUuidRouteParams =
  Pick<FerramentasParams, "uuid" | "relationshipUuid" | "configIdentifier">;
  
export type FerramentasProdutoOnboardingConfiguracaoDeMesasComParceirosByUuidRouteParams =
  Pick<FerramentasParams, "uuid">;

export type FerramentasProdutoRavenNotificationTypeByIdRouteParams = Pick<
  FerramentasParams,
  "uuid"
>;

export type FerramentasProdutoRavenAddNotificationConfigByTypeIdRouteParams =
  Pick<FerramentasParams, "uuid">;

export type FerramentasProdutoRavenNotificationConfigurationByIdRouteParams =
  Pick<FerramentasParams, "configurationId">;

export type FerramentasProdutoOnboardingConfiguracaoDeConviteTemplateDeEmailByIdRouteParams =
  Pick<FerramentasParams, "id">;
