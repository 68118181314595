import {
  OCol,
  OField,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
} from "@maestro/react";

export const ImportBuyAndSellFidcForm = () => {
  return (
    <div>
      <ORow>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="buyerTaxId"
            name="buyerTaxId"
            label="CNPJ Sacado"
            dataAction="compra_venda_fidc:input:cnpj_sacado"
            dataLabel="cnpj_sacado"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="sellerTaxId"
            name="sellerTaxId"
            label="CNPJ Cedente"
            dataAction="compra_venda_fidc:input:cnpj_cedente"
            dataLabel="cnpj_cedente"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="guarantorTaxId"
            name="guarantorTaxId"
            label="CNPJ Fiador"
            dataAction="compra_venda_fidc:input:cnpj_fiador"
            dataLabel="cnpj_fiador"
            readonly
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id="brandNew"
            name="brandNew"
            label="Zero Km"
            dataAction="compra_venda_fidc:select:kero_km"
            dataLabel="kero_km"
          >
            <OOption value="false">NÃO</OOption>
            <OOption value="true">SIM</OOption>
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id="type"
            name="type"
            label="Tipo"
            dataAction="compra_venda_fidc:select:tipo"
            dataLabel="tipo"
          >
            <OOption value="LEVE">leve</OOption>
            <OOption value="PESADO">Pesado</OOption>
            <OOption value="MAQUINAS_EQUIPAMENTOS">
              Máquinas & Equipamentos
            </OOption>
          </ORFieldSelect>
        </OCol>

        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="gracePeriod"
            name="gracePeriod"
            label="Tempo de carência (em meses)"
            dataAction="compra_venda_fidc:input:tempo_carencia"
            dataLabel="tempo_carencia"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="installments"
            name="installments"
            label="Número de parcelas (em meses)"
            dataAction="compra_venda_fidc:input:numero_parcelas"
            dataLabel="numero_parcelas"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <OField label="Data de Contrato (DD/MM/AAAA)" htmlFor="operationDate">
            <ORFieldInputDate
              id="operationDate"
              name="operationDate"
              className="mt-2"
            />
          </OField>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <OField label="Data de Vencimento (DD/MM/AAAA)" htmlFor="dueDate">
            <ORFieldInputDate id="dueDate" name="dueDate" className="mt-2" />
          </OField>
        </OCol>
        <OCol xs={12} xl={6}>
          <OField
            label="Data do Contrato de Compra e Venda (DD/MM/AAAA)"
            htmlFor="contractDate"
          >
            <ORFieldInputDate
              id="contractDate"
              name="contractDate"
              className="mt-2"
            />
          </OField>
        </OCol>
        <OCol xs={12} xl={6}>
          <OField
            label="Data do Contrato de Promessa de Cessão (DD/MM/AAAA)"
            htmlFor="originalDate"
          >
            <ORFieldInputDate
              id="originalDate"
              name="originalDate"
              className="mt-2"
            />
          </OField>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id="isBuyerGuarantee"
            name="isBuyerGuarantee"
            label="Garantia Cedente?"
            dataAction="compra_venda_fidc:select:garantia_cedente"
            dataLabel="garantia_cedente"
          >
            <OOption value="false">NÃO</OOption>
            <OOption value="true">SIM</OOption>
          </ORFieldSelect>
        </OCol>
      </ORow>
    </div>
  );
};
