import { OAvatar } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { ManagementForm } from "./management-ddis.type";

export const ddisManagementGrid: ODataGridGeneratorConfig<ManagementForm> = {
  datagrid: {
    noDataText: "Nenhuma informação",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      caption: "Bandeira",
      dataField: "icon",
      cellRender: ({ data: { icon } }) => (
        <OAvatar image={icon} alt="Bandeira do país referente à fileira" />
      ),
    },
    {
      caption: "País",
      dataField: "country",
      dataType: "string",
      format: "string",
    },
    {
      caption: "Código do País",
      dataField: "countryCode",
      dataType: "string",
      format: "string",
    },
    {
      caption: "DDI",
      dataField: "ddi",
      dataType: "number",
      alignment: "left",
    },
    {
      caption: "Máscaras",
      dataField: "masksFormatted",
      dataType: "string",
      format: "string",
    },
  ],
};
