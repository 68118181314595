import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager } from "@maestro/react";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Connection, Edge } from "reactflow";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  mapProcessorType,
  relationshipProps,
} from "../../../../../../../../components/canvas/canvas.utils";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";

import { ADD_RELATIONSHIP_MODAL_ID } from "../../config-proposal-workflow.utils";
import {
  addRelationshipFormDefaultValues,
  addRelationshipValidationSchema,
} from "./add-relationship-modal.schemas";

export const useAddRelationshipModal = () => {
  const {
    nodes,
    processorOptions,
    currentConnection,
    edges,
    setNodes,
    setEdges,
    handleRequestError,
  } = useCanvas();

  const [loading, setLoading] = useState(false);
  const [sourceProcessorId, setSourceProcessorId] = useState<number>();
  const [processorOutputs, setProcessorOutputs] =
    useState<
      Awaited<
        ReturnType<typeof service.hubCreditManager.getProcessorDetails>
      >["data"]
    >();

  const lastConnection = useRef<Connection>();

  const form = useForm({
    defaultValues: addRelationshipFormDefaultValues,
    resolver: yupResolver(addRelationshipValidationSchema),
  });
  const { setValue, getValues, trigger, resetField } = form;

  const getProcessorOutputs = useCallback(async () => {
    try {
      if (!sourceProcessorId) return;

      setLoading(true);

      const { data } = await service.hubCreditManager.getProcessorDetails(
        sourceProcessorId,
      );

      setProcessorOutputs(data);
    } catch (err) {
      handleRequestError(
        err,
        "Um erro ocorreu ao tentar buscar pelos tipos de relacionamento.",
      );
    } finally {
      setLoading(false);
    }
  }, [handleRequestError, sourceProcessorId]);

  const addRelationship = useCallback(async () => {
    if (!currentConnection) return;
    const relationshipOutputId = Number(getValues().relationshipOutputId);

    try {
      const { data: processorConfigRelationshipId } =
        await service.hubCreditManager.createProcessorConfigRelationship({
          parentProcessorConfigId: Number(currentConnection.source),
          childProcessorConfigId: Number(currentConnection.target),
          relationshipOutputId,
        });

      const output = processorOutputs?.outputs?.find(
        (out) => out.id === relationshipOutputId,
      );

      const edgeAux: Edge = {
        ...relationshipProps,
        id: String(processorConfigRelationshipId),
        source: currentConnection.source ?? "",
        target: currentConnection.target ?? "",
        label: output?.type,
      };

      setEdges([...edges, edgeAux]);

      const sourceIndex = nodes.findIndex(
        (node) => node.id === currentConnection.source,
      );

      const relationshipAsParentAux = {
        id: processorConfigRelationshipId,
        childRelationshipConfigId: Number(currentConnection.target),
        relationshipOutput: {
          description: output?.description ?? "",
          type: output?.type ?? "",
        },
      };

      const nodesAux = [...nodes];

      nodesAux[sourceIndex].data.relationshipsAsParent.push(
        relationshipAsParentAux,
      );

      setNodes(nodesAux);
    } catch (err) {
      handleRequestError(
        err,
        "Um erro ocorreu ao tentar salvar o relacionamento.",
      );
    }
  }, [
    currentConnection,
    edges,
    getValues,
    handleRequestError,
    nodes,
    processorOutputs,
    setEdges,
    setNodes,
  ]);

  const closeModal = useCallback(() => {
    modalManager.hide(ADD_RELATIONSHIP_MODAL_ID);

    resetField("relationshipOutputId");
  }, [resetField]);

  const setFormValues = useCallback(() => {
    if (!!currentConnection && !!processorOptions?.length) {
      const source = nodes.find(
        (node) => node?.data.id === Number(currentConnection?.source),
      );

      const sourceProcessor = processorOptions.find(
        (processor) => processor.id === source?.data.processor?.id,
      );
      setSourceProcessorId(sourceProcessor?.id);

      setValue("sourceName", source?.data?.name ?? "");
      setValue(
        "sourceType",
        getValueFromMap(mapProcessorType, sourceProcessor?.type) ??
          sourceProcessor?.type ??
          "",
      );

      const target = nodes.find(
        (node) => node?.data.id === Number(currentConnection?.target),
      );

      const targetProcessor = processorOptions?.find(
        (processor) => processor.id === target?.data.processor?.id,
      );
      setValue("targetName", target?.data?.name ?? "");
      setValue(
        "targetType",
        getValueFromMap(mapProcessorType, targetProcessor?.type) ??
          targetProcessor?.type ??
          "",
      );
    }
  }, [currentConnection, nodes, processorOptions, setValue]);

  useEffect(() => {
    if (!isEqual(lastConnection.current, currentConnection)) {
      lastConnection.current = currentConnection;
      setFormValues();
    }
  }, [currentConnection, setFormValues]);

  useEffect(() => {
    if (typeof sourceProcessorId === "number") getProcessorOutputs();
  }, [getProcessorOutputs, sourceProcessorId]);

  return {
    form,
    loading,
    processorOutputs,
    addRelationship,
    closeModal,
    trigger,
  };
};
