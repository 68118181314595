import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";

export const buildDataSource = (originatorIdentification?: string) =>
  dataSourceCustomStoreGenerator<HubPartners.Admin.OriginatorDetails>(() =>
    service.hubPartners
      .getEconomicGroupOriginators(originatorIdentification ?? "")
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os grupos de originador";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const clientEconomicGroupGrid: ODataGridGeneratorConfig<HubPartners.Admin.OriginatorDetails> =
  {
    datagrid: {
      noDataText: "Este originador não faz parte de um Grupo Econômico.",
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "economicGroup.name",
        caption: "Grupo Econômico / Identificador",
        calculateFilterExpression: (filterValue) => [
          ["economicGroup.name", "contains", filterValue],
          "or",
          [
            "economicGroup.identifier",
            "contains",
            filterValue.replace(/[^\w]/g, ""),
          ],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.economicGroup?.name?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {data.economicGroup?.identifier}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "officialName",
        caption: "Originador",
        width: 240,
        allowEditing: false,
        calculateFilterExpression: (filterValue) => [
          ["officialName", "contains", filterValue],
          "or",
          ["identification", "contains", filterValue.replace(/[^\w]/g, "")],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.officialName?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.identification)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "partnershipTypes",
        caption: "Tipo de parceria",
        cellRender(prop) {
          return (
            prop.data.partnershipTypes.map((x) => x.code).join(", ") || "-"
          );
        },
      },
      {
        dataField: "originatorSubTypes",
        caption: "Subtipo de originador",
        cellRender(prop) {
          return (
            prop.data.originatorSubTypes.map((x) => x.code).join(", ") || "-"
          );
        },
      },
      {
        dataField: "mainOriginator.name",
        caption: "Instituição Responsável",
        width: 250,
      },
      {
        dataField: "identifier",
        caption: "Identificador Externo",
      },
    ],
  };
