import {
  modalManager,
  OConfirmationModal,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";

export const RemoveCardModal = () => {
  const [loading, setLoading] = useState(false);
  const {
    selectedCard,
    setSelectedCard,
    form: { value: workflowFormTemplate, callService: getForm },
  } = useWorkflowFormTemplate();

  const { hide } = modalManager;

  const deleteStep = useCallback(async () => {
    try {
      if (!selectedCard) return;

      setLoading(true);

      await service.hubCreditManager.removeFormStepCard({
        formStepCardId: selectedCard.id,
      });

      OToastManager.success("Card excluído com sucesso!");
      workflowFormTemplate && getForm(workflowFormTemplate.id);
      setSelectedCard(undefined);
      hide("remove-card-modal");
    } catch {
      OToastManager.danger("Não foi possível deletar card. Tente novamente.");
    } finally {
      setLoading(false);
    }
  }, [getForm, hide, selectedCard, setSelectedCard, workflowFormTemplate]);

  return (
    <OConfirmationModal
      modalId="remove-card-modal"
      title="Tem certeza que deseja excluir este card?"
      onConfirm={deleteStep}
      loading={loading}
      className="position-absolute"
    >
      <OTypography size="md">
        Ao confirmar a exclusão não será possível reverter a ação.
      </OTypography>
    </OConfirmationModal>
  );
};
