import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { ReceivablesCustomerByStrategyIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { validationSchema } from "../_compose/formulario/onboarding-strategies-form.form";
import { FormValuesType } from "../_compose/formulario/onboarding-strategies-form.types";

export const useEditOnboardingStrategies = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;
  const { strategyId } =
    useParams<ReceivablesCustomerByStrategyIdRouteParams>();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const load = useCallback(async () => {
    try {
      if (!strategyId) return;

      setLoading(true);

      const { data } = await service.quickfin.getAdminOnboardingStrategyById(
        strategyId,
      );

      const values = {
        description: data.description,
        onboardingPriority: String(data.onboardingPriority),
        onboardingType: data.onboardingType,
        productType: data.productType,
        sacadosIds: data.sacados.map((sacado) => String(sacado.id)),
        estrategiasId: data.estrategias.map((estrategia) =>
          String(estrategia.id),
        ),
      };

      reset(values);
    } catch {
      OToastManager.danger(
        "Ocorreu um erro para buscar estratégia de onboarding.",
      );
    } finally {
      setLoading(false);
    }
  }, [reset, strategyId]);

  const onSubmit = handleSubmit(async (values) => {
    if (!strategyId) return;

    setLoading(true);

    const payload = {
      ...values,
      id: Number(strategyId),
      estrategiasId: values.estrategiasId ?? [],
    };

    try {
      await service.quickfin.updateAdminOnboardingStrategy(payload);

      OToastManager.success("Estratégia alterada com sucesso.");
      modalManager.show("update-strategies-modal");
    } catch {
      OToastManager.danger("Ocorreu um erro ao atualizar a estratégia.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    const modalClose = () =>
      navigate(routes.recebiveis.product.onboardingStrategies.path);

    const cleanUp = modalManager.on(
      "update-strategies-modal",
      "modalClose",
      modalClose,
    );

    return cleanUp;
  }, [navigate, routes]);

  return {
    form,
    loading,
    strategyId,
    onSubmit,
  };
};
