import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { workflowUserActionRequestsGrid } from "./workflow-user-action-requests.grid";

export const WorkflowUserActionRequestsPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager.getWorkflowLeadProcessorUserActions(id),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Solicitações ao cliente" />}
      actions={
        <RefreshGridButton
          onClick={() => !dataSource?.isLoading() && dataSource?.reload()}
        />
      }
    >
      <ODataGridGenerator
        grid={workflowUserActionRequestsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
