import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetDashboadChargesResponse } from "services/fidc/models/responses";
import { currencySymbol } from "utils/currency";
import { assetStatusMap, downloadBankslip } from "./view-assets.utils";

const buildDetailsLink = (slipId: string, installmentNumber: number) => {
  const baseUrl =
    corporateRouter.routes.fidc.product.bankslipEditing.assets.details.path;
  const searchParams = new URLSearchParams();
  searchParams.set("slipId", slipId);
  searchParams.set("installmentNumber", installmentNumber.toString());

  return baseUrl + "?" + searchParams.toString();
};

const grid: ODataGridGeneratorConfig<
  GetDashboadChargesResponse[number]["charges"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma parcela",
    pager: {
      infoText: "Página {0} de {1} ({2} parcelas)",
    },
    filterRow: {
      visible: true,
    },
    sorting: {
      ascendingText: "installmentNumber",
    },
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "installmentNumber",
      caption: "Parcelas",
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => assetStatusMap[data.status ?? "UNDEFINED"],
    },
    {
      dataField: "value",
      caption: "Valor da parcela",
      cellRender: ({ data }) =>
        masks.currency(data.value, currencySymbol.BRL, "."),
    },
    {
      dataField: "paidValue",
      caption: "Valor pago",
      cellRender: ({ data }) =>
        data.paidValue
          ? masks.currency(data.paidValue, currencySymbol.BRL, ".")
          : "-",
    },

    {
      dataField: "dueDate",
      caption: "Data de vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "paidDate",
      caption: "Data de pagamento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "bankSlipId",
      caption: "Bankslip Id",
    },
    {
      caption: "Ação",
      cellRender: ({ data }) =>
        data.bankSlipId &&
        (data.status !== "OVERDUE" ? (
          <IconButton
            icon={{ category: "orq", icon: "orq-download" }}
            dataAction=""
            dataLabel=""
            outline
            onClick={() => downloadBankslip(data.bankSlipId ?? "")}
          >
            Baixar boleto
          </IconButton>
        ) : (
          <StandardLink
            role={roles.fidc.product.bankslipEditingAssetsDetailsPage.role}
            href={buildDetailsLink(data?.bankSlipId, data?.installmentNumber)}
          >
            Visualizar e editar
          </StandardLink>
        )),
    },
  ],
};

type ViewAssetsMasterDetailProps = MasterDetailComponentProps<
  GetDashboadChargesResponse[number]
>;

export const ViewAssetsMasterDetail = ({
  data: {
    data: { charges },
  },
}: ViewAssetsMasterDetailProps) => {
  return <ODataGridGenerator grid={grid} dataSource={charges} />;
};
