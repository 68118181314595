import { OButton, OIcon } from "@maestro/react";

interface RefreshGridButtonProps
  extends Omit<
    React.ComponentPropsWithRef<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  dataAction?: string;
  dataLabel?: string;
}

export const RefreshGridButton = (props: RefreshGridButtonProps) => {
  return (
    <OButton
      dataAction="grid:botao:recarregar_grid"
      dataLabel="recarregar_grid"
      {...props}
      type="default"
      outline
    >
      <OIcon category="orq" icon="orq-refresh" />
    </OButton>
  );
};
