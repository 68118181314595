import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import { InputDdiPhone } from "components/input-ddi-phone";
import { SelectSearchField } from "components/select-search";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { InternacionalUserForm } from "../add-internacional-user.type";
import { basicFields } from "./basic-fields.form";
import { useBasicFields } from "./basic-fields.hook";

interface BasicFieldsProps {
  editing?: boolean;
  editingPhoneValue?: string;
}

export const BasicFields = ({
  editing,
  editingPhoneValue,
}: BasicFieldsProps) => {
  const {
    getUserDataLoading,
    disableEmail,
    phoneDefaultValue,
    countries,
    getAllCountries,
  } = useBasicFields(editing);

  useEffect(() => {
    getAllCountries();
  }, [getAllCountries]);

  const {
    setValue,
    formState: { errors },
  } = useFormContext<InternacionalUserForm>();

  const onChangePhone = useCallback(
    (value: string) => {
      setValue("user.phoneNumber", value);
    },
    [setValue],
  );

  return (
    <ORow gap={3}>
      <OCol xs={12} md={6} align="center">
        <ORFieldInput {...basicFields.identification} disabled={editing} />
        {getUserDataLoading && <OLoader size="sm" />}
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...basicFields.name} disabled={editing} />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...basicFields.email}
          disabled={editing || disableEmail}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputDdiPhone
          defaultValue={editingPhoneValue || phoneDefaultValue}
          onChange={onChangePhone}
          error={!!errors.phoneNumber}
          message={errors?.phoneNumber?.message}
          {...basicFields.phoneNumber}
          disabled={editing}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <SelectSearchField
          options={countries}
          id="user.countryCode"
          name="user.countryCode"
          label="Código do país"
          required
          placeholder="Selecionar"
        />
      </OCol>
      <OCol>
        <ORFieldSelectMultiple id="profiles" name="profiles" label="Profiles">
          <OOptionMultiple value="B2C:CorporateCash-Colaborador.Operator">
            Operador Corporate Cash - B2C:CorporateCash-Colaborador.Operator
          </OOptionMultiple>
          <OOptionMultiple value="B2C:RECEIVABLES-ASSIGNOR-OFFSHORE.Operator">
            Operador Cedente Offshore -
            B2C:RECEIVABLES-ASSIGNOR-OFFSHORE.Operator
          </OOptionMultiple>
        </ORFieldSelectMultiple>
      </OCol>
    </ORow>
  );
};
