import { datadogRum } from "@datadog/browser-rum";
import { behaviorEvents } from "contexts/behavior";

export const enrichGlobalContext = () => {
  behaviorEvents.subscribe((behavior) => {
    if (behavior.state === "ERROR") {
      datadogRum.setGlobalContextProperty("behavior", "ERROR");
    } else if (behavior.state === "LOADED") {
      datadogRum.setGlobalContextProperty("behavior", behavior.value?.id);
    }
  });
};
