import { OButton } from "@maestro/react";
import { useUploadTiers } from "../../upload-tiers.context";

type SubmitButtonProps = Omit<
  React.ComponentProps<typeof OButton>,
  "dataAction" | "dataLabel" | "children"
>;

export const SubmitButton = (props: SubmitButtonProps) => {
  const { onClick } = props;

  const { nextStep, stepNumber, submit } = useUploadTiers();

  return (
    <OButton
      dataAction={`upload-tiers-step-${stepNumber}:botao:enviar`}
      dataLabel="enviar"
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        submit();
        nextStep();
      }}
    >
      Enviar
    </OButton>
  );
};
