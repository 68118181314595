import * as yup from "yup";

export const settingsFormValidationSchema = yup.object().shape({
  executeOnSingleParentOutput: yup.array().default([]),
  leadsCanBeCreated: yup.array().default([]),
  leadsCanBeDeleted: yup.array().default([]),
  leadsCanBeExpired: yup.array().default([]),
  leadsInWorkflowGroupCanBeCreated: yup.array().default([]),
});

export const settingsFormDefaultValues = {
  executeOnSingleParentOutput: [] as string[],
  leadsCanBeCreated: [] as string[],
  leadsCanBeDeleted: [] as string[],
  leadsCanBeExpired: [] as string[],
  leadsInWorkflowGroupCanBeCreated: [] as string[],
};
