import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { ConsumerUnitForm } from "../../../../edit-consumer-unit.form";

export const useDistributorSelector = () => {
  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getDistributors,
  );

  const { clearErrors, setError } = useFormContext<ConsumerUnitForm>();

  const getDistributors = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("currentDistributorId");
    else
      setError("currentDistributorId", {
        message:
          "Erro ao buscar os status possíveis. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getDistributors();
  }, [getDistributors]);

  const options = useMemo(
    () =>
      value?.response?.map<SelectSearchFieldOption<number>>(
        ({ id, entity }) => ({
          label: `${entity.name} - ${fastCpfCnpjMask(entity.taxId)}`,
          value: id,
          customKey: id,
        }),
      ) ?? [],
    [value?.response],
  );

  return {
    getDistributors,
    loading,
    hasFetchError: hasError,
    options,
  };
};
