import { useState } from "react";
import { Panel } from "reactflow";
import {
  ChangeLogger,
  NodeInspector,
  ToolButton,
  ViewportLogger,
} from "./_compose";
import { FlowDevtools } from "./canva-devtools.styles";

export const CanvaDevtools = () => {
  const [nodeInspectorActive, setNodeInspectorActive] = useState(false);
  const [changeLoggerActive, setChangeLoggerActive] = useState(false);
  const [viewportLoggerActive, setViewportLoggerActive] = useState(false);

  return (
    <FlowDevtools>
      <Panel position="top-left">
        <ToolButton
          setActive={setNodeInspectorActive}
          active={nodeInspectorActive}
          title="Toggle Node Inspector"
        >
          Node Inspector
        </ToolButton>
        <ToolButton
          setActive={setChangeLoggerActive}
          active={changeLoggerActive}
          title="Toggle Change Logger"
        >
          Change Logger
        </ToolButton>
        <ToolButton
          setActive={setViewportLoggerActive}
          active={viewportLoggerActive}
          title="Toggle Viewport Logger"
        >
          Viewport Logger
        </ToolButton>
      </Panel>

      {changeLoggerActive && <ChangeLogger />}
      {nodeInspectorActive && <NodeInspector />}
      {viewportLoggerActive && <ViewportLogger />}
    </FlowDevtools>
  );
};
