import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./aml-disapproval.grid";

const dataSource =
  dataSourceCustomStoreGenerator<HubOnboarding.Onboarding.AMLResponseView>(() =>
    service.onboarding
      .getAMLMonitor()
      .then(({ data }) => data.value)
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar reprovações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const AmlDisapproval = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Reprovações de AML"
          description="Acompanhe os hits apontados por AML"
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
