import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { GatekeeperProfile } from "services/gatekeeper/models";

export const grid: ODataGridGeneratorConfig<GatekeeperProfile> = {
  datagrid: {
    noDataText: "Nenhum profile",
    filterRow: { visible: true },
  },
  columns: [
    {
      caption: "Detalhes",
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.ferramentas.product.gatekeeper.profiles.profile.generalInformation.path(
            { uuid: data.uuid },
          )}
        />
      ),
      allowExporting: false,
    },
    { dataField: "name", caption: "Nome" },
    { dataField: "type", caption: "Tipo" },
    { dataField: "groupName", caption: "Nome do grupo" },
    {
      dataField: "availablePermissionsDescription",
      caption: "Descrição das permissões disponíveis",
    },
    {
      dataField: "unavailablePermissionsDescription",
      caption: "Descrição das permissões não disponíveis",
    },
    {
      dataField: "isAvailableForCustomProfiles",
      caption: "isAvailableForCustomProfiles",
    },
    { dataField: "isBuiltIn", caption: "isBuiltIn" },
    {
      dataField: "roles",
      caption: "Roles",
      width: "300px",
      calculateCellValue: ({ roles }) => roles?.join("; ") ?? "",
    },
    {
      dataField: "subProfiles",
      caption: "SubProfiles",
      width: "300px",
      calculateCellValue: ({ subProfiles }) => subProfiles?.join("; ") ?? "",
    },
  ],
};
