import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CreateAdminForm } from "./create-admin.types";

export const useCreateAdmin = () => {
  const {
    callService: getUserByTaxId,
    loading: getUserLoading,
    value: user,
  } = useServiceCall(service.clerk.getUsersByTaxId);

  const { loading: getDesksLoading } = useServiceCall(
    service.clerk.getEntityDesks,
  );

  const { loading: getUserProfilesLoading } = useServiceCall(
    service.gatekeeper.getUserByCpf,
  );

  const { callService: createAdministrator, loading: submitLoading } =
    useServiceCall(service.onboarding.createAdministrator);

  const navigate = useNavigate();

  const form = useForm<CreateAdminForm>();

  const { handleSubmit, setValue, watch } = form;

  const taxIdWatch = watch("taxId");

  const autofillUser = useCallback(
    async (taxId: string) => {
      const { response } = await getUserByTaxId({
        taxId,
      });
      if (response) {
        setValue("name", response.data.name ?? "");
        setValue("email", response.data.email ?? "");
      }
    },
    [getUserByTaxId, setValue],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await createAdministrator({
          email: values.email,
          name: values.name,
          taxId: values.taxId,
          desks: values.desks,
          gatekeeper: {
            addUserProfiles: values.profiles,
          },
        });

        if (success) {
          OToastManager.success("Administrador adicionado com sucesso");
          navigate(corporateRouter.routes.usuarios.product.b2c.path);
        } else {
          OToastManager.danger("Erro ao adicionar administrador");
        }
      }),
    [createAdministrator, handleSubmit, navigate],
  );

  /** Fill form fields when user inputs a tax id */
  useEffect(() => {
    if (validators.cpf(taxIdWatch)) {
      autofillUser(taxIdWatch);
    }
  }, [autofillUser, taxIdWatch]);

  const disableEmail = user && taxIdWatch === user.cpf;

  return {
    disableEmail,
    form,
    getDesksLoading,
    getUserLoading,
    getUserProfilesLoading,
    submitLoading,
    submit,
  };
};
