import { modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { Job } from "../../jobs.types";
import { stopJobs } from "../../jobs.utils";
import { ConfirmationModal, stopMultipleModalId } from "../confirmation-modal";

interface BatchStopButtonProps {
  selectedJobs: Job[];
  reload: () => void;
}

export const BatchStopButton = ({
  selectedJobs,
  reload,
}: BatchStopButtonProps) => (
  <>
    <IconButton
      icon={{ category: "far", icon: "fa-ban" }}
      type="danger"
      disabled={!selectedJobs.length}
      onClick={() => modalManager.show(stopMultipleModalId)}
    >
      Parar selecionados
    </IconButton>
    <ConfirmationModal
      id={stopMultipleModalId}
      onConfirm={async () => {
        await stopJobs(selectedJobs.map(({ id }) => id));
        reload();
        modalManager.hide(stopMultipleModalId);
      }}
      type="stop"
      jobsAmount={selectedJobs.length}
    />
  </>
);
