import { useServiceCall } from "hooks/service-call";
import { AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes } from "pages/antecipacao-de-cartoes/routes/antecipacao-de-cartoes.route-params";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";

export const useAssignmentDetails = () => {
  const { companyId, operationId } =
    useParams<AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes>();
  if (!companyId || !operationId) throw new Error("No url parameters");

  const {
    callService,
    hasError,
    loading,
    value: assignmentDetails,
  } = useServiceCall(service.quickfin.getCompanyAssignment);

  const getAssignment = useCallback(
    () => callService(companyId, operationId),
    [callService, companyId, operationId],
  );

  useEffect(() => {
    getAssignment();
  }, [getAssignment]);

  return {
    getAssignment,
    hasError,
    loading,
    assignmentDetails,
  };
};
