import * as yup from "yup";

export type EditBookFormFields = HubPartners.Admin.Book;

export const editBooksSchema: yup.ObjectSchema<EditBookFormFields> = yup.object(
  {
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    uuid: yup.string().required("Este campo é obrigatório"),
  },
);
