import { OConfirmationModal } from "@maestro/react";

export type ConfirmationModalProps = {
  onConfirm: () => void;
  id: string;
  isConfirmationModalProcessing: boolean;
};

export const ConfirmationRepostModal = ({
  onConfirm,
  id,
  isConfirmationModalProcessing,
}: ConfirmationModalProps) => {
  return (
    <OConfirmationModal
      style={{ position: "absolute" }}
      modalId={id}
      loading={isConfirmationModalProcessing}
      title="Reenvio - Modal de Confirmação"
      confirmLabel="Sim"
      closeLabel="Cancelar"
      onConfirm={onConfirm}
    >
      <div>Você confirma a reenvio das desaverbações selecionadas?</div>
    </OConfirmationModal>
  );
};
