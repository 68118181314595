export const getFirstAndLastName = (name: string) => {
  const [firstName, ...otherNames] = name.split(" ");
  const lastName = otherNames.pop();

  const shortName = [firstName, lastName].filter(Boolean).join(" ");

  return { firstName, lastName, shortName };
};

export const getInitials = (name: string) => {
  const { firstName, lastName } = getFirstAndLastName(name);

  const firstLetter = firstName[0];
  const secondLetter = lastName?.[0] ?? "";

  return firstLetter + secondLetter;
};
