import { Behaviors } from "../types/behavior-ids";
import { BehaviorConfiguration } from "../types/config";
import { adminBehavior } from "./admin.config";
import { corbanBehavior } from "./corban.config";
import { customerServiceBehavior } from "./customer-service.config";
import { rtbBehavior } from "./rtb.config";

export const configs: Record<Behaviors, BehaviorConfiguration> = {
  [Behaviors.Admin]: adminBehavior,
  [Behaviors.CustomerService]: customerServiceBehavior,
  [Behaviors.RTB]: rtbBehavior,
  [Behaviors.Corban]: corbanBehavior,
};
