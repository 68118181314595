import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { workflowRoles } from "../../../../../roles/workflow.roles";

export const useCards = () => {
  const navigate = useNavigate();
  const { hasRole } = useRoles();

  const canEdit = hasRole(workflowRoles.product.editCampaignPage.role);
  const canAccessTemplates = hasRole(workflowRoles.product.campaignTemplatesPage.role);

  const { callService } = useServiceCall(service.hubCreditManager.createBanner);

  const handleCreateCampaign = useCallback(async () => {
    const { success, response } = await callService();
    if (success) {
      navigate(
        corporateRouter.routes.workflow.product.bannerManagement.edit.path({
          id: response.data.id,
        }),
      );
    }
  }, [callService, navigate]);

  return { handleCreateCampaign, canEdit, canAccessTemplates };
};
