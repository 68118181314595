import {
  OCard,
  OCol,
  ODivider,
  OLoader,
  ORow,
  OSkeletonCardNode,
  OTypography,
} from "@maestro/react";
import { useMemo } from "react";
import { useSimulation } from "../../simulation/simulation.context";
import { StyledCardBody } from "./info-card.styles";
import { LoadSimulationItems } from "./info-card.utils";
import { ResultInfos } from "./result-infos/result-infos.component";

export const SimulationInfoCard = () => {
  const { loading, form, hideForm } = useSimulation();

  const { watch } = form;

  const termWatch = watch("termOptions");

  const leftItems = LoadSimulationItems("left");

  const rightItems = LoadSimulationItems("right");

  const SimulationInfoCardMemo = useMemo(() => {
    return (
      <div className="mb-4">
        <OSkeletonCardNode
          loading={loading || !termWatch}
          height="305px"
          width="100%"
          rounded
        >
          <OCard className="position-relative">
            {loading && <OLoader backdrop absolute />}
            {hideForm ? (
              <div className="card-header bg-light p-4">
                <OTypography className="m-0" size="xl">
                  Valores e condições
                </OTypography>
              </div>
            ) : (
              <div className="card-header p-4">
                <OTypography
                  className="m-0"
                  size="xl"
                  key={`${hideForm}-card-detail`}
                >
                  Informações do empréstimo
                </OTypography>
              </div>
            )}
            <ODivider size="xxs" />
            <StyledCardBody className="card-body p-4">
              <ORow>
                <OCol
                  sm={12}
                  lg={6}
                  className="d-flex flex-column justify-content-between"
                >
                  <ResultInfos items={leftItems} />
                </OCol>

                <OCol
                  sm={12}
                  lg={6}
                  className="d-flex flex-column justify-content-between"
                >
                  <ResultInfos items={rightItems} />
                </OCol>
              </ORow>
            </StyledCardBody>
          </OCard>
        </OSkeletonCardNode>
      </div>
    );
  }, [termWatch, loading, hideForm, leftItems, rightItems]);

  return <>{SimulationInfoCardMemo}</>;
};
