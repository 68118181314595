import { yupResolver } from "@hookform/resolvers/yup";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostAdminCurrency } from "services/hubfx/models/requests/post-admin-currencies.request";
import { PostByIdAdminCurrency } from "services/hubfx/models/requests/post-by-id-admin-currencies.request";
import { DetailsTemplate } from "templates/details-template";
import { currencyFormValidationSchema } from "../form/exchange-product-currencies-form.schemas";
import { exchangeCurrencyDetailsGenerator } from "./exchange-product-currencies-details.utils";

export const ExchangeProductCurrenciesDetailsPage = () => {
  const { value } = useParseFromSearchParams<PostByIdAdminCurrency>("data");

  if (!value?.id) throw new Error("No id");

  const {
    callService: getCurrencies,
    hasError,
    loading,
  } = useServiceCall(service.hubFx.getCurrencies);

  const form = useForm<PostAdminCurrency>({
    resolver: yupResolver(currencyFormValidationSchema),
  });

  const { reset } = form;

  useEffect(() => {
    value && reset(value);
  }, [value, reset]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da Moeda" />}>
      <DetailsCard
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes da moeda."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getCurrencies()} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={value}
        fields={exchangeCurrencyDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
