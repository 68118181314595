import { OBadge, OIcon } from "@maestro/react";
import dayjs from "dayjs";

export interface StatusComponentProps {
  status: "PROCESSED_WITHOUT_FILE" | "FAILED" | "PROCESSED_WITH_FILE";
}

export const StatusComponent: React.FC<StatusComponentProps> = ({ status }) => {
  switch (status) {
    case "PROCESSED_WITHOUT_FILE":
      return (
        <OBadge rounded type="info-light">
          <OIcon category="orq" icon="orq-info" size="lg" />
          Período sem movimentações
        </OBadge>
      );
    case "FAILED":
      return (
        <OBadge rounded type="warning-light">
          <OIcon
            category="orq"
            icon="orq-status-warning"
            size="lg"
            type="warning"
          />
          Falha no processamento
        </OBadge>
      );
    case "PROCESSED_WITH_FILE":
      return (
        <OBadge rounded type="success-light">
          <OIcon
            category="orq"
            icon="orq-status-success"
            size="lg"
            type="success"
          />
          Processado com sucesso
        </OBadge>
      );
    default:
      return null;
  }
};

export const formatDate = (date: string, mask: string, hour: number) => {
  return dayjs
    .utc(date)
    .add(hour, "hours")
    .tz("America/Sao_Paulo")
    .format(mask);
};
