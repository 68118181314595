import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoWorkflowConfigViewById } from "../../../../../../../../routes/workflow.route-params";
import {
  editDefaultGridDefaultValues,
  EditDefaultGridFormValues,
  editDefaultGridSchema,
} from "./workflow-config-view-edit-configuration.schema";

export const useWorkflowConfigViewEditConfiguration = () => {
  const { workflowConfigViewId } =
    useParams<WorkflowProdutoWorkflowConfigViewById>();
  if (!workflowConfigViewId) throw new Error("No workflowConfigViewId");

  const form = useForm<EditDefaultGridFormValues>({
    defaultValues: editDefaultGridDefaultValues,
    resolver: yupResolver(editDefaultGridSchema),
  });
  const { setValue, handleSubmit, watch } = form;
  const defaultGridIdWatch = watch("defaultGridId");

  const {
    callService: getWorkflowConfigViewById,
    value: workflowConfigView,
    loading: loadingGetWorkflowConfigViewById,
  } = useServiceCall(service.hubCreditManager.getWorkflowConfigViewById);

  const {
    callService: getGrids,
    value: grids,
    loading: loadingGetGrids,
    hasError: hasErrorGetGrids,
  } = useServiceCall(service.hubCreditManager.getGrids);

  const {
    callService: updateWorkflowConfigViewDefaultgrid,
    loading: loadingUpdateWorkflowConfigViewDefaultGrid,
  } = useServiceCall(
    service.hubCreditManager.updateWorkflowConfigViewDefaultGrid,
  );

  const update = useCallback(
    async ({ isVisible, defaultGridId }: EditDefaultGridFormValues) => {
      const { success } = await updateWorkflowConfigViewDefaultgrid({
        isVisible: !!isVisible.length,
        defaultGridId: defaultGridId || null,
        workflowConfigViewId: Number(workflowConfigViewId),
      });

      if (success) {
        OToastManager.success("Grid padrão salvo com sucesso.");
      } else {
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar o grid padrão. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [updateWorkflowConfigViewDefaultgrid, workflowConfigViewId],
  );

  useEffect(() => {
    getWorkflowConfigViewById(workflowConfigViewId);
    getGrids();
  }, [getGrids, getWorkflowConfigViewById, workflowConfigViewId]);

  useEffect(() => {
    if (!workflowConfigView) return;

    setValue("isVisible", workflowConfigView.isVisible ? ["true"] : []);
    setValue("defaultGridId", workflowConfigView.defaultGrid?.id ?? 0);
  }, [setValue, workflowConfigView]);

  useEffect(() => {
    hasErrorGetGrids &&
      OToastManager.danger(
        "Não foi possível receber as opções de grids padrões. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorGetGrids]);

  return {
    defaultGridIdWatch,
    form,
    grids,
    loadingGetGrids,
    loadingGetWorkflowConfigViewById,
    loadingUpdateWorkflowConfigViewDefaultGrid,
    handleSubmit,
    update,
  };
};
