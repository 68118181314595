import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const buildDeskAccountConfigRelationshipGrid = (
  configIdentifier: string,
  uuid: string,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum relacionamento",
    },
    columns: [
      {
        role: roles.ferramentas.product
          .onboardingWorkflowsConfigsAccountConfigDetailsPage.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.desksConfigs.details.deskAccountConfig.details.relationships.update.path(
              {
                configIdentifier,
                uuid,
                relationshipUuid: data.uuid,
              },
            )}
          />
        ),
      },
      {
        caption: "Role",
        dataField: "lessRelationshipRole",
        dataType: "string",
      },
      {
        caption: "Tem entidade default?",
        dataField: "hasEntityDefault",
        dataType: "boolean",
      },
      {
        caption: "Master cge",
        dataField: "cgeMaster",
        dataType: "number",
      },
      {
        caption: "Master",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["nameMaster"],
          "taxIdMaster",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.nameMaster}
            taxId={data.taxIdMaster}
          />
        ),
      },
      {
        dataField: "uuid",
        dataType: "string",
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex, row }) =>
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Remover",
                  onClick: () => {
                    component.option(
                      "editing.texts.confirmDeleteMessage",
                      `Você confirma que o relacionamento '${data?.uuid}' será removida?`,
                    );
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          ),
      },
      {
        type: "buttons",
        buttons: [
          {
            hint: "Salvar",
            icon: "save",
            name: "save",
          },
          {
            hint: "Cancelar",
            icon: "undo",
            name: "cancel",
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    Awaited<
      ReturnType<
        typeof service.onboarding.getDeskOnboardingAccountConfigRelationships
      >
    >["data"]["deskOnboardingRelationshipAccountConfigs"][number]
  >);
