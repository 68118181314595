import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useSetEligibilityButton = (id: number, setEligible: boolean) => {
  const { callService, loading } = useServiceCall(
    service.eligibilityengine.setEligibility,
  );

  const setEligibility = useCallback(async () => {
    const { success } = await callService(id, {
      isEligible: setEligible,
      publish: true,
    });

    if (success)
      OToastManager.success(
        `Oferta ${setEligible ? "enviada" : "negada"} com sucesso`,
      );
    else
      OToastManager.danger(
        `Erro ao ${setEligible ? "enviar" : "negar"} a oferta`,
      );
  }, [callService, id, setEligible]);

  return {
    setEligibility,
    loading,
  };
};
