import { OButton, ODropdown, OIcon, OTypography } from "@maestro/react";
import { PaginationDataGridProps } from "./data-grid-pagination.types";
import { PaginationSizes } from "./_compose";

export const DataGridPagination = ({
  hasNext,
  hasPrevious = true,
  page,
  setPage,
  pageSize,
  setPageSize,
  pageSizes,
  handlePageSize,
  handleNext,
  handlePrevious,
  disabledIteration = false,
}: PaginationDataGridProps) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <div className="d-flex flex-row align-items-center gap-2">
        <ODropdown positionY="top">
          <OButton type="light" disabled={disabledIteration}>
            <div className="d-flex flex-row align-items-center gap-1">
              {pageSize}
              <OIcon category="orq" icon="orq-chevron-up" />
            </div>
          </OButton>
          <PaginationSizes
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageSizes={pageSizes}
            handlePageSize={handlePageSize}
          />
        </ODropdown>
        <OTypography>Linhas por página</OTypography>
      </div>
      <div className="d-flex flex-row gap-1">
        <OButton
          type="light"
          title="Página anterior"
          disabled={page === 1 || !hasPrevious || disabledIteration}
          onClick={() => {
            setPage((oldIndex) => oldIndex - 1);
            if (handlePrevious) handlePrevious(page - 1);
          }}
        >
          <OIcon category="orq" icon="orq-chevron-left" />
        </OButton>
        <OButton type="tertiary">{page}</OButton>
        <OButton
          type="light"
          title="Próxima página"
          disabled={!hasNext || disabledIteration}
          onClick={() => {
            setPage((oldIndex) => oldIndex + 1);
            if (handleNext) handleNext(page + 1);
          }}
        >
          <OIcon category="orq" icon="orq-chevron-right" />
        </OButton>
      </div>
    </div>
  );
};
