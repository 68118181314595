import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { entityScoresGrid } from "./entity-scores.grid";

export const EntityScoresPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Scores de originadores" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={entityScoresGrid} />
    </GridTemplate>
  );
};
