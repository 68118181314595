import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { GridTemplate } from "templates/grid-template";
import { MinimumCriteriaGrid } from "./minimum-criteria.grid";
import { corporateRouter } from "routes/corporate-router.context";
import { roles } from "roles/roles";

export const MinimumCriteriaColumnsPage = () => {
    return (
        <GridTemplate
            pageTitle={<PageTitle title="Critérios Mínimos" />}
            actions={
                <>
                    <RouterButton
                        role={roles.workflow.product.configurationsCreateMinimumCriteriaPage.role}
                        href={corporateRouter.routes.workflow.product.configurations.minimumCriteria.add.path}
                    >
                        Adicionar
                    </RouterButton>
                    <RefreshGridButton
                        onClick={() => {
                            !MinimumCriteriaGrid.datagrid.dataSource?.isLoading() &&
                                MinimumCriteriaGrid.datagrid.dataSource?.reload();
                        }}
                    />
                </>
            }
        >
            <ODataGridGenerator grid={MinimumCriteriaGrid} />
        </GridTemplate>
    );
};