import { useCallback, useState } from "react";
import { service } from "services";

export const useAccountBalance = () => {
  const [balance, setBalance] = useState<BankingHub.BalanceResponse>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getBalance = useCallback(async (account: string, agency: string) => {
    try {
      setLoading(true);

      const {
        data: { data },
      } = await service.bankinghub.getAccountBalance(account, agency);

      setBalance(data);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    balance,
    loading,
    hasError,
    getBalance,
  };
};
