import { OButton, OCol, OIcon, OLoader, ORow } from "@maestro/react";
import { DetailsTemplate } from "templates/details-template";
import { StatusCard } from "../status-card";
import { useStatusCarousel } from "./status-carousel.hook";
import { BigButton } from "./status-carousel.styles";

interface StatusCarouselProps {
  activeStatus: string;
  relationship: string;
  setActiveStatus: (activeStatus: string) => void;
}

export const StatusCarousel = ({
  activeStatus,
  relationship,
  setActiveStatus,
}: StatusCarouselProps) => {
  const {
    cardsCount,
    disableHideNullStatuses,
    filteredStatusList,
    firstIndex,
    hideNullStatuses,
    loading,
    onPageBackward,
    onPageForward,
    removeStatus,
    resetPreferences,
    resizedStatusList,
    rowSize,
    statusList,
  } = useStatusCarousel(activeStatus, relationship, setActiveStatus);

  if (loading) {
    return <OLoader />;
  }

  if (!resizedStatusList) {
    return <div />;
  }

  return (
    <DetailsTemplate
      actions={
        <>
          <OButton
            disabled={filteredStatusList === statusList}
            onClick={resetPreferences}
          >
            Resetar status removidos
          </OButton>
          <OButton
            disabled={disableHideNullStatuses}
            onClick={hideNullStatuses}
          >
            Esconder status vazios
          </OButton>
        </>
      }
    >
      <div className="d-flex flex-row gap-2 w-100">
        <BigButton onClick={onPageBackward} disabled={firstIndex === 0}>
          <OIcon category="orq" icon="orq-chevron-left" size="lg" />
        </BigButton>
        <ORow className="w-100">
          {resizedStatusList.map((status) => (
            <OCol xs={rowSize} key={status.status}>
              <StatusCard
                active={activeStatus === status.status}
                count={status.count}
                description={status.description}
                onSelect={() => setActiveStatus(status.status)}
                onRemove={() => removeStatus(status.status)}
                title={status.title}
              />
            </OCol>
          ))}
        </ORow>
        <BigButton
          onClick={onPageForward}
          disabled={
            !filteredStatusList ||
            firstIndex + cardsCount >= filteredStatusList.length
          }
        >
          <OIcon category="orq" icon="orq-chevron-right" size="lg" />
        </BigButton>
      </div>
    </DetailsTemplate>
  );
};
