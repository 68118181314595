import dayjs from "dayjs";
import { GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse } from "services/quickfin/models";

export interface OperationCalculatedValues {
  meanMonthlyTax: number;
  meanTerm: number;
  totalTax: number;
}

export const calculateMeanTerm = (
  trades: GetAdminCompaniesByCompanyIdOperationsByOperationIdResponse["trades"],
): OperationCalculatedValues => {
  let prodValuePeriod = 0;
  let prodValuePeriodTax = 0;
  const sumValue = trades.reduce((acc, trade) => {
    const today = dayjs().toString();
    const maturityDate = dayjs(trade.receivable.maturityDate);
    const daysDifference = maturityDate.diff(today, "day");

    prodValuePeriod += trade.maturityAmount * daysDifference;
    prodValuePeriodTax +=
      trade.maturityAmount * trade.taxaMensal * daysDifference;

    return acc + trade.maturityAmount;
  }, 0);

  const meanTerm = prodValuePeriod / sumValue;
  const meanMonthlyTax = (prodValuePeriodTax / prodValuePeriod) * 100;
  const totalTax = meanMonthlyTax * (meanTerm / 30);

  return {
    meanMonthlyTax,
    meanTerm,
    totalTax,
  };
};
