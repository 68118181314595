export const reportTopicsOptions = [
  {
    label: "Identificação do recebível",
    value: "IdentificacaoRecebivel",
  },
  { label: "Nome do Sacado", value: "NomeSacado" },
  { label: "CNPJ/CPF do Sacado", value: "IdentificacaoSacado" },
  { label: "Nome do Cedente", value: "NomeCedente" },
  { label: "CNPJ/CPF do Cedente", value: "IdentificacaoCedente" },
  { label: "Data de vencimento", value: "DataVencimento" },
  { label: "Valor vencimento", value: "ValorVencimento" },
  { label: "Valor desembolso", value: "ValorDesembolso" },
  { label: "Data de desembolso", value: "DataDesembolso" },
  { label: "Data do trade", value: "DataTrade" },
  { label: "Rebate", value: "Rebate" },
  { label: "Taxa total", value: "TaxaTotal" },
  { label: "Taxa mensal", value: "TaxaMensal" },
  {
    label: "Data de criação do recebível",
    value: "DataCriacaoRecebivel",
  },
  { label: "Status", value: "Status" },
];
