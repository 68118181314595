import {
  OCard,
  OCardBody,
  OCol,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useWorkflowConfigHierarchy } from "./workflow-config-hierarchy.hook";

export const WorkflowConfigHierarchy = () => {
  const {
    allowedChildWorkflowConfigsOptions,
    fields,
    form,
    hasError,
    loading,
    loadingEditWorkflowConfigHierarchy,
    getAllData,
    updateWorkflow,
  } = useWorkflowConfigHierarchy();
  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar configuração de workflow - Dados principais" />
      }
      actions={
        <LoadingButton
          loading={loadingEditWorkflowConfigHierarchy}
          onClick={handleSubmit(updateWorkflow)}
          disabled={hasError}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasError}
        loading={loading}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar detalhes do processador"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={getAllData} />
          </ErrorComponent>
        }
        render={() => (
          <FormProvider {...form}>
            <form spellCheck="false">
              <OCard className="justify-content-between my-3">
                <OCardBody>
                  <ORow>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <OTypography size="lg">Hierarquia</OTypography>
                    </div>
                    <OCol sm={4}>
                      <ORFieldInput
                        id="tabName"
                        name="tabName"
                        tag="text"
                        label="Título da Tab"
                        labelSize="md"
                        tooltip="Label utilizado quando o workflow faz parte de uma hierarquia. É utilizado para identificar qual workflow da hierarquia está sendo utilizado."
                        tooltipPosition="top-right"
                      />
                    </OCol>
                    <OCol sm={6}>
                      <ORFieldSelectMultiple
                        id="allowedChildWorkflowConfigs"
                        name="allowedChildWorkflowConfigs"
                        label="Workflows da Hierarquia"
                        labelSize="md"
                        tooltip="Workflows adicionais listados na home do workflow atual."
                        tooltipPosition="top-right"
                        key={allowedChildWorkflowConfigsOptions
                          ?.map((x) => x.id)
                          .join("_")}
                      >
                        {allowedChildWorkflowConfigsOptions?.map((x) => (
                          <OOptionMultiple
                            value={String(x.id)}
                            key={`child-workflow-configs-option-${x.id}`}
                          >
                            <OTypography>{x.name}</OTypography>
                          </OOptionMultiple>
                        ))}
                      </ORFieldSelectMultiple>
                    </OCol>
                  </ORow>
                </OCardBody>
              </OCard>

              {fields?.map((f) => {
                const gridTitle = `hierarchy.${f.childId}.gridTitle`;
                const tabName = `hierarchy.${f.childId}.tabName`;
                const tabTitle = `hierarchy.${f.childId}.tabTitle`;
                const tabDescription = `hierarchy.${f.childId}.tabDescription`;

                return (
                  <OCard
                    key={f.childId}
                    className="justify-content-between my-3"
                  >
                    <OCardBody>
                      <ORow>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <OTypography size="lg">{f.name}</OTypography>
                        </div>
                        <OCol sm={4}>
                          <ORFieldInput
                            id={gridTitle}
                            name={gridTitle}
                            tag="text"
                            label="Título da Grid"
                            labelSize="md"
                            tooltip="Título que aparece acima da grid. Exemplo: clientes, propostas, etc."
                            tooltipPosition="top-right"
                          />
                        </OCol>
                        <OCol sm={4}>
                          <ORFieldInput
                            id={tabTitle}
                            name={tabTitle}
                            tag="text"
                            label="Subtítulo"
                            labelSize="md"
                            tooltip="Título de apoio que aparece em cima da barra de busca."
                            tooltipPosition="top-right"
                          />
                        </OCol>
                        <OCol sm={4}>
                          <ORFieldInput
                            id={tabName}
                            name={tabName}
                            tag="text"
                            label="Título da Tab"
                            labelSize="md"
                            tooltip="Label utilizado quando o workflow faz parte de uma hierarquia. É utilizado para identificar qual workflow da hierarquia está sendo utilizado."
                            tooltipPosition="top-right"
                          />
                        </OCol>
                        <OCol sm={12}>
                          <ORFieldInput
                            id={tabDescription}
                            name={tabDescription}
                            tag="text"
                            label="Descrição Subtítulo"
                            labelSize="md"
                            tooltip="Descrição que aparece abaixo do título de apoio."
                            tooltipPosition="top-right"
                          />
                        </OCol>
                      </ORow>
                    </OCardBody>
                  </OCard>
                );
              })}
            </form>
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
