import {
  modalManager,
  OButton,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GetApiAdminConfigurableGridColumnResponseItem } from "services/hubcreditmanager/models/responses";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { DraggableCard } from "../../../configuracoes-de-workflows/[id]/editar/edit-workflow-config.styles";
import {
  ADD_CONFIGURABLE_GRID_COLUMN_MODAL,
  AddConfigurableGridColumnModal,
} from "./add-configurable-grid-column-modal.component";
import { ConfigurableGridColumnCardContent } from "./configurable-grid-column-card-content.component";
import { useEditGrid } from "./edit-grid.hook";

export const EditGridPage = () => {
  const {
    cardBeingDragged,
    cardBeingDraggedOver,
    form,
    getAllData,
    gridColumnOptions,
    gridColumnsWatch,
    hasErrorConfigurableGridColumns,
    hasErrorWorkflowGrid,
    loadingUpsertGrid,
    loadingConfigurableGridColumns,
    loadingWorkflowGrid,
    workflowGrid,
    reorderCards,
    selectedGridColumnsInOrder,
    updateGrid,
  } = useEditGrid();

  const { handleSubmit } = form;

  return (
    <>
      <DetailsTemplate
        pageTitle={<PageTitle title="Editar grid" />}
        actions={
          <>
            <OButton
              dataAction="editar-colunas-configuraveis:botao:adicionar-coluna-configuravel"
              dataLabel="adicionar-coluna-configuravel"
              onClick={() =>
                modalManager.show(ADD_CONFIGURABLE_GRID_COLUMN_MODAL)
              }
              type="dark"
              disabled={!gridColumnOptions}
              outline
            >
              Adicionar / Remover coluna(s) de monitoramento
            </OButton>
            <LoadingButton
              loading={loadingUpsertGrid}
              onClick={handleSubmit(updateGrid)}
              dataAction="editar_workflow:botao:salvar"
              dataLabel="salvar"
              disabled={!gridColumnsWatch.length}
            >
              Salvar
            </LoadingButton>
          </>
        }
      >
        <ContentTemplate
          loading={loadingConfigurableGridColumns || loadingWorkflowGrid}
          hasError={hasErrorConfigurableGridColumns || hasErrorWorkflowGrid}
          value={gridColumnsWatch?.length}
          errorComponent={
            <ErrorComponent
              messageTitle="Um erro ocorreu ao buscar pelos dados de configuração."
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={getAllData} />
            </ErrorComponent>
          }
          emptyStateComponent={
            <EmptyState messageTitle="Nenhuma coluna configurável foi adicionada no momento." />
          }
          render={() => {
            return (
              <div className="d-flex flex-column gap-2">
                <FormProvider {...form}>
                  <form className="w-100">
                    <ORFieldInput
                      id="name"
                      name="name"
                      dataAction="workflow-grid:texto:nome"
                      dataLabel="nome"
                      label="Nome"
                      labelSize="lg"
                      tag="text"
                    />
                  </form>
                </FormProvider>
                <OTypography size="lg" key={gridColumnOptions?.length}>
                  Colunas configuráveis
                </OTypography>
                {gridColumnOptions &&
                  selectedGridColumnsInOrder
                    .map(
                      (selectedGridColumnId) =>
                        gridColumnOptions.find(
                          (option) =>
                            option.id === Number(selectedGridColumnId),
                        ) as GetApiAdminConfigurableGridColumnResponseItem,
                    )
                    .map((option, index) => (
                      <DraggableCard
                        key={`configurable-grid-column-card-${option.id}`}
                        type="info-light"
                        onDragStart={() => {
                          cardBeingDragged.current = index;
                        }}
                        onDragEnter={() => {
                          cardBeingDraggedOver.current = index;
                        }}
                        onDragEnd={reorderCards}
                        draggable
                      >
                        {option && (
                          <ConfigurableGridColumnCardContent
                            option={option}
                            workflowGrid={workflowGrid}
                            refetch={getAllData}
                          />
                        )}
                      </DraggableCard>
                    ))}
              </div>
            );
          }}
        />
      </DetailsTemplate>
      <AddConfigurableGridColumnModal
        form={form}
        gridColumnOptions={gridColumnOptions}
      />
    </>
  );
};
