import { OBadge, OTypography } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { SettlementOData } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  settlementPaymentMethods,
  settlementStatusLibrary,
  settlementTintMap,
} from "../settlements.utils";

const grid = { sm: 6, md: 3, lg: 3, xl: 2 } as const;

export const buildSettlementsDetails = (
  settlement: SettlementOData,
): DetailsFields => {
  const settlementDetails = {
    settlement: {
      items: [
        {
          label: "Data do pagamento",
          value: (
            <OTypography>
              {dayjs(settlement.PaymentDate).format("DD/MM/YYYY")}
            </OTypography>
          ),
          grid,
        },
        {
          label: "Situação",
          value: (
            <OBadge
              size="sm"
              rounded
              type={
                getValueFromMap(settlementTintMap, settlement.PaymentStatus) ||
                "warning"
              }
            >
              {getValueFromMap(
                settlementStatusLibrary,
                settlement.PaymentStatus,
              ) || settlement.PaymentStatus}
            </OBadge>
          ),
          grid,
        },
      ],
    },
  };

  if (settlement.PaymentMethod) {
    settlementDetails.settlement.items.push({
      label: "Forma de pagamento",
      value: (
        <OTypography>
          {getValueFromMap(settlementPaymentMethods, settlement.PaymentMethod)}
        </OTypography>
      ),
      grid,
    });
  }

  if (settlement.PaymentBankInfo) {
    settlementDetails.settlement.items.push(
      {
        label: "Agência",
        value: settlement.PaymentBankInfo && (
          <OTypography>{settlement.PaymentBankInfo.Agency}</OTypography>
        ),
        grid,
      },
      {
        label: "Conta",
        value: settlement.PaymentBankInfo && (
          <div className="ps-2">
            <OTypography>
              {settlement.PaymentBankInfo.AccountNumber}-
              {settlement.PaymentBankInfo.AccountDigit}
            </OTypography>
          </div>
        ),
        grid,
      },
    );
  }

  return settlementDetails;
};
