import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCheckbox,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { getPayloadId } from "../../../workflow-processors.utils";
import { useEditProcessor } from "../../edit-processor.context";
import {
  generalInfoFormDefaultValues,
  generalInfoFormValidationSchema,
} from "./general-info-form.schemas";
import { ProcessorConfigGeneralInfo } from "./general-info.types";

export const GeneralInfo = () => {
  const [loading, setLoading] = useState(false);

  const {
    id,
    processor: { value: processor, callService: getProcessorDetails },
  } = useEditProcessor();

  const form = useForm<ProcessorConfigGeneralInfo>({
    resolver: yupResolver(generalInfoFormValidationSchema),
    defaultValues: generalInfoFormDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const handleSubmitGeneralInfo = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.updateProcessor({
            ...values,
            canBeWfEntry: !!values.canBeWfEntry.length,
            hasUserAction: !!values.hasUserAction.length,
            configurableStatusId: getPayloadId(values.configurableStatusId),

            id: Number(id),
          });

          getProcessorDetails(id);

          OToastManager.success("Processador editado com sucesso!");
        } catch {
          OToastManager.danger(
            "Houve um problema ao tentar editar o processador. Por favor, tente novamente mais tarde.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, getProcessorDetails],
  );

  useEffect(() => {
    if (!processor) return;

    Object.entries(processor).forEach(([key, value]) => {
      if (key === "canBeWfEntry" || key === "hasUserAction") {
        setValue(key, value ? ["1"] : []);
      } else {
        setValue(key as keyof ProcessorConfigGeneralInfo, value?.toString());
      }
    });
  }, [processor, setValue]);

  return (
    <div className="d-flex flex-column gap-4">
      {loading && <OLoader absolute backdrop />}

      <OTypography tag="h2" weight="500" size="lg">
        Dados gerais
      </OTypography>

      <FormProvider {...form}>
        <form spellCheck="false">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-4">
              <ORFieldInput
                id="type"
                name="type"
                tag="text"
                label="Tipo"
                tooltip="Ex.=NOME_PROCESSADOR"
                tooltipPosition="top-right"
                required
                labelSize="md"
                dataAction="editar_processador:texto:tipo"
                dataLabel="tipo"
              />
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                required
                labelSize="md"
                dataAction="editar_processador:texto:descricao"
                dataLabel="descricao"
              />
            </div>
            <div>
              <ORFieldInput
                id="eventName"
                name="eventName"
                tooltip="Ex.=HubCreditManagerWebApp.Domain.Aggregates.WorkflowAggregate.Events.StartProcessorNotificationEvent"
                tag="text"
                label="Nome do evento"
                labelSize="md"
                dataAction="editar_processador:texto:nome_evento"
                dataLabel="nome_evento"
              />
            </div>
            <div className="d-flex justify-content-between">
              <ORFieldCheckboxGroup
                id="canBeWfEntry"
                name="canBeWfEntry"
                dataAction="editar_processador:radio:pode_ser_entrada"
                dataLabel="pode_ser_entrada"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="wf-entry-checkbox" value="1" />
                  <OLabel htmlFor="wf-entry-checkbox">
                    Pode ser entrada de Workflow?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
              <ORFieldCheckboxGroup
                id="hasUserAction"
                name="hasUserAction"
                dataAction="editar_processador:radio:possui_acao_usuario"
                dataLabel="possui_acao_usuario"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="wf-entry-checkbox" value="1" />
                  <OLabel htmlFor="wf-entry-checkbox">
                    Possui ação de usuário?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
              <OButton
                onClick={() => {
                  handleSubmitGeneralInfo();
                }}
                dataAction="editar_processador:botao:salvar"
                dataLabel="salvar"
              >
                Salvar
              </OButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
