import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { profileManagementRequestsStatusMap } from "../../gerenciamento-de-perfis/profiles-management.utils";
import { useAdminProfileRequest } from "./admin-profile-request.hook";

export const AdminProfileRequestPage = () => {
  const {
    batchUpdate,
    batchUpdateLoading,
    dataSource,
    grid,
    gridRef,
    reprocess,
    reprocessLoading,
    request,
    selectedChanges,
    submit,
    submitLoading,
  } = useAdminProfileRequest();

  return (
    <GridTemplate
      pageTitle={
        <>
          <PageTitle title="Mudanças" description={request?.title} />
          <div className="mt-4 d-flex align-items-center gap-2">
            Status da solicitação:
            {getValueFromMap(
              profileManagementRequestsStatusMap,
              request?.status,
            ) ?? request?.status}
          </div>
        </>
      }
      actions={
        <>
          {["CONCLUIDA", "ERRO_IMPLEMENTACAO"].includes(
            request?.status ?? "",
          ) && (
            <LoadingButton
              dataAction=""
              dataLabel=""
              loading={reprocessLoading}
              onClick={reprocess}
            >
              Reprocessar
            </LoadingButton>
          )}

          {request?.status === "EM_ANALISE" && (
            <>
              <LoadingButton
                dataAction=""
                dataLabel=""
                loading={batchUpdateLoading}
                disabled={!selectedChanges?.length}
                onClick={() => batchUpdate("APROVADA")}
              >
                Aprovar selecionados
              </LoadingButton>
              <LoadingButton
                dataAction=""
                dataLabel=""
                loading={batchUpdateLoading}
                disabled={!selectedChanges?.length}
                onClick={() => batchUpdate("PENDENTE_ALTERACAO")}
                type="danger"
              >
                Reprovar selecionados
              </LoadingButton>
              <LoadingButton
                dataAction=""
                dataLabel=""
                loading={submitLoading}
                onClick={submit}
              >
                Finalizar análise
              </LoadingButton>
            </>
          )}
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
