import { useRoles } from "hooks/roles";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { roles } from "roles/roles";

const IMPERSONATED_AGENT_QUERY_PARAM = "impersonated-agent";

export const useAgentImpersonate = () => {
  const { hasRole } = useRoles();

  const allowAgentImpersonation = useMemo(
    () => hasRole(roles.energia.product.allowAgentImpersonation.role),
    [hasRole],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const impersonatedAgentFromParams = searchParams.get(
    IMPERSONATED_AGENT_QUERY_PARAM,
  );

  const form = useForm({
    defaultValues: {
      agent: impersonatedAgentFromParams,
    },
  });

  const { watch } = form;

  const agentWatch = watch("agent");

  useEffect(() => {
    if (allowAgentImpersonation && impersonatedAgentFromParams !== agentWatch) {
      if (agentWatch) {
        searchParams.set(IMPERSONATED_AGENT_QUERY_PARAM, agentWatch);
      } else {
        searchParams.delete(IMPERSONATED_AGENT_QUERY_PARAM);
      }
      setSearchParams(searchParams);
    }
  }, [
    agentWatch,
    allowAgentImpersonation,
    impersonatedAgentFromParams,
    searchParams,
    setSearchParams,
  ]);

  return {
    agentWatch,
    allowAgentImpersonation,
    form,
    impersonatedAgentFromParams,
    searchParams,
  };
};
