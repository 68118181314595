import dayjs from "dayjs";
import { DetailsFields } from "components/details-card";
import { getValueFromMap } from "utils/get-value-from-map";
import { energyTradeStatusMap, tradeProductTypeMap } from "../../utils";
import {
  approvedAmountTypeMap,
  approvedAmountMaskMap,
} from "./trade-fields.utils";
import { MappedStatusBadge } from "../mapped-status-badge";

export const tradeFields = (trade: HubEnergy.TradeResponse) =>
  ({
    trade: {
      title: "Proposta",
      items: [
        {
          label: "Produto",
          value:
            getValueFromMap(tradeProductTypeMap, trade.productType) ??
            trade.productType,
          grid: { md: 6, lg: 4 },
        },
        {
          label:
            getValueFromMap(approvedAmountTypeMap, trade.approvedAmountType) ??
            trade.approvedAmountType,
          value: (
            getValueFromMap(approvedAmountMaskMap, trade.approvedAmountType) ??
            approvedAmountMaskMap.DEFAULT
          )(trade.approvedAmount),
          grid: { md: 6, lg: 4 },
        },
        {
          label: "Status",
          value: (
            <MappedStatusBadge
              map={energyTradeStatusMap}
              status={trade.status}
            />
          ),
          grid: { md: 6, lg: 4 },
        },
        {
          label: "Data da proposta",
          value:
            trade.proposalDate &&
            dayjs(trade.proposalDate).format("DD/MM/YYYY"),
          grid: { md: 6, lg: 4 },
        },
        {
          label: "Expiração da proposta",
          value:
            trade.expirationDate &&
            dayjs(trade.expirationDate).format("DD/MM/YYYY"),
          grid: { md: 6, lg: 8 },
        },
        {
          label: "Data do inicio do contrato",
          value:
            trade.supplyStartDate &&
            dayjs(trade.supplyStartDate).format("DD/MM/YYYY"),
          grid: { md: 6, lg: 4 },
        },
        {
          label: "Data do final do contrato",
          value:
            trade.supplyEndDate &&
            dayjs(trade.supplyEndDate).format("DD/MM/YYYY"),
          grid: { md: 6, lg: 4 },
        },
      ],
    },
  } satisfies DetailsFields);
