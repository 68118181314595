import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";

interface ConcludeStepButtonProps {
  diligenceStepId: number;
  reload: () => void;
}

export const ConcludeStepButton = ({
  diligenceStepId,
  reload,
}: ConcludeStepButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.concludeDiligenceStep,
  );

  const concludeDiligenceStep = useCallback(async () => {
    const { success, error } = await callService(diligenceStepId);
    if (!success)
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Não foi possível concluir o step",
      );
    else {
      OToastManager.success("Step concluído com sucesso");
      reload();
    }
  }, [callService, diligenceStepId, reload]);

  return (
    <LoadingButton loading={loading} onClick={concludeDiligenceStep}>
      Concluir
    </LoadingButton>
  );
};
