import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import { hubEnergyErrorCodeMap } from "../../../../../../components/deprecated/error/error-code.utils";

export const getErrors = (errorResponse: HubEnergy.ValidationErrorResponse) => {
  const errorsByIndex = errorResponse.response?.failures.reduce(
    (errors, error) => {
      const indexStr = error.fieldName.match(/\d+/)?.[0];
      const index = Number(indexStr);
      const errorMessage =
        getValueFromMap(hubEnergyErrorCodeMap, error.errorCode) ??
        error.errorCode;
      // eslint-disable-next-line no-param-reassign
      errors[index] = errors[index]
        ? [...errors[index], errorMessage]
        : [errorMessage];

      return errors;
    },
    {} as Record<string, string[]>,
  );

  return (
    errorsByIndex &&
    Object.entries(errorsByIndex).map(([index, errorMessages]) => ({
      index,
      errorMessages,
    }))
  );
};

export const energyValidationResultsGrid: ODataGridGeneratorConfig<
  NonNullable<ReturnType<typeof getErrors>>[number]
> = {
  datagrid: {
    noDataText: "Nenhum erro encontrado",
    stateStoring: gridStorage("energyValidationResultsGrid"),
  },
  columns: [
    {
      dataField: "index",
      caption: "Linha",
      sortOrder: "asc",
      cellRender: ({ data }) => {
        return data.index === "NaN"
          ? "Não identificada"
          : Number(data.index) + 1;
      },
    },
    {
      dataField: "errorMessages",
      caption: "Erros",
      calculateCellValue: ({ errorMessages }) => errorMessages?.join(" "),
      cellRender: ({ data: { errorMessages } }) =>
        errorMessages.map((error) => <p key={error}>{error}</p>),
    },
  ],
};
