import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, linkGrid } from "./links.grid";

export const Links = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Vinculos" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={
        <RouterButton
          href={
            corporateRouter.routes.parceiros.product.configurations
              .productMaintenance.links.add.path
          }
          role={roles.parceiros.product.addLink.role}
        >
          Adicionar vínculo
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={linkGrid}
      />
    </GridTemplate>
  );
};
