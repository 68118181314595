import { OBadge, OButton, OIcon } from "@maestro/react";
import { StyledCount, StyledText } from "./filter-button.styles";
import { ButtonProps } from "./filter-button.types";

export const FilterButton = ({
  onClick,
  cleanFilters,
  filtersCount,
}: ButtonProps) => {
  return (
    <div className="d-flex flex-row justify-content-end mb-2 gap-2">
      {!!filtersCount && (
        <OButton onClick={cleanFilters} type="dark" bordered={false} outline>
          <div className="d-flex flex-row gap-2">
            <StyledText>Limpar filtros</StyledText>
            <OBadge
              size="xs"
              circle
              type="info"
              className="d-flex align-items-center"
            >
              <StyledCount>
                <div key="count">{filtersCount}</div>
              </StyledCount>
            </OBadge>
          </div>
        </OButton>
      )}
      <OButton outline onClick={onClick}>
        <div className="d-flex flex-row gap-2">
          <span>Filtros</span>
          <OIcon category="orq" icon="orq-tools-filter" />
        </div>
      </OButton>
    </div>
  );
};
