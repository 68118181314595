import { client } from "../client";
import { endpoints } from "../endpoints";
import { GetFinancialInstitutionsResponse } from "./models";

export const assetsService = {
  getFinancialInstitutions: () =>
    client.get<GetFinancialInstitutionsResponse>(
      endpoints.assets.financialInstitutions,
    ),
};
