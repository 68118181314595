import { OButton, OFieldError, OIcon } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { RefreshGridButton } from "components/refresh-grid-button";
import DataSource from "devextreme/data/data_source";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { energyContactsFormGrid } from "./energy-contacts-form.grid";

export interface EnergyContactsFormValues {
  contacts?: HubEnergy.ContactResponse[];
}

interface EnergyContactsFormProps {
  readonly?: boolean;
  contactsDataSource?: DataSource<HubEnergy.ContactResponse>;
}

/** @deprecated */
export const EnergyContactsForm = ({
  readonly,
  contactsDataSource,
}: EnergyContactsFormProps) => {
  const gridRef = useGridRef();

  const {
    setValue,
    formState: {
      errors: { contacts: contactsErrors },
    },
    watch,
  } = useFormContext<EnergyContactsFormValues>();

  const watchContacts = watch("contacts");

  const grid = useMemo(
    () =>
      energyContactsFormGrid((contacts) => {
        setValue("contacts", contacts);
      }, readonly),
    [readonly, setValue],
  );

  return (
    <>
      {!!contactsErrors?.message && (
        <OFieldError visible>{contactsErrors.message}</OFieldError>
      )}
      {!readonly && (
        <div className="d-flex justify-content-end my-2 gap-2">
          <OButton onClick={() => gridRef.current?.instance.addRow()}>
            <OIcon category="orq" icon="orq-add-plus" />
          </OButton>
          {contactsDataSource && (
            <RefreshGridButton onClick={() => contactsDataSource.reload()} />
          )}
        </div>
      )}
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={contactsDataSource ?? watchContacts ?? []}
      />
    </>
  );
};
