import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { LocalFormValuesType } from "./select-sacados.types";
import { DashboarOnboardingsFormValues } from "../../dashboard-onboardings.form";

export const useSelectSacados = () => {
  const [allCompanies, setAllCompanies] = useState<
    SelectSearchOption<string>[]
  >([]);

  const form = useFormContext<DashboarOnboardingsFormValues>();
  const { setValue, getValues, watch } = form;
  const watchSacados = watch("sacadoIds");

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;

  const watchInput = localWatch("inputSacados");

  const { callService: getAllCompanies, value: dataCompanies } = useServiceCall(
    service.quickfin.getAllCompanies,
  );

  useEffect(() => {
    getAllCompanies({ CompanyType: "Sacado" });
  }, [getAllCompanies]);

  useEffect(() => {
    if (!dataCompanies) return;

    const newData = dataCompanies.companies.map((d) => {
      return {
        value: String(d.id),
        label: `${d.name} (${d.identification})`,
        customKey: d.id,
        onSelect: (value: string) => {
          const sacados = getValues("sacadoIds") ?? [];

          setValue("sacadoIds", [...new Set([...sacados, Number(value)])]);
        },
      };
    });

    setAllCompanies(newData);
  }, [dataCompanies, getValues, setValue]);

  useEffect(() => {
    if (watchSacados) localSetValue("inputSacados", undefined);
  }, [localSetValue, watchSacados]);

  return {
    allCompanies,
    localForm,
    watchInput,
    setValue,
    watchSacados,
  };
};
