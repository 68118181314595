import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import {
  buildDataSource,
  workflowProposalsGrid,
} from "./workflow-proposals.grid";

export const WorkflowProposalPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();

  if (!id) throw new Error("No id");

  const gridRef = useGridRef();

  const workflowProposalsDataSource = useMemo(() => buildDataSource(id), [id]);

  return (
    <GridTemplate
      gridRef={gridRef}
      pageTitle={<PageTitle title="Grupo de propostas" />}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={workflowProposalsGrid}
        dataSource={workflowProposalsDataSource}
      />
    </GridTemplate>
  );
};
