import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTooltip } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";
import {
  EditPropertyValueModal,
  editPropertyValueModalId,
} from "../edit-property-value-modal";

interface EditPropertyButtonProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const EditPropertyButton = ({ property }: EditPropertyButtonProps) => {
  const { refresh } = useStepAnalysisContext();

  return (
    <>
      <OTooltip floating position="top-left">
        <TooltipContent>Editar</TooltipContent>
        <OButton
          type="dark"
          outline
          onClick={() =>
            modalManager.show(editPropertyValueModalId(property.id))
          }
        >
          <OIcon category="fal" icon="fa-pencil" />
        </OButton>
      </OTooltip>
      <EditPropertyValueModal property={property} reload={refresh} />
    </>
  );
};
