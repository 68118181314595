import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/system-form/system-form.form";
import { FormValuesType } from "../_compose/system-form/system-form.types";

export const useClerkSystemsDetails = (id?: number) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (!id) return;

      const { strategies } = values;

      const payload = {
        data: { entity_id: id, strategies: strategies ?? [] },
      };

      await service.clerk.setStrategyEntity(payload);

      OToastManager.success("Sistema atualizado com sucesso");

      navigate(routes.ferramentas.product.clerk.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    if (!id) return;

    const { data } = await service.clerk.getStrategyEntityById(id);

    const strategies = data.strategyProducts.map(
      (strategy) => strategy.estrategiaUniqueId,
    );

    const resetValues = {
      name: data.identification,
      strategies,
    };

    reset(resetValues);
  }, [id, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    submit,
    loading,
  };
};
