import { useServiceCall } from "hooks/service-call";
import uniqBy from "lodash/uniqBy";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { TierRelationship } from "./tiers.types";

interface TiersContext {
  cedenteOptions: {
    id: number;
    name: string;
  }[];
  getTierRelationships: (id?: string | number | undefined) => unknown;
  hasError: boolean;
  loading: boolean;
  selectedCedente: number | undefined;
  setSelectedCedente: (selected: number | undefined) => void;
  tierRelationships: TierRelationship[] | undefined;
}

const tiersContext = createContext({} as TiersContext);

interface TiersProps {
  children: React.ReactNode;
}

export const TiersProvider = ({ children }: TiersProps) => {
  const [selectedCedente, setSelectedCedente] = useState<number>();

  const {
    callService: getTierRelationships,
    hasError,
    loading,
    value: tierRelationships,
  } = useServiceCall(service.quickfin.getTierRelationships);

  useEffect(() => {
    getTierRelationships();
  }, [getTierRelationships]);

  const cedenteOptions = useMemo(
    () =>
      uniqBy(
        tierRelationships?.map((sacadoCedenteTier) => ({
          id: sacadoCedenteTier.cedenteId,
          name: sacadoCedenteTier.cedenteName,
        })),
        "id",
      ),
    [tierRelationships],
  );

  const value = useMemo(
    () => ({
      cedenteOptions,
      getTierRelationships,
      hasError,
      loading,
      selectedCedente,
      setSelectedCedente,
      tierRelationships,
    }),
    [
      cedenteOptions,
      getTierRelationships,
      hasError,
      loading,
      selectedCedente,
      tierRelationships,
    ],
  );

  return (
    <tiersContext.Provider value={value}>{children}</tiersContext.Provider>
  );
};

export const useTiers = () => useContext(tiersContext);
