import { ODropdown, ODropdownList, OInputSearch } from "@maestro/react";
import { useMemo } from "react";
import { UnrenderedWarning } from "./_compose";
import { useSelectSearch } from "./select-search.hook";
import { SelectableDropdownItem } from "./select-search.styles";
import { SelectSearchProps } from "./select-search.types";

const identity = (value?: string) => value;

export const SelectSearch = <T,>({
  id,
  name,
  options,
  value,
  placeholder,
  className,
  disabled = false,
  maxOptions,
  onClear,
  customizeText = identity,
  eager = true,
  clearInputOnSelect,
  innerProps,
}: SelectSearchProps<T>) => {
  const {
    filteredOptions,
    searchValue,
    selectedValue,
    setSearchValue,
    setSelectedValue,
  } = useSelectSearch(value, options, onClear, eager);

  const {
    onInput: onInputProp,
    className: classNameProp,
    handleClear: handleClearProp,
  } = innerProps?.input ?? {};

  const renderedOptions = useMemo(
    () =>
      filteredOptions.slice(0, maxOptions).map((option) => (
        <SelectableDropdownItem
          $selected={selectedValue === option.value}
          key={option.customKey ?? option.label}
          handleInteraction={() => {
            setSearchValue(clearInputOnSelect ? "" : option.label);
            if (!eager) {
              option.onSelect(option.value);
              setSelectedValue(option.value);
            }
          }}
        >
          {option.label}
        </SelectableDropdownItem>
      )),
    [
      clearInputOnSelect,
      eager,
      filteredOptions,
      maxOptions,
      selectedValue,
      setSearchValue,
      setSelectedValue,
    ],
  );

  return (
    <ODropdown
      floating
      {...innerProps?.dropdown}
      className={className}
      onClick={(evt) => {
        if (disabled) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        innerProps?.dropdown?.onClick?.(evt);
      }}
    >
      <OInputSearch
        id={id}
        name={name}
        {...innerProps?.input}
        disabled={disabled}
        dataAction="select-search:texto:select-search"
        dataLabel="select-search"
        autocomplete="off"
        value={customizeText(searchValue)}
        placeholder={placeholder}
        className={`flex-grow-1 ${classNameProp ?? ""}`}
        onInput={(evt) => {
          setSearchValue(evt.currentTarget.value ?? "");
          onInputProp?.(evt);
        }}
        handleClear={() => {
          setSearchValue("");
          setSelectedValue(undefined);
          onClear?.();
          handleClearProp?.();
        }}
      />
      <ODropdownList
        {...innerProps?.dropdownList}
        style={{
          maxHeight: "20rem",
          overflowY: "auto",
        }}
        className="w-100"
      >
        {renderedOptions}
        {maxOptions && filteredOptions.length > maxOptions && (
          <UnrenderedWarning />
        )}
      </ODropdownList>
    </ODropdown>
  );
};
