import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubFx.odata.getLimits>>["data"]["value"]
  >[number]
>;

export const exchangeOdataLimitsGrid = (
  mapSymbol: (currency: string | null) => string,
  filterValue?: Grid["datagrid"]["filterValue"],
) =>
  ({
    datagrid: {
      noDataText: "Nenhum limite encontrado.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar limites.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubFx.odata.adminLimitsOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
        },
      ),
    },
    columns: [
      {
        allowExporting: false,
        type: "buttons",
        role: roles.cambio.product.limitsDetailsPage.role,
        cellRender: ({ data }) =>
          data.Id && (
            <DetailsLink
              href={buildCustomerLink(
                `${data.CounterpartyIdentification}` ?? "",
                corporateRouter.routes.cambio.customer.limits.details.path({
                  id: data.Id,
                }),
              )}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "Id",
        caption: "Id",
        dataType: "number",
      },
      {
        caption: "Id da contraparte",
        dataField: "CounterpartyId",
        visible: false,
      },
      {
        caption: "Nome da contraparte",
        dataField: "CounterpartyName",
      },
      {
        caption: "Identificação",
        dataField: "CounterpartyIdentification",
        format: (value) => masks.cnpj(value),
      },
      {
        caption: "Moeda",
        dataField: "Currency",
      },
      {
        caption: "Tipo",
        dataField: "FlowType",
      },
      {
        caption: "Categoria",
        dataField: "PreTicketId",
        format: (value) => (value ? "PRE_TICKET" : "ANUAL"),
      },
      {
        caption: "Status",
        dataField: "Status",
      },
      {
        caption: "Valor disponível",
        cellRender: ({ data }) =>
          masks.currency(data.AvailableAmount, mapSymbol(data.Currency), "."),
      },
      {
        caption: "Valor total",
        cellRender: ({ data }) =>
          masks.currency(data.TotalAmount, mapSymbol(data.Currency), "."),
      },
    ],
  } satisfies Grid);
