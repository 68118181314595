import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowProdutoLeadsRouteQueryParams } from "../../../routes/workflow.route-params";

export type WorkflowConfig = Awaited<
  ReturnType<typeof service.hubCreditManager.getWorkflowConfig>
>["data"][number];

export const workflowsConfigGrid = {
  datagrid: {
    noDataText: "Nenhum workflow encontrado",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getWorkflowConfig().catch(() => {
        throw new Error(
          "Não foi possível carregar os workflows pré-configurados.",
        );
      }),
    ),
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      sortOrder: "asc",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "icon",
      caption: "Ícone",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar",
              role: roles.workflow.product.workflowsConfigDetailsEditPage.role,
              route:
                corporateRouter.routes.workflow.product.workflowsConfig.details.edit.mainData.path(
                  {
                    id: data.id,
                  },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-visibility-on" },
              label: "Leads",
              role: roles.workflow.product.leadsPage.role,
              route: `${
                corporateRouter.routes.workflow.product.leads.path
              }?data=${encodeURIComponent(
                JSON.stringify({
                  selectedWorkflowConfig: data.identification,
                } as WorkflowProdutoLeadsRouteQueryParams),
              )}`,
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<WorkflowConfig>;
