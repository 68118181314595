import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { service } from "services";
import { BankingClienteReconResultByIdRouteParams } from "../../../../routes/banking.route-params";
import { justificationFormValidationSchema } from "./reconciliation-details.form";
import { JustificationForm } from "./reconciliation-details.types";
import { buildDataSource } from "./reconciliation-details.grid";

export const useReconciliationDetails = () => {
  const { reconResultExternalId } =
    useParams<BankingClienteReconResultByIdRouteParams>();
  if (!reconResultExternalId) throw new Error("No route params");

  const detailsDataSource = useMemo(
    () => buildDataSource(reconResultExternalId),
    [reconResultExternalId],
  );

  const form = useForm<JustificationForm>({
    resolver: yupResolver(justificationFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const { hasRole } = useRoles();

  const {
    callService: _reconCallService,
    hasError: reconHasError,
    loading: reconLoading,
    value: reconResultResponse,
  } = useServiceCall(service.reconciliero.getReconciliationResultById);

  const {
    callService: _categoriesCallService,
    value: categoriesResponse,
    loading: categoriesLoading,
  } = useServiceCall(service.reconciliero.getCategories);

  const { callService: _approvalCallService, loading: approvalLoading } =
    useServiceCall(service.reconciliero.approveReconciliationResult);

  const {
    callService: _justifyReconResultCallService,
    loading: justifyReconLoading,
  } = useServiceCall(service.reconciliero.justifyReconciliationResult);

  const getReconResult = useCallback(
    () => _reconCallService(reconResultExternalId),
    [_reconCallService, reconResultExternalId],
  );

  const getCategories = useCallback(async () => {
    const { success } = await _categoriesCallService();
    if (!success)
      OToastManager.danger("Erro ao buscar categorias de reconciliação");
  }, [_categoriesCallService]);

  const showApproveCard =
    reconResultResponse?.status === "PENDING_APPROVAL" &&
    hasRole(roles.banking.product.reconciliationsApproveButton.role);

  const showJustifyButton =
    reconResultResponse?.status === "FAILED" &&
    hasRole(roles.banking.product.reconciliationsJustifyButton.role);

  useEffect(() => {
    getReconResult();
  }, [getReconResult]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (reconResultResponse) {
      reset({
        justification: reconResultResponse?.justification,
        categoryId: reconResultResponse?.categoryIdentification,
      });
    }
  }, [reconResultResponse, categoriesResponse, reset]);

  const saveJustification = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (reconResultResponse) {
          const { categoryId, justification } = values;
          const category = categoriesResponse?.find(
            ({ id }) => id === categoryId,
          );
          const categoryLabel = category?.name ?? "";

          const params = {
            category: categoryLabel,
            categoryId,
            justification,
          };

          const { success } = await _justifyReconResultCallService(
            reconResultResponse.externalId,
            params,
          );

          if (!success) OToastManager.danger("Error ao enviar justificativa");

          getReconResult();
        }
      }),
    [
      _justifyReconResultCallService,
      categoriesResponse,
      getReconResult,
      handleSubmit,
      reconResultResponse,
    ],
  );

  const sendReconApproval = useCallback(
    async (approvalStatus: boolean) => {
      if (reconResultResponse) {
        const { success } = await _approvalCallService(
          reconResultResponse.externalId,
          approvalStatus,
        );

        if (!success)
          OToastManager.danger("Erro ao alterar resultado da reconciliação");

        getReconResult();
      }
    },
    [_approvalCallService, getReconResult, reconResultResponse],
  );

  return {
    reconResult: reconResultResponse,
    categories: categoriesResponse,
    getReconResult,
    reconHasError,
    reconLoading,
    form,
    saveJustification,
    sendReconApproval,
    approvalLoading,
    justifyReconLoading,
    categoriesLoading,
    showApproveCard,
    showJustifyButton,
    detailsDataSource,
  };
};
