import { parseExcelDate } from "utils/parse-excel-date";

export const parseNumber = (n: unknown) => {
  if (typeof n === "string") return Number(n.replace(/[^0-9]+/g, ""));
  if (typeof n === "number") return n;
  return undefined;
  // throw new Error(`${n} is not a valid number`);
};

export const toBoolean = (value: unknown) =>
  typeof value === "string" && value.toLowerCase() === "sim";

export const formatExcelDate = (rawDate: unknown) => {
  if (typeof rawDate === "number")
    return new Date(parseExcelDate(rawDate)).toISOString();
  return undefined;
  // throw new Error(`${rawDate} is not a valid excel date`);
};

type FilteredObject<T> = {
  [K in keyof T]?: NonNullable<T[K]>;
};

export const filterValues = <T extends Record<string, unknown>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_k, v]) => typeof v !== "undefined" && v !== null,
    ),
  ) as FilteredObject<T>;
