import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { Node, Edge } from "reactflow";
import { service } from "services";
import {
  GetEligibilityGroupProductByProductKindResponse,
  GetProductByProductKindVariantResponse,
  PostEligibilityGroupBody,
  PutEligibilityGroupByGroupIdBody,
} from "services/eligibilityengine/models";
import {
  CriteriaType,
  ProductKind,
} from "services/eligibilityengine/models/types/eligibility-criteria.types";
import {
  NewTreeNodeData,
  newTreeNodeProps,
} from "../../../../components/flow-container/new-tree-node";

import { editEligibilityGroupModalId } from "./_compose/edit-eligibility-group-modal/edit-eligibility-group-modal.types";
import { EligibilityGroup } from "./eligibility-tree.types";
import {
  eligibilityGroupListToTrees,
  findRoot,
  generateNewNode,
  hasAnyEmptyEligibilityCriteria,
  inactivateNodeAndChildren,
  removeTemporaryIdsFromTreeNodes,
} from "./eligibility-tree.utils";
import {
  EligibilityGroupNodeData,
  eligibilityGroupNodeProps,
} from "../../../../components/flow-container/eligibility-group-node";

interface EligibilityTreeContext {
  chart: { nodes: Node[]; edges: Edge[] };
  criteria: CriteriaType[];
  criteriaHasError: boolean;
  criteriaLoading: boolean;
  discardChanges: () => Promise<void>;
  editingGroup: EligibilityGroup | undefined;
  form: UseFormReturn<{ variant: string }>;
  getCriteria: () => Promise<unknown>;
  getEligibilityGroups: () => Promise<void>;
  getVariants: () => Promise<void>;
  groups: GetEligibilityGroupProductByProductKindResponse | undefined;
  groupsHasError: boolean;
  groupsLoading: boolean;
  hasUnsavedChanges: boolean;
  removeGroupAndChildren: (eligibilityGroupId: number) => void;
  replaceNodeInTree: (eligibilityGroup: EligibilityGroup) => void;
  saveChanges: () => Promise<void>;
  variants: GetProductByProductKindVariantResponse | undefined;
  variantsHasError: boolean;
  variantsLoading: boolean;
}

const eligibilityTreeContext = createContext({} as EligibilityTreeContext);

export interface EligibilityTreeProviderProps {
  productKind: ProductKind;
  children: React.ReactNode;
}

export const EligibilityTreeProvider = ({
  productKind,
  children,
}: EligibilityTreeProviderProps) => {
  const [chart, setChart] = useState<{ nodes: Node[]; edges: Edge[] }>({
    nodes: [],
    edges: [],
  });
  const [trees, setTrees] = useState<Record<string, EligibilityGroup[]>>({});
  const [editingGroup, setEditingGroup] = useState<EligibilityGroup>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [editedGroups, setEditedGroups] = useState<number[]>([]);

  const {
    callService: _variantsCallService,
    hasError: variantsHasError,
    loading: variantsLoading,
    value: variants,
  } = useServiceCall(service.eligibilityengine.getVariants);

  const {
    callService: _getEligibilityGroupsCallService,
    hasError: groupsHasError,
    loading: groupsLoading,
    value: groups,
  } = useServiceCall(service.eligibilityengine.getEligibilityGroups);

  const {
    callService: getCriteria,
    hasError: criteriaHasError,
    loading: criteriaLoading,
    value: criteria,
  } = useServiceCall(service.eligibilityengine.getCriteria);

  const form = useForm<{ variant: string }>();

  const { clearErrors, setError, watch } = form;

  const variantWatcher = watch("variant");

  const getVariants = useCallback(async () => {
    const { success } = await _variantsCallService(productKind);
    if (success) clearErrors("variant");
    else
      setError("variant", {
        message:
          "Erro ao buscar as variantes. Clique no botão ao lado para tentar novamente.",
      });
  }, [_variantsCallService, clearErrors, productKind, setError]);

  const getEligibilityGroups = useCallback(async () => {
    const { success } = await _getEligibilityGroupsCallService(productKind);
    if (!success)
      OToastManager.danger("Erro ao buscar os grupos de elegibilidade");
  }, [_getEligibilityGroupsCallService, productKind]);

  const editTrees = useCallback(
    (arg: React.SetStateAction<Record<string, EligibilityGroup[]>>) => {
      setHasUnsavedChanges(true);
      return setTrees(arg);
    },
    [],
  );

  const discardChanges = useCallback(async () => {
    await getEligibilityGroups();
    setHasUnsavedChanges(false);
    setEditedGroups([]);
  }, [getEligibilityGroups]);

  const removeGroupAndChildren = useCallback(
    (eligibilityGroupId: number) => {
      editTrees((_trees) => {
        const updatedTree = inactivateNodeAndChildren(
          eligibilityGroupId,
          _trees[variantWatcher],
        );
        const updatedTrees = { ..._trees, [variantWatcher]: updatedTree };

        if (editingGroup) {
          const { id: rootId } = findRoot(
            updatedTrees[variantWatcher],
            editingGroup,
          );
          setEditedGroups((_editedGroups) => [
            ..._editedGroups.filter((treeId) => treeId !== rootId),
            rootId,
          ]);
        }

        return updatedTrees;
      });
    },
    [editTrees, editingGroup, variantWatcher],
  );

  const replaceNodeInTree = useCallback(
    (eligibilityGroup: EligibilityGroup) => {
      editTrees((_trees) => {
        const updatedTree = [
          ..._trees[variantWatcher].filter(
            (group) => group.id !== eligibilityGroup.id,
          ),
          eligibilityGroup,
        ];
        const updatedTrees = { ..._trees, [variantWatcher]: updatedTree };

        const root = findRoot(updatedTrees[variantWatcher], eligibilityGroup);
        if (!root.isNewlyCreated) {
          setEditedGroups((_editedGroups) => [
            ..._editedGroups.filter((treeId) => treeId !== root.id),
            root.id,
          ]);
        }

        return updatedTrees;
      });
    },
    [editTrees, variantWatcher],
  );

  const saveEachGroup = useCallback(
    async (allTrees: EligibilityGroup[]) => {
      const newTreesPromises = allTrees
        .filter((tree) => tree.isNewlyCreated)
        .map((tree) => {
          const newTree = removeTemporaryIdsFromTreeNodes(tree);
          return service.eligibilityengine.createGroup(
            newTree as PostEligibilityGroupBody,
          );
        });

      const editedTreesPromises = editedGroups.map((id) => {
        const editedTree = allTrees.find((x) => x.id === id);
        if (editedTree) {
          const editedTreeWithoutTempIds =
            removeTemporaryIdsFromTreeNodes(editedTree);
          return service.eligibilityengine.editGroup(
            id,
            editedTreeWithoutTempIds as PutEligibilityGroupByGroupIdBody,
          );
        }
        throw new Error("Edited tree not found");
      });

      await Promise.all([...newTreesPromises, ...editedTreesPromises]);
    },
    [editedGroups],
  );

  const saveChanges = useCallback(async () => {
    try {
      if (Object.values(trees).some((tree) => tree.length === 0)) {
        OToastManager.danger("Cada produto deve ter pelo menos uma árvore");
        return;
      }

      const allGroups = Object.values(trees)
        .flat()
        .filter((group) => !(group.isNewlyCreated && !group.active));

      if (hasAnyEmptyEligibilityCriteria(allGroups)) {
        OToastManager.danger("Existem grupos sem critérios!");
        return;
      }

      const allTrees = eligibilityGroupListToTrees(allGroups);
      await saveEachGroup(allTrees);
      await getEligibilityGroups();
      setHasUnsavedChanges(false);
      setEditedGroups([]);

      OToastManager.success("Grupos alterados com sucesso");
    } catch (error) {
      OToastManager.danger("Erro ao salvar alterações");
    }
  }, [getEligibilityGroups, saveEachGroup, trees]);

  /**
   * "Criar nova árvore"
   */
  const handleNewGroup = useCallback(
    (parentId?: string) => {
      const parentIdParam = parentId ? Number(parentId) : undefined;
      const variantIdParam = Number(variantWatcher);
      const newNode = generateNewNode(variantIdParam, parentIdParam);

      editTrees((_oldTrees) => ({
        ..._oldTrees,
        [variantWatcher]: [..._oldTrees[variantWatcher], newNode],
      }));
      setEditingGroup({ ...newNode });
      modalManager.show(editEligibilityGroupModalId);
    },
    [editTrees, variantWatcher],
  );

  /**
   * Edit eligibility group
   */
  const handleEligibilityGroupCriteria = useCallback(
    (nodeId: string) => {
      if (nodeId && trees[variantWatcher]) {
        const [groupToBeEdited] = trees[variantWatcher].filter(
          (group) => group.id === Number(nodeId),
        );

        setEditingGroup({ ...groupToBeEdited });
        modalManager.show(editEligibilityGroupModalId);
      }
    },
    [trees, variantWatcher],
  );

  useEffect(() => {
    getVariants();
  }, [getVariants]);

  useEffect(() => {
    getEligibilityGroups();
  }, [getEligibilityGroups]);

  useEffect(() => {
    getCriteria();
  }, [getCriteria]);

  useEffect(() => {
    if (groups) {
      setTrees(groups as Record<string, EligibilityGroup[]>);
    }
  }, [groups]);

  useEffect(() => {
    if (trees?.[variantWatcher]) {
      const nodes: Node<EligibilityGroupNodeData | NewTreeNodeData>[] = trees[
        variantWatcher
      ]
        .filter((group) => group.active)
        .map((group: EligibilityGroup) => ({
          id: `${group.id}`,
          data: {
            label: group.groupName,
            variantId: variantWatcher,
            handleCreateChild: handleNewGroup,
            handleEditGroup: handleEligibilityGroupCriteria,
          },
          ...eligibilityGroupNodeProps,
        }));

      const edges: Edge[] = trees[variantWatcher]
        .filter((group: EligibilityGroup) => group.parentId && group.active)
        .map((group: EligibilityGroup) => ({
          id: `${group.parentId}-${group.id}`,
          source: `${group.parentId}`,
          target: `${group.id}`,
          type: "smoothstep",
          animated: true,
        }));

      const addNewTreeNode = {
        data: { handleNewTree: handleNewGroup },
        ...newTreeNodeProps,
      };

      nodes.push(addNewTreeNode);

      setChart({ nodes, edges });
    }
  }, [handleEligibilityGroupCriteria, handleNewGroup, trees, variantWatcher]);

  const value = useMemo(
    () => ({
      chart,
      criteria: criteria ?? [],
      criteriaHasError,
      criteriaLoading,
      discardChanges,
      editingGroup,
      form,
      getCriteria,
      getEligibilityGroups,
      getVariants,
      groups,
      groupsHasError,
      groupsLoading,
      hasUnsavedChanges,
      removeGroupAndChildren,
      replaceNodeInTree,
      saveChanges,
      variants,
      variantsHasError,
      variantsLoading,
    }),
    [
      chart,
      criteria,
      criteriaHasError,
      criteriaLoading,
      discardChanges,
      editingGroup,
      form,
      getCriteria,
      getEligibilityGroups,
      getVariants,
      groups,
      groupsHasError,
      groupsLoading,
      hasUnsavedChanges,
      removeGroupAndChildren,
      replaceNodeInTree,
      saveChanges,
      variants,
      variantsHasError,
      variantsLoading,
    ],
  );

  return (
    <eligibilityTreeContext.Provider value={value}>
      {children}
    </eligibilityTreeContext.Provider>
  );
};

export const useEligibilityTree = () => useContext(eligibilityTreeContext);
