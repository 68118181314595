import { CardStatus } from "../../cards.types";
import { ICardInfo, ISelectModalOptions } from "./description.types";

export const SelectModalOptions: Array<ISelectModalOptions> = [
  {
    label: "Bloqueio Preventivo",
    value: CardStatus.Fraud,
  },
  {
    label: "Bloqueio por Danos",
    value: CardStatus.Damaged,
  },
  {
    label: "Bloqueio por Perda",
    value: CardStatus.Lost,
  },
  {
    label: "Bloqueio por Roubo",
    value: CardStatus.Robbed,
  },
];

export const PersonalCardInfo: Array<ICardInfo> = [
  {
    key: "owner",
    subKey: "name",
    id: "owner",
    label: "Proprietário",
    dataRef: "cardData",
  },
  {
    key: "printedName",
    id: "printedName",
    label: "Nome impresso no cartão",
    dataRef: "cardData",
  },
  {
    key: "modality",
    id: "modality",
    label: "Modalidade",
    dataRef: "cardData",
  },
  {
    key: "type",
    id: "type",
    label: "Tipo",
    dataRef: "cardData",
  },
  {
    key: "activatedAt",
    id: "activatedAt",
    label: "Data de Ativação",
    dataRef: "cardData",
  },
];

export const AdressesCardInfo: Array<ICardInfo> = [
  {
    key: "deliveryAddress",
    id: "address",
    label: "Residencial",
    dataRef: "cardData",
  },
];

export const blockedCardStatuses = [
  CardStatus.Blocked,
  CardStatus.Damaged,
  CardStatus.Lost,
  CardStatus.Robbed,
  CardStatus.Fraud,
  CardStatus.Canceled,
];
