import React, { useCallback } from "react";

import { OToastManager } from "@maestro/react";

import { IconButton } from "components/icon-button";

interface CopyFieldProps {
  content: string;
  className?: string;
  style?: { [key: string]: any };
}

export const CopyButton: React.FC<CopyFieldProps> = ({ content }) => {
  const handleCopy = useCallback(() => {
    const Input = document.createElement("input");
    Input.id = "inputWithContentToBeCopied";
    Input.value = content;
    document.body.appendChild(Input);
    const InputWithContentToBeCopied = document.getElementById(
      "inputWithContentToBeCopied",
    ) as HTMLInputElement;
    InputWithContentToBeCopied?.select();
    document.execCommand("Copy");
    document.body.removeChild(Input);
    OToastManager.info("Conteúdo copiado!");
  }, [content]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-edit-copy" }}
      type="tertiary"
      outline
      onClick={() => handleCopy?.()}
    >
      Copiar link tagueado
    </IconButton>
  );
};
