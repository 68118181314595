import { stringifyParamsSerializer } from "utils/stringify-params-serializer";
import { client } from "../client";
import { eligibilityengineEndpoints } from "./eligibilityengine.endpoints";
import {
  GetOfferParams,
  PatchOfferByIdBody,
  PostEligibilityGroupBody,
  PutEligibilityGroupByGroupIdBody,
} from "./models/requests";
import {
  GetCriteriaTypesResponse,
  GetEligibilityGroupProductByProductKindResponse,
  GetOfferResponse,
  GetOfferSnapshotResponse,
  GetProductByProductKindVariantResponse,
} from "./models/responses";

export const eligibilityengineService = {
  getOffers(params?: GetOfferParams) {
    return client.get<GetOfferResponse>(
      eligibilityengineEndpoints.offer.endpoint,
      {
        params,
        paramsSerializer: {
          serialize: stringifyParamsSerializer(true),
        },
      },
    );
  },
  getOfferSnapshot(offerId: string | number) {
    return client.get<GetOfferSnapshotResponse>(
      eligibilityengineEndpoints.offer.snapshot.byId(offerId),
    );
  },
  setEligibility(id: string | number, body: PatchOfferByIdBody) {
    return client.patch(eligibilityengineEndpoints.offer.byId(id), body);
  },
  reprocessEligibility(file: File) {
    const dataForm = new FormData();
    dataForm.append("formFile", file);

    return client.post(
      eligibilityengineEndpoints.contingency.eligibility,
      dataForm,
    );
  },
  intubateDebitCardOffers(file: File) {
    const dataForm = new FormData();
    dataForm.append("formFile", file);

    return client.post(
      eligibilityengineEndpoints.eligibility.product.debit,
      dataForm,
    );
  },
  getVariants(productKind: "CREDIT" | "DEBIT" | "CREDIT_INVESTFLEX") {
    return client.get<GetProductByProductKindVariantResponse>(
      eligibilityengineEndpoints.product.byProductKind.variant(productKind),
    );
  },
  getEligibilityGroups(productKind: "CREDIT" | "DEBIT" | "CREDIT_INVESTFLEX") {
    return client.get<GetEligibilityGroupProductByProductKindResponse>(
      eligibilityengineEndpoints.eligibility.group.product.byProductKind(
        productKind,
      ),
    );
  },
  getCriteria() {
    return client.get<GetCriteriaTypesResponse>(
      eligibilityengineEndpoints.criteria.types,
    );
  },
  createGroup(body: PostEligibilityGroupBody) {
    return client.post(
      eligibilityengineEndpoints.eligibility.group.endpoint,
      body,
    );
  },
  editGroup(groupId: string | number, body: PutEligibilityGroupByGroupIdBody) {
    return client.put(
      eligibilityengineEndpoints.eligibility.group.byGroupId(groupId),
      body,
    );
  },
};
