import { OTypography } from "@maestro/react";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";

const gridServices: ODataGridGeneratorConfig<HubOnboarding.Onboarding.AccountServices> =
  {
    datagrid: {
      noDataText: "Nenhum serviço",
      pager: {
        showInfo: true,
        infoText: "Página {0} de {1} ({2} serviços)",
      },
      paging: {
        pageSize: 10,
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      { dataField: "id", caption: "Id" },
      { dataField: "name", caption: "Nome" },
    ],
  };

const gridProducts: ODataGridGeneratorConfig<HubOnboarding.Onboarding.AccountProducts> =
  {
    datagrid: {
      noDataText: "Nenhum produto",
      pager: {
        showInfo: true,
        infoText: "Página {0} de {1} ({2} produtos)",
      },
      paging: {
        pageSize: 10,
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      { dataField: "id", caption: "Id" },
      { dataField: "name", caption: "Nome" },
    ],
  };

interface OpenAccountsMasterDetailProps {
  data: {
    data: {
      accountServices: HubOnboarding.Onboarding.AccountServices[];
      accountProducts: HubOnboarding.Onboarding.AccountProducts[];
    };
  };
}

export const OpenAccountsMasterDetail = ({
  data: {
    data: { accountServices, accountProducts },
  },
}: OpenAccountsMasterDetailProps) => {
  return (
    <>
      <div>
        <OTypography type="dark" size="lg" className="mb-3">
          Produtos
        </OTypography>
        <ODataGridGenerator grid={gridProducts} dataSource={accountProducts} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <OTypography type="dark" size="lg" className="mb-3">
          Serviços
        </OTypography>
        <ODataGridGenerator grid={gridServices} dataSource={accountServices} />
      </div>
    </>
  );
};
