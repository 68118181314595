import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetPipelineConfigurationList } from "services/onboarding/models/responses/get-pipeline-configuration.response";

export const useSemaphoreStepForm = () => {
  const [loading, setLoading] = useState(false);
  const [allPipelines, setAllPipelines] =
    useState<GetPipelineConfigurationList>([]);

  const form = useFormContext();
  const { watch, setValue } = form;

  const watchInput = watch("pipelineUuid");
  const watchShouldStopOnRed = watch("shouldStopOnRed");
  const watchShouldStopOnGreen = watch("shouldStopOnGreen");
  const watchShouldStopOnYellow = watch("shouldStopOnYellow");

  const setStopValidation = (name: string, value: boolean) => {
    setValue(name, value);
  };

  const setInput = (event: any) => {
    setValue("pipelineUuid", event.nativeEvent.detail);
  };

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.getPipelineConfigurationList();

      setAllPipelines(data);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return {
    loading,
    allPipelines,
    watchInput,
    watchShouldStopOnRed,
    watchShouldStopOnGreen,
    watchShouldStopOnYellow,
    setStopValidation,
    setInput,
  };
};
