import { OLoader } from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { PostAdminAccountopeningGetWorkflowsItem } from "services/onboarding-core-account/models";
import { corporateRouter } from "routes/corporate-router.context";
import { useNavigate } from "react-router-dom";
import { useGridActions } from "./grid-actions.hook";
import { cadastroRoles } from "pages/cadastro/roles/cadastro.roles";

interface GridActionsProps {
  contract: PostAdminAccountopeningGetWorkflowsItem;
}

export const GridActions = ({ contract }: GridActionsProps) => {
  const { completeWorkflow, reprocessWorkflow, loading } =
    useGridActions(contract);
  const { routes } = corporateRouter;
  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <OLoader size="sm" />
      ) : (
        <DataGridAction
          actions={[
            {
              label: "Gerar Termo",
              icon: { category: "fa", icon: "fa-file-certificate" },
              onClick: () => completeWorkflow(),
            },
            {
              label: "Reenviar Contas",
              icon: { category: "orq", icon: "orq-refresh" },
              onClick: () => reprocessWorkflow(),
            },
            {
              label: "Ver Detalhes",
              icon: { category: "orq", icon: "orq-tools-analysis" },
              role: cadastroRoles.customer.checkLogsAccountOpening.role,
              onClick: () =>
                navigate(
                  routes.cadastro.customer.btgAccounts.optin.masterDetails.path(
                    {
                      workflowUuid: contract.uuid,
                    },
                  ),
                ),
            },
          ]}
        />
      )}
    </>
  );
};
