import { masks } from "@maestro/utils";

export const maskIdentification = (value: string | undefined) => {
  if (Number.isNaN(Number(value))) return value;
  return masks.cpfCnpj(value);
};

export const maskIdentificationByCountry = (
  identification: string,
  country: string | null,
) => {
  if (country === "BR") return masks.cpfCnpj(identification);
  return identification;
};
