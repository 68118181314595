export interface FilterOptionProps {
  [id: string]: string;
}

export const filterOptions: FilterOptionProps = {
  Pago: "[4, 13]",
  Agendado: "[3, 2, 9, 11, 15]",
  "Aprovação Pendente": "[14]",
  "Aguardando Análise": "[1, 7, 8, 18]",
  Cancelado: "[6, 16]",
  Erro: "[10, 12, 5]",
};

export interface PaymentsFilterFields {
  startDate?: string;
  endDate?: string;
  status?: string;
  referenciaCnab?: string;
}

export interface PaymentBodyProps {
  onFilter: (filters: PaymentsFilterFields) => void;
}

export interface PaymentsFilters {
  startDate?: string;
  endDate?: string;
  status?: number[];
  referenciaCnab?: string;
}

export const oneMonthAgoInMs = 30 * 24 * 60 * 60 * 1000;

export interface PaymentLineFilter {
  name?: string;
  cpf?: string;
  status?: number[];
}
