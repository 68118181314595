import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, deskConfigsGrid } from "./desk-configs.grid";

export const DeskConfigsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configurações de mesa" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.ferramentas.product.onboarding
              .workflowsConfigs.desksConfigs.register.path
          }
          role={
            roles.ferramentas.product
              .onboardingWorkflowsConfigsDeskConfigsRegisterPage.role
          }
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={deskConfigsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
