import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services";
import { EnergyDistributorDiligenceFormValues } from "./field-form.model";

export const useEnergyDistributorDiligenceFieldsForm = () => {
  const form = useFormContext<EnergyDistributorDiligenceFormValues>();

  const {
    control,
    getValues,
    formState: {
      errors: { diligenceStepConfigIds },
    },
  } = form;

  const { append, remove, fields } = useFieldArray({
    control,
    name: "diligenceStepConfigIds",
  });

  const appendField = useCallback(() => append({ id: 0 }), [append]);

  const {
    callService: getDiligenceStepConfig,
    loading: diligenceStepConfigLoading,
    value: diligenceStepConfigValues,
  } = useServiceCall(service.hubEnergy.getDiligenceStepConfig);

  useEffect(() => {
    getDiligenceStepConfig();
  }, [getDiligenceStepConfig]);

  return {
    diligenceStepConfigIds,
    diligenceStepConfigLoading,
    diligenceStepConfigValues,
    fields,
    appendField,
    getValues,
    remove,
  };
};
