import { CustomerCommand } from "./types/command";
import { customer } from "./customer";

class CustomerManager {
  private customer = customer;

  async execute(command: CustomerCommand) {
    await command.execute(this.customer);
  }
}

export const customerManager = new CustomerManager();
