import { modalManager, OToastManager } from "@maestro/core";
import { OConfirmationModal } from "@maestro/react";
import { AxiosError } from "axios";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";
import moment from "moment";
import { useCallback, useState } from "react";
import { client } from "../../../../../../services/client";
import { endpoints } from "../../utils/endpoints";
import { CardSection } from "../already-approved/already-approved.styles";
import {
  MapperCardModalityAndType,
  MapperCardStatus,
} from "../already-approved/already-approved.types";
import { CardStatus } from "../cards.types";
import { capitalizePhraseFirstLetters } from "../utils/cases";
import {
  ICellRenderParams,
  IVirtualCardItem,
  IVirtualsProps,
} from "./virtuals.types";

const CARD_VIRTUAL_CONFIRMATION_MODAL_ID = "card-virtual-confirmation-modal-id";
const CARD_TEMPORARY_BLOCK_MODAL_ID =
  "card-temporary-block-confirmation-modal-id";
export const Virtuals = ({
  virtualCardItemsData,
  selectedCompany,
  loadCards,
}: IVirtualsProps) => {
  const [virtualCardSelected, setVirtualCardSelected] =
    useState<IVirtualCardItem>();

  const [isConfirmationModalProcessing, setIsConfirmationModalProcessing] =
    useState(false);

  const processCardUnblock = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.delete(
          endpoints.bankinghub.deleteTemporaryBlocks(id),
          {
            headers: {
              "x-identification": selectedCompany,
            },
          },
        );
        if (status === 200) {
          loadCards();
        } else if (status === 401) {
          OToastManager.danger(
            "Erro ao Desbloquear o cartão virtual, sem autorização",
          );
        }
      } catch (error) {
        OToastManager.danger(
          "Erro ao Desbloquear o cartão virtual, tente novamente",
        );
      } finally {
        setVirtualCardSelected(undefined);
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, loadCards],
  );

  const processCardTemporaryBlock = useCallback(
    async (id: string) => {
      try {
        setIsConfirmationModalProcessing(true);
        if (!selectedCompany) return;
        const { status } = await client.post(
          endpoints.bankinghub.postTemporaryBlock(id),
          {},
          {
            headers: {
              "x-identification": selectedCompany,
              "content-type": "application/json",
            },
          },
        );
        if (status === 200) {
          loadCards();
        }
      } catch (error) {
        const status = (error as AxiosError<unknown>)?.response?.status;
        if (status === 401) {
          OToastManager.danger(
            "Erro ao Bloquear temporariamente o cartão, sem autorização",
          );
          return;
        }
        OToastManager.danger(
          "Erro ao Bloquear temporariamente o cartão, tente novamente",
        );
      } finally {
        setIsConfirmationModalProcessing(false);
      }
    },
    [selectedCompany, loadCards],
  );

  return (
    <CardSection>
      <OConfirmationModal
        modalId={CARD_VIRTUAL_CONFIRMATION_MODAL_ID}
        title="Confirmar desbloqueio"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          if (!virtualCardSelected) return;
          processCardUnblock(virtualCardSelected.id);
        }}
      >
        <span>
          Tem certeza que deseja desbloquear o cartão com final{" "}
          <strong>{virtualCardSelected?.last4Digits}</strong>.
        </span>
      </OConfirmationModal>
      <OConfirmationModal
        modalId={CARD_TEMPORARY_BLOCK_MODAL_ID}
        title="Confirmar bloqueio temporário"
        confirmLabel="Continuar"
        loading={isConfirmationModalProcessing}
        onConfirm={() => {
          if (!virtualCardSelected) return;
          processCardTemporaryBlock(virtualCardSelected.id);
        }}
      >
        <span>
          Tem certeza que deseja bloquear o cartão com final{" "}
          <strong>{virtualCardSelected?.last4Digits}</strong> temporariamente?
        </span>
      </OConfirmationModal>
      <DataGrid
        id="grid-container-virtuals"
        keyExpr="id"
        width="100%"
        rowAlternationEnabled={!virtualCardSelected}
        selectedRowKeys={[virtualCardSelected?.id]}
        dataSource={virtualCardItemsData}
        showColumnLines
        showRowLines
        noDataText="Nenhuma informação de Cartões virtuais encontrada."
        columnAutoWidth
        scrolling={{
          showScrollbar: "always",
        }}
        headerFilter={{
          visible: true,
        }}
        pager={{
          showInfo: true,
          visible: true,
          allowedPageSizes: [10, 20, 30],
          showPageSizeSelector: true,
        }}
      >
        <FilterRow visible />
        <Paging defaultPageSize={10} />
        <Column
          caption="Proprietário"
          allowHeaderFiltering={false}
          dataType="string"
          dataField="ownerName"
          cellRender={({ data }: ICellRenderParams) => {
            return capitalizePhraseFirstLetters(data.ownerName);
          }}
        />
        <Column
          caption="Cartão Final"
          allowHeaderFiltering={false}
          dataType="string"
          dataField="last4Digits"
        />
        <Column
          caption="Nome impresso no cartão"
          allowHeaderFiltering={false}
          dataType="string"
          dataField="printedName"
          cellRender={({ data }: ICellRenderParams) => {
            return capitalizePhraseFirstLetters(data.printedName);
          }}
        />
        <Column
          caption="Data de criação"
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const segments = filterValue.split(" ");
            const value = `${segments[0]
              .split("/")
              .filter((value: string) => value)
              .reverse()
              .join("-")}${segments[1] ? `T${segments[1]}` : ""}`;
            return ["timestamp", selectedFilterOperation, value];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="createdAt"
          cellRender={(params: ICellRenderParams) => {
            if (!params.data.createdAt) return "-";
            return moment(`${params.data.createdAt}`).format(
              "DD/MM/YYYY HH:mm:ss",
            );
          }}
        />
        <Column
          caption="Modalidade"
          filterOperations={["contains"]}
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const key = Object.keys(MapperCardModalityAndType).find((key) =>
              MapperCardModalityAndType[
                key as keyof typeof MapperCardModalityAndType
              ].includes(filterValue),
            );
            return ["modality", selectedFilterOperation, key];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="modality"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.modality) return "-";
            return MapperCardModalityAndType[data.modality];
          }}
        />
        <Column
          caption="Variante"
          allowHeaderFiltering={false}
          dataType="string"
          dataField="variant"
          cellRender={({ data }: ICellRenderParams) => {
            return capitalizePhraseFirstLetters(data.variant);
          }}
        />
        <Column
          caption="Status"
          filterOperations={["contains"]}
          calculateFilterExpression={(
            filterValue: any,
            selectedFilterOperation: string,
          ) => {
            const key = Object.keys(MapperCardStatus).find((key) =>
              MapperCardStatus[key as keyof typeof MapperCardStatus].includes(
                filterValue,
              ),
            );
            return ["status", selectedFilterOperation, key];
          }}
          allowHeaderFiltering={false}
          dataType="string"
          dataField="status"
          cellRender={({ data }: ICellRenderParams) => {
            if (!data.status) return "-";
            return MapperCardStatus[data.status];
          }}
        />
        <Column
          caption="Ação"
          allowHeaderFiltering={false}
          type="buttons"
          buttons={[
            {
              visible: (e: { row: ICellRenderParams }) => {
                return e.row.data.status === CardStatus.BlockedInternal;
              },
              text: "Desbloquear cartão",
              onClick: (e: { row: ICellRenderParams }) => {
                setVirtualCardSelected(e.row.data);
                modalManager.show(CARD_VIRTUAL_CONFIRMATION_MODAL_ID);
              },
            },
            {
              visible: (e: { row: ICellRenderParams }) => {
                return e.row.data.status === CardStatus.Normal;
              },
              text: "Bloquear cartão",
              onClick: (e: { row: ICellRenderParams }) => {
                setVirtualCardSelected(e.row.data);
                modalManager.show(CARD_TEMPORARY_BLOCK_MODAL_ID);
              },
            },
          ]}
        />
      </DataGrid>
    </CardSection>
  );
};
