import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { currencySymbol } from "utils/currency";

export const limitRequestsGrid = (
  handleApproval: (
    approval: "REJECTED" | "APPROVED",
    limitRequestId: number,
  ) => Promise<unknown>,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum solicitação encontrado.",
      headerFilter: { visible: true, allowSearch: true },
      dataSource: dataSourceCustomStoreGenerator(() => {
        return service.hubCreditManager
          .getAllPendingLimitRequests()
          .catch(() => {
            throw new Error("Erro ao buscar solicitações.");
          });
      }),
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        allowSearch: false,
        allowExporting: false,
        type: "buttons",
        role: roles.emprestimos.product.limitManagerLimitsDetailsSummaryPage
          .role,
        cellRender: ({ data }) =>
          !!data.id && (
            <DetailsLink
              href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.summary.path(
                {
                  id: data.id,
                },
              )}
            />
          ),
      },
      {
        dataField: "limit.customer.legalEntity.name",
        caption: "Nome",
      },
      {
        caption: "CPF / CNPJ",
        dataField: "limit.customer.legalEntity.taxId",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        caption: "Produto",
        dataField: "limit.creditLine.name",
      },
      {
        caption: "Limite",
        dataField: "amount",
        format: (value) => masks.currency(value, currencySymbol.BRL, "."),
      },
      {
        caption: "Identificação",
        dataField: "limit.creditLine.identification",
      },
      {
        caption: "Data de criação",
        dataField: "createdDate",
        dataType: "datetime",
      },
      {
        caption: "Data de vencimento",
        dataField: "expirationDate",
        dataType: "datetime",
      },
      {
        allowExporting: false,
        caption: "Ações",
        cellRender: ({ data }) => {
          return (
            !!data.id && (
              <DataGridAction
                actions={[
                  {
                    label: "Reprovar",
                    icon: { category: "fal", icon: "fa-download" },
                    onClick: async () => {
                      data.id && handleApproval("REJECTED", +data.id);
                    },
                  },
                  {
                    label: "Aprovar",
                    icon: { category: "fal", icon: "fa-edit" },
                    onClick: () => {
                      data.id && handleApproval("APPROVED", +data.id);
                    },
                  },
                ]}
              />
            )
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<
        ReturnType<typeof service.hubCreditManager.getAllPendingLimitRequests>
      >["data"]
    >[number]
  >);
