import { OToastManager, modalManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { logger } from "utils/logger";
import {
  Section,
  SideFilterBarForm,
  SideFilterBarModalId,
} from "./side-filter-bar.types";
import {
  assembleFiltersToSubmit,
  loadFieldValueReset,
  removeNullableFormFields,
} from "./side-filter-bar.utils";

export const useSideFilterBar = (
  sections: Section[],
  submitFilters: (data: Record<string, unknown>) => void,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formFieldIdsFiltered, setFormFieldIdsFiltered] = useState<string[]>(
    [],
  );

  const form = useForm<SideFilterBarForm>({
    reValidateMode: "onBlur",
  });

  const { setValue, getValues } = form;

  const onFormSubmit = useCallback(
    async (formToSubmit: SideFilterBarForm) => {
      try {
        setIsSubmitting(true);

        const formFieldsToFilter = removeNullableFormFields(
          formToSubmit,
          sections,
        );

        const filtersToSubmit = assembleFiltersToSubmit(
          formFieldsToFilter,
          sections,
        );

        submitFilters(filtersToSubmit);

        modalManager.hide(SideFilterBarModalId);

        setFormFieldIdsFiltered(Object.keys(formFieldsToFilter));
      } catch (error) {
        logger.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [sections, submitFilters],
  );

  const clearForm = useCallback(async () => {
    try {
      formFieldIdsFiltered.forEach((fieldId) => {
        const fieldValue = getValues(fieldId);
        setValue(fieldId, loadFieldValueReset(fieldValue));
      });

      await onFormSubmit({});
    } catch (error) {
      logger.error(error);
    }
  }, [formFieldIdsFiltered, getValues, setValue, onFormSubmit]);

  const onFormError = useCallback((formErrors?: SideFilterBarForm) => {
    if (!formErrors) return;
    logger.error(formErrors);
    OToastManager.warning("Por favor verifique os filtros escolhidos.");
  }, []);

  return {
    isSubmitting,
    formFieldIdsFiltered,
    setFormFieldIdsFiltered,
    onFormSubmit,
    clearForm,
    onFormError,
  };
};
