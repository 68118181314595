import {
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useProcessorConfigSettingsPage } from "./processor-config-settings.hook";

export const ProcessorConfigSettingsPage = () => {
  const { form, loading, updateSettings } = useProcessorConfigSettingsPage();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Configurações"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={updateSettings}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <ORow>
            <OCol sm={6}>
              <ORFieldCheckboxGroup
                id="leadsCanBeDeleted"
                name="leadsCanBeDeleted"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="leads-can-be-deleted-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="leads-can-be-deleted-checkbox">
                    Lead neste processor pode ser excluído?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={6}>
              <ORFieldCheckboxGroup
                id="leadsCanBeExpired"
                name="leadsCanBeExpired"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="leads-can-be-expired-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="leads-can-be-expired-checkbox">
                    Leads neste processador podem ser expirados?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={6}>
              <ORFieldCheckboxGroup
                id="leadsCanBeCreated"
                name="leadsCanBeCreated"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="leads-can-be-created-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="leads-can-be-created-checkbox">
                    Outros leads podem ser iniciados?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={6}>
              <ORFieldCheckboxGroup
                id="executeOnSingleParentOutput"
                name="executeOnSingleParentOutput"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="single-parent-checkbox" value="1" />
                  <OLabel htmlFor="single-parent-checkbox">
                    Executa com saída de apenas um pai?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={6}>
              <ORFieldCheckboxGroup
                id="leadsInWorkflowGroupCanBeCreated"
                name="leadsInWorkflowGroupCanBeCreated"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="leads-in-workflow-group-can-be-created-checkbox"
                    value="1"
                  />
                  <OLabel htmlFor="leads-in-workflow-group-can-be-created-checkbox">
                    Outros leads do grupo de Workflow podem ser iniciados?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
