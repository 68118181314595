import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { GenericPropertyFieldProps } from "../../property-field.types";

export const useWorkflowConfigField = ({
  processorConfigProperty: {
    name,
    processorPropertyId,
    processorConfigId,
    value,
  },
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const fieldInitialized = useRef(false);
  const { watch, setValue, reset } = useFormContext();
  const {
    value: chidWorkflowConfigs,
    callService: getChildWorkflowConfigs,
    hasError: hasErrorChidWorkflowConfigs,
    loading,
  } = useServiceCall(service.hubCreditManager.getChildWorkflowConfigs);

  const processorPropertyFieldId = getProcessorPropertyFieldId(
    name,
    processorPropertyId,
  );

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    hasErrorChidWorkflowConfigs &&
      OToastManager.danger(
        "Não foi possivel buscar as opções de workflows filhos. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorChidWorkflowConfigs]);

  useEffect(() => {
    if (!chidWorkflowConfigs) {
      getChildWorkflowConfigs(processorPropertyId);
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    chidWorkflowConfigs,
    value,
    setValue,
    getChildWorkflowConfigs,
    reset,
    processorPropertyId,
  ]);

  useEffect(() => {
    if (!fieldInitialized.current) {
      fieldInitialized.current = true;
      return;
    }

    if (!fieldWatch || fieldWatch === value) return;

    handleSubmitProperty(fieldWatch, processorPropertyId, processorConfigId);
  }, [
    fieldWatch,
    handleSubmitProperty,
    processorPropertyId,
    processorConfigId,
    value,
  ]);

  return { chidWorkflowConfigs, loading };
};
