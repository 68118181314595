import { OTypography } from "@maestro/react";

interface DetailWalletProps {
  title: string;
  value: string;
}

export const DetailWallet: React.FC<DetailWalletProps> = ({ title, value }) => {
  return (
    <div style={{ boxSizing: "border-box" }}>
      <div className="d-flex flex-column gap-2 mb-3">
        <OTypography weight="500" size="sm" type="default">
          {title}
        </OTypography>
        <OTypography size="lg" type="default" className="mb-0">
          {value}
        </OTypography>
      </div>
    </div>
  );
};
