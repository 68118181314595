import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { PostSimulateValue } from "services/acc/models/responses/get-admin-simulate-preticket.response";

export const adminCustomerAccSettlementDetailsGenerator = (
  value: PostSimulateValue | null,
) => {
  if (!value) return {} as DetailsFields;

  const { accSettlement, bankingBalance } = value;
  const { payer, preTicketAmount, currency } = accSettlement;

  const quoteDetails = {
    quote: {
      title: "Dados da operação",
      items: [
        {
          label: "Pagador",
          value: payer,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor da liquidação",
          value: masks.currency(preTicketAmount, currency),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Saldo em conta",
          value: masks.currency(bankingBalance.balance, "R$"),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    interest: {
      title: "Valor de juros a pagar",
      items: [
        {
          value: masks.currency(accSettlement.fixedInterestValue, "R$"),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return quoteDetails;
};
