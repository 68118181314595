import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

interface UpdateAmountButtonProps {
  limitRequestId: number;
}

export const UpdateAmountButton = ({
  limitRequestId,
}: UpdateAmountButtonProps) => {
  return (
    <RouterButton
      dataAction="emprestimos_contingencia:botao:atualizar_valor_proposta"
      dataLabel="atualizar_valor_proposta"
      href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.contingencies.updateAmount.path(
        {
          id: limitRequestId,
        },
      )}
      role={
        roles.emprestimos.product
          .limitManagerLimitsDetailsContingenciesUpdateAmountPage.role
      }
      outline
    >
      Atualizar valor
    </RouterButton>
  );
};
