import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { format } from "utils/date";
import { AntecipacaoAFornecedorClienteOperacoesByIdRouteParams } from "../../../../routes/antecipacao-a-fornecedor.route-params";
import { grid } from "./operations-details.grid";
import { SignatureAlert } from "./_compose";

type Operation = Awaited<
  ReturnType<typeof service.quickfin.getAttendance>
>["data"];

export const OperationsDetails = () => {
  const { id } =
    useParams<AntecipacaoAFornecedorClienteOperacoesByIdRouteParams>();
  if (!id) throw new Error("No id parameter");

  const gridRef = useGridRef();

  const [operation, setOperation] = useState<Operation>();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<NonNullable<Operation["trades"]>[number]>(
        () =>
          service.quickfin
            .getAttendance(id)
            .then(({ data }) => {
              setOperation(data);
              return data.trades ?? [];
            })
            .catch(() => {
              const errorMessage = "Erro ao buscar as operações";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            }),
      ),
    [id],
  );

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={`Operação #${id}${
            operation?.createdAt ? " - " + format.date(operation.createdAt) : ""
          }`}
        />
      }
    >
      <SignatureAlert operation={operation} />
      <GridTemplate gridRef={gridRef} showRefreshButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </DetailsTemplate>
  );
};
