import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { LoanStatus } from "../../../ofertas/offers.type";
import { disbursementsGrid } from "./operations-disbursements.grid";

export const OperationsDisbursementsPage = () => {
  const { id } = useParams<EmprestimosProdutoEmprestimosById>();
  const { hasRole } = useRoles();

  if (!id) throw new Error("No id");

  const {
    callService: getLoanDetails,
    loading,
    value: loanDetails,
  } = useServiceCall(service.hubLoan.getLoanDetails);

  const disbursed = useMemo(
    () => loanDetails?.disbursements.some((d) => d.type === "MAIN") ?? true,
    [loanDetails?.disbursements],
  );

  useEffect(() => {
    getLoanDetails(id);
  }, [getLoanDetails, id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Desembolsos" />}
      actions={
        <RefreshGridButton
          disabled={loading}
          onClick={() => {
            getLoanDetails(id);
          }}
        />
      }
    >
      <ODataGridGenerator
        grid={disbursementsGrid(
          disbursed,
          hasRole("hub-loan:Admin.Loan.UpdateDisbursementValue"),
          loanDetails?.status as LoanStatus,
        )}
        dataSource={loanDetails?.disbursements ?? []}
        loading={loading}
      />
    </GridTemplate>
  );
};
