import { Edge, MarkerType, Node } from "reactflow";

export const processorProps: Node = {
  id: "",
  data: null,
  position: { x: 0, y: 0 },
  type: "PROCESSOR",
  style: {
    border: "1px solid #1a192b",
    borderRadius: "3px",
    color: "#222",
    backgroundColor: "#FFF",
    padding: "8px 14px",
  },
};

export const relationshipProps: Edge = {
  id: "",
  source: "",
  target: "",
  markerEnd: { type: MarkerType.ArrowClosed },
  style: { strokeWidth: 3 },
  labelBgStyle: { fill: "#FFF" },
};

export const mapProcessorType = {
  NOTIFICACAO: "Notificação",
  FORM: "Formulário",
  DILIGENCIA_AGROMATIC: "Diligência Agromatic",
  ENTUBA: "Entuba",
  DISPONIBILIZA_CREDITO: "Disponibilização de Crédito",
  ONBOARDING: "Onboarding",
  ANALISE_DE_CREDITO: "Análise de Crédito",
  AGUARDANDO_CONDICAO: "Aguardando Condição",
  ENVIO_ECNPJ: "Envio e-CNPJ",
  CALCULADORA_ENERGIA: "Calculadora de Energia",
  PENDENTE_APROVACAO: "Aprovação Pendente",
  ACEITE_CLIENTE: "Aceite do cliente",
  CONTRATACAO: "Contratação",
  VALIDACAO: "Validação",
  CRIAR_RELACIONAMENTO_ENERGIA: "Criar relacionamento de energia",
  CRIAR_RELACIONAMENTO_CONTA: "Criar relacionamento com conta",
  ENVIO_DE_LOTE: "Envio de Lote",
  ONBOARDING_GARANTIDORES: "Onboarding de garantidores",
  CREDIT_ENGINE_ACCEPT: "Aceite motor de crédito",
  CREDIT_ENGINE_CANCEL: "Cancelamento motor de crédito",
  DOWNLOAD_INCOME_AUTH_CARD: "Download carta autorização",
  UPDATE_LIMIT_REQUEST_STATUS: "Atualizar status do limite",
  EXPIRACAO_DE_LEAD: "Expiração de leads",
  COMPROVANTE_DE_DESEMBOLSO: "Baixar comp. de desembolso",
  BUSCA_INTEGRADORES_DISTRIBUIDORES: "Buscar integradores/distribuidores",
  RPA_CONTA_DE_LUZ: "RPA - Conta de luz",
  CONSOME_LIMITE: "Consome limite cliente",
  DISPONIBILIZA_LEADS_CONTEXTO: "Leads filtrados por contexto",
  LEADS_DESATIVADOS: "Leads desativados",
  DISPONIBILIZA_WORKFLOW_FILHO: "Disponibiliza workflow filho",
  DISPONIBILIZA_INICIO_LEAD_SUBWORKFLOW: "Disponibiliza início subworkflow",
  HUB_ONBOARDING_SEMAPHORE: "Semáforo do HubOnboarding",
  CONSOME_LIMITE_ORIGINACAO: "Consome limite de originador",
  INTEGRACAO_SOLAR_DEVELOPERS: "Originação solar via developers",
  CREATE_ACC_PROPOSAL: "Criar proposta de ACC",
  MIGRACAO_ENERGIA: "Migração de energia",
  BUSCA_ORIGINADORES: "Buscar originadores",
  INTEGRACAO_LANDING_PAGE: "Originação via LPs",
  REGISTER_ORIGINATOR_B2B: "Registrar originador no B2B",
  DECISAO: "Decisão",
  SOLICITACAO_OPTIN: "Solicita opt-in",
  ATUALIZA_LEADS: "Atualiza outros leads",
  CREATE_LIMIT_REQUEST: "Cria LimitRequest",
  EXPIRA_LIMITES_GERADOS: "Expira limites gerados",
  CREDIT_ENGINE_STATE: "Envio state motor de crédito",
  ASSINATURA_DE_CONTRATO: "Assinatura de contrato",
  FINALIZA_CONTEXTOS: "Finaliza contextos",
  ATUALIZA_INFORMACOES: "Atualiza informações",
  DISPONIBILIZA_ACAO: "Disponibiliza ação",
  AGROMATIC: "Agromatic",
};
