import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostCustomerInvitesUpdateWorkflowBody } from "services/clerk";
import { updateInviteDefaultValues } from "./_compose/update-invite-form/update-invite-form.form";

export const useInviteDetails = (identifier: string) => {
  if (!identifier) {
    throw new Error("No identifier");
  }

  const {
    loading: loadingDetails,
    callService: getInviteDetailsFromIdentifier,
    value: inviteDetails,
    hasError,
  } = useServiceCall(service.clerk.getWorkflowDetails);

  useEffect(() => {
    getInviteDetailsFromIdentifier({ identifier });
  }, [getInviteDetailsFromIdentifier, identifier]);

  const { loading: loadingUpdate, callService: updateInviteInfo } =
    useServiceCall(service.clerk.updateWorkflow);

  const form = useForm({
    defaultValues: updateInviteDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const updateInvite = handleSubmit(async (values) => {
    const body: PostCustomerInvitesUpdateWorkflowBody = {
      identifier,
      receiverEmail: values.receiverEmail,
    };

    const { success } = await updateInviteInfo(body);

    if (success) {
      OToastManager.success("Informações atualizadas com sucesso!");
    } else {
      OToastManager.danger("Tivemos algum problema, verifique os erros!");
    }
  });

  const accountType = (inviteDetails?.accounts || [])
    .map(({ type }) => type)
    .join(", ");

  useEffect(() => {
    if (inviteDetails) {
      reset({
        type: accountType,
        ...inviteDetails,
      });
    }
  }, [reset, inviteDetails, accountType]);

  return {
    form,
    hasError,
    loadingDetails,
    loadingUpdate,
    updateInvite,
    inviteDetails,
  };
};
