export enum IntegrationLayout {
  "FEBRABAN-240" = "FEBRABAN-240",
}

export const LayoutCNAB = {
  "FEBRABAN-240": {
    Cobrança: "33d82113-935f-4733-9a48-d74270630498",
    Pagamento: "b1f9a426-0b74-4493-b315-092d41007e02",
    Extrato: "cd04377f-ac72-4d39-8f65-aa4170b4bc94",
    Fopa: "1dd59d39-b134-45a0-8671-96a109bac769",
  },
};
