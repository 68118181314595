import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { useParams } from "react-router-dom";
import { ConfigurationForm } from "../_compose/configuracoes-form";
import { useRavenNotificationConfigurationAdd } from "./add-notification-configuration.hook";
import { FerramentasProdutoRavenAddNotificationConfigByTypeIdRouteParams } from "../../../../../routes/ferramentas.route-params";

export const RavenNotificationConfigurationAdd = () => {
  const { uuid } =
    useParams<FerramentasProdutoRavenAddNotificationConfigByTypeIdRouteParams>();

  const { submit, form, loading } = useRavenNotificationConfigurationAdd(uuid);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Notificações"
          description="Adicione uma nova configuração para essa notificação"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Adicionar
        </LoadingButton>
      }
    >
      <ConfigurationForm form={form} />
    </FormTemplate>
  );
};
