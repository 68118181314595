import { masks } from "@maestro/utils";
import { BankAccountWithTaxId } from "../../components/generic-bank-data/generic-bank-data.types";

export const buildOperationSummary = (
  valorTotalBruto: number,
  valorTotalLiquido: number,
  receivablesLength: number,
  operationsLength: number,
  bankAccount: BankAccountWithTaxId | undefined,
) => {
  return {
    items: [
      {
        label: "Valor Total Bruto",
        value: masks.currency(valorTotalBruto, "R$"),
      },
      {
        label: "Valor Total Líquido",
        value: masks.currency(valorTotalLiquido, "R$"),
      },
      {
        label: "Número de Trades",
        value: receivablesLength,
      },
      {
        label: "Número de Operações",
        value: operationsLength,
      },
      {
        label: "Dados bancários",
        value: `Banco: ${bankAccount?.bank} Conta: ${bankAccount?.account}-${bankAccount?.account_digit} Agência: ${bankAccount?.branch}`,
      },
    ],
  };
};
