import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../routes/workflow.route-params";
import {
  settingsFormDefaultValues,
  settingsFormValidationSchema,
} from "./processor-config-settings.schema";

export const useProcessorConfigSettingsPage = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const form = useForm({
    resolver: yupResolver(settingsFormValidationSchema),
    defaultValues: settingsFormDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const [loading, setLoading] = useState(false);

  const getSettings = useCallback(async () => {
    try {
      if (!processorId) return;

      setLoading(true);

      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsSettings(
          processorId,
        );

      reset({
        executeOnSingleParentOutput: data.executeOnSingleParentOutput
          ? ["1"]
          : [],
        leadsCanBeCreated: data.leadsCanBeCreated ? ["1"] : [],
        leadsInWorkflowGroupCanBeCreated: data.leadsInWorkflowGroupCanBeCreated
          ? ["1"]
          : [],
        leadsCanBeExpired: data.leadsCanBeExpired ? ["1"] : [],
        leadsCanBeDeleted: data.leadsCanBeDeleted ? ["1"] : [],
      });
    } catch (err) {
      OToastManager.danger("Erro para retornar as configurações.");
    } finally {
      setLoading(false);
    }
  }, [processorId, reset]);

  const updateSettings = handleSubmit(async (values) => {
    try {
      if (!processorId) return;

      setLoading(true);

      await service.hubCreditManager.updateProcessorConfigDetailsSettings({
        processorConfigId: Number(processorId),
        executeOnSingleParentOutput:
          !!values.executeOnSingleParentOutput?.length,
        leadsCanBeCreated: !!values.leadsCanBeCreated?.length,
        leadsInWorkflowGroupCanBeCreated:
          !!values.leadsInWorkflowGroupCanBeCreated?.length,
        leadsCanBeExpired: !!values.leadsCanBeExpired?.length,
        leadsCanBeDeleted: !!values.leadsCanBeDeleted?.length,
      });

      OToastManager.success("Configurações alteradas com sucesso.");
    } catch (err) {
      OToastManager.danger("Erro para salvar as configurações.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return {
    form,
    loading,
    updateSettings,
  };
};
