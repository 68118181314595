import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContactContext } from "contexts/contact/contact.component";
import { FormValuesType, validationSchema } from "../_compose";
import { prepareToRequest } from "../contacts.utils";

export const useContactsFavoredCreate = () => {
  const navigate = useNavigate();

  const { contact } = useContext(ContactContext);

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      if (!contact) return;

      const newBankAccount = prepareToRequest(values);
      const bankAccounts = contact.product.bankAccounts ?? [];
      bankAccounts.push(newBankAccount);

      const payload = {
        ...contact,
        product: { ...contact.product, bankAccounts },
      };

      await service.adminBankinghub.createContact(payload);

      OToastManager.success("Cadastrado com sucesso");

      navigate(corporateRouter.routes.banking.customer.contacts.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return { form, submit, loading, setLoading };
};
