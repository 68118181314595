import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { GetContactByContactHolderResponseItem } from "services/admin-bankinghub/models";

export const ContactContext = createContext<{
  contact: GetContactByContactHolderResponseItem | null;
  setContact: React.Dispatch<
    React.SetStateAction<GetContactByContactHolderResponseItem | null>
  >;
}>({
  contact: null,
  setContact: (
    _value: SetStateAction<GetContactByContactHolderResponseItem | null>,
  ) => {
    throw new Error("Function not implemented.");
  },
});

export const ContactProvider = ({ children }: PropsWithChildren) => {
  const [contact, setContact] =
    useState<GetContactByContactHolderResponseItem | null>(null);
  const value = useMemo(() => ({ contact, setContact }), [contact, setContact]);
  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  );
};
