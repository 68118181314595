import {
  OCol,
  OField,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { productsMap } from "../cedente-limits.utils";
import { useAddCedenteLimit } from "./add-cedente-limit.hook";

export const AddCedenteLimite = () => {
  const { cedentesOptions, cedentesLoading, form, submit, submitLoading } =
    useAddCedenteLimit();

  const {
    formState: { errors },
    setValue,
  } = form;

  const clearCedente = useCallback(
    () => setValue("cedenteId", null as any),
    [setValue],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar limite para cedente" />}
      actions={
        <LoadingButton
          dataAction="limite_cedente:botao:Salvar"
          dataLabel="Salvar"
          onClick={submit}
          loading={submitLoading}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow gap={3}>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldInput
                dataAction="limite_cedente:texto:limite"
                dataLabel="limite"
                id="limit"
                name="limit"
                tag="currency"
                label="Limite"
                symbol="R$"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <ORFieldSelect
                dataAction="limite_cedente:select:tipo_produto"
                dataLabel="tipo_produto"
                id="tipoProduto"
                name="tipoProduto"
                label="Tipo de produto"
              >
                {Object.entries(productsMap).map(([key, value]) => (
                  <OOption key={key} value={key}>
                    {value}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <OField
                label="Cedente"
                htmlFor="cedenteId"
                error={!!errors.cedenteId}
                message={errors?.cedenteId?.message}
              >
                <SelectSearch
                  id="cedenteId"
                  name="cedenteId"
                  placeholder="Busque o nome do cedente"
                  options={cedentesOptions}
                  disabled={cedentesLoading}
                  onClear={clearCedente}
                  className="w-100"
                  maxOptions={50}
                  eager={false}
                />
              </OField>
              {cedentesLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
