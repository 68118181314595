import { validators } from "@maestro/utils";
import * as yup from "yup";

export interface GuarantorFormValues {
  guarantorTaxId: string;
  guarantorName?: string;
  guarantorType: string;
}

export const guarantorValidationFormSchema: yup.ObjectSchema<GuarantorFormValues> =
  yup.object({
    guarantorTaxId: yup
      .string()
      .required("Este campo é obrigatório")
      .test("validate_cpf", "CPF inválido", (value) => validators.cpf(value)),
    guarantorName: yup.string().optional(),
    guarantorType: yup.string().required("Este campo é obrigatório"),
  });
