import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { ClerkSystemsForm } from "../_compose";
import { useClerkSystemsDetails } from "./details.hook";

export const ClerkSystemsDetails = () => {
  const { value: id } = useParseFromSearchParams<number>("id");
  const { submit, form, loading } = useClerkSystemsDetails(id);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Sistema clerk"
          description="Editar dados do sistema"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="sistemas_clerk:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ClerkSystemsForm mode="update" />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
