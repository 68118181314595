import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../../components/validation-error-component";
import { ConsumerUnitFields } from "./_compose";
import { useEditConsumerUnit } from "./edit-consumer-unit.hook";

export const EditConsumerUnitPage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    form,
    getConsumerUnit,
    submit,
    submitLoading,
  } = useEditConsumerUnit();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar unidade consumidora" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={fetchLoading}
        hasError={fetchHasError}
        errorComponent={
          <ValidationErrorComponent
            error={fetchError}
            callback={getConsumerUnit}
            title="Erro ao buscar os dados da unidade consumidora"
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <ConsumerUnitFields />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
