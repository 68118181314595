import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useUser } from "contexts/user";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  addPayerDefaultValue,
  addPayerValidationSchema,
} from "./add-payer.form";
import { AddPayerFormFields } from "./add-payer.types";

export const useAddPayer = () => {
  const [currentStep, setCurrentStep] = useState("INITIAL_STEP");
  const [requiredDocuments, setRequiredDocuments] = useState<
    ScfDevelopers.PayerDocumentType[]
  >([]);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);

  const { user } = useUser();

  const form = useForm<AddPayerFormFields>({
    resolver: yupResolver(addPayerValidationSchema),
    defaultValues: addPayerDefaultValue,
  });
  const { watch } = form;

  const configurationIdWatch = watch("partnerConfigurationId");

  const {
    callService: getEntityDesks,
    loading: loadingDesks,
    value: possibleDesks,
  } = useServiceCall(service.clerk.getEntityDesks);

  const { callService: getConfigurations, value: configurations } =
    useServiceCall(service.scfDevelopers.getConfigurations);

  const getConfigurationsWithoutSteps = useCallback(async () => {
    const { success } = await getConfigurations();

    if (!success) {
      OToastManager.danger(
        "Não foi possível obter as mesas que o usuário possui acesso",
      );
    }
  }, [getConfigurations]);

  const getPossibleDesks = useCallback(async () => {
    if (user) {
      const payload = {
        taxId: user.cpf ?? "",
      };

      const { success } = await getEntityDesks(payload);

      if (!success) {
        OToastManager.danger(
          "Não foi possível obter as mesas que o usuário possui acesso",
        );
      }
    }
  }, [user, getEntityDesks]);

  useEffect(() => {
    getConfigurationsWithoutSteps();
  }, [getConfigurationsWithoutSteps]);

  useEffect(() => {
    getPossibleDesks();
  }, [getPossibleDesks]);

  useEffect(() => {
    if (configurations && configurationIdWatch) {
      const foundConfiguration = configurations.find(
        (configuration) =>
          configuration.configurationId === configurationIdWatch,
      );

      if (foundConfiguration) {
        setRequiredFields(foundConfiguration.requestRequiredFields);
        setRequiredDocuments(foundConfiguration.requiredOnboardingDocuments);
      }
    }
  }, [configurationIdWatch, configurations]);

  return {
    configurations,
    currentStep,
    form,
    loadingDesks,
    possibleDesks,
    requiredDocuments,
    requiredFields,
    setCurrentStep,
  };
};
