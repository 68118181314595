import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import {
  energyValidationResultsGrid,
  getErrors,
} from "./energy-validation-results.grid";

interface EnergyValidationResultsProps {
  errorResponse: HubEnergy.ValidationErrorResponse;
}

export const EnergyValidationResults = ({
  errorResponse,
}: EnergyValidationResultsProps) => {
  const dataSource = useMemo(() => getErrors(errorResponse), [errorResponse]);

  return (
    <ODataGridGenerator
      grid={energyValidationResultsGrid}
      dataSource={dataSource}
    />
  );
};
