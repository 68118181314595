import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { energyAdequatorsFormValidationFormSchema } from "../../../../components/deprecated/adequator/energy-adequator.schemas";
import { EnergyAdequatorsFormValues } from "../../../../components/deprecated/adequator/energy-adequator.types";
import { getHubEnergyErrorMessage } from "../../../../components/deprecated/error/error-code.utils";

export const useEnergyCreateAdequatorPage = () => {
  const navigate = useNavigate();

  const form = useForm<EnergyAdequatorsFormValues>({
    resolver: yupResolver(energyAdequatorsFormValidationFormSchema),
  });
  const { handleSubmit } = form;

  const { callService: createAdequacyOptions, loading } = useServiceCall(
    service.hubEnergy.createAdequacyOptions,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createAdequacyOptions({
          ...values,
          createdByCustomer: !!values.createdByCustomer.length,
        });

        if (!success)
          return OToastManager.danger(getHubEnergyErrorMessage(error));

        OToastManager.success("Adequadora adicionada com sucesso");

        navigate(corporateRouter.routes.energia.product.adequators.path);
      }),
    [createAdequacyOptions, handleSubmit, navigate],
  );

  return { form, loading, submit };
};
