import { OConfirmationModal, modalManager } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { IconButton } from "components/icon-button";
import { service } from "services";
import styled from "styled-components";
import { DetailsTemplate } from "templates/details-template";

export const StyledPre = styled.pre`
  background: #2c3e50;
  color: #ecf0f1;
  height: 400px;
  line-height: 150%;
  overflow-y: auto;
  padding: 20px;
  text-align: left;
`;

export const buildGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDataUpdateLogs>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum logs",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "id",
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      caption: "Data de criação",
    },
    {
      dataField: "typeName",
    },
    {
      dataField: "workFlowLogType",
    },
    {
      dataField: "portalStatus",
    },
    {
      dataField: "workflowStatus",
    },
    {
      dataField: "workflowId",
    },
    {
      dataField: "messageFlowKey",
    },
    {
      caption: "Ações",
      formItem: { visible: false },
      cellRender: ({ data }) => {
        const modalId = `data-update-logs-${data.id}-modal`;
        return (
          <>
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-unified-invoice" },
                  label: "Exibir",
                  onClick: () => modalManager.show(modalId),
                },
              ]}
            />
            <OConfirmationModal
              modalId={modalId}
              title="Json Message"
              confirmLabel="Ok"
              divider={false}
              size="md"
              onConfirm={() => modalManager.hide(modalId)}
            >
              <DetailsTemplate
                actions={
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(
                        JSON.stringify(
                          {
                            ...data,
                            jsonMessage: JSON.parse(data.jsonMessage),
                          },
                          null,
                          2,
                        ),
                      )
                    }
                    icon={{ category: "orq", icon: "orq-edit-copy" }}
                  >
                    Copiar
                  </IconButton>
                }
              >
                <StyledPre>
                  {JSON.stringify(
                    { ...data, jsonMessage: JSON.parse(data.jsonMessage) },
                    null,
                    2,
                  )}
                </StyledPre>
              </DetailsTemplate>
            </OConfirmationModal>
          </>
        );
      },
    },
  ],
};
