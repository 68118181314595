import { OCol, ORow } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { SelectSearchMultiple } from "components/form";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { userProfilesFields } from "./user-profiles.form";
import { useUserProfiles } from "./user-profiles.hook";

export const UserProfiles = () => {
  const {
    allProfiles,
    form,
    getProfiles,
    getProfilesHasError,
    getProfilesLoading,
    getUser,
    getUserHasError,
    getUserLoading,
    isDirty,
    loading,
    options,
    resetProfiles,
    submit,
    userData,
  } = useUserProfiles();

  const title = userData
    ? `Profiles do usuário - ${userData.email}`
    : "Profiles do usuário";

  return (
    <FormTemplate
      pageTitle={<PageTitle title={title} />}
      actions={
        <>
          <LoadingButton
            dataAction="acoes:botao:resetar"
            dataLabel="resetar"
            loading={loading}
            disabled={getUserHasError || getProfilesHasError || !isDirty}
            type="dark"
            outline
            onClick={resetProfiles}
          >
            Resetar
          </LoadingButton>
          <LoadingButton
            dataAction="acoes:botao:salvar"
            dataLabel="salvar"
            loading={loading}
            disabled={getUserHasError || getProfilesHasError}
            onClick={submit}
          >
            Salvar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={getUserLoading || getProfilesLoading}
          hasError={getUserHasError || getProfilesHasError}
          value={!!userData && !!allProfiles}
          errorComponent={
            getUserHasError ? (
              <DefaultErrorComponent
                title="Não foi possível buscar o usuário"
                callback={getUser}
              />
            ) : (
              <DefaultErrorComponent
                title="Não foi possível buscar os profiles"
                callback={getProfiles}
              />
            )
          }
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <SelectSearchMultiple
                    {...userProfilesFields.profiles}
                    options={options}
                  />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
