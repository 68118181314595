import * as yup from "yup";

export interface ExchangeNatureFormValues {
  code: string;
  groupCode: string;
  description: string;
  disclaimer: string | null | undefined;
  isApproved: string[];
  isIncomingOperationAllowed: string[];
  isOutgoingOperationAllowed: string[];
  isDifferentOwnershipAccountAllowed: string[];
  minimumAmount: number | null | undefined;
  maximumAmount: number | null | undefined;
  genericFields: {
    typeId: number;
    isRequired: boolean;
  }[];
  documentGroupIds: number[];
}

export const exchangeNatureFormValidationFormSchema: yup.ObjectSchema<ExchangeNatureFormValues> =
  yup.object({
    code: yup.string().required("Campo obrigatório"),
    groupCode: yup.string().required("Campo obrigatório"),
    description: yup.string().required("Campo obrigatório"),
    disclaimer: yup.string().nullable(),
    isApproved: yup.array().default([]),
    isIncomingOperationAllowed: yup.array().default([]),
    isOutgoingOperationAllowed: yup.array().default([]),
    isDifferentOwnershipAccountAllowed: yup.array().default([]),
    minimumAmount: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    maximumAmount: yup
      .number()
      .transform((value) => value || null)
      .nullable(),
    genericFields: yup
      .array(
        yup.object({
          typeId: yup.number().required("Campo obrigatório"),
          isRequired: yup.boolean().required("Campo obrigatório"),
        }),
      )
      .default([]),
    documentGroupIds: yup
      .array()
      .required("Campo obrigatório")
      .min(1, "É necessário selecionar ao menos um documento."),
  });
