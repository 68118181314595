import { OCol, OOption, ORFieldSelect, OToastManager } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  EmprestimosProdutoParceiroAssociarConvenioRouteQueryParams,
  EmprestimosProdutoParceirosByPartnerNameByPartnerId,
} from "../../../../../../routes/emprestimos.route-params";

export const PartnerAssociateAgreementPage = () => {
  const [loading, setLoading] = useState(false);

  const {
    callService: agreementsCallService,
    hasError: agreementsHasError,
    value: agreementsValue,
    loading: agreementsLoading,
  } = useServiceCall(service.hubLoan.getAgreement);

  const form = useForm<{ agreementId: number }>();
  const { handleSubmit, setError } = form;

  const { partnerId } =
    useParams<EmprestimosProdutoParceirosByPartnerNameByPartnerId>();

  const { value: partnerAgreemnts } =
    useParseFromSearchParams<EmprestimosProdutoParceiroAssociarConvenioRouteQueryParams>(
      "data",
    );

  if (!partnerId) throw new Error("No partnerName");

  const associate = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!values.agreementId) {
          setError("agreementId", { message: "Este campo é obrigatório" });
          return;
        }
        try {
          setLoading(true);
          await service.hubLoan.postArrangement({ partnerId, ...values });
          OToastManager.success(
            "O parceiro foi associado ao convênio com sucesso.",
          );
          partnerAgreemnts?.push(values);
        } catch {
          OToastManager.danger(
            "Não foi possível associar o parceiro ao convênio.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, partnerAgreemnts, partnerId, setError],
  );

  useEffect(() => {
    agreementsCallService();
  }, [agreementsCallService]);

  return (
    <ContentTemplate
      value={agreementsValue}
      loading={agreementsLoading}
      hasError={agreementsHasError}
      errorComponent={
        <ErrorComponent
          messageTitle="Ocorreu um erro ao buscar os convênios"
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton onClick={() => agreementsCallService()} />
        </ErrorComponent>
      }
      render={(agreements) => {
        const filteredAgreements = agreements.filter(
          (agreement) =>
            !partnerAgreemnts?.some(
              (partnerAgreemnt) => partnerAgreemnt.agreementId === agreement.id,
            ),
        );
        return (
          <FormTemplate
            pageTitle={<PageTitle title="Associar convênio ao parceiro" />}
            actions={
              <LoadingButton
                loading={loading}
                dataAction="emprestimos_parceiro_associar_convenio:botao:associar"
                dataLabel="associar"
                onClick={associate}
              >
                Associar
              </LoadingButton>
            }
          >
            <CardTemplate>
              <FormProvider {...form}>
                <OCol md={6}>
                  <ORFieldSelect
                    id="agreementId"
                    name="agreementId"
                    dataAction="emprestimos_parceiro_associar_convenio:select:convenio"
                    dataLabel="convenio"
                    label="Convênio"
                    key={filteredAgreements?.length}
                  >
                    {filteredAgreements?.map((agreement) => (
                      <OOption key={agreement.id} value={agreement.id}>
                        {agreement.name}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
              </FormProvider>
            </CardTemplate>
          </FormTemplate>
        );
      }}
    />
  );
};
