import { MaestroFields, Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface EditStepPropertyForm {
  /** non editable */
  isAutomation: boolean | null;
  isPublic: string[];
  typeId: number;
  minimumAmount: number;
  documentTypeId: number | null;
}

export const editStepPropertyFormFields = {
  isPublic: {
    id: "isPublic",
    name: "isPublic",
  },
  minimumAmount: {
    id: "minimumAmount",
    name: "minimumAmount",
    tag: "number",
    label: "Quantidade mínima",
    placeholder: "Preencher",
  },
} as const satisfies MaestroFields<EditStepPropertyForm>;

export const editStepPropertyFormValidationSchema = yup.object<
  EditStepPropertyForm,
  YupObjectSchema<EditStepPropertyForm>
>({
  typeId: yup.number().required("Campo obrigatório"),
});

export const checkboxValue = "true";

export const editStepPropertyFormDefaultValues = {
  isAutomation: null,
  isPublic: [],
  typeId: null,
  documentTypeId: null,
  minimumAmount: 1,
} satisfies Partial<
  Nullable<EditStepPropertyForm>
> as unknown as EditStepPropertyForm;
