import * as yup from "yup";

export const modalFormValidationSchema = yup.object().shape({
  unblockReason: yup.string().required("Motivo é obrigatório"),
});

export const modalFormDefaultValues = {
  unblockReason: "",
  taxId: "",
  name: "",
};
