import { DetailsFields } from "components/details-card";
import { GetCheckCompanyExistsResponse } from "services/fopa";
import { format } from "utils/date";

export const buildDetails = (
  info: GetCheckCompanyExistsResponse,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Ativo",
        value: info.active
          ? "Disponivel para habilitação"
          : "Empresa não possui o módulo folha de pagamentos ativado.",
        grid: { md: 4 },
      },
      {
        label: "Data de criação",
        value: format.dateTime(info.createdAt),
        grid: { md: 4 },
      },
      {
        label: "Bloqueado",
        value: info.locked
          ? "Empresa está no período de trava de 24h pós contratação."
          : "Nenhum bloqueio encontrado",
        grid: { md: 4 },
      },
    ],
  },
});
