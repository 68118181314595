import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";

export const useGridActions = (
  property: HubEnergy.DiligenceStepPropertyResponse,
  reload: () => void,
) => {
  const { callService: _removeProperty, loading: removePropertyLoading } =
    useServiceCall(service.hubEnergy.removeProperty);

  const {
    callService: _sendDocumentToPaperclip,
    loading: sendDocumentToPaperclipLoading,
  } = useServiceCall(service.hubEnergy.sendDocumentToPaperclip);

  const removeProperty = useCallback(async () => {
    const { success, error } = await _removeProperty(property.id);

    if (success) {
      OToastManager.success("Propriedade removida com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao remover a propriedade",
      );
    }
  }, [_removeProperty, property.id, reload]);

  const sendDocumentToPaperclip = useCallback(async () => {
    const { success, error } = await _sendDocumentToPaperclip({
      documentId: property.documentTemplate?.id,
    });

    if (success) {
      OToastManager.success("Documento enviado para assinatura");
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao enviar o documento para assinatura",
      );
    }
  }, [_sendDocumentToPaperclip, property.documentTemplate?.id]);

  return {
    loading: removePropertyLoading || sendDocumentToPaperclipLoading,
    removeProperty,
    sendDocumentToPaperclip,
  };
};
