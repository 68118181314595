import { OClickableCard, OLabel, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { useCallback } from "react";
import { PortfolioManagementDetails } from "services/moana/models/responses/portfolio-management.response";

interface PortfolioManagementProps {
  title: string;
  receivablesInRange?: PortfolioManagementDetails;
  selectedRanges: number[];
  setSelectedRanges: (r: number[]) => void;
  range: number;
  totalItems: number;
  setTotalItems: (r: number) => void;
}

export const PortfolioManagement: React.FC<PortfolioManagementProps> = ({
  title,
  receivablesInRange,
  selectedRanges,
  setSelectedRanges,
  range,
  totalItems,
  setTotalItems,
}) => {
  const totalAmount = receivablesInRange?.totalAmount ?? 0;
  const receivablesCount = receivablesInRange?.receivablesCount ?? 0;

  const handleClick = useCallback(() => {
    if (selectedRanges.includes(range)) {
      setSelectedRanges(selectedRanges.filter((r) => range !== r));
      setTotalItems(totalItems - receivablesCount);
    } else {
      setSelectedRanges(selectedRanges.concat(range));
      setTotalItems(totalItems + receivablesCount);
    }
  }, [
    selectedRanges,
    range,
    receivablesCount,
    setSelectedRanges,
    setTotalItems,
    totalItems,
  ]);

  return (
    <OClickableCard
      id="radio-card"
      name="radio-card"
      inputType="checkbox"
      value={masks.currency(totalAmount ?? 0)}
      disabled={receivablesCount <= 0}
      onClick={() => handleClick()}
      size="210px"
    >
      <div className="d-flex flex-column">
        <OTypography tag="span">{title}</OTypography>
        <OLabel htmlFor="label" size="sm">
          Quantidade: {receivablesCount}
        </OLabel>
        <OLabel htmlFor="label" size="sm">
          Total: {masks.currency(totalAmount, "R$")}
        </OLabel>
      </div>
    </OClickableCard>
  );
};
