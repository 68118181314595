import styled from "styled-components";

export const PageContainer = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  background-color: var(--theme-background);
`;
