import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";

export const useFraudVerification = () => {
  const [fraudVerifications, setFraudVerifications] =
    useState<HubOnboardingRelationships.Admin.FraudVerificationsItem[]>();
  const [loading, setLoading] = useState(false);

  const getFraudVerification = useCallback(async (uuid: string) => {
    try {
      setLoading(true);
      const { data } = await service.onboardingRelationships.getWorkflowDetails(
        {
          uuid,
        },
      );
      setFraudVerifications(data.fraudVerifications);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o workflow");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    fraudVerifications,
    getFraudVerification,
  };
};
