import { modalManager, OToastManager } from "@maestro/core";
import { OButton, OIcon, OInputDateRange, OTypography } from "@maestro/react";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { auth } from "../../../../../../contexts/auth";
import { MoneyMask } from "../../utils";
import { removeCipherFormatter } from "../../utils/formatters/cipher";
import { CardSection } from "../already-approved/already-approved.styles";
import { ColumnGap, RowGap, RowSpacedContainer } from "../cards.styles";
import {
  Currency,
  ItemCategory,
  ItemType,
  MapperItemCategoryAndType,
} from "../cards.types";
import { TransactionChargeBack } from "../compose/chargeback/chargeback.component";
import {
  IChargebackTransactionItem,
  ISalesforceTokenInfo,
} from "../compose/chargeback/chargeback.types";
import { RefusalTransactionModal } from "../compose/refusal-transaction-modal/refusal-transaction-modal.component";
import { ITransactionRelatedItem } from "../compose/transaction-details/related-items/related-items.types";
import { TransactionDetails } from "../compose/transaction-details/transaction-details.component";
import { ITransactionDetailsData } from "../compose/transaction-details/transaction-details.types";
import {
  eventFilterExpression,
  formContestationDescriptionFromTimelineItem,
  formTransactionDetailsFromTimelineItem,
  formTransactionRelatedItems,
  isChargebackTransactionFromTimelineItem,
  isInvoicePayment,
  isTimelineItemWithoutAmount,
  isTransaction,
  transactionFilterExpression,
} from "../utils/timeline";
import { handleTimestampHeaderFiltering } from "../utils/timestamp-header-filtering";
import { CellRowContent } from "./timeline.styles";
import {
  ICellRenderParams,
  ITimelineItem,
  ITimelineProps,
} from "./timeline.types";

type OInputDateRangeOnChangeValueType =
  | [moment.Moment, moment.Moment]
  | [string, string]
  | undefined;

export const Timeline = ({
  timelineData,
  selectedCompany,
  openContestations,
  setOpenContestations,
  upgradeTimelineDataSize,
  isUpgradeDataButtonActive,
}: ITimelineProps) => {
  const [selectedRowKey, setSelectedRowKey] = useState<string>();
  const [chargebackTransactionItemData, setChargebackTransactionItemData] =
    useState<IChargebackTransactionItem>();

  const [transactionDetailsData, setTransactionDetailsData] = useState<
    { data: ITransactionDetailsData; showOnModal?: boolean } | undefined
  >();

  const [transactionRelatedItems, setTransactionRelatedItems] = useState<
    ITransactionRelatedItem[] | undefined
  >();

  const [onRequestContestation, setOnRequestContestation] = useState(false);

  const [salesforceTokenInfo, setSalesforceTokenInfo] =
    useState<ISalesforceTokenInfo>();

  const [dateRangeValue, setDateRangeValue] =
    useState<OInputDateRangeOnChangeValueType>();

  const [dataFiltered, setDataFiltered] = useState<
    ITimelineItem[] | undefined
  >();

  useEffect(() => {
    const closeCleanUp = modalManager.on(
      "refusal-transaction-modal-id",
      "modalClose",
      () => {
        if (transactionDetailsData?.showOnModal) {
          setTransactionDetailsData(undefined);
        }
      },
    );

    return () => {
      closeCleanUp();
    };
  }, []);

  const getSalesforceTokenInfo = useCallback(async () => {
    try {
      const hash = auth.value; // window.location.hash.split("#/salesforce/")[1];
      const decodedHash = atob(hash.split(".")[1]);
      const { numberTicket, dhOpenTicket, caseType } = JSON.parse(decodedHash);
      const isChargeback = caseType === "Chargeback";
      setSalesforceTokenInfo({
        numberTicket,
        dhOpenTicket,
        isChargeback,
      });
    } catch (e) {
      OToastManager.danger(
        "Erro ao capturar informações do pedido. Por favor recarregue a página.",
      );
    }
  }, []);

  useEffect(() => {
    getSalesforceTokenInfo();
  }, [getSalesforceTokenInfo]);

  const detailsHandleClick = (data: ITimelineItem) => {
    const transactionDetails = formTransactionDetailsFromTimelineItem(data);
    setTransactionDetailsData({ data: transactionDetails });
    const transactionRelatedItems = formTransactionRelatedItems(data);
    setTransactionRelatedItems(transactionRelatedItems);
    setSelectedRowKey(data.correlationId);
    setChargebackTransactionItemData({
      creditAccountId: data.creditAccountId.toString(),
      authorizationId: data.data.item.id.toString(),
      description: formContestationDescriptionFromTimelineItem(data),
      amount: data.data.item.principalAmount,
    });
  };

  return (
    <CardSection>
      {transactionDetailsData?.showOnModal && (
        <RefusalTransactionModal info={transactionDetailsData.data} />
      )}
      <ColumnGap rowGap={30}>
        <RowSpacedContainer>
          <RowGap
            columnGap={20}
            className="px-2 align-items-center justify-content-between w-100"
            style={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <OTypography type="dark">Intervalo de tempo dos dados</OTypography>
            <OInputDateRange
              size="md"
              value={dateRangeValue}
              onChange={(dates) => {
                setDataFiltered(undefined);
                setDateRangeValue(undefined);
                const datesTime: (number | undefined | null)[] = [];
                dates?.forEach(
                  (date: moment.Moment | undefined | null, index: number) => {
                    if (!date) return;

                    if (index === 0) date.set({ h: 0, m: 0, s: 0 });
                    else if (index === 1) date.set({ h: 23, m: 59, s: 59 });

                    datesTime.push(date.toDate().getTime());
                  },
                );
                const [firstDate, secondDate] = datesTime;
                if (!firstDate || !secondDate) return;
                const newData = timelineData.filter((item: ITimelineItem) => {
                  const itemTime = moment(`${item.timestamp}Z`)
                    .toDate()
                    .getTime();
                  return itemTime > firstDate && itemTime < secondDate;
                });
                if (dates !== null)
                  setDateRangeValue(dates as OInputDateRangeOnChangeValueType);
                setDataFiltered(newData);
              }}
            />
            {isUpgradeDataButtonActive && (
              <OButton
                dataAction="cartoes-transaction-details:botao:carregar-mais-dados"
                dataLabel="carregar-mais-dados"
                outline
                bordered={false}
                onClick={upgradeTimelineDataSize}
              >
                Carregar mais dados
              </OButton>
            )}
          </RowGap>
        </RowSpacedContainer>
        <DataGrid
          id="grid-container-timeline"
          keyExpr="correlationId"
          width="100%"
          rowAlternationEnabled={!selectedRowKey}
          selectedRowKeys={[selectedRowKey]}
          dataSource={dataFiltered || timelineData}
          showColumnLines
          showRowLines
          noDataText="Nenhuma informação de Linha do tempo encontrada."
          columnAutoWidth
          scrolling={{
            showScrollbar: "always",
          }}
          headerFilter={{
            visible: true,
          }}
          pager={{
            showInfo: true,
            visible: true,
            allowedPageSizes: [10, 20, 30],
            showPageSizeSelector: true,
          }}
        >
          <FilterRow visible />
          <Paging defaultPageSize={10} />
          <Column
            caption="Tipo"
            filterOperations={["contains"]}
            calculateFilterExpression={(filterValue: any) => {
              if ("Evento".includes(filterValue)) {
                return eventFilterExpression();
              }
              return transactionFilterExpression();
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="type"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.type) return "-";
              return data.type === ItemType.Transaction ||
                data.type === ItemType.Confirmation ||
                (data.type === ItemType.Statement &&
                  (data.category === ItemCategory.Payment ||
                    data.category === ItemCategory.PartialPayment))
                ? "Transação"
                : "Evento";
            }}
          />
          <Column
            caption="Categoria"
            filterOperations={["contains"]}
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              const key = Object.keys(MapperItemCategoryAndType).find((key) =>
                MapperItemCategoryAndType[
                  key as keyof typeof MapperItemCategoryAndType
                ].includes(filterValue),
              );
              return ["category", selectedFilterOperation, key];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="category"
            width={220}
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.category) return "-";
              if (data.category !== ItemCategory.Refusal) {
                if (data.type === ItemType.Confirmation)
                  return MapperItemCategoryAndType[data.type];
                return MapperItemCategoryAndType[data.category];
              }
              return (
                <CellRowContent>
                  <span style={{ fontSize: "13px" }}>
                    {MapperItemCategoryAndType[data.category]}
                  </span>
                  <OButton
                    outline
                    bordered={false}
                    disabled={
                      !(
                        !!data.data.item.refusalReason ||
                        !!data.data.item.attendantRefusalReason
                      )
                    }
                    dataAction="cartoes-transaction-details:botao:info"
                    dataLabel="info"
                    type="danger"
                    onClick={() => {
                      if (
                        !!data.data.item.refusalReason ||
                        !!data.data.item.attendantRefusalReason
                      ) {
                        const transactionDetails =
                          formTransactionDetailsFromTimelineItem(data);
                        setTransactionDetailsData({
                          data: transactionDetails,
                          showOnModal: true,
                        });
                        setSelectedRowKey(undefined);
                      }
                    }}
                  >
                    <OIcon category="orq" icon="orq-status-error" />
                  </OButton>
                </CellRowContent>
              );
            }}
          />
          <Column
            caption="Data e Hora"
            calculateFilterExpression={(
              filterValue: string,
              selectedFilterOperation: string,
            ) => {
              const timeZoneOffset: [number, number, number] = [3, 0, 0];
              const value = handleTimestampHeaderFiltering(
                filterValue,
                timeZoneOffset,
              );
              return ["timestamp", selectedFilterOperation, value];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="timestamp"
            cellRender={(params: ICellRenderParams) => {
              if (!params.data.timestamp) return "-";
              const {
                data: { timestamp },
              } = params;
              const hasFullTimezone = timestamp.includes("Z");
              return moment(
                hasFullTimezone ? timestamp : `${timestamp}Z`,
              ).format("DD/MM/YYYY HH:mm:ss");
            }}
          />
          <Column
            caption="Título"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              return ["data.item.title", selectedFilterOperation, filterValue];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.title"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.data.item?.title) return "-";
              return data.data.item.title;
            }}
          />
          <Column
            caption="Subtítulo"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              return [
                "data.item.subTitle",
                selectedFilterOperation,
                filterValue,
              ];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.subTitle"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.data.item?.subTitle) return "-";
              return data.data.item.subTitle;
            }}
          />
          <Column
            caption="Descrição"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              return [
                "data.item.authorizationDescription",
                selectedFilterOperation,
                filterValue,
              ];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.authorizationDescription"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.data.item?.authorizationDescription) return "-";
              return data.data.item.authorizationDescription;
            }}
          />
          <Column
            caption="Cartão Final"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              return [
                "data.item.cardLast4Digits",
                selectedFilterOperation,
                filterValue,
              ];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.cardLast4Digits"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.data.item?.cardLast4Digits) return "-";
              return data.data.item.cardLast4Digits;
            }}
          />
          <Column
            caption="Id"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              return ["data.item.id", selectedFilterOperation, filterValue];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.id"
            cellRender={({ data }: ICellRenderParams) => {
              if (!data.data.item?.id) return "-";
              if (isTimelineItemWithoutAmount(data.type)) return "";
              return data.data.item.id;
            }}
          />
          <Column
            caption="Valor"
            calculateFilterExpression={(
              filterValue: any,
              selectedFilterOperation: string,
            ) => {
              const value = removeCipherFormatter(filterValue);
              return [
                "data.item.principalAmount",
                selectedFilterOperation,
                value,
              ];
            }}
            allowHeaderFiltering={false}
            dataType="string"
            dataField="data.item.principalAmount"
            cellRender={({ data }: ICellRenderParams) => {
              let amount;
              if (isInvoicePayment(data.type, data.category))
                amount = data.data.item.localAmount;
              else if (!isTimelineItemWithoutAmount(data.type))
                amount = data.data.item?.principalAmount;
              return (amount && MoneyMask(amount, Currency.BRL)) || "-";
            }}
          />
          <Column
            caption="Ação"
            allowHeaderFiltering={false}
            type="buttons"
            buttons={[
              {
                visible: (e: { row: ICellRenderParams }) => {
                  return isTransaction(e.row.data.type);
                },
                text: "Detalhes",
                onClick: (e: { row: ICellRenderParams }) => {
                  detailsHandleClick(e.row.data);
                },
              },
            ]}
          />
        </DataGrid>
        {selectedRowKey && transactionDetailsData && (
          <TransactionDetails
            transactionDetailsData={transactionDetailsData.data}
            transactionRelatedItems={transactionRelatedItems}
            closeTransactionDetails={() => {
              setSelectedRowKey(undefined);
            }}
            onRequestContestationData={{
              onRequestContestation,
              setOnRequestContestation,
            }}
            isChargeback={!!salesforceTokenInfo?.isChargeback}
            isChargebackTransaction={isChargebackTransactionFromTimelineItem(
              transactionDetailsData.data.itemType,
              transactionDetailsData.data.itemCategory,
              transactionDetailsData.data.isCredit,
            )}
            openContestations={openContestations}
          />
        )}

        {chargebackTransactionItemData &&
          salesforceTokenInfo &&
          onRequestContestation && (
            <TransactionChargeBack
              chargebackTransactionItemData={chargebackTransactionItemData}
              finishRequestContestation={() => {
                setOnRequestContestation(false);
                if (!setOpenContestations) return;
                setOpenContestations((prev) => {
                  const { authorizationId } = chargebackTransactionItemData;
                  if (prev) return prev.concat(authorizationId);
                  else return [authorizationId];
                });
              }}
              selectedCompany={selectedCompany}
              salesforceTokenInfo={salesforceTokenInfo}
            />
          )}
      </ColumnGap>
    </CardSection>
  );
};
