/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import { logger } from "utils/logger";
import { clearSearchParam } from "utils/search-params/clear-search-param";
import { getSearchParams } from "utils/search-params/get-search-params";
import { Behavior } from "./behavior";
import { getBehaviorFromDomain } from "./behavior.utils";
import { configs } from "./configs";
import { Behaviors, isBehavior } from "./types";
import { BehaviorCommand } from "./types/command";
import { BehaviorConfiguration } from "./types/config";
import { BehaviorSearchParams, behaviorSearchKey } from "./types/query-string";

export class ChangeBehaviorCommand implements BehaviorCommand {
  private behaviorId: Behaviors;

  constructor(behaviorId: Behaviors) {
    this.behaviorId = behaviorId;
  }

  async execute(behavior: Behavior) {
    const newBehavior = configs[this.behaviorId];
    if (newBehavior) {
      behavior.value = newBehavior;
      behavior.state = "LOADED";
    } else {
      behavior.state = "ERROR";
      throw new Error(
        `[Behavior]Unknown behavior identification: ${this.behaviorId}.`,
      );
    }
  }
}

export class LoadBehaviorCommand implements BehaviorCommand {
  async execute(behavior: Behavior) {
    const params = getSearchParams<BehaviorSearchParams>();
    const searchId = params[behaviorSearchKey];
    const storageId = Behavior.storage.get();
    const domainId = getBehaviorFromDomain();

    let config: BehaviorConfiguration;

    if (isBehavior(searchId) && configs[searchId]) {
      config = configs[searchId];
    } else if (isBehavior(storageId) && configs[storageId]) {
      config = configs[storageId];
    } else if (isBehavior(domainId) && configs[domainId]) {
      config = configs[domainId];
    } else {
      behavior.state = "ERROR";
      throw new Error(
        `[Behavior]Unable to initialize behavior. \`searchId\` is ${searchId}, \`storageId\` id ${storageId} and \`domainId\` is ${domainId}.`,
      );
    }

    behavior.value = config;
    behavior.state = "LOADED";

    clearSearchParam(behaviorSearchKey);
  }
}

export class InitializeBehaviorCommand implements BehaviorCommand {
  async execute(behavior: Behavior) {
    const oldState = behavior.state;
    if (behavior.state !== "LOADED") {
      behavior.state = "ERROR";
      throw new Error(
        `[Behavior]A behavior must be loaded before being initialized. The state is ${oldState}.`,
      );
    }
    if (!behavior.value) {
      behavior.state = "ERROR";
      throw new Error(
        `[Behavior]The behavior is unexpectedly undefined. The state is ${oldState}.`,
      );
    }

    try {
      behavior.value.init();
    } catch (err) {
      logger.error(err);
      behavior.state = "ERROR";
      throw new Error(
        `[Behavior]An error occurred during the initialization of ${behavior.value.id}.`,
      );
    }
  }
}
