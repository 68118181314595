import { OToastManager } from "@maestro/core";
import {
  OButton,
  OCard,
  OCardBody,
  OInputSearch,
  OLoader,
  OModal,
  OModalBody,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";
import { OModalDiv } from "./add-component-modal.styles";

export const AddComponentModal = () => {
  const {
    form: { callService: getForm, value: workflowFormTemplate },
    selectedCard,
    components: { callService: getComponentsList, value: componentsList },
  } = useWorkflowFormTemplate();

  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const addComponent = useCallback(
    async (component: NonNullable<typeof componentsList>[number]) => {
      try {
        if (!workflowFormTemplate) return;

        setLoading(true);

        await service.hubCreditManager.addFormStepCardComponent({
          formStepCardId: selectedCard?.id,
          componentId: component.id,
          position: selectedCard?.components?.length
            ? selectedCard.components
                .map((c) => c.position)
                .reduce((a, b) => Math.max(a, b)) + 1
            : 0,
        });

        getForm(workflowFormTemplate?.id);
        OToastManager.success("Componente adicionado com sucesso.");
      } catch {
        OToastManager.danger("Não foi possível adicionar o componente.");
      } finally {
        setLoading(false);
      }
    },
    [workflowFormTemplate, getForm, selectedCard],
  );

  useEffect(() => {
    getComponentsList();
  }, [getComponentsList]);

  const filteredComponents = componentsList?.filter(
    (component) =>
      !search || component.name.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <OModal
      id="add-component-modal"
      position="center"
      size="md"
      className="position-absolute h-100"
    >
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar componente
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {loading && <OLoader size="xl" absolute />}
        <OInputSearch
          placeholder="Buscar componente pelo nome"
          id="component-search"
          name="component-search"
          autocomplete="off"
          value={search}
          onInput={(e) => setSearch(e.currentTarget.value)}
        />
        <OModalDiv className="d-flex flex-column gap-1 mt-4">
          {!filteredComponents?.length && (
            <OTypography className="text-center mt-5">
              Nenhum componente disponível para adição
            </OTypography>
          )}
          {filteredComponents?.map((fc) => (
            <OCard key={fc.id}>
              <OCardBody>
                <div className="d-flex flex-column gap-3">
                  <OTypography weight="bold" key={`name_${fc.name}`}>
                    {fc.name}
                  </OTypography>
                  <OTypography key={`description_${fc.description}`}>
                    {fc.description}
                  </OTypography>
                  <div className="d-flex justify-content-end">
                    <OButton
                      type="dark"
                      onClick={() => {
                        addComponent(fc);
                      }}
                    >
                      Adicionar
                    </OButton>
                  </div>
                </div>
              </OCardBody>
            </OCard>
          ))}
        </OModalDiv>
      </OModalBody>
    </OModal>
  );
};
