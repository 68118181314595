import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoLeadById } from "../../../../../../routes/workflow.route-params";
import { WorkflowPendencyForm } from "./workflow-pendency-form.component";
import {
  WorkflowPendencyFormValues,
  workflowPendencyValidationSchema,
} from "./workflow-pendency-form.schemas";

export const WorkflowAddPendencyPage = () => {
  const form = useForm<WorkflowPendencyFormValues>({
    resolver: yupResolver(workflowPendencyValidationSchema),
  });

  const { id } = useParams<WorkflowProdutoLeadById>();

  return (
    <FormTemplate pageTitle={<PageTitle title="Adicionar pendência" />}>
      <FormProvider {...form}>
        <WorkflowPendencyForm leadId={Number(id)} />
      </FormProvider>
    </FormTemplate>
  );
};
