import * as yup from "yup";

export const addResaleFormValidationSchema = yup.object().shape({
  identification: yup.string().nullable().trim().required("Campo obrigatório"),
  name: yup.string().nullable().trim().required("Campo obrigatório"),
  state: yup.string().nullable().trim().required("Campo obrigatório"),
  city: yup.string().nullable().trim().required("Campo obrigatório"),
});

export const addResaleFormDefaultValues = {
  name: "",
  identification: "",
  state: "",
  city: "",
};
