import { isValidElement } from "react";

interface ElementOrFallbackProps {
  node: React.ReactNode;
  fallback: JSX.Element;
}

export const ElementOrFallback = ({
  node,
  fallback,
}: ElementOrFallbackProps) => {
  if (isValidElement(node) || node === null) return node;

  return fallback;
};
