import { OModalBody, OModalHeader, OTypography } from "@maestro/react";
import { ThemeSwitch } from "components/configuration-modal/_compose";
import dayjs from "dayjs";
import { ShadowContainer } from "../shadow-container";

interface EmailModalProps {
  note: HubEnergy.DiligenceStepPendencyNoteSimpleResponse;
}

export const EmailModal = ({ note }: EmailModalProps) => {
  return (
    <>
      <OModalHeader
        closeButton
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "var(--theme-light)",
          boxShadow: "0px 0px 5px var(--theme-light-inverse)",
        }}
      >
        <div className="d-flex flex-column">
          <OTypography size="lg">
            Anotação por {note.author} em{" "}
            {dayjs(note.createdDate).format("DD/MM/YYYY, HH:mm:ss")}
          </OTypography>
          <ThemeSwitch />
        </div>
      </OModalHeader>
      <OModalBody style={{ height: "100%" }}>
        <div className="pt-2 pb-5">
          <ShadowContainer>
            <div dangerouslySetInnerHTML={{ __html: note.content }} />
          </ShadowContainer>
        </div>
      </OModalBody>
    </>
  );
};
