import { OToastManager } from "@maestro/react";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { PostApiDesksGetEntityDesksResponse } from "services/clerk";
import { AddPayerFormFields } from "../../add-payer.types";

export const useInitialStep = (
  configurations: ScfDevelopers.PartnerConfiguration[] | undefined,
  possibleDesks: PostApiDesksGetEntityDesksResponse | undefined,
  setCurrentStep: (value: string) => void,
) => {
  const [loading, setLoading] = useState(false);

  const form = useFormContext<AddPayerFormFields>();
  const { register, setValue, watch } = form;

  const configurationIdWatch = watch("partnerConfigurationId");
  const identificationWatch = watch("identification");

  const possibleConfigurations = useMemo(() => {
    if (!configurations || !possibleDesks) {
      return [];
    }

    return configurations.filter((configuration) =>
      configuration.desks.find((desk) =>
        possibleDesks.find((entity) => entity.code === desk),
      ),
    );
  }, [configurations, possibleDesks]);

  const chosenConfiguration = useMemo(() => {
    if (!configurationIdWatch || !configurations) {
      return null;
    }

    return configurations?.find(
      (configuration) => configuration.configurationId === configurationIdWatch,
    );
  }, [configurationIdWatch, configurations]);

  const onSubmit = useCallback(async () => {
    if (!chosenConfiguration) {
      return;
    }

    setValue("authClientId", chosenConfiguration.authClientId);
    setValue(
      "partnerConfigurationId",
      chosenConfiguration.configurationId ?? "",
    );

    setLoading(true);
    try {
      const payload: ScfDevelopers.CheckIfUpserIsavailable = {
        authClientId: chosenConfiguration.authClientId,
        configurationId: chosenConfiguration.configurationId ?? "",
        taxId: identificationWatch,
      };
      const { data } = await service.scfDevelopers.checkIfCanUpsert(payload);
      if (data.canUpsert) {
        setCurrentStep("EXTRA_DATA");
      } else {
        OToastManager.danger("Já existe sacado simples desse cnpj/cpf");
      }
    } catch {
      OToastManager.danger("Erro ao verificar se sacado existe");
    } finally {
      setLoading(false);
    }
  }, [chosenConfiguration, identificationWatch, setCurrentStep, setValue]);

  return {
    chosenConfiguration,
    identificationWatch,
    loading,
    onSubmit,
    possibleConfigurations,
    register,
  };
};
