import * as yup from "yup";

export interface EditGridFormFields {
  name: string;
  configurableGridColumns: string[];
}

export const editGridSchema = yup.object().shape({
  name: yup.string().required("É necessário preencher este campo"),
  configurableGridColumns: yup.array().of(yup.string()),
});

export const editGridDefaultValues = {
  name: "",
  configurableGridColumns: [] as string[],
};
