import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { RavenFormProps } from "./raven-form.types";

export const RavenForm = ({ form }: RavenFormProps) => {
  return (
    <CardTemplate>
      <FormProvider {...form}>
        <form>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                dataAction="raven:input:nome"
                dataLabel="nome"
                label="Nome"
                description={`Aceito somente números, letras e "_".`}
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="type"
                name="type"
                dataAction="raven:input:tipo"
                dataLabel="tipo"
                placeholder="Tipo"
                label="Tipo"
              >
                <OOption id="main" value="MAIN">
                  MAIN
                </OOption>
                <OOption id="sub" value="SUB">
                  SUB
                </OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="fileKey"
                name="fileKey"
                dataAction="raven:input:diretorio_do_arquivo"
                dataLabel="diretorio_do_arquivo"
                label="Diretório do Arquivo"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="defaultTitle"
                name="defaultTitle"
                dataAction="raven:input:assunto_titulo_padrao_do_email"
                dataLabel="assunto_titulo_padrao_do_email"
                label="Assunto/Título Padrão do E-mail"
              />
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldInput
                tag="text"
                id="variables"
                name="variables"
                dataAction="raven:input:variaveis"
                dataLabel="variaveis"
                label="Variáveis"
                description="Variáveis para customizar o template separadas por vírgula."
              />
            </OCol>
          </ORow>
        </form>
      </FormProvider>
    </CardTemplate>
  );
};
