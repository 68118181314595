import { OToastManager } from "@maestro/core";
import { OButton, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { Condition } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CodingTextArea } from "../coding-text-area/coding-text-area.component";
import { OTypographyStyled } from "../coding-text-area/coding-text-area.styles";

export interface TestConditionFormValues {
  workflow: string;
  result: Awaited<
    ReturnType<typeof service.hubCreditManager.executeCondition>
  >["data"];
}

interface TestConditionFormProps {
  condition: Condition;
}

export const TestConditionForm = ({
  condition: { conditionName, expression },
}: TestConditionFormProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const { getValues, setValue, handleSubmit } =
    useFormContext<TestConditionFormValues>();

  const executeCondition = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const { data } = await service.hubCreditManager.executeCondition({
            testingWorkflow: JSON.parse(values.workflow),
            conditionsNames: conditionName ? [conditionName] : [],
          });

          setValue("result", data);
          setResult(data.toString());
          OToastManager.success("Teste realizado com sucesso.");
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao testar a condição. Por favor, certifique-se de que o objeto esteja no formato correto.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [conditionName, handleSubmit, setValue],
  );

  return (
    <FormTemplate
      actions={
        <>
          <OButton
            className="text-right"
            dataAction="testar_condicao:botao:indentar"
            dataLabel="indentar"
            type="dark"
            outline
            onClick={async () => {
              try {
                setValue(
                  "workflow",
                  JSON.stringify(JSON.parse(getValues("workflow")), null, 8),
                );
              } catch {
                return false;
              }
            }}
          >
            Indentar
          </OButton>
          <LoadingButton
            loading={loading}
            className="text-right"
            dataAction="testar_condicao:botao:executar"
            dataLabel="executar"
            type="dark"
            onClick={() => executeCondition()}
          >
            Executar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <div className="d-flex flex-column gap-3 mb-5">
          <OTypography size="sm">Expressão</OTypography>
          <OTypographyStyled weight="lighter" key={conditionName}>
            {expression}
          </OTypographyStyled>
        </div>
        <div className="d-flex flex-column gap-4 mb-4">
          <form spellCheck={false}>
            <CodingTextArea
              id="workflow"
              name="workflow"
              tag="textarea"
              label="Workflow para teste"
              dataAction="testar_condicao:texto:workflow"
              dataLabel="workflow"
              adaptHeight
            />
          </form>
          <div className="d-flex flex-column gap-3 mb-5">
            <OTypography size="sm">Retorno</OTypography>
            <OTypographyStyled
              weight="lighter"
              key={result}
            >{`> ${result}`}</OTypographyStyled>
          </div>
        </div>
      </CardTemplate>
    </FormTemplate>
  );
};
