import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { format } from "utils/date";
import { OnboardingReviewEditType } from "./onboarding-review-edit.type";

export type DataReview = NonNullable<
  PostAdminCompaniesGetByTaxIdResponse["dataReviews"]
>[number];

export const useOnboardingReviewEdit = ({ uuid }: OnboardingReviewEditType) => {
  const navigate = useNavigate();

  const dataReviewDetails = useServiceCall(
    service.onboarding.getDataReviewDetails,
  );
  const dataReviewEnums = useServiceCall(service.onboarding.getDataReviewEnums);

  const { callService, value: dataReview } = dataReviewDetails;
  const { callService: getEnums } = dataReviewEnums;

  const [loading, setLoading] = useState(false);

  const form = useForm<DataReview>();
  const { reset, handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        portalStatus: values.status,
        portalNotificationStatus: values.notificationStatus,
      };

      await service.onboarding.updateDataReviewByUuid(uuid, payload);

      OToastManager.success("Revisão cadastral editada com sucesso");

      navigate(
        corporateRouter.routes.cadastro.customer.details.onboardingReviews.path,
      );

      OToastManager.success(
        "Aguarde alguns minutos para que a atualização seja feita",
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    callService(uuid);
    getEnums();
  }, [callService, getEnums, uuid]);

  useEffect(() => {
    if (!dataReview) return;

    const {
      portalStatus,
      portalNotificationStatus,
      createdDate,
      updatedDate,
      workflowDataReviewConfigType,
    } = dataReview;

    const resetValues = {
      notificationStatus: portalNotificationStatus,
      status: portalStatus,
      type: workflowDataReviewConfigType,
      insertedAt: format.date(createdDate),
      updatedAt: updatedDate && format.date(updatedDate),
    };

    reset(resetValues);
  }, [uuid, reset, dataReview]);

  return {
    form,
    submit,
    loading,
    dataReviewDetails,
    dataReviewEnums,
  };
};
