import { OToastManager } from "@maestro/react";
import { service } from "services";
import { StatusId, TooltipInfoId } from "./clientes.type";

export const statusBiometryTooltipMap: TooltipInfoId = {
  NOT_CREATED: "Cliente ainda não iniciou o cadastro de biometria",
  CREATED: "Cliente iniciou processo de cadastro, aguardando processamento.",
  STARTED: "Cliente iniciou processo de cadastro, aguardando processamento.",
  NO_ID_UPLOADED:
    "Cadastro falhou por falta de documento, cliente precisa iniciar um novo cadastro.",
  ERROR_NOT_READABLE_ID:
    "Cadastro falhou por documento ilegível, cliente precisa iniciar um novo cadastro.",
  DENIED_UNSUPPORTED_ID_TYPE:
    "Cadastro falhou pelo documento não ser aceito, cliente precisa iniciar um novo cadastro.",
  DENIED_UNSUPPORTED_ID_COUNTRY:
    "Cadastro falhou pelo documento não ser aceito, cliente precisa iniciar um novo cadastro.",
  RESET:
    "Cadastro cancelado manualmente, cliente precisa iniciar um novo cadastro.",
  APPROVED_VERIFIED:
    "Cadastro concluído com sucesso. Se tiver relacionamento com a tabela 'manual_approvals', indica aprovação manual.",
  APPROVED:
    "Cadastro concluído com sucesso. Se tiver relacionamento com a tabela 'manual_approvals', indica aprovação manual.",
  APPROVED_MOCK: "",
  DENIED_FRAUD: "Não foi possível validar pessoa da foto.",
  NO_MATCH: "Pessoa da foto diferente do documento.",
  NOT_POSSIBLE: "Não foi possível validar pessoa da foto",
  REJECTED: "Não foi possível validar pessoa da foto",
  PENDING: "Cliente não iniciou o processo de cadastro",
  FAILED:
    "Cadastro falhou na leitura do documento, cliente precisa iniciar um novo cadastro.",
  RESET_FACE:
    "Cliente iniciou o processo de cadastro, porem foi solicitado a revalidação facial",
  WAITING_APPROVAL:
    "Cliente iniciou o processo de cadastro e esta aguardando Aprovação",
};

export const statusBiometryMap: StatusId = {
  NOT_CREATED: {
    label: "Não inicializada",
    type: "info",
  },
  CREATED: {
    label: "Criada",
    type: "info",
  },
  STARTED: {
    label: "Criada",
    type: "info",
  },
  NO_ID_UPLOADED: {
    label: "Falha no documento",
    type: "danger",
  },
  ERROR_NOT_READABLE_ID: {
    label: "Documento ilegível",
    type: "danger",
  },
  DENIED_UNSUPPORTED_ID_TYPE: {
    label: "Documento nao suportado",
    type: "danger",
  },
  DENIED_UNSUPPORTED_ID_COUNTRY: {
    label: "Documento nao suportado",
    type: "danger",
  },
  RESET: {
    label: "Cancelado manualmente",
    type: "warning",
  },
  APPROVED_VERIFIED: {
    label: "Cadastro concluído com sucesso",
    type: "success",
  },
  APPROVED: {
    label: "Cadastro concluído com sucesso",
    type: "success",
  },
  APPROVED_MOCK: {
    label: "Mock aprovado",
    type: "success",
  },
  DENIED_FRAUD: {
    label: "Foto inválida",
    type: "danger",
  },
  NO_MATCH: {
    label: "Documento não confere",
    type: "danger",
  },
  NOT_POSSIBLE: {
    label: "Foto inválida",
    type: "danger",
  },
  REJECTED: {
    label: "Foto inválida",
    type: "danger",
  },
  PENDING: {
    label: "Pendente Coleta",
    type: "warning",
  },
  FAILED: {
    label: "Foto inválida",
    type: "danger",
  },
  RESET_FACE: {
    label: "Pendente Validação Facial",
    type: "warning",
  },
  WAITING_APPROVAL: {
    label: "Aguardando Aprovação",
    type: "info",
  },
  UNDEFINED: {
    label: "Indefinida",
    type: "info",
  },
};

export const statusAccountTooltipMap: TooltipInfoId = {
  CONFIRMED: "Usuário cadastrou a senha e confirmou o login",
  FORCE_CHANGE_PASSWORD: "Usuário realizando o processo esqueci minha senha",
  RESET_REQUIRED:
    "Usuário precisa atualizar a senha para realizar o login por CPF",
  UNCONFIRMED: "Usuário cadastrou a senha, mas nao confirmou o login",
  NOT_CREATED: "Usuário nao cadastrou a senha no portal",
};

export const statusAccountMap: StatusId = {
  CONFIRMED: {
    label: "Confirmado",
    type: "success",
  },
  FORCE_CHANGE_PASSWORD: {
    label: "Aguardando nova senha",
    type: "danger",
  },
  RESET_REQUIRED: {
    label: "Requer reset",
    type: "danger",
  },
  UNCONFIRMED: {
    label: "Não confirmado",
    type: "warning",
  },
  NOT_CREATED: {
    label: "Não existe",
    type: "info",
  },
};

export const documentsMap = {
  PROCURACAO: "Procuração",
  ATA_ELEICAO_DIRETORIA: "Ata eleição diretoria",
  CONTRATO_SOCIAL: "Contrato social",
  CCMEI: "CCMEI",
  ESTATUTO_SOCIAL: "Estatuto social",
  REQUERIMENTO_EMPRESARIO: "Requerimento empresário",
  INDEFINIDO: "Indefinido",
  OUTROS: "Outros",
};

export const statusOnboardingTooltipMap = {
  PROXIMO_DO_VENCIMENTO: "Os poderes estão próximos da data do vencimento",
  PENDENTE_ASSINATURA:
    "Os signatários deverão assinar o termo de delegação de poderes",
  PENDENTE_BIOMETRIA: "O usuário ainda não validou a biometria",
  VALIDACAO_PENDENTE:
    "Para concluir o cadastro do usuário, um dos gestores deverá realizar a validação de segurança",
  INATIVO: "O usuário foi inativado da empresa",
  VENCIDO: "Os poderes do representante legal expiraram",
  REPROVADO: "Usuário reprovado no cadastro",
  ATIVO: "Usuário habilitado",
  SEM_PERFIL: "Usuário sem perfil definido na empresa",
  SEM_ACESSO: "Usuário do portal de parceiros, sem acesso à conta PJ",
  REPRESENTANTE_LEGAL_SEM_ACESSO:
    "Para que o representante legal tenha acesso à(s) conta(s) é necessário cadastrá-lo na plataforma",
  PENDENTE_ENVIO: "Usuário precisa enviar documento",
  INDEFINIDO: "",
  PENDENTE_ATIVACAO_EMAIL:
    "O e-mail foi enviado, mas usuário não finalizou o cadastro.",
};

export const statusOnboardingMap: StatusId = {
  PROXIMO_DO_VENCIMENTO: {
    label: "Próximo do vencimento",
    type: "warning",
  },
  PENDENTE_ASSINATURA: {
    label: "Próximo assinatura",
    type: "warning",
  },
  PENDENTE_BIOMETRIA: {
    label: "Biometria pendente",
    type: "warning",
  },
  VALIDACAO_PENDENTE: {
    label: "Validação pendente",
    type: "warning",
  },
  INATIVO: {
    label: "Inativo",
    type: "warning",
  },
  VENCIDO: {
    label: "Vencido",
    type: "danger",
  },
  REPROVADO: {
    label: "Reprovado",
    type: "danger",
  },
  ATIVO: {
    label: "Ativo",
    type: "success",
  },
  SEM_PERFIL: {
    label: "Selecionar perfil",
    type: "info",
  },
  SEM_ACESSO: {
    label: "Sem acesso à conta",
    type: "info",
  },
  REPRESENTANTE_LEGAL_SEM_ACESSO: {
    label: "Representante legal sem acesso",
    type: "warning",
  },
  PENDENTE_ENVIO: {
    label: "Pendente envio",
    type: "warning",
  },
  INDEFINIDO: {
    label: "Indefinido",
    type: "info",
  },
  PENDENTE_ATIVACAO_EMAIL: {
    label: "Aguardando usuário",
    type: "warning",
  },
};

export const sendWelcomeEmail = async (cpf: string, companyTaxId: string) => {
  service.onboarding
    .sendWelcomeEmail({
      data: [
        {
          taxId: cpf,
          relatedTaxId: companyTaxId,
        },
      ],
    })
    .then(() =>
      OToastManager.success("E-mail de boas-vindas enviado com sucesso!"),
    )
    .catch(() =>
      OToastManager.danger("Não foi possível reenviar o e-mail de boas-vindas"),
    );
};

export const invalidateToken = async (cpf: string) => {
  service.gatekeeper
    .invalidateByCpf({ cpf })
    .then(() =>
      OToastManager.success(
        "Os tokens de todos os dispositivos foram revogados com sucesso",
      ),
    )
    .catch(() => OToastManager.danger("Não foi possível revogar os tokens"));
};
