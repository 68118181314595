import { OCard, OCardBody, OTypography } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { LimitQuotesFormValues, Quotes } from "./limit-conditions.types";

type InstallmentOption = Quotes[number]["installmentOptions"][number];

const limitQuotesMasterDetailGrid = (
  onChange: (installmentOptions: InstallmentOption[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum arquivo",
      editing: {
        mode: "batch",
        allowUpdating: true,
      },
      onRowUpdated: ({ component }) => {
        onChange(
          component.getVisibleRows().map((row) => ({
            ...row.data,
            percentageAmortization: row.data.percentageAmortization / 100,
          })),
        );
      },
      onRowValidating: (e) => {
        const percentSum = e.component
          .getVisibleRows()
          .reduce((sum, row) => sum + row.data.percentageAmortization, 0);

        if (percentSum !== 100) {
          e.errorText = "A soma dos percentuais de amortização deve ser 100%";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "installmentNumber",
        caption: "Parcela",
        alignment: "center",
        allowEditing: false,
      },
      {
        dataField: "percentageAmortization",
        caption: "Percentual de Amortização (%)",
        alignment: "center",
        dataType: "number",
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "O valor deste campo deve ser maior do que 0",
            validationCallback: ({ value }) => {
              return value >= 0;
            },
          },
          {
            type: "custom",
            message: "O valor deste campo não pode ser maior do que 100",
            validationCallback: ({ value }) => {
              return value <= 100;
            },
          },
          {
            type: "custom",
            message: "Este campo só pode conter até 2 casas decimais",
            validationCallback: ({ value }) => {
              return String(value % 1).length <= 2;
            },
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<InstallmentOption>);

type LimitQuotesInstallmentMasterDetailProps = MasterDetailComponentProps<
  Quotes[number]
>;

export const LimitQuotesInstallmentMasterDetail = ({
  data: { data, rowIndex },
}: LimitQuotesInstallmentMasterDetailProps) => {
  const { setValue, getValues } = useFormContext<LimitQuotesFormValues>();

  const grid = useMemo(() => {
    return limitQuotesMasterDetailGrid((newInstallmentOptions) => {
      setValue(
        "quotes",
        getValues("quotes").map((quote, index) => ({
          ...quote,
          installmentOptions:
            index === rowIndex - 1
              ? newInstallmentOptions
              : quote.installmentOptions,
        })),
      );
    });
  }, [getValues, rowIndex, setValue]);

  if (
    data.minTerm !== data.maxTerm ||
    typeof data.minTerm !== "number" ||
    typeof data.maxTerm !== "number"
  ) {
    return (
      <OCard>
        <OCardBody>
          <OTypography type="dark">
            É necessário que os respectivos campos &quot;De&quot; e
            &quot;Até&quot; sejam iguais e a frequência de amortização seja
            diferente de &quot;Bullet&quot; para configurar o parcelamento.
          </OTypography>
        </OCardBody>
      </OCard>
    );
  }

  return (
    <ODataGridGenerator
      grid={grid}
      dataSource={
        data.installmentOptions.length === data.maxTerm
          ? data.installmentOptions.map((option) => ({
              ...option,
              percentageAmortization: option.percentageAmortization * 100,
            }))
          : Array.from(
              { length: data.maxTerm },
              (_, index) =>
                ({
                  installmentNumber: index + 1,
                } as InstallmentOption),
            )
      }
    />
  );
};
