import { DetailsFields } from "components/details-card";
import { ClipboardButton } from "../../../../../../../../components/clipboard-button";

export const stepRelatedInfoFields = (
  details: Record<string, string>,
): DetailsFields => ({
  details: {
    items: Object.entries(details).map(([key, value]) => ({
      label: key,
      value: (
        <div className="d-flex align-items-center gap-2">
          <span style={{ wordBreak: "break-all" }}>{value}</span>
          <ClipboardButton content={value} />
        </div>
      ),
      grid: { md: 6, lg: 4 },
    })),
  },
});
