import { ODataGridGeneratorConfig } from "components/data-grid";
import { masks } from "@maestro/utils";
import { InvitesMasterDetails } from "./_compose/invites-master-details";
import { CustomerInviteEvents } from "./customer-invite-details.types";

export const customerInviteDetailsGrid: ODataGridGeneratorConfig<CustomerInviteEvents> =
  {
    datagrid: {
      noDataText: "Nenhum convite aberto",
      filterRow: { visible: true },
      headerFilter: { visible: true },
    },
    columns: [
      {
        dataType: "string",
        caption: "Identificação",
        allowHeaderFiltering: false,
        calculateCellValue: (data) =>
          data.receiver ? data.receiver : masks.cpf(data.cpf),
      },
      {
        dataField: "insertedAt",
        dataType: "datetime",
        format: "shortDate",
        caption: "Criado em",
        allowHeaderFiltering: false,
      },
      {
        dataField: "type",
        dataType: "string",
        caption: "Tipo",
        allowHeaderFiltering: false,
      },
      {
        dataField: "ravenStatus",
        dataType: "string",
        caption: "Status",
      },
    ],
    masterDetail: {
      enabled: true,
      component: InvitesMasterDetails,
    },
  };
