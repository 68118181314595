import {
  OConfirmationModal,
  OIcon,
  OLoader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { UnstyledButton } from "components/unstyled-button";
import type dxDataGrid from "devextreme/ui/data_grid";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { buildCustomerLink } from "utils/build-customer-link";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { useGridActions } from "../../../../cliente/unidades-consumidoras/[consumerUnitId]/migracao/[stepId]/_compose/pendencies/_compose/grid-actions/grid-actions.hook";

const startAutomationConfirmationModalId = (id: string | number) =>
  `start-automation-confirmation-modal-id-${id}`;
const pressForResponseConfirmationModalId = (id: string | number) =>
  `press-for-response-confirmation-modal-id-${id}`;
const restartPendencyAnalysisConfirmationModalId = (id: string | number) =>
  `restart-pendency-analysis-confirmation-modal-id-${id}`;

interface GridActionsProps {
  task: HubEnergy.DiligenceTaskODataResponse;
  component: dxDataGrid<
    HubEnergy.DiligenceTaskODataResponse,
    HubEnergy.DiligenceTaskODataResponse
  >;
  rowIndex: number;
  isEditing: boolean;
}

export const GridActions = ({
  component,
  isEditing,
  task,
  rowIndex,
}: GridActionsProps) => {
  const {
    loading,
    startAutomation,
    pressForResponse,
    restartPendencyAnalysis,
  } = useGridActions(task.pendencyId, () =>
    component.getDataSource()?.reload?.(),
  );

  if (isEditing)
    return (
      <div className="d-flex justify-content-center gap-2">
        <UnstyledButton
          title="Desfazer"
          onClick={() => component.cancelEditData()}
        >
          <OIcon category="fa" icon="fa-undo" />
        </UnstyledButton>
        <UnstyledButton title="Salvar" onClick={() => component.saveEditData()}>
          <OIcon category="fa" icon="fa-save" />
        </UnstyledButton>
      </div>
    );

  if (loading) return <OLoader size="sm" />;

  return (
    <>
      <DataGridActionV2
        actions={[
          {
            icon: { category: "orq", icon: "orq-visibility-on" },
            label: "Ver etapa",
            role: roles.energia.customer.consumerUnitDetails.role,
            route: buildCustomerLink(
              task.customerTaxId,
              corporateRouter.routes.energia.customer.consumerUnit.diligence.step.path(
                {
                  consumerUnitId: task.consumerUnitId,
                  stepId: task.stepId,
                },
              ),
            ),
          },
          {
            visible:
              !!task.pendencyIsAutomation &&
              task.pendencyTypeName === "ENVIAR_CARTA_DENUNCIA",
            disabled: task.pendencyAutomationStatus !== "PENDENTE_EXECUCAO",
            icon: { category: "orq", icon: "orq-email" },
            label: "Enviar carta denúncia",
            onClick: () =>
              modalManager.show(
                startAutomationConfirmationModalId(task.pendencyId),
              ),
          },
          {
            visible:
              !!task.pendencyIsAutomation &&
              task.pendencyTypeName === "ENVIAR_CARTA_DENUNCIA" &&
              task.pendencyAutomationStatus === "EXECUTANDO",
            icon: { category: "orq", icon: "orq-attention" },
            label: "Cobrar resposta",
            onClick: () =>
              modalManager.show(
                pressForResponseConfirmationModalId(task.pendencyId),
              ),
          },
          {
            visible: task.pendencyStatus === "AGUARDANDO_DISTRIBUIDORA",
            icon: { category: "orq", icon: "orq-batch" },
            label: "Retornar pendência para análise",
            onClick: () =>
              modalManager.show(
                restartPendencyAnalysisConfirmationModalId(task.pendencyId),
              ),
          },
          {
            icon: { category: "orq", icon: "orq-edit-pencil" },
            label: "Editar",
            onClick: () => component.editRow(rowIndex),
          },
        ]}
      />
      <OConfirmationModal
        {...datagridModalFix}
        modalId={startAutomationConfirmationModalId(task.pendencyId)}
        title="Enviar carta denúncia"
        divider={false}
        onConfirm={() => {
          startAutomation();
          modalManager.hide(
            startAutomationConfirmationModalId(task.pendencyId),
          );
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja enviar a carta denúncia?
        </OTypography>
      </OConfirmationModal>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={pressForResponseConfirmationModalId(task.pendencyId)}
        title="Enviar cobrança de resposta"
        divider={false}
        onConfirm={() => {
          pressForResponse();
          modalManager.hide(
            pressForResponseConfirmationModalId(task.pendencyId),
          );
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja enviar a cobrança de resposta?
        </OTypography>
      </OConfirmationModal>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={restartPendencyAnalysisConfirmationModalId(task.pendencyId)}
        title="Retornar pendência para análise"
        divider={false}
        onConfirm={() => {
          restartPendencyAnalysis();
          modalManager.hide(
            restartPendencyAnalysisConfirmationModalId(task.pendencyId),
          );
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja retornar pendência para análise?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
