import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import {
  NameAndTaxIdFormatted,
  calculateNameAndTaxIdFilterExpression,
} from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { buildCustomerLink } from "utils/build-customer-link";
import { currencySymbol } from "utils/currency";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { gridStorage } from "utils/storage";
import {
  configureHeaderFilterDataSource,
  getValidationMessages,
} from "../../utils";

export const billingReportsDataSource = (taxId: string | null = null) => {
  const apiCall = taxId
    ? () => service.hubEnergy.getBillingReportsByTaxId(taxId)
    : () => service.hubEnergy.getBillingReports();

  return dataSourceCustomStoreGenerator<HubEnergy.AdminBillingReportResponse>(
    () =>
      apiCall()
        .then(({ data }) => data.response ?? [])
        .catch((error) => {
          const errorMessage =
            getValidationMessages(error)?.[0]?.errorMessage ??
            "Erro ao buscar os relatórios de faturamento.";

          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
  );
};

const [postProcessConsumerUnit, calculateConsumerUnitCellValue] =
  configureHeaderFilterDataSource<HubEnergy.AdminBillingReportResponse>()(
    [
      "consumerUnit.officialName",
      "consumerUnit.taxId",
      "consumerUnit.installationNumber",
    ] as const,
    (name, taxId, instNumber) =>
      `${name} - ${fastCpfCnpjMask(taxId)} - ${instNumber}`,
  );

export const billingReportsGrid: ODataGridGeneratorConfig<HubEnergy.AdminBillingReportResponse> =
  {
    datagrid: {
      noDataText: "Nenhum relatório de faturamento encontrado.",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("billingReportsGrid"),
      columnAutoWidth: false,
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        role: roles.energia.customer.billingReportDetails.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={buildCustomerLink(
              data.customer.taxId,
              corporateRouter.routes.energia.customer.consumerUnit.billingReports.byBillingReportId.report.path(
                {
                  consumerUnitId: data.consumerUnit.relatedEntityId,
                  billingReportId: data.id,
                },
              ),
            )}
          />
        ),
        dataType: "number",
        calculateCellValue: ({ id }) => id,
        allowFiltering: true,
        allowHeaderFiltering: false,
        allowSorting: true,
        width: "auto",
        alignment: "left",
      },
      {
        dataType: "string",
        caption: "Unidade consumidora",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          [
            "consumerUnit.officialName",
            "consumerUnit.name",
            "consumerUnit.installationNumber",
          ],
          "consumerUnit.taxId",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.consumerUnit.officialName}
            subtexts={[
              masks.cpfCnpj(data.consumerUnit.taxId),
              `Nº instalação: ${data.consumerUnit.installationNumber}`,
            ]}
          />
        ),
        calculateCellValue: calculateConsumerUnitCellValue,
        headerFilter: { dataSource: postProcessConsumerUnit },
        allowSorting: true,
      },
      {
        dataField: "value",
        caption: "Valor",
        cellRender: ({ data }) =>
          masks.currency(data.value, currencySymbol.BRL),
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "boletaRegistryId",
        caption: "Número da boleta",
        dataType: "number",
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "consumption",
        caption: "Consumo",
        dataType: "number",
        cellRender: ({ data }) => data.consumption.toFixed(2) + " MWh",
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "reportDate",
        format: "MM/yyyy",
        dataType: "date",
        caption: "Mês de referência",
        sortOrder: "desc",
        allowHeaderFiltering: false,
        width: "auto",
      },
    ],
  };
