import {
  OCard,
  OCardBody,
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { InputMultiple } from "../../../../recebiveis/components/input-multiple/input-multiple.component";
import {
  exchangeGenericFieldComponentTypeOptions,
  exchangeGenericFieldMaskOptions,
} from "./generic-fields.utils";

export const ExchangeGenericFieldsForm = () => {
  return (
    <OCard>
      <OCardBody>
        <ORow>
          <OCol sm={12} md={6}>
            <ORFieldInput tag="text" id="name" name="name" label="Nome" />
          </OCol>
          <OCol sm={12} md={6}>
            <ORFieldInput
              tag="text"
              id="description"
              name="description"
              label="Descrição"
            />
          </OCol>
        </ORow>

        <ORow>
          <OCol sm={12} md={6}>
            <ORFieldSelect id="mask" name="mask" label="Máscara">
              {Object.entries(exchangeGenericFieldMaskOptions).map(
                ([value, label]) => (
                  <OOption key={value} value={value}>
                    {label}
                  </OOption>
                ),
              )}
            </ORFieldSelect>
          </OCol>
          <OCol sm={12} md={6}>
            <ORFieldSelect
              id="componentType"
              name="componentType"
              label="Tipo de componente"
            >
              {Object.entries(exchangeGenericFieldComponentTypeOptions).map(
                ([value, label]) => (
                  <OOption key={value} value={value}>
                    {label}
                  </OOption>
                ),
              )}
            </ORFieldSelect>
          </OCol>
        </ORow>

        <OCol sm={12} md={6}>
          <InputMultiple
            id="fxPendencyCodes"
            name="fxPendencyCodes"
            label="Códigos de pendência do FX"
          />
        </OCol>
      </OCardBody>
    </OCard>
  );
};
