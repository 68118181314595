import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/step-config/step-config.form";
import { FormValuesType } from "../_compose/step-config/step-config.types";

export const useSemaphoreConfigurationDetails = (uuid: string) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { reset, handleSubmit } = form;

  const { callService: _remove } = useServiceCall(
    service.onboarding.removeSemaphoreStep,
  );

  const removeSemaphoreTemplate = async () => {
    try {
      setLoading(true);

      const { success } = await _remove(uuid);

      if (!success) {
        OToastManager.danger("Não foi possível remover o step");
        return;
      }

      OToastManager.success("Step removido com sucesso");

      setLoading(false);

      navigate(
        corporateRouter.routes.ferramentas.product.onboarding.semaphore
          .configuration.steps.path,
      );
    } catch {
      OToastManager.danger("Não foi possível remover o step");
    }
  };

  const { callService: _update } = useServiceCall(
    service.onboarding.updateSemaphoreStep,
  );

  const updateSemaphoreTemplate = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const { success } = await _update(uuid, values);

      if (success) OToastManager.success("Step atualizado com sucesso");
      else OToastManager.danger("Não foi possível atualizar o step");

      setLoading(false);
    } catch {
      OToastManager.danger("Não foi possível atualizar o step");
    }
  });

  const load = useCallback(async () => {
    setLoading(true);

    if (!uuid) return;

    const { data } = await service.onboarding.findSemaphoreStep(uuid);

    reset(data);

    setLoading(false);
  }, [uuid, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    loading,
    updateSemaphoreTemplate,
    removeSemaphoreTemplate,
  };
};
