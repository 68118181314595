import {
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { service } from "services/service";
import { VariableDetails } from "../variable-details";

interface ContingencyScriptProps {
  contingency: HubOnboardingRelationships.Admin.Contingency;
}

export const ContingencyScript = ({ contingency }: ContingencyScriptProps) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<Record<string, string>>({});

  const submit = useCallback(async () => {
    if (Object.values(payload).length < contingency.variableDetails.length) {
      OToastManager.danger("Preencha todos os campos");
      return;
    }

    setLoading(true);
    try {
      await service.onboardingRelationships.runContingency({
        identifier: contingency.identifier ?? "",
        data: payload,
      });
    } catch {
      OToastManager.danger("Ocorreu um erro. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [contingency, payload]);

  return (
    <OCard className="my-2">
      <OCardHeader>
        <PageTitle
          title={contingency.identifier ?? ""}
          description={contingency.description}
        />
      </OCardHeader>
      <OCardBody>
        <ORow>
          {contingency.variableDetails.length > 0 &&
            contingency.variableDetails.map((variableDetail) => (
              <VariableDetails
                key={variableDetail.propertyIdentifier}
                variableDetail={variableDetail}
                payload={payload}
                setPayload={setPayload}
              />
            ))}
        </ORow>
      </OCardBody>
      <OCardFooter className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      </OCardFooter>
    </OCard>
  );
};
