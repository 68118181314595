import {
  OCol,
  OLabel,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldInputDateRange,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { ReportsProps } from "../../request-for-reports.types";
import { SelectCedentes, SelectSacados } from "./_compose";
import {
  periodOptions,
  productTypesOptions,
  reportTypeOptions,
  statusAntecipationOptions,
} from "./reports-config.utils";

export const ReportsConfig = ({ isExcel }: ReportsProps) => {
  const form = useFormContext();
  const { watch } = form;
  const watchReportPeriod = watch("reportPeriod");

  const customPeriodLabel = isExcel
    ? "Selecione o período (data de criação da nota)"
    : "Selecione o período (data do trade)";

  return (
    <>
      <OTypography type="dark" className="mb-4">
        Selecione o tipo do relatório e suas configurações
      </OTypography>

      <ORow>
        <ORFieldRadioGroup
          id="reportType"
          name="reportType"
          dataLabel="tipo_de_relatorio"
          dataAction="relatorios_em_lote:select:tipo_de_relatorio"
          label="Tipo de Relatório"
        >
          <OCol xs={12} className="d-flex gap-3">
            {reportTypeOptions.map(({ label, value }) => (
              <div key={value} className="d-flex aligm-items-center gap-2">
                <ORadio id={value} value={value} />
                <OLabel htmlFor={value} size="sm">
                  {label}
                </OLabel>
              </div>
            ))}
          </OCol>
        </ORFieldRadioGroup>
      </ORow>

      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldSelectMultiple
            dataAction="relatorios_em_lote:select:tipo_de_produto"
            dataLabel="tipo_de_produto"
            id="productType"
            name="productType"
            label="Tipo de Produto"
          >
            <div>
              {productTypesOptions.map((option) => {
                if (option.visibleFor && !isExcel) return;

                return (
                  <OOptionMultiple key={option.value} value={option.value}>
                    {option.label}
                  </OOptionMultiple>
                );
              })}
            </div>
          </ORFieldSelectMultiple>
        </OCol>
        {isExcel && (
          <OCol xs={12} md={6}>
            <ORFieldInput
              tag="text"
              id="reportName"
              name="reportName"
              dataAction="relatorios_em_lote:texto:nome_relatorio"
              dataLabel="nome_relatorio"
              label="Crie um nome para o relatório"
              placeholder="Insira um nome para facilitar o controle"
              maxlength={150}
            />
          </OCol>
        )}
      </ORow>

      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldSelect
            dataAction="relatorios_em_lote:select:periodo_de_relatorio"
            dataLabel="periodo_de_relatorio"
            id="reportPeriod"
            name="reportPeriod"
            placeholder="Selecione o período"
            selectLabel={customPeriodLabel}
            label={customPeriodLabel}
          >
            {periodOptions.map((option) => (
              <OOption key={option.value} value={option.value}>
                {option.label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6}>
          {isExcel ? (
            <ORFieldSelectMultiple
              id="statusAntecipations"
              name="statusAntecipations"
              dataLabel="status_antecipacao"
              dataAction="relatorios_em_lote:select:status_antecipacao"
              label="Status das antecipações"
              placeholder="Selecione status das antecipações"
            >
              {statusAntecipationOptions.map((option) => (
                <OOptionMultiple key={option.value} value={option.value}>
                  {option.label}
                </OOptionMultiple>
              ))}
            </ORFieldSelectMultiple>
          ) : (
            <ORFieldInput
              tag="text"
              id="reportName"
              name="reportName"
              dataAction="relatorios_em_lote:texto:nome_relatorio"
              dataLabel="nome_relatorio"
              label="Crie um nome para o relatório"
              placeholder="Insira um nome para facilitar o controle"
              maxlength={150}
            />
          )}
        </OCol>
      </ORow>

      {watchReportPeriod === "CustomPeriod" && (
        <ORow>
          <OCol xs={12} md={6}>
            <ORFieldInputDateRange
              id="periodDate"
              name="periodDate"
              aspect="flushed"
              placeholder={["Data de inicio", "Data de fim"]}
            />
          </OCol>
        </ORow>
      )}

      <ORow>
        <OCol xs={12} md={6}>
          <SelectSacados />
        </OCol>
        <OCol xs={12} md={6}>
          <SelectCedentes />
        </OCol>
      </ORow>
    </>
  );
};
