import {
  OButton,
  OCard,
  OCardBody,
  OIcon,
  OLoader,
  modalManager,
  OTooltip,
  OToastManager,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { NoteDetail } from "./_compose";
import { CustomPagination } from "./_compose/custom-pagination";
import { NoteFilter } from "./_compose/note-filter";
import { useNoteFilter } from "./_compose/note-filter/note-filter.hook";
import { noteFilterModalId } from "./_compose/note-filter/note-filter.utils";
import { grid } from "./note-note.grid";

export const TitleNoteNote = () => {
  const gridRef = useGridRef();
  const {
    dataSource,
    loadingDataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  } = useNoteFilter();

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Nota a nota"
          description="Visualize todos os títulos recebíveis e suas informações principais"
        />
      }
    >
      <OCard>
        <OCardBody>
          <div className="d-flex flex-column gap-4">
            {!summary || loadingSummary ? (
              <div className="d-flex justify-content-center">
                <OLoader />
              </div>
            ) : (
              <NoteDetail summary={summary} loading={loadingSummary} />
            )}
            <GridTemplate
              gridRef={gridRef}
              actions={
                <>
                  <IconButton
                    icon={{ category: "orq", icon: "orq-tools-search" }}
                    dataAction="titulo_nota_nota:botao:mostrar_form"
                    dataLabel="mostrar_form"
                    onClick={() => modalManager.show(noteFilterModalId)}
                  >
                    Abrir busca
                  </IconButton>
                  <OTooltip type="info">
                    <span slot="tooltip-content">Copiar URL da busca</span>
                    <IconButton
                      outline
                      bordered
                      type="default"
                      icon={{
                        category: "orq",
                        icon: "orq-edit-copy",
                        size: "lg",
                        className: "m-0",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        OToastManager.success(
                          "URL da busca copiada com sucesso!",
                        );
                      }}
                    />
                  </OTooltip>
                  <RefreshGridButton onClick={() => refetchData()} />
                  <OButton
                    dataAction="grid:botao:exportar_grid"
                    dataLabel="exportar_grid"
                    onClick={() => exportAllData()}
                    type="default"
                    outline
                  >
                    <OIcon
                      category="far"
                      icon="fa-file-spreadsheet"
                      style={{ width: "16px" }} // same width as refresh grid icon
                    />
                  </OButton>
                </>
              }
            >
              <ODataGridGenerator
                grid={grid}
                gridRef={gridRef}
                dataSource={dataSource}
                loading={loadingDataSource}
              />
              <CustomPagination
                totalCount={summary?.totalRecebiveis ?? -1}
                loading={loadingSummary}
                currentPage={page}
                setCurrentPage={setPage}
                pagination={[10, 20, 30]}
                selectedPaginationSize={rows}
                setSelectedPaginationSize={setRows}
              />
            </GridTemplate>
          </div>
        </OCardBody>
      </OCard>
      <NoteFilter form={form} onSubmit={onSubmit} resetFilter={resetFilter} />
    </DetailsTemplate>
  );
};
