import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EnergyAdequatorFieldsForm } from "../../../../../components/deprecated/adequator/energy-adequator.component";
import { useEnergyUpdateAdequatorPage } from "./energy-update-adequator.hook";

export const EnergyUpdateAdequatorPage = () => {
  const {
    contactsDataSource,
    form,
    genericFieldDetails,
    hasError,
    loading,
    loadingDetails,
    handleErrorCallback,
    submit,
  } = useEnergyUpdateAdequatorPage();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar Adequadora" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={handleErrorCallback}
          />
        }
        value={genericFieldDetails}
        render={() => (
          <FormProvider {...form}>
            <EnergyAdequatorFieldsForm
              contactsDataSource={contactsDataSource}
            />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
