import { OIcon } from "@maestro/react";
import dayjs from "dayjs";
import { Banner } from "services/hubcreditmanager/models/responses";
import { StatusMapContentType } from "./banner-management.types";

export const statusMap: Record<string, StatusMapContentType> = {
  ATIVO: {
    label: "Ativo" as const,
    backgroundColor: "success-light" as const,
    icon: (
      <OIcon
        category="orq"
        icon="orq-status-success"
        size="md"
        type="success"
      />
    ),
  },
  INATIVO: {
    label: "Inativo" as const,
    backgroundColor: "danger-light" as const,
    icon: (
      <OIcon
        category="orq"
        icon="orq-status-disapproved"
        size="md"
        type="danger"
      />
    ),
  },
  EXPIRADO: {
    label: "Expirado" as const,
    backgroundColor: "warning-light" as const,
    icon: (
      <OIcon
        category="orq"
        icon="orq-status-warning"
        size="md"
        type="warning"
      />
    ),
  },
  LANCAMENTO_FUTURO: {
    label: "Lançamento futuro" as const,
    backgroundColor: "info-light" as const,
    icon: <OIcon category="orq" icon="orq-info" size="md" type="info" />,
  },
};

export const getStatus = (data: Banner) => {
  const isBefore = data.isActive && dayjs().isBefore(dayjs(data.startingFrom));
  const isAfter = data.isActive && dayjs().isAfter(dayjs(data.endingAt));

  const statusKey = isBefore
    ? "LANCAMENTO_FUTURO"
    : isAfter
    ? "EXPIRADO"
    : data.isActive
    ? "ATIVO"
    : "INATIVO";

  return { ...statusMap[statusKey], isBefore, isAfter };
};
