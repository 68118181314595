import { OToastManager, modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { NameAndTaxIdFormatted } from "components/name-and-tax-id-formatted";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GenerateContract } from "../_compose/generate-contract";
import { SyncDataUpdateWorkflow } from "./_compose/sync-data-update-workflow.component";

export const buildDataSource = (requestTaxId: string) =>
  dataSourceCustomStoreGenerator(() =>
    service.onboarding
      .getDataUpdateWorkflows({ requestTaxId })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Não foi possível buscar os workflows";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const customerDataUpdateWorkflowsGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDataUpdateWorkflows>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum workflow",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
  },
  columns: [
    {
      dataField: "uuid",
      dataType: "string",
      caption: "Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "portalStatus",
      dataType: "string",
      caption: "Status",
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de criação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "updatedDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Última atualização",
      allowHeaderFiltering: false,
    },
    {
      dataField: "requestBy",
      caption: "Criado por",
      cellRender: ({ data }) => <NameAndTaxIdFormatted name={data.requestBy} />,
    },
    {
      caption: "Ações",
      cellRender: ({ data: { uuid } }) => {
        const syncModalId = `sync-data-review-workflow-${uuid}-modal`;
        const generateContractModalId = `generate-contract-${uuid}-modal`;

        return (
          <>
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-swap-circle" },
                  label: "Sincronizar",
                  onClick: () => {
                    modalManager.show(syncModalId);
                  },
                },
                {
                  label: "Editar",
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  route:
                    corporateRouter.routes.cadastro.customer.details.dataUpdates.details.edit.path(
                      {
                        uuid,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingUpdateEditPage.role,
                },
                {
                  label: "Logs",
                  icon: { category: "orq", icon: "orq-file" },
                  route:
                    corporateRouter.routes.cadastro.customer.details.dataUpdates.details.logs.path(
                      {
                        uuid,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingUpdateLogsPage.role,
                },
                {
                  icon: { category: "orq", icon: "orq-contract-agreement" },
                  label: "Gerar contrato",
                  onClick: () => {
                    modalManager.show(generateContractModalId);
                  },
                },
              ]}
            />
            <SyncDataUpdateWorkflow modalId={syncModalId} uuid={uuid} />
            <GenerateContract
              modalId={generateContractModalId}
              dataUpdateUuid={uuid}
            />
          </>
        );
      },
    },
  ],
};
