import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { OnboardingDomainConfigFormProps } from "./onboarding-form.types";

export const OnboardingDomainConfigForm = ({
  form,
}: OnboardingDomainConfigFormProps) => {
  return (
    <FormProvider {...form}>
      <form>
        <ORow>
          <OCol xs={6} md={6} xl={6}>
            <ORFieldInput
              tag="text"
              id="group"
              name="group"
              dataAction="adicionar_dominio:input:group"
              dataLabel="input:group"
              label="Grupo"
            />
          </OCol>
          <OCol xs={6} md={6} xl={6}>
            <ORFieldInput
              tag="text"
              id="key"
              name="key"
              dataAction="adicionar_dominio:input:key"
              dataLabel="input:key"
              label="Chave"
            />
          </OCol>
        </ORow>

        <ORow>
          <OCol xs={12} md={12} xl={12}>
            <ORFieldInput
              tag="textarea"
              id="value"
              name="value"
              dataAction="adicionar_dominio:input:value"
              dataLabel="input:value"
              label="Valor"
              initialHeight={300}
            />
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
