import { OStep, OStepHeader } from "@maestro/react";
import { useMemo } from "react";
import { stepsMap, StepTimelineProps } from "./step-render.types";

export const StepTimeline = ({ step }: StepTimelineProps) => {
  const renderStage = useMemo(() => {
    const stepIndex = Object.keys(stepsMap).findIndex((item) => item === step);

    return (
      <div className="my-4 pb-4">
        <OStepHeader
          key={Object.keys(stepsMap).join("-")}
          activeStep={stepIndex}
          disableNavigation
        >
          {Object.entries(stepsMap).map(([item, value]) => (
            <OStep key={item} position="bottom">
              {value.title}
            </OStep>
          ))}
        </OStepHeader>
      </div>
    );
  }, [step]);

  return <>{renderStage}</>;
};
