import {
  OCard,
  OCardBody,
  OExpander,
  OExpanderContent,
  OExpanderHeader,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import React, { FC } from "react";
import { ICollapseItemProps } from "./collapse-item.types";

export const CollapseItem: FC<React.PropsWithChildren<ICollapseItemProps>> = ({
  title,
  children,
  loading,
}) => {
  return (
    <OCard>
      <OCardBody>
        {loading && (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <OTypography type="dark">{title}</OTypography>
            <OLoader size="xs" />
          </div>
        )}
        {!loading && (
          <OExpander>
            <OExpanderHeader className="d-flex justify-content-between align-items-center">
              <OTypography type="dark">{title}</OTypography>
              <OIcon
                className="rotate"
                category="fal"
                icon="fa-angle-down"
                size="xxl"
                type="dark"
              />
            </OExpanderHeader>
            <OExpanderContent>
              <div className="p-2">{children}</div>
            </OExpanderContent>
          </OExpander>
        )}
      </OCardBody>
    </OCard>
  );
};
