import { OTypography } from "@maestro/react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { ReceivablesProductByCedenteIdByCedenteTypeRouteParams } from "../../../../../routes/recebiveis.route-params";
import { dashboardOnboardDetailsGenerator } from "./dashboard-onboard.details";
import { dashboardOnboardingGrid } from "./dashboard-onboarding.grid";

export const DashboardOnboardingPage = () => {
  const { cedenteId, cedenteType } =
    useParams<ReceivablesProductByCedenteIdByCedenteTypeRouteParams>();

  if (!cedenteId || !cedenteType)
    throw new Error("No cedenterId or cedenteType");

  const gridRef = useGridRef();

  const {
    callService: getCompanyDetails,
    value: company,
    loading,
    hasError,
  } = useServiceCall(service.quickfin.getCompanyDetails);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        getCompanyDetails({
          CedenteId: cedenteId,
          TipoOnboarding: cedenteType,
        }).then(({ success, response }) => {
          if (!success) throw new Error("Erro ao buscar detalhes");
          return response.data.relacionamentosFornecedor ?? [];
        }),
      ),
    [cedenteId, cedenteType, getCompanyDetails],
  );

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle title={`Detalhes do fornecedor - ${company?.cedenteName}`} />
      }
    >
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar as informações"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => dataSource.reload()} />
            </ErrorComponent>
          }
          value={company}
          fields={(value) =>
            dashboardOnboardDetailsGenerator(value, cedenteType)
          }
        />
        <GridTemplate
          pageTitle={
            <OTypography type="dark">Detalhes de relacionamento</OTypography>
          }
          gridRef={gridRef}
          showExportButton
          showClearFiltersButton
          showRefreshButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={dashboardOnboardingGrid}
            dataSource={dataSource}
          />
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
