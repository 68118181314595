import { OToastManager, modalManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { ConfirmationModal } from "./_compose";
import { Crontab } from "./crontab.types";
import { runWorker } from "./crontab.utils";

export const dataSource = dataSourceCustomStoreGenerator<Crontab>(() =>
  service.recebiveisCartao
    .getCrontab()
    .then(({ data }) => data)
    .catch(() => {
      const errorMessage = "Erro ao buscar o crontab";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const crontabGrid: ODataGridGeneratorConfig<Crontab> = {
  datagrid: {
    filterRow: { visible: true },
  },
  columns: [
    {
      dataField: "pattern",
      caption: "Padrão",
    },
    {
      dataField: "worker",
      caption: "Worker",
    },
    {
      caption: "Ações",
      cellRender: ({ data: { worker } }) => {
        const modalId = `crontab-${worker}-modal`;

        return (
          <>
            <IconButton
              dataAction="crontab:botao:executar_agora"
              dataLabel="executar_agora"
              icon={{ category: "far", icon: "fa-terminal" }}
              type="dark"
              outline
              onClick={() => modalManager.show(modalId)}
            >
              Executar agora
            </IconButton>
            <ConfirmationModal
              id={modalId}
              job={worker}
              onConfirm={async () =>
                runWorker(worker).then(() => modalManager.hide(modalId))
              }
            />
          </>
        );
      },
    },
  ],
  paging: {
    defaultPageSize: 30,
  },
};
