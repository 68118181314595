import { ODataGridGeneratorConfig } from "components/data-grid";
import { getValueFromMap } from "utils/get-value-from-map";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { BlockListTypesMap } from "./block.types";

export const buildGrid = (
  deleteRow: (id: number) => void,
  redirectPageEdit: (blockList: Validator.Block) => void,
): ODataGridGeneratorConfig<Validator.Block> => ({
  datagrid: {
    noDataText: "Nenhum sistema encontrado",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "identification", caption: "Identificação" },
    {
      dataField: "createdAt",
      caption: "Data Criação",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      dataField: "from",
      caption: "Data Início",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "until",
      caption: "Data Fim",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "type",
      caption: "Tipo",
      cellRender: ({ data }) => {
        const mappedStatus = getValueFromMap(BlockListTypesMap, data.type);
        return mappedStatus;
      },
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Editar",
              role: roles.fidc.product.eligibilitysPage.role,
              onClick: () => redirectPageEdit(data),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir",
              onClick: () => deleteRow(rowIndex),
            },
          ]}
        />
      ),
    },
  ],
});
