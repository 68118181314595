import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import {
  GetAccountAccountsResponse,
  GetBalanceBalanceByAccountResponse,
  GetAccountAccountsResponseItem,
} from "services/admin-bankinghub/models";
import { grid } from "./accounts-admin.grid";
import { Account } from "./accounts-admin.type";

export const AccountsAdmin = () => {
  const { customer } = useCustomer();
  const { routes } = corporateRouter;

  const gridRef = useGridRef();

  const fetchAccountBalance = async (account: Account) => {
    try {
      const balance = await service.adminBankinghub.getBalanceByAccount(
        Number(account.number),
        account.agency,
      );

      return { ...account, ...balance.data };
    } catch (error) {
      if (!isAxiosError(error)) logger.error(error);
      return { ...account };
    }
  };

  const dataSource = dataSourceCustomStoreGenerator<
    Omit<Account, "GetBalanceBalanceByAccountResponse"> &
      Partial<GetBalanceBalanceByAccountResponse>
  >(
    async () => {
      try {
        const response = await service.adminBankinghub.getAccounts({
          taxId: customer.identification,
        });
        const { data } = response;
        if (!data) return [];

        const accounts: GetAccountAccountsResponse = data.map(
          (account: GetAccountAccountsResponseItem) => {
            const isInvestment =
              account.segment === "IB" && account.coSegment === "B2C";
            return {
              ...account,
              service: isInvestment ? "Conta investimento" : account.service,
            };
          },
        );
        const result = await Promise.all(
          accounts.map(async (account: any) => fetchAccountBalance(account)),
        );

        return result;
      } catch (error) {
        if (!isAxiosError(error)) logger.error(error);
        const errorMessage = "Erro ao buscar os contas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }
    },
    {
      customStoreOptions: {
        update: async ({ id }, { businessAccount }) => {
          try {
            if (businessAccount) {
              await service.adminBankinghub.addAccountBusiness(id);
            } else {
              await service.adminBankinghub.removeAccountBusiness(id);
            }

            OToastManager.success("Configuração editada com sucesso");
          } catch (err) {
            if (!isAxiosError(err)) logger.error(err);
            OToastManager.danger("Não foi possível editar Conta Business");
          }
        },
      },
    },
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={
        <RouterButton
          dataLabel="optin"
          href={routes.cadastro.customer.btgAccounts.optin.createAccounts.path}
          role={roles.cadastro.customer.btgAccountsOptinCreateAccountsPage.role}
          dataAction="optin:botao:redirecionar"
        >
          Abrir Contas Opt-in
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
