import { OIcon, OTooltip } from "@maestro/react";
import { MoanaOwnerStatus } from "services/moana/models";
import { statusMapped } from "./wallet-state.utils";

interface WalletStateProps {
  state?: MoanaOwnerStatus[];
}

export const WalletState: React.FC<WalletStateProps> = ({ state = [] }) => {
  const steps = Object.values(MoanaOwnerStatus);
  return (
    <div className="d-flex gap-2">
      {steps.map((step) => {
        const isConcluded = state.includes(step);
        const type = isConcluded ? "success" : "default";
        const icon = isConcluded
          ? "orq-status-success"
          : "orq-status-disapproved";
        return (
          <OTooltip key={step} type={type} floating>
            <span slot="tooltip-content">{statusMapped[step]}</span>
            <OIcon type={type} icon={icon} category="orq" />
          </OTooltip>
        );
      })}
    </div>
  );
};
