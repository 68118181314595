import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useGroupForm } from "./group-form.hook";
import { GroupFormProps } from "./group-form.types";

export const GroupForm = ({ editing }: GroupFormProps) => {
  const { loading } = useGroupForm();

  return (
    <>
      {loading && <OLoader absolute />}

      <ORow className="mb-3">
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="groupName"
            name="groupName"
            dataAction="grupo_empresa:input:nome_do_grupo"
            dataLabel="nome_do_grupo"
            label="Nome do grupo"
            disabled={editing}
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id="type"
            name="type"
            dataAction="grupo_empresa:select:Tipo"
            dataLabel="Tipo"
            placeholder="Selecione:"
            label="Tipo"
            disabled={editing}
          >
            <OOption value="MatrizFilial">Matriz - Filial</OOption>
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="taxId"
            name="taxId"
            dataAction="grupo_empresa:input:cnpj_da_entidade_principal"
            dataLabel="cnpj_da_entidade_principal"
            label="CNPJ da entidade principal"
            disabled={editing}
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="officialName"
            name="officialName"
            dataAction="grupo_empresa:input:razao_social"
            dataLabel="razao_social"
            label="Razão social"
            disabled
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="description"
            name="description"
            dataAction="grupo_empresa:input:descricao"
            dataLabel="descricao"
            label="Descrição"
            disabled={editing}
          />
        </OCol>
      </ORow>
    </>
  );
};
