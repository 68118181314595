import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { dataSourceGenerator, gatekeeperLogsGrid } from "./blocked-user.grid";
import { useMemo } from "react";
import { useGridRef } from "hooks/grid-ref";
import { UsuariosProdutoByIdentificationRouteParams } from "../../../../routes/usuarios.route-params";
import { useParams } from "react-router-dom";

export const GatekeeperBlockedUser = () => {
  const gridRef = useGridRef();
  const { identification } =
    useParams<UsuariosProdutoByIdentificationRouteParams>();
  const dataSource = useMemo(
    () => dataSourceGenerator(identification ?? ""),
    [],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Usuários Bloqueados Gatekeeper" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={gatekeeperLogsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
