import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { GetCompanyFundAdminResponse } from "services/fidc/models/responses/get-company-fund.response";
import { service } from "services/service";

export const useFidcCompanyFunds = () => {
  const [loading, setLoading] = useState(false);
  const [companyFunds, setFunds] = useState<GetCompanyFundAdminResponse>();

  const hasFetched = useRef(false);

  const getCompanyFunds = useCallback(async () => {
    if (hasFetched.current) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await service.fidc.getCompany();

      hasFetched.current = true;

      setFunds(data);
    } catch {
      OToastManager.danger("Erro ao buscar os fundos de FIDC");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCompanyFunds();
  }, [getCompanyFunds]);

  return {
    companyFunds,
    loading,
  };
};
