import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { format } from "utils/date";
import { FileItem } from "./_compose";
import { useSecurityAntifraudAnalysisDetails } from "./details.hook";

export const SecurityAntifraudAnalysisDetails = () => {
  const { value: id } = useParseFromSearchParams<string>("id");
  const { submit, form, loading, detail } =
    useSecurityAntifraudAnalysisDetails(id);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Detalhes da análise"
          description="Complemente a análise de fraude, edite o status e verifique os arquivos."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="raven_template:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {detail && (
          <ORow className="mb-5">
            <OCol xs={12} md={6} xl={3} className="d-flex flex-column">
              <OTypography>Nome/CPF:</OTypography>
              <OTypography>
                {detail.name} - {masks.cpf(detail.taxId)}
              </OTypography>
            </OCol>
            <OCol xs={12} md={6} xl={3} className="d-flex flex-column">
              <OTypography>Criação da análise:</OTypography>
              <OTypography>{format.dateTime(detail.insertedAt)}</OTypography>
            </OCol>
            <OCol xs={12} md={6} xl={3} className="d-flex flex-column">
              <OTypography>Resultado da análise:</OTypography>
              <OTypography>
                {format.dateTime(detail.analysisResultDate)}
              </OTypography>
            </OCol>
            <OCol xs={12} md={6} xl={3} className="d-flex flex-column">
              <OTypography>Análise manual:</OTypography>
              <OTypography>
                {format.dateTime(detail.manualAnalysisDate)}
              </OTypography>
            </OCol>
          </ORow>
        )}

        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="analysedBy"
                  name="analysedBy"
                  dataAction="analise_antifraude:input:analisado_por"
                  dataLabel="analisado_por"
                  label="Analisado por"
                  disabled
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="analysisResult"
                  name="analysisResult"
                  dataAction="analise_antifraude:input:resultado_da_analise"
                  dataLabel="resultado_da_analise"
                  label="Resultado da análise"
                  disabled
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="analysisType"
                  name="analysisType"
                  dataAction="analise_antifraude:input:tipo_da_analise"
                  dataLabel="tipo_da_analise"
                  label="Tipo da análise"
                  disabled
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="justification"
                  name="justification"
                  dataAction="analise_antifraude:input:justificativa"
                  dataLabel="justificativa"
                  label="Justificativa"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldSelect
                  id="status"
                  name="status"
                  dataAction="analise_antifraude:input:status"
                  dataLabel="status"
                  placeholder="Status"
                  label="Status"
                >
                  <OOption id="CANCELADO" value="CANCELADO">
                    Cancelado
                  </OOption>
                  <OOption id="REPROVADO" value="REPROVADO">
                    Reprovado
                  </OOption>
                  <OOption id="APROVADO" value="APROVADO">
                    Aprovado
                  </OOption>
                </ORFieldSelect>
              </OCol>
            </ORow>
            <ORow>
              <OCol xs={12} md={6} xl={3} className="d-flex flex-column gap-3">
                <OTypography tag="span">Documentos:</OTypography>

                <div className="d-flex flex-column gap-1">
                  {detail?.files.map((file) => {
                    const { fileKey, filename } = file;

                    if (!fileKey || !filename) return;

                    return (
                      <FileItem
                        key={fileKey}
                        filename={filename}
                        fileKey={fileKey}
                      />
                    );
                  })}
                </div>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
