import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCustomer } from "contexts/customer";
import { GridTemplate } from "templates/grid-template";
import { customerServiceTermsGrid } from "./customer-service-terms.grid";

export const CustomerServiceTerms = () => {
  const { customer, refetch } = useCustomer();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Termos" />}
      actions={<RefreshGridButton onClick={refetch} />}
    >
      <ODataGridGenerator
        grid={customerServiceTermsGrid}
        dataSource={customer.contracts}
      />
    </GridTemplate>
  );
};
