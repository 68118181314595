import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { billingReportItemGroupGrid } from "./billing-report-item-groups.grid";

interface BillingReportGroupsComponentProps {
  billingReport: HubEnergy.BillingReportResponse;
}

export const BillingReportGroupsComponent = ({
  billingReport,
}: BillingReportGroupsComponentProps) => {
  const gridRef = useGridRef();

  return (
    <>
      {billingReport.billingReportItemGroups.map((group) => (
        <GridTemplate
          key={group.id}
          pageTitle={
            <OTypography type="dark" size="lg">
              {group.name}
            </OTypography>
          }
          gridRef={gridRef}
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={billingReportItemGroupGrid}
            dataSource={group.billingReportItems}
          />
        </GridTemplate>
      ))}
    </>
  );
};
