import { GetProductAdminResponse } from "services/fidc/models/responses";
import * as XLSX from "xlsx";
import {
  rentingDefaultValues,
  buyingAndSellingDefaultValues,
} from "./import-fidc.form";
import { ImportBuyAndSellFidcForm, ImportRentingFidcForm } from "./forms";

export const importProductSelectMap: Record<string, string> = {
  "simpar-locacao": "Locação",
  "simpar-compra-e-venda": "Compra e venda",
};

export const formToRenderMap = {
  "simpar-locacao": {
    formFields: <ImportRentingFidcForm />,
    defaultValues: rentingDefaultValues,
  },
  "simpar-compra-e-venda": {
    formFields: <ImportBuyAndSellFidcForm />,
    defaultValues: buyingAndSellingDefaultValues,
  },
};

export const getFidcSheetModel = (
  model: GetProductAdminResponse[number]["assetSchema"],
) => {
  const modelProperties: Fidc.AssetProperty[] = Object.values(model.properties);

  const wsName = "Modelo";
  const fileName = "modelo_fidc_importacao.xlsx";

  const data = [modelProperties.map((elem: Fidc.AssetProperty) => elem.title)];

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, wsName);
  XLSX.writeFile(wb, fileName);
};
