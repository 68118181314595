import { OToastManager, modalManager } from "@maestro/core";
import { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { AdminBeneficiaryAnalyses } from "services/hubfx/odata/models/responses/get-admin-beneficiary-analyses-o-data.response";
import { service } from "services/service";
import { analysisModalIds } from "./analysis-modal.utils";

export const useAnalysisModal = (
  closes: boolean,
  gridRef: React.RefObject<DataGrid>,
) => {
  const { callService, loading } = useServiceCall(
    service.hubFx.patchBeneficiaryIsActive,
  );

  const modalId = analysisModalIds[closes ? "deactivate" : "activate"];

  const submitChanges = useCallback(
    async (analyses: AdminBeneficiaryAnalyses[]) => {
      const body = {
        Analyses: analyses.map((analysis) => ({
          id: analysis.Id,
          isActive: !closes,
        })),
      };

      const { success } = await callService(body);
      modalManager.hide(modalId);
      gridRef.current?.instance.getDataSource().reload();

      if (success) {
        OToastManager.success("Análises alteradas com sucesso!");
      } else {
        OToastManager.danger(
          "Erro ao alterar status das análises selecionadas.",
        );
      }
    },
    [callService, closes, gridRef, modalId],
  );

  return {
    loading,
    modalId,
    submitChanges,
  };
};
