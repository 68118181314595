import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useMemo } from "react";
import { collateralTypeMap } from "../simulation/simulation.utils";
import { Collateral } from "./collateral-details.types";
import { CollateralsDetails } from "./collateral-modal/collateral.component";

interface CollateralDetailsModalProps {
  collaterals: Collateral[];
  selectedCollateralType: string | undefined;
}

export const COLLATERAL_DETAILS_MODAL = "collateral-details-modal";

export const CollateralDetailsModal = ({
  collaterals,
  selectedCollateralType,
}: CollateralDetailsModalProps) => {
  const hasMultipleCollaterals = collaterals.length > 1;

  const collateralLabel = useMemo(() => {
    if (!selectedCollateralType) return "";
    return (
      collateralTypeMap[selectedCollateralType]?.label || selectedCollateralType
    );
  }, [selectedCollateralType]);

  return (
    <OModal id={COLLATERAL_DETAILS_MODAL} position="right" size="xs">
      <OModalHeader closeButton>
        <OTypography size="lg" className="m-0">
          {hasMultipleCollaterals
            ? "Informações das Garantias"
            : "Informações da Garantia"}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography>
          {hasMultipleCollaterals
            ? `Garantias de ${collateralLabel}`
            : `Garantia de ${collateralLabel}`}
        </OTypography>
        <CollateralsDetails
          collaterals={collaterals}
          selectedCollateralType={selectedCollateralType}
        />
      </OModalBody>
      <OModalFooter>
        <OButton
          className="btn-outline-dark"
          type="light"
          onClick={() => modalManager.hide(COLLATERAL_DETAILS_MODAL)}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
