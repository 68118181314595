import { OCol, ORFieldInput, ORFieldInputDate, ORow } from "@maestro/react";
import { Policy } from "./policy.component";

export const InsuranceFormComponent = () => {
  return (
    <>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="identification"
            name="identification"
            label="Número da apólice"
            tag="text"
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="guaranteedPercentage"
            name="guaranteedPercentage"
            label="Percentual garantido"
            tag="percentage"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInputDate
            id="expireDate"
            name="expireDate"
            size="md"
            label="Data de vencimento"
            disabledDate={(date) => date.isBefore()}
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="amount"
            name="amount"
            label="Valor da apólice"
            tag="currency"
            symbol="R$"
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="totalPremiumAmount"
            name="totalPremiumAmount"
            label="Valor do prêmio"
            tag="currency"
            symbol="R$"
          />
        </OCol>
      </ORow>
      <Policy />
    </>
  );
};
