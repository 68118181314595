import { OCard, OCardBody } from "@maestro/react";
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FlowContainer } from "../../../../../../../components/flow-container";
import { useEligibilityOfferDetails } from "./eligibility-offer-details.hook";
import { buildEligibilityDetailsFields } from "./eligibility-offer-details.details";
import { EligibilityCriteriaModal } from "./_compose";

export const EligibilityOfferDetails = () => {
  const { chart, loading, hasError, snapshot, getOfferSnapshot, currentNode } =
    useEligibilityOfferDetails();

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate pageTitle={<PageTitle title="Detalhes da oferta" />}>
        <DetailsCard
          fields={buildEligibilityDetailsFields}
          loading={loading}
          value={snapshot?.company}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar os detalhes da oferta"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => getOfferSnapshot()} />
            </ErrorComponent>
          }
        />
      </DetailsTemplate>
      {chart && (
        <OCard>
          <OCardBody>
            <FlowContainer chart={chart} />
          </OCardBody>
        </OCard>
      )}
      {currentNode && <EligibilityCriteriaModal currentNode={currentNode} />}
    </div>
  );
};
