import { useMemo } from "react";
import { corporateRouter } from "routes/corporate-router.context";
import { Offer } from "services/bankinghub/models/types/cards/cards";
import { Requirement, RequirementStatus } from "./requirements-modal.types";
import { DEFAULT_REQUIREMENTS } from "./requirements-modal.utils";

export const useRequirementsModal = (offer: Offer | undefined) => {
  const data = useMemo((): Requirement[] => {
    const [
      solicitationRequirement,
      guarantorsRequirement,
      signaturesRequirement,
      feedbackRequirement,
    ] = DEFAULT_REQUIREMENTS;

    solicitationRequirement.status =
      offer?.isOnHiringProcess || offer?.isOnDebitHiringProcess
        ? RequirementStatus.Approved
        : RequirementStatus.Pending;

    guarantorsRequirement.status = offer?.isGuarantorsOnboarded
      ? RequirementStatus.Approved
      : RequirementStatus.Pending;

    guarantorsRequirement.link = {
      text: "Conferir cadastros",
      url: corporateRouter.routes.cadastro.customer.guarantors.path,
    };

    guarantorsRequirement.show =
      !offer?.isOnDebitHiringProcess && !!offer?.guarantors?.length;

    signaturesRequirement.status =
      offer?.isContractSigned && !offer?.isOnDebitHiringProcess
        ? RequirementStatus.Approved
        : RequirementStatus.Pending;

    feedbackRequirement.status =
      offer?.isOnHiringProcess &&
      offer?.isGuarantorsOnboarded &&
      offer?.isContractSigned
        ? RequirementStatus.Approved
        : RequirementStatus.Pending;

    return [
      solicitationRequirement,
      guarantorsRequirement,
      signaturesRequirement,
      feedbackRequirement,
    ];
  }, [offer]);

  return { data };
};
