import { OTypography } from "@maestro/react";
import { InvoiceInfoItem } from "./item-info.styles";
import { IItemInfoProps } from "./item-info.types";

export const ItemInfo = ({ label, value }: IItemInfoProps) => {
  return (
    <InvoiceInfoItem>
      <OTypography weight="500">{label}</OTypography>
      <OTypography weight="300">
        {!!value && typeof value === "string" ? value : "-"}
      </OTypography>
    </InvoiceInfoItem>
  );
};
