import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { getPropertyLabel } from "../../../../../../../../../../utils/get-property-label";
import { EditStepPropertyFields } from "./_compose";
import { useEditStepPropertyModal } from "./edit-step-property-modal.hook";
import { editStepPropertyModalId } from "./edit-step-property-modal.utils";

interface EditStepPropertyModalProps {
  reload: () => void;
}

export const EditStepPropertyModal = ({
  reload,
}: EditStepPropertyModalProps) => {
  const { form, loading, property, submit } = useEditStepPropertyModal(reload);

  return (
    <OModal
      id={editStepPropertyModalId}
      position="center"
      backdrop
      className="position-absolute"
    >
      <OModalHeader>
        <OTypography size="lg">Editar propriedade</OTypography>
        {property && (
          <OTypography type="default">{getPropertyLabel(property)}</OTypography>
        )}
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <EditStepPropertyFields idPrefix="edit" />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(editStepPropertyModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
