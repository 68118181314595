import { OCol, OField, OLoader, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearch } from "components/select-search";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddRelatioship } from "./add-relationship.hook";

export const AddRelationship = () => {
  const {
    sacadosOptions,
    sacadosLoading,
    cedentesOptions,
    cedentesLoading,
    tierId,
    form,
    submit,
    submitLoading,
  } = useAddRelatioship();

  const {
    formState: { errors },
    setValue,
  } = form;

  const clearSacado = useCallback(
    () => setValue("sacadoId", null as any),
    [setValue],
  );

  const clearCedente = useCallback(
    () => setValue("cedenteId", null as any),
    [setValue],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Criar relacionamento entre sacado e cedente"
          description={`Adicione um relacionamento entre sacado e cedente para o tier ${tierId}`}
        />
      }
      actions={
        <LoadingButton
          dataAction="tier_relacionamento:botao:criar_relacionamento"
          dataLabel="criar_relacionamento"
          onClick={submit}
          loading={submitLoading}
        >
          Criar relacionamento
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <OField
                label="Sacado"
                htmlFor="sacadoId"
                error={!!errors.sacadoId}
                message={errors?.sacadoId?.message}
              >
                <SelectSearch
                  id="sacadoId"
                  name="sacadoId"
                  placeholder="Busque o nome do sacado"
                  options={sacadosOptions}
                  disabled={sacadosLoading}
                  onClear={clearSacado}
                  className="w-100"
                  maxOptions={50}
                  eager={false}
                />
              </OField>
              {sacadosLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} md={6} xl={4} align="center" className="gap-2">
              <OField
                label="Cedente"
                htmlFor="cedenteId"
                error={!!errors.cedenteId}
                message={errors?.cedenteId?.message}
              >
                <SelectSearch
                  id="cedenteId"
                  name="cedenteId"
                  placeholder="Busque o nome do cedente"
                  options={cedentesOptions}
                  disabled={cedentesLoading}
                  onClear={clearCedente}
                  className="w-100"
                  maxOptions={50}
                  eager={false}
                />
              </OField>
              {cedentesLoading && <OLoader className="d-block" size="sm" />}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
