import { OToastManager } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { format } from "utils/date";
import { FormValuesType, OnboardingEditType } from "./onboarding-edit.type";

export const useOnboardingEdit = ({ id }: OnboardingEditType) => {
  const navigate = useNavigate();
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>();
  const { reset, handleSubmit, watch } = form;

  const watchStatus = watch("status");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        taxId: customer.identification,
        type: values.type,
        status: values.status,
        publishEvent: true,
      };

      await service.onboarding.onboardStatus(payload);

      OToastManager.success("Onboarding editado com sucesso");

      navigate(corporateRouter.routes.cadastro.customer.onboardings.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    const getOnboarding = customer.onboardings?.find(
      (onboarding) => onboarding?.id === Number(id),
    );

    if (!getOnboarding) return;

    const { type, status, insertedAt, updatedAt } = getOnboarding;

    if (!type || !status) return;

    const resetValues = {
      type,
      status,
      insertedAt: format.date(insertedAt),
      updatedAt: format.date(updatedAt),
    };

    reset(resetValues);
  }, [customer, id, reset]);

  return {
    form,
    submit,
    loading,
    watchStatus,
  };
};
