export const ORFieldInputs = [
  {
    label: "Nome",
    name: "description",
    id: "name",
    required: true,
    placeholder: "Ex: Dólar americano",
  },
  {
    label: "Código",
    name: "code",
    id: "code",
    required: true,
    mask: true,
    maskOption: {
      mask: "aaa",
      prepare: (value: string) =>
        value
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
    },
    placeholder: "Ex: USD",
  },
  {
    label: "Símbolo",
    name: "symbol",
    id: "symbol",
    placeholder: "Ex: $",
  },
  {
    label: "URL do ícone",
    name: "iconUrl",
    id: "iconUrl",
  },
  {
    label: "Quantidade de casas decimais",
    name: "decimals",
    id: "decimals",
    required: true,
    mask: true,
    maskOption: {
      mask: "0",
      prepare: (value: string) => {
        const num = parseInt(value, 10);
        if (!Number.isNaN(num) && num >= 1 && num <= 8) {
          return value;
        }
        return "";
      },
    },
    placeholder: "Ex: 5",
  },
  {
    label: "openingHours",
    name: "openingHours",
    id: "openingHours",
    required: true,
    placeholder: "Ex: 24h por dia, 7 dias da semana | 10h às 12h ",
  },
  {
    label: "incomingDisbursementHours",
    name: "incomingDisbursementHours",
    id: "incomingDisbursementHours",
    required: true,
    placeholder: "Ex: D+0 das 12h às 16h | D+1 das 09h às 17h50",
  },
  {
    label: "outgoingDisbursementHours",
    name: "outgoingDisbursementHours",
    id: "outgoingDisbursementHours",
    required: true,
    placeholder: "Ex: D+0 das 12h às 16h | D+1 das 09h às 17h50",
  },
];

export const LimitFields = [
  {
    label: "Limite padrão",
    name: "standardQuoteLimitAmount",
    id: "standardQuoteLimitAmount",
    required: true,
  },
  {
    label: "Limite pré-ticket",
    name: "preTicketQuoteLimitAmount",
    id: "preTicketQuoteLimitAmount",
    required: true,
  },
];

export const ORFieldRadioGroups = [
  {
    label: "AllowsNonStopTrading (24/7)",
    name: "allowsNonStopTrading",
    id: "allowsNonStopTrading",
    required: true,
    disbursement: false,
  },
  {
    label: "AllowsSameDayDisbursement (D+0)",
    name: "allowsSameDayDisbursement",
    id: "allowsSameDayDisbursement",
    required: true,
    disbursement: true,
  },
];

export const SameDayFields = [
  {
    label: "sameDayIncomingLimitTime (D+0)",
    name: "sameDayIncomingLimitTime",
    id: "sameDayIncomingLimitTime",
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "sameDayOutgoingLimitTime (D+0)",
    name: "sameDayOutgoingLimitTime",
    id: "sameDayOutgoingLimitTime",
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
];

export const NonStopTradingFields = [
  {
    label: "incomingStartTime (24/7)",
    name: "incomingStartTime",
    id: "incomingStartTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "incomingEndTime (24/7)",
    name: "incomingEndTime",
    id: "incomingEndTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "outgoingStartTime (24/7)",
    name: "outgoingStartTime",
    id: "outgoingStartTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "outgoingEndTime (24/7)",
    name: "outgoingEndTime",
    id: "outgoingEndTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
];

export function stringToTime(value: string | null | undefined) {
  return value?.replace(/^(\d{2})(\d{2})(\d{2}).*/, "$1:$2:$3");
}
