import { memo } from "react";
import { FinancialInstitutionIcon } from "../../../../../../financial-institution-icon";
import { OTruncateTypography } from "../../../../../../o-truncate-typography";

interface FinancialInstitutionOptionProps {
  title: string;
  bankCode?: string;
  isHeader?: boolean;
}

export const FinancialInstitutionOption = memo(
  ({ title, bankCode, isHeader }: FinancialInstitutionOptionProps) => {
    return (
      <div
        style={{ overflowX: "hidden" }}
        className={`d-flex gap-2 align-items-center ${isHeader ? "" : "py-2"}`}
        title={title}
      >
        <div className="flex-grow-1">
          <FinancialInstitutionIcon bankCode={bankCode} size={32} />
        </div>
        <OTruncateTypography type="dark" key={title}>
          {title}
        </OTruncateTypography>
      </div>
    );
  },
);
