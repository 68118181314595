import { client } from "../../../client";
import { clerkEndpoints } from "../../clerk.endpoints";
import {
  PostODataCompaniesGetAccountPfBody,
  PostODataCompaniesGetAccountPfResponse,
  PostODataCompaniesGetAccountPjBody,
  PostODataCompaniesGetAccountPjResponse,
  PostODataCompaniesGetAssignorBody,
  PostODataCompaniesGetAssignorResponse,
  PostODataCompaniesGetClientDeskBody,
  PostODataCompaniesGetClientDeskResponse,
  PostODataCompaniesGetEnergyBody,
  PostODataCompaniesGetEnergyResponse,
  PostODataCompaniesGetNoOnboardingBody,
  PostODataCompaniesGetNoOnboardingResponse,
  PostODataCompaniesGetPayerBody,
  PostODataCompaniesGetPayerResponse,
  PostODataCompaniesGetReviewWorkflowBody,
  PostODataCompaniesGetReviewWorkflowResponse,
  PostODataCompaniesGetDataUpdateResponse,
  PostODataCompaniesGetDataUpdateBody,
} from "./models";

export const clerkODataCompaniesService = {
  getEnergySelf(body: PostODataCompaniesGetEnergyBody) {
    return client.post<PostODataCompaniesGetEnergyResponse>(
      clerkEndpoints.admin.odata.desks.companies.energy.self,
      body,
    );
  },
  getAssignorSelf(body: PostODataCompaniesGetAssignorBody) {
    return client.post<PostODataCompaniesGetAssignorResponse>(
      clerkEndpoints.admin.odata.desks.companies.assignor.self,
      body,
    );
  },
  getAccountPfSelf(body: PostODataCompaniesGetAccountPfBody) {
    return client.post<PostODataCompaniesGetAccountPfResponse>(
      clerkEndpoints.admin.odata.desks.companies.accountPf.self,
      body,
    );
  },
  getAccountPjSelf(body: PostODataCompaniesGetAccountPjBody) {
    return client.post<PostODataCompaniesGetAccountPjResponse>(
      clerkEndpoints.admin.odata.desks.companies.accountPj.self,
      body,
    );
  },
  getPayerSelf(body: PostODataCompaniesGetPayerBody) {
    return client.post<PostODataCompaniesGetPayerResponse>(
      clerkEndpoints.admin.odata.desks.companies.payer.self,
      body,
    );
  },
  getNoOnboardingSelf(body: PostODataCompaniesGetNoOnboardingBody) {
    return client.post<PostODataCompaniesGetNoOnboardingResponse>(
      clerkEndpoints.admin.odata.desks.companies.noOnboarding.self,
      body,
    );
  },
  getReviewWorkflowSelf(body: PostODataCompaniesGetReviewWorkflowBody) {
    return client.post<PostODataCompaniesGetReviewWorkflowResponse>(
      clerkEndpoints.admin.odata.desks.reviewWorkflow.self,
      body,
    );
  },
  getClientDeskSelf(body: PostODataCompaniesGetClientDeskBody) {
    return client.post<PostODataCompaniesGetClientDeskResponse>(
      clerkEndpoints.admin.odata.desks.companies.desks.self,
      body,
    );
  },
  geDataUpdateWorkflowSelf(body: PostODataCompaniesGetDataUpdateBody) {
    return client.post<PostODataCompaniesGetDataUpdateResponse>(
      clerkEndpoints.admin.odata.desks.dataUpdateWorkflow.self,
      body,
    );
  },
};
