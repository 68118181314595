import {
  ExtraFields,
  PutBusinessagreementByIdBody,
} from "services/turtlefee/models/requests";
import { GetBusinessagreementSearchResponse } from "services/turtlefee/models/responses";
import { banksList } from "utils/bank-list";
import { AgreementPartnerObject, FeesSacadoGrid } from "./fees-sacado.types";

export const rebateTypeMap = {
  REBATE_QF: "Rebate padrão",
  REBATE_QF_ADJUSTED_BY_INVOICE: "Rebate ajustado",
};

export const paymentTypeMap = {
  Disbursement: "Desembolso",
  Settlement: "Liquidação",
};

export const banksOptions = banksList
  .sort(({ id: a }, { id: b }) => Number(a) - Number(b))
  .map((bank) => ({
    label: `${bank.id} - ${bank.title}`,
    value: bank.id,
  }));

export const customizeData = (
  data: GetBusinessagreementSearchResponse,
): FeesSacadoGrid[] => {
  return data.map((item) => {
    const account =
      item.favored.find(({ key }) => key === "account")?.value ?? "";
    const agency =
      item.favored.find(({ key }) => key === "agency")?.value ?? "";
    const bank =
      item.favored
        .find(({ key }) => key === "bank")
        ?.value.toString()
        .padStart(3, "0") ?? "";

    return { ...item, account, agency, bank };
  });
};

export const formatFavored = (
  data: PutBusinessagreementByIdBody,
  updated: ExtraFields,
): TurtleFee.AgreementPartner[] => {
  const dataFavored = Object.fromEntries(
    data.favored.map((el) => Object.values(el)),
  );
  const newFavored: AgreementPartnerObject = {
    ...dataFavored,
    ...updated,
  };
  return Object.entries(newFavored).map(([key, value]) => ({
    key,
    value,
  }));
};
