import { service } from "services";
import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addStatusObservationSchema } from "./aprovar-app-modal.form";
import { useEffect, useMemo } from "react";
import { OToastManager, modalManager } from "@maestro/core";


export const useAppApprove = (
    modalId: string,
    appId: string,
    version: string | number,
    tenantId: string,
    successCallback: () => void,
 
) => {
    const {
        callService: patchApproveApp,
        hasError,
        loading,
        value
        
    } = useServiceCall(service.developers.patchApproveApp)

    const form = useForm<{ statusObservation: string }>({
        defaultValues: { statusObservation: "Aprovado" },
        resolver: yupResolver(addStatusObservationSchema),
      });
    const { handleSubmit, reset } = form;
    const submit = useMemo(()=>
      handleSubmit(({statusObservation})=>{
        patchApproveApp(appId, version, tenantId, {statusObservation})
      }),
      [handleSubmit]
    )
    useEffect(() => {
        const cleanup = modalManager.on(modalId, "modalOpen", () =>
          reset({ statusObservation: "" }),
        );
        return cleanup;
      }, [modalId, reset]);
      useEffect(() => {
        if (hasError) OToastManager.danger("Falha ao aprovar o aplicativo");
      }, [hasError]);
    
      useEffect(() => {
        if (value) {
          OToastManager.success("Aplicativo aprovado com sucesso");
          modalManager.hide(modalId);
          successCallback();
        }
      }, [modalId, successCallback, value]);
    
      return {
        form,
        loading,
        submit,
      };
}