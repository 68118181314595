import { OIcon } from "@maestro/react";
import { UnstyledButton } from "components/unstyled-button";

interface ClearButtonProps {
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
}

export const ClearButton = ({ disabled, onClick }: ClearButtonProps) => {
  return (
    <UnstyledButton
      onClick={onClick}
      disabled={disabled}
      style={{ display: disabled ? "none" : undefined }}
    >
      <OIcon category="orq" icon="orq-close" />
    </UnstyledButton>
  );
};
