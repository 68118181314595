import * as yup from "yup";

export interface AddBigNumbersFormFields {
  bigNumbers: string[];
}

export const schema = yup.object().shape({
  bigNumbers: yup.array().of(yup.string()),
});

export const defaultValues = {
  bigNumbers: [] as string[],
};
