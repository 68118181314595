import { OToastManager } from "@maestro/core";
import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const dataSource = dataSourceCustomStoreGenerator(
  () =>
    service.onboarding
      .getOfficers()
      .then(({ data }) => data ?? [])
      .catch(() => {
        const validator = "Erro ao buscar officers";

        OToastManager.danger(validator);
        throw new Error(validator);
      }),
  {
    customStoreOptions: {
      insert: async (values) => {
        try {
          await service.onboarding.registerOfficer({
            officerCge: values.cge,
            officerTaxId: values.taxId,
          });

          OToastManager.success("Officer adicionada com sucesso");

          return values;
        } catch (error) {
          OToastManager.danger("Um erro ocorreu ao tentar adicionar.");
          throw new Error("Um erro ocorreu ao tentar adicionar.");
        }
      },
      update: async (originalData, updatedData) => {
        const data = { ...originalData, ...updatedData };

        try {
          await service.onboarding.updateOfficer({
            id: data.id,
            officerCge: data.cge,
            officerTaxId: data.taxId,
          });
          OToastManager.success("Officer atualizada com sucesso.");
        } catch (error) {
          OToastManager.danger("Erro ao atualizar officer");
        }
      },
    },
  },
);

export const dofficersGrid: ODataGridGeneratorConfig<
  Awaited<ReturnType<typeof service.onboarding.getOfficers>>["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum officers",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    onRowValidating: (e) => {
      const { taxId, cge } = {
        ...e.oldData,
        ...e.newData,
      };

      if (!taxId && !cge) {
        e.errorText = "Necessário preencher CPF ou CGE";
        e.isValid = false;
      }
    },
  },
  columns: [
    {
      dataField: "id",
      allowEditing: false,
    },
    {
      dataField: "cge",
      dataType: "number",
    },
    {
      dataField: "taxId",
      caption: "CPF",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      customizeText: ({ value }) => masks.cpfCnpj(value),
      validationRules: [
        {
          type: "custom",
          message: "CPF inválido",
          ignoreEmptyValue: true,
          validationCallback: (option) => {
            return validators.cpf(option.value);
          },
        },
      ],
    },
    {
      dataField: "name",
      caption: "Nome",
      allowEditing: false,
    },
    {
      caption: "Ações",
      formItem: { visible: false },
      cellRender: ({ component, rowIndex, row }) =>
        !row.isEditing && (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar",
                onClick: () => {
                  component.editRow(rowIndex);
                },
              },
            ]}
          />
        ),
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
};
