import { OToastManager } from "@maestro/core";
import { portals } from "components/impersonate-dropdown";
import { service } from "services";
import { logger } from "../logger";

export const impersonateRedirect = async (
  email: string,
  portal: keyof typeof portals,
) => {
  try {
    const { data } = await service.gatekeeper.impersonate({
      user: email,
    });
    window.open(
      portals[portal].url + `/impersonar/${data.token}?portal=${portal}`,
    );
  } catch (err) {
    OToastManager.danger("Usuário não pode ser impersonado");
    logger.warn("Usuário não pode ser impersonado", err);
  }
};
