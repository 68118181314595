export const usuariosRouteParams = {
  identification: "identification",
} as const;

type UsuariosParams = Record<keyof typeof usuariosRouteParams, string>;

export type UsuariosClienteByIdentificationRouteParams = Pick<
  UsuariosParams,
  "identification"
>;

export type UsuariosProdutoByIdentificationRouteParams = Pick<
  UsuariosParams,
  "identification"
>;
