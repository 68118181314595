import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import {
  contractFileConfigurationGrid,
  dataSource,
} from "./contract-file-configuration.grid";

export const ContractFileConfigurationPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configurações de arquivo do contrato" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.ferramentas.product.onboarding
              .workflowsConfigs.contractFileConfiguration.register.path
          }
          role={
            roles.ferramentas.product
              .onboardingWorkflowsConfigsContractFileConfigurationRegisterPage
              .role
          }
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={contractFileConfigurationGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
