import { modalManager } from "@maestro/core";
import {
  OButton,
  OLabel,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { contentTypeOptions } from "../../../../form-details.utils";
import { useSubimitCardModal } from "./submit-card-modal.hook";

export const SubimitCardModal = () => {
  const { hide } = modalManager;
  const {
    form,
    isToggleable,
    loading,
    modalMode,
    workflowFormTemplate,
    getForm,
    setIsToggleable,
    setSelectedCard,
    submitCard,
  } = useSubimitCardModal();

  return (
    <OModal
      id="submit-card-modal"
      position="center"
      size="md"
      className="position-absolute"
      onModalClose={() => {
        workflowFormTemplate && getForm(workflowFormTemplate.id);
        setSelectedCard(undefined);
      }}
    >
      <OModalHeader closeButton>
        <div className="d-flex gap-3 align-items-center">
          <OTypography tag="h1" size="xxl" key={modalMode.modalTitle}>
            {modalMode.modalTitle}
          </OTypography>
          {loading && <OLoader size="xs" />}
        </div>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-5">
          <FormProvider {...form}>
            <form spellCheck="false">
              <ORFieldInput id="title" name="title" tag="text" label="Título" />
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
              />
              <ORFieldSelect
                id="step-content"
                name="content"
                label="Tipo do conteúdo"
              >
                {contentTypeOptions.map((option) => (
                  <OOption
                    value={option}
                    key={`draggable-cards-step-content-${option}`}
                  >
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            </form>
          </FormProvider>
        </div>
        <div className="d-flex flex-column ">
          <OLabel htmlFor="isToggleable">Colapsável</OLabel>
          <OSwitch
            type="tertiary"
            id="isToggleable"
            value={isToggleable}
            size="sm"
            onInput={() => setIsToggleable(!isToggleable)}
          />
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            type="dark"
            outline
            onClick={() => {
              workflowFormTemplate && getForm(workflowFormTemplate.id);
              setSelectedCard(undefined);
              hide("submit-card-modal");
            }}
          >
            Fechar
          </OButton>

          <OButton
            key={modalMode.modalAction}
            type="dark"
            onClick={() => submitCard()}
          >
            {modalMode.modalAction}
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
