import { GetCompanyProductsResponse } from "services/hubloan/models";
import { productMap } from "../../offers.utils";

interface ProductsProps {
  availableProducts: GetCompanyProductsResponse[];
}

export const Products = ({ availableProducts }: ProductsProps) => {
  return (
    <>
      {availableProducts.map((product) => {
        const props = productMap[product.identification];
        const title = props.title(product.creditLineVisibleName);

        if (props?.content)
          return (
            <div key={product.id}>
              {props.content({
                ...props,
                id: product.id,
                offerId: product.offerId,
                limit: product.availableAmount,
                key: props.identification,
                title,
                creditLineIcon: product.creditLineIcon,
                creditLineDescription: product.creditLineDescription,
                iconName: product.creditLineIcon ?? props.iconName,
                fundingType: product.fundingType,
              })}
            </div>
          );

        return null;
      })}
    </>
  );
};
