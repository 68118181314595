import * as yup from "yup";

export const editDefaultGridSchema = yup.object().shape({
  defaultGridId: yup.number(),
  isVisible: yup.array().default([]),
});

export const editDefaultGridDefaultValues = {
  defaultGridId: 0,
  isVisible: [],
};

export interface EditDefaultGridFormValues {
  defaultGridId: number;
  isVisible: string[];
}
