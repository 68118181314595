import { OBadge, OIcon } from "@maestro/react";
import { UnstyledButton } from "../../../../unstyled-button";

interface SelectedValueProps {
  disabled: boolean;
  removeValue: () => void;
  value: string;
}

export const SelectedValue = ({
  disabled = false,
  removeValue,
  value,
}: SelectedValueProps) => (
  <OBadge type="dark" size="sm" rounded outline>
    <div className="d-flex align-items-center gap-2">
      {value}
      {!disabled && (
        <UnstyledButton onClick={() => removeValue()}>
          <OIcon category="fal" icon="fa-times" />
        </UnstyledButton>
      )}
    </div>
  </OBadge>
);
