import {
  dataSourceCustomStoreGenerator,
  ExtractODataGridGeneratorConfigType,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { exchangePreTicketsGrid } from "../../../components/pre-tickets/pre-tickets.grid";

const dataSource = dataSourceCustomStoreGenerator<
  ExtractODataGridGeneratorConfigType<ReturnType<typeof exchangePreTicketsGrid>>
>(() =>
  service.hubFx.adminCustomer.getPreTickets().catch(() => {
    throw new Error("Erro ao buscar pré-tickets.");
  }),
);

export const ExchangePreTicketsPage = () => {
  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pré-Tickets" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangePreTicketsGrid(mapSymbol)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
