import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useProfiles = () => {
  const {
    callService: _getAllProfiles,
    loading: getProfilesLoading,
    hasError: getProfilesHasError,
    value: allProfiles,
  } = useServiceCall(service.gatekeeper.getAllProfiles);

  const getProfiles = useCallback(async () => {
    const { success } = await _getAllProfiles();
    if (!success) OToastManager.danger("Não foi possível buscar os profiles");
  }, [_getAllProfiles]);

  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  return {
    allProfiles,
    getProfiles,
    getProfilesHasError,
    getProfilesLoading,
  };
};
