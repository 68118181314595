import { OOption, ORFieldSelect } from "@maestro/react";
import { productMap } from "pages/recebiveis/pages/produto/estrategias-de-onboarding/_compose/formulario/onboarding-strategies-form.utils";

export const SelectTipoProduto = () => {
  return (
    <ORFieldSelect
      id="tipoProduto"
      name="tipoProduto"
      dataAction="estrategias_tco:input:tipo_produto"
      dataLabel="tipo_produto"
      label="Tipo de Produto"
      placeholder="Selecione"
      disabled
      value="CCC"
    >
      {productMap.map((product) => (
        <OOption key={product.value} id={product.value} value={product.value}>
          {product.title}
        </OOption>
      ))}
    </ORFieldSelect>
  );
};
