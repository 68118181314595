import React from "react";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { ApiSelector } from "../../../../../../../components/api-selector";

type DistributorSelectorProps = Omit<
  React.ComponentProps<typeof ApiSelector>,
  "optionMapper" | "serviceMethod"
>;

export const DistributorSelector = (props: DistributorSelectorProps) => {
  return (
    <ApiSelector
      {...props}
      optionMapper={(value) => {
        const options =
          value?.response?.map(({ entity, id }) => ({
            content: `${entity.name} - ${fastCpfCnpjMask(entity.taxId)}`,
            value: id,
          })) ?? [];
        options.sort((a, b) => a.content.localeCompare(b.content));
        return options;
      }}
      serviceMethod={service.hubEnergy.getDistributors}
    />
  );
};
