import * as yup from "yup";

export interface PropertyFormValues {
  name: string;
  nirf: string | null;
  ccir: string | null;
  rgi: string;
  idealFraction: number;
  totalArea: number | null;
  state: string;
  cityId: number;
  car: string;
  registration: string;
  identification: string;
}

export const propertyFormValidationsSchema: yup.ObjectSchema<PropertyFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    nirf: yup.string().default(null).nullable(),
    ccir: yup.string().default(null).nullable(),
    rgi: yup.string().required("Este campo é obrigatório"),
    idealFraction: yup
      .number()
      .transform((value) => value || undefined)
      .required("Este campo é obrigatório"),
    totalArea: yup
      .number()
      .transform((value) => value || undefined)
      .default(null)
      .nullable(),
    state: yup.string().required("Este campo é obrigatório"),
    cityId: yup
      .number()
      .transform((value) => value || undefined)
      .required("Este campo é obrigatório"),
    car: yup.string().required("Este campo é obrigatório"),
    registration: yup.string().required("Este campo é obrigatório"),
    identification: yup.string().required("Este campo é obrigatório"),
  });
