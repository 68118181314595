import { OIcon, OInputText, OTooltip } from "@maestro/react";
import { RowContainer } from "./input-copy.styles";
import { IInputCopyProps } from "./input-copy.types";

export const InputCopy = ({
  value,
  dataAction,
  dataLabel,
  disabled,
}: IInputCopyProps) => {
  return (
    <RowContainer className="gap-2 align-items-center">
      <OInputText
        dataAction={dataAction}
        dataLabel={dataLabel}
        id="input-copy"
        name="input-copy"
        value={value}
        disabled={disabled}
      />
      <OTooltip>
        <span slot="tooltip-content">Copiar</span>
        <button
          style={{ all: "unset" }}
          onClick={() => {
            navigator.clipboard.writeText(value);
          }}
        >
          <OIcon category="fal" icon="fa-copy" />
        </button>
      </OTooltip>
    </RowContainer>
  );
};
