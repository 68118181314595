import { OToastManager, modalManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GenerateContract } from "../_compose/generate-contract";
import { ApproveOnboardingWorkflow } from "./_compose/approve-onboarding-workflow.component";
import { ReopenOnboarding } from "./_compose/reopen-onboarding.component";
import { ResendOnboardingAnalysis } from "./_compose/resend-onboarding-analysis.component";
import { SyncOnboardingWorkflow } from "./_compose/sync-onboarding-workflow.component";
import { TerminateOnboardingRelationship } from "./_compose/terminate-onboarding-relationship.component";

export const buildDataSource = (requestTaxId: string) =>
  dataSourceCustomStoreGenerator(() =>
    service.onboarding
      .getEntityWorkflows({ requestTaxId })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Não foi possível buscar os workflows";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const customerOnboardingWorkflowsGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getEntityWorkflows>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum workflow",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
  },
  columns: [
    {
      dataField: "id",
      dataType: "string",
      caption: "Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "relationship",
      dataType: "string",
      caption: "Relacionamento",
    },
    {
      dataField: "portalStatus",
      dataType: "string",
      caption: "Status",
    },
    {
      caption: "Mesa",
      calculateCellValue: ({ deskCode, deskName }) =>
        [deskCode, deskName].filter(Boolean).join(" - ") || "-",
      calculateFilterExpression: (filterValue) => [
        ["deskCode", "contains", filterValue],
        "or",
        ["deskName", "contains", filterValue],
      ],
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de criação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "updatedDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Última atualização",
      allowHeaderFiltering: false,
    },
    {
      caption: "Ações",
      cellRender: ({ data: { id, relationship } }) => {
        const syncModalId = `sync-onboarding-workflow-${id}-modal`;
        const approveModalId = `approve-onboarding-workflow-${id}-modal`;
        const terminateModalId = `terminate-onboarding-relationship-${id}-modal`;
        const resendOnboardingAnalysisModalId = `resend-onbaording-analysis-${id}-modal`;
        const reopenOnboardingModalId = `reopen-onbaording-${id}-modal`;
        const generateContractModalId = `generate-contract-${id}-modal`;
        return (
          <>
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-swap-circle" },
                  label: "Sincronizar",
                  onClick: () => {
                    modalManager.show(syncModalId);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-check" },
                  label: "Aprovar conclusão",
                  onClick: () => {
                    modalManager.show(approveModalId);
                  },
                  role: roles.cadastro.customer.toolsApproveContingencyPage
                    .role,
                },
                {
                  icon: { category: "orq", icon: "orq-cancel" },
                  label: "Encerrar relacionamento",
                  onClick: () => {
                    modalManager.show(terminateModalId);
                  },
                  role: roles.cadastro.customer.toolsFinishAccessPage.role,
                },
                {
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  label: "Editar",
                  route:
                    corporateRouter.routes.cadastro.customer.onboardings.onboarding.edit.path(
                      {
                        id,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingsOnboardingEditPage
                    .role,
                },
                {
                  icon: { category: "orq", icon: "orq-file" },
                  label: "Logs",
                  route:
                    corporateRouter.routes.cadastro.customer.onboardings.onboarding.logs.path(
                      {
                        id,
                      },
                    ),
                  role: roles.cadastro.customer.onboardingsOnboardingLogsPage
                    .role,
                },
                {
                  icon: { category: "orq", icon: "orq-current-account" },
                  label: "Migrar",
                  route:
                    corporateRouter.routes.cadastro.customer.tools.migrateDesks.byWorkflowId.path(
                      {
                        workflowId: id,
                      },
                    ),
                  role: roles.cadastro.customer
                    .toolsMigrateDesksByWorkflowIdPage.role,
                },
                {
                  icon: { category: "orq", icon: "orq-telegram" },
                  label: "Reenviar",
                  onClick: () => {
                    modalManager.show(resendOnboardingAnalysisModalId);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-locker-open" },
                  label: "Reabrir",
                  onClick: () => {
                    modalManager.show(reopenOnboardingModalId);
                  },
                },
                {
                  icon: { category: "orq", icon: "orq-contract-agreement" },
                  label: "Gerar contrato",
                  onClick: () => {
                    modalManager.show(generateContractModalId);
                  },
                },
              ]}
            />

            <SyncOnboardingWorkflow modalId={syncModalId} workflowUuid={id} />
            <ApproveOnboardingWorkflow
              modalId={approveModalId}
              workflowId={id}
            />
            <TerminateOnboardingRelationship
              modalId={terminateModalId}
              workflowId={id}
              relationship={relationship}
            />
            <ResendOnboardingAnalysis
              modalId={resendOnboardingAnalysisModalId}
              workflowUuid={id}
            />
            <ReopenOnboarding
              modalId={reopenOnboardingModalId}
              workflowUuid={id}
            />
            <GenerateContract
              modalId={generateContractModalId}
              workflowUuid={id}
            />
          </>
        );
      },
    },
  ],
};
