import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services";
import { ODataBatchValueItem } from "services/hubcreditmanager/odata/models/responses/get-odata-batch-file-templates-admin.respose";

export const useBatchTemplate = () => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<ODataBatchValueItem>();

  const template = useServiceCall(
    service.hubCreditManager.getBatchFileTemplates,
  );

  const { callService: getBatchTemplateDetails } = template;

  useEffect(() => {
    if (!selectedTemplate) return;
    getBatchTemplateDetails();
  }, [getBatchTemplateDetails, selectedTemplate]);

  return {
    selectedTemplate,
    setSelectedTemplate,
    template,
  };
};
