import {
  OButton,
  OCol,
  OInputText,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services";
import { CprReference } from "services/hubcreditmanager/models/responses";

export const LINK_CPR_MODAL = "link-to-existing-cpr";

interface LinkToExistingCprButtonProps {
  limitRequestId?: number;
}

export const LinkToExistingCprButton = ({
  limitRequestId,
}: LinkToExistingCprButtonProps) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [cprInput, setCprInput] = useState<string | undefined>();
  const [cpr, setCpr] = useState<CprReference | undefined>();

  const { callService: getAllCprIds, value: cprIds } = useServiceCall(
    service.hubCreditManager.getAllCprIds,
  );

  const { callService: linkToCpr, loading } = useServiceCall(
    service.hubCreditManager.linkToCpr,
  );

  useEffect(() => {
    getAllCprIds();
  }, [getAllCprIds]);

  useEffect(() => {
    modalManager.on(LINK_CPR_MODAL, "modalClose", () => {
      setCprInput(undefined);
      setCpr(undefined);
    });
  }, [getAllCprIds]);

  function findSelectedCpr() {
    if (!cprIds) return;

    setSearchLoading(true);

    const existingCpr = cprIds?.find(
      (c) =>
        c.cprId === cprInput &&
        c.limitRequest.currentLimit?.creditLine?.identification === "AGRO",
    );

    if (existingCpr) setCpr(existingCpr);

    setTimeout(() => setSearchLoading(false), 800);
  }

  async function handleLinkToCpr() {
    if (!cpr || !limitRequestId) return;

    const payload = {
      limitRequestId,
      cprId: cpr?.cprId,
    };

    const response = await linkToCpr(payload);

    if (response.success) {
      modalManager.hide(LINK_CPR_MODAL);
      OToastManager.success("Limite vinculado com sucesso!");
    } else
      OToastManager.danger(
        "Houve um erro ao tentar realizar o vínculo, tente novamente mais tarde.",
      );
  }

  return (
    <>
      <OModal id={LINK_CPR_MODAL} position="center" size="sm" backdrop={false}>
        <div>{(loading || searchLoading) && <OLoader backdrop absolute />}</div>
        <OModalHeader divider closeButton>
          <OTypography size="lg">Vincular a CPR existente</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-2">
            <OTypography>Selecione a CPR que deseja vincular:</OTypography>
            <ORow>
              <OCol sm={8}>
                <OInputText
                  className="w-100"
                  placeholder="Informar tag"
                  id="cprId"
                  name="cprId"
                  value={cprInput}
                  onInput={(e) => setCprInput(e.currentTarget.value)}
                />
              </OCol>
              <OCol sm={4}>
                <OButton outline type="dark" onClick={() => findSelectedCpr()}>
                  Buscar
                </OButton>
              </OCol>
            </ORow>
            {!!cpr && !searchLoading && (
              <div className="d-flex flex-column gap-2 mt-3">
                <OTypography weight="bold">Informações do limite</OTypography>
                <ORow>
                  <OCol className="d-flex flex-column gap-2">
                    <OTypography>Cliente</OTypography>
                    <OTypography type="dark-60">
                      {cpr.limitRequest.customer.legalEntity.name}
                    </OTypography>
                  </OCol>
                  <OCol className="d-flex flex-column gap-2">
                    <OTypography>Valor</OTypography>
                    <OTypography type="dark-60">
                      {masks.currency(cpr.limitRequest.amount, "R$")}
                    </OTypography>
                  </OCol>
                </ORow>
              </div>
            )}
          </div>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex gap-3">
            <OButton onClick={() => modalManager.hide(LINK_CPR_MODAL)} outline>
              Fechar
            </OButton>
            <OButton onClick={() => handleLinkToCpr()} disabled={!cpr}>
              Confirmar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <OButton onClick={() => modalManager.show(LINK_CPR_MODAL)} outline>
        Vincular
      </OButton>
    </>
  );
};
