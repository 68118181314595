import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { FinancedAsset } from "./financed-asset.types";

export const financedAssetPropertyContratualPatiesGrid = {
  datagrid: {
    noDataText: "Nenhuma parte relacionada encontrada",
  },
  columns: [
    {
      dataField: "legalEntity.name",
      caption: "Nome",
    },
    {
      dataField: "legalEntity.taxId",
      caption: "Documento",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  FinancedAsset["properties"][number]["contractualParties"][number]
>;
