import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

import { service } from "services";

export type GenericField = NonNullable<
  Awaited<
    ReturnType<typeof service.acc.nature.getNatureGenericFieldTypes>
  >["data"]["response"]
>[number];

export const adminContingencyACCGenericFieldsGrid = {
  datagrid: {
    noDataText: "Nenhum campo genérico encontrado.",
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceCustomStoreGenerator(
      () =>
        service.acc.nature
          .getNatureGenericFieldTypes()
          .then(({ data }) => data.response ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar campos genéricos");
          }),
      {
        customStoreOptions: {
          remove: async (removedNature) => {
            try {
              if (!removedNature.id) return;

              await service.acc.nature.deleteNatureGenericFieldTypes(
                removedNature.id,
              );

              OToastManager.success("Campo genérico foi removido com sucesso.");
            } catch {
              OToastManager.danger(
                "Um erro ocorreu ao tentar deletar o campo genérico. Por favor, tente novamente mais tarde.",
              );
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "mask",
      caption: "Máscara",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "fas", icon: "fa-pen" },
              label: "Editar",
              role: roles.acc.product.contingencyNaturesGenericFieldsUpdatePage
                .role,
              route:
                corporateRouter.routes.acc.product.contingency.natures.genericFields.update.path(
                  {
                    id: data.id,
                  },
                ),
            },
            {
              icon: { category: "fas", icon: "fa-trash" },
              label: "Remover",
              onClick: () => {
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<GenericField>;
