import { ODivider, OTypography } from "@maestro/react";
import { Fragment } from "react";
import { BankslipEventDetail } from "../bankslip-event-detail";

interface BankslipEventsGroupProps {
  events: BankingHub.BankslipEvents;
}

export const BankslipEventsGroup = ({
  events: { bankSlipEvents, bankSlipPixEvents },
}: BankslipEventsGroupProps) => {
  if (!bankSlipEvents.length && !bankSlipPixEvents?.length) {
    return (
      <OTypography type="dark">
        Não existem eventos disponíveis para este boleto.
      </OTypography>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-3">
        <OTypography type="dark" weight="bold">
          BILLBO
        </OTypography>
        {bankSlipEvents.map((event, index) => {
          const hasDivider = index < bankSlipEvents.length - 1;
          const fragmentKey = `${event.status}_${index}`;
          return (
            <Fragment key={fragmentKey}>
              <BankslipEventDetail event={event} />
              {hasDivider && <ODivider size="xxs" />}
            </Fragment>
          );
        })}
        {!!bankSlipPixEvents?.length && (
          <>
            <ODivider />
            <OTypography type="dark" weight="bold">
              BAAS
            </OTypography>
            {bankSlipPixEvents.map((event, index) => {
              const hasDivider = index < bankSlipPixEvents.length - 1;
              const fragmentKey = `${event.status}_${index}`;
              return (
                <Fragment key={fragmentKey}>
                  <BankslipEventDetail event={event} />
                  {hasDivider && <ODivider />}
                </Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
