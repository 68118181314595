import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { AdvisoredEntities } from "pages/ferramentas/pages/gatekeeper/advisors/advisored-entities.master-detail";
import { GridTemplate } from "templates/grid-template";

export const AdvisoredCustomersPage = () => {

    const gridRef = useGridRef();

    return (
        <GridTemplate
            pageTitle={<PageTitle title="Clientes assessorados" />}
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
            showExportButton
        >
            <AdvisoredEntities identificationTuple="self" gridRef={gridRef} />
        </GridTemplate>
    );
};
