const baseImageUrl = "https://empresas.btgpactual.com/app-assets/card";

export const AssetsUrl = {
  card: {
    mercado_pago: `${baseImageUrl}/mercado_pago.png`,
    getnet: `${baseImageUrl}/getnet.png`,
    adyen: `${baseImageUrl}/adyen.png`,
    cielo: `${baseImageUrl}/cielo.png`,
    safrapay: `${baseImageUrl}/safrapay.png`,
    stone: `${baseImageUrl}/stone.png`,
    sumup: `${baseImageUrl}/sumup.png`,
    pagseguro: `${baseImageUrl}/pagseguro.png`,
    bin: `${baseImageUrl}/bin.png`,
    adiq: `${baseImageUrl}/adiq.png`,
    ton: `${baseImageUrl}/ton.png`,
    rede: `${baseImageUrl}/rede.png`,
    bancoob: `${baseImageUrl}/bancoob.png`,
    granito: `${baseImageUrl}/granito.png`,
    global_payments: `${baseImageUrl}/global_payments.png`,
    cloudwalk: `${baseImageUrl}/cloudwalk.png`,
    vero: `${baseImageUrl}/vero.png`,
    other: `${baseImageUrl}/operation.png`,
  },
};
