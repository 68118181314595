import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { endpoints } from "services/endpoints";

const redirectDetailLink = (uuid: string) => {
  return corporateRouter.routes.ferramentas.product.onboarding.semaphore.consults.details.path(
    { uuid },
  );
};

export const dataSource =
  dataSourceODataStoreGenerator<HubOnboardingBack.Odata.SemaphoreWorkflowView>(
    endpoints.onboarding.admin.entitySemaphore.listAll,
  );

export const grid: ODataGridGeneratorConfig<HubOnboardingBack.Odata.SemaphoreWorkflowView> =
  {
    datagrid: {
      noDataText: "Nenhuma consulta a semáforo foi localizada",
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        caption: "Detalhes",
        role: roles.ferramentas.product.entitySemaphoreDetails.role,
        cellRender: ({ data: { UUID } }) => (
          <DetailsLink href={redirectDetailLink(UUID)} />
        ),
      },
      {
        dataField: "TaxId",
        caption: "CNPJ/CPF",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      { dataField: "Color", caption: "Cor" },
      {
        dataField: "RequestedBy",
        caption: "Requisitado Por",
        format: (value) => masks.cpf(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "CreatedDate",
        dataType: "datetime",
        caption: "Requisitado em",
        format: "dd/MM/yyyy HH:mm:ss",
        sortOrder: "desc",
      },
    ],
  };
