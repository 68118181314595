import { OTypography } from "@maestro/react";
import { MoneyMask } from "../../../../../utils";
import { Currency } from "../../../../cards.types";
import { IItem } from "../../../invoices.types";
import { DetailInfoItem } from "../../details.styles";
import { IMoneyInfosProps } from "./money-infos.types";

export const MoneyInfos = ({ label, value, items }: IMoneyInfosProps) => {
  return (
    <DetailInfoItem>
      <OTypography type="dark">{`${label}: ${MoneyMask(
        value,
        Currency.BRL,
      )}`}</OTypography>
      <ul>
        {items.map((item: IItem, index: number) => (
          <li key={`item-${index}`}>
            <OTypography type="dark">{`${item.title}: ${MoneyMask(
              item.amount,
              Currency.BRL,
            )}`}</OTypography>
          </li>
        ))}
      </ul>
    </DetailInfoItem>
  );
};
