import { OToastManager } from "@maestro/core";
import { OInputCpf } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../../../utils";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.AgentResponse>(
    () =>
      service.hubEnergy
        .getAgents()
        .then(({ data }) => data.response)
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0]?.errorMessage ??
            "Erro ao buscar os agentes";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            const { data } = await service.hubEnergy.upsertAgent({
              level: values.level,
              name: values.name,
              taxId: values.taxId,
            });
            OToastManager.success("Agente adicionado");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].errorMessage ??
              "Erro ao adicionar agente";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (oldValues, newValues) => {
          try {
            const newData = { ...oldValues, ...newValues };

            const { data } = await service.hubEnergy.upsertAgent({
              agentId: oldValues.id,
              level: newData.level,
              name: newData.name,
              taxId: newData.taxId,
            });
            OToastManager.success("Agente atualizado");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].errorMessage ??
              "Erro ao atualizar agente";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async (values) => {
          try {
            await service.hubEnergy.deleteAgent(values.id);
            OToastManager.success("Agente excluído");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].errorMessage ??
              "Erro ao excluir agente";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

const analysisLevelMap = {
  BPO: "BPO",
  OPS: "OPS",
  ADMIN: "ADMIN",
} satisfies Record<HubEnergy.EAnalysisLevel, string>;

export const agentsGrid: ODataGridGeneratorConfig<HubEnergy.AgentResponse> = {
  datagrid: {
    noDataText: "Nenhum agente",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    editing: {
      confirmDelete: true,
      allowDeleting: true,
      allowUpdating: true,
    },
    stateStoring: gridStorage("energy-agents"),
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortIndex: 0,
      sortOrder: "asc",
      width: "auto",
      allowEditing: false,
    },
    {
      dataField: "taxId",
      dataType: "string",
      caption: "CPF",
      format: (value) => masks.cpf(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      editCellRender: ({ setValue, rowIndex, value }) => (
        <OInputCpf
          id={`taxid-${rowIndex}`}
          name={`taxid-${rowIndex}`}
          value={value}
          onInput={(evt) => setValue(evt.currentTarget.value)}
          aspect="unstyled"
          className="p-2"
          autocomplete="off"
        />
      ),
      width: "auto",
    },
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "level",
      dataType: "string",
      caption: "Nível",
      lookup: {
        dataSource: Object.entries(analysisLevelMap).map(([k, v]) => ({
          value: k,
          label: v,
        })),
        displayExpr: "label",
        valueExpr: "value",
      },
      width: "auto",
    },
  ],
};
