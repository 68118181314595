import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { UseFormReturn } from "react-hook-form";
import { CedenteDetailsForm } from "../../cedente-details.types";
import { useFilteredEstrategiaSelector } from "./filtered-estrategia-selector.hook";
import { SacadoDetailsForm } from "../../../../sacados/detalhes/sacado-details.types";

interface FilteredEstrategiaSelectorProps {
  form: UseFormReturn<CedenteDetailsForm | SacadoDetailsForm>;
  tipoProduto: string | null;
  companyId: string | number;
}

export const FilteredEstrategiaSelector = ({
  companyId,
  form,
  tipoProduto,
}: FilteredEstrategiaSelectorProps) => {
  const { estrategias, getCompanyEstrategias, hasFetchError, loading } =
    useFilteredEstrategiaSelector(companyId, tipoProduto, form);

  return (
    <div className="d-flex align-items-center flex-fill">
      <ORFieldSelect
        dataAction="selecionar_estrategia:select:selecionar_estrategia"
        dataLabel="selecionar_estrategia"
        id="estrategiaId"
        name="estrategiaId"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        label="Estratégia"
        key={estrategias?.length}
        required
      >
        {estrategias?.map((estrategia) => (
          <OOption key={estrategia.id} value={estrategia.id}>
            {estrategia.nome}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="selecionar_estrategia:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getCompanyEstrategias()}
        />
      )}
    </div>
  );
};
