import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { hubEnergyEndpoints } from "services/hubenergy/hubenergy.endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../components/mapped-status-badge";
import { approvedAmountTypeMap } from "../../../components/trade/trade-fields.utils";
import {
  configureHeaderFilterODataGroupBy,
  disableHeaderFilterPagination,
  odataDateFilterWorkaround,
  tradeProductTypeMap,
} from "../../../utils";
import { energyTradeStatusMap } from "../../../utils/status-maps/trade.status";
import {
  exportOnlyColumnNames,
  exportOnlyColumns,
} from "./energy-trades.utils";
import { GridActions, ProposalCell } from "./_compose";

const buildDataSource = (
  paramsCallback?: (params: Record<string, string | undefined>) => void,
) =>
  dataSourceODataStoreGenerator<HubEnergy.TradeODataResponse>(
    hubEnergyEndpoints.odata.trades,
    {
      odataStoreOptions: {
        beforeSend: ({ params }) => {
          odataDateFilterWorkaround(params);
          paramsCallback?.(params);
        },
      },
    },
  );

export const dataSource = buildDataSource();

export const energyTradesGrid: ODataGridGeneratorConfig<HubEnergy.TradeODataResponse> =
  {
    datagrid: {
      noDataText: "Nenhuma proposta encontrada.",
      headerFilter: { visible: true },
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("energyTradesGrid"),
      exportGrid: {
        onExporting: ({ component }) => {
          component?.beginUpdate();
          exportOnlyColumnNames.forEach((columnName) => {
            component?.columnOption(columnName, "visible", true);
          });
        },
        onExported: ({ component }) => {
          exportOnlyColumnNames.forEach((columnName) => {
            component?.columnOption(columnName, "visible", false);
          });
          component?.endUpdate();
        },
      },
      columnAutoWidth: false,
      syncLookupFilterValues: false,
    },
    columns: [
      {
        dataField: "id",
        dataType: "number",
        role: roles.energia.product.tradesDetails.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.energia.product.trades.byId.details.path(
              {
                tradeId: data.id,
              },
            )}
          />
        ),
        caption: "",
        allowFiltering: true,
        allowHeaderFiltering: false,
        allowSorting: true,
        width: "auto",
        alignment: "left",
        allowExporting: false,
      },
      ...exportOnlyColumns,
      {
        dataField: "customerOfficialName",
        dataType: "string",
        caption: "Cliente",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          "customerOfficialName",
          "customerTaxId",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.customerOfficialName}
            taxId={data.customerTaxId}
          />
        ),
        minWidth: 180,
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
            ["customerOfficialName", "customerTaxId"],
            (adjustParams) => buildDataSource(adjustParams),
            (customerOfficialName, customerTaxId) =>
              `${customerOfficialName} - ${fastCpfCnpjMask(customerTaxId)}`,
          ),
        allowSorting: true,
        allowExporting: false,
      },
      {
        dataType: "string",
        caption: "Proposta",
        cellRender: ({ data }) => (
          <ProposalCell
            approvedAmount={data.approvedAmount}
            approvedAmountType={data.approvedAmountType}
          />
        ),
        calculateCellValue: (data) =>
          `${data.approvedAmountType} - ${data.approvedAmount}`,
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
            ["approvedAmountType"] as const,
            (adjustParams) => buildDataSource(adjustParams),
            (approvedAmountType) =>
              getValueFromMap(approvedAmountTypeMap, approvedAmountType) ??
              approvedAmountType,
          ),
        allowFiltering: true,
        allowSorting: true,
        allowExporting: false,
        width: "auto",
      },
      {
        dataField: "productType",
        caption: "Produto",
        dataType: "string",
        alignment: "center",
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
            ["productType"] as const,
            (adjustParams) => buildDataSource(adjustParams),
            (productType) =>
              getValueFromMap(tradeProductTypeMap, productType) ?? productType,
          ),
        lookup: {
          dataSource: Object.entries(tradeProductTypeMap).map(
            ([key, value]: [string, string]) => ({
              text: value,
              value: key,
            }),
          ),
          displayExpr: "text",
          valueExpr: "value",
        },
        width: "auto",
      },
      {
        dataField: "status",
        caption: "Status",
        dataType: "string",
        alignment: "center",
        cellRender: ({ data }) => (
          <MappedStatusBadge map={energyTradeStatusMap} status={data.status} />
        ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
            ["status"] as const,
            (adjustParams) => buildDataSource(adjustParams),
            (status) =>
              getValueFromMap(energyTradeStatusMap, status)?.text ?? status,
          ),
        lookup: {
          dataSource: Object.entries(energyTradeStatusMap).map((item) => ({
            text: item[1].text,
            value: item[0],
          })),
          displayExpr: "text",
          valueExpr: "value",
        },
        filterType: "exclude",
        defaultFilterValues: ["EXPIRADO", "REJEITADO"],
        width: "auto",
      },
      {
        dataField: "proposalDate",
        dataType: "date",
        format: "shortDateShortTime",
        caption: "Data da proposta",
        headerFilter: { dataSource: disableHeaderFilterPagination },
        width: "auto",
      },
      {
        dataField: "supplyStartDate",
        dataType: "date",
        caption: "Início do contrato",
        headerFilter: { dataSource: disableHeaderFilterPagination },
        width: "auto",
      },
      {
        dataField: "supplyEndDate",
        dataType: "date",
        caption: "Fim do contrato",
        headerFilter: { dataSource: disableHeaderFilterPagination },
        width: "auto",
      },
      {
        dataField: "createdDate",
        dataType: "date",
        sortOrder: "desc",
        visible: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => <GridActions trade={data} />,
        role: roles.energia.product.tradesGridActions.role,
        allowExporting: false,
        width: "auto",
      },
    ],
  };
