import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useOverdraftLimit = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [limit, setLimit] = useState<BankingHub.FullLimit>();
  const [analysisStatus, setAnalysisStatus] = useState<string>();

  const getLimit = useCallback(async () => {
    try {
      setLoading(true);
      setHasError(false);

      const { data } = await service.bankinghub.getOverdraftLimits();

      setLimit(data);
      setAnalysisStatus(data ? "Approved" : "Created");
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getLimit();
  }, [getLimit]);

  return {
    loading,
    hasError,
    limit,
    analysisStatus,
    getLimit,
  };
};
