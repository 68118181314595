import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { buildGrid } from "./guarantors.grid";

export const Guarantors = () => {
  const {
    customer: { identification },
  } = useCustomer();
  const gridRef = useGridRef();

  const dataSource =
    dataSourceCustomStoreGenerator<HubOnboardingRelationships.Guarantor.DetailedGuarantor>(
      () =>
        service.onboardingRelationships
          .adminGuarantorsGetAll({
            companyTaxId: identification,
          })
          .then(({ data }) => data.guarantorsResponse ?? [])
          .catch(() => {
            const errorMessage = "Erro ao buscar os avalistas";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
    );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Avalistas" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.cadastro.customer.guarantors.monitoring.path
          }
        >
          Monitoramento
        </RouterButton>
      }
      showClearFiltersButton
      showRefreshButton
      showExportButton
      gridRef={gridRef}
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={buildGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
