import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { exchangeLimitDetailsGenerator } from "../../../../../components/limits/limit.details";
import { CambioLimitesByIdRouteParams } from "../../../../../routes/cambio.route-params";

export const ExchangeProductLimitDetailsPage = () => {
  const { id } = useParams<CambioLimitesByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: limitDetails,
    callService: getLimitDetails,
    hasError: hasErrorLimitDetails,
    loading: loadingLimitDetails,
  } = useServiceCall(service.hubFx.getLimitDetails);

  useEffect(() => {
    getLimitDetails(id);
  }, [getLimitDetails, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do Limite" />}>
      <DetailsCard
        loading={loadingLimitDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes do limite."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getLimitDetails(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorLimitDetails}
        value={limitDetails}
        fields={exchangeLimitDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
