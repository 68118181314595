import { OLoader, OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { SelectSearchMultiple } from "components/form";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../../routes/ferramentas.route-params";

interface DeskAccountConfigProductsServicesFormValues {
  serviceIds: number[];
  productIds: number[];
}

export const DeskAccountConfigProductsServices = () => {
  const { uuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DeskAccountConfigProductsServicesFormValues>();
  const { clearErrors, setError, handleSubmit, reset } = form;

  const {
    callService: getBtgProductsOptions,
    loading: loadingProducts,
    value: products,
    hasError: hasErrorProducts,
  } = useServiceCall(service.onboarding.getBtgProducts);
  const {
    callService: getBtgServicesOptions,
    loading: loadingServices,
    value: services,
    hasError: hasErrorServices,
  } = useServiceCall(service.onboarding.getBtgServices);
  const {
    callService: getDeskOnboardingConfigAccountConfigProductsServices,
    loading: loadingCurrentAccountConfigProductsServices,
    value: currentAccountConfigProductsServices,
    hasError: hasErrorCurrentAccountConfigProductsServices,
  } = useServiceCall(
    service.onboarding.getDeskOnboardingAccountConfigProductsAndServices,
  );
  const {
    callService: upsertAccountConfigProductsServicesOnDeskOnboardingConfig,
    loading: submitLoading,
  } = useServiceCall(
    service.onboarding.upsertDeskOnboardingAccountConfigProductsAndServices,
  );

  const getBtgProducts = useCallback(async () => {
    const { success } = await getBtgProductsOptions();
    if (success) clearErrors();
    else
      setError("productIds", {
        message:
          "Erro ao buscar os produtos. Clique no botão ao lado para tentar novamente.",
      });
  }, [getBtgProductsOptions, clearErrors, setError]);
  const getBtgServices = useCallback(async () => {
    const { success } = await getBtgServicesOptions();
    if (success) clearErrors();
    else
      setError("serviceIds", {
        message:
          "Erro ao buscar os serviços. Clique no botão ao lado para tentar novamente.",
      });
  }, [getBtgServicesOptions, clearErrors, setError]);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } =
          await upsertAccountConfigProductsServicesOnDeskOnboardingConfig({
            ...values,
            deskOnboardingAccountConfigUuid: uuid,
          });

        if (!success)
          return OToastManager.danger("Erro ao atualizar produtos e serviços");

        OToastManager.success("Produtos e serviços atualizados com sucesso");
      }),
    [
      handleSubmit,
      upsertAccountConfigProductsServicesOnDeskOnboardingConfig,
      uuid,
    ],
  );

  useEffect(() => {
    getBtgProducts();
    getBtgServices();
    getDeskOnboardingConfigAccountConfigProductsServices(uuid);
  }, [
    getBtgProducts,
    getBtgServices,
    getDeskOnboardingConfigAccountConfigProductsServices,
    uuid,
  ]);

  useEffect(() => {
    currentAccountConfigProductsServices &&
      reset({
        productIds: currentAccountConfigProductsServices.btgProducts.map(
          ({ id }) => id,
        ),
        serviceIds: currentAccountConfigProductsServices.btgServices.map(
          ({ id }) => id,
        ),
      });
  }, [currentAccountConfigProductsServices, reset]);

  const optionsProducts = useMemo(
    () =>
      products?.map((product) => ({
        label: `${product.id} - ${product.description}`,
        value: product.id,
      })) ?? [],
    [products],
  );
  const optionsServicess = useMemo(
    () =>
      services?.map((serviceOption) => ({
        label: `${serviceOption.id} - ${serviceOption.description}`,
        value: serviceOption.id,
      })) ?? [],
    [services],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Produtos e Serviços na conta"
          description={`Agência: ${currentAccountConfigProductsServices?.deskOnboardingAccountInfoAgency} | Conta: ${currentAccountConfigProductsServices?.deskOnboardingAccountInfoAccountType} | Configuração: ${configIdentifier}`}
        />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Enviar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingCurrentAccountConfigProductsServices}
        hasError={hasErrorCurrentAccountConfigProductsServices}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar produtos e serviços"
            callback={() =>
              getDeskOnboardingConfigAccountConfigProductsServices(uuid)
            }
          />
        }
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <div className="d-flex align-items-center flex-fill">
                <SelectSearchMultiple
                  id="productIds"
                  name="productIds"
                  options={optionsProducts}
                  label="Produtos"
                  placeholder="Buscar"
                />
                {loadingProducts && <OLoader size="sm" />}
                {hasErrorProducts && !loadingProducts && (
                  <RefreshGridButton
                    dataAction="buscar_produtos_servicos:botao:tentar_novamente"
                    dataLabel="tentar_novamente"
                    onClick={() => getBtgProducts()}
                  />
                )}
              </div>
              <div className="d-flex align-items-center flex-fill">
                <SelectSearchMultiple
                  id="serviceIds"
                  name="serviceIds"
                  options={optionsServicess}
                  label="Serviços"
                  placeholder="Buscar"
                />
                {loadingServices && <OLoader size="sm" />}
                {hasErrorServices && !loadingServices && (
                  <RefreshGridButton
                    dataAction="buscar_produtos_servicos:botao:tentar_novamente"
                    dataLabel="tentar_novamente"
                    onClick={() => getBtgServices()}
                  />
                )}
              </div>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
