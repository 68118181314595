import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { ParceirosProdutoOperatorDetailsRouteParams } from "../../../../../routes/parceiros.route-params";
import {
  OperatorDetailsFormFields,
  operatorDetailsValidationSchema,
} from "./operator-details.form";

export const useOperatorDetailByIdentification = () => {
  const [loadingOperator, setLoadingOperator] = useState(false);

  const { operatorProfileIdentifier } =
    useParams<ParceirosProdutoOperatorDetailsRouteParams>();

  if (!operatorProfileIdentifier) throw new Error("No Id");

  const form = useForm<OperatorDetailsFormFields>({
    resolver: yupResolver(operatorDetailsValidationSchema),
  });
  const { setValue, watch } = form;
  const watchCge = watch("cge");
  const watchEmail = watch("email");

  const fetchOperator = useCallback(async () => {
    setLoadingOperator(true);
    try {
      const { data } =
        await service.hubPartners.portal.getOperatorProfileDetails(
          operatorProfileIdentifier,
        );

      const operator = data.data;
      setValue("name", operator.name);
      setValue("identification", operator.identification);
      setValue("email", operator.email);
      setValue("cge", operator.cge);
    } catch {
      OToastManager.danger("Falha ao obter detalhes do operador");
    } finally {
      setLoadingOperator(false);
    }
  }, [operatorProfileIdentifier, setValue]);

  useEffect(() => {
    fetchOperator();
  }, [fetchOperator]);

  return {
    form,
    loadingOperator,
    watchCge,
    watchEmail,
  };
};
