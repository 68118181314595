import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useCurrentAccountForm } from "./current-account-form.hook";
import { CurrentAccountFormProps } from "./current-account-form.types";

export const CurrentAccountForm = ({
  form,
  showEmailField,
  setLoading,
}: CurrentAccountFormProps) => {
  const { watchDeskCode, desks, officers, accounts, semaphoreLoading } =
    useCurrentAccountForm({
      form,
      setLoading,
    });

  const options = useMemo(
    () =>
      officers?.map((officer) => ({
        label: officer.name,
        value: officer.cge,
      })) ?? [],
    [officers],
  );

  return (
    <FormProvider {...form}>
      {semaphoreLoading && <OLoader absolute />}
      <form>
        <ORow>
          <OCol xs={12} sm={6}>
            <ORFieldInput
              tag="cnpj"
              id="taxId"
              name="taxId"
              dataAction="formulario-abertura-conta:input:cnpj"
              dataLabel="cnpj"
              label="CNPJ"
              placeholder="Informar"
            />
          </OCol>
        </ORow>

        <ORow>
          <OCol xs={12} sm={6}>
            <ORFieldInput
              tag="text"
              id="officialName"
              name="officialName"
              dataAction="formulario-abertura-conta:input:razao-social"
              dataLabel="razao-social"
              label="Razão Social"
              placeholder="Informar"
              disabled
            />
          </OCol>
          <OCol xs={12} sm={6}>
            <ORFieldInput
              tag="text"
              id="status"
              name="status"
              dataLabel="situacao-receita"
              dataAction="formulario-abertura-conta:input:situacao-receita"
              label="Situação Receita"
              disabled
            />
          </OCol>

          {showEmailField && (
            <OCol xs={12} sm={6}>
              <ORFieldInput
                tag="text"
                id="email"
                name="email"
                dataAction="formulario-abertura-conta:input:e-mail"
                dataLabel="e-mail"
                label="E-mail"
                placeholder="Informar"
              />
            </OCol>
          )}

          <OCol xs={12} sm={6}>
            <ORFieldSelect
              id="desk"
              name="desk"
              dataAction="formulario-abertura-conta:select:mesas"
              dataLabel="mesas"
              placeholder="Mesa"
              label="Mesa"
              required
              key={desks.map((item) => item.deskCode).join("-")}
            >
              {desks.map((desk) => (
                <OOption key={desk.deskCode} value={desk.deskCode}>
                  {desk.deskDescription}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>

          <OCol xs={12} sm={6}>
            {watchDeskCode && (
              <SelectSearchField
                id="officer"
                name="officer"
                options={options}
                placeholder="Officer"
                label="Officer"
              />
            )}
          </OCol>

          <OCol xs={12} sm={6}>
            {watchDeskCode && (
              <ORFieldSelect
                id="account"
                name="account"
                dataAction="formulario-abertura-conta:select:conta"
                dataLabel="conta"
                placeholder="Conta"
                label="Conta"
                required
                key={accounts
                  .map((account) => watchDeskCode + account.type)
                  .join("-")}
              >
                {accounts.map((account) => (
                  <OOption
                    key={watchDeskCode + account.type}
                    value={`${account.agency}-${account.type}-${account.optinTemplate}`}
                  >
                    {account.description}
                  </OOption>
                ))}
              </ORFieldSelect>
            )}
          </OCol>
        </ORow>
      </form>
    </FormProvider>
  );
};
