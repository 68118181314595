import {
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";

export const removeSignatureGroupModalId = "remove-signature-group-modal";

interface RemoveSignatureGroupConfirmationModalProps {
  callback: () => void;
  loading: boolean;
}

export const RemoveSignatureGroupConfirmationModal = ({
  callback,
  loading,
}: RemoveSignatureGroupConfirmationModalProps) => {
  return (
    <OModal
      id={removeSignatureGroupModalId}
      position="center"
      size="sm"
      className="position-absolute"
    >
      <OModalHeader>
        <OTypography size="lg">
          Tem certeza que deseja remover o grupo de assinaturas?
        </OTypography>
      </OModalHeader>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="modal_confirmacao:botao:cancelar"
            dataLabel="cancelar"
            onClick={() => modalManager.hide(removeSignatureGroupModalId)}
            type="dark"
            outline
          >
            Cancelar
          </OButton>
          <LoadingButton
            dataAction="modal_confirmacao:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            onClick={callback}
            type="danger"
          >
            Remover
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
