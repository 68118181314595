import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ReceivablesCustomerByReceivableIdRouteParams } from "../../../../../routes/recebiveis.route-params";

export const useGroupDetails = () => {
  const { receivableId } =
    useParams<ReceivablesCustomerByReceivableIdRouteParams>();
  const [loading, setLoading] = useState(false);

  const { callService: getGroup, value: group } = useServiceCall(
    service.quickfin.getReceivableGroupById,
  );

  const sendConclusion = async () => {
    try {
      setLoading(true);

      if (!group?.groupId) return;

      await service.quickfin.groupForceCompletion({ groupId: group.groupId });

      OToastManager.success("Dados atualizados com sucesso");
    } catch (err) {
      OToastManager.danger("Erro para atualizar os dados");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!receivableId) return;

    getGroup(receivableId);
  }, [getGroup, receivableId]);

  return {
    group,
    loading,
    sendConclusion,
  };
};
