const MonthsAbbreviationsList = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export const DayMonthMask = (date: string | null, division: string) => {
  if (typeof date !== "string") return "";
  const [, month, day] = date.split("-");
  return `${day}${division}${
    MonthsAbbreviationsList[Number.parseInt(month) - 1]
  }`;
};
