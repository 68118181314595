import { useMenuTabs } from "layout/tab-menu";
import { useMemo } from "react";
import { logger } from "utils/logger";
import { useRoles } from "../roles";

export const useProductsHomeRoute = () => {
  const { hasRole } = useRoles();
  const menuTabs = useMenuTabs();

  const productsRoute = useMemo(() => {
    const path = menuTabs.product.find((product) =>
      hasRole(product.role),
    )?.path;

    if (!path) logger.fatal("userHasNoRoles");

    return path;
  }, [hasRole, menuTabs.product]);

  return productsRoute;
};
