interface SettlementOdataBankAccount {
  Agency: string;
  AccountNumber: string;
  AccountDigit: string;
}

export enum SettlementPaymentStatus {
  CREATED = "CREATED",
  CANCELLED = "CANCELLED",
  WAITING_TRADE_INTEGRATION = "WAITING_TRADE_INTEGRATION",
  WAITING_RELEASE_INTEGRATION = "WAITING_RELEASE_INTEGRATION",
  WAITING_RELEASE_CALLBACK = "WAITING_RELEASE_CALLBACK",
  WAITING_PAYMENT_MESSAGE = "WAITING_PAYMENT_MESSAGE",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export interface SettlementOData {
  SettlementId: string;
  PayerDocument: string;
  PayerName: string;
  PaymentBankInfo: SettlementOdataBankAccount;
  PaymentStatus: SettlementPaymentStatus;
  PaymentMethod: string;
  PaymentDate: string;
  PaymentError: string;
}

export interface GetOdataSettlementsOdataResponse {
  "@odata.context": string;
  "@odata.count": number;
  value: SettlementOData[];
}
