import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GatekeeperProfileForm } from "../_compose";
import { useGatekeeperProfilesAdd } from "./add.hook";

export const GatekeeperProfilesAdd = () => {
  const { submit, form, loading } = useGatekeeperProfilesAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Perfil Gatekeeper"
          description="Adicione um perfil ao Gatekeeper"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_perfil:botao:criar_perfil"
          dataLabel="criar_perfil"
          onClick={submit}
        >
          Criar Perfil
        </LoadingButton>
      }
    >
      <CardTemplate>
        <GatekeeperProfileForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
