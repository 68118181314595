import { validators } from "@maestro/utils";

const UNMASKED_PHONE_LENGTH = 13;
const MASKED_PHONE_LENGTH = 19;

export const validatePhone = (
  value: string | undefined | null,
): value is string => {
  if (!value) return false;
  return (
    [UNMASKED_PHONE_LENGTH, MASKED_PHONE_LENGTH].includes(value.length) &&
    validators.phone(value)
  );
};
