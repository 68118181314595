import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useStepsBuilder } from "components/steps-builder";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { PostAdminReceivablesSearchResponse } from "services/quickfin/models";
import { logger } from "utils/logger";
import { capitalizeObjectKeys } from "../../../../../utils";
import { useIntubateOperations } from "../../intubate-operations.context";
import { ReceivableToSearch } from "./file-upload.types";
import {
  applyOverrides,
  getReceivablesNotFound,
  parseIntubateSpreadsheet,
} from "./file-upload.utils";

export const useFileUpload = () => {
  const [hasParseError, setHasParseError] = useState(false);

  const {
    setCardReceivables,
    setFileSearchReceivables,
    setReceivablesNotFound,
  } = useIntubateOperations();

  const { nextStep } = useStepsBuilder();

  const {
    callService: _searchReceivables,
    hasError: searchReceivablesHasError,
    loading: searchReceivablesLoading,
  } = useServiceCall(service.quickfin.searchReceivables);

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset,
  } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  const handleFoundReceivables = useCallback(
    (
      foundReceivables: PostAdminReceivablesSearchResponse,
      localReceivables: ReceivableToSearch[],
    ) => {
      const receivablesWithOverrideMaturityData = applyOverrides(
        localReceivables,
        foundReceivables,
      );
      const pascalCaseReceivables =
        receivablesWithOverrideMaturityData.map(capitalizeObjectKeys);

      setCardReceivables(pascalCaseReceivables);
      setFileSearchReceivables(pascalCaseReceivables);
      setReceivablesNotFound(
        getReceivablesNotFound(localReceivables, foundReceivables),
      );
      nextStep();
    },
    [
      nextStep,
      setCardReceivables,
      setFileSearchReceivables,
      setReceivablesNotFound,
    ],
  );

  const searchReceivables = useCallback(
    async (readerResult: string | ArrayBuffer) => {
      try {
        const localReceivables = parseIntubateSpreadsheet(readerResult);

        if (localReceivables.length > 0) {
          const { success, response } = await _searchReceivables(
            localReceivables,
          );

          if (success) {
            handleFoundReceivables(response.data, localReceivables);
          } else {
            OToastManager.danger("Erro ao buscar os recebíveis");
          }
        }
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    },
    [_searchReceivables, handleFoundReceivables],
  );

  useEffect(() => {
    if (result && !readerError) {
      searchReceivables(result);
    }
  }, [readerError, result, searchReceivables]);

  return {
    form,
    handleAddFile,
    loading: readerLoading || searchReceivablesLoading,
    hasError: readerError || hasParseError || searchReceivablesHasError,
    reset,
    setHasParseError,
  };
};
