import { OTooltip } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { StyledValue } from "../roadwalker-master-detail.style";
import { AssignmentTradeCallbackMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerAssignmentTradeCallbackDetails = (
  data: AssignmentTradeCallbackMasterDetail,
) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    assignmenttradecallback: {
      items: [
        {
          label: "AssignmentId",
          value: <StyledValue>{data.AssignmentId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "AssignmentExternalId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.AssignmentExternalId}</span>
                {data.AssignmentExternalId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "MessageId",
          value: (
            <StyledValue>
              <OTooltip floating position="top">
                <span slot="tooltip-content">{data.MessageId}</span>
                {data.MessageId}
              </OTooltip>
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "BackOfficeStatus",
          value: <StyledValue>{data.BackOfficeStatus}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Message",
          value: <StyledValue>{data.Message}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Notifications",
          value: <StyledValue>{data.Notifications?.join("; ")}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "OperationType",
          value: <StyledValue>{data.OperationType}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
