import {
  OButton,
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OCol,
  OIcon,
  ORow,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";

export const AnticipationSuccessPage = () => {
  const { contractNumber } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardHeader divider>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-3">
              <OIcon
                size="xxl"
                category="orq"
                icon="orq-status-success"
                type="success"
              />
              <OTypography size="lg">
                Antecipação de parcelas solicitada com sucesso
              </OTypography>
            </div>
            <OTypography type="dark-80">
              {`Solicitação no valor total de 
                ${masks.currency(
                  searchParams.get("installmentsAmount") ?? 0,
                  "R$",
                  ".",
                )} realizada com sucesso em ${dayjs().format("DD/MM/YYYY")}, às 
                ${dayjs().format("HH:mm:ss")}.`}
            </OTypography>
          </div>
        </OCardHeader>
        <OCardBody>
          <ORow>
            <OCol sm={3}>
              <div className="d-flex flex-column">
                <OTypography type="dark">Número do contrato</OTypography>
                <OTypography type="dark-80">{contractNumber}</OTypography>
              </div>
            </OCol>
            <OCol sm={3}>
              <div className="d-flex flex-column">
                <OTypography type="dark">
                  Data de expiração do boleto
                </OTypography>
                <OTypography type="dark-80">
                  {dayjs(searchParams.get("expirationDate")).format(
                    "DD/MM/YYYY",
                  )}
                </OTypography>
              </div>
            </OCol>
            <OCol sm={3}>
              <div className="d-flex flex-column">
                <OTypography type="dark">Desconto</OTypography>
                <OTypography type="dark-80">
                  {masks.currency(searchParams.get("discount") ?? 0, "R$", ".")}
                </OTypography>
              </div>
            </OCol>
            <OCol sm={3}>
              <div className="d-flex flex-column">
                <OTypography type="dark">Parcelas totais</OTypography>
                <OTypography type="dark-80">
                  {searchParams.get("installmentsCount") ?? 0}
                </OTypography>
              </div>
            </OCol>
          </ORow>
        </OCardBody>
        <OCardFooter divider>
          <div className="d-flex flex-column gap-2">
            <OTypography type="dark" size="lg">
              Linha digitável
            </OTypography>
            <div className="d-flex align-items-center gap-1">
              <OTypography type="dark-80">
                {searchParams.get("barcode")
                  ? masks.generic(searchParams.get("barcode"), {
                      mask: "00000.00000 00000.000000 00000.000000 0 00000000000000",
                    })
                  : "Linha digitável não identificada"}
              </OTypography>
              <OTooltip slot="tooltip" position="top">
                <div slot="tooltip-content">Copiar</div>
                <OButton
                  type="light"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      searchParams.get("barcode") as string,
                    )
                  }
                >
                  <OIcon category="orq" icon="orq-edit-copy" type="dark" />
                </OButton>
              </OTooltip>
            </div>
            <div className="d-flex align-items-center gap-2">
              <OIcon category="orq" icon="orq-info-on" type="info" />
              <OTypography type="dark-80">
                O arquivo será enviado por e-mail assim que for gerado.
              </OTypography>
            </div>
          </div>
        </OCardFooter>
      </OCard>
    </div>
  );
};
