import { CreditorIdsSelect } from "./creditor-ids-select.component";
import { MarketplaceIdsSelect } from "./marketplace-ids-select.component";
import { PortfolioIdsSelect } from "./portfolio-ids-select.component";

export const EntitiesSelects = () => {
  return (
    <>
      <MarketplaceIdsSelect />
      <PortfolioIdsSelect />
      <CreditorIdsSelect />
    </>
  );
};
