import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager } from "@maestro/core";
import {
  OButton,
  OCheckbox,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldInputProgress,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { OTruncateTypography } from "components/o-truncate-typography";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  AddressType,
  MapperAddressType,
} from "services/bankinghub/models/types/cards";
import debounce from "lodash/debounce";
import { logger } from "utils/logger";
import { manageDeliveryAddressFormModalFields } from "./manage-delivery-address-form-modal.form";
import { useManageDeliveryAddressFormModal } from "./manage-delivery-address-form-modal.hook";
import { manageDeliveryAddressFormValidationSchema } from "./manage-delivery-address-form-modal.schema";
import {
  ManageDeliveryAddressForm,
  ManageDeliveryAddressFormModalId,
  STATES,
  UpdateDeliveryAddressFormModalInfo,
} from "./manage-delivery-address-form-modal.types";

interface ManageDeliveryAddressFormModalProps {
  ownerId?: string;
  address?: BankingHubCards.Address;
  handleUpdateAddressId: (addressId?: string) => void;
  resetAddressId: () => void;
}

export const ManageDeliveryAddressFormModal = ({
  ownerId,
  address,
  handleUpdateAddressId,
  resetAddressId,
}: ManageDeliveryAddressFormModalProps) => {
  const [editing, setEditing] = useState<boolean | undefined>();
  const form = useForm<ManageDeliveryAddressForm>({
    resolver: yupResolver(manageDeliveryAddressFormValidationSchema),
    defaultValues: {
      numberNotInformed: "no",
      complementNotInformed: "no",
    },
  });

  const { handleSubmit, setValue, watch } = form;

  const { loadingAddress, loadAddress, onFormSubmit, submitting } =
    useManageDeliveryAddressFormModal({
      form,
      address,
      ownerId,
      handleUpdateAddressId,
      resetAddressId,
    });

  const zipCodeWatcher = watch("zipCode");

  const numberNotInformedWatcher = watch("numberNotInformed");

  const complementNotInformedWatcher = watch("complementNotInformed");

  const stateIdWatcher = watch("stateId");

  const typeWatcher = watch("type");

  useEffect(() => {
    const openCleanup = modalManager.on(
      ManageDeliveryAddressFormModalId,
      "modalOpen",
      () => {
        form.reset();
        setEditing(!!address);
      },
    );

    const closeCleanup = modalManager.on(
      ManageDeliveryAddressFormModalId,
      "modalClose",
      () => {
        form.reset();
        setEditing(undefined);
      },
    );

    return () => {
      openCleanup();
      closeCleanup();
    };
  }, [form, address]);

  const handleAddressToEdit = useMemo(
    () =>
      debounce(async (data: BankingHubCards.Address) => {
        setValue("zipCode", data.zipCode);
        setValue("street", data.street);
        setValue("number", data.number);
        setValue("complement", data.complement);
        setValue("neighborhood", data.neighborhood);
        setValue("city", data.city);
        setValue("stateId", data.state);
        setValue("type", data.type);

        setValue("numberNotInformed", !data.number ? "yes" : "no");
        setValue("complementNotInformed", !data.complement ? "yes" : "no");
      }, 300),
    [setValue],
  );

  useEffect(() => {
    handleAddressToEdit.cancel();
    if (address && editing) handleAddressToEdit(address);
  }, [address, editing, handleAddressToEdit]);

  const [title, subtitle] = useMemo((): [string, string] => {
    const hasToAddANewAddress = !editing;
    return [
      hasToAddANewAddress
        ? UpdateDeliveryAddressFormModalInfo.createTitle
        : UpdateDeliveryAddressFormModalInfo.editTitle,
      UpdateDeliveryAddressFormModalInfo.subtitle,
    ];
  }, [editing]);

  const checkboxOnInput = (
    field: "numberNotInformed" | "complementNotInformed",
    event: FormEvent<HTMLOCheckboxElement>,
  ) => {
    const target = event.target as HTMLOCheckboxElement;

    const value = target.checked ? "yes" : "no";

    setValue(field, value);

    if (!target.checked) {
      setValue(field === "numberNotInformed" ? "number" : "complement", "");
    }
  };

  const onFormError = useCallback((errors?: Record<string, unknown>) => {
    if (!errors) return;
    logger.warn(errors);
  }, []);

  const submit = useMemo(
    () => handleSubmit(onFormSubmit, onFormError),
    [handleSubmit, onFormSubmit, onFormError],
  );

  return (
    <OModal position="center" id={ManageDeliveryAddressFormModalId}>
      <FormProvider {...form}>
        <form autoComplete="off" id="card-hiring-manage-delivery-address">
          <OModalHeader closeButton>
            <div className="d-flex flex-column gap-1">
              <OTypography key={title} type="primary" size="xl" weight="500">
                {title}
              </OTypography>
              <OTypography key={subtitle} type="primary-80">
                {subtitle}
              </OTypography>
            </div>
          </OModalHeader>
          <OModalBody>
            {submitting && <OLoader absolute backdrop />}
            <div className="d-flex flex-column gap-4">
              {loadingAddress && <OLoader absolute backdrop />}
              <div className="row">
                <div
                  className="col-6"
                  onBlur={() => {
                    loadAddress.cancel();
                    loadAddress(zipCodeWatcher);
                  }}
                >
                  <ORFieldInput
                    {...manageDeliveryAddressFormModalFields.zipCode}
                  />
                </div>
                <div className="col-6">
                  <ORFieldInput
                    {...manageDeliveryAddressFormModalFields.street}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="d-flex flex-row gap-1 align-items-center">
                    <ORFieldInput
                      {...manageDeliveryAddressFormModalFields.number}
                      key={numberNotInformedWatcher}
                      disabled={numberNotInformedWatcher === "yes"}
                    />
                    <div className="d-flex flex-row gap-1 align-items-center">
                      <OCheckbox
                        key={numberNotInformedWatcher}
                        className="col-auto"
                        name="numberNotInformed"
                        id="numberNotInformed"
                        onInput={(e) => {
                          checkboxOnInput("numberNotInformed", e);
                        }}
                        checked={numberNotInformedWatcher === "yes"}
                        value="numberNotInformed"
                        size="sm"
                        title="Complemento não se aplica"
                      />
                      <OTruncateTypography size="sm">
                        Não se aplica
                      </OTruncateTypography>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-row gap-1 align-items-center">
                    <ORFieldInputProgress
                      {...manageDeliveryAddressFormModalFields.complement}
                      key={complementNotInformedWatcher}
                      disabled={complementNotInformedWatcher === "yes"}
                      valueLength={watch("complement")?.length ?? 0}
                    />
                    <div className="d-flex flex-row gap-1 align-items-center">
                      <OCheckbox
                        key={complementNotInformedWatcher}
                        className="col-auto"
                        name="complementNotInformed"
                        id="complementNotInformed"
                        onInput={(e) => {
                          checkboxOnInput("complementNotInformed", e);
                        }}
                        checked={complementNotInformedWatcher === "yes"}
                        value="complementNotInformed"
                        size="sm"
                      />
                      <OTruncateTypography size="sm">
                        Não se aplica
                      </OTruncateTypography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <ORFieldInput
                    {...manageDeliveryAddressFormModalFields.neighborhood}
                  />
                </div>
                <div className="col-6">
                  <ORFieldInput
                    {...manageDeliveryAddressFormModalFields.city}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <ORFieldSelect
                    {...manageDeliveryAddressFormModalFields.stateId}
                    key={stateIdWatcher}
                  >
                    {STATES.map((state) => (
                      <OOption
                        onClick={() => setValue("stateId", state.id)}
                        value={state.id}
                        key={state.id}
                      >
                        {state.title}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </div>
                <div className="col-6">
                  <ORFieldSelect
                    {...manageDeliveryAddressFormModalFields.type}
                    key={typeWatcher}
                  >
                    {Object.values(AddressType).map((type) => (
                      <OOption
                        onClick={() => setValue("type", type)}
                        value={type}
                        key={type}
                      >
                        {MapperAddressType[type]}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </div>
              </div>
            </div>
          </OModalBody>
          <OModalFooter divider>
            <div className="d-flex justify-content-end gap-3">
              <OButton
                type="dark"
                outline
                onClick={() =>
                  modalManager.hide(ManageDeliveryAddressFormModalId)
                }
              >
                Cancelar
              </OButton>
              <OButton onClick={submit}>
                {editing ? "Atualizar" : "Salvar"}
              </OButton>
            </div>
          </OModalFooter>
        </form>
      </FormProvider>
    </OModal>
  );
};
