export const mleRouteParams = {
  adequatorId: "adequatorId",
  billingReportId: "billingReportId",
  consumerUnitId: "consumerUnitId",
  consumerUnitsImportDataId: "consumerUnitsImportDataId",
  customerId: "customerId",
  diligenceEventRuleId: "diligenceEventRuleId",
  distributorId: "distributorId",
  documentTypeId: "documentTypeId",
  pendencyTypeId: "pendencyTypeId",
  propertyTypeId: "propertyTypeId",
  stepConfigId: "stepConfigId",
  stepId: "stepId",
  stepType: "stepType",
  stepTypeId: "stepTypeId",
  tradeId: "tradeId",
} as const;

type MleParams = Record<keyof typeof mleRouteParams, string>;

export type MleByAdequatorIdRouteParams = Pick<MleParams, "adequatorId">;
export type MleByBillingReportIdRouteParams = Pick<
  MleParams,
  "billingReportId"
>;
export type MleByConsumerUnitIdRouteParams = Pick<MleParams, "consumerUnitId">;
export type MleByConsumerUnitsImportDataIdRouteParams = Pick<
  MleParams,
  "consumerUnitsImportDataId"
>;
export type MleByCustomerIdRouteParams = Pick<MleParams, "customerId">;
export type MleByDiligenceEventRuleIdRouteParams = Pick<
  MleParams,
  "diligenceEventRuleId"
>;
export type MleByDistributorIdRouteParams = Pick<MleParams, "distributorId">;
export type MleByDocumentTypeIdRouteParams = Pick<MleParams, "documentTypeId">;
export type MleByPendencyTypeIdRouteParams = Pick<MleParams, "pendencyTypeId">;
export type MleByPropertyTypeIdRouteParams = Pick<MleParams, "propertyTypeId">;
export type MleByStepConfigIdRouteParams = Pick<MleParams, "stepConfigId">;
export type MleByStepIdRouteParams = Pick<MleParams, "stepId">;
export type MleByStepTypeByConsumerUnitIdRouteParams = Pick<
  MleParams,
  "stepType" | "consumerUnitId"
>;
export type MleByStepTypeIdRouteParams = Pick<MleParams, "stepTypeId">;
export type MleByTradeIdByConsumerUnitIdRouteParams = Pick<
  MleParams,
  "tradeId" | "consumerUnitId"
>;
export type MleByTradeIdRouteParams = Pick<MleParams, "tradeId">;
