export const removeCipherFormatter = (value: string) => {
  if (value.includes(",")) {
    value = value.replace(/\D/g, "");
    const dotPosition = value.length - 2;
    const cents = value.slice(dotPosition);
    return `${value.slice(0, dotPosition)}${cents !== "00" ? `.${cents}` : ""}`;
  } else {
    return value.replace(/\D/g, "");
  }
};
