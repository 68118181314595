import {
  OAvatar,
  OCheckbox,
  ODivider,
  OInputSearch,
  OTypography,
} from "@maestro/react";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OMultLabel } from "../../../../../o-mult-label";
import {
  SelectionOption,
  SingleSelectionSectionFilter,
} from "../../../../side-filter-bar.types";
import {
  SelectionContainer,
  SelectionOptionContainer,
  SelectionOptionInfoContainer,
} from "../../side-form-modal.styles";

type SingleSelectionProps = SingleSelectionSectionFilter;

export const SingleSelection = ({ data, formId }: SingleSelectionProps) => {
  const [searchString, setSearchString] = useState("");

  const { watch, setValue } = useFormContext();

  const fieldValueWatched: unknown = watch(formId);

  const filteredData = useMemo((): SelectionOption[] => {
    if (!searchString) return data;

    return data?.filter(({ label: { primary, secondary } }) => {
      return `${primary} ${secondary}`
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  }, [searchString, data]);

  const handleCheckboxOnClick = useCallback(
    (value: unknown) => {
      setValue(formId, value);
    },
    [formId, setValue],
  );

  return (
    <div className="d-flex flex-column gap-3">
      <div className="bg-light">
        <OInputSearch
          id="search"
          name={`side-filter-bar-input-search-${formId}`}
          placeholder="Digite aqui para buscar"
          aspect="outline"
          handleClear={() => setSearchString("")}
          onInput={(e) => {
            const { value } = e.currentTarget;
            setSearchString(value ?? "");
          }}
        />
      </div>
      <SelectionContainer>
        {filteredData.map(
          ({ label: { primary, secondary, imageUrl }, value }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="px-1">
              <OMultLabel
                htmlFor={`checkbox-${value}`}
                className="d-grid w-100"
                size="lg"
              >
                <SelectionOptionContainer>
                  <SelectionOptionInfoContainer
                    hasImage={!!imageUrl}
                    hasSecondaryInfo={!!secondary}
                  >
                    {imageUrl && <OAvatar image={imageUrl} alt={value} />}
                    <div id="info">
                      <OTypography key={primary} type="dark">
                        {primary}
                      </OTypography>
                      {secondary && (
                        <OTypography key={secondary} type="dark-60" size="sm">
                          {secondary}
                        </OTypography>
                      )}
                    </div>
                  </SelectionOptionInfoContainer>
                  <OCheckbox
                    id={`checkbox-${value}`}
                    name={`checkbox-${value}`}
                    value={value}
                    checked={fieldValueWatched === value}
                    size="xs"
                    onClick={() => {
                      handleCheckboxOnClick(value);
                    }}
                  />
                </SelectionOptionContainer>
              </OMultLabel>
              <ODivider size="xxs" className="my-2" />
            </div>
          ),
        )}
      </SelectionContainer>
    </div>
  );
};
