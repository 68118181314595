import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { currencySymbol } from "utils/currency";
import { Collaterals } from "./collaterals.types";

export const solarGrid = {
  datagrid: {
    noDataText: "Nenhuma garantia encontrada.",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      alignment: "left",
    },
    {
      dataField: "amount",
      caption: "Valor",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "manufacturer",
      caption: "Fabricante",
    },
    {
      dataField: "quantity",
      caption: "Quantidade",
    },
    {
      caption: "Preço unitário",
      dataField: "unitPrice",
      format: (value) => masks.currency(value, currencySymbol.BRL),
    },
    {
      caption: "Porcentagem de garantia",
      dataField: "guaranteedPercentage",
      format: (value) => masks.percentage(value),
    },
    {
      dataField: "lockDisbursement",
      caption: "Trava de desembolso",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      caption: "Opcional",
      dataField: "hasOptional",
      cellRender: ({ data }) => (data.hasOptional ? "Sim" : "Não"),
    },
  ],
} satisfies ODataGridGeneratorConfig<Collaterals[number]>;
