import { OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useStatusSelector } from "./status-selector.hook";

export const StatusSelector = () => {
  const { getPossibleStatuses, hasFetchError, loading, options } =
    useStatusSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <ORFieldSelect
        id="status"
        name="status"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={options.length}
        required
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={() => getPossibleStatuses()} />
      )}
    </div>
  );
};
