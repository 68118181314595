import { StringUtils } from "utils/string";

const handleValue = (value: string, maxLength: number): string => {
  const requireRemoveSpecialChars = !value.includes("*");

  const base = requireRemoveSpecialChars
    ? StringUtils.removeSpecialChars(value)
    : value;

  const fillString = requireRemoveSpecialChars ? "0" : "*";

  return base.padStart(maxLength, fillString);
};

/** Função utilizada para formatar a Ag. no formato 00XX */
const formatAccountAgency = (agency?: string | number) => {
  if (!agency) return "";

  return handleValue(agency.toString(), 4);
};

/** Função utilizada para formatar o número da conta no formato XXXXXXXXX-X */
const formatAccountNumber = (number?: string | number) => {
  if (!number) return "";

  const value = handleValue(number.toString(), 9);

  return `${value.slice(0, -1)}-${value.slice(-1)}`;
};

export const AccountUtils = {
  formatAccountAgency,
  formatAccountNumber,
};
