import { OOption, ORFieldSelect } from "@maestro/react";

export const WalletType = () => {
  return (
    <ORFieldSelect id="walletType" name="walletType" label="Tipo de carteira">
      <OOption value="all" key="all">
        Mostrar tudo
      </OOption>
      <OOption value="own" key="own">
        Carteira própria
      </OOption>
      <OOption value="discounted" key="discounted">
        Carteira descontada
      </OOption>
    </ORFieldSelect>
  );
};
