import { env } from "utils/environments";

export const paperclipv2Endpoints = {
  contract: {
    byContractId: {
      fileBinary: (contractId: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${contractId}/file-binary.pdf`,
    },
    byUuid: {
      details: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/v3/Contract/${uuid}/details`,
      fileBinary: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${uuid}/file-binary.pdf`,
      sendNotifications: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${uuid}/send-notifications`,
      signedFileBinary: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${uuid}/signed-file-binary.pdf`,
      status: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${uuid}/status`,
      updateContract: (uuid: string) =>
        `${env.API_PAPERCLIPV2}/Contract/${uuid}/update-contract`,
    },
  },
  contracts: {
    batch: {
      updateSignatories: `${env.API_PAPERCLIPV2}/contracts/batch/update-signatories`,
    },
  },
  odata: {
    contractAdminOData: `${env.API_PAPERCLIPV2}/odata/ContractAdminOData`,
  },
  signatureRule: {
    endpoint: `${env.API_PAPERCLIPV2}/SignatureRule`,
    byRuleId: {
      endpoint: (ruleId: string | number) =>
        `${env.API_PAPERCLIPV2}/SignatureRule/${ruleId}`,
      groupOfSignatures: {
        endpoint: (ruleId: string | number) =>
          `${env.API_PAPERCLIPV2}/SignatureRule/${ruleId}/GroupOfSignatures`,
        byGroupId: (ruleId: string | number, groupId: string | number) =>
          `${env.API_PAPERCLIPV2}/SignatureRule/${ruleId}/GroupOfSignatures/${groupId}`,
      },
    },
  },
};
