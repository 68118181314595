export enum ProductType {
  Cartao = "Cartao",
  CCC = "CCC",
  FIDC = "FIDC",
  FundingProprio = "FundingProprio",
  Offshore = "Offshore",
  RiscoSacado = "RiscoSacado",
  RiscoCedente = "RiscoCedente",
}

export const productTypeMap: Record<ProductType, string> = {
  Cartao: "Cartão",
  RiscoCedente: "Cessão de crédito",
  CCC: "Cessão com coobrigação",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  Offshore: "Offshore",
  RiscoSacado: "Risco Sacado",
};

export const settlementMethodCodes = ["NONE", "INTN", "EXTN", "INVC", "SWFT"];

export const lockInfoTypes = ["NONE", "DRCT", "RFCD", "LOCK", "SCAN", "MSTT"];
