/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Amplify, Auth as AmplifyAuth, Hub } from "aws-amplify";
import { env } from "utils/environments";
import { logger } from "utils/logger";
import { safeSessionStorage } from "utils/storage";
import { Auth } from "../auth";
import { AuthCommand } from "../types/command";

const signInRedirectStorageKey = "corporate-portal:sign-in-redirect";

export class ConfigureAmplifyCommand implements AuthCommand {
  async execute() {
    logger.debug("[auth]ConfigureAmplifyCommand");

    Amplify.configure({
      Auth: {
        userPoolId: env.USER_POOL_ID,
        region: "us-east-1",
        userPoolWebClientId: env.USER_POOL_WEB_CLIENT_ID,
      },
      Analytics: {
        disabled: true,
      },
      oauth: {
        domain: env.AMAZON_COGNITO_DOMAIN,
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin + "/logout",
        responseType: "code",
      },
    });
  }
}

export class StartAuthRedirectListenersCommand implements AuthCommand {
  async execute() {
    logger.debug("[auth]StartAuthRedirectListenersCommand");

    Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signIn") {
        const redirectUrl = safeSessionStorage.getItem(
          signInRedirectStorageKey,
        );
        if (!redirectUrl) return;

        try {
          // Check that url is valid and hasn't been tampered with
          const validateUrl = new URL(redirectUrl);
          if (validateUrl.origin !== window.location.origin) {
            logger.fatal(
              `[auth]StartAuthRedirectListenersCommand. Wrong redirecUrl origin: ${validateUrl.href}. Current: ${window.location.href}`,
            );
            return;
          }

          logger.debug(
            `[auth]StartAuthRedirectListenersCommand. Redirecting after login: ${redirectUrl}`,
          );

          window.history.replaceState(null, "", redirectUrl);
          // for some reason I need to manually dispatch a popstate event
          const popStateEvent = new PopStateEvent("popstate");
          dispatchEvent(popStateEvent);
        } catch (err) {
          logger.error(
            `[auth]StartAuthRedirectListenersCommand. Unable to parse url: ${redirectUrl}. Error message: ${
              (err as Error)?.message ?? ""
            }`,
          );
        } finally {
          safeSessionStorage.removeItem(signInRedirectStorageKey);
        }
      } else if (event === "oAuthSignOut") {
        logger.debug(
          "[auth]StartAuthRedirectListenersCommand oAuthSignOut. Current url: ",
          window.location.href,
        );
        safeSessionStorage.setItem(
          signInRedirectStorageKey,
          window.location.href,
        );
      }
    });
  }
}

export class RefreshCognitoTokenCommand implements AuthCommand {
  async execute(auth: Auth) {
    logger.debug("[auth]RefreshCognitoTokenCommand");

    const cognitoUser: CognitoUser =
      await AmplifyAuth.currentAuthenticatedUser();

    await new Promise((resolve, reject) => {
      try {
        const userSession = cognitoUser.getSignInUserSession();

        if (!userSession) {
          throw new Error("No user session");
        }

        cognitoUser.refreshSession(
          userSession.getRefreshToken(),
          (err, cognitoSession: CognitoUserSession) => {
            if (err) {
              const cleanError = new Error(err.message);
              cleanError.name = err.name;
              cleanError.stack = err.stack;
              reject(cleanError);
            }

            const token = cognitoSession?.getAccessToken()?.getJwtToken();

            auth.value = token;

            resolve(token);
          },
        );
      } catch (err) {
        reject(err);
      }
    });
  }
}

export class FederatedSignInCommand implements AuthCommand {
  async execute() {
    logger.debug("[auth]FederatedSignInCommand");

    // if there's something stored already, it was saved because of oAuthSignOut
    // so we don't overwrite it
    if (!safeSessionStorage.getItem(signInRedirectStorageKey)) {
      logger.debug(
        "[auth]FederatedSignInCommand. Current url: ",
        window.location.href,
      );
      safeSessionStorage.setItem(
        signInRedirectStorageKey,
        window.location.href,
      );
    }

    await AmplifyAuth.federatedSignIn({
      customProvider: env.AMAZON_COGNITO_PROVIDER as string,
    });
  }
}
