import { OToastManager } from "@maestro/core";
import {
  OIcon,
  OUpload,
  OUploadInput,
  OUploadItem,
  OUploadLabel,
} from "@maestro/react";
import { ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { useState } from "react";
import { service } from "services";
import { getFormData } from "utils/file/file.utils";
import type { CollateralDocument } from "./collateral-documents.grid";

export const CollateralDocumentsUpload = ({
  component,
  rowIndex,
  data,
}: ColumnEditCellTemplateData<CollateralDocument>) => {
  const [loading, setLoading] = useState(false);

  const onAddFile = async (file: File) => {
    try {
      setLoading(true);
      OToastManager.info("Enviando arquivo...");
      const formData = await getFormData(file);

      const { data: fileKey } = await service.hubCreditManager.upload(formData);

      component.cellValue(rowIndex, "file.name", file.name);
      component.cellValue(rowIndex, "file.key", fileKey);
      OToastManager.destroy();
    } catch (err) {
      OToastManager.danger(
        "Não foi possível fazer upload do arquivo, tente novamente.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <OUpload
      onAddFile={(event) => {
        if (data?.file?.key || loading) {
          OToastManager.warning("Já tem um arquivo");
          return;
        }
        onAddFile(event.detail);
      }}
    >
      <OUploadInput
        id="file"
        name="file"
        dataAction="emprestimos_documentos_garantia_imovel:file:documento"
        dataLabel="documento"
        accept="application/pdf,application/msword,image/jpg,image/jpeg,image/png"
      />
      <OUploadLabel htmlFor="file" size="md">
        Upload de documento de imóvel à garantia
        <OIcon category="orq" icon="orq-upload" size="lg" />
      </OUploadLabel>
      {data?.file?.key && (
        <div className="d-flex flex-column">
          <OUploadItem
            handleRemove={() => {
              component.cellValue(rowIndex, "file.name", null);
              component.cellValue(rowIndex, "file.key", null);
            }}
          >
            <div style={{ overflow: "hidden" }}>{data.file.name}</div>
          </OUploadItem>
        </div>
      )}
    </OUpload>
  );
};
