import { OBadge } from "@maestro/react";

export function getLeadExportStatusBadge(status: string) {
  if (status === "EXECUTING") return <OBadge size="sm">EM EXECUÇÃO</OBadge>;
  if (status === "ERROR")
    return (
      <OBadge type="danger" size="sm">
        ERRO
      </OBadge>
    );

  return (
    <OBadge type="success" size="sm">
      {status === "SUCCEEDED" ? "SUCESSO" : "CRIADO"}
    </OBadge>
  );
}
