import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { CheckboxField } from "../../../../../../components/checkbox-field";
import { maximumDurationTypeMap } from "../../../../../../utils";
import { stepConfigFormFields } from "./step-config-form.form";
import { StepTypeSelector, useStepTypeSelector } from "./_compose";

export const StepConfigFormComponent = () => {
  const { stepTypes } = useStepTypeSelector();

  return (
    <OCard>
      <OCardHeader>
        <OTypography weight="500">Dados gerais</OTypography>
      </OCardHeader>
      <OCardBody>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput {...stepConfigFormFields.name} />
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldInput {...stepConfigFormFields.label} />
          </OCol>
        </ORow>
        <ORow rowGap={5}>
          <OCol xs={12} sm="auto">
            <CheckboxField {...stepConfigFormFields.isPublic} />
          </OCol>
          <OCol xs={12} lg={6}>
            <CheckboxField {...stepConfigFormFields.needsPreApproval} />
          </OCol>
        </ORow>
        <ORow>
          <OCol xs={12} lg={6}>
            <ORFieldInput {...stepConfigFormFields.maximumDuration} />
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldSelect {...stepConfigFormFields.maximumDurationType}>
              {Object.entries(maximumDurationTypeMap).map(([value, label]) => (
                <OOption key={value} value={value}>
                  {label}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
        </ORow>
        <ORow align="start">
          <OCol xs={12} lg={6}>
            <StepTypeSelector
              name="typeId"
              label="Tipo de step"
              multiple={false}
              stepTypes={stepTypes}
            />
          </OCol>
          <OCol xs={12} lg={6}>
            <StepTypeSelector
              name="dependencyTypeIds"
              label="Depende de"
              multiple
              stepTypes={stepTypes}
            />
          </OCol>
        </ORow>
      </OCardBody>
    </OCard>
  );
};
