import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ResetStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { useIntubateOperationsWithoutRegistry } from "../../intubate-operations-without-registry.context";
import { resultGrid } from "./result.grid";

export const Result = () => {
  const {
    createOperationsHasError,
    createOperationsLoading,
    createdOperations,
    submit,
  } = useIntubateOperationsWithoutRegistry();

  const gridRef = useGridRef();

  return (
    <div className="position-relative d-flex flex-column gap-4">
      <ContentTemplate
        loading={createOperationsLoading}
        hasError={createOperationsHasError}
        value={createdOperations}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível fazer a solicitação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={submit} />
          </ErrorComponent>
        }
        render={(_createdOperations) => (
          <GridTemplate
            gridRef={gridRef}
            showExportButton
            showClearFiltersButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={resultGrid}
              dataSource={createdOperations}
            />
          </GridTemplate>
        )}
      />

      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton
          label="Fazer outra operação"
          disabled={createOperationsLoading}
          type="tertiary"
          outline={false}
          className="ms-auto"
        />
      </div>
    </div>
  );
};
