import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { AntecipacaoDeCartoesContractSignatoriesByTaxId } from "../../../../routes/antecipacao-de-cartoes.route-params";
import {
  ContractSignatoriesForm,
  contractSignatoriesFormValidationSchema,
} from "../_compose";

export const useEditContractSignatories = () => {
  const { taxId } = useParams<AntecipacaoDeCartoesContractSignatoriesByTaxId>();
  if (!taxId) throw new Error("No taxId");

  const {
    callService: getCustomerContractSignatories,
    loading: fetchLoading,
    hasError: fetchHasError,
    value: initialValues,
  } = useServiceCall(service.quickfin.getCustomerContractSignatories);

  const { callService: updateContractSignatories, loading: submitLoading } =
    useServiceCall(service.quickfin.updateContractSignatories);

  const navigate = useNavigate();

  const form = useForm<ContractSignatoriesForm>({
    resolver: yupResolver(contractSignatoriesFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const fetch = useCallback(
    () => getCustomerContractSignatories(taxId),
    [getCustomerContractSignatories, taxId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateContractSignatories({
          taxId,
          identifications: values.identifications,
        });

        if (success) {
          navigate(
            corporateRouter.routes.antecipacaoDeCartoes.product
              .contractSignatories.path,
          );
          OToastManager.success("Assinates configurados com sucesso");
        } else {
          OToastManager.danger("Erro ao configurar os assinantes");
        }
      }),
    [handleSubmit, navigate, taxId, updateContractSignatories],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (initialValues)
      reset({
        taxId,
        identifications: initialValues,
      });
  }, [initialValues, reset, taxId]);

  return {
    fetch,
    fetchHasError,
    fetchLoading,
    form,
    initialValues,
    submit,
    submitLoading,
    taxId,
  };
};
