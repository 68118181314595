import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ResetStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { useQuote } from "../../quote.context";
import { quoteGrid } from "../quote-step/quote-step.grid";

export const Result = () => {
  const {
    anticipate,
    anticipateHasError,
    anticipateLoading,
    anticipateResponse,
  } = useQuote();

  const gridRef = useGridRef();

  return (
    <div className="d-flex flex-column gap-4">
      <ContentTemplate
        loading={anticipateLoading}
        hasError={anticipateHasError}
        value={anticipateResponse?.recebiveis}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível fazer a solicitação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => anticipate()} />
          </ErrorComponent>
        }
        render={(_recebiveis) => (
          <GridTemplate
            gridRef={gridRef}
            showClearFiltersButton
            showExportButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={quoteGrid}
              dataSource={_recebiveis}
            />
          </GridTemplate>
        )}
      />

      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton
          label="Fazer outra cotação"
          disabled={anticipateLoading}
          type="tertiary"
          outline={false}
          className="ms-auto"
        />
      </div>
    </div>
  );
};
