import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { useOriginatorInfoFields } from "./originator-info-fields.hook";

interface OriginatorInfoFieldsProps {
  isEditing?: boolean;
}

export const OriginatorInfoFields = ({
  isEditing = false,
}: OriginatorInfoFieldsProps) => {
  const { foundEntityData } = useOriginatorInfoFields(isEditing);
  return (
    <div className="d-flex flex-column gab-5">
      <ORow>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="identification"
            name="identification"
            label="CNPJ"
            disabled={isEditing}
            required
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="officialName"
            name="officialName"
            label="Razão Social"
            required
            disabled={isEditing || foundEntityData}
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="tradingName"
            name="tradingName"
            label="Nome Fantasia"
            disabled={isEditing || foundEntityData}
          />
        </OCol>
      </ORow>
    </div>
  );
};
