import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { usePropertyTypeSelector } from "./property-type-selector.hook";

interface PropertyTypeSelectorProps {
  id: string;
}

export const PropertyTypeSelector = ({ id }: PropertyTypeSelectorProps) => {
  const { getPropertyTypes, hasFetchError, loading, propertyTypes } =
    usePropertyTypeSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={propertyTypes}
        id={id}
        name="typeId"
        label="Tipo de propriedade"
        placeholder="Selecionar"
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getPropertyTypes} />
      )}
    </div>
  );
};
