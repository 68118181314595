import { OLoader, modalManager } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { roles } from "roles/roles";
import {
  RemoveStepTypeConfirmationModal,
  removeStepTypeConfirmationModalId,
} from "../remove-step-type-confirmation-modal";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  stepType: HubEnergy.DiligenceStepTypeResponse;
  reload: () => void;
}

export const GridActions = ({ stepType, reload }: GridActionsProps) => {
  const { deleteStepType, loading } = useGridActions(stepType, reload);

  return (
    <>
      {loading ? (
        <OLoader size="md" />
      ) : (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              role: roles.energia.product.diligenceConfigDeleteDocument.role,
              onClick: () =>
                modalManager.show(
                  removeStepTypeConfirmationModalId(stepType.id),
                ),
            },
          ]}
        />
      )}
      <RemoveStepTypeConfirmationModal
        stepType={stepType}
        onConfirm={deleteStepType}
      />
    </>
  );
};
