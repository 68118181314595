import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminGenerateGuaranteeContractBody } from "services/hubloan/models/requests/post-admin-generate-guarantee-contract.request";
import { buildCustomerLink } from "utils/build-customer-link";
import { LimitRequest } from "../../../detalhes/limit-details.types";
import { formalizeCollateralFormValidationsSchema } from "./formalize-collateral-button.form";

export const useFormalizeCollateral = (
  limitRequest: LimitRequest | undefined,
) => {
  const [loading, setLoading] = useState(false);

  const { callService: getCollaterals, value: collaterals } = useServiceCall(
    service.hubCreditManager.getCollaterals,
  );

  const form = useForm({
    resolver: yupResolver(
      formalizeCollateralFormValidationsSchema(collaterals),
    ),
  });

  const { trigger, getValues } = form;

  const navigate = useNavigate();

  useEffect(() => {
    if (limitRequest) getCollaterals(limitRequest.id.toString());
  }, [getCollaterals, limitRequest]);

  const formalizeCollateral = useCallback(async () => {
    try {
      if (!limitRequest) return;

      const isValid = await trigger();

      if (!isValid) return;

      const { collateralFormalized, collateralToFormalize, ...values } =
        getValues();

      setLoading(true);

      const guarantees = (collateralToFormalize || [])
        .map((c: string) => ({ type: c }))
        .concat(
          (collateralFormalized || []).map((c: string) => ({
            type: c,
            existingContractReference: values[`contract-${c}`],
            signedDate: values[`signed-date-${c}`],
          })),
        );

      const payload: PostAdminGenerateGuaranteeContractBody = {
        guarantees,
        product: limitRequest?.currentLimit.creditLine.identification,
        taxId: limitRequest?.currentLimit.customer.legalEntity.taxId,
      };

      const { data } = await service.hubLoan.generateGuaranteeContract(payload);

      OToastManager.success(
        "Formalização de garantias solicitada com sucesso.",
      );

      modalManager.hide("generate-guarantee-contract");

      navigate(
        buildCustomerLink(
          limitRequest?.currentLimit.customer.legalEntity.taxId ?? "",
          corporateRouter.routes.emprestimos.customer.operations.details.path({
            id: data,
          }),
        ),
      );
    } catch {
      OToastManager.danger(
        "Não foi possível solicitar formalização de garantias.",
      );
    } finally {
      setLoading(false);
    }
  }, [getValues, limitRequest, navigate, trigger]);

  return { loading, collaterals, formalizeCollateral, form };
};
