import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";

interface UpdateEstrategiasTaxasConfirmationModalProps {
  submit: (updateEstrategiasTaxas: boolean) => () => void;
}

export const updateEstrategiasTaxasConfirmationModalId =
  "update-estrategias-taxas-confirmation-modal";

export const UpdateEstrategiasTaxasConfirmationModal = ({
  submit,
}: UpdateEstrategiasTaxasConfirmationModalProps) => {
  return (
    <OModal
      id={updateEstrategiasTaxasConfirmationModalId}
      position="center"
      size="sm"
      backdrop
    >
      <OModalHeader>
        <OTypography size="lg">Confirmação</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ textAlign: "start" }}>
          Deseja atualizar a configuração de taxa de todas as estratégias
          vinculadas a este template?
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(updateEstrategiasTaxasConfirmationModalId)
            }
            className="me-auto"
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:nao"
            dataLabel="nao"
            type="dark"
            outline
            onClick={() => {
              submit(false)();
              modalManager.hide(updateEstrategiasTaxasConfirmationModalId);
            }}
          >
            Não
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:sim"
            dataLabel="sim"
            onClick={() => {
              submit(true)();
              modalManager.hide(updateEstrategiasTaxasConfirmationModalId);
            }}
          >
            Sim
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
