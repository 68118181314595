import { yupResolver } from "@hookform/resolvers/yup";
import { OCard, OCardBody, ORow, OToastManager } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { useCollectionsAgreementContext } from "../convenio";
import {
  DescriptionInput,
  DiscountFields,
  FineFields,
  IdInput,
  InterestFields,
  NameInput,
} from "./_compose";
import { DaysToCancelInput } from "./_compose/days-to-cancel-input";
import { DefaultAgreementInput } from "./_compose/default-agreement-input";
import { FormFooterline } from "./_compose/form-footerline";
import {
  assembleUpsertCollectionAgreementDefaultValues,
  UpsertCollectionAgreementFormFields,
  upsertCollectionAgreementValidationSchema,
} from "./upsert-collection-agreement.form";

export const UpsertCollectionAgreement = () => {
  const { agreementToUpdate } = useCollectionsAgreementContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const form = useForm<UpsertCollectionAgreementFormFields>({
    defaultValues:
      assembleUpsertCollectionAgreementDefaultValues(agreementToUpdate),
    mode: "onChange",
    resolver: yupResolver(upsertCollectionAgreementValidationSchema),
  });

  const pageTitleProps = useMemo(() => {
    const title = `${agreementToUpdate ? "Editar" : "Cadastrar"} convênio`;
    const subtitle = `Preencha os campos abaixo para ${
      agreementToUpdate ? "editar o convênio" : "cadastrar um novo convênio"
    }`;
    return { title, subtitle };
  }, [agreementToUpdate]);

  useEffect(() => {
    if (pathname.includes("editar") && !agreementToUpdate) {
      OToastManager.warning(
        "Ocorreu um erro para editar o convênio. Tente novamente!",
      );
      navigate(
        corporateRouter.routes.banking.customer.collection.agreement.path,
      );
    }
  }, [agreementToUpdate, navigate, pathname]);

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-4">
        <PageTitle {...pageTitleProps} />

        <OCard>
          <OCardBody className="d-flex flex-column gap-3">
            <div className="d-flex flex-column">
              <ORow align="center" gap={3}>
                <IdInput />
                <NameInput />
              </ORow>

              <ORow align="center" gap={3}>
                <DescriptionInput />
                <DaysToCancelInput />
              </ORow>
            </div>

            <InterestFields />
            <FineFields />
            <DiscountFields />

            <DefaultAgreementInput />
          </OCardBody>
        </OCard>
        <FormFooterline />
      </div>
    </FormProvider>
  );
};
