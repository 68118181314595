import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { logger } from "utils/logger";
import { UploadGroupFileProps } from "./upload-group-file.types";
import { parseSpreadsheet } from "./upload-group-file.utils";

export const useUploadGroupFile = ({
  setCompanyGroup,
}: UploadGroupFileProps) => {
  const {
    error: readerError,
    loading: readerLoader,
    reader,
    result,
  } = useReadFile();

  const [loading, setLoading] = useState(false);
  const [isBranchFormOpen, setIsBranchFormOpen] = useState(false);
  const [newCompanyGroup, setNewCompanyGroup] = useState<
    CompanyGroupAssociationsItem[]
  >([]);

  const form = useFormContext();
  const { watch } = form;
  const watchTaxId = watch("taxId");

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) reader.readAsBinaryString(file);
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        setLoading(true);

        const sheet = parseSpreadsheet(
          result,
        ) as CompanyGroupAssociationsItem[];

        const filteredSheet = sheet.filter(
          (value) => value.taxId !== watchTaxId.replace(/\D/g, ""),
        );

        setCompanyGroup((oldCompanyGroup) => [
          ...oldCompanyGroup,
          ...filteredSheet,
        ]);
        setNewCompanyGroup((oldCompanyGroup) => [
          ...oldCompanyGroup,
          ...filteredSheet,
        ]);
      } catch (err) {
        logger.error(err);
        OToastManager.danger("Erro para pegar os dados do arquivo.");
      } finally {
        setLoading(false);
      }
    }
  }, [readerError, result, setCompanyGroup, watchTaxId]);

  return {
    handleAddFile,
    isBranchFormOpen,
    loading,
    newCompanyGroup,
    readerLoader,
    setIsBranchFormOpen,
    setNewCompanyGroup,
  };
};
