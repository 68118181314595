import { Routes } from "routes/routes.types";
import { WorkflowsRmAdminCustomerPage } from "../pages/cliente";
import { WorkflowsRmAdminPage } from "../pages/produto";
import { workflowRmAdminRoles } from "../roles/workflow-rmadmin.roles";

export const workflowRmAdminRoutes = {
  customer: {
    name: "",
    path: "/workflow-rmadmin/cliente",
    element: <WorkflowsRmAdminCustomerPage />,
    role: workflowRmAdminRoles.customer.tab.role,
  },
  product: {
    name: "",
    path: "/workflow-rmadmin/produto",
    element: <WorkflowsRmAdminPage />,
    role: workflowRmAdminRoles.product.tab.role,
  },
} as const satisfies Routes;
