import { useCallback } from "react";
import { service } from "services";
import { logger } from "utils/logger";
import { OToastManager, modalManager } from "@maestro/react";
import {
  Integration,
  IntegrationRegisterHeader,
  ListIntegrationRequest,
} from "./compose/interfaces/integration";
import { IntegrationFileProcessor } from "./integrations.form";
import { IntegrationModalName } from "./compose/enums/integrationModalName";
import { RegisterCompanyLayoutProps } from "./compose/interfaces/layout";

export const useFileProcessor = () => {
  const assembleHeaders = (
    integration: Integration,
  ): IntegrationRegisterHeader => {
    const filteredContact = integration.contacts.find(
      (contact) => contact.type === "VAN",
    );
    return {
      "x-identification": integration.companyDocument,
      "x-auth-identification": integration.companyDocument,
      "x-operator-identification": integration.companyDocument,
      "x-operator-email": "implantacaoempresas@btgpactual.com",
      "x-auth-user-email": filteredContact?.email || "",
      "x-identification-name": filteredContact?.name || "",
    };
  };

  const assembleConfig = (contactCNPJ: string, contactEmail: string) => {
    return {
      isBolepix: false,
      status: "ACTIVE",
      erp: "",
      sendSMS: false,
      sendEmail: false,
      defaultOperatorInfo: {
        id: contactCNPJ,
        email: contactEmail,
      },
    };
  };

  const getIntegrations = useCallback(
    async (values: ListIntegrationRequest) => {
      try {
        const { product: productId, document } = values;

        const response =
          await service.adminBankinghub.getFileProcessorIntegrations(document, {
            productId,
          });

        return response?.data?.integrations || [];
      } catch (err) {
        OToastManager.danger("Erro ao buscar integrações");
        logger.error(err);
      }
    },
    [],
  );

  const updateIntegration = useCallback(
    async (
      integration: IntegrationFileProcessor,
      status: string,
      observation: string,
    ) => {
      try {
        await service.adminBankinghub.updateIntegration(
          integration.id,
          status,
          observation,
        );
        OToastManager.success("Integração atualizada com sucesso!");
        modalManager.hide(IntegrationModalName.EDIT_INTEGRATION_MODAL);
      } catch (err) {
        OToastManager.danger("Erro ao atualizar integrações");
        logger.error(err);
      }
    },
    [],
  );

  const registerCompany = useCallback(
    async (document: string, name: string) => {
      const payload = {
        document,
        name,
      };

      try {
        const response = await service.adminBankinghub.registerCompany(payload);

        return response;
      } catch (error) {
        logger.error("useFileProcessor: registerCompany ", { error });
        throw error;
      }
    },
    [],
  );

  const registerCompanyLayout = useCallback(
    async ({
      layoutId,
      productId,
      taxId,
      operatorTaxId,
      operatorEmail,
    }: RegisterCompanyLayoutProps) => {
      try {
        const config = assembleConfig(operatorTaxId, operatorEmail);
        const payload = {
          layoutId,
          productId,
          taxId,
          config,
        };

        await service.adminBankinghub.registerCompanyLayout(payload);
      } catch (error) {
        logger.error("useFileProcessor:", { error });
        throw error;
      }
    },
    [],
  );

  const registerIntegration = useCallback(async (payload: Integration) => {
    try {
      const headers = assembleHeaders(payload);
      const body = { ...payload };
      const response = await service.adminBankinghub.registerIntegration(
        headers,
        body,
      );
      return response?.data;
    } catch (error) {
      logger.error("useFileProcessor: error registering new integration", {
        error,
      });
      throw error;
    }
  }, []);

  return {
    getIntegrations,
    updateIntegration,
    registerCompany,
    registerCompanyLayout,
    registerIntegration,
  };
};
