import * as yup from "yup";

export interface SettlementInstructionFormValues {
  firstPaymentDate?: string | null;
  paymentDate?: string | null;
}

export const settlementInstructionFormValidationsSchema: yup.ObjectSchema<SettlementInstructionFormValues> =
  yup.object({
    firstPaymentDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .nullable()
      .optional(),
    paymentDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .nullable()
      .optional(),
  });
