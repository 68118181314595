import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingEditByIdRouteParams } from "../../../../routes/cadastro.route-params";
import { useOnboardingEdit } from "./onboarding-edit.hook";
import { statusType } from "./onboarding-edit.type";

export const OnboardingEdit = () => {
  const { id } = useParams<OnboardingEditByIdRouteParams>();
  if (!id) throw new Error("No id in route parameters");

  const { submit, form, loading, watchStatus } = useOnboardingEdit({ id });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar Onboarding" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="editar_onboarding:botao:editar"
          dataLabel="editar"
          onClick={submit}
        >
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol>
                <ORFieldSelect
                  id="status"
                  name="status"
                  dataAction="editar_onboarding:input:status"
                  dataLabel="status"
                  placeholder="Status"
                  label="Status"
                  value={watchStatus}
                >
                  {statusType.map((status) => (
                    <OOption key={status} id={status} value={status}>
                      {status}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="type"
                  name="type"
                  dataAction="editar_onboarding:input:tipo-de-onboarding"
                  dataLabel="tipo-de-onboarding"
                  label="Tipo de onboarding"
                  disabled
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="insertedAt"
                  name="insertedAt"
                  dataAction="editar_onboarding:input:data-da-criacao"
                  dataLabel="data-da-criacao"
                  label="Data da Criação"
                  disabled
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="updatedAt"
                  name="updatedAt"
                  dataAction="editar_onboarding:input:data-de-atualizacao"
                  dataLabel="data-de-atualizacao"
                  label="Data de Atualização"
                  disabled
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
