import * as yup from "yup";
import {
  ProposalStatus,
  CardModality,
} from "services/bankinghub/models/types/cards/enums";
import { validators } from "@maestro/utils";
import { CardHiringForm } from "./card-hiring-form.types";
import { validatePhone } from "./card-hiring-form.utils";

const numberRequired = yup.number().required("Campo obrigatório");

const stringRequired = yup.string().required("Campo obrigatório");

const notRequired = yup.mixed().notRequired();

const validateWhenCategory = (
  category: keyof CardHiringForm,
  ref: "initial" | "additional",
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as CardHiringForm)[category] === ref ? schema : notRequired,
  );

const validateWhenProposalStatus = (
  proposalStatus: keyof CardHiringForm,
  ref: ProposalStatus,
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as CardHiringForm)[proposalStatus] === ref ? schema : notRequired,
  );

const validateWhenProposalStatusAndModality = (
  proposalStatus: keyof CardHiringForm,
  proposalStatusRef: ProposalStatus,
  modality: keyof CardHiringForm,
  modalityRefs: CardModality[],
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as CardHiringForm)[proposalStatus] === proposalStatusRef &&
    (modalityRefs as unknown[]).includes((parent as CardHiringForm)[modality])
      ? schema
      : notRequired,
  );

const validateWhenModality = (
  modality: keyof CardHiringForm,
  modalityRefs: CardModality[],
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (modalityRefs as unknown[]).includes((parent as CardHiringForm)[modality])
      ? schema
      : notRequired,
  );

const validateWhenPinType = (
  pinType: keyof CardHiringForm,
  schema: yup.AnySchema,
) =>
  yup.lazy((_, { parent }) =>
    (parent as CardHiringForm)[pinType] === "personal" ? schema : notRequired,
  );

const loadPinConfirmSchema = (
  pinType: keyof CardHiringForm,
  pin: keyof CardHiringForm,
) =>
  yup.lazy((_, { parent }) =>
    (parent as CardHiringForm)[pinType] === "personal"
      ? yup
          .string()
          .required("Campo obrigatório")
          .equals([(parent as CardHiringForm)[pin]], "As senhas não coincidem")
      : notRequired,
  );

const validatePrintedName = (value: string | undefined | null) => {
  if (!value) return false;
  const hasInvalidSpaces = !!value.match(/^ |  +| $/g)?.length;
  const isValid =
    /^((([A-Za-z]){2,}( ([A-Za-z]){2,})*)+ ([A-Za-z] )*(([A-Za-z]){2,}( ([A-Za-z]){2,})*)+)+$/.test(
      value,
    );
  return !(hasInvalidSpaces || !isValid);
};

const validatePin = (value: string | undefined | null) => {
  if (!value) return false;
  const areSequentials = (first: number, second: number, third: number) => {
    const diferences = [Math.abs(second - first), Math.abs(third - second)];
    return diferences.every((diff) => diff === 1);
  };

  const hasANumericalSequence = (digits: number[]) => {
    const [first, second, third, fourth] = digits;
    return (
      areSequentials(first, second, third) ||
      areSequentials(second, third, fourth)
    );
  };

  const hasEqualDigitsLimit = (digits: number[]) => {
    return new Set(digits).size < 2;
  };

  if (value.length !== 4) return false;
  const digits = value.split("").map((digit) => Number.parseInt(digit));
  if (hasANumericalSequence(digits)) {
    return false;
  }
  if (hasEqualDigitsLimit(digits)) {
    return false;
  }
  return true;
};

export const cardHiringFormValidationSchema = yup.object<
  CardHiringForm,
  yup.ObjectSchema<CardHiringForm>["fields"]
>({
  category: yup
    .string()
    .equals(["initial", "additional"])
    .required("Campo obrigatório"),
  proposalStatus: yup.mixed<ProposalStatus>().required("Campo obrigatório"),
  programId: numberRequired,
  ownerDocument: yup
    .string()
    .required("Campo obrigatório")
    .test("ownerDocument", "Portador do cartão inválido", validators.cpf),
  ownerId: stringRequired,
  addressId: stringRequired,
  phone: yup
    .string()
    .required("Campo obrigatório")
    .test("phone", "Número de telefone inválido", validatePhone),
  printedName: yup
    .string()
    .required("Campo obrigatório")
    .test(
      "printedName",
      "Nome impresso no cartão inválido",
      validatePrintedName,
    ),
  issueDateId: validateWhenProposalStatusAndModality(
    "proposalStatus",
    ProposalStatus.Available,
    "modality",
    [CardModality.Credit, CardModality.Multiple],
    numberRequired,
  ),
  issueDateDay: validateWhenProposalStatusAndModality(
    "proposalStatus",
    ProposalStatus.Available,
    "modality",
    [CardModality.Credit, CardModality.Multiple],
    numberRequired,
  ),
  pinType: yup
    .string()
    .equals(["personal", "random"])
    .required("Campo obrigatório"),
  pin: validateWhenPinType(
    "pinType",
    yup
      .string()
      .required("Campo obrigatório")
      .test("pin", "Senha inválida", validatePin),
  ),
  pinConfirm: loadPinConfirmSchema("pinType", "pin"),
  accountId: validateWhenCategory("category", "additional", stringRequired),
  offerId: validateWhenProposalStatus(
    "proposalStatus",
    ProposalStatus.Available,
    stringRequired,
  ),
  modality: yup.mixed<CardModality>().required("Campo obrigatório"),
  hiredAmount: validateWhenProposalStatusAndModality(
    "proposalStatus",
    ProposalStatus.Available,
    "modality",
    [CardModality.Credit, CardModality.Multiple],
    numberRequired,
  ),
  branch: validateWhenModality(
    "modality",
    [CardModality.Debit, CardModality.Multiple],
    stringRequired,
  ),
  accountNumber: validateWhenModality(
    "modality",
    [CardModality.Debit, CardModality.Multiple],
    stringRequired,
  ),
});
