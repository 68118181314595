import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { RemoveButton, ReprocessButton } from "../action-buttons";
import { errorMessagesGrid } from "./error-messages.grid";

interface ErrorMessagesProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse;
}

export const ErrorMessages = ({ importData }: ErrorMessagesProps) => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      gridRef={gridRef}
      actions={
        <div className="w-100 d-flex align-items-center justify-content-between">
          <OTypography size="lg" type="default" className="ms-2">
            Erros
          </OTypography>
          <div className="d-flex align-items-center gap-2">
            <RemoveButton importData={importData} />
            <ReprocessButton importData={importData} />
          </div>
        </div>
      }
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={errorMessagesGrid}
        dataSource={importData?.errors}
      />
    </GridTemplate>
  );
};
