import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";
import { periodTabMap } from "./dashboard-fluctuations.utils";

interface DashboardFluctuations {
  activeTab: number;
  flowType: "REMESSA" | "INTERNACAO";
}

export const useDashboardFluctuations = ({
  activeTab,
  flowType,
}: DashboardFluctuations) => {
  const dashboard = useServiceCall(
    service.hubFx.getByChannelDashboardFluctuations,
  );

  const getByChannelDashboardFluctuations = dashboard.callService;

  const getDashboard = useCallback(async () => {
    getByChannelDashboardFluctuations({
      range: periodTabMap[activeTab].id,
      flowType,
    });
  }, [activeTab, flowType, getByChannelDashboardFluctuations]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  return { dashboard, getDashboard };
};
