import { modalManager, OToastManager } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { roles } from "roles/roles";
import { JobsMasterDetail } from "./jobs.master-detail";
import { Job } from "./jobs.types";
import { jobStateMap, runJobs, stopJobs, styleStateCells } from "./jobs.utils";
import { ConfirmationModal } from "./_compose";

export const dataSource = dataSourceCustomStoreGenerator<Job>(
  (loadOptions: any) =>
    service.raven
      .getJobs(loadOptions)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os jobs";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const buildJobsGrid = (
  setSelectedJobs: (jobs: Job[]) => void,
  autoExpandAll: boolean,
): ODataGridGeneratorConfig<Job> => ({
  datagrid: {
    filterRow: { visible: true },
    headerFilter: { visible: true },
    remoteOperations: true,
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 50, 100],
    },
    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
    onCellPrepared: styleStateCells,
    onSelectionChanged: (evt) => setSelectedJobs(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "attempted_at",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Executado em",
      sortOrder: "desc",
      allowHeaderFiltering: false,
    },
    {
      dataField: "worker",
      dataType: "string",
      caption: "Worker",
      allowHeaderFiltering: false,
      selectedFilterOperation: "contains",
    },
    {
      dataField: "inserted_at",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Criado em",
      selectedFilterOperation: ">=",
      allowHeaderFiltering: false,
    },
    {
      dataField: "attempt",
      dataType: "number",
      caption: "Tentativas",
      allowHeaderFiltering: false,
    },
    {
      dataField: "max_attempts",
      dataType: "number",
      caption: "Máximo de tentativas",
      allowHeaderFiltering: false,
    },
    {
      dataField: "state",
      dataType: "string",
      caption: "Situação",
      calculateCellValue: ({ state }) =>
        getValueFromMap(jobStateMap, state) || state,
      headerFilter: {
        dataSource: Object.entries(jobStateMap).map(([k, v]) => ({
          value: k,
          text: v,
        })),
      },
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      allowEditing: false,
      caption: "Ações",
      cellRender: ({ data: { id, worker } }) => {
        const runModalId = `jobs-run-${id}-modal`;
        const stopModalId = `jobs-stop-${id}-modal`;

        return (
          <>
            <DataGridAction
              actions={[
                {
                  label: "Redisparar",
                  icon: { category: "far", icon: "fa-redo" },
                  onClick: () => modalManager.show(runModalId),
                  role: roles.ferramentas.product.ravenRunJobs.role,
                },
                {
                  label: "Parar",
                  icon: { category: "far", icon: "fa-ban" },
                  onClick: () => modalManager.show(stopModalId),
                  role: roles.ferramentas.product.ravenStopJobs.role,
                },
              ]}
            />
            <ConfirmationModal
              id={runModalId}
              onConfirm={async () =>
                runJobs([id]).then(() => dataSource.reload())
              }
              type="run"
              job={worker}
            />
            <ConfirmationModal
              id={stopModalId}
              onConfirm={async () =>
                stopJobs([id]).then(() => dataSource.reload())
              }
              type="stop"
              job={worker}
            />
          </>
        );
      },
    },
  ],
  masterDetail: {
    enabled: true,
    component: JobsMasterDetail,
    autoExpandAll,
  },
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
