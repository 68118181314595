import * as yup from "yup";
import { CompanyUserProfilesForm } from "./company-user-profiles.types";

export const companyUserProfilesFields = {
  profiles: {
    id: "profiles",
    name: "profiles",
    label: "Profiles",
    placeholder: "Buscar um profile",
  },
  companyTaxId: {
    id: "companyTaxId",
    name: "companyTaxId",
    label: "Empresa",
    placeholder: "Selecionar uma empresa",
  },
};

export const companyUserProfilesDefaultValues = {
  profiles: [],
};

export const companyUserProfilesValidationSchema = yup.object<
  CompanyUserProfilesForm,
  yup.ObjectSchema<CompanyUserProfilesForm>["fields"]
>({
  companyTaxId: yup.string().required("Campo obrigatório"),
  profiles: yup.array().required("Campo obrigatório"),
});
