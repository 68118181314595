import * as yup from "yup";
import { RoadwalkerSearch } from "./roadwalker-search-modal.types";

export const roadwalkerSearchDefaultValues: RoadwalkerSearch = {
  messageType: "trade",
  tradesIds: [],
  indexedSearch: "key",
  filters: [],
};

export const roadwalkerSearchValidationSchema: yup.ObjectSchema<RoadwalkerSearch> =
  yup.object({
    messageType: yup.string().required("Este campo é obrigatorio"),
    tradesIds: yup.array().min(1, "Inclua ao menos um id para a busca"),
    indexedSearch: yup.string().required("Este campo é obrigatorio"),
    filters: yup.array().required("Este campo é obrigatorio"),
  });
