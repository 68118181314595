import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const usePortfolioIdField = () => {
  const {
    callService,
    loading,
    value: portfolios,
    hasError,
  } = useServiceCall(service.quickfin.getPortfolios);

  const { clearErrors, setError } = useFormContext();

  const getPortfolios = useCallback(async () => {
    const { success } = await callService();

    if (success) {
      clearErrors("portfolioId");
    } else {
      setError("portfolioId", {
        message:
          "Erro ao buscar as carteiras. Clique no botão ao lado para tentar novamente.",
      });
    }
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getPortfolios();
  }, [getPortfolios]);

  return {
    portfolios,
    getPortfolios,
    loading,
    hasFetchError: hasError,
  };
};
