import { ErrorComponent } from "./error-component.component";
import { TryAgainButton } from "./try-again-button.component";

interface DefaultErrorComponentProps {
  callback: () => void;
  title: string;
  paragraph?: string;
}

export const DefaultErrorComponent = ({
  callback,
  title,
  paragraph = "Clique no botão para tentar novamente.",
}: DefaultErrorComponentProps) => (
  <ErrorComponent messageTitle={title} messageParagraph={paragraph}>
    <TryAgainButton onClick={callback} />
  </ErrorComponent>
);
