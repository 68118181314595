import {
  modalManager,
  OButton,
  ODropdownAction,
  ODropdownActionItem,
  OIcon,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect, useRef } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../components/hooks/use-workflow-form-template.context";
import { useWorkflow } from "../../../../../../../components/hooks/use-workflow.context";
import { OCardStyled, OTypographyStyled } from "./draggable-steps.styles";

export const DraggableSteps = () => {
  const dragItem = useRef<number>(0);
  const dragOverItem = useRef<number>(0);
  const initialized = useRef<boolean>(false);
  const { show } = modalManager;
  const {
    form: { callService: getForm, value: workflowFormTemplate },
    setSelectedStep,
    selectedStep,
    setSelectedCard,
  } = useWorkflowFormTemplate();

  const { setConditionDestination, setSelectedConditions } = useWorkflow();

  useEffect(() => {
    if (!workflowFormTemplate?.steps.length || initialized.current) return;
    initialized.current = true;
    workflowFormTemplate.steps.sort((a, b) => a.position - b.position);
    setSelectedStep(workflowFormTemplate.steps[0]);
  }, [setSelectedStep, workflowFormTemplate]);

  const updateStepPositions = useCallback(async () => {
    if (!workflowFormTemplate) return;

    let stepListAux = workflowFormTemplate.steps.map((s) => ({ ...s })); // .splice() e [...array] nao funcionam para array em um state
    const dragItemContent = stepListAux[dragItem.current];

    stepListAux.splice(dragItem.current, 1);
    stepListAux.splice(dragOverItem.current, 0, dragItemContent);
    stepListAux = stepListAux.map((step, index) => ({
      ...step,
      position: index,
    }));

    if (
      stepListAux[dragItem.current] ===
      workflowFormTemplate.steps[dragItem.current]
    )
      return;

    try {
      await service.hubCreditManager.updateFormStepPositions({
        formId: workflowFormTemplate.id,
        positions: stepListAux.map((step) => {
          return { formStepId: step.id, position: step.position };
        }),
      });

      workflowFormTemplate.steps = stepListAux;
      getForm(workflowFormTemplate.id);
    } catch {
      OToastManager.danger("Não foi possível atualizar a posição dos passos.");
    }
  }, [workflowFormTemplate, getForm]);

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between align-items-center">
        <OTypography tag="h3" weight="normal" size="lg">
          Passos
        </OTypography>
        <OButton
          type="info"
          disabled={!workflowFormTemplate}
          onClick={() => {
            setSelectedStep(undefined);
            show("add-step-modal");
          }}
        >
          Adicionar passo
        </OButton>
      </div>

      <div className="d-flex flex-wrap gap-2">
        {!workflowFormTemplate?.steps?.length && (
          <OTypography>Formulário ainda sem passos cadastrados</OTypography>
        )}

        {workflowFormTemplate?.steps
          ?.sort((a, b) => a.position - b.position)
          .map((step, index) => (
            <OCardStyled
              onClick={() => setSelectedStep(step)}
              type={step.id === selectedStep?.id ? "info-light" : "light"}
              onDragStart={() => {
                dragItem.current = index;
              }}
              onDragEnter={() => {
                dragOverItem.current = index;
              }}
              onDragEnd={updateStepPositions}
              key={step.id}
              draggable
            >
              <div className="d-flex align-items-center p-3 h-100 justify-content-between">
                <OTypographyStyled size="sm" key={step.name}>
                  {step.name}
                </OTypographyStyled>
                {step.id === selectedStep?.id && (
                  <ODropdownAction>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setConditionDestination("STEP");
                        setSelectedConditions(step.conditions);
                        show("update-step-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-pencil"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Editar</OTypography>
                    </ODropdownActionItem>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedCard(undefined);
                        show("submit-card-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-add-circle"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Adicionar card</OTypography>
                    </ODropdownActionItem>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        show("remove-step-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-trash"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Remover</OTypography>
                    </ODropdownActionItem>
                  </ODropdownAction>
                )}
              </div>
            </OCardStyled>
          ))}
      </div>
    </div>
  );
};
