import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { MonitoringTemplate } from "../../../../cadastro/components/monitoramento";
import { GuarantorsMonitoringGrid } from "./guarantors-monitoring.grid";

export const GuarantorsMonitoring = () => {
  const gridRef = useGridRef();
  return (
    <>
      <PageTitle title="Monitoramento Avalistas" />
      <MonitoringTemplate
        filterFields={[
          {
            label: "Nome",
            property: "CompanyName",
          },
          {
            label: "TaxId",
            property: "CompanyIdentification",
          },
          {
            label: "Tipo de avalista",
            property: "Type",
          },
          {
            label: "Nome do avalista",
            property: "UserName",
          },
          {
            label: "TaxId do avalista",
            property: "UserIdentification",
          },
        ]}
        relationship="GUARANTOR"
        gridBuilder={GuarantorsMonitoringGrid}
        gridRef={gridRef}
      />
    </>
  );
};
