import { ORadioGroup, OTypography } from "@maestro/react";
import { ExportRadio } from "./items/export-radio.component";
import { order } from "../../filter/filters";

export const OrdenationOptions = () => {
  return (
    <>
      <OTypography className="mt-2 mb-2">
        Ordenação dos lançamentos por data
      </OTypography>
      <ORadioGroup
        className="d-flex flex-row justify-content-start gap-2 w-40"
        data-filterparam="order"
        dataAction="statement-export-filter"
        dataLabel="statement-export-filter"
      >
        {order.map((item) => (
          <ExportRadio radioName="order" {...item} key={item.value} />
        ))}
      </ORadioGroup>
    </>
  );
};
