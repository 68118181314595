import { OTypography, OIcon, OButton, OToastManager } from "@maestro/react";

interface TrackingCodeProps {
  code: string;
  link: string;
  isPostOffice: boolean;
}

export const TrackingCode = ({
  code,
  link,
  isPostOffice,
}: TrackingCodeProps): JSX.Element => {
  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    OToastManager.success("Código copiado");
  };

  const handleTrackingLinkClick = () => {
    window.open(link, "_blank");
  };

  return (
    <div className="d-flex flex-column">
      <OTypography type="primary-80" size="sm">
        Código de rastreio
      </OTypography>
      <div className="d-flex align-items-center">
        <OTypography>{code}</OTypography>
        <OButton
          dataAction="rastreamento_cartao:botao:copiar_codigo_de_rastreio"
          dataLabel="copiar_codigo_de_rastreio"
          circle
          outline
          bordered={false}
          onClick={handleCopy}
          type="default"
        >
          <OIcon category="orq" icon="orq-edit-copy" />
        </OButton>
      </div>
      <OButton
        className="p-0"
        outline={true}
        bordered={false}
        dataAction="rastreamento_cartao:botao:acessar_link_de_rastreio"
        dataLabel="acessar_link_de_rastreio"
        type="info"
        onClick={handleTrackingLinkClick}
      >
        <div className="d-flex">
          {isPostOffice ? "Acessar site dos Correios" : "Acessar rastreio"}
          <OIcon className="pl-1" category="orq" icon="orq-external-link" />
        </div>
      </OButton>
    </div>
  );
};
