import { BankIconSVG } from "../financial-institution-icon.types";

export const BankBrasilSVG = ({ width, height, size }: BankIconSVG) => {
  return (
    <svg
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={56} cy={56} r={56} fill="#FDE100" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 46l12.484 8.333 14.995-10-6.242-4.178 8.119-5.399 17.482 11.667-28.722 19.154 6.242 4.179L85.978 46 55.99 26 26 46zm59.979 20l-12.507-8.333-14.995 10 6.242 4.154-8.12 5.423-17.482-11.666L67.84 46.422l-6.242-4.179L26 66l29.99 20 29.989-20zm0-31.667l-6.266 4.179-6.241-4.179L85.979 26v8.333zM26 77.667l6.242-4.179 6.242 4.179L26 86v-8.333z"
        fill="#003882"
      />
    </svg>
  );
};
