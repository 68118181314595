export interface OfferGuarantorsContext {
  offerGuarantors?: BankingHubCards.OfferGuarantor[];
  fetch: () => void;
  fetchHasError: boolean;
  fetchLoading: boolean;
}

export const OfferGuarantorsContextDefaultData: OfferGuarantorsContext = {
  offerGuarantors: undefined,
  fetch: () => {},
  fetchHasError: false,
  fetchLoading: false,
};
