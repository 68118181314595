import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiGroupsResponseItem } from "services/clerk";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { grid } from "./groups.grid";

const dataSource = dataSourceCustomStoreGenerator<GetApiGroupsResponseItem>(
  () =>
    service.clerk
      .getGroups()
      .then(({ data }) =>
        data.filter((company) => company.type === "MatrizFilial"),
      )
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar grupos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const Groups = () => {
  const { routes } = corporateRouter;
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Grupos de empresas"
          description="Visualizar grupos cadastrados na plataforma"
        />
      }
      actions={
        <RouterButton
          dataAction="grupo_empresas:botao:adicionar"
          dataLabel="adicionar"
          href={routes.empresas.product.groups.add.path}
          role={roles.empresas.product.groupsAddPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
