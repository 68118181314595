import {
  AddressForm,
  addressFormDefaultValues,
  addressFormValidationSchema,
} from "../../../../components/address-form/address.form";
import {
  DistributorBasicDataForm,
  DistributorConfigForm,
  distributorBasicDataFormDefaultValues,
  distributorBasicDataFormValidationSchema,
  distributorConfigFormDefaultValues,
  distributorConfigFormValidationSchema,
} from "../_compose";

export interface AddDistributorForm
  extends DistributorBasicDataForm,
    DistributorConfigForm,
    AddressForm {}

export const addDistributorFormValidationSchema =
  distributorBasicDataFormValidationSchema
    .concat(distributorConfigFormValidationSchema)
    .concat(addressFormValidationSchema);

export const addDistributorFormDefaultValues = {
  ...distributorBasicDataFormDefaultValues,
  ...distributorConfigFormDefaultValues,
  ...addressFormDefaultValues,
};
