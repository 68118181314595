export const ORFieldInputs = [
  {
    label: "Valor total",
    name: "amount",
    id: "amount",
    mask: true,
  },
  {
    label: "CPF / CNPJ",
    name: "counterpartyIdentification",
    id: "counterpartyIdentification",
  },
  {
    label: "natureId",
    name: "natureId",
    id: "natureId",
    number: true,
  },
  {
    label: "orderId",
    name: "orderId",
    id: "orderId",
    number: true,
  },
];

export const mapFlowType = [
  {
    label: "Envio",
    value: "REMESSA",
  },
  {
    label: "Recebimento",
    value: "INTERNACAO",
  },
];
