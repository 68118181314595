import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { ResetButton } from "../../_compose";
import { useUploadTiers } from "../../upload-tiers.context";

export const Result = () => {
  const { submit, submitHasError, submitLoading } = useUploadTiers();

  return (
    <div className="position-relative d-flex flex-column gap-4">
      <ContentTemplate
        loading={submitLoading}
        hasError={submitHasError}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível fazer a solicitação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => submit()} />
          </ErrorComponent>
        }
        render={() => (
          <EmptyState messageTitle="Relacionamentos enviados com sucesso" />
        )}
      />

      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetButton disabled={submitLoading}>Enviar outro arquivo</ResetButton>
      </div>
    </div>
  );
};
