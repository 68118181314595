import { OButton, OCol, OField, OIcon, OOption, OSelect } from "@maestro/react";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  ConfigurationDefaultValue,
  ConfigurationFormField,
  DomainScfFormFields,
} from "../../domain-configuration-scf.form";
import { stepTypeMap } from "../../domain-configuration-scf.utils";
import { StyledIndex, StyledRow } from "./configuration-form.style";
import { ConfigurationFormMapper } from "./configuration-form.utils";

interface ConfigurationFormProps {
  fieldName: "configurations" | "syncConfigurations";
  title: string;
}

export const ConfigurationsForm = ({
  fieldName,
  title,
}: ConfigurationFormProps) => {
  const [stepType, setStepType] =
    useState<ScfDevelopers.StepType>("COMPLEMENT");

  const form = useFormContext<DomainScfFormFields>();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <OField label={title} htmlFor="extra-fields">
      <div className="d-flex flex-column" id="extra-fields">
        <div className="d-flex flex-row w-50 gap-3 mb-3">
          <OSelect
            dataAction="partner_configuration_scf:select:tipo_step"
            dataLabel="tipo_step"
            value={stepType}
            onInput={(evt) =>
              setStepType(
                (evt.currentTarget as HTMLOSelectElement)
                  .value as ScfDevelopers.StepType,
              )
            }
          >
            {Object.entries(stepTypeMap).map(([value, label]) => (
              <OOption key={`${fieldName}-${value}`} value={value}>
                {label}
              </OOption>
            ))}
          </OSelect>
          <OButton
            dataAction={`partner_configuration_scf:botao:adicionar_${fieldName}`}
            dataLabel={`adicionar_${fieldName}`}
            onClick={() =>
              append(
                ConfigurationDefaultValue[stepType] as ConfigurationFormField,
              )
            }
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
        {fields.map((field, index) => (
          <StyledRow key={field.id}>
            <OCol xs={1} className="h-100">
              <StyledIndex>{index + 1}</StyledIndex>
            </OCol>
            <OCol xs={10} align="center">
              <ConfigurationFormMapper
                stepType={field.stepType}
                fieldName={fieldName}
                index={index}
              />
            </OCol>
            <OCol xs={1} align="center">
              <OButton
                dataAction={`partner_configuration_scf:botao:remover_${fieldName}`}
                dataLabel={`remover_${fieldName}`}
                onClick={() => remove(index)}
              >
                <OIcon category="fa" icon="fa-trash" />
              </OButton>
            </OCol>
          </StyledRow>
        ))}
      </div>
    </OField>
  );
};
