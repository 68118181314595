import { OToastManager } from "@maestro/core";
import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { configurableColumnsFormDefaultValues } from "./configurable-columns-form.schemas";

interface ConfigurableColumnsFormProps {
  mode: "add" | "edit";
  id?: string;
}

export const ConfigurableColumnsForm = (
  props: ConfigurableColumnsFormProps,
) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { handleSubmit } =
    useFormContext<typeof configurableColumnsFormDefaultValues>();

  const createConfigurableColumns = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const { mode, id } = props;

          const sortable = !!values.sortable.length;
          const filterable = !!values.filterable.length;

          if (mode === "add") {
            await service.hubCreditManager.createConfigurableGridColumns({
              ...values,
              sortable,
              filterable,
            });
          } else {
            await service.hubCreditManager.updateConfigurableGridColumns({
              ...values,
              id: Number(id),
              sortable,
              filterable,
            });
          }
          OToastManager.success("Coluna configurável salva com sucesso.");

          navigate(
            corporateRouter.routes.workflow.product.configurations
              .configurableColumns.path,
          );
        } catch {
          OToastManager.danger("Houve um erro ao criar a Coluna Configurável.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, props],
  );

  return (
    <FormTemplate
      actions={
        <LoadingButton
          loading={loading}
          type="info"
          onClick={createConfigurableColumns}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <form spellCheck="false">
          <ORow>
            <OCol>
              <ORFieldInput
                id="caption"
                name="caption"
                tag="text"
                label="Legenda"
                labelSize="md"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="dataField"
                name="dataField"
                tag="text"
                label="Data Field"
                labelSize="md"
              />
            </OCol>
            <OCol>
              <ORFieldInput
                id="dataType"
                name="dataType"
                tag="text"
                label="Data Type"
                labelSize="md"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ORFieldInput
                id="cellRenderType"
                name="cellRenderType"
                tag="text"
                label="Tipo de renderização"
                labelSize="md"
              />
            </OCol>
            <OCol>
              <ORFieldCheckboxGroup id="filterable" name="filterable">
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox size="xs" id="filterable-checkbox" value="1" />
                  <OLabel htmlFor="filterable-checkbox">Filtrável?</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol>
              <ORFieldCheckboxGroup id="sortable" name="sortable">
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox size="xs" id="sortable-checkbox" value="1" />
                  <OLabel htmlFor="sortable-checkbox">Exportação?</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol sm={6}>
              <ORFieldInput
                adaptHeight
                id="headerExportConfiguration"
                name="headerExportConfiguration"
                tag="textarea"
                label="Nome na planilha de extração"
                labelSize="md"
              />
            </OCol>
          </ORow>
        </form>
      </CardTemplate>
    </FormTemplate>
  );
};
