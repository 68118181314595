import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const contractTemplatesGrid = {
  datagrid: {
    noDataText: "Nenhum template cadastrado.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { dataField: "name", caption: "Nome" },
    { dataField: "file.fileKey", caption: "Identificação" },
    {
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              label: "Baixar",
              icon: { category: "fal", icon: "fa-download" },
              onClick: async () => {
                try {
                  const { data: base } =
                    await service.quickfin.downloadPublicContractTemplateFile(
                      data.id,
                    );

                  helpers.downloadBase64(base, data.file.originalName);
                } catch {
                  OToastManager.danger(
                    "Não foi possível fazer o download do arquivo",
                  );
                }
              },
            },
            {
              label: "Editar",
              icon: { category: "fal", icon: "fa-edit" },
              role: roles.recebiveis.product.contractTemplatesEditPage.role,
              route:
                corporateRouter.routes.recebiveis.product.contractTemplates.edit.path(
                  {
                    templateId: data.id,
                  },
                ),
            },
            {
              label: "Excluir",
              icon: { category: "fal", icon: "fa-trash" },
              onClick: () => {
                component.deleteRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.quickfin.getPublicContractTemplates>
  >["data"][number]
>;
