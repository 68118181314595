import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { useUngroupConfirmationModal } from "./ungroup-confirmation-modal.hook";

interface UngroupConfirmationModalProps {
  prefix: string;
  successCallback: () => void;
}

export const buildUngroupConfirmationModalId = (prefix: string) =>
  `ungroup-confirmation-modal-id-${prefix}`;

export const UngroupConfirmationModal = ({
  prefix,
  successCallback,
}: UngroupConfirmationModalProps) => {
  const modalId = buildUngroupConfirmationModalId(prefix);

  const { loading, ungroup } = useUngroupConfirmationModal(
    modalId,
    prefix,
    successCallback,
  );

  return (
    <OModal id={modalId} position="center" backdrop>
      <OModalHeader>
        <OTypography size="lg">Desfazer agrupamento</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ textAlign: "start" }}>
          Tem certeza que deseja desfazer o agrupamento do prefixo{" "}
          {masks.cnpj(prefix)}?
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_desagrupar:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton
            dataAction="confirmacao_desagrupar:botao:desagrupar"
            dataLabel="desagrupar"
            type="danger"
            loading={loading}
            onClick={() => ungroup()}
          >
            Desagrupar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
