import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useEnergyCompaniesInvite } from "./energy-invite.hook";

export const EnergyCompaniesInvite = () => {
  const { form, submit, loading } = useEnergyCompaniesInvite();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Convite"
          description="Preencha o formulário abaixo para enviar o convite."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="energia-convite:botao:enviar"
          dataLabel="enviar"
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol>
                <ORFieldInput
                  tag="cnpj"
                  id="taxId"
                  name="taxId"
                  dataAction="formulario-abertura-conta:input:cnpj"
                  dataLabel="cnpj"
                  label="CNPJ"
                  placeholder="Informar"
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="officialName"
                  name="officialName"
                  dataAction="formulario-abertura-conta:input:razao-social"
                  dataLabel="razao-social"
                  label="Razão Social"
                  placeholder="Informar"
                  disabled
                />
              </OCol>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="status"
                  name="status"
                  dataLabel="situacao-receita"
                  dataAction="formulario-abertura-conta:input:situacao-receita"
                  label="Situação Receita"
                  disabled
                />
              </OCol>
            </ORow>

            <ORow>
              <OCol>
                <ORFieldInput
                  tag="text"
                  id="email"
                  name="email"
                  dataAction="formulario-abertura-conta:input:e-mail"
                  dataLabel="e-mail"
                  label="E-mail"
                  placeholder="Informar"
                />
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
