import { modalManager, OToastManager } from "@maestro/core";
import {
  OConfirmationModal,
  OIcon,
  OTooltip,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { TooltipContent } from "components/tooltip-icon";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";

interface ConcludeButtonProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

const allowedPendencyStatuses: HubEnergy.EDiligenceStepPendencyStatus[] = [
  "PENDENTE_BPO",
  "PENDENTE_OPS",
  "CONCLUIDA",
];

export const ConcludeButton = ({ pendency }: ConcludeButtonProps) => {
  const modalId = `conclude-pendency-confirmation-modal-${pendency.id}`;

  const { stepAnalysis, setStepAnalysis } = useStepAnalysisContext();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.stepAnalysisConcludePendency,
  );

  const concludePendency = useCallback(async () => {
    const { success, response, error } = await callService({
      diligenceStepAnalysisId: stepAnalysis.id,
      diligenceStepPendencyId: pendency.id,
    });

    if (success) {
      OToastManager.success("Pendência concluída com sucesso");
      setStepAnalysis(response.data.response);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].errorMessage ??
          "Erro ao concluir a pendência",
      );
    }
  }, [callService, pendency.id, setStepAnalysis, stepAnalysis.id]);

  if (
    !allowedPendencyStatuses.includes(pendency.status) ||
    (stepAnalysis.level === "BPO" && pendency.status === "PENDENTE_OPS") // escalated
  )
    return null;

  return (
    <>
      <OTooltip floating position="top-left">
        <TooltipContent>
          {pendency.status !== "CONCLUIDA" ? "Concluir" : "Concluída"}
        </TooltipContent>
        <LoadingButton
          loading={loading}
          type={pendency.status === "CONCLUIDA" ? "success" : "dark"}
          outline={pendency.status !== "CONCLUIDA"}
          style={{
            pointerEvents: pendency.status === "CONCLUIDA" ? "none" : "unset",
          }}
          onClick={() => modalManager.show(modalId)}
        >
          <OIcon category="fal" icon="fa-check" />
        </LoadingButton>
      </OTooltip>
      <OConfirmationModal
        modalId={modalId}
        title="Concluir pendência?"
        divider={false}
        onConfirm={() => {
          concludePendency();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>Tem certeza que deseja concluir a pendência?</OTypography>
      </OConfirmationModal>
    </>
  );
};
