import { OToastManager } from "@maestro/core";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetSnakeCompanyByIdResponse } from "services/fidc/models/responses";

const buildEditFinancialContactLink = (
  data: GetSnakeCompanyByIdResponse["financials"][number],
) => {
  const { routes } = corporateRouter;

  const baseUrl = routes.fidc.product.interactionsCompany.details.edit.path;

  const stringifyedData = JSON.stringify(data);
  const searchParams = new URLSearchParams();
  searchParams.set("contact", stringifyedData);

  return baseUrl + "?" + searchParams.toString();
};

export const interactionFinancialsGrid = (
  fetchInteraction: () => Promise<void>,
): ODataGridGeneratorConfig<
  GetSnakeCompanyByIdResponse["financials"][number]
> => ({
  datagrid: {
    noDataText: "Nenhum contato financeiro encontrado.",
  },
  columns: [
    {
      role: roles.fidc.product.interactionsDetailsEditPage.role,
      cellRender: ({ data }) => (
        <StandardLink href={buildEditFinancialContactLink(data)}>
          Editar
        </StandardLink>
      ),
    },
    {
      dataField: "id",
      caption: "Id",
      visible: false,
      allowEditing: false,
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "email",
      caption: "Email",
    },
    {
      dataField: "cellPhoneNumber",
      caption: "Celular",
    },
    {
      type: "buttons",
      caption: "Remover",
      buttons: [
        {
          hint: "Apagar",
          icon: "fa fa-trash",
          onClick: async ({ row }) => {
            try {
              await service.fidc.removeFinancial(row?.data?.id ?? "");

              await fetchInteraction();

              OToastManager.success("Registro removido com sucesso.");
            } catch {
              OToastManager.danger("Um erro ocorreu ao remover o registro.");
            }
          },
        },
      ],
    },
  ],
});
