import dayjs from "dayjs";
import * as yup from "yup";
import { RegisterFeesForm } from "./register-fees.types";

export const registerFeesSacadoValidationSchema: yup.ObjectSchema<RegisterFeesForm> =
  yup.object({
    tax: yup
      .number()
      .required("Este campo é obrigatório")
      .moreThan(0, "Taxa deve ser maior que zero"),
    rebateType: yup.string().required("Este campo é obrigatório"),
    bankCode: yup.string().required("Este campo é obrigatório"),
    agency: yup.string().required("Este campo é obrigatório"),
    account: yup.string().required("Este campo é obrigatório"),
    paymentType: yup.string().required("Este campo é obrigatório"),
    paymentDay: yup.string().required("Este campo é obrigatório"),
    effectiveDate: yup.string().required("Este campo é obrigatório"),
    endEffectiveDate: yup
      .string()
      .nullable()
      .test({
        name: "should-be-greater-than-valid-from",
        message:
          "Fim de vigência deve ser uma data superior ao início de vigência",
        test: (value, context) => {
          return (
            !value || dayjs(value).isAfter(dayjs(context.parent.effectiveDate))
          );
        },
      }),
  });

export const registerFeesSacadoDefaultValues = {
  tax: 1,
  rebateType: "",
  bankCode: "",
  agency: "",
  account: "",
  paymentType: "",
  paymentDay: "",
  effectiveDate: "",
  endEffectiveDate: "",
};
