import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { hubEnergyEndpoints } from "services/hubenergy/hubenergy.endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import {
  configureHeaderFilterODataGroupBy,
  odataDateFilterWorkaround,
} from "../../../../utils";
import {
  analysisLogTypeMap,
  exportOnlyColumnNames,
  exportOnlyColumns,
} from "./analysis-logs.utils";

const buildDataSource = (
  paramsCallback?: (params: Record<string, string | undefined>) => void,
) =>
  dataSourceODataStoreGenerator<HubEnergy.DiligenceStepAnalysisLogODataResponse>(
    hubEnergyEndpoints.odata.diligenceStepAnalysisLogs,
    {
      odataStoreOptions: {
        beforeSend: ({ params }) => {
          odataDateFilterWorkaround(params);
          paramsCallback?.(params);
        },
        deserializeDates: false,
      },
    },
  );

export const dataSource = buildDataSource();

export const analysisLogsGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceStepAnalysisLogODataResponse> =
  {
    datagrid: {
      noDataText: "Nenhum log",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("energy-analysisLogs"),
      columnAutoWidth: false,
      dateSerializationFormat: null as any,
      exportGrid: {
        onExporting: ({ component }) => {
          component?.beginUpdate();
          exportOnlyColumnNames.forEach((columnName) => {
            component?.columnOption(columnName, "visible", true);
          });
        },
        onExported: ({ component }) => {
          exportOnlyColumnNames.forEach((columnName) => {
            component?.columnOption(columnName, "visible", false);
          });
          component?.endUpdate();
        },
      },
    },
    columns: [
      {
        dataField: "agentName",
        dataType: "string",
        caption: "Agente",
        width: "auto",
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceStepAnalysisLogODataResponse>()(
            ["agentName"],
            (adjustParams) => buildDataSource(adjustParams),
          ),
      },
      {
        dataField: "message",
        dataType: "string",
        caption: "Log",
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceStepAnalysisLogODataResponse>()(
            ["type"],
            (adjustParams) => buildDataSource(adjustParams),
            (type) => getValueFromMap(analysisLogTypeMap, type) ?? type,
          ),
        minWidth: 180,
      },
      {
        dataField: "analysisLevel",
        dataType: "string",
        caption: "Nível da análise",
        width: "auto",
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceStepAnalysisLogODataResponse>()(
            ["analysisLevel"],
            (adjustParams) => buildDataSource(adjustParams),
          ),
      },
      {
        dataField: "stepTypeLabel",
        dataType: "string",
        caption: "Etapa",
        width: "auto",
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceStepAnalysisLogODataResponse>()(
            ["stepTypeLabel"],
            (adjustParams) => buildDataSource(adjustParams),
          ),
      },
      {
        dataField: "consumerUnitOfficialName",
        dataType: "string",
        caption: "Unidade consumidora",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["consumerUnitOfficialName", "consumerUnitInstallationNumber"],
          "consumerUnitTaxId",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.consumerUnitOfficialName}
            subtexts={[
              masks.cpfCnpj(data.consumerUnitTaxId),
              `Nº instalação: ${data.consumerUnitInstallationNumber}`,
            ]}
          />
        ),
        allowSorting: true,
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceStepAnalysisLogODataResponse>()(
            [
              "consumerUnitOfficialName",
              "consumerUnitTaxId",
              "consumerUnitInstallationNumber",
            ],
            (adjustParams) => buildDataSource(adjustParams),
            (officialName, taxId, installationNumber) =>
              `${officialName} | ${masks.cpfCnpj(
                taxId,
              )} | ${installationNumber}`,
          ),
        width: "auto",
        allowExporting: false,
      },
      ...exportOnlyColumns,
      {
        dataField: "createdDate",
        dataType: "date",
        format: "shortDateShortTime",
        caption: "Data",
        // headerFilter: https://supportcenter.devexpress.com/ticket/details/t743084/datagrid-how-to-display-treeview-in-the-header-filter
        width: "auto",
        sortIndex: 0,
        sortOrder: "desc",
      },
    ],
  };
