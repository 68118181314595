import { client } from "../../client";
import { gatekeeperEndpoints } from "../gatekeeper.endpoints";
import {
  PatchProfilesByProfileNameBody,
  PatchProfilesDetailsUpdateBody,
  PostProfilesAddActsBody,
  PostProfilesAddRolesBody,
  PostProfilesBody,
  PostProfilesDetailsCreateBody,
  PostProfilesDetailsDeleteBody,
  PostProfilesGetActsBody,
  PostProfilesGetDetailsBody,
  PostProfilesRemoveActsBody,
  PostProfilesRemoveRolesBody,
  PostProfilesUpdateSubProfilesBody,
} from "./models/requests";
import {
  GetGetActsResponse,
  GetProfilesAllResponse,
  PatchProfilesByProfileNameResponse,
  PatchProfilesDetailsUpdateResponse,
  PostProfilesDetailsCreateResponse,
  PostProfilesDetailsDeleteResponse,
  PostProfilesGetActsResponse,
  PostProfilesGetDetailsResponse,
  PostProfilesResponse,
} from "./models/responses";

export const gatekeeperProfilesService = {
  createNewProfile(body: PostProfilesBody) {
    return client.post<PostProfilesResponse>(
      gatekeeperEndpoints.profiles.endpoint,
      body,
    );
  },
  editProfile(profileName: string, body: PatchProfilesByProfileNameBody) {
    return client.patch<PatchProfilesByProfileNameResponse>(
      gatekeeperEndpoints.profiles.byProfileName(profileName),
      body,
    );
  },
  getAllProfiles() {
    return client.get<GetProfilesAllResponse>(gatekeeperEndpoints.profiles.all);
  },
  getProfilesDetails(body: PostProfilesGetDetailsBody) {
    return client.post<PostProfilesGetDetailsResponse>(
      gatekeeperEndpoints.profiles.getDetails,
      body,
    );
  },
  getProfileActs(body: PostProfilesGetActsBody) {
    return client.post<PostProfilesGetActsResponse>(
      gatekeeperEndpoints.profiles.getActs,
      body,
    );
  },
  addActsToProfile(body: PostProfilesAddActsBody) {
    return client.post(gatekeeperEndpoints.profiles.addActs, body);
  },
  removeActsFromProfile(body: PostProfilesRemoveActsBody) {
    return client.post(gatekeeperEndpoints.profiles.removeActs, body);
  },
  addProfileDescription(body: PostProfilesDetailsCreateBody) {
    return client.post<PostProfilesDetailsCreateResponse>(
      gatekeeperEndpoints.profiles.details.create,
      body,
    );
  },
  editProfileDescription(body: PatchProfilesDetailsUpdateBody) {
    return client.patch<PatchProfilesDetailsUpdateResponse>(
      gatekeeperEndpoints.profiles.details.update,
      body,
    );
  },
  deleteProfileDescription(body: PostProfilesDetailsDeleteBody) {
    return client.post<PostProfilesDetailsDeleteResponse>(
      gatekeeperEndpoints.profiles.details.delete,
      body,
    );
  },
  updateSubprofiles(body: PostProfilesUpdateSubProfilesBody) {
    return client.post(gatekeeperEndpoints.profiles.updateSubProfiles, body);
  },
  addProfileRoles(body: PostProfilesAddRolesBody) {
    return client.post(gatekeeperEndpoints.profiles.addRoles, body);
  },
  removeProfileRoles(body: PostProfilesRemoveRolesBody) {
    return client.post(gatekeeperEndpoints.profiles.removeRoles, body);
  },
  getActs() {
    return client.get<GetGetActsResponse>(gatekeeperEndpoints.getActs);
  },
};
