import { RoutesTreeNode } from "layout/global-menu/_compose/routes-menu/routes-menu.types";
import {
  buildRoutesTree,
  findCurrentNode,
} from "layout/global-menu/_compose/routes-menu/routes-menu.utils";
import { routes } from "routes/routes";

let routesTree: RoutesTreeNode;

const getRoutesTree = () => {
  if (routesTree) return routesTree;
  if (routes) routesTree = buildRoutesTree(routes);
  return routesTree;
};

const findParentWithDepth = (
  tree: RoutesTreeNode,
  depth: number,
): RoutesTreeNode => {
  if (tree.depth < depth)
    throw new Error("Passed node must have depth greater than target");

  if (tree.depth === depth) return tree;

  return findParentWithDepth(tree.parent, depth);
};

export const findProductLevelNode = () => {
  const currentNode = findCurrentNode(
    getRoutesTree(),
    window.location.pathname,
  );
  if (!currentNode) return undefined;
  const productNode = findParentWithDepth(currentNode, 1);
  return productNode;
};
