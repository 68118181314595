import styled from "styled-components";

interface PaginationButtonProps {
  selected: boolean;
}

export const PaginationButton = styled.div<PaginationButtonProps>`
  ${({ selected }) =>
    selected
      ? "background-color: var(--theme-tertiary); color: var(--theme-light);"
      : "background-color: var(--theme-light); color: var(--theme-dark);"};
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 12px;
  &:hover {
    ${({ selected }) =>
      selected
        ? "background-color: var(--theme-tertiary-hover);"
        : "background-color: var(--theme-light-hover);"};
  }
`;

export const PaginationDescription = styled.span`
  color: var(--theme-default);
  font-size: 13px;
`;
