import { OTooltip, OIcon } from "@maestro/react";
import { TooltipContent } from "./tooltip-icon.styles";

interface TooltipIconProps {
  content: React.ReactNode;
  maxWidth?: string;
}

export const TooltipIcon = ({
  content,
  maxWidth = "250px",
}: TooltipIconProps) => {
  return (
    <OTooltip maxWidth={maxWidth} floating>
      <TooltipContent>{content}</TooltipContent>
      <OIcon category="fal" icon="fa-info-circle" />
    </OTooltip>
  );
};
