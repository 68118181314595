import { OToastManager, modalManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services/service";

export const useUpdateStrategiesModal = (strategyId?: string) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    try {
      if (!strategyId) return;

      setLoading(true);

      await service.quickfin.getUpdateOnboardingTypeById(strategyId);

      modalManager.hide("update-strategies-modal");
    } catch {
      OToastManager.danger(
        "Ocorreu um erro para atualizar as estratégias relacionadas.",
      );
    } finally {
      setLoading(false);
    }
  }, [strategyId]);

  return {
    loading,
    onConfirm,
  };
};
