import { OButton } from "@maestro/react";
import { useRelocate } from "../../relocate.context";

type NextStepButtonProps = Omit<
  React.ComponentProps<typeof OButton>,
  "dataAction" | "dataLabel"
>;

export const NextStepButton = (props: NextStepButtonProps) => {
  const { onClick } = props;

  const { stepNumber, nextStep } = useRelocate();

  return (
    <OButton
      dataAction={`realocacao_step_${stepNumber}:botao:proximo`}
      dataLabel="proximo"
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        nextStep();
      }}
    />
  );
};
