import styled from "styled-components";

export const FavoriteEmptyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  transition: transform ease 0.2s;

  &:hover {
    transform: rotate(72deg);
  }
`;
