import { MappedStatus } from "./status-maps.types";

export const diligenceStepStatusMap = {
  CRIADO: {
    type: "info",
    text: "Criado",
  },
  AGUARDANDO_APROVACAO_DILIGENCIA: {
    type: "warning",
    text: "Aguardando aprovação da diligência",
  },
  AGUARDANDO_DEPENDENCIA: { type: "info", text: "Aguardando dependência" },
  AGUARDANDO_APROVACAO_ETAPA: {
    type: "warning",
    text: "Aguardando aprovação da etapa",
  },
  EM_ANDAMENTO: { type: "info", text: "Em andamento pelo cliente" },
  EM_ANALISE: { type: "warning", text: "Em análise pelo Backoffice" },
  AGUARDANDO_DISTRIBUIDORA: { type: "info", text: "Aguardando distribuidora" },
  PENDENTE_AJUSTE: { type: "info", text: "Pendente ajuste pelo cliente" },
  CONCLUIDO: { type: "success", text: "Concluído" },
  CANCELADO: { type: "danger", text: "Cancelado" },
} satisfies Record<HubEnergy.EDiligenceStepStatus, MappedStatus>;
