import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { Eligibility } from "../pages/elegibilidade";
import { GatekeeperAdvisorsPage } from "../pages/gatekeeper/advisors";
import { AdminUpdateProfileChangePage } from "../pages/gatekeeper/analise-de-solicitacoes/[requestId]/[changeId]/admin-update-profile-change.page";
import { AdminProfileRequestPage } from "../pages/gatekeeper/analise-de-solicitacoes/[requestId]/admin-profile-request.page";
import { AdminProfilesRequestsPage } from "../pages/gatekeeper/analise-de-solicitacoes/admin-profiles-requests.page";
import { UpdateProfileChangePage } from "../pages/gatekeeper/gerenciamento-de-perfis/[requestId]/[changeId]/update-profile-change.page";
import { AddProfileChangePage } from "../pages/gatekeeper/gerenciamento-de-perfis/[requestId]/adicionar/add-profile-change.page";
import { ProfileRequestPage } from "../pages/gatekeeper/gerenciamento-de-perfis/[requestId]/profile-request.page";
import { CreateProfileRequestPage } from "../pages/gatekeeper/gerenciamento-de-perfis/criar/create-profile-request.page";
import { ProfilesManagementPage } from "../pages/gatekeeper/gerenciamento-de-perfis/profiles-management.page";
import { GatekeeperProfiles } from "../pages/gatekeeper/profiles";
import { GatekeeperProfilesAdd } from "../pages/gatekeeper/profiles/adicionar";
import { GatekeeperProfilesDetails } from "../pages/gatekeeper/profiles/detalhes";
import { GatekeeperProfilesDetailsAdd } from "../pages/gatekeeper/profiles/detalhes/adicionar";
import { GatekeeperProfilesGeneralInformation } from "../pages/gatekeeper/profiles/informacoes-gerais";
import { GatekeeperProfilesActsMapping } from "../pages/gatekeeper/profiles/mapeamento-atos";
import { GatekeeperProfilesRoles } from "../pages/gatekeeper/profiles/roles";
import { GatekeeperProfilesSubProfiles } from "../pages/gatekeeper/profiles/sub-profiles/sub-profiles.page";
import { GatekeeperRoles } from "../pages/gatekeeper/roles";
import { GatekeeperRolesAdd } from "../pages/gatekeeper/roles/adicionar";
import { GatekeeperRolesDetails } from "../pages/gatekeeper/roles/detalhes";
import { GatekeeperSystems } from "../pages/gatekeeper/sistemas";
import { GatekeeperSystemsAdd } from "../pages/gatekeeper/sistemas/adicionar";
import { GatekeeperSystemsDetails } from "../pages/gatekeeper/sistemas/detalhes";
import { GatekeeperUsers } from "../pages/gatekeeper/usuarios";
import { CompanyUserProfiles } from "../pages/gatekeeper/usuarios/[taxId]/profiles-empresa";
import { UserProfiles } from "../pages/gatekeeper/usuarios/[taxId]/profiles-usuario";
import { GatekeeperBlockedUsers } from "../pages/gatekeeper/usuarios_bloqueados";
import { ManagementDdis } from "../pages/gestao-ddis";
import { Desks } from "../pages/mesas";
import { ManageDeskMigrations } from "../pages/migracoes-segmento";
import { DeskMigrationDetails } from "../pages/migracoes-segmento/[migrationId]";
import { PowersMonitoring } from "../pages/monitoramento-poderes";
import { GuarantorsMonitoring } from "../pages/monitoramento/avalista";
import { EloasMonitoring } from "../pages/monitoramento/procuracoes-eletronicas";
import { LoasMonitoring } from "../pages/monitoramento/procuracoes-fisicas";
import { EmailTemplatePage } from "../pages/onboarding/configuracao-de-convite/email-template";
import { AddEmailTemplatePage } from "../pages/onboarding/configuracao-de-convite/email-template/adicionar";
import { UpdateEmailTemplatePage } from "../pages/onboarding/configuracao-de-convite/email-template/editar";
import { InviteTypePage } from "../pages/onboarding/configuracao-de-convite/tipos-convite";
import { AddInviteTypePage } from "../pages/onboarding/configuracao-de-convite/tipos-convite/adicionar/add-invite-type.page";
import { UpdateInviteTypePage } from "../pages/onboarding/configuracao-de-convite/tipos-convite/editar";
import { OnboardingDomainConfigs } from "../pages/onboarding/configuracao-de-dominio";
import { DomainConfigsScf } from "../pages/onboarding/configuracao-de-dominio-scf/configuracao-de-parceiro";
import { AddPartnerConfigurationScf } from "../pages/onboarding/configuracao-de-dominio-scf/configuracao-de-parceiro/adicionar";
import { EditPartnerConfigurationScf } from "../pages/onboarding/configuracao-de-dominio-scf/configuracao-de-parceiro/editar";
import { ScfOfficers } from "../pages/onboarding/configuracao-de-dominio-scf/officers";
import { AddOfficer } from "../pages/onboarding/configuracao-de-dominio-scf/officers/adicionar";
import { Partners } from "../pages/onboarding/configuracao-de-dominio-scf/parceiros";
import { AddPartner } from "../pages/onboarding/configuracao-de-dominio-scf/parceiros/adicionar";
import { OnboardingDomainConfigAdd } from "../pages/onboarding/configuracao-de-dominio/adicionar";
import { OnboardingDomainConfigEdit } from "../pages/onboarding/configuracao-de-dominio/editar";
import { UpdateContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato/[uuid]/update-contract-file-configuration.page";
import { RegisterContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato/adicionar/register-contract-file-configuration.page";
import { ContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato/contract-file-configuration.page";
import { RegisterDeskDocumentConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-documentos/adicionar/register-desk-document-config.page";
import { DeskDocumentConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-documentos/desk-document-config.page";
import { RegisterDeskDocumentConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/documentos/adicionar/register-desk-document-config-relationship.page";
import { DeskDocumentConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/documentos/desk-document-config-relationship.page";
import { UpdateDeskConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/editar/update-desk-config.page";
import { UpdateDeskAccountConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/[uuid]/editar/update-desk-account-config.page";
import { DeskAccountConfigProductsServices } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/[uuid]/produtos-servicos/desk-account-config-products-services.page";
import { UpdateDeskAccountConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/[uuid]/relacionamentos/[relationshipUuid]/update-desk-account-config-relationship.page";
import { RegisterDeskAccountConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/[uuid]/relacionamentos/adicionar/register-desk-account-config-relationship.page";
import { DeskAccountConfigRelationships } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/[uuid]/relacionamentos/desk-account-config-relationships.page";
import { RegisterDeskAccountConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/adicionar/register-desk-account-config.page";
import { DeskAccountConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/informacao-de-conta/desk-account-config.page";
import { UpdateDeskConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/mesas/[deskCode]/update-desk-config-relationship.page";
import { RegisterDeskConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/mesas/adicionar/register-desk-config-relationship.page";
import { DeskConfigRelationshipPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/mesas/desk-config-relationship.page";
import { DeskNotificationsPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/notificacoes/desk-notifications.page";
import { UpdateDeskConfigOfficerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/officers/[uuid]/update-desk-config-officer.page";
import { RegisterDeskConfigOfficerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/officers/adicionar/register-desk-config-officer.page";
import { DeskConfigOfficerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/[configIdentifier]/officers/desk-config-officer.page";
import { RegisterDeskConfigPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/adicionar/register-desk.page";
import { DeskConfigsPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-mesa/desk-configs.page";
import { UpdateDeskPartnerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-parceiros/[uuid]/update-desk-partner.page";
import { RegisterDeskPartnerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-parceiros/adicionar/register-desk-partner.page";
import { DeskPartnerPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-de-parceiros/desk-partner.page";
import { UpdateDefaultContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato/[uuid]/update-default-contract-file-configuration.page";
import { RegisterDefaultContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato/adicionar/register-default-contract-file-configuration.page";
import { DefaultContractFileConfigurationPage } from "../pages/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato/default-contract-file-configuration.page";
import { UpdateDeskAccountInfoPage } from "../pages/onboarding/configuracao-de-workflows/informacoes-de-conta/[uuid]/update-desk-account-info.page";
import { RegisterDeskAccountInfoPage } from "../pages/onboarding/configuracao-de-workflows/informacoes-de-conta/adicionar/register-desk-account-info.page";
import { DeskAccountInfoPage } from "../pages/onboarding/configuracao-de-workflows/informacoes-de-conta/desk-account-info.page";
import { UpdateDeskPage } from "../pages/onboarding/configuracao-de-workflows/mesas/[deskCode]/update-desk.page";
import { RegisterDeskPage } from "../pages/onboarding/configuracao-de-workflows/mesas/adicionar/register-desk.page";
import { DesksPage } from "../pages/onboarding/configuracao-de-workflows/mesas/desks.page";
import { OfficersPage } from "../pages/onboarding/configuracao-de-workflows/officers/officers.page";
import { UploadOnboardingFilesPage } from "../pages/onboarding/configuracao-de-workflows/upload/upload-onboarding-files.page";
import { Contingency } from "../pages/onboarding/contingencias";
import { SemaphoreStep } from "../pages/onboarding/semaforo/configuracao/steps";
import { SemaphoreStepAdd } from "../pages/onboarding/semaforo/configuracao/steps/adicionar";
import { SemaphoreStepDetails } from "../pages/onboarding/semaforo/configuracao/steps/detalhes";
import { SemaphoreTemplate } from "../pages/onboarding/semaforo/configuracao/template";
import { SemaphoreTemplateAdd } from "../pages/onboarding/semaforo/configuracao/template/adicionar";
import { SemaphoreConfigurationDetails } from "../pages/onboarding/semaforo/configuracao/template/detalhes";
import { SemaphoreConsults } from "../pages/onboarding/semaforo/consultas";
import { SemaphoreConsultDetails } from "../pages/onboarding/semaforo/consultas/detalhes";
import { BatchSemaphoreConsult } from "../pages/onboarding/semaforo/consultas/lote/batch-semaphore-consult.page";
import { RavenNotifications } from "../pages/raven-notificacoes/gestao";
import { RavenNotificationAdd } from "../pages/raven-notificacoes/gestao/adicionar";
import { RavenNotificationDetails } from "../pages/raven-notificacoes/gestao/detalhes";
import { RavenNotificationConfigurationAdd } from "../pages/raven-notificacoes/gestao/raven-notification-configuration/add-notification-configuration";
import { RavenNotificationConfigurationsDetails } from "../pages/raven-notificacoes/gestao/raven-notification-configuration/detalhes/detail.page";
import { Jobs } from "../pages/raven-notificacoes/jobs";
import { RavenTemplates } from "../pages/raven-templates";
import { RavenTemplatesAdd } from "../pages/raven-templates/adicionar";
import { RavenTemplatesDetails } from "../pages/raven-templates/detalhes";
import { RegisterOperator } from "../pages/registrar-atendente";
import { AmlDisapproval } from "../pages/reprovacoes-aml";
import { Roadwalker } from "../pages/roadwalker";
import { SecurityAntifraudAnalysis } from "../pages/seguranca/analise-antifraude";
import { SecurityAntifraudAnalysisDetails } from "../pages/seguranca/analise-antifraude/detalhes";
import { SecurityBiometricsRelease } from "../pages/seguranca/liberacao-biometrica";
import { ClerkSystems } from "../pages/sistemas-clerk";
import { ClerkSystemsAdd } from "../pages/sistemas-clerk/adicionar";
import { ClerkSystemsDetails } from "../pages/sistemas-clerk/detalhes";
import { ferramentasRoles } from "../roles/ferramentas.roles";
import { ferramentasRouteParams } from "./ferramentas.route-params";

export const ferramentasRoutes = {
  product: {
    name: "",
    path: "/ferramentas/produto",
    element: <RedirectWithRoles />,
    children: {
      eligibility: {
        name: "Elegibilidade",
        path: "/ferramentas/produto/elegibilidade",
        role: ferramentasRoles.product.eligibilityPage.role,
        element: <Eligibility />,
      },
      amlDisapproval: {
        name: "Monitor de reprovações AML",
        path: "/ferramentas/produto/reprovacoes-aml",
        role: ferramentasRoles.product.amlDisapprovalPage.role,
        element: <AmlDisapproval />,
      },
      monitoring: {
        name: "Monitoramento avalista e procurações",
        path: "/ferramentas/produto/monitoramento",
        role: ferramentasRoles.product.MonitoringGuarantorAndLoaPage.role,
        hasSubItems: true,
        children: {
          guarantors: {
            name: "Monitoramento Avalistas",
            path: "/ferramentas/produto/monitoramento/avalista",
            role: ferramentasRoles.product.MonitoringGuarantorsPage.role,
            element: <GuarantorsMonitoring />,
          },
          letterOfAttorney: {
            name: "Monitoramento Proc Física",
            path: "/ferramentas/produto/monitoramento/procuracao-fisica",
            role: ferramentasRoles.product.MonitoringLoaPage.role,
            element: <LoasMonitoring />,
          },
          eletronicLetterOfAttorney: {
            name: "Monitoramento Proc Eletrônica",
            path: "/ferramentas/produto/monitoramento/procuracao-eletronica",
            role: ferramentasRoles.product.MonitoringElosPage.role,
            element: <EloasMonitoring />,
          },
        },
      },
      powersMonitoring: {
        name: "Monitoramento de poderes",
        path: "/ferramentas/produto/monitoramento-poderes",
        role: ferramentasRoles.product.powersMonitoringPage.role,
        element: <PowersMonitoring />,
      },
      managementDdis: {
        name: "Gestão de DDIs",
        path: "/ferramentas/produto/gestao-ddis",
        role: ferramentasRoles.product.ddiManagementPage.role,
        element: <ManagementDdis />,
      },
      gatekeeper: {
        name: "Gatekeeper",
        path: "/ferramentas/produto/gatekeeper",
        hasSubItems: true,
        children: {
          profiles: {
            name: "Profiles",
            path: "/ferramentas/produto/gatekeeper/profiles",
            role: ferramentasRoles.product.gatekeeperProfilesPage.role,
            element: <GatekeeperProfiles />,
            showChildren: true,
            children: {
              add: {
                name: "Adicionar",
                path: "/ferramentas/produto/gatekeeper/profiles/adicionar",
                role: ferramentasRoles.product.gatekeeperProfilesAddPage.role,
                element: <GatekeeperProfilesAdd />,
              },
              profile: {
                name: "Profile",
                path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}`,
                showChildren: true,
                children: {
                  generalInformation: {
                    name: "Informações Gerais",
                    path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/informacoes-gerais`,
                    role: ferramentasRoles.product
                      .gatekeeperProfilesGeneralInformationPage.role,
                    element: <GatekeeperProfilesGeneralInformation />,
                  },
                  roles: {
                    name: "Roles",
                    path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/roles`,
                    role: ferramentasRoles.product.gatekeeperProfilesRolesPage
                      .role,
                    element: <GatekeeperProfilesRoles />,
                  },
                  subProfiles: {
                    name: "SubProfiles",
                    path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/subProfiles`,
                    role: ferramentasRoles.product
                      .gatekeeperProfilesSubProfilesPage.role,
                    element: <GatekeeperProfilesSubProfiles />,
                  },
                  details: {
                    name: "Detalhes do profile",
                    path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/detalhes`,
                    role: ferramentasRoles.product.gatekeeperProfilesDetailsPage
                      .role,
                    element: <GatekeeperProfilesDetails />,
                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/detalhes/adicionar`,
                        role: ferramentasRoles.product
                          .gatekeeperProfilesDetailsPage.role,
                        element: <GatekeeperProfilesDetailsAdd />,
                      },
                    },
                  },
                  actsMapping: {
                    name: "Mapeamento de atos",
                    path: `/ferramentas/produto/gatekeeper/profiles/:${ferramentasRouteParams.uuid}/mapeamento-atos`,
                    role: ferramentasRoles.product
                      .gatekeeperProfilesActsMappingPage.role,
                    element: <GatekeeperProfilesActsMapping />,
                  },
                },
              },
            },
          },
          roles: {
            name: "Roles",
            path: "/ferramentas/produto/gatekeeper/roles",
            role: ferramentasRoles.product.gatekeeperRolesPage.role,
            element: <GatekeeperRoles />,
            children: {
              add: {
                name: "Adicionar",
                path: "/ferramentas/produto/gatekeeper/roles/adicionar",
                role: ferramentasRoles.product.gatekeeperRolesAddPage.role,
                element: <GatekeeperRolesAdd />,
              },
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/gatekeeper/roles/detalhes`,
                role: ferramentasRoles.product.gatekeeperRolesDetailsPage.role,
                element: <GatekeeperRolesDetails />,
              },
            },
          },
          users: {
            name: "Usuários",
            path: "/ferramentas/produto/gatekeeper/usuarios",
            role: ferramentasRoles.product.gatekeeperUsersPage.role,
            element: <GatekeeperUsers />,
            children: {
              byTaxId: {
                name: "Usuários",
                path: `/ferramentas/produto/gatekeeper/usuarios/:${ferramentasRouteParams.taxId}`,
                role: ferramentasRoles.product.gatekeeperUsersPage.role,
                showChildren: true,
                children: {
                  userProfiles: {
                    name: "Profiles do usuário",
                    path: `/ferramentas/produto/gatekeeper/usuarios/:${ferramentasRouteParams.taxId}/profiles-usuario`,
                    role: ferramentasRoles.product
                      .gatekeeperUsersUserProfilesPage.role,
                    element: <UserProfiles />,
                  },
                  companyUserProfiles: {
                    name: "Profiles da empresa",
                    path: `/ferramentas/produto/gatekeeper/usuarios/:${ferramentasRouteParams.taxId}/profiles-empresa`,
                    role: ferramentasRoles.product
                      .gatekeeperUsersCompanyUserProfilesPage.role,
                    element: <CompanyUserProfiles />,
                  },
                },
              },
            },
          },
          users_blocked: {
            name: "Usuários Bloqueados",
            path: "/ferramentas/produto/gatekeeper/users_blocked",
            role: ferramentasRoles.product.gatekeeperUsersPage.role,
            element: <GatekeeperBlockedUsers />,
          },
          systems: {
            name: "Sistemas",
            path: "/ferramentas/produto/gatekeeper/sistemas",
            role: ferramentasRoles.product.gatekeeperSystemsPage.role,
            element: <GatekeeperSystems />,
            children: {
              add: {
                name: "Adicionar",
                path: "/ferramentas/produto/gatekeeper/sistemas/adicionar",
                role: ferramentasRoles.product.gatekeeperSystemsAddPage.role,
                element: <GatekeeperSystemsAdd />,
              },
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/gatekeeper/sistemas/detalhes`,
                role: ferramentasRoles.product.gatekeeperSystemsDetailsPage
                  .role,
                element: <GatekeeperSystemsDetails />,
              },
            },
          },
          profilesManagement: {
            name: "Gerenciamento de perfis",
            path: "/ferramentas/produto/gatekeeper/gerenciamento-de-perfis",
            role: ferramentasRoles.product.gatekeeperProfilesManagementPage
              .role,
            element: <ProfilesManagementPage />,
            children: {
              create: {
                name: "Criar",
                path: "/ferramentas/produto/gatekeeper/gerenciamento-de-perfis/criar",
                role: ferramentasRoles.product.gatekeeperProfilesManagementPage
                  .role,
                element: <CreateProfileRequestPage />,
              },
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/gatekeeper/gerenciamento-de-perfis/:${ferramentasRouteParams.requestId}`,
                role: ferramentasRoles.product.gatekeeperProfilesManagementPage
                  .role,
                element: <ProfileRequestPage />,
                children: {
                  add: {
                    name: "Adicionar",
                    path: `/ferramentas/produto/gatekeeper/gerenciamento-de-perfis/:${ferramentasRouteParams.requestId}/adicionar`,
                    element: <AddProfileChangePage />,
                    role: ferramentasRoles.product
                      .gatekeeperProfilesManagementPage.role,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/ferramentas/produto/gatekeeper/gerenciamento-de-perfis/:${ferramentasRouteParams.requestId}/:${ferramentasRouteParams.changeId}`,
                    element: <UpdateProfileChangePage />,
                    role: ferramentasRoles.product
                      .gatekeeperProfilesManagementPage.role,
                  },
                },
              },
            },
          },
          adminProfilesManagement: {
            name: "Análise de solicitações",
            path: "/ferramentas/produto/gatekeeper/analise-de-solicitacoes",
            element: <AdminProfilesRequestsPage />,
            role: ferramentasRoles.product.gatekeeperAdminProfilesManagementPage
              .role,
            children: {
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/gatekeeper/analise-de-solicitacoes/:${ferramentasRouteParams.requestId}`,
                role: ferramentasRoles.product
                  .gatekeeperAdminProfilesManagementPage.role,
                element: <AdminProfileRequestPage />,
                children: {
                  update: {
                    name: "Reprovar",
                    path: `/ferramentas/produto/gatekeeper/analise-de-solicitacoes/:${ferramentasRouteParams.requestId}/:${ferramentasRouteParams.changeId}`,
                    element: <AdminUpdateProfileChangePage />,
                    role: ferramentasRoles.product
                      .gatekeeperAdminProfilesManagementPage.role,
                  },
                },
              },
            },
          },
          advisors: {
            name: "Assessores",
            path: "/ferramentas/produto/gatekeeper/advisors",
            role: ferramentasRoles.product.gatekeeperAdvisorsPage.role,
            element: <GatekeeperAdvisorsPage />,
          },
        },
      },
      clerk: {
        name: "Sistemas Clerk",
        path: "/ferramentas/produto/clerk",
        role: ferramentasRoles.product.clerkPage.role,
        element: <ClerkSystems />,
        children: {
          add: {
            name: "Adicionar",
            path: "/ferramentas/produto/clerk/usuarios/adicionar",
            role: ferramentasRoles.product.clerkAddPage.role,
            element: <ClerkSystemsAdd />,
          },
          details: {
            name: "Detalhes",
            path: `/ferramentas/produto/clerk/usuarios/detalhes`,
            role: ferramentasRoles.product.clerkDetailsPage.role,
            element: <ClerkSystemsDetails />,
          },
        },
      },

      raven: {
        name: "Raven",
        path: "/ferramentas/produto/raven",
        hasSubItems: true,
        children: {
          templates: {
            name: "Raven Templates",
            path: "/ferramentas/produto/raven/raven-templates",
            role: ferramentasRoles.product.ravenPage.role,
            element: <RavenTemplates />,
            children: {
              add: {
                name: "Adicionar",
                path: "/ferramentas/produto/raven/raven-templates/adicionar",
                role: ferramentasRoles.product.ravenAddPage.role,
                element: <RavenTemplatesAdd />,
              },
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/raven/raven-templates/detalhes`,
                role: ferramentasRoles.product.ravenDetailsPage.role,
                element: <RavenTemplatesDetails />,
              },
            },
          },
          notificacoes: {
            name: "Notificações",
            path: "/ferramentas/produto/raven",
            hasSubItems: true,
            children: {
              gestao: {
                name: "Gestão de notificações",
                path: "/ferramentas/produto/raven/raven-notificacoes/gestao",
                role: ferramentasRoles.product.ravenNotificationTypesPage.role,
                element: <RavenNotifications />,
                children: {
                  add: {
                    name: "Adicionar",
                    path: `/ferramentas/produto/raven/raven-notificacoes/gestao/tipo/adicionar`,
                    role: ferramentasRoles.product.ravenAddNotificationTypesPage
                      .role,
                    element: <RavenNotificationAdd />,
                  },
                  details: {
                    name: "Detalhes da notificação",
                    path: `/ferramentas/produto/raven/raven-notificacoes/gestao/tipo/:${ferramentasRouteParams.uuid}/detalhes`,
                    role: ferramentasRoles.product
                      .ravenNotificationTypesDetailsPage.role,
                    element: <RavenNotificationDetails />,
                  },
                  addConfig: {
                    name: "Adicionar configuração",
                    path: `/ferramentas/produto/raven/raven-notificacoes/gestao/tipo/:${ferramentasRouteParams.uuid}/configuracao/adicionar/`,
                    role: ferramentasRoles.product
                      .ravenAddNotificationConfigPage.role,
                    element: <RavenNotificationConfigurationAdd />,
                  },
                  configDetails: {
                    name: "Detalhes da configuração",
                    path: `/ferramentas/produto/raven/raven-notificacoes/gestao/configuracao/:${ferramentasRouteParams.configurationId}/detalhes`,
                    role: ferramentasRoles.product
                      .ravenNotificationConfigDetailsPage.role,
                    element: <RavenNotificationConfigurationsDetails />,
                  },
                },
              },
              jobs: {
                name: "Jobs",
                path: "/ferramentas/produto/raven/raven-notificacoes/jobs",
                role: ferramentasRoles.product.ravenJobsPage.role,
                element: <Jobs />,
              },
            },
          },
        },
      },
      desks: {
        name: "Mesas",
        path: "/ferramentas/produto/mesas",
        role: ferramentasRoles.product.desksPage.role,
        element: <Desks />,
      },
      registrarAtendente: {
        name: "Registrar atendente",
        path: "/ferramentas/produto/registrar-atendente",
        element: <RegisterOperator />,
        role: ferramentasRoles.product.registrarAtendentePage.role,
      },
      manageDeskMigrations: {
        name: "Gerenciar migrações de segmento",
        path: "/ferramentas/produto/migracoes-segmento",
        element: <ManageDeskMigrations />,
        role: ferramentasRoles.product.manageDeskMigrationsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/ferramentas/produto/migracoes-segmento/:${ferramentasRouteParams.migrationId}`,
            element: <DeskMigrationDetails />,
            role: ferramentasRoles.product.manageDeskMigrationsDetailsPage.role,
          },
        },
      },
      roadwalker: {
        name: "Roadwalker",
        path: "/ferramentas/produto/roadwalker",
        element: <Roadwalker />,
        role: ferramentasRoles.product.roadwalker.role,
      },
      onboarding: {
        name: "Onboarding",
        path: "/ferramentas/produto/onboarding",
        hasSubItems: true,
        children: {
          domainConfig: {
            name: "Configuração de Domínio",
            path: "/ferramentas/produto/onboarding/configuracao-dominio",
            element: <OnboardingDomainConfigs />,
            role: ferramentasRoles.product.onboardingDomainConfigPage.role,
            children: {
              add: {
                name: "Adicionar",
                path: "/ferramentas/produto/onboarding/configuracao-dominio/adicionar",
                role: ferramentasRoles.product.onboardingDomainConfigAddPage
                  .role,
                element: <OnboardingDomainConfigAdd />,
              },
              edit: {
                name: "Editar",
                path: "/ferramentas/produto/onboarding/configuracao-dominio/editar",
                role: ferramentasRoles.product.onboardingDomainConfigEditPage
                  .role,
                element: <OnboardingDomainConfigEdit />,
              },
            },
          },
          domainConfigScf: {
            name: "Configuração de Domínio Scf",
            path: "/ferramentas/produto/onboarding/configuracao-dominio-scf",
            hasSubItems: true,
            children: {
              partnerConfiguration: {
                name: "Configurações de parceiro",
                path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/configuracao-parceiro",
                element: <DomainConfigsScf />,
                role: ferramentasRoles.product.onboardingDomainConfigScfPage
                  .role,
                children: {
                  add: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/configuracao-parceiro/adicionar",
                    role: ferramentasRoles.product
                      .onboardingDomainConfigScfAddPage.role,
                    element: <AddPartnerConfigurationScf />,
                  },
                  edit: {
                    name: "Informações gerais",
                    path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/configuracao-parceiro/editar",
                    role: ferramentasRoles.product
                      .onboardingDomainConfigScfEditPage.role,
                    element: <EditPartnerConfigurationScf />,
                  },
                },
              },
              partners: {
                name: "Parceiros",
                path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/parceiros",
                role: ferramentasRoles.product.onboardingPartnersPage.role,
                element: <Partners />,
                children: {
                  add: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/parceiros/adicionar",
                    role: ferramentasRoles.product.onboardingPartnerUpsertPage
                      .role,
                    element: <AddPartner />,
                  },
                },
              },
              officers: {
                name: "Officers",
                path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/officers",
                role: ferramentasRoles.product.onboardingScfOfficersPage.role,
                element: <ScfOfficers />,
                children: {
                  add: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-dominio-scf/officers/adicionar",
                    role: ferramentasRoles.product
                      .onboardingScfOfficerUpsertPage.role,
                    element: <AddOfficer />,
                  },
                },
              },
            },
          },
          semaphore: {
            name: "Semáforo",
            path: "/ferramentas/produto/onboarding/semaforo",
            hasSubItems: true,
            children: {
              batchConsult: {
                name: "Consulta em Lote",
                path: "/ferramentas/produto/onboarding/semaforo/consulta-em-lote",
                role: ferramentasRoles.product.entitySemaphorePage.role,
                element: <BatchSemaphoreConsult />,
              },
              consults: {
                name: "Consultas Executadas",
                path: "/ferramentas/produto/onboarding/semaforo/consultas",
                role: ferramentasRoles.product.entitySemaphorePage.role,
                element: <SemaphoreConsults />,
                children: {
                  details: {
                    name: "Consulta Específica",
                    path: `/ferramentas/produto/onboarding/semaforo/consultas/:${ferramentasRouteParams.uuid}/detalhes`,
                    role: ferramentasRoles.product.entitySemaphoreDetailsPage
                      .role,
                    element: <SemaphoreConsultDetails />,
                  },
                },
              },
              configuration: {
                name: "Configurações",
                path: "/ferramentas/produto/onboarding/semaforo/configuracao",
                role: ferramentasRoles.product.semaphoreConfigurationPage.role,
                hasSubItems: true,
                children: {
                  template: {
                    name: "Templates",
                    path: "/ferramentas/produto/onboarding/semaforo/configuracao/templates",
                    role: ferramentasRoles.product.semaphoreConfigTemplatePage
                      .role,
                    element: <SemaphoreTemplate />,
                    children: {
                      add: {
                        name: "Adicionar configuração de semáforo",
                        path: "/ferramentas/produto/onboarding/semaforo/configuracao/templates/adicionar",
                        role: ferramentasRoles.product
                          .semaphoreConfigTemplateAdd.role,
                        element: <SemaphoreTemplateAdd />,
                      },
                      details: {
                        name: "Detalhes",
                        path: `/ferramentas/produto/onboarding/semaforo/configuracao/templates/:${ferramentasRouteParams.uuid}/detalhes`,
                        role: ferramentasRoles.product
                          .semaphoreConfigTemplateDetails.role,
                        element: <SemaphoreConfigurationDetails />,
                      },
                    },
                  },
                  steps: {
                    name: "Steps",
                    path: "/ferramentas/produto/onboarding/semaforo/configuracao/steps",
                    role: ferramentasRoles.product.semaphoreConfigStepPage.role,
                    element: <SemaphoreStep />,
                    children: {
                      add: {
                        name: "Adicionar step de semáforo",
                        path: "/ferramentas/produto/onboarding/semaforo/configuracao/steps/adicionar",
                        role: ferramentasRoles.product.semaphoreConfigStepAdd
                          .role,
                        element: <SemaphoreStepAdd />,
                      },
                      details: {
                        name: "Detalhes",
                        path: `/ferramentas/produto/onboarding/semaforo/configuracao/steps/:${ferramentasRouteParams.uuid}/detalhes`,
                        role: ferramentasRoles.product
                          .semaphoreConfigStepDetails.role,
                        element: <SemaphoreStepDetails />,
                      },
                    },
                  },
                },
              },
            },
          },
          workflowsConfigs: {
            name: "Configurações de workflows",
            path: "/ferramentas/produto/onboarding/configuracao-de-workflows",
            hasSubItems: true,
            children: {
              desks: {
                name: "Mesas",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/mesas",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsDesksPage.role,
                element: <DesksPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/mesas/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDesksRegisterPage.role,
                    element: <RegisterDeskPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/mesas/:${ferramentasRouteParams.deskCode}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDesksDetailsPage.role,
                    element: <UpdateDeskPage />,
                  },
                },
              },
              desksConfigs: {
                name: "Configurações de mesa",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsConfigsPage.role,
                element: <DeskConfigsPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDeskConfigsRegisterPage.role,
                    element: <RegisterDeskConfigPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDeskConfigsDetailsPage.role,
                    showChildren: true,
                    children: {
                      edit: {
                        name: "Editar",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/editar`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsDeskConfigsDetailsPage
                          .role,
                        element: <UpdateDeskConfigPage />,
                      },
                      officers: {
                        name: "Officers",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/officers`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsDeskOfficersConfigPage
                          .role,
                        element: <DeskConfigOfficerPage />,
                        children: {
                          register: {
                            name: "Adicionar",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/officers/adicionar`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsDeskOfficersConfigPage
                              .role,
                            element: <RegisterDeskConfigOfficerPage />,
                          },
                          details: {
                            name: "Detalhes",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/officers/:${ferramentasRouteParams.uuid}`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsDeskOfficersConfigPage
                              .role,
                            element: <UpdateDeskConfigOfficerPage />,
                          },
                        },
                      },
                      deskConfigRelationship: {
                        name: "Mesas",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/mesas`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsDeskConfigRelationshipPage
                          .role,
                        element: <DeskConfigRelationshipPage />,
                        children: {
                          register: {
                            name: "Adicionar",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/mesas/adicionar`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsDeskConfigRelationshipRegisterPage
                              .role,
                            element: <RegisterDeskConfigRelationshipPage />,
                          },
                          details: {
                            name: "Detalhes",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/mesas/:${ferramentasRouteParams.uuid}`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsDeskConfigRelationshipDetailsPage
                              .role,
                            element: <UpdateDeskConfigRelationshipPage />,
                          },
                        },
                      },
                      deskDocumentConfigRelationship: {
                        name: "Documentos",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/documentos`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsDeskDocumentConfigRelationshipPage
                          .role,
                        element: <DeskDocumentConfigRelationshipPage />,
                        children: {
                          register: {
                            name: "Adicionar",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/documentos/adicionar`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsDeskDocumentConfigRelationshipRegisterPage
                              .role,
                            element: (
                              <RegisterDeskDocumentConfigRelationshipPage />
                            ),
                          },
                        },
                      },
                      deskAccountConfig: {
                        name: "Informações de conta",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsAccountConfigPage.role,
                        element: <DeskAccountConfigPage />,
                        children: {
                          register: {
                            name: "Adicionar",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/adicionar`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsAccountConfigRegisterPage
                              .role,
                            element: <RegisterDeskAccountConfigPage />,
                          },
                          details: {
                            name: "Detalhes",
                            path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}`,
                            role: ferramentasRoles.product
                              .onboardingWorkflowsConfigsAccountConfigDetailsPage
                              .role,
                            showChildren: true,
                            children: {
                              edit: {
                                name: "Editar",
                                path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}/editar`,
                                role: ferramentasRoles.product
                                  .onboardingWorkflowsConfigsAccountConfigDetailsPage
                                  .role,
                                element: <UpdateDeskAccountConfigPage />,
                              },
                              productsServices: {
                                name: "Produtos/Serviços",
                                path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}/produtos-servicos`,
                                role: ferramentasRoles.product
                                  .onboardingWorkflowsConfigsAccountConfigDetailsPage
                                  .role,
                                element: <DeskAccountConfigProductsServices />,
                              },
                              relationships: {
                                name: "Relacionamentos",
                                path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}/relacionamentos`,
                                role: ferramentasRoles.product
                                  .onboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage
                                  .role,
                                element: <DeskAccountConfigRelationships />,
                                children: {
                                  register: {
                                    name: "Adicionar",
                                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}/relacionamentos/adicionar`,
                                    role: ferramentasRoles.product
                                      .onboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage
                                      .role,
                                    element: (
                                      <RegisterDeskAccountConfigRelationshipPage />
                                    ),
                                  },
                                  update: {
                                    name: "Editar",
                                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/informacoes-de-conta/:${ferramentasRouteParams.uuid}/relacionamentos/:${ferramentasRouteParams.relationshipUuid}`,
                                    role: ferramentasRoles.product
                                      .onboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage
                                      .role,
                                    element: (
                                      <UpdateDeskAccountConfigRelationshipPage />
                                    ),
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      deskNotifications: {
                        name: "Notificações",
                        path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-mesa/:${ferramentasRouteParams.configIdentifier}/notificacoes`,
                        role: ferramentasRoles.product
                          .onboardingWorkflowsConfigsDeskNotificationsPage.role,
                        element: <DeskNotificationsPage />,
                      },
                    },
                  },
                },
              },
              deskPartnerConfig: {
                name: "Configurações de mesa com parceiros",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-parceiros",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsPartnerConfigPage.role,
                element: <DeskPartnerPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-parceiros/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsPartnerConfigRegisterPage.role,
                    element: <RegisterDeskPartnerPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-parceiros/:${ferramentasRouteParams.uuid}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsPartnerConfigDetailsPage.role,
                    element: <UpdateDeskPartnerPage />,
                  },
                },
              },
              deskDocumentConfig: {
                name: "Configuração de documentos",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-documentos",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsDeskDocumentConfigPage.role,
                element: <DeskDocumentConfigPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-de-documentos/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDeskDocumentConfigRegisterPage
                      .role,
                    element: <RegisterDeskDocumentConfigPage />,
                  },
                },
              },
              deskAccountInfo: {
                name: "Informações de conta",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/informacoes-de-conta",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsAccountInfoPage.role,
                element: <DeskAccountInfoPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/informacoes-de-conta/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsAccountInfoRegisterPage.role,
                    element: <RegisterDeskAccountInfoPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/informacoes-de-conta/:${ferramentasRouteParams.uuid}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsAccountInfoDetailsPage.role,
                    element: <UpdateDeskAccountInfoPage />,
                  },
                },
              },
              contractFileConfiguration: {
                name: "Configurações de arquivo do contrato",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsContractFileConfigurationPage.role,
                element: <ContractFileConfigurationPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsContractFileConfigurationRegisterPage
                      .role,
                    element: <RegisterContractFileConfigurationPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-arquivo-contrato/:${ferramentasRouteParams.uuid}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsContractFileConfigurationDetailsPage
                      .role,
                    element: <UpdateContractFileConfigurationPage />,
                  },
                },
              },
              defaultContractFileConfiguration: {
                name: "Configurações padrão de arquivo do contrato",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato",
                role: ferramentasRoles.product
                  .onboardingWorkflowsConfigsDefaultContractFileConfigurationPage
                  .role,
                element: <DefaultContractFileConfigurationPage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: "/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato/adicionar",
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDefaultContractFileConfigurationRegisterPage
                      .role,
                    element: <RegisterDefaultContractFileConfigurationPage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-workflows/configuracao-padrao-arquivo-contrato/:${ferramentasRouteParams.uuid}`,
                    role: ferramentasRoles.product
                      .onboardingWorkflowsConfigsDefaultContractFileConfigurationDetailsPage
                      .role,
                    element: <UpdateDefaultContractFileConfigurationPage />,
                  },
                },
              },
              uploadFiles: {
                name: "Upload de arquivos",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/upload",
                role: ferramentasRoles.product
                  .onboardingUploadOnboardingFilesPage.role,
                element: <UploadOnboardingFilesPage />,
              },
              officers: {
                name: "Officers",
                path: "/ferramentas/produto/onboarding/configuracao-de-workflows/officers",
                role: ferramentasRoles.product.onboardingOfficersPage.role,
                element: <OfficersPage />,
              },
            },
          },
          contingency: {
            name: "Contingências Relationships",
            path: "/ferramentas/produto/onboarding/contingencia",
            element: <Contingency />,
          },
          inviteConfigs: {
            name: "Configurações de convite",
            path: "/ferramentas/produto/onboarding/configuracao-de-convite",
            hasSubItems: true,
            children: {
              emailTemplates: {
                name: "Templates de e-mail",
                path: "/ferramentas/produto/onboarding/configuracao-de-convite/templates-email",
                role: ferramentasRoles.product
                  .onboardingInviteConfigsEmailTemplatesPage.role,
                element: <EmailTemplatePage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: `/ferramentas/produto/onboarding/configuracao-de-convite/templates-email/adicionar`,
                    role: ferramentasRoles.product
                      .onboardingInviteConfigsEmailTemplatesAddPage.role,
                    element: <AddEmailTemplatePage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-convite/templates-email/:${ferramentasRouteParams.id}`,
                    role: ferramentasRoles.product
                      .onboardingInviteConfigsEmailTemplatesDetailsPage.role,
                    element: <UpdateEmailTemplatePage />,
                  },
                },
              },
              inviteTypes: {
                name: "Tipos de convite",
                path: "/ferramentas/produto/onboarding/configuracao-de-convite/tipos-convite",
                role: ferramentasRoles.product
                  .onboardingInviteConfigsInviteTypesPage.role,
                element: <InviteTypePage />,
                children: {
                  register: {
                    name: "Adicionar",
                    path: `/ferramentas/produto/onboarding/configuracao-de-convite/tipos-convite/adicionar`,
                    role: ferramentasRoles.product
                      .onboardingInviteConfigsInviteTypesAddPage.role,
                    element: <AddInviteTypePage />,
                  },
                  details: {
                    name: "Detalhes",
                    path: `/ferramentas/produto/onboarding/configuracao-de-convite/tipos-convite/:${ferramentasRouteParams.id}`,
                    role: ferramentasRoles.product
                      .onboardingInviteConfigsInviteTypesDetailsPage.role,
                    element: <UpdateInviteTypePage />,
                  },
                },
              },
            },
          },
        },
      },
      security: {
        name: "Segurança e Antifraude",
        path: "/ferramentas/produto/seguranca",
        hasSubItems: true,
        children: {
          biometricsRelease: {
            name: "Liberação Biométrica",
            path: "/ferramentas/produto/seguranca/liberacao-biometrica",
            role: ferramentasRoles.product.securityBiometricsReleasePage.role,
            element: <SecurityBiometricsRelease />,
          },
          antifraudAnalysis: {
            name: "Análise Antifraude",
            path: "/ferramentas/produto/seguranca/analise-antifraude",
            role: ferramentasRoles.product.securityAntifraudAnalysisPage.role,
            element: <SecurityAntifraudAnalysis />,
            children: {
              details: {
                name: "Detalhes",
                path: `/ferramentas/produto/seguranca/analise-antifraude/detalhes`,
                role: ferramentasRoles.product
                  .securityAntifraudAnalysisDetailsPage.role,
                element: <SecurityAntifraudAnalysisDetails />,
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
