import styled from "styled-components";

export const StyledPre = styled.pre`
  border: 1px solid var(--theme-dark-10);
  border-radius: 0.25rem;

  background-color: #00000009;

  padding: 0.5rem;
  margin: 0.5rem 0;

  max-height: 600px;
  overflow: auto;
`;
