import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { AdminBeneficiaryAnalyses } from "services/hubfx/odata/models/responses/get-admin-beneficiary-analyses-o-data.response";

export const exchangeOdataBeneficiaryAnalysesGrid = (
  setSelectedOrders: (orders: AdminBeneficiaryAnalyses[]) => void,
): ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getBeneficiaryAnalyses>
    >["data"]["value"]
  >[number]
> => ({
  datagrid: {
    noDataText: "Nenhum beneficiário encontrado",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar beneficiários.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminBeneficiaryAnalysesOData,
    ),
    onSelectionChanged: (evt) => setSelectedOrders(evt.selectedRowsData),
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) =>
        !!data.Id && (
          <DetailsLink
            href={`${corporateRouter.routes.cambio.product.beneficiaryAnalyses.details.path(
              { id: data.Id },
            )}`}
          />
        ),
    },
    {
      dataField: "Id",
      caption: "Id",
      dataType: "number",
    },
    {
      dataField: "CreatedDate",
      caption: "Dt. Criação",
      dataType: "date",
    },
    {
      caption: "Nome",
      dataField: "BeneficiaryBankName",
    },
    {
      caption: "Status",
      dataField: "IsActive",
      cellRender: ({ data }) => (data.IsActive ? "Ativa" : "Inativa"),
    },
    {
      caption: "Mensagem de erro",
      dataField: "ErrorMessage",
    },
    {
      caption: "Contraparte",
      dataField: "CounterpartyName",
    },
    {
      caption: "Moeda",
      dataField: "BeneficiaryAccountCurrencyId",
    },
    {
      caption: "País do banco principal",
      dataField: "BeneficiaryCountryId",
    },
    {
      caption: "Nome do banco principal",
      dataField: "BeneficiaryBankName",
    },
    {
      caption: "Swift do banco principal",
      dataField: "BeneficiaryBankSwift",
    },
    {
      caption: "Nome do banco intermediário",
      dataField: "BeneficiaryIntermediateBankName",
    },
    {
      caption: "Swift do banco intermediário",
      dataField: "BeneficiaryIntermediateBankSwift",
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
