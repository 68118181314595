import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  WorkflowProdutoConfigurableColumnsById,
  WorkflowProdutoConfigurableColumnsRouteQueryParms,
} from "../../../../../routes/workflow.route-params";
import { ConfigurableColumnsForm } from "../configurable-columns-form.component";
import {
  configurableColumnsFormDefaultValues,
  configurableColumnsFormValidationSchema,
} from "../configurable-columns-form.schemas";

export const EditConfigurableColumnsPage = () => {
  const { id } = useParams<WorkflowProdutoConfigurableColumnsById>();

  if (!id) throw new Error("No id");
  const { value, hasError } =
    useParseFromSearchParams<WorkflowProdutoConfigurableColumnsRouteQueryParms>(
      "data",
    );
  const form = useForm({
    resolver: yupResolver(configurableColumnsFormValidationSchema),
    defaultValues: configurableColumnsFormDefaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    value &&
      reset({
        ...value,
        filterable: value.filterable ? ["1"] : [],
        sortable: value.sortable ? ["1"] : [],
      });
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate
          pageTitle={<PageTitle title="Editar Colunas Configuráveis" />}
        >
          <FormProvider {...form}>
            <ConfigurableColumnsForm mode="edit" id={id} />
          </FormProvider>
        </FormTemplate>
      )}
    />
  );
};
