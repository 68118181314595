import { OButton, modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { ComponentProperty } from "../../../component-info.types";
import { COMPONENT_PROPERTY_MODAL_ID } from "../component-properties-modal/component-properties-modal.component";
import { componentPropertiesGrid } from "./component-properties-grid.grid";

interface ComponentDetailsProps {
  id: string;
  component: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponentsById>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getComponentsById>
      >["data"]
    >
  >;
  setSelectedProperty: React.Dispatch<
    React.SetStateAction<ComponentProperty | undefined>
  >;
}

export const ComponentProperties = ({
  id,
  component,
  setSelectedProperty,
}: ComponentDetailsProps) => {
  const gridRef = useGridRef();

  const { value: componentValue } = component;
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Propriedades" />}
      gridRef={gridRef}
      actions={
        <OButton
          dataAction="editar_componente:botao:adicionar_propriedade"
          dataLabel="adicionar_propriedade"
          onClick={() => {
            setSelectedProperty(undefined);
            modalManager.show(COMPONENT_PROPERTY_MODAL_ID);
          }}
        >
          Adicionar
        </OButton>
      }
    >
      <ODataGridGenerator
        grid={componentPropertiesGrid(id, component, setSelectedProperty)}
        dataSource={componentValue?.properties ?? []}
      />
    </GridTemplate>
  );
};
