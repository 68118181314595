import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";

export const loanConfigurationVariableGrid = {
  datagrid: {
    noDataText: "Nenhuma variável de configuração encontrada.",
    dataSource: dataSourceCustomStoreGenerator(
      () => {
        return service.hubLoan.getConfigurationVariableAll().catch(() => {
          throw new Error("Erro ao buscar variáveis de configuração.");
        });
      },
      {
        customStoreOptions: {
          update: async (row, updatedValues) => {
            try {
              OToastManager.info("Atualizando...");
              await service.hubLoan.updateConfigurationVariable({
                id: row.id,
                newValue: updatedValues.variableValue,
              });
              OToastManager.destroy();
              OToastManager.success("Atualizado com sucesso");
            } catch {
              OToastManager.danger("Não foi possivel atualizar");
            }
          },
        },
      },
    ),
    editing: {
      allowUpdating: true,
    },
    headerFilter: { visible: true, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "variableName",
      allowEditing: false,
    },
    {
      dataField: "variableValue",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.getConfigurationVariableAll>
    >["data"]
  >[number]
>;
