import { CardVariant, IAccount, IInvoice } from "../cards.types";

export interface IAlreadyApprovedProps {
  selectedCompany: string;
  cardAccountData?: IAccount;
  invoicesData?: Array<IInvoice>;
  loadCards: Function;
}

export interface ICardSectionStylesProps {
  maxWidth?: string;
}

export const MapperCardStatus: Record<string, string> = {
  NORMAL: "Ativo",
  BLOCKED: "Bloqueado",
  CANCELED: "Cancelado",
  CREATED: "Aguardando",
  DAMAGED: "Danificado",
  EXPIRED: "Expirado",
  FRAUD: "Bloqueado por fraude",
  LOST: "Bloqueado por perda",
  WARNING: "Em observação",
  ROBBED: "Bloqueado por roubo",
  REQUESTED: "Solicitado",
  SLEEPING: "Bloqueado temporariamente",
  BLOCKED_INTERNAL: "Bloqueado por prevenção",
  BLOCKED_BY_PAYMENT_DEFAULT: "Bloqueado temporariamente por inadimplência",
  DESTROYED: "Entrega cancelada",
};

export const MapperCardModalityAndType: Record<string, string> = {
  CREDIT: "Crédito",
  DEBIT: "Débito",
  MULTIPLE: "Crédito e Débito",
  PHYSICAL: "Físico",
  VIRTUAL: "Virtual",
};

export const MapperCardVariant: Record<CardVariant, string> = {
  [CardVariant.Black]: "Black",
  [CardVariant.Platinum]: "Platinum",
  [CardVariant.Business]: "Business",
  [CardVariant.Gold]: "Business",
  [CardVariant.BusinessDebit]: "Business",
};
