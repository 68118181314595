import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { service } from "services";

export const useGatekeeperUserProfiles = (taxId: string) => {
  const {
    callService: _getUserByCpf,
    loading: getUserLoading,
    hasError: getUserHasError,
    value: userData,
  } = useServiceCall(service.gatekeeper.getUserByCpf);

  const getUser = useCallback(async () => {
    const { success } = await _getUserByCpf({ cpf: taxId });
    if (!success) OToastManager.danger("Não foi possível buscar o usuário");
  }, [_getUserByCpf, taxId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return {
    getUserLoading,
    getUserHasError,
    userData,
    getUser,
  };
};
