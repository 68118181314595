import { safeLocalStorage } from "utils/storage";
import { themeEvents } from "./theme.event";
import { ThemeMode } from "./types/theme";

export class Theme {
  private static storageKey = "corporate-portal:theme-settings";

  private static store = safeLocalStorage;

  static storage = {
    get: () => this.store.getItem(this.storageKey) as ThemeMode | null,
    set: (theme: ThemeMode) => this.store.setItem(this.storageKey, theme),
    remove: () => this.store.removeItem(this.storageKey),
  };

  _value: ThemeMode = "light";

  constructor() {
    const systemPreference = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";

    const storedTheme = Theme.storage.get();

    this.value = storedTheme ?? systemPreference ?? "light";
  }

  get value() {
    return this._value;
  }

  set value(newValue) {
    this._value = newValue;

    document.documentElement.setAttribute("data-theme", newValue);
    Theme.storage.set(newValue);

    themeEvents.dispatch(this);
  }
}

export const theme = new Theme();
