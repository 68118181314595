import { FormProvider } from "react-hook-form";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInputDate,
  modalManager,
} from "@maestro/react";
import { useConciliacaoDetailsModal } from "./conciliacao-details-modal.hook";

export const conciliacaoDetailsModalId = "conciliacao-details-modal-client";

export const ConciliacaoDetailsModal = () => {
  const { form, clear, submit } = useConciliacaoDetailsModal();
  const { hide } = modalManager;

  return (
    <OModal id={conciliacaoDetailsModalId}>
      <OModalHeader closeButton divider>
        <span className="d-flex gap-2">Escolha uma data de antecipação</span>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <ORFieldInputDate
              id="dataVencimento"
              name="dataVencimento"
              label="Data de vencimento"
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton type="dark" outline onClick={clear}>
            Limpar
          </OButton>
          <OButton
            onClick={() => {
              submit();
              hide(conciliacaoDetailsModalId);
            }}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
