import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import type { RowPreparedEvent } from "devextreme/ui/data_grid";
import {
  diligenceStatusMap,
  diligenceStepStatusMap,
  getStatusFromMap,
} from "../../utils";

export const highlightNearMigrationDate = ({
  data,
  rowElement,
  rowType,
}: RowPreparedEvent<HubEnergy.ConsumerUnitODataResponse>) => {
  if (
    rowType === "data" &&
    data?.migrationDate &&
    data.tradeStatus !== "CONCLUIDO" &&
    data.diligenceStatus !== "CONCLUIDA"
  ) {
    const today = dayjs();
    const migrationDate = dayjs(data.migrationDate);

    if (migrationDate.isBefore(today)) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-danger-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-danger-light-inverse)",
      );
    } else if (migrationDate.diff(today, "days") < 30) {
      rowElement.style.setProperty(
        "background-color",
        "var(--theme-warning-light-80)",
      );
      rowElement.style.setProperty(
        "color",
        "var(--theme-warning-light-inverse)",
      );
    }
  }
};

export const calculateMigrationStatusCellValue = (
  diligenceStatus: HubEnergy.EDiligenceStatus | null,
  diligenceStepsStatuses: string[] | null,
) => {
  if (!diligenceStatus) return null;

  if (!diligenceStepsStatuses?.length)
    return getStatusFromMap(diligenceStatusMap, diligenceStatus).text;

  return diligenceStepsStatuses
    ?.map((stepStatus) => {
      const [name, status] = stepStatus.split(" ");
      const mappedStatus = getStatusFromMap(diligenceStepStatusMap, status);
      return `${name} - ${mappedStatus.text}`;
    })
    .join("; ");
};

// grid export
const consumerUnitNameColumn = "consumerUnitNameColumn";
const consumerUnitOfficialNameColumn = "consumerUnitOfficialNameColumn";
const consumerUnitTaxIdColumn = "consumerUnitTaxIdColumn";
const installationNumberColumn = "installationNumberColumn";
const distributorNameColumn = "distributorNameColumn";
const distributorOfficialNameColumn = "distributorOfficialNameColumn";
const distributorTaxIdColumn = "distributorTaxIdColumn";

export const exportOnlyColumnNames = [
  consumerUnitNameColumn,
  consumerUnitOfficialNameColumn,
  consumerUnitTaxIdColumn,
  installationNumberColumn,
  distributorOfficialNameColumn,
  distributorNameColumn,
  distributorTaxIdColumn,
];

export const exportOnlyColumns = [
  {
    visible: false,
    dataField: "consumerUnitName",
    dataType: "string",
    name: consumerUnitNameColumn,
    caption: "Unidade consumidora - Nome fantasia",
  },
  {
    visible: false,
    dataField: "consumerUnitOfficialName",
    dataType: "string",
    name: consumerUnitOfficialNameColumn,
    caption: "Unidade consumidora - Razão social",
  },
  {
    visible: false,
    dataField: "consumerUnitTaxId",
    dataType: "string",
    name: consumerUnitTaxIdColumn,
    caption: "Unidade consumidora - CNPJ",
  },
  {
    visible: false,
    dataField: "installationNumber",
    dataType: "string",
    name: installationNumberColumn,
    caption: "Unidade consumidora - Número de instalação",
  },
  {
    visible: false,
    dataField: "distributorOfficialName",
    dataType: "string",
    name: distributorOfficialNameColumn,
    caption: "Distribuidora - Razão social",
  },
  {
    visible: false,
    dataField: "distributorName",
    dataType: "string",
    name: distributorNameColumn,
    caption: "Distribuidora - Nome fantasia",
  },

  {
    visible: false,
    dataField: "distributorTaxId",
    dataType: "string",
    name: distributorTaxIdColumn,
    caption: "Distribuidora - CNPJ",
  },
] satisfies ODataGridGeneratorConfig<HubEnergy.ConsumerUnitODataResponse>["columns"];
