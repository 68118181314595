import { OCard, OCardBody, OTypography } from "@maestro/react";
import { IsPublicBadge, NewPendencyBadge } from "../badges";
import { ActionsArea, NotesArea } from "./_compose";

interface PendencyTileProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

export const PendencyTile = ({ pendency }: PendencyTileProps) => {
  return (
    <OCard className="position-relative">
      <div
        className="badge-container position-absolute d-flex"
        style={{ transform: "translate(0, -50%)" }}
      >
        <NewPendencyBadge pendency={pendency} />
        <IsPublicBadge isPublic={pendency.isPublic} />
      </div>
      <OCardBody>
        <div className="d-flex justify-content-between gap-3">
          <div className="pt-2">
            <p>
              <OTypography tag="span" weight="700">
                {pendency.type.label}
              </OTypography>
              {pendency.type.description && (
                <OTypography tag="span">
                  : {pendency.type.description}
                </OTypography>
              )}
            </p>
            {pendency.message && (
              <p>
                <OTypography tag="span">
                  Mensagem: {pendency.message}
                </OTypography>
              </p>
            )}
            {!!pendency.notes?.filter((n) => !n.content.startsWith("<html>"))
              .length && <NotesArea pendency={pendency} />}
          </div>
          <ActionsArea pendency={pendency} />
        </div>
      </OCardBody>
    </OCard>
  );
};
