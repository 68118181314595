/**
 * Generates a regular expression that matches the string, ignoring possible spaces
 */
export const buildTextHighlight = (text = "") => {
  const reString = text?.split(" ").join("\\s?");
  return new RegExp(reString.trim(), "i");
};

/**
 * Generates a regular expression that matches a tax id, ignoring possible dots, dashes or slashed
 */
export const buildTaxIdHighlight = (value = "") => {
  const reString = value?.replace(/\D/g, "").split("").join("(\\.|-|/)?");
  return new RegExp(reString.trim(), "i");
};
