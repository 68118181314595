import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { exchangeBeneficiariesDetailsGenerator } from "pages/cambio/components/beneficiary/beneficiaries.details";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { CambioBeneficiariosByIdRouteParams } from "../../../../../routes/cambio.route-params";

export const ExchangeProductBeneficiariesDetailsPage = () => {
  const { id } = useParams<CambioBeneficiariosByIdRouteParams>();

  if (!id) throw new Error("No id");

  const {
    value: limitDetails,
    callService: getBeneficiaryDetails,
    hasError: hasErrorLimitDetails,
    loading: loadingLimitDetails,
  } = useServiceCall(service.hubFx.getBeneficiaryDetails);

  useEffect(() => {
    getBeneficiaryDetails(id);
  }, [getBeneficiaryDetails, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do beneficiário" />}>
      <DetailsCard
        loading={loadingLimitDetails}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar detalhes do beneficiário."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getBeneficiaryDetails(id)} />
          </ErrorComponent>
        }
        hasError={hasErrorLimitDetails}
        value={limitDetails}
        fields={exchangeBeneficiariesDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
