import { useUnsafeUser } from "contexts/user";
import { useCallback } from "react";
import { Role } from "roles/roles.types";
import { isNotProd } from "utils/environments";
import { customerServiceRoles } from "./customer-service-roles";
import { isCustomerServiceMock, isFullMock } from "./mock-roles-storage";
import { checkRole } from "./use-roles.utils";

export const useRoles = () => {
  const { user } = useUnsafeUser();

  const hasRole = useCallback(
    (role: Role | undefined) => {
      if (isNotProd()) {
        if (isFullMock()) {
          return true;
        }
        if (isCustomerServiceMock()) {
          return checkRole(role, customerServiceRoles);
        }
      }
      return checkRole(role, user?.roles);
    },
    [user?.roles],
  );

  return {
    hasRole,
  };
};
