import { OToastManager } from "@maestro/core";
import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { LoadingButton } from "components/loading-button";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { LoanData } from "../loan-summary.types";

interface RepublishTradeProps {
  loanData: LoanData;
}

export const ApproveDisbursement = ({ loanData }: RepublishTradeProps) => {
  const [loading, setLoading] = useState(false);
  const form = useForm<{ checkbox: string[] }>({
    defaultValues: {
      checkbox: [],
    },
  });

  const { handleSubmit } = form;

  const handleApproveDisbursement = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          if (values.checkbox.length) {
            localStorage.setItem(
              "approve-disbursement-modal",
              "doNotShowAgain",
            );
          }
          setLoading(true);

          await service.hubLoan.approveDisbursement({
            loanId: loanData.id,
          });

          OToastManager.success("O empréstimo foi enviado para desembolso.");
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao tentar aprovar o desembolso.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, loanData.id],
  );

  return (
    <>
      {loanData?.status === "AprovacaoEnvioTrade" &&
        (localStorage.getItem("approve-disbursement-modal") ? (
          <LoadingButton
            loading={loading}
            type="success"
            onClick={handleApproveDisbursement}
          >
            Aprovar desembolso
          </LoadingButton>
        ) : (
          <ButtonWithConfirmation
            confirmationModal={{
              title:
                "Você confirma a aprovação de desembolso do empréstimo atual?",
              loading,
            }}
            onConfirm={() => !loading && handleApproveDisbursement()}
            modalBody={
              <FormProvider {...form}>
                <ORFieldCheckboxGroup
                  description=""
                  id="checkbox"
                  name="checkbox"
                >
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <OCheckbox id="chk1" value="true" />
                    <OLabel htmlFor="chk1">Não perguntar novamente</OLabel>
                  </div>
                </ORFieldCheckboxGroup>
              </FormProvider>
            }
            type="success"
          >
            Aprovar desembolso
          </ButtonWithConfirmation>
        ))}
    </>
  );
};
