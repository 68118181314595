import { modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { Job } from "../../jobs.types";
import { stopJobs } from "../../jobs.utils";
import { ConfirmationModal, stopMultipleModalId } from "../confirmation-modal";

interface BatchStopButtonProps {
  selectedJobs: Job[];
  reload: () => void;
}

export const BatchStopButton = ({
  selectedJobs,
  reload,
}: BatchStopButtonProps) => (
  <>
    <IconButton
      dataAction="antecipacao_cartao_jobs:botao:parar_selecionados"
      dataLabel="parar_selecionados"
      icon={{ category: "far", icon: "fa-ban" }}
      type="danger"
      disabled={selectedJobs.length < 1}
      onClick={() => modalManager.show(stopMultipleModalId)}
    >
      Parar selecionados
    </IconButton>
    <ConfirmationModal
      id={stopMultipleModalId}
      onConfirm={async () =>
        stopJobs(selectedJobs.map((job) => job.id)).then(() => reload())
      }
      type="stop"
      jobsAmount={selectedJobs.length}
    />
  </>
);
