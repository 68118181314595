import { OToastManager, modalManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { StatusBadge } from "components/status-badge";
import React from "react";
import { service } from "services";
import { ContractTemplateError } from "services/hubloan/models/responses/error/post-api-admin-company-upload-contract-template-error.response";
import { LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID } from "../../../../components/loan-contracts-templates/loan-contracts-templates-upload-error-modal.component";
import {
  ContractGeneratorFormValues,
  contractGeneratorStatusMap,
} from "../contract-generator.types";
import { ContractTemplatesUpload } from "./contract-template-upload.component";
import { StyledSpan } from "./contract-templates.styles";

type ContractGeneratorTemplate =
  ContractGeneratorFormValues["contractTemplates"][number];

export const contractsTemplatesGrid = (
  initialContractTemplates: ContractGeneratorTemplate[],
  setErrors: React.Dispatch<
    React.SetStateAction<ContractTemplateError[] | undefined>
  >,
  onChange: (contractTemplates: ContractGeneratorTemplate[]) => void,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum template de contrato.",
      dataSource: initialContractTemplates ?? [],
      editing: {
        mode: "form",
        confirmDelete: true,
      },
      onRowInserted: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowValidating: (e) => {
        if (!e.newData?.template && !e.oldData?.template) {
          e.errorText = "Necessário anexar um documento";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "type",
        caption: "Tipo de contrato",
        formItem: {
          visible: false,
        },
      },
      {
        dataField: "template",
        caption: "Guardar fileKey do template",
        visible: false,
        showEditorAlways: false,
        formItem: { visible: false },
      },
      {
        dataField: "template-edit",
        caption: "Template",
        width: 500,
        editCellRender: (prop) => <ContractTemplatesUpload {...prop} />,
        cellRender: ({ data }) => (
          <StyledSpan title={data.template}>{data.template}</StyledSpan>
        ),
      },
      {
        dataField: "status",
        caption: "Status",
        formItem: { visible: false },
        cellRender: ({ data }) => (
          <StatusBadge {...contractGeneratorStatusMap[data.status]} />
        ),
      },
      {
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex }) => {
          return (
            <DataGridAction
              actions={[
                {
                  label: "Visualizar erros",
                  icon: { category: "orq", icon: "orq-visibility-on" },
                  visible: !!data.contractState?.hasError,
                  onClick: () => {
                    setErrors(data.contractState?.errors);
                    modalManager.show(
                      LOAN_CONTRACTS_TEMPLATES_UPLOAD_ERROR_MODAL_ID,
                    );
                  },
                },
                {
                  label: "Download template",
                  icon: { category: "orq", icon: "orq-download" },
                  onClick: async () => {
                    try {
                      OToastManager.info("Enviando arquivo...");

                      const { data: blob } = await service.hubLoan.download({
                        key: data.template,
                      });

                      helpers.downloadBlobFile(
                        data.template.split("/").at(-1) ?? "template",
                        blob,
                      );
                      OToastManager.destroy();
                    } catch (err) {
                      OToastManager.danger("Não foi possível baixar arquivo.");
                    }
                  },
                },
                {
                  label: "Download renderizado",
                  icon: { category: "orq", icon: "orq-download" },
                  visible: ["RENDERIZADO", "RENDERIZADO_COM_ERRO"].includes(
                    data.status,
                  ),
                  onClick: async () => {
                    try {
                      if (
                        !["RENDERIZADO", "RENDERIZADO_COM_ERRO"].includes(
                          data.status,
                        ) ||
                        !data.renderedTemplate
                      )
                        return;

                      OToastManager.info("Enviando arquivo...");

                      const { data: blob } = await service.hubLoan.download({
                        key: data.renderedTemplate,
                      });

                      helpers.downloadBlobFile(
                        data.template.split("/").at(-1) ?? "template",
                        blob,
                      );
                      OToastManager.destroy();
                    } catch (err) {
                      OToastManager.danger("Não foi possível baixar arquivo.");
                    }
                  },
                },
                {
                  label: "Remover",
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  onClick: () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    ContractGeneratorTemplate & { "template-edit"?: unknown }
  >;
};
