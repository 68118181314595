import * as yup from "yup";

export type FormType = "CAC" | "SEGURO" | "CDB";

export type CollateralFormValues = {
  collateralType: string;
  identification?: string | null;
  amount?: number | null;
  guaranteedPercentage?: number | null;
  expireDate?: string | null;
  lockDisbursement?: string | null;
  description?: string | null;
  hasOptional: boolean;
  formalizedContractNumber?: string | null;
  manufacturer?: string | null;
  manufacturingYear?: string | null;
  unitPrice?: number | null;
  formalized: boolean;
  finame?: string | null;
  storageLocation?: string | null;
  totalPremiumAmount?: number | null;
};

export const addCollateralSchema: yup.ObjectSchema<CollateralFormValues> =
  yup.object({
    collateralType: yup.string().required("Campo obrigatório"),
    identification: yup.string().when("collateralType", {
      is: (value: string) => ["SEGURO", "CDB", "EQUIPAMENTO"].includes(value),
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    unitPrice: yup.number().when("collateralType", {
      is: (value: string) => value === "EQUIPAMENTO",
      then: (s: yup.NumberSchema) => s.min(0.01, "Campo obrigatório"),
      otherwise: (s: yup.NumberSchema) => s.nullable(),
    }),
    manufacturingYear: yup.string().when("collateralType", {
      is: (value: string) => value === "EQUIPAMENTO",
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    manufacturer: yup.string().when("collateralType", {
      is: (value: string) => value === "EQUIPAMENTO",
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    amount: yup.number().when("collateralType", {
      is: (value: string) =>
        ["SEGURO", "CAC", "NOTA_PROMISSORIA"].includes(value),
      then: (s: yup.NumberSchema) => s.min(0.01, "Campo obrigatório"),
      otherwise: (s: yup.NumberSchema) => s.nullable(),
    }),
    guaranteedPercentage: yup.number().when("collateralType", {
      is: (value: string) => ["SEGURO", "NOTA_PROMISSORIA"].includes(value),
      then: (s: yup.NumberSchema) => s.min(0.0001, "Campo obrigatório"),
      otherwise: (s: yup.NumberSchema) => s.nullable(),
    }),
    totalPremiumAmount: yup.number().when("collateralType", {
      is: (value: string) => value === "SEGURO",
      then: (s: yup.NumberSchema) => s.min(0.0001, "Campo obrigatório"),
      otherwise: (s: yup.NumberSchema) => s.nullable(),
    }),
    expireDate: yup.string().when("collateralType", {
      is: (value: string) => ["SEGURO", "CAC"].includes(value),
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    lockDisbursement: yup.string().when("collateralType", {
      is: (value: string) => value === "CDB",
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    formalizedContractNumber: yup
      .string()
      .nullable()
      .test("is-required", "Campo obrigatório", (value, schema) => {
        const { collateralType, formalized } = schema.parent;
        return (
          !["CAC", "NOTA_PROMISSORIA"].includes(collateralType) ||
          !formalized ||
          !!value
        );
      }),
    formalized: yup.boolean().nullable().required("Campo obrigatório"),
    description: yup.string().nullable(),
    hasOptional: yup.boolean().nullable().required("Campo obrigatório"),
    finame: yup.string().when("collateralType", {
      is: (value: string) => value === "EQUIPAMENTO",
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
    storageLocation: yup.string().when("collateralType", {
      is: (value: string) => value === "EQUIPAMENTO",
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
      otherwise: (s: yup.StringSchema) => s.nullable(),
    }),
  });

export const defaultCollateralFormValues: CollateralFormValues = {
  collateralType: "",
  formalized: false,
  hasOptional: false,
  amount: 0,
  description: "",
  expireDate: "",
  formalizedContractNumber: "",
  guaranteedPercentage: 0,
  identification: "",
  lockDisbursement: "PRE",
  manufacturer: "",
  manufacturingYear: "",
  unitPrice: 0,
  storageLocation: "",
  finame: "",
};
