import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useLocation, useParams } from "react-router-dom";
import { service } from "services";

export const useSettlementDetails = () => {
  const { settlementId } = useParams<{ settlementId: string }>();
  const { state } = useLocation();
  if (!settlementId) throw new Error("No url parameters");

  const tradesDataSource = dataSourceCustomStoreGenerator(() =>
    service.quickfin
      .getSettlementBySettlementId(settlementId)
      .then(({ data }) => ({
        data: data.value,
        totalCount: data["@odata.count"],
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os trades da liquidação";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

  return {
    state,
    tradesDataSource,
  };
};
