import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OTypography,
} from "@maestro/react";

interface CompanyDetailsCardTemplateProps {
  title: string;
  children?: JSX.Element;
}

export const CompanyDetailsCardTemplate = ({
  title,
  children,
}: CompanyDetailsCardTemplateProps) => {
  return (
    <OCol xs={12} lg={4}>
      <OCard className="position-relative">
        <OCardHeader divider>
          <OTypography type="default">{title}</OTypography>
        </OCardHeader>
        <OCardBody>{children}</OCardBody>
      </OCard>
    </OCol>
  );
};
