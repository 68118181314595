import { ODataGridGeneratorConfig } from "components/data-grid";
import { contractStatusMap } from "pages/acc/components/utils/acc-contract-status.utils";
import { service } from "services";
import { AccContractSignatoriesMasterDetail } from "./master-detail/acc-contract-signatories.master-detail";

export const adminCustomerAccContractsGrid = {
  datagrid: {
    noDataText: "Não foram encontrados contratos associados a este empréstimo.",
  },
  columns: [
    {
      caption: "Tipo",
      dataField: "name",
    },
    {
      caption: "ExternalId",
      dataField: "externalId",
    },
    {
      caption: "Status",
      cellRender: ({ data }) => {
        return contractStatusMap[data?.status] ?? data?.status;
      },
    },
  ],
  masterDetail: {
    component: ({ data }) => (
      <div className="d-flex flex-column gap-4">
        <AccContractSignatoriesMasterDetail data={data} />
      </div>
    ),
    enabled: true,
  },
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.acc.adminCustomer.getAccCounterpartiesDetails>
  >["data"]["response"]["accDocuments"][number]
>;
