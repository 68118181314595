import { LoadingButton } from "components/loading-button";
import { useApproveButton } from "./approve-button.hook";

interface ApproveButtonProps {
  successCallback: () => void;
  workflowMigrationId: string;
  disabled: boolean;
}

export const ApproveButton = ({
  successCallback,
  workflowMigrationId,
  disabled,
}: ApproveButtonProps) => {
  const { approveDeskMigration, loading } = useApproveButton(
    workflowMigrationId,
    successCallback,
  );

  return (
    <LoadingButton
      dataAction="acoes:botao:aprovar"
      dataLabel="aprovar"
      loading={loading}
      disabled={disabled}
      onClick={approveDeskMigration}
    >
      Aprovar
    </LoadingButton>
  );
};
