import { OClickableBox, OTypography } from "@maestro/react";
import {
  isCustomerServiceMock,
  isFullMock,
  isNormal,
  setCustomerServiceMock,
  setFullMock,
  setNormal,
} from "hooks/roles/mock-roles-storage";

export const MockRolesSelector = () => {
  return (
    <div className="d-flex justify-content-between flex-column gap-2 py-4">
      <OTypography weight="500" className="mb-2">
        Comportamento do filtro de roles
      </OTypography>

      <OClickableBox
        dataAction="modal_configuracoes:botao:normal"
        dataLabel="normal"
        inputType="radio"
        name="mock-roles-selector"
        id="normal"
        value="normal"
        checked={isNormal()}
        size="sm"
        onInput={() => setNormal()}
      >
        Normal
      </OClickableBox>

      <OClickableBox
        dataAction="modal_configuracoes:botao:bypass"
        dataLabel="bypass"
        inputType="radio"
        name="mock-roles-selector"
        id="bypass"
        value="bypass"
        checked={isFullMock()}
        size="sm"
        onInput={() => setFullMock()}
      >
        Ignorar roles
      </OClickableBox>

      <OClickableBox
        dataAction="modal_configuracoes:botao:customer_service"
        dataLabel="customer_service"
        inputType="radio"
        name="mock-roles-selector"
        id="customer-service"
        value="customer-service"
        checked={isCustomerServiceMock()}
        size="sm"
        onInput={() => setCustomerServiceMock()}
      >
        Usar roles de atendente
      </OClickableBox>
    </div>
  );
};
