import { OLink, OTypography } from "@maestro/react";

export const ProfileDescription = () => {
  return (
    <div className="d-flex flex-column gap-1 mb-4">
      <OTypography>
        <strong>Atenção:</strong> O nome de um profile deve seguir a seguinte
        formatação:
      </OTypography>
      <OTypography weight="500">
        [portal ou serviço]:[produto ou recurso]-[categoria(opcional)].[perfil].
      </OTypography>
      <OTypography>Ex: parceiro:Usuario-Payer.ImpactoSocial</OTypography>
      <OTypography>
        Para mais detalhes, acesse a documentaçãoclique aqui para ver a{" "}
        <OLink
          href="https://wiki.quickfin.com.br/en/Plataforma/Enablers/primeiro-acesso/dev/gatekeeper/profiles-roles"
          target="_blank"
        >
          documentação
        </OLink>
      </OTypography>
    </div>
  );
};
