import { useEffect } from "react";
import type { ProductItem } from "../../offers.utils";
import { AcceptFooter } from "./accept-footer/accept-footer.component";
import { BankAccountList } from "./bank-account-list/bank-account.component";
import { CollateralsCard } from "./collaterals/collaterals.component";
import { Guarantors } from "./guarantors/guarantors.component";
import { SettlementTypes } from "./settlement-type/settlement-type.component";
import { SimulationResult } from "./simulation-result/simulation-result.component";
import { SimulationPageHeader } from "./simulation/simulation-page-header.component";
import { SimulationComponent } from "./simulation/simulation.component";
import { useSimulation } from "./simulation/simulation.context";

export type SimulationProps = ProductItem;

export const Simulation = (props: SimulationProps) => {
  const { simulationProduct, setSimulationProduct } = useSimulation();

  useEffect(() => {
    if (props) {
      setSimulationProduct(props);
    }
  }, [props, setSimulationProduct]);

  return (
    <div>
      <SimulationPageHeader simulationProduct={simulationProduct} />
      <SimulationComponent />
      <SimulationResult />
      <CollateralsCard />
      <Guarantors simulationProduct={simulationProduct} />
      <SettlementTypes />
      <BankAccountList simulationProducts={simulationProduct} />
      <AcceptFooter />
    </div>
  );
};
