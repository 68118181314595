import * as yup from "yup";

export interface WorkflowPendencyFormValues {
  type: string;
  documentId?: number;
  reason: string;
  fieldName?: string;
}

export const workflowPendencyValidationSchema: yup.ObjectSchema<WorkflowPendencyFormValues> =
  yup.object({
    type: yup.string().required("Campo obrigatório"),
    reason: yup.string().required("Campo obrigatório"),
    documentId: yup.number().optional(),
    fieldName: yup.string().when("documentId", {
      is: (value: string) => !value,
      then: (s: yup.StringSchema) => s.required("Campo obrigatório"),
    }),
  });
