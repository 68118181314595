import { OBadge, OCard, OIcon, OTooltip, OTypography } from "@maestro/react";
import React, { useMemo } from "react";
import { MoneyMask } from "../../../../../cartoes/pages/legacy/utils";

type ReportCardDayProps = {
  anticipatedAmount: number | undefined;
  disbursementAmount: number | undefined;
  liquidatedAmount: number | undefined;
  vnpAmount: number | undefined;
  fee: number | undefined;
  formattedDate: string;
  status: string | undefined;
};

export const ReportCardDay: React.FC<ReportCardDayProps> = ({
  anticipatedAmount,
  disbursementAmount,
  liquidatedAmount,
  vnpAmount,
  fee,
  formattedDate,
  status,
}) => {
  const detailsDayDate = useMemo(
    () => (
      <OTypography>{`Detalhes da antecipação do dia ${formattedDate}`}</OTypography>
    ),
    [formattedDate],
  );

  const maturityAmountColumn = useMemo(
    () => (
      <div className="w-20">
        <div className="d-flex flex-row align-items-center gap-1">
          <OTypography className="text-secondary">Vencimento</OTypography>
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">
              Soma de todas as operações com vencimento para este dia.
            </span>
            <OIcon category="orq" icon="orq-info" />
          </OTooltip>
        </div>
        <OTypography>{MoneyMask(anticipatedAmount || 0)}</OTypography>
      </div>
    ),
    [anticipatedAmount],
  );

  const disbursementAmountColumn = useMemo(
    () => (
      <div className="w-20">
        <div className="d-flex flex-row align-items-center gap-1">
          <OTypography className="text-secondary">Recebido</OTypography>
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">
              Valor recebido das credenciadoras pelos registros das operações.
            </span>
            <OIcon category="orq" icon="orq-info" />
          </OTooltip>
        </div>
        <OTypography>{MoneyMask(disbursementAmount || 0)}</OTypography>
      </div>
    ),
    [disbursementAmount],
  );

  const liquidatedAmountColumn = useMemo(
    () => (
      <div className="w-20">
        <div className="d-flex flex-row align-items-end gap-1">
          <OTypography className="text-secondary">Liquidado</OTypography>
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">
              Valor liquidado, utilizando recursos recebidos das credenciadoras
              e, quando necessário, os saldos disponíveis em contas.
            </span>
            <OIcon category="orq" icon="orq-info" />
          </OTooltip>
        </div>
        <OTypography>{MoneyMask(liquidatedAmount || 0)}</OTypography>
      </div>
    ),
    [liquidatedAmount],
  );

  const feeAmountColumn = useMemo(
    () => (
      <div className="w-20">
        <div className="d-flex flex-row align-items-end gap-1">
          <OTypography className="text-secondary">Encargos</OTypography>
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">
              Juros por atraso, referentes à não quitação do total da operação,
              do dia de vencimento até hoje.
            </span>
            <OIcon category="orq" icon="orq-info" />
          </OTooltip>
        </div>
        <OTypography>{MoneyMask(fee || 0)}</OTypography>
      </div>
    ),
    [fee],
  );

  const vnpAmountColumn = useMemo(
    () => (
      <div className="w-20">
        <div className="d-flex flex-row align-items-end gap-1">
          <OTypography className="text-secondary">Saldo devedor</OTypography>
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">
              Soma dos valores em aberto com os encargos por atrasos.
            </span>
            <OIcon category="orq" icon="orq-info" />
          </OTooltip>
        </div>
        <OTypography style={{ color: "#CE363F" }}>
          {MoneyMask(vnpAmount || 0)}
        </OTypography>
      </div>
    ),
    [vnpAmount],
  );

  return (
    <OCard className="p-4 mb-4">
      <div className="d-flex flex-row mb-4 gap-2 align-items-center">
        {detailsDayDate}
        {status === "nao_pago" ? (
          <OBadge size="sm" key="danger-badge" type="danger">
            Não pago
          </OBadge>
        ) : status === "em_aberto" ? (
          <OBadge size="sm" key="warning-badge" type="warning">
            Em aberto
          </OBadge>
        ) : (
          <OBadge size="sm" key="success-badge" type="success">
            Pago
          </OBadge>
        )}
      </div>

      <div className="d-flex flex-row border-0">
        {maturityAmountColumn}
        {disbursementAmountColumn}
        {liquidatedAmountColumn}
        {feeAmountColumn}
        {vnpAmountColumn}
      </div>
    </OCard>
  );
};
