import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { LoadingButton } from "components/loading-button";
import { useSetEligibilityButton } from "./set-eligibility-button.hook";

interface SetEligibilityButtonProps {
  id: number;
  isEligible: boolean;
  taxId: string;
  reloadGrid: () => void;
}

export const SetEligibilityButton = ({
  id,
  isEligible,
  taxId,
  reloadGrid,
}: SetEligibilityButtonProps) => {
  const modalId = `${id}-set-eligibility-modal`;
  const setEligible = !isEligible;

  const { loading, setEligibility } = useSetEligibilityButton(id, setEligible);

  return (
    <>
      <div style={{ width: "150px" }}>
        <IconButton
          dataAction="modal_mudar_eligibilidade:botao:oferta"
          dataLabel="oferta"
          icon={{
            category: "far",
            icon: setEligible ? "fa-envelope" : "fa-times",
          }}
          loading={loading}
          type="dark"
          outline
          onClick={() => modalManager.show(modalId)}
          fullWidth
        >
          {setEligible ? "Fazer oferta" : "Negar oferta"}
        </IconButton>
      </div>

      <OModal id={modalId} position="center" size="sm">
        <OModalHeader>
          <OTypography size="lg">
            {setEligible ? "Fazer oferta" : "Negar oferta"} de produto?
          </OTypography>
        </OModalHeader>
        <OModalBody>
          <OTypography>
            Tem certeza que deseja{" "}
            {setEligible ? "fazer uma oferta" : "negar a oferta"} para{" "}
            {masks.cpfCnpj(taxId)}?
          </OTypography>
        </OModalBody>
        <OModalFooter>
          <div className="w-100 d-flex justify-content-end gap-2">
            <OButton
              dataAction="modal_mudar_eligibilidade:botao:cancelar"
              dataLabel="cancelar"
              type="dark"
              outline
              onClick={() => modalManager.hide(modalId)}
            >
              Cancelar
            </OButton>
            <LoadingButton
              dataAction="modal_mudar_eligibilidade:botao:sim"
              dataLabel="sim"
              loading={loading}
              onClick={async () => {
                await setEligibility();
                modalManager.hide(modalId);
                reloadGrid();
              }}
            >
              Sim
            </LoadingButton>
          </div>
        </OModalFooter>
      </OModal>
    </>
  );
};
