import { Collateral } from "../collateral-details.types";
import { ImovelCollateralDetailsModalContent } from "../imovel-collateral-modal/imovel-collateral.component";
import { SacasGraoCollateralDetailsModalContent } from "../sacas-grao-collateral-modal/sacas-grao.component";
import { SolarCollateralDetailsModalContent } from "../solar-collateral-modal/solar-collateral.component";
import { Container } from "./collateral.styles";

interface CollateralDetailsProps {
  collaterals: Collateral[];
  selectedCollateralType: string | undefined;
}

const CollateralDetailsModalMapper = ({
  collaterals,
  selectedCollateralType,
}: CollateralDetailsProps) => {
  switch (selectedCollateralType) {
    case "PAINEIS_SOLARES":
      return <SolarCollateralDetailsModalContent collaterals={collaterals} />;
    case "SACAS_GRAO":
      return (
        <SacasGraoCollateralDetailsModalContent collaterals={collaterals} />
      );
    case "IMOVEL":
      return <ImovelCollateralDetailsModalContent collaterals={collaterals} />;
    default:
      return null;
  }
};

export const CollateralsDetails = ({
  collaterals,
  selectedCollateralType,
}: CollateralDetailsProps) => {
  return (
    <Container>
      <CollateralDetailsModalMapper
        collaterals={collaterals}
        selectedCollateralType={selectedCollateralType}
      />
    </Container>
  );
};
