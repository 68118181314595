import * as yup from "yup";

export const floatingSettingsModalValidationSchema = yup.object({
  quantityInDays: yup
    .number()
    .typeError("Esse campo é obrigatório")
    .integer("Somente números inteiros são permitidos")
    .moreThan(0, "O número de dias deve ser maior que 0")
    .required("Esse campo é obrigatório"),
});
