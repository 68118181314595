import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { LimitContractTemplates } from "services/hubcreditmanager/models/types/limit/limit.types";
import { loanIrisActs } from "../../../../../../components/utils/loan-iris-acts.utils";
import { ContractsTemplateUpload } from "./contract-template-upload.component";

export type ContractTemplate = LimitContractTemplates;

export const contractTemplatesGrid = {
  datagrid: {
    noDataText: "Nenhum template de contrato configurado",
    editing: {
      mode: "form",
      confirmDelete: true,
    },
    onRowValidating: (e) => {
      if (!e.newData?.templateKey && !e.oldData?.templateKey) {
        e.errorText = "Necessário anexar um documento";
        e.isValid = false;
      }
    },
  },
  columns: [
    {
      allowEditing: false,
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "contractType",
      caption: "Tipo de contrato",
      validationRules: [{ type: "required" }],
      lookup: {
        dataSource: [
          "CCB",
          "CPR",
          "CAC",
          "AF_IMOVEL",
          "CCG",
          "ICF",
          "CT",
          "NOTA_PROMISSORIA",
          "TERMO_CARTAO",
        ],
      },
    },
    {
      dataField: "signatureOrigin",
      caption: "Origem da assinatura",
      validationRules: [{ type: "required" }],
      lookup: {
        dataSource: [
          {
            value: "PAPERCLIP",
            label: "PaperClip",
          },
          {
            value: "CLICK_SIGN",
            label: "ClickSign",
          },
          {
            value: "DOCU_SIGN",
            label: "DocuSign",
          },
        ],
        displayExpr: "label",
        valueExpr: "value",
      },
    },
    {
      dataField: "irisAct",
      caption: "Ato na IRIS",
      validationRules: [{ type: "required" }],
      lookup: {
        displayExpr: "label",
        valueExpr: "value",
        dataSource: loanIrisActs,
      },
      cellRender: ({ data }) => {
        return loanIrisActs.find((irisAct) => irisAct.value === data.irisAct)
          ?.label;
      },
    },
    {
      dataField: "templateKey",
      caption: "Chave do template",
      visible: false,
      showEditorAlways: false,
      formItem: { visible: false },
    },
    {
      dataField: "templateKey-edit",
      caption: "Chave do template",
      editCellRender: (prop) => <ContractsTemplateUpload {...prop} />,
      cellRender: ({ data }) => data?.templateKey,
    },
    {
      formItem: { visible: false },
      cellRender: ({ data, component, rowIndex, row }) => {
        return (
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  label: "Baixar",
                  icon: { category: "fal", icon: "fa-download" },
                  onClick: async () => {
                    try {
                      OToastManager.info("Enviando arquivo...");

                      const { data: blob } =
                        await service.hubCreditManager.download({
                          key: data.templateKey,
                        });

                      helpers.downloadBlobFile(
                        data.templateKey.split("/")?.at(-1) ?? "",
                        blob,
                      );
                      OToastManager.destroy();
                    } catch (err) {
                      OToastManager.danger("Não foi possível baixar arquivo.");
                    }
                  },
                },
                {
                  label: "Editar",
                  icon: { category: "fal", icon: "fa-edit" },
                  onClick: () => {
                    component.editRow(rowIndex);
                  },
                },
                {
                  label: "Excluir",
                  icon: { category: "fal", icon: "fa-trash" },
                  onClick: () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          )
        );
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<
  ContractTemplate & { "templateKey-edit"?: unknown }
>;
