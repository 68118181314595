import { client } from "../client";
import {
  GetReconciliationResultsParams,
  PatchJustifyReconciliationResultParams,
  PatchApproveReconciliationResultParams,
  GetReconciliationResultDetailsByIdParams,
} from "./models/requests";
import {
  GetReconciliationCategoriesResponse,
  GetReconciliationResultByIdResponse,
  GetReconciliationResultDetailsByIdResponse,
  GetReconciliationsResultsResponse,
} from "./models/responses";
import { reconcilieroEndpoints } from "./reconciliero.endpoints";

export const reconcilieroService = {
  getReconciliationsResults(params?: GetReconciliationResultsParams) {
    return client.get<GetReconciliationsResultsResponse>(
      reconcilieroEndpoints.reconciliations.results.endpoint,
      {
        params,
      },
    );
  },
  getReconciliationResultById(externalId: string) {
    return client.get<GetReconciliationResultByIdResponse>(
      reconcilieroEndpoints.reconciliations.results.byId(externalId),
    );
  },
  getReconciliationResultDetailsById(
    externalId: string,
    params: GetReconciliationResultDetailsByIdParams,
  ) {
    return client.get<GetReconciliationResultDetailsByIdResponse>(
      reconcilieroEndpoints.reconciliations.results.details.byId(externalId),
      {
        params,
      },
    );
  },
  getCategories() {
    return client.get<GetReconciliationCategoriesResponse>(
      reconcilieroEndpoints.reconciliations.categories.endpoint,
    );
  },
  justifyReconciliationResult(
    externalId: string,
    body: PatchJustifyReconciliationResultParams,
  ) {
    return client.patch<void>(
      reconcilieroEndpoints.reconciliations.results.justify.byId(externalId),
      body,
    );
  },
  approveReconciliationResult(
    externalId: string,
    body: PatchApproveReconciliationResultParams,
  ) {
    return client.patch<void>(
      reconcilieroEndpoints.reconciliations.results.approve.byId(externalId),
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
};
