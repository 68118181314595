import { OIcon, OTooltip, OTypography } from "@maestro/react";
import { RouterButton } from "components/router-button";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostPowerOfAttorneyIsEloaAvailableResponse } from "services/clerk";
import { getValueFromMap } from "utils/get-value-from-map";
import { DottedUnderline, IconContainer, List } from "../cards.styles";
import { statusMap } from "./eloa-card.utils";

type EloaCardProps = Pick<
  PostPowerOfAttorneyIsEloaAvailableResponse,
  "description" | "status" | "updatedAt"
>;

export const EloaCardContents = ({
  description,
  status,
  updatedAt,
}: EloaCardProps) => {
  const statusProps = getValueFromMap(statusMap, status) ?? statusMap.DEFAULT;
  const detailsList = description?.split(";");

  return (
    <div className="d-flex flex-column gap-3 w-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon
              category="fa"
              icon={statusProps.icon}
              type={statusProps.color}
            />
          </IconContainer>
          <OTypography>Status:</OTypography>
        </div>
        <OTypography>{statusProps.text}</OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-history" />
          </IconContainer>
          <OTypography>Atualizado em:</OTypography>
        </div>
        <OTypography>{dayjs(updatedAt).format("DD/MM/YYYY")}</OTypography>
      </div>
      <div>
        {detailsList && (
          <OTooltip position="right" maxWidth="400px" floating>
            <div slot="tooltip-content">
              <List className="ps-2 m-0 text-left">
                {detailsList.map((detail) => (
                  <li key={detail.match(/\S/g)?.join("")}>{detail}</li>
                ))}
              </List>
            </div>
            <div className="d-flex gap-1">
              <IconContainer>
                <OIcon category="fa" icon="fa-info-circle" />
              </IconContainer>
              <OTypography>
                <DottedUnderline>Detalhes</DottedUnderline>
              </OTypography>
            </div>
          </OTooltip>
        )}
      </div>
      {status !== "INDISPONIVEL" && (
        <div className="align-self-center">
          <RouterButton
            dataAction="procuracao_eletronica:botao:ver_aprovadores"
            dataLabel="ver_aprovadores"
            href={
              corporateRouter.routes.cadastro.customer.details.eloaApprovers
                .path
            }
            role={roles.cadastro.customer.detailsEloaApproversPage.role}
          >
            <OTypography type="light">Ver Aprovadores</OTypography>
          </RouterButton>
        </div>
      )}
    </div>
  );
};
