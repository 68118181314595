import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { energiaRoles } from "../../../roles/energia.roles";
import { energyCommonDomainGrid } from "./energy-common-domain.grid";

export const EnergyCommonDomainPage = () => {
  const dataGridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Variáveis de Configuração" />}
      actions={
        <OButton
          onClick={() => {
            dataGridRef.current?.instance.addRow();
          }}
          role={energiaRoles.product.addCommonDomain.role}
        >
          Adicionar
        </OButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={dataGridRef} grid={energyCommonDomainGrid} />
    </GridTemplate>
  );
};
