import { OButton, OLoader } from "@maestro/react";
import React from "react";

interface LoadingButtonProps extends React.ComponentProps<typeof OButton> {
  loading: boolean;
  children: React.ReactNode;
}

export const LoadingButton = ({
  children,
  loading,
  ...rest
}: LoadingButtonProps) => {
  const hideContentClass = loading ? `invisible` : "visibile";
  return (
    <OButton {...rest} disabled={loading || rest.disabled}>
      {loading && <OLoader type="current-color" size="sm" absolute />}
      <div className={hideContentClass}>{children}</div>
    </OButton>
  );
};
