import { OOption, ORFieldSelect, OSkeletonCardNode } from "@maestro/react";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useServiceCall } from "hooks/service-call";
import { WorkflowLead } from "../../workflow-lead-details.types";
import { WorkflowLeadStatusCard } from "./workflow-lead-status-card.component";
import { WorkflowLeadStatusHistoryFormValues } from "./workflow-lead-status-history-form.schemas";

interface WorkflowLeadStatusHistoryProps {
  workflowLead: WorkflowLead;
}

export const WorkflowLeadStatusHistory = ({
  workflowLead,
}: WorkflowLeadStatusHistoryProps) => {
  const form = useForm<WorkflowLeadStatusHistoryFormValues>();

  const { watch, setValue } = form;

  const viewWatch = watch("view");

  const {
    callService: getWorkflowLeadsStateHistory,
    value: stateHistory,
    loading,
  } = useServiceCall(service.hubCreditManager.getWorkflowLeadsStateHistory);

  const Cards = useMemo(() => {
    const cards: JSX.Element[] = [];

    if (stateHistory) {
      const statusHistory = stateHistory.statusHistory
        .filter((status) => status.workflowConfigView.view.type === viewWatch)
        ?.sort((a, b) =>
          dayjs(a.createdDate).isAfter(b.createdDate) ? 1 : -1,
        );

      statusHistory.forEach((status, i) =>
        cards.push(
          <div className="d-flex justify-content-between align-items-center">
            {i !== 0 && <i className="fa fa-arrow-right" />}
            <WorkflowLeadStatusCard
              key={`${status.name}-${status.createdDate}`}
              statusHistory={status}
            />
          </div>,
        ),
      );
    }

    return cards;
  }, [stateHistory, viewWatch]);

  useEffect(() => {
    if (!workflowLead) return;
    setValue("view", "ADMIN");
  }, [setValue, workflowLead]);

  useEffect(() => {
    if (!workflowLead) return;
    getWorkflowLeadsStateHistory(workflowLead.id);
  }, [getWorkflowLeadsStateHistory, workflowLead]);

  return (
    <OSkeletonCardNode loading={loading} height="325px" width="100%" rounded>
      <div className="d-flex flex-column gap-3">
        <div className="w-20">
          <FormProvider {...form}>
            <form>
              <ORFieldSelect id="view" name="view" label="Visualização">
                <div>
                  {workflowLead?.workflowConfig.workflowConfigViews.map(
                    (configView) => (
                      <OOption
                        value={configView.view.type}
                        key={configView.view.type}
                      >
                        {configView.view.name}
                      </OOption>
                    ),
                  )}
                </div>
              </ORFieldSelect>
            </form>
          </FormProvider>
        </div>
        <div className="d-flex justify-content-center gap-2 flex-wrap">
          {Cards}
        </div>
      </div>
    </OSkeletonCardNode>
  );
};
