import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { isAxiosError } from "axios";
import {
  updateOriginatorCategorizationsDefaultValues,
  updateOriginatorCategorizationsSchema,
} from "./update-originator-categorizations.form";

export const useUpdateOriginatorCategorizations = () => {
  const [isCorban, setIsCorban] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [identifier, setIdentifier] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorCategorizationsSchema),
    defaultValues: updateOriginatorCategorizationsDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorCategorization(
        identifier,
        values,
      );
      OToastManager.success("Originador atualizado com sucesso!");
    } catch (err) {
      if (
        isAxiosError<
          | HubPartners.Admin.ErrorResponse
          | HubPartners.Admin.AlternateErrorResponse
        >(err)
      ) {
        if (err.response && err.response.data) {
          if ("failures" in err.response.data) {
            err.response.data.failures?.forEach((failure) => {
              failure.message && OToastManager.danger(failure.message);
            });
          } else if ("message" in err.response.data) {
            OToastManager.danger(
              err.response.data.message ??
                "Não foi possível atualizar a categorização do originador.",
            );
          }
        }
      } else {
        OToastManager.danger(
          "Não foi possível atualizar a categorização do originador.",
        );
      }
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;
      setValue(
        "partnershipTypeName",
        details.partnershipTypes[0].code +
          " - " +
          details.partnershipTypes[0].name,
      );
      setValue("partnershipTypeCode", details.partnershipTypes[0].code);
      setValue(
        "originatorSubTypesCodes",
        details.originatorSubTypes.map((subtype) => subtype.code),
      );
      setValue(
        "productsCodes",
        details.products.map((product) => product.code),
      );

      setIdentifier(details.identifier);

      setIsCorban(
        details.partnershipTypes.some((pt) => pt.code !== "PES") &&
          details.partnershipTypes.some((pt) => pt.code !== "FDR"),
      );
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    isCorban,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
