const applyOffset = (value: string, offset: number): string => {
  if (!value) return "";
  return (Number(value) + offset).toString();
};

const handleWithTime = (
  time: string,
  timeZoneOffset: [number, number, number],
) => {
  let [hour, minutes, seconds] = time.split(":");
  const [hourOffset, minutesOffset, secondsOffset] = timeZoneOffset;
  hour = applyOffset(hour, hourOffset);
  minutes = applyOffset(minutes, minutesOffset);
  seconds = applyOffset(seconds, secondsOffset);
  return [hour, minutes, seconds].filter((value: string) => value).join(":");
};

const handleWithDate = (date: string) => {
  return date
    .split("/")
    .filter((value: string) => value)
    .reverse()
    .join("-");
};

const handleWithCompleteDateTime = (
  date: string,
  time: string,
  timeZoneOffset: [number, number, number],
): string => {
  const targetDate = handleWithDate(date);
  const targetTime = handleWithTime(time, timeZoneOffset);
  return `${targetDate}T${targetTime}`;
};

const handleWithOnlyFirstSegment = (
  firstSegment: string,
  timeZoneOffset: [number, number, number],
): string => {
  const isADate = firstSegment.includes("/");
  const isATime = firstSegment.includes(":");
  const isUnrecognized = (isADate && isATime) || (!isADate && !isATime);
  if (isUnrecognized) return firstSegment;
  else if (isADate) return handleWithDate(firstSegment);
  else return handleWithTime(firstSegment, timeZoneOffset);
};

export const handleTimestampHeaderFiltering = (
  value: string,
  timeZoneOffset: [number, number, number],
): string => {
  const [firstSegment, secondSegment] = value.split(" ");
  if (firstSegment && secondSegment)
    return handleWithCompleteDateTime(
      firstSegment,
      secondSegment,
      timeZoneOffset,
    );
  else return handleWithOnlyFirstSegment(firstSegment, timeZoneOffset);
};
