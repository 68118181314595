import { useEffect, useRef } from "react";

interface ShadowContainerProps {
  children: React.ReactNode;
}

export const ShadowContainer = ({ children }: ShadowContainerProps) => {
  const hostRef = useRef<HTMLDivElement>(null);
  const insideShadowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hostRef.current && insideShadowRef.current) {
      const shadow = hostRef.current.attachShadow({ mode: "closed" });
      shadow.appendChild(insideShadowRef.current);
    }
  }, []);

  return (
    <>
      <div ref={hostRef} />
      <div ref={insideShadowRef}>{children}</div>
    </>
  );
};
