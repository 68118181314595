import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  ORFieldInputDate,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { LimitConditions } from "../../limit-conditions.types";
import {
  SettlementInstructionFormValues,
  settlementInstructionFormValidationsSchema,
} from "./settlement-instruction-form.schemas";

interface SettlementInstructionFormProps {
  financialConditions: LimitConditions;
  setHasAllSettlementInstruction: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettlementInstructionForm = ({
  financialConditions,
  setHasAllSettlementInstruction,
}: SettlementInstructionFormProps) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<
    SettlementInstructionFormValues & {
      limitRequestId?: number;
    }
  >({
    resolver: yupResolver(settlementInstructionFormValidationsSchema),
  });

  const { handleSubmit, reset, getValues } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubCreditManager.updateSettlementInstruction({
            ...values,
            limitRequestId: financialConditions.id,
          });

          const settlementDates: SettlementInstructionFormValues = getValues();
          setHasAllSettlementInstruction(
            !Object.values(settlementDates).some((date) => !date),
          );
          OToastManager.success("Dados de liquidação atualizados.");
        } catch {
          OToastManager.danger("Ocorreu um erro ao atualizar os dados.");
        } finally {
          setLoading(false);
        }
      }),
    [
      financialConditions.id,
      handleSubmit,
      getValues,
      setHasAllSettlementInstruction,
    ],
  );

  useEffect(() => {
    if (!financialConditions.settlementInstruction) return;

    const { firstPaymentDate, paymentDate } =
      financialConditions.settlementInstruction as SettlementInstructionFormValues;

    reset({
      firstPaymentDate: firstPaymentDate
        ? dayjs(firstPaymentDate).utc(false).format("YYYY-MM-DD")
        : null,
      paymentDate: paymentDate
        ? dayjs(paymentDate).utc(false).format("YYYY-MM-DD")
        : null,
    });
  }, [financialConditions.settlementInstruction, reset]);

  return (
    <FormTemplate
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar alterações
        </LoadingButton>
      }
    >
      <OCard>
        <OCardHeader>
          <OTypography type="dark" tag="h3" size="lg">
            Prazos de liquidação
          </OTypography>
        </OCardHeader>
        <OCardBody className="position-relative">
          <FormProvider {...form}>
            <ORow>
              <OCol md={4} className="d-flex flex-column gap-3">
                <ORFieldInputDate
                  id="paymentDate"
                  name="paymentDate"
                  label="Data do vencimento final"
                  disabledDate={(date) =>
                    date.toDate().getTime() <
                    dayjs().startOf("day").add(1, "month").toDate().getTime()
                  }
                />
              </OCol>
              <OCol md={4}>
                <ORFieldInputDate
                  id="firstPaymentDate"
                  name="firstPaymentDate"
                  label="Data do primeiro pagamento"
                  disabledDate={(date) =>
                    date.toDate().getTime() <
                    dayjs().startOf("day").add(1, "month").toDate().getTime()
                  }
                />
              </OCol>
            </ORow>
          </FormProvider>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
