import { modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { ruleTextBuilder } from "../../../_utils";

export const removeRuleConfirmationModalId = (ruleId: number) =>
  `remove-rule-confirmation-modal-${ruleId}`;

interface RemoveRuleConfirmationModalProps {
  rule: HubEnergy.DiligenceEventRuleSimpleResponse;
  remove: () => void;
}

export const RemoveRuleConfirmationModal = ({
  rule,
  remove,
}: RemoveRuleConfirmationModalProps) => {
  const modalId = removeRuleConfirmationModalId(rule.id);

  return (
    <OConfirmationModal
      {...datagridModalFix}
      style={{ position: "absolute" }}
      modalId={modalId}
      title="Excluir regra"
      confirmLabel="Excluir"
      closeLabel="Cancelar"
      divider={false}
      onConfirm={() => {
        remove();
        modalManager.hide(modalId);
      }}
    >
      <OTypography style={{ whiteSpace: "normal" }}>
        Tem certeza que deseja excluir a regra abaixo e todas as ações
        associadas?
      </OTypography>
      <OTypography style={{ whiteSpace: "normal" }}>
        {ruleTextBuilder(rule)}
      </OTypography>
    </OConfirmationModal>
  );
};
