import { parseExcelDate } from "utils/parse-excel-date";
import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { Configuration } from "./batch-import.type";

export const parseIntubateSpreadsheet = (
  buffer: FileReader["result"],
  configuration: Configuration,
): ScfDevelopers.ImportedBatchPayer[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return {
      addresses: [
        {
          cityName: cleanRow.cidade,
          complement: cleanRow["complemento do endereço"],
          countryCode: cleanRow["país"],
          number: cleanRow["número do endereço"]
            ? cleanRow["número do endereço"].toString()
            : null,
          stateCode: cleanRow.estado,
          street: cleanRow["endereço"],
          zipCode: cleanRow.cep ? cleanRow.cep.toString() : null,
        },
      ],
      birthDate: cleanRow["data de nascimento"]
        ? parseExcelDate(cleanRow["data de nascimento"])
        : undefined,
      emailAddress: cleanRow.email,
      gender: cleanRow.gender,
      name: cleanRow.nome,
      identification: cleanRow["cnpj/cpf"]
        ? cleanRow["cnpj/cpf"].toString()
        : "",
      phoneNumber: cleanRow.telefone ? cleanRow.telefone.toString() : undefined,
      authClientId: configuration.authClientId,
      partnerConfigurationId: configuration.configurationId,
    };
  });
};

export const missingFieldMap = {
  CountryCode: "País",
  Street: "Endereço",
  ZipCode: "CEP",
};

export const ErrorMessagesMap = {
  AtLeastOneCedente: "Informar pelo menos um cedente",
  AtLeastOneReceivable: "Informar pelo menos um recebível",
  AtLeastOneSacado: "Informar pelo menos um sacado",
  CantHaveDisbursementeAmount: "Não pode ter valor de desembolso",
  CantHavePixTypeAndBankInfo: "Não pode ter tipo pix e informações bancárias",
  CantHavePixInfoAndPagfor: "Não pode ter pix e ser pagfor",
  CantHavePixKeyAndBankInfo: "Não pode ter chave pix e informações bancárias",
  CantHaveRebateWithRebateAlongaPrazo:
    "Estratégia com rebate alonga prazo não permite inserir um valor de rebate",
  CantHaveTaxaParticularIfRebateFixo:
    "Não é permitido taxa fornecedor com rebate fixo configurado para as taxas BTG",
  CantHaveVisibilidadeIfDoesntHavePrazo:
    "'Prazo que o cedente visualiza' não pode ser diferente de 'Prazo Original' se não tem rebate alonga prazo",
  CedenteCadastrado: "Cedente já associado ao sacado",
  CedenteDesativado: "Cedente está inativado",
  CedenteInvalido: "Cedente inválido",
  CedenteMustHaveCgeWhenOffShore:
    "Cedente precisa de CGE quando o bordero é offshore",
  CedenteNaoCadastrado: "Cedente não cadastrado",
  CedenteRiskNotImplemented: "Risco cedente não implementado",
  CodigoFornecedorDoestnNeedBankAccount:
    "Conta bancária não é necessária quando o bordero é offshore",
  CodigoFornecedorMustHaveEstrategiaOffshore: "Código do fornecedor inválido",
  CompanyNotFound: "Empresa não encontrada",
  ConfigurationNotFound: "Configuração não encontrada",
  ContractTemplateGUIDDoesNotExists: "O identificador do contrato não existe",
  ContratoInvalido: "Contrato inválido",
  ConvenioCannotBeRemovedDueContainingSacadoCedenteObjsCompanyConvenios:
    "Convênio tem vínculo com cedente!",
  ConvenioCanOnlyHaveRebateOrSpreadDoRebateNotBoth:
    "Só é possível registrar um tipo de rebate",
  ConvenioGUIDDoesNotExists: "Código do convênio não encontrado",
  default: "Erro desconhecido",
  DiasDeVencimentoInvalidos: "Dias de vencimento inválidos!",
  DiasDeVencimentoRepetidos: "Não pode conter dias repetidos",
  RepasseInvalido: "Data de repasse inválida",
  DisbursmentValueInvalid: "Valor de desembolso superior a taxa",
  DisbursmentValueNotAllowed: "Valor de desembolso não permitido",
  EmailAlreadyInUse:
    "Não foi possível criar a sua conta com os dados fornecidos",
  IdentificationRequired: "Identificação obrigatória",
  IofMustBeGreaterThanZero: "Iof deve ser maior que zero",
  InvalidAccountDigit: "Dígito da conta inválido",
  InvalidAgency: "Agencia inválida",
  InvalidBankAccount: "Conta bancária inválida",
  InvalidBankCode: "Código do banco inválido",
  InvalidCNPJ: "CNPJ inválido",
  InvalidCodigoFornecedor: "Código de fornecedor inválido ou inexistente",
  InvalidCompanyEntityType: "Tipo de documento inválido",
  InvalidCompanyType: "Tipo da empresa inválido",
  InvalidContractTemplate: "Template de contrato inválido",
  InvalidContractType: "Modelo de termo  inválido",
  InvalidConvenio: "Convênio inválido",
  InvalidCPF: "CPF inválido",
  InvalidHmac: "Hmac inválido",
  InvalidMaturityDate: "Data de vencimento inválida",
  InvalidDisbursementDate: "Data de desembolso inválida",
  InvalidOperation: "Operação inválida",
  InvalidContaPix: "Informações faltantes de conta bancária",
  InvalidPixType: "Tipo pix inválido",
  InvalidPixKey: "Chave pix inválida",
  InvalidRoles: "Permissão inválida",
  InvalidRebate: "Rebate inválido",
  InvalidSacado: "Sacado inválido",
  InvalidTemplate: "Template inválido",
  InvalidToken: "Token inválido",
  InvalidTradeId: "ID do trade inválido",
  InvalidValue: "Valor inválido",
  MissingEmail: "Informe o email",
  MissingestrategiaId: "Informe o identificador do convênio",
  MissingIdentificadorExterno: "Informe o identificador externo",
  MissingTaxId: "Informe o CNPJ/CPF",
  MustBeCedente: "A empresa deve ser do tipo cedente",
  MustBeCodigoFornecedorForOffshore:
    "Documento deve ser código fornecedor para operações offshore",
  MustBeGreaterThanPreviousValue: "Valor precisa ser maior que o anterior",
  MustBeSacado: "A empresa deve ser do tipo sacado",
  MustHaveCedenteIdentification:
    "Identificador inválido/coluna com nome inválido",
  MustHaveCgeWhenOffShore: "Informe o CGE",
  MustHaveContaLiquidacao: "Informe uma conta de liquidação",
  MustHaveContractTemplate: "Template de contrato não pode ser vazio",
  MustHaveContractType: "Tipo de contrato não pode ser vazio",
  MustHaveDiasParaVencimentoSacado:
    "Dias para vencimento sacado não pode ser vazio",
  MustHaveDiasParaVencimentoDataRepasseRiscoCedente:
    "Dias para vencimento data repasse risco cedente não pode ser vazio",
  MustHaveEstrategiaOffshore: "Estratégia configurada não é offshore",
  MustHaveFCCId: "Fcc não pode ser vazio",
  MustHaveNoIdentificationWhenOffshore:
    "Identificação deve estar vazia quando Offshore",
  MustHaveOfficialName: "Informe o nome da empresa",
  MustHavePositiveRebateTolerance:
    "Tolerância de rebate deve ser um valor positivo",
  MustHaveTipoOnboarding: "Tipo de onboarding não pode ser vazio",
  MustHaveValorVencimento: "Precisa ter valor de vencimento",
  MustHaveTipoTaxaParticular:
    'Ao marcar "Permitir definição de taxa Sacado/Cedente" deve ser informada a "Configuração taxa Sacado/Cedente"',
  MustHaveVisbilidadeIfRebateAlongaPrazo:
    "'Prazo que o cedente visualiza' deve ser preenchido se tem rebate alonga prazo",
  NotaEmOperacao: "Nota já em operação",
  OffshoreDoestnNeedBankAccount:
    "Bordero offshore não necessite de conta bancária",
  OffshoreMustHaveEstrategiaOffshore: "Estratégia configurada não é offshore",
  OneCurrencyPerBordero: "Apenas uma moeda permitida por bordero",
  OneTemplatePerProductPortfolioMarketplace:
    "Apenas um template permitido por produto, carteira e marketplace",
  ReceivableAlreadyExists: "Este recebível já existe em nossa plataforma",
  ReceivableAlreadyExistsInThisSheet: "O recebível está repetido na planilha",
  RepeatedCompanyForThisCedente: "Sacado já associado a este cedente ",
  RepeatedCompanyForThisSacado: "Cedente já associado a este sacado ",
  SacadoMustHaveCGEWhenOffShore: "Sacado precisa de CGE",
  TaxaBaseNaoCadastrada: "Taxa base não cadastrada para este fornecedor",
  TaxaNaoCadastrada: "Taxa não cadastrada para o período da nota",
  TaxaParticularInvalida: "Taxa fornecedor inválida ou inferior à taxa base",
  TaxIdAlreadyInUse: "CNPJ/CPF já cadastrado",
  TaxIdNotAuthorized: "CNPJ/CPF não autorizado",
  UserCPFAlreadyExists:
    "Não foi possível criar a sua conta com os dados fornecidos",
  UserCpfIsNotValid: "CPF inválido",
  UserNameCanNotBeEmpty: "O campo nome não pode ser vazio",
  UsernameExistsException:
    "Não foi possível criar a sua conta com os dados fornecidos",
  UserNotAuthorized: "Usuário não autorizado",
  UserNotFound: "Usuário não encontrado",
  VencimentoInvalido: "Vencimento inválido",
  ConvenioCannotBeRemovedDueContainingSacadoCedenteObjs:
    "Convênio está sendo usado!",
  InvalidRiscoOperacao: "Risco de operação inválido",
  MustHaveRiscoOperacao: "Risco de operação não encontrado",
  CnpjNotMatriz: "O CNPJ informado não corresponde a uma matriz",
  InvalidTaxId: "CNPJ inválido",
  CompanyNotHaveRequiredRole: "A empresa não possui uma role necessária",
  CnpjNotSubsidiary: "A empresa não é subsidiária",
  InvalidGroupType: "Tipo de grupo inválido",
  OnboardCantBeEdited: "Este onboarding não pode ser editado",
  InvalidPhoneNumber: "Telefone inválido",
  InvalidEmail: "Email inválido",
  EmailAndTaxIdNotAllowed: "Email ou CPF já em uso",
  CnpjIsNotActiveInRfb: "CNPJ inativo na RFB",
  CpfIsNotRegularInRfb: "CPF não regular na RFB",
  UnderAge: "P.F. menor de idade",
  InvalidCountryCode: "Código do país inválido",
  NotEmptyValidator: "Campo obrigatório não informado",
  NotNullValidator: "Campo obrigatório não informado",
  CannotHaveMoreThan500Onboardings:
    "Permitido cadastrar até 500 onboardings por arquivo",
  CannotHaveRepeatedOnboardings: "Há onboardings duplicados",
  BirthDateIsRequired: "Data de Nascimento obrigatória",
  InvalidMaritalStatus: "Estado Civil inválido",
  InvalidGender: "Sexo inválido",
};
