import { OAvatar, ODivider, OTypography } from "@maestro/react";
import { BtgIconAvatar, BtgIconAvatarType } from "components/btg-icon-avatar";
import { AccountResumeProps } from "./account-resume.types";
import { AccountUtils } from "../../utils";

export const AccountResume = ({
  accountNumber,
  accountType,
  agency,
  bankCode = "208",
  bankImage,
  bankName,
  title,
}: AccountResumeProps) => {
  const btgIconAvatarType =
    bankCode === "208"
      ? agency === "50"
        ? BtgIconAvatarType.Business
        : agency === "1"
        ? BtgIconAvatarType.Investment
        : BtgIconAvatarType.Banking
      : undefined;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          {!!bankImage && <OAvatar image={bankImage} alt="Bank Icon" />}
          {!bankImage && !!btgIconAvatarType && (
            <BtgIconAvatar type={btgIconAvatarType} />
          )}
          <div className="d-flex flex-column">
            <OTypography type="dark">{title}</OTypography>
            <div className="d-flex">
              <OTypography size="sm" key={bankName}>
                {bankName}
              </OTypography>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2">
          <OTypography type="dark-60">Banco</OTypography>
          <OTypography type="dark" key={bankCode}>
            {bankCode}
          </OTypography>

          <ODivider position="vertical" size="xxs" />

          <OTypography type="dark-60">Agência</OTypography>
          <OTypography type="dark" key={agency}>
            {AccountUtils.formatAccountAgency(agency)}
          </OTypography>

          <ODivider position="vertical" size="xxs" />

          <OTypography type="dark-60">Conta</OTypography>
          <OTypography type="dark" key={accountNumber}>
            {AccountUtils.formatAccountNumber(accountNumber)}
          </OTypography>

          {accountType && (
            <>
              <ODivider position="vertical" size="xxs" />
              <OTypography type="dark" key={accountType}>
                {accountType}
              </OTypography>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
