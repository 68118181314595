import {
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  modalManager,
} from "@maestro/react";
import { DefaultErrorComponent, ErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { getValueFromMap } from "utils/get-value-from-map";
import { documentTypeMap, countryCodeMap } from "../../../../utils";

import {
  FilteredEstrategiaSelector,
  UpdateReceivablesConfirmationModal,
  updateReceivablesConfirmationModalId,
} from "../../cedentes/detalhes/_compose";
import { CedenteDetailsForm } from "../../cedentes/detalhes/cedente-details.types";
import { useSacadoDetails } from "./sacado-details.hook";
import { SacadoDetailsForm } from "./sacado-details.types";

export const SacadoDetails = () => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    addSacado,
    addCompanySacadoLoading,
    sacado,
    editSacado,
    editCompanySacadoLoading,
    error,
    form,
    getSacado,
    isCreating,
    loading,
  } = useSacadoDetails();

  const { watch, trigger } = form;

  const entityTypeWatcher = watch("companyEntityType");

  return (
    <>
      <FormTemplate
        pageTitle={
          <PageTitle
            title={isCreating ? "Adicionar sacado" : "Detalhes do sacado"}
          />
        }
        actions={
          <LoadingButton
            dataAction="detalhes:botao:submeter"
            dataLabel="submeter"
            loading={editCompanySacadoLoading || addCompanySacadoLoading}
            disabled={!isCreating && (!sacado || loading)}
            onClick={async () => {
              if (isCreating) {
                addSacado();
              } else if (await trigger())
                modalManager.show(updateReceivablesConfirmationModalId);
            }}
          >
            {isCreating ? "Enviar" : "Salvar"}
          </LoadingButton>
        }
      >
        <CardTemplate>
          <FormProvider {...form}>
            <ContentTemplate
              loading={loading}
              hasError={error !== "NONE"}
              errorComponent={
                error === "FETCH" ? (
                  <DefaultErrorComponent
                    title="Erro ao buscar o sacado"
                    callback={getSacado}
                  />
                ) : (
                  <ErrorComponent messageTitle="Este sacado não está associado a esta empresa" />
                )
              }
              noValue
              render={() => (
                <ORow gap={3}>
                  <OCol xs={12} md={6}>
                    <ORFieldSelect
                      dataAction="detalhes:select:tipo_documento"
                      dataLabel="tipo_documento"
                      id="companyEntityType"
                      name="companyEntityType"
                      label="Tipo do documento"
                      placeholder="Selecionar"
                      required
                    >
                      {Object.values(documentTypeMap).map(
                        ({ label, value }) => (
                          <OOption key={value} value={value}>
                            {label}
                          </OOption>
                        ),
                      )}
                    </ORFieldSelect>
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldSelect
                      dataAction="detalhes:select:country"
                      dataLabel="country"
                      id="country"
                      name="country"
                      label="País"
                      placeholder="Selecionar"
                      disabled={!isCreating}
                      required
                    >
                      {Object.values(countryCodeMap).map(({ label, value }) => (
                        <OOption key={value} value={value}>
                          {label}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      dataAction="detalhes:texto:identificacao"
                      dataLabel="identificacao"
                      id="identificacao"
                      name="identificacao"
                      label="Identificação"
                      placeholder="Preencher"
                      tag={
                        getValueFromMap(documentTypeMap, entityTypeWatcher)
                          ?.inputTag ?? "text"
                      }
                      key={entityTypeWatcher}
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      dataAction="detalhes:texto:razao_social"
                      dataLabel="razao_social"
                      id="officialName"
                      name="officialName"
                      label="Razão social"
                      placeholder="Preencher"
                      tag="text"
                      required
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      dataAction="detalhes:texto:codigo_sacado"
                      dataLabel="codigo_sacado"
                      id="identificadorExterno"
                      name="identificadorExterno"
                      label="Código do sacado"
                      placeholder="Preencher"
                      tag="text"
                      tooltip="Código do sacado para o fornecedor. Esse código serve para identificar importações de contrapartes que não possuem CNPJ."
                      tooltipMaxWidth="600px"
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <ORFieldInput
                      dataAction="detalhes:texto:cge"
                      dataLabel="cge"
                      id="cge"
                      name="cge"
                      label="CGE"
                      placeholder="Preencher"
                      tag="text"
                      tooltip="CGE para os sacados que não possuem CNPJ. Portanto, é necessário somente para contrapartes sem CNPJ. Este campo, em conjunto com o Código do Sacado, serve para identificarmos uma contraparte quando um fornecedor importa recebíveis."
                      tooltipMaxWidth="600px"
                    />
                  </OCol>
                  <OCol xs={12} md={6}>
                    <FilteredEstrategiaSelector
                      form={
                        form as UseFormReturn<
                          CedenteDetailsForm | SacadoDetailsForm
                        >
                      }
                      tipoProduto={sacado?.estrategia?.tipoProduto ?? null}
                      companyId={quickfinId}
                    />
                  </OCol>
                  <OCol xs={12} md={3}>
                    <ORFieldRadioGroup
                      dataAction="detalhes:select:permite_override_estrategia"
                      dataLabel="permite_override_estrategia"
                      id="permiteOverrideEstrategia"
                      name="permiteOverrideEstrategia"
                      label="Permite override estratégia (Parceiro define as taxas?)"
                    >
                      <div className="d-flex gap-4">
                        <div className="d-flex gap-2">
                          <ORadio
                            id="permiteOverrideEstrategia-yes"
                            value="yes"
                          />
                          <OLabel htmlFor="permiteOverrideEstrategia-yes">
                            Sim
                          </OLabel>
                        </div>
                        <div className="d-flex gap-2">
                          <ORadio
                            id="permiteOverrideEstrategia-no"
                            value="no"
                          />
                          <OLabel htmlFor="permiteOverrideEstrategia-no">
                            Não
                          </OLabel>
                        </div>
                      </div>
                    </ORFieldRadioGroup>
                  </OCol>
                </ORow>
              )}
            />
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <UpdateReceivablesConfirmationModal submit={editSacado} />
    </>
  );
};
