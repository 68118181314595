import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { isErrorStatusCode } from "utils/is-error-status-code";
import { quickfinProductNameMap } from "./quickfin-products.utils";

export const useQuickfinProducts = (clerkProducts: string[]) => {
  const {
    customer: { identification },
  } = useCustomer();

  const { callService, error, loading, value } = useServiceCall(
    service.quickfin.getCompanyProducts,
  );

  const getCompanyProducts = useCallback(
    () =>
      callService({
        companyTaxId: identification,
      }),
    [callService, identification],
  );

  useEffect(() => {
    getCompanyProducts();
  }, [getCompanyProducts]);

  const products = useMemo(() => {
    const uniqueFunds = [
      ...new Set(
        value
          ?.filter((product) => product.productType === "FIDC")
          .map((product) => product.additionalInfo?.relatedName)
          .filter(Boolean) as string[],
      ),
    ];

    const uniqueProducts = [
      ...new Set(value?.map((product) => product.productType)),
    ]
      .map(
        (productType) =>
          getValueFromMap(quickfinProductNameMap, productType) ?? productType,
      )
      .filter((product) => !clerkProducts.includes(product))
      .map((product) => ({
        type: product,
        ...(product === "FIDC" && { funds: uniqueFunds }),
      }));

    return uniqueProducts;
  }, [clerkProducts, value]);

  return {
    getCompanyProducts,
    hasError: isErrorStatusCode(error, ">=", 500),
    loading,
    products,
  };
};
