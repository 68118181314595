import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { externalIdentificationsGrid } from "./external-identifications.grid";

export const ExternalIdentificationsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id parameter");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.hubCreditManager
          .getExternalIdentifications(id)
          .then(({ data }) => {
            return data ?? [];
          })
          .catch(() => {
            throw new Error("Erro ao buscar identificações externas");
          }),
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Identificações externas" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !dataSource?.isLoading() && dataSource?.reload();
          }}
        />
      }
    >
      <ODataGridGenerator
        grid={externalIdentificationsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
