import { OTypography } from "@maestro/react";
import { useMemo } from "react";
import { sortById } from "../../../../../../utils";
import { useStepAnalysisContext } from "../../step-analysis.context";
import { PendencyTile, PropertyTile } from "../../_compose";
import { PropertyStage } from "../step-analysis-stages.types";

export const PropertyStageComponent = () => {
  const { selectedStage, stepAnalysis } = useStepAnalysisContext();
  const propertyStage = selectedStage as PropertyStage;

  const property = useMemo(
    () =>
      stepAnalysis.propertiesWithPendencies.find(
        (p) => p.id === propertyStage.propertyId,
      )!,
    [propertyStage.propertyId, stepAnalysis.propertiesWithPendencies],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <PropertyTile key={property.id} property={property} />
      <OTypography size="lg" type="dark">
        Pendências
      </OTypography>
      <div className="ps-4 d-flex flex-column gap-4">
        {sortById(property.pendencies).map((p) => (
          <PendencyTile key={p.id} pendency={p} />
        ))}
      </div>
    </div>
  );
};
