import { OIcon } from "@maestro/react";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { useMemo } from "react";
import { useLinkClickHandler } from "react-router-dom";
import { buildCustomerLink } from "utils/build-customer-link";
import { useCustomerSelector } from "../../context";
import { CustomerSelectorItem } from "../../customer-selector.types";
import { buildUserLink } from "../../utils";
import { maskIdentification } from "../../../../utils/mask"
import {
  HighlightText,
  buildTaxIdHighlight,
  buildTextHighlight,
} from "../highlight-text";
import { FavoriteButton } from "./_compose";
import {
  IdentificationSpan,
  NameSpan,
  StyledDropdownItem,
  UnstyledAnchor,
} from "./dropdown-item.styles";

interface DropdownItemProps {
  item: CustomerSelectorItem;
}

export const DropdownItem = ({ item }: DropdownItemProps) => {
  const { mode, value, selectOption } = useCustomerSelector();

  const icon = item.type === "costumer" ? "fa-building" : "fa-user";

  const hasValue = !!value;

  const maskedIdentification = useMemo(
    () => maskIdentification(item.identification),
    [item.identification],
  );

  const href =
    item.type === "costumer"
      ? buildCustomerLink(item.identification)
      : buildUserLink(item.identification);

  return (
    <StyledDropdownItem
      handleInteraction={() => {
        selectOption(item);
      }}
    >
      <UnstyledAnchor
        href={href}
        onClick={useLinkClickHandler(href)}
        onAuxClick={useLinkNewTabHandler(href)}
        className="d-flex align-items-start gap-1"
      >
        {mode === "fetch" && (
          <OIcon
            category="fa"
            icon={icon}
            type="dark"
            size="sm"
            style={{ height: "calc(1.2 * var(--font-md))" }} // align with text
          />
        )}

        <div className="d-flex flex-column gap-1" style={{ minWidth: "0" }}>
          <NameSpan
            lighterText={hasValue}
            title={item.officialName ?? undefined}
          >
            <HighlightText
              text={item.officialName}
              highlightExpression={buildTextHighlight(value)}
            />
          </NameSpan>
          <IdentificationSpan lighterText={hasValue}>
            <HighlightText
              text={maskedIdentification}
              highlightExpression={buildTaxIdHighlight(value)}
            />
          </IdentificationSpan>
        </div>

        {mode === "fetch" && <FavoriteButton item={item} />}
      </UnstyledAnchor>
    </StyledDropdownItem>
  );
};
