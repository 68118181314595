import {
  OButton,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterItem,
  OFilterModal,
  OIcon,
  OInputDateRange,
  OInputText,
  OLabel,
  ORadio,
  ORadioGroup,
  OTypography,
  useFilter,
} from "@maestro/react";
import React, { memo, useCallback, useRef, useState } from "react";
import { format } from "utils/date";
import {
  filterOptions,
  oneMonthAgoInMs,
  PaymentsFilterFields,
  PaymentsFilters,
} from "./payment-filter-modal.types";

interface PaymentFilterModalProps {
  filters: PaymentsFilters;
  onFilter: (filters: PaymentsFilterFields) => void;
}

export const PaymentFilterModal: React.FC<PaymentFilterModalProps> = memo(
  ({ filters, onFilter }: PaymentFilterModalProps) => {
    const [filterFields, setFilterFields] = useState<PaymentsFilterFields>({
      startDate: new Date(Date.now() - oneMonthAgoInMs).toISOString(),
      endDate: new Date().toISOString(),
    });

    const filterModalRef = useRef<HTMLOFilterModalElement>(null);

    const { openFilter, filter } =
      useFilter<PaymentsFilterFields>(filterModalRef);

    const handleFilterField = (field: string, value: string | undefined) => {
      setFilterFields((old) => ({ ...old, [field]: value }));
    };

    const handleFilter = useCallback(async () => {
      const filterValues = await filter();
      onFilter({ ...filterValues, ...filterFields });
    }, [filterFields, onFilter, filter]);

    return (
      <>
        <OFilterModal ref={filterModalRef} id="fopa-payments-filter">
          <OFilterHeader>Filtrar Pagamentos</OFilterHeader>
          <OFilterBody>
            <OFilterItem>
              <OTypography slot="title">Lote</OTypography>
              <OInputText
                data-filterparam="referenciaCnab"
                id="lote"
                name="lote"
                dataAction="fopa_payment_filter:input:lote"
                dataLabel="lote"
              />
            </OFilterItem>
            <OFilterItem>
              <OTypography slot="title">Período</OTypography>
              <OInputDateRange
                value={[
                  format.date(
                    `${filters.startDate?.substring(0, 10)}T00:00:00Z`,
                    { timeZone: "UTC" },
                  ),
                  format.date(
                    `${filters.endDate?.substring(0, 10)}T23:59:59Z`,
                    { timeZone: "UTC" },
                  ),
                ]}
                onChange={(evt) => {
                  handleFilterField(
                    "startDate",
                    evt?.[0]?.format("YYYY-MM-DD"),
                  );
                  handleFilterField("endDate", evt?.[1]?.format("YYYY-MM-DD"));
                }}
              />
            </OFilterItem>
            <OFilterItem>
              <OTypography slot="title">Status</OTypography>
              <ORadioGroup
                dataAction="fopa_payment_filter:radio:status"
                dataLabel="status"
                data-filterparam="status"
              >
                <div>
                  {Object.keys(filterOptions).map((option) => (
                    <div key={`raio-${option}`} className="d-flex gap-2">
                      <ORadio
                        id={option}
                        name={option}
                        value={filterOptions[option]}
                      />
                      <OLabel htmlFor={option}>{option}</OLabel>
                    </div>
                  ))}
                </div>
              </ORadioGroup>
            </OFilterItem>
          </OFilterBody>
          <OFilterFooter>
            <div className="d-flex justify-content-end">
              <OButton
                onClick={handleFilter}
                dataLabel="filtrar"
                dataAction="fopa_payment_filter:botao:filtrar"
              >
                Filtrar
              </OButton>
            </div>
          </OFilterFooter>
        </OFilterModal>

        <OButton
          dataAction="fopa_payment_filter:botao:filtrar"
          dataLabel="filtrar"
          onClick={openFilter}
        >
          <div className="d-flex align-items-center gap-2">
            <OIcon type="light" category="fal" icon="fa-search" />
            <OTypography type="light">Filtrar</OTypography>
          </div>
        </OButton>
      </>
    );
  },
);
