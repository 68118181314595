import { service } from "services";
import {
  parseIntubateAddSpreadsheet,
  parseIntubateCancelSpreadsheet,
  parseIntubateEditSpreadsheet,
} from "./_compose";

export const intubateTypeMap = {
  add: {
    title: "Entuba - Inclusão",
    service: service.recebiveisCartao.entubaInclusao,
    tip: "Enviar entuba de inclusao para adicionar novos registros.",
    modelUrl: "/files/modelo_importacao_entuba_inclusao_registro.xlsx",
    parser: parseIntubateAddSpreadsheet,
  },
  edit: {
    title: "Entuba - Alteração",
    service: service.recebiveisCartao.entubaAlteracao,
    tip: "Enviar entuba de alteracao para atualizar registros existentes.",
    modelUrl: "/files/modelo_importacao_entuba_alteracao_registro.xlsx",
    parser: parseIntubateEditSpreadsheet,
  },
  cancel: {
    title: "Entuba - Cancelamento",
    service: service.recebiveisCartao.entubaCancelamento,
    tip: "Enviar entuba de cancelamento para cancelar registros efetivados.",
    modelUrl: "/files/modelo_importacao_entuba_cancelamento_registro.xlsx",
    parser: parseIntubateCancelSpreadsheet,
  },
};
