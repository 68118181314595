export const formFields = {
  basicMinValue: {
    id: "basicMinValue",
    name: "basicMinValue",
    dataLabel: "basicMinValue",
    dataAction: "estrategia_tco_input_basicMinValue",
    label: "Valor mínimo",
    tag: "currency" as const,
    required: true,
  },
  basicMaxValue: {
    id: "basicMaxValue",
    name: "basicMaxValue",
    dataLabel: "basicMaxValue",
    dataAction: "estrategia_tco_input_basicMaxValue",
    label: "Valor máximo",
    tag: "currency" as const,
    required: true,
  },
  basicPercentage: {
    id: "basicPercentage",
    name: "basicPercentage",
    dataLabel: "basicPercentage",
    dataAction: "estrategia_tco_input_basicPercentage",
    label: "Porcentagem",
    tag: "percentage" as const,
    required: true,
  },
  intermediateMinValue: {
    id: "intermediateMinValue",
    name: "intermediateMinValue",
    dataLabel: "intermediateMinValue",
    dataAction: "estrategia_tco_input_intermediateMinValue",
    label: "Valor mínimo",
    tag: "currency" as const,
    required: true,
  },
  intermediateMaxValue: {
    id: "intermediateMaxValue",
    name: "intermediateMaxValue",
    dataLabel: "intermediateMaxValue",
    dataAction: "estrategia_tco_input_intermediateMaxValue",
    label: "Valor máximo",
    tag: "currency" as const,
    required: true,
  },
  intermediatePercentage: {
    id: "intermediatePercentage",
    name: "intermediatePercentage",
    dataLabel: "intermediatePercentage",
    dataAction: "estrategia_tco_input_intermediatePercentage",
    label: "Porcentagem",
    tag: "percentage" as const,
    required: true,
  },
  advancedMinValue: {
    id: "advancedMinValue",
    name: "advancedMinValue",
    dataLabel: "advancedMinValue",
    dataAction: "estrategia_tco_input_advancedMinValue",
    label: "Valor mínimo",
    tag: "currency" as const,
    required: true,
  },
  advancedMaxValue: {
    id: "advancedMaxValue",
    name: "advancedMaxValue",
    dataLabel: "advancedMaxValue",
    dataAction: "estrategia_tco_input_advancedMaxValue",
    label: "Valor máximo",
    tag: "currency" as const,
    required: true,
  },
  advancedPercentage: {
    id: "advancedPercentage",
    name: "advancedPercentage",
    dataLabel: "advancedPercentage",
    dataAction: "estrategia_tco_input_advancedPercentage",
    label: "Porcentagem",
    tag: "percentage" as const,
    required: true,
  },
  corporateMinValue: {
    id: "corporateMinValue",
    name: "corporateMinValue",
    dataLabel: "corporateMinValue",
    dataAction: "estrategia_tco_input_corporateMinValue",
    label: "Valor mínimo",
    tag: "currency" as const,
    required: true,
  },
  corporateMaxValue: {
    id: "corporateMaxValue",
    name: "corporateMaxValue",
    dataLabel: "corporateMaxValue",
    dataAction: "estrategia_tco_input_corporateMaxValue",
    label: "Valor máximo",
    tag: "currency" as const,
    required: true,
  },
  corporatePercentage: {
    id: "corporatePercentage",
    name: "corporatePercentage",
    dataLabel: "corporatePercentage",
    dataAction: "estrategia_tco_input_corporatePercentage",
    label: "Porcentagem",
    tag: "percentage" as const,
    required: true,
  },
};
