import { OCard, OCardBody, OTypography } from "@maestro/react";
import { getPropertyLabel } from "../../../../../../utils";
import { IsPublicBadge } from "../badges";
import { ActionsArea, PropertyValueRenderer } from "./_compose";

interface PropertyTileProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
  readonly?: boolean;
}

export const PropertyTile = ({ property, readonly }: PropertyTileProps) => {
  return (
    <OCard className="position-relative">
      <div
        className="badge-container position-absolute"
        style={{ transform: "translate(0, -50%)" }}
      >
        <IsPublicBadge isPublic={property.isPublic} />
      </div>
      <OCardBody>
        <div className="d-flex justify-content-between gap-3">
          <div style={{ minWidth: 0 }} className="pt-2 flex-shrink-1">
            <OTypography weight="700">{getPropertyLabel(property)}</OTypography>
            <div style={{ wordWrap: "break-word" }} className="ps-2">
              <PropertyValueRenderer property={property} />
            </div>
          </div>
          {!readonly && <ActionsArea property={property} />}
        </div>
      </OCardBody>
    </OCard>
  );
};
