import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { CustomerAccSettlement } from "services/acc/models/responses/get-admin-settlements.response";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";

export const adminCustomerAccSettlementDetailsGenerator = (
  value: CustomerAccSettlement | null,
) => {
  if (!value) return {} as DetailsFields;

  const quoteDetails = {
    quote: {
      title: "Liquidação",
      items: [
        {
          label: "Id",
          value: value.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(value.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: value?.status || "UNDEFINED",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: value?.country,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: value.sellCurrencyType,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: value.flowType,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Enquadramento",
          value: value.natureDescription,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    accProposal: {
      title: "Valores da liquidação",
      items: [
        {
          label: "Valor solicitado",
          value: masks.currency(
            value?.requestedAmount,
            currencySymbol[
              value.sellCurrencyType as keyof typeof currencySymbol
            ],
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor da proposta",
          value: masks.currency(
            value?.accSettlement.proposalAmount,
            currencySymbol[
              value.sellCurrencyType as keyof typeof currencySymbol
            ],
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: `Valor de juros em ${value.sellCurrencyType}`,
          value: masks.currency(
            value?.accSettlement.foreignFixedInterestValue ?? 0,
            currencySymbol[
              value.sellCurrencyType as keyof typeof currencySymbol
            ],
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor de juros em BRL",
          value: masks.currency(
            value?.accSettlement.fixedInterestValue,
            "R$",
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return quoteDetails;
};
