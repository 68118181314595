import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { UserNotification } from "services/raven/models/types";
import { downloadEmail } from "./email-notifications.utils";

export const emailNotificationsGrid: ODataGridGeneratorConfig<UserNotification> =
  {
    datagrid: {
      noDataText: "Nenhuma notificação",
      remoteOperations: true,
      filterRow: { visible: true },
    },
    columns: [
      {
        dataField: "receiver",
        dataType: "string",
        caption: "Destinatário",
      },
      {
        dataField: "title",
        dataType: "string",
        caption: "Título",
      },

      {
        dataField: "status",
        caption: "Status",
        dataType: "string",
      },
      {
        dataField: "trackerSend",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Entregue em",
      },
      {
        dataField: "trackerOpen",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Lido em",
      },
      {
        dataField: "trackerBounce",
        dataType: "datetime",
        format: "shortDateShortTime",
        caption: "Devolvido em",
      },
      {
        dataField: "insertedAt",
        dataType: "datetime",
        visible: false,
      },
      {
        caption: "Ações",
        alignment: "center",
        role: "raven:Notification.Download",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-file" },
                label: "Ver template",
                disabled: !data.fileKey,
                onClick: () => downloadEmail(data.fileKey),
                role: "raven:Notification.Download",
              },
            ]}
          />
        ),
      },
    ],
  };
