import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { useMemo } from "react";
import { useGridRef } from "hooks/grid-ref";
import { CompanyFundSelect } from "../../../components/company-fund-select";
import { useCompanyNotification } from "./company.hook";
import { CustomPagination } from "./interacoes/_compose/custom-pagination";
import { CompanySearch } from "./search";

export const CompanyInteractions = () => {
  const gridRef = useGridRef();
  const {
    dataSource,
    form,
    formSerch,
    companyFunds,
    selectedFundWatch,
    rows,
    setRows,
    page,
    setPage,
    totalItems,
    loading,
    grid,
    setFindTaxId,
  } = useCompanyNotification();

  const { handleSubmit } = formSerch;

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        grid={grid}
        gridRef={gridRef}
        dataSource={dataSource}
        loading={loading}
      />
    ),
    [grid, gridRef, dataSource, loading],
  );

  const filter = useMemo(
    () =>
      handleSubmit((values) => {
        return setFindTaxId(values.findTaxId);
      }),
    [handleSubmit, setFindTaxId],
  );

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle title="Interações" />
        <CompanyFundSelect />
        {selectedFundWatch && (
          <GridTemplate
            pageTitle={
              <PageTitle
                title={`Empresas do fundo ${
                  companyFunds?.find((item) => item.fund === selectedFundWatch)
                    ?.name ?? ""
                }`}
              />
            }
            actions={
              <FormProvider {...formSerch}>
                <CompanySearch onSubmit={filter} setFindTaxId={setFindTaxId} />
              </FormProvider>
            }
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
          >
            {memoizedGrid}
            <CustomPagination
              totalCount={totalItems}
              currentPage={page}
              setCurrentPage={setPage}
              pagination={[10, 20, 30]}
              selectedPaginationSize={rows}
              setSelectedPaginationSize={setRows}
            />
          </GridTemplate>
        )}
      </div>
    </FormProvider>
  );
};
