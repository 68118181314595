import { OCard, OCardHeader, OCol, OTypography } from "@maestro/react";
import { OwnersTotals } from "services/moana/models";
import { OwnerTotalsCard } from "./owner-totals-card.component";

interface OwnersTotalsProps {
  ownersTotals?: OwnersTotals;
}

export const OwnerTotals = ({ ownersTotals }: OwnersTotalsProps) => {
  return (
    <>
      {ownersTotals && (
        <OCard className="mb-4">
          <OCardHeader divider>
            <OCol className="justify-content-between">
              <OTypography type="default">Totais</OTypography>
            </OCol>
          </OCardHeader>
          <OwnerTotalsCard ownersTotals={ownersTotals} />
        </OCard>
      )}
    </>
  );
};
