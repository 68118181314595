import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  CellRenderFields,
  calculateFilterExpressionFields,
} from "components/cell-render-fields";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { FilesItem } from "services/onboarding/models";
import { DocumentsItemFormatted } from "./document-devolutive.types";

export const buildGrid = (
  downloadDocument: (item: FilesItem) => Promise<void>,
): ODataGridGeneratorConfig<DocumentsItemFormatted> => ({
  datagrid: {
    noDataText: "Nenhuma documento encontrado",
    pager: {
      showPageSizeSelector: true,
    },
    filterRow: {
      visible: true,
    },
    editing: {
      allowUpdating: true,
    },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome/Identificação",
      allowEditing: false,
      calculateFilterExpression: (...args) =>
        calculateFilterExpressionFields(["name", "taxId"], ...args),
      cellRender: ({ data }) => (
        <CellRenderFields fields={[data.name, masks.cpfCnpj(data.taxId)]} />
      ),
    },
    {
      dataField: "type",
      caption: "Tipo",
      allowEditing: false,
    },
    {
      caption: "Obrigatório",
      cellRender: ({ data }) => (data.isRequired ? "Sim" : "Não"),
      allowEditing: false,
    },
    {
      dataField: "files",
      caption: "Documentos",
      allowEditing: false,
      cellRender: ({ data }) => {
        if (!data.files.length)
          return (
            <OTypography tag="span">Nenhum arquivo encontrado</OTypography>
          );

        return data.files.map((file) => (
          <IconButton
            key={file.key}
            icon={{ category: "orq", icon: "orq-download" }}
            dataAction="devolutiva-documentos:botao:download-arquivo"
            dataLabel="download-arquivo"
            type="dark"
            outline
            onClick={() => downloadDocument(file)}
            className="mb-2"
          >
            {file.name}
          </IconButton>
        ));
      },
    },
    {
      dataField: "justification",
      caption: "Justificativa",
    },
    {
      dataField: "files",
      caption: "Ocorrências",
      allowEditing: false,
      cellRender: ({ data }) => {
        if (!data.files.length) return "";

        return data.files.map(
          (file) =>
            file.hasError && (
              <OTypography tag="span" type="danger" key={file.key}>
                {file.errorMessage}
              </OTypography>
            ),
        );
      },
    },
  ],
});
