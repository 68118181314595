import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { validators } from "@maestro/utils";
import { SelectSearchField } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { brazilStates } from "services/public/models/types/brazil-states.utils";
import { BrazilUF } from "services/public/models/types/brazil-uf.type";
import { EnergyAddressFormValues } from "./energy-address-form.schemas";

interface EnergyAddressFormProps {
  disabled?: boolean;
}

/** @deprecated */
export const EnergyAddressForm = ({ disabled }: EnergyAddressFormProps) => {
  const {
    value: cities,
    callService: getCitiesByState,
    loading,
  } = useServiceCall(service.onboarding.getCities);
  const { callService: getAddressByZipCode, loading: loadingAddress } =
    useServiceCall(service.onboarding.getAddress);

  const {
    watch,
    setValue,
    getValues,
    resetField,
    formState: { dirtyFields },
  } = useFormContext<EnergyAddressFormValues>();

  const zipCode = watch("address.zipCode");
  const uf = watch("address.state");
  const cityWatch = watch("address.city");
  const lastZipCode = useRef("");
  const lastUF = useRef("");

  const getAddress = useCallback(async () => {
    if (
      !zipCode ||
      !validators.cep(zipCode) ||
      lastZipCode.current === zipCode ||
      disabled ||
      !dirtyFields?.address?.zipCode
    ) {
      return;
    }

    const { success, response } = await getAddressByZipCode({
      zipCode,
    });

    if (!success) return OToastManager.danger("Erro ao buscar endereço");

    const address = response.data;

    lastZipCode.current = zipCode;

    setValue("address.street", address.streetName);
    setValue("address.neighborhood", address.neighborhood);
    setValue("address.state", address.stateCode as BrazilUF);
    setValue("address.city", address.cityName.toUpperCase());
  }, [
    zipCode,
    disabled,
    dirtyFields?.address?.zipCode,
    getAddressByZipCode,
    setValue,
  ]);

  const getCities = useCallback(async () => {
    if (!uf || lastUF.current === uf) return;

    const { success, response: ufCities } = await getCitiesByState({
      stateCode: uf,
      countryCode: "BR",
    });

    if (!success) {
      resetField("address.city");
      OToastManager.danger("Erro ao buscar cidades");
      return;
    }

    lastUF.current = uf;

    if (
      !ufCities.data?.some(
        (ufCity) =>
          ufCity.cityName?.toUpperCase() ===
          getValues("address.city")?.toUpperCase(),
      )
    ) {
      resetField("address.city");
    }
  }, [getCitiesByState, getValues, resetField, uf]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  return (
    <>
      <ORow>
        <OCol xs={12} md={6} className="d-flex align-items-center gap-2">
          <ORFieldInput
            required
            disabled={disabled}
            tag="cep"
            id="cep"
            name="address.zipCode"
            label="CEP"
          />
          {loadingAddress && <OLoader />}
        </OCol>
      </ORow>
      <ORow>
        <OCol xs={12} md={6}>
          <ORFieldInput
            required
            tag="text"
            id="street"
            name="address.street"
            label="Rua"
            disabled={loadingAddress || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            tag="text"
            id="number"
            name="address.number"
            label="Número"
            disabled={loadingAddress || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            tag="text"
            id="complement"
            name="address.complement"
            label="Complemento"
            disabled={loadingAddress || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            required
            tag="text"
            id="neighborhood"
            name="address.neighborhood"
            label="Bairro"
            disabled={loadingAddress || disabled}
          />
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldSelect
            required
            id="state"
            name="address.state"
            label="Estado"
            disabled={loadingAddress || disabled}
          >
            {brazilStates.map((state) => (
              <OOption key={state.uf} value={state.uf}>
                {state.uf}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6} className="d-flex align-items-center">
          <SelectSearchField
            required
            id="city"
            name="address.city"
            label="Cidade"
            key={cities?.length}
            disabled={
              !cities ||
              cities.length < 1 ||
              disabled ||
              loading ||
              loadingAddress
            }
            options={
              cities?.map((city) => ({
                label: city.cityName,
                value: city.cityName?.toUpperCase(),
              })) ?? []
            }
            value={
              cities?.find(
                (city) =>
                  city.cityName?.toUpperCase() === cityWatch?.toUpperCase(),
              )?.cityName
            }
          />
          {loading && <OLoader />}
        </OCol>
      </ORow>
    </>
  );
};
