import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  ContractSignatoriesForm,
  contractSignatoriesFormValidationSchema,
} from "../_compose";

export const useAddContractSignatories = () => {
  const { callService: createContractSignatories, loading: submitLoading } =
    useServiceCall(service.quickfin.createContractSignatories);

  const navigate = useNavigate();

  const form = useForm<ContractSignatoriesForm>({
    resolver: yupResolver(contractSignatoriesFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await createContractSignatories({
          taxId: values.taxId,
          identifications: values.identifications,
        });

        if (success) {
          navigate(
            corporateRouter.routes.antecipacaoDeCartoes.product
              .contractSignatories.path,
          );
          OToastManager.success("Assinates configurados com sucesso");
        } else {
          OToastManager.danger("Erro ao configurar os assinantes");
        }
      }),
    [createContractSignatories, handleSubmit, navigate],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
