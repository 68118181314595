export enum InterestType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE_PER_MONTH = "PERCENTAGE_PER_MONTH",
  FIXED_VALUE_PER_MONTH = "FIXED_VALUE_PER_MONTH",
}

export enum FeesType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE = "PERCENTAGE",
  FIXED_VALUE = "FIXED_VALUE",
}
