import { ONotification, OTypography } from "@maestro/react";
import { StandardLink } from "components/standard-link";
import { useCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { UsuariosClienteByIdentificationRouteParams } from "../../../../../../../routes/usuarios.route-params";

export const ExpiringPowersCompanyUserNotification = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const {
    customer: { identification: customerIdentification },
  } = useCustomer();

  const { hasRole } = useRoles();

  const { callService, value } = useServiceCall(
    service.onboardingRelationships.getPowersStatus,
  );

  useEffect(() => {
    if (
      hasRole(roles.usuarios.customer.detailsExpiringPowersCompanyUserPage.role)
    )
      callService({
        companyTaxId: customerIdentification,
        userTaxId: identification,
      });
  }, [callService, customerIdentification, hasRole, identification]);

  const hasExpiredPowers = value?.status && value.status !== "NOT_EXPIRE";

  return hasExpiredPowers ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">
          O usuário possui expiração de poderes
        </OTypography>
        <StandardLink
          href={corporateRouter.routes.usuarios.customer.users.details.expiringPowersCompanyUser.path(
            { identification },
          )}
          role={
            roles.usuarios.customer.detailsExpiringPowersCompanyUserPage.role
          }
        >
          Detalhes
        </StandardLink>
      </div>
    </ONotification>
  ) : null;
};
