import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useInstrumentCodeIdField } from "./instrument-code-id-field.hook";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const InstrumentCodeIdField = () => {
  const { getInstrumentCodes, hasFetchError, instrumentCodes, loading } =
    useInstrumentCodeIdField();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:instrument_codes"
        dataLabel="instrument_codes"
        id="instrumentCodeId"
        name="instrumentCodeId"
        label={EstrategiaFormLabels.instrumentCodeId}
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={instrumentCodes?.length}
        required
      >
        {instrumentCodes?.map((instrumentCode) => (
          <OOption
            key={instrumentCode.instrumentCodeId}
            value={instrumentCode.instrumentCodeId}
          >
            {instrumentCode.code} - {instrumentCode.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="instrument_codes:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getInstrumentCodes()}
        />
      )}
    </OCol>
  );
};
