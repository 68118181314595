import { client } from "../client";
import { legacysEndpoints } from "./legacys.endpoints";
import {
  BaseCalendar,
  GetCalendarResponse,
  PostCalendarBody,
  PatchCalendarBody,
  GetCalendarsResponse,
  GetBaseCalendars,
  PostCalendarResponse,
} from "./models";

export const legacysService = {
  getBaseCalendars() {
    return client.get<GetBaseCalendars>(legacysEndpoints.baseCalendar.endpoint);
  },

  getBaseCalendarById(id: number) {
    return client.get<BaseCalendar>(legacysEndpoints.baseCalendar.getById(id));
  },

  getCustomCalendars() {
    return client.get<GetCalendarsResponse>(
      legacysEndpoints.customCalendar.endpoint,
    );
  },

  getCalendarNames() {
    return client.get<string[]>(legacysEndpoints.calendar.endpoint);
  },

  getCustomCalendarById(id: number) {
    return client.get<GetCalendarResponse>(
      legacysEndpoints.customCalendar.getById(id),
    );
  },

  createCustomCalendar(body: PostCalendarBody) {
    return client.post<PostCalendarResponse>(
      legacysEndpoints.customCalendar.endpoint,
      body,
    );
  },

  updateCustomCalendar(body: PatchCalendarBody) {
    return client.patch<PostCalendarResponse>(
      legacysEndpoints.customCalendar.endpoint,
      body,
    );
  },

  deleteCustomCalendar(id: number) {
    return client.delete(legacysEndpoints.customCalendar.getById(id));
  },
};
