import { OCol, OOptionMultiple, ORFieldSelectMultiple } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const DashboardWorkflowConfigSelector = () => {
  const form = useFormContext();
  const { setValue, watch } = form;
  const workflowConfigIdsWatcher = watch("workflowConfigIds");

  const { callService: getWorkflowConfigs, value: workflowConfigs } =
    useServiceCall(service.hubCreditManager.getWorkflowConfig);

  useEffect(() => {
    getWorkflowConfigs();
  }, [getWorkflowConfigs]);

  useEffect(() => {
    const idsFromConfigs =
      workflowConfigs?.map((workflowConfig) => workflowConfig.id.toString()) ||
      [];
    setValue("workflowConfigIds", idsFromConfigs);
  }, [workflowConfigs, setValue]);

  return (
    <OCol xs={12}>
      <ORFieldSelectMultiple
        id="workflowConfigIds"
        name="workflowConfigIds"
        label="Workflow"
        placeholder="Buscar Workflow"
        dataAction="dashboard_workflow_lead:multiselect:filtrar_workflow"
        dataLabel="filtrar_workflow"
        key={workflowConfigIdsWatcher}
      >
        {workflowConfigs?.map((workflowConfig) => (
          <OOptionMultiple
            value={workflowConfig.id.toString()}
            key={`${workflowConfig.id}`}
          >
            {workflowConfig.name}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
    </OCol>
  );
};
