import { useGridRef } from "hooks/grid-ref";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { RoleAssignmentsForm } from "../role-assignments.types";
import { Profile } from "./profile-selector.types";
import { mapProfileUuids } from "./profile-selector.utils";

interface ProfileSelectorContext {
  blockedProfiles: Record<string, string[]>;
  clearProfiles: () => void;
  gridRef: ReturnType<typeof useGridRef>;
  selected: string | undefined;
  setProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
}

const profileSelectorContext = createContext({} as ProfileSelectorContext);

interface ProfileSelectorProviderProps {
  children: React.ReactNode;
}

export const ProfileSelectorProvider = ({
  children,
}: ProfileSelectorProviderProps) => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [blockedProfiles, setBlockedProfiles] = useState<
    Record<string, string[]>
  >({});

  const gridRef = useGridRef();

  const { setValue, watch } = useFormContext<RoleAssignmentsForm>();

  const rolesWatcher = watch("roles");

  const clearProfiles = useCallback(() => setValue("roles", []), [setValue]);

  // Recalculate `blockedProfiles` when `rolesWatcher` changes
  useEffect(() => {
    const newBlockedProfiles = (rolesWatcher ?? []).reduce(
      (blocked, selectedUuid) => {
        const selectedProfile = profiles.find(
          (profile) => profile.uuid === selectedUuid,
        );

        selectedProfile?.subProfiles.forEach((subProfile) => {
          // eslint-disable-next-line no-param-reassign
          blocked[subProfile.uuid] = [
            ...(blocked[subProfile.uuid] ?? []),
            selectedProfile.nameLabel,
          ];
        });
        return blocked;
      },
      {} as Record<string, string[]>,
    );

    setBlockedProfiles(newBlockedProfiles);
  }, [profiles, rolesWatcher, setValue]);

  // Clears selected profiles when `blockedProfiles` changes
  useEffect(() => {
    const profilesToUncheck = (rolesWatcher ?? []).filter(
      (uuid) => blockedProfiles[uuid],
    );
    if (profilesToUncheck.length) {
      setValue(
        "roles",
        (rolesWatcher ?? []).filter((p) => !profilesToUncheck.includes(p)),
      );
    }
  }, [blockedProfiles, rolesWatcher, setValue]);

  // Selects grid rows corresponding to the selected profiles
  useEffect(() => {
    gridRef.current?.instance.selectRows(rolesWatcher ?? [], false);
  }, [gridRef, rolesWatcher]);

  /** Text containing the names of the selected profiles */
  const selected = mapProfileUuids(rolesWatcher, profiles);

  const value = useMemo(
    () => ({
      blockedProfiles,
      clearProfiles,
      gridRef,
      selected,
      setProfiles,
    }),
    [blockedProfiles, clearProfiles, gridRef, selected],
  );

  return (
    <profileSelectorContext.Provider value={value}>
      {children}
    </profileSelectorContext.Provider>
  );
};

export const useProfileSelector = () => useContext(profileSelectorContext);
