import { memo, useContext } from "react";
import { Column } from "devextreme-react/data-grid";
import { ODataGrid } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { OButton, ORow, OCol, OTypography, modalManager } from "@maestro/react";
import { IntegrationModalName } from "../enums/integrationModalName";
import { IntegrationContext } from "../context/integration.context";

const IntegrationContactGrid = () => {
  const { integration, setIntegration } = useContext(IntegrationContext);

  const handleClick = (values: any) => {
    const filteredContact = integration.contacts.filter(
      (contact) =>
        contact.email !== values.key.email &&
        contact.phone !== values.key.phone,
    );
    setIntegration({ ...integration, contacts: filteredContact });
  };

  return (
    <ORow>
      <ORow className="p-2">
        <OCol className="pt-2" align="start">
          <OTypography size="md">Contatos</OTypography>
        </OCol>
        <OCol justify="end" align="end">
          <OButton
            className="mt-1 mr-0 pr-0"
            onClick={() => {
              modalManager.show(IntegrationModalName.ADD_CONTACT_INTEGRATION);
            }}
          >
            Adicionar Contato
          </OButton>
        </OCol>
      </ORow>
      <ODataGrid
        dataSource={integration.contacts}
        showBorders={false}
        showRowLines
        showColumnLines={false}
        scrolling={{ showScrollbar: "onScroll" }}
        columnAutoWidth
      >
        <Column
          dataField="name"
          caption="Nome"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="type"
          caption="Tipo"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="phone"
          caption="Telefone"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="email"
          caption="E-mail"
          alignment="left"
          allowSorting={false}
        />
        <Column
          dataField="actions"
          caption="Ações"
          alignment="right"
          cellRender={(values) => {
            return (
              <DataGridAction
                actions={[
                  {
                    label: "Excluir",
                    icon: {
                      category: "orq",
                      icon: "orq-edit-trash",
                    },
                    onClick: async () => {
                      handleClick(values);
                    },
                  },
                ]}
              />
            );
          }}
          allowSorting={false}
          data-testid="technical-contacts-grid-action-cell"
        />
      </ODataGrid>
    </ORow>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(IntegrationContactGrid);
