import { DeepNullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface ActionEditorForm {
  actionType: string;
  targetStepTypes: string[];
  targetStepStatuses: string[];
  targetPendencyTypes: string[];
  targetPendencyStatuses: string[];
  targetSamePendency: boolean;
  targetSameStep: boolean;
  isEnabled: boolean;
  payload: {
    changePendencyStatusActionPayload: {
      status: string;
    };
    changeStepStatusActionPayload: {
      status: string;
    };
    createPendencyActionPayload: {
      message: string;
      isPublic: boolean;
      isAutomation: boolean;
      hasBpoAction: boolean;
      status: string;
      type: string;
    };
    createPropertyActionPayload: {
      isPublic: boolean;
      isAutomation: boolean;
      type: string;
      documentType: string;
    };
  };
}

export const actionEditorFormFields = {
  actionType: {
    name: "actionType",
    label: "Ação",
    placeholder: "Selecionar",
    required: true,
  },
  targetStepTypes: {
    name: "targetStepTypes",
    label: "Tipo da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetStepStatuses: {
    name: "targetStepStatuses",
    label: "Status da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetPendencyTypes: {
    name: "targetPendencyTypes",
    label: "Tipo da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetPendencyStatuses: {
    name: "targetPendencyStatuses",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetSamePendency: {
    id: "targetSamePendency",
    name: "targetSamePendency",
    checkboxLabel: "Mesma pendência que disparou o evento",
  },
  targetSameStep: {
    id: "targetSameStep",
    name: "targetSameStep",
    checkboxLabel: "Mesma step que disparou o evento",
  },
  isEnabled: {
    id: "isEnabled",
    name: "isEnabled",
    checkboxLabel: "Habilitado",
  },
  // CHANGE_PENDENCY_STATUS
  "payload.changePendencyStatusActionPayload.status": {
    name: "payload.changePendencyStatusActionPayload.status",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  // CHANGE_STEP_STATUS
  "payload.changeStepStatusActionPayload.status": {
    name: "payload.changeStepStatusActionPayload.status",
    label: "Status da step",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  // CREATE_PENDENCY
  "payload.createPendencyActionPayload.isPublic": {
    id: "payload.createPendencyActionPayload.isPublic",
    name: "payload.createPendencyActionPayload.isPublic",
    checkboxLabel: "Mostra para o cliente",
  },
  "payload.createPendencyActionPayload.isAutomation": {
    id: "payload.createPendencyActionPayload.isAutomation",
    name: "payload.createPendencyActionPayload.isAutomation",
    checkboxLabel: "Automação",
  },
  "payload.createPendencyActionPayload.hasBpoAction": {
    id: "payload.createPendencyActionPayload.hasBpoAction",
    name: "payload.createPendencyActionPayload.hasBpoAction",
    checkboxLabel: "Tem ação de BPO",
  },
  "payload.createPendencyActionPayload.status": {
    name: "payload.createPendencyActionPayload.status",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: false,
    dropdownPosition: "top",
    required: true,
  },
  "payload.createPendencyActionPayload.type": {
    name: "payload.createPendencyActionPayload.type",
    label: "Tipo da pendência",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  "payload.createPendencyActionPayload.message": {
    id: "payload.createPendencyActionPayload.message",
    name: "payload.createPendencyActionPayload.message",
    tag: "textarea",
    label: "Mensagem",
    adaptHeight: true,
  },
  // CREATE_PROPERTY
  "payload.createPropertyActionPayload.isPublic": {
    id: "payload.createPropertyActionPayload.isPublic",
    name: "payload.createPropertyActionPayload.isPublic",
    checkboxLabel: "Mostra para o cliente",
  },
  "payload.createPropertyActionPayload.isAutomation": {
    id: "payload.createPropertyActionPayload.isAutomation",
    name: "payload.createPropertyActionPayload.isAutomation",
    checkboxLabel: "Automação",
  },
  "payload.createPropertyActionPayload.type": {
    name: "payload.createPropertyActionPayload.type",
    label: "Tipo da propriedade",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  "payload.createPropertyActionPayload.documentType": {
    name: "payload.createPropertyActionPayload.documentType",
    label: "Tipo do documento",
    placeholder: "Selecionar",
    multiple: false,
    dropdownPosition: "top",
  },
} as const;

type Payload = ActionEditorForm["payload"];
type ChangePendencyStatus = Payload["changePendencyStatusActionPayload"];
type ChangeStepStatus = Payload["changeStepStatusActionPayload"];
type CreatePendencyAction = Payload["createPendencyActionPayload"];
type CreatePropertyAction = Payload["createPropertyActionPayload"];

export const actionEditorFormValidationSchema = yup.object<
  ActionEditorForm,
  YupObjectSchema<ActionEditorForm>
>({
  actionType: yup.string().required("Campo obrigatório"),
  payload: yup.object<Payload, YupObjectSchema<Payload>>({
    changePendencyStatusActionPayload: yup
      .object<ChangePendencyStatus, YupObjectSchema<ChangePendencyStatus>>({
        status: yup.string().required("Campo obrigatório"),
      })
      .when("actionType", {
        is: "CHANGE_PENDENCY_STATUS" satisfies HubEnergy.EDiligenceEventActionType,
        then: (schema) => schema,
        otherwise: () => yup.object().nullable().optional(),
      }),
    changeStepStatusActionPayload: yup
      .object<ChangeStepStatus, YupObjectSchema<ChangeStepStatus>>({
        status: yup.string().required("Campo obrigatório"),
      })
      .when("actionType", {
        is: "CHANGE_STEP_STATUS" satisfies HubEnergy.EDiligenceEventActionType,
        then: (schema) => schema,
        otherwise: () => yup.object().nullable().optional(),
      }),
    createPendencyActionPayload: yup
      .object<CreatePendencyAction, YupObjectSchema<CreatePendencyAction>>({
        status: yup.string().required("Campo obrigatório"),
        type: yup.string().required("Campo obrigatório"),
      })
      .when("actionType", {
        is: "CREATE_PENDENCY" satisfies HubEnergy.EDiligenceEventActionType,
        then: (schema) => schema,
        otherwise: () => yup.object().nullable().optional(),
      }),
    createPropertyActionPayload: yup
      .object<CreatePropertyAction, YupObjectSchema<CreatePropertyAction>>({
        type: yup.string().required("Campo obrigatório"),
      })
      .when("actionType", {
        is: "CREATE_PROPERTY" satisfies HubEnergy.EDiligenceEventActionType,
        then: (schema) => schema,
        otherwise: () => yup.object().nullable().optional(),
      }),
  }),
});

export const actionEditorFormDefaultValues = {
  actionType: null,
  targetStepTypes: [],
  targetStepStatuses: [],
  targetPendencyTypes: [],
  targetPendencyStatuses: [],
  targetSamePendency: false,
  targetSameStep: false,
  isEnabled: true,
  payload: {
    changePendencyStatusActionPayload: {
      status: null,
    },
    changeStepStatusActionPayload: {
      status: null,
    },
    createPendencyActionPayload: {
      isAutomation: false,
      hasBpoAction: false,
      isPublic: false,
      message: null,
      status: null,
      type: null,
    },
    createPropertyActionPayload: {
      isAutomation: false,
      isPublic: false,
      type: null,
      documentType: null,
    },
  },
} satisfies Partial<
  DeepNullable<ActionEditorForm>
> as unknown as ActionEditorForm;
