import { DetailsGroup } from "./_compose";
import { DetailsFields } from "./details-card.types";

interface DetailsContentProps {
  fields: DetailsFields;
}

export const DetailsContent = ({ fields }: DetailsContentProps) => (
  <div className="d-flex flex-column gap-4">
    {Object.values(fields).map((group, rowIndex) => {
      return (
        /* eslint-disable-next-line react/no-array-index-key */
        <DetailsGroup key={rowIndex} group={group} />
      );
    })}
  </div>
);
