import * as yup from "yup";

export interface RegisterBankingAccountFormValues {
  account_digit: string | undefined;
  account_number: string | undefined;
  agency_digit: string | undefined;
  agency_number: string | undefined;
  covenant: string | undefined;
  document: string;
  name: string;
  type_document: string;
  wallet_code: string | undefined;
}

export const registerBankingAccountSchema: yup.ObjectSchema<RegisterBankingAccountFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    document: yup.string().required("Este campo é obrigatório"),
    type_document: yup.string().required("Este campo é obrigatório"),
    covenant: yup.string().optional(),
    agency_number: yup.string().optional(),
    agency_digit: yup.string().optional(),
    account_number: yup.string().optional(),
    account_digit: yup.string().optional(),
    wallet_code: yup.string().optional(),
  });
