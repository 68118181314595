import { OLoader } from "@maestro/react";
import {
  InitializeBehaviorCommand,
  LoadBehaviorCommand,
  behaviorManager,
  useUnsafeBehavior,
} from "contexts/behavior";
import { useEffect } from "react";
import { logger } from "utils/logger";
import { ErrorComponent } from "../../empty-state";

interface BehaviorGuardProps {
  children: React.ReactNode;
}

export const BehaviorGuard = ({ children }: BehaviorGuardProps) => {
  const { state } = useUnsafeBehavior();

  /** Load behavior if it's in INITIAL state */
  useEffect(() => {
    if (state === "INITIAL") {
      try {
        behaviorManager.execute(new LoadBehaviorCommand());
      } catch (err) {
        logger.error(err);
      }
    }
  }, [state]);

  /** Initialize behavior after it's loaded */
  useEffect(() => {
    if (state === "LOADED") {
      try {
        if (!window.location.pathname.startsWith('/acesso')) {
          behaviorManager.execute(new InitializeBehaviorCommand());
        }
      } catch (err) {
        logger.error(err);
      }
    }
  }, [state]);

  if (state === "INITIAL") {
    return <OLoader absolute data-component="BehaviorGuard" />;
  }

  if (state === "ERROR") {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <ErrorComponent
          messageTitle="Ocorreu um problema com a inicialização do portal"
          messageParagraph="Nossa equipe já foi notificada. Tente recarregar a página."
        />
      </div>
    );
  }

  return children as JSX.Element;
};
