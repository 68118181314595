import * as yup from "yup";
import { SettlementStrategyForm } from "../../settlement-strategies.types";
import { ProductType } from "../../settlement-strategies.utils";

export const addSettlementStrategyDefaultValues: SettlementStrategyForm = {
  tipoProduto: ProductType.Cartao,
  cedenteId: undefined,
  sacadoId: undefined,
  estrategiaComercialId: undefined,
  settlementMethodCode: "",
  settlementLockInfo: "",
  bankAccount: {},
};

const bankAccountValidator = yup.string().when("settlementMethodCode", {
  is: (val: string) => val === "INTN",
  then: (schema) => schema.required("Este campo é obrigatório"),
  otherwise: (schema) => schema.notRequired(),
});

export const addSettlementStrategySchema: yup.ObjectSchema<SettlementStrategyForm> =
  yup.object({
    tipoProduto: yup.mixed<ProductType>().required("Este campo é obrigatório"),
    cedenteId: yup.number(),
    sacadoId: yup.number(),
    estrategiaComercialId: yup.number(),
    settlementLockInfo: yup.string().required("Este campo é obrigatório"),
    settlementMethodCode: yup.string().required("Este campo é obrigatório"),
    bankAccount: yup
      .object({
        companyId: bankAccountValidator,
        bankCode: bankAccountValidator,
        branch: bankAccountValidator,
        branchCheckDigit: yup.string(),
        accountNumber: bankAccountValidator,
        accountCheckDigit: yup.string(),
      })
      .required(),
  });
