import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByBillingReportIdRouteParams } from "../../../../../../../routes/energia.route-params";

export const useBillingReport = () => {
  const { billingReportId } = useParams<MleByBillingReportIdRouteParams>();
  if (!billingReportId) throw new Error("No billingReportId");

  const {
    callService: _fetch,
    loading: fetchLoading,
    hasError: fetchHasError,
    error: fetchError,
    value: fetchValue,
  } = useServiceCall(service.hubEnergy.getBillingReportById);

  const getBillingReport = useCallback(
    () => _fetch(billingReportId),
    [_fetch, billingReportId],
  );

  useEffect(() => {
    getBillingReport();
  }, [getBillingReport]);

  return {
    billingReportId,
    getBillingReport,
    fetchError,
    fetchHasError,
    fetchLoading,
    value: fetchValue?.response,
  };
};
