import { OIcon, OSwitch } from "@maestro/react";
import styled from "styled-components";

export const Switch = styled(OSwitch)`
  display: flex;
`;

export const CenteredIcon = styled(OIcon)`
  i {
    line-height: 1 !important;
  }
`;
