export const accRouteParams = {
  id: "id",
  orderId: "orderId",
  preTicketId: "preTicketId",
} as const;

type ACCParams = Record<keyof typeof accRouteParams, string>;

export type ACCOperacoesByIdRouteParams = Pick<ACCParams, "id">;

export type ACCNaturezasByIdRouteParams = Pick<ACCParams, "id">;

export type ACCPreticketByIdRouteParams = Pick<ACCParams, "id" | "orderId">;
