import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { FormValuesType } from "./group-form.types";

export const useGroupForm = () => {
  const [loading, setLoading] = useState(false);

  const { watch, setValue } = useFormContext<FormValuesType>();
  const watchTaxId = watch("taxId");

  const getAdminCompany = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.getAdminCompany({
        taxId: watchTaxId,
      });

      setValue("officialName", data.legalName);
    } catch (err) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [setValue, watchTaxId]);

  useEffect(() => {
    if (validators.cnpj(watchTaxId)) getAdminCompany();
  }, [getAdminCompany, watchTaxId]);

  return {
    loading,
    watchTaxId,
  };
};
