import { OField, OOptionMultiple, OSelectMultiple } from "@maestro/react";
import { useEffect } from "react";
import { getValueFromMap } from "utils/get-value-from-map";

interface MultiSelectAllProps {
  id: string;
  items: string[];
  label: string;
  mapping: Record<string, string>;
  selected: any[];
  setSelected: (values: any[]) => void;
}

export const MultiSelectAll = ({
  id,
  items,
  label,
  mapping,
  selected,
  setSelected,
}: MultiSelectAllProps) => {
  const handleSelect = (selectedItem: any) => {
    if (!selected) {
      setSelected([selectedItem]);
      return;
    }

    if (!selected.includes(selectedItem)) {
      setSelected([...selected, selectedItem]);
    } else {
      setSelected(selected.filter((s) => s !== selectedItem));
    }
  };

  const selectAll = () => {
    if (items && selected?.length !== items.length) {
      setSelected(items);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    // .getElementsByClassName return an array, so get the first and only element;
    const selectBox = document
      .getElementById(id)
      ?.getElementsByClassName("o-select__header")[0];

    if (!selectBox) {
      return;
    }

    const selectBoxText = selectBox.children[0];

    if (selected && selected.length > 0) {
      selectBoxText.textContent = selected
        .map((item) => (mapping ? getValueFromMap(mapping, item) : item))
        .join(", ");
    } else {
      selectBoxText.textContent = "Selecione:";
    }
  }, [id, items, mapping, selected]);

  return (
    <OField htmlFor={id} label={label}>
      <OSelectMultiple id={id} disabled={!(items && items.length > 0)}>
        <OOptionMultiple
          key="selectAll"
          value=""
          onClick={selectAll}
          selected={selected && items && selected.length === items.length}
        >
          Todas as unidades
        </OOptionMultiple>
        {items?.map((item) => (
          <OOptionMultiple
            key={item}
            value={item}
            onClick={() => handleSelect(item)}
            selected={selected && selected.includes(item)}
          >
            {mapping ? getValueFromMap(mapping, item) : item}
          </OOptionMultiple>
        ))}
      </OSelectMultiple>
    </OField>
  );
};
