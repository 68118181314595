import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInputDate,
  OTypography,
  modalManager,
} from "@maestro/react";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { vigencyFields } from "./vigency-select-modal.form";
import { vigencyValidationSchema } from "./vigency-select-modal.schema";
import { VigencyForm } from "./vigency-select-modal.types";

export const vigencySelectModalId = `vigency-select-modal`;

interface VigencySelectModalProps {
  maxVigency: string | undefined;
  message: string | undefined;
  submit: (vigency: string) => void;
  editing?: boolean;
}

export const VigencySelectModal = ({
  maxVigency,
  message,
  submit,
  editing,
}: VigencySelectModalProps) => {
  const form = useForm<VigencyForm>({
    resolver: yupResolver(vigencyValidationSchema),
  });

  const { handleSubmit } = form;

  return (
    <OModal id={vigencySelectModalId} position="center" size="sm" backdrop>
      <OModalHeader>
        <OTypography size="lg">
          {editing ? "Editar perfis de acesso" : "Adicionar usuário"}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-3" style={{ whiteSpace: "normal" }}>
          {message}
        </OTypography>
        <FormProvider {...form}>
          <ORFieldInputDate
            {...vigencyFields.vigencyDate}
            disabledDate={
              maxVigency
                ? (date) => {
                    const isAfterMaxVigency = dayjs(date.toDate()).isAfter(
                      dayjs(maxVigency),
                      "day",
                    );

                    return isAfterMaxVigency;
                  }
                : undefined
            }
          />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_vigencia:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(vigencySelectModalId)}
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_vigencia:botao:enviar"
            dataLabel="enviar"
            onClick={handleSubmit(({ vigencyDate }) => {
              submit(vigencyDate);
              modalManager.hide(vigencySelectModalId);
            })}
          >
            Enviar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
