export const reportTypeOptions: Record<string, string> = {
  ComprovanteDemonstrativo: "Comprovante e Demonstrativo",
  Comprovante: "Comprovante",
  Demonstrativo: "Demonstrativo",
  Planilha: "Planilha (Excel)",
};

interface StatusOptions {
  label: string;
  type: "danger" | "warning" | "success" | "info" | "secondary";
}

export const statusOptions: Record<string, StatusOptions> = {
  Error: {
    label: "ERRO NA BUSCA",
    type: "danger",
  },
  Expired: {
    label: "EXPIRADO",
    type: "warning",
  },
  Requested: {
    label: "SOLICITADO",
    type: "success",
  },
  Processed: {
    label: "PROCESSADO",
    type: "info",
  },
  Processing: {
    label: "PROCESSANDO",
    type: "secondary",
  },
  NoContent: {
    label: "NÃO GERADO",
    type: "warning",
  },
};
