import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { ProfileMasterDetail } from "./_compose";
import { Checkbox } from "./_compose/checkbox";
import { Profile } from "./profile-selector.types";

export const buildDataSource = (
  identification: string,
  setProfiles: (profiles: Profile[]) => void,
  setCeilingVigencyDate: (maxVigency: string) => void,
) =>
  dataSourceCustomStoreGenerator<Profile>(
    () =>
      service.onboardingRelationships
        .getProfiles({
          companyTaxId: identification,
        })
        .then(({ data }) => {
          setProfiles(data.profiles);
          setCeilingVigencyDate(data.ceilingVigencyDate);
          return data.profiles;
        })
        .catch(() => {
          const errorMessage = "Erro ao buscar as funções";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        key: "uuid",
      },
    },
  );

export const roleAssingmentsGrid: ODataGridGeneratorConfig<Profile> = {
  datagrid: {
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
    },
  },
  columns: [
    {
      cellRender: ({ data }) => <Checkbox profile={data} />,
      width: 40,
      alignment: "center",
    },
    {
      dataField: "nameLabel",
      caption: "Função",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "groupNameLabel",
      caption: "Categoria",
      groupIndex: 0,
      groupCellRender: ({ displayValue }) => displayValue,
    },
  ],
  paging: {
    pageSize: 20,
  },
  masterDetail: {
    enabled: true,
    component: ProfileMasterDetail,
  },
};
