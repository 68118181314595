import { SelectSearchMultiple } from "components/form";
import { useSelectCompanies } from "./select-companies.hook";

export const SelectCompanies = () => {
  const { allCompanies } = useSelectCompanies();

  return (
    <SelectSearchMultiple
      label="Nome ou Identificação das Empresas"
      id="companiesIds"
      name="companiesIds"
      placeholder="Selecionar"
      options={allCompanies}
      maxOptions={50}
    />
  );
};
