import * as yup from "yup";

export const generalInfoFormValidationSchema = yup.object().shape({
  type: yup
    .string()
    .nullable()
    .trim()
    .required("Campo obrigatório")
    .matches(
      /^[^`~!@#$%^&*()+-={}[\]|\\:;“’<,>.?๐฿ ç]*$/,
      "O campo não deve conter caracteres especiais ou espaços. Utilize _ como separador",
    )
    .uppercase("O tipo do componente deve ser em maiúsculo"),
  description: yup.string().nullable().trim().required("Campo obrigatório"),
  eventName: yup
    .string()
    .nullable()
    .trim()
    .test(
      "nospaces",
      "Não pode conter espaços",
      (value) => !value?.includes(" ") ?? false,
    ),
  canBeWfEntry: yup.array().default([]),
  hasUserAction: yup.array().default([]),
});

export const generalInfoFormDefaultValues = {
  type: "",
  description: "",
  eventName: "",
  canBeWfEntry: [] as string[],
  hasUserAction: [] as string[],
};
