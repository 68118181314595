import { useCallback, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SelectSearchV2Option } from "../select-search.types";
import { SelectSearchFieldV2Option } from "./select-search-field.types";
import { getSelectedValuesFromWatch } from "./select-search-field.utils";

const searchCharactersToIgnore = /[^0-9A-Z]+/gi;

export const useSelectSearchFieldV2 = <T>(
  name: string,
  multiple: boolean,
  originalOptions: SelectSearchFieldV2Option<T>[],
) => {
  const inputRef = useRef<HTMLOInputTextElement>(null);

  const [searchText, setSearchText] = useState<string>();

  const form = useFormContext();

  const { formState, getFieldState, setValue, watch } = form;

  const { error: fieldErrors } = getFieldState(name, formState);

  const fieldWatch: T | T[] = watch(name);

  /** Internal value is always an array */
  const internalValue = useMemo(
    () => getSelectedValuesFromWatch(fieldWatch),
    [fieldWatch],
  );

  /** Sets form value and single value or array, based on `multiple` prop */
  const setInternalValue = useCallback(
    (value: T[]) => {
      if (multiple) {
        setValue(name, value, {
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        setValue(name, value[0], {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    },
    [multiple, name, setValue],
  );

  /** Filter shown options based on searchValue */
  const filteredOptions = useMemo(
    () =>
      originalOptions.filter(
        (option) =>
          !searchText ||
          !!option.content
            ?.replace(searchCharactersToIgnore, "")
            ?.match(
              RegExp(searchText?.replace(searchCharactersToIgnore, ""), "i"),
            ),
      ),
    [originalOptions, searchText],
  );

  /** Add onClick and selected */
  const options: SelectSearchV2Option[] = useMemo(
    () =>
      filteredOptions.map((option) => ({
        content: option.content,
        key: String(option.value),
        onClick: (evt) => {
          setInternalValue(
            internalValue.includes(option.value) // is selected
              ? multiple
                ? internalValue.filter((oldValue) => oldValue !== option.value)
                : []
              : multiple
              ? [...internalValue, option.value]
              : [option.value],
          );
          option.onClick?.(evt);
          if (inputRef.current) inputRef.current.value = "";
          setSearchText(undefined);
          if (multiple) evt.stopPropagation(); // prevent dropdown closing
        },
        selected: !!internalValue.includes(option.value),
      })),
    [filteredOptions, internalValue, multiple, setInternalValue],
  );

  return {
    fieldErrors,
    inputRef,
    internalValue,
    options,
    setInternalValue,
    setSearchText,
  };
};
