import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useIdContaDeRetirada = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const {
    callService,
    loading,
    value: accounts,
    hasError,
  } = useServiceCall(service.adminBankinghub.getAccounts);

  const { clearErrors, setError } = useFormContext();

  const getAccounts = useCallback(async () => {
    const { success } = await callService({
      taxId: identification,
    });
    if (success) clearErrors("idContaDeRetirada");
    else
      setError("idContaDeRetirada", {
        message:
          "Erro ao buscar as contas. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, identification, setError]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return {
    accounts,
    getAccounts,
    loading,
    hasFetchError: hasError,
  };
};
