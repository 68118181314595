import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { Contract } from "../../contracts-grid.types";
import { generateContractFilename } from "./grid-actions.utils";

export const useGridActions = (contract: Contract) => {
  const { callService: _download, loading: downloadLoading } = useServiceCall(
    service.paperclipv2.getContractFileBinary,
  );

  const { callService: _downloadSigned, loading: downloadSignedLoading } =
    useServiceCall(service.paperclipv2.getContractSignedFileBinary);

  const { callService: _sendNotifications, loading: sendNotificationsLoading } =
    useServiceCall(service.paperclipv2.sendNotifications);

  const {
    callService: _reprocessContracts,
    loading: reprocessContractsLoading,
  } = useServiceCall(service.onboarding.reprocessContracts);

  const {
    callService: _sendSignedContractToLess,
    loading: sendSignedContractToLessLoading,
  } = useServiceCall(service.onboarding.sendSignedContractToLess);

  const {
    callService: _reprocessSignatories,
    loading: reprocessSignatoriesLoading,
  } = useServiceCall(service.paperclipv2.updateSignatories);

  const downloadContract = useCallback(async () => {
    const { success, response } = await _download(contract.UUID);
    if (success) {
      const filename = generateContractFilename(contract.CreatedAt, false);
      helpers.downloadBlobFile(filename, response.data);
      OToastManager.success("Download feito com sucesso");
    } else {
      OToastManager.danger("Não foi possível baixar o contrato");
    }
  }, [_download, contract.CreatedAt, contract.UUID]);

  const downloadSignedContract = useCallback(async () => {
    const { success, response } = await _downloadSigned(contract.UUID);
    if (success) {
      const filename = generateContractFilename(contract.CreatedAt, true);
      helpers.downloadBlobFile(filename, response.data);
      OToastManager.success("Download feito com sucesso");
    } else {
      OToastManager.danger("Não foi possível baixar o contrato");
    }
  }, [_downloadSigned, contract.CreatedAt, contract.UUID]);

  const resendNotifications = useCallback(async () => {
    const { success } = await _sendNotifications(contract.UUID);
    if (success) OToastManager.success("Notificações reenviadas");
    else OToastManager.danger("Não foi possível reenviar as notificações");
  }, [_sendNotifications, contract.UUID]);

  const reprocessContracts = useCallback(async () => {
    if (!contract.CompanyCNPJ)
      return OToastManager.danger("A empresa não possui CNPJ");
    const { success } = await _reprocessContracts({
      taxIds: [contract.CompanyCNPJ],
      cancelOldContract: true,
      type: contract.ContractType,
      notifications: ["StripeBanner", "Bell"],
    });
    if (success)
      OToastManager.success(
        "O documento solicitado foi gerado com sucesso, aguarde enquanto os dados complementares são consultados para a criação do pdf",
      );
    else OToastManager.danger("Não foi possível executar o reprocessamento");
  }, [_reprocessContracts, contract.CompanyCNPJ, contract.ContractType]);

  const sendSignedContractToLess = useCallback(async () => {
    const { success } = await _sendSignedContractToLess(contract.UUID);
    if (success) OToastManager.success("Contrato enviado para o LESS");
    else OToastManager.danger("Não foi possível enviar o contrato para o LESS");
  }, [_sendSignedContractToLess, contract.UUID]);

  const reprocessSignatories = useCallback(async () => {
    const { success, response } = await _reprocessSignatories({
      Contracts: [{ uuid: contract.UUID, isIris: true }],
    });
    const data = response?.data[0];
    if (success && data?.isSuccessful)
      OToastManager.success(
        `${data?.qtdUpdated} assinante(s) reprocessados com sucesso`,
      );
    else OToastManager.danger("Não foi possível reprocessar os assinantes");
  }, [_reprocessSignatories, contract.UUID]);

  return {
    downloadContract,
    downloadSignedContract,
    reprocessContracts,
    resendNotifications,
    sendSignedContractToLess,
    reprocessSignatories,
    loading:
      downloadLoading ||
      downloadSignedLoading ||
      sendNotificationsLoading ||
      reprocessContractsLoading ||
      sendSignedContractToLessLoading ||
      reprocessSignatoriesLoading,
  };
};
