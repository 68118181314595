import { env } from "utils/environments";

export const turtlefeeEndpoints = {
  businessagreement: {
    byId: (id: string | number) =>
      `${env.API_TURTLEFEE}/businessagreement/${id}`,
    endpoint: `${env.API_TURTLEFEE}/businessagreement`,
    search: `${env.API_TURTLEFEE}/businessagreement/search`,
  },
};
