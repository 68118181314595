import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";
import { transferStatusMap } from "./transfers.utils";

export const grid: ODataGridGeneratorConfig<BankingHub.v2.Payment> = {
  datagrid: {
    noDataText: "Nenhuma transferência encontrada",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "registeredTimestamp",
      caption: "Data",
      cellRender: ({ data }) => format.dateTime(data.registeredTimestamp),
    },
    {
      caption: "Agência",
      cellRender: ({ data }) => data.debitParty?.branch ?? "-",
    },
    {
      caption: "Conta Corrente",
      cellRender: ({ data }) => data.debitParty?.account ?? "-",
    },
    {
      caption: "Favorecido",
      cellRender: ({ data }) => data.creditParty?.name ?? "-",
    },
    {
      caption: "Valor",
      cellRender: ({ data }) =>
        masks.currency(data.amount ?? 0, currencySymbol.BRL, "."),
    },
    {
      caption: "Status",
      cellRender: ({ data }) =>
        transferStatusMap(
          data.paymentStatus ?? "",
          data.accepted ?? false,
          data.movements ?? [],
        ),
    },
  ],
};
