import { OCard, OCardBody, OCardHeader } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";

interface DashboardOnboardingsInfosProps {
  onboardingType: string;
  sacadoIds: number[];
}

export const DashboardOnboardingsInfos = ({
  onboardingType,
  sacadoIds,
}: DashboardOnboardingsInfosProps) => {
  const {
    callService: getInfos,
    loading,
    hasError,
    value: infos,
  } = useServiceCall(service.quickfin.getDashboardOnboardingsInfos);

  useEffect(() => {
    getInfos({
      TipoOnboarding: onboardingType,
      SacadoIdList: sacadoIds,
    });
  }, [getInfos, onboardingType, sacadoIds]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={loading}
      noValue
      errorComponent={
        <ErrorComponent
          messageTitle="Não foi possível buscar as informações"
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton
            onClick={() =>
              getInfos({
                TipoOnboarding: onboardingType,
                SacadoIdList: sacadoIds,
              })
            }
          />
        </ErrorComponent>
      }
      render={() => (
        <OCard>
          <OCardBody>
            <div className="d-flex flex-wrap gap-4">
              <div>
                <OCard>
                  <OCardHeader>Não iniciados</OCardHeader>
                  <OCardBody>{infos?.naoIniciados}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Em progressos</OCardHeader>
                  <OCardBody>{infos?.emProgresso}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Perfil acesso</OCardHeader>
                  <OCardBody>{infos?.perfilDeAcesso}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Envio docs</OCardHeader>
                  <OCardBody>{infos?.envioDeDocs}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Em análise</OCardHeader>
                  <OCardBody>{infos?.emAnalise}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Docs pendentes</OCardHeader>
                  <OCardBody>{infos?.docsPendentes}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Rejeitados</OCardHeader>
                  <OCardBody>{infos?.rejeitados}</OCardBody>
                </OCard>
              </div>
              <div>
                <OCard>
                  <OCardHeader>Concluídos</OCardHeader>
                  <OCardBody>{infos?.concluidos}</OCardBody>
                </OCard>
              </div>
            </div>
          </OCardBody>
        </OCard>
      )}
    />
  );
};
