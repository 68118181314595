import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { corporateRouter } from "routes/corporate-router.context";
import { useNavigate } from "react-router-dom";
import { FormValuesType } from "../_compose/template-config/template-config.types";
import { validationSchema } from "../_compose/template-config/template-config.form";

export const useSemaphoreTemplateAdd = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const { identifier, description } = values;

      const { data } = await service.onboarding.addSemaphoreTemplate({
        identifier,
        description,
      });

      OToastManager.success("Template de semáforo adicionado com sucesso");

      navigate(
        corporateRouter.routes.ferramentas.product.onboarding.semaphore.configuration.template.details.path(
          {
            uuid: data.uuid,
          },
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
