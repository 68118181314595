import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useDeskSelector = () => {
  const {
    callService,
    loading,
    value: desks,
    hasError,
  } = useServiceCall(service.clerk.getDesks);

  const { clearErrors, setError } = useFormContext<{ desks: unknown }>();

  const getDesks = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors();
    else
      setError("desks", {
        message:
          "Erro ao buscar as mesas. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getDesks();
  }, [getDesks]);

  return {
    getDesks,
    hasError,
    loading,
    desks,
  };
};
