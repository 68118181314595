import { masks } from "@maestro/utils";
import { InfoCard } from "../info-card";
import { useOperationsDashboard } from "./operations-dashboard.hook";

export const OperationsDashboard = () => {
  const {
    dailySummaryHasError,
    dailySummaryLoading,
    dailySummaryValue,
    getDailySummary,
    getTradesMaturing,
    tradesMaturingHasError,
    tradesMaturingLoading,
    tradesMaturingValue,
  } = useOperationsDashboard();

  const maturingTradesTotal =
    tradesMaturingValue
      ?.filter((trade) =>
        ["TradeEmAtraso", "TradeDesembolsado", "TradeLiquidado"].includes(
          trade.state,
        ),
      )
      .reduce((acc, curr) => acc + curr.amount, 0) ?? 0;

  const diffIncomingOutgoing =
    (dailySummaryValue?.valorDesembolsoAntecipadoTotal || 0) -
    maturingTradesTotal;

  return (
    <div className="d-flex gap-3">
      <InfoCard
        footerFirstLabel="Líquido"
        footerFirstValue={masks.currency(
          dailySummaryValue?.valorDesembolsoAntecipadoTotal ?? 0,
          "R$",
        )}
        footerSecondLabel="Quantidade"
        footerSecondValue={
          dailySummaryValue?.tradesAntecipadosQtd?.toString() ?? "0"
        }
        refetch={getDailySummary}
        title="Desembolsados"
        value={masks.currency(
          dailySummaryValue?.valorVencimentoAntecipadoTotal ?? 0,
          "R$",
        )}
        hasError={dailySummaryHasError}
        loading={dailySummaryLoading}
      />

      <InfoCard
        footerFirstLabel="Líquido"
        footerFirstValue={masks.currency(
          dailySummaryValue?.valorDesembolsoRecusadoTotal ?? 0,
          "R$",
        )}
        footerSecondLabel="Quantidade"
        footerSecondValue={
          dailySummaryValue?.tradesRecusadosQtd?.toString() ?? "0"
        }
        refetch={getDailySummary}
        title="Recusados"
        value={masks.currency(
          dailySummaryValue?.valorVencimentoRecusadoTotal ?? 0,
          "R$",
        )}
        hasError={dailySummaryHasError}
        loading={dailySummaryLoading}
      />

      <InfoCard
        footerFirstLabel="Líquido"
        footerFirstValue={masks.currency(
          dailySummaryValue?.valorDesembolsoPendenteTotal ?? 0,
          "R$",
        )}
        footerSecondLabel="Quantidade"
        footerSecondValue={
          dailySummaryValue?.tradesPendentesQtd?.toString() ?? "0"
        }
        refetch={getDailySummary}
        title="Pendentes"
        value={masks.currency(
          dailySummaryValue?.valorVencimentoPendenteTotal ?? 0,
          "R$",
        )}
        hasError={dailySummaryHasError}
        loading={dailySummaryLoading}
      />

      <InfoCard
        footerFirstLabel="Entrada"
        footerFirstValue={masks.currency(
          dailySummaryValue?.valorVencimentoAntecipadoTotal ?? 0,
          "R$",
        )}
        footerSecondLabel="Saída"
        footerSecondValue={masks.currency(maturingTradesTotal ?? 0, "R$")}
        refetch={() => {
          getDailySummary();
          getTradesMaturing();
        }}
        title="Resultado"
        value={masks.currency(diffIncomingOutgoing ?? 0, "R$")}
        hasError={dailySummaryHasError || tradesMaturingHasError}
        loading={dailySummaryLoading || tradesMaturingLoading}
      />
    </div>
  );
};
