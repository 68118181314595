import { OCheckbox, OTooltip } from "@maestro/react";
import { useMemo } from "react";
import { useProfileSelector } from "../../profile-selector.context";
import { Profile } from "../../profile-selector.types";
import { TooltipContent } from "./checkbox.styles";

interface CheckboxProps {
  profile: Profile;
}

export const Checkbox = ({ profile }: CheckboxProps) => {
  const { blockedProfiles } = useProfileSelector();

  const profilesBlocking = blockedProfiles[profile.uuid];
  const isBlocked = profilesBlocking?.length > 0;

  const isDisabled = !profile.isAvailable || isBlocked;

  const checkbox = useMemo(
    () => (
      <OCheckbox
        id={profile.uuid}
        value={profile.uuid}
        size="xs"
        disabled={isDisabled}
      />
    ),
    [isDisabled, profile.uuid],
  );

  const tooltipMessage = !profile.isAvailable
    ? "Função indisponível"
    : "Esta função foi desabilitada, pois está disponível " +
      (blockedProfiles[profile.uuid]?.length > 1
        ? "nas funções selecionadas:"
        : "na função selecionada:");

  return isDisabled ? (
    <OTooltip maxWidth="400px" floating>
      <TooltipContent slot="tooltip-content">
        {tooltipMessage}
        <ul>
          {blockedProfiles[profile.uuid]?.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </ul>
      </TooltipContent>
      {checkbox}
    </OTooltip>
  ) : (
    checkbox
  );
};
