import { roles } from "roles/roles";
import { MenuTabs } from "../tab-menu.types";

export const corbanMenuTabs = {
  customer: [
    {
      name: "Cadastro",
      path: "/cadastro/cliente",
      role: roles.cadastro.customer.tab.role,
    },
    {
      name: "Usuários",
      path: "/usuarios/cliente",
      role: roles.usuarios.customer.tab.role,
    },
    {
      name: "ACC",
      path: "/acc/cliente",
      role: roles.acc.customer.tab.role,
    },
    {
      name: "Antecipação de Cartões",
      path: "/antecipacao-de-cartoes/cliente",
      role: roles.antecipacaoDeCartoes.customer.tab.role,
    },
    {
      name: "Antecipação a Fornecedor",
      path: "/antecipacao-a-fornecedor/cliente",
      role: roles.antecipacaoAFornecedor.customer.tab.role,
    },
    {
      name: "Câmbio",
      path: "/cambio/cliente",
      role: roles.cambio.customer.tab.role,
    },
    {
      // legacy
      name: "Cartões",
      path: "/cartoes/cliente",
      role: roles.cartoes.customer.tab.role,
    },
    {
      name: "Conta Corrente",
      path: "/banking/cliente",
      role: roles.banking.customer.tab.role,
    },
    {
      name: "Duplicatas",
      path: "/duplicatas/cliente",
      role: roles.duplicatas.customer.tab.role,
    },
    {
      name: "Empréstimos",
      path: "/emprestimos/cliente",
      role: roles.emprestimos.customer.tab.role,
    },
    {
      name: "Energia",
      path: "/energia/cliente",
      role: roles.energia.customer.tab.role,
    },
    {
      name: "Folha de Pagamento",
      path: "/folha-de-pagamento/cliente",
      role: roles.fopa.customer.tab.role,
    },
    {
      name: "Catálogo de produtos",
      path: "/workflow-rmadmin/cliente",
      role: roles.workflowRmAdmin.customer.tab.role,
    },
    {
      name: "Recebíveis",
      path: "/recebiveis/cliente",
      role: roles.recebiveis.customer.tab.role,
    },
    {
      name: "Renegociação/Quitação",
      path: "/renegociacoes/cliente",
      role: roles.renegociacao.customer.tab.role,
    },
    {
      name: "Developers",
      path: "/developers/cliente",
      role: roles.developers.product.tab.role,
    },
  ],
  product: [
    {
      name: "Catálogo de produtos",
      path: "/workflow-rmadmin/produto",
      role: roles.workflowRmAdmin.product.tab.role,
    },
    {
      name: "Cadastro",
      path: "/finders/produto/clientes",
      role: roles.b2b.product.clientesPage.role,
    },
    {
      name: "Clientes assessorados",
      path: "/clientes-assessorados",
      role: roles.advisoredCustomers.product.clientesAssessoradosPage.role,
    },
    {
      name: "Simulador",
      path: "/finders/produto/simulador",
      role: roles.b2b.product.simuladorPage.role,
    },
    {
      name: "Operações",
      path: "/finders/produto/operacoes",
      role: roles.b2b.product.operacoesPage.role,
    },
    {
      name: "Perfil",
      path: "/finders/produto/perfil",
      role: roles.b2b.product.catalogoPage.role,
    },
    {
      name: "Materiais de apoio",
      path: "/finders/produto/atendimento/materiais-de-apoio",
      role: roles.b2b.product.materiaisDeApoioPage.role,
    },
    {
      name: "Entre em contato",
      path: "/finders/produto/atendimento/entre-em-contato",
      role: roles.b2b.product.entreEmContatoPage.role,
    },
  ],
} satisfies MenuTabs;
