import { client } from "../../client";
import { adminBankinghubEndpoints } from "../admin-bankinghub.endpoints";
import {
  GetCardOffersParams,
  PatchCardOffersNotificationsBody,
  GetCardCompaniesTransactionsSummaryParams,
} from "./models/requests";
import {
  GetCardAccountsSummaryResponse,
  GetCardCardsSummaryResponse,
  GetCardContestationsSummaryResponse,
  GetCardOffersResponse,
  GetCardOffersSummaryResponse,
  GetCardTransactionsSummaryResponse,
  GetCardCompaniesTransactionsSummaryResponse,
} from "./models/responses";

export const adminBankinghubCardService = {
  getAccountsSummary() {
    return client.get<GetCardAccountsSummaryResponse>(
      adminBankinghubEndpoints.card.accounts.summary,
    );
  },
  getCardsSummary() {
    return client.get<GetCardCardsSummaryResponse>(
      adminBankinghubEndpoints.card.cards.summary,
    );
  },
  getContestationsSummary() {
    return client.get<GetCardContestationsSummaryResponse>(
      adminBankinghubEndpoints.card.contestations.summary,
    );
  },
  getOffersSummary() {
    return client.get<GetCardOffersSummaryResponse>(
      adminBankinghubEndpoints.card.offers.summary,
    );
  },
  getTransactionsSummary() {
    return client.get<GetCardTransactionsSummaryResponse>(
      adminBankinghubEndpoints.card.transactions.summary,
    );
  },
  resendOfferNotification(body: PatchCardOffersNotificationsBody) {
    return client.patch(
      adminBankinghubEndpoints.card.offers.notifications,
      body,
    );
  },
  getOffers(params?: GetCardOffersParams) {
    return client.get<GetCardOffersResponse>(
      adminBankinghubEndpoints.card.offers.endpoint,
      {
        params,
      },
    );
  },
  getCompaniesTransactionsSummary(
    params?: GetCardCompaniesTransactionsSummaryParams,
  ) {
    return client.get<GetCardCompaniesTransactionsSummaryResponse>(
      adminBankinghubEndpoints.card.companies.transactions.summary,
      {
        params,
      },
    );
  },
};
