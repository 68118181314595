import { ODataGridGenerator } from "components/data-grid/o-data-grid-generator.component";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, reconciliationsGrid } from "./reconciliations.grid";
import { DateFilter } from "./_compose";

export const Reconciliations = () => {
  const gridRef = useGridRef();
  const [datesInterval, setDatesInterval] = useState<[string, string]>([
    dayjs().startOf("month").format("YYYY/MM/DD"),
    dayjs().endOf("month").format("YYYY/MM/DD"),
  ]);
  const dataSource = useMemo(
    () => buildDataSource(...datesInterval),
    [datesInterval],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Reconciliações" />}
      gridRef={gridRef}
      showRefreshButton
      actions={
        <DateFilter
          datesInterval={datesInterval}
          setDatesInterval={setDatesInterval}
        />
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={reconciliationsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
