import { OToastManager, modalManager } from "@maestro/core";
import {
  OButton,
  OCheckbox,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { TooltipIcon } from "components/tooltip-icon";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services/service";
import {
  contractTypeMap,
  notificationTypesList,
} from "./generate-contract.utils";

interface GenerateContractProps {
  workflowUuid?: string;
  dataUpdateUuid?: string;
  dataReviewUuid?: string;
  modalId: string;
}

export const GenerateContract = ({
  workflowUuid,
  dataReviewUuid,
  dataUpdateUuid,
  modalId,
}: GenerateContractProps) => {
  const { customer } = useCustomer();
  const form = useForm();
  const { handleSubmit } = form;

  const { callService, loading } = useServiceCall(
    service.onboarding.batchCreateContract,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await callService({
          taxIds: [customer.identification],
          type: values.contractType,
          notifications: values.notificationTypes,
          startSignatureFlow: true,
          workflowLevelsUuid: workflowUuid,
          dataReviewUuid,
          dataUpdateUuid,
        });

        if (success) {
          OToastManager.info("Contrato criado com sucesso.");

          modalManager.hide(modalId);
        } else {
          OToastManager.warning("Erro ao criar contrato.");
        }
      }),
    [
      callService,
      customer.identification,
      dataReviewUuid,
      dataUpdateUuid,
      handleSubmit,
      modalId,
      workflowUuid,
    ],
  );

  return (
    <OModal id={modalId} size="sm" position="center">
      <OModalHeader closeButton>
        <PageTitle title=" Selecione que tipo de contrato será gerado" />
        <OTypography>{`Id: ${workflowUuid}`}</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORFieldSelect
            id="contractType"
            name="contractType"
            label="Tipo de contrato"
            placeholder="Selecione"
          >
            {Object.entries(contractTypeMap).map(([value, contractType]) => (
              <OOption key={value} value={value}>
                {contractType.label}
                <TooltipIcon content={<p>{contractType.info}</p>} />
              </OOption>
            ))}
          </ORFieldSelect>
          <ORFieldCheckboxGroup
            id="notificationTypes"
            name="notificationTypes"
            label="Notificação:"
          >
            {notificationTypesList.map((notificationType) => (
              <div
                className="d-flex flex-row gap-2 align-items-center"
                key={notificationType}
              >
                <OCheckbox
                  size="xs"
                  id={`${notificationType}-checkbox`}
                  value={notificationType}
                />
                <OLabel htmlFor={`${notificationType}-checkbox`}>
                  {notificationType}
                </OLabel>
              </div>
            ))}
          </ORFieldCheckboxGroup>
        </FormProvider>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex flex-row justify-content-end gap-2">
          <OButton outline onClick={() => modalManager.hide(modalId)}>
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={() => submit()}>
            Confirmar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
