import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Convenio } from "./convenios.types";

export const dataSource = dataSourceCustomStoreGenerator<Convenio>(
  (loadOptions) =>
    service.recebiveisCartao
      .getConvenios(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os convênios";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

export const conveniosGrid: ODataGridGeneratorConfig<Convenio> = {
  datagrid: {
    noDataText: "Nenhum convênio.",
    filterRow: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
    },
    {
      dataField: "tax_id",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "name",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "convenio_guid",
      dataType: "string",
      caption: "Guid do convênio",
    },
  ],
};
