import {
  OCard,
  OCardBody,
  OLoader,
  OOption,
  ORFieldSelect,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useFidcCompanyFunds } from "../../hooks/fidc-company-funds";

export const CompanyFundSelect = () => {
  const { companyFunds, loading } = useFidcCompanyFunds();

  return (
    <OCard>
      <OCardBody>
        <PageTitle title="Selecione um fundo" />
        {companyFunds && !loading ? (
          <ORFieldSelect
            name="selectedFund"
            id="selectedFund"
            dataAction="interacoes_fidc:select:fundo_fidc"
            dataLabel="fundo_fidc"
            placeholder="Selecione aqui"
          >
            {companyFunds
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((fund) => (
                <OOption key={fund.id} value={fund.fund ?? ""}>
                  {fund.name} - {fund.taxId}
                </OOption>
              ))}
          </ORFieldSelect>
        ) : (
          <OLoader />
        )}
      </OCardBody>
    </OCard>
  );
};
