import { client } from "../client";
import {
  PostByMsgTypeParams,
  PostByMsgTypeReprocessParams,
} from "./models/requests";
import {
  PostByMsgTypeReprocessResponse,
  PostByMsgTypeResponse,
} from "./models/responses";
import { roadwalkerEndpoints } from "./roadwalker.endpoints";

export const roadwalkerService = {
  getMessages(messageType: string, payload: PostByMsgTypeParams) {
    return client.post<PostByMsgTypeResponse>(
      roadwalkerEndpoints.byMsgType.endpoint(messageType),
      payload,
    );
  },

  reprocessMessage(messageType: string, payload: PostByMsgTypeReprocessParams) {
    return client.post<PostByMsgTypeReprocessResponse>(
      roadwalkerEndpoints.byMsgType.reprocess(messageType),
      payload,
    );
  },
};
