import { DetailsFields } from "components/details-card";
import { diligenceFields } from "../../../../../diligence.details";

export const consumerUnitInfoFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): DetailsFields => ({
  consumerUnit: {
    ...diligenceFields(consumerUnit, false).consumerUnit,
    title: "Unidade consumidora",
  },
});
