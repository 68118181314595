import { OButton } from "@maestro/react";
import { useStepsBuilder } from "../../steps-builder.context";

interface NextStepButtonProps
  extends Omit<
    React.ComponentProps<typeof OButton>,
    "dataAction" | "dataLabel"
  > {
  label?: string;
}

export const NextStepButton = (props: NextStepButtonProps) => {
  const { onClick, label = "Próximo" } = props;

  const { stepNumber, nextStep } = useStepsBuilder();

  return (
    <OButton
      dataAction={`entuba_step_${stepNumber}:botao:proximo`}
      dataLabel="proximo"
      {...props}
      onClick={(evt) => {
        onClick?.(evt);
        nextStep();
      }}
    >
      {label}
    </OButton>
  );
};
