import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { AddressFields } from "../../../../../components/address-form/address.component";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { energiaRoles } from "../../../../../roles/energia.roles";
import { DistributorBasicDataFields } from "../../_compose";
import { useDistributorRegistrationData } from "./distributor-registration-data.hook";

export const DistributorRegistrationDataPage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getDistributor,
    submit,
    submitLoading,
  } = useDistributorRegistrationData();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Dados cadastrais" />}
      actions={
        <LoadingButton
          loading={submitLoading}
          onClick={submit}
          role={energiaRoles.product.updateDistributoRegistration.role}
        >
          Salvar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-3 position-relative">
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <ValidationErrorComponent
              error={fetchError}
              title="Erro ao carregar a distribuidora"
              callback={getDistributor}
            />
          }
          value={fetchValue}
          render={() => (
            <FormProvider {...form}>
              <OCard>
                <OCardHeader>
                  <OTypography>Dados cadastrais</OTypography>
                </OCardHeader>
                <OCardBody>
                  <DistributorBasicDataFields disableTaxId />
                </OCardBody>
              </OCard>
              <OCard>
                <OCardHeader>
                  <OTypography>Endereço</OTypography>
                </OCardHeader>
                <OCardBody>
                  <AddressFields />
                </OCardBody>
              </OCard>
            </FormProvider>
          )}
        />
      </div>
    </FormTemplate>
  );
};
