import { OTypography } from "@maestro/react";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  approvedAmountMaskMap,
  approvedAmountTypeMap,
} from "../../../../../components/trade/trade-fields.utils";

interface ProposalCellProps {
  approvedAmountType: string;
  approvedAmount: number;
}

export const ProposalCell = ({
  approvedAmount,
  approvedAmountType,
}: ProposalCellProps) => {
  const label =
    getValueFromMap(approvedAmountTypeMap, approvedAmountType) ??
    approvedAmountType;
  const mask =
    getValueFromMap(approvedAmountMaskMap, approvedAmountType) ??
    approvedAmountMaskMap.DEFAULT;

  return (
    <div className="d-flex flex-column gap-1">
      <OTypography size="sm" type="dark-80">
        {label}
      </OTypography>
      <OTypography>{mask(approvedAmount)}</OTypography>
    </div>
  );
};
