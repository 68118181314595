import { Roles } from "roles/roles.types";

export const fopaRoles = {
  customer: {
    tab: {
      role: "admin:FopaTabCustomer.View",
      description: "Visualização da tab 'Folha de Pagamento' na visão cliente",
    },
    detailsPage: {
      role: "admin:FopaDetailsPageCustomer.View",
      description:
        "Visualização da página 'Folha de Pagamento - Detalhes' na visão cliente",
    },
    paymentsPage: {
      role: "admin:FopaPaymentsPageCustomer.View",
      description:
        "Visualização da página 'Folha de Pagamento - Pagamentos' na visão cliente",
    },
    paymentsPaymentDetailsPage: {
      role: "admin:FopaPaymentsPaymentDetailsPageCustomer.View",
      description:
        "Visualização da página 'Folha de Pagamento - Pagamentos - Detalhes do pagamento' na visão cliente",
    },
    onboardingsPage: {
      role: "admin:FopaOnboardingsPageCustomer.View",
      description:
        "Visualização da página 'Folha de Pagamento - Onboarding Funcionários' na visão cliente",
    },
  },
} satisfies Roles;
