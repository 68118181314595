import { env } from "utils/environments";

export const onboardingCoreAccountEndpoints = {
  admin: {
    accountclose: {
      btgAccounts: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/btg-accounts`,
      cancelClose: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/cancel-close`,
      close: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/close`,
      reasons: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/reasons`,
      validateAccount: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/validate-account`,
      getWorkflowDetails: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/get-workflow-details`,
      generateDeclaration: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/get-declaration`,
      downloadDeclaration: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/accountclose/download-declaration`,
    },
    accountOpening: {
      templates: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/templates`,
      getWorkflows: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/get-workflows`,
      getDetailedWorkflow: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/get-detailed-workflow`,
      getMasterDetailedWorkflow: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/get-all-detailed-workflow`,
      requesterResponse: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/requester-response`,
      workflow: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/workflow`,
      verifyOptinByCode: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/verify-optin-by-code`,
      reprocessNotifcation: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/run-notification`,
      changeStatusNotifcation: `${env.API_ONBOARDING_CORE_ACCOUNT}/admin/account-opening/change-status-notification`,
    },
  },
};
