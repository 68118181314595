import { useMemo } from "react";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTooltip, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { TooltipContent } from "components/tooltip-icon";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { FiltersModal, paymentsFilterModalId } from "./_compose/filters-modal";
import {
  SupplierPaymentsProvider,
  usePayments,
} from "./supplier-payments.context";
import { filesGrid } from "./supplier-payments.grid";
import { downloadPaymentsMonthlyReport } from "./supplier-payments.utils";

const SupplierPaymentsComponent = () => {
  const gridRef = useGridRef();
  const { loading, files, getFiles, hasError } = usePayments();

  const gridSummary = useMemo(
    () => (
      <Summary texts={{ sum: "Total: {0}" }}>
        <TotalItem
          column="data.fileSummary.totalAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency: "BRL",
            precision: 3,
          }}
        />
        <TotalItem
          column="data.fileSummary.ineligibleTotalAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency: "BRL",
            precision: 3,
          }}
        />
      </Summary>
    ),
    [],
  );

  return (
    <>
      <FiltersModal />
      <GridTemplate
        pageTitle={<PageTitle title="Pagamentos ao Fornecedor" />}
        gridRef={gridRef}
        actions={
          <div className="d-flex justify-content-between align-items-end w-100">
            <OTypography size="sm" type="dark">
              *Valor não processado refere-se ao total de pagamentos estornados
              e pagamentos que não puderam ser realizados
            </OTypography>

            <div className="d-flex gap-2">
              <IconButton
                dataAction="pagamentos:botao:filtros"
                dataLabel="filtros"
                icon={{ category: "orq", icon: "orq-tools-filter" }}
                onClick={() => modalManager.show(paymentsFilterModalId)}
              >
                Filtros
              </IconButton>
              <RefreshGridButton onClick={() => getFiles()} />
              {files && (
                <OTooltip>
                  <TooltipContent>Exportar relatório</TooltipContent>
                  <OButton
                    dataAction="pagamentos:botao:exportar_relatorio_mensal"
                    dataLabel="exportar_relatorio_mensal"
                    onClick={() => downloadPaymentsMonthlyReport(files)}
                    type="default"
                    outline
                  >
                    <OIcon
                      category="far"
                      icon="fa-file-spreadsheet"
                      style={{ width: "16px" }}
                    />
                  </OButton>
                </OTooltip>
              )}
            </div>
          </div>
        }
      >
        {hasError && (
          <ErrorComponent
            messageTitle="Erro ao buscar pagamentos"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getFiles()} />
          </ErrorComponent>
        )}
        {!hasError && (
          <ODataGridGenerator
            gridRef={gridRef}
            grid={filesGrid}
            dataSource={files}
            loading={loading}
          >
            {gridSummary}
          </ODataGridGenerator>
        )}
      </GridTemplate>
    </>
  );
};

export const SupplierPayments = () => (
  <SupplierPaymentsProvider>
    <SupplierPaymentsComponent />
  </SupplierPaymentsProvider>
);
