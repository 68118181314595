import {
  OAccordion,
  OCollapse,
  OCollapseContent,
  OCollapseHeader,
  OTypography,
} from "@maestro/react";
import React from "react";

export interface FaqItem {
  title: React.ReactNode;
  description: React.ReactNode;
}

export interface FaqProps {
  title?: string;
  items: FaqItem[];
}

export const Faq = (props: FaqProps) => {
  return (
    <>
      {props?.title && (
        <div className="mb-4 bg-transparent">
          <OTypography tag="span" size="md" type="dark">
            {props?.title}
          </OTypography>
          <OTypography tag="span" size="sm" type="dark">
            <i className="fal fa-caret-down" />
          </OTypography>
        </div>
      )}
      <OAccordion>
        {props?.items.map((item) => (
          <OCollapse key={`faq-${item.title?.toString()}`}>
            <OCollapseHeader>
              <OTypography>{item.title}</OTypography>
            </OCollapseHeader>
            <OCollapseContent>
              <div className="pb-3">
                <OTypography type="dark-60" weight="500">
                  {item.description}
                </OTypography>
              </div>
            </OCollapseContent>
          </OCollapse>
        ))}
      </OAccordion>
    </>
  );
};
