import { ORFieldInput } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useUserToCopySelector } from "./user-to-copy-selector.hook";

interface ContractTemplateSelectorProps {
  label: string;
  name: string;
}

export const UserToCopySelector = ({
  name,
  label,
}: ContractTemplateSelectorProps) => {
  const { loading, getUserInformation } = useUserToCopySelector(name);

  const handleSearchClick = () => {
    const inputValue =
      (document.getElementById(name) as HTMLInputElement)?.value || "";
    getUserInformation(inputValue);
  };

  return (
    <div className="d-flex justify-content-between flex-fill">
      <ORFieldInput
        id={name}
        name={name}
        label={label}
        tag="text"
        placeholder="Preencher"
      />
      <LoadingButton
        loading={loading}
        dataAction="user-to-copy:botao:procurar"
        dataLabel="procurar"
        onClick={handleSearchClick}
      >
        Procurar
      </LoadingButton>
    </div>
  );
};
