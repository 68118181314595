import { OToastManager } from "@maestro/core";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { tradesColumns } from "./trades.grid";
import { Trade } from "./trades.types";
import { warnLowBalance } from "./trades.utils";

const buildDataSource = (parentId: string | number) =>
  dataSourceCustomStoreGenerator<Trade>(() =>
    service.recebiveisCartao
      .getTradeChildren(parentId)
      .then(({ data }) => data.trades)
      .catch(() => {
        const errorMessage = "Erro ao buscar trades filhos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const tradesMasterDetailGrid: ODataGridGeneratorConfig<Trade> = {
  datagrid: {
    noDataText: "Trade não possui filhos.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    onCellPrepared: warnLowBalance,
  },
  columns: tradesColumns,
};

type TradesMasterDetailProps = MasterDetailComponentProps<Trade>;

export const TradesMasterDetail = ({
  data: {
    data: { id },
  },
}: TradesMasterDetailProps) => {
  const dataSource = useMemo(() => buildDataSource(id), [id]);
  return (
    <ODataGridGenerator grid={tradesMasterDetailGrid} dataSource={dataSource} />
  );
};
