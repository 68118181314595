import * as yup from "yup";
import {
  ContactFormValuesType,
  FormValuesType,
} from "./current-account-form.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  taxId: yup.string().required("Este campo é obrigatório"),
  officialName: yup.string().required("Este campo é obrigatório"),
  status: yup.string().required("Este campo é obrigatório"),
  desk: yup.string().required("Este campo é obrigatório"),
  officer: yup.string().required("Este campo é obrigatório"),
  account: yup.string().required("Este campo é obrigatório"),
});

export const contactValidationSchema: yup.ObjectSchema<ContactFormValuesType> =
  yup
    .object({
      email: yup.string().required("Este campo é obrigatório").nullable(),
    })
    .concat(validationSchema);
