import { OOption, ORFieldSelect } from "@maestro/react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useFormPropertyField } from "./form-property-field.hook";

export const FormPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { formList } = useFormPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  return (
    <ORFieldSelect
      key={formList?.map((form) => form.identification).join("")}
      id={`properties.${name}_${processorPropertyId}`}
      name={`properties.${name}_${processorPropertyId}`}
      placeholder="Selecione"
      label={description}
    >
      {formList?.map((form) => (
        <OOption key={form.identification} value={form.identification}>
          {form.name}
        </OOption>
      ))}
      <OOption value="" key={`${name.toLowerCase()}-form-property-null`}>
        Nenhum
      </OOption>
    </ORFieldSelect>
  );
};
