import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { EditStepPropertyFields } from "../edit-step-property-modal/_compose";
import { useAddStepPropertyModal } from "./add-step-property-modal.hook";

export const addStepPropertyModalId = "add-step-property-modal";

interface AddStepPropertyModalProps {
  diligenceStepId: number | string;
  reload: () => void;
}

export const AddStepPropertyModal = ({
  diligenceStepId,
  reload,
}: AddStepPropertyModalProps) => {
  const { form, loading, submit } = useAddStepPropertyModal(
    diligenceStepId,
    reload,
  );

  return (
    <OModal
      id={addStepPropertyModalId}
      position="center"
      backdrop
      className="position-absolute"
    >
      <OModalHeader>
        <OTypography size="lg">Adicionar propriedade</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <EditStepPropertyFields idPrefix="add" />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(addStepPropertyModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
