import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { companyMonitorGrid } from "../company-monitor.grid";

const grid = companyMonitorGrid("ASSIGNOR");

export const AssignorCompanies = () => {
  const { routes } = corporateRouter;
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Fornecedores" />}
      actions={
        <RouterButton
          dataAction="fornecedor:botao:adicionar"
          dataLabel="adicionar"
          href={routes.empresas.product.assignor.newAccount.path}
          role={roles.empresas.product.assignorNewAccountPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
