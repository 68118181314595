import { useEffect, useMemo, useRef, useState } from "react";
import { StatusMapContentType } from "../../../../../../banner-management.types";
import {
  replaceAccents,
  searchCharactersToIgnore,
} from "./collapse-item.utils";

export const useDropdown = (
  values?: string[],
  statusMap?: StatusMapContentType[],
  clearSearchs?: boolean,
) => {
  const [searchValue, setSearchValue] = useState("");

  const dropdownRef = useRef(null);

  const filteredValues = useMemo(() => {
    const cleanedValue = replaceAccents(searchValue).replace(
      searchCharactersToIgnore,
      "",
    );

    return (
      values?.filter((c) => {
        const finalCleanedValue = replaceAccents(c).replace(
          searchCharactersToIgnore,
          "",
        );
        const findCharacters = finalCleanedValue?.match(
          RegExp(cleanedValue, "i"),
        );

        return findCharacters || String(c) === searchValue;
      }) || []
    );
  }, [searchValue, values]);

  const valuesMapping = useMemo(() => {
    if (statusMap)
      return statusMap.map((v) => {
        return { value: v.label, label: v.label };
      });
    return filteredValues?.map((v) => {
      return { value: v, label: v };
    });
  }, [statusMap, filteredValues]);

  useEffect(() => {
    setSearchValue("");
  }, [clearSearchs]);

  return {
    dropdownRef,
    valuesMapping,
    setSearchValue,
  };
};
