import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import {
  AvailableOffersCard,
  AveragePhysicalCardsCard,
  AverageVirtualCardsCard,
  CardVariantsBarChartCard,
  DisabledCardsStatusDoughnutChartCard,
  EnabledCardsPieChartCard,
  HiredOffersCard,
  LastDayAccountsCard,
  LastDayContestationsCard,
  LastDayOffersCard,
  LastWeekAccountsCard,
  LastWeekContestationsCard,
  TotalAccountsCard,
  TotalContestationsCard,
  TotalOffersPieChartCard,
  TotalTransactionsCard,
  TotalMonthlyTransactionsCard,
} from "./_cards";
import { CardsDashboardProvider } from "./cards-dashboard.context";
import { ColumnContainer, GridColumn, GridRow } from "./cards-dashboard.styles";

const CardsDashboardComponent = () => {
  return (
    <DetailsTemplate pageTitle={<PageTitle title="Dashboard" />}>
      <ColumnContainer>
        <GridRow templateColumns="repeat(auto-fit, minmax(300px, 100%))">
          <GridRow templateColumns="repeat(auto-fit, minmax(310px, 1fr))">
            <GridRow templateColumns="repeat(2, auto)">
              <GridColumn templateRows="repeat(2, auto)">
                <AvailableOffersCard />
                <TotalAccountsCard />
              </GridColumn>
              <GridColumn templateRows="repeat(2, auto)">
                <HiredOffersCard />
                <LastWeekAccountsCard />
              </GridColumn>
            </GridRow>
            <GridRow templateColumns="repeat(2, minmax(150px, 1fr))">
              <TotalOffersPieChartCard />
              <GridColumn templateRows="repeat(2, auto)">
                <LastDayOffersCard />
                <LastDayAccountsCard />
              </GridColumn>
            </GridRow>
          </GridRow>
        </GridRow>
        <GridRow templateColumns="repeat(auto-fit, minmax(300px, 100%))">
          <GridRow templateColumns="repeat(auto-fit, minmax(150px, 1fr))">
            <EnabledCardsPieChartCard />
            <TotalContestationsCard />
            <LastWeekContestationsCard />
            <LastDayContestationsCard />
          </GridRow>
        </GridRow>
        <GridRow templateColumns="repeat(auto-fit, minmax(300px, 100%))">
          <GridRow templateColumns="repeat(auto-fit, minmax(310px, 1fr))">
            <CardVariantsBarChartCard />
            <GridRow templateColumns="repeat(2, minmax(150px, 1fr))">
              <DisabledCardsStatusDoughnutChartCard />
              <GridColumn templateRows="repeat(2, auto)">
                <AveragePhysicalCardsCard />
                <AverageVirtualCardsCard />
                <TotalTransactionsCard />
                <TotalMonthlyTransactionsCard />
              </GridColumn>
            </GridRow>
          </GridRow>
        </GridRow>
      </ColumnContainer>
    </DetailsTemplate>
  );
};

export const CardsDashboard = () => (
  <CardsDashboardProvider>
    <CardsDashboardComponent />
  </CardsDashboardProvider>
);
