import {
  OCard,
  OCardBody,
  OLabel,
  ORadio,
  ORadioGroup,
  OTypography,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { PixKeyType } from "../../pix-key-input.types";
import { MapperPixKeyType } from "../../pix-key-input.utils";

export const PhoneCPFRadio = () => {
  const { setValue } = useFormContext();

  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-3">
        <OTypography type="dark">
          O número digitado é uma chave CPF ou Celular?
        </OTypography>

        <ORadioGroup>
          <div className="d-flex gap-5">
            {[PixKeyType.CPF, PixKeyType.PHONE].map((keyType) => (
              <div className="d-flex gap-2 align-items-center" key={keyType}>
                <ORadio
                  id="keyType"
                  value="keyType"
                  size="xs"
                  onClick={() => setValue("pixKeyType", keyType)}
                />
                <OLabel htmlFor={keyType}>{MapperPixKeyType[keyType]}</OLabel>
              </div>
            ))}
          </div>
        </ORadioGroup>
      </OCardBody>
    </OCard>
  );
};
