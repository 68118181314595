import {
  OCol,
  OLabel,
  ORFieldInput,
  ORFieldRadioGroup,
  ORadio,
  ORow,
} from "@maestro/react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ImportSettingsForm } from "./import-settings.types";

interface ImportSettingsProps {
  form: UseFormReturn<ImportSettingsForm>;
  classname?: string;
}

export const ImportSettings = ({ form, classname }: ImportSettingsProps) => {
  return (
    <FormProvider {...form}>
      <ORow gap={3} className={classname}>
        <OCol xs={12} md={3}>
          <ORFieldRadioGroup
            dataAction="importar_cedente_lote:select:atualizar_cedentes"
            dataLabel="atualizar_cedentes"
            id="atualizaCedente"
            name="atualizaCedente"
            label="Atualizar cedentes"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="atualizaCedente-yes" value="yes" />
                <OLabel htmlFor="atualizaCedente-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="atualizaCedente-no" value="no" />
                <OLabel htmlFor="atualizaCedente-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={3}>
          <ORFieldRadioGroup
            dataAction="importar_cedente_lote:select:atualizar_recebiveis_nao_antecipados"
            dataLabel="atualizar_recebiveis_nao_antecipados"
            id="updateActiveReceivables"
            name="updateActiveReceivables"
            label="Atualizar recebíveis não antecipados"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="updateActiveReceivables-yes" value="yes" />
                <OLabel htmlFor="updateActiveReceivables-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="updateActiveReceivables-no" value="no" />
                <OLabel htmlFor="updateActiveReceivables-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={3}>
          <ORFieldRadioGroup
            dataAction="importar_cedente_lote:select:cancela_contratos_ao_desativar"
            dataLabel="cancela_contratos_ao_desativar"
            id="cancelContract"
            name="cancelContract"
            label="Cancela contratos ao desativar"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="cancelContract-yes" value="yes" />
                <OLabel htmlFor="cancelContract-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="cancelContract-no" value="no" />
                <OLabel htmlFor="cancelContract-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldInput
            dataAction="importar_cedente_lote:select:justificativa_ativar_desativar_relacionamento"
            dataLabel="justificativa_ativar_desativar_relacionamento"
            tag="text"
            id="justificativeDeactivationRelationship"
            name="justificativeDeactivationRelationship"
            label="Justificativa ativar/desativar relacionamento"
          />
        </OCol>
      </ORow>
    </FormProvider>
  );
};
