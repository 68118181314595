import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetApiSystemsResponseItem } from "services/clerk";

const redirectDetailLink = (id: number) => {
  const baseUrl = corporateRouter.routes.ferramentas.product.clerk.details.path;
  const stringifiedid = JSON.stringify(id);

  return `${baseUrl}?id=${encodeURIComponent(stringifiedid)}`;
};

export const grid: ODataGridGeneratorConfig<GetApiSystemsResponseItem> = {
  datagrid: {
    noDataText: "Nenhum sistema encontrado",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.ferramentas.product.clerkDetailsPage.role,
      cellRender: ({ data: { entityId } }) => (
        <DetailsLink href={redirectDetailLink(entityId)} />
      ),
    },
    { dataField: "identification", caption: "Nome" },
  ],
};
