import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { LocalFormValuesType } from "./system-form.types";

export const useClerkSystemsForm = () => {
  const [loading, setLoading] = useState(false);
  const [allStrategies, setAllStrategies] = useState<
    SelectSearchOption<string>[]
  >([]);

  const form = useFormContext();
  const { watch, setValue, getValues } = form;

  const watchStrategies = watch("strategies") as string[] | undefined;

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;

  const watchInput = localWatch("input");

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.clerk.getStrategies();

      const newData = data.map((d) => {
        const firstUuid = d.estrategiaUniqueId.split("-")[0];

        return {
          value: d.estrategiaUniqueId,
          label: `${firstUuid} - ${d.name}`,
          customKey: d.estrategiaUniqueId,
          onSelect: (value: string) => {
            const strategies = getValues("strategies") ?? [];

            setValue("strategies", [...new Set([...strategies, value])]);
          },
        };
      });

      setAllStrategies(newData);
    } finally {
      setLoading(false);
    }
  }, [setValue, getValues]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (watchStrategies) localSetValue("input", undefined);
  }, [localSetValue, watchStrategies]);

  return {
    localForm,
    loading,
    watchStrategies,
    allStrategies,
    watchInput,
    setValue,
  };
};
