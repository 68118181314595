import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { applyDefaultColors } from "utils/chartjs-default-colors";
import { theme as globalTheme } from "./theme";
import { SetThemeCommand } from "./theme.commands";
import { themeEvents } from "./theme.event";
import { themeManager } from "./theme.invoker";
import { ThemeMode } from "./types/theme";

interface ThemeContext {
  theme: ThemeMode;
}

const themeContext = createContext<ThemeContext>({
  theme: globalTheme.value,
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState(globalTheme.value);

  applyDefaultColors(theme);

  useEffect(() => {
    const cleanUp = themeEvents.subscribe((newTheme) => {
      setTheme(newTheme.value);
    });

    return cleanUp;
  }, []);

  const value = useMemo(
    () => ({
      theme,
    }),
    [theme],
  );

  return (
    <themeContext.Provider value={value}>{children}</themeContext.Provider>
  );
};

export const useTheme = () => useContext(themeContext);

// Command wrappers

/** Set theme as the given value */
export const setTheme = (themeMode: ThemeMode) => {
  themeManager.execute(new SetThemeCommand(themeMode));
};

/** Toggle current theme */
export const toggleTheme = () => {
  const newTheme = globalTheme.value === "light" ? "dark" : "light";
  themeManager.execute(new SetThemeCommand(newTheme));
};
