import { OIcon, OTooltip } from "@maestro/react";

export const UnavailableBankslip = () => {
  return (
    <OTooltip floating position="top-left">
      <span slot="tooltip-content">Boleto indisponível.</span>
      <OIcon category="fal" icon="fa-ban" size="md" />
    </OTooltip>
  );
};
