import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, modalManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { addGroupValidationSchema } from "./add-group-confirmation-modal.form";

export const useAddGroupConfirmationModal = (
  modalId: string,
  successCallback: () => void,
) => {
  const {
    callService: addGroup,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.addGroup);

  const form = useForm<{ taxId: string }>({
    defaultValues: { taxId: "" },
    resolver: yupResolver(addGroupValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(({ taxId }) => {
        addGroup({ tax_id: taxId });
      }),
    [handleSubmit, addGroup],
  );

  useEffect(() => {
    const cleanup = modalManager.on(modalId, "modalOpen", () =>
      reset({ taxId: "" }),
    );
    return cleanup;
  }, [modalId, reset]);

  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao realizar o agrupamento");
  }, [hasError]);

  useEffect(() => {
    if (value) {
      OToastManager.success("Prefixo agrupado com sucesso");
      modalManager.hide(modalId);
      successCallback();
    }
  }, [modalId, successCallback, value]);

  return {
    form,
    loading,
    submit,
  };
};
