import { OCol, ORFieldInput, ORow } from "@maestro/react";

interface CreateRoleFormProps {
  index?: number;
}

export const CreateRoleForm = ({ index }: CreateRoleFormProps) => {
  return (
    <ORow className="flex-fill">
      <OCol xs={12} sm={6}>
        <ORFieldInput
          dataAction="role_form:texto:nome"
          dataLabel="nome"
          id={`changes.${index}.name`}
          name={typeof index === "number" ? `changes.${index}.name` : "name"}
          tag="text"
          label="Nome"
        />
      </OCol>
      <OCol xs={12} sm={6}>
        <ORFieldInput
          dataAction="role_form:texto:descricao"
          dataLabel="descricao"
          id={`changes.${index}.description`}
          name={
            typeof index === "number"
              ? `changes.${index}.description`
              : "description"
          }
          tag="text"
          label="Descrição"
        />
      </OCol>
    </ORow>
  );
};
