import { JSX } from "@maestro/core";
import { OButton } from "@maestro/react";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { ReactNode } from "react";
import { useLinkClickHandler } from "react-router-dom";
import { withRole } from "../with-role";

export interface RouterButtonProps extends JSX.OButton {
  href: string;
  children?: ReactNode;
}

export const RouterButton = withRole(
  ({ href, children, ...rest }: RouterButtonProps) => {
    const onAuxClick = useLinkNewTabHandler(href);

    return (
      <OButton
        onClick={useLinkClickHandler(href)}
        onMouseDown={(evt) => evt.button === 1 && evt.preventDefault()} // fix for middle click when page has overflow
        onAuxClick={!rest.disabled ? onAuxClick : undefined}
        {...rest}
      >
        {children}
      </OButton>
    );
  },
);

RouterButton.displayName = "RouterButton";
