import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import {
  MapperWalletStatusToStatusBadgeProps,
  MapperWalletTypeToStatusBadgeProps,
  WalletProvisionRequestStatus,
} from "services/bankinghub/models/types/cards";
import {
  walletProvisionRequestStatusFilters,
  walletTypeFilters,
} from "./card-wallets-grid.filter-utils";
import { WalletProvisionRequestActionButtons } from "./wallet-provision-request-action-buttons";
import { Wallet } from "services/bankinghub/models/types/cards/cards";

export const walletGrid: ODataGridGeneratorConfig<Wallet> = {
  datagrid: {
    noDataText: "Nenhuma requisição de wallet encontrada",
    remoteOperations: {
      paging: true,
      filtering: true,
    },
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "card.owner.name",
      caption: "Portador",
      allowSorting: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "card.owner.document",
      caption: "Documento do portador",
      allowSorting: true,
      allowHeaderFiltering: true,
      format: (value) => masks.cpf(value),
    },
    {
      dataField: "type",
      caption: "Wallet",
      headerFilter: {
        dataSource: walletTypeFilters,
      },
      allowSorting: true,
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellRender: ({ data }) => (
        <StatusBadge {...MapperWalletTypeToStatusBadgeProps[data.type]} />
      ),
    },
    {
      dataField: "card.printedName",
      caption: "Nome do cartão",
      allowSorting: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "card.last4Digits",
      caption: "Final do cartão",
      allowSorting: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
    },
    {
      dataField: "status",
      caption: "Status",
      headerFilter: {
        dataSource: walletProvisionRequestStatusFilters,
      },
      allowSorting: true,
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellRender: ({ data }) => (
        <StatusBadge {...MapperWalletStatusToStatusBadgeProps[data.status]} />
      ),
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => {
        if (data.status !== WalletProvisionRequestStatus.Created) return <></>;

        return (
          <WalletProvisionRequestActionButtons
            requestId={data.id}
            cardId={data.card.externalId}
            reload={() => component.getDataSource().reload()}
          />
        );
      },
    },
  ],
  paging: {
    enabled: true,
    defaultPageSize: 15,
  },
};
