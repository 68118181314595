import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";

export const interactionsCompanyGrid = (
  resend: (interactionId: string) => Promise<void>,
): ODataGridGeneratorConfig<Fidc.Interactions> => ({
  datagrid: {
    noDataText: "Nenhuma interação disponível.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
      allowSearch: true,
    },
  },
  columns: [
    {
      dataField: "companyName",
      caption: "Empresa",
    },
    {
      dataField: "companyTaxId",
      caption: "CNPJ",
    },
    {
      dataField: "channel",
      caption: "Canal",
    },
    {
      dataField: "to",
      caption: "Destinatário",
    },
    {
      dataField: "dateCreated",
      caption: "Data de envio",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "content",
      caption: "Conteúdo",
    },
    {
      dataField: "open",
      caption: "Aberto",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "sent",
      caption: "Confirmação de envio",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "bounce",
      caption: "Rejeitado",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <IconButton
          key={`reenviar-button-${data.id}`}
          dataAction="interacoes_fidc:botao:reenviar"
          dataLabel="reenviar"
          icon={{
            category: "orq",
            icon: "orq-email",
          }}
          onClick={() => resend(data.id)}
          outline
        >
          Reenviar
        </IconButton>
      ),
    },
  ],
});
