import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { logger } from "utils/logger";
import {
  AllowListTypesMap,
  BlockListTypesMap,
  IntubateType,
} from "./intubate-generic.types";
import { intubateTypeMap } from "./intubate-generic.utils";

export const useIntubateGeneric = (type: IntubateType) => {
  const [hasParseError, setHasParseError] = useState(false);

  const {
    callService,
    hasError: apiError,
    loading: apiLoading,
    value: apiResponse,
  } = useServiceCall<any, unknown>(intubateTypeMap[type].service);

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset,
  } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        const parsed = intubateTypeMap[type].parser(result);

        parsed.forEach((intubateValue) => {
          if (intubateValue.from > intubateValue.until) {
            OToastManager.danger(
              `Erro de validação de dados, identificador: Error(${intubateValue.identification})`,
            );
            throw new Error("Data inicio não pode ser maior que data Fim");
          }

          if (intubateValue.identification == null) {
            OToastManager.danger(
              `Erro de validação de dados, identificador: Error(${intubateValue.identification})`,
            );
            throw new Error("Identificador não pode ser null");
          }

          if (type === "addAllow") {
            if (
              intubateValue.type === null ||
              // eslint-disable-next-line no-prototype-builtins
              !AllowListTypesMap.hasOwnProperty(intubateValue.type)
            ) {
              OToastManager.danger(
                `Erro de validação de dados, identificador: Error(${intubateValue.identification})`,
              );
              throw new Error("Valor invalido na coluna type");
            }
          } else if (
            intubateValue.type === null ||
            // eslint-disable-next-line no-prototype-builtins
            !BlockListTypesMap.hasOwnProperty(intubateValue.type)
          ) {
            OToastManager.danger(
              `Erro de validação de dados, identificador: Error(${intubateValue.identification})`,
            );
            throw new Error("Valor invalido na coluna type");
          }
        });

        if (type === "addAllow") {
          const objectAllowList = { AllowListItems: parsed };
          callService(objectAllowList);
        } else {
          const objectBlockList = { BlockListItems: parsed };
          callService(objectBlockList);
        }
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    }
  }, [callService, readerError, result, type]);

  useEffect(() => {
    if (apiError) {
      OToastManager.danger("Erro ao enviar o arquivo");
      reset();
    }
  }, [apiError, reset]);

  useEffect(() => {
    if (apiResponse) {
      OToastManager.success("Arquivo enviado com sucesso");
      reset();
    }
  }, [apiResponse, reset]);

  return {
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    reset,
    setHasParseError,
  };
};
