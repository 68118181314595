import { DetailsFields } from "components/details-card";
import { service } from "services";

export const dashboardOnboardDetailsGenerator = (
  details: Awaited<
    ReturnType<typeof service.quickfin.getCompanyDetails>
  >["data"],
  onboardingType: string,
) => {
  return {
    limit: {
      items: [
        {
          label: "Identificação do fornecedor",
          value: details.cedenteIdentification,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status do onboarding",
          value: `${onboardingType} - ${details?.statusOnboarding ?? ""}`,
          grid: { md: 4, xl: 3 },
        },
        ...(details.email
          ? ([
              {
                label: "Email principal",
                value: details.email,
                grid: { md: 4, xl: 3 },
              },
            ] as const)
          : []),
        ...(details.contact?.length
          ? ([
              {
                label: "Contatos",
                value: details.contact.join(", "),
                grid: { md: 4, xl: 3 },
              },
            ] as const)
          : []),
        ...(details.errosOnboarding?.length
          ? ([
              {
                label: "Erros no onboarding",
                value: details.errosOnboarding.join(", "),
                grid: { md: 4, xl: 3 },
              },
            ] as const)
          : []),
      ],
    },
  } satisfies DetailsFields;
};
