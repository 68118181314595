import { OIcon, OTypography } from "@maestro/react";
import { EmptyState } from "components/empty-state";
import { RouterButton } from "components/router-button";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { PostCustomerInvitesGetWorkflowDetailsResponse } from "services/clerk";
import { IconContainer } from "../cards.styles";

type InviteCardProps = Omit<
  PostCustomerInvitesGetWorkflowDetailsResponse,
  "updatedAt"
>;

export const InviteCardContents = ({
  identifier,
  createdBy,
  insertedAt,
  officerName,
  receiverEmail,
  status,
}: InviteCardProps) => {
  const workflowIdentifier: string = identifier;

  if (status === "CANCELADO")
    return <EmptyState messageParagraph="Nenhuma informação" />;

  return (
    <div className="d-flex flex-column gap-3 w-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-envelope" />
          </IconContainer>
          <OTypography>Enviado para: </OTypography>
        </div>
        <OTypography>{receiverEmail || "-"}</OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-calendar-alt" />
          </IconContainer>
          <OTypography>Criado em: </OTypography>
        </div>
        <OTypography>
          {dayjs(insertedAt)
            .utc(true)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY, HH:mm:ss") || "-"}
        </OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-user-tie" />
          </IconContainer>
          <OTypography>Officer: </OTypography>
        </div>
        <OTypography>{officerName || "-"}</OTypography>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1">
          <IconContainer>
            <OIcon category="fa" icon="fa-id-card" />
          </IconContainer>
          <OTypography>Responsável: </OTypography>
        </div>
        <OTypography>{createdBy || "-"}</OTypography>
      </div>
      <div className="align-self-center">
        <RouterButton
          dataAction="convite:botao:detalhes_convite"
          dataLabel="detalhes_convites"
          href={corporateRouter.routes.cadastro.customer.notification.workflows.invites.path(
            { workflowIdentifier },
          )}
        >
          <OTypography type="light">Detalhes</OTypography>
        </RouterButton>
      </div>
    </div>
  );
};
