import { OrqProgressRing } from "@orquestra-web/react";
import { StandardLink } from "components/standard-link";
import { GetProcessDashboardResponseItem } from "services/recebiveis-cartao/models/responses/get-process-dashboard-response";
import { detailsUrlMapper } from "../../pages/produto/process-dashboard/process-dashboard.utils";

export interface ProcessStatusCardContentProps {
  item: GetProcessDashboardResponseItem;
  index: number;
  loading: boolean;
}

export const ProcessStatusCardContent = ({
  item: {
    id,
    title,
    desirable,
    percentage,
    details,
    completed,
    scheduled,
    available,
    executing,
    retryable,
    attempts,
  },
  index,
  loading,
}: ProcessStatusCardContentProps) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="mb-4">{`${index + 1}. ${title}`}</div>
        {details && (
          <StandardLink
            href={
              id in detailsUrlMapper
                ? detailsUrlMapper[id as keyof typeof detailsUrlMapper]
                : detailsUrlMapper.default
            }
          >
            Detalhes
          </StandardLink>
        )}
      </div>
      <div className="d-flex flex-row-reverse justify-content-between  aqui">
        <div className="d-flex justify-content-center align-items-center col-3">
          <OrqProgressRing size="large" value={percentage} loading={loading} />
        </div>
        <div className="mt-4 col-8">
          <div className="d-flex justify-content-between">
            <span>Desejável</span>
            <span>{desirable || "Pendente"}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Processado</span>
            <span>{completed}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Executando</span>
            <span>{executing}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Aguardando</span>
            <span>{available}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Agendado</span>
            <span>{scheduled}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Reprogramado</span>
            <span>{retryable}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Tentativas falhas</span>
            <span>{attempts}</span>
          </div>
        </div>
      </div>
    </>
  );
};
