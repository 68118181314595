/* eslint-disable no-param-reassign */
import axios from "axios";
import { auth } from "contexts/auth";
import { customer } from "contexts/customer";
import { logger } from "utils/logger";

export const client = axios.create();

client.interceptors.request.use((config) => {
  config.headers.Authorization = auth.value;
  config.headers["x-identification"] =
    config.headers["x-identification"] ?? customer.value?.identification;
  config.headers["x-identification-type"] = customer.value?.identificationType;
  config.headers["x-uuid"] = customer.value?.uuid;

  return config;
});

client.interceptors.response.use(null, (error) => {
  logger.error(error);
  return Promise.reject(error);
});
