import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { useUploadCedentes } from "../../upload-cedentes.context";
import { validationResultsGrid } from "./validation-results.grid";
import { addErrorsToCedentes } from "./validation-results.utils";

export const ValidationResults = () => {
  const { cedentes, validationResults, clear, submit, submitLoading } =
    useUploadCedentes();

  const dataSource = useMemo(
    () => addErrorsToCedentes(cedentes, validationResults),
    [cedentes, validationResults],
  );

  const canSubmit = validationResults?.length === 0;

  return (
    <GridTemplate
      actions={
        canSubmit ? (
          <LoadingButton
            dataAction="validacao:botao:importar_cedentes"
            dataLabel="importar_cedentes"
            loading={submitLoading}
            onClick={() => submit()}
          >
            Importar cedentes
          </LoadingButton>
        ) : (
          <OButton
            dataAction="validacao:botao:enviar_outro_arquivo"
            dataLabel="enviar_outro_arquivo"
            onClick={() => clear()}
            type="dark"
            outline
          >
            Enviar outro arquivo
          </OButton>
        )
      }
    >
      <ODataGridGenerator
        grid={validationResultsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
