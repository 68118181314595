import { ODataGridGeneratorConfig } from "components/data-grid";
import { Activation } from "services/clerk/models/types";

export const userActivationsGrid: ODataGridGeneratorConfig<Activation> = {
  datagrid: {
    noDataText: "Nenhuma ativação encontrada.",
  },
  columns: [
    {
      dataField: "insertedAt",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
      caption: "Cadastrado em",
    },
    {
      dataField: "updatedAt",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
      caption: "Atualizado em",
    },
    {
      dataField: "type",
      dataType: "string",
      caption: "Tipo",
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
    },
  ],
};
