import {
  OIcon,
  OLink,
  OLoader,
  ORFieldUploadInput,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { FormProvider } from "react-hook-form";
import { UploadContainer } from "../../../../../../components";
import { useFileUpload } from "./file-upload.hook";

export const FileUpload = () => {
  const {
    apiHasError,
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    readerReset,
    setHasParseError,
  } = useFileUpload();

  if (hasParseError || !!readerError || apiHasError)
    return (
      <div>
        <ErrorComponent
          messageTitle="Erro ao processar a planilha"
          messageParagraph="Certifique-se que ela foi preenchida corretamente conforme o modelo."
        >
          <TryAgainButton
            onClick={() => {
              setHasParseError(false);
              readerReset();
            }}
          />
        </ErrorComponent>
      </div>
    );

  return (
    <div>
      <FormProvider {...form}>
        <UploadContainer>
          <ORFieldUploadInput
            id="batch-tiers"
            name="batch-tiers"
            inputLabel="Clique ou arraste o arquivo aqui"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            dataAction="tiers:upload:tiers"
            dataLabel="tiers"
            multiple={false}
            handleAddFile={handleAddFile}
            disabled={apiLoading || readerLoading}
            value={[]}
          />
        </UploadContainer>

        <div className="d-flex flex-column align-items-center gap-3 mt-4">
          <OLink href="/files/modelo_entuba_sacado_cedente_tier.xlsx">
            <OIcon category="fas" icon="fa-external-link" className="me-2" />
            Baixar modelo
          </OLink>
          <OTypography type="default">
            Por favor, preencha as colunas em verde. As colunas em vermelho não
            serão usadas.
          </OTypography>
          {(apiLoading || readerLoading) && <OLoader />}
        </div>
      </FormProvider>
    </div>
  );
};
