import {
  modalManager,
  OTypography,
  OConfirmationModal,
  OToastManager,
} from "@maestro/react";
import { DataGridAction } from "components/datagrid-action";
import { useState } from "react";
import { sharkService } from "services/shark/shark.service";

interface UserGridActionsProps {
  id: number;
  receiver?: string;
}

export const UserGridActions = ({ id, receiver }: UserGridActionsProps) => {
  const resendModalId = `reenvia-estimulo-individual`;
  const [loading, setLoading] = useState(false);

  const handleResendNotification = async () => {
    try {
      setLoading(true);
      await sharkService.republishNotification({
        type: "notification",
        id,
      });
      OToastManager.success("Estímulo reenviado com sucesso!");
      modalManager.hide(resendModalId);
    } catch {
      OToastManager.danger("Erro ao reenviar estímulo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataGridAction
        actions={[
          {
            icon: { category: "far", icon: "fa-paper-plane" },
            label: "Reenviar estímulo",
            onClick: () => modalManager.show(resendModalId),
          },
        ]}
      />
      <OConfirmationModal
        modalId={resendModalId}
        title="Reenviar estímulo?"
        closeLabel="Cancelar"
        confirmLabel="Sim"
        onConfirm={handleResendNotification}
        loading={loading}
      >
        <OTypography>
          Deseja reenviar o estímulo para o destinatário {receiver}?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
