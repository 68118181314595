import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  OOption,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const DeskConfigRelationshipForm = () => {
  const {
    callService: getDesks,
    loading: loadingDesks,
    value: desks,
    hasError: hasErrorDesks,
  } = useServiceCall(service.onboarding.getDesks);
  const {
    callService: getDesksOnboardingConfigs,
    loading: loadingDesksConfigs,
    value: desksConfigs,
    hasError: hasErrorDesksConfigs,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigs);

  useEffect(() => {
    getDesks();
    getDesksOnboardingConfigs();
  }, [getDesks, getDesksOnboardingConfigs]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskCode"
                name="deskCode"
                label="Mesa"
                placeholder="Selecione"
                disabled={loadingDesks}
                key={desks?.length}
              >
                {desks?.map((desk) => (
                  <OOption key={desk.deskCode} value={desk.deskCode}>
                    {`${desk.deskCode} - ${desk.deskName} (${desk.deskDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDesks && <OLoader className="d-block" size="sm" />}
              {hasErrorDesks && (
                <OButton type="default" outline onClick={() => getDesks()}>
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="configIdentifier"
                name="configIdentifier"
                label="Configuração"
                placeholder="Selecione"
                disabled={loadingDesksConfigs}
                key={desksConfigs?.length}
              >
                {desksConfigs?.map((config) => (
                  <OOption
                    key={config.configIdentifier}
                    value={config.configIdentifier}
                  >
                    {`${config.configIdentifier} - ${config.configName} (${config.configDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDesksConfigs && <OLoader className="d-block" size="sm" />}
              {hasErrorDesksConfigs && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingConfigs()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="isDefault"
                name="isDefault"
                label="Configuração padrão?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
