import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { logger } from "utils/logger";
import { Whitelist } from "./whitelists.types";
import { AcquirerSelector } from "./_compose";

export const dataSource = dataSourceCustomStoreGenerator<Whitelist>(
  (loadOptions) =>
    service.recebiveisCartao
      .getWhitelists(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar as agendas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      insert: async (data) => {
        try {
          await service.recebiveisCartao.addWhitelist(data);
          OToastManager.success("Deflator adicionado com sucesso");
        } catch (err) {
          if (!isAxiosError(err)) logger.error(err);
          OToastManager.danger("Não foi possível adicionar o deflator");
        }
        return data;
      },
      update: async (original, updated) => {
        try {
          await service.recebiveisCartao.editWhitelist(original.id, {
            company_tax_id: updated.company_tax_id || original.company_tax_id,
            credenciadora_id:
              updated.credenciadora_id || original.credenciadora_id,
            datasource: updated.datasource || original.datasource,
          });
          OToastManager.success("Deflator editado com sucesso");
        } catch (err) {
          if (!isAxiosError(err)) logger.error(err);
          OToastManager.danger("Não foi possível editar o deflator");
        }
      },
    },
  },
);

export const whitelistsGrid: ODataGridGeneratorConfig<Whitelist> = {
  datagrid: {
    noDataText: "Nenhuma agenda.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    pager: {
      showPageSizeSelector: true,
    },
    editing: {
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
    remoteOperations: true,
    onRowUpdated: () => dataSource.reload(),
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      allowEditing: false,
      allowHeaderFiltering: false,
    },
    {
      // only here for editing
      dataField: "credenciadora_id",
      dataType: "number",
      visible: false,
    },
    {
      dataField: "credenciadora_name",
      dataType: "string",
      caption: "Credenciadora",
      editCellRender: (props) => <AcquirerSelector {...props} />,
      allowHeaderFiltering: false,
    },
    {
      dataField: "company_tax_id",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value) || value,
      calculateFilterExpression: (filterValue, selectedFilterOperation) => [
        "company_tax_id",
        selectedFilterOperation,
        filterValue.replace(/\W/g, ""),
      ],
      allowHeaderFiltering: false,
    },
    {
      dataField: "datasource",
      dataType: "string",
      caption: "Registradora",
    },
  ],
};
