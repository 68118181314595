import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { TooltipIcon } from "components/tooltip-icon";
import dayjs from "dayjs";
import { Options } from "devextreme/data/data_source";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { LegalEntityPowers } from "./expiring-powers.types";
import { documentTypeIdMap, documentTypeMap } from "./expiring-powers.utils";

export const buildDataSource = (filter: Options["filter"]) =>
  dataSourceODataStoreGenerator<LegalEntityPowers>(
    endpoints.onboardingRelationships.odata.companyPowers.legalEntityPowers,
    {
      dataSourceOptions: {
        filter,
      },
      odataStoreOptions: {
        // strip time from ISO format
        // ex: 2023-07-25T00:00:00Z -> 2023-07-25
        beforeSend: ({ params }) => {
          if (params.$filter) {
            // eslint-disable-next-line no-param-reassign
            params.$filter = (params.$filter as string).replace(
              /(\d{4}-\d{2}-\d{2})T\d{2}:\d{2}:\d{2}Z/g,
              "$1",
            );
          }
        },
      },
    },
  );

export const expiringPowersGrid: ODataGridGeneratorConfig<LegalEntityPowers> = {
  datagrid: {
    filterRow: { visible: true },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "CompanyOfficialName",
      dataType: "string",
      caption: "Razão social",
    },
    {
      dataField: "CompanyTaxId",
      dataType: "string",
      caption: "CNPJ",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cnpj(value),
    },
    {
      dataField: "UserOfficialName",
      dataType: "string",
      caption: "Usuário",
    },
    {
      dataField: "UserTaxId",
      dataType: "string",
      caption: "CPF",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpf(value),
    },
    {
      dataField: "ActId",
      dataType: "number",
      caption: "Ato (id)",
    },
    {
      dataField: "ActName",
      dataType: "string",
      caption: "Ato (nome)",
    },
    {
      dataField: "DocumentTypeId",
      caption: "Identificador do documento",
      cellRender: ({ data }) => (
        <span className="d-flex gap-2">
          {data.DocumentTypeId}
          <TooltipIcon
            content={getValueFromMap(
              documentTypeIdMap,
              data.DocumentTypeId.toString(),
            )}
          />
        </span>
      ),
    },
    {
      dataField: "DocumentType",
      dataType: "string",
      caption: "Tipo de documento",
      cellRender: ({ data }) =>
        getValueFromMap(documentTypeMap, data.DocumentType) ??
        data.DocumentType,
    },
    {
      dataField: "MultipleSignatures",
      dataType: "boolean",
      caption: "Múltiplas assinaturas",
    },
    {
      dataField: "StartDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Início",
    },
    {
      dataField: "Vigency",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Vigência",
      selectedFilterOperation: "<=",
      filterValue: dayjs().startOf("day").add(3, "months").toDate(),
    },
  ],
};
