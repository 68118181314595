import { OToastManager, modalManager } from "@maestro/core";
import { DataGridAction } from "components/datagrid-action";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { ComponentProperty } from "../../../component-info.types";
import { COMPONENT_PROPERTY_MODAL_ID } from "../component-properties-modal/component-properties-modal.component";

interface ComponentPropertiesGridActionsProps {
  data: ComponentProperty;
  id: string;
  component: ReturnType<
    typeof useServiceCall<
      Parameters<typeof service.hubCreditManager.getComponentsById>,
      Awaited<
        ReturnType<typeof service.hubCreditManager.getComponentsById>
      >["data"]
    >
  >;
  setSelectedProperty: React.Dispatch<
    React.SetStateAction<ComponentProperty | undefined>
  >;
}
 
export const ComponentPropertiesGridActions = ({
  data,
  id,
  component,
  setSelectedProperty,
}: ComponentPropertiesGridActionsProps) => {
  const { callService: getComponentDetails } = component;

  const { callService, loading } = useServiceCall(
    service.hubCreditManager.removeComponentProperty,
  );

  const removeProperty = useCallback(
    async (propertyId: number) => {
      const { success } = await callService({
        componentPropertyId: propertyId,
      });

      if (success) {
        getComponentDetails(id);

        OToastManager.success("A propriedade foi removida com sucesso.");
      } else {
        OToastManager.danger(
          "Ocorreu um erro ao remover propriedade do componente. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [getComponentDetails, id, callService],
  );

  return (
    <DataGridAction
      actions={[
        {
          icon: { category: "orq", icon: "orq-edit-pencil" },
          label: "Editar",
          onClick: () => {
            setSelectedProperty(data);
            modalManager.show(COMPONENT_PROPERTY_MODAL_ID);
          },
        },
        {
          icon: { category: "orq", icon: "orq-edit-trash" },
          label: "Remover",
          disabled: loading,
          onClick: () => {
            removeProperty(data.id);
          },
        },
      ]}
    />
  );
};
