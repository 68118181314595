import { ContentTemplate } from "templates/content-template";
import { DetailsCardProps } from "./details-card.types";
import { DetailsContent } from "./details-content.component";

export const DetailsWithoutCard = <T,>({
  fields,
  value,
  ...rest
}: DetailsCardProps<T>) => {
  if (typeof fields === "function") {
    const props = {
      ...rest,
      value,
      render: (_value: T) => <DetailsContent fields={fields(_value)} />,
    };
    return <ContentTemplate {...props} />;
  }

  const props = {
    ...rest,
    render: () => <DetailsContent fields={fields} />,
  };
  return <ContentTemplate noValue {...props} />;
};
