import { OFilterItem, ORadioGroup, OTypography } from "@maestro/react";
import { movementTypes } from "../filters";
import { RadioFilterItem } from "../items/radio-filter-item.component";

export const MovementTypeFilter = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Tipo de movimentações</OTypography>{" "}
      <ORadioGroup
        data-filterparam="movementTypeFilter"
        dataAction="statement-movement-filter"
        dataLabel="statement-movement-filter"
      >
        {movementTypes.map((item) => (
          <RadioFilterItem {...item} key={item.value} />
        ))}
      </ORadioGroup>
    </OFilterItem>
  );
};
