import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingUpdateByUUIDRouteParams } from "../../../../../routes/cadastro.route-params";
import { StyledPre } from "../logs/atualizacao-cadastral-logs.grid";
import { useDataUpdateEdit } from "./atualizacao-cadastral-edit.hook";

export const DataUpdateEdit = () => {
  const { uuid } = useParams<OnboardingUpdateByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid in route parameters");

  const {
    submit,
    form,
    loading,
    dataUpdateDetails,
    dataUpdateEnums,
    dataUpdateChanges,
  } = useDataUpdateEdit({
    uuid,
  });

  const {
    callService: getDetails,
    loading: loadingDetails,
    hasError: hasErrorDetails,
  } = dataUpdateDetails;
  const {
    callService: getEnums,
    loading: loadingEnums,
    hasError: hasErrorEnums,
    value: enums,
  } = dataUpdateEnums;
  const { value: changes } = dataUpdateChanges;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Editar Atualização Cadastral"
          description={`Id: ${uuid}`}
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Editar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-4">
        <ContentTemplate
          loading={loadingDetails}
          hasError={hasErrorDetails}
          errorComponent={
            <DefaultErrorComponent
              title="Erro ao buscar atualização"
              callback={() => getDetails(uuid)}
            />
          }
          noValue
          render={() => (
            <CardTemplate>
              <FormProvider {...form}>
                <form>
                  <ORow>
                    <OCol className="gap-2">
                      <ORFieldSelect
                        disabled={loadingEnums}
                        id="status"
                        name="status"
                        placeholder="Status"
                        label="Status"
                        key={enums?.portalStatusTypes?.length}
                      >
                        {enums?.portalStatusTypes?.map((portalStatus) => (
                          <OOption key={portalStatus} value={portalStatus}>
                            {portalStatus}
                          </OOption>
                        ))}
                      </ORFieldSelect>
                      {loadingEnums && (
                        <OLoader className="d-block" size="sm" />
                      )}
                      {hasErrorEnums && (
                        <OButton
                          type="default"
                          outline
                          onClick={() => getEnums()}
                        >
                          <OIcon
                            category="fa"
                            icon="fa-repeat"
                            size="sm"
                            type="default"
                          />
                        </OButton>
                      )}
                    </OCol>
                    <OCol>
                      <ORFieldInput
                        tag="text"
                        id="insertedAt"
                        name="insertedAt"
                        label="Data da Criação"
                        disabled
                      />
                    </OCol>
                    <OCol>
                      <ORFieldInput
                        tag="text"
                        id="updatedAt"
                        name="updatedAt"
                        label="Data de Atualização"
                        disabled
                      />
                    </OCol>
                  </ORow>
                </form>
              </FormProvider>
            </CardTemplate>
          )}
        />
        {changes && (
          <DetailsTemplate
            pageTitle={<PageTitle title="Alterações na atualização" />}
          >
            <CardTemplate>
              <StyledPre>{JSON.stringify(changes, null, 2)}</StyledPre>
            </CardTemplate>
          </DetailsTemplate>
        )}
      </div>
    </FormTemplate>
  );
};
