import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { BorderosByIdReceivablesItem } from "services/quickfin/models";

export const grid: ODataGridGeneratorConfig<BorderosByIdReceivablesItem> = {
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    { caption: "Id", dataField: "id" },
    { caption: "Sacado", dataField: "sacadoName" },
    { caption: "Identificação", dataField: "identification" },
    {
      caption: "Valor no Vencimento",
      dataField: "maturityAmount",
      format: (value) => masks.currency(value, "R$"),
    },
    { caption: "Moeda", dataField: "currency" },
    {
      caption: "Data de Vencimento",
      dataField: "maturityDate",
      dataType: "date",
      format: "shortDateShortTime",
      sortOrder: "desc",
    },
    { caption: "Status", dataField: "receivableStatus" },
  ],
};
