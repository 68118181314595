import * as yup from "yup";
import { EstrategiaTemplateRelationshipsConfirmationModalForm } from "./estrategia-template-relationships-confirmation-modal.types";

export const estrategiaTemplateRelationshipsConfirmationModalDefaultValues = {
  updateEstrategias: null,
  updateCedentes: null,
} as Record<keyof EstrategiaTemplateRelationshipsConfirmationModalForm, any>;

export const estrategiaTemplateRelationshipsConfirmationModalValidationSchema =
  yup.object({
    updateEstrategias: yup.string().required("Este campo é obrigatório"),
    updateCedentes: yup
      .string()
      .nullable()
      .when("updateEstrategias", {
        is: "yes",
        then: (schema) =>
          schema.nonNullable().required("Este campo é obrigatório"),
      }),
  });
