import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";

const buildDetailsLink = (interactionId: string, taxId: string) => {
  const { routes } = corporateRouter;

  const baseUrl = routes.fidc.product.interactionsCompany.details.path;

  const searchParams = new URLSearchParams();
  searchParams.set("interactionId", interactionId);
  searchParams.set("taxId", taxId);

  return baseUrl + "?" + searchParams.toString();
};

export const companyGrid: (
  rows: number,
) => ODataGridGeneratorConfig<Fidc.Company> = (rows) => ({
  datagrid: {
    noDataText: "Nenhuma empresa encontrada.",
    pager: { visible: false },
    paging: { pageSize: rows },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.fidc.product.interactionsDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink href={buildDetailsLink(data.id, data.taxId)} />
      ),
    },
    {
      dataField: "name",
      caption: "Empresa",
    },
    {
      dataField: "taxId",
      caption: "CNPJ",
    },
  ],
});
