import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { MleByTradeIdRouteParams } from "../../../../../routes/energia.route-params";
import { CreateTradeDocumentsButton } from "./_compose";
import { buildDataSource, tradeDocumentsGrid } from "./trade-documents.grid";

export const TradeDocumentsPage = () => {
  const { tradeId } = useParams<MleByTradeIdRouteParams>();
  if (!tradeId) throw new Error("No tradeId");

  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(tradeId), [tradeId]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Documentos" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={<CreateTradeDocumentsButton tradeId={tradeId} />}
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={tradeDocumentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
