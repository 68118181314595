import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { EmprestimosProdutoLogsById } from "../../../../routes/emprestimos.route-params";
import { loanLogDetailsGenerator } from "./loan-log-details.details";

export const LoanLogDetailsPage = () => {
  const { id } = useParams<EmprestimosProdutoLogsById>();

  if (!id) throw new Error("No id");

  const {
    callService: getLog,
    value: log,
    hasError,
    loading,
  } = useServiceCall(service.hubLoan.getLog);

  useEffect(() => {
    getLog(id);
  }, [getLog, id]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes dos Logs" />}>
      <DetailsCard
        loading={loading}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os detalhes do log."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getLog(id)} />
          </ErrorComponent>
        }
        hasError={hasError}
        value={log}
        fields={loanLogDetailsGenerator}
      />
    </DetailsTemplate>
  );
};
