import {
  FeesType,
  InterestType,
} from "services/admin-bankinghub/collection/models/types";
import * as yup from "yup";

export interface UpsertCollectionAgreementFormFields {
  id?: string;
  name: string;
  isDefaultAgreement: boolean;
  settings: Settings;
}

interface Settings {
  daysToCancel?: number;
  description?: string;
  fine?: Fine;
  interest?: Interest;
  discount?: Discount;
}

interface Discount {
  discountValidityInDays?: number;
  percentOrAmount?: number;
  type?: FeesType;
}

export interface Fine {
  daysToStart?: number;
  percentOrAmount?: number;
  type?: FeesType;
}

export interface Interest {
  daysToStart?: number;
  percentOrAmount?: number;
  type?: InterestType;
}

const MIN_PERCENTAGE_VALUE = 0.0001;

export const upsertCollectionAgreementValidationSchema = yup.object({
  id: yup.string().when("isDefaultAgreement", {
    is: false,
    then: (schema) =>
      schema.test(
        "id",
        "O Id deve conter de 3 a 13 caracteres numéricos.",
        (value) => {
          if (!value) return true;
          const onlyNumbersRegex = /^[0-9]{3,13}$/;
          return onlyNumbersRegex.test(value);
        },
      ),
  }),
  name: yup.string().required("Este campo é obrigatório"),
  settings: yup.object({
    discount: yup.object({
      discountValidityInDays: yup.number().when("type", {
        is: (type?: FeesType) =>
          !!type && [FeesType.FIXED_VALUE, FeesType.PERCENTAGE].includes(type),
        then: (schema) =>
          schema
            .optional()
            .typeError("Apenas números inteiros são aceitos")
            .integer("Apenas números inteiros são válidos")
            .min(0, "Insira um número maior ou igual a zero"),
      }),
      percentOrAmount: yup
        .number()
        .when("type", {
          is: FeesType.FIXED_VALUE,
          then: (schema) =>
            schema.min(0.01, "Informe um valor para o desconto"),
        })
        .when("type", {
          is: FeesType.PERCENTAGE,
          then: (schema) =>
            schema
              .min(MIN_PERCENTAGE_VALUE, "Informe um valor para o desconto")
              .max(99.99, "A porcentagem de desconto deve ser menor que 100%"),
        }),
    }),
    fine: yup.object({
      daysToStart: yup.number().when("type", {
        is: (type?: FeesType) =>
          !!type && [FeesType.FIXED_VALUE, FeesType.PERCENTAGE].includes(type),
        then: (schema) =>
          schema
            .typeError("Apenas números inteiros são aceitos")
            .required("Este campo é obrigatório")
            .integer("Apenas números inteiros são válidos")
            .min(1, "Este campo é obrigatório"),
      }),
      percentOrAmount: yup
        .number()
        .when("type", {
          is: FeesType.FIXED_VALUE,
          then: (schema) =>
            schema
              .required("Este campo é obrigatório")
              .min(0.01, "Informe um valor para a multa"),
        })
        .when("type", {
          is: FeesType.PERCENTAGE,
          then: (schema) =>
            schema
              .required("Este campo é obrigatório")
              .min(MIN_PERCENTAGE_VALUE, "Informe um valor para a multa")
              .max(99.99, "A porcentagem de multa deve ser menor que 100%"),
        }),
    }),
    interest: yup.object({
      daysToStart: yup.number().when("type", {
        is: (type?: InterestType) =>
          !!type &&
          [
            InterestType.FIXED_VALUE_PER_MONTH,
            InterestType.PERCENTAGE_PER_MONTH,
          ].includes(type),
        then: (schema) =>
          schema
            .typeError("Apenas números inteiros são aceitos")
            .required("Este campo é obrigatório")
            .integer("Apenas números inteiros são válidos")
            .min(1, "Este campo é obrigatório"),
      }),
      percentOrAmount: yup
        .number()
        .when("type", {
          is: InterestType.FIXED_VALUE_PER_MONTH,
          then: (schema) =>
            schema
              .required("Este campo é obrigatório")
              .min(0.01, "Informe um valor para o juros"),
        })
        .when("type", {
          is: InterestType.PERCENTAGE_PER_MONTH,
          then: (schema) =>
            schema
              .required("Este campo é obrigatório")
              .min(MIN_PERCENTAGE_VALUE, "Informe um valor para o juros")
              .max(99.99, "A porcentagem de juros deve ser menor que 100%"),
        }),
    }),
  }),
});

export const assembleUpsertCollectionAgreementDefaultValues = (
  agreementToUpdate?: BankingHubAdminCollection.Agreement,
): UpsertCollectionAgreementFormFields => {
  const id = agreementToUpdate?.id;
  const name = agreementToUpdate?.name ?? "";
  const isDefaultAgreement = id === "DEFAULT";
  const settings: Settings = { ...agreementToUpdate?.settings };

  const hasNoDiscount = settings.discount?.type === FeesType.NOT_APPLICABLE;
  if (hasNoDiscount) {
    settings.discount = { type: FeesType.NOT_APPLICABLE };
  }

  const hasPercentageDiscount = settings.discount?.type === FeesType.PERCENTAGE;
  if (settings.discount?.percentOrAmount && hasPercentageDiscount) {
    settings.discount.percentOrAmount /= 100;
  }

  const hasNoFine = settings.fine?.type === FeesType.NOT_APPLICABLE;
  if (hasNoFine) {
    settings.fine = { type: FeesType.NOT_APPLICABLE };
  }
  const hasPercentageFine = settings.fine?.type === FeesType.PERCENTAGE;
  if (settings.fine?.percentOrAmount && hasPercentageFine) {
    settings.fine.percentOrAmount /= 100;
  }

  const hasNoInterest = settings.interest?.type === InterestType.NOT_APPLICABLE;
  if (hasNoInterest) {
    settings.interest = { type: InterestType.NOT_APPLICABLE };
  }
  const hasPercentageInterest =
    settings.interest?.type === InterestType.PERCENTAGE_PER_MONTH;
  if (settings.interest?.percentOrAmount && hasPercentageInterest) {
    settings.interest.percentOrAmount /= 100;
  }

  return { id, name, isDefaultAgreement, settings };
};
