import { env } from "utils/environments";

export const ravenEndpoints = {
  notifications: {
    adminOdata: `${env.API_RAVEN}/api/notifications/admin_odata`,
    downloadEmail: `${env.API_RAVEN}/api/notifications/download-email`,
  },
  notificationConfig: {
    endpoint: `${env.API_RAVEN}/api/notification_config`,
    odata: `${env.API_RAVEN}/api/notification_config/odata`,
    byName: (name: string) =>
      `${env.API_RAVEN}/api/notification_config/${name}`,
  },
  notificationTypes: {
    endpoint: `${env.API_RAVEN}/api/v2/notification_types`,
    byId: (id: string) => `${env.API_RAVEN}/api/v2/notification_types/${id}`,
  },
  notification_configurations: {
    endpoint: `${env.API_RAVEN}/api/v2/notification_configurations`,
    byId: (id: string) =>
      `${env.API_RAVEN}/api/v2/notification_configurations/${id}`,
    getNotificationConfigurationsbyType: (id: string) =>
      `${env.API_RAVEN}/api/v2/notification_configurations/list_by_notification_type/${id}`,
  },
  jobs: {
    endpoint: `${env.API_RAVEN}/api/v2/jobs/`,
    runJob: `${env.API_RAVEN}/api/v2/jobs/run-job`,
    stopJob: `${env.API_RAVEN}/api/v2/jobs/stop-job`,
  },
};
