import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { logger } from "utils/logger";
import dayjs from "dayjs";
import { DailyBalanceItem } from "./daily-balance.types";
import { highlightNegative } from "./daily-balance.utils";

const statementDays = 45;

export const buildDataSource = (account: string, agency: string) =>
  dataSourceCustomStoreGenerator<DailyBalanceItem>(async () => {
    const params = {
      startDate: dayjs().subtract(statementDays, "day").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    };

    try {
      const response = await service.bankinghub.getAccountMovement(
        account,
        agency,
        params,
      );

      const { dailyMovements } = response.data.data;

      const result = dailyMovements.map((value) => ({
        date: value.date,
        balance: value.balance?.balance,
      }));

      return result;
    } catch (error) {
      if (!isAxiosError(error)) logger.error(error);
      const errorMessage = "Erro ao buscar as informações";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }
  });

export const dailyBalanceGrid: ODataGridGeneratorConfig<DailyBalanceItem> = {
  datagrid: {
    filterRow: { visible: true },
    onCellPrepared: highlightNegative,
  },
  columns: [
    {
      dataField: "date",
      dataType: "date",
      format: "longDate",
      caption: "Data",
    },
    {
      dataField: "balance",
      caption: "Saldo",
      format: (value) => masks.currency(value, "R$", "."),
    },
  ],
};
