import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchMultipleOption } from "components/form";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { User } from "services/clerk/admin/models/responses/compare";
import { getModificationsToArray } from "../../../../../../ferramentas/utils/get-modifications-to-array";
import { OnboardingEditByIdRouteParams } from "../../../../../routes/cadastro.route-params";
import { useBlockList } from "./_compose/use-block-list";
import { extractAgencyAccount } from "./_compose/utils";
import {
  usersBlockListDefaultValues,
  usersBlockListValidationSchema,
} from "./users-blocked.form";
import { UserForm } from "./users-blocked.types";

export const useUsersBlockList = () => {
  const params = useParams<OnboardingEditByIdRouteParams>();
  if (!params.id) throw new Error("No id account");

  const {
    callService: insertListOfBlockedUsers,
    loading: insertListOfBlockedUsersLoading,
  } = useServiceCall(service.adminBankinghub.insertBlockUsers);

  const {
    callService: deleteListOfBlockedUsers,
    loading: deleteListOfBlockedUsersLoading,
  } = useServiceCall(service.adminBankinghub.unlockBlockUsers);

  const { allUsersBlocked, getAllUsersBlockList, getAllUsersBlockListLoading, setAllUsersBlocked } =
    useBlockList();

  const { customer } = useCustomer();
  const allUsersAllowed = customer.users.filter((x) =>
    !x.legalRepresentative ? { name: x.name } : "",
  );

  const form = useForm<UserForm>({
    defaultValues: usersBlockListDefaultValues,
    resolver: yupResolver(usersBlockListValidationSchema),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset
  } = form;

  const resetUsersBlockList = () => {reset()};

  const submit = handleSubmit(async (values) => {
    if (!allUsersBlocked) throw new Error("No allUsersBlocked in submit");

    const { added, removed } = getModificationsToArray(
      allUsersBlocked,
      values.users,
    );

    if (added.length === 0 && removed.length === 0) {
      return OToastManager.info("Nenhuma modificação");
    }

    const allCpfBlocked: string[] = [];

    values.users.forEach((userName) => {
      const foundUser = customer.users.find(
        (user: User) => user.name === userName,
      );

      if (foundUser) {
        allCpfBlocked.push(foundUser.cpf);
      }
    });

    const promises: Promise<{ success: boolean }>[] = [];

    if (added.length > 0) {
      const resultBlockedUsers = added.map((add) => {
        const { agency, account } = extractAgencyAccount(
          params.id as string,
        );

        return insertListOfBlockedUsers({
          accountNumber: account,
          agency: agency,
          userDocument:
            customer.users.find((user) => user.name === add)?.cpf ?? "",
        });
      });

      promises.push(...resultBlockedUsers);
    }

    if (removed.length > 0) {
      const resultUnlockUsers = removed.map((remove: string) => {
        const { agency, account } = extractAgencyAccount(
          params.id as string,
        );

        return deleteListOfBlockedUsers({
          accountNumber: account,
          agency: agency,
          userDocument:
            customer.users.find((user) => user.name === remove)?.cpf ??
            "",
        });
      });

      promises.push(...resultUnlockUsers);
    }

    const successes = await Promise.all(promises);

    if (successes.every(({ success }) => success)) {
      setAllUsersBlocked(values.users);
      OToastManager.success("Usuários salvos com sucesso!");
    } else {
      OToastManager.danger("Erro para salvar os usuários");
    }
  },
  (errors) => {
    OToastManager.danger(
      `Campos obrigatórios: ${Object.keys(errors).join(", ")}`,
    );
  });

  useEffect(() => {
    if (allUsersBlocked) {
      reset({ users: allUsersBlocked });
    }
  }, [reset, allUsersBlocked]);

  const options = useMemo(
    () =>
      allUsersAllowed?.map<SelectSearchMultipleOption<string>>((profile) => ({
        label: profile.name,
        value: profile.name,
      })) ?? [],
    [allUsersAllowed],
  );

  const isDirty = !!dirtyFields.users;

  return {
    allUsersAllowed,
    form,
    isDirty,
    options,
    resetUsersBlockList,
    submit,
    allUsersBlocked,
    getAllUsersBlockList,
    loading:
      getAllUsersBlockListLoading ||
      insertListOfBlockedUsersLoading ||
      deleteListOfBlockedUsersLoading,
  };
};
