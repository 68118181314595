import { modalManager } from "@maestro/core";
import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { StepConfigSelector } from "../../../../../../../../../components/step-config-selector";
import { useAddDiligenceStepModal } from "./add-diligence-step-modal.hook";
import { addDiligenceStepModalId } from "./add-diligence-step-modal.utils";

interface AddDiligenceStepModalProps {
  diligenceId: number;
  reload: () => void;
}

export const AddDiligenceStepModal = ({
  diligenceId,
  reload,
}: AddDiligenceStepModalProps) => {
  const { form, loading, submit } = useAddDiligenceStepModal(
    diligenceId,
    reload,
  );

  return (
    <OModal
      id={addDiligenceStepModalId}
      style={{ position: "absolute" }}
      position="center"
      backdrop={!loading}
    >
      <OModalHeader>
        <OTypography size="lg">Adicionar step</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <StepConfigSelector
                id="step-config"
                field="diligenceStepConfigId"
                label="Configuração de step"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(addDiligenceStepModalId)}
            disabled={loading}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
