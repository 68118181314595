import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { RouterButton } from "components/router-button";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./representative-card.grid";

interface RepresentativeCardProps {
  representatives: HubOnboardingRelationships.Admin.LegalRepresentativesItem[];
  uuid: string;
  refetch: () => Promise<void>;
  canEdit: boolean;
}

export const RepresentativeCard = ({
  representatives,
  uuid,
  refetch,
  canEdit,
}: RepresentativeCardProps) => {
  const gridComponent = useMemo(() => {
    return grid({ refetch, canEdit, uuid });
  }, [refetch, canEdit, uuid]);

  return (
    <GridTemplate
      pageTitle={
        <OTypography size="lg" type="default" className="mb-3">
          Representantes
        </OTypography>
      }
      actions={
        <RouterButton
          dataAction="detalhes_avalista:botao:editar"
          dataLabel="editar"
          disabled={canEdit}
          href={corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.add.path(
            {
              uuid,
            },
          )}
          role={roles.cadastro.customer.guarantorsGuarantorsEmbeddedPage.role}
        >
          Adicionar
        </RouterButton>
      }
    >
      <ODataGridGenerator grid={gridComponent} dataSource={representatives} />
    </GridTemplate>
  );
};
