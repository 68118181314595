import { dataSourceCustomStoreGenerator } from "components/data-grid";
import type DataSource from "devextreme/data/data_source";
import { useServiceCall } from "hooks/service-call";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByStepIdRouteParams } from "../../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../../utils";

interface DiligenceStepContext {
  dataSource: DataSource<HubEnergy.DiligenceStepPropertyResponse>;
  documentTypesCallService: ReturnType<
    typeof useServiceCall<
      [],
      HubEnergy.ApiBaseResponse<HubEnergy.DocumentTypeResponse[]>
    >
  >["callService"];
  documentTypesHasError: boolean;
  documentTypesLoading: boolean;
  documentTypesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DocumentTypeResponse[]>
    | undefined;
  pendencyTypes: HubEnergy.DiligenceStepPendencyTypeResponse[];
  pendencyTypesCallService: ReturnType<
    typeof useServiceCall<
      [],
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyTypeResponse[]>
    >
  >["callService"];
  pendencyTypesHasError: boolean;
  propertiesLoading: boolean;
  propertiesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse[]>
    | undefined;
  propertyTypesCallService: ReturnType<
    typeof useServiceCall<
      [],
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse[]>
    >
  >["callService"];
  propertyTypesHasError: boolean;
  propertyTypesLoading: boolean;
  propertyTypesValue:
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse[]>
    | undefined;
  stepId: string;
}

const diligenceStepContext = createContext({} as DiligenceStepContext);

interface DiligenceStepProviderProps {
  children: React.ReactNode;
}

export const DiligenceStepProvider = ({
  children,
}: DiligenceStepProviderProps) => {
  const { stepId } = useParams<MleByStepIdRouteParams>();
  if (!stepId) throw new Error("No stepId");

  const {
    callService: propertiesCallService,
    value: propertiesValue,
    loading: propertiesLoading,
  } = useServiceCall(service.hubEnergy.getDiligenceStepByIdProperties);

  const {
    callService: pendencyTypesCallService,
    hasError: pendencyTypesHasError,
    value: pendencyTypesValue,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPendencyTypes);

  const {
    callService: propertyTypesCallService,
    loading: propertyTypesLoading,
    value: propertyTypesValue,
    hasError: propertyTypesHasError,
  } = useServiceCall(service.hubEnergy.getDiligenceStepPropertyTypes);

  const {
    callService: documentTypesCallService,
    loading: documentTypesLoading,
    value: documentTypesValue,
    hasError: documentTypesHasError,
  } = useServiceCall(service.hubEnergy.getDocumentTypes);

  useEffect(() => {
    pendencyTypesCallService();
  }, [pendencyTypesCallService]);

  useEffect(() => {
    propertyTypesCallService();
  }, [propertyTypesCallService]);

  useEffect(() => {
    documentTypesCallService();
  }, [documentTypesCallService]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        propertiesCallService(stepId)
          .then(({ response }) => response?.data?.response ?? [])
          .catch((err) => {
            const errorMessage =
              getValidationMessages(err)?.[0].errorMessage ??
              "Não foi possível buscar as propriedades";
            throw new Error(errorMessage);
          }),
      ),
    [propertiesCallService, stepId],
  );

  const pendencyTypes = useMemo(
    () => pendencyTypesValue?.response ?? [],
    [pendencyTypesValue?.response],
  );

  const value = useMemo(
    () => ({
      dataSource,
      documentTypesCallService,
      documentTypesHasError,
      documentTypesLoading,
      documentTypesValue,
      pendencyTypes,
      pendencyTypesCallService,
      pendencyTypesHasError,
      propertiesLoading,
      propertiesValue,
      propertyTypesCallService,
      propertyTypesHasError,
      propertyTypesLoading,
      propertyTypesValue,
      stepId,
    }),
    [
      dataSource,
      documentTypesCallService,
      documentTypesHasError,
      documentTypesLoading,
      documentTypesValue,
      pendencyTypes,
      pendencyTypesCallService,
      pendencyTypesHasError,
      propertiesLoading,
      propertiesValue,
      propertyTypesCallService,
      propertyTypesHasError,
      propertyTypesLoading,
      propertyTypesValue,
      stepId,
    ],
  );

  return (
    <diligenceStepContext.Provider value={value}>
      {children}
    </diligenceStepContext.Provider>
  );
};

export const useDiligenceStep = () => useContext(diligenceStepContext);
