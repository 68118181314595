import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoGridById } from "../../../../../routes/workflow.route-params";
import { getNewCardsOrder } from "../../../configuracoes-de-workflows/[id]/editar/edit-workflow-config.utils";
import {
  editGridDefaultValues,
  EditGridFormFields,
  editGridSchema,
} from "./edit-grid.schema";

export const useEditGrid = () => {
  const [selectedGridColumnsInOrder, setSelectedGridColumnsInOrder] = useState<
    string[]
  >([]);

  const cardBeingDragged = useRef<number>();
  const cardBeingDraggedOver = useRef<number>();

  const { gridId } = useParams<WorkflowProdutoGridById>();
  if (!gridId) throw new Error("No grid id");

  const form = useForm<EditGridFormFields>({
    resolver: yupResolver(editGridSchema),
    defaultValues: editGridDefaultValues,
  });

  const { setValue, watch } = form;

  const gridColumnsWatch = watch("configurableGridColumns");

  const {
    value: workflowGrid,
    callService: getWorkflowGrid,
    loading: loadingWorkflowGrid,
    hasError: hasErrorWorkflowGrid,
  } = useServiceCall(service.hubCreditManager.getGridById);

  const {
    value: gridColumnOptions,
    callService: getConfigurableGridColumns,
    loading: loadingConfigurableGridColumns,
    hasError: hasErrorConfigurableGridColumns,
  } = useServiceCall(service.hubCreditManager.getConfigurableGridColumns);

  const { callService: callUpsertGrid, loading: loadingUpsertGrid } =
    useServiceCall(service.hubCreditManager.upsertGrid);

  const reorderCards = useCallback(() => {
    if (
      typeof cardBeingDragged.current === "number" &&
      typeof cardBeingDraggedOver.current === "number"
    ) {
      setSelectedGridColumnsInOrder(
        getNewCardsOrder(
          selectedGridColumnsInOrder,
          cardBeingDragged.current,
          cardBeingDraggedOver.current,
        ),
      );
    }

    cardBeingDragged.current = undefined;
    cardBeingDraggedOver.current = undefined;
  }, [selectedGridColumnsInOrder]);

  const getAllData = useCallback(async () => {
    await Promise.all([getWorkflowGrid(gridId), getConfigurableGridColumns()]);
  }, [getConfigurableGridColumns, getWorkflowGrid, gridId]);

  const updateGrid = useCallback(
    async (values: EditGridFormFields) => {
      const { name } = values;

      const { success } = await callUpsertGrid({
        gridId: Number(gridId),
        name,
        configurableGridColumnIds: selectedGridColumnsInOrder.map(
          (gridColumnId) => Number(gridColumnId),
        ),
      });

      if (success) {
        OToastManager.success("Grid atualizado com sucesso!");
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar atualizar os dados do grid. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callUpsertGrid, gridId, selectedGridColumnsInOrder],
  );

  useEffect(() => {
    if (selectedGridColumnsInOrder.length < gridColumnsWatch.length) {
      const gridColumnIdsNotIncluded = gridColumnsWatch.filter(
        (gridColumnId: string) =>
          !selectedGridColumnsInOrder.includes(gridColumnId),
      );

      const selectedGridColumnsAux = [
        ...selectedGridColumnsInOrder,
        ...gridColumnIdsNotIncluded,
      ];

      setSelectedGridColumnsInOrder(selectedGridColumnsAux);
    } else if (selectedGridColumnsInOrder.length > gridColumnsWatch.length) {
      setSelectedGridColumnsInOrder([...gridColumnsWatch]);
    }
  }, [gridColumnsWatch, selectedGridColumnsInOrder]);

  useEffect(() => {
    if (workflowGrid) {
      const workflowConfigConfigurableGridColumnIds =
        workflowGrid.gridColumns.map((x) =>
          String(x.configurableGridColumn.id),
        );

      setValue("name", workflowGrid.name);

      setValue(
        "configurableGridColumns",
        workflowConfigConfigurableGridColumnIds,
      );
    }
  }, [setValue, workflowGrid]);

  useEffect(() => {
    hasErrorWorkflowGrid &&
      OToastManager.danger(
        "Um erro ocorreu ao buscar os dados do grid. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorWorkflowGrid]);

  useEffect(() => {
    hasErrorConfigurableGridColumns &&
      OToastManager.warning(
        "Um erro ocorreu ao buscar as colunas configuráveis. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorConfigurableGridColumns]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    cardBeingDragged,
    cardBeingDraggedOver,
    form,
    getAllData,
    gridColumnOptions,
    gridColumnsWatch,
    hasErrorConfigurableGridColumns,
    hasErrorWorkflowGrid,
    workflowGrid,
    loadingConfigurableGridColumns,
    loadingUpsertGrid,
    loadingWorkflowGrid,
    reorderCards,
    selectedGridColumnsInOrder,
    updateGrid,
  };
};
