import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { useEffect } from "react";
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { OButton } from "@maestro/react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { DraggableScoreBenefits } from "./_compose/draggable-score-benefits.component";

export const ScoreBenefitsPage = () => {
  const {
    callService: getScoreBenefits,
    value: benefits,
    hasError,
    loading,
  } = useServiceCall(service.hubCreditManager.getScoreBenefits);

  const navigate = useNavigate();

  useEffect(() => {
    getScoreBenefits();
  }, [getScoreBenefits]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Benefícios" />}
      actions={
        <OButton
          onClick={() => {
            navigate(
              corporateRouter.routes.workflow.product.originatorScore.benefits
                .add.path,
            );
          }}
        >
          Adicionar
        </OButton>
      }
    >
      <ContentTemplate
        loading={loading && !benefits?.length}
        hasError={hasError}
        value={benefits?.length}
        errorComponent={
          <ErrorComponent
            messageTitle="Um erro ocorreu ao buscar os benefícios."
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={getScoreBenefits} />
          </ErrorComponent>
        }
        emptyStateComponent={
          <EmptyState messageTitle="Nenhum benefício foi adicionado no momento." />
        }
        render={() => (
          <DraggableScoreBenefits
            getScoreBenefits={getScoreBenefits}
            benefits={benefits}
          />
        )}
      />
    </DetailsTemplate>
  );
};
