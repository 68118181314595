import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { service } from "services";
import { isAxiosError } from "axios";
import { logger } from "utils/logger";
import { OToastManager } from "@maestro/core";
import { buildGrid } from "./allow.grid";
import { CustomPagination } from "./compose/custom-pagination";

export const EligibilityAllowList = () => {
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(999);

  const { routes } = corporateRouter;
  const navigate = useNavigate();
  const redirectPageEdit = useCallback(
    (allowList: Validator.Allow) =>
      navigate(
        corporateRouter.routes.fidc.product.eligibilitys.eligibilityAllowList
          .edit.path,
        { state: { allowList } },
      ),
    [navigate],
  );

  const gridRef = useRef<DataGrid>(null);

  const dataSource = dataSourceCustomStoreGenerator<Validator.Allow>(
    () =>
      service.validator
        .getAllowList({
          Limit: rows,
          Offset: (page - 1) * rows,
        })
        .then(({ data }) => {
          setTotalItems(data.totalItems);
          return data;
        })
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar lista.";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        remove: async (data) => {
          try {
            await service.validator.DeleteAllow(data.id);
            OToastManager.success("Identificador removido com sucesso");
          } catch (err) {
            if (!isAxiosError(err)) logger.error(err);
            OToastManager.danger("Não foi possível remover o template");
          }
        },
      },
    },
  );

  const deleteRow = useCallback(
    (id: number) => gridRef.current?.instance.deleteRow(id),
    [],
  );

  const grid = useMemo(
    () => buildGrid(deleteRow, redirectPageEdit),
    [deleteRow, redirectPageEdit],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Elegibilidade - Lista de Permissões"
          description="Lista de identificadores cadastrados com permissão para validação de elegibilidade."
        />
      }
      actions={
        <RouterButton
          dataAction="fidc_alegibilidade_sistema:botao:adicionar"
          dataLabel="adicionar"
          href={routes.fidc.product.eligibilitys.eligibilityAllowList.add.path}
          role={roles.fidc.product.eligibilitysPage.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showExportButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[10]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
    </GridTemplate>
  );
};
