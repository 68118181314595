import { OIcon } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { UnstyledButton } from "components/unstyled-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { Tier } from "../../tiers.types";

export const ActionCell: ODataGridGeneratorConfig<Tier>["columns"][number]["cellRender"] =
  (cell) => {
    const { component, rowIndex, row, data } = cell;

    if (row.isEditing) {
      return (
        <div className="d-flex justify-content-center gap-2">
          <UnstyledButton
            title="Desfazer"
            onClick={() => component.cancelEditData()}
          >
            <OIcon category="fa" icon="fa-undo" />
          </UnstyledButton>
          <UnstyledButton
            title="Desfazer"
            onClick={() => component.saveEditData()}
          >
            <OIcon category="fa" icon="fa-save" />
          </UnstyledButton>
        </div>
      );
    }

    return (
      <DataGridAction
        actions={[
          {
            icon: { category: "fas", icon: "fa-plus" },
            label: "Adicionar relacionamento",
            route:
              corporateRouter.routes.antecipacaoDeCartoes.product.enquadramento.tiers.addRelationship.path(
                { tierId: data.id },
              ),
            role: roles.antecipacaoDeCartoes.product
              .enquadramentoTiersAddRelationshipPage.role,
          },
          {
            icon: { category: "fas", icon: "fa-pen" },
            label: "Editar",
            onClick: () => component.editRow(rowIndex),
          },
          {
            icon: { category: "fas", icon: "fa-trash" },
            label: "Remover",
            onClick: () => component.deleteRow(rowIndex),
          },
        ]}
      />
    );
  };
