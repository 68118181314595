import { ODataGridGeneratorConfig } from "components/data-grid";
import { Collaterals } from "../../collaterals.types";
import { CollateralsPropertiesMasterDetail } from "../collaterals-master-detail/collaterals-properties.master-detail";

export const sacasPropertiesGrid = {
  datagrid: {
    noDataText: "Nenhum registro associado foi encontrado.",
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "registration",
      caption: "Registro",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "idealFraction",
      caption: "Fração ideal",
    },
    {
      dataField: "car",
      caption: "CAR",
    },
    {
      dataField: "ccir",
      caption: "CCIR",
    },
    {
      dataField: "nirf",
      caption: "NIRF",
    },
    {
      dataField: "rgi",
      caption: "RGI",
    },
    {
      caption: "Área total",
      dataField: "totalArea",
    },
    {
      caption: "Rua",
      dataField: "address.addressStreet",
    },
    {
      caption: "Número",
      dataField: "address.buildingNumber",
    },
    {
      caption: "Cidade",
      dataField: "address.city",
    },
    {
      caption: "Distrito",
      dataField: "address.district",
    },
    {
      caption: "Estado",
      dataField: "address.state",
    },
    {
      caption: "CEP",
      dataField: "address.zipCode",
    },
  ],
  masterDetail: {
    enabled: true,
    component: CollateralsPropertiesMasterDetail,
  },
} satisfies ODataGridGeneratorConfig<Collaterals[number]["properties"][number]>;
