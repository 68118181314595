/* eslint-disable no-param-reassign */
import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { errorMessageMap } from "../../../../../utils";
import { Receivable } from "../../batch-upload-receivables.types";
import { highlightErrors } from "./validation-results.utils";
import { uniqBy } from "lodash";

export const validationResultsGrid: ODataGridGeneratorConfig<Receivable> = {
  datagrid: {
    noDataText: "Nenhum recebível",
    pager: { showPageSizeSelector: true },
    headerFilter: { visible: true },
    filterRow: { visible: true },
    onCellPrepared: highlightErrors,
  },
  columns: [
    {
      dataField: "errorMessages",
      caption: "Situação",
      sortOrder: "desc",
      allowHeaderFiltering: false,
      calculateCellValue: ({ errorMessages }) => errorMessages?.join(" "),
      cellRender: ({ data: { errorMessages } }) => (
        <div>
          {!errorMessages?.length ? (
            <OIcon category="fa" icon="fa-check" />
          ) : (
            uniqBy(errorMessages, "message").map((error) => (
              <p key={error.key + error.message}>
                {getValueFromMap(errorMessageMap, error.message) ??
                  errorMessageMap.default}
              </p>
            ))
          )}
        </div>
      ),
    },
    {
      dataField: "identification",
      caption: "Número da nota",
      allowHeaderFiltering: false,
    },
    { dataField: "legalName", caption: "Fornecedor" },
    {
      dataField: "taxId",
      caption: "Identificação (CNPJ/CPF/Offshore)",
      allowHeaderFiltering: false,
    },
    { dataField: "currency", caption: "Moeda" },
    {
      dataField: "maturityAmount",
      caption: "Valor de face",
      cellRender: ({ data: { currency, maturityAmount } }) =>
        masks.currency(
          maturityAmount,
          getValueFromMap(currencySymbol, currency),
        ),
      allowHeaderFiltering: false,
    },
    {
      dataField: "disbursementAmount",
      caption: "Valor de desembolso",
      cellRender: ({ data: { currency, disbursementAmount } }) =>
        masks.currency(
          disbursementAmount,
          getValueFromMap(currencySymbol, currency),
        ),
      allowHeaderFiltering: false,
    },
    {
      dataField: "rebate",
      caption: "Rebate",
      cellRender: ({ data: { currency, rebate } }) =>
        masks.currency(rebate, getValueFromMap(currencySymbol, currency)),
      allowHeaderFiltering: false,
    },
    {
      dataField: "disbursementDate",
      caption: "Data desembolso",
      dataType: "date",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturityDate",
      caption: "Data vencimento",
      dataType: "date",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "dataRepasse",
      caption: "Data repasse",
      dataType: "date",
      format: (value) => dayjs(value).utc(false).format("DD/MM/YYYY"),
      allowHeaderFiltering: false,
    },
    {
      dataField: "bankCode",
      caption: "Código do banco",
      allowHeaderFiltering: false,
    },
    {
      dataField: "agency",
      caption: "Agência",
      allowHeaderFiltering: false,
    },
    {
      dataField: "accountNumber",
      caption: "Conta",
      allowHeaderFiltering: false,
    },
    {
      dataField: "accountDigit",
      caption: "Dígito",
      allowHeaderFiltering: false,
    },
    {
      dataField: "pixType",
      caption: "Tipo Pix",
      allowHeaderFiltering: false,
    },
    {
      dataField: "pixKey",
      caption: "Chave Pix",
      allowHeaderFiltering: false,
    },
    {
      dataField: "hasHedge",
      caption: "Tem Hedge",
      allowHeaderFiltering: false,
    },
    {
      dataField: "iof",
      caption: "IOF",
      cellRender: ({ data: { currency, iof } }) =>
        masks.currency(iof, getValueFromMap(currencySymbol, currency)),
      allowHeaderFiltering: false,
    },
    {
      dataField: "repository",
      caption: "Registradora",
      allowHeaderFiltering: false,
    },
    {
      dataField: "registerNumber",
      caption: "Nº de registro",
      allowHeaderFiltering: false,
    },
    {
      dataField: "exchangeRate",
      caption: "Taxa de câmbio",
      allowHeaderFiltering: false,
    },
    {
      dataField: "clientLoanPrincipal",
      caption: "Valor principal do cliente",
      cellRender: ({ data: { currency, clientLoanPrincipal } }) =>
        masks.currency(
          clientLoanPrincipal,
          getValueFromMap(currencySymbol, currency),
        ),
      allowHeaderFiltering: false,
    },
    {
      dataField: "loanRate",
      caption: "Taxa do empréstimo",
      cellRender: ({ data: { loanRate } }) => masks.percentage(loanRate),
      allowHeaderFiltering: false,
    },
    {
      dataField: "loanBaseDayCountCode",
      caption: "Base de dias do empréstimo",
      allowHeaderFiltering: false,
    },
    {
      dataField: "propertyName",
      caption: "Nome do empreendimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "propertyUnit",
      caption: "Unidade do empreendimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "propertyBlock",
      caption: "Bloco do empreendimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "externalId",
      caption: "Origin Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "country",
      caption: "País",
      allowHeaderFiltering: false,
    }
  ],
};
