import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";

export const importFidcGrid: ODataGridGeneratorConfig<Fidc.Vehicle> = {
  datagrid: {
    noDataText: "Nenhum veículo importado.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} ítens)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "Valor",
      caption: "Valor",
    },
    {
      dataField: "Chassi do Carro",
      caption: "Chassi do Carro",
    },
    {
      dataField: "Renavam",
      caption: "Renavam",
    },
    {
      dataField: "Ano Modelo",
      caption: "Ano Modelo",
    },
    {
      dataField: "CNPJ Proprietário",
      caption: "CNPJ Proprietário",
      cellRender: ({ data }) => masks.cpfCnpj(data["CNPJ Proprietário"]),
    },
    {
      dataField: "Placa",
      caption: "Placa",
    },
    {
      dataField: "Ano Fabricação",
      caption: "Ano Fabricação",
    },
  ],
};
