import { OButton, modalManager } from "@maestro/react";
import { AddDiligenceStepModal, addDiligenceStepModalId } from "./_compose";

interface AddDiligenceStepButtonProps {
  diligenceId: number;
  reload: () => void;
}

export const AddDiligenceStepButton = ({
  diligenceId,
  reload,
}: AddDiligenceStepButtonProps) => {
  return (
    <>
      <OButton onClick={() => modalManager.show(addDiligenceStepModalId)}>
        Adicionar step
      </OButton>
      <AddDiligenceStepModal diligenceId={diligenceId} reload={reload} />
    </>
  );
};
