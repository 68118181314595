import { OTypography } from "@maestro/react";
import { MasterDetailComponentProps } from "components/data-grid";
import { DefaultErrorComponent } from "components/empty-state";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { Contract } from "../operations-contracts.context";
import { RulesSignatory } from "./contract-rule-signatory.component";

type ContractSignatoriesMasterDetailType = MasterDetailComponentProps<Contract>;
export const ContractSignatoriesMasterDetail = ({
  data: {
    data: { externalId, status },
  },
}: ContractSignatoriesMasterDetailType) => {
  const {
    callService: getContractDetails,
    loading,
    hasError,
    value: contractDetails,
  } = useServiceCall(service.paperclipv2.getContractDetails);

  useEffect(() => {
    if (status === "Pending" || !externalId) return;

    getContractDetails(externalId);
  }, [externalId, status, getContractDetails]);

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      errorComponent={
        <DefaultErrorComponent
          title="Não foi possível buscar os detalhes do contrato"
          callback={() => getContractDetails(externalId)}
        />
      }
      value={contractDetails}
      render={(_contractDetails) => (
        <div className="d-flex flex-column gap-4">
          <OTypography tag="h3" size="lg" type="default">
            Detalhes do contrato
          </OTypography>
          <div
            dangerouslySetInnerHTML={{
              __html: contractDetails?.details ?? "",
            }}
          />
          <OTypography tag="h3" size="lg" type="default">
            Signatários
          </OTypography>

          <RulesSignatory contract={_contractDetails} />
        </div>
      )}
    />
  );
};
