import styled from "styled-components";

export const ConfigurationPre = styled.pre`
  background: #2c3e50;
  color: #ecf0f1;
  height: 400px;
  line-height: 150%;
  overflow-y: auto;
  padding: 20px;
  text-align: left;
`;
