import { UserNotification } from "services/raven/models/types";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DownloadEmailButton } from "./_compose";

export const notificationsGrid: ODataGridGeneratorConfig<UserNotification> = {
  datagrid: {
    noDataText: "Nenhuma notificação encontrada.",
    remoteOperations: true,
    pager: {
      allowedPageSizes: [10, 20, 40, 100, 500],
      infoText: "Página {0} de {1} ({2} notificações)",
    },
    filterRow: { visible: true },
  },
  columns: [
    {
      dataField: "type",
      dataType: "string",
      caption: "Tipo",
    },
    {
      dataField: "title",
      dataType: "string",
      caption: "Título",
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
    },
    {
      dataField: "insertedAt",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Cadastrado em",
    },
    {
      dataField: "trackerDelivery",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Enviado em",
    },
    {
      dataField: "trackerSend",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Entregue em",
    },
    {
      dataField: "trackerOpen",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Lido em",
    },
    {
      dataField: "fileKey",
      dataType: "string",
      caption: "E-mail enviado",
      cellRender: ({ data }) => <DownloadEmailButton data={data} />,
    },
  ],
};
