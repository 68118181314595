import { OButton, OIcon, OTypography, modalManager } from "@maestro/react";
import { useEffect, useRef, useState } from "react";
import {
  ColumnGap,
  InfoGrid,
  RowGap,
  RowSpacedContainer,
  SubsectionContainer,
} from "../../cards.styles";
import { ItemCategory } from "../../cards.types";
import { GeneralInfo } from "../general-info/general-info.component";
import { RefusalTransactionModal } from "../refusal-transaction-modal/refusal-transaction-modal.component";
import { ChargebackStatusFromTransactionsId } from "../utils/chargeback";
import { transactionStatusByCategoryAndType } from "../utils/transaction-details";
import {
  ChargebackInstructionAlreadyOpened,
  ChargebackInstructionWrongCaseType,
  TransactionGeneralDetails,
  TransactionInternationalDetails,
} from "./constants";
import { RelatedItems } from "./related-items/related-items.component";
import { ChargebackInstructions } from "./transaction-details.styles";
import { ITransactionDetailsProps } from "./transaction-details.types";

export const TransactionDetails = ({
  transactionDetailsData,
  transactionRelatedItems,
  closeTransactionDetails,
  onRequestContestationData,
  isChargeback,
  isChargebackTransaction,
  openContestations,
}: ITransactionDetailsProps) => {
  const DetailsContainerRef = useRef<HTMLDivElement | null>(null);

  const [isRefusalTransactionModalOpen, setIsRefusalTransactionModalOpen] =
    useState(false);

  useEffect(() => {
    const closeCleanUp = modalManager.on(
      "refusal-transaction-modal-id",
      "modalClose",
      () => {
        setIsRefusalTransactionModalOpen(false);
      },
    );

    return () => {
      closeCleanUp();
    };
  }, []);

  const [chargebackStatus, setChargebackStatus] = useState<{
    status: boolean;
    message: string;
  }>();

  useEffect(() => {
    setTimeout(() => {
      const lastElement = DetailsContainerRef.current?.lastElementChild;
      lastElement?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (!openContestations) return;
    const status = ChargebackStatusFromTransactionsId(
      transactionDetailsData.authorizationId,
      openContestations,
    );
    setChargebackStatus(status);
  }, [transactionDetailsData, openContestations]);

  const valueFromKey = (key: string) => {
    const value =
      transactionDetailsData[key as keyof typeof transactionDetailsData];
    return !!value ? value : "-";
  };

  const isInternational = (): boolean => {
    return transactionDetailsData.type === "Compra internacional";
  };

  const isFromInvoiceItem = (): boolean => {
    return transactionDetailsData.itemOrigin === "Invoice";
  };

  return (
    <>
      <SubsectionContainer ref={DetailsContainerRef}>
        {isRefusalTransactionModalOpen && (
          <RefusalTransactionModal info={transactionDetailsData} />
        )}
        <ColumnGap rowGap={10}>
          <RowSpacedContainer>
            <OTypography type="dark">Detalhes da transação</OTypography>
            <OButton
              className="icon-button"
              outline
              bordered={false}
              dataAction="cartoes-transaction-details:botao:fechar-detalhes-transacao"
              dataLabel="fechar-detalhes-transacao"
              title="Fechar detalhes da transação"
              onClick={() => {
                closeTransactionDetails();
                onRequestContestationData.setOnRequestContestation(false);
              }}
            >
              <OIcon type="dark" category="fal" icon="fa-times-circle" />
            </OButton>
          </RowSpacedContainer>
          <ColumnGap rowGap={15}>
            <InfoGrid columns={4} columnsWidth={195}>
              {TransactionGeneralDetails.map((info) => {
                return (
                  <GeneralInfo
                    label={info.label}
                    value={valueFromKey(info.key)}
                    strongValue={false}
                    key={info.key}
                  />
                );
              })}
            </InfoGrid>
            <InfoGrid columns={2} columnsWidth={405}>
              <InfoGrid columns={2} columnsWidth={195}>
                <RowGap columnGap={5}>
                  <GeneralInfo
                    label={"Status"}
                    value={transactionStatusByCategoryAndType(
                      transactionDetailsData.itemType,
                      transactionDetailsData.itemCategory,
                    )}
                    strongValue={false}
                    key={"status"}
                  />
                  {transactionDetailsData.itemCategory ===
                    ItemCategory.Refusal && (
                    <OButton
                      outline
                      bordered={false}
                      disabled={
                        !(
                          !!transactionDetailsData.refusalReason ||
                          !!transactionDetailsData.attendantRefusalReason
                        )
                      }
                      dataAction="cartoes-transaction-details:botao:info"
                      dataLabel="info"
                      type="danger"
                      onClick={() => {
                        if (
                          !!transactionDetailsData.refusalReason ||
                          !!transactionDetailsData.attendantRefusalReason
                        ) {
                          setIsRefusalTransactionModalOpen(true);
                        }
                      }}
                    >
                      <OIcon category="orq" icon="orq-status-error" />
                    </OButton>
                  )}
                </RowGap>
                <GeneralInfo
                  label={"Estabelecimento"}
                  value={valueFromKey("merchantName")}
                  strongValue={false}
                  key={"merchantName"}
                />
              </InfoGrid>
              <GeneralInfo
                label={"Descritivo"}
                value={valueFromKey("description")}
                strongValue={false}
                key={"description"}
              />
            </InfoGrid>
            {isInternational() && (
              <InfoGrid columns={4} columnsWidth={195}>
                {TransactionInternationalDetails.map((info) => {
                  if (
                    (isFromInvoiceItem() && info.key === "iofValue") ||
                    (!isInternational() && info.key === "dcc")
                  )
                    return null;
                  return (
                    <GeneralInfo
                      label={info.label}
                      value={valueFromKey(info.key)}
                      strongValue={false}
                      key={info.key}
                    />
                  );
                })}
              </InfoGrid>
            )}
          </ColumnGap>
        </ColumnGap>
        {transactionRelatedItems && (
          <RelatedItems transactionRelatedItems={transactionRelatedItems} />
        )}
        {chargebackStatus && isChargeback && isChargebackTransaction && (
          <RowGap columnGap={30}>
            <OButton
              title={chargebackStatus.message}
              dataAction="cartoes-transaction-details:botao:contestacao"
              dataLabel="contestacao"
              disabled={
                onRequestContestationData.onRequestContestation ||
                chargebackStatus.status
              }
              onClick={() =>
                onRequestContestationData.setOnRequestContestation(true)
              }
            >
              Contestação
            </OButton>
            {(!isChargeback || chargebackStatus.status) && (
              <ChargebackInstructions>
                {!isChargeback
                  ? ChargebackInstructionWrongCaseType
                  : ChargebackInstructionAlreadyOpened}
              </ChargebackInstructions>
            )}
          </RowGap>
        )}
      </SubsectionContainer>
    </>
  );
};
