import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GatekeeperProfileDetailsAddForm } from "./_compose";
import { useGatekeeperProfilesDetailsAdd } from "./details-add.hook";

export const GatekeeperProfilesDetailsAdd = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const { submit, form, loading } = useGatekeeperProfilesDetailsAdd(uuid);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Detalhes do Perfil Gatekeeper"
          description="Adicione um detalhe ao perfil Gatekeeper"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_detalhe:botao:criar_detalhe"
          dataLabel="criar_detalhe"
          onClick={submit}
        >
          Criar Detalhe
        </LoadingButton>
      }
    >
      <CardTemplate>
        <GatekeeperProfileDetailsAddForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
