import { OLoader } from "@maestro/react";
import { Auth, auth } from "contexts/auth";
import { behaviorManager, ChangeBehaviorCommand } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { useImpersonate } from "contexts/impersonate/impersonate.component";
import { GetUserCommand, userManager, useUnsafeUser } from "contexts/user";
import React, { FC, useEffect, useState } from "react";

interface ImpersonateGuardProps {
  children?: React.ReactNode;
}

export const ImpersonateGuard: FC<ImpersonateGuardProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const impersonateContext = useImpersonate();
  const { user } = useUnsafeUser();

  useEffect(() => {
    const load = async () => {
      const token = window.location.pathname
        .split("/impersonar/")[1]
        .split("/")[0]
        .split("?")[0];

      auth.value = token;
      Auth.storage.set(token);

      await behaviorManager.execute(
        new ChangeBehaviorCommand(Behaviors.Corban),
      );
      await userManager.execute(new GetUserCommand());

      if (impersonateContext.setIsImpersonating) {
        impersonateContext.setIsImpersonating(true);
      }
    };

    if (window.location.pathname.startsWith("/impersonar")) {
      load();
    } else {
      setLoading(false);
    }
  }, [impersonateContext]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/impersonar") && user?.roles) {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <OLoader absolute data-component="ImpersonateGuard" />;
  }

  return children as JSX.Element;
};
