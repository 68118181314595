import {
  ItemCategory,
  ItemType,
  MapperItemCategoryAndType,
} from "../../cards.types";

export const transactionStatusByCategoryAndType = (
  type: ItemType,
  category: ItemCategory,
): string => {
  if (type === ItemType.Confirmation) return MapperItemCategoryAndType[type];
  return MapperItemCategoryAndType[category];
};
