import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { useAddressFields } from "./address-fields.hook";

interface AddressFieldsProps {
  addressKey: string;
  index: number;
}

export const AddressFields = ({ addressKey, index }: AddressFieldsProps) => {
  const { hasFoundAddress, loadingAddress } = useAddressFields(index);

  if (loadingAddress) {
    return <OLoader absolute />;
  }

  return (
    <ORow key={addressKey}>
      <OCol xl={2}>
        <ORFieldInput
          tag="cep"
          id={`addresses.${index}.zipCode`}
          name={`addresses.${index}.zipCode`}
          label="CEP"
        />
      </OCol>
      <OCol xl={4}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.street`}
          name={`addresses.${index}.street`}
          label="Endereço"
          disabled={hasFoundAddress}
        />
      </OCol>
      <OCol xl={2}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.number`}
          name={`addresses.${index}.number`}
          label="Número"
        />
      </OCol>
      <OCol xl={4}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.complement`}
          name={`addresses.${index}.complement`}
          label="Complemento"
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.neighborhood`}
          name={`addresses.${index}.neighborhood`}
          label="Bairro"
          disabled={hasFoundAddress}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.cityName`}
          name={`addresses.${index}.cityName`}
          label="Cidade"
          disabled={hasFoundAddress}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.stateCode`}
          name={`addresses.${index}.stateCode`}
          label="Estado"
          disabled={hasFoundAddress}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`addresses.${index}.countryCode`}
          name={`addresses.${index}.countryCode`}
          label="País"
          disabled={hasFoundAddress}
        />
      </OCol>
    </ORow>
  );
};
