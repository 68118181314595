import { Routes } from "routes/routes.types";
import { b2bRoles } from "../../../roles/b2b.roles";

const DummyElement = () => <div />;

export const b2bEmbeddedRoutes = {
  b2b: {
    name: "B2B",
    path: "/finders",
    children: {
      product: {
        name: "Portal B2B",
        path: "/finders/produto",
        role: b2bRoles.product.tab.role,
        element: <DummyElement />,
        children: {
          monitoramento: {
            name: "Monitoramento",
            path: "/finders/produto/monitoramento",
            showChildren: true,
            role: b2bRoles.product.monitoringPage.role,
            element: <DummyElement />,
            children: {
              summary: {
                name: "Resumo",
                path: "/finders/produto/monitoramento/:proposalId/resumo",
                role: b2bRoles.product.summaryPage.role,
                element: <DummyElement />,
              },
              documents: {
                name: "Documentos",
                path: "/finders/produto/monitoramento/:proposalId/documentos",
                role: b2bRoles.product.documentsPage.role,
                element: <DummyElement />,
              },
              pendencies: {
                name: "Pendências",
                path: "/finders/produto/monitoramento/:proposalId/pendencias",
                role: b2bRoles.product.pendenciesPage.role,
                element: <DummyElement />,
              },
            },
          },
          clientes: {
            name: "Clientes",
            path: "/finders/produto/clientes",
            role: b2bRoles.product.clientesPage.role,
            element: <DummyElement />,
          },
          simulador: {
            name: "Simulador",
            path: "/finders/produto/simulador",
            role: b2bRoles.product.simuladorPage.role,
            element: <DummyElement />,
          },
          catalogo: {
            name: "Catálogo de produtos",
            path: "/finders/produto/catalogo",
            role: b2bRoles.product.catalogoPage.role,
            element: <DummyElement />,
          },
          operacoes: {
            name: "Operações",
            path: "/finders/produto/operacoes",
            role: b2bRoles.product.operacoesPage.role,
            element: <DummyElement />,
          },
          remuneracao: {
            name: "Remuneração",
            path: "/finders/produto/remuneracao",
            role: b2bRoles.product.remuneracaoPage.role,
            element: <DummyElement />,
          },
          originadores: {
            name: "Originadores",
            path: "/finders/produto/originadores",
            role: b2bRoles.product.originadoresPage.role,
            element: <DummyElement />,
          },
          operadores: {
            name: "Operadores",
            path: "/finders/produto/operadores",
            role: b2bRoles.product.operadoresPage.role,
            element: <DummyElement />,
          },
          perfil: {
            name: "Perfil",
            path: "/finders/produto/perfil",
            element: <DummyElement />,
          },
          atendimentoAoCliente: {
            name: "Atendimento ao cliente",
            path: "/finders/produto/atendimento",
            hasSubItems: true,
            children: {
              materiaisDeApoio: {
                name: "Materiais de apoio",
                path: "/finders/produto/atendimento/materiais-de-apoio",
                role: b2bRoles.product.materiaisDeApoioPage.role,
                element: <DummyElement />,
              },
              entreEmContato: {
                name: "Entre em contato",
                path: "/finders/produto/atendimento/entre-em-contato",
                role: b2bRoles.product.entreEmContatoPage.role,
                element: <DummyElement />,
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
