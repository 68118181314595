import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { useCustomer } from "contexts/customer";
import { OnboardingEditByIdRouteParams } from "../../../../../../../routes/cadastro.route-params";
import { extractAgencyAccount } from "../utils";

export const useBlockList = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams<OnboardingEditByIdRouteParams>();
  const [allUsersBlocked, setAllUsersBlocked] = useState<string[]>([]);

  const { customer } = useCustomer();

  const getAllUsersBlockList = useCallback(async () => {
    setLoading(true);
    const dataAccounts = await service.adminBankinghub.getAccountsPermissions();

    const accountFound = dataAccounts.data.data.find((acc) => {
      const { agency, account } = extractAgencyAccount(params.id as string);

      return (
        acc.branch === agency && account.includes(acc.accountNumber)
      );
    });

    if (accountFound) {
      const usersBlocked = (accountFound?.blocked ?? []).reduce(
        (acc, blockedUser) => {
          const found = customer.users.find(
            (user) => user.cpf === blockedUser,
          );
          if (found) {
            acc.push(found.name);
          }

          return acc;
        },
        [] as string[],
      );

      setAllUsersBlocked(usersBlocked);
    }

    setLoading(false);
  }, [customer.users, params.id]);

  useEffect(() => {
    getAllUsersBlockList();
  }, [getAllUsersBlockList]);

  return {
    allUsersBlocked,
    getAllUsersBlockList,
    getAllUsersBlockListLoading: loading,
    setAllUsersBlocked
  };
};
