import { ORow } from "@maestro/react";
import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { CurrentAccountCard } from "./current-account";
import { EloaCard } from "./eloa";
import { InviteCard } from "./invite";
import { OnboardingReviewCard } from "./onboarding-review";
import { PlanCard } from "./plan";
import { DataUpdateCard } from "./data-update";

export const Cards = () => {
  const { hasRole } = useRoles();

  return (
    <ORow gap={3}>
      {hasRole(roles.cadastro.customer.inviteCardPage.role) && <InviteCard />}
      <EloaCard />
      <PlanCard />
      <OnboardingReviewCard />
      {hasRole(roles.cadastro.customer.detailsDataUpdatePage.role) && <DataUpdateCard />}
      {hasRole(roles.cadastro.customer.checkingAccountOpening.role) && <CurrentAccountCard />}
    </ORow>
  );
};
