import dayjs from "dayjs";
import {
  PostAdminReceivablesSearchResponse,
  PostAdminReceivablesSearchResponseItem,
} from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { parseExcelDate } from "utils/parse-excel-date";
import { cleanTaxId, normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { cardBrandMap } from "../../../../../utils/card-brand-map";
import { ReceivableToSearch } from "./file-upload.types";
import { FileSearchReceivableWithOverrideMaturityAmount } from "../../intubate-operations.types";

export const parseIntubateSpreadsheet = (
  buffer: FileReader["result"],
): ReceivableToSearch[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);
    return {
      brand: (
        getValueFromMap(cardBrandMap, cleanRow.bandeira) || cleanRow.bandeira
      )
        .trim()
        .toLowerCase(),
      maturityDate: parseExcelDate(cleanRow["data de vencimento"]),
      overrideMaturityAmount: cleanRow["valor solicitado"],
      overrideDisbursementAmount: cleanRow["valor desembolso"],
      payeeTaxIdentification: cleanTaxId(String(cleanRow["cnpj do cedente"])),
      payerTaxIdentification: cleanTaxId(String(cleanRow["cnpj do sacado"])),
      disbursementDate: cleanRow["data de desembolso"]
        ? parseExcelDate(cleanRow["data de desembolso"])
        : new Date().toISOString().split("T")[0].toString(),
    };
  });
};

const generateKey = (
  brand: string,
  payeeTaxIdentification: string,
  payerTaxIdentification: string,
  maturityDate: string,
) =>
  `${brand.toLowerCase()}-${payeeTaxIdentification}-${payerTaxIdentification}-${maturityDate}`;

const createReceivableDictionary = (
  localData: ReceivableToSearch[],
): Map<string, ReceivableToSearch> => {
  const receivableDictionary = new Map<string, ReceivableToSearch>();

  localData.forEach((receivable) => {
    const key = generateKey(
      receivable.brand,
      receivable.payeeTaxIdentification,
      receivable.payerTaxIdentification,
      receivable.maturityDate,
    );
    receivableDictionary.set(key, receivable);
  });

  return receivableDictionary;
};

const createSearchedReceivableDictionary = (
  searchedReceivables: PostAdminReceivablesSearchResponse,
): Map<string, PostAdminReceivablesSearchResponseItem> => {
  const receivableDictionary = new Map<
    string,
    PostAdminReceivablesSearchResponseItem
  >();

  searchedReceivables.forEach((receivable) => {
    const key = generateKey(
      receivable.bandeira,
      receivable.cedenteIdentification,
      receivable.sacadoIdentification,
      dayjs(receivable.dataVencimento).format("YYYY-MM-DD"),
    );
    receivableDictionary.set(key, receivable);
  });

  return receivableDictionary;
};

/**
 * @deprecated This is horrible, but until the API is refactored, it's what we have
 */
export const applyOverrides = (
  localData: ReceivableToSearch[],
  searchedReceivables: PostAdminReceivablesSearchResponse,
) => {
  const receivableDictionary = createReceivableDictionary(localData);

  return searchedReceivables.map((receivable) => {
    const receivableWithOverride = {
      ...receivable,
      valorSolicitado: receivable.valorVencimento,
      valorDesembolsoSolicitado: receivable.valorDesembolso,
    };

    const key = generateKey(
      receivable.bandeira,
      receivable.cedenteIdentification,
      receivable.sacadoIdentification,
      dayjs(receivable.dataVencimento).format("YYYY-MM-DD"),
    );

    const correspondingLocalReceivable = receivableDictionary.get(key);

    if (correspondingLocalReceivable) {
      receivableWithOverride.valorSolicitado =
        correspondingLocalReceivable.overrideMaturityAmount;
      if (
        correspondingLocalReceivable.overrideMaturityAmount &&
        !correspondingLocalReceivable.overrideDisbursementAmount
      ) {
        receivableWithOverride.valorDesembolsoSolicitado =
          correspondingLocalReceivable.overrideMaturityAmount *
          (1 - receivable.taxaTotal);
      } else {
        receivableWithOverride.valorDesembolsoSolicitado =
          correspondingLocalReceivable.overrideDisbursementAmount;
      }
    }

    return receivableWithOverride;
  });
};

export const computeTaxaEfetiva = (
  receivable: FileSearchReceivableWithOverrideMaturityAmount,
) => ({
  ...receivable,
  taxaEfetiva:
    1 -
    (receivable.ValorDesembolsoSolicitado ?? receivable.ValorDesembolso) /
      receivable.ValorSolicitado,
});

/**
 * @deprecated This is horrible, but until the API is refactored, it's what we have
 */
export const getReceivablesNotFound = (
  localData: ReceivableToSearch[],
  searchedReceivables: PostAdminReceivablesSearchResponse,
) => {
  const searchedReceivablesDictionary =
    createSearchedReceivableDictionary(searchedReceivables);

  return localData
    .filter((localReceivable) => {
      const key = generateKey(
        localReceivable.brand,
        localReceivable.payeeTaxIdentification,
        localReceivable.payerTaxIdentification,
        localReceivable.maturityDate,
      );
      return !searchedReceivablesDictionary.has(key);
    })
    .map(
      ({
        brand,
        maturityDate,
        payeeTaxIdentification,
        payerTaxIdentification,
      }) => ({
        bandeira: brand,
        dataVencimento: maturityDate,
        cedenteCnpj: payeeTaxIdentification,
        sacadoCnpj: payerTaxIdentification,
      }),
    );
};
