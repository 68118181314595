import { useState } from "react";
import { RuleDisplay, RuleEditor } from "./_compose";

interface RuleComponentProps {
  rule?: HubEnergy.DiligenceEventRuleResponse;
  initialMode: "display" | "editing";
  setRule?: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventRuleResponse | undefined>
  >;
}

export const RuleComponent = ({
  rule,
  initialMode,
  setRule,
}: RuleComponentProps) => {
  const [mode, setMode] = useState(!rule ? "editing" : initialMode);

  if (mode === "display" && rule)
    return <RuleDisplay rule={rule} setMode={setMode} />;

  return <RuleEditor ruleToEdit={rule} setRule={setRule} setMode={setMode} />;
};
