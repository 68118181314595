import { OToastManager } from "@maestro/core";
import { OLoader, OTypography } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services/service";
import { ContingencyScript } from "../script-contingencia/contingency-script.component";

interface RelationshipContingenciesProps {
  entity: any;
  relationship: HubOnboardingRelationships.Admin.ContingencyProfile;
}

export const RelationshipContingencies = ({
  entity,
  relationship,
}: RelationshipContingenciesProps) => {
  const [contingencies, setContingencies] =
    useState<HubOnboardingRelationships.Admin.Contingency[]>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await service.onboardingRelationships.verifyScripts([
        relationship,
      ]);
      setContingencies(data.filter((contingency) => contingency.identifier));
    } catch {
      OToastManager.danger("Falha ao obter contingências");
    } finally {
      setLoading(false);
    }
  }, [relationship]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return loading ? (
    <OLoader />
  ) : contingencies && contingencies.length > 0 ? (
    <>
      {contingencies?.map((contingency) => (
        <ContingencyScript
          entity={entity}
          key={contingency.uuid}
          contingency={contingency}
        />
      ))}
    </>
  ) : (
    <OTypography>Nenhuma contingência disponível</OTypography>
  );
};
