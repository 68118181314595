import {
  OPillTab,
  OPillTabBody,
  OPillTabContent,
  OPillTabHeader,
  OPillTabs,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useMemo } from "react";
import { ProcessorConfigMonitoringPageContent } from "./_compose";
import { useProcessorConfigMonitoringPage } from "./processor-config-monitoring-tab.hook";

export const ProcessorConfigMonitoringPage = () => {
  const { workflowConfigViews } = useProcessorConfigMonitoringPage();

  const monitoringContent = useMemo(() => {
    if (!workflowConfigViews.length) return;

    return (
      <OPillTabs>
        <OPillTabHeader className="mb-4">
          {workflowConfigViews.map((w) => (
            <OPillTab key={`${w.workflowConfigView.viewType}_header`}>
              {w.workflowConfigView.viewName}
            </OPillTab>
          ))}
        </OPillTabHeader>
        <OPillTabBody>
          {workflowConfigViews.map((w) => (
            <OPillTabContent key={`${w.workflowConfigView.viewType}_body`}>
              <ProcessorConfigMonitoringPageContent workflowConfigView={w} />
            </OPillTabContent>
          ))}
        </OPillTabBody>
      </OPillTabs>
    );
  }, [workflowConfigViews]);

  return (
    <>
      <PageTitle
        title="Configuração de Processador"
        description="OBS: Não se esqueça de clicar em 'Salvar' caso tenha alterado alguma configuração."
      />

      {monitoringContent}
    </>
  );
};
