import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, franquiasGrid } from "./franquias.grid";

export const FranchisePage = () => {
  const gridRef = useGridRef();
  const { routes } = corporateRouter;

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Todas as franquias" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
      actions={
        <RouterButton
          href={routes.franquias.product.franquias.register.path}
          role={roles.franquias.product.addFranchise.role}
        >
          Adicionar
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={franquiasGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
