import { OTooltip, OTypography } from "@maestro/react";
import { StatusBadge } from "components/status-badge";
import { TooltipContent } from "components/tooltip-icon";
import { ProductPendency } from "../../closing-pendencies.types";

interface PendencyProps {
  pendency: ProductPendency;
}

export const Pendency = ({ pendency }: PendencyProps) => {
  if (pendency.status === "SEM_OCORRENCIAS")
    return <StatusBadge type="success">Nenhuma pendência</StatusBadge>;

  if (pendency.status === "VALIDANDO")
    return <StatusBadge type="info">Validando</StatusBadge>;

  if (pendency.status === "COM_OCORRENCIAS")
    return (
      <OTooltip>
        <TooltipContent>{pendency.action}</TooltipContent>
        <StatusBadge type="warning">{pendency.occurrence}</StatusBadge>
      </OTooltip>
    );

  return (
    <div>
      <OTypography type="dark">
        <strong>Status:</strong> {pendency.status}
      </OTypography>
      <OTypography type="dark">
        <strong>Pendência:</strong> {pendency.occurrence}
      </OTypography>
      <OTypography type="dark">
        <strong>Ação:</strong> {pendency.action}
      </OTypography>
    </div>
  );
};
