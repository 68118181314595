import { OButton } from "@maestro/react";
import { isAxiosError } from "axios";
import { DetailsFields } from "components/details-card";
import { PostContingencyEligibilityErrorResponse } from "services/eligibilityengine/models";
import { StatusIcon } from "../../../../components/status-icon";
import { downloadCsv, makeCsvFromObjectArray } from "../../../utils/csv";
import { mapErrors } from "./intubate-debit-card-offers.utils";

const successFields = {
  result: {
    items: [
      {
        label: "Resultado",
        value: (
          <div className="d-flex gap-2">
            <StatusIcon status="success" /> Sucesso.
          </div>
        ),
      },
    ],
  },
};

const genericErrorFields = {
  result: {
    items: [
      {
        label: "Resultado",
        value: (
          <div className="d-flex gap-2">
            <StatusIcon status="danger" /> Erro ao enviar o arquivo, tente
            novamente.
          </div>
        ),
      },
    ],
  },
};

const validationErrorFields = (onClick: () => void) => ({
  result: {
    items: [
      {
        label: "Resultado",
        value: (
          <div className="d-flex gap-2">
            <StatusIcon status="danger" /> Foram encontrados erros no arquivo.
          </div>
        ),
      },
      {
        label: null,
        value: (
          <OButton
            dataAction="resultado:botao:baixar_relatorio_de_erros"
            dataLabel="baixar_relatorio_de_erros"
            onClick={onClick}
          >
            Baixar relatório de erros
          </OButton>
        ),
      },
    ],
  },
});

export const buildIntubateDebitCardOffersResultFields = (
  error: unknown,
): DetailsFields => {
  if (!error) return successFields;

  if (
    isAxiosError<PostContingencyEligibilityErrorResponse>(error) &&
    error.response?.data.length
  ) {
    const errors = error.response.data;
    return validationErrorFields(() =>
      downloadCsv(
        makeCsvFromObjectArray(mapErrors(errors)),
        "erros_elegibilidade.csv",
      ),
    );
  }

  return genericErrorFields;
};
