import { OCard, OCardBody } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ReportsConfig } from "./_compose";
import { useBatchRequestForReports } from "./request-for-reports.hook";

export const RelatorioOperacoes = () => {
  const { form, loading, submit } = useBatchRequestForReports();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Relatórios das operações"
          description="Gere relatórios das operações com as configurações escolhidas."
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="relatorios_antecipacoes:botao:gerar_arquivo"
          dataLabel="gerar_arquivo"
          onClick={submit}
        >
          Gerar arquivo
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <form>
          <div className="d-flex flex-column gap-4">
            <OCard>
              <OCardBody>
                <ReportsConfig />
              </OCardBody>
            </OCard>
          </div>
        </form>
      </FormProvider>
    </FormTemplate>
  );
};
