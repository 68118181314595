import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCustomer } from "contexts/customer";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { grid, Signatory } from "./eloa-approvers.grid";

export const EloaApprovers = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const dataSource = dataSourceCustomStoreGenerator<Signatory>(() =>
    service.gatekeeper
      .getSignatureCombinations({
        identification,
        actId: 6,
        amount_value: 0,
      })
      .then(
        ({ data }) =>
          data.availableCombinations?.[0]?.groups?.[0]?.signatories ?? [],
      )
      .catch(() => {
        const errorMessage = "Erro ao buscar os aprovadores";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Aprovadores da Procuração Eletrônica" />}
      actions={<RefreshGridButton onClick={() => dataSource.reload()} />}
    >
      <ODataGridGenerator grid={grid} dataSource={dataSource} />
    </GridTemplate>
  );
};
