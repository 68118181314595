import * as yup from "yup";
import { InternacionalUserForm } from "./add-internacional-user.type";

const requiredFieldMessage = "Campo obrigatório";
const requiredField = () => yup.string().required(requiredFieldMessage);

export const addInternacionalUserValidationSchema = yup.object<
  InternacionalUserForm,
  yup.ObjectSchema<InternacionalUserForm>["fields"]
>({
  user: yup.object({
    email: requiredField().email("Campo deve ser um e-mail"),
    phoneNumber: requiredField(),
    identification: requiredField(),
    name: requiredField(),
    countryCode: requiredField(),
  }),
  profiles: yup
    .array()
    .required(requiredFieldMessage)
    .min(1, "É necessário selecionar ao menos um profile."),
});
