import { OToastManager } from "@maestro/react";
import { useCallback, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { documentSubtypeByDocumentMap } from "../../../../add-payer.utils";
import { ExtraDataFormFields } from "../../../extra-data/extra-data.types";

export const useDocumentField = (index: number) => {
  const form = useFormContext<ExtraDataFormFields>();
  const {
    control,
    formState: { errors },
    getValues,
  } = form;

  const possibleDocumentSubtypes = getValueFromMap(
    documentSubtypeByDocumentMap,
    getValues(`documents.${index}.type`),
  );

  const { append, fields, remove } = useFieldArray({
    control,
    name: `documents.${index}.files`,
  });

  const fileError = useMemo(() => {
    const documentsErrors = errors.documents;
    if (!documentsErrors) {
      return undefined;
    }

    return documentsErrors[index]?.message ?? undefined;
  }, [errors.documents, index]);

  const handleAddFile = useCallback(
    async (file: File) => {
      const [name, extension] = file.name.split(".");

      if (!name || !extension) {
        return;
      }

      const formData = new FormData();
      formData.set("image", file);

      try {
        const { data } = await service.scfDevelopers.fileUpload(formData);

        append({
          name,
          pageType: "COMPLETO",
          url: data,
          extension,
        });
      } catch {
        OToastManager.danger(
          "Erro ao adicionar arquivo. Tente novamente mais tarde",
        );
      }
    },
    [append],
  );

  const handleRemoveFile = (fileIndex: number) => {
    const file = getValues(`documents.${index}.files.${fileIndex}`);

    if (!file) {
      OToastManager.danger("Erro ao remover documento");
      return;
    }

    remove(fileIndex);
  };

  return {
    fields,
    fileError,
    handleAddFile,
    handleRemoveFile,
    possibleDocumentSubtypes,
  };
};
