import { PageTitle } from "components/page-title";
import { useState } from "react";
import { DetailsTemplate } from "templates/details-template";
import { SpreadsheetData, UploadStep } from "./_compose";

export const UploadImportSpreadsheetPage = () => {
  const [sheetData, setSheetData] = useState<
    HubEnergy.LegacyImport.ParseSpreadsheetResponse | undefined
  >();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Enviar planilha" />}>
      {sheetData ? (
        <SpreadsheetData sheetData={sheetData} setSheetData={setSheetData} />
      ) : (
        <UploadStep setSheetData={setSheetData} />
      )}
    </DetailsTemplate>
  );
};
