import {
  OBadge,
  OCol,
  OField,
  OIcon,
  OLoader,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { useState } from "react";
import { GetSemaphoreStepItem } from "services/onboarding/models/responses/get-semaphore-step.response";
import { StepOrderUpdateModal } from "../step-update/step-order-update.component";
import { StepUpdateModal } from "../step-update/step-update.component";
import { useSemaphoreTemplateForm } from "./template-config.hook";
import { SemaphoreTemplateFormProps } from "./template-config.types";

export const SemaphoreTemplateForm = ({
  mode,
  uuid,
}: SemaphoreTemplateFormProps) => {
  const {
    localForm,
    loading,
    watchSteps,
    allSteps,
    watchInput,
    removeStep,
    openModalUpdateStepOrder,
    openModalUpdateStep,
  } = useSemaphoreTemplateForm(uuid);

  const [stepToUpdate, setStepToUpdate] = useState<GetSemaphoreStepItem>();

  return (
    <>
      {loading && <OLoader absolute backdrop />}
      <ORow>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="identifier"
            name="identifier"
            dataAction="configuracao_semaforo:input:identifier"
            dataLabel="Idetificador"
            label="Identificador"
            disabled={mode === "update"}
          />
        </OCol>
        <OCol xs={12} md={8} xl={5}>
          <ORFieldInput
            tag="text"
            id="description"
            name="description"
            dataAction="configuracao_semaforo:input:description"
            dataLabel="Descrição"
            label="Descrição"
            disabled={mode === "update"}
          />
        </OCol>
      </ORow>
      <ORow align="start">
        <OCol xs={12} sm={6} lg={4}>
          <OField label="Steps de Validação" htmlFor="input">
            <SelectSearch
              id="input"
              name="input"
              placeholder="Selecionar"
              options={allSteps}
              value={watchInput}
              className="w-100"
              maxOptions={50}
              disabled={mode === "add"}
              innerProps={{
                input: {
                  ...localForm.register("input"),
                },
              }}
            />
          </OField>
        </OCol>
      </ORow>
      <ORow align="start">
        {!!watchSteps?.length && (
          <OCol xs={12} sm={6} lg={4} className="d-flex flex-column gap-4">
            <OTypography tag="h3">Selecionados:</OTypography>

            <div className="d-flex flex-wrap gap-2">
              {watchSteps.map((step) => {
                const findStrategy = allSteps.find(
                  (all) => all.value === step.uuid,
                );

                if (!findStrategy?.label) return;

                return (
                  <OBadge key={step.uuid} type="dark" size="sm" rounded outline>
                    <ORow>
                      <div className="d-flex align-items-center gap-2">
                        {step.order} - {findStrategy.label}
                        <UnstyledButton
                          disabled={mode === "add"}
                          onClick={() => {
                            setStepToUpdate(step);
                            openModalUpdateStep(step);
                          }}
                        >
                          <OIcon category="fal" icon="fa-edit" />
                        </UnstyledButton>
                        <UnstyledButton
                          id={step.order?.toString()}
                          disabled={mode === "add"}
                          onClick={() => {
                            setStepToUpdate(step);
                            openModalUpdateStepOrder(step);
                          }}
                        >
                          <OIcon category="fal" icon="fa-sort" />
                        </UnstyledButton>
                        <UnstyledButton
                          disabled={mode === "add"}
                          onClick={async () => {
                            await removeStep(step.uuid);
                          }}
                        >
                          <OIcon category="fal" icon="fa-times" />
                        </UnstyledButton>
                      </div>
                    </ORow>
                  </OBadge>
                );
              })}
            </div>
          </OCol>
        )}
      </ORow>

      <StepOrderUpdateModal templateUuid={uuid} step={stepToUpdate} />
      <StepUpdateModal templateUuid={uuid} step={stepToUpdate} />
    </>
  );
};
