import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { useCallback, useState } from "react";
import { service } from "services";
import { WorkflowFile } from "services/hubcreditmanager/models/responses";

interface DownloadWorkflowPendencyProps {
  files: WorkflowFile[];
}

export const DownloadWorkflowPendency = ({
  files,
}: DownloadWorkflowPendencyProps) => {
  const [loading, setLoading] = useState(false);

  const downloadPendencies = useCallback(
    async ({ key, name }: WorkflowFile) => {
      try {
        setLoading(true);
        const { data: blob } = await service.hubCreditManager.download({
          key,
        });

        helpers.downloadBlobFile(name, blob);

        OToastManager.success("Download realizado com sucesso!");
      } catch {
        OToastManager.danger("Houve um erro ao baixar os arquivos.");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return files.length ? (
    <IconButton
      dataAction="acoes:botao:download"
      dataLabel="download"
      icon={{
        category: "orq",
        icon: "orq-download",
      }}
      type="dark"
      outline
      onClick={() => files.forEach((file) => downloadPendencies(file))}
      loading={loading}
    >
      {`Download (${files.length})`}
    </IconButton>
  ) : (
    <>---</>
  );
};
