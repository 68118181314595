import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { useUser } from "contexts/user";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { WalletProvisionRequestStatus } from "services/bankinghub/models/types/cards";
import { getValueFromMap } from "utils/get-value-from-map";

interface WalletProvisionRequestActionButtonsProps {
  requestId: string;
  cardId: string;
  reload: () => void;
}

export const WalletProvisionRequestActionButtons = ({
  requestId,
  cardId,
  reload,
}: WalletProvisionRequestActionButtonsProps) => {
  const [action, setAction] = useState<WalletProvisionRequestStatus>(
    WalletProvisionRequestStatus.Created,
  );
  const { callService, hasError, loading } = useServiceCall(
    service.bankinghub.updateCardWalletProvision,
  );

  const modalText = {
    APPROVED: {
      title: "Aprovar requisição de Wallet?",
      subtitle: "Deseja realmente APROVAR a requisição de Wallet?",
    },
    REJECTED: {
      title: "Rejeitar requisição de Wallet?",
      subtitle: "Deseja realmente REJEITAR a requisição de Wallet?",
    },
  };

  const updateWalletProvisionRequestStatusModalId = `update-wallet-provision-request-${requestId}-modal`;

  const { user } = useUser();

  useEffect(() => {
    if (hasError) {
      OToastManager.danger(
        "Não foi possível modificar o status da requisição de wallet",
      );
    }
  }, [hasError]);

  const handleUpdateWalletCardProvision = useCallback(async () => {
    try {
      const status = await callService(cardId, requestId, {
        action,
        operatorDocument: user.cpf,
        operatorName: user.name,
      });
      if (status.success) reload();
    } catch (error) {
      console.error(error);
    } finally {
      modalManager.hide(updateWalletProvisionRequestStatusModalId);
    }
  }, [user, cardId, requestId, updateWalletProvisionRequestStatusModalId]);

  return (
    <>
      <DataGridActionV2
        actions={[
          {
            icon: { category: "orq", icon: "orq-close" },
            label: "Rejeitar",
            onClick: () => {
              setAction(WalletProvisionRequestStatus.Rejected);
              modalManager.show(updateWalletProvisionRequestStatusModalId);
            },
          },
          {
            icon: { category: "orq", icon: "orq-check" },
            label: "Aprovar",
            onClick: () => {
              setAction(WalletProvisionRequestStatus.Approved);
              modalManager.show(updateWalletProvisionRequestStatusModalId);
            },
          },
        ]}
      />
      <OConfirmationModal
        modalId={updateWalletProvisionRequestStatusModalId}
        title={
          getValueFromMap(modalText, action)?.title ||
          "Alterar status da requisição de Wallet?"
        }
        confirmLabel="Sim"
        closeLabel="Não"
        divider={false}
        size="xs"
        loading={loading}
        onConfirm={handleUpdateWalletCardProvision}
      >
        <OTypography
          type="dark"
          style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
        >
          {getValueFromMap(modalText, action)?.subtitle ||
            "Alterar status da requisição de Wallet?"}
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
