import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { service } from "services";
import { PostCompaniesByIdBorderosVerifyErrorResponse } from "services/quickfin/models";
import { logger } from "utils/logger";
import {
  ImportSettingsForm,
  importSettingsFormValidationSchema,
} from "./_compose/import-settings";
import { Sacado } from "./upload-sacados.types";
import { useVerifySacados } from "./verify-sacados.hook";

interface UploadSacadosContext {
  sacados: Sacado[] | undefined;
  clear: () => unknown;
  companyId: number;
  form: UseFormReturn<ImportSettingsForm>;
  setSacados: (sacados: Sacado[]) => void;
  submit: () => unknown;
  submitLoading: boolean;
  validationHasError: boolean;
  validationLoading: boolean;
  validationResults: PostCompaniesByIdBorderosVerifyErrorResponse | undefined;
  verifySacados: () => unknown;
}

const uploadSacadosContext = createContext({} as UploadSacadosContext);

export const UploadSacadosProvider = ({
  children,
}: React.PropsWithChildren) => {
  const {
    customer: { quickfinId: companyId },
  } = useCustomer();

  const [sacados, setSacados] = useState<Sacado[]>();

  const {
    clear: validationClear,
    verifySacados: _verifySacados,
    hasError: validationHasError,
    loading: validationLoading,
    validationResults,
  } = useVerifySacados();

  const { callService: uploadSacados, loading: submitLoading } = useServiceCall(
    service.quickfin.uploadSacados,
  );

  const form = useForm<ImportSettingsForm>({
    resolver: yupResolver(importSettingsFormValidationSchema),
  });

  const { formState, getValues } = form;

  const verifySacados = useCallback(() => {
    // shouldn't happen
    if (!sacados) {
      logger.error("No sacados");
      return;
    }

    const { atualizaSacado, updateActiveReceivables } = getValues();

    return _verifySacados(companyId, {
      atualizaSacado: atualizaSacado === "yes",
      updateActiveReceivables: updateActiveReceivables === "yes",
      sacados: sacados.map((sacado) => ({
        CreateSacadoParams: sacado,
      })),
    });
  }, [_verifySacados, sacados, companyId, getValues]);

  const clear = useCallback(() => {
    setSacados(undefined);
    validationClear();
  }, [validationClear]);

  const submit = useCallback(async () => {
    // shouldn't happen
    if (!sacados) {
      logger.error("No sacados");
      return;
    }

    const { atualizaSacado, updateActiveReceivables } = getValues();

    const { success } = await uploadSacados(companyId, {
      atualizaSacado: atualizaSacado === "yes",
      updateActiveReceivables: updateActiveReceivables === "yes",
      sacados: sacados.map((sacado) => ({
        CreateSacadoParams: sacado,
      })),
    });

    if (success) {
      OToastManager.success("Sacados importados com sucesso");
      clear();
    } else {
      OToastManager.danger("Não foi possível importar os sacados");
    }
  }, [sacados, clear, companyId, getValues, uploadSacados]);

  useEffect(() => {
    if (sacados) verifySacados();
  }, [sacados, verifySacados]);

  const value = useMemo(
    () => ({
      sacados,
      clear,
      companyId,
      form,
      formState, // Unused. It's only here to force useMemo to recalculate and rerender Provider child components
      setSacados,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifySacados,
    }),
    [
      sacados,
      clear,
      companyId,
      form,
      formState,
      submit,
      submitLoading,
      validationHasError,
      validationLoading,
      validationResults,
      verifySacados,
    ],
  );

  return (
    <uploadSacadosContext.Provider value={value}>
      {children}
    </uploadSacadosContext.Provider>
  );
};

export const useUploadSacados = () => useContext(uploadSacadosContext);
