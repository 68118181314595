import { useAuthorization } from "contexts/auth";
import ArrayStore from "devextreme/data/array_store";
import DataSource, {
  Options as DataSourceOptions,
} from "devextreme/data/data_source";
import ODataStore, {
  Options as ODataStoreOptions,
} from "devextreme/data/odata/store";
import { useCallback, useEffect, useState } from "react";

interface ODataResponse {
  value: unknown[];
}

export const useOData = <
  T,
  TItem = T extends ODataResponse ? T["value"][number] : T,
>(
  endpoint: string,
  options?: {
    oDataStoreOptions?: ODataStoreOptions;
    dataSourceOptions?: DataSourceOptions;
  },
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] =
    useState<Parameters<Required<ODataStoreOptions>["errorHandler"]>[number]>();
  const { authorization } = useAuthorization();

  const [dataSource, setDataSource] = useState(
    new DataSource<TItem>({
      store: new ArrayStore({
        data: [],
      }),
    }),
  );

  const getDataSource = useCallback(() => {
    setLoading(true);
    (async () => {
      setDataSource(
        new DataSource<TItem>({
          store: new ODataStore<TItem>({
            url: endpoint,
            version: 4,
            beforeSend: (config) => {
              // eslint-disable-next-line no-param-reassign
              config.headers = {
                Authorization: authorization,
                ...config.headers,
              };
            },
            onLoaded: () => setLoading(false),
            errorHandler: (e) => {
              setLoading(false);
              setError(e);
            },
            ...options?.oDataStoreOptions,
          }),
          ...options?.dataSourceOptions,
        }),
      );
    })();
  }, [
    authorization,
    endpoint,
    options?.dataSourceOptions,
    options?.oDataStoreOptions,
  ]);

  useEffect(() => {
    getDataSource();
  }, [getDataSource]);

  return {
    dataSource,
    loading,
    error,
  };
};
