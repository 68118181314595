import { OCard, OCardBody, OCol, ORow } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailWallet } from "pages/duplicatas/pages/cliente/informacoes/_compose/detail-wallet/detail-wallet.component";
import { ReceivableTotalResponse } from "services/moana/models";

interface MenuWalletProps {
  amount: number;
  availableAmount: number;
  totals: ReceivableTotalResponse | undefined;
}

export const MenuWallet = ({
  amount,
  availableAmount,
  totals,
}: MenuWalletProps) => (
  <OCard className="mb-4">
    <OCardBody className="position-relative">
      <ORow>
        <OCol>
          <DetailWallet
            title="Limite aprovado"
            value={masks.currency(amount, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Limite disponível"
            value={masks.currency(availableAmount, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Carteira total"
            value={masks.currency(totals?.totalReceivableValue ?? 0, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Vencidos total"
            value={masks.currency(totals?.totalPendingPaymentValue ?? 0, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Carteira disponível"
            value={masks.currency(totals?.totalEligibleValue ?? 0, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Carteira descontada"
            value={masks.currency(totals?.totalAnticipatedValue ?? 0, "R$")}
          />
        </OCol>
        <OCol>
          <DetailWallet
            title="Carteira vencida"
            value={masks.currency(totals?.totalLateSettlement, "R$")}
          />
        </OCol>
      </ORow>
    </OCardBody>
  </OCard>
);
