import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ProfileManagementChangeStatus } from "services/gatekeeper/models/types/profile-management.types";
import { FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams } from "../../../../routes/ferramentas.route-params";
import { profileRequestGrid } from "./admin-profile-request.grid";
import { Change } from "./admin-profile-request.types";

export const useAdminProfileRequest = () => {
  const { requestId } =
    useParams<FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams>();
  if (!requestId) throw new Error("No requestId");

  const gridRef = useGridRef();

  const [selectedChanges, setSelectedChanges] = useState<Change[]>([]);

  const { loading: batchUpdateLoading, callService: _batchUpdate } =
    useServiceCall(
      service.gatekeeper.updateAdminProfileManagementRequestChanges,
    );

  const { callService: fetch, value: request } = useServiceCall(
    service.gatekeeper.getAdminProfileManagementRequestDetails,
  );

  const { loading: reprocessLoading, callService: _reprocess } = useServiceCall(
    service.gatekeeper.publishRequestAnalysisAdminProfileManagement,
  );

  const { loading: submitLoading, callService: _submit } = useServiceCall(
    service.gatekeeper.resolveAdminProfileManagementRequest,
  );

  const navigate = useNavigate();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        fetch({
          requestId: +requestId,
        })
          .then(({ response }) => response?.data.changes ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar detalhes da solicitação");
          }),
      ),
    [fetch, requestId],
  );

  const reprocess = useCallback(async () => {
    const { success } = await _reprocess({
      requestId: +requestId,
    });

    if (success) {
      OToastManager.success("Análise reprocessada com sucesso");
      navigate(
        corporateRouter.routes.ferramentas.product.gatekeeper
          .adminProfilesManagement.path,
      );
    } else {
      OToastManager.danger("Erro ao reprocessar análise");
    }
  }, [_reprocess, navigate, requestId]);

  const submit = useCallback(async () => {
    const { success, error } = await _submit({
      requestId: +requestId,
    });

    if (success) {
      OToastManager.success("Análise finalizada com sucesso");
      navigate(
        corporateRouter.routes.ferramentas.product.gatekeeper
          .adminProfilesManagement.path,
      );
    } else {
      let errorMessage = "Erro ao finalizar análise";
      if (isAxiosError<{ message: string }>(error)) {
        errorMessage = error.response?.data?.message ?? errorMessage;
      }
      OToastManager.danger(errorMessage);
    }
  }, [_submit, navigate, requestId]);

  const batchUpdate = useCallback(
    async (status: ProfileManagementChangeStatus) => {
      const { success } = await _batchUpdate({
        changeIds: selectedChanges.map(({ id }) => id),
        status,
      });

      if (success) {
        OToastManager.success("Status das mudanças atualizados");
        dataSource.reload();
        gridRef.current?.instance.deselectAll();
      } else {
        OToastManager.danger("Erro ao atualizar status das mudanças");
      }
    },
    [_batchUpdate, dataSource, gridRef, selectedChanges],
  );

  const grid = useMemo(
    () =>
      profileRequestGrid(
        requestId,
        request?.status === "EM_ANALISE",
        setSelectedChanges,
      ),
    [request?.status, requestId],
  );

  return {
    batchUpdate,
    batchUpdateLoading,
    dataSource,
    grid,
    gridRef,
    reprocess,
    reprocessLoading,
    request,
    selectedChanges,
    submit,
    submitLoading,
  };
};
