export const handleClickSelector = (ref: React.RefObject<HTMLElement>) => {
  if (!ref.current) return;

  const element = ref.current.querySelector(".o-select__header") as HTMLElement;
  element.setAttribute("aria-expanded", "true");
  element.setAttribute("data-active", "true");

  const option = ref.current.querySelector(".o-select__option") as HTMLElement;
  option.setAttribute("data-active", "true");
};

export function replaceAccents(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const searchCharactersToIgnore = /[^0-9A-Z]+/gi;
