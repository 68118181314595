import { CreateAgreementRequest } from "services/admin-bankinghub/collection/models";
import {
  FeesType,
  InterestType,
} from "services/admin-bankinghub/collection/models/types";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";

const assemblePercentOrAmount = (
  type: FeesType | InterestType,
  value?: number,
) => {
  if (value === undefined) return 0;

  const isPercentage = [
    FeesType.PERCENTAGE,
    InterestType.PERCENTAGE_PER_MONTH,
  ].includes(type);

  return isPercentage ? value * 100 : value;
};

export const assembleUpsertAgreementParams = (
  formValues: UpsertCollectionAgreementFormFields,
): CreateAgreementRequest => {
  const {
    id,
    name,
    isDefaultAgreement,
    settings: { daysToCancel, description, discount, fine, interest },
  } = formValues;

  const settings: BankingHubAdminCollection.AgreementSettings = {};

  if (daysToCancel !== undefined) settings.daysToCancel = daysToCancel;
  if (description) settings.description = description;
  if (discount?.type) {
    settings.discount = {
      discountValidityInDays: discount.discountValidityInDays ?? 0,
      percentOrAmount: assemblePercentOrAmount(
        discount.type,
        discount.percentOrAmount,
      ),
      type: discount.type,
    };
  }
  if (fine?.type) {
    settings.fine = {
      daysToStart: fine.daysToStart ?? 0,
      percentOrAmount: assemblePercentOrAmount(fine.type, fine.percentOrAmount),
      type: fine.type,
    };
  }
  if (interest?.type) {
    settings.interest = {
      daysToStart: interest.daysToStart ?? 0,
      percentOrAmount: assemblePercentOrAmount(
        interest.type,
        interest.percentOrAmount,
      ),
      type: interest.type,
    };
  }

  const hasId = !!id && !isDefaultAgreement;

  return {
    ...(hasId && { id }),
    name,
    isDefaultAgreement,
    settings,
  };
};
