import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../../routes/emprestimos.route-params";
import {
  LimitUpdateAmountFormValues,
  limitUpdateAmountFormValidationSchema,
} from "./limit-update-amount.schemas";

export const LimitUpdateAmountPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id");

  const { callService: updateLimitAmount, loading } = useServiceCall(
    service.hubCreditManager.updateLimitRequestAmount,
  );
  const form = useForm<LimitUpdateAmountFormValues>({
    resolver: yupResolver(limitUpdateAmountFormValidationSchema),
  });

  const { handleSubmit } = form;

  const navigate = useNavigate();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateLimitAmount({
          limitRequestId: +id,
          ...values,
        });

        if (success) {
          OToastManager.success("Valor da proposta atualizado com sucesso");
          navigate(
            corporateRouter.routes.emprestimos.product.limitManager.limits.details.summary.path(
              { id },
            ),
          );
        } else {
          OToastManager.danger("Não foi possível atualizar valor da proposta");
        }
      }),
    [handleSubmit, id, navigate, updateLimitAmount],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar valor da proposta" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="limite_atualizar_valor:botao:atualizar_valor"
          dataLabel="atualizar_valor"
          onClick={onSubmit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                id="amount"
                name="amount"
                tag="currency"
                symbol="R$"
                label="Novo valor"
                dataAction="limite_atualizar_valor:currency:novo_valor"
                dataLabel="novo_valor"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
