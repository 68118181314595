import { IFileUpload, OToastManager, OUploadCustomEvent } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../utils";

export const useDocumentUploadWithoutForm = (
  document: HubEnergy.DocumentResponse,
) => {
  const { callService: getChannelFile, loading: downloadLoading } =
    useServiceCall(service.hubEnergy.getChannelFile);
  const { callService: uploadChannelFile, loading: uploadLoading } =
    useServiceCall(service.hubEnergy.uploadChannelFile);
  const { callService: removeChannelFile, loading: removeLoading } =
    useServiceCall(service.hubEnergy.removeChannelFile);

  const download = useCallback(
    async (file: { key?: string; name: string }) => {
      const { key, name } = file;
      if (!key) return OToastManager.danger("Arquivo sem chave");

      const { success, response, error } = await getChannelFile({
        key,
      });

      const fileBase64 = response?.data.response;
      if (!success || !fileBase64) {
        const toastTitle = `Erro ao fazer download do arquivo ${name}`;
        const toastMessage = getValidationMessages(error)?.[0]?.errorMessage;
        return OToastManager.danger(
          toastMessage
            ? {
                title: toastTitle,
                message: toastMessage,
              }
            : toastTitle,
        );
      }

      helpers.downloadBase64(fileBase64, name);

      OToastManager.info(`Download do arquivo ${name} realizado com sucesso.`);
    },
    [getChannelFile],
  );

  const upload = useCallback(
    async (
      evt: OUploadCustomEvent<File>,
      resolve: (file: IFileUpload) => void,
      documentId: number | string,
    ) => {
      const file = evt.detail;

      const { success, response, error } = await uploadChannelFile(
        documentId,
        file,
      );

      if (!success)
        return OToastManager.danger(
          getValidationMessages(error)?.[0]?.errorMessage ??
            "Erro ao fazer upload do arquivo",
        );

      const { data } = response;
      document.files = [...(document.files ?? []), data.response];
      resolve(Object.assign(file, { key: data.response?.key }));
    },
    [document, uploadChannelFile],
  );

  const remove = useCallback(
    async (
      file: IFileUpload & { key?: string },
      resolve: (file: IFileUpload) => void,
    ) => {
      const { key, name } = file;
      if (!key) return OToastManager.danger("Arquivo sem chave");

      const { success, error } = await removeChannelFile({ key });

      if (!success) {
        const toastTitle = `Erro ao excluir o arquivo ${name}`;
        const toastMessage = getValidationMessages(error)?.[0]?.errorMessage;
        return OToastManager.danger(
          toastMessage
            ? {
                title: toastTitle,
                message: toastMessage,
              }
            : toastTitle,
        );
      }

      resolve(file);
      document.files = (document.files ?? []).filter((f) => f.key !== key);

      OToastManager.info(`O arquivo ${name} foi excluído com sucesso.`);
    },
    [document, removeChannelFile],
  );

  return {
    downloadLoading,
    removeLoading,
    uploadLoading,
    download,
    upload,
    remove,
  };
};
