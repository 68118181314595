import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeConviteTemplateDeEmailByIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { OnboardingEmailTemplateForm } from "../_compose";
import { useUpdateEmailTemplate } from "./update.hook";

export const UpdateEmailTemplatePage = () => {
  const { id } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeConviteTemplateDeEmailByIdRouteParams>();
  if (!id) throw new Error("No id");

  const { form, submit, submitLoading } = useUpdateEmailTemplate(id);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Detalhes do template de e-mail" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <OnboardingEmailTemplateForm form={form} />
      </CardTemplate>
    </FormTemplate>
  );
};
