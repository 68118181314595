/* eslint-disable import/newline-after-import */
import { Bar } from "react-chartjs-2";

// register used components
import { BarElement, Chart, Legend, LinearScale } from "chart.js";
Chart.register(BarElement);
Chart.register(LinearScale);
Chart.register(Legend);

export const chartOptions: React.ComponentProps<typeof Bar>["options"] = {
  scales: {
    x: {
      max: 20,
      reverse: true,
      ticks: {
        minRotation: 45,
        maxRotation: 45,
        sampleSize: 10,
      },
    },
  },
  responsive: true,
  resizeDelay: 500,
  plugins: {
    legend: {
      position: "top",
    },
  },
} as const;
