import {
  OConfirmationModal,
  OTypography,
  modalManager,
  OToastManager,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import { GetAdminCompanyFlagsResponseItem } from "services/quickfin/models";

interface RestrictionDeleteModalProps {
  restriction?: GetAdminCompanyFlagsResponseItem; 
  onRemoved: () => void;
}

export const RestrictionDeleteModal: React.FC<RestrictionDeleteModalProps> = ({
  restriction,
  onRemoved,
}) => {
  const { loading: loadingRemove, callService: removeRestriction } =
    useServiceCall(service.quickfin.removeAdminCompanyFlags);

  const handleRemove = async () => {
    if (!restriction?.id) return;
    const { success } = await removeRestriction(restriction.id);

    if (!success)
      return OToastManager.danger(
        "Não foi possível remover a restrição no momento, tente novamente mais tarde!",
      );

    onRemoved();
    OToastManager.success("Restrição removida com sucesso!");
    modalManager.hide("confirm-remove-modal");
  };

  return (
    <OConfirmationModal
      modalId="confirm-remove-modal"
      title={`Deseja excluir a restrição ${restriction?.name} da empresa ${restriction?.companyName}?`}
      closeLabel="Cancelar"
      confirmLabel="Excluir"
      divider={false}
      size="sm"
      loading={loadingRemove}
      onConfirm={handleRemove}
    >
      <OTypography className="d-flex jusitfy-content-start">
        Esta ação não poderá ser desfeita se confirmada.
      </OTypography>
    </OConfirmationModal>
  );
};
