import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { OnboardingEditByIdRouteParams } from "../../../../routes/cadastro.route-params";
import { useOnboardingWorkflowEdit } from "./onboarding-workflow-edit.hook";

export const OnboardingWorkflowEdit = () => {
  const { id } = useParams<OnboardingEditByIdRouteParams>();
  if (!id) throw new Error("No id in route parameters");

  const { submit, form, loading, workflows, onboardingEnums, customer } =
    useOnboardingWorkflowEdit({
      id,
    });

  const {
    callService: getDetails,
    loading: loadingDetails,
    hasError: hasErrorDetails,
  } = workflows;
  const {
    callService: getEnums,
    loading: loadingEnums,
    hasError: hasErrorEnums,
    value: enums,
  } = onboardingEnums;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar Onboarding" description={`Id: ${id}`} />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Editar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loadingDetails}
        hasError={hasErrorDetails}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar onboarding"
            callback={() =>
              getDetails({ requestTaxId: customer.identification })
            }
          />
        }
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <ORow>
                <OCol className="gap-2">
                  <ORFieldSelect
                    disabled={loadingEnums}
                    id="status"
                    name="status"
                    placeholder="Status"
                    label="Status"
                    key={enums?.portalStatusTypes?.length}
                  >
                    {enums?.portalStatusTypes?.map((portalStatus) => (
                      <OOption key={portalStatus} value={portalStatus}>
                        {portalStatus}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                  {loadingEnums && <OLoader className="d-block" size="sm" />}
                  {hasErrorEnums && (
                    <OButton type="default" outline onClick={() => getEnums()}>
                      <OIcon
                        category="fa"
                        icon="fa-repeat"
                        size="sm"
                        type="default"
                      />
                    </OButton>
                  )}
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    id="type"
                    name="type"
                    label="Tipo de onboarding"
                    disabled
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    id="insertedAt"
                    name="insertedAt"
                    label="Data da Criação"
                    disabled
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    id="updatedAt"
                    name="updatedAt"
                    label="Data de Atualização"
                    disabled
                  />
                </OCol>
              </ORow>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
