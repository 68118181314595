import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const HabilitaPagForBankingField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.habilitaPagForBanking}
      name="habilitaPagForBanking"
      description="Configuração que habilita o agendamento do pagamento do recebível ao fornecedor no Banking na data de vencimento. O agendamento acontece após a aprovação do recebível pelo sacado. O recebível fica disponível para antecipação, somente após confirmação do agendamento"
    />
  );
};
