import { Header } from "../header/header.component";
import { TabMenu, useMenuTabs } from "../tab-menu";
import { MainContainer, PageContainer } from "./base-layout.styles";

interface BaseLayoutProps {
  children: React.ReactNode;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const menuTabs = useMenuTabs();
  return (
    <PageContainer id="base-layout">
      <Header />

      <TabMenu tabs={menuTabs} />

      <MainContainer id="main-container">{children}</MainContainer>
    </PageContainer>
  );
};
