import { useCallback, useState } from "react";
import { service } from "services";
import { Sacado } from "../company-sacados.types";

export const useGetCompanySacado = (
  companyId: string | number,
  sacadoId: number,
  strategyId: number,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<"NONE" | "FETCH" | "FIND">("NONE");
  const [sacado, setSacado] = useState<Sacado>();

  const getSacado = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.quickfin.getCompanySacados(companyId);

      const foundSacado = data.find(
        (_sacado) =>
          _sacado.id === sacadoId && _sacado.estrategia.id === strategyId,
      );

      if (foundSacado) {
        setSacado(foundSacado);
        setError("NONE");
      } else {
        setError("FIND");
      }
    } catch (err) {
      setError("FETCH");
    } finally {
      setLoading(false);
    }
  }, [companyId, sacadoId, strategyId]);

  return {
    loading,
    error,
    sacado,
    getSacado,
  };
};
