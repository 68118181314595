import { OTypography } from "@maestro/react";
import dayjs from "dayjs";
import { sortById } from "../../../../../../../../utils";
import { NotesBadge } from "../../../badges";

interface NotesAreaProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

export const NotesArea = ({ pendency }: NotesAreaProps) => {
  return (
    <div className="mt-3 d-flex flex-column gap-2 align-items-start">
      <NotesBadge />
      {sortById(
        pendency.notes.filter((n) => !n.content.startsWith("<html>")),
      ).map((n) => (
        <p key={n.id}>
          <OTypography tag="span" weight="700">
            [{dayjs(n.createdDate).format("DD/MM/YYYY HH:mm:ss")}] {n.author}
          </OTypography>
          <OTypography tag="span">: {n.content}</OTypography>
        </p>
      ))}
    </div>
  );
};
