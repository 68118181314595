import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import {
  newAutoEmailDefaulValues,
  newAutoEmailValidationSchema,
} from "./new-auto-email.form";
import { NewAutoEmailForm } from "./new-auto-email.types";
import { fastCpfCnpjMask } from "./new-auto-email.utils";

export const useNewAutoEmail = () => {
  const {
    callService: getNonAcquirerCompanies,
    hasError: companiesHasError,
    loading: companiesLoading,
    value: companies,
  } = useServiceCall(service.recebiveisCartao.getNonAcquirerCompanies);

  const {
    callService: addEmailConfig,
    hasError,
    loading,
    value,
  } = useServiceCall(service.recebiveisCartao.addEmailConfig);

  const form = useForm<NewAutoEmailForm>({
    defaultValues: newAutoEmailDefaulValues,
    resolver: yupResolver(newAutoEmailValidationSchema),
  });

  const { handleSubmit, setValue, trigger } = form;

  const submit = useMemo(
    () =>
      handleSubmit((values) =>
        addEmailConfig({
          email_config: {
            company_id: String(values.companyId),
            email_type: values.emailType,
            send_to_ol: values.sendToOL === "yes",
            send_to_operadores: values.sendToOperators === "yes",
            send_to_representante_legal:
              values.sendToLegalRepresentative === "yes",
          },
        }),
      ),
    [addEmailConfig, handleSubmit],
  );

  /** Initial request */
  useEffect(() => {
    getNonAcquirerCompanies();
  }, [getNonAcquirerCompanies]);

  /** Error warning */
  useEffect(() => {
    if (companiesHasError) OToastManager.danger("Erro ao buscar as empresas");
  }, [companiesHasError]);

  /** Error warning */
  useEffect(() => {
    if (hasError)
      OToastManager.danger("Erro ao criar a configuração de e-mail");
  }, [hasError]);

  /** Success */
  useEffect(() => {
    if (value)
      OToastManager.success("Configuração de e-mail criada com sucesso");
  }, [value]);

  const options = useMemo<SelectSearchOption<unknown>[]>(
    () =>
      companies?.companies.map((company) => ({
        label: `${company.name} - ${fastCpfCnpjMask(company.tax_id)}`,
        value: company.id,
        onSelect: () => {
          setValue("companyId", company.id);
          trigger("companyId");
        },
      })) ?? [],
    [companies?.companies, setValue, trigger],
  );

  return {
    companiesLoading,
    form,
    loading,
    options,
    submit,
  };
};
