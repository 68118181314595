import * as PaymentsEnums from "services/api/models/types/payments/enums";
import { ObjectValueValidateFunction } from "../types";
import { ObjectUtils } from "../utils";

const ReportValidator: Record<
  keyof ApiPayments.Report,
  ObjectValueValidateFunction<ApiPayments.Report>
> = {
  id: (value) => typeof value === "string",
  status: (value) => Object.values(PaymentsEnums.ReportStatus).includes(value),
  description: (value) => typeof value === "string",
  operatorTaxId: (value) => typeof value === "string",
  reasonType: (value) => typeof value === "string",
  reportType: (value) => typeof value === "string",
  systemOriginName: (value) => typeof value === "string",
  ticketId: (value) => typeof value === "string",
};

const AccountValidator: Record<
  keyof ApiPayments.Account,
  ObjectValueValidateFunction<ApiPayments.Account>
> = {
  type: (value) => Object.values(PaymentsEnums.AccountType).includes(value),
  number: (value) => typeof value === "string",
  branch: (value) => typeof value === "string",
  bankCode: (value) => typeof value === "string",
  ispb: (value) => !value || typeof value === "string",
};

const PartyValidator: Record<
  keyof ApiPayments.DebitParty | keyof ApiPayments.CreditParty,
  ObjectValueValidateFunction<ApiPayments.DebitParty | ApiPayments.CreditParty>
> = {
  taxId: (value) => !value || typeof value === "string",
  name: (value) => typeof value === "string",
  account: (value) =>
    ObjectUtils.validateInterface<ApiPayments.Account>(value, AccountValidator),
};

const PayeeValidator: Record<
  keyof ApiPayments.Payee,
  ObjectValueValidateFunction<ApiPayments.Payee>
> = {
  socialName: (value) => typeof value === "string",
  fantasyName: (value) => typeof value === "string",
  taxId: (value) => typeof value === "string",
  bankCode: (value) => typeof value === "string",
  bankName: (value) => !value || typeof value === "string",
};

const BankslipDetailsValidator: Record<
  keyof ApiPayments.BankslipDetails,
  ObjectValueValidateFunction<ApiPayments.BankslipDetails>
> = {
  bankSlipType: (value) =>
    Object.values(PaymentsEnums.BankslipType).includes(value),
  barcode: (value) => typeof value === "string",
  digitableLine: (value) => typeof value === "string",
  discount: (value) => typeof value === "number",
  dueDate: (value) => typeof value === "string",
  expirationDate: (value) => typeof value === "string",
  fine: (value) => typeof value === "number",
  interest: (value) => typeof value === "number",
  payee: (value) => ObjectUtils.validateInterface(value, PayeeValidator),
};

const KeyValidator: Record<
  keyof ApiPayments.Key,
  ObjectValueValidateFunction<ApiPayments.Key>
> = {
  type: (value) => Object.values(PaymentsEnums.PixKeyType).includes(value),
  value: (value) => typeof value === "string",
};

const PixKeyDetailsValidator: Record<
  keyof ApiPayments.PixKeyDetails,
  ObjectValueValidateFunction<ApiPayments.PixKeyDetails>
> = {
  creditParty: (value) =>
    !value ||
    ObjectUtils.validateInterface<ApiPayments.CreditParty>(
      value,
      PartyValidator,
    ),
  endToEndId: (value) => typeof value === "string",
  key: (value) =>
    ObjectUtils.validateInterface<ApiPayments.Key>(value, KeyValidator),
};

const QrCodeValidator: Record<
  keyof ApiPayments.QrCode,
  ObjectValueValidateFunction<ApiPayments.QrCode>
> = {
  type: (value) => Object.values(PaymentsEnums.PixQrCodeType).includes(value),
};

const PixQrCodeDetailsValidator: Record<
  keyof ApiPayments.PixQrCodeDetails,
  ObjectValueValidateFunction<ApiPayments.PixQrCodeDetails>
> = {
  creditParty: (value) =>
    ObjectUtils.validateInterface<ApiPayments.CreditParty>(
      value,
      PartyValidator,
    ),
  endToEndId: (value) => typeof value === "string",
  key: (value) =>
    ObjectUtils.validateInterface<ApiPayments.Key>(value, KeyValidator),
  qrCode: (value) =>
    ObjectUtils.validateInterface<ApiPayments.QrCode>(value, QrCodeValidator),
  emv: (value) => !value || typeof value === "string",
  expirationDateTime: (value) => !value || typeof value === "string",
  transactionId: (value) => !value || typeof value === "string",
  location: (value) => !value || typeof value === "string",
};

const PixReversalDetailsValidator: Record<
  keyof ApiPayments.PixReversalDetails,
  ObjectValueValidateFunction<ApiPayments.PixReversalDetails>
> = {
  originalEndToEndId: (value) => !value || typeof value === "string",
  creditParty: (value) =>
    !value ||
    ObjectUtils.validateInterface<ApiPayments.CreditParty>(
      value,
      PartyValidator,
    ),
  endToEndId: (value) => !value || typeof value === "string",
};

const BankingDetailsValidator: Record<
  | keyof ApiPayments.PixManualDetails
  | keyof ApiPayments.TedDetails
  | keyof ApiPayments.TefDetails,
  ObjectValueValidateFunction<
    | ApiPayments.PixManualDetails
    | ApiPayments.TedDetails
    | ApiPayments.TefDetails
  >
> = {
  creditParty: (value) =>
    ObjectUtils.validateInterface<ApiPayments.CreditParty>(
      value,
      PartyValidator,
    ),
};

const TaxPayerValidator: Record<
  keyof ApiPayments.TaxPayer,
  ObjectValueValidateFunction<ApiPayments.TaxPayer>
> = {
  id: (value) => typeof value === "string",
  name: (value) => typeof value === "string",
};

const DarfDetailsValidator: Record<
  keyof ApiPayments.DarfDetails,
  ObjectValueValidateFunction<ApiPayments.DarfDetails>
> = {
  taxPayer: (value) => ObjectUtils.validateInterface(value, TaxPayerValidator),
  baselinePeriodDate: (value) => !value || typeof value === "string",
  treasuryRevenueCode: (value) => typeof value === "string",
  referenceNumber: (value) => !value || typeof value === "string",
  expireDate: (value) => typeof value === "string",
  principalAmount: (value) => typeof value === "number",
  fineAmount: (value) => typeof value === "number",
  feeAmount: (value) => typeof value === "number",
};

const UtilitiesDetailsValidator: Record<
  keyof ApiPayments.UtilitiesDetails,
  ObjectValueValidateFunction<ApiPayments.UtilitiesDetails>
> = {
  assignor: (value) => typeof value === "string",
  barcode: (value) => typeof value === "string",
  closeTime: (value) => typeof value === "string",
  digitableLine: (value) => typeof value === "string",
  openTime: (value) => typeof value === "string",
  dueDate: (value) => !value || typeof value === "string",
};

const CreditCardInvoiceDetailsValidator: Record<
  keyof ApiPayments.CreditCardInvoiceDetails,
  ObjectValueValidateFunction<ApiPayments.CreditCardInvoiceDetails>
> = {
  creditAccountNumber: (value) => typeof value === "string",
  invoiceId: (value) => typeof value === "string",
};

const PaymentErrorValidator: Record<
  keyof ApiPayments.PaymentError,
  ObjectValueValidateFunction<ApiPayments.PaymentError>
> = {
  code: (value) => typeof value === "string",
  detail: (value) => typeof value === "string",
  arguments: (value) => !value || typeof value === "object",
};

export const PaymentDataValidator: Record<
  keyof ApiPayments.PaymentData,
  ObjectValueValidateFunction<ApiPayments.PaymentData>
> = {
  id: (value) => typeof value === "string",
  batchId: (value) => typeof value === "string",
  status: (value) => Object.values(PaymentsEnums.PaymentStatus).includes(value),
  systemOrigin: (value) => typeof value === "string",
  fraudId: (value) => !value || typeof value === "string",
  settlementDate: (value) => !value || typeof value === "string",
  reports: (value) =>
    value
      ? Array.isArray(value)
        ? value.every((item) =>
            ObjectUtils.validateInterface<ApiPayments.Report>(
              item,
              ReportValidator,
            ),
          )
        : false
      : true,
  type: (value) => Object.values(PaymentsEnums.PaymentType).includes(value),
  debitParty: (value) =>
    ObjectUtils.validateInterface<ApiPayments.DebitParty>(
      value,
      PartyValidator,
    ),
  amount: (value) => typeof value === "number",
  description: (value) => !value || typeof value === "string",
  paymentDate: (value) => typeof value === "string",
  detail: (value, data) => {
    if (data.type === PaymentsEnums.PaymentType.Bankslip) {
      return ObjectUtils.validateInterface<ApiPayments.BankslipDetails>(
        value,
        BankslipDetailsValidator,
      );
    }
    if (data.type === PaymentsEnums.PaymentType.PixKey) {
      return ObjectUtils.validateInterface<ApiPayments.PixKeyDetails>(
        value,
        PixKeyDetailsValidator,
      );
    }
    if (data.type === PaymentsEnums.PaymentType.PixQrCode) {
      return ObjectUtils.validateInterface<ApiPayments.PixQrCodeDetails>(
        value,
        PixQrCodeDetailsValidator,
      );
    }
    if (data.type === PaymentsEnums.PaymentType.PixReversal) {
      return ObjectUtils.validateInterface<ApiPayments.PixReversalDetails>(
        value,
        PixReversalDetailsValidator,
      );
    }
    if (
      data.type === PaymentsEnums.PaymentType.PixManual ||
      data.type === PaymentsEnums.PaymentType.Ted ||
      data.type === PaymentsEnums.PaymentType.Tef
    ) {
      return ObjectUtils.validateInterface<
        | ApiPayments.PixManualDetails
        | ApiPayments.TedDetails
        | ApiPayments.TefDetails
      >(value, BankingDetailsValidator);
    }
    if (data.type === PaymentsEnums.PaymentType.Darf) {
      return ObjectUtils.validateInterface<ApiPayments.DarfDetails>(
        value,
        DarfDetailsValidator,
      );
    }
    if (data.type === PaymentsEnums.PaymentType.Utilities) {
      return ObjectUtils.validateInterface<ApiPayments.UtilitiesDetails>(
        value,
        UtilitiesDetailsValidator,
      );
    }
    if (data.type === PaymentsEnums.PaymentType.CreditCardInvoice) {
      return ObjectUtils.validateInterface<ApiPayments.CreditCardInvoiceDetails>(
        value,
        CreditCardInvoiceDetailsValidator,
      );
    }

    return false;
  },
  errors: (value) =>
    !(value === undefined)
      ? Array.isArray(value)
        ? value.every((item) =>
            ObjectUtils.validateInterface<ApiPayments.PaymentError>(
              item,
              PaymentErrorValidator,
            ),
          )
        : false
      : true,
};
