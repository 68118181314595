import { OFilterItem, ORadioGroup, OTypography } from "@maestro/react";
import { periods } from "../filters";
import { MonthPickerFilterItem } from "../items/month-picker-item.component";
import { RadioFilterItem } from "../items/radio-filter-item.component";
import { RangePickerFilterItem } from "../items/range-picker-filter-item.component";

interface PeriodProps {
  filter: string;
  setFilter: CallableFunction;
}

export const PeriodFilter = ({ filter, setFilter }: PeriodProps) => {
  return (
    <OFilterItem>
      <OTypography slot="title">Período</OTypography>
      <ORadioGroup
        data-filterparam="periodFilter"
        dataAction="statement-period-filter"
        dataLabel="statement-period-filter"
        onInput={(event: any) => setFilter(event.target.value)}
        defaultValue="last-week"
      >
        {periods.map((item) => (
          <div className="d-flex flex-column" key={item.value}>
            <RadioFilterItem {...item} />
            {filter === "monthly" && item.value === "monthly" && (
              <MonthPickerFilterItem />
            )}
            {filter === "custom" && item.value === "custom" && (
              <RangePickerFilterItem />
            )}
          </div>
        ))}
      </ORadioGroup>
    </OFilterItem>
  );
};
