import { OToastManager } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../../routes/ferramentas.route-params";
import { buildDeskAccountConfigRelationshipGrid } from "./desk-account-config-relationships.grid";

export const DeskAccountConfigRelationships = () => {
  const { uuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid || !configIdentifier)
    throw new Error("No uuid or configIdentifier");
  const gridRef = useGridRef();

  const {
    callService: getDeskOnboardingConfigAccountConfigRelationships,
    value: currentAccountConfigRelationships,
  } = useServiceCall(
    service.onboarding.getDeskOnboardingAccountConfigRelationships,
  );

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          getDeskOnboardingConfigAccountConfigRelationships(uuid).then(
            ({ response, success }) => {
              if (!success) throw new Error("Erro ao buscar relacionamento");

              return (
                response.data.deskOnboardingRelationshipAccountConfigs ?? []
              );
            },
          ),
        {
          customStoreOptions: {
            remove: async (data) => {
              try {
                if (!data.uuid) throw new Error("No uuid");

                await service.onboarding.removeDeskOnboardingAccountConfigRelationship(
                  uuid,
                  data.uuid,
                );

                OToastManager.success("Relacionamento removido com sucesso.");
              } catch (error) {
                OToastManager.danger("Erro ao remover relacionamento");
              }
            },
          },
        },
      ),
    [getDeskOnboardingConfigAccountConfigRelationships, uuid],
  );

  const grid = useMemo(
    () => buildDeskAccountConfigRelationshipGrid(configIdentifier, uuid),
    [configIdentifier, uuid],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Relacionamentos"
          description={`Agência: ${currentAccountConfigRelationships?.deskOnboardingAccountInfoAgency} | Conta: ${currentAccountConfigRelationships?.deskOnboardingAccountInfoAccountType} | Configuração: ${configIdentifier}`}
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
      actions={
        <RouterButton
          href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.desksConfigs.details.deskAccountConfig.details.relationships.register.path(
            {
              configIdentifier,
              uuid,
            },
          )}
          role={
            roles.ferramentas.product
              .onboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage
              .role
          }
        >
          Adicionar
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={grid}
      />
    </GridTemplate>
  );
};
