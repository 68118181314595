import {
  OInputDate,
  OInputDateRange,
  ORadioGroup,
  OTypography,
} from "@maestro/react";
import { ExportRadio } from "./items/export-radio.component";
import { periods } from "../../filter/filters";
import { PeriodProps } from "../export-modal.types";

export const PeriodOptions = ({ filter, setFilter }: PeriodProps) => {
  return (
    <>
      <OTypography className="mt-2 mb-2">
        Selecione o período de exportação
      </OTypography>
      <ORadioGroup
        className="d-flex flex-row gap-2 w-25"
        data-filterparam="periodFilter"
        dataAction="statement-export-filter"
        dataLabel="statement-export-filter"
        onInput={(event: any) => setFilter(event.target.value)}
      >
        {periods.map((item) => (
          <ExportRadio radioName="period" {...item} key={item.value} />
        ))}
      </ORadioGroup>
      <>
        {filter === "monthly" && (
          <OInputDate
            className="mt-2 w-40"
            data-filterparam="referenceDate"
            allowClear
            aspect="flushed"
            picker="month"
            placeholder="Selecione o mês"
            size="md"
          />
        )}
        {filter === "custom" && (
          <OInputDateRange
            className="mt-2 w-80"
            data-filterparam="referenceDate"
            allowClear
            aspect="flushed"
            size="md"
          />
        )}
      </>
    </>
  );
};
