import { safeSessionStorage } from "utils/storage";
import { behaviorEvents } from "./behavior.event";
import { Behaviors } from "./types/behavior-ids";
import { BehaviorConfiguration } from "./types/config";
import { BehaviorState } from "./types/state";

export class Behavior {
  private _state: BehaviorState = "INITIAL";

  private static storageKey = "corporate-portal:behavior-settings";

  private static store = safeSessionStorage;
  // TODO put this after embedded domain is working
  // env.NODE_ENV === "development" ? sessionStorage : localStorage;

  static storage = {
    get: () => this.store.getItem(this.storageKey),
    set: (id: Behaviors) => this.store.setItem(this.storageKey, id),
    remove: () => this.store.removeItem(this.storageKey),
  };

  value?: BehaviorConfiguration;

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
    behaviorEvents.dispatch(this);

    if (this.value?.id) {
      Behavior.storage.set(this.value.id);
    } else {
      Behavior.storage.remove();
    }
  }
}

export const behavior = new Behavior();
