import { RowPreparedEvent } from "devextreme/ui/data_grid";
import cloneDeep from "lodash/cloneDeep";
import { PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse } from "services/quickfin/models";
import { TaxaFromSpreadsheet } from "../../batch-taxas.types";
import { TaxaWithErrors } from "./validation-results.types";

export const addErrorsToTaxas = (
  taxas: TaxaFromSpreadsheet[] = [],
  errorMessages: PostCompaniesByCompanyIdEstrategiasByEstrategiaIdTaxasVerifyErrorResponse = [],
) => {
  const taxasWithErrors = cloneDeep(taxas).map((taxa) => ({
    ...taxa,
    errorMessages: [],
  })) as TaxaWithErrors[];

  errorMessages.forEach((error) => {
    const indexStr = error.key.match(/\d+/)?.[0];
    if (!indexStr) return;
    const index = Number(indexStr);
    taxasWithErrors[index].errorMessages?.push(error.message);
  });

  return taxasWithErrors;
};

export const highlightErrors = ({
  data,
  rowElement,
  rowType,
}: RowPreparedEvent<TaxaWithErrors>) => {
  if (rowType === "data" && data?.errorMessages?.length) {
    rowElement.style.setProperty(
      "background-color",
      "var(--theme-danger-light)",
    );
    rowElement.style.setProperty("color", "var(--theme-danger-light-inverse)");
  }
};
