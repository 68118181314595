import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { BankAccountField } from "../campo-conta-bancaria";
import { StrategyParametersFields } from "../campo-parametros-estrategia";
import { PredicatesFields } from "../campo-predicados";
import { SettlementStrategisFormProps } from "./settlement-strategies-form.types";

export const SettlementStrategiesForm = ({
  form,
  initialValues,
  isConsolidated,
  setIsConsolidated,
}: SettlementStrategisFormProps) => {
  return (
    <CardTemplate cardBodyClassName="d-flex flex-column gap-4">
      <FormProvider {...form}>
        <PredicatesFields initialValues={initialValues} />
        <StrategyParametersFields
          initialValues={initialValues}
          isConsolidated={isConsolidated}
          setIsConsolidated={setIsConsolidated}
        />
        <BankAccountField initialValues={initialValues} />
      </FormProvider>
    </CardTemplate>
  );
};
