import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetSemaphoreTemplateResponseItem } from "services/onboarding/models/responses/get-semaphore-configuration.response";

const redirectDetailLink = (uuid: string) => {
  return corporateRouter.routes.ferramentas.product.onboarding.semaphore.configuration.template.details.path(
    { uuid },
  );
};

export const grid: ODataGridGeneratorConfig<GetSemaphoreTemplateResponseItem> =
  {
    datagrid: {
      noDataText: "Nenhuma configuração de semáforo foi encontrada",
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        caption: "Detalhes",
        role: roles.ferramentas.product.semaphoreConfigTemplateDetails.role,
        cellRender: ({ data: { uuid } }) => (
          <DetailsLink href={redirectDetailLink(uuid)} />
        ),
      },
      { dataField: "identifier", caption: "Identificador" },
      { dataField: "description", caption: "Descrição" },
    ],
  };
