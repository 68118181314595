import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useUploadStep = (
  setSheetData: (
    sheetData: HubEnergy.LegacyImport.ParseSpreadsheetResponse,
  ) => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.parseConsumerUnitsImportDataSpreadsheet,
  );

  const form = useForm();

  const uploadSpreadsheet = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      const { success, error, response } = await callService(formData);

      if (success) {
        setSheetData(response.data.response);
      } else {
        OToastManager.danger(
          getValidationMessages(error)?.[0]?.errorMessage ??
            "Erro ao enviar a planilha",
        );
      }
    },
    [callService, setSheetData],
  );

  return {
    form,
    loading,
    uploadSpreadsheet,
  };
};
