import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  BatchRequestEditType,
  FormValuesType,
} from "./contingencies-edit.type";

export const useContingencyEdit = ({ id }: BatchRequestEditType) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>();
  const { handleSubmit, watch } = form;

  const {
    callService: getWorkflowLeadsBatchRequestsDetails,
    value: batchRequestDetails,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowLeadsBatchRequestsDetails,
  );

  useEffect(() => {
    getWorkflowLeadsBatchRequestsDetails(id);
  }, [getWorkflowLeadsBatchRequestsDetails, id]);

  const watchStatus = watch("status");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        id,
        status: values.status,
      };

      await service.hubCreditManager.updateWorkflowLeadsBatchRequestStatus(
        payload,
      );

      OToastManager.success("Status editado com sucesso");

      navigate(
        corporateRouter.routes.workflow.product.batchs.batchRequests.summary.path(
          {
            id,
          },
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (batchRequestDetails) {
      form.setValue("status", batchRequestDetails.status);
    }
  }, [batchRequestDetails, form, id]);

  return {
    form,
    submit,
    loading,
    watchStatus,
  };
};
