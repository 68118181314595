export interface OfferedCard {
  variant: BankingHubCards.CardVariant;
  modality: BankingHubCards.CardModality;
  programId: number;
  proposalStatus: BankingHubCards.ProposalStatus;
  issueDates?: BankingHubCards.IssueDate[];
  offerId?: string;
  grantedLimit?: number;
}

export interface CardOwner {
  name: string;
  email: string;
  cpf: string;
}

export interface CardHiringContext {
  accountProposals: BankingHubCards.Proposal[] | undefined;
  creditCardOwners: CardOwner[] | undefined;
  debitCardOwners: CardOwner[] | undefined;
  canHire: boolean;
  setCanHire: (value: React.SetStateAction<boolean>) => void;
  fetch: () => void;
  fetchHasError: boolean;
  fetchLoading: boolean;
}

export const CardHiringContextDefaultData: CardHiringContext = {
  accountProposals: undefined,
  creditCardOwners: undefined,
  debitCardOwners: undefined,
  canHire: false,
  setCanHire: () => {},
  fetch: () => {},
  fetchHasError: false,
  fetchLoading: false,
};
