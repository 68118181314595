import dayjs from "dayjs";
import { RegisterFeesForm } from "./register-fees.types";

export const buildPayload = (
  cge: string,
  profitShare: boolean,
  values: RegisterFeesForm,
) => ({
  partners: [
    {
      key: "sacado",
      value: cge,
    },
  ],
  favored: [
    {
      key: "sacado",
      value: cge,
    },
    {
      key: "bank",
      value: values.bankCode,
    },
    {
      key: "agency",
      value: values.agency,
    },
    {
      key: "account",
      value: values.account,
    },
  ],
  feePercentage: values.tax,
  paymentType: {
    disbursement: {
      split: values.paymentType === "Disbursement" ? 1 : 0,
    },
    settlement: {
      split: values.paymentType === "Settlement" ? 1 : 0,
    },
  },
  paymentDay: parseInt(values.paymentDay),
  profitShare,
  managerial: false,
  validFrom: dayjs(values.effectiveDate).format("YYYY-MM-DD"),
  validTo: values.endEffectiveDate
    ? dayjs(values.endEffectiveDate).format("YYYY-MM-DD")
    : "",
  product: {
    ProductCode: 14,
    Book: 1599,
    Strategy: null,
  },
  createdBy: "pme",
  feeModality: "Sacado",
  parentId: 1,
  calculationMethod: { code: values.rebateType },
  properties: {},
  sourceAppId: "pme",
});
