import {
  OCol,
  OModal,
  OModalBody,
  OModalHeader,
  ORow,
  OTypography,
  modalManager,
  OButton,
  ORFieldInputDate,
} from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useFormContext } from "react-hook-form";
import {
  CreditConciliationApprovalsFormValues,
  creditConciliationValidationStatusMap,
} from "../../credit-conciliation.utils";
import { SelectSearchFieldV2 } from "../../../../../../../energia/components/select-search-v2";
import { CreditConciliationSearchProps } from "./search.types";

const creditConciliationSearchModalId = "desverbation-search-modal";

export const CreditConciliationSearch = ({
  onSubmit,
  funds,
}: CreditConciliationSearchProps) => {
  const { reset } = useFormContext<CreditConciliationApprovalsFormValues>();
  return (
    <>
      <IconButton
        dataAction="desverbation_search:botao:pesquisar"
        dataLabel="pesquisar"
        icon={{ category: "fa", icon: "fa-filter" }}
        type="default"
        onClick={() => modalManager.show(creditConciliationSearchModalId)}
      >
        Filtro de busca
      </IconButton>

      <OModal id={creditConciliationSearchModalId} size="sm">
        <OModalHeader>
          <OTypography size="lg">Parâmetros do Filtro</OTypography>
        </OModalHeader>
        <OModalBody>
          <div className="d-flex flex-column gap-4">
            <ORow>
              <OCol xs={12}>
                <SelectSearchFieldV2
                  options={funds.map(({ name: typeName, taxId }) => ({
                    content: typeName,
                    value: taxId,
                  }))}
                  id="findFundTaxId"
                  name="findFundTaxId"
                  label="Fundo"
                  multiple={false}
                  placeholder="Selecione aqui"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol xs={12}>
                <SelectSearchFieldV2
                  options={Object.entries(
                    creditConciliationValidationStatusMap,
                  ).map(([type, text]) => ({
                    content: text.label,
                    value: type,
                  }))}
                  id="findStatus"
                  name="findStatus"
                  label="Status"
                  multiple
                  placeholder="Selecionar"
                />
              </OCol>
            </ORow>
            <ORow>
              <OCol xs={6}>
                <ORFieldInputDate
                  id="findReceivedDate"
                  name="findReceivedDate"
                  label="Data de envio"
                />
              </OCol>
            </ORow>
            <div className="d-flex justify-content-end gap-2">
              <OButton
                dataAction="credit_conciliation:botao:filtro"
                dataLabel="filtro"
                onClick={() => {
                  onSubmit();
                  modalManager.hide(creditConciliationSearchModalId);
                }}
              >
                Filtrar
              </OButton>
              <OButton
                dataAction="credit_conciliation:botao:limpar"
                dataLabel="limpar"
                type="dark"
                outline
                onClick={() => {
                  reset();
                  modalManager.hide(creditConciliationSearchModalId);
                  onSubmit();
                }}
              >
                Limpar Filtros
              </OButton>
              <OButton
                dataAction="credit_conciliation:botao:Cancelar"
                dataLabel="Cancelar"
                type="default"
                onClick={() => {
                  modalManager.hide(creditConciliationSearchModalId);
                }}
              >
                Cancelar
              </OButton>
            </div>
          </div>
        </OModalBody>
      </OModal>
    </>
  );
};
