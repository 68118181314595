import { OToastManager, OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useState } from "react";
import { service } from "services/service";

interface DeactivateUserAccessButtonProps {
  email: string;
}

export const DeactivateAccessButton = ({
  email,
}: DeactivateUserAccessButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeactivation = async () => {
    try {
      setLoading(true);
      await service.gatekeeper.deactivateUser(email);
      OToastManager.success("Acesso desativado com sucesso.");
    } catch {
      OToastManager.danger("Falha ao desativar acesso.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonWithConfirmation
      confirmationModal={{ title: "Desativar acesso", loading }}
      modalBody={
        <OTypography>
          Tem certeza que deseja desativar o acesso desse usuário?
        </OTypography>
      }
      dataAction="usuario_produto:botao:desativar_acesso"
      dataLabel="desativar_acesso"
      type="danger"
      onConfirm={() => handleDeactivation()}
      outline
    >
      Desativar acesso
    </ButtonWithConfirmation>
  );
};
