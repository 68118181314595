import { GetUsersCompleteSelfResponse } from "services/clerk";
import { UserState } from "./types/state";
import { userEvents } from "./user.event";

export class User {
  private _state: UserState = "UNAUTHENTICATED";

  value?: GetUsersCompleteSelfResponse;

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
    userEvents.dispatch(this);
  }
}

export const user = new User();
