import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../utils";

export const useNotifySignatories = (currentTrade: HubEnergy.TradeResponse) => {
  const {
    callService: sendPendingSignatureNotification,
    loading: loadingNotify,
    error: errorNotify,
  } = useServiceCall(service.hubEnergy.sendPendingSignatureNotification);

  const notifiySignatories = useCallback(async () => {
    const { success } = await sendPendingSignatureNotification({
      tradeId: currentTrade.id,
    });

    if (success) {
      OToastManager.success("Notificações enviadas com sucesso");
    } else {
      const errorMessage =
        getValidationMessages(errorNotify)?.[0]?.errorMessage ??
        "Falha ao enviar notificações";

      OToastManager.danger(errorMessage);
    }
  }, [currentTrade, errorNotify, sendPendingSignatureNotification]);

  return {
    loadingNotify,
    notifiySignatories,
  };
};
