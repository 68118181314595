import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./cnab-agreement.grid";
import { useCNABAgreement } from "./cnab-agreement.hook";

export const CNABAgreement = () => {
  const {
    agreementsList = [],
    createAgreement,
    isLoadingCreateAgreement,
    isLoadingAgreementsList,
  } = useCNABAgreement();
  const gridRef = useGridRef();

  return (
    <div>
      <GridTemplate
        pageTitle={<PageTitle title="Convênio - Arquivos e transmissões" />}
        actions={
          <LoadingButton
            onClick={createAgreement}
            loading={isLoadingCreateAgreement}
          >
            Cadastrar convênio
          </LoadingButton>
        }
        gridRef={gridRef}
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={agreementsList}
          loading={isLoadingAgreementsList}
        />
      </GridTemplate>
    </div>
  );
};
