import { OLoader } from "@maestro/react";
import { FormTemplate } from "templates/form-template";
import { PageTitle } from "components/page-title";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CalendarForm } from "../_compose/calendar-form";
import { useCalendarDetails } from "./calendar-details.hook";

export const CalendarDetailsPage = () => {
  const { calendarDetails, loading, updatingCalendar, form, onSubmit } =
    useCalendarDetails();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={`Calendário ${calendarDetails?.name ?? ""}`} />
      }
      actions={
        <LoadingButton
          loading={loading || updatingCalendar}
          dataAction="calendario:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <CalendarForm isUpdate />
      </FormProvider>
      {loading && <OLoader backdrop absolute />}
    </FormTemplate>
  );
};
