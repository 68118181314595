import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { GridTemplate } from "templates/grid-template";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { grid, dataSource } from "./semaphore-consults.grid";

export const SemaphoreConsults = () => {
  const gridRef = useGridRef();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Consultas"
          description="Todas as consultas do semáforo de Onboarding"
        />
      }
    >
      <CardTemplate>
        {dataSource && (
          <GridTemplate
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
            showExportButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={grid}
              dataSource={dataSource}
            />
          </GridTemplate>
        )}
      </CardTemplate>
    </FormTemplate>
  );
};
