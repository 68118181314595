import {
  OCol,
  OLabel,
  ORFieldInput,
  ORow,
  OSwitch,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useWorkflow } from "../../../../../../../../../../../components/hooks/use-workflow.context";
import { useWorkflowFormTemplate } from "../../../../../../../../../../../components/hooks/use-workflow-form-template.context";

export const ComponentBasicData = () => {
  const { selectedComponent } = useWorkflowFormTemplate();
  const { setSelectedConditions } = useWorkflow();

  const form = useForm();

  const { setValue, watch } = form;

  const nameWatcher = watch("name");

  const updateComponent = useCallback(
    async (value: boolean) => {
      try {
        if (!selectedComponent) return;

        await service.hubCreditManager.updateFormStepCardComponent({
          id: selectedComponent.id,
          isReadOnly: value,
          name: nameWatcher,
        });
      } catch {
        OToastManager.danger("Não foi possível atualizar componente");
      }
    },
    [selectedComponent, nameWatcher],
  );

  const handleFocusOut = useCallback(() => {
    return updateComponent(selectedComponent?.isReadOnly ?? false);
  }, [updateComponent, selectedComponent?.isReadOnly]);

  useEffect(() => {
    if (!selectedComponent) return;
    setSelectedConditions(selectedComponent.conditions);
  }, [selectedComponent, setSelectedConditions]);

  useEffect(() => {
    if (selectedComponent?.name === undefined) return;
    setValue("name", selectedComponent.name);
  }, [selectedComponent?.name, setValue]);

  useEffect(() => {
    const propertyInput = document.getElementById("name");

    if (propertyInput) {
      propertyInput.addEventListener("focusout", handleFocusOut);

      return () =>
        propertyInput.removeEventListener("focusout", handleFocusOut);
    }

    return () => {};
  }, [handleFocusOut]);

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <OTypography size="lg">Dados básicos</OTypography>
      </div>
      <ORow>
        <OCol className="d-flex flex-column gap-3">
          <FormProvider {...form}>
            <ORFieldInput
              id="name"
              name="name"
              tag="text"
              label="Nome"
              labelSize="md"
            />
          </FormProvider>
        </OCol>
        <OCol className="d-flex flex-column gap-3">
          <OTypography>Tipo</OTypography>
          <OTypography>{selectedComponent?.component?.type}</OTypography>
        </OCol>
      </ORow>
      <ORow>
        <OCol className="d-flex flex-column gap-3">
          <OTypography>Descrição</OTypography>
          <OTypography>{selectedComponent?.component?.description}</OTypography>
        </OCol>
        <OCol className="d-flex flex-column ">
          <OLabel htmlFor="isReadOnly">Somente Leitura</OLabel>
          <OSwitch
            type="tertiary"
            id="isReadOnly"
            value={selectedComponent?.isReadOnly ?? false}
            size="sm"
            onInput={(e) => updateComponent(e.currentTarget.value)}
          />
        </OCol>
      </ORow>
    </div>
  );
};
