import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";

export const tradeImportsGrid = {
  datagrid: {
    noDataText: "Nenhum trade encontrado.",
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar trades.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubLoan.odata.tradeImportsAdmin,
    ),
  },
  columns: [
    {
      allowExporting: false,
      type: "buttons",
      role: roles.emprestimos.product.loanDetailsSummaryPage.role,
      cellRender: ({ data }) =>
        data.loanId &&
        data.taxId && (
          <DetailsLink
            href={buildCustomerLink(
              data.taxId,
              corporateRouter.routes.emprestimos.customer.operations.details.path(
                {
                  id: data.loanId,
                },
              ),
            )}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
    },
    {
      caption: "TradeId",
      dataField: "tradeId",
    },
    {
      caption: "Parceiro",
      dataField: "partnerName",
    },
    {
      caption: "CPF/CNPJ parceiro",
      dataField: "partnerTaxId",
      format: (value) => masks.cpf(value),
    },
    {
      caption: "Produto",
      dataField: "product",
    },
    {
      caption: "Status",
      dataField: "status",
    },
    {
      caption: "Requisitado por",
      dataField: "requestedBy",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.odata.getTradeImportsAdmin>
    >["data"]["value"]
  >[number]
>;
