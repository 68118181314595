import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../routes/workflow.route-params";
import { defaultValues, schema } from "./edit-workflow-config-main-data.schema";
import { EditWorkflowConfigMainDataFormValuesType } from "./edit-workflow-config-main-data.types";

export const useEditWorkflowConfigMainData = () => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const form = useForm<EditWorkflowConfigMainDataFormValuesType>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { reset } = form;

  const {
    value: workflowConfig,
    callService: getWorkflowConfigDetails,
    loading: loadingWorkflowConfig,
    hasError: hasErrorWorkflowConfig,
  } = useServiceCall(service.hubCreditManager.getWorkflowConfigDetails);

  const {
    value: detailsPageTypeData,
    callService: getDetailsPageType,
    loading: loadingGetDetailsPageType,
    hasError: hasErrorGetDetailsPageType,
  } = useServiceCall(service.hubCreditManager.getDetailsPageType);

  const {
    value: books,
    callService: getBooks,
    loading: loadingBooks,
    hasError: hasErrorBooks,
  } = useServiceCall(service.hubCreditManager.getBooks);

  const {
    value: minimumCriteriaOptions,
    callService: getMinimumCriteria,
    loading: loadingMinimumCriteria,
    hasError: hasErrorMinimumCriteria,
  } = useServiceCall(service.hubCreditManager.getMinimumCriteria);
  const {
    value: creditLines,
    callService: getCreditLines,
    loading: loadingCreditLines,
    hasError: hasErrorCreditLines,
  } = useServiceCall(service.hubCreditManager.getCreditLines);
  const {
    value: reusableWorkflowConfigsOptions,
    callService: getReusableWorkflowConfigs,
    loading: loadingReusableChildWorkflowConfigs,
    hasError: hasErrorReusableChildWorkflowConfigs,
  } = useServiceCall(service.hubCreditManager.getAllWorkflowConfigs);
  const {
    callService: callEditWorkflowConfig,
    loading: loadingEditWorkflowConfig,
  } = useServiceCall(service.hubCreditManager.editWorkflowConfig);

  const hasError = useMemo(() => {
    return (
      hasErrorBooks ||
      hasErrorMinimumCriteria ||
      hasErrorWorkflowConfig ||
      hasErrorGetDetailsPageType ||
      hasErrorCreditLines ||
      hasErrorReusableChildWorkflowConfigs
    );
  }, [
    hasErrorBooks,
    hasErrorCreditLines,
    hasErrorGetDetailsPageType,
    hasErrorMinimumCriteria,
    hasErrorWorkflowConfig,
    hasErrorReusableChildWorkflowConfigs,
  ]);

  const loading = useMemo(() => {
    return (
      loadingBooks ||
      loadingMinimumCriteria ||
      loadingWorkflowConfig ||
      loadingGetDetailsPageType ||
      loadingCreditLines ||
      loadingReusableChildWorkflowConfigs
    );
  }, [
    loadingBooks,
    loadingCreditLines,
    loadingGetDetailsPageType,
    loadingMinimumCriteria,
    loadingWorkflowConfig,
    loadingReusableChildWorkflowConfigs,
  ]);

  const updateWorkflow = useCallback(
    async ({
      bookCr,
      defaultCreditLines,
      defaultUserNotificationType,
      description,
      finishPreviousBigNumberOnStartNewOne,
      hideDetailsAction,
      hideSummary,
      checkDenyList,
      gridTitle,
      detailsPageType,
      icon,
      minimumCriteria,
      name,
      nameId,
      uniqueNameId,
      tabDescription,
      tabTitle,
      reusableWorkflowConfigs,
      redirectWorkflow,
    }: EditWorkflowConfigMainDataFormValuesType) => {
      const { success } = await callEditWorkflowConfig({
        id,
        name,
        nameId,
        uniqueNameId,
        ...(bookCr !== 0 && { bookCr }),
        defaultCreditLines,
        defaultUserNotificationType,
        description,
        detailsPageType,
        finishPreviousBigNumberOnStartNewOne:
          finishPreviousBigNumberOnStartNewOne.includes("true"),
        hideDetailsAction: hideDetailsAction.includes("true"),
        hideSummary: hideSummary.includes("true"),
        checkDenyList: checkDenyList.includes("true"),
        gridTitle,
        icon,
        minimumCriteriaIds: minimumCriteria?.map((x) => Number(x)) ?? [],
        tabDescription,
        tabTitle,
        workflowConfigReusableIds:
          reusableWorkflowConfigs?.map((x) => Number(x)) ?? [],
        redirectWorkflow,
      });

      if (success) {
        OToastManager.success("Workflow editado com sucesso!");
      } else {
        OToastManager.danger(
          "Houve um problema ao tentar editar a configuração de workflow. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callEditWorkflowConfig, id],
  );

  const getAllData = useCallback(() => {
    getWorkflowConfigDetails(id);
    getBooks();
    getMinimumCriteria();
    getReusableWorkflowConfigs();
    getCreditLines();
    getDetailsPageType();
  }, [
    getBooks,
    getCreditLines,
    getDetailsPageType,
    getMinimumCriteria,
    getReusableWorkflowConfigs,
    getWorkflowConfigDetails,
    id,
  ]);

  useEffect(() => {
    if (!workflowConfig) return;

    const {
      book,
      defaultCreditLines,
      defaultUserNotificationType,
      description,
      detailsPageType,
      finishPreviousBigNumberOnStartNewOne,
      hideDetailsAction,
      hideSummary,
      checkDenyList,
      gridTitle,
      icon,
      identification,
      minimumCriteria,
      name,
      nameId,
      uniqueNameId,
      tabDescription,
      tabTitle,
      workflowConfigReusables,
      redirectWorkflow,
    } = workflowConfig;

    setTimeout(() => {
      reset({
        bookCr: book && book.bookCr,
        defaultCreditLines: defaultCreditLines ?? [],
        defaultUserNotificationType: defaultUserNotificationType ?? "",
        description,
        detailsPageType,
        finishPreviousBigNumberOnStartNewOne:
          finishPreviousBigNumberOnStartNewOne ? ["true"] : [],
        hideDetailsAction: hideDetailsAction ? ["true"] : [],
        hideSummary: hideSummary ? ["true"] : [],
        checkDenyList: checkDenyList ? ["true"] : [],
        gridTitle,
        icon,
        identification,
        minimumCriteria: minimumCriteria.map((x) => String(x.id)),
        name,
        nameId,
        uniqueNameId,
        tabDescription,
        tabTitle,
        reusableWorkflowConfigs:
          workflowConfigReusables?.map((x) =>
            String(x.reusableWorkflowConfigId),
          ) ?? [],
        redirectWorkflow: redirectWorkflow,
      });
    }, 200);
  }, [reset, workflowConfig]);

  useEffect(() => {
    hasErrorWorkflowConfig &&
      OToastManager.danger(
        "Um erro ocorreu ao buscar pelos dados da configuração do workflow. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorWorkflowConfig]);

  useEffect(() => {
    hasErrorBooks &&
      OToastManager.danger(
        "Um erro ocorreu ao tentar buscar pelos books. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorBooks]);

  useEffect(() => {
    hasErrorMinimumCriteria &&
      OToastManager.warning(
        "Um erro ocorreu ao buscar pelos critérios mínimos. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorMinimumCriteria]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    books,
    creditLines,
    detailsPageTypeData,
    form,
    hasError,
    loading,
    loadingEditWorkflowConfig,
    minimumCriteriaOptions,
    reusableWorkflowConfigsOptions,
    workflowConfig,
    getAllData,
    updateWorkflow,
  };
};
