import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { notificationStatusMap } from "./review-notification-status.utils";

interface ReviewNotificationStatusProps {
  notificationStatus?: string | null;
}

export const ReviewNotificationStatus = ({
  notificationStatus,
}: ReviewNotificationStatusProps) => {
  const content = getValueFromMap(notificationStatusMap, notificationStatus);

  if (!content) return null;

  return <StatusBadge type={content.type}>{content.text}</StatusBadge>;
};
