import { OToastManager, OTypography } from "@maestro/react";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useState } from "react";
import { service } from "services/service";

interface ActivateUserAccessButtonProps {
  email: string;
}

export const ActivateAccessButton = ({
  email,
}: ActivateUserAccessButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleActivation = async () => {
    try {
      setLoading(true);
      await service.gatekeeper.activateUser(email);
      OToastManager.success("Acesso ativado com sucesso.");
    } catch {
      OToastManager.danger("Falha ao ativar acesso.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonWithConfirmation
      confirmationModal={{ title: "Ativar acesso", loading }}
      modalBody={
        <OTypography>
          Tem certeza que deseja ativar o acesso desse usuário?
        </OTypography>
      }
      dataAction="usuario_produto:botao:ativar_acesso"
      dataLabel="ativar_acesso"
      onConfirm={() => handleActivation()}
      outline
    >
      Ativar acesso
    </ButtonWithConfirmation>
  );
};
