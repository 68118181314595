import {
  modalManager,
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { OfferGuarantorExplanationModalId } from "./offer-guarantors-explanation-modal.types";

export * from "./offer-guarantors-explanation-modal.types";

export const OfferGuarantorExplanationModal = () => {
  return (
    <OModal id={OfferGuarantorExplanationModalId} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography size="xl">
          Quem são os responsáveis pelo cartão?
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography type="dark-80">
          O responsável pelo cartão assina como Devedor Solidário e se declara
          responsável por cumprir as obrigações presentes e assumidas pela
          empresa que assina o contrato do cartão de crédito. O Devedor
          Solidário poderá ser cobrado, caso o Devedor Originário não cumpra as
          obrigações presentes no contrato.
        </OTypography>
      </OModalBody>
      <OModalFooter divider>
        <ORow justify="end">
          <OCol sm="auto">
            <OButton
              outline
              onClick={() =>
                modalManager.hide(OfferGuarantorExplanationModalId)
              }
            >
              Entendi
            </OButton>
          </OCol>
        </ORow>
      </OModalFooter>
    </OModal>
  );
};
