import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import {
  InfoCardContent,
  InfoCardContentProps,
} from "./info-card-content.component";

interface InfoCardProps extends InfoCardContentProps {
  hasError: boolean;
  loading: boolean;
  refetch: () => void;
}

export const InfoCard = ({
  hasError,
  loading,
  refetch,
  footerFirstLabel,
  footerFirstValue,
  footerSecondLabel,
  footerSecondValue,
  title,
  value,
}: InfoCardProps) => {
  return (
    <OCard className="position-relative">
      <OCardBody>
        <ContentTemplate
          hasError={hasError}
          loading={loading}
          noValue
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar as informações"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => refetch()} />
            </ErrorComponent>
          }
          render={() => (
            <InfoCardContent
              footerFirstLabel={footerFirstLabel}
              footerFirstValue={footerFirstValue}
              footerSecondLabel={footerSecondLabel}
              footerSecondValue={footerSecondValue}
              refetch={refetch}
              title={title}
              value={value}
            />
          )}
        />
      </OCardBody>
    </OCard>
  );
};
