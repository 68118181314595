import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { mapCurrencySymbol } from "utils/currency";
import { StyledValue } from "../roadwalker-master-detail.style";
import { FeePaymentMasterDetail } from "../roadwalker-master-detail.types";

export const roadwalkerFeePaymentDetails = (data: FeePaymentMasterDetail) => {
  if (!data) {
    return {} as DetailsFields;
  }

  return {
    feepayment: {
      items: [
        {
          label: "MessageId",
          value: <StyledValue>{data.MessageId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Identification",
          value: <StyledValue>{data.Identification}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "FeeTradeId",
          value: <StyledValue>{data.FeeTradeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "InstallmentNumber",
          value: <StyledValue>{data.InstallmentNumber}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "OriginalFeeId",
          value: <StyledValue>{data.OriginalFeeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "LinkedFeeId",
          value: <StyledValue>{data.LinkedFeeId}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PaymentDate",
          value: (
            <StyledValue>
              {dayjs(data.PaymentDate).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: <StyledValue>{data.Status}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PublishDate",
          value: (
            <StyledValue>
              {dayjs(data.PublishDate).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Value",
          value: (
            <StyledValue>
              {masks.currency(
                data.Value,
                mapCurrencySymbol(data.Currency),
                ".",
              )}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Currency",
          value: <StyledValue>{mapCurrencySymbol(data.Currency)}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SettlementMethod",
          value: <StyledValue>{data.SettlementMethod}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "SettlementSystem",
          value: <StyledValue>{data.SettlementSystem}</StyledValue>,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CreatedAt",
          value: (
            <StyledValue>
              {dayjs(data.CreatedAt).utc(false).format("DD-MM-YYYY HH:mm:ss")}
            </StyledValue>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
