import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { StyledSpan } from "./admin-client-acc-proposals.styles";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.acc.adminCustomer.getAccProposals>
    >["data"]["response"]
  >[number]
>;

export const adminCustomerACCProposalsODataGrid = () =>
  ({
    datagrid: {
      noDataText: "Nenhuma proposta encontrada.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar propostas.";
      },
      dataSource: dataSourceCustomStoreGenerator(() =>
        service.acc.adminCustomer
          .getAccProposals()
          .then(({ data }) => data.response ?? []),
      ),
    },
    columns: [
      {
        type: "buttons",
        allowExporting: false,
        role: roles.acc.customer.proposalsDetailsPage.role,
        cellRender: ({ data }) =>
          data.correlationId &&
          data.status === "DISPONIVEL" && (
            <StyledSpan>
              <StandardLink
                href={corporateRouter.routes.acc.customer.proposals.details.path(
                  {
                    id: data.correlationId,
                  },
                )}
              >
                Simular
              </StandardLink>
            </StyledSpan>
          ),
      },
      {
        caption: "Nome",
        dataField: "counterpartyName",
      },
      {
        caption: "Identificação",
        dataField: "counterpartyIdentification",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Proposta",
        dataType: "number",
        alignment: "left",
      },
      {
        caption: "Valor solicitado",
        dataType: "date",
        dataField: "amount",
        cellRender: ({ data }) =>
          masks.currency(
            data.amount,
            getValueFromMap(currencySymbol, data.currency),
          ),
      },
      {
        caption: "Data de vencimento",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "settlementDate",
      },
      {
        caption: "Data de expiração",
        dataType: "date",
        format: "dd/MM/yyyy",
        dataField: "expirationDate",
      },
      {
        caption: "Taxa de juros",
        dataField: "interestRate",
        customizeText: ({ value }) => masks.percentage(value),
        alignment: "left",
      },
      {
        caption: "Status",
        dataField: "status",
      },
    ],
  } satisfies Grid);
