import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { roles } from "roles/roles";
import { useSemaphoreTemplateAdd } from "./add.hook";
import { SemaphoreTemplateForm } from "../_compose";

export const SemaphoreTemplateAdd = () => {
  const { submit, form, loading } = useSemaphoreTemplateAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Semáforo"
          description="Adicionar novo template de semáforo"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="configuracao_semaforo:botao:adicionar"
          dataLabel="adicionar"
          onClick={submit}
          role={roles.ferramentas.product.semaphoreConfigTemplateAdd.role}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <SemaphoreTemplateForm mode="add" />
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
