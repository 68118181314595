import { validators } from "@maestro/utils";
import * as yup from "yup";
import { ContractSignatoriesForm } from "./contract-signatories-form.types";

export const contractSignatoriesFormValidationSchema = yup.object<
  ContractSignatoriesForm,
  yup.ObjectSchema<ContractSignatoriesForm>["fields"]
>({
  taxId: yup
    .string()
    .required("Campo obrigatório")
    .test("taxId", "Documento inválido", validators.cpfCnpj),
  identifications: yup
    .array()
    .required("Campo obrigatório")
    .min(1, "É necessário selecionar ao menos um representante."),
});
