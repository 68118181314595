import { OToastManager, modalManager } from "@maestro/core";
import { DataGridAction } from "components/datagrid-action";
import { useCallback, useState } from "react";
import { service } from "services";
import { ProcessorProperty } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useEditProcessor } from "../../edit-processor.context";
import { PROPERTY_MODAL_ID } from "../property-modal/property-modal.component";

interface PropertiesGridActionsProps {
  data: ProcessorProperty;
}

export const PropertiesGridActions = ({ data }: PropertiesGridActionsProps) => {
  const {
    processor: { callService: getProcessorDetails },
    id,
    setSelectedProperty,
  } = useEditProcessor();

  const [loading, setLoading] = useState(false);

  const { show } = modalManager;

  const removeProperty = useCallback(
    async (processorPropertyId: number) => {
      try {
        setLoading(true);

        await service.hubCreditManager.removeProcessorProperty({
          processorPropertyId,
        });

        getProcessorDetails(id);
        OToastManager.success("A propriedade foi removida com sucesso.");
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao remover propriedade do processador. Por favor, tente novamente mais tarde.",
        );
      } finally {
        setLoading(false);
      }
    },
    [getProcessorDetails, setLoading, id],
  );

  return (
    <DataGridAction
      actions={[
        {
          icon: { category: "orq", icon: "orq-edit-pencil" },
          label: "Editar",
          onClick: () => {
            setSelectedProperty(data);
            show(PROPERTY_MODAL_ID);
          },
        },
        {
          icon: { category: "orq", icon: "orq-edit-trash" },
          label: "Remover",
          disabled: loading,
          onClick: () => {
            removeProperty(data.id);
          },
        },
      ]}
    />
  );
};
