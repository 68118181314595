import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { ferramentasRoles } from "../../../roles/ferramentas.roles";
import { GatekeeperUser } from "./gatekeeper-users.types";

export const dataSource = dataSourceCustomStoreGenerator<GatekeeperUser>(
  (loadOptions) =>
    service.gatekeeper
      .getUsers(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os usuários";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
);

const buildDetailsLink = (taxId: string) =>
  corporateRouter.routes.ferramentas.product.gatekeeper.users.byTaxId.userProfiles.path(
    { taxId: encodeURIComponent(taxId) },
  );

export const gatekeeperUsersGrid: ODataGridGeneratorConfig<GatekeeperUser> = {
  datagrid: {
    noDataText: "Nenhum usuário",
    filterRow: { visible: true },
    remoteOperations: true,
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      role: ferramentasRoles.product.gatekeeperUsersUserProfilesPage.role,
      caption: "Detalhes",
      cellRender: ({ data: { identification } }) =>
        identification && (
          <DetailsLink href={buildDetailsLink(identification)} />
        ),
    },
    {
      dataField: "identification",
      dataType: "string",
      caption: "CPF",
      format: (value) => masks.cpf(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "email",
      dataType: "string",
      caption: "E-mail",
    },
    {
      dataField: "uuid",
      dataType: "string",
      caption: "UUID",
    },
    {
      dataField: "profiles",
      dataType: "string",
      caption: "Profiles",
    },
  ],
};
