import { OCheckboxGroup } from "@maestro/react";
import { CheckBoxButton } from "./items/check-box-button.component";
import { balanceYield } from "../../filter/filters";

export const BalanceYieldOptions = () => {
  return (
    <OCheckboxGroup
      className="d-flex flex-row justify-content-start gap-3"
      data-filterparam="balanceYieldFilters"
      dataAction="statement-export-filter"
      dataLabel="statement-export-filter"
    >
      {balanceYield.map((item) => (
        <CheckBoxButton groupName="balance-yield" {...item} key={item.value} />
      ))}
    </OCheckboxGroup>
  );
};
