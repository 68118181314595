import { OCol, OOption, ORFieldSelect, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useToolsApproveContingency } from "./approve-contingency.hook";

export const ToolsApproveContingency = () => {
  const { form, submit, loading } = useToolsApproveContingency();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Aprovar conclusão de cadastro" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="formulario-ferramentas-aprovar-conclusao-cadastro:botao:aprovar"
          dataLabel="aprovar"
          onClick={submit}
        >
          Aprovar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} sm={6} lg={4}>
                <ORFieldSelect
                  id="relationshipType"
                  name="relationshipType"
                  dataAction="formulario-ferramentas-aprovar-conclusao-cadastro:select:tipo-relacionamento"
                  dataLabel="tipo-relacionamento"
                  placeholder="Selecionar"
                  label="Tipo de Relacionamento"
                >
                  <OOption value="CEDENTE_COMPLETO">Cedente Completo</OOption>
                  <OOption value="CLIENTE_ABERTURA_DE_CONTA">
                    Cliente Abertura de Conta
                  </OOption>
                </ORFieldSelect>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
