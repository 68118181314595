import { isAxiosError } from "axios";
import CustomStore from "devextreme/data/custom_store";
import { service } from "services";
import { logger } from "utils/logger";

export const getHeaderFilter = (entityName: string, propertyName: string) =>
  new CustomStore({
    load: () =>
      service.quickfin
        .getDistinctValues(entityName, propertyName)
        .then(({ data }) => data.map((item) => ({ text: item, value: item })))
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
        }),
    loadMode: "raw",
  });
