import { OButton, OFieldError } from "@maestro/react";
import { CreateAccountOptinForm } from "../../create-accounts-optin.type";
import { CreateAccountsFields } from "./_compose/create-accounts-fields";
import { useCreateAccountsForm } from "./create-accounts-form.hook";
import { newCreateAccountOptin } from "../../create-accounts-optin.form";

export const CreateAccountForm = () => {
  const {
    officers,
    templates,
    createAccountOptinErrors,
    fields,
    append,
    remove,
  } = useCreateAccountsForm();

  return (
    <div>
      {!!createAccountOptinErrors?.message && (
        <OFieldError visible>{createAccountOptinErrors.message}</OFieldError>
      )}

      {fields.map((field, index) => (
        <div className="d-flex flex-column" key={field.id}>
          <CreateAccountsFields
            index={index}
            templates={templates ?? []}
            officers={officers}
            remove={remove}
          />
        </div>
      ))}

      {!!fields.length && (
        <div className="d-flex justify-content-end">
          <OButton
            dataAction="profiles_form:botao:adicionar"
            dataLabel="adicionar"
            onClick={() =>
              append(
                newCreateAccountOptin as CreateAccountOptinForm["createAccountOptin"][number],
              )
            }
          >
            Adicionar
          </OButton>
        </div>
      )}
    </div>
  );
};
