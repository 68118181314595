import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { attachUserFields, checkboxValue } from "./attach-user.form";
import { useAttachUser } from "./attach-user.hook";

export const AttachUser = () => {
  const { form, initialValues, loading, submit } = useAttachUser();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Vincular usuário" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="acoes:botao:enviar"
          dataLabel="enviar"
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                {...attachUserFields.taxId}
                disabled={!!initialValues?.taxId}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                {...attachUserFields.name}
                disabled={!!initialValues?.name}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                {...attachUserFields.email}
                disabled={!!initialValues?.email}
              />
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldInput
                {...attachUserFields.phone}
                disabled={!!initialValues?.phone}
              />
            </OCol>
            <OCol xs={12}>
              <ORFieldCheckboxGroup {...attachUserFields.sendWelcomeEmail}>
                <div className="d-flex align-items-center gap-2">
                  <OCheckbox
                    id={checkboxValue}
                    value={checkboxValue}
                    size="sm"
                  />
                  <OLabel htmlFor={checkboxValue} type="dark">
                    Enviar e-mail de boas vindas
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
