import { masks } from "@maestro/utils";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { ExchangePreTicketDetails } from "../../../../../../components/pre-tickets/pre-ticket-details.component";
import { CambioPreTicketsByIdRouteParams } from "../../../../../../routes/cambio.route-params";

export const ExchangeProductPreTicketDetailsPage = () => {
  const { id, counterparty } = useParams<CambioPreTicketsByIdRouteParams>();

  if (!id) throw new Error("No id");
  if (!counterparty) throw new Error("No counterparty");

  const {
    value: preTicketDetails,
    callService: getPreTicketDetails,
    hasError: hasErrorPreTicketDetails,
    loading: loadingPreTicketDetails,
  } = useServiceCall(service.hubFx.getPreTicketDetails);

  useEffect(() => {
    getPreTicketDetails({
      preTicketId: Number(id),
      counterpartyIdentification: counterparty,
    });
  }, [getPreTicketDetails, id, counterparty]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do Pré-Ticket" />}>
      <div className="d-flex flex-column gap-2">
        <DetailsCard
          loading={loadingPreTicketDetails}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar detalhes do pré-ticket."
              messageParagraph="Tente novamente mais tarde."
            >
              <TryAgainButton
                onClick={() =>
                  getPreTicketDetails({
                    preTicketId: Number(id),
                    counterpartyIdentification: counterparty,
                  })
                }
              />
            </ErrorComponent>
          }
          hasError={hasErrorPreTicketDetails}
          value={preTicketDetails?.[0]}
          fields={(preTicket) => ({
            company: {
              title: "Empresa",
              items: [
                {
                  label: "Nome da empresa",
                  value: preTicket.counterpartyName,
                  grid: { md: 4 },
                },
                {
                  label: "CNPJ",
                  value: masks.cpfCnpj(counterparty),
                  grid: { md: 4 },
                },
              ],
            },
          })}
        />
        {preTicketDetails && (
          <ExchangePreTicketDetails
            preTicketDetails={preTicketDetails[0]}
            type="product"
          />
        )}
      </div>
    </DetailsTemplate>
  );
};
