import {
  OCard,
  OCardBody,
  OCol,
  OField,
  ORow,
  OSwitch,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { MoanaOwnerStatus } from "services/moana/models";
import { CardState } from "pages/duplicatas/pages/produto/carteiras/_compose/wallet-state/card-state.component";
import { DetailWallet } from "./detail-wallet.component";

interface DetailWalletInterface {
  documentNumber: string;
  name: string;
  personType: string;
  activePortfolio: boolean;
  totalValue: number;
  state: MoanaOwnerStatus[];
  limitStatus: string;
  expirationDate: string;
}

export const DetailWalletMenuComponent = ({
  documentNumber,
  name,
  personType,
  activePortfolio,
  totalValue,
  state,
  limitStatus,
  expirationDate,
}: DetailWalletInterface) => (
  <OCard>
    <OCardBody className="position-relative">
      <ORow>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet title="Identificação" value={documentNumber} />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet title="Razão social" value={name} />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet title="Tipo de pessoa" value={personType} />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <div style={{ boxSizing: "border-box" }}>
            <div className="d-flex flex-column gap-2 mb-3">
              <OTypography weight="500" size="sm" type="default">
                Produto ativo
              </OTypography>
              <OField htmlFor="isActive" disabled>
                <OSwitch
                  value={activePortfolio}
                  className="w-100"
                  size="sm"
                  disabled
                />
              </OField>
            </div>
          </div>
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet
            title="Limite aprovado"
            value={masks.currency(totalValue, "R$")}
          />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet
            title="Data de expiração do limite"
            value={expirationDate}
          />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <DetailWallet title="Status do limite" value={limitStatus} />
        </OCol>
        <OCol xs={12} md={6} lg={3}>
          <CardState title="Status" state={state ?? []} />
        </OCol>
      </ORow>
    </OCardBody>
  </OCard>
);
