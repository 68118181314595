import { OCheckbox, OLabel } from "@maestro/react";
import { Props } from "../../export-modal.types";

export const CheckBoxButton = ({
  label,
  value,
  groupName,
}: Props & { groupName: string }) => {
  return (
    <div className="d-flex flex-row justify-content-start gap-3 align-items-center">
      <OCheckbox id={value} name={groupName} size="sm" value={value} />
      <OLabel htmlFor="radioxs" size="md">
        {label}
      </OLabel>
    </div>
  );
};
