import {
  modalManager,
  OConfirmationModal,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useWorkflowFormTemplate } from "../../../../../../../../../components/hooks/use-workflow-form-template.context";

export const RemoveStepModal = () => {
  const [loading, setLoading] = useState(false);
  const {
    selectedStep,
    setSelectedStep,
    form: { value: workflowFormTemplate, callService: getForm },
  } = useWorkflowFormTemplate();

  const { hide } = modalManager;

  const deleteStep = useCallback(async () => {
    try {
      if (!selectedStep) return;

      setLoading(true);

      await service.hubCreditManager.removeFormStep({
        formStepId: selectedStep.id,
      });

      OToastManager.success("Passo excluído com sucesso!");
      workflowFormTemplate?.id && getForm(workflowFormTemplate.id);
      setSelectedStep(undefined);
      hide("remove-step-modal");
    } catch {
      OToastManager.danger(
        "Não foi possível remover passo. Tente novamente mais tarde.",
      );
    } finally {
      setLoading(false);
    }
  }, [getForm, hide, selectedStep, workflowFormTemplate, setSelectedStep]);

  return (
    <OConfirmationModal
      modalId="remove-step-modal"
      title="Tem certeza que deseja excluir este passo?"
      onConfirm={deleteStep}
      loading={loading}
      className="position-absolute"
    >
      <OTypography size="md">
        Ao confirmar a exclusão não será possível reverter a ação.
      </OTypography>
    </OConfirmationModal>
  );
};
