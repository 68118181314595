import { OCard, OCardBody, OTypography } from "@maestro/react";
import { ContentTemplate } from "templates/content-template";
import { ExportChartButton } from "../export-chart-button";
import { BarChart } from "./bar-chart.component";
import { Metric } from "./chart-card.types";

interface ChartCardProps {
  metric: Metric;
}

export const ChartCard = ({ metric }: ChartCardProps) => {
  return (
    <OCard>
      <OCardBody className="d-flex flex-column align-items-stretch">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <OTypography tag="span" size="lg">
            {metric.configName}
          </OTypography>
          <div className="d-flex align-items-center gap-2 ms-5">
            <ExportChartButton metric={metric} />
          </div>
        </div>

        <ContentTemplate
          value={metric.records}
          render={(_records) => (
            <BarChart configName={metric.configName} records={_records} />
          )}
        />
      </OCardBody>
    </OCard>
  );
};
