import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { dataSource, desksGrid } from "./desks.grid";

export const Desks = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Mesas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={
        <RouterButton
          href={
            corporateRouter.routes.parceiros.product.configurations.desks.add
              .path
          }
          role={roles.parceiros.product.addDesk.role}
        >
          Adicionar mesa
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={desksGrid}
      />
    </GridTemplate>
  );
};
