import { OToastManager, OUploadCustomEvent, modalManager } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { Contract } from "../../contracts-grid.types";
import { EditContractModalForm } from "./edit-contract-modal.types";
import { editContractModalId } from "./edit-contract-modal.utils";

export const useEditContractModal = (
  contract: Contract,
  reload: () => void,
) => {
  const uuid = (contract.UUID as any)._value;

  const { callService, loading } = useServiceCall(
    service.paperclipv2.updateContract,
  );

  const { loading: fileLoading, reader, result } = useReadFile();

  const form = useForm<EditContractModalForm>();

  const { getValues, handleSubmit, reset, setError, setValue } = form;

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>, resolve: (file: File) => void) => {
      if (getValues("fileBase64")) {
        setError("uploadDummy", {
          message: "Apenas um arquivo pode ser enviado",
        });
        return;
      }

      const file = evt.detail;

      if (file) {
        reader.readAsDataURL(file);
        resolve(file);
      }
    },
    [getValues, reader, setError],
  );

  const updateContract = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await callService(uuid, {
          ContractPlatform: values.platform,
          NewStatus: values.status,
          NewContractFile: values.fileBase64 ?? "",
        });
        if (success) {
          OToastManager.success("Contrato atualizado");
          reload();
          modalManager.hide(editContractModalId(uuid));
        } else OToastManager.danger("Não foi possível atualizar o contrato");
      }),
    [callService, handleSubmit, reload, uuid],
  );

  useEffect(() => {
    reset({
      platform: contract.ContractPlatform,
      status: contract.Status,
      uploadDummy: [],
    });
  }, [contract.ContractPlatform, contract.Status, reset]);

  useEffect(() => {
    if (result && typeof result === "string") {
      setValue("fileBase64", result.split("base64,")[1]);
    }
  }, [result, setValue]);

  return {
    fileLoading,
    form,
    handleAddFile,
    loading,
    updateContract,
  };
};
