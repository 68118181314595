import { OTypography } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { OnboardingStrategiesODataValueItem } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { priorityMap, productMap } from "./onboarding-strategies.utils";

export const onboardingStrategiesGrid = (
  filterEstrategias: (filterValue: string) => string[],
  filterSacados: (filterValue: string) => string[],
  setStrategyId: React.Dispatch<React.SetStateAction<number | undefined>>,
): ODataGridGeneratorConfig<OnboardingStrategiesODataValueItem> => ({
  datagrid: {
    noDataText: "Nenhuma estratégia encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} itens)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "Description", caption: "Descrição" },
    {
      dataField: "OnboardingPriority",
      caption: "Prioridade",
      cellRender: ({ data }) =>
        getValueFromMap(priorityMap, data.OnboardingPriority.toString()),
      lookup: {
        dataSource: Object.keys(priorityMap).map((key) => ({
          value: parseInt(key),
          label: getValueFromMap(priorityMap, key),
        })),
        valueExpr: "value",
        displayExpr: "label",
      },
    },
    {
      dataField: "ProductType",
      caption: "Tipo de Produto",
      format: (value) => productMap[value],
      cellRender: ({ data }) => getValueFromMap(productMap, data.ProductType),
      lookup: {
        dataSource: Object.keys(productMap).map((key) => ({
          value: key,
          label: getValueFromMap(productMap, key),
        })),
        valueExpr: "value",
        displayExpr: "label",
      },
    },
    { dataField: "OnboardingType", caption: "Tipo de onboarding" },
    {
      dataField: "Sacados",
      caption: "Sacados",
      allowSorting: false,
      cellRender: ({ data }) =>
        data.Sacados?.map((sacado: string) => (
          <OTypography key={sacado}>{sacado}</OTypography>
        )),
      calculateFilterExpression: filterSacados,
    },
    {
      dataField: "Estrategias",
      caption: "Estratégias Comerciais",
      allowSorting: false,
      cellRender: ({ data }) =>
        data.Estrategias?.map((estrategia: string) => (
          <OTypography key={estrategia}>{estrategia}</OTypography>
        )),
      calculateFilterExpression: filterEstrategias,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                route:
                  corporateRouter.routes.recebiveis.product.onboardingStrategies.edit.path(
                    {
                      strategyId: data.Id,
                    },
                  ),
                role: roles.recebiveis.product.onboardingStrategiesEditPage
                  .role,
              },
              {
                label: "Excluir",
                icon: { category: "orq", icon: "orq-edit-trash" },
                onClick: () => setStrategyId(data.Id),
              },
            ]}
          />
        );
      },
    },
  ],
});
