import {
  OConfirmationModal,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

const modalId = "force-recreate-diligence-confirmation-modal";

interface ForceRecreateDiligenceButtonProps {
  consumerUnitId: string | number;
}

export const ForceRecreateDiligenceButton = ({
  consumerUnitId,
}: ForceRecreateDiligenceButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.forceRecreateDiligence,
  );

  const recreate = useCallback(async () => {
    const { success } = await callService(consumerUnitId);

    if (success) OToastManager.success("Migração recriada com sucesso");
    else OToastManager.danger("Erro ao recriar a migração");
  }, [callService, consumerUnitId]);

  return (
    <>
      <LoadingButton
        loading={loading}
        type="danger"
        onClick={() => modalManager.show(modalId)}
      >
        Recriar migração
      </LoadingButton>
      <OConfirmationModal
        style={{ position: "absolute" }}
        modalId={modalId}
        title="Recriar migração"
        confirmLabel="Sim"
        closeLabel="Cancelar"
        divider={false}
        onConfirm={() => {
          recreate();
          modalManager.hide(modalId);
        }}
      >
        <OTypography>Tem certeza que deseja recriar a migração?</OTypography>
        <OTypography>
          A migração atual será excluida e uma nova será criada.
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
