import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import {
  NameAndTaxIdFormatted,
  calculateNameAndTaxIdFilterExpression,
} from "components/name-and-tax-id-formatted";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../components/mapped-status-badge";
import {
  MappedStatus,
  configureHeaderFilterDataSource,
  diligenceStepStatusMap,
  getValidationMessages,
} from "../../../utils";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepMonitor>(() =>
    service.hubEnergy
      .getDiligenceStepsMonitor()
      .then(({ data }) => data.response)
      .catch((err) => {
        const errorMessage =
          getValidationMessages(err)?.[0]?.errorMessage ??
          "Erro ao buscar os steps";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

const [postProcessConsumerUnit, calculateConsumerUnitCellValue] =
  configureHeaderFilterDataSource<HubEnergy.DiligenceStepMonitor>()(
    [
      "consumerUnitOfficialName",
      "consumerUnitTaxId",
      "consumerUnitInstallationNumber",
    ] as const,
    (name, taxId, instNumber) =>
      `${name} - ${fastCpfCnpjMask(taxId)} - ${instNumber}`,
  );

const [postProcessDistributor, calculateDistributorCellValue] =
  configureHeaderFilterDataSource<HubEnergy.DiligenceStepMonitor>()(
    ["distributorName", "distributorTaxId"] as const,
    (name, taxId) => `${name} - ${fastCpfCnpjMask(taxId)}`,
  );

export const diligenceStepMonitorGrid = (
  setSelectedSteps: (steps: HubEnergy.DiligenceStepMonitor[]) => void,
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepMonitor> => ({
  datagrid: {
    noDataText: "Nenhum step",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("diligenceStepMonitor"),
    onSelectionChanged: (evt) => setSelectedSteps(evt.selectedRowsData),
  },
  columns: [
    {
      dataType: "string",
      caption: "Unidade consumidora",
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        [
          "consumerUnitOfficialName",
          "consumerUnitName",
          "consumerUnitInstallationNumber",
        ],
        "consumerUnitTaxId",
      ),
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.consumerUnitOfficialName}
          subtexts={[
            masks.cpfCnpj(data.consumerUnitTaxId),
            `Nº instalação: ${data.consumerUnitInstallationNumber}`,
          ]}
        />
      ),
      alignment: "left",
      calculateCellValue: calculateConsumerUnitCellValue,
      headerFilter: { dataSource: postProcessConsumerUnit },
    },
    {
      dataType: "string",
      caption: "Distribuidora",
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        ["distributorOfficialName", "distributorName"],
        "distributorTaxId",
      ),
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.distributorName}
          taxId={data.distributorTaxId}
        />
      ),
      width: "auto",
      alignment: "left",
      calculateCellValue: calculateDistributorCellValue,
      headerFilter: { dataSource: postProcessDistributor },
    },
    {
      dataField: "consumerUnitMigrationDate",
      dataType: "date",
      format: "shortDate",
      caption: "Data da migração",
      width: "auto",
    },
    {
      dataField: "diligenceStepTypeName",
      dataType: "string",
      caption: "Step",
      width: "auto",
    },
    {
      dataField: "diligenceStepStatus",
      caption: "Status do step",
      width: "auto",
      cellRender: ({ data }) => (
        <MappedStatusBadge
          map={diligenceStepStatusMap}
          status={data.diligenceStepStatus}
        />
      ),
      headerFilter: {
        dataSource: Object.entries(diligenceStepStatusMap).map(
          (item: [string, MappedStatus]) => ({
            text: item[1].text,
            value: item[0],
          }),
        ),
      },
      lookup: {
        dataSource: Object.entries(diligenceStepStatusMap).map(
          (item: [string, MappedStatus]) => ({
            text: item[1].text,
            value: item[0],
          }),
        ),
        displayExpr: "text",
        valueExpr: "value",
      },
    },
  ],
  selection: {
    mode: "multiple",
    allowSelectAll: true,
    showCheckBoxesMode: "always",
    selectAllMode: "allPages",
  },
});
