import {
  OCol,
  OLabel,
  OOption,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { contractTypeMap } from "../../utils";
import { ContractTemplateSelector } from "../contract-template-selector";

interface EstrategiaRelationshipFieldsProps {
  companyId: string | number | null;
  product: string | undefined;
}

export const EstrategiaRelationshipFields = ({
  companyId,
  product,
}: EstrategiaRelationshipFieldsProps) => {
  if (["RiscoCedente", "CCC"].includes(product || "")) {
    return (
      <>
        <div className="d-flex align-items-center mb-2">
          <OTypography type="default">Cadastro sacado</OTypography>
        </div>
        <ORow gap={3} className="ms-1">
          <OCol xs={12} md={6}>
            <ORFieldRadioGroup
              dataAction="detalhes:radio:permite_override"
              dataLabel="permite_override"
              id="permiteOverride"
              name="permiteOverride"
              label="Permite override estratégia (Parceiro define as taxas?)"
            >
              <div className="d-flex gap-4">
                <div className="d-flex gap-2">
                  <ORadio id="permiteOverride-yes" value="yes" />
                  <OLabel htmlFor="permiteOverride-yes">Sim</OLabel>
                </div>
                <div className="d-flex gap-2">
                  <ORadio id="permiteOverride-no" value="no" />
                  <OLabel htmlFor="permiteOverride-no">Não</OLabel>
                </div>
              </div>
            </ORFieldRadioGroup>
          </OCol>
        </ORow>
      </>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <OTypography type="default">Cadastro cedente</OTypography>
      </div>
      <ORow gap={3} className="ms-1">
        <OCol xs={12} md={6}>
          <ORFieldRadioGroup
            dataAction="detalhes:radio:antecipa_automaticamente"
            dataLabel="antecipa_automaticamente"
            id="antecipaAutomaticamente"
            name="antecipaAutomaticamente"
            label="Antecipa automaticamente"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="antecipaAutomaticamente-yes" value="yes" />
                <OLabel htmlFor="antecipaAutomaticamente-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="antecipaAutomaticamente-no" value="no" />
                <OLabel htmlFor="antecipaAutomaticamente-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldRadioGroup
            dataAction="detalhes:radio:permite_override"
            dataLabel="permite_override"
            id="permiteOverride"
            name="permiteOverride"
            label="Permite override estratégia (Parceiro define as taxas?)"
          >
            <div className="d-flex gap-4">
              <div className="d-flex gap-2">
                <ORadio id="permiteOverride-yes" value="yes" />
                <OLabel htmlFor="permiteOverride-yes">Sim</OLabel>
              </div>
              <div className="d-flex gap-2">
                <ORadio id="permiteOverride-no" value="no" />
                <OLabel htmlFor="permiteOverride-no">Não</OLabel>
              </div>
            </div>
          </ORFieldRadioGroup>
        </OCol>
        <OCol xs={12} md={6}>
          <ORFieldSelect
            dataAction="detalhes:select:tipo_contrato_relacionamento"
            dataLabel="tipo_contrato_relacionamento"
            id="tipoContratoRelacionamento"
            name="tipoContratoRelacionamento"
            label="Tipo de contrato"
            placeholder="Selecionar"
          >
            {Object.entries(contractTypeMap).map(([value, label]) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6}>
          <ContractTemplateSelector
            label="Template de contrato"
            companyId={companyId}
            name="contractTemplateId"
          />
        </OCol>
      </ORow>
      <div className="d-flex align-items-center mt-4 mb-2">
        <OTypography type="default">Antecipação automática</OTypography>
      </div>
      <ORow gap={3} className="ms-1">
        <OCol xs={12} md={6}>
          <ORFieldSelect
            dataAction="detalhes:select:tipo_contrato_antecipacao_automatica"
            dataLabel="tipo_contrato_antecipacao_automatica"
            id="tipoContratoRelacionamentoAntecipaAutomaticamente"
            name="tipoContratoRelacionamentoAntecipaAutomaticamente"
            label="Tipo de contrato"
            placeholder="Selecionar"
            description="Caso seja permitido ao usuário selecionar a antecipação automática, escolha o tipo de termo e o template do novo contrato que deverá ser assinado"
          >
            {Object.entries(contractTypeMap).map(([value, label]) => (
              <OOption key={value} value={value}>
                {label}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol xs={12} md={6}>
          <ContractTemplateSelector
            label="Template de contrato"
            companyId={companyId}
            name="contractTemplateIdAntecipaAutomaticamente"
          />
        </OCol>
      </ORow>
    </>
  );
};
