import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { LimitAdditionalFee } from "services/hubcreditmanager/models/types/limit/limit.types";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

const feeTypeMap = {
  Registration: "Custos de cartório",
  Report: "Laudo",
  Diligence: "Diligência",
  TAC: "TAC",
};

export const additionalFeesGrid = {
  datagrid: {
    noDataText: "Nenhuma taxa adicional encontrada",
    editing: {
      texts: {
        confirmDeleteMessage: "Deseja realmente excluir essa taxa adicional?",
      },
    },
  },
  columns: [
    {
      dataField: "amount",
      caption: "Valor",
      format: (value) => masks.currency(value, currencySymbol.BRL),
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "type",
      caption: "Tipo",
      format: (value) => getValueFromMap(feeTypeMap, value) || value,
      validationRules: [{ type: "required" }],
      lookup: {
        dataSource: [
          {
            value: "Registration",
            label: "Custos de cartório",
          },
          {
            value: "Report",
            label: "Laudo",
          },
          {
            value: "Diligence",
            label: "Diligência",
          },
          {
            value: "TAC",
            label: "TAC",
          },
        ],
        valueExpr: "value",
        displayExpr: "label",
      },
    },
    {
      dataType: "boolean",
      dataField: "isFinanced",
      caption: "Financiado",
      validationRules: [
        {
          type: "custom",
          message: "Este campo é obrigatório",
          validationCallback: ({ value }) => {
            return typeof value === "boolean";
          },
        },
      ],
    },
    {
      dataType: "boolean",
      dataField: "isUserChoice",
      caption: "Escolha do Cliente",
      validationRules: [
        {
          type: "custom",
          message: "Este campo é obrigatório",
          validationCallback: ({ value }) => {
            return typeof value === "boolean";
          },
        },
      ],
    },
    {
      cellRender: ({ component, rowIndex, row }) => {
        return (
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  label: "Editar",
                  icon: { category: "fal", icon: "fa-edit" },
                  onClick: () => {
                    component.editRow(rowIndex);
                  },
                },
                {
                  label: "Excluir",
                  icon: { category: "fal", icon: "fa-trash" },
                  onClick: () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          )
        );
      },
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitAdditionalFee>;
