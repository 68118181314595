import { OIcon, IType } from "@maestro/react";

export const buttonTypes: IType[]= [
  "primary",
  "secondary",
  "tertiary",
  "quaternary",
  "default",
  "success",
  "info",
  "warning",
  "danger",
  "light",
  "dark",
  "success-light",
  "info-light",
  "warning-light",
  "danger-light",
  "current-color",
];

export const orqIcons: React.ComponentPropsWithRef<typeof OIcon>["icon"][] = [
  "orq-unlink",
  "orq-tools-set",
  "orq-settings",
  "orq-folder-upload",
  "orq-folder-check",
  "orq-link",
  "orq-associations-on",
  "orq-bank-on",
  "orq-benefits-on",
  "orq-bulb-on",
  "orq-cash-withdraw-on",
  "orq-contract-agreement-on",
  "orq-current-wallet-on",
  "orq-disconnect-devices-on",
  "orq-exchange-on",
  "orq-financing-on",
  "orq-glassdoor",
  "orq-insurance-on",
  "orq-invest-on",
  "orq-operationalization-on",
  "orq-order-sent",
  "orq-payroll-on",
  "orq-pizza-chart-on",
  "orq-products-on",
  "orq-question-on",
  "orq-school-on",
  "orq-settings-on",
  "orq-tv-pay-on",
  "orq-user-clients-on",
  "orq-user-manage-on",
  "orq-wallet-virtual-on",
  "orq-token",
  "orq-renegociations",
  "orq-connection-error",
  "orq-chevron-down",
  "orq-chevron-left",
  "orq-chevron-right",
  "orq-chevron-up",
  "orq-private-pension",
  "orq-add-circle",
  "orq-bookmark-on",
  "orq-card-on",
  "orq-drag-drop",
  "orq-home-on",
  "orq-investment-fund",
  "orq-language",
  "orq-linkedin",
  "orq-nft",
  "orq-podcast",
  "orq-remove-circle",
  "orq-save",
  "orq-spotify",
  "orq-support-on",
  "orq-telegram",
  "orq-twitter",
  "orq-add-minus",
  "orq-add-plus",
  "orq-aggressive",
  "orq-agribusiness",
  "orq-agriculture",
  "orq-agro",
  "orq-airline",
  "orq-android",
  "orq-animal",
  "orq-anticipate",
  "orq-apple",
  "orq-archive",
  "orq-associations",
  "orq-attach",
  "orq-attention",
  "orq-automatic-transfer",
  "orq-bank",
  "orq-bank-branch",
  "orq-bank-slip",
  "orq-batch",
  "orq-batch-transfer",
  "orq-beauty",
  "orq-benefits",
  "orq-bills-receive",
  "orq-bitcoin",
  "orq-block",
  "orq-booklet",
  "orq-bookmark",
  "orq-borrower-services",
  "orq-bulb",
  "orq-camera",
  "orq-cancel",
  "orq-cancel-orders",
  "orq-cancel-purchase",
  "orq-candle-chart",
  "orq-capital-goods",
  "orq-car",
  "orq-card",
  "orq-car-fine",
  "orq-car-parking",
  "orq-car-rental",
  "orq-cash-withdraw",
  "orq-celebration",
  "orq-change-user",
  "orq-chargeback",
  "orq-charging-rule",
  "orq-chat",
  "orq-check",
  "orq-checking-copy",
  "orq-chemicals",
  "orq-chip",
  "orq-cinema",
  "orq-claim-notice",
  "orq-clock",
  "orq-close",
  "orq-coin",
  "orq-commission",
  "orq-competition",
  "orq-configuration",
  "orq-conservative",
  "orq-construction",
  "orq-contactless",
  "orq-contactless-password",
  "orq-content-and-services",
  "orq-contract-agreement",
  "orq-contract-signature",
  "orq-corporate-floors",
  "orq-cruise",
  "orq-cryptocoin",
  "orq-culture",
  "orq-current-wallet",
  "orq-deposit",
  "orq-digital-content",
  "orq-direct-sale",
  "orq-disconnect-devices",
  "orq-dislike",
  "orq-doc-chargeback",
  "orq-doc-in",
  "orq-doc-jpg",
  "orq-doc-out",
  "orq-doc-pdf",
  "orq-doc-scheduled",
  "orq-arrow-down",
  "orq-download",
  "orq-drink",
  "orq-drugstore",
  "orq-duty-free",
  "orq-edit-copy",
  "orq-edit-pencil",
  "orq-edit-trash",
  "orq-eletronics",
  "orq-email",
  "orq-empty",
  "orq-empty-search",
  "orq-endorsement",
  "orq-energy",
  "orq-environment",
  "orq-ethereum",
  "orq-ex-bank",
  "orq-exchange",
  "orq-expand-view",
  "orq-external-link",
  "orq-facebook",
  "orq-fashion",
  "orq-favorite",
  "orq-favorite-on",
  "orq-fee",
  "orq-fees-fines",
  "orq-fgc",
  "orq-file",
  "orq-financial-contribution",
  "orq-financing",
  "orq-fixed-income",
  "orq-flash-auto",
  "orq-flash-off",
  "orq-flash-on",
  "orq-fuel",
  "orq-future",
  "orq-gambling",
  "orq-games",
  "orq-google",
  "orq-government",
  "orq-gym",
  "orq-health-and-education",
  "orq-high-financial-reserve",
  "orq-historic",
  "orq-home",
  "orq-home-and-decor",
  "orq-hospital",
  "orq-hotel",
  "orq-hotels",
  "orq-housing",
  "orq-hybrids",
  "orq-id-face",
  "orq-id-facial-recognition",
  "orq-id-touch",
  "orq-image",
  "orq-income",
  "orq-industry",
  "orq-info",
  "orq-infrastructure",
  "orq-instagram",
  "orq-installments-total",
  "orq-insurance",
  "orq-insured",
  "orq-international",
  "orq-invest",
  "orq-invoice",
  "orq-invoice-anticipate",
  "orq-invoice-anticipation",
  "orq-it",
  "orq-jewelry",
  "orq-arrow-left",
  "orq-like",
  "orq-like-on",
  "orq-limitemais",
  "orq-line-chart",
  "orq-lock",
  "orq-locker-close",
  "orq-locker-open",
  "orq-logoff",
  "orq-lottery",
  "orq-low-reserve",
  "orq-management",
  "orq-medicine",
  "orq-medium-reserve",
  "orq-metals",
  "orq-minimize-view",
  "orq-mining",
  "orq-moderate",
  "orq-money",
  "orq-money-application",
  "orq-money-in",
  "orq-money-out",
  "orq-more-options",
  "orq-music",
  "orq-new-claim",
  "orq-non-motorized",
  "orq-notification",
  "orq-notification-on",
  "orq-offer-book",
  "orq-online",
  "orq-open-banking",
  "orq-opening",
  "orq-operationalization",
  "orq-order-received",
  "orq-others",
  "orq-outlook",
  "orq-page-first",
  "orq-page-last",
  "orq-paid-back",
  "orq-pause",
  "orq-payment-bills",
  "orq-payment-scheduled",
  "orq-payment-tribute",
  "orq-payroll",
  "orq-pets",
  "orq-pin",
  "orq-pix",
  "orq-pix-key",
  "orq-pizza-chart",
  "orq-play",
  "orq-play-next",
  "orq-play-previous",
  "orq-points",
  "orq-policy",
  "orq-portability",
  "orq-printer",
  "orq-products",
  "orq-profitability-negative",
  "orq-profitability-positive",
  "orq-property",
  "orq-property-secured",
  "orq-proposal",
  "orq-public-utility",
  "orq-purchase-and-sales",
  "orq-qr-code",
  "orq-question",
  "orq-rate-off",
  "orq-rate-on",
  "orq-read-bank-slip",
  "orq-receipt",
  "orq-receivables",
  "orq-recent",
  "orq-reclaim-money",
  "orq-refresh",
  "orq-remote-file",
  "orq-remunerated-balance",
  "orq-rental",
  "orq-research",
  "orq-restaurant",
  "orq-retail",
  "orq-rewards",
  "orq-arrow-right",
  "orq-rural",
  "orq-salary",
  "orq-sanitation",
  "orq-schedule",
  "orq-scheduled",
  "orq-schedule-recurring",
  "orq-school",
  "orq-services",
  "orq-share",
  "orq-share-android",
  "orq-shipping",
  "orq-shipping-remittance",
  "orq-smile",
  "orq-solar-credit",
  "orq-sound-off",
  "orq-sound-on",
  "orq-sports",
  "orq-stack-burger-menu",
  "orq-stack-burger-menu-plus",
  "orq-stack-details",
  "orq-stack-list",
  "orq-status-disapproved",
  "orq-status-error",
  "orq-status-success",
  "orq-status-warning",
  "orq-stripe",
  "orq-subscription",
  "orq-supermarket",
  "orq-supplies",
  "orq-support",
  "orq-surety",
  "orq-swap-circle",
  "orq-tag",
  "orq-tariff",
  "orq-ted-chargeback",
  "orq-ted-in",
  "orq-ted-out",
  "orq-ted-scheduled",
  "orq-tef-chargeback",
  "orq-tef-in",
  "orq-tef-out",
  "orq-tef-scheduled",
  "orq-telecomunication",
  "orq-telephony",
  "orq-theater",
  "orq-timer",
  "orq-tools-analysis",
  "orq-tools-filter",
  "orq-tools-search",
  "orq-tools-tools",
  "orq-tools-widgets",
  "orq-touch-screen",
  "orq-town-hall",
  "orq-transaction-limit",
  "orq-transfer",
  "orq-transportation",
  "orq-travel",
  "orq-turn-camera",
  "orq-tv-pay",
  "orq-unified-invoice",
  "orq-arrow-up",
  "orq-upload",
  "orq-user-add",
  "orq-user-clients",
  "orq-user-login",
  "orq-user-manage",
  "orq-user-remove-access",
  "orq-vacation",
  "orq-view-pass",
  "orq-visibility-on",
  "orq-visibility-off",
  "orq-voucher",
  "orq-wallet-comparison",
  "orq-wallet-virtual",
  "orq-wellness",
  "orq-whatsapp",
  "orq-wholesale",
  "orq-widget-collapse",
  "orq-widget-expansion",
  "orq-youtube",
];
