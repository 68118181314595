import {
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { orqIcons } from "../../../../../../configuracoes/workflow-configuration.utils";
import { useProcessorConfigChannelsPageContent } from "./processor-config-channel.hook";

export const ProcessorConfigChannelsPage = () => {
  const {
    form,
    isWfEntryWatch,
    channelList,
    loading,
    workflowConfigViews,
    updateChannel,
  } = useProcessorConfigChannelsPageContent();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Canais"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={updateChannel}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <ORow rowGap={4}>
            <OCol sm={12}>
              <ORFieldCheckboxGroup id="isWfEntry" name="isWfEntry">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="entry-checkbox" value="1" />
                  <OLabel htmlFor="entry-checkbox">
                    Entrada de dados inicial do workflow?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            {isWfEntryWatch?.length > 0 && (
              <>
                <OCol md={6} sm={12}>
                  <ORFieldSelectMultiple
                    id="entryChannels"
                    name="entryChannels"
                    label="Canais"
                    labelSize="md"
                    key={channelList?.map((c) => c.id).join("")}
                  >
                    {channelList?.map((c) => (
                      <OOptionMultiple
                        key={`processor-config-channel-option-${c.id}`}
                        value={c.id.toString()}
                      >
                        {c.name}
                      </OOptionMultiple>
                    ))}
                  </ORFieldSelectMultiple>
                </OCol>
                <OCol md={6} sm={12}>
                  <ORFieldSelectMultiple
                    id="entryViews"
                    name="entryViews"
                    label="Ação disponível em qual view?"
                    labelSize="md"
                    key={workflowConfigViews
                      ?.map((wfView) => wfView.id)
                      .join("")}
                  >
                    {workflowConfigViews?.map((wfView) => (
                      <OOptionMultiple key={wfView.id} value={wfView.view.type}>
                        {wfView.view.name}
                      </OOptionMultiple>
                    ))}
                  </ORFieldSelectMultiple>
                </OCol>
                <OCol md={6} sm={12}>
                  <ORFieldInput
                    id="cardActionButtonDescription"
                    name="cardActionButtonDescription"
                    tag="text"
                    label="Nome no botão de ação"
                    labelSize="md"
                  />
                </OCol>
                <OCol md={6} sm={12}>
                  <SelectSearchField
                    id="icon"
                    name="icon"
                    label="Ícone"
                    labelSize="md"
                    options={orqIcons.map((icon) => ({
                      label: icon,
                      value: icon,
                    }))}
                  />
                </OCol>
                <OCol md={6} sm={12}>
                  <ORFieldCheckboxGroup id="showOnActions" name="showOnActions">
                    <div className="d-flex align-items-center gap-2">
                      <OCheckbox
                        size="xs"
                        id="showOnActions-checkbox"
                        value="1"
                      />
                      <OLabel htmlFor="showOnActions-checkbox">
                        Mostrar em ações?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
                <OCol md={6} sm={12}>
                  <ORFieldCheckboxGroup id="showOnTabs" name="showOnTabs">
                    <div className="d-flex align-items-center gap-2">
                      <OCheckbox size="xs" id="showOnTabs-checkbox" value="1" />
                      <OLabel htmlFor="showOnTabs-checkbox">
                        Mostrar em abas?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
                <OCol md={6} sm={12}>
                  <ORFieldCheckboxGroup id="showOnCards" name="showOnCards">
                    <div className="d-flex align-items-center gap-2">
                      <OCheckbox size="xs" id="showOnTabs-checkbox" value="1" />
                      <OLabel htmlFor="showOnTabs-checkbox">
                        Mostrar em cards?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
              </>
            )}
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
