import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  ORFieldRadioGroup,
  ORadio,
  ORow,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";

export const DeskConfigOfficerForm = () => {
  const {
    callService: getOfficersOptions,
    loading,
    value,
    hasError,
  } = useServiceCall(service.onboarding.getOfficers);

  useEffect(() => {
    getOfficersOptions();
  }, [getOfficersOptions]);

  const options = useMemo(
    () =>
      value?.map((officer) => ({
        label: `${officer.cge} - ${fastCpfCnpjMask(officer.taxId)} - ${
          officer.name
        }`,
        value: officer.cge,
      })) ?? [],
    [value],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <SelectSearchField
                id="officerCge"
                name="officerCge"
                options={options}
                label="Officers"
                placeholder="Buscar"
              />
              {loading && <OLoader className="d-block" size="sm" />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getOfficersOptions()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldRadioGroup
                id="isDefault"
                name="isDefault"
                label="isDefault"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
