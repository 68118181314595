import { useCallback, useEffect, useMemo, useState } from "react";
import { isAxiosError } from "axios";
import omitBy from "lodash/omitBy";
import { useForm } from "react-hook-form";
import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { service } from "services";
import {
  GetOwnerResponse,
  OwnersInState,
  OwnersTotals,
} from "services/moana/models";
import { logger } from "utils/logger";
import { helpers } from "@maestro/utils";
import { walletsFilterFormDefaultValues } from "./_compose/wallets-filter/wallets-filter.form";
import { WalletsFilterForm } from "./_compose/wallets-filter/wallets-filter.types";
import { buildQuery } from "pages/duplicatas/components/wallet-details/wallet-details.hook";

export const useWallets = () => {
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [ownersInState, setOwnersInState] = useState<OwnersInState>();
  const [ownersTotals, setOwnersTotals] = useState<OwnersTotals>();
  const form = useForm({
    defaultValues: walletsFilterFormDefaultValues,
  });
  const { reset } = form;
  const [search, setSearch] = useState<WalletsFilterForm>(
    walletsFilterFormDefaultValues,
  );

  const [sortQuery, setSortQuery] = useState("+name");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<GetOwnerResponse["data"][number]>(() => {
        return service.moana
          .getOwner(
            buildQuery({
              limit: rows,
              offset: (page - 1) * rows,
              ...(!!sortQuery && { sort: sortQuery }),
              ...search,
            }),
          )
          .then(({ data }) => data.data)
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar carteiras.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [page, rows, search, sortQuery],
  );

  const onSubmit = (values: WalletsFilterForm) => {
    const filledValues = omitBy<WalletsFilterForm>(values, (val) => !val);
    const hasValues = Object.values(filledValues).length;
    if (hasValues) {
      setSearch(filledValues);
    } else {
      setSearch(walletsFilterFormDefaultValues);
    }
  };

  const resetFilter = () => {
    reset();
    setSearch(walletsFilterFormDefaultValues);
  };

  const exportData = useCallback(async () => {
    try {
      const { data } = await service.moana.exportOwner(
        buildQuery({
          ...(!!sortQuery && { sort: sortQuery }),
          ...search,
        }),
      );
      helpers.downloadBase64(data, "carteiras.xlsx");
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [search, sortQuery]);

  const exportDataEmail = useCallback(async () => {
    try {
      OToastManager.info("Enviando planilha por e-mail.");
      await service.moana.exportOwnerEmail();
      OToastManager.success("E-mail enviado com sucesso. Em poucos minutos estará na sua Caixa de Entrada.");
    } catch {
      OToastManager.danger("Não foi possível enviar o arquivo por e-mail.");
    }
  }, []);

  const getTotals = useCallback(async () => {
    try {
      const { data } = await service.moana.getTotals(buildQuery(search));
      setTotalItems(data.totalItems);
      setOwnersInState(data.ownersInState);
      setOwnersTotals(data.totals);
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [search]);

  useEffect(() => {
    getTotals();
  }, [getTotals]);

  return {
    dataSource,
    exportData,
    exportDataEmail,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    form,
    onSubmit,
    resetFilter,
    setSortQuery,
    ownersInState,
    ownersTotals,
  };
};
