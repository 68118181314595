import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const dataSource =
  dataSourceCustomStoreGenerator<HubPartners.Admin.PartnershipType>(() =>
    service.hubPartners
      .getPartnershipTypes()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os tipos de parceria";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const partnershipTypesGrid: ODataGridGeneratorConfig<HubPartners.Admin.PartnershipType> =
  {
    datagrid: {
      noDataText: "Nenhum tipo de parceria encontrado.",
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "code",
        caption: "Código",
        sortOrder: "asc",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "segmentFilterCode",
        caption: "Código de Segmento de Filtro",
      },
      {
        caption: "Ações",
        allowExporting: false,
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: {
                  category: "orq",
                  icon: "orq-edit-pencil",
                },
                label: "Editar",
                visible: !!data.uuid,
                route: `${
                  corporateRouter.routes.parceiros.product.configurations
                    .partnershipTypes.edit.path
                }?data=${encodeURIComponent(
                  JSON.stringify({
                    uuid: data.uuid,
                    name: data.name,
                    code: data.code,
                    segmentFilterCode: data.segmentFilterCode,
                  } satisfies HubPartners.Admin.PartnershipType),
                )}`,
                role: roles.parceiros.product.editPartnershipType.role,
              },
            ]}
          />
        ),
      },
    ],
  };
