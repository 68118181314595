import { useTheme } from "contexts/theme";
import { Pie } from "react-chartjs-2";

// register used components
// eslint-disable-next-line import/order, import/newline-after-import
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
Chart.register(ArcElement);
Chart.register(Legend);
Chart.register(Tooltip);

const chartConfig = {
  availableQuantity: {
    label: "Disponíveis",
    backgroundColor: {
      dark: "rgba(252, 255, 78, 0.8)",
      light: "rgba(243, 246, 18, 0.4)",
    },
    borderColor: {
      dark: "rgba(252, 255, 78, 1)",
      light: "rgba(165, 167, 25, 1)",
    },
  },
  hiredQuantity: {
    label: "Contratadas",
    backgroundColor: {
      dark: "rgba(79, 146, 255, 0.8)",
      light: "rgba(78, 146, 255, 0.4)",
    },
    borderColor: {
      dark: "rgba(79, 146, 255, 1)",
      light: "rgba(3, 42, 105, 1)",
    },
  },
};

interface TotalOffersPieChartProps {
  availableQuantity: number;
  hiredQuantity: number;
}

export const TotalOffersPieChart = ({
  availableQuantity,
  hiredQuantity,
}: TotalOffersPieChartProps) => {
  const { theme } = useTheme();

  return (
    <Pie
      data={{
        labels: [
          chartConfig.availableQuantity.label,
          chartConfig.hiredQuantity.label,
        ],
        datasets: [
          {
            label: "Quantidade de Ofertas",
            data: [availableQuantity, hiredQuantity],
            backgroundColor: [
              chartConfig.availableQuantity.backgroundColor[theme],
              chartConfig.hiredQuantity.backgroundColor[theme],
            ],
            borderColor: [
              chartConfig.availableQuantity.borderColor[theme],
              chartConfig.hiredQuantity.borderColor[theme],
            ],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};
