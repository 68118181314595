import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import {
  IndicationFields,
  OriginatorInfoFields,
  CategorizationsFields,
} from "../../../../components/originator";
import { useNewOriginator } from "./new-originator.hook";

export const NewOriginator = () => {
  const { form, loading, onSubmit } = useNewOriginator();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Cadastro de originador"
          description="Preencha o formulário abaixo para iniciar o cadastro."
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Cadastrar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <div className="d-flex flex-column gap-3">
          <OCard rounded>
            <OCardBody>
              <OriginatorInfoFields />
            </OCardBody>
          </OCard>
          <OCard rounded>
            <OCardHeader>
              <OTypography type="default" size="lg" weight="500">
                Categorização
              </OTypography>
              <OTypography type="dark-60">
                Selecione qual é o tipo deste parceiro e quais produtos ele
                poderá oferecer a seus clientes.
              </OTypography>
            </OCardHeader>
            <OCardBody>
              <CategorizationsFields />
            </OCardBody>
          </OCard>
          <OCard rounded>
            <OCardHeader>
              <OTypography type="default" size="lg" weight="500">
                Indicação
              </OTypography>
              <OTypography type="dark-60">
                Nesta etapa, caso o originador tenha sido indicado, selecione o
                indicador.
              </OTypography>
            </OCardHeader>
            <OCardBody>
              <IndicationFields />
            </OCardBody>
          </OCard>
        </div>
      </FormProvider>
    </FormTemplate>
  );
};
