import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { gridStorage } from "utils/storage";
import { service } from "services";
import { RejectedOptins } from "services/recebiveis-cartao/models";
import { ODataRequest } from "services/service.types";
import { masks } from "@maestro/utils";
import { OToastManager } from "@maestro/core";
import { RetryButton } from "./retry-button.component";

const maskReason = (value: string | null | undefined) => {
  if (value?.includes("ERRC0080"))
    return "Usuário final recebedor ou titular do recebível informado já autorizado";
  if (value?.includes("ERRC0081")) return "Estabelecimento sem credenciamento";
  if (value?.includes("ERRC0121"))
    return "Data de assinatura maior que a data de início do OPT-IN";
  if (value?.includes("ERRC0196")) return "Base centralizada indisponível";
  return `Motivo desconhecido: ${value}`;
};

export const rejectedOptinsGrid = {
  datagrid: {
    noDataText: "Nenhuma empresa",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    stateStoring: gridStorage("rejected-optins"),
    dataSource: dataSourceCustomStoreGenerator<RejectedOptins>((loadOptions) =>
      service.recebiveisCartao
        .getRejectedOptins(loadOptions as ODataRequest)
        .then(({ data }) => ({
          data: data.data ?? [],
        }))
        .catch(() => {
          const errorMessage = "Não foi possível buscar as empresas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    ),
  },
  columns: [
    {
      dataField: "companyName",
      dataType: "string",
      caption: "Empresa",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => data.companyName || "Empresa não registrada",
    },
    {
      dataField: "taxId",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      allowHeaderFiltering: false,
    },
    {
      dataField: "reason",
      dataType: "string",
      caption: "Motivo",
      cellRender: ({ data }) => maskReason(data.reason),
      allowHeaderFiltering: false,
    },
    {
      dataField: "insertedAt",
      dataType: "datetime",
      caption: "Inserido em",
      allowHeaderFiltering: false,
    },
    {
      dataField: "updatedAt",
      dataType: "datetime",
      caption: "Atualizado em",
      allowHeaderFiltering: false,
    },
    {
      caption: "Retentar",
      cellRender: ({ data }) => {
        const newParams = {
          taxId: data.taxId,
          companyName: data.companyName,
        };

        return <RetryButton newParams={newParams} />;
      },
    },
  ],
} satisfies ODataGridGeneratorConfig<RejectedOptins>;
