import { PostAdminOperationsResponse } from "services/quickfin/models";
import { CardReceivableWithOverrideMaturityAmount } from "../../intubate-operations.types";
import { ReceivableWithError } from "./result.types";

export const buildResultsDataSource = (
  cardReceivables: CardReceivableWithOverrideMaturityAmount[],
  createdOperations: PostAdminOperationsResponse,
) => {
  const displayReceivables: ReceivableWithError[] = [...cardReceivables];

  createdOperations.forEach((operation) => {
    const errorMessage = operation.error;

    if (!errorMessage) return;

    operation.receivables.forEach((operationReceivable) => {
      const displayReceivable = displayReceivables.find(
        (receivable) => receivable.ReceivableId === operationReceivable.id,
      );

      if (displayReceivable) displayReceivable.error = errorMessage;
    });
  });

  return displayReceivables;
};

export const errorMap = {
  "ListCantBeEmpty:Operation.Trades": "Taxa não encontrada",
};
