import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, eligibilityOffersGrid } from "./eligibility-offers.grid";

export const EligibilityOffers = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Ofertas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={eligibilityOffersGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
