import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { exchangeOdataPreTicketsGrid } from "pages/cambio/components/odata/exchange-odata-pre-tickets.grid";
import { CambioOrdensByIdRouteParams } from "pages/cambio/routes/cambio.route-params";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";

export const ExchangeProductContingencyOrderPreTicketsDetailsPage = () => {
  const { id } = useParams<CambioOrdensByIdRouteParams>();

  if (!id) throw new Error("No id");

  const gridRef = useGridRef();

  const { mapSymbol } = useCurrencies();

  const grid = useMemo(
    () => exchangeOdataPreTicketsGrid(mapSymbol, ["OrderId", "=", +id]),
    [id, mapSymbol],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pré-Tickets da Ordem" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
