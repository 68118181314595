import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useMemo, useState } from "react";
import { service } from "services";
import { NotificationOperation } from "services/shark/models/responses";
import { logger } from "utils/logger";

export const useCobrancasEstimulos = () => {
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<NotificationOperation>((loadOptions) => {
        return service.shark
          .getOperations({
            ...loadOptions,
            take: rows,
            skip: (page - 1) * rows,
          } as any)
          .then(({ data }) => {
            setTotalItems(data.total_count);
            return {
              data: data.data,
              totalCount: data.total_count,
            };
          })
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar estímulos.";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [page, rows],
  );

  return {
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
  };
};
