import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { cardHiringFields } from "../../card-hiring-form.form";

interface IssueDateIdFieldProps {
  data?: BankingHubCards.IssueDate[];
}

export const IssueDateIdField = ({ data }: IssueDateIdFieldProps) => {
  const { setValue } = useFormContext();

  return (
    <OCol xs={12} md={6} align="center">
      <ORFieldSelect
        {...cardHiringFields.issueDateId}
        key={data?.length}
        readonly={!data?.length}
      >
        {data?.map(({ id, day }) => {
          return (
            <OOption
              key={id}
              value={id}
              onClick={() => {
                setValue("issueDateDay", day);
              }}
            >
              Todo dia {day}
            </OOption>
          );
        })}
      </ORFieldSelect>
    </OCol>
  );
};
