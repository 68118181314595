import { validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";

export interface LimitGuarantorsFormValues {
  type: string;
  identification: string;
}

export const limitGuarantorsFormGrid = (
  onChange: (contractTemplates: LimitGuarantorsFormValues[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantias - avalistas adicionada",
      dataSource: [],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false,
      },
      onRowInserted: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowUpdated: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
    },
    columns: [
      {
        dataField: "type",
        caption: "Tipo",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: [
            {
              value: "SIMPLES",
              label: "Simples",
            },
            {
              value: "COMPLETO",
              label: "Completo",
            },
          ],
          valueExpr: "value",
          displayExpr: "label",
        },
      },
      {
        dataField: "identification",
        caption: "CPF",
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CPF inválido",
            validationCallback: (option) => {
              return validators.cpf(option.value);
            },
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<LimitGuarantorsFormValues>);
