import { masks } from "@maestro/utils";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ContractSignatoriesFormFields } from "../_compose";
import { useEditContractSignatories } from "./edit-contract-signatories.hook";

export const EditContractSignatories = () => {
  const {
    fetch,
    fetchHasError,
    fetchLoading,
    form,
    initialValues,
    submit,
    submitLoading,
    taxId,
  } = useEditContractSignatories();

  return (
    <FormTemplate
      pageTitle={<PageTitle title={`Editar - ${masks.cpfCnpj(taxId)}`} />}
      actions={
        <LoadingButton
          dataAction="acoes:botao:salvar"
          dataLabel="salvar"
          loading={submitLoading}
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <DefaultErrorComponent
              title="Erro ao carregar os dados"
              callback={fetch}
            />
          }
          value={initialValues !== undefined}
          render={() => (
            <ContractSignatoriesFormFields form={form} disableTaxId />
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
