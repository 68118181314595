import { StepAnalysisProvider } from "./step-analysis.context";
import { ConsumerUnitHeader } from "./_compose";
import {
  StepAnalysisStageComponent,
  StepAnalysisStageSelector,
} from "./_stages";

const StepAnalysisContentComponent = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <ConsumerUnitHeader />
      <StepAnalysisStageSelector />
      <StepAnalysisStageComponent />
    </div>
  );
};

interface StepAnalysisContentProps {
  stepAnalysis: HubEnergy.DiligenceStepAnalysisResponse;
  refetch: () => void;
}

export const StepAnalysisContent = ({
  stepAnalysis,
  refetch,
}: StepAnalysisContentProps) => (
  <StepAnalysisProvider stepAnalysis={stepAnalysis} refetch={refetch}>
    <StepAnalysisContentComponent />
  </StepAnalysisProvider>
);
