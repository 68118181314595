import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge"
import { ValidateOperation } from "../../intubate-operations.types";

export const validateGrid: ODataGridGeneratorConfig<ValidateOperation> = {
  datagrid: {
    noDataText: "Vazio.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "info",
      dataType: "string",
      caption: "Info",
      allowHeaderFiltering: false,
    },
    {
      dataField: "type",
      dataType: "string",
      caption: "Tipo",
      allowHeaderFiltering: false,
    },
    {
      dataField: "valid",
      dataType: "boolean",
      caption: "Válido",
      allowHeaderFiltering: true,
      cellRender: ({ data: { valid } }) => (
        <StatusBadge
          type={valid ? "success" : "danger"}
        >
          {valid ? "ok" : "inválido"}
        </StatusBadge>
      ),
    },
  ],
};
