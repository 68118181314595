import { useContext, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { WorkflowContext } from "../../../../../../../../../../../components/hooks/use-workflow.context";
import { getOutputComponentsIds } from "../../processor-config-monitoring.utils";
import { ProcessorOutputsComponentProps } from "./processor-outputs.types";

export const useProcessorOutputsComponent = ({
  workflowConfigView,
}: ProcessorOutputsComponentProps) => {
  if (!workflowConfigView) throw new Error("WorkflowConfigView not found");

  const {
    bigNumbers: { value: bigNumbers },
    configurableStatus: { value: configurableStatus },
  } = useContext(WorkflowContext);

  const { setValue } = useFormContext();

  const resetValuesRef = useRef(false);

  useEffect(() => {
    if (!workflowConfigView || !!resetValuesRef.current) return;

    resetValuesRef.current = true;

    setTimeout(() => {
      workflowConfigView.processorOutputConfig.forEach((output) => {
        const statusIds = output.status?.length > 0 ? output.status[0].id ?? 0 : 0;
        const bigNumberIds = output.bigNumbers?.map((b) => b.id.toString());
        const outputComponentIds = getOutputComponentsIds(
          workflowConfigView.workflowConfigView,
          output.id,
        );

        setValue(outputComponentIds.type, output.type);
        setValue(outputComponentIds.status, statusIds);

        if (bigNumberIds?.length)
          setValue(outputComponentIds.bigNumber, bigNumberIds);
      });
    }, 1000);
  }, [workflowConfigView, setValue]);

  return { bigNumbers, configurableStatus };
};
