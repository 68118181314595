import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddRestrictionsList } from "./add.hook";
import { productTypeMap } from "./add.utils";

export const AddRestrictionsList = () => {
  const { form, loading, watchTipoProduto, restrictionOptions, submit } = useAddRestrictionsList();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Adicionar empresa"
          description="Listagem das empresas que possuem algum tipo de restrição"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="lista_restricoes:botao:adicionar"
          dataLabel="adicionar"
          onClick={submit}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute />}
        <FormProvider {...form}>
          <form>
            <ORow>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldInput
                  tag="text"
                  id="companyCnpj"
                  name="companyCnpj"
                  dataAction="lista_restricoes:input:cnpj"
                  dataLabel="cnpj"
                  label="CNPJ"
                />
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldSelect
                  id="tipoProduto"
                  name="tipoProduto"
                  dataAction="lista_restricoes:input:tipo_do_produto"
                  dataLabel="tipo_do_produto"
                  label="Tipo do produto"
                  placeholder="Selecione um produto"
                  value={watchTipoProduto}
                >
                  {productTypeMap.map((product) => (
                    <OOption
                      key={product.name}
                      id={product.name}
                      value={product.name}
                    >
                      {product.title}
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol xs={12} md={6} xl={4}>
                <ORFieldSelect
                  id="name"
                  name="name"
                  dataAction="lista_restricoes:input:restricao"
                  dataLabel="restricao"
                  label="Restrição"
                  placeholder="Selecione uma restrição"
                >
                  {restrictionOptions.map(option => 
                    <OOption value={option}>
                      {option}
                    </OOption>
                  )}
                </ORFieldSelect>
              </OCol>
            </ORow>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
