import { UseFormReturn } from "react-hook-form";
import { AccountType } from "services/bankinghub/models/types";

export interface UpdateContactType {
  form: UseFormReturn<FormValuesType, any>;
}

export interface FormValuesType {
  bank?: string;
  ispb: string;
  branch: string;
  account: string;
  account_digit: string;
  account_type: AccountType;
  is_disbursement_account: "S" | "N";
}

export const MapperAccountType: Record<AccountType, string> = {
  [AccountType.CC]: "Conta corrente",
  [AccountType.PP]: "Conta poupança",
  [AccountType.CD]: "Conta depósito",
  [AccountType.CP]: "Conta de crédito",
  [AccountType.CG]: "Conta garantida",
  [AccountType.CI]: "Conta investimento",
  [AccountType.PG]: "Conta pagamento",
  [AccountType.CS]: "Conta salário",
};
