import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { AllowListNoBiometryDataItem } from "services/gateholder/models/responses";

export const grid: ODataGridGeneratorConfig<AllowListNoBiometryDataItem> = {
  datagrid: {
    noDataText: "Nenhum CPF encontrado.",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      allowDeleting: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      dataField: "tax_id",
      caption: "CPF",
      format: (value) => masks.cpf(value),
    },
    { dataField: "justification", caption: "Justificativa" },
    { dataField: "added_by", caption: "Criado por", allowEditing: false },
    {
      dataField: "inserted_at",
      caption: "Criado em",
      dataType: "date",
      format: "shortDateShortTime",
      alignment: "center",
      sortOrder: "desc",
      allowEditing: false,
    },
  ],
};
