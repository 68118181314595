import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

export const removeImportDataConfirmationModalId = (id: number) =>
  `remove-import-data-confirmation-modal-${id}`;

interface RemoveImportDataConfirmationModalProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem;
  onConfirm: () => void;
}

export const RemoveImportDataConfirmationModal = ({
  importData,
  onConfirm,
}: RemoveImportDataConfirmationModalProps) => {
  const modalId = removeImportDataConfirmationModalId(importData.id);

  return (
    <OModal id={modalId} position="center" size="sm" {...datagridModalFix}>
      <OModalHeader>
        <OTypography size="lg">Remover importação</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography size="md" style={{ whiteSpace: "normal" }}>
          Tem certeza que deseja remover a importação id {importData.id} e
          agrupamento {importData.groupingId}?
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <OButton
            type="danger"
            onClick={() => {
              onConfirm();
              modalManager.hide(modalId);
            }}
          >
            Remover
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
