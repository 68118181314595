import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { ValidationRule } from "devextreme/common";
import { LimitInvoice } from "services/hubcreditmanager/models/types/limit/limit.types";
import { InvoiceFormValues } from "./editar/invoice-form.schemas";

type InvoiceProduct = LimitInvoice["invoiceProducts"][number];

export interface InvoiceProductsFormValues extends InvoiceFormValues {
  invoiceProducts: InvoiceProduct[];
}

const validatePositiveNumber: ValidationRule = {
  type: "custom",
  message: "Este campo deve conter um número maior do que 0.",
  ignoreEmptyValue: true,
  validationCallback: ({ value }) => {
    return value >= 0;
  },
};

const validateDecimals: ValidationRule = {
  type: "custom",
  message: "Este valor não pode conter mais que 2 dígitos decimais.",
  ignoreEmptyValue: true,
  validationCallback: ({ value }) => {
    return (value?.toString().split(".")?.[1]?.length || 0) <= 2;
  },
};

export const invoiceProductsGrid = (
  readonly?: boolean,
  onChange?: (invoiceProducts: InvoiceProduct[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum produto foi registrado.",
      editing: {
        allowAdding: !readonly,
        allowDeleting: !readonly,
        allowUpdating: !readonly,
      },
      onRowInserted: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowUpdated: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
    },
    columns: [
      {
        dataField: "code",
        caption: "Código",
        sortOrder: "asc",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "manufacturer",
        caption: "Fabricante",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "description",
        caption: "Descrição",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "quantity",
        caption: "Quantidade",
        validationRules: [{ type: "required" }, validatePositiveNumber],
      },
      {
        dataField: "unitPrice",
        caption: "Preço unitário",
        format: "R$ #,##0.00",
        validationRules: [validatePositiveNumber, validateDecimals],
      },
      {
        dataField: "amount",
        caption: "Valor",
        format: "R$ #,##0.00",
        validationRules: [validatePositiveNumber, validateDecimals],
      },
    ],
  } satisfies ODataGridGeneratorConfig<InvoiceProduct>);
