import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoLeadsRouteQueryParams } from "../../../../../../routes/workflow.route-params";
import { workflowLeadsBatchGrid } from "./workflow-leads-batch.grid";

export const BatchRequestWorkflowLeadsPage = () => {
  const { value } =
    useParseFromSearchParams<WorkflowProdutoLeadsRouteQueryParams>("data");

  const { id } = useParams<{ id?: string }>();

  const gridRef = useGridRef();

  const grid = useMemo(
    () => workflowLeadsBatchGrid(Number(id), value?.selectedWorkflowConfig),
    [value?.selectedWorkflowConfig, id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de leads processados" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </GridTemplate>
  );
};
