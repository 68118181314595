import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { useEstrategiaComercial } from "../_compose";
import {
  EstrategiaRelationshipForm,
  LoadedDataValues,
} from "../../../../../components";

export const useEstrategiaComercialRelationship = () => {
  const {
    estrategia,
    estrategiaId,
    getCompanyEstrategia,
    hasError,
    loading,
    quickfinId,
  } = useEstrategiaComercial();

  const { callService, loading: submitLoading } = useServiceCall(
    service.quickfin.editCompanyEstrategiaRelationship,
  );

  const form = useForm<EstrategiaRelationshipForm>();

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () => (updateRelationships: boolean) =>
      handleSubmit(async (values) => {
        const { success } = await callService(
          quickfinId,
          estrategiaId,
          String(updateRelationships),
          {
            antecipaAutomaticamente: values.antecipaAutomaticamente === "yes",
            permiteOverride: values.permiteOverride === "yes",
            tipoContratoRelacionamento: values.tipoContratoRelacionamento,
            contractTemplateId: values.contractTemplateId,
            tipoContratoRelacionamentoAntecipaAutomaticamente:
              values.tipoContratoRelacionamentoAntecipaAutomaticamente,
            contractTemplateIdAntecipaAutomaticamente:
              values.contractTemplateIdAntecipaAutomaticamente,
          },
        );

        if (success)
          OToastManager.success(
            "Cadastro de relacionamentos salvo com sucesso",
          );
        else
          OToastManager.danger("Erro ao salvar o cadastro de relacionamentos");
      }),
    [callService, estrategiaId, handleSubmit, quickfinId],
  );

  useEffect(() => {
    const initial =
      estrategia?.configuracoesCadastroRelacionamento as LoadedDataValues;
    if (initial) {
      reset({
        antecipaAutomaticamente: initial.antecipaAutomaticamente ? "yes" : "no",
        permiteOverride: initial.permiteOverride ? "yes" : "no",
        tipoContratoRelacionamento:
          estrategia?.estrategia.tipoContrato ??
          initial.tipoContratoRelacionamento,
        contractTemplateId:
          estrategia?.estrategia.contractTemplateId ??
          initial.contractTemplateId,
        tipoContratoRelacionamentoAntecipaAutomaticamente:
          initial.tipoContratoRelacionamentoAntecipaAutomaticamente,
        contractTemplateIdAntecipaAutomaticamente:
          initial.contractTemplateIdAntecipaAutomaticamente,
      });
    }
  }, [estrategia, reset]);

  return {
    estrategiaName: estrategia?.nome,
    estrategiaProduct: estrategia?.tipoProduto,
    form,
    getCompanyEstrategia,
    hasError,
    loading,
    quickfinId,
    submit,
    submitLoading,
  };
};
