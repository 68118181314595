import { Bar } from "react-chartjs-2";

interface ChartProps {
  labels: string[];
  datasets: number[];
}

export const QuantidadeDeDisparosChart = ({ labels, datasets }: ChartProps) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: "Quantidade de disparos",
        data: datasets,
        backgroundColor: "rgba(25, 90, 180, 1)",
        borderWidth: 1,
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    aspectRatio: 3,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
