import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiEmailConfigsResponseItem } from "services/clerk";

export const dataSource =
  dataSourceCustomStoreGenerator<GetApiEmailConfigsResponseItem>(() =>
    service.clerk
      .showEmailConfigs()
      .then(({ data }) => data ?? [])
      .catch(() => {
        const validator = "Erro ao buscar templates de email.";
        OToastManager.danger(validator);
        throw new Error(validator);
      }),
  );

export const emailTemplatesGrid: ODataGridGeneratorConfig<
  Awaited<ReturnType<typeof service.clerk.showEmailConfigs>>["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhum template de email",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "insertedAt",
      caption: "Data de inserção",
      dataType: "datetime",
      customizeText: ({ value }) =>
        value
          ? dayjs(value).subtract(3, "hours").format("DD/MM/YYYY, HH:mm:ss")
          : "-",
    },
    {
      dataField: "updatedAt",
      caption: "Última atualização",
      sortOrder: "desc",
      dataType: "datetime",
      customizeText: ({ value }) =>
        value
          ? dayjs(value).subtract(3, "hours").format("DD/MM/YYYY, HH:mm:ss")
          : "-",
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, component }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Detalhes",
              role: roles.ferramentas.product
                .onboardingInviteConfigsEmailTemplatesDetailsPage.role,
              route:
                corporateRouter.routes.ferramentas.product.onboarding.inviteConfigs.emailTemplates.details.path(
                  { id: data.id },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              role: roles.ferramentas.product
                .onboardingInviteConfigsEmailTemplatesRemovePage.role,
              onClick: () => {
                try {
                  service.clerk.deleteEmailConfigsById({ id: data.id });
                  component.getDataSource().reload();
                  OToastManager.success(
                    "Template de e-mail removido com sucesso!",
                  );
                } catch {
                  OToastManager.danger("Erro ao remover template de e-mail.");
                }
              },
            },
          ]}
        />
      ),
    },
  ],
};
