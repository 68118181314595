import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { format } from "utils/date";

type DataUpdate = NonNullable<
  PostAdminCompaniesGetByTaxIdResponse["dataUpdates"]
>[number];

export const grid: ODataGridGeneratorConfig<DataUpdate> = {
  datagrid: {
    noDataText: "Nenhuma atualização cadastral",
  },
  columns: [
    { dataField: "status", caption: "Status" },
    {
      dataField: "insertedAt",
      caption: "Data de criação",
      format: (value) => format.dateTime(value),
    },
    {
      dataField: "updatedAt",
      caption: "Última atualização",
      format: (value) => format.dateTime(value),
    },
  ],
};
