import * as yup from "yup";
import { AddSacadoTierConfigForm } from "./add-sacado-tier-config.types";

export const addSacadoTierConfigDefaulValues = {
  sacadoId: null,
  minimumTier: null,
  tipoProduto: null,
} satisfies Record<keyof AddSacadoTierConfigForm, unknown> as any;

export const addSacadoTierConfigValidationSchema: yup.ObjectSchema<AddSacadoTierConfigForm> =
  yup.object({
    sacadoId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("É necessário selecionar um sacado"),
    minimumTier: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("É necessário digitar um tier"),
    tipoProduto: yup
      .string()
      .required("É necessário escolher um tipo de produto"),
  });
