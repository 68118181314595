import { EligibilityGroup } from "./eligibility-tree.types";

export const inactivateNodeAndChildren = (
  nodeId: number,
  groups: EligibilityGroup[],
) => {
  const groupIdsToBeInactivated = groups.reduce(
    (ids, group) => {
      if (group.parentId && ids.includes(group.parentId)) ids.push(group.id);
      return ids;
    },
    [nodeId],
  );

  const inactivatedGroups = groups.map((group) => {
    if (group.id && groupIdsToBeInactivated.includes(group.id)) {
      const inactiveCriteria = group.eligibilityCriteria.map((criteria) => {
        const inactiveParams = criteria.criteriaParams.map((params) => ({
          ...params,
          active: false,
        }));
        return { ...criteria, criteriaParams: inactiveParams, active: false };
      });
      return {
        ...group,
        eligibilityCriteria: inactiveCriteria,
        active: false,
      };
    }
    return group;
  });

  return inactivatedGroups;
};

export const hasAnyEmptyEligibilityCriteria = (groups: EligibilityGroup[]) => {
  return groups.some((group) => group.eligibilityCriteria?.length === 0);
};

export const eligibilityGroupListToTrees = (groups: EligibilityGroup[]) => {
  const hashTable: Record<number, EligibilityGroup> = {};
  const groupTrees: EligibilityGroup[] = [];

  groups.forEach((group) => {
    hashTable[group.id] = { ...group, children: [] };
  });

  groups.forEach((group) => {
    if (group.parentId)
      hashTable[group.parentId].children?.push(hashTable[group.id]);
    else groupTrees.push(hashTable[group.id]);
  });

  return groupTrees;
};

export const removeTemporaryIdsFromTreeNodes = (node: EligibilityGroup) => {
  const newNode: EligibilityGroup = { ...node };

  if (node.isNewlyCreated) {
    newNode.id = 0;
    newNode.eligibilityCriteria = node.eligibilityCriteria.map((criteria) => ({
      ...criteria,
      eligibilityGroupId: 0,
    }));
  }

  if (node.children) {
    newNode.children = node.children.map((child) =>
      removeTemporaryIdsFromTreeNodes(child),
    );
  }

  return newNode;
};

export function generateNewNode(
  variantId: number,
  parentId: number | undefined = undefined,
): EligibilityGroup {
  const id = Math.random();
  return {
    id,
    parentId,
    active: true,
    isNewlyCreated: true,
    groupName: `Novo grupo de elegibilidade`,
    variantId,
    eligibilityCriteria: [
      {
        active: true,
        type: "PRE_APPROVED_LIMIT",
        isNewlyCreated: true,
        criteriaParams: [
          {
            active: true,
            operation: "GREATER_THAN",
            value: "0.0",
            isNewlyCreated: true,
          },
        ],
        eligibilityGroupId: id,
      },
    ],
    children: [],
  };
}

export const findRoot = (
  groups: EligibilityGroup[],
  group: EligibilityGroup,
): EligibilityGroup => {
  if (!group.parentId) return { ...group };

  const parent = groups.find((x) => x.id === group.parentId);
  if (parent) return findRoot(groups, parent);
  if (!parent) return group;

  throw new Error("Could not find root");
};

export const operationMap = {
  GREATER_THAN: "Maior que",
  GREATER_THAN_OR_EQUAL: "Maior ou igual a",
  EQUAL: "Igual",
  LESS_THAN: "Menor que",
  LESS_THAN_OR_EQUAL: "Menor ou igual a",
};

export const criteriaTypeMap = {
  PRE_APPROVED_LIMIT: "Limite pré-aprovado",
  NOT_ELIGIBLE: "Não elegível",
  ELIGIBLE: "Elegível",
  CORPORATE: "Corporate",
  ALLOWED_USERS_TO_HIRE_DEBIT_CARD: "Usuários com poder de movimentação",
  VALID_CPF: "CPF Válido",
  VALID_CNPJ: "CNPJ Válido",
  AGRO_GUARANTEE: "Garantias formalizadas",
};
