import { modalManager, OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { AdminBeneficiaryAnalyses } from "services/hubfx/odata/models/responses/get-admin-beneficiary-analyses-o-data.response";
import { GridTemplate } from "templates/grid-template";
import { exchangeOdataBeneficiaryAnalysesGrid } from "./exchange-odata-beneficiary-analyses.grid";
import { AnalysisModal } from "./modal-analises";
import { analysisModalIds } from "./modal-analises/analysis-modal.utils";

export const ExchangeProductBeneficiaryAnalysesPage = () => {
  const [selectedOrders, setSelectedOrders] = useState<
    AdminBeneficiaryAnalyses[]
  >([]);

  const gridRef = useGridRef();

  const grid = useMemo(
    () => exchangeOdataBeneficiaryAnalysesGrid(setSelectedOrders),
    [],
  );

  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Análises de beneficiários" />}
        gridRef={gridRef}
        actions={
          <>
            {selectedOrders.length > 0 && (
              <>
                <OButton
                  onClick={() => modalManager.show(analysisModalIds.deactivate)}
                >
                  Desativar
                </OButton>
                <OButton
                  onClick={() => modalManager.show(analysisModalIds.activate)}
                >
                  Ativar
                </OButton>
              </>
            )}
          </>
        }
        showClearFiltersButton
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator gridRef={gridRef} grid={grid} />
      </GridTemplate>
      <AnalysisModal orders={selectedOrders ?? []} gridRef={gridRef} closes />
      <AnalysisModal orders={selectedOrders ?? []} gridRef={gridRef} />
    </>
  );
};
