import { OToastManager } from "@maestro/core";
import { OBadge, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { GetB2BCoreOperatorResponse } from "services/b2b/core/models";
import { impersonateRedirect } from "utils/impersonate";
import { EProfiles, PROFILES_COLORS } from "./operators.types";
import { TagCodeCell } from "./tag-code.component";
import { CopyButton } from "./copy-button.component";

export type B2BCoreOperator = GetB2BCoreOperatorResponse[number];

export const buildDataSource = (identification: string) =>
  dataSourceCustomStoreGenerator<B2BCoreOperator>(() =>
    service.b2bCore
      .operators({
        originatorIdentification: identification,
      })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar os operadores";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const operatorsGrid: ODataGridGeneratorConfig<B2BCoreOperator> = {
  datagrid: {
    noDataText: "Esta empresa não possui operadores.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome/CPF",
      sortOrder: "asc",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["name", "contains", filterValue],
        "or",
        ["identification", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.name?.toUpperCase()}</OTypography>
          <OTypography type="default">
            {masks.cpfCnpj(data.identification)}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "profile",
      caption: "Perfil",
      allowEditing: false,
      customizeText: ({ value }) => EProfiles[value],
      calculateFilterExpression: (filterValue) => [
        [
          (item: GetB2BCoreOperatorResponse[number]) => {
            let matched = false;

            const reText = new RegExp(filterValue, "i");
            const profileLabel = EProfiles[item.profile];
            matched = matched || !!profileLabel.match(reText)?.length;

            return matched;
          },
          "=",
          true,
        ],
      ],
      cellRender: ({ data }) =>
        data.profile ? (
          <OBadge
            key={data.profile + "badge"}
            title={data.profile}
            size="sm"
            rounded
            type={PROFILES_COLORS[data.profile]}
          >
            {EProfiles[data.profile]}
          </OBadge>
        ) : (
          ""
        ),
    },
    {
      dataField: "email",
      caption: "E-mail/Telefone",
      allowEditing: false,
      calculateFilterExpression: (filterValue) => [
        ["email", "contains", filterValue],
        "or",
        ["phone", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.email}</OTypography>
          <OTypography type="default">{masks.phone(data.phone)}</OTypography>
        </div>
      ),
    },
    {
      caption: "Cidade/Estado",
      dataField: "address.city",
      calculateFilterExpression: (filterValue) => [
        ["address.city", "contains", filterValue],
        "or",
        ["address.state", "contains", filterValue],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.address?.city?.toUpperCase()}</OTypography>
          <OTypography type="default">
            {data.address?.state?.toUpperCase()}
          </OTypography>
        </div>
      ),
    },
    {
      caption: "Tag code",
      dataField: "tagCode",
      width: "100px",
      cellRender: ({ data }) => <TagCodeCell operator={data} />,
    },
    {
      caption: "Código CGE",
      dataField: "codCge",
    },
    {
      caption: "Data do último acesso",
      dataField: "lastAccessDate",
      dataType: "datetime",
      format: "shortDate",
      alignment: "center",
    },
    {
      caption: "Data do cadastro",
      dataField: "createdAt",
      dataType: "datetime",
      format: "shortDate",
      alignment: "center",
    },
    {
      cellRender: ({ data }) => (
        <CopyButton
          content={`${process.env.REACT_APP_PORTAL_CORE_FRONT_URL}/criar-conta?tag=${data.tagCode}`}
        />
      ),
    },
    {
      cellRender: ({ data }) => (
        <IconButton
          icon={{ category: "fal", icon: "fa-portrait" }}
          type="dark"
          outline
          onClick={() => impersonateRedirect(data.email, "PARCEIROS")}
        >
          Impersonar B2B
        </IconButton>
      ),
    },
  ],
};
