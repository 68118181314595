import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";
import { DisabledCardsStatusDoughnutChart } from "./_compose";

export const DisabledCardsStatusDoughnutChartCard = () => {
  return (
    <InfoCard
      summaryType="cardsSummary"
      render={(_value) => (
        <InfoCardContent
          header="Status de Cartões Desabilitados"
          body={
            <DisabledCardsStatusDoughnutChart
              damagedStatusQuantity={_value.damagedStatusQuantity ?? 0}
              robbedStatusQuantity={_value.robbedStatusQuantity ?? 0}
              fraudStatusQuantity={_value.fraudStatusQuantity ?? 0}
              lostStatusQuantity={_value.lostStatusQuantity ?? 0}
            />
          }
        />
      )}
    />
  );
};
