import { OptionChangedEventInfo } from "devextreme/core/dom_component";
import dxDataGrid from "devextreme/ui/data_grid";

export const parseSortingToUrl = (
  event: OptionChangedEventInfo<dxDataGrid<unknown, unknown>>,
) => {
  const state = event.component.state() as { columns: { dataField: string }[] };
  const matchColumnIdx = event.fullName.match(/\d+/g);
  const column = matchColumnIdx ? state.columns[+matchColumnIdx[0]] : undefined;

  if (!column) return "";

  const sortedKeys = { [column.dataField]: event.value };

  const sortedKeyEntries = Object.entries(sortedKeys);
  const sortingQueryString = sortedKeyEntries.reduce((prev, [key, value]) => {
    const sortingType = value === "asc" ? "+" : "-";
    return `${prev ?? `${prev},`}${sortingType}${key}`;
  }, "");

  return sortingQueryString;
};
