export enum AccountType {
  CC = "CC",
  PP = "PP",
  CD = "CD",
  CP = "CP",
  CG = "CG",
  CI = "CI",
  PG = "PG",
  CS = "CS",
}
