import { modalManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { mapProcessorType } from "../../../../../../../../components/canvas/canvas.utils";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";

export const AddProcessorConfigModalId = "add-processor-config-modal";

export const useAddProcessorConfigModal = () => {
  const {
    viewPoint,
    processorOptions,
    workflowId,
    getCanvasData,
    handleRequestError,
  } = useCanvas();
  const { screenToFlowPosition } = useReactFlow();

  const [search, setSearch] = useState<string>();
  const [filteredProcessors, setFilteredProcessors] = useState<
    typeof processorOptions
  >([]);

  const addProcessorConfig = useCallback(
    async (id: number) => {
      try {
        const { x, y } = screenToFlowPosition({
          x: viewPoint.client.x,
          y: viewPoint.client.y,
        });

        await service.hubCreditManager.addProcessorConfig({
          processorId: id,
          workflowConfigId: Number(workflowId),
          canvasData: { positionX: x, positionY: y },
        });

        getCanvasData();
      } catch (err) {
        handleRequestError(
          err,
          "Um problema ocorreu ao tentar adicionar a configuração de processador ao workflow.",
        );
      } finally {
        modalManager.hide(AddProcessorConfigModalId);
      }
    },
    [
      viewPoint,
      workflowId,
      getCanvasData,
      handleRequestError,
      screenToFlowPosition,
    ],
  );

  useEffect(() => {
    if (search) {
      const filteredProcessorsAux = processorOptions?.filter(
        (x) =>
          x.type.toLowerCase().includes(search.toLowerCase()) ||
          getValueFromMap(mapProcessorType, x.type)
            ?.toLowerCase()
            .includes(search.toLowerCase()),
      );

      if (filteredProcessorsAux) setFilteredProcessors(filteredProcessorsAux);
    } else if (!search && !!processorOptions) {
      setFilteredProcessors(processorOptions);
    }
  }, [processorOptions, search]);

  return { search, setSearch, filteredProcessors, addProcessorConfig };
};
