import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OCol,
  OField,
  OIcon,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTooltip,
} from "@maestro/react";
import { DocumentSubtypeMap } from "../../../../add-payer.utils";
import { FilesModal } from "../files-modal";
import { useDocumentField } from "./document-field.hook";

interface DocumentFieldProps {
  documentKey: string;
  index: number;
}

export const DocumentField = ({ documentKey, index }: DocumentFieldProps) => {
  const {
    fields,
    fileError,
    handleAddFile,
    handleRemoveFile,
    possibleDocumentSubtypes,
  } = useDocumentField(index);

  return (
    <OCard key={documentKey} type="default" outline>
      <OCardBody>
        <ORow>
          <OCol xs={3}>
            <OField
              htmlFor={`status_icon_${index}`}
              label="Status do documento"
              className="d-flex flex-column align-items-start gap-2"
            >
              <div className="d-flex flex-row gap-2 align-items-center">
                <OIcon
                  id={`status_icon_${index}`}
                  category="orq"
                  icon={fileError ? "orq-close" : "orq-check"}
                  type={fileError ? "danger" : "success"}
                  size="xxl"
                />
                {fileError && (
                  <OTooltip floating>
                    <span slot="tooltip-content">{fileError}</span>
                    <OIcon category="orq" icon="orq-info" size="lg" />
                  </OTooltip>
                )}
              </div>
            </OField>
          </OCol>
          <OCol xs={3}>
            <ORFieldInput
              tag="text"
              id={`documents.${index}.type`}
              name={`documents.${index}.type`}
              label="Tipo de documento"
              disabled
            />
          </OCol>
          <OCol xs={3}>
            {possibleDocumentSubtypes && (
              <ORFieldSelect
                id={`documents.${index}.subType`}
                name={`documents.${index}.subType`}
                label="Subtipo de documento"
              >
                {possibleDocumentSubtypes.map((document) => (
                  <OOption key={document} value={document}>
                    {DocumentSubtypeMap[document]}
                  </OOption>
                ))}
              </ORFieldSelect>
            )}
          </OCol>
          <OCol xs={3} className="d-flex flex-row align-items-center">
            <OButton
              type="tertiary"
              onClick={() => modalManager.show(`document-${index}-files-modal`)}
            >
              Ver arquivos anexados
            </OButton>
          </OCol>
        </ORow>
      </OCardBody>
      <FilesModal
        documentIndex={index}
        fields={fields}
        handleAddFile={handleAddFile}
        handleRemoveFile={handleRemoveFile}
      />
    </OCard>
  );
};
