import { getValueFromMap } from "utils/get-value-from-map";

export const loanStatus = {
  Simulado: { text: "Simulação Aceita", value: "Simulado", description: "" },
  PendenteOnboardingTerceiros: {
    text: "Pendente onboarding de terceiros",
    value: "PendenteOnboardingTerceiros",
    description: "",
  },
  GeracaoDeContrato: {
    text: "Geração de Contratos",
    value: "GeracaoDeContrato",
    description: "",
  },
  GeracaoDeContratoDeGarantias: {
    text: "Geração de Contratos de Garantias",
    value: "GeracaoDeContratoDeGarantias",
    description: "",
  },
  PendenteAssinatura: {
    text: "Aguardando Assinaturas",
    value: "PendenteAssinatura",
    description: "Simulação pendente de assinatura",
  },
  PendenteValidacao: {
    text: "Validação da Operação",
    value: "PendenteValidacao",
    description:
      "Operação está pendente de validação, passo automático com validação baseado na configuração do convênio",
  },
  AguardandoRegistro: {
    text: "Aguardando Registros",
    value: "AguardandoRegistro",
    description: "",
  },
  AguardandoNotaFiscal: {
    text: "Aguardando Nota Fiscal",
    value: "AguardandoNotaFiscal",
    description: "",
  },
  PendenteAtivacaoConta: {
    text: "Pendente ativação de conta",
    value: "PendenteAtivacaoConta",
    description: "",
  },
  AprovacaoEnvioTrade: {
    text: "Pendente Aprovação",
    value: "AprovacaoEnvioTrade",
    description: "",
  },
  RecalculandoOperacao: {
    text: "Recalculando Operação",
    value: "RecalculandoOperacao",
    description: "",
  },
  EnvioTrade: {
    text: "Envio do Trade",
    value: "EnvioTrade",
    description: "",
  },
  TradeEnviado: {
    text: "Trade Enviado",
    value: "TradeEnviado",
    description:
      "Operação foi enviada para o FTS porém sem confirmação de chegada",
  },
  AguardandoAprovacao: {
    text: "Aguardando Aprovação",
    value: "AguardandoAprovacao",
    description: "Operação pendente de aprovação no FTS",
  },
  AguardandoDesembolso: {
    text: "Aguardando Desembolso",
    value: "AguardandoDesembolso",
    description:
      "FTS exportou a operação e está pendente de desembolso no CROS",
  },

  Validado: {
    text: "Validado",
    value: "Validado",
    description: "Operação já foi validada de acordo com as regras do convênio",
  },
  ContratoFormalizado: {
    text: "Contrato formalizado",
    value: "ContratoFormalizado",
    description: "Contrato formalizado",
  },
  Desembolsado: {
    text: "Desembolsado",
    value: "Desembolsado",
    description: "Operação desembolsada",
  },
  Atrasado: {
    text: "Atrasado",
    value: "Atrasado",
    description: "Operação atrasada",
  },
  Liquidado: {
    text: "Liquidado",
    value: "Liquidado",
    description: "Empréstimo foi quitado",
  },
  Expirado: {
    text: "Expirado",
    value: "Expirado",
    description: "Simulação expirada, contrato não foi assinado a tempo",
  },
  Cancelado: {
    text: "Cancelado",
    value: "Cancelado",
    description: "Operação cancelada pelo usuário, ou via admin",
  },
  Invalido: {
    text: "Inválido",
    value: "Invalido",
    description:
      "Operação invalida, visualizar motivo na coluna 'Motivo da Rejeição'",
  },
  Renegociado: {
    text: "Renegociado",
    value: "Renegociado",
    description: "",
  },
  Cedido: {
    text: "Cedido",
    value: "Cedido",
    description: "",
  },
};

export const mapLoanStatus = (status: string | null | undefined) =>
  getValueFromMap(loanStatus, status)?.text ?? status ?? "";
