import styled from "styled-components";

export const DetailInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  border: none;

  label {
    font-size: 16px;
    font-weight: lighter;
    color: grey;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }

  strong {
    font-size: 25px;
    font-weight: 600;
    color: black;
  }
`;

export const DetailStatus = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;

  label {
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin-left: 5px;
  }

  span {
    font-size: 18px;
    height: fit-content;
    align-self: center;
  }
`;
