import { OConfirmationModal, modalManager } from "@maestro/react";
import { DomainPre } from "./domain-modal.styles";

export interface DomainConfirmationModalProps {
  domainValue: HubOnboarding.Onboarding.DomainConfig;
}

export const DomainConfirmationModal = ({
  domainValue,
}: DomainConfirmationModalProps) => {
  return (
    <OConfirmationModal
      modalId={`domainValue-${domainValue.key}-admin-domain-modal`}
      title="Informações do domínio"
      confirmLabel="Ok"
      divider={false}
      size="md"
      onConfirm={() =>
        modalManager.hide(`domainValue-${domainValue.key}-admin-domain-modal`)
      }
    >
      <DomainPre>{JSON.stringify(domainValue, null, 4)}</DomainPre>
    </OConfirmationModal>
  );
};
