import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  customerOnboardingWorkflowsGrid,
} from "./customer-onboarding-workflows.grid";

export const CustomerOnboardingWorkflows = () => {
  const {
    customer: { identification },
  } = useCustomer();
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(identification),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Edição de onboarding" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={customerOnboardingWorkflowsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
