import { OBadge, OField, OIcon, OLoader, OTypography } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { useCompanySearch } from "./company-search.hook";
import { remapHookFormRegister } from "../../../../../../components/generic-bank-data/generic-bank-data.utils";

interface CompanySearchProps {
  mode: "Sacado" | "Cedente";
  name: string;
  label: string;
  placeholder: string;
  multiple: boolean;
}

export const CompanySearch = ({
  mode,
  label,
  name,
  placeholder,
  multiple,
}: CompanySearchProps) => {
  const { localForm, loading, options, selected, removeCompany } =
    useCompanySearch(mode, name);

  const {
    formState: {
      errors: { input: error },
    },
    watch,
  } = localForm;

  const inputWatch = watch("input");

  return (
    <div>
      <div className="d-flex align-items-center gap-2">
        <OField
          label={label}
          htmlFor="input"
          error={!!error}
          message={error?.message as string}
          required={mode === "Cedente"}
        >
          <SelectSearch
            id="input"
            name="input"
            placeholder={placeholder}
            options={options}
            value={inputWatch}
            className="w-100"
            maxOptions={50}
            eager={false}
            disabled={!multiple && selected.length > 0}
            innerProps={{
              input: {
                ...remapHookFormRegister(localForm.register("input")),
              },
            }}
          />
        </OField>
        {loading && <OLoader className="d-block" size="sm" />}
      </div>
      {!!selected.length && (
        <OTypography size="sm" className="mb-2">
          Selecionados:
        </OTypography>
      )}
      <div className="d-flex flex-wrap gap-2">
        {selected.map((company) => (
          <OBadge
            key={company.identification}
            type="dark"
            size="sm"
            rounded
            outline
          >
            <div className="d-flex align-items-center gap-2">
              {company.label}
              <UnstyledButton
                onClick={() => removeCompany(company.identification)}
              >
                <OIcon category="fal" icon="fa-times" />
              </UnstyledButton>
            </div>
          </OBadge>
        ))}
      </div>
    </div>
  );
};
