import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { GetCreditConciliationParams } from "services/fidc/models/requests/get-credit-conciliation.request";
import {
  InfoCardContent,
  InfoCardContentProps,
} from "./info-card-content.component";
import { useCreditConciliationDashboard } from "../../detalhes/credit-conciliation-dashboard/credit-conciliation-dashboard.hook";
import { CardContainer } from "./card.styles";

interface InfoCardProps extends InfoCardContentProps {
  hasError?: boolean;
  loading?: boolean;
}
type ViewCreditConciliationParams = GetCreditConciliationParams;
export const InfoCard = ({
  hasError,
  loading,
  title,
  value,
  quantity,
  status,
}: InfoCardProps) => {
  const { getCreditConciliationDetails } = useCreditConciliationDashboard();
  const cardType =
    status === "APPROVED"
      ? "success-light"
      : status === "REJECTED"
      ? "danger-light"
      : "warning-light";
  const { orderId } = getSearchParams<ViewCreditConciliationParams>();
  if (!orderId) throw new Error("No url parameters");
  return (
    <CardContainer>
      <OCard className="position-relative" type={cardType} key={value}>
        <OCardBody className="h-100">
          <ContentTemplate
            hasError={hasError}
            loading={loading}
            noValue
            errorComponent={
              <ErrorComponent
                messageTitle="Não foi possível buscar as informações."
                messageParagraph="Atualize a página para tentar novamente."
              >
                <TryAgainButton
                  onClick={() => getCreditConciliationDetails(orderId)}
                />
              </ErrorComponent>
            }
            render={() => (
              <InfoCardContent
                title={title}
                value={value}
                quantity={quantity}
                status={status}
              />
            )}
          />
        </OCardBody>
      </OCard>
    </CardContainer>
  );
};
