import { ExchangeGenericFieldMask } from "services/acc/models/types/nature/generic-field-config.model";

export const exchangeGenericFieldMaskOptions: Record<
  ExchangeGenericFieldMask,
  string
> = {
  CNPJ: "CNPJ",
  CPF: "CPF",
  DATE: "DATE",
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  DUE_KEY: "DUE_KEY",
  DUE: "DUE",
};

export const parseAccPendencyCodes = (pendencyCodes: string | null) => {
  try {
    return pendencyCodes ? (JSON.parse(pendencyCodes) as string[]) : [];
  } catch {
    return [];
  }
};
