import { IInvoiceInfo } from "./invoices.types";

export const InvoiceCardInfo: Array<IInvoiceInfo> = [
  {
    key: "totalBalance",
    label: "Valor do Fechamento",
  },
  {
    key: "paidBalance",
    label: "Valor Pago da Fatura",
  },
  {
    key: "remainingBalance",
    label: "Valor em Aberto",
  },
];
