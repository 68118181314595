import { OCol, OField, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { PostApiDesksGetEntityDesksResponse } from "services/clerk";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useInitialStep } from "./initial-step.hook";
import { StyledSelect } from "./initial-step.styles";

interface InitialStepProps {
  configurations?: ScfDevelopers.PartnerConfiguration[];
  loadingDesks: boolean;
  possibleDesks?: PostApiDesksGetEntityDesksResponse;
  setCurrentStep: (value: string) => void;
}

export const InitialStep = ({
  configurations,
  loadingDesks,
  possibleDesks,
  setCurrentStep,
}: InitialStepProps) => {
  const {
    chosenConfiguration,
    identificationWatch,
    loading,
    onSubmit,
    possibleConfigurations,
    register,
  } = useInitialStep(configurations, possibleDesks, setCurrentStep);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Registrar sacado simples" />}
      actions={
        <LoadingButton
          dataAction="importar_sacado_simples:botao:iniciar_cadastro"
          dataLabel="iniciar_cadastro"
          loading={loading}
          disabled={!(chosenConfiguration && identificationWatch)}
          onClick={() => onSubmit()}
        >
          Iniciar cadastro
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute />}

        <ORow>
          <OCol xs={6}>
            <OField htmlFor="partnerConfigurationId" label="Fluxo onboarding">
              <StyledSelect
                {...register("partnerConfigurationId")}
                id="partnerConfigurationId"
                name="partnerConfigurationId"
                disabled={loadingDesks || !possibleConfigurations}
                defaultValue=""
              >
                <option hidden value="">
                  {" "}
                </option>
                {possibleConfigurations?.map((desk) => (
                  <option
                    key={desk.configurationId}
                    value={desk.configurationId ?? ""}
                  >
                    {desk.configurationDisplayName}
                  </option>
                ))}
              </StyledSelect>
            </OField>
          </OCol>
          <OCol xl={6}>
            <ORFieldInput
              tag="cpfcnpj"
              id="identification"
              name="identification"
              label="CNPJ/CPF"
            />
          </OCol>
        </ORow>
      </CardTemplate>
    </FormTemplate>
  );
};
