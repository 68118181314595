import { OCol, ORFieldInputDateRange } from "@maestro/react";

export const CancelingDateRange = () => {
  return (
    <>
      <OCol xs={12} lg={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="maturityDate"
          name="maturityDate"
          label="Data vencimento"
        />
      </OCol>
      <OCol xs={12} lg={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="createdAt"
          name="createdAt"
          label="Criado em"
        />
      </OCol>
      <OCol xs={12} lg={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="updatedAt"
          name="updatedAt"
          label="Atualizado em"
        />
      </OCol>
    </>
  );
};
