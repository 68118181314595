import { modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { Job } from "../../jobs.types";
import { runJobs } from "../../jobs.utils";
import { ConfirmationModal, runMultipleModalId } from "../confirmation-modal";

interface BatchRunButtonProps {
  selectedJobs: Job[];
  reload: () => void;
}

export const BatchRunButton = ({
  selectedJobs,
  reload,
}: BatchRunButtonProps) => (
  <>
    <IconButton
      dataAction="antecipacao_cartao_jobs:botao:redisparar_selecionados"
      dataLabel="redisparar_selecionados"
      icon={{ category: "far", icon: "fa-redo" }}
      disabled={selectedJobs.length < 1}
      onClick={() => modalManager.show(runMultipleModalId)}
    >
      Redisparar selecionados
    </IconButton>
    <ConfirmationModal
      id={runMultipleModalId}
      onConfirm={async () =>
        runJobs(selectedJobs.map((job) => job.id)).then(() => reload())
      }
      type="run"
      jobsAmount={selectedJobs.length}
    />
  </>
);
