import { OToastManager } from "@maestro/core";
import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useCallback, useMemo } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  lettersOfAttorneyGrid,
} from "./letters-of-attorney.grid";

export const LettersOfAttorneyPage = () => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const dataSource = useMemo(() => buildDataSource(), []);

  const grid = useMemo(() => lettersOfAttorneyGrid(gridRef), [gridRef]);

  const createWorkflow = useCallback(async () => {
    try {
      await service.onboardingRelationships.createWorkflow();
      gridRef.current?.instance.getDataSource().reload();
    } catch {
      OToastManager.danger("Falha ao criar workflow");
    }
  }, [gridRef]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Procurações físicas" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      actions={
        <>
          {hasRole(
            roles.cadastro.customer.lettersOfAttorneyVintageCreateWorkflow.role,
          ) && <OButton onClick={createWorkflow}>Adicionar</OButton>}
          <RouterButton
            href={
              corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage
                .monitoring.path
            }
          >
            Monitoramento
          </RouterButton>
        </>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
