import { ODataGridGeneratorConfig } from "components/data-grid";
import { emprestimosRoles } from "../../../../../roles/emprestimos.roles";
import { ContractSignatoriesMasterDetail } from "./_compose/contract-signatories.master-detail";
import { ContractsGridDataAction } from "./operations-contracts-grid-data-action.component";
import { Contract } from "./operations-contracts.context";
import { contractStatusMapper } from "./operations-contracts.utils";

export const operationsContractsGrid = {
  datagrid: {
    noDataText: "Não foram encontrados contratos associados a este empréstimo.",
  },
  columns: [
    {
      caption: "Contrato",
      dataField: "number",
    },
    {
      caption: "Data de solicitação",
      dataField: "formalizationDate",
      dataType: "datetime",
    },
    {
      caption: "Tipo",
      dataField: "type",
    },
    {
      caption: "Status",
      dataField: "status",
      customizeText: ({ value }) => contractStatusMapper[value],
    },
    {
      caption: "Provedor da assinatura",
      dataField: "signatureOrigin",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => <ContractsGridDataAction contract={data} />,
      role: emprestimosRoles.customer.operationsContractsDataAction.role,
    },
  ],
  masterDetail: {
    component: ({ data }) => (
      <div className="d-flex flex-column gap-4">
        <ContractSignatoriesMasterDetail data={data} />
      </div>
    ),
    enabled: true,
  },
} satisfies ODataGridGeneratorConfig<Contract>;
