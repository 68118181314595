import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { GridTemplate } from "templates/grid-template";
import { energyTradeGuarantorsGrid } from "../../../../../components/deprecated/guarantor/energy-trade-guarantors.grid";
import { generateEnergyGuarantorsDataSource } from "../../../../../components/deprecated/guarantor/energy-trade-guarantors.utils";
import { MleByTradeIdRouteParams } from "../../../../../routes/energia.route-params";

export const EnergyTradeGuarantorsPage = () => {
  const { tradeId } = useParams<MleByTradeIdRouteParams>();

  if (!tradeId) throw new Error("No tradeId");
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const dataSource = useMemo(
    () => generateEnergyGuarantorsDataSource(tradeId),
    [tradeId],
  );

  const grid = useMemo(
    () =>
      energyTradeGuarantorsGrid(
        hasRole(roles.energia.customer.tradesGuarantorsEdit.role),
      ),
    [hasRole],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Avalistas" />}
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
