import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { modalManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { validationSchema } from "../_compose/template-config/template-config.form";
import { FormValuesType } from "../_compose/template-config/template-config.types";

export const useSemaphoreConfigurationDetails = (uuid: string) => {
  const { callService: _remove, loading } = useServiceCall(() =>
    service.onboarding.removeSemaphoreTemplate(uuid as string),
  );

  const navigate = useNavigate();

  const removeSemaphoreTemplate = async () => {
    try {
      await _remove();
      OToastManager.success("Template foi removido com sucesso");
      navigate(
        corporateRouter.routes.ferramentas.product.onboarding.semaphore
          .configuration.template.path,
      );
    } catch {
      OToastManager.danger("Não foi possível remover o template");
    }
  };

  const [stateLoading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { reset } = form;

  const load = useCallback(async () => {
    setLoading(true);

    if (!uuid) return;

    const { data } = await service.onboarding.findSemaphoreTemplate(uuid);

    const resetValues = {
      identifier: data.identifier,
      description: data.description,
      steps: data.steps,
    };

    reset(resetValues);

    setLoading(false);
  }, [uuid, reset]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    modalManager.on("step-order-modal", "modalClose", load);
  }, [load]);

  return {
    form,
    loading,
    stateLoading,
    removeSemaphoreTemplate,
  };
};
