import {
  modalManager,
  OBadge,
  OCard,
  OCardBody,
  ODropdownAction,
  ODropdownActionItem,
  OIcon,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { useCallback, useRef } from "react";
import { service } from "services";
import { FormStepCard } from "services/hubcreditmanager/models/types/workflow/form.types";
import { useWorkflowFormTemplate } from "../../../../../../../components/hooks/use-workflow-form-template.context";
import { useWorkflow } from "../../../../../../../components/hooks/use-workflow.context";

interface DraggableComponentsProps {
  card: FormStepCard;
}

export const DraggableComponents = ({ card }: DraggableComponentsProps) => {
  const { show } = modalManager;
  const {
    form: { value: workflowFormTemplate, callService: getForm },
    setSelectedComponent,
  } = useWorkflowFormTemplate();

  const { setConditionDestination, setSelectedConditions } = useWorkflow();

  const dragItem = useRef<number>(0);
  const dragOverItem = useRef<number>(0);

  const updateComponentPositions = useCallback(async () => {
    if (!card || !workflowFormTemplate) return;
    let componentsListAux = card.components.map((c) => ({ ...c })); // .splice() e [...array] nao funcionam para array em um state
    const dragItemContent = componentsListAux[dragItem.current];

    componentsListAux.splice(dragItem.current, 1);
    componentsListAux.splice(dragOverItem.current, 0, dragItemContent);
    componentsListAux = componentsListAux.map((step, index) => ({
      ...step,
      position: index,
    }));

    if (
      componentsListAux[dragItem.current]?.id ===
      card.components[dragItem.current]?.id
    )
      return;

    try {
      await service.hubCreditManager.updateFormStepCardComponentPositions({
        formStepCardId: card.id,
        positions: componentsListAux.map((cl) => ({
          formStepCardComponentId: cl.id,
          position: cl.position,
        })),
      });

      getForm(workflowFormTemplate.id);
    } catch {
      OToastManager.danger(
        "Não foi possível atualizar as posições dos componentes",
      );
    }
  }, [card, workflowFormTemplate, getForm]);

  return (
    <div className="d-flex flex-column gap-2">
      {!card?.components?.length && (
        <OTypography>Este card ainda não possui componentes</OTypography>
      )}

      {card?.components
        .sort((a, b) => a.position - b.position)
        .map((c, index) => (
          <OCard
            type="default"
            outline
            onDragStart={() => {
              dragItem.current = index;
            }}
            onDragEnter={() => {
              dragOverItem.current = index;
            }}
            onDragEnd={() => updateComponentPositions()}
            key={`workflow-card-component-${c.id}`}
            draggable
          >
            <OCardBody>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2 align-items-center">
                    <OTypography weight="bold" key={c.component.name}>
                      {c.component.name}
                    </OTypography>
                    {c.name && ` - ${c.name}`}
                    {c.isReadOnly && (
                      <OBadge type="info" size="xs">
                        Somente Leitura
                      </OBadge>
                    )}
                    {c.validationStatus === "INVALID" && (
                      <OBadge type="danger" size="xs">
                        Inválido
                      </OBadge>
                    )}
                  </div>
                  <ODropdownAction floating>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedComponent(c);
                        setSelectedConditions(c.conditions);
                        setConditionDestination("COMPONENT");
                        show("edit-component-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-pencil"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Editar</OTypography>
                    </ODropdownActionItem>
                    <ODropdownActionItem
                      size="xl"
                      handleClick={() => {
                        setSelectedComponent(c);
                        show("remove-component-modal");
                      }}
                    >
                      <OIcon
                        category="orq"
                        icon="orq-edit-trash"
                        type="dark"
                        size="xl"
                      />
                      <OTypography type="dark">Remover</OTypography>
                    </ODropdownActionItem>
                  </ODropdownAction>
                </div>
                <OTypography key={c.component.description}>
                  {c.component.description}
                </OTypography>
              </div>
            </OCardBody>
          </OCard>
        ))}
    </div>
  );
};
