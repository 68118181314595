import dayjs from "dayjs";
import React, { createContext } from "react";

export interface MovementsFilter {
  categories: string[];
  movementTypes: string[];
}

interface Owner {
  accountNumber: string;
  branch: string;
  document: string;
  id: string;
}

export interface BalanceYieldItem {
  id: string;
  owner: Owner;
}

interface Account {
  agency: number;
  number: string;
  service: string;
  status: number;
  balance?: {
    accountNumber: number;
    balance: number;
    blockedBalance: number;
    blockedDate: string;
    overdraftLimit: number;
  };
  show?: boolean;
  bank: number;
  accountType: string;
}

interface AbstractStatementContext {
  selectedAccount: Account | null;
  setSelectedAccount: CallableFunction;

  balanceYields: BalanceYieldItem[];
  setBalanceYields: CallableFunction;

  fetchStatus: boolean;
  setFetchStatus: CallableFunction;

  cursor: string | null;
  setCursor: CallableFunction;

  isFirstFetch: boolean;
  setIsFirstFetch: CallableFunction;

  filters: MovementsFilter;
  setFilters: CallableFunction;

  monthly: string;
  setMonthly: CallableFunction;

  startDate: Date;
  endDate: Date;

  setStartDate: CallableFunction;
  setEndDate: CallableFunction;

  hasFilters: boolean;
  setHasFilters: CallableFunction;

  isAllFetched: boolean;
  setIsAllFetched: CallableFunction;
}

const statementDays = 45;

export const StatementContext = createContext<AbstractStatementContext>({
  selectedAccount: null,
  setSelectedAccount: () => {},

  balanceYields: [],
  setBalanceYields: () => {},

  fetchStatus: true,
  setFetchStatus: () => {},

  cursor: null,
  setCursor: () => {},

  isFirstFetch: true,
  setIsFirstFetch: () => {},

  filters: { categories: [], movementTypes: [] },
  setFilters: () => {},

  monthly: "",
  setMonthly: () => "",

  startDate: dayjs().subtract(statementDays, "day").toDate(),
  endDate: new Date(),

  setStartDate: () => null,
  setEndDate: () => null,

  hasFilters: false,
  setHasFilters: () => {},

  isAllFetched: false,
  setIsAllFetched: () => {},
});

type Props = {
  children: React.ReactNode;
};
export const StatementProvider = ({ children }: Props) => {
  const [fetchStatus, setFetchStatus] = React.useState(true);
  const [cursor, setCursor] = React.useState<string | null>(null);
  const [isFirstFetch, setIsFirstFetch] = React.useState(true);
  const [filters, setFilters] = React.useState<MovementsFilter>({
    categories: [],
    movementTypes: [],
  });
  const [monthly, setMonthly] = React.useState("");
  const [startDate, setStartDate] = React.useState<Date>(
    dayjs().subtract(statementDays, "day").toDate(),
  );
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [hasFilters, setHasFilters] = React.useState(false);
  const [isAllFetched, setIsAllFetched] = React.useState(false);
  const [balanceYields, setBalanceYields] = React.useState<BalanceYieldItem[]>(
    [],
  );
  const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(
    null,
  );

  return (
    <StatementContext.Provider
      value={{
        balanceYields,
        setBalanceYields,
        selectedAccount,
        setSelectedAccount,
        fetchStatus,
        setFetchStatus,
        cursor,
        setCursor,
        isFirstFetch,
        setIsFirstFetch,
        filters,
        setFilters,
        monthly,
        setMonthly,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        hasFilters,
        setHasFilters,
        isAllFetched,
        setIsAllFetched,
      }}
    >
      {children}
    </StatementContext.Provider>
  );
};
