import { OCol } from "@maestro/react";
import { InputMultiple } from "../../input-multiple/input-multiple.component";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const DiasParaVencimentoDataRepasseRiscoCedenteField = () => {
  return (
    <OCol xs={12} md={6}>
      <InputMultiple
        dataAction="geral:texto:dias_repasse"
        dataLabel="dias_repasse"
        id="diasParaVencimentoDataRepasseRiscoCedente"
        name="diasParaVencimentoDataRepasseRiscoCedente"
        label={EstrategiaFormLabels.diasParaVencimentoDataRepasseRiscoCedente}
        description="Quantidade de dias antes da data repasse para receber notificação"
        required
      />
    </OCol>
  );
};
