export const signingOriginMap = {
  PaperClip: "Paperclip",
  DocuSign: "Docusign",
};

export const signingPartyRuleOrigiMap = {
  ParameterizedRules: "Paperclip",
  IRIS: "IRIS",
  ContractSpecificSignatories: "Específico",
  None: "Nenhum",
};

export const groupingType = ["GrupoEconomico", "Filial"];
