import React, { createContext, useMemo, useState } from "react";
import { emptyIntegration } from "../resources/empty";
import { Integration, IntegrationContact } from "../interfaces/integration";

interface IntegrationDetails {
  setIntegration(newAccount: Integration): void;
  integration: Integration;
  setIsIntegrationLoading(value: boolean): void;
  isIntegrationLoading: boolean;
}

export const IntegrationContext = createContext<IntegrationDetails>({
  setIntegration(): void {},
  integration: emptyIntegration,
  isIntegrationLoading: false,
  setIsIntegrationLoading(): void {},
});

interface IntegrationDetailsProviderProps {
  children: React.ReactNode;
}

export const IntegrationDetailsProvider: React.FC<
  IntegrationDetailsProviderProps
> = ({ children }) => {
  const [contacts, setContacts] = useState<IntegrationContact[]>([]);
  const [isIntegrationLoading, setIsIntegrationLoading] =
    useState<boolean>(false);
  const [integration, setIntegration] = useState<Integration>(emptyIntegration);

  function handleUpdateIntegrationContacts(
    updatedContacts: IntegrationContact[],
  ) {
    setContacts(updatedContacts);
  }

  function handleUpdateIntegration(updatedIntegration: Integration) {
    setIntegration(updatedIntegration);
  }

  function handleUpdateLoading(isLoading: boolean) {
    setIsIntegrationLoading(isLoading);
  }

  const value = useMemo(
    () => ({
      setIntegration: handleUpdateIntegration,
      integration,
      setIntegratinContacts: handleUpdateIntegrationContacts,
      contacts,
      isIntegrationLoading,
      setIsIntegrationLoading: handleUpdateLoading,
    }),
    [contacts, integration, isIntegrationLoading],
  );

  return (
    <IntegrationContext.Provider value={value as unknown as IntegrationDetails}>
      {children}
    </IntegrationContext.Provider>
  );
};
