import { EmbeddedContainer, EmbeddedPortal } from "components/portal-embeded";
import { useCustomer } from "contexts/customer";

export const OnboardingEmbedded = () => {
  const { customer } = useCustomer();

  const headers = {
    "x-identification": customer.identification,
    "x-uuid": customer.uuid,
  };

  return (
    <EmbeddedContainer>
      <EmbeddedPortal route="/cadastro/novo" headers={headers} />
    </EmbeddedContainer>
  );
};
