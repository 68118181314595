import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductGenericFieldsGrid } from "./exchange-product-generic-fields.grid";

export const ExchangeProductGenericFieldsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Campos Genéricos" />}
      actions={
        <RouterButton
          dataAction="cambio_campos_genericos:botao:adicionar_campo_generico"
          dataLabel="adicionar_campo_generico"
          href={
            corporateRouter.routes.cambio.product.contingency.natures
              .genericFields.create.path
          }
          role={
            roles.cambio.product.contingencyNaturesGenericFieldsCreatePage.role
          }
        >
          Adicionar campo genérico
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={exchangeProductGenericFieldsGrid}
      />
    </GridTemplate>
  );
};
