import { dataSourceODataStoreGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { endpoints } from "services/endpoints";
import { GetOdataContractsPendingComplementViewResponse } from "services/quickfin/models";
import { pendingComplementsGrid } from "./pending-complements.grid";

export const usePendingComplements = () => {
  const gridRef = useGridRef();
  const dataSource = dataSourceODataStoreGenerator<
    GetOdataContractsPendingComplementViewResponse["value"][number]
  >(endpoints.quickfin.odata.contractsPendingComplement);

  return { gridRef, grid: pendingComplementsGrid, dataSource };
};
