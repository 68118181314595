import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FerramentasProdutoMigracoesMesasByMigrationIdRouteParams } from "../../../routes/ferramentas.route-params";
import { MigrationMovement } from "./desk-migration-details.types";

export const useDeskMigrationDetails = () => {
  const { migrationId } =
    useParams<FerramentasProdutoMigracoesMesasByMigrationIdRouteParams>();
  if (!migrationId) throw new Error("No migrationId");

  const {
    callService: getDeskMigration,
    hasError,
    loading,
    value: deskMigration,
  } = useServiceCall(service.onboarding.getDeskMigration);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<MigrationMovement>(async () => {
        const { success, response } = await getDeskMigration(migrationId);

        if (success) return response.data.migrationMovements;

        const errorMessage = "Erro ao buscar os detalhes da migração";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
    [getDeskMigration, migrationId],
  );

  return {
    dataSource,
    deskMigration,
    hasError,
    loading,
    migrationId,
  };
};
