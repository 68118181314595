import * as yup from "yup";

export const preticketsFormValidationSchema = (
  orderAmount?: number,
  isCounterpartyCorrect?: boolean,
) => {
  if (!orderAmount)
    return yup.object({
      counterpartyIdentification: yup
        .string()
        .required("Este campo é obrigatório")
        .test(
          "is-counterparty-correct",
          "A identificação da contraparte está incorreta.",
          (value) => isCounterpartyCorrect !== false ? !!value : false,
        ),
      orderId: yup.number().notRequired(),
      amount: yup
        .number()
        .required("Este campo é obrigatório")
        .moreThan(0, "O valor precisa ser maior do que zero"),
      currency: yup.string().required("Este campo é obrigatório"),
      flowType: yup.string().nullable().required("Este campo é obrigatório"),
      natureId: yup.number().nullable().required("Este campo é obrigatório"),
      channelInput: yup.string().required("Este campo é obrigatório"),
    });

  return yup.object({
    amount: yup
      .number()
      .required("Este campo é obrigatório")
      .moreThan(0, "O valor precisa ser maior do que zero")
      .max(
        orderAmount,
        `Escolha um valor menor ou igual ao valor disponível da ordem.`,
      ),
    currency: yup.string().required("Este campo é obrigatório"),
    flowType: yup.string().nullable().required("Este campo é obrigatório"),
    natureId: yup.number().nullable().required("Este campo é obrigatório"),
    orderId: yup.number().required("Este campo é obrigatório"),
    channelInput: yup.string().required("Este campo é obrigatório"),
    counterpartyIdentification: yup
      .string()
      .required("Este campo é obrigatório")
      .test(
        "is-counterparty-correct",
        "A identificação da contraparte está incorreta.",
        (value) => isCounterpartyCorrect !== false ? !!value : false,
      ),
  });
};

export const defaultValues = {
  amount: 0,
  currency: "USD",
  channelInput: "B2C",
};
