import { ODropdownList } from "@maestro/react";
import styled from "styled-components";

export const MaxHeightODropdownList = styled(ODropdownList)`
  max-height: 500px;
  overflow-y: auto;

  min-width: unset !important;

  &.dropdown-hidden {
    visibility: hidden;
    opacity: 0;
  }
`;
