import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { IconButton } from "components/icon-button";
import { service } from "services";
import { PreticketFile } from "services/hubfx/models/responses";
import { exchangePreTicketGenericFieldsGrid } from "./pre-ticket-generic-fields.grid";

export const exchangePreTicketDocumentFilesGrid = {
  datagrid: {
    noDataText: "Nenhum arquivo",
    showColumnHeaders: false,
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
      customizeText: ({ value }) => value ?? "Arquivo não informado",
    },
    {
      cellRender: ({ data }) =>
        !!data.key && (
          <IconButton
            icon={{
              category: "fal",
              icon: "fa-download",
            }}
            dataAction="cambio_pre_ticket_documentos:botao:baixar_arquivo"
            dataLabel="baixar_arquivo"
            onClick={async () => {
              try {
                if (!data.key) return;
                const { data: blob } = await service.hubFx.downloadFile({
                  key: data.key,
                });

                helpers.downloadBlobFile(data.name ?? "", blob);
              } catch {
                OToastManager.danger("Não foi possível baixar arquivo.");
              }
            }}
          >
            Baixar arquivo
          </IconButton>
        ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: ({
      data: {
        data: { genericFields },
      },
    }) => (
      <>
        {!!genericFields?.length && (
          <ODataGridGenerator
            grid={exchangePreTicketGenericFieldsGrid}
            dataSource={genericFields}
          />
        )}
      </>
    ),
  },
} satisfies ODataGridGeneratorConfig<PreticketFile>;
