import {
  OCheckbox,
  OCheckboxGroup,
  OFilterItem,
  OLabel,
  OTypography,
} from "@maestro/react";
import { PixKeyStatus } from "../../../../pix-keys.type";
import { MapperPixKeyStatus } from "../../../../pix-keys.utils";

export const StatusFilterItem = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Status</OTypography>
      <OCheckboxGroup
        className="d-flex flex-column align-items-center w-100"
        data-filterparam="status"
      >
        {Object.values(PixKeyStatus).map((status) => (
          <div
            className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom"
            key={status}
          >
            <OLabel htmlFor={`status_${status}`}>
              {MapperPixKeyStatus[status].label}
            </OLabel>
            <OCheckbox id={`status_${status}`} value={status} size="sm" />
          </div>
        ))}
      </OCheckboxGroup>
    </OFilterItem>
  );
};
