import {
  DocumentTypeSelector,
  PendencyStatusSelector,
  PendencyTypeSelector,
  PropertyTypeSelector,
  StepStatusSelector,
  StepTypeSelector,
} from "../../../selects";
import { ruleEditorFormFields } from "./rule-editor.form";
import { RuleEditorField } from "./rule-editor.types";

export const ruleEditorFields: Record<
  "step" | "pendency" | "property",
  RuleEditorField[]
> = {
  step: [
    {
      key: "stepTypes",
      Component: () => <StepTypeSelector {...ruleEditorFormFields.stepTypes} />,
      availableEventTypes: [
        "PENDENCY_CHANGED_STATUS",
        "PENDENCY_CREATED",
        "PROPERTY_MODIFIED",
        "STEP_CHANGED_STATUS",
        "STEP_CREATED",
      ],
    },
    {
      key: "stepPreviousStatuses",
      Component: () => (
        <StepStatusSelector {...ruleEditorFormFields.stepPreviousStatuses} />
      ),
      availableEventTypes: ["STEP_CHANGED_STATUS"],
    },
    {
      key: "stepStatuses",
      Component: () => (
        <StepStatusSelector {...ruleEditorFormFields.stepStatuses} />
      ),
      availableEventTypes: [
        "PENDENCY_CHANGED_STATUS",
        "PENDENCY_CREATED",
        "PROPERTY_MODIFIED",
        "STEP_CHANGED_STATUS",
        "STEP_CREATED",
      ],
    },
  ],
  pendency: [
    {
      key: "pendencyTypes",
      Component: () => (
        <PendencyTypeSelector
          {...ruleEditorFormFields.pendencyTypes}
          valueType="name"
        />
      ),
      availableEventTypes: ["PENDENCY_CHANGED_STATUS", "PENDENCY_CREATED"],
    },
    {
      key: "pendencyPreviousStatuses",
      Component: () => (
        <PendencyStatusSelector
          {...ruleEditorFormFields.pendencyPreviousStatuses}
        />
      ),
      availableEventTypes: ["PENDENCY_CHANGED_STATUS"],
    },
    {
      key: "pendencyStatuses",
      Component: () => (
        <PendencyStatusSelector {...ruleEditorFormFields.pendencyStatuses} />
      ),
      availableEventTypes: ["PENDENCY_CHANGED_STATUS", "PENDENCY_CREATED"],
    },
  ],
  property: [
    {
      key: "propertyTypes",
      Component: () => (
        <PropertyTypeSelector
          {...ruleEditorFormFields.propertyTypes}
          valueType="name"
        />
      ),
      availableEventTypes: ["PROPERTY_MODIFIED"],
    },
    {
      key: "documentTypes",
      Component: () => (
        <DocumentTypeSelector
          {...ruleEditorFormFields.documentTypes}
          valueType="name"
        />
      ),
      availableEventTypes: ["PROPERTY_MODIFIED"],
    },
  ],
};
