import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminPreticketBody } from "services/hubfx/models/requests/post-admin-pretickets.request";
import { AccOrder } from "services/hubfx/models/responses/get-admin-orders.response";
import { GetAdminNatureResponse } from "services/hubfx/nature/models/responses";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  defaultValues,
  preticketsFormValidationSchema,
} from "./exchange-product-preticket-create.schemas";
import { mapFlowType } from "./exchange-product-preticket-create.utils";

export const ExchangeProductPreticketCreatePage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState<AccOrder[]>([]);
  const [order, setOrder] = useState<AccOrder>();

  const [natures, setNatures] = useState<GetAdminNatureResponse>();

  const [isCounterpartyCorrect, setIsCounterpartyCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { currencies, mapSymbol } = useCurrencies();

  const form = useForm<PostAdminPreticketBody>({
    resolver: yupResolver(
      preticketsFormValidationSchema(
        order?.notAllocatedAmount,
        isCounterpartyCorrect,
      ),
    ),
    defaultValues,
  });

  const { handleSubmit, watch, clearErrors } = form;
  const watchCurrency = watch("currency");
  const watchTargetId = watch("counterpartyIdentification");
  const watchOrder = watch("orderId");
  const watchFlowType = watch("flowType");

  const onAdd = useCallback(
    async (values: PostAdminPreticketBody) => {
      try {
        setLoading(true);

        const { counterpartyIdentification, ...formValues } = values;

        if (!counterpartyIdentification) return;

        const payload = {
          ...formValues,
          counterpartyIdentification: counterpartyIdentification.replace(
            /\D/g,
            "",
          ),
        };

        await service.hubFx.postPreticket(payload);

        OToastManager.success("Preticket adicionado com sucesso.");

        navigate(corporateRouter.routes.cambio.product.preTickets.path);
      } catch {
        OToastManager.danger("Erro ao adicionar preticket.");
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        onAdd(formValues);
      }),
    [handleSubmit, onAdd],
  );

  const getOrders = useCallback(
    async (counterpartyIdentification: string, currency: string) => {
      try {
        if (watchFlowType !== "INTERNACAO") return;
        setIsLoading(true);

        const { data: ordersData } = await service.hubFx.postOrders({
          counterpartyIdentification,
          currency,
        });

        if (!ordersData) {
          setIsCounterpartyCorrect(false);
          return;
        }

        setIsCounterpartyCorrect(true);
        clearErrors("counterpartyIdentification");
        setOrders(ordersData);
      } catch {
        setIsCounterpartyCorrect(false);
      } finally {
        setIsLoading(false);
      }
    },
    [clearErrors, watchFlowType],
  );

  const getNatures = useCallback(async () => {
    const { data } = await service.hubFx.nature.getNatures();

    setNatures(data);
  }, []);

  useEffect(() => {
    const targetId = watchTargetId?.replace(/\D/g, "");
    if (targetId?.split("").length === 14) {
      !!watchCurrency && getOrders(targetId, watchCurrency);
      return;
    }
    setIsCounterpartyCorrect(false);
  }, [watchTargetId, watchCurrency, getOrders]);

  useEffect(() => {
    watchOrder && setOrder(orders.find((o) => o.id === Number(watchOrder)));
  }, [watchOrder, orders]);

  useEffect(() => {
    getNatures();
  }, [getNatures]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar rascunho de Pré-Ticket" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Criar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {isLoading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <OCol>
            <ORFieldSelect
              label="Channel"
              name="channelInput"
              id="channelInput"
            >
              {["B2C"]?.map((channel) => (
                <OOption key={channel} value={channel}>
                  {channel}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol>
            <ORFieldSelect label="FlowType" name="flowType" id="flowType">
              {mapFlowType?.map((flow) => (
                <OOption key={flow.value} value={flow.value}>
                  {flow.label}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          {currencies && (
            <OCol>
              <ORFieldSelect label="Moeda" name="currency" id="currency">
                {currencies
                  .filter((curr) => curr.isApproved)
                  ?.map((curr) => (
                    <OOption key={curr.code} value={curr.code}>
                      {curr.description}
                    </OOption>
                  ))}
              </ORFieldSelect>
            </OCol>
          )}
          <OCol>
            <ORFieldInput
              label="CPF / CNPJ"
              name="counterpartyIdentification"
              id="counterpartyIdentification"
              tag="mask"
              maskOption={{
                mask: "00.000.000/0000-00",
              }}
            />
          </OCol>
          {natures && (
            <OCol>
              <ORFieldSelect label="Natureza" name="natureId" id="natureId">
                {natures?.map((nature) => (
                  <OOption key={nature.code} value={nature.id}>
                    <div
                      key={`${nature.id}-div`}
                      className="d-flex flex-column"
                    >
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">{"Id: "}</OTypography>
                        <OTypography type="dark-80">{nature.id}</OTypography>
                      </div>
                      <div key="amount" className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {" Descrição: "}
                        </OTypography>
                        <OTypography key="desc" type="dark-80">
                          {nature.description}
                        </OTypography>
                      </div>
                    </div>
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          {watchFlowType === "INTERNACAO" && (
            <OCol>
              <ORFieldSelect
                label="Ordem"
                name="orderId"
                id="orderId"
                disabled={!orders?.length || !isCounterpartyCorrect}
              >
                {orders?.map((o) => (
                  <OOption key={`${o.id}-option`} value={o.id}>
                    <div key={`${o.id}-div`} className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {"Id da ordem: "}
                        </OTypography>
                        <OTypography type="dark-80">{o.id}</OTypography>
                      </div>
                      <div key="amount" className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {" Valor disponível: "}
                        </OTypography>
                        <OTypography type="dark-80">
                          {masks.currency(
                            o.notAllocatedAmount,
                            mapSymbol(o.currency),
                          )}
                        </OTypography>
                      </div>
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">
                          {" conciliationId: "}
                        </OTypography>
                        <OTypography type="dark-80">
                          {o.conciliationId}
                        </OTypography>
                      </div>
                      <div className="d-flex flex-row gap-2">
                        <OTypography type="dark-60">{" Pagador: "}</OTypography>
                        <OTypography type="dark-80">{o.payer}</OTypography>
                      </div>
                    </div>
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          <OCol>
            <ORFieldInput
              tag="currency"
              symbol={mapSymbol(watchCurrency)}
              label="Valor total"
              name="amount"
              id="amount"
              disabled={watchFlowType === "INTERNACAO" && !watchOrder}
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
