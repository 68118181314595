import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldSelect,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoLeadByIdContextsByProcessorContextId } from "../../../../../../routes/workflow.route-params";
import {
  WorkflowUpdateContextStatusFormValues,
  statusOptions,
  workflowUpdateContextStatusValidationSchema,
} from "./workflow-update-context-status.schemas";

export const WorkflowUpdateContextStatusPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<WorkflowUpdateContextStatusFormValues>({
    resolver: yupResolver(workflowUpdateContextStatusValidationSchema),
  });

  const { handleSubmit } = form;

  const { processorContextId, id, processorId } =
    useParams<WorkflowProdutoLeadByIdContextsByProcessorContextId>();

  if (!processorContextId || !id || !processorId)
    throw new Error("No processorContextId, id or processorId");

  const {
    value: outputs,
    hasError,
    loading: loadingOutputs,
    callService: getProcessorOutputs,
  } = useServiceCall(service.hubCreditManager.getProcessorOutputs);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);
          await service.hubCreditManager.updateProcessorContextStatus({
            processorContextId: +processorContextId,
            ...values,
          });

          OToastManager.success("Status atualizado com sucesso!");
        } catch {
          OToastManager.danger("Houve um problema ao atualizar status");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, processorContextId],
  );

  useEffect(() => {
    !outputs && getProcessorOutputs(processorId);
  }, [getProcessorOutputs, processorId, outputs]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar status do contexto" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="contexto_atualizar_status:botao:enviar"
          dataLabel="enviar"
          onClick={onSubmit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldSelect
                id="newStatus"
                name="newStatus"
                label="Novo status"
                dataAction="atualizar_status_contexto:select:novo_status"
                dataLabel="novo_status"
              >
                {Object.entries(statusOptions).map(([value, label]) => (
                  <OOption key={value} value={value}>
                    {label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>

          <ORow>
            <OCol className="d-flex align-items-center">
              <ORFieldSelect
                id="processorOutputId"
                name="processorOutputId"
                label="Output"
                dataAction="atualizar_status_contexto:select:output"
                dataLabel="output"
                key={outputs?.length}
              >
                {outputs?.map((output) => (
                  <OOption key={output.id} value={output.id}>
                    {output.description}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingOutputs && <OLoader />}
              {hasError && (
                <OButton
                  type="default"
                  outline
                  dataLabel="tentar_novamente_outputs"
                  dataAction="atualizar_status_contexto:botao:tentar_novamente_outputs"
                  onClick={() => getProcessorOutputs(processorId)}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
