import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { agroProposalsGrid } from "./monitor-agro-proposals.grid";

export const MonitorAgroProposalsPage = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(() =>
        service.b2bAgro
          .getProposals()
          .then(({ data }) => {
            return data ?? [];
          })
          .catch(() => {
            throw new Error("Erro ao buscar propostas");
          }),
      ),
    [],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Propostas Agro" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={agroProposalsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
