import { OCard, OTypography } from "@maestro/react";
import styled from "styled-components";

export const OCardStyled = styled(OCard)`
  width: 200px;
  height: 70px;
`;

export const OTypographyStyled = styled(OTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
