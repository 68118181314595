import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { loanAgreementsGrid } from "../../../components/loan-agreement/loan-agreements.grid";

const dataSource = dataSourceCustomStoreGenerator(() => {
  return service.hubLoan.getAgreement().catch(() => {
    throw new Error("Erro ao buscar convênios.");
  });
});

export const LoanAgreementsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Convênios" />}
      actions={
        <>
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_convenios:botao:cadastrar_perfis"
            dataLabel="cadastrar_perfis"
            href={
              corporateRouter.routes.emprestimos.product.agreements
                .createProfile.path
            }
            role={roles.emprestimos.product.agreementsCreateProfilePage.role}
            outline
          >
            Cadastrar Perfis
          </RouterButton>
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_convenios:botao:adicionar"
            dataLabel="adicionar"
            href={
              corporateRouter.routes.emprestimos.product.agreements.add.path
            }
            role={roles.emprestimos.product.agreementsAddPage.role}
            outline
          >
            Adicionar
          </RouterButton>
        </>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={loanAgreementsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
