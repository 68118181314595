import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GridTemplate } from "templates/grid-template";
import { FormProvider, useForm } from "react-hook-form";
import { useMemo } from "react";
import { creditConciliationGrid } from "./credit-conciliation.grid";
import { CustomPagination } from "./_compose/custom-pagination";
import { useCreditConciliation } from "./credit-conciliation.hook";
import {
  CreditConciliationApprovalsFormValues,
  CreditConciliationFormDefaulValues,
} from "./credit-conciliation.utils";
import { CreditConciliationSearch } from "./_compose/search";

export const CreditConciliationPage = () => {
  const {
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    setFindStatus,
    setFindReceivedDate,
    setFundTaxId,
    setFilter,
    funds,
  } = useCreditConciliation();

  const form = useForm<CreditConciliationApprovalsFormValues>({
    defaultValues: CreditConciliationFormDefaulValues,
  });

  const { handleSubmit } = form;

  const filter = useMemo(
    () =>
      handleSubmit((values) => {
        setFindReceivedDate(values.findReceivedDate);
        setFindStatus(values.findStatus ?? []);
        setFilter(true);
        return setFundTaxId(values.findFundTaxId);
      }),
    [handleSubmit, setFilter, setFindReceivedDate, setFindStatus, setFundTaxId],
  );
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Liquidação por Repasse" />}
      gridRef={gridRef}
      actions={
        <FormProvider {...form}>
          <CreditConciliationSearch onSubmit={filter} funds={funds ?? []} />
        </FormProvider>
      }
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={creditConciliationGrid}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[10, 20, 30]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
    </GridTemplate>
  );
};
