import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

export const removeStepTypeConfirmationModalId = (id: number) =>
  `remove-step-type-confirmation-modal-${id}`;

export interface RemoveStepTypeConfirmationModalProps {
  stepType: HubEnergy.DiligenceStepTypeResponse;
  onConfirm: () => void;
}

export const RemoveStepTypeConfirmationModal = ({
  stepType,
  onConfirm,
}: RemoveStepTypeConfirmationModalProps) => {
  const modalId = removeStepTypeConfirmationModalId(stepType.id);

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Remover tipo de step"
      divider={false}
      onConfirm={() => {
        onConfirm();
        modalManager.hide(modalId);
      }}
      {...datagridModalFix}
    >
      <OTypography size="md" style={{ whiteSpace: "normal" }}>
        Tem certeza que deseja remover o tipo de step {stepType.name}?
      </OTypography>
    </OConfirmationModal>
  );
};
