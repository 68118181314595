import { OCard, OCardBody } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../../../../components/validation-error-component";
import {
  BillingReportGroupsComponent,
  NotifyInvoicedBillingReportButton,
  SyncBillingReportWithNesButton,
} from "./_compose";
import { billingReportFields } from "./billing-report.details";
import { useBillingReport } from "./billing-report.hook";

export const BillingReport = () => {
  const {
    billingReportId,
    getBillingReport,
    fetchError,
    fetchHasError,
    fetchLoading,
    value,
  } = useBillingReport();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Relatório de Faturamento" />}
      actions={
        <>
          <SyncBillingReportWithNesButton billingReportId={billingReportId} />
          <NotifyInvoicedBillingReportButton
            billingReportId={billingReportId}
          />
        </>
      }
    >
      <div className="d-flex flex-column gap-4 position-relative">
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <OCard>
              <OCardBody>
                <ValidationErrorComponent
                  error={fetchError}
                  title="Erro ao buscar o relatório"
                  callback={getBillingReport}
                />
              </OCardBody>
            </OCard>
          }
          value={value}
          render={(_billingReport) => (
            <>
              <DetailsCard
                fields={billingReportFields}
                value={_billingReport}
              />
              <BillingReportGroupsComponent billingReport={_billingReport} />
            </>
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
