import { modalManager, OButton, OToastManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useCallback } from "react";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { useBatchFileTemplateColumns } from "../../../../../../../../components/hooks/use-workflow-batch-template.context";
import { useWorkflow } from "../../../../../../../../components/hooks/use-workflow.context";
import { ADD_COLUMN_MODAL_ID } from "../add-column-modal/add-column-modal.component";
import { columnsGrid } from "./columns-grid.grid";

export const ColumnsGrid = () => {
  const { setSelectedTemplateColumn, id } = useBatchFileTemplateColumns();

  const gridRef = useGridRef();

  const { setSelectedConditions } = useWorkflow();

  const { show } = modalManager;

  const removeColumn = useCallback(
    async (batchFileTemplateColumnId: number) => {
      try {
        await service.hubCreditManager.removeBatchFileTemplateColumns({
          batchFileTemplateColumnId,
        });

        await service.hubCreditManager.getBatchFileTemplateColumns(id);

        OToastManager.success("A coluna foi removida com sucesso.");
      } catch {
        OToastManager.danger(
          "Ocorreu um erro ao remover a coluna do template. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [id],
  );

  return (
    <div className="mt-4">
      <GridTemplate
        pageTitle={<PageTitle title="Colunas" />}
        actions={
          <OButton
            dataAction="batch_file_template:botao:adicionar_coluna"
            dataLabel="adicionar_coluna"
            onClick={() => {
              show(ADD_COLUMN_MODAL_ID);
            }}
          >
            Adicionar
          </OButton>
        }
        gridRef={gridRef}
        showRefreshButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={columnsGrid(
            id,
            removeColumn,
            setSelectedConditions,
            setSelectedTemplateColumn,
          )}
        />
      </GridTemplate>
    </div>
  );
};
