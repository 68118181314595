import { env } from "utils/environments";

export const accEndpoints = {
  admin: {
    common: `${env.API_ACC}/api/admin/common`,
    payers: `${env.API_ACC}/api/admin/common/payer-types`,
    countries: `${env.API_ACC}/api/admin/country/approved`,
    counterparty: `${env.API_ACC}/api/admin/counterparty`,
    pretickets: `${env.API_ACC}/api/admin/preticket`,
    acc: {
      generateContract: `${env.API_ACC}/api/admin/acc/generate-contract`,
      proposals: `${env.API_ACC}/api/admin/acc/proposals`,
      orders: `${env.API_ACC}/api/admin/acc-settlement/available-orders`,
    },
    customer: {
      trades: `${env.API_ACC}/api/admin/customer/acc/trade`,
      simulate: `${env.API_ACC}/api/admin/customer/acc/simulate`,
      tradesById: (id: string) =>
        `${env.API_ACC}/api/admin/customer/acc/trade/${id}`,
      counterpartiesDetails: `${env.API_ACC}/api/admin/customer/acc/counterparty`,
      proposals: `${env.API_ACC}/api/admin/customer/acc/proposal`,
      simulateSettlement: `${env.API_ACC}/api/admin/customer/acc-settlement/interest-payment/simulate`,
      preticketById: (id: string) =>
        `${env.API_ACC}/api/admin/customer/pre-ticket/${id}`,
      orderById: (conciliationId: string) =>
        `${env.API_ACC}/api/admin/customer/acc-settlement/order-conciliation/${conciliationId}`,
    },
    nature: {
      endpoint: `${env.API_ACC}/api/admin/nature`,
      byId: (id: string | number) => `${env.API_ACC}/api/admin/nature/${id}`,
      documentGroups: {
        endpoint: `${env.API_ACC}/api/admin/nature/document-groups`,
        byId: (id: string | number) =>
          `${env.API_ACC}/api/admin/nature/document-groups/${id}`,
      },
      documentConfigs: {
        endpoint: `${env.API_ACC}/api/admin/nature/document-configs`,
        byId: (id: string | number) =>
          `${env.API_ACC}/api/admin/nature/document-configs/${id}`,
      },
      genericFieldTypes: {
        endpoint: `${env.API_ACC}/api/admin/nature/generic-field-types`,
        byId: (id: string | number) =>
          `${env.API_ACC}/api/admin/nature/generic-field-types/${id}`,
      },
    },
  },
  odata: {
    adminACCCounterpartiesOData: `${env.API_ACC}/api/admin/odata/acc-counterparties`,
    adminACCProposalsOData: `${env.API_ACC}/api/admin/odata/acc-proposals`,
    adminACCTradesOData: `${env.API_ACC}/api/admin/odata/acc-trades`,
    adminACCSettlementsOData: `${env.API_ACC}/api/admin/odata/acc-settlements`,
    adminACCCustomerSettlementsOData: `${env.API_ACC}/api/admin-customer/odata/acc-settlements`
  },
};
