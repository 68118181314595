import { OCol, ORow, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { ProposedCreditLine } from "services/hubcreditmanager/models/responses";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { productCreditLineMapper } from "../../../workflow-leads.utils";

export const workflowCreditLineProposalDetailsFields = (
  proposal: ProposedCreditLine | undefined,
) => {
  const fields: DetailsFields = {
    proposedCreditLine: {
      items: [
        {
          label: "Valor aprovado",
          value:
            proposal?.approvedAmountType === "GUARANTEED_DISCOUNT"
              ? masks.percentage(proposal.approvedAmount)
              : masks.currency(
                  proposal?.approvedAmount,
                  getValueFromMap(
                    currencySymbol,
                    proposal?.approvedAmountCurrencyType,
                  ) ?? currencySymbol.BRL,
                  ".",
                ),
          grid: { md: 6, lg: 4, xl: 3 },
        },
        {
          label: "Tipo do Valor aprovado",
          value: proposal?.approvedAmountType,
          grid: { md: 6, lg: 4, xl: 3 },
          visible: !!proposal?.approvedAmountType,
        },
        {
          label: "Linha de crédito",
          value: getValueFromMap(
            productCreditLineMapper,
            proposal?.creditLine.identification,
          ),
          grid: { md: 6, lg: 4, xl: 3 },
        },
        {
          label: "Garantias",
          value: Array.from(
            new Set(
              proposal?.requiredCollaterals?.map((c) => c.collateralType),
            ),
          ).join(", "),
          grid: { md: 6, lg: 4, xl: 3 },
          visible: !!proposal?.requiredCollaterals?.length,
        },
        {
          label: "Intervalo de parcelamento",
          value: `${proposal?.proposedRate?.quotes?.[0].minTerm} ~ ${proposal?.proposedRate?.quotes?.[0].maxTerm} parcelas`,
          grid: { md: 6, lg: 4, xl: 3 },
          visible:
            !!proposal?.proposedRate?.quotes?.[0].minTerm &&
            !!proposal?.proposedRate?.quotes?.[0].maxTerm,
        },
        {
          label: "Taxa de juros",
          value: `${masks.percentage(
            proposal?.proposedRate?.quotes?.[0].installmentsTax,
          )} a.m.`,
          grid: { md: 6, lg: 4, xl: 3 },
          visible: !!proposal?.proposedRate?.quotes?.[0].installmentsTax,
        },
        {
          label: "Garantidores",
          value: (
            <div className="d-flex flex-column gap-3">
              {proposal?.requiredCollaterals
                .filter((rc) => rc.collateralType === "GARANTIDOR")
                .map((collateral) => (
                  <ORow key={collateral.guarantor.legalEntity.taxId}>
                    <OCol>
                      <OTypography>
                        {collateral.guarantor.legalEntity.name}
                      </OTypography>
                    </OCol>
                    <OCol>
                      <OTypography>
                        {masks.cpfCnpj(collateral.guarantor.legalEntity.taxId)}
                      </OTypography>
                    </OCol>
                  </ORow>
                ))}
            </div>
          ),
          grid: { md: 6 },
          visible: !!proposal?.requiredCollaterals.find(
            (rc) => rc.collateralType === "GARANTIDOR",
          ),
        },
      ],
    },
    counterproposal: {
      title: "Contraproposta",
      visible: !!proposal?.counterproposal,
      items: [
        {
          label: "Desconto desejado",
          value: proposal?.counterproposal?.desiredDiscount,
          grid: { md: 6, lg: 4, xl: 3 },
        },
        {
          label: "Número de parcelas desejadas",
          value: proposal?.counterproposal?.desiredTerm,
          grid: { md: 6, lg: 4, xl: 3 },
        },
        {
          label: "Infos adicionais",
          value: proposal?.counterproposal?.additionalInfo,
          grid: { md: 6, lg: 4, xl: 3 },
        },
      ],
    },
  };
  return fields;
};
