import { OCol, ORFieldInputDateRange } from "@maestro/react";

export const DateInputs = () => {
  return (
    <>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="tradeDate"
          name="tradeDate"
          label="Data trade"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="disbursementDate"
          name="disbursementDate"
          label="Data desembolso"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="maturityDate"
          name="maturityDate"
          label="Data vencimento"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="createdAt"
          name="createdAt"
          label="Criado em"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="updatedAt"
          name="updatedAt"
          label="Atualizado em"
        />
      </OCol>
    </>
  );
};
