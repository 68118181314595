import {
  ICategory,
  OCheckbox,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  ONotification,
  OOption,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useProcessorConfigUserActionsPageContent } from "./processor-config-user-actions.hook";

export const ProcessorConfigUserActionsPage = () => {
  const {
    bigNumbers,
    buttonTypeOptions,
    form,
    iconOptions,
    loading,
    selectedProcessorConfig,
    workflowConfigViews,
    updateUserActions,
  } = useProcessorConfigUserActionsPageContent();

  if (!selectedProcessorConfig?.processor.hasUserAction) {
    return (
      <ONotification>
        Esse processador não contém ações de Usuário.
      </ONotification>
    );
  }

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Ações de Usuários"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={updateUserActions}>
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        {loading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          {selectedProcessorConfig?.processor.hasUserAction && (
            <ORow>
              <OCol md={6} sm={12}>
                <ORFieldInput
                  id="actionButtonName"
                  name="actionButtonName"
                  label="Descrição do botão de ação"
                  labelSize="md"
                  tag="text"
                  placeholder="Preencher"
                />
              </OCol>
              <OCol md={6} sm={12}>
                <SelectSearchField
                  id="actionIcon"
                  name="actionIcon"
                  label="Ícone"
                  labelSize="md"
                  options={iconOptions.map((icon) => ({
                    label: icon.value,
                    value: icon.value,
                  }))}
                />
              </OCol>
              <OCol md={6} sm={12}>
                <ORFieldSelectMultiple
                  id="viewUserActions"
                  name="viewUserActions"
                  label="Ação disponível em qual view?"
                  labelSize="md"
                  key={workflowConfigViews?.map((wfView) => wfView.id).join("")}
                >
                  {workflowConfigViews?.map((wfView) => (
                    <OOptionMultiple
                      key={`processor-config-view-option-${selectedProcessorConfig?.id}-${wfView.id}`}
                      value={wfView.view.type}
                    >
                      {wfView.view.name}
                    </OOptionMultiple>
                  ))}
                </ORFieldSelectMultiple>
              </OCol>
              <OCol md={6} sm={12}>
                <SelectSearchField
                  id="actionButtonType"
                  name="actionButtonType"
                  label="Cor do botão"
                  labelSize="md"
                  key={`action-button-type-${buttonTypeOptions?.length}`}
                  options={buttonTypeOptions.map((button) => ({
                    label: button.value,
                    value: button.value,
                  }))}
                />
              </OCol>
              <OCol md={6} sm={12}>
                <ORFieldSelect
                  id="bigNumberRelated"
                  name="bigNumberRelated"
                  label="Relacionado com qual BigNumber ?"
                  labelSize="md"
                  key={bigNumbers?.map((bn) => bn.id).join("")}
                >
                  <OOption value={0} key="bigNumberRelated_0">
                    <div className="d-flex gap-2">
                      <OTypography>Nenhum</OTypography>
                    </div>
                  </OOption>

                  {bigNumbers?.map((bn) => (
                    <OOption value={bn.id} key={bn.id}>
                      <div className="d-flex gap-2">
                        <OTypography>{bn.name}</OTypography>
                        <OIcon
                          category={bn.iconName.split("-")[0] as ICategory}
                          icon={bn.iconName}
                          size="md"
                        />
                      </div>
                    </OOption>
                  ))}
                </ORFieldSelect>
              </OCol>
              <OCol md={3} sm={6}>
                <ORFieldCheckboxGroup
                  id="hasActionWithoutContext"
                  name="hasActionWithoutContext"
                >
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <OCheckbox
                      size="xs"
                      id="has-action-without-context-checkbox"
                      value="1"
                    />
                    <OLabel htmlFor="has-action-without-context-checkbox">
                      Ação disponível sem contexto?
                    </OLabel>
                  </div>
                </ORFieldCheckboxGroup>
              </OCol>
              <OCol md={3} sm={6}>
                <ORFieldCheckboxGroup
                  id="isActionButtonOutlined"
                  name="isActionButtonOutlined"
                >
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <OCheckbox
                      size="xs"
                      id="is-action-button-outlined-checkbox"
                      value="1"
                    />
                    <OLabel htmlFor="is-action-button-outlined-checkbox">
                      Retirar fundo do botão?
                    </OLabel>
                  </div>
                </ORFieldCheckboxGroup>
              </OCol>
            </ORow>
          )}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
