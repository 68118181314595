import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORow,
} from "@maestro/react";
import * as yup from "yup";
import { SelectSearchIndicators } from "./indicators-select-search";
import { useIndicationFields } from "./use-indication-fields.component";

interface IndicationFieldsProps {
  isEditing?: boolean;
}

export const IndicationFields = ({
  isEditing = false,
}: IndicationFieldsProps) => {
  const {
    toggleHasIndicatorCheckbox,
    indicators,
    indicatorOnFieldWatch,
    setValue,
    setOperatorName,
    setOriginatorName,
    selectedIndicators,
    wasIndicated,
  } = useIndicationFields(isEditing);

  return (
    <>
      <ORFieldCheckboxGroup id="hasIndicator" name="hasIndicator" required>
        <div className="d-flex gap-2 align-items-center">
          <OCheckbox
            id="hasIndicatorCheck"
            size="sm"
            value="check"
            onClick={(checked) =>
              toggleHasIndicatorCheckbox(!checked.currentTarget.checked)
            }
          />
          <OLabel htmlFor="hasIndicatorCheck">
            Este originador foi indicado.
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
      <ORow>
        <OCol sm={6}>
          <SelectSearchIndicators
            disabled={!wasIndicated}
            indicators={indicators}
            watchValue={indicatorOnFieldWatch}
            setValue={setValue}
            setOperatorName={setOperatorName}
            setOriginatorName={setOriginatorName}
            formField={{
              name: "indicatorOnField",
              id: "indicatorOnField",
              label: "Indicador",
              placeholder: "Informar",
              validator: yup.string(),
              initialValue: "",
              tag: "search" as const,
              tooltip:
                "Procure por parte do nome ou pelos dígitos do CNPJ/CPF do indicador",
              tooltipMaxWidth: "300px",
              tooltipPosition: "top-right" as const,
            }}
          />
        </OCol>
        {selectedIndicators}
      </ORow>
    </>
  );
};
