import { OLoader } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useAdequacyOptionSelector } from "./adequacy-option-selector.hook";

export const AdequacyOptionSelector = () => {
  const { adequacyOptions, getAdequacyOptions, hasFetchError, loading } =
    useAdequacyOptionSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchMultiple
        options={adequacyOptions}
        id="adequacyOptionIds"
        name="adequacyOptionIds"
        label="Adequadoras recomendadas"
        placeholder="Selecionar"
      />
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={() => getAdequacyOptions()} />
      )}
    </div>
  );
};
