interface DetailsTemplateProps {
  pageTitle?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const DetailsTemplate = ({
  pageTitle,
  actions,
  children,
}: DetailsTemplateProps) => {
  return (
    <div>
      {pageTitle}

      {actions && (
        <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
          {actions}
        </div>
      )}

      <div className="position-relative">{children}</div>
    </div>
  );
};
