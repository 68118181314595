import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams } from "../../../../routes/cadastro.route-params";

export const useGuarantorDetails = () => {
  const { guarantorUuid } =
    useParams<CadastroClienteAvalistasDetalhesByGuarantorUuidRouteParams>();
  if (!guarantorUuid)
    throw new Error("Uuid do workflow do avalista não foi encontrado na url");

  const [guarantor, setGuarantor] =
    useState<HubOnboardingRelationships.Guarantor.Guarantor>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getGuarantor = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await service.onboardingRelationships.getGuarantor({
        getOnboardingByUuidQueryBody: { onboardingUuid: guarantorUuid },
      });
      setGuarantor(data);
      setHasError(false);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o avalista");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [guarantorUuid]);

  useEffect(() => {
    getGuarantor();
  }, [getGuarantor, guarantorUuid]);

  return {
    guarantor,
    guarantorUuid,
    getGuarantor,
    hasError,
    loading,
  };
};
