import { OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { AddUserLegalRepresentive } from "services/onboarding-relationships/models";
import { CadastroClienteProcuracoesByUuidRouteParams } from "../../../../../../../routes/cadastro.route-params";

export const useRepresentativeAdd = ({
  uuid,
}: CadastroClienteProcuracoesByUuidRouteParams) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<AddUserLegalRepresentive>();
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        workflowUuid: uuid,
        legalRepresentative: {
          email: values.email,
          name: values.name,
          phoneNumber: values.phoneNumber,
          taxId: values.taxId,
        },
      };

      await service.onboardingRelationships.addUser(payload);

      OToastManager.success("Representante cadastrado com sucesso");

      navigate(
        corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.path(
          { uuid },
        ),
      );
    } catch (err) {
      const message = isAxiosError(err)
        ? err.response?.data.failures[0].errorMessage
        : "Erro para salvar os dados";
      OToastManager.danger(message);
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    submit,
    loading,
  };
};
