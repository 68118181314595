import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, customersGrid } from "./customers.grid";

export const B2BCustomersPage = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(identification),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Clientes" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={customersGrid}
      />
    </GridTemplate>
  );
};
