import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PatchByIdAdminSettlementBody } from "services/acc/models/requests/patch-by-id-admin-preticket.request";
import { AdminPayerTypesResponse } from "services/acc/models/responses/get-admin-payer-types.response";
import { GetCommonCountriesResponse } from "services/acc/models/responses/get-common-countries.response";
import { ChannelOdataSettlementsValue } from "services/acc/odata/models/responses/get-admin-acc-settlements-o-data.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { settlementsFormValidationSchema } from "./admin-contingency-acc-settlements-edit.schemas";
import {
  ORFieldInputs,
  payerGroupMap,
  statusMap,
} from "./admin-contingency-acc-settlements-edit.utils";

export const AdminContingencyACCSettlementEditPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payerTypes, setPayerTypes] = useState<AdminPayerTypesResponse>();
  const [countries, setCountries] = useState<GetCommonCountriesResponse>();

  const { value: preticket } =
    useParseFromSearchParams<ChannelOdataSettlementsValue>("data");

  const form = useForm<PatchByIdAdminSettlementBody>({
    resolver: yupResolver(settlementsFormValidationSchema),
    defaultValues: { ...preticket },
  });

  const { handleSubmit, watch, reset } = form;

  const watchStatus = watch("preTicketStatus");

  const onEdit = useCallback(
    async (values: PatchByIdAdminSettlementBody & { preTicketId: number }) => {
      try {
        setLoading(true);

        if (!preticket) return;

        const {
          preTicketFxAvailableAmount,
          preTicketPayerType,
          preTicketClientLink,
          preTicketCountry,
          preTicketId,
          preTicketStatus,
        } = values;

        const payload = {
          preTicketClientLink,
          preTicketCountry,
          preTicketId,
          preTicketStatus,
          preTicketFxAvailableAmount: preTicketFxAvailableAmount ?? null,
          preTicketPayerType: preTicketPayerType ?? null,
          counterpartyIdentification:
            preticket.preTicketCounterpartyIdentification,
        };

        await service.acc.patchByIdSettlement(payload);

        OToastManager.success("Preticket atualizado com sucesso.");

        navigate(
          corporateRouter.routes.acc.product.contingency.settlements.path,
        );
      } catch {
        OToastManager.danger("Erro ao atualizar preticket.");
      } finally {
        setLoading(false);
      }
    },
    [navigate, preticket],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        if (preticket?.id) {
          onEdit({ ...formValues, preTicketId: preticket.preTicketId });
        }
      }),
    [preticket, handleSubmit, onEdit],
  );

  const getPayers = useCallback(async () => {
    const { data } = await service.acc.getPayerTypes();

    if (!data) return;

    setPayerTypes(data?.response);
  }, []);

  const getCountries = useCallback(async () => {
    const { data: response } = await service.acc.getCountries();

    if (!response) return;

    setCountries(response.response);
  }, []);

  useEffect(() => {
    getPayers();
    getCountries();
  }, [getCountries, getPayers]);

  useEffect(() => {
    if (!preticket) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { preTicketStatus, preTicketFxAvailableAmount, ...values } =
      preticket;

    const payload = {
      ...values,
      preTicketStatus: watchStatus,
      preTicketFxAvailableAmount: preTicketFxAvailableAmount ?? 0,
    };

    reset(payload);
  }, [preticket, reset, watchStatus]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar liquidação" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldSelect
              label="Status"
              name="preTicketStatus"
              id="preTicketStatus"
            >
              {statusMap?.map((status) => (
                <OOption key={status} value={status}>
                  {status}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          {payerTypes && (
            <OCol>
              <ORFieldSelect
                label="Tipo de pagador"
                name="preTicketPayerType"
                id="preTicketPayerType"
                disabled={watchStatus !== "RASCUNHO"}
              >
                {payerTypes?.map((payerGroup) =>
                  payerGroup.payerTypes?.map((payer) => (
                    <OOption key={payer.code} value={String(payer.code)}>
                      {payer.code} -{" "}
                      {payerGroup.group && payerGroupMap[payerGroup.group]} -{" "}
                      {payer.description}
                    </OOption>
                  )),
                )}
              </ORFieldSelect>
            </OCol>
          )}
          {countries && (
            <OCol>
              <ORFieldSelect
                label="País"
                name="preTicketCountry"
                id="preTicketCountry"
                disabled={watchStatus !== "RASCUNHO"}
              >
                {countries?.map((country) => (
                  <OOption key={country.code} value={String(country.code)}>
                    {country.description}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          {ORFieldInputs.map((item) => (
            <OCol key={`${item.id}-col`}>
              <ORFieldInput
                symbol={preticket?.currency}
                tag={item.mask ? "currency" : "text"}
                disabled={item.blockedAtDraft && watchStatus !== "RASCUNHO"}
                {...item}
              />
            </OCol>
          ))}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
