import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { StepConfigFormComponent, useStepConfigForm } from "../_compose";

export const AddStepConfigPage = () => {
  const { form, loading, submit } = useStepConfigForm();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar configuração de step" />}
      actions={
        <LoadingButton loading={loading} onClick={submit()}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <StepConfigFormComponent />
      </FormProvider>
    </FormTemplate>
  );
};
