import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { OnboardingStatusSelect } from "./onboarding-status-select.component";

export const CedenteForm = () => {
  const { callService, hasError, loading, value } = useServiceCall(
    service.quickfin.portal,
  );

  const { setError } = useFormContext();

  useEffect(() => {
    callService();
  }, [callService]);

  useEffect(() => {
    if (hasError)
      setError("portalId", { message: "Erro ao buscar os parceiros" });
  }, [hasError, setError]);

  return (
    <>
      <OCol xs={12} md={6} xl={4}>
        <OnboardingStatusSelect
          dataAction="formulario_cedente:select:onboarding_completo"
          dataLabel="onboarding_completo"
          id="onboardingCompletoStatus"
          name="onboardingCompletoStatus"
          label="Onboarding completo"
          placeholder="Selecionar"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <OnboardingStatusSelect
          dataAction="formulario_cedente:select:onboarding_simples"
          dataLabel="onboarding_simples"
          id="onboardingSimplesStatus"
          name="onboardingSimplesStatus"
          label="Onboarding simples"
          placeholder="Selecionar"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <OnboardingStatusSelect
          dataAction="formulario_cedente:select:onboarding_pme"
          dataLabel="onboarding_pme"
          id="onboardingContaPMEStatus"
          name="onboardingContaPMEStatus"
          label="Onboarding PME"
          placeholder="Selecionar"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4} align="center">
        <ORFieldSelect
          dataAction="formulario_cedente:select:parceiro"
          dataLabel="parceiro"
          id="portalId"
          name="portalId"
          label="Parceiro de origem"
          placeholder="Selecionar"
          disabled={loading || hasError}
          key={value?.length}
        >
          {value?.map((portal) => (
            <OOption value={portal.id} key={portal.id}>
              {portal.name}
            </OOption>
          ))}
        </ORFieldSelect>
        {loading && <OLoader size="sm" />}
      </OCol>
    </>
  );
};
