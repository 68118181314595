import { OButton } from "@maestro/react";
import styled from "styled-components";

export const FilterOButton = styled(OButton)`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
