import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useState } from "react";
import { service } from "services";

interface RepublishWorkflowStatusButtonProps {
  workflowId: number;
}

export const RepublishWorkflowStatusButton = ({
  workflowId,
}: RepublishWorkflowStatusButtonProps) => {
  const [loading, setLoading] = useState(false);

  const republishWorkflowStatus = useCallback(async () => {
    try {
      setLoading(true);

      await service.hubCreditManager.republishWorkflowStatus({
        ids: [workflowId],
      });

      OToastManager.success(
        "Evento de atualização de status do WorkflowLead republicado com sucesso.",
      );
    } catch {
      OToastManager.danger("Não foi possível republicar evento.");
    } finally {
      setLoading(false);
    }
  }, [workflowId]);

  return (
    <LoadingButton
      loading={loading}
      onClick={() => republishWorkflowStatus()}
      outline
    >
      Republicar
    </LoadingButton>
  );
};
