import { maskIdentification } from "utils/mask"
import { useUnsafeCustomer } from "contexts/customer";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomerSelectorItem } from "../customer-selector.types";
import { displayValueFromCustomer, filterOptions } from "../utils";
import { useFullOptions } from "./full-options.context";
import { useSearchEntity } from "./search-entity.context";
import { useUserCustomers } from "./user-customers.context";

interface CustomerSelectorContext {
  mode: "fetch" | "select";
  value: string | undefined;
  displayValue: string | null | undefined;
  options: CustomerSelectorItem[];
  loading: boolean;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDisplayValue: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  selectOption: (item: CustomerSelectorItem) => void;
}

const customerSelectorContext = createContext({} as CustomerSelectorContext);

interface CustomerSelectorProviderProps {
  children: React.ReactNode;
}

export const CustomerSelectorProvider = ({
  children,
}: CustomerSelectorProviderProps) => {
  // state used for the search
  const [value, setValue] = useState<string>();
  // state used for display only
  const [displayValue, setDisplayValue] = useState<string | null>();
  const [options, setOptions] = useState<CustomerSelectorItem[]>([]);

  const { customer, state } = useUnsafeCustomer();

  const {
    loading: searchEntityLoading,
    setLoading: setSearchEntityLoading,
    debouncedSearchEntity,
  } = useSearchEntity();
  const { loading: userCustomersLoading } = useUserCustomers();
  const { fullOptions, mode } = useFullOptions();

  /** Search for entities */
  useEffect(() => {
    if (mode === "fetch" && value) {
      setSearchEntityLoading(true);
      debouncedSearchEntity(value);
    }
  }, [debouncedSearchEntity, mode, setSearchEntityLoading, value]);

  /** Set options */
  useEffect(() => {
    setOptions(filterOptions(fullOptions, value));
  }, [fullOptions, mode, value]);

  /** Clear input when state is EMPTY. */
  useEffect(() => {
    if (state === "EMPTY") {
      setValue(undefined);
      // manually set `displayValue` in case `value` was already undefined
      // (useEffect wouldn't when the dependency goes undefined -> undefined)
      setDisplayValue(undefined);
    }
  }, [state]);

  /** Update component value based on customer changes. */
  useEffect(() => {
    if (["LOADING", "SELECTED"].includes(state) && !!customer) {
      setDisplayValue(displayValueFromCustomer(customer));
    }
    // INTENTIONAL - DO NOT PUT `state` HERE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const selectOption = useCallback((item: CustomerSelectorItem) => {
    if (item.type === "costumer") {
      setDisplayValue(
        `${maskIdentification(item?.identification)} - ${item?.officialName}`,
      );
    }
  }, []);

  const contextValue = useMemo(
    () =>
      ({
        mode,
        value,
        displayValue,
        options,
        loading: userCustomersLoading || searchEntityLoading,
        setValue,
        setDisplayValue,
        selectOption,
      } as const),
    [
      displayValue,
      mode,
      options,
      searchEntityLoading,
      selectOption,
      userCustomersLoading,
      value,
    ],
  );

  return (
    <customerSelectorContext.Provider value={contextValue}>
      {children}
    </customerSelectorContext.Provider>
  );
};

export const useCustomerSelector = () => useContext(customerSelectorContext);
