import { OIcon } from "@maestro/react";

type PaymentMethodItem = {
  icon: JSX.Element;
  label: string;
  display: string;
};

export const paymentMethodMap: Record<string, PaymentMethodItem> = {
  Boleto: {
    icon: <OIcon category="orq" icon="orq-bank-slip" size="xxxl" />,
    label: "Boleto bancário",
    display: "Boleto",
  },
  DebitoEmConta: {
    icon: <OIcon category="orq" icon="orq-coin" size="xxxl" />,
    label: "Débito em conta",
    display: "DebitoEmConta",
  },
};
