import { OAvatar, OSkeletonAvatar, OTypography } from "@maestro/react";
import { OTruncateTypography } from "components/o-truncate-typography";
import { DataGridCellContentProps } from "../../types";

export const DataGridCellContent = ({
  primary,
  secondary,
  image,
  textWrap = false,
}: DataGridCellContentProps) => {
  return (
    <div
      className={
        "d-flex flex-row gap-2 align-items-center" + textWrap ? "text-wrap" : ""
      }
    >
      {image && (
        <>
          {image.url ? (
            <OAvatar image={image.url} alt={image.alt} />
          ) : (
            <OSkeletonAvatar />
          )}
        </>
      )}
      <div className="d-flex flex-column gap-1 overflow-hidden">
        {textWrap ? (
          <>
            <OTypography>{primary}</OTypography>
            {secondary && (
              <OTypography type="dark-60" size="sm">
                {secondary}
              </OTypography>
            )}
          </>
        ) : (
          <>
            <OTruncateTypography title={primary}>{primary}</OTruncateTypography>
            {secondary && (
              <OTruncateTypography title={secondary} type="dark-60" size="sm">
                {secondary}
              </OTruncateTypography>
            )}
          </>
        )}
      </div>
    </div>
  );
};
