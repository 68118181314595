import { OButton, modalManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceODataStoreGenerator,
} from "components/data-grid";
import { endpoints } from "services/endpoints";
import { GetOdataOnboardingDetailsResponse } from "services/onboarding/odata/models";

export const buildDetailsDataSource = (externalReference?: string) => {
  return dataSourceODataStoreGenerator<
    ODataGridGeneratorConfig<GetOdataOnboardingDetailsResponse["value"][number]>
  >(endpoints.onboarding.odata.onboarding.details, {
    dataSourceOptions: {
      filter: ["ExternalReference", "=", externalReference],
    },
  });
};

export const buildDetailsGrid = (
  setUserAgentText: React.Dispatch<React.SetStateAction<string>>,
  modalId: string,
): ODataGridGeneratorConfig<
  GetOdataOnboardingDetailsResponse["value"][number]
> => ({
  datagrid: {
    noDataText: "Nenhum detalhe encontrado.",
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "CreatedDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de criação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "UpdatedDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de Atualização",
      allowHeaderFiltering: false,
    },
    {
      dataField: "Type",
      dataType: "string",
      caption: "Tipo",
      allowHeaderFiltering: true,
    },
    {
      dataField: "SourceSystem",
      dataType: "string",
      caption: "Sistema de Origem",
      allowHeaderFiltering: true,
    },
    {
      dataField: "AuthIdentification",
      dataType: "string",
      caption: "Usuario que Requisitou",
      allowHeaderFiltering: true,
    },
    {
      dataField: "AuthIdentificationType",
      dataType: "string",
      caption: "Usuario que Requisitou (Tipo)",
      allowHeaderFiltering: true,
    },
    {
      dataField: "Identification",
      dataType: "string",
      caption: "CNPJ",
      allowHeaderFiltering: true,
    },
    {
      dataField: "ImpersonatedBy",
      dataType: "string",
      caption: "Impersonado Por",
      allowHeaderFiltering: true,
    },
    {
      dataField: "AttendedUser",
      dataType: "string",
      caption: "Atendido Por",
      allowHeaderFiltering: true,
    },
    {
      dataField: "Channel",
      dataType: "string",
      caption: "Canal",
      allowHeaderFiltering: true,
    },
    {
      dataField: "Ip",
      dataType: "string",
      caption: "Ip",
      allowHeaderFiltering: true,
    },
    {
      dataField: "WorkflowType",
      dataType: "string",
      caption: "Tipo do Workflow",
      allowHeaderFiltering: true,
    },
    {
      allowSearch: false,
      allowExporting: false,
      caption: "UserAgent",
      type: "buttons",
      cellRender: ({ data }) => (
        <OButton
          onClick={() => {
            setUserAgentText(data.UserAgent);
            modalManager.show(modalId);
          }}
          type="dark"
          outline
          bordered={false}
          dataAction=""
          dataLabel=""
        >
          {data.UserAgent}
        </OButton>
      ),
    },
  ],
});
