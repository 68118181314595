import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { DashboardOnboardingsInfos } from "./dashboard-onboardings-infos.component";
import {
  dashboardOnboardingFormDefaulValues,
  DashboarOnboardingsFormValues,
} from "./dashboard-onboardings.form";
import { dashboardOnboardingsGrid } from "./dashboard-onboardings.grid";
import { DashboardSearch } from "./_compose";

export const DashboardOnboardingsPage = () => {
  const [onboardingType, setOnboardingType] = useState("CEDENTE_COMPLETO");
  const [sacadoIds, setSacadoIds] = useState<number[]>([]);

  const gridRef = useGridRef();

  const form = useForm<DashboarOnboardingsFormValues>({
    defaultValues: dashboardOnboardingFormDefaulValues,
  });
  const { handleSubmit } = form;

  const grid = useMemo(
    () => dashboardOnboardingsGrid(onboardingType),
    [onboardingType],
  );

  const filter = useMemo(
    () =>
      handleSubmit((values) => {
        setOnboardingType(values.onboardingType);
        setSacadoIds(values.sacadoIds);
      }),
    [handleSubmit],
  );
  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Dashboard de Onboardings"
          description="Confira a situação dos fornecedores Risco Sacado"
        />
      }
    >
      <div className="d-flex flex-column gap-4">
        <DashboardOnboardingsInfos
          onboardingType={onboardingType}
          sacadoIds={sacadoIds}
        />
        <GridTemplate
          actions={
            <FormProvider {...form}>
              <DashboardSearch onSubmit={filter} />
            </FormProvider>
          }
          gridRef={gridRef}
          showClearFiltersButton
          showRefreshButton
          showExportButton
        >
          <ODataGridGenerator gridRef={gridRef} grid={grid} />
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
