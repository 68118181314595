import { OTab, OTabHeader, OTypography } from "@maestro/react";
import { useState } from "react";
import { InfoGrid, SubsectionContainer } from "../../../cards.styles";
import { ICategories, ISummary } from "../../invoices.types";
import { MoneyInfos } from "./money-infos/money-infos.component";
import { IResumeProps } from "./resume.types";

export const Resume = ({ summaryData }: IResumeProps) => {
  const [indexView, setIndexView] = useState<number>(0);

  return (
    <SubsectionContainer>
      <div>
        <OTypography>· Resumo</OTypography>
        <OTabHeader>
          {summaryData.map((data: ISummary, index: number) => (
            <OTab
              dataAction={`fatura-cartoes:tab:${data?.title}`}
              dataLabel={`${data?.title}`}
              key={`menu-item-Invoice-Details-Resume-${index}`}
              handleClick={() => setIndexView(index)}
            >
              <OTypography type="dark">{data.title}</OTypography>
            </OTab>
          ))}
        </OTabHeader>
      </div>

      <InfoGrid style={{ marginLeft: "20px" }} columns={2} columnsWidth={395}>
        {summaryData[indexView].categories.map(
          (category: ICategories, index: number) => {
            return (
              <MoneyInfos
                label={category.title}
                value={category.amount}
                items={category.items}
                key={`${category.title}-${index}`}
              />
            );
          },
        )}
      </InfoGrid>
    </SubsectionContainer>
  );
};
