import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { usePortfolioIdField } from "./portfolio-id-field.hook";
import { EstrategiaFormLabels } from "../estrategia-form.types";

export const PortfolioIdField = () => {
  const { portfolios, getPortfolios, loading, hasFetchError } =
    usePortfolioIdField();

  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        dataAction="geral:select:carteiras"
        dataLabel="carteiras"
        id="portfolioId"
        name="portfolioId"
        label={EstrategiaFormLabels.portfolioId}
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={portfolios?.length}
        required
      >
        {portfolios?.map((portfolio) => (
          <OOption key={portfolio.id} value={portfolio.id}>
            {portfolio.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="carteiras:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getPortfolios()}
        />
      )}
    </OCol>
  );
};
