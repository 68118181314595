import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetOwnerReceivablesResponse } from "services/moana/models";

export const discountedGrid: (
  rows: number,
) => ODataGridGeneratorConfig<GetOwnerReceivablesResponse["data"][number]> = (
  rows,
) => ({
  datagrid: {
    noDataText: "Nenhuma cobrança encontrada",
    pager: { visible: false },
    paging: { pageSize: rows },
    allowSorting: false,
  },
  columns: [
    {
      dataField: "receivableNumber",
      caption: "Seu número",
    },
    {
      dataField: "payerName",
      caption: "Devedor",
    },
    {
      dataField: "payerDocumentNumber",
      caption: "Identificação do devedor",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "amountValue",
      caption: "Valor Vencimento",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "maturityDate",
      caption: "Data Vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
  ],
});
