import * as yup from "yup";
import { PostCnabStatementReprocessFileBody } from "services/admin-bankinghub/models";

export const reprocessFileDefaulValues = {
  agency: undefined,
  account: undefined,
  startAt: undefined,
  endAt: undefined,
} satisfies Record<
  keyof Omit<PostCnabStatementReprocessFileBody, "shouldSendVan">,
  unknown
> as any;

export const reprocessFileValidationSchema: yup.ObjectSchema<
  Omit<PostCnabStatementReprocessFileBody, "shouldSendVan">
> = yup.object({
  agency: yup.string().required("Este campo é obrigatório"),
  account: yup.string().required("Este campo é obrigatório"),
  startAt: yup.string().required("Este campo é obrigatório"),
  endAt: yup.string().required("Este campo é obrigatório"),
});
