import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ContractTemplateDetailsForm } from "../../../../components/contract-templates/contract-template-details-form.component";
import { useEditCompanyContractTemplate } from "./edit-company-contract-template.hook";

export const EditCompanyContractTemplate = () => {
  const {
    fetchHasError,
    fetchLoading,
    fetchTemplate,
    form,
    onDownloadFile,
    submit,
    submitLoading,
    template,
  } = useEditCompanyContractTemplate();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar template de contrato" />}
      actions={
        <LoadingButton
          dataAction="recebiveis_editar_template_contrato:botao:salvar"
          dataLabel="salvar"
          loading={submitLoading}
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ContentTemplate
            loading={fetchLoading}
            hasError={fetchHasError}
            errorComponent={
              <DefaultErrorComponent
                title="Não foi possível buscar o template de contrato"
                callback={fetchTemplate}
              />
            }
            value={template}
            render={() => (
              <ContractTemplateDetailsForm onDownloadFile={onDownloadFile} />
            )}
          />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
