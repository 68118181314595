import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EmprestimosProdutoParceirosByPartnerNameByPartnerId } from "../../../../routes/emprestimos.route-params";
import {
  LoanPartnerForm,
  LoanPartnersFormValues,
} from "../../../../components/loan-partner/loan-partner-form.component";
import { loanPartnerValidationFormSchema } from "../../../../components/loan-partner/loan-partner-form.schemas";

type LoanEditPartnerFormValues = LoanPartnersFormValues & {
  partnerId?: number;
};

export const EditPartnerPage = () => {
  const { partnerId } =
    useParams<EmprestimosProdutoParceirosByPartnerNameByPartnerId>();
  if (!partnerId) throw new Error("No partnerId");

  const form = useForm<LoanEditPartnerFormValues>({
    resolver: yupResolver(loanPartnerValidationFormSchema),
  });

  const [loading, setLoading] = useState(false);

  const { handleSubmit, reset } = form;

  const getPartnerById = useCallback(async () => {
    try {
      if (!partnerId) return;

      setLoading(true);
      const { data } = await service.hubLoan.getPartnerById(partnerId);

      reset({
        ...data,
        isFromDevelopersSender: data.isFromDevelopersSender ? ["1"] : [],
      });
    } catch (err) {
      OToastManager.danger("Erro para retornar os parceiros.");
    } finally {
      setLoading(false);
    }
  }, [partnerId, reset]);

  const updatePartner = handleSubmit(
    async (values: LoanEditPartnerFormValues) => {
      try {
        if (!partnerId) return;

        setLoading(true);
        await service.hubLoan.updatePartner({
          ...values,
          id: +partnerId,
          isFromDevelopersSender: !!values.isFromDevelopersSender?.length,
        });

        OToastManager.success("Parceiro editado com sucesso");
      } catch {
        OToastManager.danger("Ocorreu algum erro. Tente novamente mais tarde");
      } finally {
        setLoading(false);
      }
    },
  );

  useEffect(() => {
    if (!partnerId) return;
    getPartnerById();
  }, [getPartnerById, partnerId]);

  return (
    <ContentTemplate
      noValue
      render={() => (
        <FormTemplate
          pageTitle={<PageTitle title="Editar convênio" />}
          actions={
            <LoadingButton loading={loading} onClick={updatePartner}>
              Salvar
            </LoadingButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <LoanPartnerForm />
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
      )}
    />
  );
};
