import { IconButton } from "components/icon-button";
import type { UserNotification } from "services/raven/models/types";
import { useDownloadEmail } from "./download-email-button.hook";

interface DownloadEmailButtonProps {
  data: UserNotification;
}

export const DownloadEmailButton = ({
  data: { fileKey },
}: DownloadEmailButtonProps) => {
  const { downloadEmail, loading } = useDownloadEmail();

  return fileKey ? (
    <IconButton
      dataAction="acoes:botao:download"
      dataLabel="download"
      icon={{
        category: "orq",
        icon: "orq-download",
      }}
      type="dark"
      outline
      onClick={() => downloadEmail(fileKey)}
      loading={loading}
    >
      Download
    </IconButton>
  ) : (
    <>---</>
  );
};
