import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { OToastManager, modalManager } from "@maestro/core";
import { service } from "services";
import { useServiceCall } from "hooks/service-call";

export const useConsentRevoke = (
  id: string,
  modalId: string,
  revokeConsentType: "scope" | "resource",
  successCallback?: () => void,
) => {
  const {
    callService: patchRevokeConsent,
    hasError,
    value,
    loading,
  } = useServiceCall(
    revokeConsentType === "resource"
      ? service.developers.RevokeConsentResource
      : service.developers.RevokeConsentScope,
  );

  const form = useForm<{ statusObservation: string }>({
    defaultValues: { statusObservation: "Revogado" },
  });
  const { handleSubmit, reset } = form;
  const submit = useMemo(
    () =>
      handleSubmit(() => {
        patchRevokeConsent(id);
      }),
    [handleSubmit, id, patchRevokeConsent],
  );

  useEffect(() => {
    const cleanup = modalManager.on(modalId, "modalOpen", () =>
      reset({ statusObservation: "" }),
    );
    return cleanup;
  }, [modalId, reset]);

  useEffect(() => {
    if (hasError) OToastManager.danger("Falha ao revogar consentimento");
  }, [hasError]);

  useEffect(() => {
    if (value !== undefined) {
      OToastManager.success("Consentimento revogado com sucesso");
      modalManager.hide(modalId);
      if (successCallback) successCallback();
    }
  }, [modalId, successCallback, value]);

  return {
    form,
    loading,
    submit,
  };
};