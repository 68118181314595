import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { buildSettlementsDetails } from "./settlement-details.details";
import { settlementTradesGrid } from "./settlement-details.grid";
import { useSettlementDetails } from "./settlement-details.hook";

export const SettlementDetails = () => {
  const { state, tradesDataSource } = useSettlementDetails();
  const gridRef = useGridRef();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da liquidação" />}>
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar os detalhes da liquidação"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton
                onClick={() => gridRef.current?.instance.refresh()}
              />
            </ErrorComponent>
          }
          value={state}
          fields={buildSettlementsDetails}
        />
        <GridTemplate
          actions={
            <RefreshGridButton
              onClick={() => gridRef.current?.instance.refresh()}
            />
          }
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={settlementTradesGrid}
            dataSource={tradesDataSource}
          />
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
