import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { energiaRoles } from "../../../../../roles/energia.roles";
import {
  DistributorConfigFields,
  DistributorContactsGrid,
} from "../../_compose";
import { useDistributorConfiguration } from "./distributor-configuration.hook";

export const DistributorConfigurationPage = () => {
  const {
    fetchError,
    fetchHasError,
    fetchLoading,
    fetchValue,
    form,
    getDistributor,
    submit,
    submitLoading,
  } = useDistributorConfiguration();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Configuração da distribuidora" />}
      actions={
        <LoadingButton
          loading={submitLoading}
          onClick={submit}
          role={energiaRoles.product.updateDistributor.role}
        >
          Salvar
        </LoadingButton>
      }
    >
      <div className="d-flex flex-column gap-3 position-relative">
        <ContentTemplate
          loading={fetchLoading}
          hasError={fetchHasError}
          errorComponent={
            <ValidationErrorComponent
              error={fetchError}
              title="Erro ao carregar a distribuidora"
              callback={getDistributor}
            />
          }
          value={fetchValue}
          render={() => (
            <FormProvider {...form}>
              <OCard>
                <OCardHeader>
                  <OTypography>Configurações</OTypography>
                </OCardHeader>
                <OCardBody>
                  <DistributorConfigFields />
                </OCardBody>
              </OCard>
              <OCard>
                <OCardHeader>
                  <OTypography>Contatos</OTypography>
                </OCardHeader>
                <OCardBody>
                  <DistributorContactsGrid />
                </OCardBody>
              </OCard>
            </FormProvider>
          )}
        />
      </div>
    </FormTemplate>
  );
};
