import { logger } from "utils/logger";
import { Customer } from "./customer";
import { ClearCustomerCommand, GetCustomerCommand } from "./customer.commands";
import { customerManager } from "./customer.invoker";

export const getCustomerFromStorage = () => {
  const savedCustomer = Customer.storage.get();

  if (savedCustomer) {
    logger.debug(`[getCustomerFromStorage]savedCustomer: ${savedCustomer}`);

    customerManager.execute(new GetCustomerCommand(savedCustomer));
  } else {
    customerManager.execute(new ClearCustomerCommand());
  }
};
