import { ONotification, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";

export const ClosedDownCompanyNotification = () => {
  const { customer } = useCustomer();

  const hasClosedDown = customer.statusRfb === "BAIXADA";

  return hasClosedDown ? (
    <ONotification type="danger" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">Empresa baixada</OTypography>
        <OTypography type="dark">
          Infelizmente a empresa que você está acessando foi baixada na Receita
          Federal. Algumas funcionalidades estarão indisponíveis
        </OTypography>
      </div>
    </ONotification>
  ) : null;
};
