import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";

export const buildGrid = (
  setCompanyGroup: React.Dispatch<
    React.SetStateAction<CompanyGroupAssociationsItem[]>
  >,
): ODataGridGeneratorConfig<CompanyGroupAssociationsItem> => ({
  datagrid: {
    noDataText: "Nenhum dado encontrado",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      allowDeleting: true,
    },
    onRowRemoved: (row) => {
      setCompanyGroup(
        removeKeyFromDataSourceItems(row.component.getDataSource().items()),
      );
    },
  },
  columns: [
    {
      caption: "CNPJ",
      dataField: "taxId",
      format: (value) => masks.cpfCnpj(value),
    },
    { caption: "Nome", dataField: "name" },
    {
      caption: "Status",
      cellRender: () => <OIcon category="orq" icon="orq-check" />,
    },
  ],
});
