import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
  modalManager,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { useAddGroupConfirmationModal } from "./add-group-confirmation-modal.hook";

interface AddGroupConfirmationModalProps {
  prefix: string;
  successCallback: () => void;
}

export const buildAddGroupConfirmationModalId = (prefix: string) =>
  `add-group-confirmation-modal-id-${prefix}`;

export const AddGroupConfirmationModal = ({
  prefix,
  successCallback,
}: AddGroupConfirmationModalProps) => {
  const modalId = buildAddGroupConfirmationModalId(prefix);

  const { form, loading, submit } = useAddGroupConfirmationModal(
    modalId,
    successCallback,
  );

  return (
    <OModal id={modalId} position="center" backdrop>
      <OModalHeader>
        <OTypography size="lg">Agrupar</OTypography>
      </OModalHeader>
      <OModalBody>
        <div>
          <OTypography className="mb-3" style={{ textAlign: "start" }}>
            Tem certeza que deseja agrupar o prefixo {masks.cnpj(prefix)}?
          </OTypography>
          <FormProvider {...form}>
            <ORFieldInput
              dataAction="confirmacao_agrupar:texto:cnpj"
              dataLabel="cnpj"
              id="taxId"
              name="taxId"
              tag="cnpj"
              label="Digite o CNPJ completo da matriz dessa rede"
              placeholder="00.000.000/0000-00"
              disabled={loading}
            />
          </FormProvider>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_agrupar:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton
            dataAction="confirmacao_agrupar:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            onClick={() => submit()}
          >
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
