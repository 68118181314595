import { auth } from "contexts/auth";
import { behavior } from "contexts/behavior";
import { Behaviors, behaviorSearchKey } from "contexts/behavior/types";

/**
 * Generates an `URLSearchParams` object with the given search string plus
 * the current `behavior` and `authorization` parameters
 */
export const setInitialParams = (searchString = "") => {
  const searchParams = new URLSearchParams(searchString);
  if (behavior.value) {
    searchParams.set(behaviorSearchKey, behavior.value.id);
  }
  if (behavior.value?.id === Behaviors.CustomerService) {
    searchParams.set("authorization", auth.value);
  }

  return searchParams;
};
