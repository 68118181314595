import { useTheme } from "contexts/theme";
import { Doughnut } from "react-chartjs-2";

// register used components
// eslint-disable-next-line import/order, import/newline-after-import
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
Chart.register(ArcElement);
Chart.register(Legend);
Chart.register(Tooltip);

const chartConfig = {
  damagedStatusQuantity: {
    label: "Danos",
    backgroundColor: {
      dark: "rgb(0, 0, 0)",
      light: "rgba(38, 38, 38, 0.6)",
    },
    borderColor: {
      dark: "#ffffff",
      light: "rgba(38, 38, 38, 1)",
    },
  },
  robbedStatusQuantity: {
    label: "Roubo",
    backgroundColor: {
      dark: "rgba(241, 22, 66, 0.8)",
      light: "rgba(186, 12, 47, 0.4)",
    },
    borderColor: {
      dark: "rgba(241, 22, 66, 1)",
      light: "rgba(186, 12, 47, 1)",
    },
  },
  fraudStatusQuantity: {
    label: "Fraude",
    backgroundColor: {
      dark: "rgba(255, 118, 44, 0.8)",
      light: "rgba(222, 82, 5, 0.4)",
    },
    borderColor: {
      dark: "rgba(255, 118, 44, 1)",
      light: "rgba(222, 82, 5, 1)",
    },
  },
  lostStatusQuantity: {
    label: "Perda",
    backgroundColor: {
      dark: "rgba(252, 255, 78, 0.8)",
      light: "rgba(243, 246, 18, 0.4)",
    },
    borderColor: {
      dark: "rgba(252, 255, 78, 1)",
      light: "rgba(165, 167, 25, 1)",
    },
  },
};

interface DisabledCardsStatusDoughnutChartProps {
  damagedStatusQuantity: number;
  robbedStatusQuantity: number;
  fraudStatusQuantity: number;
  lostStatusQuantity: number;
}

export const DisabledCardsStatusDoughnutChart = ({
  damagedStatusQuantity,
  robbedStatusQuantity,
  fraudStatusQuantity,
  lostStatusQuantity,
}: DisabledCardsStatusDoughnutChartProps) => {
  const { theme } = useTheme();

  return (
    <Doughnut
      data={{
        labels: [
          chartConfig.damagedStatusQuantity.label,
          chartConfig.robbedStatusQuantity.label,
          chartConfig.fraudStatusQuantity.label,
          chartConfig.lostStatusQuantity.label,
        ],
        datasets: [
          {
            label: "Status de Cartões Desabilitados",
            data: [
              damagedStatusQuantity,
              robbedStatusQuantity,
              fraudStatusQuantity,
              lostStatusQuantity,
            ],
            backgroundColor: [
              chartConfig.damagedStatusQuantity.backgroundColor[theme],
              chartConfig.robbedStatusQuantity.backgroundColor[theme],
              chartConfig.fraudStatusQuantity.backgroundColor[theme],
              chartConfig.lostStatusQuantity.backgroundColor[theme],
            ],
            borderColor: [
              chartConfig.damagedStatusQuantity.borderColor[theme],
              chartConfig.robbedStatusQuantity.borderColor[theme],
              chartConfig.fraudStatusQuantity.borderColor[theme],
              chartConfig.lostStatusQuantity.borderColor[theme],
            ],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};
