import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetAdminCustomerIncorrectOrdersResponse } from "services/hubfx/models/responses";
import { CopyButton } from "components/copy-button";

export const incorrectOrdersGrid: ODataGridGeneratorConfig<
  GetAdminCustomerIncorrectOrdersResponse[number]
> = {
  datagrid: {
    noDataText: "Nenhuma ordem incorreta.",
    filterRow: {
      visible: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "conciliationId",
      caption: "Conciliation Id",
      cellRender: ({ data }) => (
        <>
          {data.conciliationId}{" "}
          <CopyButton
            action="detalhes_do_cliente:botao:copiar-identificacao"
            label="copiar-identificacao"
            target={data.conciliationId ?? ""}
            toasterMessage="Identificação copiada."
          />
        </>
      ),
    },
    {
      dataField: "errorMessage",
      caption: "Erro",
    },
    {
      dataField: "isActive",
      caption: "Ordem Corrigida?",
      calculateCellValue: ({ isActive }) => (isActive ? "Não" : "Sim"),
    },
    {
      dataField: "currency",
      caption: "Moeda",
    },
    {
      dataField: "countryCode",
      caption: "Código do país",
    },
    {
      dataField: "amount",
      caption: "Quantia",
      cellRender: ({ data }) => masks.currency(data.amount),
    },
    {
      dataField: "payer",
      caption: "Pagador",
    },
    {
      dataField: "createdDate",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      dataField: "updatedDate",
      caption: "Atualizado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
  ],
};
