import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldUploadInput,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useReactFlow } from "reactflow";
import { service } from "services";
import { CanvasExport } from "services/hubcreditmanager/models/types/workflow/canvas-export.types";
import { WorkflowErrorResponse } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useCanvas } from "../../../../../../../../components/hooks/use-canvas/use-canvas.context";
import { UploadContainer } from "./import-canvas-modal.styles";

export const IMPORT_CANVAS_MODAL_ID = "import-canvas-modal";

export const ImportCanvasModal = () => {
  const { callService: _importCanvas, loading } = useServiceCall(
    service.hubCreditManager.importCanvas,
  );

  const [importData, setImportData] = useState<CanvasExport | undefined>(
    undefined,
  );

  const { screenToFlowPosition } = useReactFlow();
  const { viewPoint, workflowId, getCanvasData } = useCanvas();

  const {
    loading: readerLoader,
    reader,
    result,
    reset: resetReader,
  } = useReadFile();

  const form = useForm({ defaultValues: { fileInput: [] } });
  const { reset } = form;

  const importCanvas = useCallback(async () => {
    if (!importData) return OToastManager.danger("Erro!");

    const { x, y } = screenToFlowPosition({
      x: viewPoint.client.x,
      y: viewPoint.client.y,
    });

    const { success, error } = await _importCanvas({
      workflowConfigId: Number(workflowId),
      canvasExport: importData,
      positionX: x,
      positionY: y,
    });

    if (success) {
      OToastManager.success("Importação feita com sucesso");
      getCanvasData();
      modalManager.hide(IMPORT_CANVAS_MODAL_ID);
    } else {
      let errorMessage = "Erro na importação";
      if (
        isAxiosError<WorkflowErrorResponse>(error) &&
        error.response?.data?.failures?.[0]?.errorCode ===
          "UnableToImportWorkflowCanvas"
      ) {
        errorMessage = error.response.data.failures?.[0]?.message;
      }
      OToastManager.danger(errorMessage);
    }
  }, [
    _importCanvas,
    getCanvasData,
    importData,
    screenToFlowPosition,
    viewPoint.client.x,
    viewPoint.client.y,
    workflowId,
  ]);

  useEffect(() => {
    if (result) {
      setImportData(JSON.parse(result as string));
    }
  }, [result]);

  useEffect(() => {
    const cleanup = modalManager.on(
      IMPORT_CANVAS_MODAL_ID,
      "modalClose",
      () => {
        reset({ fileInput: [] });
        resetReader();
        setImportData(undefined);
      },
    );
    return cleanup;
  }, [reset, resetReader]);

  return (
    <OModal id={IMPORT_CANVAS_MODAL_ID} position="center">
      <OModalHeader closeButton>
        <OTypography size="lg">Importar canvas</OTypography>
      </OModalHeader>
      <OModalBody>
        <div>
          <FormProvider {...form}>
            <UploadContainer>
              <ORFieldUploadInput
                id="fileInput"
                name="fileInput"
                inputLabel="Clique ou arraste o arquivo aqui"
                accept="application/json"
                multiple={false}
                handleAddFile={(evt, resolve) => {
                  reader.readAsText(evt.detail);
                  resolve(evt.detail);
                }}
                disabled={readerLoader || loading}
                value={[]}
              />
            </UploadContainer>

            <div className="d-flex flex-column align-items-center gap-3 mt-4">
              {(readerLoader || loading) && <OLoader />}
            </div>
          </FormProvider>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-4">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(IMPORT_CANVAS_MODAL_ID)}
          >
            Cancelar
          </OButton>
          <OButton onClick={importCanvas}>Importar</OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
