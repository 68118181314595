import { EdgeLabelRenderer, useNodes } from "reactflow";
import { ProcessorConfig } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { GroupNodeDataType } from "../../../group-node/group-node.types";
import { NodeInfo } from "./_compose";
import { InspectorContent } from "./node-inspector.styles";

export const NodeInspector = () => {
  const nodes = useNodes<GroupNodeDataType | ProcessorConfig>();

  return (
    <EdgeLabelRenderer>
      <InspectorContent>
        {nodes.map((node) => {
          const x = node.positionAbsolute?.x || 0;
          const y = node.positionAbsolute?.y || 0;
          const width = node.width || 0;
          const height = node.height || 0;

          return (
            <NodeInfo
              key={node.id}
              id={node.id}
              selected={!!node.selected}
              type={node.type || "default"}
              x={x}
              y={y}
              width={width}
              height={height}
              data={node.data}
            />
          );
        })}
      </InspectorContent>
    </EdgeLabelRenderer>
  );
};
