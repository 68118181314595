import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

interface RecreateTradeConfirmationModalProps {
  customerName: string;
  modalId: string;
  callback: () => void;
}

export const RecreateTradeConfirmationModal = ({
  customerName,
  modalId,
  callback,
}: RecreateTradeConfirmationModalProps) => {
  return (
    <OModal id={modalId} position="center" size="sm" {...datagridModalFix}>
      <OModalHeader>
        <OTypography size="lg" style={{ whiteSpace: "normal" }}>
          Certeza que deseja recriar o trade para {customerName}?
        </OTypography>
      </OModalHeader>
      <OModalBody className="p-2">
        <OTypography style={{ whiteSpace: "normal" }}>
          O trade atual será expirado e um novo será gerado a partir do lead
          original.
        </OTypography>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-2">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
          <OButton
            onClick={() => {
              callback();
              modalManager.hide(modalId);
            }}
          >
            Recriar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
