import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { getTradeFinanceErrorMessage } from "pages/acc/components/error/error-code.utils";
import { ExchangeDocumentConfigsForm } from "pages/acc/components/nature/document-configs/document-configs-form.component";
import {
  exchangeDocumentConfigsFormValidationFormSchema,
  ExchangeDocumentConfigsFormValues,
} from "pages/acc/components/nature/document-configs/document-configs-form.schemas";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";

export const AdminContingencyACCCreateDocumentConfig = () => {
  const form = useForm<ExchangeDocumentConfigsFormValues>({
    resolver: yupResolver(exchangeDocumentConfigsFormValidationFormSchema),
  });

  const navigate = useNavigate();

  const { handleSubmit } = form;

  const { callService: createNatureDocumentConfigs, loading } = useServiceCall(
    service.acc.nature.createNatureDocumentConfigs,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createNatureDocumentConfigs({
          ...values,
          isFileAllowed: !!values.isFileAllowed.length,
        });
        if (!success)
          return OToastManager.danger(getTradeFinanceErrorMessage(error));

        OToastManager.success(
          "Configuração de documento adicionado com sucesso",
        );

        navigate(
          corporateRouter.routes.acc.product.contingency.natures.documentConfigs
            .path,
        );
      }),
    [createNatureDocumentConfigs, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Configuração de Documentos" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ExchangeDocumentConfigsForm />
      </FormProvider>
    </FormTemplate>
  );
};
