import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { roles } from "roles/roles";
import { AppVerifier } from "../pages/verificar-app/verificar-app.page";
import { AppList } from "../pages/cliente/apps/listagem";
import { AppDetails } from "../pages/cliente/apps/detalhes";
import { appsRouteParams } from "./developers.route-params";
import {
  ResourceByAppList,
  ResourceByTenantList,
} from "../pages/cliente/consent/listagem";
import { ScopesByAppList } from "../pages/cliente/consent/listagem/scopes/listagem-by-app.page";

export const developersRoutes = {
  product: {
    name: "Developers",
    path: "/developers/produto",
    element: <RedirectWithRoles />,
    role: roles.developers.product.tab.role,
    children: {
      Aplicativos: {
        name: "Aplicativos",
        path: "/developers/produto/aplicativos",
        element: <AppVerifier />,
      },
    },
  },
  customer: {
    name: "",
    path: "/developers/cliente",
    element: <RedirectWithRoles />,
    children: {
      listagemApps: {
        name: "Aplicativos",
        path: "/developers/cliente/aplicativo",
        role: roles.developers.customer.tab.role,
        element: <AppList />,
        children: {
          details: {
            name: "Detalhes",
            path: `/developers/cliente/aplicativo/:${appsRouteParams.appId}/detalhes`,
            role: roles.developers.customer.tab.role,
            element: <AppDetails />,
          },
          listagemResource: {
            name: "Consentimentos do app (recursos)",
            path: `/developers/cliente/aplicativo/:${appsRouteParams.appId}/resources`,
            role: roles.developers.customer.tab.role,
            element: <ResourceByAppList />,
          },
          listagemScope: {
            name: "Consentimentos do app (escopos)",
            path: `/developers/cliente/aplicativo/:${appsRouteParams.appId}/scopes`,
            role: roles.developers.customer.tab.role,
            element: <ScopesByAppList />,
          },
        },
      },
      listagemResource: {
        name: "Consentimentos (recursos)",
        path: "/developers/cliente/resources",
        role: roles.developers.customer.tab.role,
        element: <ResourceByTenantList />,
        children: {
          details: {
            name: "",
            path: "",
          },
        },
      },
    },
  },
} as const satisfies Routes;
