import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { DailyBalanceItem } from "./daily-balance.types";

export const highlightNegative = (e: CellPreparedEvent<DailyBalanceItem>) => {
  if (e?.data?.balance === undefined) return;
  if (e.data.balance < 0) {
    e.cellElement.style.backgroundColor = "var(--theme-danger-light)";
    e.cellElement.style.color = "var(--theme-danger-light-inverse)";
  }
};
