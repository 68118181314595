import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { FormValuesType } from "../_compose/formulario/tco-strategies-form.types";
import {
  tcoStrategiesDefaultValues,
  validationSchema,
} from "../_compose/formulario/tco-strategies-form.form";
import { PostAdminTCOStrategyBody } from "services/quickfin/models/requests/post-admin-tco-strategy.request";
import { AxiosError, isAxiosError } from "axios";
import { ApiResponse, mapTCOStrategyError } from "../utils";

export const useAddTCOStrategies = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    defaultValues: tcoStrategiesDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue, watch } = form;

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);

    const payload: PostAdminTCOStrategyBody = {
      description: values.description,
      tipoProduto: values.tipoProduto,
      estrategiasIds: values.estrategiasIds,
      companiesIds: values.companiesIds,
      portfolioId: values.portfolioId,
      valuesByPlan: {
        SIMPLES2616: {
          maxValue: values.basicMaxValue,
          minValue: values.basicMinValue,
          percentage: values.basicPercentage,
        },
        VALOR2588: {
          maxValue: values.intermediateMaxValue,
          minValue: values.intermediateMinValue,
          percentage: values.intermediatePercentage,
        },
        GESTAO2589: {
          maxValue: values.advancedMaxValue,
          minValue: values.advancedMinValue,
          percentage: values.advancedPercentage,
        },
        FREE2458: {
          maxValue: values.corporateMaxValue,
          minValue: values.corporateMinValue,
          percentage: values.corporatePercentage,
        },
      },
    };

    try {
      await service.quickfin.createAdminTCOStrategy(payload);

      OToastManager.success("Estratégia adicionada com sucesso.");
      navigate(routes.recebiveis.product.tcoStrategies.path);
    } catch (e) {
      if (isAxiosError<ApiResponse>(e)) {
        OToastManager.danger(mapTCOStrategyError(e as AxiosError));
      } else {
        OToastManager.danger("Ocorreu um erro ao atualizar a estratégia.");
      }
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    onSubmit,
    setValue,
    watch,
  };
};
