import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { GridActions } from "./_compose";

export type GenericField = NonNullable<
  Awaited<
    ReturnType<typeof service.hubEnergy.getAdequacyOptions>
  >["data"]["response"]
>[number];

export const energyAdequatorsGrid = {
  datagrid: {
    noDataText: "Nenhuma adequadora encontrada.",
    headerFilter: { visible: true },
    filterRow: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("energyAdequatorsGrid"),
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubEnergy
        .getAdequacyOptions()
        .then(({ data }) => data.response ?? []),
    ),
  },
  columns: [
    {
      role: roles.energia.product.updateAdequator.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.energia.product.adequators.byId.update.path(
            {
              adequatorId: data.id,
            },
          )}
        />
      ),
      calculateCellValue: ({ id }) => id,
      allowFiltering: true,
      allowHeaderFiltering: false,
      allowSorting: true,
      width: "auto",
      allowExporting: false,
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "entity.taxId",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
    },
    {
      dataField: "createdByCustomer",
      dataType: "boolean",
      caption: "Criado pelo cliente ?",
    },
    {
      caption: "Ações",
      cellRender: ({ data, component }) => (
        <GridActions
          data={data}
          reload={() => component.getDataSource().reload()}
        />
      ),
      width: "auto",
      allowExporting: false,
    },
  ],
} satisfies ODataGridGeneratorConfig<GenericField>;
