import { CreditManagerAddress } from "services/hubcreditmanager/models/types/address/address.types";
import * as yup from "yup";

export type CreditManagerAddressFormValues = {
  address: CreditManagerAddress;
};

export const creditManagerAddressValidationFormSchema: yup.ObjectSchema<CreditManagerAddressFormValues> =
  yup.object({
    address: yup.object({
      zipCode: yup.string().required("Este campo é obrigatório"),
      state: yup
        .string<
          NonNullable<CreditManagerAddressFormValues["address"]["state"]>
        >()
        .required("Este campo é obrigatório"),
      city: yup.string().required("Este campo é obrigatório"),
      addressStreet: yup.string().required("Este campo é obrigatório"),
      buildingNumber: yup.string().default(null).nullable(),
      district: yup.string().required("Este campo é obrigatório"),
    }),
  });
