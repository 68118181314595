import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  ContractTemplateDetailsFormValues,
  contractTemplateDetailsFormValidationSchema,
} from "../../../../components/contract-templates/contract-template-details-form.schemas";
import { RecebiveisClienteTemplatesContratoByTemplateIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { useDownloadContractTemplateFile } from "../_compose";

export const useEditCompanyContractTemplate = () => {
  const { templateId } =
    useParams<RecebiveisClienteTemplatesContratoByTemplateIdRouteParams>();
  if (!templateId) throw new Error("No templateId");

  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    callService: getCompanyContractTemplate,
    loading: fetchLoading,
    hasError: fetchHasError,
    value: template,
  } = useServiceCall(service.quickfin.getCompanyContractTemplate);

  const { callService: updateCompanyContractTemplate, loading: submitLoading } =
    useServiceCall(service.quickfin.updateCompanyContractTemplate);

  const { onDownloadFile } = useDownloadContractTemplateFile(templateId);

  const navigate = useNavigate();

  const form = useForm<ContractTemplateDetailsFormValues>({
    resolver: yupResolver(contractTemplateDetailsFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const fetchTemplate = useCallback(
    () => getCompanyContractTemplate(quickfinId, templateId),
    [getCompanyContractTemplate, quickfinId, templateId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateCompanyContractTemplate(
          quickfinId,
          templateId,
          {
            name: values.name,
            signatureOrigin: values.signatureOrigin,
            file: {
              originalName: values.file[0].name,
              fileContent:
                values.file[0] instanceof File
                  ? await helpers.convertFileToBase64(values.file[0])
                  : undefined,
            },
            needsSignature: !!values.needsSignature?.length,
            geraNovosTermos: !!values.geraNovosTermos?.length,
            hasComplement: !!values.hasComplement?.length,
            signingParties: [
              {
                partyType: "Sacado",
                ...values.signingParties?.sacado,
                companyId: 0,
                parties: values.signingParties?.sacado?.parties ?? [],
                validFor: values.signingParties?.sacado?.validFor ?? [],
                contractTemplateId: +templateId,
              },
              {
                partyType: "Cedente",
                ...values.signingParties?.cedente,
                companyId: 0,
                parties: values.signingParties?.cedente?.parties ?? [],
                validFor: values.signingParties?.cedente?.validFor ?? [],
                contractTemplateId: +templateId,
              },
            ],
          },
        );

        if (success) {
          OToastManager.success("Template atualizado com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.customer.contractTemplates.path,
          );
        } else OToastManager.danger("Não foi possível atualizar o template");
      }),
    [
      handleSubmit,
      navigate,
      quickfinId,
      templateId,
      updateCompanyContractTemplate,
    ],
  );

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  useEffect(() => {
    if (template)
      reset({
        name: template.name,
        signatureOrigin: template.signatureOrigin,
        needsSignature: template.needsSignature ? ["true"] : [],
        hasComplement: template.hasComplement ? ["true"] : [],
        file: template.file
          ? [{ name: template.file.originalName } as File]
          : [],
        signingParties: {
          cedente: template.signingParties.find(
            (s) => s.partyType === "Cedente",
          ),
          sacado: template.signingParties.find((s) => s.partyType === "Sacado"),
        },
      });
  }, [reset, template]);

  return {
    fetchHasError,
    fetchLoading,
    fetchTemplate,
    form,
    onDownloadFile,
    submit,
    submitLoading,
    template,
  };
};
