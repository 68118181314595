export const receivableTypesOptions = ["Nota", "Cartão", "FIDC", "Offshore"];

export const receivableStatusOptions = [
  {
    label: "Aberto",
    value: "Aberto",
  },
  {
    label: "Em agendamento",
    value: "EmAgendamento",
  },
  {
    label: "Agendado",
    value: "Agendado",
  },
  {
    label: "Em aprovação",
    value: "EmAprovacao",
  },
  {
    label: "Aprovado",
    value: "Aprovado",
  },
  {
    label: "Erro validação",
    value: "ErrorValidacao",
  },
];

export const cancelingFilterModalId = "canceling-filter-modal";
export const receivablesCancelingModalId = "receivables-canceling-modal";
