import { useUnsafeCustomer } from "contexts/customer";
import { Navigate } from "react-router-dom";
import { logger } from "utils/logger";

export const RedirectToNotFoundPage = () => {
  const { customer } = useUnsafeCustomer();

  logger.warn("notFoundRedirect", {
    from: window.location.href,
    customer: customer?.identification,
  });

  return <Navigate to="/pagina-nao-encontrada" replace />;
};
