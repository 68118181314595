import { logger } from "utils/logger";
import { ObjectValueValidateFunction } from "../types";

const validateInterface = <I>(
  data: any,
  validator: Record<keyof I, ObjectValueValidateFunction>,
): data is I => {
  try {
    return Object.entries<ObjectValueValidateFunction>(validator).every(
      ([key, validate]) => {
        const isValidKeyValue = validate(data[key], data);
        if (!isValidKeyValue) {
          logger.error("Interface validation error in key: " + key);
        }
        return isValidKeyValue;
      },
    );
  } catch {
    logger.error("Interface validation throwed");
    return false;
  }
};

export const ObjectUtils = { validateInterface };
