import { OConfirmationModal } from "@maestro/react";
import { ClearGridFiltersButton } from "components/clear-grid-filters-button";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { useTCOStrategies } from "./tco-strategies.hook";

export const TCOStrategies = () => {
  const { clearFilter, dataSource, deleteTCO, grid, gridRef, loading } =
    useTCOStrategies();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Estratégias de TCO"
          description="Gerenciamento de estratégias de TCO"
        />
      }
      actions={
        <>
          <StandardLink
            href={
              corporateRouter.routes.recebiveis.product.tcoStrategies.add
                .path
            }
            role={roles.recebiveis.product.tcoStrategiesAddPage.role}
          >
            Adicionar
          </StandardLink>
          <ClearGridFiltersButton onClick={() => clearFilter()} />
        </>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        loading={loading}
        grid={grid}
        dataSource={dataSource}
      />
      <OConfirmationModal
        modalId="delete-tco-modal"
        title="Tem certeza que deseja eliminar este registro?"
        onConfirm={deleteTCO}
      />
    </GridTemplate>
  );
};
