import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useMemo } from "react";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { useTiers } from "./tiers.context";
import { Tier, TierRelationship } from "./tiers.types";

export const tierRelationshipsMasterDetailGrid: ODataGridGeneratorConfig<TierRelationship> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
      editing: {
        allowDeleting: true,
        mode: "row",
        texts: {
          confirmDeleteMessage:
            "Tem certeza que deseja remover este relacionamento?",
        },
      },
      onRowRemoved: async ({ data: { tierId, sacadoId, cedenteId } }) => {
        try {
          await service.quickfin.removeTierRelationship(
            tierId,
            sacadoId,
            cedenteId,
          );
          OToastManager.success("Relacionamento removido com sucesso");
        } catch {
          const errorMessage = "Erro ao remover o relacionamento";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    },
    columns: [
      {
        dataField: "sacadoName",
        dataType: "string",
        caption: "Sacado",
      },
      {
        dataField: "sacadoIdentification",
        dataType: "string",
        caption: "CNPJ do Sacado",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "cedenteName",
        dataType: "string",
        caption: "Cedente",
      },
      {
        dataField: "cedenteIdentification",
        dataType: "string",
        caption: "CNPJ do Cedente",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "totalAmount",
        dataType: "number",
        caption: "Valor total",
        format: (value) => masks.currency(value, "R$"),
      },
    ],
  };

type TierRelationshipsMasterDetailProps = MasterDetailComponentProps<Tier>;

export const TierRelationshipsMasterDetail = ({
  data: {
    data: { id },
  },
}: TierRelationshipsMasterDetailProps) => {
  const { tierRelationships, selectedCedente } = useTiers();

  const dataSource = useMemo(
    () =>
      tierRelationships
        ?.filter((relationship) => relationship.tierId === id)
        .filter(
          (relationship) =>
            selectedCedente === undefined ||
            relationship.cedenteId === selectedCedente,
        ),
    [id, selectedCedente, tierRelationships],
  );
  return (
    <ODataGridGenerator
      grid={tierRelationshipsMasterDetailGrid}
      dataSource={dataSource}
    />
  );
};
