import styled from "styled-components";

export const SortingOptionsUl = styled.ul`
  list-style: none;
  padding-left: 0;
  max-height: 10.5rem;
  min-height: 10.5rem;
  overflow-y: scroll;
  margin-bottom: 0;

  li {
    display: flex;
    margin-bottom: 1rem;
    gap: 0.5rem;
  }
`;

export const SortingButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
`;
