import * as yup from "yup";
import { ContractGeneratorFormValues } from "./contract-generator.types";

export const contractGeneratorFormValidationsSchema: yup.ObjectSchema<ContractGeneratorFormValues> =
  yup.object({
    taxId: yup.string().required("Este campo é obrigatório"),
    creditLine: yup.string().required("Este campo é obrigatório").nullable(),
    contractTemplates: yup
      .array()
      .default([])
      .min(1, "Este campo é obrigatório"),
    contractVariables: yup
      .array<ContractGeneratorFormValues["contractVariables"]>()
      .default([]),
  });
