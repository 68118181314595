import { OLoader, modalManager } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { roles } from "roles/roles";
import {
  RemoveStepConfigConfirmationModal,
  removeStepConfigConfirmationModalId,
} from "../remove-step-config-confirmation-modal";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  stepConfig: HubEnergy.DiligenceStepConfigBaseResponse;
  reload: () => void;
}

export const GridActions = ({ stepConfig, reload }: GridActionsProps) => {
  const { deleteStepConfig, loading } = useGridActions(stepConfig, reload);

  return (
    <>
      {loading ? (
        <OLoader size="md" />
      ) : (
        <DataGridActionV2
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              role: roles.energia.product.diligenceConfigDeleteDocument.role,
              onClick: () =>
                modalManager.show(
                  removeStepConfigConfirmationModalId(stepConfig.id),
                ),
            },
          ]}
        />
      )}
      <RemoveStepConfigConfirmationModal
        stepConfig={stepConfig}
        onConfirm={deleteStepConfig}
      />
    </>
  );
};
