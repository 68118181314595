import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { banksOptions } from "../../../fees-sacado.utils";

export const FeesRegisterPaymentAccountFields = () => {
  return (
    <ORow>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldSelect
          dataAction="formulario_taxa_rebate:select:codigo_banco"
          dataLabel="codigo_banco"
          id="bankCode"
          name="bankCode"
          label="Banco"
        >
          {banksOptions.map(({ value, label }) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          tag="text"
          dataAction="formulario_taxa_rebate:input:agencia"
          dataLabel="agencia"
          id="agency"
          name="agency"
          label="Agência"
          maxlength={4}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput
          tag="text"
          dataAction="formulario_taxa_rebate:input:conta"
          dataLabel="conta"
          id="account"
          name="account"
          label="Conta com Dígito"
        />
      </OCol>
    </ORow>
  );
};
