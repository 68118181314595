import { OIcon } from "@maestro/react";
import { RouterLink, RouterLinkProps } from "../router-link";

type StandardLinkProps = RouterLinkProps;

export const StandardLink = ({
  href,
  children,
  ...rest
}: StandardLinkProps) => {
  return (
    <RouterLink href={href} {...rest}>
      {children}
      <OIcon
        category="fas"
        icon="fa-external-link"
        className="ms-1"
        size="sm"
        {...rest}
      />
    </RouterLink>
  );
};
