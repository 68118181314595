import {
  OCol,
  OField,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
} from "@maestro/react";

export const ImportRentingFidcForm = () => {
  return (
    <div>
      <ORow>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="buyerTaxId"
            name="buyerTaxId"
            label="CNPJ Sacado"
            dataAction="locacao_fidc:input:cnpj_sacado"
            dataLabel="cnpj_sacado"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="sellerTaxId"
            name="sellerTaxId"
            label="CNPJ Cedente"
            dataAction="locacao_fidc:input:cnpj_cedente"
            dataLabel="cnpj_cedente"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="guarantorTaxId"
            name="guarantorTaxId"
            label="CNPJ Fiador"
            dataAction="locacao_fidc:input:cnpj_fiador"
            dataLabel="cnpj_fiador"
            readonly
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="text"
            id="faceValue"
            name="faceValue"
            label="Valor Face"
            dataAction="locacao_fidc:input:valor_face"
            dataLabel="valor_face"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldSelect
            id="type"
            name="type"
            label="Tipo"
            dataAction="locacao_fidc:select:tipo"
            dataLabel="tipo"
          >
            <OOption value="LEVE">leve</OOption>
            <OOption value="PESADO">Pesado</OOption>
            <OOption value="MAQUINAS_EQUIPAMENTOS">
              Máquinas & Equipamentos
            </OOption>
          </ORFieldSelect>
        </OCol>

        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="gracePeriod"
            name="gracePeriod"
            label="Tempo de carência (em meses)"
            dataAction="locacao_fidc:input:tempo_carencia"
            dataLabel="tempo_carencia"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <ORFieldInput
            tag="cnpj"
            id="installments"
            name="installments"
            label="Número de parcelas (em meses)"
            dataAction="locacao_fidc:input:numero_parcelas"
            dataLabel="numero_parcelas"
          />
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <OField label="Data de Contrato (DD/MM/AAAA)" htmlFor="operationDate">
            <ORFieldInputDate
              id="operationDate"
              name="operationDate"
              className="mt-2"
            />
          </OField>
        </OCol>
        <OCol xs={12} md={6} xl={4}>
          <OField label="Data de Vencimento (DD/MM/AAAA)" htmlFor="dueDate">
            <ORFieldInputDate
              id="dueDate"
              name="dueDate"
              className="mt-2"
              disabled
            />
          </OField>
        </OCol>
      </ORow>
    </div>
  );
};
