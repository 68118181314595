import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskConfigRelationshipForm } from "../_compose/desk-config-relationship-form.component";
import {
  DeskConfigRelationshipFormValues,
  deskConfigRelationshipFormValidationSchema,
} from "../_compose/desk-config-relationship.form";

export const UpdateDeskConfigRelationshipPage = () => {
  const { uuid } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid) throw new Error("No uuid");
  const form = useForm<DeskConfigRelationshipFormValues>({
    resolver: yupResolver(deskConfigRelationshipFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskConfigRelationshipDetails,
    loading,
    hasError,
    value: deskConfigRelationship,
  } = useServiceCall(service.onboarding.getDeskConfigRelationshipDetails);
  const { callService: updateDeskConfigRelationship, loading: submitLoading } =
    useServiceCall(service.onboarding.updateDeskConfigRelationship);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskConfigRelationship({
          deskConfigRelationshipUuid: uuid,
          ...values,
        });

        if (!success)
          return OToastManager.danger("Erro ao atualizar relacionamento");

        return OToastManager.success("Relacionamento atualizado com sucesso");
      }),
    [handleSubmit, updateDeskConfigRelationship, uuid],
  );

  useEffect(() => {
    getDeskConfigRelationshipDetails(uuid);
  }, [getDeskConfigRelationshipDetails, uuid]);

  useEffect(() => {
    deskConfigRelationship &&
      reset({
        configIdentifier:
          deskConfigRelationship.deskOnboardingConfig.configIdentifier,
        deskCode: deskConfigRelationship.desk.deskCode,
        isDefault: deskConfigRelationship.isDefault,
      });
  }, [deskConfigRelationship, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar relacionamento mesa/configuração" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar relacionamento"
            callback={() => getDeskConfigRelationshipDetails(uuid)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskConfigRelationshipForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
