export interface PropertyValueForm {
  value: string | null;
  adequacyOptionId: number | null;
  // helper
  files: { name: string; key: string }[] | null;
}

export const propertyValueFormDefaultValues: PropertyValueForm = {
  adequacyOptionId: null,
  value: null,
  files: null,
};
