import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface StepTypeForm {
  name: string;
  label: string;
  description: string;
}

export const stepTypeFormFields = {
  name: {
    id: "name",
    name: "name",
    tag: "text",
    label: "Nome",
    placeholder: "Preencher",
    required: true,
    autocomplete: "off",
  },
  label: {
    id: "label",
    name: "label",
    tag: "text",
    label: "Label",
    placeholder: "Preencher",
    required: false,
    autocomplete: "off",
  },
  description: {
    id: "description",
    name: "description",
    tag: "text",
    label: "Descrição",
    placeholder: "Preencher",
    required: false,
    autocomplete: "off",
  },
} as const satisfies MaestroFields<StepTypeForm>;

const stringRequired = yup.string().required("Campo obrigatório");

export const stepTypeFormValidationSchema = yup.object<
  StepTypeForm,
  YupObjectSchema<StepTypeForm>
>({
  name: stringRequired,
});

export const stepTypeFormDefaultValues = {
  description: null,
  name: null,
  label: null,
} satisfies Partial<DeepNullable<StepTypeForm>> as unknown as StepTypeForm;
