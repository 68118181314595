import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { validationSchema } from "../_compose/configuracoes-form/configuration-form.form";
import { ConfigurationFormValuesType } from "../_compose/configuracoes-form/configuration-form.types";

export const useRavenNotificationConfigurationAdd = (
  notification_type_id: string | undefined,
) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<ConfigurationFormValuesType>({
    resolver: yupResolver(validationSchema),
  });

  const submit = async () => {
    try {
      setLoading(true);

      if (notification_type_id) {
        const {
          channel,
          "sendOnDefault?": sendOnDefault,
          "userOverridable?": userOverridable,
        } = form.getValues();

        const isSendOnDefault = sendOnDefault === "true";
        const isUserOverridable = userOverridable === "true";

        const payload = {
          channel,
          "send_on_default?": isSendOnDefault,
          "user_overridable?": isUserOverridable,
          notification_type_id,
        };

        await service.raven.addNotificationConfiguration(payload);

        OToastManager.success("Configuração adicionada com sucesso");

        form.resetField("channel");
        form.resetField("sendOnDefault?");
        form.resetField("userOverridable?");
      } else {
        OToastManager.warning("Tipo de notificação não encontrado");
      }
    } catch (err) {
      OToastManager.danger("Erro ao criar a configuração de notificação");
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    submit,
    loading,
  };
};
