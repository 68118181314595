import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { Contract } from "../renegociacao-historico.type";

export const buildDetails = (contract: Contract): DetailsFields => ({
  info: {
    items: [
      {
        label: "Contrato",
        value: contract.contract,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Renegociado em",
        value: dayjs(contract.created_in).format("DD/MM/YYYY"),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Status",
        value: contract.status || "",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  payment: {
    title: "Métodos de pagamento",
    items: [
      {
        label: "Método do primeiro pagamento",
        value: contract.first_payment_method,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Método de pagamento das parcelas",
        value: contract.installments_payment_method,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  value: {
    title: "Valor",
    items: [
      {
        label: "Valor total",
        value: masks.currency(contract.total_value, "RS", "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Valor com desconto",
        value: masks.currency(contract.total_value_with_discount, "RS", "."),
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
