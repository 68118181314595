import {
  SettlementPaymentStatus,
  SettlementTradePaymentStatus,
} from "services/quickfin/models";

export const settlementStatusLibrary: Record<SettlementPaymentStatus, string> =
  {
    CREATED: "Criado",
    CANCELLED: "Cancelado",
    WAITING_TRADE_INTEGRATION: "Aguardando integração",
    WAITING_RELEASE_INTEGRATION: "Aguardando liberação",
    WAITING_RELEASE_CALLBACK: "Aguardando callback",
    WAITING_PAYMENT_MESSAGE: "Aguardando pagamento",
    COMPLETED: "Completado",
    ERROR: "Erro",
  };

export const settlementTintMap: Record<
  SettlementPaymentStatus,
  "warning" | "success" | "danger" | "info"
> = {
  CREATED: "info",
  CANCELLED: "danger",
  WAITING_TRADE_INTEGRATION: "info",
  WAITING_RELEASE_INTEGRATION: "info",
  WAITING_RELEASE_CALLBACK: "info",
  WAITING_PAYMENT_MESSAGE: "info",
  COMPLETED: "success",
  ERROR: "danger",
};

export const settlementTradeStatusLibrary: Record<
  SettlementTradePaymentStatus,
  string
> = {
  CREATED: "Criado",
  WAITING_CALLBACK: "Aguardando retorno",
  VALID: "Válido",
  INVALID: "Inválido",
  CANCELLED: "Cancelado",
  REMOVED_BY_CUSTOMER: "Removido",
  ERROR: "Erro",
  INTEGRATION_ERROR: "Erro integração",
  COMPLETED: "Finalizado",
};

export const settlementTradeStatusTintMap: Record<
  SettlementTradePaymentStatus,
  "warning" | "success" | "danger" | "info"
> = {
  CREATED: "info",
  WAITING_CALLBACK: "info",
  VALID: "info",
  INVALID: "danger",
  CANCELLED: "danger",
  REMOVED_BY_CUSTOMER: "danger",
  ERROR: "danger",
  INTEGRATION_ERROR: "danger",
  COMPLETED: "success",
};

export const settlementPaymentMethods = {
  PIX: "PIX",
  TED: "TED",
  BANKSLIP: "Boleto",
  CC: "Débito em conta",
};
