import { ODataGridGeneratorConfig } from "components/data-grid";
import {
  OBadge,
  ODropdownAction,
  ODropdownActionItem,
  OIcon,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import dayjs from "dayjs";
import { gatekeeperEndpoints } from "services/gatekeeper/gatekeeper.endpoints";
import { client } from "services/client";
import {
  ActiveDevice,
  ActivityLogin,
  StatusType,
  StatusTypeLabel,
} from "./atividades-login.types";
import { ActivityDetails } from "./_compose/detalhes-atividade";
import { ConfirmBlockModal } from "./_compose/confirmacao-bloqueio";

const blockDevice = async (sessiontokenId?: string) => {
  try {
    await client.patch(
      gatekeeperEndpoints.sessionToken.invalidate.bySessionTokenId(
        sessiontokenId ?? "",
      ),
      {},
    );
    OToastManager.success("Dispositivo bloqueado com sucesso!");
  } catch {
    OToastManager.danger("Falha ao bloquear dispositivo");
  }
};

export const LoginActivityGrid: ODataGridGeneratorConfig<ActivityLogin> = {
  datagrid: {
    noDataText: "Nenhuma atividade de Login",
    filterRow: { visible: true },
    remoteOperations: true,
    pager: {
      showInfo: true,
      visible: true,
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} atividades)",
    },
  },
  columns: [
    {
      dataField: "insertedAt",
      dataType: "string",
      caption: "Última Conexão",
      cellRender: ({ value }) => (
        <div className="d-flex flex-column gap-1">
          {value ? (
            <>
              <OTypography tag="span">
                {dayjs(value + "Z").format("DD/MM/YYYY")}
              </OTypography>
              <OTypography tag="span" size="sm" type="dark-60">
                {dayjs(value + "Z").format("HH:mm:ss")}
              </OTypography>
            </>
          ) : (
            <OTypography tag="span">-</OTypography>
          )}
        </div>
      ),
    },
    {
      dataField: "city",
      dataType: "string",
      caption: "Local",
      cellRender: ({ data }: { data: ActiveDevice }) => (
        <div className="d-flex flex-column gap-1">
          {data.city && data.country ? (
            <>
              <OTypography tag="span">{data.city}</OTypography>
              <OTypography tag="span" size="sm" type="dark-60">
                {data.country}
              </OTypography>
            </>
          ) : (
            <OTypography tag="span">-</OTypography>
          )}
        </div>
      ),
    },
    {
      caption: "Dispositivo",
      dataField: "device",
      cellRender: ({ value }) => (
        <div className="d-flex flex-column gap-1">
          <OTypography tag="span">{value ?? "-"}</OTypography>
        </div>
      ),
    },
    {
      caption: "Informações Adicionais",
      dataField: "browser",
      dataType: "string",
      cellRender: ({ value }) => (
        <div className="d-flex flex-column gap-1">
          <OTypography tag="span">{value ?? "-"}</OTypography>
        </div>
      ),
    },
    {
      caption: "Status",
      allowFiltering: true,
      calculateCellValue: (rowData: ActiveDevice) =>
        StatusTypeLabel[rowData.status],
      lookup: {
        dataSource: [
          StatusTypeLabel[StatusType.ActiveToken],
          StatusTypeLabel[StatusType.ActiveSession],
          StatusTypeLabel[StatusType.ExpiredSession],
        ],
      },
      cellRender: ({ data }: { data: ActiveDevice }) => (
        <OBadge
          size="sm"
          type={
            data.status !== StatusType.ExpiredSession
              ? "info-light"
              : "warning-light"
          }
        >
          {StatusTypeLabel[data.status]}
        </OBadge>
      ),
    },
    {
      caption: "Ações",
      alignment: "center",
      allowExporting: false,
      cellRender: ({ data }: { data: ActiveDevice }) => {
        const showBlockDeviceButton =
          data.sessionTokenId && data.status !== StatusType.ExpiredSession;
        const showDetailsButton = data.latitude && data.longitude;

        return (
          <>
            {(showBlockDeviceButton || showDetailsButton) && (
              <ODropdownAction>
                {showBlockDeviceButton && (
                  <ODropdownActionItem
                    size="xl"
                    handleClick={() => {
                      modalManager.show(
                        `confirm-blocking-modal-${data.sessionTokenId}`,
                      );
                    }}
                  >
                    <OIcon category="orq" icon="orq-locker-close" size="xl" />
                    <OTypography size="md">Bloquear dispositivo</OTypography>
                  </ODropdownActionItem>
                )}
                {showDetailsButton && (
                  <ODropdownActionItem
                    size="xl"
                    handleClick={() =>
                      modalManager.show(
                        `activity-details-modal-${data.sessionTokenId}`,
                      )
                    }
                  >
                    <OIcon category="orq" icon="orq-international" size="xl" />
                    <OTypography size="md">Detalhes</OTypography>
                  </ODropdownActionItem>
                )}
              </ODropdownAction>
            )}
            <ActivityDetails
              device={data}
              blockDevice={() => blockDevice(data.sessionTokenId)}
            />
            <ConfirmBlockModal
              device={data}
              blockDevice={() => blockDevice(data.sessionTokenId)}
            />
          </>
        );
      },
    },
  ],
};
