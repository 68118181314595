import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { format } from "utils/date";

export const bankslipPaymentsGrid: ODataGridGeneratorConfig<BankingHub.BankSlipPayment> =
  {
    datagrid: {
      noDataText: "Nenhum pagamento encontrado",
      pager: {
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        dataField: "paymentDate",
        caption: "Data de pagamento",
        format: (value) => format.date(value, { timeZone: "UTC" }),
      },
      { dataField: "agency", caption: "Agência" },
      { dataField: "accountNumber", caption: "Nº Conta" },
      {
        caption: "Favorecido",
        cellRender: ({ data }) => data.beneficiary?.socialName ?? "-",
      },
      {
        dataField: "expireDate",
        caption: "Vencimento",
        format: (value) => format.date(value, { timeZone: "UTC" }),
      },
      {
        dataField: "value",
        caption: "Valor",
        format: (value) => masks.currency(value, "R$", "."),
      },
      { dataField: "status", caption: "Status" },
    ],
  };
