import { OConfirmationModal, OLoader, OTypography } from "@maestro/react";
import { CancelBankslipModalId } from "./cancel-bankslip-modal.types";
import { useCancelBankslip } from "./cancel-bankslip-modal.hook";

export const CancelBankslipModal = () => {
  const { cancelCollection, isLoading } = useCancelBankslip();

  return (
    <>
      {isLoading && <OLoader absolute />}

      <OConfirmationModal
        modalId={CancelBankslipModalId}
        title="Cancelar boleto?"
        onConfirm={cancelCollection}
        confirmLabel="Cancelar boleto"
      >
        <OTypography type="dark">
          Ao prosseguir, o boleto será cancelado. Deseja continuar?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
