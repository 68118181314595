import {
  OCard,
  OCardBody,
  OCol,
  OLoader,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { useGroupDetails } from "./group.hook";

export const GroupDetails = () => {
  const { group, loading, sendConclusion } = useGroupDetails();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Grupo" />}
      actions={
        <LoadingButton
          dataAction="grupo:botao:forcar_conclusao_do_grupo"
          dataLabel="forcar_conclusao_do_grupo"
          onClick={sendConclusion}
          loading={loading}
        >
          Forçar conclusão do grupo
        </LoadingButton>
      }
    >
      {loading && <OLoader absolute />}

      {group && (
        <ORow align="start">
          <OCol xs={12} md={6} xl={4}>
            <OCard>
              <OCardBody className="d-flex flex-column gap-2">
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Id:
                  </OTypography>
                  <OTypography type="dark">{group.groupId}</OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Completo:
                  </OTypography>
                  <OTypography type="dark">
                    {group.isCompleted ? "Sim" : "Não"}
                  </OTypography>
                </div>
                <div className="d-flex gap-2">
                  <OTypography type="dark" weight="700">
                    Itens:
                  </OTypography>
                  <div className="d-flex flex-column gap-1">
                    {Object.keys(group.groupItems).map((item) => (
                      <OTypography key={item} type="dark">
                        {item} -{" "}
                        {group.groupItems[Number(item)] ? "Sim" : "Não"}
                      </OTypography>
                    ))}
                  </div>
                </div>
              </OCardBody>
            </OCard>
          </OCol>
        </ORow>
      )}
    </DetailsTemplate>
  );
};
