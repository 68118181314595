import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../utils";
import {
  RuleEditorForm,
  ruleEditorFormDefaultValues,
  ruleEditorFormValidationSchema,
} from "./rule-editor.form";

export const useRuleEditor = (
  ruleToEdit: HubEnergy.DiligenceEventRuleSimpleResponse | undefined,
  setRule:
    | React.Dispatch<
        React.SetStateAction<HubEnergy.DiligenceEventRuleResponse | undefined>
      >
    | undefined,
  setMode?: React.Dispatch<React.SetStateAction<"display" | "editing">>,
) => {
  const { callService: create, loading: createLoading } = useServiceCall(
    service.hubEnergy.createDiligenceEventRule,
  );
  const { callService: update, loading: updateLoading } = useServiceCall(
    service.hubEnergy.updateDiligenceEventRule,
  );

  const initializedRef = useRef(false);

  const navigate = useNavigate();

  const form = useForm<RuleEditorForm>({
    defaultValues: ruleEditorFormDefaultValues,
    resolver: yupResolver(ruleEditorFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const eventTypeWatcher = watch("eventType") as HubEnergy.EDiligenceEventType;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (ruleToEdit) {
          const { success, response, error } = await update({
            diligenceEventRuleId: ruleToEdit.id,
            ...values,
          });

          if (success) {
            OToastManager.success("Regra atualizada com sucesso");
            setRule?.((old) => ({
              ...(old ?? { actions: [], allowedDistributors: [] }),
              ...response.data.response,
            }));
            setMode?.("display");
          } else {
            OToastManager.danger(
              getValidationMessages(error)?.[0]?.errorMessage ??
                "Erro ao atualizar a regra",
            );
          }
        } else {
          const { success, response, error } = await create(values);

          if (success) {
            OToastManager.success("Regra criada com sucesso");
            navigate(
              corporateRouter.routes.energia.product.diligenceConfig.diligenceEvents.details.path(
                { diligenceEventRuleId: response.data.response.id },
              ),
              { replace: true },
            );
          } else {
            OToastManager.danger(
              getValidationMessages(error)?.[0]?.errorMessage ??
                "Erro ao criar a regra",
            );
          }
        }
      }),
    [create, handleSubmit, navigate, ruleToEdit, setMode, setRule, update],
  );

  useEffect(() => {
    if (!initializedRef.current && eventTypeWatcher === ruleToEdit?.eventType) {
      initializedRef.current = true;
      return;
    }
    reset({ ...ruleEditorFormDefaultValues, eventType: eventTypeWatcher });
  }, [eventTypeWatcher, reset, ruleToEdit?.eventType]);

  useEffect(() => {
    if (ruleToEdit) reset(ruleToEdit);
  }, [reset, ruleToEdit]);

  return {
    eventTypeWatcher,
    form,
    submit,
    submitLoading: createLoading || updateLoading,
  };
};
