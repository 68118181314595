import {
  OButton,
  OConfirmationModal,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { Account } from "../../account-close.types";
import { useGridActions } from "./grid-actions.hook";

interface GridActionsProps {
  data: Account;
  reload: () => void;
}

export const GridActions = ({
  data: { status, workflowId, id },
  reload,
}: GridActionsProps) => {
  const { cancelClosing, loading, generateFile, loadingFile } =
    useGridActions();
  const navigate = useNavigate();

  if (
    status !== "EM_PROCESSO_DE_ENCERRAMENTO" &&
    status !== "ENCERRADA" &&
    workflowId !== null
  )
    return null;

  if (status === "EM_PROCESSO_DE_ENCERRAMENTO") {
    const modalId = `cancel-account-close-${workflowId}`;
    return (
      <>
        <LoadingButton
          dataAction="encerramento_conta:botao:cancelar_encerramento"
          dataLabel="cancelar_encerramento"
          loading={loading}
          onClick={() => modalManager.show(modalId)}
          outline
          type="danger"
        >
          Cancelar encerramento
        </LoadingButton>
        <OConfirmationModal
          style={{ position: "absolute" }}
          modalId={modalId}
          title="Cancelar encerramento"
          confirmLabel="Sim"
          closeLabel="Não"
          onConfirm={async () => {
            modalManager.hide(modalId);
            await cancelClosing(workflowId);
            reload();
          }}
        >
          <div>Tem certeza que deseja cancelar o encerramento?</div>
        </OConfirmationModal>
      </>
    );
  }
  if (status === "ENCERRADA" && workflowId !== null) {
    return (
      <OButton
        dataAction="atualizacao_cadastral:botao:abrir_modal_historico_atualizacao_cadastral"
        dataLabel="abrir_modal_historico_atualizacao_cadastral"
        onClick={() =>
          navigate(
            corporateRouter.routes.cadastro.customer.accountClose.details.path({
              workflowUuid: workflowId,
            }),
          )
        }
        className="align-self-center"
      >
        <OTypography type="light">Ver Detalhes</OTypography>
      </OButton>
    );
  }
  if (status === "ENCERRADA" && workflowId === null) {
    return (
      <LoadingButton
        dataAction="atualizacao_cadastral:botao:abrir_modal_historico_atualizacao_cadastral"
        dataLabel="abrir_modal_historico_atualizacao_cadastral"
        loading={loadingFile}
        onClick={async () => {
          await generateFile(id, undefined);
        }}
        className="align-self-center"
      >
        <OTypography type="light">Gerar arquivo</OTypography>
      </LoadingButton>
    );
  }
  return null;
};
