import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services";

export const useLimitInvoices = (limitRequestIdd: string) => {
  const { callService, value, hasError, loading } = useServiceCall(
    service.hubCreditManager.getInvoices,
  );

  const [serviceInvoice, setServiceInvoice] =
    useState<NonNullable<typeof value>[number]>();
  const [productsInvoice, setProductsInvoice] =
    useState<NonNullable<typeof value>[number]>();

  useEffect(() => {
    callService(limitRequestIdd);
  }, [callService, limitRequestIdd]);

  useEffect(() => {
    setServiceInvoice(value?.find((invoice) => invoice.type === "SERVICES"));
    setProductsInvoice(value?.find((invoice) => invoice.type === "EQUIPMENTS"));
  }, [value]);

  return {
    serviceInvoice,
    productsInvoice,
    hasError,
    loading,
    callService,
  };
};
