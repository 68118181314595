import { OButton, OIcon, OLoader, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { useCollectionsAgreementContext } from "../../../convenio";
import { UpsertCollectionAgreementFormFields } from "../../upsert-collection-agreement.form";
import { useFormFooterline } from "./form-footerline.hook";

export const FormFooterline = () => {
  const { agreementToUpdate } = useCollectionsAgreementContext();
  const { upsertAgreement, isLoading } = useFormFooterline();
  const { handleSubmit } =
    useFormContext<UpsertCollectionAgreementFormFields>();
  const navigate = useNavigate();

  const labels = useMemo(() => {
    const action = agreementToUpdate ? "Editar" : "Cadastrar";

    const warningText = `Revise todos os campos antes de ${action.toLowerCase()} esse convênio`;
    const button = `${action} convênio`;

    return { warningText, button };
  }, [agreementToUpdate]);

  return (
    <div>
      {isLoading && <OLoader absolute backdrop />}
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="d-flex align-items-center gap-2">
          <OIcon category="orq" icon="orq-info-on" type="info" />
          <OTypography type="dark-80" key={labels.warningText}>
            {labels.warningText}
          </OTypography>
        </div>

        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            onClick={() =>
              navigate(
                corporateRouter.routes.banking.customer.collection.agreement
                  .path,
              )
            }
            outline
          >
            Voltar
          </OButton>
          <OButton onClick={handleSubmit(upsertAgreement)} key={labels.button}>
            {labels.button}
          </OButton>
        </div>
      </div>
    </div>
  );
};
