import { client } from "../../client";
import { b2bagroEndpoints } from "./b2bagro.endpoints";
import {
  GetProposalDetailsResponse,
  GetProposalDocumentsResponse,
  GetProposalPendenciesResponse,
  GetProposalsResponse,
} from "./models";
import { PostProposalsUpdateCanEditGuaranteeBody } from "./models/requests/post-proposal-update-can-edit-guarantee.request";
import { PostProposalUpdateStatusBody } from "./models/requests/post-proposal-update-status.request";

export const b2bAgroService = {
  getProposals() {
    return client.get<GetProposalsResponse>(
      b2bagroEndpoints.admin.proposals.endpoint,
      {
        headers: { origem: "agro" },
      },
    );
  },
  getProposalDetails(proposalId: string) {
    return client.get<GetProposalDetailsResponse>(
      b2bagroEndpoints.admin.proposals.byProposalId.endpoint(proposalId),
      {
        headers: { origem: "agro" },
      },
    );
  },
  updateProposalStatus(proposalId: string, body: PostProposalUpdateStatusBody) {
    return client.patch(
      b2bagroEndpoints.admin.proposals.byProposalId.updateStatus(proposalId),
      body,
      {
        headers: { origem: "agro" },
      },
    );
  },
  getProposalDocuments(proposalId: string) {
    return client.get<GetProposalDocumentsResponse>(
      b2bagroEndpoints.admin.proposals.byProposalId.documents(proposalId),
      {
        headers: { origem: "agro" },
      },
    );
  },
  getProposalPendencies(proposalId: string) {
    return client.get<GetProposalPendenciesResponse>(
      b2bagroEndpoints.admin.proposals.byProposalId.pendencies(proposalId),
      {
        headers: { origem: "agro" },
      },
    );
  },
  updateCanEditGuarantee(
    proposalId: string,
    body: PostProposalsUpdateCanEditGuaranteeBody,
  ) {
    return client.post<GetProposalPendenciesResponse>(
      b2bagroEndpoints.admin.proposals.byProposalId.editGuarantee(proposalId),
      body,
      {
        headers: { origem: "agro" },
      },
    );
  },
};
