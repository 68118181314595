import { OToastManager } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { format } from "utils/date";

interface FormValuesType {
  type: string;
  status: string;
  insertedAt: string;
  updatedAt: string;
}

export const useOnboardingWorkflowEdit = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>();
  const { reset, handleSubmit } = form;

  const workflows = useServiceCall(service.onboarding.getEntityWorkflows);
  const onboardingEnums = useServiceCall(service.onboarding.getWorkflowEnums);

  const { callService, value } = workflows;
  const { callService: getEnums } = onboardingEnums;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.onboarding.updateWorkflow({
        workflowUuid: id,
        portalStatus: values.status,
      });

      OToastManager.success("Onboarding editado com sucesso");

      navigate(corporateRouter.routes.cadastro.customer.onboardings.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    callService({ requestTaxId: customer.identification });
    getEnums();
  }, [callService, customer.identification, getEnums]);

  useEffect(() => {
    const getOnboarding = value?.find((onboarding) => onboarding?.id === id);

    if (!getOnboarding) return;

    const { relationship, portalStatus, createdDate, updatedDate } =
      getOnboarding;

    const resetValues = {
      type: relationship,
      status: portalStatus,
      insertedAt: format.date(createdDate),
      updatedAt: updatedDate && format.date(updatedDate),
    };

    reset(resetValues);
  }, [id, reset, value]);

  return {
    form,
    submit,
    loading,
    workflows,
    onboardingEnums,
    customer,
  };
};
