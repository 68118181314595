import {
  OCol,
  OOption,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORow,
  ORadio,
  OLabel,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ConfigurationFormProps } from "./configuration-form.types";

export const ConfigurationForm = ({ form }: ConfigurationFormProps) => {
  return (
    <CardTemplate>
      <FormProvider {...form}>
        <form>
          <ORow>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldSelect
                id="channel"
                name="channel"
                label="Canal de envio"
                required
              >
                <OOption id="sms" value="sms">
                  SMS
                </OOption>
                <OOption id="email" value="email">
                  E-mail
                </OOption>
                <OOption id="push" value="push">
                  Notificação Push
                </OOption>
                <OOption id="bell" value="bell">
                  Sininho
                </OOption>
              </ORFieldSelect>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                id="send_on_default?"
                name="sendOnDefault?"
                label="Envio por padrão?"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="sendOnDefault?-yes" value="true" />
                    <OLabel htmlFor="sendOnDefault?-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="sendOnDefault?-no" value="false" />
                    <OLabel htmlFor="sendOnDefault?-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} md={6} xl={4}>
              <ORFieldRadioGroup
                id="user_overridable?"
                name="userOverridable?"
                label="O usuário pode configurar?"
              >
                <div className="d-flex gap-5">
                  <div className="d-flex gap-2">
                    <ORadio id="userOverridable?-yes" value="true" />
                    <OLabel htmlFor="userOverridable?-yes">Sim</OLabel>
                  </div>
                  <div className="d-flex gap-2">
                    <ORadio id="userOverridable?-no" value="false" />
                    <OLabel htmlFor="userOverridable?-no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </form>
      </FormProvider>
    </CardTemplate>
  );
};
