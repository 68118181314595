export const appsRouteParams = {
  appId: "appId",
} as const;

export const ResourceRouteParams = {
  id: "id",
} as const;

export const usuariosRouteParams = {
  identification: "identification",
} as const;

type AppParams = Record<keyof typeof appsRouteParams, string>;

export type AppIdentificationRouteParams = Pick<AppParams, "appId">;
