import styled from "styled-components";

export const StyledCard = styled.div`
  width: 8.125rem;
  height: 6.125rem;
  position: relative;
  top: 12rem;
  left: 2rem;
  margin-bottom: 10rem;
  cursor: pointer;
`;
