import { PageTitle } from "components/page-title";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FileUpload, ValidationResults } from "./_compose";
import {
  BatchTaxasProvider,
  BatchTaxasProviderProps,
  useBatchTaxas,
} from "./batch-taxas.context";

const BatchTaxasComponent = () => {
  const { validationResults } = useBatchTaxas();

  return (
    <FormTemplate pageTitle={<PageTitle title="Configurar taxas" />}>
      {!validationResults ? (
        <CardTemplate>
          <FileUpload />
        </CardTemplate>
      ) : (
        <ValidationResults />
      )}
    </FormTemplate>
  );
};

export const BatchTaxas = (props: BatchTaxasProviderProps) => (
  <BatchTaxasProvider {...props}>
    <BatchTaxasComponent />
  </BatchTaxasProvider>
);
