import { masks } from "@maestro/utils";
import { customerQueryParam } from "components/access-control";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { UserRelatedEntity } from "services/clerk/models/types";
import { RemoveRelationshipButton } from "../../../../components";

const buildCompanyDetailsLink = (cnpj: string) => {
  const baseUrl = corporateRouter.routes.cadastro.customer.details.path;
  const searchParams = new URLSearchParams();
  searchParams.set(customerQueryParam, cnpj);

  return baseUrl + "?" + searchParams.toString();
};

export const buildRelatedEntitiesGrid = (
  userTaxId: string,
  reload: () => void,
): ODataGridGeneratorConfig<UserRelatedEntity> => ({
  datagrid: {
    noDataText: "Nenhuma empresa relacionada a este usuário.",
    pager: {
      showInfo: true,
      visible: true,
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} empresas)",
    },
  },
  columns: [
    {
      caption: "Detalhes",
      cellRender: ({ data }) =>
        data.identification && (
          <DetailsLink href={buildCompanyDetailsLink(data.identification)} />
        ),
    },
    {
      dataField: "displayName",
      dataType: "string",
      caption: "Nome",
    },
    {
      dataField: "identification",
      dataType: "string",
      caption: "CNPJ da empresa",
      cellRender: ({ data }) => masks.cpfCnpj(data.identification),
    },
    {
      dataField: "legalRepresentative",
      dataType: "boolean",
      caption: "Representante Legal",
    },
    {
      caption: "Ações",
      role: roles.usuarios.product.removeUserCompanyRelationshipButton.role,
      cellRender: ({ data }) =>
        data.identification ? (
          <RemoveRelationshipButton
            callback={reload}
            companyTaxId={data.identification}
            userTaxId={userTaxId}
          />
        ) : (
          "Empresa sem taxId"
        ),
    },
  ],
});
