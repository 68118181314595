import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";

export const useUserToCopySelector = (name: string) => {
  const [loading, setLoading] = useState(false);

  const { callService: getUserByEmail, value: userByEmail } = useServiceCall(
    service.gatekeeper.getUserByEmail,
  );

  const form = useFormContext();

  const { clearErrors, setError, setValue } = form;

  const getUserInformation = useCallback(
    async (inputValue: string) => {
      setLoading(true);
      try {
        const { success } = await getUserByEmail({ email: inputValue });

        if (success) clearErrors(name);
        else
          setError(name, {
            message: "Erro ao buscar os usuários para copiar",
          });
      } catch (err) {
        OToastManager.danger("Erro para salvar os dados");
      } finally {
        setLoading(false);
      }
    },
    [clearErrors, getUserByEmail, name, setError],
  );

  useEffect(() => {
    if (!userByEmail) return;
    if (userByEmail?.profiles) setValue("profiles", userByEmail.profiles);
  }, [setValue, userByEmail]);

  return {
    loading,
    getUserInformation,
  };
};
