import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { buildAssignmentDetails } from "./assignment-details.details";
import { assetsGrid } from "./assignment-details.grid";
import { useAssignmentDetails } from "./assignment-details.hook";

export const AssignmentDetails = () => {
  const { getAssignment, hasError, loading, assignmentDetails } =
    useAssignmentDetails();
  const gridRef = useGridRef();

  const gridSummary = useMemo(
    () => (
      <Summary texts={{ sum: "{0}" }}>
        <TotalItem
          column="price"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
            assignmentDetails?.assets?.[0].currency || "BRL",
            precision: 3,
          }}
        />
        <TotalItem
          column="maturityAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
              assignmentDetails?.assets?.[0].currency || "BRL",
            precision: 3,
          }}
        />
      </Summary>
    ),
    [assignmentDetails?.assets],
  );

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da operação" />}>
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar os detalhes da operação"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={() => getAssignment()} />
            </ErrorComponent>
          }
          value={assignmentDetails}
          fields={buildAssignmentDetails}
        />
        <GridTemplate
          actions={<RefreshGridButton onClick={() => getAssignment()} />}
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={assetsGrid}
            dataSource={assignmentDetails?.assets}
            loading={loading}
          >
            {gridSummary}
          </ODataGridGenerator>
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
