export const integrationMock = {
  integrations: [
    {
      id: "7d5d5c18-a030-4388-a902-3d3c60b357dc",
      companyDocument: "37297902000141",
      type: "VAN",
      status: "ACTIVE",
      inboundFilename: "37297902000141-COLLECTION-INBOUND-20240424063200.rem",
      outboundFilename: "37297902000141-COLLECTION-OUTBOUND-20240424063200.rem",
      contacts: [
        {
          name: "Agmar Torres",
          type: "Técnico",
          email: "arquivos.transmissoes@btgpactual.com",
          phone: "35999101023",
        },
        {
          name: "Agmar Torres",
          type: "VAN",
          email: "arquivostransmissoes@btgpactual.com",
          phone: "35999101022",
        },
      ],
      accounts: [
        {
          accountNumber: "00388685",
          accountDigit: "0",
          branch: "50",
        },
      ],
      createdAt: "15/05/2024",
    },
  ],
};
