import {
  DateRangeSectionFilter,
  IntervalAmountSectionFilter,
  MultSelectionSectionFilter,
  Section,
  SectionFilterType,
  SelectionOption,
} from "components/side-filter-bar";
import dayjs from "dayjs";
import {
  DataSourceType,
  MapperDataSourceType,
  MapperPaymentTypeGroup,
  ObjectValueValidateFunction,
  PaymentTypeGroup,
} from "../types";
import { ObjectUtils } from "./object";

export interface Filters {
  paymentType?: PaymentTypeGroup[];
  status?: DataSourceType[];
  taxId?: string[];
  paymentDate?: [dayjs.Dayjs, dayjs.Dayjs];
  amount?: [number, number];
}

export const FiltersValidator: Record<
  keyof Filters,
  ObjectValueValidateFunction<Filters>
> = {
  paymentType: (value) =>
    value === undefined ||
    (Array.isArray(value) &&
      value.every((item) => Object.values(PaymentTypeGroup).includes(item))),
  status: (value) =>
    value === undefined ||
    (Array.isArray(value) &&
      value.every((item) => Object.values(DataSourceType).includes(item))),
  taxId: (value) =>
    value === undefined ||
    (Array.isArray(value) && value.every((item) => typeof item === "string")),
  paymentDate: (value) =>
    value === undefined ||
    (Array.isArray(value) &&
      value.every((item) => dayjs.isDayjs(item) || item === undefined) &&
      value.length === 2),
  amount: (value) =>
    value === undefined ||
    (Array.isArray(value) &&
      value.every((item) => typeof item === "number") &&
      value.length === 2),
};

export const assembleSections = (
  filtersSubmitted: Filters | undefined,
): Section[] => {
  const paymentType: MultSelectionSectionFilter = {
    title: "Tipo de pagamento",
    formId: "paymentType",
    data: Object.values(PaymentTypeGroup).reduce<SelectionOption[]>(
      (data, value) => {
        return data.concat({
          label: {
            primary: MapperPaymentTypeGroup[value],
          },
          value,
        });
      },
      [],
    ),
    defaultValue: filtersSubmitted?.paymentType,
    type: SectionFilterType.MultSelection,
  };

  const status: MultSelectionSectionFilter = {
    title: "Status",
    formId: "status",
    data: Object.values(DataSourceType).reduce<SelectionOption[]>(
      (data, value) => {
        if (value === DataSourceType.All) return data;
        return data.concat({
          label: {
            primary: MapperDataSourceType[value],
          },
          value,
        });
      },
      [],
    ),
    defaultValue: filtersSubmitted?.status,
    type: SectionFilterType.MultSelection,
  };

  const paymentDate: DateRangeSectionFilter = {
    title: "Período",
    formId: "paymentDate",
    defaultValue: filtersSubmitted?.paymentDate,
    type: SectionFilterType.DateRange,
  };

  const amount: IntervalAmountSectionFilter = {
    title: "Valor da transferência",
    formId: "amount",
    defaultValue: filtersSubmitted?.amount,
    type: SectionFilterType.IntervalAmount,
  };

  return [paymentType, status, paymentDate, amount];
};

export const assembleFilters = (data: Record<string, unknown>): Filters => {
  const isValid = ObjectUtils.validateInterface<Filters>(
    data,
    FiltersValidator,
  );

  if (!isValid) throw Error("Invalid filters data");

  const filters: Filters = Object.fromEntries(
    Object.keys(FiltersValidator).reduce<[string, unknown][]>(
      (entries, entry) => {
        const target = data[entry];
        if (target) return [...entries, [entry, target]];
        return entries;
      },
      [],
    ),
  );

  return filters;
};
