import {
  OButton,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { FormProvider } from "react-hook-form";
import { ModalWithSelectOverflow } from "../../../../../components/modal-with-select-overflow";
import { diligenceStepStatusMap } from "../../../../../utils";
import { useBatchStepStatusModal } from "./batch-step-status-modal.hook";
import { batchStepStatusModalId } from "./batch-step-status-modal.utils";

interface BatchStepStatusModalProps {
  gridRef: React.RefObject<DataGrid>;
  selectedSteps: HubEnergy.DiligenceStepMonitor[];
}

export const BatchStepStatusModal = ({
  gridRef,
  selectedSteps,
}: BatchStepStatusModalProps) => {
  const { form, loading, submit } = useBatchStepStatusModal(
    selectedSteps,
    gridRef,
  );

  return (
    <ModalWithSelectOverflow id={batchStepStatusModalId} position="center">
      <OModalHeader closeButton>
        <OTypography type="dark" size="lg">
          Mudar status de steps em lote
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography type="dark" className="mb-4">
          {selectedSteps.length} steps selecionados
        </OTypography>
        <FormProvider {...form}>
          <ORFieldSelect id="status" name="status" label="Status">
            {Object.entries(diligenceStepStatusMap).map(([key, value]) => (
              <OOption key={key} value={key}>
                {value.text}
              </OOption>
            ))}
          </ORFieldSelect>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(batchStepStatusModalId)}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
