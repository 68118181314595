import styled from "styled-components";

export const SummaryTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;

  td {
    border-color: var(--theme-dark);
    border-style: solid;
    border-width: 1px;
    padding: 5px 10px;
  }
  th {
    border-color: var(--theme-dark);
    border-style: solid;
    border-width: 1px;
    padding: 5px 10px;
  }
`;
