import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { OLink, OTypography } from "@maestro/react";
import { NotificationForm } from "../_compose/notificacoes-form";
import { useRavenNotificationTypeAdd } from "./add-notification-type.hook";

export const RavenNotificationAdd = () => {
  const { submit, form, loading } = useRavenNotificationTypeAdd();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Notificações"
          description="Adicione um novo tipo de notificação"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Adicionar
        </LoadingButton>
      }
    >
      <OTypography type="default" className="mb-3">
        Antes de registrar sua notificação, certifique-se de ler atentamente a
        documentação da API,{" "}
        <OLink
          href="https://wiki.quickfin.com.br/en/Products/Seguran%C3%A7a/Raven/NotificationHub"
          target="_blank"
        >
          disponível aqui
        </OLink>
      </OTypography>
      <OTypography type="default" className="mb-3">
        Você pode conferir a nossa biblioteca de ícones{" "}
        <OLink
          href="https://maestro.empresas.btgpactual.com/docs/main/?path=/docs/lista-de-%C3%ADcones--page"
          target="_blank"
        >
          aqui
        </OLink>
      </OTypography>
      <NotificationForm form={form} />
    </FormTemplate>
  );
};
