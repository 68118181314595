import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { logger } from "utils/logger";
import { Deflator } from "./deflators.types";

export const dataSource = dataSourceCustomStoreGenerator<Deflator>(
  (loadOptions) => {
    // serializer workaround
    // https://github.com/axios/axios/issues/5094#issuecomment-1274863322
    if (Array.isArray(loadOptions.sort)) {
      // eslint-disable-next-line no-param-reassign
      loadOptions.sort = loadOptions.sort.map((option) =>
        JSON.stringify(option),
      );
    }
    return service.recebiveisCartao
      .getDeflators(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar os deflatores";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  },
  {
    customStoreOptions: {
      update: async ({ id }, { deflation_value }) => {
        try {
          await service.recebiveisCartao.editDeflator(id, {
            deflation_value,
          });
          OToastManager.success("Deflator editado com sucesso");
        } catch (err) {
          if (!isAxiosError(err)) logger.error(err);
          OToastManager.danger("Não foi possível editar o deflator");
        }
      },
    },
  },
);

export const deflatorsGrid: ODataGridGeneratorConfig<Deflator> = {
  datagrid: {
    noDataText: "Nenhum deflator.",
    filterRow: {
      visible: true,
    },
    editing: {
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
    remoteOperations: true,
    onRowUpdated: () => dataSource.reload(),
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "company_name",
      dataType: "string",
      caption: "Empresa",
      allowEditing: false,
    },
    {
      dataField: "company_cnpj",
      dataType: "string",
      caption: "CNPJ (Empresa)",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowEditing: false,
    },
    {
      dataField: "credenciadora_name",
      dataType: "string",
      caption: "Credenciadora",
      allowEditing: false,
    },
    {
      dataField: "credenciadora_cnpj",
      dataType: "string",
      caption: "CNPJ (Cred.)",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowEditing: false,
    },
    {
      dataField: "deflation_value",
      dataType: "string",
      caption: "Deflator",
      validationRules: [
        { type: "required" },
        {
          type: "pattern",
          message: "Usar '.' (ponto) como separador decimal",
          pattern: /^[^,]+$/,
        },
        {
          type: "custom",
          message: "O deflator deve ser um número entre 0 e 1!",
          validationCallback: ({ value }) => {
            try {
              const floatValue = parseFloat(value);

              return floatValue >= 0 && floatValue <= 1;
            } catch {
              return false;
            }
          },
        },
      ],
    },
  ],
};
