import { useState } from "react";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { FormTemplate } from "templates/form-template";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { listIntegrationGrid } from "./integrations.grid";
import { useFileProcessor } from "./integrations.hook";
import {
  IntegrationFileProcessor,
  IntegrationFormValues,
  integrationSchema,
  integrationDefaultValues,
} from "./integrations.form";
import { ProductsCNAB } from "./integrations.utils";
import { EditIntegrationModal } from "./compose/modal/edit-integration.details";
import { integrationMock } from "./mock/integration";
import { AddIntegrationModal } from "./compose/modal/add-integration.details";
import { IntegrationModalName } from "./compose/enums/integrationModalName";
import { AddAccountIntegrationModal } from "./compose/modal/add-account-integration.details";
import { AddContactIntegrationModal } from "./compose/modal/add-contact-integration.details";

export const UpdateIntegrationPage = () => {
  const { getIntegrations } = useFileProcessor();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [integrations, setIntegrations] = useState<IntegrationFileProcessor[]>(
    [],
  );
  const [currentIntegration, setCurrentIntegration] =
    useState<IntegrationFileProcessor>(integrationMock.integrations[0]);
  const form = useForm<IntegrationFormValues>({
    resolver: yupResolver(integrationSchema),
    defaultValues: integrationDefaultValues,
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      setHasError(false);
      const response = await getIntegrations(values);

      setIntegrations(response || []);
    } catch {
      setHasError(true);
      OToastManager.danger("Erro ao buscar integrações");
    } finally {
      setLoading(false);
    }
  });

  return (
    <DetailsTemplate>
      <div className="d-flex flex-column gap-5">
        <FormTemplate
          pageTitle={
            <>
              <PageTitle title="Listagem de integrações" />
              <ORow className="mr-5 mb-2 w-auto" justify="end" align="end">
                <OButton
                  className="w-auto"
                  onClick={() => {
                    modalManager.show(IntegrationModalName.ADD_INTEGRATION);
                  }}
                >
                  Nova Integração +
                </OButton>
              </ORow>
            </>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <ORow>
                <OCol md={12} xl={5}>
                  <ORFieldInput
                    tag="cnpj"
                    id="document"
                    name="document"
                    dataAction="pesquisar_integracao:texto:cnpj"
                    dataLabel="cnpj"
                    label="CNPJ"
                  />
                </OCol>
                <OCol md={12} xl={5}>
                  <ORFieldSelect
                    id="product"
                    name="product"
                    dataAction="atualizar_integracao:texto:produto_cnab"
                    dataLabel="produto_cnab"
                    label="Produto Cnab"
                  >
                    {Object.entries(ProductsCNAB).map(([key, value]) => (
                      <OOption value={value} key={key}>
                        {key}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
                <OCol md={2} align="center" justify="center">
                  <OButton
                    dataAction="pesquisar_integracao:texto:pesquisar"
                    dataLabel="pesquisar"
                    onClick={() => onSubmit()}
                  >
                    Pesquisar
                  </OButton>
                </OCol>
              </ORow>
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          noValue
          errorComponent={
            <ErrorComponent
              messageTitle="Ocorreu um erro ao buscar integrações"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => onSubmit()} />
            </ErrorComponent>
          }
          render={() => (
            <ODataGridGenerator
              grid={listIntegrationGrid(setCurrentIntegration)}
              dataSource={integrations}
            />
          )}
        />
      </div>
      <EditIntegrationModal
        integrations={integrations}
        setIntegrations={setIntegrations}
        currentIntegration={currentIntegration}
      />
      <AddIntegrationModal />
      <AddContactIntegrationModal />
      <AddAccountIntegrationModal />
    </DetailsTemplate>
  );
};
