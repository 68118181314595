import { MoneyMask } from "../../../../utils";
import { RowGap } from "../../../cards.styles";
import { Currency } from "../../../cards.types";
import { GeneralInfo } from "../../../compose/general-info/general-info.component";
import { DescriptionCardInfo } from "./constants";
import { IDescriptionProps } from "./description.types";

export const Description = ({ activeInvoiceData }: IDescriptionProps) => {
  return (
    <RowGap columnGap={80}>
      {DescriptionCardInfo.map((info) => (
        <div key={info.key}>
          <GeneralInfo
            label={info.label}
            value={MoneyMask(
              activeInvoiceData[
                info.key as keyof typeof activeInvoiceData
              ] as string,
              Currency.BRL,
            )}
            strongValue={false}
          />
        </div>
      ))}
    </RowGap>
  );
};
