import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { PreviewBatchTiersInfo } from "../../upload-tiers.types";

export const previewBatchTiersGrid: ODataGridGeneratorConfig<PreviewBatchTiersInfo> =
  {
    datagrid: {
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "sacadoName",
        dataType: "string",
        caption: "Sacado",
      },
      {
        dataField: "sacadoIdentification",
        dataType: "string",
        caption: "CNPJ do banco emissor",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "cedenteName",
        dataType: "string",
        caption: "Cedente",
      },
      {
        dataField: "cedenteIdentification",
        dataType: "string",
        caption: "CNPJ da credenciadora",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "tierClassification",
        dataType: "number",
        caption: "Tier",
      },
    ],
  };
