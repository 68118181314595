import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CompanyGroupAssociationsItem } from "services/onboarding/models";
import { EmpresasClienteByIdentificationRouteParams } from "../../../../routes/empresas.route-params";
import { validationSchema } from "../_compose/group-form/group-form.form";
import { FormValuesType } from "../_compose/group-form/group-form.types";

export const useEditGroup = () => {
  const { identification } =
    useParams<EmpresasClienteByIdentificationRouteParams>();

  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);
  const [companyGroup, setCompanyGroup] = useState<
    CompanyGroupAssociationsItem[]
  >([]);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        companyGroupAssociations: companyGroup,
      };

      await service.onboarding.registerGroupAndAssociations(payload);

      OToastManager.success("Grupo alterado com sucesso");

      navigate(routes.empresas.product.groups.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    if (!identification) return;

    const { data } = await service.onboarding.getCompaniesFromGroupByTaxId(
      identification,
    );

    const resetValues = {
      description: data.description,
      groupName: data.groupName,
      officialName: data.officialName,
      taxId: data.taxId,
      type: data.type,
    };

    reset(resetValues);
  }, [identification, reset]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    form,
    loading,
    setCompanyGroup,
    submit,
  };
};
