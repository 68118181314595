import styled, { css } from "styled-components";

interface OverflowWithFadeProps {
  $maxHeightPx: number;
  $hasFade: boolean;
}

export const OverflowWithFade = styled.div<OverflowWithFadeProps>`
  max-height: ${({ $maxHeightPx }) => `${$maxHeightPx}px`};
  overflow: hidden;

  ${({ $hasFade }) =>
    $hasFade &&
    css`
      mask-image: linear-gradient(180deg, #000 60%, transparent);
    `}
`;
