/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-mutable-exports */

export const dummyStorage: Storage = {
  clear: () => null,
  getItem: () => null,
  key: () => null,
  removeItem: () => null,
  setItem: () => null,
  length: 0,
};

export let safeLocalStorage: Storage;

try {
  safeLocalStorage = window.localStorage;
} catch {
  safeLocalStorage = dummyStorage;
  console.error("localStorage blocked, applying fallback");
}

export let safeSessionStorage: Storage;

try {
  safeSessionStorage = window.sessionStorage;
} catch {
  safeSessionStorage = dummyStorage;
  console.error("sessionStorage blocked, applying fallback");
}
