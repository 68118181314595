import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GroupOfSignatures, SignatureGroupForm } from "./signature-group.types";

export const useSignatureGroup = (
  ruleId: string | number,
  groupOfSignatures: GroupOfSignatures | undefined,
) => {
  const {
    customer: { quickfinId },
  } = useCustomer();

  const {
    callService: _getCompanyWithOnboarding,
    hasError: getCompanyHasError,
    loading: getCompanyLoading,
    value: company,
  } = useServiceCall(service.quickfin.getCompanyWithOnboarding);

  const { callService: editGroupOfSignatures, loading: editLoading } =
    useServiceCall(service.paperclipv2.editGroupOfSignatures);

  const { callService: addGroupOfSignatures, loading: addLoading } =
    useServiceCall(service.paperclipv2.addGroupOfSignatures);

  const navigate = useNavigate();

  const form = useForm<SignatureGroupForm>();

  const { handleSubmit, reset } = form;

  const getCompanyWithOnboarding = useCallback(
    () => _getCompanyWithOnboarding(quickfinId),
    [_getCompanyWithOnboarding, quickfinId],
  );

  const edit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!groupOfSignatures) throw new Error("No groupOfSignatures");

        const { success } = await editGroupOfSignatures(
          ruleId,
          groupOfSignatures.id,
          {
            groupId: groupOfSignatures.id,
            signatureRule: Number(ruleId),
            updateGroupCommandBodyContent: values,
          },
        );

        if (success)
          OToastManager.success("Grupo de assinaturas alterado com sucesso");
        else OToastManager.danger("Erro ao modificar o grupo de assinaturas");
      }),
    [editGroupOfSignatures, groupOfSignatures, handleSubmit, ruleId],
  );

  const add = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addGroupOfSignatures(ruleId, {
          signatureRuleId: Number(ruleId),
          bodyContent: values,
        });

        if (success) {
          OToastManager.success("Grupo de assinaturas adicionado com sucesso");
          navigate(corporateRouter.routes.recebiveis.customer.signatures.path);
        } else OToastManager.danger("Erro ao adicionar o grupo de assinaturas");
      }),
    [addGroupOfSignatures, handleSubmit, navigate, ruleId],
  );

  useEffect(() => {
    getCompanyWithOnboarding();
  }, [getCompanyWithOnboarding]);

  useEffect(() => {
    if (groupOfSignatures) {
      reset(groupOfSignatures);
    }
  }, [groupOfSignatures, reset]);

  const legalRepresentatives = useMemo(
    () =>
      company?.legalRepresentatives
        .map((representative) => ({
          name: representative.name,
          email: representative.email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [company?.legalRepresentatives],
  );

  return {
    form,
    getCompanyHasError,
    getCompanyWithOnboarding,
    loading: getCompanyLoading,
    legalRepresentatives,
    submit: groupOfSignatures ? edit : add,
    submitLoading: editLoading || addLoading,
  };
};
