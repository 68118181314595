import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { contractTemplatesGrid } from "./contract-templates.grid";

const dataSource = dataSourceCustomStoreGenerator(
  () =>
    service.quickfin.getPublicContractTemplates().catch(() => {
      throw new Error("Erro ao buscar contratos");
    }),
  {
    customStoreOptions: {
      remove: async (template) => {
        try {
          await service.quickfin.deletePublicContractTemplate(template.id);

          OToastManager.success("Template excluido com sucesso");
        } catch {
          OToastManager.danger("Não foi possível excluir o template");
        }
      },
    },
  },
);

export const ContractTemplatesPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Templates de contrato"
          description="Visualize e edite os templates de contrato públicos"
        />
      }
      actions={
        <RouterButton
          dataAction="recebiveis_templates_contrato:botao:adicionar_template"
          dataLabel="adicionar_template"
          href={
            corporateRouter.routes.recebiveis.product.contractTemplates.add.path
          }
          role={roles.recebiveis.product.contractTemplatesAddPage.role}
        >
          Adicionar template
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={contractTemplatesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
