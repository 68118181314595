import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import dayjs from "dayjs";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { FeesSacadoGrid } from "./fees-sacado.types";
import {
  banksOptions,
  customizeData,
  formatFavored,
  rebateTypeMap,
} from "./fees-sacado.utils";

export const dataSourceGenerator = (cge: string) =>
  dataSourceCustomStoreGenerator<FeesSacadoGrid>(
    () =>
      service.turtlefee
        .getFees({
          feemodality: "Sacado",
          "partners[0].key": "sacado",
          "partners[0].value": cge,
        })
        .then(({ data }) => customizeData(data))
        .catch(() => {
          const errorMessage = "Erro ao buscar o extrato";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        update: async (data, updated) => {
          try {
            await service.turtlefee.updateFee(data.id, {
              ...data,
              ...updated,
              favored: formatFavored(data, updated),
            });
            OToastManager.success("Fee atualizado com sucesso.");
          } catch {
            OToastManager.danger(
              "Houve um problema ao atualizar, tente novamente mais tarde.",
            );
          }
        },
      },
    },
  );

export const feesGrid: ODataGridGeneratorConfig<FeesSacadoGrid> = {
  datagrid: {
    noDataText: "Nenhuma taxa cadastrada.",
    editing: {
      allowUpdating: true,
      mode: "row",
    },
  },
  columns: [
    {
      dataField: "calculationMethod",
      caption: "Tipo de rebate",
      allowEditing: false,
      cellRender: ({ data }) =>
        getValueFromMap(rebateTypeMap, data.calculationMethod.code) ??
        data.calculationMethod.code,
    },
    {
      dataField: "feePercentage",
      caption: "Taxa",
      allowEditing: false,
      cellRender: ({ data }) => masks.percentage(data.feePercentage),
    },
    {
      dataField: "paymentDay",
      caption: "Dia pagamento",
      allowEditing: false,
    },
    {
      dataField: "paymentType",
      caption: "Tipo do pagamento",
      allowEditing: false,
      cellRender: ({ data }) =>
        data.paymentType.disbursement.split ? "Desembolso" : "Liquidação",
    },
    {
      dataField: "bank",
      caption: "Banco",
      minWidth: 180,
      lookup: {
        dataSource: banksOptions,
        displayExpr: "label",
        valueExpr: "value",
      },
    },
    {
      dataField: "agency",
      caption: "Agência",
    },
    {
      dataField: "account",
      caption: "Conta",
    },
    {
      dataField: "profitShare",
      caption: "Participação nos lucros",
      allowEditing: false,
    },
    {
      dataField: "validFrom",
      caption: "Início da vigência",
      dataType: "date",
      format: "shortDate",
      allowEditing: false,
    },
    {
      dataField: "validTo",
      caption: "Fim da vigência",
      dataType: "date",
      format: "shortDate",
      validationRules: [
        {
          type: "custom",
          message:
            "Fim de vigência deve ser uma data superior ao início de vigência",
          ignoreEmptyValue: true,
          validationCallback: (e) => {
            if (dayjs(e.data.validTo).isBefore(e.data.validFrom)) return false;
            return true;
          },
        },
      ],
    },
  ],
};
