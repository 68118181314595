import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useAddStrategy } from "./add-strategy.hook";

export const AddStrategyPage = () => {
  const { loadingAddStrategy, form, addStrategy } = useAddStrategy();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar estratégia" />}
      actions={
        <LoadingButton
          loading={loadingAddStrategy}
          onClick={handleSubmit(addStrategy)}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <form spellCheck="false">
                <ORow>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="strategyName"
                      name="strategyName"
                      label="Nome"
                      labelSize="md"
                      tag="text"
                      placeholder="Preencher"
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="strategyCr"
                      name="strategyCr"
                      label="CR"
                      labelSize="md"
                      tag="number"
                      placeholder="Preencher"
                    />
                  </OCol>
                </ORow>
              </form>
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
