import {
  ORow,
  OCol,
  ORFieldCheckboxGroup,
  OCheckbox,
  OLabel,
  ORFieldInput,
} from "@maestro/react";
import {
  editStepPropertyFormFields,
  checkboxValue,
} from "../../edit-step-property-modal.form";
import { DocumentTypeSelector } from "../document-type-selector";
import { PropertyTypeSelector } from "../property-type-selector";

interface EditStepPropertyFieldsProps {
  idPrefix: string;
}

export const EditStepPropertyFields = ({
  idPrefix,
}: EditStepPropertyFieldsProps) => {
  return (
    <ORow align="end">
      <OCol xs={12} md={6}>
        <PropertyTypeSelector id={`${idPrefix}-typeId`} />
      </OCol>
      <OCol xs={12} md={6}>
        <DocumentTypeSelector id={`${idPrefix}-documentTypeId`} />
      </OCol>
      <OCol sm={12} md={6}>
        <ORFieldInput {...editStepPropertyFormFields.minimumAmount} />
      </OCol>
      <OCol sm={12} md={6}>
        <ORFieldCheckboxGroup {...editStepPropertyFormFields.isPublic}>
          <div className="d-flex align-items-center gap-2">
            <OCheckbox
              id={`${idPrefix}-is-public-checkbox`}
              value={checkboxValue}
              size="sm"
            />
            <OLabel htmlFor={`${idPrefix}-is-public-checkbox`}>Público?</OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
