import { modalManager, OButton, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useEditProcessor } from "../../edit-processor.context";
import { PROPERTY_MODAL_ID } from "../property-modal/property-modal.component";
import { propertiesGrid } from "./properties-grid.grid";

export const PropertiesGrid = () => {
  const {
    processor: { value: processor },

    setSelectedProperty,
  } = useEditProcessor();

  const { show } = modalManager;

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between align-items-center">
        <OTypography tag="h2" weight="500" size="lg">
          Propriedades
        </OTypography>
        <OButton
          onClick={() => {
            setSelectedProperty(undefined);
            show(PROPERTY_MODAL_ID);
          }}
        >
          Adicionar
        </OButton>
      </div>
      <ODataGridGenerator
        grid={propertiesGrid}
        dataSource={processor?.properties ?? []}
      />
    </div>
  );
};
