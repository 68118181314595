import { PostContingencyEligibilityErrorResponse } from "services/eligibilityengine/models";

export const mapErrors = (errors: PostContingencyEligibilityErrorResponse) => {
  return errors.map((error) => {
    return {
      CNPJ: error.governmentId,
      Produto: error.productKind,
      Linha: error.lineNumber,
      Erros: error.validationResult.errorList.toString().replaceAll('"', ""),
    };
  });
};
