import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetBalanceBalanceByAccountResponse } from "services/bankinghub/models";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";

const mapperAccountStatus = {
  "1": "Ativa",
  "2": "Bloqueio parcial",
  "3": "Bloqueio total",
  "4": "Inativa",
  "5": "Encerrada",
  "7": "Em processo de encerramento",
};

export const accountsGrid: ODataGridGeneratorConfig<
  BankingHub.Account & GetBalanceBalanceByAccountResponse
> = {
  datagrid: {
    noDataText: "Nenhuma conta cadastrada",
    pager: {
      infoText: "Página {0} de {1} ({2} contas)",
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
  },
  columns: [
    { dataField: "agency", caption: "Agência" },
    { dataField: "number", caption: "Conta" },
    { dataField: "service", caption: "Tipo" },
    {
      allowHeaderFiltering: false,
      allowEditing: false,
      dataField: "balance",
      caption: "Saldo",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      allowHeaderFiltering: false,
      allowEditing: false,
      dataField: "blockedBalance",
      caption: "Saldo bloqueado",
      format: (value) => masks.currency(value || 0, currencySymbol.BRL),
    },
    {
      dataField: "status",
      allowEditing: false,
      caption: "Status",
      customizeText: ({ value }) => {
        return (
          getValueFromMap(mapperAccountStatus, value?.toString()) ??
          value?.toPrecision()
        );
      },
    },
    {
      caption: "Ações",
      cellRender: ({ data, component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              label: "Extrato",
              icon: { category: "orq", icon: "orq-bills-receive" },
              role: roles.cadastro.customer.btgAccountsAccountAdminStatementPage
                .role,
              route:
                corporateRouter.routes.banking.customer.accounts.account.agency.statement.path(
                  {
                    account: data.number ?? "",
                    agency: data.agency ?? "",
                  },
                ),
            },
            {
              label: "Informe de rendimentos",
              icon: { category: "orq", icon: "orq-file" },
              role: roles.banking.customer
                .btgAccountsAccountAdminCorporateTaxPage.role,
              route:
                corporateRouter.routes.banking.customer.accounts.account.agency.corporateTax.path(
                  {
                    agency: data.agency ?? "",
                    account: data.number ?? "",
                  },
                ),
            },
            {
              label: "Usuários restritos",
              icon: { category: "orq", icon: "orq-block" },
              role: roles.cadastro.customer
                .btgAccountsAccountAdminUsersBlockedPage.role,
              route:
                corporateRouter.routes.cadastro.customer.btgAccounts.accountAdmin.usersBlocked.path(
                  {
                    id: `${data.number}${data.agency}`,
                  },
                ),
            },
            {
              label: "Editar",
              icon: { category: "orq", icon: "orq-edit-pencil" },
              onClick: () => {
                component.editRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
  ],
};
