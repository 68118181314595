import { Nullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface DiligenceStepForm {
  status: string;
  expirationDate: string | null;
  isPublic: string[];
}

export const diligenceStepFormValidationSchema = yup.object<
  DiligenceStepForm,
  YupObjectSchema<DiligenceStepForm>
>({
  status: yup.string().required("Campo obrigatório"),
});

export const checkboxValue = "true";

export const diligenceStepFormDefaultValues = {
  expirationDate: null,
  status: null,
  isPublic: [],
} satisfies Partial<
  Nullable<DiligenceStepForm>
> as unknown as DiligenceStepForm;
