import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { OperationsODataValueItem } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { operationStatusLibrary } from "./operations.utils";

export const grid: ODataGridGeneratorConfig<OperationsODataValueItem> = {
  datagrid: {
    noDataText: "Nenhuma operação",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      role: roles.recebiveis.product.operationsDetalhesPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.recebiveis.product.operations.trades.detalhes.path(
            {
              companyId: data.CompanyId,
              operationId: data.Id,
            },
          )}
        />
      ),
    },
    { dataField: "Id", caption: "Id" },
    { dataField: "SacadoName", caption: "Sacado" },
    {
      dataField: "PrazoMedio",
      caption: "Prazo Médio",
      format: "#,##0.00",
      dataType: "number",
      allowHeaderFiltering: false,
    },
    {
      dataField: "TaxaMensal",
      caption: "Taxa Mensal",
      allowHeaderFiltering: false,
      format: { type: "percent", precision: 4 },
      dataType: "number",
    },
    {
      dataField: "Status",
      caption: "Situação",
      cellRender: ({ data }) =>
        getValueFromMap(operationStatusLibrary, data.Status) ?? data.Status,
    },
    {
      dataField: "ValorDesembolsoAntecipado",
      caption: "Valor desembolso sucesso",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "ValorVencimentoAntecipado",
      caption: "Valor vencimento sucesso",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "ValorSolicitado",
      caption: "Valor solicitado",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      sortOrder: "desc",
    },
    {
      dataField: "ValorDesembolsoRecusado",
      caption: "Valor desembolso recusado",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "ValorVencimentoRecusado",
      caption: "Valor vencimento recusado",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "ValorDesembolsoPendente",
      caption: "Valor desembolso pendente",
      format: (value) => masks.currency(value),
    },
    {
      dataField: "ValorVencimentoPendente",
      caption: "Valor vencimento pendente",
      format: (value) => masks.currency(value),
    },
    { dataField: "TradesAntecipadosQtd", caption: "Trades sucesso" },
    { dataField: "TradesRecusadosQtd", caption: "Trades recusados" },
    { dataField: "TradesPendentesQtd", caption: "Trades pendentes" },
    {
      dataField: "TCO",
      caption: "TCO",
      format: (value) => masks.currency(value),
    },
  ],
};
