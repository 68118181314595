import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";

interface StepBoardProps {
  step: HubEnergy.ConsumerUnitsByStepResponse;
}

export const StepBoard = ({ step }: StepBoardProps) => {
  return (
    <OCard style={{ width: "max-content" }}>
      <OCardHeader style={{ minWidth: "max-content" }}>
        <OTypography>
          {step.stepType.label}{" "}
          {!!step.consumerUnits.length && `(${step.consumerUnits.length})`}
        </OTypography>
      </OCardHeader>
      <OCardBody className="d-flex flex-column gap-3">
        {step.consumerUnits.map((cu) => (
          <OCard key={cu.relatedEntityId}>
            <div
              style={{ width: "min-content" }}
              className="p-2 d-flex flex-column gap-1"
            >
              <div className="d-grid">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={cu.officialName}
                >
                  {cu.officialName}
                </p>
              </div>
              <div
                className="d-flex flex-column gap-1"
                style={{ width: "max-content" }}
              >
                <p>{masks.cpfCnpj(cu.taxId)}</p>
                <p>Nº Instalação: {cu.installationNumber}</p>
              </div>
            </div>
          </OCard>
        ))}
      </OCardBody>
    </OCard>
  );
};
