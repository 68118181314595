import { OModal, OModalBody, OModalHeader, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useWalletDetails } from "pages/duplicatas/components/wallet-details/wallet-details.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { discountedWalletModal } from "./portfolio-mangament.utils";
import { MoanaOwnerReceivable } from "services/moana/models";
import { CustomPagination } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/custom-pagination";
import { IconButton } from "components/icon-button";
import { discountedGrid } from "./discounted-wallet-management.grid";

interface DiscountWalletModalProps {
  ranges?: number[] | null;
  documentNumber: string;
  totalItems: number;
}

export const DiscountWalletModal = ({
  ranges,
  documentNumber,
  totalItems,
}: DiscountWalletModalProps) => {
  const gridRef = useGridRef();
  const {
    getDiscountedPortfolio,
    rows,
    setRows,
    page,
    setPage,
    exportDiscountedWallet,
  } = useWalletDetails(documentNumber);

  const [dataSource, setDataSource] = useState<MoanaOwnerReceivable[]>([]);

  const fetchDiscountedPortfolio = useCallback(async () => {
    const response = await getDiscountedPortfolio(page, rows, ranges);
    setDataSource(response);
  }, [getDiscountedPortfolio, page, rows, ranges]);

  useEffect(() => {
    fetchDiscountedPortfolio();
  }, [fetchDiscountedPortfolio]);

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        grid={discountedGrid(rows)}
        gridRef={gridRef}
        dataSource={dataSource}
      />
    ),
    [gridRef, dataSource, rows],
  );

  return (
    <OModal id={discountedWalletModal} size="lg" position="right">
      <OModalHeader>
        <OTypography size="lg">Detalhes da carteira desconta</OTypography>
      </OModalHeader>
      <OModalBody>
        <GridTemplate
          gridRef={gridRef}
          actions={
            <IconButton
              icon={{ category: "far", icon: "fa-file-spreadsheet" }}
              style={{ width: "16px", marginRight: "30px" }}
              onClick={() => exportDiscountedWallet(ranges)}
              type="default"
              outline
            />
          }
        >
          {memoizedGrid}
          <CustomPagination
            totalCount={totalItems}
            currentPage={page}
            setCurrentPage={setPage}
            pagination={[10, 20, 30]}
            selectedPaginationSize={rows}
            setSelectedPaginationSize={setRows}
          />
        </GridTemplate>
      </OModalBody>
    </OModal>
  );
};
