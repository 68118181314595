import {
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  ORFieldInputDateRange,
  ORow,
  OTypography,
} from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { Pie } from "react-chartjs-2";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  loanProductDashboardAmountDetails,
  loanProductDashboardDetails,
} from "./loan-product-dashboard.details";
import { useLoanProductDashboard } from "./loan-product-dashboard.hook";
import { loanProductDashboardDescriptionMap } from "./loan-product-dashboard.utils";

export const LoanProductDashboardPage = () => {
  const { form, dashboard, amountKeyValue, memoizedAmountDescription } =
    useLoanProductDashboard();

  return (
    <ORow gap={2}>
      <OCol xs={12} lg={10} />
      <OCol xs={12} lg={2}>
        <div className="position-relative w-100">
          <FormProvider {...form}>
            <ORFieldInputDateRange id="period" name="period" label="Período" />
          </FormProvider>
        </div>
      </OCol>

      <OCol xs={12} lg={4}>
        <ContentTemplate
          hasError={dashboard.hasError}
          loading={dashboard.loading}
          value={dashboard.value}
          render={(_value) => (
            <OCard>
              <OCardHeader>
                <OTypography size="lg">Distribuição por Status</OTypography>
              </OCardHeader>
              <OCardBody>
                <Pie
                  data={{
                    labels: amountKeyValue
                      ? amountKeyValue.map(
                          (x) =>
                            getValueFromMap(
                              loanProductDashboardDescriptionMap,
                              x[0],
                            )?.label,
                        )
                      : [],
                    datasets: [
                      {
                        label: "Total de operações",
                        data: amountKeyValue
                          ? amountKeyValue.map((x) => x[1])
                          : [],
                        backgroundColor: memoizedAmountDescription,
                        borderColor: memoizedAmountDescription,
                      },
                    ],
                  }}
                />
              </OCardBody>
            </OCard>
          )}
        />
      </OCol>
      <OCol xs={12} lg={4}>
        <DetailsCard
          loading={dashboard.loading}
          hasError={dashboard.hasError}
          value={dashboard.value}
          fields={loanProductDashboardDetails}
        />
      </OCol>
      <OCol xs={12} lg={4}>
        <DetailsCard
          loading={dashboard.loading}
          hasError={dashboard.hasError}
          value={dashboard.value}
          fields={loanProductDashboardAmountDetails}
        />
      </OCol>
    </ORow>
  );
};
