import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByConsumerUnitIdRouteParams } from "../../../../../../../routes/energia.route-params";

export const useConsumerUnitCard = (
  onLoad: (
    value: HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitDetailsResponse>,
  ) => void,
) => {
  const { consumerUnitId } = useParams<MleByConsumerUnitIdRouteParams>();
  if (!consumerUnitId) throw new Error("No consumerUnitId");

  const { callService, loading, hasError, value, error } = useServiceCall(
    service.hubEnergy.getConsumerUnitDetails,
  );

  const getConsumerUnitDetails = useCallback(
    () => callService(consumerUnitId),
    [callService, consumerUnitId],
  );

  useEffect(() => {
    getConsumerUnitDetails();
  }, [getConsumerUnitDetails]);

  useEffect(() => {
    if (value) onLoad(value);
  }, [onLoad, value]);

  return {
    consumerUnitId,
    error,
    loading,
    hasError,
    value,
    getConsumerUnitDetails,
  };
};
