import { OperationsInstallmentsComponent } from "./operations-installments.component";
import { OperationsInstallmentsProvider } from "./operations-installments.context";

export const OperationsInstallmentsPage = () => {
  return (
    <OperationsInstallmentsProvider>
      <OperationsInstallmentsComponent />
    </OperationsInstallmentsProvider>
  );
};
