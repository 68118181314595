import { DetailsCard } from "components/details-card";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { tradeDetailsGenerator } from "./trade.details";
import { useTradeDetails } from "./trade.hook";

export const TradeDetails = () => {
  const { detail, detailLoader, downloadTrade, hasError, loading } =
    useTradeDetails();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Trade" />}
      actions={
        detail?.receivable?.trade?.comprovanteSnapshot && (
          <div className="d-flex justify-content-end">
            <LoadingButton
              dataAction="trade:botao:baixar_comprovante"
              dataLabel="baixar_comprovante"
              onClick={() => downloadTrade(detail.receivable.trade?.id)}
              loading={loading}
            >
              Baixar Comprovante
            </LoadingButton>
          </div>
        )
      }
    >
      <DetailsCard
        loading={detailLoader}
        hasError={hasError}
        value={detail?.receivable.trade}
        fields={tradeDetailsGenerator}
      />
    </FormTemplate>
  );
};
