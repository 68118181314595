import { ORadio } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { InstallmentOption } from "./renegociacao-condicoes.types";

export interface RenegotiationOffersGridProps {
  selectedOffer: InstallmentOption | undefined;
  setSelectedOffer: React.Dispatch<
    React.SetStateAction<InstallmentOption | undefined>
  >;
}

export const buildGrid = ({
  selectedOffer,
  setSelectedOffer,
}: RenegotiationOffersGridProps): ODataGridGeneratorConfig<InstallmentOption> => ({
  datagrid: {
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} itens)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      cellRender: ({ data }) => (
        <ORadio
          size="xs"
          id={data.installments_count.toString()}
          value={data.installments_count.toString()}
          checked={
            data.installments_count === selectedOffer?.installments_count
          }
          onInput={() => setSelectedOffer(data)}
        />
      ),
    },
    { dataField: "installments_count", caption: "Número de parcelas" },
    {
      dataField: "total_value",
      caption: "Valor total",
      customizeText: ({ value }) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "total_value_with_discount",
      caption: "Valor com desconto",
      customizeText: ({ value }) => masks.currency(value, "R$", "."),
    },
    {
      dataField: "original_interest_rate",
      caption: "Valor de juros (a.m.)",
      customizeText: ({ value }) =>
        value ? masks.percentage(value / 100) : "Não informado",
    },
    {
      dataField: "total_value",
      caption: "Valor da parcela",
      cellRender: ({ data }) =>
        masks.currency(data.total_value / data.installments_count, "R$", "."),
    },
  ],
});
