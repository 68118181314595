import { OTabLink, OTypography } from "@maestro/react";
import { cases } from "@maestro/utils";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { useLinkClickHandler } from "react-router-dom";
import { MenuTabs } from "../../tab-menu.types";

interface TabItemProps {
  tab: MenuTabs[keyof MenuTabs][number];
}

export const TabItem = ({ tab }: TabItemProps) => {
  const clickHandler = useLinkClickHandler<HTMLOTabLinkElement>(tab.path);
  const newTabHandler = useLinkNewTabHandler(tab.path);

  return (
    <OTabLink
      dataAction={`tab_menu:tab:${cases.snake(tab.name)}`}
      dataLabel={cases.snake(tab.name)}
      href={tab.path}
      onClick={(evt) => {
        if (!tab.disabled) clickHandler(evt);
      }}
      onAuxClick={(evt) => {
        if (!tab.disabled) newTabHandler(evt);
      }}
      style={{ minWidth: "fit-content" }}
      disabled={tab.disabled}
    >
      <OTypography tag="span" type="primary">
        {tab.name}
      </OTypography>
    </OTabLink>
  );
};
