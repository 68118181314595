import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams } from "../../../../../routes/ferramentas.route-params";
import { ProfilesForm } from "../_compose/profiles-form.component";
import {
  ProfilesFormValues,
  profileFormValidationSchema,
} from "../_compose/profiles-role-form.schemas";

export const AddProfileChangePage = () => {
  const { requestId } =
    useParams<FerramentasProdutoGerenciamentoPerfisByRequestIdRouteParams>();

  if (!requestId) throw new Error("No requestId");

  const form = useForm<ProfilesFormValues>({
    resolver: yupResolver(profileFormValidationSchema),
  });

  const { handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.gatekeeper.createProfileManagementRequestChange({
            changes: values.changes.map((change) => ({
              ...change,
              requestId: +requestId,
              type: values.type,
            })),
          });

          OToastManager.success("Mudanças salvas com sucesso");

          navigate(
            corporateRouter.routes.ferramentas.product.gatekeeper.profilesManagement.details.path(
              { requestId },
            ),
          );
        } catch {
          OToastManager.danger("Erro ao salvar mudanças");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, requestId],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar mudanças" />}
      actions={
        <LoadingButton
          dataAction="criar_mudanca_perfil:input:salvar"
          dataLabel="salvar"
          loading={loading}
          onClick={submit}
        >
          Salvar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ProfilesForm requestId={+requestId} />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
