import {
  OButton,
  OCheckbox,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OTooltip,
  OTypography,
  modalManager,
} from "@maestro/react";
import groupBy from "lodash/groupBy";
import { FormProvider } from "react-hook-form";
import { LimitRequest } from "../../../detalhes/limit-details.types";
import { collateralsToFormalize } from "./formalize-collateral-button.form";
import { useFormalizeCollateral } from "./formalize-collateral-button.hook";

interface FormalizeCollateralButtonProps {
  limitRequest: LimitRequest | undefined;
}

export const FormalizeCollateralButton = ({
  limitRequest,
}: FormalizeCollateralButtonProps) => {
  const { collaterals, formalizeCollateral, loading, form } =
    useFormalizeCollateral(limitRequest);

  const collateralsCanFormalize = collaterals?.filter((c) =>
    collateralsToFormalize.includes(c.collateralType),
  );

  const groupedCollaterals = groupBy(collateralsCanFormalize, "collateralType");

  const collateralToFormalize = Object.keys(groupedCollaterals).filter(
    (key) => !groupedCollaterals[key].some((c) => c.formalized),
  );

  const collateralFormalized = Object.keys(groupedCollaterals).filter((key) =>
    groupedCollaterals[key].some((c) => c.formalized),
  );

  return (
    <>
      <OModal
        id="generate-guarantee-contract"
        position="center"
        size="sm"
        backdrop={false}
      >
        <div>{loading && <OLoader backdrop absolute />}</div>
        <OModalHeader divider closeButton>
          <OTypography size="lg">Formalizar garantias</OTypography>
        </OModalHeader>
        <OModalBody>
          <FormProvider {...form}>
            <div className="d-flex flex-column gap-3">
              {!!collateralToFormalize.length && (
                <div className="d-flex flex-column gap-2">
                  <OTypography>
                    Selecione as garantias para formalizar:
                  </OTypography>
                  <ORFieldCheckboxGroup
                    id="collateralToFormalize"
                    name="collateralToFormalize"
                  >
                    {collateralToFormalize.map((c) => (
                      <div
                        className="d-flex align-items-center gap-3 mb-1"
                        key={c}
                      >
                        <OCheckbox id={c} size="sm" value={c} />
                        <OLabel htmlFor={c}>{c}</OLabel>
                      </div>
                    ))}
                  </ORFieldCheckboxGroup>
                </div>
              )}
              {!!collateralFormalized.length && (
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex  align-items-center gap-2">
                    <OTypography>
                      Selecione as garantias para aditar:
                    </OTypography>
                    <OTooltip>
                      <span slot="tooltip-content">
                        Consiste em garantias já formalizadas
                      </span>
                      <OIcon category="orq" icon="orq-info" type="info" />
                    </OTooltip>
                  </div>
                  <ORFieldCheckboxGroup
                    id="collateralFormalized"
                    name="collateralFormalized"
                  >
                    {collateralFormalized.map((c) => (
                      <ORow
                        className="d-flex align-items-center justify-content-between "
                        key={c}
                      >
                        <OCol>
                          <div className="d-flex align-items-center gap-3 mb-1">
                            <OCheckbox id={c} size="sm" value={c} />
                            <OLabel htmlFor={c}>{c}</OLabel>
                          </div>
                        </OCol>
                        <OCol>
                          <ORFieldInputDate
                            label="Data da assinatura"
                            disabledDate={(date) => date.isAfter()}
                            id={`signed-date-${c}`}
                            name={`signed-date-${c}`}
                          />
                        </OCol>
                        <OCol>
                          <ORFieldInput
                            id={`contract-${c}`}
                            name={`contract-${c}`}
                            tag="text"
                            label="Contrato existente"
                          />
                        </OCol>
                      </ORow>
                    ))}
                  </ORFieldCheckboxGroup>
                </div>
              )}
            </div>
          </FormProvider>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex gap-3">
            <OButton
              onClick={() => modalManager.hide("generate-guarantee-contract")}
              outline
            >
              Fechar
            </OButton>
            <OButton onClick={formalizeCollateral}>Confirmar</OButton>
          </div>
        </OModalFooter>
      </OModal>
      <OButton
        onClick={() => modalManager.show("generate-guarantee-contract")}
        disabled={!collateralsCanFormalize?.length}
        outline
      >
        Formalizar
      </OButton>
    </>
  );
};
