import { OToastManager } from "@maestro/react";
import {
  ExtractODataGridGeneratorConfigType,
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { guarantorsGrid } from "./guarantors.grid";
import { LimitGuarantors } from "./guarantors.types";

const buildPayload = ({ limitGuarantors }: LimitGuarantors) => {
  const guarantorsTaxIds = limitGuarantors.map(
    ({ guarantor }) => guarantor.legalEntity.taxId,
  );

  let requiredGuarantors: string[] = [];

  if (guarantorsTaxIds.length)
    requiredGuarantors = requiredGuarantors.concat(guarantorsTaxIds);

  requiredGuarantors = Array.from(new Set(requiredGuarantors));

  const type = limitGuarantors[0]?.guarantor?.type ?? "COMPLETO";

  return {
    requiredGuarantors,
    type,
  };
};

export const GuarantorsPage = () => {
  const [limitGuarantors, setLimitGuarantors] = useState<LimitGuarantors>();
  const [loading, setLoading] = useState(false);
  const [
    displayOnboardingGuarantorButton,
    setDisplayOnboardingGuarantorButton,
  ] = useState(false);

  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  if (!id) throw new Error("No id parameter");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        ExtractODataGridGeneratorConfigType<typeof guarantorsGrid>
      >(
        () =>
          service.hubCreditManager
            .getLimitGuarantors(id)
            .then(({ data }) => {
              setLimitGuarantors(data);

              const payload = buildPayload(data);

              if (!payload.requiredGuarantors.length) return [];

              return service.onboardingRelationships.b2c
                .loadGuarantors(payload, data.customerTaxId)
                .then(({ data: { guarantorsResponse } }) => {
                  setDisplayOnboardingGuarantorButton(
                    guarantorsResponse.some(
                      (guarantor) => guarantor.canSendToAnalysis,
                    ),
                  );
                  return guarantorsResponse.map((guarantor) => ({
                    ...guarantor,
                    limitGuarantorId: data.limitGuarantors.find(
                      (limit) =>
                        limit.guarantor.legalEntity.taxId === guarantor.taxId,
                    )?.id,
                    customerTaxId: data.customerTaxId,
                  }));
                });
            })
            .catch(() => {
              throw new Error("Error ao buscar avalistas");
            }),
        {
          customStoreOptions: {
            remove: async (data) => {
              try {
                if (!data.limitGuarantorId) return;
                await service.hubCreditManager.removeGuarantor({
                  id: data.limitGuarantorId,
                });

                OToastManager.success("Avalista removido com sucesso.");
              } catch {
                OToastManager.danger(
                  "Ocorreu um erro ao tentar remover o avalista.",
                );
              }
            },
          },
        },
      ),
    [id],
  );

  const sendGuarantors = useCallback(async () => {
    try {
      if (!limitGuarantors) return;
      setLoading(true);

      await service.onboardingRelationships.b2c.onboardingGuarantor(
        buildPayload(limitGuarantors),
        limitGuarantors.customerTaxId,
      );

      OToastManager.success("As alterações foram enviadas com sucesso.");
    } catch {
      OToastManager.danger(
        "Ocorreu um erro ao enviar as alterações dos dados do(s) avalista(s).",
      );
    } finally {
      setLoading(false);
    }
  }, [limitGuarantors]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Avalistas" />}
      actions={
        <>
          {displayOnboardingGuarantorButton && (
            <LoadingButton
              loading={loading}
              type="tertiary"
              dataAction="emprestimos_avalistas:botao:enviar_alteracoes"
              dataLabel="enviar_alteracoes"
              outline
              onClick={sendGuarantors}
            >
              Enviar alterações
            </LoadingButton>
          )}
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_avalistas:botao:adicionar_avalista"
            dataLabel="adicionar_avalista"
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.guarantors.add.path(
              { id },
            )}
            role={
              roles.emprestimos.product
                .limitManagerLimitsDetailsGuarantorsAddPage.role
            }
            outline
          >
            Adicionar avalista
          </RouterButton>
          <RefreshGridButton
            onClick={() => {
              !dataSource.isLoading() && dataSource.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator grid={guarantorsGrid} dataSource={dataSource} />
    </GridTemplate>
  );
};
