export const downloadJson = (obj: unknown) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:application/json;charset=utf-16," +
      encodeURIComponent(JSON.stringify(obj, null, 2)),
  );
  element.setAttribute(
    "download",
    `workflow-export-${new Date().toISOString()}.json`,
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
