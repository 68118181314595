import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useAddInternacionalUser } from "./add-internacional-user.hook";
import { BasicFields } from "./basic-fields";

export const AddInternacionalUser = () => {
  const { form, loading, submit } = useAddInternacionalUser();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Novo usuário internacional" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <BasicFields />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
