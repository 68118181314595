import * as yup from "yup";

export const userActionsFormValidationSchema = yup.object().shape({
  actionButtonName: yup.string().nullable(),
  actionIcon: yup.string().nullable(),
  bigNumberRelated: yup.number().nullable(),
  viewUserActions: yup.array().default([]),
  actionButtonType: yup.string().nullable(),
  hasActionWithoutContext: yup.array().default([]),
  isActionButtonOutlined: yup.array().default([]),
});

export const userActionsFormDefaultValues = {
  actionButtonName: "",
  actionIcon: "",
  bigNumberRelated: 0,
  viewUserActions: [] as string[],
  actionButtonType: "",
  hasActionWithoutContext: [] as string[],
  isActionButtonOutlined: [] as string[],
};
