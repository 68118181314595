import * as yup from "yup";

export interface ResetLegalEntityForm {
  onboadingRelationShip: string;
}

export const resetLegalEntityValidationSchema: yup.ObjectSchema<ResetLegalEntityForm> =
  yup.object({
    onboadingRelationShip: yup
      .string()
      .required("Escolha ao menos um relacionamento"),
  });
