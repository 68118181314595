import { ORow } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { BTGBusinessForm, BaseForm, CedenteForm, SacadoForm } from "./_compose";
import { useCompanyInfo } from "./company-info.hook";

export const CompanyInfo = () => {
  const {
    company,
    form,
    formConfig,
    getCompany,
    getCompanyHasError,
    getCompanyLoading,
    submit,
    updateCompanyLoading,
  } = useCompanyInfo();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Informações" />}
      actions={
        <LoadingButton
          dataAction="acoes:botao:atualizar_informações"
          dataLabel="atualizar_informações"
          loading={updateCompanyLoading}
          onClick={() => submit()}
        >
          Atualizar informações
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={getCompanyLoading}
          hasError={getCompanyHasError}
          value={company}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar a empresa"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => getCompany()} />
            </ErrorComponent>
          }
          render={() => (
            <FormProvider {...form}>
              <ORow gap={3}>
                <BaseForm identificationType={formConfig.base} />
                {formConfig.cedente && <CedenteForm />}
                {formConfig.sacado && <SacadoForm />}
                {formConfig.onboarding && <BTGBusinessForm />}
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
