import { OCardBody, OCol, ORow } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailWallet } from "pages/duplicatas/pages/cliente/informacoes/_compose/detail-wallet/detail-wallet.component";
import { OwnersTotals } from "services/moana/models";

interface OwnersTotalsCardProps {
  ownersTotals: OwnersTotals;
}

export const OwnerTotalsCard = ({ ownersTotals }: OwnersTotalsCardProps) => {
  return (
    <OCardBody className="position-relative">
      <ORow>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Carteira total"
            value={masks.currency(ownersTotals.totalReceivableValue, "R$")}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Vencidos total"
            value={masks.currency(ownersTotals.totalPendingPaymentValue, "R$")}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Carteira disponível"
            value={masks.currency(ownersTotals.totalEligibleValue, "R$")}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Carteira descontada"
            value={masks.currency(ownersTotals.totalAnticipatedValue, "R$")}
          />
        </OCol>
        <OCol xs={12} md={6} lg={2}>
          <DetailWallet
            title="Carteira vencida"
            value={masks.currency(ownersTotals.totalLateSettlement, "R$")}
          />
        </OCol>
      </ORow>
    </OCardBody>
  );
};
