import { Roles } from "roles/roles.types";

export const accRoles = {
  customer: {
    tab: {
      role: "admin:AccTabCustomer.View",
      description: "Visualização da tab 'ACC' na visão cliente",
    },
    tradesPage: {
      role: "admin:AccTradesPageCustomer.View",
      description: "Visualização da página 'Operações' na visão cliente",
    },
    tradesDetailsPage: {
      role: "admin:AccTradesDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão cliente",
    },
    contractsPage: {
      role: "admin:AccContractsPageCustomer.View",
      description: "Visualização da página 'Contratos' na visão cliente",
    },
    counterpartyDetailsPage: {
      role: "admin:AccCounterpartiesDetailsPageProduct.View",
      description:
        "Visualização da página 'Contraparte - Detalhes' na visão cliente",
    },
    proposalsPage: {
      role: "admin:AccProposalsPageCustomer.View",
      description: "Visualização da página 'Propostas' na visão cliente",
    },
    proposalsDetailsPage: {
      role: "admin:AccProposalsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Propostas - Detalhes' na visão cliente",
    },
    settlementsPage: {
      role: "admin:AccSettlementsPageCustomer.View",
      description: "Visualização da página 'Liquidações' na visão cliente",
    },
    settlementsDetailsPage: {
      role: "admin:AccSettlementsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Liquidações - Detalhes' na visão cliente",
    },
    orderDetailsPage: {
      role: "admin:AccOrderDetailsPageCustomer.View",
      description:
        "Visualização da página 'Liquidações - Detalhes - Ordem' na visão cliente",
    },
    simulationDetailsPage: {
      role: "admin:AccSimulationDetailsPageCustomer.View",
      description:
        "Visualização da página 'Liquidações - Detalhes - Simulação' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:AccTabProduct.View",
      description: "Visualização da tab 'ACC' na visão produto",
    },
    proposalsPage: {
      role: "admin:AccProposalsPageProduct.View",
      description: "Visualização da página 'Propostas' na visão produto",
    },
    tradesPage: {
      role: "admin:AccTradesPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    settlementsPage: {
      role: "admin:AccSettlementsPageProduct.View",
      description: "Visualização da página 'Liquidações' na visão produto",
    },
    settlementsFormPage: {
      role: "admin:AccSettlementsFormPageProduct.View",
      description:
        "Visualização da página 'Liquidações - Formulário' na visão produto",
    },
    counterpartiesPage: {
      role: "admin:AccCounterpartiesPageProduct.View",
      description: "Visualização da página 'Contrapartes' na visão produto",
    },
    counterpartiesAddPage: {
      role: "admin:AccCounterpartiesAddPageProduct.View",
      description:
        "Visualização da página 'Contrapartes - Adicionar' na visão produto",
    },

    settlementsContingencyPage: {
      role: "admin:AccSettlementsContingencyPageProduct.View",
      description:
        "Visualização da página 'Contingência - Liquidações' na visão produto",
    },
    settlementsContingencyFormPage: {
      role: "admin:AccSettlementsContingencyFormPageProduct.View",
      description:
        "Visualização da página 'Contingência - Liquidações - Formulário' na visão produto",
    },

    contingencyCommonDomainPage: {
      role: "admin:AccContingencyCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Contingência - Variáveis de Configuração' na visão produto",
    },
    contingencyNaturesAllPage: {
      role: "admin:AccContingencyNaturesAllPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas' na visão produto",
    },
    contingencyNaturesAllCreatePage: {
      role: "admin:AccContingencyNaturesAllCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas - Adicionar' na visão produto",
    },
    contingencyNaturesAllUpdatePage: {
      role: "admin:AccContingencyNaturesAllUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas - Atualizar' na visão produto",
    },
    contingencyNaturesDocumentGroupsPage: {
      role: "admin:AccContingencyNaturesDocumentGroupsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos' na visão produto",
    },
    contingencyNaturesDocumentGroupsCreatePage: {
      role: "admin:AccContingencyNaturesDocumentGroupsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos - Adicionar' na visão produto",
    },
    contingencyNaturesDocumentGroupsUpdatePage: {
      role: "admin:AccContingencyNaturesDocumentGroupsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos - Atualizar' na visão produto",
    },
    contingencyNaturesDocumentConfigsPage: {
      role: "admin:AccContingencyNaturesDocumentConfigsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos' na visão produto",
    },
    contingencyNaturesDocumentConfigsCreatePage: {
      role: "admin:AccContingencyNaturesDocumentConfigsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos - Adicionar' na visão produto",
    },
    contingencyNaturesDocumentConfigsUpdatePage: {
      role: "admin:AccContingencyNaturesDocumentConfigsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos - Atualizar' na visão produto",
    },
    contingencyNaturesGenericFieldsPage: {
      role: "admin:AccContingencyNaturesGenericFieldsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos' na visão produto",
    },
    contingencyNaturesGenericFieldsCreatePage: {
      role: "admin:AccContingencyNaturesGenericFieldsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos - Adicionar' na visão produto",
    },
    contingencyNaturesGenericFieldsUpdatePage: {
      role: "admin:AccContingencyNaturesGenericFieldsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos - Atualizar' na visão produto",
    },
  },
} satisfies Roles;
