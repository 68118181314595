import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";

export interface LimitInstallmentFormValues {
  minTerm: number;
  maxTerm: number;
  monthlyValue: number;
  gracePeriod: number;
}

export const limitInstallmentsFormGrid = (
  onChange: (contractTemplates: LimitInstallmentFormValues[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum parcelamento adicionado",
      dataSource: [],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false,
      },
      onRowInserted: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: item.monthlyValue / 100,
              })),
          ),
        );
      },
      onRowUpdated: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: item.monthlyValue / 100,
              })),
          ),
        );
      },
      onRowRemoved: async (row) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            row.component
              .getDataSource()
              .items()
              .map((item) => ({
                ...item,
                monthlyValue: item.monthlyValue / 100,
              })),
          ),
        );
      },
      onRowValidating: (e) => {
        const { minTerm, maxTerm, gracePeriod } = {
          ...e.oldData,
          ...e.newData,
        };
        const sameGracePeriod = e.component
          .getVisibleRows()
          .flatMap((row) => (row.isEditing ? [] : [row.data]))
          .filter((item) => item.gracePeriod === gracePeriod);
        if (
          !minTerm ||
          !maxTerm ||
          sameGracePeriod.some(
            (item) =>
              (minTerm >= item.minTerm && minTerm <= item.maxTerm) ||
              (item.minTerm >= minTerm && item.minTerm <= maxTerm),
          )
        ) {
          e.errorText =
            "Não é permitido intervalos de parcelas conflitantes com a mesma carência";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "minTerm",
        caption: "De (parcelas)",
        dataType: "number",
        validationRules: [
          { type: "required" },
          { type: "numeric" },
          {
            type: "custom",
            message: "O valor mínimo de parcelamento são 6",
            validationCallback: (option) => {
              return option.value >= 6;
            },
          },
        ],
      },
      {
        dataField: "maxTerm",
        caption: "Até (parcelas)",
        dataType: "number",
        validationRules: [{ type: "required" }, { type: "numeric" }],
      },
      {
        dataField: "monthlyValue",
        caption: "Taxa (a.m. %)",
        dataType: "number",
        validationRules: [{ type: "required" }, { type: "numeric" }],
      },
      {
        dataField: "gracePeriod",
        caption: "Carência (mês)",
        dataType: "number",
        validationRules: [{ type: "required" }, { type: "numeric" }],
      },
    ],
  } satisfies ODataGridGeneratorConfig<LimitInstallmentFormValues>);
