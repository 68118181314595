import {
  OCol,
  OLabel,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OSwitch,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { CollateralFormValues } from "../add-collateral-form.schema";

export const CACFormComponent = () => {
  const form = useFormContext<CollateralFormValues>();

  const { setValue, watch } = form;

  const formalizedWatcher = watch("formalized");

  return (
    <>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInputDate
            id="expireDate"
            name="expireDate"
            size="md"
            label="Data de vencimento"
            disabledDate={(date) => date.isBefore()}
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="amount"
            name="amount"
            label="Valor aprovado"
            tag="currency"
            symbol="R$"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={6} align="center">
          <div className="d-flex align-items-center gap-2">
            <OSwitch
              id="formalized"
              size="xs"
              className="mr-2"
              value={!!formalizedWatcher}
              onInput={() => setValue("formalized", !formalizedWatcher)}
            />
            <OLabel htmlFor="formalized" size="md">
              Já assinado
            </OLabel>
          </div>
        </OCol>
        {formalizedWatcher && (
          <OCol sm={12} md={6}>
            <ORFieldInput
              id="formalizedContractNumber"
              name="formalizedContractNumber"
              label="Código do CAC"
              tag="text"
            />
          </OCol>
        )}
      </ORow>
    </>
  );
};
