import {
  OConfirmationModal,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";

export const SyncCnpjConfirmationModal = () => {
  const form = useFormContext();
  const { setValue, watch } = form;
  const watchIdentification = watch("identification");

  const fillByCpfCnpj = useCallback(async () => {
    const body = {
      taxId: watchIdentification,
    };
    try {
      const { data } = await service.onboarding.searchLegalEntity(body);
      setValue("officialName", data.officialName);
      setValue("tradingName", data.name);
      modalManager.hide("sync-cnpj-confirmation-modal-id");
    } catch {
      OToastManager.danger("Falha ao obter dados da empresa");
    }
  }, [setValue, watchIdentification]);

  return (
    <OConfirmationModal
      modalId="sync-cnpj-confirmation-modal-id"
      title="Deseja sincronizar as informações dessa empresa?"
      onConfirm={fillByCpfCnpj}
    />
  );
};
