import { OButton, OIcon } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { energiaRoles } from "../../../../../../../../roles/energia.roles";
import { DistributorConfigForm } from "../../distributor-config.form";
import { distributorContactsGrid } from "./contacts-grid.grid";

export const DistributorContactsGrid = () => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const { setValue, watch } = useFormContext<DistributorConfigForm>();

  const contactsWatcher = watch("contacts");

  const canEditContract = hasRole(
    energiaRoles.product.diligenceContactsEdiActionst.role,
  );

  const grid = useMemo(
    () =>
      distributorContactsGrid((contacts) => {
        setValue("contacts", contacts);
      }, canEditContract),
    [canEditContract, setValue],
  );

  return (
    <GridTemplate
      actions={
        <OButton
          onClick={() => gridRef.current?.instance.addRow()}
          role={energiaRoles.product.diligenceContactsEdiActionst.role}
        >
          <OIcon category="orq" icon="orq-add-plus" />
        </OButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={contactsWatcher ?? []}
      />
    </GridTemplate>
  );
};
