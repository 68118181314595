import { OCard } from "@maestro/react";
import styled from "styled-components";

export const OCardStyled = styled(OCard)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--font-sm) var(--font-xxl);
`;

export const FileName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--font-md);
  font-size: var(--font-md);
`;
