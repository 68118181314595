import { OToastManager, modalManager } from "@maestro/react";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { GetSemaphoreStepItem } from "services/onboarding/models/responses/get-semaphore-step.response";
import { LocalFormValuesType } from "./template-config.types";

export const useSemaphoreTemplateForm = (uuid?: string | undefined) => {
  const templateUuid = uuid;
  const [loading, setLoading] = useState(false);
  const [allSteps, setAllSteps] = useState<SelectSearchOption<string>[]>([]);

  const form = useFormContext();
  const { watch, setValue, getValues } = form;

  const watchSteps = watch("steps") as GetSemaphoreStepItem[] | undefined;

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;
  const watchInput = localWatch("input");

  const removeStep = async (stepUuid: string) => {
    if (!templateUuid) return;

    if (!watchSteps?.find((s) => s?.uuid === stepUuid)) return;

    try {
      await service.onboarding.removeSemaphoreStepOnTemplate({
        stepUuid,
        templateUuid,
      });

      const filteredStep = watchSteps?.filter((s) => s?.uuid !== stepUuid);

      setValue("steps", filteredStep);
      OToastManager.success("Step removido do template");
    } catch {
      OToastManager.danger("Não foi possível remover o step do template");
    }
  };

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.getSemaphoreStepList();
      const addStep = async (stepUuid: string) => {
        if (!stepUuid) return;

        if (!templateUuid) return;

        await service.onboarding.addSemaphoreStepOnTemplate({
          stepUuid,
          templateUuid,
        });
      };

      const newData = data.map((d) => {
        return {
          value: d.uuid,
          label: `${d.description}`,
          customKey: d.uuid,
          onSelect: async (value: string) => {
            try {
              const steps = getValues("steps") ?? [];

              await addStep(value);
              setValue("steps", [...new Set([...steps, value])]);
              OToastManager.success("Step adicionado ao template");
            } catch {
              OToastManager.danger(
                "Não foi possível adicionar o step ao template",
              );
            }
          },
        };
      });

      setAllSteps(newData);
    } finally {
      setLoading(false);
    }
  }, [templateUuid, setValue, getValues]);

  const openModalUpdateStepOrder = (step: GetSemaphoreStepItem) => {
    if (!templateUuid) return;

    if (!watchSteps?.find((s) => s === step)) return;

    modalManager.show("step-order-modal");
  };
  const openModalUpdateStep = (step: GetSemaphoreStepItem) => {
    if (!templateUuid) return;

    if (!watchSteps?.find((s) => s === step)) return;

    modalManager.show("step-modal");
  };

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (watchSteps) localSetValue("input", undefined);
  }, [localSetValue, watchSteps]);

  return {
    localForm,
    loading,
    watchSteps,
    allSteps,
    watchInput,
    removeStep,
    openModalUpdateStepOrder,
    openModalUpdateStep,
  };
};
