import {
  ExchangeGenericFieldComponentType,
  ExchangeGenericFieldMask,
} from "services/hubfx/models/types/nature/generic-field-config.model";

export const exchangeGenericFieldMaskOptions: Record<
  ExchangeGenericFieldMask,
  string
> = {
  CNPJ: "CNPJ",
  CPF: "CPF",
  DATE: "DATE",
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  DUE_KEY: "DUE_KEY",
  DUE: "DUE",
};

export const exchangeGenericFieldComponentTypeOptions: Record<
  ExchangeGenericFieldComponentType,
  string
> = {
  FIELD: "FIELD",
  SELECT: "SELECT",
};

export const parseFxPendencyCodes = (fxPendencyCodes: string | null) => {
  try {
    return fxPendencyCodes ? (JSON.parse(fxPendencyCodes) as string[]) : [];
  } catch {
    return [];
  }
};
