import styled from "styled-components";

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(232, 232, 232);
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-left: 10px;
  margin-bottom: 15px;

  button {
    width: fit-content;
    min-width: 60px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  span {
    font-size: 14px;
    font-weight: lighter;
    color: red;
  }
`;

export const HtmlStringContent = styled.div`
  h5 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }
`;
