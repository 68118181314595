import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useCardsCustomerContext } from "pages/cartoes/contexts";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { NoAccountRegisteredTemplate } from "../../../components/no-account-registered-template";
import { remapFilter } from "./card-wallets-grid.filter-utils";
import { walletGrid } from "./card-wallets-grid.grid";
import { Wallet } from "services/bankinghub/models/types/cards/cards";

export const CardWalletsGrid = () => {
  const { account } = useCardsCustomerContext();

  const gridRef = useGridRef();

  const dataSource = dataSourceCustomStoreGenerator<Wallet>((loadOptions) =>
    service.bankinghub
      .getCardsWalletProvisions({
        companyDocument: account?.company.document || "",
        ...remapFilter(loadOptions),
      })
      .then(({ data }) => ({
        data: data.items,
        totalCount: data.numberOfRecords,
      }))
      .catch((error) => {
        console.error(error);
        const gridErrorMessage = "Falha ao buscar requisições de wallets";
        throw new Error(gridErrorMessage);
      }),
  );

  return (
    <>
      <NoAccountRegisteredTemplate
        pageTitleProps={{
          title: "Wallets",
        }}
        children={
          <GridTemplate
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={walletGrid}
              dataSource={dataSource}
            />
          </GridTemplate>
        }
      />
    </>
  );
};
