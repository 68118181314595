import {
  OButton,
  OFilter,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterModal,
  OTypography,
} from "@maestro/react";
import { OTextButton } from "../../../o-text-button";
import {
  Section,
  SideFilterBarForm,
  SideFilterBarModalId,
} from "../../side-filter-bar.types";
import {
  CustomLabel,
  DateRange,
  IntervalAmount,
  IntervalCount,
  MultFinancialInstitutionSelection,
  MultSelection,
  SectionLayout,
  SelectableSearch,
  SingleSelection,
} from "./_compose";
import {
  isACustomLabelSectionFilter,
  isADateRangeSectionFilter,
  isAIntervalAmountSectionFilter,
  isAIntervalCountSectionFilter,
  isAMultFinancialInstitutionSelectionSectionFilter,
  isAMultSelectionSectionFilter,
  isASelectableSearchSectionFilter,
  isASingleSelectionSectionFilter,
} from "./side-form-modal.utils";

interface SideFormModalProps {
  sections: Section[];
  formFieldIdsFiltered: string[];
  setFormFieldIdsFiltered: React.Dispatch<React.SetStateAction<string[]>>;
  onFormSubmit: (formToSubmit: SideFilterBarForm) => void;
}

export const SideFormModal = ({
  sections,
  formFieldIdsFiltered,
  setFormFieldIdsFiltered,
  onFormSubmit,
}: SideFormModalProps) => {
  return (
    <OFilterModal id={SideFilterBarModalId}>
      <OFilterHeader>
        <OTypography type="dark" size="lg">
          Filtros
        </OTypography>
      </OFilterHeader>
      <div className="d-flex flex-column gap-4 h-100 justify-content-between">
        <OFilterBody>
          <OFilter>
            {sections.map((section) => {
              return (
                <SectionLayout
                  key={section.formId}
                  title={section.title}
                  formId={section.formId}
                  formFieldIdsFiltered={formFieldIdsFiltered}
                  setFormFieldIdsFiltered={setFormFieldIdsFiltered}
                >
                  {isASingleSelectionSectionFilter(section) && (
                    <SingleSelection {...section} />
                  )}
                  {isAMultSelectionSectionFilter(section) && (
                    <MultSelection {...section} />
                  )}
                  {isADateRangeSectionFilter(section) && (
                    <DateRange {...section} />
                  )}
                  {isACustomLabelSectionFilter(section) && (
                    <CustomLabel {...section} />
                  )}
                  {isAIntervalAmountSectionFilter(section) && (
                    <IntervalAmount {...section} />
                  )}
                  {isASelectableSearchSectionFilter(section) && (
                    <SelectableSearch {...section} />
                  )}
                  {isAIntervalCountSectionFilter(section) && (
                    <IntervalCount {...section} />
                  )}
                  {isAMultFinancialInstitutionSelectionSectionFilter(
                    section,
                  ) && <MultFinancialInstitutionSelection {...section} />}
                </SectionLayout>
              );
            })}
          </OFilter>
        </OFilterBody>
        <OFilterFooter>
          <div className="d-flex justify-content-end gap-2">
            <OTextButton
              type="dark"
              outline
              bordered={false}
              onClick={() => onFormSubmit({})}
            >
              Limpar
            </OTextButton>
            <OButton typeButton="submit">Filtrar</OButton>
          </div>
        </OFilterFooter>
      </div>
    </OFilterModal>
  );
};
