import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { NotificationTypeOdataResponse } from "services/raven/models/responses/notification-types-odata.response";
import { service } from "services";
import { ICategory, IIcon, OToastManager } from "@maestro/core";
import { OIcon } from "@maestro/react";
import { NotificationConfigurationMasterDetail } from "./raven-notification-configuration/configuration.master-detail";

export const buildGrid = (
  deleteRow: (rowIndex: number) => void,
): ODataGridGeneratorConfig<NotificationTypeOdataResponse> => ({
  datagrid: {
    noDataText: "Nenhum tipo de notificação encontrada",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    editing: {
      mode: "row",
      selectTextOnEditStart: true,
    },
    onRowRemoved: async ({ data: { id } }) => {
      try {
        await service.raven.deleteNotificationType(id);
        OToastManager.success("Notificação removida com sucesso");
      } catch {
        OToastManager.danger("Erro ao remover a notificação");
      }
    },
  },
  columns: [
    {
      dataField: "icon",
      caption: "Ícone",
      cellRender: (icone) => (
        <OIcon
          category={icone.value.split("/")[0] as ICategory}
          icon={icone.value.split("/")[1] as IIcon}
          size="xl"
          type="tertiary"
        />
      ),
    },
    {
      dataField: "renderedName",
      caption: "Nome renderizado",
    },
    { dataField: "name", caption: "Nome" },
    {
      dataField: "description",
      caption: "Descrição",
      width: "250px",
    },
    {
      dataField: "legalRepresentative?",
      caption: "Representantes Legais",
      width: "200px",
      alignment: "center",
    },
    {
      dataField: "roles",
      caption: "Roles",
      width: "250px",
    },
    {
      dataField: "tenant",
      caption: "Responsável",
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Detalhes",
              role: roles.ferramentas.product.ravenNotificationTypesDetailsPage
                .role,
              route:
                corporateRouter.routes.ferramentas.product.raven.notificacoes.gestao.details.path(
                  { uuid: data.id },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-add-plus" },
              label: "Nova configuração",
              role: roles.ferramentas.product.ravenAddNotificationConfigPage
                .role,
              route:
                corporateRouter.routes.ferramentas.product.raven.notificacoes.gestao.addConfig.path(
                  { uuid: data.id },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir",
              role: roles.ferramentas.product.ravenRemoveNotificationTypes.role,
              onClick: () => deleteRow(rowIndex),
            },
          ]}
        />
      ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: NotificationConfigurationMasterDetail,
  },
});
