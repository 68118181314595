import { modalManager, ODivider } from "@maestro/react";
import { MenuItem } from "components/menu/_compose";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { SelectConditionsComponent } from "../../../../../../../../../../components/conditions/select-conditions-component/select-conditions.component";
import { ComponentBasicData } from "./component-menu/basic-data.component";
import { ComponentProperty } from "./component-menu/property/component-property.component";

enum MenuItems {
  Default = "Dados básicos",
  Propriedades = "Propriedades",
  Condições = "Condições",
}

interface ComponentPropertyMenuProps {
  form: UseFormReturn<FieldValues>;
}

export const ComponentMenu = ({ form }: ComponentPropertyMenuProps) => {
  const [activeItem, setActiveItem] = useState(MenuItems.Default);

  useEffect(() => {
    const cleanup = modalManager.on("edit-component-modal", "modalOpen", () =>
      setActiveItem(MenuItems.Default),
    );

    return cleanup;
  }, []);

  return (
    <div className="d-flex gap-4">
      <div className="d-flex flex-column gap-2">
        <MenuItem
          onClick={() => setActiveItem(MenuItems.Default)}
          label={MenuItems.Default}
          link=""
          active={activeItem === MenuItems.Default}
        />
        <MenuItem
          onClick={() => setActiveItem(MenuItems.Propriedades)}
          label={MenuItems.Propriedades}
          link=""
          active={activeItem === MenuItems.Propriedades}
        />
        <MenuItem
          onClick={() => setActiveItem(MenuItems.Condições)}
          label={MenuItems.Condições}
          link=""
          active={activeItem === MenuItems.Condições}
        />
      </div>
      <ODivider size="xxs" position="vertical" />
      <FormProvider {...form}>
        <form className="w-100">
          {activeItem === MenuItems.Default && <ComponentBasicData />}
          {activeItem === MenuItems.Propriedades && <ComponentProperty />}
          {activeItem === MenuItems.Condições && <SelectConditionsComponent />}
        </form>
      </FormProvider>
    </div>
  );
};
