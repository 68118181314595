import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";

export const strategiesDataSource = dataSourceCustomStoreGenerator(
  async () => {
    return service.hubCreditManager.getStrategies().catch(() => {
      throw new Error("Um erro ocorreu ao tentar buscar as estratégias.");
    });
  },
  {
    customStoreOptions: {
      update: async (originalValues, updatedValues) => {
        try {
          await service.hubCreditManager.updateStrategy({
            strategyId: originalValues.id,
            strategyCr: updatedValues.strategyCr || originalValues.strategyCr,
            strategyName:
              updatedValues.strategyName || originalValues.strategyName,
          });
          OToastManager.success("A estratégia foi atualizada com sucesso.");
        } catch {
          OToastManager.danger(
            "Um erro ocorreu ao tentar atualizar a estratégia. Por favor, tente novamente mais tarde.",
          );
        }
      },
      remove: async (data) => {
        try {
          await service.hubCreditManager.deleteStrategy({
            strategyId: data.id,
          });
          OToastManager.success("A estratégia foi removida com sucesso.");
        } catch (e) {
          OToastManager.danger(
            "Um erro ocorreu ao tentar remover a estratégia. Por favor, tente novamente mais tarde.",
          );
        }
      },
    },
  },
);

export const strategiesGrid = {
  datagrid: {
    noDataText: "Nenhuma estratégia foi encontrada.",
    headerFilter: { visible: true, allowSearch: true },
    editing: {
      allowUpdating: true,
      allowDeleting: true,
      confirmDelete: true,
    },
    paging: {
      pageSize: 10,
    },
    onRowUpdated: () => strategiesDataSource.reload(),
    onRowRemoved: () => strategiesDataSource.reload(),
  },
  columns: [
    {
      sortOrder: "asc",
      dataField: "id",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "strategyCr",
      caption: "CR",
      alignment: "left",
    },
    {
      dataField: "strategyName",
      caption: "Nome",
      alignment: "left",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubCreditManager.getStrategies>>["data"]
  >[number]
>;
