import { PatchByIdAdminOrderBody } from "services/hubfx/models/requests/patch-by-id-admin-orders.request";
import * as yup from "yup";

export const orderFormValidationSchema: yup.ObjectSchema<
  Omit<PatchByIdAdminOrderBody, "id">
> = yup.object({
  accAllocatedAmount: yup.number().required("Este campo é obrigatório"),
  accSettledAmount: yup.number().required("Este campo é obrigatório"),
  inTransitAmount: yup.number().required("Este campo é obrigatório"),
  isActive: yup.boolean().required("Este campo é obrigatório"),
  country: yup.string().required("Este campo é obrigatório"),
  payer: yup.string().required("Este campo é obrigatório"),
});
