import { PatchByIdAdminSettlementBody } from "services/acc/models/requests/patch-by-id-admin-preticket.request";
import * as yup from "yup";

export const settlementsFormValidationSchema: yup.ObjectSchema<
  Omit<
    PatchByIdAdminSettlementBody,
    "preTicketId" | "counterpartyIdentification"
  >
> = yup.object({
  preTicketFxAvailableAmount: yup.number().required("Este campo é obrigatório"),
  preTicketStatus: yup.string().required("Este campo é obrigatório"),
  preTicketCountry: yup.string().required("Este campo é obrigatório"),
  preTicketPayerType: yup.string().required("Este campo é obrigatório"),
  preTicketClientLink: yup.string().required("Este campo é obrigatório"),
});
