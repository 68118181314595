import * as yup from "yup";
import {
  CreateAccountOptinFields,
  CreateAccountOptinForm,
} from "./create-accounts-optin.type";

export const newCreateAccountOptin = {
  accountTemplateCode: null,
  accountQuantity: 1,
  officerName: null,
  desk: null,
} satisfies CreateAccountOptinFields;

export const createAccountOptinFormDefaultValues = {
  createAccountOptin: [newCreateAccountOptin],
};

export const createAccountOptinFormValidationSchema: yup.ObjectSchema<CreateAccountOptinForm> =
  yup.object({
    createAccountOptin: yup
      .array(
        yup.object({
          accountTemplateCode: yup
            .string()
            .required("Este campo é obrigatório"),
          accountQuantity: yup
            .number()
            .positive("Minimo de contas é 1")
            .required("Este campo é obrigatório"),
          officerName: yup.string().required("Este campo é obrigatório"),
        }),
      )
      .required("É necessário adicionar ao menos uma conta")
      .min(1, "É necessário adicionar ao menos uma conta"),
  });
