import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { adminACCProposalsODataGrid } from "./admin-product-acc-proposals-odata.grid";

export const AdminProductACCProposalsPage = () => {
  const gridRef = useGridRef();

  const quotesGrid = useMemo(() => adminACCProposalsODataGrid(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Propostas" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !quotesGrid.datagrid.dataSource.isLoading() &&
              quotesGrid.datagrid.dataSource.reload();
          }}
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={quotesGrid} />
    </GridTemplate>
  );
};
