import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { workflowsConfigGrid } from "./workflows-config.grid";

export const WorkflowsConfigPage = () => {
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configurações de Workflows" />}
      actions={
        <>
          <RouterButton
            dataAction="workflows_configurações:botao:criar"
            dataLabel="criar"
            role={roles.workflow.product.workflowsConfigAddPage.role}
            href={
              corporateRouter.routes.workflow.product.workflowsConfig.add.path
            }
          >
            Criar
          </RouterButton>
          <RefreshGridButton
            onClick={() => {
              !workflowsConfigGrid.datagrid.dataSource?.isLoading() &&
                workflowsConfigGrid.datagrid.dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator grid={workflowsConfigGrid} />
    </GridTemplate>
  );
};
