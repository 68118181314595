import { ONotification, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";

const hasPendingReviewMap = {
  Finalized: ["NULL", "CONCLUIDA", "CANCELADO"],
  Pending: [
    "PENDENTE_AJUSTE_APONTADO_POR_ONBOARDING",
    "PENDENTE_ASSINATURA",
    "REPROVADO_POR_ONBOARDING",
    "ERRO_WORKFLOW_REVISAO_CADASTRAL",
    "ENVIADO_PARA_ANALISE_DE_ONBOARDING",
    "REPROVADO_POR_AML",
    "EM_REVISAO_CADASTRAL",
    "EM_ANALISE_DE_ONBOARDING",
    "FICHA_ENVIADA_PARA_ONBOARDING",
    "PENDENTE_ANALISE_DE_FRAUDE",
  ],
};

export const PendingReviewNotification = () => {
  const { customer } = useCustomer();

  const hasPendingReview = customer.dataReviews.find(
    (review) =>
      !hasPendingReviewMap.Finalized.includes(review.status) &&
      review.isRegulatory,
  );

  return hasPendingReview ? (
    <ONotification type="warning" width="100%">
      <div className="d-flex flex-column gap-2">
        <OTypography type="dark">Revisão cadastral em andamento</OTypography>
        <OTypography type="dark">
          Atenção aos prazos de revisão cadastral, pois as contas poderão ser
          bloqueadas
        </OTypography>
      </div>
    </ONotification>
  ) : null;
};
