import { OToastManager } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { copyToClipboard } from "utils/copy";
import { AccountUtils } from "utils/financial-institution/account";
import { CNABAgreementMasterDetail } from "./cnab-agreement.master-detail";
import { formatID } from "./cnab-agreement.utils";

export const grid: ODataGridGeneratorConfig<BankingHubAdminConfigHub.Agreement> =
  {
    datagrid: { noDataText: "Nenhum convênio cadastrado" },
    columns: [
      {
        dataField: "id",
        caption: "Convênio",
        width: "20%",
        allowSorting: false,
        alignment: "left",
        cellRender: ({ data: { id } }) => formatID(id),
      },
      {
        dataField: "service",
        caption: "Serviço",
        width: "25%",
        alignment: "left",
      },
      {
        dataField: "agency",
        caption: "Agência",
        width: "15%",
        alignment: "left",
        cellRender: ({ data: { agency } }) =>
          agency ? AccountUtils.formatAccountAgency(agency) : "-",
      },
      {
        dataField: "accountNumber",
        caption: "Nº da Conta",
        width: "20%",
        alignment: "left",
        cellRender: ({ data: { accountNumber } }) =>
          accountNumber ? AccountUtils.formatAccountNumber(accountNumber) : "-",
      },
      {
        dataField: "updatedAt",
        caption: "Atualizado em",
        width: "20%",
        allowSorting: true,
        sortOrder: "desc",
        alignment: "left",
        cellRender: ({ data: { updatedAt } }) =>
          dayjs(updatedAt).format("DD/MM/YYYY, HH:mm"),
      },
      {
        caption: "Ações",
        alignment: "center",
        width: "85px",
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-copy" },
                  label: "Copiar ID do convênio",
                  onClick: () => {
                    const id = formatID(data.id);
                    copyToClipboard(id);
                    OToastManager.success("Id do convênio copiado com sucesso");
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    masterDetail: {
      enabled: true,
      component: CNABAgreementMasterDetail,
    },
  };
