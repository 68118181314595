import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { Guarantors } from "../pages/cliente/avalistas";
import { GuarantorContingencies } from "../pages/cliente/avalistas/contingencias";
import { GuarantorDetails } from "../pages/cliente/avalistas/detalhes/guarantor-details.page";
import { GuarantorEmbedded } from "../pages/cliente/avalistas/embedado";
import { GuarantorMonitoring } from "../pages/cliente/avalistas/monitoramento";
import { OnboardingEmbedded } from "../pages/cliente/cadastro-embedado";
import { Onboardings } from "../pages/cliente/cadastros";
import { OnboardingLogs } from "../pages/cliente/cadastros/[uuid]/logs/onboarding-logs.page";
import { OnboardingWorkflowEdit } from "../pages/cliente/cadastros/[uuid]/onboarding-workflow-edit.page";
import { OnboardingOpenAccounts } from "../pages/cliente/cadastros/contas-solicitadas/open-accounts.page";
import { OnboardingDetails } from "../pages/cliente/cadastros/detalhes/onboarding-details.page";
import { OnboardingEdit } from "../pages/cliente/cadastros/editar";
import { OnboardingDetailsLogs } from "../pages/cliente/cadastros/onboarding-details";
import { AccountsAdmin } from "../pages/cliente/contas-btg/contas";
import { AccountAdminStatement } from "../pages/cliente/contas-btg/contas/extrato";
import { AccountAdminPermissionHistory } from "../pages/cliente/contas-btg/contas/historico-permissoes";
import { AccountAdminUsersBlocked } from "../pages/cliente/contas-btg/contas/usuarios-restritos/users-blocked.page";
import { NewAccountsOptinCreateAccountsPage } from "../pages/cliente/contas-btg/novas-contas-optin/create-accounts-form/create-accounts-optin.page";
import { MasterDetailsAccount } from "../pages/cliente/contas-btg/novas-contas-optin/detailed-workflow/master-detailed-workflow.page";
import { NewAccountsOptinPage } from "../pages/cliente/contas-btg/novas-contas-optin/new-accounts-optin.page";
import { CompanyDetails } from "../pages/cliente/detalhes";
import { EnableCurrentAccount } from "../pages/cliente/detalhes/abertura-conta-corrente";
import { EloaApprovers } from "../pages/cliente/detalhes/aprovadores-procuracao-eletronica";
import { ExpiringPowers } from "../pages/cliente/detalhes/expiracao-poderes";
import { DataUpdateHistory } from "../pages/cliente/detalhes/historico-atualizacao-cadastral";
import { DataUpdateEdit } from "../pages/cliente/detalhes/historico-atualizacao-cadastral/editar";
import { DataUpdateLogs } from "../pages/cliente/detalhes/historico-atualizacao-cadastral/logs/atualizacao-cadastral-logs.page";
import { OnboardingReviewHistory } from "../pages/cliente/detalhes/historico-revisao";
import { OnboardingReviewEdit } from "../pages/cliente/detalhes/historico-revisao/editar";
import { OnboardingReviewLogs } from "../pages/cliente/detalhes/historico-revisao/logs/onboarding-review-logs.page";
import { AccountClose } from "../pages/cliente/encerramento-conta";
import { DetailedWorkflowClose } from "../pages/cliente/encerramento-conta/detalhes/account-close-details.page";
import { ClosingReason } from "../pages/cliente/encerramento-conta/motivo";
import { ClosingPendencies } from "../pages/cliente/encerramento-conta/pendencias";
import { Strategies } from "../pages/cliente/estrategias";
import { Tools } from "../pages/cliente/ferramentas";
import { ToolsApproveContingency } from "../pages/cliente/ferramentas/aprovacao-cadastro";
import { CustomerDataUpdateWorkflows } from "../pages/cliente/ferramentas/atualizacao-cadastral-workflows/customer-data-update-workflows.page";
import { ToolsDocumentDevolutive } from "../pages/cliente/ferramentas/documentos-cliente";
import { ToolsGenerateTerm } from "../pages/cliente/ferramentas/gerar-termos";
import { DeskMigrationOptions } from "../pages/cliente/ferramentas/migracao-segmento/[workflowId]";
import { CustomerDataReviewWorkflows } from "../pages/cliente/ferramentas/revisao-cadastral-workflows/customer-data-review-workflows.page";
import { CustomerOnboardingWorkflows } from "../pages/cliente/ferramentas/workflows/customer-onboarding-workflows.page";
import { CompanyActs } from "../pages/cliente/firmas-e-poderes";
import { AttachUser } from "../pages/cliente/firmas-e-poderes/vincular";
import { MonitorAML } from "../pages/cliente/monitor-aml";
import { CustomerWorkflowTracker } from "../pages/cliente/notificacoes/convites";
import { CustomerInviteDetails } from "../pages/cliente/notificacoes/convites/[workflowIdentifier]";
import { EmailNotifications } from "../pages/cliente/notificacoes/emails";
import { ElectronicLettersOfAttorneyPage } from "../pages/cliente/procuracoes/eletronicas";
import { EloaContingencies } from "../pages/cliente/procuracoes/eletronicas/contingencias";
import { EloaMonitoring } from "../pages/cliente/procuracoes/eletronicas/monitoramento";
import { LettersOfAttorneyPage } from "../pages/cliente/procuracoes/fisicas";
import { LoaContingencies } from "../pages/cliente/procuracoes/fisicas/detalhes/contingencias";
import { WorkflowDetails } from "../pages/cliente/procuracoes/fisicas/detalhes/letter-of-attorney-details.page";
import { AddRepresentativePage } from "../pages/cliente/procuracoes/fisicas/detalhes/representatives-card/add-representative";
import { UpdateRepresentativePage } from "../pages/cliente/procuracoes/fisicas/detalhes/representatives-card/update-representative";
import { LoaMonitoring } from "../pages/cliente/procuracoes/fisicas/monitoramento";
import { FraudVerificationPage } from "../pages/cliente/procuracoes/fisicas/validacoes-seguranca/fraud-verification.page";
import { CompanyProducts } from "../pages/cliente/produtos";
import { Relationship } from "../pages/cliente/relacionamento";
import { CompanyGroupAssociationsPage } from "../pages/cliente/solicitacao-de-relacionamento/company-group-associations.page";
import { Terms } from "../pages/cliente/termos";
import { cadastroRoles } from "../roles/cadastro.roles";
import { cadastroRouteParams } from "./cadastro.route-params";

export const cadastroRoutes = {
  customer: {
    name: "",
    path: "/cadastro/cliente",
    element: <RedirectWithRoles />,
    children: {
      details: {
        name: "Detalhes",
        path: "/cadastro/cliente/detalhes",
        role: cadastroRoles.customer.tab.role,
        element: <CompanyDetails />,
        children: {
          eloaApprovers: {
            name: "Procuração Eletrônica",
            path: "/cadastro/cliente/detalhes/aprovadores-procuracao-eletronica",
            role: cadastroRoles.customer.detailsEloaApproversPage.role,
            element: <EloaApprovers />,
          },
          onboardingReviews: {
            name: "Revisão Cadastral",
            path: "/cadastro/cliente/detalhes/historico-revisao",
            role: cadastroRoles.customer.detailsOnboardingReviewsPage.role,
            element: <OnboardingReviewHistory />,
            children: {
              details: {
                name: "Revisão Cadastral",
                path: `/cadastro/cliente/detalhes/historico-revisao/:${cadastroRouteParams.uuid}`,
                children: {
                  edit: {
                    name: "Editar",
                    path: `/cadastro/cliente/detalhes/historico-revisao/:${cadastroRouteParams.uuid}/editar`,
                    role: cadastroRoles.customer.onboardingReviewsEditPage.role,
                    element: <OnboardingReviewEdit />,
                  },
                  logs: {
                    name: "Logs",
                    path: `/cadastro/cliente/detalhes/historico-revisao/:${cadastroRouteParams.uuid}/logs`,
                    role: cadastroRoles.customer.onboardingReviewsEditPage.role,
                    element: <OnboardingReviewLogs />,
                  },
                },
              },
            },
          },
          dataUpdates: {
            name: "Atualização Cadastral",
            path: "/cadastro/cliente/detalhes/historico-atualizacao-cadastral",
            role: cadastroRoles.customer.detailsDataUpdatePage.role,
            element: <DataUpdateHistory />,
            children: {
              details: {
                name: "Atualização Cadastral",
                path: `/cadastro/cliente/detalhes/historico-atualizacao-cadastral/:${cadastroRouteParams.uuid}`,
                children: {
                  edit: {
                    name: "Editar",
                    path: `/cadastro/cliente/detalhes/historico-atualizacao-cadastral/:${cadastroRouteParams.uuid}/editar`,
                    role: cadastroRoles.customer.detailsDataUpdatePage.role,
                    element: <DataUpdateEdit />,
                  },
                  logs: {
                    name: "Logs",
                    path: `/cadastro/cliente/detalhes/historico-atualizacao-cadastral/:${cadastroRouteParams.uuid}/logs`,
                    role: cadastroRoles.customer.detailsDataUpdatePage.role,
                    element: <DataUpdateLogs />,
                  },
                },
              },
            },
          },
          enableCurrentAccount: {
            name: "Abertura de Conta Corrente",
            path: "/cadastro/cliente/detalhes/abertura-conta-corrente",
            role: cadastroRoles.customer.detailsEnableCurrentAccountPage.role,
            element: <EnableCurrentAccount />,
          },
          expiringPowers: {
            name: "Expiração de poderes",
            path: "/cadastro/cliente/detalhes/expiracao-poderes",
            role: cadastroRoles.customer.detailsExpiringPowersPage.role,
            element: <ExpiringPowers />,
          },
        },
      },
      onboardings: {
        name: "Cadastros",
        path: "/cadastro/cliente/cadastros",
        role: cadastroRoles.customer.onboardingsPage.role,
        element: <Onboardings />,
        children: {
          onboarding: {
            name: "Cadastros",
            path: `/cadastro/cliente/cadastros/:${cadastroRouteParams.id}`,
            role: cadastroRoles.customer.onboardingsOnboardingEditPage.role,
            children: {
              legacy: {
                name: "Editar",
                path: `/cadastro/cliente/cadastros/:${cadastroRouteParams.id}/legado`,
                element: <OnboardingEdit />,
                role: cadastroRoles.customer.onboardingsOnboardingEditPage.role,
              },
              edit: {
                name: "Editar",
                path: `/cadastro/cliente/cadastros/:${cadastroRouteParams.id}/editar`,
                element: <OnboardingWorkflowEdit />,
                role: cadastroRoles.customer.onboardingsOnboardingEditPage.role,
              },
              logs: {
                name: "Logs",
                path: `/cadastro/cliente/cadastros/:${cadastroRouteParams.id}/logs`,
                element: <OnboardingLogs />,
                role: cadastroRoles.customer.onboardingsOnboardingLogsPage.role,
              },
            },
          },
          onboardingDetails: {
            name: "Detalhes",
            path: `/cadastro/cliente/cadastros/detalhes/:${cadastroRouteParams.externalReference}`,
            role: cadastroRoles.customer.onboardingsOnboardingDetailsGridColumn
              .role,
            element: <OnboardingDetailsLogs />,
          },
          openAccounts: {
            name: "Contas solicitadas",
            path: `/cadastro/cliente/cadastros/contas-solicitadas/:${cadastroRouteParams.externalReference}`,
            role: cadastroRoles.customer.onboardingsOnboardingOpenAccountsPage
              .role,
            element: <OnboardingOpenAccounts />,
          },
          details: {
            name: "Detalhes",
            path: "/cadastro/cliente/cadastros/detalhes",
            role: cadastroRoles.customer.onboardingsPage.role,
            element: <OnboardingDetails />,
          },
        },
      },
      onboardingEmbedded: {
        name: "Cadastro Embedado",
        path: "/cadastro/cliente/embedado",
        role: cadastroRoles.customer.onboardingEmbeddedPage.role,
        element: <OnboardingEmbedded />,
      },
      accountClose: {
        name: "Encerramento de conta",
        path: "/cadastro/cliente/encerramento-conta",
        role: cadastroRoles.customer.accountClosePage.role,
        element: <AccountClose />,
        children: {
          pendencies: {
            name: "Pendências",
            path: "/cadastro/cliente/encerramento-conta/pendencias",
            role: cadastroRoles.customer.accountClosePendenciesPage.role,
            element: <ClosingPendencies />,
          },
          reasons: {
            name: "Motivo do encerramento",
            path: "/cadastro/cliente/encerramento-conta/motivo",
            role: cadastroRoles.customer.accountCloseReasonPage.role,
            element: <ClosingReason />,
          },
          details: {
            name: "Detalhes",
            path: `/cadastro/cliente/encerramento-conta/detalhes/:${cadastroRouteParams.workflowUuid}`,
            role: cadastroRoles.customer.accountCloseDetailedFileGeneratePage
              .role,
            element: <DetailedWorkflowClose />,
          },
        },
      },
      btgAccounts: {
        name: "Contas BTG",
        path: "/cadastro/cliente/contas-btg",
        hasSubItems: true,
        children: {
          accountAdmin: {
            name: "Contas",
            path: "/cadastro/cliente/contas-btg/contas",
            role: cadastroRoles.customer.btgAccountsAccountAdminPage.role,
            element: <AccountsAdmin />,
            children: {
              permissionHistory: {
                name: "Histórico de Permissões",
                path: `/cadastro/cliente/contas-btg/contas/historico-permissoes/:${cadastroRouteParams.account}/:${cadastroRouteParams.agency}`,
                role: cadastroRoles.customer
                  .btgAccountsAccountAdminPermissionHistoryPage.role,
                element: <AccountAdminPermissionHistory />,
              },
              statement: {
                name: "Extrato",
                path: `/cadastro/cliente/contas-btg/contas/extrato/:${cadastroRouteParams.account}/:${cadastroRouteParams.agency}`,
                role: cadastroRoles.customer
                  .btgAccountsAccountAdminStatementPage.role,
                element: <AccountAdminStatement />,
              },
              usersBlocked: {
                name: "Usuários restritos",
                path: `/cadastro/cliente/contas-btg/contas/usuarios-restritos/:${cadastroRouteParams.id}`,
                role: cadastroRoles.customer
                  .btgAccountsAccountAdminUsersBlockedPage.role,
                element: <AccountAdminUsersBlocked />,
              },
            },
          },
          optin: {
            name: "Novas Contas Opt-in",
            path: "/cadastro/cliente/contas-btg/novas-contas-optin",
            element: <NewAccountsOptinPage />,
            role: cadastroRoles.customer.btgAccountsOptinPage.role,
            children: {
              createAccounts: {
                name: "Criar Contas Opt-in",
                path: `/cadastro/cliente/contas-btg/novas-contas-optin/criar-contas`,
                role: cadastroRoles.customer.btgAccountsOptinCreateAccountsPage
                  .role,
                element: <NewAccountsOptinCreateAccountsPage />,
              },
              masterDetails: {
                name: "Detalhes da conta",
                path: `/cadastro/cliente/contas-btg/novas-contas-optin/master-datails/:${cadastroRouteParams.workflowUuid}`,
                role: cadastroRoles.customer.btgAccountsOptinCreateAccountsPage
                  .role,
                element: <MasterDetailsAccount />,
              },
            },
          },
        },
      },
      guarantors: {
        name: "Avalistas",
        path: "/cadastro/cliente/avalistas",
        role: cadastroRoles.customer.guarantorsPage.role,
        element: <Guarantors />,
        children: {
          monitoring: {
            name: "Monitoramento",
            path: "/cadastro/cliente/avalistas/monitoramento",
            role: cadastroRoles.customer.guarantorsMonitoringPage.role,
            element: <GuarantorMonitoring />,
          },
          contingencies: {
            name: "Contingências Avalista",
            path: `/cadastro/cliente/avalistas/contingencias/:${cadastroRouteParams.guarantorUuid}`,
            role: cadastroRoles.customer.guarantorsContingenciesPage.role,
            element: <GuarantorContingencies />,
          },
          guarantorsDetails: {
            name: "Detalhes",
            path: `/cadastro/cliente/avalistas/detalhes/:${cadastroRouteParams.guarantorUuid}`,
            role: cadastroRoles.customer.guarantorsGuarantorsDetailsPage.role,
            element: <GuarantorDetails />,
          },
          guarantorsEmbedded: {
            name: "Embedado",
            path: `/cadastro/cliente/avalistas/embedado/:${cadastroRouteParams.guarantorUuid}`,
            role: cadastroRoles.customer.guarantorsGuarantorsEmbeddedPage.role,
            element: <GuarantorEmbedded />,
          },
        },
      },
      products: {
        name: "Produtos",
        path: "/cadastro/cliente/produtos",
        role: cadastroRoles.customer.productsPage.role,
        element: <CompanyProducts />,
      },
      customerServiceContracts: {
        name: "Termos",
        path: "/cadastro/cliente/termos",
        role: cadastroRoles.customer.termsPage.role,
        element: <Terms />,
      },
      relationship: {
        name: "Relacionamento",
        path: "/cadastro/cliente/relacionamento",
        role: cadastroRoles.customer.relationshipPage.role,
        element: <Relationship />,
      },
      strategies: {
        name: "Estratégias",
        path: "/cadastro/cliente/estrategias",
        role: cadastroRoles.customer.strategiesPage.role,
        element: <Strategies />,
      },
      monitorAml: {
        name: "Reprovações de AML",
        path: "/cadastro/cliente/monitor",
        role: cadastroRoles.customer.monitorAmlPage.role,
        element: <MonitorAML />,
      },
      companyActs: {
        name: "Firmas e poderes",
        path: "/cadastro/cliente/firmas-e-poderes",
        role: cadastroRoles.customer.companyActsPage.role,
        element: <CompanyActs />,
        children: {
          attachUser: {
            name: "Vincular usuário",
            path: "/cadastro/cliente/firmas-e-poderes/vincular",
            role: cadastroRoles.customer.companyActsAttachUserPage.role,
            element: <AttachUser />,
          },
        },
      },
      companyGroupAssociations: {
        name: "Solicitação de Relacionamento",
        path: "/cadastro/cliente/solicitacao-de-relacionamento",
        role: cadastroRoles.customer.companyGroupAssociationsPage.role,
        element: <CompanyGroupAssociationsPage />,
      },
      lettersOfAttorney: {
        name: "Procurações",
        path: "/cadastro/cliente/procuracoes",
        hasSubItems: true,
        children: {
          vintage: {
            name: "Procurações físicas",
            path: "/cadastro/cliente/procuracoes/fisicas",
            role: cadastroRoles.customer.lettersOfAttorneyVintagePage.role,
            element: <LettersOfAttorneyPage />,
            showChildren: true,
            children: {
              embedded: {
                name: "Detalhes",
                path: `/cadastro/cliente/procuracoes/fisicas/:${cadastroRouteParams.uuid}`,
                role: cadastroRoles.customer
                  .lettersOfAttorneyVintageEmbeddedPage.role,
                element: <WorkflowDetails />,
                children: {
                  add: {
                    name: "Adicionar",
                    path: `/cadastro/cliente/procuracoes/fisicas/:${cadastroRouteParams.uuid}/adicionar`,
                    role: cadastroRoles.customer
                      .lettersOfAttorneyVintageEmbeddedPage.role,
                    element: <AddRepresentativePage />,
                  },
                  contingencies: {
                    name: "Contingências Procuração física",
                    path: `/cadastro/cliente/procuracoes/fisicas/:${cadastroRouteParams.uuid}/contingencias`,
                    role: cadastroRoles.customer
                      .lettersOfAttorneyVintageContingencies.role,
                    element: <LoaContingencies />,
                  },
                  update: {
                    name: "Atualizar",
                    path: `/cadastro/cliente/procuracoes/fisicas/:${cadastroRouteParams.uuid}/:${cadastroRouteParams.representativeUuid}`,
                    role: cadastroRoles.customer
                      .lettersOfAttorneyVintageEmbeddedPage.role,
                    element: <UpdateRepresentativePage />,
                  },
                },
              },
              monitoring: {
                name: "Monitoramento",
                path: "/cadastro/cliente/procuracoes/fisicas/monitoramento",
                role: cadastroRoles.customer.lettersOfAttorneyVintageMonitoring
                  .role,
                element: <LoaMonitoring />,
              },
              fraudVerification: {
                name: "Validações de segurança",
                path: `/cadastro/cliente/procuracoes/fisicas/validacoes-seguranca/:${cadastroRouteParams.uuid}`,
                role: cadastroRoles.customer
                  .lettersOfAttorneyVintageFraudVerificationPage.role,
                element: <FraudVerificationPage />,
              },
            },
          },
          electronic: {
            name: "Procurações eletrônicas",
            path: "/cadastro/cliente/procuracoes/eletronicas",
            role: cadastroRoles.customer.lettersOfAttorneyElectronicPage.role,
            element: <ElectronicLettersOfAttorneyPage />,
            children: {
              monitoring: {
                name: "Monitoramento",
                path: "/cadastro/cliente/procuracoes/eletronicas/monitoramento",
                role: cadastroRoles.customer
                  .lettersOfAttorneyElectronicMonitoring.role,
                element: <EloaMonitoring />,
              },
              contingencies: {
                name: "Contingências Procuração eletrônica",
                path: `/cadastro/cliente/procuracoes/eletronicas/:${cadastroRouteParams.uuid}/contingencias`,
                role: cadastroRoles.customer
                  .lettersOfAttorneyElectronicContingencies.role,
                element: <EloaContingencies />,
              },
            },
          },
        },
      },
      tools: {
        name: "Ferramentas",
        path: "/cadastro/cliente/ferramentas",
        role: cadastroRoles.customer.toolsPage.role,
        element: <Tools />,
        children: {
          approveContingency: {
            name: "Aprovação de cadastro",
            path: "/cadastro/cliente/ferramentas/aprovacao-cadastro",
            role: cadastroRoles.customer.toolsApproveContingencyPage.role,
            element: <ToolsApproveContingency />,
          },
          documentDevolutive: {
            name: "Documentos do cliente",
            path: "/cadastro/cliente/ferramentas/documentos-cliente",
            role: cadastroRoles.customer.toolsDocumentDevolutivePage.role,
            element: <ToolsDocumentDevolutive />,
          },
          generateTerm: {
            name: "Gerar termos",
            path: "/cadastro/cliente/ferramentas/gerar-termos",
            role: cadastroRoles.customer.toolsGenerateTermPage.role,
            element: <ToolsGenerateTerm />,
          },
          migrateDesks: {
            name: "Migração de segmento de conta",
            path: "/cadastro/cliente/ferramentas/migracao-segmento",
            role: cadastroRoles.customer.toolsMigrateDesksPage.role,
            children: {
              byWorkflowId: {
                name: "Migrar mesa",
                path: `/cadastro/cliente/ferramentas/migracao-segmento/:${cadastroRouteParams.workflowId}`,
                role: cadastroRoles.customer.toolsMigrateDesksByWorkflowIdPage
                  .role,
                element: <DeskMigrationOptions />,
              },
            },
          },
          onboardingWorkflows: {
            name: "Edição de onboarding",
            path: "/cadastro/cliente/ferramentas/onboarding-workflows",
            role: cadastroRoles.customer.toolsOnboardingWorkflowsPage.role,
            element: <CustomerOnboardingWorkflows />,
          },
          dataReviewWorkflows: {
            name: "Edição de revisão cadastral",
            path: "/cadastro/cliente/ferramentas/revisao-cadastral-workflows",
            role: cadastroRoles.customer.toolsDataReviewWorkflowsPage.role,
            element: <CustomerDataReviewWorkflows />,
          },
          dataUpdateWorkflows: {
            name: "Edição de atualização cadastral",
            path: "/cadastro/cliente/ferramentas/atualizacao-cadastral-workflows",
            role: cadastroRoles.customer.toolsDataUpdateWorkflowsPage.role,
            element: <CustomerDataUpdateWorkflows />,
          },
        },
      },
      notification: {
        name: "Notificações",
        path: "/cadastro/cliente/notificacoes",
        hasSubItems: true,
        children: {
          emails: {
            name: "E-mails",
            path: "/cadastro/cliente/notificacoes/email",
            role: cadastroRoles.customer.notificationEmailsPage.role,
            element: <EmailNotifications />,
          },
          workflows: {
            name: "Convites",
            path: "/cadastro/cliente/notificacoes/convites",
            role: cadastroRoles.customer.notificationWorkflowsPage.role,
            element: <CustomerWorkflowTracker />,
            children: {
              invites: {
                name: "Detalhes do Convite",
                path: `/cadastro/cliente/notificacoes/convites/:${cadastroRouteParams.workflowIdentifier}`,
                role: cadastroRoles.customer.notificationInvitesPage.role,
                element: <CustomerInviteDetails />,
              },
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
