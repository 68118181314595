import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { GetAdminCustomerLimitsOdataResponse } from "services/hubcreditmanager/models/responses";
import { format } from "utils/date";

export const buildLimitManagerGuarantorDetailsFields = (
  limit:
    | NonNullable<GetAdminCustomerLimitsOdataResponse["value"]>[number]
    | undefined,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Linha de crédito:",
        value: limit?.CreditLineName,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Estado da requisição:",
        value: limit?.LimitRequestStatus,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Data de expiração:",
        value: format.date(limit?.ExpirationDate?.toString(), {
          timeZone: "UTC",
        }),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  limits: {
    title: "Limite",
    items: [
      {
        label: "Limite:",
        value: masks.currency(limit?.Amount, "R$", "."),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Limite disponível:",
        value: masks.currency(limit?.AvailableAmount, "R$", "."),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
});
