import {
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { AutoRelocationForm } from "../../auto-relocation.types";

interface RelocationTimeModalProps {
  form: UseFormReturn<AutoRelocationForm>;
  relocationPeriod: "semanal" | "diario";
  submit: (periodo: "semanal" | "diario") => () => Promise<void>;
}

export const relocationTimeModalId = "relocation-time-modal-id";

export const RelocationTimeModal = ({
  form,
  relocationPeriod,
  submit,
}: RelocationTimeModalProps) => {
  const [loading, setLoading] = useState(false);

  const { setValue } = form;

  const innerSubmit = useCallback(async () => {
    setLoading(true);
    await submit(relocationPeriod)();
    setLoading(false);
  }, [relocationPeriod, submit]);

  useEffect(() => {
    const cleanup = modalManager.on(relocationTimeModalId, "modalOpen", () =>
      setValue("time", ""),
    );
    return cleanup;
  }, [setValue]);

  const title = `Ligar realocação ${
    relocationPeriod === "semanal" ? "semanal" : "diária"
  }`;

  return (
    <OModal id={relocationTimeModalId} position="center" size="sm">
      <OModalHeader>
        <OTypography size="lg" key={title}>
          {title}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <ORFieldInput
          dataAction="modal_realocacao:texto:horario"
          dataLabel="horario"
          id="time"
          name="time"
          tag="mask"
          label="Digite o horário para rodar a realocação"
          placeholder="00:00"
          maskOption={{ mask: "00{:}00" }}
        />
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end">
          <LoadingButton
            dataAction="modal_realocacao:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            onClick={innerSubmit}
          >
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
