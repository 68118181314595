import * as yup from "yup";
import { FormValuesType } from "./onboarding-strategies-form.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  description: yup.string().required("Este campo é obrigatório"),
  onboardingPriority: yup.string().required("Este campo é obrigatório"),
  onboardingType: yup.string().required("Este campo é obrigatório"),
  productType: yup.string().required("Este campo é obrigatório"),
  sacadosIds: yup.array().required("Este campo é obrigatorio"),
  estrategiasId: yup.array(),
});
