import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import dayjs from "dayjs";
import { Installment } from "../../../../offers.type";

export const grid: ODataGridGeneratorConfig<Installment[][number]> = {
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    noDataText: "Nenhuma parcela encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} parcelas)",
    },
    remoteOperations: true,
  },
  columns: [
    {
      dataField: "code",
      dataType: "string",
      caption: "Parcela",
    },
    {
      dataField: "maturityDate",
      caption: "Vencimento",
      cellRender: ({ data }) => {
        return dayjs(data.maturityDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      },
    },
    {
      dataField: "amount",
      caption: "Valor",
      format: (value) => masks.currency(value, "R$", "."),
    },
  ],
};
