import { SelectConditionModal } from "../../../../../../components/conditions/select-conditions-component/select-condition-modal/select-condition-modal.component";
import { TestConditionModal } from "../../../../../../components/conditions/test-condition-modal/test-condition-modal.component";
import { CanvasProvider } from "../../../../../../components/hooks/use-canvas/use-canvas.context";
import { WorkflowProvider } from "../../../../../../components/hooks/use-workflow.context";
import {
  AddCanvasItemModal,
  AddProcessorConfigModal,
  AddRelationshipModal,
  CanvasContextMenu,
  ImportCanvasModal,
} from "./_compose";

export const CanvasProposalWorkflowPage = () => {
  return (
    <WorkflowProvider>
      <CanvasProvider>
        <SelectConditionModal />
        <TestConditionModal />

        <AddCanvasItemModal />
        <AddProcessorConfigModal />
        <AddRelationshipModal />
        <ImportCanvasModal />

        <CanvasContextMenu />
      </CanvasProvider>
    </WorkflowProvider>
  );
};
