import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminReceivablesCotacaoResponse } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";

export const quoteGrid: ODataGridGeneratorConfig<
  PostAdminReceivablesCotacaoResponse[number]
> = {
  datagrid: {},
  columns: [
    {
      dataField: "recebivel.idExterno",
      caption: "ID",
    },
    {
      dataField: "recebivel.sacadoNome",
      caption: "Sacado",
    },
    {
      dataField: "recebivel.sacadoCnpj",
      caption: "CNPJ do Sacado",
      format: (value) => masks.cnpj(value),
    },
    {
      dataField: "recebivel.cedenteNome",
      caption: "Cedente",
    },
    {
      dataField: "recebivel.cedenteCnpj",
      caption: "CNPJ do Cedente",
      format: (value) => masks.cnpj(value),
    },
    {
      dataField: "recebivel.bandeira",
      caption: "Bandeira",
      alignment: "center",
      cellRender: ({ data }) => {
        const icon =
          getValueFromMap(cardIconMap, data.recebivel.bandeira) ||
          data.recebivel.bandeira;

        return icon;
      },
    },
    {
      dataField: "recebivel.dataVencimento",
      caption: "Data de Vencimento",
      dataType: "date",
      format: "shortDate",
    },
    {
      dataField: "recebivel.dataDesembolso",
      caption: "Data de Desembolso",
      dataType: "date",
      format: "shortDate",
    },
    {
      dataField: "recebivel.valorVencimento",
      caption: "Valor Bruto",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "cotacao",
      caption: "Valor Líquido",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "taxaMensal",
      caption: "Taxa Mensal",
      dataType: "number",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "taxaTotal",
      caption: "Taxa Total",
      dataType: "number",
      format: { type: "percent", precision: 4 },
    },
    {
      dataField: "erro",
      caption: "Erro",
    },
  ],
};
