const removeDxScrollableContentStyle = (element: Element) =>
  (element?.closest?.(".dx-scrollable-content") as HTMLElement)?.setAttribute(
    "style",
    "",
  );

/**
 * Fix for modals inside datagrid `cellRender`.
 *
 * #### Problem:
 * When the datagrid has overflow, the modal sometimes gets "stuck" inside the grid.
 * #### Cause:
 * The datagrid listens for "mousedown" and "wheel" events and applies a `transform` to
 * a `div.dx-scrollable-content`, causing it to effectively behave as `position: relative`
 * (https://stackoverflow.com/a/68753395) and thus "imprisoning" the modal (that has position absolute).
 * ### How the fix works:
 * It simply prevents the events from travelling upwards in the DOM and reaching the
 * datagrid handlers. It doesn't matter that the regular datagrid behavior won't work,
 * since when the modal is open that's all we care about.
 * ### Usage
 * ```jsx
 * <OModal {...datagridModalFix} />
 * ```
 */
export const datagridModalFix = {
  onMouseDown: (evt: React.MouseEvent) => {
    removeDxScrollableContentStyle(evt.currentTarget);
    evt.stopPropagation();
  },
  onWheel: (evt: React.WheelEvent) => {
    removeDxScrollableContentStyle(evt.currentTarget);
    evt.stopPropagation();
  },
  // Ideally this would be onTransitionStart, but React doesn't support it yet (https://github.com/facebook/react/pull/27345)
  // Clearing the inline style at the start because the `.dx-scrollable-content` may already have it before the modal is open
  onTransitionEnd: (evt: React.TransitionEvent) =>
    removeDxScrollableContentStyle(evt.currentTarget),
};
