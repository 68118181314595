import { ODataGridGeneratorConfig } from "components/data-grid";
import { FinancedAsset } from "./financed-asset.types";

export const financedAssetPropertyRegistriesGrid = {
  datagrid: {
    noDataText: "Nenhum propriedade encontrada para o ativo financiado",
  },
  columns: [
    {
      dataField: "registryFirmName",
      caption: "Nome",
    },
    {
      dataField: "registryFirmTaxId",
      caption: "CNPJ",
    },
    {
      dataField: "address",
      caption: "Endereço",
    },
    {
      dataField: "neighborhood",
      caption: "Bairro",
    },
    {
      dataField: "city",
      caption: "Cidade",
    },
    {
      dataField: "county",
      caption: "Comarca",
    },
    {
      dataField: "state",
      caption: "Estado",
    },
    {
      dataField: "zipCode",
      caption: "CEP",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  FinancedAsset["properties"][number]["registries"][number]
>;
