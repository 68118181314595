import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PostAdminUserBody } from "services/onboarding/models";
import { service } from "services/service";
import { useGetUser } from "../../../../hooks/use-get-user.hook";
import { changeUserEmailSchema } from "./alteracao-email.form";

export interface EmailManagementFormValues {
  currentEmail: string;
  newEmail: string;
  updatingReason: string;
}

export const useChangeUserEmail = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useGetUser();
  const form = useForm<EmailManagementFormValues>({
    resolver: yupResolver(changeUserEmailSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (!user) {
      return;
    }

    const payload: PostAdminUserBody = {
      newUserEmail: values.newEmail,
      oldUserEmail: user.email ?? "",
      updateReason: values.updatingReason,
      userTaxId: user.cpf ?? "",
    };

    setLoading(true);
    try {
      await service.onboarding.updateUserEmail(payload);
      OToastManager.success("E-mail do usuário alterado com sucesso!");
    } catch {
      OToastManager.danger("Não foi possível alterar o e-mail do usuário");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    onSubmit,
    loading,
    user,
  };
};
