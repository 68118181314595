import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PatchByIdAdminPreticketBody } from "services/hubfx/models/requests/patch-by-id-admin-pretickets.request";
import { GetCommonCountriesResponse } from "services/hubfx/models/responses";
import { AdminPayerTypesResponse } from "services/hubfx/models/responses/get-payer-types.response";
import { ChannelOdataPreTicketsValue } from "services/hubfx/odata/models/responses/get-admin-pre-tickets-o-data.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { preticketsFormValidationSchema } from "./exchange-product-preticket-edit.schemas";
import {
  ORFieldInputs,
  payerGroupMap,
  statusMap,
} from "./exchange-product-preticket-edit.utils";

export const ExchangeContingencyPreticketEditPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [payerTypes, setPayerTypes] = useState<AdminPayerTypesResponse>();
  const [countries, setCountries] = useState<GetCommonCountriesResponse>();

  const { value: preticket } =
    useParseFromSearchParams<ChannelOdataPreTicketsValue>("data");

  const form = useForm<PatchByIdAdminPreticketBody>({
    resolver: yupResolver(preticketsFormValidationSchema),
    defaultValues: {
      ...preticket,
    },
  });

  const { handleSubmit, watch, reset } = form;
  const watchStatus = watch("status");

  const onEdit = useCallback(
    async (values: PatchByIdAdminPreticketBody & { preTicketId: number }) => {
      try {
        setLoading(true);

        const {
          fxAvailableAmount,
          payerType,
          clientLink,
          counterpartyIdentification,
          country,
          preTicketId,
          status,
        } = values;

        const payload = {
          clientLink,
          counterpartyIdentification,
          country,
          preTicketId,
          status,
          fxAvailableAmount: fxAvailableAmount ?? null,
          payerType: payerType ?? null,
        };

        await service.hubFx.patchByIdPreticket(payload);

        OToastManager.success("Preticket atualizado com sucesso.");

        navigate(
          corporateRouter.routes.cambio.product.contingency.preTickets.path,
        );
      } catch {
        OToastManager.danger("Erro ao atualizar preticket.");
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        if (preticket?.id) {
          onEdit({ ...formValues, preTicketId: preticket.id });
        }
      }),
    [preticket, handleSubmit, onEdit],
  );

  const getPayers = useCallback(async () => {
    const { data: response } = await service.hubFx.getPayerTypes();

    if (!response) return;

    setPayerTypes(response);
  }, []);

  const getCountries = useCallback(async () => {
    const { data: response } = await service.hubFx.getCountries();

    if (!response) return;

    setCountries(response);
  }, []);

  useEffect(() => {
    getPayers();
    getCountries();
  }, [getCountries, getPayers]);

  useEffect(() => {
    if (!preticket) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, fxAvailableAmount, ...values } = preticket;

    const payload = {
      ...values,
      status: watchStatus,
      fxAvailableAmount: fxAvailableAmount ?? 0,
    };

    reset(payload);
  }, [preticket, reset, watchStatus]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar Pré-Ticket" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldSelect label="Status" name="status" id="status">
              {statusMap?.map((status) => (
                <OOption key={status} value={status}>
                  {status}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>

          {ORFieldInputs.map((item) => (
            <OCol key={`${item.id}-col`}>
              <ORFieldInput
                symbol={
                  preticket?.flowType === "REMESSA"
                    ? String(preticket?.buyCurrencyType)
                    : String(preticket?.sellCurrencyType)
                }
                tag={item.mask ? "currency" : "text"}
                disabled={item.blockedAtDraft && watchStatus !== "RASCUNHO"}
                {...item}
              />
            </OCol>
          ))}
          {countries && (
            <OCol>
              <ORFieldSelect
                disabled={watchStatus !== "RASCUNHO"}
                label="País"
                name="country"
                id="country"
              >
                {countries?.map((country) => (
                  <OOption key={country.code} value={String(country.code)}>
                    {country.description}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          )}
          {payerTypes && (
            <OCol>
              <ORFieldSelect
                label="Tipo de pagador"
                name="payerType"
                id="payerType"
                disabled={watchStatus !== "RASCUNHO"}
              >
                {payerTypes?.map((payerGroup) =>
                  payerGroup.payerTypes?.map((payer) => (
                    <OOption key={payer.code} value={String(payer.code)}>
                      {payer.code} -{" "}
                      {payerGroup.group && payerGroupMap[payerGroup.group]} -{" "}
                      {payer.description}
                    </OOption>
                  )),
                )}
              </ORFieldSelect>
            </OCol>
          )}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
