import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { ContractTemplate } from "./loan-contracts-templates.types";

type AdditionalSigner = ContractTemplate["additionalSigners"][number];

export const loanAdditionalSignerFieldsGrid = (
  onChange?: (additionalSigners: AdditionalSigner[]) => void,
) =>
  ({
    datagrid: {
      noDataText: "Nenhum registro adicionado.",
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
      },
      onRowInserted: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowUpdated: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowRemoved: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
    },
    columns: [
      {
        dataField: "partyCompany.taxId",
        caption: "Parte",
        format: (value) => masks.cnpj(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CNPJ inválido",
            validationCallback: (option) => {
              return validators.cnpj(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          // eslint-disable-next-line no-param-reassign
          newData.partyCompany = {
            ...newData.partyCompany,
            taxId: value?.replace(/\D/g, ""),
          };
        },
      },
      {
        dataField: "signerCompany.taxId",
        caption: "CPF",
        format: (value) => masks.cpf(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CPF inválido",
            validationCallback: (option) => {
              return validators.cpf(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          // eslint-disable-next-line no-param-reassign
          newData.signerCompany = {
            ...newData.signerCompany,
            taxId: value?.replace(/\D/g, ""),
          };
        },
      },
      {
        dataField: "signerCompany.name",
        caption: "Nome",
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "email",
        caption: "E-mail",
        validationRules: [{ type: "required" }, { type: "email" }],
      },
      {
        dataField: "nature",
        caption: "Natureza",
        lookup: {
          dataSource: ["AVALISTA", "INTERVENIENTE", "PROCURADOR", "TESTEMUNHA"],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "signWithBiometry",
        caption: "Assina via biometria?",
        dataType: "boolean",
      },
      {
        dataField: "automaticSignature",
        caption: "Assinatura automática?",
        dataType: "boolean",
      },
    ],
  } satisfies ODataGridGeneratorConfig<AdditionalSigner>);
