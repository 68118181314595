import { modalManager } from "@maestro/core";
import { IconButton } from "components/icon-button";
import { Job } from "../../jobs.types";
import { runJobs } from "../../jobs.utils";
import { ConfirmationModal, runMultipleModalId } from "../confirmation-modal";

interface BatchRunButtonProps {
  selectedJobs: Job[];
  reload: () => void;
}

export const BatchRunButton = ({
  selectedJobs,
  reload,
}: BatchRunButtonProps) => (
  <>
    <IconButton
      icon={{ category: "far", icon: "fa-redo" }}
      disabled={!selectedJobs.length}
      onClick={() => modalManager.show(runMultipleModalId)}
    >
      Redisparar selecionados
    </IconButton>
    <ConfirmationModal
      id={runMultipleModalId}
      onConfirm={async () => {
        await runJobs(selectedJobs.map(({ id }) => id));
        reload();
        modalManager.hide(runMultipleModalId);
      }}
      type="run"
      jobsAmount={selectedJobs.length}
    />
  </>
);
