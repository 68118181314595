import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { getHubfxErrorMessage } from "pages/cambio/components/error/error-code.utils";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PatchAdminCounterpartyBody } from "services/hubfx/models/requests";
import { AdminODataCounterpartiesValue } from "services/hubfx/odata/models/responses/get-admin-counterparties-o-data.response";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  exchangeProductEditCounterpartyFormDefaultValues,
  exchangeProductEditCounterpartyFormValidationSchema,
  ExchangeProductEditCounterpartyFormValues,
} from "./exchange-product-edit-counterparty-form.schemas";

export const ExchangeProductEditCounterpartyPage = () => {
  const navigate = useNavigate();

  const { value: counterParty } =
    useParseFromSearchParams<AdminODataCounterpartiesValue>("data");

  const form = useForm<ExchangeProductEditCounterpartyFormValues>({
    resolver: yupResolver(exchangeProductEditCounterpartyFormValidationSchema),
    defaultValues: exchangeProductEditCounterpartyFormDefaultValues,
  });
  const { reset, handleSubmit, getValues } = form;

  const { callService: updateCounterparty, loading } = useServiceCall(
    service.hubFx.patchCounterparty,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async () => {
        if (!counterParty?.Identification) return;

        const { Cge, ForcePreTicket, HasPreTicketEnabled } = getValues();

        const payload: PatchAdminCounterpartyBody = {
          CounterpartyIdentification: counterParty.Identification,
          Cge,
          ForcePreTicket: !!ForcePreTicket?.length,
          HasPreTicketEnabled: !!HasPreTicketEnabled?.length,
        };

        const { success, error } = await updateCounterparty(payload);

        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success("Contraparte atualizada com sucesso");

        navigate(corporateRouter.routes.cambio.product.counterparties.path);
      }),
    [handleSubmit, updateCounterparty, getValues, counterParty, navigate],
  );

  useEffect(() => {
    if (!counterParty) return;

    reset({
      ...counterParty,
      Cge: counterParty?.Cge,
      HasPreTicketEnabled: counterParty.HasPreTicketEnabled ? ["true"] : [],
      ForcePreTicket: counterParty.ForcePreTicket ? ["true"] : [],
    } as ExchangeProductEditCounterpartyFormValues);
  }, [counterParty, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar contraparte" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol sm={4} md={4}>
              <ORFieldInput tag="number" id="Cge" name="Cge" label="Cge" />
            </OCol>
            <OCol sm={4} md={4}>
              <ORFieldCheckboxGroup
                name="HasPreTicketEnabled"
                id="HasPreTicketEnabled"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    id="HasPreTicketEnabled-checkbox"
                    value="true"
                    size="xs"
                  />
                  <OLabel htmlFor="HasPreTicketEnabled-checkbox">
                    Pré-Ticket habilitado
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={4} md={4}>
              <ORFieldCheckboxGroup name="ForcePreTicket" id="ForcePreTicket">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    id="ForcePreTicket-checkbox"
                    value="true"
                    size="xs"
                  />
                  <OLabel htmlFor="ForcePreTicket-checkbox">
                    Pré-Ticket obrigatório
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
