import {
  OCard,
  OCardBody,
  OCol,
  OLabel,
  ORFieldInput,
  ORFieldRadioGroup,
  ORadio,
  ORow,
  OToastManager,
} from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { AtualizacaoRepresentanteLegalByUuidRouteParams } from "../../../../../../../routes/cadastro.route-params";
import { DocumentCard } from "./document";
import { RepresentativeFormValues } from "./representative-form-values";

export const UpdateRepresentativePage = () => {
  const { representativeUuid } =
    useParams<AtualizacaoRepresentanteLegalByUuidRouteParams>();
  if (!representativeUuid)
    throw new Error("No representative workflow uuid in route parameters");

  const {
    loading: loadingConfigRepresentative,
    callService: configRepresentative,
  } = useServiceCall(service.onboardingRelationships.configRepresentative);

  const {
    value,
    hasError,
    loading,
    callService: getRepresentative,
  } = useServiceCall(service.onboardingRelationships.getRepresentative);

  const {
    loading: loadingUpdateRepresentative,
    callService: updateRepresentative,
  } = useServiceCall(service.onboardingRelationships.updateRepresentative);

  const form = useForm<RepresentativeFormValues>();

  const { watch, reset, handleSubmit } = form;
  const currentIsDocumentsNecessary = useRef<string>();
  const watchSendDocuments = watch("sendDocuments");

  const getRepresenattiveCallBack = useCallback(async () => {
    const { success, response } = await getRepresentative({
      representativeUuid,
    });
    if (!success) return;
    const documentNecessary = response.data.isDocumentsNecessary ? "yes" : "no";
    currentIsDocumentsNecessary.current = documentNecessary;
    reset({
      ...response.data,
      sendDocuments: documentNecessary,
      documentFiles: response.data.documents?.[0]?.files ?? [],
      subType: response.data.documents?.[0]?.subType,
    });
  }, [getRepresentative, representativeUuid, reset]);

  useEffect(() => {
    getRepresenattiveCallBack();
  }, [getRepresenattiveCallBack]);

  useEffect(() => {
    if (watchSendDocuments !== currentIsDocumentsNecessary.current) {
      configRepresentative({ representativeUuid }).then(() =>
        getRepresenattiveCallBack(),
      );
    }
  }, [
    configRepresentative,
    getRepresenattiveCallBack,
    representativeUuid,
    watchSendDocuments,
  ]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Atualizar representante" />}
      actions={
        <LoadingButton
          loading={loadingUpdateRepresentative}
          title="Atualizar"
          ariaLabel="Atualizar"
          disabled={!value?.canEdit}
          onClick={handleSubmit(async (values) => {
            try {
              if (value === undefined) return;
              await updateRepresentative({
                email: values.email,
                name: values.name,
                id: value.id,
                phoneNumber: values.phoneNumber,
                taxId: values.taxId,
                uuid: representativeUuid,
              });
              OToastManager.success("Representante atualizado com sucesso");
            } catch {
              OToastManager.danger("Erro ao atualizar o Representante");
            }
          })}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={
          loading || loadingConfigRepresentative || loadingUpdateRepresentative
        }
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar detalhes"
            callback={() => getRepresentative({ representativeUuid })}
          />
        }
        value={value}
        render={() => (
          <OCard>
            <OCardBody>
              <FormProvider {...form}>
                <form>
                  <ORow>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="cpf"
                        id="taxId"
                        name="taxId"
                        label="Cpf"
                        disabled={value?.taxId !== undefined}
                      />
                    </OCol>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="text"
                        id="biometryStatus"
                        name="biometryStatus"
                        label="Status Biometria"
                        disabled
                      />
                    </OCol>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldRadioGroup
                        disabled={!value?.canEdit}
                        id="sendDocuments"
                        name="sendDocuments"
                        label="Enviar documentos"
                      >
                        <div className="d-flex gap-5">
                          <div className="d-flex gap-2">
                            <ORadio id="sendDocuments-yes" value="yes" />
                            <OLabel htmlFor="sendDocuments-yes">Sim</OLabel>
                          </div>
                          <div className="d-flex gap-2">
                            <ORadio id="sendDocuments-no" value="no" />
                            <OLabel htmlFor="sendDocuments-no">Não</OLabel>
                          </div>
                        </div>
                      </ORFieldRadioGroup>
                    </OCol>
                  </ORow>
                  <ORow>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="text"
                        id="email"
                        name="email"
                        disabled={!value?.canEdit}
                        label="Email"
                      />
                    </OCol>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="text"
                        id="name"
                        name="name"
                        disabled={!value?.canEdit}
                        label="Nome"
                      />
                    </OCol>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="phone"
                        id="phoneNumber"
                        name="phoneNumber"
                        disabled={!value?.canEdit}
                        label="Telefone"
                      />
                    </OCol>
                  </ORow>
                  <ORow>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="text"
                        id="fraudId"
                        name="fraudId"
                        disabled
                        label="Fraude Id"
                        value={value?.fraudId ?? "-"}
                      />
                    </OCol>
                    <OCol xs={12} md={6} xl={4}>
                      <ORFieldInput
                        tag="text"
                        id="fraudAnalysis"
                        name="fraudAnalysis"
                        disabled
                        label="Análise de fraude"
                        value={value?.fraudAnalysis ?? "-"}
                      />
                    </OCol>
                  </ORow>

                  {(value?.documents?.length ?? 0) > 0 && (
                    <DocumentCard
                      documentRepresentativeUuid={
                        value?.documents[0].uuid ?? ""
                      }
                      refetch={() => getRepresenattiveCallBack()}
                      canEdit={!!value?.canEdit}
                    />
                  )}
                </form>
              </FormProvider>
            </OCardBody>
          </OCard>
        )}
      />
    </FormTemplate>
  );
};
