import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { RecebiveisProductEstrategiaTemplatesByEstrategiaIdRouteParams } from "../../../../../../routes/recebiveis.route-params";

export const useEstrategiaTemplate = () => {
  const { estrategiaId } =
    useParams<RecebiveisProductEstrategiaTemplatesByEstrategiaIdRouteParams>();
  if (!estrategiaId) throw new Error("No estrategiaId");

  const { callService, hasError, loading, value } = useServiceCall(
    service.quickfin.getEstrategias,
  );

  const getCompanyEstrategia = useCallback(() => callService(), [callService]);

  useEffect(() => {
    getCompanyEstrategia();
  }, [getCompanyEstrategia]);

  const estrategia = useMemo(
    () => value?.find((_estrategia) => _estrategia.id === Number(estrategiaId)),
    [estrategiaId, value],
  );

  return {
    estrategia,
    estrategiaId,
    getCompanyEstrategia,
    hasError,
    loading,
  };
};
