import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { useServiceCall } from "hooks/service-call";
import { GetEntitySemaphoreItem } from "services/onboarding/models/responses/get-entity-semaphore.response";
import { OToastManager } from "@maestro/react";

export const useSemaphoreConsultDetails = (uuid: string) => {
  const { callService: _findConsult, loading } = useServiceCall(
    service.onboarding.findSemaphoreEntityConsult,
  );

  const [semaphoreEntityItem, setSemaphoreEntityItem] =
    useState<GetEntitySemaphoreItem>();

  const load = useCallback(async () => {
    if (!uuid) return;

    const { response } = await _findConsult(uuid);
    if (!response?.data) {
      OToastManager.warning(
        "Não foi localizado os detalhes da consulta da entidade",
      );
      return;
    }

    setSemaphoreEntityItem(response.data);
  }, [uuid, _findConsult]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    loading,
    semaphoreEntityItem,
  };
};
