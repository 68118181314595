import { DetailsFields } from "components/details-card";
import {
  AssignmentTradeCallbackMasterDetail,
  BalanceMasterDetail,
  BoletoMasterDetail,
  FeePaymentMasterDetail,
  PagForMasterDetail,
  PaymentMasterDetail,
  RoadwalkerMessage,
  TradeCallbackMasterDetail,
  TradeMasterDetail,
} from "../roadwalker-master-detail.types";
import { roadwalkerBalanceDetails } from "./roadwalker-balance-detail.details";
import { roadwalkerBoletoDetails } from "./roadwalker-boleto-detail.details";
import { roadwalkerFeePaymentDetails } from "./roadwalker-fee-payment-detail.details";
import { roadwalkerPagForDetails } from "./roadwalker-pag-for-detail.details";
import { roadwalkerPaymentDetails } from "./roadwalker-payment-detail.details";
import { roadwalkerTrdeCallbackDetails } from "./roadwalker-trade-callback-detail.details";
import { roadwalkerTradeDetails } from "./roadwalker-trade-detail.details";
import { roadwalkerAssignmentTradeCallbackDetails } from "./roadwalker-assignment-trade-callback-detail.details";

export const roadwalkerDetail = (
  data: RoadwalkerMessage,
  messageType: string,
) => {
  if (messageType === "balance") {
    return roadwalkerBalanceDetails(data as BalanceMasterDetail);
  }
  if (messageType === "boleto") {
    return roadwalkerBoletoDetails(data as BoletoMasterDetail);
  }
  if (messageType === "feepayment") {
    return roadwalkerFeePaymentDetails(data as FeePaymentMasterDetail);
  }
  if (messageType === "pagfor") {
    return roadwalkerPagForDetails(data as PagForMasterDetail);
  }
  if (messageType === "payment") {
    return roadwalkerPaymentDetails(data as PaymentMasterDetail);
  }
  if (messageType === "trade") {
    return roadwalkerTradeDetails(data as TradeMasterDetail);
  }
  if (messageType === "tradecallback") {
    return roadwalkerTrdeCallbackDetails(data as TradeCallbackMasterDetail);
  }
  if (messageType === "assignmenttradecallback") {
    return roadwalkerAssignmentTradeCallbackDetails(
      data as AssignmentTradeCallbackMasterDetail,
    );
  }

  return {} as DetailsFields;
};
