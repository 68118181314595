import { service } from "services";
import {
  FormStep,
  FormStepCardComponent,
} from "services/hubcreditmanager/models/types/workflow/form.types";
import {
  AssociatedCondition,
  ConditionDestination,
  ProcessorConfig,
  TemplateColumn,
} from "services/hubcreditmanager/models/types/workflow/workflow.types";

export const getRemoveConditionInfo = (
  destination: ConditionDestination,
  associatedCondition: AssociatedCondition,
) =>
  ({
    PROCESSOR_CONFIG: {
      service: () => {
        if (!associatedCondition.processorConfigConditionId)
          throw new Error("No id");
        return service.hubCreditManager.removeProcessorConfigCondition({
          processorConfigConditionId:
            associatedCondition.processorConfigConditionId,
        });
      },
    },
    STEP: {
      service: () => {
        if (!associatedCondition.formStepConditionId) throw new Error("No id");
        return service.hubCreditManager.removeFormStepCondition({
          formStepConditionId: associatedCondition.formStepConditionId,
        });
      },
    },
    COMPONENT: {
      service: () => {
        if (!associatedCondition.formStepComponentConditionId)
          throw new Error("No id");
        return service.hubCreditManager.removeFormStepCardComponentCondition({
          formStepCardComponentConditionId:
            associatedCondition.formStepComponentConditionId,
        });
      },
    },
    BATCH: {
      service: () => {
        if (!associatedCondition.batchFileTemplateColumnId)
          throw new Error("No id");
        return service.hubCreditManager.removeBatchFileTemplateCondition({
          batchFileTemplateConditionId:
            associatedCondition.batchFileTemplateColumnId,
        });
      },
    },
  }[destination]);

export const getAddConditionInfo = (
  destination: ConditionDestination,
  selectedProcessorConfig?: ProcessorConfig,
  selectedStep?: FormStep,
  selectedComponent?: FormStepCardComponent,
  selectedTemplateColumn?: TemplateColumn,
) =>
  ({
    PROCESSOR_CONFIG: {
      service: (sharedPaylod: {
        conditionType: string;
        conditionId?: number;
      }) => {
        if (!selectedProcessorConfig?.id) throw new Error("No id");
        return service.hubCreditManager.addProcessorConfigCondition({
          ...sharedPaylod,
          processorConfigId: selectedProcessorConfig?.id,
        });
      },
    },
    STEP: {
      service: (sharedPaylod: {
        conditionType: string;
        conditionId?: number;
      }) => {
        if (!selectedStep?.id) throw new Error("No id");
        return service.hubCreditManager.addFormStepCondition({
          ...sharedPaylod,
          formStepId: selectedStep?.id,
        });
      },
    },
    COMPONENT: {
      service: (sharedPaylod: {
        conditionType: string;
        conditionId?: number;
      }) => {
        if (!selectedComponent?.id) throw new Error("No id");
        return service.hubCreditManager.addFormStepCardComponentCondition({
          ...sharedPaylod,
          formStepCardComponentId: selectedComponent?.id,
        });
      },
    },
    BATCH: {
      service: (sharedPaylod: {
        conditionType: string;
        conditionId?: number;
      }) => {
        if (!selectedTemplateColumn?.id) throw new Error("No id");
        return service.hubCreditManager.addBatchFileTemplateCondition({
          ...sharedPaylod,
          batchFileTemplateColumnId: selectedTemplateColumn?.id,
        });
      },
    },
  }[destination]);

export const getRelationshipId = (
  destination: ConditionDestination,
  id: number,
) =>
  ({
    PROCESSOR_CONFIG: {
      processorConfigConditionId: id,
    },
    STEP: {
      formStepConditionId: id,
    },
    COMPONENT: {
      formStepComponentConditionId: id,
    },
    DOCUMENT: {
      formDocumentConditionId: id,
    },
    BATCH: {
      batchFileTemplateColumnId: id,
    },
  }[destination]);

export const conditionLabelsMap = {
  EXECUTE: {
    label: (destination: string) =>
      destination === "PROCESSOR_CONFIG" ? "Executar" : "Mostrar",
  },
  DONT_EXECUTE: {
    label: (destination: string) =>
      destination === "PROCESSOR_CONFIG" ? "Não executar" : "Não mostrar",
  },
  IS_REQUIRED: {
    label: () => "É obrigatório",
  },
};
