import { modalManager } from "@maestro/core";
import { OButton, OIcon, OTooltip, OTypography } from "@maestro/react";
import { TooltipContent } from "components/tooltip-icon";
import {
  DocumentTemplateModal,
  documentTemplateModalId,
} from "../../../../../../../../../../components/document-template-modal";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";

interface DocumentTemplateButtonProps {
  property: HubEnergy.DiligenceStepPropertySimpleResponse;
}

export const DocumentTemplateButton = ({
  property,
}: DocumentTemplateButtonProps) => {
  const { refresh } = useStepAnalysisContext();

  const hasTemplateFile = !!property.documentTemplate?.files?.length;
  const hasMultipleFiles =
    hasTemplateFile && property.documentTemplate.files.length > 1;

  return (
    <>
      <OTooltip
        maxWidth="420px"
        floating
        position="top-left"
        key={`${hasTemplateFile}${hasMultipleFiles}`}
      >
        <TooltipContent
          style={{ textAlign: "left" }}
          className="d-flex flex-column align-items-center gap-1"
        >
          <OTypography>
            Template do documento {!hasTemplateFile && "(vazio)"}
          </OTypography>
          {!hasTemplateFile && (
            <OTypography size="sm">
              Nenhum arquivo será mostrado para o cliente
            </OTypography>
          )}
          {hasMultipleFiles && (
            <OTypography size="sm">
              <OIcon
                category="orq"
                icon="orq-status-error"
                type="danger"
                className="d-inline"
              />{" "}
              Template de documento possui mais de um arquivo! Apenas o primeiro
              arquivo será exibido para o cliente.
            </OTypography>
          )}
        </TooltipContent>
        <OButton
          type="dark"
          outline
          onClick={() =>
            modalManager.show(documentTemplateModalId(property.id))
          }
          className="position-relative"
        >
          {!hasTemplateFile && (
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "var(--theme-warning)",
                borderRadius: "50%",
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
            />
          )}
          {hasMultipleFiles && (
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "var(--theme-danger)",
                borderRadius: "50%",
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
            />
          )}
          <OIcon category="fal" icon="fa-file-alt" />
        </OButton>
      </OTooltip>
      <DocumentTemplateModal
        documentTemplate={property.documentTemplate}
        propertyId={property.id}
        onModalClose={refresh}
      />
    </>
  );
};
