import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { LimitFinancedAsset } from "services/hubcreditmanager/models/types/limit/limit.types";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { financedAssetIdentificationMap } from "./financed-assets.utils";

export const financedAssetsGrid = {
  datagrid: {
    noDataText: "Nenhum ativo financiado encontrado para o limite",
  },
  columns: [
    {
      role: roles.emprestimos.product
        .limitManagerLimitsDetailsFinancedAssetsDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.financedAssets.details.path(
            { id: data.id },
          )}
        />
      ),
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "identification",
      caption: "Identificação",
      format: (value) =>
        getValueFromMap(financedAssetIdentificationMap, value?.toUpperCase()) ??
        value,
    },
    {
      dataField: "quantity",
      caption: "Quantidade",
      format: (value) => masks.currency(value, "", ".", ",", 0),
    },
    {
      dataField: "unitaryWeight",
      caption: "Peso unitário",
      cellRender: ({ data }) =>
        `${data.unitaryWeight ?? "-"} ${data.unitaryWeightUnit ?? "-"}`,
    },
    {
      dataField: "unitaryAmount",
      caption: "Valor unitário",
      format: (value) => masks.currency(value, currencySymbol.BRL),
    },
    {
      dataField: "amount",
      caption: "Valor total",
      format: (value) => masks.currency(value, currencySymbol.BRL),
    },
    {
      dataField: "harvest",
      caption: "Safra",
    },
    {
      dataField: "priceDate",
      caption: "Data de apuração do preço",
      dataType: "date",
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitFinancedAsset>;
