import { isAxiosError } from "axios";
import { DefaultErrorComponent } from "components/empty-state";
import { PostAdminRoleassignmentsGetWorkflowErrorResponse } from "services/onboarding-relationships/models";

interface FailureListProps {
  error: unknown;
  refetch: () => void;
}

export const FailureList = ({ error, refetch }: FailureListProps) => {
  const isTreatedError =
    isAxiosError<PostAdminRoleassignmentsGetWorkflowErrorResponse>(error) &&
    error.response?.data.failures?.[0];

  return isTreatedError ? (
    <ul>
      {error.response?.data.failures.map((failure) => (
        <li key={failure.errorCode}>{failure.errorMessage}</li>
      ))}
    </ul>
  ) : (
    <DefaultErrorComponent
      callback={refetch}
      title="Não foi possível buscar os perfis do usuário"
    />
  );
};
