import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { useBankslipContext } from "../../bankslip.context";

export const useBankslipEvents = () => {
  const [bankslipEvents, setBankslipEvents] =
    useState<BankingHub.BankslipEvents>({ bankSlipEvents: [] });
  const [isLoading, setIsLoading] = useState(false);
  const { bankslipToSeeEvents } = useBankslipContext();

  const getBankslipEvents = useCallback(async () => {
    try {
      if (!bankslipToSeeEvents) return;
      setIsLoading(true);
      const { data } = await service.adminBankinghub.getBankslipEvents(
        bankslipToSeeEvents,
      );
      setBankslipEvents(data);
    } catch {
      OToastManager.danger("Ocorreu um erro ao buscar os eventos do boleto.");
      setBankslipEvents({ bankSlipEvents: [] });
    } finally {
      setIsLoading(false);
    }
  }, [bankslipToSeeEvents]);

  useEffect(() => {
    getBankslipEvents();
  }, [getBankslipEvents]);

  return { bankslipEvents, isLoading };
};
