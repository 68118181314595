import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useConsentRevoke } from "./revoke-consent-modal.hook";

interface RevokeConsentModalProps {
  id: string;
  revokeConsentType: "scope" | "resource";
  successCallback?: () => void;
}

export const buildModalId = (appId: string) =>
  `developers-modal-revoke-consent-${appId}`;

export const RevokeConsentModal = ({
  id,
  revokeConsentType,
  successCallback,
}: RevokeConsentModalProps) => {
  const modalId = buildModalId(id);
  const { loading, submit } = useConsentRevoke(
    id,
    modalId,
    revokeConsentType,
    successCallback,
  );

  return (
    <OModal id={modalId} position="center" backdrop>
      <OModalHeader>
        <OTypography size="lg">Revogar consentimento</OTypography>
      </OModalHeader>
      <OModalBody>
        <div>
          <OTypography>Deseja revogar o consentimento?</OTypography>
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton disabled={loading} onClick={() => submit()}>
            Revogar
          </OButton>
          <OButton
            disabled={loading}
            onClick={() => modalManager.hide(modalId)}
          >
            Cancelar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
