import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { PostAdminCustomerLimitsResponse } from "services/hubcreditmanager/models/responses";

export const grid: ODataGridGeneratorConfig<
  NonNullable<
    NonNullable<
      NonNullable<
        PostAdminCustomerLimitsResponse["currentLimitRequest"]
      >["rate"]
    >["quotes"]
  >[number]
> = {
  datagrid: {
    noDataText: "Nenhuma cotação encontrada",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} cotações)",
    },
  },
  columns: [
    {
      dataField: "minTerm",
      caption: "Mínimo de parcelas",
    },
    {
      dataField: "maxTerm",
      caption: "Máximo de parcelas",
    },
    {
      dataField: "monthlyValue",
      caption: "Juros mensal",
      cellRender: ({ data }) => masks.percentage(data.monthlyValue),
    },
    {
      dataField: "gracePeriod",
      caption: "Carência",
    },
  ],
};
