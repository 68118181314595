/* eslint-disable react/jsx-no-duplicate-props */
import { OCol, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { EmptyState } from "components/empty-state";
import { usersBlockListFields } from "./users-blocked.form";
import { useUsersBlockList } from "./users-blocked.hook";

export const AccountAdminUsersBlocked = () => {
  const {
    allUsersAllowed,
    form,
    isDirty,
    options,
    resetUsersBlockList,
    submit,
    allUsersBlocked,
    loading,
  } = useUsersBlockList();

  const title = "Usuários restritos";

  return (
    <FormTemplate
      pageTitle={<PageTitle title={title} />}
      actions={
        <>
          <LoadingButton
            loading={loading}
            disabled={!isDirty}
            type="dark"
            outline
            onClick={resetUsersBlockList}
          >
            Resetar
          </LoadingButton>
          <LoadingButton
            loading={loading}
            onClick={submit}
          >
            Salvar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <ContentTemplate
          value={!!allUsersAllowed && !!allUsersBlocked}
          loading={loading}
          emptyStateComponent={
            <EmptyState messageParagraph="Nenhum dado a ser exibido" />
          }
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <SelectSearchMultiple
                    {...usersBlockListFields.users}
                    options={options}
                  />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
