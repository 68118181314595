import { OCol, ORFieldInputDateRange } from "@maestro/react";

export const MaturityDate = () => {
  return (
    <OCol xs={12} md={6} xl={4}>
      <ORFieldInputDateRange
        allowClear
        id="maturityDate"
        name="maturityDate"
        label="Data vencimento"
        placeholder={["Data de início", "Data de fim"]}
      />
    </OCol>
  );
};
