import { ODataGridGeneratorConfig } from "components/data-grid";
import { masks } from "@maestro/utils";
import { roles } from "roles/roles";
import { GridActions } from "./grid-actions/grid-actions.component";
import { NewAccountOptinMasterDetail } from "./new-accounts-optin.master-detail";
import { Workflow } from "./new-accounts-optin.type";

export const grid: ODataGridGeneratorConfig<Workflow> = {
  datagrid: {
    noDataText: "Nenhuma conta encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "uuid",
      caption: "Id Solicitação",
      role: roles.cadastro.customer.btgAccountsOptinWorkflowIdColumn.role,
    },
    {
      dataField: "cnpj",
      caption: "CNPJ",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "razaoSocial",
      caption: "Razão Social",
    },
    {
      dataField: "status",
      caption: "Status Workflow",
    },
    {
      dataField: "detailedStatus",
      caption: "Status Detalhado",
    },
    {
      dataField: "createdDate",
      caption: "Data de Abertura",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
      sortOrder: "desc",
    },
    {
      dataField: "updatedDate",
      caption: "Última Atualização",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    {
      dataField: "requester",
      caption: "Solicitante",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => <GridActions contract={data} />,
      alignment: "center",
    },
  ],
  masterDetail: {
    enabled: true,
    component: NewAccountOptinMasterDetail,
  },
};
