import {
  GetContractByUuidDetailsResponse,
  RuleStatus,
} from "services/paperclipv2/models";

export type ContractDetails = GetContractByUuidDetailsResponse;

export const RuleStatusTitle: {
  [key in RuleStatus]: string;
} = {
  PENDING_YOU: "Pendente (você)",
  PENDING_OTHERS: "Pendente (outros)",
  PENDING: "Pendente",
  OK: "Autorizado",
  OLD: "OLD",
};

export const RuleStatusColor: {
  [key in RuleStatus]: "success" | "default" | "info" | "warning" | "danger";
} = {
  PENDING_YOU: "warning",
  PENDING_OTHERS: "info",
  PENDING: "warning",
  OK: "success",
  OLD: "default",
};
