import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import dayjs from "dayjs";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { CompanyTransactionSummary } from "./companies-transactions-summary.types";

export const buildDataSource = (startDate: string, endDate: string) =>
  dataSourceCustomStoreGenerator<CompanyTransactionSummary>(() => {
    const daysInterval = dayjs(endDate).diff(dayjs(startDate), "day");

    if (daysInterval > 31) {
      OToastManager.warning("Intervalo de datas acima de 31 dias.");
      return Promise.resolve([]);
    }

    return service.adminBankinghub.card
      .getCompaniesTransactionsSummary({
        startDate,
        endDate,
      })
      .then(({ data }) => data.summaries)
      .catch(() => {
        const errorMessage =
          "Erro ao buscar o resumo das transações por empresas";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const grid: ODataGridGeneratorConfig<CompanyTransactionSummary> = {
  datagrid: {
    noDataText: "Nenhuma transação por empresa",
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
    },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 40, 100, 500, 1000],
      showInfo: true,
    },
  },
  columns: [
    {
      dataField: "name",
      caption: "Empresa",
      allowSorting: false,
      allowHeaderFiltering: false,
    },
    {
      dataField: "document",
      caption: "CNPJ",
      format: (value) => masks.cpfCnpj(value),
      allowSorting: false,
      allowHeaderFiltering: false,
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "transactionValue",
      caption: "Valor total transacionado",
      format: (value) => masks.currency(value, currencySymbol.BRL),
      allowSorting: false,
      allowFiltering: false,
    },
    {
      dataField: "transactionCount",
      caption: "Quantidade de transações",
      allowSorting: false,
      allowHeaderFiltering: false,
    },
  ],
  paging: {
    enabled: true,
    defaultPageSize: 1000,
  },
};
