import {
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { PostApiCustomerInviteOpenAccountBody } from "services/onboarding/models";
import { useInviteRequests } from "../invite-requests/invite-requests-hook";

interface InviteModalProps {
  optinTemplate: string;
  taxId: string;
  payloadAux: PostApiCustomerInviteOpenAccountBody | undefined;
}

export const InviteModal = ({
  optinTemplate,
  taxId,
  payloadAux,
}: InviteModalProps) => {
  const { sentInvite, redirectToOptin } = useInviteRequests();

  return (
    <OModal id="invite-modal" size="md" position="center" rounded>
      <OModalHeader divider closeButton>
        <OTypography tag="h2" size="lg">
          Gostaria de abrir a conta para o cliente através do optin?
        </OTypography>
      </OModalHeader>
      <OModalFooter>
        <div className="w-100 d-flex justify-content-end gap-2">
          <OButton
            dataAction="modal_convite:botao:enviar_convite"
            dataLabel="enviarConvite"
            type="dark"
            outline
            onClick={async () => {
              modalManager.hide("invite-modal");
              await sentInvite(payloadAux);
            }}
          >
            Enviar convite
          </OButton>
          <OButton
            dataAction="modal_convite:botao:ir_para_optin"
            dataLabel="irParaOptin"
            onClick={() => redirectToOptin(taxId, optinTemplate)}
          >
            Ir para optin
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
