import { masks } from "@maestro/utils";
import { MasterDetailComponentProps } from "components/data-grid";
import { DetailsCard, DetailsFields } from "components/details-card";
import { AdminPayeesODataValueItem } from "services/quickfin/models";
import { useAssignorsMasterDetails } from "./assignors-master-details.hook";

interface AssignorsDetailsGeneratorProps {
  cedenteIdentification: string | undefined;
  email: string | undefined;
  statusOnboarding: string | undefined;
  TipoOnboarding: string;
}

const assignorsDetailsGenerator = (data: AssignorsDetailsGeneratorProps) => {
  return {
    assignors: {
      items: [
        {
          label: "Identificação",
          value: masks.cnpj(data?.cedenteIdentification),
          grid: { xs: 12 },
        },
        {
          label: "Status do onboarding",
          value: `${data.TipoOnboarding} - ${data?.statusOnboarding}`,
          grid: { xs: 12 },
        },
        {
          label: "Email principal",
          value: data?.email,
          grid: { xs: 12 },
        },
      ],
    },
  } satisfies DetailsFields;
};

type AssignorsMasterDetailsProps =
  MasterDetailComponentProps<AdminPayeesODataValueItem>;

export const AssignorsMasterDetails = ({
  data: { data },
}: AssignorsMasterDetailsProps) => {
  const { hasError, loading, value } = useAssignorsMasterDetails(data);

  return (
    <div className="d-flex">
      <DetailsCard
        hasError={hasError}
        loading={loading}
        value={value}
        fields={assignorsDetailsGenerator}
      />
    </div>
  );
};
