import { OToastManager } from "@maestro/core";
import { useState, useEffect } from "react";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { service } from "services";
import {
  OModal,
  OModalBody,
  OModalHeader,
  OButton,
  modalManager,
  OModalFooter,
  OLabel,
  OCheckboxGroup,
  OCheckbox
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useRoles } from "hooks/roles";
import { Role } from "roles/roles.types";

export const AddRelationship = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const {
    callService: getGroupAssociations,
    value,
  } = useServiceCall(service.onboarding.getGroupAssociations);

  const { hasRole } = useRoles();
  const { callService: registerGroupAndAssociations, loading } = useServiceCall(
    service.onboarding.registerGroupAndAssociations,
  );
  const [checkedboxOption, setCheckedboxOption] = useState<string[]>([]);

  const onChange = (checkedValues: string[]) => {
    setCheckedboxOption(checkedValues);
  };
  const submit = async () => {
    const { success } = await registerGroupAndAssociations({
      taxId: identification,
      additionalRoles: checkedboxOption.map(x => parseInt(x)),
      groupName: "",
      description: "",
      companyGroupAssociations: [],
      type: "",
    });

    if (!success)
      return OToastManager.danger("Erro ao adicionar relacionamento");

    OToastManager.success("Adicionado com sucesso");
  };

  useEffect(() => {
    getGroupAssociations();
  }, [getGroupAssociations]);

  return (
    <OModal id={"group_association_modal"} size="sm" position="center">
      <OModalHeader closeButton divider>
        <span className="d-flex gap-2">
          Solicitação para adicionar relacionamentos na empresa:
        </span>
      </OModalHeader>
      <OModalBody>
        <OCheckboxGroup
          dataAction="section_name:checkbox:named_event"
          dataLabel="named_event"
          value={checkedboxOption}
          key={"1"}
          onInput={(event) => {
            const checkboxValue = (event.target as HTMLOCheckboxGroupElement)
              .value;
            Array.isArray(checkboxValue) && onChange(checkboxValue);
          }}
        >
          {value?.groupAssociation?.map((checkboxOption) => (
            hasRole(checkboxOption.role as Role) &&
            <div className="d-flex align-items-center gap-2 mb-1" key={checkboxOption.id.toString()}>
              <OCheckbox
                size="xs"
                id={checkboxOption.id.toString()}
                value={checkboxOption.id.toString()}
              />
              <OLabel htmlFor={checkboxOption.label}>{checkboxOption.label}</OLabel>
            </div>
          ))}
        </OCheckboxGroup>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_agrupar:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() => {
              setCheckedboxOption([])
              modalManager.hide("group_association_modal")
            }}
          >
            Cancelar
          </OButton>
          <LoadingButton
            dataAction="confirmacao_agrupar:botao:enviar"
            dataLabel="enviar"
            loading={loading}
            onClick={() => submit()}
          >
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
