import { UseFormReturn } from "react-hook-form";
import * as yup from "yup";

export interface FranquiasFormValues {
  name: string;
}

export const franquiasFormValidationSchema: yup.ObjectSchema<FranquiasFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
  });

export interface FranquiasFormProps {
  form: UseFormReturn<FranquiasFormValues, any>;
}
