import { OTypography } from "@maestro/react";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { TCOStrategiesODataValueItem } from "services/quickfin/models/responses/get-odata-tco-strategies-odata.response";
import { getValueFromMap } from "utils/get-value-from-map";
import { productMap } from "../estrategias-de-onboarding/onboarding-strategies.utils";

export const tcoStrategiesGrid = (
  filterEstrategias: (filterValue: string) => string[],
  filterCompanies: (filterValue: string) => string[],
  setStrategyId: React.Dispatch<React.SetStateAction<number | undefined>>,
): ODataGridGeneratorConfig<TCOStrategiesODataValueItem> => ({
  datagrid: {
    noDataText: "Nenhuma estratégia encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} itens)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    { dataField: "Description", caption: "Descrição" },
    {
      dataField: "TipoProduto",
      caption: "Tipo de Produto",
      format: (value) => productMap[value],
      cellRender: ({ data }) => getValueFromMap(productMap, data.TipoProduto),
      lookup: {
        dataSource: Object.keys(productMap).map((key) => ({
          value: key,
          label: getValueFromMap(productMap, key),
        })),
        valueExpr: "value",
        displayExpr: "label",
      },
    },
    {
      dataField: "Companies",
      caption: "Empresas",
      allowSorting: false,
      cellRender: ({ data }) =>
        data.Companies?.map((c: string) => (
          <OTypography key={c}>{c}</OTypography>
        )),
      calculateFilterExpression: filterCompanies,
    },
    {
      dataField: "Estrategias",
      caption: "Estratégias Comerciais",
      allowSorting: false,
      cellRender: ({ data }) =>
        data.Estrategias?.map((estrategia: string) => (
          <OTypography key={estrategia}>{estrategia}</OTypography>
        )),
      calculateFilterExpression: filterEstrategias,
    },
    {
      dataField: "PortfolioName",
      caption: "Carteira",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                route:
                  corporateRouter.routes.recebiveis.product.tcoStrategies.edit.path(
                    {
                      strategyId: data.Id,
                    },
                  ),
                role: roles.recebiveis.product.tcoStrategiesEditPage.role,
              },
              {
                label: "Excluir",
                icon: { category: "orq", icon: "orq-edit-trash" },
                onClick: () => setStrategyId(data.Id),
              },
            ]}
          />
        );
      },
    },
  ],
});
