import styled from "styled-components";

export const LoggerContent = styled.div`
  pointer-events: none;
  position: relative;
  top: 50px;
  left: 20px;
`;

export const LoggerTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
