import { OConfirmationModal, modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { GridTemplate } from "templates/grid-template";
import { RoadwalkerSearchModal } from "./_compose/modal-busca-roadwalker";
import { useRoadwalker } from "./roadwalker.hook";
import {
  reprocessConfirmationModalId,
  roadwalkerSearchModalId,
} from "./roadwalker.utils";

export const Roadwalker = () => {
  const {
    control,
    dataSource,
    form,
    grid,
    gridRef,
    loadingMessages,
    loadingReprocess,
    messageTypeWatch,
    onReprocess,
    onSearch,
    refetch,
    resetSearch,
    selectedMessages,
    setShowMostRecent,
    showMostRecent,
  } = useRoadwalker();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Roadwalker"
          description="Monitoramento e reprocessamento de mensagens recebidas."
        />
      }
      actions={
        <>
          <IconButton
            icon={{ category: "orq", icon: "orq-tools-search" }}
            dataAction="monitoramento_roadwalker:botao:abrir_busca"
            dataLabel="abrir_busca"
            onClick={() => modalManager.show(roadwalkerSearchModalId)}
          >
            Abrir busca
          </IconButton>
          <LoadingButton
            dataAction="monitoramento_roadwalker:botao:reprocessar"
            dataLabel="reprocessar"
            loading={loadingReprocess}
            disabled={!selectedMessages?.length}
            onClick={() => modalManager.show(reprocessConfirmationModalId)}
          >
            Reprocessar
          </LoadingButton>
          <RefreshGridButton onClick={() => refetch()} />
        </>
      }
      gridRef={gridRef}
      showExportButton
    >
      <ODataGridGenerator
        grid={grid}
        gridRef={gridRef}
        dataSource={dataSource}
      />
      <RoadwalkerSearchModal
        control={control}
        form={form}
        loadingMessages={loadingMessages}
        messageType={messageTypeWatch}
        onSubmit={onSearch}
        resetSearch={resetSearch}
        setShowMostRecent={setShowMostRecent}
        showMostRecent={showMostRecent}
      />
      <OConfirmationModal
        modalId={reprocessConfirmationModalId}
        loading={loadingReprocess}
        title={`Deseja reprocessar essa${
          selectedMessages && selectedMessages.length > 1 ? "s" : ""
        } mensage${
          selectedMessages && selectedMessages.length > 1 ? "ns" : "m"
        }?`}
        onConfirm={() => onReprocess()}
      />
    </GridTemplate>
  );
};
