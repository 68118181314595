import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { PostCountry } from "services/hubfx/models/requests/post-admin-countries.request";

export const exchangeProductCountriesGrid = {
  datagrid: {
    noDataText: "Nenhum país encontrado.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminCountriesOData,
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "description",
      caption: "Nome",
    },
    {
      dataField: "code",
      caption: "Código",
    },
    {
      dataField: "isApproved",
      caption: "Aprovado",
    },
    {
      dataField: "bcbCode",
      caption: "Código BCB",
    },
    {
      dataField: "createdDate",
      caption: "Data de criação",
    },
    {
      dataField: "updatedDate",
      caption: "Última atualização",
    },
    {
      role: roles.cambio.product.contingencyCountriesFormPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          href={`${
            corporateRouter.routes.cambio.product.contingency.countries.form
              .path
          }?data=${encodeURIComponent(JSON.stringify(data as PostCountry))}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubFx.odata.getAdminCountries>
  >["data"]["value"][number]
>;
