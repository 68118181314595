import styled from "styled-components";

export const HeaderNavigation = styled.nav`
  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
`;
