import { OTypography } from "@maestro/react";
import { useWorkflowFormTemplate } from "../../../../../../../../../../../../components/hooks/use-workflow-form-template.context";
import { ComponentPropertyField } from "./component-property-field.component";

export const ComponentProperty = () => {
  const { selectedComponent } = useWorkflowFormTemplate();

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Propriedades</OTypography>

      {selectedComponent?.component.properties.length ? (
        <div className="d-flex gap-4 flex-wrap justify-content-between">
          {selectedComponent?.component.properties.map((prop) => (
            <div className="w-48" key={`processor-config-property-${prop.id}`}>
              <ComponentPropertyField
                key={prop.name}
                componentProperty={prop}
                formStepCardComponentId={selectedComponent.id}
              />
            </div>
          ))}
        </div>
      ) : (
        <OTypography size="md" type="default">
          Nenhuma propriedade configurada para este componente
        </OTypography>
      )}
    </div>
  );
};
