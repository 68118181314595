import { OButton, OCol, OIcon, ORow } from "@maestro/react";
import { UseFieldArrayReturn } from "react-hook-form";
import {
  EditEligibilityGroupForm,
  newCriterium,
} from "../../edit-eligibility-group-modal.types";

interface AddFieldsProps {
  fieldArray: UseFieldArrayReturn<EditEligibilityGroupForm, "criteria", "id">;
}

export const AddFields = ({ fieldArray }: AddFieldsProps) => {
  const { append } = fieldArray;

  return (
    <ORow justify="end">
      <OCol xs={1}>
        <OButton
          dataAction="editar_grupo:botao:adicionar"
          dataLabel="adicionar"
          onClick={() => append(newCriterium)}
        >
          <OIcon category="fa" icon="fa-plus" />
        </OButton>
      </OCol>
    </ORow>
  );
};
