import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { exchangeOdataBeneficiariesGrid } from "pages/cambio/components/odata/exchange-odata-beneficiaries.grid";
import { GridTemplate } from "templates/grid-template";

export const ExchangeProductBeneficiariesPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Beneficiários" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeOdataBeneficiariesGrid}
      />
    </GridTemplate>
  );
};
