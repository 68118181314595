import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useState } from "react";
import { service } from "services";

interface UpdateAgroSacksButtonProps {
  limitRequestId: number;
  disabled?: boolean;
}

export const UpdateAgroSacksButton = ({
  limitRequestId,
  disabled,
}: UpdateAgroSacksButtonProps) => {
  const [loading, setLoading] = useState(false);

  const updateAgroSacks = useCallback(async () => {
    try {
      setLoading(true);

      await service.hubCreditManager.updateAgroSacks({ limitRequestId });

      OToastManager.success(
        "Data de apuração e preço das sacas atualizados com sucesso.",
      );
    } catch {
      OToastManager.danger("Não foi possível atualizar preço das sacas.");
    } finally {
      setLoading(false);
    }
  }, [limitRequestId]);

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      dataAction="emprestimos_contingencia:botao:atualizar_sacas"
      dataLabel="atualizar_sacas"
      onClick={() => updateAgroSacks()}
      outline
    >
      Atualizar
    </LoadingButton>
  );
};
