import { DeepNullable, MaestroFields, YupObjectSchema } from "utils/types";
import * as yup from "yup";
import {
  AddressForm,
  addressFormDefaultValues,
  addressFormValidationSchema,
} from "../../../../../../components/address-form/address.form";

export interface ConsumerUnitForm extends AddressForm {
  externalId: string;
  alias: string;
  invoiceName: string;
  ccer: string;
  clientNumber: string;
  demand: number;
  distributorContractExpirationDate: string;
  fareGroup: string;
  installationNumber: string;
  invoiceAmount: number;
  isMainUnit: string[];
  migrationDate: string;
  minimumPeakAllocation: number | null;
  modality: string;
  offPeakDemand: number | null;
  peakDemand: number | null;
  reportingDate: string;
  supplyEndDate: string;
  taxId: string;
  volume: number | null;
  volumePerHour: number | null;
  entityOfficialName: string;
  entityName: string;
  currentDistributorId: number;
  agentProfile: string;
  energyType: string;
}

export const consumerUnitFormFields = {
  externalId: {
    id: "externalId",
    name: "externalId",
    tag: "text",
    label: "ExternalId",
    placeholder: "Preencher",
  },
  alias: {
    id: "alias",
    name: "alias",
    tag: "text",
    label: "Descrição",
    placeholder: "Preencher",
  },
  invoiceName: {
    id: "invoiceName",
    name: "invoiceName",
    tag: "text",
    label: "Nome na fatura",
    placeholder: "Preencher",
  },
  ccer: {
    id: "ccer",
    name: "ccer",
    tag: "text",
    label: "Número CCER",
    placeholder: "Preencher",
  },
  installationNumber: {
    id: "installationNumber",
    name: "installationNumber",
    tag: "text",
    label: "Número de instalação",
    placeholder: "Preencher",
    required: true,
  },
  clientNumber: {
    id: "clientNumber",
    name: "clientNumber",
    tag: "text",
    label: "Número do cliente",
    placeholder: "Preencher",
  },
  fareGroup: {
    id: "fareGroup",
    name: "fareGroup",
    tag: "text",
    label: "Modalidade tarifária - Grupo",
    placeholder: "Preencher",
  },
  modality: {
    id: "modality",
    name: "modality",
    tag: "text",
    label: "Modalidade tarifária - Bandeira",
    placeholder: "Preencher",
  },
  volume: {
    id: "volume",
    name: "volume",
    tag: "text",
    label: "Volume (MWm)",
    placeholder: "Preencher",
  },
  peakDemand: {
    id: "peakDemand",
    name: "peakDemand",
    tag: "text",
    label: "Demanda contratada ponta (kW)",
    placeholder: "Preencher",
  },
  offPeakDemand: {
    id: "offPeakDemand",
    name: "offPeakDemand",
    tag: "text",
    label: "Demanda contratada fora-ponta (kW)",
    placeholder: "Preencher",
  },
  minimumPeakAllocation: {
    id: "minimumPeakAllocation",
    name: "minimumPeakAllocation",
    tag: "number",
    label: "Percentual de alocação do volume mínimo na ponta",
    placeholder: "Preencher",
  },
  isMainUnit: {
    id: "isMainUnit",
    name: "isMainUnit",
  },
  migrationDate: {
    id: "migrationDate",
    name: "migrationDate",
    label: "Data da migração",
    placeholder: "Selecionar",
    size: "md",
  },
  supplyEndDate: {
    id: "supplyEndDate",
    name: "supplyEndDate",
    label: "Data final de suprimento",
    placeholder: "Selecionar",
    size: "md",
  },
  distributorContractExpirationDate: {
    id: "distributorContractExpirationDate",
    name: "distributorContractExpirationDate",
    label: "Data de expiração do contrato com a distribuidora",
    placeholder: "Selecionar",
    size: "md",
  },
  taxId: {
    id: "taxId",
    name: "taxId",
    tag: "cpfcnpj",
    label: "CNPJ",
    placeholder: "Preencher",
    required: true,
  },
  entityOfficialName: {
    id: "entityOfficialName",
    name: "entityOfficialName",
    tag: "text",
    label: "Razão social",
    placeholder: "Preencher",
    required: true,
  },
  entityName: {
    id: "entityName",
    name: "entityName",
    tag: "text",
    label: "Nome fantasia",
    placeholder: "Preencher",
    required: true,
  },
  agentProfile: {
    id: "agentProfile",
    name: "agentProfile",
    tag: "text",
    label: "Perfil do agente",
    placeholder: "Preencher",
  },
  energyType: {
    id: "energyType",
    name: "energyType",
    tag: "text",
    label: "Tipo de energia",
    placeholder: "Preencher",
  },
} as const satisfies MaestroFields<ConsumerUnitForm>;

const stringRequired = yup.string().required("Campo obrigatório").trim();

export const consumerUnitFormValidationSchema = yup
  .object<ConsumerUnitForm, YupObjectSchema<ConsumerUnitForm>>({
    installationNumber: stringRequired,
    taxId: stringRequired,
    entityName: stringRequired,
    currentDistributorId: yup.number().required("Campo obrigatório"),
  })
  .when([], (_, schema, context) =>
    (context.value as ConsumerUnitForm)?.address?.zipCode
      ? schema.concat(addressFormValidationSchema)
      : schema,
  );

export const consumerUnitFormDefaultValues = {
  ...addressFormDefaultValues,
  externalId: null,
  alias: null,
  invoiceName: null,
  ccer: null,
  clientNumber: null,
  demand: null,
  distributorContractExpirationDate: null,
  fareGroup: null,
  installationNumber: null,
  invoiceAmount: null,
  isMainUnit: null,
  migrationDate: null,
  minimumPeakAllocation: null,
  modality: null,
  offPeakDemand: null,
  peakDemand: null,
  reportingDate: null,
  supplyEndDate: null,
  taxId: null,
  volume: null,
  volumePerHour: null,
  entityOfficialName: null,
  entityName: null,
  currentDistributorId: null,
  agentProfile: null,
  energyType: null,
} satisfies Partial<
  DeepNullable<ConsumerUnitForm>
> as unknown as ConsumerUnitForm;

export const checkboxValue = "true";
