import { OCol, OLink, ORow, OTypography } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { SelectSearchMultiple } from "components/form";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FerramentasProdutoUsuariosByUUIDRouteParams } from "pages/ferramentas/routes/ferramentas.route-params";
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { userProfilesFields } from "./roles.form";
import { useGatekeeperProfilesRoles } from "./roles.hook";

export const GatekeeperProfilesRoles = () => {
  const { uuid } = useParams<FerramentasProdutoUsuariosByUUIDRouteParams>();
  if (!uuid) throw new Error("No uuid");

  const [profileName, setProfileName] = useState<string>();

  const loadProfileName = useCallback(async () => {
    const { data } = await service.gatekeeper.getProfileDetails({
      profiles: [{ uuid }],
    });

    setProfileName(data[0].name);
  }, []);

  useEffect(() => {
    loadProfileName();
  }, [loadProfileName]);

  const {
    allRolesHasError,
    allRolesOptions,
    form,
    getAllRoles,
    getInitialProfile,
    initialProfileHasError,
    loading,
    submit,
  } = useGatekeeperProfilesRoles(uuid, profileName);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Perfil Gatekeeper"
          description="Atualize as roles do perfil selecionado"
        />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="atualizar_roles:botao:atualizar"
          dataLabel="atualizar"
          onClick={submit}
        >
          Atualizar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loading}
          hasError={allRolesHasError || initialProfileHasError}
          errorComponent={
            allRolesHasError ? (
              <DefaultErrorComponent
                title="Não foi possível buscar todas as roles"
                callback={getAllRoles}
              />
            ) : (
              <DefaultErrorComponent
                title="Não foi possível buscar as roles deste profile"
                callback={() => getInitialProfile(profileName ?? "")}
              />
            )
          }
          noValue
          render={() => (
            <>
              <OTypography className="mb-4">
                Para mais detalhes, acesse a{" "}
                <OLink
                  href="https://wiki.quickfin.com.br/en/Plataforma/Enablers/primeiro-acesso/dev/gatekeeper/profiles-roles"
                  target="_blank"
                >
                  documentação
                </OLink>
              </OTypography>

              <FormProvider {...form}>
                <ORow align="start">
                  <OCol xs={12} sm={6} lg={4}>
                    <SelectSearchMultiple
                      {...userProfilesFields.roles}
                      options={allRolesOptions}
                      className="w-100"
                      maxOptions={50}
                    />
                  </OCol>
                </ORow>
              </FormProvider>
            </>
          )}
        />
      </CardTemplate>
    </DetailsTemplate>
  );
};
