import { MappedStatus } from "./status-maps.types";

export const energyTradeStatusMap = {
  CRIADO: { type: "info", text: "Criado" },
  ERRO_RESERVA_OPERACAO: {
    type: "danger",
    text: "Erro na reserva da operação",
  },
  PENDENTE_ONBOARDING_CLIENTE: {
    type: "warning",
    text: "Pendente onboarding do cliente",
  },
  PENDENTE_ONBOARDING_AVALISTA: {
    type: "warning",
    text: "Pendente onboarding avalista",
  },
  PENDENTE_ESTADO_CIVIL_AVALISTA: {
    type: "warning",
    text: "Pendente estado civil avalista",
  },
  PENDENTE_ENDERECO_DISTRIBUIDORA: {
    type: "warning",
    text: "Pendente endereço distribuidora",
  },
  PENDENTE_ENDERECO_UNIDADE_CONSUMIDORA: {
    type: "warning",
    text: "Pendente endereço unidade consumidora",
  },
  PENDENTE_DADOS_INSTALACAO_UNIDADE_CONSUMIDORA: {
    type: "warning",
    text: "Pendente dados instalação unidade consumidora",
  },
  PENDENTE_DADOS_CONSUMO_UNIDADE_CONSUMIDORA: {
    type: "warning",
    text: "Pendente dados consumo unidade consumidora",
  },
  PENDENTE_RESERVA_OPERACAO: {
    type: "info",
    text: "Pendente reserva da operação",
  },
  PENDENTE_KIT_CADASTRAL: { type: "info", text: "Gerando documentação" },
  ERRO_GERACAO_DOCUMENTOS_MIGRACAO_E_CONTRATO: {
    type: "danger",
    text: "Erro geração documentos migração e contrato",
  },
  ERRO_GERACAO_CONTRATO: { type: "danger", text: "Erro geração contrato" },
  ERRO_GERACAO_DOCUMENTOS_MIGRACAO: {
    type: "danger",
    text: "Erro geração documentos migração",
  },
  PENDENTE_ASSINATURA: { type: "warning", text: "Pendente assinatura" },
  DOCUMENTOS_ASSINADOS_PENDENTE_ONBOARDING: {
    type: "warning",
    text: "Documentos assinados, pendente onboarding",
  },
  DOCUMENTOS_ASSINADOS_PENDENTE_CRIACAO_OPERACAO: {
    type: "info",
    text: "Documentos assinados, pendente criação da operação",
  },
  ERRO_CRIACAO_OPERACAO: {
    type: "danger",
    text: "Erro na criação da operação",
  },
  BOLETA_NES_CRIADA_PENDENTE_FINALIZACAO: {
    type: "info",
    text: "Boleta NES criada, pendente finalização",
  },
  ERRO_FINALIZACAO_BOLETA_NES: {
    type: "danger",
    text: "Erro finalização boleta NES",
  },
  BOLETA_NES_FINALIZADA_PENDENTE_DILIGENCIA: {
    type: "info",
    text: "Boleta NES finalizada, pendente diligência",
  },
  EM_DILIGENCIA: { type: "warning", text: "Em diligência" },
  CONCLUIDO: { type: "success", text: "Ativo" },
  FINALIZADO: { type: "gray", text: "Finalizado" },
  CANCELADO: { type: "gray", text: "Contrato distratado" },
  REJEITADO: { type: "gray", text: "Rejeitado" },
  EXPIRADO: { type: "gray", text: "Expirado" },
} satisfies Record<HubEnergy.ETradeStatus, MappedStatus>;
