import { cases } from "@maestro/utils";
import {
  OfferGuarantorBiometryStatusType,
  OfferGuarantorDocumentType,
} from "services/bankinghub/models/types/cards";
import {
  AssembleOIconPropsParams,
  IconProps,
  OfferGuarantorProperty,
  OfferGuarantorPropertyValidation,
} from "./offer-guarantors-list.types";

const loadRenderOIconDefaultCategoryProp = (
  type: Exclude<HTMLOIconElement["type"], undefined>,
): HTMLOIconElement["category"] => {
  return ["success", "icon"].includes(type) ? "fa" : "orq";
};

const loadRenderOIconDefaultIconProp = (
  type: Exclude<HTMLOIconElement["type"], undefined>,
): HTMLOIconElement["icon"] => {
  return type === "success"
    ? "fa-check-circle"
    : type === "info"
    ? "fa-info-circle"
    : type === "warning"
    ? "orq-status-warning"
    : "orq-add-minus";
};

export const assembleOIconProps = ({
  type,
  category = loadRenderOIconDefaultCategoryProp(type),
  icon = loadRenderOIconDefaultIconProp(type),
  size = "md",
}: AssembleOIconPropsParams): IconProps => {
  return {
    className: "align-self-start",
    size,
    type,
    category,
    icon,
  };
};

const loadOfferGuarantorPropertyError = (
  data: BankingHubCards.OfferGuarantor,
  propertyName: OfferGuarantorProperty,
): BankingHubCards.OfferGuarantorError | undefined => {
  return data.errors.find((error) => error.propertyName === propertyName);
};

const assembleOfferGuarantorPropertyBasicValidation = (
  data: BankingHubCards.OfferGuarantor,
  propertyName: OfferGuarantorProperty,
  error?: BankingHubCards.OfferGuarantorError,
): OfferGuarantorPropertyValidation => {
  return {
    isValid: !!data[propertyName] && !error,
    error,
  };
};

export const MapperOfferGuarantorPropertyToValidationFunction: Record<
  OfferGuarantorProperty,
  (data: BankingHubCards.OfferGuarantor) => OfferGuarantorPropertyValidation
> = {
  [OfferGuarantorProperty.Email]: (data) => {
    const propertyName = OfferGuarantorProperty.Biometry;

    const error = loadOfferGuarantorPropertyError(data, propertyName);

    return assembleOfferGuarantorPropertyBasicValidation(
      data,
      propertyName,
      error,
    );
  },
  [OfferGuarantorProperty.PhoneNumber]: (data) => {
    const propertyName = OfferGuarantorProperty.Biometry;

    const error = loadOfferGuarantorPropertyError(data, propertyName);

    return assembleOfferGuarantorPropertyBasicValidation(
      data,
      propertyName,
      error,
    );
  },
  [OfferGuarantorProperty.Address]: (data) => {
    const propertyName = OfferGuarantorProperty.Biometry;

    const error = loadOfferGuarantorPropertyError(data, propertyName);

    const basicValidation = assembleOfferGuarantorPropertyBasicValidation(
      data,
      propertyName,
      error,
    );

    const proofOfAddress = data.documents?.find(
      (document) => document.type === OfferGuarantorDocumentType.ProofOfAddress,
    );

    const proofOfAddressError = data.errors.find(
      ({ entityId }) => entityId === proofOfAddress?.id,
    );

    if (proofOfAddressError) {
      return {
        isValid: false,
        error: proofOfAddressError,
      };
    }

    return basicValidation;
  },
  [OfferGuarantorProperty.Document]: (data) => {
    const propertyName = OfferGuarantorProperty.Biometry;

    const error = loadOfferGuarantorPropertyError(data, propertyName);

    return assembleOfferGuarantorPropertyBasicValidation(
      data,
      propertyName,
      error,
    );
  },
  [OfferGuarantorProperty.Biometry]: (data) => {
    const propertyName = OfferGuarantorProperty.Biometry;

    const error = loadOfferGuarantorPropertyError(data, propertyName);

    const basicValidation = assembleOfferGuarantorPropertyBasicValidation(
      data,
      propertyName,
      error,
    );

    if (data.biometryStatusType === OfferGuarantorBiometryStatusType.Approved) {
      return {
        isValid: true,
        error: undefined,
      };
    }

    if (data.biometryStatusType === OfferGuarantorBiometryStatusType.Pending) {
      return {
        isValid: false,
        error: {
          propertyName:
            basicValidation.error?.propertyName ?? "biometryStatusType",
          errorMessage:
            basicValidation.error?.errorMessage ?? "Biometria pendente",
          entityId: error?.entityId ?? 0,
        },
      };
    }

    return basicValidation;
  },
};

export const assembleOfferGuarantorDocumentFileErrorMessage = ({
  errorMessage,
}: BankingHubCards.OfferGuarantorDocumentFile): string | undefined => {
  if (!errorMessage) return;

  const description = errorMessage.split(" - ").at(-1);

  if (!description) return;

  const operations: [RegExp, string][] = [
    [/,+/g, ", "],
    [/\n+/g, ". "],
    [/\s\s+/g, " "],
  ];

  return cases.title(
    operations.reduce(
      (output, [searchValue, replaceValue]) =>
        output.replace(searchValue, replaceValue),
      description,
    ),
  );
};

export const validateOfferGuarantorOnboarding = (
  data: BankingHubCards.OfferGuarantor,
) => {
  const { isValid: isValidPhone } =
    MapperOfferGuarantorPropertyToValidationFunction[
      OfferGuarantorProperty.PhoneNumber
    ](data);

  const { isValid: isValidAddress } =
    MapperOfferGuarantorPropertyToValidationFunction[
      OfferGuarantorProperty.Address
    ](data);

  const { isValid: isValidBiometry } =
    MapperOfferGuarantorPropertyToValidationFunction[
      OfferGuarantorProperty.Biometry
    ](data);

  return isValidPhone && isValidAddress && isValidBiometry;
};
