import { isAxiosError } from "axios";
import {
  Guarantor,
  RelationshipsErrorResponse,
} from "./trade-guarantors-grid.types";

export const maritalStatusTypeMap = {
  CASADO: "Casado",
  DIVORCIADO: "Divorciado",
  SEPARADO_JUDICIALMENTE: "Separado judicialmente",
  SOLTEIRO: "Solteiro",
  UNIAO_ESTAVEL: "União estável",
  VIUVO: "Viúvo",
} satisfies Record<HubEnergy.EMaritalStatusType, string>;

/** @deprecated */
export const weddingRegimeTypeMap = {
  COMUNHAO_PARCIAL: "Comunhão parcial",
  COMUNHAO_TOTAL: "Comunhão total",
  SEPARACAO_TOTAL: "Separação total",
} satisfies Record<HubEnergy.EWeddingRegimeType, string>;

export const addErrorsToGuarantors = (
  error: unknown,
  guarantors: Guarantor[],
) => {
  if (!isAxiosError<RelationshipsErrorResponse>(error)) return guarantors;

  error.response?.data.failures.forEach(({ fieldName, errorMessage }) => {
    const indexStr = fieldName.match(/\d+/)?.[0];
    const index = Number(indexStr);
    if (guarantors[index]) {
      guarantors[index].errorMessages = guarantors[index].errorMessages ?? [];
      guarantors[index].errorMessages?.push(errorMessage);
    }
  });

  return guarantors;
};
