import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { workflowConditionssGrid } from "./workflow-conditions.grid";

export const WorkflowConditionsPage = () => {
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de condições" />}
      actions={
        <>
          <RouterButton
            dataAction="condicoes:botao:adicionar"
            dataLabel="adicionar"
            role={roles.workflow.product.conditionsAddPage.role}
            href={corporateRouter.routes.workflow.product.conditions.add.path}
          >
            Adicionar
          </RouterButton>
          <RefreshGridButton
            onClick={() => {
              !workflowConditionssGrid.datagrid.dataSource?.isLoading() &&
                workflowConditionssGrid.datagrid.dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator grid={workflowConditionssGrid} />
    </GridTemplate>
  );
};
