import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { ModalWithSelectOverflow } from "../../../../../../../../../../components/modal-with-select-overflow";
import {
  getPropertyLabel,
  getValidationMessages,
} from "../../../../../../../../../../utils";
import { PendencyTypeSelector } from "../../../../../../../../configuracao-de-diligencia/eventos/_compose";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";
import {
  AddPendencyModalForm,
  addPendencyModalFormDefaultValues,
  addPendencyModalFormFields,
  addPendencyModalFormValidationSchema,
} from "./add-pendency-modal.form";

interface AddPendencyModalProps {
  property: HubEnergy.DiligenceStepPropertySimpleResponse;
}

export const addPendencyModalId = "add-pendency-modal-id";

export const AddPendencyModal = ({ property }: AddPendencyModalProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.stepAnalysisCreatePendency,
  );

  const [value, setValue] = useState<
    | HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyTypeResponse[]>
    | undefined
  >();

  const { stepAnalysis, setStepAnalysis } = useStepAnalysisContext();

  const form = useForm<AddPendencyModalForm>({
    defaultValues: addPendencyModalFormDefaultValues,
    resolver: yupResolver(addPendencyModalFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const pendencyTypeIdWatch = watch("typeId");
  const messageWatch = watch("message");

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, response, error } = await callService({
          diligenceStepAnalysisId: stepAnalysis.id,
          propertyId: property.id,
          typeId: values.typeId,
          message: values.message,
        });

        if (success) {
          OToastManager.success("Pendência adicionada com sucesso");
          setStepAnalysis(response.data.response);
          modalManager.hide(addPendencyModalId);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Erro ao adicionar a pendência",
          );
        }
      }),
    [callService, handleSubmit, property.id, setStepAnalysis, stepAnalysis.id],
  );

  useEffect(() => {
    const cleanup = modalManager.on(addPendencyModalId, "modalClose", () => {
      reset(addPendencyModalFormDefaultValues);
    });
    return cleanup;
  }, [reset]);

  const selectedPendencyType = value?.response?.find(
    (pt) => pt.id === pendencyTypeIdWatch,
  );

  return (
    <ModalWithSelectOverflow
      id={addPendencyModalId}
      style={{ position: "absolute" }}
      position="center"
      backdrop={!loading}
    >
      <OModalHeader>
        <OTypography size="lg">Adicionar pendência</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-4">
          Pendênciar a propriedade <em>{getPropertyLabel(property)}</em>.
        </OTypography>
        <OTypography className="mb-4">
          Texto visto pelo cliente:{" "}
          <em>{messageWatch || selectedPendencyType?.description || ""}</em>
        </OTypography>
        <FormProvider {...form}>
          <PendencyTypeSelector
            {...addPendencyModalFormFields.typeId}
            setValue={setValue}
            valueType="id"
          />
          <ORFieldInput {...addPendencyModalFormFields.message} />
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(addPendencyModalId)}
            disabled={loading}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Adicionar
          </LoadingButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
