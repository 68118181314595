export const disverbationHistoryStatusFileBadgeMap = {
  REJECTED: {
    label: "Rejeitado",
    type: "warning",
  },
  PENDING_APPROVAL: {
    label: "Aguardando aprovação",
    type: "warning",
  },
  SENT: {
    label: "Enviado",
    type: "info",
  },
  APPROVED: {
    label: "Aprovado",
    type: "success",
  },
  ERROR: {
    label: "Erro",
    type: "danger",
  },
} as const;
