import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { toastFailures } from "../../desk-migration-details.utils";

export const useApproveButton = (
  workflowMigrationId: string,
  successCallback: () => void,
) => {
  const { callService: _approve, loading } = useServiceCall(
    service.onboarding.approveDeskMigration,
  );

  const approveDeskMigration = useCallback(async () => {
    const { success, error } = await _approve({
      workflowMigrationId,
    });
    if (success) {
      OToastManager.success("Migração aprovada");
      successCallback();
    } else {
      OToastManager.danger("Erro ao aprovar a migração");
      toastFailures(error);
    }
  }, [_approve, successCallback, workflowMigrationId]);

  return {
    approveDeskMigration,
    loading,
  };
};
