import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { buildCustomerLink } from "utils/build-customer-link";
import {
  createAccountOptinFormDefaultValues,
  createAccountOptinFormValidationSchema,
} from "./create-accounts-optin.form";
import { CreateAccountOptinForm } from "./create-accounts-optin.type";

export const useCreateAccountsOptin = () => {
  const {
    customer: { identification },
  } = useCustomer();

  const form = useForm<CreateAccountOptinForm>({
    defaultValues: createAccountOptinFormDefaultValues,
    resolver: yupResolver(createAccountOptinFormValidationSchema),
  });

  const { handleSubmit } = form;

  const navigate = useNavigate();

  const { callService: workflow, loading } = useServiceCall(
    service.onboardingCoreAccount.workflow,
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const payload = {
          companyTaxId: identification,
          accountCodes: values.createAccountOptin.flatMap((account) => {
            const { accountTemplateCode } = account;
            if (accountTemplateCode !== null) {
              return Array(account.accountQuantity).fill(accountTemplateCode);
            }
            return [];
          }),
        };
        const { success } = await workflow(payload);

        if (success) {
          OToastManager.success("Cadastrado com sucesso");
          navigate(
            buildCustomerLink(
              identification,
              corporateRouter.routes.cadastro.customer.btgAccounts.optin.path,
            ),
          );
        } else OToastManager.danger("Erro para salvar os dados");
      }),
    [handleSubmit, identification, navigate, workflow],
  );

  return {
    onSubmit,
    loading,
    form,
  };
};
