export const parceirosRouteParams = {
  customerId: "customerId",
  proposalId: "proposalId",
  uuid: "uuid",
  operatorProfileIdentifier: "operatorProfileIdentifier",
  operatorIdentification: "operatorIdentification",
  originatorIdentification: "originatorIdentification",
} as const;

type ParceirosParams = Record<keyof typeof parceirosRouteParams, string>;

export type ParceirosProdutoOperatorDetailsRouteParams = Pick<
  ParceirosParams,
  "operatorProfileIdentifier" | "operatorIdentification"
>;

export type ParceirosClienteByCustomerIdRouteParams = Pick<
  ParceirosParams,
  "customerId"
>;

export type ParceirosAgroProdutoByProposalIdRouteParams = Pick<
  ParceirosParams,
  "proposalId"
>;

export type ParceirosOriginatorsDetailsUuidParams = Pick<
  ParceirosParams,
  "uuid"
>;

export interface ParceirosGrupoEconomicoFormRouteQueryParam {
  uuid: string;
  name: string;
  description?: string;
}

export interface ParceirosProdutosFormRouteQueryParam {
  name: string;
  code: string;
  description?: string;
  uuid: string;
}

export interface ParceirosBooksFormRouteQueryParam {
  name: string;
  code: string;
  uuid: string;
}

export interface ParceirosCatalogsFormRouteQueryParam {
  name: string;
  code: string;
  uuid: string;
}

export interface ParceirosLinkFormRouteQueryParam {
  productsUuid: string;
  bookUuid: string;
  catalogsUuid: string;
  uuid: string;
}

export interface ParceirosOriginatorsDetailsRouteQueryParam {
  identification: string;
  officialName: string;
  tradingName: string;
  partnershipType: {
    code: string;
    name: string;
    defaultProfiles: string;
    uuid: string;
  };
  originatorSubTypes: string[];
  remunerationAccount: {
    bank: number;
    agency: number;
    account: number;
    uuid: string;
  };
  id: number;
  uuid: string;
}

export interface ParceirosOriginadoresFormRouteQueryParam {
  id: number;
  uuid: string;
  oficialName: string;
  tradingName: string;
  cge: number;
  identification: string;
  tagCode: string;
  partnershipType: string;
  originatorSubTypes: string;
  indicatorResponse: string;
  CreatedDate: string;
  UpdatedDate: string;
}
