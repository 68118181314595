import { OIcon, OTypography } from "@maestro/react";
import { OfferGuarantorPortalStatus } from "services/bankinghub/models/types/cards";
import { assembleOIconProps } from "../offer-guarantors-list.utils";
import {
  OFFER_GUARANTOR_PORTAL_PENDING_STATUSES,
  OFFER_GUARANTOR_PORTAL_IN_ANALYSIS_STATUSES,
  OFFER_GUARANTOR_PORTAL_IN_REPROVED_STATUSES,
} from "./portal-statuses-resumes.utils";

interface PortalStatusesResumesProps {
  data: OfferGuarantorPortalStatus;
}

export const PortalStatusesResumes = ({ data }: PortalStatusesResumesProps) => {
  return (
    <>
      {OFFER_GUARANTOR_PORTAL_PENDING_STATUSES.includes(data) && (
        <div className="d-flex flex-row gap-2">
          <OIcon
            {...assembleOIconProps({
              type: "warning",
              category: "fa",
              icon: "fa-info-circle",
            })}
          />
          <OTypography type="secondary">Pendências no cadastro</OTypography>
        </div>
      )}
      {OFFER_GUARANTOR_PORTAL_IN_ANALYSIS_STATUSES.includes(data) && (
        <div className="d-flex flex-row gap-2">
          <OIcon {...assembleOIconProps({ type: "warning" })} />
          <OTypography type="secondary">Em análise de dados</OTypography>
        </div>
      )}
      {OFFER_GUARANTOR_PORTAL_IN_REPROVED_STATUSES.includes(data) && (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-row gap-2">
            <OIcon
              {...assembleOIconProps({
                type: "danger",
                category: "orq",
                icon: "orq-status-warning",
              })}
            />
            <OTypography type="secondary">Cadastro reprovado</OTypography>
          </div>
          <div className="d-flex flex-row gap-2">
            <OIcon
              {...assembleOIconProps({
                type: "default",
                category: "fa",
                icon: "fa-info-circle",
              })}
            />
            <OTypography type="secondary">
              Em caso de dúvidas, entre em contato com nosso atendimento
            </OTypography>
          </div>
        </div>
      )}
    </>
  );
};
