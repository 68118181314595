import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  addBookFormDefaultValues,
  addBookFormValidationSchema,
  AddBookFormValues,
} from "./add-book.schema";

export const useAddBook = () => {
  const form = useForm<AddBookFormValues>({
    resolver: yupResolver(addBookFormValidationSchema),
    defaultValues: addBookFormDefaultValues,
  });

  const navigate = useNavigate();

  const { callService: callAddBook, loading: loadingAddBook } = useServiceCall(
    service.hubCreditManager.addBook,
  );

  const addBook = useCallback(
    async ({ bookName, bookCr }: AddBookFormValues) => {
      const { success, error } = await callAddBook({
        bookName,
        bookCr,
      });

      if (success) {
        OToastManager.success("O book foi adicionado com sucesso.");

        navigate(
          corporateRouter.routes.emprestimos.product.contingency
            .booksAndStrategies.path,
        );
      } else if (
        isAxiosError(error) &&
        error.response?.data.errorType === "DbUpdateException"
      ) {
        OToastManager.danger("Já existe um book com este CR.");
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar adicionar o book. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callAddBook, navigate],
  );

  return {
    form,
    addBook,
    loadingAddBook,
  };
};
