import { InfoCardContent } from "../../_compose";
import { InfoCard } from "../../_compose/info-card/info-card.component";
import { TotalOffersPieChart } from "./_compose";

export const TotalOffersPieChartCard = () => {
  return (
    <InfoCard
      summaryType="offersSummary"
      render={(_value) => (
        <InfoCardContent
          header="Quantidade de Ofertas Totais"
          body={
            <TotalOffersPieChart
              availableQuantity={_value.availableQuantity ?? 0}
              hiredQuantity={_value.hiredQuantity ?? 0}
            />
          }
        />
      )}
    />
  );
};
