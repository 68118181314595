import dayjs from "dayjs";

export const parsePeriodDate = (
  date?: [dayjs.Dayjs, dayjs.Dayjs] | [string, string] | [],
) => {
  if (!date) return "";
  return date.map((d) =>
    dayjs.isDayjs(d) ? d.format("YYYY/MM/DD") : dayjs(d).format("YYYY/MM/DD"),
  );
};
