import { OConfirmationModal, OTypography, modalManager } from "@maestro/react";
import { datagridModalFix } from "utils/datagrid-modal-fix";

export const removeStepConfigConfirmationModalId = (id: number) =>
  `remove-step-config-confirmation-modal-${id}`;

export interface RemoveStepConfigConfirmationModalProps {
  stepConfig: HubEnergy.DiligenceStepConfigBaseResponse;
  onConfirm: () => void;
}

export const RemoveStepConfigConfirmationModal = ({
  stepConfig,
  onConfirm,
}: RemoveStepConfigConfirmationModalProps) => {
  const modalId = removeStepConfigConfirmationModalId(stepConfig.id);

  return (
    <OConfirmationModal
      modalId={modalId}
      title="Remover configuração de step"
      divider={false}
      onConfirm={() => {
        onConfirm();
        modalManager.hide(modalId);
      }}
      {...datagridModalFix}
    >
      <OTypography size="md" style={{ whiteSpace: "normal" }}>
        Tem certeza que deseja remover a configuração de step {stepConfig.name}?
      </OTypography>
    </OConfirmationModal>
  );
};
