import { client } from "../client";
import {
  GetAdminAccountcloseBtgAccountsParams,
  PostAdminAccountcloseCancelCloseBody,
  PostAdminAccountcloseCloseBody,
  PostAdminAccountcloseValidateAccountBody,
  PostAdminAccountopeningGetDetailedWorkflowBody,
  PostAdminAccountopeningGetWorkflowsBody,
  PostAdminAccountOpeningReprocessNotificationBody,
  PostAdminAccountopeningVerifyOptinByCodeBody,
  PostDownloadFileAccountCloseBody,
  PostGenerateFileAccountCloseBody,
  PostGetWorkflowDetailsBody,
  PostTestRequesterResponseBody,
  PostTestWorkflowBody,
} from "./models/requests";
import {
  GetAdminAccountcloseBtgAccountsResponse,
  GetAdminAccountcloseReasonsResponse,
  PostAdminAccountcloseValidateAccountResponse,
  PostAdminAccountopeningGetDetailedWorkflowResponse,
  PostAdminAccountopeningGetMasterDetailedWorkflowResponse,
  PostAdminAccountopeningGetWorkflowsResponse,
  PostAdminAccountopeningTemplatesResponse,
  PostAdminAccountopeningVerifyOptinByCodeResponse,
  PostGetWorkflowDetailsResponse,
} from "./models/responses";
import { onboardingCoreAccountEndpoints } from "./onboarding-core-account.endpoints";

export const onboardingCoreAccountService = {
  getAccounts(params?: GetAdminAccountcloseBtgAccountsParams) {
    return client.get<GetAdminAccountcloseBtgAccountsResponse>(
      onboardingCoreAccountEndpoints.admin.accountclose.btgAccounts,
      { params },
    );
  },
  validateAccounts(body: PostAdminAccountcloseValidateAccountBody) {
    return client.post<PostAdminAccountcloseValidateAccountResponse>(
      onboardingCoreAccountEndpoints.admin.accountclose.validateAccount,
      body,
    );
  },
  closeAccounts(body: PostAdminAccountcloseCloseBody) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountclose.close,
      body,
    );
  },
  getAccontCloseReasons() {
    return client.get<GetAdminAccountcloseReasonsResponse>(
      onboardingCoreAccountEndpoints.admin.accountclose.reasons,
    );
  },
  cancelClosing(body: PostAdminAccountcloseCancelCloseBody) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountclose.cancelClose,
      body,
    );
  },
  workflow(body: PostTestWorkflowBody) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountOpening.workflow,
      body,
    );
  },
  requesterResponse(body: PostTestRequesterResponseBody) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountOpening.requesterResponse,
      body,
    );
  },
  getWorkflows(body: PostAdminAccountopeningGetWorkflowsBody) {
    return client.post<PostAdminAccountopeningGetWorkflowsResponse>(
      onboardingCoreAccountEndpoints.admin.accountOpening.getWorkflows,
      body,
    );
  },
  getDetailedWorkflow(body: PostAdminAccountopeningGetDetailedWorkflowBody) {
    return client.post<PostAdminAccountopeningGetDetailedWorkflowResponse>(
      onboardingCoreAccountEndpoints.admin.accountOpening.getDetailedWorkflow,
      body,
    );
  },
  getMasterDetailedWorkflow(
    body: PostAdminAccountopeningGetDetailedWorkflowBody,
  ) {
    return client.post<PostAdminAccountopeningGetMasterDetailedWorkflowResponse>(
      onboardingCoreAccountEndpoints.admin.accountOpening
        .getMasterDetailedWorkflow,
      body,
    );
  },
  reprocessNotificationStatus(
    body: PostAdminAccountOpeningReprocessNotificationBody,
  ) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountOpening.reprocessNotifcation,
      body,
    );
  },
  changeStatusNotificationStatus(
    body: PostAdminAccountOpeningReprocessNotificationBody,
  ) {
    return client.post(
      onboardingCoreAccountEndpoints.admin.accountOpening
        .changeStatusNotifcation,
      body,
    );
  },
  getTemplates() {
    return client.post<PostAdminAccountopeningTemplatesResponse>(
      onboardingCoreAccountEndpoints.admin.accountOpening.templates,
    );
  },
  verifyOptinByCode(body: PostAdminAccountopeningVerifyOptinByCodeBody) {
    return client.post<PostAdminAccountopeningVerifyOptinByCodeResponse>(
      onboardingCoreAccountEndpoints.admin.accountOpening.verifyOptinByCode,
      body,
    );
  },
  getWorkflowDetails(body: PostGetWorkflowDetailsBody) {
    return client.post<PostGetWorkflowDetailsResponse>(
      onboardingCoreAccountEndpoints.admin.accountclose.getWorkflowDetails,
      body,
    );
  },
  generateDeclaration(body: PostGenerateFileAccountCloseBody) {
    return client.post<Blob>(
      onboardingCoreAccountEndpoints.admin.accountclose.generateDeclaration,
      body,
      {
        responseType: "blob",
      },
    );
  },
  downloadDeclaration(body: PostDownloadFileAccountCloseBody) {
    return client.post<Blob>(
      onboardingCoreAccountEndpoints.admin.accountclose.downloadDeclaration,
      body,
      {
        responseType: "blob",
      },
    );
  },
};
