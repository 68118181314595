import { OTypography } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";
import { GoAgainButton } from "../../_compose";
import { useRelocate } from "../../relocate.context";

export const Result = () => {
  const { execute, executionHasError, executionLoading, executionValue } =
    useRelocate();

  return (
    <div className="d-flex flex-column gap-4">
      <ContentTemplate
        loading={executionLoading}
        hasError={executionHasError}
        value={executionValue}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao executar a realocação"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => execute()} />
          </ErrorComponent>
        }
        render={(_value) =>
          _value.status === "ok" ? (
            <div>
              <OTypography size="lg" className="mb-3">
                Realocações enviadas com sucesso
              </OTypography>
              <OTypography>
                Para acompanhar os envios ao CROS, entre na tela de Registros -
                Solicitações.
              </OTypography>
              <OTypography>
                Para visualizar os registros retornados, entre na tela de
                Registros e filtre por modelo de realocação.
              </OTypography>
            </div>
          ) : (
            <div>
              <OTypography size="lg">Enfilerando realocação</OTypography>
            </div>
          )
        }
      />
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <GoAgainButton disabled={executionHasError || executionLoading} />
      </div>
    </div>
  );
};
