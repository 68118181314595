import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  PendencyTypeForm,
  pendencyTypeFormDefaultValues,
  pendencyTypeFormValidationSchema,
} from "../_compose";

export const useAddPendencyType = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createDiligenceStepPendencyType,
  );

  const navigate = useNavigate();

  const form = useForm<PendencyTypeForm>({
    defaultValues: pendencyTypeFormDefaultValues,
    resolver: yupResolver(pendencyTypeFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          description: values.description,
          name: values.name,
          label: values.label,
        });

        if (success) {
          OToastManager.success("Tipo de pendência salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.pendencyTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].errorMessage ??
              "Erro ao salvar tipo de pendência",
          );
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
