import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { GetSemaphoreStepItem } from "services/onboarding/models/responses/get-semaphore-step.response";
import { grid } from "./step-config.grid";

export const SemaphoreStep = () => {
  const { routes } = corporateRouter;

  const dataSource = dataSourceCustomStoreGenerator<GetSemaphoreStepItem>(() =>
    service.onboarding
      .getSemaphoreStepList()
      .then(({ data }) => data)
      .catch((err) => {
        if (!isAxiosError(err)) logger.error(err);
        const errorMessage = "Erro ao buscar os steps de semáforo";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Steps de Semáforo"
          description="Visualizar steps cadastrados para configuração de semáforo"
        />
      }
      actions={
        <RouterButton
          dataAction="ferramentas_configuracao_semaforo_step:botao:adicionar"
          dataLabel="adicionar"
          href={
            routes.ferramentas.product.onboarding.semaphore.configuration.steps
              .add.path
          }
          role={roles.ferramentas.product.semaphoreConfigStepAdd.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
