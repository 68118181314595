import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback } from "react";
import { useDeleteCollectionAgreement } from "./delete-agreement-modal.hook";
import {
  DeleteAgreementModalId,
  DeleteAgreementModalProps,
} from "./delete-agreement-modal.types";

export * from "./delete-agreement-modal.types";

export const DeleteAgreementModal = ({
  agreementId,
}: DeleteAgreementModalProps) => {
  const { deleteAgreement, isLoading } = useDeleteCollectionAgreement();

  const handleConfirm = useCallback(() => {
    deleteAgreement(agreementId);
  }, [agreementId, deleteAgreement]);

  return (
    <OModal
      id={DeleteAgreementModalId}
      position="center"
      size="sm"
      backdrop={!isLoading}
    >
      <OModalHeader closeButton={!isLoading}>
        <OTypography type="dark" size="lg" className="text-nowrap">
          Deseja excluir esse convênio?
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <OTypography type="dark-80">
          Ao confirmar a exclusão, este convênio não estará mais disponível.
          Deseja continuar?
        </OTypography>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            onClick={() => modalManager.hide(DeleteAgreementModalId)}
            disabled={isLoading}
            outline
          >
            Voltar
          </OButton>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            onClick={handleConfirm}
          >
            Excluir convênio
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
