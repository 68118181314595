import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { WorkflowProvider } from "../components/hooks/use-workflow.context";
import { EditConditionPage } from "../pages/produto/condicoes/[id]/editar/edit-condition.page";
import { TestConditionPage } from "../pages/produto/condicoes/[id]/testar/test-condition.page";
import { AddConditionPage } from "../pages/produto/condicoes/adicionar/add-condition.page";
import { WorkflowConditionsPage } from "../pages/produto/condicoes/workflow-conditions.page";
import { CanvasProposalWorkflowPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/canvas/config-proposal-workflow.page";
import { WorkflowConfigHierarchy } from "../pages/produto/configuracoes-de-workflows/[id]/editar/hierarquia/workflow-config-hierarchy.page";
import { EditWorkflowConfigMainData } from "../pages/produto/configuracoes-de-workflows/[id]/editar/main-data";
import { EditOfferPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/ofertas/[id]/edit-offer.page";
import { AddOfferPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/ofertas/adicionar/add-offer.page";
import { WorkflowConfigOffersPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/ofertas/offers.page";
import { ProcessorConfigBasicDataPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/basic-data/processor-config-basic-data.component";
import { ProcessorConfigChannelsPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/channels";
import { ProcessorConfigMonitoringPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/monitoring/processor-config-monitoring-tab.component";
import { ProcessorConfigPropertiesPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/properties";
import { ProcessorConfigSettingsPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/settings";
import { ProcessorConfigUserActionsPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/[id]/user-actions/processor-config-user-actions.component";
import { ProcessorConfigByWorkflowIdPage } from "../pages/produto/configuracoes-de-workflows/[id]/editar/processadores/processor-config.page";
import { ViewBigNumberGrid } from "../pages/produto/configuracoes-de-workflows/[id]/editar/visualizacoes/[workflowConfigViewId]/big-numbers/[viewBigNumberId]/view-big-number-grid.page";
import { WorkflowConfigViewBigNumbers } from "../pages/produto/configuracoes-de-workflows/[id]/editar/visualizacoes/[workflowConfigViewId]/big-numbers/workflow-config-view-big-numbers.component";
import { WorkflowConfigViewEditConfiguration } from "../pages/produto/configuracoes-de-workflows/[id]/editar/visualizacoes/[workflowConfigViewId]/configuration/workflow-config-view-edit-configuration.page";
import { AddWorkflowConfigView } from "../pages/produto/configuracoes-de-workflows/[id]/editar/visualizacoes/adicionar/add-workflow-config-view.page";
import { WorkflowConfigViews } from "../pages/produto/configuracoes-de-workflows/[id]/editar/visualizacoes/workflow-config-views.page";
import { AddProposalWorkflowPage } from "../pages/produto/configuracoes-de-workflows/adicionar/add-proposal-workflow.page";
import { WorkflowsConfigPage } from "../pages/produto/configuracoes-de-workflows/workflows-config.page";
import { UpdateBigNumbersPage } from "../pages/produto/configuracoes/big-numbers/[id]/editar/edit-big-numbers.page";
import { AddBigNumbersPage } from "../pages/produto/configuracoes/big-numbers/adicionar/add-big-numbers.page";
import { WorkflowBigNumbersPage } from "../pages/produto/configuracoes/big-numbers/big-numbers.page";
import { EditCommonDomainPage } from "../pages/produto/configuracoes/common-domain/[id]/edit-common-domain.page";
import { AddCommonDomainPage } from "../pages/produto/configuracoes/common-domain/adicionar/add-common-domain.page";
import { CommonDomainColumnsPage } from "../pages/produto/configuracoes/common-domain/common-domain.page";
import { EditConfigurableColumnsPage } from "../pages/produto/configuracoes/configurable-columns/[id]/edit-configurable-columns.page";
import { AddConfigurableColumnsPage } from "../pages/produto/configuracoes/configurable-columns/adicionar/add-configurable-columns.page";
import { ConfigurableColumnsPage } from "../pages/produto/configuracoes/configurable-columns/configurable-columns.page";
import { EditGridPage } from "../pages/produto/configuracoes/grids/[id]/edit-grid.page";
import { AddGridPage } from "../pages/produto/configuracoes/grids/adicionar/add-grid.page";
import { WorkflowGridsPage } from "../pages/produto/configuracoes/grids/grids.page";
import { EditMinimumCriteriaPage } from "../pages/produto/configuracoes/minimum-criteria/[id]/editar/edit-minimum-criteria.page";
import { AddMinimumCriteriaPage } from "../pages/produto/configuracoes/minimum-criteria/adicionar/add-minimum-criteria.page";
import { MinimumCriteriaColumnsPage } from "../pages/produto/configuracoes/minimum-criteria/minimum-criteria.page";
import { EditStatusPage } from "../pages/produto/configuracoes/status/[id]/editar/edit-configurable-status.page";
import { AddStatusPage } from "../pages/produto/configuracoes/status/adicionar/add-configurable-status.page";
import { WorkflowConfigurableStatusPage } from "../pages/produto/configuracoes/status/configurable-status.page";
import { WorkflowLeadDashboardPage } from "../pages/produto/dashboard/dashboard.page";
import { ExportRequestsPage } from "../pages/produto/exportacoes/lead-exports.page";
import { BannerManagementPage } from "../pages/produto/gerenciamento-de-banners/banner-management.page";
import { BannerTemplatesPage } from "../pages/produto/gerenciamento-de-banners/banner-templates/banner-templates.page";
import { BannerTemplateFormPage } from "../pages/produto/gerenciamento-de-banners/banner-templates/form/banner-templates-form.page";
import { BannerManagementDetailsPage } from "../pages/produto/gerenciamento-de-banners/detalhes";
import { BannerManagementFormPage } from "../pages/produto/gerenciamento-de-banners/form/banner-form.page";
import { EditComponentPage } from "../pages/produto/gestor-de-formularios/componentes/[id]/edit-component.page";
import { AddComponentPage } from "../pages/produto/gestor-de-formularios/componentes/adicionar/add-component.page";
import { ComponentsPage } from "../pages/produto/gestor-de-formularios/componentes/components.page";
import { FormDetailsPage } from "../pages/produto/gestor-de-formularios/formularios/[id]/form-details.page";
import { FormsPage } from "../pages/produto/gestor-de-formularios/formularios/forms.page";
import { WorkflowUpdateContextStatusPage } from "../pages/produto/leads/[id]/contextos/[processorContextId]/workflow-update-context-status.page";
import { WorkflowAddContextPage } from "../pages/produto/leads/[id]/contextos/adicionar/workflow-add-context.page";
import { WorkflowContextsPage } from "../pages/produto/leads/[id]/contextos/workflow-contexts.page";
import { WorkflowContingenciesPage } from "../pages/produto/leads/[id]/contingencias/workflow-contingencies.page";
import { WorkflowLeadDetailsPage } from "../pages/produto/leads/[id]/detalhes/workflow-lead-details.page";
import { WorkflowDocumentPendencyPage } from "../pages/produto/leads/[id]/documentos/[documentId]/workflow-document-pendency.page";
import { WorkflowDocumentsPage } from "../pages/produto/leads/[id]/documentos/workflow-documents.page";
import { WorkflowCreditLinesPage } from "../pages/produto/leads/[id]/linhas-de-credito/workflow-credit-lines.page";
import { WorkflowAddPendencyPage } from "../pages/produto/leads/[id]/pendencias/adicionar/workflow-add-pendency.page";
import { WorkflowPendenciesPage } from "../pages/produto/leads/[id]/pendencias/workflow-pendencies.page";
import { WorkflowCreditlineProposalPage } from "../pages/produto/leads/[id]/propostas/[creditLineProposalId]/workflow-creditline-proposal.page";
import { WorkflowProposalPage } from "../pages/produto/leads/[id]/propostas/workflow-proposals.page";
import { WorkflowUserActionRequestsPage } from "../pages/produto/leads/[id]/solicitacoes/workflow-user-action-requests.page";
import { WorkflowConsumerUnitPage } from "../pages/produto/leads/[id]/unidades-consumidoras/[unitId]/workflow-consumer-unit.page";
import { WorkflowConsumerUnitsPage } from "../pages/produto/leads/[id]/unidades-consumidoras/workflow-consumer-units.page";
import { WorkflowLeadsPage } from "../pages/produto/leads/workflow-leads.page";
import { AddColumnPage } from "../pages/produto/lotes/configuracoes/adicionar/coluna/add-column.page";
import { BatchConfigurableColumnsPage } from "../pages/produto/lotes/configuracoes/configurable-columns.page";
import { BatchRequestContingenciesPage } from "../pages/produto/lotes/enviados/[id]/contingencias/batch-request-contingencies.page";
import { ContingenciesEditPage } from "../pages/produto/lotes/enviados/[id]/contingencias/editar/contingencies-edit.page";
import { BatchRequestDetailsPage } from "../pages/produto/lotes/enviados/[id]/detalhes/batch-request-details.page";
import { BatchRequestWorkflowLeadsPage } from "../pages/produto/lotes/enviados/[id]/leads/batch-request-workflow-leads.page";
import { BatchRequestsPage } from "../pages/produto/lotes/enviados/batch-requests.page";
import { AddTemplatePage } from "../pages/produto/lotes/templates/[id]/adicionar/add-batch-template.page";
import { BatchFileTemplateDetailsPage } from "../pages/produto/lotes/templates/[id]/detalhes/batch-file-template-details.page";
import { BatchFileTemplatesPage } from "../pages/produto/lotes/templates/batch-file-templates.page";
import { EditProcessorPage } from "../pages/produto/processadores/[id]/edit-processor.page";
import { AddProcessorPage } from "../pages/produto/processadores/adicionar/add-processor.page";
import { WorkflowProcessorsPage } from "../pages/produto/processadores/workflow-processors.page";
import { AddResalePage } from "../pages/produto/revendas-parceiros/adicionar/add-resale.page";
import { ResalesPage } from "../pages/produto/revendas-parceiros/resales.page";
import { EditScoreBenefitPage } from "../pages/produto/score-de-originadores/beneficios/[id]/editar/edit-score-benefit.page";
import { AddScoreBenefitPage } from "../pages/produto/score-de-originadores/beneficios/adicionar/add-score-benefit.page";
import { ScoreBenefitsPage } from "../pages/produto/score-de-originadores/beneficios/score-benefits.page";
import { EditScoreModulePage } from "../pages/produto/score-de-originadores/modulos/[id]/editar/edit-score-module.page";
import { AddScoreModulePage } from "../pages/produto/score-de-originadores/modulos/adicionar/add-score-module.page";
import { ScoreModulePage } from "../pages/produto/score-de-originadores/modulos/score-module.page";
import { EntityScoresPage } from "../pages/produto/score-de-originadores/monitor/entity-scores.page";
import { ScoreLevelsPage } from "../pages/produto/score-de-originadores/niveis/score-levels.page";
import { WorkflowsPage } from "../pages/produto/workflows/workflows.page";
import { workflowRoles } from "../roles/workflow.roles";
import { workflowRouteParams } from "./workflow.route-params";
import { BannerManagementSuccessPage } from "../pages/produto/gerenciamento-de-banners/success/success.page";
import { DenyListPage } from "../pages/produto/deny-list/deny-list.page";
import { AddDenyListPage } from "../pages/produto/deny-list/adicionar/add-deny-list.page";
import { RemoveDenyListPage } from "../pages/produto/deny-list/remover/remove-deny-list.page";

export const workflowRoutes = {
  product: {
    name: "",
    path: "/workflow/produto",
    element: <RedirectWithRoles />,
    children: {
      dashboard: {
        name: "Dashboard",
        path: "/workflow/produto/dashboard",
        element: <WorkflowLeadDashboardPage />,
        role: workflowRoles.product.dashboardPage.role,
      },
      workflows: {
        name: "Workflows",
        path: "/workflow/produto/workflows",
        element: <WorkflowsPage />,
        role: workflowRoles.product.workflowsPage.role,
      },
      workflowsConfig: {
        name: "Configurações de Workflows",
        path: "/workflow/produto/configuracoes-de-workflows",
        element: <WorkflowsConfigPage />,
        role: workflowRoles.product.workflowsConfigPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: `/workflow/produto/configuracoes-de-workflows/adicionar`,
            element: <AddProposalWorkflowPage />,
            role: workflowRoles.product.workflowsConfigAddPage.role,
          },
          details: {
            name: "Configurações de Workflows",
            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}`,
            children: {
              edit: {
                name: "Editar",
                path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar`,
                showChildren: true,
                role: workflowRoles.product.workflowsConfigDetailsEditPage.role,
                children: {
                  mainData: {
                    name: "Dados principais",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/dados-principais`,
                    element: <EditWorkflowConfigMainData />,
                    role: workflowRoles.product
                      .workflowsConfigDetailsEditMainDataPage.role,
                  },
                  hierarchy: {
                    name: "Hierarquia",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/hierarquia`,
                    element: <WorkflowConfigHierarchy />,
                    role: workflowRoles.product
                      .workflowsConfigDetailsWorkflowConfigHierarchyPage.role,
                  },
                  views: {
                    name: "Visualizações",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes`,
                    element: <WorkflowConfigViews />,
                    role: workflowRoles.product
                      .workflowsConfigDetailsWorkflowConfigViewsPage.role,
                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes/adicionar`,
                        element: <AddWorkflowConfigView />,
                        role: workflowRoles.product
                          .workflowsConfigDetailsAddWorkflowConfigViewPage.role,
                      },
                      configure: {
                        name: "Configurar",
                        path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes/:${workflowRouteParams.workflowConfigViewId}`,
                        showChildren: true,
                        children: {
                          bigNumbers: {
                            name: "Big numbers",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes/:${workflowRouteParams.workflowConfigViewId}/big-numbers`,
                            element: <WorkflowConfigViewBigNumbers />,
                            children: {
                              grid: {
                                name: "Grid",
                                path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes/:${workflowRouteParams.workflowConfigViewId}/big-numbers/:${workflowRouteParams.viewBigNumberId}`,
                                element: <ViewBigNumberGrid />,
                              },
                            },
                          },
                          defaultGrid: {
                            name: "Configuração",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/visualizacoes/:${workflowRouteParams.workflowConfigViewId}/configuracao`,
                            element: <WorkflowConfigViewEditConfiguration />,
                          },
                        },
                      },
                    },
                  },
                  offers: {
                    name: "Ofertas",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/ofertas`,
                    element: <WorkflowConfigOffersPage />,
                    role: workflowRoles.product.workflowsConfigDetailsOffersPage
                      .role,
                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/ofertas/adicionar`,
                        element: <AddOfferPage />,
                        role: workflowRoles.product
                          .workflowsConfigDetailsOffersAddPage.role,
                      },
                      edit: {
                        name: "Editar",
                        path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/ofertas/:${workflowRouteParams.offerId}`,
                        element: <EditOfferPage />,
                        role: workflowRoles.product
                          .workflowsConfigDetailsOffersEditPage.role,
                      },
                    },
                  },
                  canvas: {
                    name: "Fluxo de configuração",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/canvas`,
                    element: <CanvasProposalWorkflowPage />,
                    role: workflowRoles.product.workflowsConfigDetailsCanvasPage
                      .role,
                  },
                  processor: {
                    name: "Processadores",
                    path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores`,
                    element: <ProcessorConfigByWorkflowIdPage />,
                    role: workflowRoles.product.workflowsConfigProcessorsPage
                      .role,
                    contextProvider: WorkflowProvider,
                    showChildren: true,
                    children: {
                      details: {
                        name: "Detalhes",
                        path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}`,
                        showChildren: true,
                        children: {
                          dataBasic: {
                            name: "Dados Básicos",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/dados-basicos`,
                            element: <ProcessorConfigBasicDataPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsBasicDataPage.role,
                          },
                          monitoring: {
                            name: "Monitoramento",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/monitoramento`,
                            element: <ProcessorConfigMonitoringPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsMonitoringPage
                              .role,
                          },
                          channels: {
                            name: "Canais",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/canais`,
                            element: <ProcessorConfigChannelsPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsChannelsPage.role,
                          },
                          settings: {
                            name: "Configurações",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/configuracoes`,
                            element: <ProcessorConfigSettingsPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsSettingsPage.role,
                          },
                          userActions: {
                            name: "Ações de Usuário",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/acoes-usuario`,
                            element: <ProcessorConfigUserActionsPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsUserActionsPage
                              .role,
                          },
                          properties: {
                            name: "Propriedades",
                            path: `/workflow/produto/configuracoes-de-workflows/:${workflowRouteParams.id}/editar/processadores/:${workflowRouteParams.processorId}/propriedades`,
                            element: <ProcessorConfigPropertiesPage />,
                            role: workflowRoles.product
                              .workflowConfigProcessorDetailsPropertiesPage
                              .role,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      configurations: {
        name: "Configurações",
        path: "/workflow/produto/configuracoes",
        role: workflowRoles.product.configurationsPage.role,
        hasSubItems: true,
        showChildren: true,
        children: {
          grids: {
            name: "Grids",
            path: "/workflow/produto/configuracoes/grids",
            element: <WorkflowGridsPage />,
            role: workflowRoles.product.configurationsGridsPage.role,
            children: {
              add: {
                name: "Adicionar grid",
                path: "/workflow/produto/configuracoes/grids/adicionar",
                element: <AddGridPage />,
                role: workflowRoles.product.configurationsAddGridPage.role,
              },
              edit: {
                name: "Editar grid",
                path: `/workflow/produto/configuracoes/grids/:${workflowRouteParams.gridId}`,
                element: <EditGridPage />,
                role: workflowRoles.product.configurationsEditGridPage.role,
              },
            },
          },
          bigNumbers: {
            name: "Big Numbers",
            path: "/workflow/produto/configuracoes/big-numbers",
            element: <WorkflowBigNumbersPage />,
            role: workflowRoles.product.configurationsBigNumbersPage.role,
            children: {
              add: {
                name: "Adicionar Big Number",
                path: "/workflow/produto/configuracoes/big-numbers/adicionar",
                element: <AddBigNumbersPage />,
                role: workflowRoles.product.configurationsAddBigNumberPage.role,
              },
              update: {
                name: "Editar Big Number",
                path: `/workflow/produto/configuracoes/big-numbers/:${workflowRouteParams.id}`,
                element: <UpdateBigNumbersPage />,
                role: workflowRoles.product.configurationsUpdateBigNumberPage
                  .role,
              },
            },
          },
          configurableColumns: {
            name: "Colunas configuráveis",
            path: "/workflow/produto/configuracoes/colunas-configuraveis",
            element: <ConfigurableColumnsPage />,
            role: workflowRoles.product.configurationsConfigurableColumnsPage
              .role,
            children: {
              create: {
                name: "Adicionar",
                path: "/workflow/produto/configuracoes/colunas-configuraveis/adicionar",
                element: <AddConfigurableColumnsPage />,
                role: workflowRoles.product
                  .configurationsCreateConfigurableColumnsPage.role,
              },
              update: {
                name: "Editar",
                path: `/workflow/produto/configuracoes/colunas-configuraveis/:${workflowRouteParams.id}`,
                element: <EditConfigurableColumnsPage />,
                role: workflowRoles.product
                  .configurationsUpdateConfigurableColumnsPage.role,
              },
            },
          },
          minimumCriteria: {
            name: "Critérios Mínimos",
            path: "/workflow/produto/configuracoes/criterios-minimos",
            element: <MinimumCriteriaColumnsPage />,
            role: workflowRoles.product.configurationsMinimumCriteriaPage.role,
            children: {
              add: {
                name: "Adicionar critérios mínimos",
                path: "/workflow/produto/configuracoes/criterios-minimos/adicionar",
                element: <AddMinimumCriteriaPage />,
                role: workflowRoles.product
                  .configurationsCreateMinimumCriteriaPage.role,
              },
              edit: {
                name: "Editar",
                path: `/workflow/produto/configuracoes/criterios-minimos/:${workflowRouteParams.id}`,
                element: <EditMinimumCriteriaPage />,
                role: workflowRoles.product
                  .configurationsEditMinimumCriteriaPage.role,
              },
            },
          },
          status: {
            name: "Status",
            path: "/workflow/produto/configuracoes/status",
            element: <WorkflowConfigurableStatusPage />,
            role: workflowRoles.product.configurationsStatusPage.role,
            children: {
              create: {
                name: "Adicionar status",
                path: "/workflow/produto/configuracoes/status/adicionar",
                element: <AddStatusPage />,
                role: workflowRoles.product.configurationsAddBigNumberPage.role,
              },
              update: {
                name: "Editar",
                path: `/workflow/produto/configuracoes/status/:${workflowRouteParams.id}`,
                element: <EditStatusPage />,
                role: workflowRoles.product.configurationsUpdateStatusPage.role,
              },
            },
          },
          commonDomain: {
            name: "Common Domain",
            path: "/workflow/produto/configuracoes/common-domain",
            element: <CommonDomainColumnsPage />,
            role: workflowRoles.product.configurationsMinimumCriteriaPage.role,
            children: {
              create: {
                name: "Adicionar Common Domain",
                path: "/workflow/produto/configuracoes/common-domain/adicionar",
                element: <AddCommonDomainPage />,
                role: workflowRoles.product.configurationsCreateCommonDomainPage
                  .role,
              },
              update: {
                name: "Editar",
                path: `/workflow/produto/configuracoes/common-domain/:${workflowRouteParams.id}`,
                element: <EditCommonDomainPage />,
                role: workflowRoles.product.configurationsUpdateCommonDomainPage
                  .role,
              },
            },
          },
        },
      },
      bannerManagement: {
        name: "Gerenciamento de campanhas",
        path: "/workflow/produto/banner-management",
        element: <BannerManagementPage />,
        role: workflowRoles.product.campaignsPage.role,
        showChildren: false,
        children: {
          edit: {
            name: "Editar",
            path: `/workflow/produto/banner-management/:${workflowRouteParams.id}`,
            element: <BannerManagementFormPage />,
            role: workflowRoles.product.editCampaignPage.role,
          },
          details: {
            name: "Detalhes",
            path: `/workflow/produto/banner-management/detalhes/:${workflowRouteParams.id}`,
            element: <BannerManagementDetailsPage />,
            role: workflowRoles.product.campaignsPage.role,
          },
          success: {
            name: "Sucesso",
            path: `/workflow/produto/banner-management/sucesso`,
            element: <BannerManagementSuccessPage />,
            role: workflowRoles.product.editCampaignPage.role,
          },
          bannerTemplates: {
            name: "Templates de campanha",
            path: "/workflow/produto/banner-management/templates",
            element: <BannerTemplatesPage />,
            role: workflowRoles.product.campaignTemplatesPage.role,
            showChildren: false,
            children: {
              add: {
                name: "Adicionar",
                path: `/workflow/produto/banner-management/templates/adicionar`,
                element: <BannerTemplateFormPage />,
                role: workflowRoles.product.createCampaignTemplatePage.role,
              },
              edit: {
                name: "Editar",
                path: `/workflow/produto/banner-management/templates/editar/:${workflowRouteParams.id}`,
                element: <BannerTemplateFormPage />,
                role: workflowRoles.product.editCampaignTemplatePage.role,
              },
            },
          },
        },
      },
      processors: {
        name: "Processadores",
        path: "/workflow/produto/processadores",
        element: <WorkflowProcessorsPage />,
        role: workflowRoles.product.processorsPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: `/workflow/produto/processadores/adicionar`,
            element: <AddProcessorPage />,
            role: workflowRoles.product.processorsAddPage.role,
          },
          edit: {
            name: "Editar",
            path: `/workflow/produto/processadores/:${workflowRouteParams.id}`,
            element: <EditProcessorPage />,
            role: workflowRoles.product.processorsEditPage.role,
          },
        },
      },
      conditions: {
        name: "Condições",
        path: "/workflow/produto/condicoes",
        element: <WorkflowConditionsPage />,
        role: workflowRoles.product.conditionsPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: `/workflow/produto/condicoes/adicionar`,
            element: <AddConditionPage />,
            role: workflowRoles.product.conditionsAddPage.role,
          },
          details: {
            name: "Condição",
            path: `/workflow/produto/condicoes/:${workflowRouteParams.id}`,
            children: {
              edit: {
                name: "Editar",
                path: `/workflow/produto/condicoes/:${workflowRouteParams.id}/editar`,
                element: <EditConditionPage />,
                role: workflowRoles.product.conditionsDetailsEditPage.role,
              },
              test: {
                name: "Testar",
                path: `/workflow/produto/condicoes/:${workflowRouteParams.id}/testar`,
                element: <TestConditionPage />,
                role: workflowRoles.product.conditionsDetailsTestPage.role,
              },
            },
          },
        },
      },
      leads: {
        name: "Leads",
        path: "/workflow/produto/leads",
        element: <WorkflowLeadsPage />,
        role: workflowRoles.product.leadsPage.role,
        children: {
          details: {
            name: "Lead",
            path: `/workflow/produto/leads/:${workflowRouteParams.id}`,
            showChildren: true,
            children: {
              summary: {
                name: "Detalhes",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/detalhes`,
                element: <WorkflowLeadDetailsPage />,
                role: workflowRoles.product.leadsDetailsSummaryPage.role,
              },
              creditLines: {
                name: "Linhas de crédito",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/linhas-de-credito`,
                element: <WorkflowCreditLinesPage />,
                role: workflowRoles.product.leadsDetailsCreditLinesPage.role,
              },
              proposals: {
                name: "Propostas",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/propostas`,
                element: <WorkflowProposalPage />,
                role: workflowRoles.product.leadsDetailsProposalsPage.role,
                children: {
                  details: {
                    name: "Detalhes",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/propostas/:${workflowRouteParams.proposedCreditLineId}`,
                    element: <WorkflowCreditlineProposalPage />,
                    role: workflowRoles.product.leadsDetailsProposalsDetailsPage
                      .role,
                  },
                },
              },
              consumerUnits: {
                name: "Unidades consumidoras",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/unidades-consumidoras`,
                element: <WorkflowConsumerUnitsPage />,
                role: workflowRoles.product.leadsDetailsConsumerUnitsPage.role,
                children: {
                  details: {
                    name: "Detalhes",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/unidades-consumidoras/:${workflowRouteParams.unitId}`,
                    element: <WorkflowConsumerUnitPage />,
                    role: workflowRoles.product
                      .leadsDetailsConsumerUnitsDetailsPage.role,
                  },
                },
              },
              documents: {
                name: "Documentos",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/documentos`,
                element: <WorkflowDocumentsPage />,
                role: workflowRoles.product.leadsDetailsDocumentsPage.role,
                children: {
                  pendency: {
                    name: "Pendenciar",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/documentos/:${workflowRouteParams.documentId}`,
                    element: <WorkflowDocumentPendencyPage />,
                    role: workflowRoles.product
                      .leadsDetailsDocumentsPendencyPage.role,
                  },
                },
              },
              pendencies: {
                name: "Pendências",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/pendencias`,
                element: <WorkflowPendenciesPage />,
                role: workflowRoles.product.leadsDetailsPendenciesPage.role,
                children: {
                  add: {
                    name: "Adicionar",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/pendencias/adicionar`,
                    element: <WorkflowAddPendencyPage />,
                    role: workflowRoles.product.leadsDetailsPendenciesAddPage
                      .role,
                  },
                },
              },
              contexts: {
                name: "Contextos",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/contextos`,
                element: <WorkflowContextsPage />,
                role: workflowRoles.product.leadsDetailsContextsPage.role,

                children: {
                  updateStatus: {
                    name: "Atualizar status",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/contextos/:${workflowRouteParams.processorContextId}/:${workflowRouteParams.processorId}`,
                    element: <WorkflowUpdateContextStatusPage />,
                    role: workflowRoles.product
                      .leadsDetailsContextsUpdateStatusPage.role,
                  },
                  add: {
                    name: "Adicionar",
                    path: `/workflow/produto/leads/:${workflowRouteParams.id}/contextos/adicionar`,
                    element: <WorkflowAddContextPage />,
                    role: workflowRoles.product.leadsDetailsContextsAddPage
                      .role,
                  },
                },
              },
              userActionRequests: {
                name: "Solicitações",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/solicitacoes`,
                element: <WorkflowUserActionRequestsPage />,
                role: workflowRoles.product.leadsDetailsUserActionRequestsPage
                  .role,
              },
              contingencies: {
                name: "Contingências",
                path: `/workflow/produto/leads/:${workflowRouteParams.id}/contingencias`,
                element: <WorkflowContingenciesPage />,
                role: workflowRoles.product.leadsDetailsUserActionRequestsPage
                  .role,
              },
            },
          },
        },
      },
      resales: {
        name: "Revendas/Parceiros",
        path: "/workflow/produto/revendas-parceiros",
        element: <ResalesPage />,
        role: workflowRoles.product.resalesPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: "/workflow/produto/revendas-parceiros/adicionar",
            element: <AddResalePage />,
            role: workflowRoles.product.resalesAddPage.role,
          },
        },
      },
      formsManager: {
        name: "Gestor de formulários",
        path: "/workflow/produto/gestor-de-formularios",
        hasSubItems: true,
        children: {
          forms: {
            name: "Formulários",
            path: "/workflow/produto/gestor-de-formularios/formularios",
            element: <FormsPage />,
            role: workflowRoles.product.formsManagerFormsPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: `/workflow/produto/gestor-de-formularios/formularios/:${workflowRouteParams.id}`,
                element: <FormDetailsPage />,
                role: workflowRoles.product.formsManagerFormsDetailsPage.role,
              },
            },
          },
          components: {
            name: "Componentes",
            path: "/workflow/produto/gestor-de-formularios/componentes",
            element: <ComponentsPage />,
            role: workflowRoles.product.formsManagerComponentsPage.role,
            children: {
              add: {
                name: "Adicionar",
                path: `/workflow/produto/gestor-de-formularios/componentes/adicionar`,
                element: <AddComponentPage />,
                role: workflowRoles.product.formsManagerComponentsAddPage.role,
              },
              edit: {
                name: "Editar",
                path: `/workflow/produto/gestor-de-formularios/componentes/:${workflowRouteParams.id}`,
                element: <EditComponentPage />,
                role: workflowRoles.product.formsManagerComponentsEditPage.role,
              },
            },
          },
        },
      },
      batchs: {
        name: "Lotes",
        path: "/workflow/produto/lotes",
        hasSubItems: true,
        children: {
          batchRequests: {
            name: "Lotes enviados",
            path: "/workflow/produto/lotes/enviados",
            element: <BatchRequestsPage />,
            role: workflowRoles.product.batchRequestsPage.role,
            showChildren: true,
            children: {
              summary: {
                name: "Detalhes",
                path: `/workflow/produto/lotes/enviados/:${workflowRouteParams.id}/detalhes`,
                element: <BatchRequestDetailsPage />,
                role: workflowRoles.product.batchRequestsDetailsSummaryPage
                  .role,
              },
              leads: {
                name: "Leads",
                path: `/workflow/produto/lotes/enviados/:${workflowRouteParams.id}/leads`,
                element: <BatchRequestWorkflowLeadsPage />,
                role: workflowRoles.product.batchRequestsLeadsPage.role,
              },
              contigencies: {
                name: "Contingencias",
                path: `/workflow/produto/lotes/enviados/:${workflowRouteParams.id}/contigencies`,
                element: <BatchRequestContingenciesPage />,
                role: workflowRoles.product.batchRequestsContingencyPage.role,
                children: {
                  statusEdit: {
                    name: "Editar",
                    path: `/workflow/produto/lotes/enviados/:${workflowRouteParams.id}/contigencies/editar`,
                    role: workflowRoles.product.batchRequestsContingencyEditPage
                      .role,
                    element: <ContingenciesEditPage />,
                  },
                },
              },
            },
          },
          batchTemplates: {
            name: "Templates",
            path: "/workflow/produto/lotes/templates",
            element: <BatchFileTemplatesPage />,
            role: workflowRoles.product.batchTemplatePage.role,
            children: {
              summary: {
                name: "Detalhes",
                path: `/workflow/produto/lotes/templates/:${workflowRouteParams.id}/detalhes`,
                element: <BatchFileTemplateDetailsPage />,
                role: workflowRoles.product.batchTemplatesDetailsSummaryPage
                  .role,
              },
              addTemplate: {
                name: "Adicionar Template",
                path: `/workflow/produto/lotes/templates/adicionar-template`,
                element: <AddTemplatePage />,
                role: workflowRoles.product.batchTemplatesAddPage.role,
              },
            },
          },
          configuration: {
            name: "Configuração",
            path: `/workflow/produto/lotes/configuracao-template`,
            element: <BatchConfigurableColumnsPage />,
            role: workflowRoles.product.batchTemplatesConfigurationsPage.role,
            children: {
              add: {
                name: "Adicionar",
                path: `/workflow/produto/lotes/configuracao-template/adicionar`,
                children: {
                  column: {
                    name: "Template",
                    path: `/workflow/produto/lotes/configuracao-template/adicionar/template`,
                    element: <AddColumnPage />,
                    role: workflowRoles.product.batchConfigurationsAddColumnPage
                      .role,
                  },
                },
              },
            },
          },
        },
      },
      exportRequests: {
        name: "Exportações",
        path: "/workflow/produto/exportacoes",
        element: <ExportRequestsPage />,
        role: workflowRoles.product.exportRequestsPage.role,
      },
      originatorScore: {
        name: "Score de originadores",
        path: "/workflow/produto/score-originadores",
        role: workflowRoles.product.originatorScorePage.role,
        hasSubItems: true,
        showChildren: true,
        children: {
          monitor: {
            name: "Monitor",
            path: "/workflow/produto/score-originadores/monitor",
            element: <EntityScoresPage />,
            role: workflowRoles.product.originatorScoreMonitorPage.role,
          },
          modules: {
            name: "Módulos",
            path: "/workflow/produto/score-originadores/modulos",
            element: <ScoreModulePage />,
            role: workflowRoles.product.originatorScoreModulePage.role,
            children: {
              add: {
                name: "Adicionar módulo",
                path: "/workflow/produto/score-originadores/modulos/adicionar",
                element: <AddScoreModulePage />,
                role: workflowRoles.product.originatorAddScoreModulePage.role,
              },
              edit: {
                name: "Editar módulo",
                path: `/workflow/produto/score-originadores/modulos/:${workflowRouteParams.scoreModuleId}`,
                element: <EditScoreModulePage />,
                role: workflowRoles.product.originatorEditScoreModulePage.role,
              },
            },
          },
          benefits: {
            name: "Benefícios",
            path: "/workflow/produto/score-originadores/beneficios",
            element: <ScoreBenefitsPage />,
            role: workflowRoles.product.originatorScoreBenefitPage.role,
            children: {
              add: {
                name: "Adicionar benefícios",
                path: "/workflow/produto/score-originadores/beneficios/adicionar",
                element: <AddScoreBenefitPage />,
                role: workflowRoles.product.originatorAddScoreBenefitPage.role,
              },
              edit: {
                name: "Editar benefícios",
                path: `/workflow/produto/score-originadores/beneficios/:${workflowRouteParams.scoreBenefitId}`,
                element: <EditScoreBenefitPage />,
                role: workflowRoles.product.originatorEditScoreBenefitPage.role,
              },
            },
          },
          levels: {
            name: "Níveis",
            path: "/workflow/produto/score-originadores/niveis",
            element: <ScoreLevelsPage />,
            role: workflowRoles.product.originatorScoreLevelPage.role,
          },
        },
      },
      denyList: {
        name: "Lista de Bloqueios",
        path: "/workflow/produto/lista-bloqueios",
        role: workflowRoles.product.legalEntityDenialPage.role,
        element: <DenyListPage />,
        children: {
          add: {
            name: "Adicionar cliente",
            path: "/workflow/produto/lista-bloqueios/adicionar",
            element: <AddDenyListPage />,
            role: workflowRoles.product.legalEntityDenialAddPage.role,
          },
          edit: {
            name: "Remover cliente",
            path: `/workflow/produto/lista-bloqueios/remover/:${workflowRouteParams.denyListId}`,
            element: <RemoveDenyListPage />,
            role: workflowRoles.product.legalEntityDenialEditPage.role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
