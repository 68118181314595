import { masks } from "@maestro/utils";
import { maskIdentificationByCountry } from "utils/mask";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { AdminPayeesODataValueItem } from "services/quickfin/models";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { AssignorsMasterDetails } from "./_compose";
import {
  calculateOnboardingStatusFilter,
  contractStatusMap,
  onboardingStatusMap,
  productTypeMap,
} from "./assignors.utils";

export const grid: ODataGridGeneratorConfig<AdminPayeesODataValueItem> = {
  datagrid: {
    exportGrid: { fileName: "Cedentes.xlsx" },
    noDataText: "Nenhum dado encontrado",
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      cellRender: ({ data }) => {
        if (!data.PayeeIdentification) return;
        const country =
          data.PayeeCountry && data.PayeeCountry !== "BR"
            ? data.PayeeCountry
            : "";
        const identification = country + data.PayeeIdentification;
        return (
          <DetailsLink
            href={buildCustomerLink(
              identification,
              corporateRouter.routes.recebiveis.customer.path,
            )}
          />
        );
      },
    },
    {
      dataField: "PayeeName",
      caption: "Cedente",
    },
    {
      dataField: "PayeeIdentification",
      caption: "Identificação Cedente",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      cellRender: ({ data }) => {
        return maskIdentificationByCountry(
          data.PayeeIdentification,
          data.PayeeCountry,
        );
      },
    },
    {
      dataField: "PayeeCountry",
      caption: "País do Cedente",
    },
    {
      dataField: "PayerName",
      caption: "Sacado",
    },
    {
      dataField: "PayerIdentification",
      caption: "Identificação Sacado",
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "ProductType",
      caption: "Tipo de produto",
      calculateCellValue: ({ ProductType }) => ProductType,
      cellRender: ({ data }) =>
        getValueFromMap(productTypeMap, data.ProductType),
      lookup: {
        dataSource: Object.entries(productTypeMap).map(([value, label]) => ({
          value,
          label,
        })),
        valueExpr: "value",
        displayExpr: "label",
      },
    },
    { dataField: "OnboardingType", caption: "Tipo de onboarding" },
    {
      dataField: "OnboardingStatus",
      caption: "Onboarding Status",
      calculateCellValue: ({ OnboardingStatus }) => {
        const statusKeys = Object.keys(onboardingStatusMap);
        const findValue = statusKeys.find((key: string) =>
          onboardingStatusMap[key].includes(OnboardingStatus),
        );

        return findValue ?? OnboardingStatus;
      },
      calculateFilterExpression: calculateOnboardingStatusFilter,
      lookup: {
        dataSource: Object.keys(onboardingStatusMap),
      },
    },
    {
      dataField: "OnboardingUpdatedAt",
      caption: "Onboarding atualizado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      dataField: "CommercialStrategyName",
      caption: "Estratégia comercial",
    },
    {
      dataField: "CompanyCreatedAt",
      caption: "Criado em",
      dataType: "datetime",
      format: "shortDateShortTime",
    },
    {
      dataField: "ContractStatus",
      caption: "Status Termo",
      calculateCellValue: ({ ContractStatus }) => ContractStatus,
      cellRender: ({ data }) =>
        getValueFromMap(contractStatusMap, data.ContractStatus) ??
        data.ContractStatus,
      lookup: {
        dataSource: Object.entries(contractStatusMap).map(([value, label]) => ({
          label,
          value,
        })),
        displayExpr: "label",
        valueExpr: "value",
      },
    },
    {
      dataField: "IsActive",
      caption: "Ativo",
    },
  ],
  masterDetail: {
    enabled: true,
    component: AssignorsMasterDetails,
  },
};
