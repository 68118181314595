import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { isAxiosError } from "axios";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { logger } from "utils/logger";
import { EmailConfig } from "./auto-emails.types";
import { emailTypeMap } from "./auto-emails.utils";

export const dataSource = dataSourceCustomStoreGenerator<EmailConfig>(
  (loadOptions) => {
    // serializer workaround
    // https://github.com/axios/axios/issues/5094#issuecomment-1274863322
    if (Array.isArray(loadOptions.sort)) {
      // eslint-disable-next-line no-param-reassign
      loadOptions.sort = loadOptions.sort.map((option) =>
        JSON.stringify(option),
      );
    }
    return service.recebiveisCartao
      .getEmailConfigs(loadOptions as any)
      .then(({ data }) => ({
        data: data.data,
        totalCount: data.total_count,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar as configurações de email";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  },
  {
    customStoreOptions: {
      update: async (
        { id },
        { send_to_ol, send_to_operadores, send_to_representante_legal },
      ) => {
        try {
          await service.recebiveisCartao.editEmailConfig(id, {
            email_config: {
              send_to_ol,
              send_to_operadores,
              send_to_representante_legal,
            },
          });
          OToastManager.success("Configuração editada com sucesso");
        } catch (err) {
          if (!isAxiosError(err)) logger.error(err);
          OToastManager.danger("Não foi possível editar a configuração");
        }
      },
      remove: async ({ id }) => {
        try {
          await service.recebiveisCartao.removeEmailConfig(id);
          OToastManager.success("Configuração removida com sucesso");
        } catch (err) {
          if (!isAxiosError(err)) logger.error(err);
          OToastManager.danger("Não foi possível remover a configuração");
        }
      },
    },
  },
);

export const autoEmailsGrid: ODataGridGeneratorConfig<EmailConfig> = {
  datagrid: {
    noDataText: "Nenhuma configuração de email.",
    filterRow: {
      visible: true,
    },
    editing: {
      allowDeleting: true,
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
    remoteOperations: true,
    onRowUpdated: () => dataSource.reload(),
    onRowRemoved: () => dataSource.reload(),
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      allowEditing: false,
    },
    {
      dataField: "company_name",
      dataType: "string",
      caption: "Empresa",
      allowEditing: false,
    },
    {
      dataField: "company_cnpj",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowEditing: false,
    },
    {
      dataField: "send_to_ol",
      dataType: "boolean",
      caption: "Enviar para OL",
    },
    {
      dataField: "send_to_representante_legal",
      dataType: "boolean",
      caption: "Enviar para representante legal",
    },
    {
      dataField: "send_to_operadores",
      dataType: "boolean",
      caption: "Enviar para operadores",
    },
    {
      dataField: "email_type",
      dataType: "string",
      caption: "Tipo do e-mail",
      format: (value) => getValueFromMap(emailTypeMap, value) || value,
      allowEditing: false,
    },
  ],
};
