import { logger } from "utils/logger";
import { safeLocalStorage } from "utils/storage";
import { CustomerSelectorItem } from "../customer-selector.types";

const favoriteCustomersStorageKey = "corporate:favorite-customers";

/** Loads the favorites array from localStorage */
export const loadFavoritesFromStorage = () => {
  const favoritesString = safeLocalStorage.getItem(favoriteCustomersStorageKey);

  if (!favoritesString) return [];

  try {
    const favorites = JSON.parse(favoritesString) as CustomerSelectorItem[];
    return favorites;
  } catch (err) {
    logger.error(err);
    return [];
  }
};

export const saveFavoritesToStorage = (favorites: CustomerSelectorItem[]) => {
  safeLocalStorage.setItem(
    favoriteCustomersStorageKey,
    JSON.stringify(favorites),
  );
};
