import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { SacadoTierConfig } from "./sacado-tier.types";
import { productsMap } from "./sacado-tier.utils";

export const dataSource = dataSourceCustomStoreGenerator<SacadoTierConfig>(
  () =>
    service.quickfin
      .getSacadoTierConfigs()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage =
          "Erro ao buscar as configurações de tier mínimo de sacado";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      update: async (original, updated) => {
        try {
          await service.quickfin.editSacadoTierConfig(original.id, {
            ...original,
            ...updated,
            minimumTier: String(updated.minimumTier ?? original.minimumTier),
          });
          OToastManager.success("Configuração editada com sucesso");
        } catch {
          const errorMessage = "Erro ao editar a configuração";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
      remove: async ({ id }) => {
        try {
          await service.quickfin.removeSacadoTierConfig(id);
          OToastManager.success("Configuração removida com sucesso");
        } catch {
          const errorMessage = "Erro ao remover a configuração";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    },
  },
);

export const sacadoTierConfigsGrid: ODataGridGeneratorConfig<SacadoTierConfig> =
  {
    datagrid: {
      noDataText: "Nenhuma configuração.",
      filterRow: { visible: true },
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        mode: "row",
        selectTextOnEditStart: true,
        texts: {
          confirmDeleteMessage:
            "Tem certeza que deseja remover esta configuração?",
        },
      },
    },
    columns: [
      {
        dataField: "sacadoName",
        dataType: "string",
        caption: "Sacado",
        allowEditing: false,
      },
      {
        dataField: "sacadoIdentification",
        dataType: "string",
        caption: "CNPJ",
        format: (value) => masks.cnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowEditing: false,
      },
      {
        dataField: "minimumTier",
        dataType: "number",
        caption: "Tier mínimo",
        alignment: "center",
        validationRules: [{ type: "required" }, { type: "numeric" }],
      },
      {
        dataField: "tipoProduto",
        dataType: "string",
        caption: "Tipo do produto",
        lookup: {
          dataSource: Object.entries(productsMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "createdAt",
        dataType: "datetime",
        format: "shortDate",
        caption: "Data de criação",
        allowEditing: false,
      },
      {
        dataField: "updatedAt",
        dataType: "datetime",
        format: "shortDate",
        caption: "Última atualização",
        allowEditing: false,
      },
    ],
  };
