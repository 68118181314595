import { ODataGridGeneratorConfig } from "components/data-grid";
import { ProcessorOutput } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { ConfigurableStatusBadge } from "./configurable-status-badge.component";
import { OutputsGridActions } from "./outputs-grid-actions.component";

export const outputsGrid = {
  datagrid: {
    noDataText: "Nenhuma saída configurada",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "executeOnStart",
      caption: "Saída inicial?",
    },
    {
      dataField: "contextStatusOnOutput",
      caption: "Status do contexto após saída",
    },
    {
      dataField: "configurableStatus",
      caption: "Status da saída",
      cellRender: ({ data }) =>
        data.configurableStatus && (
          <ConfigurableStatusBadge {...data.configurableStatus} />
        ),
      alignment: "center",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => <OutputsGridActions data={data} />,
    },
  ],
} satisfies ODataGridGeneratorConfig<ProcessorOutput>;
