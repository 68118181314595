import {
  OButton,
  ODropdown,
  ODropdownItem,
  ODropdownList,
  OIcon,
  OTypography,
} from "@maestro/react";
import { useBankslipContext } from "../../bankslip.context";

export const PaginationGrid = () => {
  const {
    pageNumber,
    handlePageNumberChange,
    pageSize,
    handlePageSizeChange,
    hasNextPage,
  } = useBankslipContext();

  return (
    <div className="d-flex justify-content-between align-items-center pt-3">
      <div className="d-flex align-items-center gap-2">
        <ODropdown positionY="top">
          <OButton type="light" dataAction="" dataLabel="">
            <div className="d-flex align-items-center gap-1">
              <OTypography type="dark">{pageSize}</OTypography>
              <OIcon category="orq" icon="orq-chevron-up" />
            </div>
          </OButton>

          <ODropdownList minWidth="70px">
            {[10, 20, 50, 100].map((size: number) => {
              return (
                <ODropdownItem
                  key={size}
                  onClick={() => handlePageSizeChange(size)}
                >
                  <OTypography type="dark">{size}</OTypography>
                </ODropdownItem>
              );
            })}
          </ODropdownList>
        </ODropdown>

        <OTypography type="dark">Linhas por página</OTypography>
      </div>

      <div className="d-flex flex-row gap-2">
        <div className="d-flex flex-row gap-1">
          <OButton
            size="sm"
            type="light"
            disabled={pageNumber === 1}
            onClick={() => handlePageNumberChange(pageNumber - 1)}
          >
            <OIcon category="orq" icon="orq-chevron-left" />
          </OButton>

          <OButton size="sm" type="tertiary">
            {pageNumber}
          </OButton>

          <OButton
            size="sm"
            type="light"
            disabled={!hasNextPage}
            onClick={() => handlePageNumberChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </OButton>

          <OButton
            size="sm"
            type="light"
            disabled={!hasNextPage}
            onClick={() => handlePageNumberChange(pageNumber + 1)}
          >
            <OIcon category="orq" icon="orq-chevron-right" />
          </OButton>
        </div>
      </div>
    </div>
  );
};
