import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { gridStorage } from "utils/storage";
import { highlightRowsWithError } from "./spreadsheet-data.utils";

export const spreadsheetDataGrid: ODataGridGeneratorConfig<HubEnergy.LegacyImport.SpreadsheetDataWithValidation> =
  {
    datagrid: {
      noDataText: "Nenhuma unidade consumidora",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("mle-spreadsheetData"),
      onRowPrepared: highlightRowsWithError,
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "groupingId",
        caption: "Id agrupamento",
        sortOrder: "asc",
        sortIndex: 0,
      },
      {
        dataField: "taxId",
        caption: "CNPJ unidade consumidora",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        dataField: "installationNumber",
        caption: "Número de instalação",
      },
      {
        dataField: "quoteId",
        caption: "Id cotação",
      },
      {
        dataField: "customerTaxId",
        caption: "CNPJ matriz",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        dataField: "distributorTaxId",
        caption: "CNPJ distribuidora",
        format: (value) => masks.cpfCnpj(value),
      },
      {
        caption: "Concluiu migração",
        calculateCellValue: ({ isDiligenceFinished }) =>
          !!isDiligenceFinished?.toLowerCase().startsWith("s"),
      },
      {
        dataField: "errors",
        caption: "Erros",
        cssClass: "dx-word-wrap",
        cellRender: ({ data }) =>
          !!data.errors?.length && (
            <ul>
              {data.errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          ),
      },
    ],
  };
