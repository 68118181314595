import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  addStrategyFormDefaultValues,
  addStrategyFormValidationSchema,
  AddStrategyFormValues,
} from "./add-strategy.schema";

export const useAddStrategy = () => {
  const form = useForm<AddStrategyFormValues>({
    resolver: yupResolver(addStrategyFormValidationSchema),
    defaultValues: addStrategyFormDefaultValues,
  });

  const navigate = useNavigate();

  const { callService: callAddStrategy, loading: loadingAddStrategy } =
    useServiceCall(service.hubCreditManager.addStrategy);

  const addStrategy = useCallback(
    async ({ strategyName, strategyCr }: AddStrategyFormValues) => {
      const { success, error } = await callAddStrategy({
        strategyName,
        strategyCr,
      });

      if (success) {
        OToastManager.success("A estratégia foi adicionada com sucesso.");

        navigate(
          corporateRouter.routes.emprestimos.product.contingency
            .booksAndStrategies.path,
        );
      } else if (
        isAxiosError(error) &&
        error.response?.data.errorType === "DbUpdateException"
      ) {
        OToastManager.danger("Já existe uma estratégia com este CR.");
      } else {
        OToastManager.danger(
          "Um erro ocorreu ao tentar adicionar a estratégia. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callAddStrategy, navigate],
  );

  return { addStrategy, form, loadingAddStrategy };
};
