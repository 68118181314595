import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import {
  FranquiasFormValues,
  franquiasFormValidationSchema,
} from "../_compose";

export const useAddFranchise = () => {
  const form = useForm<FranquiasFormValues>({
    resolver: yupResolver(franquiasFormValidationSchema),
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const { callService: addFranchise, loading: submitLoading } = useServiceCall(
    service.clerk.addFranchise,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await addFranchise(values);

        if (!success) return OToastManager.danger("Erro ao criar franquia.");

        navigate(corporateRouter.routes.franquias.product.franquias.path);
        return OToastManager.success("Franquia criada com sucesso!");
      }),
    [addFranchise, handleSubmit, navigate],
  );

  return {
    form,
    submit,
    submitLoading,
  };
};
