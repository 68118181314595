import * as yup from "yup";

export interface LimitUpdateAmountFormValues {
  amount: number;
}

export const limitUpdateAmountFormValidationSchema: yup.ObjectSchema<LimitUpdateAmountFormValues> =
  yup.object({
    amount: yup.number().required("Campo obrigatório"),
  });
