import { OButton } from "@maestro/react";
import { ClearSelectionButtonProps } from "./clear-selection-button.types";

export const ClearSelectionButton = ({
  handleClick,
}: ClearSelectionButtonProps) => {
  return (
    <OButton
      className="align-self-center"
      onClick={handleClick}
      type="dark"
      bordered={false}
      outline
    >
      Limpar seleção
    </OButton>
  );
};
