import {
  OButton,
  OCol,
  OField,
  OIcon,
  OLink,
  OLoader,
  ORow,
  OUpload,
  OUploadInput,
  OUploadLabel,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import { DetailsTemplate } from "templates/details-template";
import { BranchCompany } from "../branch-company";
import { buildGrid } from "./upload-group-file.grid";
import { useUploadGroupFile } from "./upload-group-file.hook";
import { UploadContainer } from "./upload-group-file.styles";
import { UploadGroupFileProps } from "./upload-group-file.types";

export const UploadGroupFile = ({ setCompanyGroup }: UploadGroupFileProps) => {
  const {
    handleAddFile,
    isBranchFormOpen,
    loading,
    newCompanyGroup,
    readerLoader,
    setIsBranchFormOpen,
    setNewCompanyGroup,
  } = useUploadGroupFile({
    setCompanyGroup,
  });

  const grid = useMemo(() => buildGrid(setCompanyGroup), [setCompanyGroup]);

  return (
    <DetailsTemplate
      actions={
        <>
          <OButton
            dataAction="grupo_empresa:botao:adicionar_filial"
            dataLabel="adicionar_filial"
            onClick={() => setIsBranchFormOpen((oldValue) => !oldValue)}
          >
            Adicionar Filial
          </OButton>
          <OLink href="/files/modelo_importacao_grupo_empresas_admin.xlsx">
            <OIcon category="fas" icon="fa-external-link" className="me-2" />
            Baixar modelo
          </OLink>
        </>
      }
    >
      {(loading || readerLoader) && <OLoader absolute />}

      {isBranchFormOpen && (
        <BranchCompany
          setNewCompanyGroup={setNewCompanyGroup}
          setCompanyGroup={setCompanyGroup}
        />
      )}

      <ORow className="my-3">
        <OCol xs={12} md={6} xl={4}>
          <UploadContainer>
            <OUpload onAddFile={handleAddFile}>
              <div>
                <OField id="companyGroupAssociations" disabled={loading}>
                  <div className="d-flex flex-column gap-3">
                    <OUploadInput
                      id="companyGroupAssociations"
                      name="companyGroupAssociations"
                      dataAction="grupo_empresa:upload:documento"
                      dataLabel="documento"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      multiple={false}
                      disabled={loading}
                    />

                    <OUploadLabel htmlFor="companyGroupAssociations" size="md">
                      Selecionar documento
                      <OIcon category="far" icon="fa-cloud-upload" />
                    </OUploadLabel>
                  </div>
                </OField>
              </div>
            </OUpload>
          </UploadContainer>
        </OCol>
      </ORow>

      {newCompanyGroup.length > 0 && (
        <ODataGridGenerator grid={grid} dataSource={newCompanyGroup} />
      )}
    </DetailsTemplate>
  );
};
