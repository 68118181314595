import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";

export const useGridActions = (
  companyId: string | number,
  estrategiaId: string | number,
) => {
  const { callService: remove, loading: removeLoading } = useServiceCall(
    service.quickfin.removeCompanyEstrategia,
  );

  const { callService: setUnsetDefault, loading: setUnsetDefaultLoading } =
    useServiceCall(service.quickfin.setUnsetDefaultCompanyEstrategia);

  const removeCompanyEstrategia = useCallback(async () => {
    const { success } = await remove(companyId, estrategiaId);
    if (success) {
      OToastManager.success("Estratégia excluída com sucesso");
    } else {
      OToastManager.danger("Não foi possível excluir a estratégia");
    }
  }, [companyId, estrategiaId, remove]);

  const setUnsetDefaultCompanyEstrategia = useCallback(async () => {
    const { success } = await setUnsetDefault(companyId, estrategiaId);
    if (success) {
      OToastManager.success("Estratégia marcada/desmarcada como padrão");
    } else {
      OToastManager.danger("Não foi possível marcar a estratégia como padrão");
    }
  }, [companyId, estrategiaId, setUnsetDefault]);

  return {
    removeCompanyEstrategia,
    setUnsetDefaultCompanyEstrategia,
    loading: removeLoading || setUnsetDefaultLoading,
  };
};
