import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { PixKeysFilterFields } from "./_compose/filters-modal/filters-modal.type";
import { PixKeyStatus } from "./pix-keys.type";

interface PixKeysContextProps {
  pixKeysList: BankingHub.PixKeyOption[];
  filters: PixKeysFilterFields;
  setFilters: Dispatch<SetStateAction<PixKeysFilterFields>>;
  loading: boolean;
}

const PixKeysContext = createContext({} as PixKeysContextProps);

interface PixKeysProviderProps {
  children: React.ReactNode;
}

export const PixKeysProvider = ({ children }: PixKeysProviderProps) => {
  const [pixKeysList, setPixKeysList] = useState<BankingHub.PixKeyOption[]>([]);
  const [filters, setFilters] = useState<PixKeysFilterFields>({});

  const {
    callService,
    hasError,
    loading,
    value: data,
  } = useServiceCall(service.adminBankinghub.getPixKeysByTaxId);

  const getPixKeysList = useCallback(async () => {
    callService();
  }, [callService]);

  useEffect(() => {
    if (hasError)
      OToastManager.danger(
        "Ocorreu um erro ao listar as chaves Pix registradas nesta conta.",
      );
  }, [hasError]);

  useEffect(() => {
    if (!data?.keys.length) {
      setPixKeysList([]);
      return;
    }

    const { keys } = data;

    const filteredKeysByType = filters.keyType
      ? keys.filter((key) => filters.keyType?.split(",").includes(key.keyType))
      : keys;

    const filteredKeysByStatus = filters.status
      ? filteredKeysByType.filter((key) =>
          filters.status
            ?.split(",")
            .includes(key.status ?? PixKeyStatus.Registered),
        )
      : filteredKeysByType;

    setPixKeysList(filteredKeysByStatus);
  }, [data, filters]);

  useEffect(() => {
    getPixKeysList();
  }, [getPixKeysList]);

  const value = useMemo(
    () => ({
      filters,
      setFilters,
      pixKeysList,
      loading,
    }),
    [filters, pixKeysList, loading],
  );

  return (
    <PixKeysContext.Provider value={value}>{children}</PixKeysContext.Provider>
  );
};

export const usePixKeysContext = () => useContext(PixKeysContext);
