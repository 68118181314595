import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useSimulation } from "../../simulation/simulation.context";
import { grid } from "./calendar.grid";

export const CALENDAR_MODAL = "calendar-modal";

export const CalendarModal = () => {
  const { loading, selectedSimulationResult } = useSimulation();

  const gridRef = useGridRef();

  return (
    <OModal id={CALENDAR_MODAL} position="right" size="xs">
      <OModalHeader closeButton>
        <OTypography size="lg">Detalhes da parcela</OTypography>
        <OTypography type="default">
          Confira todas as informações antes de aprovar.
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <OTypography size="lg">Calendário de pagamentos</OTypography>
        <GridTemplate gridRef={gridRef} showRefreshButton>
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            loading={loading}
            dataSource={selectedSimulationResult?.settlement.installments}
          />
        </GridTemplate>
      </OModalBody>

      <OModalFooter className="justify-content-start">
        <OButton
          type="light"
          className="btn-outline-dark"
          onClick={() => modalManager.hide(CALENDAR_MODAL)}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
