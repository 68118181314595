import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import dayjs from "dayjs";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiAdminCollateralByIdResponse } from "services/hubcreditmanager/models/responses";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../../routes/emprestimos.route-params";
import {
  CollateralFormValues,
  addCollateralSchema,
  defaultCollateralFormValues,
} from "./add-collateral-form.schema";

export const useCollateralForm = (
  collateral?: GetApiAdminCollateralByIdResponse,
) => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();
  if (!id) throw new Error("No id parameter");

  const navigate = useNavigate();

  const form = useForm<CollateralFormValues>({
    resolver: yupResolver(addCollateralSchema),
    defaultValues: defaultCollateralFormValues,
  });

  const { watch, handleSubmit, reset } = form;

  const typeWatcher = watch("collateralType");

  const { callService: addCollateralService, loading: addLoading } =
    useServiceCall(service.hubCreditManager.createCollateral);

  const { callService: updateCollateralService, loading: updateLoading } =
    useServiceCall(service.hubCreditManager.updateCollateral);

  const addCollateral = useCallback(
    async (values: CollateralFormValues) => {
      const { success } = await addCollateralService({
        ...values,
        limitRequestId: +id,
        expireDate: values.expireDate
          ? dayjs(values.expireDate).format("YYYY-MM-DD")
          : null,
        hasOptional: !!values.hasOptional,
        manufacturingYear: values.manufacturingYear
          ? +dayjs(values.manufacturingYear).format("YYYY")
          : null,
      });
      if (success) {
        OToastManager.success("Garantia adicionada com sucesso");
        navigate(
          corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.path(
            { id },
          ),
        );
      } else OToastManager.danger("Houve um problema. Tente novamente.");
    },
    [addCollateralService, id, navigate],
  );

  const updateCollateral = useCallback(
    async (values: CollateralFormValues) => {
      if (!collateral) return;

      const { success } = await updateCollateralService({
        ...values,
        expireDate: values.expireDate
          ? dayjs(values.expireDate).format("YYYY-MM-DD")
          : null,
        hasOptional: values.hasOptional,
        collateralId: collateral.id,
        formalizedContractNumber: values.formalized
          ? values.formalizedContractNumber
          : undefined,
        guaranteedPercentage: values.guaranteedPercentage || undefined,
        manufacturingYear: values.manufacturingYear
          ? +dayjs(values.manufacturingYear).format("YYYY")
          : null,
      });

      if (success) {
        OToastManager.success("Garantia atualizada com sucesso");
        navigate(
          corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.path(
            { id },
          ),
        );
      } else OToastManager.danger("Houve um problema. Tente novamente.");
    },
    [collateral, id, navigate, updateCollateralService],
  );

  useEffect(() => {
    if (collateral)
      reset({
        ...collateral,
        manufacturingYear: collateral.manufacturingYear
          ? dayjs()
              .set("year", collateral.manufacturingYear)
              .format("YYYY-MM-DD")
          : "",
        expireDate: collateral.expireDate || null,
        formalizedContractNumber: collateral.formalizedContractNumber || "",
        description: collateral.description || "",
        identification: collateral.identification || "",
      } as CollateralFormValues);
  }, [collateral, reset]);

  return {
    loading: addLoading || updateLoading,
    form,
    collateralType: typeWatcher,
    handleSubmit,
    addCollateral,
    updateCollateral,
  };
};
