import { OCol, ORFieldInput, ORow } from "@maestro/react";

import { updateInviteFields } from "./update-invite-form.form";

export const UpdateInviteForm = () => {
  return (
    <ORow>
      <OCol xs={12} md={10} xl={6}>
        <ORFieldInput {...updateInviteFields.receiverEmail} />
      </OCol>
      <OCol xs={12} md={10} xl={6}>
        <ORFieldInput {...updateInviteFields.createdBy} />
      </OCol>
      <OCol xs={12} md={6} xl={6}>
        <ORFieldInput {...updateInviteFields.officerName} />
      </OCol>
      <OCol xs={12} md={6} xl={6}>
        <ORFieldInput {...updateInviteFields.type} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateInviteFields.token} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateInviteFields.segmentName} />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInput {...updateInviteFields.deskName} />
      </OCol>
    </ORow>
  );
};
