import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AssociatedCondition } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { useWorkflowFormTemplate } from "../../../hooks/use-workflow-form-template.context";
import { useBatchFileTemplateColumns } from "../../../hooks/use-workflow-batch-template.context";
import { useWorkflow } from "../../../hooks/use-workflow.context";
import {
  conditionLabelsMap,
  getAddConditionInfo,
  getRelationshipId,
} from "../select-conditions.utils";
import { selectConditionModalFormValidationSchema } from "./select-condition-modal-form.schemas";

export const SelectConditionModal = () => {
  const {
    processorConfig: { value: selectedProcessorConfig },
    setSelectedConditions,
    conditions: { value: allConditions },
    conditionDestination,
  } = useWorkflow();

  const { selectedComponent, selectedStep } = useWorkflowFormTemplate();

  const { selectedTemplateColumn } = useBatchFileTemplateColumns();

  const [loading, setLoading] = useState(false);

  const form = useForm<{
    conditionType: string;
    condition: number;
  }>({
    resolver: yupResolver(selectConditionModalFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const { hide } = modalManager;

  const addConditionToProcessorConfig = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          const addConditionInfo = getAddConditionInfo(
            conditionDestination,
            selectedProcessorConfig,
            selectedStep,
            selectedComponent,
            selectedTemplateColumn,
          );

          const choosenCondition = allConditions?.find(
            (c) => c.id === values.condition,
          );

          const { data } = await addConditionInfo.service({
            conditionType: values.conditionType,
            conditionId: choosenCondition?.id,
          });

          const relationshipId = getRelationshipId(conditionDestination, data);

          setSelectedConditions((state) =>
            state.concat({
              ...relationshipId,
              ...choosenCondition,
              conditionType: values.conditionType,
            } as AssociatedCondition),
          );

          hide("select-condition-modal");
          reset();

          OToastManager.success("Condição adicionada com sucesso.");
        } catch {
          OToastManager.danger("Não foi possível adicionar condição.");
        } finally {
          setLoading(false);
        }
      }),
    [
      handleSubmit,
      conditionDestination,
      selectedProcessorConfig,
      selectedStep,
      selectedComponent,
      selectedTemplateColumn,
      allConditions,
      setSelectedConditions,
      hide,
      reset,
    ],
  );

  return (
    <OModal
      id="select-condition-modal"
      position="center"
      size="xs"
      className="position-absolute"
    >
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar condição
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {loading && <OLoader size="xl" absolute />}
        <FormProvider {...form}>
          <form>
            <div className="d-flex flex-column gap-2">
              <ORFieldSelect
                id="condition"
                name="condition"
                label="Condição"
                dataAction="select-condition-modal:select:condicao"
                dataLabel="condicao"
              >
                <div>
                  {allConditions?.map((condition) => (
                    <OOption key={condition.conditionName} value={condition.id}>
                      {condition.description}
                    </OOption>
                  ))}
                </div>
              </ORFieldSelect>

              {conditionDestination === "BATCH" ? (
                <ORFieldSelect
                  id="conditionType"
                  name="conditionType"
                  label="Tipo"
                  dataAction="select-condition-modal:select:tipo-condicao"
                  dataLabel="tipo-condicao"
                >
                  <OOption value="IS_REQUIRED">
                    {conditionLabelsMap.IS_REQUIRED.label()}
                  </OOption>
                </ORFieldSelect>
              ) : (
                <ORFieldSelect
                  id="conditionType"
                  name="conditionType"
                  label="Tipo"
                  dataAction="select-condition-modal:select:tipo-condicao"
                  dataLabel="tipo-condicao"
                >
                  <div>
                    <OOption value="EXECUTE">
                      {conditionLabelsMap.EXECUTE.label(conditionDestination)}
                    </OOption>
                    <OOption value="DONT_EXECUTE">
                      {conditionLabelsMap.DONT_EXECUTE.label(
                        conditionDestination,
                      )}
                    </OOption>
                    {conditionDestination === "COMPONENT" && (
                      <OOption value="IS_REQUIRED">
                        {conditionLabelsMap.IS_REQUIRED.label()}
                      </OOption>
                    )}
                  </div>
                </ORFieldSelect>
              )}
            </div>
          </form>
        </FormProvider>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            dataAction="adicionar_condicao:botao:voltar"
            dataLabel="voltar"
            type="dark"
            outline
            onClick={() => {
              hide("select-condition-modal");
            }}
          >
            Fechar
          </OButton>
          <OButton
            dataAction="selecionar_condicao:botao:adicionar"
            dataLabel="adicionar"
            type="dark"
            onClick={() => addConditionToProcessorConfig()}
          >
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
