import { Roles } from "roles/roles.types";

export const renegociacoesRoles = {
  customer: {
    tab: {
      role: "admin:RenegociacoesTabCustomer.View",
      description:
        "Visualização da página 'Renegociação/Quitação de contratos' na visão cliente",
    },
    monitorConditionsPage: {
      role: "admin:RenegociacoesMonitorConditionsPageCustomer.View",
      description:
        "Visualização da página 'Renegociação/Quitação de contratos - Renegociação/Quitação de contratos - Condições' na visão cliente",
    },
    monitorHistoryPage: {
      role: "admin:RenegociacoesMonitorHistoryPageCustomer.View",
      description:
        "Visualização da página 'Renegociação/Quitação de contratos - Renegociação/Quitação de contratos - Histórico' na visão cliente",
    },
    monitorHistoryDetailsPage: {
      role: "admin:RenegociacoesMonitorHistoryDetailsPageCustomer.View",
      description:
        "Visualização da página 'Renegociação/Quitação de contratos - Renegociação/Quitação de contratos - Histórico - Detalhes' na visão cliente",
    },
    monitorHistoryDetailsSuccessPage: {
      role: "admin:RenegociacoesMonitorHistoryDetailsSuccessPageCustomer.View",
      description:
        "Visualização da página 'Renegociação/Quitação de contratos - Renegociação/Quitação de contratos - Histórico - Detalhes - Sucesso' na visão cliente",
    },
  },
} satisfies Roles;
