import { AxiosError, isAxiosError } from "axios";

export const tcoStrategyErrorsResponse = {
  InvalidValuesByPlan: "InvalidValuesByPlan",
  InvalidStrategy: "InvalidStrategy",
  ThereAreAlreadySettingsInTheInformedParameters:
    "ThereAreAlreadySettingsInTheInformedParameters",
};

export enum tcoStrategyErrorsMessage {
  InvalidValuesByPlan = "Valores por planos inválidos.",
  InvalidStrategy = "Estratégia inválida.",
  ThereAreAlreadySettingsInTheInformedParameters = "Já existe uma estratégia com os parâmetros selecionados.",
  Default = "Ocorreu um erro ao salvar a estratégia.",
}

export interface ApiResponse {
  errorCode: string;
  isError: string;
  message: string;
  modal: string;
}

export const mapTCOStrategyError = (e?: AxiosError) => {
  if (isAxiosError<ApiResponse>(e)) {
    if (e.response) {
      if (tcoStrategyErrorsResponse.hasOwnProperty(e.response.data.message)) {
        return tcoStrategyErrorsMessage[
          e.response.data.message as keyof typeof tcoStrategyErrorsResponse
        ];
      }
    }
  }
  return tcoStrategyErrorsMessage.Default;
};
