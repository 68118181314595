import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import { StandardLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { buildCustomerLink } from "utils/build-customer-link";
import { tradeFields } from "../../../../../components/trade";

export const energyTradeProductDetailsGenerator = (
  trade: HubEnergy.TradeDetailsResponse,
) => {
  return {
    customer: {
      title: "Matriz",
      items: [
        {
          label: "Nome",
          value: (
            <StandardLink
              href={buildCustomerLink(
                trade.lead.customerEntityTaxId,
                corporateRouter.routes.energia.customer.path,
              )}
            >
              {trade.lead?.customerEntityName}
            </StandardLink>
          ),
          grid: { md: 6, lg: 4 },
        },
        {
          label: "Razão social",
          value: trade.lead?.customerEntityOfficialName,
          grid: { md: 6, lg: 4 },
        },
        {
          label: "CNPJ",
          value: (
            <div className="d-flex align-items-center gap-2">
              {masks.cpfCnpj(trade.lead?.customerEntityTaxId)}{" "}
              <CopyButton
                action="detalhes_do_cliente:botao:copiar-identificacao"
                label="copiar-identificacao"
                target={trade.lead?.customerEntityTaxId}
                toasterMessage="Identificação copiada."
              />
            </div>
          ),
          grid: { md: 6, lg: 4 },
        },
      ],
    },

    trade: {
      title: "Proposta",
      items: tradeFields(trade).trade.items,
    },
  } satisfies DetailsFields;
};
