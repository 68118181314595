import { OCard, OCardBody } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { ContentTemplate } from "templates/content-template";

type InfoCardProps<T> = {
  callService: () => void;
  hasError: boolean;
  loading: boolean;
  value: T;
  render: (value: NonNullable<T>) => JSX.Element;
};

export const InfoCard = <T,>({
  callService,
  hasError,
  loading,
  value,
  render,
}: InfoCardProps<T>) => {
  return (
    <OCard className="position-relative">
      <OCardBody className="h-100">
        <ContentTemplate
          hasError={hasError}
          loading={loading}
          value={value as Exclude<typeof value, undefined>}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível buscar as informações"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => callService()} />
            </ErrorComponent>
          }
          render={render}
        />
      </OCardBody>
    </OCard>
  );
};
