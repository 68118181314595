import { Roles } from "roles/roles.types";

export const cadastroRoles = {
  product: {
    datadogDashboard: {
      role: "admin:OnboardingDatadogDashboardProduct.View",
      description:
        "Visualização do iframe do datadog no Dashboard de Onboarding",
    },
  },
  customer: {
    tab: {
      role: "admin:OnboardingTabCustomer.View",
      description: "Visualização da tab 'Cadastro' na visão cliente",
    },
    detailsOriginDetailField: {
      role: "admin:OnboardingDetailsOriginDetailField.View",
      description:
        "Visualização do campo 'Origem' na página 'Detalhes' na visão cliente",
    },
    detailsDesksListDetailField: {
      role: "admin:OnboardingDetailsDesksListDetailField.View",
      description:
        "Visualização do campo 'Segmentos' na página 'Detalhes' na visão cliente",
    },
    detailsProductsDetailField: {
      role: "admin:OnboardingDetailsProductsDetailField.View",
      description:
        "Visualização do campo 'Produtos' na página 'Detalhes' na visão cliente",
    },
    detailsEloaApproversPage: {
      role: "admin:OnboardingDetailsEloaApproversPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Procuração Eletrônica' na visão cliente",
    },
    detailsOnboardingReviewsPage: {
      role: "admin:OnboardingDetailsOnboardingReviewsPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Revisão Cadastral' na visão cliente",
    },
    detailsDataUpdatePage: {
      role: "admin:DataUpdatePageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Atualização Cadastral' na visão cliente",
    },
    detailsEnableCurrentAccountPage: {
      role: "admin:OnboardingDetailsEnableCurrentAccountPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Abertura de Conta Corrente' na visão cliente",
    },
    detailsExpiringPowersPage: {
      role: "admin:OnboardingDetailsExpiringPowersPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Expiração de poderes' na visão cliente",
    },
    onboardingsPage: {
      role: "admin:OnboardingOnboardingsPageCustomer.View",
      description: "Visualização da página 'Cadastros' na visão cliente",
    },
    onboardingsOnboardingEditPage: {
      role: "admin:OnboardingOnboardingsOnboardingEditPageCustomer.View",
      description:
        "Visualização da página 'Cadastros - Editar' na visão cliente",
    },
    onboardingsOnboardingLogsPage: {
      role: "admin:OnboardingOnboardingsOnboardingLogsPageCustomer.View",
      description: "Visualização da página 'Cadastros - Logs' na visão cliente",
    },
    onboardingsViewDetailsPage: {
      role: "admin:OnboardingOnboardingsOnboardingViewDetailsPageCustomer.View",
      description:
        "Visualização da página 'Cadastros - Detalhes' na visão cliente",
    },
    onboardingsOnboardingRawStatusGridColumn: {
      role: "admin:OnboardingOnboardingsOnboardingRawStatusGridColumnCustomer.View",
      description:
        "Visualização da coluna 'Canal de Origem' da página 'Cadastros' na visão cliente",
    },
    onboardingsOnboardingOriginChannelGridColumn: {
      role: "admin:OnboardingOnboardingsOnboardingOriginChannelGridColumnCustomer.View",
      description:
        "Visualização da coluna 'Status Detalhado' da página 'Cadastros' na visão cliente",
    },
    onboardingsOnboardingDetailsGridColumn: {
      role: "admin:OnboardingOnboardingsOnboardingDetailsGridColumnCustomer.View",
      description: "Abrir modal com detalhes dos onbardings",
    },
    onboardingsOnboardingOpenAccountsPage: {
      role: "admin:OnboardingOnboardingsOnboardingOpenAccountsPageCustomer.View",
      description:
        "Visualização da página 'Contas Solicitadas' na visão cliente",
    },
    onboardingsOnboardingOpenAccountsButton: {
      role: "admin:OnboardingOnboardingsOnboardingOpenAccountsButtonCustomer.View",
      description:
        "Visualização da ação de 'Contas Solicitadas' onboarding da grid de 'Cadastros' na visão cliente",
    },
    onboardingsOnboardingDeleteButton: {
      role: "admin:OnboardingOnboardingsOnboardingDeleteButtonCustomer.View",
      description:
        "Visualização da ação de 'Excluir' onboarding da grid de 'Cadastros' na visão cliente",
    },
    onboardingEmbeddedPage: {
      role: "admin:OnboardingOnboardingEmbeddedPageCustomer.View",
      description:
        "Visualização da página 'Cadastro Embedado' na visão cliente",
    },
    accountClosePage: {
      role: "admin:OnboardingAccountClosePageCustomer.View",
      description:
        "Visualização da página 'Encerramento de conta' na visão cliente",
    },
    accountClosePendenciesPage: {
      role: "admin:OnboardingAccountClosePendenciesPageCustomer.View",
      description:
        "Visualização da página 'Encerramento de conta - Pendências' na visão cliente",
    },
    accountCloseReasonPage: {
      role: "admin:OnboardingAccountCloseReasonPageCustomer.View",
      description:
        "Visualização da página 'Encerramento de conta - Motivo do encerramento' na visão cliente",
    },
    accountCloseDetailedFileGeneratePage: {
      role: "admin:OnboardingAccountCloseGenerateFile.View",
      description:
        "Visualização do botão para realizar o download ou gerar um novo arquivo detalhado do encerramento na página 'Encerramento de conta' na visão cliente",
    },
    btgAccountsAccountAdminPage: {
      role: "admin:OnboardingBtgAccountsAccountAdminPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Contas' na visão cliente",
    },
    btgAccountsAccountAdminPermissionHistoryPage: {
      role: "admin:OnboardingBtgAccountsAccountAdminPermissionHistoryPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Contas - Histórico de Permissões' na visão cliente",
    },
    btgAccountsAccountAdminStatementPage: {
      role: "admin:OnboardingBtgAccountsAccountAdminStatementPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Contas - Extrato' na visão cliente",
    },
    btgAccountsAccountAdminUsersBlockedPage: {
      role: "admin:AccountsManagement.View",
      description:
        "Visualização da página 'Contas BTG - Contas - Usuários Restritos' na visão cliente",
    },
    btgAccountsBalancePage: {
      role: "admin:OnboardingBtgAccountsBalancePageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Remunera+' na visão cliente",
    },
    btgAccountsOverdraftLimitPage: {
      role: "admin:OnboardingBtgAccountsOverdraftLimitPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Limite da conta' na visão cliente",
    },
    btgAccountsOptinPage: {
      role: "admin:OnboardingBtgAccountsOptinPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Novas Contas Opt-in' na visão cliente",
    },
    btgAccountsOptinWorkflowIdColumn: {
      role: "admin:OnboardingBtgAccountsOptinWorkflowIdColumn.View",
      description:
        "Visualização da coluna 'Solicitação Id' da página 'Contas BTG - Novas Contas Opt-in' na visão cliente",
    },
    btgAccountsOptinCreateAccountsPage: {
      role: "admin:OnboardingBtgAccountsOptinCreateAccountsPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Novas Contas Opt-in - Criar Contas Opt-in' na visão cliente",
    },
    btgAccountsOptinCreateAccountsVerifyOptinPowersPage: {
      role: "admin:OnboardingBtgAccountsOptinCreateAccountsVerifyOptinPowersPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Novas Contas Opt-in - Criar Contas Opt-in - Verificar Poderes Optin' na visão cliente",
    },
    guarantorsPage: {
      role: "admin:OnboardingGuarantorsPageCustomer.View",
      description: "Visualização da página 'Avalistas' na visão cliente",
    },
    guarantorsContingenciesPage: {
      role: "admin:OnboardingGuarantorsContingenciesPageCustomer.View",
      description:
        "Visualização da página 'Avalistas - Contingências' na visão cliente",
    },
    guarantorsMonitoringPage: {
      role: "admin:OnboardingGuarantorsMonitoringPageCustomer.View",
      description:
        "Visualização da página 'Avalistas - Monitoramento' na visão cliente",
    },
    guarantorsGuarantorsDetailsPage: {
      role: "admin:OnboardingGuarantorsGuarantorsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Avalistas - Detalhes' na visão cliente",
    },
    guarantorsGuarantorsEmbeddedPage: {
      role: "admin:OnboardingGuarantorsGuarantorsEmbeddedPageCustomer.View",
      description:
        "Visualização da página 'Avalistas - Embedado' na visão cliente",
    },
    guarantorsGuarantorsDetailedStatusColumn: {
      role: "admin:OnboardingGuarantorsGuarantorsDetailedStatusColumnCustomer.View",
      description:
        "Visualização da coluna 'Status Detalhado' da página 'Avalistas' na visão cliente",
    },
    productsPage: {
      role: "admin:OnboardingProductsPageCustomer.View",
      description: "Visualização da página 'Produtos' na visão cliente",
    },
    contractsPage: {
      role: "admin:OnboardingContractsPageCustomer.View",
      description: "Visualização da página 'Contratos' na visão cliente",
    },
    termsPage: {
      role: "admin:OnboardingTermsPageCustomer.View",
      description: "Visualização da página 'Termos' na visão cliente",
    },
    relationshipPage: {
      role: "admin:OnboardingRelationshipPageCustomer.View",
      description: "Visualização da página 'Relacionamento' na visão cliente",
    },
    strategiesPage: {
      role: "admin:OnboardingStrategiesPageCustomer.View",
      description: "Visualização da página 'Estratégias' na visão cliente",
    },
    monitorAmlPage: {
      role: "admin:OnboardingMonitorAmlPageCustomer.View",
      description:
        "Visualização da página 'Reprovações de AML' na visão cliente",
    },
    companyActsPage: {
      role: "admin:OnboardingCompanyPowersPageCustomer.View",
      description: "Visualização da página 'Firmas e Poderes' na visão cliente",
    },
    companyActsCacheSwitch: {
      role: "admin:OnboardingCompanyActsCacheSwitchCustomer.View",
      description:
        "Visualização do switch 'Usar cache' na página 'Firmas e Poderes' na visão cliente",
    },
    companyActsAttachUserPage: {
      role: "admin:OnboardingCompanyActsAttachUserPageCustomer.View",
      description:
        "Visualização da página 'Firmas e Poderes - Vincular usuário' na visão cliente",
    },
    companyGroupAssociationsPage: {
      role: "admin:OnboardingCompanyGroupAssociationsPageCustomer.View",
      description:
        "Visualização da página 'Solicitação de Relacionamento' na visão cliente",
    },
    lettersOfAttorneyVintagePage: {
      role: "admin:OnboardingLettersOfAttorneyPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações físicas' na visão cliente",
    },
    lettersOfAttorneyVintageCreateWorkflow: {
      role: "admin:OnboardingLettersOfAttorneyCreateWorkflowCustomer.View",
      description:
        "Visualização do botão 'Adicionar' na página 'Procurações - Procurações físicas' na visão cliente",
    },
    lettersOfAttorneyVintageContingencies: {
      role: "admin:OnboardingLettersOfAttorneyContingenciesPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações físicas - COntingências' na visão cliente",
    },
    lettersOfAttorneyVintageMonitoring: {
      role: "admin:OnboardingLettersOfAttorneyMonitoringPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações físicas - Monitoramento' na visão cliente",
    },
    lettersOfAttorneyVintageDetailedStatusColumn: {
      role: "admin:OnboardingLettersOfAttorneyVintageDetailedStatusColumn.View",
      description:
        "Visualização da coluna 'Status detalhado' na grid de procurações físicas",
    },
    lettersOfAttorneyVintageUuidColumn: {
      role: "admin:OnboardingLettersOfAttorneyVintageUuidColumn.View",
      description:
        "Visualização da coluna 'UUID' na grid de procurações físicas",
    },
    lettersOfAttorneyVintageEmbeddedPage: {
      role: "admin:OnboardingLettersOfAttorneyEmbeddedPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações físicas - Detalhes' na visão cliente",
    },
    lettersOfAttorneyVintageFraudVerificationPage: {
      role: "admin:OnboardingLettersOfAttorneyFraudVerificationPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações físicas - Validações de segurança' na visão cliente",
    },
    lettersOfAttorneyElectronicPage: {
      role: "admin:OnboardingLettersOfAttorneyElectronicPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações eletrônicas' na visão cliente",
    },
    lettersOfAttorneyElectronicContingencies: {
      role: "admin:OnboardingLettersOfAttorneyElectronicContingenciesPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações eletrônicas - Contingências' na visão cliente",
    },
    lettersOfAttorneyElectronicMonitoring: {
      role: "admin:OnboardingLettersOfAttorneyElectronicMonitoringPageCustomer.View",
      description:
        "Visualização da página 'Procurações - Procurações eletrônicas - Monitoramento' na visão cliente",
    },
    lettersOfAttorneyElectronicUuidColumn: {
      role: "admin:OnboardingLettersOfAttorneyElectronicUuidColumnCustomer.View",
      description:
        "Visualização da coluna 'UUID' na grid de procurações eletrônicas",
    },
    lettersOfAttorneyElectronicDetailedStatusColumn: {
      role: "admin:OnboardingLettersOfAttorneyElectronicDetailedStatusColumnCustomer.View",
      description:
        "Visualização da coluna 'Status Detalhado' na grid de procurações eletrônicas",
    },
    toolsPage: {
      role: "admin:OnboardingToolsPageCustomer.View",
      description: "Visualização da página 'Ferramentas' na visão cliente",
    },
    toolsApproveContingencyPage: {
      role: "admin:OnboardingToolsApproveContingencyPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Aprovação de cadastro' na visão cliente",
    },
    toolsDocumentDevolutivePage: {
      role: "admin:OnboardingToolsDocumentDevolutivePageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Documentos do cliente' na visão cliente",
    },
    toolsFinishAccessPage: {
      role: "admin:OnboardingToolsFinishAccessPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Encerrar relacionamento' na visão cliente",
    },
    toolsGenerateTermPage: {
      role: "admin:OnboardingToolsGenerateTermPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Gerar termos' na visão cliente",
    },
    toolsMigrateDesksPage: {
      role: "admin:OnboardingToolsMigrateDesksPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Migração de segmento de conta' na visão cliente",
    },
    toolsOnboardingWorkflowsPage: {
      role: "admin:OnboardingToolsOnboardingWorkflowsPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Edição de onboarding' na visão cliente",
    },
    toolsOnboardingWorkflowsAllSegmentsEdit: {
      role: "admin:OnboardingToolsOnboardingWorkflowsAllSegmentsEditCustomer.View",
      description:
        "Permite alterar workflows de todos os segmentos na página 'Ferramentas - Edição de onboarding' na visão cliente",
    },
    toolsDataReviewWorkflowsPage: {
      role: "admin:OnboardingToolsDataReviewWorkflowsPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Edição de revisão cadastral' na visão cliente",
    },
    toolsDataUpdateWorkflowsPage: {
      role: "admin:OnboardingToolsDataUpdateWorkflowsPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Edição de atualização cadastral' na visão cliente",
    },
    toolsMigrateDesksByWorkflowIdPage: {
      role: "admin:OnboardingToolsMigrateDesksByWorkflowIdPageCustomer.View",
      description:
        "Visualização da página 'Ferramentas - Migração de segmento de conta - Migrar segmento de conta' na visão cliente",
    },
    notificationEmailsPage: {
      role: "admin:OnboardingNotificationEmailsPageCustomer.View",
      description:
        "Visualização da página 'Monitorar Notificações - Emails' na visão cliente",
    },
    notificationWorkflowsPage: {
      role: "admin:OnboardingNotificationWorkflowsPageCustomer.View",
      description:
        "Visualização da página 'Monitorar Notificações - Onboarding' na visão cliente",
    },
    notificationInvitesPage: {
      role: "admin:OnboardingNotificationInvitesPageCustomer.View",
      description:
        "Visualização da página 'Monitorar Notificações - Onboarding - Convite' na visão cliente",
    },
    inviteCardPage: {
      role: "admin:OnboardingInviteCardPageCustomer.View",
      description:
        "Visualização da página 'Onboarding - Convite' na visão cliente",
    },
    onboardingReviewsEditPage: {
      role: "admin:onboardingReviewsEditPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Revisão Cadastral - Editar' na visão cliente",
    },
    onboardingReviewsLogsPage: {
      role: "admin:onboardingReviewsLogsPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Revisão Cadastral - Logs' na visão cliente",
    },
    onboardingUpdateEditPage: {
      role: "admin:onboardinUpdateEditPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Atualização Cadastral - Editar' na visão cliente",
    },
    onboardingUpdateLogsPage: {
      role: "admin:onboardinUpdateLogsPageCustomer.View",
      description:
        "Visualização da página 'Detalhes - Atualização Cadastral - Logs' na visão cliente",
    },
    oldAdminButtonPage: {
      role: "admin:oldAdminButtonPageCustomer.View",
      description: "Visualização da página 'Portal Antigo' na visão cliente",
    },
    checkingAccountOpening: {
      role: "admin:CheckingAccountOpening.View",
      description:
        "Visualização de Abertura de Conta Corrente na visão cliente",
    },
    checkLogsAccountOpening: {
      role: "admin:CheckingAccountOpeningAdmin.View",
      description: "Visualização de Logs de Optin",
    },
    checkDetailedAccountOpening: {
      role: "admin:CheckingDetailedAccountOpeningAdmin.View",
      description: "Visualização do workflow detalhado de Optin",
    },
    detailsOriginFranchiseField: {
      role: "admin:OnboardingDetailsOriginFranchiseField.View",
      description:
        "Visualização do campo 'Origem - Franquia' na página 'Detalhes' na visão cliente",
    },
  },
} satisfies Roles;
