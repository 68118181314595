import * as yup from "yup";

export const cedenteEditDetailsValidationSchema = yup.object({
  companyEntityType: yup.string().required("Campo obrigatório").nullable(),
  estrategiaId: yup.number().required("Campo obrigatório").nullable(),
  tipoContrato: yup.string().required("Campo obrigatório").nullable(),
  contractTemplateId: yup
    .number()
    .test("contractTemplateId", "Campo obrigatório", (value, context) => {
      return !!value || context.parent.tipoContrato === "SemContrato";
    })
    .nullable(),
  identification: yup.string().required("Campo obrigatório").nullable(),
  officialName: yup.string().required("Campo obrigatório").nullable(),
  justificative: yup
    .string()
    .test("justificative", "Campo obrigatório", (value, context) => {
      // CAREFUL: DELICATE LOGIC!!! _originalDesativaFornecedor can be undefined
      const hasntChangedValue =
        (context.parent.desativaFornecedor === "yes" &&
          context.parent._originalDesativaFornecedor === "yes") ||
        (context.parent.desativaFornecedor === "no" &&
          context.parent._originalDesativaFornecedor !== "yes");

      return !!value || hasntChangedValue;
    })
    .nullable(),
  identificadorExterno: yup.string(),
  cge: yup.string(),
  antecipaAutomaticamente: yup
    .string()
    .required("Campo obrigatório")
    .nullable(),
  permiteOverrideEstrategia: yup
    .string()
    .required("Campo obrigatório")
    .nullable(),
  desativaFornecedor: yup.string().required("Campo obrigatório").nullable(),
  contractComplement: yup.array().nullable(),
  contractComplementInclusionList: yup.array().nullable(),
});

export const cedenteDetailsValidationSchema =
  cedenteEditDetailsValidationSchema.shape({
    country: yup
      .string()
      .required("Este campo é obrigatório")
      .test(
        "country",
        "País não pode ser Brasil quando Tipo do documento for Offshore",
        (value, context) => {
          return context.parent.companyEntityType === "Offshore" &&
            value === "BR"
            ? false
            : true;
        },
      )
      .test(
        "country",
        "Para Tipo do documento CPF ou CNPJ, País deve ser Brasil",
        (value, context) => {
          return context.parent.companyEntityType !== "Offshore" &&
            value !== "BR"
            ? false
            : true;
        },
      )
      .nullable(),
  });

export const cedenteDetailsDefaultValues = {
  antecipaAutomaticamente: "no",
  cge: "",
  companyEntityType: "",
  contractTemplateId: null,
  desativaFornecedor: "no",
  estrategiaGUID: "",
  estrategiaId: undefined,
  identificadorExterno: "",
  identification: "",
  officialName: "",
  permiteOverrideEstrategia: "no",
  receiptTemplateId: null,
  tipoContrato: "",
  tipoProduto: "",
  country: "",
  contractComplement: [],
  contractComplementInclusionList: [],
};
