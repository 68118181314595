import { OCard, OCardBody, OCol, ORow, OTypography } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { getValueFromMap } from "utils/get-value-from-map";
import { productNameOverridesMap } from "../detalhes/_compose/products-list/products-list.utils";

export const CompanyProducts = () => {
  const { customer } = useCustomer();

  return (
    <div
      className="d-flex flex-column gap-2"
      style={{ boxSizing: "border-box" }}
    >
      <OTypography size="lg" type="default">
        Produtos
      </OTypography>
      <OCard>
        <OCardBody className="position-relative">
          <ORow gap={4}>
            {customer.products?.map((product) => {
              const name =
                getValueFromMap(productNameOverridesMap, product.name) ??
                product.name;
              const date =
                product.insertedAt &&
                new Date(product.insertedAt).toLocaleString("pt-BR");
              const eligibility =
                product.eligibilityStatus || "Elegibilidade não analisada";

              return (
                <OCol
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-column gap-1"
                  key={product.name}
                >
                  <OTypography tag="h3" type="dark">
                    {name}
                  </OTypography>

                  <div className="d-flex flex-column">
                    <OTypography tag="span" type="dark-60">
                      Data de contratação - {date}
                    </OTypography>
                    <OTypography tag="span" type="dark-60">
                      Elegibilidade - {eligibility}
                    </OTypography>
                  </div>
                </OCol>
              );
            })}
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
