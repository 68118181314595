import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import {
  GetContractGeneratorByProductIdParams,
  PostContractGeneratorBody,
} from "services/fidc/models/requests";
import {
  GetContractGeneratorByProductIdResponse,
  GetDashboardProductsResponse,
  GetDashboardBrokersByProductIdResponse,
} from "services/fidc/models/responses";
import { service } from "services/service";
import dayjs from "dayjs";
import { ConsolidateContractsFormValues } from "./consolidated-contracts.utils";
import { consolidatedContractsSchema } from "./consolidated-contracts.form";

const dataSourceGenerator = (productId: string, brokerId: any) =>
  dataSourceCustomStoreGenerator<
    GetContractGeneratorByProductIdResponse["items"][number]
  >((loadOptions) => {
    const params: GetContractGeneratorByProductIdParams = {
      skip: loadOptions.skip ?? 0,
      take: loadOptions.take ?? 10,
      brokerId,
    };
    return service.fidc
      .getConsolidatedContracts(productId, params)
      .then(({ data }) => {
        return {
          data: data.items,
          totalCount: data.totalItems,
        };
      })
      .catch(() => {
        const errorMessage = "Erro ao buscar contratos consolidados.";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const useConsolidatedContracts = () => {
  const [dataSource, setDataSource] =
    useState<ReturnType<typeof dataSourceGenerator>>();
  const [products, setProducts] = useState<GetDashboardProductsResponse>();
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [brokers, setBrokers] =
    useState<GetDashboardBrokersByProductIdResponse[]>();
  const [selectedBroker, setSelectedBroker] = useState<string>();
  const [page, setPage] = useState(1);
  const [allPagesGeneration, setAllPagesGeneration] = useState(false);
  const form = useForm<ConsolidateContractsFormValues>({
    resolver: yupResolver(consolidatedContractsSchema),
  });
  const { handleSubmit } = form;

  const getProducts = useCallback(async () => {
    try {
      const { data } = await service.fidc.getProducts();

      setProducts(data);
    } catch {
      OToastManager.danger("Erro ao buscar produtos");
    }
  }, []);

  const fetchBrokersByProductId = (productId: string) => {
    return service.fidc
      .getBrokersByProductId(productId)
      .then(({ data }) => {
        setBrokers(data);
        setSelectedBroker("");
      })
      .catch(() => {
        OToastManager.danger("Erro ao buscar brokers.");
      });
  };

  const onSubmit = handleSubmit(async (values) => {
    if (!selectedProduct) {
      OToastManager.warning("Favor informar o produto");
      return;
    }
    if (!selectedBroker) {
      OToastManager.warning("Favor informar o broker");
      return;
    }
    const body: PostContractGeneratorBody = {
      fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
      limit: values.limit,
      page,
      productId: selectedProduct,
      brokerId: selectedBroker,
      toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
      allPagesGeneration,
    };

    try {
      await service.fidc.postContracts(body);
      OToastManager.success("Os contratos entraram na fila pra serem gerados");
    } catch {
      OToastManager.danger("Erro ao tentar gerar os contratos");
    }
  });

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (selectedProduct) {
      fetchBrokersByProductId(selectedProduct);
      setDataSource(dataSourceGenerator(selectedProduct, null));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedBroker) {
      setDataSource(dataSourceGenerator(selectedProduct, selectedBroker));
    }
  }, [selectedBroker, selectedProduct]);

  return {
    dataSource,
    form,
    onSubmit,
    page,
    allPagesGeneration,
    products,
    selectedProduct,
    brokers,
    selectedBroker,
    setPage,
    setAllPagesGeneration,
    setSelectedProduct,
    setSelectedBroker,
  };
};
