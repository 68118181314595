import { InternalValueOf, NestedKeyOf } from "./header-filter.types";

type Filter = (string | null | Filter)[];

/**
 * Given 2 arrays of matching keys and values, generates a
 * datagrid filter expression of the type
 * ```
 * [[key1, "=", value1], "and", ..., [key_n, "=", value_n]]
 * ```
 * When the arrays have length 1, it returns the simplified expression
 * ```
 * [key1, "=", value1]
 * ```
 */
export const generateFilter = (
  keys: readonly string[],
  values: readonly any[],
): Filter => {
  if (keys.length === 1) {
    return [keys[0], "=", values[0]];
  }

  const filter = keys.reduce((_filter, key, idx) => {
    _filter.push([key, "=", values[idx]]);
    _filter.push("and");
    return _filter;
  }, [] as Filter);

  filter.pop(); // remove trailing "and"

  return filter;
};

/**
 * Access a nested property of an object via a string containing the "path"
 * ```
 * const obj = {
 *   a: {
 *     b: {
 *       c: 4,
 *     },
 *   },
 * };
 *
 * // const innerProperty = 4
 * const innerProperty = access(obj, "a.b.c");
 * ```
 */
export const access = <T, K extends NestedKeyOf<T>>(
  obj: T,
  path: K,
): InternalValueOf<T, K> => {
  const keys = path.split(".");
  let _obj = obj;
  for (const key of keys) {
    _obj = (_obj as any)?.[key];
  }
  return _obj as InternalValueOf<T, K>;
};
