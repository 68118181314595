import { OTooltip, OTypography } from "@maestro/react";
import { DetailsFields } from "components/details-card";
import { StatusBadge } from "components/status-badge";
import { TooltipContent } from "components/tooltip-icon";
import dayjs from "dayjs";
import {
  ValidationOccurenceResults,
  ValidationResults,
} from "./account-close-details-pendencias.type";

interface PendencyProps {
  pendency: ValidationOccurenceResults;
}

const Pendency = ({ pendency }: PendencyProps) => {
  if (pendency.status === "SEM_OCORRENCIAS") {
    return (
      <>
        <StatusBadge type="info">
          Validado em:{" "}
          {dayjs(pendency.verifiedAt).format("DD/MM/YYYY HH:mm:ss")}
        </StatusBadge>
        <StatusBadge type="success">Nenhuma pendência</StatusBadge>
      </>
    );
  }

  if (pendency.status === "VALIDANDO")
    return <StatusBadge type="info">Validando</StatusBadge>;

  if (pendency.status === "COM_OCORRENCIAS")
    return (
      <>
        <StatusBadge type="info">
          Validado em:{" "}
          {dayjs(pendency.verifiedAt).format("DD/MM/YYYY HH:mm:ss")}
        </StatusBadge>
        <OTooltip>
          <TooltipContent>{pendency.action}</TooltipContent>
          <StatusBadge type="warning">{pendency.occurrence}</StatusBadge>
        </OTooltip>
      </>
    );

  return (
    <div>
      <OTypography type="dark">
        <strong>Status:</strong> {pendency.status}
      </OTypography>
      <OTypography type="dark">
        <strong>Pendência:</strong> {pendency.occurrence}
      </OTypography>
      <OTypography type="dark">
        <strong>Ação:</strong> {pendency.action}
      </OTypography>
    </div>
  );
};

const buildPendencyDetailsByAccount = (
  accountPendencies: ValidationResults,
): DetailsFields[string] => ({
  title: `Agência: ${accountPendencies.agencyNumber} | Conta: ${accountPendencies.accountNumber}`,
  items: accountPendencies.productOccurrences.map((productPendency) => ({
    label: productPendency.productName,
    value: <Pendency pendency={productPendency} />,
    grid: { xs: 12, md: 6 },
  })),
});

export const buildClosingPendenciesDetails = (
  validationResults: ValidationResults[],
): DetailsFields => {
  return Object.fromEntries(
    validationResults.map((accountPendencies, idx) => [
      idx,
      buildPendencyDetailsByAccount(accountPendencies),
    ]),
  );
};
