import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useState } from "react";
import { service } from "services";

export const useSelectCompanies = () => {
  const [allCompanies, setAllCompanies] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const { callService: getAllCompanies, value: dataCompanies } = useServiceCall(
    service.quickfin.getAllCompanies,
  );

  useEffect(() => {
    getAllCompanies({ CompanyType: "Cedente" });
  }, [getAllCompanies]);

  useEffect(() => {
    if (!dataCompanies?.companies) return;

    const newData = dataCompanies.companies.map((d) => {
      return {
        value: String(d.id),
        label: d.identification
          ? `${d.name} (${d.identification})`
          : d.name ?? "",
      };
    });

    setAllCompanies(newData);
  }, [dataCompanies]);

  return {
    allCompanies,
  };
};
