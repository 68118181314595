import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { dataSource, gatekeeperLogsGrid } from "./blocked_users.grid";

export const GatekeeperBlockedUsers = () => {
    const gridRef = useGridRef();

    return (
        <GridTemplate
            pageTitle={<PageTitle title="Usuários Bloqueados Gatekeeper" />}
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
        >
            <ODataGridGenerator
                gridRef={gridRef}
                grid={gatekeeperLogsGrid}
                dataSource={dataSource}
            />
        </GridTemplate>
    );
};
