import { OIcon } from "@maestro/react";
import { actionTextBuilder, ruleTextBuilder, uncapitalize } from "../../_utils";

interface RuleAndActionsGridCellProps {
  rule: HubEnergy.DiligenceEventRuleResponse;
}

export const RuleAndActionsGridCell = ({
  rule,
}: RuleAndActionsGridCellProps) => {
  const ruleText = ruleTextBuilder(rule);
  const actionTexts = rule.actions?.map((a) => actionTextBuilder(a)) ?? [];

  if (actionTexts.length === 0) {
    return (
      <p>
        <OIcon
          category="orq"
          icon="orq-status-warning"
          type="warning"
          size="md"
          className="d-inline"
        />{" "}
        {ruleText}
      </p>
    );
  }

  if (actionTexts.length === 1) {
    return (
      <p>
        {ruleText}, {uncapitalize(actionTexts[0])}
      </p>
    );
  }

  return (
    <div>
      <p>{ruleText}</p>
      {actionTexts.map((at) => (
        <p key={at}>- {at}</p>
      ))}
    </div>
  );
};
