import { accEndpoints } from "./acc/acc.endpoints";
import { adminBankinghubEndpoints } from "./admin-bankinghub/admin-bankinghub.endpoints";
import { apiEndpoints } from "./api/api.endpoints";
import { assetsEndpoints } from "./assets/assets.endpoints";
import { bankinghubEndpoints } from "./bankinghub/bankinghub.endpoints";
import { clerkEndpoints } from "./clerk/clerk.endpoints";
import { corbanEndpoints } from "./corban/corban.endpoints";
import { customerServiceEndpoints } from "./customer-service/customer-service.endpoints";
import { eligibilityengineEndpoints } from "./eligibilityengine/eligibilityengine.endpoints";
import { fidcEndpoints } from "./fidc/fidc.endpoints";
import { fopaEndpoints } from "./fopa/fopa.endpoints";
import { gateholderEndpoints } from "./gateholder/gateholder.endpoints";
import { gatekeeperEndpoints } from "./gatekeeper/gatekeeper.endpoints";
import { hubCreditManagerEndpoints } from "./hubcreditmanager/hubcreditmanager.endpoints";
import { hubfxEndpoints } from "./hubfx/hubfx.endpoints";
import { hubloanEndpoints } from "./hubloan/hubloan.endpoints";
import { hubPartnersEndpoints } from "./hubpartners/hubpartners.endpoints";
import { moanaEndpoints } from "./moana/moana.endpoints";
import { onboardingCoreAccountEndpoints } from "./onboarding-core-account/onboarding-core-account.endpoints";
import { onboardingRelationshipsEndpoints } from "./onboarding-relationships/onboarding-relationships.endpoints";
import { onboardingEndpoints } from "./onboarding/onboarding.endpoints";
import { paperclipv2Endpoints } from "./paperclipv2/paperclipv2.endpoints";
import { publicEndpoints } from "./public/public.endpoints";
import { quickfinEndpoints } from "./quickfin/quickfin.endpoints";
import { ravenEndpoints } from "./raven/raven.endpoints";
import { recebiveisCartaoEndpoints } from "./recebiveis-cartao/recebiveis-cartao.endpoints";
import { renegotiationEndpoints } from "./renegotiation/renegotiation.endpoints";
import { roadwalkerEndpoints } from "./roadwalker/roadwalker.endpoints";
import { scfDevelopersEndpoints } from "./scf-developers/scf-developers.endpoints";
import { sennaEndpoints } from "./senna/senna.endpoints";
import { sharkEndpoints } from "./shark/shark.endpoints";
import { turtlefeeEndpoints } from "./turtlefee/turtlefee.endpoints";

export const endpoints = {
  acc: accEndpoints,
  adminBankinghub: adminBankinghubEndpoints,
  api: apiEndpoints,
  bankinghub: bankinghubEndpoints,
  clerk: clerkEndpoints,
  corban: corbanEndpoints,
  customerService: customerServiceEndpoints,
  eligibilityengine: eligibilityengineEndpoints,
  fidc: fidcEndpoints,
  fopa: fopaEndpoints,
  gateholder: gateholderEndpoints,
  gatekeeper: gatekeeperEndpoints,
  hubCreditManager: hubCreditManagerEndpoints,
  hubFx: hubfxEndpoints,
  hubLoan: hubloanEndpoints,
  hubPartners: hubPartnersEndpoints,
  moana: moanaEndpoints,
  onboarding: onboardingEndpoints,
  onboardingCoreAccount: onboardingCoreAccountEndpoints,
  onboardingRelationships: onboardingRelationshipsEndpoints,
  paperclipv2: paperclipv2Endpoints,
  public: publicEndpoints,
  quickfin: quickfinEndpoints,
  raven: ravenEndpoints,
  recebiveisCartao: recebiveisCartaoEndpoints,
  renegotiation: renegotiationEndpoints,
  roadwalker: roadwalkerEndpoints,
  scfDevelopers: scfDevelopersEndpoints,
  senna: sennaEndpoints,
  shark: sharkEndpoints,
  turtlefee: turtlefeeEndpoints,
  assets: assetsEndpoints,
};
