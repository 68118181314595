import { ODropdownItem, OIcon, OTypography } from "@maestro/react";

export const UnrenderedWarning = () => (
  <ODropdownItem style={{ pointerEvents: "none" }} className="mx-auto">
    <OTypography
      tag="span"
      type="dark"
      style={{
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
      }}
    >
      <OIcon
        category="fas"
        icon="fa-exclamation-triangle"
        type="warning"
        style={{ display: "inline" }}
        className="me-2"
      />
      Existem mais opções que não estão sendo exibidas. Digite uma busca mais
      específica.
    </OTypography>
  </ODropdownItem>
);
