import { getValueFromMap } from "utils/get-value-from-map";
import { parseExcelDate } from "utils/parse-excel-date";
import { cleanTaxId, normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { QuoteIntubate } from "../../types";
import { cardBrandMap } from "../card-brand-map";

export const parseQuoteSpreadsheet = (
  buffer: FileReader["result"],
): QuoteIntubate[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  return sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);

    return {
      dataVencimento: parseExcelDate(cleanRow["data de vencimento"]),
      dataDesembolso: cleanRow["data de desembolso"]
        ? parseExcelDate(cleanRow["data de desembolso"])
        : new Date().toISOString().split("T")[0].toString(),
      valorVencimento: cleanRow["valor bruto"],
      bandeira: (
        getValueFromMap(cardBrandMap, cleanRow.bandeira) || cleanRow.bandeira
      ).trim(),
      sacadoCnpj: cleanTaxId(String(cleanRow["cnpj do sacado"])),
      cedenteCnpj: cleanTaxId(String(cleanRow["cnpj do cedente"])),
      idExterno: cleanRow["número da nota"],
    };
  });
};
