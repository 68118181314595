import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../routes/workflow.route-params";
import { workflowConfigViewsGrid } from "./workflow-config-views.grid";

export const WorkflowConfigViews = () => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const viewsGrid = workflowConfigViewsGrid(id);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Visualizações" />}>
      <GridTemplate
        actions={
          <>
            <RouterButton
              // role={roles.workflow.product.configurationsAddGridPage.role}
              href={corporateRouter.routes.workflow.product.workflowsConfig.details.edit.views.add.path(
                { id },
              )}
            >
              Adicionar
            </RouterButton>
            <RefreshGridButton
              onClick={() => {
                !viewsGrid.datagrid.dataSource?.isLoading() &&
                  viewsGrid.datagrid.dataSource?.reload();
              }}
            />
          </>
        }
      >
        <ODataGridGenerator grid={viewsGrid} />
      </GridTemplate>
    </DetailsTemplate>
  );
};
