import { OTypography } from "@maestro/react";
import { DetailInfoItem } from "../../invoices/details/details.styles";
import { IGeneralInfoProps } from "./general-info.types";

export const GeneralInfo = ({
  label,
  value,
  strongValue,
  key,
}: IGeneralInfoProps) => {
  return (
    <DetailInfoItem key={key}>
      <OTypography>{label}</OTypography>
      {/* @ts-ignore */}
      <OTypography size={strongValue ? "xxl" : "md"}>{value}</OTypography>
    </DetailInfoItem>
  );
};
