import { logger } from "utils/logger";

interface HighlightTextProps {
  text: string | undefined | null;
  highlightExpression: RegExp;
}
export const HighlightText = ({
  text,
  highlightExpression,
}: HighlightTextProps) => {
  if (!text) return <span />;

  try {
    return (
      <span>
        {text
          .replace(
            highlightExpression,
            (textToHighlight) =>
              `{{highlightTag}}${textToHighlight}{{highlightTag}}`,
          )
          .split("{{highlightTag}}")
          .reduce(
            (previousValue, currentValue, currentIndex) => (
              <>
                {previousValue}
                {currentIndex % 2 ? (
                  <strong
                    style={{
                      color: "var(--theme-dark)",
                      textDecoration: "underline",
                    }}
                  >
                    {currentValue}
                  </strong>
                ) : (
                  currentValue
                )}
              </>
            ),
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>,
          )}
      </span>
    );
  } catch (err) {
    logger.error(err);

    return <span>{text}</span>;
  }
};
