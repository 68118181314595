import { MasterDetailComponentProps } from "components/data-grid";

type CNABAgreementMasterDetailProps =
  MasterDetailComponentProps<BankingHubAdminConfigHub.Agreement>;

export const CNABAgreementMasterDetail = ({
  data: {
    data: { settings },
  },
}: CNABAgreementMasterDetailProps) => {
  return <pre>{JSON.stringify(settings, null, 4)}</pre>;
};
