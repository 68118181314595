import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  {
    value: "ALUGUEL",
    label: "Aluguel",
  },
  {
    value: "COMPRA_VENDA",
    label: "Compra e venda",
  },
  {
    value: "NOTA",
    label: "Nota",
  },
];

interface TypeFidcFieldProps {
  required?: boolean;
}

export const TypeFidcField = ({ required }: TypeFidcFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:tipo_fidc"
        dataLabel="tipo_fidc"
        id="typeFIDC"
        name="typeFIDC"
        label={EstrategiaFormLabels.typeFIDC}
        description="Tipo de recebível a ser processado pelo FIDC"
        placeholder="Selecionar"
        required={required}
      >
        {options.map(({ label, value }) => (
          <OOption key={value} value={value}>
            {label}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
