import { modalManager } from "@maestro/core";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { OnboardingDomainConfig } from "services/onboarding/models";
import { DomainConfirmationModal } from "./_compose";

const redirectDetailLink = (baseUrl: string, group: string, key: string) => {
  const stringifiedGroup = JSON.stringify(group);
  const stringifiedKey = JSON.stringify(key);

  return `${baseUrl}?group=${stringifiedGroup}&key=${stringifiedKey}`;
};

export const grid: ODataGridGeneratorConfig<OnboardingDomainConfig> = {
  datagrid: {
    noDataText: "Nenhum domínio",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    { dataField: "group", caption: "Grupo" },
    { dataField: "key", caption: "Chave" },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data }) => (
        <>
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar",
                role: roles.ferramentas.product.onboardingDomainConfigEditPage
                  .role,
                route: redirectDetailLink(
                  corporateRouter.routes.ferramentas.product.onboarding
                    .domainConfig.edit.path,
                  data.group,
                  data.key,
                ),
              },
              {
                icon: { category: "orq", icon: "orq-unified-invoice" },
                label: "Exibir",
                onClick: () =>
                  modalManager.show(
                    `domainValue-${data.key}-admin-domain-modal`,
                  ),
              },
            ]}
          />
          <DomainConfirmationModal domainValue={data} />
        </>
      ),
    },
  ],
};
