import { OIcon, OInput, OLoader } from "@maestro/react";
import { useBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import React, { useMemo } from "react";
import { EmptyButton, InputOutline } from "./styled-input.styles";
import { copyShareLink } from "./styled-input.utils";

interface StyledInputProps
  extends Omit<React.ComponentProps<typeof OInput>, "aspect" | "inputType"> {
  loading: boolean;
  active: boolean;
  handleClear?: () => void;
}

export const StyledInput: React.FC<StyledInputProps> = ({
  loading,
  active,
  handleClear,
  ...props
}) => {
  const { size, value } = props;

  const { hasRoles } = useBehavior();

  const after = useMemo(
    () => (
      <>
        {hasRoles([BehaviorRoles.ShareLinkButton_Show]) && active && (
          <EmptyButton
            type="button"
            onMouseDownCapture={(evt) => {
              // prevent input clearing
              evt.preventDefault();
              evt.stopPropagation();
            }}
            onClickCapture={(evt) => {
              copyShareLink();
              evt.preventDefault();
              evt.stopPropagation();
            }}
            data-label="compartilhar"
            data-action="search:botao:compartilhar"
          >
            <OIcon type="primary" category="orq" icon="orq-link" size={size} />
          </EmptyButton>
        )}
        {(!!value || active) && (
          <EmptyButton
            type="button"
            onMouseDownCapture={(evt) => {
              // prevent input clearing
              evt.preventDefault();
              evt.stopPropagation();
            }}
            onClick={(evt) => {
              handleClear?.();
              evt.preventDefault();
              evt.stopPropagation();
            }}
            data-label="apagar-pesquisa"
            data-action="search:botao:apagar-pesquisa"
          >
            <OIcon type="primary" category="orq" icon="orq-close" size={size} />
          </EmptyButton>
        )}
      </>
    ),
    [active, handleClear, hasRoles, size, value],
  );

  const before = useMemo(() => {
    if (loading) return <OLoader type="dark" size="xs" />;

    if (active)
      return <OIcon category="fa" icon="fa-circle" type="success" size="sm" />;
  }, [loading, active]);

  return (
    <InputOutline
      {...props}
      aspect="outline"
      inputType="text"
      className={active ? "o-input-active" : undefined}
    >
      <span slot="before">{before}</span>
      <span slot="after">{after}</span>
    </InputOutline>
  );
};
