import { OToastManager } from "@maestro/core";
import axios from "axios";
import { useCallback, useState } from "react";
import { service } from "services";

export const useBankslipSearchCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bankslip, setBankslip] = useState<BankingHub.Collection>();

  const searchBankslip = useCallback(async (value: string) => {
    try {
      setIsLoading(true);
      setBankslip(undefined);
      const { data } = await service.adminBankinghub.searchBankslip(value);

      setBankslip(data);
    } catch (error) {
      const message =
        axios.isAxiosError(error) && error.response?.status === 404
          ? "Boleto não encontrado."
          : "Ocorreu um erro ao buscar o boleto.";

      OToastManager.danger(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { bankslip, isLoading, searchBankslip };
};
