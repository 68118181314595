interface FormTemplateProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  pageTitle?: React.ReactNode;
}

export const FormTemplate = ({
  actions,
  children,
  pageTitle,
}: FormTemplateProps) => {
  return (
    <div>
      {pageTitle}

      {children}

      {actions && (
        <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
          {actions}
        </div>
      )}
    </div>
  );
};
