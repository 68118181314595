import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../../routes/workflow.route-params";
import {
  OfferFormValues,
  offerFormDefaultValues,
  offerFormValidationSchema,
} from "../offer-form.schema";
import { OfferForm } from "../_compose/offer-form.component";

export const AddOfferPage = () => {
  const { id: workflowConfigId } =
    useParams<WorkflowProdutoWorkflowConfigById>();
  if (!workflowConfigId) throw new Error("No id");

  const navigate = useNavigate();

  const form = useForm<OfferFormValues>({
    resolver: yupResolver(offerFormValidationSchema),
    defaultValues: offerFormDefaultValues,
  });

  const { handleSubmit, setValue } = form;

  const { callService: callAddOffer, loading: loadingAddOffer } =
    useServiceCall(service.hubCreditManager.addOffer);

  const onAddFile = useCallback(
    (e: OUploadCustomEvent<File>) => {
      const file = e.detail;

      setValue("file", [file]);
    },
    [setValue],
  );

  const addOffer = useCallback(
    async ({ description, label, subtitle, title, file }: OfferFormValues) => {
      const formData = new FormData();

      formData.append("workflowConfigId", workflowConfigId);
      formData.append("description", description);
      formData.append("label", label);
      formData.append("subtitle", subtitle);
      formData.append("title", title);

      if (file?.length) {
        formData.append("file", file[0]);
      }

      const { success } = await callAddOffer(formData);

      if (success) {
        OToastManager.success("A oferta foi adicionada com sucesso.");

        navigate(
          corporateRouter.routes.workflow.product.workflowsConfig.details.edit.offers.path(
            { id: workflowConfigId },
          ),
        );
      } else {
        OToastManager.danger("Um erro ocorreu ao tentar adicionar a oferta.");
      }
    },
    [callAddOffer, navigate, workflowConfigId],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar oferta" />}
      actions={
        <LoadingButton
          loading={loadingAddOffer}
          onClick={handleSubmit(addOffer)}
          dataAction="adicionar-oferta:botao:adicionar"
          dataLabel="adicionar"
        >
          Adicionar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <OfferForm onAddFile={onAddFile} />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
