import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { StatusBadge } from "components/status-badge";
import { getValueFromMap } from "utils/get-value-from-map";
import { currencySymbol } from "utils/currency";
import { OIcon, OTooltip } from "@maestro/react";
import { disverbationHistoryStatusFileBadgeMap } from "../historico/disverbation-historys.utils";

export const disverbationApprovalsGrid = (
  setSelectedDisverbations: React.Dispatch<
    React.SetStateAction<Fidc.Installments[] | undefined>
  >,
): ODataGridGeneratorConfig<Fidc.Installments> => ({
  datagrid: {
    noDataText: "Nenhuma parcela de desaverbação foi encontrada.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
      allowSearch: true,
    },
    onSelectionChanged: (evt) => setSelectedDisverbations(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "ContractNumber",
      caption: "Contrato",
    },
    {
      dataField: "FundName",
      caption: "Fundo",
    },
    {
      dataField: "InstallmentNumber",
      caption: "Parcela",
      alignment: "center",
    },
    {
      dataField: "PaidValue",
      caption: "Valor Pago",
      format: (value) => masks.currency(value, currencySymbol.BRL),
    },
    {
      dataField: "Status",
      dataType: "string",
      caption: "Status",
      alignment: "center",
      cellRender: ({ data }) => {
        const badge = getValueFromMap(
          disverbationHistoryStatusFileBadgeMap,
          data.Status,
        );

        if (
          data.Status === "ERROR" &&
          typeof data.ErrorDetails === "string" &&
          data.ErrorDetails.length > 0
        ) {
          const errorMessages = data.ErrorDetails.split(";");
          return (
            <OTooltip type="warning" position="top" floating>
              <div slot="tooltip-content">
                {errorMessages.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
              <div className="d-flex justify-content-end gap-3">
                <StatusBadge
                  type={badge?.type ?? "danger"}
                  size="sm"
                  className="d-inline-block"
                >
                  {badge?.label ?? ""}
                </StatusBadge>
                <OIcon
                  category="fa"
                  icon="fa-exclamation-triangle"
                  size="sm"
                  className="ml-1"
                  type="warning"
                />
              </div>
            </OTooltip>
          );
        }
        if (badge) {
          return (
            <StatusBadge type={badge.type} size="sm" className="d-inline-block">
              {badge.label}
            </StatusBadge>
          );
        }
        return data.Status;
      },
    },
    {
      dataField: "ChargeDueDate",
      caption: "Data Vencimento",
      dataType: "date",
      format: "dd/MM/yyyy",
      allowSorting: false,
      allowFiltering: false,
    },
    {
      dataField: "PaidDate",
      caption: "Data do Pagamento",
      dataType: "date",
      format: "dd/MM/yyyy",
      allowSorting: false,
      allowFiltering: false,
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
