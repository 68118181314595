import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { GuarantorOccurrence } from "./occurrences-grid.types";
import { documentTypeMap, occurrenceTypeMap } from "./occurrences-grid.utils";

export const buildDataSourceLoa = (workflowUuid: string) =>
  dataSourceCustomStoreGenerator<GuarantorOccurrence>(() =>
    service.onboardingRelationships
      .getLoaOccurrences({ workflowUuid })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Não foi possível buscar as ocorrências";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const buildDataSourceGuarantor = (workflowUuid: string) =>
  dataSourceCustomStoreGenerator<GuarantorOccurrence>(() =>
    service.onboardingRelationships
      .getGuarantorOccurrences({ workflowUuid })
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Não foi possível buscar as ocorrências";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const occurrencesGrid: ODataGridGeneratorConfig<GuarantorOccurrence> = {
  datagrid: {
    noDataText: "Nenhuma ocorrência",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: {
      showPageSizeSelector: true,
    },
  },
  columns: [
    {
      dataField: "label",
      dataType: "string",
      caption: "Identificação",
      groupIndex: 0,
      groupCellRender: ({ displayValue }) => displayValue,
    },
    {
      dataField: "documentType",
      dataType: "string",
      caption: "Tipo do documento",
      customizeText: ({ value }) =>
        getValueFromMap(documentTypeMap, value) ?? value,
    },
    {
      dataField: "occurrenceType",
      dataType: "string",
      caption: "Tipo da ocorrência",
      customizeText: ({ value }) =>
        getValueFromMap(occurrenceTypeMap, value) ?? value,
    },
    {
      dataField: "occurrenceDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data da ocorrência",
      allowHeaderFiltering: false,
    },
    {
      dataField: "errorMessage",
      dataType: "string",
      caption: "Mensagem",
      cssClass: "dx-word-wrap",
      allowHeaderFiltering: false,
    },
  ],
};
